// Campos en el formulario
export type Fields = {
  nombre: string;
  descripcion: string;
  estatus: string;
  mantenimientoTipoBeneficio: string;
  mantenimientoValorBeneficio: number;
  empresas: number[];
};

export type Dirty = {
  [key in keyof Fields]: boolean;
};

// Reglas de validación de los campos
export const validationRules = {
  nombre: {
    nombreRequired: value => (value ? false : true)
  },
  descripcion: {
    descripcionRequired: value => (value ? false : true)
  },
  mantenimientoTipoBeneficio: {
    mantenimientoTipoBeneficioRequired: value => value === "NA"
  },
  mantenimientoValorBeneficio: {
    mantenimientoValorBeneficioRequired: value => !(Number(value) > 0)
  },
  estatus: {
    estatusRequired: value => (value ? false : true)
  }
};

// Mensajes de validación
export const validationMessages = {
  nombreRequired: () => "Ingrese el nombre del convenio",
  descripcionRequired: () => "Ingrese la descripción del convenio",
  estatusRequired: () => "Elija el estado del convenio",
  mantenimientoTipoBeneficioRequired: () => "Elija el tipo de descuento",
  mantenimientoValorBeneficioRequired: () => "Especifique el beneficio",
  default: () => "Error en el campo"
};

export const emptyFields = (): Fields => ({
  nombre: "",
  descripcion: "",
  estatus: "",
  mantenimientoTipoBeneficio: "NA",
  mantenimientoValorBeneficio: 0,
  empresas: []
});

export const dirtyClear = (): Dirty => ({
  nombre: false,
  descripcion: false,
  estatus: false,
  mantenimientoTipoBeneficio: false,
  mantenimientoValorBeneficio: false,
  empresas: false
});

export const dirtySubmit = (): Dirty => ({
  nombre: true,
  descripcion: true,
  estatus: true,
  mantenimientoTipoBeneficio: true,
  mantenimientoValorBeneficio: true,
  empresas: true
});
