import { Preview } from "../../../../../types/IAction";
import * as actions from "../../../../actions/invitados";
import { GetBitacoraInvitado } from "../../../../reducers/invitados";

export interface TabBitacoraDispatcher {
  clearBitacoraInvitado: () => Promise<Preview<void>>;
  getBitacoraInvitado: (
    idPersona: any
  ) => Promise<Preview<GetBitacoraInvitado>>;
}

export const tabBitacoraDispatcher: (
  dispatch: (...args) => Promise<Preview<any>>
) => TabBitacoraDispatcher = dispatch => ({
  clearBitacoraInvitado: () => {
    return dispatch(actions.clearBitacoraInvitado());
  },
  getBitacoraInvitado: (idPersona: any) => {
    return dispatch(actions.getBitacoraInvitado(idPersona));
  }
});
