import React from "react";
import { Container, Row, Col } from "reactstrap";
import {
  errorNotification,
  successNotification
} from "../../../utils/notifications";
import {
  Router,
  Styled,
  RouterProps
} from "../../../toolkit/decorators/Artifact";
import FormBuilder from "../../../toolkit/baseForms2/formBuilder/FormBuilder";
import {
  Buttons,
  formConfig,
  Inputs
} from "./ComisionesDeportivaPendiente.form";
import IButtonBuilder from "../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import ConfirmModal from "../common/confirmModal/confirmModal";
import { MemoTable } from "./ComisionesDeportivaPendientes.table";
import { ComisionesDeportivaPendientesDispatcher } from "../../containers/comisionesDeportivaPendientes/ComisionesDeportivaPendientes.dispatcher";
import { ComisionesDeportivaPendientesStore } from "../../containers/comisionesDeportivaPendientes/ComisionesDeportivaPendientes.store";
import ComisionProcesada from "../../../types/ComisionProcesada";
import s from "./styles.scss";
import comisionDeportivaCsv from "../../helpers/comisionDeportivaCsv";

interface Props
  extends ComisionesDeportivaPendientesStore,
    ComisionesDeportivaPendientesDispatcher,
    RouterProps<any> {}

interface State {
  showConfirm: boolean;
  selected: number[];
}

/**
 * Gestión de comisiones que no se procesaron en CRM1 por error de operación
 */
@Router()
@Styled(s)
export default class ComisionesDeportivaPendientes extends React.Component<
  Props,
  State
> {
  state = {
    showConfirm: false,
    selected: []
  };

  /**
   * Selección de una comisión desde la tabla
   */
  onCheck = (id: number, checked: boolean) => {
    const selected = checked
      ? this.state.selected.includes(id)
        ? [...this.state.selected]
        : [...this.state.selected, id]
      : this.state.selected.filter(sel => sel != id);

    this.setState({ selected });
  };

  /**
   * Selección o descarte de todas las comisiones
   */
  onCheckAll = (checked: boolean) => {
    const comisiones = this.props.comsionesDeportivaCrm1Pendientes || [];
    this.setState({
      selected: checked ? comisiones.map(c => c.id) : []
    });
  };

  /**
   * Búsqueda de comisiones pendientes desde formulario
   */
  submit = async (fields: { [key in Inputs]: string }) => {
    const data = {
      ...fields,
      club: fields.club || null,
      estatus: fields.estatus || null,
      empleado: fields.empleado || null,
      folioFactura: fields.folioFactura || null
    };

    if (!data.fecha) {
      errorNotification("Verifique la información solicitada");
      return;
    }

    const preview = await this.props.getComisionesDeportivaCrm1Pendientes(data);

    if (preview.fulfilled) {
      this.setState({ selected: [] });
    } else {
      errorNotification(preview.comsionesDeportivaCrm1PendientesError);
    }
  };

  /**
   * Presentación del modal de comfirmación de procesamiento
   */
  confirmar = () => {
    if (!this.state.selected.length) {
      errorNotification("Seleccione alguna comisión por procesar");
      return;
    }

    this.setState({ showConfirm: true });
  };

  /**
   * Procesamiento confirmado de comisiones seleccionadas
   */
  procesar = async () => {
    const preview = await this.props.putComisionesDeportivaCrm1Procesar(
      this.state.selected
    );

    if (preview.fulfilled) {
      successNotification("Comisiones procesadas, verifique el resultado");
      this.setState({ showConfirm: false });
      this.download(preview.comsionesDeportivaCrm1Procesadas);
    } else {
      errorNotification(preview.comsionesDeportivaCrm1ProcesarError);
    }
  };

  /**
   * Descarga de reporte de comisiones pendientes y procesadas
   */
  download = (resultado?: ComisionProcesada[]) => {
    const procesadas = this.props.comsionesDeportivaCrm1Procesadas || [];
    const paraReporte = resultado
      ? this.props.comsionesDeportivaCrm1Pendientes
          .filter(c => this.state.selected.includes(c.id))
          .map(c => ({
            ...c,
            comisionCrm1Id: (resultado.find(p => p.id == c.id) || {})
              .comisionCrm1Id
          }))
      : this.props.comsionesDeportivaCrm1Pendientes.map(c => ({
          ...c,
          comisionCrm1Id: (procesadas.find(p => p.id == c.id) || {})
            .comisionCrm1Id
        }));

    comisionDeportivaCsv(paraReporte);
  };

  render() {
    return (
      <Container className="mb-5">
        <ConfirmModal
          title="Autorización de procesamiento"
          message={
            <>
              <p>
                Se reportarán estas comisiones en el periodo actual en el
                sistema de cobro.
              </p>
              <p>ESTA OPERACIÓN NO SE PUEDE DESHACER.</p>
              <p>¿Desea continuar?</p>
            </>
          }
          isOpen={this.state.showConfirm}
          size="md"
          ok={this.procesar}
          cancel={() => {
            this.setState({
              showConfirm: false
            });
          }}
          waiting={this.props.comsionesDeportivaCrm1ProcesarLoading}
        />

        <FormBuilder<Inputs, Buttons>
          config={formConfig}
          submit={this.submit}
          processing={this.props.comsionesDeportivaCrm1PendientesLoading}
        >
          <IButtonBuilder<Inputs, Buttons>
            name="procesar"
            onClick={this.confirmar}
            processing={this.props.comsionesDeportivaCrm1ProcesarLoading}
            disabled={
              !!this.props.comsionesDeportivaCrm1Procesadas ||
              !this.props.comsionesDeportivaCrm1Pendientes
            }
          />
        </FormBuilder>

        <hr className="row" />

        <MemoTable
          data={this.props.comsionesDeportivaCrm1Pendientes || []}
          loading={
            this.props.comsionesDeportivaCrm1PendientesLoading ||
            this.props.comsionesDeportivaCrm1ProcesarLoading
          }
          onExport={this.download}
          onCheck={this.onCheck}
          onCheckAll={this.onCheckAll}
          processed={this.props.comsionesDeportivaCrm1Procesadas}
          selected={this.state.selected}
        />
      </Container>
    );
  }
}
