import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import RowRender from "../priceDataMaintenanceForm/RowRender";
import { withRouter } from "react-router-dom";
import s from "./tabMaintenances.scss";
import { Container, Row, Col, Button } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import SelectFilter from "../common/SelectFilter";
import moment from "moment";
if (process.env.BROWSER) {
  var ReactDataGrid = require("react-data-grid");
}
const formatDate = date => moment(date).format("DD/MM/YYYY");
const NO_AUTORIZADOS = "4";

interface Props {
  getPricesToMaintenance: (params: object) => any;
  prices: object[];
  loading: boolean;
  maintenances: { datosBasicosMantenimientoId: string; nombre: string }[];
  conceptos: object[];
  clubs: { clubId: string; nombre: string }[];
  authorizePricesMaintenances: (prices: object[]) => any;
}

class TabMaintenances extends React.Component<Props> {
  state = {
    isModalOpen: false,
    columns: [
      {
        key: "nombre",
        name: "Club"
      },
      {
        key: "inicioVigencia",
        name: "Inicio Vigencia",
        formatter: cellInfo => this.renderDate(cellInfo)
      },
      {
        key: "nombreUsuarioSolicitante",
        name: "Usuario"
      },
      {
        key: "mantenimientoNombre",
        name: "Mantenimiento"
      },
      {
        key: "nombreMembresia",
        name: "Membresía"
      },
      {
        key: "tipoIntegrante",
        name: "Tipo de Integrante"
      },
      {
        key: "numeroIntegrante",
        name: "Número de Integrante"
      },
      {
        key: "precio",
        name: "Precio",
        editable: false
      }
    ],
    rows: [],
    selectedIndexes: [],
    filters: {
      estatus: NO_AUTORIZADOS,
      mantenimiento: "",
      club: {
        value: "",
        label: "Todos los Clubes"
      }
    },
    saving: false
  };

  changeFilter = (e, name) => {
    const value = e.target.value;
    this.setState(state => {
      const { filters } = this.state;
      filters[name] = value;
      return { ...state, filters };
    });
  };

  renderDate = cell => {
    const index = this.state.rows.findIndex(
      row =>
        cell.row.mantenimientoId === row.mantenimientoId &&
        cell.row.membresiaId === row.membresiaId &&
        cell.row.clubId === row.clubId &&
        cell.row.numeroIntegrante === row.numeroIntegrante &&
        cell.row.tipoIntegrante === row.tipoIntegrante
    );
    return <div>{formatDate(this.state.rows[index][`inicioVigencia`])}</div>;
  };

  changeClub = club => {
    this.setState(prevState => {
      const { filters } = this.state;
      filters.club = club;
      return { ...prevState, filters };
    });
  };

  search = filters => {
    const { club } = filters;
    this.props.getPricesToMaintenance({
      ...filters,
      club: club.value ? club.value : ""
    });
  };

  refreshPrices = async actionPromise => {
    return actionPromise
      .then(() => {
        this.setState({ saving: false });
        return this.props.getPricesToMaintenance({
          ...this.state.filters,
          club: this.state.filters.club.value
            ? this.state.filters.club.value
            : this.props.clubs.map(({ clubId }) => clubId).join(",")
        });
      })
      .then(({ value: { data } }) => {
        this.setState(state => {
          return {
            ...state,
            data: data.map(price => {
              return {
                ...price,
                cuentaContableId: price.cuentaContableId
                  ? price.cuentaContableId
                  : "",
                cuentaProductoId: price.cuentaProductoId
                  ? price.cuentaProductoId
                  : ""
              };
            }),
            selectedIndexes: []
          };
        });
      });
  };

  onRowsSelected = rows => {
    this.setState({
      selectedIndexes: this.state.selectedIndexes.concat(
        rows.map(r => r.rowIdx)
      )
    });
  };

  onRowsDeselected = rows => {
    let rowIndexes = rows.map(r => r.rowIdx);
    this.setState({
      selectedIndexes: this.state.selectedIndexes.filter(
        i => rowIndexes.indexOf(i) === -1
      )
    });
  };

  rowGetter = i => {
    return this.state.rows[i];
  };

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (this.props.prices && prevProps.prices !== this.props.prices) {
      this.setState({
        ...this.state,
        rows: this.props.prices,
        selectedIndexes: []
      });
    }
  }

  componentWillReceiveProps() {}

  handleSubmit = e => {
    e.preventDefault();
    const checked = this.state.selectedIndexes;
    const data = this.state.rows;
    const pricesToAuthorize = data
      .filter((_, index) => checked.indexOf(index) > -1)
      .map(({ id }) => id);
    this.setState({ saving: true });
    this.refreshPrices(
      this.props.authorizePricesMaintenances(pricesToAuthorize)
    );
  };

  render() {
    return (
      <Container fluid className="p-0 mt-2">
        {this.props.conceptos && this.props.maintenances && (
          <Row>
            <Col>
              <BaseInput
                label={"Mantenimiento"}
                name={"mantenimiento"}
                type="select"
                id={"mantenimiento"}
                placeholder="Mantenimiento"
                value={this.state.filters.mantenimiento}
                size="sm"
                onChange={e => this.changeFilter(e, "mantenimiento")}
                options={[
                  { value: "", label: "Seleccione el Mantenimiento" }
                ].concat(
                  this.props.maintenances.map(option => ({
                    value: option.datosBasicosMantenimientoId,
                    label: option.nombre
                  }))
                )}
              />
            </Col>
            <Col>
              {this.props.clubs && (
                <SelectFilter
                  label={"Club"}
                  name="clubSelector"
                  type="select"
                  id="clubSelector"
                  placeholder="Club"
                  value={this.state.filters.club}
                  options={[{ value: "", label: "Todos los Clubes" }].concat(
                    this.props.clubs
                      ? this.props.clubs
                          .filter(x => !!x.migradoCrm2)
                          .map(({ clubId, nombre }) => ({
                            label: nombre,
                            value: clubId
                          }))
                      : []
                  )}
                  onChange={this.changeClub}
                  errors={[]}
                />
              )}
            </Col>
            <Col>
              <Button
                size="sm"
                className={`${s.searchButton} ${s.primaryButton} btn-block`}
                onClick={() => this.search(this.state.filters)}
                disabled={this.state.saving}
              >
                Buscar
              </Button>
            </Col>
          </Row>
        )}
        <hr />
        <div className={s.container}>
          <div className={s.wrapper}>
            <ReactDataGrid
              enableCellSelect={true}
              enableRowSelect={true}
              columns={this.state.columns}
              rowGetter={this.rowGetter}
              rowsCount={this.props.loading ? 0 : this.state.rows.length}
              rowRenderer={RowRender}
              rowSelection={{
                showCheckbox: true,
                enableShiftSelect: true,
                onRowsSelected: this.onRowsSelected,
                onRowsDeselected: this.onRowsDeselected,
                selectBy: {
                  indexes: this.state.selectedIndexes
                }
              }}
            />
          </div>
        </div>
        <Col xs={2} className="float-right pr-0 pt-2">
          <Button
            className={`${s.buttonMarginTop} ${s.primaryButton} float-right`}
            onClick={e => this.handleSubmit(e)}
            size="sm"
            block
            disabled={this.state.saving}
          >
            {"Autorizar"}
          </Button>
        </Col>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(TabMaintenances));
