import React, { Component } from "react";
import { ReduxConnect } from "../../../../../toolkit/decorators/Artifact";
import {
  tabMovimientosDispatcher,
  TabMovimientosDispatcher
} from "./TabMovimientos.dispatcher";
import {
  tabMovimientosStore,
  TabMovimientosStore
} from "./TabMovimientos.store";
import InvitadoDetalle from "../../../../../types/InvitadoDetalle";
import { MemoTable } from "./TabMovimientos.table";
import { PaymentMovement } from "../../../paymentMovements";
import HistoryCreditNotes from "../../../paymentMovements/historyCreditNotes";
import { Container } from "reactstrap";
import { errorNotification } from "../../../../../utils/notifications";

interface Props extends TabMovimientosStore, TabMovimientosDispatcher {
  detalle: InvitadoDetalle;
}

interface State {
  showCreditNotes: boolean;
  row: PaymentMovement;
}

@ReduxConnect(tabMovimientosStore, tabMovimientosDispatcher)
export default class TabMovimientos extends Component<Props, State> {
  state: Readonly<State> = {
    showCreditNotes: false,
    row: null as PaymentMovement
  };

  componentDidMount() {
    this.props.getMovimientosInvitado(this.props.detalle.idPersona);
  }

  onShowNotasCredito = (row: PaymentMovement) => {
    this.setState({ showCreditNotes: true, row });
  };

  onViewPdf = (row: PaymentMovement) => {
    this.props.showDocument(row.storageIdFactura);
  };

  onViewXml = (row: PaymentMovement) => {
    this.props.getBillXml(row.uuidFactura);
  };

  onSendEmail = (row: PaymentMovement) => {
    if (!this.props.detalle.correo) {
      errorNotification("El invitado no tiene un correo registrado");
      return;
    }

    this.props.sendMovementEmail(row.id, this.props.detalle.correo);
  };

  render() {
    return (
      <Container>
        <HistoryCreditNotes
          isOpenHCN={this.state.showCreditNotes}
          toggleHCN={() => this.setState({ showCreditNotes: false })}
          ventaId={
            this.state.row && this.state.row.ventaId
              ? this.state.row.ventaId
              : "0"
          }
          historicalRebilling={this.props.historicalRebilling}
          listHistoricalRebilling={this.props.listHistoricalRebilling}
          toggleBill={this.props.showDocument}
        />

        <MemoTable
          data={this.props.movimientosInvitado}
          loading={this.props.gettingMovimientosInvitado}
          onShowNotasCredito={this.onShowNotasCredito}
          onViewPdf={this.onViewPdf}
          onViewXml={this.onViewXml}
          onSendEmail={this.onSendEmail}
        />
      </Container>
    );
  }
}
