import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Container, Row, Col, Button, Input, Form } from "reactstrap";
import SelectFilter from "../common/SelectFilter";
import ReactTable from "react-table";
import CONSTANTS from "../../../utils/constants";
import ModalFrame from "../../../toolkit/modalFrame";
import BaseInput from "../../../toolkit/baseInput";

interface Props {
  match: any;
  memberships: {
    datosBasicosMembresiaId: number;
    nombre: string;
    integrantes: number;
  }[];
  history: any;
  getMembershipMembers: (membresiaId: string) => any;
  getMaintenancesMembership: (membresiaId: string) => any;
  maintenancesMembership: {
    datosBasicosMantenimientoId: number;
    nombre: string;
  }[];
  memberTypes: { id: number; nombre: string }[];
  membershipTypesMembers: {
    nombre: string;
    maximoIntegrantes: number;
    tipoIntegranteId: number;
    membresiaId: string;
  }[];
  sendMembersTypesRules: (membresiaId: string, data: object[]) => any;
  getMembersTypesRules: (membresiaId: string) => any;
  membersRules: {
    edadMaxima: number;
    edadMinima: number;
    idMantenimiento: number;
    idMembresia: number;
    idTipoIntegrante: number;
  }[];
}

enum TIPO_PERSONA {
  CLIENTE_TITULAR = 0,
  CLIENTE_INTEGRANTE = 1
}

interface State {
  membershipSelected: {
    value: number;
    label: string;
    integrantes: number;
  };
  data: {
    idMantenimiento: number;
    nombre: string;
  }[];
  dataFilter: {
    idMantenimiento: number;
    nombre: string;
  }[];
  fieldsModal: any;
  dataLoaded: boolean;
  initialLoad: boolean;
  isOpen: boolean;
  saveClick: boolean;
}

const getEmptyState = () => ({
  membershipSelected: null,
  data: null,
  dataFilter: null,
  dataLoaded: false,
  initialLoad: false,
  isOpen: false,
  fieldsModal: {
    edadMinimaId: "",
    edadMaximaId: "",
    idRule: "",
    idMantenimiento: "",
    nombre: "",
    edadMinima: "",
    edadMaxima: ""
  },
  saveClick: false
});

class MembershipMembersRules extends React.Component<Props, State> {
  state = getEmptyState();

  setMemberhip = membresiaId => {
    const membership = (this.props.memberships || []).find(
      ({ datosBasicosMembresiaId }) => datosBasicosMembresiaId == membresiaId
    );

    if (!!membership) {
      const { nombre, integrantes } = membership;
      this.setState({
        ...this.state,
        ...getEmptyState(),
        membershipSelected: {
          value: membresiaId,
          label: nombre,
          integrantes
        }
      });
    }
  };

  initialLoad = () => {
    const { membresiaId } = this.props.match.params;
    this.setMemberhip(membresiaId);
    this.props.getMembershipMembers(membresiaId);
    this.props.getMaintenancesMembership(membresiaId);
    this.props.getMembersTypesRules(membresiaId);
  };

  componentDidMount() {
    this.initialLoad();
  }

  getData = (
    maintenancesMembership,
    membershipTypesMembers,
    membersRulesHash
  ) => {
    return maintenancesMembership
      .filter(x => !!x)
      .map(({ datosBasicosMantenimientoId: idMantenimiento, nombre }) => ({
        idMantenimiento,
        nombre,
        ...membershipTypesMembers.reduce(
          (
            accum,
            { tipoIntegranteId: idTipoIntegrante, nombre: nombreTipoIntegrante }
          ) => {
            accum[idTipoIntegrante] = nombreTipoIntegrante;
            accum[`edadMinima_${idTipoIntegrante}`] =
              (membersRulesHash[idMantenimiento] &&
                membersRulesHash[idMantenimiento][idTipoIntegrante] &&
                membersRulesHash[idMantenimiento][idTipoIntegrante]
                  .edadMinima) ||
              0;
            accum[`edadMaxima_${idTipoIntegrante}`] =
              (membersRulesHash[idMantenimiento] &&
                membersRulesHash[idMantenimiento][idTipoIntegrante] &&
                membersRulesHash[idMantenimiento][idTipoIntegrante]
                  .edadMaxima) ||
              0;
            return accum;
          },
          {}
        )
      }));
  };

  componentDidUpdate(prevProps) {
    const { membresiaId } = this.props.match.params;
    const { membresiaId: prevMembresiaId } = prevProps.match.params;
    if (membresiaId !== prevMembresiaId) {
      this.initialLoad();
    }

    const { maintenancesMembership, membershipTypesMembers } = this.props;

    const { membersRules } = this.props;

    if (this.state.saveClick) {
      this.setState({
        saveClick: false,
        isOpen: false
      });
      this.initialLoad();
    }

    if (
      maintenancesMembership &&
      membershipTypesMembers &&
      membersRules &&
      !this.state.dataLoaded
    ) {
      const membersRulesHash = {};
      membersRules.forEach(
        ({ idTipoIntegrante, idMantenimiento, edadMaxima, edadMinima }) => {
          membersRulesHash[idMantenimiento] =
            membersRulesHash[idMantenimiento] || {};
          membersRulesHash[idMantenimiento][idTipoIntegrante] = {
            edadMaxima,
            edadMinima
          };
        }
      );
      const data = this.getData(
        maintenancesMembership,
        membershipTypesMembers,
        membersRulesHash
      );
      this.setState({ ...this.state, data, dataLoaded: true });
    }
  }

  changeMembership = ({ value }) => {
    this.props.history.push(`/membresias/${value}/reglas-integrantes`);
  };

  disableButton = () => false;

  sendMembersTypesRulesModal = e => {
    e.preventDefault();
    const { membresiaId } = this.props.match.params;
    const data = [];

    data.push({
      idMantenimiento: this.state.fieldsModal.idMantenimiento,
      idMembresia: membresiaId,
      edadMinima: parseInt(this.state.fieldsModal.edadMinima),
      edadMaxima: parseInt(this.state.fieldsModal.edadMaxima),
      idTipoIntegrante: this.state.fieldsModal.idRule
    });

    this.props.sendMembersTypesRules(membresiaId, data);
    this.setState({ saveClick: true });
  };

  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  updateRule = data => {
    const {
      edadMinimaId,
      edadMaximaId,
      idRule,
      idMantenimiento,
      nombre,
      edadMinima,
      edadMaxima
    } = data;
    this.setState(state => {
      const fieldsModal = {
        ...this.state.fieldsModal,
        edadMinimaId,
        edadMaximaId,
        idRule,
        idMantenimiento,
        nombre,
        edadMinima,
        edadMaxima
      };
      return { ...state, fieldsModal };
    });
    this.toggleModal();
  };

  onChangeFieldsModal = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fieldsModal = {
          ...this.state.fieldsModal,
          [fieldName]: value
        };
        return {
          ...state,
          fieldsModal
        };
      });
    };
  };

  render() {
    const columns = [];
    if (this.state.data) {
      columns.push({
        Header: "NOMBRE",
        accessor: "nombre",
        minWidth: 250,
        className: "text-center",
        Cell: ({ value }) => <p className="mb-0">{value}</p>
      });

      this.props.membershipTypesMembers.forEach(
        ({ nombre, tipoIntegranteId: id }) => {
          const edadMinimaId = `edadMinima_${id}`;
          const edadMaximaId = `edadMaxima_${id}`;
          const idRule = id;
          const column = {
            Header: nombre,
            columns: [
              {
                Header: "Min.",
                accessor: edadMinimaId,
                Cell: data => <p>{data.value}</p>,
                minWidth: 45
              },
              {
                Header: "Max.",
                accessor: edadMaximaId,
                Cell: data => <p>{data.value}</p>,
                minWidth: 45
              },
              {
                Header: "Opciones",
                width: 80,
                accessor: "actions",
                Cell: row => {
                  return (
                    <Container className="p-0">
                      <Row>
                        <Col>
                          <a
                            className={s.aEditar}
                            id={`validate${row.original.idMantenimiento}`}
                            onClick={() =>
                              this.updateRule({
                                edadMinimaId,
                                edadMaximaId,
                                idRule,
                                idMantenimiento: row.original.idMantenimiento,
                                nombre: row.original.nombre,
                                edadMinima: row.original[edadMinimaId],
                                edadMaxima: row.original[edadMaximaId]
                              })
                            }
                          >
                            Editar
                          </a>
                        </Col>
                      </Row>
                    </Container>
                  );
                }
              }
            ]
          };
          columns.push(column);
        }
      );
    }

    return (
      <Container className="p-0 mt-2">
        <ModalFrame
          title={`Editar edades para club ${this.state.fieldsModal.nombre}`}
          isOpen={this.state.isOpen}
          toggle={this.toggleModal}
        >
          <Container>
            <Form>
              <Row>
                <Col xs={12} md={4} lg={4}>
                  <BaseInput
                    label={"Edad Mínima"}
                    name="nedadMinima"
                    type="text"
                    id="edadMinima"
                    value={this.state.fieldsModal.edadMinima}
                    onChange={this.onChangeFieldsModal("edadMinima")}
                  />
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <BaseInput
                    label={"Edad Máxima"}
                    name="edadMaxima"
                    type="text"
                    id="edadMaxima"
                    value={this.state.fieldsModal.edadMaxima}
                    onChange={this.onChangeFieldsModal("edadMaxima")}
                  />
                </Col>
                <Col xs={12} md={4} lg={4} className="mt-4">
                  <Button
                    onClick={this.sendMembersTypesRulesModal}
                    size="sm"
                    block
                  >
                    Guardar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </ModalFrame>
        <Row className="p-0 m-0 mb-3">
          <Col xs="5" className="p-0 m-0">
            <SelectFilter
              label={"Membresía"}
              name="membershipSelector"
              type="select"
              id="membershipSelector"
              placeholder="Membresía"
              value={this.state.membershipSelected}
              options={this.props.memberships.map(
                ({ datosBasicosMembresiaId, nombre }) => ({
                  label: nombre,
                  value: datosBasicosMembresiaId
                })
              )}
              onChange={this.changeMembership}
            />
          </Col>
          <Col xs="2" className="p-0 m-0"></Col>
          <Col xs="5" className="p-0 m-0">
            {this.state.data && this.state.data.length > 0 && (
              <BaseInput
                label="Buscar por nombre"
                name="membresiaNombre"
                type="text"
                id="membresiaNombre"
                onChange={e => {
                  let dataFilter = this.state.data.filter(
                    d =>
                      d.nombre
                        .trim()
                        .toLocaleLowerCase()
                        .indexOf(e.target.value.trim().toLocaleLowerCase()) >= 0
                  );
                  this.setState({
                    ...this.state,
                    dataFilter,
                    dataLoaded: true
                  });
                }}
              />
            )}
          </Col>
        </Row>
        <Row className="p-0 m-0">
          {this.state.data && (
            <>
              <ReactTable
                data={
                  this.state.dataFilter
                    ? this.state.dataFilter
                    : this.state.data
                }
                columns={columns}
                noDataText="Membresia sin mantenimientos asociados."
                pageSize={
                  (this.state.data || []).length || CONSTANTS.DEFAULT_PAGE_SIZE
                }
                sortable={false}
                showPagination={false}
                className="-highlight w-100"
                getTbodyProps={() => ({
                  style: { backgroundColor: "#EAECEE" }
                })}
              />
            </>
          )}
        </Row>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(MembershipMembersRules));
