import moment from "moment";
import IAction from "../../../types/IAction";

export interface Props {
  downloadReportMonthlyAccrual: (
    clubVentaId: number,
    clubDispersionId: number,
    fechaInicio: string,
    fechaFin: string,
    tipo: string,
    tipoProducto: string
  ) => IAction;
  downloadReportAccrualProcessLinesSent: (processId: number) => IAction;
  downloadReportItemsToAccrualBySaleDate: (fecha: string) => IAction;
  clubs: {
    clubId: number;
    nombre: string;
  }[];
}

export interface PropsMonthlyAccrual {
  downloadReportMonthlyAccrual: () => void;
  onSearch: boolean;
  data: any[];
}

export interface PropsToAccrualBySaleDate {
  downloadReport: () => void;
  onSearch: boolean;
  data: any[];
}

export interface PropsAccrualProcesses {
  downloadReportAccrualProcessLinesSent: (processId: number) => void;
  selectProcess: (processId: number) => void;
  onSearch: boolean;
  data: any[];
}

export interface State {
  filters: object;
  onSearch: boolean;
  data: any[];
}

export interface StateAccrualProcesses {
  processId: boolean;
  onSearch: boolean;
  dataLines: any[];
}

export const catalogoMonth = [
  { value: "", label: "Seleccionar mes" },
  { value: "1", label: "ENERO" },
  { value: "2", label: "FEBRERO" },
  { value: "3", label: "MARZO" },
  { value: "4", label: "ABRIL" },
  { value: "5", label: "MAYO" },
  { value: "6", label: "JUNIO" },
  { value: "7", label: "JULIO" },
  { value: "8", label: "AGOSTO" },
  { value: "9", label: "SEPTIEMBRE" },
  { value: "10", label: "OCTUBRE" },
  { value: "11", label: "NOVIEMBRE" },
  { value: "12", label: "DICIEMBRE" }
];

export const ACCRUAL_PROCESSES_REPORT_TYPE = "PROCESO";
export const TO_ACCRUAL_REPORT_TYPE = "POR_DEVENGAR";

export const getYearCatalog = () => {
  const currentYear = moment().year() + 5;
  const catalog = [{ value: 0, label: "Seleccionar año" }];
  const nYears = 15;

  for (let i = currentYear; i > currentYear - nYears; i--) {
    catalog.push({
      value: i,
      label: i.toString()
    });
  }

  return catalog;
};

export const getReportTypesCatalog = () => {
  return [
    { value: null, label: "Seleccionar tipo reporte" },
    { value: "FACTURACION", label: "DEVENGAMIENTO FACTURADO" },
    { value: "DEVENGAMIENTO", label: "DEVENGAMIENTO PENDIENTE" },
    { value: "POR_DEVENGAR", label: "POR DEVENGAR POR FECHA" },
    { value: "PROCESO", label: "PROCESOS DEVENGAMIENTO EJECUTADOS" }
  ];
};

export const getReportProductTypesCatalog = () => {
  return [
    { value: null, label: "Seleccionar tipo producto" },
    { value: "MEMBRESIA", label: "MEMBRESIA" },
    { value: "MANTENIMIENTO", label: "MANTENIMIENTO" }
  ];
};

export const getValidaDateFromState = (mes, anio) => {
  const fechaInicio = moment(`${anio}-${mes}-1`);
  const fechaFin = moment(`${anio}-${mes}-1`)
    .add(1, "month")
    .subtract(1, "day");

  return {
    fechaInicio: fechaInicio.format("YYYY-MM-DD"),
    fechaFin: fechaFin.format("YYYY-MM-DD")
  };
};

export const getContextualStatusClass = process => {
  return !!process.selected ? "table-success" : "";
};
