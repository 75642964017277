import React, { Component } from "react";
import IUserSelectorProps from "./IUserSelector.props";
import IUserSelectorState from "./IUserSelector.state";
import ITypeSelect from "../../baseForms2/iTypeSelect/ITypeSelect";
import { getEmployeesBasicCatalog } from "../../../Home/actions/catalogs";

export default class IUserSelectorConnected extends Component<
  IUserSelectorProps,
  IUserSelectorState
> {
  state = {
    value: "",
    options: [],
    loading: false
  };

  componentDidMount(): void {
    this.setState({ loading: true });
    getEmployeesBasicCatalog(
      this.props.includeSystemUsers,
      this.props.includeSuspendedUsers,
      this.props.init
    ).then(options => {
      this.setState({ options, loading: false });
    });
  }

  componentDidUpdate(prevProps: Readonly<IUserSelectorProps>): void {
    const reload =
      this.props.init !== prevProps.init ||
      this.props.includeSystemUsers !== prevProps.includeSystemUsers ||
      this.props.includeSuspendedUsers !== prevProps.includeSuspendedUsers;

    if (reload) {
      this.setState({ loading: true });
      getEmployeesBasicCatalog(
        this.props.includeSystemUsers,
        this.props.includeSuspendedUsers,
        this.props.init
      ).then(options => {
        this.setState({ options, loading: false });
      });
    }
  }

  render() {
    return (
      <ITypeSelect
        {...(this.props as any)}
        data={
          this.props.useNumeroEmpleado
            ? this.state.options.filter(u => u.employeeNumber > 0)
            : this.state.options
        }
        isLoading={this.state.loading}
        mapOption={item => ({
          value: this.props.useNumeroEmpleado
            ? item.employeeNumber
            : item.userId,
          label: item.label
        })}
      />
    );
  }
}
