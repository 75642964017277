import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";

import {
  getQuoteByPersonId,
  getQuoteByMembership,
  getQuoteFilteredClubAgreement,
  getCashboxPersonId,
  getCashboxByMembership
} from "../../actions/sportsProducts";
import { getEmpresasConvenio } from "../../actions/convenios";
import { getClubs } from "../../actions/catalogs";
import { saveSPQuote, deleteProducts } from "../../actions/sportsProducts";
import SportsProductsQuotesSelector from "../../components/sportsProductsQuotesSelector";

interface Props {
  getClubs: () => void;
  saveSPQuote: (data: object) => any;
  getQuoteByPersonId: (personId: number, params: object) => any;
  getQuoteByMembership: (params: object) => any;
  getQuoteFilteredClubAgreement: (params: object) => any;
  getCashboxPersonId: (personId: number) => any;
  getCashboxByMembership: (params: object) => any;
  getEmpresasConvenio: () => any;
  deleteProducts: (productId: string) => any;
  deletingProduct: boolean;
  deletingProductError: object;
  clubs: object[];
  empresasConvenio: any;
}

class SportsProductsQuotesSelectorPage extends React.Component<Props> {
  componentDidMount() {
    this.props.getClubs();
    this.props.getEmpresasConvenio();
  }

  render() {
    return (
      <main>
        <Helmet title="Sports Products Quote Selector" />
        <Layout>
          <SportsProductsQuotesSelector
            saveSPQuote={this.props.saveSPQuote}
            getQuoteByPersonId={this.props.getQuoteByPersonId}
            getQuoteByMembership={this.props.getQuoteByMembership}
            getQuoteFilteredClubAgreement={
              this.props.getQuoteFilteredClubAgreement
            }
            getCashboxPersonId={this.props.getCashboxPersonId}
            getCashboxByMembership={this.props.getCashboxByMembership}
            deleteProducts={this.props.deleteProducts}
            deletingProduct={this.props.deletingProduct}
            deletingProductError={this.props.deletingProductError}
            clubs={this.props.clubs}
            empresasConvenio={this.props.empresasConvenio}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getClubs: () => {
    dispatch(getClubs());
  },
  saveSPQuote: (data: object) => {
    return dispatch(saveSPQuote(data));
  },
  getQuoteByPersonId: (personId: number, params: object) => {
    return dispatch(getQuoteByPersonId(personId, params));
  },
  getQuoteByMembership: (params: object) => {
    return dispatch(getQuoteByMembership(params));
  },
  getQuoteFilteredClubAgreement: (params: object) => {
    return dispatch(getQuoteFilteredClubAgreement(params));
  },
  getCashboxPersonId: (personId: number) => {
    return dispatch(getCashboxPersonId(personId));
  },
  getCashboxByMembership: (params: object) => {
    return dispatch(getCashboxByMembership(params));
  },
  getEmpresasConvenio: () => {
    dispatch(getEmpresasConvenio());
  },
  deleteProducts: (productId: string) => {
    dispatch(deleteProducts(productId));
  }
});

const mapStateToProps = state => {
  const { catalogs, sportsProducts, convenios } = state;

  return {
    deletingProduct: sportsProducts && sportsProducts.deletingProduct,
    deletingProductError: sportsProducts && sportsProducts.deletingProductError,
    clubs: catalogs.clubs,
    convenios: convenios.empresasConvenio
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SportsProductsQuotesSelectorPage);
