import { QueryArguments } from "../../../types-business/QueryArguments";
import EmpresaConvenio from "../../../types/EmpresaConvenio";
import * as actions from "../../actions/empresaConvenio";
import { Preview } from "../../../types/IAction";
import {
  GetEmpresasConvenio,
  UpdateEmpresaConvenio
} from "../../reducers/empresaConvenio";

// Interfaz esperada por los dispatchers
export interface EmpresaConvenioDispatcher {
  getCompanies: (
    args?: QueryArguments
  ) => Promise<Preview<GetEmpresasConvenio>>;
  updateCompany: (
    companie: EmpresaConvenio
  ) => Promise<Preview<UpdateEmpresaConvenio>>;
}

export const empresaConvenioDispatcher: (
  dispatch: (...args) => Promise<Preview<any>>
) => EmpresaConvenioDispatcher = dispatch => ({
  getCompanies: (args?: QueryArguments) => {
    return dispatch(actions.getCompanies(args));
  },
  updateCompany: (companie: EmpresaConvenio) => {
    return dispatch(actions.updateCompanie(companie.id, companie));
  }
});
