import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";
import { QueryArguments } from "../../types-business/QueryArguments";
import { QueryArgumentsClub } from "../../types-business/QueryArgumentsClub";
import * as localStorageHelper from "./localStorage";
import { STORED_TYPE } from "./localStorage";

const CATALOGS_BASE_URL = "/api/catalogs";
const MAINTENANCES_API_URL = "/api/maintenances";
const CONVENIOS_BASE_URL = "/api/cconvenios";
const SCHEDULES_BASE_URL = "/api/schedule-patterns";
const USERS_BASE_URL = "/api/users";

export const getConcepts = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_CONCEPTS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${CATALOGS_BASE_URL}/concepts`, {
        withCredentials: true
      })
    }
  };
};

export const getStatus = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_STATUS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CATALOGS_BASE_URL}/price-status`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getPaymentTypes = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_PAYMENT_TYPES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CATALOGS_BASE_URL}/payment-types`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getProductAccounts = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_PRODUCT_ACCOUNTS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CATALOGS_BASE_URL}/product-accounts`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getAccountingAccounts = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_ACCOUNTING_ACCOUNTS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CATALOGS_BASE_URL}/accounting-accounts`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getCatalogAccrual = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_ACCRUAL,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${CATALOGS_BASE_URL}/accrual`, {
        withCredentials: true
      })
    }
  };
};

export const getClubs = (params?: QueryArgumentsClub): IAction => {
  return {
    type: actionTypes.GET_CATALOG_CLUBS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${CATALOGS_BASE_URL}/clubs`, {
        withCredentials: true,
        params
      })
    }
  };
};

export const getClubsRequest = (params?: QueryArguments) => {
  return executeCatalogWithLocalStorage({
    localStorageKey: "clubBasicDataCatalogo",
    localStorageType: STORED_TYPE.ARRAY,
    url: `${constants.BASE_URL}${CATALOGS_BASE_URL}/clubs`,
    config: { withCredentials: true, params }
  });
};

export const getClubsRequestBe = (params?: QueryArguments) => {
  const url = `${constants.BASE_URL}${CATALOGS_BASE_URL}/clubs`;
  const config = { withCredentials: true, params };
  return axios.get(url, config);
};

export const getAgreements = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_AGREEMENTS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CATALOGS_BASE_URL}/agreements`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getMembershipsTypes = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_MEMBERSHIPS_TYPES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CATALOGS_BASE_URL}/memberships-types`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getAllMembershipsAvailableToSell = () => {
  const url = `${constants.BASE_URL}${CATALOGS_BASE_URL}/memberships-types/available-to-sell`;
  const config = { withCredentials: true };
  return axios.get(url, config);
};

export const getAllMaintenancesAvailableToSell = () => {
  const url = `${constants.BASE_URL}${CATALOGS_BASE_URL}/maintenances/available-to-sell`;
  const config = { withCredentials: true };
  return axios.get(url, config);
};

export const getMemberships = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_MEMBERSHIPS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CATALOGS_BASE_URL}/memberships`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getSchemas = (): IAction => {
  return {
    type: actionTypes.GET_SCHEMAS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CATALOGS_BASE_URL}/benefits-schemas`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getMaintenances = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_MAINTENANCES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CATALOGS_BASE_URL}/maintenances`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getPromotions = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_PROMOTIONS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CATALOGS_BASE_URL}/promotions`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getSellers = (params?: QueryArguments): IAction => {
  return {
    type: actionTypes.GET_CATALOG_SELLERS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${CATALOGS_BASE_URL}/sellers`, {
        withCredentials: true,
        params
      })
    }
  };
};

export const getSellersCatalog = (params?: QueryArguments) => {
  return executeCatalogWithLocalStorage({
    localStorageKey: "sellersCatalogo",
    localStorageType: STORED_TYPE.ARRAY,
    url: `${constants.BASE_URL}${CATALOGS_BASE_URL}/sellers`,
    config: { withCredentials: true, params }
  });
};

export const getEmployeesBasicCatalog = async (
  includeSystemUsers?,
  includeSuspendedUsers?,
  initUserId?
) => {
  const users = await executeCatalogWithLocalStorage({
    localStorageKey: "employeesBasicCatalogo",
    localStorageType: STORED_TYPE.ARRAY,
    url: `${constants.BASE_URL}${USERS_BASE_URL}/employees/basic-data`,
    config: { withCredentials: true }
  });

  return users.data.filter(
    e =>
      e.userId == initUserId ||
      ((includeSuspendedUsers || e.status !== "SUSPENDED") &&
        (includeSystemUsers || e.employeeNumber > 0))
  );
};

export const getUsers = (params?: QueryArguments): IAction => {
  return {
    type: actionTypes.GET_CATALOG_USERS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${CATALOGS_BASE_URL}/users`, {
        withCredentials: true,
        params
      })
    }
  };
};

export const getUsersRequest = (params?: QueryArguments) => {
  return axios.get(`${constants.BASE_URL}${CATALOGS_BASE_URL}/users`, {
    withCredentials: true,
    params
  });
};

export const getMembershipsTypesByClub = (clubId): IAction => {
  return {
    type: actionTypes.GET_CATALOG_MEMBERSHIPS_TYPES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CATALOGS_BASE_URL}/memberships-types/${clubId}/club`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getMaintenancesByMembershipId = (membresiaId): IAction => {
  return {
    type: actionTypes.GET_CATALOG_MAINTENANCES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CATALOGS_BASE_URL}/maintenances/${membresiaId}/memberships`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getMaintenancesByMembershipForQuotation = (
  membresiaId: number,
  clubId: number,
  tipoCotizacion: String
) => {
  return axios.get(
    `${constants.BASE_URL}/api/maintenances/basicData/by-membership/for-quotation`,
    {
      withCredentials: true,
      params: { membresiaId, clubId, tipoCotizacion }
    }
  );
};

export const getDomains = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_DOMAINS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${CATALOGS_BASE_URL}/domains`, {
        withCredentials: true
      })
    }
  };
};

export const getRoles = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_ROLES,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${CATALOGS_BASE_URL}/roles`, {
        withCredentials: true
      })
    }
  };
};

export const getSurveyTypes = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_SURVEY_TYPES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CATALOGS_BASE_URL}/survey-types`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getSurveyTypesRequest = () => {
  return axios.get(`${constants.BASE_URL}${CATALOGS_BASE_URL}/survey-types`, {
    withCredentials: true
  });
};

export const getSportsProducts = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_SPORTS_PRODUCTS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${CATALOGS_BASE_URL}/products`, {
        withCredentials: true
      })
    }
  };
};

export const getSportsProductsRequest = (
  clasificacionId: number,
  clubId: number
) => {
  return axios.get(`${constants.BASE_URL}${CATALOGS_BASE_URL}/products`, {
    withCredentials: true,
    params: { clasificacionId: clasificacionId || null, clubId: clubId || null }
  });
};

export const getSportsProductsFiltered = (params: object): IAction => {
  return {
    type: actionTypes.GET_CATALOG_SPORTS_PRODUCTS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CATALOGS_BASE_URL}/products/filtered`,
        {
          withCredentials: true,
          params
        }
      )
    }
  };
};

export const getProgramasDeportivos = (params: object): IAction => {
  return {
    type: actionTypes.GET_CATALOG_SPORTS_PRODUCTS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}/api/products/programas-deportivos`,
        {
          withCredentials: true,
          params
        }
      )
    }
  };
};

export const getSportsProductsFilteredRequest = (params: object) => {
  return axios.get(
    `${constants.BASE_URL}${CATALOGS_BASE_URL}/products/filtered`,
    {
      withCredentials: true,
      params
    }
  );
};

export const getSportsProductsTypes = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_SPORTS_PRODUCTS_TYPES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CATALOGS_BASE_URL}/products-types`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getPersonTypes = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_PERSON_TYPES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CATALOGS_BASE_URL}/person-types`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getSatProducts = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_SAT_PRODUCTS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CATALOGS_BASE_URL}/sat-products`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getSatUnits = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_SAT_UNITS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CATALOGS_BASE_URL}/sat-units`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getBanksMonthsWithoutInterest = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_BANKS_MONTHS_WITHOUT_INTEREST,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CATALOGS_BASE_URL}/banks/months-without-interest`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getWaysPay = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_WAYS_PAY,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${CATALOGS_BASE_URL}/ways-pay`, {
        withCredentials: true
      })
    }
  };
};

export const getBankPayTypes = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_BANK_PAY_TYPES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CATALOGS_BASE_URL}/bank-pay-types`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getCardTypes = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_CARD_TYPES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CATALOGS_BASE_URL}/card-types`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getCardTypesRequest = () => {
  return axios.get(`${constants.BASE_URL}${CATALOGS_BASE_URL}/card-types`, {
    withCredentials: true
  });
};

export const getBanks = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_BANKS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${CATALOGS_BASE_URL}/banks`, {
        withCredentials: true
      })
    }
  };
};

export const getBanksRequest = () => {
  return axios.get(`${constants.BASE_URL}${CATALOGS_BASE_URL}/banks`, {
    withCredentials: true
  });
};

export const getAnnuityTypes = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_ANNUITY_TYPES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CATALOGS_BASE_URL}/annuity-types`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getCompanies = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_COMPANIES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CATALOGS_BASE_URL}/companies`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getSpecialGuestsTypes = (): IAction => {
  return {
    type: actionTypes.GET_SPECIAL_GUEST_TYPES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CATALOGS_BASE_URL}/special-guests-types`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getPositions = (): IAction => {
  return {
    type: actionTypes.GET_CATALOG_POSITIONS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${MAINTENANCES_API_URL}/positions`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getEmpresasConvenioRequest = () => {
  return executeCatalogWithLocalStorage({
    localStorageKey: "empresasConvenioCatalog",
    localStorageType: STORED_TYPE.ARRAY,
    url: `${constants.BASE_URL}${CONVENIOS_BASE_URL}/empresas-convenio`,
    config: { withCredentials: true }
  });
};

export const getProductsClassificationsRequest = () => {
  return executeCatalogWithLocalStorage({
    localStorageKey: "productosClasificaciones",
    localStorageType: STORED_TYPE.ARRAY,
    url: `${constants.BASE_URL}${CATALOGS_BASE_URL}/products/categories`,
    config: { withCredentials: true }
  });
};

export const getAccountingAccountsRequest = () => {
  return executeCatalogWithLocalStorage({
    localStorageKey: "cuentasContablesCatalog",
    localStorageType: STORED_TYPE.ARRAY,
    url: `${constants.BASE_URL}${CATALOGS_BASE_URL}/accounting-accounts`,
    config: { withCredentials: true }
  });
};

export const getProductsSatRequest = () => {
  return executeCatalogWithLocalStorage({
    localStorageKey: "productosSatCatalog",
    localStorageType: STORED_TYPE.ARRAY,
    url: `${constants.BASE_URL}${CATALOGS_BASE_URL}/sat-products`,
    config: { withCredentials: true }
  });
};

export const getUnitsSatRequest = () => {
  return executeCatalogWithLocalStorage({
    localStorageKey: "unidadesSatCatalog",
    localStorageType: STORED_TYPE.ARRAY,
    url: `${constants.BASE_URL}${CATALOGS_BASE_URL}/sat-units`,
    config: { withCredentials: true }
  });
};

export const getSchedulePatternsCatalog = () => {
  return executeCatalogWithLocalStorage({
    localStorageKey: "patronesHorario",
    localStorageType: STORED_TYPE.ARRAY,
    url: `${constants.BASE_URL}${SCHEDULES_BASE_URL}`,
    config: { withCredentials: true }
  });
};

export interface AxiosGetMethodExecutorConfig {
  localStorageKey: string;
  localStorageType: STORED_TYPE;
  url: string;
  config: {
    withCredentials: boolean;
    params?: object;
  };
}

export const executeCatalogWithLocalStorage = (
  executorConfig: AxiosGetMethodExecutorConfig
) => {
  const key = executorConfig.localStorageKey;
  const isInStorage = localStorageHelper.hasKey(key);

  if (isInStorage) {
    console.info(key, "from local storage");

    let data = null;
    switch (executorConfig.localStorageType) {
      case STORED_TYPE.OBJECT:
        data = localStorageHelper.getObject(key);
        break;
      case STORED_TYPE.ARRAY:
        data = localStorageHelper.getArray(key);
        break;
    }
    return Promise.resolve({ data });
  } else {
    console.info(key, "from backend");
    return axios
      .get(executorConfig.url, executorConfig.config)
      .then(({ data }) => {
        switch (executorConfig.localStorageType) {
          case STORED_TYPE.OBJECT:
            localStorageHelper.putObject(key, data);
            break;
          case STORED_TYPE.ARRAY:
            localStorageHelper.putArray(key, data);
            break;
        }

        return Promise.resolve({ data });
      });
  }
};
