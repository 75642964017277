import { Preview } from "../../../../../types/IAction";
import * as actions from "../../../../actions/invitados";
import * as actionsPartner from "../../../../actions/partners";
import * as actionsBilling from "../../../../actions/billing";
import * as actionsReports from "../../../../actions/reports";
import * as actionsDocuments from "../../../../actions/documents";
import { GetMovimientosInvitado } from "../../../../reducers/invitados";

export interface TabMovimientosDispatcher {
  historicalRebilling: (ventaId: any) => any;
  sendMovementEmail: (idMovimiento, correo) => any;
  showDocument: (id: any) => any;
  getBillXml: (uuid: string) => any;
  clearMovimientosInvitado: () => Promise<Preview<void>>;
  getMovimientosInvitado: (
    idPersona: any
  ) => Promise<Preview<GetMovimientosInvitado>>;
}

export const tabMovimientosDispatcher: (
  dispatch: (...args) => Promise<Preview<any>>
) => TabMovimientosDispatcher = dispatch => ({
  historicalRebilling: (ventaId: any) => {
    return dispatch(actionsReports.historicalRebilling(ventaId));
  },
  sendMovementEmail: (idMovimiento, correo) => {
    return dispatch(actionsPartner.sendMovementEmail(idMovimiento, correo));
  },
  showDocument: (id: any) => {
    return dispatch(actionsDocuments.showDocument(id));
  },
  getBillXml: (uuid: string) => {
    return dispatch(actionsBilling.getBillXml(uuid));
  },
  clearMovimientosInvitado: () => {
    return dispatch(actions.clearMovimientosInvitado());
  },
  getMovimientosInvitado: (idPersona: any) => {
    return dispatch(actions.getMovimientosInvitado(idPersona));
  }
});
