import React from "react";
import { connect } from "react-redux";
import Layout from "../../components/layout";
import { Helmet } from "react-helmet";
import ListComissionsSchemas from "../../components/comissionsSchemaList";
import {
  getSchemas,
  getPostsBySchema,
  savePostsBySchema,
  cleanPeticionData
} from "../../actions/comissionsSchemas";
import { getPositions } from "../../actions/catalogs";
import CONSTANTS from "../../../utils/constants";
import { QueryArguments } from "../../../types-business/QueryArguments";
import isNil from "lodash.isnil";

interface Props {
  getSchemas: (args?: QueryArguments) => any;
  schemas: object[];
  history: any;
  getPostsBySchema: (idSchema?: string) => any;
  posts: object;
  savePostsBySchema: (args?: QueryArguments) => any;
  getPositions: () => any;
  catalogPostions: object[];
  cleanPeticionData: (data: object) => any;
}

interface State {
  schemas: object[];
  filters: object;
  postData: object[];
}

class ComissionsSchemasListPage extends React.Component<Props, State> {
  state = {
    schemas: [],
    filters: {
      size: CONSTANTS.DEFAULT_PAGE_SIZE,
      page: 0
    },
    postData: []
  };

  onFetchData = ({ pageSize: size, page }) => {
    const filters = {
      ...this.state.filters,
      size,
      page
    };
    this.props.getSchemas(filters);
    this.setState(state => ({ state, ...filters }));
  };

  goTo = destiny => {
    this.props.history.push(destiny);
  };

  componentDidMount() {
    this.props.getSchemas(this.state.filters);
    this.props.getPositions();
  }

  componentDidUpdate() {
    if (!isNil(this.props.catalogPostions)) {
      if (
        this.props.catalogPostions.length > 0 &&
        this.state.postData.length == 0
      ) {
        let dataInsert = [];
        this.props.catalogPostions.map(element => {
          dataInsert.push({ value: element.name, id: element.positionId });
        });
        this.setState({ postData: dataInsert });
      } else if (
        this.props.catalogPostions.length == 0 &&
        this.state.postData.length > 0
      ) {
        this.setState({ postData: [] });
      }
    }
  }

  render() {
    return (
      <main>
        <Helmet title="Creacion de Esquema de Beneficios" />
        <Layout>
          <ListComissionsSchemas
            schemas={this.props.schemas}
            posts={this.props.posts}
            postData={this.state.postData}
            fetchData={this.onFetchData}
            getPostsBySchema={this.props.getPostsBySchema}
            searching={this.props.schemas ? false : true}
            onClickNewSchema={(schema?) =>
              this.goTo(
                "/esquemas-comisiones/" + (schema ? schema.esquemaId : "")
              )
            }
            savePostsBySchema={this.props.savePostsBySchema}
            cleanPeticionData={this.props.cleanPeticionData}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSchemas: (args?: QueryArguments) => {
    dispatch(getSchemas(args));
  },
  getPostsBySchema: (idSchema?: string) => {
    dispatch(getPostsBySchema(idSchema));
  },
  savePostsBySchema: (args?: QueryArguments) => {
    dispatch(savePostsBySchema(args));
  },
  getPositions: () => {
    dispatch(getPositions());
  },
  cleanPeticionData: (data: object) => {
    dispatch(cleanPeticionData(data));
  }
});

const mapStateToProps = state => {
  const {
    benefitsSchemas: { schemas, posts },
    catalogs: { catalogPostions }
  } = state;
  return {
    schemas,
    posts,
    catalogPostions
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComissionsSchemasListPage);
