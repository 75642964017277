/* eslint-disable css-modules/no-unused-class */

import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import normalizeCss from "normalize.css";
import bootstrapCss from "bootstrap/dist/css/bootstrap.css";
import s from "./styles.scss";
import defaultStyles from "./exclude/defaultStyles.scss";
import reactTable from "./exclude/reactTable.scss";
import richEditorStyles from "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import pagTableStyles from "react-table/react-table.css";
import fullCalendarBaseStyles from "fullcalendar/dist/fullcalendar.min.css";
import reactTostifyStyles from "react-toastify/dist/ReactToastify.min.css";
import fullCalendarCustom from "./exclude/fullcalendar.scss";
import reactTimesCss from "react-times/css/classic/default.css";
import rcTreeStyles from "rc-tree/assets/index.css";
import reactYearlyCalendar from "./exclude/react-yearly-calendar-scss.scss";

class LayoutStyles extends React.Component<any, any> {
  render() {
    return <div>{this.props.children}</div>;
  }
}

export default withStyles(
  normalizeCss,
  bootstrapCss,
  richEditorStyles,
  pagTableStyles,
  reactTostifyStyles,
  reactTable,
  fullCalendarBaseStyles,
  fullCalendarCustom,
  reactTimesCss,
  rcTreeStyles,
  reactYearlyCalendar,
  s,
  defaultStyles
)(LayoutStyles);
