import React from "react";
import { connect } from "react-redux";
import DocumentsTable from "../../components/documentsTable";
import {
  sendScannedFile,
  sendEmailFile,
  getDocument,
  getDocumentForSign,
  recreateFile,
  saveDocumentSignature,
  authorizeDocument
} from "../../actions/partners";

interface Props {
  idPersona: string;
  idMembresiaSocio?: string;
  partnerDocuments: object[];
  sendScannedFile: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string,
    file: Blob,
    fileName: string
  ) => any;
  sendEmailFile: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string
  ) => any;
  getDocument: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string
  ) => any;
  getDocumentForSign: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string
  ) => any;
  recreateFile: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string
  ) => any;
  saveDocumentSignature: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string,
    signature: string
  ) => any;
  authorizeDocument: (
    idTipoDocumento: number,
    idMembresiaSocio: number,
    idPersona: number
  ) => any;
  disableName: boolean;
  disableFullWidth: boolean;
  printData: object;
  partnerMembershipDocumentInstances: any[];
  showValidationAction: boolean;
  showSignAction: boolean;
  showScanAction: boolean;
  expedienteAutorizado: boolean;
}

class DocumentsTablePage extends React.Component<Props, void> {
  render() {
    return (
      <DocumentsTable
        disableName={this.props.disableName}
        idPersona={this.props.idPersona}
        idMembresiaSocio={this.props.idMembresiaSocio}
        partnerDocuments={this.props.partnerDocuments}
        sendScannedFile={this.props.sendScannedFile}
        sendEmailFile={this.props.sendEmailFile}
        getDocument={this.props.getDocument}
        getDocumentForSign={this.props.getDocumentForSign}
        recreateFile={this.props.recreateFile}
        saveDocumentSignature={this.props.saveDocumentSignature}
        disableFullWidth={this.props.disableFullWidth}
        authorizeDocument={this.props.authorizeDocument}
        printData={this.props.printData}
        partnerMembershipDocumentInstances={
          this.props.partnerMembershipDocumentInstances
        }
        showValidationAction={this.props.showValidationAction}
        showSignAction={this.props.showSignAction}
        showScanAction={this.props.showScanAction}
        expedienteAutorizado={this.props.expedienteAutorizado}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  sendScannedFile: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string,
    file: Blob,
    fileName: string
  ) => {
    return dispatch(
      sendScannedFile(
        idTipoDocumento,
        idMembresiaSocio,
        idPersona,
        file,
        fileName
      )
    );
  },
  sendEmailFile: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string
  ) => {
    return dispatch(
      sendEmailFile(idTipoDocumento, idMembresiaSocio, idPersona)
    );
  },
  getDocument: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string
  ) => {
    return dispatch(getDocument(idTipoDocumento, idMembresiaSocio, idPersona));
  },
  getDocumentForSign: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string
  ) => {
    return dispatch(
      getDocumentForSign(idTipoDocumento, idMembresiaSocio, idPersona)
    );
  },
  recreateFile: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string
  ) => {
    return dispatch(recreateFile(idTipoDocumento, idMembresiaSocio, idPersona));
  },
  saveDocumentSignature: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string,
    signature: string
  ) => {
    return dispatch(
      saveDocumentSignature(
        idTipoDocumento,
        idMembresiaSocio,
        idPersona,
        signature
      )
    );
  },
  authorizeDocument: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string
  ) => {
    dispatch(authorizeDocument(idTipoDocumento, idMembresiaSocio, idPersona));
  }
});

const mapStateToProps = _ => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentsTablePage);
