exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".lKT_b{background:#f3d8d8}.husFT{background:#bce0bc}._2qCbx{background:#fff}._1iQba{padding:0!important}.v2wOq{padding:7px 3px}._3D1c0 div{overflow-x:hidden!important}._3D1c0 div div div div{overflow:hidden!important}", ""]);

// exports
exports.locals = {
	"pendiente": "lKT_b",
	"procesada": "husFT",
	"normal": "_2qCbx",
	"tdClear": "_1iQba",
	"field": "v2wOq",
	"hidden": "_3D1c0"
};