import {
  ActivarInvitado,
  DeleteInvitado,
  DesactivarInvitado,
  GetInvitados,
  PostHuellaInvitado
} from "../../reducers/invitados";

export interface InvitadosListStore
  extends GetInvitados,
    ActivarInvitado,
    DesactivarInvitado,
    DeleteInvitado,
    PostHuellaInvitado {}

export const invitadosListStore: (state) => InvitadosListStore = state => {
  const { invitados } = state;
  return {
    invitados: invitados.invitados,
    gettingInvitados: invitados.gettingInvitados,
    gettingInvitadosError: invitados.gettingInvitadosError,

    activandoInvitado: invitados.activandoInvitado,
    activandoInvitadoError: invitados.activandoInvitadoError,

    desactivandoInvitado: invitados.desactivandoInvitado,
    desactivandoInvitadoError: invitados.desactivandoInvitadoError,

    deletingInvitado: invitados.deletingInvitado,
    deletingInvitadoError: invitados.deletingInvitadoError,

    postingHuellaInvitado: invitados.postingHuellaInvitado
  };
};
