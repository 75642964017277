import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import DatePicker from "../../common/DatePicker";
import s from "./styles.scss";
import {
  Button,
  Input,
  Label,
  Row,
  Col,
  Container,
  FormGroup
} from "reactstrap";
import ModalFrame from "../../../../toolkit/modalFrame";
import BaseInput from "../../../../toolkit/baseInput";
import moment from "moment";
import IAction from "../../../../types/IAction";
// import { OPTIONS_FORMA_PAGO } from "../../../../utils/constants";

interface Props {
  isNowOpen: boolean;
  toggle: () => any;
  data: any;
  createAsign: boolean;
  accountingAccounts: any;
  addDealerConfigCharge: (body: object) => IAction;
  updateDealerConfigCharge: (body: object) => IAction;
  dataUpdate: any;
  dataAddDCC: any;
  idClub: any;
  concesionarioId: any;
  setSecondTable: () => any;
}

interface State {
  fijo: boolean;
  variable: boolean;
  fields: any;
  onLoad: boolean;
  loadSuccess: boolean;
}

const FECHA_INICIO_CF = "fechaInicioCF";
const FECHA_TERMINO_CF = "fechaTerminoCF";

const FECHA_INICIO_CV = "fechaInicioCV";
const FECHA_TERMINO_CV = "fechaTerminoCV";

const OPTIONS_PRODUCTO_SERVIVIO = [
  { id: "0", value: "Seleccione un Producto/Servicio" },
  {
    id: "24",
    value: "80131500 -Alquiler y arrendamiento de propiedades o edificaciones"
  }
];

const OPTIONS_UNIDAD = [
  { id: "0", value: "Seleccione una unidad de servicio" },
  { id: "2", value: "E48 - Unidad de servicio" },
  { id: "6", value: "MON - Mes" }
];

class AsignDealer extends React.Component<Props, State> {
  state = {
    fijo: false,
    variable: true,
    fields: {
      montoFijo: "",
      formaDePagoCF: "",
      cuentaContableIdCF: "",
      diaAplicacionCF: "",
      fechaInicioCF: null,
      fechaTerminoCF: null,
      descripcionCargoCF: "",
      productoServicioSATIdCF: "",
      unidadServicioSATIdCF: "",
      cantidadCF: "",
      retencionIvaCF: false,
      validarCargo: true,
      montoMinimoVentas: "",
      porcentaje: "",
      cuentaContableIdCV: "",
      diaAplicacionCV: "",
      fechaInicioCV: null,
      fechaTerminoCV: null,
      descripcionCargoCV: "",
      productoServicioSATIdCV: "",
      unidadServicioSATIdCV: "",
      cantidadCV: "",
      retencionIvaCV: false,
      formaDePagoCV: "",
      vigenciaCF: false,
      vigenciaCV: false
    },
    loadSuccess: false,
    onLoad: false
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dataUpdate !== this.props.dataUpdate) {
      this.setState({ loadSuccess: false });
    }
    if (prevProps.dataAddDCC !== this.props.dataAddDCC) {
      this.setState({ loadSuccess: false });
    }

    if (
      (this.props.dataUpdate !== null || this.props.dataAddDCC !== null) &&
      !this.state.loadSuccess
    ) {
      this.setState(state => ({
        ...state,
        fields: {
          montoFijo: "",
          formaDePagoCF: "",
          cuentaContableIdCF: "",
          diaAplicacionCF: "",
          fechaInicioCF: null,
          fechaTerminoCF: null,
          descripcionCargoCF: "",
          productoServicioSATIdCF: "",
          unidadServicioSATIdCF: "",
          cantidadCF: "",
          retencionIvaCF: false,
          validarCargo: true,
          montoMinimoVentas: "",
          porcentaje: "",
          cuentaContableIdCV: "",
          diaAplicacionCV: "",
          fechaInicioCV: null,
          fechaTerminoCV: null,
          descripcionCargoCV: "",
          productoServicioSATIdCV: "",
          unidadServicioSATIdCV: "",
          cantidadCV: "",
          retencionIvaCV: false,
          formaDePagoCV: "",
          vigenciaCF: false,
          vigenciaCV: false
        },
        onLoad: false,
        loadSuccess: true
      }));
      this.props.setSecondTable();
      this.props.toggle();
    }

    if (
      !this.state.onLoad &&
      this.props.createAsign !== undefined &&
      this.props.isNowOpen &&
      !this.props.createAsign &&
      Object.entries(this.props.data).length > 0
    ) {
      if (this.props.data.tipo == "FIJO") {
        const {
          montoFijo,
          formaDePago,
          cuentaContableId,
          diaAplicacion,
          fechaInicio,
          fechaTermino,
          descripcionCargo,
          productoServicioSATId,
          unidadServicioSATId,
          unidades,
          retencionIva,
          validarCargo
        } = this.props.data;

        this.setState(state => ({
          ...state,
          fields: {
            montoFijo,
            formaDePagoCF: formaDePago,
            cuentaContableIdCF: cuentaContableId,
            diaAplicacionCF: diaAplicacion,
            fechaInicioCF: fechaInicio,
            fechaTerminoCF: fechaTermino,
            vigenciaCF:
              fechaTermino === null || fechaTermino === undefined
                ? false
                : true,
            descripcionCargoCF: descripcionCargo ? descripcionCargo : "",
            productoServicioSATIdCF: productoServicioSATId,
            unidadServicioSATIdCF: unidadServicioSATId,
            cantidadCF: unidades,
            retencionIvaCF: retencionIva,
            validarCargo: true
          },
          onLoad: true
        }));
      }

      if (this.props.data.tipo == "VARIABLE") {
        const {
          montoMinimoVentas,
          porcentaje,
          cuentaContableId,
          diaAplicacion,
          fechaInicio,
          fechaTermino,
          descripcionCargo,
          productoServicioSATId,
          unidadServicioSATId,
          unidades,
          retencionIva,
          formaDePago
        } = this.props.data;

        this.setState(state => ({
          ...state,
          fields: {
            montoMinimoVentas,
            porcentaje,
            cuentaContableIdCV: cuentaContableId,
            diaAplicacionCV: diaAplicacion,
            fechaInicioCV: fechaInicio,
            fechaTerminoCV: fechaTermino,
            vigenciaCV:
              fechaTermino === null || fechaTermino === undefined
                ? false
                : true,
            descripcionCargoCV: descripcionCargo,
            productoServicioSATIdCV: productoServicioSATId,
            unidadServicioSATIdCV: unidadServicioSATId,
            cantidadCV: unidades,
            retencionIvaCV: retencionIva,
            formaDePagoCV: formaDePago
          },
          onLoad: true
        }));
      }
    }
  }

  clearData = () => {
    this.setState(state => ({
      ...state,
      fields: {
        montoFijo: "",
        formaDePagoCF: "",
        cuentaContableIdCF: "",
        diaAplicacionCF: "",
        fechaInicioCF: null,
        fechaTerminoCF: null,
        descripcionCargoCF: "",
        productoServicioSATIdCF: "",
        unidadServicioSATIdCF: "",
        cantidadCF: "",
        retencionIvaCF: false,
        validarCargo: true,
        montoMinimoVentas: "",
        porcentaje: "",
        cuentaContableIdCV: "",
        diaAplicacionCV: "",
        fechaInicioCV: null,
        fechaTerminoCV: null,
        descripcionCargoCV: "",
        productoServicioSATIdCV: "",
        unidadServicioSATIdCV: "",
        cantidadCV: "",
        retencionIvaCV: false,
        formaDePagoCV: "",
        vigenciaCF: false,
        vigenciaCV: false
      },
      onLoad: false
    }));
    this.props.toggle();
  };

  onChangePersona = () => {
    return event => {
      this.setState({
        fijo: !this.state.fijo,
        variable: !this.state.variable
      });
    };
  };

  onChangeFields = fieldName => {
    return event => {
      let value = "";
      if (
        fieldName === "montoFijo" ||
        fieldName === "diaAplicacionCF" ||
        fieldName === "diaAplicacionCV" ||
        fieldName === "porcentaje" ||
        fieldName === "cantidad" ||
        fieldName === "montoMinimoVentas"
      ) {
        value = this.validarNumerico(event.target.value);
      } else {
        value = event.target.value;
      }

      this.setState(state => {
        const fields = {
          ...this.state.fields,
          [fieldName]: value
        };
        return { ...state, fields };
      });
    };
  };

  validarNumerico = valor => {
    return valor.replace(/[^0-9]/g, "");
  };

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: date
        };
        return { ...state, error: {}, fields };
      });
    };
  };

  onChangeCheckBox = (e, name) => {
    const { checked } = e.target;
    this.setState(state => {
      const fields = {
        ...state.fields,
        [name]: checked
      };
      return { ...state, error: {}, fields };
    });
  };

  handleUpdate = e => {
    e.preventDefault();
    if (this.props.data.tipo === "FIJO" && !this.props.createAsign) {
      this.props.updateDealerConfigCharge({
        id: this.props.data.id,
        tipo: "FIJO",
        concesionarioId: this.props.concesionarioId,
        clubId: this.props.idClub,
        fechaInicio: this.state.fields.fechaInicioCF,
        fechaTermino: !this.state.fields.vigenciaCF
          ? null
          : this.state.fields.fechaTerminoCF,
        diaAplicacion: parseInt(this.state.fields.diaAplicacionCF),
        cuentaContableId: parseInt(this.state.fields.cuentaContableIdCF),
        descripcionCargo: this.state.fields.descripcionCargoCF,
        productoServicioSATId: parseInt(
          this.state.fields.productoServicioSATIdCF
        ),
        unidadServicioSATId: parseInt(this.state.fields.unidadServicioSATIdCF),
        unidades: parseInt(this.state.fields.cantidadCF),
        retencionIva: this.state.fields.retencionIvaCF,
        formaDePago: this.state.fields.formaDePagoCF,
        montoFijo: parseInt(this.state.fields.montoFijo),
        validarCargo: true,
        montoMinimoVentas: null,
        porcentaje: null,
        restarCargoFijo: null
      });
    }

    if (this.props.data.tipo === "VARIABLE" && !this.props.createAsign) {
      this.props.updateDealerConfigCharge({
        id: this.props.data.id,
        tipo: "VARIABLE",
        concesionarioId: this.props.concesionarioId,
        clubId: this.props.idClub,
        fechaInicio: this.state.fields.fechaInicioCV,
        fechaTermino: !this.state.fields.vigenciaCV
          ? null
          : this.state.fields.fechaTerminoCV,
        diaAplicacion: parseInt(this.state.fields.diaAplicacionCV),
        cuentaContableId: parseInt(this.state.fields.cuentaContableIdCV),
        descripcionCargo: this.state.fields.descripcionCargoCV,
        productoServicioSATId: parseInt(
          this.state.fields.productoServicioSATIdCV
        ),
        unidadServicioSATId: parseInt(this.state.fields.unidadServicioSATIdCV),
        unidades: parseInt(this.state.fields.cantidadCV),
        retencionIva: this.state.fields.retencionIvaCV,
        formaDePago: this.state.fields.formaDePagoCV,
        montoMinimoVentas: parseInt(this.state.fields.montoMinimoVentas),
        validarCargo: true,
        porcentaje: parseInt(this.state.fields.porcentaje),
        restarCargoFijo: this.state.fields.retencionIvaCV
      });
    }
  };

  handleAdd = e => {
    e.preventDefault();
    if (this.state.fijo && this.props.createAsign) {
      this.props.addDealerConfigCharge({
        tipo: "FIJO",
        concesionarioId: this.props.concesionarioId,
        clubId: this.props.idClub,
        fechaInicio: this.state.fields.fechaInicioCF,
        fechaTermino: !this.state.fields.vigenciaCF
          ? null
          : this.state.fields.fechaTerminoCF,
        diaAplicacion: parseInt(this.state.fields.diaAplicacionCF),
        cuentaContableId: parseInt(this.state.fields.cuentaContableIdCF),
        descripcionCargo: this.state.fields.descripcionCargoCF,
        productoServicioSATId: parseInt(
          this.state.fields.productoServicioSATIdCF
        ),
        unidadServicioSATId: parseInt(this.state.fields.unidadServicioSATIdCF),
        unidades: parseInt(this.state.fields.cantidadCF),
        retencionIva: this.state.fields.retencionIvaCF,
        formaDePago: this.state.fields.formaDePagoCF,
        montoFijo: parseInt(this.state.fields.montoFijo),
        validarCargo: true
      });
    }

    if (this.state.variable && this.props.createAsign) {
      this.props.addDealerConfigCharge({
        tipo: "VARIABLE",
        concesionarioId: this.props.concesionarioId,
        clubId: this.props.idClub,
        fechaInicio: this.state.fields.fechaInicioCV,
        fechaTermino: !this.state.fields.vigenciaCV
          ? null
          : this.state.fields.fechaTerminoCV,
        diaAplicacion: parseInt(this.state.fields.diaAplicacionCV),
        cuentaContableId: parseInt(this.state.fields.cuentaContableIdCV),
        descripcionCargo: this.state.fields.descripcionCargoCV,
        productoServicioSATId: parseInt(
          this.state.fields.productoServicioSATIdCV
        ),
        unidadServicioSATId: parseInt(this.state.fields.unidadServicioSATIdCV),
        unidades: parseInt(this.state.fields.cantidadCV),
        retencionIva: this.state.fields.retencionIvaCV,
        formaDePago: this.state.fields.formaDePagoCV,
        montoMinimoVentas: parseInt(this.state.fields.montoMinimoVentas),
        porcentaje: parseInt(this.state.fields.porcentaje),
        restarCargoFijo: this.state.fields.retencionIvaCV,
        validarCargo: true
      });
    }
  };

  render() {
    return (
      <Container className="p-0">
        <ModalFrame
          title={`Asignar concesionario a unidad de negocio`}
          isOpen={this.props.isNowOpen}
          toggle={this.clearData}
          size="lg"
        >
          {this.props.createAsign ? (
            <Row>
              <Col xs={4} md={4} lg={4}>
                <Label className="float-right" check>
                  <Input
                    type="checkbox"
                    checked={this.state.variable}
                    onChange={this.onChangePersona()}
                  />
                  Cobro Variable
                </Label>
              </Col>
              <Col xs={4} md={4} lg={4}>
                <Label className="float-right" check>
                  <Input
                    type="checkbox"
                    checked={this.state.fijo}
                    onChange={this.onChangePersona()}
                  />
                  Cobro fijo
                </Label>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={4} md={4} lg={4}>
                <Label className="float-right" check>
                  <Input
                    type="checkbox"
                    disabled={true}
                    checked={this.props.data.tipo === "VARIABLE" ? true : false}
                    onChange={this.onChangePersona()}
                  />
                  Cobro Variable
                </Label>
              </Col>
              <Col xs={4} md={4} lg={4}>
                <Label className="float-right" check>
                  <Input
                    type="checkbox"
                    disabled={true}
                    checked={this.props.data.tipo === "FIJO" ? true : false}
                    onChange={this.onChangePersona()}
                  />
                  Cobro fijo
                </Label>
              </Col>
            </Row>
          )}
          {!this.state.variable ||
          (!this.props.createAsign && this.props.data.tipo === "FIJO") ? (
            <>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <BaseInput
                    label={"$ Monto"}
                    name="montoFijo"
                    type="text"
                    id="montoFijo"
                    value={this.state.fields.montoFijo}
                    onChange={this.onChangeFields("montoFijo")}
                  />
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <BaseInput
                    label="Forma de pago"
                    name="formaDePagoCF"
                    id="formaDePagoCF"
                    type="select"
                    placeholder="Seleccionar forma de pago"
                    value={this.state.fields.formaDePagoCF}
                    onChange={this.onChangeFields("formaDePagoCF")}
                    size={"sm"}
                    options={[
                      { value: "EFECTIVO", label: "EFECTIVO" },
                      { value: "TRANSFERENCIA", label: "TRANSFERENCIA" },
                      { value: "POR_DEFINIR_99", label: "99 - POR DEFINIR" }
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <BaseInput
                    label="Cuenta contable"
                    name="cuentaContableIdCF"
                    id="cuentaContableIdCF"
                    type="select"
                    placeholder="Seleccionar cuenta contable"
                    value={this.state.fields.cuentaContableIdCF}
                    onChange={this.onChangeFields("cuentaContableIdCF")}
                    size={"sm"}
                    options={[
                      { value: "", label: "Seleccionar cuenta contable" }
                    ].concat(
                      (this.props.accountingAccounts || [])
                        .filter(x => x.numeroCuenta == "4070")
                        .map(option => ({
                          value: option.cuentaContableId,
                          label: `${option.numeroCuenta} - ${option.nombre}`
                        }))
                    )}
                  />
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <BaseInput
                    label={"Día de aplicación"}
                    name="diaAplicacionCF"
                    type="text"
                    id="diaAplicacionCF"
                    value={this.state.fields.diaAplicacionCF}
                    onChange={this.onChangeFields("diaAplicacionCF")}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <DatePicker
                    label="Fecha de inicio"
                    name={FECHA_INICIO_CF}
                    id={FECHA_INICIO_CF}
                    selected={this.state.fields[FECHA_INICIO_CF]}
                    onChange={this.onChangeDate(FECHA_INICIO_CF)}
                  />
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <DatePicker
                    label="Fecha de término"
                    name={FECHA_TERMINO_CF}
                    id={FECHA_TERMINO_CF}
                    disabled={!this.state.fields.vigenciaCF}
                    selected={this.state.fields[FECHA_TERMINO_CF]}
                    onChange={this.onChangeDate(FECHA_TERMINO_CF)}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <BaseInput
                    label={"Descripción del cargo"}
                    name="descripcionCargoCF"
                    type="text"
                    id="descripcionCargoCF"
                    value={this.state.fields.descripcionCargoCF}
                    onChange={this.onChangeFields("descripcionCargoCF")}
                  />
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={this.state.fields.vigenciaCF}
                        onChange={e => this.onChangeCheckBox(e, "vigenciaCF")}
                      />
                      Vigencia
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <BaseInput
                    label="Producto / Servicio"
                    name="productoServicioSATIdCF"
                    id="productoServicioSATIdCF"
                    type="select"
                    placeholder="Seleccionar Producto/ Servicio"
                    value={this.state.fields.productoServicioSATIdCF}
                    onChange={this.onChangeFields("productoServicioSATIdCF")}
                    size={"sm"}
                    options={OPTIONS_PRODUCTO_SERVIVIO.map(option => ({
                      value: option.id,
                      label: option.value
                    }))}
                  />
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <BaseInput
                    label="Unidad de servicio"
                    name="unidadServicioSATIdCF"
                    id="unidadServicioSATIdCF"
                    type="select"
                    placeholder="Seleccionar unidad de servicio"
                    value={this.state.fields.unidadServicioSATIdCF}
                    onChange={this.onChangeFields("unidadServicioSATIdCF")}
                    size={"sm"}
                    options={OPTIONS_UNIDAD.map(option => ({
                      value: option.id,
                      label: option.value
                    }))}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <BaseInput
                    label={"Cantidad"}
                    name="cantidadCF"
                    type="text"
                    id="cantidadCF"
                    value={this.state.fields.cantidadCF}
                    onChange={this.onChangeFields("cantidadCF")}
                  />
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={this.state.fields.retencionIvaCF}
                        onChange={e =>
                          this.onChangeCheckBox(e, "retencionIvaCF")
                        }
                      />
                      Retención IVA
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </>
          ) : this.state.variable ||
            (!this.props.createAsign && this.props.data.tipo === "VARIABLE") ? (
            <>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <BaseInput
                    label={"$ Monto mínimo de venta"}
                    name="montoMinimoVentas"
                    type="text"
                    id="montoMinimoVentas"
                    value={this.state.fields.montoMinimoVentas}
                    onChange={this.onChangeFields("montoMinimoVentas")}
                  />
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <BaseInput
                    label={"Porcentaje a cobrar"}
                    name="porcentaje"
                    type="text"
                    id="porcentaje"
                    value={this.state.fields.porcentaje}
                    onChange={this.onChangeFields("porcentaje")}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <BaseInput
                    label="Cuenta contable"
                    name="cuentaContableIdCV"
                    id="cuentaContableIdCV"
                    type="select"
                    placeholder="Seleccionar cuenta contable"
                    value={this.state.fields.cuentaContableIdCV}
                    onChange={this.onChangeFields("cuentaContableIdCV")}
                    size={"sm"}
                    options={[
                      { value: "", label: "Seleccionar cuenta contable" }
                    ].concat(
                      (this.props.accountingAccounts || [])
                        .filter(x => x.numeroCuenta == "4070")
                        .map(option => ({
                          value: option.cuentaContableId,
                          label: `${option.numeroCuenta} - ${option.nombre}`
                        }))
                    )}
                  />
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <BaseInput
                    label={"Día de aplicación"}
                    name="diaAplicacionCV"
                    type="text"
                    id="diaAplicacionCV"
                    value={this.state.fields.diaAplicacionCV}
                    onChange={this.onChangeFields("diaAplicacionCV")}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <BaseInput
                    label="Forma de pago"
                    name="formaDePagoCV"
                    id="formaDePagoCV"
                    type="select"
                    placeholder="Seleccionar forma de pago"
                    value={this.state.fields.formaDePagoCV}
                    onChange={this.onChangeFields("formaDePagoCV")}
                    size={"sm"}
                    options={[
                      { value: "EFECTIVO", label: "EFECTIVO" },
                      { value: "TRANSFERENCIA", label: "TRANSFERENCIA" },
                      { value: "POR_DEFINIR_99", label: "99 - POR DEFINIR" }
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <DatePicker
                    label="Fecha de inicio"
                    name={FECHA_INICIO_CV}
                    id={FECHA_INICIO_CV}
                    selected={this.state.fields[FECHA_INICIO_CV]}
                    onChange={this.onChangeDate(FECHA_INICIO_CV)}
                  />
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <DatePicker
                    label="Fecha de término"
                    name={FECHA_TERMINO_CV}
                    id={FECHA_TERMINO_CV}
                    disabled={!this.state.fields.vigenciaCV}
                    selected={this.state.fields[FECHA_TERMINO_CV]}
                    onChange={this.onChangeDate(FECHA_TERMINO_CV)}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <BaseInput
                    label={"Descripción del cargo"}
                    name="descripcionCargoCV"
                    type="text"
                    id="descripcionCargoCV"
                    value={this.state.fields.descripcionCargoCV}
                    onChange={this.onChangeFields("descripcionCargoCV")}
                  />
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={this.state.fields.vigenciaCV}
                        onChange={e => this.onChangeCheckBox(e, "vigenciaCV")}
                      />
                      Vigencia
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <BaseInput
                    label="Producto / Servicio"
                    name="productoServicioSATIdCV"
                    id="productoServicioSATIdCV"
                    type="select"
                    placeholder="Seleccionar Producto/ Servicio"
                    value={this.state.fields.productoServicioSATIdCV}
                    onChange={this.onChangeFields("productoServicioSATIdCV")}
                    size={"sm"}
                    options={OPTIONS_PRODUCTO_SERVIVIO.map(option => ({
                      value: option.id,
                      label: option.value
                    }))}
                  />
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <BaseInput
                    label="Unidad de servicio"
                    name="unidadServicioSATIdCV"
                    id="unidadServicioSATIdCV"
                    type="select"
                    placeholder="Seleccionar unidad de servicio"
                    value={this.state.fields.unidadServicioSATIdCV}
                    onChange={this.onChangeFields("unidadServicioSATIdCV")}
                    size={"sm"}
                    options={OPTIONS_UNIDAD.map(option => ({
                      value: option.id,
                      label: option.value
                    }))}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <BaseInput
                    label={"Cantidad"}
                    name="cantidadCV"
                    type="text"
                    id="cantidadCV"
                    value={this.state.fields.cantidadCV}
                    onChange={this.onChangeFields("cantidadCV")}
                  />
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={this.state.fields.retencionIvaCV}
                        onChange={e =>
                          this.onChangeCheckBox(e, "retencionIvaCV")
                        }
                      />
                      Restar cargo fijo
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
          <Row className="mt-4">
            <Col xs={6} md={6} lg={6}>
              <Button block color="secondary" onClick={this.clearData}>
                CANCELAR
              </Button>
            </Col>
            <Col xs={6} md={6} lg={6}>
              {!this.state.variable ||
              (!this.props.createAsign && this.props.data.tipo === "FIJO") ? (
                <Button
                  block
                  color="secondary"
                  disabled={
                    this.state.fields.montoFijo === "" ||
                    this.state.fields.cuentaContableIdCF === "" ||
                    this.state.fields.diaAplicacionCF === "" ||
                    this.state.fields.fechaInicioCF === null ||
                    this.state.fields.descripcionCargoCF === "" ||
                    this.state.fields.productoServicioSATIdCF === "0" ||
                    this.state.fields.unidadServicioSATIdCF === "0" ||
                    this.state.fields.cantidadCF === ""
                  }
                  onClick={
                    !this.props.createAsign
                      ? e => this.handleUpdate(e)
                      : e => this.handleAdd(e)
                  }
                >
                  {!this.props.createAsign ? "ACTUALIZAR" : "CREAR"}
                </Button>
              ) : (
                <Button
                  block
                  color="secondary"
                  disabled={
                    this.state.fields.montoMinimoVentas === "" ||
                    this.state.fields.porcentaje === "" ||
                    this.state.fields.cuentaContableIdCV === "" ||
                    this.state.fields.diaAplicacionCV === "" ||
                    this.state.fields.fechaInicioCV === null ||
                    this.state.fields.descripcionCargoCV === "" ||
                    this.state.fields.productoServicioSATIdCV === "0" ||
                    this.state.fields.unidadServicioSATIdCV === "0" ||
                    this.state.fields.cantidadCV === ""
                  }
                  onClick={
                    !this.props.createAsign
                      ? e => this.handleUpdate(e)
                      : e => this.handleAdd(e)
                  }
                >
                  {!this.props.createAsign ? "ACTUALIZAR" : "CREAR"}
                </Button>
              )}
            </Col>
          </Row>
        </ModalFrame>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(AsignDealer));
