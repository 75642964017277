import { Club } from "../../../types-business/Clubs";

export enum VESTIDORES {
  CABALLERO = "HOMBRE",
  DAMA = "MUJER",
  NINO = "NINO",
  NINA = "NINA"
}

export enum ALTURA_LOCKER {
  ARRIBA = "ARRIBA",
  ENMEDIO = "ENMEDIO",
  ABAJO = "ABAJO"
}

export enum TAMANO_LOCKER {
  PEQUENO = "CHICO",
  MEDIANO = "MEDIANO",
  GRANDE = "GRANDE"
}

export interface LOCKER_CONFIG {
  clubId: number;
  lockerList: {
    vestidor: VESTIDORES;
    nombre: string;
    paredId: number;
    size: TAMANO_LOCKER;
    posicion: ALTURA_LOCKER;
    numero: string;
    esAsignable: boolean;
    eliminar?: boolean;
  }[];
}

export interface Props {
  clubs: Club[];
  saveLockersConfig: (lockerConfig: LOCKER_CONFIG) => any;
  getLockers: (clubId: number) => any;
  getListFile: () => any;
  userContext: any;
}

export interface State {
  isWallOpen: boolean;
  isLockerOpen: boolean;
  tamanoLocker: string;
  alturaLocker: string;
  loading: boolean;
  pared: string;
  paredId: number;
  locker: string;
  tamano: object;
  club: {
    value: number;
    label: string;
  };
  vestidor: VESTIDORES;
  lockers: {
    id: number;
    vestidor: VESTIDORES;
    tamano: TAMANO_LOCKER;
    altura: ALTURA_LOCKER;
    pared: string;
    paredId: number;
    numero: string;
    noAsignable: boolean;
    estatus: string;
  }[];
  deleted: {
    id: number;
    vestidor: VESTIDORES;
    tamano: TAMANO_LOCKER;
    altura: ALTURA_LOCKER;
    pared: string;
    paredId: number;
    numero: string;
    noAsignable: boolean;
    estatus: string;
  }[];
  disabledButton: boolean;
}

export const opcionesAltura = [
  {
    value: ALTURA_LOCKER.ABAJO,
    label: ALTURA_LOCKER.ABAJO
  },
  {
    value: ALTURA_LOCKER.ARRIBA,
    label: ALTURA_LOCKER.ARRIBA
  },
  {
    value: ALTURA_LOCKER.ENMEDIO,
    label: ALTURA_LOCKER.ENMEDIO
  }
];

export const opcionesVestidores = [
  {
    value: VESTIDORES.CABALLERO,
    label: VESTIDORES.CABALLERO
  },
  {
    value: VESTIDORES.DAMA,
    label: VESTIDORES.DAMA
  },
  {
    value: VESTIDORES.NINO,
    label: VESTIDORES.NINO
  },
  {
    value: VESTIDORES.NINA,
    label: VESTIDORES.NINA
  }
];

export const opcionesTamanoLocker = [
  {
    value: TAMANO_LOCKER.PEQUENO,
    label: TAMANO_LOCKER.PEQUENO
  },
  {
    value: TAMANO_LOCKER.MEDIANO,
    label: TAMANO_LOCKER.MEDIANO
  },
  {
    value: TAMANO_LOCKER.GRANDE,
    label: TAMANO_LOCKER.GRANDE
  }
];
