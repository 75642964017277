import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { MEMBERSHIP_TYPE } from "../../../../../utils/constants";
import ReactTable from "react-table";
import MexicanCurrency from "../../../../../Home/components/common/currency";
import { InputCellTable } from "../../utils/InputCellTable";
import { errorNotification } from "../../../../../utils/notifications";

const tableTitle = "Tracking de Ventas del Mes";

interface Props {
  clubId: string;
  month: string;
  year: string;
  getSalesTracking: (params: object) => SalesTrackingInterface;
  saveCompromises: (compromises: SalesTrackingInterface) => any;
  disabled: boolean;
}

enum RowTypes {
  PRESUPUESTO = "PRESUPUESTO",
  COMPROMISO = "COMPROMISO",
  ALCANZADO = "ALCANZADO",
  PORCENTAJE_ALCANZADO = "PORCENTAJE_ALCANZADO"
}

export enum ColumnTypes {
  VENTAS = "VENTAS",
  IMPORTE = "IMPORTE",
  PROSPECTOS = "PROSPECTOS"
}

export interface SalesTrackingInterface {
  clubId: string;
  mes: string;
  anio: string;
  numeroEjecutivosQ1: number;
  numeroEjecutivosQ2: number;
  numeroVentas: {
    presupuesto: string;
    compromiso: string;
    alcanzado: string;
    porcentajeAlcanzado: string;
  };
  totalVentas: {
    presupuesto: string;
    compromiso: string;
    alcanzado: string;
    porcentajeAlcanzado: string;
  };
  numeroProspectos: {
    presupuesto: string;
    compromiso: string;
    alcanzado: string;
    porcentajeAlcanzado: string;
  };
}

const convertGoalsToTableData = ({
  numeroVentas: {
    presupuesto: numeroVentasPresupuesto,
    compromiso: numeroVentasCompromiso,
    alcanzado: numeroVentasAlcanzado,
    porcentajeAlcanzado: numeroVentasPorcentajeAlcanzado
  },
  totalVentas: {
    presupuesto: totalVentasPresupuesto,
    compromiso: totalVentasCompromiso,
    alcanzado: totalVentasAlcanzado,
    porcentajeAlcanzado: totalVentasPorcentajeAlcanzado
  },
  numeroProspectos: {
    presupuesto: numeroProspectosPresupuesto,
    compromiso: numeroProspectosCompromiso,
    alcanzado: numeroProspectosAlcanzado,
    porcentajeAlcanzado: numeroProspectosPorcentajeAlcanzado
  }
}: SalesTrackingInterface) => {
  return [
    {
      [ColumnTypes.VENTAS]: {
        [RowTypes.PRESUPUESTO]: numeroVentasPresupuesto,
        [RowTypes.COMPROMISO]: numeroVentasCompromiso,
        [RowTypes.ALCANZADO]: numeroVentasAlcanzado,
        [RowTypes.PORCENTAJE_ALCANZADO]: numeroVentasPorcentajeAlcanzado
      },
      [ColumnTypes.IMPORTE]: {
        [RowTypes.PRESUPUESTO]: totalVentasPresupuesto,
        [RowTypes.COMPROMISO]: totalVentasCompromiso,
        [RowTypes.ALCANZADO]: totalVentasAlcanzado,
        [RowTypes.PORCENTAJE_ALCANZADO]: totalVentasPorcentajeAlcanzado
      },
      [ColumnTypes.PROSPECTOS]: {
        [RowTypes.PRESUPUESTO]: numeroProspectosPresupuesto,
        [RowTypes.COMPROMISO]: numeroProspectosCompromiso,
        [RowTypes.ALCANZADO]: numeroProspectosAlcanzado,
        [RowTypes.PORCENTAJE_ALCANZADO]: numeroProspectosPorcentajeAlcanzado
      }
    }
  ];
};

const getEmptyTableData = () => {
  return [
    {
      [ColumnTypes.VENTAS]: {
        [RowTypes.PRESUPUESTO]: "",
        [RowTypes.COMPROMISO]: "",
        [RowTypes.ALCANZADO]: "",
        [RowTypes.PORCENTAJE_ALCANZADO]: ""
      },
      [ColumnTypes.IMPORTE]: {
        [RowTypes.PRESUPUESTO]: "",
        [RowTypes.COMPROMISO]: "",
        [RowTypes.ALCANZADO]: "",
        [RowTypes.PORCENTAJE_ALCANZADO]: ""
      },
      [ColumnTypes.PROSPECTOS]: {
        [RowTypes.PRESUPUESTO]: "",
        [RowTypes.COMPROMISO]: "",
        [RowTypes.ALCANZADO]: "",
        [RowTypes.PORCENTAJE_ALCANZADO]: ""
      }
    }
  ];
};

const defaultColumn = {
  className: "text-center"
};

export default ({
  clubId,
  month,
  year,
  getSalesTracking,
  saveCompromises,
  disabled
}: Props) => {
  const [salesTracking, setSalesTracking] = useState(null);
  const [tableData, setTableData] = useState(getEmptyTableData());
  const [tableDataLoading, setTableDataLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setTableDataLoading(true);
      try {
        if (clubId == "") {
          throw { response: { data: { message: "El club es requerido" } } };
        }

        const goals = await getSalesTracking({
          clubId: clubId,
          anio: year,
          mes: month,
          tipoEsquema: MEMBERSHIP_TYPE.VENTA
        });

        const goalsWithDate = {
          ...goals,
          clubId,
          anio: year,
          mes: month
        };

        setSalesTracking(goalsWithDate);
        saveCompromises(goalsWithDate);
        setTableData(convertGoalsToTableData(goalsWithDate));
        setError(false);
      } catch ({ response: { data } }) {
        if (data && data.message) {
          errorNotification(data.message);
        } else {
          errorNotification("Error inesperado.");
        }
        setSalesTracking(null);
        saveCompromises(null);
        setTableData(getEmptyTableData());
        setError(true);
      } finally {
        setTableDataLoading(false);
      }
    };

    getData();
  }, [year, month, clubId]);

  const setCompromises = (column: ColumnTypes) => value => {
    const newSalesTracking: SalesTrackingInterface = { ...salesTracking };

    switch (column) {
      case ColumnTypes.IMPORTE:
        newSalesTracking.totalVentas.compromiso = value;
        break;
      case ColumnTypes.PROSPECTOS:
        newSalesTracking.numeroProspectos.compromiso = value;
        break;
      case ColumnTypes.VENTAS:
        newSalesTracking.numeroVentas.compromiso = value;
        break;
      default:
        break;
    }

    saveCompromises(newSalesTracking);
    setTableData(convertGoalsToTableData(newSalesTracking));
  };

  const columns = [
    {
      Header: ColumnTypes.VENTAS,
      columns: [
        {
          ...defaultColumn,
          Header: RowTypes.PRESUPUESTO,
          accessor: `${ColumnTypes.VENTAS}.${RowTypes.PRESUPUESTO}`,
          headerStyle: {
            fontSize: "11px"
          }
        },
        {
          ...defaultColumn,
          Header: RowTypes.COMPROMISO,
          id: `${ColumnTypes.VENTAS}-${RowTypes.COMPROMISO}`,
          accessor: () => (
            <InputCellTable
              disabled={disabled || error}
              value={tableData[0][ColumnTypes.VENTAS][RowTypes.COMPROMISO]}
              onChange={setCompromises(ColumnTypes.VENTAS)}
            />
          ),
          headerStyle: {
            fontSize: "11px"
          }
        },
        {
          ...defaultColumn,
          Header: RowTypes.ALCANZADO,
          accessor: `${ColumnTypes.VENTAS}.${RowTypes.ALCANZADO}`,
          headerStyle: {
            fontSize: "11px"
          }
        },
        {
          ...defaultColumn,
          Header: "% ALCANZADO",
          accessor: `${ColumnTypes.VENTAS}.${RowTypes.PORCENTAJE_ALCANZADO}`,
          headerStyle: {
            fontSize: "11px"
          }
        }
      ]
    },
    {
      Header: ColumnTypes.IMPORTE,
      columns: [
        {
          ...defaultColumn,
          Header: RowTypes.PRESUPUESTO,
          accessor: `${ColumnTypes.IMPORTE}.${RowTypes.PRESUPUESTO}`,
          headerStyle: {
            fontSize: "11px"
          },
          Cell: ({ value }) => <MexicanCurrency quantity={value} />
        },
        {
          ...defaultColumn,
          Header: RowTypes.COMPROMISO,
          id: `${ColumnTypes.IMPORTE}-${RowTypes.COMPROMISO}`,
          accessor: () => (
            <InputCellTable
              disabled={disabled || error}
              value={tableData[0][ColumnTypes.IMPORTE][RowTypes.COMPROMISO]}
              onChange={setCompromises(ColumnTypes.IMPORTE)}
            />
          ),
          headerStyle: {
            fontSize: "11px"
          }
        },
        {
          ...defaultColumn,
          Header: RowTypes.ALCANZADO,
          accessor: `${ColumnTypes.IMPORTE}.${RowTypes.ALCANZADO}`,
          headerStyle: {
            fontSize: "11px"
          },
          Cell: ({ value }) => <MexicanCurrency quantity={value} />
        },
        {
          ...defaultColumn,
          Header: "% ALCANZADO",
          accessor: `${ColumnTypes.IMPORTE}.${RowTypes.PORCENTAJE_ALCANZADO}`,
          headerStyle: {
            fontSize: "11px"
          }
        }
      ]
    },
    {
      Header: ColumnTypes.PROSPECTOS,
      columns: [
        {
          ...defaultColumn,
          Header: RowTypes.PRESUPUESTO,
          accessor: `${ColumnTypes.PROSPECTOS}.${RowTypes.PRESUPUESTO}`,
          headerStyle: {
            fontSize: "11px"
          }
        },
        {
          ...defaultColumn,
          Header: RowTypes.COMPROMISO,
          id: `${ColumnTypes.PROSPECTOS}-${RowTypes.COMPROMISO}`,
          accessor: () => (
            <InputCellTable
              disabled={disabled || error}
              value={tableData[0][ColumnTypes.PROSPECTOS][RowTypes.COMPROMISO]}
              onChange={setCompromises(ColumnTypes.PROSPECTOS)}
            />
          ),
          headerStyle: {
            fontSize: "11px"
          }
        },
        {
          ...defaultColumn,
          Header: RowTypes.ALCANZADO,
          accessor: `${ColumnTypes.PROSPECTOS}.${RowTypes.ALCANZADO}`,
          headerStyle: {
            fontSize: "11px"
          }
        },
        {
          ...defaultColumn,
          Header: "% ALCANZADO",
          accessor: `${ColumnTypes.PROSPECTOS}.${RowTypes.PORCENTAJE_ALCANZADO}`,
          headerStyle: {
            fontSize: "11px"
          }
        }
      ]
    }
  ];

  return (
    <Container className="mt-3 p-0">
      <Row>
        <Col>
          <p className={`primaryTitle w-100`}>{tableTitle}</p>
          <ReactTable
            data={tableData}
            loading={tableDataLoading}
            pageSize={1}
            columns={columns}
            sortable={false}
            resizable={false}
            showPagination={false}
            className="-highlight -striped"
            getTrProps={() => ({ style: { height: "30px" } })}
          />
        </Col>
      </Row>
    </Container>
  );
};
