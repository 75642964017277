import EmpresaConvenio from "../../../types/EmpresaConvenio";
import EmpresaConvenioInvitado from "../../../types/EmpresaConvenioInvitado";
import { Preview } from "../../../types/IAction";
import * as actionsConvenios from "../../actions/convenios";
import * as actions from "../../actions/empresaConvenio";
import * as actionsProfile from "../../actions/profile";
import { ConvenioClubesAsignables } from "../../reducers/convenios";
import {
  CreateEmpresaConvenio,
  GetEmpresaConvenio,
  GetEmpresaConvenioInvitado,
  UpdateEmpresaConvenio,
  UpdateEmpresaConvenioInvitado
} from "../../reducers/empresaConvenio";
import { GetUsers } from "../../reducers/user";

export interface EmpresaConvenioCarouselDispatcher {
  getCompany: (id: any) => Promise<Preview<GetEmpresaConvenio>>;
  createCompany: (
    company: EmpresaConvenio
  ) => Promise<Preview<CreateEmpresaConvenio>>;
  updateCompany: (
    company: EmpresaConvenio
  ) => Promise<Preview<UpdateEmpresaConvenio>>;
  getEmpresaConvenioInvitado: (
    id
  ) => Promise<Preview<GetEmpresaConvenioInvitado>>;
  putEmpresaConvenioInvitado: (
    company: EmpresaConvenioInvitado
  ) => Promise<Preview<UpdateEmpresaConvenioInvitado>>;
  getUsers: () => Promise<Preview<GetUsers>>;
  getClubesAsignables: () => Promise<Preview<ConvenioClubesAsignables>>;
}

export const empresaConvenioCarouselDispatcher: (
  dispatch: (...args) => Promise<Preview<any>>
) => EmpresaConvenioCarouselDispatcher = dispatch => ({
  getCompany: (id: any) => {
    return dispatch(actions.getCompanie(id));
  },
  updateCompany: (company: EmpresaConvenio) => {
    return dispatch(actions.updateCompanie(company.id, company));
  },
  createCompany: (company: EmpresaConvenio) => {
    return dispatch(actions.createCompanie(company));
  },
  getEmpresaConvenioInvitado: id => {
    return dispatch(actions.getEmpresaConvenioInvitado(id));
  },
  putEmpresaConvenioInvitado: (company: EmpresaConvenioInvitado) => {
    return dispatch(actions.putEmpresaConvenioInvitado(company.id, company));
  },
  getUsers: () => {
    return dispatch(actionsProfile.getUsers());
  },
  getClubesAsignables: () => {
    return dispatch(actionsConvenios.getClubesAsignables());
  }
});
