import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";

import {
  Button,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Container,
  UncontrolledTooltip
} from "reactstrap";
import s from "./styles.scss";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Constants from "../../../utils/constants";

interface Club {
  nombre: string;
  clubId: string;
  concepto: string;
  empresa: string;
}

interface Props {
  clubs: Club[];
  checkClub: (e, id) => any;
  selectAll: (e, value) => any;
  selectedClubs: object;
  visibleClubs: object;
  concepto: string;
  empresa: string;
  onChangeFilter: (key, value) => any;
  disabled: boolean;
}
const { CLUB_SELECTION } = Constants;
const {
  ALL_CONCEPTOS,
  ALL_EMPRESAS,
  ALL_CONCEPTOS_ID,
  ALL_EMPRESAS_ID
} = CLUB_SELECTION;
const CONCEPTOS = [
  ALL_CONCEPTOS,
  "SW",
  "GYM_PLUS",
  "LOAD",
  "ZUMA",
  "BTB",
  "GYM"
];

const getEmpresas = clubs => {
  const empresaCache = {};
  const empresas = [{ id: ALL_EMPRESAS_ID, nombre: ALL_EMPRESAS, key: -1 }];
  clubs.forEach(club => {
    const empresaId = club.empresa;
    const empresaNombre = club.empresaInfo.nombre;
    if (!empresaCache[empresaId]) {
      empresaCache[empresaId] = true;
      empresas.push({ id: empresaId, nombre: empresaNombre, key: empresaId });
    }
  });

  return empresas;
};

class ClubSelector extends React.Component<Props, null> {
  render() {
    return (
      <Container className="w-100 p-0 m-0">
        <Row>
          <Col />
          {this.props.clubs && (
            <Col>
              <Input
                name="club_selector_concepto"
                type="select"
                id="club_selector_concepto"
                value={this.props.concepto}
                onChange={value => this.props.onChangeFilter("concepto", value)}
              >
                {CONCEPTOS.map(opt => (
                  <option key={opt} value={opt}>
                    {opt.toLowerCase()}
                  </option>
                ))}
              </Input>
            </Col>
          )}
          <Col>
            <Input
              name="club_selector_empresa"
              type="select"
              id="club_selector_empresa"
              value={this.props.empresa}
              onChange={value => this.props.onChangeFilter("empresa", value)}
            >
              {this.props.clubs &&
                getEmpresas(this.props.clubs).map(opt => (
                  <option key={opt.key} value={opt.id}>
                    {(opt.nombre || "").toLowerCase()}
                  </option>
                ))}
            </Input>
          </Col>
          <Col xs="2">
            <Button
              id={"selectAll"}
              onClick={e => this.props.selectAll(e, true)}
              color="link"
              className="text-success"
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
            <Button
              id={"unSelectAll"}
              onClick={e => this.props.selectAll(e, false)}
              color="link"
              className="text-danger"
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>

            <UncontrolledTooltip placement="left" target={`selectAll`}>
              Selecionar todos
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="right" target={`unSelectAll`}>
              Deseleccionar todos
            </UncontrolledTooltip>
          </Col>
          <Col />
        </Row>
        <Row className={s.checkBoxesContainer}>
          {this.props.visibleClubs &&
            this.props.clubs.map(
              club =>
                this.props.visibleClubs[club.clubId] && (
                  <Col className={s.labelContainer} key={club.clubId} xs="3">
                    <FormGroup className={s.label} check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={!!this.props.selectedClubs[club.clubId]}
                          onChange={e => this.props.checkClub(e, club.clubId)}
                          disabled={this.props.disabled}
                        />
                        {club.nombre}
                      </Label>
                    </FormGroup>
                  </Col>
                )
            )}
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ClubSelector));
