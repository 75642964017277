import React from "react";
import { connect } from "react-redux";
import { getClassificationByLevel } from "../../actions/products";
import ComissionsSportsProduct from "../../components/comissionsSportsProducts";
import {
  saveSportsProductComissions,
  getSportsProductComissions,
  getSPComissionsCoordination,
  getSPComissionsOperation,
  getSportsProductOperationComissions,
  saveSportsProductOperationComissions,
  autorizeComissions
} from "../../actions/sportsProducts";
import { getPositions } from "../../actions/catalogs";
import IAction from "../../../types/IAction";

interface Props {
  onClickSave: (e: object) => any;
  saveSportsProductComissions: (id: string, fields: object) => IAction;
  getSportsProductComissions: (id: string) => any;
  getSPComissionsCoordination: (id: string) => any;
  getSPComissionsOperation: (id: string) => any;
  getSportsProductOperationComissions: (id: string) => any;
  generalConfigById: object;
  getBasicProduct: (id: string) => any;
  getPositions: () => any;
  basicDataSportsProduct: object;
  comissionsBySportsProductId: object;
  comissionsCoordinationBySportsProductId: object;
  comissionsOperationBySportsProductId: object;
  operationComissionsBySportsProductId: any;
  saveSportsProductOperationComissions: (body: any) => IAction;
  autorizeComissions: (id: string, tipo: string) => IAction;
  getClassificationByLevel: (level: number) => any;
  classificationByLevel: object[];
  catalogPostions: object[];
}

class ComissionsSportsProducts extends React.Component<Props, void> {
  componentDidMount() {
    this.props.getPositions();
  }

  render() {
    return (
      <ComissionsSportsProduct
        getClassificationByLevel={this.props.getClassificationByLevel}
        sportProduct={this.props.sportProduct}
        onClickSave={this.props.onClickSave}
        saveSportsProductComissions={this.props.saveSportsProductComissions}
        getSportsProductComissions={this.props.getSportsProductComissions}
        getSPComissionsCoordination={this.props.getSPComissionsCoordination}
        getSPComissionsOperation={this.props.getSPComissionsOperation}
        getSportsProductOperationComissions={
          this.props.getSportsProductOperationComissions
        }
        comissionsById={this.props.comissionsBySportsProductId}
        comissionsByIdCoordination={
          this.props.comissionsCoordinationBySportsProductId
        }
        comissionsByIdOperation={
          this.props.comissionsOperationBySportsProductId
        }
        operationComissionsById={
          this.props.operationComissionsBySportsProductId
        }
        saveSportsProductOperationComissions={
          this.props.saveSportsProductOperationComissions
        }
        autorizeComissions={this.props.autorizeComissions}
        classificationByLevel={this.props.classificationByLevel}
        catalogPostions={this.props.catalogPostions}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  saveSportsProductComissions: (id: string, fields: object) => {
    dispatch(saveSportsProductComissions(id, fields));
  },
  getSportsProductComissions: (id: string) => {
    dispatch(getSportsProductComissions(id));
  },
  getSPComissionsCoordination: (id: string) => {
    dispatch(getSPComissionsCoordination(id));
  },
  getSPComissionsOperation: (id: string) => {
    dispatch(getSPComissionsOperation(id));
  },
  getSportsProductOperationComissions: (id: string) => {
    dispatch(getSportsProductOperationComissions(id));
  },
  saveSportsProductOperationComissions: (body: any) => {
    dispatch(saveSportsProductOperationComissions(body));
  },
  autorizeComissions: (id: string, tipo: string) => {
    dispatch(autorizeComissions(id, tipo));
  },
  getClassificationByLevel: (level: number) => {
    return dispatch(getClassificationByLevel(level));
  },
  getPositions: () => {
    return dispatch(getPositions());
  }
});

const mapStateToProps = state => {
  const { sportsProducts, products, catalogs } = state;
  return {
    classificationByLevel: products.classificationByLevel,
    comissionsBySportsProductId: sportsProducts.comissionsBySportsProductId,
    comissionsCoordinationBySportsProductId:
      sportsProducts.comissionsCoordinationBySportsProductId,
    comissionsOperationBySportsProductId:
      sportsProducts.comissionsOperationBySportsProductId,
    operationComissionsBySportsProductId:
      sportsProducts.operationComissionsBySportsProductId,
    catalogPostions: catalogs.catalogPostions
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComissionsSportsProducts);
