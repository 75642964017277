import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import BaseInput from "../../../../toolkit/baseInput";
import BaseNumericInput from "../../../../toolkit/baseNumericInput";
import DatePicker from "../../common/DatePicker";
import s from "./styles.scss";
import { Button, Col, Row } from "reactstrap";
import moment from "moment";
import ProductClassification from "../../../containers/productClassification";

import { getSportsProductsFilteredRequest } from "../../../actions/catalogs";
import { handleRequestError } from "../../../../utils/helpers";

interface Props {
  classifications: any[];
  showCancelButton: boolean;
  fitkidz: boolean;
  addBenefit: (productBenefit: any) => void;
  cancelBenefit: () => void;
}

interface State {
  tmpBankSelected: object;
  tmpBankDiscount: string;
  tmpClassificationId: number;
  tmpClassificationDiscountPerc: string;
  tmpClassificationDiscountAmount: string;
  tmpClassificationNoDiscountCodes: string;
  tmpProductSelected: object;
  tmpClassificationInitdate: string;
  tmpClassificationFinaldate: string;
  //----------------------------------------
  tmpClassificationProducts: any[];
}

class ProductsBenefits extends React.Component<Props, State> {
  state = {
    tmpBankSelected: null,
    tmpBankDiscount: null,
    tmpClassificationId: null,
    tmpClassificationDiscountPerc: null,
    tmpClassificationDiscountAmount: null,
    tmpClassificationNoDiscountCodes: null,
    tmpProductSelected: null,
    tmpClassificationInitdate: null,
    tmpClassificationFinaldate: null,
    tmpClassificationProducts: []
  };

  onClassificationLevelChange = (
    classificationId: number,
    parents: number[],
    level: number
  ) => {
    this.setState({
      tmpClassificationId: classificationId
    });

    if (level == 3) {
      const filters = {
        clasificacionId: classificationId,
        nombre: "",
        estatus: "ACTIVO",
        carrito: true
      };

      getSportsProductsFilteredRequest(filters)
        .then(({ data }) => {
          this.setState({ tmpClassificationProducts: [...data] });
        })
        .catch(handleRequestError);
    } else {
      this.setState({ tmpClassificationProducts: [] });
    }
  };

  clearFieldsValues = () => {
    this.setState({
      tmpBankSelected: null,
      tmpBankDiscount: null,
      tmpClassificationId: null,
      tmpClassificationDiscountPerc: null,
      tmpClassificationDiscountAmount: null,
      tmpClassificationNoDiscountCodes: null,
      tmpProductSelected: null,
      tmpClassificationInitdate: null,
      tmpClassificationFinaldate: null,
      tmpClassificationProducts: []
    });
  };

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col xs="9">
            <ProductClassification
              setResult={this.onClassificationLevelChange}
            />
          </Col>
          <Col>
            <BaseInput
              type="select"
              label={"Producto"}
              name="productSelector"
              id="productSelector"
              value={this.state.tmpProductSelected}
              options={[{ value: null, label: "Seleccione" }].concat(
                (this.state.tmpClassificationProducts || []).map(a => {
                  return { value: a.id, label: a.nombre };
                })
              )}
              onChange={e => {
                this.setState({ tmpProductSelected: e.target.value });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <BaseNumericInput
              type="text"
              label={"% Descuento"}
              name="classificationDiscountPercentage"
              id="classificationDiscountPercentage"
              value={this.state.tmpClassificationDiscountPerc}
              disabled={!!this.state.tmpClassificationDiscountAmount}
              onChange={e => {
                this.setState({
                  tmpClassificationDiscountPerc: e.target.value,
                  tmpClassificationDiscountAmount: null
                });
              }}
            />
          </Col>
          <Col>
            <BaseNumericInput
              type="text"
              label={"$ Descuento"}
              name="classificationDiscountAmount"
              id="classificationDiscountAmount"
              value={this.state.tmpClassificationDiscountAmount}
              disabled={!!this.state.tmpClassificationDiscountPerc}
              onChange={e => {
                this.setState({
                  tmpClassificationDiscountAmount: e.target.value,
                  tmpClassificationDiscountPerc: null
                });
              }}
            />
          </Col>
          <Col>
            <BaseNumericInput
              type="text"
              label={"No. Códigos Descuento"}
              name="classificationNodiscountCodes"
              id="classificationNodiscountCodes"
              value={this.state.tmpClassificationNoDiscountCodes}
              onChange={e => {
                this.setState({
                  tmpClassificationNoDiscountCodes: e.target.value
                });
              }}
            />
          </Col>
          <Col>
            <DatePicker
              label="Inicio vigencia"
              name="fechaInicioVigencia"
              id="fechaInicioVigencia"
              selected={this.state.tmpClassificationInitdate}
              onChange={e => {
                this.setState({
                  tmpClassificationInitdate: moment(e).format("YYYY-MM-DD")
                });
              }}
            />
          </Col>
          <Col>
            <DatePicker
              label="Fin vigencia"
              name="fechaFinVigencia"
              id="fechaFinVigencia"
              selected={this.state.tmpClassificationFinaldate}
              onChange={e => {
                this.setState({
                  tmpClassificationFinaldate: moment(e).format("YYYY-MM-DD")
                });
              }}
            />
          </Col>
        </Row>
        <div className="text-right mt-3">
          <Button
            className={`mt-4 ${s.primaryButton} `}
            onClick={e => {
              e.preventDefault();
              const {
                tmpClassificationId,
                tmpClassificationDiscountPerc,
                tmpClassificationDiscountAmount,
                tmpClassificationNoDiscountCodes,
                tmpProductSelected,
                tmpClassificationInitdate,
                tmpClassificationFinaldate
              } = this.state;

              const clasif = this.props.classifications.find(
                x => x.id == tmpClassificationId
              );

              const producto = this.state.tmpClassificationProducts.find(
                x => x.id == tmpProductSelected
              );

              if (this.props.addBenefit) {
                this.props.addBenefit({
                  categoriaId: clasif.id,
                  categoriaDescripcion: clasif.descripcion,
                  porcentajeDescuento: tmpClassificationDiscountPerc,
                  montoDescuento: tmpClassificationDiscountAmount,
                  numeroCodigosDescuento: tmpClassificationNoDiscountCodes,
                  productoId: producto.id,
                  productoNombre: producto.nombre,
                  fechaInicioVigencia: tmpClassificationInitdate,
                  fechaFinVigencia: tmpClassificationFinaldate,
                  fitkidz: this.props.fitkidz || false
                });
              }

              this.clearFieldsValues();
            }}
            disabled={
              !this.state.tmpClassificationId ||
              !this.state.tmpClassificationNoDiscountCodes ||
              (!this.state.tmpClassificationDiscountPerc &&
                !this.state.tmpClassificationDiscountAmount)
            }
          >
            Agregar
          </Button>

          {!!this.props.showCancelButton && (
            <Button
              className={`mt-4 ml-3 ${s.secundaryButton} `}
              onClick={e => {
                e.preventDefault();
                this.clearFieldsValues();

                if (this.props.cancelBenefit) {
                  this.props.cancelBenefit();
                }
              }}
            >
              Cancelar
            </Button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(s)(ProductsBenefits);
