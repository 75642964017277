import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Button, Input, Label, Row, Col, Container } from "reactstrap";
import ModalFrame from "../../../../toolkit/modalFrame";
import BaseInput from "../../../../toolkit/baseInput";
import moment from "moment";
import ReactTable from "react-table";
import { getLocationByPostalCode } from "../../../helpers/LocationByPostalCode";
import MexicanCurrency from "../../../components/common/currency";
import { errorNotification } from "../../../../utils/notifications";
import { getBillingCatalogByKeyRequest } from "../../../actions/billing";
import {
  REGIMENES_RECEPTOR_CFDI40,
  USO_CFDI40
} from "../../prospectDetail/cfdi40Uso";
import IAction from "../../../../types/IAction";

interface Props {
  selPostOpen: boolean;
  toggle: () => any;
  savePosts: (prueba) => any;
  cantidadTotal: string;
  billing: any;
  timbradoUUID: string;
  createCreditNote: (fields: any) => IAction;
  banks: any;
}

interface State {
  searchFields: {
    razonSocial: string;
    rfc: string;
    calle: string;
    numero: string;
    colonia: string;
    cp: string;
    estado: string;
    municipio: string;
    nombre: string;
    segundoNombre: string;
    apellidoPaterno: string;
    apellidoMaterno: string;
    claveUsoCfdi: string;
    regimenFiscalReceptor: string;
    medioPago: string;
  };
  importeErroneo: {
    importeSaldoAnterior: string;
    importePagado: string;
    numeroTarjeta: string;
    nomTarjeta: string;
    importe: string;
    fechaOperacion: string;
  };
  sendToSave: boolean;
  coloniasOptions: object[];
  municipiosOptions: object[];
  estadosOptions: object[];
  changeData: boolean;
  errorData: boolean;
  setValue: boolean;
  setCorrectTime: boolean;
  arrayTime: string[];
  personaFisica: boolean;
  personaMoral: boolean;
  catalogUsiCfdi: any[];
  catalogRegimen: any[];
}

const formatTime = (dateTime?) =>
  moment(dateTime)
    .utc()
    .format("YYYY-MM-DD");

const USO_CFDI_CATALOG_KEY = "CatUsoCfdi";
const REGIMEN_RECEPTOR_CATALOG_KEY = "CatRegimenFiscalReceptor";
const USO_CFDI_DEFAULT = "P01";

const MEDIOS_PAGO_OPTIONS = [
  { value: "", label: "No cambiar" },
  { value: "01", label: "Efectivo" },
  { value: "02", label: "Cheque" },
  { value: "03", label: "Transferencia" },
  { value: "04", label: "Tarjeta de crédito o Amex" },
  { value: "28", label: "Tarjeta de débito" },
  { value: "06", label: "App o Línea de pago" },
  { value: "99", label: "Por definir" }
];

class PayComplementGenerationModal extends React.Component<Props, State> {
  state = {
    searchFields: {
      razonSocial: "",
      rfc: "",
      calle: "",
      numero: "",
      colonia: "",
      cp: "",
      estado: "",
      municipio: "",
      nombre: "",
      segundoNombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      claveUsoCfdi: "",
      regimenFiscalReceptor: "",
      medioPago: ""
    },
    importeErroneo: {
      importeSaldoAnterior: "",
      importePagado: "",
      numeroTarjeta: null,
      nomTarjeta: null,
      importe: null,
      fechaOperacion: null
    },
    sendToSave: false,
    coloniasOptions: [],
    municipiosOptions: [],
    estadosOptions: [],
    changeData: false,
    errorData: true,
    setValue: true,
    setCorrectTime: false,
    arrayTime: [],
    personaFisica: false,
    personaMoral: true,
    catalogUsiCfdi: [],
    catalogRegimen: []
  };

  componentDidMount() {
    getBillingCatalogByKeyRequest(USO_CFDI_CATALOG_KEY).then(
      ({ data: catalogUsiCfdi }) => {
        this.setState({ catalogUsiCfdi });
      }
    );
    getBillingCatalogByKeyRequest(REGIMEN_RECEPTOR_CATALOG_KEY).then(
      ({ data: catalogRegimen }) => {
        this.setState({ catalogRegimen });
      }
    );
  }

  componentDidUpdate() {
    if (
      this.state.setValue &&
      this.props.billing &&
      parseInt(this.props.billing.total) > 0
    ) {
      let importeErroneo = this.state.importeErroneo;
      importeErroneo.importeSaldoAnterior = this.props.billing.total;
      importeErroneo.importePagado = this.props.billing.total;
      this.setState({
        importeErroneo: importeErroneo,
        setValue: false
      });
    }
    if (this.props.banks.creditNoteCreated && this.state.sendToSave) {
      this.setState({ sendToSave: false });
      this.clearData();
    }
  }

  clearData = () => {
    this.setState({
      searchFields: {
        razonSocial: "",
        rfc: "",
        calle: "",
        numero: "",
        colonia: "",
        cp: "",
        estado: "",
        municipio: "",
        nombre: "",
        segundoNombre: "",
        apellidoPaterno: "",
        apellidoMaterno: "",
        claveUsoCfdi: "",
        regimenFiscalReceptor: "",
        medioPago: ""
      },
      importeErroneo: {
        importeSaldoAnterior: "",
        importePagado: "",
        numeroTarjeta: null,
        nomTarjeta: null,
        importe: null,
        fechaOperacion: null
      },
      changeData: false,
      errorData: true,
      personaFisica: false,
      personaMoral: true,
      setValue: true
    });
    this.props.toggle();
  };

  handleInitialDateChanged = () => {
    return dateTime => {
      let valSearchFields = this.state.importeErroneo;
      valSearchFields.fechaOperacion = formatTime(dateTime);
      this.setState({
        importeErroneo: valSearchFields
      });
    };
  };

  disabledButton = () => {
    let valReturn = true;
    if (this.state.changeData) {
      let revisionData = this.state.searchFields;
      if (this.state.personaFisica) {
        if (
          revisionData.rfc.length != 0 &&
          revisionData.nombre.length != 0 &&
          revisionData.apellidoPaterno.length != 0 &&
          revisionData.cp.length != 0 &&
          revisionData.calle.length != 0 &&
          revisionData.numero.length != 0 &&
          revisionData.colonia.length != 0 &&
          revisionData.municipio.length != 0 &&
          revisionData.estado.length != 0
        ) {
          valReturn = false;
        }
      } else if (this.state.personaMoral) {
        if (
          revisionData.rfc.length != 0 &&
          revisionData.razonSocial.length != 0 &&
          revisionData.cp.length != 0 &&
          revisionData.calle.length != 0 &&
          revisionData.numero.length != 0 &&
          revisionData.colonia.length != 0 &&
          revisionData.municipio.length != 0 &&
          revisionData.estado.length != 0
        ) {
          valReturn = false;
        }
      }
    } else if (this.state.errorData) {
      let valObject = Object.values(this.state.importeErroneo);
      if (!valObject.includes("")) {
        valReturn = false;
      }
    }
    return valReturn;
  };

  onChangeImporteErroneo = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const importeErroneo = {
          ...state.importeErroneo,
          [fieldName]: value
        };
        return { ...state, importeErroneo };
      });
    };
  };

  sendToEP = () => {
    return () => {
      // Validación del timbrado
      const uuid = this.props.timbradoUUID;
      const fields = this.state.searchFields;

      if (uuid == undefined || uuid == null) {
        errorNotification("Esta factura no cuenta con UUID");
        return;
      }

      // Cancelación por cobranza errónea
      if (!this.state.changeData) {
        let dataSend = {
          facturaUUID: uuid,
          importe: this.props.billing.total,
          causa: "COBRANZA_ERRONEA",
          tipo: "NOTA_CREDITO"
        };
        this.setState({ sendToSave: true });
        this.props.createCreditNote(dataSend);

        return;
      }

      // En otro caso, se validan datos actualizados
      // Nombre en persona física
      if (
        this.state.personaFisica &&
        (!fields.nombre || !fields.apellidoPaterno)
      ) {
        errorNotification("Ingrese el nombre de la persona física");
        return;
      }

      // Razón social
      if (this.state.personaMoral && !fields.razonSocial) {
        errorNotification("Ingrese la razón social");
        return;
      }

      // Otros campos
      if (!fields.claveUsoCfdi || !fields.regimenFiscalReceptor || !fields.cp) {
        errorNotification("Ingrese los campos obligatorios por favor");
        return;
      }

      // Si la dirección no está completa, se omite:
      const datosFiscales =
        !!fields.calle.length &&
        !!fields.numero.length &&
        !!fields.colonia.length &&
        !!fields.municipio.length &&
        !!fields.estado.length
          ? { ...fields }
          : {
              razonSocial: fields.razonSocial,
              rfc: fields.rfc,
              nombre: fields.nombre,
              segundoNombre: fields.segundoNombre,
              apellidoPaterno: fields.apellidoPaterno,
              apellidoMaterno: fields.apellidoMaterno,
              cp: fields.cp,
              claveUsoCfdi: fields.claveUsoCfdi,
              regimenFiscalReceptor: fields.regimenFiscalReceptor,
              medioPago: fields.medioPago
            };

      // Se crea la cancelación o nota de crédito
      this.setState({ sendToSave: true });
      this.props.createCreditNote({
        facturaUUID: uuid,
        importe: this.props.billing.total,
        causa: "DATOS_FISCALES",
        tipoPersona: this.state.personaFisica ? "FISICA" : "MORAL",
        tipo: "NOTA_CREDITO",
        datosFiscales
      });
    };
  };

  onChangeSearchFieldsFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const searchFields = {
          ...state.searchFields,
          [fieldName]: value
        };

        if (fieldName == "regimenFiscalReceptor") {
          searchFields.claveUsoCfdi = "";
        }

        return { ...state, searchFields };
      });
      if (fieldName === "cp") {
        this.handleChangeCodigoPostal(value);
      }
    };
  };

  onCheckDatosFiscales = () => {
    return event => {
      const value = !this.state.changeData;
      if (value) {
        this.setState({ changeData: value, errorData: false });
      } else {
        this.setState({ changeData: value });
      }
    };
  };

  onCheckCobranzaErronea = () => {
    return event => {
      const value = !this.state.errorData;
      if (value) {
        this.setState({ changeData: false, errorData: value });
      } else {
        this.setState({ errorData: value });
      }
    };
  };

  onChangePersona = () => {
    return event => {
      const personaFisica = !this.state.personaFisica;
      const personaMoral = !this.state.personaMoral;
      const searchFields = {
        ...this.state.searchFields,
        regimenFiscalReceptor: "",
        claveUsoCfdi: ""
      };

      this.setState({
        personaFisica,
        personaMoral,
        searchFields
      });
    };
  };

  showCurrency = quantity => (
    <p className="p-0 text-center w-100 m-0">
      <MexicanCurrency quantity={quantity} />
    </p>
  );

  addressDataUpdate = async address => {
    const coloniasNombre = {};
    const municipiosNombre = {};
    const estadosNombre = {};

    const colonias = [];
    const municipios = [];
    const estados = [];

    address.forEach(direccion => {
      const { nombre, municipio, estado } = direccion;
      if (!coloniasNombre[nombre]) {
        colonias.push({
          value: nombre,
          label: nombre
        });
        coloniasNombre[nombre] = true;
      }
      if (!municipiosNombre[municipio]) {
        municipios.push({
          value: municipio,
          label: municipio
        });
        municipiosNombre[municipio] = true;
      }
      if (!estadosNombre[estado]) {
        estados.push({
          value: estado,
          label: estado
        });
        estadosNombre[estado] = true;
      }
    });
    let cOptions = this.state.coloniasOptions;
    let mOptions = this.state.municipiosOptions;
    let eOptions = this.state.estadosOptions;
    cOptions = colonias;
    mOptions = municipios;
    eOptions = estados;

    this.setState(state => {
      return {
        ...state,
        coloniasOptions: cOptions,
        municipiosOptions: mOptions,
        estadosOptions: eOptions,
        tempAddress: address
      };
    });
  };

  handleChangeCodigoPostal = async value => {
    if (value.length == 5) {
      getLocationByPostalCode(value).then(res => this.addressDataUpdate(res));
    }
  };

  changeAddressCombos = address => {
    const coloniasNombre = {};
    const municipiosNombre = {};
    const estadosNombre = {};

    const colonias = [
      {
        value: "",
        label:
          address.length > 0
            ? "Selecciona la Colonia"
            : "Verifica el Código Postal"
      }
    ];
    const municipios = [
      {
        value: "",
        label:
          address.length > 0
            ? "Selecciona el Municipio"
            : "Verifica el Código Postal"
      }
    ];
    const estados = [
      {
        value: "",
        label:
          address.length > 0
            ? "Selecciona el Estado"
            : "Verifica el Código Postal"
      }
    ];

    address.forEach(direccion => {
      const { nombre, municipio, estado } = direccion;
      if (!coloniasNombre[nombre]) {
        colonias.push({
          value: nombre,
          label: nombre
        });
        coloniasNombre[nombre] = true;
      }
      if (!municipiosNombre[municipio]) {
        municipios.push({
          value: municipio,
          label: municipio
        });
        municipiosNombre[municipio] = true;
      }
      if (!estadosNombre[estado]) {
        estados.push({
          value: estado,
          label: estado
        });
        estadosNombre[estado] = true;
      }
    });
    let cOptions = this.state.coloniasOptions;
    let mOptions = this.state.municipiosOptions;
    let eOptions = this.state.estadosOptions;
    cOptions = colonias;
    mOptions = municipios;
    eOptions = estados;

    this.setState(state => ({
      ...state,
      coloniasOptions: cOptions,
      municipiosOptions: mOptions,
      estadosOptions: eOptions,
      tempAddress: address
    }));
  };

  columns = [
    {
      Header: () => <p className="font-weight-bold">Concepto</p>,
      id: 0,
      width: "400",
      accessor: "concepto",
      Cell: row => {
        return <span>{row.original.concepto}</span>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Cantidad</p>,
      id: 0,
      width: "100",
      accessor: "cantidad",
      className: "text-center"
    },
    {
      Header: () => <p className="font-weight-bold">Precio Unitario</p>,
      id: 0,
      width: "100",
      accessor: "precioUnitario",
      className: "text-center",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.precioUnitario} />;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Importe</p>,
      id: 0,
      width: "100",
      accessor: "importe",
      className: "text-center",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.importe} />;
      }
    }
  ];

  render() {
    const billing = this.props.billing;

    // TODO: Cambiar a catálogos
    const catRegimenFiscal =
      !this.state.personaFisica && !this.state.personaMoral
        ? [{ value: "", label: "Seleccione tipo de persona" }]
        : this.state.personaFisica
        ? [
            { value: "", label: "Seleccione" },
            ...REGIMENES_RECEPTOR_CFDI40.filter(rf => rf.fisica)
          ]
        : [
            { value: "", label: "Seleccione" },
            ...REGIMENES_RECEPTOR_CFDI40.filter(rf => rf.moral)
          ];

    const usos = (
      REGIMENES_RECEPTOR_CFDI40.filter(
        rf => (rf.value as any) == this.state.searchFields.regimenFiscalReceptor
      ).pop() || { usos: [] }
    ).usos;

    const catUsoCfdi40 = !this.state.searchFields.regimenFiscalReceptor
      ? [{ value: "", label: "Seleccione el régimen" }]
      : [
          { value: "", label: "Seleccione" },
          ...USO_CFDI40.filter(uso => usos.indexOf(uso.value) >= 0)
        ];

    return (
      <Container className="p-0">
        <ModalFrame
          title={`Solicitar Nota de Crédito - Cobranza Errónea`}
          isOpen={this.props.selPostOpen}
          toggle={this.clearData}
          size="lg"
        >
          {/*  <Row className={s.customDistancia}>
            <Col xs={12} md={12} lg={12}>
              <p className={s.grayTitle}>
                Criterio de aplicación para la nota de crédito
              </p>
            </Col>
            <Col xs={4} md={4} lg={4}>
              <Label className="float-right" check>
                <Input
                  type="checkbox"
                  checked={this.state.changeData}
                  onChange={this.onCheckDatosFiscales()}
                />
                Cambio de datos fiscales
              </Label>
            </Col>
            <Col xs={4} md={4} lg={4}>
              <Label className="float-right" check>
                <Input
                  type="checkbox"
                  checked={this.state.errorData}
                  onChange={this.onCheckCobranzaErronea()}
                />
                Cobranza err&oacute;nea
              </Label>
            </Col>
          </Row> */}

          {/*ZONA DE CAMBIO DE DATOS FISCALES*/}
          {this.state.changeData ? (
            <div>
              <Row className={s.customDistanciaCheck}>
                <Col xs={12} md={12} lg={12}>
                  <p className={s.grayTitle}>
                    Datos fiscales de la factura original
                  </p>
                </Col>
                <Col xs={8} md={8} lg={8}>
                  <BaseInput
                    type="text"
                    name="razonSocial01"
                    id="razonSocial01"
                    label={"Razon Social"}
                    placeholder="Razon Social"
                    value={
                      billing && billing.cliente && billing.cliente.rfc
                        ? billing.cliente.nombre == "VENTA AL PUBLICO"
                          ? billing.cliente.nombre
                          : `${billing.cliente.nombre || ""} ${billing.cliente
                              .segundoNombre || ""} ${billing.cliente
                              .apellidoPaterno || ""} ${billing.cliente
                              .apellidoMaterno || ""}`
                        : null
                    }
                    disabled={true}
                  />
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <BaseInput
                    type="text"
                    name="rfc01"
                    id="rfc01"
                    label={"RFC"}
                    placeholder="RFC"
                    value={
                      billing && billing.cliente
                        ? billing.cliente.rfc || ""
                        : null
                    }
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row className={s.customDistanciaCheck}>
                <Col xs={12} md={4} lg={4}>
                  <BaseInput
                    type="text"
                    label={"Calle"}
                    name="calle01"
                    id="calle01"
                    placeholder="Calle"
                    value={
                      billing &&
                      billing.cliente &&
                      billing.cliente.domicilioFiscal
                        ? `${billing.cliente.domicilioFiscal.calle ||
                            ""} ${billing.cliente.domicilioFiscal.noInterior ||
                            ""}`
                        : null
                    }
                    disabled={true}
                  />
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <BaseInput
                    type="text"
                    name="numero01"
                    id="numero01"
                    label={"Número"}
                    placeholder="Número"
                    value={
                      billing &&
                      billing.cliente &&
                      billing.cliente.domicilioFiscal
                        ? billing.cliente.domicilioFiscal.noExterior || ""
                        : null
                    }
                    disabled={true}
                    onChange={this.onChangeSearchFieldsFactory("numero01")}
                  />
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <BaseInput
                    type="text"
                    name="colonia01"
                    id="colonia01"
                    label={"Colonia"}
                    placeholder="Colonia"
                    value={
                      billing &&
                      billing.cliente &&
                      billing.cliente.domicilioFiscal
                        ? billing.cliente.domicilioFiscal.colonia || ""
                        : null
                    }
                    disabled={true}
                  />
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <BaseInput
                    type="text"
                    name="cp01"
                    id="cp01"
                    label={"CP"}
                    placeholder="CP"
                    value={
                      billing &&
                      billing.cliente &&
                      billing.cliente.domicilioFiscal
                        ? billing.cliente.domicilioFiscal.codigoPostal || ""
                        : null
                    }
                    disabled={true}
                  />
                </Col>

                <Col xs={12} md={4} lg={4}>
                  <BaseInput
                    type="text"
                    name="estado01"
                    id="estado01"
                    label={"Estado"}
                    placeholder="Estado"
                    value={
                      billing &&
                      billing.cliente &&
                      billing.cliente.domicilioFiscal
                        ? billing.cliente.domicilioFiscal.estado || ""
                        : null
                    }
                    disabled={true}
                  />
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <BaseInput
                    type="text"
                    name="municipio01"
                    id="municipio01"
                    label={"Municipio"}
                    placeholder="Municipio"
                    value={
                      billing &&
                      billing.cliente &&
                      billing.cliente.domicilioFiscal
                        ? billing.cliente.domicilioFiscal.municipio || ""
                        : null
                    }
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row className={s.customDistanciaCheck}>
                <Col xs={12} md={12} lg={12}>
                  <p className={s.grayTitle}>
                    Datos fiscales de la nueva factura
                  </p>
                </Col>
              </Row>
              <Row className={s.customDistancia}>
                <Col xs={4} md={4} lg={4}>
                  <Label className="float-right" check>
                    <Input
                      type="checkbox"
                      checked={this.state.personaMoral}
                      onChange={this.onChangePersona()}
                    />
                    Persona moral
                  </Label>
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <Label className="float-right" check>
                    <Input
                      type="checkbox"
                      checked={this.state.personaFisica}
                      onChange={this.onChangePersona()}
                    />
                    Persona física
                  </Label>
                </Col>
              </Row>
              {this.state.personaFisica ? (
                <Row>
                  <Col xs={12} md={4} lg={4}>
                    <BaseInput
                      type="text"
                      name="nombre"
                      id="nombre"
                      label={"* Nombre"}
                      placeholder="Nombre"
                      value={this.state.searchFields.nombre}
                      onChange={this.onChangeSearchFieldsFactory("nombre")}
                    />
                  </Col>
                  <Col xs={12} md={4} lg={4}>
                    <BaseInput
                      type="text"
                      name="segundoNombre"
                      id="segundoNombre"
                      label={"Segundo Nombre"}
                      placeholder="Segundo Nombre"
                      value={this.state.searchFields.segundoNombre}
                      onChange={this.onChangeSearchFieldsFactory(
                        "segundoNombre"
                      )}
                    />
                  </Col>
                  <Col xs={12} md={4} lg={4}>
                    <BaseInput
                      type="text"
                      name="apellidoPaterno"
                      id="apellidoPaterno"
                      label={"* Apellido Paterno"}
                      placeholder="Apellido Paterno"
                      value={this.state.searchFields.apellidoPaterno}
                      onChange={this.onChangeSearchFieldsFactory(
                        "apellidoPaterno"
                      )}
                    />
                  </Col>
                </Row>
              ) : null}
              <Row>
                {this.state.personaMoral ? (
                  <Col xs={12} md={8} lg={8}>
                    <BaseInput
                      type="text"
                      label={"* Razon Social"}
                      name="razonSocial"
                      id="razonSocial"
                      placeholder="Razon Social"
                      value={this.state.searchFields.razonSocial}
                      onChange={this.onChangeSearchFieldsFactory("razonSocial")}
                    />
                  </Col>
                ) : null}
                {this.state.personaFisica ? (
                  <Col xs={12} md={4} lg={4}>
                    <BaseInput
                      type="text"
                      name="apellidoMaterno"
                      id="apellidoMaterno"
                      label={"Apellido Materno"}
                      placeholder="Apellido Materno"
                      value={this.state.searchFields.apellidoMaterno}
                      onChange={this.onChangeSearchFieldsFactory(
                        "apellidoMaterno"
                      )}
                    />
                  </Col>
                ) : null}
                <Col xs={12} md={4} lg={4}>
                  <BaseInput
                    type="text"
                    name="rfc"
                    id="rfc"
                    label={"* RFC"}
                    placeholder="RFC"
                    value={this.state.searchFields.rfc}
                    onChange={this.onChangeSearchFieldsFactory("rfc")}
                  />
                </Col>
              </Row>
              <hr />
              <Row className={s.customDistanciaCheck}>
                <Col xs={12} md={4} lg={4}>
                  <BaseInput
                    label={"* Calle"}
                    name="calle"
                    type="text"
                    id="calle"
                    placeholder="Calle"
                    value={this.state.searchFields.calle}
                    // disabled={}
                    onChange={this.onChangeSearchFieldsFactory("calle")}
                  />
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <BaseInput
                    label={"* Número"}
                    name="numero"
                    type="text"
                    id="numero"
                    placeholder="Número"
                    value={this.state.searchFields.numero}
                    // disabled={}
                    onChange={this.onChangeSearchFieldsFactory("numero")}
                  />
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <BaseInput
                    label={"* CP"}
                    name="cp"
                    type="text"
                    id="cp"
                    placeholder="CP"
                    value={this.state.searchFields.cp}
                    // disabled={}
                    onChange={this.onChangeSearchFieldsFactory("cp")}
                  />
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <BaseInput
                    label={"* Estado"}
                    name="estado"
                    type="select"
                    id="estado"
                    placeholder="Estado"
                    value={this.state.searchFields.estado}
                    options={[
                      { value: "", label: "Selecciona el estado" }
                    ].concat(
                      this.state.estadosOptions.map(value => {
                        return {
                          value: value.label,
                          label: value.label
                        };
                      })
                    )}
                    // disabled={}
                    onChange={this.onChangeSearchFieldsFactory("estado")}
                  />
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <BaseInput
                    label={"* Municipio"}
                    name="municipio"
                    type="select"
                    id="municipio"
                    placeholder="Municipio"
                    value={this.state.searchFields.municipio}
                    options={[
                      { value: "", label: "Selecciona el municipio" }
                    ].concat(
                      this.state.municipiosOptions.map(value => {
                        return {
                          value: value.label,
                          label: value.label
                        };
                      })
                    )}
                    // disabled={}
                    onChange={this.onChangeSearchFieldsFactory("municipio")}
                  />
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <BaseInput
                    label={"* Colonia"}
                    name="colonia"
                    type="select"
                    id="colonia"
                    placeholder="Colonia"
                    value={this.state.searchFields.colonia}
                    // disabled={}
                    options={[
                      { value: "", label: "Selecciona la colonia" }
                    ].concat(
                      this.state.coloniasOptions.map(value => {
                        return {
                          value: value.label,
                          label: value.label
                        };
                      })
                    )}
                    onChange={this.onChangeSearchFieldsFactory("colonia")}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xs={12} md={4} lg={4}>
                  <BaseInput
                    type="select"
                    label="Forma de pago"
                    name="medioPago"
                    id="medioPago"
                    size={"sm"}
                    value={this.state.searchFields.medioPago}
                    options={MEDIOS_PAGO_OPTIONS}
                    onChange={this.onChangeSearchFieldsFactory("medioPago")}
                  />
                </Col>
                <Col xs={12} md={4} lg={4}>
                  <BaseInput
                    type="select"
                    label="* Régimen fiscal del receptor"
                    name="regimenFiscalReceptor"
                    id="regimenFiscalReceptor"
                    size={"sm"}
                    value={this.state.searchFields.regimenFiscalReceptor}
                    options={catRegimenFiscal}
                    onChange={this.onChangeSearchFieldsFactory(
                      "regimenFiscalReceptor"
                    )}
                  />
                </Col>

                <Col xs={12} md={4} lg={4}>
                  <BaseInput
                    type="select"
                    label="Clave uso CFDI"
                    name="claveUsoCFDI"
                    id="claveUsoCFDI"
                    size={"sm"}
                    value={this.state.searchFields.claveUsoCfdi}
                    options={catUsoCfdi40}
                    onChange={this.onChangeSearchFieldsFactory("claveUsoCfdi")}
                  />
                </Col>
              </Row>
            </div>
          ) : null}

          {/*--------------------- COBRANZA ERRONEA --------------------*/}
          {this.state.errorData ? (
            <div>
              <Row className={s.customDistanciaCheck}>
                <Col xs={12} md={12} lg={12}>
                  <p className={s.grayTitle}>
                    Datos de solicitud de devolucion
                  </p>
                </Col>
                <Col xs={6} md={6} lg={6}>
                  <BaseInput
                    label={"Numero de tarjeta"}
                    name="numeroTarjeta"
                    type="text"
                    id="numeroTarjeta"
                    placeholder="Numero de tarjeta"
                    value={this.state.importeErroneo.numeroTarjeta}
                    disabled={true}
                    onChange={this.onChangeImporteErroneo("numeroTarjeta")}
                  />
                </Col>
                <Col xs={6} md={6} lg={6}>
                  <BaseInput
                    label={"Nombre tarjetahabiente"}
                    name="nomTarjeta"
                    type="text"
                    id="nomTarjeta"
                    placeholder="Nombre tarjetahabiente"
                    value={this.state.importeErroneo.nomTarjeta}
                    disabled={true}
                    onChange={this.onChangeImporteErroneo("nomTarjeta")}
                  />
                </Col>
                <Col xs={6} md={6} lg={6}>
                  <BaseInput
                    label={"Importe"}
                    name="importe"
                    type="text"
                    id="importe"
                    placeholder="Importe"
                    value={
                      this.props.billing && this.props.billing.total
                        ? this.props.billing.total
                        : this.state.searchFields.importe
                    }
                    disabled={true}
                    onChange={this.onChangeImporteErroneo("importe")}
                  />
                </Col>
                <Col xs={6} md={6} lg={6}>
                  <BaseInput
                    label={"Fecha operación"}
                    name="fechaOperacion"
                    type="text"
                    id="fechaOperacion"
                    placeholder="Fecha operación"
                    value={this.state.importeErroneo.fechaOperacion}
                    disabled={true}
                    onChange={this.onChangeImporteErroneo("fechaOperacion")}
                  />
                </Col>
              </Row>
              <Row className={s.customDistanciaCheck}>
                <Col xs={12} md={12} lg={12}>
                  <p className={s.grayTitle}>Movimientos</p>
                  <ReactTable
                    data={
                      this.props.billing ? this.props.billing.items || [] : []
                    }
                    columns={this.columns}
                    className="mt-5 -striped -highlight"
                    sortable={false}
                    resizable={false}
                    showPagination={false}
                    pageSize={
                      (this.props.billing ? this.props.billing.items || [] : [])
                        .length + 1
                    }
                  />
                </Col>
              </Row>
            </div>
          ) : null}
          <hr />
          <Row className={`${s.customDistancia}`}>
            <Col xs={4} md={4} lg={4}>
              <Label className={s.label}>
                {`Monto total de factura origen:`}
              </Label>
              {this.props.billing && this.props.billing.total
                ? this.showCurrency(this.props.billing.total)
                : null}
            </Col>
          </Row>
          <Row className={`${s.customDistancia}`}>
            <Col xs={6} md={6} lg={6}>
              <Button
                block
                onClick={this.sendToEP()}
                disabled={this.disabledButton()}
              >
                ENVIAR SOLICITUD
              </Button>
            </Col>
            <Col xs={6} md={6} lg={6}>
              <Button block color="secondary" onClick={this.clearData}>
                NO ENVIAR
              </Button>
            </Col>
          </Row>
        </ModalFrame>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(PayComplementGenerationModal));
