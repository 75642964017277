import React from "react";
import ContextType from "./types/contextType";
import { ReactTableDefaults } from "react-table";
import moment from "moment";

moment.locale("es_MX");

Object.assign(ReactTableDefaults, {
  previousText: "Anterior",
  nextText: "Siguiente",
  loadingText: "Cargando...",
  noDataText: "No se encontró información",
  pageText: "",
  ofText: "de",
  rowsText: "filas"
});

// TODO abstract this common context
interface Props {
  context: {
    // Enables critical path CSS rendering
    // https://github.com/kriasoft/isomorphic-style-loader
    insertCss: any;
    // Universal HTTP client
    fetch: any;
    store: any;
  };
  //TODO
  children: any;
}

interface State {}

export default class App extends React.PureComponent<Props, State> {
  static childContextTypes = ContextType;

  getChildContext() {
    return this.props.context;
  }

  render() {
    return React.Children.only(this.props.children);
  }
}
