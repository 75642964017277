import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import PagedTable from "../common/PagedTable";
import { QueryResponse } from "../../../types/QueryResponse";
import { Container } from "reactstrap";

interface Props {
  roles: QueryResponse<Role>;
  searching: boolean;
  onClickNewRole: (role) => any;
  reloadRoles: boolean;
  fetchData: () => void;
  history: any;
}

interface Role {
  code: string;
  description: string;
}

class Roles extends React.Component<Props> {
  componentDidUpdate() {}

  handleEditRole = role => {
    this.props.onClickNewRole(role);
  };

  render() {
    const columns = [
      { name: "Id", id: "id" },
      { name: "Descripción", id: "description" },
      { name: "Código", id: "code" },
      {
        headerActions: [
          { name: "add", f: () => this.props.onClickNewRole(null) }
        ]
      }
    ];

    const actions = [{ name: "edit", f: this.handleEditRole }];

    return (
      <Container className="p-0 mt-3">
        <PagedTable
          data={this.props.roles}
          columns={columns}
          actions={actions}
          fetchData={this.props.fetchData}
          searching={this.props.searching}
        />
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(Roles));
