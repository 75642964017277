import React from "react";
import { Container, Row, Col, Button, Input } from "reactstrap";
import ReactTable from "react-table";
import moment from "moment";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { allowedTo } from "../../../../utils/accessTree";
import accessCodes from "../../../../utils/accessCodes";
import constants from "../../../../utils/constants";
import { PRICES_STATUS } from "../../../../types/MassiveAuthorizationPricesFilter";

const { AUTHORIZE_MEMBERSHIPS_PRICE } = accessCodes;
const formatDate = date => moment.utc(date).format("DD/MM/YYYY");

interface Props {
  renderEditable: () => any;
  renderMaximoDescuento: () => any;
  renderCuentaContableSelector: () => any;
  renderCuentaProductoSelector: () => any;
  authorize: () => any;
  handleSelectAll: () => any;
  data: object[];
  loading: boolean;
  filters: object;
}

class TablePriceDataMemberships extends React.Component<Props> {
  state = {
    selectAll: false
  };

  columns = [
    {
      Header: "Membresía",
      accessor: "nombreMembresia",
      width: 200
    },
    {
      Header: "U. Negocio",
      accessor: "club.nombre"
    },
    {
      id: "inicioVigencia",
      Header: "Inicio Vigencia",
      accessor: ({ inicioVigencia }) => formatDate(inicioVigencia)
    },
    {
      Header: _ => {
        return (
          <div className={`${s.checkboxSelectAll} w-100 text-center`}>
            <Input
              type="checkbox"
              checked={this.state.selectAll}
              onChange={e => {
                this.setState({ selectAll: !this.state.selectAll });
                this.props.handleSelectAll(!this.state.selectAll);
              }}
            />
            <a>* Precio</a>
          </div>
        );
      },
      accessor: "precio",
      Cell: this.props.renderEditable
    },
    {
      Header: "% Max. Descuento",
      accessor: "maximoDescuento",
      Cell: this.props.renderMaximoDescuento
    }
  ];

  render() {
    return (
      <Container fluid className="m-0 p-0">
        <Row className="mb-3">
          <Col className="text-center" xs={12}>
            {allowedTo(AUTHORIZE_MEMBERSHIPS_PRICE) && (
              <Button
                className={`${s.actionsButton}`}
                color="success"
                size="sm"
                onClick={this.props.authorize}
                disabled={
                  this.props.filters["estatusPrecio"] ===
                  PRICES_STATUS.PRICES_NO_AUTORIZADOS
                    ? this.props.data.length > 0
                      ? false
                      : true
                    : true
                }
              >
                Autorizar
              </Button>
            )}
          </Col>
        </Row>

        <Row className="mt-4 mb-3">
          <Col xs={12}>
            Marcar los precios a Guardar o Autorizar. (* Campos requeridos)
          </Col>
        </Row>

        {this.props.filters["estatusPrecio"] ===
          PRICES_STATUS.PRICES_NO_AUTORIZADOS && (
          <Row className={"my-4"}>
            <Col xs={6}>
              <div className={s.cellUnautorizedNom}></div> Pendientes por
              autorizar
            </Col>
            <Col xs={6}>
              <div className={s.cellEditNom}></div> Pendientes por guardar
            </Col>
          </Row>
        )}

        <ReactTable
          manual
          data={this.props.data}
          columns={this.columns}
          className="w-100 -striped -highlight"
          loading={this.props.loading}
          sortable={false}
          showPagination={false}
          style={{ minHeight: constants.TABLES_HEIGHT }}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(TablePriceDataMemberships));
