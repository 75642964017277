import axios from "axios";
import FileDownload from "js-file-download";

import IAction from "../../types/IAction";
import constants from "../../utils/constants";
import actionTypes from "../actionTypes";
import { errorNotification } from "../../utils/notifications";
const DEMANDS_BASE_URL = "/api/demands";

export const saveDemand = (data: object): IAction => {
  return {
    type: actionTypes.SAVE_DEMAND,
    payload: {
      promise: axios.post(`${constants.BASE_URL}${DEMANDS_BASE_URL}`, data, {
        withCredentials: true
      })
    }
  };
};

export const getTokenStatus = (token: string): IAction => {
  return {
    type: actionTypes.GET_TOKEN_FOLLOW_UP_SURVEY,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${DEMANDS_BASE_URL}/survey/token`,
        {
          withCredentials: true,
          params: { token }
        }
      )
    }
  };
};

export const saveSurvey = (data: object): IAction => {
  return {
    type: actionTypes.SAVE_FOLLOW_UP_SURVEY,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${DEMANDS_BASE_URL}/survey`,
        data,
        {}
      )
    }
  };
};

export const updateDemand = (id: number, data: object): IAction => {
  return {
    type: actionTypes.UPDATE_DEMAND,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${DEMANDS_BASE_URL}/${id}`,
        data,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getClassificationByLevel = (level: number): IAction => {
  return {
    type: actionTypes.GET_PRODUCTS_CLASSIFICATION_BY_LEVEL,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${DEMANDS_BASE_URL}/classification`,
        {
          withCredentials: true,
          params: { nivel: level }
        }
      )
    }
  };
};

export const getDemands = (params: object): IAction => {
  return {
    type: actionTypes.GET_DEMANDS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${DEMANDS_BASE_URL}`, {
        withCredentials: true,
        params
      })
    }
  };
};

export const getDemandById = (id: number): IAction => {
  return {
    type: actionTypes.GET_DEMAND_BY_ID,
    payload:
      id == -1
        ? {
            data: null
          }
        : {
            promise: axios.get(
              `${constants.BASE_URL}${DEMANDS_BASE_URL}/${id}`,
              {
                withCredentials: true
              }
            )
          }
  };
};

export const saveObservation = (id: number, observacion: object): IAction => {
  return {
    type: actionTypes.SAVE_DEMAND_OBSERVATION,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${DEMANDS_BASE_URL}/${id}/addObservation`,
        observacion,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const saveDetails = (id: number, details: object): IAction => {
  return {
    type: actionTypes.SAVE_DEMAND_DETAILS,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${DEMANDS_BASE_URL}/${id}/addDetails`,
        details,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getDemandsFileDownload = (filters: object, _callback) => {
  const url = `${constants.BASE_URL}${DEMANDS_BASE_URL}/file`;
  const config = {
    withCredentials: true,
    params: { ...filters },
    responseType: "blob"
  };

  return axios.get(url, config).then(({ data }) => {
    if (!!_callback) {
      _callback();
    }

    FileDownload(data, "QuejasYSugerencias.csv");
  });
};

export const getDemandsFileAxios = (params, callback) => {
  return axios
    .get(`${constants.BASE_URL}${DEMANDS_BASE_URL}/file`, {
      withCredentials: true,
      responseType: "blob",
      params
    })
    .then(({ data }) => {
      FileDownload(data, "demands.csv");
      callback && callback();
    })
    .catch(err => {
      callback && callback();

      const msg = "No se pudo descargar el archivo";
      if (err && err.response && err.response.data) {
        err.response.data.text().then(t => {
          try {
            const message = JSON.parse(t).message || msg;
            errorNotification(message);
          } catch (erj) {
            errorNotification(msg);
          }
        });
      } else {
        errorNotification(msg);
      }
    });
};

export const getDemandsClassificationsFiiltered = (
  type: string,
  level: number,
  parentId: number,
  full?: boolean
) => {
  return axios.get(
    `${constants.BASE_URL}${DEMANDS_BASE_URL}/classifications/filtered`,
    {
      withCredentials: true,
      params: {
        tipo: type,
        nivel: level,
        padreId: parentId,
        full: !!full
      }
    }
  );
};

export const createDemandClassificationNode = (data: object) => {
  return axios.post(
    `${constants.BASE_URL}${DEMANDS_BASE_URL}/classifications/create`,
    data,
    {
      withCredentials: true
    }
  );
};

export const updateDemandClassificationNode = (data: object) => {
  return axios.put(
    `${constants.BASE_URL}${DEMANDS_BASE_URL}/classifications/update`,
    data,
    {
      withCredentials: true
    }
  );
};

export const getDemandEvidencesRequest = demandId => {
  return axios.get(
    `${constants.BASE_URL}${DEMANDS_BASE_URL}/${demandId}/evidences`,
    {
      withCredentials: true
    }
  );
};

export const getDemandEvidenceByIdRequest = (demandId, evidenceId) => {
  return axios.get(
    `${constants.BASE_URL}${DEMANDS_BASE_URL}/${demandId}/evidences/${evidenceId}`,
    {
      withCredentials: true
    }
  );
};

export const createDemandEvidenceRequest = (demandId, data: object) => {
  return axios.post(
    `${constants.BASE_URL}${DEMANDS_BASE_URL}/${demandId}/evidences`,
    data,
    {
      withCredentials: true
    }
  );
};
