import React from "react";
import { TabPane, Container, Row, Col } from "reactstrap";
import Survey from "../../components/surveys";
import BaseInput from "../../../toolkit/baseInput";
import {
  handleRequestError,
  parseArrayToDropdownOptions
} from "../../../utils/helpers";
import { getSurveysRequest } from "../../actions/surveys";
import { Member } from "./interfaces";

interface Props {
  idMembresiaSocio: String;
  surveyTypes: any[];
  members: Member[];
  saveAnswers: (answers: object) => any;
}

class TabSurveys extends React.Component<Props> {
  state = {
    filters: {
      integrante: "",
      survey: ""
    },
    surveys: null
  };

  onChangeFilter = (e, fieldName) => {
    const value = e.target.value;
    this.setState(state => {
      const filters = {
        ...this.state.filters,
        [fieldName]: value
      };
      if (filters.survey != "" && filters.integrante != "")
        this.getSurveys(filters);
      return { ...state, filters };
    });
  };

  getSurveys = filters => {
    getSurveysRequest(filters)
      .then(({ data }) => {
        this.setState({ surveys: data });
      })
      .catch(handleRequestError);
  };

  render() {
    return (
      <TabPane className="my-2" tabId="3">
        <Container className="p-0 m-0">
          <Row className="p-0 m-0">
            <Col className="pl-0">
              <BaseInput
                type="select"
                id="survey"
                name="survey"
                label="Encuesta"
                onChange={e => this.onChangeFilter(e, "survey")}
                options={parseArrayToDropdownOptions(
                  this.props.surveyTypes || [],
                  "value",
                  "nombre"
                )}
              />
            </Col>
            <Col className="pr-0">
              <BaseInput
                type="select"
                id="integrante"
                name="integrante"
                label="Integrante"
                onChange={e => this.onChangeFilter(e, "integrante")}
                options={parseArrayToDropdownOptions(
                  this.props.members || [],
                  "idPersona",
                  "nombrePersona"
                )}
              />
            </Col>
          </Row>
        </Container>
        {this.state.surveys && (
          <Survey
            idMembresiaSocio={this.props.idMembresiaSocio}
            surveys={this.state.surveys}
            idPersona={this.state.filters.integrante}
            saveAnswers={this.props.saveAnswers}
          />
        )}
      </TabPane>
    );
  }
}
export default TabSurveys;
