import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import ModalFrame from "../../../toolkit/modalFrame";
import moment from "moment";
import {
  Container,
  Row,
  Col,
  Label,
  Form,
  FormGroup,
  FormText
} from "reactstrap";
import ReactTable from "react-table";
import s from "./styles.scss";
import BaseInput from "../../../toolkit/baseInput";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  promotion: object;
  getPromotionDetailById: (id: string) => void;
  promotionDetail: PromotionDetail;
}

interface PromotionDetail {
  nombre: string;
  description: string;
  condicionesLegales: string;
  inicioVigencia: string;
  finVigencia: string;
  maximoSocios: string;
  empresa: string;
  estatus: string;
  membresias: object[];
  mantenimientos: object[];
  clubs: object[];
}

class PromotionDetail extends React.Component<Props> {
  state = {
    columnsMemberships: [{ Header: "Membresias", accessor: "nombre" }],
    columnsMaintenances: [{ Header: "Mantenimientos", accessor: "nombre" }],
    columnsClubs: [{ Header: "Club", accessor: "nombre" }]
  };

  removeTags = text =>
    text === null || text === "" ? "" : text.toString().replace(/<[^>]*>/g, "");

  componentWillReceiveProps(nextProps) {
    if (nextProps.promotion && nextProps.isOpen && !nextProps.promotionDetail) {
      this.props.getPromotionDetailById(nextProps.promotion.id);
    }
  }
  render() {
    return (
      <ModalFrame
        title={`Promoción ${
          this.props.promotionDetail ? this.props.promotionDetail.nombre : ""
        }`}
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        size="lg"
      >
        <Container>
          {this.props.promotionDetail && (
            <Form>
              <Row>
                <Col className="pb-2">
                  <p className={`${s.primaryTitle} w-100 m-0`}>Datos Básicos</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <BaseInput
                    id="promotionDetailName"
                    name="promotionDetailName"
                    type="text"
                    label={"Nombre"}
                    value={this.props.promotionDetail.nombre}
                    disabled={true}
                  />
                </Col>
                <Col>
                  <BaseInput
                    id="promotionDetailInicioVig"
                    name="promotionDetailInicioVig"
                    type="text"
                    label={"Inicio de vigencia"}
                    value={moment(
                      this.props.promotionDetail.inicioVigencia
                    ).format("YYYY-MM-DD")}
                    disabled={true}
                  />
                </Col>
                <Col>
                  <BaseInput
                    id="promotionDetailFinVig"
                    name="promotionDetailFinVig"
                    type="text"
                    label={"Fin de vigencia"}
                    value={moment(
                      this.props.promotionDetail.finVigencia
                    ).format("YYYY-MM-DD")}
                    disabled={true}
                  />
                </Col>
                <Col>
                  <BaseInput
                    id="promotionDetailEstatus"
                    name="promotionDetailEstatus"
                    type="text"
                    label={"Estatus"}
                    value={this.props.promotionDetail.estatus}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label style={{ fontWeight: "bold" }}>Descripción:</Label>
                    <FormText color="muted">
                      {this.removeTags(this.props.promotionDetail.description)}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label style={{ fontWeight: "bold" }}>
                      Condiciones Legales:
                    </Label>
                    <FormText color="muted">
                      {this.removeTags(
                        this.props.promotionDetail.condicionesLegales
                      )}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col
                  className={s.tableContainers}
                  xs={12}
                  md={6}
                  lg={4}
                  style={{ height: "300px" }}
                >
                  <Container className="h-100">
                    <p className={`${s.primaryTitle} w-100 m-0 mb-1`}>Clubs</p>
                    <ReactTable
                      manual
                      data={this.props.promotionDetail.clubs}
                      columns={this.state.columnsClubs}
                      className="w-100 -striped -highlight"
                      loading={false}
                      sortable={false}
                      showPagination={false}
                      defaultPageSize={3}
                      style={{ height: "250px" }}
                    />
                  </Container>
                </Col>

                <Col
                  className={s.tableContainers}
                  xs={12}
                  md={6}
                  lg={4}
                  style={{ height: "300px" }}
                >
                  <Container className="h-100">
                    <p className={`${s.primaryTitle} w-100 m-0 mb-1`}>
                      Membresias
                    </p>
                    <ReactTable
                      manual
                      data={this.props.promotionDetail.membresias}
                      columns={this.state.columnsMemberships}
                      className="w-100 -striped -highlight"
                      loading={false}
                      sortable={false}
                      showPagination={false}
                      defaultPageSize={3}
                      style={{ height: "250px" }}
                    />
                  </Container>
                </Col>

                <Col
                  className={s.tableContainers}
                  xs={12}
                  md={6}
                  lg={4}
                  style={{ height: "300px" }}
                >
                  <Container className="h-100">
                    <p className={`${s.primaryTitle} w-100 m-0 mb-1`}>
                      Mantenimientos
                    </p>
                    <ReactTable
                      manual
                      data={this.props.promotionDetail.mantenimientos}
                      columns={this.state.columnsMaintenances}
                      className="w-100 -striped -highlight"
                      loading={false}
                      sortable={false}
                      showPagination={false}
                      defaultPageSize={3}
                      style={{ height: "250px" }}
                    />
                  </Container>
                </Col>
              </Row>
            </Form>
          )}
        </Container>
      </ModalFrame>
    );
  }
}

export default withRouter(withStyles(s)(PromotionDetail));
