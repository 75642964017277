import React from "react";
import { connect } from "react-redux";
import { getClassificationByLevel } from "../../actions/products";
import ProductClassificationComponent from "./ClassificationComponent";

interface Props {
  getClassificationByLevel: (level: number) => any;
  setResult: (classification: number, parents: number[]) => any;
  result: number;
  fitKidz: boolean;
  groupUser: string[];
}

class ProductClassificationContainer extends React.Component<Props> {
  render() {
    return (
      <ProductClassificationComponent
        getClassificationByLevel={this.props.getClassificationByLevel}
        setResult={this.props.setResult}
        result={this.props.result}
        fitKidz={this.props.fitKidz}
        groupUser={this.props.groupUser}
      ></ProductClassificationComponent>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getClassificationByLevel: (level: number) => {
    return dispatch(getClassificationByLevel(level));
  }
});

const mapStateToProps = _ => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductClassificationContainer);
