import React from "react";
import { connect } from "react-redux";
import Layout from "../../components/layout";
import { Helmet } from "react-helmet";
import MembershipSoldReportPage from "../../components/membershipSoldReport";
import { getClubs, getSellers } from "../../actions/catalogs";
import { getPeriods } from "../../actions/periods";
import { FILTERS } from "../../components/membershipSoldReport/interfaces";
import {
  getMembershipSoldReport,
  sendReasignMembership,
  getPastPeriods,
  clearReasignMembership
} from "../../actions/reports";
import moment from "moment";
const PAST_PERIODS_TO_GET = 12;

interface Props {
  clubs: object;
  periods: object;
  periodById: object;
  reportMembershipSold: object[];
  sellers: object[];
  reasignMembership: boolean;
  getClubs: () => any;
  getPeriods: () => void;
  getPastPeriods: (limite: string, cantidad: number) => any;
  getSellers: () => void;
  getMembershipSoldReport: (filters: FILTERS) => void;
  sendReasignMembership: (filters: object) => void;
  clearReasignMembership: () => void;
}

interface State {}

class MembershipSoldReport extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    this.props.getClubs();
    this.props.getSellers();
    this.props.getPastPeriods(
      moment()
        .add(15, "days")
        .format("YYYY-MM-DD"),
      PAST_PERIODS_TO_GET
    );
  }

  componentDidUpdate() {}

  render() {
    return (
      <main>
        <Layout>
          <Helmet title="Reporte de ventas de membresías" />
          {this.props.clubs && this.props.periods && (
            <MembershipSoldReportPage
              clubs={this.props.clubs}
              periods={this.props.periods}
              reportMembershipSold={this.props.reportMembershipSold}
              sellers={this.props.sellers}
              getSchemasByPeriod={this.props.getPeriods}
              getMembershipSoldReport={this.props.getMembershipSoldReport}
              sendReasignMembership={this.props.sendReasignMembership}
              reasignMembership={this.props.reasignMembership}
              clearReasignMembership={this.props.clearReasignMembership}
            />
          )}
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getClubs: () => {
    return dispatch(getClubs());
  },
  getPeriods: () => {
    return dispatch(getPeriods());
  },

  getPastPeriods: (limite, cantidad) => {
    return dispatch(getPastPeriods(limite, cantidad));
  },

  getMembershipSoldReport: (filters: FILTERS) => {
    return dispatch(getMembershipSoldReport(filters));
  },
  sendReasignMembership: (filters: FILTERS) => {
    return dispatch(sendReasignMembership(filters));
  },
  getSellers: () => {
    return dispatch(getSellers());
  },
  clearReasignMembership: () => {
    return dispatch(clearReasignMembership());
  }
});

const mapStateToProps = state => {
  const {
    catalogs: { clubs, sellers },
    periods: { periods, periodById },
    reports: { reportMembershipSold, reasignMembership }
  } = state;
  return {
    clubs,
    periods,
    sellers,
    periodById,
    reportMembershipSold,
    reasignMembership
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MembershipSoldReport);
