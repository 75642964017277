import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import ReportCustomizableReceipts from "../../components/reportCustomizableReceipts";
import * as catalogActions from "../../actions/catalogs";
import * as reportsActions from "../../actions/reports";
import IAction from "../../../types/IAction";
import { Club } from "../../../types-business/Clubs";
import { getClubsRequest } from "../../actions/catalogs";
import { handleRequestError } from "../../../utils/helpers";

interface Props {
  getCustomizableReceipt: (
    clubId: string,
    fechaInicio: string,
    fechaFin: string,
    estatus: string
  ) => IAction;
  downloadCustomizableReceipt: (body: object) => IAction;
  refuseCustomizableReceipt: (reciboProvicionalId: string) => IAction;
  validateCustomizableReceipt: (reciboProvicionalId: string) => IAction;
  reportCustomizableReceipt: any;
  objRFCR: any;
  objVCR: any;
  clearReasignMembership: () => IAction;
  pdfProvitionalReceipt: (reciboProvisionalId: string) => IAction;
}

interface State {
  clubs: Club[];
}

class CustomizableReceipts extends React.Component<Props, State> {
  state = {
    clubs: []
  };

  componentDidMount() {
    this.getClubs();
  }

  componentWillUnmount() {
    this.props.clearReasignMembership();
  }

  getClubs = () => {
    getClubsRequest()
      .then(({ data }) => {
        this.setState({ clubs: (data || []).filter(x => !!x.migradoCrm2) });
      })
      .catch(handleRequestError);
  };

  render() {
    return (
      <main>
        <Helmet title="Shift delivery" />
        <Layout>
          <ReportCustomizableReceipts
            clubs={this.state.clubs || []}
            reportCustomizableReceipt={this.props.reportCustomizableReceipt}
            getCustomizableReceipt={this.props.getCustomizableReceipt}
            downloadCustomizableReceipt={this.props.downloadCustomizableReceipt}
            refuseCustomizableReceipt={this.props.refuseCustomizableReceipt}
            validateCustomizableReceipt={this.props.validateCustomizableReceipt}
            objRFCR={this.props.objRFCR}
            objVCR={this.props.objVCR}
            pdfProvitionalReceipt={this.props.pdfProvitionalReceipt}
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({ reports }) => {
  return {
    reportCustomizableReceipt: reports.reportCustomizableReceipt,
    objRFCR: reports.objRFCR,
    objVCR: reports.objVCR
  };
};

const mapDispatchToProps = dispatch => ({
  getCustomizableReceipt: (
    clubId: string,
    fechaInicio: string,
    fechaFin: string,
    estatus: string
  ) => {
    dispatch(
      reportsActions.getCustomizableReceipt(
        clubId,
        fechaInicio,
        fechaFin,
        estatus
      )
    );
  },
  downloadCustomizableReceipt: (body: object) => {
    dispatch(reportsActions.downloadCustomizableReceipt(body));
  },
  refuseCustomizableReceipt: (reciboProvicionalId: string) => {
    dispatch(reportsActions.refuseCustomizableReceipt(reciboProvicionalId));
  },
  validateCustomizableReceipt: (reciboProvicionalId: string) => {
    dispatch(reportsActions.validateCustomizableReceipt(reciboProvicionalId));
  },
  clearReasignMembership: () => {
    dispatch(reportsActions.clearReasignMembership());
  },
  pdfProvitionalReceipt: (reciboProvisionalId: string) => {
    dispatch(reportsActions.pdfProvitionalReceipt(reciboProvisionalId));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomizableReceipts);
