import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import ReactTable from "react-table";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import equal from "lodash.isequal";
import IAction from "../../../types/IAction";
import PersonSelector from "../../../People/containers/PersonSelector";
import { QueryResponse } from "../../../types/QueryResponse";
import { QueryArguments } from "../../../types-business/QueryArguments";

import s from "./styles.scss";
import ModalFrame from "../../../toolkit/modalFrame";

interface Person {
  personaId: number;
  nombre: string;
  primerApellido: string;
  tipo: string;
}

interface Evento {
  representanteId: number;
  nombreRepresentante: string;
  clubId: number;
  club: string;
  fechaInicio: string;
  fechaFin: string;
  maximoParticipantes: number;
  sesionesRestantes: number;
  participantes: any;
  clases: any;
  tipoEvento: string;
  evento: string;
}

interface Props {
  clubs: any;
  products: QueryResponse<Product>;
  searching: boolean;
  removeSession: (product) => IAction;
  onClickNewProduct: (product) => IAction;
  reloadProducts: boolean;
  fetchData: () => void;
  history: any;
  match: any;
  filters: Filters;
  onChangeFilter: any;
  getEvents: (clubId: string, args?: QueryArguments) => IAction;
  sportsProducts: [{ id: string; nombre: string }];
  events: object[];
  eventDetails: Evento;
  getDetails: (qs: object) => IAction;
  addAttendant: (productId: string, attendantId: string) => IAction;
  deleteAttendantOnEvent: (attendantId: string) => IAction;
  deleteSession: (body: object) => IAction;
  newAttendant: boolean;
  toggleEditSession: (edit?: boolean) => IAction;
  newSessionProductId: (id: number) => IAction;
  sellers: any[];
}

interface Filters {
  clasificacionId: string;
  page: number;
  size: number;
}

interface Product {
  id: string;
  clasificacionId: string;
  nombre: string;
  estatus: string;
  publicarPrecio: boolean;
  fechaInicioVigencia: string;
  fechaFinVigencia: string;
  descripcion: string;
}

interface State {
  selectedClub: string;
  selectedPerson: Person;
  modal: boolean;
  openModal: boolean;
  openModalQuestion: boolean;
  deleteEvent: object;
  eventClasses: any;
}

class SportSession extends React.Component<Props, State> {
  state = {
    selectedPerson: null,
    selectedClub: "",
    modal: false,
    openModal: false,
    openModalQuestion: false,
    deleteEvent: null,
    eventClasses: []
  };

  search = () => {
    this.props.getEvents(this.state.selectedClub, this.props.filters);
  };

  deleteAttendant = attendant => {
    this.props.deleteAttendantOnEvent(attendant.participanteId);
  };

  setPerson = async (selectedPerson: Person) => {
    await this.setState({
      selectedPerson
    });
    this.props.addAttendant(
      this.props.match.params.productId,
      this.state.selectedPerson.personaId
    );
  };

  componentDidMount() {
    this.props.getDetails({
      productoInstanciaId: this.props.match.params.productId,
      participanteId: this.props.match.params.personaId
    });
  }

  componentDidUpdate(prevProps) {
    if (!equal(this.props.eventDetails, prevProps.eventDetails)) {
      this.updateSessions();
    }
  }

  updateSessions() {
    this.setState({
      eventClasses: this.props.eventDetails.clases
    });
  }

  toggleModal = () => {
    this.setState({ openModal: !this.state.openModal });
  };

  toggleModalQuestion = () => {
    this.setState({ openModalQuestion: !this.state.openModalQuestion });
  };

  handleDeleteSession = () => {
    let deleteEventClass = this.state.deleteEvent;
    deleteEventClass.esActivo = false;
    this.props.deleteSession(deleteEventClass);
    this.toggleModalQuestion();
  };

  deleteEvent = session => {
    this.setState({ openModalQuestion: true, deleteEvent: session });
  };

  render() {
    const { eventDetails } = this.props;
    const attendantColumns = [
      { Header: "Participante", accessor: "nombre" },
      { Header: "Fecha de Inicio", accessor: "fechaInicio" },
      {
        Header: "",
        id: "buttons",
        accessor: attendant => {
          return attendant.participanteId ===
            eventDetails.representanteId ? null : (
            <a
              id={`${attendant.productoId}`}
              onClick={() => {
                this.deleteAttendant(attendant);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </a>
          );
        }
      }
    ];
    const clasesColumns = [
      { Header: "Fecha", accessor: "fechaClase" },
      { Header: "Hora", accessor: "horaClase" },
      {
        Header: "Instructor",
        id: "instructor",
        accessor: session => {
          const sellers = this.props.sellers;
          const instructor = session.instructorId;
          const seller =
            sellers &&
            instructor >= 0 &&
            sellers.find(element => element.userId == session.instructorId);

          if (seller) {
            const userWithPosition =
              seller &&
              seller.userPositions &&
              seller.userPositions.length &&
              true;
            return (
              <>
                <div
                  style={{
                    color: userWithPosition ? null : "#700",
                    textDecoration: userWithPosition ? null : "underline"
                  }}
                >
                  {seller.name}
                </div>
                <div
                  hidden={userWithPosition}
                  style={{
                    color: userWithPosition ? null : "#700",
                    fontSize: "0.8em"
                  }}
                >
                  Este empleado no puede recibir comisiones
                </div>
              </>
            );
          }

          return "Cargando...";
        }
      },
      { Header: "Estatus", accessor: "estatus" },
      {
        Header: () => {
          return (
            <a
              onClick={() => {
                this.props.newSessionProductId(
                  this.props.match.params.productId
                );
                this.props.toggleEditSession();
              }}
            >
              +
            </a>
          );
        },
        id: "buttons",
        accessor: session => {
          return (
            <Row>
              <Col className={s.marginLeftNil} xs={2}>
                <a
                  id={`${session.productoId}`}
                  onClick={() => {
                    this.deleteEvent(session);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </a>
              </Col>
            </Row>
          );
        }
      }
    ];
    return (
      <Container className="p-0 mt-3">
        <ModalFrame
          title={"Eliminar Clase"}
          isOpen={this.state.openModalQuestion}
          toggle={this.toggleModalQuestion}
        >
          <Container>
            <Row>
              <p className={`text-center w-100`}>
                ¿Está seguro que desea eliminar la clase con fecha
                <span className="text-capitalize font-weight-bold">
                  {" "}
                  {this.state.deleteEvent != null
                    ? this.state.deleteEvent.fechaClase
                    : " "}{" "}
                </span>{" "}
                y hora
                <span className="text-capitalize font-weight-bold">
                  {" "}
                  {this.state.deleteEvent != null
                    ? this.state.deleteEvent.horaClase
                    : " "}{" "}
                </span>
                ?
              </p>
            </Row>
            <Row>
              <Col>
                <Button color="secondary" onClick={this.toggleModalQuestion}>
                  Cancelar
                </Button>
              </Col>
              <Col>
                <Button onClick={this.handleDeleteSession} color="danger">
                  Eliminar
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalFrame>

        <p className={`${s.grayTitle} w-100 my-1`}>Datos Representante</p>
        {eventDetails ? (
          <div>
            <Row>
              <Col md="3">
                <FormGroup>
                  <Label for="nombreRepresentante">Nombre</Label>
                  <Input
                    type="text"
                    name="nombre"
                    id="nombreRepresentante"
                    value={eventDetails.nombreRepresentante}
                    className={s.input}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="5">
                <FormGroup>
                  <Label for="event">Evento</Label>
                  <Input
                    type="text"
                    name="event"
                    id="event"
                    className={s.input}
                    value={eventDetails.evento}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label for="club">Club</Label>
                  <Input
                    type="text"
                    name="club"
                    id="club"
                    className={s.input}
                    value={eventDetails.club}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label for="eventType">Tipo de evento</Label>
                  <Input
                    type="text"
                    name="eventType"
                    id="eventType"
                    className={s.input}
                    value={eventDetails.tipoEvento}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label for="initialDate">Inicio</Label>
                  <Input
                    type="text"
                    name="text"
                    id="initialDate"
                    className={s.input}
                    value={eventDetails.fechaInicio}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label for="finalDate">Fin</Label>
                  <Input
                    type="text"
                    name="finalDate"
                    id="finalDate"
                    className={s.input}
                    value={eventDetails.fechaFin}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label for="maxCompetitors">Maximo de participantes</Label>
                  <Input
                    type="number"
                    name="maxCompetitors"
                    id="maxCompetitors"
                    className={s.input}
                    value={eventDetails.maximoParticipantes}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup>
                  <Label for="sesiones">Clases restantes</Label>
                  <Input
                    type="number"
                    name="sesiones"
                    id="sesiones"
                    className={s.input}
                    value={eventDetails.sesionesRestantes}
                    disabled
                  />
                </FormGroup>
              </Col>

              <Col>
                <PersonSelector
                  disabled={
                    eventDetails.participantes.length >=
                    eventDetails.maximoParticipantes
                  }
                  setPerson={this.setPerson}
                  cKey={"CP0QEKU0B6"}
                />
              </Col>
            </Row>
            <div>
              <ReactTable
                data={eventDetails.participantes}
                columns={attendantColumns}
                defaultPageSize={5}
              />
            </div>
            <div style={{ marginBottom: 20 }}>
              <p className={`${s.grayTitle} w-100 my-1`}>Clases</p>
              <ReactTable
                data={this.state.eventClasses}
                columns={clasesColumns}
                defaultPageSize={5}
              />
            </div>
          </div>
        ) : null}
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(SportSession));
