import withStyles from "isomorphic-style-loader/lib/withStyles";
import moment from "moment";
import React from "react";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";
import ModalFrame from "../../../../../toolkit/modalFrame";
import { handleRequestError } from "../../../../../utils/helpers";
import { infoNotification } from "../../../../../utils/notifications";
import {
  generateCurrentMonthCharge,
  generateMonthChargeWithDate
} from "../../../../actions/partners";
import DatePicker from "../../../common/DatePicker";
import s from "../styles.scss";

interface Props {
  idMembresiaSocio: string;
  isOpen: boolean;
  cancel: () => any;
  success: () => any;
}

interface State {
  selectedDate: string;
  isCatModalCurrentMonthChargeEnabled: boolean;
}

class CreateMaintenanceChargeModal extends React.Component<Props, State> {
  state = {
    selectedDate: null,
    isCatModalCurrentMonthChargeEnabled: true
  };

  componentDidMount() {}

  handleCheckBoxChange = e => {
    const checked = e.target.checked;
    this.setState({ isCatModalCurrentMonthChargeEnabled: checked });
  };

  handleGenerateCurrentMonth = () => {
    infoNotification("Generando cargo de mantenimiento para el mes actual...");
    const promise = !this.state.selectedDate
      ? generateCurrentMonthCharge(
          this.props.idMembresiaSocio,
          this.state.isCatModalCurrentMonthChargeEnabled
        )
      : generateMonthChargeWithDate(
          this.props.idMembresiaSocio,
          this.state.selectedDate,
          this.state.isCatModalCurrentMonthChargeEnabled
        );

    promise
      .then(() => {
        this.setState({
          isCatModalCurrentMonthChargeEnabled: true,
          selectedDate: null
        });
        infoNotification("Cargo generado correctamente...");

        if (this.props.success) {
          this.props.success();
        }
      })
      .catch(handleRequestError);
  };

  render() {
    return (
      <ModalFrame
        title={"Generar cargo de mantenimiento"}
        isOpen={this.props.isOpen}
      >
        <Container>
          <div>
            <FormGroup>
              <Label style={{ fontWeight: "bold" }}>Fecha:</Label>
              <DatePicker
                id="crearMantenimientoFecha"
                name="crearMantenimientoFecha"
                selected={this.state.selectedDate}
                maxDate={moment()}
                onChange={evt => {
                  const date = moment(evt).format("YYYY-MM-DD");
                  this.setState({
                    selectedDate: date
                  });
                }}
              />
            </FormGroup>
          </div>

          <p className="m-5">
            <Label check>
              <Input
                type="checkbox"
                checked={this.state.isCatModalCurrentMonthChargeEnabled}
                onChange={e => this.handleCheckBoxChange(e)}
              />
              Habilitar para proceso de cargos automáticos
            </Label>
          </p>
          <p>
            Se generará el cargo de mantenimiento del mes/año seleccionado. En
            caso de no seleccionar una fecha se creará el del mes actual ¿Desea
            continuar?
          </p>

          <Row>
            <Col className="text-right">
              <Button
                color="secondary"
                onClick={() => {
                  this.setState({
                    isCatModalCurrentMonthChargeEnabled: true,
                    selectedDate: null
                  });
                  if (this.props.cancel) {
                    this.props.cancel();
                  }
                }}
                style={{ marginRight: "15px" }}
              >
                Cerrar
              </Button>
              <Button color="primary" onClick={this.handleGenerateCurrentMonth}>
                Generar Cargo
              </Button>
            </Col>
          </Row>
        </Container>
      </ModalFrame>
    );
  }
}

export default withStyles(s)(CreateMaintenanceChargeModal);
