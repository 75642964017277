import React from "react";
import { connect } from "react-redux";
import Layout from "../../components/layout";
import { Helmet } from "react-helmet";
import ComissionsSaleReportPage from "../../components/comissionsSaleReport";
import {
  getComissionsSaleReport,
  getPastMonthPeriods
} from "../../actions/reports";
import { getClubsRequest, getSellers } from "../../actions/catalogs";
import { FILTERS } from "../../components/comissionsSaleReport/interfaces";
import { Club } from "../../../types-business/Clubs";
import { handleRequestError } from "../../../utils/helpers";

interface Props {
  periods: any;
  reportComissions: any[];
  loadingReport: boolean;
  sellers: any[];
  schemas: any[];
  userContext: { clubSessionId?: any };
  getPastMonthPeriods: () => any;
  getSellers: () => any;
  getComissionsSaleReport: (filters: FILTERS) => void;
  authorizeMemberships: (comissionsList: object) => void;
}

interface State {
  clubs: Club[];
}

class ComissionsSaleReport extends React.Component<Props, State> {
  state = { clubs: null };

  componentDidMount() {
    this.getClubs();
    this.props.getPastMonthPeriods();
    this.props.getSellers();
  }

  componentDidUpdate() {}

  getClubs = () => {
    getClubsRequest()
      .then(({ data }) => {
        this.setState({ clubs: (data || []).filter(x => !!x.migradoCrm2) });
      })
      .catch(handleRequestError);
  };

  render() {
    return (
      <main>
        <Helmet title="Creacion de Esquema de Beneficios" />
        <Layout>
          {this.state.clubs && this.props.periods && (
            <ComissionsSaleReportPage
              fetchSearchData={this.props.getComissionsSaleReport}
              clubs={this.state.clubs || []}
              periods={this.props.periods}
              reportComissions={this.props.reportComissions}
              sellers={this.props.sellers}
              authorizeMemberships={this.props.authorizeMemberships}
              userContext={this.props.userContext}
              loadingReport={this.props.loadingReport}
            />
          )}
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getPastMonthPeriods: () => {
    return dispatch(getPastMonthPeriods());
  },
  getComissionsSaleReport: (filters: FILTERS) => {
    return dispatch(getComissionsSaleReport(filters));
  },
  getSellers: () => {
    return dispatch(getSellers());
  }
});

const mapStateToProps = state => {
  const {
    catalogs: { sellers, schemas },
    periods: { periods, periodById }
  } = state;

  return {
    schemas,
    periods,
    periodById,
    sellers,
    reportComissions: state.reports.saleComissions,
    loadingReport: state.reports.loadingSaleComissions,
    userContext: state.user.userContext
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComissionsSaleReport);
