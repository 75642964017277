import React from "react";
import { downloadGcpFile } from "../../actions/lotesCargosAutomaticos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";

const columns = [
  {
    Header: "Club",
    id: "club",
    accessor: "club",
    width: "160"
  },
  {
    Header: "Tipo",
    id: "modalidad",
    accessor: enumAccessor("modalidad"),
    width: "160"
  },
  {
    Header: "Estatus",
    id: "estatus",
    accessor: enumAccessor("estatus"),
    width: "160"
  },
  {
    Header: "No. de Registros",
    id: "numeroRegistrosEnvio",
    accessor: "numeroRegistrosEnvio",
    width: "104"
  },
  {
    Header: "Emisor",
    id: "modalidadTipotarjeta",
    accessor: enumAccessor("modalidadTipotarjeta"),
    width: "112"
  },
  {
    Header: "Fecha envío",
    id: "fechaEnvio",
    accessor: dateAccessor("fechaEnvio"),
    width: "128"
  },
  {
    Header: "Fecha respuesta",
    id: "fechaProcesamientoRespuesta",
    accessor: dateAccessor("fechaProcesamientoRespuesta"),
    width: "128"
  },
  {
    Header: "Envío",
    id: "fileStorageEnvioId",
    accessor: storageAccessor("nombreArchivo", "fileStorageEnvioId", "_envio"),
    width: "72"
  },
  {
    Header: "Respuesta",
    id: "fileStorageRespuestaId",
    accessor: storageAccessor(
      "nombreArchivo",
      "fileStorageRespuestaId",
      "_respuesta"
    ),
    width: "72"
  },
  {
    Header: "Errores",
    id: "fileStorageErrorId",
    accessor: storageAccessor(
      "nombreArchivo",
      "fileStorageErrorId",
      "_errores"
    ),
    width: "72"
  }
];

function enumAccessor(accessor: string) {
  return row => {
    const str = row[accessor] || "";
    const sentence = str.toLowerCase().split("_");

    for (var i = 0; i < sentence.length; i++) {
      if (sentence[i]) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1) + " ";
      }
    }

    return <div>{sentence}</div>;
  };
}

function storageAccessor(
  fileNameAccessor: string,
  storageAccessor: string,
  postfix
) {
  return row => {
    const fileName =
      (row[fileNameAccessor] || "Archivo").replace(".txt", "") +
      postfix +
      ".txt";
    const storage = row[storageAccessor];

    return storage ? (
      <div
        style={{ cursor: "pointer", textAlign: "center" }}
        onClick={() => downloadGcpFile(storage, fileName)}
      >
        <FontAwesomeIcon icon={faFileDownload} color="black" />
      </div>
    ) : (
      <div>No existe</div>
    );
  };
}

function dateAccessor(accessor: string) {
  return row => {
    const str = row[accessor] || "-";

    return <div>{formatDate(str)}</div>;
  };
}

function formatDate(isoDate: string): string {
  try {
    const [date, time] = isoDate.split("T");
    const [year, month, day] = date.split("-");

    // Formato de la fecha:
    const fmtDate = `${day}/${month}/${year} `;
    const indexSeconds = time.lastIndexOf(":");
    const fmtTime = indexSeconds >= 4 ? time.substring(0, indexSeconds) : time;

    return fmtDate + fmtTime;
  } catch (error) {
    return isoDate;
  }
}

export default columns;
