import { combineReducers } from "redux";

import homeReducers from "../Home/reducers";
import schedulePatternsReducers from "../SchedulePatterns/reducers";
import trackingReducers from "../Tracking/reducers";
import activitiesReducers from "../Activities/reducers";
import referredCodesReducers from "../ReferredCodes/reducers";
import isNil from "lodash.isnil";
import actionTypes from "../Home/actionTypes";
import { cleanUp } from "../utils/accessTree";

const appReducer = combineReducers({
  ...homeReducers,
  ...schedulePatternsReducers,
  ...trackingReducers,
  ...activitiesReducers,
  ...referredCodesReducers
});

let initialState;

const rootReducer = (state, action) => {
  if (!isNil(state) && isNil(initialState)) {
    initialState = state;
  }

  if (action.type === actionTypes.CLEAN_GLOBAL_STATE) {
    cleanUp();
    return appReducer(initialState, action);
  }

  if (action.type === actionTypes.RESTART_APP) {
    cleanUp();
    const reduce = appReducer(initialState, action);
    if (window.location.pathname !== "/") {
      window.location.href = "/";
    }
    return reduce;
  }

  if (action.type === actionTypes.CHANGE_PASSWORD) {
    cleanUp();
    const reduce = appReducer(initialState, action);
    if (window.location.pathname !== "/revoke") {
      window.location.href = "/revoke";
    }
    return reduce;
  }

  return appReducer(state, action);
};

const rootStore = rootReducer;

export default rootStore;
