import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Container, Row, Col, Input, Button } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import moment from "moment";
import {
  stringify as objectToQueryParams,
  parse as parseQueryParams
} from "query-string";
import ReactTable from "react-table";
import { MEMBERSHIP_TYPE, CONCEPTOS } from "../../../utils/constants";
import { validDateGoals } from "../../../utils/helpers";

interface Props {
  location: any;
  history: any;
  getActiveClubs: () => any;
  saveGoals: (data: object[]) => any;
  getGoals: (object: object) => any;
  clubs: { nombre: string; clubId: number; concepto: string }[];
}

interface State {
  mes: number;
  anio: number;
  tipoEsquema: String;
  data: { clubId: number; nombreClub: string }[];
  concepto: string;
}

export const ComissionSchemaTypes = [
  { value: MEMBERSHIP_TYPE.VENTA, label: "Venta" },
  { value: MEMBERSHIP_TYPE.REACTIVACION, label: "Reactivación" }
];

class Goals extends React.Component<Props, State> {
  state = {
    mes: null,
    anio: null,
    tipoEsquema: "",
    data: [],
    concepto: null
  };

  setQueryParams = state => {
    this.props.history.push({ search: objectToQueryParams(state) });
  };

  loadData = state => {
    const { tipoEsquema, concepto: clubConcepto } = state;
    this.props.getGoals(state).then(({ value: { data: goals } }) => {
      const goalsHash = {};

      goals.forEach(goal => {
        goalsHash[goal.clubId] = goal;
      });

      const data = this.props.clubs
        .filter(({ concepto }) => !clubConcepto || clubConcepto == concepto)
        .map(({ clubId, nombre: nombreClub, concepto }) => {
          const dataGoal = goalsHash[clubId];
          return {
            tipoEsquema,
            clubId,
            nombreClub,
            concepto,
            mes: state.mes,
            anio: state.anio,
            nroMembresias: 0,
            nroEjecutivosQ1: 0,
            nroEjecutivosQ2: 0,
            totalVentas: 0,
            nroProspectos: 0,
            ...dataGoal
          };
        });

      this.setState({ data });
    });
  };

  async componentDidMount() {
    let { mes, anio, tipoEsquema } = parseQueryParams(
      this.props.location.search
    );
    if (!mes) {
      mes = moment().month() + 1;
    }
    if (!anio) {
      anio = moment().year();
    }
    if (!tipoEsquema) {
      tipoEsquema = ComissionSchemaTypes[0].value;
    }

    const state = { mes, anio, tipoEsquema };
    this.setQueryParams(state);
    this.setState({
      ...state
    });
    this.loadData(state);
  }

  selectConcept = e => {
    e.preventDefault();
    const concepto = e.target.value;
    this.setState({ concepto });
    const state = {
      concepto,
      mes: this.state.mes,
      anio: this.state.anio,
      tipoEsquema: this.state.tipoEsquema
    };
    this.setQueryParams(state);
    this.loadData(state);
  };

  selectMonth = e => {
    e.preventDefault();
    const mes = e.target.value;
    this.setState({ mes });
    const state = {
      mes,
      anio: this.state.anio,
      tipoEsquema: this.state.tipoEsquema
    };
    this.setQueryParams(state);
    this.loadData(state);
  };

  selectYear = e => {
    e.preventDefault();
    const anio = e.target.value;
    this.setState({ anio });
    const state = {
      anio,
      mes: this.state.mes,
      tipoEsquema: this.state.tipoEsquema
    };
    this.setQueryParams(state);
    this.loadData(state);
  };

  selectSchemaType = e => {
    e.preventDefault();
    const tipoEsquema = e.target.value;
    this.setState({ tipoEsquema });
    const state = {
      anio: this.state.anio,
      mes: this.state.mes,
      tipoEsquema
    };
    this.setQueryParams(state);
    this.loadData(state);
  };

  setValue = (index, id, value) => {
    const data = [...this.state.data];
    data[index][id] = value;
    this.setState({ ...this.state, data });
  };

  disableForm = () => {
    return (
      this.state.mes &&
      this.state.anio &&
      !validDateGoals(this.state.mes, this.state.anio)
    );
  };

  renderInputText = ({ index, column: { id } }) => {
    return (
      <Input
        type="number"
        className={`${s.renderEditableInput}`}
        value={this.state.data[index][id]}
        disabled={this.disableForm()}
        onChange={e => {
          const value = e.target.value;
          this.setValue(index, id, value);
        }}
      />
    );
  };

  onSubmit = e => {
    e.preventDefault();
    this.props.saveGoals(this.state.data);
  };

  render() {
    const columns = [
      {
        Header: "TIPO ESQUEMA",
        accessor: "tipoEsquema",
        width: 150
      },
      {
        Header: "CLUB",
        accessor: "nombreClub"
      },
      {
        Header: "CLUB CONCEPTO",
        accessor: "concepto"
      },
      {
        Header: "NRO. MEMBRESIAS",
        accessor: "nroMembresias",
        Cell: this.renderInputText,
        className: "p-1",
        width: 150
      },
      {
        Header: "IMPORTE",
        accessor: "totalVentas",
        Cell: this.renderInputText,
        className: "p-1",
        width: 150
      },
      {
        Header: "NRO. PROSPECTOS",
        accessor: "nroProspectos",
        Cell: this.renderInputText,
        className: "p-1",
        width: 150
      },
      {
        Header: "NRO. EJECUTIVOS Q1",
        accessor: "nroEjecutivosQ1",
        Cell: this.renderInputText,
        className: "p-1",
        width: 150
      },
      {
        Header: "NRO. EJECUTIVOS Q2",
        accessor: "nroEjecutivosQ2",
        Cell: this.renderInputText,
        className: "p-1",
        width: 150
      }
    ];
    return (
      <Container className="mt-3 p-0">
        <Row>
          <Col>
            <BaseInput
              type="select"
              label="Club Concepto"
              name="goalsClubConcepts"
              id="goalsClubConcepts"
              value={this.state.concepto}
              onChange={this.selectConcept}
              options={[
                { value: "", label: "Todos los conceptos" },
                ...CONCEPTOS
              ]}
            />
          </Col>
          <Col>
            <BaseInput
              label="Mes"
              name="mesMetas"
              type="select"
              id="mesMetas"
              placeholder="Mes"
              value={this.state.mes}
              onChange={this.selectMonth}
              options={Array.apply(0, Array(12)).map((_, index) => ({
                value: index + 1,
                label: moment()
                  .month(index)
                  .format("MMMM")
              }))}
            />
          </Col>
          <Col>
            <BaseInput
              label="Año"
              name="anioMetas"
              type="select"
              id="anioMetas"
              placeholder="Año"
              value={this.state.anio}
              onChange={this.selectYear}
              options={Array.apply(0, Array(20)).map((_, index) => {
                const year = moment()
                  .subtract(10 - index, "years")
                  .year();
                return { value: year, label: year };
              })}
            />
          </Col>
          <Col>
            <BaseInput
              label="Tipo esquema comisión"
              name="comissionSchemaType"
              type="select"
              id="comissionSchemaType"
              value={this.state.tipoEsquema}
              options={[...ComissionSchemaTypes]}
              onChange={this.selectSchemaType}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {this.state.data.length ? (
              <ReactTable
                data={this.state.data}
                columns={columns}
                sortable={false}
                pageSize={this.state.data.length}
                showPagination={false}
                className={`-highlight -striped`}
                style={{ height: "400px" }}
              />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col />
          <Col xs="2">
            <Button
              className={`mt-3 ${s.primaryButton}`}
              onClick={e => this.onSubmit(e)}
              disabled={this.disableForm()}
              size="sm"
              block
            >
              {"Guardar"}
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(Goals));
