import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import PeriodsConfiguration from "../../components/periodsConfiguration";
import {
  getPeriods,
  saveSchemasByPeriod,
  getPeriodById
} from "../../actions/periods";
import { getSchemas, getClubs } from "../../actions/catalogs";

interface Props {
  getPeriods: () => any;
  getSchemas: () => any;
  periods: object[];
  schemas: object[];
  clubs: object[];
  getClubs: () => any;
  saveSchemasByPeriod: (schemasByClub: object) => any;
  getPeriodById: (id: string) => any;
  periodById: any;
}

class PeriodsConfigurationPage extends React.Component<Props> {
  componentDidMount() {
    this.props.getPeriods();
    this.props.getClubs();
  }

  render() {
    return (
      <main>
        <Helmet title="Configuracion de Periodos" />
        <Layout>
          {this.props.periods && (
            <PeriodsConfiguration
              periods={this.props.periods}
              schemas={this.props.schemas}
              getSchemas={this.props.getSchemas}
              clubs={this.props.clubs}
              saveSchemasByPeriod={this.props.saveSchemasByPeriod}
              getPeriodById={this.props.getPeriodById}
              periodById={this.props.periodById}
            />
          )}
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getPeriods: () => {
    dispatch(getPeriods());
  },
  getPeriodById: (id: string) => {
    dispatch(getPeriodById(id));
  },
  getSchemas: () => {
    dispatch(getSchemas());
  },
  getClubs: () => {
    dispatch(getClubs());
  },
  saveSchemasByPeriod: schemas => {
    dispatch(saveSchemasByPeriod(schemas));
  }
});

const mapStateToProps = state => {
  const {
    periods: { periods, periodById },
    catalogs: { schemas, clubs }
  } = state;
  return {
    periods,
    schemas,
    clubs,
    periodById
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PeriodsConfigurationPage);
