import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import SportsProductsQuoteById from "../../components/sportsProductsQuoteById";
import * as sportProductActions from "../../actions/sportsProducts";
import * as terminalActions from "../../actions/terminal";
import * as catalogActions from "../../actions/catalogs";
import IAction from "../../../types/IAction";
import { addDataLogueo } from "../../actions/terminal";

interface Props {
  match: any;
  getQuoteByPersonId: (id: string, params: object) => void;
  payQuote: (personaId: string, data: object) => void;
  getBanksMonthsWithoutInterest: () => void;
  banksMonthsWithoutInterest: object[];
  saving: boolean;
  waysPay: object[];
  quote: any;
  loading: boolean;
  getCashboxById: (id: string) => any;
  cashboxById: object;
  newCart: object;
  cartCreated: boolean;
  productsList: object[];
  saveSPQuote: (data: object) => any;
  pendingPayment: (amount: number) => any;
  isPending: boolean;
  amountPending: number;
  payPending: () => any;
  clearReference: () => any;
  terminal: object;
  addLogueoRedux: (data: object) => any;
  clearLogueoRedux: () => any;
  getRefenceSantanderCargo: (
    clubId: string,
    idMovimiento: string,
    formaDePago: string
  ) => any;
  getRefenceSantanderComplemento: (
    clubId: string,
    monto: string,
    formaDePago: string
  ) => any;
  userContext: object;
  getCompanies: () => any;
  companiesTypes: object[];
  getReferencesNotUsed: (clubId: string) => IAction;
  referencesNotUsed: object[];
  addDataLogueo: () => any;
}

class SportsProductsQuoteByIdPage extends React.Component<Props> {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.addDataLogueo();
    if (!!id) this.props.getCashboxById(id);
    this.props.getBanksMonthsWithoutInterest();
    this.props.getCompanies();
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props.match.params;
    const prevId = prevProps.match.params.id;
    if (!prevId && !!id) {
      this.props.getCashboxById(id);
    }
  }

  render() {
    return (
      <main>
        <Helmet title="Sports Products" />
        <Layout>
          {this.props.banksMonthsWithoutInterest && (
            <SportsProductsQuoteById
              terminal={this.props.terminal}
              quote={this.props.quote}
              banksMonthsWithoutInterest={this.props.banksMonthsWithoutInterest}
              waysPay={this.props.waysPay}
              payQuote={this.props.payQuote}
              saving={this.props.saving}
              personaId={this.props.match.params.id}
              loading={this.props.loading}
              cashboxById={this.props.cashboxById}
              newCart={this.props.newCart}
              cartCreated={this.props.cartCreated}
              productsList={this.props.productsList}
              saveSPQuote={this.props.saveSPQuote}
              pendingPayment={this.props.pendingPayment}
              isPending={this.props.isPending}
              amountPending={this.props.amountPending}
              payPending={this.props.payPending}
              getCashboxById={this.props.getCashboxById}
              addLogueoRedux={this.props.addLogueoRedux}
              clearLogueoRedux={this.props.clearLogueoRedux}
              getRefenceSantanderCargo={this.props.getRefenceSantanderCargo}
              getRefenceSantanderComplemento={
                this.props.getRefenceSantanderComplemento
              }
              userContext={this.props.userContext}
              clearReference={this.props.clearReference}
              companiesTypes={this.props.companiesTypes}
              getReferencesNotUsed={this.props.getReferencesNotUsed}
              referencesNotUsed={this.props.referencesNotUsed}
            />
          )}
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getQuoteByPersonId: (id: number, params: object) => {
    return dispatch(sportProductActions.getQuoteByPersonId(id, params));
  },
  payQuote: (personaId: string, data: object) => {
    return dispatch(sportProductActions.payQuote(personaId, data));
  },
  getBanksMonthsWithoutInterest: () => {
    dispatch(catalogActions.getBanksMonthsWithoutInterest());
  },
  getCashboxById: (id: string) => {
    return dispatch(sportProductActions.getCashboxById(id));
  },
  saveSPQuote: (data: object) => {
    return dispatch(sportProductActions.saveSPQuote(data));
  },
  pendingPayment: (amount: number) => {
    return dispatch(sportProductActions.pendingPayment(amount));
  },
  payPending: () => {
    return dispatch(sportProductActions.payPending());
  },
  addLogueoRedux: (data: object) => {
    return dispatch(terminalActions.addLogueoRedux(data));
  },
  clearLogueoRedux: () => {
    return dispatch(terminalActions.clearLogueoRedux());
  },
  getRefenceSantanderCargo: (
    clubId: string,
    idMovimiento: string,
    formaDePago: string
  ) => {
    return dispatch(
      terminalActions.getRefenceSantanderCargo(
        clubId,
        idMovimiento,
        formaDePago
      )
    );
  },
  getRefenceSantanderComplemento: (
    clubId: string,
    monto: string,
    formaDePago: string
  ) => {
    return dispatch(
      terminalActions.getRefenceSantanderComplemento(clubId, monto, formaDePago)
    );
  },
  clearReference: () => {
    return dispatch(terminalActions.clearReference());
  },
  getCompanies: () => {
    return dispatch(catalogActions.getCompanies());
  },
  getReferencesNotUsed: (clubId: string) => {
    return dispatch(terminalActions.getReferencesNotUsed(clubId));
  },
  addDataLogueo: () => {
    dispatch(addDataLogueo());
  }
});

const mapStateToProps = state => {
  const { sportsProducts, catalogs, terminal, user } = state;
  return {
    quote: sportsProducts.quoteByPersonId,
    saving: sportsProducts.savingQuotePay,
    waysPay: catalogs.waysPay,
    banksMonthsWithoutInterest: catalogs.banksMonthsWithoutInterest,
    loading: sportsProducts.gettingProductQuote,
    cashboxById: sportsProducts.cashboxById,
    newCart: sportsProducts.newCart,
    cartCreated: sportsProducts.cartCreated,
    productsList: sportsProducts.productsList,
    isPending: sportsProducts.isPending,
    amountPending: sportsProducts.amountPending,
    terminal,
    userContext: user.userContext,
    companiesTypes: catalogs.companiesTypes,
    referencesNotUsed: terminal.referencesNotUsed
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SportsProductsQuoteByIdPage);
