import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./styles.scss";

interface Props {
  type: string;
  name: string;
  id: string;
  placeholder?: string;
  label?: string;
  value: string | number | Date;
  dataQAId: string;
  dataElmId: string;
  onChange: (string: string) => void;
  onKeyPress: (target: any) => void;
  onBlur: (string: string) => void;
  errors: Array<string>;
  disabled: boolean;
  size: string;
  options: any;
  className?: string;
  uppercase: boolean;
  maxlength: string;
  multipleSelection: boolean;
}

class BaseInput extends React.Component<Props, void> {
  render() {
    const {
      id,
      name,
      value,
      label,
      type,
      placeholder,
      dataQAId,
      dataElmId,
      children,
      options,
      errors,
      disabled,
      size,
      maxlength,
      multipleSelection
    } = this.props;

    let errClass = errors && errors.length ? s.error : "";

    return (
      <FormGroup className={s.formGroup}>
        {label && (
          <Label className={s.label} for={id}>
            {label}:
          </Label>
        )}
        <Input
          type={type}
          name={name}
          id={id}
          placeholder={placeholder}
          className={`${
            !multipleSelection ? s.input : s.inputMultiselect
          } ${errClass} px-2 py-1 ${
            !!this.props.uppercase ? "text-uppercase" : ""
          }`}
          value={value}
          disabled={disabled || false}
          data-qa-id={dataQAId}
          data-elm-id={dataElmId}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          onKeyPress={this.props.onKeyPress}
          size={size || "md"}
          maxLength={maxlength || ""}
          autoComplete="new-password"
          multiple={type === "select" && !!multipleSelection}
        >
          {type === "select"
            ? options &&
              options.map((option, index) => {
                return (
                  <option
                    key={index}
                    value={option.value}
                    // className="text-capitalize"
                  >
                    {option.label}
                  </option>
                );
              })
            : null}
        </Input>
        {children}
        {errors &&
          errors.map((message, index) => {
            return (
              <span key={index} className={s.errorSpan}>
                {message}
                <br />
              </span>
            );
          })}
      </FormGroup>
    );
  }
}

export default withStyles(s)(BaseInput);
