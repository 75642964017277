import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import MaintenancesSelector from "../../../Home/components/maintenancesSelector";
import ClubSelector from "../../../Home/containers/clubSelector";

let loadClubs = null;

export default ({
  maintenances,
  saveData,
  maintenancesCodes,
  clubs: clubsCodes
}) => {
  const [selected, setSelected] = useState([]);
  const [validClubs, setValidClubs] = useState([]);

  useEffect(() => {
    if (maintenancesCodes && maintenances) {
      setSelected(
        maintenancesCodes.map(id => ({
          datosBasicosMantenimientoId: id,
          nombre: maintenances.find(
            ({ datosBasicosMantenimientoId }) =>
              datosBasicosMantenimientoId == id
          ).nombre
        }))
      );
    }

    if (clubsCodes) {
      setValidClubs(clubsCodes.map(id => ({ clubId: id })));
    }
  }, [maintenancesCodes, clubsCodes, maintenances]);

  let newSelected = [];

  const setNewSelected = ({ selected: updatedSelected }) => {
    newSelected = updatedSelected;
  };

  let clubs = [];

  const setClubs = newClubs => {
    clubs = newClubs;
  };

  const setloadClubs = f => {
    if (!loadClubs) loadClubs = f;
  };

  const onClick = () => {
    loadClubs();
    saveData(
      clubs.map(({ clubId }) => clubId),
      newSelected.map(({ id }) => id)
    );
  };

  return (
    <Container>
      <Row className="mt-3">
        <p className={`primaryTitle w-100`}>
          Seleccionar mantenimientos aplicables
        </p>
        <Col className="p-0">
          <MaintenancesSelector
            items={maintenances}
            selected={selected}
            updateSelection={setNewSelected}
            keyId="datosBasicosMantenimientoId"
            keyName="nombre"
          />
        </Col>
      </Row>
      <hr />
      <Row className="mt-3">
        <p className={`primaryTitle w-100`}>Seleccionar clubes aplicables</p>
        <Col className="p-0">
          <ClubSelector
            onClickSave={setloadClubs}
            validClubs={validClubs}
            setClubs={setClubs}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={9} />
        <Col>
          <Button
            className="primaryButton"
            onClick={e => {
              e.preventDefault();
              onClick();
            }}
            size="sm"
            block
          >
            Guardar Mantenimientos y Clubes aplicables
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
