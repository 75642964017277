import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import {
  infoNotification,
  errorNotification,
  successNotification,
  GET_DEALERS_FULFILLED,
  GET_DEALERS_PENDING,
  GET_DEALERS_REJECTED,
  DELETE_DEALER_FULFILLED,
  DELETE_DEALER_PENDING,
  DELETE_DEALER_REJECTED,
  GET_DEALERS_MOVEMENTS_FULFILLED,
  GET_DEALERS_MOVEMENTS_PENDING,
  GET_DEALERS_MOVEMENTS_REJECTED,
  ACCEPT_CHARGE_MOVEMENTS_FULFILLED,
  ACCEPT_CHARGE_MOVEMENTS_PENDING,
  ACCEPT_CHARGE_MOVEMENTS_REJECTED,
  GET_DEALERS_CHARGES_FULFILLED,
  GET_DEALERS_CHARGES_PENDING,
  GET_DEALERS_CHARGES_REJECTED,
  DELETE_CHARGE_DEALER_FULFILLED,
  DELETE_CHARGE_DEALER_PENDING,
  DELETE_CHARGE_DEALER_REJECTED,
  GET_SELECTED_DEALER_CHARGE_FULFILLED,
  GET_SELECTED_DEALER_CHARGE_PENDING,
  GET_SELECTED_DEALER_CHARGE_REJECTED,
  GET_DEALERS_STATUS_REPORT_FULFILLED,
  GET_DEALERS_STATUS_REPORT_PENDING,
  GET_DEALERS_STATUS_REPORT_REJECTED
} from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";
interface State {
  dealers: object[];
  dealersMovement: object[];
  deleteDealer: boolean;
  deleteChargeDealer: boolean;
  acceptCharge: boolean;
  dealersCharges: object[];
  selectedDealerCharge: object;
  dealersStatusReport: object;
  errorAcceptCharge: boolean;
}

const initialState: State = {
  dealers: [],
  dealersMovement: [],
  deleteDealer: false,
  deleteChargeDealer: false,
  acceptCharge: false,
  dealersCharges: [],
  selectedDealerCharge: {},
  dealersStatusReport: {},
  errorAcceptCharge: false
};

export default function dealersReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.GET_DEALERS_LIST}_PENDING`: {
      infoNotification(GET_DEALERS_PENDING);
      return {
        ...state,
        dealers: []
      };
    }
    case `${actionTypes.GET_DEALERS_LIST}_REJECTED`: {
      errorNotification(readMessageBack(action, GET_DEALERS_REJECTED));
      return {
        ...state,
        dealers: []
      };
    }
    case `${actionTypes.GET_DEALERS_LIST}_FULFILLED`: {
      successNotification(GET_DEALERS_FULFILLED);
      return {
        ...state,
        dealers: action.payload.data
      };
    }
    case `${actionTypes.GET_DEALERS_CHARGES}_PENDING`: {
      infoNotification(GET_DEALERS_CHARGES_PENDING);
      return {
        ...state,
        dealersCharges: []
      };
    }
    case `${actionTypes.GET_DEALERS_CHARGES}_REJECTED`: {
      errorNotification(readMessageBack(action, GET_DEALERS_CHARGES_REJECTED));
      return {
        ...state,
        dealersCharges: []
      };
    }
    case `${actionTypes.GET_DEALERS_CHARGES}_FULFILLED`: {
      successNotification(GET_DEALERS_CHARGES_FULFILLED);
      return {
        ...state,
        dealersCharges: action.payload.data
      };
    }
    case `${actionTypes.GET_SELECTED_DEALER_CHARGE}_PENDING`: {
      infoNotification(GET_SELECTED_DEALER_CHARGE_PENDING);
      return {
        ...state,
        selectedDealerCharge: {}
      };
    }
    case `${actionTypes.GET_SELECTED_DEALER_CHARGE}_REJECTED`: {
      errorNotification(
        readMessageBack(action, GET_SELECTED_DEALER_CHARGE_REJECTED)
      );
      return {
        ...state,
        selectedDealerCharge: {}
      };
    }
    case `${actionTypes.GET_SELECTED_DEALER_CHARGE}_FULFILLED`: {
      successNotification(GET_SELECTED_DEALER_CHARGE_FULFILLED);
      return {
        ...state,
        selectedDealerCharge: action.payload.data
      };
    }
    case `${actionTypes.DELETE_DEALER}_PENDING`: {
      infoNotification(DELETE_DEALER_PENDING);
      return {
        ...state,
        deleteDealer: false
      };
    }
    case `${actionTypes.DELETE_DEALER}_REJECTED`: {
      errorNotification(readMessageBack(action, DELETE_DEALER_REJECTED));
      return {
        ...state,
        deleteDealer: false
      };
    }
    case `${actionTypes.DELETE_DEALER}_FULFILLED`: {
      successNotification(DELETE_DEALER_FULFILLED);
      return {
        ...state,
        deleteDealer: true
      };
    }
    case `${actionTypes.DELETE_CHARGE_DEALER}_PENDING`: {
      infoNotification(DELETE_CHARGE_DEALER_PENDING);
      return {
        ...state,
        deleteChargeDealer: false
      };
    }
    case `${actionTypes.DELETE_CHARGE_DEALER}_REJECTED`: {
      errorNotification(readMessageBack(action, DELETE_CHARGE_DEALER_REJECTED));
      return {
        ...state,
        deleteChargeDealer: false
      };
    }
    case `${actionTypes.DELETE_CHARGE_DEALER}_FULFILLED`: {
      successNotification(DELETE_CHARGE_DEALER_FULFILLED);
      return {
        ...state,
        deleteChargeDealer: true
      };
    }
    case `${actionTypes.GET_DEALERS_MOVEMENTS}_PENDING`: {
      infoNotification(GET_DEALERS_MOVEMENTS_PENDING);
      return {
        ...state,
        dealersMovement: []
      };
    }
    case `${actionTypes.GET_DEALERS_MOVEMENTS}_REJECTED`: {
      errorNotification(
        readMessageBack(action, GET_DEALERS_MOVEMENTS_REJECTED)
      );
      return {
        ...state,
        dealersMovement: []
      };
    }
    case `${actionTypes.GET_DEALERS_MOVEMENTS}_FULFILLED`: {
      successNotification(GET_DEALERS_MOVEMENTS_FULFILLED);
      return {
        ...state,
        dealersMovement: action.payload.data
      };
    }
    case `${actionTypes.GET_DEALERS_STATUS_REPORT}_PENDING`: {
      infoNotification(GET_DEALERS_STATUS_REPORT_PENDING);
      return {
        ...state,
        dealersStatusReport: {}
      };
    }
    case `${actionTypes.GET_DEALERS_STATUS_REPORT}_REJECTED`: {
      errorNotification(
        readMessageBack(action, GET_DEALERS_STATUS_REPORT_REJECTED)
      );
      return {
        ...state,
        dealersStatusReport: {}
      };
    }
    case `${actionTypes.GET_DEALERS_STATUS_REPORT}_FULFILLED`: {
      successNotification(GET_DEALERS_STATUS_REPORT_FULFILLED);
      return {
        ...state,
        dealersStatusReport: action.payload.data
      };
    }
    case `${actionTypes.ACCEPT_CHARGE}_PENDING`: {
      infoNotification(ACCEPT_CHARGE_MOVEMENTS_PENDING);
      return {
        ...state,
        acceptCharge: false,
        errorAcceptCharge: false
      };
    }
    case `${actionTypes.ACCEPT_CHARGE}_REJECTED`: {
      errorNotification(
        readMessageBack(action, ACCEPT_CHARGE_MOVEMENTS_REJECTED)
      );
      return {
        ...state,
        acceptCharge: false,
        errorAcceptCharge: true
      };
    }
    case `${actionTypes.ACCEPT_CHARGE}_FULFILLED`: {
      successNotification(ACCEPT_CHARGE_MOVEMENTS_FULFILLED);
      return {
        ...state,
        acceptCharge: true,
        errorAcceptCharge: false
      };
    }
    case `${actionTypes.CLEAR_DEALERS_REDUX}`: {
      return {
        ...state,
        dealers: [],
        dealersMovement: [],
        deleteDealer: false,
        deleteChargeDealer: false,
        acceptCharge: false,
        dealersCharges: [],
        selectedDealerCharge: {},
        dealersStatusReport: {}
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
