/// imports
import React from "react";
import BaseInput from "../../../../toolkit/baseInput";
import s from "./styles.scss";
import {
  Dirty,
  dirtyClear,
  dirtySubmit,
  emptyFields,
  Fields,
  validationMessages,
  validationRules
} from "./validation";
import {
  Router,
  RouterProps,
  Styled
} from "../../../../toolkit/decorators/Artifact";
import Reform from "@franleplant/reform";
import { FormErrors } from "@franleplant/reform/types";
import {
  errorNotification,
  infoNotification
} from "../../../../utils/notifications";
import { Preview } from "../../../../types/IAction";
import { Button, Input, UncontrolledTooltip } from "reactstrap";
////

/// Interfaces Props y State
interface Props extends RouterProps {
  // Identificador del convenio
  id: number;

  // Limpieza de carrusel de convenios
  clearConvenioCarousel: () => Promise<Preview<any>>;

  // Datos básicos
  convenioDatosBasicos: any;
  convenioDatosBasicosLoading: boolean;
  getConvenioDatosBasicos: (idConvenio: number) => Promise<Preview<any>>;
  postConvenioDatosBasicos: (datosBasicos: any) => Promise<Preview<any>>;
  putConvenioDatosBasicos: (
    idConvenio: number,
    datosBasicos: any
  ) => Promise<Preview<any>>;

  // Empresas asociadas al convenio
  empresasAsignables: any[];
  empresasAsignablesLoading: boolean;
  getEmpresasAsignables: (idConvenio: number) => Promise<Preview<any>>;

  // Anuncio de formulario para confirmar el cambio de paso en el carrusel
  handleDirty: (dirty: boolean, dirtyMsg: string) => void;
}

interface State {
  // Valores y validación de formulario
  fields: Fields;
  dirty: Dirty;
  errors: FormErrors;

  // Buscador de empresas
  empresaFinder: string;
  empresas: any[];
}
////

@Router()
@Styled(s)
export default class ConvenioBasicData extends React.Component<Props, State> {
  /// Variables de clase
  state = {
    fields: emptyFields(),
    dirty: dirtyClear(),
    errors: {},
    empresaFinder: "",
    empresas: []
  };

  // Gestión de formulario
  re = Reform.reactMixins.objectMixin(this);
  validationRules = validationRules;
  validationMessages = validationMessages;
  ////

  /// Ciclo de vida del componente
  async componentDidMount() {
    this.props.clearConvenioCarousel();
    this.props.getEmpresasAsignables(this.props.id);

    if (this.props.id > 0) {
      this.loadConvenio();
    }
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.empresasAsignables != this.props.empresasAsignables) {
      this.setState({
        empresas: this.props.empresasAsignables,
        empresaFinder: ""
      });
    }

    if (prevProps.id != this.props.id) {
      this.props.getEmpresasAsignables(this.props.id);
      this.loadConvenio();
    }
  }
  ////

  /// Gestión del formulario
  // Actualización de los campos de texto y select's nativos
  onChangeInput = (evt, fieldName: string) => {
    const value = evt.target.value || "";

    this.setState({
      fields: {
        ...this.state.fields,
        [fieldName]: value
      },
      dirty: {
        ...this.state.dirty,
        [fieldName]: true
      }
    });

    this.re.validateField(fieldName, value);
  };

  onChangeMantenimientoTipoBeneficio = (tipo: string) => {
    this.setState({
      fields: {
        ...this.state.fields,
        mantenimientoTipoBeneficio: tipo
      },
      dirty: {
        ...this.state.dirty,
        mantenimientoTipoBeneficio: true
      }
    });

    this.re.validateField("mantenimientoTipoBeneficio", tipo);
  };

  // Filtro de empresas
  onChangeEmpresaFilter = evt => {
    const value = evt.target.value || "";
    this.setState({
      empresaFinder: value,
      empresas: value
        ? this.props.empresasAsignables.filter(
            e =>
              e.nombre &&
              e.nombre.toUpperCase().indexOf(value.toUpperCase()) > -1
          )
        : this.props.empresasAsignables
    });
  };

  // Seleccionar todos
  onCheckAll = evt => {
    const value = evt.target.checked ? true : false;
    const asignables = [
      ...this.state.empresas.filter(e => e.asignable).map(e => e.id)
    ];

    const selected = value
      ? [...this.state.fields.empresas, ...asignables]
      : this.state.fields.empresas.filter(id => asignables.indexOf(id) === -1);

    this.setState({
      fields: {
        ...this.state.fields,
        empresas: [...new Set(selected)]
      }
    });
  };

  // Selección de una empresa
  onCheckEmpresa = (evt, idEmpresa) => {
    const value = evt.target.checked ? true : false;
    const index = this.state.fields.empresas.indexOf(Number(idEmpresa));
    const seleccionadas = [...this.state.fields.empresas];

    if (value && index === -1) {
      seleccionadas.push(idEmpresa);
    } else if (!value && index !== -1) {
      seleccionadas.splice(index, 1);
    }

    this.setState({
      fields: {
        ...this.state.fields,
        empresas: seleccionadas
      }
    });
  };

  // Valida el estado de un campo y muestra solo un error
  onFieldValidation = fieldName => {
    if (!this.state.dirty[fieldName]) {
      return [];
    }

    const errors = this.re.mapFieldErrors(fieldName) || [];
    return errors.length > 1 ? [errors[0]] : errors;
  };

  // Activa las validaciones al abandonar el campo
  onBlurField = fieldName => {
    this.setState({
      dirty: {
        ...this.state.dirty,
        [fieldName]: true
      }
    });

    this.re.validateField(fieldName, this.state.fields[fieldName]);
  };

  // Actualización o creación de convenio
  submit = async evt => {
    const id = this.props.id;
    const convenio = { id, ...this.state.fields };
    evt.preventDefault();

    this.setState({
      dirty: dirtySubmit()
    });

    if (!this.re.validateFormFromState()) {
      errorNotification(
        "No ha ingresado la información requerida, revise los datos por favor."
      );
      return;
    }

    // Actualización o creación del convenio
    const preview = id
      ? await this.props.putConvenioDatosBasicos(id, convenio)
      : await this.props.postConvenioDatosBasicos(convenio);

    if (preview.fulfilled) {
      infoNotification(
        `Convenio ${id ? "actualizado" : "creado"} exitosamente`
      );

      if (this.props.id === 0) {
        this.props.history.push(
          `/cconvenios/${preview.convenioDatosBasicos.id}/configuracion`
        );
      }
    }
  };
  ////

  /// Otros métodos; loadConvenio, checkTipoBeneficio
  loadConvenio = async () => {
    this.props.getEmpresasAsignables(this.props.id);
    const preview = await this.props.getConvenioDatosBasicos(this.props.id);
    const convenio = preview.convenioDatosBasicos;

    if (preview.fulfilled) {
      this.setState({
        fields: {
          nombre: convenio.nombre,
          descripcion: convenio.descripcion,
          estatus: convenio.estatus,
          mantenimientoTipoBeneficio: convenio.mantenimientoTipoBeneficio,
          mantenimientoValorBeneficio: convenio.mantenimientoValorBeneficio,
          empresas: convenio.empresas
        }
      });
    }
  };

  checkTipoBeneficio = (tipo: string) => {
    return this.state.fields.mantenimientoTipoBeneficio === tipo;
  };
  ////

  /// Métodos de renderizado
  render() {
    if (this.props.empresasAsignablesLoading) {
      /// JSX del componente cuando está en carga
      return (
        <div className="h-100">
          <p className="primaryTitle w-100">Datos básicos</p>
          <p style={{ color: "#444", marginTop: "16px", fontSize: "14px" }}>
            Espere un momento por favor...
          </p>
        </div>
      );
      ////
    }

    return (
      <div className="h-100">
        <form>
          <p className="primaryTitle w-100">Datos básicos</p>
          {/* /// JSX del formulario */}
          <div className="form-row">
            <div className="col-2">
              <BaseInput
                label={"Nombre"}
                name="nombre"
                type="text"
                id="nombre"
                placeholder="Nombre"
                value={this.state.fields.nombre}
                onChange={evt => this.onChangeInput(evt, "nombre")}
                onBlur={() => this.onBlurField("nombre")}
                errors={this.onFieldValidation("nombre")}
              />
            </div>
            <div className="col-3">
              <BaseInput
                label={"Descripcion"}
                name="descripcion"
                type="text"
                id="descripcion"
                placeholder="Descripcion"
                value={this.state.fields.descripcion}
                onChange={evt => this.onChangeInput(evt, "descripcion")}
                onBlur={() => this.onBlurField("descripcion")}
                errors={this.onFieldValidation("descripcion")}
              />
            </div>
            <div className="col-2">
              <BaseInput
                label={"Estatus"}
                name="estatus"
                type="select"
                id="estatus"
                options={[
                  {
                    label: "Seleccione",
                    value: ""
                  },
                  {
                    label: "Activo",
                    value: "ACTIVO"
                  },
                  {
                    label: "Inactivo",
                    value: "INACTIVO"
                  }
                ]}
                placeholder="Estatus"
                value={this.state.fields.estatus}
                onChange={evt => this.onChangeInput(evt, "estatus")}
                onBlur={() => this.onBlurField("estatus")}
                errors={this.onFieldValidation("estatus")}
              />
            </div>
            <div className="col">
              <div>
                <div
                  style={{
                    fontSize: "12.8px",
                    margin: "3px 0 5px 0",
                    color: "#222"
                  }}
                >
                  Tipo de descuento:
                </div>
                <div
                  className="btn-group btn-group-toggle"
                  data-toggle="buttons"
                >
                  <label
                    id={`btn-chk-PORCENTAJE`}
                    className={`btn btn-sm btn-outline-secondary ${
                      this.checkTipoBeneficio("PORCENTAJE") ? "active" : ""
                    }`}
                    onClick={() =>
                      this.onChangeMantenimientoTipoBeneficio("PORCENTAJE")
                    }
                  >
                    <input
                      type="radio"
                      name="mantenimientoTipoBeneficio"
                      id="PORCENTAJE"
                      defaultChecked={this.checkTipoBeneficio("PORCENTAJE")}
                    />
                    Porcentaje
                  </label>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`btn-chk-PORCENTAJE`}
                  >
                    Porcentaje de descuento
                  </UncontrolledTooltip>
                  <label
                    id={`btn-chk-PRECIOFIJO`}
                    className={`btn btn-sm btn-outline-secondary ${
                      this.checkTipoBeneficio("PRECIOFIJO") ? "active" : ""
                    }`}
                    onClick={() =>
                      this.onChangeMantenimientoTipoBeneficio("PRECIOFIJO")
                    }
                  >
                    <input
                      type="radio"
                      name="mantenimientoTipoBeneficio"
                      id="PRECIOFIJO"
                      defaultChecked={this.checkTipoBeneficio("PRECIOFIJO")}
                    />
                    Precio
                  </label>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`btn-chk-PRECIOFIJO`}
                  >
                    Precio fijo de mantenimiento
                  </UncontrolledTooltip>
                  <label
                    id={`btn-chk-DESCUENTOABS`}
                    className={`btn btn-sm btn-outline-secondary ${
                      this.checkTipoBeneficio("DESCUENTOABS") ? "active" : ""
                    }`}
                    onClick={() =>
                      this.onChangeMantenimientoTipoBeneficio("DESCUENTOABS")
                    }
                  >
                    <input
                      type="radio"
                      name="mantenimientoTipoBeneficio"
                      id="DESCUENTOABS"
                      defaultChecked={this.checkTipoBeneficio("DESCUENTOABS")}
                    />
                    Descuento
                  </label>
                  <UncontrolledTooltip
                    placement="bottom"
                    target={`btn-chk-DESCUENTOABS`}
                  >
                    Descuento absoluto al mantenimiento
                  </UncontrolledTooltip>
                </div>
                <span className={s.errorSpan}>
                  {this.onFieldValidation("mantenimientoTipoBeneficio").join(
                    " "
                  )}
                </span>
              </div>
            </div>

            <div className="col">
              <div className={`${s.inputContainer} input-group`}>
                <BaseInput
                  label={"Descuento por integrante"}
                  name="mantenimientoValorBeneficio"
                  type="number"
                  id="mantenimientoValorBeneficio"
                  value={this.state.fields.mantenimientoValorBeneficio}
                  onChange={evt =>
                    this.onChangeInput(evt, "mantenimientoValorBeneficio")
                  }
                  onBlur={() => this.onBlurField("mantenimientoValorBeneficio")}
                  errors={this.onFieldValidation("mantenimientoValorBeneficio")}
                />
                <div className={`${s.prepend} input-group-prepend`}>
                  <span className="input-group-text">
                    {this.state.fields.mantenimientoTipoBeneficio ===
                    "PORCENTAJE"
                      ? "%"
                      : "$"}
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* //// */}

          {this.renderAlert()}
          <p className="primaryTitle w-100 mt-4">Empresas asociadas</p>

          {/* /// JSX de búsqueda por nombre de empresa */}
          <div className="form-row">
            <div className="col">
              <BaseInput
                label={"Empresa"}
                name="empresa"
                type="text"
                id="empresa"
                placeholder="Búsqueda por nombre de empresa"
                value={this.state.empresaFinder}
                onChange={this.onChangeEmpresaFilter}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="col-3 mt-4">
              <div style={{ display: "inline-block", marginRight: "8px" }}>
                <input
                  id="all-empresas"
                  onChange={this.onCheckAll}
                  type="checkbox"
                  style={{
                    position: "unset",
                    margin: "0px",
                    marginLeft: "0px"
                  }}
                />
              </div>
              <label htmlFor="all-empresas" style={{ display: "inline-block" }}>
                Seleccionar todas
              </label>
            </div>
          </div>

          <div
            style={{
              overflowY: "scroll",
              overflowX: "hidden",
              border: "1px #999 solid",
              height: "calc(100vh - 500px)",
              marginTop: "16px"
            }}
          >
            <div className="container">
              <div className="row">{this.renderEmpresa()}</div>
            </div>
          </div>

          <div className="row">
            <div className="col" />
            <div className="col-2">
              <Button
                className={`mt-3 ${s.primaryButton}`}
                onClick={this.submit}
                size="sm"
                block
              >
                Guardar
              </Button>
            </div>
          </div>
          {/* //// */}
        </form>
      </div>
    );
  }

  renderEmpresa() {
    /// JSX de checkboxes de Empresas
    const tituloValidas = (
      <div
        className="col-12 mb-3"
        style={{ padding: "16px 8px", background: "#ececec", fontSize: "14px" }}
      >
        EMPRESAS ASIGNABLES
      </div>
    );

    const empresasValidas = this.state.empresas
      .filter(e => e.asignable)
      .map(e => (
        <div key={"empresa-ls-" + e.id} className="col-3 mb-2">
          <div
            id={"empresa-check-" + e.id}
            style={{
              display: "inline-block",
              marginRight: "8px",
              paddingTop: "1px",
              verticalAlign: "top"
            }}
          >
            <input
              onChange={evt => this.onCheckEmpresa(evt, e.id)}
              type="checkbox"
              checked={this.state.fields.empresas.indexOf(e.id) >= 0}
              style={{ position: "unset", margin: "0px", marginLeft: "0px" }}
            />
          </div>
          <label
            htmlFor={"empresa-check-" + e.id}
            style={{
              display: "inline-block",
              width: "calc(100% - 24px)",
              fontSize: "12px"
            }}
          >
            {e.nombre}
          </label>
        </div>
      ));

    const tituloInvalidas = (
      <div
        className="col-12 mb-3"
        style={{ padding: "16px 8px", background: "#ececec", fontSize: "14px" }}
      >
        EMPRESAS ASIGNADAS A OTROS CONVENIOS
      </div>
    );

    const empresasInvalidas = this.state.empresas
      .filter(e => !e.asignable)
      .map(e => (
        <div key={"empresa-ls-" + e.id} className="col-3 mb-2">
          <div
            style={{
              display: "inline-block",
              marginRight: "8px",
              paddingTop: "1px",
              verticalAlign: "top"
            }}
          >
            <input
              type="checkbox"
              disabled
              style={{ position: "unset", margin: "0px", marginLeft: "0px" }}
            />
          </div>
          <div
            style={{
              display: "inline-block",
              width: "calc(100% - 24px)",
              fontSize: "12px",
              color: "#777"
            }}
          >
            {e.nombre}
          </div>
        </div>
      ));

    if (empresasValidas.length > 0 && empresasInvalidas.length > 0) {
      return (
        <>
          {tituloValidas}
          {empresasValidas}
          {tituloInvalidas}
          {empresasInvalidas}
        </>
      );
    } else if (empresasValidas.length) {
      return (
        <>
          {tituloValidas}
          {empresasValidas}
        </>
      );
    } else if (empresasInvalidas.length > 0) {
      return (
        <>
          {tituloInvalidas}
          {empresasInvalidas}
        </>
      );
    } else {
      return <></>;
    }
    ////
  }

  renderAlert() {
    /// JSX de alerta de errores o warnings
    const empresasEnConvenio: number[] =
      this.props.convenioDatosBasicos.empresas || [];
    const mantenimientoTipoBeneficio = this.state.fields
      .mantenimientoTipoBeneficio;

    if (mantenimientoTipoBeneficio === "PRECIOFIJO") {
      return (
        <div className="alert alert-warning mt-3 mb-3">
          Especificar el precio fijo del mantenimiento implica su actualización
          periódica; considere esto en el cierre anual.
        </div>
      );
    }

    if (mantenimientoTipoBeneficio === "DESCUENTOABS") {
      return (
        <div className="alert alert-warning mt-3 mb-3">
          Especificar un descuento absoluto en el mantenimiento implica su
          actualización periódica; considere esto en el cierre anual.
        </div>
      );
    }

    if (
      !this.props.convenioDatosBasicosLoading &&
      this.props.id &&
      empresasEnConvenio.length == 0
    ) {
      return (
        <div className="alert alert-warning mt-3 mb-3">
          El convenio no tienen asociada ninguna empresa.
        </div>
      );
    }

    return <></>;
    ////
  }
  ////
}
