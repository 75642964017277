import React from "react";
import { DateItem } from "./types";

interface Props {
  date: DateItem;
  soportaAlterna: boolean;
}

interface State {
  selected: "ORIGINAL" | "ALTERNA";
}

/**
 * Representación de un día dentro del calendario
 */
export class DateComponent extends React.Component<Props, State> {
  state = {
    selected: this.props.date.selected
  };

  /**
   * Listener de click en fechas
   */
  onClickDate = () => {
    if (this.props.date.disabled) {
      return;
    }

    const selected = this.nextClick();
    this.props.date.selected = selected;
    this.setState({ selected });
  };

  nextClick = () => {
    switch (this.state.selected) {
      case "ORIGINAL":
        return this.props.soportaAlterna ? "ALTERNA" : null;
      case "ALTERNA":
        return null;
      default:
        return "ORIGINAL";
    }
  };

  render() {
    let className = "sw-calendar date";

    if (this.props.date.disabled) {
      className += " disabled";
      if (this.props.date.selected) {
        switch (this.props.date.selected) {
          case "ORIGINAL":
            className += " selected afiliacion-original";
            break;
          case "ALTERNA":
            className += " selected afiliacion-alterna";
            break;
        }
      }
    } else if (this.state.selected) {
      switch (this.state.selected) {
        case "ORIGINAL":
          className += " selected afiliacion-original";
          break;
        case "ALTERNA":
          className += " selected afiliacion-alterna";
          break;
      }
    }

    return (
      <div className={className} onClick={this.onClickDate}>
        {this.props.date.moment.date()}
      </div>
    );
  }
}
