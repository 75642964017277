import isNil from "lodash.isnil";
import React from "react";
import { connect } from "react-redux";
import { getClubsRequest } from "../../../Home/actions/catalogs";
import { Club } from "../../../types-business/Clubs";
import { handleRequestError } from "../../../utils/helpers";
import { ClubSelector } from "../../components/ClubSelector";

interface Props {
  disabled?: boolean;
  onChange: (value: string) => any;
  value: string;
  userClub: string;
  label?: string;
  migrado?: boolean;
}

interface State {
  clubs: Club[];
  loading: boolean;
}

class ClubSelectorContainer extends React.Component<Props, State> {
  state = {
    clubs: [],
    loading: false
  };

  componentDidMount() {
    if (!this.props.disabled) {
      this.setState({ loading: true });
      getClubsRequest()
        .then(({ data }) => {
          this.setState({ clubs: data, loading: false });
        })
        .catch(handleRequestError);
    }
  }

  getFilteredClubs = () => {
    if (!isNil(this.props.migrado)) {
      if (!!this.props.migrado) {
        return (this.state.clubs || []).filter(x => !!x.migradoCrm2);
      } else {
        return (this.state.clubs || []).filter(x => !x.migradoCrm2);
      }
    } else {
      return [...this.state.clubs];
    }
  };

  render() {
    return (
      <ClubSelector
        value={this.props.userClub || this.props.value}
        clubs={this.getFilteredClubs()}
        onChange={this.props.onChange}
        disabled={
          this.state.loading || this.props.disabled || this.props.userClub
        }
        label={this.props.label}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClubSelectorContainer);
