import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import { UserContext, WHO_AM_I_STATE } from "../../types-business/Profile";
import { setFunctionalityTree, cleanUp } from "../../utils/accessTree";
import {
  infoNotification,
  SAVE_USER_PENDING,
  errorNotification,
  SAVE_USER_ERROR,
  successNotification,
  SAVE_USER,
  SAVE_ROLES_USER_PENDING,
  SAVE_ROLES_USER_ERROR,
  SAVE_ROLES_USER,
  SAVE_USER_PERMITS_PENDING,
  SAVE_USER_PERMITS_ERROR,
  SAVE_USER_PERMITS,
  GET_KITS_MESSAGE_PENDING,
  GET_KITS_MESSAGE_ERROR,
  GET_KITS_MESSAGE_FULLIED,
  GET_KITS_RESPONSIBLES_MESSAGE_PENDING,
  GET_KITS_RESPONSIBLES_MESSAGE_ERROR,
  GET_KITS_RESPONSIBLES_MESSAGE_FULLIED,
  ASSIGN_TAG_MESSAGE_PENDING,
  ASSIGN_TAG_MESSAGE_ERROR,
  ASSIGN_TAG_MESSAGE_FULLIED,
  DELLOCATION_TAG_MESSAGE_PENDING,
  DELLOCATION_TAG_MESSAGE_ERROR,
  DELLOCATION_TAG_MESSAGE_FULLIED,
  ERROR
} from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";

interface Prospect {
  personaId: string;
}

interface User {
  id: string;
  name: string;
  username: string;
}

export interface GetUsers {
  gettingUsersError: any;
  usersPage: any;
  usersList: any[];
  gettingUsers: boolean;
}

interface State extends GetUsers {
  listFitkits: object;
  chargeListFitkits: boolean;

  listResponsibleFitkits: object;
  chargeListResponsibleFitkits: boolean;

  chargeAssignTag: boolean;
  responseAssignTag: object;

  chargeDellocationTag: boolean;
  responseDellocationTag: object;

  userContext?: UserContext;
  editableUser?: UserContext;

  updatingProfile: boolean;
  updateProfileError: object;
  updateExternalUser: boolean;

  loginUser: boolean;
  successfullLogin: boolean;
  iKnowWhoIAm: WHO_AM_I_STATE;
  loginUserError: object;

  activatingAccount: boolean;
  activateAccountError: object;

  creatingUser: boolean;
  createUserError: object;
  createUserRequested: boolean;

  requestingForgotPassword: boolean;
  forgotPasswordError: object;
  forgotPasswordRequested: boolean;

  requestingResetPassword: boolean;
  resetPasswordError: object;
  resetPasswordRequested: boolean;

  gettingProspectsError: object;
  prospects: Prospect[];
  searchingProspects: boolean;

  gettingGeneralManagersError: object;
  users: object[];
  generalManagers: object[];
  searchingGeneralManagers: boolean;
  repeatedProspects: object[];

  prospectDetailLoading: boolean;
  editingProspectDetail: object[];
  prospectDetailError: boolean;
  treeAvailable: boolean;

  savingUser: boolean;
  basicUserSaved: object;
  gettingBasicUser: boolean;
  gettingSecuritySpaces: boolean;
  successfullSecuritySpaces: boolean;
  securitySpacesError: object;
  permissionsTree: object;

  rolesUserSaved: boolean;
  rolesUser: object;
  gettingRolesUser: boolean;

  userPermitsSaved: boolean;
  permitsUser: number[];
  userById: object;
  logOutSuccess: boolean;
}
const initialState: State = {
  listFitkits: null,
  chargeListFitkits: false,
  listResponsibleFitkits: null,
  chargeListResponsibleFitkits: false,
  chargeAssignTag: false,
  responseAssignTag: null,
  chargeDellocationTag: false,
  responseDellocationTag: null,
  usersPage: null,
  userContext: null,
  editableUser: null,
  permissionsTree: null,
  updatingProfile: false,
  updateProfileError: null,
  updateExternalUser: null,

  loginUser: false,
  successfullLogin: false,
  loginUserError: null,
  gettingSecuritySpaces: false,
  successfullSecuritySpaces: false,
  securitySpacesError: null,
  iKnowWhoIAm: WHO_AM_I_STATE.EMPTY,
  activatingAccount: false,
  activateAccountError: null,

  creatingUser: false,
  createUserError: null,
  createUserRequested: null,

  requestingForgotPassword: false,
  forgotPasswordError: null,
  forgotPasswordRequested: null,

  requestingResetPassword: false,
  resetPasswordError: null,
  resetPasswordRequested: null,

  gettingProspectsError: null,
  prospects: null,
  searchingProspects: false,

  gettingUsersError: null,
  gettingGeneralManagersError: null,
  users: null,
  generalManagers: [],
  searchingGeneralManagers: false,
  repeatedProspects: [],

  prospectDetailLoading: false,
  editingProspectDetail: null,
  prospectDetailError: false,
  treeAvailable: false,

  gettingBasicUser: false,
  savingUser: false,
  basicUserSaved: null,

  rolesUserSaved: false,
  rolesUser: null,
  gettingRolesUser: false,

  userPermitsSaved: false,
  permitsUser: null,
  gettingUsers: false,
  userById: null,
  usersList: [],
  logOutSuccess: false
};

function getErrorMessage(payload) {
  if (payload.response && payload.response.data) {
    return {
      message: payload.response.data.message || "Ocurrió un error inesperado",
      error_description:
        payload.response.data.error_description ||
        payload.response.data.message ||
        "Ocurrió un error inesperado"
    };
  }

  return {
    error_description: "Ocurrió un error inesperado"
  };
}
function getLoginErrorMessage(payload) {
  const error = {
    message: "",
    error_description: ""
  };

  if (payload.response) {
    const code = payload.response.status;
    error.message = payload.response.message;

    if (code >= 400 && code < 500) {
      error.error_description = "Nombre de usuario o contraseña inválido";
    } else {
      error.error_description = "Ocurrió un error inesperado";
    }
  } else {
    error.message = "Ocurrió un error inesperado";
    error.error_description = "Ocurrió un error inesperado";
  }

  return error;
}

export default function loginReducer(
  state: State = initialState,
  action: IAction
): State {
  const snapshot = snapshotAction(state, action);

  // Llamada a resolver de snapshot
  state != snapshot &&
    !action.type.endsWith("_PENDING") &&
    action.meta &&
    action.meta.resolver &&
    action.meta.resolver({
      fulfilled: action.type.endsWith("_FULFILLED"),
      ...snapshot
    });

  return state != snapshot
    ? {
        ...state,
        ...snapshot
      }
    : state;
}

function snapshotAction(
  state: State = initialState,
  action: IAction
): Partial<State> {
  switch (action.type) {
    case actionTypes.SET_USER_CONTEXT: {
      return {
        userContext: action.payload
      };
    }
    case `${actionTypes.GET_PROSPECTS}_PENDING`: {
      return {
        gettingProspectsError: null,
        searchingProspects: true
      };
    }
    case `${actionTypes.GET_PROSPECTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingProspectsError: getErrorMessage(action.payload),
        searchingProspects: false
      };
    }
    case `${actionTypes.GET_PROSPECTS}_FULFILLED`: {
      return {
        gettingProspectsError: null,
        prospects: action.payload.data,
        searchingProspects: false
      };
    }
    case `${actionTypes.UPDATE_PROSPECT_DATA_BY_ID}`: {
      return {
        prospects: state.prospects.map(prospect =>
          prospect.personaId == action.payload.personaId
            ? { ...prospect, ...action.payload }
            : { ...prospect }
        )
      };
    }
    case `${actionTypes.GET_PROSPECTS_REPEATED}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {};
    }
    case `${actionTypes.GET_PROSPECTS_REPEATED}_FULFILLED`: {
      return {
        repeatedProspects: action.payload.data.content
      };
    }
    case `${actionTypes.GET_PROSPECTS_REPEATED}_PENDING`: {
      return {};
    }
    case `${actionTypes.GET_USERS}_PENDING`: {
      return {
        gettingUsersError: null,
        gettingUsers: true,
        usersPage: null
      };
    }
    case `${actionTypes.GET_USERS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingUsersError: getErrorMessage(action.payload),
        gettingUsers: false,
        usersPage: null
      };
    }
    case `${actionTypes.GET_USERS}_FULFILLED`: {
      return {
        gettingUsersError: null,
        usersPage: action.payload.data,
        usersList: action.payload.data,
        gettingUsers: false
      };
    }
    case `${actionTypes.GET_GENERAL_MANAGERS}_PENDING`: {
      return {
        gettingGeneralManagersError: null,
        searchingGeneralManagers: true
      };
    }
    case `${actionTypes.GET_GENERAL_MANAGERS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingGeneralManagersError: getErrorMessage(action.payload),
        searchingGeneralManagers: false
      };
    }
    case `${actionTypes.GET_GENERAL_MANAGERS}_FULFILLED`: {
      return {
        gettingGeneralManagersError: null,
        generalManagers: action.payload.data.content,
        searchingGeneralManagers: false
      };
    }
    case `${actionTypes.FUNCTIONALITY_TREE}_PENDING`: {
      return {
        treeAvailable: false
      };
    }
    case `${actionTypes.FUNCTIONALITY_TREE}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        treeAvailable: false
      };
    }
    case `${actionTypes.FUNCTIONALITY_TREE}_FULFILLED`: {
      setFunctionalityTree(action.payload.data);
      return {
        treeAvailable: true
      };
    }
    case `${actionTypes.PERMISSION_TREE}_PENDING`: {
      return {};
    }
    case `${actionTypes.PERMISSION_TREE}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {};
    }
    case `${actionTypes.PERMISSION_TREE}_FULFILLED`: {
      const permisssions = action.payload.data;
      const permissionsTree = {
        permissionId: "FAKE_ROOT_PERMISSION",
        area: null,
        description: "Root",
        childPermissions: [...permisssions]
      };

      const setParents = (node, padre) => {
        node.padre = padre;
        node.description = node.description.toUpperCase();
        node.permissionId = String(node.permissionId);
        if (node.childPermissions)
          node.childPermissions.forEach(child => setParents(child, node));
      };

      setParents(permissionsTree, null);

      return {
        permissionsTree
      };
    }
    case `${actionTypes.WHO_AM_I}_PENDING`: {
      return {
        loginUserError: null,
        loginUser: true,
        iKnowWhoIAm: WHO_AM_I_STATE.PROGRESS
      };
    }
    case `${actionTypes.WHO_AM_I}_REJECTED`: {
      // errorNotification(readMessageBack(action, ERROR));
      return {
        loginUserError: null,
        loginUser: false,
        iKnowWhoIAm: WHO_AM_I_STATE.FAILED
      };
    }
    case `${actionTypes.WHO_AM_I}_FULFILLED`: {
      return {
        userContext: action.payload.data || this.state.userContext,
        loginUserError: null,
        loginUser: false,
        iKnowWhoIAm: WHO_AM_I_STATE.SUCCESS
      };
    }

    case `${actionTypes.LOGIN_USER_SSP}_PENDING`: {
      return {
        loginUserError: null,
        loginUser: true
      };
    }
    case `${actionTypes.LOGIN_USER_SSP}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        loginUserError: getErrorMessage(action.payload),
        loginUser: false
      };
    }
    case `${actionTypes.LOGIN_USER_SSP}_FULFILLED`: {
      return {
        userContext: action.payload.data || this.state.userContext,
        loginUserError: null,
        loginUser: false,
        successfullLogin: true
      };
    }
    case `${actionTypes.GET_USER_SSP}_PENDING`: {
      return {
        securitySpacesError: null,
        gettingSecuritySpaces: true
      };
    }
    case `${actionTypes.GET_USER_SSP}_REJECTED`: {
      let loginErrorMessage = getLoginErrorMessage(action.payload);
      errorNotification(loginErrorMessage.error_description);
      return {
        securitySpacesError: loginErrorMessage,
        gettingSecuritySpaces: false
      };
    }
    case `${actionTypes.GET_USER_SSP}_FULFILLED`: {
      const userContext = state.userContext || {};
      return {
        userContext: { ...userContext, ...action.payload.data },
        securitySpacesError: null,
        gettingSecuritySpaces: false,
        successfullSecuritySpaces: true
      };
    }
    case `${actionTypes.LOGOUT_USER}_PENDING`: {
      return {};
    }
    case `${actionTypes.LOGOUT_USER}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {};
    }
    case `${actionTypes.LOGOUT_USER}_FULFILLED`: {
      return {};
    }
    case `${actionTypes.GET_PROSPECT_BY_ID}_PENDING`: {
      return {
        prospectDetailLoading: true,
        prospectDetailError: false
      };
    }
    case `${actionTypes.GET_PROSPECT_BY_ID}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        prospectDetailLoading: false,
        prospectDetailError: true
      };
    }
    case `${actionTypes.GET_PROSPECT_BY_ID}_FULFILLED`: {
      return {
        editingProspectDetail: action.payload.data,
        prospectDetailLoading: false,
        prospectDetailError: false
      };
    }
    case `${actionTypes.CLEAN_PROSPECT_BY_ID}`: {
      return {
        editingProspectDetail: null
      };
    }
    case `${actionTypes.SAVE_BASIC_USER}_PENDING`: {
      infoNotification(SAVE_USER_PENDING);
      return {
        savingUser: true
      };
    }
    case `${actionTypes.SAVE_BASIC_USER}_REJECTED`: {
      errorNotification(readMessageBack(action, SAVE_USER_ERROR));
      return {
        savingUser: false
      };
    }
    case `${actionTypes.SAVE_BASIC_USER}_FULFILLED`: {
      successNotification(SAVE_USER);
      return {
        savingUser: false,
        userById: action.payload.data,
        basicUserSaved: action.payload.data
      };
    }
    case `${actionTypes.CLEAR_BASIC_USER}_FULFILLED`: {
      successNotification(SAVE_USER);
      return {
        savingUser: false,
        userById: null,
        basicUserSaved: null
      };
    }

    case `${actionTypes.UPDATE_BASIC_USER}_PENDING`: {
      infoNotification(SAVE_USER_PENDING);
      return {
        savingUser: false
      };
    }
    case `${actionTypes.UPDATE_BASIC_USER}_REJECTED`: {
      errorNotification(readMessageBack(action, SAVE_USER_ERROR));
      return {
        savingUser: false
      };
    }
    case `${actionTypes.UPDATE_BASIC_USER}_FULFILLED`: {
      successNotification(SAVE_USER);
      return {
        savingUser: true,
        userById: action.payload.data,
        basicUserSaved: action.payload.data
      };
    }
    case `${actionTypes.GET_ROLES_USER}_PENDING`: {
      return {
        gettingRolesUser: true
      };
    }
    case `${actionTypes.GET_ROLES_USER}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingRolesUser: false
      };
    }
    case `${actionTypes.GET_ROLES_USER}_FULFILLED`: {
      return {
        gettingRolesUser: false,
        rolesUser: action.payload.data
      };
    }
    case `${actionTypes.SAVE_ROLES_USER}_PENDING`: {
      infoNotification(SAVE_ROLES_USER_PENDING);
      return {
        rolesUserSaved: false
      };
    }
    case `${actionTypes.SAVE_ROLES_USER}_REJECTED`: {
      errorNotification(readMessageBack(action, SAVE_ROLES_USER_ERROR));
      return {
        rolesUserSaved: false
      };
    }
    case `${actionTypes.SAVE_ROLES_USER}_FULFILLED`: {
      successNotification(SAVE_ROLES_USER);
      return {
        rolesUserSaved: true,
        rolesUser: action.payload.data
      };
    }

    case `${actionTypes.SAVE_USER_PERMITS}_PENDING`: {
      infoNotification(SAVE_USER_PERMITS_PENDING);
      return {
        userPermitsSaved: false
      };
    }
    case `${actionTypes.SAVE_USER_PERMITS}_REJECTED`: {
      errorNotification(readMessageBack(action, SAVE_USER_PERMITS_ERROR));
      return {
        userPermitsSaved: false
      };
    }
    case `${actionTypes.SAVE_USER_PERMITS}_FULFILLED`: {
      successNotification(SAVE_USER_PERMITS);
      return {
        userPermitsSaved: true,
        permitsUser: action.payload.data
      };
    }
    case `${actionTypes.GET_USER_PERMITS}_PENDING`: {
      return {
        permitsUser: null
      };
    }
    case `${actionTypes.GET_USER_PERMITS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        permitsUser: null
      };
    }
    case `${actionTypes.GET_USER_PERMITS}_FULFILLED`: {
      return {
        permitsUser: action.payload.data
      };
    }
    case `${actionTypes.GET_USER_BY_ID}_PENDING`: {
      return {
        userById: null,
        gettingBasicUser: true
      };
    }
    case `${actionTypes.GET_USER_BY_ID}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        userById: null,
        gettingBasicUser: false
      };
    }
    case `${actionTypes.GET_USER_BY_ID}_FULFILLED`: {
      return {
        gettingBasicUser: false,
        userById: action.payload.data
      };
    }
    case `${actionTypes.GET_KITS}_PENDING`: {
      infoNotification(GET_KITS_MESSAGE_PENDING);
      return {
        chargeListFitkits: false,
        listFitkits: null
      };
    }
    case `${actionTypes.GET_KITS}_REJECTED`: {
      errorNotification(readMessageBack(action, GET_KITS_MESSAGE_ERROR));
      return {
        chargeListFitkits: false,
        listFitkits: null
      };
    }
    case `${actionTypes.GET_KITS}_FULFILLED`: {
      successNotification(GET_KITS_MESSAGE_FULLIED);
      return {
        chargeListFitkits: true,
        listFitkits: action.payload.data
      };
    }
    case `${actionTypes.GET_RESPONSIBLES_KITS}_PENDING`: {
      infoNotification(GET_KITS_RESPONSIBLES_MESSAGE_PENDING);
      return {
        chargeListResponsibleFitkits: false,
        listResponsibleFitkits: null
      };
    }
    case `${actionTypes.GET_RESPONSIBLES_KITS}_REJECTED`: {
      errorNotification(
        readMessageBack(action, GET_KITS_RESPONSIBLES_MESSAGE_ERROR)
      );
      return {
        chargeListResponsibleFitkits: false,
        listResponsibleFitkits: null
      };
    }
    case `${actionTypes.GET_RESPONSIBLES_KITS}_FULFILLED`: {
      successNotification(GET_KITS_RESPONSIBLES_MESSAGE_FULLIED);
      return {
        chargeListResponsibleFitkits: true,
        listResponsibleFitkits: action.payload.data
      };
    }
    case `${actionTypes.ASSIGN_TAG}_PENDING`: {
      infoNotification(ASSIGN_TAG_MESSAGE_PENDING);
      return {
        chargeAssignTag: false,
        responseAssignTag: null
      };
    }
    case `${actionTypes.ASSIGN_TAG}_REJECTED`: {
      errorNotification(readMessageBack(action, ASSIGN_TAG_MESSAGE_ERROR));
      return {
        chargeAssignTag: false,
        responseAssignTag: null
      };
    }
    case `${actionTypes.ASSIGN_TAG}_FULFILLED`: {
      successNotification(ASSIGN_TAG_MESSAGE_FULLIED);
      return {
        chargeAssignTag: true,
        responseAssignTag: action.payload.data
      };
    }
    case `${actionTypes.DELLOCATION_TAG}_PENDING`: {
      infoNotification(DELLOCATION_TAG_MESSAGE_PENDING);
      return {
        chargeDellocationTag: false,
        responseDellocationTag: null
      };
    }
    case `${actionTypes.DELLOCATION_TAG}_REJECTED`: {
      errorNotification(readMessageBack(action, DELLOCATION_TAG_MESSAGE_ERROR));
      return {
        chargeDellocationTag: false,
        responseDellocationTag: null
      };
    }
    case `${actionTypes.DELLOCATION_TAG}_FULFILLED`: {
      successNotification(DELLOCATION_TAG_MESSAGE_FULLIED);
      return {
        chargeDellocationTag: true,
        responseDellocationTag: action.payload.data
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
