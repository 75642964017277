import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import ModalFrame from "../../../../toolkit/modalFrame";
import {
  Button,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip
} from "reactstrap";
import s from "./selectMaintenancesModal.scss";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  maintenances: { maintenanceId: string; nombre: string }[];
  maintenancesInUse: any[];
  isOpen: boolean;
  history: any;
  toggle: () => void;
  setMaintenances: (maintenances: object[]) => any;
}

class SelectMaintenanceSportsProduct extends React.Component<Props> {
  state = {
    fields: {},
    errors: {},
    selectedMaintenances: []
  };

  checkMaintenance = async e => {
    const checked = e.target.checked;
    const val = parseInt(e.target.value, 10);
    const selectedMaintenances = checked
      ? [...(this.state.selectedMaintenances || []), val]
      : (this.state.selectedMaintenances || []).filter(x => x != val);
    await this.setState({ selectedMaintenances: [...selectedMaintenances] });
  };

  selectAll = async e => {
    e.preventDefault();
    const selectedMaintenances = [
      ...this.props.maintenances.map(x => x.maintenanceId)
    ];
    await this.setState({ selectedMaintenances: [...selectedMaintenances] });
  };

  unselectAll = async e => {
    e.preventDefault();
    await this.setState({ selectedMaintenances: [] });
  };

  render() {
    return (
      <ModalFrame
        title={`Seleccionar mantenimientos`}
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle()}
        size="lg"
      >
        <Container style={{ width: "90%" }} className="center-block p-0 mb-3">
          <Row className="m-0">
            <Container className="w-100 p-0 m-0">
              <Row>
                <Col />
                <Col xs="2">
                  <Button
                    id={"maintenanceSelectorSelectAll"}
                    onClick={e => this.selectAll(e)}
                    color="link"
                    className="text-success"
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </Button>
                  <Button
                    id={"maintenanceSelectorUnSelectAll"}
                    onClick={e => this.unselectAll(e)}
                    color="link"
                    className="text-danger"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>

                  <UncontrolledTooltip
                    placement="left"
                    target={`maintenanceSelectorSelectAll`}
                  >
                    Selecionar todos
                  </UncontrolledTooltip>
                  <UncontrolledTooltip
                    placement="right"
                    target={`maintenanceSelectorUnSelectAll`}
                  >
                    Deseleccionar todos
                  </UncontrolledTooltip>
                </Col>
                <Col />
              </Row>
              <Row className={s.checkBoxesContainer}>
                {this.props.maintenances &&
                  this.props.maintenances.map(maintenance => (
                    <Col
                      className={s.labelContainer}
                      key={maintenance.maintenanceId}
                      xs="3"
                    >
                      <FormGroup className={s.label} check>
                        <Label check>
                          <Input
                            type="checkbox"
                            value={maintenance.maintenanceId}
                            checked={
                              this.state.selectedMaintenances.indexOf(
                                maintenance.maintenanceId
                              ) >= 0
                            }
                            onChange={e => this.checkMaintenance(e)}
                          />
                          {maintenance.nombre}
                        </Label>
                      </FormGroup>
                    </Col>
                  ))}
              </Row>
            </Container>
          </Row>
          <Row className="m-0">
            <Col />
            <Col className="p-0" xs="2">
              <Button
                className={`${s.primaryButton} mt-3`}
                onClick={e =>
                  this.props.setMaintenances(
                    this.props.maintenances.filter(
                      x =>
                        this.state.selectedMaintenances.indexOf(
                          x.maintenanceId
                        ) >= 0
                    )
                  )
                }
                size="sm"
                block
              >
                Aceptar
              </Button>
            </Col>
          </Row>
        </Container>
      </ModalFrame>
    );
  }
}

export default withRouter(withStyles(s)(SelectMaintenanceSportsProduct));
