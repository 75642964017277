import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import ReactTable from "react-table";
import { Container, Row, Col, Input, Button } from "reactstrap";
import s from "./styles.scss";
import {
  FILTERS,
  FILTERS_REASSIGN,
  REASIGN_MEMBERSHIP_STATE
} from "./interfaces";
import ReportFilter from "./ReportFilter";
import MexicanCurrency from "../common/currency";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import ReAsignMembershipModal from "./reAsignMembershipModal";

const changeSellStyles = {
  fontSize: "1.15rem",
  color: "blue",
  display: "block",
  textAlign: "center",
  cursor: "pointer"
};

interface Props {
  reasignMembership: boolean;
  clubs: object;
  periods: object;
  reportMembershipSold: object[];
  sellers: object[];
  getMembershipSoldReport: (FILTERS) => void;
  sendReasignMembership: (filters: object) => void;
  clearReasignMembership: () => void;
}

interface State extends FILTERS_REASSIGN {}

class MembershipSoldReport extends React.Component<Props, State> {
  state = {
    idClub: null,
    idMembresia: null,
    idUsuario: null,
    isModalOpen: false,
    membresia: null,
    nombreClub: null,
    nombreVendedor: null,
    filtersModal: null
  };

  reAsignMembership = ({
    idClubBase,
    idMembresiaSocio,
    vendedor,
    membresia,
    nombreClub,
    nombreVendedor
  }) => {
    let state = { ...this.state };
    state.idClub = idClubBase;
    state.idMembresia = idMembresiaSocio;
    state.idUsuario = vendedor;
    state.idUsuario = vendedor;
    state.isModalOpen = !state.isModalOpen;
    state.membresia = membresia;
    state.nombreClub = nombreClub;
    state.nombreVendedor = nombreVendedor;
    this.setState(state);
  };

  toggleModal = () => {
    let state = { ...this.state };
    (state.isModalOpen = !state.isModalOpen), this.setState(state);
  };

  preSendReasignMembership = async (filters: REASIGN_MEMBERSHIP_STATE) => {
    const { vendedorId, membresiaSocioId } = filters;
    this.props.sendReasignMembership({ vendedorId, membresiaSocioId });
    let state = { ...this.state };
    state.filtersModal = filters;
  };
  componentDidUpdate = async () => {
    if (this.props.reasignMembership) {
      this.props.clearReasignMembership();
    }
  };

  render() {
    const membershipSoldTableReport = [
      {
        Header: "Club",
        accessor: "nombreClub",
        className: "text-center"
      },
      {
        Header: "# Membresía",
        accessor: "idPublico",
        className: "text-center"
      },
      {
        Header: "Vendedor",
        accessor: "nombreVendedor",
        width: 150
      },
      {
        Header: "Fecha adquisicion",
        accessor: "fechaAdquisicion",
        Cell: row => (
          <p className="text-center m-0 p-0">
            {moment(row.value).format("YYYY-MM-DD")}
          </p>
        )
      },
      {
        Header: "Fecha inicio mantenimiento",
        accessor: "fechaInicioMantenimiento",
        Cell: row => (
          <p className="text-center m-0 p-0">
            {moment(row.value).format("YYYY-MM-DD")}
          </p>
        )
      },
      {
        Header: "Fecha pago",
        accessor: "fechaPago",
        Cell: row => (
          <p className="text-center m-0 p-0">
            {moment(row.value).format("YYYY-MM-DD")}
          </p>
        )
      },
      {
        Header: "Precio base membresia",
        accessor: "precioBaseMembresia",
        Cell: row => (
          <p className="text-right m-0 p-0">
            <MexicanCurrency quantity={row.value} />
          </p>
        )
      },
      {
        Header: "Precio venta membresia",
        accessor: "precioVentaMembresia",
        Cell: row => (
          <p className="text-right m-0 p-0">
            <MexicanCurrency quantity={row.value} />
          </p>
        ),
        width: 80
      },
      {
        Header: "Estatus membresia",
        accessor: "estatusMembresia",
        className: "text-center"
      },
      {
        Header: "Expediente autorizado",
        accessor: "expedienteAutorizado",
        width: 150,
        className: "text-center"
      },
      {
        Header: "Cargos automaticos",
        accessor: "cargosAutomaticosAsignados",
        width: 150,
        className: "text-center"
      },
      {
        Header: "Reasignar venta",
        Cell: row => (
          <div>
            <a
              style={changeSellStyles}
              onClick={() => this.reAsignMembership(row.original)}
            >
              <FontAwesomeIcon icon={faExchangeAlt} />
              &nbsp;
            </a>
          </div>
        ),
        width: 150
      }
    ];

    return (
      <Container className="mt-3 mb-5 p-0">
        <Row className="mb-1">
          <Col sm="12">Ingresa los campos requeridos para comenzar ( * )</Col>
        </Row>

        {this.state.idMembresia && (
          <ReAsignMembershipModal
            {...this.state}
            sellers={this.props.sellers}
            toggle={this.toggleModal}
            sendReasignMembership={this.preSendReasignMembership}
          />
        )}

        <ReportFilter
          clubs={this.props.clubs}
          periods={this.props.periods}
          getMembershipSoldReport={this.props.getMembershipSoldReport}
          reasignMembership={this}
        />
        {this.props.reportMembershipSold && (
          <span>
            <hr />
            <Row className={"my-3"}>
              <Col sm="12">Resultados de Búsqueda</Col>
            </Row>
            <ReactTable
              pages={1}
              data={this.props.reportMembershipSold}
              columns={membershipSoldTableReport}
              showPagination={false}
              sortable={true}
              multiSort={true}
              resizable={true}
              pageSize={this.props.reportMembershipSold.length + 3}
            />
          </span>
        )}
      </Container>
    );
  }
}

export default withStyles(s)(MembershipSoldReport);
