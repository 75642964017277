import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import PromotionsReport from "../../components/promotionsReport";

interface Props {
  match: any;
  history: any;
}

interface State {
  products: object;
}

class PromotionsReportPage extends React.Component<Props, State> {
  state = { products: { content: [], totalPages: 0 } };

  render() {
    return (
      <main>
        <Helmet title="Reporte de promociones" />
        <Layout>
          <PromotionsReport {...this.props} />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionsReportPage);
