import React from "react";
import ReactTable from "react-table";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import s from "./styles.scss";

interface Props {
  sportsProgramCommisions: object[];
}

interface State {}

class SportsProgramsComissionsTable extends React.Component<Props, State> {
  getSportsProgramsCommisionsSource = () => {
    return this.props.sportsProgramCommisions || [];
  };

  render() {
    let columns = [
      {
        Header: () => <p className="font-weight-bold">Id producto instancia</p>,
        id: 0,
        accessor: "producto",
        Cell: row => {
          return <p>{row.original.productoInstanciaId}</p>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Nombre</p>,
        id: 1,
        accessor: "nombre",
        Cell: row => {
          return <p>{row.original.personaNombre}</p>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Monto</p>,
        id: 2,
        accessor: "monto",
        Cell: row => {
          return <p>$ {row.original.monto}</p>;
        }
      }
    ];

    return (
      <Container className="p-0 container" fluid>
        <Row className="row mt-3 mb-3">
          <Col>
            <p className={`${s.primaryTitle} w-100 m-0`}>Programas asociados</p>
          </Col>
        </Row>
        <Row>
          <Col className={s.customTable}>
            <ReactTable
              className="mt-5 -striped -highlight"
              data={this.getSportsProgramsCommisionsSource()}
              columns={columns}
              sortable={false}
              resizable={false}
              pageSize={
                !!this.props.sportsProgramCommisions &&
                !!this.props.sportsProgramCommisions.length
                  ? this.props.sportsProgramCommisions.length + 1
                  : 5
              }
              showPagination={false}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(SportsProgramsComissionsTable));
