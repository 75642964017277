import React from "react";
import s from "./styles.scss";

export const columns = [
  {
    Header: "Folio",
    id: "facturaFolio",
    accessor: simpleAccessor("facturaFolio"),
    className: s.tdclr,
    headerClassName: s.header,
    width: "120"
  },
  {
    Header: "UUID de Factura",
    id: "facturaUuid",
    accessor: simpleAccessor("facturaUuid"),
    className: s.tdclr,
    headerClassName: s.header,
    width: "255"
  },
  {
    Header: "Rfc de Receptor",
    id: "rfcReceptor",
    accessor: simpleAccessor("rfcReceptor"),
    className: s.tdclr,
    headerClassName: s.header,
    width: "120"
  },
  {
    Header: "Receptor",
    id: "receptor",
    accessor: trimAccessor("receptor", 64),
    className: s.tdclr,
    headerClassName: s.header,
    width: "255"
  },
  {
    Header: "Fecha sistema",
    id: "fechaActual",
    accessor: simpleAccessor("fechaActual"),
    className: s.tdclr,
    headerClassName: s.header,
    width: "128"
  },
  {
    Header: "Fecha SAT",
    id: "fechaReal",
    accessor: simpleAccessor("fechaReal"),
    className: s.tdclr,
    headerClassName: s.header,
    width: "128"
  },
  {
    Header: "Observaciones",
    id: "observaciones",
    accessor: simpleAccessor("observaciones"),
    className: s.tdclr,
    headerClassName: s.header,
    width: "512"
  }
];

function simpleAccessor(accessor) {
  return row => {
    let className = "";

    if (row.logLevel == "ERROR") {
      className = s.errorRow;
    } else if (row.logLevel == "WARN") {
      className = s.warnRow;
    }

    return (
      <div className={`${s.divRow} ${className}`}>
        {row[accessor] || <>&nbsp;</>}
      </div>
    );
  };
}

function trimAccessor(accessor, maxChars) {
  return row => {
    let className = "";
    let value = row[accessor] || "";
    value =
      value.length > maxChars ? value.substring(0, maxChars) + "..." : value;

    if (row.logLevel == "ERROR") {
      className = s.errorRow;
    } else if (row.logLevel == "WARN") {
      className = s.warnRow;
    }

    return (
      <div className={`${s.divRow} ${className}`}>{value || <>&nbsp;</>}</div>
    );
  };
}

export default columns;
