import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Row, Button, Container, Col, Label, Input } from "reactstrap";
import DatePicker from "../../common/DatePicker";
import BaseInput from "../../../../toolkit/baseInput";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";

interface OPTION_MEMBERSHIP {
  idPersona: string;
  nombre: string;
  primerApellido: string;
  segundoApellido: string;
}

interface Props {
  partnersMembership: OPTION_MEMBERSHIP[];
  onChangeFilter: any;
  onChangeCheck: any;
  titulo: string;
  alerta: boolean;
  openDatePicker: boolean;
  fechaAlerta: string;
  onChangeFecha: any;
  descripcion: string;
  returnListNotificaciones: any;
  addNotification: any;
  idMembresiaSocio: any;
}

class AddMessage extends React.Component<Props> {
  render() {
    const {
      partnersMembership,
      onChangeFilter,
      onChangeCheck,
      titulo,
      alerta,
      openDatePicker,
      fechaAlerta,
      onChangeFecha,
      descripcion,
      returnListNotificaciones,
      addNotification,
      idMembresiaSocio
    } = this.props;

    return (
      <Container className="p-0 container" fluid>
        <div className="row mt-3 mb-3">
          <div className="col">
            <p className={`${s.primaryTitle} w-100 m-0`}>Alta de mensajes</p>
          </div>
        </div>
        <Row>
          <Col>
            <BaseInput
              label="Socio"
              name="socio"
              id="socio"
              type="select"
              placeholder="Seleccione un socio"
              onChange={onChangeFilter("idMembresiaSocio")}
              size={"sm"}
              options={[{ value: "", label: "Seleccione un socio" }].concat(
                partnersMembership
                  ? partnersMembership.map(option => ({
                      value: option.idPersona.toString(),
                      label: `${option.nombre} ${option.primerApellido} ${option.segundoApellido}`
                    }))
                  : []
              )}
            />
          </Col>
          <Col>
            <BaseInput
              label={"Título"}
              name={"titulo"}
              id={"titulo"}
              placeholder="Título"
              value={titulo}
              size="sm"
              onChange={onChangeFilter("titulo")}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={s.colCustomCheck}>
              <Label check>
                <Input
                  type="checkbox"
                  checked={alerta}
                  onChange={e => onChangeCheck(e)}
                />
                Alerta
              </Label>
            </div>
          </Col>
          <Col>
            {openDatePicker ? (
              <DatePicker
                label="Fecha alerta"
                name="fechaAlerta"
                id="fechaAlerta"
                selected={fechaAlerta}
                onChange={onChangeFecha()}
                errors={[]}
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row>
          <Col>Descripción:</Col>
        </Row>
        <Row>
          <Col>
            <textarea
              name="descripcion"
              rows={6}
              id="descripcion"
              value={descripcion}
              className="form-control"
              onChange={onChangeFilter("descripcion")}
            />
          </Col>
        </Row>
        <Row className={s.customBotonera}>
          <Col></Col>
          <Col>
            <Button
              size="sm"
              className={`${s.searchButton} ${s.primaryButton} float-right btn-block`}
              onClick={returnListNotificaciones}
            >
              Listado de mensajes
            </Button>
          </Col>
          <Col>
            <Button
              size="sm"
              className={`${s.searchButton} ${s.primaryButton} float-right btn-block`}
              onClick={addNotification}
              disabled={
                idMembresiaSocio === "" ||
                idMembresiaSocio === undefined ||
                titulo === "" ||
                titulo === undefined ||
                descripcion === "" ||
                descripcion === undefined
              }
            >
              Guardar mensaje
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(AddMessage));
