import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Club } from "../../../types-business/Clubs";
import { handleRequestError } from "../../../utils/helpers";
import { getClubsRequest, getUsers } from "../../actions/catalogs";
import {
  getClassificationByLevel,
  getDemands,
  saveDetails,
  saveObservation
} from "../../actions/demans";
import DemandList from "../../components/demandList";
import Layout from "../../components/layout";
import {
  LEVEL_FOUR,
  LEVEL_ONE,
  LEVEL_THREE,
  LEVEL_TWO
} from "../productClassification/ClassificationComponent";

interface Props {
  getClassificationByLevel: (level: number) => any;
  getDemands: (demand: object) => any;
  demands: object[];
  loadingDemands: boolean;
  saveObservation: (id: number, observacion: object) => any;
  users: object[];
  getUsers: () => any;
  loadingUsers: boolean;
  saveDetails: (
    id: string,
    details: { responsableId: number; fechaCompromiso: string }
  ) => any;
}

interface State {
  classificationByLevelOne: object[];
  classificationByLevelTwo: object[];
  classificationByLevelThree: object[];
  classificationByLevelFour: object[];
  clubs: Club[];
}

class DemandsListContainer extends React.Component<Props, State> {
  state = {
    classificationByLevelOne: null,
    classificationByLevelTwo: null,
    classificationByLevelThree: null,
    classificationByLevelFour: null,
    clubs: null
  };

  componentDidMount() {
    this.getClubsMigradoActivo();
    //this.props.getUsers();
    this.props
      .getClassificationByLevel(LEVEL_ONE)
      .then(({ value: { data } }) => {
        this.setState({ classificationByLevelOne: data });
      });

    this.props
      .getClassificationByLevel(LEVEL_TWO)
      .then(({ value: { data } }) => {
        this.setState({ classificationByLevelTwo: data });
      });

    this.props
      .getClassificationByLevel(LEVEL_THREE)
      .then(({ value: { data } }) => {
        this.setState({ classificationByLevelThree: data });
      });

    this.props
      .getClassificationByLevel(LEVEL_FOUR)
      .then(({ value: { data } }) => {
        this.setState({ classificationByLevelFour: data });
      });
  }

  getClubsMigradoActivo = () => {
    getClubsRequest()
      .then(({ data }) => {
        this.setState({ clubs: (data || []).filter(x => !!x.migradoCrm2) });
      })
      .catch(handleRequestError);
  };

  render() {
    return (
      <main>
        <Layout>
          <Helmet title="Peticiones y Quejas" />
          <DemandList
            demands={this.props.demands}
            getDemands={this.props.getDemands}
            clubs={this.state.clubs || []}
            getClassificationByLevel={this.props.getClassificationByLevel}
            classificationByLevelOne={this.state.classificationByLevelOne}
            classificationByLevelTwo={this.state.classificationByLevelTwo}
            classificationByLevelThree={this.state.classificationByLevelThree}
            classificationByLevelFour={this.state.classificationByLevelFour}
            loadingDemands={this.props.loadingDemands}
            saveObservation={this.props.saveObservation}
            users={this.props.users}
            loadingUsers={this.props.loadingUsers}
            saveDetails={this.props.saveDetails}
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = state => {
  const {
    catalogs: { users, loadingUsers },
    demands: { demands, loadingDemands }
  } = state;
  return {
    demands,
    loadingDemands,
    users,
    loadingUsers
  };
};

const mapDispatchToProps = {
  getClassificationByLevel,
  getDemands,
  saveObservation,
  saveDetails,
  getUsers
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DemandsListContainer);
