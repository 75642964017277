import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import {
  Container,
  Input,
  Button,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SelectClubsModal from "../../../Home/components/addGroupsToSportsProducts/selectClubsModal";
import { isNumber } from "util";
import concat from "lodash.concat";
import { setChildStep } from "../../../Home/helpers/stepper-state-comparator";
import { MEMBER_TYPE_LIST, MEMBER_TYPES } from "../../../utils/constants";
import { Schedule } from "../SchedulePatterns";
import find from "lodash.find";

interface Response {
  id?: number;
  nombre: string;
  tipoIntegrante: MEMBER_TYPES;
  activo: boolean;
  patronHorarioid: number;
  clubes: Array<number>;
}

interface PatternGroupsMaintenance {
  id: number;
  nombre: string;
  tipoIntegrante: MEMBER_TYPES;
  activo: boolean;
  patronHorarioid: number;
  clubes: Array<{ clubId: number; nombre: string }>;
}

interface Props {
  match: any;
  history: any;
  patterns: Array<Schedule>;
  onClickSave: (e: object) => any;
  updateGroupsSchedulePatterns: (id: string, groups: Response[]) => any;
  patternGroupsMaintenance: Array<PatternGroupsMaintenance>;
}

interface State {
  groups: Array<{
    id: number;
    nombre: string;
    tipoIntegrante: MEMBER_TYPES;
    patronHorarioid: number;
    clubes: Array<{ clubId: number; nombre: string }>;
  }>;
  isOpenModal: boolean;
  index: number;
}

class ClubGroupsPatterns extends React.Component<Props, State> {
  state = {
    groups: [],
    isOpenModal: false,
    index: -1
  };

  componentDidMount() {
    this.addEmptyGroup();
    const { productId } = this.props.match.params;
    this.props.onClickSave(e => {
      e.preventDefault();
      this.props.updateGroupsSchedulePatterns(
        productId,
        this.state.groups.map(group => ({
          ...group,
          activo: true,
          clubes: group.clubes.map(({ clubId }) => clubId)
        }))
      );
    });
  }

  componentDidUpdate(prevProps) {
    const { patternGroupsMaintenance: groups } = this.props;
    const { patternGroupsMaintenance: prevProductGroups } = prevProps;
    if (prevProductGroups != groups && groups && groups.length) {
      setChildStep(groups);
      this.setState({ groups: groups.map(group => ({ ...group })) });
    }
  }

  setValue = (index, id, value) => {
    const groups = [...this.state.groups];
    groups[index][id] = value;
    setChildStep(groups);
    this.setState({ ...this.state, groups });
  };

  renderInputText = ({ index, column: { id } }) => {
    return (
      <Container className="my-1">
        <Row className="mt-1">
          <Col className="px-1">
            <Input
              type="text"
              className={`${s.renderEditableInput}`}
              value={this.state.groups[index][id]}
              onChange={e => {
                const value = e.target.value;
                this.setValue(index, id, value);
              }}
            />
          </Col>
        </Row>
      </Container>
    );
  };

  renderInputSelect = (options, nameKey = null, idKey = null) => ({
    index,
    column: { id }
  }) => {
    return (
      <Container className="my-1">
        <UncontrolledTooltip placement="left" target={`selector${index}${id}`}>
          {nameKey
            ? find(
                options,
                option => this.state.groups[index][id] == option[idKey]
              )[nameKey]
            : this.state.groups[index][id]}
        </UncontrolledTooltip>
        <Row className="mt-1">
          <Col className="px-1">
            <Input
              name="memberTypeSelector"
              type="select"
              id={`selector${index}${id}`}
              className={`${s.renderEditableInput}`}
              value={this.state.groups[index][id]}
              onChange={e => {
                const value = e.target.value;
                this.setValue(index, id, value);
              }}
              style={{ height: "2rem" }}
            >
              {nameKey
                ? options.map(opt => (
                    <option key={opt[idKey]} value={opt[idKey]}>
                      {opt[nameKey]}
                    </option>
                  ))
                : options.map(opt => (
                    <option key={opt} value={opt}>
                      {opt}
                    </option>
                  ))}
            </Input>
          </Col>
        </Row>
      </Container>
    );
  };

  renderClubes = ({ index, column: { id } }) => {
    return (
      <p className="text-justify m-2">
        {this.state.groups[index][id].map(c => c.nombre).join(", ")}
      </p>
    );
  };

  addEmptyGroup = () => {
    const groups = [...this.state.groups];
    groups.push({
      nombre: `GRUPO ${String.fromCharCode(65 + groups.length)}`,
      patronHorarioid: this.props.patterns[0]
        ? this.props.patterns[0].patronId
        : null,
      tipoIntegrante: MEMBER_TYPES.INTEGRANTE,
      clubes: []
    });

    setChildStep(groups);
    this.setState({ groups });
  };

  toggleModal = index => {
    this.setState({
      isOpenModal: !this.state.isOpenModal,
      index: isNumber(index) ? index : this.state.index
    });
  };

  addClubsToSportsClub = clubes => {
    const groups = [...this.state.groups];
    groups[this.state.index].clubes = [...clubes];
    setChildStep(groups);
    this.setState({ groups });
    this.toggleModal(this.state.index);
  };

  render() {
    return (
      <Container className="p-0 mt-2">
        {this.state.index >= 0 && (
          <SelectClubsModal
            clubsInUse={[]}
            isOpen={this.state.isOpenModal}
            toggle={this.toggleModal}
            addClubsToSportsClub={this.addClubsToSportsClub}
            validClubs={this.state.groups[this.state.index].clubes}
          />
        )}
        <Row className="p-0 m-0 mb-3">
          <ReactTable
            data={this.state.groups}
            columns={[
              {
                Header: "NOMBRE",
                accessor: "nombre",
                Cell: this.renderInputText,
                className: "p-0",
                width: 150
              },
              {
                Header: "TIPO INTEGRANTE",
                accessor: "tipoIntegrante",
                Cell: this.renderInputSelect(MEMBER_TYPE_LIST),
                className: "p-0",
                width: 150
              },
              {
                Header: "PATRON",
                accessor: "patronHorarioid",
                Cell: this.renderInputSelect(
                  this.props.patterns,
                  "nombre",
                  "patronId"
                ),
                className: "p-0",
                width: 200
              },
              {
                Header: "CLUBES",
                accessor: "clubes",
                Cell: this.renderClubes,
                className: "p-0",
                style: { whiteSpace: "normal" }
              },
              {
                Header: () => (
                  <div className="w-100 text-center">
                    <Button
                      color="link"
                      onClick={() => this.addEmptyGroup()}
                      className={`${s.innerButton} ${s.primaryLink}`}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </div>
                ),
                Cell: ({ index }) => (
                  <Button
                    className={`${s.primaryButton} btn-block`}
                    size="sm"
                    style={{ lineHeight: "1.25" }}
                    onClick={() => this.toggleModal(index)}
                  >
                    CLUBES
                  </Button>
                ),
                width: 80
              }
            ]}
            pageSize={this.state.groups.length}
            sortable={false}
            showPagination={false}
            className="-highlight -striped w-100"
            getTbodyProps={() => ({ style: { backgroundColor: "#EAECEE" } })}
          />
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ClubGroupsPatterns));
