import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import {
  infoNotification,
  errorNotification,
  successNotification,
  SAVE_GOALS_PENDING,
  SAVE_GOALS_ERROR,
  SAVE_GOALS
} from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";
interface State {}
const initialState: State = {};

export default function goalsReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.SAVE_GOALS}_PENDING`: {
      infoNotification(SAVE_GOALS_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.SAVE_GOALS}_REJECTED`: {
      errorNotification(readMessageBack(action, SAVE_GOALS_ERROR));
      return {
        ...state
      };
    }
    case `${actionTypes.SAVE_GOALS}_FULFILLED`: {
      successNotification(SAVE_GOALS);
      return {
        ...state
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
