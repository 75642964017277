import React from "react";
import { withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import toNumber from "lodash.tonumber";
import moment from "moment";
import { Container, Row, Col, Button } from "reactstrap";

import BaseInput from "../../../toolkit/baseInput";
import PersonSelector from "../../../People/containers/PersonSelector";
import { LockerDto } from "../../../types/Lockers";
import SelectFilter from "../common/SelectFilter";
import {
  ID_MEMBRESIA_SOCIO_INVITED,
  TIPO_CODIGO_LOCKER
} from "../../../utils/constants";
import CouponValidator, { CODE_VALIDITY } from "../couponValidator";
import { getPartnerMembershipByPartnerId } from "../../actions/partners";

import s from "./styles.scss";
import { Archivo, RequestArchivo } from "../expedientsV2controls/types";
import ArchivoControl from "../expedientsV2controls/archivo/archivo";
import { errorNotification } from "../../../utils/notifications";

import * as actions from "../../actions/expedientes";
import { Preview } from "../../../types/IAction";
import { ReduxConnect, Styled } from "../../../toolkit/decorators/Artifact";

const mapDispatchToProps = dispatch => ({
  getArchivoVigente: (idExpediente, idDocumento): Promise<Preview<any>> => {
    return dispatch(actions.getArchivoVigente(idExpediente, idDocumento));
  },
  postRefresh: (requestArchivo: RequestArchivo): Promise<Preview<any>> => {
    return dispatch(actions.postRefresh(requestArchivo));
  }
});

const mapStateToProps = state => {
  const { expedientes } = state;

  return {
    archivoRefreshLoading: expedientes.archivoRefreshLoading,
    archivoVigenteLoading: expedientes.archivoVigenteLoading
  };
};

interface Person {
  personaId: number;
  nombre: string;
  primerApellido: string;
  tipo: string;
}

interface Props {
  selectedLocker: LockerDto;
  onAssignProduct: (
    lockerId: number,
    personId: number,
    productId: number,
    responsibleId: number,
    msi: number,
    codigo: string,
    storage: string
  ) => any;
  productsFilteredBySize: { id: number; nombre: string }[];
  clear: any;
  sellers: object[];
  lockerDocuments: any[];
  fileScanned: boolean;

  // Documentacion V2, actualizar container a PreviewConnect
  archivoRefreshLoading?: boolean;
  getArchivoVigente?: (idExpediente, idDocumento) => Promise<Preview<any>>;
  postRefresh?: (requestArchivo: RequestArchivo) => Promise<Preview<any>>;
}

interface State {
  selectedPersonId: number;
  selectedLockerSize: string;
  selectedProductId: number;
  selectedResponsible: object;
  selectedMsi: number;
  person: object;
  objetoDescuento: CODE_VALIDITY;
  resetCodeValidator: boolean;
  selectedPersonMembershipPartnetId: string;
  selectedPersonPublicId: string;
  archivo: Archivo;
}

const userToOption = ({ userId, name, firstSurname, secondSurname }) => ({
  label: `${name} ${firstSurname} ${secondSurname}`,
  value: userId
});

@ReduxConnect(mapStateToProps, mapDispatchToProps)
@Styled(s)
export default class LockerAssignmentForm extends React.Component<
  Props,
  State
> {
  state = {
    selectedPersonId: 1,
    selectedLockerSize: "",
    selectedProductId: -1,
    selectedResponsible: null,
    selectedMsi: -1,
    person: null,
    objetoDescuento: null,
    resetCodeValidator: false,
    selectedPersonMembershipPartnetId: ID_MEMBRESIA_SOCIO_INVITED,
    selectedPersonPublicId: null,
    archivo: {} as Archivo
  };

  handleProductChanged = e => {
    const productId = e.target.value;
    this.setState(state => ({
      ...state,
      selectedProductId: productId
    }));
  };

  handleResponsibleChanged = responsible => {
    this.setState(state => ({
      ...state,
      selectedResponsible: responsible
    }));
  };
  handleAssignButtonClicked = (e: React.FormEvent<HTMLInputElement>): void => {
    e.preventDefault();
    const {
      selectedPersonId,
      selectedProductId,
      selectedResponsible,
      selectedMsi,
      objetoDescuento
    } = this.state;
    if (
      !!this.props.onAssignProduct &&
      !!this.props.selectedLocker &&
      (!!selectedPersonId && selectedPersonId > 0) &&
      (!!selectedProductId && selectedProductId > 0) &&
      (!!selectedResponsible &&
        !!selectedResponsible.value &&
        selectedResponsible.value > 0)
    ) {
      this.props.onAssignProduct(
        this.props.selectedLocker.id,
        selectedPersonId,
        selectedProductId,
        selectedResponsible.value,
        selectedMsi,
        !!objetoDescuento ? objetoDescuento.codigoDescuento : "",
        this.state.archivo.storage
      );
    }
  };

  setPerson = async (selectedPerson: Person) => {
    if (!!selectedPerson) {
      this.setState({
        selectedPersonId: selectedPerson.personaId,
        person: selectedPerson
      });
      this.getSelectedPersonpartnerMembreshipData(selectedPerson.personaId);
    } else {
      this.setState({
        selectedPersonId: -1,
        person: null,
        selectedPersonMembershipPartnetId: ID_MEMBRESIA_SOCIO_INVITED
      });
    }
  };

  getOptionsLockerSizes = () => {
    return [
      { value: "", label: "Seleccionar" },
      { value: "CHICO", label: "Chico" },
      { value: "MEDIANO", label: "Mediano" },
      { value: "GRANDE", label: "Grande" }
    ];
  };

  getOptionsProducts = () => {
    const { productsFilteredBySize } = this.props;
    return [{ value: -1, label: "Seleccionar" }].concat(
      (productsFilteredBySize || [])
        .filter(pfbz => !!pfbz.nombre)
        .map(pfbz => {
          return { label: pfbz.nombre, value: pfbz.id };
        })
    );
  };

  componentDidUpdate(prevProps) {
    if (this.props.clear != prevProps.clear) {
      this.setState(state => ({
        ...state,
        selectedResponsible: null,
        selectedPersonId: 1,
        selectedPersonMembershipPartnetId: ID_MEMBRESIA_SOCIO_INVITED,
        resetCodeValidator: !this.state.resetCodeValidator,
        selectedLockerSize: "",
        selectedProductId: -1,
        selectedMsi: -1,
        person: null,
        objetoDescuento: null,
        archivo: {} as Archivo
      }));
    }
  }

  getPrintData = () => {
    const fechaInicioMoment = moment();
    const { nombre: nombreProducto } = this.props.productsFilteredBySize.filter(
      ({ id }) => id == this.state.selectedProductId
    )[0];
    const mesesArriendo = toNumber(nombreProducto.match(/(\d+)/)[0]);
    const { nombre, primerApellido, segundoApellido } = this.state.person;

    // str.match(/(\d+)/)
    return {
      numeroMembresia: "",
      nombreClub: this.props.selectedLocker.club,
      numeroCasillero: this.props.selectedLocker.numero,
      tamanoLocker: this.props.selectedLocker.size,
      tipoVestidor: this.props.selectedLocker.vestidor,
      ubicacionClub: this.props.selectedLocker.club,
      fechaFirmaDocumento: fechaInicioMoment.format("DD-MM-YYYY"),
      horaFirmaDocumento: fechaInicioMoment.format("HH-mm"),
      fechaInicio: fechaInicioMoment.format("DD-MM-YYYY"),
      fechaFin: fechaInicioMoment
        .add(mesesArriendo, "months")
        .format("DD-MM-YYYY"),
      nombreCompletoPersona: `${nombre} ${primerApellido} ${segundoApellido}`,
      nombreEmpleado: this.state.selectedResponsible.label
    };
  };

  onValidateDiscountCode = (objetoDescuento: CODE_VALIDITY) => {
    this.setState({ objetoDescuento });
  };

  getSelectedPersonpartnerMembreshipData = (partnerId: number) => {
    getPartnerMembershipByPartnerId(partnerId)
      .then(({ data }) => {
        this.setState({
          selectedPersonMembershipPartnetId: !!data
            ? data.idMembresiaSocio
            : ID_MEMBRESIA_SOCIO_INVITED,
          selectedPersonPublicId: !!data ? data.idPublico : "-"
        });
      })
      .catch(err => {
        this.setState({
          selectedPersonMembershipPartnetId: ID_MEMBRESIA_SOCIO_INVITED
        });
      });
  };

  render() {
    return (
      <Container className="p-0 mt-3">
        <p className={`mt-2 ${s.primaryTitle}`}>
          Información básica del locker:
        </p>
        <Row>
          <Col style={{ paddingBottom: "5px" }} xs={12} md={6} lg={4}>
            <BaseInput
              type="text"
              name="lockerInfoClub"
              id="lockerInfoClub"
              label="Club"
              placeholder="Club"
              value={
                this.props.selectedLocker && this.props.selectedLocker.club
              }
              disabled
            />
          </Col>
          <Col style={{ paddingBottom: "5px" }} xs={12} md={6} lg={4}>
            <BaseInput
              type="text"
              name="lockerInfoLockerRoom"
              id="lockerInfoLockerRoom"
              label="Vestidor"
              placeholder="Vestidor"
              value={
                this.props.selectedLocker && this.props.selectedLocker.vestidor
              }
              disabled
            />
          </Col>
          <Col style={{ paddingBottom: "5px" }} xs={12} md={6} lg={4}>
            <BaseInput
              type="text"
              name="lockerInfoWall"
              id="lockerInfoWall"
              label="Pared"
              placeholder="Pared"
              value={
                this.props.selectedLocker && this.props.selectedLocker.pared
              }
              disabled
            />
          </Col>
          <Col style={{ paddingBottom: "5px" }} xs={12} md={6} lg={4}>
            <BaseInput
              type="text"
              name="lockerInfoSize"
              id="lockerInfoSize"
              label="Tamaño"
              placeholder="Tamaño"
              value={
                this.props.selectedLocker && this.props.selectedLocker.size
              }
              disabled
            />
          </Col>
          <Col style={{ paddingBottom: "5px" }} xs={12} md={6} lg={4}>
            <BaseInput
              type="text"
              name="lockerInfoPosition"
              id="lockerInfoPosition"
              label="Posición"
              placeholder="Posición"
              value={
                this.props.selectedLocker && this.props.selectedLocker.posicion
              }
              disabled
            />
          </Col>
          <Col style={{ paddingBottom: "5px" }} xs={12} md={6} lg={4}>
            <BaseInput
              type="text"
              name="lockerInfoNumber"
              id="lockerInfoNumber"
              label="Número"
              placeholder="Número"
              value={
                this.props.selectedLocker && this.props.selectedLocker.numero
              }
              disabled
            />
          </Col>
        </Row>
        <p className={`mt-2 ${s.primaryTitle}`}>Selección del producto:</p>
        <Row>
          <Col>
            <BaseInput
              type="select"
              name="lockerAssignmentLockerProduct"
              id="lockerAssignmentLockerProduct"
              label="Producto"
              placeholder="Producto"
              value={this.state.selectedProductId}
              options={this.getOptionsProducts()}
              onChange={this.handleProductChanged}
              disabled={
                !this.props.selectedLocker || this.props.selectedLocker.id <= 0
              }
            />
          </Col>
        </Row>
        <p className={`mt-2 ${s.primaryTitle}`}>
          Selección del cliente y responsable:
        </p>
        <Row>
          <Col>
            <PersonSelector
              selectedPerson={this.state.person}
              setPerson={this.setPerson}
              cKey={"8QATIT5N86"}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <SelectFilter
              label="Responsable de la asignación"
              name="lockerAssignmentResponsible"
              id="lockerAssignmentResponsible"
              type="select"
              placeholder="Responsable de la asignación"
              value={this.state.selectedResponsible}
              isMulti={false}
              options={
                this.props.sellers && userToOption
                  ? this.props.sellers.map(userToOption)
                  : []
              }
              onChange={this.handleResponsibleChanged}
              loading={false}
              isAsync={false}
              disabled={
                !this.props.selectedLocker || this.props.selectedLocker.id <= 0
              }
            />
          </Col>
        </Row>

        <p className={`mt-2 ${s.primaryTitle}`}>Cupón:</p>
        <Row>
          <Col>
            <CouponValidator
              onValidateDiscountCode={this.onValidateDiscountCode}
              tipoCodigo={TIPO_CODIGO_LOCKER}
              productoId={this.state.selectedProductId}
              reset={this.state.resetCodeValidator}
            />
          </Col>
        </Row>

        <p className={`mt-2 ${s.primaryTitle}`}>Documentación:</p>
        <div className="row">
          <Col xs={12} className="mb-3">
            <Button
              size="sm"
              className={`${s.searchButton} ${s.primaryButton} float-right btn-block`}
              onClick={async () => {
                const { productsFilteredBySize } = this.props;
                const productosFiltered = productsFilteredBySize.filter(
                  p => this.state.selectedProductId == p.id
                );

                const producto =
                  productosFiltered.length > 0
                    ? (productosFiltered[0].nombre || "").replace(
                        /(^.+\D)(\d+)(\D.+$)/i,
                        "$2"
                      )
                    : "0";

                const preview = await this.props.postRefresh({
                  codigo: "LOCKER",
                  idMembresiaSocio: ((this.state
                    .selectedPersonMembershipPartnetId != "-1"
                    ? this.state.selectedPersonMembershipPartnetId
                    : null) as unknown) as number,
                  idPersonaExpediente: this.state.person.personaId,
                  idPersonaDocumento: this.state.person.personaId,
                  values: {
                    casillero: this.props.selectedLocker.numero,
                    club: this.props.selectedLocker.club,
                    clubMembresia:
                      this.state.selectedPersonMembershipPartnetId != "-1"
                        ? this.props.selectedLocker.club
                        : "-",
                    idPublico: this.state.selectedPersonPublicId,
                    nombreCompleto: (
                      (this.state.person.nombre || "") +
                      " " +
                      (this.state.person.primerApellido || "") +
                      " " +
                      (this.state.person.segundoApellido || "")
                    ).trim(),
                    tamanio: this.props.selectedLocker.size,
                    ubicacion: this.props.selectedLocker.posicion,
                    meses: producto
                  }
                });

                if (preview.fulfilled) {
                  this.setState({
                    archivo: preview.archivoRefresh
                  });
                }
              }}
              disabled={
                !(
                  !!this.props.selectedLocker &&
                  this.props.selectedLocker.id > 0 &&
                  !!this.state.selectedPersonId &&
                  this.state.selectedPersonId > 0 &&
                  !!this.state.selectedProductId &&
                  this.state.selectedProductId > 0 &&
                  !!this.state.selectedResponsible &&
                  !!this.state.selectedResponsible.value &&
                  this.state.selectedResponsible.value > 0
                )
              }
            >
              {this.state.archivo.idItem
                ? "Regenerar contrato"
                : "Generar contrato"}
            </Button>
          </Col>
        </div>
        <div hidden={this.state.archivo.idItem == null} className="row">
          <Col>
            {this.state.archivo.idItem && (
              <ArchivoControl
                archivo={this.state.archivo}
                botonCorreo={false}
                botonRegenrar={false}
                userId={0}
                update={async () => {
                  const preview = await this.props.getArchivoVigente(
                    this.state.archivo.idExpediente,
                    this.state.archivo.idDocumento
                  );

                  if (preview.fulfilled) {
                    this.setState({
                      archivo: preview.archivoVigente
                    });
                  } else {
                    errorNotification("No se encontró el contrato");
                  }

                  return preview;
                }}
              />
            )}
          </Col>
        </div>
        <div hidden={this.state.archivo.idItem == null} className="row">
          <Col xs={12} md={6} lg={4} className="mt-4">
            <Button
              size="sm"
              className={`${s.searchButton} ${s.primaryButton} float-right btn-block`}
              onClick={this.handleAssignButtonClicked}
              disabled={
                !(
                  !!this.props.selectedLocker &&
                  this.props.selectedLocker.id > 0 &&
                  !!this.state.selectedPersonId &&
                  this.state.selectedPersonId > 0 &&
                  !!this.state.selectedProductId &&
                  this.state.selectedProductId > 0 &&
                  !!this.state.selectedResponsible &&
                  !!this.state.selectedResponsible.value &&
                  this.state.selectedResponsible.value > 0 &&
                  this.state.archivo.completo
                )
              }
            >
              Asignar
            </Button>
          </Col>
        </div>
      </Container>
    );
  }
}
