import FormBuilderConfig from "../../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import ITextBuilder from "../../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";
import ISelectBuilder from "../../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";
import IButtonBuilder from "../../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";

type Inputs = "nombre" | "responsable" | "estatus";
type Buttons = "guardar" | "regresar";
export const formConfig: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "nombre",
      label: "Empresa",
      component: ITextBuilder,
      bts: {
        sCol: 6
      }
    },
    {
      name: "responsable",
      label: "Responsable",
      bts: {
        sCol: 3
      }
    },
    {
      name: "estatus",
      label: "Estatus",
      component: ISelectBuilder,
      bts: {
        sCol: 3
      },
      props: {
        data: ["ACTIVO", "INACTIVO"],
        mapOption: item => ({
          value: item,
          label: item.charAt(0).toUpperCase() + item.slice(1)
        })
      }
    }
  ],
  buttons: [
    {
      name: "guardar",
      label: "Guardar",
      isSubmit: true,
      component: IButtonBuilder,
      bts: {
        sOff: 6,
        sCol: 3
      }
    },
    {
      name: "regresar",
      label: "Regresar",
      isSubmit: false,
      bts: {
        sCol: 3
      }
    }
  ],
  validations: {
    nombre: {
      required: true
    },
    responsable: {
      required: true
    },
    estatus: {
      required: true
    }
  },
  rows: [["nombre", "responsable", "estatus", "guardar", "regresar"]]
};
