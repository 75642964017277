import React from "react";
import { TabPane, Container, Row, Col, Button } from "reactstrap";
import NumericInput from "../../../toolkit/baseNumericInput";
import ModalFrame from "../../../toolkit/modalFrame";
import { Quotation } from "../../../Quotations/components/Quotation";
import { getEmptyFilters } from "../../../Quotations/utils";
import { Filters, QuotationInterface } from "../../../Quotations/types";
import NotificationsMessages from "./notificationsMessages";
import ProcedureChargesMessages from "./procedureChargesMessages";
import {
  FIRST_MONTH,
  LAST_MONTH,
  TODAY_NUMBER_MONTH
} from "../../../Quotations/components/QuotationTable";
import {
  reactivateMembershipRequest,
  reactivateMembershipValidationRequest
} from "../../actions/partners";
import { errorNotification } from "../../../utils/notifications";
import { Partner } from "./interfaces";

enum REFERRAL_CODE_STATE {
  VALID,
  INVALID,
  DEFAULT
}

interface Props {
  partner: Partner;
  quotations: QuotationInterface;
  history: any;
  getQuotations: (personaId, args, integrantes) => void;
  activeTab: string;
}

interface State {
  isOpen: boolean;
  data: object;
  montoReactivacion: number;
  filters: Filters;

  membershipReactivateValidationMode: boolean;
  membershipReactivateMessages: any[];
  membershipReactivateCharges: any[];
  membershipReactivateConditions: object;
  membershipReactivateUserAmount: number;
  selectedMonths: any[];
  stateReferral: REFERRAL_CODE_STATE;
  codigoReferido: string;
}

const search = (f, id, filters, integrantes) => {
  f(
    id,
    {
      ...filters,
      mesInicio: FIRST_MONTH,
      mesFin: LAST_MONTH,
      diaInicio: TODAY_NUMBER_MONTH
    },
    integrantes
  );
};

let filters = getEmptyFilters();

//-------------COMPONENTE INTERNO PARA COTIZACION---------------------------
const Quotations = props => {
  const handleSwitch = () => {
    props.executeConditionsValidation({
      clubId: filters.club,
      clubAlternoId: filters.clubAlterno,
      mantenimientoId: filters.mantenimiento,
      membresiaId: filters.membresia,
      idPromocion: filters.promocion || null,
      idConvenio: filters.convenio || null,
      idEmpresa: filters.empresa || null,
      formaPagoParaDescuento: filters.descuento || "EFECTIVO"
    });
  };

  const onChangeFilters = newFilters => {
    filters = newFilters;
    filters.clubAlternoId = newFilters.clubAlterno;

    if (newFilters.membresia && newFilters.club) {
      search(
        props.getQuotations,
        props.partner.idPersona,
        { tipoPrecio: 4, clubAlternoId: newFilters.clubAlterno, ...newFilters },
        []
      );
    }

    props.setFilters(filters);
  };

  return (
    <Container className="w-100 p-0 my-3">
      <Row>
        <Col lg={12} md={12} xs={12} className="w-100 p-0 m-0 test-class">
          <Quotation
            enableMemberEdition
            onChangeFilters={onChangeFilters}
            quotation={props.quotations}
            quotationUse={props.quotationUse}
            checkMode={props.checkMode}
            updateSelectedMonths={props.updateSelectedMonths}
            partner={props.partner}
            disableReferredCode={false}
            setStateReferral={props.setStateReferral}
            setCodigoReferido={props.setCodigoReferido}
          />
        </Col>
        <hr />
        <Col className="pl-0">
          <Button
            onClick={handleSwitch}
            disabled={props.stateReferral == REFERRAL_CODE_STATE.INVALID}
          >
            Aplicar reactivación
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
//----------------------------------------------------------------------------

class TabReactivation extends React.Component<Props, State> {
  state = {
    isOpen: false,
    data: null,
    montoReactivacion: null,
    filters: null,
    membershipReactivateValidationMode: false,
    membershipReactivateMessages: [],
    membershipReactivateCharges: [],
    membershipReactivateConditions: null,
    membershipReactivateUserAmount: null,
    selectedMonths: [],
    stateReferral: REFERRAL_CODE_STATE.DEFAULT,
    codigoReferido: null
  };

  toggleModal = () => {
    this.closeAndClearMembershipReactivationData();
  };

  handleInputChange = e =>
    this.setState({
      montoReactivacion: e.target.value,
      membershipReactivateUserAmount: e.target.value
    });

  validateMembershipReactivation = (data?: object) => {
    const { filters } = this.state;
    if (!!filters.biclub && !data.clubAlternoId) {
      errorNotification("Debe seleccionar un club alterno");
      return;
    }

    const fullData = {
      ...data,
      mesesCotizacion: [
        ...this.state.selectedMonths.map(x => {
          x.integrantes.bebes = null;
          x.integrantes.extras = null;
          return x;
        })
      ],
      codigoReferidoValidacion: this.state.codigoReferido
    };

    this.setState({
      data: { ...fullData },
      membershipReactivateConditions: { ...fullData }
    });

    const { partner } = this.props;
    reactivateMembershipValidationRequest(fullData, partner.idMembresiaSocio)
      .then(({ data: validationResult }) => {
        const { resultado, mensajes, cargosAGenerar } = validationResult;
        this.setState({
          isOpen: !this.state.isOpen,
          membershipReactivateMessages: mensajes,
          membershipReactivateCharges: cargosAGenerar
        });
      })
      .catch(err => {
        errorNotification("Ocurrió un error al ejecutar la petición");
      });
  };

  executeMembershipReactivation = () => {
    const { partner } = this.props;
    const {
      membershipReactivateConditions,
      membershipReactivateUserAmount
    } = this.state;

    reactivateMembershipRequest(
      {
        ...membershipReactivateConditions,
        precioVentaReactivacion: membershipReactivateUserAmount,
        codigoReferidoValidacion: this.state.codigoReferido
      },
      partner.idMembresiaSocio,
      "1",
      "false"
    )
      .then(({ data }) => {
        const { resultado } = data;
        const membresiaSocio = data.data || {};

        if (!!resultado) {
          this.closeAndClearMembershipReactivationData();

          this.setState({ codigoReferido: null });

          const { idMembresiaSocio, idPublico, idClubBase } = membresiaSocio;

          //Si hubo un cambio de club, se va a la pantalla de venta exitosa de membresía socio, si no, se va al carrito para el pago
          if (partner.idClubBase != idClubBase) {
            this.props.history.push(
              `/socio/venta-exitosa/${idMembresiaSocio}?idPublico=${idPublico}&idClub=${idClubBase}`
            );
          } else {
            this.props.history.push(
              `/productos/carrito/${this.props.partner.idPropietario}`
            );
          }
        } else {
          errorNotification(
            "Ocurrió un error al ejecutar la petición de reactivación de membresía"
          );
          this.closeAndClearMembershipReactivationData();
        }
      })
      .catch(err => {
        errorNotification(
          "Ocurrió un error al ejecutar la petición de reactivación de membresía"
        );
        this.closeAndClearMembershipReactivationData();
      });
  };

  closeAndClearMembershipReactivationData() {
    this.setState({
      isOpen: !this.state.isOpen,
      data: null,
      membershipReactivateMessages: [],
      membershipReactivateCharges: [],
      membershipReactivateConditions: null,
      membershipReactivateUserAmount: null,
      stateReferral: REFERRAL_CODE_STATE.DEFAULT
    });
  }

  cancelMembershipReactivation = () => {
    //Se cierra el modal y se limpian los datos
    this.closeAndClearMembershipReactivationData();
  };

  render() {
    if (this.props.activeTab != "10") {
      return <></>;
    }

    return (
      <TabPane className="my-2" tabId="10">
        <ModalFrame
          title={"Confirmar reactivación de membresía"}
          isOpen={this.state.isOpen}
          toggle={this.toggleModal}
          size="lg"
        >
          <Container>
            <p>Mensajes:</p>
            <br />
            <Row>
              <Col>
                <NotificationsMessages
                  messages={this.state.membershipReactivateMessages || []}
                />
              </Col>
            </Row>

            <br />
            <br />
            <p>Cargos a generar:</p>
            <br />
            <Row>
              <Col>
                <ProcedureChargesMessages
                  cargos={this.state.membershipReactivateCharges || []}
                />
              </Col>
            </Row>

            {!(this.state.membershipReactivateMessages || []).length && (
              <Row>
                <Col>
                  <p className={`w-100`}>
                    <span>
                      Ingrese el monto a pagar por reactivación, el cual debe
                      estar en el rango del cargo de reactivación mostrado. (Si
                      no se encuentra dentro del rango, se sobreescribirá con
                      los límites del cargo)
                    </span>
                    <NumericInput
                      label={"Monto de reactivación"}
                      name="montoReactivacion"
                      decimals={true}
                      id="montoReactivacion"
                      placeholder="Monto de reactivacion"
                      onChange={this.handleInputChange}
                    />
                  </p>
                </Col>
              </Row>
            )}
            <Row>
              <Col className="text-right">
                <Button
                  color="secondary"
                  onClick={this.cancelMembershipReactivation}
                  style={{ marginRight: "15px" }}
                >
                  Cancelar
                </Button>

                {!(this.state.membershipReactivateMessages || []).length &&
                  parseFloat(this.state.membershipReactivateUserAmount) > 0 && (
                    <Button
                      onClick={this.executeMembershipReactivation}
                      color="primary"
                    >
                      Reactivar
                    </Button>
                  )}
              </Col>
            </Row>
          </Container>
        </ModalFrame>
        <Container className="p-0 m-0">
          <Row className="p-0 m-0">
            <Quotations
              {...this.props}
              quotationUse="REACTIVACION"
              executeConditionsValidation={this.validateMembershipReactivation}
              setFilters={filters => {
                this.setState({ filters });
              }}
              checkMode={true}
              updateSelectedMonths={months => {
                this.setState({ selectedMonths: [...months] });
              }}
              setStateReferral={newStateReferral => {
                this.setState({ stateReferral: newStateReferral });
              }}
              setCodigoReferido={newCodigoReferido => {
                this.setState({ codigoReferido: newCodigoReferido });
              }}
              stateReferral={this.state.stateReferral}
            />
          </Row>
        </Container>
      </TabPane>
    );
  }
}
export default TabReactivation;
