import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Container, TabPane } from "reactstrap";
import s from "./tabStyles.scss";
import ReactTable from "react-table";

interface Props {
  listHistoryAutCharges: any[];
}

interface State {}

class TabHistoryAutCarge extends React.Component<Props, State> {
  state = {};

  columns = [
    {
      Header: "Club",
      accessor: "club"
    },
    {
      Header: "Fecha de envío",
      accessor: "fechaCobro"
    },
    {
      Header: "Tipo CA",
      accessor: "tipoCA"
    },
    {
      Header: "Numero de Tarjeta",
      accessor: "numeroTarjeta"
    },
    {
      Header: "Referencia",
      accessor: "referencia"
    },
    {
      Header: "Importe",
      accessor: "importe"
    },
    {
      Header: "Estatus",
      accessor: "estatus"
    },
    {
      Header: "Código rechazo/aprov",
      accessor: "codigo"
    },
    {
      Header: "Descripción rechazo/aprov",
      accessor: "descripcion"
    }
  ];

  render() {
    return (
      <TabPane className="my-2" tabId="12">
        <Container className="p-0 mt-3">
          <ReactTable
            manual
            data={this.props.listHistoryAutCharges || []}
            columns={this.columns}
            className="w-100 -striped -highlight"
            showPagination={false}
          />
        </Container>
      </TabPane>
    );
  }
}
export default withStyles(s)(TabHistoryAutCarge);
