import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

import {
  errorNotification,
  successNotification
} from "../../../utils/notifications";
import {
  getQuizzCategoriesRequest,
  createQuizzCategoryRequest,
  updateQuizzCategoryRequest
} from "../../actions/products";

import { handleRequestError } from "../../../utils/helpers";

interface QuizzCategory {
  id: number;
  nombre: string;
  activa: boolean;
}

const OP_MODES = {
  NONE: "NONE",
  CREATE: "CREATE",
  EDIT: "EDIT"
};

interface Props {}

interface State {
  filters: { nombre: string; activa: string };
  tableData: QuizzCategory[];
  searching: boolean;
  //modal
  showModal: boolean;
  selectedQuizzCategory: QuizzCategory;
  mode: string;
}

class AdminQuizzCategories extends React.Component<Props, State> {
  state = {
    filters: { nombre: "", activa: "TODOS" },
    tableData: [],
    searching: false,
    showModal: false,
    selectedQuizzCategory: {} as QuizzCategory,
    mode: OP_MODES.NONE
  };

  componentDidMount() {
    this.search();
  }

  search = () => {
    getQuizzCategoriesRequest()
      .then(({ data }) => {
        const { filters } = this.state;

        if (!!filters.nombre) {
          data = data.filter(x =>
            x.nombre.toLowerCase().includes(filters.nombre.toLowerCase())
          );
        }
        if (!!filters.activa && filters.activa != "TODOS") {
          if (filters.activa == "TRUE") {
            data = data.filter(x => !!x.activa);
          } else {
            data = data.filter(x => !x.activa);
          }
        }

        this.setState({ tableData: data });
      })
      .catch(handleRequestError);
  };

  onClickNewCategory = () => {
    const { selectedQuizzCategory } = this.state;
    selectedQuizzCategory.nombre = "";
    selectedQuizzCategory.activa = true;

    this.setState({
      mode: OP_MODES.CREATE,
      selectedQuizzCategory
    });
    this.toggleModal();
  };

  onClickEditCategory = (category: QuizzCategory) => {
    this.setState({
      selectedQuizzCategory: { ...category },
      mode: OP_MODES.EDIT
    });
    this.toggleModal();
  };

  //modal

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  clearEditObjectState = () => {
    this.setState({
      selectedQuizzCategory: {} as QuizzCategory,
      mode: OP_MODES.NONE
    });
  };

  handleSaveCategory = e => {
    e.preventDefault();

    if (
      !this.state.selectedQuizzCategory ||
      !this.state.selectedQuizzCategory.nombre
    ) {
      errorNotification("Debe llenar los campos obligatorios.");
      return;
    }

    switch (this.state.mode) {
      case OP_MODES.CREATE:
        createQuizzCategoryRequest({
          ...this.state.selectedQuizzCategory
        })
          .then(() => {
            successNotification("Categoría creada correctamente");
            this.handleCancelEditCategory();
            this.search();
          })
          .catch(handleRequestError);

        break;
      case OP_MODES.EDIT:
        updateQuizzCategoryRequest({
          ...this.state.selectedQuizzCategory
        })
          .then(() => {
            successNotification("Categoría actualizada correctamente");
            this.handleCancelEditCategory();
            this.search();
          })
          .catch(handleRequestError);

        break;
      default:
        this.handleCancelEditCategory();
        break;
    }
  };

  handleCancelEditCategory = () => {
    this.toggleModal();
    this.clearEditObjectState();
  };

  onChangeCheckedFactory = fieldName => {
    this.setState({
      ...this.state,
      selectedQuizzCategory: {
        ...this.state.selectedQuizzCategory,
        [fieldName]: !this.state.selectedQuizzCategory[fieldName]
      }
    });
  };

  render() {
    let columns = [
      {
        Header: () => <p className="font-weight-bold">Id</p>,
        id: 0,
        width: 80,
        accessor: "id",
        Cell: row => {
          return <div className="pl-2">{row.original.id}</div>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Nombre</p>,
        id: 1,
        accessor: "nombre",
        Cell: row => {
          return <div className="text-wrap">{row.original.nombre}</div>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Activa</p>,
        id: 1,
        width: 120,
        accessor: "activa",
        Cell: row => {
          return (
            <div className="text-center">
              {!!row.original.activa ? "SI" : "NO"}
            </div>
          );
        }
      },
      {
        Header: () => {
          return (
            <div className="text-center">
              <Button
                title="Crear"
                className={`${s.actionHeaderIcon}`}
                onClick={this.onClickNewCategory}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          );
        },
        id: -300,
        width: 80,
        accessor: "actions",
        Cell: row => {
          return (
            <div className="text-center">
              <Button
                title="Editar"
                className={`${s.actionHeaderIcon} mr-2`}
                onClick={() => {
                  this.onClickEditCategory(row.original);
                }}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              <Button
                title="Eliminar"
                className={`${s.actionHeaderIcon} mr-2`}
                onClick={() => {}}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          );
        }
      }
    ];

    return (
      <Container className="p-0 mt-3">
        <Form
          onSubmit={e => {
            e.preventDefault();
            this.search();
          }}
        >
          <Row>
            <Col xs={4}>
              <BaseInput
                type="text"
                label="Nombre"
                name={"nameFilter"}
                id={"nameFilter"}
                value={this.state.filters.nombre}
                onChange={e => {
                  const value = e.target.value;
                  const { filters } = this.state;
                  filters.nombre = value;
                  this.setState({ filters: { ...filters } });
                }}
              />
            </Col>
            <Col xs={2}>
              <BaseInput
                type="select"
                label="Activo"
                name={"activeFilter"}
                id={"activeFilter"}
                value={this.state.filters.activa}
                options={[
                  { value: "TODOS", label: "TODOS" },
                  { value: "TRUE", label: "ACTIVOS" },
                  { value: "FALSE", label: "INACTIVOS" }
                ]}
                onChange={e => {
                  const value = e.target.value;
                  const { filters } = this.state;
                  filters.activa = value;
                  this.setState({ filters: { ...filters } });
                }}
              />
            </Col>
            <Col xs={2}>
              <Button
                className={`${s.buttonMarginTop} ${s.primaryButton} mt-4`}
                type="submit"
                size="sm"
                block
              >
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
        <ReactTable
          className="mt-5 -striped -highlight"
          data={this.state.tableData || []}
          columns={columns}
          sortable={false}
          resizable={false}
          pageSize={(this.state.tableData || []).length + 2}
          showPagination={false}
          loading={this.state.searching}
        />

        <Modal
          isOpen={this.state.showModal}
          toggle={this.toggleModal}
          fade={true}
          backdrop="static"
          keyboard={false}
        >
          <ModalHeader toggle={this.toggleModal}>
            {this.state.mode === OP_MODES.CREATE && (
              <span className="font-weight-bold">Creación de categoría</span>
            )}
            {this.state.mode === OP_MODES.EDIT && (
              <span className="font-weight-bold">Edición de categoría</span>
            )}
          </ModalHeader>
          <ModalBody>
            <div className="mt-2">
              <Form>
                <Row>
                  <Col xs={12} md={10}>
                    <BaseInput
                      type="text"
                      label="Nombre *"
                      name="categoryName"
                      id="categoryName"
                      placeholder="Nombre"
                      value={this.state.selectedQuizzCategory.nombre || ""}
                      onChange={e => {
                        const value = e.target.value;
                        const { selectedQuizzCategory } = this.state;
                        selectedQuizzCategory.nombre = value;
                        this.setState({ selectedQuizzCategory });
                      }}
                    />
                  </Col>
                  <Col xs={12} sm={2}>
                    <br />
                    <FormGroup check className="mt-2">
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={this.state.selectedQuizzCategory.activa}
                          onChange={e => this.onChangeCheckedFactory("activa")}
                        />
                        Activo
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className={`${s.primaryButton} d-inline-block mr-2`}
              onClick={this.handleSaveCategory}
              disabled={
                !this.state.selectedQuizzCategory ||
                !this.state.selectedQuizzCategory.nombre
              }
            >
              GUARDAR
            </Button>
            <Button
              className={`${s.secondaryButton} d-inline-block mr-2`}
              onClick={this.handleCancelEditCategory}
            >
              CANCELAR
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(AdminQuizzCategories));
