import React from "react";
import { connect } from "react-redux";
import Layout from "../../components/layout";
import { Helmet } from "react-helmet";
import DiscountCodeListPage from "../../components/discountCodeListPage";
import { getClubs } from "../../actions/catalogs";
import {
  getCodesList,
  clearCodes,
  getDiscountCodesListFile,
  getCodesListLte
} from "../../actions/dicounts";
import { QueryArguments } from "../../../types-business/QueryArguments";

interface Props {
  clubs: object;
  getClubs: () => any;
  getCodesList: (lote: object, args?: QueryArguments) => any;
  getCodesListLte: (data: object) => any;
  clearCodes: () => any;
  getDiscountCodesListFile: (lote: string) => any;
  discounts: object;
}

interface State {
  tipoCodigo: string; //"PRODUCTO", // "PRODUCTO"|| "MEMBRESIA",
  idProducto: string; //"idProducto",
  tipoDescuento: string; // "MONTO", // "MONTO"|| "PORCENTAJE",
  inicioVigencia: string; // "xxxx",
  finVigencia: string; // "xxxx",
  clubes: [
    {
      idCLub: number; // 0,
      cantidad: number; //0
    }
  ];
}

class DiscountCodeList extends React.Component<Props, State> {
  state = {
    tipoCodigo: "",
    idProducto: "",
    tipoDescuento: "",
    inicioVigencia: "",
    finVigencia: "",
    clubes: null
  };
  componentDidMount() {
    this.props.getClubs();
  }

  render() {
    return (
      <main>
        <Helmet title="Creacion codigos de descuento para clubes" />
        <Layout>
          {this.props.clubs && (
            <DiscountCodeListPage
              getDiscountCodesListFile={this.props.getDiscountCodesListFile}
              discounts={this.props.discounts}
              getCodesList={this.props.getCodesList}
              getCodesListLte={this.props.getCodesListLte}
              clearCodes={this.props.clearCodes}
              clubs={this.props.clubs}
            />
          )}
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getCodesListLte: (data: any) => {
    dispatch(getCodesListLte(data));
  },
  getDiscountCodesListFile: (lote: string) => {
    dispatch(getDiscountCodesListFile(lote));
  },
  clearCodes: () => {
    return dispatch(clearCodes());
  },
  getCodesList: (lote: string, args?: QueryArguments) => {
    return dispatch(getCodesList(lote, args));
  },
  getClubs: () => {
    return dispatch(getClubs());
  }
});

const mapStateToProps = state => {
  const {
    catalogs: { clubs },
    discounts
  } = state;
  return {
    clubs,
    discounts
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DiscountCodeList);
