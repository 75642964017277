import { Preview } from "../../../types/IAction";
import * as actions from "../../actions/invitados";
import {
  ActivarInvitado,
  DesactivarInvitado,
  DeleteInvitado,
  GetInvitados,
  PostHuellaInvitado
} from "../../reducers/invitados";

export interface InvitadosListDispatcher {
  getInvitados: (filter: any) => Promise<Preview<GetInvitados>>;
  putInvitadoActivar: (idInvitado: any) => Promise<Preview<ActivarInvitado>>;
  putInvitadoDesactivar: (
    idInvitado: any
  ) => Promise<Preview<DesactivarInvitado>>;
  deleteInvitado: (idInvitado: any) => Promise<Preview<DeleteInvitado>>;
  postHuellaInvitado: (
    idInvitado: any,
    huella: any
  ) => Promise<Preview<PostHuellaInvitado>>;
}

export const invitadosListDispatcher: (
  dispatch: (...args) => Promise<Preview<any>>
) => InvitadosListDispatcher = dispatch => ({
  getInvitados: (filters: any) => {
    return dispatch(actions.getInvitados(filters));
  },
  putInvitadoActivar: (idInvitado: any) => {
    return dispatch(actions.putInvitadoActivar(idInvitado));
  },
  putInvitadoDesactivar: (idInvitado: any) => {
    return dispatch(actions.putInvitadoDesactivar(idInvitado));
  },
  deleteInvitado: (idInvitado: any) => {
    return dispatch(actions.deleteInvitado(idInvitado));
  },
  postHuellaInvitado: (idInvitado: any, huella: any) => {
    return dispatch(actions.postHuellaInvitado(idInvitado, huella));
  }
});
