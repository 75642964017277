import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Container, Col, Button, Row } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import DatePicker from "../common/DatePicker";
import ReactTable from "react-table";
import MexicanCurrency from "../common/currency";
import s from "./styles.scss";
import IAction from "../../../types/IAction";
import { OPTIONS_FORMA_PAGO_CUSTOM } from "../../../utils/constants";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  clubs: object[];
  getSantanderRefDosntApply: (
    clubId: string,
    formaDePago: string,
    fechaInicio: string,
    fechaFin: string,
    page: string,
    size: string
  ) => IAction;
  reportReferenceDA: any;
  getSantanderRefDosntApplyCsv: (body: object) => IAction;
}

interface State {
  filters: object;
}

const START_DATE_FIELDNAME = "fechaInicio";
const END_DATE_FIELDNAME = "fechaFin";

class ReferenceDosntApplyReport extends React.Component<Props, State> {
  state = {
    filters: {
      idClub: null,
      fechaInicio: null,
      fechaFin: null,
      formaDePago: null
    }
  };

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const filters = {
          ...state.filters,
          [fieldName]: date
        };
        return { ...state, error: {}, filters };
      });
    };
  };

  onFetchData = ({ pageSize: size, page }) => {
    const { idClub, formaDePago, fechaInicio, fechaFin } = this.state.filters;
    const clubId = idClub;
    this.props.getSantanderRefDosntApply(
      clubId,
      formaDePago,
      fechaInicio,
      fechaFin,
      page ? page : "0",
      size ? size : "20"
    );
  };

  onClickDownloadCsvFile = () => {
    const { idClub, formaDePago, fechaInicio, fechaFin } = this.state.filters;
    const clubId = idClub;
    this.props.getSantanderRefDosntApplyCsv({
      clubId,
      formaDePago,
      fechaInicio,
      fechaFin
    });
  };

  columns = [
    {
      Header: () => <p className="font-weight-bold">Id</p>,
      id: 0,
      accessor: "id",
      Cell: row => {
        return <p>{row.original.id}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Referencia</p>,
      id: 0,
      accessor: "referencia",
      Cell: row => {
        return <p>{row.original.referencia}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Estado</p>,
      id: 0,
      accessor: "estatus",
      Cell: row => {
        return <p>{row.original.estatus}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Club</p>,
      id: 0,
      accessor: "clubNombre",
      Cell: row => {
        return <p>{row.original.clubNombre}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Forma de pago</p>,
      id: 0,
      accessor: "formaDePago",
      Cell: row => {
        return <p>{row.original.formaDePago}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Importe</p>,
      id: 0,
      accessor: "monto",
      Cell: row => {
        return (
          <p>
            <MexicanCurrency quantity={row.original.monto} />
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Fecha</p>,
      id: 0,
      accessor: "fechaCreacion",
      Cell: row => {
        return <p>{row.original.fechaCreacion}</p>;
      }
    }
  ];

  render() {
    const { clubs, reportReferenceDA } = this.props;
    const { idClub } = this.state.filters;
    return (
      <Container className="p-0">
        <div className="row mt-3 mb-3">
          <div className="col">
            <p className={`${s.primaryTitle} w-100 m-0`}>
              Búsqueda de referencias no aplicadas
            </p>
          </div>
        </div>
        <Row>
          <Col xs={12} md={4} lg={4}>
            <BaseInput
              label="Club"
              name="club"
              id="club"
              type="select"
              placeholder="Seleccionar club"
              value={idClub}
              onChange={this.onChangeFilter("idClub")}
              size={"sm"}
              options={[{ value: "null", label: "Seleccionar club" }].concat(
                clubs
                  ? clubs
                      .filter(x => !!x.migradoCrm2)
                      .map(option => ({
                        value: option.clubId,
                        label: option.nombre
                      }))
                  : []
              )}
            />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <DatePicker
              label="Fecha inicio"
              name={START_DATE_FIELDNAME}
              id={START_DATE_FIELDNAME}
              selected={this.state.filters[START_DATE_FIELDNAME]}
              onChange={this.onChangeDate(START_DATE_FIELDNAME)}
            />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <DatePicker
              label="Fecha fin"
              name={END_DATE_FIELDNAME}
              id={END_DATE_FIELDNAME}
              selected={this.state.filters[END_DATE_FIELDNAME]}
              onChange={this.onChangeDate(END_DATE_FIELDNAME)}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4} lg={4}>
            <BaseInput
              label="Forma de Pago"
              name="formaDePagoPagoProductos"
              type="select"
              id="formaDePagoPagoProductos"
              placeholder="Forma de Pago"
              options={[
                { value: "null", label: "Seleccionar forma de pago" }
              ].concat(
                OPTIONS_FORMA_PAGO_CUSTOM.map(option => ({
                  value: option,
                  label: option
                }))
              )}
              value={this.state.filters.formaDePago}
              onChange={this.onChangeFilter("formaDePago")}
            />
          </Col>
          <Col xs={12} md={4} lg={4} className="mt-4">
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.onFetchData}
            >
              Buscar
            </Button>
          </Col>
        </Row>
        {reportReferenceDA &&
        reportReferenceDA.content &&
        reportReferenceDA.content.length > 0 ? (
          <Row className={s.customRowCSV}>
            <Col className={s.customTextoCSV}>Exportar archivo CSV</Col>
            <Col className={s.customLogoCSV}>
              <Button
                id="btnLockerSummaryExportCsv"
                color="link"
                onClick={this.onClickDownloadCsvFile}
              >
                <FontAwesomeIcon icon={faFileExport} color="black" />
              </Button>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <Row className={s.customRow}>
          <Col>
            <ReactTable
              manual
              data={reportReferenceDA ? reportReferenceDA.content : []}
              pages={reportReferenceDA ? reportReferenceDA.totalPages : 0}
              columns={this.columns}
              className="w-100"
              onFetchData={this.onFetchData}
              defaultPageSize={20}
              sortable={false}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ReferenceDosntApplyReport));
