import React from "react";
import s from "./styles.scss";
import { Styled } from "../../../toolkit/decorators/Artifact";
import { OpcionType } from ".";

interface Props {
  opcion: OpcionType;
  onChange: (opcion: OpcionType) => void;
}

interface State {}

/**
 * Botonera de opciones en el modal del módulo de expedientes
 */
@Styled(s)
export default class ExpedienteVisorOpciones extends React.Component<
  Props,
  State
> {
  render() {
    return (
      <div className={`${s.options}`}>
        <div className={`${s.title}`}>Documentos por validar</div>
        <div className={`${s.seleccionar}`}>
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            <label
              className={this.renderClassName("POR_VALIDAR")}
              onClick={() => this.props.onChange("POR_VALIDAR")}
            >
              <input type="radio" name="porValidar" defaultChecked={true} />
              Por validar
            </label>

            <label
              className={this.renderClassName("COMISION")}
              onClick={() => this.props.onChange("COMISION")}
            >
              <input type="radio" name="comision" />
              Comisión
            </label>

            <label
              className={this.renderClassName("TODOS")}
              onClick={() => this.props.onChange("TODOS")}
            >
              <input type="radio" name="todos" />
              Todos
            </label>
          </div>
        </div>
      </div>
    );
  }

  renderClassName(opcion: OpcionType) {
    const active = this.props.opcion == opcion ? " active" : "";

    return `${s.label} btn btn-sm btn-outline-primary${active}`;
  }
}
