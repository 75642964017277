import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import IAction from "../../../types/IAction";

import Layout from "../../components/layout";
import { getClubs, getSellers } from "../../actions/catalogs";
import { toggleEditSession } from "../../actions/modals";
import { getUsers } from "../../actions/users";
import { getProducts } from "../../actions/sportsProducts";
import {
  addAttendant,
  deleteAttendantOnEvent,
  deleteSession,
  getEvents,
  getDetails,
  newSessionProductId
} from "../../actions/events";
import { getSportsProductsTypes, getMemberships } from "../../actions/catalogs";
import SportSession from "../../components/sportSession";

import { QueryResponse } from "../../../types/QueryResponse";
import { QueryArguments } from "../../../types-business/QueryArguments";
import CONSTANTS from "../../../utils/constants";
import users from "../users";

interface Product {
  id: string;
  clasificacionId: string;
  nombre: string;
  estatus: string;
  publicarPrecio: boolean;
  fechaInicioVigencia: string;
  fechaFinVigencia: string;
  descripcion: string;
}

interface Props {
  clubs: object[];
  products: QueryResponse<Product>;
  getClubs: () => IAction;
  getUsers: () => IAction;
  getEvents: (clubId: number, args?: QueryArguments) => IAction;
  getMemberships: () => IAction;
  getProducts: (args?: QueryArguments) => IAction;
  getSportsProductsTypes: () => IAction;
  match: any;
  searching: boolean;
  addProduct: (product?) => IAction;
  history: any;
  sportsProductsTypes: object[];
  events: object[];
  eventDetails: object;
  removeSession: (product) => IAction;
  getDetails: (qs: object) => IAction;
  addAttendant: (productId: string, attendantId: string) => IAction;
  deleteAttendantOnEvent: (attendantId: string) => IAction;
  deleteSession: (eventClass?) => IAction;
  newAttendant: boolean;
  sellers: object[];
  getSellers: () => IAction;
  toggleEditSession: (edit?: boolean) => IAction;
  newSessionProductId: (id: number) => IAction;
}

interface State {
  products: object;
  events: object;
  filters: { clasificacionId: string };
}

class SportsSession extends React.Component<Props, State> {
  state = {
    products: {
      content: [],
      totalPages: 0
    },
    events: {
      content: [],
      totalPages: 0
    },
    filters: {
      clasificacionId: null,
      size: CONSTANTS.DEFAULT_PAGE_SIZE,
      page: 0
    }
  };

  onChangeFilter = (value, name) => {
    const filters = {
      ...this.state.filters,
      [`${name}`]: value ? value : null
    };
    this.setState(state => ({
      ...state,
      filters
    }));
  };

  onFetchData = ({ pageSize: size, page }) => {
    const filters = {
      ...this.state.filters,
      size,
      page
    };
    this.props.getProducts(filters);
    this.setState(state => ({ ...state, ...filters }));
  };

  goTo = destiny => {
    this.props.history.push(destiny);
  };

  componentDidUpdate(prevProps) {
    const { products } = this.props;
    const { products: prevProducts } = prevProps;
    if (products && prevProducts !== products) {
      this.setState({ products, events: { content: this.props.events || [] } });
    }
  }

  componentDidMount() {
    this.props.getClubs();
    this.props.getSellers();
    this.props.getMemberships();
  }
  render() {
    return (
      <main>
        <Helmet title="Sports Sold Products" />
        <Layout>
          <SportSession
            // @ts-ignore
            clubs={this.props.clubs}
            events={this.state.events}
            eventDetails={this.props.eventDetails}
            products={this.state.products}
            fetchData={this.onFetchData}
            searching={this.props.searching}
            filters={this.state.filters}
            onChangeFilter={this.onChangeFilter}
            getEvents={this.props.getEvents}
            getDetails={this.props.getDetails}
            getProducts={this.props.getProducts}
            sportsProducts={this.props.sportsProductsTypes}
            deleteSession={this.props.deleteSession}
            addAttendant={this.props.addAttendant}
            deleteAttendantOnEvent={this.props.deleteAttendantOnEvent}
            newAttendant={this.props.newAttendant}
            sellers={this.props.sellers}
            toggleEditSession={this.props.toggleEditSession}
            newSessionProductId={this.props.newSessionProductId}
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({ sportsProducts, catalogs, modals }) => {
  return {
    clubs: catalogs.clubs,
    products: sportsProducts.products,
    searching: sportsProducts.gettingProducts,
    sportsProductsTypes: catalogs.sportsProductsTypes,
    events: sportsProducts.events,
    eventDetails: sportsProducts.eventDetails,
    users: users.usersPage,
    newAttendant: sportsProducts.newAttendant,
    sellers: catalogs.sellers
  };
};

const mapDispatchToProps = {
  addAttendant,
  deleteAttendantOnEvent,
  deleteSession,
  getClubs,
  getDetails,
  getEvents,
  getMemberships,
  getProducts,
  getSportsProductsTypes,
  getUsers,
  getSellers,
  newSessionProductId,
  toggleEditSession
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SportsSession);
