import React from "react";
import { connect } from "react-redux";
import * as agreementsActions from "../../../actions/agreementGroups";
import * as modalActions from "../../../actions/modals";
import { PRICE_TYPES } from "../../../../types-business/Agreements";
import BenefitsConfiguration from "../../../components/agreementGroups/benefitsConfiguration";
import isNil from "lodash.isnil";

// TODO: Esta funcionalidad se heredó de los convenios; faltan exclusiones

interface Props {
  successfulSave: boolean;
  onClickSave: (e: object) => any;
  getAgreementMemberships: (id: string) => any;
  getClubsAgreement: (id: string) => any;
  getPricesAgreement: (
    convenioId: string,
    membershipId: string,
    clubId: string
  ) => any;
  agreementMemberships: object[];
  agreementClubs: object[];
  agreementPrices: object;
  checkRelative: (ev) => any;
  checkProportional: (ev) => any;
  onChangeMembershipValue: (ev) => any;
  onChangePriceType: (membershipPriceType: string) => any;
  checkLastMonth: (monthIndex, ev) => any;
  updateCellPrice: (monthIndex, memberIndex, ev) => any;
  onChangePriceTypeMonth: (monthIndex: number, type: PRICE_TYPES) => any;
  deleteMonthPrices: () => any;
  addMonthPrices: () => any;
  agreementModified: boolean;
  showConfirmBenefitsChangeModal: () => any;
  changesConfirmed: boolean;
  membershipChanged: (membershipId: string) => any;
  clubChanged: () => any;
  saveChangesBenefits: (agreementPrices: object) => any;
}

class AddPricesAgreementPage extends React.Component<Props> {
  render() {
    return (
      <div className="container p-0 m-0">
        <BenefitsConfiguration
          onClickSave={this.props.onClickSave}
          getMemberships={this.props.getAgreementMemberships}
          getClubs={this.props.getClubsAgreement}
          getPrices={this.props.getPricesAgreement}
          memberships={this.props.agreementMemberships}
          clubs={this.props.agreementClubs}
          prices={this.props.agreementPrices}
          onChangePriceType={this.props.onChangePriceType}
          onChangeMembershipValue={this.props.onChangeMembershipValue}
          checkRelative={this.props.checkRelative}
          checkProportional={this.props.checkProportional}
          updateCellPrice={this.props.updateCellPrice}
          onChangePriceTypeMonth={this.props.onChangePriceTypeMonth}
          addMonthPrices={this.props.addMonthPrices}
          deleteMonthPrices={this.props.deleteMonthPrices}
          checkLastMonth={this.props.checkLastMonth}
          membershipChanged={this.props.membershipChanged}
          clubChanged={this.props.clubChanged}
          changesConfirmed={this.props.changesConfirmed}
          entityModified={this.props.agreementModified}
          showConfirmBenefitsChangeModal={
            this.props.showConfirmBenefitsChangeModal
          }
          saveChangesBenefits={this.props.saveChangesBenefits}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showConfirmBenefitsChangeModal: () => {
    dispatch(modalActions.toggleConfirmBenefitsModal());
  },
  getAgreementMemberships: (id: string) => {
    dispatch(agreementsActions.getAgreementMemberships(id));
  },
  getClubsAgreement: (id: string) => {
    dispatch(agreementsActions.getClubsAgreement(id));
  },
  getPricesAgreement: (
    convenioId: string,
    membershipId: string,
    clubId: string
  ) => {
    let clubSend = isNil(clubId) ? "general" : clubId;
    dispatch(
      agreementsActions.getPricesAgreement(convenioId, membershipId, clubSend)
    );
  },
  checkProportional: ev => {
    dispatch(agreementsActions.checkProportional(ev));
  },
  checkRelative: ev => {
    dispatch(agreementsActions.checkRelative(ev));
  },
  onChangeMembershipValue: ev => {
    dispatch(agreementsActions.onChangeMembershipValue(ev));
  },
  onChangePriceType: (membershipPriceType: string) => {
    dispatch(agreementsActions.onChangePriceType(membershipPriceType));
  },
  updateCellPrice: (monthIndex, memberIndex, ev) => {
    dispatch(agreementsActions.updateCellPrice(monthIndex, memberIndex, ev));
  },
  checkLastMonth: (monthIndex, ev) => {
    dispatch(agreementsActions.checkLastMonth(monthIndex, ev));
  },
  onChangePriceTypeMonth: (monthIndex, type: PRICE_TYPES) => {
    dispatch(agreementsActions.onChangePriceTypeMonth(monthIndex, type));
  },
  deleteMonthPrices: () => {
    dispatch(agreementsActions.deleteMonthPrices());
  },
  addMonthPrices: () => {
    dispatch(agreementsActions.addMonthPrices());
  },
  membershipChanged: (membershipId: string) => {
    dispatch(agreementsActions.membershipChanged(membershipId));
  },
  clubChanged: () => {
    dispatch(agreementsActions.clubChanged());
  },
  saveChangesBenefits: agreementPrices => {
    dispatch(agreementsActions.saveChangesBenefits(agreementPrices));
  }
});

const mapStateToProps = state => {
  const agreements = state.agreementGroups;

  return {
    agreementMemberships: agreements.agreementMemberships,
    agreementClubs: agreements.agreementClubs,
    agreementPrices: agreements.agreementPrices,
    agreementModified: agreements.agreementModified,
    changesConfirmed: agreements.changesConfirmed
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPricesAgreementPage);
