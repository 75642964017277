import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import {
  infoNotification,
  SAVE_DEMAND_PENDING,
  UPDATE_DEMAND_PENDING,
  errorNotification,
  SAVE_DEMAND_REJECTED,
  UPDATE_DEMAND_REJECTED,
  successNotification,
  SAVE_DEMAND_FULFILLED,
  UPDATE_DEMAND_FULFILLED,
  SAVE_DEMAND_OBSERVATION_PENDING,
  SAVE_DEMAND_OBSERVATION_REJECTED,
  SAVE_DEMAND_OBSERVATION_FULFILLED,
  SAVE_DEMAND_DETAILS_PENDING,
  SAVE_DEMAND_DETAILS_REJECTED,
  SAVE_DEMAND_DETAILS_FULFILLED,
  SAVE_FOLLOW_UP_SURVEY_PENDING,
  SAVE_FOLLOW_UP_SURVEY_REJECTED,
  SAVE_FOLLOW_UP_SURVEY_FULFILLED,
  ERROR
} from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";
interface State {
  successfullyCreated: boolean;
  demands: object[];
  loadingDemands: boolean;
  demandById: object;
  surveySuccessfullyCreated: boolean;
}

const initialState: State = {
  successfullyCreated: false,
  demands: null,
  loadingDemands: false,
  demandById: null,
  surveySuccessfullyCreated: false
};

export default function demandsSchemasReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.GET_DEMAND_BY_ID}`: {
      return {
        ...state,
        demandById: null
      };
    }
    case `${actionTypes.GET_DEMAND_BY_ID}_PENDING`: {
      return {
        ...state,
        demandById: null
      };
    }
    case `${actionTypes.GET_DEMAND_BY_ID}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        demandById: null
      };
    }
    case `${actionTypes.GET_DEMAND_BY_ID}_FULFILLED`: {
      return {
        ...state,
        demandById: action.payload.data
      };
    }
    case `${actionTypes.GET_DEMANDS}_PENDING`: {
      return {
        ...state,
        demands: null,
        loadingDemands: true
      };
    }
    case `${actionTypes.GET_DEMANDS}_REJECTED`: {
      return {
        ...state,
        demands: null,
        loadingDemands: false
      };
    }
    case `${actionTypes.GET_DEMANDS}_FULFILLED`: {
      return {
        ...state,
        demands: action.payload.data,
        loadingDemands: false
      };
    }
    case `${actionTypes.UPDATE_DEMAND}_PENDING`: {
      infoNotification(UPDATE_DEMAND_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.UPDATE_DEMAND}_REJECTED`: {
      errorNotification(readMessageBack(action, UPDATE_DEMAND_REJECTED));
      return {
        ...state
      };
    }
    case `${actionTypes.UPDATE_DEMAND}_FULFILLED`: {
      successNotification(UPDATE_DEMAND_FULFILLED);
      return {
        ...state
      };
    }
    case `${actionTypes.SAVE_DEMAND}_PENDING`: {
      infoNotification(SAVE_DEMAND_PENDING);
      return {
        ...state,
        successfullyCreated: false
      };
    }
    case `${actionTypes.SAVE_DEMAND}_REJECTED`: {
      errorNotification(readMessageBack(action, SAVE_DEMAND_REJECTED));
      return {
        ...state,
        successfullyCreated: false
      };
    }
    case `${actionTypes.SAVE_DEMAND}_FULFILLED`: {
      successNotification(SAVE_DEMAND_FULFILLED);
      return {
        ...state,
        successfullyCreated: true
      };
    }
    case `${actionTypes.SAVE_FOLLOW_UP_SURVEY}_PENDING`: {
      infoNotification(SAVE_FOLLOW_UP_SURVEY_PENDING);
      return {
        ...state,
        surveySuccessfullyCreated: false
      };
    }
    case `${actionTypes.SAVE_FOLLOW_UP_SURVEY}_REJECTED`: {
      errorNotification(
        readMessageBack(action, SAVE_FOLLOW_UP_SURVEY_REJECTED)
      );
      return {
        ...state,
        surveySuccessfullyCreated: false
      };
    }
    case `${actionTypes.SAVE_FOLLOW_UP_SURVEY}_FULFILLED`: {
      successNotification(SAVE_FOLLOW_UP_SURVEY_FULFILLED);
      return {
        ...state,
        surveySuccessfullyCreated: true
      };
    }
    case `${actionTypes.SAVE_DEMAND_OBSERVATION}_PENDING`: {
      infoNotification(SAVE_DEMAND_OBSERVATION_PENDING);
      return {
        ...state,
        successfullyCreated: false
      };
    }
    case `${actionTypes.SAVE_DEMAND_OBSERVATION}_REJECTED`: {
      errorNotification(
        readMessageBack(action, SAVE_DEMAND_OBSERVATION_REJECTED)
      );
      return {
        ...state,
        successfullyCreated: false
      };
    }
    case `${actionTypes.SAVE_DEMAND_OBSERVATION}_FULFILLED`: {
      successNotification(SAVE_DEMAND_OBSERVATION_FULFILLED);
      return {
        ...state,
        successfullyCreated: true
      };
    }
    case `${actionTypes.SAVE_DEMAND_DETAILS}_PENDING`: {
      infoNotification(SAVE_DEMAND_DETAILS_PENDING);
      return {
        ...state,
        successfullyCreated: false
      };
    }
    case `${actionTypes.SAVE_DEMAND_DETAILS}_REJECTED`: {
      errorNotification(readMessageBack(action, SAVE_DEMAND_DETAILS_REJECTED));
      return {
        ...state,
        successfullyCreated: false
      };
    }
    case `${actionTypes.SAVE_DEMAND_DETAILS}_FULFILLED`: {
      successNotification(SAVE_DEMAND_DETAILS_FULFILLED);
      return {
        ...state,
        successfullyCreated: true
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
