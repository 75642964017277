import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import { getErrorMessage } from "../../utils/helpers";
import { ERROR, errorNotification } from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";

interface State {
  binnaclePartner: object[];
  binnaclePartnerLoading: boolean;
  binnaclePartnerError: object;
  binnaclePartnerMembership: object[];
  binnaclePartnerMembershipLoading: boolean;
  binnaclePartnerMembershipError: object;
}

const initialState: State = {
  binnaclePartner: null,
  binnaclePartnerLoading: false,
  binnaclePartnerError: null,
  binnaclePartnerMembership: null,
  binnaclePartnerMembershipLoading: false,
  binnaclePartnerMembershipError: null
};

export default function binnacleReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.GET_ACTIVITY_LOGS_BY_PARTNER}_PENDING`: {
      return {
        ...state,
        binnaclePartnerLoading: true,
        binnaclePartnerError: null
      };
    }
    case `${actionTypes.GET_ACTIVITY_LOGS_BY_PARTNER}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        binnaclePartner: null,
        binnaclePartnerLoading: false,
        binnaclePartnerError: getErrorMessage(action.payload)
      };
    }
    case `${actionTypes.GET_ACTIVITY_LOGS_BY_PARTNER}_FULFILLED`: {
      return {
        ...state,
        binnaclePartner: action.payload.data,
        binnaclePartnerLoading: false,
        binnaclePartnerError: null
      };
    }
    case `${actionTypes.GET_ACTIVITY_LOGS_BY_PARTNER_MEMBERSHIP}_PENDING`: {
      return {
        ...state,
        binnaclePartnerMembershipLoading: true,
        binnaclePartnerMembershipError: null
      };
    }
    case `${actionTypes.GET_ACTIVITY_LOGS_BY_PARTNER_MEMBERSHIP}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        binnaclePartnerMembership: null,
        binnaclePartnerMembershipLoading: false,
        binnaclePartnerMembershipError: getErrorMessage(action.payload)
      };
    }
    case `${actionTypes.GET_ACTIVITY_LOGS_BY_PARTNER_MEMBERSHIP}_FULFILLED`: {
      return {
        ...state,
        binnaclePartnerMembership: action.payload.data,
        binnaclePartnerMembershipLoading: false,
        binnaclePartnerMembershipError: null
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
