import React from "react";
import { connect } from "react-redux";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import SaveNextCarousel from "../../components/saveNextCarousel";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import { SPORTS_PRODUCTS_STEPS } from "../../../steps";
import * as sportsProductActions from "../../actions/sportsProducts";
import s from "./styles.scss";
import { userHasPermission } from "../../../utils/accessTree";
import permissionCodes from "../../../utils/permissionCodes";

interface Props {
  getSportProductById: (id) => any;
  sportProduct: object;
  history: any;
  match: any;
  location: any;
  index: number;
  component: any;
  clearBasicDataSaved: () => any;
}

interface State {}

class ComponentWithMembershipName extends React.Component<any> {
  render() {
    const { Component, index, rest, sportProduct } = this.props;
    return (
      <div className="w-100 p-0 m-0">
        {sportProduct && (
          <p className={s.primaryTitle}>
            {sportProduct.producto.nombre.toUpperCase()}
          </p>
        )}
        <Component {...rest} {...this.props} key={index} />
      </div>
    );
  }
}

class SportsProductsCarousel extends React.Component<Props, State> {
  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) this.props.getSportProductById(id);
  }

  onClickNext = e => {
    e.preventDefault();
    const params = {
      ...this.props.match.params,
      id: this.props.match.params.id
    };
    this.props.history.push(
      SPORTS_PRODUCTS_STEPS[this.props.index].getNextStep(params)
    );
  };

  getProductId = () => this.props.match.params.id;

  render() {
    const id = this.getProductId();
    if (this.props.index > 0) {
      this.props.clearBasicDataSaved();
    }

    const userHasCreateOrEditProductPermissions =
      userHasPermission(permissionCodes.PRODUCTS_CREATE_PRODUCT) ||
      userHasPermission(permissionCodes.PRODUCTS_EDIT_PRODUCT);

    return (
      <main>
        <Helmet title="Nuevo Producto" />
        <Layout>
          <SaveNextCarousel
            steps={SPORTS_PRODUCTS_STEPS.map(({ title, getHref }) => ({
              title,
              href: id ? getHref(id) : null
            }))}
            onClickNext={this.onClickNext}
            index={this.props.index}
            disableNext={!this.props.match.params.id}
            activeStep={id ? SPORTS_PRODUCTS_STEPS.length : 0}
            hiddenSaveButton={
              !userHasCreateOrEditProductPermissions ||
              (this.props.index == 5 ? true : false)
            }
          >
            {SPORTS_PRODUCTS_STEPS.map(
              ({ component: Component, ...rest }, index) => {
                return (
                  <ComponentWithMembershipName
                    Component={Component}
                    index={index}
                    rest={rest}
                    sportProduct={this.props.sportProduct}
                    id={id}
                    key={index}
                  />
                );
              }
            )}
          </SaveNextCarousel>
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSportProductById: (id: string) => {
    dispatch(sportsProductActions.getSportProductById(id));
  },
  clearBasicDataSaved: () => {
    dispatch(sportsProductActions.clearBasicDataSaved());
  }
});

const mapStateToProps = state => {
  const { sportsProducts } = state;
  return {
    sportProduct: sportsProducts.sportProductById
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(s)(SportsProductsCarousel));
