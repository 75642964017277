import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import BaseInput from "../../../toolkit/baseInput";
import { Alert, Button, FormGroup, Label, Input, Col, Row } from "reactstrap";
import ModalFrame from "../../../toolkit/modalFrame";
import s from "./styles.scss";
import { Club } from "../../../types/Clubs";
import Reform from "@franleplant/reform";
import { FormErrors } from "@franleplant/reform/types";
import axios from "axios";
import constants from "../../../utils/constants";
import DatePicker from "../common/DatePicker";
import moment from "moment";
import { connect } from "react-redux";
import { getCompanies } from "../../actions/catalogs";
import { Typeahead } from "react-bootstrap-typeahead";
//Concepto
const SW = "SW";
const GYM_PLUS = "GYM_PLUS";
const LOAD = "LOAD";
const ZUMA = "ZUMA";
const BTB = "BTB";
const GYM = "GYM";
//Empresa
const THANOS = "THANOS";
const MEGATLON = "MEGATLON";
//Regiones
const REGION_1 = "REGION_1";
const REGION_2 = "REGION_2";
const REGION_3 = "REGION_3";
const REGION_4 = "REGION_4";
const REGION_5 = "REGION_5";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  getCompanies: () => void;
  companiesTypes: object[];
  creatingClub: boolean;
  history: any;
  client: any;
  editClub: (clubId: string, club: Club) => any;
  creatingClubError: object;
  users: { userId: string }[];
  generalManagers: { userId: string }[];
  clubs: Club[];
  createClub: (fields: object) => any;
  clubCreated: boolean;
  clubToEdit: ClubToEdit;
  disableClubCreated: () => {};
  catalogClubs: { claveCorta: string }[];
  usersList: Array<User>;
}

interface User {
  id: string;
  name: string;
  username: string;
}

interface ClubToEdit {
  clubId: string;
  nombre: string;
  claveCorta: string;
  concepto: string;
  empresa: string;
  empresaInfo: null;
  fechaApertura: string;
  fechaPreventa: string;
  responsableApertura: string;
  activo: boolean;
  calle: string;
  numero: string;
  codigoPostal: string;
  colonia: string;
  municipio: string;
  estado: string;
  facturacionBloqueada: boolean;
  prefijoFactura: string;
}

interface State {
  fields: Club;
  editing: boolean;
  errors: FormErrors;
  opcionesConcepto: Option[];
  opcionesEmpresa: Option[];
  opcionesRegionOperacion: Option[];
  opcionesRegionVenta: Option[];
  coloniasOptions: object;
  municipiosOptions: object;
  estadosOptions: object;
  loadingAddress: boolean;
  tempAddress: Array<object>;
  selected: any;
}

const getCleanFields = () => {
  return {
    claveCorta: "",
    nombre: "",
    concepto: "",
    regionOperacion: "",
    regionVenta: "",
    empresa: "",
    empresaInfo: { id: -1 },
    responsableApertura: "",
    fechaApertura: "",
    fechaPreventa: "",
    calle: "",
    numero: "",
    codigoPostal: "",
    colonia: "",
    municipio: "",
    estado: "",
    activo: false,
    facturacionBloqueada: false,
    prefijoFactura: "",
    aplicaReferidos: true
  };
};

interface Option {
  value: string;
  label: string;
}

class CreateClubModal extends React.Component<Props, State> {
  state = {
    fields: getCleanFields(),
    editing: false,
    loadingAddress: false,
    errors: {},
    opcionesConcepto: [
      { value: "", label: "Seleccionar" },
      { value: SW, label: "sw" },
      { value: GYM_PLUS, label: "gym_plus" },
      { value: LOAD, label: "load" },
      { value: ZUMA, label: "zuma" },
      { value: BTB, label: "btb" },
      { value: GYM, label: "gym" }
    ],
    opcionesEmpresa: [
      { value: "", label: "Seleccionar" },
      { value: MEGATLON, label: "Megatlon" },
      { value: THANOS, label: "Thanos" }
    ],
    opcionesRegionOperacion: [
      { value: "", label: "Seleccionar" },
      { value: REGION_1, label: REGION_1 },
      { value: REGION_2, label: REGION_2 },
      { value: REGION_3, label: REGION_3 },
      { value: REGION_4, label: REGION_4 },
      { value: REGION_5, label: REGION_5 }
    ],
    opcionesRegionVenta: [
      { value: "", label: "Seleccionar" },
      { value: REGION_1, label: REGION_1 },
      { value: REGION_2, label: REGION_2 },
      { value: REGION_3, label: REGION_3 },
      { value: REGION_4, label: REGION_4 }
    ],
    coloniasOptions: [{ value: "", label: "Seleccionar la Colonia" }],
    municipiosOptions: [{ value: "", label: "Seleccionar el Municipio" }],
    estadosOptions: [{ value: "", label: "Seleccionar el Estado" }],
    tempAddress: null,
    selected: null
  };

  re = Reform.reactMixins.objectMixin(this);

  validationRules = {
    nombre: { required: true },
    claveCorta: {
      required: true,
      maxLength: 16,
      shortKeyExistence: value => this.validateShortKeyExistence(value)
    },
    concepto: { required: true },
    empresa: { required: true },
    responsableApertura: { required: true },
    calle: { required: true },
    numero: { required: true },
    codigoPostal: {
      required: true,
      maxLength: 5,
      minLength: 5
    },
    colonia: { required: true },
    municipio: { required: true },
    estado: { required: true },
    prefijoFactura: { required: true, minLength: 1 }
  };

  validationMessages = {
    required: _ => `Obligatorio`,
    minLength: ruleValue => `Debe tener al menos ${ruleValue} caracteres`,
    maxLength: ruleValue => `Deber tener máximo ${ruleValue} caracteres`,
    default: _ => `Inválido`,
    shortKeyExistence: _ => `Esta clave corta ya existe, modificala`
  };

  validateShortKeyExistence = value => {
    let valid = false;
    this.props.catalogClubs.forEach(club => {
      if (club.claveCorta == value)
        valid =
          this.props.clubToEdit && this.props.clubToEdit.claveCorta == value
            ? false
            : true;
    });
    return valid;
  };

  componentDidMount() {
    this.props.getCompanies();
    if (!this.props.isOpen && this.state.editing) {
      this.setState(prevState => {
        return {
          ...prevState,
          fields: getCleanFields(),
          editing: false
        };
      });
    }

    if (this.props.isOpen && !this.state.editing) {
      let fields;
      if (this.props.clubToEdit) {
        if (
          this.props.clubToEdit.direccion &&
          this.props.clubToEdit.direccion.codigoPostal &&
          this.props.clubToEdit.direccion.codigoPostal.toString().length == 5
        ) {
          this.getLocationByPostalCode(
            this.props.clubToEdit.direccion.codigoPostal
          );
        }

        const clubToEdit = this.props.clubToEdit;
        const clubAddress = clubToEdit.direccion || getCleanFields().direccion;

        fields = {
          ...this.state.fields,
          ...clubToEdit,
          codigoPostal: clubAddress.codigoPostal,
          colonia: clubAddress.colonia,
          calle: clubAddress.calle,
          numero: clubAddress.numero,
          municipio: clubAddress.municipio,
          estado: clubAddress.estado
        };
      } else {
        fields = getCleanFields();
      }

      this.setState(prevState => {
        return {
          ...prevState,
          fields,
          editing: true,
          errors: {}
        };
      });
    }
  }

  onChangeEmpresa = () => {
    return event => {
      const value = event.target.value;
      let state = { ...this.state };
      state.fields.empresaInfo.id = value;
      state.fields.empresa = value;
      this.setState(state);
    };
  };

  onChangeFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };

        return { ...state, error: {}, fields };
      });
      this.re.validateField(fieldName, value);
    };
  };

  // HACKXXX: No aplicaba a selector por nombre
  onChangeGerente = selecteds => {
    const sel = selecteds && selecteds.length > 0 ? selecteds[0] : null;
    const value = sel ? sel.id : null;

    this.setState({ selected: selecteds });

    const fields = {
      ...this.state.fields,
      responsableApertura: value
    } as any;

    this.setState({ fields });
    this.re.validateField("responsableApertura", value);
  };

  onChangeFecha = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: date
        };
        return { ...state, error: {}, fields };
      });
      this.re.validateField(fieldName, date);
    };
  };

  onSubmit = e => {
    e.preventDefault();
    if (this.re.validateFormFromState()) {
      if (this.props.clubToEdit) {
        this.props.editClub(
          this.props.clubToEdit && this.props.clubToEdit.clubId,
          this.state.fields
        );
      } else {
        this.props.createClub(this.state.fields);
      }
    }
  };

  getOptionsResponsible = (users: any) => {
    const content = users && users.content ? users.content : users || [];
    const response = [];

    content.forEach((user: any) => {
      const option = {
        label: `${user.name || ""} ${user.firstSurname ||
          ""} ${user.secondSurname || ""}`.trim(),
        id: user.userId
      };

      // Selección del responsable, en modo de edición
      if (
        this.state.selected == null &&
        this.state.fields.responsableApertura == user.userId
      ) {
        this.setState({ selected: [option] });
      }

      response.push(option);
    });

    return response;
  };

  handleChangeActive = e => {
    const activo = e.target.checked;
    this.setState(state => {
      return {
        ...state,
        fields: {
          ...state.fields,
          activo
        }
      };
    });
  };

  handleChangeBillingBlocked = e => {
    const facturacionBloqueada = e.target.checked;
    this.setState(state => {
      return {
        ...state,
        fields: {
          ...state.fields,
          facturacionBloqueada
        }
      };
    });
  };

  handleChangeReferredAppliance = e => {
    const aplicaReferidos = e.target.checked;
    this.setState(state => {
      return {
        ...state,
        fields: {
          ...state.fields,
          aplicaReferidos
        }
      };
    });
  };

  handleChangePostalCode = e => {
    e.preventDefault();
    const value = e.target.value.toString();
    this.onChangePostalCode(value);
    if (value.length == 5) {
      this.getLocationByPostalCode(value);
    }
  };

  onChangePostalCode = codigoPostal => {
    this.setState(state => {
      const fields = {
        ...state.fields,
        codigoPostal
      };

      return { ...state, error: {}, fields };
    });
  };

  onChangeAddress(name, value) {
    this.setState(state => {
      const fields = {
        ...state.fields,
        [name]: value
      };

      return { ...state, error: {}, fields };
    });
    this.re.validateField(name, value);
  }

  onChangeColonia = e => {
    const value = e.target.value;
    this.onChangeAddress("colonia", value);
    this.setComplementalData(value);
  };

  setComplementalData(value) {
    this.state.tempAddress.forEach(colonia => {
      if (colonia.nombre === value) {
        this.onChangeAddress("municipio", colonia.municipio);
        this.onChangeAddress("estado", colonia.estado);
      }
    });
  }

  getLocationByPostalCode = postalCode => {
    this.setState(state => {
      return { ...state, error: {}, loadingAddress: true };
    });
    axios
      .get(
        `${constants.BASE_URL}/api/address/locationByPostalCode/${postalCode}`,
        {
          withCredentials: true
        }
      )
      .then(response => {
        this.changeAddressCombos(response.data);
      })
      .catch(() => {
        this.setState(state => {
          return { ...state, error: {}, loadingAddress: false };
        });
        alert(
          "Ocurrio un error, verifica tu conexion a internet o recarga la página"
        );
      });
  };

  changeAddressCombos = address => {
    const coloniasNombre = {};
    const municipiosNombre = {};
    const estadosNombre = {};

    const colonias = [
      {
        value: "",
        label:
          address.length > 0
            ? "Seleccionar la Colonia"
            : "Verifica el Código Postal"
      }
    ];
    const municipios = [
      {
        value: "",
        label:
          address.length > 0
            ? "Seleccionar el Municipio"
            : "Verifica el Código Postal"
      }
    ];
    const estados = [
      {
        value: "",
        label:
          address.length > 0
            ? "Seleccionar el Estado"
            : "Verifica el Código Postal"
      }
    ];

    address.forEach(direccion => {
      const { nombre, municipio, estado } = direccion;
      if (!coloniasNombre[nombre]) {
        colonias.push({
          value: nombre,
          label: nombre
        });
        coloniasNombre[nombre] = true;
      }
      if (!municipiosNombre[municipio]) {
        municipios.push({
          value: municipio,
          label: municipio
        });
        municipiosNombre[municipio] = true;
      }
      if (!estadosNombre[estado]) {
        estados.push({
          value: estado,
          label: estado
        });
        estadosNombre[estado] = true;
      }
    });
    let cOptions = this.state.coloniasOptions;
    let mOptions = this.state.municipiosOptions;
    let eOptions = this.state.estadosOptions;
    cOptions = colonias;
    mOptions = municipios;
    eOptions = estados;

    this.setState(state => {
      return {
        ...state,
        coloniasOptions: cOptions,
        municipiosOptions: mOptions,
        estadosOptions: eOptions,
        loadingAddress: false,
        tempAddress: address
      };
    });
  };

  render() {
    return (
      <ModalFrame
        title={`${this.props.clubToEdit ? "Editar" : "Crear"} Club`}
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        size="lg"
      >
        <div className={s.modalContainer}>
          <div className="h-100">
            <form className={s.basicDataClub}>
              <div className="form-row">
                <div className="col">
                  <BaseInput
                    label={"Nombre"}
                    name="nombre"
                    type="text"
                    id="nombre"
                    placeholder="Nombre"
                    value={this.state.fields.nombre}
                    onChange={this.onChangeFactory("nombre")}
                    errors={this.re.mapFieldErrors("nombre")}
                  />
                </div>
                <div className="col">
                  <BaseInput
                    label={"Clave Corta"}
                    name="claveCorta"
                    type="text"
                    id="claveCorta"
                    placeholder="Clave Corta"
                    value={this.state.fields.claveCorta}
                    onChange={this.onChangeFactory("claveCorta")}
                    errors={this.re.mapFieldErrors("claveCorta")}
                  />
                </div>
                <div className="col">
                  <Label className={s.label}>Gerente General:</Label>
                  <Typeahead
                    id={"responsable"}
                    promptText={"Gerente General"}
                    onChange={this.onChangeGerente}
                    bsSize={"sm"}
                    emptyLabel={"Sin coincidencias"}
                    disabled={this.props.users ? false : true}
                    maxResults={20}
                    options={this.getOptionsResponsible(this.props.users || [])}
                    selected={this.state.selected}
                  />
                </div>
              </div>
              {/* <div className="form-row">
                {this.props.users && (
                  <div className="col">
                    <BaseInput
                      label={"Gerente General"}
                      name="gerenteGeneral"
                      type="select"
                      id="gerenteGeneral"
                      options={this.getOptionsResponsible(
                        this.props.generalManagers
                      )}
                      placeholder="Operador"
                      value={this.state.fields.gerenteGeneral}
                      onChange={this.onChangeFactory("gerenteGeneral")}
                      errors={this.re.mapFieldErrors("gerenteGeneral")}
                    />
                  </div>
                )}
              </div> */}
              <div className="form-row">
                <div className="col">
                  <BaseInput
                    label={"Tipo"}
                    name="concepto"
                    type="select"
                    options={this.state.opcionesConcepto}
                    id="concepto"
                    placeholder="Tipo"
                    value={this.state.fields.concepto}
                    onChange={this.onChangeFactory("concepto")}
                    errors={this.re.mapFieldErrors("concepto")}
                  />
                </div>
                <div className="col">
                  <BaseInput
                    label="Empresa"
                    name="empresa"
                    id="empresa"
                    type="select"
                    placeholder="Seleccionar empresa"
                    value={
                      this.state.fields.empresaInfo &&
                      this.state.fields.empresaInfo.id
                    }
                    onChange={this.onChangeEmpresa()}
                    size={"sm"}
                    options={[
                      { value: "", label: "Seleccionar empresa" }
                    ].concat(
                      this.props.companiesTypes
                        ? this.props.companiesTypes.map(option => ({
                            value: option.id,
                            label: option.nombre
                          }))
                        : []
                    )}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="col">
                  <DatePicker
                    label="Inicio Apertura"
                    name="fechaApertura"
                    id="fechaApertura"
                    selected={this.state.fields.fechaApertura}
                    onChange={this.onChangeFecha("fechaApertura")}
                    errors={this.re.mapFieldErrors("fechaApertura")}
                  />
                </div>
                <div className="col">
                  <DatePicker
                    label="Fecha Preventa"
                    name="fechaPreventa"
                    id="fechaPreventa"
                    selected={this.state.fields.fechaPreventa}
                    onChange={this.onChangeFecha("fechaPreventa")}
                    errors={this.re.mapFieldErrors("fechaPreventa")}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col">
                  <BaseInput
                    label={"Región operación"}
                    name="regionOperacion"
                    type="select"
                    options={this.state.opcionesRegionOperacion}
                    id="regionOperacion"
                    placeholder="Región operación"
                    value={this.state.fields.regionOperacion}
                    onChange={this.onChangeFactory("regionOperacion")}
                    errors={this.re.mapFieldErrors("regionOperacion")}
                  />
                </div>
                <div className="col">
                  <BaseInput
                    label={"Región venta"}
                    name="regionVenta"
                    type="select"
                    options={this.state.opcionesRegionVenta}
                    id="regionVenta"
                    placeholder="Región venta"
                    value={this.state.fields.regionVenta}
                    onChange={this.onChangeFactory("regionVenta")}
                    errors={this.re.mapFieldErrors("regionVenta")}
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="col">
                  <div className={s.checkboxContainer}>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={this.state.fields.activo}
                          onChange={e => this.handleChangeActive(e)}
                        />{" "}
                        Activo
                      </Label>
                    </FormGroup>
                  </div>
                </div>

                <div className="col">
                  <div className={s.checkboxContainer}>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={this.state.fields.aplicaReferidos}
                          onChange={e => this.handleChangeReferredAppliance(e)}
                        />{" "}
                        Aplica referidos
                      </Label>
                    </FormGroup>
                  </div>
                </div>

                <div className="col">
                  <div className={s.checkboxContainer}>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={this.state.fields.facturacionBloqueada}
                          onChange={e => this.handleChangeBillingBlocked(e)}
                        />{" "}
                        Facturación bloqueada
                      </Label>
                    </FormGroup>
                  </div>
                </div>
                <div className="col">
                  <BaseInput
                    label={"Prefijo factura"}
                    name="prefijoFactura"
                    type="text"
                    id="prefijoFactura"
                    placeholder="Prefijo factura"
                    value={this.state.fields.prefijoFactura}
                    onChange={this.onChangeFactory("prefijoFactura")}
                    errors={this.re.mapFieldErrors("prefijoFactura")}
                  />
                </div>
              </div>
              <hr />
              <Row>
                <Col xs={12}>
                  <h5>Dirección</h5>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={4}>
                  <BaseInput
                    label={"Calle"}
                    name={`calle`}
                    type="text"
                    id={`calle`}
                    placeholder={"Calle"}
                    value={this.state.fields.calle}
                    onChange={this.onChangeFactory("calle")}
                    errors={this.re.mapFieldErrors("calle")}
                  />
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <BaseInput
                    label={"Número"}
                    name={`numero`}
                    type="text"
                    id={`numero`}
                    placeholder={"Número"}
                    value={this.state.fields.numero}
                    onChange={this.onChangeFactory("numero")}
                    errors={this.re.mapFieldErrors("numero")}
                  />
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <BaseInput
                    label={"Código Postal"}
                    name={`codigoPostal`}
                    type="text"
                    id={`codigoPostal`}
                    placeholder={"Código Postal"}
                    value={this.state.fields.codigoPostal}
                    onChange={e => this.handleChangePostalCode(e)}
                    errors={this.re.mapFieldErrors("codigoPostal")}
                  />
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <BaseInput
                    label={"Colonia"}
                    name={`colonia`}
                    type="select"
                    options={this.state.coloniasOptions}
                    id={`colonia`}
                    placeholder={"Colonia"}
                    disabled={this.state.loadingAddress}
                    value={this.state.fields.colonia}
                    onChange={e => this.onChangeColonia(e)}
                    errors={this.re.mapFieldErrors("colonia")}
                  />
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <BaseInput
                    label={"Municipio"}
                    name={`municipio`}
                    type="select"
                    options={this.state.municipiosOptions}
                    id={`municipio`}
                    placeholder={"Municipio"}
                    disabled={this.state.loadingAddress}
                    value={this.state.fields.municipio}
                    onChange={this.onChangeFactory("municipio")}
                    errors={this.re.mapFieldErrors("municipio")}
                  />
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <BaseInput
                    label={"Estado"}
                    name={`estado`}
                    type="select"
                    options={this.state.estadosOptions}
                    id={`estado`}
                    placeholder={"Estado"}
                    disabled={this.state.loadingAddress}
                    value={this.state.fields.estado}
                    onChange={this.onChangeFactory("estado")}
                    errors={this.re.mapFieldErrors("estado")}
                  />
                </Col>
              </Row>
            </form>
          </div>
          <div className={s.formGroup}>
            <div className="row">
              <div className="col" />
              <div className="col-2">
                <Button
                  className={`${s.buttonMarginTop} ${s.primaryButton}`}
                  onClick={e => this.onSubmit(e)}
                  size="sm"
                  block
                  disabled={this.props.creatingClub}
                >
                  {"Guardar"}
                </Button>
              </div>
            </div>
          </div>
          {this.props.creatingClubError && (
            <span>
              <hr />
              <Alert color="danger">
                Ocurrio un error creando el club. Intente nuevamente.
              </Alert>
            </span>
          )}
        </div>
      </ModalFrame>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getCompanies: () => {
    dispatch(getCompanies());
  }
});

const mapStateToProps = state => {
  const { catalogs } = state;
  return {
    companiesTypes: catalogs.companiesTypes || []
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(s)(CreateClubModal))
);
