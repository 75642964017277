import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import IAction from "../../../types/IAction";
import * as reportes from "../../actions/reports";
import Layout from "../../components/layout";
import ReportPartnerMembershipsWithoutCharge from "../../components/partnerMembershipsWithoutMonthChargeReport";

interface Props {
  downloadReportPartnerMembershipWithoutMonthCharge: () => IAction;
}

interface State {}

class ReportPartnerMembershipsWithoutChargePage extends React.Component<
  Props,
  State
> {
  state = {};

  render() {
    return (
      <main>
        <Helmet title="ReportPartnerMembershipsWithoutCharge" />
        <Layout>
          <ReportPartnerMembershipsWithoutCharge
            downloadReportPartnerMembershipWithoutMonthCharge={
              this.props.downloadReportPartnerMembershipWithoutMonthCharge
            }
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({}) => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  downloadReportPartnerMembershipWithoutMonthCharge: () => {
    dispatch(reportes.downloadReportPartnerMembershipWithoutMonthCharge());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportPartnerMembershipsWithoutChargePage);
