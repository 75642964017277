import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import {
  infoNotification,
  errorNotification,
  successNotification,
  SCHEMAS_BY_PERIOD_PENDING,
  SCHEMAS_BY_PERIOD_ERROR,
  SCHEMAS_BY_PERIOD,
  ERROR
} from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";
interface State {
  periods: object[];
  periodById: object;
}
const initialState: State = {
  periods: null,
  periodById: null
};

export default function periodsReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.SCHEMAS_BY_PERIOD}_PENDING`: {
      infoNotification(SCHEMAS_BY_PERIOD_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.SCHEMAS_BY_PERIOD}_REJECTED`: {
      errorNotification(readMessageBack(action, SCHEMAS_BY_PERIOD_ERROR));
      return {
        ...state
      };
    }
    case `${actionTypes.SCHEMAS_BY_PERIOD}_FULFILLED`: {
      successNotification(SCHEMAS_BY_PERIOD);
      return {
        ...state
      };
    }
    case `${actionTypes.GET_PERIOD_BY_ID}_PENDING`: {
      return {
        ...state,
        periodById: null
      };
    }
    case `${actionTypes.GET_PERIOD_BY_ID}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        periodById: null
      };
    }
    case `${actionTypes.GET_PERIOD_BY_ID}_FULFILLED`: {
      return {
        ...state,
        periodById: action.payload.data
      };
    }
    case `${actionTypes.GET_PERIODS}_PENDING`: {
      return {
        ...state,
        periods: null
      };
    }
    case `${actionTypes.GET_PERIODS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        periods: null
      };
    }
    case `${actionTypes.GET_PERIODS}_FULFILLED`: {
      return {
        ...state,
        periods: action.payload.data
      };
    }
    case `${actionTypes.GET_MONTH_PERIODS}_PENDING`: {
      return {
        ...state,
        periods: null
      };
    }
    case `${actionTypes.GET_MONTH_PERIODS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        periods: null
      };
    }
    case `${actionTypes.GET_MONTH_PERIODS}_FULFILLED`: {
      return {
        ...state,
        periods: action.payload.data
      };
    }
    case `${actionTypes.GET_PAST_PERIODS}_PENDING`: {
      return {
        ...state,
        periods: null
      };
    }
    case `${actionTypes.GET_PAST_PERIODS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        periods: null
      };
    }
    case `${actionTypes.GET_PAST_PERIODS}_FULFILLED`: {
      return {
        ...state,
        periods: action.payload.data
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
