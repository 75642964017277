import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";
import { QueryArguments } from "../../types-business/QueryArguments";
import { AGREEMENT_PRICE } from "../../types-business/Agreements";
import FileDownload from "js-file-download";
import moment from "moment";

const AGREEMENT_GROUPS_BASE_URL = "/api/agreement-groups";

export const getAgreements = (args?: QueryArguments): IAction => {
  return {
    type: actionTypes.GROUP_GET_AGREEMENTS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${AGREEMENT_GROUPS_BASE_URL}`, {
        withCredentials: true,
        params: { ...args, sort: "updatedDate,desc" }
      })
    }
  };
};

export const getAgreementsRequest = (args?: QueryArguments) => {
  return axios.get(`${constants.BASE_URL}${AGREEMENT_GROUPS_BASE_URL}`, {
    withCredentials: true,
    params: { ...args, sort: "updatedDate,desc" }
  });
};

export const getFilterAgreements = (args?: QueryArguments): IAction => {
  return {
    type: actionTypes.GROUP_FILTER_AGREEMENTS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${AGREEMENT_GROUPS_BASE_URL}`, {
        withCredentials: true,
        params: { ...args, sort: "updatedDate,desc" }
      })
    }
  };
};

export const createAgreement = (fields: object): IAction => {
  return {
    type: actionTypes.GROUP_CREATE_AGREEMENT,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${AGREEMENT_GROUPS_BASE_URL}`,
        fields,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const searchByAttributes = (attributes): IAction => {
  return {
    type: actionTypes.GROUP_SEARCH_AGREEMENTS_BY_ATTRIBUTES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${AGREEMENT_GROUPS_BASE_URL}/search`,
        {
          withCredentials: true,
          params: {
            ...attributes
          }
        }
      )
    }
  };
};

export const editAgreement = (id: string, fields: object): IAction => {
  return {
    type: actionTypes.GROUP_CREATE_AGREEMENT,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${AGREEMENT_GROUPS_BASE_URL}/${id}`,
        fields,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const disableAgreementCreated = (): IAction => {
  return {
    type: actionTypes.GROUP_DISABLE_AGREEMENT_CREATED
  };
};

export const disableBasicAgreementSaved = (): IAction => {
  return {
    type: actionTypes.GROUP_DISABLE_BASIC_AGREEMENT_SAVED
  };
};

export const getPrices = (params?: QueryArguments): IAction => {
  return {
    type: actionTypes.GROUP_GET_PRICES_AGREEMENT,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${AGREEMENT_GROUPS_BASE_URL}/prices`,
        {
          withCredentials: true,
          params
        }
      )
    }
  };
};

export const saveBasicAgreement = (agreement: object): IAction => {
  return {
    type: actionTypes.GROUP_SAVE_BASIC_AGREEMENT,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${AGREEMENT_GROUPS_BASE_URL}/basicData`,
        agreement,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const updateBasicAgreement = (
  convenioId: string,
  agreement: object
): IAction => {
  return {
    type: actionTypes.GROUP_UPDATE_BASIC_AGREEMENT,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${AGREEMENT_GROUPS_BASE_URL}/basicData/${convenioId}`,
        agreement,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const addClubsToAgreement = (
  convenioId: string,
  clubs: object[]
): IAction => {
  return {
    type: actionTypes.GROUP_ADD_CLUBS_AGREEMENT,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${AGREEMENT_GROUPS_BASE_URL}/${convenioId}/clubs`,
        clubs,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getClubsAgreement = (id: string): IAction => {
  return {
    type: actionTypes.GROUP_GET_CLUBS_AGREEMENT,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${AGREEMENT_GROUPS_BASE_URL}/${id}/clubs`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const addMaintenancesToAgreement = (
  convenioId: string,
  maintenances: object[]
): IAction => {
  return {
    type: actionTypes.GROUP_ADD_MAINTENANCES_AGREEMENT,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${AGREEMENT_GROUPS_BASE_URL}/${convenioId}/maintenances`,
        maintenances,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getMaintenancesAgreement = (id: string): IAction => {
  return {
    type: actionTypes.GROUP_GET_MAINTENANCES_AGREEMENT,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${AGREEMENT_GROUPS_BASE_URL}/${id}/maintenances`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getPricesAgreement = (
  convenioId: string,
  membershipId: string,
  clubId: string
): IAction => {
  return {
    type: actionTypes.GROUP_GET_PRICES_AGREEMENT,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${AGREEMENT_GROUPS_BASE_URL}/${convenioId}/memberships/${membershipId}/clubs/${clubId}/benefits`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getAgreementMemberships = (id: string): IAction => {
  return {
    type: actionTypes.GROUP_GET_MEMBERSHIPS_AGREEMENT,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${AGREEMENT_GROUPS_BASE_URL}/${id}/memberships`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const checkProportional = ev => {
  const checked = ev.target.checked;
  return {
    type: actionTypes.GROUP_CHECK_PROPORTIONAL_AGREEMENT,
    payload: checked
  };
};

export const checkRelative = ev => {
  const checked = ev.target.checked;
  return {
    type: actionTypes.GROUP_CHECK_ABSOLUTE_AGREEMENT,
    payload: checked
  };
};

export const onChangeMembershipValue = ev => {
  ev.preventDefault();
  const value = ev.target.value;
  return {
    type: actionTypes.GROUP_AGREEMENT_MEMBERSHIP_VALUE,
    payload: value
  };
};

export const onChangePriceType = (membershipPriceType: string) => {
  return {
    type: actionTypes.GROUP_AGREEMENT_MEMBERSHIP_VALUE_TYPE,
    payload: membershipPriceType
  };
};

export const onChangePriceTypeMonth = (
  monthIndex: number,
  priceType: string
) => {
  return {
    type: actionTypes.GROUP_AGREEMENT_MEMBERSHIP_VALUE_TYPE_MONTH,
    payload: {
      priceType,
      monthIndex
    }
  };
};

export const updateCellPrice = (monthIndex, memberIndex, ev) => {
  ev.preventDefault();
  const value = ev.target.value;
  return {
    type: actionTypes.GROUP_AGREEMENT_PRICE_CELL,
    payload: {
      monthIndex,
      memberIndex,
      value
    }
  };
};

export const checkLastMonth = (monthIndex, ev) => {
  const checked = ev.target.checked;
  return {
    type: actionTypes.GROUP_CHECK_LAST_MONTH_AGREEMENT,
    payload: {
      monthIndex,
      checked
    }
  };
};

export const deleteMonthPrices = () => {
  return {
    type: actionTypes.GROUP_DELETE_MONTH_PRICES_AGREEMENT
  };
};

export const addMonthPrices = () => {
  return {
    type: actionTypes.GROUP_ADD_MONTH_PRICES_AGREEMENT
  };
};

export const membershipChanged = (membershipId: string) => {
  return {
    type: actionTypes.GROUP_MEMBERSHIP_CHANGED_AGREEMENT,
    payload: membershipId
  };
};

export const clubChanged = () => {
  return {
    type: actionTypes.GROUP_CLUB_CHANGED_AGREEMENT
  };
};

export const saveChangesBenefits = (agreementPrices: AGREEMENT_PRICE) => {
  return {
    type: actionTypes.GROUP_SAVE_CHANGES_BENEFITS_AGREEMENT,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${AGREEMENT_GROUPS_BASE_URL}/benefits`,
        agreementPrices,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getAgreementDetailById = (id: string): IAction => {
  return {
    type: actionTypes.GROUP_GET_AGREEMENT_DETAIL,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${AGREEMENT_GROUPS_BASE_URL}/${id}/detail`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getAgreementById = (id: string): IAction => {
  return {
    type: actionTypes.GROUP_GET_AGREEMENT_BY_ID,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${AGREEMENT_GROUPS_BASE_URL}/basicData/${id}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getPartnerMembershipsWithAgreementsReportRequest = args => {
  const params = {
    page: 0,
    size: 20,
    ...args
  };
  return axios.get(`${constants.BASE_URL}${AGREEMENT_GROUPS_BASE_URL}/report`, {
    withCredentials: true,
    params
  });
};

export const executePartnerMembershipsWithAgreementsReportDownload = args => {
  const params = {
    page: 0,
    size: 20,
    ...args
  };

  return axios
    .get(`${constants.BASE_URL}${AGREEMENT_GROUPS_BASE_URL}/report/download`, {
      withCredentials: true,
      params,
      responseType: "blob"
    })
    .then(({ data }) => {
      FileDownload(
        data,
        "ReporteMembresiasConConvenio_" +
          moment().format("YYYY-MM-DD HH:mm") +
          ".csv"
      );
    });
};

export const executeAgreementsClubAccessReportDownload = args => {
  return axios
    .get(
      `${constants.BASE_URL}${AGREEMENT_GROUPS_BASE_URL}/report/club-access/download`,
      {
        withCredentials: true,
        params: args,
        responseType: "blob"
      }
    )
    .then(({ data }) => {
      FileDownload(
        data,
        "ReporteConvenioAforo_" + moment().format("YYYY-MM-DD HH:mm") + ".csv"
      );
    });
};
