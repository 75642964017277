import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";
import { QueryArguments } from "../../types-business/QueryArguments";

const baseUrl = `/api/products`;

export const getProducts = (params?: QueryArguments): IAction => {
  return {
    type: actionTypes.GET_SPORTS_PRODUCTS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}`, {
        withCredentials: true,
        params: { ...params }
      })
    }
  };
};

export const getPromotions = (id: string): IAction => {
  return {
    type: actionTypes.GET_PROMOTIONS_SPORTS_PRODUCTS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/${id}/promotions`, {
        withCredentials: true
      })
    }
  };
};

export const getPromotionBenefits = (
  id: string,
  promotionId: string
): IAction => {
  return {
    type: actionTypes.GET_PROMOTION_BENEFITS_SPORTS_PRODUCTS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/${id}/promotions/${promotionId}/benefits`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const savePromotion = (id: string, body: object): IAction => {
  return {
    type: actionTypes.SAVE_PROMOTION_SPORTS_PRODUCTS,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${baseUrl}/${id}/promotions`,
        body,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const updatePromotion = (
  id: string,
  promotionId: number,
  body: object
): IAction => {
  return {
    type: actionTypes.UPDATE_PROMOTION_SPORTS_PRODUCTS,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${baseUrl}/${id}/promotions/${promotionId}`,
        body,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const saveSportsProductConfig = (
  id: string,
  fields: object
): IAction => {
  return {
    type: actionTypes.SAVE_CONFIG_SPORTS_PRODUCTS,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${baseUrl}/${id}/general-config`,
        fields,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getCashboxById = (cashboxId: string): IAction => {
  return {
    type: actionTypes.GET_CASHBOX_BY_ID,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/cashbox/${cashboxId}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getCashboxByIdRequest = (cashboxId: string) => {
  return axios.get(`${constants.BASE_URL}${baseUrl}/cashbox/${cashboxId}`, {
    withCredentials: true
  });
};

export const getCashboxPersonId = (personId: number): IAction => {
  return {
    type: actionTypes.GET_CHECKBOXES_BY_PERSON_ID,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/cashbox/person/${personId}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getCashboxByMembership = (params: object): IAction => {
  return {
    type: actionTypes.GET_CASHBOXES_BY_MEMBERSHIP,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/cashbox/by-membership`,
        {
          withCredentials: true,
          params
        }
      )
    }
  };
};

export const getQuoteByPersonId = (
  personId: number,
  params: object
): IAction => {
  return {
    type: actionTypes.GET_QUOTE_BY_PERSON_ID,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/quotes/person/${personId}`,
        {
          withCredentials: true,
          params
        }
      )
    }
  };
};

export const getQuoteByMembership = (params: object): IAction => {
  return {
    type: actionTypes.GET_QUOTE_BY_MEMBERSHIP,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/quotes/by-membership`,
        {
          withCredentials: true,
          params
        }
      )
    }
  };
};

export const getQuoteFilteredClubAgreement = (params: object): IAction => {
  return {
    type: actionTypes.GET_QUOTE_FILTERED,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/quotes/filtered`, {
        withCredentials: true,
        params
      })
    }
  };
};

export const saveSPQuote = (fields: object): IAction => {
  return {
    type: actionTypes.SAVE_QUOTE_SPORTS_PRODUCTS,
    payload: {
      promise: axios.post(`${constants.BASE_URL}${baseUrl}/quotes`, fields, {
        withCredentials: true
      })
    }
  };
};

export const loadSPQuote = (
  fields: object,
  productsList: object[]
): IAction => {
  return {
    type: actionTypes.LOAD_QUOTE_SPORTS_PRODUCTS,
    payload: { fields, productsList }
  };
};

export const pendingPayment = (amount: number): IAction => {
  return {
    type: actionTypes.PENDING_PAYMENT,
    payload: amount
  };
};

export const saveSportsProductComissions = (
  id: string,
  fields: object
): IAction => {
  return {
    type: actionTypes.SAVE_COMISSIONS_SPORTS_PRODUCTS,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${baseUrl}/${id}/comissions`,
        fields,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const autorizeComissions = (
  idProducto: string,
  tipo: string
): IAction => {
  return {
    type: actionTypes.AUTORIZE_COMISSIONS,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${baseUrl}/${idProducto}/authorize-commissions`,
        {
          tipo
        },
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getSportsProductConfig = (id: string): IAction => {
  return {
    type: actionTypes.GET_CONFIG_SPORTS_PRODUCTS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/basic-data/${id}`, {
        withCredentials: true
      })
    }
  };
};

export const sendPhotoFile = (pricesFile: Blob, productId: number): IAction => {
  const data = new FormData();
  data.append("Foto", pricesFile);

  return {
    type: actionTypes.SEND_PHOTO_PRODUCT_FILE,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${baseUrl}/${productId}/photo`,
        data,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const setProductPhoto = (payload: Blob): IAction => {
  return {
    type: actionTypes.SET_PRODUCT_PHOTO,
    payload
  };
};

export const getSportsProductComissions = (id: string): IAction => {
  return {
    type: actionTypes.GET_COMISSIONS_SPORTS_PRODUCTS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/${id}/comissions`, {
        withCredentials: true
      })
    }
  };
};

export const getSPComissionsCoordination = (id: string): IAction => {
  return {
    type: actionTypes.GET_COMISSIONS_SPORTS_PRODUCTS_COORDINATION,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/${id}/comissions-coordination`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getSPComissionsOperation = (id: string): IAction => {
  return {
    type: actionTypes.GET_COMISSIONS_SPORTS_PRODUCTS_OPERATION,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/${id}/comissions-operation`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const saveBasicData = (product: object): IAction => {
  return {
    type: actionTypes.SAVE_BASIC_SPORTS_PRODUCTS,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${baseUrl}/basic-data`,
        product,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const updateBasicData = (id: string, product: object): IAction => {
  return {
    type: actionTypes.SAVE_BASIC_SPORTS_PRODUCTS,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${baseUrl}/basic-data/${id}`,
        product,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getBasicData = (id: string): IAction => {
  return {
    type: actionTypes.GET_BASIC_SPORTS_PRODUCTS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/basic-data/${id}`, {
        withCredentials: true
      })
    }
  };
};

export const getGroupsSportsProduct = (id: string): IAction => {
  return {
    type: actionTypes.GET_GROUPS_SPORTS_PRODUCTS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/${id}/groups`, {
        withCredentials: true
      })
    }
  };
};

export const getClubsSportsProduct = (id: string) => {
  return axios.get(`${constants.BASE_URL}${baseUrl}/${id}/clubs`, {
    withCredentials: true
  });
};

export const updateGroupsSportsProduct = (
  id: string,
  groups: object[]
): IAction => {
  return {
    type: actionTypes.UPDATE_GROUPS_SPORTS_PRODUCTS,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${baseUrl}/${id}/groups`,
        groups,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const addPricesToSportsProducts = (
  id: string,
  prices: object
): IAction => {
  return {
    type: actionTypes.ADD_PRICES_SPORTS_PRODUCTS,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${baseUrl}/${id}/prices-to-authorize`,
        prices,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getPricesToSportsProducts = (params): IAction => {
  return {
    type: actionTypes.GET_PRICES_SPORTS_PRODUCTS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/prices`, {
        withCredentials: true,
        params
      })
    }
  };
};

export const deleteProducts = (productId: string): IAction => {
  return {
    type: actionTypes.DELETE_PRODUCT,
    payload: {
      promise: axios.delete(
        `${constants.BASE_URL}${baseUrl}/products/${productId}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const updateProductAmount = (productId: string, monto: number) => {
  return axios.put(
    `${constants.BASE_URL}${baseUrl}/products/${productId}/update-amount?monto=${monto}`,
    null,
    {
      withCredentials: true
    }
  );
};

export const descuentoTotal = (total: any) => {
  return axios.post(`${constants.BASE_URL}${baseUrl}/descuento-total`, null, {
    withCredentials: true,
    params: total
  });
};

export const productPaymentException = (productId: string) => {
  return axios.put(
    `${constants.BASE_URL}${baseUrl}/products/${productId}/payment-exception`,
    null,
    {
      withCredentials: true
    }
  );
};

export const updateProductDescription = (
  productId: string,
  description: String
) => {
  return axios.put(
    `${constants.BASE_URL}${baseUrl}/products/${productId}/update-description?descripcion=${description}`,
    null,
    {
      withCredentials: true
    }
  );
};

export const getProductAvailableDatesForProductPeriod = (productId: string) => {
  return axios.get(
    `${constants.BASE_URL}${baseUrl}/products/${productId}/available-dates-for-period-change`,
    {
      withCredentials: true
    }
  );
};

export const updateProductDatePeriod = (
  productId: string,
  fechaInicio: String
) => {
  return axios.put(
    `${constants.BASE_URL}${baseUrl}/products/${productId}/update-date-period?fechaInicio=${fechaInicio}`,
    null,
    {
      withCredentials: true
    }
  );
};

export const productDisableForceCat = (productId: string, motivo: string) => {
  return axios.put(
    `${constants.BASE_URL}${baseUrl}/products/${productId}/disable-force-cat`,
    motivo,
    {
      withCredentials: true
    }
  );
};

export const enableMaintenenceChargeForCatProcess = cotizacionProductoItemId => {
  const url = `${constants.BASE_URL}${baseUrl}/products/${cotizacionProductoItemId}/enable-for-cat-process`;
  const config = { withCredentials: true };
  return axios.put(url, null, config);
};

export const excludeMaintenenceChargeFromCatProcess = cotizacionProductoItemId => {
  const url = `${constants.BASE_URL}${baseUrl}/products/${cotizacionProductoItemId}/exclude-from-cat-process`;
  const config = { withCredentials: true };
  return axios.put(url, null, config);
};

export const duplicateDeletedProduct = cotizacionProductoItemId => {
  const url = `${constants.BASE_URL}${baseUrl}/products/${cotizacionProductoItemId}/duplicate-deleted`;
  const config = { withCredentials: true };
  return axios.put(url, null, config);
};

export const revertPaymentException = cotizacionProductoItemId => {
  const url = `${constants.BASE_URL}${baseUrl}/products/${cotizacionProductoItemId}/revert-payment-exception`;
  const config = { withCredentials: true };
  return axios.put(url, null, config);
};

export const getPricesToSportsProductsMassive = (params): IAction => {
  return {
    type: actionTypes.GET_PRICES_SPORTS_PRODUCTS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/prices-massive`, {
        withCredentials: true,
        params
      })
    }
  };
};

export const getPricesToSportsProductsByDate = (params): IAction => {
  return {
    type: actionTypes.GET_PRICES_SPORTS_PRODUCTS_BY_DATE,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/prices-by-date`, {
        withCredentials: true,
        params
      })
    }
  };
};

export const authorizePrices = (prices: object[], id: string): IAction => {
  return {
    type: actionTypes.AUTHORIZE_SPORTS_PRODUCTS_PRICES,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${baseUrl}/authorize-prices`,
        prices.map(price => ({ ...price, productoId: id })),
        {
          withCredentials: true
        }
      )
    }
  };
};

export const authorizePricesMassive = (prices: object[]): IAction => {
  return {
    type: actionTypes.AUTHORIZE_SPORTS_PRODUCTS_PRICES,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${baseUrl}/authorize-prices-massive`,
        prices,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getSportsProductsByFilters = (params: object): IAction => {
  return {
    type: actionTypes.GET_SPORTS_PRODUCTS_BY_FILTERS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/filtered`, {
        withCredentials: true,
        params
      })
    }
  };
};

export const getQuotation = (params: object): IAction => {
  return {
    type: actionTypes.GET_SPORT_PRODUCT_QUOTE,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/quotation`, {
        withCredentials: true,
        params
      })
    }
  };
};

export const cleanSportsProducts = (): IAction => {
  return {
    type: actionTypes.CLEAN_SPORTS_PRODUCTS,
    payload: null
  };
};

export const clearBasicDataSaved = (): IAction => {
  return {
    type: actionTypes.CLEAR_BASICDATASAVED_SPORTS_PRODUCTS,
    payload: null
  };
};

export const cleanPromotions = (): IAction => {
  return {
    type: actionTypes.CLEAN_PROMOTIONS,
    payload: null
  };
};

export const cleanQuotation = (): IAction => {
  return {
    type: actionTypes.CLEAN_QUOTATION,
    payload: null
  };
};

export const getSportProductById = (id: string): IAction => {
  return {
    type: actionTypes.GET_SPORT_PRODUCT_BY_ID,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/basic-data/${id}`, {
        withCredentials: true
      })
    }
  };
};

export const getQuoteById = (id: string): IAction => {
  return {
    type: actionTypes.GET_SPORT_PRODUCT_QUOTE_BY_ID,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/${id}/quote`, {
        withCredentials: true
      })
    }
  };
};

export const payQuote = (id: string, body: object): IAction => {
  return {
    type: actionTypes.SPORTS_PRODUCTS_PAY_QUOTE,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${baseUrl}/${id}/pay-quote`,
        body,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const deleteCashboxByIdRequest = (cashboxId: string) => {
  return axios.delete(`${constants.BASE_URL}${baseUrl}/cashbox/${cashboxId}`, {
    withCredentials: true
  });
};

export const deleteCashboxPaymentsByIdRequest = (
  cashboxId: string,
  deleteCashbox: boolean
) => {
  return axios.delete(
    `${constants.BASE_URL}${baseUrl}/cashbox/${cashboxId}/payments/${deleteCashbox}`,
    {
      withCredentials: true
    }
  );
};

export const payPending = (): IAction => {
  return {
    type: actionTypes.PAY_PENDING
  };
};

export const getSportsProductOperationComissions = (id: string): IAction => {
  return {
    type: actionTypes.GET_OPERATION_COMISSIONS_SPORTS_PRODUCTS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/${id}/operation-comissions`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getSportsProductOperationComissionsProm = (id: string) => {
  return axios.get(
    `${constants.BASE_URL}${baseUrl}/${id}/operation-comissions`,
    {
      withCredentials: true
    }
  );
};

export const saveSportsProductOperationComissions = (body: any): IAction => {
  return {
    type: actionTypes.SAVE_OPERATION_COMISSIONS_SPORTS_PRODUCTS,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${baseUrl}/operation-comissions`,
        body,
        {
          withCredentials: true
        }
      )
    }
  };
};
