import React, { useState, useEffect } from "react";
import {
  Container,
  Label,
  Input,
  UncontrolledTooltip,
  Row,
  Col
} from "reactstrap";
import ReactTable from "react-table";
import MexicanCurrency from "../../common/currency";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faStore,
  faDollarSign,
  faCheckCircle,
  faArrowAltCircleRight,
  faExchangeAlt,
  faCalendarAlt,
  faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";
import { SHOPPING_CART_PRODUCTS_TYPES } from "../../../../utils/constants";
import s from "./styles.scss";
import { PRODUCT_STATE } from "../interfaces_const";
import reduce from "lodash.reduce";
import { userHasPermission } from "../../../../utils/accessTree";
import permissionCodes from "../../../../utils/permissionCodes";
import BaseButtonWithIconAndTooltip from "../../../../toolkit/baseButtonWithIconAndTooltip";

interface Props {
  products: PRODUCT_STATE[];
  subtotal: number;
  total: number;
  minRows: number;
  loading: boolean;
  checked?: object;
  descuentos: number;
  iva: number;
  allChecked: boolean;
  showChangeDescription: boolean;
  showChangeDatePeriod: boolean;
  freezeObject: object;
  showClientNameColumn: boolean;
  membresiaSocioEstatus: string;
  deleteProduct: (index: number) => any;
  updateProductAmout: (index: number) => any;
  productPaymentException: (index: number) => any;
  updateProductDescription: (index: number, current: any) => any;
  updateProductDatePeriod: (index: number, current: any) => any;
  disableForceCat: (index: number) => any;
  setChecked?: (id: string, checked: boolean) => any;
  setFreezeObject: (data: object) => any;
}

const add = (a, b) => a + b;

const showCurrency = quantity => (
  <p className="p-0 text-center w-100 m-0">
    <MexicanCurrency quantity={quantity} />
  </p>
);

const getSubTotal = (products, checked) => {
  return products
    .map(product => (checked[product.itemId] ? product.subtotal : 0))
    .reduce(add, 0);
};

const getDescuentos = (products, checked) => {
  return products
    .map(product => (checked[product.itemId] ? product.montoDescuento : 0))
    .reduce(add, 0);
};

const getIva = (products, checked) => {
  return products
    .map(product => (checked[product.itemId] ? product.montoIva : 0))
    .reduce(add, 0);
};

const getTotal = (products, checked) => {
  return products
    .map(product => (checked[product.itemId] ? product.total : 0))
    .reduce(add, 0);
};

const getProductRowStatusClass = (product: any) => {
  if (!!product.inhabilitadoParaVenta) {
    return "bg-secondary text-white";
  }
  return "";
};

const getProductCatForzosoBg = (product: any) => {
  const cargoCat = ["MANTENIMIENTO", "MEMBRESIA"];
  const requiereCat =
    cargoCat.includes(product.tipoItem) &&
    !!product.requiereCatForzoso &&
    !product.tieneMedioPagoRegistrado;

  if (requiereCat) {
    return { background: "#b3cbcb" };
  }
  return {};
};

const TOKENIZACION_PRODUCT_ID = 10005069;
const BLACKPASS_PRODUCT_ID = 3671;
const partnerMembershipStatusAllowedToSellProducts = ["ACTIVA", "PENDIENTE"];
const partnerMembershipStatusAllowedToSellMaintenances = [
  "ACTIVA",
  "PASIVA",
  "INACTIVA",
  "PENDIENTE"
];
const partnerMembershipStatusAllowedToSellMemberships = [
  "PASIVA",
  "INACTIVA",
  "PENDIENTE"
];

const ProductsList = React.memo(
  ({
    products,
    minRows,
    loading,
    freezeObject,
    showClientNameColumn,
    deleteProduct,
    updateProductAmout,
    productPaymentException,
    updateProductDescription,
    updateProductDatePeriod,
    disableForceCat,
    setChecked,
    setFreezeObject,
    showChangeDescription,
    showChangeDatePeriod,
    membresiaSocioEstatus,
    membresiaSocioExiste
  }: Props) => {
    const [localChecked, setLocalChecked] = useState(freezeObject);

    useEffect(() => {
      if (Object.keys(localChecked).length > 0) {
        setFreezeObject(localChecked);
      }
    }, [localChecked]);

    useEffect(() => {
      if (Object.keys(localChecked).length > 0) {
        let valLocalChecked = localChecked;
        products.forEach(element => {
          if (!localChecked.hasOwnProperty(element.itemId)) {
            Object.defineProperty(valLocalChecked, element.itemId, {
              value: false
            });
          }
        });
        setLocalChecked(valLocalChecked);
      } else {
        setLocalChecked(
          reduce(
            products,
            (accum, { itemId, porPagar }) => ({
              ...accum,
              [itemId]: porPagar
            }),
            {}
          )
        );
      }
    }, [products]);
    const columns: any = [
      {
        Header: "Nombre",
        id: "updateProductDescription",
        accessor: ({ sportsProductName, tipoItem, itemId }) => {
          const index = products.map(p => p.itemId).indexOf(itemId);

          let hasChangeDescriptionPermission = false;
          let hasChangeDatePeriodPermission = false;

          switch (tipoItem) {
            case SHOPPING_CART_PRODUCTS_TYPES.MEMBRESIA:
              hasChangeDescriptionPermission = userHasPermission(
                permissionCodes.SHOPPING_CART_UPDATE_DESCRIPTION_PERMISSION
              );
              break;
            case SHOPPING_CART_PRODUCTS_TYPES.MANTENIMIENTO:
            case SHOPPING_CART_PRODUCTS_TYPES.ANUALIDAD:
              hasChangeDescriptionPermission = userHasPermission(
                permissionCodes.SHOPPING_CART_UPDATE_DESCRIPTION_PERMISSION
              );
              hasChangeDatePeriodPermission = userHasPermission(
                permissionCodes.SHOPPING_CART_UPDATE_DATE_PERIOD_PERMISSION
              );
              break;
            default:
              hasChangeDescriptionPermission = false;
              break;
          }

          return (
            <Container>
              <Row>
                <Col className="text-wrap">{sportsProductName}</Col>
                {!!showChangeDescription && hasChangeDescriptionPermission ? (
                  <Col xs="1">
                    <BaseButtonWithIconAndTooltip
                      id={`updateDescription-${itemId}`}
                      icon={faExchangeAlt}
                      tooltipTitle="Actualizar descripción"
                      onClick={() =>
                        updateProductDescription(index, sportsProductName)
                      }
                    />
                  </Col>
                ) : null}
                {!!showChangeDatePeriod && hasChangeDatePeriodPermission ? (
                  <Col xs="1">
                    <BaseButtonWithIconAndTooltip
                      id={`updateDatePeriod-${itemId}`}
                      icon={faCalendarAlt}
                      tooltipTitle="Actualizar vigencia"
                      onClick={() =>
                        updateProductDatePeriod(index, sportsProductName)
                      }
                    />
                  </Col>
                ) : null}
              </Row>
            </Container>
          );
        }
      },
      {
        Header: "Club",
        accessor: "club",
        width: 150,
        Cell: ({ value }) => <div className="text-wrap">{value}</div>
      },
      {
        Header: "Responsable",
        accessor: "responsible.label",
        width: 200,
        Cell: row => {
          return (
            <div className="text-wrap">{row.original.responsible.label}</div>
          );
        }
      },
      {
        Header: "Cantidad",
        accessor: "quantity",
        width: 100,
        Cell: ({ value }) => (
          <p className="p-0 text-center w-100 m-0">{value}</p>
        )
      },
      {
        Header: "Subtotal",
        accessor: "subtotal",
        width: 100,
        Cell: ({ value }) => showCurrency(value),
        Footer: (
          <span>
            SUBTOTAL: {showCurrency(getSubTotal(products, localChecked))}
          </span>
        )
      },
      {
        Header: "Descuento",
        accessor: "montoDescuento",
        width: 100,
        Footer: (
          <span>
            DESCUENTOS: {showCurrency(getDescuentos(products, localChecked))}
          </span>
        ),
        Cell: ({ value }) => showCurrency(value)
      },
      {
        Header: "Código Descuento",
        accessor: "discountCode",
        width: 100
      },
      {
        Header: "IVA",
        accessor: "montoIva",
        width: 100,
        Cell: ({ value }) => showCurrency(value),
        Footer: <span>IVA: {showCurrency(getIva(products, localChecked))}</span>
      },
      {
        Header: "Total",
        accessor: "total",
        width: 100,
        Cell: ({ value, original }) =>
          !original.tienePrecioVenta ? (
            <span>SIN PRECIO VENTA</span>
          ) : (
            showCurrency(value)
          ),
        Footer: (
          <span>TOTAL: {showCurrency(getTotal(products, localChecked))}</span>
        )
      }
    ];

    if (deleteProduct) {
      columns.push({
        width: 25,
        id: "deleteProduct",
        accessor: ({ tipoItem, eliminable, itemId }) => {
          const index = products.map(p => p.itemId).indexOf(itemId);

          let hasPermission = false;

          switch (tipoItem) {
            case SHOPPING_CART_PRODUCTS_TYPES.MEMBRESIA:
              hasPermission = userHasPermission(
                permissionCodes.SHOPPING_CART_DELETE_MEMBRESHIP_PERMISSION
              );
              break;
            case SHOPPING_CART_PRODUCTS_TYPES.MANTENIMIENTO:
              hasPermission = userHasPermission(
                permissionCodes.SHOPPING_CART_DELETE_MAINTENANCE_PERMISSION
              );
              break;
            case SHOPPING_CART_PRODUCTS_TYPES.ANUALIDAD:
              hasPermission = userHasPermission(
                permissionCodes.SHOPPING_CART_DELETE_ANNUITY_PERMISSION
              );
              break;
            default:
              hasPermission = userHasPermission(
                permissionCodes.SHOPPING_CART_DELETE_PRODUCT_AND_CHARGES_PERMISSION
              );
              break;
          }

          return hasPermission ? (
            <a
              id={`deleteItem-${itemId}`}
              onClick={() => deleteProduct(index)}
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faTimes} />
              <UncontrolledTooltip
                placement="top"
                target={`deleteItem-${itemId}`}
              >
                Eliminar
              </UncontrolledTooltip>
            </a>
          ) : null;
        }
      });
    }

    if (updateProductAmout) {
      columns.push({
        width: 25,
        id: "updateProductAmount",
        accessor: ({ tipoItem, itemId, esCuotaVacacional }) => {
          const index = products.map(p => p.itemId).indexOf(itemId);

          let hasPermission = false;

          switch (tipoItem) {
            case SHOPPING_CART_PRODUCTS_TYPES.MEMBRESIA:
              hasPermission = userHasPermission(
                permissionCodes.SHOPPING_CART_UPDATE_AMOUNT_MEMBRESHIP_PERMISSION
              );
              break;
            case SHOPPING_CART_PRODUCTS_TYPES.MANTENIMIENTO:
              hasPermission = userHasPermission(
                permissionCodes.SHOPPING_CART_UPDATE_AMOUNT_MAINTENANCE_PERMISSION
              );
              break;
            case SHOPPING_CART_PRODUCTS_TYPES.ANUALIDAD:
              hasPermission = userHasPermission(
                permissionCodes.SHOPPING_CART_UPDATE_AMOUNT_ANNUITY_PERMISSION
              );
              break;
            case SHOPPING_CART_PRODUCTS_TYPES.PRODUCTO:
              hasPermission = userHasPermission(
                permissionCodes.SHOPPING_CART_UPDATE_AMOUNT_PRODUCT_AND_CHARGES_PERMISSION
              );
              break;
            default:
              hasPermission = false;
              break;
          }

          return hasPermission && !esCuotaVacacional ? (
            <a
              id={`updateAmount-${itemId}`}
              onClick={() => updateProductAmout(index)}
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faDollarSign} />
              <UncontrolledTooltip
                placement="top"
                target={`updateAmount-${itemId}`}
              >
                Actualizar monto
              </UncontrolledTooltip>
            </a>
          ) : null;
        }
      });
    }

    if (productPaymentException) {
      columns.push({
        width: 25,
        id: "productPaymentException",
        accessor: ({ tipoItem, itemId, permiteExcepcionPago }) => {
          const index = products.map(p => p.itemId).indexOf(itemId);

          let hasPermission = false;

          switch (tipoItem) {
            case SHOPPING_CART_PRODUCTS_TYPES.MEMBRESIA:
              hasPermission = userHasPermission(
                permissionCodes.SHOPPING_CART_PAYMENT_EXCEPTION_MEMBRESHIP_PERMISSION
              );
              break;
            case SHOPPING_CART_PRODUCTS_TYPES.MANTENIMIENTO:
              hasPermission = userHasPermission(
                permissionCodes.SHOPPING_CART_PAYMENT_EXCEPTION_MAINTENANCE_PERMISSION
              );
              break;
            case SHOPPING_CART_PRODUCTS_TYPES.PRODUCTO:
              hasPermission =
                userHasPermission(
                  permissionCodes.SHOPPING_CART_PAYMENT_EXCEPTION_PRODUCT_PERMISSION
                ) && !!permiteExcepcionPago;
              break;
            default:
              hasPermission = false;
              break;
          }

          return hasPermission ? (
            <a
              id={`paymentException-${itemId}`}
              onClick={() => productPaymentException(index)}
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faCheckCircle} />
              <UncontrolledTooltip
                placement="top"
                target={`paymentException-${itemId}`}
              >
                Excepción de pago
              </UncontrolledTooltip>
            </a>
          ) : null;
        }
      });
    }

    if (disableForceCat) {
      columns.push({
        width: 25,
        id: "disableForceCatException",
        accessor: ({
          tipoItem,
          itemId,
          requiereCatForzoso,
          tieneMedioPagoRegistrado
        }) => {
          const index = products.map(p => p.itemId).indexOf(itemId);

          let hasPermission = false;

          switch (tipoItem) {
            case SHOPPING_CART_PRODUCTS_TYPES.MANTENIMIENTO:
            case SHOPPING_CART_PRODUCTS_TYPES.MEMBRESIA:
            case SHOPPING_CART_PRODUCTS_TYPES.ANUALIDAD:
              hasPermission = userHasPermission(
                permissionCodes.SHOPPING_CART_DISABLE_FORCE_CAT_MAINTENANCE_PERMISSION
              );
              break;
            default:
              hasPermission = false;
              break;
          }

          return !!requiereCatForzoso &&
            hasPermission &&
            !tieneMedioPagoRegistrado ? (
            <a
              id={`disableForceCatException-${itemId}`}
              onClick={() => disableForceCat(index)}
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon icon={faArrowAltCircleRight} />
              <UncontrolledTooltip
                placement="top"
                target={`disableForceCatException-${itemId}`}
              >
                Deshabilitar validación cat forzoso
              </UncontrolledTooltip>
            </a>
          ) : null;
        }
      });
    }

    if (setChecked) {
      columns.push({
        id: "itemId",
        width: 25,
        className: "p-0",
        Header: () => <FontAwesomeIcon icon={faStore} />,
        accessor: ({
          itemId,
          esClienteExtranjero,
          tieneFormatoMigracion,
          tieneMedioPagoRegistrado,
          eventos,
          tipoItem,
          sportsProductName,
          sportsProductId,
          requiereCatForzoso,
          inhabilitadoParaVenta,
          total
        }) => {
          let canSelectItem = true;
          let mensajeError = "La venta del cargo no está permitida";

          // Paso 1. Se verifica si el cargo requiere la tokenización
          const cargoCat = ["MANTENIMIENTO", "MEMBRESIA"];
          const requiereCat = cargoCat.includes(tipoItem) && requiereCatForzoso;

          if (
            requiereCat &&
            esClienteExtranjero &&
            !tieneFormatoMigracion &&
            !tieneMedioPagoRegistrado
          ) {
            canSelectItem = false;
            mensajeError =
              "El cliente no tiene un Medio de Pago ni el Formato de Migración";
          }

          if (
            requiereCat &&
            !esClienteExtranjero &&
            !tieneMedioPagoRegistrado
          ) {
            //canSelectItem = false;
            mensajeError = "El cliente no tiene un Medio de Pago registrado";
          }

          if (canSelectItem == true && !!inhabilitadoParaVenta) {
            canSelectItem = false;
            mensajeError = "El cargo ha sido inhabilitado para su venta";
          }

          if (canSelectItem == true && (total || 0) <= 0) {
            canSelectItem = false;
            mensajeError = "El cargo no tiene precio";
          }

          if (canSelectItem == true) {
            switch (tipoItem) {
              case SHOPPING_CART_PRODUCTS_TYPES.PRODUCTO:
                canSelectItem =
                  !membresiaSocioExiste ||
                  (partnerMembershipStatusAllowedToSellProducts.includes(
                    membresiaSocioEstatus
                  ) ||
                    (partnerMembershipStatusAllowedToSellMaintenances.includes(
                      membresiaSocioEstatus
                    ) &&
                      [TOKENIZACION_PRODUCT_ID, BLACKPASS_PRODUCT_ID].indexOf(
                        sportsProductId
                      ) >= 0));
                mensajeError = canSelectItem
                  ? mensajeError
                  : "Este producto solo se puede vender a membresías activas";
                break;
              case SHOPPING_CART_PRODUCTS_TYPES.MANTENIMIENTO:
              case SHOPPING_CART_PRODUCTS_TYPES.ANUALIDAD:
                canSelectItem = partnerMembershipStatusAllowedToSellMaintenances.includes(
                  membresiaSocioEstatus
                );
                mensajeError = canSelectItem
                  ? mensajeError
                  : "Cargo inválido para membresías eliminadas";
                break;
              case SHOPPING_CART_PRODUCTS_TYPES.MEMBRESIA:
                canSelectItem = partnerMembershipStatusAllowedToSellMemberships.includes(
                  membresiaSocioEstatus
                );
                mensajeError = canSelectItem
                  ? mensajeError
                  : "Cargo inválido para membresías que ya están activas";
                break;
              case SHOPPING_CART_PRODUCTS_TYPES.CARGO_CONCESIONARIO:
                canSelectItem = true;
                break;
              default:
                canSelectItem = false;
                break;
            }
          }

          if (canSelectItem) {
            return (
              <Label check>
                <Input
                  className={s.checkboxInput}
                  type="checkbox"
                  onChange={e => {
                    const productoChecked = e.target.checked;
                    setLocalChecked({
                      ...localChecked,
                      [itemId]: productoChecked
                    });

                    setChecked(itemId, productoChecked);
                  }}
                  style={{ marginLeft: "0.50rem" }}
                />
              </Label>
            );
          } else {
            return (
              <span
                id={"em-" + itemId}
                className="ml-1"
                style={{
                  cursor: "pointer",
                  display: "block",
                  paddingLeft: "4px",
                  paddingTop: "8px"
                }}
              >
                <FontAwesomeIcon icon={faExclamationCircle} color="#b38f00" />
                <UncontrolledTooltip placement="left" target={"em-" + itemId}>
                  {mensajeError}
                </UncontrolledTooltip>
              </span>
            );
          }
        }
      });
    }

    if (showClientNameColumn) {
      columns.unshift({
        Header: "Cliente",
        accessor: "nombreCliente",
        width: 100
      });
    }

    return (
      <Container className="row p-0 m-0 mt-2">
        <ReactTable
          manual
          data={products || []}
          columns={columns}
          className="w-100 -striped -highlight"
          loading={loading}
          sortable={false}
          showPagination={false}
          defaultPageSize={minRows}
          getTrProps={(_, product) => {
            return !!product
              ? {
                  className: getProductRowStatusClass(product.original),
                  style: getProductCatForzosoBg(product.original)
                }
              : {};
          }}
        />
      </Container>
    );
  }
);

export default ProductsList;
