import { faPlus } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import ReactTable from "react-table";
import { Container } from "reactstrap";
import BaseButtonWithIconAndTooltip from "../../../../../toolkit/baseButtonWithIconAndTooltip";

interface Props {
  referredCodesDetailList: object[];
  partnerMembershipId: String;
  createReferredCodes: () => void;
}

class ReferredCodesDetailTable extends React.Component<Props> {
  columns = [
    {
      Header: () => (
        <div className="w-100 text-center">
          <BaseButtonWithIconAndTooltip
            id={`createReferredCodes_xggdh`}
            icon={faPlus}
            tooltipTitle={"Crear códigos para programa de referidos"}
            onClick={() => {
              if (!!this.props.createReferredCodes) {
                this.props.createReferredCodes();
              }
            }}
          />
        </div>
      ),
      className: "text-center",
      Cell: row => {
        return null;
      }
    },
    {
      Header: "Código",
      className: "text-center",
      accessor: "codigo"
    },
    {
      Header: "Fecha creación",
      className: "text-center",
      accessor: "fechaCreacion"
    },
    {
      Header: "Estatus",
      className: "text-center",
      accessor: "estatus"
    }
  ];

  render() {
    return (
      <Container className="p-0">
        <p className="primaryTitle w-100">Códigos para Referidos</p>
        <ReactTable
          data={this.props.referredCodesDetailList || []}
          columns={this.columns}
          pageSize={20}
          showPagination={false}
          className="mt-3"
        />
      </Container>
    );
  }
}

export default ReferredCodesDetailTable;
