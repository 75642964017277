import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import BaseInput from "../../../../../toolkit/baseInput";
import { handleRequestError } from "../../../../../utils/helpers";
import {
  infoNotification,
  successNotification
} from "../../../../../utils/notifications";
import { applyDirectMaintenanceChange } from "../../../../actions/partners";
import { Partner } from "./../../interfaces";
import s from "./../../tabStyles.scss";

interface Props {
  partner: Partner;
  refreshPartner: () => void;
  previousMaintenancesList: any[];
}

interface State {
  selectedMaintenanceId: any;
}

class PreviousMaintenanceChange extends React.Component<Props, State> {
  state = {
    selectedMaintenanceId: null
  };

  handleChange = () => {
    const { partner } = this.props;
    const { idMembresiaSocio } = partner;
    const { selectedMaintenanceId } = this.state;

    if (!idMembresiaSocio || !selectedMaintenanceId) {
      return;
    }

    infoNotification("Actualizando mantenimiento...");

    applyDirectMaintenanceChange(idMembresiaSocio, selectedMaintenanceId)
      .then(() => {
        successNotification("Mantenimiento actualizado correctamente");
        if (this.props.refreshPartner) {
          this.props.refreshPartner();
        }
      })
      .catch(handleRequestError);
  };

  render() {
    return (
      <React.Fragment>
        <div>
          <div className="row mt-3 mb-3">
            <div className="col">
              <p className={`${s.primaryTitle} w-100 m-0`}>
                Cambio a mantenimiento previo
              </p>
            </div>
          </div>
          <Row className="row mt-3 mb-3">
            <Col sm={8}>
              <BaseInput
                label="Mantenimiento"
                name="previousMaintenanceSelector"
                id="previousMaintenanceSelector"
                type="select"
                value={this.state.selectedMaintenanceId}
                onChange={e => {
                  e.preventDefault();
                  const value = e.target.value;
                  this.setState({ selectedMaintenanceId: value || null });
                }}
                options={[
                  { value: "", label: "Seleccionar mantenimiento" }
                ].concat(
                  (this.props.previousMaintenancesList || []).map(option => ({
                    value: option.id,
                    label: `${option.nombre} (${option.fecha})`
                  }))
                )}
              />
            </Col>
            <Col className="pl-0 mt-4" sm={4}>
              <Button
                onClick={this.handleChange}
                disabled={!this.state.selectedMaintenanceId}
              >
                Aplicar cambio
              </Button>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(s)(PreviousMaintenanceChange));
