import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import ReactTable from "react-table";
import ControlledCellInput from "./controlledCellInput";
import {
  faTimes,
  faPercentage,
  faArrowDown,
  faDollarSign,
  faEquals
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Container,
  Row,
  Col,
  Label,
  Input,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import {
  PRICE_TYPES,
  PROMOTION_PRICE
} from "../../../../types-business/Promotions";
import moment from "moment";

// TODO: Esta funcionalidad se heredó de los convenios; faltan exclusiones

const HEADER_HEIGHT_EDITABLE = "12rem";
const HEADER_HEIGHT_NOT_EDITABLE = "2rem";
const MAX_MONTHS = 13;

interface Props {
  benefits: PROMOTION_PRICE;
  updateCellPrice: (monthIndex, memberIndex, ev) => any;
  checkLastMonth: (monthIndex, ev) => any;
  onChangePriceTypeMonth: (monthIndex: number, type: PRICE_TYPES) => any;
  deleteMonthPrices: () => any;
  addMonthPrices: () => any;
  editable: boolean;
}

interface State {
  activeCell: object;
}

class BenefitsTable extends React.Component<Props, State> {
  state = {
    activeCell: {}
  };

  getMonthHeader = month => {
    if (this.props.benefits.mesRelativo) return `MES ${month}`;
    else
      return moment()
        .month(month - 1)
        .format("MMM")
        .toUpperCase();
  };

  getTable = (columns, data) => (
    <div className="container w-100 p-0 m-0">
      <Row className="w-100 p-0 m-0">
        {this.props.benefits && (
          <div className="m-0 p-0 w-100 mt-1">
            <ReactTable
              data={data}
              columns={columns}
              defaultPageSize={this.props.benefits.numeroDeIntegrantes}
              className="-striped -highlight"
              showPagination={false}
              sortable={false}
              resizable={false}
            />
          </div>
        )}
      </Row>
    </div>
  );

  getModeTypeButton = (
    index,
    priceType: PRICE_TYPES,
    active,
    leftFont,
    rightFont,
    id
  ) => {
    const fontsStyles = { width: "0.625em", height: "0.9em" };
    const getTooltipText = () => {
      switch (priceType) {
        case PRICE_TYPES.DESCUENTOABS:
          return "Descuento Absoluto";
        case PRICE_TYPES.PRECIOFIJO:
          return "Precio Fijo";
        case PRICE_TYPES.PORCENTAJE:
          return "Porcentaje de Descuento";
        case PRICE_TYPES.NA:
          return "No Aplica Descuento";
      }
    };

    return (
      <div className="w-100 m-0 p-0">
        <Button
          className={`${s.optionButton}`}
          onClick={() => this.props.onChangePriceTypeMonth(index, priceType)}
          active={active}
          id={id}
        >
          {
            <a onClick={() => {}} className={`${s.deleteAnchor}`}>
              <FontAwesomeIcon style={fontsStyles} icon={leftFont} />{" "}
              {rightFont ? (
                <FontAwesomeIcon style={fontsStyles} icon={rightFont} />
              ) : null}
            </a>
          }
        </Button>
        <UncontrolledTooltip placement="right" target={id}>
          {getTooltipText()}
        </UncontrolledTooltip>
      </div>
    );
  };

  changeCellActive = (mode, x, y) => {
    const activeCell = { ...this.state.activeCell };

    if (!activeCell[x]) {
      activeCell[x] = {};
    }
    activeCell[x][y] = mode === "FOCUS";

    this.setState({ activeCell });
  };

  getHeaderHeight = () =>
    this.props.editable ? HEADER_HEIGHT_EDITABLE : HEADER_HEIGHT_NOT_EDITABLE;

  render() {
    const defaultProps = { className: "p-0", headerClassName: "p-0" };
    const width = 70;
    const columns = [
      {
        ...defaultProps,
        className: "p-1",
        Header: () => (
          <div style={{ height: this.getHeaderHeight() }}>
            <div className="d-flex w-100 h-100 flex-column justify-content-center">
              <div className="m-0">
                <Button
                  onClick={this.props.deleteMonthPrices}
                  style={{
                    backgroundColor: "red",
                    width: "3rem",
                    height: "1.5rem",
                    fontSize: "0.7rem",
                    fontWeight: "bold"
                  }}
                  className="p-0 px-1"
                >
                  {" - COL."}
                </Button>
              </div>
              <div className="m-0">
                <Button
                  onClick={this.props.addMonthPrices}
                  style={{
                    backgroundColor: "green",
                    width: "3rem",
                    height: "1.5rem",
                    fontSize: "0.7rem",
                    fontWeight: "bold"
                  }}
                  className="p-0 px-1 mt-2"
                  disabled={
                    this.props.benefits.meses.length === MAX_MONTHS - 1 ||
                    (this.props.benefits.meses.length > 0 &&
                      this.props.benefits.meses[
                        this.props.benefits.meses.length - 1
                      ].esUltimoMesRecurrente)
                  }
                >
                  {" + COL."}
                </Button>
              </div>
              <div className="m-0">
                {this.props.benefits.meses.length ? (
                  <Label style={{ fontSize: "0.7rem" }} className="mt-2" check>
                    <Input
                      type="checkbox"
                      checked={
                        this.props.benefits.meses[
                          this.props.benefits.meses.length - 1
                        ].esUltimoMesRecurrente
                      }
                      onChange={e =>
                        this.props.checkLastMonth(
                          this.props.benefits.meses.length - 1,
                          e
                        )
                      }
                      style={{
                        marginLeft: "1.75rem",
                        marginTop: "1rem"
                      }}
                    />{" "}
                    RECURRENTE
                  </Label>
                ) : null}
              </div>
            </div>
          </div>
        ),
        id: -1,
        accessor: row => row[0].integrante,
        Cell: row => (
          <p className="text-center m-0 p-0">{`INT. ${row.value}`}</p>
        ),
        width: width,
        headerStyle: {
          height: this.getHeaderHeight()
        }
      }
    ];
    const data = [];
    if (this.props.benefits) {
      this.props.benefits.meses.forEach(({ mes, tipoBeneficioMes }, index) => {
        columns.push({
          ...defaultProps,
          Header: () => (
            <Container
              className="p-0 m-0 w-100"
              fluid
              id="membershipTypeSelect"
            >
              <Row className="p-0 m-0 justify-content-center">
                <p className="mb-1">{this.getMonthHeader(mes)}</p>
              </Row>
              <div className="container w-100 p-0 m-0">
                {this.props.editable && (
                  <div className="container w-100 p-0 m-0">
                    <Row className="justify-content-center">
                      {this.getModeTypeButton(
                        index,
                        PRICE_TYPES.PRECIOFIJO,
                        tipoBeneficioMes === PRICE_TYPES.PRECIOFIJO,
                        faDollarSign,
                        faEquals,
                        `${PRICE_TYPES.PRECIOFIJO}${index}`
                      )}
                    </Row>
                    <Row className="justify-content-center">
                      {this.getModeTypeButton(
                        index,
                        PRICE_TYPES.PORCENTAJE,
                        tipoBeneficioMes === PRICE_TYPES.PORCENTAJE,
                        faPercentage,
                        faArrowDown,
                        `${PRICE_TYPES.PORCENTAJE}${index}`
                      )}
                    </Row>
                    <Row className="justify-content-center">
                      {this.getModeTypeButton(
                        index,
                        PRICE_TYPES.DESCUENTOABS,
                        tipoBeneficioMes === PRICE_TYPES.DESCUENTOABS,
                        faDollarSign,
                        faArrowDown,
                        `${PRICE_TYPES.DESCUENTOABS}${index}`
                      )}
                    </Row>
                    <Row className="justify-content-center">
                      {this.getModeTypeButton(
                        index,
                        PRICE_TYPES.NA,
                        tipoBeneficioMes === PRICE_TYPES.NA,
                        faTimes,
                        null,
                        `${PRICE_TYPES.NA}${index}`
                      )}
                    </Row>
                  </div>
                )}
              </div>
            </Container>
          ),
          accessor: mesValues =>
            mesValues[index + 1] && mesValues[index + 1].valor,
          id: index + 1,
          width: width,
          headerStyle: {
            height: this.props.editable
              ? HEADER_HEIGHT_EDITABLE
              : HEADER_HEIGHT_NOT_EDITABLE
          },
          Cell: row => {
            return (
              <div className="w-100 p-0 m-0">
                {this.props.editable ? (
                  <Container className="m-0 p-0 w-100">
                    <Row className="m-0 p-0 w-100">
                      <Col xs={9} className="p-0">
                        <ControlledCellInput
                          name={`membershipValue${mes}${row.index}`}
                          type="number"
                          id="inputBenefitCell"
                          placeholder="Precio"
                          value={row.value}
                          disabled={tipoBeneficioMes === PRICE_TYPES.NA}
                          saveChange={ev => {
                            return this.props.updateCellPrice(
                              row.column.id - 1,
                              row.index,
                              ev
                            );
                          }}
                          style={
                            tipoBeneficioMes === PRICE_TYPES.PORCENTAJE ||
                            tipoBeneficioMes === PRICE_TYPES.DESCUENTOABS
                              ? { color: "red" }
                              : {}
                          }
                          className={`p-1 ${s.inputValue}`}
                        />
                      </Col>
                      <Col
                        className={`p-1 ${s.typeInputContainer}`}
                        xs={3}
                        style={
                          this.state.activeCell[mes] &&
                          this.state.activeCell[mes][row.index]
                            ? { backgroundColor: "white" }
                            : null
                        }
                      >
                        <span className="mt-1">
                          {tipoBeneficioMes === PRICE_TYPES.PORCENTAJE
                            ? "%"
                            : "$"}
                        </span>
                      </Col>
                    </Row>
                  </Container>
                ) : (
                  row.value
                )}
              </div>
            );
          }
        });
      });

      const { numeroDeIntegrantes } = this.props.benefits;
      for (
        let indexBeneficio = 0;
        indexBeneficio < numeroDeIntegrantes;
        indexBeneficio++
      ) {
        const row = [{ integrante: indexBeneficio + 1 }];
        this.props.benefits.meses.forEach(({ beneficios }) => {
          row.push(beneficios[indexBeneficio]);
        });
        data.push(row);
      }
    }

    return (
      <Container className="w-100 p-0 m-0">
        {this.props.benefits && this.getTable(columns, data)}
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(BenefitsTable));
