import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Container, Row, Col, UncontrolledTooltip, Button } from "reactstrap";
import ReactTable from "react-table";
import columns from "./columns";
import ClubSelectList from "../../containers/clubSelectList";
import {
  errorNotification,
  infoNotification
} from "../../../utils/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "../common/DatePicker";
import moment from "moment";

interface Props {
  loadingMediosPagoEliminados: boolean;
  downloadingMediosPagoEliminados: boolean;
  mediosPagoEliminados: any[];
  getMediosPagoEliminados: (params: any) => void;
  getMediosPagoEliminadosReporte: (params: any) => void;
  userContext: any;
}

interface State {
  club: any;
  fechaInicio: any;
  fechaFin: any;
}

class MediosPagoEliminadosPage extends React.Component<Props, State> {
  state = {
    club: null,
    fechaInicio: null,
    fechaFin: null
  };

  generateFilters = () => {
    if (!this.state.club || !this.state.club.value) {
      errorNotification("Por favor, seleccione el club");
      return null;
    } else if (!this.state.fechaInicio) {
      errorNotification("Por favor, seleccione la fecha de inicio de búsqueda");
      return null;
    } else if (!this.state.fechaFin) {
      errorNotification("Por favor, seleccione la fecha final de búsqueda");
      return null;
    }

    return {
      idClub: this.state.club.value,
      fechaInicio: this.state.fechaInicio,
      fechaFin: this.state.fechaFin
    };
  };

  submit = evt => {
    evt.preventDefault();
    const filters = this.generateFilters();
    filters && infoNotification("Buscando medios de pago eliminados");
    filters && this.props.getMediosPagoEliminados(filters);
  };

  download = () => {
    const filters = this.generateFilters();
    filters &&
      infoNotification("Descargando reporte de medios de pago eliminados");
    filters && this.props.getMediosPagoEliminadosReporte(filters);
  };

  render() {
    return (
      <Container className="mt-3 p-0">
        {/* Filtros */}
        <form onSubmit={this.submit} autoComplete="off">
          <Row className="mt-4">
            {/* Selector de club */}
            <Col md={4}>
              <ClubSelectList
                clubSelected={this.state.club}
                onSelectClub={club => this.setState({ club })}
                label="* Club"
                userClub={this.props.userContext.clubSessionId}
              />
            </Col>

            {/* Fecha de inicio */}
            <div className="col-3 mb-3">
              <div
                style={{
                  display: "inline-block",
                  width: "calc(100% - 24px)"
                }}
              >
                <DatePicker
                  id="fechaInicio"
                  name="fechaInicio"
                  label="* Fecha inicio"
                  selected={this.state.fechaInicio}
                  onChange={e =>
                    this.setState({
                      fechaInicio: moment(e).format("YYYY-MM-DD")
                    })
                  }
                />
              </div>
              <UncontrolledTooltip placement="top" target="fechaInicioBorrar">
                Borrar fecha
              </UncontrolledTooltip>
              <div
                id="fechaInicioBorrar"
                onClick={e => {
                  e.preventDefault();
                  this.setState({ fechaInicio: null });
                }}
                style={{
                  cursor: "pointer",
                  textAlign: "right",
                  display: "inline-block",
                  width: "24px"
                }}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </div>
            </div>

            {/* Fecha de fin */}
            <div className="col-3 mb-3">
              <div
                style={{
                  display: "inline-block",
                  width: "calc(100% - 24px)"
                }}
              >
                <DatePicker
                  id="fechaFin"
                  name="fechaFin"
                  label="* Fecha fin"
                  selected={this.state.fechaFin}
                  onChange={e =>
                    this.setState({ fechaFin: moment(e).format("YYYY-MM-DD") })
                  }
                />
              </div>
              <UncontrolledTooltip placement="top" target="fechaFinBorrar">
                Borrar fecha
              </UncontrolledTooltip>
              <div
                id="fechaFinBorrar"
                onClick={e => {
                  e.preventDefault();
                  this.setState({ fechaFin: null });
                }}
                style={{
                  cursor: "pointer",
                  textAlign: "right",
                  display: "inline-block",
                  width: "24px"
                }}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </div>
            </div>

            {/* Botón de búsqueda */}
            <Col md={2} className="p-0">
              <Button
                className={`${s.primaryButton} mt-4`}
                type="submit"
                size="sm"
                block
                disabled={
                  this.props.loadingMediosPagoEliminados ||
                  this.props.downloadingMediosPagoEliminados
                }
              >
                {this.props.loadingMediosPagoEliminados
                  ? "Buscando..."
                  : "Buscar"}
              </Button>
            </Col>
          </Row>
        </form>

        {/* Botón de descarga */}
        {!!(this.props.mediosPagoEliminados || []).length && (
          <Row className={"mt-5"}>
            <Col sm="6">
              Resultados de la búsqueda:{" "}
              <b
                style={{ fontWeight: "bolder", display: "inline-block" }}
                className="ml-3"
              >
                {(this.props.mediosPagoEliminados || []).length}
              </b>
            </Col>
            <Col sm="6" className="text-right">
              <span>Exportar a CSV: </span>
              <Button
                id="btnReportExportCsv"
                color="link"
                onClick={() => this.download()}
              >
                <FontAwesomeIcon icon={faFileExport} color="black" />
              </Button>
            </Col>
          </Row>
        )}
        <ReactTable
          className="mt-5 -striped -highlight"
          data={this.props.mediosPagoEliminados || []}
          columns={columns}
          sortable={false}
          resizable={false}
          pageSize={
            this.props.mediosPagoEliminados &&
            this.props.mediosPagoEliminados.length > 5
              ? this.props.mediosPagoEliminados.length
              : 5
          }
          showPagination={false}
          loading={this.props.loadingMediosPagoEliminados}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(MediosPagoEliminadosPage));
