import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import {
  Container,
  Input,
  Button,
  Row,
  Col,
  UncontrolledTooltip,
  FormGroup,
  Label
} from "reactstrap";
import ModalFrame from "../../../toolkit/modalFrame";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import BaseInput from "../../../toolkit/baseInput";
import SelectClubsModal from "../../../Home/components/addGroupsToSportsProducts/selectClubsModal";
import { isNumber } from "util";
import find from "lodash.find";
import { getClubsRequest } from "../../actions/catalogs";
import { getAllQuizzesRequest } from "../../actions/products";
import { handleRequestError } from "../../../utils/helpers";

interface Props {}

interface State {
  groups: Array<{
    id: number;
    dispositivoId: number;
    encuestaId: string;
    clubes: Array<{ clubId: number; nombre: string }>;
    meses: Array<{ mesId: number; nombre: string }>;
  }>;
  isOpenModal: boolean;
  index: number;
  clubsCatalog: any[];
  quizzesCatalog: any[];
  monthsCatalog: any[];
  filters: { selectedYear: number };

  isOpenModalMonth: boolean;
}

class AdminQuizzAndDevicesPerYear extends React.Component<Props, State> {
  state = {
    groups: [],
    clubsCatalog: [],
    quizzesCatalog: [],
    monthsCatalog: [],
    filters: { selectedYear: 2020 },
    isOpenModal: false,
    index: -1,
    isOpenModalMonth: false
  };

  componentDidMount() {
    this.getClubsCatalog();
    this.getQuizzesCatalog();
    this.getMonthsCatalog();
  }

  getClubsCatalog = () => {
    getClubsRequest()
      .then(({ data }) => {
        this.setState({ clubsCatalog: data });
      })
      .catch(handleRequestError);
  };

  getQuizzesCatalog = () => {
    getAllQuizzesRequest()
      .then(({ data }) => {
        this.setState({ quizzesCatalog: data });
      })
      .catch(handleRequestError);
  };

  getMonthsCatalog = () => {
    this.setState({
      monthsCatalog: [
        { mesId: 1, nombre: "Enero", selected: false },
        { mesId: 2, nombre: "Feberero", selected: false },
        { mesId: 3, nombre: "Marzo", selected: false },
        { mesId: 4, nombre: "Abril", selected: false },
        { mesId: 5, nombre: "Mayo", selected: false },
        { mesId: 6, nombre: "Junio", selected: false },
        { mesId: 7, nombre: "Julio", selected: false },
        { mesId: 8, nombre: "Agosto", selected: false },
        { mesId: 9, nombre: "Septiembre", selected: false },
        { mesId: 10, nombre: "Octubre", selected: false },
        { mesId: 11, nombre: "Noviembre", selected: false },
        { mesId: 12, nombre: "Diciembre", selected: false }
      ]
    });
  };

  getYearOptions = () => {
    const years = [];
    for (let i = 2010; i <= 2030; i++) {
      years.push({ value: i, label: `${i}` });
    }
    return years;
  };

  getDevicesOptions = () => {
    return [
      { value: null, label: "Seleccione..." },
      { value: 1, label: "Ipad-Quiosco" },
      { value: 2, label: "Ipad-Baño-Mujeres" },
      { value: 3, label: "Ipad-Baño-Hombres" },
      { value: 4, label: "Ipad-Movil1" },
      { value: 5, label: "Ipad-Movil2" },
      { value: 6, label: "Ipad-Salón" },
      { value: 7, label: "Ipad-Salón" },
      { value: 8, label: "Ipad-Salón" }
    ];
  };

  getQuizzOptions = () => {
    const tmp = (this.state.quizzesCatalog || []).map(x => {
      return { value: x.id, label: x.nombre };
    });
    return [{ value: null, label: "Seleccione..." }, ...tmp];
  };

  setValue = (index, id, value) => {
    const groups = [...this.state.groups];
    groups[index][id] = value;
    this.setState({ ...this.state, groups });
  };

  renderInputSelect = (options, nameKey = null, idKey = null) => ({
    index,
    column: { id }
  }) => {
    return (
      <Container className="my-1">
        <UncontrolledTooltip placement="left" target={`selector${index}${id}`}>
          {nameKey
            ? find(
                options,
                option => this.state.groups[index][id] == option[idKey]
              )[nameKey]
            : this.state.groups[index][id]}
        </UncontrolledTooltip>
        <Row className="mt-1">
          <Col className="px-1">
            <Input
              name="memberTypeSelector"
              type="select"
              id={`selector${index}${id}`}
              className={`${s.renderEditableInput}`}
              value={this.state.groups[index][id]}
              onChange={e => {
                const value = e.target.value;
                this.setValue(index, id, value);
              }}
              style={{ height: "2rem" }}
            >
              {nameKey
                ? options.map(opt => (
                    <option key={opt[idKey]} value={opt[idKey]}>
                      {opt[nameKey]}
                    </option>
                  ))
                : options.map(opt => (
                    <option key={opt} value={opt}>
                      {opt}
                    </option>
                  ))}
            </Input>
          </Col>
        </Row>
      </Container>
    );
  };

  renderClubes = ({ index, column: { id } }) => {
    return (
      <p className="text-justify m-2">
        {this.state.groups[index][id].map(c => c.nombre).join(", ")}
      </p>
    );
  };

  renderMeses = ({ index, column: { id } }) => {
    return (
      <p className="text-justify m-2">
        {(this.state.groups[index][id] || []).map(c => c.nombre).join(", ")}
      </p>
    );
  };

  addEmptyGroup = () => {
    const groups = [...this.state.groups];
    groups.push({
      id: null,
      dispositivoId: null,
      encuestaId: null,
      clubes: [],
      meses: []
    });

    this.setState({ groups });
  };

  toggleModal = index => {
    this.setState({
      isOpenModal: !this.state.isOpenModal,
      index: isNumber(index) ? index : this.state.index
    });
  };

  toggleModalMonth = (index = -1) => {
    this.setState({
      isOpenModalMonth: !this.state.isOpenModalMonth,
      index: isNumber(index) ? index : this.state.index
    });
  };

  addClubsToSportsClub = clubes => {
    if (this.state.index >= 0) {
      const groups = [...this.state.groups];
      groups[this.state.index].clubes = [...clubes];
      this.setState({ groups });
      this.toggleModal(this.state.index);
    }
  };

  addMonths = months => {
    if (this.state.index >= 0) {
      const groups = [...this.state.groups];
      groups[this.state.index].meses = [...months];

      const { monthsCatalog } = this.state;
      monthsCatalog.forEach(x => {
        x.selected = false;
      });

      this.setState({ groups, monthsCatalog });
      this.toggleModalMonth(this.state.index);
    }
  };

  render() {
    return (
      <Container className="p-0 mt-2">
        <SelectClubsModal
          clubsInUse={[]}
          isOpen={this.state.isOpenModal}
          toggle={this.toggleModal}
          addClubsToSportsClub={this.addClubsToSportsClub}
        />

        {/* MESES */}
        <ModalFrame
          title={`Seleccionar Meses`}
          isOpen={this.state.isOpenModalMonth}
          toggle={() => this.toggleModalMonth()}
          size="lg"
        >
          <Container style={{ width: "90%" }} className="center-block p-0 mb-3">
            <Row className="m-0 mb-3">
              <Col xs="2" className="text-center">
                <Button
                  id={"selectAll"}
                  onClick={() => {
                    const { monthsCatalog } = this.state;
                    monthsCatalog.forEach(x => {
                      x.selected = true;
                    });
                    this.setState({ monthsCatalog });
                  }}
                  color="link"
                  className="text-success"
                >
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
                <Button
                  id={"unSelectAll"}
                  onClick={() => {
                    const { monthsCatalog } = this.state;
                    monthsCatalog.forEach(x => {
                      x.selected = false;
                    });
                    this.setState({ monthsCatalog });
                  }}
                  color="link"
                  className="text-danger"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </Col>
            </Row>
            <Row className="m-0 mb-5">
              {this.state.monthsCatalog.map((x, index) => (
                <Col xs={2} key={x.nombre + index}>
                  <FormGroup check className="mt-2">
                    <Label check>
                      <Input
                        type="checkbox"
                        value={x.mesId}
                        checked={x.selected}
                        onChange={e => {
                          const { monthsCatalog } = this.state;
                          const checked = e.target.checked;
                          const mesId = e.target.value;
                          monthsCatalog.find(
                            x => x.mesId == mesId
                          ).selected = checked;
                          this.setState({
                            monthsCatalog
                          });
                        }}
                      />
                      {x.nombre}
                    </Label>
                  </FormGroup>
                </Col>
              ))}
              <Col />
              <Col className="p-0" xs="2">
                <Button
                  className={`mt-3`}
                  onClick={() => {
                    this.addMonths(
                      this.state.monthsCatalog.filter(x => !!x.selected)
                    );
                  }}
                  size="sm"
                  block
                >
                  Añadir
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalFrame>

        <div>
          <Row>
            <Col>
              <BaseInput
                type="select"
                label="Año*"
                name="yearSelector"
                id="yearSelector"
                placeholder="Seleccione el año"
                value={this.state.filters.selectedYear}
                options={this.getYearOptions()}
                onChange={e => {
                  const value = e.target.value;
                  const { filters } = this.state;
                  filters.selectedYear = value;
                  this.setState({
                    filters,
                    groups: []
                  });
                }}
              />
            </Col>
          </Row>
        </div>

        <Row className="p-0 mt-3 mb-3">
          <ReactTable
            data={this.state.groups}
            columns={[
              {
                Header: "DISPOSITIVO",
                accessor: "dispositivoId",
                Cell: this.renderInputSelect(
                  this.getDevicesOptions(),
                  "label",
                  "value"
                ),
                className: "p-0",
                width: 200
              },
              {
                Header: "ENCUESTA",
                accessor: "encuestaId",
                Cell: this.renderInputSelect(
                  this.getQuizzOptions(),
                  "label",
                  "value"
                ),
                className: "p-0",
                width: 200
              },
              {
                Header: "MESES",
                accessor: "meses",
                Cell: this.renderMeses,
                className: "p-0",
                width: 200,
                style: { whiteSpace: "normal" }
              },
              {
                Header: "CLUBES",
                accessor: "clubes",
                Cell: this.renderClubes,
                className: "p-0",
                style: { whiteSpace: "normal" }
              },
              {
                Header: () => (
                  <div className="w-100 text-center">
                    <Button
                      color="link"
                      onClick={() => this.addEmptyGroup()}
                      className={`${s.innerButton} ${s.primaryLink}`}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </div>
                ),
                Cell: ({ index }) => (
                  <div>
                    <Button
                      className={`${s.primaryButton}`}
                      size="sm"
                      style={{ lineHeight: "1.25" }}
                      onClick={() => this.toggleModalMonth(index)}
                    >
                      MESES
                    </Button>
                    &nbsp;
                    <Button
                      className={`${s.primaryButton}`}
                      size="sm"
                      style={{ lineHeight: "1.25" }}
                      onClick={() => this.toggleModal(index)}
                    >
                      CLUBES
                    </Button>
                  </div>
                ),
                width: 120
              }
            ]}
            pageSize={(this.state.groups || []).length + 2}
            sortable={false}
            showPagination={false}
            className="-highlight -striped w-100"
            getTbodyProps={() => ({ style: { backgroundColor: "#EAECEE" } })}
          />
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(AdminQuizzAndDevicesPerYear));
