import FormBuilderConfig from "../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import ITextBuilder from "../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";
import IButtonBuilder from "../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import IUserSelectorBuilder from "../../../toolkit/baseForms2Commons/iUserSelectorBuilder/IUserSelectorBuilder";

export type Inputs = "noEmpleado" | "empleado";
export type Buttons = "aceptar";

export const formConfig: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "noEmpleado",
      label: "No. de Empleado",
      bts: {
        sCol: 3
      },
      component: ITextBuilder,
      props: {
        maxlength: 10
      }
    },
    {
      name: "empleado",
      label: "Empleado",
      bts: {
        sCol: 6
      },
      component: IUserSelectorBuilder
    }
  ],
  buttons: [
    {
      name: "aceptar",
      label: "Buscar",
      isSubmit: true,
      bts: {
        sCol: 3
      },
      component: IButtonBuilder
    }
  ],
  validations: {
    noEmpleado: {
      requiredIfNotEmpleado: {
        validator: (value, formValue) => !formValue.empleado && !value,
        message: () => "Seleccione un usuario o ingrese el número"
      }
    },
    empleado: {
      requiredIfNotNoEmpleado: {
        validator: (value, formValue) => !formValue.noEmpleado && !value,
        message: () => "Ingrese el número de empleado o selecciónelo"
      }
    }
  },
  rows: [["noEmpleado", "empleado", "aceptar"]]
};
