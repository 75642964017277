import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import ModalFrame from "../../../../toolkit/modalFrame";
import { Button, Container, Row, Col, Label } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import BaseInput from "../../../../toolkit/baseInput";

import s from "./styles.scss";
import { FILTERS_REASSIGN, REASIGN_MEMBERSHIP_STATE } from "../interfaces";

interface Props extends FILTERS_REASSIGN {
  isModalOpen: boolean;
  sellers: object[];
  toggle: () => void;
  sendReasignMembership: (filters: object) => void;
}

interface State extends REASIGN_MEMBERSHIP_STATE {}

class ReAsignMembershipModal extends React.Component<Props, State> {
  state = {
    sellersHash: null,
    email: null,
    fullName: null,
    status: null,
    vendedorId: null,
    membresiaSocioId: null
  };

  onChangeSeller = selected => {
    let selectedSeller;

    if (selected.length > 0 && this.state.sellersHash[selected[0]["id"]]) {
      selectedSeller = this.state.sellersHash[selected[0]["id"]];
      let state = this.state;
      state.email = selectedSeller["email"];
      state.fullName = `${selectedSeller["name"]}  ${selectedSeller["firstSurname"]}  ${selectedSeller["secondSurname"]}`;
      state.status = selectedSeller["status"];
      state.membresiaSocioId = this.props.idMembresia;
      state.vendedorId = selected[0]["id"];
      this.setState(state);
    }
  };

  componentDidMount() {
    const sellersHash = {};
    this.props.sellers.forEach(seller => {
      sellersHash[seller["userId"]] = seller;
    });
    let state = this.state;
    state.sellersHash = sellersHash;
    this.setState(state);
  }

  preToggle = async () => {
    let state = this.state;
    state.email = null;
    state.fullName = null;
    state.status = null;
    await this.setState(state);
    this.props.toggle();
  };
  preSendReasignMembership = () => {
    const { vendedorId, membresiaSocioId } = this.state;
    this.props.sendReasignMembership({ vendedorId, membresiaSocioId });
    this.preToggle();
  };

  render() {
    return (
      <ModalFrame
        title={`Reasignar venta de membresía`}
        isOpen={this.props.isModalOpen}
        toggle={() => this.preToggle()}
        size="lg"
      >
        <Container style={{ width: "90%" }} className="center-block p-0 mb-3">
          <p
            className={"mt-3 mb-2"}
            style={{ fontWeight: "bold", fontSize: "1.2em" }}
          >
            Datos de la membresía
          </p>

          <Row className="m-0">
            <Col sm={3}>
              <BaseInput
                label="# Membresía"
                name="reassignModalNoMembership"
                id="reassignModalNoMembership"
                type="text"
                value={this.props.idMembresia}
                disabled={true}
              />
            </Col>

            <Col sm={6}>
              <BaseInput
                label="Nombre"
                name="reassignModalName"
                id="reassignModalName"
                type="text"
                value={this.props.membresia["nombre"]}
                disabled={true}
              />
            </Col>
            <Col sm={3}>
              <BaseInput
                label="Club"
                name="reassignModalClub"
                id="reassignModalClub"
                type="text"
                value={this.props["nombreClub"]}
                disabled={true}
              />
            </Col>
          </Row>

          <p
            className={"mt-3 mb-2"}
            style={{ fontWeight: "bold", fontSize: "1.2em" }}
          >
            Reasignar a:
          </p>

          <Row>
            <Col sm="4">
              <Label className={s.label}>Nombre de vendedor</Label>
              <Typeahead
                id={"vendedor"}
                promptText={"Vendedores"}
                bsSize={"sm"}
                onChange={selected => this.onChangeSeller(selected)}
                emptyLabel={"Sin coincidencias"}
                disabled={this.props.sellers ? false : true}
                maxResults={20}
                options={
                  this.props.sellers
                    ? this.props.sellers.map(item => {
                        var rObj = {};
                        rObj["id"] = item["userId"];
                        rObj["label"] = `${item["name"] || ""} ${item[
                          "firstSurname"
                        ] || ""} ${item["secondSurname"] || ""} `;
                        return rObj;
                      })
                    : []
                }
              />
            </Col>
            <Col sm={5}>
              <p> Nombre Completo:</p>{" "}
              <span className={"my-1"}> {this.state.fullName}</span>
            </Col>
            <Col sm={3}>
              <p> Estatus:</p>
              <span className={"my-1"}> {this.state.status}</span>
            </Col>
          </Row>
          <hr />
          <Row className="m-0">
            <Col sm={{ size: 3, offset: 8 }}>
              <Button
                className={" mt-3"}
                onClick={() => this.preSendReasignMembership()}
                size="lg"
                block
              >
                Reasignar
              </Button>
            </Col>
          </Row>
        </Container>
      </ModalFrame>
    );
  }
}

export default withRouter(withStyles(s)(ReAsignMembershipModal));
