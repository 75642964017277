import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import { ReduxConnect } from "../../../toolkit/decorators/Artifact";
import {
  NotaCreditoBloqueoDispatcher,
  notaCreditoBloqueoDispatcher
} from "./NotaCreditoBloqueo.dispatcher";
import {
  NotaCreditoBloqueoStore,
  notaCreditoBloqueoStore
} from "./NotaCreditoBloqueo.store";
import NotaCreditoBloqueoComponent from "../../components/notaCreditoBloqueo";

interface Props extends NotaCreditoBloqueoStore, NotaCreditoBloqueoDispatcher {}

interface State {}

@ReduxConnect(notaCreditoBloqueoStore, notaCreditoBloqueoDispatcher)
export default class NotaCreditoBloqueoContainer extends React.Component<
  Props,
  State
> {
  render() {
    return (
      <main>
        <Layout>
          <Helmet title="Empresas de convenio" />
          <NotaCreditoBloqueoComponent {...this.props} />
        </Layout>
      </main>
    );
  }
}
