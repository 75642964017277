import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Goals from "../../components/goals";
import { getClubs } from "../../actions/catalogs";
import { saveGoals, getGoals } from "../../actions/goals";

interface Props {
  getClubs: (params: object) => any;
  saveGoals: (goals: object[]) => any;
  getGoals: (params: object) => any;
  clubs: object[];
}

class GoalsPage extends React.Component<Props, void> {
  componentDidMount() {
    this.getActiveClubs();
  }

  getActiveClubs = () => {
    this.props.getClubs({ estatus: "ACTIVO" });
  };

  render() {
    return (
      <main>
        <Helmet title="Anualidades" />
        <Layout>
          {this.props.clubs && (
            <Goals
              getActiveClubs={this.props.getActiveClubs}
              saveGoals={this.props.saveGoals}
              getGoals={this.props.getGoals}
              clubs={this.props.clubs}
            />
          )}
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getClubs: params => {
    return dispatch(getClubs(params));
  },
  saveGoals: (data: object[]) => {
    return dispatch(saveGoals(data));
  },
  getGoals: (params: object) => {
    return dispatch(getGoals(params));
  }
});

const mapStateToProps = state => {
  const {
    catalogs: { clubs }
  } = state;
  return {
    clubs
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoalsPage);
