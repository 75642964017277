import React from "react";
import { withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./styles.scss";
import PriceDataSportsProductsForm from "../priceDataSportsProductsForm";

interface Props {
  history: any;
  match: any;
  addPricesToSportsProducts: (id: string, prices: object) => any;
  getPricesToSportsProducts: (params: string) => any;
  successfulSave: boolean;
  estatus: Catalog[];
  tiposPrecio: tipoPrecio[];
  prices: Prices;
  loading: boolean;
  savingPrices: boolean;
  savingPricesError: boolean;
  onClickSave: (e: object) => any;
  authorizePrices: (prices: object[], id: string) => any;
  onSuccessfulSave: () => any;
  getSportsProductConfig: (id: string) => any;
  generalConfigById: object;
  generalProductById: object;
  accountingAccounts: object[];
  productAccounts: object[];
}

interface PriceData {
  grupoId: string;
  nombre: string;
  inicioVigencia: string;
  finVigencia: string;
  precio: string;
  estatus: string;
}

interface Prices {
  content: PriceData[];
  totalPages: string;
}

interface tipoPrecio {
  esquemaPagoId: string;
  nombre: string;
}

interface Catalog {
  id: string;
  nombre: string;
}

interface State {}

class priceDataSportsProducts extends React.Component<Props, State> {
  state = {};

  render() {
    return (
      <PriceDataSportsProductsForm
        addPricesToSportsProducts={this.props.addPricesToSportsProducts}
        getPricesToSportsProducts={this.props.getPricesToSportsProducts}
        id={this.props.match.params.id}
        successfulSave={this.props.successfulSave}
        estatus={this.props.estatus}
        prices={this.props.prices}
        tiposPrecio={this.props.tiposPrecio}
        loading={this.props.loading}
        savingPrices={this.props.savingPrices}
        savingPricesError={this.props.savingPricesError}
        onClickSave={this.props.onClickSave}
        authorizePrices={this.props.authorizePrices}
        onSuccessfulSave={this.props.onSuccessfulSave}
        getSportsProductConfig={this.props.getSportsProductConfig}
        generalConfigById={this.props.generalConfigById}
        generalProductById={this.props.generalProductById}
        accountingAccounts={this.props.accountingAccounts}
        productAccounts={this.props.productAccounts}
      />
    );
  }
}

export default withRouter(withStyles(s)(priceDataSportsProducts));
