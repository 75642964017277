import Reform from "@franleplant/reform";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cloneDeep from "lodash.clonedeep";
import moment from "moment";
import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "reactstrap";
import BaseInput from "../../../../toolkit/baseInput";
import BaseNumericInput from "../../../../toolkit/baseNumericInput";
import ModalFrame from "../../../../toolkit/modalFrame";
import DatePicker from "../../common/DatePicker";
import { AdmCharge } from "../interfaces";
import s from "./styles.scss";

const formatTime = (dateTime?) =>
  moment(dateTime)
    .utc()
    .format("YYYY-MM-DD");

interface Props {
  isOpen: boolean;
  history: any;
  edited: any;

  clubsCatalog: any[];
  accountingAccounts: any[];
  satProducts: any[];
  satUnits: any[];
  addCharge: (data: object) => void;
  cancel: () => void;
}

interface State {
  fields: AdmCharge;
  errors: object;
}

class AdmAddProductModal extends React.Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);

  state = {
    fields: {
      descripcion: null,
      cuentaContableIngresosId: null,
      cuentaContableClienteId: null,
      cuentaContableGastosId: null,
      clubesIds: [],
      fechaEmision: formatTime(),
      numeroMesesVigencia: null,
      ivaPorcentaje: null,
      importe: null,
      claveProductoSat: null,
      claveUnidadSat: null,
      cantidad: null,
      key: null,
      cuentasContablesDesc: null
    },
    errors: {}
  };

  validationRules = {
    descripcion: { required: true },
    cuentaContableIngresosId: { required: true },
    cuentaContableClienteId: { required: true },
    cuentaContableGastosId: { required: true },
    fechaEmision: { required: true },
    numeroMesesVigencia: { required: true },
    ivaPorcentaje: { required: true },
    importe: { required: true },
    claveProductoSat: { required: true },
    claveUnidadSat: { required: true },
    cantidad: { required: true }
  };

  validationMessages = {
    required: _ => `Obligatorio`,
    default: _ => `Invalido`
  };

  onSubmit = e => {
    e.preventDefault();
    const obj = cloneDeep(this.state.fields);
    if (!obj.key) {
      obj.key = Math.floor(Math.random() * 100000 + 1);
    }

    let accountingAccountDescription = "";

    if (!!obj.cuentaContableIngresosId) {
      const tmpAcAc = this.props.accountingAccounts.find(
        x => x.cuentaContableId == obj.cuentaContableIngresosId
      );
      if (!!tmpAcAc) {
        accountingAccountDescription += `Cuenta contable ingresos: (${tmpAcAc.numeroCuenta}) ${tmpAcAc.nombre}\n`;
      }
    }

    if (!!obj.cuentaContableClienteId) {
      const tmpAcAc = this.props.accountingAccounts.find(
        x => x.cuentaContableId == obj.cuentaContableClienteId
      );
      if (!!tmpAcAc) {
        accountingAccountDescription += `Cuenta contable clientes: (${tmpAcAc.numeroCuenta}) ${tmpAcAc.nombre}\n`;
      }
    }

    if (!!obj.cuentaContableGastosId) {
      const tmpAcAc = this.props.accountingAccounts.find(
        x => x.cuentaContableId == obj.cuentaContableGastosId
      );
      if (!!tmpAcAc) {
        accountingAccountDescription += `Cuenta contable gastos: (${tmpAcAc.numeroCuenta}) ${tmpAcAc.nombre}\n`;
      }
    }

    obj.cuentasContablesDesc = accountingAccountDescription;

    this.props.addCharge(obj);
    this.clearFieldsValues();
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      if (!!this.props.edited) {
        const edited = cloneDeep(this.props.edited);
        this.setState({
          fields: {
            descripcion: edited.descripcion || null,
            cuentaContableIngresosId: edited.cuentaContableIngresosId || null,
            cuentaContableClienteId: edited.cuentaContableClienteId || null,
            cuentaContableGastosId: edited.cuentaContableGastosId || null,
            clubesIds: edited.clubesIds || [],
            fechaEmision: edited.fechaEmision || formatTime(),
            numeroMesesVigencia: edited.numeroMesesVigencia || null,
            ivaPorcentaje: edited.ivaPorcentaje || null,
            importe: edited.importe || null,
            claveProductoSat: edited.claveProductoSat || null,
            claveUnidadSat: edited.claveUnidadSat || null,
            cantidad: edited.cantidad || null,
            key: edited.key || null,
            cuentasContablesDesc: null
          }
        });
      }
    }
  }

  onChangeFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };
        return { ...state, error: {}, fields };
      });
      this.re.validateField(fieldName, value);
    };
  };

  onChangeNumberFactory = fieldName => {
    return event => {
      const value = event.target.value.replace(/[^0-9\.]/gi, "");
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };
        return { ...state, error: {}, fields };
      });
      this.re.validateField(fieldName, value);
    };
  };

  onChangeDateFactory = fieldName => {
    return dateTime => {
      const value = formatTime(dateTime);
      this.setState({
        ...this.state,
        fields: { ...this.state.fields, [fieldName]: value }
      });
      this.re.validateField(fieldName, value);
    };
  };

  clearFieldsValues = () => {
    this.setState({
      fields: {
        descripcion: null,
        cuentaContableIngresosId: null,
        cuentaContableClienteId: null,
        cuentaContableGastosId: null,
        clubesIds: [],
        fechaEmision: formatTime(),
        numeroMesesVigencia: null,
        ivaPorcentaje: null,
        importe: null,
        claveProductoSat: null,
        claveUnidadSat: null,
        cantidad: null,
        key: null,
        cuentasContablesDesc: null
      }
    });
  };

  render() {
    return (
      <ModalFrame
        title={`Agregar cargo`}
        size={"lg"}
        isOpen={this.props.isOpen}
      >
        <Container>
          <Form>
            <Row className="mt-4">
              <Col>
                Descripción *:
                <textarea
                  className="form-control"
                  rows={3}
                  id="admChargeDescription"
                  name="admChargeDescription"
                  value={this.state.fields.descripcion}
                  onChange={this.onChangeFactory("descripcion")}
                  errors={this.re.mapFieldErrors("descripcion")}
                />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col>
                <BaseInput
                  type="select"
                  label={"Cuenta contable ingresos *"}
                  name="admChargeCuentaContableIngreso"
                  id="admChargeCuentaContableIngreso"
                  value={this.state.fields.cuentaContableIngresosId}
                  options={[{ value: "", label: "Seleccionar" }].concat(
                    this.props.accountingAccounts.map(option => ({
                      value: option.cuentaContableId,
                      label: option.numeroCuenta + " " + option.nombre
                    }))
                  )}
                  onChange={this.onChangeFactory("cuentaContableIngresosId")}
                  errors={this.re.mapFieldErrors("cuentaContableIngresosId")}
                />
              </Col>
              <Col>
                <BaseInput
                  type="select"
                  label={"Cuenta contable clientes *"}
                  name="admChargeCuentaContableClientes"
                  id="admChargeCuentaContableClientes"
                  value={this.state.fields.cuentaContableClienteId}
                  options={[{ value: "", label: "Seleccionar" }].concat(
                    this.props.accountingAccounts.map(option => ({
                      value: option.cuentaContableId,
                      label: option.numeroCuenta + " " + option.nombre
                    }))
                  )}
                  onChange={this.onChangeFactory("cuentaContableClienteId")}
                  errors={this.re.mapFieldErrors("cuentaContableClienteId")}
                />
              </Col>
              <Col>
                <BaseInput
                  type="select"
                  label={"Cuenta contable gastos "}
                  name="admChargeCuentaContableGastos"
                  id="admChargeCuentaContableGastos"
                  value={this.state.fields.cuentaContableGastosId}
                  options={[{ value: "", label: "Seleccionar" }].concat(
                    this.props.accountingAccounts.map(option => ({
                      value: option.cuentaContableId,
                      label: option.numeroCuenta + " " + option.nombre
                    }))
                  )}
                  onChange={this.onChangeFactory("cuentaContableGastosId")}
                  errors={this.re.mapFieldErrors("cuentaContableGastosId")}
                />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col>
                <BaseInput
                  label={"Clubes"}
                  type="select"
                  id="admChargeClubes"
                  name="admChargeClubes"
                  multipleSelection={true}
                  value={this.state.fields.clubesIds || []}
                  onChange={e => {
                    var options = e.target.options;
                    var value = [];
                    for (var i = 0, l = options.length; i < l; i++) {
                      if (options[i].selected) {
                        value.push(options[i].value);
                      }
                    }

                    const { fields } = this.state;
                    fields.clubesIds = [...value];
                    this.setState({ fields });
                  }}
                  options={(this.props.clubsCatalog || []).map(x => {
                    return { value: x.clubId, label: x.nombre };
                  })}
                />
              </Col>
              <Col>
                <div className="m-3">
                  <span>Para seleccionar múltiples clubes:</span>
                  <br />
                  <span>CTRL + CLICK </span>
                  <br />
                  <span>SHIFT + CLICK </span>
                </div>
              </Col>
            </Row>

            <Row className="mt-4">
              <Col xs={4}>
                <DatePicker
                  label="Fecha aplicación contable *"
                  name="admChargeFechaContable"
                  id="admChargeFechaContable"
                  selected={this.state.fields.fechaEmision}
                  onChange={this.onChangeDateFactory("fechaEmision")}
                  errors={this.re.mapFieldErrors("fechaEmision")}
                />
              </Col>

              <Col xs={4}>
                <BaseNumericInput
                  label={"# Meses de vigencia *"}
                  name="admChargeMesesVigencia"
                  id="admChargeMesesVigencia"
                  type="text"
                  value={this.state.fields.numeroMesesVigencia}
                  placeholder={"# meses"}
                  onChange={this.onChangeNumberFactory("numeroMesesVigencia")}
                  errors={this.re.mapFieldErrors("numeroMesesVigencia")}
                />
              </Col>

              <Col xs={4}>
                <BaseNumericInput
                  label={"% IVA *"}
                  name="admChargeIva"
                  id="admChargeIva"
                  type="text"
                  value={this.state.fields.ivaPorcentaje || "16"}
                  placeholder={"% IVA"}
                  disabled={true}
                  onChange={this.onChangeNumberFactory("ivaPorcentaje")}
                  errors={this.re.mapFieldErrors("ivaPorcentaje")}
                />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col xs={4}>
                <BaseNumericInput
                  label={"Importe (Iva incluido) *"}
                  name="admChargeImporte"
                  id="admChargeImporte"
                  type="text"
                  value={this.state.fields.importe}
                  decimals={true}
                  placeholder={"Importe"}
                  onChange={this.onChangeNumberFactory("importe")}
                  errors={this.re.mapFieldErrors("importe")}
                />
              </Col>
              <Col xs={4}>
                <BaseInput
                  type="select"
                  label={"Producto/Servicio *"}
                  name="admChargeClaveProducto"
                  id="admChargeClaveProducto"
                  value={this.state.fields.claveProductoSat}
                  options={[
                    { value: null, label: "Seleccionar Producto SAT" }
                  ].concat(
                    this.props.satProducts.map(option => ({
                      value: option.id,
                      label: `${option.claveProducto} - ${option.nombre}`
                    }))
                  )}
                  onChange={this.onChangeFactory("claveProductoSat")}
                  errors={this.re.mapFieldErrors("claveProductoSat")}
                />
              </Col>
              <Col xs={4}>
                <BaseInput
                  type="select"
                  label={"Unidad *"}
                  name="admChargeClaveUnidad"
                  id="admChargeClaveUnidad"
                  value={this.state.fields.claveUnidadSat}
                  options={[
                    { value: null, label: "Seleccionar Unidad SAT" }
                  ].concat(
                    this.props.satUnits.map(option => ({
                      value: option.id,
                      label: `${option.claveUnidad} - ${option.nombre}`
                    }))
                  )}
                  onChange={this.onChangeFactory("claveUnidadSat")}
                  errors={this.re.mapFieldErrors("claveUnidadSat")}
                />
              </Col>
              <Col xs={4}>
                <BaseNumericInput
                  label={"Cantidad *"}
                  name="admChargeCantidad"
                  id="admChargeCantidad"
                  type="text"
                  value={this.state.fields.cantidad}
                  placeholder={"Importe"}
                  onChange={this.onChangeNumberFactory("cantidad")}
                  errors={this.re.mapFieldErrors("cantidad")}
                />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col>
                <Button
                  className={`${s.buttonMarginTop} ${s.primaryButton}`}
                  onClick={e => this.onSubmit(e)}
                  size="lg"
                  block
                >
                  Guardar
                </Button>
              </Col>

              <Col>
                <Button
                  className={`${s.buttonMarginTop}`}
                  onClick={e => this.props.cancel()}
                  size="lg"
                  block
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </ModalFrame>
    );
  }
}

export default withRouter(withStyles(s)(AdmAddProductModal));
