import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Row, Col, Button } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import s from "./styles.scss";
import ReactTable from "react-table";
import MexicanCurrency from "../common/currency";
import isNil from "lodash.isnil";
import {
  errorNotification,
  ERROR_ITEM_VALUE_EXCESS,
  ERROR_MONTH_YEAR
} from "../../../utils/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { MONTHS } from "../../../utils/constants";
// import locale from 'antd/es/date-picker/locale/es_ES';

interface Props {
  clubs: { clubId: number; nombre: string }[];
  onChangeDevengoDistribution: (
    devengoDistribution: any[],
    DevengoDistributionValidated: boolean
  ) => any;
  tipoItemData: { idCampo; label }[];
  billingData: {
    billingCatalogs: any[];
    dataResponse: object;
    gettingCatalogs: boolean;
    sendingData: boolean;
    successSend: boolean;
  };
  disabled: boolean;
  devengoDistribution: object[];
  taxDistribution: object[];
  accountingAccounts: {
    cuentaContableId: number;
    nombre: string;
    numeroCuenta: string;
  }[];
  sendDistributionLines: () => any;
}

interface State {
  fields: {
    item: number;
    nombre: string;
    monto: number;
    cuentaContableId: number;
    cuentaContableIdDev: number;
    id: number;
    idDad: number;
    new: boolean;
    clubId: number;
    fechaContable: string;
    facturaId: string;
    renglon: number;
    periodo: string;
  };
  mes: string;
  anio: string;
  validData: boolean;
  numberId: number;
  filterDevengoDistribution: object[];
  anioArray: object[];
  block: boolean;
}

const formatTime = (dateTime?) =>
  moment(dateTime)
    .utc()
    .format("YYYY-MM-DD");

class AccrualDistribution extends React.Component<Props, State> {
  state = {
    fields: {
      item: null,
      nombre: null,
      monto: null,
      cuentaContableId: null,
      cuentaContableIdDev: null,
      id: null,
      idDad: null,
      new: true,
      clubId: null,
      fechaContable: formatTime(),
      facturaId: "001",
      renglon: null,
      periodo: "null"
    },
    mes: null,
    anio: null,
    validData: false,
    numberId: 0,
    filterDevengoDistribution: [],
    anioArray: [],
    block: false
  };

  onChangeField = async (e, fieldName) => {
    let state = { ...this.state };
    state.fields[fieldName] = e.target.value;
    let indexVal = -1;
    if (fieldName == "item") {
      this.props.taxDistribution.forEach((element, index) => {
        if (element.id == e.target.value) {
          indexVal = index;
        }
      });
      if (indexVal >= 0) {
        state.fields.id = state.numberId;
        state.fields.idDad = this.props.taxDistribution[indexVal].id;
        state.fields.nombre = this.props.taxDistribution[
          indexVal
        ].nombre.toUpperCase();
        state.fields.cuentaContableIdDev = this.props.taxDistribution[
          indexVal
        ].cuentaContable;
        state.fields.renglon = this.props.taxDistribution[indexVal].renglon;
      }
    }
    if (fieldName == "mes") {
      state.mes = e.target.value;
    }
    if (fieldName == "anio") {
      state.anio = e.target.value;
    }
    await this.setState(state);
    if (this.validateFields()) {
      this.setState({
        ...this.state,
        validData: true,
        numberId: this.state.numberId + 1
      });
    } else {
      this.setState({ ...this.state, validData: false });
    }
  };

  validateFields = () => {
    const { fields } = this.state;
    for (const key in fields) {
      if (fields.hasOwnProperty(key)) {
        const element = fields[key];
        if (isNil(element)) return false;
      }
    }
    return true;
  };

  getClearFields = () => ({
    item: null,
    nombre: null,
    monto: null,
    cuentaContableId: null,
    cuentaContableIdDev: null,
    id: null,
    idDad: null,
    new: true,
    clubId: null,
    fechaContable: formatTime(),
    facturaId: "001",
    renglon: null,
    periodo: "null"
  });

  onDeleteField = id => {
    const state = { ...this.state };
    const devengoDistribution = [...this.props.devengoDistribution];
    let deleteValue = -1;
    devengoDistribution.forEach((element, index) => {
      if (element.id == id) {
        deleteValue = index;
      }
    });
    if (deleteValue >= 0) {
      devengoDistribution.splice(deleteValue, 1);
      this.props.onChangeDevengoDistribution(devengoDistribution, true);
      state.fields = this.getClearFields();
      state.validData = false;
      if (devengoDistribution.length == 0) {
        state.block = false;
      }
      this.setState(state);
    }
  };

  addItem = async () => {
    if (isNil(this.state.mes) || isNil(this.state.anio)) {
      errorNotification(ERROR_MONTH_YEAR);
    } else {
      const state = { ...this.state };
      const taxDistribution = [...this.props.taxDistribution];
      const devengoDistribution = [...this.props.devengoDistribution];
      let cantMoney = 0;
      if (devengoDistribution && devengoDistribution.length > 0) {
        devengoDistribution.forEach(element => {
          if (element.idDad == state.fields.idDad) {
            cantMoney = cantMoney + parseFloat(element.monto);
          }
        });
      }
      cantMoney = cantMoney + parseFloat(state.fields.monto);
      let numberIndex = -1;
      taxDistribution.forEach((element, index) => {
        if (element.id == state.fields.idDad) numberIndex = index;
      });
      if (
        numberIndex >= 0 &&
        cantMoney > parseFloat(taxDistribution[numberIndex].monto)
      ) {
        errorNotification(ERROR_ITEM_VALUE_EXCESS);
      } else {
        state.fields.new = true;
        state.fields.periodo = state.mes + "-" + state.anio.substring(2, 4);
        devengoDistribution.push(state.fields);
        this.props.onChangeDevengoDistribution(devengoDistribution, true);
        state.fields = this.getClearFields();
        state.validData = false;
        state.block = true;
        this.setState(state);
      }
    }
  };

  componentDidMount() {
    let year = parseInt(formatTime().substring(0, 4));
    let objYear = [
      { value: year, label: year },
      { value: year - 1, label: year - 1 }
    ];
    if (this.props.taxDistribution && this.props.taxDistribution.length > 0) {
      let result = this.props.taxDistribution.filter(
        element => element.activa == true
      );
      this.setState({ filterDevengoDistribution: result, anioArray: objYear });
    } else {
      this.setState({ anioArray: objYear });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.taxDistribution &&
      this.props.taxDistribution.length > 0 &&
      prevProps.taxDistribution != this.props.taxDistribution
    ) {
      let result = this.props.taxDistribution.filter(
        element => element.activa == true
      );
      this.setState({ filterDevengoDistribution: result });
    }
  }

  render() {
    let CatProductoServicio = [];

    for (let catalog of this.props.billingData.billingCatalogs) {
      if (catalog["clave"] == "CatProductoServicio")
        CatProductoServicio = catalog["data"];
    }

    const itemsTablecolumns = [
      {
        Header: "periodo",
        accessor: "periodo"
      },
      {
        Header: "item",
        accessor: "nombre"
      },
      {
        Header: "club",
        accessor: "clubId",
        Cell: row => {
          return (
            <a>
              {" "}
              {row.original.clubId
                ? this.props.clubs.map(element => {
                    if (element.clubId == row.original.clubId) {
                      return element.nombre.toUpperCase();
                    }
                  })
                : ""}{" "}
            </a>
          );
        }
      },
      {
        Header: "monto",
        accessor: "monto",
        Cell: row => (
          <p className="text-right m-0 p-0">
            <MexicanCurrency quantity={row.value} />
          </p>
        )
      },
      {
        Header: "cuenta contable",
        accessor: "cuentaContableId",
        Cell: row => {
          return (
            <a>
              {" "}
              {row.original.cuentaContableId
                ? this.props.accountingAccounts.map(element => {
                    if (
                      element.cuentaContableId == row.original.cuentaContableId
                    ) {
                      return (
                        element.numeroCuenta +
                        " - " +
                        element.nombre.toUpperCase()
                      );
                    }
                  })
                : ""}{" "}
            </a>
          );
        }
      },
      {
        Header: "acciones",
        accessor: "acciones",
        Cell: row => {
          return row.original && row.original.new ? (
            <a
              id={`accrual${row.original.id}`}
              onClick={() => {
                this.onDeleteField(row.original.id);
              }}
            >
              <FontAwesomeIcon
                style={{ cursor: "pointer" }}
                icon={faMinusCircle}
              />
            </a>
          ) : (
            <a id={`accrual${row.original.id}`}>
              <FontAwesomeIcon icon={faMinusCircle} />
            </a>
          );
        }
      }
    ];

    let dataTable = this.props.devengoDistribution;
    return (
      <span>
        <Row>
          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled || this.state.block}
              label={"*mes:"}
              name={`mes`}
              type="select"
              id={`mes`}
              placeholder={"mes"}
              options={[{ value: -1, label: "Selecciona el mes" }].concat(
                MONTHS.map(element => {
                  return {
                    value: element.value,
                    label: element.label
                  };
                })
              )}
              value={isNil(this.state.mes) ? -1 : this.state.mes}
              onChange={e => this.onChangeField(e, "mes")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled || this.state.block}
              label={"*a\u00F1o:"}
              name={`anio`}
              type="select"
              id={`anio`}
              placeholder={"anio"}
              options={[{ value: -1, label: "Seleccionar el a\u00F1o" }].concat(
                this.state.anioArray.map(element => {
                  return {
                    value: element.value,
                    label: element.label
                  };
                })
              )}
              value={isNil(this.state.anio) ? -1 : this.state.anio}
              onChange={e => this.onChangeField(e, "anio")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*item:"}
              name={`item`}
              type="select"
              id={`item`}
              placeholder={"item"}
              options={[{ value: -1, label: "Seleccionar el item" }].concat(
                this.state.filterDevengoDistribution.map(element => {
                  return {
                    value: element.id,
                    label: element.nombre
                  };
                })
              )}
              value={
                isNil(this.state.fields.item) ? -1 : this.state.fields.item
              }
              onChange={e => this.onChangeField(e, "item")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*club"}
              name={`clubId`}
              type="select"
              id={`clubId`}
              placeholder={"clubId"}
              options={[{ value: -1, label: "Seleccionar el club" }].concat(
                this.props.clubs.map(element => {
                  return {
                    value: element.clubId,
                    label: element.nombre.toUpperCase()
                  };
                })
              )}
              value={
                isNil(this.state.fields.clubId) ? -1 : this.state.fields.clubId
              }
              onChange={e => this.onChangeField(e, "clubId")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*monto"}
              name={`Monto`}
              type="text"
              id={`monto`}
              placeholder={"0"}
              value={
                isNil(this.state.fields.monto) ? "" : this.state.fields.monto
              }
              onChange={e => this.onChangeField(e, "monto")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*cuenta contable ingreso"}
              name={`cuentaContableId`}
              type="select"
              id={`cuentaContableId`}
              placeholder={"cuentaContableId"}
              options={[
                { value: -1, label: "Seleccionar cuenta contable" }
              ].concat(
                this.props.accountingAccounts.map(element => {
                  return {
                    value: element.cuentaContableId,
                    label:
                      element.numeroCuenta +
                      " - " +
                      element.nombre.toUpperCase()
                  };
                })
              )}
              value={
                isNil(this.state.fields.cuentaContableId)
                  ? -1
                  : this.state.fields.cuentaContableId
              }
              onChange={e => this.onChangeField(e, "cuentaContableId")}
            />
          </Col>

          <Col sm="3">
            <Button
              disabled={!this.state.validData}
              block
              onClick={() => this.addItem()}
              className="mt-4 mb-1"
              style={{ height: "2rem" }}
            >
              +ITEM
            </Button>
          </Col>

          <Col sm="3">
            <Button
              disabled={!(this.props.devengoDistribution.length > 0)}
              block
              onClick={() => this.props.sendDistributionLines()}
              className="mt-4 mb-1"
              style={{ height: "2rem" }}
            >
              Enviar devengos
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <ReactTable
              pages={1}
              data={dataTable}
              columns={itemsTablecolumns}
              showPagination={false}
              sortable={true}
              multiSort={true}
              resizable={true}
              pageSize={this.props.devengoDistribution.length + 3}
            />
          </Col>
        </Row>
      </span>
    );
  }
}

export default withStyles(s)(AccrualDistribution);
