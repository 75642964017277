import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Container } from "reactstrap";
import { Banco } from "../../../types/Banks";
import PagedTable from "../common/PagedTable";
import { QueryResponse } from "../../../types/QueryResponse";

interface Props {
  banks: QueryResponse<Banco>;
  defaultRows: string;
  fetchData: () => void;
  searching: boolean;
  onClickNewBank: (bank) => any;
}

class Banks extends React.Component<Props> {
  handleEditBank = bank => {
    this.props.onClickNewBank(bank);
  };

  render() {
    const columns = [
      { name: "Nombre", id: "nombre" },
      {
        headerActions: [{ name: "add", f: this.props.onClickNewBank }]
      }
    ];

    return (
      <Container className="mt-3 p-0">
        <PagedTable
          data={this.props.banks}
          columns={columns}
          actions={[]}
          fetchData={this.props.fetchData}
          searching={this.props.searching}
        />
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(Banks));
