import React from "react";
import { Helmet } from "react-helmet";
import CorporativePersons from "../../components/corporativePersons";
import Layout from "../../components/layout";

interface Props {
  match: any;
}

interface State {}

class Corporatives extends React.Component<Props, State> {
  state = {};

  componentDidMount() {}

  render() {
    return (
      <main>
        <Helmet title="Clientes corporativos" />
        <Layout>
          <CorporativePersons />
        </Layout>
      </main>
    );
  }
}

export default Corporatives;
