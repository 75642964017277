import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import AccrualList from "../../components/accrualGlobal/accrualList";
import * as billingActions from "../../actions/billing";
import IAction from "../../../types/IAction";

interface Props {
  getAccrual: () => IAction;
  updateAccrual: (data: object, idEsquema: string) => IAction;
  listAccrual: object[];
}

interface State {}

class Accrual extends React.Component<Props, State> {
  state = {};

  render() {
    return (
      <main>
        <Helmet title="Esquemas devengamiento" />
        <Layout>
          <AccrualList
            listAccrual={this.props.listAccrual}
            getAccrual={this.props.getAccrual}
            updateAccrual={this.props.updateAccrual}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getAccrual: () => {
    dispatch(billingActions.getAccrual());
  },
  updateAccrual: (data: object, idEsquema: string) => {
    dispatch(billingActions.updateAccrual(data, idEsquema));
  }
});

const mapStateToProps = ({ billing }) => {
  return {
    listAccrual: billing.listAccrual
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Accrual);
