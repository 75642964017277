import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import { Container, Button, Row, Col } from "reactstrap";
import Reform from "@franleplant/reform";
import BaseInput from "../../../toolkit/baseInput";
import s from "./styles.scss";
import CorreosElectronicos from "./CorreosElectronicos";
import Direcciones from "./Direcciones";
import Telefonos from "./Telefonos";
import Contactos from "./Contactos";
import upperCase from "lodash.uppercase";
import PeopleBasicData from "../../../People/containers/PeopleBasicData";
import PersonSelector from "../../../People/containers/PersonSelector";
import isNil from "lodash.isnil";
import { isArray } from "util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faPen,
  faTimesCircle,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import isEmpty from "lodash.isempty";
import moment from "moment";
import {
  BFF_URL,
  IMAGES_ENDPOINT,
  KID_MAXIMUM_AGE
} from "../../../utils/constants";

import {
  successNotification,
  infoNotification,
  UPDATE_PERSON_PHONE_PENDING,
  UPDATE_PERSON_PHONE_FULFILLED,
  errorNotification,
  UPDATE_PERSON_PHONE_REJECTED,
  UPDATE_PERSON_CONTACT_PENDING,
  UPDATE_PERSON_CONTACT_FULFILLED,
  UPDATE_PERSON_CONTACT_REJECTED,
  DELETE_PERSON_CONTACT_FULFILLED,
  DELETE_PERSON_CONTACT_PENDING,
  DELETE_PERSON_CONTACT_REJECTED,
  DELETE_PERSON_PHONE_FULFILLED,
  DELETE_PERSON_PHONE_PENDING,
  DELETE_PERSON_PHONE_REJECTED,
  UPDATE_PERSON_EMAIL_FULFILLED,
  UPDATE_PERSON_EMAIL_PENDING,
  UPDATE_PERSON_EMAIL_REJECTED,
  UPDATE_PERSON_ADDRESS_FULFILLED,
  UPDATE_PERSON_ADDRESS_PENDING,
  UPDATE_PERSON_ADDRESS_REJECTED,
  DELETE_PERSON_EMAIL_FULFILLED,
  DELETE_PERSON_EMAIL_PENDING,
  DELETE_PERSON_EMAIL_REJECTED,
  DELETE_PERSON_ADDRESS_FULFILLED,
  DELETE_PERSON_ADDRESS_PENDING,
  DELETE_PERSON_ADDRESS_REJECTED,
  UPDATE_PERSON_ADDRES_INVALID_RFC
} from "../../../utils/notifications";

import {
  Person,
  Telefono,
  Contacto,
  Responsable,
  CorreoElectronico,
  Direccion
} from "../oneDayPassActivatorPage/interfaces";

import { updatePhotoProm } from "../../actions/persons";

const SOLTERO = "SOLTERO";
const CASADO = "CASADO";
const OFICINA = "OFICINA";
const CASA = "CASA";
const CLIENTE = "CLIENTE";
const PROSPECTO = "PROSPECTO";
const TIPO_PERSONA_FISICA = "FISICA";
const TIPO_PERSONA_MORAL = "MORAL";

interface Props {
  viewBasicDataMode: boolean;
  viewDetailMode: boolean;
  person: Person;
  onChangeData: (object) => void;
  onEditExtraDataProspect: (prospect: object, id: string) => void;
  error: boolean;
  loading: boolean;
  match: any;
  editProspect: (prospect: object) => any;
  personPicture: object;
  updatePhoto: (idPersona: string, photo: Blob) => any;
  saveResponsable: (responsable: object) => any;
  toggleResponsable: (idMenor: string, idResponsable) => any;
  getResponsables: (personaId: string) => any;
  responsables: Responsable[];
  history: any;
  deletePhone: (phoneId: string) => any;
  updatePhone: (personId: string, body: object) => any;
  deleteContact: (contactId: string) => any;
  updateContact: (personId: string, body: object) => any;
  deleteAddress: (addressId: string) => any;
  updateAddress: (addressId: string, body: object) => any;
  deleteEmail: (addressId: string) => any;
  updateEmail: (addressId: string, body: object) => any;
  empresasConvenio: any[];
}

interface State {
  fields: {
    personaId: string;
    tipo: string;
    estadoCivil: string;
    correosElectronicos: CorreoElectronico[];
    direcciones: Direccion[];
    telefonos: Telefono[];
    contactos: Contacto[];
    responsables: Responsable[];
  };
  fotoStorageId: number;
  loadingResponsable: boolean;
}

enum ESTATUS_OPTIONS {
  ACTIVO = "ACTIVO",
  INACTIVO = "INACTIVO"
}

const getCleanFields = () => {
  return {
    personaId: "",
    tipo: PROSPECTO,
    estadoCivil: "SOLTERO",
    correosElectronicos: [],
    direcciones: [],
    telefonos: [],
    contactos: [],
    responsables: []
  };
};

const validateArrayCorreosElectronicos = correos =>
  !correos.every(correo => correo.correo && correo.tipo);

const validateCorreosElectronicos = correos =>
  correos.length == 0 ? true : false;

const validateArrayDirecciones = direcciones =>
  !direcciones.every(
    direccion =>
      direccion.codigoPostal &&
      direccion.calle &&
      direccion.numero &&
      direccion.colonia &&
      direccion.estado &&
      direccion.municipio
  );

const validateDirecciones = telefonos => (telefonos.length == 0 ? true : false);

const validateArrayTelefonos = telefonos =>
  !telefonos.every(telefono => telefono.numero && telefono.tipo);

const validateTelefonos = direcciones =>
  direcciones.length == 0 ? true : false;

const validateArrayContactos = contactos =>
  !contactos.every(
    contacto =>
      contacto.nombre &&
      contacto.apellido &&
      contacto.telefono &&
      contacto.parentesco
  );

const validateContactos = direcciones =>
  direcciones.length == 0 ? true : false;

const isKid = birthdate => {
  const age = moment().diff(moment(birthdate, "YYYY-MM-DD"), "y");
  return age < KID_MAXIMUM_AGE;
};

const CONCESIONARIO_TIPO = "CONCESIONARIO";
const CORPORATIVO_TIPO = "CORPORATIVO";
const PROSPECTO_TIPO = "PROSPECTO";
const MORAL_PERSONA_TIPO = "MORAL";

class ProspectDetail extends React.Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);
  state = {
    fields: getCleanFields(),
    errors: {},
    loadingResponsable: false,
    fotoStorageId: null
  };

  validationRules = {
    estadoCivil: { required: true },
    tipo: { required: true },
    correosElectronicos: {
      required: true,
      requiredCorreosElectronicos: value => validateCorreosElectronicos(value),
      requiredDataCorreosElectronicos: value =>
        validateArrayCorreosElectronicos(value)
    },
    direcciones: {
      required: true,
      requiredDirecciones: value => validateDirecciones(value),
      requiredDataDirecciones: value => validateArrayDirecciones(value)
    },
    telefonos: {
      required: true,
      requiredTelefonos: value => validateTelefonos(value),
      requiredDataTelefonos: value => validateArrayTelefonos(value)
    },
    contactos: {
      requiredContactos: value => validateContactos(value),
      requiredDataContactos: value => validateArrayContactos(value)
    },
    validateArrayContactos
  };

  validationMessages = {
    required: _ => `Obligatorio`,
    email: _ => `Correo electrónico inválido`,
    minLength: ruleValue => `Debe tener al menos ${ruleValue} caracteres`,
    maxLength: ruleValue => `Deber tener mínimo ${ruleValue} caracteres`,
    default: _ => `Inválido`,
    requiredCorreosElectronicos: _ =>
      "Debes ingresar al menos un correo electrónico",
    requiredDataCorreosElectronicos: _ =>
      "Verifica que no haya campos obligatorios vacios en alguno de los datos de correo electrónico",
    requiredDirecciones: _ => "Debes ingresar al menos una dirección",
    requiredDataDirecciones: _ =>
      "Verifica que no haya campos obligatorios vacios en alguna de las direcciones",
    requiredTelefonos: _ => "Debes ingresar al menos un teléfono",
    requiredDataTelefonos: _ =>
      "Verifica que no haya campos obligatorios vacios en alguno de los datos de teléfono",
    requiredContactos: _ => "Debes ingresar al menos un contacto",
    requiredDataContactos: _ =>
      "Verifica que no haya campos obligatorios vacios en alguno de los datos de contacto"
  };

  civilStateOptions = [
    { value: SOLTERO, label: "Soltero" },
    { value: CASADO, label: "Casado" }
  ];

  setOptionCivilState = estadoCivil => {
    this.setState(prevState => ({
      fields: { ...prevState.fields, estadoCivil }
    }));
  };

  onChangeFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };

        return { ...state, error: {}, fields };
      });
      this.re.validateField(fieldName, value);
    };
  };

  onSubmit = e => {
    e.preventDefault();
    const { personaId } = this.props.match.params;
    const {
      fields: { tipo, estadoCivil }
    } = this.state;
    const extra = {
      tipo,
      estadoCivil
    };
    this.props.onEditExtraDataProspect(extra, personaId);
  };

  addContacto = () => {
    let contacto = {
      nombre: "",
      apellido: "",
      telefono: "",
      parentesco: ""
    };

    this.setState(state => {
      let contactos = state.fields.contactos;
      contactos.push(contacto);
      return {
        ...state,
        fields: {
          ...state.fields,
          contactos
        }
      };
    });
  };

  changeContacto = (name, value, index) => {
    this.setState(state => {
      let contactos = state.fields.contactos;
      contactos = contactos.map((contacto, i) => {
        if (i == index) {
          return { ...contacto, [name]: value };
        } else {
          return contacto;
        }
      });
      return {
        ...state,
        fields: {
          ...state.fields,
          contactos
        }
      };
    });
  };

  addEmail = () => {
    let email = {
      correo: "",
      tipo: "PARTICULAR",
      principal: false,
      bloqueoPublicidad: false
    };

    this.setState(state => {
      let correosElectronicos = state.fields.correosElectronicos;
      correosElectronicos.push(email);
      return {
        ...state,
        fields: {
          ...state.fields,
          correosElectronicos
        }
      };
    });
  };

  validateEmail = (id: number, correo: string) => {
    const correos = [...(this.state.fields.correosElectronicos || [])];
    const existente = correos.filter(c => c.correo == correo).shift();

    correos.forEach(c => {
      c.principal = false;
    });

    if (existente) {
      existente.correoElectronicoId = id;
      existente.principal = true;
    } else {
      correos.unshift({
        correoElectronicoId: id,
        correo,
        tipo: "PARTICULAR",
        principal: true,
        bloqueoPublicidad: false
      });
    }

    this.setState({
      fields: {
        ...this.state.fields,
        correosElectronicos: correos
      }
    });
  };

  saveEmail = async index => {
    try {
      infoNotification(UPDATE_PERSON_EMAIL_PENDING);
      const {
        value: { data }
      } = await this.props.updateEmail(
        this.props.match.params.personaId,
        this.state.fields.correosElectronicos[index]
      );

      const { correosElectronicos } = this.state.fields;
      correosElectronicos[index] = data;
      this.setState({
        ...this.state,
        fields: {
          ...this.state.fields,
          correosElectronicos
        }
      });
      successNotification(UPDATE_PERSON_EMAIL_FULFILLED);
    } catch (e) {
      console.error(e);
      errorNotification(UPDATE_PERSON_EMAIL_REJECTED);
    }
  };

  deleteEmail = async index => {
    try {
      infoNotification(DELETE_PERSON_EMAIL_PENDING);
      await this.props.deleteEmail(
        this.state.fields.correosElectronicos[index].correoElectronicoId
      );
      successNotification(DELETE_PERSON_EMAIL_FULFILLED);
      this.setState(state => {
        let correosElectronicos = state.fields.correosElectronicos;
        correosElectronicos.splice(index, 1);
        return {
          ...state,
          fields: {
            ...state.fields,
            correosElectronicos
          }
        };
      });
    } catch (e) {
      errorNotification(DELETE_PERSON_EMAIL_REJECTED);
      console.error(e);
    }
  };

  changeEmail = (name, value, index) => {
    this.setState(state => {
      let correosElectronicos = state.fields.correosElectronicos;
      correosElectronicos = correosElectronicos.map((email, i) => {
        if (i == index) {
          return { ...email, [name]: value };
        } else {
          return email;
        }
      });
      return {
        ...state,
        fields: {
          ...state.fields,
          correosElectronicos
        }
      };
    });
  };

  changePrincipalEmail = index => {
    this.setState(state => {
      let correosElectronicos = state.fields.correosElectronicos;
      correosElectronicos = correosElectronicos.map((email, i) => {
        if (index == i) {
          return { ...email, principal: true };
        } else {
          return { ...email, principal: false };
        }
      });
      return {
        ...state,
        fields: {
          ...state.fields,
          correosElectronicos
        }
      };
    });
  };

  changeBloqueoPublicidad = (check, index) => {
    this.setState(state => {
      let correosElectronicos = state.fields.correosElectronicos;
      correosElectronicos = correosElectronicos.map((email, i) => {
        if (index == i) {
          return { ...email, bloqueoPublicidad: check };
        } else {
          return { ...email, bloqueoPublicidad: email.bloqueoPublicidad };
        }
      });
      return {
        ...state,
        fields: {
          ...state.fields,
          correosElectronicos
        }
      };
    });
  };

  changePrincipalTelefono = index => {
    const { telefonos } = this.state.fields;
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        telefonos: telefonos.map((telefono, indexMap) => {
          return {
            ...telefono,
            principal: indexMap == index
          };
        })
      }
    });
  };

  addTelefono = () => {
    let telefono = {
      numero: "",
      tipo: "CELULAR",
      principal: false
    };

    this.setState(state => {
      let telefonos = state.fields.telefonos;
      telefonos.push(telefono);
      return {
        ...state,
        fields: {
          ...state.fields,
          telefonos
        }
      };
    });
  };

  addResponsable = () => {
    const { person } = this.props;
    const responsable = {
      idPersonaMenor: person.personaId,
      idResponsable: null,
      parentesco: "",
      nombrePersonaMenor: `${person.nombre} ${person.primerApellido}`,
      nombreResponsable: "",
      estatus: ESTATUS_OPTIONS.ACTIVO
    };

    this.setState(state => {
      const { responsables } = state.fields;
      responsables.push(responsable);
      return {
        ...state,
        fields: {
          ...state.fields,
          responsables
        }
      };
    });
  };

  setResponsableId = (index, persona) => {
    const { personaId, nombre, primerApellido, segundoApellido } = persona;
    const { responsables } = this.state.fields;
    const responsable = responsables[index];
    const newResponsable = {
      ...responsable,
      idResponsable: personaId,
      nombreResponsable: `${nombre} ${primerApellido} ${segundoApellido}`,
      persona
    };
    const newResponsables = [...responsables];
    newResponsables[index] = newResponsable;

    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        responsables: newResponsables
      }
    });
  };

  deleteContacto = async index => {
    try {
      infoNotification(DELETE_PERSON_CONTACT_PENDING);
      await this.props.deleteContact(this.state.fields.contactos[index].id);
      successNotification(DELETE_PERSON_CONTACT_FULFILLED);
      this.setState(state => {
        let contactos = state.fields.contactos;
        contactos.splice(index, 1);
        return {
          ...state,
          fields: {
            ...state.fields,
            contactos
          }
        };
      });
    } catch (e) {
      errorNotification(DELETE_PERSON_CONTACT_REJECTED);
      console.error(e);
    }
  };

  deleteTelefono = async index => {
    try {
      infoNotification(DELETE_PERSON_PHONE_PENDING);
      await this.props.deletePhone(this.state.fields.telefonos[index].id);
      successNotification(DELETE_PERSON_PHONE_FULFILLED);
      this.setState(state => {
        let telefonos = state.fields.telefonos;
        telefonos.splice(index, 1);
        return {
          ...state,
          fields: {
            ...state.fields,
            telefonos
          }
        };
      });
    } catch (e) {
      errorNotification(DELETE_PERSON_PHONE_REJECTED);
      console.error(e);
    }
  };

  saveContacto = async index => {
    try {
      infoNotification(UPDATE_PERSON_CONTACT_PENDING);
      const {
        value: { data }
      } = await this.props.updateContact(
        this.props.match.params.personaId,
        this.state.fields.contactos[index]
      );

      const { contactos } = this.state.fields;
      contactos[index] = data;
      this.setState({
        ...this.state,
        fields: {
          ...this.state.fields,
          contactos
        }
      });
      successNotification(UPDATE_PERSON_CONTACT_FULFILLED);
    } catch (e) {
      console.error(e);
      errorNotification(UPDATE_PERSON_CONTACT_REJECTED);
    }
  };

  saveDireccion = async index => {
    try {
      if (!this.validateTaxAddressData(this.state.fields.direcciones[index])) {
        return;
      }

      infoNotification(UPDATE_PERSON_ADDRESS_PENDING);

      if (!this.state.fields.direcciones[index].claveUsoCfdi) {
        this.state.fields.direcciones[index].claveUsoCfdi = null;
      }

      const {
        value: { data }
      } = await this.props.updateAddress(
        this.props.match.params.personaId,
        this.state.fields.direcciones[index]
      );

      const { direcciones } = this.state.fields;
      direcciones[index] = data;
      this.setState({
        ...this.state,
        fields: {
          ...this.state.fields,
          direcciones
        }
      });
      successNotification(UPDATE_PERSON_ADDRESS_FULFILLED);
    } catch (e) {
      console.error(e);
      errorNotification(UPDATE_PERSON_ADDRESS_REJECTED);
    }
  };

  validateTaxAddressData = (direccion: Direccion): boolean => {
    if (!!direccion.domicilioFiscal) {
      const { tipoPersonaFiscal, rfc } = direccion;
      let error = false;
      switch (tipoPersonaFiscal) {
        case TIPO_PERSONA_FISICA:
          error =
            rfc.length != 13 ||
            !/[A-Za-z&]{4}[0-9]{6}[A-Za-z0-9&]{3}/i.test(rfc) ||
            !moment(rfc.substring(4, 10), "YYMMDD").isValid();
          break;
        case TIPO_PERSONA_MORAL:
          error =
            rfc.length != 12 ||
            !/[A-Za-z&]{3}[0-9]{6}[A-Za-z0-9&]{3}/i.test(rfc) ||
            !moment(rfc.substring(3, 9), "YYMMDD").isValid();
          break;
      }

      if (error) {
        errorNotification(UPDATE_PERSON_ADDRES_INVALID_RFC);
        return false;
      }
    }

    return true;
  };

  saveTelefono = async index => {
    try {
      infoNotification(UPDATE_PERSON_PHONE_PENDING);
      const {
        value: { data }
      } = await this.props.updatePhone(
        this.props.match.params.personaId,
        this.state.fields.telefonos[index]
      );

      const { telefonos } = this.state.fields;
      telefonos[index] = data;
      this.setState({
        ...this.state,
        fields: {
          ...this.state.fields,
          telefonos
        }
      });
      successNotification(UPDATE_PERSON_PHONE_FULFILLED);
    } catch (e) {
      console.error(e);
      errorNotification(UPDATE_PERSON_PHONE_REJECTED);
    }
  };

  changeTelefono = (name, value, index) => {
    this.setState(state => {
      let telefonos = state.fields.telefonos;
      telefonos = telefonos.map((telefono, i) => {
        if (i == index) {
          return { ...telefono, [name]: value };
        } else {
          return telefono;
        }
      });
      return {
        ...state,
        fields: {
          ...state.fields,
          telefonos
        }
      };
    });
  };

  addDireccion = () => {
    let direccion = {
      tipo: CASA,
      domicilio: "domicilio",
      codigoPostal: "",
      colonia: "",
      calle: "",
      municipio: "",
      numero: "",
      estado: "",
      domicilioFiscal: false
    };

    this.setState(state => {
      let direcciones = state.fields.direcciones;
      direcciones.push(direccion);
      return {
        ...state,
        fields: {
          ...state.fields,
          direcciones
        }
      };
    });
  };

  deleteDireccion = async index => {
    try {
      infoNotification(DELETE_PERSON_ADDRESS_PENDING);
      await this.props.deleteAddress(
        this.state.fields.direcciones[index].direccionId
      );
      successNotification(DELETE_PERSON_ADDRESS_FULFILLED);
      this.setState(state => {
        let direcciones = state.fields.direcciones;
        direcciones.splice(index, 1);
        return {
          ...state,
          fields: {
            ...state.fields,
            direcciones
          }
        };
      });
    } catch (e) {
      errorNotification(DELETE_PERSON_ADDRESS_REJECTED);
      console.error(e);
    }
  };

  changeColonia = (value, index) => {
    this.setState(state => {
      let direcciones = state.fields.direcciones;
      direcciones = direcciones.map((direccion, i) => {
        if (i == index) {
          return { ...direccion, ["colonia"]: value };
        } else {
          return direccion;
        }
      });
      return {
        ...state,
        fields: {
          ...state.fields,
          direcciones
        }
      };
    });
  };

  changeDireccion = (name, value, index) => {
    this.setState(state => {
      let direcciones = state.fields.direcciones;
      direcciones = direcciones.map((direccion, i) => {
        if (i == index) {
          if (!direccion.claveUsoCfdi) {
            direccion.claveUsoCfdi = null;
          }

          return { ...direccion, [name]: value };
        } else {
          return direccion;
        }
      });
      return {
        ...state,
        fields: {
          ...state.fields,
          direcciones
        }
      };
    });
  };

  changeDomicilioFiscal = (check, index) => {
    this.setState(state => {
      let direcciones = state.fields.direcciones;
      direcciones = direcciones.map((direccion, i) => {
        if (index == i) {
          const {
            tipoPersonaFiscal,
            rfc,
            razonSocial,
            regimenFiscal,
            claveUsoCfdi
          } = direccion;
          return {
            ...direccion,
            domicilioFiscal: check,
            tipoPersonaFiscal: !!check ? tipoPersonaFiscal : null,
            rfc: !!check ? rfc : null,
            razonSocial: !!check ? razonSocial : null,
            regimenFiscal: !!check ? regimenFiscal : null,
            claveUsoCfdi: !!check ? claveUsoCfdi : null
          };
        } else {
          return { ...direccion, domicilioFiscal: direccion.domicilioFiscal };
        }
      });
      return {
        ...state,
        fields: {
          ...state.fields,
          direcciones
        }
      };
    });
  };

  typePersonOptions = [
    { value: "", label: "SELECCIONAR" },
    { value: CLIENTE, label: "CLIENTE" },
    { value: PROSPECTO, label: "PROSPECTO" }
  ];

  loadResponsables = () => {
    if (!this.state.loadingResponsable && this.props.prospect) {
      if (isKid(this.props.prospect.fechaNacimiento)) {
        this.props.getResponsables(this.props.prospect.personaId);
        this.setState({
          loadingResponsable: true
        });
      }
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.personaId != this.props.match.params.personaId) {
      location.reload();
    }

    if (!this.props.responsables) {
      this.loadResponsables();
    }

    if (prevProps.responsables !== this.props.responsables) {
      this.setState({
        fields: {
          ...this.state.fields,
          responsables: this.props.responsables
        }
      });
    }

    const { person } = this.props;
    if (
      person &&
      !this.props.error &&
      this.state.fields.personaId !== person.personaId
    ) {
      const prospect = person;
      const { fotoStorageId } = person;
      this.setState(state => {
        const fields = {
          ...state.fields,
          ...prospect
        };
        return { ...state, error: {}, fields, fotoStorageId };
      });
      if (isEmpty(prospect.contactos)) {
        this.addContacto();
      }
      if (isEmpty(prospect.correosElectronicos)) {
        this.addEmail();
      }
      if (isEmpty(prospect.direcciones)) {
        this.addDireccion();
      }
      if (isEmpty(prospect.telefonos)) {
        this.addTelefono();
      }
    }
  }

  componentDidMount() {
    if (!this.props.responsables) {
      this.loadResponsables();
    }

    this.setState(state => {
      const fields = getCleanFields();
      return { ...state, error: {}, fields };
    });
  }

  handleFileChosen = ev => {
    ev.preventDefault();
    const file = ev.target.files[0];
    const { person } = this.props;
    if (person) {
      infoNotification("Cargando foto...");
      updatePhotoProm(person.personaId, file)
        .then((data: any) => {
          const fData = JSON.parse(data.data);
          const { fotoStorageId } = fData;
          this.setState({ fotoStorageId });
          successNotification("Foto cargada correctamente.");
        })
        .catch(err => {
          errorNotification("Ocurrió un error al cargar la foto.");
        });
    }
  };

  disableResponsable = index => {
    const responsable = this.state.fields.responsables[index];
    return isEmpty(responsable.parentesco) || !responsable.idResponsable;
  };

  saveResponsable = async index => {
    const responsable = this.state.fields.responsables[index];
    const {
      value: { data }
    } = await this.props.saveResponsable(responsable);
    const { responsables } = this.state.fields;
    responsables[index] = data;
    this.setState({
      fields: {
        ...this.state.fields,
        responsables
      }
    });
  };

  toggleResponsable = async index => {
    const responsable = this.state.fields.responsables[index];
    await this.props.toggleResponsable(
      responsable.idPersonaMenor,
      responsable.idResponsable
    );
    const { responsables } = this.state.fields;
    responsables.splice(index, 1);
    this.setState({
      fields: {
        ...this.state.fields,
        responsables
      }
    });
  };

  getPhotoUrl = () => {
    return !!this.state.fotoStorageId || this.props.person.fotoStorageId
      ? `${BFF_URL}${IMAGES_ENDPOINT}/${this.state.fotoStorageId ||
          this.props.person.fotoStorageId}`
      : "";
  };

  render() {
    const { person } = this.props;
    const { direcciones } = person;

    let isMoralPersonType = false;
    const isConcesionario = person.tipo == CONCESIONARIO_TIPO;
    const isCorporativo = person.tipo == CORPORATIVO_TIPO;
    const isProspecto = person.tipo == PROSPECTO_TIPO;

    if ((direcciones || []).length) {
      const oDomicilioFiscal = direcciones.find(x => x.domicilioFiscal);
      if (!!oDomicilioFiscal) {
        isMoralPersonType =
          (oDomicilioFiscal.tipoPersonaFiscal || "") == MORAL_PERSONA_TIPO;
      }
    }

    return (
      <Container className="p-0">
        <div className={s.tableContainer}>
          <Row className="primaryTitle m-0">
            <Col xs="10">
              <p className="m-0 mb-2">
                {upperCase(
                  `${person.nombre} ${person.primerApellido ||
                    ""} ${person.segundoApellido || ""}`
                )}
              </p>
            </Col>
          </Row>
          <PeopleBasicData
            viewMode={this.props.viewBasicDataMode}
            prospectToEdit={person}
            editProspect={this.props.editProspect}
            disableDetailsButton
            small
            isMoralPersonType={isMoralPersonType}
            isConcesionario={isConcesionario}
            isCorporativo={isCorporativo}
            isProspecto={isProspecto}
            empresasConvenio={this.props.empresasConvenio}
            validateEmail={this.validateEmail}
          />
          <hr />
          <form>
            {person.tipo != CONCESIONARIO_TIPO &&
              person.tipo != CORPORATIVO_TIPO &&
              person.tipo != PROSPECTO_TIPO && (
                <div>
                  <div className="form-row">
                    <div className="col">
                      <p className="mb-1 primaryTitle">Foto</p>
                      <Row>
                        {(!!this.state.fotoStorageId ||
                          !!person.fotoStorageId) && (
                          <Col md={4}>
                            <img
                              style={{
                                display: "block",
                                maxWidth: "400px",
                                maxHeight: "165px",
                                width: "auto",
                                height: "auto"
                              }}
                              src={this.getPhotoUrl()}
                              alt=""
                            />
                          </Col>
                        )}
                      </Row>
                    </div>
                  </div>
                  <hr />
                </div>
              )}
            {person.tipo != CONCESIONARIO_TIPO &&
              person.tipo != CORPORATIVO_TIPO && (
                <div>
                  <div className="form-row">
                    <p className="my-1 primaryTitle w-100">Detalle</p>
                  </div>
                  <div className="form-row">
                    <div className="col">
                      <BaseInput
                        label={"Estado Civil"}
                        name="estadoCivil"
                        type="select"
                        options={this.civilStateOptions}
                        id="estadoCivil"
                        placeholder="Estado Civil"
                        value={this.state.fields.estadoCivil}
                        onChange={this.onChangeFactory("estadoCivil")}
                        errors={this.re.mapFieldErrors("estadoCivil")}
                        disabled={
                          this.props.loading || !!this.props.viewDetailMode
                        }
                      />
                    </div>
                    <div className="col">
                      <BaseInput
                        label={"Tipo de Persona"}
                        name="tipo"
                        type="select"
                        options={this.typePersonOptions}
                        id="tipo"
                        placeholder="Tipo de Persona"
                        value={this.state.fields.tipo}
                        onChange={this.onChangeFactory("tipo")}
                        errors={this.re.mapFieldErrors("tipo")}
                        disabled={true}
                      />
                    </div>
                    {!this.props.viewDetailMode && (
                      <div className="col-2 mt-4">
                        <Button
                          className={`${s.buttonMarginTop} ${s.primaryButton}`}
                          onClick={e => this.onSubmit(e)}
                          size="sm"
                          disabled={false}
                          block
                        >
                          Guardar Datos Extra
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            <CorreosElectronicos
              viewMode={this.props.viewDetailMode}
              data={this.state.fields.correosElectronicos}
              onAddEmail={this.addEmail}
              onDeleteEmail={this.deleteEmail}
              onChangeEmail={this.changeEmail}
              onChangePrincipalEmail={this.changePrincipalEmail}
              onChangeBloqueoPublicidad={this.changeBloqueoPublicidad}
              errors={this.re.mapFieldErrors("correosElectronicos")}
              saveEmail={this.saveEmail}
            />
            <Direcciones
              viewMode={this.props.viewDetailMode}
              data={this.state.fields.direcciones}
              onAddDireccion={this.addDireccion}
              onDeleteDireccion={this.deleteDireccion}
              onChangeDireccion={this.changeDireccion}
              onChangeColonia={this.changeColonia}
              onChangeDomicilioFiscal={this.changeDomicilioFiscal}
              errors={this.re.mapFieldErrors("direcciones")}
              saveDireccion={this.saveDireccion}
              isConcesionario={isConcesionario}
              isCorporativo={isCorporativo}
            />
            <Telefonos
              viewMode={this.props.viewDetailMode}
              data={this.state.fields.telefonos}
              onAddTelefono={this.addTelefono}
              onDeleteTelefono={this.deleteTelefono}
              onChangeTelefono={this.changeTelefono}
              onChangePrincipalTelefono={this.changePrincipalTelefono}
              errors={this.re.mapFieldErrors("telefonos")}
              saveTelefono={this.saveTelefono}
            />
            {person.tipo != CONCESIONARIO_TIPO && (
              <Contactos
                viewMode={this.props.viewDetailMode}
                data={this.state.fields.contactos}
                onAddContacto={this.addContacto}
                onDeleteContacto={this.deleteContacto}
                onChangeContacto={this.changeContacto}
                saveContacto={this.saveContacto}
                errors={this.re.mapFieldErrors("contactos")}
              />
            )}
            <hr />
            {person &&
              person.tipo != CONCESIONARIO_TIPO &&
              isKid(person.fechaNacimiento) && (
                <Container className="p-0">
                  <Row className="primaryTitle m-0">
                    <Col xs="10">
                      <p className="m-0">Responsables</p>
                    </Col>
                    <Col xs={3} className="text-right">
                      {this.props.viewDetailMode && (
                        <Button
                          className="btn btn-light btn-sm"
                          onClick={e => this.addResponsable()}
                        >
                          Agregar Responsable
                          <FontAwesomeIcon className="ml-2" icon={faPlus} />
                        </Button>
                      )}
                    </Col>
                  </Row>
                  {this.state.fields.responsables.map(
                    (
                      {
                        parentesco,
                        persona,
                        nombreResponsable,
                        idResponsable,
                        id
                      },
                      index,
                      responsables
                    ) => (
                      <Row key={index}>
                        <Col>
                          <PersonSelector
                            disabled={index !== responsables.length - 1}
                            initPerson={
                              persona ||
                              (idResponsable && {
                                personaId: idResponsable,
                                nombre: nombreResponsable
                              })
                            }
                            setPerson={persona =>
                              this.setResponsableId(index, persona)
                            }
                            cKey={"CQ9C83CDPQ"}
                          />
                        </Col>
                        <Col xs="3">
                          <BaseInput
                            label="Parentesco"
                            name="parentesco"
                            type="text"
                            id="parentesco"
                            placeholder="Parentesco"
                            value={parentesco}
                            onChange={event => {
                              const parentesco = event.target.value;
                              const { responsables } = this.state.fields;
                              const responsable = responsables[index];
                              responsable.parentesco = parentesco;

                              this.setState({
                                fields: {
                                  ...this.state.fields,
                                  responsables
                                }
                              });
                            }}
                          />
                        </Col>
                        <Col xs="1" className="pl-0">
                          <Container>
                            <Row className="h-100 mt-4">
                              <Col className="p-1">
                                <span
                                  onClick={() =>
                                    !this.disableResponsable(index) &&
                                    this.saveResponsable(index)
                                  }
                                  style={{
                                    cursor: "pointer",
                                    color: this.disableResponsable(index)
                                      ? "gray"
                                      : "black"
                                  }}
                                >
                                  <FontAwesomeIcon icon={faSave} />
                                </span>
                              </Col>
                              <Col className="p-1">
                                <span
                                  onClick={() =>
                                    id &&
                                    this.props.history.push(
                                      `/personas/${idResponsable}/detalle`
                                    )
                                  }
                                  style={{
                                    cursor: "pointer",
                                    color: !id ? "gray" : "black"
                                  }}
                                >
                                  <FontAwesomeIcon icon={faPen} />
                                </span>
                              </Col>
                              <Col className="p-1">
                                <span
                                  onClick={() => {
                                    id && this.toggleResponsable(index);
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    color: !id ? "gray" : "black"
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTimesCircle} />
                                </span>
                              </Col>
                            </Row>
                          </Container>
                        </Col>
                      </Row>
                    )
                  )}
                </Container>
              )}
          </form>
        </div>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ProspectDetail));
