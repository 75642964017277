// TODO: Patron Redux en revisión de cargos en proceso
import axios from "axios";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import { Button, Col, Container, Row, TabPane } from "reactstrap";
import BaseInput from "../../../../toolkit/baseInput";
import ModalFrame from "../../../../toolkit/modalFrame";
import IAction from "../../../../types/IAction";
import { userHasPermission } from "../../../../utils/accessTree";
import constants from "../../../../utils/constants";
import { handleRequestError } from "../../../../utils/helpers";
import { infoNotification } from "../../../../utils/notifications";
import permissionCodes from "../../../../utils/permissionCodes";
import { generateIgualaMaintenanceCharge } from "../../../actions/partners";
import { createMaintenanceDiscountRequest } from "../../../actions/products";
import CreateMaintenanceChargeModal from "./createMaintenanceChargeModal/createMaintenanceChargeModal";
import MovementsTable from "./movementsTable";
import s from "./styles.scss";

interface Props {
  movementsList: object[];
  idMembresia: string;
  idMembresiaSocio: string;
  listHistoricalRebilling: any;
  sendMovementEmail: (id: number, email: string) => any;
  toggleBill: (storageId: number) => any;
  toggleBillXml: (uuid: string) => IAction;
  getMovements: () => any;
  historicalRebilling: (ventaId: string) => IAction;
  invalidateStateValue: (fieldName: string, value: any) => void;
  refreshPartner: () => void;
  descripcionTraspaso: any;
  membershipBlocked: boolean;
  membershipBlockedReactivacion: boolean;
}

interface State {
  isOpen: boolean;
  amount: number;
  hasProcessing: boolean;

  isModalCurrentMonthChargeOpen: boolean;
  isModalIgualaChargeOpen: boolean;
  igualaAmount: number;
  isCatModalCurrentMonthChargeEnabled: boolean;
  saving: boolean;
}

class TabMovements extends React.Component<Props, State> {
  state = {
    isOpen: false,
    amount: null,
    hasProcessing: false,

    isModalCurrentMonthChargeOpen: false,
    isModalIgualaChargeOpen: false,
    igualaAmount: null,
    isCatModalCurrentMonthChargeEnabled: true,

    saving: false
  };

  getTablePageSize = () => {
    const defPageSize: number = 20;
    return (this.props.movementsList || []).length
      ? this.props.movementsList.length + 1
      : defPageSize;
  };

  toggleModal = () => this.setState({ isOpen: !this.state.isOpen });

  toggleModalCurrentmonthCharge = () => {
    this.setState({
      isModalCurrentMonthChargeOpen: !this.state.isModalCurrentMonthChargeOpen
    });
  };

  toggleModalIgualaCharge = () => {
    this.setState({
      isModalIgualaChargeOpen: !this.state.isModalIgualaChargeOpen
    });
  };

  handleDiscountCreation = () => {
    createMaintenanceDiscountRequest({
      membresiaSocioId: this.props.idMembresiaSocio,
      productoId: this.props.idMembresia,
      monto: this.state.amount
    })
      .then(() => {
        this.toggleModal();
        this.props.invalidateStateValue("movementsList", []);
        this.props.getMovements();
      })
      .catch(handleRequestError);
  };

  handleGenerateIgualaCharge = () => {
    infoNotification("Generando cargo de iguala de mantenimiento");

    this.setState({ saving: true }, () => {
      generateIgualaMaintenanceCharge(
        this.props.idMembresiaSocio,
        this.state.igualaAmount
      )
        .then(() => {
          this.setState({
            isModalIgualaChargeOpen: false,
            saving: false
          });
          infoNotification("Cargo generado correctamente...");
          this.props.invalidateStateValue("movementsList", []);
          this.props.getMovements();
        })
        .catch(handleRequestError);
    });
  };

  handleInputChange = e => this.setState({ amount: e.target.value });
  handleInputIgualaChange = e =>
    this.setState({ igualaAmount: e.target.value });

  /*  handleCheckBoxChange = e => {
     const checked = e.target.checked;
     this.setState({ isCatModalCurrentMonthChargeEnabled: checked });
   }; */

  async componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.idMembresiaSocio != this.props.idMembresiaSocio) {
      this.showProcessingAlert(this.props.idMembresiaSocio);
    }
  }

  async componentDidMount() {
    this.showProcessingAlert(this.props.idMembresiaSocio);
  }

  async showProcessingAlert(idMembresiaSocio) {
    try {
      await axios.get(constants.BASE_URL + "/api/partners/has-processing", {
        withCredentials: true,
        params: {
          idMembresiaSocio
        }
      });

      this.setState({
        hasProcessing: true
      });
    } catch (error) {
      this.setState({
        hasProcessing: false
      });
    }
  }

  isMembershipBlocked = () => {
    return (
      !!this.props.membershipBlocked ||
      !!this.props.membershipBlockedReactivacion
    );
  };

  invalidateMovementsData = () => {
    this.props.invalidateStateValue("movementsList", []);
    this.props.getMovements();
  };

  render() {
    const {
      movementsList,
      toggleBill,
      toggleBillXml,
      sendMovementEmail
    } = this.props;
    return (
      <TabPane className="my-2" tabId="7">
        {userHasPermission(
          permissionCodes.PARTNER_MEMBERSHIP_TAB_MOVEMENTS_ADD_DISCOUNT
        ) && (
          <ModalFrame
            title={"Agregar descuento de mantenimiento"}
            isOpen={this.state.isOpen}
            toggle={this.toggleModal}
          >
            <Container>
              <p>
                Se generará un código de descuento aplicable al último cargo de
                mantenimiento no pagado existente. En caso de no existir, se
                generará el cargo de mantenimiento del siguiente mes con el
                descuento aplicado
              </p>
              <Row>
                <p className={`w-100`}>
                  <BaseInput
                    label={"Cantidad"}
                    name="cantidadDescuento"
                    type="number"
                    id="cantidadDescuento"
                    placeholder="Descuento"
                    onChange={this.handleInputChange}
                  />
                </p>
              </Row>
              <Row>
                <Col className="text-right">
                  <Button
                    color="secondary"
                    onClick={this.toggleModal}
                    style={{ marginRight: "15px" }}
                  >
                    Cerrar
                  </Button>
                  <Button
                    color="primary"
                    onClick={this.handleDiscountCreation}
                    disabled={(() =>
                      !this.state.amount ||
                      parseFloat(this.state.amount) <= 0)()}
                  >
                    Generar descuento
                  </Button>
                </Col>
              </Row>
            </Container>
          </ModalFrame>
        )}

        {userHasPermission(
          permissionCodes.PARTNER_MEMBERSHIP_TAB_MOVEMENTS_GENERATE_IGUALA_MAINTENANCE
        ) && (
          <ModalFrame
            title={"Generar cargo de iguala de mantenimiento"}
            isOpen={this.state.isModalIgualaChargeOpen}
            toggle={this.toggleModalIgualaCharge}
          >
            <Container>
              <p>
                Se generará un cargo de iguala de mantenimiento con el monto
                ingresado. ¿Desea continuar?
              </p>
              <Row>
                <p className={`w-100`}>
                  <BaseInput
                    label={"Importe"}
                    name="cantidadImporte"
                    type="number"
                    id="cantidadImporte"
                    placeholder="Importe"
                    onChange={this.handleInputIgualaChange}
                  />
                </p>
              </Row>
              <Row>
                <Col className="text-right">
                  <Button
                    color="primary"
                    onClick={this.handleGenerateIgualaCharge}
                    style={{ marginRight: "15px" }}
                    disabled={
                      !this.state.igualaAmount ||
                      parseFloat(this.state.igualaAmount) <= 0 ||
                      !!this.state.saving
                    }
                  >
                    Generar
                  </Button>

                  <Button
                    color="secondary"
                    onClick={this.toggleModalIgualaCharge}
                  >
                    Cerrar
                  </Button>
                </Col>
              </Row>
            </Container>
          </ModalFrame>
        )}

        {userHasPermission(
          permissionCodes.PARTNER_MEMBERSHIP_TAB_MOVEMENTS_GENERATE_CURRENT_MONTH
        ) && (
          <CreateMaintenanceChargeModal
            idMembresiaSocio={this.props.idMembresiaSocio}
            isOpen={this.state.isModalCurrentMonthChargeOpen}
            cancel={() => {
              this.setState({ isModalCurrentMonthChargeOpen: false });
            }}
            success={() => {
              this.setState({ isModalCurrentMonthChargeOpen: false });
              this.props.invalidateStateValue("movementsList", []);
              this.props.getMovements();
            }}
          />
        )}
        <Container className="p-0">
          <Row>
            <Col md="5">
              <div className="m-4">
                {userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_MOVEMENTS_GENERATE_IGUALA_MAINTENANCE
                ) &&
                  !this.isMembershipBlocked() && (
                    <Button
                      className="bg-info"
                      size="sm"
                      onClick={this.toggleModalIgualaCharge}
                    >
                      Generar cargo de iguala de mantenimiento
                    </Button>
                  )}
              </div>
            </Col>
            <Col md="7">
              <div className="m-4 text-right">
                {userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_MOVEMENTS_GENERATE_CURRENT_MONTH
                ) &&
                  !this.isMembershipBlocked() && (
                    <Button
                      className="primaryButton"
                      size="sm"
                      onClick={this.toggleModalCurrentmonthCharge}
                    >
                      Generar cargo de mantenimiento
                    </Button>
                  )}

                {userHasPermission(
                  permissionCodes.PARTNER_MEMBERSHIP_TAB_MOVEMENTS_ADD_DISCOUNT
                ) &&
                  !this.isMembershipBlocked() && (
                    <Button
                      className="ml-4"
                      size="sm"
                      onClick={this.toggleModal}
                    >
                      Agregar descuento de mantenimiento
                    </Button>
                  )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div
                hidden={!this.state.hasProcessing}
                className="alert alert-warning"
                style={{ padding: "6px 8px" }}
                role="alert"
              >
                <p className="m-0">
                  Algunos de los movimientos pendientes se estan procesando como
                  cargos automáticos; por favor, espere la resolución para
                  manipularlos manualmente en el carrito de compras.
                </p>
              </div>
            </Col>
            <Col>
              <Row>
                <Col>
                  <div className="m-3">
                    <div
                      style={{
                        display: "inline",
                        padding: "0px 20px",
                        marginRight: "8px",
                        background: "#ddd",
                        border: "solid 1px #000"
                      }}
                    ></div>
                    <span>
                      Cargos de mantenimientos <b>NO</b> habilitados para{" "}
                      <b>CAT</b>
                    </span>
                  </div>
                </Col>
                <Col>
                  <div className="m-3">
                    <div
                      style={{
                        display: "inline",
                        padding: "0px 20px",
                        marginRight: "8px",
                        background: "#fff3cd",
                        border: "solid 1px #000"
                      }}
                    ></div>
                    <span>Cargos de mantenimiento en proceso CAT activo</span>
                  </div>
                </Col>
              </Row>
              <MovementsTable
                historicalRebilling={this.props.historicalRebilling}
                listHistoricalRebilling={this.props.listHistoricalRebilling}
                loadingMovements={false}
                sendMovementEmail={sendMovementEmail}
                toggleBill={toggleBill}
                toggleBillXml={toggleBillXml}
                movementsList={movementsList}
                tablePageSize={this.getTablePageSize()}
                descripcionTraspaso={this.props.descripcionTraspaso}
                membershipBlocked={this.isMembershipBlocked()}
                refreshPartner={this.props.refreshPartner}
                invalidateMovementsData={this.invalidateMovementsData}
              />
            </Col>
          </Row>
        </Container>
      </TabPane>
    );
  }
}

export default withStyles(s)(TabMovements);
