import React from "react";
import { connect } from "react-redux";
import Layout from "../../components/layout";
import { Helmet } from "react-helmet";
import { getClubs } from "../../actions/catalogs";
import Demands from "../../components/demands";
import {
  saveDemand,
  getClassificationByLevel,
  getDemandById,
  updateDemand
} from "../../actions/demans";

interface Props {
  clubs: { clubId: number; nombre: string }[];
  getClubs: () => any;
  getClassificationByLevel: (level: number) => any;
  saveDemand: (demand: object) => any;
  updateDemand: (id: number, demand: object) => any;
  successfullyCreated: boolean;
  getDemandById: (id: number) => any;
  demandById: object;
  history: any;

  // HACKXXX: Se debe crear correctamente la interfaz
  match: any;
}

interface State {
  quejaId: any;
  updating: boolean;
}

class DemandsContainer extends React.Component<Props, State> {
  state = {
    quejaId: null,
    updating: false
  };

  async componentDidMount() {
    const { quejaId } = this.props.match.params;
    this.props.getClubs();

    if (quejaId) {
      await this.setState({
        quejaId: quejaId,
        updating: true
      });
      this.props.getDemandById(quejaId);
    } else {
      await this.setState({
        quejaId: -1,
        updating: true
      });
      this.props.getDemandById(-1);
    }
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    const quejaId = this.props.match.params.quejaId || -1;
    const changeDemand =
      !!this.props.demandById && prevProps.demandById != this.props.demandById;
    const emptyDemand = quejaId == -1 && !this.props.demandById;

    if (prevState.quejaId && prevState.quejaId != quejaId) {
      await this.setState({
        quejaId: quejaId,
        updating: true
      });
      this.props.getDemandById(quejaId);
    } else if (this.state.updating && (changeDemand || emptyDemand)) {
      await this.setState({
        updating: false
      });
    }
  }

  render() {
    return (
      <main>
        <Layout>
          <Helmet title="Peticiones y Quejas" />
          {this.state.updating || (
            <Demands
              successfullyCreated={this.props.successfullyCreated}
              saveDemand={this.props.saveDemand}
              updateDemand={this.props.updateDemand}
              clubs={(this.props.clubs || []).filter(c => !!c.migradoCrm2)}
              getClassificationByLevel={this.props.getClassificationByLevel}
              demandById={this.props.demandById}
            ></Demands>
          )}
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getClubs: () => {
    return dispatch(getClubs());
  },
  getClassificationByLevel: (level: number) => {
    return dispatch(getClassificationByLevel(level));
  },
  saveDemand: (demand: object) => {
    return dispatch(saveDemand(demand));
  },
  updateDemand: (id: number, demand: object) => {
    return dispatch(updateDemand(id, demand));
  },
  getDemandById: (id: number) => {
    return dispatch(getDemandById(id));
  }
});

const mapStateToProps = state => {
  const {
    catalogs: { clubs },
    demands: { successfullyCreated, demandById }
  } = state;
  return {
    clubs,
    successfullyCreated,
    demandById
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DemandsContainer);
