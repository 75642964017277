import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import { Container, Col, Button, Row } from "reactstrap";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BaseInput from "../../../toolkit/baseInput";
import MexicanCurrency from "../common/currency";
import s from "./styles.scss";
import { getClubsRequest } from "../../actions/catalogs";
import { getReportCatPreliminar } from "../../actions/reports";
import { handleRequestError } from "../../../utils/helpers";
import { Props, State } from "./interfaces";
import { userHasPermission } from "../../../utils/accessTree";
import permissionCodes from "../../../utils/permissionCodes";
import {
  infoNotification,
  successNotification
} from "../../../utils/notifications";

class CatPreliminarReport extends React.Component<Props, State> {
  state = {
    filters: {
      clubId: null
    },
    onSearch: false,
    clubs: [],
    data: []
  };

  componentDidMount() {
    this.getClubsCatalog();

    const { filters } = this.state;
    filters.clubId = this.props.userContext.clubSessionId;
    this.setState({ filters: { ...filters } }, () => {
      this.search();
    });
  }

  getClubsCatalog = () => {
    getClubsRequest()
      .then(({ data }) => {
        this.setState({ clubs: data });
      })
      .catch(handleRequestError);
  };

  columns = [
    {
      Header: () => <p className="font-weight-bold">Club</p>,
      Cell: row => {
        return <p className={s.initial}>{row.original.clubNombre}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold"># Membresía</p>,
      className: "text-center",
      Cell: row => {
        return <p className={s.initial}>{row.original.membresiaIdPublico}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Tipo</p>,
      className: "text-center",
      Cell: row => {
        return <p className={s.initial}>{row.original.tipoMedioPago}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Marca</p>,
      className: "text-center",
      Cell: row => {
        return <p className={s.initial}>{row.original.tipoTarjetaMedioPago}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Fecha cargo</p>,
      className: "text-center",
      Cell: row => {
        return <p className={s.initial}>{row.original.fechaAlta}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Tipo item</p>,
      className: "text-center",
      Cell: row => {
        return <p className={s.initial}>{row.original.tipoItem}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Mantenimiento</p>,
      className: "text-center",
      Cell: row => {
        return <p className={s.initial}>{row.original.mantenimientoNombre}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Importe</p>,
      className: "text-right",
      Cell: row => {
        return (
          <p className={s.initial}>
            <MexicanCurrency quantity={row.original.importeTotal} />
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Titular</p>,
      Cell: row => {
        return <p className={s.initial}>{row.original.personaNombre}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Titular correo</p>,
      Cell: row => {
        return <p className={s.initial}>{row.original.personaCorreo}</p>;
      }
    }
  ];

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  onClickDownloadCsvFile = () => {
    const { clubId } = this.state.filters;
    this.props.downloadReportCatPreliminar(clubId);
  };

  search = () => {
    infoNotification("Consultando los datos preliminares de CAT");

    this.setState({
      onSearch: true
    });

    const { clubId } = this.state.filters;

    getReportCatPreliminar(clubId)
      .then(({ data }) => {
        this.setState({ data });
        successNotification("Datos consultados correctamente");
      })
      .catch(handleRequestError);
  };

  render() {
    const { clubs, data } = this.state;
    const searchAllClubsPermission = userHasPermission(
      permissionCodes.REPORT_CAT_PRELIMINAR_SEARCH_ALL_CLUBS
    );

    return (
      <Container className="p-0">
        <Row>
          <Col xs={12} md={3} lg={3}>
            <BaseInput
              label="Club"
              name="club"
              id="club"
              type="select"
              value={this.state.filters.clubId}
              onChange={this.onChangeFilter("clubId")}
              size={"sm"}
              options={[{ value: "", label: "Seleccionar" }].concat(
                (clubs || [])
                  .filter(c => !!c.migradoCrm2)
                  .map(option => ({
                    value: option.clubId,
                    label: option.nombre
                  }))
              )}
              disabled={!searchAllClubsPermission}
            />
          </Col>
          <Col xs={12} md={3} lg={3} className="mt-4">
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.search}
              disabled={!this.state.filters.clubId}
            >
              Buscar
            </Button>
          </Col>
        </Row>

        {!!(data || []).length && (
          <Row className={"mt-5"}>
            <Col sm="6">
              Resultados de la búsqueda:{" "}
              <b
                style={{ fontWeight: "bolder", display: "inline-block" }}
                className="ml-3"
              >
                {(data || []).length}
              </b>
            </Col>
            <Col sm="6" className="text-right">
              <span>Exportar a CSV: </span>
              <Button
                id="btnReportExportCsv"
                color="link"
                onClick={this.onClickDownloadCsvFile}
              >
                <FontAwesomeIcon icon={faFileExport} color="black" />
              </Button>
            </Col>
          </Row>
        )}
        <ReactTable
          data={data || []}
          columns={this.columns}
          className="mt-2 -striped -highlight"
          sortable={false}
          resizable={false}
          pageSize={20}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(CatPreliminarReport));
