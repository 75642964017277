import React from "react";
import { Container, Row, Col, Input, Button } from "reactstrap";
import DatePicker from "../common/DatePicker";
import ReactTable from "react-table";
import moment from "moment";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import BaseInput from "../../../toolkit/baseInput";
import StartValidityModal from "../setStartValidity";
import { allowedTo } from "../../../utils/accessTree";
import accessCodes from "../../../utils/accessCodes";
import cloneDeep from "lodash.clonedeep";
import CONSTANTS from "../../../utils/constants";
import { setChildStep } from "../../helpers/stepper-state-comparator";

const ULTIMOS_REGISTRADOS = "6";
const AUTORIZADOS = "2";
const NO_AUTORIZADOS = "4";

const { AUTHORIZE_SPORTS_PRODUCTS_PRICE } = accessCodes;

interface Catalog {
  id: string;
  nombre: string;
}

interface Prices {
  nombre: string;
  estatus: string;
}

interface GeneralConfigSportsProduct {
  numeroEventos: number;
  fechaInicioVenta: string;
  fechaInicio: string;
  fechaFin: string;
  vigenciaDias: number;
  capacidad: number;
  aceptaInvitados: boolean;
  aceptaEmpleados: boolean;
}

const formatDate = date => moment(date).format("DD/MM/YYYY");

interface Props {
  successfulSave: boolean;
  addPricesToSportsProducts: (id: string, prices: object) => any;
  getPricesToSportsProducts: (params: object) => any;
  onClickSave: (e: object) => any;
  estatus: Catalog[];
  tiposPrecio: TipoPrecio[];
  prices: Prices[];
  id: string;
  loading: boolean;
  savingPrices: boolean;
  savingPricesError: boolean;
  onSuccessfulSave: () => any;
  authorizePrices: (prices: object[], id: string) => any;
  getSportsProductConfig: (id: string) => any;
  generalConfigById: GeneralConfigSportsProduct;
  generalProductById: object;
  accountingAccounts: object[];
  productAccounts: object[];
}

interface TipoPrecio {
  esquemaPagoId: string;
  nombre: string;
}

const getCleanChecked = () => ({});

class priceDataSportsProducts extends React.Component<Props> {
  renderEditable = cellInfo => {
    const { index, column } = cellInfo;
    const priceInfo = this.state.data[index];
    const { estatusId } = priceInfo;
    const inputName = column.id;
    const precio = priceInfo[`${inputName}`];
    return (
      <div
        className={
          (inputName === "precioEmpleado" &&
            !this.props.generalConfigById.aceptaEmpleados) ||
          (inputName === "precioPublico" &&
            !this.props.generalConfigById.aceptaInvitados)
            ? `${s.cellDisabled} ${s.renderEditable}`
            : precio && estatusId == ULTIMOS_REGISTRADOS
            ? `${s.cellEdit} ${s.renderEditable}`
            : estatusId == AUTORIZADOS
            ? `${s.cellAutorized} ${s.renderEditable}`
            : estatusId == NO_AUTORIZADOS
            ? `${s.cellUnautorized} ${s.renderEditable}`
            : `${s.cell} ${s.renderEditable}`
        }
      >
        <Input
          type="text"
          className={s.renderEditableInput}
          contentEditable={estatusId != AUTORIZADOS}
          value={this.state.data[index][`${inputName}`]}
          disabled={
            (inputName === "precioEmpleado" &&
              !this.props.generalConfigById.aceptaEmpleados) ||
            (inputName === "precioPublico" &&
              !this.props.generalConfigById.aceptaInvitados)
              ? true
              : false
          }
          onChange={(e: any) => {
            e.preventDefault();
            const tmpData = [...this.state.data];
            tmpData[index][`${inputName}`] = e.target.value.replace(
              /[^0-9]/gi,
              ""
            );
            if (tmpData[index][`${inputName}`] != "") {
              tmpData[index][`estatusId`] = ULTIMOS_REGISTRADOS;
            }
            setChildStep(tmpData);
            this.setState({ data: tmpData });
          }}
        />
      </div>
    );
  };

  state = {
    isModalOpen: false,
    search: {},
    selectAll: false,
    filters: {
      fechaVigencia: moment().format("YYYY-MM-DD"),
      estatus: ULTIMOS_REGISTRADOS,
      tipoPagoId: "1"
    },
    data: [],
    checked: getCleanChecked()
  };

  componentDidMount() {
    this.props.getSportsProductConfig(this.props.id);
    this.props.onClickSave(e => {
      e.preventDefault();
      this.modalToggle();
    });
    this.search();
  }

  handleCheckPrice = (id, e) => {
    const value = Boolean(e.target.checked);
    const data = cloneDeep(this.state.data);
    const checked = this.state.checked;
    checked[id] = value;
    setChildStep(data);
    this.setState(state => {
      return { ...state, data, checked };
    });
  };

  componentWillReceiveProps(nextProps) {
    if (!this.props.prices && nextProps.prices) {
      const data = [...nextProps.prices];
      setChildStep(data);
      this.setState({
        ...this.state,
        data,
        checked: getCleanChecked()
      });
    }
  }

  handleSelectAll = value => {
    const checked = this.state.checked;
    const data = cloneDeep(this.state.data);
    this.state.data.forEach(({ grupoId }) => {
      checked[grupoId] = value;
    });
    setChildStep(data);
    this.setState(state => ({
      ...state,
      data,
      checked,
      selectAll: value
    }));
  };

  refreshPrices = async actionPromise => {
    const { id } = this.props;
    return actionPromise
      .then(() => {
        this.setState(() => ({ isModalOpen: false }));
        return this.props.getPricesToSportsProducts({
          ...this.state.filters,
          id
        });
      })
      .then(({ value: { data: reqData } }) => {
        const data = reqData.map(price => {
          return {
            ...price,
            precioEmpleado: price.precioEmpleado || 0,
            precioSocio: price.precioSocio || 0,
            precioPublico: price.precioPublico || 0
          };
        });

        setChildStep(data);

        this.setState(state => {
          return {
            ...state,
            data,
            checked: getCleanChecked()
          };
        });
      });
  };

  save = async fechaInicioVigencia => {
    const checked = this.state.checked;
    const data: any = this.state.data;
    const { tipoPagoId } = this.state.filters;
    fechaInicioVigencia = moment(fechaInicioVigencia).format("YYYY-MM-DD");
    let updateData = data
      .filter(({ id }) => checked[id])
      .map(priceInfo => {
        return {
          id: priceInfo.id,
          grupoId: priceInfo.grupoId,
          precioEmpleado: priceInfo.precioEmpleado,
          precioPublico: priceInfo.precioPublico,
          precioSocio: priceInfo.precioSocio
        };
      });
    updateData = {
      fechaInicioVigencia,
      tipoPagoId,
      precios: updateData
    };
    this.refreshPrices(
      this.props.addPricesToSportsProducts(this.props.id, updateData)
    );
  };

  authorize = () => {
    const checked = this.state.checked;
    const { data } = this.state;
    const pricesToAuthorize = data
      .filter(
        ({ grupoId, estatusId }) =>
          checked[grupoId] && estatusId == NO_AUTORIZADOS
      )
      .map(({ grupoId, tipoPagoId }) => {
        return {
          grupoId,
          tipoPagoId
        };
      });
    this.refreshPrices(
      this.props.authorizePrices(pricesToAuthorize, this.props.id)
    );
  };

  search = () => {
    const { filters } = this.state;
    const { id } = this.props;
    this.setState(() => ({ checked: getCleanChecked() }));
    this.props.getPricesToSportsProducts({ ...filters, id });

    // HOTFIX: No se muestran los precios porque se cargan de this.state.data
    // this.refreshPrices(Promise.resolve());
  };

  changeFilter = (e, name) => {
    const value = e.target.value;
    this.setState(state => {
      const { filters } = this.state;
      filters[name] = value;
      return { ...state, filters };
    });
  };

  onChangeFilterDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: date
        };
        return { ...state, filters };
      });
    };
  };

  modalToggle = () => {
    this.setState(state => {
      const isModalOpen = !this.state.isModalOpen;
      return { ...state, isModalOpen };
    });
  };

  shouldComponentUpdate(nextProps) {
    return !(
      this.props.successfulSave !== nextProps.successfulSave &&
      nextProps.successfulSave
    );
  }

  getColumns = () => {
    const columns = [
      {
        Header: _ => {
          return (
            <div className={`${s.checkboxSelectAll} w-100 text-center`}>
              <Input
                type="checkbox"
                checked={this.state.selectAll}
                onChange={e => this.handleSelectAll(Boolean(e.target.checked))}
              />
            </div>
          );
        },
        id: "seleccionado",
        minWidth: 22,
        accessor: price => {
          return (
            <div className={`${s.checkboxContainer} ${s.myStyle}`}>
              <Row>
                <Col>
                  <Input
                    type="checkbox"
                    className={`${s.renderEditableCheckBox}`}
                    checked={this.state.checked[price.id]}
                    onChange={e => this.handleCheckPrice(price.id, e)}
                  />
                </Col>
              </Row>
            </div>
          );
        }
      },
      {
        Header: "Grupo",
        accessor: "nombre",
        minWidth: 60
      },
      {
        id: "inicioVigencia",
        Header: "Inicio de Vigencia",
        accessor: ({ fechaInicioVigencia }) => formatDate(fechaInicioVigencia),
        minWidth: 60
      },
      {
        id: "finVigencia",
        Header: "Fin de Vigencia",
        accessor: ({ fechaFinVigencia }) => formatDate(fechaFinVigencia),
        minWidth: 60
      }
    ];

    if (!this.props.generalProductById.esCorporativo) {
      columns.push({
        Header: "Precio Socio",
        accessor: "precioSocio",
        Cell: this.renderEditable
      });

      columns.push({
        Header: "Precio Empleado",
        accessor: "precioEmpleado",
        Cell: this.renderEditable
      });
    }

    columns.push({
      Header: "Precio Publico",
      accessor: "precioPublico",
      Cell: this.renderEditable
    });

    return columns;
  };

  render() {
    return (
      <Container fluid className="p-0 m-0 ">
        <StartValidityModal
          isOpen={this.state.isModalOpen}
          toggle={this.modalToggle}
          save={this.save}
          savingPrices={this.props.savingPrices}
          savingPricesError={this.props.savingPricesError}
        />
        <Row>
          <Col>
            <DatePicker
              label="Fecha de Vigencia"
              name="fechaVigencia"
              id="fechaVigencia"
              selected={this.state.filters.fechaVigencia}
              onChange={this.onChangeFilterDate("fechaVigencia")}
              size={"sm"}
            />
          </Col>
          <Col>
            <BaseInput
              label={"Estatus"}
              name={"estatus"}
              type="select"
              id={"estatus"}
              placeholder="Estatus"
              value={this.state.filters.estatus}
              size="sm"
              onChange={e => this.changeFilter(e, "estatus")}
              options={[{ value: "", label: "Seleccione el Estatus" }].concat(
                this.props.estatus.map(option => ({
                  value: option.id,
                  label: option.nombre
                }))
              )}
            />
          </Col>
          <Col>
            <BaseInput
              label={"Tipo de Precio"}
              name={"tipoPagoId"}
              type="select"
              id={"tipoPagoId"}
              placeholder="Tipo de Precio"
              value={this.state.filters.tipoPagoId}
              size="sm"
              onChange={e => this.changeFilter(e, "tipoPagoId")}
              options={[
                { value: "", label: "Seleccione el Tipo de Precio" }
              ].concat(
                this.props.tiposPrecio.map(option => ({
                  value: option.esquemaPagoId,
                  label: option.nombre
                }))
              )}
            />
          </Col>
          <Col>
            <Button
              size="sm"
              className={`${s.searchButton} ${s.primaryButton} float-right btn-block`}
              onClick={this.search}
              disabled={
                !this.state.filters.estatus ||
                !this.state.filters.fechaVigencia ||
                !this.state.filters.tipoPagoId
              }
            >
              Buscar
            </Button>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col className="text-center" xs={12}>
            {allowedTo(AUTHORIZE_SPORTS_PRODUCTS_PRICE) && (
              <Button
                className={`${s.actionsButton}`}
                color="success"
                size="sm"
                onClick={this.authorize}
              >
                Autorizar
              </Button>
            )}
          </Col>
        </Row>
        {this.props.generalConfigById && (
          <ReactTable
            manual
            data={this.state.data}
            columns={this.getColumns()}
            className={`w-100 -striped -highlight`}
            loading={this.props.loading}
            sortable={false}
            showPagination={false}
            defaultPageSize={3}
            resizable={false}
            style={{
              minHeight: `${this.state.data.length > 0 ? "100px" : "100px"}`
            }}
          />
        )}
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(priceDataSportsProducts));
