import React, { useState } from "react";
import ReactTable from "react-table";
import s from "./styles.scss";
import MembresiaCrm1 from "../../../types/MembresiaCrm1";

export interface TableProps {
  data: MembresiaCrm1[];
  loading: boolean;
}

export const MemoTable = React.memo(({ data, loading }: TableProps) => {
  const [columns, _] = useState(columnsFormatter());

  return (
    <>
      <div className="row">
        {/* Cantidad de membresías encontradas */}
        {!!data && !!data.length && (
          <>
            <div className="col p-0" style={{ lineHeight: "40px" }}>
              <span>Membresías encontradas: ({data.length})</span>
            </div>
          </>
        )}
      </div>
      <div className="row p-0 mb-5">
        <ReactTable
          data={data || []}
          loading={loading}
          pageSize={data && data.length > 5 ? data.length : 5}
          columns={columns}
          sortable={false}
          resizable={true}
          showPagination={false}
          className={`w-100 -highlight -striped ${s.hidden}`}
        />
      </div>
    </>
  );
});

const columnsFormatter = () => [
  {
    Header: "Memb.",
    id: "membresiaId",
    maxWidth: "88",
    className: s.tdClear,
    accessor: simpleAccessor("membresiaId"),
    headerClassName: "text-left"
  },
  {
    Header: "Id Persona",
    id: "personaId",
    maxWidth: "96",
    className: s.tdClear,
    accessor: simpleAccessor("personaId"),
    headerClassName: "text-left"
  },
  {
    Header: "Rol",
    id: "rol",
    maxWidth: "88",
    className: s.tdClear,
    accessor: simpleAccessor("rol"),
    headerClassName: "text-left"
  },
  {
    Header: "Nombre",
    id: "nombre",
    maxWidth: "128",
    className: s.tdClear,
    accessor: simpleAccessor("nombre"),
    headerClassName: "text-left"
  },
  {
    Header: "Ap. Paterno",
    id: "primerApellido",
    maxWidth: "128",
    className: s.tdClear,
    accessor: simpleAccessor("primerApellido"),
    headerClassName: "text-left"
  },
  {
    Header: "Ap. Materno",
    id: "segundoApellido",
    maxWidth: "128",
    className: s.tdClear,
    accessor: simpleAccessor("segundoApellido"),
    headerClassName: "text-left"
  },
  {
    Header: "Fecha de Nac.",
    id: "fechaNacimiento",
    maxWidth: "104",
    className: s.tdClear,
    accessor: dateAccessor("fechaNacimiento"),
    headerClassName: "text-left"
  },
  {
    Header: "Estatus",
    id: "esatus",
    maxWidth: "72",
    className: s.tdClear,
    accessor: simpleAccessor("estatus"),
    headerClassName: "text-left"
  },
  {
    Header: "Inicio de Vig.",
    id: "incio",
    maxWidth: "96",
    className: s.tdClear,
    accessor: dateAccessor("incio"),
    headerClassName: "text-left"
  },
  {
    Header: "Fin de Vig.",
    id: "fin",
    maxWidth: "96",
    className: s.tdClear,
    accessor: dateAccessor("fin"),
    headerClassName: "text-left"
  },
  {
    Header: "Estatus mov.",
    id: "estatusMov",
    maxWidth: "96",
    className: s.tdClear,
    accessor: simpleAccessor("estatusMov"),
    headerClassName: "text-left"
  },
  {
    Header: "Tipo invitado",
    id: "tipoInvitado",
    maxWidth: "152",
    className: s.tdClear,
    accessor: simpleAccessor("tipoInvitado"),
    headerClassName: "text-left"
  }
];

function simpleAccessor(key: keyof MembresiaCrm1) {
  return (row: MembresiaCrm1) => {
    const className =
      row.estatus != "Activo" ? `${s.field} ${s.inactivo}` : s.field;

    return <div className={className}>{row[key]}&nbsp;</div>;
  };
}

function dateAccessor(key: keyof MembresiaCrm1) {
  return row => {
    const className =
      row.estatus != "Activo" ? `${s.field} ${s.inactivo}` : s.field;

    return <div className={className}>{formatDate(row[key] || "-")}</div>;
  };
}

function formatDate(isoDate: string): string {
  try {
    const [year, month, day] = isoDate.split("-");
    return `${day}/${month}/${year} `;
  } catch (error) {
    return isoDate;
  }
}
