import { Preview, PreviewDispatcher } from "../../../types/IAction";
import {
  clearMembresiasCrm1,
  getMembresiasCrm1Acceso
} from "../../actions/membresiasCrm1";
import { GetMembresiasCrm1Acceso } from "../../reducers/membresiasCrm1";

export interface MembresiasExternasDispatcher {
  clearMembresiasCrm1: () => Promise<Preview<any>>;
  getMembresiasCrm1Acceso: (
    params?: any
  ) => Promise<Preview<GetMembresiasCrm1Acceso>>;
}

export const membresiasExternasDispatcher: PreviewDispatcher<
  MembresiasExternasDispatcher
> = dispatch => ({
  clearMembresiasCrm1: () => dispatch(clearMembresiasCrm1()),
  getMembresiasCrm1Acceso: (params?: any) =>
    dispatch(getMembresiasCrm1Acceso(params))
});
