import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import DatePicker from "../common/DatePicker";
import SelectFilter from "../common/SelectFilter";
import DragAndDropSelect from "../common/dragAndDropSelect";
import SportProgramSearch from "../sportProgramSearch";
import s from "./styles.scss";
import IAction from "../../../types/IAction";
import constants from "../../../utils/constants";
import moment from "moment";
import {
  getSportProgramInstanceDetail,
  createSportProgramInstance,
  updateSportProgramInstance,
  closeSportProgramInstance,
  deleteSportProgramInstance,
  getValidCustomersForSportProgramInstance
} from "../../actions/sportsPrograms";
import {
  infoNotification,
  errorNotification,
  successNotification,
  warnNotification,
  SPORTS_PROGRAMS_GET_VALID_CUSTOMERS_NOT_FOUND_MESSAGE,
  SPORTS_PROGRAMS_GET_VALID_CUSTOMERS_PENDING_MESSAGE,
  SPORTS_PROGRAMS_GET_VALID_CUSTOMERS_REJECTED_MESSAGE
} from "../../../utils/notifications";
import difference from "lodash.difference";

interface Club {
  clubId: number;
  nombre: string;
}

interface ITEM {
  id?: string;
  instancia: string;
  content: string;
  draggable: boolean;
}

interface Props {
  clubs: Club[];
  sellers: object[];
  sportProgramsProducts: object[];
  sportsProgramsInstances: object[];
  gettingSportsProgramsInstances: boolean;
  searchSportsProgramsInstances: (fields: object) => IAction;
  updated: () => void;
  userContext: any;
}

interface State {
  selectedSportProgramInstance: object;
  mode: string;

  dragAndDropItems: ITEM[];
  dragAndDropSelected: ITEM[];
  beforeSelected: ITEM[];
  fields: object;
  instructor: object;
  programaDeportivoId: number;

  sellersAsOptions: any[];
  showModal: boolean;
  showModalCancel: boolean;
  instanceToClose: any;
  searchFields: object;
  old: any;

  dragableUsed: boolean;

  error?: any;
}

const transformSourceToSelectOptionsFormat = (
  list: any,
  text: string,
  idName?: string
) => {
  return [
    {
      value: 0,
      label: text
    }
  ].concat(
    !!list
      ? list.map(option => ({
          value: idName ? option[idName] : option.id,
          label: option.nombre
        }))
      : []
  );
};

const START_DATE_FIELDNAME = "fechaInicio";
const END_DATE_FIELDNAME = "fechaFin";

const sortItems = (data: ITEM[]) =>
  data.sort(({ content: contentA }, { content: contentB }) =>
    contentA.localeCompare(contentB)
  );

class SportProgram extends React.Component<Props, State> {
  state = {
    selectedSportProgramInstance: null,
    mode: constants.SPORTS_PROGRAM_REGISTRATION_MODE.SEARCH,

    dragAndDropItems: [],
    dragAndDropSelected: [],
    beforeSelected: [],
    fields: {
      programaId: "",
      clubId: "",
      instructorId: "",
      fechaInicio: "",
      fechaFin: ""
    },
    instructor: null,
    programaDeportivoId: -1,
    sellersAsOptions: [],
    showModal: false,
    showModalCancel: false,
    instanceToClose: null,
    searchFields: null,
    old: {} as any,
    dragableUsed: false
  };

  getSellerByIdAsOption = (id: number): object => {
    return this.state.sellersAsOptions.find((x: any) => x.value == (id || -1));
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  toggleModalCancel = () => {
    this.setState({
      showModalCancel: !this.state.showModalCancel
    });
  };

  clearEditObjectState = () => {
    this.setState({
      selectedSportProgramInstance: null,
      mode: constants.SPORTS_PROGRAM_REGISTRATION_MODE.SEARCH,
      dragAndDropItems: [],
      dragAndDropSelected: [],
      fields: {
        programaId: "",
        clubId: "",
        instructorId: "",
        fechaInicio: "",
        fechaFin: ""
      },
      instructor: null,
      programaDeportivoId: -1,
      instanceToClose: null
    });
  };

  getValidCustomers = (
    sportProgramProductId: number,
    clubId: number,
    selectedItems?: ITEM[]
  ) => {
    infoNotification(SPORTS_PROGRAMS_GET_VALID_CUSTOMERS_PENDING_MESSAGE);
    getValidCustomersForSportProgramInstance(sportProgramProductId, clubId)
      .then(({ data }) => {
        if (!data.length) {
          warnNotification(
            SPORTS_PROGRAMS_GET_VALID_CUSTOMERS_NOT_FOUND_MESSAGE
          );
          return;
        }

        let i = 0;

        let dragAndDropItems = sortItems(
          data.map(
            (x: any): ITEM => {
              i = i + 1;
              return {
                id: String(i),
                instancia: x.productoInstanciaId,
                content: x.nombreCliente,
                draggable: true
              };
            }
          )
        );
        let dragAndDropSelected = selectedItems || [];
        dragAndDropSelected.forEach(d => {
          i = i + 1;
          d.id = String(i);
        });

        this.setState({
          dragAndDropItems,
          dragAndDropSelected
        });
      })
      .catch(err => {
        errorNotification(SPORTS_PROGRAMS_GET_VALID_CUSTOMERS_REJECTED_MESSAGE);
      });
  };

  //Current component handlers

  handleProductAndClubSelection = e => {
    e.preventDefault();

    const newFields = { ...this.state.fields, [e.target.name]: e.target.value };

    if (newFields[e.target.name] <= 0) {
      delete newFields[e.target.name];
    }

    this.setState({
      fields: newFields,
      dragAndDropItems: [],
      dragAndDropSelected: [],
      beforeSelected: []
    });

    if (!!newFields.programaId && !!newFields.clubId) {
      this.getValidCustomers(
        parseInt(newFields.programaId),
        parseInt(newFields.clubId)
      );
    }
  };

  handleInstructorSelection = e =>
    this.setState({
      fields: {
        ...this.state.fields,
        instructorId: e.value
      },
      instructor: e
    });

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event);
      const fields =
        fieldName === "fechaInicio"
          ? {
              ...this.state.fields,
              [fieldName]: date.format("YYYY-MM-DD"),
              fechaFin: date.add(1, "M").format("YYYY-MM-DD")
            }
          : {
              ...this.state.fields,
              [fieldName]: date.format("YYYY-MM-DD")
            };

      this.setState({
        error: {},
        fields
      });
    };
  };

  updateSelection = ({ items, selected }) => {
    this.setState({
      dragAndDropItems: sortItems(items),
      dragAndDropSelected: sortItems(selected),
      dragableUsed: true
    });
  };

  handleCloseSportProgramRegistration = () => {
    this.clearEditObjectState();
  };

  handleSaveSportProgramInstance = e => {
    e.preventDefault();
    if (
      !this.state.fields.programaId ||
      !this.state.fields.clubId ||
      !this.state.fields.instructorId ||
      !this.state.fields.fechaInicio ||
      !this.state.fields.fechaFin ||
      !(
        this.state.fields.instructorId != this.state.old.instructorId ||
        this.state.fields.fechaInicio != this.state.old.fechaInicio ||
        this.state.fields.fechaFin != this.state.old.fechaFin ||
        this.state.dragableUsed
      )
    ) {
      errorNotification(
        "Debe llenar los campos obligatorios y seleccionar las instancias de productos a asociar."
      );
      return;
    }

    switch (this.state.mode) {
      case constants.SPORTS_PROGRAM_REGISTRATION_MODE.CREATE:
        //Create body object
        const instanceData = {
          productoId: this.state.fields.programaId,
          entrenadorId: this.state.fields.instructorId,
          clubId: this.state.fields.clubId,
          fechaInicio: this.state.fields.fechaInicio,
          fechaFin: this.state.fields.fechaFin,
          productosInstanciaIds: this.state.dragAndDropSelected.map(
            x => x.instancia
          )
        };

        infoNotification("Creando instancia de programa deportivo...");

        createSportProgramInstance(instanceData)
          .then(({ data }: any) => {
            this.props.updated();
            successNotification(
              "La instancía de programa deportivo se creó correctamente"
            );
            this.handleCloseSportProgramRegistration();
            this.props.searchSportsProgramsInstances(this.state.searchFields);
          })
          .catch((err: any) => {
            errorNotification(
              "Ocurrió un error al guardar los datos de instancia de programa deportivo"
            );
          });

        break;
      case constants.SPORTS_PROGRAM_REGISTRATION_MODE.EDIT:
        infoNotification("Actualizando la instancia de programa deportivo...");

        const curretProductInstanceIds = (
          this.state.dragAndDropSelected || []
        ).map(x => x.instancia);

        updateSportProgramInstance(this.state.programaDeportivoId, {
          instructorId: this.state.fields.instructorId,
          fechaInicio: this.state.fields.fechaInicio,
          fechaFin: this.state.fields.fechaFin,
          instancias: curretProductInstanceIds
        })
          .then(() => {
            this.props.updated();
            successNotification(
              "La instancía de programa deportivo fue actualizada"
            );
            this.handleCloseSportProgramRegistration();
            this.props.searchSportsProgramsInstances(this.state.searchFields);
          })
          .catch(() => {
            errorNotification(
              "Ocurrió un error al actualizar los datos de la instancia de programa deportivo"
            );
          });

        break;
      default:
        this.handleCloseSportProgramRegistration();
        break;
    }
  };

  confirmCloseSportsProgramInstance = instanceData => {
    this.setState({ instanceToClose: instanceData });
    this.toggleModal();
  };

  handleCloseSportsProgramInstance = () => {
    this.toggleModal();

    if (!this.state.instanceToClose) {
      return;
    }

    infoNotification(
      "Cerrando instancia de programa deportivo y generando comisiones..."
    );
    closeSportProgramInstance(this.state.instanceToClose.programaDeportivoId)
      .then(() => {
        this.props.updated();
        successNotification(
          "La instancía de programa deportivo se cerró correctamente"
        );
        this.handleCloseSportProgramRegistration();
        this.props.searchSportsProgramsInstances(this.state.searchFields);
      })
      .catch(err => {
        errorNotification(
          "Ocurrió un error al cerrar los datos de instancia de programa deportivo"
        );
      });
  };

  handleCancelProgram = () => {
    this.toggleModalCancel();

    infoNotification("Cancelando el registro del programa deportivo...");

    deleteSportProgramInstance(this.state.programaDeportivoId)
      .then(() => {
        this.props.updated();
        successNotification(
          "El registro del programa deportivo se canceló correctamente"
        );
        this.handleCloseSportProgramRegistration();
        this.props.searchSportsProgramsInstances(this.state.searchFields);
      })
      .catch(err => {
        errorNotification(
          "Ocurrió un error al cancelar el registro del programa deportivo"
        );
      });
  };

  handleSearch = searchFields => {
    this.setState({ searchFields });
    this.props.searchSportsProgramsInstances(searchFields);
  };

  //Search component handlers
  changeSportProgramInstanceSelection = (
    selectedProgInstanceData: any,
    mode: string
  ) => {
    if (mode == constants.SPORTS_PROGRAM_REGISTRATION_MODE.CREATE) {
      this.setState({
        selectedSportProgramInstance: selectedProgInstanceData,
        mode,
        dragableUsed: false,
        old: {
          instructorId: null,
          fechaInicio: null,
          fechaFin: null
        }
      });
    } else {
      getSportProgramInstanceDetail(
        selectedProgInstanceData.programaDeportivoId
      )
        .then(({ data }) => {
          if (data.status == "CERRADO") {
            errorNotification(
              "La instancia del programa deportivo ya fue cerrada"
            );
            return;
          }

          const selectedItems = data.clientesParticipantes.map(
            (x: any): ITEM => {
              return {
                instancia: x.productoInstanciaId,
                content: x.nombreCliente,
                draggable: true
              };
            }
          );

          this.setState({
            selectedSportProgramInstance: data,
            dragAndDropSelected: selectedItems,
            dragableUsed: false,
            mode,
            programaDeportivoId: data.programaDeportivoId,
            fields: {
              programaId: data.productoId || "",
              clubId: data.clubId || "",
              instructorId: data.instructorId || "",
              fechaInicio: data.fechaInicio || "",
              fechaFin: data.fechaFin || ""
            },
            instructor: this.getSellerByIdAsOption(data.instructorId),
            old: {
              instructorId: data.instructorId,
              fechaInicio: data.fechaInicio,
              fechaFin: data.fechaFin
            }
          });
          this.getValidCustomers(data.productoId, data.clubId, selectedItems);
        })
        .catch(err => {
          errorNotification(
            "Error al consultar los datos de la instancia del programa deportivo"
          );
        });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.sellers != this.props.sellers) {
      this.setState({
        sellersAsOptions: nextProps.sellers.map((item: any) => ({
          label: `${item.name || ""} ${item.firstSurname ||
            ""} ${item.secondSurname || ""}`.trim(),
          value: item.userId
        }))
      });
    }
  }

  componentDidMount = () => {
    this.setState({
      fields: {
        ...this.state.fields,
        clubId:
          (this.props.userContext && this.props.userContext.clubSessionId) ||
          null
      }
    });
  };

  render() {
    return (
      <Container className="p-0 mt-3">
        <div
          className={`${
            this.state.mode != constants.SPORTS_PROGRAM_REGISTRATION_MODE.SEARCH
              ? "d-none"
              : ""
          }`}
        >
          <SportProgramSearch
            clubs={this.props.clubs}
            sellers={this.props.sellers}
            sportProgramsProducts={this.props.sportProgramsProducts}
            sportsProgramsInstances={this.props.sportsProgramsInstances}
            gettingSportsProgramsInstances={
              this.props.gettingSportsProgramsInstances
            }
            searchSportsProgramsInstances={this.handleSearch}
            changeSportProgramInstanceSelection={
              this.changeSportProgramInstanceSelection
            }
            confirmCloseSportProgramInstance={
              this.confirmCloseSportsProgramInstance
            }
            userClub={
              this.props.userContext && this.props.userContext.clubSessionId
            }
          />
        </div>

        {this.state.mode !=
          constants.SPORTS_PROGRAM_REGISTRATION_MODE.SEARCH && (
          <div
            className={`${
              this.state.mode ==
              constants.SPORTS_PROGRAM_REGISTRATION_MODE.SEARCH
                ? "d-none"
                : ""
            }`}
          >
            <p className={`${s.primaryTitle}`}>
              {this.state.mode ==
              constants.SPORTS_PROGRAM_REGISTRATION_MODE.CREATE
                ? "Creación de instancía de programa deportivo"
                : "Edición de instancía de programa deportivo"}
            </p>
            <Row>
              <Col lg="12" className="mb-2">
                {this.state.mode ==
                constants.SPORTS_PROGRAM_REGISTRATION_MODE.CREATE
                  ? "Seleccione el programa deportivo y el club donde se impartió para buscar a los clientes aplicables"
                  : "Programa deportivo y club seleccionados"}
              </Col>
              <Col xs={12} md={4} lg={4}>
                <BaseInput
                  type="select"
                  id="programaId"
                  name="programaId"
                  value={this.state.fields.programaId}
                  label="Programa deportivo *"
                  placeholder="Programa deportivo"
                  options={transformSourceToSelectOptionsFormat(
                    this.props.sportProgramsProducts,
                    "Seleccione un programa deportivo"
                  )}
                  onChange={this.handleProductAndClubSelection}
                  disabled={
                    this.state.mode ==
                    constants.SPORTS_PROGRAM_REGISTRATION_MODE.EDIT
                  }
                />
              </Col>
              <Col xs={12} md={4} lg={4}>
                <BaseInput
                  type="select"
                  id="clubId"
                  name="clubId"
                  value={this.state.fields.clubId}
                  label="Club *"
                  placeholder="CLUB"
                  options={transformSourceToSelectOptionsFormat(
                    this.props.clubs,
                    "Seleccione un club",
                    "clubId"
                  )}
                  onChange={this.handleProductAndClubSelection}
                  disabled={
                    this.state.mode ==
                      constants.SPORTS_PROGRAM_REGISTRATION_MODE.EDIT ||
                    (this.props.userContext &&
                      this.props.userContext.clubSessionId)
                  }
                />
              </Col>
              <Col lg="12" className="mb-2 mt-3">
                Seleccione al instructor y el periodo de impartición
              </Col>
              <Col xs={12} md={4} lg={4}>
                <SelectFilter
                  label="Selecciona instructor *"
                  name="instructorId"
                  type="select"
                  id="instructorId"
                  placeholder="Seleccione un instructor"
                  value={this.state.instructor}
                  isMulti={false}
                  options={this.state.sellersAsOptions}
                  onChange={this.handleInstructorSelection}
                  loading={false}
                  isAsync={false}
                />
              </Col>
              <Col xs={12} md={4} lg={4}>
                <DatePicker
                  label="Fecha Inicial *"
                  name={START_DATE_FIELDNAME}
                  id={START_DATE_FIELDNAME}
                  selected={this.state.fields[START_DATE_FIELDNAME]}
                  onChange={this.onChangeDate(START_DATE_FIELDNAME)}
                />
              </Col>
              <Col xs={12} md={4} lg={4}>
                <DatePicker
                  label="Fecha Final *"
                  name={END_DATE_FIELDNAME}
                  id={END_DATE_FIELDNAME}
                  selected={this.state.fields[END_DATE_FIELDNAME]}
                  onChange={this.onChangeDate(END_DATE_FIELDNAME)}
                />
              </Col>
            </Row>
            <Row className="p-0 mt-3">
              <DragAndDropSelect
                height={"300px"}
                data={this.state.dragAndDropItems}
                selected={this.state.dragAndDropSelected}
                updateSelection={this.updateSelection}
                onClickSelected={(itemId: string, state: boolean) => {}}
                resetClicked={(source: object[]) => {}}
              />
            </Row>
            <Row className="my-3">
              <Col className="text-right">
                <Button
                  className={`${s.dangerButton} mr-2 ${
                    this.state.mode !=
                    constants.SPORTS_PROGRAM_REGISTRATION_MODE.EDIT
                      ? "d-none"
                      : "d-inline-block"
                  }`}
                  onClick={this.toggleModalCancel}
                >
                  {"Descartar"}
                </Button>
                <Button
                  className={`${s.secondaryButton} d-inline-block mr-2`}
                  onClick={this.handleCloseSportProgramRegistration}
                >
                  {"Cancelar"}
                </Button>

                <Button
                  className={`${s.primaryButton} d-inline-block mr-2`}
                  onClick={this.handleSaveSportProgramInstance}
                  disabled={
                    !this.state.fields.programaId ||
                    !this.state.fields.clubId ||
                    !this.state.fields.instructorId ||
                    !this.state.fields.fechaInicio ||
                    !this.state.fields.fechaFin ||
                    this.state.dragAndDropSelected.length <= 0 ||
                    !(
                      this.state.fields.instructorId !=
                        this.state.old.instructorId ||
                      this.state.fields.fechaInicio !=
                        this.state.old.fechaInicio ||
                      this.state.fields.fechaFin != this.state.old.fechaFin ||
                      this.state.dragableUsed
                    )
                  }
                >
                  {"Guardar"}
                </Button>
              </Col>
            </Row>
          </div>
        )}

        <Modal
          isOpen={this.state.showModal}
          toggle={this.toggleModal}
          fade={true}
        >
          <ModalHeader toggle={this.toggleModal}>
            Confirmar cierre de instancia
          </ModalHeader>
          <ModalBody>
            Se cerrará la instancia de programa deportivo. Se generarán las
            comisiones de operación correspondientes y ya no se podrán agregar
            usuarios. ¿Desea continuar?
          </ModalBody>
          <ModalFooter>
            <Button
              className={`${s.primaryButton} d-inline-block mr-2`}
              onClick={this.handleCloseSportsProgramInstance}
            >
              SI
            </Button>{" "}
            <Button
              className={`${s.secondaryButton} d-inline-block mr-2`}
              onClick={this.toggleModal}
            >
              NO
            </Button>
          </ModalFooter>
        </Modal>

        {/* Confirmación de eliminación de programa deportivo */}
        <Modal
          isOpen={this.state.showModalCancel}
          toggle={this.toggleModalCancel}
          fade={true}
        >
          <ModalHeader toggle={this.toggleModalCancel}>
            Confirmar eliminación del programa deportivo
          </ModalHeader>
          <ModalBody>
            Se descartará este programa deportivo y se reestableceran los
            eventos a los usuarios para que pueda configurarlos después. ¿Desea
            continuar?
          </ModalBody>
          <ModalFooter>
            <Button
              className={`${s.primaryButton} d-inline-block mr-2`}
              onClick={this.handleCancelProgram}
            >
              SI
            </Button>{" "}
            <Button
              className={`${s.secondaryButton} d-inline-block mr-2`}
              onClick={this.toggleModalCancel}
            >
              NO
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(SportProgram));
