import React from "react";
import { connect } from "react-redux";
import Layout from "../../components/layout";
import { Helmet } from "react-helmet";
import ComissionsReportPage from "../../components/comissionsReport";
import {
  getComissionsReport,
  getPastPeriods,
  authorizeMemberships
} from "../../actions/reports";
import { getClubs, getSchemas, getSellers } from "../../actions/catalogs";
import { getPeriodById } from "../../actions/periods";
import { FILTERS } from "../../components/comissionsReport/interfaces";
import moment from "moment";
const PAST_PERIODS_TO_GET = 30;

interface Props {
  clubs: object;
  periods: object;
  periodById: object;
  reportComissions: object[];
  sellers: object[];
  schemas: object[];
  getClubs: () => any;
  getSchemas: () => any;
  getPastPeriods: (limite: string, cantidad: number) => any;
  getSellers: () => any;
  getComissionsReport: (filters: FILTERS) => void;
  getPeriodById: (idPeriodo: number) => void;
  authorizeMemberships: (comissionsList: object) => void;
}

interface State {}

class ComissionsReport extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    this.props.getSchemas();
    this.props.getClubs();
    this.props.getPastPeriods(
      moment()
        .add(20, "days")
        .format("YYYY-MM-DD"),
      PAST_PERIODS_TO_GET
    );
    this.props.getSellers();
  }

  componentDidUpdate() {}

  seterNameToSchemas = () => {
    let schemasCal = [];
    if (this.props.periodById && this.props.schemas) {
      this.props.periodById["esquemasAplicables"].forEach(element => {
        this.props.schemas.forEach(item => {
          if (element.esquemaId === item["esquemaId"]) schemasCal.push(item);
        });
      });
      return schemasCal;
    }
    return null;
  };

  render() {
    return (
      <main>
        <Helmet title="Creacion de Esquema de Beneficios" />
        <Layout>
          {this.props.clubs && this.props.periods && (
            <ComissionsReportPage
              fetchSearchData={this.props.getComissionsReport}
              getSchemasByPeriod={this.props.getPeriodById}
              clubs={this.props.clubs}
              periods={this.props.periods}
              schemas={this.seterNameToSchemas()}
              reportComissions={this.props.reportComissions}
              sellers={this.props.sellers}
              authorizeMemberships={this.props.authorizeMemberships}
            />
          )}
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getClubs: () => {
    return dispatch(getClubs());
  },
  getPastPeriods: (limite, cantidad) => {
    return dispatch(getPastPeriods(limite, cantidad));
  },
  getSchemas: () => {
    return dispatch(getSchemas());
  },
  getPeriodById: idPeriodo => {
    return dispatch(getPeriodById(idPeriodo));
  },
  getComissionsReport: (filters: FILTERS) => {
    return dispatch(getComissionsReport(filters));
  },
  getSellers: () => {
    return dispatch(getSellers());
  },
  authorizeMemberships: (comissionsList: object) => {
    return dispatch(authorizeMemberships(comissionsList));
  }
});

const mapStateToProps = state => {
  const {
    catalogs: { clubs, sellers, schemas },
    periods: { periods, periodById },
    reports: { reportComissions, loadingReport }
  } = state;
  return {
    clubs,
    schemas,
    periods,
    periodById,
    sellers,
    reportComissions,
    loadingReport
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComissionsReport);
