import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";

export const setProspectToEdit = (prospect: object) => ({
  type: actionTypes.SET_PROSPECT_TO_EDIT,
  payload: prospect
});

export const toggleCreateProspectModal = (prospect?: object): IAction => ({
  type: actionTypes.CREATE_PROSPECT_TOGGLE,
  payload: prospect
});

export const toggleLinkPartnerModal = (): IAction => ({
  type: actionTypes.LINK_PARTNER_TOGGLE
});
export const toggleLinkFreePartnerModal = (): IAction => ({
  type: actionTypes.LINK_FREE_PARTNER_TOGGLE
});
export const toggleChangeLinkFreePartnerModal = (data): IAction => ({
  type: actionTypes.LINK_CHANGE_FREE_PARTNER_TOGGLE,
  payload: { changeData: data }
});
export const toggleLinkFreeChildPartnerModal = (): IAction => ({
  type: actionTypes.LINK_FREE_CHILD_PARTNER_TOGGLE
});

export const toggleAddPersonModal = (controlKey: String = ""): IAction => ({
  type: actionTypes.ADD_PERSON_TOGGLE,
  payload: { controlKey }
});

export const toggleConfirmBenefitsModal = (): IAction => ({
  type: actionTypes.CONFIRM_CHANGES_BENEFITS_MODAL
});

export const toggleCreateAgreementModal = (agreement?: object): IAction => ({
  type: actionTypes.CREATE_AGREEMENT_TOGGLE,
  payload: agreement
});

export const toggleCreateClubModal = (club?: object): IAction => ({
  type: actionTypes.CREATE_CLUB_TOGGLE,
  payload: club
});

export const toggleDeleteProspectModal = (prospect?: object): IAction => ({
  type: actionTypes.DELETE_PROSPECT_TOGGLE,
  payload: prospect
});

export const toggleActionProspectModal = (prospect?: object): IAction => ({
  type: actionTypes.ACTIONS_PROSPECT_TOGGLE,
  payload: prospect
});

export const getProspectActionsLog = (prospectId?: string): IAction => {
  const baseUrl = `/api/prospects/${prospectId}/activities`;
  return {
    type: actionTypes.GET_PROSPECTS_ACTIONS_LOG,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}`, {
        withCredentials: true
      })
    }
  };
};

export const editStatusActivity = (
  activityId: string,
  status: string
): IAction => {
  const baseUrl = `/api/activity/${activityId}/status`;
  return {
    type: actionTypes.EDIT_ACTIVITY_STATUS,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${baseUrl}`,
        { status },
        {
          withCredentials: true
        }
      )
    }
  };
};

export const toggleActionLogProspectModal = (prospect?: object): IAction => ({
  type: actionTypes.ACTIONS_LOG_PROSPECT_TOGGLE,
  payload: prospect
});

export const toggleStatusProspectModal = (prospect?: object): IAction => ({
  type: actionTypes.STATUS_PROSPECT_TOGGLE,
  payload: prospect
});

export const toggleChangeStatusActivity = (activityId?: string): IAction => ({
  type: actionTypes.STATUS_ACTIVITY_TOGGLE,
  payload: activityId
});

export const togglePromotionDetailModal = (promotion?: object): IAction => ({
  type: actionTypes.SHOW_PROMOTION_DETAIL_TOGGLE,
  payload: promotion
});

export const toggleAgreementDetailModal = (agreement?: object): IAction => ({
  type: actionTypes.SHOW_AGREEMENT_DETAIL_TOGGLE,
  payload: agreement
});

export const toggleCreateBankModal = (bank?: object): IAction => ({
  type: actionTypes.CREATE_BANK_TOGGLE,
  payload: bank
});

export const toggleEditMonthsWithoutInterest = (bank?: object): IAction => ({
  type: actionTypes.EDIT_BANK_MONTHS_WITHOUT_INTEREST_TOGGLE,
  payload: bank
});

export const toggleAddBankCardModal = (): IAction => ({
  type: actionTypes.PARTNER_ADD_BANK_CARD_TOGGLE
});

export const toggleEditSession = (edit?: boolean): IAction => ({
  type: actionTypes.EDIT_SESSION_MODAL,
  payload: edit
});
