import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import Reform from "@franleplant/reform";
import { FormErrors } from "@franleplant/reform/types";
import { Container } from "reactstrap";
import fill from "lodash.fill";
import findIndex from "lodash.findindex";

interface Props {
  match: any;
  history: any;
  successfulSave: boolean;
  memberships: MEMBERSHIP[];
  membershipsDocument: MEMBERSHIP_DOCUMENT[];
  onClickSave: (e: object) => any;
  setMemberships: (memberships) => any;
}

interface State {
  errors: FormErrors;
  fields: { membresiasAplicables: object };
  membershipsSelected: object;
}

interface MEMBERSHIP_DOCUMENT {
  idMembresia: number;
}

interface MEMBERSHIP {
  datosBasicosMembresiaId: number;
  nombre: string;
}
class AddMembershipsToDocument extends React.Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);
  state = {
    fields: {
      membresiasAplicables: null
    },
    errors: {},
    membershipsSelected: []
  };

  validationRules = {
    membresiasAplicables: {
      required: _ =>
        this.props.memberships
          .filter((_, index) => this.state.membershipsSelected[index])
          .map(({ datosBasicosMembresiaId: membresiaId }) => ({
            membresiaId
          })).length === 0
    }
  };

  validationMessages = {
    required: _ => `Necesitas seleccionar al menos una membresia`,
    default: _ => `Inválido`
  };

  componentDidMount() {
    this.setState({
      membershipsSelected: fill(Array(this.props.memberships.length), false)
    });

    this.props.onClickSave(e => {
      e.preventDefault();
      if (this.re.validateFormFromState()) {
        const membresias = this.props.memberships
          .filter((_, index) => this.state.membershipsSelected[index])
          .map(({ datosBasicosMembresiaId: membresiaId }) => ({
            membresia: membresiaId
          }));
        this.props.setMemberships(membresias);
      }
    });
  }
  processed = false;
  componentDidUpdate() {
    const { membershipsDocument: propsMembershipsDocument } = this.props;

    const { memberships } = this.props;

    if (
      memberships.length &&
      propsMembershipsDocument &&
      propsMembershipsDocument.length &&
      !this.processed
    ) {
      const { id } = this.props.match.params;
      this.setState({
        membershipsSelected: id
          ? memberships.map(
              ({ datosBasicosMembresiaId }) =>
                findIndex(
                  propsMembershipsDocument,
                  m => datosBasicosMembresiaId === m.idMembresia
                ) > -1
            )
          : []
      });
      this.processed = true;
    }
  }

  onSelectMembership = (event, id) => {
    const { checked } = event.target;
    const { membershipsSelected } = this.state;
    membershipsSelected[id] = checked;
    this.setState({ membershipsSelected });
    this.re.validateFormFromState();
  };

  render() {
    return (
      <Container className="p-0" fluid>
        <div className="form-group">
          <div className="row mb-3 mt-3">
            <div className="col">
              <p className={`${s.primaryTitle} w-100 m-0`}>
                Membresias Aplicables
              </p>
            </div>
          </div>
          <div className="row m-0 p-0">
            {this.props.memberships &&
              this.props.memberships.length &&
              this.props.memberships.map(
                ({ nombre, datosBasicosMembresiaId }, index) => (
                  <div key={datosBasicosMembresiaId} className="col-3 p-0 m-0">
                    <div className="row p-0 m-0">
                      <div className="col-10 m-0 p-0">
                        <label className="my-0">{nombre}</label>
                      </div>
                      <div className="col-2 m-0 p-0">
                        <input
                          className={s.checkboxInput}
                          type="checkbox"
                          name={nombre}
                          checked={this.state.membershipsSelected[index]}
                          onChange={e => this.onSelectMembership(e, index)}
                          style={{ marginLeft: "0.75rem" }}
                        />
                      </div>
                    </div>
                  </div>
                )
              )}
          </div>
        </div>
        {this.re.mapFieldErrors("membresiasAplicables") &&
          this.re
            .mapFieldErrors("membresiasAplicables")
            .map((message, index) => {
              return (
                <span key={index} className={s.errorSpan}>
                  {message}
                  <br />
                </span>
              );
            })}
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(AddMembershipsToDocument));
