// TODO: Patron Redux en revisión de cargos en proceso
import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import { Col, Row, TabPane } from "reactstrap";
import TabFitKidzDiscountCodes from "./fitKidzDiscountCodesTable";
import TabFitKidzEvidences from "./fitKidzEvidenceTable";
import TabFitKidzMaintenanceChange from "./fitKidzMaintenanceChange";
import s from "./styles.scss";

interface Props {
  evidenceList: object[];
  fitKidzDiscountsCodesList: object[];
  idMembresiaSocio: string;
  getEvidences: () => any;
  getDiscountCodes: () => any;
  invalidateStateValue: (fieldName: string, value: any) => void;
  refreshPartner: () => void;
  esFitKidzPorCaducar: boolean;
}

interface State {}

class TabFitKidz extends React.Component<Props, State> {
  state = {};

  render() {
    return (
      <TabPane className="my-2" tabId="24">
        {!!this.props.esFitKidzPorCaducar && (
          <div>
            <Row>
              <Col>
                <TabFitKidzMaintenanceChange
                  idMembresiaSocio={this.props.idMembresiaSocio}
                  refreshPartner={this.props.refreshPartner}
                />
              </Col>
            </Row>
          </div>
        )}
        <div>
          <Row>
            <Col>
              <TabFitKidzEvidences
                idMembresiaSocio={this.props.idMembresiaSocio}
                evidenceList={this.props.evidenceList}
                invalidateStateValue={this.props.invalidateStateValue}
                getEvidences={this.props.getEvidences}
              />
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col>
              <TabFitKidzDiscountCodes
                idMembresiaSocio={this.props.idMembresiaSocio}
                fitKidzDiscountsCodesList={this.props.fitKidzDiscountsCodesList}
                invalidateStateValue={this.props.invalidateStateValue}
                getDiscountCodes={this.props.getDiscountCodes}
              />
            </Col>
          </Row>
        </div>
      </TabPane>
    );
  }
}

export default withStyles(s)(TabFitKidz);
