import React from "react";
import find from "lodash.find";
import { InputCellTable } from "../../../utils/InputCellTable";
import MexicanCurrency from "../../../../../../Home/components/common/currency";
import concat from "lodash.concat";
import { Container, Row, Col } from "reactstrap";
import ReactTable from "react-table";

const getEmptyTableData = () => [];

const convertGoalsToTableData = salesTracking => {
  return (
    salesTracking &&
    salesTracking.vendedores &&
    salesTracking.vendedores.map(sales => {
      return {
        nombreVendedor: sales.nombreVendedor.toUpperCase(),
        vendedorId: sales.vendedorId,
        periodos: sales.periodos.map(periodo => {
          return {
            compromisoId: periodo.compromisoId,
            periodoId: periodo.periodoId,
            numeroVentasCompromiso: periodo.numeroVentas.compromiso,
            numeroVentasAlcanzado: periodo.numeroVentas.alcanzado,
            numeroVentasPorcentajeAlcanzado:
              periodo.numeroVentas.porcentajeAlcanzado,
            totalVentasCompromiso: periodo.totalVentas.compromiso,
            totalVentasAlcanzado: periodo.totalVentas.alcanzado,
            totalVentasPorcentajeAlcanzado:
              periodo.totalVentas.porcentajeAlcanzado
          };
        })
      };
    })
  );
};

const getColumns = (salesTracking, updateValues, disabled = false) => {
  const sellerNameColumn = {
    Header: "Vendedores",
    id: "vendedores",
    accessor: "nombreVendedor",
    columns: null,
    width: 300
  };

  return concat(
    [sellerNameColumn],
    salesTracking.vendedores[0].periodos.map((periodo, index) => {
      return {
        Header: periodo.nombrePeriodo,
        id: periodo.periodoId,
        accessor: null,
        width: null,
        columns: [
          {
            Header: "# COMPROMISO",
            id: `ventas-compromiso-${periodo.periodoId}-compromiso`,
            accessor: row => {
              return (
                <InputCellTable
                  disabled={disabled}
                  value={row.periodos[index].numeroVentasCompromiso}
                  onChange={updateValues(index, row, "numeroVentasCompromiso")}
                />
              );
            },
            headerStyle: {
              fontSize: "11px"
            }
          },
          {
            Header: "# TOTALES",
            id: `ventas-totales-${periodo.periodoId}-totales`,
            accessor: `periodos[${index}].numeroVentasAlcanzado`,
            className: "text-center",
            headerStyle: {
              fontSize: "11px"
            }
          },
          {
            Header: "% ALCANZADO",
            id: `ventas-totales-${periodo.periodoId}-porcentaje-alcanzado`,
            accessor: `periodos[${index}].numeroVentasPorcentajeAlcanzado`,
            className: "text-center",
            headerStyle: {
              fontSize: "11px"
            }
          },
          {
            Header: "$ COMPROMISO",
            id: `ingreso-compromiso-${periodo.periodoId}-compromiso`,
            accessor: row => {
              return (
                <InputCellTable
                  disabled={disabled}
                  value={row.periodos[index].totalVentasCompromiso}
                  onChange={updateValues(index, row, "totalVentasCompromiso")}
                />
              );
            },
            headerStyle: {
              fontSize: "11px"
            }
          },
          {
            Header: "$ TOTALES",
            id: `ingreso-totales-${periodo.periodoId}-totales`,
            accessor: `periodos[${index}].totalVentasAlcanzado`,
            Cell: ({ value }) => <MexicanCurrency quantity={value} />,
            className: "text-center",
            headerStyle: {
              fontSize: "11px"
            }
          },
          {
            Header: "% ALCANZADO",
            id: `ingresos-totales-${periodo.periodoId}-porcentaje-alcanzado`,
            accessor: `periodos[${index}].totalVentasPorcentajeAlcanzado`,
            className: "text-center",
            headerStyle: {
              fontSize: "11px"
            }
          }
        ]
      };
    })
  );
};

export default ({
  salesTracking,
  loading,
  saveCompromisesBySeller,
  errorGettingData,
  disabled,
  setHasSellers
}) => {
  let tableData = getEmptyTableData();

  if (salesTracking) {
    tableData = convertGoalsToTableData(salesTracking);
  } else {
    tableData = getEmptyTableData();
  }

  const updateValue = (index, data, key) => value => {
    const newSalesTracking = { ...salesTracking };
    const seller = find(
      newSalesTracking.vendedores,
      ({ vendedorId }) => vendedorId == data.vendedorId
    );

    switch (key) {
      case "totalVentasCompromiso":
        seller.periodos[index].totalVentas.compromiso = value;
        break;
      case "numeroVentasCompromiso":
        seller.periodos[index].numeroVentas.compromiso = value;
        break;
      default:
        break;
    }

    saveCompromisesBySeller(newSalesTracking);
  };

  const hasSellers = salesTracking && !!(salesTracking.vendedores || []).length;

  setHasSellers(hasSellers);

  const columns = hasSellers
    ? getColumns(salesTracking, updateValue, disabled)
    : [];

  return (
    <Container className="mt-3 p-0">
      {hasSellers && (
        <Row>
          <Col>
            <ReactTable
              data={tableData}
              loading={loading}
              pageSize={tableData.length || 3}
              columns={columns}
              sortable={false}
              resizable={false}
              showPagination={false}
              className="-highlight -striped"
              getTrProps={() => ({ style: { height: "30px" } })}
              noDataText={errorGettingData ? "Error inesperado" : null}
            />
          </Col>
        </Row>
      )}
      {!hasSellers && (
        <div className="text-center">
          NO HAY VENDEDORES DEFINIDOS PARA EL CLUB
        </div>
      )}
    </Container>
  );
};
