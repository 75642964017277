import React from "react";
import find from "lodash.find";
import { InputCellTable } from "../../../utils/InputCellTable";
import concat from "lodash.concat";
import { Container, Row, Col } from "reactstrap";
import ReactTable from "react-table";

const getEmptyTableData = () => [];

const convertGoalsToTableData = salesTracking => {
  return salesTracking.vendedores.map(sales => {
    return {
      nombreVendedor: sales.nombreVendedor.toUpperCase(),
      vendedorId: sales.vendedorId,
      periodos: sales.periodos.map(periodo => {
        return {
          compromisoId: periodo.compromisoId,
          periodoId: periodo.periodoId,
          numeroProspectosCompromiso: periodo.numeroProspectos.compromiso,
          numeroProspectosAlcanzado: periodo.numeroProspectos.alcanzado,
          numeroProspectosPorcentajeAlcanzado:
            periodo.numeroProspectos.porcentajeAlcanzado
        };
      })
    };
  });
};

const getColumns = (salesTracking, updateValues, disabled = false) => {
  const sellerNameColumn = {
    Header: "Vendedores",
    id: "vendedores",
    accessor: "nombreVendedor",
    columns: null,
    width: 300
  };

  return concat(
    [sellerNameColumn],
    salesTracking.vendedores[0].periodos.map((periodo, index) => {
      return {
        Header: periodo.nombrePeriodo,
        id: periodo.periodoId,
        accessor: null,
        width: null,
        columns: [
          {
            Header: "# COMPROMISO",
            id: `prospectos-compromiso-${periodo.periodoId}-compromiso`,
            accessor: row => {
              return (
                <InputCellTable
                  disabled={disabled}
                  value={row.periodos[index].numeroProspectosCompromiso}
                  onChange={updateValues(index, row)}
                />
              );
            },
            headerStyle: {
              fontSize: "11px"
            }
          },
          {
            Header: "# TOTALES",
            id: `prospectos-totales-${periodo.periodoId}-totales`,
            accessor: `periodos[${index}].numeroProspectosAlcanzado`,
            className: "text-center",
            headerStyle: {
              fontSize: "11px"
            }
          },
          {
            Header: "% ALCANZADO",
            id: `prospectos-totales-${periodo.periodoId}-porcentaje-alcanzado`,
            accessor: `periodos[${index}].numeroProspectosPorcentajeAlcanzado`,
            className: "text-center",
            headerStyle: {
              fontSize: "11px"
            }
          }
        ]
      };
    })
  );
};

export default ({
  salesTracking,
  loading,
  saveCompromisesBySeller,
  errorGettingData,
  disabled
}) => {
  let tableData = getEmptyTableData();

  if (salesTracking) {
    tableData = convertGoalsToTableData(salesTracking);
  } else {
    tableData = getEmptyTableData();
  }

  const updateValue = (index, data) => value => {
    const newSalesTracking = { ...salesTracking };
    const seller = find(
      newSalesTracking.vendedores,
      ({ vendedorId }) => vendedorId == data.vendedorId
    );

    seller.periodos[index].numeroProspectos.compromiso = value;
    saveCompromisesBySeller(newSalesTracking);
  };

  const hasSellers = salesTracking && !!(salesTracking.vendedores || []).length;

  const columns = hasSellers
    ? getColumns(salesTracking, updateValue, disabled)
    : [];

  return (
    <Container className="mt-3 p-0">
      {hasSellers && (
        <Row>
          <Col>
            <ReactTable
              data={tableData}
              loading={loading}
              pageSize={tableData.length || 3}
              columns={columns}
              sortable={false}
              resizable={false}
              showPagination={false}
              className="-highlight -striped"
              getTrProps={() => ({ style: { height: "30px" } })}
              noDataText={errorGettingData ? "Error inesperado" : null}
            />
          </Col>
        </Row>
      )}
      {!hasSellers && (
        <div className="text-center">
          NO HAY VENDEDORES DEFINIDOS PARA EL CLUB
        </div>
      )}
    </Container>
  );
};
