import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Container, Col, Row, Button } from "reactstrap";
import s from "./styles.scss";
import { withRouter } from "react-router-dom";

interface Props {
  errorPath: string;
  error: string;
  info: string;
}

interface State {}

class ErrorDetail extends React.Component<Props, State> {
  state = {};

  goBack = () => {
    history.go(-2);
  };

  render() {
    const { errorPath, error, info } = this.props;
    return (
      <Container>
        <Row>
          <Col className="mt-4">
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.goBack}
            >
              Regresar
            </Button>
          </Col>
        </Row>
        <div className="row mt-3 mb-3">
          <div className="col">
            <p className={`${s.primaryTitle} w-100 m-0`}>Error Path</p>
          </div>
        </div>
        <Row>
          <Col> {errorPath} </Col>
        </Row>
        <div className="row mt-3 mb-3">
          <div className="col">
            <p className={`${s.primaryTitle} w-100 m-0`}>Error</p>
          </div>
        </div>
        <Row>
          <Col> {error} </Col>
        </Row>
        <div className="row mt-3 mb-3">
          <div className="col">
            <p className={`${s.primaryTitle} w-100 m-0`}>Info</p>
          </div>
        </div>
        <Row>
          <Col> {info} </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ErrorDetail));
