import React, { FunctionComponent } from "react"; // we need this to make JSX compile
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";

interface Props {
  disabled?: boolean;
  getFile: (e: React.FormEvent<HTMLInputElement>) => any;
}

const FileExportButton: FunctionComponent<Props> = ({ disabled, getFile }) => (
  <div className="text-center">
    <Button color="link" disabled={disabled} onClick={getFile}>
      <FontAwesomeIcon icon={faFileExport} color="black" />
    </Button>
  </div>
);
export default FileExportButton;
