import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faLink } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import GenericModal from "../../../Modals/components/GenericModal";
import BaseInput from "../../../toolkit/baseInput";
import { isArray } from "util";
import { STATUS_ACTIVITY } from "../../../utils/constants";
import isEmpty from "lodash.isempty";

const activitiesTitle = "Acciones a Realizar";

export default ({
  activities,
  loading,
  errorGettingData,
  markActivityRead,
  saveEvidence
}) => {
  const [evidences, setEvidences] = useState({});

  useEffect(() => {
    const evidences = {};

    if (isArray(activities)) {
      activities.forEach(({ evidencia, id }) => {
        const editable = isEmpty(evidencia);
        evidences[id] = {
          editable,
          evidence: evidencia
        };
      });
    }

    setEvidences(evidences);
  }, [activities]);

  const columns = [
    {
      Header: "Fecha Registro",
      accessor: "createdDate",
      className: "text-center",
      Cell: ({ value }) => moment(value).format("DD/MM/YYYY HH:mm"),
      width: 150
    },
    {
      Header: "Acción",
      accessor: "nota"
    },
    {
      Header: "Responsable",
      accessor: "assignedToData.username",
      width: 150
    },
    {
      Header: "Enterado",
      id: "enterado",
      className: "text-center",
      width: 70,
      accessor: ({ id, status }) => {
        const style = {
          cursor: "pointer",
          color: null
        };

        const enabled = status === STATUS_ACTIVITY.CREADA;
        if (enabled) {
          style.color = "red";
        } else {
          style.color = "#5dbb63";
          style.cursor = null;
        }

        return (
          <a
            id={`faTag${id}`}
            onClick={async () => {
              if (enabled) {
                markActivityRead(id);
              }
            }}
          >
            <FontAwesomeIcon icon={faCheck} style={style} />
          </a>
        );
      }
    },
    {
      Header: "Evidencia",
      id: "evidencia",
      width: 70,
      className: "text-center",
      style: {
        cursor: "pointer"
      },
      accessor: ({ id }) => {
        const evidenceEditable = evidences[id] && evidences[id].editable;
        const textEvidence = evidences[id] ? evidences[id].evidence : "";

        return (
          <GenericModal
            Button={({ onClick }) => (
              <a id={`faTag${id}`} onClick={onClick}>
                <FontAwesomeIcon icon={faLink} />
              </a>
            )}
            title="Evidencia"
          >
            <Container>
              <Row>
                <Col>
                  {evidenceEditable && (
                    <BaseInput
                      label="Evidencia"
                      name="evidencia"
                      type="text"
                      id="evidencia"
                      placeholder="Evidencia"
                      value={textEvidence}
                      onChange={event => {
                        const value = event.target.value;
                        const newEvidences = {
                          ...evidences,
                          [id]: {
                            ...evidences[id],
                            evidence: value
                          }
                        };
                        setEvidences(newEvidences);
                      }}
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    className="primaryButton"
                    onClick={async e => {
                      e.preventDefault();
                      if (evidenceEditable) {
                        await saveEvidence(id, textEvidence);
                        const newEvidences = {
                          ...evidences,
                          [id]: {
                            ...evidences[id],
                            editable: false
                          }
                        };
                        setEvidences(newEvidences);
                      } else {
                        window.open(textEvidence, "_blank");
                      }
                    }}
                    size="sm"
                    block
                  >
                    {evidenceEditable
                      ? "Guardar Evidencia"
                      : "Mostrar Evidencia"}
                  </Button>
                </Col>
              </Row>
            </Container>
          </GenericModal>
        );
      }
    }
  ];

  return (
    <Container className="mt-3 p-0">
      <Row>
        <Col>
          <p className="primaryTitle w-100">{activitiesTitle}</p>
          <ReactTable
            data={activities}
            loading={loading}
            pageSize={activities.length || 3}
            columns={columns}
            sortable={false}
            resizable={false}
            showPagination={false}
            className="-highlight -striped"
            getTrProps={() => ({ style: { height: "30px" } })}
            noDataText={errorGettingData ? "Error inesperado" : null}
          />
        </Col>
      </Row>
    </Container>
  );
};
