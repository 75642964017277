import React from "react";
import { RouterProps } from "../../../../toolkit/decorators/Artifact";
import FormBuilder from "../../../../toolkit/baseForms2/formBuilder/FormBuilder";
import { formConfig } from "./EmpresaConvenioBasicData.form";
import { EmpresaConvenioCarouselDispatcher } from "../../../containers/empresaConvenioCarousel/EmpresaConvenioCarousel.dispatcher";
import { EmpresaConvenioCarouselStore } from "../../../containers/empresaConvenioCarousel/EmpresaConvenioCarousel.store";
import {
  successNotification,
  errorNotification
} from "../../../../utils/notifications";
import IButtonBuilder from "../../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import { Container, Row } from "reactstrap";
import ITypeSelectBuilder from "../../../../toolkit/baseForms2/iTypeSelectBuilder/ITypeSelectBuilder";

interface Props
  extends EmpresaConvenioCarouselDispatcher,
    EmpresaConvenioCarouselStore,
    RouterProps<{
      id: string;
    }> {
  id: number;
  handleDirty: (dirty: boolean, dirtyMsg: string) => void;
  handleParams: (id: number) => void;
}
interface State {
  init: any;
}

export default class EmpresaConvenioBasicDataStep extends React.Component<
  Props,
  State
> {
  state = {
    init: null
  };

  async componentDidMount() {
    if (
      !this.props.gettingUsers &&
      (!this.props.usersList || this.props.usersList.length == 0)
    ) {
      this.props.getUsers();
    }

    if (this.props.id) {
      await this.loadCompany();
    }
  }

  async componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.id != prevProps.id) {
      await this.loadCompany();
    }
  }

  loadCompany = async () => {
    const preview = await this.props.getCompany(this.props.id);
    if (preview.fulfilled) {
      this.setState({
        init: { ...preview.empresaConvenio }
      });
    } else {
      errorNotification("No se pudieron cargar los datos de la empresa");
    }
  };

  submit = async fields => {
    if (!this.props.id) {
      // Creación de la empresa
      const preview = await this.props.createCompany(fields);
      if (preview.fulfilled) {
        const id = +preview.empresaConvenioCreated.id;
        this.props.handleParams(id);
        this.setState({ init: { ...preview.empresaConvenioCreated } });
        successNotification("Empresa creada correctamente");
      } else {
        errorNotification("No se pudo crear la empresa");
      }
    } else {
      // Actualización de la empresa
      const preview = await this.props.updateCompany({
        id: this.props.id,
        ...fields
      });
      preview.fulfilled
        ? successNotification("Empresa actualizada correctamente")
        : errorNotification("No se pudo actualizar la empresa");
      preview.fulfilled &&
        this.setState({ init: { ...preview.empresaConvenioUpdated } });
    }
  };

  regresar = () => {
    this.props.history.push("/empresas-convenios");
  };

  render() {
    return (
      <Container>
        <Row>
          <p className="primaryTitle w-100">Datos básicos</p>
        </Row>
        <FormBuilder
          config={formConfig}
          init={this.state.init}
          submit={this.submit}
          processing={
            this.props.updatingEmpresaConvenio ||
            this.props.creatingEmpresaConvenio
          }
          loading={this.props.gettingEmpresaConvenio}
        >
          <ITypeSelectBuilder
            name="responsable"
            data={this.props.usersList}
            isLoading={this.props.gettingUsers}
            mapOption={item => ({
              value: item.userId,
              label: `${item.name || ""} ${item.firstSurname ||
                ""} ${item.secondSurname || ""} `.trim()
            })}
          />
          <IButtonBuilder name="regresar" onClick={this.regresar} />
        </FormBuilder>
      </Container>
    );
  }
}
