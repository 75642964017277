import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import {
  GET_DISCOUT_CODES_LIST_PENDING,
  GET_DISCOUT_CODES_LIST_REJECTED,
  CREATE_DISCOUT_CODES_PENDING,
  CREATE_DISCOUT_CODES_FULFILLED,
  CREATE_DISCOUT_CODES_REJECTED,
  infoNotification,
  errorNotification,
  successNotification,
  DOWNLOAD_FILE_WAITING,
  DOWNLOAD_FILE_ERROR,
  DOWNLOAD_FILE,
  ACTIVATE_DAY_PASS_PENDING,
  ACTIVATE_DAY_PASS_REJECTED,
  ACTIVATE_DAY_PASS_FULFILLED,
  GET_SINGLE_CODE_PENDING,
  GET_SINGLE_CODE_FULFILLED,
  GET_SINGLE_CODE_REJECTED,
  ERROR
} from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";
interface State {
  dayPassList: object;
  gettingDayPassList: boolean;
  sendingData: boolean;
  gettingData: boolean;
  gettingList: boolean;
  dataResponse: object;
  successSend: boolean;
  gettingCatalogs: boolean;
  billingCatalogs: object;
  discountList: object;
  fileLoaded: boolean;
  passDayData: object;
  successActivatedPassDay: boolean;
  activatingPassDay: boolean;
  gettingLteList: boolean;
  dataLteList: boolean;
  gettingLteListMembrsh: boolean;
  dataLteListMembrsh: object;
  gettingSingleCode: boolean;
  dataSingleCode: object;
}
const initialState: State = {
  dayPassList: null,
  gettingDayPassList: false,
  sendingData: false,
  gettingData: false,
  gettingList: false,
  dataResponse: null,
  successSend: false,
  billingCatalogs: null,
  gettingCatalogs: false,
  discountList: null,
  fileLoaded: false,
  passDayData: null,
  successActivatedPassDay: false,
  activatingPassDay: false,
  gettingLteList: false,
  dataLteList: null,
  gettingLteListMembrsh: false,
  dataLteListMembrsh: null,
  gettingSingleCode: false,
  dataSingleCode: null
};

export default function billing(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.CREATE_DISCOUT_CODES}_PENDING`: {
      infoNotification(CREATE_DISCOUT_CODES_PENDING);
      return {
        ...state,
        sendingData: true,
        successSend: false
      };
    }
    case `${actionTypes.CREATE_DISCOUT_CODES}_REJECTED`: {
      errorNotification(readMessageBack(action, CREATE_DISCOUT_CODES_REJECTED));
      return {
        ...state,
        sendingData: false,
        successSend: false
      };
    }
    case `${actionTypes.CREATE_DISCOUT_CODES}_FULFILLED`: {
      successNotification(CREATE_DISCOUT_CODES_FULFILLED);
      return {
        ...state,
        dataResponse: action.payload.data,
        sendingData: false,
        successSend: true
      };
    }
    case `${actionTypes.GET_DISCOUT_CODES_LIST}_PENDING`: {
      return {
        ...state,
        discountList: null,
        gettingList: true
      };
    }
    case `${actionTypes.GET_DISCOUT_CODES_LIST}_REJECTED`: {
      errorNotification(
        readMessageBack(action, GET_DISCOUT_CODES_LIST_REJECTED)
      );
      return {
        ...state,
        discountList: null,
        gettingList: false
      };
    }
    case `${actionTypes.GET_DISCOUT_CODES_LIST}_FULFILLED`: {
      return {
        ...state,
        discountList: action.payload.data,
        gettingList: false
      };
    }

    case `${actionTypes.GET_DISCOUNT_CODES_LIST_FILE}_PENDING`: {
      infoNotification(DOWNLOAD_FILE_WAITING);
      return {
        ...state,
        fileLoaded: false
      };
    }
    case `${actionTypes.GET_DISCOUNT_CODES_LIST_FILE}_REJECTED`: {
      errorNotification(readMessageBack(action, DOWNLOAD_FILE_ERROR));
      return {
        ...state,
        fileLoaded: false
      };
    }
    case `${actionTypes.GET_DISCOUNT_CODES_LIST_FILE}_FULFILLED`: {
      successNotification(DOWNLOAD_FILE);
      return {
        ...state,
        fileLoaded: true
      };
    }

    case `${actionTypes.GET_PASS_DAY_LIST}_PENDING`: {
      return {
        ...state,
        gettingDayPassList: true
      };
    }
    case `${actionTypes.GET_PASS_DAY_LIST}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        gettingDayPassList: false
      };
    }
    case `${actionTypes.GET_PASS_DAY_LIST}_FULFILLED`: {
      return {
        ...state,
        dayPassList: action.payload.data,
        gettingDayPassList: false
      };
    }

    case `${actionTypes.ACTIVATE_DAY_PASS}_PENDING`: {
      infoNotification(ACTIVATE_DAY_PASS_PENDING);
      return {
        ...state,
        activatingPassDay: true
      };
    }
    case `${actionTypes.ACTIVATE_DAY_PASS}_REJECTED`: {
      errorNotification(readMessageBack(action, ACTIVATE_DAY_PASS_REJECTED));
      return {
        ...state,
        activatingPassDay: false,
        successActivatedPassDay: false
      };
    }
    case `${actionTypes.ACTIVATE_DAY_PASS}_FULFILLED`: {
      successNotification(ACTIVATE_DAY_PASS_FULFILLED);
      return {
        ...state,
        passDayData: action.payload.data,
        activatingPassDay: false,
        successActivatedPassDay: true
      };
    }

    case `${actionTypes.GET_DISCOUNT_CODES_LTE}_PENDING`: {
      infoNotification(GET_DISCOUT_CODES_LIST_PENDING);
      return {
        ...state,
        gettingLteList: true,
        discountList: null
      };
    }
    case `${actionTypes.GET_DISCOUNT_CODES_LTE}_REJECTED`: {
      errorNotification(
        readMessageBack(action, GET_DISCOUT_CODES_LIST_REJECTED)
      );
      return {
        ...state,
        gettingLteList: false,
        discountList: null
      };
    }
    case `${actionTypes.GET_DISCOUNT_CODES_LTE}_FULFILLED`: {
      // successNotification(DOWNLOAD_FILE);
      return {
        ...state,
        gettingLteList: false,
        dataLteList: action.payload.data,
        discountList: null
      };
    }

    case `${actionTypes.GET_DISCOUNT_CODES_LTE_MEMBRSH}_PENDING`: {
      return {
        ...state,
        gettingLteListMembrsh: true,
        dataSingleCode: null
      };
    }
    case `${actionTypes.GET_DISCOUNT_CODES_LTE_MEMBRSH}_REJECTED`: {
      errorNotification(
        readMessageBack(action, GET_DISCOUT_CODES_LIST_REJECTED)
      );
      return {
        ...state,
        gettingLteListMembrsh: false
      };
    }
    case `${actionTypes.GET_DISCOUNT_CODES_LTE_MEMBRSH}_FULFILLED`: {
      return {
        ...state,
        gettingLteListMembrsh: false,
        dataLteListMembrsh: action.payload.data
      };
    }

    case `${actionTypes.GET_SINGLE_CODE}_PENDING`: {
      infoNotification(GET_SINGLE_CODE_PENDING);
      return {
        ...state,
        gettingSingleCode: true
      };
    }
    case `${actionTypes.GET_SINGLE_CODE}_REJECTED`: {
      errorNotification(readMessageBack(action, GET_SINGLE_CODE_REJECTED));
      return {
        ...state,
        gettingSingleCode: false
      };
    }
    case `${actionTypes.GET_SINGLE_CODE}_FULFILLED`: {
      successNotification(GET_SINGLE_CODE_FULFILLED);
      return {
        ...state,
        gettingSingleCode: false,
        dataSingleCode: action.payload.data,
        discountList: null
      };
    }

    case `${actionTypes.CLEAR_SINGLE_CODE}`: {
      return {
        ...state,
        gettingSingleCode: false,
        dataSingleCode: null
      };
    }

    case `${actionTypes.CLEAR_DISCOUT_CODES}`: {
      return {
        ...state,
        dataResponse: null,
        sendingData: false,
        successSend: false,
        discountList: null,
        gettingDayPassList: false,
        dayPassList: null,
        passDayData: null,
        activatingPassDay: false,
        successActivatedPassDay: false,
        dataSingleCode: null
      };
    }

    default: {
      return state;
    }
  }
}

export { initialState };
