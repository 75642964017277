import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import { Nav, NavLink, NavItem, TabContent } from "reactstrap";
import s from "./index.scss";
import { Container } from "reactstrap";
import TabMaintenances from "./tabMaintenances";
import TabMemberships from "./tabMemberships";
import TabSportsProducts from "./tabSportsProducts";

interface Props {
  tiposPrecio: TipoPrecio[];
  authorizePricesMaintenances: (prices: object[]) => any;
  maintenances: object[];
  getPricesToMaintenance: (params: object) => any;
  getPricesToSportsProducts: (params: object) => any;
  authorizePricesMassive: (prices: object[]) => any;
  sportsProducts: { id: string; nombre: string }[];
  pricesToProduct: object[];
  loadingPricesToProduct: boolean;
  pricesMaintenances: object[];
  loadingMaintenances: boolean;
  conceptos: object[];
  clubs: object[];
}

interface TipoPrecio {
  esquemaPagoId: string;
  nombre: string;
}

class ProductsAuthorization extends React.Component<Props> {
  state = {
    activeTab: "1"
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    return (
      <Container className="p-0 mt-3 w-0">
        <Nav className={s.tabs} tabs>
          <NavItem>
            <NavLink
              className={`${this.state.activeTab === "1" ? "active" : ""} ${
                s.cursorPointer
              }`}
              onClick={() => {
                this.toggleTab("1");
              }}
            >
              Mantenimientos
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${this.state.activeTab === "2" ? "active" : ""} ${
                s.cursorPointer
              }`}
              onClick={() => {
                this.toggleTab("2");
              }}
            >
              Membresias
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${this.state.activeTab === "3" ? "active" : ""} ${
                s.cursorPointer
              }`}
              onClick={() => {
                this.toggleTab("3");
              }}
            >
              Productos
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent
          style={{ height: "615px" }}
          activeTab={this.state.activeTab}
        >
          <div className="p-0 m-0 w-100 h-100">
            {this.state.activeTab === "1" && (
              <TabMaintenances
                getPricesToMaintenance={this.props.getPricesToMaintenance}
                authorizePricesMaintenances={
                  this.props.authorizePricesMaintenances
                }
                maintenances={this.props.maintenances}
                prices={this.props.pricesMaintenances}
                loading={this.props.loadingMaintenances}
                conceptos={this.props.conceptos}
                clubs={this.props.clubs}
              />
            )}
            {this.state.activeTab === "2" && <TabMemberships />}
            {this.state.activeTab === "3" && (
              <TabSportsProducts
                sportsProducts={this.props.sportsProducts}
                getPricesToSportsProducts={this.props.getPricesToSportsProducts}
                authorizePricesMassive={this.props.authorizePricesMassive}
                prices={this.props.pricesToProduct}
                tiposPrecio={this.props.tiposPrecio}
                loading={this.props.loadingPricesToProduct}
              />
            )}
          </div>
        </TabContent>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(ProductsAuthorization));
