import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import QuizzesSection from "../../components/quizzesSection";
import {
  addQuiz,
  getQuizzes,
  getQuizzesQuestionsById,
  getQuizzCategoriesRequest
} from "../../actions/products";
import IAction from "../../../types/IAction";

interface Props {
  addQuiz: (data: object) => IAction;
  getQuizzes: (data: object) => IAction;
  getQuizzesQuestionsById: (id: number) => IAction;
  listQuizzes: object;
  listQuestionsQuizzes: object;
}

interface State {
  quizzCategories: { id: number; nombre: string; activa: boolean }[];
}

class Quizzes extends React.Component<Props, State> {
  state = {
    quizzCategories: []
  };

  getQuizzcategories = () => {
    getQuizzCategoriesRequest().then(({ data }) => {
      this.setState({ quizzCategories: data });
    });
  };

  componentDidMount() {
    this.getQuizzcategories();
  }

  render() {
    return (
      <main>
        <Helmet title="Quizzes" />
        <Layout>
          <QuizzesSection
            addQuiz={this.props.addQuiz}
            getQuizzes={this.props.getQuizzes}
            getQuizzesQuestionsById={this.props.getQuizzesQuestionsById}
            quizzCategories={this.state.quizzCategories}
            listQuizzes={this.props.listQuizzes}
            listQuestionsQuizzes={this.props.listQuestionsQuizzes}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addQuiz: (data: object) => {
    dispatch(addQuiz(data));
  },
  getQuizzes: (data: string) => {
    dispatch(getQuizzes(data));
  },
  getQuizzesQuestionsById: (id: number) => {
    dispatch(getQuizzesQuestionsById(id));
  }
});

const mapStateToProps = ({ products }) => {
  return {
    listQuizzes: products.listQuizzes,
    listQuestionsQuizzes: products.listQuestionsQuizzes
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Quizzes);
