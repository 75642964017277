import axios from "axios";
import constants from "../../utils/constants";

export const getLocationByPostalCode = postalCode => {
  return axios
    .get(
      `${constants.BASE_URL}/api/address/locationByPostalCode/${postalCode}`,
      {
        withCredentials: true
      }
    )
    .then(response => {
      return response.data;
    })
    .catch(_ => {
      alert("Ocurrio un error, verifica el código Postal");
    });
};
