import React from "react";
import s from "./styles.scss";
import { Styled } from "../../../toolkit/decorators/Artifact";
import ClubSelectList from "../../containers/clubSelectList";
import {
  Row,
  Col,
  UncontrolledTooltip,
  Button,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import { FormErrors } from "@franleplant/reform/types";
import { errorNotification } from "../../../utils/notifications";
import DatePicker from "../common/DatePicker";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

// Interfaz para validación de formularios con Reform
interface Fields {
  club: any;
  idPublico: string;
  fechaInicio: any;
  fechaFin: any;
  ignorarAut: boolean;
}

interface FiltersMap {
  idClub: number;
  idPublico: number;
  fechaInicio: any;
  fechaFin: any;
  ignorarAut: boolean;
}

// Propiedades y estado del componente
interface Props {
  submit: (evt: FiltersMap) => void;
  userContext: any;
  loading: boolean;
}

interface State {
  // Propiedades requeridas por Reform, para validación de formularios
  fields: Fields;
  errors: FormErrors;
}

@Styled(s)
export default class ExpedienteValidationFilters extends React.Component<
  Props,
  State
> {
  emptyAntiguedad = { value: "", label: "Seleccione" };
  state = {
    fields: {
      club: null,
      fechaInicio: null,
      fechaFin: null,
      ignorarAut: true
    } as Fields,
    errors: {}
  };

  submit = evt => {
    evt.preventDefault();
    if (!this.state.fields.club || !this.state.fields.club.value) {
      errorNotification("Debe seleccionar el club de búsqueda");
      return;
    }

    if (
      !this.state.fields.idPublico &&
      (!this.state.fields.fechaInicio || !this.state.fields.fechaFin)
    ) {
      errorNotification(
        "Debe seleccionar la fecha de inicio y fin, o el número de membresía"
      );
      return;
    }

    const filters = {
      idClub: Number(this.state.fields.club.value) || 0,
      idPublico: Number(this.state.fields.idPublico) || 0,
      fechaInicio: this.state.fields.fechaInicio,
      fechaFin: this.state.fields.fechaFin,
      ignorarAut: this.state.fields.ignorarAut
    };

    // Limpieza de datos
    this.props.submit(filters);
  };

  onChangeDate = fieldName => {
    return event => {
      const date = event ? moment(event).format("YYYY-MM-DD") : null;

      this.setState({
        fields: {
          ...this.state.fields,
          [fieldName]: date
        }
      });
    };
  };

  render() {
    return (
      <>
        {/* Filtros */}
        <form onSubmit={this.submit} autoComplete="off">
          <Row className="mt-4">
            {/* Selector de Club */}
            <Col md={2}>
              <ClubSelectList
                clubSelected={this.state.fields.club}
                onSelectClub={club =>
                  this.setState({ fields: { ...this.state.fields, club } })
                }
                label="* Club"
                userClub={this.props.userContext.clubSessionId}
              />
            </Col>

            {/* Membresía */}
            <Col md={2}>
              <BaseInput
                label={"Membresía"}
                name={"idMembresiaSocio"}
                id={"idMembresiaSocio"}
                placeholder="Membresía"
                value={this.state.fields.idPublico}
                size="sm"
                onChange={e => {
                  const idPublico = e.target.value;
                  this.setState({
                    fields: { ...this.state.fields, idPublico }
                  });
                }}
              />
            </Col>

            {/* Fecha de inicio */}
            <Col xs={12} md={2} lg={2}>
              <div
                style={{ display: "inline-block", width: "calc(100% - 24px)" }}
              >
                <DatePicker
                  label="Fecha inicio"
                  name={"fechaInicio"}
                  id={"fechaInicio"}
                  selected={this.state.fields["fechaInicio"]}
                  onChange={this.onChangeDate("fechaInicio")}
                />
              </div>
              <UncontrolledTooltip placement="top" target="fechaInicioBorrar">
                Borrar fecha
              </UncontrolledTooltip>
              <div
                id="fechaInicioBorrar"
                onClick={() => this.onChangeDate("fechaInicio")(null)}
                style={{
                  cursor: "pointer",
                  textAlign: "right",
                  display: "inline-block",
                  width: "24px"
                }}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </div>
            </Col>

            {/* Fecha de fin */}
            <Col xs={12} md={2} lg={2}>
              <div
                style={{ display: "inline-block", width: "calc(100% - 24px)" }}
              >
                <DatePicker
                  label="Fecha fin"
                  name={"fechaFin"}
                  id={"fechaFin"}
                  selected={this.state.fields["fechaFin"]}
                  onChange={this.onChangeDate("fechaFin")}
                />
              </div>
              <UncontrolledTooltip placement="top" target="fechaFinBorrar">
                Borrar fecha
              </UncontrolledTooltip>
              <div
                id="fechaFinBorrar"
                onClick={() => this.onChangeDate("fechaFin")(null)}
                style={{
                  cursor: "pointer",
                  textAlign: "right",
                  display: "inline-block",
                  width: "24px"
                }}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </div>
            </Col>

            {/* Indica que se deben eliminar los autorizados */}
            <Col md={2}>
              <div className="mt-4">
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={this.state.fields.ignorarAut}
                      onChange={e => {
                        const ignorarAut = e.target.checked;
                        this.setState({
                          fields: {
                            ...this.state.fields,
                            ignorarAut
                          }
                        });
                      }}
                    />
                    Ignorar
                    <br />
                    autorizados
                  </Label>
                </FormGroup>
              </div>
            </Col>

            <Col md={2} className="p-0">
              <Button
                className={`${s.primaryButton} mt-4`}
                type="submit"
                size="sm"
                block
                disabled={this.props.loading}
              >
                {this.props.loading ? "Buscando..." : "Buscar"}
              </Button>
            </Col>
          </Row>
        </form>
      </>
    );
  }
}
