import React from "react";
import { connect } from "react-redux";
import { toggleAddPersonModal } from "../../../Home/actions/modals";
import BaseInput from "../../../toolkit/baseInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserPlus,
  faUser,
  faPencilAlt
} from "@fortawesome/free-solid-svg-icons";
import isNil from "lodash.isnil";

export interface Person {
  personaId: number;
  nombre: string;
  primerApellido: string;
  segundoApellido: string;
  fechaNacimiento?: string;
  tipo?: string;
}

interface Props {
  toggleAddPersonModal: (controlKey: String) => any;
  selectedPerson: Person;
  personCreated: Person;
  initPerson: Person;
  setPerson: (person: Person) => any;
  label: string;
  disabled: boolean;
  onlyClientMode: boolean;
  showEditButton: boolean;
  cKey: String;
  controlKey: String;
}

class PersonSelector extends React.Component<Props> {
  state = {
    person: this.props.initPerson
  };

  componentDidUpdate(prevProps) {
    const {
      selectedPerson: prevSelectedPerson,
      personCreated: prevPersonCreated,
      controlKey: prevControlKey
    } = prevProps;

    if (prevProps.initPerson !== this.props.initPerson) {
      this.setState({
        person: this.props.initPerson
      });
    } else {
      if (prevSelectedPerson !== this.props.selectedPerson) {
        this.setState({ person: this.props.selectedPerson });
        this.props.setPerson(this.props.selectedPerson);
      }

      if (
        prevPersonCreated !== this.props.personCreated &&
        this.props.personCreated
      ) {
        this.setState({ person: this.props.personCreated });
        this.props.setPerson(this.props.personCreated);
      }
    }

    if (prevProps.controlKey != prevProps.cKey) {
      return;
    }
  }

  getPersonName = () => {
    if (this.state.person) {
      const { nombre, primerApellido, segundoApellido } = this.state.person;
      return `${nombre}${primerApellido ? ` ${primerApellido}` : ""}${
        segundoApellido ? ` ${segundoApellido}` : ""
      }`;
    } else {
      return "";
    }
  };

  openToEdit = () => {
    if (!!this.state.person) {
      const { tipo, personaId } = this.state.person;
      let url = null;
      switch (tipo) {
        case "CLIENTE_TITULAR":
        case "CLIENTE_INTEGRANTE":
        case "CLIENTE":
          url = `/socio/${personaId}/detalle`;
          break;
        case "PROSPECTO":
          url = `/prospecto/${personaId}/detalle`;
          break;
        case "CONCESIONARIO":
          url = `/concesionarios/${personaId}/detalle`;
          break;
        case "CORPORATIVO":
          url = `/corporativos/${personaId}/detalle`;
          break;
        case "EXTERNO":
          url = `/externo/${personaId}/detalle`;
          break;
        case "EMPLEADO":
          url = `/empleado/${personaId}/detalle`;
          break;
      }

      if (!!url) {
        window.open(url, "_blank");
      }
    }
  };

  render() {
    return (
      <div className="row p-0 m-0">
        <div className="col p-0 m-0">
          <BaseInput
            label={this.props.label || "Persona"}
            name="personSportsProductQuotation"
            type="input"
            id={"personSportsProductQuotation"}
            placeholder="Seleccione la Persona"
            value={this.getPersonName()}
            disabled
            onChange={() => {}}
          />
        </div>
        <div className="col-1 p-1">
          <span
            onClick={() =>
              !this.props.disabled &&
              this.props.toggleAddPersonModal(this.props.cKey)
            }
            style={{
              position: "absolute",
              top: "30px",
              cursor: "pointer",
              left: "20px",
              color: this.props.disabled ? "gray" : "black"
            }}
          >
            {!this.props.onlyClientMode && (
              <FontAwesomeIcon icon={faUserPlus} />
            )}
            {this.props.onlyClientMode && <FontAwesomeIcon icon={faUser} />}
          </span>
        </div>
        {!!this.props.showEditButton &&
          !!this.state.person &&
          !!this.state.person.tipo && (
            <div className="col-1 p-1">
              <span
                onClick={() => !this.props.disabled && this.openToEdit()}
                style={{
                  position: "absolute",
                  top: "30px",
                  cursor: "pointer",
                  left: "10px",
                  color: this.props.disabled ? "gray" : "black"
                }}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </span>
            </div>
          )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  toggleAddPersonModal: (controlKey: String) => {
    dispatch(toggleAddPersonModal(controlKey));
  }
});

const mapStateToProps = state => {
  const {
    persons: { selectedPerson, personCreated },
    modals
  } = state;
  return {
    selectedPerson,
    personCreated,
    controlKey: modals.controlKey
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonSelector);
