import React from "react";
import { connect } from "react-redux";
import Layout from "../../components/layout";
import { Helmet } from "react-helmet";
import ComissionsSchemas from "../../components/comissionsSchemas";
import {
  getIndicators,
  saveComissionsSchemas,
  getSchemaById,
  cleanSchemaById
} from "../../actions/comissionsSchemas";

interface Props {
  getIndicators: () => any;
  indicators: object[];
  saveComissionsSchemas: (schema: object) => any;
  getSchemaById: (id: string) => any;
  schemaById: object;
  cleanSchemaById: () => any;
}

class BenefitsSchemaPage extends React.Component<Props> {
  componentDidMount() {
    this.props.getIndicators();
  }

  componentWillUnmount() {
    this.props.cleanSchemaById();
  }

  render() {
    return (
      <main>
        <Helmet title="Creacion de Esquemas de Comisiones" />
        <Layout>
          {this.props.indicators && (
            <ComissionsSchemas
              saveComissionsSchemas={this.props.saveComissionsSchemas}
              indicators={this.props.indicators}
              getSchemaById={this.props.getSchemaById}
              schemaById={this.props.schemaById}
            />
          )}
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getIndicators: () => {
    return dispatch(getIndicators());
  },
  getSchemaById: (id: string) => {
    return dispatch(getSchemaById(id));
  },
  saveComissionsSchemas: (schema: object) => {
    return dispatch(saveComissionsSchemas(schema));
  },
  cleanSchemaById: () => {
    return dispatch(cleanSchemaById());
  }
});

const mapStateToProps = state => {
  const {
    benefitsSchemas: { indicators, schemaById }
  } = state;
  return {
    indicators,
    schemaById
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BenefitsSchemaPage);
