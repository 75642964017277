import React from "react";
import BaseInput from "../../../toolkit/baseInput";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  Button
} from "reactstrap";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./stylesComponents.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faSave,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import BaseNumericInput from "../../../toolkit/baseNumericInput";

const OFICINA = "OFICINA";
const PARTICULAR = "PARTICULAR";
const CELULAR = "CELULAR";

interface Telefono {
  numero: string;
  tipo: string;
  principal: boolean;
  id: string;
}

interface Props {
  viewMode: boolean;
  data: Telefono[];
  onAddTelefono: () => void;
  onDeleteTelefono: (index: string) => void;
  onChangeTelefono: (name: string, value: string, index: string) => void;
  onChangePrincipalTelefono: (index) => void;
  saveTelefono: (index: number) => void;
  errors: any;
}

class Telefonos extends React.Component<Props> {
  renderTelefonos = () => {
    let data = this.props.data.map((telefono, index) => {
      const disableSave = !telefono.numero || !telefono.tipo;
      const disableDelete = !telefono.id;
      return (
        <Card key={index + "-telefono"} className={s.card}>
          <CardBody className={`p-1 ${s.cardBody}`}>
            <Row>
              <Col>
                <BaseNumericInput
                  label={"Número (10 dígitos)"}
                  name={`numero_${index}`}
                  id={`numero_${index}`}
                  placeholder={"Número"}
                  value={telefono.numero}
                  onChange={e => this.handleChangeTelefono("numero", index, e)}
                  maxlength={"10"}
                  disabled={!!this.props.viewMode}
                />
              </Col>
              <Col>
                <BaseInput
                  label={"Tipo"}
                  name={`tipo_${index}`}
                  type="select"
                  options={this.typeNumberOptions}
                  id={`tipo_${index}`}
                  placeholder={"Tipo"}
                  value={telefono.tipo}
                  onChange={e => this.handleChangeTelefono("tipo", index, e)}
                  disabled={!!this.props.viewMode}
                />
              </Col>
              <Col className={s.centerRow} xs={1}>
                <FormGroup className={s.label} check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={telefono.principal}
                      onChange={() =>
                        this.props.onChangePrincipalTelefono(index)
                      }
                      disabled={!!this.props.viewMode}
                    />
                    {"Principal"}
                  </Label>
                </FormGroup>
              </Col>
              {!this.props.viewMode && (
                <Col xs={1}>
                  <div className={`float-right mx-1 mt-4 ${s.deleteRow}`}>
                    {(telefono.numero || "").length >= 10 && !!telefono.tipo && (
                      <a
                        onClick={e => {
                          e.preventDefault();
                          if (!disableSave) this.props.saveTelefono(index);
                        }}
                        style={{
                          color: disableSave ? "gray" : "black"
                        }}
                      >
                        <FontAwesomeIcon icon={faSave} />
                      </a>
                    )}
                  </div>
                  <div className={`float-right mx-1 mt-4 ${s.deleteRow}`}>
                    <a
                      style={{
                        color: disableDelete ? "gray" : "black"
                      }}
                      onClick={e =>
                        !disableDelete && this.deleteTelefono(e, index)
                      }
                    >
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </a>
                  </div>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      );
    });
    return data;
  };

  handleChangeTelefono = (name, index, e) => {
    const value = e.target.value;
    this.props.onChangeTelefono(name, value, index);
  };

  handleAddTelefono = e => {
    e.preventDefault();
    this.props.onAddTelefono();
  };

  deleteTelefono = (e, index) => {
    e.preventDefault();
    this.props.onDeleteTelefono(index);
  };

  typeNumberOptions = [
    { value: OFICINA, label: "OFICINA" },
    { value: PARTICULAR, label: "PARTICULAR" },
    { value: CELULAR, label: "MÓVIL" }
  ];

  render() {
    return (
      <div>
        <Row className="primaryTitle m-0 mt-3">
          <Col>
            <p className="m-0">Teléfonos</p>
            {this.props.errors &&
              this.props.errors.map((message, index) => {
                return (
                  <span key={index} className={s.errorSpan}>
                    {message}
                    <br />
                  </span>
                );
              })}
          </Col>
          <Col xs={3} className="text-right">
            {!this.props.viewMode && (
              <Button
                className="btn btn-light btn-sm"
                onClick={e => this.handleAddTelefono(e)}
              >
                Agregar Télefono
                <FontAwesomeIcon className="ml-2" icon={faPlus} />
              </Button>
            )}
          </Col>
        </Row>
        {this.renderTelefonos()}
      </div>
    );
  }
}

export default withRouter(withStyles(s)(Telefonos));
