import React from "react";
import { connect } from "react-redux";
import { getAccountingAccounts } from "../../actions/catalogs";
import SelectCuenta from "../../components/selectCuenta";

interface CuentaContable {
  cuentaContableId: string;
  nombre: string;
  numeroCuenta: string;
}

interface Props {
  accountingAccounts: CuentaContable[];
  getAccountingAccounts: () => any;
  value: string;
  onChange: (e) => any;
  disabled: boolean;
  gettingAccountingAccounts: boolean;
  size: string;
}

class RolesUserPage extends React.Component<Props> {
  componentDidMount() {
    if (!this.props.accountingAccounts && !this.props.gettingAccountingAccounts)
      this.props.getAccountingAccounts();
  }

  render() {
    const { accountingAccounts } = this.props;
    return (
      <div>
        {accountingAccounts && (
          <SelectCuenta
            value={this.props.value}
            onChange={this.props.onChange}
            disabled={this.props.disabled}
            size={this.props.size}
            id={"cuentaContable"}
            options={this.props.accountingAccounts.map(accounts => ({
              ...accounts,
              id: accounts.cuentaContableId
            }))}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getAccountingAccounts: () => {
    dispatch(getAccountingAccounts());
  }
});

const mapStateToProps = state => {
  const { catalogs } = state;
  const { accountingAccounts, gettingAccountingAccounts } = catalogs;
  return {
    accountingAccounts,
    gettingAccountingAccounts
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RolesUserPage);
