import { RouteComponentProps, withRouter } from "react-router-dom";
import React from "react";
import Reform from "@franleplant/reform";
import { FormErrors } from "@franleplant/reform/types";
import { Row, Col, Button, Container } from "reactstrap";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { parse as parseQueryParams } from "query-string";
import s from "./styles.scss";
import BasePasswordInput from "../../../toolkit/basePasswordInput";

// TODO: REDUX
import axios from "axios";
import constants from "../../../utils/constants";
import { successNotification } from "../../../utils/notifications";
import {
  errorNotification,
  infoNotification
} from "../../../utils/notifications";
import { delay } from "lodash";

// Interfaz para validación de formularios con Reform
interface Fields {
  newPassword: string;
  newConfirm: string;
}

// Propiedades y estado del componente
interface Props extends RouteComponentProps {
  logoutUser: () => any;
  restartApp: () => any;
  user: any;
}

interface State {
  // Propiedades requeridas por Reform, para validación de formularios
  fields: Fields;
  errors: FormErrors;

  // Propiedades requeridas para manejar la forma en que se muestran los errores
  newPasswordDirty: boolean;
  newConfirmDirty: boolean;
  loading: boolean;
}

/**
 * Componente que permite la actualización de la contraseña
 */
class Recovery extends React.Component<Props, State> {
  /* Estado inicial del formulario */
  state = {
    fields: {
      newPassword: "",
      newConfirm: ""
    },
    errors: {},
    username: false,
    newPasswordDirty: false,
    newConfirmDirty: false,
    loading: false
  };

  /* Propiedades para la validación de formularios */
  re = Reform.reactMixins.objectMixin(this);

  validationRules = {
    newPassword: {
      newPasswordRequired: value => !value,
      newPasswordSpaces: value => {
        const exp = /\s/;
        return exp.exec(value) != null;
      },
      newPasswordValidation: value => {
        const exp = /^(?=(?:.*[A-Z]){1})(?=(?:.*[a-z]){1})(?=(?:.*[0-9]){1})\S/;
        return exp.exec(value) == null;
      },
      minLength: 5,
      maxLength: 16
    },
    newConfirm: {
      newConfirmRequired: value => (value ? false : true),
      newConfirmShouldMatch: value => value !== this.state.fields.newPassword
    }
  };

  validationMessages = {
    // Nueva contraseña
    newPasswordRequired: () => "Debe ingresar su nueva contraseña",
    newPasswordSpaces: () => "La contraseña no debe contener espacios",
    newPasswordValidation: () =>
      "Debe contener al menos una mayuscula, una minuscula y un número",
    minLength: ruleValue => `Debe tener al menos ${ruleValue} caracteres`,
    maxLength: ruleValue => `Deber tener maximo ${ruleValue} caracteres`,
    newPasswordShouldNotMatch: () => "No debe repetir su contraseña actual",

    // Confirmación de la contraseña
    newConfirmRequired: () => "Debe confirmar la nueva contraseña",
    newConfirmShouldMatch: () => "Las contraseñas no coinciden",

    // Default
    default: () => "Valor requerido o no válido"
  };

  /* Métodos de interacción con el formulario */

  // Actualización de los campos de texto
  onChangeTextInput = (targetValue, fieldName) => {
    const value = targetValue || "";

    // Se valida todo el formulario porque los campos tienen dependencias
    this.setState(
      {
        fields: {
          ...this.state.fields,
          [fieldName]: value
        },
        [fieldName + "Dirty"]: true
      } as any,
      this.re.validateFormFromState
    );
  };

  // Valida el estado de un campo y muestra solo un error
  onFieldValidation = fieldName => {
    if (!this.state[fieldName + "Dirty"]) {
      return [];
    }

    const errors = this.re.mapFieldErrors(fieldName) || [];
    return errors.length > 1 ? [errors[0]] : errors;
  };

  logout = () => {
    this.props
      .logoutUser()
      .then(() => {
        this.props.restartApp();
      })
      .catch(e => console.error(e));
  };

  // Actualización de la contraseña en el servidor
  submit = async evt => {
    evt.preventDefault();

    this.setState({
      newPasswordDirty: true,
      newConfirmDirty: true
    });

    if (!this.re.validateFormFromState()) {
      errorNotification(
        "No ha ingresado la información requerida, revise los datos por favor."
      );
      return;
    }

    this.setState({
      loading: true
    });

    try {
      infoNotification("Actualizando contraseña...");

      const { ufo } = parseQueryParams(this.props.location.search);
      await axios.post(
        `${constants.BASE_URL}/api/auth/recovery`,
        {
          data: ufo,
          password: this.state.fields.newPassword
        },
        {
          withCredentials: true
        }
      );
      successNotification("Contraseña actualizada exitosamente");

      this.setState({
        fields: {
          newPassword: "",
          newConfirm: ""
        }
      });

      delay(this.logout, 2500);
    } catch (error) {
      const hasData = error && error.response && error.response.data;
      const data = hasData ? error.response.data : {};

      const message =
        data.message || "Error desconocido, intente de nuevo más tarde";
      errorNotification(message);
    }

    this.setState({
      loading: false
    });
  };

  render() {
    return (
      <Container>
        <div className="alert alert-info mt-3" role="alert">
          Ingrese su nueva contraseña
        </div>
        <Row>
          <Col md={4}>&nbsp;</Col>
          <Col md={4}>
            <BasePasswordInput
              label={"Nueva contraseña"}
              name="newPassword"
              type="password"
              id="newPassword"
              placeholder="Nueva contraseña"
              value={this.state.fields.newPassword}
              onChange={evt =>
                this.onChangeTextInput(evt.target.value, "newPassword")
              }
              errors={this.onFieldValidation("newPassword")}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>&nbsp;</Col>
          <Col md={4}>
            <BasePasswordInput
              label={"Confirme la contraseña"}
              name="newConfirm"
              type="password"
              id="newConfirm"
              placeholder="Confirme la contraseña"
              value={this.state.fields.newConfirm}
              onChange={evt =>
                this.onChangeTextInput(evt.target.value, "newConfirm")
              }
              errors={this.onFieldValidation("newConfirm")}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>&nbsp;</Col>
          <Col md={4}>
            <Button
              className={s.buttonMarginTop + " primaryButton"}
              type="button"
              onClick={this.submit}
              size="sm"
              block
              disabled={this.state.loading}
            >
              {!this.state.loading ? "Actualizar contraseña" : "Espere..."}
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(Recovery));
