import { Input } from "reactstrap";
import React from "react";

interface Props {
  value: number;
  saveChange: (ev: any) => any;
}

interface State {
  inputValue: number;
}

class ControlledCellInput extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: props.value
    };
  }

  handleOnChange = event => {
    this.setState({ inputValue: event.target.value });
  };

  handleOnBlur = event => {
    this.props.saveChange(event);
  };

  render() {
    const { inputValue } = this.state;
    const { value, saveChange, ...props } = this.props;

    return (
      <Input
        {...props}
        value={inputValue}
        onChange={this.handleOnChange}
        onBlur={this.handleOnBlur}
      />
    );
  }
}

export default ControlledCellInput;
