import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import ReactTable from "react-table";
import { withRouter } from "react-router-dom";
import { Button, Container, Row, Col } from "reactstrap";
import ReportFilter from "./ReportFilter";
import ReasignationModal from "./Reasignation";
import { FILTERS_CATALOGS, FILTERS, TIPOS_COMISION_SERIAL } from "./interfaces";
import s from "./styles.scss";
import { faFileExport, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { downloadMembershipsSalesComissionsReport } from "../../actions/reports";
import ModalFrame from "../../../toolkit/modalFrame";
import axios from "axios";
import constants from "../../../utils/constants";
import {
  errorNotification,
  infoNotification
} from "../../../utils/notifications";
import { readMessageBack } from "../../../utils/readMessageBack";
import { generateReasignationCol, MEMBERSHIP_TABLE_STRUCT } from "./tables";
import FileDownload from "js-file-download";
import { userHasPermission } from "../../../utils/accessTree";
import permissionCodes from "../../../utils/permissionCodes";
import BaseCsvIconDownloader from "../../../toolkit/baseCsvIconDownloader";

// TODO: REDUX - Revisión 2
const REPORTS_BASE_URL = "/api/reports";

interface Props {
  fetchSearchData: (filters: FILTERS) => void;
  filterCatalogs: FILTERS_CATALOGS;
  searching: boolean;
  reportComissions: any[];
  loadingReport: boolean;
  clubs: any;
  periods: any;
  sellers: any[];
  userContext: { clubSessionId?: any };
}

interface State {
  // Filtros aplicados en la pantalla
  currentFilters: FILTERS;

  // Muestra la ventana de confirmación de procesamiento de comisiones
  showProccessConfirm: boolean;

  // Verdadero en tanto se estén procesando las comisiones
  waitProccess: boolean;

  // Indica si el proceso de comisiones es global o de una selección (no implementada aún)
  globalProccess: boolean;

  // Propiedades aplicables al popup de reasignación de ventas
  showReasignation: boolean;
  cotizacionProductoItemId: number;
  idPublico: number;
  descripcion: string;
  club: string;
}

class ComissionsReportPage extends React.Component<Props, State> {
  state = {
    currentFilters: null,
    showProccessConfirm: false,
    waitProccess: false,
    globalProccess: false,
    showReasignation: false,
    cotizacionProductoItemId: null,
    idPublico: null,
    descripcion: null,
    club: null
  };

  // Columna y callback de reasignación
  reasignCol = generateReasignationCol(this.reasignCallback.bind(this));
  reasignCallback(row) {
    this.setState({
      showReasignation: true,
      cotizacionProductoItemId: row.idCotizacionProductosItem,
      idPublico: row.idPublico,
      descripcion: row.descripcion,
      club: row.clubNombre
    });
  }

  /**
   * Confirmación de procesamiento de comisiones (seleccionadas o globales)
   * @param filter Filtros seleccionados
   */
  fetchProccess = (filter?: FILTERS) => {
    this.setState({
      showProccessConfirm: true,
      currentFilters: filter,
      globalProccess: filter ? true : false
    });
  };

  /**
   * Oculta o muestra el popup de confirmación
   */
  toggleModal = () => {
    this.setState({
      showProccessConfirm: !this.state.showProccessConfirm
    });
  };

  /**
   * Procesamiento de comisiones en CRM1 (seleccionadas o globales)
   */
  confirmProccess = async () => {
    const body = {
      clubId: this.state.currentFilters.clubId,
      periodoId: this.state.currentFilters.periodoId
    };

    // NOTE: Para procesar comisiones individualmente en versiones posteriores
    const postfix = this.state.globalProccess ? "" : "Ids";
    let uri: string;

    switch (this.state.currentFilters.tipoComision) {
      case "VENTA_DE_MEMBRESIA":
        uri = "/comissionsMembership/proccess" + postfix;
        break;
      case "REACTIVACION":
        uri = "/comissionsReactivation/proccess" + postfix;
        break;
      case "MANTENIMIENTO_ANUAL_MSI":
        uri = "/comissionsAnnuity/proccess" + postfix;
        break;
      default:
        errorNotification("Debe seleccionar un tipo de comisión");
        return;
    }

    this.setState({
      waitProccess: true
    });

    try {
      infoNotification("Procesando las comisiones");
      let response = await axios.post(
        `${constants.BASE_URL}${REPORTS_BASE_URL}${uri}`,
        body,
        {
          withCredentials: true
        }
      );

      infoNotification(
        response.data.message || "Comisiones procesadas correctamente"
      );
    } catch (error) {
      errorNotification(
        readMessageBack(error, "No fue posible procesar las comisiones")
      );
    }

    this.setState({
      showProccessConfirm: false,
      waitProccess: false
    });
  };

  /**
   * Confirma la reasignación de un vendedor desde el popup de reasignación
   * @param filter Objeto con vendedor e item de venta
   */
  reasignationSale = async (filter: any) => {
    infoNotification("Reasignando la comisión...");
    try {
      let body = {
        cotizacionProductoItemId: filter.cotizacionProductoItemId,
        vendedorId: filter.seller.userId
      };
      await axios.put(
        `${constants.BASE_URL}${REPORTS_BASE_URL}/comissions/seller`,
        body,
        {
          withCredentials: true
        }
      );

      this.props.fetchSearchData(this.state.currentFilters);

      infoNotification("Comisión reasignada correctamente");
    } catch (error) {
      errorNotification(
        "No fue posible reasignar la comisión, inténtelo más tarde"
      );
    }

    this.setState({
      showReasignation: false,
      cotizacionProductoItemId: null,
      idPublico: null,
      descripcion: null,
      club: null
    });
  };

  /**
   * Cierre de ventana modal de reasignación
   */
  reasignationClose = () => {
    this.setState({
      showReasignation: false,
      cotizacionProductoItemId: null,
      idPublico: null,
      descripcion: null,
      club: null
    });
  };

  /**
   * Indica si se debe ocultar la nota de comisiones pendientes
   */
  get hidePendingNote(): boolean {
    const data = this.props.reportComissions || [];

    for (const item of data) {
      if (
        item.estatusCRM1 === "FALTA_DOCUMENTACION" ||
        item.estatusCRM1 === "FALTA_TOKENIZACION" ||
        item.estatusCRM1 === "FALTA_DOCUMENTO_CAT"
      ) {
        return false;
      }
    }

    return true;
  }

  downloadCSV = async (filters, _callback) => {
    infoNotification("Generando reporte...");
    try {
      let data = await axios.post(
        `${constants.BASE_URL}${REPORTS_BASE_URL}/comissions/sale/download`,
        filters,
        { withCredentials: true }
      );

      if (!!_callback) {
        _callback();
      }

      FileDownload(data.data, "Reporte de Comisiones de Venta.csv");
    } catch (error) {
      errorNotification(
        "No fue posible generar el reporte, inténtelo más tarde"
      );
    }
  };

  render() {
    return (
      <Container className="mt-3 mb-5 p-0">
        <Row className="mb-1">
          <Col sm="12">Ingresa los campos requeridos para comenzar ( * )</Col>
        </Row>

        <ReportFilter
          // @ts-ignore
          clubs={this.props.clubs}
          periods={this.props.periods}
          fetchSearchData={(filters: FILTERS) => {
            this.setState({ currentFilters: filters });
            this.props.fetchSearchData(filters);
          }}
          sellers={this.props.sellers}
          showProccess={false}
          fetchProccess={this.fetchProccess}
          userContext={this.props.userContext}
          loadingReport={this.props.loadingReport}
        />

        <Row className={"my-3"}>
          <Col sm="12">
            <div hidden={this.hidePendingNote} className="alert alert-danger">
              Algunas comisiones no cumplen con las reglas necesarias para su
              pago.
            </div>
          </Col>
        </Row>

        <BaseCsvIconDownloader
          rowCount={(this.props.reportComissions || []).length}
          executeReportDownload={this.downloadCSV}
          getFilters={() => {
            return {
              ...this.state.currentFilters,
              tipoComision:
                TIPOS_COMISION_SERIAL[this.state.currentFilters.tipoComision]
            };
          }}
        />

        <ReactTable
          pages={1}
          data={this.props.reportComissions || []}
          columns={
            userHasPermission(permissionCodes.COMMISSIONS_SALE_REASIGN)
              ? [...MEMBERSHIP_TABLE_STRUCT, this.reasignCol]
              : MEMBERSHIP_TABLE_STRUCT
          }
          showPagination={false}
          sortable={false}
          multiSort={false}
          resizable={true}
          pageSize={(this.props.reportComissions || []).length + 3}
        />

        <ModalFrame
          title={"Confirme la operación"}
          isOpen={this.state.showProccessConfirm}
          toggle={this.toggleModal}
          size="lg"
        >
          <Row>
            <Col lg={12}>
              Cuando confirma una comisión, esta se procesa en un sistema
              externo que debería estar disponible para continuar con el flujo
              de pago. <br />
              <br />
              <span style={{ fontWeight: "bold" }}>
                Se procesarán únicamente las comisiones del club, periodo y tipo
                seleccionados.
              </span>
            </Col>
            <Col lg={12}>
              <div
                hidden={this.state.waitProccess}
                style={{ textAlign: "right" }}
              >
                <Button
                  className={`mt-4 ${s.buttonMarginTop} ${s.primaryButton}`}
                  onClick={this.confirmProccess}
                  style={{ height: "2rem", marginLeft: "8px" }}
                >
                  Aceptar
                </Button>
                <Button
                  className={`mt-4 ${s.buttonMarginTop} ${s.primaryButton}`}
                  onClick={this.toggleModal}
                  style={{ height: "2rem", marginLeft: "8px" }}
                >
                  Cancelar
                </Button>
              </div>
              <div
                hidden={!this.state.waitProccess}
                style={{ textAlign: "right" }}
              >
                <FontAwesomeIcon icon={faSpinner} pulse />
              </div>
            </Col>
          </Row>
        </ModalFrame>

        <ReasignationModal
          // @ts-ignore
          isModalOpen={this.state.showReasignation}
          sellers={this.props.sellers}
          cotizacionProductoItemId={this.state.cotizacionProductoItemId}
          idPublico={this.state.idPublico}
          descripcion={this.state.descripcion}
          club={this.state.club}
          reasignSale={this.reasignationSale}
          close={this.reasignationClose}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ComissionsReportPage));
