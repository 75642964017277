import React from "react";
import { withRouter } from "react-router-dom";
import FormBuilder from "../../../toolkit/baseForms2/formBuilder/FormBuilder";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./styles.scss";
import { formConfig, Inputs, Buttons } from "./filtros.form";
import IUserSelectorBuilder from "../../../toolkit/baseForms2Commons/iUserSelectorBuilder/IUserSelectorBuilder";
import { Container, Row, Col } from "reactstrap";
import ReactTable from "react-table";
import { columns } from "./columns";
import {
  comisionesTycPagadas,
  comisionesTycOpciones
} from "../../actions/reports";
import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import MexicanCurrency from "../common/currency/index";
import ISelectBuilder from "../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";

interface Props {}

interface State {
  loadingForm: boolean;
  initForm: { [key in Inputs]: any };
  disableSelector: boolean;
  data: any[];
  totalComision: any;
  totalImporte: any;
  opciones: any[];
}

class ComisionesTycPagadasPage extends React.Component<Props, State> {
  state = {
    loadingForm: false,
    initForm: null,
    disableSelector: false,
    data: null,
    totalComision: null,
    totalImporte: null,
    opciones: null
  };

  componentDidMount(): void {
    comisionesTycOpciones()
      .then(response => {
        this.setState({
          opciones: response.data
        });
      })
      .catch(_ => {
        errorNotification("No se pudieron consultar los periodos");
      });
  }

  submit = values => {
    const numeroEmpleado = values.noEmpleado || values.empleado;

    if (!numeroEmpleado) {
      errorNotification("Seleccione al empleado o ingrese el No. de empleado");
      return;
    }

    if (!values.periodo) {
      errorNotification("Seleccione el periodo de búsqueda");
      return;
    }

    const valuesPeriodo = (values.periodo as string).split("::");
    const quincena = valuesPeriodo[0];
    const periodo = valuesPeriodo[1];
    infoNotification("Buscando comisiones...");
    this.setState({
      loadingForm: true
    });

    comisionesTycPagadas(numeroEmpleado, periodo, quincena)
      .then(response => {
        successNotification("Comisiones consultadas correctamente");
        this.setState({
          data: response.data.comisiones,
          totalComision: response.data.totalComision,
          totalImporte: response.data.totalImporte,
          loadingForm: false
        });
      })
      .catch(_ => {
        errorNotification("No se pudieron consultar las comisiones");
        this.setState({
          loadingForm: false
        });
      });
  };

  render() {
    return (
      <Container className="mb-5 mt-3">
        <FormBuilder<Inputs, Buttons>
          config={formConfig}
          init={this.state.initForm}
          submit={this.submit}
          loading={this.state.loadingForm || !this.state.opciones}
          onChange={values =>
            this.setState({
              disableSelector: !!values.noEmpleado
            })
          }
        >
          <IUserSelectorBuilder
            name="empleado"
            includeSystemUsers={true}
            disabled={this.state.disableSelector}
            useNumeroEmpleado
          />
          <ISelectBuilder name="periodo" data={this.state.opciones || []} />
        </FormBuilder>

        {!!this.state.data && (
          <Row>
            <Col lg={12}>
              <p className="primaryTitle w-100">
                Comisiones Pagadas &nbsp;&nbsp; Importe total:&nbsp;
                <MexicanCurrency quantity={this.state.totalImporte} />
                &nbsp;&nbsp; Comisión total:&nbsp;
                <MexicanCurrency quantity={this.state.totalComision} />
              </p>
              <ReactTable
                data={this.state.data}
                columns={columns}
                className="mt-2"
                resizable={true}
                sortable={false}
                loading={this.state.loadingForm}
                showPagination={false}
                pageSize={
                  this.state.data.length > 10 ? this.state.data.length : 10
                }
              />
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ComisionesTycPagadasPage));
