import React from "react";
import { connect } from "react-redux";
import { sendCompromisesBySeller } from "../../../actions/sales";
import { saveGoals } from "../../../../Home/actions/goals";
import MonthlySalesTracking from "../../components/MonthlySalesTracking";
import { Helmet } from "react-helmet";
import Layout from "../../../../Home/components/layout";
import { SalesTrackingInterface } from "../../components/MonthlySalesTracking/TotalSales";
import { MEMBERSHIP_TYPE } from "../../../../utils/constants";

interface Props {
  sendCompromisesBySeller: (compromises) => any;
  saveGoals: (goals) => any;
  userContext: any;
}

class MonthlySalesTrackingContainer extends React.Component<Props> {
  sendCompromises = ({
    anio,
    mes,
    clubId,
    numeroVentas,
    totalVentas,
    numeroProspectos,
    numeroEjecutivosQ1,
    numeroEjecutivosQ2
  }: SalesTrackingInterface) => {
    const goals = [
      {
        anio: anio,
        mes: mes,
        clubId: clubId,
        tipoEsquema: MEMBERSHIP_TYPE.VENTA,
        compromisoNroVentas: numeroVentas.compromiso,
        nroMembresias: numeroVentas.presupuesto,

        compromisoTotalVentas: totalVentas.compromiso,
        totalVentas: totalVentas.presupuesto,

        compromisoNroProspectos: numeroProspectos.compromiso,
        nroProspectos: numeroProspectos.presupuesto,

        nroEjecutivosQ1: numeroEjecutivosQ1,
        nroEjecutivosQ2: numeroEjecutivosQ2
      }
    ];

    this.props.saveGoals(goals);
  };

  render() {
    return (
      <main>
        <Helmet title="Tracking de Ventas Mensuales por Club" />
        <Layout>
          <MonthlySalesTracking
            sendCompromisesBySeller={this.props.sendCompromisesBySeller}
            sendCompromises={this.sendCompromises}
            userContext={this.props.userContext}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = {
  sendCompromisesBySeller,
  saveGoals
};

const mapStateToProps = state => ({
  userContext: state.user.userContext
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MonthlySalesTrackingContainer);
