import React from "react";
import BaseInput from "../../../toolkit/baseInput";
import BaseNumericInput from "../../../toolkit/baseNumericInput";
import BaseAlphabeticInput from "../../../toolkit/baseAlphabeticInput";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./stylesComponentContactos.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faSave,
  faTimesCircle,
  faPlus
} from "@fortawesome/free-solid-svg-icons";

interface Contacto {
  nombre: string;
  apellido: string;
  telefono: string;
  parentesco: string;
  id: string;
}

interface Props {
  viewMode: boolean;
  data: Contacto[];
  onAddContacto: () => void;
  onDeleteContacto: (index: string) => void;
  onChangeContacto: (name: string, value: string, index: string) => void;
  errors: any;
  title: string;
  newItemTitle: string;
  saveContacto: (index: number) => void;
}

class Contactos extends React.Component<Props> {
  renderContactos = () => {
    let data = this.props.data.map((contacto, index) => {
      const disableSave =
        !contacto.telefono ||
        !contacto.nombre ||
        !contacto.apellido ||
        !contacto.parentesco;
      const disableDelete = !contacto.id;
      return (
        <Card key={index + "-contactos"} className={s.card}>
          <CardBody className={`p-1 ${s.cardBody}`}>
            <Row>
              <Col>
                <BaseAlphabeticInput
                  label={"Nombre * "}
                  name={`nombre_${index}`}
                  id={`nombre_${index}`}
                  placeholder={"Nombre"}
                  value={contacto.nombre}
                  onChange={e =>
                    this.handleChangeContacto("nombre", index, e, true)
                  }
                  uppercase={true}
                  disabled={!!this.props.viewMode}
                />
              </Col>
              <Col>
                <BaseAlphabeticInput
                  label={"Apellido * "}
                  name={`apellido_${index}`}
                  type="text"
                  id={`apellido_${index}`}
                  placeholder={"Apellido"}
                  value={contacto.apellido}
                  onChange={e =>
                    this.handleChangeContacto("apellido", index, e, true)
                  }
                  uppercase={true}
                  disabled={!!this.props.viewMode}
                />
              </Col>
              <Col>
                <BaseNumericInput
                  label={"Teléfono * (10 dígitos)"}
                  name={`telefono_${index}`}
                  id={`telefono_${index}`}
                  placeholder={"Teléfono"}
                  value={contacto.telefono}
                  onChange={e =>
                    this.handleChangeContacto("telefono", index, e)
                  }
                  maxlength={"10"}
                  disabled={!!this.props.viewMode}
                />
              </Col>
              <Col>
                <BaseInput
                  label={"Parentesco * "}
                  name={`parentesco_${index}`}
                  type="select"
                  options={this.relationshipOptions}
                  id={`parentesco_${index}`}
                  placeholder={"Parentesco"}
                  value={contacto.parentesco}
                  onChange={e =>
                    this.handleChangeContacto("parentesco", index, e)
                  }
                  disabled={!!this.props.viewMode}
                />
              </Col>
              {!this.props.viewMode && (
                <Col xs={1}>
                  <div className={`float-right mx-1 mt-4 ${s.deleteRow}`}>
                    {!!(contacto.nombre || "") &&
                      !!(contacto.apellido || "") &&
                      !!contacto.parentesco &&
                      (contacto.telefono || "").length >= 10 && (
                        <a
                          onClick={e => {
                            e.preventDefault();
                            if (!disableSave) this.props.saveContacto(index);
                          }}
                          style={{
                            color: disableSave ? "gray" : "black"
                          }}
                        >
                          <FontAwesomeIcon icon={faSave} />
                        </a>
                      )}
                  </div>
                  <div className={`float-right mx-1 mt-4 ${s.deleteRow}`}>
                    <a
                      style={{
                        color: disableDelete ? "gray" : "black"
                      }}
                      onClick={e =>
                        !disableDelete && this.deleteContacto(e, index)
                      }
                    >
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </a>
                  </div>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      );
    });
    return data;
  };

  relationshipOptions = [
    { value: "", label: "Seleccionar" },
    { value: "PADRE_MADRE", label: "PADRE/MADRE" },
    { value: "HERMANO_A", label: "HERMANO(A)" },
    { value: "HIJO_A", label: "HIJO(A)" },
    { value: "ESPOSO_A", label: "ESPOSO(A)" },
    { value: "FAMILIAR", label: "FAMILIAR" },
    { value: "AMIGO", label: "AMIGO" },
    { value: "TUTOR", label: "TUTOR" },
    { value: "NANA", label: "NANA" },
    { value: "CHOFER", label: "CHOFER" },
    { value: "OTRO", label: "OTRO" }
  ];

  handleChangeContacto = (name, index, e, toUpperCase?: boolean) => {
    const tmp = e.target.value;
    const value = !!toUpperCase ? (tmp || "").toUpperCase() : tmp;
    this.props.onChangeContacto(name, value, index);
  };

  handleAddContacto = e => {
    e.preventDefault();
    this.props.onAddContacto();
  };

  deleteContacto = (e, index) => {
    e.preventDefault();
    this.props.onDeleteContacto(index);
  };

  render() {
    return (
      <div>
        <Row className="primaryTitle m-0 mt-3">
          <Col>
            <p className="m-0">
              {this.props.title || "Contactos de Emergencia"}
            </p>
            {this.props.errors &&
              this.props.errors.map((message, index) => {
                return (
                  <span key={index} className={s.errorSpan}>
                    {message}
                    <br />
                  </span>
                );
              })}
          </Col>
          <Col xs={3} className="text-right">
            {!this.props.viewMode && (
              <Button
                className="btn btn-light btn-sm"
                onClick={e => this.handleAddContacto(e)}
              >
                {this.props.newItemTitle || "Agregar Contacto"}
                <FontAwesomeIcon className="ml-2" icon={faPlus} />
              </Button>
            )}
          </Col>
        </Row>
        {this.renderContactos()}
      </div>
    );
  }
}

export default withRouter(withStyles(s)(Contactos));
