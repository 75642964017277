import React from "react";
import { connect } from "react-redux";
import BaseInput from "../../../toolkit/baseInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import ModalFrame from "../../../toolkit/modalFrame";
import { Button, Table } from "reactstrap";
import {
  getPersonsRequest,
  getPersonByIdRequest
} from "../../../Home/actions/persons";
import { Prospect, PEOPLE_TYPES, SEX_TYPES } from "../../../types/Prospects";
import DatePicker from "../../../Home/components/common/DatePicker";
import { errorNotification } from "../../../utils/notifications";
import s from "./styles.scss";

interface Person {
  personaId: number;
  nombre: string;
  primerApellido: string;
  segundoApellido: string;

  fechaNacimiento: any;
  sexo: SEX_TYPES;
  entidadNacimiento: string;
  bloqueoMail: boolean;
  numeroEmpleado: number;
  bloqueoCallCenter: boolean;
}

interface Props {
  setPerson: (person: Person) => any;
  label: string;
  disabled: boolean;
}

interface State {
  person: Person;
  isOpen: boolean;

  searchFields: {
    nombre: string;
    apellido: string;
    apellidoMaterno: string;
    edad: any;
    sexo: string;
    tipo: PEOPLE_TYPES;
  };
  errorMessage: boolean;
  personaId: string;
  selectedPerson: string;
  persons: Prospect[];
  personByID: Prospect;
  loading: boolean;
}

class PartnerSelector extends React.Component<Props, State> {
  state = {
    person: null,
    isOpen: false,

    searchFields: {
      nombre: "",
      apellido: "",
      apellidoMaterno: "",
      edad: 0,
      sexo: "",
      tipo: PEOPLE_TYPES.CLIENTE
    },

    errorMessage: false,
    personaId: "",
    selectedPerson: null,
    persons: null,
    personByID: null,
    loading: false
  };

  getPersonName = () => {
    if (this.state.person) {
      const { nombre, primerApellido, segundoApellido } = this.state.person;
      return `${nombre}${primerApellido ? ` ${primerApellido}` : ""}${
        segundoApellido ? ` ${segundoApellido}` : ""
      }`;
    } else {
      return "";
    }
  };

  toggleModal = () => {
    this.clearState();
    this.setState({ isOpen: !this.state.isOpen });
  };

  onChangeSearchFieldsFactory = fieldName => {
    return event => {
      let value;
      if (fieldName === "edad") {
        value = event;
      } else {
        value = event.target.value;
      }
      this.setState(state => {
        const searchFields = {
          ...state.searchFields,
          [fieldName]: value
        };

        return { ...state, searchFields };
      });
    };
  };

  onChangePersonaID = event => {
    this.setState({ personaId: event.target.value });
  };

  searchClients = e => {
    e.preventDefault();
    const args = { ...this.state.searchFields };

    this.setState({
      loading: true
    });

    if (this.state.personaId) {
      getPersonByIdRequest(this.state.personaId).then(data => {
        const { data: personByID } = data;
        this.setState({
          personByID,
          persons: null,
          errorMessage: true,
          loading: false
        });
      });
    } else {
      if (!!args.nombre && !!args.nombre.length) {
        args.nombre = args.nombre.trim();
      }
      if (!!args.apellido && !!args.apellido.length) {
        args.apellido = args.apellido.trim();
      }

      getPersonsRequest(args).then(data => {
        const { data: persons } = data;
        this.setState({
          persons: (persons || []).filter(
            x =>
              x.tipo == PEOPLE_TYPES.CLIENTE ||
              x.tipo == PEOPLE_TYPES.CLIENTE_INTEGRANTE ||
              x.tipo == PEOPLE_TYPES.CLIENTE_TITULAR
          ),
          personByID: null,
          errorMessage: true,
          loading: false
        });
      });
    }
  };

  selectPerson = id => {
    this.setState({ selectedPerson: id });
  };

  linkPartner = e => {
    e.preventDefault();
    if (
      this.state.personByID &&
      this.state.personByID.personaId === this.state.selectedPerson
    ) {
      this.setState({ person: this.state.personByID });
      this.props.setPerson(this.state.personByID);
    } else if (this.state.persons.length > 0) {
      const person = this.state.persons.filter(
        persona => persona.personaId === this.state.selectedPerson
      );
      this.setState({ person: person[0] });
      this.props.setPerson(person[0]);
    }
    this.toggleModal();
    this.clearState();
  };

  clearState = () => {
    this.setState({
      searchFields: {
        nombre: "",
        apellido: "",
        apellidoMaterno: "",
        edad: 0,
        sexo: null,
        tipo: PEOPLE_TYPES.CLIENTE
      },
      errorMessage: false,
      personaId: "",
      selectedPerson: null,
      persons: null,
      personByID: null
    });
  };

  getPersonsRender = () => {
    const persons =
      this.state.persons ||
      (this.state.personByID ? [this.state.personByID] : []);

    if (!persons.length && this.state.errorMessage) {
      this.setState({ errorMessage: false });
      errorNotification(
        "No se ha encontrado información con los filtros especificados"
      );
    }

    return persons && persons.length > 0 ? (
      <Table size="sm" hover className="table-borderless">
        <thead>
          <tr>
            <th>Persona Id</th>
            <th>Nombre</th>
            <th>A. Paterno</th>
            <th>A. Materno</th>
            <th>Número</th>
            <th>Correo</th>
            <th>F. Nacimiento</th>
          </tr>
        </thead>
        <tbody>
          {persons && !!persons.length
            ? persons.map(prs => (
                <tr
                  className={s.personRow}
                  style={
                    prs.personaId === this.state.selectedPerson
                      ? {
                          backgroundColor: "rgba(0, 0, 0, 0.075)",
                          cursor: "hand"
                        }
                      : { cursor: "hand" }
                  }
                  onClick={() => this.selectPerson(prs.personaId)}
                  key={prs.personaId}
                >
                  <th scope="row">{prs.personaId}</th>
                  <td>{prs.nombre}</td>
                  <td>{prs.primerApellido}</td>
                  <td>{prs.segundoApellido}</td>
                  <td>
                    {prs.telefonos &&
                      prs.telefonos.length &&
                      prs.telefonos[0].numero}
                  </td>
                  <td>
                    {prs.correosElectronicos &&
                      prs.correosElectronicos.length &&
                      prs.correosElectronicos[0].correo}
                  </td>
                  <td>{prs.fechaNacimiento}</td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
    ) : null;
  };

  render() {
    return (
      <div>
        <ModalFrame
          title="Socios"
          isOpen={this.state.isOpen}
          toggle={this.toggleModal}
          size="lg"
        >
          <div className={s.modalContainer}>
            <div className="h-100">
              <form className={s.basicDataClub}>
                <div className="form-row">
                  <div className="col">
                    <BaseInput
                      label={"Persona Id"}
                      name="personaID"
                      type="text"
                      id="personaID"
                      placeholder="Persona Id"
                      value={this.state.personaId}
                      onChange={e => this.onChangePersonaID(e)}
                      disabled={
                        this.state.searchFields.apellido ||
                        this.state.searchFields.nombre
                      }
                    />
                  </div>
                  <div className="col">
                    <BaseInput
                      label={"Nombre"}
                      name="nombre"
                      type="text"
                      id="nombre"
                      placeholder="Nombre"
                      value={this.state.searchFields.nombre}
                      disabled={this.state.personaId}
                      onChange={this.onChangeSearchFieldsFactory("nombre")}
                    />
                  </div>
                  <div className="col">
                    <BaseInput
                      label={"Apellido"}
                      name="apellido"
                      type="text"
                      id="apellido"
                      placeholder="Apellido"
                      value={this.state.searchFields.apellido}
                      disabled={this.state.personaId}
                      onChange={this.onChangeSearchFieldsFactory("apellido")}
                    />
                  </div>
                  {/* <div className="col">
                    <BaseInput
                      label={"Apellido Materno"}
                      name="apellidoMaterno"
                      type="text"
                      id="apellidoMaterno"
                      placeholder="Apellido Materno"
                      value={this.state.searchFields.apellidoMaterno}
                      disabled={this.state.personaId}
                      onChange={this.onChangeSearchFieldsFactory(
                        "apellidoMaterno"
                      )}
                    />
                  </div> */}
                  <div className="col">
                    <BaseInput
                      label={"Sexo"}
                      name="sexo"
                      type="select"
                      id="sexo"
                      placeholder="Sexo"
                      value={this.state.searchFields.sexo}
                      disabled={this.state.personaId}
                      options={[
                        { value: "", label: "Seleccione" },
                        { value: "FEMENINO", label: "Femenino" },
                        { value: "MASCULINO", label: "Masculino" }
                      ]}
                      onChange={this.onChangeSearchFieldsFactory("sexo")}
                    />
                  </div>
                  <div className="col">
                    <DatePicker
                      label="Edad"
                      name="edad"
                      id="edad"
                      selected={this.state.searchFields.edad}
                      onChange={this.onChangeSearchFieldsFactory("edad")}
                    />
                  </div>
                  <div className="col">
                    <BaseInput
                      label={"Tipo de persona"}
                      name="tipo"
                      type="select"
                      id="tipo"
                      placeholder="Tipo de persona"
                      value={this.state.searchFields.tipo}
                      disabled={this.state.personaId}
                      options={[
                        {
                          label: "Todos",
                          value: "TODOS"
                        },
                        {
                          label: "Cliente",
                          value: PEOPLE_TYPES.CLIENTE
                        },
                        {
                          label: "Cliente integrante",
                          value: PEOPLE_TYPES.CLIENTE_INTEGRANTE
                        }
                      ]}
                      onChange={this.onChangeSearchFieldsFactory("tipo")}
                    />
                  </div>
                  <div className="col-1">
                    <Button
                      className={`${s.buttonMarginTop} ${s.primaryButton} mt-4`}
                      onClick={e => this.searchClients(e)}
                      size="sm"
                      block
                      disabled={
                        (!this.state.personaId &&
                          !(
                            this.state.searchFields.apellido &&
                            this.state.searchFields.nombre
                          )) ||
                        this.state.loading
                      }
                    >
                      {!this.state.loading ? "Buscar" : "Espere"}
                    </Button>
                  </div>
                </div>
              </form>
              <hr />
              {this.getPersonsRender()}
            </div>
            <form>
              <div className="form-row p-0 m-0">
                <div className="col" />
                <div className="col-2 text-right pr-0">
                  <Button
                    className={`${s.buttonMarginTop} ${s.primaryButton} mt-4`}
                    style={{ cursor: "hand" }}
                    onClick={e => this.linkPartner(e)}
                    size="sm"
                    block
                    disabled={!this.state.selectedPerson}
                  >
                    {"Seleccionar"}
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </ModalFrame>
        <div className="row p-0 m-0">
          <div className="col p-0 m-0">
            <BaseInput
              label={this.props.label || "Persona"}
              name="partner"
              type="input"
              id={"personSportsProductQuotation"}
              placeholder="Seleccionar socio"
              value={this.getPersonName()}
              disabled
              onChange={() => {}}
            />
          </div>
          <div className="col-1 p-1">
            <span
              onClick={() => !this.props.disabled && this.toggleModal()}
              style={{
                position: "absolute",
                top: "30px",
                cursor: "pointer",
                left: "20px",
                color: this.props.disabled ? "gray" : "black"
              }}
            >
              <FontAwesomeIcon icon={faUser} />
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => {
  const {
    persons: { selectedPerson }
  } = state;
  return {
    selectedPerson
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerSelector);
