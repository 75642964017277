import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import {
  infoNotification,
  ROLE_CREATION_WAITING,
  errorNotification,
  ROLE_CREATION_ERROR,
  ROLE_CREATION,
  successNotification,
  ERROR
} from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";
interface State {
  roleById: object;
}
const initialState: State = {
  roleById: null
};

export default function roleReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.SAVE_ROLE}_PENDING`: {
      infoNotification(ROLE_CREATION_WAITING);
      return {
        ...state
      };
    }
    case `${actionTypes.SAVE_ROLE}_REJECTED`: {
      errorNotification(readMessageBack(action, ROLE_CREATION_ERROR));
      return {
        ...state,
        roleById: null
      };
    }
    case `${actionTypes.SAVE_ROLE}_FULFILLED`: {
      successNotification(ROLE_CREATION);
      return {
        ...state,
        roleById: action.payload.data
      };
    }
    case `${actionTypes.GET_ROLE_BY_ID}_PENDING`: {
      return {
        ...state,
        roleById: null
      };
    }
    case `${actionTypes.GET_ROLE_BY_ID}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        roleById: null
      };
    }
    case `${actionTypes.GET_ROLE_BY_ID}_FULFILLED`: {
      return {
        ...state,
        roleById: action.payload.data
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
