import React from "react";
import { TabPane, Container, Row, Col, Button, Label } from "reactstrap";
import IAction from "../../../../types/IAction";
import s from "./../tabStyles.scss";
import ModalFrame from "../../../../toolkit/modalFrame";
import PersonSelector from "../../../../People/containers/PersonSelector";
import NotificationsMessages from "./../notificationsMessages";
import ProcedureChargesMessages from "./../procedureChargesMessages";
import {
  FIRST_MONTH,
  LAST_MONTH,
  TODAY_NUMBER_MONTH
} from "../../../../Quotations/components/QuotationTable";
import { Quotation } from "../../../../Quotations/components/Quotation";
import { Club } from "../../../../types-business/Clubs";
import QuotationTable from "../../../../Quotations/components/QuotationTable";
import { Filters, QuotationInterface } from "../../../../Quotations/types";
import { getEmptyFilters } from "../../../../Quotations/utils";
import isNil from "lodash.isnil";
import permissionCodes from "../../../../utils/permissionCodes";
import { userHasPermission } from "../../../../utils/accessTree";
import {
  switchMembershipRequest,
  switchMembershipValidationRequest,
  updateConvenioRequest
} from "../../../actions/partners";
import {
  errorNotification,
  infoNotification,
  successNotification,
  UPDATE_CONVENIO_INFO,
  UPDATE_CONVENIO_SUCCESS
} from "../../../../utils/notifications";
import TransferClubMembership from "./../transferClub";
import CancelMembership from "./cancelacionMembresia/cancelMembership";
import { PARTNER_MEMB_ACTIVE_STATUS } from "../../../../utils/constants";
import { handleRequestError } from "../../../../utils/helpers";
import { Partner } from "./../interfaces";
import { Typeahead } from "react-bootstrap-typeahead";
import CesionDerechos from "./cesionDerechos";
import CambioTitular from "./cambioTitular";
import PreviousMaintenanceChange from "./previousMaintenanceChange";

interface Props {
  partner: Partner;
  quotations: QuotationInterface;
  clubs: Club[];
  match: any;
  history: any;
  agreements: any[];
  successfullSwitchMembership: object;
  actualQuote: object[];
  getActualQuote: () => void;
  getQuotations: (personaId, args, integrantes) => void;
  rightsChange: (idMembresiaSocio: string, idPersona: string) => IAction;
  refreshPartner: () => void;
  activeTab: string;
  membershipBlockedReactivacion: boolean;
  previousMaintenancesList: any[];
}

interface State {
  isOpen: boolean;
  data: object;
  selectedPerson: any;
  isOpenRights: boolean;
  isOpenConvenio: boolean;
  convenioAsociado: any;
  convenioAsociadoId: any;
  membershipChangeValidationMode: boolean;
  membershipChangeMessages: any[];
  membershipChangeCharges: any[];
  membershipChangeConditions: object;
  empresaId: string;
}

const search = (f, id, filters, integrantes) => {
  f(
    id,
    {
      ...filters,
      mesInicio: FIRST_MONTH,
      mesFin: LAST_MONTH,
      diaInicio: TODAY_NUMBER_MONTH
    },
    integrantes
  );
};

let filters: Filters = getEmptyFilters();

const Quotations = props => {
  const onChangeFilters = newFilters => {
    if (newFilters.membresia && newFilters.club)
      search(
        props.getQuotations,
        props.partner.idPersona,
        { tipoPrecio: 1, clubAlternoId: newFilters.clubAlterno, ...newFilters },
        []
      );

    filters = newFilters;
  };

  const handleSwitch = () => {
    props.toggle({
      clubId: filters.club,
      clubAlternoId: filters.clubAlternoId,
      mantenimientoId: filters.mantenimiento,
      membresiaId: filters.membresia,
      idPromocion: filters.promocion,
      idConvenio: filters.convenio
    });
  };

  return (
    <Container className="w-100 p-0 my-3">
      <Label>
        <b>Cotizacion actual:</b>
      </Label>
      {props.actualQuote && (
        <QuotationTable quotation={props.actualQuote} enableColumnEdition />
      )}
      <hr />
      <Label>
        <b>Cotizacion propuesta:</b>
      </Label>
      <Quotation
        enableMemberEdition
        disableAgreements
        disablePromotions
        disableDiscounts
        disableReferredCode={true}
        disablePrices
        onChangeFilters={onChangeFilters}
        quotation={props.quotations}
      />

      <Col className="pl-0">
        <Button onClick={handleSwitch}>Aplicar cambios</Button>
      </Col>
    </Container>
  );
};

class TabSurveys extends React.Component<Props, State> {
  state = {
    isOpen: false,
    data: null,
    selectedPerson: null,
    isOpenRights: false,
    isOpenConvenio: false,
    convenioAsociadoId: null,
    convenioAsociado: null,
    membershipChangeValidationMode: false,
    membershipChangeMessages: [],
    membershipChangeCharges: [],
    membershipChangeConditions: null,
    empresaId: null
  };

  componentDidUpdate() {
    const { successfullSwitchMembership } = this.props;
    if (
      !isNil(successfullSwitchMembership) &&
      !isNil(successfullSwitchMembership[0])
    ) {
      if (!isNil(successfullSwitchMembership[0].data)) {
        const {
          idMembresiaSocio,
          idPublico,
          idClubBase
        } = successfullSwitchMembership[0].data;
        this.props.history.push(
          `/socio/venta-exitosa/${idMembresiaSocio}?idPublico=${idPublico}&idClub=${idClubBase}`
        );
      } else {
        successfullSwitchMembership.map(error => {
          if (error.tipo === "success") {
            this.props.history.push(
              `/productos/carrito/${this.props.partner.idPropietario}`
            );
          }
        });
      }
    }
  }

  toggleModal = (data?: object) => {
    this.setState({ isOpen: !this.state.isOpen, data: data });
  };

  toggleModalRights = () => {
    this.setState({ isOpenRights: !this.state.isOpenRights });
  };

  toggleModalConvenio = () => {
    this.setState({ isOpenConvenio: !this.state.isOpenConvenio });
  };

  setSelectedPerson = (person: any) => {
    this.setState({ selectedPerson: person });
  };

  handleRightsChange = () => {
    const { partner } = this.props;
    const { selectedPerson } = this.state;
    this.props.rightsChange(partner.idMembresiaSocio, selectedPerson.personaId);
    this.toggleModalRights();
  };

  handleAgreementAssociation = () => {
    const { convenioAsociadoId: convenioId, empresaId } = this.state;
    const { idMembresiaSocio } = this.props.partner;

    infoNotification(UPDATE_CONVENIO_INFO);
    updateConvenioRequest(convenioId, idMembresiaSocio, empresaId)
      .then(() => {
        successNotification(UPDATE_CONVENIO_SUCCESS);
        this.setState({
          isOpenConvenio: false
        });
      })
      .catch(handleRequestError);
  };

  validateMembershipChange = (data?: object) => {
    this.setState({ data, membershipChangeConditions: data });

    const { partner } = this.props;
    switchMembershipValidationRequest(data, partner.idMembresiaSocio)
      .then(({ data }) => {
        const { resultado, mensajes, cargosAGenerar } = data;
        this.setState({
          isOpen: !this.state.isOpen,
          membershipChangeMessages: mensajes,
          membershipChangeCharges: cargosAGenerar
        });
      })
      .catch(handleRequestError);
  };

  executeMembershipChange = () => {
    const { partner } = this.props;
    const { membershipChangeConditions } = this.state;

    switchMembershipRequest(
      membershipChangeConditions,
      partner.idMembresiaSocio,
      "1",
      "false"
    )
      .then(({ data }) => {
        const { resultado } = data;
        const membresiaSocio = data.data || {};

        if (!!resultado) {
          this.closeAndClearMembershipChangeData();

          const { idMembresiaSocio, idPublico, idClubBase } = membresiaSocio;

          successNotification("El cambio de membresía se realizó exitoamente");

          //Si hubo un cambio de club, se va a la pantalla de venta exitosa de membresía socio, si no, se va al carrito para el pago
          if (partner.idClubBase != idClubBase) {
            this.props.history.push(
              `/socio/venta-exitosa/${idMembresiaSocio}?idPublico=${idPublico}&idClub=${idClubBase}`
            );
          } else {
            this.props.history.push(
              `/productos/carrito/${this.props.partner.idPropietario}`
            );
          }
        } else {
          errorNotification(
            "Ocurrió un error al ejecutar la petición de cambio de membresía"
          );
          this.closeAndClearMembershipChangeData();
        }
      })
      .catch(err => {
        errorNotification(
          "Ocurrió un error al ejecutar la petición de cambio de membresía"
        );
        this.closeAndClearMembershipChangeData();
      });
  };

  closeAndClearMembershipChangeData() {
    this.setState({
      isOpen: !this.state.isOpen,
      data: null,
      membershipChangeConditions: null,
      membershipChangeMessages: [],
      membershipChangeCharges: []
    });
  }

  cancelMembershipChange = () => {
    //Se cierra el modal y se limpian los datos
    this.closeAndClearMembershipChangeData();
  };

  render() {
    if (this.props.activeTab != "6") {
      return <></>;
    }

    return (
      <TabPane className="my-2" tabId="6">
        {/* CAMBIO DE MEMBRESIA */}
        <ModalFrame
          title={"Confirmar cambio de membresía"}
          isOpen={this.state.isOpen}
          toggle={this.toggleModal}
          size="lg"
        >
          <Container>
            <p>Mensajes:</p>
            <br />
            <NotificationsMessages
              messages={this.state.membershipChangeMessages || []}
            />

            <br />
            <br />
            <p>Cargos a generar:</p>
            <br />
            <ProcedureChargesMessages
              cargos={this.state.membershipChangeCharges || []}
            />

            {!(this.state.membershipChangeMessages || []).length && (
              <Row>
                <Col>
                  <br />
                  <p className={`text-center w-100`}>
                    ¿Desea realizar el cambio de membresía?
                  </p>
                </Col>
              </Row>
            )}
            <Row>
              <Col className="text-right">
                <Button
                  color="secondary"
                  onClick={this.cancelMembershipChange}
                  style={{ marginRight: "15px" }}
                >
                  Cancelar
                </Button>

                {!(this.state.membershipChangeMessages || []).length && (
                  <Button
                    onClick={this.executeMembershipChange}
                    color="primary"
                  >
                    Cambiar
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        </ModalFrame>

        {/* ASOCIACION DE CONVENIO */}
        <ModalFrame
          title={"¿Desea asociar el convenio?"}
          isOpen={this.state.isOpenConvenio}
          toggle={this.toggleModalConvenio}
        >
          <Container>
            <Row>
              <p className={`text-center w-100 mb-5`}>
                Se asociará el convenio {`${this.state.convenioAsociado}`} a la
                membresía socio. ¿Desea continuar?
              </p>
            </Row>
            <Row>
              <Col className="text-right">
                <Button
                  color="secondary"
                  onClick={this.toggleModalConvenio}
                  style={{ marginRight: "15px" }}
                >
                  Cancelar
                </Button>
                <Button
                  color="primary"
                  onClick={this.handleAgreementAssociation}
                >
                  Asociar
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalFrame>

        <Container>
          {/* Cancelación de membresía socio */}
          {userHasPermission(
            permissionCodes.PARTNER_MEMBERSHIP_TAB_TRAM_CANCEL_MEMBERSHIP
          ) && (
            <div className="mt-4">
              <div className="row mt-3 mb-3">
                <div className="col">
                  <p className={`${s.primaryTitle} w-100 m-0`}>
                    Cancelación de membresía socio
                  </p>
                </div>
              </div>
              <div>
                <CancelMembership
                  partner={this.props.partner}
                  reload={this.props.refreshPartner}
                />
              </div>
            </div>
          )}

          {/* CAMBIO A MANTENIMIENTO PREVIO */}
          {(this.props.partner || {}).estatusMembresia ==
            PARTNER_MEMB_ACTIVE_STATUS &&
            userHasPermission(
              permissionCodes.PARTNER_MEMBERSHIP_TAB_TRAM_CHANGE_PREVIOUS_MAINTENANCE
            ) &&
            (this.props.previousMaintenancesList || []).length > 0 && (
              <PreviousMaintenanceChange
                className="mt-4"
                partner={this.props.partner}
                refreshPartner={this.props.refreshPartner}
                previousMaintenancesList={this.props.previousMaintenancesList}
              />
            )}

          {/* CESION DE DERECHOS */}
          {(this.props.partner || {}).estatusMembresia ==
            PARTNER_MEMB_ACTIVE_STATUS &&
            userHasPermission(
              permissionCodes.PARTNER_MEMBERSHIP_TAB_TRAM_CESION
            ) && (
              <CesionDerechos
                className="mt-4"
                partner={this.props.partner}
                rightsChange={this.props.rightsChange}
                refreshPartner={this.props.refreshPartner}
              />
            )}

          {/* CAMBIO DE TITULAR */}
          {(this.props.partner || {}).estatusMembresia ==
            PARTNER_MEMB_ACTIVE_STATUS &&
            userHasPermission(
              permissionCodes.PARTNER_MEMBERSHIP_TAB_TRAM_CHANGE_TITULAR
            ) && (
              <CambioTitular
                className="mt-4"
                partner={this.props.partner}
                refreshPartner={this.props.refreshPartner}
              />
            )}

          {/* ASOCIACION DE CONVENIO */}
          {userHasPermission(
            permissionCodes.PARTNER_MEMBERSHIP_ASSIGN_AGGREMENT_PERMISSION
          ) &&
            (this.props.partner || {}).estatusMembresia ==
              PARTNER_MEMB_ACTIVE_STATUS && (
              <div className="mt-4">
                <div className="row mt-3 mb-3">
                  <div className="col">
                    <p className={`${s.primaryTitle} w-100 m-0`}>
                      Asociación de convenio
                    </p>
                  </div>
                </div>
                <Row className="row mt-3 mb-3">
                  <Col>
                    <Label className={s.label}>Convenio:</Label>
                    <Typeahead
                      label={"Convenio"}
                      name="Convenio"
                      type="select"
                      id="Convenio"
                      promptText={"Convenio"}
                      bsSize={"sm"}
                      emptyLabel={"Sin coincidencias"}
                      options={
                        this.props.agreements
                          ? this.props.agreements.map(agreement => ({
                              id: `${agreement.convenioId}-${agreement.empresaId}`,
                              label: agreement.empresa
                            }))
                          : []
                      }
                      onChange={sel =>
                        this.setState({
                          convenioAsociadoId:
                            sel.length > 0 ? sel[0]["id"].split("-")[0] : null,
                          convenioAsociado:
                            sel.length > 0 ? sel[0]["label"] : null,
                          empresaId:
                            sel.length > 0 ? sel[0]["id"].split("-")[1] : null
                        })
                      }
                    />
                  </Col>
                  <Col className="mt-4">
                    <Button
                      size="sm"
                      className={`float-right btn-block`}
                      onClick={this.toggleModalConvenio}
                      disabled={
                        this.state.convenioAsociadoId === "" ||
                        this.state.convenioAsociadoId === null
                      }
                    >
                      Asociar convenio
                    </Button>
                  </Col>
                </Row>
              </div>
            )}

          {/* CAMBIO DE CLUB, EXCLUSIVAMENTE */}
          {(this.props.partner || {}).estatusMembresia ==
            PARTNER_MEMB_ACTIVE_STATUS &&
            userHasPermission(
              permissionCodes.PARTNER_MEMBERSHIP_TAB_TRAM_TRANSFER_CLUB
            ) && (
              <div className="mt-4">
                <div className="row mt-3 mb-3">
                  <div className="col">
                    <p className={`${s.primaryTitle} w-100 m-0`}>
                      Traspaso de club conservando el mantenimiento actual
                    </p>
                  </div>
                </div>
                <div style={{ marginLeft: "-15px", marginRight: "-15px" }}>
                  <TransferClubMembership
                    partner={this.props.partner}
                    clubs={this.props.clubs}
                    redirect={url => {
                      location.pathname = url;
                    }}
                  />
                </div>
              </div>
            )}

          {/* CAMBIO DE MEMBRESIA */}
          {(this.props.partner || {}).estatusMembresia ==
            PARTNER_MEMB_ACTIVE_STATUS && (
            <div className="mt-4">
              <div className="row mt-3 mb-3">
                <div className="col">
                  <p className={`${s.primaryTitle} w-100 m-0`}>
                    Cambio de membresía o traspaso
                  </p>
                </div>
              </div>
              <Row className="p-0 m-0">
                <Quotations
                  {...this.props}
                  toggle={this.validateMembershipChange}
                />
              </Row>
            </div>
          )}
        </Container>
      </TabPane>
    );
  }
}

export default TabSurveys;
