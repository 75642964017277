import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import { withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import { Props } from "./interfaces";
import DeleteCatForzosoReport from "./reportsComponents/deleteCatForzosoReport";
import s from "./styles.scss";

class MiscReports extends React.Component<Props> {
  componentDidMount() {}

  render() {
    return (
      <Container className="p-0">
        <DeleteCatForzosoReport className="mt-5" />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(MiscReports));
