import React from "react";
import { connect } from "react-redux";
import * as agreementActions from "../../../actions/agreementGroups";
import * as productActions from "../../../actions/products";
import { withRouter } from "react-router-dom";
import MaintenancesSelector from "../../../components/maintenancesSelector";
import { setChildStep } from "../../../helpers/stepper-state-comparator";

// TODO: Esta funcionalidad se heredó de los convenios; faltan exclusiones

interface Props {
  maintenances: { datosBasicosMantenimientoId: string; nombre: string }[];
  searchMaintenancesByAttributes: () => any;
  successfulSave: boolean;
  onClickSave: (e: object) => any;
  addMaintenancesToAgreement: (
    convenioId: string,
    maintenances: object[]
  ) => any;
  getMaintenancesAgreement: (id: string) => any;
  maintenancesAgreement: { datosBasicosMantenimientoId: string }[];
  match: any;
}

interface State {
  selected: object[];
}

class AddMaintenancesAgreementPage extends React.Component<Props, State> {
  state = {
    selected: null
  };

  selectedMaintenances = [];

  componentDidMount() {
    const { id } = this.props.match.params;

    this.props.onClickSave(e => {
      e.preventDefault();
      this.props.addMaintenancesToAgreement(
        id,
        this.selectedMaintenances.map(({ id: mantenimientoId }) => ({
          mantenimientoId
        }))
      );
    });

    this.props.getMaintenancesAgreement(id);
  }

  selectedLoaded = false;

  componentDidUpdate() {
    if (
      !this.selectedLoaded &&
      this.props.maintenances &&
      this.props.maintenancesAgreement
    ) {
      const selected = this.props.maintenancesAgreement.map(mantenimiento => {
        const { nombre } = this.props.maintenances.find(
          ({ datosBasicosMantenimientoId }) =>
            datosBasicosMantenimientoId ==
            mantenimiento.datosBasicosMantenimientoId
        );
        return {
          nombre,
          datosBasicosMantenimientoId: mantenimiento.datosBasicosMantenimientoId
        };
      });

      setChildStep(selected);
      this.setState({
        selected
      });
      this.selectedLoaded = true;
    }
  }

  render() {
    return (
      <MaintenancesSelector
        items={this.props.maintenances}
        selected={this.state.selected}
        updateSelection={({ selected }) => {
          setChildStep(selected);
          this.selectedMaintenances = selected;
        }}
        searchByText={this.props.searchMaintenancesByAttributes}
        keyId="datosBasicosMantenimientoId"
        keyName="nombre"
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  searchMaintenancesByAttributes: (attributes: object) => {
    dispatch(productActions.searchByAttributes(attributes));
  },
  addMaintenancesToAgreement: (convenioId: string, maintenances: object[]) => {
    dispatch(
      agreementActions.addMaintenancesToAgreement(convenioId, maintenances)
    );
  },
  getMaintenancesAgreement: (id: string) => {
    dispatch(agreementActions.getMaintenancesAgreement(id));
  }
});

const mapStateToProps = state => {
  const { products } = state;
  const agreements = state.agreementGroups;

  return {
    successfulSave: agreements.maintenancesSaved,
    validClubs: agreements.agreementClubs,
    maintenances: products.maintenancesByAttributes,
    maintenancesAgreement: agreements.agreementMaintenances
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddMaintenancesAgreementPage)
);
