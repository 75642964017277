const columns = [
  {
    Header: "Club",
    id: "club",
    accessor: "club",
    width: "128"
  },
  {
    Header: "Membresía",
    id: "idPublico",
    accessor: "idPublico",
    width: "64"
  },
  {
    Header: "Titular",
    id: "titular",
    accessor: "titular",
    width: "256"
  },
  {
    Header: "Razón de elim.",
    id: "razon",
    accessor: "razon",
    width: "128"
  },
  {
    Header: "Fecha de elim.",
    id: "fecha",
    accessor: "fecha",
    width: "128"
  },
  {
    Header: "Emisor",
    id: "emisor",
    accessor: "emisor",
    width: "128"
  },
  {
    Header: "Tipo",
    id: "tipo",
    accessor: "tipo",
    width: "64"
  },
  {
    Header: "Responsable",
    id: "responsable",
    accessor: "responsable",
    width: "256"
  }
];

export default columns;
