import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import * as catalogActions from "../../actions/catalogs";
import * as reportsActions from "../../actions/reports";
import CashCutReport from "../../components/cashCutReport";
import IAction from "../../../types/IAction";

interface Props {
  getClubs: () => void;
  getCashCut: (clubId: string, fechaDePago: string) => IAction;
  pdfCashCut: (data: object, clubId: string, fechaDePago: string) => IAction;
  clearCashCut: () => IAction;
  clubs: object[];
  reportCashCut: any;
}

interface State {}

class CashCut extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    this.props.getClubs();
  }

  componentWillUnmount() {
    this.props.clearCashCut();
  }

  render() {
    return (
      <main>
        <Helmet title="Box Cut" />
        <Layout>
          <CashCutReport
            getCashCut={this.props.getCashCut}
            pdfCashCut={this.props.pdfCashCut}
            clubs={this.props.clubs}
            reportCashCut={this.props.reportCashCut}
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({ catalogs, reports }) => {
  return {
    clubs: catalogs.clubs,
    reportCashCut: reports.reportCashCut
  };
};

const mapDispatchToProps = dispatch => ({
  getClubs: () => {
    dispatch(catalogActions.getClubs());
  },
  getCashCut: (clubId: string, fechaDePago: string) => {
    return dispatch(reportsActions.getCashCut(clubId, fechaDePago));
  },
  pdfCashCut: (data: object, clubId: string, fechaDePago: string) => {
    return dispatch(reportsActions.pdfCashCut(data, clubId, fechaDePago));
  },
  clearCashCut: () => {
    return dispatch(reportsActions.clearCashCut());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CashCut);
