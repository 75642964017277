import {
  Archivo,
  FlujoDocumento,
  StatusAutorizacion,
  TipoBoton
} from "../types";
import React from "react";
import s from "./styles.sm.scss";
import { Styled } from "../../../../toolkit/decorators/Artifact";
import DocBotonSm from "../docBoton/docBotonSm";
import { Button } from "reactstrap";
import ConfirmModalLink from "../../common/confirmModalLink/confirmModalLink";
import { Preview } from "../../../../types/IAction";
import constants from "../../../../utils/constants";
import FormBuilderModal from "../../common/formBuilderModal/formBuilderModal";
import FormBuilderConfig from "../../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../../../utils/notifications";

const URL_BASE = `${constants.BASE_URL}/${constants.DOCUMENTS_BASE_URL}/`;
const URL_SWORD = `${constants.BASE_URL}/api/sworld/`;
const MSG_WAIT_SCAN_SM = "Finalizar";
const MSG_WAIT_SIGN_SM = "Finalizar";

interface Props {
  // Archivo por mostrar
  archivo: Archivo;

  // Identificador del usuario
  userId: number;

  // Indican qué botones mostrarán; por defecto se observan
  botonCorreo?: boolean;
  botonFirmaOEscanner?: boolean;
  botonVer?: boolean;
  botonRegenrar?: boolean;
  formConfigRegenerar?: FormBuilderConfig<any, "aceptar" | "cancelar">;

  // Métodos externos, para correo, regenerar o actualizar despues de firmar
  sendEmail?: (archivo: Archivo) => Promise<Preview<any>>;
  regenerar?: (archivo: Archivo, formValue?: any) => Promise<Preview<any>>;
  update?: (archivo?: Archivo) => Promise<Preview<any>>;
  cincelFirma?: (archivo?: Archivo) => Promise<Preview<any>>;
}

interface State {
  // Indica que el componente está esperando una acción determinada
  waiting: boolean;
  waitMsg: string;

  // Control del diálogo de confirmación
  confirm: {
    open: boolean;
    message?: string;
    title?: string;
    link?: string;
    ok?: () => void;
    urlUpload?: string;
  };

  // Diálogo de FormConfig
  formModalShow: boolean;

  // Indica si el correo ya fue enviado
  sended: boolean;
}

/**
 * NOTE: Esta es una copia casi idéntica de "archivoLg", necesaria para mostrar
 * los controles en la tabla; es decir, solo cambia la hoja de estilos, algunos
 * mensajes y los botones.
 *
 * Por favor, manténgalo actualizado.
 */
@Styled(s)
export default class ArchivoControlSm extends React.Component<Props, State> {
  state = {
    waiting: false,
    waitMsg: "",
    confirm: {
      open: false,
      message: null,
      title: null,
      link: null,
      ok: null,
      urlUpload: null
    },
    formModalShow: false,
    sended: false
  };

  // Se invoca cuando se solicita firmar un documento
  actionFirma = (urlSign: string) => {
    if (!this.props.archivo.storage) {
      errorNotification("Regenere el documento antes de proceder a la firma");
      return;
    }

    if (this.props.archivo.migrado) {
      errorNotification(
        "Este archivo proviene de la versión anterior, regenere el documento por favor."
      );
      return;
    }

    if (this.props.archivo.completo) {
      this.setState({
        confirm: {
          open: true,
          message: "El documento ya había sido firmado, ¿desea continuar?",
          title: "Confirme el firmado",
          link: urlSign,
          ok: () =>
            this.setState({
              waiting: true,
              waitMsg: MSG_WAIT_SIGN_SM
            })
        }
      });
    } else {
      this.setState({
        waiting: true,
        waitMsg: MSG_WAIT_SIGN_SM
      });
    }
  };

  // Se invoca cuando se solicita escanear un documento
  actionScan = (urlScan: string, urlUpload: string) => {
    const message = this.props.archivo.completo
      ? "El documento ya había sido escaneado, ¿desea continuar?"
      : "Indique si va a escanear el archivo o seleccionarlo";

    this.setState({
      confirm: {
        open: true,
        message,
        title: "Confirme la carga del archivo",
        link: urlScan,
        ok: () =>
          this.setState({
            waiting: true,
            waitMsg: MSG_WAIT_SCAN_SM
          }),
        urlUpload
      }
    });
  };

  // Se invoca cuando se solicita una regeneración
  actionRegen = () => {
    if (this.props.formConfigRegenerar) {
      this.setState({ formModalShow: true });
      return;
    }

    this.setState({
      confirm: {
        open: true,
        message: "¿Desea continuar con la regeneración?",
        title: "Confirme la regeneración",
        ok: () => this.props.regenerar(this.props.archivo)
      }
    });
  };

  // Se invoca cuando el usuario oprime el botón después de escanear o firmar
  updateAfterSWidget = async () => {
    if (this.props.update) {
      await this.props.update(this.props.archivo);
    }

    this.setState({
      waiting: false
    });
  };

  // Se invoca al cerrar el modal
  resultConfirm = (ok: boolean) => {
    if (ok && this.state.confirm.ok) {
      this.state.confirm.ok();
    }

    this.setState({
      confirm: {
        open: false
      }
    });
  };

  uploadSuccess = () => {
    this.updateAfterSWidget();
    this.setState({
      confirm: {
        open: false
      }
    });
  };

  render() {
    if (!this.props.archivo) {
      return <></>;
    }

    const postClass = this.renderAutorizacionClass();
    const nombreStyle = this.renderNombreStyle();
    const annotation =
      this.props.archivo.statusAutorizacion == StatusAutorizacion.NO_GENERADO
        ? " (No generado)"
        : "";

    return (
      <>
        <div className={`${s.archivo}${postClass}`}>
          {/* Nombre del archivo */}
          <div className={s.nombre} style={nombreStyle}>
            {this.props.archivo.nombre}
            {annotation}
          </div>

          {/* Acciones aplicables al archivo */}
          {this.renderBotonCorreo()}
          {this.renderBotonFirmaOEscaner()}
          {this.renderBotonFirmasCincel()}
          {this.renderBotonAuditoria()}
          {this.renderBotonVer()}
          {this.renderBotonRegenerar()}

          {/* Máscara, en caso de invocar a SWidget */}
          <div hidden={!this.state.waiting} className={s.waitMask}>
            <Button
              onClick={this.updateAfterSWidget}
              className={s.buttonMargin}
            >
              {this.state.waitMsg}
            </Button>
          </div>
        </div>

        {/* Modal de confirmación */}
        <ConfirmModalLink
          title={this.state.confirm.title}
          message={this.state.confirm.message}
          isOpen={this.state.confirm.open}
          ok={() => this.resultConfirm(true)}
          cancel={() => this.resultConfirm(false)}
          success={this.uploadSuccess}
          linkConfirm={this.state.confirm.link}
          urlUpload={this.state.confirm.urlUpload}
        />

        {/* Modal de formulario de regeneración */}
        <FormBuilderModal
          isOpen={this.state.formModalShow}
          title="Información adicional"
          message="Complete la información del documento"
          ok={async values => {
            const prev = await this.props.regenerar(this.props.archivo, values);
            prev.fulfilled && this.setState({ formModalShow: false });
            this.setState({ formModalShow: false });
          }}
          cancel={() => this.setState({ formModalShow: false })}
          formConfig={this.props.formConfigRegenerar}
          size={"md"}
        />
      </>
    );
  }

  renderAutorizacionClass() {
    const moduloExpedientes = this.props.archivo.moduloExpedientes;
    const requerido = this.props.archivo.requeridoComision
      ? ` ${s.requerido}`
      : "";

    if (moduloExpedientes) {
      switch (this.props.archivo.statusAutorizacion) {
        case StatusAutorizacion.NO_GENERADO:
          return ` ${s.noGenerado}`;
        case StatusAutorizacion.NO_AUTORIZADO:
          return this.props.archivo.requeridoComision
            ? ` ${s.noAutorizado}`
            : "";
        case StatusAutorizacion.AUTORIZADO:
          return ` ${s.autorizado}${requerido}`;
        case StatusAutorizacion.FIRMA_INVALIDA:
          return ` ${s.firmaInvalida}${requerido}`;
        case StatusAutorizacion.DOCUMENTO_INCORRECTO:
          return ` ${s.documentoIncorrecto}${requerido}`;
        default:
          return "";
      }
    } else {
      return this.props.archivo.completo ? ` ${s.autorizado}` : "";
    }
  }

  renderBotonCorreo() {
    if (
      this.props.botonCorreo === false ||
      this.props.archivo.flujo == FlujoDocumento.CINCEL
    ) {
      return <></>;
    }

    // Si el documento se escaneó o ya se envió, no se debe enviar
    if (
      this.props.archivo.flujo == FlujoDocumento.ESCANEAR ||
      this.state.sended
    ) {
      return (
        <DocBotonSm
          tipoBoton={TipoBoton.CORREO}
          completo={this.props.archivo.completo}
        />
      );
    }

    return (
      <DocBotonSm
        tipoBoton={TipoBoton.CORREO}
        action={async () => {
          this.setState({ sended: true });
          infoNotification("Enviando correo, espere un momento por favor...");

          const preview = await this.props.sendEmail(this.props.archivo);

          if (preview.fulfilled) {
            successNotification("Correo enviado correctamente.");
          } else {
            errorNotification(
              "No se pudo enviar el correo, intente de nuevo más tarde."
            );
            this.setState({ sended: false });
          }
        }}
        wait={this.state.waiting}
        completo={this.props.archivo.completo}
      />
    );
  }

  renderBotonFirmaOEscaner() {
    if (
      this.props.botonFirmaOEscanner === false ||
      this.props.archivo.flujo == FlujoDocumento.CINCEL
    ) {
      return <></>;
    }

    // Cálculo de las posibles rutas que se aplicarán
    const {
      idExpediente,
      idItem,
      completo,
      storage,
      migrado
    } = this.props.archivo;
    const idUser = Number(this.props.userId) || 0;
    const urlScan = `sworld://scanner/${idExpediente}/${idItem}/${idUser}`;
    const urlSign = `sworld://signature/${idExpediente}/${idItem}/${idUser}`;
    const urlUpload = `${URL_SWORD}expedients/${idExpediente}/upload/${idItem}`;

    // Selección del botón; escaner, firma o ninguno
    switch (this.props.archivo.flujo) {
      // Si el documento es personal o requiere firma con bolígrafo, se escanea
      case FlujoDocumento.ESCANEAR:
      case FlujoDocumento.FIRMA_MATERIAL:
        return (
          <DocBotonSm
            tipoBoton={TipoBoton.ESCANEAR}
            link={null}
            action={() => this.actionScan(urlScan, urlUpload)}
            wait={this.state.waiting}
            completo={this.props.archivo.completo}
          />
        );

      // Si el documento requiere una firma digital, se solicita
      case FlujoDocumento.FIRMA_DIGITAL:
        return (
          <DocBotonSm
            tipoBoton={TipoBoton.FIRMAR}
            link={completo || !storage || migrado ? null : urlSign}
            action={() => this.actionFirma(urlSign)}
            wait={this.state.waiting}
            completo={this.props.archivo.completo}
          />
        );

      // Si el documento no requiere firma ni ser escaneado, se omite el botón
      default:
        return (
          <DocBotonSm
            tipoBoton={TipoBoton.FIRMAR}
            completo={this.props.archivo.completo}
          />
        );
    }
  }

  renderBotonVer() {
    if (this.props.botonVer === false) {
      return <></>;
    }

    // Para ver el archivo, debe estar generado
    if (!this.props.archivo.storage) {
      return (
        <DocBotonSm
          tipoBoton={TipoBoton.VER}
          completo={this.props.archivo.completo}
        />
      );
    }

    return (
      <DocBotonSm
        tipoBoton={TipoBoton.VER}
        link={`${URL_BASE}${this.props.archivo.storage}`}
        wait={this.state.waiting}
        completo={this.props.archivo.completo}
      />
    );
  }

  renderBotonRegenerar() {
    if (this.props.botonRegenrar === false) {
      return <></>;
    }

    // Para escanear un nuevo documento, no se necesita regenerar
    if (this.props.archivo.flujo == FlujoDocumento.ESCANEAR) {
      return (
        <DocBotonSm
          tipoBoton={TipoBoton.REGENERAR}
          completo={this.props.archivo.completo}
        />
      );
    }

    return (
      <DocBotonSm
        tipoBoton={TipoBoton.REGENERAR}
        action={() => this.actionRegen()}
        wait={this.state.waiting}
        completo={this.props.archivo.completo}
      />
    );
  }

  // CINCEL - Documento de auditoría
  renderBotonAuditoria() {
    if (this.props.archivo.flujo != FlujoDocumento.CINCEL) {
      return <></>;
    }

    // Para ver el archivo, debe estar almacenado
    if (!this.props.archivo.storageCincelAudit) {
      return (
        <DocBotonSm
          tipoBoton={TipoBoton.AUDITORIA}
          completo={this.props.archivo.completo}
        />
      );
    }

    return (
      <DocBotonSm
        tipoBoton={TipoBoton.AUDITORIA}
        link={`${URL_BASE}${this.props.archivo.storageCincelAudit}`}
        wait={this.state.waiting}
        completo={this.props.archivo.completo}
      />
    );
  }

  // CINCEL - Consulta de firmas
  renderBotonFirmasCincel() {
    if (this.props.archivo.flujo != FlujoDocumento.CINCEL) {
      return <></>;
    }

    // Para escanear un nuevo documento, no se necesita regenerar
    if (this.props.archivo.completo) {
      return (
        <DocBotonSm
          tipoBoton={TipoBoton.CINCEL}
          completo={this.props.archivo.completo}
        />
      );
    }

    return (
      <DocBotonSm
        tipoBoton={TipoBoton.CINCEL}
        action={() => this.props.cincelFirma(this.props.archivo)}
        wait={this.state.waiting}
        completo={this.props.archivo.completo}
      />
    );
  }

  // Este método es muy naco, pero son tres de la mañana
  renderNombreStyle = () => {
    let numeroHide = 0;

    if (
      this.props.botonCorreo === false &&
      this.props.archivo.flujo != FlujoDocumento.CINCEL
    ) {
      numeroHide += 1;
    }
    if (
      this.props.botonFirmaOEscanner === false &&
      this.props.archivo.flujo != FlujoDocumento.CINCEL
    ) {
      numeroHide += 1;
    }
    if (this.props.botonVer === false) {
      numeroHide += 1;
    }
    if (this.props.botonRegenrar === false) {
      numeroHide += 1;
    }

    return {
      width: `calc(100% - ${225 - 56 * numeroHide}px)`,
      maxWidth: `calc(100% - ${224 - 56 * numeroHide}px)`
    };
  };
}
