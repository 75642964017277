import React from "react";
import FormBuilder from "../../../toolkit/baseForms2/formBuilder/FormBuilder";
import { formConfig, Inputs, Buttons } from "./LockerExtension.form";
import { LockerExtensionStore } from "../../containers/lockerExtension/LockerExtension.store";
import { LockerExtensionDispatcher } from "../../containers/lockerExtension/LockerExtension.dispatcher";
import ISelectBuilder from "../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";
import { Archivo } from "../expedientsV2controls/types";
import { Button, Container, Row, Col } from "reactstrap";
import ArchivoControl from "../expedientsV2controls/archivo/archivo";
import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import s from "./styles.scss";
import { Styled, RouterProps } from "../../../toolkit/decorators/Artifact";
import IButtonBuilder from "../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";

interface Props
  extends LockerExtensionStore,
    LockerExtensionDispatcher,
    RouterProps<any> {
  idProductoLocker: string;
}
interface State {
  productos: any[];
  archivo: Archivo;
  values: { [key in Inputs]: any };
}

@Styled(s)
export default class LockerExtensionPage extends React.Component<Props, State> {
  state = {
    productos: [],
    archivo: null as Archivo,
    values: {} as { [key in Inputs]: any }
  };

  async componentDidMount() {
    const prevProducto = await this.props.getLockerProduct(
      this.props.idProductoLocker
    );

    if (!prevProducto.fulfilled) {
      errorNotification(
        "No se pudo cargar la información del locker, intente más tarde"
      );
      return;
    }

    if (!this.state.productos.length) {
      const prevLockers = await this.props.getLockersProducts();

      this.setState({
        productos:
          prevLockers.lockersProducts[prevProducto.lockerProduct.lockerSize] ||
          []
      });
    }
  }

  generarArchivo = async () => {
    const productSelected = this.state.productos.find(
      ps => ps.id == this.state.values.productoId
    );

    const producto = productSelected
      ? (productSelected.nombre || "").replace(/(^.+\D)(\d+)(\D.+$)/i, "$2")
      : "0";

    const preview = await this.props.postRefresh({
      codigo: "LOCKER",
      idMembresiaSocio: this.props.lockerProduct.idMembresiaSocio,
      idPersonaExpediente: this.props.lockerProduct.personaId,
      idPersonaDocumento: this.props.lockerProduct.personaId,
      values: {
        casillero: this.props.lockerProduct.lockerNumero,
        club: this.props.lockerProduct.clubId,
        clubMembresia:
          this.props.lockerProduct.clubBase || this.props.lockerProduct.clubId,
        idPublico: this.props.lockerProduct.idPublico,
        nombreCompleto: this.props.lockerProduct.nombrePersona,
        tamanio: this.props.lockerProduct.lockerSize,
        ubicacion: this.props.lockerProduct.lockerPosicion,
        meses: producto,
        dias: this.props.lockerProduct.dias || 0
      }
    });

    if (preview.fulfilled) {
      this.setState({
        archivo: preview.archivoRefresh
      });
    }
  };

  verArchivo = async () => {
    const preview = await this.props.getArchivoVigente(
      this.state.archivo.idExpediente,
      this.state.archivo.idDocumento
    );

    if (preview.fulfilled) {
      this.setState({
        archivo: preview.archivoVigente
      });
    } else {
      errorNotification("No se encontró el contrato");
    }

    return preview;
  };

  onFormChange = values => {
    this.setState({ values });
  };

  submit = async values => {
    if (!this.state.archivo || !this.state.archivo.completo) {
      errorNotification("Debe escanear el documento");
      return;
    }

    infoNotification("Reasignando el locker");
    const preview = await this.props.putLockerExtension({
      ...values,
      lockerProductoId: this.props.idProductoLocker,
      storage: this.state.archivo.storage
    });

    preview.fulfilled
      ? successNotification("Locker reasignado correctamente")
      : errorNotification(preview.puttingLockerExtensionError);
  };

  render() {
    const producto = this.props.lockerProduct;
    const titulo = producto
      ? `${producto.nombrePersona}  -  LOCKER ${producto.lockerNumero}, ${producto.dias} DÍAS RESTANTES`
      : " ";

    return (
      <Container>
        <Row>
          <p className="primaryTitle w-100 mt-4">{titulo}</p>
        </Row>

        <FormBuilder<Inputs, Buttons>
          config={formConfig}
          submit={this.submit}
          onChange={this.onFormChange}
          processing={this.props.puttingLockerExtension}
        >
          <ISelectBuilder<Inputs>
            name="productoId"
            data={this.state.productos}
            mapOption={l => ({ value: l.id, label: l.nombre })}
          />
          <IButtonBuilder<Inputs, Buttons>
            name="cancelar"
            onClick={e => this.props.history.push("/lockers/asignados")}
          />
          <div className="col-sm-2 pt-4" data-form-name="generar">
            <Button
              key="-btn-archivo"
              className={`${s.complementaryButton}`}
              block
              size="sm"
              onClick={this.generarArchivo}
              disabled={
                !this.state.values.productoId ||
                !this.state.values.responsableId ||
                this.props.archivoRefreshLoading
              }
            >
              {!this.props.archivoRefreshLoading
                ? "Generar documento"
                : "Espere..."}
            </Button>
          </div>
          <div
            className="col-sm-2 mt-4 mb-1"
            style={{ background: "#eee" }}
            data-form-name="archivo"
          >
            <ArchivoControl
              key="-archivo"
              archivo={this.state.archivo}
              botonCorreo={false}
              botonRegenrar={false}
              userId={0}
              sm={true}
              update={this.verArchivo}
            />
          </div>
        </FormBuilder>
      </Container>
    );
  }
}
