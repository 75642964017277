import React from "react";
import { withRouter } from "react-router-dom";
import {
  Container,
  Label,
  FormGroup,
  Input,
  Row,
  Col,
  Button,
  UncontrolledTooltip
} from "reactstrap";
import { setChildStep } from "../../../helpers/stepper-state-comparator";
import { divideArrayInSubArrays } from "../../../../utils/helpers";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import forEach from "lodash.foreach";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./styles.scss";
import IAction from "../../../../types/IAction";

interface Props {
  getSPComissionsOperation: (id: string) => any;
  saveSportsProductComissions: (id: string, fields: object) => IAction;
  autorizeComissions: (id: string, tipo: string) => IAction;
  comissionsByIdOperation: any;
  catalogPostions: any;
  match: any;
}

interface State {
  fields: any;
  load: boolean;
  selectedPuestos: object;
}

const AmountInput = ({ value, onChange, symbol, label, hasDecimal }) => (
  <div className="form-row">
    <div className="col">
      <Label className={s.label}>{`${label} (${symbol})`}:</Label>
      <Input
        type="number"
        value={value}
        onChange={evt => onChange(evt, hasDecimal)}
        step={hasDecimal ? "0.1" : "1"}
      />
    </div>
  </div>
);

class SalesCommisions extends React.Component<Props, State> {
  state = {
    fields: {
      monto: 0,
      porcentaje: 0,
      aplicaPagoParcial: false
    },
    load: false,
    selectedPuestos: {}
  };

  componentDidMount() {
    this.props.getSPComissionsOperation(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    const { comissionsByIdOperation } = this.props;
    const { comissionsByIdOperation: prevcomissionsByIdOperation } = prevProps;

    if (!prevcomissionsByIdOperation && comissionsByIdOperation) {
      const fields = {
        ...this.state.fields,
        ...comissionsByIdOperation[0]
      };

      setChildStep(fields);

      this.setState({
        fields
      });
    }

    if (
      this.props.comissionsByIdOperation !== null &&
      this.props.comissionsByIdOperation.length &&
      this.props.catalogPostions &&
      !this.state.load
    ) {
      const puestosExistentes = this.props.comissionsByIdOperation[0].puestos;
      const selectedPuestos = {
        ...this.state.selectedPuestos
      };

      puestosExistentes.map(puestoId => {
        this.props.catalogPostions.map(puesto => {
          if (puesto.positionId === puestoId) {
            selectedPuestos[puesto.positionId] = true;
          }
        });
      });

      setChildStep(selectedPuestos);
      this.setState(state => ({ ...state, selectedPuestos, load: true }));
    }
  }

  onChangeTotal = attr => (e, hasDecimal) => {
    const value = e.target.value;
    const fields = {
      ...this.state.fields,
      [attr]: hasDecimal ? Number(value) : parseInt(value)
    };
    setChildStep(fields);
    this.setState({
      ...this.state,
      fields
    });
  };

  cleanState = state => {
    const newChildState = {};
    forEach(state, (value, key) => {
      if (value) {
        newChildState[key] = value;
      }
    });

    return newChildState;
  };

  selectAll = (e, value) => {
    e.preventDefault();
    const selectedPuestos = { ...this.state.selectedPuestos };

    this.props.catalogPostions.forEach(({ positionId }) => {
      selectedPuestos[positionId] = value;
    });

    setChildStep(this.cleanState(selectedPuestos));

    this.setState(state => ({
      ...state,
      selectedPuestos
    }));
  };

  checkPuesto = (e, positionId) => {
    const checked = e.target.checked;
    const selectedPuestos = {
      ...this.state.selectedPuestos,
      [positionId]: checked
    };

    setChildStep(this.cleanState(selectedPuestos));

    this.setState(state => ({
      ...state,
      selectedPuestos
    }));
  };

  checkAplicaPagoParcial = (e, attr) => {
    const checked = e.target.checked;
    const fields = { ...this.state.fields, [attr]: checked };
    setChildStep(fields);
    this.setState(state => ({
      ...state,
      fields
    }));
  };

  save = e => {
    e.preventDefault();
    const puestos = [];
    this.props.catalogPostions.forEach(puesto => {
      const { positionId } = puesto;
      if (this.state.selectedPuestos[positionId]) {
        puestos.push(positionId);
      }
    });
    this.props.saveSportsProductComissions(this.props.match.params.id, {
      ...this.state.fields,
      aplicaPagoParcial: this.state.fields.aplicaPagoParcial,
      tipoComision: "OPERACION",
      puestos
    });

    window.setTimeout(() => window.location.reload(true), 4500);
  };

  getPositionsSubarrays = (itemsPerPage: number) => {
    if (!!this.props.catalogPostions) {
      return divideArrayInSubArrays(this.props.catalogPostions, itemsPerPage);
    } else {
      return [];
    }
  };

  getPositionsRearranged() {
    const columns = 4;
    const itemsPerPage = Math.floor(
      this.props.catalogPostions.length / columns
    );
    const subArrays = this.getPositionsSubarrays(itemsPerPage);
    const result = [];

    for (let i = 0; i < itemsPerPage; i++) {
      for (let c = 0; c < columns; c++) {
        if (!!subArrays[c][i]) {
          result.push(subArrays[c][i]);
        } else {
          result.push(null);
        }
      }
    }

    return result;
  }

  render() {
    let autorizado = false;
    if (this.props.comissionsByIdOperation) {
      if (this.props.comissionsByIdOperation[0]) {
        autorizado = this.props.comissionsByIdOperation[0].autorizado;
      }
    }

    return (
      <Container className="p-0 m-0 ">
        <Row>
          <Col xs={12} md={6} lg={6}>
            <AmountInput
              label="Monto Fijo"
              value={this.state.fields.monto}
              symbol="$"
              onChange={this.onChangeTotal("monto")}
              hasDecimal={false}
            />
          </Col>
          <Col xs={12} md={6} lg={6}>
            <AmountInput
              label="Porcentaje"
              value={this.state.fields.porcentaje}
              symbol="%"
              onChange={this.onChangeTotal("porcentaje")}
              hasDecimal={true}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="text-center">
            <Label check>
              <Input
                type="checkbox"
                checked={this.state.fields.aplicaPagoParcial}
                onChange={e =>
                  this.checkAplicaPagoParcial(e, "aplicaPagoParcial")
                }
              />
              Aplica pago parcial
            </Label>
          </Col>
        </Row>
        <Row>
          <Col>
            <Container className={s.puestos}>
              <Row>
                <Col>
                  <p className={`primaryTitle w-100`}>Puestos</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    id={"selectAll"}
                    onClick={e => this.selectAll(e, true)}
                    color="link"
                    className="text-success"
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </Button>
                  <Button
                    id={"unSelectAll"}
                    onClick={e => this.selectAll(e, false)}
                    color="link"
                    className="text-danger"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                  <UncontrolledTooltip placement="left" target={`selectAll`}>
                    Selecionar todos
                  </UncontrolledTooltip>
                  <UncontrolledTooltip placement="right" target={`unSelectAll`}>
                    Deseleccionar todos
                  </UncontrolledTooltip>
                </Col>
              </Row>
              <Row className={s.checkBoxesContainer}>
                {this.props.catalogPostions &&
                  this.getPositionsRearranged().map(puesto => (
                    <Col
                      className={s.labelContainer}
                      key={puesto.positionId}
                      xs="3"
                    >
                      <FormGroup className={s.label} check>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={
                              !!this.state.selectedPuestos[puesto.positionId]
                            }
                            onChange={e =>
                              this.checkPuesto(e, puesto.positionId)
                            }
                          />
                          {puesto.name}
                        </Label>
                      </FormGroup>
                    </Col>
                  ))}
              </Row>
            </Container>
          </Col>
        </Row>
        <Row>
          <Col>
            <Container className={s.puestos}>
              <Button
                size="sm"
                className={s.customAutirizar}
                onClick={this.save}
              >
                Guardar
              </Button>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(SalesCommisions));
