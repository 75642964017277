import React from "react";
import IInputBuilder from "../iInputBuilder/IInputBuilder";
import ITypeSelect from "../iTypeSelect/ITypeSelect";
import ITypeSelectBuilderProps from "./ITypeSelectBuilder.props";
import ITypeSelectBuilderState from "./ITypeSelectBuilder.state";

export default class ITypeSelectBuilder<
  INP extends string
> extends IInputBuilder<
  INP,
  ITypeSelectBuilderProps<INP>,
  ITypeSelectBuilderState
> {
  render() {
    return <ITypeSelect {...(this.props as any)} />;
  }
}
