import {
  GetLockerProduct,
  GetLockersProducts,
  PutLockerExtension
} from "../../reducers/lockers";

export interface LockerExtensionStore
  extends GetLockersProducts,
    GetLockerProduct,
    PutLockerExtension {
  archivoRefreshLoading: boolean;
  archivoVigenteLoading: boolean;
}

export const lockerExtensionStore: (state) => LockerExtensionStore = state => {
  const { lockers, expedientes } = state;

  return {
    lockersProducts: lockers.lockersProducts,
    gettingLockersProductsError: lockers.gettingLockersProductsError,
    gettingLockersProducts: lockers.gettingLockersProducts,

    lockerProduct: lockers.lockerProduct,
    gettingLockerProductError: lockers.gettingLockerProductError,
    gettingLockerProduct: lockers.gettingLockerProduct,

    puttingLockerExtension: lockers.puttingLockerExtension,
    puttingLockerExtensionError: lockers.puttingLockerExtensionError,

    archivoRefreshLoading: expedientes.archivoRefreshLoading,
    archivoVigenteLoading: expedientes.archivoVigenteLoading
  };
};
