import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import ReactTable from "react-table";
import AddRoles from "../addRoles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import constants from "../../../utils/constants";
import { setChildStep } from "../../helpers/stepper-state-comparator";

interface Props {
  onClickSave: (e: object) => any;
  user: object[];
  saveRolesUser: (user: object, id: string) => any;
  clubs: object[];
  roles: object[];
  match: any;
  getRolesUser: (id: string) => any;
  gettingRolesUser: boolean;
}

interface State {
  showModal: boolean;
  data: object[];
  columns: object[];
}

class RolesUser extends React.Component<Props, State> {
  state = {
    data: null,
    columns: [
      { Header: "Nombre", accessor: "nombre", maxWidth: 200 },
      {
        Header: "Es Global",
        id: "isGlobal",
        accessor: d => (d.isGlobal ? "Si" : "No"),
        maxWidth: 100
      },
      {
        Header: "Clubs",
        id: "clubs",
        accessor: d => {
          return d.isGlobal ? (
            "Todos"
          ) : (
            <div>{d.clubs.map(club => club.nombre).join(", ")}</div>
          );
        }
      },
      {
        Header: "Acciones",
        id: "rolId",
        accessor: rol => <Row> {this.renderActions(rol)} </Row>,
        maxWidth: 70
      }
    ],
    showModal: false
  };

  renderActions = rol => {
    return (
      <div className="w-100 text-center">
        <Button
          color="link"
          onClick={() => this.removeRol(rol)}
          className={`${s.actionHeaderIcon} ${s.deleteAnchor}`}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </div>
    );
  };

  removeRol = rol => {
    const data = this.state.data.filter(r => rol !== r);
    setChildStep(data);
    this.setState(state => {
      return {
        ...state,
        data
      };
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      this.setData(nextProps.user);
    }
  }

  componentDidMount() {
    const { personaId } = this.props.match.params;
    this.props.getRolesUser(personaId);
    this.setState({
      data: this.props.user
    });
    this.props.onClickSave(e => {
      e.preventDefault();
      this.props.saveRolesUser(
        this.state.data,
        this.props.match.params.personaId
      );
    });
  }

  setData = user => {
    if (user && !this.state.data) {
      setChildStep(user);
      this.setState(state => {
        return {
          ...state,
          data: user
        };
      });
    } else {
      this.setState({
        data: this.props.user
      });
    }
  };

  toogleModal = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        showModal: !prevState.showModal
      };
    });
  };

  addRol = rol => {
    const { data } = this.state;
    data.push(rol);
    const newData = [];
    for (const record of data) {
      const newRecord = { ...record };
      newData.push(newRecord);
    }
    setChildStep(newData);
    this.setState(state => {
      return { ...state, data: newData };
    });
  };

  render() {
    return (
      <Container className="w-100 p-0 m-0" fluid>
        <AddRoles
          toggle={this.toogleModal}
          isOpen={this.state.showModal}
          clubs={this.props.clubs}
          roles={this.props.roles}
          addRol={this.addRol}
        />
        <Row className="mb-3">
          <Col>
            <p className={`${s.primaryTitle} w-100 m-0`}>
              Roles{" "}
              <p
                className={`float-right ${s.addRol}`}
                onClick={this.toogleModal}
              >
                <FontAwesomeIcon icon={faPlus} />
              </p>
            </p>
          </Col>
        </Row>
        <ReactTable
          manual
          data={this.state.data ? this.state.data : []}
          columns={this.state.columns}
          className="w-100 -striped -highlight"
          pageSize={
            (this.state.data || []).length > 5 ? this.state.data.length : 5
          }
          sortable={false}
          showPagination={false}
          loading={this.props.gettingRolesUser}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(RolesUser));
