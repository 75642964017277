import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, UncontrolledTooltip, Button } from "reactstrap";
import ReactTable from "react-table";
import ClubSelectList from "../../containers/clubSelectList";
import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "../common/DatePicker";
import moment from "moment";
import {
  getExtranjerosLicenciaAplicable,
  getExtranjerosLicencias,
  postExtranjerosLicenciaAplicable
} from "../../actions/partners";
import ConfirmModal from "../common/confirmModal/confirmModal";

interface Props {
  idMembresiaSocio: any;
  activeTab: any;
}

interface State {
  fechaInicio: any;
  fechaFin: any;
  extension: boolean;
  licenciaAplicable: any;
  licenciaVerificada: boolean;
  errorTipoIntegrante: boolean;
  errorAdeudoPendiente: boolean;
  licencias: any[];
}

export class TabLicenciaExtranjeros extends React.Component<Props, State> {
  state = {
    fechaInicio: null,
    fechaFin: null,
    extension: false,
    licenciaAplicable: null,
    licenciaVerificada: false,
    errorTipoIntegrante: false,
    errorAdeudoPendiente: false,
    licencias: []
  };

  componentDidUpdate(prevProps) {
    if (this.props.activeTab == "23" && prevProps.activeTab != "23") {
      this.getLicenciaAplicable(this.props.idMembresiaSocio, {});

      infoNotification("Consultando licencias anteriores");
      this.getLicencias();
    }
  }

  generateFilters = () => {
    if (!this.state.fechaInicio) {
      errorNotification(
        "Por favor, seleccione la fecha de inicio de la licencia"
      );
      return null;
    } else if (!this.state.fechaFin) {
      errorNotification("Por favor, seleccione la fecha final de la licencia");
      return null;
    }

    return {
      fechaInicio: this.state.fechaInicio,
      fechaFin: this.state.fechaFin,
      extension: this.state.extension
    };
  };

  verify = evt => {
    evt.preventDefault();
    const filters = this.generateFilters();
    filters && infoNotification("Verificando información");
    filters && this.getLicenciaAplicable(this.props.idMembresiaSocio, filters);
  };

  getLicenciaAplicable = (idMembresiaSocio, filters) => {
    getExtranjerosLicenciaAplicable(idMembresiaSocio, filters)
      .then(response => {
        const licenciaAplicable = response.data;

        if (!licenciaAplicable.hasTipoIntegrante) {
          this.setState({
            errorTipoIntegrante: true
          });
        }
        if (licenciaAplicable.hasAdeudoPendiente) {
          this.setState({
            errorAdeudoPendiente: true
          });
        }

        if (filters.fechaInicio) {
          this.setState({
            licenciaVerificada:
              licenciaAplicable.hasTipoIntegrante &&
              !licenciaAplicable.hasAdeudoPendiente
          });
        }

        this.setState({ licenciaAplicable });
      })
      .catch(error =>
        errorNotification(
          error &&
            error.response &&
            error.response.data &&
            error.response.data.message
            ? error.response.data.message
            : "No se puede consultar la información de extranjeros"
        )
      );
  };

  private getLicencias() {
    getExtranjerosLicencias(this.props.idMembresiaSocio)
      .then(response => {
        successNotification("Licencias consultadas correctamente");
        this.setState({
          licencias: response.data
        });
      })
      .catch(err =>
        errorNotification("No se pudieron consultar las licencias anteriores")
      );
  }

  postLicenciaAplicable = (idMembresiaSocio, filters) => {
    postExtranjerosLicenciaAplicable(idMembresiaSocio, filters)
      .then(response => {
        const licenciaAplicable = response.data;
        this.setState({ licenciaAplicable });
      })
      .catch(error =>
        errorNotification(
          error &&
            error.response &&
            error.response.data &&
            error.response.data.message
            ? error.response.data.message
            : "No se puede consultar la información de extranjeros"
        )
      );
  };

  render() {
    if (this.props.activeTab != "23") {
      return <></>;
    }

    return (
      <Container className="mt-3 p-0">
        {this.state.errorTipoIntegrante && (
          <div className="alert alert-danger">
            Aunque la membresía tiene socios extranjeros, ninguno es titular o
            cotitular
          </div>
        )}
        {this.state.errorAdeudoPendiente && (
          <div className="alert alert-danger">
            No es posible configurar nuevas licencias en membresías con adeudos
          </div>
        )}
        {/* Filtros */}
        <form
          hidden={
            this.state.errorAdeudoPendiente || this.state.errorTipoIntegrante
          }
          onSubmit={this.submit}
          autoComplete="off"
        >
          <Row className="mt-4">
            {/* Fecha de inicio */}
            <div className="col-3 mb-3">
              <div
                style={{
                  display: "inline-block",
                  width: "calc(100% - 24px)"
                }}
              >
                <DatePicker
                  id="fechaInicio"
                  name="fechaInicio"
                  label="*Fecha inicial de licencia"
                  selected={this.state.fechaInicio}
                  onChange={e =>
                    this.setState({
                      fechaInicio: moment(e).format("YYYY-MM-DD")
                    })
                  }
                />
              </div>
              <UncontrolledTooltip placement="top" target="fechaInicioBorrar">
                Borrar fecha
              </UncontrolledTooltip>
              <div
                id="fechaInicioBorrar"
                onClick={e => {
                  e.preventDefault();
                  this.setState({ fechaInicio: null });
                }}
                style={{
                  cursor: "pointer",
                  textAlign: "right",
                  display: "inline-block",
                  width: "24px"
                }}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </div>
            </div>

            {/* Fecha de fin */}
            <div className="col-3 mb-3">
              <div
                style={{
                  display: "inline-block",
                  width: "calc(100% - 24px)"
                }}
              >
                <DatePicker
                  id="fechaFin"
                  name="fechaFin"
                  label="*Fecha final de licencia"
                  selected={this.state.fechaFin}
                  onChange={e =>
                    this.setState({ fechaFin: moment(e).format("YYYY-MM-DD") })
                  }
                />
              </div>
              <UncontrolledTooltip placement="top" target="fechaFinBorrar">
                Borrar fecha
              </UncontrolledTooltip>
              <div
                id="fechaFinBorrar"
                onClick={e => {
                  e.preventDefault();
                  this.setState({ fechaFin: null });
                }}
                style={{
                  cursor: "pointer",
                  textAlign: "right",
                  display: "inline-block",
                  width: "24px"
                }}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </div>
            </div>

            {/* Botón de verificación */}
            <Col md={2} className="p-0">
              <Button
                className={`mt-4`}
                type="button"
                size="sm"
                block
                onClick={this.verify}
              >
                Aplicar
              </Button>
            </Col>
          </Row>
        </form>

        {/* Muestra las membresías anteriores */}
        <ReactTable
          className="mt-3 -striped -highlight"
          data={this.state.licencias}
          columns={findColumns}
          sortable={false}
          resizable={false}
          pageSize={
            this.state.licencias && this.state.licencias.length > 5
              ? this.state.licencias.length
              : 5
          }
          showPagination={false}
        />

        {/* Confirmación de aplicación de licencia */}
        <ConfirmModal
          title="Creación de licencia nueva"
          size="lg"
          message={
            !!this.state.licenciaAplicable && (
              <>
                <div>Se creará una licencia con los siguientes datos:</div>
                <div
                  style={{
                    fontSize: "0.9em",
                    fontWeight: "bold",
                    paddingLeft: "12px"
                  }}
                >
                  <div>
                    Fecha inicial de licencia:&nbsp;
                    {moment(this.state.fechaInicio).format("DD/MM/YYYY")}
                  </div>
                  <div>
                    Fecha final de licencia:&nbsp;
                    {moment(this.state.fechaFin).format("DD/MM/YYYY")}
                  </div>
                  <div>
                    Mantenimiento o anualidad vigente en la fechas:&nbsp;
                    {this.state.licenciaAplicable.idCotizacionItem
                      ? "Sí"
                      : "No"}
                  </div>
                  <div hidden={!this.state.licenciaAplicable.idCargoCliente}>
                    Descripción de cargo:&nbsp;
                    {this.state.licenciaAplicable.descripcionCargo}
                  </div>
                </div>
              </>
            )
          }
          isOpen={this.state.licenciaVerificada}
          ok={async () => {
            try {
              const filters = this.generateFilters();
              infoNotification("Creando la licencia");

              await postExtranjerosLicenciaAplicable(
                this.props.idMembresiaSocio,
                filters
              );
              this.getLicencias();

              this.setState({
                licenciaVerificada: false
              });
            } catch (error) {
              errorNotification(
                error &&
                  error.response &&
                  error.response.data &&
                  error.response.data.message
                  ? error.response.data.message
                  : "No fue posible crear la licencia"
              );
            }
          }}
          cancel={() => {
            this.setState({
              licenciaVerificada: false
            });
          }}
        />
      </Container>
    );
  }
}

const findColumns = [
  {
    Header: "Fecha inicial",
    id: "fechaInicio",
    accessor: "fechaInicio",
    width: "120"
  },
  {
    Header: "Fecha final",
    id: "fechaFin",
    accessor: "fechaFin",
    width: "120"
  },
  {
    Header: "Extensión",
    id: "extension",
    accessor: "extension",
    width: "96"
  },
  {
    Header: "Cargo de extensión",
    id: "descripcionCargo",
    accessor: "descripcionCargo",
    width: "400"
  },
  {
    Header: "Estatus",
    id: "estatus",
    accessor: "estatus",
    width: "120"
  }
];
