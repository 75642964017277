import React from "react";
import ReactTable from "react-table";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Container, Row, Col, UncontrolledTooltip } from "reactstrap";
import {
  faEnvelope,
  faClone,
  faFilePdf,
  faFileCsv,
  faUserFriends,
  faClock,
  faCheck,
  faMinusSquare,
  faCopy,
  faRecycle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import EmailBill from "../EmailBill";
import ModalFrame from "../../../../../toolkit/modalFrame";
import HistoryCreditNotes from "../historyCreditNotes";

import s from "../styles.scss";
import IAction from "../../../../../types/IAction";
import MexicanCurrency from "../../../common/currency";
import ModalAddFreeGuestAccess from "../../modalAddFreeGuestAccess";
import { userHasPermission } from "../../../../../utils/accessTree";
import permissionCodes from "../../../../../utils/permissionCodes";
import ModalAddAnnuityZumaTiempoBenefit from "../../modalAddAnnuityZumaTiempoBenefit";
import BaseButtonWithIconAndTooltip from "../../../../../toolkit/baseButtonWithIconAndTooltip";
import {
  enableMaintenenceChargeForCatProcess,
  excludeMaintenenceChargeFromCatProcess,
  duplicateDeletedProduct,
  revertPaymentException
} from "../../../../actions/sportsProducts";
import { handleRequestError } from "../../../../../utils/helpers";
import { successNotification } from "../../../../../utils/notifications";

export interface PaymentMovement {
  id: number;
  uuidFactura: string;
  storageIdFactura: number;
  notasCredito: string;
  ventaId: string;
  membresiaSocioId: number;
  tipoCargoDisplay: String;
  cargoEntidadReferenciadaId: number;
  estatus: string;
  benefZumaTiempoAplicado: boolean;
}

interface Props {
  movementsList: any[];
  tablePageSize: number;
  listHistoricalRebilling: any;
  sendMovementEmail: (id: number, email: string) => any;
  toggleBill: (storageId: number) => any;
  toggleBillXml: (uuid: string) => IAction;
  historicalRebilling: (ventaId: string) => IAction;
  refreshPartner: () => void;
  getMovements: () => void;
  invalidateMovementsData: () => void;
  descripcionTraspaso: any;
  membershipBlocked: boolean;
}

interface State {
  modalEmail: boolean;
  idModalEmail: number;
  toOpenHCN: boolean;
  ventaId: string;
  modalFreeGuestsIsOpen: boolean;
  modalFreeGuestsPartnerMembershipId: number;
  modalFreeGuestsMovementId: number;
  modalAnnuityZumaTiempoIsOpen: boolean;
  modalAnnuityZumaTiempoPartnerMembershipId: number;
  modalAnnuityZumaTiempoMovementId: number;
}

const CHARGE_TYPE_MAINTENANCE = "MANTENIMIENTO";
const CHARGE_TYPE_ANNUITY = "ANUALIDAD";
const CHARGE_TYPE_MEMBERSHIP = "MEMBRESIA";
const CHARGE_STATUS_PAID = "PAGADO";
const CHARGE_STATUS_PENDING = "PENDIENTE";
const CHARGE_STATUS_DELETED = "ELIMINADO";
const CHARGE_STATUS_EXCEPTION = "EXCEPCION_PAGO";
class MovementsTable extends React.Component<Props, State> {
  state = {
    modalEmail: false,
    idModalEmail: null,
    toOpenHCN: false,
    ventaId: "",

    modalFreeGuestsIsOpen: false,
    modalFreeGuestsPartnerMembershipId: null,
    modalFreeGuestsMovementId: null,

    modalAnnuityZumaTiempoIsOpen: false,
    modalAnnuityZumaTiempoPartnerMembershipId: null,
    modalAnnuityZumaTiempoMovementId: null
  };

  toggleModalEmail = (idModalEmail: number) => {
    this.setState({ idModalEmail, modalEmail: !this.state.modalEmail });
  };

  toggleModalFreeGuests = (partnerMembershipId: number, movementId: number) => {
    this.setState({
      modalFreeGuestsPartnerMembershipId: partnerMembershipId,
      modalFreeGuestsMovementId: movementId,
      modalFreeGuestsIsOpen: true
    });
  };

  clearModalFreeGuests = () => {
    this.setState({
      modalFreeGuestsPartnerMembershipId: null,
      modalFreeGuestsMovementId: null,
      modalFreeGuestsIsOpen: false
    });
  };

  toggleModalAnnuityZumaTiempo = (
    partnerMembershipId: number,
    movementId: number
  ) => {
    this.setState({
      modalAnnuityZumaTiempoPartnerMembershipId: partnerMembershipId,
      modalAnnuityZumaTiempoMovementId: movementId,
      modalAnnuityZumaTiempoIsOpen: true
    });
  };

  clearModalAnnuityZumaTiempo = () => {
    this.setState({
      modalAnnuityZumaTiempoPartnerMembershipId: null,
      modalAnnuityZumaTiempoMovementId: null,
      modalAnnuityZumaTiempoIsOpen: false
    });
  };

  handleMaintenanceChargeEnableFotCat = cargoEntidadReferenciadaId => {
    enableMaintenenceChargeForCatProcess(cargoEntidadReferenciadaId)
      .then(() => {
        successNotification("Cargo habilitado para proceso CAT");

        if (!!this.props.invalidateMovementsData) {
          this.props.invalidateMovementsData();
        }
        if (!!this.props.getMovements) {
          this.props.getMovements();
        }
      })
      .catch(handleRequestError);
  };

  handleMaintenanceChargeExcludeFromCatProcess = cargoEntidadReferenciadaId => {
    excludeMaintenenceChargeFromCatProcess(cargoEntidadReferenciadaId)
      .then(() => {
        successNotification("Cargo excluido de proceso CAT activo");

        if (!!this.props.invalidateMovementsData) {
          this.props.invalidateMovementsData();
        }
        if (!!this.props.getMovements) {
          this.props.getMovements();
        }
      })
      .catch(handleRequestError);
  };

  handleDuplicateDeletedMovement = cargoEntidadReferenciadaId => {
    duplicateDeletedProduct(cargoEntidadReferenciadaId)
      .then(() => {
        successNotification("Cargo duplicado correctamente");

        if (!!this.props.invalidateMovementsData) {
          this.props.invalidateMovementsData();
        }
        if (!!this.props.getMovements) {
          this.props.getMovements();
        }
      })
      .catch(handleRequestError);
  };

  handleRevertPaymentException = cargoEntidadReferenciadaId => {
    revertPaymentException(cargoEntidadReferenciadaId)
      .then(() => {
        successNotification("Se revirtió la excepción de pago");

        if (!!this.props.invalidateMovementsData) {
          this.props.invalidateMovementsData();
        }
        if (!!this.props.getMovements) {
          this.props.getMovements();
        }
      })
      .catch(handleRequestError);
  };

  columns = [
    {
      Header: "Tipo Cargo",
      width: "120",
      accessor: "tipoCargoDisplay"
    },
    {
      Header: "F. Creación",
      width: "80",
      accessor: "fechaCargo",
      className: "text-center"
    },
    {
      // NOTE: El ancho de esta columna se sobreescribe en el método calcDescriptionWidth
      Header: "Descripcion",
      accessor: "descripcion",
      width: "150",
      Cell: ({ value, column: { id }, index }) => {
        return <div className="text-nowrap">{value}</div>;
      }
    },
    {
      Header: "Estatus",
      width: "160",
      className: "text-center",
      accessor: "estatus"
    },
    {
      Header: "Importe",
      className: "text-center",
      width: "90",
      accessor: "importe",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.importe} />;
      }
    },
    {
      Header: "Descuento",
      className: "text-center",
      width: "90",
      accessor: "descuento",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.descuento} />;
      }
    },
    {
      Header: "Total",
      className: "text-center",
      width: "90",
      accessor: "total",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.total} />;
      }
    },
    {
      Header: "Folio factura",
      className: "text-center",
      width: "80",
      accessor: "folioFactura"
    },
    {
      Header: "Fecha factura",
      className: "text-center",
      width: "80",
      accessor: "fechaFactura"
    },
    {
      Header: "Hist. notas crédito",
      id: "historial",
      width: "80",
      accessor: ({ notasCredito, ventaId }: PaymentMovement) => {
        return notasCredito ? (
          <Container className="p-0">
            <Row className="m-0">
              <Col className="px-1">
                <a
                  id={`notas${ventaId}`}
                  onClick={async () => {
                    await this.setState({
                      toOpenHCN: true,
                      ventaId
                    });
                  }}
                >
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faClone}
                  />
                </a>
              </Col>
            </Row>
          </Container>
        ) : null;
      }
    },
    {
      Header: "Opciones",
      id: "acciones",
      width: "150",
      accessor: ({
        id,
        storageIdFactura,
        uuidFactura,
        membresiaSocioId,
        tipoCargoDisplay,
        cargoEntidadReferenciadaId,
        estatus,
        benefZumaTiempoAplicado,
        habilitadoCat,
        enProcesoCat
      }: PaymentMovement) => {
        const isValidChargeTypeForFreeGuests =
          ((tipoCargoDisplay || "").toUpperCase() == CHARGE_TYPE_MAINTENANCE ||
            (tipoCargoDisplay || "").toUpperCase() == CHARGE_TYPE_ANNUITY) &&
          estatus == CHARGE_STATUS_PAID;

        const isValidChargeTypeForZumaTiempo =
          (tipoCargoDisplay || "").toUpperCase() == CHARGE_TYPE_ANNUITY &&
          estatus == CHARGE_STATUS_PAID &&
          !benefZumaTiempoAplicado;

        const isValidChargeTypeForEnableCat =
          (tipoCargoDisplay || "").toUpperCase() == CHARGE_TYPE_MAINTENANCE &&
          estatus == CHARGE_STATUS_PENDING &&
          !habilitadoCat;

        const isValidChargeTypeForExcludeCat =
          (tipoCargoDisplay || "").toUpperCase() == CHARGE_TYPE_MAINTENANCE &&
          estatus == CHARGE_STATUS_PENDING &&
          !!enProcesoCat;

        const isValidChargeTypeForDuplicate = estatus == CHARGE_STATUS_DELETED;

        const isValidChargeTypeForRevertPaymentException =
          ((tipoCargoDisplay || "").toUpperCase() == CHARGE_TYPE_MAINTENANCE ||
            (tipoCargoDisplay || "").toUpperCase() == CHARGE_TYPE_MEMBERSHIP) &&
          estatus == CHARGE_STATUS_EXCEPTION;

        return (
          <Container className="p-0">
            <Row className="m-0">
              {!!storageIdFactura && (
                <Col xs="2" className="px-1">
                  <BaseButtonWithIconAndTooltip
                    size="sm"
                    id={`email${id}`}
                    icon={faEnvelope}
                    tooltipTitle=" Enviar factura por correo"
                    onClick={() => this.toggleModalEmail(id)}
                  />
                </Col>
              )}
              {!!storageIdFactura && (
                <Col xs="2" className="px-1">
                  <BaseButtonWithIconAndTooltip
                    size="sm"
                    id={`factura${id}`}
                    icon={faFilePdf}
                    tooltipTitle="Ver pdf factura"
                    onClick={() => this.props.toggleBill(storageIdFactura)}
                  />
                </Col>
              )}
              {!!uuidFactura && (
                <Col xs="2" className="px-1">
                  <BaseButtonWithIconAndTooltip
                    size="sm"
                    id={`factura-xml${id}`}
                    icon={faFileCsv}
                    tooltipTitle=" Ver xml factura"
                    onClick={() => this.props.toggleBillXml(uuidFactura)}
                  />
                </Col>
              )}
              {userHasPermission(
                permissionCodes.PARTNER_MEMBERSHIP_TAB_MOVEMENTS_GENERATE_ACCESS_FREEGUESTS
              ) &&
              !!cargoEntidadReferenciadaId &&
              !!membresiaSocioId &&
              isValidChargeTypeForFreeGuests ? (
                <Col xs="2" className="px-1">
                  <BaseButtonWithIconAndTooltip
                    size="sm"
                    id={`movement-add-free-guests${id}`}
                    icon={faUserFriends}
                    tooltipTitle="Agregar acceso para invitado gratis"
                    onClick={() =>
                      this.toggleModalFreeGuests(
                        membresiaSocioId,
                        cargoEntidadReferenciadaId
                      )
                    }
                  />
                </Col>
              ) : null}

              {userHasPermission(
                permissionCodes.PARTNER_MEMBERSHIP_TAB_MOVEMENTS_ANNUITY_ZUMA_TIEMPO
              ) &&
              !!cargoEntidadReferenciadaId &&
              !!membresiaSocioId &&
              isValidChargeTypeForZumaTiempo ? (
                <Col xs="2" className="px-1">
                  <BaseButtonWithIconAndTooltip
                    size="sm"
                    id={`movement-add-zuma_tiempo${id}`}
                    icon={faClock}
                    tooltipTitle="Aplicar beneficio ZUMA TIEMPO"
                    onClick={() =>
                      this.toggleModalAnnuityZumaTiempo(
                        membresiaSocioId,
                        cargoEntidadReferenciadaId
                      )
                    }
                  />
                </Col>
              ) : null}

              {userHasPermission(
                permissionCodes.PARTNER_MEMBERSHIP_TAB_MOVEMENTS_MAINTENACE_ENABLE_CAT
              ) &&
              !!cargoEntidadReferenciadaId &&
              !!membresiaSocioId &&
              isValidChargeTypeForEnableCat ? (
                <Col xs="2" className="px-1">
                  <BaseButtonWithIconAndTooltip
                    size="sm"
                    id={`movement-enabled-for-cat${id}`}
                    icon={faCheck}
                    tooltipTitle="Habilitar para proceso CAT"
                    onClick={() => {
                      this.handleMaintenanceChargeEnableFotCat(
                        cargoEntidadReferenciadaId
                      );
                    }}
                  />
                </Col>
              ) : null}

              {userHasPermission(
                permissionCodes.PARTNER_MEMBERSHIP_TAB_MOVEMENTS_MAINTENACE_EXCLUDE_IN_CAT
              ) &&
              !!cargoEntidadReferenciadaId &&
              !!membresiaSocioId &&
              isValidChargeTypeForExcludeCat ? (
                <Col xs="2" className="px-1">
                  <BaseButtonWithIconAndTooltip
                    size="sm"
                    id={`movement-exclude-from-cat${id}`}
                    icon={faMinusSquare}
                    tooltipTitle="Excluir de proceso CAT activo"
                    onClick={() => {
                      this.handleMaintenanceChargeExcludeFromCatProcess(
                        cargoEntidadReferenciadaId
                      );
                    }}
                    confirmModal={{
                      title: "Excluir cargo de proceso de envío a CAT en curso",
                      message: `Se liberará el cargo del proceso de envío a CAT en curso de CRM2, pero esto podría generar un cargo doble. ¿Desea continuar?`
                    }}
                  />
                </Col>
              ) : null}

              {userHasPermission(
                permissionCodes.PARTNER_MEMBERSHIP_TAB_MOVEMENTS_DUPLICATE_DELETED_MOVEMENT
              ) &&
              !!cargoEntidadReferenciadaId &&
              !!membresiaSocioId &&
              isValidChargeTypeForDuplicate ? (
                <Col xs="2" className="px-1">
                  <BaseButtonWithIconAndTooltip
                    size="sm"
                    id={`movement-duplicate-deleted${id}`}
                    icon={faCopy}
                    tooltipTitle="Duplicar movimiento"
                    onClick={() => {
                      this.handleDuplicateDeletedMovement(
                        cargoEntidadReferenciadaId
                      );
                    }}
                    confirmModal={{
                      title: "Duplicar cargo eliminado",
                      message: `Se creará una copia del movimiento con estatus PENDIENTE para su cobro en carrito. ¿Desea continuar?`
                    }}
                  />
                </Col>
              ) : null}

              {userHasPermission(
                permissionCodes.PARTNER_MEMBERSHIP_TAB_MOVEMENTS_REVERT_PAYMENT_EXCEPTION
              ) &&
              !!cargoEntidadReferenciadaId &&
              !!membresiaSocioId &&
              isValidChargeTypeForRevertPaymentException ? (
                <Col xs="2" className="px-1">
                  <BaseButtonWithIconAndTooltip
                    size="sm"
                    id={`movement-revert-payment-exception${id}`}
                    icon={faRecycle}
                    tooltipTitle="Revertir excepción de pago"
                    onClick={() => {
                      this.handleRevertPaymentException(
                        cargoEntidadReferenciadaId
                      );
                    }}
                    confirmModal={{
                      title: "Revertir excepción de pago",
                      message: `Se revertirá la excepción de pago del cargo. ¿Desea continuar?`
                    }}
                  />
                </Col>
              ) : null}
            </Row>
          </Container>
        );
      }
    }
  ];

  columnsTraspaso = [
    {
      Header: "Tipo Cargo",
      width: "120",
      accessor: "tipoCargoDisplay"
    },
    {
      Header: "Fecha",
      width: "80",
      accessor: "fechaCargo",
      className: "text-center"
    },
    {
      // NOTE: El ancho de esta columna se sobreescribe en el método calcDescriptionWidth
      Header: "Descripcion",
      accessor: "descripcion",
      width: "150",
      Cell: ({ value, column: { id }, index }) => {
        return <div className="text-nowrap">{value}</div>;
      }
    },
    {
      Header: "Estatus",
      width: "160",
      className: "text-center",
      accessor: "estatus"
    },
    {
      Header: "Importe",
      className: "text-center",
      width: "90",
      accessor: "importe",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.importe} />;
      }
    },
    {
      Header: "Descuento",
      className: "text-center",
      width: "90",
      accessor: "descuento",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.descuento} />;
      }
    },
    {
      Header: "Total",
      className: "text-center",
      width: "90",
      accessor: "total",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.total} />;
      }
    },
    {
      Header: "Folio factura",
      className: "text-center",
      width: "80",
      accessor: "folioFactura"
    },
    {
      Header: "Fecha factura",
      className: "text-center",
      width: "80",
      accessor: "fechaFactura"
    },
    {
      Header: "Hist. notas crédito",
      id: "historial",
      width: "80",
      accessor: ({ notasCredito, ventaId }: PaymentMovement) => {
        return notasCredito ? (
          <Container className="p-0">
            <Row className="m-0">
              <Col className="px-1">
                <a
                  id={`notas${ventaId}`}
                  onClick={async () => {
                    await this.setState({
                      toOpenHCN: true,
                      ventaId
                    });
                  }}
                >
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faClone}
                  />
                </a>
              </Col>
            </Row>
          </Container>
        ) : null;
      }
    },
    {
      Header: "Opciones",
      id: "acciones",
      width: "150",
      accessor: ({ id, storageIdFactura, uuidFactura }: PaymentMovement) => {
        return (
          <Container className="p-0">
            <Row className="m-0">
              {!!storageIdFactura && (
                <Col className="px-1">
                  <a
                    id={`factura${id}`}
                    onClick={() => this.props.toggleBill(storageIdFactura)}
                  >
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={faFilePdf}
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={`factura${id}`}
                    >
                      Ver pdf factura
                    </UncontrolledTooltip>
                  </a>
                </Col>
              )}
              {!!uuidFactura && (
                <Col className="px-1">
                  <a
                    id={`factura-xml${id}`}
                    onClick={() => this.props.toggleBillXml(uuidFactura)}
                  >
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={faFileCsv}
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={`factura-xml${id}`}
                    >
                      Ver xml factura
                    </UncontrolledTooltip>
                  </a>
                </Col>
              )}
            </Row>
          </Container>
        );
      }
    }
  ];

  /**
   * Cálculo aproximado del ancho de la descripción y el total de la tabla
   */
  calcDescriptionWidth = () => {
    const spacing = 6.4;
    let max = 150;

    if (!this.props.movementsList || this.props.movementsList.length === 0) {
      this.columns[2].width = max + "";
    }

    for (const mov of this.props.movementsList) {
      const size = (mov.descripcion || "").length * spacing;
      max = max >= size ? max : size;
    }

    this.columns[2].width = max + "";
  };

  isMembershipBlocked = () => {
    return this.props.membershipBlocked || false;
  };

  render() {
    const maxWidth = this.calcDescriptionWidth();
    const movListLength = (this.props.movementsList || []).length;
    //const pageSize = movListLength < 20 ? movListLength + 2 : 20;
    //const showPagination = movListLength > 20;

    const pageSize = movListLength + 2;
    const showPagination = false;

    return (
      <Container className="p-0">
        <HistoryCreditNotes
          isOpenHCN={this.state.toOpenHCN}
          toggleHCN={() => {
            const toOpenHCN = !this.state.toOpenHCN;
            if (!toOpenHCN) {
            }
            this.setState({ toOpenHCN });
          }}
          ventaId={this.state.ventaId}
          historicalRebilling={this.props.historicalRebilling}
          listHistoricalRebilling={this.props.listHistoricalRebilling}
          toggleBill={this.props.toggleBill}
        />
        <ModalFrame
          title={"Mandar Factura por Mail"}
          isOpen={this.state.modalEmail}
          toggle={this.toggleModalEmail}
          size="lg"
        >
          <EmailBill
            id={this.state.idModalEmail}
            sendEmail={this.props.sendMovementEmail}
          />
        </ModalFrame>
        <ModalAddFreeGuestAccess
          isOpen={this.state.modalFreeGuestsIsOpen}
          partnerMembershipId={this.state.modalFreeGuestsPartnerMembershipId}
          movementId={this.state.modalFreeGuestsMovementId}
          onOk={() => {
            if (!!this.props.refreshPartner) {
              this.props.refreshPartner();
            }
          }}
          onCancel={() => {
            this.clearModalFreeGuests();
          }}
        />

        <ModalAddAnnuityZumaTiempoBenefit
          isOpen={this.state.modalAnnuityZumaTiempoIsOpen}
          partnerMembershipId={
            this.state.modalAnnuityZumaTiempoPartnerMembershipId
          }
          movementId={this.state.modalAnnuityZumaTiempoMovementId}
          onOk={() => {
            if (!!this.props.invalidateMovementsData) {
              this.props.invalidateMovementsData();
            }
            if (!!this.props.refreshPartner) {
              this.props.refreshPartner();
            }
          }}
          onCancel={() => {
            this.clearModalAnnuityZumaTiempo();
          }}
        />
        <Row>
          <Col>
            <ReactTable
              data={this.props.movementsList || []}
              columns={
                this.isMembershipBlocked() ? this.columnsTraspaso : this.columns
              }
              pageSize={pageSize}
              showPagination={showPagination}
              className="mt-3"
              getTrProps={(_, item) => {
                if (
                  !!item &&
                  !!item.original &&
                  item.original.tipoCargo == "COBRO_MANTENIMIENTO" &&
                  item.original.estatus == "PENDIENTE"
                ) {
                  if (!item.original.habilitadoCat) {
                    return { style: { background: "#ddd" } };
                  } else if (!!item.original.enProcesoCat) {
                    return { style: { background: "#fff3cd" } };
                  }
                }
                return {};
              }}
              style={{ fontSize: "0.75rem", width: "100%", maxWidth: "100%" }}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withStyles(s)(MovementsTable);
