export enum PRICES_STATUS {
  PRICES_ULTIMOS_REGISTRADOS = "6",
  PRICES_AUTORIZADOS = "2",
  PRICES_NO_AUTORIZADOS = "4"
}

export enum MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES {
  PRICE_TYPE,
  MEMBERSHIP,
  DATE,
  ESTATUS
}
