import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import { readMessageBack } from "../../utils/readMessageBack";
interface State {
  quotes: object[];
}
const initialState: State = {
  quotes: []
};
import { errorNotification, ERROR } from "../../utils/notifications";

export default function sellersReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.GET_QUOTES_BY_SELLER}_PENDING`: {
      return {
        ...state
      };
    }
    case `${actionTypes.GET_QUOTES_BY_SELLER}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        quotes: []
      };
    }
    case `${actionTypes.GET_QUOTES_BY_SELLER}_FULFILLED`: {
      return {
        ...state,
        quotes: action.payload.data
      };
    }

    default: {
      return state;
    }
  }
}

export { initialState };
