import React from "react";
import IInputBuilder from "../iInputBuilder/IInputBuilder";
import IMultipleCheck from "../iMultipleCheck/IMultipleCheck";
import IMultipleCheckBuilderProps from "./IMultipleCheckBuilder.props";
import IMultipleCheckBuilderState from "./IMultipleCheckBuilder.state";

export default class IMultipleCheckBuilder<
  INP extends string
> extends IInputBuilder<
  INP,
  IMultipleCheckBuilderProps<INP>,
  IMultipleCheckBuilderState
> {
  render() {
    return <IMultipleCheck {...(this.props as any)} />;
  }
}
