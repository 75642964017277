import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import PricesMaintenancesMassiveLoad from "../../components/pricesMaintenancesMassiveLoad";
import * as productActions from "../../actions/products";

import * as clubActions from "../../actions/clubs";

interface Props {
  match: any;
  history: any;
  getAllClubs: () => any;
  searchMaintenancesByAttributes: () => any;
  maintenances: object[];
  clubs: object[];
  getMassivePricesFile: (object) => any;
  sendPricesFile: (pricesFile: Blob, fileName: string) => any;
  setPricesFile: (pricesFile: Blob) => any;
  pricesFileLoaded: boolean;
  fileErrors: string[];
  pricesFile: Blob;
}

class PricesMaintenancesMassiveLoadPage extends React.Component<Props, void> {
  componentDidMount() {
    this.props.getAllClubs();
    this.props.searchMaintenancesByAttributes();
  }

  render() {
    return (
      <main>
        <Helmet title="Carga Masiva de Precios de Mantenimientos" />
        <Layout>
          <PricesMaintenancesMassiveLoad
            clubs={this.props.clubs}
            maintenances={this.props.maintenances}
            searchMaintenancesByAttributes={
              this.props.searchMaintenancesByAttributes
            }
            getMassivePricesFile={this.props.getMassivePricesFile}
            sendPricesFile={this.props.sendPricesFile}
            setPricesFile={this.props.setPricesFile}
            pricesFileLoaded={this.props.pricesFileLoaded}
            fileErrors={this.props.fileErrors}
            pricesFile={this.props.pricesFile}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getAllClubs: () => {
    dispatch(clubActions.getAllClubs());
  },
  searchMaintenancesByAttributes: (attributes: object) => {
    dispatch(productActions.searchByAttributes(attributes));
  },
  getMassivePricesFile: (attributes: object) => {
    dispatch(productActions.getMassivePricesFile(attributes));
  },
  sendPricesFile: (pricesFile: Blob, fileName: string) => {
    dispatch(productActions.sendPricesFile(pricesFile, fileName));
  },
  setPricesFile: (pricesFile: Blob) => {
    dispatch(productActions.setPricesFile(pricesFile));
  }
});

const mapStateToProps = state => {
  const { clubs, products } = state;
  return {
    clubs: clubs.allClubs,
    maintenances: products.maintenancesByAttributes,
    pricesFileLoaded: products.fileLoaded,
    fileErrors: products.pricesFileErrors,
    pricesFile: products.pricesFile
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PricesMaintenancesMassiveLoadPage);
