import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Container, Col, Button, Row } from "reactstrap";
import DatePicker from "../common/DatePicker";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BaseInput from "../../../toolkit/baseInput";
import s from "./styles.scss";
import {
  getMaintenancesPriceGrid,
  printMaintenancesPricesGrid
} from "../../actions/reports";
import {
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import { handleRequestError } from "../../../utils/helpers";
import IAction from "../../../types/IAction";
import MembershipComponent from "./membership-component";

interface Props {
  downloadMaintenancesPriceGrid: (clubId: number, fecha: number) => IAction;
  clubs: {
    clubId: number;
    nombre: string;
    migradoCrm2: boolean;
  }[];
}

interface State {
  filters: object;
  onSearch: boolean;
  data: any[];
}

class ReportMaintenancesPricesGrid extends React.Component<Props, State> {
  state = {
    filters: {
      clubId: null,
      fecha: moment().format("YYYY-MM-DD")
    },
    onSearch: false,
    data: []
  };

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const filters = {
          ...state.filters,
          [fieldName]: date
        };
        return { ...state, error: {}, filters };
      });
    };
  };

  search = () => {
    this.setState({
      data: [],
      onSearch: true
    });

    const { clubId, fecha } = this.state.filters;

    if (!clubId) {
      return;
    }

    infoNotification("Consultando parrilla de precios...");

    getMaintenancesPriceGrid(clubId, fecha)
      .then(({ data }) => {
        this.setState({ data, onSearch: false });
        successNotification("Datos consultados correctamente...");
      })
      .catch(handleRequestError);
  };

  getClubOptions = () => {
    const { clubs } = this.props;

    return [
      { value: "", label: "Seleccionar club" },
      ...(clubs || [])
        .filter(c => !!c.migradoCrm2)
        .map(option => ({
          value: option.clubId,
          label: option.nombre
        }))
    ];
  };

  render() {
    const { clubId, fecha } = this.state.filters;

    return (
      <Container className="p-0">
        <Row>
          <Col xs={12} md={4} lg={4}>
            <BaseInput
              label="Club"
              name="pricesGridClub"
              id="pricesGridClub"
              type="select"
              placeholder="Seleccionar club"
              value={clubId}
              onChange={this.onChangeFilter("clubId")}
              options={this.getClubOptions()}
            />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <DatePicker
              label="Desde"
              name="fromDate"
              id="fromDate"
              selected={fecha}
              onChange={this.onChangeDate("fecha")}
            />
          </Col>
          <Col xs={12} md={4} lg={4} className="mt-4">
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.search}
              disabled={!this.state.filters.clubId}
            >
              Buscar
            </Button>
          </Col>
        </Row>
        {!!(this.state.data || []).length && (
          <Row className={"mt-5"}>
            <Col sm="6">
              Resultados de la búsqueda:{" "}
              <b
                style={{ fontWeight: "bolder", display: "inline-block" }}
                className="ml-3"
              >
                {(this.state.data || []).length}
              </b>
            </Col>
            <Col sm="6" className="text-right">
              <span>Publicar precios </span>
              <Button
                id="btnReportExportCsv"
                color="link"
                onClick={() => {
                  const { clubId, fecha } = this.state.filters;
                  printMaintenancesPricesGrid(fecha, clubId);
                }}
              >
                <FontAwesomeIcon icon={faFileExport} color="black" />
              </Button>
            </Col>
          </Row>
        )}

        {(this.state.data || []).map(x => {
          return (
            <MembershipComponent
              key={`membership${x.membresiaId}`}
              membershipData={x}
            />
          );
        })}
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ReportMaintenancesPricesGrid));
