import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Container, Row, Col, Input, Button } from "reactstrap";
import MaintenancesSelector from "../maintenancesSelector";
import {
  getMassivePricesDownload,
  getMassivePricesDownloadOnParts
} from "../../actions/products";

interface FILE_DATA {
  maintenancesIds: string;
  clubsIds: string;
}
interface Props {
  history: any;
  clubs: { clubId: string; nombre: string }[];
  maintenances: { datosBasicosMantenimientoId: string; nombre: string }[];
  searchMaintenancesByAttributes: ({ text: string }) => any;
  getMassivePricesFile: (data: FILE_DATA) => any;
  sendPricesFile: (pricesFile: Blob, fileName: string) => any;
  setPricesFile: (pricesFile: Blob) => any;
  pricesFileLoaded: boolean;
  fileErrors: string[];
  pricesFile: Blob;
}

interface State {
  pricesFile: object;
  disabledButton: boolean;
  selectedMaintenances: object[];
  selectedClubs: object[];
  downloading: boolean;
}

class PricesMaintenancesMassiveLoad extends React.Component<Props, State> {
  state = {
    pricesFile: null,
    disabledButton: true,
    selectedMaintenances: [],
    selectedClubs: [],
    downloading: false
  };

  dataClubsLoaded = false;

  updateState = ({
    selectedMaintenances = this.state.selectedMaintenances,
    selectedClubs = this.state.selectedClubs
  }) => {
    this.setState({
      selectedMaintenances,
      selectedClubs,
      disabledButton:
        selectedClubs.length === 0 || selectedMaintenances.length === 0
    });
  };

  updateSelection = (mode: string) => {
    switch (mode) {
      case "maintenances":
        return ({ selected: selectedMaintenances }) =>
          this.updateState({ selectedMaintenances });
      case "clubs":
        return ({ selected: selectedClubs }) =>
          this.updateState({ selectedClubs });
      default:
        return () => {};
    }
  };

  getFile = e => {
    e.preventDefault();

    this.setState({ downloading: true });
    getMassivePricesDownloadOnParts(
      this.state.selectedMaintenances,
      this.state.selectedClubs,
      () => this.setState({ downloading: false })
    );
  };

  handleFileChosen = ev => {
    ev.preventDefault();
    this.props.setPricesFile(ev.target.files[0]);
  };

  sendFile = ev => {
    ev.preventDefault();
    this.props.sendPricesFile(
      this.props.pricesFile,
      "precios-mantenimientos.csv"
    );
  };

  render() {
    return (
      <Container className="p-0">
        <div className="my-1">
          <p className={`${s.primaryTitle} my-2`}>Seleccionar Clubes</p>
          <MaintenancesSelector
            items={this.props.clubs}
            selected={this.state.selectedClubs}
            updateSelection={this.updateSelection("clubs")}
            keyId="clubId"
            keyName="nombre"
          />
        </div>
        <div className="my-1">
          <Row className="p-0 m-0">
            <Col className="p-0 m-0">
              <p className={`${s.primaryTitle} my-2`}>
                Seleccionar Mantenimientos
              </p>
            </Col>
          </Row>
          <MaintenancesSelector
            items={this.props.maintenances}
            updateSelection={this.updateSelection("maintenances")}
            searchByText={this.props.searchMaintenancesByAttributes}
            keyId="datosBasicosMantenimientoId"
            keyName="nombre"
            selected={this.state.selectedMaintenances}
          />
          <Row className="p-0 m-0">
            <Col />
            <Col className="p-0 my-1" xs={2}>
              <Button
                className={`${s.buttonMarginTop} ${s.primaryButton}`}
                onClick={this.getFile}
                size="sm"
                disabled={this.state.disabledButton || this.state.downloading}
                block
              >
                {this.state.downloading ? "Espere..." : "Descargar Archivo"}
              </Button>
            </Col>
          </Row>
        </div>
        <div className="mt-1 mb-3">
          <p className={`${s.primaryTitle} my-2`}>
            Seleccionar Archivo de Precios
          </p>
          <Row className={`p-0 m-0`}>
            <Col xs={4} className="p-0 m-0">
              <Input
                type="file"
                id="pricesFile"
                accept=".csv"
                onChange={e => this.handleFileChosen(e)}
              />
            </Col>
            <Col />
            <Col xs={2} className="p-0 m-0">
              <Button
                className={`${s.buttonMarginTop} ${s.primaryButton}`}
                onClick={this.sendFile}
                size="sm"
                disabled={!this.props.pricesFile || this.state.downloading}
                block
              >
                Cargar Archivo
              </Button>
            </Col>
          </Row>
          {this.props.fileErrors && (
            <div className="alert alert-danger p-2 m-0 mt-3">
              {this.props.fileErrors.map(error => (
                <p className="my-1">{error}</p>
              ))}
            </div>
          )}
        </div>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(PricesMaintenancesMassiveLoad));
