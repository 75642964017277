import React, { useState } from "react";
import ModalWrapper from "../ModalWrapper";

const GenericModal = ({ Button, title, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen(!isOpen);
  return (
    <React.Fragment>
      <Button onClick={toggleModal} />
      <ModalWrapper isOpen={isOpen} toggle={toggleModal} title={title}>
        {children}
      </ModalWrapper>
    </React.Fragment>
  );
};

export default GenericModal;
