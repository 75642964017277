import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import { infoNotification } from "../../utils/notifications";
import BasicSpinner from "../basicSpinner";
import s from "./styles.scss";

interface Props {
  message: string;
  rowCount: number;
  executeReportDownload: (filters: any, f: Function) => any;
  getFilters: () => any;
}

interface State {
  downloading: boolean;
}

class BaseIconCsvDownloader extends React.Component<Props, State> {
  state = {
    downloading: false
  };

  render() {
    return (
      <div className="m-2">
        {!!this.state.downloading && (
          <BasicSpinner
            message={this.props.message || "Descargando reporte"}
            loading={this.state.downloading}
            small={true}
          />
        )}

        {(this.props.rowCount || 0) > 0 && !this.state.downloading && (
          <Row>
            <Col className="text-right">
              <span
                style={{
                  fontWeight: "bold",
                  display: "inline-block",
                  marginRight: "60px"
                }}
              >
                No. de registros: {this.props.rowCount}
              </span>
              <span
                style={{
                  fontWeight: "bold",
                  display: "inline-block",
                  marginRight: "15px"
                }}
              >
                Exportar archivo CSV
              </span>
              <Button
                id="btnProspectsReportExportCsv"
                color="link"
                onClick={() => {
                  const filters = this.props.getFilters();

                  this.setState({ downloading: true });

                  infoNotification("Descargando reporte...");

                  this.props.executeReportDownload(filters, () => {
                    this.setState({ downloading: false });
                  });
                }}
              >
                <FontAwesomeIcon icon={faFileExport} color="black" />
              </Button>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default withStyles(s)(BaseIconCsvDownloader);
