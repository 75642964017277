import ReactTable from "react-table";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import { handleRequestError } from "../../../utils/helpers";
import {
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import { getReportCatProcessesDirectionSummary } from "../../actions/reports";
import { Props, State } from "./interfaces";
import s from "./styles.scss";
import MexicanCurrency from "../common/currency";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";

class ReportCatProcessesDirectionSummary extends React.Component<Props, State> {
  state = {
    onSearch: false,
    data: { facturados: [], rechazados: [] }
  };

  columnsFacturados = [
    {
      Header: () => <p className="font-weight-bold">Club</p>,
      className: "text-center",
      accessor: "clubNombre"
    },
    {
      Header: () => <p className="font-weight-bold">Club Id</p>,
      className: "text-center",
      accessor: "clubId"
    },
    {
      Header: () => <p className="font-weight-bold">Modalidad</p>,
      className: "text-left",
      accessor: "modalidad"
    },
    {
      Header: () => <p className="font-weight-bold">Total</p>,
      accessor: "total",
      className: "text-right",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.total} />;
      }
    },
    {
      Header: () => <p className="font-weight-bold"># CARGOS FACTURADOS</p>,
      className: "text-center",
      accessor: "totalCount"
    }
  ];

  columnsRechazados = [
    {
      Header: () => <p className="font-weight-bold">Club</p>,
      className: "text-center",
      accessor: "clubNombre"
    },
    {
      Header: () => <p className="font-weight-bold">Club Id</p>,
      className: "text-center",
      accessor: "clubId"
    },
    {
      Header: () => <p className="font-weight-bold">Modalidad</p>,
      className: "text-left",
      accessor: "modalidad"
    },
    {
      Header: () => <p className="font-weight-bold">Total</p>,
      accessor: "total",
      className: "text-right",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.total} />;
      }
    },
    {
      Header: () => <p className="font-weight-bold"># CARGOS RECHAZADOS</p>,
      className: "text-center",
      accessor: "totalCount"
    }
  ];

  componentDidMount() {
    this.search();
  }

  //CONTROL BEHAVIOR

  getEmptyData = () => {
    return { facturados: [], rechazados: [] };
  };

  search = () => {
    this.setState({ onSearch: true, data: this.getEmptyData() });

    infoNotification("Consultando datos...");

    getReportCatProcessesDirectionSummary()
      .then(({ data }) => {
        this.setState({ data, onSearch: false });
        successNotification("Datos consultados correctamente...");
      })
      .catch(handleRequestError);
  };

  downloadReportCatProcessesDirectionSummary = () => {
    this.props.downloadReportCatProcessesDirectionSummary();
  };

  //RENDER
  render() {
    const { data } = this.state;

    return (
      <Container className="p-0 mt-1">
        {!!(data.facturados || []).length && (
          <Row>
            <Col sm="12" className="text-right">
              <span>Exportar a CSV: </span>
              <Button
                id="btnReportExportCsv"
                color="link"
                onClick={() => {
                  this.downloadReportCatProcessesDirectionSummary();
                }}
              >
                <FontAwesomeIcon icon={faFileExport} color="black" />
              </Button>
            </Col>
          </Row>
        )}

        <Row>
          <Col xs={12} md={6} lg={6}>
            <p className="primaryTitle w-100">FACTURADOS</p>
            <ReactTable
              data={data.facturados || []}
              columns={this.columnsFacturados}
              className="mt-2"
              sortable={false}
              resizable={false}
              loading={this.state.onSearch}
            />
          </Col>
          <Col xs={12} md={6} lg={6}>
            <p className="primaryTitle w-100">RECHAZADOS</p>
            <ReactTable
              data={data.rechazados || []}
              columns={this.columnsRechazados}
              className="mt-2"
              sortable={false}
              resizable={false}
              loading={this.state.onSearch}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ReportCatProcessesDirectionSummary));
