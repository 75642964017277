import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import Reform from "@franleplant/reform";

import ModalFrame from "../../../toolkit/modalFrame";
import s from "./styles.scss";

import { Button, Container, Alert, Form } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import {
  INTEREST_STATUS,
  DISINTEREST_REASON
} from "../../../types-business/User";

interface Prospect {
  personaId: string;
  status: INTEREST_STATUS;
  razonDesinteres: string;
}

interface Props {
  isOpen: boolean;
  toggle: () => void;
  editStatusProspect: (personaId: String, body: any) => void;
  prospect: Prospect;
  history: any;
  client: any;
  statusProspect: boolean;
  statusProspectError: any;
}

interface State {
  fields: {
    status: INTEREST_STATUS;
    reason: string;
  };
  editing: boolean;
  errors: object;
}

const getStatusLabel = (status: string) => {
  switch (status) {
    case INTEREST_STATUS.STATUS_INTERESADO:
      return "Interesado";
    case INTEREST_STATUS.STATUS_NO_INTERESADO:
      return "No interesado";
    case INTEREST_STATUS.STATUS_POSIBLEMENTE:
      return "Posiblemente interesado";
    default:
      return "";
  }
};

const options = [];
const reasonOpts = DISINTEREST_REASON;

for (const key in INTEREST_STATUS) {
  options.push({
    value: INTEREST_STATUS[key],
    label: getStatusLabel(INTEREST_STATUS[key])
  });
}

class ActionProspectModal extends React.Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);

  state = {
    fields: {
      status: INTEREST_STATUS.STATUS_NO_INTERESADO,
      reason: ""
    },
    editing: false,
    errors: {}
  };

  validationRules = {
    status: { required: true }
  };

  validationMessages = {
    required: _ => `Obligatorio`,
    dateMustBeFuture: _ => "La fecha debe ser un momento en el futuro",
    dateMustBeValid: _ => "La fecha inicial debe ser menor que la final",
    noteMustExistsWhenTypeNote: _ =>
      "No debe estar vacío cuando se crea una nota",
    default: _ => `Invalido`
  };

  onSubmit = e => {
    e.preventDefault();
    if (this.re.validateFormFromState()) {
      this.props.editStatusProspect(this.props.prospect.personaId, {
        personaStatus: this.state.fields.status,
        razonDesinteres: this.state.fields.reason
      });
    }
  };

  componentDidUpdate() {
    if (this.props.isOpen && !this.state.editing) {
      const reason = this.props.prospect
        ? this.props.prospect.razonDesinteres
        : "";

      this.setState({
        editing: true,
        fields: {
          status: this.props.prospect
            ? this.props.prospect.status
            : INTEREST_STATUS.STATUS_NO_INTERESADO,
          reason:
            !reason || reason == "NO_APLICA" || reason == "NO_ESPECIFICADO"
              ? "PRECIO"
              : reason
        }
      });
    }

    if (!this.props.isOpen && this.state.editing) {
      this.setState(prevState => {
        return {
          ...prevState,
          editing: false
        };
      });
    }
  }

  onChangeFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };

        return { ...state, error: {}, fields };
      });
      this.re.validateField(fieldName, value);
    };
  };

  render() {
    return (
      <ModalFrame
        title={"Cambiar estatus de interes"}
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <Container>
          <Form>
            <BaseInput
              label={"Estatus de interes"}
              name={"status"}
              type="select"
              id={"status"}
              placeholder="Estatus de interes"
              value={this.state.fields["status"]}
              // dataQAId={"user_login_password"}
              errors={this.re.mapFieldErrors("status")}
              options={options}
              onChange={this.onChangeFactory("status")}
            />
            <div
              hidden={this.state.fields.status != "NO_INTERESADO"}
              className="mt-3"
            >
              <BaseInput
                label={"Razón de desinterés"}
                name={"reason"}
                type="select"
                id={"reason"}
                placeholder="Razón de desinterés"
                value={this.state.fields["reason"]}
                // dataQAId={"user_login_password"}
                options={reasonOpts}
                onChange={this.onChangeFactory("reason")}
              />
            </div>
            <Button
              className={`${s.buttonMarginTop} ${s.primaryButton}`}
              onClick={e => this.onSubmit(e)}
              size="lg"
              disabled={this.props.statusProspect}
              block
            >
              {this.props.statusProspect ? "Cambiando" : "Cambiar Estatus"}
            </Button>
            {this.props.statusProspectError && (
              <span>
                <hr />
                <Alert color="danger">Ocurrió un error inesperado.</Alert>
              </span>
            )}
          </Form>
        </Container>
      </ModalFrame>
    );
  }
}

export default withRouter(withStyles(s)(ActionProspectModal));
