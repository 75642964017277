import React from "react";
import { connect } from "react-redux";
import CreateRole from "../../components/createRole";
import Layout from "../../components/layout";
import * as sessionActions from "../../actions/session";
import * as catalogActions from "../../actions/catalogs";
import { withRouter } from "react-router-dom";

interface Props {
  getDomains: () => any;
  getPermissionsTree: () => any;
  domains: object[];
  permissionsTree: object;
  saveRole: (role: object, permissions: object[]) => any;
  updateRole: (roleId: string, role: object, permissions: object[]) => any;
  match: any;
  getRoleById: (roleId: string) => any;
  role: { id: string };
  validRoleId: boolean;
  history: any;
}

class Modals extends React.Component<Props, {}> {
  componentDidMount() {
    this.props.getDomains();
    this.props.getPermissionsTree();
    const { roleId } = this.props.match.params;
    if (roleId) this.props.getRoleById(roleId);
  }

  /**
   * BUGFIX: Este método impedía la creación de un nuevo rol
  componentDidUpdate() {
    const { roleId } = this.props.match.params;
    if (!roleId && this.props.role && this.props.role.id)
      this.props.history.push(`/roles/nuevo-rol/${this.props.role.id}`);
  }
  **/

  saveRole = (role: object, permissions: object[]) => {
    const { roleId } = this.props.match.params;
    if (roleId) {
      this.props.updateRole(roleId, role, permissions);
    } else {
      this.props.saveRole(role, permissions);
    }
  };

  render() {
    const { domains, permissionsTree, role } = this.props;
    const { roleId } = this.props.match.params;

    return (
      <main>
        <Layout>
          {domains && permissionsTree && (!roleId || (roleId && role)) ? (
            <CreateRole
              domains={this.props.domains}
              permissionsTree={this.props.permissionsTree}
              saveRole={this.saveRole}
              role={this.props.role}
            />
          ) : null}
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = state => {
  const { user, catalogs, auth } = state;
  return {
    domains: catalogs.domains,
    permissionsTree: user.permissionsTree,
    role: auth.roleById,
    validRoleId: auth.validRoleId
  };
};

const mapDispatchToProps = dispatch => ({
  getDomains: () => {
    dispatch(catalogActions.getDomains());
  },
  getPermissionsTree: () => {
    dispatch(sessionActions.getPermissionsTree());
  },
  saveRole: (role: object, permissions: object[]) => {
    dispatch(sessionActions.saveRole(role, permissions));
  },
  updateRole: (roleId: string, role: object, permissions: object[]) => {
    dispatch(sessionActions.updateRole(roleId, role, permissions));
  },
  getRoleById: (roleId: string) => {
    dispatch(sessionActions.getRoleById(roleId));
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Modals)
);
