import React from "react";
import ReactTable from "react-table";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  Form
} from "reactstrap";
import {
  faEnvelope,
  faMoneyBill,
  faClock
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import EmailBill from "./EmailBill";
import PersonSelector from "../../../People/containers/PersonSelector";
import { Person } from "../oneDayPassActivatorPage/interfaces";
import ClubCatalogSelect from "../sportsProductsNewQuote/ClubCatalogSelect";
import { Club } from "../../../types-business/Clubs";
import BaseInput from "../../../toolkit/baseInput";
import ModalFrame from "../../../toolkit/modalFrame";

import s from "./styles.scss";
import HistoryCreditNotes from "./historyCreditNotes";
import IAction from "../../../types/IAction";

enum TipoCargoCliente {
  VENTA_PRODUCTO = "VENTA_PRODUCTO",
  VENTA_MEMBRESIA = "VENTA_MEMBRESIA",
  VENTA_ANUALIDAD = "VENTA_ANUALIDAD",
  COBRO_MANTENIMIENTO = "COBRO_MANTENIMIENTO"
}

enum TipoBusqueda {
  PERSONA = "PERSONA",
  MEMBRESIA = "MEMBRESIA"
}

enum EstatusCargo {
  ELIMINADO = "ELIMINADO",
  PENDIENTE = "PENDIENTE",
  PAGADO = "PAGADO",
  EXCEPCION_PAGO = "EXCEPCION_PAGO",
  PENDIENTE_LINEA_PAGO = "PENDIENTE_LINEA_PAGO"
}

export interface PaymentMovement {
  id: number;
  membresiaSocioId: number;
  personaId: number;
  tipoCargo: TipoCargoCliente;
  tipoCargoDisplay: string;
  fechaCargo: string;
  descripcion: string;
  estatus: EstatusCargo;
  importe: number;
  descuento: number;
  total: number;
  folioFactura: string;
  uuidFactura: string;
  fechaFactura: string;
  storageIdFactura: number;
  folioNotaCredito: string;
  fechaNotaCredito: string;
  storageIdNotaCredito: number;
  cargoEntidadReferenciadaId: number;
  notasCredito: string;
  ventaId: string;
}

interface Props {
  loadingMovements: boolean;
  paymentMovements: object[];
  paymentMovementsByMembership: object[];
  getPaymentMovements: (personaId: number) => any;
  clubs: Club[];
  searchByClubAndMembership: (params: object) => any;
  sendMovementEmail: (id: number, email: string) => any;
  toggleBill: (storageId: number) => any;
  listHistoricalRebilling: any;
  historicalRebilling: (ventaId: string) => IAction;
}

interface State {
  person: Person;
  clubBase: { value: number };
  membresiaSocioIdPublico: number;
  modalEmail: boolean;
  idModalEmail: number;
  tipoBusqueda: TipoBusqueda;
  toOpenHCN: boolean;
  ventaId: string;
}

class PaymentMovementsList extends React.Component<Props, State> {
  state = {
    person: null,
    clubBase: null,
    membresiaSocioIdPublico: null,
    modalEmail: false,
    idModalEmail: null,
    tipoBusqueda: TipoBusqueda.PERSONA,
    toOpenHCN: false,
    ventaId: ""
  };

  toggleModalEmail = (idModalEmail: number) => {
    this.setState({ idModalEmail, modalEmail: !this.state.modalEmail });
  };

  columns = [
    {
      Header: "Tipo Cargo",
      accessor: "tipoCargo"
    },
    {
      Header: "Fecha cargo",
      accessor: "fechaCargo"
    },
    {
      Header: "Fecha factura vigente",
      accessor: "fechaFactura"
    },
    {
      Header: "Descripcion",
      accessor: "descripcion",
      Cell: ({ value, column: { id }, index }) => {
        const tooltipId = `${id}-${index}`;
        return (
          <span id={tooltipId}>
            {value}
            <UncontrolledTooltip placement="top" target={tooltipId}>
              {value}
            </UncontrolledTooltip>
          </span>
        );
      }
    },
    {
      Header: "Estatus",
      accessor: "estatus"
    },
    {
      Header: "Importe",
      accessor: "importe"
    },
    {
      Header: "Folio factura vigente",
      accessor: "folioFactura"
    },
    {
      Header: "Historial notas de crédito",
      id: "historial",
      width: 80,
      accessor: ({ notasCredito, ventaId }: PaymentMovement) => {
        return notasCredito ? (
          <Container className="p-0">
            <Row className="m-0">
              <Col className="px-1">
                <a
                  id={`notas${notasCredito}`}
                  onClick={() => {
                    this.setState({
                      toOpenHCN: true,
                      ventaId
                    });
                  }}
                >
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faClock}
                  />
                </a>
              </Col>
            </Row>
          </Container>
        ) : null;
      }
    },
    {
      Header: "Opciones",
      id: "acciones",
      width: 80,
      accessor: ({ id, storageIdFactura }: PaymentMovement) => {
        return (
          <Container className="p-0">
            <Row className="m-0">
              <Col className="px-1">
                <a id={`email${id}`} onClick={() => this.toggleModalEmail(id)}>
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faEnvelope}
                  />
                </a>
              </Col>
              <Col className="px-1">
                <a
                  id={`factura${id}`}
                  onClick={() => this.props.toggleBill(storageIdFactura)}
                >
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faMoneyBill}
                  />
                </a>
              </Col>
            </Row>
          </Container>
        );
      }
    }
  ];

  setPerson = person => {
    this.setState({ person });
  };

  searchByClient = () => {
    if (this.state.person) {
      const { personaId } = this.state.person;
      this.props.getPaymentMovements(personaId);
      this.setState({ tipoBusqueda: TipoBusqueda.PERSONA });
    }
  };

  setClubBase = clubBase => {
    this.setState({ clubBase });
  };

  setMembresiaPublicoId = event => {
    const membresiaSocioIdPublico = event.target.value;
    this.setState({ membresiaSocioIdPublico });
  };

  searchByClubAndMembership = () => {
    const {
      clubBase: { value: clubId },
      membresiaSocioIdPublico
    } = this.state;
    this.props.searchByClubAndMembership({
      clubId,
      membresiaSocioIdPublico
    });
    this.setState({ tipoBusqueda: TipoBusqueda.MEMBRESIA });
  };

  getTableSource = () => {
    return this.state.tipoBusqueda === "PERSONA"
      ? this.props.paymentMovements || []
      : this.props.paymentMovementsByMembership || [];
  };

  getTablePageSize = () => {
    const defPageSize: number = 20;
    return this.state.tipoBusqueda === "PERSONA"
      ? (this.props.paymentMovements || []).length
        ? this.props.paymentMovements.length + 1
        : defPageSize
      : (this.props.paymentMovementsByMembership || []).length
      ? this.props.paymentMovementsByMembership.length + 1
      : defPageSize;
  };

  render() {
    return (
      <Container className="p-0">
        <HistoryCreditNotes
          isOpenHCN={this.state.toOpenHCN}
          toggleHCN={() => {
            const toOpenHCN = !this.state.toOpenHCN;
            if (!toOpenHCN) {
            }
            this.setState({ toOpenHCN });
          }}
          ventaId={this.state.ventaId}
          historicalRebilling={this.props.historicalRebilling}
          listHistoricalRebilling={this.props.listHistoricalRebilling}
        />
        <ModalFrame
          title={"Mandar Factura por Mail"}
          isOpen={this.state.modalEmail}
          toggle={this.toggleModalEmail}
          size="lg"
        >
          <EmailBill
            id={this.state.idModalEmail}
            sendEmail={this.props.sendMovementEmail}
          />
        </ModalFrame>
        <Row>
          <Col>
            <PersonSelector setPerson={this.setPerson} cKey={"GZQWUAIRME"} />
          </Col>
          <Col xs={3}>
            <Button
              className={`${s.buttonMarginTop} ${s.primaryButton} mt-4`}
              onClick={this.searchByClient}
              size="sm"
              block
              disabled={!this.state.person}
            >
              Buscar por Persona
            </Button>
          </Col>
        </Row>
        <Form
          onSubmit={e => {
            e.preventDefault();
            this.searchByClubAndMembership();
          }}
        >
          <Row>
            <Col>
              <ClubCatalogSelect
                club={this.state.clubBase}
                label="Club Base"
                clubs={this.props.clubs}
                onSelectClub={this.setClubBase}
              />
            </Col>
            <Col>
              <BaseInput
                label="Id público membresía"
                name="idPublicoMembresia"
                type="text"
                id="idPublicoMembresia"
                placeholder="Id público membresía"
                value={this.state.membresiaSocioIdPublico || ""}
                onChange={this.setMembresiaPublicoId}
                errors={[]}
              />
            </Col>
            <Col xs={3}>
              <Button
                className={`${s.buttonMarginTop} ${s.primaryButton} mt-4`}
                type="submit"
                size="sm"
                block
                disabled={
                  !this.state.clubBase && !this.state.membresiaSocioIdPublico
                }
              >
                Buscar por membresia y club
              </Button>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col>
            <ReactTable
              data={this.getTableSource()}
              columns={this.columns}
              pageSize={this.getTablePageSize()}
              showPagination={false}
              loading={this.props.loadingMovements}
              className="mt-3"
              getTrProps={() => ({
                // style: { height: "60px" },
                className: "mt-1"
              })}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(PaymentMovementsList));
