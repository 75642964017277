import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants, {
  STATUS_ACTIVITY,
  DEFAULT_CATEGORY_ID
} from "../../utils/constants";

const ACTIVITIES_BASE_URL = "/api/activity";

export const getUserActivities = (params: object): IAction => {
  return {
    type: actionTypes.GET_USER_ACTIVITIES,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${ACTIVITIES_BASE_URL}`, {
        withCredentials: true,
        params
      })
    }
  };
};

export const markActivityRead = (activityId: number): IAction => {
  return {
    type: actionTypes.MARK_AS_SEEN,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${ACTIVITIES_BASE_URL}/user/status`,
        {
          actividadUsuarioId: activityId,
          estatus: STATUS_ACTIVITY.LEIDA
        },
        {
          withCredentials: true
        }
      )
    }
  };
};

export const saveEvidence = (activityId: number, evidence: string): IAction => {
  return {
    type: actionTypes.SAVE_EVIDENCE,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${ACTIVITIES_BASE_URL}/user/evidence`,
        {
          actividadUsuarioId: activityId,
          evidencia: evidence
        },
        {
          withCredentials: true
        }
      )
    }
  };
};

export const newUserActivity = (activity: object): IAction => {
  return {
    type: actionTypes.NEW_USER_ACTIVITY,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${ACTIVITIES_BASE_URL}/user`,
        {
          idCategoria: DEFAULT_CATEGORY_ID,
          ...activity
        },
        {
          withCredentials: true
        }
      )
    }
  };
};
