import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Container, ButtonGroup, Button } from "reactstrap";
import MaintenancesSelector from "../maintenancesSelector";
import { TYPE_OPTIONS } from "../../../types-business/Promotions";

interface Props {
  agreements: { convenioId: number; nombre: string }[];
  searchAgreementsByAttributes: () => any;
  successfulSave: boolean;
  onClickSave: (e: object) => any;
  addAgreementsToPromotion: (promotionId: string, agreements: object) => any;
  getAgreementsPromotion: (id: string) => any;
  agreementsPromotion: { convenio: number }[];
  match: any;
  promotion: { tipoAplicabilidad: TYPE_OPTIONS };
  getPromotionById: (promotionId: string) => any;
}

const activeButtonStyle = {
  width: "20rem",
  backgroundColor: "#fc0524",
  borderColor: "#fc0524"
};

const inactiveButtonStyle = { width: "20rem" };

interface State {
  selected: object[];
  optionSelected: TYPE_OPTIONS;
}

class AddAgreementsComponent extends React.Component<Props, State> {
  state = {
    selected: null,
    optionSelected: null
  };

  selectedAgreements = [];

  componentDidMount() {
    const { id } = this.props.match.params;

    this.props.onClickSave(e => {
      e.preventDefault();
      this.props.addAgreementsToPromotion(id, {
        agreements:
          this.state.optionSelected !== TYPE_OPTIONS.NO_AGREEMENTS
            ? this.selectedAgreements.map(({ id: convenio }) => ({ convenio }))
            : [],
        option: this.state.optionSelected
      });
    });

    this.props.getAgreementsPromotion(id);
    this.props.getPromotionById(id);
  }

  selectedLoaded = false;

  componentDidUpdate(prevProps) {
    if (
      !this.selectedLoaded &&
      this.props.agreements &&
      this.props.agreementsPromotion
    ) {
      this.setState({
        selected: this.props.agreementsPromotion.map(
          ({ convenio: convenioId }) => {
            const { nombre } = this.props.agreements.find(
              ({ convenioId: id }) => id === convenioId
            );
            return {
              nombre,
              convenioId
            };
          }
        )
      });
      this.selectedLoaded = true;
    }

    if (!prevProps.promotion && this.props.promotion) {
      this.setState({ optionSelected: this.props.promotion.tipoAplicabilidad });
    }
  }

  updateSelection = ({ selected }) => {
    this.selectedAgreements = selected;
  };
  onOptionSelected = (optionSelected: TYPE_OPTIONS) => {
    this.setState({ ...this.state, optionSelected });
  };

  isOptionActive = option => option === this.state.optionSelected;

  render() {
    return (
      <Container className="p-0 m-0">
        <ButtonGroup>
          <Button
            className={s.optionButton}
            style={
              this.isOptionActive(TYPE_OPTIONS.NO_AGREEMENTS)
                ? activeButtonStyle
                : inactiveButtonStyle
            }
            onClick={() => this.onOptionSelected(TYPE_OPTIONS.NO_AGREEMENTS)}
            active={this.isOptionActive(TYPE_OPTIONS.NO_AGREEMENTS)}
          >
            NO APLICA A CONVENIOS
          </Button>
          <Button
            className={s.optionButton}
            style={
              this.isOptionActive(TYPE_OPTIONS.SOME_AGREEMENTS)
                ? activeButtonStyle
                : inactiveButtonStyle
            }
            onClick={() => this.onOptionSelected(TYPE_OPTIONS.SOME_AGREEMENTS)}
            active={this.isOptionActive(TYPE_OPTIONS.SOME_AGREEMENTS)}
          >
            APLICA A TODOS LOS CONVENIOS
          </Button>
          <Button
            className={s.optionButton}
            style={
              this.isOptionActive(TYPE_OPTIONS.ONLY_AGREEMENTS)
                ? activeButtonStyle
                : inactiveButtonStyle
            }
            onClick={() => this.onOptionSelected(TYPE_OPTIONS.ONLY_AGREEMENTS)}
            active={this.isOptionActive(TYPE_OPTIONS.ONLY_AGREEMENTS)}
          >
            APLICA SOLO A CONVENIOS ASOCIADOS
          </Button>
        </ButtonGroup>
        <hr />

        {this.isOptionActive(TYPE_OPTIONS.NO_AGREEMENTS) && (
          <p>No aplica si hay un convenio seleccionado</p>
        )}
        {this.isOptionActive(TYPE_OPTIONS.SOME_AGREEMENTS) && (
          <p>Se aplica se haya seleccionado o no un convenio</p>
        )}
        {this.isOptionActive(TYPE_OPTIONS.ONLY_AGREEMENTS) && (
          <p>
            Sólo se aplica si se ha seleccionado un convenio asociado a la
            promoción
          </p>
        )}

        {this.state.optionSelected &&
        this.isOptionActive(TYPE_OPTIONS.ONLY_AGREEMENTS) ? (
          <MaintenancesSelector
            items={this.props.agreements}
            selected={this.state.selected}
            updateSelection={this.updateSelection}
            searchByText={this.props.searchAgreementsByAttributes}
            keyId="convenioId"
            keyName="nombre"
          />
        ) : null}
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(AddAgreementsComponent));
