import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Container, Row, Col, Button } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBomb, faFileExport } from "@fortawesome/free-solid-svg-icons";

import { handleRequestError } from "../../../utils/helpers";

import {
  getQuizzAppliedResumeRequest,
  getQuizzAppliedResumeRequestDownload
} from "../../actions/products";
import { getClubsRequest } from "../../actions/catalogs";

import {
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import BaseCsvIconDownloader from "../../../toolkit/baseCsvIconDownloader";

interface Props {}

interface State {
  filters: Object;
  surveysAppliedResume: {
    clubId: string;
    clubNombre: string;
    dispositivoNombre: string;
    evaluacionNombre: string;
    evaluacionesCompletas: number;
    evaluacionesIncompletas: number;
    evaluacionesTotal: number;
  }[];
  clubsCatalog: { cubId: number; nombre: string }[];
}

class QuizzSurveyAppliedResume extends React.Component<Props, State> {
  state = {
    filters: {
      clubId: 60,
      dispositivoId: null,
      encuestaId: null,
      anio: null,
      mes: null
    },
    surveysAppliedResume: [],
    clubsCatalog: []
  };

  getQuizzAppliedResume = () => {
    infoNotification("Consultando datos de encuestas aplicadas...");

    const params = { ...this.state.filters };
    params.clubId = !!params.clubId && params.clubId > 0 ? params.clubId : null;
    params.dispositivoId =
      !!params.dispositivoId && params.dispositivoId > 0
        ? params.dispositivoId
        : null;
    params.encuestaId =
      !!params.encuestaId && params.encuestaId > 0 ? params.encuestaId : null;
    params.anio = !!params.anio && params.anio > 0 ? params.anio : null;
    params.mes = !!params.mes && params.mes > 0 ? params.mes : null;

    getQuizzAppliedResumeRequest(params)
      .then(({ data }) => {
        this.setState({ surveysAppliedResume: data });
        successNotification("Datos consultados correctamente...");
      })
      .catch(handleRequestError);
  };

  getClubsCatalog = () => {
    getClubsRequest()
      .then(({ data }) => {
        this.setState({ clubsCatalog: data });
      })
      .catch(handleRequestError);
  };
  componentDidMount() {
    this.getClubsCatalog();
    this.getQuizzAppliedResume();
  }

  getAnioCatalog = () => {
    const anios = [{ value: null, label: "Seleccione..." }];
    for (let i = 2010; i <= 2100; i++) {
      anios.push({ value: i, label: `${i}` });
    }
    return anios;
  };
  getMesCatalog = () => {
    return [
      { value: 0, label: "Seleccione..." },
      { value: 1, label: "ENE" },
      { value: 2, label: "FEB" },
      { value: 3, label: "MAR" },
      { value: 4, label: "ABR" },
      { value: 5, label: "MAY" },
      { value: 6, label: "JUN" },
      { value: 7, label: "JUL" },
      { value: 8, label: "AGO" },
      { value: 9, label: "SEP" },
      { value: 10, label: "OCT" },
      { value: 11, label: "NOV" },
      { value: 12, label: "DIC" }
    ];
  };

  getDevicesCatalog = () => {
    return [
      { value: 0, label: "Seleccione..." },
      { value: 1, label: "Ipad-Quiosco" },
      { value: 2, label: "Ipad-Baño-Mujeres" },
      { value: 3, label: "Ipad-Baño-Hombres" },
      { value: 4, label: "Ipad-Movil1" },
      { value: 5, label: "Ipad-Movil2" },
      { value: 6, label: "Ipad-Salón 1" },
      { value: 7, label: "Ipad-Salón 2" },
      { value: 8, label: "Ipad-Salón 3" }
    ];
  };

  search = () => {
    this.getQuizzAppliedResume();
  };

  render() {
    let columns = [
      {
        Header: () => <p className="font-weight-bold">Club</p>,
        id: 0,
        accessor: "clubNombre",
        Cell: row => {
          return <div className="pl-2">{row.original.clubNombre}</div>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Año</p>,
        id: 0,
        accessor: "anio",
        Cell: row => {
          return <div className="pl-2">{row.original.anio}</div>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Mes</p>,
        id: 0,
        accessor: "mes",
        Cell: row => {
          return <div className="pl-2">{row.original.mes}</div>;
        }
      },

      {
        Header: () => <p className="font-weight-bold">Dispositivo</p>,
        id: 0,
        accessor: "dispositivoNombre",
        Cell: row => {
          return <div className="pl-2">{row.original.dispositivoNombre}</div>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Evaluación</p>,
        id: 0,
        accessor: "evaluacionNombre",
        Cell: row => {
          return <div className="pl-2">{row.original.evaluacionNombre}</div>;
        }
      },
      {
        Header: () => (
          <p className="font-weight-bold">Evaluaciones completas</p>
        ),
        id: 0,
        accessor: "evaluacionesCompletas",
        Cell: row => {
          return (
            <div className="pl-2">{row.original.evaluacionesCompletas}</div>
          );
        }
      },
      {
        Header: () => (
          <p className="font-weight-bold">Evaluaciones incompletas</p>
        ),
        id: 0,
        accessor: "evaluacionesIncompletas",
        Cell: row => {
          return (
            <div className="pl-2">{row.original.evaluacionesIncompletas}</div>
          );
        }
      },
      {
        Header: () => <p className="font-weight-bold">Evaluaciones total</p>,
        id: 0,
        accessor: "evaluacionesTotal",
        Cell: row => {
          return <div className="pl-2">{row.original.evaluacionesTotal}</div>;
        }
      }
    ];

    return (
      <Container className="p-0 mt-3">
        <Row>
          <Col xs={3}>
            <BaseInput
              type="select"
              label="Club"
              name={"clubFilter"}
              id={"clubFilter"}
              value={this.state.filters.clubId}
              options={(this.state.clubsCatalog || []).map(
                ({ clubId, nombre }) => {
                  return {
                    value: clubId,
                    label: nombre
                  };
                }
              )}
              onChange={e => {
                const value = e.target.value;
                const { filters } = this.state;
                filters.clubId = value;
                this.setState({ filters });
              }}
            />
          </Col>

          <Col xs={3}>
            <BaseInput
              type="select"
              label="Evaluación"
              name={"surveyFilter"}
              id={"surveyFilter"}
              options={[{ value: null, label: "TODOS" }]}
              value={this.state.filters.encuestaId}
              onChange={e => {
                const value = e.target.value;
                const { filters } = this.state;
                filters.encuestaId = value;
                this.setState({ filters });
              }}
            />
          </Col>
          <Col xs={3}>
            <BaseInput
              type="select"
              label="Dispositivos"
              name={"deviceFilter"}
              id={"deviceFilter"}
              options={this.getDevicesCatalog()}
              value={this.state.filters.dispositivoId}
              onChange={e => {
                const value = e.target.value;
                const { filters } = this.state;
                filters.dispositivoId = value;
                this.setState({ filters });
              }}
            />
          </Col>
          <Col xs={3}>
            <BaseInput
              type="select"
              label="Año"
              name={"annioFilter"}
              id={"annioFilter"}
              value={this.state.filters.anio}
              options={this.getAnioCatalog()}
              onChange={e => {
                const value = e.target.value;
                const { filters } = this.state;
                filters.anio = value;
                this.setState({ filters });
              }}
            />
          </Col>
          <Col xs={3}>
            <BaseInput
              type="select"
              label="Mes"
              name={"monthFilter"}
              id={"monthFilter"}
              value={this.state.filters.mes}
              options={this.getMesCatalog()}
              onChange={e => {
                const value = e.target.value;
                const { filters } = this.state;
                filters.mes = value;
                this.setState({ filters });
              }}
            />
          </Col>
          <Col xs={2}>
            <Button
              className={`${s.buttonMarginTop} ${s.primaryButton} mt-4`}
              onClick={e => this.search()}
              size="sm"
              block
            >
              Buscar
            </Button>
          </Col>
        </Row>

        <br />
        <BaseCsvIconDownloader
          rowCount={(this.state.surveysAppliedResume || []).length}
          executeReportDownload={getQuizzAppliedResumeRequestDownload}
          getFilters={() => {
            const params = { ...this.state.filters };
            params.clubId =
              !!params.clubId && params.clubId > 0 ? params.clubId : null;
            params.dispositivoId =
              !!params.dispositivoId && params.dispositivoId > 0
                ? params.dispositivoId
                : null;
            params.encuestaId =
              !!params.encuestaId && params.encuestaId > 0
                ? params.encuestaId
                : null;
            params.anio = !!params.anio && params.anio > 0 ? params.anio : null;
            params.mes = !!params.mes && params.mes > 0 ? params.mes : null;
            return params;
          }}
        />

        <ReactTable
          className="mt-5 -striped -highlight"
          data={this.state.surveysAppliedResume || []}
          columns={columns}
          sortable={false}
          resizable={false}
          pageSize={(this.state.surveysAppliedResume || []).length + 2}
          showPagination={false}
          loading={false}
        />
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(QuizzSurveyAppliedResume));
