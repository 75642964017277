import React from "react";
import BaseInput from "../../../toolkit/baseInput";

export const PromotionSelector = ({
  value,
  disabled,
  onChange,
  promotions,
  size = "sm"
}) => (
  <BaseInput
    label="Promoción"
    name="promocionSelector"
    type="select"
    id="promocionSelector"
    placeholder="Promoción"
    value={value}
    size={size}
    disabled={disabled}
    onChange={e => {
      const value = e.target.value;
      return onChange(value);
    }}
    options={[
      {
        value: "",
        label: "Sin Promoción"
      }
    ].concat(
      promotions
        ? promotions.map(option => ({
            value: option.id,
            label: option.nombre
          }))
        : []
    )}
  />
);
