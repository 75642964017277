import React from "react";
import { Button, Col, Container, Row, TabPane } from "reactstrap";
import { QueryResponse } from "../../../../types/QueryResponse";
import { userHasPermission } from "../../../../utils/accessTree";
import { handleRequestError } from "../../../../utils/helpers";
import permissionCodes from "../../../../utils/permissionCodes";
import { getBinnacleByPartnerMembershipAxios } from "../../../actions/binnacles";
import PagedTable from "../../common/PagedTable";
import AddBinnacleMessageModal from "./addBinnacleMessageModal/addBinnacleMessageModal";

interface Binnacle {
  eventoFecha: string;
  actividad: string;
}

interface Props {
  idMembresiaSocio: string;
  idPersona: string;
  activeTab: string;
}

interface State {
  searching: boolean;
  search: any;
  binnacles: QueryResponse<Binnacle>;
  addMessageModalIsOpen: boolean;
}

class TabBinnacle extends React.Component<Props, State> {
  state = {
    binnacles: [] as any,
    searching: false,
    search: {
      membresiaSocioId: null,
      page: 0,
      size: 20
    },
    addMessageModalIsOpen: false
  };

  onFetchData = async ({ pageSize: size, page }) => {
    await this.setState(state => ({
      ...state,
      search: { ...this.state.search, size, page }
    }));
    await this.search();
  };

  search = async () => {
    await this.setState(state => ({
      ...state,
      searching: true,
      search: {
        ...this.state.search,
        membresiaSocioId: this.props.idMembresiaSocio,
        personaId: this.props.idPersona
      }
    }));

    getBinnacleByPartnerMembershipAxios(this.state.search)
      .then(({ data }) => {
        if (data && data.content && data.content.length) {
          data.content.forEach(d => {
            d.eventoFecha = formatDate(d.eventoFecha);

            // Operaciones realizadas por sistema
            if (d.eventoUserId <= 0) {
              d.eventoUsername = "SISTEMA";
              d.eventoUserFullName = "USUARIO GLOBAL DE SISTEMA";
            }

            // Mapping JSON para casos específicos
            if (d.modulo == "ARCHIVOS" && d.json) {
              try {
                const json = JSON.parse(d.json);
                const archivo = json && json.nombre ? ` (${json.nombre})` : "";

                d.actividad = (d.actividad || "") + archivo;
              } catch (error) {}
            }
          });
        }

        this.setState(state => ({
          ...state,
          binnacles: data,
          searching: false
        }));
      })
      .catch((err, f?: Function) => {
        handleRequestError(err, f);
        this.setState(state => ({ ...state, searching: false }));
      });
  };

  render() {
    if (this.props.activeTab != "18") {
      return <></>;
    }

    const columns = [
      { name: "Fecha", id: "eventoFecha", minWidth: 100 },
      { name: "Usuario", id: "eventoUsername", minWidth: 100 },
      { name: "Nombre", id: "eventoUserFullName", minWidth: 180 },
      { name: "Descripción", id: "actividad", minWidth: 300 }
    ];

    return (
      <TabPane className="my-2" tabId="18">
        <AddBinnacleMessageModal
          idMembresiaSocio={this.props.idMembresiaSocio}
          isOpen={this.state.addMessageModalIsOpen}
          cancel={() => {
            this.setState({ addMessageModalIsOpen: false });
          }}
          success={() => {
            this.setState({ addMessageModalIsOpen: false });
            this.search();
          }}
        />
        <Row>
          <Col md="5">
            <div className="m-4">
              {userHasPermission(
                permissionCodes.PARTNER_MEMBERSHIP_TAB_BINNACLE_ADD_MESSAGE
              ) && (
                <Button
                  className=""
                  size="sm"
                  onClick={() => {
                    this.setState({ addMessageModalIsOpen: true });
                  }}
                >
                  Agregar registro a la bitácora
                </Button>
              )}
            </div>
          </Col>
        </Row>

        <Container className="p-0 mt-3">
          <PagedTable
            data={this.state.binnacles || []}
            columns={columns}
            fetchData={this.onFetchData}
            searching={this.state.searching}
            defaultPageSize={100}
          />
        </Container>
      </TabPane>
    );
  }
}

function formatDate(isoDate: string): string {
  try {
    const [date, time] = isoDate.split("T");
    const [year, month, day] = date.split("-");

    // Formato de la fecha:
    const fmtDate = `${day}/${month}/${year} `;
    const indexSeconds = time.lastIndexOf(":");
    const fmtTime = indexSeconds >= 4 ? time.substring(0, indexSeconds) : time;

    return fmtDate + fmtTime;
  } catch (error) {
    return isoDate;
  }
}

export default TabBinnacle;
