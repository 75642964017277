import React from "react";
import BaseInput from "../../../toolkit/baseInput";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  Button
} from "reactstrap";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./stylesComponents.scss";
import axios from "axios";
import constants from "../../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faSave,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import { getBillingCatalogByKeyRequest } from "../../actions/billing";
import { REGIMENES_RECEPTOR_CFDI40, USO_CFDI40 } from "./cfdi40Uso";

const OFICINA = "OFICINA";
const CASA = "CASA";
const SELECCIONAR = "";

interface Direccion {
  tipo: string;
  codigoPostal: string;
  colonia: string;
  calle: string;
  municipio: string;
  numero: string;
  estado: string;
  domicilioFiscal: boolean;
  rfc: string;
  razonSocial: string;
  direccionId: string;
  tipoPersonaFiscal: string;
  cuentaProducto: string;
  regimenFiscal: string;
  claveUsoCfdi: string;
  claveUsoCfdi40: string;
}

interface Props {
  viewMode: boolean;
  data: Direccion[];
  onAddDireccion: () => void;
  onDeleteDireccion: (index: number) => void;
  saveDireccion: (index: number) => void;
  onChangeDireccion: (name: string, value: string, index: string) => void;
  onChangeColonia: (value: string, index: string) => void;
  onChangeDomicilioFiscal: (checked: boolean, index: string) => void;
  errors: any;
  isConcesionario: boolean;
  isCorporativo: boolean;
  ocultarTitulo: boolean;
}

interface State {
  coloniasOptions: Array<object>;
  municipiosOptions: Array<object>;
  estadosOptions: Array<object>;
  inicializar: boolean;
  tempAddress: Array<object>;
  catalogUsiCfdi: any[];
}

const USO_CFDI_CATALOG_KEY = "CatUsoCfdi";
const REGIMEN_EMISOR_CATALOG_KEY = "CatRegimenFiscalReceptor";
const USO_CFDI_DEFAULT = "P01";

class Direcciones extends React.Component<Props, State> {
  state = {
    coloniasOptions: [[{ value: SELECCIONAR, label: "Selecciona la Colonia" }]],
    municipiosOptions: [
      [{ value: SELECCIONAR, label: "Selecciona el Municipio" }]
    ],
    estadosOptions: [[{ value: SELECCIONAR, label: "Seleccionar el Estado" }]],
    inicializar: true,
    tempAddress: null,
    catalogUsiCfdi: []
  };

  componentDidMount() {
    getBillingCatalogByKeyRequest(USO_CFDI_CATALOG_KEY).then(
      ({ data: catalogUsiCfdi }) => {
        this.setState({ catalogUsiCfdi });
      }
    );
    /*getBillingCatalogByKeyRequest(REGIMEN_EMISOR_CATALOG_KEY).then(
      ({ data: catalogRegimenEmisor }) => {
        this.setState({ catalogRegimenEmisor });
      }
    );*/
  }

  componentDidUpdate() {
    if (this.state.inicializar && this.props.data.length > 0) {
      this.props.data.forEach((direccion, index) => {
        if (direccion.codigoPostal.toString().length == 5)
          this.getLocationByPostalCode(direccion.codigoPostal, index);
      });
      this.setState(state => ({
        ...state,
        inicializar: false
      }));
    }
  }

  renderDirecciones = () => {
    let data = this.props.data.map((direccion, index) => {
      const disableSave = !(
        direccion.calle &&
        direccion.numero &&
        direccion.tipo &&
        direccion.codigoPostal &&
        direccion.colonia &&
        direccion.municipio &&
        direccion.estado &&
        (!direccion.domicilioFiscal ||
          (direccion.domicilioFiscal &&
            direccion.tipoPersonaFiscal &&
            direccion.rfc &&
            direccion.razonSocial &&
            direccion.regimenFiscal &&
            direccion.claveUsoCfdi40))
      );

      const disableDelete = !direccion.direccionId;
      const catRegimenFiscal = !direccion.tipoPersonaFiscal
        ? [{ value: "", label: "Seleccione tipo de persona" }]
        : direccion.tipoPersonaFiscal == "FISICA"
        ? [
            { value: "", label: "Seleccione" },
            ...REGIMENES_RECEPTOR_CFDI40.filter(rf => rf.fisica)
          ]
        : [
            { value: "", label: "Seleccione" },
            ...REGIMENES_RECEPTOR_CFDI40.filter(rf => rf.moral)
          ];

      const usos = (
        REGIMENES_RECEPTOR_CFDI40.filter(
          rf => (rf.value as any) == direccion.regimenFiscal
        ).pop() || { usos: [] }
      ).usos;

      const catUsoCfdi40 = !direccion.regimenFiscal
        ? [{ value: "", label: "Seleccione el régimen" }]
        : [
            { value: "", label: "Seleccione" },
            ...USO_CFDI40.filter(uso => usos.indexOf(uso.value) >= 0)
          ];

      return (
        <Card key={index + "-direccion"} className={s.card}>
          <CardBody className={`p-1 ${s.cardBody}`}>
            {!this.props.viewMode && (
              <div className="mt-1 text-right">
                <a
                  className="mr-3"
                  onClick={e => {
                    e.preventDefault();
                    if (!disableSave) this.props.saveDireccion(index);
                  }}
                  style={{
                    display: "inline-block",
                    color: disableSave ? "gray" : "black",
                    cursor: "pointer"
                  }}
                >
                  <FontAwesomeIcon icon={faSave} />
                </a>

                <a
                  className="mr-4"
                  style={{
                    display: "inline-block",
                    color: disableDelete ? "gray" : "black",
                    cursor: "pointer"
                  }}
                  onClick={e =>
                    !disableDelete && this.deleteDireccion(e, index)
                  }
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                </a>
              </div>
            )}
            <Row className="mt-2">
              <Col xs={6}>
                <BaseInput
                  label={"Calle * "}
                  name={`calle_${index}`}
                  type="text"
                  id={`calle_${index}`}
                  placeholder={"Calle"}
                  value={direccion.calle}
                  onChange={e =>
                    this.handleChangeDireccion("calle", index, e, true)
                  }
                  uppercase={true}
                  disabled={!!this.props.viewMode}
                />
              </Col>
              <Col xs={3}>
                <BaseInput
                  label={"Número * "}
                  name={`numero_${index}`}
                  type="text"
                  id={`numero_${index}`}
                  placeholder={"Número"}
                  value={direccion.numero}
                  onChange={e => this.handleChangeDireccion("numero", index, e)}
                  disabled={!!this.props.viewMode}
                />
              </Col>
              <Col xs={3}>
                <BaseInput
                  label={"Tipo * "}
                  name={`tipo_${index}`}
                  type="select"
                  options={this.typeHouseOptions}
                  id={`tipo_${index}`}
                  placeholder={"Tipo"}
                  value={direccion.tipo}
                  onChange={e => this.handleChangeDireccion("tipo", index, e)}
                  disabled={!!this.props.viewMode}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs={2}>
                <BaseInput
                  label={"Código Postal * "}
                  name={`codigoPostal_${index}`}
                  type="text"
                  id={`codigoPostal_${index}`}
                  placeholder={"Código Postal"}
                  value={direccion.codigoPostal}
                  onChange={e => this.handleChangeCodigoPostal(index, e)}
                  disabled={!!this.props.viewMode}
                />
              </Col>
              <Col>
                <BaseInput
                  label={"Colonia * "}
                  name={`colonia_${index}`}
                  type="select"
                  options={this.getColoniasOptions(index, direccion.colonia)}
                  id={`colonia_${index}`}
                  placeholder={"Colonia"}
                  value={direccion.colonia}
                  onChange={e => this.handleChangeColonia(index, e)}
                  disabled={!!this.props.viewMode}
                />
              </Col>
              <Col>
                <BaseInput
                  label={"Delegación o Municipio * "}
                  name={`municipio_${index}`}
                  type="select"
                  options={this.state.municipiosOptions[index]}
                  id={`municipio_${index}`}
                  placeholder={"Municipio"}
                  value={direccion.municipio}
                  onChange={e =>
                    this.handleChangeDireccion("municipio", index, e)
                  }
                  disabled={!!this.props.viewMode}
                />
              </Col>
              <Col>
                <BaseInput
                  label={"Estado * "}
                  name={`estado_${index}`}
                  type="select"
                  options={this.state.estadosOptions[index]}
                  id={`estado_${index}`}
                  placeholder={"Estado"}
                  value={direccion.estado}
                  onChange={e => this.handleChangeDireccion("estado", index, e)}
                  disabled={!!this.props.viewMode}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={2} className={s.centerRow}>
                <FormGroup className={s.label} check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={direccion.domicilioFiscal}
                      onChange={e => this.handleChangeDomicilioFiscal(e, index)}
                      disabled={!!this.props.viewMode}
                    />
                    {"Domicilio Fiscal"}
                  </Label>
                </FormGroup>
              </Col>
            </Row>

            {!!direccion.domicilioFiscal && (
              <Row className="mt-2">
                <Col>
                  <BaseInput
                    label={"Tipo persona fiscal"}
                    name={`tipo_persona_fiscal${index}`}
                    type="select"
                    options={this.taxPersonType}
                    id={`tipo_persona_fiscal_${index}`}
                    placeholder={"Tipo persona fiscal"}
                    value={direccion.tipoPersonaFiscal}
                    disabled={
                      !direccion.domicilioFiscal || !!this.props.viewMode
                    }
                    onChange={e =>
                      this.handleChangeDireccion("tipoPersonaFiscal", index, e)
                    }
                  />
                </Col>
                <Col>
                  <BaseInput
                    type="text"
                    label={"RFC"}
                    name={`rfc_${index}`}
                    id={`rfc_${index}`}
                    className="text-uppercase"
                    disabled={
                      !direccion.domicilioFiscal || !!this.props.viewMode
                    }
                    placeholder={"RFC"}
                    value={direccion.rfc}
                    onChange={e =>
                      this.handleChangeDireccion("rfc", index, e, true)
                    }
                    uppercase={true}
                    maxlength={"13"}
                  />
                </Col>
                <Col>
                  <BaseInput
                    type="text"
                    label={"Razón Social"}
                    name={`razonSocial_${index}`}
                    id={`razonSocial_${index}`}
                    className="text-uppercase"
                    placeholder={"Razón Social"}
                    disabled={
                      !direccion.domicilioFiscal || !!this.props.viewMode
                    }
                    value={direccion.razonSocial}
                    onChange={e =>
                      this.handleChangeDireccion("razonSocial", index, e, true)
                    }
                    uppercase={true}
                  />
                </Col>

                <Col>
                  <BaseInput
                    type="select"
                    label="Clave uso CFDI"
                    name={`clave_uso_cfdi_${index}`}
                    id={`clave_uso_cfdi_${index}`}
                    options={[
                      {
                        value: "",
                        label: "Seleccione un uso de cfdi"
                      }
                    ].concat(
                      (this.state.catalogUsiCfdi || [])
                        .sort((a, b) =>
                          a.descripcion.localeCompare(b.descripcion)
                        )
                        .map(({ clave, descripcion }) => {
                          return {
                            value: clave,
                            label: `${clave} - ${descripcion}`
                          };
                        })
                    )}
                    value={direccion.claveUsoCfdi || ""}
                    disabled={
                      !direccion.domicilioFiscal || !!this.props.viewMode
                    }
                    onChange={e =>
                      this.handleChangeDireccion("claveUsoCfdi", index, e)
                    }
                  />
                </Col>
                {(this.props.isConcesionario || this.props.isCorporativo) && (
                  <Col>
                    <BaseInput
                      label={"Cuenta producto"}
                      name={`cuentaProducto_${index}`}
                      className="text-uppercase"
                      type="text"
                      id={`cuentaProducto_${index}`}
                      placeholder={"Cuenta producto"}
                      disabled={
                        !direccion.domicilioFiscal || !!this.props.viewMode
                      }
                      value={direccion.cuentaProducto}
                      onChange={e =>
                        this.handleChangeDireccion(
                          "cuentaProducto",
                          index,
                          e,
                          true
                        )
                      }
                      uppercase={true}
                      maxlength={15}
                    />
                  </Col>
                )}
              </Row>
            )}

            {/* CFDI 4.0 */}
            {!!direccion.domicilioFiscal && (
              <Row className="mt-2">
                <Col sm="12">
                  <div
                    className="alert alert-warning"
                    style={{ border: "1px #ccc solid", fontSize: "0.8em" }}
                  >
                    Los siguientes valores son requeridos a partir de CFDI 4.0,
                    asegúrese de ingresar EXACTAMENTE el código postal, la razón
                    social y estos dos campos.
                  </div>
                </Col>
                <Col md="3">
                  <BaseInput
                    type="select"
                    label={"Régimen fiscal del receptor"}
                    name={`regimen_fiscal_${index}`}
                    id={`regimen_fiscal_${index}`}
                    options={catRegimenFiscal}
                    placeholder={"Régimen fiscal"}
                    value={direccion.regimenFiscal || ""}
                    disabled={
                      !direccion.domicilioFiscal || !!this.props.viewMode
                    }
                    onChange={e =>
                      this.handleChangeDireccion("regimenFiscal", index, e)
                    }
                  />
                </Col>
                <Col md="3">
                  <BaseInput
                    type="select"
                    label={"Clave uso CFDI 4.0"}
                    name={`uso_cfdi_40_${index}`}
                    id={`uso_cfdi_40_${index}`}
                    options={catUsoCfdi40}
                    placeholder={"Régimen fiscal"}
                    value={direccion.claveUsoCfdi40 || ""}
                    disabled={
                      !direccion.domicilioFiscal || !!this.props.viewMode
                    }
                    onChange={e =>
                      this.handleChangeDireccion("claveUsoCfdi40", index, e)
                    }
                  />
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      );
    });
    return data;
  };

  typeHouseOptions = [
    { value: OFICINA, label: "OFICINA" },
    { value: CASA, label: "CASA" }
  ];

  taxPersonType = [
    { value: "", label: "Seleccione" },
    { value: "FISICA", label: "FÍSICA" },
    { value: "MORAL", label: "MORAL" }
  ];

  getColoniasOptions = (index, stateValue) => {
    if (
      !!this.state.coloniasOptions[index] &&
      !(this.state.coloniasOptions[index] || []).find(
        x => x.value == stateValue
      )
    ) {
      let cOptions = this.state.coloniasOptions;
      cOptions[index].push({ value: stateValue, label: stateValue });
      this.setState(() => ({
        coloniasOptions: cOptions
      }));
    }
    return this.state.coloniasOptions[index];
  };

  handleChangeDireccion = (name, index, e, toUpperCase?: boolean) => {
    const tmp = e.target.value;
    const value = !!toUpperCase ? (tmp || "").toUpperCase() : tmp;
    this.props.onChangeDireccion(name, value, index);

    if (name == "tipoPersonaFiscal") {
      this.props.onChangeDireccion("regimenFiscal", "", index);
      this.props.onChangeDireccion("claveUsoCfdi40", "", index);
    } else if (name == "regimenFiscal") {
      this.props.onChangeDireccion("claveUsoCfdi40", "", index);
    }
  };

  handleChangeColonia = (index, e) => {
    const value = e.target.value;
    this.props.onChangeColonia(value, index);
    this.setComplementalData(value, index);
  };

  setComplementalData(value, index) {
    this.state.tempAddress.forEach(colonia => {
      if (colonia.nombre === value) {
        this.props.onChangeDireccion("municipio", colonia.municipio, index);
        this.props.onChangeDireccion("estado", colonia.estado, index);
      }
    });
  }

  getLocationByPostalCode = (postalCode, index) => {
    axios
      .get(
        `${constants.BASE_URL}/api/address/locationByPostalCode/${postalCode}`,
        {
          withCredentials: true
        }
      )
      .then(response => {
        this.changeAddressCombos(response.data, index);
      })
      .catch(_ => {
        alert("Ocurrio un error, verifica el código Postal");
      });
  };

  handleChangeCodigoPostal = async (index, e) => {
    e.preventDefault();
    const value = e.target.value.toString();
    this.props.onChangeDireccion("codigoPostal", value, index);
    if (value.length == 5) {
      this.getLocationByPostalCode(value, index);
    }
  };

  changeAddressCombos = (address, index) => {
    const coloniasNombre = {};
    const municipiosNombre = {};
    const estadosNombre = {};

    const colonias = [
      {
        value: SELECCIONAR,
        label:
          address.length > 0
            ? "Selecciona la Colonia"
            : "Verifica el Código Postal"
      }
    ];
    const municipios = [
      {
        value: SELECCIONAR,
        label:
          address.length > 0
            ? "Selecciona el Municipio"
            : "Verifica el Código Postal"
      }
    ];
    const estados = [
      {
        value: SELECCIONAR,
        label:
          address.length > 0
            ? "Selecciona el Estado"
            : "Verifica el Código Postal"
      }
    ];

    address.forEach(direccion => {
      const { nombre, municipio, estado } = direccion;
      if (!coloniasNombre[nombre]) {
        colonias.push({
          value: nombre,
          label: nombre
        });
        coloniasNombre[nombre] = true;
      }
      if (!municipiosNombre[municipio]) {
        municipios.push({
          value: municipio,
          label: municipio
        });
        municipiosNombre[municipio] = true;
      }
      if (!estadosNombre[estado]) {
        estados.push({
          value: estado,
          label: estado
        });
        estadosNombre[estado] = true;
      }
    });
    let cOptions = this.state.coloniasOptions;
    let mOptions = this.state.municipiosOptions;
    let eOptions = this.state.estadosOptions;
    cOptions[index] = colonias;
    mOptions[index] = municipios;
    eOptions[index] = estados;

    this.setState(state => ({
      ...state,
      coloniasOptions: cOptions,
      municipiosOptions: mOptions,
      estadosOptions: eOptions,
      tempAddress: address
    }));
  };

  handleAddDireccion = e => {
    e.preventDefault();
    this.props.onAddDireccion();
  };

  deleteDireccion = (e, index) => {
    e.preventDefault();
    this.props.onDeleteDireccion(index);
  };

  handleChangeDomicilioFiscal = (e, index) => {
    const checked = e.target.checked;
    this.props.onChangeDomicilioFiscal(checked, index);
  };

  render() {
    return (
      <div>
        {!this.props.ocultarTitulo && (
          <Row className="primaryTitle m-0 mt-3">
            <Col>
              <p className="m-0">Direcciones</p>
              {this.props.errors &&
                this.props.errors.map((message, index) => {
                  return (
                    <span key={index} className={s.errorSpan}>
                      {message}
                      <br />
                    </span>
                  );
                })}
            </Col>
            <Col xs={3} className="text-right">
              {!this.props.viewMode && (
                <Button
                  className="btn btn-light btn-sm"
                  onClick={e => this.handleAddDireccion(e)}
                >
                  Agregar Dirección
                  <FontAwesomeIcon className="ml-2" icon={faPlus} />
                </Button>
              )}
            </Col>
          </Row>
        )}

        {this.renderDirecciones()}
      </div>
    );
  }
}

export default withRouter(withStyles(s)(Direcciones));
