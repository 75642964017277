import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import constants from "../../utils/constants";
import { restActionFactory } from "../../toolkit/axiosActions/axiosActionsFactory";

const COMISIONES_DEPORTIVA_CRM1_BASE_URL =
  "/api/reports/comisiones-deportiva-crm1";

export const getComisionesDeportivaCrm1Pendientes = (params?: any): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.GET_COMISIONES_DEPORTIVA_CRM1_PENDIENTES,
    urlComplement: "/pendientes",
    method: "get",
    params
  });
};

export const putComisionesDeportivaCrm1Procesar = (body: any): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.PUT_COMISIONES_DEPORTIVA_CRM1_PROCESAR,
    urlComplement: "/procesar",
    method: "put",
    body
  });
};

export const getClasesSinComision = (params?: any): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.GET_CLASES_SIN_COMISION,
    urlComplement: "/impartidas-sin-comision",
    method: "get",
    params
  });
};

export const postClasesComisionGenerar = (body: number[]): IAction => {
  return comisionesRestAction({
    actionType: actionTypes.POST_CLASES_COMISION_GENERAR,
    urlComplement: "/reparar-operacion",
    method: "post",
    body
  });
};

const comisionesRestAction = restActionFactory(
  `${constants.BASE_URL}${COMISIONES_DEPORTIVA_CRM1_BASE_URL}`
);
