import React from "react";
import s from "../styles.scss";

export default ({ label, value, onChange, id }) => (
  <div className="form-group mb-1 mt-3">
    <label>{label}</label>
    <textarea
      className={`form-control ${s.baseInput} px-2 py-1`}
      style={{ height: null }}
      rows={10}
      name={id}
      id={id}
      placeholder={label}
      value={value}
      onChange={event => {
        const value = event.target.value;
        onChange(value);
      }}
    />
  </div>
);
