import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import BaseInput from "../../../../toolkit/baseInput";
import s from "../styles.scss";
import { FILTERS } from "../interfaces";

interface Props {
  getMembershipSoldReport: (filters: FILTERS) => void;
  clubs: { clubId: number; nombre: string }[];
  periods: { id: number; nombre: string }[];
}

interface State extends FILTERS {}

class ReportFilter extends React.Component<Props, State> {
  state = {
    clubId: null,
    periodoId: null,
    disabledSearch: true
  };

  componentDidMount = () => {};

  toggleDisabledSearch = () => {
    let state = this.state;
    if (this.state.clubId && this.state.periodoId) state.disabledSearch = false;
    else state.disabledSearch = true;
    this.setState(state);
  };

  onChangeClubPeriodDrop = async (e, fieldName) => {
    let state = this.state;
    state[fieldName] = e.target.value;
    await this.setState(state);
    this.toggleDisabledSearch();
  };

  render() {
    return (
      <Container className="mt-3 mb-5 p-0">
        <Row className="my-4">
          <Col sm="4">
            <BaseInput
              label={"* Club"}
              name="Club"
              type="select"
              id="club"
              size={"sm"}
              value={this.state.clubId}
              options={[{ value: -1, label: "Seleccionar el club" }].concat(
                this.props.clubs.map(({ clubId, nombre }) => {
                  return {
                    value: clubId,
                    label: nombre
                  };
                })
              )}
              onChange={e => this.onChangeClubPeriodDrop(e, "clubId")}
            />
          </Col>
          <Col sm="4">
            <BaseInput
              label={"* Periodo"}
              name="perios"
              type="select"
              id="periodo"
              size={"sm"}
              value={this.state.periodoId}
              options={[{ value: -1, label: "Seleccionar el periodo" }].concat(
                this.props.periods.map(({ id, nombre }) => {
                  return {
                    value: id,
                    label: nombre
                  };
                })
              )}
              onChange={e => this.onChangeClubPeriodDrop(e, "periodoId")}
            />
          </Col>
        </Row>

        <hr />

        <Row className="my-4">
          <Col sm={{ size: 3, offset: 9 }}>
            <Button
              size="lg"
              disabled={this.state.disabledSearch}
              block
              onClick={() => this.props.getMembershipSoldReport(this.state)}
            >
              Buscar
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ReportFilter));
