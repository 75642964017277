import React, { useState } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import ClubSelector from "../../../../../Selectors/containers/ClubSelector";
import TotalSales from "../../../containers/TotalSales";
import TotalSalesBySeller from "../../../containers/TotalSalesBySeller";
import { MonthSelector } from "../../../../../Selectors/components/MonthSelector";
import * as moment from "moment";
import GenericModal from "../../../../../Modals/components/GenericModal";
import OpenNewActivityModalButton from "./OpenNewActivityModalButton";
import ActivitiesTable from "../../../../../Activities/containers/ActivitiesTable";
import NewUserActivity from "../../../../../Activities/containers/NewUserActivity";
import { validDateGoals } from "../../../../../utils/helpers";
import isEmpty from "lodash.isempty";
import permissionCodes from "../../../../../utils/permissionCodes";
import { userHasPermission } from "../../../../../utils/accessTree";

interface Props {
  sendCompromisesBySeller: (compromises) => any;
  sendCompromises: (compromises) => any;
  userContext: any;
}

const SalesTrackingLayout = ({
  sendCompromisesBySeller,
  sendCompromises,
  userContext
}: Props) => {
  const [clubId, setClubId] = useState(
    (userContext && userContext.clubSessionId) || ""
  );
  const [{ month, year }, setMonth] = useState({
    month: moment().month() + 1,
    year: moment().year()
  });
  const [hasSellers, setHasSellers] = useState(false);

  const validToday = validDateGoals(month, year);

  let clubCompromises = {};
  let clubCompromisesBySeller = {};

  const saveCompromises = newClubCompromises => {
    clubCompromises = newClubCompromises;
  };

  const saveCompromisesBySeller = newClubCompromisesBySeller => {
    clubCompromisesBySeller = newClubCompromisesBySeller;
  };

  return (
    <Container className="mt-3 p-0">
      <Row>
        <Col>
          <ClubSelector
            value={clubId}
            onChange={setClubId}
            userClub={userContext && userContext.clubSessionId}
          />
        </Col>
        <Col>
          <MonthSelector month={month} year={year} onChange={setMonth} />
        </Col>
      </Row>
      <Row>
        <Col>
          <TotalSales
            clubId={clubId}
            month={month}
            year={year}
            saveCompromises={saveCompromises}
            disabled={!validToday}
            setHasSellers={setHasSellers}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        {userHasPermission(
          permissionCodes.SALES_TRACKING_MONTH_COMPROMISES_EDITION_PERMISSION
        ) &&
          hasSellers && (
            <Col className="text-right">
              <Button
                className="primaryButton"
                onClick={e => {
                  e.preventDefault();
                  sendCompromises(clubCompromises);
                }}
                disabled={!validToday}
                size="sm"
              >
                Guardar Compromisos
              </Button>
            </Col>
          )}
      </Row>
      <hr />
      <Row>
        <Col>
          <TotalSalesBySeller
            clubId={clubId}
            month={month}
            year={year}
            disabled={!validToday}
            saveCompromisesBySeller={saveCompromisesBySeller}
            setHasSellers={setHasSellers}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        {userHasPermission(
          permissionCodes.SALES_TRACKING_SELLER_COMPROMISES_EDITION_PERMISSION
        ) &&
          hasSellers && (
            <Col className="text-right">
              <Button
                className="primaryButton"
                disabled={!validToday}
                onClick={e => {
                  e.preventDefault();
                  sendCompromisesBySeller(clubCompromisesBySeller);
                }}
                size="sm"
              >
                Guardar Compromisos por Vendedor
              </Button>
            </Col>
          )}
      </Row>
      <hr />
      <Row>
        <Col>
          <ActivitiesTable
            clubId={clubId}
            disabled={!validToday}
            month={month}
            year={year}
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col xs={9} />
        {userHasPermission(
          permissionCodes.SALES_TRACKING_NEW_ACTIVITY_CREATION_PERMISSION
        ) && (
          <Col>
            <GenericModal
              Button={({ onClick }) => (
                <OpenNewActivityModalButton
                  onClick={onClick}
                  disabled={!validToday}
                />
              )}
              title="Nueva Actividad"
            >
              <NewUserActivity disabled={!validToday} clubId={clubId} />
            </GenericModal>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default SalesTrackingLayout;
