import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./styles.scss";

interface Props {
  type: string;
  name: string;
  id: string;
  placeholder?: string;
  label?: string;
  value: string | number | Date;
  dataQAId: string;
  dataElmId: string;
  onChange: (string: string) => void;
  onBlur: (string: string) => void;
  errors: Array<string>;
  disabled: boolean;
  size: string;
  options: Array<{
    label: string;
    value: string | number;
  }>;
  className?: string;
  uppercase: boolean;
}

class BaseAlphabeticInput extends React.Component<Props, void> {
  render() {
    const {
      id,
      name,
      value,
      label,
      placeholder,
      dataQAId,
      dataElmId,
      errors,
      disabled,
      size
    } = this.props;

    const specialCharCodes = [
      8,
      9,
      32,
      193,
      201,
      205,
      209,
      211,
      218,
      241,
      225,
      233,
      237,
      243,
      250
    ];

    return (
      <FormGroup className={s.formGroup}>
        {label && (
          <Label className={s.label} for={id}>
            {label}:
          </Label>
        )}
        <Input
          type="text"
          name={name}
          id={id}
          placeholder={placeholder}
          className={`${s.input} px-2 py-1 ${
            !!this.props.uppercase ? "text-uppercase" : ""
          }`}
          value={value}
          disabled={disabled || false}
          data-qa-id={dataQAId}
          data-elm-id={dataElmId}
          onKeyPress={event => {
            if (
              !(
                (event.charCode >= 65 && event.charCode <= 90) ||
                (event.charCode >= 97 && event.charCode <= 122) ||
                specialCharCodes.indexOf(event.charCode) >= 0
              )
            ) {
              event.preventDefault();
            }
          }}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          size={size || "md"}
        />
        {errors &&
          errors.map((message, index) => {
            return (
              <span key={index} className={s.errorSpan}>
                {message}
                <br />
              </span>
            );
          })}
      </FormGroup>
    );
  }
}

export default withStyles(s)(BaseAlphabeticInput);
