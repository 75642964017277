import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import { getErrorMessage } from "../../utils/helpers";
import { PROMOTION_PRICE, PRICE_TYPES } from "../../types-business/Promotions";
import moment from "moment";
import {
  infoNotification,
  errorNotification,
  successNotification,
  PENDING,
  ERROR,
  SAVE_BASIC_PROMOTION,
  ADD_CLUBS_PROMOTION,
  ADD_MAINTENANCES_PROMOTION,
  ADD_AGREEMENTS_PROMOTION_PENDING,
  ADD_AGREEMENTS_PROMOTION_ERROR,
  ADD_AGREEMENTS_PROMOTION,
  SAVE_CHANGES_BENEFITS_AGREEMENT,
  SAVE_CHANGES_BENEFITS_AGREEMENT_ERROR,
  SAVE_CHANGES_BENEFITS_AGREEMENT_PENDING
} from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";
const MONTHS_IN_YEAR = 12;
interface State {
  basicPromotionSaved: boolean;
  gettingBasicPromotion: boolean;
  basicPromotion: object;
  clubsSaved: boolean;
  maintenancesSaved: boolean;
  promotionClubs: object[];
  promotionMaintenances: object[];
  promotionAgreements: object[];
  gettingPromotionsError: object;
  gettingPromotions: boolean;
  promotions: object[];
  promotionMemberships: object[];
  promotionPrices: PROMOTION_PRICE;
  promotionModified: boolean;
  changesConfirmed: boolean;
  promotionById: object;
  promotionsFiltered: object[];
  gettingPromotionsFiltered: boolean;
}
const initialState: State = {
  basicPromotionSaved: false,
  gettingBasicPromotion: false,
  basicPromotion: null,
  clubsSaved: false,
  promotionClubs: null,
  maintenancesSaved: false,
  promotionMaintenances: null,
  gettingPromotionsError: null,
  gettingPromotions: false,
  promotions: null,
  promotionMemberships: null,
  promotionPrices: null,
  promotionModified: false,
  changesConfirmed: false,
  promotionAgreements: null,
  promotionById: null,
  promotionsFiltered: null,
  gettingPromotionsFiltered: false
};

export default function promotionsReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.ADD_AGREEMENTS_PROMOTION}_PENDING`: {
      infoNotification(ADD_AGREEMENTS_PROMOTION_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.ADD_AGREEMENTS_PROMOTION}_REJECTED`: {
      errorNotification(
        readMessageBack(action, ADD_AGREEMENTS_PROMOTION_ERROR)
      );
      return {
        ...state
      };
    }
    case `${actionTypes.ADD_AGREEMENTS_PROMOTION}_FULFILLED`: {
      successNotification(ADD_AGREEMENTS_PROMOTION);
      return {
        ...state
      };
    }
    case `${actionTypes.PROMOTION_BY_ID}_PENDING`: {
      return {
        ...state,
        promotionById: null
      };
    }
    case `${actionTypes.PROMOTION_BY_ID}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        promotionById: null
      };
    }
    case `${actionTypes.PROMOTION_BY_ID}_FULFILLED`: {
      return {
        ...state,
        promotionById: action.payload.data
      };
    }
    case `${actionTypes.GET_PROMOTIONS_FILTERED}_PENDING`: {
      return {
        ...state,
        promotionsFiltered: null,
        gettingPromotionsFiltered: true
      };
    }
    case `${actionTypes.GET_PROMOTIONS_FILTERED}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        promotionsFiltered: null,
        gettingPromotionsFiltered: false
      };
    }
    case `${actionTypes.GET_PROMOTIONS_FILTERED}_FULFILLED`: {
      return {
        ...state,
        promotionsFiltered: action.payload.data,
        gettingPromotionsFiltered: false
      };
    }
    case `${actionTypes.GET_PROMOTIONS}_PENDING`: {
      return {
        ...state,
        gettingPromotionsError: null,
        gettingPromotions: true
      };
    }
    case `${actionTypes.GET_PROMOTIONS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        gettingPromotionsError: getErrorMessage(action.payload),
        promotions: null,
        gettingPromotions: false
      };
    }
    case `${actionTypes.GET_PROMOTIONS}_FULFILLED`: {
      return {
        ...state,
        gettingPromotionsError: null,
        promotions: action.payload.data,
        gettingPromotions: false
      };
    }
    case actionTypes.CONFIRM_BENEFITS_CHANGES: {
      return {
        ...state,
        changesConfirmed: true
      };
    }
    case actionTypes.MEMBERSHIP_CHANGED: {
      return {
        ...state,
        changesConfirmed: false,
        promotionModified: false,
        promotionPrices: null
      };
    }
    case actionTypes.CLUB_CHANGED: {
      return {
        ...state,
        changesConfirmed: false,
        promotionModified: false
      };
    }
    case `${actionTypes.SAVE_CHANGES_BENEFITS}_PENDING`: {
      infoNotification(SAVE_CHANGES_BENEFITS_AGREEMENT_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.SAVE_CHANGES_BENEFITS}_REJECTED`: {
      errorNotification(
        readMessageBack(action, SAVE_CHANGES_BENEFITS_AGREEMENT_ERROR)
      );
      return {
        ...state
      };
    }
    case `${actionTypes.SAVE_CHANGES_BENEFITS}_FULFILLED`: {
      successNotification(SAVE_CHANGES_BENEFITS_AGREEMENT);
      return {
        ...state,
        changesConfirmed: false,
        promotionModified: false
      };
    }
    case `${actionTypes.ADD_MAINTENANCES_PROMOTION}_PENDING`: {
      infoNotification(PENDING);
      return {
        ...state,
        maintenancesSaved: false
      };
    }
    case `${actionTypes.ADD_MAINTENANCES_PROMOTION}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        maintenancesSaved: false
      };
    }
    case `${actionTypes.ADD_MAINTENANCES_PROMOTION}_FULFILLED`: {
      successNotification(ADD_MAINTENANCES_PROMOTION);
      return {
        ...state,
        maintenancesSaved: true
      };
    }
    case `${actionTypes.GET_MAINTENANCES_PROMOTION}_PENDING`: {
      return {
        ...state,
        promotionMaintenances: null
      };
    }
    case `${actionTypes.GET_MAINTENANCES_PROMOTION}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        promotionMaintenances: null
      };
    }
    case `${actionTypes.GET_MAINTENANCES_PROMOTION}_FULFILLED`: {
      return {
        ...state,
        promotionMaintenances: action.payload.data
      };
    }
    case `${actionTypes.GET_MEMBERSHIPS_PROMOTION}_PENDING`: {
      return {
        ...state,
        promotionMemberships: null
      };
    }
    case `${actionTypes.GET_MEMBERSHIPS_PROMOTION}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        promotionMemberships: null
      };
    }
    case `${actionTypes.GET_MEMBERSHIPS_PROMOTION}_FULFILLED`: {
      return {
        ...state,
        promotionMemberships: action.payload.data
      };
    }
    case `${actionTypes.ADD_CLUBS_PROMOTION}_PENDING`: {
      infoNotification(PENDING);
      return {
        ...state,
        clubsSaved: false
      };
    }
    case `${actionTypes.ADD_CLUBS_PROMOTION}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        clubsSaved: false
      };
    }
    case `${actionTypes.ADD_CLUBS_PROMOTION}_FULFILLED`: {
      successNotification(ADD_CLUBS_PROMOTION);
      return {
        ...state,
        clubsSaved: true
      };
    }
    case `${actionTypes.PROMOTION_PRICE_CELL}`: {
      const promotionPrices = { ...state.promotionPrices };
      const { monthIndex, memberIndex, value } = action.payload;
      promotionPrices.meses[monthIndex].beneficios[memberIndex].valor = value;
      return {
        ...state,
        promotionPrices,
        promotionModified: true
      };
    }
    case `${actionTypes.CHECK_LAST_MONTH}`: {
      const promotionPrices = { ...state.promotionPrices };
      const { monthIndex, checked } = action.payload;
      if (checked) {
        promotionPrices.meses.forEach(benefit => {
          benefit.esUltimoMesRecurrente = false;
        });
      }
      promotionPrices.meses[monthIndex].esUltimoMesRecurrente = checked;
      return {
        ...state,
        promotionPrices,
        promotionModified: true
      };
    }
    case `${actionTypes.PROMOTION_MEMBERSHIP_VALUE_TYPE}`: {
      const promotionPrices = { ...state.promotionPrices };
      promotionPrices.tipoBeneficioMembresia = action.payload;
      promotionPrices.valorBeneficioMembresia = 0;
      return {
        ...state,
        promotionPrices,
        promotionModified: true
      };
    }
    case `${actionTypes.PROMOTION_MEMBERSHIP_VALUE_TYPE_MONTH}`: {
      const promotionPrices = { ...state.promotionPrices };
      const { monthIndex, priceType } = action.payload;
      promotionPrices.meses[monthIndex].tipoBeneficioMes = priceType;
      promotionPrices.meses[monthIndex].beneficios.forEach(beneficio => {
        beneficio.valor = 0;
      });

      return {
        ...state,
        promotionPrices,
        promotionModified: true
      };
    }
    case `${actionTypes.PROMOTION_MEMBERSHIP_VALUE}`: {
      const promotionPrices = { ...state.promotionPrices };
      promotionPrices.valorBeneficioMembresia = action.payload;
      return {
        ...state,
        promotionPrices,
        promotionModified: true
      };
    }
    case `${actionTypes.CHECK_PROPORTIONAL}`: {
      const promotionPrices = { ...state.promotionPrices };
      promotionPrices.aplicaProporcional = action.payload;
      return {
        ...state,
        promotionPrices,
        promotionModified: true
      };
    }
    case `${actionTypes.PROMOTION_BENEFIT_FREE_USER_MIRROR}`: {
      const promotionPrices = { ...state.promotionPrices };
      promotionPrices.invitadosGratisEspejo = action.payload;
      promotionPrices.invitadosGratisPorCumpleanos = false;
      promotionPrices.invitadosGratisFechaInicioPeriodo = null;
      promotionPrices.invitadosGratisFechaFinPeriodo = null;
      promotionPrices.invitadosGratisMeses = null;
      promotionPrices.ninosGratis = 0;
      return {
        ...state,
        promotionPrices,
        promotionModified: true
      };
    }
    case `${actionTypes.PROMOTION_BENEFIT_FREE_USER_BY_BIRTHDAY}`: {
      const promotionPrices = { ...state.promotionPrices };
      promotionPrices.invitadosGratisEspejo = false;
      promotionPrices.invitadosGratisPorCumpleanos = action.payload;
      promotionPrices.invitadosGratisFechaInicioPeriodo = null;
      promotionPrices.invitadosGratisFechaFinPeriodo = null;
      promotionPrices.invitadosGratisMeses = null;
      promotionPrices.ninosGratis = 0;
      return {
        ...state,
        promotionPrices,
        promotionModified: true
      };
    }
    case `${actionTypes.PROMOTION_BENEFIT_FREE_USER_NUMBER}`: {
      const promotionPrices = { ...state.promotionPrices };
      promotionPrices.invitadosGratis = action.payload;
      promotionPrices.ninosGratis = 0;
      return {
        ...state,
        promotionPrices,
        promotionModified: true
      };
    }
    case `${actionTypes.PROMOTION_BENEFIT_FREE_USER_INITIAL_DATE}`: {
      const promotionPrices = { ...state.promotionPrices };
      promotionPrices.invitadosGratisFechaInicioPeriodo = action.payload;
      promotionPrices.invitadosGratisMeses = null;
      promotionPrices.ninosGratis = 0;
      return {
        ...state,
        promotionPrices,
        promotionModified: true
      };
    }
    case `${actionTypes.PROMOTION_BENEFIT_FREE_USER_FINAL_DATE}`: {
      const promotionPrices = { ...state.promotionPrices };
      promotionPrices.invitadosGratisFechaFinPeriodo = action.payload;
      promotionPrices.invitadosGratisMeses = null;
      promotionPrices.ninosGratis = 0;
      return {
        ...state,
        promotionPrices,
        promotionModified: true
      };
    }
    case `${actionTypes.PROMOTION_BENEFIT_FREE_USER_MONTHS}`: {
      const promotionPrices = { ...state.promotionPrices };
      promotionPrices.invitadosGratisMeses = action.payload;
      promotionPrices.invitadosGratisFechaInicioPeriodo = null;
      promotionPrices.invitadosGratisFechaFinPeriodo = null;
      promotionPrices.ninosGratis = 0;
      return {
        ...state,
        promotionPrices,
        promotionModified: true
      };
    }
    case `${actionTypes.PROMOTION_BENEFIT_FREE_GUESTS_PASSES_NUMBER}`: {
      const promotionPrices = { ...state.promotionPrices };
      promotionPrices.pasesInvitadoGratis = action.payload;
      return {
        ...state,
        promotionPrices,
        promotionModified: true
      };
    }
    case `${actionTypes.PROMOTION_BENEFIT_FREE_CHILDREN_NUMBER}`: {
      const promotionPrices = { ...state.promotionPrices };
      promotionPrices.ninosGratis = action.payload;
      promotionPrices.invitadosGratisPorCumpleanos = false;
      promotionPrices.invitadosGratis = 0;
      promotionPrices.invitadosGratisMeses = null;
      promotionPrices.invitadosGratisFechaInicioPeriodo = null;
      promotionPrices.invitadosGratisFechaFinPeriodo = null;
      return {
        ...state,
        promotionPrices,
        promotionModified: true
      };
    }
    case `${actionTypes.CHECK_ABSOLUTE}`: {
      const promotionPrices = { ...state.promotionPrices };
      const mesRelativo = action.payload;
      promotionPrices.mesRelativo = mesRelativo;
      if (mesRelativo) {
        promotionPrices.meses.forEach((mesInfo, index) => {
          mesInfo.mes = index + 1;
        });
      }

      return {
        ...state,
        promotionPrices,
        promotionModified: true
      };
    }
    case `${actionTypes.ADD_MONTH_PRICES}`: {
      const promotionPrices = { ...state.promotionPrices };
      const benefits = [];
      for (
        let memberId = 1;
        memberId <= promotionPrices.numeroDeIntegrantes;
        memberId++
      ) {
        benefits.push({
          integrante: memberId,
          valor: 0
        });
      }

      const posicion =
        promotionPrices.meses.length > 0
          ? promotionPrices.meses[promotionPrices.meses.length - 1].posicion + 1
          : 0;
      let mes = 0;
      if (promotionPrices.meses.length > 0) {
        if (!promotionPrices.mesRelativo) {
          const mesAnterior =
            promotionPrices.meses[promotionPrices.meses.length - 1].mes;
          mes = mesAnterior == MONTHS_IN_YEAR ? 1 : mesAnterior + 1;
        } else {
          mes = promotionPrices.meses[promotionPrices.meses.length - 1].mes + 1;
        }
      } else {
        mes = promotionPrices.mesRelativo ? 1 : moment().month() + 1;
      }

      promotionPrices.meses.push({
        tipoBeneficioMes: PRICE_TYPES.DESCUENTOABS,
        esUltimoMesRecurrente: false,
        beneficios: benefits,
        posicion,
        mes
      });

      return {
        ...state,
        promotionPrices,
        promotionModified: true
      };
    }
    case `${actionTypes.DELETE_MONTH_PRICES}`: {
      const promotionPrices = { ...state.promotionPrices };
      promotionPrices.meses.pop();
      return {
        ...state,
        promotionPrices,
        promotionModified: true
      };
    }
    case `${actionTypes.GET_PRICES_PROMOTION}_PENDING`: {
      return {
        ...state,
        promotionPrices: null
      };
    }
    case `${actionTypes.GET_PRICES_PROMOTION}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        promotionPrices: null
      };
    }
    case `${actionTypes.GET_PRICES_PROMOTION}_FULFILLED`: {
      return {
        ...state,
        promotionPrices: action.payload.data
      };
    }
    case `${actionTypes.GET_CLUBS_PROMOTION}_PENDING`: {
      return {
        ...state,
        promotionClubs: null
      };
    }
    case `${actionTypes.GET_CLUBS_PROMOTION}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        promotionClubs: null
      };
    }
    case `${actionTypes.GET_CLUBS_PROMOTION}_FULFILLED`: {
      return {
        ...state,
        promotionClubs: action.payload.data
      };
    }
    case `${actionTypes.GET_AGREEMENTS_PROMOTION}_PENDING`: {
      return {
        ...state,
        promotionAgreements: null
      };
    }
    case `${actionTypes.GET_AGREEMENTS_PROMOTION}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        promotionAgreements: null
      };
    }
    case `${actionTypes.GET_AGREEMENTS_PROMOTION}_FULFILLED`: {
      return {
        ...state,
        promotionAgreements: action.payload.data
      };
    }
    case `${actionTypes.SAVE_BASIC_PROMOTION}_PENDING`: {
      infoNotification(PENDING);
      return {
        ...state,
        basicPromotionSaved: false
      };
    }
    case `${actionTypes.SAVE_BASIC_PROMOTION}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        basicPromotionSaved: false
      };
    }
    case `${actionTypes.SAVE_BASIC_PROMOTION}_FULFILLED`: {
      successNotification(SAVE_BASIC_PROMOTION);
      return {
        ...state,
        basicPromotionSaved: true,
        basicPromotion: action.payload.data
      };
    }
    case `${actionTypes.UPDATE_BASIC_PROMOTION}_PENDING`: {
      infoNotification(PENDING);
      return {
        ...state,
        basicPromotionSaved: false
      };
    }
    case `${actionTypes.UPDATE_BASIC_PROMOTION}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        basicPromotionSaved: false
      };
    }
    case `${actionTypes.UPDATE_BASIC_PROMOTION}_FULFILLED`: {
      successNotification(SAVE_BASIC_PROMOTION);
      return {
        ...state,
        basicPromotionSaved: true,
        basicPromotion: action.payload.data
      };
    }
    case `${actionTypes.GET_BASIC_PROMOTION}_PENDING`: {
      return {
        ...state,
        gettingBasicPromotion: true
      };
    }
    case `${actionTypes.GET_BASIC_PROMOTION}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        gettingBasicPromotion: false
      };
    }
    case `${actionTypes.GET_BASIC_PROMOTION}_FULFILLED`: {
      return {
        ...state,
        gettingBasicPromotion: false,
        basicPromotion: action.payload.data
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
