import React from "react";
import { Container, TabPane, Row, Col } from "reactstrap";
import ReactTable from "react-table";

const COLUMNS = [
  {
    Header: "Descripcion",
    accessor: "descripcionBeneficio",
    width: "300"
  },
  {
    Header: "Tipo",
    accessor: "tipoBeneficio",
    className: "text-center",
    width: "150"
  },
  {
    Header: "Cantidad",
    accessor: "cantidad",
    width: "80"
  },
  {
    Header: "Aplicado",
    className: "text-center",
    accessor: "aplicado",
    Cell: row => {
      const { aplicado } = row.original;
      return <span>{!!aplicado && aplicado == "1" ? "SI" : "NO"}</span>;
    },
    width: "80"
  },
  {
    Header: "Fecha inicio",
    accessor: "fechaInicio",
    width: "150"
  },
  {
    Header: "Fecha fin",
    accessor: "fechaFin",
    width: "150"
  },
  {
    Header: "Comentario",
    accessor: "comentario",
    width: "300"
  },
  {
    Header: "Origen",
    accessor: "origen",
    width: "200"
  }
];

interface Props {
  crm1Benefits: any[];
  idMembresiaSocio: string;
  invalidateStateValue: (fieldName: string, value: any) => void;
  getCrm1Benefits: () => void;
}

class TabBenefitsCrm1 extends React.Component<Props> {
  render() {
    return (
      <TabPane className="my-2" tabId="17">
        <Container className="p-0">
          <Row>
            <Col>
              <ReactTable
                data={this.props.crm1Benefits || []}
                columns={COLUMNS}
                pageSize={20}
                showPagination={true}
                className="mt-3"
              />
            </Col>
          </Row>
        </Container>
      </TabPane>
    );
  }
}

export default TabBenefitsCrm1;
