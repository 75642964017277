import React, { Fragment } from "react";
import BaseInput from "../../../../toolkit/baseInput";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Button, Row, Col, Form } from "reactstrap";
import s from "./styles.scss";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import { withRouter } from "react-router-dom";

interface Props {
  fields: any;
  onChangeFactory: any;
  searchQuizzes: any;
  opcionesCategoria: object;
  opcionesEstatus: object;
}

interface State {}

class FormSearchQuizzes extends React.Component<Props, State> {
  state = {};

  render() {
    return (
      <Fragment>
        <Form
          onSubmit={e => {
            e.preventDefault();
            this.props.searchQuizzes(e);
          }}
        >
          <Row>
            <Col>
              <BaseInput
                label={"Nombre"}
                name="nombre"
                type="text"
                id="nombre"
                value={this.props.fields.nombre}
                onChange={this.props.onChangeFactory("nombre")}
              />
            </Col>
            <Col>
              <BaseInput
                label={"Identificador"}
                name="identificador"
                type="text"
                id="identificador"
                value={this.props.fields.identificador}
                onChange={this.props.onChangeFactory("identificador")}
              />
            </Col>
            <Col>
              <BaseInput
                label={"Categoría"}
                name="categoria"
                type="select"
                options={this.props.opcionesCategoria}
                id="categoria"
                value={this.props.fields.categoria}
                onChange={this.props.onChangeFactory("categoria")}
              />
            </Col>
            <Col>
              <BaseInput
                label={"Estatus"}
                name="estatus"
                type="select"
                options={this.props.opcionesEstatus}
                id="estatus"
                value={this.props.fields.estatus}
                onChange={this.props.onChangeFactory("estatus")}
              />
            </Col>
            <Col className="mt-4">
              <Button type="submit" block>
                {"Buscar"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Fragment>
    );
  }
}

export default withRouter(withStyles(s)(FormSearchQuizzes));
