import React from "react";
import BaseInput from "../../../toolkit/baseInput";
import { Container, Row, Col } from "reactstrap";
import moment from "moment";

const monthOptions = Array.apply(0, Array(12)).map((_, index) => ({
  value: index + 1,
  label: moment()
    .month(index)
    .format("MMMM")
}));

export const MonthSelector = ({
  month,
  year,
  onChange,
  disabled = false,
  size = "sm"
}) => (
  <Container className="p-0">
    <Row>
      <Col>
        <BaseInput
          label="Mes"
          name="monthSelector"
          type="select"
          id="monthPagoSelector"
          placeholder="Mes"
          value={month || moment().month() + 1}
          size={size}
          disabled={disabled}
          onChange={e => {
            const value = e.target.value;
            return onChange({ month: value, year });
          }}
          options={monthOptions}
        />
      </Col>
      <Col>
        <BaseInput
          label="Año"
          name="anioMetas"
          type="select"
          id="anioMetas"
          placeholder="Año"
          value={year}
          onChange={e => {
            const value = e.target.value;
            return onChange({ month, year: value });
          }}
          options={Array.apply(0, Array(20)).map((_, index) => {
            const year = moment()
              .subtract(10 - index, "years")
              .year();
            return { value: year, label: year };
          })}
        />
      </Col>
    </Row>
  </Container>
);
