import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import AdminDemandsClassifications from "../../components/adminDemandsClassifications";

interface Props {}

class AdminDemandsClassificationsPage extends React.Component<Props> {
  render() {
    return (
      <main>
        <Helmet title="Sports Products" />
        <Layout>
          <AdminDemandsClassifications />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDemandsClassificationsPage);
