import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import PagedTable from "../common/PagedTable";
import { QueryResponse } from "../../../types/QueryResponse";
import { UncontrolledTooltip, Container, Form, Row, Button } from "reactstrap";
import { handleRequestError } from "../../../utils/helpers";
import DatePicker from "../common/DatePicker";
import moment from "moment";
import { RouterProps } from "../../../toolkit/decorators/Artifact";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

interface Binnacle {
  eventoFecha: string;
  actividad: string;
}

interface Props extends RouterProps {
  getBinnacleByPartner: (params?: object) => any;
}

interface State {
  searching: boolean;
  search: any;
  binnacles: QueryResponse<Binnacle>;
}

class Binnacles extends React.Component<Props, State> {
  state = {
    binnacles: [] as any,
    searching: false,
    search: {
      usuario: null,
      fechaDesde: null,
      fechaHasta: null,
      page: 0,
      size: 100
    }
  };

  async componentDidUpdate() {}

  onFetchData = async ({ pageSize: size, page }) => {
    await this.setState(state => ({
      ...state,
      search: { ...this.state.search, size, page }
    }));
    await this.search();
  };

  onChangeFilter = (value, name) => {
    const search = {
      ...this.state.search,
      [`${name}`]: value ? value : null
    };
    this.setState(state => ({ ...state, search }));
  };

  search = async () => {
    this.setState(state => ({ ...state, searching: true }));
    let search = this.state.search;

    this.props
      .getBinnacleByPartner(search)
      .then(({ value: { data } }) => {
        data
          ? data.content.map(
              d =>
                (d.eventoFecha = moment(
                  new Date(d.eventoFecha).setHours(
                    new Date(d.eventoFecha).getHours() + 5
                  )
                ).format("YYYY-MM-DD"))
            )
          : null;
        this.setState(state => ({
          ...state,
          binnacles: data,
          searching: false
        }));
      })
      .catch((err, f?: Function) => {
        handleRequestError(err, f);
        this.setState(state => ({ ...state, searching: false }));
      });
  };

  render() {
    const columns = [
      { name: "Fecha", id: "eventoFecha", minWidth: 30 },
      { name: "Usuario", id: "eventoUsername", minWidth: 30 },
      { name: "Modulo", id: "modulo", minWidth: 30 },
      { name: "Descripción", id: "actividad" }
    ];

    return (
      <Container className="p-0 mt-3">
        <Form
          onSubmit={async e => {
            e.preventDefault();
            await this.search();
          }}
        >
          <Row>
            <div className="col-3 mb-3">
              <div
                style={{
                  display: "inline-block",
                  width: "calc(100% - 24px)"
                }}
              >
                <DatePicker
                  id="fechaDesde"
                  name="fechaDesde"
                  label="Fecha Desde"
                  selected={this.state.search.fechaDesde}
                  onChange={e =>
                    this.onChangeFilter(
                      moment(e).format("YYYY-MM-DD"),
                      "fechaDesde"
                    )
                  }
                />
              </div>
              <UncontrolledTooltip placement="top" target="fechaDesdeBorrar">
                Borrar fecha
              </UncontrolledTooltip>
              <div
                id="fechaDesdeBorrar"
                onClick={e => {
                  e.preventDefault();
                  this.onChangeFilter(null, "fechaDesde");
                }}
                style={{
                  cursor: "pointer",
                  textAlign: "right",
                  display: "inline-block",
                  width: "24px"
                }}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </div>
            </div>
            <div className="col-3 mb-3">
              <div
                style={{
                  display: "inline-block",
                  width: "calc(100% - 24px)"
                }}
              >
                <DatePicker
                  id="fechaHasta"
                  name="fechaHasta"
                  label="Fecha Hasta"
                  selected={this.state.search.fechaHasta}
                  onChange={e =>
                    this.onChangeFilter(
                      moment(e).format("YYYY-MM-DD"),
                      "fechaHasta"
                    )
                  }
                />
              </div>
              <UncontrolledTooltip placement="top" target="fechaHastaBorrar">
                Borrar fecha
              </UncontrolledTooltip>
              <div
                id="fechaHastaBorrar"
                onClick={e => {
                  e.preventDefault();
                  this.onChangeFilter(null, "fechaHasta");
                }}
                style={{
                  cursor: "pointer",
                  textAlign: "right",
                  display: "inline-block",
                  width: "24px"
                }}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </div>
            </div>
            <div className="col-2 pt-3">
              <Button
                block
                className={`${s.searchButton} ${s.primaryButton}`}
                type="submit"
              >
                Buscar
              </Button>
            </div>
          </Row>
        </Form>
        <PagedTable
          data={this.state.binnacles || []}
          columns={columns}
          fetchData={this.onFetchData}
          searching={this.state.searching}
          defaultPageSize={100}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(Binnacles));
