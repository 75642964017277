import React from "react";
import ModalFrame from "../../../../toolkit/modalFrame";
import ReactTable from "react-table";
import { Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";
import moment from "moment";
import s from "../styles.scss";
import constants from "../../../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileContract, faIdCard } from "@fortawesome/free-solid-svg-icons";

interface Props {
  isDocumentOpen: boolean;
  toggleDocument: () => void;
  dayPassInfo: any;
}

interface State {}

export class PassDocumentsModal extends React.Component<Props, State> {
  state = {};

  render() {
    if (!this.props.isDocumentOpen || !this.props.dayPassInfo) {
      return null;
    }

    const nombreCompleto =
      this.props.dayPassInfo.nombreCompleto || "Pases utilizados";

    const columns = [
      {
        Header: "Identificador",
        accessor: "id"
      },
      {
        Header: "Aplicación",
        accessor: "fechaAplicacion",
        Cell: row => (
          <p className="m-0 p-0">{moment(row.value).format("DD-MM-YYYY")}</p>
        )
      },
      {
        Header: "Lib. de Resp.",
        accessor: "docLiberacion",
        Cell: row => {
          const idDom = "doc-lib-" + row.value;

          if (!row.value) {
            return "-";
          }

          return (
            <>
              <UncontrolledTooltip placement="left" target={idDom}>
                Ver documento
              </UncontrolledTooltip>
              <Button
                id={idDom}
                className={`${s.rowIconButton}`}
                onClick={e => {
                  e.preventDefault();
                  window.open(
                    `${constants.BASE_URL}/${constants.DOCUMENTS_BASE_URL}/${row.value}`,
                    "_blank"
                  );
                }}
                style={{ height: "25px", padding: "2px 4px" }}
                size="sm"
              >
                <FontAwesomeIcon icon={faFileContract} />
              </Button>
            </>
          );
        }
      },
      {
        Header: "Identificación",
        accessor: "docIdentificacion",
        Cell: row => {
          const idDom = "doc-id-" + row.value;

          if (!row.value) {
            return "-";
          }

          return (
            <>
              <UncontrolledTooltip placement="left" target={idDom}>
                Ver documento
              </UncontrolledTooltip>
              <Button
                id={idDom}
                className={`${s.rowIconButton}`}
                onClick={e => {
                  e.preventDefault();
                  window.open(
                    `${constants.BASE_URL}/${constants.DOCUMENTS_BASE_URL}/${row.value}`,
                    "_blank"
                  );
                }}
                style={{ height: "25px", padding: "2px 4px" }}
                size="sm"
              >
                <FontAwesomeIcon icon={faIdCard} />
              </Button>
            </>
          );
        }
      }
    ];

    return (
      <ModalFrame
        title={nombreCompleto}
        isOpen={this.props.isDocumentOpen}
        toggle={() => this.props.toggleDocument()}
      >
        <Container style={{ width: "90%" }} className="center-block p-0 mb-3">
          <Row>
            <Col xs="12">
              <ReactTable
                data={this.props.dayPassInfo.pases || []}
                columns={columns}
                showPagination={false}
                sortable={false}
                multiSort={false}
                resizable={false}
                pageSize={(this.props.dayPassInfo.pases || []).length || 3}
              />
            </Col>
          </Row>
        </Container>
      </ModalFrame>
    );
  }
}
