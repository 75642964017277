import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./styles.scss";

interface Props {
  id: string;
  id2: string;
  name: string;
  name2: string;
  label?: string;
  placeholder?: string;
  placeholder2?: string;
  value: string | number | Date;
  value2: string | number | Date;
  dataQAId: string;
  dataElmId: string;
  onChange: (string: string) => void;
  onChange2: (string: string) => void;
  onBlur: (string: string) => void;
  errors: Array<string>;
  disabled: boolean;
  size: string;
  className?: string;
  uppercase: boolean;
  decimals: boolean;
  maxlength: string;
  doubleInput: boolean;
}

class DoubleNumericInput extends React.Component<Props, void> {
  render() {
    const {
      id,
      id2,
      name,
      name2,
      label,
      value,
      value2,
      placeholder,
      placeholder2,
      dataQAId,
      dataElmId,
      errors,
      disabled,
      size,
      maxlength,
      doubleInput
    } = this.props;

    const idOne = "textbox_" + Math.floor(Math.random() * 100000 + 1);
    const idTwo = "textbox_" + Math.floor(Math.random() * 100000 + 1);

    return (
      <FormGroup className={s.formGroup}>
        {label && (
          <Label className={s.label} for={id}>
            {label}:
          </Label>
        )}
        <Input
          type="text"
          id={id || idOne}
          name={name || idOne}
          size={size || "md"}
          maxLength={maxlength || ""}
          placeholder={placeholder}
          className={`${s.input} px-2 py-1 ${
            !!this.props.uppercase ? "text-uppercase" : ""
          }`}
          value={value || ""}
          disabled={disabled || false}
          data-qa-id={dataQAId}
          data-elm-id={dataElmId}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          onKeyPress={event => {
            if (
              !(event.charCode >= 48 && event.charCode <= 57) ||
              (event.charCode == 8 ||
                event.charCode == 9 ||
                event.charCode == 45 ||
                event.charCode == 46)
            ) {
              event.preventDefault();
            }
          }}
        />
        {!!doubleInput && (
          <React.Fragment>
            <br />
            <Input
              type="text"
              id={id2 || idTwo}
              name={name2 || idTwo}
              size={size || "md"}
              maxLength={maxlength || ""}
              placeholder={placeholder2}
              className={`${s.input} px-2 py-1 ${
                !!this.props.uppercase ? "text-uppercase" : ""
              }`}
              value={value2 || ""}
              disabled={disabled || false}
              data-qa-id={dataQAId}
              data-elm-id={dataElmId}
              onChange={this.props.onChange2}
              onBlur={this.props.onBlur}
              onKeyPress={event => {
                if (
                  !(
                    event.charCode == 46 ||
                    (event.charCode >= 48 && event.charCode <= 57)
                  ) ||
                  (event.charCode == 8 ||
                    event.charCode == 9 ||
                    event.charCode == 45)
                ) {
                  event.preventDefault();
                }
              }}
            />
          </React.Fragment>
        )}
        {errors &&
          errors.map((message, index) => {
            return (
              <span key={index} className={s.errorSpan}>
                {message}
                <br />
              </span>
            );
          })}
      </FormGroup>
    );
  }
}

export default withStyles(s)(DoubleNumericInput);
