import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Annuities from "../../components/annuities";

interface Props {}

class AnnuitiesPage extends React.Component<Props> {
  state = {};

  render() {
    return (
      <main>
        <Helmet title="Annuities" />
        <Layout>
          <Annuities />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnuitiesPage);
