import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";
import { QueryArguments } from "../../types-business/QueryArguments";

const DOCUMENTS_BASE_URL = "/api/documents";

export const showDocument = (idDocumento: number) => {
  return {
    type: actionTypes.OPEN_TAB_DOCUMENT_INSTANCE,
    payload: window.open(
      `${constants.BASE_URL}/${constants.DOCUMENTS_BASE_URL}/${idDocumento}`,
      "_blank"
    )
  };
};

export const getDocumentsGuest = (): IAction => {
  return {
    type: actionTypes.GET_DOCUMENTS_GUEST,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${DOCUMENTS_BASE_URL}/guests`, {
        withCredentials: true
      })
    }
  };
};

export const getDocumentsSpecialGuest = (): IAction => {
  return {
    type: actionTypes.GET_DOCUMENTS_SPECIAL_GUEST,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${DOCUMENTS_BASE_URL}/special-guests`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getDocumentsLocker = (): IAction => {
  return {
    type: actionTypes.GET_DOCUMENTS_LOCKER,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${DOCUMENTS_BASE_URL}/locker`, {
        withCredentials: true
      })
    }
  };
};

export const getDocuments = (args?: QueryArguments): IAction => {
  return {
    type: actionTypes.GET_DOCUMENTS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${DOCUMENTS_BASE_URL}`, {
        withCredentials: true,
        params: args
      })
    }
  };
};

export const saveBasicDocument = (doc: object): IAction => {
  return {
    type: actionTypes.SAVE_BASIC_DOCUMENT,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${DOCUMENTS_BASE_URL}/basicData`,
        doc,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const updateBasicDocument = (id: string, doc: object): IAction => {
  return {
    type: actionTypes.UPDATE_BASIC_DOCUMENT,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${DOCUMENTS_BASE_URL}/basicData/${id}`,
        doc,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getBasicData = (id: string): IAction => {
  return {
    type: actionTypes.GET_BASIC_DOCUMENT,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${DOCUMENTS_BASE_URL}/basicData/${id}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getMembershipsDocument = (id: string): IAction => {
  return {
    type: actionTypes.GET_MEMBERSHIPS_DOCUMENT,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${DOCUMENTS_BASE_URL}/${id}/memberships`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const addMembershipsToDocument = (
  id: string,
  memberships: object[]
): IAction => {
  return {
    type: actionTypes.ADD_MEMBERSHIPS_DOCUMENT,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${DOCUMENTS_BASE_URL}/${id}/memberships`,
        memberships,
        {
          withCredentials: true
        }
      )
    }
  };
};
