import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import Reform from "@franleplant/reform";
import DatePicker from "../common/DatePicker";
import moment from "moment";
import ModalFrame from "../../../toolkit/modalFrame";
import s from "./styles.scss";
import { Button, Container, Form, Alert } from "reactstrap";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  save: (inicioVigencia: string) => void;
  savingPrices: boolean;
  savingPricesError: boolean;
}

interface State {
  fields: {
    inicioVigencia: string;
  };
  errors: object;
}

const getInicioVigencia = (date?) =>
  moment
    .utc(date)
    .startOf("day")
    .format("YYYY-MM-DD");

class StartValidityModal extends React.Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);

  state = {
    fields: {
      inicioVigencia: getInicioVigencia()
    },
    errors: {}
  };

  dateValidator = dateInput => {
    const date = moment(dateInput).startOf("day");
    const lastDayValid = moment()
      .subtract(1, "day")
      .endOf("day");
    return date.isSameOrBefore(lastDayValid);
  };

  validationRules = {
    inicioVigencia: {
      required: true,
      dateMustBeFuture: value => this.dateValidator(value)
    }
  };

  validationMessages = {
    required: _ => `Obligatorio`,
    dateMustBeFuture: _ => "La fecha debe ser un momento en el futuro",
    default: _ => `Invalido`
  };

  onChangeFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };

        return { ...state, error: {}, fields };
      });
      this.re.validateField(fieldName, value);
    };
  };

  onChangeFecha = fieldName => {
    return event => {
      const date = getInicioVigencia(event);
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: date
        };
        return { ...state, error: {}, fields };
      });
      this.re.validateField(fieldName, date);
    };
  };

  onSubmit = e => {
    e.preventDefault();
    if (this.re.validateFormFromState()) {
      this.props.save(this.state.fields.inicioVigencia);
      this.setState(state => {
        const fields = {
          inicioVigencia: getInicioVigencia()
        };
        return {
          ...state,
          fields
        };
      });
    }
  };

  render() {
    return (
      <ModalFrame
        title={"Ingresar Fecha Vigencia"}
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <Container>
          <Form>
            <DatePicker
              label="inicioVigencia"
              name="inicioVigencia"
              id="inicioVigencia"
              selected={this.state.fields.inicioVigencia}
              onChange={this.onChangeFecha("inicioVigencia")}
              errors={this.re.mapFieldErrors("inicioVigencia")}
            />
            <Button
              className={`${s.buttonMarginTop} ${s.primaryButton}`}
              onClick={e => this.onSubmit(e)}
              size="lg"
              disabled={this.props.savingPrices}
              block
            >
              {this.props.savingPrices ? "Guardando" : "Guardar"}
            </Button>
            {this.props.savingPricesError && (
              <span>
                <hr />
                <Alert color="danger">Ocurrió un error inesperado.</Alert>
              </span>
            )}
          </Form>
        </Container>
      </ModalFrame>
    );
  }
}

export default withRouter(withStyles(s)(StartValidityModal));
