import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import {
  Container,
  Col,
  Button,
  Row,
  UncontrolledTooltip,
  Form
} from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import s from "./styles.scss";
import ReactTable from "react-table";
import {
  faCubes,
  faFileArchive,
  faFileCsv,
  faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddUserCorporative from "./addUserCorporative";
import { getCorporative } from "../../actions/persons";
import { handleRequestError } from "../../../utils/helpers";

interface Props {
  history: any;
}

interface State {
  persons: any;
  toOpen: boolean;
  toOpenClubs: boolean;
  idUsuario: string;
}

class CorporativePersons extends React.Component<Props, State> {
  state = {
    filters: {
      nombre: ""
    },
    persons: { totalPages: 0, content: [] },
    toOpen: false,
    toOpenClubs: false,
    idUsuario: ""
  };

  columns = [
    {
      Header: () => <p className="font-weight-bold">Id</p>,
      id: 0,
      width: 100,
      accessor: "id",
      className: "text-center"
    },
    {
      Header: () => <p className="font-weight-bold">Nombre</p>,
      id: 0,
      accessor: "nombre",
      Cell: row => {
        return (
          <div>
            <a
              href={`/corporativos/${row.original.id}/detalle`}
              target="_blank"
            >
              {row.original.nombre}
            </a>
          </div>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">RFC</p>,
      id: 0,
      accessor: "rfc",
      className: "text-center"
    },
    {
      Header: () => <p className="font-weight-bold">Cuenta producto</p>,
      id: 0,
      accessor: "cuentaProducto",
      className: "text-center"
    },
    {
      Header: () => (
        <div className="w-100 text-center">
          <Button
            color="link"
            onClick={this.addUser}
            className={`${s.innerButton} ${s.primaryLink}`}
          >
            <FontAwesomeIcon icon={faUserPlus} />
          </Button>
          <Button
            color="link"
            id="btnVentaPublico"
            onClick={() => {
              this.props.history.push("/facturacion-adm");
            }}
            className={`${s.innerButton} ${s.primaryLink} ml-2`}
          >
            <FontAwesomeIcon icon={faFileCsv} />
            <UncontrolledTooltip placement="left" target={`btnVentaPublico`}>
              Facturar venta al público
            </UncontrolledTooltip>
          </Button>
        </div>
      ),
      id: -300,
      width: 80,
      accessor: "actions",
      className: "text-center",
      Cell: row => {
        return (
          <a
            id={`corporative${row.original.id}`}
            href={`/facturacion-adm/${row.original.id}`}
          >
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              icon={faFileArchive}
            />
            <UncontrolledTooltip
              placement="left"
              target={`corporative${row.original.id}`}
            >
              Facturar
            </UncontrolledTooltip>
          </a>
        );
      }
    }
  ];

  onChangeFilters = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  getCorporativeClients = (nombre, page, size) => {
    getCorporative(nombre, page, size)
      .then(({ data }) => {
        this.setState({ persons: data });
      })
      .catch(handleRequestError);
  };

  onFetchData = ({ pageSize: size, page }) => {
    const { nombre } = this.state.filters;
    this.getCorporativeClients(nombre, page, size);
  };

  addUser = () => {
    this.setState({
      toOpen: true
    });
  };

  render() {
    const { persons } = this.state;
    return (
      <Container className="p-0">
        <AddUserCorporative
          isNowOpen={this.state.toOpen}
          ok={() => {
            this.setState({ toOpen: false });
            const { nombre } = this.state.filters;
            this.getCorporativeClients(nombre, 0, 20);
          }}
          cancel={() => {
            this.setState({ toOpen: false });
          }}
        />
        <Form
          onSubmit={e => {
            e.preventDefault();
            this.onFetchData(e);
          }}
        >
          <Row className="mt-4">
            <Col xs={12} md={10} lg={10}>
              <BaseInput
                label={"Nombre"}
                name="nombre"
                type="text"
                id="nombre"
                value={this.state.filters.nombre}
                onChange={this.onChangeFilters("nombre")}
              />
            </Col>
            <Col xs={12} md={2} lg={2} className="mt-4">
              <Button
                size="sm"
                className={`float-right btn-block`}
                onClick={this.onFetchData}
              >
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
        <Row className={s.customRow}>
          <Col>
            <ReactTable
              manual
              data={persons ? persons.content : []}
              pages={persons ? persons.totalPages : 0}
              columns={this.columns}
              className="w-100"
              onFetchData={this.onFetchData}
              defaultPageSize={20}
              sortable={false}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(CorporativePersons));
