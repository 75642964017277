import ITextBuilder from "../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";
import FormBuilderConfig from "../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IButtonBuilder from "../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";

type Inputs =
  | "tdc1Afiliacion"
  | "tdc1Buzon"
  | "tdc1Sucursal"
  | "tdc2Afiliacion"
  | "tdc2Buzon"
  | "tdc2Sucursal"
  | "amexAfiliacion"
  | "amexBuzon"
  | "amexSucursal";
type Buttons = "aceptar" | "cancelar";

export const afiliacionesFormConfig: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "tdc1Afiliacion",
      label: "Afiliación TDC 1",
      bts: {
        xCol: 4
      },
      component: ITextBuilder
    },
    {
      name: "tdc1Buzon",
      label: "Buzón TDC 1",
      bts: {
        xCol: 4
      },
      component: ITextBuilder
    },
    {
      name: "tdc1Sucursal",
      label: "Sucursal TDC 1",
      bts: {
        xCol: 4
      },
      component: ITextBuilder
    },
    {
      name: "tdc2Afiliacion",
      label: "Afiliación TDC 2",
      bts: {
        xCol: 4
      },
      component: ITextBuilder
    },
    {
      name: "tdc2Buzon",
      label: "Buzón TDC 2",
      bts: {
        xCol: 4
      },
      component: ITextBuilder
    },
    {
      name: "tdc2Sucursal",
      label: "Sucursal TDC 2",
      bts: {
        xCol: 4
      },
      component: ITextBuilder
    },
    {
      name: "amexAfiliacion",
      label: "Afiliación Amex",
      bts: {
        xCol: 4
      },
      component: ITextBuilder
    },
    {
      name: "amexBuzon",
      label: "Buzón Amex",
      bts: {
        xCol: 4
      },
      component: ITextBuilder
    },
    {
      name: "amexSucursal",
      label: "Sucursal Amex",
      bts: {
        xCol: 4
      },
      component: ITextBuilder
    }
  ],
  buttons: [
    {
      name: "aceptar",
      label: "Aceptar",
      isSubmit: true,
      bts: {
        mCol: 3,
        mOff: 6
      },
      component: IButtonBuilder
    },
    {
      name: "cancelar",
      label: "Cancelar",
      isSubmit: false,
      bts: {
        mCol: 3
      },
      component: IButtonBuilder
    }
  ],
  validations: {
    tdc1Afiliacion: {
      requiredWithNoSpace: {
        validator: (value: string) => !value || !value.replace(/\s+/g, ""),
        message: () => "Afiliación obligatoria"
      },
      notSpace: {
        validator: (value: string) => /\s+/g.test(value),
        message: () => "No se admiten espacios en blanco"
      }
    },
    tdc1Buzon: {
      requiredWithNoSpace: {
        validator: (value: string) => !value || !value.replace(/\s+/g, ""),
        message: () => "Buzón obligatorio"
      },
      notSpace: {
        validator: (value: string) => /\s+/g.test(value),
        message: () => "No se admiten espacios en blanco"
      }
    },
    tdc1Sucursal: {
      requiredWithNoSpace: {
        validator: (value: string) => !value || !value.replace(/\s+/g, ""),
        message: () => "Sucursal obligatoria"
      },
      notSpace: {
        validator: (value: string) => /\s+/g.test(value),
        message: () => "No se admiten espacios en blanco"
      }
    },
    tdc2Afiliacion: {
      notSpace: {
        validator: (value: string) => value && /\s+/g.test(value),
        message: () => "No se admiten espacios en blanco"
      }
    },
    tdc2Buzon: {
      notSpace: {
        validator: (value: string) => value && /\s+/g.test(value),
        message: () => "No se admiten espacios en blanco"
      }
    },
    tdc2Sucursal: {
      notSpace: {
        validator: (value: string) => value && /\s+/g.test(value),
        message: () => "No se admiten espacios en blanco"
      }
    },
    amexAfiliacion: {
      requiredWithNoSpace: {
        validator: (value: string) => !value || !value.replace(/\s+/g, ""),
        message: () => "Afiliación obligatoria"
      },
      notSpace: {
        validator: (value: string) => /\s+/g.test(value),
        message: () => "No se admiten espacios en blanco"
      }
    },
    amexBuzon: {
      requiredWithNoSpace: {
        validator: (value: string) => !value || !value.replace(/\s+/g, ""),
        message: () => "Buzón obligatorio"
      },
      notSpace: {
        validator: (value: string) => /\s+/g.test(value),
        message: () => "No se admiten espacios en blanco"
      }
    },
    amexSucursal: {
      requiredWithNoSpace: {
        validator: (value: string) => !value || !value.replace(/\s+/g, ""),
        message: () => "Sucursal obligatoria"
      },
      notSpace: {
        validator: (value: string) => /\s+/g.test(value),
        message: () => "No se admiten espacios en blanco"
      }
    }
  },
  rows: [
    [
      "tdc1Afiliacion",
      "tdc1Buzon",
      "tdc1Sucursal",
      "tdc2Afiliacion",
      "tdc2Buzon",
      "tdc2Sucursal",
      "amexAfiliacion",
      "amexBuzon",
      "amexSucursal",
      "aceptar",
      "cancelar"
    ]
  ]
};
