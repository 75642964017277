import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import ProductsActivation from "../../components/productsActivation";
import * as productsActions from "../../actions/products";
import * as catalogsActions from "../../actions/catalogs";

interface Props {
  getProducts: (args: object) => any;
  getSatProducts: () => any;
  getSatUnits: () => any;
  getSellers: () => any;
  activate: (products: object[]) => any;
  products: object[];
  satProducts: object[];
  satUnits: object[];
  sellers: object[];
  reload: boolean;
  loading: boolean;
}
class ProductsActivationPage extends React.Component<Props> {
  componentDidMount() {
    this.props.getSatProducts();
    this.props.getSatUnits();
    this.props.getSellers();
  }
  render() {
    return (
      <main>
        <Helmet title="Products Activation" />
        <Layout>
          <ProductsActivation
            getProducts={this.props.getProducts}
            activate={this.props.activate}
            products={this.props.products}
            satProducts={this.props.satProducts}
            satUnits={this.props.satUnits}
            sellers={this.props.sellers}
            reload={this.props.reload}
            loading={this.props.loading}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getProducts: (args: object) => {
    dispatch(productsActions.getProductsAllTypes(args));
  },
  getSatProducts: () => {
    dispatch(catalogsActions.getSatProducts());
  },
  getSatUnits: () => {
    dispatch(catalogsActions.getSatUnits());
  },
  getSellers: () => {
    dispatch(catalogsActions.getSellers());
  },
  activate: (products: object[]) => {
    dispatch(productsActions.activate(products));
  }
});

const mapStateToProps = state => {
  const { products, catalogs } = state;
  return {
    products: products.productsAllTypes,
    loading: products.gettingProductsAllTypes,
    reload: products.reloadProductsAllTypes,
    satProducts: catalogs.satProducts,
    satUnits: catalogs.satUnits,
    sellers: catalogs.sellers
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsActivationPage);
