import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import { getErrorMessage } from "../../utils/helpers";
import { readMessageBack } from "../../utils/readMessageBack";
import {
  infoNotification,
  CREATE_CLUB_PENDING,
  errorNotification,
  CREATE_CLUB_ERROR,
  successNotification,
  CREATE_CLUB,
  ERROR
} from "../../utils/notifications";

export interface GetClubs {
  reloadClubs: boolean;
  clubs: any[];
  gettingClubs: boolean;
  gettingClubsError: string;
}

export interface GetAllowedClubs {
  reloadClubs: boolean;
  allowedClubs: any[];
  gettingAllowedClubs: boolean;
  gettingAllowedClubsError: string;
}

export interface GetAllClubsBasicData {
  reloadClubs: boolean;
  allClubsBasicData: any[];
  gettingAllClubsBasicData: boolean;
  gettingAllClubsBasicDataError: string;
}

interface State extends GetAllowedClubs, GetClubs, GetAllClubsBasicData {
  gettingClubSellers: boolean;
  gettingClubSellersError: object;
  clubSellers: object[];
  gettingClubGeneralManagers: boolean;
  gettingClubGeneralManagersError: object;
  clubGeneralManagers: object[];
  createClubError: object;
  creatingClub: boolean;
  clubCreated: boolean;
  allClubs: object[];
  reloadClubs: boolean;
}

const initialState: State = {
  gettingClubsError: null,
  clubs: null,
  allClubsBasicData: [],
  gettingAllClubsBasicData: false,
  gettingAllClubsBasicDataError: null,
  allClubs: [],
  gettingClubs: false,
  gettingAllowedClubsError: null,
  allowedClubs: [],
  gettingAllowedClubs: false,
  gettingClubSellersError: {},
  gettingClubSellers: false,
  clubSellers: [],
  gettingClubGeneralManagersError: {},
  gettingClubGeneralManagers: false,
  clubGeneralManagers: [],
  createClubError: null,
  creatingClub: false,
  clubCreated: false,
  reloadClubs: false
};

export default function clubsReducer(
  state: State = initialState,
  action: IAction
): State {
  const snapshot = snapshotAction(state, action);

  // Llamada a resolver de snapshot
  state != snapshot &&
    !action.type.endsWith("_PENDING") &&
    action.meta &&
    action.meta.resolver &&
    action.meta.resolver({
      fulfilled: action.type.endsWith("_FULFILLED"),
      ...snapshot
    });

  return state != snapshot
    ? {
        ...state,
        ...snapshot
      }
    : state;
}

function snapshotAction(
  state: State = initialState,
  action: IAction
): Partial<State> {
  switch (action.type) {
    case `${actionTypes.GET_CLUBS}_PENDING`: {
      return {
        reloadClubs: false,
        gettingClubsError: null,
        gettingClubs: true
      };
    }
    case `${actionTypes.GET_CLUBS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingClubsError: getErrorMessage(action.payload),
        gettingClubs: false
      };
    }
    case `${actionTypes.GET_CLUBS}_FULFILLED`: {
      return {
        gettingClubsError: null,
        clubs: action.payload.data,
        gettingClubs: false
      };
    }
    case `${actionTypes.GET_ALL_CLUBS}_PENDING`: {
      return {
        reloadClubs: false,
        allClubs: []
      };
    }
    case `${actionTypes.GET_ALL_CLUBS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        allClubs: []
      };
    }
    case `${actionTypes.GET_ALL_CLUBS}_FULFILLED`: {
      return {
        allClubs: action.payload.data
      };
    }
    case `${actionTypes.GET_ALL_CLUBS_BASIC_DATA}_PENDING`: {
      return {
        reloadClubs: false,
        allClubsBasicData: [],
        gettingAllClubsBasicData: true,
        gettingAllClubsBasicDataError: null
      };
    }
    case `${actionTypes.GET_ALL_CLUBS_BASIC_DATA}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        allClubsBasicData: [],
        gettingAllClubsBasicData: false,
        gettingAllClubsBasicDataError: readMessageBack(action, ERROR)
      };
    }
    case `${actionTypes.GET_ALL_CLUBS_BASIC_DATA}_FULFILLED`: {
      return {
        allClubsBasicData: action.payload.data,
        gettingAllClubsBasicData: false,
        gettingAllClubsBasicDataError: null
      };
    }
    case `${actionTypes.GET_ALLOWED_CLUBS}_PENDING`: {
      return {
        reloadClubs: false,
        gettingAllowedClubsError: null,
        gettingAllowedClubs: true
      };
    }
    case `${actionTypes.GET_ALLOWED_CLUBS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingAllowedClubsError: readMessageBack(action, ERROR),
        gettingAllowedClubs: false
      };
    }
    case `${actionTypes.GET_ALLOWED_CLUBS}_FULFILLED`: {
      return {
        gettingAllowedClubsError: null,
        allowedClubs: action.payload.data,
        gettingAllowedClubs: false
      };
    }
    case `${actionTypes.GET_CLUB_SELLERS}_PENDING`: {
      return {
        reloadClubs: false,
        gettingClubSellersError: null,
        gettingClubSellers: true
      };
    }
    case `${actionTypes.GET_CLUB_SELLERS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingClubSellersError: getErrorMessage(action.payload),
        gettingClubSellers: false
      };
    }
    case `${actionTypes.GET_CLUB_SELLERS}_FULFILLED`: {
      return {
        gettingClubSellersError: null,
        clubSellers: action.payload.data,
        gettingClubSellers: false
      };
    }
    case `${actionTypes.GET_CLUB_GENERAL_MANAGERS}_PENDING`: {
      return {
        gettingClubGeneralManagersError: null,
        gettingClubGeneralManagers: true,
        clubGeneralManagers: []
      };
    }
    case `${actionTypes.GET_CLUB_GENERAL_MANAGERS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingClubGeneralManagersError: getErrorMessage(action.payload),
        gettingClubGeneralManagers: false,
        clubGeneralManagers: []
      };
    }
    case `${actionTypes.GET_CLUB_GENERAL_MANAGERS}_FULFILLED`: {
      return {
        gettingClubGeneralManagersError: null,
        gettingClubGeneralManagers: false,
        clubGeneralManagers: action.payload.data
      };
    }
    case `${actionTypes.CREATE_CLUB}_PENDING`: {
      infoNotification(CREATE_CLUB_PENDING);
      return {
        createClubError: null,
        creatingClub: true
      };
    }
    case `${actionTypes.CREATE_CLUB}_REJECTED`: {
      errorNotification(readMessageBack(action, CREATE_CLUB_ERROR));
      return {
        createClubError: getErrorMessage(action.payload),
        creatingClub: false
      };
    }
    case `${actionTypes.CREATE_CLUB}_FULFILLED`: {
      successNotification(CREATE_CLUB);
      return {
        reloadClubs: true,
        createClubError: null,
        creatingClub: false,
        clubCreated: true
      };
    }
    case `${actionTypes.CLEAR_ALLOWED_CLUBS}`: {
      return {
        allowedClubs: [],
        clubSellers: []
      };
    }
    case `${actionTypes.DISABLE_CLUB_CREATED}`: {
      return {
        clubCreated: false
      };
    }

    default: {
      return state;
    }
  }
}

export { initialState };
