import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import Reform from "@franleplant/reform";
import { FormGroup, Label, Input, Container, Form } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import s from "./styles.scss";
import { FormErrors } from "@franleplant/reform/types";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import RichEditor from "../richEditor";
import fill from "lodash.fill";
import findIndex from "lodash.findindex";
import DatePicker from "../common/DatePicker";
import moment from "moment";
import {
  TYPE_OPTIONS,
  TYPE_PROMOTION_USE
} from "../../../types-business/Promotions";
import isNil from "lodash.isnil";
import { setChildStep } from "../../helpers/stepper-state-comparator";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import { timeStamp } from "console";
import uppercase from "lodash.uppercase";

interface MEMBERSHIP {
  datosBasicosMembresiaId: number;
  nombre: string;
}

interface MEMBERSHIP_SAVED {
  membresiaId: number;
}

interface Props {
  onClickSave: (e: object) => any;
  history: any;
  client: any;
  saveBasicPromotion: (promotion: object) => any;
  updateBasicPromotion: (promotionId: string, promotion: object) => any;
  match: any;
  promotion: Promotion;
  getBasicPromotion: (id: string) => any;
  memberships: MEMBERSHIP[];
  users: object[];
}

interface Fields {
  nombre: string;
  responsableId: string;
  condicionesLegales: string;
  inicioVigencia: string;
  finVigencia: string;
  maximoSocios: string;
  tipoUsoPromocion: string;
}

interface Promotion {
  condicionesLegales: string;
  nombre: string;
  responsableId: string;
  isfinVigenciaPresente: boolean;
  inicioVigencia: string;
  finVigencia: string;
  description: string;
  membresias: MEMBERSHIP_SAVED[];
  maximoSocios: string;
  tipoAplicabilidad: TYPE_OPTIONS;
  tipoUsoPromocion: TYPE_PROMOTION_USE;
}

interface State {
  errors: FormErrors;
  fields: Fields;
  disableEndDate: boolean;
  disableLimit: boolean;
  biclub: boolean;
  editorState: EditorState;
  membershipsSelected: object;
}

const promotionToFields = ({
  nombre,
  responsableId,
  condicionesLegales,
  inicioVigencia,
  finVigencia,
  maximoSocios,
  membresias,
  tipoUsoPromocion
}) => {
  return {
    nombre,
    tipoUsoPromocion,
    responsableId,
    condicionesLegales,
    inicioVigencia: moment(inicioVigencia).format("YYYY-MM-DD"),
    finVigencia: moment(finVigencia).format("YYYY-MM-DD"),
    maximoSocios,
    membresiasAplicables: {
      required: _ =>
        membresias
          .filter((_, index) => this.state.membershipsSelected[index])
          .map(({ datosBasicosMembresiaId: membresiaId }) => ({
            membresiaId
          })).length === 0
    }
  };
};

class BasicDataPromotionForm extends React.Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);

  state = {
    fields: {
      nombre: "",
      responsableId: "",
      inicioVigencia: "",
      finVigencia: "",
      condicionesLegales: "",
      maximoSocios: "",
      membresiasAplicables: null,
      tipoUsoPromocion: null
    },
    disableEndDate: true,
    disableLimit: true,
    biclub: false,
    editorState: EditorState.createEmpty(),
    errors: {},
    membershipsSelected: null
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      this.props.getBasicPromotion(id);
    }

    this.setState({
      membershipsSelected: fill(Array(this.props.memberships.length), false)
    });

    this.props.onClickSave(e => {
      e.preventDefault();

      if (this.re.validateFormFromState()) {
        const {
          inicioVigencia,
          finVigencia,
          maximoSocios,
          responsableId
        } = this.state.fields;
        const { biclub } = this.state;
        const promotion = {
          ...this.state.fields,
          biclub,
          description: draftToHtml(
            convertToRaw(this.state.editorState.getCurrentContent())
          ),
          responsableId: parseInt(responsableId, 10),
          maximoSocios:
            maximoSocios.length && maximoSocios !== "0"
              ? parseInt(maximoSocios, 10)
              : 0,
          sinLimite: !(maximoSocios.length && maximoSocios !== "0"),
          inicioVigencia: moment(inicioVigencia),
          finVigencia: finVigencia
            ? moment(finVigencia)
            : moment(inicioVigencia).add(100, "years"),
          membresias: this.props.memberships
            .filter((_, index) => this.state.membershipsSelected[index])
            .map(({ datosBasicosMembresiaId: membresiaId }) => ({
              membresiaId
            }))
        };
        delete promotion.membresiasAplicables;
        const { id } = this.props.match.params;
        if (!this.props.promotion) {
          promotion["tipoAplicabilidad"] = TYPE_OPTIONS.NO_AGREEMENTS;
          this.props
            .saveBasicPromotion(promotion)
            .then(({ value: { data } }) => {
              this.props.history.push(`/promociones/datos-basicos/${data.id}`);
            });
        } else {
          this.props.updateBasicPromotion(id, {
            ...this.props.promotion,
            ...promotion
          });
        }
      }
    });
  }

  processed = false;

  optionsResponsible = users => {
    const response = [];
    users.forEach(user => {
      if (user.name) {
        response.push({
          label: `${user.name} ${user.firstSurname} ${user.secondSurname}`,
          value: user.userId
        });
      }
    });

    return response;
  };

  componentDidUpdate(prevProps) {
    const { promotion: propsPromotion } = this.props;
    const { promotion: prevPropsPromotion } = prevProps;

    if (propsPromotion && prevPropsPromotion !== propsPromotion) {
      this.setState(state => ({
        ...state,
        fields: promotionToFields(propsPromotion),
        biclub: !!propsPromotion.biclub,
        editorState: EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(propsPromotion.description).contentBlocks
          )
        )
      }));

      if (propsPromotion.finVigencia && this.state.disableEndDate) {
        this.setState({ disableEndDate: false });
      }

      if (propsPromotion.maximoSocios && this.state.disableLimit) {
        this.setState({ disableLimit: false });
      }
    }

    const { memberships } = this.props;

    if (
      memberships.length &&
      propsPromotion &&
      propsPromotion.membresias &&
      propsPromotion.membresias.length &&
      !this.processed
    ) {
      const { membresias: appliedMemberships } = propsPromotion;
      this.setState({
        membershipsSelected: memberships.map(
          ({ datosBasicosMembresiaId }) =>
            findIndex(
              appliedMemberships,
              m => datosBasicosMembresiaId === m.membresiaId
            ) > -1
        )
      });
      this.processed = true;
    }
  }

  dateValidator = (dateInput, inputName) => {
    const { id } = this.props.match.params;
    if (id && this.props.promotion[`${inputName}`] === dateInput) {
      return false;
    }
    const date = moment(dateInput).startOf("day");
    const now = moment().startOf("day");
    return date.isBefore(now);
  };

  bothDatesValidator = (dateInput, otherInput) => {
    const date = new Date(dateInput);
    const other = new Date(otherInput);
    return dateInput && otherInput && date >= other;
  };

  validationRules = {
    inicioVigencia: {
      required: true,
      dateMustBeFuture: value =>
        isNil(this.props.match.params.id)
          ? this.dateValidator(value, "inicioVigencia")
          : false,
      bothDates: value =>
        this.bothDatesValidator(value, this.state.fields.finVigencia)
    },
    finVigencia: {
      dateMustBeFuture: value =>
        isNil(this.props.match.params.id)
          ? this.dateValidator(value, "finVigencia")
          : false,
      bothDates: value =>
        this.bothDatesValidator(this.state.fields.inicioVigencia, value),
      required: value => !this.state.disableEndDate && !value
    },
    nombre: { required: true },
    tipoUsoPromocion: { required: true },
    condicionesLegales: { required: true },
    membresiasAplicables: {
      required: _ =>
        this.props.memberships
          .filter((_, index) => this.state.membershipsSelected[index])
          .map(({ datosBasicosMembresiaId: membresiaId }) => ({
            membresiaId
          })).length === 0
    }
  };

  validationMessages = {
    required: _ => `Obligatorio`,
    dateMustBeFuture: _ => "La fecha debe ser futura",
    bothDates: _ => "La fecha inicial debe ser menor a la final",
    minLength: ruleValue => `Debe tener al menos ${ruleValue} caracteres`,
    maxLength: ruleValue => `Deber tener maximo ${ruleValue} caracteres`,
    default: _ => `Inválido`
  };

  onChangeFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };

        return { ...state, fields, errors: {} };
      });
      this.re.validateField(fieldName, value);
    };
  };

  onChangeFecha = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: date
        };
        return { ...state, error: {}, fields };
      });
      this.re.validateField(fieldName, date);
    };
  };

  checkDisableDate = e => {
    const disableEndDate = e.target.checked;
    this.setState(() => ({
      disableEndDate,
      fields: { ...this.state.fields, finVigencia: "" }
    }));
  };

  checkDisableLimit = e => {
    const disableLimit = e.target.checked;
    this.setState(() => ({
      disableLimit,
      fields: { ...this.state.fields, maximoSocios: "" }
    }));
  };

  checkBiclub = e => {
    const biclub = e.target.checked;
    this.setState(() => ({
      biclub
    }));
  };

  onChangeEditor = editorState => this.setState({ editorState });

  onSelectMembership = (event, id) => {
    const { checked } = event.target;
    const { membershipsSelected } = this.state;
    membershipsSelected[id] = checked;
    this.setState({ membershipsSelected });
  };

  formatOptions = options =>
    options.map(option => ({
      value: option == "Seleccionar" ? "" : option,
      label: option
    }));

  onChangeSeller = selected => {
    let responsableId = null;
    if (selected.length > 0) {
      responsableId = selected[0]["id"];
    } else {
      responsableId = null;
    }
    this.setState(state => {
      const fields = { ...state.fields, responsableId };

      setChildStep(fields);

      return { ...state, fields, errors: {} };
    });
  };

  findUser = () => {
    let dataReturn = [];
    if (
      this.props.users &&
      this.props.promotion &&
      this.props.promotion.responsableId
    ) {
      let objReturn = this.props.users.find(
        element => element.userId == this.props.promotion.responsableId
      );
      if (!isNil(objReturn) && objReturn.hasOwnProperty("userId")) {
        dataReturn.push({
          id: objReturn.userId,
          label: uppercase(
            `${objReturn.name || ""} ${objReturn.firstSurname ||
              ""} ${objReturn.secondSurname || ""}`
          )
        });
      }
    }
    return dataReturn;
  };

  render() {
    return (
      <Container className="p-0" fluid>
        <div className="row mb-3">
          <div className="col">
            <p className={`${s.primaryTitle} w-100 m-0`}>Datos Básicos</p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <BaseInput
              label={"Nombre"}
              name="nombrePromocion"
              type="text"
              id="nombrePromocion"
              placeholder="Nombre"
              value={this.state.fields.nombre}
              onChange={this.onChangeFactory("nombre")}
              errors={this.re.mapFieldErrors("nombre")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <BaseInput
              type="select"
              id="promotionType"
              name="promotionType"
              label="Tipo"
              value={this.state.fields.tipoUsoPromocion}
              onChange={this.onChangeFactory("tipoUsoPromocion")}
              errors={this.re.mapFieldErrors("tipoUsoPromocion")}
              options={[
                { value: null, label: "Seleccione..." },
                { value: "VENTA", label: "VENTA" },
                { value: "REACTIVACION", label: "REACTIVACION" }
              ]}
            />
          </div>

          {this.props.users && (
            <div className="col">
              <Label className={s.label}>Responsable</Label>
              <Typeahead
                id={"Responsable"}
                defaultSelected={this.findUser()}
                promptText={"Responsable"}
                bsSize={"sm"}
                onChange={selected => this.onChangeSeller(selected)}
                emptyLabel={"Sin coincidencias"}
                disabled={this.props.users ? false : true}
                maxResults={20}
                options={
                  this.props.users
                    ? this.props.users.map(item => {
                        var rObj = {};
                        rObj["id"] = item["userId"];
                        rObj["label"] = uppercase(
                          `${item["name"] || ""} ${item["firstSurname"] ||
                            ""} ${item["secondSurname"] || ""}`
                        );
                        return rObj;
                      })
                    : []
                }
              />
            </div>
          )}
          <div className="col">
            <DatePicker
              label="Inicio Vigencia"
              name="inicioVigencia"
              id="inicioVigencia"
              selected={this.state.fields.inicioVigencia}
              onChange={this.onChangeFecha("inicioVigencia")}
              errors={this.re.mapFieldErrors("inicioVigencia")}
            />
          </div>
          <div className="col">
            <DatePicker
              label="Fin Vigencia"
              name="finVigencia"
              id="finVigencia"
              selected={this.state.fields.finVigencia}
              onChange={this.onChangeFecha("finVigencia")}
              errors={this.re.mapFieldErrors("finVigencia")}
              disabled={this.state.disableEndDate}
            />
          </div>
          <div className="col">
            <BaseInput
              label={"No. Maximo"}
              name="maximoSocios"
              type="number"
              id="maximoSocios"
              placeholder="No. Maximo"
              value={this.state.fields.maximoSocios}
              disabled={this.state.disableLimit}
              onChange={this.onChangeFactory("maximoSocios")}
              errors={this.re.mapFieldErrors("maximoSocios")}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="col">
            <Form className={`${s.checkboxContainer}`}>
              <FormGroup check className="ml-5 form-check-inline float-right">
                <Label check>
                  <Input
                    type="checkbox"
                    checked={this.state.disableLimit}
                    onChange={e => this.checkDisableLimit(e)}
                  />{" "}
                  Sin Máximo de Integrantes
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-inline float-right">
                <Label check>
                  <Input
                    type="checkbox"
                    checked={this.state.disableEndDate}
                    onChange={e => this.checkDisableDate(e)}
                  />{" "}
                  Sin Fin de Vigencia
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-inline float-right mr-5">
                <Label check>
                  <Input
                    type="checkbox"
                    checked={this.state.biclub}
                    onChange={e => this.checkBiclub(e)}
                  />{" "}
                  Biclub
                </Label>
              </FormGroup>
            </Form>
          </div>
        </div>
        <div className="form-row mt-1">
          <FormGroup className="col m-0">
            <Label>Descripción:</Label>
          </FormGroup>
        </div>
        <div style={{ height: "300px" }} className="mt-1">
          <RichEditor
            onChangeEditor={this.onChangeEditor}
            editorState={this.state.editorState}
          />
        </div>
        <div className="form-group mb-1 mt-3">
          <label>Condiciones Legales:</label>
          <textarea
            className="form-control"
            rows={9}
            id="condicionesLegales"
            value={this.state.fields.condicionesLegales}
            onChange={this.onChangeFactory("condicionesLegales")}
          />
        </div>
        {this.re.mapFieldErrors("condicionesLegales") &&
          this.re.mapFieldErrors("condicionesLegales").map((message, index) => {
            return (
              <span key={index} className={s.errorSpan}>
                {message}
                <br />
              </span>
            );
          })}
        <div className="form-group">
          <div className="row mb-3 mt-3">
            <div className="col">
              <p className={`${s.primaryTitle} w-100 m-0`}>
                Membresias Aplicables
              </p>
            </div>
          </div>
          <div className="row m-0 p-0">
            {this.state.membershipsSelected &&
              this.state.membershipsSelected.length &&
              this.props.memberships &&
              this.props.memberships.length &&
              this.props.memberships.map(
                ({ nombre, datosBasicosMembresiaId }, index) => (
                  <div key={datosBasicosMembresiaId} className="col-3 p-0 m-0">
                    <div className="row p-0 m-0">
                      <div className="col-2 m-0 p-0">
                        <input
                          className={s.checkboxInput}
                          type="checkbox"
                          name={nombre}
                          checked={this.state.membershipsSelected[index]}
                          onChange={e => this.onSelectMembership(e, index)}
                          style={{ marginLeft: "0.75rem" }}
                        />
                      </div>

                      <div className="col-10 m-0 p-0">
                        <label className="my-0 custom-label">{nombre}</label>
                      </div>
                    </div>
                  </div>
                )
              )}
            {this.re.mapFieldErrors("membresiasAplicables") &&
              this.re
                .mapFieldErrors("membresiasAplicables")
                .map((message, index) => {
                  return (
                    <span key={index} className={s.errorSpan}>
                      {message}
                      <br />
                    </span>
                  );
                })}
          </div>
        </div>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(BasicDataPromotionForm));
