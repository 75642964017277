import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import BenefitsTable from "../benefitsTable";

import { Container } from "reactstrap";
import {
  PRICE_TYPES,
  AGREEMENT_PRICE
} from "../../../../types-business/Agreements";
import BenefitsHeader from "../benefitsHeader";

// TODO: Esta funcionalidad se heredó de los convenios; faltan exclusiones

interface MEMBERSHIP {
  membresiaId: string;
  nombre: string;
}

interface Club {
  clubId: string;
  nombre: string;
}

interface Props {
  memberships: MEMBERSHIP[];
  clubs: Club[];
  membershipId: string;
  clubId: string;
  onChangeMembership: (ev) => any;
  onChangeClub: (ev) => any;
  prices: AGREEMENT_PRICE;
  checkProportional: (ev) => any;
  checkRelative: (ev) => any;
  onChangeMembershipValue: (ev) => any;
  onChangePriceType: (membershipPriceType: string) => any;
  updateCellPrice: (monthIndex, memberIndex, ev) => any;
  checkLastMonth: (monthIndex, ev) => any;
  onChangePriceTypeMonth: (monthIndex: number, type: PRICE_TYPES) => any;
  deleteMonthPrices: () => any;
  addMonthPrices: () => any;
}

const Benefits = ({
  prices,
  updateCellPrice,
  deleteMonthPrices,
  addMonthPrices,
  onChangeClub,
  clubId,
  clubs,
  onChangeMembership,
  membershipId,
  memberships,
  checkLastMonth,
  checkProportional,
  checkRelative,
  onChangeMembershipValue,
  onChangePriceTypeMonth,
  onChangePriceType
}: Props) => (
  <Container className="w-100 p-0 m-0">
    <BenefitsHeader
      onChangeClub={onChangeClub}
      onChangePriceType={onChangePriceType}
      clubId={clubId}
      clubs={clubs}
      onChangeMembership={onChangeMembership}
      membershipId={membershipId}
      memberships={memberships}
      checkRelative={checkRelative}
      checkProportional={checkProportional}
      benefits={prices}
      onChangeMembershipValue={onChangeMembershipValue}
    />
    <hr />
    <BenefitsTable
      benefits={prices}
      updateCellPrice={updateCellPrice}
      checkLastMonth={checkLastMonth}
      onChangePriceTypeMonth={onChangePriceTypeMonth}
      deleteMonthPrices={deleteMonthPrices}
      addMonthPrices={addMonthPrices}
      editable
    />
  </Container>
);

export default withRouter(withStyles(s)(Benefits));
