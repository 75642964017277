import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import {
  SAVE_INBOX_CALENDAR_BLOCKED_PENDING,
  SAVE_INBOX_CALENDAR_BLOCKED_SUCCESS,
  SAVE_INBOX_CALENDAR_BLOCKED_ERROR,
  ERROR,
  infoNotification,
  errorNotification,
  successNotification
} from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";
interface State {
  gettingDates: boolean;
  gettingCalendarTypes: boolean;
  inboxCalendarDates: object;
  successSend: boolean;
  calendarTypes: object;
}

const initialState: State = {
  gettingDates: false,
  gettingCalendarTypes: false,
  inboxCalendarDates: null,
  calendarTypes: null,
  successSend: false
};

export default function inboxCalendar(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.GET_INBOX_CALENDAR}_PENDING`: {
      return {
        ...state,
        gettingDates: true,
        successSend: false
      };
    }
    case `${actionTypes.GET_INBOX_CALENDAR}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        gettingDates: false,
        successSend: false
      };
    }
    case `${actionTypes.GET_INBOX_CALENDAR}_FULFILLED`: {
      return {
        ...state,
        inboxCalendarDates: action.payload.data,
        gettingDates: false,
        successSend: false
      };
    }

    case `${actionTypes.SAVE_INBOX_CALENDAR_BLOCKED}_PENDING`: {
      infoNotification(SAVE_INBOX_CALENDAR_BLOCKED_PENDING);
      return {
        ...state,
        gettingDates: true,
        successSend: false
      };
    }
    case `${actionTypes.SAVE_INBOX_CALENDAR_BLOCKED}_REJECTED`: {
      errorNotification(
        readMessageBack(action, SAVE_INBOX_CALENDAR_BLOCKED_ERROR)
      );
      return {
        ...state,
        gettingDates: false,
        successSend: true
      };
    }
    case `${actionTypes.SAVE_INBOX_CALENDAR_BLOCKED}_FULFILLED`: {
      successNotification(SAVE_INBOX_CALENDAR_BLOCKED_SUCCESS);
      return {
        ...state,
        inboxCalendarDates: action.payload.data,
        gettingDates: false
      };
    }

    case `${actionTypes.GET_CALENDAR_TYPES}_PENDING`: {
      return {
        ...state,
        gettingCalendarTypes: true
      };
    }
    case `${actionTypes.GET_CALENDAR_TYPES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        gettingCalendarTypes: false
      };
    }
    case `${actionTypes.GET_CALENDAR_TYPES}_FULFILLED`: {
      return {
        ...state,
        calendarTypes: action.payload.data,
        gettingCalendarTypes: true
      };
    }

    default: {
      return state;
    }
  }
}

export { initialState };
