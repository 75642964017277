import React from "react";
import { connect } from "react-redux";
import Layout from "../../components/layout";
import { Helmet } from "react-helmet";
import { getPaymentMovements } from "../../actions/persons";
import PaymentMovementsList from "../../components/paymentMovements";
import { getClubs } from "../../actions/catalogs";
import {
  searchMovementsByClubAndMembership,
  sendMovementEmail
} from "../../actions/partners";
import { historicalRebilling } from "../../actions/reports";
import { showDocument } from "../../actions/documents";
import IAction from "../../../types/IAction";

interface Props {
  paymentMovements: object[];
  paymentMovementsByMembership: object[];
  clubs: object[];
  listHistoricalRebilling: any;
  getPaymentMovements: (personaId: number) => any;
  searchByClubAndMembership: (params: object) => any;
  historicalRebilling: (ventaId: string) => IAction;
  getClubs: () => any;
  sendMovementEmail: (id: number, email: string) => any;
  toggleBill: (storageId: number) => any;
}

interface State {}

class PaymentsMovementsContainer extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    this.props.getClubs();
  }

  render() {
    return (
      <main>
        <Layout>
          <Helmet title="Peticiones y Quejas" />
          <PaymentMovementsList
            paymentMovements={this.props.paymentMovements}
            paymentMovementsByMembership={
              this.props.paymentMovementsByMembership
            }
            loadingMovements={false}
            getPaymentMovements={this.props.getPaymentMovements}
            clubs={this.props.clubs}
            searchByClubAndMembership={this.props.searchByClubAndMembership}
            sendMovementEmail={this.props.sendMovementEmail}
            toggleBill={this.props.toggleBill}
            historicalRebilling={this.props.historicalRebilling}
            listHistoricalRebilling={this.props.listHistoricalRebilling}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = {
  getPaymentMovements,
  getClubs,
  searchByClubAndMembership: searchMovementsByClubAndMembership,
  sendMovementEmail,
  historicalRebilling,
  toggleBill: showDocument
};

const mapStateToProps = state => {
  const {
    catalogs: { clubs },
    persons: { paymentMovements },
    partners: { paymentMovementsByMembership },
    reports: { listHistoricalRebilling }
  } = state;

  return {
    paymentMovements,
    clubs,
    paymentMovementsByMembership,
    listHistoricalRebilling
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentsMovementsContainer);
