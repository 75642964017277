import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import s from "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MexicanCurrency from "../../../common/currency";
import { PaymentMovement } from "../../../paymentMovements";
import { Container, Row, Col, UncontrolledTooltip } from "reactstrap";
import {
  faClone,
  faEnvelope,
  faFileCsv,
  faFilePdf
} from "@fortawesome/free-solid-svg-icons";

export interface TableProps {
  data: PaymentMovement[];
  loading: boolean;
  onShowNotasCredito: (row: PaymentMovement) => void;
  onViewPdf: (row: PaymentMovement) => void;
  onViewXml: (row: PaymentMovement) => void;
  onSendEmail: (row: PaymentMovement) => void;
}

export const MemoTable = React.memo(
  ({
    data,
    loading,
    onShowNotasCredito,
    onViewPdf,
    onViewXml,
    onSendEmail
  }: TableProps) => {
    const [checkAll, setCheckAll] = useState(false);
    const [columns, setColumns] = useState(
      columnsFormatter(onShowNotasCredito, onViewPdf, onViewXml, onSendEmail)
    );

    // Actualización de estilos y checkboxes
    useEffect(() => {
      setColumns(
        columnsFormatter(onShowNotasCredito, onViewPdf, onViewXml, onSendEmail)
      );
    }, []);

    // Limpieza de checkbox para seleccionar todo, en nuevas búsquedas
    useEffect(() => {
      checkAll && setCheckAll(false);
    }, [data]);

    return (
      <>
        <div className="row mt-4">
          <div style={{ width: "100%" }}>
            <ReactTable
              data={data || []}
              loading={loading}
              pageSize={data && data.length > 5 ? data.length : 5}
              columns={columns}
              sortable={false}
              resizable={true}
              showPagination={false}
              className={`w-100 -highlight -striped ${s.hidden}`}
            />
          </div>
        </div>
      </>
    );
  }
);

const columnsFormatter = (
  onShowNotasCredito,
  onViewPdf,
  onViewXml,
  onSendEmail
) => [
  {
    Header: "Tipo Cargo",
    id: "tipoCargoDisplay",
    width: "120",
    className: s.tdClear,
    accessor: simpleAccessor("tipoCargoDisplay")
  },
  {
    Header: "Fecha",
    id: "fechaCargo",
    width: "80",
    className: `${s.tdClear} text-center`,
    accessor: dateAccessor("fechaCargo")
  },
  {
    // TODO: calcDescriptionWidth
    Header: "Descripcion",
    id: "descripcion",
    width: "255",
    className: s.tdClear,
    accessor: noWrapAccessor("descripcion")
  },
  {
    Header: "Estatus",
    id: "estatus",
    width: "160",
    className: `${s.tdClear} text-center`,
    accessor: simpleAccessor("estatus")
  },
  {
    Header: "Importe",
    id: "importe",
    width: "90",
    className: `${s.tdClear} text-right`,
    accessor: moneyAccessor("importe")
  },
  {
    Header: "Descuento",
    id: "descuento",
    width: "90",
    className: `${s.tdClear} text-right`,
    accessor: moneyAccessor("descuento")
  },
  {
    Header: "Total",
    id: "total",
    width: "90",
    className: `${s.tdClear} text-right`,
    accessor: moneyAccessor("total")
  },
  {
    Header: "Folio factura",
    id: "folioFactura",
    width: "80",
    className: `${s.tdClear} text-center`,
    accessor: simpleAccessor("folioFactura")
  },
  {
    Header: "Fecha factura",
    id: "fechaFactura",
    width: "80",
    className: `${s.tdClear} text-center`,
    accessor: dateAccessor("fechaFactura")
  },
  {
    Header: "Notas de crédito",
    id: "historial",
    width: "80",
    accessor: (row: PaymentMovement) => {
      return row.notasCredito ? (
        <Container className="p-0">
          <Row className="m-0">
            <Col className="px-1">
              <a
                id={`notas${row.ventaId}`}
                onClick={() => onShowNotasCredito(row)}
              >
                <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faClone} />
              </a>
            </Col>
          </Row>
        </Container>
      ) : null;
    }
  },
  {
    Header: "Opciones",
    id: "acciones",
    width: "150",
    accessor: (row: PaymentMovement) => {
      return (
        <Container className="p-0">
          <Row className="m-0">
            {!!row.storageIdFactura && (
              <Col className="px-1">
                <a
                  id={`factura-email-${row.id}`}
                  onClick={() => onSendEmail(row)}
                >
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faEnvelope}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={`factura-email-${row.id}`}
                  >
                    Enviar por correo
                  </UncontrolledTooltip>
                </a>
              </Col>
            )}
            {!!row.storageIdFactura && (
              <Col className="px-1">
                <a id={`factura-pdf-${row.id}`} onClick={() => onViewPdf(row)}>
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faFilePdf}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={`factura-pdf-${row.id}`}
                  >
                    Ver pdf factura
                  </UncontrolledTooltip>
                </a>
              </Col>
            )}
            {!!row.uuidFactura && (
              <Col className="px-1">
                <a id={`factura-xml-${row.id}`} onClick={() => onViewXml(row)}>
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faFileCsv}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={`factura-xml-${row.id}`}
                  >
                    Ver xml factura
                  </UncontrolledTooltip>
                </a>
              </Col>
            )}
          </Row>
        </Container>
      );
    }
  }
];

function fieldClassName(row: PaymentMovement) {
  return row.estatus == "PENDIENTE"
    ? `${s.field} ${s.pendiente}`
    : `${s.field} ${s.normal}`;
}

function simpleAccessor(key: keyof PaymentMovement) {
  return (row: any) => {
    return <div className={fieldClassName(row)}>{row[key]}&nbsp;</div>;
  };
}

function moneyAccessor(key: keyof PaymentMovement) {
  return (row: any) => {
    return (
      <div className={fieldClassName(row)}>
        <MexicanCurrency quantity={row[key]} />
      </div>
    );
  };
}

function noWrapAccessor(key: keyof PaymentMovement) {
  return (row: any) => {
    return (
      <div className={fieldClassName(row) + " text-nowrap"}>
        {row[key]}&nbsp;
      </div>
    );
  };
}

function dateAccessor(key: keyof PaymentMovement) {
  return row => {
    return (
      <div className={fieldClassName(row)}>{formatDate(row[key] || "-")}</div>
    );
  };
}

function formatDate(isoDate: string): string {
  if (!isoDate || isoDate == "-") {
    return "-";
  }

  try {
    const [year, month, day] = isoDate.split("-");
    return `${day}/${month}/${year} `;
  } catch (error) {
    return isoDate;
  }
}
