import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import ModalFrame from "../../../toolkit/modalFrame";
import s from "./styles.scss";
import ClubSelector from "../../components/clubSelector";
import Constants from "../../../utils/constants";
import Reform from "@franleplant/reform";
import { FormErrors } from "@franleplant/reform/types";
const { CLUB_SELECTION } = Constants;

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  clubs: { clubId: string; nombre: string }[];
  roles: { id: number; description: string }[];
  addRol: (rol: object) => any;
}

interface State {
  errors: FormErrors;
  concepto: string;
  empresa: string;
  visibleClubs: object;
  fields: {
    rolId: number;
    isGlobal: boolean;
    selectedClubs: object;
  };
  multiclub: boolean;
  disabledCheck: boolean;
}

const checkCondition = (filter, constant, key, obj) =>
  filter[key] === constant || obj[key] === filter[key];

class AddRoles extends React.Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);

  state = {
    concepto: CLUB_SELECTION.ALL_CONCEPTOS,
    empresa: CLUB_SELECTION.ALL_EMPRESAS,
    visibleClubs: null,
    fields: {
      rolId: null,
      isGlobal: false,
      selectedClubs: {}
    },
    errors: {},
    multiclub: false,
    disabledCheck: true
  };

  validationRules = {
    rolId: {
      required: true
    },
    selectedClubs: {
      validationSelectedClubs: _ => false
    }
  };

  validationMessages = {
    required: _ => `Obligatorio`,
    default: _ => `Inválido`,
    validationSelectedClubs: _ => `Debes Seleccionar al menos un club`
  };

  onSubmit = e => {
    e.preventDefault();
    if (this.re.validateFormFromState()) {
      const clubs = [];
      this.props.clubs.forEach(club => {
        const { clubId, nombre } = club;
        if (this.state.fields.selectedClubs[clubId])
          clubs.push({ clubId, nombre });
      });
      let nombre = "";
      this.props.roles.forEach(rol => {
        if (rol.id == this.state.fields.rolId) {
          nombre = rol.description;
        }
      });
      const rol = {
        clubs,
        isGlobal: this.state.fields.isGlobal,
        rolId: this.state.fields.rolId,
        nombre
      };
      this.props.addRol(rol);
      this.props.toggle();
      this.setState(state => {
        return {
          ...state,
          fields: {
            rolId: "",
            isGlobal: false,
            selectedClubs: {}
          }
        };
      });
    }
  };

  checkMulticlub = value => {
    this.props.roles.forEach(rol => {
      if (String(rol.id) === String(value)) {
        let state = { ...this.state };
        state.multiclub = rol["multiclub"];
        state.disabledCheck =
          String(value) === "0" || !this.state.fields.rolId ? true : false;
        this.setState(state, () => this.checkClub());
      }
    });
  };

  unCheckClubs = (id?: number, checked?: boolean) => {
    const fields = {
      ...this.state.fields,
      selectedClubs: { ...this.state.fields.selectedClubs }
    };
    if (!this.state.multiclub) fields.selectedClubs = {};

    if (id && checked)
      fields.selectedClubs = { ...fields.selectedClubs, [id]: checked };

    return fields;
  };

  checkClub = (e?: any, id?: any) => {
    let fields: any;
    if (e && id) {
      const checked = e.target.checked;
      fields = this.unCheckClubs(id, checked);
    } else fields = this.unCheckClubs();
    this.setState(state => ({
      ...state,
      fields
    }));
  };

  onChangeFilter = (key, event) => {
    const value = event.target.value;
    const { concepto, empresa, visibleClubs } = this.state;
    const filter = { concepto, empresa };
    filter[key] = value;
    this.props.clubs.forEach(club => {
      visibleClubs[club.clubId] =
        checkCondition(
          filter,
          CLUB_SELECTION.ALL_CONCEPTOS,
          "concepto",
          club
        ) &&
        checkCondition(filter, CLUB_SELECTION.ALL_EMPRESAS, "empresa", club);
    });
    this.setState(state => ({
      ...state,
      [key]: value,
      visibleClubs
    }));
  };

  selectAll = (e, value) => {
    e.preventDefault();
    const selectedClubs = { ...this.state.fields.selectedClubs };
    this.props.clubs.forEach(({ clubId }) => {
      if (this.state.visibleClubs[clubId]) {
        selectedClubs[clubId] = value;
      }
    });

    const fields = {
      ...this.state.fields,
      selectedClubs
    };

    this.setState(state => ({
      ...state,
      fields
    }));
  };
  /*---------------------------------------------------*/

  onChangeFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(
        state => {
          const fields = {
            ...state.fields,
            [fieldName]: value
          };

          return { ...state, fields, errors: {} };
        },
        () => {
          fieldName == "rolId" && this.checkMulticlub(value);
        }
      );
      this.re.validateField(fieldName, value);
    };
  };

  checkIsGlobal = ev => {
    const checked = ev.target.checked;
    this.setState(state => {
      const fields = {
        ...state.fields,
        isGlobal: checked
      };
      return {
        ...state,
        fields
      };
    });
  };

  componentDidUpdate() {
    if (
      this.props.clubs &&
      this.props.clubs.length &&
      !this.state.visibleClubs
    ) {
      const visibleClubs = {};

      this.props.clubs.forEach(club => {
        visibleClubs[club.clubId] = true;
      });

      this.setState(state => ({
        ...state,
        visibleClubs
      }));
    }
  }

  render() {
    return (
      <ModalFrame
        title={"Selecciona rol y club aplicable"}
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        size={"lg"}
      >
        <Container>
          <Form>
            <Row>
              <Col xs={8}>
                <BaseInput
                  label={"Rol"}
                  name={"Rol"}
                  type="select"
                  placeholder="Rol"
                  options={[{ value: 0, label: "Seleccionar Rol" }].concat(
                    this.props.roles.map(option => ({
                      value: option.id,
                      label: option.description
                    }))
                  )}
                  value={this.state.fields.rolId}
                  onChange={this.onChangeFactory("rolId")}
                  errors={this.re.mapFieldErrors("rolId")}
                />
                {this.state.fields.rolId != "0" &&
                this.state.fields.rolId != null ? (
                  this.state.multiclub ? (
                    <div className={`${s.rolLabel} mb-4`}>
                      Rol <strong className={s.bold}>con</strong> capacidad de
                      selección multiclub
                    </div>
                  ) : (
                    <div className={`${s.rolLabel} mb-4`}>
                      Rol <strong className={s.bold}>sin</strong> capacidad de
                      selección multiclub
                    </div>
                  )
                ) : (
                  <div className={`${s.rolLabel} mb-4`}></div>
                )}
              </Col>
              <Col xs={4}>
                <FormGroup
                  check
                  className={`${s.checkboxContainer} form-check-inline float-right`}
                >
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={this.state.fields.isGlobal}
                      onChange={e => this.checkIsGlobal(e)}
                    />{" "}
                    Es Global
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <ClubSelector
              clubs={this.props.clubs}
              checkClub={this.checkClub}
              selectAll={this.selectAll}
              selectedClubs={this.state.fields.selectedClubs}
              visibleClubs={this.state.visibleClubs}
              concepto={this.state.concepto}
              empresa={this.state.empresa}
              onChangeFilter={this.onChangeFilter}
              multiclub={this.state.multiclub}
              disabled={this.state.disabledCheck}
            />
            <Row>
              {this.re.mapFieldErrors("selectedClubs").map((message, index) => {
                return (
                  <span key={index} className={s.errorSpan}>
                    {message}
                    <br />
                  </span>
                );
              })}
            </Row>
            <Button
              className={`${s.buttonMarginTop} ${s.primaryButton}`}
              onClick={e => this.onSubmit(e)}
              size="lg"
              block
              disabled={this.state.disabledCheck}
            >
              Agregar
            </Button>
          </Form>
        </Container>
      </ModalFrame>
    );
  }
}

export default withRouter(withStyles(s)(AddRoles));
