import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Roles from "../../components/roles";
import * as rolesActions from "../../actions/roles";
import { QueryResponse } from "../../../types/QueryResponse";
import { QueryArguments } from "../../../types-business/QueryArguments";
import { Container, Row, Button, Form } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import permissionCodes from "../../../utils/permissionCodes";
import { userHasPermission } from "../../../utils/accessTree";

const { ROLES_DESCARGAR_REPORTES } = permissionCodes;

interface Props {
  roles: QueryResponse<Role>;
  getRoles: (args?: QueryArguments & { description?: string }) => any;
  searching: boolean;
  addRol: (role?) => any;
  history: any;
  match: any;
}

interface State {
  description: string;
  downloading: boolean;
}

interface Role {
  code: string;
  description: string;
}

class RolesPage extends React.Component<Props, State> {
  state = {
    description: "",
    downloading: false
  };

  onFetchData = ({ pageSize: size, page }) => {
    this.props.getRoles({
      size,
      page,
      description: this.state.description
    });
  };

  goTo = destiny => {
    this.props.history.push(destiny);
  };

  render() {
    return (
      <main>
        <Helmet title="Roles" />
        <Layout>
          {/* FIXME: La arquitectura del desarrollo no sugiere que se incluyan formularios aquí */}
          <Container>
            <Form
              onSubmit={e => {
                e.preventDefault();
                this.onFetchData({ pageSize: 20, page: 0 });
              }}
            >
              <Row className="mt-3">
                <div className="col-6">
                  <BaseInput
                    label="Descripción"
                    id="Descripción"
                    name="Descripción"
                    type="text"
                    placeholder="Descripción"
                    value={this.state.description}
                    onChange={evt =>
                      this.setState({ description: evt.target.value || "" })
                    }
                  />
                </div>
                <div className="col-2 pt-3">
                  <Button
                    className="primaryButton"
                    type="submit"
                    style={{ padding: "2px 16px", marginTop: "10px" }}
                  >
                    Buscar
                  </Button>
                </div>
              </Row>
              {userHasPermission(ROLES_DESCARGAR_REPORTES) && (
                <>
                  <hr />
                  <Row className="mt-3 mb-3">
                    <div className="col-6">
                      <Button
                        className="primaryButton"
                        type="button"
                        onClick={evt => {
                          evt.preventDefault();
                          this.setState({ downloading: true });
                          rolesActions.getReporteRolesPermisos(() =>
                            this.setState({ downloading: false })
                          );
                        }}
                        disabled={this.state.downloading}
                        block
                      >
                        {this.state.downloading
                          ? "Espere un momento"
                          : "Descargar reporte de permisos"}
                      </Button>
                    </div>
                    <div className="col-6">
                      <Button
                        className="primaryButton"
                        type="button"
                        onClick={evt => {
                          evt.preventDefault();
                          this.setState({ downloading: true });
                          rolesActions.getReporteRolesPermisosIndividuales(() =>
                            this.setState({ downloading: false })
                          );
                        }}
                        disabled={this.state.downloading}
                        block
                      >
                        {this.state.downloading
                          ? "Espere un momento"
                          : "Descargar reporte de permisos individuales"}
                      </Button>
                    </div>
                  </Row>
                </>
              )}
            </Form>
          </Container>
          <Roles
            roles={this.props.roles}
            fetchData={this.onFetchData}
            searching={this.props.searching}
            onClickNewRole={(role?) =>
              this.goTo(`/roles/nuevo-rol/${role ? role.id : ""}`)
            }
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getRoles: (args?: QueryArguments) => {
    dispatch(rolesActions.getRoles(args));
  }
});

const mapStateToProps = state => {
  const { role } = state;
  return {
    roles: role.roles || { data: [], totalPages: 0 },
    searching: role.gettingRoles
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RolesPage);
