import React from "react";
import { connect } from "react-redux";
import { getUsers } from "../../../Home/actions/catalogs";
import { UserSelector } from "../../components/UserSelector";

interface Props {
  loadUsers: () => any;
  loading: boolean;
  users: object[];
  disabled: boolean;
  onChange: (value: string) => any;
  value: object;
}

const getUserData = (users: any, userId: any) => {
  return users.find(({ userId: id }) => id == userId);
};

class UserSelectorContainer extends React.Component<Props> {
  componentDidMount() {
    this.loadUsers();
  }

  loadUsers = () => {
    if (!this.props.loading) {
      this.props.loadUsers();
    }
  };

  render() {
    return (
      <UserSelector
        value={this.props.value}
        users={this.props.users}
        onChange={value => {
          const userData = getUserData(this.props.users, value);
          this.props.onChange(userData);
        }}
        disabled={this.props.disabled || this.props.loading}
      />
    );
  }
}

const mapDispatchToProps = {
  loadUsers: getUsers
};

const mapStateToProps = ({ catalogs: { users, loadingUsers } }) => {
  return {
    users,
    loading: loadingUsers
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSelectorContainer);
