import React from "react";
import { Container, Row, Col, Button, Table } from "reactstrap";
import s from "./styles.scss";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import SelectFilter from "../../common/SelectFilter";
import DatePicker from "../../common/DatePicker";
import moment from "moment";
import { Demand } from "../../demands/types/Demand";

interface Props {
  users: {
    userId: number;
    name: string;
    firstSurname: string;
  }[];
  demand: Demand;
  loadingUsers: boolean;
  saveDetails: (details: {
    responsableId: number;
    fechaCompromiso: string;
  }) => any;
}

interface State {
  responsable: { value: number; label: string };
  fechaCompromiso: object;
}

const formatTime = (dateTime?) =>
  moment(dateTime)
    .utc()
    .format("YYYY-MM-DD");

class EditDetails extends React.Component<Props, State> {
  state = {
    responsable: null,
    fechaCompromiso: null
  };

  componentDidUpdate() {}

  componentDidMount() {}

  onChangeResponsible = responsable => {
    this.setState({ responsable });
  };

  onChangeFechaCompromiso = fechaCompromiso => {
    this.setState({
      ...this.state,
      fechaCompromiso
    });
  };

  onClickSave = () => {
    if (!this.state.responsable) {
      return false;
    }
    if (!this.state.fechaCompromiso) {
      return false;
    }
    const {
      responsable: { value: responsableId },
      fechaCompromiso
    } = this.state;
    this.props
      .saveDetails({
        responsableId,
        fechaCompromiso: formatTime(fechaCompromiso)
      })
      .then(() => {
        this.setState({ responsable: null, fechaCompromiso: null });
      });
  };

  render() {
    return (
      <Container>
        <Row>
          <Col>
            <SelectFilter
              label="Responsable"
              name="responsable"
              type="select"
              id="responsable"
              placeholder="Responsable"
              value={this.state.responsable}
              isMulti={false}
              options={
                this.props.users
                  ? this.props.users.map(({ userId, name, firstSurname }) => ({
                      label: `${name} ${firstSurname}`,
                      value: userId
                    }))
                  : []
              }
              onChange={this.onChangeResponsible}
              loading={this.props.loadingUsers}
              isAsync={false}
            />
          </Col>
          <Col>
            <DatePicker
              label="Fecha Compromiso"
              name="fechaCompromiso"
              id="fechaCompromiso"
              selected={this.state.fechaCompromiso}
              onChange={this.onChangeFechaCompromiso}
              minDate={moment()}
              errors={[]}
            />
          </Col>
          <Col xs={1}>
            <Button
              className={`${s.buttonMarginTop} ${s.primaryButton} mt-4`}
              onClick={this.onClickSave}
              size="sm"
            >
              Guardar
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="table-responsive">
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Fecha Creación</th>
                    <th>Responsable</th>
                    <th>Fecha Compromiso</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.demand && (
                    <tr>
                      <td>
                        {this.props.demand.fechaCreacionCompromiso
                          ? moment(this.props.demand.fechaCreacionCompromiso)
                              .utc()
                              .format("YYYY-MM-DD")
                          : ""}
                      </td>
                      <td>
                        {this.props.demand.responsableNombre
                          ? this.props.demand.responsableNombre
                          : ""}
                      </td>
                      <td>
                        {this.props.demand.fechaCompromiso
                          ? moment(this.props.demand.fechaCompromiso)
                              .utc()
                              .format("YYYY-MM-DD")
                          : ""}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withStyles(s)(EditDetails);
