import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";

import DoubleNumericInput from "../../../toolkit/doubleNumericInput";
import BaseNumericInput from "../../../toolkit/baseNumericInput";
import BaseInputWithCheck from "../../../toolkit/baseInputWithCheck";
import DatePicker from "../common/DatePicker";
import {
  Container,
  Col,
  Button,
  Row,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup
} from "reactstrap";
import ReactTable from "react-table";
import s from "./styles.scss";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import BaseInput from "../../../toolkit/baseInput";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable } from "@fortawesome/free-solid-svg-icons";
import {
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import {
  getAnnuitiesToAuthorizeRequest,
  authorizeAnnuitiesByIds
} from "../../actions/annuities";
import { handleRequestError } from "../../../utils/helpers";
import moment from "moment";

interface Props {}

interface State {
  checkedAll: boolean;
  checked: number[];
  showModal: boolean;
  annuitiesList: any[];
  selected: object;

  displayModalTitle: string;
  displayModalBody: string;
  showDisplayModal: boolean;
}

const phaseDiscountComponent = (aplicaPorcentaje, porcentaje, precioFijo) => {
  const numberFormat = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN"
  });

  return (
    <div style={{ textAlign: "center" }}>
      <p>{!!aplicaPorcentaje ? "SI" : "NO"}</p>
      {!!porcentaje && <p>Descuento: {porcentaje} %</p>}
      {!!precioFijo && <p>Precio: {numberFormat.format(precioFijo)}</p>}
    </div>
  );
};

class AnnuitiesAuth extends React.Component<Props, State> {
  state = {
    checkedAll: false,
    checked: [],
    showModal: false,
    annuitiesList: [],
    selected: {},
    displayModalTitle: null,
    displayModalBody: null,
    showDisplayModal: false
  };

  columns = [
    {
      Header: () => (
        <div className={s.initial}>
          <Input
            style={{ marginLeft: "0px" }}
            id="checkAllProducts"
            type="checkbox"
            checked={this.state.checkedAll}
            onChange={this.checkAll}
          />
        </div>
      ),
      id: "columnCheckItem",
      width: 50,
      headerStyle: {
        height: "4rem"
      },
      Cell: row => {
        return (
          <div className={s.initial}>
            <Input
              type="checkbox"
              style={{ marginLeft: "0px" }}
              value={row.original.id}
              checked={this.isAnnuityChecked(row.original.id)}
              onChange={this.onCheckedChanged}
            />
          </div>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Tipo</p>,
      id: 0,
      className: "text-center",
      accessor: "tipo"
    },
    {
      Header: () => <p className="font-weight-bold">Año</p>,
      id: 0,
      className: "text-center",
      accessor: "anio"
    },
    {
      Header: () => <p className="font-weight-bold">Nombre</p>,
      id: 0,
      width: 250,
      accessor: "nombre"
    },
    {
      Header: () => <p className="font-weight-bold">Estatus</p>,
      id: 0,
      className: "text-center",
      accessor: "estatus"
    },
    {
      Header: () => <p className="font-weight-bold">Meses aplicables</p>,
      id: 0,
      className: "text-center",
      accessor: "mesesAplicables"
    },
    {
      Header: () => <p className="font-weight-bold">Mantenimientos</p>,
      width: 200,
      id: 0,
      accessor: "mantenimientosNombres",
      Cell: row => {
        const mntos = (row.original.mantenimientosNombres || "").split(",");
        if (mntos.length <= 8) {
          return <p className={s.initial}>{mntos.join(", ")}</p>;
        } else {
          return (
            <Container>
              <p className={s.initial}>{mntos.slice(0, 10).join(", ")}</p>
              <br />
              <a
                style={{ cursor: "pointer", fontWeight: "bold" }}
                onClick={() => {
                  this.setState({
                    displayModalTitle: "Mantenimientos",
                    displayModalBody: mntos,
                    showDisplayModal: true
                  });
                }}
              >
                Ver todos
              </a>
            </Container>
          );
        }
      }
    },
    {
      Header: () => <p className="font-weight-bold">Clubes</p>,
      width: 200,
      id: 0,
      accessor: "clubesNombres",
      Cell: row => {
        const clubes = (row.original.clubesNombres || "").split(",");
        if (clubes.length <= 8) {
          return <p className={s.initial}>{clubes.join(", ")}</p>;
        } else {
          return (
            <Container>
              <p className={s.initial}>{clubes.slice(0, 10).join(", ")}</p>
              <br />
              <a
                style={{ cursor: "pointer", fontWeight: "bold" }}
                onClick={() => {
                  this.setState({
                    displayModalTitle: "Clubes",
                    displayModalBody: clubes,
                    showDisplayModal: true
                  });
                }}
              >
                Ver todos
              </a>
            </Container>
          );
        }
      }
    }
  ];

  subColumns = [
    {
      Header: "Inicio",
      accessor: "fechaInicio",
      className: "text-center"
    },
    {
      Header: "Fin",
      accessor: "fechaFin",
      className: "text-center"
    },
    {
      Header: "Meses gratis",
      accessor: "mesesGratis",
      className: "text-center"
    },
    {
      Header: "Contado",
      className: "text-center",
      Cell: row => {
        return phaseDiscountComponent(
          true,
          row.original.porcentajeContado,
          row.original.precioFijoContado
        );
      }
    },

    {
      Header: "3 MSI",
      className: "text-center",
      Cell: row => {
        return phaseDiscountComponent(
          row.original.aplicaPorcentaje3msi,
          row.original.porcentaje3msi,
          row.original.precioFijo3msi
        );
      }
    },
    {
      Header: "6 MSI",
      className: "text-center",
      Cell: row => {
        return phaseDiscountComponent(
          row.original.aplicaPorcentaje6msi,
          row.original.porcentaje6msi,
          row.original.precioFijo6msi
        );
      }
    },
    {
      Header: "9 MSI",
      className: "text-center",
      Cell: row => {
        return phaseDiscountComponent(
          row.original.aplicaPorcentaje9msi,
          row.original.porcentaje9msi,
          row.original.precioFijo9msi
        );
      }
    },
    {
      Header: "12 MSI",
      className: "text-center",
      Cell: row => {
        return phaseDiscountComponent(
          row.original.aplicaPorcentaje12msi,
          row.original.porcentaje12msi,
          row.original.precioFijo12msi
        );
      }
    },
    {
      Header: "Acciones",
      id: "id",
      accessor: phase => {
        return (
          <Container className="w-100 text-center">
            <Button
              color="link"
              onClick={() => this.showPhaseInfo(phase)}
              className={`${s.innerButton} ${s.primaryLink}`}
            >
              <FontAwesomeIcon icon={faTable} />
            </Button>
          </Container>
        );
      }
    }
  ];

  bankColumns = [
    {
      Header: "Banco",
      accessor: "bancoNombre",
      className: "text-center"
    },
    {
      Header: "% Descuento",
      accessor: "porcentajeDescuento",
      className: "text-center"
    }
  ];

  discountsColumns = [
    {
      Header: "Tipo producto",
      accessor: "categoriaDescripcion",
      className: "text-center"
    },
    {
      Header: "Producto",
      accessor: "productoNombre",
      className: "text-center"
    },
    {
      Header: "% Descuento",
      accessor: "porcentajeDescuento",
      className: "text-center"
    },
    {
      Header: "$ Descuento",
      accessor: "montoDescuento",
      className: "text-center"
    },
    {
      Header: "No. Códigos Descuento",
      accessor: "numeroCodigosDescuento",
      className: "text-center"
    }
  ];

  componentDidMount() {
    this.search();
  }

  checkAll = event => {
    const checked = event.target.checked
      ? (this.state.annuitiesList || []).map(x => x.id)
      : [];
    this.setState({ checked: [...checked], checkedAll: event.target.checked });
  };

  onCheckedChanged = event => {
    const target = event.target;
    const value = target.value;
    const checked = !!target.checked
      ? [...this.state.checked, parseInt(value)]
      : this.state.checked.filter(x => x != value);
    this.setState({ checked: [...checked] });
  };

  isAnnuityChecked = id => {
    return !!(this.state.checked || []).find(x => x == id);
  };

  search = () => {
    this.setState({ checked: [] });

    infoNotification("Consultando datos...");

    getAnnuitiesToAuthorizeRequest()
      .then(({ data }) => {
        this.setState({ annuitiesList: data });
        successNotification("Datos consultados correctamente...");
      })
      .catch(handleRequestError);
  };

  getDataSource = () => {
    return this.state.annuitiesList || [];
  };

  showPhaseInfo = fase => {
    this.setState({ showModal: true, selected: fase });
  };

  authorize = () => {
    if (!this.state.checked || !this.state.checked.length) {
      return;
    }

    infoNotification("Autorizando configuración de anualidades...");
    authorizeAnnuitiesByIds([...this.state.checked])
      .then(() => {
        successNotification("Configuraciones autorizadas correctamente.");
        this.setState({
          checkedAll: false,
          checked: [],
          showModal: false,
          annuitiesList: [],
          selected: {}
        });
        this.search();
      })
      .catch(handleRequestError);
  };

  //modal

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  toggleDisplayModal = () => {
    this.setState({
      showDisplayModal: !this.state.showDisplayModal
    });
  };

  handleClosePositionsModal = () => {
    this.toggleModal();
  };

  handleCloseDisplayModal = () => {
    this.toggleDisplayModal();
  };

  render() {
    return (
      <Container className="p-0 pt-3">
        <Row>
          <Col xs={12}>
            <div className="row mt-3 mb-3">
              <div className="col">
                <p className={`${s.primaryTitle} w-100 m-0`}>
                  Configuración de anualidades por autorizar
                </p>
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <ReactTable
              data={this.getDataSource()}
              columns={this.columns}
              className={`${s.customTable} mt-5 -striped -highlight`}
              sortable={false}
              resizable={true}
              pageSize={(this.state.annuitiesList || []).length + 1}
              showPagination={false}
              SubComponent={row => {
                return (
                  <div className={s.subtabla}>
                    <ReactTable
                      data={row.original.fases || []}
                      columns={this.subColumns}
                      defaultPageSize={(row.original.fases || []).length + 1}
                      showPagination={false}
                    />
                  </div>
                );
              }}
            />
          </Col>
        </Row>
        <div className="text-right mt-2 mb-5">
          <Button
            className={`${s.buttonMarginTop} ${s.primaryButton}`}
            onClick={this.authorize}
            size="sm"
            disabled={!(this.state.checked || []).length}
          >
            Autorizar
          </Button>
        </div>

        <Modal
          isOpen={this.state.showModal}
          toggle={this.toggleModal}
          fade={true}
          size="lg"
          backdrop="static"
          keyboard={false}
        >
          <ModalHeader toggle={this.toggleModal}>
            <span className="font-weight-bold">Información de la fase</span>
          </ModalHeader>
          <ModalBody>
            <Container>
              <Row>
                <Col xs={3}>
                  <DatePicker
                    label="Venta desde:"
                    name="fechaVentaDesde"
                    id="fechaVentaDesde"
                    selected={this.state.selected.fechaInicio}
                    disabled={true}
                  />
                </Col>
                <Col xs={3}>
                  <DatePicker
                    label="Venta hasta:"
                    name="fechaVentaHasta"
                    id="fechaVentaHasta"
                    selected={this.state.selected.fechaFin}
                    disabled={true}
                  />
                </Col>
                <Col xs={3}>
                  <BaseInput
                    label={"Respetar precios del Año"}
                    name="anioPreciosAplicar"
                    type="select"
                    id="anioPreciosAplicar"
                    value={this.state.selected.anioPreciosAplicar}
                    options={[
                      { value: "", label: "Seleccionar el año" },
                      { value: moment().year(), label: moment().year() },
                      {
                        value: moment().year() + 1,
                        label: moment().year() + 1
                      }
                    ]}
                    disabled={true}
                  />
                </Col>
                <Col xs={3}>
                  <BaseNumericInput
                    label={"Meses gratis"}
                    name="noMesesGratis"
                    id="noMesesGratis"
                    type="text"
                    value={this.state.selected.mesesGratis}
                    placeholder={"Meses gratis"}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col xs={4} className="text-left">
                  <FormGroup check className="form-check-inline">
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={this.state.selected.habilitadaKiosco}
                        disabled={true}
                      />{" "}
                      Habilitar para Kiosco
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <p style={{ color: "red", fontWeight: "bold" }}>MSI</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <DoubleNumericInput
                    label={"Contado"}
                    type="text"
                    placeholder={"% Descuento contado"}
                    placeholder2={"Precio Fijo contado"}
                    value={this.state.selected.porcentajeContado}
                    value2={this.state.selected.precioFijoContado}
                    doubleInput={true}
                    disabled={true}
                  />
                </Col>
                <Col>
                  <BaseInputWithCheck
                    label={"Aplica 3 MSI"}
                    checked={this.state.selected.aplicaPorcentaje3msi}
                    placeholder={"% Descuento 3 MSI"}
                    placeholder2={"Precio Fijo 3 MSI"}
                    value={this.state.selected.porcentaje3msi}
                    value2={this.state.selected.precioFijo3msi}
                    disabled={true}
                  />
                </Col>
                <Col>
                  <BaseInputWithCheck
                    label={"Aplica 6 MSI"}
                    checked={this.state.selected.aplicaPorcentaje6msi}
                    placeholder={"% Descuento 6 MSI"}
                    placeholder2={"Precio Fijo 6 MSI"}
                    value={this.state.selected.porcentaje6msi}
                    value2={this.state.selected.precioFijo6msi}
                    disabled={true}
                  />
                </Col>
                <Col>
                  <BaseInputWithCheck
                    label={"Aplica 9 MSI"}
                    checked={this.state.selected.aplicaPorcentaje9msi}
                    placeholder={"% Descuento 9 MSI"}
                    placeholder2={"Precio Fijo 9 MSI"}
                    value={this.state.selected.porcentaje9msi}
                    value2={this.state.selected.precioFijo9msi}
                    disabled={true}
                  />
                </Col>
                <Col>
                  <BaseInputWithCheck
                    label={"Aplica 12 MSI"}
                    checked={this.state.selected.aplicaPorcentaje12msi}
                    placeholder={"% Descuento 12 MSI"}
                    placeholder2={"Precio Fijo 12 MSI"}
                    value={this.state.selected.porcentaje12msi}
                    value2={this.state.selected.precioFijo12msi}
                    disabled={true}
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col>
                  <p style={{ color: "red", fontWeight: "bold" }}>
                    Invitados gratis
                  </p>
                </Col>
              </Row>

              <Row>
                <Col xs={3}>
                  <BaseNumericInput
                    label={"# Invitados gratis"}
                    name="noInvitadosGratis"
                    id="noInvitadosGratis"
                    type="text"
                    value={this.state.selected.invitadosGratis}
                    placeholder={"# Invitados gratis"}
                    disabled={true}
                  />
                </Col>
                <Col xs={3}>
                  <DatePicker
                    label="Fecha inicio acceso:"
                    name="invitadosGratisFechaInicio"
                    id="invitadosGratisFechaInicio"
                    selected={
                      this.state.selected.invitadosGratisFechaInicioPeriodo
                    }
                    disabled={true}
                  />
                </Col>
                <Col xs={3}>
                  <DatePicker
                    label="Fecha fin acceso:"
                    name="invitadosGratisFechaFin"
                    id="invitadosGratisFechaFin"
                    selected={
                      this.state.selected.invitadosGratisFechaFinPeriodo
                    }
                    disabled={true}
                  />
                </Col>
                <Col xs={3}>
                  <BaseNumericInput
                    label={"# Meses acceso"}
                    name="noMesesAcceso"
                    id="noMesesAcceso"
                    type="text"
                    value={this.state.selected.invitadosGratisMeses || ""}
                    placeholder={"# Meses acceso"}
                    disabled={true}
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col>
                  <p style={{ color: "red", fontWeight: "bold" }}>
                    Descuentos Bancos
                  </p>
                </Col>
              </Row>
              <div className="mt-2">
                <ReactTable
                  data={this.state.selected.bancosDescuentos || []}
                  columns={this.bankColumns}
                  className="-striped -highlight"
                  sortable={false}
                  showPagination={false}
                  pageSize={
                    (this.state.selected.bancosDescuentos || []).length + 1
                  }
                />
              </div>

              <Row className="mt-3">
                <Col>
                  <p style={{ color: "red", fontWeight: "bold" }}>
                    Descuentos productos
                  </p>
                </Col>
              </Row>

              <div className="mt-2">
                <ReactTable
                  data={this.state.selected.productosDescuentos || []}
                  columns={this.discountsColumns}
                  className="-striped -highlight"
                  sortable={false}
                  showPagination={false}
                  pageSize={
                    (this.state.selected.productosDescuentos || []).length + 1
                  }
                />
              </div>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button
              className={`${s.secondaryButton} d-inline-block mr-2`}
              onClick={this.handleClosePositionsModal}
            >
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.showDisplayModal}
          toggle={this.toggleDisplayModal}
          fade={true}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <ModalHeader toggle={this.toggleDisplayModal}>
            <span className="font-weight-bold">
              {this.state.displayModalTitle}
            </span>
          </ModalHeader>
          <ModalBody>
            <Container>
              <Row>
                {(this.state.displayModalBody || []).map(x => {
                  return (
                    <Col xs="3">
                      <span style={{ fontSize: "12px" }}>{x}</span>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button
              className={`${s.secondaryButton} d-inline-block mr-2`}
              onClick={this.handleCloseDisplayModal}
            >
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(AnnuitiesAuth));
