import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import { errorNotification, ERROR } from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";
import EmpresaConvenio from "../../types/EmpresaConvenio";

export interface ConvenioClubesAsignables {
  clubesAsignables: any[];
  clubesAsignablesLoading: boolean;
}

// Empresas con algún convenio asociado
// REQ: Efrén Pérez - Se deben mostrar empresas aunque no tengan convenio
export interface GetConvenioEmpresasConvenio {
  empresasConvenio: any[];
  empresasConvenioLoading: boolean;
}

// Empresas activas para cierto club y mantenimiento
export interface GetConvenioEmpresasActivas {
  empresasActivas: any[];
  empresasActivasLoading: boolean;
}

/// Definición del estado
interface State
  extends ConvenioClubesAsignables,
    GetConvenioEmpresasActivas,
    GetConvenioEmpresasConvenio {
  conveniosList: any[];
  conveniosListLoading: boolean;

  convenioDatosBasicos: any;
  convenioDatosBasicosLoading: boolean;

  // Empresas asignables a un convenio, osea, que no pertenezcan otro convenio para actalizar
  empresasAsignables: any[];
  empresasAsignablesLoading: boolean;

  clubesAsociados: number[];
  clubesAsociadosLoading: boolean;

  mantenimientosAsignables: any[];
  mantenimientosAsignablesLoading: boolean;

  mantenimientosAsociados: number[];
  mantenimientosAsociadosLoading: boolean;
}

const initialState: State = {
  conveniosList: [],
  conveniosListLoading: false,
  convenioDatosBasicos: {},
  convenioDatosBasicosLoading: false,
  empresasAsignables: [],
  empresasAsignablesLoading: false,
  empresasActivas: [],
  empresasActivasLoading: false,
  empresasConvenio: [],
  empresasConvenioLoading: false,
  clubesAsignables: [],
  clubesAsignablesLoading: false,
  clubesAsociados: [],
  clubesAsociadosLoading: false,
  mantenimientosAsignables: [],
  mantenimientosAsignablesLoading: false,
  mantenimientosAsociados: [],
  mantenimientosAsociadosLoading: false
};
////

export default function conveniosReducer(
  state: State = initialState,
  action: IAction
): State {
  const snapshot = snapshotAction(state, action);

  // Llamada a resolver de snapshot
  state != snapshot &&
    !action.type.endsWith("_PENDING") &&
    action.meta &&
    action.meta.resolver &&
    action.meta.resolver({
      fulfilled: action.type.endsWith("_FULFILLED"),
      ...snapshot
    });

  return state != snapshot
    ? {
        ...state,
        ...snapshot
      }
    : state;
}

function snapshotAction(state: State = initialState, action: IAction) {
  switch (action.type) {
    /// CLEAR_CONVENIO_CAROUSEL
    case actionTypes.CLEAR_CONVENIO_CAROUSEL: {
      return {
        convenioDatosBasicos: {},
        empresasAsignables: [],
        clubesAsignables: [],
        clubesAsociados: [],
        mantenimientosAsignables: [],
        mantenimientosAsociados: [],
        membresiasAsignables: [],
        membresiasAsociadas: [],
        descuentos: {}
      };
    } ////

    /// GET_CONVENIOS_LIST
    case `${actionTypes.GET_CONVENIOS_LIST}_PENDING`: {
      return {
        conveniosListLoading: true
      };
    }
    case `${actionTypes.GET_CONVENIOS_LIST}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        conveniosListLoading: false,
        conveniosList: []
      };
    }
    case `${actionTypes.GET_CONVENIOS_LIST}_FULFILLED`: {
      return {
        conveniosListLoading: false,
        conveniosList: action.payload.data || []
      };
    } ////

    /// GET_CONVENIO_DATOS_BASICOS
    case `${actionTypes.GET_CONVENIO_DATOS_BASICOS}_PENDING`: {
      return {
        convenioDatosBasicosLoading: true
      };
    }
    case `${actionTypes.GET_CONVENIO_DATOS_BASICOS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        convenioDatosBasicosLoading: false,
        convenioDatosBasicos: {}
      };
    }
    case `${actionTypes.GET_CONVENIO_DATOS_BASICOS}_FULFILLED`: {
      return {
        convenioDatosBasicosLoading: false,
        convenioDatosBasicos: action.payload.data || {}
      };
    } ////

    /// POST_CONVENIO_DATOS_BASICOS
    case `${actionTypes.POST_CONVENIO_DATOS_BASICOS}_PENDING`: {
      return {
        convenioDatosBasicosLoading: true
      };
    }
    case `${actionTypes.POST_CONVENIO_DATOS_BASICOS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        convenioDatosBasicosLoading: false,
        convenioDatosBasicos: {}
      };
    }
    case `${actionTypes.POST_CONVENIO_DATOS_BASICOS}_FULFILLED`: {
      return {
        convenioDatosBasicosLoading: false,
        convenioDatosBasicos: action.payload.data || {}
      };
    } ////

    /// PUT_CONVENIO_DATOS_BASICOS
    case `${actionTypes.PUT_CONVENIO_DATOS_BASICOS}_PENDING`: {
      return {
        convenioDatosBasicosLoading: true
      };
    }
    case `${actionTypes.PUT_CONVENIO_DATOS_BASICOS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        convenioDatosBasicosLoading: false,
        convenioDatosBasicos: {}
      };
    }
    case `${actionTypes.PUT_CONVENIO_DATOS_BASICOS}_FULFILLED`: {
      return {
        convenioDatosBasicosLoading: false,
        convenioDatosBasicos: action.payload.data || {}
      };
    } ////

    /// GET_CONVENIO_EMPRESAS_ASIGNABLES
    case `${actionTypes.GET_CONVENIO_EMPRESAS_ASIGNABLES}_PENDING`: {
      return {
        empresasAsignablesLoading: true
      };
    }
    case `${actionTypes.GET_CONVENIO_EMPRESAS_ASIGNABLES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        empresasAsignablesLoading: false,
        empresasAsignables: []
      };
    }
    case `${actionTypes.GET_CONVENIO_EMPRESAS_ASIGNABLES}_FULFILLED`: {
      return {
        empresasAsignablesLoading: false,
        empresasAsignables: action.payload.data || []
      };
    } ////

    /// GET_CONVENIO_EMPRESAS_ACTIVAS
    case `${actionTypes.GET_CONVENIO_EMPRESAS_ACTIVAS}_PENDING`: {
      return {
        empresasActivasLoading: true
      };
    }
    case `${actionTypes.GET_CONVENIO_EMPRESAS_ACTIVAS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        empresasActivasLoading: false,
        empresasActivas: []
      };
    }
    case `${actionTypes.GET_CONVENIO_EMPRESAS_ACTIVAS}_FULFILLED`: {
      return {
        empresasActivasLoading: false,
        empresasActivas: action.payload.data || []
      };
    } ////

    /// GET_CONVENIO_EMPRESAS_CONVENIO
    case `${actionTypes.GET_CONVENIO_EMPRESAS_CONVENIO}_PENDING`: {
      return {
        empresasConvenioLoading: true
      };
    }
    case `${actionTypes.GET_CONVENIO_EMPRESAS_CONVENIO}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        empresasConvenioLoading: false,
        empresasConvenio: []
      };
    }
    case `${actionTypes.GET_CONVENIO_EMPRESAS_CONVENIO}_FULFILLED`: {
      return {
        empresasConvenioLoading: false,
        empresasConvenio: action.payload.data || []
      };
    } ////

    /// GET_CONVENIO_CLUBES_ASIGNABLES
    case `${actionTypes.GET_CONVENIO_CLUBES_ASIGNABLES}_PENDING`: {
      return {
        clubesAsignablesLoading: true
      };
    }
    case `${actionTypes.GET_CONVENIO_CLUBES_ASIGNABLES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        clubesAsignablesLoading: false,
        clubesAsignables: []
      };
    }
    case `${actionTypes.GET_CONVENIO_CLUBES_ASIGNABLES}_FULFILLED`: {
      return {
        clubesAsignablesLoading: false,
        clubesAsignables: action.payload.data || []
      };
    } ////

    /// GET_CONVENIO_CLUBES_ASOCIADOS
    case `${actionTypes.GET_CONVENIO_CLUBES_ASOCIADOS}_PENDING`: {
      return {
        clubesAsociadosLoading: true
      };
    }
    case `${actionTypes.GET_CONVENIO_CLUBES_ASOCIADOS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        clubesAsociadosLoading: false,
        clubesAsociados: []
      };
    }
    case `${actionTypes.GET_CONVENIO_CLUBES_ASOCIADOS}_FULFILLED`: {
      return {
        clubesAsociadosLoading: false,
        clubesAsociados: action.payload.data || []
      };
    } ////

    /// PUT_CONVENIO_CLUBES_ASOCIADOS
    case `${actionTypes.PUT_CONVENIO_CLUBES_ASOCIADOS}_PENDING`: {
      return {
        clubesAsociadosLoading: true
      };
    }
    case `${actionTypes.PUT_CONVENIO_CLUBES_ASOCIADOS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        clubesAsociadosLoading: false,
        clubesAsociados: []
      };
    }
    case `${actionTypes.PUT_CONVENIO_CLUBES_ASOCIADOS}_FULFILLED`: {
      return {
        clubesAsociadosLoading: false,
        clubesAsociados: action.payload.data || []
      };
    } ////

    /// GET_CONVENIO_MANTENIMIENTOS_ASIGNABLES
    case `${actionTypes.GET_CONVENIO_MANTENIMIENTOS_ASIGNABLES}_PENDING`: {
      return {
        mantenimientosAsignablesLoading: true
      };
    }
    case `${actionTypes.GET_CONVENIO_MANTENIMIENTOS_ASIGNABLES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        mantenimientosAsignablesLoading: false,
        mantenimientosAsignables: []
      };
    }
    case `${actionTypes.GET_CONVENIO_MANTENIMIENTOS_ASIGNABLES}_FULFILLED`: {
      return {
        mantenimientosAsignablesLoading: false,
        mantenimientosAsignables: action.payload.data || []
      };
    } ////

    /// GET_CONVENIO_MANTENIMIENTOS_ASOCIADOS
    case `${actionTypes.GET_CONVENIO_MANTENIMIENTOS_ASOCIADOS}_PENDING`: {
      return {
        mantenimientosAsociadosLoading: true
      };
    }
    case `${actionTypes.GET_CONVENIO_MANTENIMIENTOS_ASOCIADOS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        mantenimientosAsociadosLoading: false,
        mantenimientosAsociados: []
      };
    }
    case `${actionTypes.GET_CONVENIO_MANTENIMIENTOS_ASOCIADOS}_FULFILLED`: {
      return {
        mantenimientosAsociadosLoading: false,
        mantenimientosAsociados: action.payload.data || []
      };
    } ////

    /// PUT_CONVENIO_MANTENIMIENTOS_ASOCIADOS
    case `${actionTypes.PUT_CONVENIO_MANTENIMIENTOS_ASOCIADOS}_PENDING`: {
      return {
        mantenimientosAsociadosLoading: true
      };
    }
    case `${actionTypes.PUT_CONVENIO_MANTENIMIENTOS_ASOCIADOS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        mantenimientosAsociadosLoading: false,
        mantenimientosAsociados: []
      };
    }
    case `${actionTypes.PUT_CONVENIO_MANTENIMIENTOS_ASOCIADOS}_FULFILLED`: {
      return {
        mantenimientosAsociadosLoading: false,
        mantenimientosAsociados: action.payload.data || []
      };
    } ////

    default: {
      return state;
    }
  }
}
