import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Container, Row, Col, Label, Input, Button } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";

import s from "./styles.scss";
import { DATA, ORIGEN_PAGO, FORMA_PAGO } from "./interfaces";
import Table from "./table";

interface Props {
  sendSells: (tiposPago: any[], cantidad: number, nroItems: number) => any;
  sellsLteReport: object;
  clearDatasendSells: () => any;
}

interface State extends DATA {}

class SellsLtePage extends React.Component<Props, State> {
  state = {
    tiposPago: [],
    origenPago: null,
    formaPago: null,
    cantidad: null,
    nroItems: null,
    disableSend: true
  };

  onChangeDrop = async (fieldName, e) => {
    let state = this.state;
    state[fieldName] = e.target.value;
    await this.setState(state);
  };

  addToTable = () => {
    let state = { ...this.state };
    if (state.cantidad !== "" && state.nroItems !== "")
      state.disableSend = false;
    const { origenPago, formaPago } = this.state;
    state.tiposPago.push({ origenPago, formaPago });
    this.setState(state);
  };
  sendSells = () => {
    const { tiposPago, cantidad, nroItems } = this.state;
    this.props.sendSells(tiposPago, cantidad, nroItems);
  };

  componentDidUpdate() {
    if (
      this.props.sellsLteReport &&
      this.props.sellsLteReport["successfullSells"]
    ) {
      let state = { ...this.state };
      state.disableSend = true;
      state.tiposPago = [];
      state.origenPago = -1;
      state.formaPago = -1;
      state.cantidad = "";
      state.nroItems = "";
      this.props.clearDatasendSells();
      this.setState(state);
    }
  }

  render() {
    return (
      <Container className="mt-3 mb-5 p-0">
        <Row className="mb-1">
          <Col sm="12">Ingresa los campos requeridos para comenzar ( * )</Col>
        </Row>

        <Row className="my-4">
          <Col sm="3">
            <Label>*Cantidad</Label>
            <Input
              type="number"
              name="Cantidad"
              id="cantidad"
              placeholder="Cantidad"
              onChange={e => this.onChangeDrop("cantidad", e)}
              value={this.state.cantidad}
            />
          </Col>

          <Col sm="3">
            <Label>*NroItems</Label>
            <Input
              type="number"
              name="nroItems"
              id="nroItems"
              placeholder="nroItems"
              onChange={e => this.onChangeDrop("nroItems", e)}
              value={this.state.nroItems}
            />
          </Col>
        </Row>

        <Row>
          <Col sm="4">
            <BaseInput
              label={"*Origen Pago"}
              name="origenPago"
              type="select"
              id="origenPago"
              size={"sm"}
              value={this.state.origenPago}
              options={ORIGEN_PAGO}
              onChange={e => this.onChangeDrop("origenPago", e)}
            />
          </Col>

          <Col sm="4">
            <BaseInput
              label={"*Forma Pago"}
              name="formaPago"
              type="select"
              id="formaPago"
              size={"sm"}
              value={this.state.formaPago}
              options={FORMA_PAGO}
              onChange={e => this.onChangeDrop("formaPago", e)}
            />
          </Col>

          <Col sm={"4"}>
            <br />
            <Button onClick={() => this.addToTable()} block>
              Agregar
            </Button>
          </Col>
        </Row>

        <Table tiposPago={this.state.tiposPago}></Table>
        <hr />
        <Row>
          <Col sm={{ size: "3", offset: "9" }}>
            <Button
              disabled={this.state.disableSend}
              onClick={() => this.sendSells()}
              block
            >
              Enviar ventas
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withStyles(s)(SellsLtePage);
