import React from "react";
import s from "./styles.scss";
import {
  Router,
  Styled,
  RouterProps
} from "../../../toolkit/decorators/Artifact";
import { Container, Button } from "reactstrap";
import ReactTable from "react-table";
import columnsFactory from "./reactTable";

interface Props extends RouterProps {
  conveniosList: any[];
  conveniosListLoading: boolean;
  getConveniosList: () => void;
}

interface State {}

@Router()
@Styled(s)
export default class ConveniosComponent extends React.Component<Props, State> {
  state = {};

  columns = columnsFactory(
    () => this.props.history.push("/cconvenios/configuracion"),
    row => this.props.history.push(`/cconvenios/${row.id}/configuracion`),
    s.actionHeaderIcon
  );

  render() {
    return (
      <Container>
        <div className="row">
          <div className="col-9">&nbsp;</div>
          <div className="col-3 mt-4">
            <Button
              className="primaryButton"
              onClick={e => {
                e.preventDefault();
                this.props.history.push("/empresas-convenios");
              }}
              size="sm"
              block
            >
              Empresas de convenio
            </Button>
          </div>
        </div>
        <ReactTable
          data={this.props.conveniosList}
          columns={this.columns}
          className={`${s.customTable} mt-5 -striped -highlight`}
          sortable={false}
          resizable={true}
          pageSize={10}
          showPagination={false}
        />
      </Container>
    );
  }
}
