import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Row, Button, Container, Col, Label, Input } from "reactstrap";
import BaseInput from "../../../../toolkit/baseInput";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";

interface Props {
  onChangeFilter: any;
  onChangeCheckActivo: any;
  onChangeCheck: any;
  titulo: string;
  alerta: boolean;
  activo: boolean;
  descripcion: string;
  returnListNotificaciones: any;
  updateNotification: any;
  idMembresiaSocio: string;
}

class UpdateMessage extends React.Component<Props> {
  render() {
    const {
      onChangeFilter,
      onChangeCheck,
      onChangeCheckActivo,
      titulo,
      alerta,
      activo,
      descripcion,
      returnListNotificaciones,
      updateNotification,
      idMembresiaSocio
    } = this.props;

    return (
      <Container className="p-0 container" fluid>
        <div className="row mt-3 mb-3">
          <div className="col">
            <p className={`${s.primaryTitle} w-100 m-0`}>
              Actualización de mensajes
            </p>
          </div>
        </div>
        <Row>
          <Col>
            <BaseInput
              label={"Título"}
              name={"titulo"}
              id={"titulo"}
              placeholder="Título"
              value={titulo}
              size="sm"
              onChange={onChangeFilter("titulo")}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={s.colCustomCheck}>
              <Label check>
                <Input
                  type="checkbox"
                  checked={alerta}
                  onChange={e => onChangeCheck(e)}
                />
                Alerta
              </Label>
            </div>
          </Col>
          <Col>
            <div className={s.colCustomCheck}>
              <Label check>
                <Input
                  type="checkbox"
                  checked={activo}
                  onChange={e => onChangeCheckActivo(e)}
                />
                Activo
              </Label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>Descripción:</Col>
        </Row>
        <Row>
          <Col>
            <textarea
              name="descripcion"
              rows={6}
              id="descripcion"
              value={descripcion}
              className="form-control"
              onChange={onChangeFilter("descripcion")}
            />
          </Col>
        </Row>
        <Row className={s.customBotonera}>
          <Col></Col>
          <Col>
            <Button
              size="sm"
              className={`${s.searchButton} ${s.primaryButton} float-right btn-block`}
              onClick={returnListNotificaciones}
            >
              Listado de mensajes
            </Button>
          </Col>
          <Col>
            <Button
              size="sm"
              className={`${s.searchButton} ${s.primaryButton} float-right btn-block`}
              onClick={updateNotification}
              disabled={
                idMembresiaSocio === "" || titulo === "" || descripcion === ""
              }
            >
              Actualizar mensaje
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(UpdateMessage));
