import { Preview } from "../../../types/IAction";
import {
  getClasesSinComision,
  postClasesComisionGenerar
} from "../../actions/comisionesDeportivaCrm1";
import {
  GetClasesSinComision,
  PostClasesComisionGenerar
} from "../../reducers/comisionesDeportivaCrm1";

export interface ClaseSinComisionDispatcher {
  getClasesSinComision: (params: any) => Promise<Preview<GetClasesSinComision>>;
  postClasesComisionGenerar: (
    body: number[]
  ) => Promise<Preview<PostClasesComisionGenerar>>;
}

export const claseSinComisionDispatcher: (
  dispatch: (...args) => Promise<Preview<any>>
) => ClaseSinComisionDispatcher = dispatch => ({
  getClasesSinComision: (params: any) => {
    return dispatch(getClasesSinComision(params));
  },
  postClasesComisionGenerar: (body: number[]) => {
    return dispatch(postClasesComisionGenerar(body));
  }
});
