import React from "react";
import DatePicker from "react-datepicker";
import DatePickerCss from "react-datepicker/src/stylesheets/datepicker.scss";
import s from "./styles.scss";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { FormGroup, Label } from "reactstrap";
import moment from "moment";
import * as $ from "jquery";
import isEmpty from "lodash.isempty";

interface Props {
  name: string;
  id: string;
  selected: any;
  label: string;
  placeholder: string;
  onChange: (string: string) => void;
  errors: Array<string>;
  disabled: boolean;
  size: string;
  maxDate: object;
  minDate: object;
}
let style = `
 .react-datepicker-wrapper{
    width: 100%;
  }

  .react-datepicker__input-container{
    width: 100%;
  }
  .react-datepicker__input-container input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    outline: 0;
    border: 1px solid #ccc;
    background: #fff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    color: #616161;
    line-height: 1.3333333;
    border-radius: $space-unit;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    padding: 4px 8px;
    font-size: 0.8rem;
    height: 2rem !important;
    cursor: pointer;
  }

  .react-datepicker__input-container input:focus {
    border-color: gray;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px gray;
  }
`;
class DatePickerContainer extends React.Component<Props> {
  jq = $.noConflict();

  componentDidMount() {
    // Ya no funciona desde cierta versión de Chrome
    // this.jq(`.react-datepicker__input-container input`).prop("disabled", true);
    this.jq(`.react-datepicker__input-container input`).each((i, el) => {
      el.addEventListener("keydown", e => e.preventDefault());
      el.autocomplete = "off";
    });

    style =
      this.props.size == "sm"
        ? `${style} .react-datepicker__input-container input{font-size: 0.8rem;}`
        : style;
  }
  render() {
    const {
      id,
      name,
      selected,
      errors,
      label,
      onChange,
      disabled
    } = this.props;
    const errClass = errors && errors.length ? s.error : "";

    return (
      <FormGroup className={s.formGroup}>
        {label && (
          <Label className={s.label} for={id}>
            {label}:
          </Label>
        )}
        <style>{style}</style>
        <DatePicker
          selected={isEmpty(selected) ? null : moment(selected)}
          id={id}
          name={name}
          onChange={onChange}
          disabled={disabled}
          dateFormat="DD/MM/YYYY"
          className={`${s.datePicker} ${s.input} ${errClass} form-control`}
          showYearDropdown
          dropdownMode="select"
          placeholderText={"DD/MM/YYYY"}
          popperClassName={s.popper}
          maxDate={this.props.maxDate || null}
          minDate={this.props.minDate || null}
          styles={{ height: "2rem" }}
        />
        {errors &&
          errors.map((message, index) => {
            return (
              <span key={index} className={s.errorSpan}>
                {message}
                <br />
              </span>
            );
          })}
      </FormGroup>
    );
  }
}

export default withStyles(DatePickerCss, s)(DatePickerContainer);
