import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import BanksMonthsWithOutInterest from "../../components/banksMonthsWithOutInterest";
import * as bankActions from "../../actions/banks";
import * as modalsActions from "../../actions/modals";
import { Banco } from "../../../types/Banks";
import { QueryResponse } from "../../../types/QueryResponse";
import { QueryArguments } from "../../../types-business/QueryArguments";
import constants from "../../../../src/utils/constants";

interface Props {
  banks: QueryResponse<Banco>;
  getMonthsWithoutInterests: (args?: QueryArguments) => any;
  searching: boolean;
  toggleEditMonthsWithoutInterest: (bank) => any;
  reload: boolean;
}

class BanksPage extends React.Component<Props> {
  state = {
    size: constants.DEFAULT_PAGE_SIZE,
    page: 0
  };

  onFetchData = ({ pageSize: size, page }) => {
    this.props.getMonthsWithoutInterests({ size, page });
    this.setState(prevState => {
      return {
        ...prevState,
        size,
        page
      };
    });
  };

  componentDidMount() {
    this.props.getMonthsWithoutInterests(this.state);
  }

  componentDidUpdate() {
    if (this.props.reload) {
      this.props.getMonthsWithoutInterests(this.state);
    }
  }

  render() {
    return (
      <main>
        <Helmet title="Bancos" />
        <Layout>
          <BanksMonthsWithOutInterest
            banks={this.props.banks}
            searching={this.props.searching}
            onClickNewBank={this.props.toggleEditMonthsWithoutInterest}
            onFetchData={this.onFetchData}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getMonthsWithoutInterests: (args?: QueryArguments) => {
    dispatch(bankActions.getMonthsWithoutInterests(args));
  },
  toggleEditMonthsWithoutInterest: bank => {
    dispatch(modalsActions.toggleEditMonthsWithoutInterest(bank));
  }
});

const mapStateToProps = state => {
  const { banks } = state;
  return {
    banks: banks.banksMonthsWithoutInterest || [],
    searching: banks.gettingBanks,
    reload: banks.reloadBanks
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BanksPage);
