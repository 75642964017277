import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import PagedTable from "../common/PagedTable";
import { QueryResponse } from "../../../types/QueryResponse";
import { QueryArguments } from "../../../types-business/QueryArguments";
import { Container, Row, Col, Button } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import FileExportButton from "../fileExportButton";

interface Locker {
  id: string;
  clubId: string;
  vestidor: string;
  pared: string;
  fechaAdquisicion: string;
  fechaVencimiento: string;
  estatus: string;
  personaId: string;
  personaNombre: string;
  locker: string;
  pdfStorageId: number;
  idMembresiaSocio: number;
  idClubBase: number;
  idPublico: number;
}

interface Props {
  clubsCatalog: any;
  lockers: QueryResponse<Locker>;
  searching: boolean;
  reloadProducts: boolean;
  history: any;
  match: any;
  filters: Filters;
  getClubFile: (clubId: number) => void;
  onChangeFilter: any;
  onClickPrintExpirationNotification: (locker) => any;
  fetchData: () => void;
  getAssignedByClubId: (args?: QueryArguments) => any;
  showDocument: (pdfStorageid: number) => any;
  userContext: any;
}

interface Filters {
  clubId: number;
  page: number;
  size: number;
}

const LOCKER_ESTATUS = {
  ACTIVO: "ACTIVO",
  POR_VENCER: "POR_VENCER",
  VENCIDO: "VENCIDO"
};

const getLockerContextualStatusClass = locker => {
  switch (locker.estatus) {
    case LOCKER_ESTATUS.ACTIVO:
      return "table-success";
    case LOCKER_ESTATUS.POR_VENCER:
      return "table-warning";
    case LOCKER_ESTATUS.VENCIDO:
      return "table-danger";
    default:
      return "";
  }
};

class LockersAssigned extends React.Component<Props> {
  handleGenerateStatusDocument = locker => {
    this.props.onClickPrintExpirationNotification(locker);
  };
  handleShowLockerAssignDocument = locker => {
    this.props.showDocument(locker.pdfStorageId);
  };

  handleClubChanged = e => {
    const clubId = e.target.value;
    this.props.onChangeFilter(clubId, "clubId");
  };

  search = () => {
    if (!!this.props.filters.clubId && this.props.filters.clubId > 0) {
      this.props.getAssignedByClubId(this.props.filters);
    }
  };

  getOptionsClubes = clubs => {
    const response = [];
    response.push({ value: "", label: "Seleccionar" });
    clubs.forEach(club => {
      if (club.nombre) {
        response.push({
          label: club.nombre,
          value: club.clubId
        });
      }
    });

    return response;
  };
  getClubFile = e => {
    e.preventDefault();
    this.props.getClubFile(this.props.filters.clubId);
  };
  render() {
    const columns = [
      { name: "Club", id: "clubNombre" },
      { name: "Memb.", id: "idPublico" },
      { name: "Vestidor", id: "vestidor", className: "text-center" },
      { name: "Pared", id: "pared", className: "text-center" },
      { name: "Posición", id: "lockerPosicion", className: "text-center" },
      { name: "Número", id: "lockerNumero", className: "text-center" },
      { name: "Locker", id: "locker" },
      {
        name: "Fecha de asignación",
        id: "fechaAdquisicion",
        className: "text-center"
      },
      {
        name: "Fecha de vencimiento",
        id: "fechaVencimiento",
        className: "text-center"
      },
      { name: "Cliente", id: "personaNombre" },
      {
        headerActions: []
      }
    ];
    const actions = [
      {
        name: "print",
        f: this.handleGenerateStatusDocument,
        visible: item => {
          return (
            item.estatus === LOCKER_ESTATUS.POR_VENCER ||
            item.estatus === LOCKER_ESTATUS.VENCIDO
          );
        },
        label: "Imprimir documento de estatus"
      },
      {
        name: "print",
        f: this.handleShowLockerAssignDocument,
        label: "Imprimir documento de asignación"
      },
      {
        name: "membership",
        f: item => {
          window.open(
            `/membresias-socios/${item.idMembresiaSocio}?idClub=${item.idClubBase}&idPublico=${item.idPublico}`,
            "_blank"
          );
        },
        label: "Ir a membresía"
      },
      {
        name: "edit",
        f: item => {
          this.props.history.push(`/lockers/extension/${item.id}`);
        },
        label: "Reasignar"
      }
    ];
    return (
      <Container className="p-0 mt-3">
        <Row>
          {this.props.clubsCatalog && (
            <Col style={{ paddingBottom: "5px" }} xs={12} md={8} lg={9}>
              <BaseInput
                label={"Club"}
                name="clubId"
                type="select"
                id="clubId"
                placeholder="Club"
                value={this.props.filters.clubId}
                options={this.getOptionsClubes(this.props.clubsCatalog)}
                onChange={this.handleClubChanged}
                disabled={
                  this.props.userContext && this.props.userContext.clubSessionId
                }
              />
            </Col>
          )}
          <Col xs={3} md={1} lg={1} className="pt-4">
            <FileExportButton
              disabled={!(this.props.filters && this.props.filters.clubId > 0)}
              getFile={this.getClubFile}
            />
          </Col>
          <Col xs={9} md={3} lg={2} className="mt-4">
            <Button
              size="sm"
              className={`${s.searchButton} ${s.primaryButton} float-right btn-block`}
              onClick={this.search}
            >
              Buscar
            </Button>
          </Col>
        </Row>

        <PagedTable
          data={this.props.lockers}
          columns={columns}
          actions={actions}
          fetchData={this.props.fetchData}
          searching={this.props.searching}
          getTrProps={(_, locker) => {
            return locker
              ? { className: getLockerContextualStatusClass(locker.original) }
              : {};
          }}
        />
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(LockersAssigned));
