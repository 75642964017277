import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import {
  Container,
  Col,
  Button,
  Row,
  UncontrolledTooltip,
  Form
} from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import s from "./styles.scss";
import IAction from "../../../types/IAction";
import ReactTable from "react-table";
import { faCubes, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddUserDealer from "./addUserDealer";
import DealerClubs from "./dealerClubs";

interface Props {
  addDealers: (body: object) => IAction;
  getDealers: (name: string, page: string, size: string) => IAction;
  getClubDealer: (personaId: string) => IAction;
  updateClubDealer: (personaId: string, body: object) => IAction;
  clubsDealer: any;
  persons: any;
  clubs: any;
  objAddDealers: any;
  updateClubsDealer: any;
}

interface State {
  toOpen: boolean;
  toOpenClubs: boolean;
  idUsuario: string;
}

class DealershipEnvironment extends React.Component<Props, State> {
  state = {
    filters: {
      nombre: ""
    },
    toOpen: false,
    toOpenClubs: false,
    idUsuario: ""
  };

  columns = [
    {
      Header: () => <p className="font-weight-bold">Id</p>,
      id: 0,
      width: 100,
      accessor: "id",
      className: "text-center"
    },
    {
      Header: () => <p className="font-weight-bold">Nombre</p>,
      id: 0,
      accessor: "nombre",
      Cell: row => {
        return (
          <div>
            <a
              href={`/concesionarios/${row.original.id}/detalle`}
              target="_blank"
            >
              {row.original.nombre}
            </a>
          </div>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">RFC</p>,
      id: 0,
      accessor: "rfc",
      className: "text-center"
    },
    {
      Header: () => <p className="font-weight-bold">Cuenta producto</p>,
      id: 0,
      accessor: "cuentaProducto",
      className: "text-center"
    },
    {
      Header: () => (
        <div className="w-100 text-center">
          <Button
            color="link"
            onClick={this.addUser}
            className={`${s.innerButton} ${s.primaryLink}`}
          >
            <FontAwesomeIcon icon={faUserPlus} />
          </Button>
        </div>
      ),
      id: -300,
      width: 80,
      accessor: "actions",
      className: "text-center",
      Cell: row => {
        return (
          <a
            id={`concesionario${row.original.id}`}
            onClick={() => {
              this.setState({
                toOpenClubs: true,
                idUsuario: row.original.id
              });
            }}
          >
            <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faCubes} />
            <UncontrolledTooltip
              placement="left"
              target={`concesionario${row.original.id}`}
            >
              Asociar clubes
            </UncontrolledTooltip>
          </a>
        );
      }
    }
  ];

  onChangeFilters = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  onFetchData = ({ pageSize: size, page }) => {
    const { nombre } = this.state.filters;
    this.props.getDealers(nombre, page, size);
  };

  addUser = () => {
    this.setState({
      toOpen: true
    });
  };

  render() {
    const { persons } = this.props;
    return (
      <Container className="p-0">
        <DealerClubs
          isNowOpenClubs={this.state.toOpenClubs}
          idUsuario={this.state.idUsuario}
          toggleClubs={() => {
            const toOpenClubs = !this.state.toOpenClubs;
            if (!toOpenClubs) {
            }
            this.setState({ toOpenClubs });
          }}
          getClubDealer={this.props.getClubDealer}
          updateClubDealer={this.props.updateClubDealer}
          clubsDealer={this.props.clubsDealer}
          clubs={this.props.clubs}
          updateClubsDealer={this.props.updateClubsDealer}
          getDealers={this.props.getDealers}
        />
        <AddUserDealer
          isNowOpen={this.state.toOpen}
          toggle={() => {
            const toOpen = !this.state.toOpen;
            if (!toOpen) {
            }
            this.setState({ toOpen });
          }}
          addDealers={this.props.addDealers}
          objAddDealers={this.props.objAddDealers}
          getDealers={this.props.getDealers}
        />
        <Form
          onSubmit={e => {
            e.preventDefault();
            this.onFetchData(e);
          }}
        >
          <Row>
            <Col xs={12} md={10} lg={10}>
              <BaseInput
                label={"Nombre"}
                name="nombre"
                type="text"
                id="nombre"
                value={this.state.filters.nombre}
                onChange={this.onChangeFilters("nombre")}
              />
            </Col>
            <Col xs={12} md={2} lg={2} className="mt-4">
              <Button
                size="sm"
                className={`float-right btn-block`}
                onClick={this.onFetchData}
              >
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
        <Row className={s.customRow}>
          <Col>
            <ReactTable
              manual
              data={persons ? persons.content : []}
              pages={persons ? persons.totalPages : 0}
              columns={this.columns}
              className="w-100"
              onFetchData={this.onFetchData}
              defaultPageSize={20}
              sortable={false}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(DealershipEnvironment));
