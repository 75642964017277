import React from "react";
import { Week, FormalWeek, DateItem } from "./types";
import { DateComponent } from "./DateComponent";

interface Props {
  id: String;
  week: Week;
  soportaAlterna: boolean;
}

interface State {}

export class WeekComponent extends React.Component<Props, State> {
  render() {
    const id = this.props.id;
    const week: FormalWeek = this.formalWeek();

    return [
      <DateComponent
        date={week.monday}
        key={id + week.monday.moment.format("YYYY-MM-DD")}
        soportaAlterna={this.props.soportaAlterna}
      />,
      <DateComponent
        date={week.tuesday}
        key={id + week.tuesday.moment.format("YYYY-MM-DD")}
        soportaAlterna={this.props.soportaAlterna}
      />,
      <DateComponent
        date={week.wednesday}
        key={id + week.wednesday.moment.format("YYYY-MM-DD")}
        soportaAlterna={this.props.soportaAlterna}
      />,
      <DateComponent
        date={week.thursday}
        key={id + week.thursday.moment.format("YYYY-MM-DD")}
        soportaAlterna={this.props.soportaAlterna}
      />,
      <DateComponent
        date={week.friday}
        key={id + week.friday.moment.format("YYYY-MM-DD")}
        soportaAlterna={this.props.soportaAlterna}
      />,
      <DateComponent
        date={week.saturday}
        key={id + week.saturday.moment.format("YYYY-MM-DD")}
        soportaAlterna={this.props.soportaAlterna}
      />,
      <DateComponent
        date={week.sunday}
        key={id + week.sunday.moment.format("YYYY-MM-DD")}
        soportaAlterna={this.props.soportaAlterna}
      />
    ];
  }

  /**
   * Normaliza una semana si no inicia en lunes o termina en domingo
   * @returns Semana sin días vacíos
   */
  formalWeek(): FormalWeek {
    const week: Week = this.props.week;

    if (week.sunday && week.monday) {
      return week as FormalWeek;
    } else if (week.sunday) {
      return this.formalWeekStart(week);
    } else {
      return this.formalWeekEnd(week);
    }
  }

  /**
   * Normaliza una semana si no inicia en lunes
   * @param week Semana que no inicia en lunes
   * @returns Semana sin días vacíos
   */
  formalWeekStart(week: Week): FormalWeek {
    const sunday: DateItem = week.sunday as DateItem;

    if (!week.saturday) {
      week.saturday = {
        moment: sunday.moment.clone().subtract({ days: 1 }),
        selected: null,
        disabled: true
      };
    }
    if (!week.friday) {
      week.friday = {
        moment: week.saturday.moment.clone().subtract({ days: 1 }),
        selected: null,
        disabled: true
      };
    }
    if (!week.thursday) {
      week.thursday = {
        moment: week.friday.moment.clone().subtract({ days: 1 }),
        selected: null,
        disabled: true
      };
    }
    if (!week.wednesday) {
      week.wednesday = {
        moment: week.thursday.moment.clone().subtract({ days: 1 }),
        selected: null,
        disabled: true
      };
    }
    if (!week.tuesday) {
      week.tuesday = {
        moment: week.wednesday.moment.clone().subtract({ days: 1 }),
        selected: null,
        disabled: true
      };
    }
    if (!week.monday) {
      week.monday = {
        moment: week.tuesday.moment.clone().subtract({ days: 1 }),
        selected: null,
        disabled: true
      };
    }

    return week as FormalWeek;
  }

  /**
   * Normaliza una semana si no termina en domingo
   * @param week Semana que no termina en domingo
   * @returns Semana sin días vacíos
   */
  formalWeekEnd(week: Week): FormalWeek {
    const monday: DateItem = week.monday as DateItem;

    if (!week.tuesday) {
      week.tuesday = {
        moment: monday.moment.clone().add({ days: 1 }),
        selected: null,
        disabled: true
      };
    }
    if (!week.wednesday) {
      week.wednesday = {
        moment: week.tuesday.moment.clone().add({ days: 1 }),
        selected: null,
        disabled: true
      };
    }
    if (!week.thursday) {
      week.thursday = {
        moment: week.wednesday.moment.clone().add({ days: 1 }),
        selected: null,
        disabled: true
      };
    }
    if (!week.friday) {
      week.friday = {
        moment: week.thursday.moment.clone().add({ days: 1 }),
        selected: null,
        disabled: true
      };
    }
    if (!week.saturday) {
      week.saturday = {
        moment: week.friday.moment.clone().add({ days: 1 }),
        selected: null,
        disabled: true
      };
    }
    if (!week.sunday) {
      week.sunday = {
        moment: week.saturday.moment.clone().add({ days: 1 }),
        selected: null,
        disabled: true
      };
    }

    return week as FormalWeek;
  }
}
