import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import * as catalogActions from "../../actions/catalogs";
import * as reportsActions from "../../actions/reports";
import { getPersonById, clearSelectedPerson } from "../../actions/persons";
import IAction from "../../../types/IAction";
import EmployeesReport from "../../components/employeesReport";

interface Props {
  getClubs: () => void;
  clubs: object[];
  getEmployees: (
    clubId: string,
    email: string,
    empresaId: string,
    estatus: string,
    estatusEvaluacion: string,
    nombre: string,
    numero: string,
    puesto: string,
    sinEmail: boolean,
    pageNumber: string,
    pageSize: string
  ) => IAction;
  reportEmployees: any;
  userContext: any;
  getEmployeesCsv: (body: object) => IAction;
  getpersonByID: (personId: string) => any;
  clearSelectedPerson: () => any;
  personByID: object;
}

interface State {}

class Employees extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    this.props.getClubs();
  }

  componentWillUnmount() {
    this.props.clearSelectedPerson();
  }

  render() {
    return (
      <main>
        <Helmet title="Reporte de empleados" />
        <Layout>
          <EmployeesReport
            clubs={this.props.clubs}
            getEmployees={this.props.getEmployees}
            reportEmployees={this.props.reportEmployees}
            getEmployeesCsv={this.props.getEmployeesCsv}
            getpersonByID={this.props.getpersonByID}
            personByID={this.props.personByID}
            clearSelectedPerson={this.props.clearSelectedPerson}
            userContext={this.props.userContext}
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({ catalogs, reports, persons, user }) => {
  return {
    clubs: catalogs.clubs,
    reportEmployees: reports.reportEmployees,
    personByID: persons.personById,
    userContext: user.userContext
  };
};

const mapDispatchToProps = dispatch => ({
  getClubs: () => {
    dispatch(catalogActions.getClubs());
  },
  getpersonByID: (id: string) => {
    return dispatch(getPersonById(id));
  },
  getEmployees: (
    clubId: string,
    email: string,
    empresaId: string,
    estatus: string,
    estatusEvaluacion: string,
    nombre: string,
    numero: string,
    puesto: string,
    sinEmail: string,
    pageNumber: string,
    pageSize: string
  ) => {
    dispatch(
      reportsActions.getEmployees(
        clubId,
        email,
        empresaId,
        estatus,
        estatusEvaluacion,
        nombre,
        numero,
        puesto,
        sinEmail,
        pageNumber,
        pageSize
      )
    );
  },
  getEmployeesCsv: (body: object) => {
    dispatch(reportsActions.getEmployeesCsv(body));
  },
  clearSelectedPerson: () => {
    return dispatch(clearSelectedPerson());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Employees);
