import React from "react";
import { Button, Col, Container, Row } from "reactstrap";
import ModalFrame from "../../../../../toolkit/modalFrame";
import { handleRequestError } from "../../../../../utils/helpers";
import {
  infoNotification,
  successNotification
} from "../../../../../utils/notifications";
import { createPartnerMembershipBitacoraMessage } from "../../../../actions/partners";
import { Partner } from "../../interfaces";

interface Props {
  partner: Partner;
  idMembresiaSocio: string;
  isOpen: boolean;
  cancel: () => any;
  success: () => any;
}

interface State {
  message: String;
}

class AddBinnacleMessageModal extends React.Component<Props, State> {
  state = {
    message: null
  };

  componentDidMount() {}

  handleSubmit = () => {
    const { message } = this.state;
    const { idMembresiaSocio } = this.props;

    infoNotification("Registrando mensaje en la bitácora...");

    createPartnerMembershipBitacoraMessage(idMembresiaSocio, message)
      .then(() => {
        successNotification("Mensaje registrado correctamente");
        this.setState({ message: null });
        this.props.success();
      })
      .catch(handleRequestError);
  };

  handleCancel = () => {
    this.setState({ message: null });
    this.props.cancel();
  };

  render() {
    return (
      <ModalFrame
        title={`Agregar mensaje a la bitácora`}
        isOpen={this.props.isOpen}
        toggle={() => {}}
        size={"md"}
      >
        <Container style={{ width: "90%" }} className="center-block p-0 mb-3">
          <div>
            <textarea
              name="txtAreaMessageModal"
              rows={10}
              id="txtAreaMessageModal"
              value={this.state.message}
              className="form-control"
              onChange={e => {
                const value = e.target.value;
                this.setState({ message: value });
              }}
            />
          </div>

          <Row className="m-0">
            <Col xs="12" md="5">
              <Button
                className={`mt-3 primaryButton`}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.handleSubmit();
                }}
                block
                disabled={!this.state.message || !this.state.message.length}
              >
                Guardar
              </Button>
            </Col>
            <Col xs="12" md="2"></Col>
            <Col xs="12" md="5">
              <Button
                className={` mt-3`}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.handleCancel();
                }}
                block
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </Container>
      </ModalFrame>
    );
  }
}

export default AddBinnacleMessageModal;
