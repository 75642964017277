import {
  GetComisionesDeportivaCrm1Pendientes,
  PutComisionesDeportivaCrm1Procesar
} from "../../reducers/comisionesDeportivaCrm1";

export interface ComisionesDeportivaPendientesStore
  extends GetComisionesDeportivaCrm1Pendientes,
    PutComisionesDeportivaCrm1Procesar {}

export const comisionesDeportivaPendientesStore: (
  state
) => ComisionesDeportivaPendientesStore = state => {
  const { comisionesDeportivaCrm1 } = state;
  return {
    comsionesDeportivaCrm1Pendientes:
      comisionesDeportivaCrm1.comsionesDeportivaCrm1Pendientes,
    comsionesDeportivaCrm1PendientesLoading:
      comisionesDeportivaCrm1.comsionesDeportivaCrm1PendientesLoading,
    comsionesDeportivaCrm1PendientesError:
      comisionesDeportivaCrm1.comsionesDeportivaCrm1PendientesError,
    comsionesDeportivaCrm1Procesadas:
      comisionesDeportivaCrm1.comsionesDeportivaCrm1Procesadas,
    comsionesDeportivaCrm1ProcesarLoading:
      comisionesDeportivaCrm1.comsionesDeportivaCrm1ProcesarLoading,
    comsionesDeportivaCrm1ProcesarError:
      comisionesDeportivaCrm1.comsionesDeportivaCrm1ProcesarError
  };
};
