import React from "react";
import s from "./styles.scss";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import { Container, Button, Row, Col } from "reactstrap";
import {
  faEdit,
  faPlusCircle,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddPhaseModal from "./phaseModal";
import SelectClubsModal from "../common/clubSelectorModal/selectClubsModal";
import SelectMaintenacesModal from "../common/maintenancesSelectorModal/selectMaintenancesModal";
import BaseInput from "../../../toolkit/baseInput";
import BaseNumericInput from "../../../toolkit/baseNumericInput";
import moment from "moment";
import cloneDeep from "lodash.clonedeep";

import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import { getAnnuityRequest, saveAnnuityRequest } from "../../actions/annuities";
import { handleRequestError } from "../../../utils/helpers";

const RESTO_ANIO = "RESTO_ANIO";
const MESES_ROLADOS = "MESES_ROLADOS";
const ANUAL = "ANUAL";

interface Props {
  maintenances: any[];
  clubs: any[];
  banks: any[];
  classifications: any[];
  history: any;
  match: any;
}

const removeTags = text =>
  text === null || text === "" ? "" : text.toString().replace(/<[^>]*>/g, "");

const phaseDiscountComponent = (aplicaPorcentaje, porcentaje, precioFijo) => {
  const numberFormat = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN"
  });

  return (
    <div style={{ textAlign: "center" }}>
      <p>{!!aplicaPorcentaje ? "SI" : "NO"}</p>
      {!!porcentaje && <p>Descuento: {porcentaje} %</p>}
      {!!precioFijo && <p>Precio: {numberFormat.format(precioFijo)}</p>}
    </div>
  );
};

class AnnuitiesConfig extends React.Component<Props> {
  state = {
    //--------------------------------
    fields: {
      id: null,
      tipo: ANUAL,
      anio: moment().year(),
      nombre: null,
      estatus: "CREADA",
      mesesAplicables: null,
      condicionesLegales: null,
      mantenimientos: [],
      mantenimientosNombres: "",
      clubes: [],
      clubesNombres: "",
      fases: [],
      saving: false
    },
    //--------------------------------
    isAddPhaseModalOpen: false,
    isClubSelectorModalOpen: false,
    isMaintenanceSelectorModalOpen: false,
    edited: null
  };

  columns = [
    {
      Header: "Inicio",
      accessor: "fechaInicio",
      className: "text-center"
    },
    {
      Header: "Fin",
      accessor: "fechaFin",
      className: "text-center"
    },
    {
      Header: "Meses gratis",
      accessor: "mesesGratis",
      className: "text-center"
    },
    {
      Header: "Contado",
      className: "text-center",
      Cell: row => {
        return phaseDiscountComponent(
          true,
          row.original.porcentajeContado,
          row.original.precioFijoContado
        );
      }
    },

    {
      Header: "3 MSI",
      className: "text-center",
      Cell: row => {
        return phaseDiscountComponent(
          row.original.aplicaPorcentaje3msi,
          row.original.porcentaje3msi,
          row.original.precioFijo3msi
        );
      }
    },
    {
      Header: "6 MSI",
      className: "text-center",
      Cell: row => {
        return phaseDiscountComponent(
          row.original.aplicaPorcentaje6msi,
          row.original.porcentaje6msi,
          row.original.precioFijo6msi
        );
      }
    },
    {
      Header: "9 MSI",
      className: "text-center",
      Cell: row => {
        return phaseDiscountComponent(
          row.original.aplicaPorcentaje9msi,
          row.original.porcentaje9msi,
          row.original.precioFijo9msi
        );
      }
    },
    {
      Header: "12 MSI",
      className: "text-center",
      Cell: row => {
        return phaseDiscountComponent(
          row.original.aplicaPorcentaje12msi,
          row.original.porcentaje12msi,
          row.original.precioFijo12msi
        );
      }
    },
    {
      Header: () => (
        <Container className="w-100 text-center">
          <Button
            color="link"
            onClick={() => this.toggleAddPhaseModal()}
            className={`${s.innerButton} ${s.primaryLink}`}
          >
            <FontAwesomeIcon icon={faPlusCircle} />
          </Button>
        </Container>
      ),
      id: "id",
      accessor: phase => {
        return (
          <Container className="w-100 text-center">
            <Button
              color="link"
              onClick={() => this.handleEditPhase(phase)}
              className={`${s.innerButton} ${s.primaryLink}`}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
            &nbsp;
            <Button
              color="link"
              onClick={() => this.handleDeletePhase(phase.key)}
              className={`${s.innerButton} ${s.primaryLink}`}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </Container>
        );
      }
    }
  ];

  componentDidMount() {
    const { id: annuityId } = this.props.match.params;
    if (annuityId) {
      getAnnuityRequest(annuityId)
        .then(({ data }) => {
          if (!!data) {
            if (!!data.fases) {
              data.fases.forEach(
                x => (x["key"] = Math.floor(Math.random() * 100000 + 1))
              );
            }
            this.setState({ fields: { ...data } });
          }
        })
        .catch(handleRequestError);
    }
  }

  toggleAddPhaseModal = () => {
    this.setState({
      edited: null,
      isAddPhaseModalOpen: !this.state.isAddPhaseModalOpen
    });
  };

  toggleClubSelectorModal = () => {
    this.setState({
      isClubSelectorModalOpen: !this.state.isClubSelectorModalOpen
    });
  };

  toggleMaintenanceSelectorModal = () => {
    this.setState({
      isMaintenanceSelectorModalOpen: !this.state.isMaintenanceSelectorModalOpen
    });
  };

  onChangeField = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...this.state.fields,
          [fieldName]: value
        };
        return { ...state, fields };
      });
    };
  };

  onClubSelectedChange = async clubsSelected => {
    const clubes = clubsSelected.map(({ clubId }) => clubId);
    const clubesNombres = clubsSelected
      .map(({ nombre }) => nombre)
      .sort()
      .join(", ");

    const { fields } = this.state;
    await this.setState({
      fields: { ...fields, clubes, clubesNombres }
    });
    this.toggleClubSelectorModal();
  };

  onMaintenaceSelectedChange = async maintenacesSelected => {
    const mantenimientos = maintenacesSelected.map(
      ({ maintenanceId }) => maintenanceId
    );
    const mantenimientosNombres = maintenacesSelected
      .map(({ nombre }) => nombre)
      .sort()
      .join(", ");

    const { fields } = this.state;
    await this.setState({
      fields: { ...fields, mantenimientos, mantenimientosNombres }
    });
    this.toggleMaintenanceSelectorModal();
  };

  handleAddPhase = (phase: object) => {
    const { fields } = this.state;
    fields.fases = [...fields.fases.filter(x => x.key != phase.key), phase];
    this.setState({
      edited: null,
      fields,
      isAddPhaseModalOpen: false
    });
  };

  handleEditPhase = async phase => {
    await this.setState({
      edited: phase
    });
    await this.setState({
      isAddPhaseModalOpen: true
    });
  };

  handleDeletePhase = phaseKey => {
    const { fields } = this.state;
    fields.fases = [...fields.fases.filter(x => x.key != phaseKey)];
    this.setState({
      fields,
      isAddPhaseModalOpen: false,
      edited: null
    });
  };

  validFields() {
    let isValid = true;
    if (this.state.fields.nombre == null || this.state.fields.nombre == "") {
      errorNotification("El campo NOMBRE es requerido");
      isValid = false;
    } else if (
      this.state.fields.mesesAplicables == null ||
      this.state.fields.mesesAplicables == ""
    ) {
      errorNotification("El campo MESES APLICABLES es requerido");
      isValid = false;
    } else if (
      this.state.fields.clubes == null ||
      this.state.fields.clubes.length == 0
    ) {
      errorNotification("Se requiere seleccionar al menos un CLUB");
      isValid = false;
    } else if (
      this.state.fields.mantenimientos == null ||
      this.state.fields.mantenimientos.length == 0
    ) {
      errorNotification("Se requiere seleccionar al menos un MANTENIMIENTO");
      isValid = false;
    }
    return isValid;
  }

  handleSubmit = e => {
    e.preventDefault();
    const obj = cloneDeep(this.state.fields);

    if (this.validFields()) {
      infoNotification("Guardando configuración de anualidad...");
      this.setState({ saving: true });
      saveAnnuityRequest(obj)
        .then(({ data }) => {
          successNotification(
            "configuración de anualidad guardada correctamente"
          );
          const { fields } = this.state;
          fields["id"] = data.id;
          this.setState({ fields: { ...fields } });
          this.props.history.push(`/anualidades/editar/${data.id}`);
        })
        .catch(handleRequestError);
      this.setState({ saving: true });
    }
  };

  render() {
    return (
      <Container className="w-100 px-0 pt-2">
        <SelectClubsModal
          isOpen={this.state.isClubSelectorModalOpen}
          toggle={this.toggleClubSelectorModal}
          addClubsToSportsClub={this.onClubSelectedChange}
          validClubs={this.props.clubs}
        />
        <SelectMaintenacesModal
          isOpen={this.state.isMaintenanceSelectorModalOpen}
          toggle={this.toggleMaintenanceSelectorModal}
          setMaintenances={this.onMaintenaceSelectedChange}
          maintenances={(this.props.maintenances || []).map(x => {
            const { datosBasicosMantenimientoId: maintenanceId, nombre } = x;
            return { maintenanceId, nombre };
          })}
          maintenancesInUse={this.state.fields.mantenimientos || []}
        />
        <AddPhaseModal
          isOpen={this.state.isAddPhaseModalOpen}
          toggle={this.toggleAddPhaseModal}
          addPhase={this.handleAddPhase}
          phases={this.state.fields.fases}
          banks={(this.props.banks || {}).content || []}
          classifications={this.props.classifications || []}
          edited={this.state.edited}
        />
        {/* ------------------------------------------------------- */}
        <Row className="primaryTitle m-0 mb-2">
          <Col xs="10">
            <p className="m-0">Datos basicos</p>
          </Col>
        </Row>
        <Row>
          <Col xs={2} className="pr-0 pt-2">
            <BaseInput
              label={"Año"}
              name="anualidadAnio"
              id="anualidadAnio"
              type="select"
              value={this.state.fields.anio}
              options={[
                { value: moment().year(), label: moment().year() },
                { value: moment().year() + 1, label: moment().year() + 1 },
                { value: moment().year() + 2, label: moment().year() + 2 },
                { value: moment().year() + 3, label: moment().year() + 3 },
                { value: moment().year() + 4, label: moment().year() + 4 }
              ]}
              onChange={this.onChangeField("anio")}
            />
          </Col>
          <Col xs={2} className="pr-0 pt-2">
            <BaseInput
              label={"Tipo"}
              name="anualidadTipo"
              id="anualidadTipo"
              type="select"
              value={this.state.fields.tipo}
              options={[
                { value: ANUAL, label: "ANUAL" },
                { value: MESES_ROLADOS, label: "MESES ROLADOS" },
                { value: RESTO_ANIO, label: "RESTO AÑO" }
              ]}
              onChange={this.onChangeField("tipo")}
            />
          </Col>
          <Col xs={8} className="pr-0 pt-2">
            <BaseInput
              label={"Nombre:"}
              name="anualidadNombre"
              id="anualidadNombre"
              type="text"
              placeholder="Nombre"
              value={this.state.fields.nombre}
              onChange={this.onChangeField("nombre")}
            />
          </Col>
          <Col xs={4} className="pr-0 pt-2">
            <BaseNumericInput
              label={"Meses aplicables:"}
              name="anualidadMesesAplicables"
              id="anualidadMesesAplicables"
              type="text"
              placeholder="Meses aplicables"
              value={this.state.fields.mesesAplicables}
              onChange={this.onChangeField("mesesAplicables")}
            />
          </Col>
          <Col xs={4} className="pr-0 pt-2">
            <BaseInput
              label={"Estatus:"}
              name="anualidadEstatus"
              id="anualidadEstatus"
              type="text"
              placeholder="Estatus"
              value={this.state.fields.estatus}
              disabled={true}
            />
          </Col>
        </Row>

        {/* ------------------------------------------------------- */}
        <Row className="primaryTitle m-0 mb-2 mt-2">
          <Col xs="10">
            <p className="m-0">Clubes</p>
          </Col>
          <Col xs="2">
            <span
              className={`w-100`}
              onClick={e => {
                e.preventDefault();
                this.setState({ isClubSelectorModalOpen: true });
              }}
              style={{
                cursor: "pointer"
              }}
            >
              Seleccionar
            </span>
          </Col>
        </Row>
        <div
          className={`text-justify p-3 h-100 m-0 mb-2`}
          style={{ border: "solid 1px", fontSize: "12px" }}
        >
          {this.state.fields.clubesNombres}
        </div>

        {/* ------------------------------------------------------- */}
        <Row className="primaryTitle m-0 mb-2 mt-2">
          <Col xs="10">
            <p className="m-0">Mantenimientos</p>
          </Col>
          <Col xs="2">
            <span
              className={`w-100`}
              onClick={e => {
                e.preventDefault();
                this.setState({ isMaintenanceSelectorModalOpen: true });
              }}
              style={{
                cursor: "pointer"
              }}
            >
              Seleccionar
            </span>
          </Col>
        </Row>
        <div
          className={`text-justify p-3 h-100 m-0 mb-2`}
          style={{ border: "solid 1px", fontSize: "12px" }}
        >
          {this.state.fields.mantenimientosNombres}
        </div>

        {/* ------------------------------------------------------- */}
        <Row className="primaryTitle m-0 mb-2">
          <Col xs="10">
            <p className="m-0">Fases</p>
          </Col>
        </Row>
        <Container className="w-100 px-0 pt-2">
          <ReactTable
            data={this.state.fields.fases}
            columns={this.columns}
            className="-striped -highlight"
            sortable={false}
            showPagination={false}
            pageSize={(this.state.fields.fases || []).length + 1}
          />
        </Container>
        <Row className="primaryTitle m-0 mt-3 mb-2">
          <Col xs="10">
            <p className="m-0">Condiciones Legales</p>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <textarea
              className="form-control"
              rows={9}
              id="anualidadCondicionesLegales"
              name="anualidadCondicionesLegales"
              value={this.state.fields.condicionesLegales}
              onChange={this.onChangeField("condicionesLegales")}
            />
          </Col>
        </Row>

        <div className="text-right pr-0 pt-2 mt-4 mb-4">
          <Button
            className={`${s.buttonMarginTop} ${s.primaryButton} mr-5`}
            onClick={e => this.handleSubmit(e)}
            size="sm"
            disabled={this.state.saving}
          >
            Guardar
          </Button>

          <Button
            className={`${s.buttonMarginTop} ${s.primaryButton}`}
            onClick={e => this.props.history.push(`/anualidades`)}
            size="sm"
            disabled={this.state.saving}
          >
            Regresar
          </Button>
        </div>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(AnnuitiesConfig));
