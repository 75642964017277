import React from "react";
import { withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./styles.scss";
import BaseInput from "../../../toolkit/baseInput";
import ReactTable from "react-table";
import moment from "moment";
import { Container, Row, Col, Input, Button } from "reactstrap";
import CONSTANTS from "../../../utils/constants";
import { getSportsProductsRequest } from "../../actions/catalogs";
import cloneDeep from "lodash.clonedeep";
import MexicanCurrency from "../common/currency";

import ProductClassification from "../../containers/productClassification";
const NO_AUTORIZADOS = "4";
const formatDate = date => moment(date).format("DD/MM/YYYY");
const getCleanChecked = () => ({});

interface Props {
  getPricesToSportsProducts: (params: object) => any;
  authorizePricesMassive: (prices: object[]) => any;
  sportsProducts: { id: string; nombre: string }[];
  prices: object[];
  tiposPrecio: TipoPrecio[];
  loading: boolean;
}

interface TipoPrecio {
  esquemaPagoId: string;
  nombre: string;
}

class TabSportsProducts extends React.Component<Props> {
  renderEditable = cellInfo => {
    const { index, column } = cellInfo;
    const inputName = column.id;
    return (
      <div className={`${s.cellUnautorized} ${s.renderEditable}`}>
        <MexicanCurrency quantity={this.state.data[index][`${inputName}`]} />
      </div>
    );
  };

  renderSelector = (cellInfo, options) => {
    const { index, column } = cellInfo;
    const inputName = column.id;
    let opt = [
      {
        id: "",
        nombre: "Seleccionar"
      }
    ].concat(
      options
        ? options.map(({ cuentaContableId, cuentaProductoId, nombre }) => ({
            id: cuentaContableId ? cuentaContableId : cuentaProductoId,
            nombre
          }))
        : []
    );
    return (
      <div className={`${s.cell} ${s.renderEditable}`}>
        <Input
          type="select"
          className={s.renderEditableInput}
          value={this.state.data[index][`${inputName}`]}
          onChange={(e: any) => {
            e.preventDefault();
            const tmpData = [...this.state.data];
            tmpData[index][`${inputName}`] = e.target.value.replace(
              /[^0-9]/gi,
              ""
            );
            this.setState({ tmpData });
          }}
        >
          {opt.map((option, index) => {
            return (
              <option key={index} value={option.id}>
                {option.nombre}
              </option>
            );
          })}
        </Input>
      </div>
    );
  };

  state = {
    search: {},
    selectAll: false,
    filters: {
      productoClasificacionId: null,
      productoId: null,
      estatus: NO_AUTORIZADOS,
      tipoPago: "1"
    },
    sportsProducts: null,
    data: [],
    columns: [
      {
        Header: _ => {
          return (
            <div className={`${s.checkboxSelectAll} w-100 text-center`}>
              <Input
                type="checkbox"
                checked={this.state.selectAll}
                onChange={e => this.handleSelectAll(Boolean(e.target.checked))}
              />
            </div>
          );
        },
        id: "seleccionado",
        minWidth: 22,
        accessor: price => {
          return (
            <div className={`${s.checkboxContainer} ${s.myStyle}`}>
              <Row>
                <Col>
                  <Input
                    type="checkbox"
                    className={`${s.renderEditableCheckBox}`}
                    checked={
                      this.state.checked[
                        `${price.grupoId}-${price.tipoPagoId}-${price.productoId}-${price.id}`
                      ]
                    }
                    onChange={e =>
                      this.handleCheckPrice(
                        `${price.grupoId}-${price.tipoPagoId}-${price.productoId}-${price.id}`,
                        e
                      )
                    }
                  />
                </Col>
              </Row>
            </div>
          );
        }
      },
      {
        Header: "Producto",
        accessor: "productoNombre",
        width: 150
      },
      {
        Header: "Grupo",
        accessor: "nombre",
        minWidth: 60
      },
      {
        Header: "Usuario",
        accessor: "nombreUsuarioSolicitante"
      },
      {
        Header: "Clubes",
        accessor: "clubes",
        width: 250
      },
      {
        id: "inicioVigencia",
        Header: "Inicio de Vigencia",
        accessor: "fechaInicioVigencia",
        calssName: "text-center",
        minWidth: 60
      },
      {
        Header: "Precio Socio",
        accessor: "precioSocio",
        Cell: this.renderEditable
      },
      {
        Header: "Precio Empleado",
        accessor: "precioEmpleado",
        Cell: this.renderEditable
      },
      {
        Header: "Precio Publico",
        accessor: "precioPublico",
        Cell: this.renderEditable
      }
    ],
    checked: getCleanChecked()
  };

  componentDidMount() {}

  handleCheckPrice = (id, e) => {
    const value = Boolean(e.target.checked);
    const data = cloneDeep(this.state.data);
    const checked = this.state.checked;
    checked[id] = value;
    this.setState(state => {
      return { ...state, data, checked };
    });
  };

  componentWillReceiveProps(nextProps) {
    if (!this.props.prices && nextProps.prices) {
      const state = cloneDeep(this.state);
      this.setState({
        ...state,
        data: nextProps.prices,
        checked: getCleanChecked()
      });
    }
  }

  handleSelectAll = value => {
    const checked = this.state.checked;
    const data = cloneDeep(this.state.data);
    this.state.data.forEach(({ grupoId, tipoPagoId, productoId, id }) => {
      checked[`${grupoId}-${tipoPagoId}-${productoId}-${id}`] = value;
    });
    this.setState(state => ({
      ...state,
      data,
      checked,
      selectAll: value
    }));
  };

  refreshPrices = async actionPromise => {
    return actionPromise
      .then(() => {
        return this.props.getPricesToSportsProducts(this.state.filters);
      })
      .then(({ value: { data } }) => {
        this.setState(state => {
          return {
            ...state,
            data: data.map(price => {
              const newPrice = { ...price };
              if (!newPrice.precioEmpleado) {
                newPrice.precioEmpleado = 0;
              }
              if (!newPrice.precioSocio) {
                newPrice.precioSocio = 0;
              }
              if (!newPrice.precioPublico) {
                newPrice.precioPublico = 0;
              }
              return newPrice;
            }),
            checked: getCleanChecked()
          };
        });
      });
  };

  handleSubmit = e => {
    e.preventDefault();
    const checked = this.state.checked;
    const { data } = this.state;
    const pricesToAuthorize = data
      .filter(
        ({ grupoId, tipoPagoId, productoId, estatusId, id }) =>
          checked[`${grupoId}-${tipoPagoId}-${productoId}-${id}`] &&
          estatusId == NO_AUTORIZADOS
      )
      .map(({ grupoId, tipoPagoId, productoId, id }) => {
        return {
          precioId: id,
          grupoId,
          tipoPagoId,
          productoId
        };
      });
    this.refreshPrices(this.props.authorizePricesMassive(pricesToAuthorize));
  };

  search = () => {
    const { filters } = this.state;
    this.setState(() => ({ checked: getCleanChecked() }));
    this.props.getPricesToSportsProducts(filters);
  };

  changeFilter = (e, name) => {
    const value = e.target.value;
    this.setState(state => {
      const { filters } = this.state;
      filters[name] = value;
      return { ...state, filters };
    });
  };

  onChangeProductClassification = (classificationId, parents, level) => {
    const { filters } = this.state;
    filters.productoClasificacionId =
      level >= 3 ? classificationId || null : null;
    this.setState({ filters: { ...filters } });

    if (!!filters.productoClasificacionId) {
      getSportsProductsRequest(filters.productoClasificacionId, null).then(
        ({ data }) => {
          this.setState({ sportsProducts: data });
        }
      );
    } else {
      this.setState({ sportsProducts: null });
    }
  };

  getProducts = () => {
    return this.state.sportsProducts || this.props.sportsProducts;
  };

  render() {
    return (
      <Container className="p-0 mt-3">
        <Row>
          <Col className="pr-0">
            <ProductClassification
              setResult={this.onChangeProductClassification}
            ></ProductClassification>
          </Col>
        </Row>
        <Row>
          <Col>
            <BaseInput
              label={"Producto"}
              name={"id"}
              type="select"
              id={"id"}
              placeholder="Producto"
              value={this.state.filters.productoId}
              size="sm"
              onChange={e => this.changeFilter(e, "productoId")}
              options={[{ value: "", label: "Seleccione el Producto" }].concat(
                this.getProducts().map(option => ({
                  value: option.id,
                  label: option.nombre.toUpperCase()
                }))
              )}
            />
          </Col>
          <Col>
            <BaseInput
              label={"Tipo de Precio"}
              name={"tipoPagoId"}
              type="select"
              id={"tipoPagoId"}
              placeholder="Tipo de Precio"
              value={this.state.filters.tipoPago}
              size="sm"
              onChange={e => this.changeFilter(e, "tipoPago")}
              options={[
                { value: "", label: "Seleccione el Tipo de Precio" }
              ].concat(
                this.props.tiposPrecio.map(option => ({
                  value: option.esquemaPagoId,
                  label: option.nombre
                }))
              )}
            />
          </Col>
          <Col>
            <Button
              size="sm"
              className={`${s.searchButton} ${s.primaryButton} float-right btn-block`}
              onClick={this.search}
              disabled={
                !this.state.filters.estatus || !this.state.filters.tipoPago
              }
            >
              Buscar
            </Button>
          </Col>
        </Row>
        <hr />
        <ReactTable
          manual
          data={this.props.loading ? [] : this.state.data}
          columns={this.state.columns}
          className={`${
            this.state.data.length > 0 ? "" : s.tableMinHeight
          } w-100 -striped -highlight tablePrices`}
          loading={this.props.loading}
          sortable={false}
          showPagination={false}
          defaultPageSize={3}
          resizable={false}
          style={{
            minHeight: `${
              this.state.data.length > 0 ? "100px" : CONSTANTS.TABLES_HEIGHT
            }`
          }}
        />
        <Col xs={2} className="float-right pr-0 pt-2">
          <Button
            className={`${s.buttonMarginTop} ${s.primaryButton} float-right`}
            onClick={e => this.handleSubmit(e)}
            size="sm"
            block
          >
            {"Autorizar"}
          </Button>
        </Col>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(TabSportsProducts));
