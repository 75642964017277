import React from "react";
import { Button } from "reactstrap";

export default ({ onClick, disabled }) => (
  <Button
    className="primaryButton"
    onClick={e => {
      e.preventDefault();
      onClick();
    }}
    size="sm"
    disabled={disabled}
    block
  >
    Registrar Nueva Actividad
  </Button>
);
