import {
  faDoorClosed,
  faFileExport,
  faTimes,
  faWarehouse
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import concat from "lodash.concat";
import toNumber from "lodash.tonumber";
import React from "react";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import {
  Button,
  Col,
  Container,
  Input,
  Row,
  UncontrolledTooltip
} from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import ModalFrame from "../../../toolkit/modalFrame";
import ClubCatalogSelect from "../sportsProductsNewQuote/ClubCatalogSelect";
import {
  ALTURA_LOCKER,
  opcionesAltura,
  opcionesTamanoLocker,
  opcionesVestidores,
  Props,
  State,
  TAMANO_LOCKER,
  VESTIDORES,
  LOCKER_CONFIG
} from "./interfaces";
import s from "./styles.scss";

class LockersConfiguration extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      club: null,
      vestidor: VESTIDORES.DAMA,
      lockers: [],
      deleted: [],
      isWallOpen: false,
      isLockerOpen: false,
      tamanoLocker: TAMANO_LOCKER.PEQUENO,
      alturaLocker: ALTURA_LOCKER.ABAJO,
      pared: "",
      paredId: -1,
      locker: "",
      loading: false,
      tamano: {
        [TAMANO_LOCKER.GRANDE]: 0,
        [TAMANO_LOCKER.MEDIANO]: 0,
        [TAMANO_LOCKER.PEQUENO]: 0
      },
      disabledButton: false
    };
  }

  selectClub = club => {
    if (club.value == 0) {
      this.setState({ club, disabledButton: true });
    } else {
      this.setState({ club, loading: true, disabledButton: false });
      this.getLockersByClub(club.value);
    }
  };

  selectVestidor = e => {
    const vestidor = e.target.value;
    this.setState({ vestidor });
  };

  selectTamano = e => {
    const tamanoLocker = e.target.value;
    this.setState({ tamanoLocker });
  };

  selectAltura = e => {
    const alturaLocker = e.target.value;
    this.setState({ alturaLocker });
  };

  toggleWall = () => {
    this.setState({ isWallOpen: !this.state.isWallOpen });
  };

  toggleLocker = () => {
    this.setState({ isLockerOpen: !this.state.isLockerOpen });
  };

  getLockersFile = e => {
    e.preventDefault();
    this.props.getListFile();
  };

  setPared = e => {
    const pared = e.target.value;
    this.setState({
      pared,
      paredId: -1
    });
  };

  selectPared = e => {
    const paredId = parseInt(e.target.options[e.target.selectedIndex].value);
    const pared = e.target.options[e.target.selectedIndex].text;

    this.setState({
      pared: !!paredId && paredId > 0 ? pared : "",
      paredId: paredId
    });
  };

  setLocker = e => {
    const locker = e.target.value;
    this.setState({
      locker
    });
  };

  setTamano = (tipoAltura: TAMANO_LOCKER) => {
    return e => {
      const value = e.target.value;
      this.setState({
        ...this.state,
        tamano: {
          ...this.state.tamano,
          [tipoAltura]: value
        }
      });
    };
  };

  getWalls = () => {
    const { vestidor } = this.state;
    let walls = [];
    let wallsKeys = [];
    this.state.lockers
      .filter(locker => locker.vestidor == (vestidor || ""))
      .filter(locker => {
        const paredItem = {
          pared: locker.pared,
          paredId: locker.paredId,
          vestidor: locker.vestidor
        };

        if (wallsKeys.includes(paredItem.paredId)) {
          return false;
        } else {
          walls.push(paredItem);
          wallsKeys.push(paredItem.paredId);
          return true;
        }
      });

    return [
      { label: "Seleccione", value: -1 },
      ...walls.map(e => {
        return { label: e.pared, value: e.paredId };
      })
    ];
  };

  getLockersByClub = (clubId: number) => {
    this.props.getLockers(clubId).then(({ value: { data } }) => {
      this.setState({
        lockers: data.lockerList.map(
          ({
            id,
            vestidor,
            pared,
            paredId,
            altura,
            tamano,
            numero,
            esAsignable,
            estatus
          }) => ({
            id,
            vestidor,
            pared,
            paredId,
            altura,
            tamano,
            numero,
            noAsignable: !esAsignable,
            estatus
          })
        ),
        loading: false
      });
    });
  };

  loadSingleLocker = () => {
    const newLocker = {
      id: -1,
      vestidor: this.state.vestidor,
      pared: this.state.pared,
      paredId: this.state.paredId,
      altura: this.state.alturaLocker,
      noAsignable: false,
      numero: this.state.locker,
      tamano: this.state.tamanoLocker
    };
    this.setState({
      lockers: concat(this.state.lockers, [newLocker]),
      isLockerOpen: false,
      locker: ""
    });
  };

  loadLockers = () => {
    const grande = toNumber(this.state.tamano[TAMANO_LOCKER.GRANDE]);
    const mediano = toNumber(this.state.tamano[TAMANO_LOCKER.MEDIANO]);
    const pequeno = toNumber(this.state.tamano[TAMANO_LOCKER.PEQUENO]);

    const emptyLocker = {
      vestidor: this.state.vestidor,
      pared: this.state.pared,
      paredId: this.state.paredId,
      altura: ALTURA_LOCKER.ARRIBA
    };

    const lockers = [];
    for (let i = 0; i < grande; i++) {
      lockers.push({
        ...emptyLocker,
        tamano: TAMANO_LOCKER.GRANDE
      });
    }

    for (let i = 0; i < mediano; i++) {
      lockers.push({
        ...emptyLocker,
        tamano: TAMANO_LOCKER.MEDIANO
      });
    }

    for (let i = 0; i < pequeno; i++) {
      lockers.push({
        ...emptyLocker,
        tamano: TAMANO_LOCKER.PEQUENO
      });
    }
    this.setState({
      lockers: concat(this.state.lockers, lockers),
      isWallOpen: false,

      vestidor: VESTIDORES.DAMA,
      pared: "",
      tamano: {
        [TAMANO_LOCKER.GRANDE]: 0,
        [TAMANO_LOCKER.MEDIANO]: 0,
        [TAMANO_LOCKER.PEQUENO]: 0
      }
    });
  };

  setValue = (index, id, value) => {
    const lockers = [...this.state.lockers];
    lockers[index][id] = value;
    this.setState({ ...this.state, lockers });
  };

  toggleWallCheckedChanged = (index, id) => {
    const lockers = [...this.state.lockers];
    const checked = !lockers[index][id];
    lockers[index][id] = checked;
    this.setState({ ...this.state, lockers });
  };

  renderInputAltura = ({ index, column: { id } }) => {
    return (
      <Input
        type="select"
        className={`${s.renderEditableInput} my-1`}
        value={this.state.lockers[index][id]}
        onChange={e => {
          const value = e.target.value;
          this.setValue(index, id, value);
        }}
      >
        {opcionesAltura.map(({ value, label }, index) => (
          <option key={index} value={value} className="text-center">
            {label}
          </option>
        ))}
      </Input>
    );
  };

  renderInputNumero = ({ index, column: { id } }) => {
    return (
      <Input
        type="text"
        className={`${s.renderEditableInput} my-1`}
        required
        value={this.state.lockers[index][id]}
        onChange={e => {
          const value = e.target.value;
          this.setValue(index, id, value);
        }}
      />
    );
  };

  renderInputNoAsignable = ({ index, column: { id } }) => {
    return (
      <div className="text-center">
        <input
          type="checkbox"
          id={`checkbox${index}`}
          style={{ marginTop: "0.75rem" }}
          checked={this.state.lockers[index][id]}
          onChange={() => {
            this.toggleWallCheckedChanged(index, id);
          }}
        />
      </div>
    );
  };

  deleteLocker = index => {
    const lockers = this.state.lockers;
    const deleted = lockers.splice(index, 1);

    this.setState({
      lockers: [...lockers],
      deleted: [...this.state.deleted, ...deleted]
    });
  };

  saveLockers = () => {
    const lockerConfig: LOCKER_CONFIG = {
      clubId: this.state.club.value,
      lockerList: [
        ...this.state.lockers.map(
          ({
            vestidor,
            tamano,
            altura,
            pared,
            paredId,
            id,
            numero,
            noAsignable
          }) => ({
            id,
            vestidor,
            nombre: pared,
            paredId,
            size: tamano,
            posicion: altura,
            numero,
            esAsignable: !noAsignable
          })
        ),
        ...this.state.deleted.map(
          ({
            vestidor,
            tamano,
            altura,
            pared,
            paredId,
            id,
            numero,
            noAsignable
          }) => ({
            id,
            vestidor,
            nombre: pared,
            paredId,
            size: tamano,
            posicion: altura,
            numero,
            esAsignable: !noAsignable,
            eliminar: true
          })
        )
      ]
    };

    if (lockerConfig.lockerList.filter(it => !!!it.numero).length > 0) {
      return;
    }

    this.props.saveLockersConfig(lockerConfig).then(() => {
      const { club } = this.state;
      this.getLockersByClub(club.value);
    });
  };

  componentDidMount = () => {
    const clubId =
      this.props.userContext && this.props.userContext.clubSessionId;
    this.setState({
      club: clubId
        ? {
            value: clubId,
            label: ""
          }
        : null
    });
  };

  render() {
    return (
      <Container className="p-0 mt-3">
        <ModalFrame
          title="Agregar locker de pared"
          isOpen={this.state.isLockerOpen}
          toggle={this.toggleLocker}
        >
          <Container>
            <Row>
              <Col>
                <BaseInput
                  label="Vestidor"
                  name="seleccionarVestidor"
                  type="select"
                  id="seleccionarVestidor"
                  placeholder="Vestidor"
                  value={this.state.vestidor}
                  options={opcionesVestidores}
                  onChange={this.selectVestidor}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <BaseInput
                  label="Pared"
                  name="nombrePared"
                  type="select"
                  id="nombrePared"
                  placeholder="Pared"
                  options={this.getWalls()}
                  value={this.state.paredId}
                  onChange={this.selectPared}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <BaseInput
                  label="Número de locker"
                  name="nombreLocker"
                  type="text"
                  id="nombreLocker"
                  placeholder="Número de locker"
                  value={this.state.locker}
                  onChange={this.setLocker}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <BaseInput
                  label="Tamaño de locker"
                  name="tamanoLocker"
                  type="select"
                  id="tamanoLocker"
                  placeholder="Tamaño de locker"
                  value={this.state.tamanoLocker}
                  options={opcionesTamanoLocker}
                  onChange={this.selectTamano}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <BaseInput
                  label="Altura de locker"
                  name="alturaLocker"
                  type="select"
                  id="alturaLocker"
                  placeholder="Altura de locker"
                  value={this.state.alturaLocker}
                  options={opcionesAltura}
                  onChange={this.selectAltura}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  className={`${s.primaryButton} mt-3`}
                  onClick={this.loadSingleLocker}
                  disabled={
                    !(
                      this.state.vestidor &&
                      (this.state.paredId && this.state.paredId > 0) &&
                      this.state.locker
                    )
                  }
                  block
                >
                  Añadir
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalFrame>
        <ModalFrame
          title="Agregar pared"
          isOpen={this.state.isWallOpen}
          toggle={this.toggleWall}
        >
          <Container>
            <Row>
              <Col>
                <BaseInput
                  label="Vestidor"
                  name="seleccionarVestidor"
                  type="select"
                  id="seleccionarVestidor"
                  placeholder="Vestidor"
                  value={this.state.vestidor}
                  options={opcionesVestidores}
                  onChange={this.selectVestidor}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <BaseInput
                  label="Pared"
                  name="nombrePared"
                  type="text"
                  id="nombrePared"
                  placeholder="Pared"
                  value={this.state.pared}
                  onChange={this.setPared}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <BaseInput
                  label={TAMANO_LOCKER.GRANDE}
                  name="lockerGRANDE"
                  type="number"
                  id="lockerGRANDE"
                  value={this.state.tamano[TAMANO_LOCKER.GRANDE]}
                  onChange={this.setTamano(TAMANO_LOCKER.GRANDE)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <BaseInput
                  label={TAMANO_LOCKER.MEDIANO}
                  name="lockerMEDIANO"
                  type="number"
                  id="lockerMEDIANO"
                  value={this.state.tamano[TAMANO_LOCKER.MEDIANO]}
                  onChange={this.setTamano(TAMANO_LOCKER.MEDIANO)}
                  className="w-100"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <BaseInput
                  label={TAMANO_LOCKER.PEQUENO}
                  name="lockerPEQUENO"
                  type="number"
                  id="lockerPEQUENO"
                  value={this.state.tamano[TAMANO_LOCKER.PEQUENO]}
                  onChange={this.setTamano(TAMANO_LOCKER.PEQUENO)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  className={`${s.primaryButton} mt-3`}
                  onClick={this.loadLockers}
                  disabled={
                    !(
                      this.state.vestidor &&
                      this.state.pared &&
                      (!!this.state.tamano[TAMANO_LOCKER.GRANDE] ||
                        !!this.state.tamano[TAMANO_LOCKER.MEDIANO] ||
                        !!this.state.tamano[TAMANO_LOCKER.PEQUENO])
                    )
                  }
                  block
                >
                  Añadir
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalFrame>
        <Row>
          <Col>
            <ClubCatalogSelect
              clubs={this.props.clubs}
              club={this.state.club}
              onSelectClub={this.selectClub}
              label="Seleccionar club"
              userClub={
                this.props.userContext && this.props.userContext.clubSessionId
              }
            />
          </Col>
          <Col xs={1} className="pt-4">
            <div className="w-100 text-center">
              <Button
                id="btn-add-locker"
                color="link"
                onClick={this.toggleLocker}
                className={`${s.innerButton} ${s.primaryLink}`}
                disabled={this.state.lockers.length < 1}
              >
                <FontAwesomeIcon icon={faDoorClosed} />
              </Button>
              <UncontrolledTooltip placement="bottom" target={`btn-add-locker`}>
                Agregar locker
              </UncontrolledTooltip>
            </div>
          </Col>
          <Col xs={1} className="pt-4">
            <div className="w-100 text-center">
              <Button
                id="btn-add-pared"
                color="link"
                onClick={this.toggleWall}
                className={`${s.innerButton} ${s.primaryLink}`}
                disabled={this.state.disabledButton}
              >
                <FontAwesomeIcon icon={faWarehouse} />
              </Button>
              <UncontrolledTooltip placement="bottom" target={`btn-add-pared`}>
                Agregar pared
              </UncontrolledTooltip>
            </div>
          </Col>
          <Col xs={1} className="pt-4">
            <div className="w-100 text-center">
              <Button
                id="btn-exportar"
                color="link"
                onClick={this.getLockersFile}
                className={`${s.innerButton} ${s.primaryLink}`}
                disabled={this.state.disabledButton}
              >
                <FontAwesomeIcon icon={faFileExport} />
              </Button>
              <UncontrolledTooltip placement="bottom" target={`btn-exportar`}>
                Exportar archivo
              </UncontrolledTooltip>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              className="form-row"
              style={{
                height: "calc(100vh - 300px)",
                overflowY: "auto",
                overflowX: "hidden",
                border: "1px solid #ccc",
                minHeight: "300px",
                marginTop: "15px"
              }}
            >
              <ReactTable
                data={this.state.lockers}
                columns={[
                  {
                    Header: "VESTIDOR",
                    accessor: "vestidor",
                    className: "text-center",
                    width: 100
                  },
                  {
                    Header: "PARED",
                    accessor: "pared",
                    className: "text-center",
                    width: 100
                  },
                  {
                    Header: "TAMANO",
                    accessor: "tamano",
                    className: "text-center",
                    width: 100
                  },
                  {
                    Header: "ALTURA",
                    id: "altura",
                    className: "p-0",
                    Cell: this.renderInputAltura
                  },
                  {
                    Header: "NUMERO",
                    id: "numero",
                    className: "p-0",
                    Cell: this.renderInputNumero
                  },
                  {
                    Header: "NO ASIGNABLE",
                    id: "noAsignable",
                    className: "p-0",
                    Cell: this.renderInputNoAsignable
                  },
                  {
                    Header: "ESTATUS",
                    accessor: "estatus",
                    className: "text-center",
                    width: 100
                  },
                  {
                    Header: "",
                    id: "acciones",
                    width: 30,
                    Cell: ({ index }) => {
                      return (
                        <Container className="p-1">
                          <Row>
                            <Col className={s.marginLeftNil} xs={2}>
                              <a
                                id={`locker${index}`}
                                onClick={() => this.deleteLocker(index)}
                                style={{ cursor: "pointer" }}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </a>
                            </Col>
                          </Row>
                        </Container>
                      );
                    }
                  }
                ]}
                pageSize={
                  this.state.lockers.length && this.state.lockers.length > 15
                    ? this.state.lockers.length
                    : 15
                }
                sortable={false}
                showPagination={false}
                className="-highlight w-100"
                getTbodyProps={() => ({
                  style: { backgroundColor: "#EAECEE" }
                })}
                loading={this.state.loading}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col />
          <Col xs={2}>
            <Button
              className={`${s.primaryButton} mt-3`}
              onClick={this.saveLockers}
              disabled={!this.state.lockers.length || !this.state.club.value}
              block
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(LockersConfiguration));
