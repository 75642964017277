export const membresiasCrm1Fixture = [
  {
    membresiaSocioId: 419599,
    personaId: 1415775,
    clubId: 75,
    membresiaId: 6371,
    estatus: 1,
    nombre: "DANIEL ISAAC",
    primerApellido: "LUNA",
    segundoApellido: "VEGA",
    fechaNacimiento: "1992-04-01",
    huella: "",
    foto: "",
    correos: ["punisher1759@hotmail.com", "punisher1759@hotmail.com"]
  },
  {
    membresiaSocioId: 419599,
    personaId: 2750409,
    clubId: 75,
    membresiaId: 6371,
    estatus: 1,
    nombre: "IVONNE ODETTE YISSELE",
    primerApellido: "PANTOJA",
    segundoApellido: "ISLAS",
    fechaNacimiento: "1979-09-04",
    huella: "",
    foto: "",
    correos: ["ioypi@yahoo.com"]
  },
  {
    membresiaSocioId: 203656,
    personaId: 1022006,
    clubId: 75,
    membresiaId: 2470,
    estatus: 1,
    nombre: "RAUL ANTONIO",
    primerApellido: "GONZALEZ",
    segundoApellido: "LOPEZ",
    fechaNacimiento: "1968-07-04",
    huella: "",
    foto: "",
    correos: ["antoniog2010@yahoo.com.mx"]
  }
];
