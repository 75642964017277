import React from "react";
import { connect } from "react-redux";
import Layout from "../../components/layout";
import { Helmet } from "react-helmet";
import MailContactPage from "../../components/mailContactPage";
import { getClubs } from "../../actions/catalogs";
import {
  createCodes,
  clearCodes,
  getPassDayList,
  activateDayPass
} from "../../actions/dicounts";
import { DayPass } from "../../components/mailContactPage/interfaces";
import { clearSelectedPerson } from "../../actions/persons";
import { getBasicData, getDocumentsGuest } from "../../actions/documents";

interface Props {
  clubs: object;
  getClubs: () => any;
  createCodes: (data: object) => any;
  clearCodes: () => any;
  discounts: object;
  selectedPerson: object;
  getPassDayList: (idPersona: number) => any;
  activateDayPass: (dayPass: DayPass) => any;
  clearSelectedPerson: () => any;
  getDocumentsGuest: () => any;
  documents: object[];
}

interface State {}

class MailContact extends React.Component<Props, State> {
  state = {};
  componentDidMount() {
    this.props.getClubs();
    this.props.getDocumentsGuest();
  }

  render() {
    return (
      <main>
        <Helmet title="Activación de pase por un día" />
        <Layout>
          {this.props.clubs && (
            <MailContactPage
              discounts={this.props.discounts}
              getPassDayList={this.props.getPassDayList}
              activateDayPass={this.props.activateDayPass}
              clearCodes={this.props.clearCodes}
              clearSelectedPerson={this.props.clearSelectedPerson}
              documents={this.props.documents}
              clubs={this.props.clubs}
            />
          )}
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getPassDayList: idPersona => {
    return dispatch(getPassDayList(idPersona));
  },
  clearCodes: () => {
    return dispatch(clearCodes());
  },
  createCodes: data => {
    return dispatch(createCodes(data));
  },
  getClubs: () => {
    return dispatch(getClubs());
  },
  activateDayPass: (dayPass: DayPass) => {
    return dispatch(activateDayPass(dayPass));
  },
  clearSelectedPerson: () => {
    return dispatch(clearSelectedPerson());
  },
  getDocument: (id: string) => {
    return dispatch(getBasicData(id));
  },
  getDocumentsGuest: () => {
    return dispatch(getDocumentsGuest());
  }
});

const mapStateToProps = state => {
  const {
    catalogs: { clubs },
    discounts,
    documents: { guestDocuments }
  } = state;
  return {
    clubs,
    discounts,
    documents: guestDocuments
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MailContact);
