import React from "react";
import IInput from "../iInput/IInput";
import IMultipleCheckProps from "./IMultipleCheck.props";
import IMultipleCheckState from "./IMultipleCheck.state";
import { Row } from "reactstrap";

export default class IMultipleCheck extends IInput<
  IMultipleCheckProps,
  IMultipleCheckState
> {
  state = {
    value: "",
    options: [] as { value: string; label: string }[]
  };

  componentDidMount(): void {
    this.setState(
      {
        value: this.props.init || []
      },
      () => super.triggerInput()
    );

    this.mapOptions();
  }

  componentDidUpdate(prevProps): void {
    if (this.props.init != prevProps.init) {
      this.setState({
        value: this.props.init
      });
    }

    if (this.props.data != prevProps.data) {
      this.mapOptions();
    }
  }

  mapOptions() {
    const data = (this.props.data && this.props.data) || [];
    const options = this.props.mapOption
      ? data.map(this.props.mapOption)
      : data;

    this.setState({ options });
  }

  onChange = value => {
    this.props.setter(this.props.name, value);
    this.props.onChange && this.props.onChange(value);
    this.setState({ value });
  };

  // Seleccionar todas las opciones
  onCheckAll = evt => {
    const checked = evt.target.checked ? true : false;
    const selected = checked
      ? [...(this.state.options || []).map(c => c.value)]
      : [];

    this.onChange(selected);
  };

  // Selección de una opción
  onCheckOption = (evt, optionValue) => {
    const checked = evt.target.checked ? true : false;
    const selected = [...(this.state.value || [])];
    const index = selected.indexOf(optionValue);

    if (checked && index === -1) {
      selected.push(optionValue);
    } else if (!checked && index !== -1) {
      selected.splice(index, 1);
    }

    this.onChange(selected);
  };

  render() {
    if (this.props.hidden) {
      return null;
    }

    return (
      <div className={this.btsClassName()}>
        {!!this.props.label && (
          <p className="primaryTitle w-100">{this.props.label}</p>
        )}

        {!!this.props.addSelectAll && (
          <>
            <div className={`form-row`}>
              <div className="col-3 mt-2">
                <div style={{ display: "inline-block", marginRight: "8px" }}>
                  <input
                    id="all-clubes"
                    onChange={this.onCheckAll}
                    type="checkbox"
                    style={{
                      position: "unset",
                      margin: "0px",
                      marginLeft: "0px"
                    }}
                  />
                </div>
                <label htmlFor="all-clubes" style={{ display: "inline-block" }}>
                  Seleccionar todos
                </label>
              </div>
            </div>

            <hr />
          </>
        )}

        {!!this.props.dirty && !!this.props.message && (
          <div className="alert alert-danger mb-3">{this.props.message}</div>
        )}

        <Row>{this.renderOptions()}</Row>
      </div>
    );
  }

  renderOptions() {
    if (!this.state.options || this.state.options.length == 0) {
      return "Espere un momento...";
    }

    return this.state.options.map(this.renderOption);
  }

  renderOption = (option: { value: string; label: string }) => {
    const key = `${this.props.name}-option-ls-${option.value}`;
    const id = `${this.props.name}-option-check-${option.value}`;
    return (
      <div key={key} className="col-3 mt-2">
        <div
          id={id}
          style={{
            display: "inline-block",
            marginRight: "8px",
            paddingTop: "1px",
            verticalAlign: "top"
          }}
        >
          <input
            onChange={evt => this.onCheckOption(evt, option.value)}
            type="checkbox"
            checked={(this.state.value || []).indexOf(option.value) >= 0}
            style={{
              position: "unset",
              margin: "0px",
              marginLeft: "0px"
            }}
          />
        </div>
        <label
          htmlFor={id}
          style={{
            display: "inline-block",
            width: "calc(100% - 24px)",
            fontSize: "12px"
          }}
        >
          {option.label}
        </label>
      </div>
    );
  };
}
