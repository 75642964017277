import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import ModalFrame from "../../../../toolkit/modalFrame";

interface Props {
  isOpen: boolean;
  toggle: () => any;
  schema: SchemaBenefits;
}

interface SchemaBenefits {
  esquemaId: string;
  nombre: string;
  esquema: object[];
}
interface State {}

class BenefitsCalculatorModal extends React.Component<Props, State> {
  state = {};
  componentDidMount() {}

  render() {
    return (
      <ModalFrame
        title={"Calculadora de beneficios"}
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        size="lg"
      >
        <div className="container h-100 w-100 p-0 m-0">
          <div className="row">
            <div className="col">
              <div>Id: {this.props.schema && this.props.schema.esquemaId}</div>
              <div>
                Esquema: {this.props.schema && this.props.schema.nombre}
              </div>
            </div>
          </div>
        </div>
      </ModalFrame>
    );
  }
}

export default withRouter(withStyles(s)(BenefitsCalculatorModal));
