import React from "react";
import IEmpresaConvenioSelectorBuilderProps from "./IEmpresaConvenioSelectorBuilder.props";
import IEmpresaConvenioSelectorBuilderState from "./IEmpresaConvenioSelectorBuilder.state";
import IInputBuilder from "../../baseForms2/iInputBuilder/IInputBuilder";
import IEmpresaConvenioSelectorConnected from "../iEmpresaConvenioSelector/IEmpresaConvenioSelectorConnected";

export default class IEmpresaConvenioSelectorBuilder<
  INP extends string
> extends IInputBuilder<
  INP,
  IEmpresaConvenioSelectorBuilderProps<INP>,
  IEmpresaConvenioSelectorBuilderState
> {
  render() {
    return <IEmpresaConvenioSelectorConnected {...(this.props as any)} />;
  }
}
