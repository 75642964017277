import React from "react";
import { ReduxConnect } from "../../../toolkit/decorators/Artifact";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import {
  claseSinComisionStore,
  ClaseSinComisionStore
} from "./ClaseSinComision.store";
import {
  claseSinComisionDispatcher,
  ClaseSinComisionDispatcher
} from "./ClaseSinComision.dispatcher";
import ClaseSinComision from "../../components/clasesSinComision";

interface Props extends ClaseSinComisionStore, ClaseSinComisionDispatcher {}

interface State {}

@ReduxConnect(claseSinComisionStore, claseSinComisionDispatcher)
export default class ClaseSinComisionPage extends React.Component<
  Props,
  State
> {
  render() {
    return (
      <main>
        <Helmet title="Agreements" />
        <Layout>
          <ClaseSinComision {...this.props} />
        </Layout>
      </main>
    );
  }
}
