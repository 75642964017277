export interface Profile {
  email: string;
  firstName: string;
  lastName: string;
  photoUrl: string;
  profileId?: string;
  userId?: number;
}

export interface Account {
  userId?: number;
  username: string;
  password?: string;
  domain: string;
  groups: string[];
}

export interface UserContext {
  account: Account;
  profile: Profile;
}

export interface UserInfo {
  userId: string;
  username: string;
  name: string;
  domain: string;
  status: string;
  lastestLoginDate: string;
  createdDate: string;
}

export interface SSP {
  ssp: Array<object>;
}

export enum WHO_AM_I_STATE {
  SUCCESS,
  FAILED,
  PROGRESS,
  EMPTY
}
