export interface Agreement {
  nombre: string;
  responsableId: string;
  inicioVigencia: string;
  fechaFinVigencia: string;
  sinFechaFinDeVigencia: string;
  activo: string;
  bloqueoVenta: string;
  numeroMaximoConvenio: string;
  giro: string;
  rangoActivo: string;
  convenioId: string;
}

export enum PRICE_TYPES {
  PORCENTAJE = "PORCENTAJE",
  DESCUENTOABS = "DESCUENTOABS",
  PRECIOFIJO = "PRECIOFIJO",
  NA = "NA"
}

export interface BENEFITS {
  integrante: number;
  valor: number;
}

export interface AGREEMENT_MONTH_PRICE {
  tipoBeneficioMes: PRICE_TYPES;
  mes: number;
  posicion: number;
  esUltimoMesRecurrente: boolean;
  beneficios: BENEFITS[];
}

export interface AGREEMENT_PRICE {
  promocion: string;
  membresia: string;
  numeroDeIntegrantes: number;
  tipoBeneficioMembresia: PRICE_TYPES;
  valorBeneficioMembresia: number;
  aplicaProporcional: boolean;
  mesRelativo: boolean;
  meses: AGREEMENT_MONTH_PRICE[];
}
