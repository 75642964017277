import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Banks from "../../components/banks";
import { Banco } from "../../../types/Banks";
import * as bankActions from "../../actions/banks";
import * as modalsActions from "../../actions/modals";
import { QueryResponse } from "../../../types/QueryResponse";
import { QueryArguments } from "../../../types-business/QueryArguments";

interface Props {
  banks: QueryResponse<Banco>;
  getBanks: (args?: QueryArguments) => any;
  searching: boolean;
  toggleCreateBankModal: (bank) => any;
  reload: boolean;
}

class BanksPage extends React.Component<Props> {
  state = {
    size: 0,
    page: 0
  };

  onFetchData = ({ pageSize: size, page }) => {
    this.props.getBanks({ size, page });
    this.setState(prevState => {
      return {
        ...prevState,
        size,
        page
      };
    });
  };

  componentDidUpdate() {
    if (this.props.reload) {
      this.props.getBanks(this.state);
    }
  }

  render() {
    return (
      <main>
        <Helmet title="Bancos" />
        <Layout>
          <Banks
            banks={this.props.banks}
            fetchData={this.onFetchData}
            searching={this.props.searching}
            onClickNewBank={this.props.toggleCreateBankModal}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getBanks: (args?: QueryArguments) => {
    dispatch(bankActions.getBanks(args));
  },
  toggleCreateBankModal: bank => {
    dispatch(modalsActions.toggleCreateBankModal(bank));
  }
});

const mapStateToProps = state => {
  const { banks } = state;
  return {
    banks: banks.all || { data: [], totalPages: 0 },
    searching: banks.gettingBanks,
    reload: banks.reloadBanks
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BanksPage);
