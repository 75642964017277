import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { showDocument } from "../../actions/documents";
import { savePaidComplement } from "../../actions/billing";
import Layout from "../../components/layout";
import * as reportsActions from "../../actions/reports";
import ListPaymentPluginValidation from "../../components/listPaymentPluginValidation";
import IAction from "../../../types/IAction";

interface Props {
  getSalesPayable: (
    page: string,
    size: string,
    fechaDesde: string,
    fechaHasta: string,
    folio: string
  ) => IAction;
  toggleBill: (storageId: number) => any;
  reportSalesPayable: any;
  savePaidComplement: (data: object, uuid: string) => any;
  billing: boolean;
}

interface State {}

class PaymentPluginValidation extends React.Component<Props, State> {
  state = {};

  componentDidMount() {}

  render() {
    return (
      <main>
        <Helmet title="Shift delivery" />
        <Layout headerLabel={"Validación de pagos y complementos"}>
          <ListPaymentPluginValidation
            getSalesPayable={this.props.getSalesPayable}
            toggleBill={this.props.toggleBill}
            reportSalesPayable={this.props.reportSalesPayable}
            savePaidComplement={this.props.savePaidComplement}
            billing={this.props.billing}
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({ reports, billing }) => {
  return {
    billing: billing.successSaveComplement,
    reportSalesPayable: reports.reportSalesPayable
  };
};

const mapDispatchToProps = dispatch => ({
  toggleBill: showDocument,
  savePaidComplement: (data: object, uuid: string) => {
    return dispatch(savePaidComplement(data, uuid));
  },
  getSalesPayable: (
    page: string,
    size: string,
    fechaDesde: string,
    fechaHasta: string,
    folio: string
  ) => {
    return dispatch(
      reportsActions.getSalesPayable(page, size, fechaDesde, fechaHasta, folio)
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentPluginValidation);
