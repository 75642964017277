import withStyles from "isomorphic-style-loader/lib/withStyles";
import moment from "moment";
import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import constants from "../../../../utils/constants";
import {
  errorNotification,
  infoNotification
} from "../../../../utils/notifications";
import { downloadReportDeleteCatForzosoFileId } from "../../../actions/reports";
import DatePicker from "../../common/DatePicker";
import s from "./../../../components/partners/tabStyles.scss";

const formatTime = (dateTime?) =>
  moment(dateTime)
    .utc()
    .format("YYYY-MM-DD");

interface Props {}

interface State {
  fechaInicio: any;
  fechaFin: any;
  downloading: boolean;
}

class DeleteCatForzosoReport extends React.Component<Props, State> {
  state = {
    fechaInicio: null,
    fechaFin: null,
    downloading: false
  };

  onClickDownloadCsvFile = () => {
    const { fechaInicio, fechaFin } = this.state;

    this.setState({
      downloading: true
    });

    infoNotification("Descargando reporte...");

    const filters = {
      fechaInicio,
      fechaFin
    };

    downloadReportDeleteCatForzosoFileId(filters)
      .then(({ data }) => {
        this.setState({
          downloading: false
        });

        const url = `${constants.BASE_URL}/${constants.DOCUMENTS_BASE_URL}/${data}`;
        window.open(url, "_blank");
      })
      .catch(err => {
        this.setState({
          downloading: false
        });

        const msg = "No se pudo descargar el archivo";
        if (err && err.response && err.response.data) {
          try {
            const message = JSON.parse(err.response.data).message || msg;
            errorNotification(message);
          } catch (erj) {
            errorNotification(msg);
          }
        } else {
          errorNotification(msg);
        }
      });
  };

  render() {
    return (
      <React.Fragment>
        <div>
          <div className="row mt-3 mb-3">
            <div className="col">
              <p className={`${s.primaryTitle} w-100 m-0`}>
                Reporte de eliminación de Cat Forzoso
              </p>
            </div>
          </div>
          <Row className="row mt-3 mb-3">
            <Col xs={12} md={2}>
              <DatePicker
                label="Fecha inicio:"
                name="reportDeleteCatForzosoFechaInicio"
                id="reportDeleteCatForzosoFechaInicio"
                selected={this.state.fechaInicio}
                onChange={dateTime => {
                  const value = formatTime(dateTime);
                  this.setState({ fechaInicio: value });
                }}
              />
            </Col>
            <Col xs={12} md={2}>
              <DatePicker
                label="Fecha fin:"
                name="reportDeleteCatForzosoFechaFin"
                id="reportDeleteCatForzosoFechaFin"
                selected={this.state.fechaFin}
                onChange={dateTime => {
                  const value = formatTime(dateTime);
                  this.setState({ fechaFin: value });
                }}
              />
            </Col>
            <Col className="pl-0 mt-4" sm={3}>
              <Button
                onClick={this.onClickDownloadCsvFile}
                disabled={!!this.state.downloading}
              >
                Descargar
              </Button>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(s)(DeleteCatForzosoReport));
