export enum TipoBoton {
  CORREO,
  FIRMAR,
  ESCANEAR,
  VER,
  REGENERAR,
  CINCEL,
  AUDITORIA
}

export enum FlujoDocumento {
  NINGUNO = "NINGUNO",
  GENERAR = "GENERAR",
  ESCANEAR = "ESCANEAR",
  FIRMA_MATERIAL = "FIRMA_MATERIAL",
  FIRMA_DIGITAL = "FIRMA_DIGITAL",
  CINCEL = "CINCEL"
}

export enum TipoDocumento {
  PROPIETARIO = "PROPIETARIO",
  TITULAR = "TITULAR",
  INTEGRANTE = "INTEGRANTE",
  EXTERNO = "EXTERNO",
  INVITADO = "INVITADO",
  INVITADO_ESPECIAL = "INVITADO_ESPECIAL"
}

export enum StatusAutorizacion {
  NO_GENERADO = "NO_GENERADO",
  NO_AUTORIZADO = "NO_AUTORIZADO",
  AUTORIZADO = "AUTORIZADO",
  FIRMA_INVALIDA = "FIRMA_INVALIDA",
  DOCUMENTO_INCORRECTO = "DOCUMENTO_INCORRECTO"
}

export interface Expediente {
  idExpediente: number;
  idMembresiaSocio: number;
  completo: boolean;
  autorizado: boolean;
  activo: boolean;
  vigentes: Archivo[];
  items: Archivo[];
}

export interface Archivo {
  idExpediente: number;
  idItem: number;
  storage?: string;
  fechaFirma?: string;
  completo: boolean;
  statusAutorizacion: StatusAutorizacion;
  moduloExpedientes: boolean;
  requeridoComision: boolean;

  idDocumento: number;
  codigo: string;
  nombre: string;
  descripcion: string;
  flujo: FlujoDocumento;
  critico: boolean;
  tipo: TipoDocumento;

  idMembresiaSocioPersona?: number;
  idPersona?: number;
  idMedioPago?: number;
  idCotizacionItem?: number;
  idPersonaEvaluacion?: number;

  idOwner: string;
  nombreCompleto: string;
  correo: string;
  migrado: boolean;

  idEntity: number;

  // Cincel
  storageCincelAudit?: string;
}

export interface ArchivoFirma {
  idExpediente: number;
  idItem: number;
  bookmark: string;
  nombre: string;
  base64Uri: string;
}

export interface RequestArchivo {
  codigo: string;
  idMembresiaSocio?: number;
  idPersonaExpediente?: number;
  idMembresiaSocioPersona?: number;
  idPersonaDocumento?: number;
  idMedioPago?: number;
  idCotizacionItem?: number;
  idPersonaEvaluacion?: number;
  values?: any;
}
