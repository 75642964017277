import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import PagedTable from "../common/PagedTable";
import { QueryResponse } from "../../../types/QueryResponse";
import { QueryArguments } from "../../../types-business/QueryArguments";
import { Container, Row, Col, Button, Form } from "reactstrap";
import ProductClassification from "../../containers/productClassification";
import BaseInput from "../../../toolkit/baseInput";
import { userHasPermission } from "../../../utils/accessTree";
import permissionCodes from "../../../utils/permissionCodes";

interface Props {
  products: QueryResponse<Product>;
  searching: boolean;
  onClickNewProduct: (product) => any;
  reloadProducts: boolean;
  fetchData: () => void;
  history: any;
  match: any;
  filters: Filters;
  onChangeFilter: any;
  getProducts: (args?: QueryArguments) => any;
  sportsProducts: [{ id: string; nombre: string }];
}

interface Filters {
  nombre: string;
  configuracionComisiones: boolean;
  page: number;
  size: number;
}

interface Product {
  id: string;
  clasificacionId: string;
  nombre: string;
  estatus: string;
  publicarPrecio: boolean;
  fechaInicioVigencia: string;
  fechaFinVigencia: string;
  descripcion: string;
}

class SportsProducts extends React.Component<Props> {
  handleEditProduct = product => {
    this.props.onClickNewProduct(product);
  };

  search = () => {
    this.props.getProducts(this.props.filters);
  };

  onChangeFactory = (event, fieldName) => {
    const value = event.target.value;
    this.props.onChangeFilter(value, fieldName);
  };

  onChangeBooleanFactory = (event, fieldName) => {
    const value = event.target.value;
    const booleanValue = value ? value === "1" : null;
    this.props.onChangeFilter(booleanValue, fieldName);
  };

  render() {
    const columns = [
      { name: "Nombre", id: "nombre" },
      { name: "Estatus", id: "estatus" },
      { name: "Conf. de comisión", id: "configuracionComision" },
      { name: "Inicio de vigencia", id: "fechaInicioVigencia" },
      { name: "Fin de vigencia", id: "fechaFinVigencia" },
      {
        headerActions: userHasPermission(
          permissionCodes.PRODUCTS_CREATE_PRODUCT
        )
          ? [{ name: "add", f: this.props.onClickNewProduct }]
          : []
      }
    ];

    let actions = [];
    if (userHasPermission(permissionCodes.PRODUCTS_EDIT_PRODUCT)) {
      actions = actions.concat([{ name: "edit", f: this.handleEditProduct }]);
    } else if (userHasPermission(permissionCodes.PRODUCTS_VIEW_PRODUCTS)) {
      actions = actions.concat([{ name: "view", f: this.handleEditProduct }]);
    }

    return (
      <Container className="p-0 mt-3">
        <Form
          onSubmit={e => {
            e.preventDefault();
            this.search();
          }}
        >
          <Row>
            <Col style={{ paddingBottom: "5px" }} xs={12} md={7} lg={7}>
              <BaseInput
                label={"Nombre del producto"}
                name="productName"
                id="productname"
                placeholder="Nombre del Producto"
                dataQAId={"product_name"}
                onChange={productName =>
                  this.onChangeFactory(productName, "nombre")
                }
              />
            </Col>
            <Col style={{ paddingBottom: "5px" }} xs={12} md={3} lg={3}>
              <BaseInput
                label={"Config. de comisiones"}
                name="config"
                type="select"
                options={[
                  { value: "", label: "Seleccione" },
                  { value: "1", label: "Autorizada" },
                  { value: "0", label: "No autorizada" }
                ]}
                id="productname"
                placeholder="Nombre del Producto"
                onChange={config =>
                  this.onChangeFactory(config, "configuracionComisiones")
                }
              />
            </Col>
            <Col xs={12} md={2} lg={2} className="mt-4">
              <Button
                size="sm"
                className={`float-right btn-block`}
                type="submit"
              >
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
        <PagedTable
          data={this.props.products}
          columns={columns}
          actions={actions}
          fetchData={this.props.fetchData}
          searching={this.props.searching}
        />
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(SportsProducts));
