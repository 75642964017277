import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./styles.scss";
import { Button } from "reactstrap";
import map from "lodash.map";
import { errorNotification } from "../../../utils/notifications";

interface Answers {
  id: number;
  idEvaluacion: number;
  idNivel: number;
  idPadre: number;
  orden: number;
  tipoRespuesta: ANSWER_TYPE;
  texto: string;
  preguntasAnidadas: Question[];
}

enum ANSWER_TYPE {
  TEXT = "Texto",
  LIST = "Lista",
  NULL = "Sin tipo",
  DECIMAL = "Decimal",
  NUMERIC = "Numerico",
  CATALOG = "Catalogo"
}

interface Question {
  id: number;
  idEvaluacion: number;
  idNivel: number;
  idPadre: number;
  orden: number;
  tipoRespuesta: ANSWER_TYPE;
  texto: string;
  respuestas?: Answers[];
  respuestasAnidadadas?: Answers[];
}

interface Props {
  idMembresiaSocio: number;
  surveys: {
    cuestionarios: {
      id: number;
      idEvaluacion: number;
      idNivel: number;
      orden: number;
      tipoRespuesta: string;
      texto: string;
      preguntas: Question[];
    }[];
  };
  match: any;
  idPersona: string;
  saveAnswers: (answers: object) => any;
}

interface State {
  answers: object;
}

class Surveys extends React.Component<Props, State> {
  state = {
    answers: {}
  };

  answersText = {};
  questionsRequired = [];

  setAnswer = (e, questionId) => {
    const value = e.target.value;
    this.setState({ answers: { ...this.state.answers, [questionId]: value } });
  };

  renderQuestions = (preguntas, level) => {
    //Se renderean las preguntas
    return (
      <div className="row p-0 m-0">
        {preguntas &&
          preguntas.map(
            ({
              texto,
              id: questionId,
              tipoRespuesta,
              respuestas,
              respuestasAnidadadas,
              configuracion,
              requerido
            }) => (
              <div
                key={questionId}
                style={{ marginLeft: `${level}rem` }}
                className={`form-group w-100 ${level === 0 ? "col-6" : ""}`}
              >
                <label>{texto + (!!requerido ? " *" : "")}</label>
                {this.getQuestionInput(
                  tipoRespuesta,
                  questionId,
                  texto,
                  respuestas ? respuestas : respuestasAnidadadas,
                  level,
                  configuracion,
                  requerido
                )}
              </div>
            )
          )}
      </div>
    );
  };

  getChildrenQuestions = (answer, questions, level) => {
    if (!answer || !questions.length) return null;

    const question = questions.find(({ id }) => id == answer);

    if (question) {
      this.answersText[question.id] = { texto: question.texto, respuestas: [] };
    }

    return (
      question && this.renderQuestions(question.preguntasAnidadas, level + 1)
    );
  };

  getQuestionInput = (
    _: ANSWER_TYPE,
    questionId,
    texto,
    respuestas,
    level,
    configuration?: string,
    requerido: boolean
  ) => {
    this.answersText[questionId] = { texto, respuestas };

    if (this.questionsRequired.indexOf(questionId) < 0 && requerido) {
      this.questionsRequired.push(questionId);
    }

    if (!respuestas.length) {
      if (_ == ANSWER_TYPE.NUMERIC) {
        return (
          <input
            type="text"
            id={`answer${questionId}`}
            placeholder={texto}
            className="form-control"
            value={this.state.answers[questionId]}
            onKeyPress={event => {
              if (
                !(
                  (event.charCode >= 48 && event.charCode <= 57) ||
                  (event.charCode == 8 ||
                    event.charCode == 9 ||
                    event.charCode == 45)
                )
              ) {
                event.preventDefault();
              }
            }}
            onChange={e => this.setAnswer(e, questionId)}
            maxLength={10}
          />
        );
      } else if (_ == ANSWER_TYPE.DECIMAL) {
        return (
          <input
            type="text"
            id={`answer${questionId}`}
            placeholder={texto}
            className="form-control"
            value={this.state.answers[questionId]}
            onKeyPress={event => {
              if (
                !(
                  (event.charCode >= 48 && event.charCode <= 57) ||
                  (event.charCode == 8 ||
                    event.charCode == 9 ||
                    event.charCode == 45 ||
                    event.charCode == 46)
                )
              ) {
                event.preventDefault();
              }
            }}
            onChange={e => this.setAnswer(e, questionId)}
            maxLength={10}
          />
        );
      } else if (_ == ANSWER_TYPE.CATALOG) {
        const config = JSON.parse(configuration || "{}");
        return (
          <select
            value={this.state.answers[questionId]}
            className="form-control"
            id={`answer${questionId}`}
            onChange={e => this.setAnswer(e, questionId)}
          >
            {[
              { id: "", dsc: "Seleccione una respuesta" },
              ...(config.catalogo || [])
            ].map(({ dsc, id }) => (
              <option key={id} value={id}>
                {dsc}
              </option>
            ))}
          </select>
        );
      } else {
        return (
          <textarea
            className="form-control"
            id={`answer${questionId}`}
            placeholder={texto}
            rows={2}
            onChange={e => this.setAnswer(e, questionId)}
            value={this.state.answers[questionId]}
          />
        );
      }
    } else {
      const answers = [];
      answers.push(
        <option key={`nullAnswer${questionId}`} value={null}>
          Seleccione una respuesta
        </option>
      );
      return (
        <div className="w-100 p-0 m-0">
          <select
            value={this.state.answers[questionId]}
            className="form-control"
            id={`answer${questionId}`}
            onChange={e => this.setAnswer(e, questionId)}
          >
            {answers.concat(
              respuestas.map(({ texto, id: answerId }) => (
                <option key={answerId} value={answerId}>
                  {texto}
                </option>
              ))
            )}
          </select>
          <div className="mt-3 mb-4 m-0 p-0 w-100">
            {this.getChildrenQuestions(
              this.state.answers[questionId],
              respuestas,
              level
            )}
          </div>
        </div>
      );
    }
  };

  saveAnswers = e => {
    e.preventDefault();
    const { idMembresiaSocio } = this.props;
    const { idPersona } = this.props;

    const respuestas = map(this.state.answers, (answer: string, idPregunta) => {
      const { respuestas } = this.answersText[idPregunta];
      const resp: { idPregunta: string; respuesta: String } = {
        idPregunta,
        respuesta: ""
      };
      if (!respuestas.length) {
        resp.respuesta = answer;
      } else {
        resp.respuesta = this.answersText[answer].texto;
      }
      return resp;
    });

    //Validación de requerida
    let doSave = true;
    this.questionsRequired.forEach(qr => {
      if (doSave && !respuestas.find(r => r.idPregunta == qr)) {
        doSave = false;
      }
    });

    if (!!doSave) {
      this.props.saveAnswers({
        estatus: true,
        idEvaluacion: 1,
        idMembresiaSocio,
        idPersona,
        respuestaPregunta: respuestas
      });
    } else {
      errorNotification("Debe contestar todas las preguntas requeridas.");
    }
  };

  render() {
    return (
      <div className="accordion container p-0" id="accordionExample">
        {this.props.surveys.cuestionarios.map(({ texto, id, preguntas }) => (
          <div key={id} className="card">
            <div
              style={{ backgroundColor: "#ce072c" }}
              className="p-0 card-header"
              id={`heading${id}`}
            >
              <h2 className="mb-0">
                <button
                  className="btn btn-link"
                  type="button"
                  data-toggle="collapse"
                  data-target={`#collapse${id}`}
                  aria-expanded="true"
                  aria-controls={`collapse${id}`}
                  style={{ color: "white", fontWeight: "bold" }}
                >
                  {texto.toLocaleUpperCase()}
                </button>
              </h2>
            </div>

            <div
              id={`collapse${id}`}
              className="collapse"
              aria-labelledby={`heading${id}`}
              data-parent="#accordionExample"
            >
              <div className="card-body container">
                {this.renderQuestions(preguntas, 0)}
              </div>
            </div>
          </div>
        ))}
        <form>
          <div className="form-row p-0 m-0">
            <div className="col" />
            <div className="col-2 float-right pr-0">
              <Button
                className={`${s.buttonMarginTop} ${s.primaryButton} mt-4`}
                onClick={e => this.saveAnswers(e)}
                size="sm"
                block
              >
                Guardar Respuestas
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withStyles(s)(Surveys);
