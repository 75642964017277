import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Container, Col, Button, Row } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import DatePicker from "../common/DatePicker";
import ReactTable from "react-table";
import s from "./styles.scss";
import IAction from "../../../types/IAction";
import { CONCEPTOS, ID_EFECTIVO, ID_CHEQUE } from "../../../utils/constants";
import isNil from "lodash.isnil";

interface Props {
  clubs: object[];
  getReferenceConsecutiveClub: (clubId: string) => IAction;
  referenceConsecutive: string;
  createReferencesByClub: (
    clubId: string,
    monto: string,
    formaDePago: string,
    fechaCreacion: string,
    numeroReferencias: string
  ) => IAction;
  referencesByClub: object[];
}

interface State {
  filters: object;
  clubs: object[];
}

class ReferenceCalculationPage extends React.Component<Props, State> {
  state = {
    filters: {
      idConcepto: "",
      clubId: "",
      monto: "",
      formaDePago: "",
      fechaCreacion: null,
      numeroReferencias: "",
      consecutivoDia: "",
      size: 20
    },
    clubs: []
  };

  componentDidUpdate() {
    if (
      !isNil(this.props.referenceConsecutive) &&
      this.props.referenceConsecutive.toString().length == 0 &&
      this.state.filters.consecutivoDia.toString().length > 0
    ) {
      let { filters } = this.state;
      filters.consecutivoDia = "";
      this.setState({ filters });
    }
    if (
      !isNil(this.props.referenceConsecutive) &&
      this.props.referenceConsecutive.toString().length != 0 &&
      this.state.filters.consecutivoDia.toString().length == 0
    ) {
      let { filters } = this.state;
      filters.consecutivoDia = this.props.referenceConsecutive;
      this.setState({ filters });
    }
  }

  componentWillUnmount() {
    let filters: {
      idConcepto: "";
      clubId: "";
      monto: "";
      formaDePago: "";
      fechaCreacion: null;
      numeroReferencias: "";
      consecutivoDia: "";
      size: 20;
    };
    this.setState({
      filters,
      clubs: []
    });
  }

  columns = [
    {
      Header: () => <p className="font-weight-bold">Referencia</p>,
      id: 0,
      width: 200,
      accessor: "referencia",
      Cell: row => {
        return <p>{row.original.referencia}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Club</p>,
      id: 1,
      width: 300,
      accessor: "clubNombre",
      Cell: row => {
        return <p>{row.original.clubNombre.toUpperCase()}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Forma de Pago</p>,
      id: 2,
      width: 150,
      accessor: "formaDePago",
      Cell: row => {
        return <p>{row.original.formaDePago}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Fecha de Creacion</p>,
      id: 3,
      width: 150,
      accessor: "fechaCreacion",
      Cell: row => {
        return <p>{row.original.fechaCreacion}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Estatus</p>,
      id: 4,
      width: 150,
      accessor: "estatus",
      Cell: row => {
        return <p>{row.original.estatus}</p>;
      }
    }
  ];

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      if (fieldName == "clubId") {
        this.props.getReferenceConsecutiveClub(value);
      }
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  onChangeConcepto = e => {
    let valFilters = this.state.filters;
    valFilters.idConcepto = e.target.value;
    let filterClubs = this.props.clubs.filter(
      value => value.concepto == e.target.value
    );
    this.setState({ filters: valFilters, clubs: filterClubs });
  };

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const filters = {
          ...state.filters,
          [fieldName]: date
        };
        return { ...state, error: {}, filters };
      });
    };
  };

  onDisabledButton = () => {
    let valActive = false;
    Object.values(this.state.filters).forEach(element => {
      if (isNil(element) || element.toString().length == 0) valActive = true;
    });
    return valActive;
  };

  generate = e => {
    e.preventDefault();
    const { filters } = this.state;
    this.props.createReferencesByClub(
      filters.clubId,
      filters.monto,
      filters.formaDePago,
      filters.fechaCreacion,
      filters.numeroReferencias
    );
  };

  render() {
    const { clubs, filters } = this.state;
    const contentReferences = this.props.referencesByClub;
    return (
      <Container className="p-0">
        <div className="row mt-3 mb-3">
          <div className="col">
            <p className={`${s.primaryTitle} w-100 m-0`}>Calcula Referencia</p>
          </div>
        </div>
        <Row>
          <Col xs={12} md={4} lg={3}>
            <BaseInput
              label="Concepto"
              name="concepto"
              id="concepto"
              type="select"
              placeholder="Seleccionar concepto"
              value={filters.idConcepto}
              onChange={e => this.onChangeConcepto(e)}
              size={"sm"}
              options={[{ value: "", label: "Seleccionar concepto" }].concat(
                CONCEPTOS
                  ? CONCEPTOS.map(option => ({
                      value: option.value,
                      label: option.label
                    }))
                  : []
              )}
            />
          </Col>
          <Col xs={12} md={4} lg={3}>
            <BaseInput
              label="Club"
              name="clubId"
              id="clubId"
              type="select"
              placeholder="Seleccionar club"
              value={filters.clubId}
              disabled={filters.idConcepto.length == 0}
              onChange={this.onChangeFilter("clubId")}
              size={"sm"}
              options={[{ value: "", label: "Seleccionar club" }].concat(
                clubs.length > 0
                  ? clubs.map(option => ({
                      value: option.clubId,
                      label: option.nombre
                    }))
                  : []
              )}
            />
          </Col>
          <Col xs={12} md={4} lg={3}>
            <DatePicker
              label="Fecha"
              name="fechaCreacion"
              id="fechaCreacion"
              selected={filters.fechaCreacion}
              onChange={this.onChangeDate("fechaCreacion")}
            />
          </Col>
          <Col xs={12} md={4} lg={3}>
            <BaseInput
              label={"Consecutivo del día"}
              name="consecutivoDia"
              type="text"
              id="consecutivoDia"
              value={filters.consecutivoDia}
              onChange={this.onChangeFilter("consecutivoDia")}
              disabled={true}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4} lg={3}>
            <BaseInput
              label={"Monto"}
              name="monto"
              type="text"
              id="monto"
              value={filters.monto}
              onChange={this.onChangeFilter("monto")}
            />
          </Col>
          <Col xs={12} md={4} lg={3}>
            <BaseInput
              label="Depósito"
              name="formaDePago"
              id="formaDePago"
              type="select"
              placeholder="Seleccionar depósito"
              value={filters.formaDePago}
              onChange={this.onChangeFilter("formaDePago")}
              size={"sm"}
              options={[
                { value: "", label: "Seleccionar depósito" },
                { value: ID_EFECTIVO, label: ID_EFECTIVO },
                { value: ID_CHEQUE, label: ID_CHEQUE }
              ]}
            />
          </Col>
          <Col xs={12} md={4} lg={3}>
            <BaseInput
              label={"Número de referencias"}
              name="numeroReferencias"
              type="text"
              id="numeroReferencias"
              value={filters.numeroReferencias}
              onChange={this.onChangeFilter("numeroReferencias")}
            />
          </Col>
          <Col xs={12} md={4} lg={3} className="mt-4">
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.generate}
              disabled={this.onDisabledButton()}
            >
              Generar
            </Button>
          </Col>
        </Row>
        <ReactTable
          data={contentReferences}
          columns={this.columns}
          sortable={false}
          resizable={false}
          showPagination={false}
          className="mt-4 -highlight"
          pageSize={contentReferences.length || 10}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ReferenceCalculationPage));
