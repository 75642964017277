import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import { getErrorMessage } from "../../utils/helpers";
import { ERROR, errorNotification } from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";

interface State {
  personalDocumentsHistory: object[];
  gettingPersonalDocumentsHistory: boolean;
  gettingPersonalDocumentsHistoryError: object;
}

const initialState: State = {
  personalDocumentsHistory: null,
  gettingPersonalDocumentsHistory: false,
  gettingPersonalDocumentsHistoryError: null
};

export default function personalDocumentsHistoryReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    //GET ALL COMPANIES
    case `${actionTypes.GET_PERSONAL_DOCUMENTS_HISTORY}_PENDING`: {
      return {
        ...state,
        gettingPersonalDocumentsHistory: true,
        gettingPersonalDocumentsHistoryError: null
      };
    }
    case `${actionTypes.GET_PERSONAL_DOCUMENTS_HISTORY}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        personalDocumentsHistory: null,
        gettingPersonalDocumentsHistory: false,
        gettingPersonalDocumentsHistoryError: getErrorMessage(action.payload)
      };
    }
    case `${actionTypes.GET_PERSONAL_DOCUMENTS_HISTORY}_FULFILLED`: {
      return {
        ...state,
        personalDocumentsHistory: action.payload.data,
        gettingPersonalDocumentsHistory: false,
        gettingPersonalDocumentsHistoryError: null
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
