import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import {
  infoNotification,
  errorNotification,
  successNotification,
  SPORTS_PROGRAMS_GET_VALID_CUSTOMERS_PENDING_MESSAGE,
  SPORTS_PROGRAMS_GET_VALID_CUSTOMERS_NOT_FOUND_MESSAGE,
  SPORTS_PROGRAMS_GET_VALID_CUSTOMERS_REJECTED_MESSAGE
} from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";
interface State {
  gettingValidCustomers: boolean;
  validCustomers: object[];
}
const initialState: State = {
  gettingValidCustomers: false,
  validCustomers: null
};

export default function sportsProgramsReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.SPORTS_PROGRAMS_GET_VALID_CUSTOMERS}_PENDING`: {
      infoNotification(SPORTS_PROGRAMS_GET_VALID_CUSTOMERS_PENDING_MESSAGE);
      return {
        ...state,
        gettingValidCustomers: true,
        validCustomers: null
      };
    }
    case `${actionTypes.SPORTS_PROGRAMS_GET_VALID_CUSTOMERS}_REJECTED`: {
      errorNotification(
        readMessageBack(
          action,
          SPORTS_PROGRAMS_GET_VALID_CUSTOMERS_REJECTED_MESSAGE
        )
      );
      return {
        ...state,
        gettingValidCustomers: false,
        validCustomers: null
      };
    }
    case `${actionTypes.SPORTS_PROGRAMS_GET_VALID_CUSTOMERS}_FULFILLED`: {
      if (!!!action.payload.data || !action.payload.data.length) {
        errorNotification(
          SPORTS_PROGRAMS_GET_VALID_CUSTOMERS_NOT_FOUND_MESSAGE
        );
      }
      return {
        ...state,
        gettingValidCustomers: false,
        validCustomers: action.payload.data
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
