import React, { Component } from "react";
import {
  Router,
  RouterProps,
  Styled
} from "../../../toolkit/decorators/Artifact";
import { InvitadosDetalleDispatcher } from "../../containers/invitadosDetalle/InvitadosDetalle.dispatcher";
import { InvitadosDetalleStore } from "../../containers/invitadosDetalle/InvitadosDetalle.store";
import s from "./styles.scss";
import { Container, Row } from "reactstrap";
import { errorNotification } from "../../../utils/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faUser } from "@fortawesome/free-solid-svg-icons";
import InvitadosDetalleTab from "./InvitadosDetalle.tabs";

interface Props
  extends InvitadosDetalleDispatcher,
    InvitadosDetalleStore,
    RouterProps<any> {}

interface State {}

@Router()
@Styled(s)
export default class InvitadosDetalleComponent extends Component<Props, State> {
  state = {
    activeTab: null
  };

  async componentDidMount(): Promise<void> {
    this.props.clearInvitado();
    const { id: idInvitado } = this.props.match.params;
    const preview = await this.props.getInvitado(idInvitado);

    if (!preview.fulfilled) {
      errorNotification("Error al cargar los datos del invitado");
    }
  }

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  tabStyle = tab => `${this.state.activeTab === tab ? "active" : ""} ${s.tab}`;

  render() {
    if (!this.props.invitado) {
      return (
        <Container>
          <FontAwesomeIcon icon={faSpinner} pulse />
        </Container>
      );
    }

    return (
      <Container>
        <Row>
          <div className={s.info}>
            <div className={s.foto}>
              <FontAwesomeIcon icon={faUser} />
            </div>
            <div className={s.persona}>
              <h3>{this.props.invitado.nombreCompleto}</h3>
              <hr />
              <p>
                Persona: {this.props.invitado.idPersona}
                {" - "}
                <a
                  href={`/productos/carrito/${this.props.invitado.idPersona}`}
                  target="blank"
                >
                  Ir a carrito de compras
                </a>
              </p>
            </div>
          </div>
        </Row>
        <InvitadosDetalleTab {...this.props} detalle={this.props.invitado} />
      </Container>
    );
  }
}
