import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import { Label } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import BaseInput from "../../../toolkit/baseInput";
import s from "./styles.scss";
import { errorNotification } from "../../../utils/notifications";
import { setChildStep } from "../../helpers/stepper-state-comparator";

interface Props {
  companie: any;
  createCompanie: (companie: any) => any;
  updateCompanie: (id: string, companie: any) => any;
  onClickSave: (e: any) => any;
  history: any;
  getUsers: any;
  users: any[];
}

interface State {
  field: any;
  estatus: string;
  userOptions: any[];
  selected: any;
}

class BasicDataEmpresaConvenioForm extends React.Component<Props, State> {
  state = {
    field: {
      id: null,
      nombre: "",
      activo: true,
      responsable: null
    },
    estatus: "",
    userOptions: [],
    selected: null
  };

  loaded = false;
  componentDidUpdate(prevProps) {
    const { companie } = this.props;
    if (companie && !this.loaded) {
      this.loaded = true;
      this.setState({
        field: {
          id: companie.id,
          nombre: companie.nombre,
          activo: companie.activo,
          responsable: companie.responsable
        },
        estatus: companie.activo ? "ACTIVO" : "INACTIVO"
      });
    }

    if (prevProps.users != this.props.users && this.props.users) {
      const userOptions = this.props.users.map(item => {
        var rObj = {} as any;
        rObj.id = item.userId;
        rObj.label =
          item.name + " " + item.firstSurname + " " + item.secondSurname;
        return rObj;
      });

      const selected =
        this.props.companie && this.props.companie.responsable
          ? [
              userOptions.find(
                option => option.id == this.props.companie.responsable
              )
            ]
          : null;

      this.setState({
        userOptions,
        selected
      });
    }
  }

  componentDidMount() {
    this.props.onClickSave(e => this.onSave());
    this.props.getUsers();
  }

  onSave() {
    if (this.onValidate()) {
      let promise;
      if (this.state.field.id) {
        promise = this.props.updateCompanie(
          this.state.field.id,
          this.state.field
        );
      } else {
        promise = this.props.createCompanie(this.state.field);
      }
      promise.then(({ value: { data } }) => {
        if (data) {
          this.props.history.push(`/empresas-convenios`);
        }
      });
    }
  }

  onValidate() {
    let isValid = true;
    if (!this.state.field.nombre || this.state.field.nombre.trim() == "") {
      isValid = false;
      errorNotification("El nombre es requerido.");
    } else if (!this.state.estatus) {
      isValid = false;
      errorNotification("El estatus es requerido.");
    }
    return isValid;
  }

  onChangeFactory(name, value) {
    this.setState({
      field: { ...this.state.field, [`${name}`]: value ? value : null }
    });
  }

  render() {
    const estatusOptions = [
      { label: "Seleccione", value: "" },
      { label: "ACTIVO", value: "ACTIVO" },
      { label: "INACTIVO", value: "INACTIVO" }
    ];

    return (
      <form>
        <div className="form-row">
          <div className="col">
            <BaseInput
              label={"Nombre"}
              name="nombre"
              type="text"
              id="nombre"
              placeholder="Nombre"
              value={this.state.field.nombre}
              onChange={e =>
                this.onChangeFactory("nombre", e.target.value.toUpperCase())
              }
              uppercase={true}
              maxlength={"250"}
            />
          </div>
          <div className="col">
            <Label className={s.label}>Responsable</Label>
            <Typeahead
              id={"Responsable"}
              selected={this.state.selected}
              promptText={"Responsable"}
              bsSize={"sm"}
              onChange={selected =>
                this.setState({
                  selected,
                  field: {
                    ...this.state.field,
                    responsable:
                      selected && selected.length ? selected[0].id : null
                  }
                })
              }
              emptyLabel={"Sin coincidencias"}
              disabled={this.props.users ? false : true}
              maxResults={20}
              options={this.state.userOptions}
            />
            {!this.props.users || this.props.users.length == 0 ? (
              <p className={s.msgError}>Cargando catálogo...</p>
            ) : (
              ""
            )}
          </div>
          <div className="col">
            <BaseInput
              label={"Estatus"}
              name="activo"
              type="select"
              id="activo"
              placeholder="Estatus"
              value={this.state.estatus}
              options={estatusOptions.map(option => ({
                value: option.value,
                label: option.label
              }))}
              onChange={e =>
                this.setState({
                  field: {
                    ...this.state.field,
                    activo: e.target.value == "ACTIVO"
                  },
                  estatus: e.target.value
                })
              }
            />
          </div>
        </div>
      </form>
    );
  }
}

export default withRouter(withStyles(s)(BasicDataEmpresaConvenioForm));
