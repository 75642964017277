import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import QuizzSurveyAppliedResume from "../../components/quizzSurveyAppliedResume";

interface Props {}

interface State {}

class QuizzSurveyAppliedResumePage extends React.Component<Props, State> {
  state = {
    quizzDeviceResume: []
  };

  render() {
    return (
      <main>
        <Helmet title="Quizzes" />
        <Layout>
          <QuizzSurveyAppliedResume />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuizzSurveyAppliedResumePage);
