import { GetMovimientosInvitado } from "../../../../reducers/invitados";

export interface TabMovimientosStore extends GetMovimientosInvitado {
  listHistoricalRebilling: any;
}

export const tabMovimientosStore: (state) => TabMovimientosStore = state => {
  const { invitados, reports } = state;

  return {
    listHistoricalRebilling: reports.listHistoricalRebilling,
    movimientosInvitado: invitados.movimientosInvitado,
    gettingMovimientosInvitado: invitados.gettingMovimientosInvitado,
    gettingMovimientosInvitadoError: invitados.gettingMovimientosInvitadoError
  };
};
