import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Container, Col, Button, Row } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import DatePicker from "../common/DatePicker";
import ReactTable from "react-table";
import MexicanCurrency from "../common/currency";
import s from "./styles.scss";
import IAction from "../../../types/IAction";
import {
  faThumbsUp,
  faThumbsDown,
  faFileExport,
  faFilePdf
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  clubs: { clubId; nombre }[];
  reportCustomizableReceipt: any;
  getCustomizableReceipt: (
    clubId: string,
    fechaInicio: string,
    fechaFin: string,
    estatus: string
  ) => IAction;
  downloadCustomizableReceipt: (body: object) => IAction;
  refuseCustomizableReceipt: (reciboProvicionalId: string) => IAction;
  validateCustomizableReceipt: (reciboProvicionalId: string) => IAction;
  objRFCR: any;
  objVCR: any;
  pdfProvitionalReceipt: (reciboProvisionalId: string) => IAction;
}

interface State {
  filters: object;
  loadSuccessRFCR: boolean;
  loadSuccessVCR: boolean;
  clickRFCR: boolean;
  clickVCR: boolean;
}

const START_DATE_FIELDNAME = "fechaInicio";
const END_DATE_FIELDNAME = "fechaFin";

class ReportCustomizableReceipts extends React.Component<Props, State> {
  state = {
    filters: {
      idClub: "",
      fechaInicio: null,
      fechaFin: null,
      estatus: null
    },
    loadSuccessRFCR: false,
    loadSuccessVCR: false,
    onSearch: false,
    clickRFCR: false,
    clickVCR: false
  };

  componentDidUpdate(prevProps) {
    const { idClub, fechaInicio, fechaFin, estatus } = this.state.filters;

    if (
      fechaFin !== null &&
      fechaInicio !== null &&
      idClub !== "" &&
      (this.state.clickRFCR || this.state.clickVCR)
    ) {
      if (prevProps.objRFCR !== this.props.objRFCR) {
        this.setState({ loadSuccessRFCR: false });
      }

      if (
        this.props.objRFCR !== null &&
        !this.state.loadSuccessRFCR &&
        this.state.clickRFCR
      ) {
        this.props.getCustomizableReceipt(
          idClub,
          fechaInicio,
          fechaFin,
          estatus
        );
        this.setState({
          loadSuccessRFCR: true,
          clickRFCR: false
        });
      }

      if (prevProps.objVCR !== this.props.objVCR) {
        this.setState({ loadSuccessVCR: false });
      }

      if (
        this.props.objVCR !== null &&
        !this.state.loadSuccessVCR &&
        this.state.clickVCR
      ) {
        this.props.getCustomizableReceipt(
          idClub,
          fechaInicio,
          fechaFin,
          estatus
        );
        this.setState({
          loadSuccessVCR: true,
          clickVCR: false
        });
      }
    }
  }

  getProductConfig(e, reciboProvicionalId) {
    e.preventDefault();
    this.props.pdfProvitionalReceipt(reciboProvicionalId);
  }

  columns = [
    {
      Header: () => <p className="font-weight-bold">Folio</p>,
      id: 0,
      width: 120,
      accessor: "folio",
      Cell: row => {
        return <p>{row.original.folio}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Fecha</p>,
      id: 0,
      width: 80,
      accessor: "fecha",
      Cell: row => {
        return <p>{row.original.fecha}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Membresía</p>,
      id: 0,
      width: 80,
      accessor: "membresia",
      Cell: row => {
        return <p>{row.original.membresia}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Club</p>,
      id: 0,
      width: 100,
      accessor: "club",
      Cell: row => {
        return <p>{row.original.club}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Razón social</p>,
      id: 0,
      width: 160,
      accessor: "razonSocial"
    },
    {
      Header: () => <p className="font-weight-bold">Estatus</p>,
      id: 0,
      width: 100,
      accessor: "estatus",
      Cell: row => {
        return <p>{row.original.estatus}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Importe</p>,
      id: 1,
      width: 100,
      accessor: "importe",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.importe} />;
      }
    },
    {
      Header: () => {
        return (
          <p className="font-weight-bold">
            Aceptar / Rechazar
            <br />
          </p>
        );
      },
      id: -300,
      width: 150,
      accessor: "actions",
      Cell: row => {
        return row.original.estatus === "PENDIENTE" ? (
          <Container className="p-0">
            <Row>
              <Col className="text-center">
                <a
                  id={`validate${row.original.folio}`}
                  onClick={() =>
                    this.props.validateCustomizableReceipt(row.original.id)
                  }
                >
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faThumbsUp}
                  />
                </a>
              </Col>
              <Col className="text-center">
                <a
                  id={`refuse${row.original.folio}`}
                  onClick={() =>
                    this.props.refuseCustomizableReceipt(row.original.id)
                  }
                >
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faThumbsDown}
                  />
                </a>
              </Col>
            </Row>
          </Container>
        ) : (
          ""
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Folio factura</p>,
      id: 4,
      width: 80,
      accessor: "folioFactura",
      Cell: row => {
        return <p>{row.original.folioFactura}</p>;
      }
    },
    {
      Header: () => (
        <p className="font-weight-bold">Fecha límite de facturación</p>
      ),
      id: 5,
      width: 160,
      accessor: "fechaLimiteFacturacion",
      Cell: row => {
        return <p>{row.original.fechaLimiteFacturacion}</p>;
      }
    },
    {
      Header: () => {
        return <p className="font-weight-bold">Ver Pdf Recibo</p>;
      },
      id: -300,
      width: 120,
      accessor: "actions",
      Cell: row => {
        return (
          <div className="text-center">
            <a
              id={`validate${row.original.folio}`}
              onClick={e => this.getProductConfig(e, row.original.id)}
            >
              <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faFilePdf} />
            </a>
          </div>
        );
      }
    }
  ];

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const filters = {
          ...state.filters,
          [fieldName]: date
        };
        return { ...state, error: {}, filters };
      });
    };
  };

  search = e => {
    e.preventDefault();
    const { idClub, fechaInicio, fechaFin, estatus } = this.state.filters;
    this.props.getCustomizableReceipt(idClub, fechaInicio, fechaFin, estatus);
  };

  onClickDownloadCsvFile = () => {
    const { idClub, fechaInicio, fechaFin, estatus } = this.state.filters;
    this.props.downloadCustomizableReceipt({
      idClub,
      fechaInicio,
      fechaFin,
      estatus
    });
  };

  render() {
    const { clubs, reportCustomizableReceipt } = this.props;
    const { idClub, estatus } = this.state.filters;

    return (
      <Container className="p-0">
        <div className="row mt-3 mb-3">
          <div className="col">
            <p className={`${s.primaryTitle} w-100 m-0`}>
              Búsqueda de recibos provisionales
            </p>
          </div>
        </div>
        <Row>
          <Col xs={12} md={3}>
            <BaseInput
              label="Club"
              name="club"
              id="club"
              type="select"
              placeholder="Seleccionar club"
              value={idClub}
              onChange={this.onChangeFilter("idClub")}
              size={"sm"}
              options={[{ value: "", label: "Seleccionar club" }].concat(
                (clubs || []).map(option => ({
                  value: option.clubId,
                  label: option.nombre
                }))
              )}
            />
          </Col>
          <Col xs={12} md={2}>
            <DatePicker
              label="Fecha inicio"
              name={START_DATE_FIELDNAME}
              id={START_DATE_FIELDNAME}
              selected={this.state.filters[START_DATE_FIELDNAME]}
              onChange={this.onChangeDate(START_DATE_FIELDNAME)}
            />
          </Col>
          <Col xs={12} md={2}>
            <DatePicker
              label="Fecha fin"
              name={END_DATE_FIELDNAME}
              id={END_DATE_FIELDNAME}
              selected={this.state.filters[END_DATE_FIELDNAME]}
              onChange={this.onChangeDate(END_DATE_FIELDNAME)}
            />
          </Col>
          <Col xs={12} md={3}>
            <BaseInput
              label="Estatus"
              name="estatus"
              id="estatus"
              type="select"
              placeholder="Seleccionar estatus"
              value={estatus}
              onChange={this.onChangeFilter("estatus")}
              size={"sm"}
              options={[
                { value: "", label: "Seleccionar club" },
                { value: "PENDIENTE", label: "PENDIENTE" },
                { value: "COBRADO", label: "COBRADO" },
                { value: "RECHAZADO", label: "RECHAZADO" }
              ]}
            />
          </Col>
          <Col xs={12} md={2}>
            <br />
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.search}
              disabled={
                this.state.filters.fechaFin === null ||
                this.state.filters.fechaInicio === null
              }
            >
              Buscar
            </Button>
          </Col>
        </Row>
        {reportCustomizableReceipt ? (
          <Row className={s.customRowCSV}>
            <Col className={s.customTextoCSV}>Exportar archivo CSV</Col>
            <Col className={s.customLogoCSV}>
              <Button
                id="btnLockerSummaryExportCsv"
                color="link"
                onClick={this.onClickDownloadCsvFile}
              >
                <FontAwesomeIcon icon={faFileExport} color="black" />
              </Button>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <ReactTable
          manual
          data={reportCustomizableReceipt ? reportCustomizableReceipt : []}
          columns={this.columns}
          lassName="w-100"
          showPagination={true}
          defaultPageSize={20}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ReportCustomizableReceipts));
