import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import moment from "moment";
import {
  Container,
  Col,
  Button,
  Row,
  UncontrolledTooltip,
  Form
} from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import DatePicker from "../common/DatePicker";
import ReactTable from "react-table";
import MexicanCurrency from "../common/currency";
import s from "./styles.scss";
import IAction from "../../../types/IAction";
import { CONCEPTOS, ESTATUS } from "../../../utils/constants";
import {
  faCheckCircle,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { allowedTo } from "../../../utils/accessTree";
import accessCodes from "../../../utils/accessCodes";
import isNil from "lodash.isnil";
const {
  FACTURAR_CARGOS_CONCESIONARIOS,
  BORRAR_CARGOS_CONCESIONARIOS
} = accessCodes;

interface Props {
  clubs: object[];
  depositoReferenciado: object[];
  getMovements: (
    fechaInicio: string,
    fechaFin: string,
    body: object
  ) => IAction;
  downloadMovementStatus: (
    fechaInicio: string,
    fechaFin: string,
    body: object
  ) => IAction;
  dealersMovement: object;
  acceptCharge: (cargoConcecionarioId: string) => IAction;
  dealers: object;
  deleteProducts: (cargoConcecionarioId: string) => IAction;
  deletingProduct: boolean;
  deletingProductError: any;
  acceptChargeValidate: boolean;
  errorAcceptCharge: boolean;
}

interface State {
  filters: object;
  clubs: object[];
  updateTableCharge: boolean;
  updateTableDelete: boolean;
}

class MovementStatusPage extends React.Component<Props, State> {
  state = {
    filters: {
      idConcepto: "",
      idClub: "",
      estatus: "",
      nombre: "",
      fechaDesde: null,
      fechaHasta: null,
      size: 20
    },
    clubs: [],
    updateTableCharge: false,
    updateTableDelete: false
  };

  componentDidUpdate() {
    if (
      (this.state.updateTableCharge && this.props.acceptChargeValidate) ||
      (this.state.updateTableDelete &&
        !this.props.deletingProduct &&
        isNil(this.props.deletingProductError))
    ) {
      const { filters } = this.state;
      this.setState({ updateTableCharge: false, updateTableDelete: false });
      this.props.getMovements(filters.fechaDesde, filters.fechaHasta, filters);
    }
    if (
      (this.state.updateTableCharge && this.props.errorAcceptCharge) ||
      (this.state.updateTableDelete && !isNil(this.props.deletingProductError))
    ) {
      this.setState({ updateTableCharge: false, updateTableDelete: false });
    }
  }

  componentWillUnmount() {
    let filters: {
      idConcepto: "";
      idClub: "";
      estatus: "";
      nombre: "";
      fechaDesde: null;
      fechaHasta: null;
      size: 20;
    };
    this.setState({
      filters,
      clubs: [],
      updateTableCharge: false,
      updateTableDelete: false
    });
  }

  onClickAceptarCobro = id => {
    this.props.acceptCharge(id);
    this.setState({ updateTableCharge: true });
  };

  onClickEliminarCobro = id => {
    this.props.deleteProducts(id);
    this.setState({ updateTableDelete: true });
  };

  columns = [
    {
      Header: () => <p className="font-weight-bold">Club</p>,
      id: 0,
      width: 80,
      accessor: "clubNombre",
      className: "text-uppercase"
    },
    {
      Header: () => <p className="font-weight-bold">Movimiento</p>,
      id: 1,
      width: 80,
      accessor: "movimientoId"
    },
    {
      Header: () => <p className="font-weight-bold">Factura</p>,
      id: 2,
      width: 80,
      accessor: "facturaFolio"
    },
    {
      Header: () => <p className="font-weight-bold">Fecha Factura</p>,
      id: 3,
      width: 100,
      accessor: "facturaFecha"
    },
    {
      Header: () => <p className="font-weight-bold">Fecha Creado</p>,
      id: 4,
      width: 100,
      accessor: "fechaCreacionCargo"
    },
    {
      Header: () => <p className="font-weight-bold">Razón Social</p>,
      id: 5,
      width: 240,
      accessor: "razonSocial",
      className: "text-uppercase"
    },
    {
      Header: () => <p className="font-weight-bold">Descripción Cargo</p>,
      id: 6,
      width: 240,
      accessor: "descripcionCargo",
      className: "text-uppercase"
    },
    {
      Header: () => <p className="font-weight-bold">Importe</p>,
      id: 7,
      width: 80,
      accessor: "importe",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.importe} />;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Forma de Pago</p>,
      id: 8,
      width: 120,
      accessor: "formaDePago",
      className: "text-uppercase"
    },
    {
      Header: () => <p className="font-weight-bold">Referencia</p>,
      id: 9,
      width: 140,
      accessor: "referencia"
    },
    {
      Header: () => <p className="font-weight-bold">Estatus</p>,
      id: 10,
      width: 170,
      accessor: "estatus",
      className: "text-uppercase"
    },
    {
      Header: () => <p className="font-weight-bold">Opciones</p>,
      id: 11,
      width: 80,
      accessor: "opciones",
      Cell: row => {
        return (
          <Container className="p-0">
            <Row className="m-0">
              {allowedTo(FACTURAR_CARGOS_CONCESIONARIOS) &&
              row.original.estatus == "PENDIENTE" ? (
                <Col className="px-1">
                  <a
                    id={`aceptar${row.original.movimientoId}`}
                    onClick={() =>
                      this.onClickAceptarCobro(row.original.movimientoId)
                    }
                  >
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={faCheckCircle}
                    />
                    <UncontrolledTooltip
                      placement="left"
                      target={`aceptar${row.original.movimientoId}`}
                    >
                      Facturar
                    </UncontrolledTooltip>
                  </a>
                </Col>
              ) : null}
              {allowedTo(BORRAR_CARGOS_CONCESIONARIOS) &&
              row.original.estatus == "PENDIENTE" ? (
                <Col className="px-1">
                  <a
                    id={`rechazar${row.original.movimientoId}`}
                    onClick={() =>
                      this.onClickEliminarCobro(row.original.movimientoId)
                    }
                  >
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={faTimesCircle}
                    />
                    <UncontrolledTooltip
                      placement="left"
                      target={`rechazar${row.original.movimientoId}`}
                    >
                      Rechazar
                    </UncontrolledTooltip>
                  </a>
                </Col>
              ) : null}
            </Row>
          </Container>
        );
      }
    }
  ];

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  onChangeConcepto = e => {
    let valFilters = this.state.filters;
    valFilters.idConcepto = e.target.value;
    let filterClubs = this.props.clubs.filter(
      value => value.concepto == e.target.value
    );
    this.setState({ filters: valFilters, clubs: filterClubs });
  };

  genReport = e => {
    e.preventDefault();
    const { filters } = this.state;
    this.props.downloadMovementStatus(
      filters.fechaDesde,
      filters.fechaHasta,
      filters
    );
  };

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const filters = {
          ...state.filters,
          [fieldName]: date
        };
        return { ...state, error: {}, filters };
      });
    };
  };

  search = e => {
    e.preventDefault();
    const { filters } = this.state;
    this.props.getMovements(filters.fechaDesde, filters.fechaHasta, filters);
  };

  render() {
    const { clubs, filters } = this.state;
    const contentDealers =
      this.props.dealersMovement && this.props.dealersMovement.content
        ? this.props.dealersMovement.content
        : [];
    return (
      <Container className="p-0">
        <div className="row mt-3 mb-3">
          <div className="col">
            <p className={`${s.primaryTitle} w-100 m-0`}>
              Estatus de movimientos
            </p>
          </div>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault();
            this.search(e);
          }}
        >
          <Row>
            <Col xs={12} md={4} lg={3}>
              <BaseInput
                label="Concepto"
                name="concepto"
                id="concepto"
                type="select"
                placeholder="Seleccionar concepto"
                value={filters.idConcepto}
                onChange={e => this.onChangeConcepto(e)}
                size={"sm"}
                options={[{ value: "", label: "Seleccionar concepto" }].concat(
                  CONCEPTOS
                    ? CONCEPTOS.map(option => ({
                        value: option.value,
                        label: option.label
                      }))
                    : []
                )}
              />
            </Col>
            <Col xs={12} md={4} lg={3}>
              <BaseInput
                label="Club"
                name="club"
                id="club"
                type="select"
                placeholder="Seleccionar club"
                value={filters.idClub}
                disabled={filters.idConcepto.length == 0}
                onChange={this.onChangeFilter("idClub")}
                size={"sm"}
                options={[{ value: "", label: "Seleccionar club" }].concat(
                  clubs.length > 0
                    ? clubs.map(option => ({
                        value: option.clubId,
                        label: option.nombre
                      }))
                    : []
                )}
              />
            </Col>
            <Col xs={12} md={8} lg={6}>
              <BaseInput
                label={"Nombre"}
                name="nombre"
                type="text"
                id="nombre"
                value={filters.nombre}
                onChange={this.onChangeFilter("nombre")}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4} lg={3}>
              <BaseInput
                label="Estatus"
                name="estatus"
                id="estatus"
                type="select"
                placeholder="Seleccionar estatus"
                value={filters.estatus}
                onChange={this.onChangeFilter("estatus")}
                size={"sm"}
                options={[{ value: "", label: "Seleccionar estatus" }].concat(
                  ESTATUS
                    ? ESTATUS.map(option => ({
                        value: option.value,
                        label: option.label
                      }))
                    : []
                )}
              />
            </Col>
            <Col xs={12} md={4} lg={3}>
              <DatePicker
                label="Desde"
                name="Desde"
                id="fechaDesde"
                selected={filters.fechaDesde}
                onChange={this.onChangeDate("fechaDesde")}
              />
            </Col>
            <Col xs={12} md={4} lg={3}>
              <DatePicker
                label="Hasta"
                name="Hasta"
                id="fechaHasta"
                selected={filters.fechaHasta}
                onChange={this.onChangeDate("fechaHasta")}
              />
            </Col>
            <Col xs={12} md={4} lg={3} className="mt-4">
              <Button
                size="sm"
                className={`float-right btn-block`}
                type="submit"
                disabled={
                  filters.fechaDesde == null || filters.fechaHasta == null
                }
              >
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
        <ReactTable
          data={contentDealers}
          columns={this.columns}
          sortable={false}
          resizable={false}
          showPagination={true}
          pageSize={this.state.filters.size}
          loading={this.state.updateTableCharge || this.state.updateTableDelete}
        />
        <Row>
          <Col></Col>
          <Col xs={12} md={4} lg={3} className="mt-4">
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.genReport}
              disabled={contentDealers.length == 0}
            >
              Generar Estatus de Movimiento
            </Button>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(MovementStatusPage));
