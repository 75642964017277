import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";

const baseUrl = `/api/expedientes`;

export const getPersonalDocumentsHistory = (
  idClub: any,
  idPublico: any
): IAction => {
  return {
    type: actionTypes.GET_PERSONAL_DOCUMENTS_HISTORY,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/club/${idClub}/membresia/${idPublico}`,
        {
          withCredentials: true
        }
      )
    }
  };
};
