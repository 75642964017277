import FormBuilderConfig from "../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IButtonBuilder from "../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import ISelectBuilder from "../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";

export type Inputs = "tipoReporte";
export type Buttons = "descargar";
export const formConfig: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "tipoReporte",
      label: "Tipo de reporte",
      bts: {
        mCol: 3
      },
      component: ISelectBuilder,
      props: {
        data: [
          { value: "R", label: "Zuma Amigos" },
          { value: "L", label: "Con lic. de extranjero" }
        ]
      }
    }
  ],
  buttons: [
    {
      name: "descargar",
      label: "Descargar",
      isSubmit: true,
      bts: {
        mCol: 3
      },
      component: IButtonBuilder
    }
  ],
  rows: [["tipoReporte", "descargar"]]
};
