import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import {
  ERROR,
  SEND_BILLING_PENDING,
  SEND_BILLING_SUCCESS,
  SEND_BILLING_ERROR,
  SEND_ACCRUAL_SUCCESS,
  SEND_ACCRUAL_ERROR,
  GET_ACCRUAL_MESSAGE_FULLIED,
  GET_ACCRUAL_MESSAGE_PENDING,
  GET_ACCRUAL_MESSAGE_ERROR,
  UPDATE_ACCRUAL_MESSAGE_FULLIED,
  UPDATE_ACCRUAL_MESSAGE_PENDING,
  UPDATE_ACCRUAL_MESSAGE_ERROR,
  infoNotification,
  errorNotification,
  successNotification,
  GET_BILL_INFO,
  CANCEL_BILL_REJECTED,
  CANCEL_BILL_FULFILLED,
  CANCEL_BILL_PENDING,
  REMAKE_BILL_REJECTED,
  REMAKE_BILL_FULFILLED,
  REMAKE_BILL_PENDING,
  SAVE_PAID_COMPLEMENT_FULLIED,
  SAVE_PAID_COMPLEMENT_PENDING,
  SAVE_PAID_COMPLEMENT_ERROR,
  GET_RETURN_INVOICE_MESSAGE_PENDING,
  GET_RETURN_INVOICE_MESSAGE_ERROR,
  GET_RETURN_INVOICE_MESSAGE_FULLIED,
  GET_TRANSACTION_BY_REFERENCE_MESSAGE_PENDING,
  GET_TRANSACTION_BY_REFERENCE_MESSAGE_ERROR,
  GET_TRANSACTION_BY_REFERENCE_MESSAGE_FULLIED,
  SAVE_RETURN_INVOICE_PENDING,
  SAVE_RETURN_INVOICE_ERROR,
  SAVE_RETURN_INVOICE_FULLIED
} from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";
interface State {
  sendingData: boolean;
  dataResponse: object;
  successSend: boolean;
  gettingCatalogs: boolean;
  billingCatalogs: object;
  bill: object;
  cancelSuccess: boolean;
  remakeSuccess: boolean;
  chargeListAccrual: boolean;
  listAccrual: object;
  chargeUpdateAccrual: boolean;
  responseAccrual: object;
  sendingAccrual: boolean;
  successSendAccrual: boolean;
  successSaveComplement: boolean;
  savingComplement: boolean;
  returnInvoiceList: object[];
  transactionByReference: object;
  saveReturnInvoice: boolean;
}
const initialState: State = {
  sendingData: false,
  dataResponse: null,
  successSend: false,
  billingCatalogs: null,
  gettingCatalogs: false,
  bill: null,
  cancelSuccess: false,
  remakeSuccess: false,
  chargeListAccrual: false,
  listAccrual: null,
  chargeUpdateAccrual: false,
  responseAccrual: null,
  sendingAccrual: false,
  successSendAccrual: false,
  successSaveComplement: false,
  savingComplement: false,
  returnInvoiceList: [],
  transactionByReference: {},
  saveReturnInvoice: false
};

export default function billing(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.SEND_ACCRUAL}_PENDING`: {
      return {
        ...state,
        sendingAccrual: true,
        successSendAccrual: false
      };
    }
    case `${actionTypes.SEND_ACCRUAL}_REJECTED`: {
      errorNotification(readMessageBack(action, SEND_ACCRUAL_ERROR));
      return {
        ...state,
        sendingAccrual: false,
        successSendAccrual: false
      };
    }
    case `${actionTypes.SEND_ACCRUAL}_FULFILLED`: {
      successNotification(SEND_ACCRUAL_SUCCESS);
      return {
        ...state,
        dataResponse: action.payload.data,
        sendingAccrual: false,
        successSendAccrual: true
      };
    }
    case `${actionTypes.SEND_BILLING}_PENDING`: {
      infoNotification(SEND_BILLING_PENDING);
      return {
        ...state,
        sendingData: true,
        successSend: false
      };
    }
    case `${actionTypes.SEND_BILLING}_REJECTED`: {
      errorNotification(readMessageBack(action, SEND_BILLING_ERROR));
      return {
        ...state,
        sendingData: false,
        successSend: false
      };
    }
    case `${actionTypes.SEND_BILLING}_FULFILLED`: {
      successNotification(SEND_BILLING_SUCCESS);
      return {
        ...state,
        dataResponse: action.payload.data,
        sendingData: false,
        successSend: true
      };
    }
    case `${actionTypes.GET_BILL}_PENDING`: {
      return {
        ...state
      };
    }
    case `${actionTypes.GET_BILL}_REJECTED`: {
      errorNotification(readMessageBack(action, GET_BILL_INFO));
      return {
        ...state
      };
    }
    case `${actionTypes.GET_BILL}_FULFILLED`: {
      return {
        ...state,
        bill: action.payload.data
      };
    }
    case `${actionTypes.REMAKE_BILL}_PENDING`: {
      infoNotification(REMAKE_BILL_PENDING);
      return {
        ...state,
        remakeSuccess: false,
        cancelSuccess: false
      };
    }
    case `${actionTypes.REMAKE_BILL}_REJECTED`: {
      errorNotification(readMessageBack(action, REMAKE_BILL_REJECTED));
      return {
        ...state,
        remakeSuccess: false,
        cancelSuccess: false
      };
    }
    case `${actionTypes.REMAKE_BILL}_FULFILLED`: {
      successNotification(REMAKE_BILL_FULFILLED);
      return {
        ...state,
        remakeSuccess: true,
        cancelSuccess: false
      };
    }
    case `${actionTypes.CANCEL_BILL}_PENDING`: {
      infoNotification(CANCEL_BILL_PENDING);
      return {
        ...state,
        cancelSuccess: false,
        remakeSuccess: false
      };
    }
    case `${actionTypes.CANCEL_BILL}_REJECTED`: {
      errorNotification(readMessageBack(action, CANCEL_BILL_REJECTED));
      return {
        ...state,
        remakeSuccess: false,
        cancelSuccess: false
      };
    }
    case `${actionTypes.CANCEL_BILL}_FULFILLED`: {
      successNotification(CANCEL_BILL_FULFILLED);
      return {
        ...state,
        remakeSuccess: false,
        cancelSuccess: true
      };
    }
    case `${actionTypes.GET_BILLING_CATALOGS}_PENDING`: {
      return {
        ...state,
        gettingCatalogs: true
      };
    }
    case `${actionTypes.GET_BILLING_CATALOGS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        gettingCatalogs: true
      };
    }
    case `${actionTypes.GET_BILLING_CATALOGS}_FULFILLED`: {
      return {
        ...state,
        billingCatalogs: action.payload.data,
        gettingCatalogs: false
      };
    }
    case `${actionTypes.GET_ACCRUAL}_PENDING`: {
      infoNotification(GET_ACCRUAL_MESSAGE_PENDING);
      return {
        ...state,
        chargeListAccrual: false,
        listAccrual: null
      };
    }
    case `${actionTypes.GET_ACCRUAL}_REJECTED`: {
      errorNotification(readMessageBack(action, GET_ACCRUAL_MESSAGE_ERROR));
      return {
        ...state,
        chargeListAccrual: false,
        listAccrual: null
      };
    }
    case `${actionTypes.GET_ACCRUAL}_FULFILLED`: {
      successNotification(GET_ACCRUAL_MESSAGE_FULLIED);
      return {
        ...state,
        chargeListAccrual: true,
        listAccrual: action.payload.data
      };
    }
    case `${actionTypes.GET_RETURN_INVOICE}_PENDING`: {
      return {
        ...state,
        returnInvoiceList: []
      };
    }
    case `${actionTypes.GET_RETURN_INVOICE}_REJECTED`: {
      errorNotification(
        readMessageBack(action, GET_RETURN_INVOICE_MESSAGE_ERROR)
      );
      return {
        ...state,
        returnInvoiceList: []
      };
    }
    case `${actionTypes.GET_RETURN_INVOICE}_FULFILLED`: {
      return {
        ...state,
        returnInvoiceList: action.payload.data
      };
    }
    case `${actionTypes.GET_TRANSACTION_BY_REFERENCE}_PENDING`: {
      infoNotification(GET_TRANSACTION_BY_REFERENCE_MESSAGE_PENDING);
      return {
        ...state,
        transactionByReference: {}
      };
    }
    case `${actionTypes.GET_TRANSACTION_BY_REFERENCE}_REJECTED`: {
      errorNotification(
        readMessageBack(action, GET_TRANSACTION_BY_REFERENCE_MESSAGE_ERROR)
      );
      return {
        ...state,
        transactionByReference: {}
      };
    }
    case `${actionTypes.GET_TRANSACTION_BY_REFERENCE}_FULFILLED`: {
      successNotification(GET_TRANSACTION_BY_REFERENCE_MESSAGE_FULLIED);
      return {
        ...state,
        transactionByReference: action.payload.data
      };
    }
    case `${actionTypes.UPDATE_ACCRUAL}_PENDING`: {
      infoNotification(UPDATE_ACCRUAL_MESSAGE_PENDING);
      return {
        ...state,
        chargeUpdateAccrual: false,
        responseAccrual: null
      };
    }
    case `${actionTypes.UPDATE_ACCRUAL}_REJECTED`: {
      errorNotification(readMessageBack(action, UPDATE_ACCRUAL_MESSAGE_ERROR));
      return {
        ...state,
        chargeUpdateAccrual: false,
        responseAccrual: null
      };
    }
    case `${actionTypes.UPDATE_ACCRUAL}_FULFILLED`: {
      successNotification(UPDATE_ACCRUAL_MESSAGE_FULLIED);
      return {
        ...state,
        chargeUpdateAccrual: true,
        responseAccrual: action.payload.data
      };
    }
    case `${actionTypes.SAVE_PAID_COMPLEMENT}_PENDING`: {
      infoNotification(SAVE_PAID_COMPLEMENT_PENDING);
      return {
        ...state,
        successSaveComplement: false,
        savingComplement: true
      };
    }
    case `${actionTypes.SAVE_PAID_COMPLEMENT}_REJECTED`: {
      errorNotification(readMessageBack(action, SAVE_PAID_COMPLEMENT_ERROR));
      return {
        ...state,
        successSaveComplement: false,
        savingComplement: false
      };
    }
    case `${actionTypes.SAVE_PAID_COMPLEMENT}_FULFILLED`: {
      successNotification(SAVE_PAID_COMPLEMENT_FULLIED);
      return {
        ...state,
        successSaveComplement: true,
        savingComplement: false
      };
    }
    case `${actionTypes.SAVE_RETURN_INVOICE}_PENDING`: {
      infoNotification(SAVE_RETURN_INVOICE_PENDING);
      return {
        ...state,
        saveReturnInvoice: false
      };
    }
    case `${actionTypes.SAVE_RETURN_INVOICE}_REJECTED`: {
      errorNotification(readMessageBack(action, SAVE_RETURN_INVOICE_ERROR));
      return {
        ...state,
        saveReturnInvoice: false
      };
    }
    case `${actionTypes.SAVE_RETURN_INVOICE}_FULFILLED`: {
      successNotification(SAVE_RETURN_INVOICE_FULLIED);
      return {
        ...state,
        saveReturnInvoice: true
      };
    }
    case `${actionTypes.CLEAR_BILLING_REDUX}`: {
      return {
        ...state,
        transactionByReference: {}
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
