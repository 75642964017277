import React from "react";
import moment from "moment";
import { Container } from "reactstrap";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

interface Props {
  cashboxList: any[];
  selectCashbox: (id: number) => void;
  loading: boolean;
}

const CashBoxList = React.memo(
  ({ cashboxList, selectCashbox, loading }: Props) => {
    const columns: any = [
      {
        Header: "Cliente",
        accessor: "clienteNombre"
      },
      {
        Header: "Fecha creacion",
        accessor: "fechaCreacion",
        Cell: ({ value: fechaCreacion, index }) => {
          return (
            <span>{`${moment(fechaCreacion).format("DD-MM-YYYY HH:mm")}`}</span>
          );
        }
      },
      {
        Header: "# items",
        accessor: "items"
      },
      {
        accessor: "id",
        width: 25,
        Cell: ({ value: id, index }) => {
          return (
            <a onClick={() => selectCashbox(id)} style={{ cursor: "pointer" }}>
              <FontAwesomeIcon icon={faArrowRight} />
            </a>
          );
        }
      }
    ];

    return (
      <Container className="row p-0 m-0 mt-2">
        <ReactTable
          manual
          data={cashboxList || []}
          columns={columns}
          className="w-100 -striped -highlight"
          loading={loading}
          sortable={false}
          showPagination={false}
          defaultPageSize={(cashboxList || []).length + 1}
        />
      </Container>
    );
  }
);

export default CashBoxList;
