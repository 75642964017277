import React from "react";
import { connect } from "react-redux";
import * as promotionActions from "../../actions/promotions";
import * as agreementsActions from "../../actions/agreements";
import { withRouter } from "react-router-dom";
import AddAgreementsToPromotion from "../../components/addAgreementsToPromotion";
import { TYPE_OPTIONS } from "../../../types-business/Promotions";

interface Props {
  agreements: { convenioId: number; nombre: string }[];
  searchAgreementsByAttributes: () => any;
  successfulSave: boolean;
  onClickSave: (e: object) => any;
  addAgreementsToPromotion: (promotionId: string, agreements: object) => any;
  getAgreementsPromotion: (id: string) => any;
  agreementsPromotion: { convenio: number }[];
  match: any;
  promotion: { tipoAplicabilidad: TYPE_OPTIONS };
  getPromotionById: (promotionId: string) => any;
}

class AddMaintenancesPromotionPage extends React.Component<Props> {
  render() {
    return (
      <AddAgreementsToPromotion
        agreements={this.props.agreements}
        searchAgreementsByAttributes={this.props.searchAgreementsByAttributes}
        promotion={this.props.promotion}
        getPromotionById={this.props.getPromotionById}
        agreementsPromotion={this.props.agreementsPromotion}
        getAgreementsPromotion={this.props.getAgreementsPromotion}
        addAgreementsToPromotion={this.props.addAgreementsToPromotion}
        onClickSave={this.props.onClickSave}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  searchAgreementsByAttributes: (attributes: object) => {
    dispatch(agreementsActions.searchByAttributes(attributes));
  },
  addAgreementsToPromotion: (promotionId: string, agreements: object) => {
    dispatch(
      promotionActions.addAgreementsToPromotion(promotionId, agreements)
    );
  },
  getPromotionById: (promotionId: string) => {
    dispatch(promotionActions.getPromotionById(promotionId));
  },
  getAgreementsPromotion: (id: string) => {
    dispatch(promotionActions.getAgreementsPromotion(id));
  }
});

const mapStateToProps = state => {
  const { promotions, agreements } = state;

  return {
    successfulSave: promotions.maintenancesSaved,
    agreements: agreements.agreementsByAttributes,
    agreementsPromotion: promotions.promotionAgreements,
    promotion: promotions.promotionById
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddMaintenancesPromotionPage)
);
