import React from "react";
import { withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import moment from "moment";
import ModalFrame from "../../../toolkit/modalFrame";
import { Container, Button, FormGroup, Label, Row, Col } from "reactstrap";
import TimePicker from "react-times";
import DatePicker from "../common/DatePicker";
import ReactTable from "react-table";
import BaseInput from "../../../toolkit/baseInput";
import PersonSelector from "../../../People/containers/PersonSelector";
import { savePersonClubIngress } from "../../actions/persons";
import {
  successNotification,
  errorNotification,
  infoNotification
} from "../../../utils/notifications";
import s from "./styles.scss";
import pullAllBy from "lodash.pullallby";
import SimpleTableNotificationsMessages from "../partners/simpleTableNotificationsMessages";

interface Person {
  personaId: number;
  nombre: string;
  primerApellido: string;
  tipo: string;
}

interface Props {
  clubs: any[];
}

interface State {
  clubId: number;
  person: Person;
  ingressType: string;
  ingressItems: any[];
  ingressDate: string;
  ingressTime: string;
  isOpen: boolean;
  data: object;
}

const INGRESS_TYPE = [
  { value: "ENTRADA", label: "ENTRADA" },
  { value: "SALIDA", label: "SALIDA" }
];

const INFO_MESSAGE = "Registrando ingreso a club...";
const SUCCESS_MESSAGE = "Registro de ingreso a club realizado correctamente.";
const ERROR_MESSAGE = "Error al registrar ingreso a club.";

const getIngressRowStatusClass = (ingress: any) => {
  if (ingress.tipoIngresoClub != "NORMAL") {
    return "table-danger";
  }
  return "";
};

class PersonClubIngress extends React.Component<Props, State> {
  state = {
    clubId: -1,
    person: null,
    ingressType: "",
    ingressItems: [],
    ingressDate: "",
    ingressTime: "8:00",
    isOpen: false,
    data: null
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      data: null
    });
  };

  getOptionsClubes = clubs => {
    return [{ value: "", label: "Seleccionar" }].concat(
      (clubs || [])
        .filter(club => !!club.nombre)
        .map(club => {
          return { label: club.nombre, value: club.clubId };
        })
    );
  };

  getOptionsIngressType = () => {
    return [{ value: "", label: "Seleccionar" }].concat([...INGRESS_TYPE]);
  };

  handleClubChanged = e => {
    const clubId = e.target.value;
    this.setState({ clubId });
  };

  handleIngressTypeChanged = e => {
    const ingressType = e.target.value;
    this.setState({ ingressType });
  };

  handleIngressDateChanged = () => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState({
        ingressDate: date
      });
    };
  };

  handleIngressTimeChanged = time => {
    const ingressTime = `${time.hour}:${time.minute}`;
    this.setState({ ingressTime });
  };

  setPerson = async (selectedPerson: Person) => {
    if (!!selectedPerson) {
      this.setState({
        person: selectedPerson,
        clubId: -1,
        ingressType: "",
        ingressItems: [],
        ingressDate: "",
        ingressTime: "8:00"
      });
    }
  };

  handleSaveIngress = () => {
    const {
      person,
      clubId,
      ingressType,
      ingressDate,
      ingressTime
    } = this.state;

    const body = {
      idPersona: person.personaId,
      idClub: clubId,
      fecha: ingressDate,
      hora: ingressTime,
      tipoIngreso: ingressType.toUpperCase()
    };

    infoNotification(INFO_MESSAGE);
    savePersonClubIngress(body)
      .then(({ data }) => {
        if (data.accesoDenegado) {
          this.setState({
            data: data.mensajes,
            isOpen: true
          });
        } else {
          successNotification(SUCCESS_MESSAGE);
          const currentItems = [...this.state.ingressItems];
          pullAllBy(currentItems, [data], "id");
          this.setState({
            ingressItems: [...currentItems, data]
          });
        }
      })
      .catch((error: any) => {
        errorNotification(ERROR_MESSAGE);
      });
  };

  render() {
    const columns = [
      {
        Header: "Persona",
        accessor: "nombrePersona"
      },
      {
        Header: "Club",
        accessor: "nombreClub"
      },
      {
        Header: "Fecha de ingreso",
        accessor: "fechaIngreso"
      },
      {
        Header: "Hora entrada",
        accessor: "horaEntrada"
      },
      {
        Header: "Hora salida",
        accessor: "horaSalida"
      },
      {
        Header: "Estatus",
        accessor: "estatus"
      }
    ];
    return (
      <Container className="p-0 mt-3">
        <ModalFrame
          title={"Notificaciones"}
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          size="md"
        >
          <Container>
            {this.state.data ? (
              <SimpleTableNotificationsMessages messages={this.state.data} />
            ) : (
              ""
            )}
            <Row>
              <Col>
                <Button color="primary" onClick={this.toggle}>
                  Aceptar
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalFrame>
        <p className={`mt-2 ${s.primaryTitle}`}>
          Registro (MOCK) de entradas y salidas de una persona en un club
        </p>
        <div className="row">
          <div className="col-md-4 pb-5">
            <div>
              <div>
                <PersonSelector
                  setPerson={this.setPerson}
                  cKey={"XNYOLO5P1F"}
                />
              </div>
            </div>
            <div>
              <BaseInput
                label="Club"
                name="ingressClub"
                type="select"
                id="ingressClub"
                placeholder="Club"
                value={this.state.clubId}
                options={this.getOptionsClubes(this.props.clubs)}
                onChange={this.handleClubChanged}
              />
            </div>
            <div>
              <BaseInput
                label="Tipo de evento"
                name="ingressType"
                type="select"
                id="ingressType"
                placeholder="Tipo de ingreso"
                value={this.state.ingressType}
                options={this.getOptionsIngressType()}
                onChange={this.handleIngressTypeChanged}
              />
            </div>
            <div>
              <DatePicker
                label="Fecha"
                name="ingressDate"
                id="ingressDate"
                selected={this.state.ingressDate}
                onChange={this.handleIngressDateChanged()}
              />
            </div>
            <div>
              <FormGroup>
                <Label for="ingressTime">Hora</Label>
                <TimePicker
                  id="ingressTime"
                  name="ingressTime"
                  timeMode="24"
                  onTimeChange={time => this.handleIngressTimeChanged(time)}
                  time={this.state.ingressTime || "8:00"}
                  theme="classic"
                  timeConfig={{
                    from: 6,
                    to: 23,
                    step: 15,
                    unit: "minutes"
                  }}
                  withoutIcon
                />
              </FormGroup>
            </div>
            <div className="mt-3 text-right">
              <Button
                size="sm"
                className={`${s.searchButton} ${s.primaryButton} float-right btn-small`}
                onClick={this.handleSaveIngress}
                disabled={
                  !this.state.clubId ||
                  !this.state.ingressType ||
                  !this.state.person ||
                  !this.state.ingressDate ||
                  !this.state.ingressTime
                }
              >
                Registrar
              </Button>
            </div>
          </div>
          <div className="col-md-1 pb-5"></div>
          <div className="col-md-7 pb-5">
            <ReactTable
              data={this.state.ingressItems}
              columns={columns}
              pageSize={15}
              showPagination={false}
              className="mt-3"
              getTrProps={(_, ingress) => {
                return ingress
                  ? { className: getIngressRowStatusClass(ingress.original) }
                  : {};
              }}
            />
          </div>
        </div>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(PersonClubIngress));
