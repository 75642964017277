import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Row, Col, Button } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import s from "./styles.scss";
import ReactTable from "react-table";
import MexicanCurrency from "../common/currency";
import isNil from "lodash.isnil";
import {
  errorNotification,
  ERROR_ITEM_VALUE_EXCESS
} from "../../../utils/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { TIPO_MONTO } from "../../../utils/constants";
import moment from "moment";
// import locale from 'antd/es/date-picker/locale/es_ES';

interface Props {
  clubs: { clubId: number; nombre: string }[];
  onChangeTaxDistribution: (
    taxDistribution: any[],
    TaxDistributionValidated: boolean
  ) => any;
  tipoItemData: { idCampo; label }[];
  billingData: {
    billingCatalogs: any[];
    dataResponse: object;
    gettingCatalogs: boolean;
    sendingData: boolean;
    successSend: boolean;
  };
  disabled: boolean;
  items: object[];
  taxDistribution: object[];
  accountingAccounts: {
    cuentaContableId: number;
    nombre: string;
    numeroCuenta: string;
  }[];
}

interface State {
  fields: {
    item: string;
    nombre: string;
    monto: number;
    cuentaContable: number;
    clubId: number;
    devengo: number;
    id: number;
    activa: boolean;
    tipoMonto: string;
    renglon: number;
    fechaContable: string;
    periodo: string;
  };
  validData: boolean;
  numberId: number;
}

const formatTime = (dateTime?) =>
  moment(dateTime)
    .utc()
    .format("YYYY-MM-DD");

class TaxDistributionData extends React.Component<Props, State> {
  state = {
    fields: {
      item: null,
      nombre: "VACIO",
      monto: null,
      cuentaContable: null,
      clubId: null,
      devengo: null,
      id: null,
      activa: false,
      tipoMonto: null,
      renglon: null,
      fechaContable: formatTime(),
      periodo: "jul-20"
    },
    validData: false,
    numberId: 1
  };

  onChangeField = async (e, fieldName) => {
    let state = { ...this.state };
    state.fields[fieldName] = e.target.value;
    if (fieldName == "item") {
      state.fields.id = state.numberId;
      let nombre =
        this.props.items[e.target.value].tipo.toUpperCase() +
        "/" +
        this.props.items[e.target.value].nombreProducto.toUpperCase();
      state.fields.nombre = nombre;
      state.fields.renglon = parseInt(e.target.value) + 1;
    } else if (fieldName == "devengo" && e.target.value == 1) {
      state.fields.tipoMonto = TIPO_MONTO[e.target.value - 1];
      state.fields.activa = true;
    } else if (fieldName == "devengo" && e.target.value == 2) {
      state.fields.tipoMonto = TIPO_MONTO[e.target.value - 1];
      state.fields.activa = false;
    } else {
      state.fields.activa = false;
    }
    await this.setState(state);
    if (this.validateFields()) {
      this.setState({
        ...this.state,
        validData: true,
        numberId: this.state.numberId + 1
      });
    } else {
      this.setState({ ...this.state, validData: false });
    }
  };

  validateFields = () => {
    const { fields } = this.state;
    for (const key in fields) {
      if (fields.hasOwnProperty(key)) {
        const element = fields[key];
        if (isNil(element)) return false;
      }
    }
    return true;
  };

  getClearFields = () => ({
    item: null,
    monto: null,
    cuentaContable: null,
    clubId: null,
    devengo: null,
    id: null,
    activa: false,
    tipoMonto: null,
    renglon: null,
    fechaContable: formatTime(),
    periodo: "jul-20"
  });

  onDeleteField = id => {
    const state = { ...this.state };
    const taxDistribution = [...this.props.taxDistribution];
    let deleteValue = -1;
    taxDistribution.forEach((element, index) => {
      if (element.id == id) {
        deleteValue = index;
      }
    });
    if (deleteValue >= 0) {
      taxDistribution.splice(deleteValue, 1);
      this.props.onChangeTaxDistribution(taxDistribution, true);
      state.fields = this.getClearFields();
      state.validData = false;
      this.setState(state);
    }
  };

  addItem = async () => {
    const state = { ...this.state };
    const taxDistribution = [...this.props.taxDistribution];
    const items = [...this.props.items];
    let cantMoney = 0;
    if (taxDistribution && taxDistribution.length > 0) {
      taxDistribution.forEach(element => {
        if (element.item == state.fields.item) {
          cantMoney = cantMoney + parseFloat(element.monto);
        }
      });
    }
    cantMoney = cantMoney + parseFloat(state.fields.monto);
    if (cantMoney > parseFloat(items[state.fields.item].importe)) {
      errorNotification(ERROR_ITEM_VALUE_EXCESS);
    } else {
      taxDistribution.push(state.fields);
      this.props.onChangeTaxDistribution(taxDistribution, true);
      state.fields = this.getClearFields();
      state.validData = false;
      this.setState(state);
    }
  };

  render() {
    let CatProductoServicio = [];
    for (let catalog of this.props.billingData.billingCatalogs) {
      if (catalog["clave"] == "CatProductoServicio")
        CatProductoServicio = catalog["data"];
    }

    const itemsTablecolumns = [
      {
        Header: "item",
        accessor: "nombre"
      },
      {
        Header: "monto",
        accessor: "monto",
        Cell: row => (
          <p className="text-right m-0 p-0">
            <MexicanCurrency quantity={row.value} />
          </p>
        )
      },
      {
        Header: "cuenta contable",
        accessor: "cuentaContable",
        Cell: row => {
          return (
            <a>
              {" "}
              {row.original.cuentaContable
                ? this.props.accountingAccounts.map(element => {
                    if (
                      element.cuentaContableId == row.original.cuentaContable
                    ) {
                      return (
                        element.numeroCuenta +
                        " - " +
                        element.nombre.toUpperCase()
                      );
                    }
                  })
                : ""}{" "}
            </a>
          );
        }
      },
      {
        Header: "club",
        accessor: "clubId",
        Cell: row => {
          return (
            <a>
              {" "}
              {row.original.clubId
                ? this.props.clubs.map(element => {
                    if (element.clubId == row.original.clubId) {
                      return element.nombre.toUpperCase();
                    }
                  })
                : ""}{" "}
            </a>
          );
        }
      },
      {
        Header: "tipo de movimiento",
        accessor: "devengo",
        Cell: row => {
          return <a> {row.original.devengo == 1 ? "DEVENGO" : "INGRESO"} </a>;
        }
      },
      {
        Header: "acciones",
        accessor: "acciones",
        Cell: row => {
          return this.props.disabled ? (
            <a id={`accrual${row.original.id}`}>
              <FontAwesomeIcon icon={faMinusCircle} />
            </a>
          ) : (
            <a
              id={`accrual${row.original.id}`}
              onClick={() => {
                this.onDeleteField(row.original.id);
              }}
            >
              <FontAwesomeIcon
                style={{ cursor: "pointer" }}
                icon={faMinusCircle}
              />
            </a>
          );
        }
      }
    ];

    let dataTable = this.props.taxDistribution;
    let idItem = 0;
    return (
      <span>
        <Row>
          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*item:"}
              name={`item`}
              type="select"
              id={`item`}
              placeholder={"item"}
              options={[{ value: -1, label: "Seleccionar el item" }].concat(
                this.props.items.map(element => {
                  idItem++;
                  return {
                    value: idItem - 1,
                    label:
                      element.tipo.toUpperCase() +
                      "/" +
                      element.nombreProducto.toUpperCase()
                    // element.areaSw.toUpperCase() +
                    // "/" +
                    // element.categoriaSw.toUpperCase()
                  };
                })
              )}
              value={
                isNil(this.state.fields.item) ? -1 : this.state.fields.item
              }
              onChange={e => this.onChangeField(e, "item")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*monto"}
              name={`Monto`}
              type="text"
              id={`monto`}
              placeholder={"0"}
              value={
                isNil(this.state.fields.monto) ? "" : this.state.fields.monto
              }
              onChange={e => this.onChangeField(e, "monto")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*cuenta contable"}
              name={`cuentaContable`}
              type="select"
              id={`cuentaContable`}
              placeholder={"cuentaContable"}
              options={[
                { value: -1, label: "Seleccionar cuenta contable" }
              ].concat(
                this.props.accountingAccounts.map(element => {
                  return {
                    value: element.cuentaContableId,
                    label:
                      element.numeroCuenta +
                      " - " +
                      element.nombre.toUpperCase()
                  };
                })
              )}
              value={
                isNil(this.state.fields.cuentaContable)
                  ? -1
                  : this.state.fields.cuentaContable
              }
              onChange={e => this.onChangeField(e, "cuentaContable")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*club"}
              name={`clubId`}
              type="select"
              id={`clubId`}
              placeholder={"clubId"}
              options={[{ value: -1, label: "Seleccionar el club" }].concat(
                this.props.clubs.map(element => {
                  return {
                    value: element.clubId,
                    label: element.nombre.toUpperCase()
                  };
                })
              )}
              value={
                isNil(this.state.fields.clubId) ? -1 : this.state.fields.clubId
              }
              onChange={e => this.onChangeField(e, "clubId")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*devengo/ingreso"}
              name={`devengo`}
              type="select"
              id={`devengo`}
              placeholder={"devengo"}
              options={[
                { value: -1, label: "Seleccionar devengo/ingreso" },
                { value: 1, label: "DEVENGO" },
                { value: 2, label: "INGRESO" }
              ]}
              value={
                isNil(this.state.fields.devengo)
                  ? -1
                  : this.state.fields.devengo
              }
              onChange={e => this.onChangeField(e, "devengo")}
            />
          </Col>

          <Col sm="2">
            <Button
              disabled={!this.state.validData}
              block
              onClick={() => this.addItem()}
              className="mt-4 mb-1"
              style={{ height: "2rem" }}
            >
              +ITEM
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <ReactTable
              pages={1}
              data={dataTable}
              columns={itemsTablecolumns}
              showPagination={false}
              sortable={true}
              multiSort={true}
              resizable={true}
              pageSize={
                this.props.taxDistribution &&
                this.props.taxDistribution.length > 0
                  ? this.props.taxDistribution.length + 3
                  : 3
              }
            />
          </Col>
        </Row>
      </span>
    );
  }
}

export default withStyles(s)(TaxDistributionData);
