import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";

export const getActivitiesBySeller = (
  sellerId: string,
  params: object
): IAction => {
  let baseUrl = `/api/activity/quotes/${sellerId}`;
  return {
    type: actionTypes.GET_QUOTES_BY_SELLER,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}`, {
        withCredentials: true,
        params
      })
    }
  };
};
