import React from "react";
import BaseInput from "../../../toolkit/baseInput";
import IInput from "../iInput/IInput";
import ITextProps from "./IText.props";
import ITextState from "./IText.state";

export default class IText extends IInput<ITextProps, ITextState> {
  state = {
    value: ""
  };

  componentDidMount(): void {
    if (this.props.init) {
      this.setState(
        {
          value: this.props.init
        },
        () => super.triggerInput()
      );
    } else {
      super.triggerInput();
    }
  }

  componentDidUpdate(prevProps): void {
    if (this.props.init != prevProps.init) {
      this.setState({
        value: this.props.init
      });
    }
  }

  onChange = evt => {
    const value = evt.target.value;
    this.props.setter(this.props.name, value);
    this.props.onChange && this.props.onChange(value);
    this.setState({ value });
  };

  render() {
    if (this.props.hidden) {
      return null;
    }

    return (
      <div className={this.btsClassName()}>
        <BaseInput
          label={this.props.label}
          name={this.props.name}
          type="text"
          id={`${this.props.formKey || ""}-${this.props.name}`}
          size={"sm"}
          disabled={this.props.disabled}
          value={this.state.value}
          onChange={this.onChange}
          errors={
            this.props.dirty && this.props.message ? [this.props.message] : null
          }
          maxlength={this.props.maxlength || null}
        />
      </div>
    );
  }
}
