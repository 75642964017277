import { Preview } from "../../../types/IAction";
import * as actions from "../../../Home/actions/convenios";
import {
  GetConvenioEmpresasActivas,
  GetConvenioEmpresasConvenio
} from "../../../Home/reducers/convenios";

export interface IEmpresaConvenioSelectorDispatcher {
  getEmpresasConvenio: () => Promise<Preview<GetConvenioEmpresasConvenio>>;
  getEmpresasActivas: (
    idClub,
    idMantenimiento
  ) => Promise<Preview<GetConvenioEmpresasActivas>>;
}

export const iEmpresaConvenioSelectorDispatcher: (
  dispatch: (...args) => Promise<Preview<any>>
) => IEmpresaConvenioSelectorDispatcher = dispatch => ({
  getEmpresasConvenio: () => {
    return dispatch(actions.getEmpresasConvenio());
  },
  getEmpresasActivas: (idClub, idMantenimiento) => {
    return dispatch(actions.getEmpresasActivas(idClub, idMantenimiento));
  }
});
