import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Label
} from "reactstrap";
import s from "./styles.scss";
import moment from "moment";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import MexicanCurrency from "../../../Home/components/common/currency";
import { QUOTATIONS_MEMBER_TYPE } from "../../../utils/constants";
import { QuotationInterface } from "../../types";
import isNil from "lodash.isnil";

export const LAST_MONTH = 12;
export const FIRST_MONTH = moment().month();
export const TODAY_NUMBER_MONTH = moment().date();

export const getDefaultMembers = () => [
  {
    tipo: QUOTATIONS_MEMBER_TYPE.NORMAL,
    numero: 1
  }
];

interface Props {
  quotation: QuotationInterface;
  enableMemberEdition: boolean;
  enableColumnEdition: boolean;
  lastMonth: number;
  checkMode: boolean;
  onChangeMonth: (number) => any;
  updateSelectedMonths: (months) => any;
}

const getTypeName = (type: QUOTATIONS_MEMBER_TYPE) => {
  switch (type) {
    case QUOTATIONS_MEMBER_TYPE.BABY:
      return "BEBE";
    case QUOTATIONS_MEMBER_TYPE.NORMAL:
      return "INTEGRANTE";
    case QUOTATIONS_MEMBER_TYPE.EXTRA:
      return "EXTRA";
  }
};

const getMonthHeader = month =>
  moment()
    .month(month - 1)
    .format("MMM")
    .toUpperCase();

const getPriceByTypeMember = (member, type: QUOTATIONS_MEMBER_TYPE) => {
  /* switch (type) {
    case QUOTATIONS_MEMBER_TYPE.BABY:
      return member.precio;
    case QUOTATIONS_MEMBER_TYPE.NORMAL:
      return member.precio;
    case QUOTATIONS_MEMBER_TYPE.EXTRA:
      return member.precio;
  } */

  switch (type) {
    case QUOTATIONS_MEMBER_TYPE.BABY:
      return member.precioConDescuentoFormaPago;
    case QUOTATIONS_MEMBER_TYPE.NORMAL:
      return member.precioConDescuentoFormaPago;
    case QUOTATIONS_MEMBER_TYPE.EXTRA:
      return member.precioConDescuentoFormaPago;
  }
};

const blockAddButtons = (integrantes, integrantesAdded) => {
  return (
    !integrantes ||
    integrantesAdded === integrantes.normales + integrantes.extra
  );
};

const blockAddBabyButton = (integrantes, babiesAdded) => {
  return !integrantes || babiesAdded === integrantes.bebe;
};

const defaultMembersState = () => ({
  integrantesAdded: 1,
  babiesAdded: 0,
  membersState: getDefaultMembers(),
  selectedMonths: []
});

class QuotationsTable extends React.Component<Props> {
  state = {
    ...defaultMembersState(),
    lastMonth: this.props.lastMonth || LAST_MONTH
  };

  reloadMembers = () => {
    const { quotation, enableMemberEdition } = this.props;
    const defaultState = defaultMembersState();

    if (!enableMemberEdition) {
      if (
        quotation &&
        quotation.preciosMantenimiento &&
        !isNil(quotation.preciosMantenimiento[0])
      ) {
        const {
          integrantes: { normales, bebes, extras }
        } = quotation.preciosMantenimiento[0];
        defaultState.babiesAdded = bebes.length;
        defaultState.integrantesAdded = normales.length;
        const memberState = [];
        normales.forEach((_, index) => {
          memberState.push({
            numero: index++,
            tipo: QUOTATIONS_MEMBER_TYPE.NORMAL
          });
        });
        extras.forEach((_, index) => {
          memberState.push({
            numero: index++,
            tipo: QUOTATIONS_MEMBER_TYPE.EXTRA
          });
        });
        bebes.forEach((_, index) => {
          memberState.push({
            numero: index++,
            tipo: QUOTATIONS_MEMBER_TYPE.BABY
          });
        });

        defaultState.membersState = memberState;
        defaultState.selectedMonths = [
          { ...quotation.preciosMantenimiento[0] }
        ];
      }
    }
    this.setState({ ...defaultState });
  };

  componentDidMount() {
    this.reloadMembers();
  }

  componentDidUpdate({ quotation: prevQuotation }) {
    const { quotation } = this.props;

    if (prevQuotation !== quotation) {
      this.reloadMembers();
    }
  }

  deleteMonth = () => {
    if (this.state.lastMonth > FIRST_MONTH) {
      this.props.onChangeMonth(this.state.lastMonth - 1);
      this.setState({ lastMonth: this.state.lastMonth - 1 });
    }
  };

  addMonth = () => {
    if (this.state.lastMonth < LAST_MONTH) {
      this.props.onChangeMonth(this.state.lastMonth + 1);
      this.setState({ lastMonth: this.state.lastMonth + 1 });
    }
  };

  deleteMember = integrantes => {
    if (integrantes.length === 1) {
      return;
    }

    const members = [...this.state.membersState];
    members.shift();
    this.setState({
      membersState: members,
      integrantesAdded: members.length
    });
  };

  addMember = (type: QUOTATIONS_MEMBER_TYPE) => {
    const integrantes = [...this.state.membersState];
    let { integrantesAdded, babiesAdded } = this.state;
    const { integrantes: integrantesCotizacion } = this.props.quotation;

    if (type === QUOTATIONS_MEMBER_TYPE.BABY) {
      integrantes.push({
        tipo: type,
        numero: integrantes.length + 1
      });
      babiesAdded++;
    } else {
      integrantesAdded++;
      if (integrantesAdded > integrantesCotizacion.normales) {
        integrantes.push({
          tipo: QUOTATIONS_MEMBER_TYPE.EXTRA,
          numero: integrantes.length + 1
        });
      } else {
        integrantes.unshift({
          tipo: type,
          numero: integrantes.length + 1
        });
      }
    }

    this.setState({
      babiesAdded,
      integrantesAdded,
      membersState: integrantes
    });
  };

  onChangeCheckedMonth = async monthItem => {
    if (this.isMonthDisabled(monthItem)) return;

    const { selectedMonths } = this.state;
    const value = monthItem.numero;
    const checked = !!selectedMonths.find(x => x.numero == value);

    if (!checked) {
      const item = this.props.quotation.preciosMantenimiento.find(
        x => x.numero == value
      );
      await this.setState({ selectedMonths: [...selectedMonths, item] });
    } else {
      await this.setState({
        selectedMonths: [...selectedMonths.filter(x => x.numero != value)]
      });
    }

    if (!!this.props.updateSelectedMonths) {
      this.props.updateSelectedMonths([...this.state.selectedMonths]);
    }
  };

  isPreviousMonthChecked = monthItem => {
    const { numero: mNum } = monthItem;
    return (
      mNum == 1 ||
      mNum - 1 == 1 ||
      !!this.state.selectedMonths.find(x => x.numero == mNum - 1)
    );
  };

  isMonthChecked = monthItem => {
    const { numero: mNum } = monthItem;
    return !!this.state.selectedMonths.find(x => x.numero == mNum);
  };

  isMonthDisabled = monthItem => {
    const { numero: mNum } = monthItem;
    const numeros = (this.state.selectedMonths || []).map(x => x.numero);
    return (
      mNum == 1 ||
      (this.isMonthChecked(monthItem) &&
        !!numeros &&
        !!numeros.length &&
        mNum != Math.max(...numeros))
    );
  };

  render() {
    const { enableMemberEdition, enableColumnEdition, quotation } = this.props;

    const {
      babiesAdded,
      integrantesAdded,
      membersState,
      lastMonth,
      selectedMonths
    } = this.state;

    const buttonsStyle = {
      padding: "1px 1px",
      width: "35px",
      fontSize: "0.7rem"
    };

    const numberTypes = {
      [QUOTATIONS_MEMBER_TYPE.NORMAL]: 0,
      [QUOTATIONS_MEMBER_TYPE.BABY]: 0,
      [QUOTATIONS_MEMBER_TYPE.EXTRA]: 0
    };

    const integrantes = quotation
      ? quotation.integrantes
      : {
          normales: 0,
          bebes: 0,
          extra: 0
        };

    const preciosMantenimiento = quotation
      ? quotation.preciosMantenimiento || []
      : [];

    const columns = [
      {
        Header: () => (
          <Container className="w-100 m-0 p-0">
            <Row className="w-100 m-0 p-0">
              <Col className="p-0 px-1 m-0" xs={6}>
                <Button
                  className={`${s.primaryButton} btn-block`}
                  size="sm"
                  onClick={() => this.addMember(QUOTATIONS_MEMBER_TYPE.NORMAL)}
                  disabled={
                    !enableMemberEdition ||
                    !quotation ||
                    blockAddButtons(integrantes, integrantesAdded)
                  }
                  style={buttonsStyle}
                >
                  +INT
                </Button>
              </Col>
              <Col className="p-0 px-1 m-0" xs={6}>
                <Button
                  className={`${s.primaryButton} btn-block`}
                  size="sm"
                  onClick={() => this.addMember(QUOTATIONS_MEMBER_TYPE.BABY)}
                  disabled={
                    !enableMemberEdition ||
                    blockAddBabyButton(integrantes, babiesAdded)
                  }
                  style={buttonsStyle}
                >
                  +BEB
                </Button>
              </Col>
            </Row>
            <Row className="w-100 m-0 mt-1 p-0">
              <Col className="p-0 px-1 m-0" xs={6}>
                <Button
                  className={`${s.primaryButton} btn-block`}
                  size="sm"
                  onClick={() => this.addMonth()}
                  style={buttonsStyle}
                  disabled={
                    !enableColumnEdition || this.state.lastMonth >= LAST_MONTH
                  }
                >
                  +MES
                </Button>
              </Col>
              <Col className="p-0 px-1 m-0" xs={6}>
                <Button
                  className={`${s.primaryButton} btn-block`}
                  size="sm"
                  onClick={() => this.deleteMonth()}
                  style={buttonsStyle}
                  disabled={
                    !enableColumnEdition || this.state.lastMonth <= FIRST_MONTH
                  }
                >
                  -MES
                </Button>
              </Col>
            </Row>
          </Container>
        ),
        id: -1,
        accessor: row => {
          return row[0].integrante;
        },
        Cell: row => {
          const { index } = row;
          const { tipo } = membersState[index];
          numberTypes[tipo] += 1;
          return (
            <p className="p-0 m-0 text-center">{`${getTypeName(tipo)} ${
              numberTypes[tipo]
            }`}</p>
          );
        },
        width: 100,
        className: ""
      }
    ];

    preciosMantenimiento.forEach((item, index) => {
      const { mes, anio, numero, integrantes } = item;
      const isMonthChecked = this.isMonthChecked(item);
      const priceIsZero =
        integrantes.normales.length > 0 &&
        !isNil(integrantes.normales[0].precioConDescuentoFormaPago)
          ? integrantes.normales[0].precioConDescuentoFormaPago <= 0
          : true;

      if (!enableColumnEdition || mes <= lastMonth) {
        columns.push({
          Header: () => (
            <Container
              className="p-0 m-0 w-100"
              fluid
              id="membershipTypeSelect"
            >
              {(priceIsZero ||
                !this.props.checkMode ||
                (!!this.props.checkMode &&
                  !this.isPreviousMonthChecked(item))) && (
                <div className="p-0 m-0 mt-1 text-center">
                  <div className="m-0">
                    {getMonthHeader(mes)}
                    <br />
                    {anio}
                  </div>
                </div>
              )}

              {!priceIsZero &&
                !!this.props.checkMode &&
                this.isPreviousMonthChecked(item) && (
                  <div
                    style={{
                      cursor: "pointer",
                      borderRadius: "10px",
                      color: !!isMonthChecked ? "white" : "auto"
                    }}
                    className={`p-1 ${
                      !!isMonthChecked ? "checkedButton" : "whiteButton"
                    }`}
                    onClick={e => {
                      e.preventDefault();
                      this.onChangeCheckedMonth(item);
                    }}
                  >
                    <div className="p-0 m-0 mt-1 text-center">
                      {getMonthHeader(mes)}
                      <br />
                      {anio}
                    </div>
                  </div>
                )}
            </Container>
          ),
          accessor: mesValues =>
            mesValues.length && mesValues[index + 1]
              ? mesValues[index + 1].valor
              : 0,
          id: index + 1,
          minWidth: 68,
          maxWidth: 1000,
          className: "",
          Cell: ({ value }) => {
            return (
              <p className="p-0 m-0 text-center">
                <MexicanCurrency quantity={value} />
              </p>
            );
          }
        });
      }
    });

    columns.push({
      Header: () => <div />,
      id: preciosMantenimiento.length + 1,
      accessor: row => {
        return row[0].integrante;
      },
      Cell: () => (
        <Row className="w-100 m-0 p-0">
          <Col className="p-0 m-0">
            <a
              onClick={() => this.deleteMember(membersState)}
              className={`${s.deleteAnchor}`}
            >
              <FontAwesomeIcon icon={faTimes} />
            </a>
          </Col>
        </Row>
      ),
      minWidth: 20,
      maxWidth: 20,
      className: ""
    });

    const getArrayByType = (type: QUOTATIONS_MEMBER_TYPE) => {
      switch (type) {
        case QUOTATIONS_MEMBER_TYPE.BABY:
          return "bebes";
        case QUOTATIONS_MEMBER_TYPE.NORMAL:
          return "normales";
        case QUOTATIONS_MEMBER_TYPE.EXTRA:
          return "extras";
        default:
          return "";
      }
    };

    const typesSeen = {
      [QUOTATIONS_MEMBER_TYPE.NORMAL]: 0,
      [QUOTATIONS_MEMBER_TYPE.BABY]: 0,
      [QUOTATIONS_MEMBER_TYPE.EXTRA]: 0
    };

    const data = [];

    for (
      let indexBeneficio = 0;
      indexBeneficio < membersState.length;
      indexBeneficio++
    ) {
      const { tipo } = membersState[indexBeneficio];

      const row = [{ integrante: `${indexBeneficio}`, valor: 0 }];
      preciosMantenimiento.forEach(({ integrantes }) => {
        const memberList = integrantes[getArrayByType(tipo)];

        if (memberList.length) {
          row.push({
            integrante: memberList[typesSeen[tipo]].numero,
            valor: getPriceByTypeMember(memberList[typesSeen[tipo]], tipo)
          });
        }
      });

      typesSeen[tipo] += 1;
      row.push({
        integrante: `${preciosMantenimiento.length + 1}`,
        valor: 0
      });
      data.push(row);
    }

    if ((data || []).length > 0) {
      for (let i = 1; i < data[0].length - 1; i++) {
        let acum = 0;
        for (let j = 0; j < data.length; j++) {
          acum += data[j][i].valor;
        }
        if (i < columns.length) {
          columns[i]["Footer"] = (
            <p className="p-0 m-0 text-center">
              <MexicanCurrency quantity={acum} />
            </p>
          );
        }
      }
    }

    if (
      !!preciosMantenimiento &&
      !!preciosMantenimiento[0] &&
      !this.isMonthChecked(preciosMantenimiento[0])
    ) {
      this.setState({
        selectedMonths: [...selectedMonths, { ...preciosMantenimiento[0] }]
      });
      if (!!this.props.updateSelectedMonths) {
        this.props.updateSelectedMonths([
          ...selectedMonths,
          { ...preciosMantenimiento[0] }
        ]);
      }
    }

    return (
      <Container className="w-100 p-0 my-3">
        <Row
          hidden={
            !this.props.quotation || !this.props.quotation.tieneDescuentoCAT
          }
        >
          <Col>
            {`Descuento aplicable por CAT: ${this.props.quotation &&
              this.props.quotation.porcentajeCAT}%`}
          </Col>
        </Row>
        <Row className="m-0 p-0">
          <ReactTable
            data={data}
            columns={columns}
            pageSize={membersState.length}
            className="w-100 -striped -highlight"
            sortable={false}
            resizable={false}
            showPagination={false}
            defaultPageSize={12}
            getTheadTrProps={() => ({ style: { height: "55px" } })}
          />
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(QuotationsTable));
