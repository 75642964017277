import React from "react";
import RowRender from "./RowRender";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import {
  Row,
  Col,
  Button,
  Container,
  UncontrolledTooltip,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import { divideArrayInSubArrays } from "../../../../src/utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import s from "./styles.scss";
import { setChildStep } from "../../helpers/stepper-state-comparator";
import forEach from "lodash.foreach";
import IAction from "../../../types/IAction";

if (process.env.BROWSER) {
  var ReactDataGrid = require("react-data-grid");
}

interface Props {
  productId: string;
  saveComissions: (body: any) => IAction;
  autorizeComissions: (id: string, tipo: string) => IAction;
  comissions: any;
  catalogPostions: any;
}

class SalesComissionsSportsProgramConfig extends React.Component<Props> {
  state = {
    rows: [],
    isPercentage: false,
    selectedPuestos: {},
    load: false
  };

  rowGetter = i => {
    return this.state.rows[i];
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.comissions.comisiones !== this.props.comissions.comisiones) {
      const { comisiones } = nextProps.comissions;
      let value = 0;

      const updatedComissions = comisiones.map((row, i) => {
        const numeroParticipantesDesde =
          i === 0 ? 1 : comisiones[i - 1].numeroParticipantesHasta + 1;

        value += row.montoPorParticipante;
        return {
          numeroParticipantesDesde,
          ...row
        };
      });

      this.setState({ rows: updatedComissions, isPercentage: value === 0 });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.comissions !== null &&
      this.props.comissions.puestos &&
      this.props.catalogPostions &&
      !this.state.load
    ) {
      const puestosExistentes = this.props.comissions.puestos;
      const selectedPuestos = {
        ...this.state.selectedPuestos
      };

      puestosExistentes.map(puestoId => {
        this.props.catalogPostions.map(puesto => {
          if (puesto.positionId === puestoId) {
            selectedPuestos[puesto.positionId] = true;
          }
        });
      });

      setChildStep(selectedPuestos);
      this.setState(state => ({ ...state, selectedPuestos, load: true }));
    }
  }

  cleanState = state => {
    const newChildState = {};
    forEach(state, (value, key) => {
      if (value) {
        newChildState[key] = value;
      }
    });

    return newChildState;
  };

  checkPuesto = (e, positionId) => {
    const checked = e.target.checked;
    const selectedPuestos = {
      ...this.state.selectedPuestos,
      [positionId]: checked
    };

    setChildStep(this.cleanState(selectedPuestos));

    this.setState(state => ({
      ...state,
      selectedPuestos
    }));
  };

  selectAll = (e, value) => {
    e.preventDefault();
    const selectedPuestos = { ...this.state.selectedPuestos };

    this.props.catalogPostions.forEach(({ positionId }) => {
      selectedPuestos[positionId] = value;
    });

    setChildStep(this.cleanState(selectedPuestos));

    this.setState(state => ({
      ...state,
      selectedPuestos
    }));
  };

  addLimit = () => {
    const { rows } = this.state;
    const lastRow = rows.length > 0 ? rows[rows.length - 1] : null;
    if (lastRow && lastRow.numeroParticipantesHasta === 10000) {
      lastRow.numeroParticipantesHasta = lastRow.numeroParticipantesDesde + 1;
    }
    const numeroParticipantesDesde = lastRow
      ? lastRow.numeroParticipantesHasta + 1
      : 1;
    const newLimit = {
      numeroParticipantesDesde,
      numeroParticipantesHasta: 10000,
      montoPorParticipante: 0,
      montoPorcentajeParticipante: 0
    };

    this.setState({
      rows: lastRow
        ? [...rows.slice(0, rows.length - 1), lastRow, newLimit]
        : [newLimit]
    });
  };

  removeLimit = () => {
    const { rows } = this.state;
    const newRows = rows.length > 0 ? rows.slice(0, rows.length - 1) : rows;
    if (newRows.length > 0) {
      newRows[newRows.length - 1]["numeroParticipantesHasta"] = 10000;
    }

    this.setState({
      rows: [...newRows]
    });
  };

  updateRows = rows => {
    return rows.map((row, i) => {
      const numeroParticipantesDesde =
        i === 0 ? 1 : rows[i - 1].numeroParticipantesHasta + 1;
      return {
        ...row,
        numeroParticipantesDesde
      };
    });
  };

  handleGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    const rows = [...this.state.rows];

    for (let i = fromRow; i <= toRow; i++) {
      if (updated.hasOwnProperty("numeroParticipantesHasta")) {
        const parsedVal = parseInt(updated["numeroParticipantesHasta"]);
        updated["numeroParticipantesHasta"] = isNaN(parsedVal) ? 0 : parsedVal;
      }

      if (updated.hasOwnProperty("montoPorParticipante")) {
        const parsedVal = parseFloat(updated["montoPorParticipante"]);
        updated["montoPorParticipante"] = isNaN(parsedVal) ? 0 : parsedVal;
      }
      if (updated.hasOwnProperty("montoPorcentajeParticipante")) {
        const parsedVal = parseFloat(updated["montoPorcentajeParticipante"]);
        updated["montoPorcentajeParticipante"] = isNaN(parsedVal)
          ? 0
          : parsedVal;
      }

      rows[i] = { ...rows[i], ...updated };
    }

    this.setState({ rows: this.updateRows(rows) });
  };

  handleSaveComissions = () => {
    const { rows, isPercentage } = this.state;
    const { productId } = this.props;
    const cleanValues = rows.map(row => {
      if (isPercentage) {
        return { ...row, montoPorParticipante: 0 };
      }
      return { ...row, montoPorcentajeParticipante: 0 };
    });

    const puestos = [];
    this.props.catalogPostions.forEach(puesto => {
      const { positionId } = puesto;
      if (this.state.selectedPuestos[positionId]) {
        puestos.push(positionId);
      }
    });

    const ultimateValues: any[] = [];

    cleanValues.map(value => {
      delete value.numeroParticipantesDesde;
      ultimateValues.push(value);
    });

    this.props.saveComissions({
      productoId: parseInt(productId),
      comisiones: ultimateValues,
      puestos
    });
  };

  toggleColumn = () => {
    this.setState({ isPercentage: !this.state.isPercentage });
  };

  getPositionsSubarrays = (itemsPerPage: number) => {
    if (!!this.props.catalogPostions) {
      return divideArrayInSubArrays(this.props.catalogPostions, itemsPerPage);
    } else {
      return [];
    }
  };

  getPositionsRearranged() {
    const columns = 4;
    const itemsPerPage = Math.floor(
      this.props.catalogPostions.length / columns
    );
    const subArrays = this.getPositionsSubarrays(itemsPerPage);
    const result = [];

    for (let i = 0; i < itemsPerPage; i++) {
      for (let c = 0; c < columns; c++) {
        if (!!subArrays[c][i]) {
          result.push(subArrays[c][i]);
        } else {
          result.push(null);
        }
      }
    }

    return result;
  }

  render() {
    let autorizado = false;
    if (this.props.comissions) {
      if (this.props.comissions) {
        autorizado = this.props.comissions.autorizado;
      }
    }

    const editable_column = this.state.isPercentage
      ? {
          key: "montoPorcentajeParticipante",
          name: "Porcentaje por participante",
          editable: true,
          frozen: true
        }
      : {
          key: "montoPorParticipante",
          name: "Monto por participante",
          editable: true,
          frozen: true
        };
    const columns = [
      {
        key: "numeroParticipantesHasta",
        name: "Hasta",
        editable: true,
        frozen: true
      },
      editable_column
    ];

    return (
      <div>
        <Container>
          <Row>
            <Col xs="8">
              <ReactDataGrid
                enableCellSelect={true}
                enableRowSelect={true}
                columns={columns}
                rowGetter={this.rowGetter}
                rowsCount={this.state.rows.length}
                onGridRowsUpdated={this.handleGridRowsUpdated}
                rowRenderer={RowRender}
                rowSelection={{
                  showCheckbox: false,
                  enableShiftSelect: false
                }}
              />
            </Col>
            <Col xs={{ size: 3, order: 2, offset: 1 }}>
              <Row>
                <Col>
                  <Button
                    className={s.symbolContainer}
                    onClick={this.toggleColumn}
                  >
                    {`Cambiar a ${this.state.isPercentage ? "$" : "%"}`}
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button className={s.symbolContainer} onClick={this.addLimit}>
                    Agregar Límite
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    className={s.symbolContainer}
                    onClick={this.removeLimit}
                  >
                    Eliminar Límite
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container className={s.puestos}>
          <Row>
            <Col>
              <p className={`primaryTitle w-100`}>Puestos</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                id={"selectAll"}
                onClick={e => this.selectAll(e, true)}
                color="link"
                className="text-success"
              >
                <FontAwesomeIcon icon={faCheck} />
              </Button>
              <Button
                id={"unSelectAll"}
                onClick={e => this.selectAll(e, false)}
                color="link"
                className="text-danger"
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
              <UncontrolledTooltip placement="left" target={`selectAll`}>
                Selecionar todos
              </UncontrolledTooltip>
              <UncontrolledTooltip placement="right" target={`unSelectAll`}>
                Deseleccionar todos
              </UncontrolledTooltip>
            </Col>
          </Row>
          <Row className={s.checkBoxesContainer}>
            {this.props.catalogPostions &&
              this.getPositionsRearranged().map(puesto => (
                <Col
                  className={s.labelContainer}
                  key={puesto.positionId}
                  xs="3"
                >
                  <FormGroup className={s.label} check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={
                          !!this.state.selectedPuestos[puesto.positionId]
                        }
                        onChange={e => this.checkPuesto(e, puesto.positionId)}
                      />
                      {puesto.name}
                    </Label>
                  </FormGroup>
                </Col>
              ))}
          </Row>
          <Row>
            <Col>
              <Container className={s.puestos}>
                <Button
                  size="sm"
                  className={s.customAutirizar}
                  onClick={this.handleSaveComissions}
                >
                  Guardar
                </Button>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withStyles(s)(SalesComissionsSportsProgramConfig);
