import React from "react";
import s from "./styles.scss";

export const columns = [
  {
    Header: "Club",
    id: "club",
    accessor: simpleAccessor("club"),
    className: s.tdclr,
    headerClassName: s.header,
    width: "120"
  },
  {
    Header: "Membresía",
    id: "idPublico",
    accessor: simpleAccessor("idPublico"),
    className: s.tdclr,
    headerClassName: s.header,
    width: "88"
  },
  {
    Header: "Titular",
    id: "titular",
    accessor: simpleAccessor("titular"),
    className: s.tdclr,
    headerClassName: s.header,
    width: "224"
  },
  {
    Header: "Fecha beneficio",
    id: "fechaBeneficio",
    accessor: simpleAccessor("fechaBeneficio"),
    className: s.tdclr,
    headerClassName: s.header,
    width: "120"
  },
  {
    Header: "Tipo beneficio",
    id: "tipo",
    accessor: simpleAccessor("tipo"),
    className: s.tdclr,
    headerClassName: s.header,
    width: "112"
  },
  {
    Header: "Valor",
    id: "valor",
    accessor: simpleAccessor("valor"),
    className: s.tdclr,
    headerClassName: s.header,
    width: "48"
  },
  {
    Header: "Observaciones",
    id: "observaciones",
    accessor: trimAccessor("observaciones", 10),
    className: s.tdclr,
    headerClassName: s.header,
    width: "112"
  },
  {
    Header: "Error",
    id: "error",
    accessor: simpleAccessor("error"),
    className: s.tdclr,
    headerClassName: s.header,
    width: "416"
  }
];

function simpleAccessor(accessor) {
  return row => {
    let className = "";

    if (row.level == 2) {
      className = s.errorRow;
    } else if (row.level == 1) {
      className = s.warnRow;
    }

    return (
      <div className={`${s.divRow} ${className}`}>
        {row[accessor] || <>&nbsp;</>}
      </div>
    );
  };
}

function trimAccessor(accessor, maxChars) {
  return row => {
    let className = "";
    let value = row[accessor] || "";
    value =
      value.length > maxChars ? value.substring(0, maxChars) + "..." : value;

    if (row.level == 2) {
      className = s.errorRow;
    } else if (row.level == 1) {
      className = s.warnRow;
    }

    return (
      <div className={`${s.divRow} ${className}`}>{value || <>&nbsp;</>}</div>
    );
  };
}

export default columns;
