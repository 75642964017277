import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import * as catalogActions from "../../actions/catalogs";
import * as clubActions from "../../actions/clubs";
import * as convenioActions from "../../actions/convenios";
import * as modalActions from "../../actions/modals";
import * as promotionsActions from "../../actions/promotions";
import * as prospectActions from "../../actions/prospects";
import * as sellerActions from "../../actions/sellers";
import Layout from "../../components/layout";
import Prospects from "../../components/prospects";

interface Props {
  getPromotions: (args) => any;
  toggleModal: (prospect?: object) => any;
  togglePromotionDetailModal: (promotion?: object) => any;
  toggleAgreementDetailModal: (agreement?: object) => any;
  toggleModalActions: (prospect?: object) => any;
  toggleModalActionsLog: (prospect?: object) => any;
  toggleModalStatus: (prospect?: object) => any;
  toggleDeleteModal: (prospect?: object) => any;
  getClubSellers: (clubId) => any;
  getClubGeneralManagers: (clubId) => any;
  getActivitiesBySeller: (id: string, params: object) => any;
  toggleChangeStatus: (activityId: string) => any;
  saveReassignment: (type: string, id: string, prospects: object[]) => any;
  getEmpresasActivas: (
    clubId: number | string,
    mantenimientoId: number | string
  ) => void;
  clearCreatedProspectVariable: () => void;

  reloadProspects: boolean;
  searching: boolean;
  clubSellers: object[];
  clubGeneralManagers: object[];
  events: object[];
  createdAction: object;
  savingReassignment: boolean;
  saveReassignmentError: boolean;
  promotions: object[];
  memberships: object[];
  maintenances: object[];
  promotionsLoading: boolean;
  userId: string;

  empresasActivasLoading: boolean;
  empresasActivas: any[];
  userContext: { clubSessionId: any; clubs: any[] };
}

class ProspectsPage extends React.Component<Props, void> {
  componentDidMount() {}

  render() {
    return (
      <main>
        <Helmet title="Prospects" />
        <Layout>
          <Prospects
            searching={this.props.searching}
            reloadProspects={this.props.reloadProspects}
            clubSellers={this.props.clubSellers}
            clubGeneralManagers={this.props.clubGeneralManagers}
            events={this.props.events}
            createdAction={this.props.createdAction}
            savingReassignment={this.props.savingReassignment}
            saveReassignmentError={this.props.saveReassignmentError}
            promotions={this.props.promotions}
            promotionsLoading={this.props.promotionsLoading}
            userId={this.props.userId}
            empresasActivasLoading={this.props.empresasActivasLoading}
            empresasActivas={this.props.empresasActivas}
            userContext={this.props.userContext}
            toggleModal={this.props.toggleModal}
            togglePromotionDetailModal={this.props.togglePromotionDetailModal}
            toggleAgreementDetailModal={this.props.toggleAgreementDetailModal}
            toggleDeleteModal={this.props.toggleDeleteModal}
            toggleModalActions={this.props.toggleModalActions}
            toggleModalActionsLog={this.props.toggleModalActionsLog}
            toggleModalStatus={this.props.toggleModalStatus}
            getClubSellers={this.props.getClubSellers}
            getClubGeneralManagers={this.props.getClubGeneralManagers}
            getActivitiesBySeller={this.props.getActivitiesBySeller}
            toggleChangeStatus={this.props.toggleChangeStatus}
            saveReassignment={this.props.saveReassignment}
            getPromotions={this.props.getPromotions}
            getEmpresasActivas={this.props.getEmpresasActivas}
            clearCreatedProspectVariable={
              this.props.clearCreatedProspectVariable
            }
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getClubSellers: clubId => {
    dispatch(clubActions.getClubSellers(clubId));
  },
  getClubGeneralManagers: clubId => {
    dispatch(clubActions.getClubGeneralManagers(clubId));
  },
  getPromotions: args => {
    dispatch(promotionsActions.getPromotions(args));
  },
  toggleModal: (prospect?: object) => {
    dispatch(modalActions.toggleCreateProspectModal(prospect));
  },
  togglePromotionDetailModal: (promotion?: object) => {
    dispatch(modalActions.togglePromotionDetailModal(promotion));
  },
  toggleAgreementDetailModal: (agreement?: object) => {
    dispatch(modalActions.toggleAgreementDetailModal(agreement));
  },
  toggleDeleteModal: (prospect?: object) => {
    dispatch(modalActions.toggleDeleteProspectModal(prospect));
  },
  toggleModalActions: (prospect?: object) => {
    dispatch(modalActions.toggleActionProspectModal(prospect));
  },
  toggleModalActionsLog: (prospect?: object) => {
    dispatch(modalActions.toggleActionLogProspectModal(prospect));
  },
  toggleModalStatus: (prospect?: object) => {
    dispatch(modalActions.toggleStatusProspectModal(prospect));
  },
  toggleChangeStatus: (activityId: string) => {
    dispatch(modalActions.toggleChangeStatusActivity(activityId));
  },
  getActivitiesBySeller: (id, params) => {
    dispatch(sellerActions.getActivitiesBySeller(id, params));
  },
  saveReassignment: (type: string, id: string, prospects: object[]) => {
    return dispatch(prospectActions.saveReassignment(type, id, prospects));
  },
  getEmpresasActivas: (clubId: number, mantenimientoId: number) => {
    dispatch(convenioActions.getEmpresasActivas(clubId, mantenimientoId));
  },
  clearCreatedProspectVariable: () => {
    dispatch(prospectActions.clearCreatedProspectVariable());
  }
});

const mapStateToProps = state => {
  const {
    user,
    modals,
    clubs,
    sellers,
    prospects,
    promotions,
    convenios
  } = state;
  return {
    reloadProspects: prospects.newProspectCreated,
    searching: user.searchingProspects,
    clubSellers: clubs.clubSellers,
    clubGeneralManagers: clubs.clubGeneralManagers,
    events: sellers.quotes,
    createdAction: modals.createdAction,
    savingReassignment: prospects.savingReassignment,
    saveReassignmentError: prospects.saveReassignmentError,
    promotions: promotions.promotions,
    promotionsLoading: promotions.gettingPromotions,
    userId: user.userContext.userId,
    userContext: user.userContext,
    empresasActivasLoading: convenios.empresasActivasLoading,
    empresasActivas: convenios.empresasActivas
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProspectsPage);
