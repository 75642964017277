import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Container } from "reactstrap";
import { Club } from "../../../types-business/Clubs";
import PagedTable from "../common/PagedTable";
import { QueryResponse } from "../../../types/QueryResponse";

interface Props {
  clubs: QueryResponse<Club>;
  defaultRows: string;
  fetchData: () => void;
  searching: boolean;
  onClickNewClub: (club) => any;
}

class Clubs extends React.Component<Props> {
  handleEditClub = club => {
    this.props.onClickNewClub(club);
  };

  handleDeleteClub = club => {
    alert(`Eliminar el club con el id ${club.clubId}`);
  };

  render() {
    const columns = [
      { name: "Clave Corta", id: "claveCorta" },
      { name: "Nombre", id: "nombre" },
      { name: "Concepto", id: "concepto" },
      { name: "Empresa", id: "empresaInfo.nombre" },
      { name: "Fecha Apertura", id: "fechaApertura" },
      { name: "Fecha Preventa", id: "fechaPreventa" },
      { name: "Activo", id: "activo" },
      {
        headerActions: [{ name: "add", f: this.props.onClickNewClub }]
      }
    ];

    const actions = [{ name: "edit", f: this.handleEditClub }];

    return (
      <Container className="mt-3 p-0">
        <PagedTable
          data={this.props.clubs}
          columns={columns}
          actions={actions}
          fetchData={this.props.fetchData}
          searching={this.props.searching}
        />
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(Clubs));
