import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import { ParedLockerDto, LockerDto } from "../../../types/Lockers";
import LockerAssignment from "../../components/lockersAssignment";
import * as lockersActions from "../../actions/lockers";
import * as catalogActions from "../../actions/catalogs";
import { QueryResponse } from "../../../types/QueryResponse";
import { QueryArguments } from "../../../types-business/QueryArguments";
import CONSTANTS from "../../../utils/constants";
import { getDocumentsLocker } from "../../actions/documents";

interface Props {
  clubs: any;
  searching: boolean;
  clubWallLockers: QueryResponse<LockerDto>;
  products: object;
  history: any;
  sellers: object[];
  getLockersWallsByClubId: (clubId: number) => any;
  getLockersWithFiltersPaginated: (
    clubId: number,
    lockerRoom: string,
    wallId: number,
    status: string,
    lockerNumber: string,
    isAssignable: boolean,
    args?: QueryArguments
  ) => any;
  getClubs: () => any;
  getSellers: () => any;
  getLockersProducts: () => any;
  saveLockerAssignment: (
    lockerId: number,
    personId: number,
    productId: number
  ) => any;
  lockerDocuments: object[];
  getDocumentsLocker: () => any;
  fileScanned: boolean;
  userContext: any;
}

interface State {
  selectedClubId: number;
  selectedLockerRoom: string;
  selectedWallId: number;
  lockerNumber: string;
  lockerRoomCatalog: object[];
  lockerRoomWalls: ParedLockerDto[];
  lockerRoomWallsFiltered: object[];
  filters: object;
  searchingClubWalls: boolean;
}

class LockerAssignmentsPage extends React.Component<Props, State> {
  state = {
    selectedClubId: -1,
    selectedLockerRoom: "",
    selectedWallId: -1,
    lockerNumber: "",
    lockerRoomCatalog: [],
    lockerRoomWalls: [],
    lockerRoomWallsFiltered: [],
    filters: {
      size: CONSTANTS.DEFAULT_PAGE_SIZE,
      page: 0
    },
    searchingClubWalls: false
  };

  onClubChanged = async (newClubId: number) => {
    await this.setState(state => ({
      ...state,
      selectedClubId: newClubId,
      selectedLockerRoom: "",
      selectedWallId: -1,
      lockerRoomWalls: [],
      lockerRoomWallsFiltered: [],
      searchingClubWalls: true
    }));

    this.loadLockerWallsBySelectedClubId();
  };

  onLockerRoomChanged = (newLockerRoom: string) => {
    const { lockerRoomWalls } = this.state;
    const lockerRoomWallsFiltered = lockerRoomWalls.filter(
      lrw => lrw.vestidor === newLockerRoom
    );

    this.setState(state => ({
      ...state,
      selectedLockerRoom: newLockerRoom,
      selectedWallId: -1,
      lockerRoomWallsFiltered
    }));
  };

  onWallChanged = (newWallId: number) => {
    this.setState(state => ({
      ...state,
      selectedWallId: newWallId
    }));
  };

  onLockerNumberChanged = (newLockerNumber: string) => {
    this.setState(state => ({
      ...state,
      lockerNumber: newLockerNumber
    }));
  };

  loadLockerWallsBySelectedClubId = () => {
    const { selectedClubId } = this.state;
    if (!!selectedClubId && selectedClubId > 0) {
      this.props
        .getLockersWallsByClubId(selectedClubId)
        .then(({ value: { data } }) => {
          this.setState(state => ({
            ...state,
            lockerRoomWalls: data,
            lockerRoomWallsFiltered: [],
            searchingClubWalls: false
          }));
        });
    }
  };

  onFetchData = ({ pageSize: size, page }) => {
    const filters = { ...this.state.filters, size, page };

    this.setState(state => ({
      ...state,
      filters
    }));

    if (!!this.state.selectedClubId && this.state.selectedClubId > 0) {
      this.props.getLockersWithFiltersPaginated(
        this.state.selectedClubId,
        this.state.selectedLockerRoom,
        this.state.selectedWallId,
        undefined,
        this.state.lockerNumber,
        undefined,
        filters
      );
    }
  };

  searchLockers = () => {
    const filters = { ...this.state.filters, page: 0 };

    this.setState(state => ({
      ...state,
      filters
    }));

    if (!!this.state.selectedClubId && this.state.selectedClubId > 0) {
      this.props.getLockersWithFiltersPaginated(
        this.state.selectedClubId,
        this.state.selectedLockerRoom,
        this.state.selectedWallId,
        undefined,
        this.state.lockerNumber,
        undefined,
        filters
      );
    }
  };

  async componentDidMount() {
    this.props.getClubs();
    this.props.getLockersProducts();
    this.props.getSellers();
    this.props.getDocumentsLocker();

    await this.setState({
      selectedClubId:
        (this.props.userContext && this.props.userContext.clubSessionId) || -1
    });

    this.loadLockerWallsBySelectedClubId();
  }

  render() {
    return (
      <main>
        <Helmet title="Lockers asignados" />
        <Layout>
          <LockerAssignment
            clubsCatalog={this.props.clubs}
            selectedClubId={this.state.selectedClubId}
            selectedLockerRoom={this.state.selectedLockerRoom}
            selectedWallId={this.state.selectedWallId}
            lockerNumber={this.state.lockerNumber}
            lockerRoomWallsFiltered={this.state.lockerRoomWallsFiltered}
            searching={this.props.searching}
            onClubChanged={this.onClubChanged}
            onLockerRoomChanged={this.onLockerRoomChanged}
            onWallChanged={this.onWallChanged}
            onLockerNumberChanged={this.onLockerNumberChanged}
            searchLockers={this.searchLockers}
            fetchData={this.onFetchData}
            wallLockers={this.props.clubWallLockers}
            products={this.props.products}
            saveLockerAssignment={this.props.saveLockerAssignment}
            searchingClubWalls={this.state.searchingClubWalls}
            sellers={this.props.sellers}
            lockerDocuments={this.props.lockerDocuments}
            fileScanned={this.props.fileScanned}
            userContext={this.props.userContext}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getLockersWallsByClubId: (clubId: number) => {
    return dispatch(lockersActions.getLockersWallsByClubId(clubId));
  },
  getLockersWithFiltersPaginated: (
    clubId: number,
    lockerRoom: string,
    wallId: number,
    status: string,
    lockerNumber: string,
    isAssignable: boolean,
    args?: QueryArguments
  ) => {
    dispatch(
      lockersActions.getLockersWithFiltersPaginated(
        clubId,
        lockerRoom,
        wallId,
        status,
        lockerNumber,
        isAssignable,
        args
      )
    );
  },
  getClubs: () => {
    dispatch(catalogActions.getClubs());
  },
  getSellers: () => {
    dispatch(catalogActions.getSellers());
  },
  getLockersProducts: () => {
    dispatch(lockersActions.getLockersProducts());
  },
  saveLockerAssignment: (
    lockerId: number,
    personId: number,
    productId: number,
    responsibleId: number,
    msi: number,
    codigo,
    storage
  ) => {
    return dispatch(
      lockersActions.saveLockerAssignment(
        lockerId,
        personId,
        productId,
        responsibleId,
        msi,
        codigo,
        storage
      )
    );
  },
  getDocumentsLocker: () => {
    return dispatch(getDocumentsLocker());
  }
});

const mapStateToProps = state => {
  const {
    lockers,
    catalogs,
    documents: { lockerDocuments },
    partners: { fileScanned }
  } = state;

  return {
    clubs: catalogs.clubs,
    searching: lockers.gettingClubWallLockers,
    clubWallLockers: lockers.clubWallLockers,
    products: lockers.lockersProducts,
    sellers: catalogs.sellers,
    lockerDocuments,
    fileScanned,
    userContext: state.user.userContext
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LockerAssignmentsPage);
