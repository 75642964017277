import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import Reform from "@franleplant/reform";
import TimePicker from "react-times";
import DatePicker from "../common/DatePicker";
import moment from "moment";
import ModalFrame from "../../../toolkit/modalFrame";
import s from "./styles.scss";

import {
  Button,
  Container,
  Alert,
  Form,
  FormGroup,
  Label,
  Row,
  Col
} from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";

const MINUTES_TO_ADD_MEETING = 30;
const MINUTES_TO_ADD_CALL = 10;
interface Activity {
  fechaInicio: string;
  fechaFin: string;
  nota: string;
  tipo: string;
}

interface Prospect {
  personaId: string;
}

interface Props {
  isOpen: boolean;
  toggle: () => void;
  createActivity: (personaId: string, activity: object) => void;
  prospect: Prospect;
  history: any;
  client: any;
  editing: boolean;
  creatingActivity: boolean;
  userId: string;
}

const CALL_TYPE = "LLAMADA";
const APPOINTMENT_TYPE = "CITA";
const NOTE_TYPE = "NOTA";
const MAIL_TYPE = "MAIL";
const WHATSAPP_TYPE = "WHATSAPP";
const START_DATE_FIELDNAME = "fechaInicio";
const END_DATE_FIELDNAME = "fechaFin";
const TYPE_FIELDNAME = "tipo";
const NOTE_FIELDNAME = "nota";

interface TIME_OBJECT {
  hour: string;
  minute: string;
  meridiem: string;
}

const getEmptyTimeObject = () => {
  const now = moment().add(1, "minute");
  return {
    hour: now.format("HH"),
    minute: now.format("mm"),
    meridiem: now.format("A")
  };
};

const equalTime = (tm1: TIME_OBJECT, tm2: TIME_OBJECT) => {
  return (
    tm1.hour === tm2.hour &&
    tm1.minute === tm2.minute &&
    tm1.meridiem === tm2.meridiem
  );
};

interface State {
  fields: Activity;
  horaInicial: TIME_OBJECT;
  horaFinal: TIME_OBJECT;
}

const getDate = (date: string, { hour, minute }: TIME_OBJECT) => {
  return new Date(`${date}T${hour}:${minute}`);
};

const getFormattedDate = (date: string, timeObject: TIME_OBJECT) => {
  return getDate(date, timeObject).toISOString();
};

const getCleanFields: () => Activity = () => ({
  fechaInicio: moment().format("YYYY-MM-DD"),
  fechaFin: moment().format("YYYY-MM-DD"),
  nota: "",
  tipo: CALL_TYPE
});

class ActionProspectModal extends React.Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);

  state = {
    fields: getCleanFields(),
    horaInicial: getEmptyTimeObject(),
    horaFinal: getEmptyTimeObject(),
    editing: false,
    errors: {}
  };

  dateValidator = (dateInput, timeObject: TIME_OBJECT) => {
    const date = getDate(dateInput, timeObject);
    const now = new Date();
    return dateInput && date <= now;
  };

  bothDatesValidator = (dateInput, otherInput) => {
    const date = new Date(dateInput);
    const other = new Date(otherInput);
    return dateInput && otherInput && date >= other;
  };

  validationRules = {
    [TYPE_FIELDNAME]: { required: true },
    [START_DATE_FIELDNAME]: {
      dateMustBeFuture: value =>
        this.dateValidator(value, this.state.horaInicial)
      // dateMustBeValid: value =>
      //   this.bothDatesValidator(
      //     getDate(value, this.state.horaInicial),
      //     getDate(this.state.fields[END_DATE_FIELDNAME], this.state.horaFinal)
      //   ),
    },
    [END_DATE_FIELDNAME]: {
      dateMustBeFuture: value =>
        this.dateValidator(value, this.state.horaFinal),
      dateMustBeValid: value =>
        this.bothDatesValidator(
          getDate(
            this.state.fields[START_DATE_FIELDNAME],
            this.state.horaInicial
          ),
          getDate(value, this.state.horaFinal)
        )
    },
    [NOTE_FIELDNAME]: {
      noteMustExistsWhenTypeNote: value => this.noteValidator(value)
    }
  };

  noteValidator = value =>
    !value &&
    (this.state.fields[TYPE_FIELDNAME] === NOTE_TYPE ||
      this.state.fields[TYPE_FIELDNAME] === MAIL_TYPE ||
      this.state.fields[TYPE_FIELDNAME] === WHATSAPP_TYPE);

  options = [CALL_TYPE, APPOINTMENT_TYPE, MAIL_TYPE, WHATSAPP_TYPE].map(
    value => ({
      label: value,
      value
    })
  );

  validationMessages = {
    required: _ => `Obligatorio`,
    dateMustBeFuture: _ => "La fecha debe ser un momento en el futuro",
    dateMustBeValid: _ => "La fecha inicial debe ser menor que la final",
    noteMustExistsWhenTypeNote: _ =>
      "No debe estar vacío cuando se crea una nota/mensaje de whatsapp",
    default: _ => `Invalido`
  };

  onSubmit = e => {
    e.preventDefault();
    try {
      const type = this.state.fields[TYPE_FIELDNAME];
      if (
        (type === CALL_TYPE || type === APPOINTMENT_TYPE) &&
        !this.re.validateFormFromState()
      ) {
        return false;
      }

      const activity = {
        ...this.state.fields
      };

      switch (activity.tipo) {
        case CALL_TYPE:
        case APPOINTMENT_TYPE:
          if (this.state.fields[START_DATE_FIELDNAME]) {
            activity[START_DATE_FIELDNAME] = getFormattedDate(
              this.state.fields[START_DATE_FIELDNAME],
              this.state.horaInicial
            );
          }

          if (this.state.fields[END_DATE_FIELDNAME]) {
            activity[END_DATE_FIELDNAME] = getFormattedDate(
              this.state.fields[END_DATE_FIELDNAME],
              this.state.horaFinal
            );
          }
          break;
        case NOTE_TYPE:
        case MAIL_TYPE:
        case WHATSAPP_TYPE:
          activity[START_DATE_FIELDNAME] = null;
          activity[END_DATE_FIELDNAME] = null;
          break;
      }

      this.props.createActivity(this.props.prospect.personaId, {
        ...activity,
        assignedTo: this.props.prospect["asignadoA"]
      });
    } catch (e) {
      if (this.state.fields.tipo === "NOTA") {
        this.props.createActivity(this.props.prospect.personaId, {
          nota: this.state.fields.nota,
          fechaInicio: "",
          fechaFin: "",
          tipo: this.state.fields.tipo,
          assignedTo: this.props.prospect["asignadoA"]
        });
      }
    }
  };

  componentDidUpdate(_, prevState) {
    if (this.props.isOpen && !this.state.editing) {
      this.setState(() => ({ editing: true }));
    }

    if (!this.props.isOpen && this.state.editing) {
      this.setState(prevState => {
        return {
          ...prevState,
          fields: getCleanFields(),
          horaInicial: getEmptyTimeObject(),
          horaFinal: getEmptyTimeObject(),
          editing: false
        };
      });
    }

    if (prevState.horaInicial !== this.state.horaInicial) {
      this.re.validateField(
        START_DATE_FIELDNAME,
        this.state.fields[START_DATE_FIELDNAME]
      );
    }

    if (prevState.horaFinal !== this.state.horaFinal) {
      this.re.validateField(
        END_DATE_FIELDNAME,
        this.state.fields[END_DATE_FIELDNAME]
      );
    }

    if (
      prevState.fields[START_DATE_FIELDNAME] !==
        this.state.fields[START_DATE_FIELDNAME] ||
      !equalTime(prevState.horaInicial, this.state.horaInicial)
    ) {
      this.updateEndDateTime();
    }
  }

  onChangeFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };

        if (fieldName === TYPE_FIELDNAME && this.disableDateInput(value)) {
          fields[START_DATE_FIELDNAME] = "";
          fields[END_DATE_FIELDNAME] = "";
        }

        return { ...state, error: {}, fields };
      });
      this.re.validateField(fieldName, value);
    };
  };

  onChangeFecha = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: date
        };
        return { ...state, error: {}, fields };
      });
      this.re.validateField(fieldName, date);
    };
  };

  disableDateInput = tipo => {
    return !(tipo === CALL_TYPE || tipo === APPOINTMENT_TYPE);
  };

  updateEndDateTime = () => {
    const MINUTES_TO_ADD =
      this.state.fields.tipo === CALL_TYPE
        ? MINUTES_TO_ADD_CALL
        : MINUTES_TO_ADD_MEETING;
    const finalDatetime = moment(
      getDate(this.state.fields[START_DATE_FIELDNAME], this.state.horaInicial)
    ).add(MINUTES_TO_ADD, "minutes");

    const hour = finalDatetime.hour();
    const state = {
      fields: this.state.fields
    };

    state["horaFinal"] = {
      hour: finalDatetime.format("HH"),
      minute: finalDatetime.format("mm"),
      meridiem: Math.floor(hour / 12) === 1 ? "PM" : "AM"
    };

    state.fields[END_DATE_FIELDNAME] = finalDatetime.format("YYYY-MM-DD");
    this.setState(() => state);
  };

  setTime = (key, time) => {
    this.setState(() => ({ [key]: time }));
  };

  render() {
    const getTimePicker = ({ hour, minute, meridiem }: TIME_OBJECT, key) => (
      <TimePicker
        timeMode="24"
        timezone="America/Mexico_City"
        onTimeChange={time => this.setTime(key, time)}
        time={hour && minute ? `${hour}:${minute}` : "08:00"}
        theme="classic"
        timeConfig={{
          from: 6,
          to: 23,
          step: 15,
          unit: "minutes"
        }}
        withoutIcon
      />
    );
    return (
      <ModalFrame
        title={"Acciones"}
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <Container>
          <Form>
            <BaseInput
              label={"Tipo"}
              name={TYPE_FIELDNAME}
              type="select"
              id={TYPE_FIELDNAME}
              placeholder="tipo"
              value={this.state.fields[TYPE_FIELDNAME]}
              // dataQAId={"user_login_password"}
              errors={this.re.mapFieldErrors(TYPE_FIELDNAME)}
              options={this.options}
              onChange={this.onChangeFactory(TYPE_FIELDNAME)}
            />
            <BaseInput
              label={"Nota / Mensaje"}
              name={NOTE_FIELDNAME}
              type="textarea"
              id={NOTE_FIELDNAME}
              value={this.state.fields.nota}
              // dataQAId={"user_login_password"}
              onChange={this.onChangeFactory(NOTE_FIELDNAME)}
              errors={this.re.mapFieldErrors(NOTE_FIELDNAME)}
            />
            {!this.disableDateInput(this.state.fields[TYPE_FIELDNAME]) && (
              <div>
                <Row>
                  <Col>
                    <DatePicker
                      label="Fecha Inicial"
                      name={START_DATE_FIELDNAME}
                      id={START_DATE_FIELDNAME}
                      selected={this.state.fields[START_DATE_FIELDNAME]}
                      onChange={this.onChangeFecha(START_DATE_FIELDNAME)}
                      errors={this.re.mapFieldErrors(START_DATE_FIELDNAME)}
                    />
                  </Col>
                  <Col>
                    <FormGroup className={s.formGroup}>
                      <Label className={s.label} for="horaInicial">
                        Hora Inicial:
                      </Label>
                      {getTimePicker(this.state.horaInicial, "horaInicial")}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <DatePicker
                      label="Fecha Final"
                      name={END_DATE_FIELDNAME}
                      id={END_DATE_FIELDNAME}
                      selected={this.state.fields[END_DATE_FIELDNAME]}
                      onChange={this.onChangeFecha(END_DATE_FIELDNAME)}
                      errors={this.re.mapFieldErrors(END_DATE_FIELDNAME)}
                    />
                  </Col>
                  <Col>
                    <FormGroup className={s.formGroup}>
                      <Label className={s.label} for="horaFinal">
                        Hora Final:
                      </Label>
                      {getTimePicker(this.state.horaFinal, "horaFinal")}
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            )}
            <Button
              className={`${s.buttonMarginTop} ${s.primaryButton}`}
              onClick={e => this.onSubmit(e)}
              size="lg"
              disabled={this.props.creatingActivity}
              block
            >
              {this.props.creatingActivity ? "Creando" : "Crear Actividad"}
            </Button>
            {false && (
              <span>
                <hr />
                <Alert color="danger">Ocurrió un error inesperado.</Alert>
              </span>
            )}
          </Form>
        </Container>
      </ModalFrame>
    );
  }
}

export default withRouter(withStyles(s)(ActionProspectModal));
