import { GetBitacoraInvitado } from "../../../../reducers/invitados";

export interface TabBitacoraStore extends GetBitacoraInvitado {}

export const tabBitacoraStore: (state) => TabBitacoraStore = state => {
  const { invitados } = state;

  return {
    bitacoraInvitado: invitados.bitacoraInvitado,
    gettingBitacoraInvitado: invitados.gettingBitacoraInvitado,
    gettingBitacoraInvitadoError: invitados.gettingBitacoraInvitadoError
  };
};
