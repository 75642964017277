import React from "react";
import BaseInput from "../../../toolkit/baseInput";

export const MaintenanceSelector = ({
  value,
  disabled,
  onChange,
  maintenances,
  size = "sm"
}) => (
  <BaseInput
    label="Mantenimiento"
    name="mantenimiento"
    type="select"
    id="mantenimiento"
    placeholder="Mantenimiento"
    value={value}
    size={size}
    disabled={disabled}
    onChange={e => {
      const value = e.target.value;
      return onChange(value);
    }}
    options={[{ value: "", label: "Sin Mantenimiento" }].concat(
      maintenances
        ? maintenances.map(option => ({
            value: option.datosBasicosMantenimientoId,
            label: option.nombre
          }))
        : []
    )}
  />
);
