import React from "react";
import update from "immutability-helper";
import RowRender from "./RowRender";
import DatePicker from "../common/DatePicker";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import CONSTANTS from "../../../utils/constants";
import moment from "moment";
const ULTIMOS_REGISTRADOS = "6";
const NO_AUTORIZADOS = "4";
import BaseInput from "../../../toolkit/baseInput";
import { Row, Col, Button, Container } from "reactstrap";
import SelectFilter from "../common/SelectFilter";
import SelectCuentaContable from "../../containers/selectCuentaContable";
import SelectCuentaProducto from "../../containers/selectCuentaProducto";
import cloneDeep from "lodash.clonedeep";
import { setChildStep } from "../../helpers/stepper-state-comparator";

import axios from "axios";
import constants from "../../../utils/constants";
import {
  infoNotification,
  successNotification,
  errorNotification
} from "../../../utils/notifications";

if (process.env.BROWSER) {
  var ReactDataGrid = require("react-data-grid");
}

interface Props {
  onClickSave: (e: object) => any;
  onSuccessfulSave: () => any;
  addPricesToMaintenance: (
    productId: string,
    tipoProducto: string,
    fechaVigencia: string,
    prices: object
  ) => any;
  successfulSave: boolean;
  prices: {
    estatusId: number;
    clubId: number;
    membresiaId: number;
    numeroIntegrante: number;
    tipoIntegrante: string;
    precio: number;
  }[];
  match: any;
  savingPrices: boolean;
  savingPricesError: boolean;
  getPricesToMaintenance: (params: object) => any;
  getClubsProduct: (tipoProducto: string, id: string) => any;
  conceptos: { id: string; nombre: string }[];
  estatus: { id: string; nombre: string }[];
  tipoProducto: string;
  productId: string;
  authorizePrices: (prices: object[]) => any;
  clubs: { clubId: string; nombre: string }[];
  loading: boolean;
  onLoading: () => any;
}

class PriceDataMaintenanceForm extends React.Component<Props> {
  state = {
    isModalOpen: false,
    rows: [],
    selectedIndexes: [],
    filters: {
      fechaVigencia: moment().format("YYYY-MM-DD"),
      estatusPrecio: ULTIMOS_REGISTRADOS,
      club: {
        value: "",
        label: ""
      }
    }
  };
  grid = null;
  rowGetter = i => {
    return this.state.rows[i];
  };

  componentDidUpdate(prevProps) {
    if (this.props.clubs && this.props.clubs.length) {
      const filters = {
        ...this.state.filters,
        club: {
          value: this.props.clubs[0].clubId,
          label: this.props.clubs[0].nombre
        }
      };
      if (!prevProps.clubs && this.props.clubs && this.props.clubs.length) {
        this.setState({ ...this.state, filters });
        this.search(filters);
      }
    }

    if (this.props.prices && prevProps.prices !== this.props.prices) {
      const rows = [...this.props.prices];
      this.setState({
        ...this.state,
        selectedIndexes: [],
        rows
      });
      setChildStep(rows);
    }

    if (
      (!prevProps.savingPrices && this.props.savingPrices) ||
      (!prevProps.loading && this.props.loading)
    ) {
      this.props.onLoading();
    }

    if (
      (prevProps.savingPrices && !this.props.savingPrices) ||
      (prevProps.loading && !this.props.loading)
    ) {
      this.props.onSuccessfulSave();
    }
  }

  shouldComponentUpdate(nextProps) {
    return !(
      this.props.successfulSave !== nextProps.successfulSave &&
      nextProps.successfulSave
    );
  }

  refreshPrices = async actionPromise => {
    return actionPromise
      .then(() => {
        return this.props.getPricesToMaintenance({
          ...this.state.filters,
          club: this.state.filters.club.value
            ? this.state.filters.club.value
            : this.props.clubs.map(({ clubId }) => clubId).join(","),
          mantenimiento: this.props.productId
        });
      })
      .then(({ value: { data } }) => {
        this.setState(state => {
          return {
            ...state,
            data: data.map(price => {
              return {
                ...price,
                cuentaContableId: price.cuentaContableId
                  ? price.cuentaContableId
                  : "",
                cuentaProductoId: price.cuentaProductoId
                  ? price.cuentaProductoId
                  : ""
              };
            }),
            selectedIndexes: []
          };
        });
      });
  };

  save = () => {
    const checked = this.state.selectedIndexes;
    const data = this.state.rows;
    const updateData = data.filter((_, index) => checked.indexOf(index) > -1);

    this.refreshPrices(
      this.props.addPricesToMaintenance(
        this.props.productId,
        this.props.tipoProducto,
        this.state.filters.fechaVigencia,
        updateData
      )
    );
  };

  authorize = () => {
    const checked = this.state.selectedIndexes;
    const data = this.state.rows;
    const pricesToAuthorize = data
      .filter(
        ({ estatusId }, index) =>
          checked.indexOf(index) > -1 && estatusId.toString() === NO_AUTORIZADOS
      )
      .map(
        ({
          clubId,
          membresiaId,
          numeroIntegrante,
          tipoIntegrante,
          cuentaContableId,
          cuentaProductoId,
          precio
        }) => {
          return {
            precio,
            tipoIntegrante,
            numeroIntegrante: numeroIntegrante,
            membresia: membresiaId,
            club: clubId,
            mantenimiento: this.props.productId,
            cuentaContable: cuentaContableId,
            cuentaProducto: cuentaProductoId
          };
        }
      );

    this.refreshPrices(this.props.authorizePrices(pricesToAuthorize));
  };

  componentDidMount() {
    this.props.getClubsProduct(this.props.tipoProducto, this.props.productId);
    this.props.onClickSave(e => {
      e.preventDefault();
      this.save();
    });
  }

  delete = async () => {
    // TODO: Redux revisión 1
    const indexes = this.state.selectedIndexes;
    const rows = this.state.rows || [];
    const items = rows.filter((_, index) => indexes.indexOf(index) > -1);
    let errors = 0;

    infoNotification("Eliminando precios...");
    for (let item of items) {
      try {
        await axios.delete(
          `${constants.BASE_URL}/api/maintenances/prices/${item.id}`,
          {
            withCredentials: true
          }
        );
      } catch (error) {
        errors += 1;
      }
    }

    if (errors === 0) {
      const plural = items.length > 1 ? "s" : "";
      successNotification(`Precio${plural} eliminado${plural} correctamente`);
    } else if (errors < items.length) {
      errorNotification(`No se pudieron eliminar ${errors} precios`);
    } else {
      errorNotification(`No se pueden eliminar precios autorizados`);
    }

    this.search(this.state.filters);
  };

  handleGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    const rows = this.state.rows.slice();
    for (let i = fromRow; i <= toRow; i++) {
      let rowToUpdate = rows[i];
      if (updated.precio != rowToUpdate.precio) {
        rowToUpdate.estatusId = parseInt(ULTIMOS_REGISTRADOS);
        if (
          updated.precio === "" ||
          updated.precio === null ||
          updated.precio === undefined
        ) {
          this.setState({
            selectedIndexes: this.state.selectedIndexes.filter(
              value => i !== value
            )
          });
        }
      }
      let updatedRow = update(rowToUpdate, { $merge: updated });
      rows[i] = updatedRow;
    }
    setChildStep(rows);
    this.setState({ rows });
  };

  search = filters => {
    const { club } = filters;
    this.props.getPricesToMaintenance({
      ...filters,
      club: club.value
        ? club.value
        : this.props.clubs.map(({ clubId }) => clubId).join(","),
      mantenimiento: this.props.productId
    });
  };

  changeFilter = (e, name) => {
    const value = e.target.value;
    setChildStep([]);
    if (this.grid) {
      this.grid.selectAllCheckbox.checked = false;
    }
    this.setState(state => {
      const { filters } = this.state;
      filters[name] = value;
      return { ...state, filters, rows: [], selectedIndexes: [] };
    });
  };

  renderCuentaContableSelector = cell => {
    const index = this.state.rows.findIndex(
      row =>
        cell.row.mantenimientoId === row.mantenimientoId &&
        cell.row.membresiaId === row.membresiaId &&
        cell.row.clubId === row.clubId &&
        cell.row.numeroIntegrante === row.numeroIntegrante &&
        cell.row.tipoIntegrante === row.tipoIntegrante
    );
    return (
      <SelectCuentaContable
        size="sm"
        value={this.state.rows[index][`cuentaContableId`]}
        onChange={(e: any) => {
          e.preventDefault();
          const rows = cloneDeep(this.state.rows);
          rows[index][`cuentaContableId`] = e.target.value;
          this.setState({ rows });
          setChildStep(rows);
        }}
      />
    );
  };

  renderCuentaProductoSelector = cell => {
    const index = this.state.rows.findIndex(
      row =>
        cell.row.mantenimientoId === row.mantenimientoId &&
        cell.row.membresiaId === row.membresiaId &&
        cell.row.clubId === row.clubId &&
        cell.row.numeroIntegrante === row.numeroIntegrante &&
        cell.row.tipoIntegrante === row.tipoIntegrante
    );
    return (
      <SelectCuentaProducto
        size="sm"
        value={this.state.rows[index][`cuentaProductoId`]}
        onChange={(e: any) => {
          e.preventDefault();
          const rows = cloneDeep(this.state.rows);
          rows[index][`cuentaProductoId`] = e.target.value;
          this.setState({ rows });
          setChildStep(rows);
        }}
      />
    );
  };

  onChangeFilterDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      setChildStep([]);
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: date
        };
        return { ...state, filters, rows: [] };
      });
    };
  };

  changeClub = club => {
    setChildStep([]);

    this.setState(prevState => {
      const { filters } = this.state;
      filters.club = club;
      return { ...prevState, filters, rows: [] };
    });
  };

  onRowsSelected = rows => {
    this.setState({
      selectedIndexes: this.state.selectedIndexes.concat(
        rows
          .filter(
            r =>
              r.row.precio !== undefined &&
              r.row.precio !== null &&
              r.row.precio !== ""
          )
          .map(r => r.rowIdx)
      )
    });
  };
  onRowsDeselected = rows => {
    let rowIndexes = rows.map(r => r.rowIdx);
    this.setState({
      selectedIndexes: this.state.selectedIndexes.filter(
        i => rowIndexes.indexOf(i) === -1
      )
    });
  };

  render() {
    return (
      <Container fluid className="p-0">
        {this.props.conceptos && this.props.estatus && (
          <Row>
            <Col>
              {this.props.clubs && (
                <SelectFilter
                  label={"Club"}
                  name="clubSelector"
                  type="select"
                  id="clubSelector"
                  placeholder="Club"
                  value={this.state.filters.club}
                  options={this.props.clubs.map(({ clubId, nombre }) => ({
                    label: nombre,
                    value: clubId
                  }))}
                  onChange={this.changeClub}
                  errors={[]}
                />
              )}
            </Col>
            <Col>
              <DatePicker
                label="Fecha de Vigencia"
                name="fechaVigencia"
                id="fechaVigencia"
                selected={this.state.filters.fechaVigencia}
                onChange={this.onChangeFilterDate("fechaVigencia")}
                size={"sm"}
              />
            </Col>
            <Col>
              <BaseInput
                label={"Estatus"}
                name={"estatus"}
                type="select"
                id={"estatus"}
                placeholder="Estatus"
                value={this.state.filters.estatusPrecio}
                size="sm"
                onChange={e => this.changeFilter(e, "estatusPrecio")}
                options={[{ value: "", label: "Seleccione el Estatus" }].concat(
                  this.props.estatus.map(option => ({
                    value: option.id,
                    label: option.nombre
                  }))
                )}
              />
            </Col>
            <Col>
              <Button
                size="sm"
                className={`${s.searchButton} ${s.primaryButton} btn-block`}
                onClick={() => this.search(this.state.filters)}
              >
                Buscar
              </Button>
            </Col>
          </Row>
        )}
        <hr />
        <Row>
          <Col className="text-center" xs={12}>
            {!(this.state.filters.estatusPrecio == "4") && (
              <Button
                className={`${s.actionsButton} mx-2`}
                color="success"
                size="sm"
                onClick={this.save}
                disabled={this.state.selectedIndexes.length > 0 ? false : true}
              >
                Guardar
              </Button>
            )}
            {this.state.filters.estatusPrecio == "4" && (
              <Button
                className={`${s.actionsButton} mx-2`}
                color="success"
                size="sm"
                onClick={this.authorize}
                disabled={this.state.selectedIndexes.length > 0 ? false : true}
              >
                Autorizar
              </Button>
            )
            /*)*/
            }
            <Button
              className={`${s.actionsButton} ml-2`}
              color="danger"
              size="sm"
              onClick={this.delete}
              disabled={this.state.selectedIndexes.length > 0 ? false : true}
            >
              Eliminar
            </Button>
          </Col>
        </Row>
        <div className={s.container}>
          <div className={s.wrapper}>
            <ReactDataGrid
              enableCellSelect={true}
              ref={node => (this.grid = node)}
              enableRowSelect={true}
              columns={[
                {
                  key: "nombre",
                  name: "Club"
                },
                {
                  key: "nombreMembresia",
                  name: "Membresía"
                },
                {
                  key: "tipoIntegrante",
                  name: "Tipo de Integrante"
                },
                {
                  key: "numeroIntegrante",
                  name: "Número de Integrante"
                },
                {
                  key: "precio",
                  name: "Precio",
                  editable: !(this.props.savingPrices || this.props.loading)
                }
              ]}
              rowGetter={this.rowGetter}
              rowsCount={this.state.rows.length}
              onGridRowsUpdated={this.handleGridRowsUpdated}
              rowRenderer={RowRender}
              minHeight={CONSTANTS.TABLES_HEIGHT_DATA_GRID}
              rowSelection={{
                showCheckbox: true,
                enableShiftSelect: true,
                onRowsSelected: this.onRowsSelected,
                onRowsDeselected: this.onRowsDeselected,
                selectBy: {
                  indexes: this.state.selectedIndexes
                }
              }}
            />
          </div>
        </div>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(PriceDataMaintenanceForm));
