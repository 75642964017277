import React, { Component } from "react";
import {
  Router,
  RouterProps,
  Styled
} from "../../../toolkit/decorators/Artifact";
import { InvitadosDetalleDispatcher } from "../../containers/invitadosDetalle/InvitadosDetalle.dispatcher";
import { InvitadosDetalleStore } from "../../containers/invitadosDetalle/InvitadosDetalle.store";
import s from "./styles.scss";
import { Nav, NavItem, NavLink, TabContent } from "reactstrap";
import { userHasPermission } from "../../../utils/accessTree";
import permissionCodes from "../../../utils/permissionCodes";
import InvitadosComponent from "../invitados";
import InvitadoDetalle from "../../../types/InvitadoDetalle";
import ExpedienteInvitadoTabla from "../expedientsV2invitadoTable";
import Survey from "../../components/surveys";
import { getSurveysRequest, saveAnswersRequest } from "../../actions/surveys";
import {
  errorNotification,
  successNotification
} from "../../../utils/notifications";
import TabMovimientos from "./tabs/tabMovimientos";
import TabBitacora from "./tabs/tabBitacora";

// Permisos
const {
  INVITADO_TAB_DOCUMENTACION: TAB_DOCUMENTACION,
  INVITADO_TAB_DAC: TAB_DAC,
  INVITADO_TAB_BITACORA: TAB_BITACORA,
  INVITADO_TAB_MOVIMIENTOS: TAB_MOVIMIENTOS,
  INVITADO_TAB_DATOS: TAB_DATOS,
  INVITADO_TAB_ACCESOS: TAB_ACCESOS
} = permissionCodes;

interface Props
  extends InvitadosDetalleDispatcher,
    InvitadosDetalleStore,
    RouterProps<any> {
  detalle: InvitadoDetalle;
}

interface State {
  activeTab: string;
  dacEncuesta: any[];
  sinPermisos: boolean;
}

@Router()
@Styled(s)
export default class InvitadosDetalleTab extends Component<Props, State> {
  state = {
    activeTab: null,
    dacEncuesta: null,
    sinPermisos: false
  };

  async componentDidMount(): Promise<void> {
    getSurveysRequest()
      .then(({ data }) => {
        this.setState({ dacEncuesta: data });
      })
      .catch(() => errorNotification("Error al cargar la Encuesta DAC"));

    if (userHasPermission(TAB_DOCUMENTACION)) {
      this.setState({ activeTab: "2" });
    } else if (userHasPermission(TAB_DAC)) {
      this.setState({ activeTab: "3" });
    } else if (userHasPermission(TAB_DATOS)) {
      this.setState({ activeTab: "1" });
    } else if (userHasPermission(TAB_MOVIMIENTOS)) {
      this.setState({ activeTab: "4" });
    } else if (userHasPermission(TAB_ACCESOS)) {
      this.setState({ activeTab: "5" });
    } else if (userHasPermission(TAB_BITACORA)) {
      this.setState({ activeTab: "6" });
    } else {
      this.setState({ sinPermisos: true });
    }
  }

  guardarRespuestas = respuestas => {
    saveAnswersRequest(respuestas)
      .then(() => {
        successNotification("Encuesta guardada correctamente");
      })
      .catch(() => {
        errorNotification("Error al guardar la encuesta");
      });
  };

  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  tabStyle = tab => `${this.state.activeTab === tab ? "active" : ""} ${s.tab}`;

  render() {
    if (this.state.sinPermisos) {
      return <></>;
    }

    return (
      <>
        <Nav className={s.tabs} tabs>
          <NavItem hidden={!userHasPermission(TAB_DOCUMENTACION)}>
            <NavLink
              className={this.tabStyle("2")}
              onClick={() => this.toggleTab("2")}
            >
              Documentación
            </NavLink>
          </NavItem>
          <NavItem hidden={!userHasPermission(TAB_DAC)}>
            <NavLink
              className={this.tabStyle("3")}
              onClick={() => this.toggleTab("3")}
            >
              Encuesta DAC
            </NavLink>
          </NavItem>
          <NavItem hidden={!userHasPermission(TAB_DATOS)}>
            <NavLink
              className={this.tabStyle("1")}
              onClick={() => this.toggleTab("1")}
            >
              Datos Básicos
            </NavLink>
          </NavItem>
          <NavItem hidden={!userHasPermission(TAB_MOVIMIENTOS)}>
            <NavLink
              className={this.tabStyle("4")}
              onClick={() => this.toggleTab("4")}
            >
              Movimientos
            </NavLink>
          </NavItem>
          <NavItem hidden={true || !userHasPermission(TAB_ACCESOS)}>
            <NavLink
              className={this.tabStyle("5")}
              onClick={() => this.toggleTab("5")}
            >
              Accesos
            </NavLink>
          </NavItem>
          <NavItem hidden={!userHasPermission(TAB_BITACORA)}>
            <NavLink
              className={this.tabStyle("6")}
              onClick={() => this.toggleTab("6")}
            >
              Bitácora
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent className="h-100" activeTab={this.state.activeTab}>
          {/* DATOS */}
          {this.state.activeTab === "1" && (
            <InvitadosComponent {...this.props} detalle={this.props.invitado} />
          )}

          {/* DOCUMENTACION */}
          {this.state.activeTab === "2" && (
            <ExpedienteInvitadoTabla
              idPersona={this.props.invitado.idPersona}
            />
          )}

          {/* DAC */}
          {this.state.activeTab === "3" && this.state.dacEncuesta && (
            <>
              <div className={s.encuestaMargen}></div>
              <Survey
                idMembresiaSocio={null}
                surveys={this.state.dacEncuesta}
                idPersona={this.props.invitado.idPersona}
                saveAnswers={this.guardarRespuestas}
              />
            </>
          )}
          {this.state.activeTab === "3" && !this.state.dacEncuesta && (
            <>Cargando encuesta...</>
          )}

          {/* MOVIMIENTOS */}
          {this.state.activeTab === "4" && (
            <TabMovimientos detalle={this.props.detalle} {...({} as any)} />
          )}

          {/* ACCESOS */}
          {this.state.activeTab === "5" && <></>}

          {/* BITACORA */}
          {this.state.activeTab === "6" && (
            <TabBitacora detalle={this.props.detalle} {...({} as any)} />
          )}
        </TabContent>
      </>
    );
  }
}
