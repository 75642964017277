import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import EmpresaConvenioComponent from "../../components/empresaConvenio";
import {
  EmpresaConvenioDispatcher,
  empresaConvenioDispatcher
} from "./EmpresaConvenio.dispatcher";
import {
  empresaConvenioStore,
  EmpresaConvenioStore
} from "./EmpresaConvenio.store";
import {
  ReduxConnect,
  Router,
  RouterProps
} from "../../../toolkit/decorators/Artifact";

interface Props
  extends EmpresaConvenioDispatcher,
    EmpresaConvenioStore,
    RouterProps {}

interface State {}

@Router()
@ReduxConnect(empresaConvenioStore, empresaConvenioDispatcher)
export default class EmpresaConvenioContainer extends React.Component<
  Props,
  State
> {
  goTo = (destiny: string, select: any) => {
    if (select) {
      this.props.history.push(destiny + select.id);
    } else {
      this.props.history.push(destiny);
    }
  };

  render() {
    return (
      <main>
        <Helmet title="Companies" />
        <Layout>
          <EmpresaConvenioComponent
            {...this.props}
            actionCompanie={(companie?) =>
              this.goTo(
                `/empresas-convenios/${
                  companie ? companie.id + "/" : ""
                }configuracion`,
                null
              )
            }
          />
        </Layout>
      </main>
    );
  }
}
