import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import s from "./styles.scss";
import { Person } from "./interfaces";
import PersonSelector from "../../../People/containers/PersonSelector";
import moment from "moment";
import BaseInput from "../../../toolkit/baseInput";
import axios from "axios";
import constants from "../../../utils/constants";
import {
  errorNotification,
  successNotification
} from "../../../utils/notifications";

// TODO: Implementar patrón Redux (Revisión 2)
interface Props {
  clearSelectedPerson: () => any;
  clubs: any[];
}

interface State {
  person: Person;
  passesInfo: any[];
  isModalOpen: boolean;
  filters: {
    idClub: string;
    idPublico: string;
  };
  queryString: string;
  isDocumentOpen: boolean;
  correo: string;
  mail: {
    asunto: string;
    fecha: string;
    parrafos: string;
    agradecimiento: string;
  };
}

class OneDayPassActivatorPage extends React.Component<Props, State> {
  state = {
    person: null,
    passesInfo: [],
    passInfo: null,
    passUsed: null,
    fechaInicioVigencia: moment().format("YYYY-MM-DD"),
    fechaFinVigencia: moment().format("YYYY-MM-DD"),
    isModalOpen: false,
    filters: {
      idClub: "",
      idPublico: ""
    },
    pase: null,
    queryString: "",
    isDocumentOpen: false,
    correo: null,
    mail: {
      asunto: "",
      fecha: "",
      parrafos: "",
      agradecimiento: ""
    }
  };

  onChangeFilters = data => {
    this.setState({ ...this.state, ...data });
  };

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  onChangeMail = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const mail = {
          ...this.state.mail,
          [fieldName]: value
        };
        return { ...state, mail };
      });
    };
  };

  preview = async () => {
    if (!this.validate()) {
      return;
    }

    const response = await axios.post(
      `${constants.BASE_URL}/api/mail/contact/preview`,
      this.state.mail,
      {
        withCredentials: true
      }
    );

    const w = window.open('target="_blank"');
    w.document.open();
    w.document.write(response.data);
    w.document.close();
  };

  send = async () => {
    if (!this.validate()) {
      return;
    }

    try {
      successNotification("Enviando mensaje...");
      const response = await axios.post(
        `${constants.BASE_URL}/api/mail/contact/send`,
        {
          correo: this.state.correo,
          ...this.state.mail
        },
        {
          withCredentials: true
        }
      );

      this.setState({
        mail: {
          asunto: "",
          fecha: "",
          parrafos: "",
          agradecimiento: ""
        }
      });

      successNotification("Mensaje enviado correctamente");
    } catch (error) {
      const message =
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
        "Error, por favor intente de nuevo más tarde";
      errorNotification(message);
    }
  };

  validate = (): boolean => {
    const mail = this.state.mail;

    if (!mail.asunto) {
      errorNotification("Debe especificar el asunto del correo");
      return false;
    }
    if (!mail.parrafos) {
      errorNotification("Debe especificar el contenido del correo");
      return false;
    }
    if (!mail.agradecimiento) {
      errorNotification("Debe especificar la despedida del correo");
      return false;
    }
    return true;
  };

  setPerson = (person: Person) => {
    if (person) {
      // Búsqueda del correo
      const correos = person.correosElectronicos || [];

      if (!correos || correos.length == 0) {
        errorNotification(
          "La persona seleccionada no tiene un correo configurado"
        );

        this.setState({
          person: null,
          correo: null
        });

        return;
      }

      const correoPrincial = correos.find(c => c.principal);
      const correoFirst = correos[0];
      const correo = (correoPrincial || correoFirst).correo;

      this.setState({
        person,
        correo
      });
    } else {
      this.setState({
        person: null,
        correo: null
      });
    }
  };

  render() {
    return (
      <Container className="mt-3 mb-5 p-0">
        <Row className="my-4">
          {/* Búsqueda por socio */}
          <Col md="12">
            <div style={{ marginBottom: "15px" }}>
              <p className={`${s.primaryTitle} w-100 m-0`}>
                Búsqueda por socio o prospecto
              </p>
            </div>
            <div style={{ paddingRight: "15px" }}>
              <PersonSelector setPerson={this.setPerson} cKey={"P5ERE2H2C7"} />
            </div>
          </Col>
        </Row>

        {/* Mensaje */}
        <div hidden={!this.state.person}>
          <p className={s.grayTitle}>
            Configuración del correo para {this.state.correo}
          </p>

          <Row>
            <Col sm="12" className="mb-2">
              <BaseInput
                label="*Asunto"
                name="asunto"
                id="asunto"
                placeholder="Un mensaje de tus amigos de Sports World"
                value={this.state.mail.asunto}
                onChange={this.onChangeMail("asunto")}
                size={"sm"}
              />
            </Col>

            <Col sm="12" className="mb-2">
              <BaseInput
                label="Fecha formal, omitible"
                name="fecha"
                id="fecha"
                placeholder="Ciudad de México a 16 de Agosto de 2022"
                value={this.state.mail.fecha}
                onChange={this.onChangeMail("fecha")}
                size={"sm"}
              />
            </Col>

            <Col sm="12" className="mb-2">
              <span style={{ fontSize: "0.8rem" }}>*Contenido:</span>
              <textarea
                name="parrafos"
                rows={10}
                id="parrafos"
                value={this.state.mail.parrafos}
                className="form-control"
                onChange={this.onChangeMail("parrafos")}
              />
            </Col>

            <Col sm="12" className="mb-2">
              <BaseInput
                label="*Despedida"
                name="agradecimiento"
                id="agradecimiento"
                placeholder="Agradecemos su preferencia"
                value={this.state.mail.agradecimiento}
                onChange={this.onChangeMail("agradecimiento")}
                size={"sm"}
              />
            </Col>
          </Row>

          <Row>
            <Col sm="8">&nbsp;</Col>
            <Col>
              <Button
                size="sm"
                className={`${s.searchButton} ${s.primaryButton} float-right btn-block`}
                onClick={() => this.preview()}
                style={{ marginTop: "25px" }}
              >
                Vista previa
              </Button>
            </Col>
            <Col>
              <Button
                size="sm"
                className={`${s.searchButton} ${s.primaryButton} float-right btn-block`}
                onClick={() => this.send()}
                style={{ marginTop: "25px" }}
              >
                Enviar
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(OneDayPassActivatorPage));
