import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Container, Row, Col, Button } from "reactstrap";
import { parse as parseQueryParams } from "query-string";

interface Props {
  history: any;
  match: any;
  location: any;
  getPartner: (params: object) => any;
}

interface Partner {
  nombrePersona: string;
  nombreMembresia: string;
  nombreConvenio: string;
  certificado: boolean;
  estatusMembresia: string;
  fechaAdquisicion: string;
  fechaInicioMantenimiento: string;
  idClubAlterno: string;
  idClubBase: string;
  idMembresia: string;
  idMembresiaSocio: string;
  idPersona: string;
  intransferible: boolean;
  limiteInicioMantenimiento: string;
  nueva: boolean;
  precioBaseMembresia: string;
  precioVentaMembresia: string;
  ventaEnLinea: boolean;
  membershipDocuments: object[];
}

interface State {
  filters: {
    idClub: string;
    idPublico: string;
  };
  idMembresiaSocio: string;
  activeTab: string;
  partner: Partner;
}

class PartnerSuccessfullPurchase extends React.Component<Props, State> {
  state = {
    filters: {
      idClub: "",
      idPublico: ""
    },
    idMembresiaSocio: "",
    activeTab: "1",
    partner: null
  };

  handleClick = e => {
    e.preventDefault();
    this.props.history.push(
      `/productos/carrito/${this.state.partner.idPersona}`
    );
  };

  searchParner = filters => {
    this.setState(state => {
      return {
        ...state,
        filters
      };
    });
    this.props.getPartner(filters).then(({ value: { data } }) => {
      this.setState({ partner: data });
    });
  };

  componentDidMount() {
    const { idPublico, idClub } = parseQueryParams(this.props.location.search);

    if (idPublico && idClub) {
      const filters = {
        idPublico,
        idClub
      };

      this.searchParner(filters);
    }
  }
  render() {
    const { idPublico } = parseQueryParams(this.props.location.search);

    return (
      <Container className="p-0 container" fluid>
        <Row className="mt-3 mb-3">
          <Col>
            <p className={`${s.message}`}>
              {`Membresía creada correctamente con el id público: # ${idPublico}`}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              className={`${s.primaryButton}`}
              onClick={e => this.handleClick(e)}
              size="lg"
              disabled={!this.state.partner}
              block
            >
              Pagar
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(PartnerSuccessfullPurchase));
