import withStyles from "isomorphic-style-loader/lib/withStyles";
import moment from "moment";
import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import ModalFrame from "../../../../toolkit/modalFrame";
import { handleRequestError } from "../../../../utils/helpers";
import { generateFreeGuestsAccess } from "../../../actions/partners";
import TextWithLineBreaks from "../../common/TextWithLineBreaks";
import s from "./styles.scss";

interface Props {
  isOpen: boolean;
  partnerMembershipId: number;
  movementId: number;
  onOk: () => void;
  onCancel: () => void;
}

interface State {
  fields: {
    noGuests: number;
    finalAccessDate: String;
  };
}

class ModalAddFreeGuestAccess extends React.Component<Props, State> {
  state = {
    fields: {
      noGuests: 0,
      finalAccessDate: null
    }
  };

  clearState = () => {
    const { fields } = this.state;
    fields.noGuests = 0;
    fields.finalAccessDate = null;
    this.setState({ fields: { ...fields } });
  };

  onOkButton = () => {
    //CALLS endpoint
    const {
      partnerMembershipId: membresiaSocioId,
      movementId: cotizacionProductoItemId
    } = this.props;

    const data = {
      membresiaSocioId,
      cotizacionProductoItemId,
      noInvitadosGratis: 1,
      fechaFinAcceso: "2000-01-01"
    };

    generateFreeGuestsAccess(this.props.partnerMembershipId, data)
      .then(() => {
        this.clearState();
        if (!!this.props.onOk) {
          this.props.onOk();
        }
      })
      .catch(handleRequestError);
  };

  onCancelButton = () => {
    this.clearState();

    if (!!this.props.onCancel) {
      this.props.onCancel();
    }
  };

  onChangeNumberFactory = fieldName => {
    return event => {
      const value = event.target.value.replace(/[^0-9]/gi, "");
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };
        return { ...state, error: {}, fields };
      });
    };
  };

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: date
        };
        return { ...state, error: {}, fields };
      });
    };
  };

  render() {
    return (
      <ModalFrame
        title={`Creación de acceso para invitado gratis`}
        isOpen={this.props.isOpen}
        toggle={() => {
          this.onCancelButton();
        }}
        size="md"
      >
        <TextWithLineBreaks
          text={
            "Se generará un cargo el cual, una vez pagado, habilitará los beneficios para alta de invitado gratis.\n\n¿Desea continuar?"
          }
        />

        {/* <Row>
          <Col xs={12} md={6} lg={6}>
            <BaseNumericInput
              label={"# Invitados gratis"}
              name="noInvitadosGratis"
              id="noInvitadosGratis"
              value={this.state.fields.noGuests}
              placeholder={"# Invitados gratis"}
              onChange={this.onChangeNumberFactory("noGuests")}
            />
          </Col>
          <Col xs={12} md={6} lg={6}>
            <DatePicker
              label="Fecha final de acceso"
              name="fechaFinAcceso"
              id="fechaFinAcceso"
              selected={this.state.fields.finalAccessDate}
              onChange={this.onChangeDate("finalAccessDate")}
            />
          </Col>
        </Row> */}
        <Row className="mt-4">
          <Col xs={6} md={6} lg={6}>
            <Button
              className={`${s.primaryButton}`}
              block
              color="secondary"
              onClick={() => {
                this.onOkButton();
              }}
            >
              CREAR ACCESO
            </Button>
          </Col>
          <Col xs={6} md={6} lg={6}>
            <Button
              block
              color="secondary"
              onClick={() => {
                this.onCancelButton();
              }}
              disabled={false}
            >
              CANCELAR
            </Button>
          </Col>
        </Row>
      </ModalFrame>
    );
  }
}

export default withRouter(withStyles(s)(ModalAddFreeGuestAccess));
