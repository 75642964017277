import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import {
  infoNotification,
  CREATE_COMISSIONS_SCHEMA_PENDING,
  errorNotification,
  CREATE_COMISSIONS_SCHEMA_ERROR,
  successNotification,
  CREATE_COMISSIONS_SCHEMA,
  SAVE_POST_BY_SCHEMAID_PENDING,
  SAVE_POST_BY_SCHEMAID_ERROR,
  SAVE_POST_BY_SCHEMAID,
  ERROR
} from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";
interface State {
  indicators: object[];
  schemaById: object;
  schemas: object[];
  posts: object;
  savePosts: boolean;
}
const initialState: State = {
  indicators: null,
  schemaById: null,
  schemas: null,
  posts: { puestos: [] },
  savePosts: true
};

export default function benefitsSchemasReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.CLEAN_SCHEMA_BY_ID}`: {
      return {
        ...state,
        schemaById: null,
        posts: { puestos: [] }
      };
    }
    case `${actionTypes.CLEAN_PETICION_DATA}`: {
      return {
        ...state,
        posts: action.payload
      };
    }
    case `${actionTypes.GET_INDICATORS}_PENDING`: {
      return {
        ...state,
        indicators: null
      };
    }
    case `${actionTypes.GET_INDICATORS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        indicators: null
      };
    }
    case `${actionTypes.GET_INDICATORS}_FULFILLED`: {
      return {
        ...state,
        indicators: action.payload.data
      };
    }
    case `${actionTypes.GET_SCHEMA_BY_ID}_PENDING`: {
      return {
        ...state,
        schemaById: null
      };
    }
    case `${actionTypes.GET_SCHEMA_BY_ID}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        schemaById: null
      };
    }
    case `${actionTypes.GET_SCHEMA_BY_ID}_FULFILLED`: {
      return {
        ...state,
        schemaById: action.payload.data
      };
    }
    case `${actionTypes.CREATE_COMISSIONS_SCHEMA}_PENDING`: {
      infoNotification(CREATE_COMISSIONS_SCHEMA_PENDING);
      return {
        ...state,
        schemaById: null
      };
    }
    case `${actionTypes.CREATE_COMISSIONS_SCHEMA}_REJECTED`: {
      errorNotification(
        readMessageBack(action, CREATE_COMISSIONS_SCHEMA_ERROR)
      );
      return {
        ...state,
        schemaById: null
      };
    }
    case `${actionTypes.CREATE_COMISSIONS_SCHEMA}_FULFILLED`: {
      successNotification(CREATE_COMISSIONS_SCHEMA);
      return {
        ...state,
        schemaById: action.payload.data
      };
    }

    case `${actionTypes.GET_BENEFITS_SCHEMAS}_PENDING`: {
      return {
        ...state,
        schemas: []
      };
    }
    case `${actionTypes.GET_BENEFITS_SCHEMAS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        schemas: []
      };
    }
    case `${actionTypes.GET_BENEFITS_SCHEMAS}_FULFILLED`: {
      return {
        ...state,
        schemas: action.payload.data
      };
    }

    case `${actionTypes.GET_POSTS_BYSCHEMA}_PENDING`: {
      return {
        ...state,
        posts: []
      };
    }
    case `${actionTypes.GET_POSTS_BYSCHEMA}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        posts: []
      };
    }
    case `${actionTypes.GET_POSTS_BYSCHEMA}_FULFILLED`: {
      return {
        ...state,
        posts: action.payload.data
      };
    }
    case `${actionTypes.SAVE_POSTS_BYSCHEMA}_PENDING`: {
      infoNotification(SAVE_POST_BY_SCHEMAID_PENDING);
      return {
        ...state,
        savePosts: true
      };
    }
    case `${actionTypes.SAVE_POSTS_BYSCHEMA}_REJECTED`: {
      errorNotification(readMessageBack(action, SAVE_POST_BY_SCHEMAID_ERROR));
      return {
        ...state,
        savePosts: false
      };
    }
    case `${actionTypes.SAVE_POSTS_BYSCHEMA}_FULFILLED`: {
      successNotification(SAVE_POST_BY_SCHEMAID);
      return {
        ...state,
        savePosts: true
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
