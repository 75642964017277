import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Container, Row, Col } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import { handleRequestError } from "../../../utils/helpers";
import { getDemandsClassificationsFiiltered } from "../../actions/demans";
import {
  DEFAULT_ID,
  DEMAND_CLASSIFICATION_TYPES
} from "../../../utils/constants";
import { getEnumOptions } from "../../../utils/helpers";

interface ClassificationNode {
  id: number;
  tipo: string;
  descripcion: string;
  nivel: number;
  padre: number;
  estatus: boolean;
  criticidad: string;
}

interface Props {
  level1: number;
  level2: number;
  level3: number;
  level4: number;
  type: string;
  tag: string;
  searchMode: boolean;
  onChangeType: (type: string) => any;
  onChangeClassification: (classification: ClassificationNode) => any;
}

interface State {
  filters: {
    type: string;
    level1: number;
    level2: number;
    level3: number;
    level4: number;
  };
  level1Data: ClassificationNode[];
  level2Data: ClassificationNode[];
  level3Data: ClassificationNode[];
  level4Data: ClassificationNode[];
  searching: boolean;
}

class DemandClassificationSelector extends React.Component<Props, State> {
  state = {
    filters: {
      type: this.props.searchMode ? "TODOS" : DEMAND_CLASSIFICATION_TYPES.QUEJA,
      level1: 0,
      level2: 0,
      level3: 0,
      level4: 0
    },
    level1Data: [],
    level2Data: [],
    level3Data: [],
    level4Data: [],
    searching: false
  };

  componentDidMount() {
    this.getLevel1Data();
  }

  componentDidUpdate(prevProps) {
    const { tag } = this.props;
    const { tag: prevTag } = prevProps;
    if (!!tag && tag != prevTag) {
      const { level1, level2, level3, level4, type } = this.props;
      const { filters } = this.state;

      filters.type = type;
      filters.level1 = level1;
      filters.level2 = level2;
      filters.level3 = level3;
      filters.level4 = level4;

      this.setState({
        filters
      });

      this.getLevel1Data();
      this.getLevel2Data();
      this.getLevel3Data();
      this.getLevel4Data();
    }
  }

  getLevel1Data = () => {
    const { type } = this.state.filters;
    if (type != "TODOS") {
      getDemandsClassificationsFiiltered(type, 1, -1)
        .then(({ data }) => {
          this.setState({ level1Data: data });
        })
        .catch(handleRequestError);
    } else {
      this.setState({ level1Data: [] });
    }
  };
  getLevel2Data = () => {
    const { type, level1 } = this.state.filters;
    if (!!level1) {
      getDemandsClassificationsFiiltered(type, 2, level1)
        .then(({ data }) => {
          this.setState({ level2Data: data });
        })
        .catch(handleRequestError);
    } else {
      this.setState({ level2Data: [] });
    }
  };
  getLevel3Data = () => {
    const { type, level2 } = this.state.filters;
    if (!!level2) {
      getDemandsClassificationsFiiltered(type, 3, level2)
        .then(({ data }) => {
          this.setState({ level3Data: data });
        })
        .catch(handleRequestError);
    } else {
      this.setState({ level3Data: [] });
    }
  };
  getLevel4Data = () => {
    const { type, level3 } = this.state.filters;
    if (!!level3) {
      getDemandsClassificationsFiiltered(type, 4, level3)
        .then(({ data }) => {
          this.setState({ level4Data: data });
        })
        .catch(handleRequestError);
    } else {
      this.setState({ level4Data: [] });
    }
  };

  executePropOnChangeClassification(data?: ClassificationNode[], id?: number) {
    if (!!this.props.onChangeClassification) {
      if (!!data) {
        this.props.onChangeClassification(data.find(x => x.id == id));
      } else {
        this.props.onChangeClassification(null);
      }
    }
  }

  render() {
    return (
      <Container className="p-0 mt-3">
        <Row>
          <Col>
            <BaseInput
              type="select"
              label="Tipo*"
              name="demandClassificationType"
              id="demandClassificationType"
              placeholder="Tipo de clasificación"
              value={this.state.filters.type}
              options={(!!this.props.searchMode
                ? [{ value: null, label: "TODOS" }]
                : []
              ).concat(getEnumOptions(DEMAND_CLASSIFICATION_TYPES))}
              onChange={e => {
                const value = e.target.value;
                const { filters } = this.state;
                filters.type = value;
                filters.level1 = DEFAULT_ID;
                filters.level2 = DEFAULT_ID;
                filters.level3 = DEFAULT_ID;
                filters.level4 = DEFAULT_ID;
                this.setState({
                  filters,
                  level1Data: [],
                  level2Data: [],
                  level3Data: [],
                  level4Data: []
                });
                this.getLevel1Data();

                if (!!this.props.onChangeType) {
                  this.props.onChangeType(value);
                }
                // this.executePropOnChangeClassification();
              }}
            />
          </Col>
          <Col>
            <BaseInput
              type="select"
              label="Nivel 1*"
              name="demandClassificationLevel1"
              id="demandClassificationLevel1"
              placeholder="Nivel 1"
              value={this.state.filters.level1}
              disabled={!(this.state.level1Data || []).length}
              options={[{ value: DEFAULT_ID, label: "Seleccione..." }].concat(
                (this.state.level1Data || []).map(
                  (option: ClassificationNode) => ({
                    value: option.id,
                    label: option.descripcion
                  })
                )
              )}
              onChange={e => {
                const value = e.target.value;
                const { filters } = this.state;
                filters.level1 = value;
                filters.level2 = DEFAULT_ID;
                filters.level3 = DEFAULT_ID;
                filters.level4 = DEFAULT_ID;
                this.setState({
                  filters,
                  level2Data: [],
                  level3Data: [],
                  level4Data: []
                });
                this.getLevel2Data();

                if (!!value) {
                  this.executePropOnChangeClassification(
                    this.state.level1Data,
                    value
                  );
                } else {
                  this.executePropOnChangeClassification();
                }
              }}
            />
          </Col>
          <Col>
            <BaseInput
              type="select"
              label="Nivel 2*"
              name="demandClassificationLevel2"
              id="demandClassificationLevel2"
              placeholder="Nivel 2"
              value={this.state.filters.level2}
              disabled={!(this.state.level2Data || []).length}
              options={[{ value: DEFAULT_ID, label: "Seleccione..." }].concat(
                (this.state.level2Data || []).map(
                  (option: ClassificationNode) => ({
                    value: option.id,
                    label: option.descripcion
                  })
                )
              )}
              onChange={e => {
                const value = e.target.value;
                const { filters } = this.state;
                filters.level2 = value;
                filters.level3 = DEFAULT_ID;
                filters.level4 = DEFAULT_ID;
                this.setState({
                  filters,
                  level3Data: [],
                  level4Data: []
                });
                this.getLevel3Data();

                if (!!value) {
                  this.executePropOnChangeClassification(
                    this.state.level2Data,
                    value
                  );
                } else {
                  this.executePropOnChangeClassification(
                    this.state.level1Data,
                    this.state.filters.level1
                  );
                }
              }}
            />
          </Col>
          <Col>
            <BaseInput
              type="select"
              label="Nivel 3*"
              name="demandClassificationLevel3"
              id="demandClassificationLevel3"
              placeholder="Nivel 3"
              value={this.state.filters.level3}
              disabled={!(this.state.level3Data || []).length}
              options={[{ value: DEFAULT_ID, label: "Seleccione..." }].concat(
                (this.state.level3Data || []).map(
                  (option: ClassificationNode) => ({
                    value: option.id,
                    label: option.descripcion
                  })
                )
              )}
              onChange={e => {
                const value = e.target.value;
                const { filters } = this.state;
                filters.level3 = value;
                filters.level4 = DEFAULT_ID;
                this.setState({
                  filters,
                  level4Data: []
                });
                this.getLevel4Data();

                if (!!value) {
                  this.executePropOnChangeClassification(
                    this.state.level3Data,
                    value
                  );
                } else {
                  this.executePropOnChangeClassification(
                    this.state.level2Data,
                    this.state.filters.level2
                  );
                }
              }}
            />
          </Col>
          <Col>
            <BaseInput
              type="select"
              label="Nivel 4*"
              name="demandClassificationLevel4"
              id="demandClassificationLevel4"
              placeholder="Nivel 4"
              value={this.state.filters.level4}
              disabled={!(this.state.level4Data || []).length}
              options={[{ value: DEFAULT_ID, label: "Seleccione..." }].concat(
                (this.state.level4Data || []).map(
                  (option: ClassificationNode) => ({
                    value: option.id,
                    label: option.descripcion
                  })
                )
              )}
              onChange={e => {
                const value = e.target.value;
                const { filters } = this.state;
                filters.level4 = value;
                this.setState({
                  filters
                });

                if (!!value) {
                  this.executePropOnChangeClassification(
                    this.state.level4Data,
                    value
                  );
                } else {
                  this.executePropOnChangeClassification(
                    this.state.level3Data,
                    this.state.filters.level3
                  );
                }
              }}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(DemandClassificationSelector));
