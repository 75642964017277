import React from "react";
import IClubSelectorProps from "./IClubSelector.props";
import IClubSelectorState from "./IClubSelector.state";
import ISelect from "../../baseForms2/iSelect/ISelect";
import ITypeSelect from "../../baseForms2/iTypeSelect/ITypeSelect";
import { ReduxConnect } from "../../decorators/Artifact";
import { iClubSelectorStore } from "./IClubSelector.store";
import { iClubSelectorDispatcher } from "./IClubSelector.dispatcher";

@ReduxConnect(iClubSelectorStore, iClubSelectorDispatcher)
export default class IClubSelectorConnected extends React.Component<
  IClubSelectorProps,
  IClubSelectorState
> {
  componentDidMount(): void {
    if (!this.props.allClubsBasicData.length) {
      this.props.getAllClubsBasicData();
    }
  }

  componentDidUpdate(prevProps: Readonly<IClubSelectorProps>): void {
    if (this.props.reloadClubs && !prevProps.reloadClubs) {
      this.props.getAllClubsBasicData();
    }
  }

  render() {
    const Component = this.props.isTypeSelector ? ITypeSelect : ISelect;
    const clubs = this.props.includeAll
      ? this.props.allClubsBasicData
      : this.props.allClubsBasicData.filter(c => c.migradoCrm2);
    const sessionClub =
      this.props.userContext && this.props.userContext.clubSessionId;
    const useSessionClub =
      this.props.useSessionClub &&
      !!sessionClub &&
      !!clubs.find(c => c.clubId == sessionClub);
    const initClub = useSessionClub ? sessionClub : this.props["init"];

    initClub &&
      this.props.setter &&
      this.props.setter(this.props.name, initClub);

    return (
      <Component
        {...(this.props as any)}
        init={initClub}
        disabled={useSessionClub}
        data={clubs}
        isLoading={this.props.gettingAllClubsBasicData}
        mapOption={c => ({ value: c.clubId, label: c.nombre })}
      />
    );
  }
}
