import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import debounce from "lodash.debounce";
import React from "react";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import { Button, Col, Container, Row } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import BaseNumericInput from "../../../toolkit/baseNumericInput";
import { userHasPermission } from "../../../utils/accessTree";
import { handleRequestError } from "../../../utils/helpers";
import {
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import permissionCodes from "../../../utils/permissionCodes";
import { getClubsRequest } from "../../actions/catalogs";
import {
  getDebtRestructuringCatPreliminar,
  authorizeDebtRestructuringCatPreliminar
} from "../../actions/reports";
import ConfirmModal from "../common/confirmModal/confirmModal";
import MexicanCurrency from "../common/currency";
import { Props, State } from "./interfaces";
import s from "./styles.scss";

class CatPreliminarDebtRestructuring extends React.Component<Props, State> {
  state = {
    filters: {
      clubId: null,
      diasACobrar: null
    },
    onSearch: false,
    onSave: false,
    clubs: [],
    data: [],
    confirmAuthorizeDebtRestructuring: false
  };

  componentDidMount() {
    this.getClubsCatalog();

    const { filters } = this.state;
    filters.clubId = this.props.userContext.clubSessionId;
    filters.diasACobrar = null;
    this.setState({ filters: { ...filters } });
  }

  getClubsCatalog = () => {
    getClubsRequest()
      .then(({ data }) => {
        this.setState({ clubs: data });
      })
      .catch(handleRequestError);
  };

  columns = [
    {
      Header: () => <p className="font-weight-bold">Club</p>,
      Cell: row => {
        return <p className={s.initial}>{row.original.clubNombre}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold"># Membresía</p>,
      className: "text-center",
      Cell: row => {
        return <p className={s.initial}>{row.original.membresiaIdPublico}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Tipo</p>,
      className: "text-center",
      Cell: row => {
        return <p className={s.initial}>{row.original.tipoMedioPago}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Marca</p>,
      className: "text-center",
      Cell: row => {
        return <p className={s.initial}>{row.original.tipoTarjetaMedioPago}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Fecha cargo</p>,
      className: "text-center",
      Cell: row => {
        return <p className={s.initial}>{row.original.fechaAlta}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Tipo item</p>,
      className: "text-center",
      Cell: row => {
        return <p className={s.initial}>{row.original.tipoItem}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Mantenimiento</p>,
      className: "text-center",
      Cell: row => {
        return <p className={s.initial}>{row.original.mantenimientoNombre}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Importe</p>,
      className: "text-right",
      Cell: row => {
        return (
          <p className={s.initial}>
            <MexicanCurrency quantity={row.original.importeTotal} />
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Nuevo Importe</p>,
      className: "text-right",
      Cell: row => {
        return (
          <p className={s.initial}>
            <MexicanCurrency
              quantity={row.original.importeTotalRestructurado}
            />
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Titular</p>,
      Cell: row => {
        return <p className={s.initial}>{row.original.personaNombre}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Titular correo</p>,
      Cell: row => {
        return <p className={s.initial}>{row.original.personaCorreo}</p>;
      }
    }
  ];

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  onChangeNumberFactory = fieldName => {
    return event => {
      const value = event.target.value.replace(/[^0-9]/gi, "");
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  onClickDownloadCsvFile = () => {
    const { clubId, diasACobrar } = this.state.filters;
    this.props.downloadDebtRestructuringCatPreliminar(clubId, diasACobrar);
  };

  search = () => {
    infoNotification("Consultando datos");

    this.setState({
      onSearch: true,
      onSave: false
    });

    const { clubId, diasACobrar } = this.state.filters;

    if (!diasACobrar) {
      return;
    }

    this.setState({ data: [] });

    getDebtRestructuringCatPreliminar(clubId || null, diasACobrar)
      .then(({ data }) => {
        this.setState({ data });
        successNotification("Datos consultados correctamente");
      })
      .catch(handleRequestError);
  };

  authorizeDebtRestructuring = () => {
    const { clubId, diasACobrar } = this.state.filters;

    if (!diasACobrar) {
      return;
    }

    infoNotification("Actualizando importes...");

    this.setState({
      onSave: true
    });

    authorizeDebtRestructuringCatPreliminar(clubId || null, diasACobrar)
      .then(() => {
        this.setState({ data: [], onSave: false });
        successNotification("Importes actualizados correctamente");
      })
      .catch(handleRequestError);
  };

  debouncedAuthorizeDebtRestructuring = debounce(
    this.authorizeDebtRestructuring,
    3000
  );

  render() {
    const { clubs, data } = this.state;
    const searchAllClubsPermission = userHasPermission(
      permissionCodes.REPORT_CAT_PRELIMINAR_SEARCH_ALL_CLUBS
    );

    return (
      <Container className="p-0">
        <ConfirmModal
          title="Autorización de restructuración de deuda de cat preliminar"
          message="Se actualizará el importe de todos los cargos mostrados en el reporte y esta acción no podrá ser revertida.  ¿Desea continuar?"
          isOpen={this.state.confirmAuthorizeDebtRestructuring}
          size="md"
          ok={() => {
            infoNotification("Autorizando. Por favor espere. ");
            this.debouncedAuthorizeDebtRestructuring();
            this.setState({
              confirmAuthorizeDebtRestructuring: false
            });
          }}
          cancel={() => {
            this.setState({
              confirmAuthorizeDebtRestructuring: false
            });
          }}
        />

        <Row>
          <Col xs={12} md={3} lg={3}>
            <BaseInput
              label="Club"
              name="club"
              id="club"
              type="select"
              value={this.state.filters.clubId}
              onChange={this.onChangeFilter("clubId")}
              size={"sm"}
              options={[{ value: "", label: "Seleccionar" }].concat(
                (clubs || [])
                  .filter(c => !!c.migradoCrm2)
                  .map(option => ({
                    value: option.clubId,
                    label: option.nombre
                  }))
              )}
              disabled={!searchAllClubsPermission}
            />
          </Col>
          <Col xs={12} md={3} lg={3}>
            <BaseNumericInput
              label={"Días a cobrar"}
              name="diasACobrar"
              id="diasACobrar"
              type="text"
              value={this.state.filters.diasACobrar}
              placeholder={"Días a cobrar"}
              onChange={this.onChangeNumberFactory("diasACobrar")}
            />
          </Col>
          <Col xs={12} md={3} lg={3} className="mt-4">
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.search}
              disabled={
                !!this.state.onSave || !(this.state.filters.diasACobrar || "")
              }
            >
              Buscar
            </Button>
          </Col>
          <Col md={1} lg={1}></Col>
          <Col xs={12} md={2} lg={2} className="mt-4">
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={() => {
                this.setState({
                  confirmAuthorizeDebtRestructuring: true
                });
              }}
              disabled={
                !!this.state.onSave || (this.state.data || []).length <= 0
              }
            >
              Autorizar
            </Button>
          </Col>
        </Row>

        {!!(data || []).length && (
          <Row className={"mt-5"}>
            <Col sm="6">
              Resultados de la búsqueda:{" "}
              <b
                style={{ fontWeight: "bolder", display: "inline-block" }}
                className="ml-3"
              >
                {(data || []).length}
              </b>
            </Col>
            <Col sm="6" className="text-right">
              <span>Exportar a CSV: </span>
              <Button
                id="btnReportExportCsv"
                color="link"
                onClick={this.onClickDownloadCsvFile}
                disabled={
                  !!this.state.onSave || (this.state.data || []).length <= 0
                }
              >
                <FontAwesomeIcon icon={faFileExport} color="black" />
              </Button>
            </Col>
          </Row>
        )}
        <ReactTable
          data={data || []}
          columns={this.columns}
          className="mt-2 -striped -highlight"
          sortable={false}
          resizable={false}
          pageSize={20}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(CatPreliminarDebtRestructuring));
