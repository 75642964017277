import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import Reform from "@franleplant/reform";
import ModalFrame from "../../../../toolkit/modalFrame";
import { Button, Container, Row, Col } from "reactstrap";
import ClubSelector from "../../../containers/clubSelector";
import s from "./selectClubsModals.scss";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  history: any;
  addClubsToSportsClub: (clubs: object[]) => any;
  validClubs: object[];
  clubsInUse: object[];
}

interface State {
  fields: object;
  errors: object;
}

class SelectClubSportsProduct extends React.Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);

  state = {
    fields: {},
    errors: {}
  };

  validationRules = {};

  validationMessages = {
    required: _ => `Obligatorio`,
    default: _ => `Invalido`
  };

  onClickSave;

  render() {
    return (
      <ModalFrame
        title={`Actualizar Clubes`}
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle()}
        size="lg"
      >
        <Container style={{ width: "90%" }} className="center-block p-0 mb-3">
          <Row className="m-0">
            <ClubSelector
              onClickSave={f => (this.onClickSave = f)}
              setClubs={clubs => this.props.addClubsToSportsClub(clubs)}
              validClubs={this.props.validClubs}
              alreadyUsed={this.props.clubsInUse}
            />
          </Row>
          <Row className="m-0">
            <Col />
            <Col className="p-0" xs="2">
              <Button
                className={`${s.primaryButton} mt-3`}
                onClick={e => this.onClickSave(e)}
                size="sm"
                block
              >
                Aceptar
              </Button>
            </Col>
          </Row>
        </Container>
      </ModalFrame>
    );
  }
}

export default withRouter(withStyles(s)(SelectClubSportsProduct));
