import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import BaseNumericInput from "../../../toolkit/baseNumericInput";
import s from "./styles.scss";
import { Container, Form, Col, Row } from "reactstrap";

interface Props {
  benefits: any;
  updateFreeChildrenNumber: (_number: number) => any;
}

class PromotionBenefitsFreeChildren extends React.Component<Props> {
  onChangeNumber = () => {
    return e => {
      const value = e.target.value;
      this.props.updateFreeChildrenNumber(value);
    };
  };

  clearFieldsValues = () => {
    this.setState({
      ninosGratis: null
    });
  };

  render() {
    return (
      this.props.benefits && (
        <Container className="mt-5">
          <Form>
            <Row className="mt-3">
              <Col>
                <p style={{ color: "red", fontWeight: "bold" }}>
                  Niños gratis (de entre 3 y 12 años)
                </p>
              </Col>
            </Row>

            <Row>
              <Col xs={2}>
                <BaseNumericInput
                  label={"# Niños gratis"}
                  name="ninosGratis"
                  id="ninosGratis"
                  type="text"
                  value={this.props.benefits.ninosGratis}
                  placeholder={"# Niños gratis"}
                  onChange={this.onChangeNumber()}
                />
              </Col>
            </Row>
          </Form>
        </Container>
      )
    );
  }
}

export default withStyles(s)(PromotionBenefitsFreeChildren);
