import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import ReferidosLicenciasReporteComponent from "../../components/referidosLicenciasReporte";
import {
  ReferidosLicenciasReporteDispatcher,
  referidosLicenciasReporteDispatcher
} from "./ReferidosLicenciasReporte.dispatcher";
import {
  referidosLicenciasReporteStore,
  ReferidosLicenciasReporteStore
} from "./ReferidosLicenciasReporte.store";
import {
  ReduxConnect,
  Router,
  RouterProps
} from "../../../toolkit/decorators/Artifact";

interface Props
  extends ReferidosLicenciasReporteDispatcher,
    ReferidosLicenciasReporteStore,
    RouterProps {}

interface State {}

@Router()
@ReduxConnect(
  referidosLicenciasReporteStore,
  referidosLicenciasReporteDispatcher
)
export default class ReferidosLicenciasReporteContainer extends React.Component<
  Props,
  State
> {
  render() {
    return (
      <main>
        <Helmet title="Companies" />
        <Layout>
          <ReferidosLicenciasReporteComponent {...this.props} />
        </Layout>
      </main>
    );
  }
}
