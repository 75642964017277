import FormBuilderConfig from "../../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import ISelectBuilder from "../../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";

export type EmpresaConvenioInvitadoInputs = "activar" | "patron";

export const formConfig: FormBuilderConfig<
  EmpresaConvenioInvitadoInputs,
  string
> = {
  inputs: [
    {
      name: "activar",
      label: "Activar para invitados",
      bts: {
        sCol: 4
      }
    },
    {
      name: "patron",
      label: "Patrón de acceso",
      bts: {
        sCol: 4
      }
    }
  ],
  buttons: [],
  validations: {
    activar: {
      required: true
    },
    patron: {
      requiredIfActivo: {
        validator: (value, formValue) => !value && formValue.activar === "1",
        message: () => "Campo obligatorio"
      }
    }
  },
  rows: [["activar", "patron"]]
};
