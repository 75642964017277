import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getSchedulePatternsCatalog } from "../../../Home/actions/catalogs";
import { handleRequestError } from "../../../utils/helpers";
import {
  getGroupsSchedulePatterns,
  updateGroupsSchedulePatterns
} from "../../actions/schedules";
import ClubGroupsPatterns from "../../components/ClubGroupsPatterns";
import { Schedule } from "../../components/SchedulePatterns";

interface Props {
  successfulSave: boolean;
  validClubs: object[];
  match: any;
  productGroups: object[];
  patternGroupsMaintenance: object[];
  getClubsSportsProduct: (id: string) => any;
  onClickSave: (e: object) => any;
  updateGroupsSchedulePatterns: (id: string, groups: object[]) => any;
  getGroupsSchedulePatterns: (id: string) => any;
}

interface State {
  schedulePatterns: Schedule[];
}

class ClubGroupsPatternsContainer extends React.Component<Props, State> {
  state = {
    schedulePatterns: null
  };

  getSchedulePatterns = () => {
    getSchedulePatternsCatalog()
      .then(({ data }) => {
        this.setState({ schedulePatterns: data });
      })
      .catch(handleRequestError);
  };

  componentDidMount() {
    const { productId } = this.props.match.params;
    if (productId) {
      this.props.getGroupsSchedulePatterns(productId);
    }

    this.getSchedulePatterns();
  }

  render() {
    return (
      this.state.schedulePatterns && (
        <ClubGroupsPatterns
          patterns={this.state.schedulePatterns}
          updateGroupsSchedulePatterns={this.props.updateGroupsSchedulePatterns}
          onClickSave={this.props.onClickSave}
          patternGroupsMaintenance={this.props.patternGroupsMaintenance}
        />
      )
    );
  }
}

const mapDispatchToProps = {
  updateGroupsSchedulePatterns,
  getGroupsSchedulePatterns
};

const mapStateToProps = state => {
  const {
    schedulePatterns: { patternGroupsMaintenance }
  } = state;

  return {
    patternGroupsMaintenance
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ClubGroupsPatternsContainer)
);
