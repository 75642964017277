import BasicDataProduct from "./Home/containers/basicDataProduct";
import BasicDataPromotion from "./Home/containers/basicDataPromotion";
import BasicDataAgreement from "./Home/containers/basicDataAgreement";
import BasicDataAgreementGroup from "./Home/containers/agreementGroups/basicDataAgreement";
import BasicDataUser from "./Home/containers/basicDataUser";
import BasicDataDocument from "./Home/containers/basicDataDocument";
import BasicDataSportsProducts from "./Home/containers/basicDataSportsProducts";
import AddClubsToProducts from "./Home/containers/addClubsToProducts";
import AddClubsToPromotion from "./Home/containers/addClubsToPromotion";
import AddClubsToAgreement from "./Home/containers/addClubsToAgreement";
import AddClubsToAgreementGroup from "./Home/containers/agreementGroups/addClubsToAgreement";
import AddGroupsToSportsProducts from "./Home/containers/addGroupsToSportsProducts";
import AddMaintenancesToPromotion from "./Home/containers/addMaintenancesToPromotion";
import AddAgreementsToPromotion from "./Home/containers/addAgreementsToPromotion";
import AddMaintenancesToAgreement from "./Home/containers/addMaintenancesToAgreement";
import AddMaintenancesToAgreementGroup from "./Home/containers/agreementGroups/addMaintenancesToAgreement";
import AddPricesToPromotion from "./Home/containers/addPricesToPromotion";
import AddPricesToAgreement from "./Home/containers/addPricesToAgreement";
import AddPricesToAgreementGroup from "./Home/containers/agreementGroups/addPricesToAgreement";
import ConfigureMaintenance from "./Home/containers/configureMaintenance";
import PriceDataMaintenance from "./Home/containers/priceDataMaintenance";
import PriceDataMemberships from "./Home/containers/priceDataMemberships";
import PriceDataSportsProducts from "./Home/containers/priceDataSportsProducts";
import RolesUser from "./Home/containers/rolesUser";
import AddMembershipsDocument from "./Home/containers/addMembershipsDocument";
import UserPermitsPage from "./Home/containers/userPermits";
import ComissionsSportsProducts from "./Home/containers/comissionsSportsProducts";
import ClubGroupsPatterns from "./SchedulePatterns/containers/ClubGroupsPatterns";
import PayrollConceptsProducts from "./Home/containers/payrollConceptsProducts";
import basicDataCompanies from "./Home/containers/basicDataCompanies";
import basicDataEmpresaConvenio from "./Home/containers/basicDataEmpresaConvenio";

export const MAINTENANCES_STEPS = [
  {
    path: "/:tipoProducto(mantenimientos)/datos-basicos/:productId?",
    getNextStep: ({ productId }) =>
      `/mantenimientos/${productId}/seleccionar-clubes`,
    getHref: productId => `/mantenimientos/datos-basicos/${productId}`,
    component: BasicDataProduct,
    title: "Datos Básicos",
    parcialHidden: false
  },
  {
    path: "/:tipoProducto(mantenimientos)/:productId/seleccionar-clubes",
    getNextStep: ({ productId }) =>
      `/mantenimientos/${productId}/configuraciones-generales`,
    getHref: productId => `/mantenimientos/${productId}/seleccionar-clubes`,
    component: AddClubsToProducts,
    title: "Agregar Clubes",
    parcialHidden: false
  },
  {
    path: "/:tipoProducto(mantenimientos)/:productId/configuraciones-generales",
    getNextStep: ({ productId }) =>
      `/mantenimientos/${productId}/crear-grupos-patrones`,
    getHref: productId =>
      `/mantenimientos/${productId}/configuraciones-generales`,
    component: ConfigureMaintenance,
    title: "Configuraciones Generales"
  },
  {
    path: "/:tipoProducto(mantenimientos)/:productId/crear-grupos-patrones",
    getNextStep: ({ productId }) => `/productos/${productId}/precios`,
    getHref: productId => `/mantenimientos/${productId}/crear-grupos-patrones`,
    component: ClubGroupsPatterns,
    title: "Creación de patrones de acceso"
  },
  {
    path: "/:tipoProducto(mantenimientos)/:productId/precios",
    getNextStep: () => `/mantenimientos`,
    getHref: productId => `/mantenimientos/${productId}/precios`,
    component: PriceDataMaintenance,
    title: "Precios",
    parcialHidden: true
  }
];

export const MEMBERSHIPS_STEPS = [
  {
    path: "/:tipoProducto(membresias)/datos-basicos/:productId?",
    getNextStep: ({ productId }) =>
      `/membresias/${productId}/seleccionar-clubes`,
    getHref: productId => `/membresias/datos-basicos/${productId}`,
    component: BasicDataProduct,
    title: "Datos Básicos"
  },
  {
    path: "/:tipoProducto(membresias)/:productId/seleccionar-clubes",
    getNextStep: ({ productId }) => `/membresias/${productId}/precios`,
    getHref: productId => `/membresias/${productId}/seleccionar-clubes`,
    component: AddClubsToProducts,
    title: "Agregar Clubes"
  },
  {
    path: "/:tipoProducto(membresias)/:productId/precios",
    getNextStep: () => `/membresias`,
    getHref: productId => `/membresias/${productId}/precios`,
    component: PriceDataMemberships,
    title: "Precios"
  }
];

export const PROMOTIONS_STEPS = [
  {
    path: "/promociones/datos-basicos/:id?",
    getNextStep: ({ id }) => `/promociones/${id}/seleccionar-clubes`,
    getHref: id => `/promociones/datos-basicos/${id}`,
    component: BasicDataPromotion,
    title: "Datos Básicos Promocion"
  },
  {
    path: "/promociones/:id/seleccionar-clubes",
    getNextStep: ({ id }) => `/promociones/${id}/seleccionar-mantenimientos`,
    getHref: id => `/promociones/${id}/seleccionar-clubes`,
    component: AddClubsToPromotion,
    title: "Agregar Clubes a la Promoción"
  },
  {
    path: "/promociones/:id/seleccionar-mantenimientos",
    getNextStep: ({ id }) => `/promociones/${id}/seleccionar-convenios`,
    getHref: id => `/promociones/${id}/seleccionar-mantenimientos`,
    component: AddMaintenancesToPromotion,
    title: "Agregar Mantenimientos a la Promoción"
  },
  {
    path: "/promociones/:id/seleccionar-convenios",
    getNextStep: ({ id }) =>
      `/promociones/${id}/membresias/clubs/general/beneficios`,
    getHref: id => `/promociones/${id}/seleccionar-convenios`,
    component: AddAgreementsToPromotion,
    title: "Agregar Convenios a la Promoción"
  },
  {
    path:
      "/:tipo(promociones)/:id/membresias/:membershipId?/clubs/:clubId?/beneficios",
    getNextStep: ({ id }) => `/promociones/${id}/confirmacion`,
    getHref: id => `/promociones/${id}/membresias/clubs/beneficios`,
    component: AddPricesToPromotion,
    title: "Captura de Beneficios de Promoción"
  }
];

export const USERS_STEPS = [
  {
    path: "/usuarios/datos-basicos/:personaId?",
    getNextStep: ({ personaId }) => `/usuarios/${personaId}/roles`,
    getHref: personaId => `/usuarios/datos-basicos/${personaId}`,
    component: BasicDataUser,
    title: "Datos Básicos Usuario",
    backHref: "/usuarios",
    backLabel: "Regresar"
  },
  {
    path: "/usuarios/:personaId/roles",
    getNextStep: ({ personaId }) => `/usuarios/${personaId}/permisos`,
    getHref: personaId => `/usuarios/${personaId}/roles`,
    component: RolesUser,
    title: "Roles Usuario"
  },
  {
    path: "/usuarios/:personaId/permisos",
    getNextStep: () => `/usuarios`,
    getHref: personaId => `/usuarios/${personaId}/permisos`,
    component: UserPermitsPage,
    title: "Permisos Usuario"
  }
];

export const AGREEMENTS_STEPS = [
  {
    path: "/convenios/datos-basicos/:id?",
    getNextStep: ({ id }) => `/convenios/${id}/seleccionar-clubes`,
    getHref: id => `/convenios/datos-basicos/${id}`,
    component: BasicDataAgreement,
    title: "Datos Básicos Convenio"
  },
  {
    path: "/convenios/:id/seleccionar-clubes",
    getNextStep: ({ id }) => `/convenios/${id}/seleccionar-mantenimientos`,
    getHref: id => `/convenios/${id}/seleccionar-clubes`,
    component: AddClubsToAgreement,
    title: "Agregar Clubes al Convenio"
  },
  {
    path: "/convenios/:id/seleccionar-mantenimientos",
    getNextStep: ({ id }) =>
      `/convenios/${id}/membresias/clubs/general/beneficios`,
    getHref: id => `/convenios/${id}/seleccionar-mantenimientos`,
    component: AddMaintenancesToAgreement,
    title: "Agregar Mantenimientos al Convenio"
  },
  {
    path:
      "/:tipo(convenios)/:id/membresias/:membershipId?/clubs/:clubId?/beneficios",
    getNextStep: ({ id }) => `/convenios/${id}/confirmacion`,
    getHref: id => `/convenios/${id}/membresias/clubs/beneficios`,
    component: AddPricesToAgreement,
    title: "Captura de Beneficios de Convenios"
  }
];

export const AGREEMENT_GROUPS_STEPS = [
  {
    path: "/grupos-convenios/datos-basicos/:id?",
    getNextStep: ({ id }) => `/grupos-convenios/${id}/seleccionar-clubes`,
    getHref: id => `/grupos-convenios/datos-basicos/${id}`,
    component: BasicDataAgreementGroup,
    title: "Datos Básicos"
  },
  {
    path: "/grupos-convenios/:id/seleccionar-clubes",
    getNextStep: ({ id }) =>
      `/grupos-convenios/${id}/seleccionar-mantenimientos`,
    getHref: id => `/grupos-convenios/${id}/seleccionar-clubes`,
    component: AddClubsToAgreementGroup,
    title: "Clubes del Grupo"
  },
  {
    path: "/grupos-convenios/:id/seleccionar-mantenimientos",
    getNextStep: ({ id }) =>
      `/grupos-convenios/${id}/membresias/clubs/general/beneficios`,
    getHref: id => `/grupos-convenios/${id}/seleccionar-mantenimientos`,
    component: AddMaintenancesToAgreementGroup,
    title: "Mantenimientos del Grupo"
  },
  {
    path:
      "/:tipo(grupos-convenios)/:id/membresias/:membershipId?/clubs/:clubId?/beneficios",
    getNextStep: ({ id }) => `/grupos-convenios/${id}/confirmacion`,
    getHref: id => `/grupos-convenios/${id}/membresias/clubs/beneficios`,
    component: AddPricesToAgreementGroup,
    title: "Beneficios del Grupo"
  }
];

export const DOCUMENTS_STEPS = [
  {
    path: "/documentos/datos-basicos/:id?",
    getNextStep: ({ id }) => `/documentos/${id}/membresias`,
    getHref: id => `/documentos/datos-basicos/${id}`,
    component: BasicDataDocument,
    title: "Datos Básicos"
  },
  {
    path: "/documentos/:id/membresias",
    getNextStep: () => `/documentos`,
    getHref: id => `/documentos/${id}/membresias`,
    component: AddMembershipsDocument,
    title: "Membresias del Documento"
  }
];

export const SPORTS_PRODUCTS_STEPS = [
  {
    path: "/productos/datos-basicos/:id?",
    getNextStep: ({ id }) => `/productos/${id}/crear-grupos`,
    getHref: id => `/productos/datos-basicos/${id}`,
    component: BasicDataSportsProducts,
    title: "Datos Básicos"
  },
  {
    path: "/productos/:id/crear-grupos",
    getNextStep: ({ id }) => `/productos/${id}/configuraciones-generales`,
    getHref: id => `/productos/${id}/crear-grupos`,
    component: AddGroupsToSportsProducts,
    title: "Agregar Grupos al Producto"
  },
  {
    path: "/productos/:id/precios",
    getNextStep: ({ id }) => `/productos/${id}/promociones`,
    getHref: id => `/productos/${id}/precios`,
    component: PriceDataSportsProducts,
    title: "Precios del Producto"
  },
  {
    path: "/productos/:id/comisiones",
    getNextStep: () => `/productos/`,
    getHref: id => `/productos/${id}/comisiones`,
    component: ComissionsSportsProducts,
    title: "Comisiones del Producto"
  },
  {
    path: "/productos/:id/conceptos-nomina",
    getNextStep: () => `/productos/`,
    getHref: id => `/productos/${id}/conceptos-nomina`,
    component: PayrollConceptsProducts,
    title: "Conceptos de nomina"
  }
];

export const COMPANIES_STEPS = [
  {
    path: "/empresa/datos-basicos/:id?",
    getNextStep: () => `/empresas/`,
    getHref: id => `/empresa/datos-basicos/${id}`,
    component: basicDataCompanies,
    title: "Datos Básicos"
  }
];

export const EMPRESAS_CONVENIO_STEPS = [
  {
    path: "/empresas-convenios/datos-basicos/:id?",
    getNextStep: () => `/empresas-convenios/`,
    getHref: id => `/empresas-convenios/datos-basicos/${id}`,
    component: basicDataEmpresaConvenio,
    title: "Datos Básicos de Empresa"
  }
];
