import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import { withRouter } from "react-router-dom";
import { Container, Button } from "reactstrap";
import { handleRequestError } from "../../../utils/helpers";
import {
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import {
  executeGenerateChargesForPartnerMembershipsWithoutMonthCharge,
  getReportPartnerMembershipWithoutMonthCharge
} from "../../actions/reports";
import { Props, State } from "./interfaces";
import s from "./styles.scss";
import TableReportPartnerMembershipsWithoutCharge from "./tableReportPartnerMembershipsWithoutCharge";

class ReportPartnerMembershipsWithoutCharge extends React.Component<
  Props,
  State
> {
  state = {
    onSearch: false,
    data: []
  };

  componentDidMount() {
    this.search();
  }

  //CONTROL BEHAVIOR

  search = () => {
    this.setState({ onSearch: true, data: [] });

    infoNotification("Consultando datos...");

    getReportPartnerMembershipWithoutMonthCharge()
      .then(({ data }) => {
        this.setState({ data, onSearch: false });
        successNotification("Datos consultados correctamente...");
      })
      .catch(handleRequestError);
  };

  //RENDER
  render() {
    return (
      <Container className="p-0">
        <p className="primaryTitle w-100 mt-4">
          Mantenimientos con membresías socio sin cargo del mes corriente
        </p>

        {(this.state.data || []).length > 0 && (
          <div className="m-5 text-right">
            <Button
              className="primaryButton"
              disabled={this.state.onSearch}
              onClick={async e => {
                e.preventDefault();

                this.setState({ onSearch: true });

                infoNotification(
                  "Ejecutando proceso de generación de cargos mensuales"
                );

                executeGenerateChargesForPartnerMembershipsWithoutMonthCharge()
                  .then(() => {
                    successNotification(
                      "Proceso de generación de cargos mensuales ejecutado correctamente"
                    );
                    this.setState({ onSearch: false });
                    this.search();
                  })
                  .catch(handleRequestError);
              }}
              size="sm"
            >
              Ejecutar generación de cargos
            </Button>
          </div>
        )}

        <TableReportPartnerMembershipsWithoutCharge
          downloadReportPartnerMembershipWithoutMonthCharge={
            this.props.downloadReportPartnerMembershipWithoutMonthCharge
          }
          onSearch={this.state.onSearch}
          data={this.state.data}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ReportPartnerMembershipsWithoutCharge));
