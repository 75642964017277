import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import ErrorDetail from "../../components/errorDetail";

interface Props {}

class ErrorPage extends React.Component<Props> {
  state = {
    errorPath: null,
    error: null,
    info: null
  };

  componentDidMount() {
    this.setState({
      errorPath: localStorage.getItem("ERROR_PATH"),
      error: localStorage.getItem("ERROR"),
      info: localStorage.getItem("INFO")
    });
    localStorage.removeItem("ERROR_PATH");
    localStorage.removeItem("ERROR");
    localStorage.removeItem("INFO");
  }

  render() {
    const { errorPath, error, info } = this.state;

    return (
      <main>
        <Helmet title="Error Page" />
        <Layout>
          <ErrorDetail errorPath={errorPath} error={error} info={info} />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorPage);
