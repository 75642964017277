import React, { Component } from "react";
import { Router, RouterProps } from "../../../toolkit/decorators/Artifact";
import { InvitadosDispatcher } from "../../containers/invitados/Invitados.dispatcher";
import { InvitadosStore } from "../../containers/invitados/Invitados.store";
import Invitado, { InvitadoTipo } from "../../../types/Invitado";
import FormBuilder from "../../../toolkit/baseForms2/formBuilder/FormBuilder";
import { formConfig, Inputs, Buttons } from "./Invitados.form";
import {
  errorNotification,
  successNotification
} from "../../../utils/notifications";
import InvitadoDetalle, { mapInvitado } from "../../../types/InvitadoDetalle";
import PersonSelector, {
  Person
} from "../../../People/containers/PersonSelector";
import { Row, Container } from "reactstrap";
import { getSchedulePatternsCatalog } from "../../actions/catalogs";
import { handleRequestError } from "../../../utils/helpers";
import ISelectBuilder from "../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";
import IMultipleCheckBuilder from "../../../toolkit/baseForms2/iMultipleCheckBuilder/IMultipleCheckBuilder";

interface Props extends InvitadosDispatcher, InvitadosStore, RouterProps<any> {
  detalle?: InvitadoDetalle;
}
interface State {
  idInvitado: any;
  initValues: Invitado;
  persona: Person;
  tutor: Person;
  clubes: any[];
  tipo: InvitadoTipo;
  schedulePatterns: any[];
  menorEdad: boolean;
}

@Router()
export default class InvitadosComponent extends Component<Props, State> {
  state = {
    idInvitado: null as any,
    initValues: null as Invitado,
    persona: null as Person,
    tutor: null as Person,
    clubes: [],
    tipo: null as InvitadoTipo,
    schedulePatterns: [],
    menorEdad: false
  };

  async componentDidMount(): Promise<void> {
    // Clubes asignables (reutilización de empresas de convenio)
    this.props.getClubesAsignables();

    // Patrones de acceso (reutilización de configuración de mantenimiento)
    getSchedulePatternsCatalog()
      .then(({ data }) => {
        this.setState({ schedulePatterns: data });
      })
      .catch(handleRequestError);

    // Si se incluye el detalle del invitado, está en modo de edición
    if (this.props.detalle) {
      // Si existe un tutor, se especifica en el selector
      const tutor = this.props.detalle.idPersonaTutor
        ? {
            personaId: this.props.detalle.idPersonaTutor,
            nombre: this.props.detalle.tutorNombreCompleto,
            primerApellido: "",
            segundoApellido: ""
          }
        : null;

      // Carga de datos para edición
      this.setState({
        idInvitado: this.props.detalle.idInvitado,
        initValues: mapInvitado(this.props.detalle),
        clubes: this.props.detalle.clubes,
        tipo: InvitadoTipo[this.props.detalle.tipo],
        menorEdad: this.props.detalle.edad < 18,
        tutor
      });
    }
  }

  /**
   * Selección de la persona invitada, solo para creación de nuevos invitados
   * @param persona Persona seleccionada en PersonSelector (reutilizado)
   */
  onSelectPersona = (persona: Person) => {
    if (!persona || !persona.fechaNacimiento) {
      errorNotification(
        "Esta persona no tiene configurada su fecha de nacimiento"
      );
      return;
    }

    this.setState({
      persona,
      menorEdad: this.edad(persona.fechaNacimiento) < 18
    });
  };

  /**
   * Selección del tutor, cuando el invitado es menor de edad
   * @param persona Persona seleccionada en PersonSelector (reutilizado)
   */
  onSelectTutor = (tutor: Person) => {
    if (!tutor || !tutor.fechaNacimiento) {
      errorNotification("El tutor no tiene configurada su fecha de nacimiento");
      return;
    }

    if (this.edad(tutor.fechaNacimiento) < 18) {
      errorNotification("El tutor no puede ser menor de edad");
      return;
    }

    this.setState({ tutor });
  };

  /**
   * Cálculo de la edad
   * @param isoDate Fecha sin hora, en formato ISO 8601
   * @returns
   */
  edad = (isoDate: string): number => {
    const millis = Date.now() - new Date(isoDate).getTime();
    const sinceDate = new Date(millis);
    return Math.abs(sinceDate.getUTCFullYear() - 1970);
  };

  /**
   * Cambio de los datos del formulario, para mostrar/ocultar campos
   * @param values Datos del formulario
   */
  onChange = (values: any) => {
    this.setState({
      tipo: InvitadoTipo[values.tipo]
    });
  };

  /**
   * Submit del formulario
   * @param values Datos del formulario válidados por las reglas de formConfig
   */
  submit = async (values: any) => {
    if (!this.state.idInvitado && !this.state.persona) {
      errorNotification("Debe seleccionar a la persona invitada");
      return;
    }

    if (this.state.menorEdad && !this.state.tutor) {
      errorNotification("Debe seleccionar al tutor del menor de edad");
      return;
    }

    const idInvitado = this.state.idInvitado;
    const idPersona = this.state.persona
      ? this.state.persona.personaId
      : this.props.detalle.idPersona;
    const invitado: Invitado = {
      idPersona,
      tipo: values.tipo,
      idUserColaborador: values.idUserColaborador,
      idEmpresa: values.idEmpresa || null,
      tipoIntercambio: values.tipoIntercambio || null,
      idPatronHorario: values.idPatronHorario,
      fechaInicioVigencia: values.fechaInicioVigencia,
      fechaFinVigencia: values.fechaFinVigencia,
      observaciones: values.observaciones,
      idPersonaTutor: this.state.tutor
        ? this.state.tutor.personaId
        : this.props.detalle && this.props.detalle.idPersonaTutor,
      clubes: values.clubes,

      // TODO: Revisar si se puede omitir la documentación
      omitirDocumentacion: this.props.detalle
        ? this.props.detalle.omitirDocumentacion
        : false
    };

    if (!idInvitado) {
      const preview = await this.props.postInvitado(invitado);

      if (!preview.fulfilled) {
        errorNotification(
          preview.creatingInvitadoError ||
            "Error al guardar los datos del invitado"
        );
        return;
      }

      this.props.history.push("/invitados-especiales");
    } else {
      const preview = await this.props.putInvitado(idInvitado, invitado);

      if (!preview.fulfilled) {
        errorNotification(
          preview.updatingInvitadoError ||
            "Error al actualizar los datos del invitado"
        );
        return;
      }

      successNotification("Invitado actualizado correctamente");
      this.props.getInvitado(idInvitado);
    }
  };

  render() {
    return (
      <Container>
        <FormBuilder<Inputs, Buttons>
          config={formConfig}
          submit={this.submit}
          init={(this.state.initValues as unknown) as any}
          loading={this.props.gettingInvitado}
          onChange={this.onChange}
          processing={
            this.props.creatingInvitado || this.props.updatingInvitado
          }
        >
          {/* Configuración de los componentes del formulario */}
          <ISelectBuilder
            name="idPatronHorario"
            data={this.state.schedulePatterns}
            mapOption={item => ({ value: item.patronId, label: item.nombre })}
          />
          <IMultipleCheckBuilder
            name="clubes"
            data={this.props.clubesAsignables}
            mapOption={item => ({ value: item.id, label: item.nombre })}
            addSelectAll={true}
          />

          {/* Titulo de división, en modo de inserción */}
          <div
            hidden={!!this.props.detalle}
            data-form-name="invitadoTitle"
            className="col-12"
          >
            <p className="primaryTitle w-100">Datos del invitado</p>
          </div>

          {/* Selectores de personas requeridos */}
          {this.renderTutorSelector()}
          {this.renderPersonaSelector()}
        </FormBuilder>
      </Container>
    );
  }

  renderPersonaSelector = () => {
    // No se puede cambiar la persona almacenada
    if (this.state.idInvitado) {
      return <></>;
    }

    return (
      <div data-form-name="persona" className="col-12">
        <PersonSelector
          label={"* Persona"}
          setPerson={this.onSelectPersona}
          canEdit={true}
          cKey={"TNYOID6Q5C59"}
        />
      </div>
    );
  };

  renderTutorSelector = () => {
    // No se puede cambiar la persona almacenada
    if (!this.state.menorEdad || !this.state.tipo) {
      return <></>;
    }

    return (
      <div data-form-name="tutorPersona" className="col-md-4">
        <PersonSelector
          label={"* Tutor"}
          setPerson={this.onSelectTutor}
          canEdit={true}
          cKey={"TNYOID6Q5C60"}
          initPerson={this.state.tutor}
        />
      </div>
    );
  };
}
