import FormBuilderConfig from "../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IButtonBuilder from "../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import ITextBuilder from "../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";
import IClubSelectorBuilder from "../../../toolkit/baseForms2Commons/iClubSelectorBuilder/IClubSelectorBuilder";
import IUserSelectorBuilder from "../../../toolkit/baseForms2Commons/iUserSelectorBuilder/IUserSelectorBuilder";

export type Inputs = "club" | "instructor" | "folioFactura";
export type Buttons = "buscar" | "generar";
export const formConfig: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "club",
      label: "Club",
      bts: {
        sCol: 3
      },
      component: IClubSelectorBuilder,
      props: {
        useSessionClub: true
      }
    },
    {
      name: "folioFactura",
      label: "Factura",
      bts: {
        sCol: 3
      },
      component: ITextBuilder
    },
    {
      name: "instructor",
      label: "Instructor",
      bts: {
        sCol: 6
      },
      component: IUserSelectorBuilder,
      props: {
        includeSystemUsers: false
      }
    }
  ],
  buttons: [
    {
      name: "buscar",
      label: "Buscar",
      isSubmit: true,
      component: IButtonBuilder,
      bts: {
        sCol: 3,
        sOff: 6
      }
    },
    {
      name: "generar",
      label: "Generar",
      bts: {
        sCol: 3
      }
    }
  ],
  rows: [["club", "folioFactura", "instructor", "buscar", "generar"]]
};
