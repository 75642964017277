import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";
import FileDownload from "js-file-download";
const BASE_URL = "/api";
import isNil from "lodash.isnil";

export const getDealers = (clubId: string, name: string): IAction => {
  let clubIdSend = clubId.length == 0 ? "null" : clubId;
  let nameSend = name.length == 0 ? "null" : name;
  return {
    type: actionTypes.GET_DEALERS_LIST,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${BASE_URL}/concesionarios/por-nombre-club/${clubIdSend}/${nameSend}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getDealersCharges = (
  concesionarioId: string,
  clubId: string
): IAction => {
  return {
    type: actionTypes.GET_DEALERS_CHARGES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${BASE_URL}/concesionarios/configuracion/cargos/${concesionarioId}/${clubId}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const deleteDealer = (personaId: string, clubId: string): IAction => {
  return {
    type: actionTypes.DELETE_DEALER,
    payload: {
      promise: axios.delete(
        `${constants.BASE_URL}${BASE_URL}/concesionarios/cargos-concesionarios/${personaId}/${clubId}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const deleteChargeDealer = (cargoId: string): IAction => {
  return {
    type: actionTypes.DELETE_CHARGE_DEALER,
    payload: {
      promise: axios.delete(
        `${constants.BASE_URL}${BASE_URL}/concesionarios/cargos/configuracion/${cargoId}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const validateChargeDealer = (cargoId: string) => {
  return axios.put(
    `${constants.BASE_URL}${BASE_URL}/concesionarios/cargos/configuracion/${cargoId}/validar`,
    null,
    {
      withCredentials: true
    }
  );
};

export const getSelectedDealerCharge = (cargoId: string): IAction => {
  return {
    type: actionTypes.GET_SELECTED_DEALER_CHARGE,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${BASE_URL}/concesionarios/cargos/configuracion/${cargoId}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const acceptCharge = (cargoConcecionarioId: string): IAction => {
  return {
    type: actionTypes.ACCEPT_CHARGE,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${BASE_URL}/concesionarios/cargos-concesionarios/${cargoConcecionarioId}/aplicar`,
        {},
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getMovements = (
  fechaInicio: string,
  fechaFin: string,
  body: object
): IAction => {
  let clubId =
    body.hasOwnProperty("idClub") && body.idClub.length == 0
      ? "null"
      : body.idClub;
  let estatus =
    body.hasOwnProperty("estatus") && body.estatus.length == 0
      ? "null"
      : body.estatus;
  let nombre =
    body.hasOwnProperty("nombre") && body.nombre.length == 0
      ? "null"
      : body.nombre;
  let size = body.hasOwnProperty("size") && body.size == 0 ? "null" : body.size;
  return {
    type: actionTypes.GET_DEALERS_MOVEMENTS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${BASE_URL}/concesionarios/movimientos/${fechaInicio}/${fechaFin}/${clubId}/${estatus}/${nombre}/${size}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getDealersStatusReport = (
  tipoReporte: string,
  clubId: string,
  concesionarioId: string,
  fechaHasta: string
): IAction => {
  let reporte = tipoReporte.length == 0 ? "null" : tipoReporte;
  let club = clubId.length == 0 ? "null" : clubId;
  let concesionario = concesionarioId.length == 0 ? "null" : concesionarioId;
  let fecha = isNil(fechaHasta) ? "null" : fechaHasta;
  return {
    type: actionTypes.GET_DEALERS_STATUS_REPORT,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${BASE_URL}/concesionarios/cargos-concesionarios/estado-cuenta/${reporte}/${club}/${concesionario}/${fecha}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const clearDealers = () => {
  return {
    type: actionTypes.CLEAR_DEALERS_REDUX,
    payload: {}
  };
};

export const downloadMovementStatus = (
  fechaInicio: string,
  fechaFin: string,
  body: object
): IAction => {
  let clubId =
    body.hasOwnProperty("idClub") && body.idClub.length == 0
      ? "null"
      : body.idClub;
  let estatus =
    body.hasOwnProperty("estatus") && body.estatus.length == 0
      ? "null"
      : body.estatus;
  let nombre =
    body.hasOwnProperty("nombre") && body.nombre.length == 0
      ? "null"
      : body.nombre;
  return {
    type: actionTypes.GET_FILE_MOVEMENT_STATUS,
    payload: {
      promise: axios
        .get(
          `${constants.BASE_URL}${BASE_URL}/concesionarios/movimientos/descargar/${fechaInicio}/${fechaFin}/${clubId}/${estatus}/${nombre}`,
          {
            withCredentials: true,
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          FileDownload(data, "ESTATUS-MOVIMIENTOS.csv");
        })
    }
  };
};

export const downloadPDFDealersStatusReport = (
  tipoReporte: string,
  clubId: string,
  concesionarioId: string,
  fechaHasta: string
): IAction => {
  let reporte = tipoReporte.length == 0 ? "null" : tipoReporte;
  let club = clubId.length == 0 ? "null" : clubId;
  let concesionario = concesionarioId.length == 0 ? "null" : concesionarioId;
  let fecha = isNil(fechaHasta) ? "null" : fechaHasta;
  return {
    type: actionTypes.DOWNLOAD_PDF_DEALERS_STATUS,
    payload: {
      promise: axios
        .post(
          `${constants.BASE_URL}${BASE_URL}/concesionarios/estado-cuenta/pdf/${reporte}/${concesionario}/${club}/${fecha}`,
          {},
          {
            withCredentials: true,
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          FileDownload(data, "estado-cuenta.pdf");
        })
    }
  };
};

export const downloadCSVDealersStatusReport = (
  tipoReporte: string,
  clubId: string,
  concesionarioId: string,
  fechaHasta: string
): IAction => {
  let reporte = tipoReporte.length == 0 ? "null" : tipoReporte;
  let club = clubId.length == 0 ? "null" : clubId;
  let concesionario = concesionarioId.length == 0 ? "null" : concesionarioId;
  let fecha = isNil(fechaHasta) ? "null" : fechaHasta;
  return {
    type: actionTypes.DOWNLOAD_CSV_DEALERS_STATUS,
    payload: {
      promise: axios
        .get(
          `${constants.BASE_URL}${BASE_URL}/concesionarios/estado-cuenta/csv/${reporte}/${concesionario}/${club}/${fecha}`,
          {
            withCredentials: true,
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          FileDownload(data, "estado-cuenta.csv");
        })
    }
  };
};
