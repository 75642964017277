/**
 * TODO: Por trabajos de construcción en msfinanzas y mspac no fue factible
 * cargar este catálogo en la base de datos.
 */

export const REGIMENES_RECEPTOR_CFDI40 = [
  {
    value: 601,
    label: "601 - General de Ley Personas Morales",
    usos: ["G01", "G02", "G03", "S01"],
    fisica: false,
    moral: true
  },
  {
    value: 603,
    label: "603 - Personas Morales con Fines no Lucrativos",
    usos: ["G01", "G02", "G03", "S01"],
    fisica: false,
    moral: true
  },
  {
    value: 605,
    label: "605 - Sueldos y Salarios e Ingresos Asimilados a Salarios",
    usos: ["S01"],
    fisica: true,
    moral: false
  },
  {
    value: 606,
    label: "606 - Arrendamiento",
    usos: ["G01", "G02", "G03", "S01"],
    fisica: true,
    moral: false
  },
  {
    value: 607,
    label: "607 - Régimen de Enajenación o Adquisición de Bienes",
    usos: ["S01"],
    fisica: true,
    moral: false
  },
  {
    value: 608,
    label: "608 - Demás ingresos",
    usos: ["S01"],
    fisica: true,
    moral: false
  },
  {
    value: 610,
    label:
      "610 - Residentes en el Extranjero sin Establecimiento Permanente en México",
    usos: ["S01"],
    fisica: true,
    moral: true
  },
  {
    value: 611,
    label: "611 - Ingresos por Dividendos (socios y accionistas)",
    usos: ["S01"],
    fisica: true,
    moral: false
  },
  {
    value: 612,
    label:
      "612 - Personas Físicas con Actividades Empresariales y Profesionales",
    usos: ["G01", "G02", "G03", "S01"],
    fisica: true,
    moral: false
  },
  {
    value: 614,
    label: "614 - Ingresos por intereses",
    usos: ["S01"],
    fisica: true,
    moral: false
  },
  {
    value: 615,
    label: "615 - Régimen de los ingresos por obtención de premios",
    usos: ["S01"],
    fisica: true,
    moral: false
  },
  {
    value: 616,
    label: "616 - Sin obligaciones fiscales",
    usos: ["S01"],
    fisica: true,
    moral: true
  },
  {
    value: 620,
    label:
      "620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
    usos: ["G01", "G02", "G03", "S01"],
    fisica: false,
    moral: true
  },
  {
    value: 621,
    label: "621 - Incorporación Fiscal",
    usos: ["G01", "G02", "G03", "S01"],
    fisica: true,
    moral: false
  },
  {
    value: 622,
    label: "622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
    usos: ["G01", "G02", "G03", "S01"],
    fisica: false,
    moral: true
  },
  {
    value: 623,
    label: "623 - Opcional para Grupos de Sociedades",
    usos: ["G01", "G02", "G03", "S01"],
    fisica: false,
    moral: true
  },
  {
    value: 624,
    label: "624 - Coordinados",
    usos: ["G01", "G02", "G03", "S01"],
    fisica: false,
    moral: true
  },
  {
    value: 625,
    label:
      "625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
    usos: ["G01", "G02", "G03", "S01"],
    fisica: true,
    moral: false
  },
  {
    value: 626,
    label: "626 - Régimen Simplificado de Confianza",
    usos: ["G01", "G02", "G03", "S01"],
    fisica: true,
    moral: true
  }
];

export const USO_CFDI40 = [
  { value: "G01", label: "G01 - Adquisición de mercancías." },
  { value: "G02", label: "G02 - Devoluciones, descuentos o bonificaciones." },
  { value: "G03", label: "G03 - Gastos en general." },
  { value: "S01", label: "S01 - Sin efectos fiscales." }
];
