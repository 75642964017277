import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import * as catalogActions from "../../actions/catalogs";
import Layout from "../../components/layout";
import PaymentLinesReport from "../../components/paymentLinesReport";
import { showDocument } from "../../actions/documents";

interface Props {
  getClubs: () => void;
  toggleBill: (storageId: number) => any;
  clubs: object[];
}

interface State {}

class PaymentLinesPage extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    this.props.getClubs();
  }

  render() {
    return (
      <main>
        <Helmet title="Payment Lines Report" />
        <Layout>
          <PaymentLinesReport
            clubs={this.props.clubs}
            toggleBill={this.props.toggleBill}
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({ catalogs }) => {
  return {
    clubs: catalogs.clubs
  };
};

const mapDispatchToProps = dispatch => ({
  toggleBill: showDocument,
  getClubs: () => {
    dispatch(catalogActions.getClubs());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentLinesPage);
