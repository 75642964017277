import React from "react";
import BaseInput from "../../../toolkit/baseInput";

export const MembershipSelector = ({
  value,
  disabled,
  onChange,
  memberships,
  size = "sm"
}) => (
  <BaseInput
    label="Membresía"
    name="membresiaSelector"
    type="select"
    id="membresiaSelector"
    placeholder="Membresía"
    value={value}
    size={size}
    disabled={disabled}
    onChange={e => {
      const value = e.target.value;
      return onChange(value);
    }}
    options={[{ value: "", label: "Sin Membresía" }].concat(
      memberships
        ? memberships.map(option => ({
            value: option.datosBasicosMembresiaId,
            label: option.nombre
          }))
        : []
    )}
  />
);
