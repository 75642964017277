import React from "react";
import Layout from "../../components/layout";
import { Helmet } from "react-helmet";
import OneDayPassActivatorPage from "../../components/oneDayPassActivatorPage";
import { getClubs } from "../../actions/catalogs";
import {
  createCodes,
  clearCodes,
  getPassDayList,
  activateDayPass
} from "../../actions/dicounts";
import { DayPass } from "../../components/oneDayPassActivatorPage/interfaces";
import { clearSelectedPerson } from "../../actions/persons";
import { ReduxConnect } from "../../../toolkit/decorators/Artifact";
import { RequestArchivo } from "../../components/expedientsV2controls/types";
import { postRefresh, getArchivoVigente } from "../../actions/expedientes";
import { Preview } from "../../../types/IAction";

const mapDispatchToProps = dispatch => ({
  getPassDayList: idPersona => {
    return dispatch(getPassDayList(idPersona));
  },
  clearCodes: () => {
    return dispatch(clearCodes());
  },
  createCodes: data => {
    return dispatch(createCodes(data));
  },
  getClubs: () => {
    return dispatch(getClubs());
  },
  activateDayPass: (dayPass: DayPass) => {
    return dispatch(activateDayPass(dayPass));
  },
  clearSelectedPerson: () => {
    return dispatch(clearSelectedPerson());
  },
  postRefresh: (requestArchivo: RequestArchivo): Preview<any> => {
    return dispatch(postRefresh(requestArchivo));
  },
  getArchivoVigente: (idExpediente, idDocumento): Preview<any> => {
    return dispatch(getArchivoVigente(idExpediente, idDocumento));
  }
});

const mapStateToProps = state => {
  const {
    catalogs: { clubs },
    discounts
  } = state;
  return {
    clubs,
    discounts
  };
};

interface Props {
  clubs: object;
  getClubs: () => any;
  createCodes: (data: object) => any;
  clearCodes: () => any;
  discounts: object;
  selectedPerson: object;
  getPassDayList: (idPersona: number) => any;
  activateDayPass: (dayPass: DayPass) => any;
  clearSelectedPerson: () => any;
  postRefresh: (requestArchivo: RequestArchivo) => Preview<any>;
  getArchivoVigente: (idExpediente, idDocumento) => Preview<any>;
}

interface State {}

@ReduxConnect(mapStateToProps, mapDispatchToProps)
export default class OneDayPassActivator extends React.Component<Props, State> {
  state = {};
  componentDidMount() {
    this.props.getClubs();
  }

  render() {
    return (
      <main>
        <Helmet title="Activación de pase por un día" />
        <Layout>
          {this.props.clubs && (
            <OneDayPassActivatorPage
              discounts={this.props.discounts}
              getPassDayList={this.props.getPassDayList}
              activateDayPass={this.props.activateDayPass}
              clearCodes={this.props.clearCodes}
              clearSelectedPerson={this.props.clearSelectedPerson}
              clubs={this.props.clubs}
              postRefresh={this.props.postRefresh}
              getArchivoVigente={this.props.getArchivoVigente}
            />
          )}
        </Layout>
      </main>
    );
  }
}
