import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import ReactivacionesReportePage from "../../components/reactivacionesReporte";

interface Props {}
interface State {}

export default class ReactivacionesReporte extends React.Component<
  Props,
  State
> {
  render() {
    return (
      <main>
        <Helmet title="Reporte de reactivaciones" />
        <Layout>
          <ReactivacionesReportePage />
        </Layout>
      </main>
    );
  }
}
