import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import * as sessionActions from "../Home/actions/session";

class PublicRoute extends Route<any> {
  state = {
    loading: true,
    success: false
  };

  componentDidMount() {
    this.setState({ success: false, loading: false });
  }

  render() {
    const { component: Component, client, ...rest } = this.props;

    if (this.state.loading) {
      return <div />;
    } else {
      return <Route {...rest} render={props => <Component {...props} />} />;
    }
  }
}

const mapStateToProps = state => {
  const { user } = state;
  return {
    userContext: user.userContext
  };
};

const mapDispatchToProps = dispatch => ({
  setUserContext: (userContext = null) => {
    dispatch(sessionActions.setUserContext(userContext));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicRoute);
