import FormBuilderConfig from "../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IButtonBuilder from "../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import IDateBuilder from "../../../toolkit/baseForms2/iDateBuilder/IDateBuilder";
import IMultipleCheckBuilder from "../../../toolkit/baseForms2/iMultipleCheckBuilder/IMultipleCheckBuilder";
import ISelectBuilder from "../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";
import ITextBuilder from "../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";
import IEmpresaConvenioSelectorBuilder from "../../../toolkit/baseForms2Commons/iEmpresaConvenioSelectorBuilder/IEmpresaConvenioBuilder";
import IUserSelectorBuilder from "../../../toolkit/baseForms2Commons/iUserSelectorBuilder/IUserSelectorBuilder";
import { getOptionsFromEnum } from "../../../toolkit/utils/enum-utils";
import { requiredClubsIfNotIntercambioComercial } from "./invitados.validator";
import {
  InvitadoIntercambioComercialTipo,
  InvitadoTipo
} from "../../../types/Invitado";
import {
  hiddenIfIntercambioComercial,
  hiddenIfNotAutorizacionDirecta,
  hiddenIfNotFamiliar,
  hiddenIfNotIntercambioComercial,
  requiredIfAutorizacionDirecta,
  requiredIfFamiliar,
  requiredIfIntercambioComercial
} from "./invitados.validator";

export type Inputs =
  | "persona"
  | "tutorPersona"
  | "invitadoTitle"
  | "tipo"
  | "idUserColaborador"
  | "idEmpresa"
  | "tipoIntercambio"
  | "idPatronHorario"
  | "fechaInicioVigencia"
  | "fechaFinVigencia"
  | "observaciones"
  | "clubes";

export type Buttons = "guardar";
export const formConfig: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "tipo",
      label: "* Tipo",
      bts: {
        mCol: 4
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Todos",
        data: getOptionsFromEnum(InvitadoTipo)
      }
    },
    {
      name: "idUserColaborador",
      label: "* Colaborador",
      bts: {
        mCol: 4
      },
      component: IUserSelectorBuilder,
      props: {
        hidden: hiddenIfNotFamiliar
      }
    },
    {
      name: "idEmpresa",
      label: "* Empresa",
      bts: {
        mCol: 4
      },
      component: IEmpresaConvenioSelectorBuilder,
      props: {
        todas: true,
        ignorarConvenio: true,
        hidden: hiddenIfNotIntercambioComercial
      }
    },
    {
      name: "tipoIntercambio",
      label: "* Tipo de Intercambio",
      bts: {
        mCol: 4
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Todos",
        data: getOptionsFromEnum(InvitadoIntercambioComercialTipo),
        hidden: hiddenIfNotIntercambioComercial
      }
    },
    {
      name: "idPatronHorario",
      label: "Patrón de acceso",
      bts: {
        mCol: 4
      },
      component: ISelectBuilder,
      props: {
        hidden: hiddenIfNotAutorizacionDirecta
      }
    },
    {
      name: "fechaInicioVigencia",
      label: "* Inicio de Vigencia",
      bts: {
        mCol: 4
      },
      component: IDateBuilder,
      props: {
        hidden: hiddenIfNotAutorizacionDirecta
      }
    },
    {
      name: "fechaFinVigencia",
      label: "* Fin de Vigencia",
      bts: {
        mCol: 4
      },
      component: IDateBuilder,
      props: {
        hidden: hiddenIfNotAutorizacionDirecta
      }
    },
    {
      name: "observaciones",
      label: "* Indique por qué se registró",
      bts: {
        mCol: 8
      },
      component: ITextBuilder,
      props: {
        hidden: hiddenIfNotAutorizacionDirecta
      }
    },
    {
      name: "tutorPersona",
      label: "Tutor",
      bts: {
        mCol: 4
      }
    },
    {
      name: "clubes",
      label: "Clubes permitidos para acceso",
      bts: {
        xCol: 12
      },
      component: IMultipleCheckBuilder,
      props: {
        hidden: hiddenIfIntercambioComercial
      }
    }
  ],
  buttons: [
    {
      name: "guardar",
      label: "Guardar",
      isSubmit: true,
      bts: {
        mOff: 9,
        mCol: 3
      },
      component: IButtonBuilder
    }
  ],
  validations: {
    tipo: {
      requiredCustom: {
        validator: value => !value,
        message: () => "Campo obligatorio"
      }
    },
    idUserColaborador: {
      requiredIfFamiliar
    },
    idEmpresa: {
      requiredIfIntercambioComercial
    },
    tipoIntercambio: {
      requiredIfIntercambioComercial
    },
    idPatronHorario: {
      requiredIfAutorizacionDirecta
    },
    fechaInicioVigencia: {
      requiredIfAutorizacionDirecta
    },
    fechaFinVigencia: {
      requiredIfAutorizacionDirecta
    },
    observaciones: {
      requiredIfAutorizacionDirecta
    },
    clubes: {
      requiredClubsIfNotAutorizacionDirecta: requiredClubsIfNotIntercambioComercial
    }
  },
  rows: [
    ["persona"],
    ["invitadoTitle"],
    [
      "tipo",
      "idUserColaborador",
      "idEmpresa",
      "tipoIntercambio",
      "fechaInicioVigencia",
      "fechaFinVigencia",
      "idPatronHorario",
      "observaciones",
      "tutorPersona"
    ],
    ["clubes"],
    ["guardar"]
  ]
};
