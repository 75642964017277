import withStyles from "isomorphic-style-loader/lib/withStyles";
import concat from "lodash.concat";
import forEach from "lodash.foreach";
import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import { DayOfWeek, ScheduleTable } from "../ScheduleTable";
import s from "./styles.scss";

export interface Schedule {
  nombre: string;
  patronId: number;
  horarios: Array<ScheduleDay>;
  activo: boolean;
}

interface Props {
  patterns: Array<Schedule>;
  storePattern: (schedule: Schedule) => any;
  getPatternById: (patternId: number) => any;
}

interface State {}

export interface ScheduleDay {
  activo: boolean;
  diaSemana: DayOfWeek;
  entrada: string;
  salida: string;
}

const InitialTimeObject = "05:30:00";
const FinalTimeObject = "23:00:00";

const InitialScheduleObject = {
  activo: false,
  entrada: InitialTimeObject,
  salida: FinalTimeObject
};

const getEmptyFields = () => ({
  [DayOfWeek.Monday]: { ...InitialScheduleObject },
  [DayOfWeek.Tuesday]: { ...InitialScheduleObject },
  [DayOfWeek.Wednesday]: { ...InitialScheduleObject },
  [DayOfWeek.Thursday]: { ...InitialScheduleObject },
  [DayOfWeek.Friday]: { ...InitialScheduleObject },
  [DayOfWeek.Saturday]: { ...InitialScheduleObject },
  [DayOfWeek.Sunday]: { ...InitialScheduleObject }
});

const EMPTY_PATTERN_ID = 0;

class SchedulePatternsComponent extends React.Component<Props, State> {
  state = {
    fields: getEmptyFields(),
    nombre: "",
    patronId: null,
    patterns: [
      {
        label: "Crear nuevo patron",
        value: EMPTY_PATTERN_ID
      }
    ]
  };

  componentDidMount() {
    if (this.props.patterns) {
      const { patterns } = this.state;
      const { patterns: patternsStored } = this.props;

      this.setState({
        patterns: concat(
          patterns,
          patternsStored.map(({ nombre, patronId }) => ({
            label: nombre,
            value: patronId
          }))
        )
      });
    }
  }

  onChangeChecked = (day: DayOfWeek) => {
    return event => {
      const value = event.target.checked;
      const fields = {
        ...this.state.fields
      };
      fields[day].activo = value;
      this.setState(state => ({ ...state, fields }));
    };
  };

  onChangeTime = (day: DayOfWeek, field: string) => {
    return (timeObject: any) => {
      const fields = {
        ...this.state.fields
      };
      fields[day][field] = timeObject;
      this.setState({ ...this.state, fields });
    };
  };

  onChangeStartTime = (day: DayOfWeek) => {
    return this.onChangeTime(day, "entrada");
  };

  onChangeEndTime = (day: DayOfWeek) => {
    return this.onChangeTime(day, "salida");
  };

  onChangeName = event => {
    const nombre = event.target.value;

    this.setState({ nombre });
  };

  onChangePattern = event => {
    const patronId = event.target.value;

    if (EMPTY_PATTERN_ID != patronId) {
      this.props.getPatternById(patronId).then(response => {
        const {
          value: { data: pattern }
        } = response;
        if (pattern) {
          const { horarios, nombre: patternName } = pattern;
          const fields = getEmptyFields();
          const nombre = patternName;

          horarios.forEach(({ diaSemana, entrada, salida }) => {
            fields[diaSemana] = {
              activo: true,
              entrada: entrada,
              salida: salida
            };
          });

          this.setState({ nombre, patronId, fields });
        }
      });
    } else {
      this.setState({
        nombre: "",
        patronId: EMPTY_PATTERN_ID,
        fields: getEmptyFields()
      });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    const response: Schedule = {
      nombre: this.state.nombre,
      patronId:
        this.state.patronId != EMPTY_PATTERN_ID ? this.state.patronId : null,
      horarios: [],
      activo: true
    };

    forEach(this.state.fields, (schedule, dia) => {
      const { activo } = schedule;
      if (activo) {
        response.horarios.push({
          activo,
          diaSemana: dia,
          entrada: schedule.entrada,
          salida: schedule.salida
        });
      }
    });

    this.props.storePattern(response).then(response => {
      const {
        value: {
          data: { patronId }
        }
      } = response;

      this.setState({ patternId: patronId });
    });
  };

  render() {
    return (
      <Container className="mt-3 pb-5">
        <Row>
          <Col className="p-0">
            <BaseInput
              label="Seleccione el Patron"
              name="patronSeleccionado"
              type="select"
              id="patronSeleccionado"
              placeholder="Seleccione el Patron"
              value={this.state.patronId}
              errors={[]}
              options={this.state.patterns}
              onChange={this.onChangePattern}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col className="p-0">
            <BaseInput
              label="Nombre Patron"
              name="nombre"
              type="text"
              id="nombre"
              placeholder="Nombre Patron"
              value={this.state.nombre}
              onChange={this.onChangeName}
              errors={[]}
            />
          </Col>
        </Row>
        <Row>
          <Col className="p-0">
            <ScheduleTable
              fields={this.state.fields}
              onChangeChecked={this.onChangeChecked}
              onChangeStartTime={this.onChangeStartTime}
              onChangeEndTime={this.onChangeEndTime}
              activeField="activo"
              startField="entrada"
              endField="salida"
            />
          </Col>
        </Row>
        <Row>
          <Col />
          <Col xs={2} className="float-right pr-0 pt-2">
            <Button
              className={`${s.buttonMarginTop} ${s.primaryButton} float-right`}
              onClick={e => this.handleSubmit(e)}
              size="sm"
              block
              disabled={!this.state.nombre}
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(SchedulePatternsComponent));
