import React from "react";
import s from "./styles.scss";
import { Preview } from "../../../types/IAction";
import {
  Styled,
  ReduxConnect,
  Router,
  RouterProps
} from "../../../toolkit/decorators/Artifact";
import * as actions from "../../actions/expedientes";
import ExpedienteValidationFilters from "./filters";
import { columns } from "./reactTable";
import ReactTable from "react-table";
import ExpedienteVisor from "../expedientsV2visor/index";
import {
  infoNotification,
  successNotification
} from "../../../utils/notifications";

const mapDispatchToProps = dispatch => ({
  /// Dispatchers de Condensados de Expediente
  getCondensados: (filters: any): Promise<Preview> => {
    return dispatch(actions.getCondensados(filters));
  },
  putAutorizar: (idExpediente): Promise<Preview> => {
    return dispatch(actions.putAutorizar(idExpediente));
  }
  ////
});

const mapStateToProps = state => {
  const { expedientes } = state;

  return {
    /// State de Condensados de Expediente
    condensados: expedientes.condensados,
    condensadosLoading: expedientes.condensadosLoading,
    autorizando: expedientes.autorizando,
    userContext: state.user.userContext
    ////
  };
};

interface Props extends RouterProps {
  condensados?: any;
  condensadosLoading?: boolean;
  getCondensados?: (filters: any) => Promise<Preview>;

  autorizando?: boolean;
  putAutorizar?: (idExpediente) => Promise<Preview>;
  userContext?: any;
}

interface State {
  page: number;
  filters: any;
  visor: boolean;
  idMembresiaSocio: string;
}

@Router()
@ReduxConnect(mapStateToProps, mapDispatchToProps)
@Styled(s)
export default class ExpedienteValidatorComponent extends React.Component<
  Props,
  State
> {
  state = {
    page: 0,
    filters: null,
    visor: false,
    idMembresiaSocio: ""
  };

  fetchData = options => {
    if (this.state.filters && this.state.filters.idClub) {
      this.submit(this.state.filters, options.page);
    }
  };

  submit = (filters, pageArg?) => {
    const page = pageArg || 0;
    this.setState({ page, filters });
    this.props.getCondensados({ ...filters, pagina: page });
  };

  goToMembresia = ({ idMembresiaSocio, idClub, idPublico }) => {
    window.open(
      `membresias-socios/${idMembresiaSocio}?idClub=${idClub}&idPublico=${idPublico}`,
      "_blank"
    );
  };

  autorizar = async row => {
    infoNotification("Autorizando el expediente");
    const preview = await this.props.putAutorizar(row.idExpediente);

    if (preview.fulfilled) {
      successNotification("Expediente autorizado correctamente");

      if (this.state.filters.ignorarAut) {
        this.submit(this.state.filters, this.state.page);
      }
    }
  };

  openExpediente = row => {
    this.setState({
      visor: true,
      idMembresiaSocio: row.idMembresiaSocio
    });
  };

  closeExpediente = () => {
    this.setState({ visor: false });

    if (this.state.filters.ignorarAut) {
      this.submit(this.state.filters);
    }
  };

  render() {
    return (
      <>
        {/* Filtros */}
        <ExpedienteValidationFilters
          submit={this.submit}
          userContext={this.props.userContext}
          loading={false}
        />

        {/* Significado de colores */}
        <div style={{ marginTop: "15px", fontSize: "0.8em" }}>
          <span style={{ padding: "4px" }}>Significado de colores:</span>
          <span
            className={s.completo}
            style={{
              padding: "4px",
              border: "1px solid #ddd",
              borderRadius: "7px",
              marginRight: "8px"
            }}
          >
            Listo para autorizar
          </span>
          <span
            className={s.autorizado}
            style={{
              padding: "4px",
              border: "1px solid #ddd",
              borderRadius: "7px"
            }}
          >
            Expediente autorizado
          </span>
        </div>

        {/* Tabla */}
        <div className={s.fixReactTable}>
          <ReactTable
            manual
            data={this.props.condensados.content || []}
            loading={this.props.condensadosLoading}
            pageSize={20}
            pages={this.props.condensados.totalPages || 1}
            page={this.state.page}
            columns={columns(
              this.goToMembresia,
              this.openExpediente,
              this.autorizar
            )}
            sortable={false}
            resizable={true}
            showPagination={true}
            showPageSizeOptions={false}
            getTrProps={() => ({ style: { height: "30px" } })}
            noDataText={"No hay expedientes por mostrar"}
            onFetchData={this.fetchData}
          />
        </div>

        {/* Visor de documentos */}
        {this.renderVisor()}
      </>
    );
  }

  renderVisor = () => {
    if (this.state.visor) {
      return (
        <ExpedienteVisor
          idMembresiaSocio={this.state.idMembresiaSocio}
          onClose={this.closeExpediente}
        />
      );
    } else {
      return <></>;
    }
  };
}
