import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import Reform from "@franleplant/reform";
import { Form, FormGroup, Label, Input, Container } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import BaseInput from "../../../toolkit/baseInput";
import s from "./styles.scss";
import { FormErrors } from "@franleplant/reform/types";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import RichEditor from "../richEditor";
import DatePicker from "../common/DatePicker";
import moment from "moment";
import fill from "lodash.fill";
import findIndex from "lodash.findindex";
import { setChildStep } from "../../helpers/stepper-state-comparator";
import axios from "axios";
import constants from "../../../utils/constants";
import { errorNotification } from "../../../utils/notifications";
import { readMessageBack } from "../../../utils/readMessageBack";

interface MEMBERSHIP {
  datosBasicosMembresiaId: number;
  nombre: string;
}

interface MEMBERSHIP_SAVED {
  membresiaId: number;
}

interface Props {
  onClickSave: (e: object) => any;
  history: any;
  client: any;
  saveBasicAgreement: (agreement: object) => any;
  updateBasicAgreement: (convenioId: string, agreement: object) => any;
  match: any;
  agreement: Agreement;
  getBasicAgreement: (convenioId: string) => any;
  memberships: MEMBERSHIP[];
  users: object[];
  gettingAgreement: boolean;
}

interface Fields {
  nombre: string;
  responsableId: string;
  condicionesLegales: string;
  inicioVigencia: string;
  finVigencia: string;
  numeroMaximo: string;
  empresa: string;
  activo: boolean;
  sinFechaFinDeVigencia: boolean;
  grupoId: any;
}

interface Agreement {
  condicionesLegales: string;
  nombre: string;
  responsableId: string;
  sinFechaFinDeVigencia: boolean;
  inicioVigencia: string;
  finVigencia: string;
  descripcion: string;
  membresias: MEMBERSHIP_SAVED[];
  empresa: string;
  numeroMaximo: string;
  activo: boolean;
  grupoId: number;
}

interface State {
  errors: FormErrors;
  fields: Fields;
  editorState: EditorState;
  membershipsSelected: object;
  grupos: any[];
  grupo: any;
  oldGrupoId: any;
}

const agreementToFields = ({
  nombre,
  responsableId,
  condicionesLegales,
  inicioVigencia,
  finVigencia,
  empresa,
  numeroMaximo,
  activo,
  sinFechaFinDeVigencia,
  descripcion,
  grupoId
}) => {
  return {
    nombre,
    responsableId,
    condicionesLegales,
    inicioVigencia: moment(inicioVigencia).format("YYYY-MM-DD"),
    finVigencia: moment(finVigencia).format("YYYY-MM-DD"),
    empresa,
    numeroMaximo,
    activo,
    sinFechaFinDeVigencia,
    descripcion,
    membresiasAplicables: [],
    grupoId
  };
};

class BasicDataAgreementForm extends React.Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);

  state = {
    fields: {
      nombre: "",
      responsableId: "",
      inicioVigencia: "",
      finVigencia: "",
      condicionesLegales: "",
      numeroMaximo: "",
      empresa: "",
      activo: false,
      sinFechaFinDeVigencia: false,
      descripcion: "",
      membresiasAplicables: null,
      grupoId: ""
    },
    editorState: EditorState.createEmpty(),
    errors: {},
    membershipsSelected: null,
    grupos: [],
    grupo: null,
    oldGrupoId: null
  };

  setInitialState = () => {
    const { id } = this.props.match.params;
    if (id && (!this.props.gettingAgreement && !this.props.agreement)) {
      this.props.getBasicAgreement(id);
    }

    if (!this.state.membershipsSelected) {
      this.setState({
        membershipsSelected: fill(Array(this.props.memberships.length), false)
      });
    }

    const { agreement: propsAgreement } = this.props;

    if (propsAgreement) {
      const fields = agreementToFields(propsAgreement);
      setChildStep(fields);
      this.setState(state => ({
        ...state,
        fields,
        editorState: EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(propsAgreement.descripcion).contentBlocks
          )
        ),
        oldGrupoId: propsAgreement.grupoId
      }));

      if (
        propsAgreement.finVigencia &&
        this.state.fields.sinFechaFinDeVigencia
      ) {
        this.setState(state => {
          const fields = {
            ...state.fields,
            finVigencia: ""
          };
          setChildStep(fields);
          return {
            ...state,
            fields
          };
        });
      }
    }
  };

  async componentDidMount() {
    this.setInitialState();

    // TODO: REDUX - Rev 1
    try {
      let response = await axios.get(
        `${constants.BASE_URL}/api/agreement-groups/actives`,
        {
          withCredentials: true
        }
      );

      this.setState({
        grupos: response.data,
        grupo: (response.data as any[]).find(
          g => g.grupoId == this.state.fields.grupoId
        )
      });
    } catch (error) {
      errorNotification(
        readMessageBack(
          error,
          "No se pudieron consultar los grupos de convenio"
        )
      );
    }

    this.props.onClickSave(e => {
      e.preventDefault();

      if (this.re.validateFormFromState()) {
        const {
          inicioVigencia,
          finVigencia,
          numeroMaximo,
          responsableId
        } = this.state.fields;
        const agreement = {
          ...this.state.fields,
          descripcion: draftToHtml(
            convertToRaw(this.state.editorState.getCurrentContent())
          ),
          responsableId: parseInt(responsableId, 10),
          numeroMaximo: parseInt(numeroMaximo, 10),
          inicioVigencia: moment(inicioVigencia),
          finVigencia: finVigencia
            ? moment(finVigencia)
            : moment(inicioVigencia).add(100, "years"),
          membresias: this.props.memberships
            .filter((_, index) => this.state.membershipsSelected[index])
            .map(({ datosBasicosMembresiaId: membresiaId }) => ({
              membresiaId
            }))
        };

        delete agreement.membresiasAplicables;
        const { id } = this.props.match.params;

        if (!id) {
          this.props
            .saveBasicAgreement(agreement)
            .then(({ value: { data } }) => {
              this.props.history.push(
                `/convenios/datos-basicos/${data.convenioId}`
              );
            });
        } else {
          this.props.updateBasicAgreement(id, agreement);
        }
      }
    });
  }

  processed = false;

  optionsResponsible = users => {
    const response = [];
    response.push({ value: "", label: "Seleccionar Responsable" });
    users.forEach(user => {
      if (user.name) {
        response.push({
          label: user.name,
          value: user.userId
        });
      }
    });

    return response;
  };

  componentDidUpdate() {
    const { agreement: propsAgreement } = this.props;

    const { memberships } = this.props;

    if (
      memberships.length &&
      propsAgreement &&
      propsAgreement.membresias &&
      propsAgreement.membresias.length &&
      !this.processed
    ) {
      const { membresias: appliedMemberships } = propsAgreement;
      const { id } = this.props.match.params;
      this.setState({
        membershipsSelected: id
          ? memberships.map(
              ({ datosBasicosMembresiaId }) =>
                findIndex(
                  appliedMemberships,
                  m => datosBasicosMembresiaId === m.membresiaId
                ) > -1
            )
          : []
      });
      this.processed = true;
    }
  }

  dateValidator = (dateInput, inputName) => {
    const { id } = this.props.match.params;
    if (id && this.props.agreement[`${inputName}`] === dateInput) {
      return false;
    }
    const date = moment(dateInput).startOf("day");
    const now = moment().startOf("day");
    return date.isBefore(now);
  };

  bothDatesValidator = (dateInput, otherInput) => {
    const date = new Date(dateInput);
    const other = new Date(otherInput);
    return dateInput && otherInput && date >= other;
  };

  validationRules = {
    inicioVigencia: {
      required: true,
      dateMustBeFuture: value => this.dateValidator(value, "inicioVigencia"),
      bothDates: value =>
        this.bothDatesValidator(value, this.state.fields.finVigencia)
    },
    finVigencia: {
      dateMustBeFuture: value => this.dateValidator(value, "finVigencia"),
      bothDates: value =>
        this.bothDatesValidator(this.state.fields.inicioVigencia, value),
      required: value => !this.state.fields.sinFechaFinDeVigencia && !value
    },
    nombre: { required: true },
    descripcion: { required: true },
    condicionesLegales: { required: true },
    numeroMaximo: { required: true },
    empresa: { required: true },
    responsableId: { required: true },
    membresiasAplicables: {
      required: _ => {
        const applyGroup = this.state.oldGrupoId == this.state.fields.grupoId;
        const selected =
          this.props.memberships
            .filter((_, index) => this.state.membershipsSelected[index])
            .map(({ datosBasicosMembresiaId: membresiaId }) => ({
              membresiaId
            })).length === 0;

        return applyGroup && selected;
      }
    }
  };

  validationMessages = {
    required: _ => `Obligatorio`,
    dateMustBeFuture: _ => "La fecha debe ser futura",
    bothDates: _ => "La fecha inicial debe ser menor a la final",
    minLength: ruleValue => `Debe tener al menos ${ruleValue} caracteres`,
    maxLength: ruleValue => `Deber tener maximo ${ruleValue} caracteres`,
    default: _ => `Inválido`
  };

  onChangeFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };

        setChildStep(fields);

        return { ...state, fields, errors: {} };
      });
      this.re.validateField(fieldName, value);
    };
  };

  onChangeFecha = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: date
        };
        setChildStep(fields);
        return { ...state, error: {}, fields };
      });
      this.re.validateField(fieldName, date);
    };
  };

  checkDisableDate = e => {
    const sinFechaFinDeVigencia = e.target.checked;
    const fields = {
      ...this.state.fields,
      sinFechaFinDeVigencia,
      finVigencia: ""
    };
    setChildStep(fields);
    this.setState(state => ({
      ...state,
      fields
    }));
  };

  checkActive = e => {
    const activo = e.target.checked;
    const fields = { ...this.state.fields, activo };
    setChildStep(fields);
    this.setState(() => ({
      fields
    }));
  };

  onChangeEditor = editorState => {
    this.setState(state => {
      const fields = {
        ...state.fields,
        descripcion: JSON.stringify(
          draftToHtml(convertToRaw(editorState.getCurrentContent()))
        ).replace(/<[^>]*>?|\\n|"/g, "")
      };
      setChildStep(fields);
      return {
        ...state,
        fields
      };
    });

    this.re.validateField(
      "descripcion",
      JSON.stringify(
        draftToHtml(convertToRaw(editorState.getCurrentContent()))
      ).replace(/<[^>]*>?|\\n|"/g, "")
    );

    return this.setState({ editorState });
  };

  onSelectMembership = (event, id) => {
    const { checked } = event.target;
    const { membershipsSelected } = this.state;
    membershipsSelected[id] = checked;
    this.setState({ membershipsSelected });
  };

  onChangeSeller = selected => {
    let responsableId = null;
    if (selected.length > 0) {
      responsableId = selected[0]["id"];
    } else {
      responsableId = null;
    }
    this.setState(state => {
      const fields = {
        ...state.fields,
        responsableId
      };

      setChildStep(fields);

      return { ...state, fields, errors: {} };
    });
  };

  formatOptions = options =>
    options.map(option => ({
      value: option == "Seleccionar" ? "" : option,
      label: option
    }));

  findUser = () => {
    let dataReturn = [];
    if (
      this.props.users &&
      this.props.agreement &&
      this.props.agreement.responsableId
    ) {
      let objReturn = this.props.users.find(
        element => element.userId == this.props.agreement.responsableId
      );
      if (objReturn.hasOwnProperty("userId")) {
        dataReturn.push({
          id: objReturn.userId,
          label:
            objReturn.name +
            " " +
            objReturn.firstSurname +
            " " +
            objReturn.secondSurname
        });
      }
    }
    return dataReturn;
  };

  render() {
    return (
      <Container className="p-0" fluid>
        <div className="row">
          <div className="col">
            <BaseInput
              label={"Nombre"}
              name="nombrePromocion"
              type="text"
              id="nombrePromocion"
              placeholder="Nombre"
              value={this.state.fields.nombre}
              onChange={this.onChangeFactory("nombre")}
              errors={this.re.mapFieldErrors("nombre")}
            />
          </div>
          {this.props.users && (
            <div className="col">
              <Label className={s.label}>Responsable</Label>
              <Typeahead
                id={"Responsable"}
                defaultSelected={this.findUser()}
                promptText={"Responsable"}
                bsSize={"sm"}
                onChange={selected => this.onChangeSeller(selected)}
                emptyLabel={"Sin coincidencias"}
                disabled={this.props.users ? false : true}
                maxResults={20}
                options={
                  this.props.users
                    ? this.props.users.map(item => {
                        var rObj = {};
                        rObj["id"] = item["userId"];
                        rObj["label"] =
                          item["name"] +
                          " " +
                          item["firstSurname"] +
                          " " +
                          item["secondSurname"];
                        return rObj;
                      })
                    : []
                }
              />
              {this.props.users === null ? (
                <p className={s.msgError}>Cargando catálogo...</p>
              ) : (
                ""
              )}
            </div>
          )}
          <div className="col">
            <DatePicker
              label="Inicio Vigencia"
              name="inicioVigencia"
              id="inicioVigencia"
              selected={this.state.fields.inicioVigencia}
              onChange={this.onChangeFecha("inicioVigencia")}
              errors={this.re.mapFieldErrors("inicioVigencia")}
            />
          </div>
          <div className="col">
            <DatePicker
              label="Fin Vigencia"
              name="finVigencia"
              id="finVigencia"
              selected={this.state.fields.finVigencia}
              onChange={this.onChangeFecha("finVigencia")}
              errors={this.re.mapFieldErrors("finVigencia")}
              disabled={this.state.fields.sinFechaFinDeVigencia}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <BaseInput
              label={"Empresa"}
              name="empresa"
              type="text"
              id="nombre"
              placeholder="Empresa"
              value={this.state.fields.empresa}
              onChange={this.onChangeFactory("empresa")}
              errors={this.re.mapFieldErrors("empresa")}
            />
          </div>
          <div className="col-3">
            <BaseInput
              label={"No. Maximo"}
              name="numeroMaximo"
              type="number"
              id="nombre"
              placeholder="No. Maximo"
              value={this.state.fields.numeroMaximo}
              onChange={this.onChangeFactory("numeroMaximo")}
              errors={this.re.mapFieldErrors("numeroMaximo")}
            />
          </div>
          <div className="col-3">
            <BaseInput
              label={"Grupo"}
              name="grupoId"
              type="select"
              id="grupoId"
              placeholder="Grupo"
              value={this.state.fields.grupoId}
              options={
                this.state.grupos
                  ? [{ value: "", label: "Seleccione un grupo" }].concat(
                      this.state.grupos.map(option => ({
                        value: option.grupoId,
                        label: option.nombre
                      }))
                    )
                  : [{ value: "", label: "Seleccione un grupo" }]
              }
              onChange={evt => {
                this.setState({
                  fields: {
                    ...this.state.fields,
                    grupoId: evt.target.value
                  },
                  grupo: this.state.grupos.find(
                    g => g.grupoId == evt.target.value
                  )
                });
              }}
            />
            <div style={{ fontSize: "0.7em" }}>
              {this.state.grupo && this.state.grupo.descripcion}
            </div>
          </div>
        </div>
        <div className="form-row">
          <div className="col">
            <Form className={`${s.checkboxContainer}`}>
              <FormGroup check className="ml-5 form-check-inline float-right">
                <Label check>
                  <Input
                    type="checkbox"
                    checked={this.state.fields.activo}
                    onChange={e => this.checkActive(e)}
                  />{" "}
                  Activo
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-inline float-right">
                <Label check>
                  <Input
                    type="checkbox"
                    checked={this.state.fields.sinFechaFinDeVigencia}
                    onChange={e => this.checkDisableDate(e)}
                  />{" "}
                  Sin Fin de Vigencia
                </Label>
              </FormGroup>
            </Form>
          </div>
        </div>

        <div className="row">
          <div className="col mt-3">
            <div
              hidden={
                this.state.oldGrupoId == (this.state.fields.grupoId || null) ||
                !this.state.fields.grupoId
              }
              className="alert alert-warning"
            >
              Al cambiar el grupo de convenio, todas sus características se
              ajustarán al mismo. Esto eliminará sus configuraciones
              personalizadas.
            </div>
            <div
              hidden={
                !(
                  this.state.oldGrupoId != null &&
                  (this.state.fields.grupoId || null) == null
                )
              }
              className="alert alert-warning"
            >
              Al eliminar el grupo de convenio, las configuraciones de su
              convenio se conservarán, pero se ignorarán nuevos cambios en ese
              grupo.
            </div>
          </div>
        </div>
        <div className="form-row mt-1">
          <FormGroup className="col m-0">
            <Label>Descripción:</Label>
          </FormGroup>
        </div>
        <div style={{ height: "300px" }} className="mt-1">
          <RichEditor
            onChangeEditor={this.onChangeEditor}
            editorState={this.state.editorState}
          />
        </div>
        {this.re.mapFieldErrors("descripcion") &&
          this.re.mapFieldErrors("descripcion").map((message, index) => {
            return (
              <div key={index} className={`col-xs-12 ${s.errorSpan}`}>
                {message}
                <br />
              </div>
            );
          })}
        <div className="form-group mb-1 mt-3">
          <label>Condiciones Legales:</label>
          <textarea
            className="form-control"
            rows={9}
            id="condicionesLegales"
            value={this.state.fields.condicionesLegales}
            onChange={this.onChangeFactory("condicionesLegales")}
          />
        </div>
        {this.re.mapFieldErrors("condicionesLegales") &&
          this.re.mapFieldErrors("condicionesLegales").map((message, index) => {
            return (
              <span key={index} className={s.errorSpan}>
                {message}
                <br />
              </span>
            );
          })}
        <div
          hidden={this.state.oldGrupoId != this.state.fields.grupoId}
          className="form-group"
        >
          <div className="row mb-3 mt-3">
            <div className="col">
              <p className={`${s.primaryTitle} w-100 m-0`}>
                Membresias Aplicables
              </p>
            </div>
          </div>
          <div className="row m-0 p-0">
            {this.state.membershipsSelected &&
              this.props.memberships &&
              this.props.memberships.length &&
              this.props.memberships.map(
                ({ nombre, datosBasicosMembresiaId }, index) => (
                  <div key={datosBasicosMembresiaId} className="col-3 p-0 m-0">
                    <div className="row p-0 m-0">
                      <div className="col-2 m-0 p-0">
                        <input
                          className={s.checkboxInput}
                          type="checkbox"
                          name={nombre}
                          checked={this.state.membershipsSelected[index]}
                          onChange={e => this.onSelectMembership(e, index)}
                          style={{ marginLeft: "0.75rem" }}
                        />
                      </div>
                      <div className="col-10 m-0 p-0">
                        <label className="my-0">{nombre}</label>
                      </div>
                    </div>
                  </div>
                )
              )}
            {this.re.mapFieldErrors("membresiasAplicables") &&
              this.re
                .mapFieldErrors("membresiasAplicables")
                .map((message, index) => {
                  return (
                    <span key={index} className={s.errorSpan}>
                      {message}
                      <br />
                    </span>
                  );
                })}
          </div>
        </div>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(BasicDataAgreementForm));
