import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";
import { QueryArguments } from "../../types-business/QueryArguments";
import { Club } from "../../types/Clubs";

const CLUBS_BASE_URL = "/api/clubs";

export const getClubs = (params?: QueryArguments): IAction => {
  return {
    type: actionTypes.GET_CLUBS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${CLUBS_BASE_URL}`, {
        withCredentials: true,
        params: { ...params, ...constants.DEFAULT_SORTING_NAMEPARAMS }
      })
    }
  };
};

export const getAllClubs = (): IAction => {
  return {
    type: actionTypes.GET_ALL_CLUBS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${CLUBS_BASE_URL}/all`, {
        withCredentials: true
      })
    }
  };
};

export const getAllClubsBasicData = (): IAction => {
  return {
    type: actionTypes.GET_ALL_CLUBS_BASIC_DATA,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CLUBS_BASE_URL}/all/basic-data`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getAllClubsBasicDataRequest = () => {
  return axios.get(`${constants.BASE_URL}${CLUBS_BASE_URL}/all/basic-data`, {
    withCredentials: true
  });
};

export const createClub = (fields: object): IAction => {
  return {
    type: actionTypes.CREATE_CLUB,
    payload: {
      promise: axios.post(`${constants.BASE_URL}${CLUBS_BASE_URL}`, fields, {
        withCredentials: true
      })
    }
  };
};

export const getAllowedClubs = () => {
  return {
    type: actionTypes.GET_ALLOWED_CLUBS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CLUBS_BASE_URL}/allowed-for-activities`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getClubSellers = clubId => {
  return {
    type: actionTypes.GET_CLUB_SELLERS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CLUBS_BASE_URL}/${clubId}/sellers`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getClubGeneralManagers = clubId => {
  return {
    type: actionTypes.GET_CLUB_GENERAL_MANAGERS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CLUBS_BASE_URL}/${clubId}/general-managers`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const editClub = (clubId: string, club: Club): IAction => {
  return {
    type: actionTypes.CREATE_CLUB,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${CLUBS_BASE_URL}/${clubId}`,
        club,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const clearAllowedClubs = (): IAction => {
  return {
    type: actionTypes.CLEAR_ALLOWED_CLUBS
  };
};

export const disableClubCreated = (): IAction => {
  return {
    type: actionTypes.DISABLE_CLUB_CREATED
  };
};

export const updateActiveForCat = (clubId: string, activo: boolean) => {
  return axios.put(
    `${constants.BASE_URL}${CLUBS_BASE_URL}/${clubId}/active-for-cat?activo=${
      !!activo ? "true" : "false"
    }`,
    {},
    { withCredentials: true }
  );
};

export const getAfiliaciones = (idClub: any) => {
  return axios.get(
    `${constants.BASE_URL}${CLUBS_BASE_URL}/afiliaciones/${idClub}`,
    {
      withCredentials: true
    }
  );
};

export const putAfiliaciones = (body: any, idClub: any) => {
  return axios.put(
    `${constants.BASE_URL}${CLUBS_BASE_URL}/afiliaciones/${idClub}`,
    body,
    {
      withCredentials: true
    }
  );
};

export const getMitTerminalCredentialsByClubIdRequest = (clubId: string) => {
  return axios.get(
    `${constants.BASE_URL}${CLUBS_BASE_URL}/mit-terminal-credentials/${clubId}`,
    {
      withCredentials: true
    }
  );
};

export const saveMitTerminalCredentialsByClubIdRequest = data => {
  return axios.put(
    `${constants.BASE_URL}${CLUBS_BASE_URL}/mit-terminal-credentials/${data.clubId}`,
    data,
    { withCredentials: true }
  );
};
