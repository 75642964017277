import React from "react";
import { ReduxConnect } from "../../../toolkit/decorators/Artifact";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import {
  ComisionesDeportivaPendientesDispatcher,
  comisionesDeportivaPendientesDispatcher
} from "./ComisionesDeportivaPendientes.dispatcher";
import {
  comisionesDeportivaPendientesStore,
  ComisionesDeportivaPendientesStore
} from "./ComisionesDeportivaPendientes.store";
import ComisionesDeportivaPendientes from "../../components/comisionesDeportivaPendientes";

interface Props
  extends ComisionesDeportivaPendientesStore,
    ComisionesDeportivaPendientesDispatcher {}

interface State {}

@ReduxConnect(
  comisionesDeportivaPendientesStore,
  comisionesDeportivaPendientesDispatcher
)
export default class ComisionesDeportivaPendientesPage extends React.Component<
  Props,
  State
> {
  render() {
    return (
      <main>
        <Helmet title="Agreements" />
        <Layout>
          <ComisionesDeportivaPendientes {...this.props} />
        </Layout>
      </main>
    );
  }
}
