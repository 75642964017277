import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";

const CLUBS_BASE_URL = "/api/activity-logs";

export const getBinnacleByPartnerMembership = (params?: any): IAction => {
  return {
    type: actionTypes.GET_ACTIVITY_LOGS_BY_PARTNER_MEMBERSHIP,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${CLUBS_BASE_URL}/by-partner-membership`,
        {
          withCredentials: true,
          params: { ...params, ...constants.DEFAULT_SORTING_PARAMS }
        }
      )
    }
  };
};

export const getBinnacleByPartner = (params?: any): IAction => {
  return {
    type: actionTypes.GET_ACTIVITY_LOGS_BY_PARTNER,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${CLUBS_BASE_URL}/by-partner`, {
        withCredentials: true,
        params: { ...params, ...{ sort: "eventoFecha,desc" } }
      })
    }
  };
};

export const getBinnacleByPartnerMembershipAxios = (args?: any) => {
  return axios.get(
    `${constants.BASE_URL}${CLUBS_BASE_URL}/by-partner-membership`,
    {
      withCredentials: true,
      params: { ...args, sort: "desc" }
    }
  );
};
