import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Layout from "../../components/layout";
import { Helmet } from "react-helmet";
import s from "./styles.scss";
import logoUrl from "../../../media/img/swbackground.jpg";
import { Container, Row, Col } from "reactstrap";

interface Shared {
  name: string;
  url: string;
}

const manuales: Shared[] = [
  {
    url:
      "https://docs.google.com/presentation/d/1eV4mf_lSbXtUZXu2GRZtHUujILILIjaaYky5CbzH7wY/edit#slide=id.g27e667da905_0_1",
    name: "Manual CRM 2024"
  },
  {
    url:
      "https://docs.google.com/document/d/1MR_aPaEpDPcDPpMVl4AdThoQhFxRh8468TMOz0KYXXM/edit?usp=sharing",
    name: "Nuevas funcionalidades"
  },
  {
    url:
      "https://docs.google.com/document/d/1JdRfJQKWSQVR0Px6w0nTSpfsCaW7Sx8jKjtR1x5aTR0/edit",
    name: "FAQ 2024"
  },
  {
    url:
      "https://docs.google.com/document/d/1x-GuXUtiKXLoSf3KB3OXtXNCGMuQ8VqRXoLirkIoNrI/edit",
    name: "Gestión de comisiones"
  }
];

const facturacion: Shared[] = [
  {
    url:
      "https://docs.google.com/document/d/1aZ8mFz__QBbKVVaDORFrlmeLevkoua9aaeBA_cwpzz8/edit",
    name: "Reglas de facturación CFDI 4.0"
  }
];

const informacion: Shared[] = [
  {
    url:
      "https://drive.google.com/file/d/1aOU6nfason7WJWrSy0kxuXnnEscGJghu/view?usp=drive_link",
    name: "Lista de Productos y Servicios 2024"
  }
];

interface Props extends RouteComponentProps {}

interface State {}

class LandingPage extends React.Component<Props, State> {
  renderShared = (sharedDocs: Shared[]) => {
    return sharedDocs.map(shared => (
      <Row>
        <Col>
          <div style={{ fontWeight: "bold", marginTop: "8px" }}>
            {shared.name}
          </div>
          <div style={{ marginBottom: "8px" }}>
            <a href={shared.url} target="_blank">
              {shared.url.length > 64
                ? shared.url.substring(0, 61) + "..."
                : shared.url}
            </a>
          </div>
        </Col>
      </Row>
    ));
  };

  render() {
    return (
      <Layout>
        <Container>
          <Helmet title="Inicio" />
          <div>&nbsp;</div>
          <Row>
            <Col lg="6">
              <h4>MANUALES Y DOCUMENTOS DE APOYO:</h4>
              {this.renderShared(manuales)}

              <div className="mb-2">&nbsp;</div>
            </Col>
            <Col lg="6">
              <h4>FACTURACIÓN:</h4>
              {this.renderShared(facturacion)}

              <div className="mb-2">&nbsp;</div>

              <h4>INFORMACIÓN:</h4>
              {this.renderShared(informacion)}
            </Col>
          </Row>
          <Row className={"my-3"}>
            <Col>
              <img src={logoUrl} className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default withRouter(withStyles(s)(LandingPage));
