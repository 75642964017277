import React from "react";
import IUserSelectorBuilderProps from "./IUserSelectorBuilder.props";
import IUserSelectorBuilderState from "./IUserSelectorBuilder.state";
import IInputBuilder from "../../baseForms2/iInputBuilder/IInputBuilder";
import IUserSelectorConnected from "../iUserSelector/IUserSelectorConnected";

export default class IUserSelectorBuilder<
  INP extends string
> extends IInputBuilder<
  INP,
  IUserSelectorBuilderProps<INP>,
  IUserSelectorBuilderState
> {
  render() {
    return <IUserSelectorConnected {...(this.props as any)} />;
  }
}
