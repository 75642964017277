import React, { useEffect, useState } from "react";
import { MEMBERSHIP_TYPE } from "../../../../../utils/constants";
import { SalesTrackingBySellerInterface } from "../../../../../types-business/Products";
import { Container, Row, Col } from "reactstrap";
import SalesTable from "./SalesTable";
import ProspectsTable from "./ProspectsTable";
import { errorNotification } from "../../../../../utils/notifications";

const tableSalesTitle = "Tracking de Ventas por Ejecutivo (Unidades e Ingreso)";
const tableProspectsTitle = "Tracking de Ventas por Ejecutivo (Prospectos)";

interface Props {
  clubId: string;
  month: string;
  year: string;
  getSalesTrackingBySeller: (params: object) => SalesTrackingBySellerInterface;
  saveCompromisesBySeller: (compromises) => any;
  disabled: boolean;
  setHasSellers: (hs: boolean) => any;
}

export default ({
  clubId,
  month,
  year,
  getSalesTrackingBySeller,
  saveCompromisesBySeller,
  disabled,
  setHasSellers
}: Props) => {
  const [salesTracking, setSalesTracking] = useState(null);
  const [tableDataLoading, setTableDataLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setTableDataLoading(true);
      try {
        if (clubId == "") {
          throw { response: { data: { message: "El club es requerido" } } };
        }

        const goals = await getSalesTrackingBySeller({
          clubId: clubId,
          anio: year,
          mes: month,
          tipoEsquema: MEMBERSHIP_TYPE.VENTA
        });

        setSalesTracking(goals);
        saveCompromisesBySeller(goals);
      } catch ({ response: { data } }) {
        if (data && data.message) {
          errorNotification(data.message);
        } else {
          errorNotification("Error inesperado.");
        }
        setError(true);
        setSalesTracking(null);
      } finally {
        setTableDataLoading(false);
      }
    };

    getData();
  }, [year, month, clubId]);

  const updateStateCompromises = newSalesTracking => {
    setSalesTracking(newSalesTracking);
    saveCompromisesBySeller(newSalesTracking);
  };

  return (
    <Container className="mt-3 p-0">
      <Row>
        <Col>
          <p className={`primaryTitle w-100`}>{tableSalesTitle}</p>
          <SalesTable
            disabled={disabled}
            loading={tableDataLoading}
            salesTracking={salesTracking}
            saveCompromisesBySeller={updateStateCompromises}
            errorGettingData={error}
            setHasSellers={setHasSellers}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <p className={`primaryTitle w-100`}>{tableProspectsTitle}</p>
          <ProspectsTable
            disabled={disabled}
            loading={tableDataLoading}
            salesTracking={salesTracking}
            saveCompromisesBySeller={updateStateCompromises}
            errorGettingData={error}
          />
        </Col>
      </Row>
    </Container>
  );
};
