import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { QueryResponse } from "../../../types/QueryResponse";
import { QueryArguments } from "../../../types-business/QueryArguments";
import { Container, Row, Col, UncontrolledTooltip } from "reactstrap";
import ReactTable from "react-table";
import CONSTANTS from "../../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

interface Props {
  products: QueryResponse<Product>;
  searching: boolean;
  onClickNewProduct: (product) => any;
  reloadProducts: boolean;
  fetchData: () => void;
  history: any;
  match: any;
  filters: Filters;
  onChangeFilter: any;
  getProducts: (args?: QueryArguments) => any;
  sportsProducts: [{ id: string; nombre: string }];
  getUsersData: (eventId: string, clubId: string) => any;
  events: object[];
  usersInEvent: {
    productoId: number;
    nombreEvento: string;
    clubId: number;
    eventoParticipanteCliente: [
      {
        clienteId: number;
        nombre: string;
        fechaInicioEvento: string;
        fechaRegistro: string;
        ventaId: number;
        factura: string;
        codigoDescuento: string;
        productoInstanciaId: number;
        tieneEventos: boolean;
      }
    ];
  };
  gettingUsersInEvent: boolean;
}

interface Filters {
  clasificacionId: string;
  page: number;
  size: number;
}

interface Product {
  id: string;
  clasificacionId: string;
  nombre: string;
  estatus: string;
  publicarPrecio: boolean;
  fechaInicioVigencia: string;
  fechaFinVigencia: string;
  descripcion: string;
}

class ProductSells extends React.Component<Props> {
  handleEditProduct = client => {
    this.props.onClickNewProduct(client.productoInstanciaId, client.clienteId);
  };

  componentDidMount() {
    const { eventId, clubId } = this.props.match.params;
    if (eventId) {
      this.props.getUsersData(eventId, clubId);
    }
  }

  render() {
    const columns = [
      { Header: "Evento", accessor: "nombreEvento", width: 200 },
      { Header: "Club base", accessor: "clubBase", width: 150 },
      {
        Header: "Membresía",
        id: "idPublico",
        accessor: item => item.idPublico || item.clienteId,
        width: 150
      },
      { Header: "Socio", accessor: "nombre", width: 200 },
      {
        Header: "Inicio del evento",
        accessor: "fechaInicioEvento",
        className: "text-center",
        width: 150
      },
      {
        Header: "Fecha de Registro",
        accessor: "fechaRegistro",
        className: "text-center",
        width: 150
      },
      { Header: "Factura", accessor: "factura", className: "text-center" },
      {
        Header: "Codigo de descuento",
        accessor: "codigoDescuento",
        className: "text-center",
        width: 150
      },
      {
        Header: "",
        id: "buttons",
        accessor: item => {
          return (
            <a
              id={`ac1${item.productoInstanciaId}`}
              onClick={() => this.handleEditProduct(item)}
            >
              <UncontrolledTooltip
                placement="left"
                target={`ac1${item.productoInstanciaId}`}
              >
                Asignar clases
              </UncontrolledTooltip>
              <FontAwesomeIcon icon={faLink} />
            </a>
          );
        }
      }
    ];

    return (
      <Container className="p-0 mt-3">
        <Row>
          <Col>
            {this.props.usersInEvent && (
              <ReactTable
                manual
                data={this.props.usersInEvent.eventoParticipanteCliente}
                columns={columns}
                className="w-100"
                defaultPageSize={CONSTANTS.DEFAULT_PAGE_SIZE}
                loading={this.props.searching}
                sortable={false}
                style={{ height: CONSTANTS.TABLES_HEIGHT }}
              />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(ProductSells));
