import { ConvenioClubesAsignables } from "../../reducers/convenios";
import {
  CreateEmpresaConvenio,
  GetEmpresaConvenio,
  GetEmpresaConvenioInvitado,
  UpdateEmpresaConvenio,
  UpdateEmpresaConvenioInvitado
} from "../../reducers/empresaConvenio";
import { GetUsers } from "../../reducers/user";

export interface EmpresaConvenioCarouselStore
  extends GetEmpresaConvenio,
    CreateEmpresaConvenio,
    UpdateEmpresaConvenio,
    GetEmpresaConvenioInvitado,
    UpdateEmpresaConvenioInvitado,
    GetUsers,
    ConvenioClubesAsignables {}

export const empresaConvenioCarouselStore: (
  state
) => EmpresaConvenioCarouselStore = state => {
  const { empresasConvenio, user, convenios } = state;
  return {
    empresaConvenio: empresasConvenio.empresaConvenio,
    gettingEmpresaConvenio: empresasConvenio.gettingEmpresaConvenio,
    gettingEmpresaConvenioError: empresasConvenio.gettingEmpresaConvenioError,
    empresaConvenioCreated: empresasConvenio.empresaConvenioCreated,
    creatingEmpresaConvenio: empresasConvenio.creatingEmpresaConvenio,
    creatingEmpresaConvenioError: empresasConvenio.creatingEmpresaConvenioError,
    empresaConvenioUpdated: empresasConvenio.empresaConvenioUpdated,
    updatingEmpresaConvenio: empresasConvenio.updatingEmpresaConvenio,
    updatingEmpresaConvenioError: empresasConvenio.updatingEmpresaConvenioError,
    empresaConvenioInvitadoUpdated:
      empresasConvenio.empresaConvenioInvitadoUpdated,
    updatingEmpresaConvenioInvitado:
      empresasConvenio.updatingEmpresaConvenioInvitado,
    updatingEmpresaConvenioInvitadoError:
      empresasConvenio.updatingEmpresaConvenioInvitadoError,
    empresaConvenioInvitado: empresasConvenio.empresaConvenioInvitado,
    gettingEmpresaConvenioInvitado:
      empresasConvenio.gettingEmpresaConvenioInvitado,
    gettingEmpresaConvenioInvitadoError:
      empresasConvenio.gettingEmpresaConvenioInvitadoError,
    gettingUsersError: user.gettingUsersError,
    usersPage: user.usersPage,
    usersList: user.usersList,
    gettingUsers: user.gettingUsers,
    clubesAsignables: convenios.clubesAsignables,
    clubesAsignablesLoading: convenios.clubesAsignablesLoading
  };
};
