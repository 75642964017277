import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import logoUrl from "../../../media/img/sw-public.jpg";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Label, Input, Button } from "reactstrap";
import {
  getPaymentLine,
  postPaymentLineAccept
} from "../../actions/paymentLines";
import { parse as parseQueryParams } from "query-string";
import { errorNotification } from "../../../utils/notifications";

interface Props {}

interface State {
  confirmacion: boolean;
  linea: any;
  error: string;
  dsource: string;
  confirmando: boolean;
}

class LineaPagoPublico extends React.Component<Props, State> {
  state = {
    confirmacion: false,
    linea: null,
    error: null,
    dsource: null,
    confirmando: false
  };

  componentDidMount(): void {
    // Consulta de la línea cifrada
    const { dsource } = parseQueryParams(this.props.location.search);
    getPaymentLine(dsource)
      .then(response => {
        // REQ: Arturo Salgado - Siempre debe confirmarse la línea
        // Si la línea ya se aceptó, se va directamente al pago
        // if (response.data.aceptada) {
        //   document.location.href = response.data.url;
        // }

        this.setState({
          linea: response.data,
          dsource
        });
      })
      .catch(err => {
        this.setState({
          error:
            (err &&
              err.response &&
              err.response.data &&
              err.response.data.message) ||
            "ಠ_ಠ ¡Ooops! Tu línea de pago es inválida, solicítala de nuevo por favor."
        });
      });
  }

  render() {
    // No se ha cargado la línea de pago
    if (this.state.confirmando || (!this.state.error && !this.state.linea)) {
      return (
        <div style={{ fontSize: "32px", textAlign: "center" }}>
          <FontAwesomeIcon icon={faSpinner} pulse />
        </div>
      );
    }

    if (this.state.error) {
      return (
        <Container>
          <Row>
            <Col>
              <img src={logoUrl} className="img-fluid" />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="alert alert-danger mb-4 mt-4">
                {this.state.error}
              </div>
            </Col>
          </Row>
        </Container>
      );
    }

    return (
      <Container>
        <Row>
          <Col>
            <img src={logoUrl} className="img-fluid mb-4" />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <div className="alert alert-info mb-4 mt-4">
              {"Acepta el uso de tu tarjeta como medio de pago principal"}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <span style={{ fontWeight: "bold" }}>Productos:</span>
          </Col>
          <Col md="12">
            <table className="table">
              <tbody>
                {(this.state.linea.cargos || []).map((cargo, index) => (
                  <tr key={"tr-cargo-" + index}>
                    <td>{cargo.unidades}</td>
                    <td>{cargo.descripcion}</td>
                    <td>{"$" + cargo.precio}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ paddingLeft: "32px" }} className="mt-5 mb-2">
              <Label check>
                <Input
                  type="checkbox"
                  checked={this.state.confirmacion}
                  onChange={e =>
                    this.setState({ confirmacion: e.target.checked })
                  }
                />
                <span style={{ fontSize: "16px" }}>
                  Acepto que la tarjeta utilizada para este pago, quedará
                  registrada para el cobro mensual o anual recurrente
                  dependiendo del producto adquirido. Acepto el aviso de
                  privacidad de clientes.
                </span>
              </Label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              onClick={e => {
                e.preventDefault();
                this.setState({ confirmando: true });

                postPaymentLineAccept(this.state.dsource)
                  .then(resp => {
                    document.location.href = this.state.linea.url;
                  })
                  .catch(err => {
                    this.setState({ confirmando: false });
                    errorNotification(
                      (err &&
                        err.response &&
                        err.response.data &&
                        err.response.data.message) ||
                        "No pudimos confirmar tu aceptación, intenta de nuevo más tarde por favor."
                    );
                  });
              }}
              size="sm"
              block
              disabled={!this.state.confirmacion}
            >
              {this.state.confirmacion
                ? "Proceder al pago"
                : "Acepta las condiciones del pago"}
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(LineaPagoPublico));
