import React from "react";
import { Input } from "reactstrap";

interface Option {
  id: string;
  numeroCuenta: string;
  nombre: string;
}

interface Props {
  value: string;
  onChange: (e) => any;
  disabled: boolean;
  size: string;
  options: Option[];
  id: string;
}

export default ({ value, onChange, disabled, size, options, id }: Props) => (
  <Input
    name="selectCuentaProducto"
    type="select"
    id={id}
    size={size}
    value={value}
    onChange={e => onChange(e)}
    disabled={disabled}
  >
    {[{ value: null, label: "Seleccione la Cuenta" }]
      .concat(
        options.map(option => ({
          value: option.id,
          label: `${option.numeroCuenta} - ${option.nombre}`
        }))
      )
      .map(opt => (
        <option key={opt.value} value={opt.value}>
          {opt.label}
        </option>
      ))}
  </Input>
);
