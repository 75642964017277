import React from "react";

import ReactTable from "react-table";
import { Container, Row, Col } from "reactstrap";

import MexicanCurrency from "../../../common/currency";
import moment from "moment";
import { userHasPermission } from "../../../../../utils/accessTree";
import permissionCodes from "../../../../../utils/permissionCodes";
import BaseButtonWithIconAndTooltip from "../../../../../toolkit/baseButtonWithIconAndTooltip";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { deleteDiscountCode } from "../../../../actions/partners";
import { handleRequestError } from "../../../../../utils/helpers";
import ConfirmModal from "../../../../components/common/confirmModal/confirmModal";

interface Props {
  discountCodesList: object[];
  partnerMembershipId: String;
  invalidateStateValue: (fieldName: string, value: any) => void;
  getMembershipDiscountCodes: () => void;
}

class DiscountCodesTable extends React.Component<Props> {
  state = {
    confirmDeleteDiscountCodeModalIsOpen: false,
    idDiscountCodeToDelete: null
  };
  columns = [
    {
      Header: "Producto aplicable",
      accessor: "codigoProducto"
    },
    {
      Header: "Club aplicable",
      accessor: "codigoClubNombre"
    },
    {
      Header: "Estatus",
      accessor: "codigoEstatus"
    },
    {
      Header: "Inicio vigencia",
      accessor: "codigoInicioVigencia",
      className: "text-center",
      Cell: ({ value }) => moment(value).format("DD-MM-YYYY")
    },
    {
      Header: "Fin vigencia",
      accessor: "codigoFinVigencia",
      className: "text-center",
      Cell: ({ value }) => moment(value).format("DD-MM-YYYY")
    },
    {
      Header: "Código",
      accessor: "codigoDescuento",
      className: "text-center"
    },
    {
      Header: "Tipo",
      accessor: "codigoTipoDescuento",
      className: "text-center"
    },
    {
      Header: "Descuento",
      accessor: "descuento",
      className: "text-center",
      Cell: row => {
        return row.original.codigoTipoDescuento == "PORCENTAJE" ? (
          <span> {row.original.codigoPorcentajeDescuento} % </span>
        ) : row.original.codigoTipoDescuento == "MONTO" ? (
          <MexicanCurrency quantity={row.original.codigoMontoDescuento} />
        ) : null;
      }
    },
    {
      Header: "",
      Cell: row => {
        return userHasPermission(
          permissionCodes.PARTNER_MEMBERSHIP_TAB_DISCOUNT_CODES_DELETE_DISCOUNT_CODE
        ) && row.original.codigoEstatus == "CREADO" ? (
          <div>
            {" "}
            <BaseButtonWithIconAndTooltip
              id={`deleteFreeGuestsAccess_${row.original.id}`}
              icon={faTimes}
              tooltipTitle={"Eliminar código de descuento"}
              onClick={() => {
                this.setState({
                  idDiscountCodeToDelete: row.original.id,
                  confirmDeleteDiscountCodeModalIsOpen: true
                });
              }}
            />
          </div>
        ) : null;
      }
    }
  ];

  handleFreeGuestsAccessDelete = () => {
    const { idDiscountCodeToDelete: id } = this.state;
    const { partnerMembershipId: idMembresiaSocio } = this.props;

    deleteDiscountCode(idMembresiaSocio, id)
      .then(() => {
        this.setState({
          idDiscountCodeToDelete: null,
          confirmDeleteDiscountCodeModalIsOpen: false
        });

        this.props.invalidateStateValue("discountCodes", []);
        this.props.getMembershipDiscountCodes();
      })
      .catch(handleRequestError);
  };

  render() {
    return (
      <Container className="p-0">
        <ConfirmModal
          title="Eliminar código de descuento"
          message="Se eliminara el código de descuento, ¿Desea continuar?"
          isOpen={this.state.confirmDeleteDiscountCodeModalIsOpen}
          requireInput={false}
          ok={this.handleFreeGuestsAccessDelete}
          cancel={() => {
            this.setState({
              idDiscountCodeToDelete: null,
              confirmDeleteDiscountCodeModalIsOpen: false
            });
          }}
        />
        <Row>
          <Col>
            <ReactTable
              data={this.props.discountCodesList || []}
              columns={this.columns}
              pageSize={20}
              showPagination={true}
              className="mt-3"
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default DiscountCodesTable;
