import cloneDeep from "lodash.clonedeep";

export interface MENU {
  display?: number;
  id: number;
  text: string;
  url: string;
  menu: MENU[];
}

interface FUNCTIONALITY_TREE_STRUCTURE {
  menu: MENU;
  displays: {
    id: string;
    functionalities: string[];
  }[];
  permissions: number[];
}

let FUNCTIONALITY_TREE: FUNCTIONALITY_TREE_STRUCTURE;
let USER_MENU: MENU[] = [];
let FUNCTIONALITY_HASH: object = {};
let FUNCTIONALITY_PERMISSIONS: object = {};
let DISPLAYS_HASH: object = {};
let menuCounter = 0;

const getDisplayHash = (userMenu: MENU[], displayHash: object) => {
  if (!userMenu) return;

  userMenu.forEach(menu => {
    if (menu.display) displayHash[menu.display] = menu;
    menu.id = menuCounter++;
    getDisplayHash(menu.menu, displayHash);
  });
};

export const allowedTo = (access: string): boolean =>
  Boolean(FUNCTIONALITY_HASH[access]);

export const userHasPermission = (permission: number): boolean =>
  Boolean(FUNCTIONALITY_PERMISSIONS[`${permission}`]);

export const userHasAnyPermissions = (permission: number[]): boolean => {
  let result: boolean = false;
  permission.forEach(x => {
    result = result || !!FUNCTIONALITY_PERMISSIONS[`${x}`];
  });
  return result;
};

export const userHasAllPermissions = (permission: number[]): boolean => {
  let result: boolean = true;
  permission.forEach(x => {
    result = result || !!FUNCTIONALITY_PERMISSIONS[`${x}`];
  });
  return result;
};

export const setFunctionalityTree = (
  tree: FUNCTIONALITY_TREE_STRUCTURE
): void => {
  FUNCTIONALITY_TREE = { ...tree };

  const { displays, permissions } = FUNCTIONALITY_TREE;

  displays.forEach(({ functionalities }) => {
    functionalities.forEach(access => {
      FUNCTIONALITY_HASH[access] = true;
    });
  });

  (permissions || []).forEach(permission => {
    FUNCTIONALITY_PERMISSIONS[`${permission}`] = true;
  });

  // Ignoring root menu.
  USER_MENU = !!FUNCTIONALITY_TREE.menu ? FUNCTIONALITY_TREE.menu.menu : [];
  getDisplayHash(USER_MENU, DISPLAYS_HASH);
};

export const getUserMenu = (): MENU[] => cloneDeep(USER_MENU);
export const validAccess = (display: number): boolean =>
  display === -1 || Boolean(DISPLAYS_HASH[display]);

export const cleanUp = () => {
  USER_MENU = [];
  FUNCTIONALITY_HASH = {};
  FUNCTIONALITY_PERMISSIONS = {};
  DISPLAYS_HASH = {};
};
