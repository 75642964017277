import React from "react";
import { connect } from "react-redux";
import * as agreementActions from "../../actions/agreements";
import * as productActions from "../../actions/products";
import * as accountActions from "../../actions/profile";
import BasicDataAgreementForm from "../../components/basicDataAgreementForm";

interface Props {
  saveBasicAgreement: (agreement: object) => any;
  updateBasicAgreement: (convenioId: string, agreement: object) => any;
  successfulSave: boolean;
  basicAgreementSaved: object;
  getBasicAgreement: (id: string) => any;
  agreement: object;
  onClickSave: (f: any) => any;
  onSuccessfulSave: () => any;
  getMemberships: () => any;
  memberships: object[];
  users: object[];
  getUsers: () => any;
  gettingAgreement: boolean;
}

class BasicDataAgreementContainer extends React.Component<Props, void> {
  componentDidMount() {
    if (!this.props.memberships) this.props.getMemberships();
    if (!this.props.users) this.props.getUsers();
  }

  componentDidUpdate() {}

  shouldComponentUpdate(nextProps) {
    if (
      this.props.basicAgreementSaved !== nextProps.basicAgreementSaved &&
      nextProps.basicAgreementSaved
    ) {
      this.props.onSuccessfulSave();
      return false;
    }

    return true;
  }

  render() {
    return (
      <div className="h-100 w-100 p-0 w-0">
        {this.props.users && this.props.memberships && (
          <BasicDataAgreementForm
            saveBasicAgreement={this.props.saveBasicAgreement}
            agreement={
              window.location.pathname != "/convenios/datos-basicos/"
                ? this.props.agreement
                : null
            }
            updateBasicAgreement={this.props.updateBasicAgreement}
            onClickSave={this.props.onClickSave}
            getBasicAgreement={this.props.getBasicAgreement}
            memberships={this.props.memberships}
            users={this.props.users}
            gettingAgreement={this.props.gettingAgreement}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  saveBasicAgreement: (agreement: object) => {
    return dispatch(agreementActions.saveBasicAgreement(agreement));
  },
  getBasicAgreement: (id: string) => {
    dispatch(agreementActions.getAgreementById(id));
  },
  updateBasicAgreement: (convenioId: string, agreement: object) => {
    dispatch(agreementActions.updateBasicAgreement(convenioId, agreement));
  },
  getMemberships: () => {
    dispatch(productActions.getMemberships());
  },
  getUsers: () => {
    dispatch(accountActions.getUsers());
  }
});

const mapStateToProps = state => {
  const { agreements, products, user } = state;
  return {
    basicAgreementSaved: agreements.basicAgreementSaved,
    agreement: agreements.agreementById,
    gettingAgreement: agreements.gettingAgreementById,
    memberships:
      products.memberships && products.memberships.content
        ? products.memberships.content
        : null,
    users: user.usersPage
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasicDataAgreementContainer);
