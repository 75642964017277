import React from "react";
import * as catalogActions from "../../actions/catalogs";
import * as reportsActions from "../../actions/reports";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Partners from "../../components/partners";
import * as prospectsActions from "../../actions/prospects";
import * as billingActions from "../../actions/billing";
import {
  toggleLinkPartnerModal,
  toggleLinkFreePartnerModal,
  toggleChangeLinkFreePartnerModal,
  toggleLinkFreeChildPartnerModal
} from "../../actions/modals";
import {
  printDocument,
  getDocument,
  sendScannedFile,
  getPartner,
  cleanDocument,
  sendEmailFile,
  recreateFile,
  getDocumentForSign,
  saveDocumentSignature,
  getParnerDocuments,
  updatePhoto,
  updatePawPrint,
  accessRegister,
  getPartnersMembership,
  deleteBankCard,
  toggleDeletePartner,
  sendMovementEmail,
  updateTitular,
  clearReportPersonIngressByPartner
} from "../../actions/partners";
import {
  getMembershipMembers,
  getMemberships,
  getMembershipById,
  getMembershipsMaintenance,
  rightsChange,
  createMaintenanceDiscount
} from "../../actions/products";
import { getQuotations } from "../../actions/prospects";
import { getPromotionsFiltered } from "../../actions/promotions";
import IAction from "../../../types/IAction";
import { QueryArguments } from "../../../types-business/QueryArguments";
import { showDocument } from "../../actions/documents";
import { getPartnersMembershipRequest } from "../../actions/partners";
import { saveAnswers } from "../../actions/surveys";
import { getEmpresasActivas } from "../../actions/convenios";
import { Club } from "../../../types-business/Clubs";
import { handleRequestError } from "../../../utils/helpers";

interface Props {
  getBankPayTypes: () => void;
  getPartner: (params: object) => void;
  getPartnersMembership: (
    idMembresiaSocio: string,
    idMantenimiento: string,
    idMembresia: string
  ) => void;
  cleanDocument: () => void;
  getDocument: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string
  ) => void;
  getDocumentForSign: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string
  ) => void;
  printDocument: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string
  ) => void;
  sendScannedFile: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string,
    file: Blob,
    name: string
  ) => void;
  sendEmailFile: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string
  ) => void;
  recreateFile: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string
  ) => void;
  saveDocumentSignature: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string,
    signature: string
  ) => void;
  partner: Partner;
  toggleLinkPartnerModal: () => any;
  toggleLinkFreePartnerModal: () => any;
  toggleChangeLinkFreePartnerModal: (data: any) => any;
  toggleLinkFreeChildPartnerModal: () => any;
  saveAnswers: (answers: object) => any;
  partnerCreated: boolean;
  idDocument: object;
  getMembershipMembers: (membresiaId: string) => any;
  membershipTypesMembers: any[];
  partnerDocuments: any;
  getPartnerDocuments: (idMembresiaSocio: string, idPersona) => void;
  updatePhoto: (
    idMantenimiento: string,
    idMembresia: string,
    idPersona: string,
    photo: Blob
  ) => void;
  updatePawPrint: (
    idMantenimiento: string,
    idMembresia: string,
    idMembresiaSocio: string,
    idPersona: string,
    pawPrint: string
  ) => void;
  accessRegister: (idPersona: string) => void;
  partnersMembership: object[];
  deleteBankCard: (id: string) => void;
  toggleDeletePartner: (
    partner?: boolean,
    idMembresiaSocio?: string
  ) => IAction;
  getMemberships: () => IAction;
  getQuotations: (
    personaId: string,
    args: object,
    integrantes: object
  ) => IAction;
  getMembershipsTypes: (clubId: string) => IAction;
  getMembershipById: (id: string) => IAction;
  getMaintenances: (membresiaId: string) => IAction;
  getPromotions: () => IAction;
  getEmpresasActivas: (attrs) => IAction;
  getMemberhips: (id: string) => any;
  quotations: object[];
  membershipById: object;
  promotions: object[];
  maintenances: object[];
  agreements: object[];
  membershipsTypes: object[];

  updateTitular: (data: object, membresiaSocioId: string) => IAction;
  sendMovementEmail: (idMovimiento: number, email: string) => IAction;
  toggleBill: (idDocumento: number) => IAction;
  toggleBillXml: (uuid: string) => IAction;
  createMaintenanceDiscount: (data: object) => IAction;
  rightsChange: (idMembresiaSocio: string, idPersona: string) => IAction;
  successfullSwitchMembership: object;
  loadingPartnersMembership: boolean;
  clearIngressByPartnerReport: () => void;
  products: object;
  listHistoricalRebilling: any;
  historicalRebilling: (ventaId: string) => IAction;
  listHistoryAutCharges: any;
  getWaysPay: () => void;
}

interface Partner {
  nombrePersona: string;
  nombreMembresia: string;
  nombreConvenio: string;
  certificado: boolean;
  estatusMembresia: string;
  fechaAdquisicion: string;
  fechaInicioMantenimiento: string;
  idClubAlterno: string;
  idClubBase: string;
  idMembresia: string;
  idMembresiaSocio: string;
  idMantenimiento: string;
  idPersona: string;
  intransferible: boolean;
  limiteInicioMantenimiento: string;
  nueva: boolean;
  precioBaseMembresia: string;
  precioVentaMembresia: string;
  ventaEnLinea: boolean;
  membershipDocuments: object[];
}
interface State {
  membershipPartners: any[];
  clubs: Club[];
}

class PartnersPage extends React.Component<Props, State> {
  state = {
    membershipPartners: null,
    clubs: []
  };

  componentDidMount() {
    this.getClubs();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.partner &&
      !this.props.partnersMembership &&
      !this.props.loadingPartnersMembership
    ) {
      this.props.getPartnersMembership(
        this.props.partner.idMembresiaSocio,
        this.props.partner.idMantenimiento,
        this.props.partner.idMembresia
      );
      this.setState({ membershipPartners: null });
    }

    if (
      this.props.partner &&
      !prevProps.partnerCreated &&
      this.props.partnerCreated
    ) {
      this.props.getPartnersMembership(
        this.props.partner.idMembresiaSocio,
        this.props.partner.idMantenimiento,
        this.props.partner.idMembresia
      );
      this.setState({ membershipPartners: null });
    }
  }

  getClubs = () => {
    catalogActions
      .getClubsRequest()
      .then(({ data }) => {
        this.setState({ clubs: data });
      })
      .catch(handleRequestError);
  };

  executePartnerMembershipPartners = () => {
    getPartnersMembershipRequest(
      this.props.partner.idMembresiaSocio,
      this.props.partner.idMantenimiento,
      this.props.partner.idMembresia
    ).then(({ data }) => {
      this.setState({ membershipPartners: data });
    });
  };

  render() {
    return (
      <main>
        <Helmet title="Partners" />
        <Layout>
          {this.state.clubs && (
            <Partners
              clubs={this.state.clubs}
              partner={this.props.partner}
              getPartner={this.props.getPartner}
              getPartnersMembership={this.props.getPartnersMembership}
              getDocument={this.props.getDocument}
              printDocument={this.props.printDocument}
              toggleAddPartner={this.props.toggleLinkPartnerModal}
              toggleAddFreePartner={this.props.toggleLinkFreePartnerModal}
              toggleChangeFreePartner={
                this.props.toggleChangeLinkFreePartnerModal
              }
              toggleAddFreeChildPartner={
                this.props.toggleLinkFreeChildPartnerModal
              }
              saveAnswers={this.props.saveAnswers}
              partnerCreated={this.props.partnerCreated}
              idDocument={this.props.idDocument}
              cleanDocument={this.props.cleanDocument}
              sendScannedFile={this.props.sendScannedFile}
              sendEmailFile={this.props.sendEmailFile}
              recreateFile={this.props.recreateFile}
              getDocumentForSign={this.props.getDocumentForSign}
              saveDocumentSignature={this.props.saveDocumentSignature}
              getMembershipMembers={this.props.getMembershipMembers}
              membershipTypesMembers={this.props.membershipTypesMembers}
              partnerDocuments={this.props.partnerDocuments}
              getPartnerDocuments={this.props.getPartnerDocuments}
              updatePhoto={this.props.updatePhoto}
              updatePawPrint={this.props.updatePawPrint}
              accessRegister={this.props.accessRegister}
              partnersMembership={
                this.state.membershipPartners || this.props.partnersMembership
              }
              deleteBankCard={this.props.deleteBankCard}
              toggleDeletePartner={this.props.toggleDeletePartner}
              getQuotations={this.props.getQuotations}
              getMembershipsTypes={this.props.getMembershipsTypes}
              getMemberhips={this.props.getMemberhips}
              getMembershipById={this.props.getMembershipById}
              getMaintenances={this.props.getMaintenances}
              getPromotions={this.props.getPromotions}
              getEmpresasActivas={this.props.getEmpresasActivas}
              quotations={this.props.quotations}
              membershipById={this.props.membershipById}
              promotions={this.props.promotions}
              maintenances={this.props.maintenances}
              agreements={this.props.agreements}
              membershipsTypes={this.props.membershipsTypes}
              updateTitular={this.props.updateTitular}
              membresiaSocioId={this.props.membresiaSocioId}
              sendMovementEmail={this.props.sendMovementEmail}
              toggleBill={this.props.toggleBill}
              toggleBillXml={this.props.toggleBillXml}
              createMaintenanceDiscount={this.props.createMaintenanceDiscount}
              rightsChange={this.props.rightsChange}
              successfullSwitchMembership={
                this.props.successfullSwitchMembership
              }
              products={this.props.products}
              historicalRebilling={this.props.historicalRebilling}
              listHistoricalRebilling={this.props.listHistoricalRebilling}
              listHistoryAutCharges={this.props.listHistoryAutCharges}
              users={this.state.users}
              executePartnerMembershipPartners={
                this.executePartnerMembershipPartners
              }
              userContext={this.props.userContext}
            />
          )}
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  historicalRebilling: (ventaId: string) => {
    dispatch(reportsActions.historicalRebilling(ventaId));
  },
  getBankPayTypes: () => {
    dispatch(catalogActions.getBankPayTypes());
  },
  getPartner: (params: object) => {
    return dispatch(getPartner(params));
  },
  getPartnersMembership: (
    idMembresiaSocio: string,
    idMantenimiento: string,
    idMembresia: string
  ) => {
    if (!!idMembresiaSocio || !!idMantenimiento || !!idMembresia) {
      dispatch(
        getPartnersMembership(idMembresiaSocio, idMantenimiento, idMembresia)
      );
    }
  },
  cleanDocument: () => {
    dispatch(cleanDocument());
  },
  getDocument: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string
  ) => {
    dispatch(getDocument(idTipoDocumento, idMembresiaSocio, idPersona));
  },
  getDocumentForSign: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string
  ) => {
    return dispatch(
      getDocumentForSign(idTipoDocumento, idMembresiaSocio, idPersona)
    );
  },
  printDocument: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string
  ) => {
    dispatch(printDocument(idTipoDocumento, idMembresiaSocio, idPersona));
  },
  toggleLinkPartnerModal: () => {
    dispatch(toggleLinkPartnerModal());
  },
  toggleLinkFreePartnerModal: () => {
    dispatch(toggleLinkFreePartnerModal());
  },
  toggleChangeLinkFreePartnerModal: (data: any) => {
    dispatch(toggleChangeLinkFreePartnerModal(data));
  },
  toggleLinkFreeChildPartnerModal: () => {
    dispatch(toggleLinkFreeChildPartnerModal());
  },
  saveAnswers: (body: object) => {
    dispatch(saveAnswers(body));
  },
  sendScannedFile: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string,
    file: Blob,
    name: string
  ) => {
    return dispatch(
      sendScannedFile(idTipoDocumento, idMembresiaSocio, idPersona, file, name)
    );
  },
  sendEmailFile: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string
  ) => {
    dispatch(sendEmailFile(idTipoDocumento, idMembresiaSocio, idPersona));
  },
  recreateFile: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string
  ) => {
    return dispatch(recreateFile(idTipoDocumento, idMembresiaSocio, idPersona));
  },
  saveDocumentSignature: (
    idTipoDocumento: string,
    idMembresiaSocio: string,
    idPersona: string,
    signature: string
  ) => {
    return dispatch(
      saveDocumentSignature(
        idTipoDocumento,
        idMembresiaSocio,
        idPersona,
        signature
      )
    );
  },
  getMembershipMembers: (membresiaId: string) => {
    dispatch(getMembershipMembers(membresiaId));
  },
  getPartnerDocuments: (idMembresiaSocio: string, idPersona) => {
    dispatch(getParnerDocuments(idMembresiaSocio, idPersona));
  },
  updatePawPrint: (
    idMantenimiento: string,
    idMembresia: string,
    idMembresiaSocio: string,
    idPersona: string,
    pawPrint: string
  ) => {
    dispatch(
      updatePawPrint(
        idMantenimiento,
        idMembresia,
        idMembresiaSocio,
        idPersona,
        pawPrint
      )
    );
  },
  updatePhoto: (
    idMantenimiento: string,
    idMembresia: string,
    idMembresiaSocio: string,
    idPersona: string,
    photo: Blob
  ) => {
    dispatch(
      updatePhoto(
        idMantenimiento,
        idMembresia,
        idMembresiaSocio,
        idPersona,
        photo
      )
    );
  },
  accessRegister: (idPersona: string) => {
    dispatch(accessRegister(idPersona));
  },
  deleteBankCard: (id: string, reason: string) => {
    return dispatch(deleteBankCard(id, reason));
  },
  toggleDeletePartner: (partner?: object, idMembresiaSocio?: string) => {
    dispatch(toggleDeletePartner(partner, idMembresiaSocio));
  },
  getMemberships: () => {
    dispatch(getMemberships());
  },

  getMembershipById: (id: string) => {
    dispatch(getMembershipById(id));
  },
  getQuotations: (personaId: string, args: object, integrantes: object) => {
    dispatch(getQuotations(personaId, args, integrantes));
  },
  getPromotions: (attr: QueryArguments) => {
    dispatch(getPromotionsFiltered(attr));
  },
  getMembershipsTypes: (clubId: string) => {
    dispatch(catalogActions.getMembershipsTypesByClub(clubId));
  },
  getMaintenances: (membresiaId: string) => {
    dispatch(catalogActions.getMaintenancesByMembershipId(membresiaId));
  },
  getEmpresasActivas: attributes => {
    dispatch(getEmpresasActivas(attributes.clubId, attributes.mantenimientoId));
  },
  getMemberhips: maintenanceId => {
    dispatch(getMembershipsMaintenance(maintenanceId));
  },
  updateTitular: (data: object, notificacionId: string) => {
    dispatch(updateTitular(data, notificacionId));
  },
  sendMovementEmail: (idMovimiento: number, email: string) => {
    dispatch(sendMovementEmail(idMovimiento, email));
  },
  toggleBill: (idDocumento: number) => {
    dispatch(showDocument(idDocumento));
  },
  toggleBillXml: (uuid: string) => {
    dispatch(billingActions.getBillXml(uuid));
  },

  rightsChange: (idMembresiaSocio: string, idPersona: string) => {
    return dispatch(rightsChange(idMembresiaSocio, idPersona));
  },
  membershipPurchase: (membershipClient: object) => {
    dispatch(prospectsActions.membershipPurchase(membershipClient));
  },
  createMaintenanceDiscount: (data: object) => {
    return dispatch(createMaintenanceDiscount(data));
  },
  clearIngressByPartnerReport: () => {
    dispatch(clearReportPersonIngressByPartner());
  },
  getWaysPay: () => {
    dispatch(catalogActions.getWaysPay());
  }
});

const mapStateToProps = ({
  catalogs,
  partners,
  promotions,
  products,
  convenios,
  prospects,
  reports,
  user
}) => {
  return {
    listHistoricalRebilling: reports.listHistoricalRebilling,
    partner: partners.partner,
    partnerCreated: partners.partnerCreated,
    idDocument: partners.idDocument,
    partnerDocuments: partners.partnerDocuments,
    partnersMembership: partners.partnersMembership,
    loadingPartnersMembership: partners.loadingPartnersMembership,
    membershipsTypes: catalogs.membershipsTypes,
    maintenances: catalogs.maintenances,
    promotions: promotions.promotionsFiltered,
    membershipById: products.membershipById,
    agreements: convenios.empresasActivas,
    quotations: prospects.quotations,
    successfullSwitchMembership: partners.successfullSwitchMembership,
    products,
    membershipTypesMembers: products.membershipTypesMembers,
    listHistoryAutCharges: products.listHistoryAutCharges,
    userContext: user.userContext
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnersPage);
