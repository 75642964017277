import React from "react";
import IInputBuilderProps from "./IInputBuilder.props";
import IInputBuilderState from "./IInputBuilder.state";

export default class IInputBuilder<
  INP extends string,
  P extends IInputBuilderProps<INP>,
  S extends IInputBuilderState
> extends React.Component<P, S> {
  public static get whoIAm() {
    return "IInputBuilder";
  }

  render() {
    return <></>;
  }
}
