import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import ProspectsReport from "../../components/prospectsReport";

interface Props {
  match: any;
  history: any;
  userContext: any;
}

interface State {
  products: object;
}

class ProspectsReportPage extends React.Component<Props, State> {
  state = { products: { content: [], totalPages: 0 } };

  render() {
    return (
      <main>
        <Helmet title="Products" />
        <Layout>
          <ProspectsReport {...this.props} />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = state => {
  return {
    userContext: state.user.userContext
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProspectsReportPage);
