import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { FormText, Label, Row, Col, Container, FormGroup } from "reactstrap";
import ModalFrame from "../../../../toolkit/modalFrame";
import ReactTable from "react-table";
import isNil from "lodash.isnil";
import moment from "moment";
import { BFF_URL, IMAGES_ENDPOINT } from "../../../../utils/constants";

interface Props {
  isOpen: boolean;
  toggle: () => any;
  personById: object;
}

interface State {}

class ModalResumenEmployed extends React.Component<Props, State> {
  state = {};

  onExit = () => {
    this.props.toggle();
  };

  render() {
    const data = !isNil(this.props.personById) ? this.props.personById : {};
    const emails =
      !isNil(this.props.personById) &&
      this.props.personById.hasOwnProperty("correosElectronicos")
        ? this.props.personById.correosElectronicos
        : [];
    const direcciones =
      !isNil(this.props.personById) &&
      this.props.personById.hasOwnProperty("direcciones")
        ? this.props.personById.direcciones
        : [];
    const columns = [
      {
        Header: "Tipo Mail",
        accessor: "tipo",
        className: "text-center",
        width: 200
      },
      {
        Header: "Mail",
        accessor: "correo",
        className: "text-center",
        width: 400
      },
      {
        Header: "Bloqueo",
        accessor: "bloqueoPublicidad",
        width: 200,
        className: "text-center",
        Cell: row => {
          return (
            <div className="text-wrap">
              {row.original.bloqueoPublicidad ? "ACTIVADO" : "DESACTIVADO"}
            </div>
          );
        }
      }
    ];

    return (
      <Container className="p-0">
        <ModalFrame
          title={`Detalles persona`}
          isOpen={this.props.isOpen}
          toggle={this.onExit}
          size="lg"
        >
          <Row className="primaryTitle m-0">
            <Col xs="10">
              <p className="m-0 mb-2">
                {`Datos de la persona (${
                  data.hasOwnProperty("personaId") ? data.personaId : null
                })`}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <Row>
                <Col xs={4} md={4} lg={4}>
                  <FormGroup>
                    <Label>Nombre:</Label>
                    <FormText className="font-weight-bold" color="black">
                      {data.hasOwnProperty("nombre") ? data.nombre : null}
                    </FormText>
                  </FormGroup>
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <FormGroup>
                    <Label>Paterno:</Label>
                    <FormText className="font-weight-bold" color="black">
                      {data.hasOwnProperty("primerApellido")
                        ? data.primerApellido
                        : null}
                    </FormText>
                  </FormGroup>
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <FormGroup>
                    <Label>Materno:</Label>
                    <FormText className="font-weight-bold" color="black">
                      {data.hasOwnProperty("segundoApellido")
                        ? data.segundoApellido
                        : null}
                    </FormText>
                  </FormGroup>
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <FormGroup>
                    <Label>F. Nacimiento:</Label>
                    <FormText className="font-weight-bold" color="black">
                      {data.hasOwnProperty("fechaNacimiento")
                        ? data.fechaNacimiento
                        : null}
                    </FormText>
                  </FormGroup>
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <FormGroup>
                    <Label>Edo. Civil:</Label>
                    <FormText className="font-weight-bold" color="black">
                      {data.hasOwnProperty("estadoCivil")
                        ? data.estadoCivil
                        : null}
                    </FormText>
                  </FormGroup>
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <FormGroup>
                    <Label>Ent. Nac:</Label>
                    <FormText className="font-weight-bold" color="black">
                      {data.hasOwnProperty("entidadNacimiento")
                        ? data.entidadNacimiento
                        : null}
                    </FormText>
                  </FormGroup>
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <FormGroup>
                    <Label>Titulo:</Label>
                    <FormText className="font-weight-bold" color="black">
                      {data.hasOwnProperty("titulo") ? data.titulo : null}
                    </FormText>
                  </FormGroup>
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <FormGroup>
                    <Label>Profesion:</Label>
                    <FormText className="font-weight-bold" color="black">
                      {data.hasOwnProperty("profesion") ? data.profesion : null}
                    </FormText>
                  </FormGroup>
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <FormGroup>
                    <Label>Sexo:</Label>
                    <FormText className="font-weight-bold" color="black">
                      {data.hasOwnProperty("sexo") ? data.sexo : null}
                    </FormText>
                  </FormGroup>
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <FormGroup>
                    <Label>Tipo:</Label>
                    <FormText className="font-weight-bold" color="black">
                      {direcciones.length > 0
                        ? direcciones[0].tipoPersonaFiscal
                        : null}
                    </FormText>
                  </FormGroup>
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <FormGroup>
                    <Label>Bloqueo Mail:</Label>
                    <FormText className="font-weight-bold" color="black">
                      {emails.length > 0
                        ? emails[0].bloqueoPublicidad
                          ? "ACTIVADO"
                          : "DESACTIVADO"
                        : null}
                    </FormText>
                  </FormGroup>
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <FormGroup>
                    <Label>Edad:</Label>
                    <FormText className="font-weight-bold" color="black">
                      {data.hasOwnProperty("fechaNacimiento")
                        ? moment().diff(data.fechaNacimiento, "years", false)
                        : null}
                    </FormText>
                  </FormGroup>
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <FormGroup>
                    <Label>Escolaridad:</Label>
                    <FormText className="font-weight-bold" color="black">
                      {data.hasOwnProperty("Escolaridad")
                        ? data.Escolaridad
                        : null}
                    </FormText>
                  </FormGroup>
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <FormGroup>
                    <Label>Ingresos:</Label>
                    <FormText className="font-weight-bold" color="black">
                      {data.hasOwnProperty("Ingresos") ? data.Ingresos : null}
                    </FormText>
                  </FormGroup>
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <FormGroup>
                    <Label>Hijos:</Label>
                    <FormText className="font-weight-bold" color="black">
                      {data.hasOwnProperty("Hijos") ? data.Hijos : null}
                    </FormText>
                  </FormGroup>
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <FormGroup>
                    <Label>RFC:</Label>
                    <FormText className="font-weight-bold" color="black">
                      {direcciones.length > 0 ? direcciones[0].rfc : null}
                    </FormText>
                  </FormGroup>
                </Col>
                <Col xs={4} md={4} lg={4}>
                  <FormGroup>
                    <Label>Usuario web:</Label>
                    <FormText className="font-weight-bold" color="black">
                      {data.hasOwnProperty("userWeb") ? data.userWeb : null}
                    </FormText>
                  </FormGroup>
                </Col>
                <Col />
              </Row>
            </Col>
            <Col md={4}>
              {!!data.fotoStorageId && (
                <img
                  style={{
                    display: "block",
                    maxWidth: "400px",
                    maxHeight: "165px",
                    width: "auto",
                    height: "auto"
                  }}
                  src={`${BFF_URL}${IMAGES_ENDPOINT}/${data.fotoStorageId}`}
                  alt=""
                />
              )}
              {!data.fotoStorageId && <span>SIN FOTO</span>}
            </Col>
          </Row>

          <Row className="primaryTitle m-0">
            <Col xs="10">
              <p className="m-0 mb-2">{`Mail(s)`}</p>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col />
            <ReactTable
              data={emails}
              pageSize={emails.length || 3}
              columns={columns}
              sortable={false}
              resizable={false}
              showPagination={false}
              className="-highlight -striped"
            />
            <Col />
          </Row>
        </ModalFrame>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ModalResumenEmployed));
