import {
  GetEmpresasConvenio,
  UpdateEmpresaConvenio
} from "../../reducers/empresaConvenio";

export interface EmpresaConvenioStore
  extends GetEmpresasConvenio,
    UpdateEmpresaConvenio {}

export const empresaConvenioStore: (state) => EmpresaConvenioStore = state => {
  const { empresasConvenio } = state;
  return {
    empresasConvenio: empresasConvenio.empresasConvenio,
    gettingEmpresasConvenio: empresasConvenio.gettingEmpresasConvenio,
    gettingEmpresasConvenioError: empresasConvenio.gettingEmpresasConvenioError,
    empresaConvenioUpdated: empresasConvenio.empresaConvenioUpdated,
    updatingEmpresaConvenio: empresasConvenio.updatingEmpresaConvenio,
    updatingEmpresaConvenioError: empresasConvenio.updatingEmpresaConvenioError
  };
};
