import React from "react";
import BaseInput from "../../../toolkit/baseInput";

import { FORMA_DE_PAGO } from "../../../utils/constants";

const getFormaDePagoOptions = () => {
  const options = [];
  for (const descuento in FORMA_DE_PAGO) {
    if (FORMA_DE_PAGO.hasOwnProperty(descuento)) {
      const formaDePagoOption = FORMA_DE_PAGO[descuento];
      options.push({
        label: descuento,
        value: formaDePagoOption
      });
    }
  }
  return options;
};

export const FormaPagoSelector = ({
  value,
  disabled,
  onChange,
  size = "sm"
}) => (
  <BaseInput
    label="Forma de Pago"
    name="formaDePagoSelector"
    type="select"
    id="formaDePagoSelector"
    placeholder="Forma de Pago"
    value={value}
    size={size}
    disabled={disabled}
    onChange={e => {
      const value = e.target.value;
      return onChange(value);
    }}
    options={getFormaDePagoOptions()}
  />
);
