import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Users from "../../components/users";
import * as usersActions from "../../actions/users";
import { QueryResponse } from "../../../types/QueryResponse";
import { QueryArguments } from "../../../types-business/QueryArguments";
import { getPositions, getRoles } from "../../actions/catalogs";

interface Props {
  users: QueryResponse<User>;
  getUsers: (args?: QueryArguments) => any;
  searching: boolean;
  addUser: (user?) => any;
  history: any;
  match: any;
  getPositions: any;
  getRoles: any;
  positions: any;
  roles: any;
}

interface User {
  id: string;
  name: string;
  username: string;
}

class UsersPage extends React.Component<Props> {
  componentDidMount() {
    this.props.getPositions();
    this.props.getRoles();
  }

  onFetchData = ({ pageSize: size, page }) => {
    this.props.getUsers({
      size,
      page
    });
  };

  goTo = destiny => {
    this.props.history.push(destiny);
  };

  render() {
    return (
      <main>
        <Helmet title="Users" />
        <Layout>
          <Users
            users={this.props.users}
            getUsers={this.props.getUsers}
            searching={this.props.searching}
            positions={this.props.positions}
            roles={this.props.roles}
            onClickNewUser={user =>
              this.goTo(`/usuarios/datos-basicos/${user ? user.userId : ""}`)
            }
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getUsers: (args?: QueryArguments) => {
    dispatch(usersActions.getUsers(args));
  },
  getPositions: () => {
    dispatch(getPositions());
  },
  getRoles: () => {
    dispatch(getRoles());
  }
});

const mapStateToProps = state => {
  const { catalogs, user } = state;
  return {
    users: user.usersPage || { data: [], pageSize: 0 },
    searching: user.gettingUsers,
    userContext: state.user.userContext,
    positions: catalogs.catalogPostions,
    roles: catalogs.roles
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersPage);
