import React from "react";
import { FormGroup, Label } from "reactstrap";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./selectFilter.scss";
import Select from "react-select";

interface Props {
  type: string;
  name: string;
  id: string;
  placeholder?: string;
  label?: string;
  value: string | number | Date;
  dataQAId: string;
  dataElmId: string;
  onChange: (string: string) => void;
  errors: Array<string>;
  isMulti: boolean;
  disabled: boolean;
  options: Array<{
    label: string;
    value: string | number;
  }>;
  isAsync: boolean;
  handleInputChange: (nombre: string) => void;
  loading: boolean;
  onDropdownOpened: () => void;
}

class SelectFilter extends React.Component<Props> {
  state = {
    inputValue: ""
  };
  render() {
    const {
      id,
      name,
      value,
      label,
      placeholder,
      children,
      options,
      errors,
      onChange,
      disabled,
      isMulti,
      loading,
      isAsync,
      handleInputChange,
      onDropdownOpened
    } = this.props;

    const customStyles = {
      control: (base, state) => {
        const newBase = {
          ...base,
          minHeight: null,
          height: "2rem !important",
          fontSize: "0.8rem",
          padding: "0px 0px"
        };
        if (state.isFocused) {
          newBase.border = "1px solid #0074c2";
          newBase.boxShadow =
            "inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(0, 116, 194, 0.6)";
        }
        return newBase;
      },
      option: base => {
        const newBase = { ...base };
        newBase.padding = "2px 8px";
        newBase.fontSize = "0.8rem";
        return newBase;
      },
      menu: base => {
        const newBase = {
          ...base,
          margin: 0,
          paddingTop: 0,
          paddingBottom: 0,
          marginTop: 0,
          marginBottom: 0,
          padding: "0px 0px"
        };
        return newBase;
      },
      input: base => {
        return { ...base, padding: "0px 0px" };
      },
      valueContainer: base => {
        return { ...base, padding: "2px 8px", position: null };
      },
      indicatorsContainer: base => {
        return {
          ...base,
          padding: "0px 0px",
          position: null
        };
      },
      multiValue: base => {
        return {
          ...base,
          padding: "0px 0px",
          margin: "2px 3px 2px 0px",
          position: null
        };
      }
    };

    const handleChange = value => {
      onChange(value);
      this.setState(state => {
        return {
          ...state,
          inputValue: ""
        };
      });
    };

    const handleMenuOpened = () => {
      if (onDropdownOpened) {
        onDropdownOpened();
      }
    };

    const optionsMessage = () =>
      isAsync && this.state.inputValue.length === 0 && value === ""
        ? "Escribe para realizar una busqueda"
        : "No se encontraron coincidencias";

    const onInputChange = (inputValue, { action }) => {
      this.setState(state => {
        return {
          ...state,
          inputValue
        };
      });

      if (isAsync) {
        switch (action) {
          case "input-change":
            handleInputChange(inputValue);
            break;
        }
      }
    };

    return (
      <FormGroup className={s.formGroup}>
        {label && (
          <Label className={s.label} for={id}>
            {label}:
          </Label>
        )}
        <Select
          name={name}
          placeholder={placeholder}
          value={value}
          styles={customStyles}
          isDisabled={disabled || false}
          onChange={handleChange}
          options={options || []}
          noOptionsMessage={optionsMessage}
          isMulti={isMulti}
          isLoading={loading}
          onInputChange={onInputChange}
          loadingMessage={_ => "Cargando ..."}
          inputValue={this.state.inputValue}
          onMenuOpen={handleMenuOpened}
        />
        {children}
        {errors &&
          errors.map((message, index) => {
            return (
              <span key={index} className={s.errorSpan}>
                {message}
                <br />
              </span>
            );
          })}
      </FormGroup>
    );
  }
}

export default withStyles(s)(SelectFilter);
