import { Preview } from "../../../types/IAction";
import * as actions from "../../actions/invitados";
import {
  GetInvitado,
  CreateInvitado,
  UpdateInvitado
} from "../../reducers/invitados";
import * as actionsConvenios from "../../actions/convenios";
import { ConvenioClubesAsignables } from "../../reducers/convenios";

export interface InvitadosDispatcher {
  clearInvitado: () => Promise<Preview<void>>;
  getInvitado: (idInvitado: any) => Promise<Preview<GetInvitado>>;
  postInvitado: (invitado: any) => Promise<Preview<CreateInvitado>>;
  putInvitado: (
    idInvitado: any,
    invitado: any
  ) => Promise<Preview<UpdateInvitado>>;
  getClubesAsignables: () => Promise<Preview<ConvenioClubesAsignables>>;
}

export const invitadosDispatcher: (
  dispatch: (...args) => Promise<Preview<any>>
) => InvitadosDispatcher = dispatch => ({
  clearInvitado: () => {
    return dispatch(actions.clearInvitado());
  },
  getInvitado: (idInvitado: any) => {
    return dispatch(actions.getInvitado(idInvitado));
  },
  postInvitado: (invitado: any) => {
    return dispatch(actions.postInvitado(invitado));
  },
  putInvitado: (idInvitado: any, invitado: any) => {
    return dispatch(actions.putInvitado(idInvitado, invitado));
  },
  getClubesAsignables: () => {
    return dispatch(actionsConvenios.getClubesAsignables());
  }
});
