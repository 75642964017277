import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import DatePicker from "../common/DatePicker";
import moment from "moment";

import ProductClassification from "../../containers/productClassification";
import { Container, Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import ReactTable from "react-table";
import CONSTANTS from "../../../utils/constants";

import s from "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

interface Props {
  clubs: any;
  searching: boolean;
  onClickProduct: (product) => any;
  fetchData: () => void;
  history: any;
  match: any;
  filters: Filters;
  onChangeFilter: any;
  getEvents: (body: object) => any;
  events: object[];
  userContext: any;
}

interface Filters {
  clasificacionId: string;
}

const START_DATE_FIELDNAME = "fechaInicio";
const END_DATE_FIELDNAME = "fechaFin";

interface State {
  selectedClub: string;
  fields: { fechaInicio: string; fechaFin: string };
}

const getCleanFields = () => ({
  fechaInicio: moment().format("YYYY-MM-DD"),
  fechaFin: moment().format("YYYY-MM-DD")
});

class SoldProducts extends React.Component<Props, State> {
  state = {
    selectedClub: null,
    fields: { fechaInicio: null, fechaFin: null }
  };

  search = () => {
    this.props.getEvents({
      clubId: this.state.selectedClub,
      fechaInicio: this.state.fields.fechaInicio,
      fechaFin: this.state.fields.fechaFin,
      clasificacionId: this.props.filters.clasificacionId
    });
  };

  handleClubSelection = e => {
    this.setState({ selectedClub: e.target.value });
  };

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: date
        };
        return { ...state, error: {}, fields };
      });
    };
  };

  componentDidMount = () => {
    this.setState({
      selectedClub:
        (this.props.userContext && this.props.userContext.clubSessionId) || null
    });
  };

  render() {
    const columns = [
      { Header: "Club", accessor: "nombreClub", className: "text-center" },
      { Header: "Evento", accessor: "nombreEvento" },
      {
        Header: "Fecha Inicio",
        accessor: "fechaInicio",
        className: "text-center"
      },
      {
        Header: "",
        id: "buttons",
        accessor: item => {
          return (
            <a
              id={`irA${item.productoId}`}
              onClick={() => this.props.onClickProduct(item)}
            >
              <UncontrolledTooltip
                placement="left"
                target={`irA${item.productoId}`}
              >
                Ir a ventas del producto por club
              </UncontrolledTooltip>
              <FontAwesomeIcon icon={faLink} />
            </a>
          );
        }
      }
    ];
    return (
      <Container className="p-0 mt-3">
        <Row>
          <Col>
            <BaseInput
              label="Club"
              name="club"
              type="select"
              id="club"
              placeholder="Tipo"
              value={this.state.selectedClub}
              disabled={
                this.props.userContext && this.props.userContext.clubSessionId
              }
              options={
                this.props.clubs &&
                [{ value: 0, label: "Seleccionar Club" }].concat(
                  this.props.clubs.map(e => {
                    return { value: e.clubId, label: e.nombre };
                  })
                )
              }
              onChange={this.handleClubSelection}
            />
          </Col>
          <Col style={{ paddingBottom: "5px" }} xs={12} md={3} lg={3}>
            <DatePicker
              label="Fecha Venta (Desde)"
              name={START_DATE_FIELDNAME}
              id={START_DATE_FIELDNAME}
              selected={this.state.fields[START_DATE_FIELDNAME]}
              onChange={this.onChangeDate(START_DATE_FIELDNAME)}
            />
          </Col>
          <Col xs={12} md={3} lg={3}>
            <DatePicker
              label="Fecha Venta (Hasta)"
              name={END_DATE_FIELDNAME}
              id={END_DATE_FIELDNAME}
              selected={this.state.fields[END_DATE_FIELDNAME]}
              onChange={this.onChangeDate(END_DATE_FIELDNAME)}
            />
          </Col>
          <Col style={{ paddingBottom: "5px" }} xs={12} md={12} lg={10}>
            <ProductClassification
              setResult={clasificacionId =>
                this.props.onChangeFilter(clasificacionId, "clasificacionId")
              }
              result={this.props.filters.clasificacionId}
            ></ProductClassification>
          </Col>
          <Col xs={12} md={12} lg={2} className="mt-4">
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.search}
              disabled={!this.state.selectedClub}
            >
              Buscar
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            {this.props.events && (
              <ReactTable
                manual
                data={this.props.events}
                columns={columns}
                className="w-100"
                defaultPageSize={CONSTANTS.DEFAULT_PAGE_SIZE}
                loading={this.props.searching}
                sortable={false}
                style={{ height: CONSTANTS.TABLES_HEIGHT }}
              />
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(SoldProducts));
