import React from "react";
import s from "./styles.scss";
import { Container } from "reactstrap";
import { Styled } from "../decorators/Artifact";

interface Props {
  // Paso en el que se encuentra el carrusel
  step?: number;

  // Listener que se dispara al cambiar de paso con los botones del carrusel; si existe
  onChangeStep?: (stepNumber: number) => boolean;

  // Indica si el carrusel permite el cambio de pasos
  active?: boolean;
}

interface State {
  // Pasos definidos en el carrusel, se determinan por los childs
  steps: {
    name: string;
  }[];
  element?: React.ReactElement;
  ownStep: number;

  // Indica si la información del carrusel no es suficiente
  useError?: boolean;
  useErrorMsg: string;
}

@Styled(s)
export default class Carousel2 extends React.Component<Props, State> {
  state = {
    steps: [],
    element: null,
    useError: null,
    useErrorMsg: "",
    ownStep: 0
  };

  componentDidMount() {
    // Revisar que los hijos existan
    if (!this.props.children) {
      this.setState({
        useError: true,
        useErrorMsg:
          "Debe especificar hijos en Carousel2:  <Carousel2>...</Carousel2>"
      });

      return;
    }

    // Revisar que los hijos sean un conjunto de nodos
    if (
      !isReactNodeArray(this.props.children) ||
      this.props.children.length === 0
    ) {
      this.setState({
        useError: true,
        useErrorMsg:
          "Debe incluir componentes como hijos en Carousel2, no se admiten cadenas u otros valores: <Carousel2>...</Carousel2>"
      });

      return;
    }

    // Se verifica que los componentes incluyan el nombre
    for (let node of this.props.children) {
      if (!isReactElement(node) || !node.props["data-carousel-name"]) {
        this.setState({
          useError: true,
          useErrorMsg:
            'Los componentes en Carousel2 deben especificar su nombre: <Carousel2>... <Component data-carousel-name="Nombre" /> ...</Carousel2>'
        });

        return;
      }
    }

    // Se validan los pasos, antes de marcar como correcto el estado del carrusel
    this.validateSteps();
  }

  componentDidUpdate(prevProps) {
    if (this.props.step != prevProps.step) {
      this.validateSteps();
    }

    // 2 hours line doc!
    if (this.props.children != prevProps.children) {
      this.setState({
        element: this.props.children[
          this.state.ownStep - 1
        ] as React.ReactElement
      });
    }
  }

  validateSteps() {
    if (
      !isReactNodeArray(this.props.children) ||
      this.props.children.length === 0
    ) {
      return;
    }

    const step =
      !this.props.step && this.props.step !== 0 ? 1 : this.props.step;
    if (step < 1 || step > this.props.children.length) {
      this.setState({
        useError: true,
        useErrorMsg:
          "Accedió a un paso inexistente en el Carrusel, por favor, comuníquese con sistemas."
      });
    }

    // Hasta este punto se habilita el carrusel y el componente que tendrá
    this.setState({
      useError: false,
      element: this.props.children[step - 1] as React.ReactElement,
      ownStep: step
    });
  }

  setStep = (ownStep: number) => {
    if (this.props.active === false) {
      return false;
    }

    if (
      !isReactNodeArray(this.props.children) ||
      this.props.children.length === 0
    ) {
      return false;
    }

    if (this.props.onChangeStep && this.props.onChangeStep(ownStep)) {
      return false;
    }

    this.setState({
      element: this.props.children[ownStep - 1] as React.ReactElement,
      ownStep
    });
    return true;
  };

  renderSteps() {
    if (
      !isReactNodeArray(this.props.children) ||
      this.props.children.length === 0
    ) {
      return <></>;
    }

    const nodes: React.ReactElement[] = [];
    const length = this.props.children.length;
    for (let nodeIndex in this.props.children) {
      const node = this.props.children[nodeIndex] as React.ReactElement;
      const step = Number(nodeIndex) + 1;

      nodes.push(
        <div key={`carousel-step-${step}`} className={s.step}>
          <div
            className={`${s.line} ${step === 1 ? s.lineRight : ""} ${
              step === length ? s.lineLeft : ""
            }`}
          ></div>
          <div
            className={`${s.number} ${
              this.state.ownStep === step
                ? s.numberActive
                : this.props.active === false
                ? s.numberDisabled
                : s.numberEnabled
            }`}
            onClick={() => this.setStep(step)}
          >
            {step}
          </div>
          <div className={s.name}>
            <span
              className={`${
                this.props.active === false || this.state.ownStep === step
                  ? ""
                  : s.active
              }`}
              onClick={() => this.setStep(step)}
            >
              {node.props["data-carousel-name"]}
            </span>
          </div>
        </div>
      );
    }

    return nodes;
  }

  render() {
    if (this.state.useError === null) {
      return <></>;
    }

    if (this.state.useError === true) {
      return (
        <Container>
          <div
            className="alert alert-danger h-100 w-100 mt-3"
            style={{ textAlign: "center" }}
            role="alert"
          >
            <p>ERROR DE DESARROLLO</p>
            <p>{this.state.useErrorMsg}</p>
          </div>
        </Container>
      );
    }

    return (
      <div className={`container ${s.withoutPadding}`}>
        <div className={s.carousel}>
          <div className={s.stepper}>{this.renderSteps()}</div>
          <div className={s.content}>{this.state.element}</div>
        </div>
      </div>
    );
  }
}

function isReactNodeArray(
  children: Array<React.ReactNode> | any
): children is Array<React.ReactNode> {
  return Array.isArray(children);
}

function isReactElement(
  node: React.ReactElement | any
): node is React.ReactElement {
  return node != null && node.props ? true : false;
}
