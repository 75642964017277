export const convenios = [
  {
    id: 1,
    nombre: "Convenio 10%",
    descripcion: "Convenio aplicable a la mayoría de las empresas",
    estatus: "ACTIVO",
    hasError: false,
    error: "No ha configurado los descuentos de ciertas membresías",
    empresas: 841,
    mantenimientoTipoBeneficio: "PORCENTAJE",
    mantenimientoValorBeneficio: 10
  }
];

export const convenio = {
  id: 1,
  nombre: "Convenio 10%",
  descripcion: "Convenio aplicable a la mayoría de las empresas",
  estatus: "ACTIVO",
  hasError: false,
  error: "No ha configurado los descuentos de ciertas membresías",
  mantenimientoTipoBeneficio: "PORCENTAJE",
  mantenimientoValorBeneficio: 10,
  empresas: [
    114304,
    2044,
    126728,
    4648056,
    19512376,
    2638,
    2617,
    2259,
    2119,
    2595,
    2088,
    3276,
    3999,
    3680,
    2445,
    2942,
    3041,
    1896,
    4000,
    3690,
    1874,
    3176,
    2536,
    1852,
    2208,
    2327,
    3194,
    2364,
    1862,
    2125,
    2570,
    2100,
    3511,
    3532
  ]
};

export const clubsAsc = [75, 112, 106];
export const mttoAsc = [
  5048,
  5049,
  10005050,
  10005053,
  10005056,
  10005057,
  10005058,
  10005059,
  10005060,
  10005061,
  10005062,
  10005063,
  10005064,
  10005066,
  10005067,
  10005068,
  10005069,
  10005071,
  10005072,
  10005073,
  10005074,
  10005075,
  10005076,
  10005077,
  10005078,
  10005079,
  10005080,
  10005081,
  10005082,
  10005083,
  10005084,
  10005086,
  10005087,
  10005088,
  10005089,
  10005090,
  10005091,
  10005092,
  10005093,
  10005094,
  10005095,
  10005096,
  10005097,
  10005098,
  10005099,
  10006002,
  10006005
];
//export const membAsc = [{1, 7, 510];

export const membAsc = [
  {
    id: 1,
    membresia_tipo_beneficio: "PRECIOFIJO",
    membresia_valor_beneficio: 4700,
    mantenimiento_tipo_beneficio: "PORCENTAJE",
    mantenimiento_valor_beneficio: 10
  },
  {
    id: 7,
    membresia_tipo_beneficio: "PRECIOFIJO",
    membresia_valor_beneficio: 4700,
    mantenimiento_tipo_beneficio: "PORCENTAJE",
    mantenimiento_valor_beneficio: 10
  },
  {
    id: 5010,
    membresia_tipo_beneficio: "PRECIOFIJO",
    membresia_valor_beneficio: 4700,
    mantenimiento_tipo_beneficio: "PORCENTAJE",
    mantenimiento_valor_beneficio: 10
  }
];

export const descuento = {
  id: 19512408,
  numeroDeIntegrantes: 1,
  tipoBeneficioMembresia: "PRECIOFIJO",
  valorBeneficioMembresia: 4700,
  aplicaProporcional: true,
  mesRelativo: true,
  meses: [
    {
      mes: 3,
      tipoBeneficioMes: "PORCENTAJE",
      esUltimoMesRecurrente: true,
      beneficios: [
        {
          integrante: 1,
          valor: 10
        },
        {
          integrante: 2,
          valor: 0
        }
      ]
    }
  ],
  club: "general",
  membresia: "1"
};

export const membresias = [
  { id: 1, nombre: "Membresía Individual" },
  { id: 7, nombre: "Membresía Familiar" },
  { id: 43, nombre: "Membresía Grupal" },
  { id: 1912, nombre: "Membresia Academia" },
  { id: 2087, nombre: "Membresia Estudiante" },
  { id: 4135, nombre: "Membresia Load" },
  { id: 4324, nombre: "Membresia Polanco" },
  { id: 5008, nombre: "Gift Card" },
  { id: 5010, nombre: "MEMBRESÍA PAREJA" }
];

export const clubs = [
  { id: 1, nombre: "Admon Central" },
  { id: 2, nombre: "Valle" },
  { id: 3, nombre: "San Angel" },
  { id: 4, nombre: "Satelite" },
  { id: 6, nombre: "Teca" },
  { id: 8, nombre: "Patriotismo" },
  { id: 9, nombre: "Puebla" },
  { id: 10, nombre: "Monterrey" },
  { id: 13, nombre: "Hermosillo" },
  { id: 14, nombre: "Interlomas" },
  { id: 15, nombre: "Condesa" },
  { id: 16, nombre: "Santa Fe" },
  { id: 30, nombre: "Palmas" },
  { id: 31, nombre: "Altavista" },
  { id: 32, nombre: "San Jeronimo" },
  { id: 33, nombre: "Pedregal" },
  { id: 36, nombre: "Veracruz" },
  { id: 37, nombre: "Amores" },
  { id: 38, nombre: "Anzures" },
  { id: 47, nombre: "Pabellon Bosques" },
  { id: 48, nombre: "Allocations" },
  { id: 49, nombre: "Antara" },
  { id: 50, nombre: "Web Pay" },
  { id: 52, nombre: "Triangulo Tecamachalco" },
  { id: 53, nombre: "Lago Alberto" },
  { id: 54, nombre: "Roma" },
  { id: 55, nombre: "Garden Santa Fe" },
  { id: 56, nombre: "Universidad" },
  { id: 57, nombre: "Reforma Rhin" },
  { id: 58, nombre: "Felix Cuevas" },
  { id: 60, nombre: "Claveria" },
  { id: 61, nombre: "Leon" },
  { id: 63, nombre: "Sonata" },
  { id: 64, nombre: "Xola" },
  { id: 65, nombre: "El nueve" },
  { id: 66, nombre: "Metepec" },
  { id: 67, nombre: "Zona Esmeralda" },
  { id: 69, nombre: "Miguel Angel de Quevedo" },
  { id: 71, nombre: "HSBC Toluca" },
  { id: 72, nombre: "Cumbres" },
  { id: 75, nombre: "Obrero Mundial" },
  { id: 77, nombre: "La Rioja" },
  { id: 82, nombre: "Revolucion" },
  { id: 83, nombre: "Barranca" },
  { id: 84, nombre: "Lindavista" },
  { id: 85, nombre: "Cabo Norte" },
  { id: 86, nombre: "Manacar" },
  { id: 87, nombre: "Bernardo Quintana" },
  { id: 88, nombre: "Juriquilla" },
  { id: 89, nombre: "Crater" },
  { id: 90, nombre: "Paseo Interlomas" },
  { id: 91, nombre: "Patio Tlalpan" },
  { id: 94, nombre: "Torreon" },
  { id: 95, nombre: "Queretaro" },
  { id: 96, nombre: "Saltillo" },
  { id: 97, nombre: "Tlanepantla" },
  { id: 98, nombre: "Aguascalientes" },
  { id: 103, nombre: "Parque San Andres" },
  { id: 104, nombre: "Nave 01" },
  { id: 105, nombre: "Load Revolucion" },
  { id: 106, nombre: "Polanco" },
  { id: 108, nombre: "Coapa" },
  { id: 109, nombre: "Cancun" },
  { id: 110, nombre: "Zuma Coapa" },
  { id: 111, nombre: "App" },
  { id: 112, nombre: "Culiacán" }
];

export const mantenimientos = [
  { id: 62, nombre: "Allclub I" },
  { id: 64, nombre: "Uniclub I" },
  { id: 540, nombre: "Fundador" },
  { id: 1001, nombre: "Contingencia Uniclub G" },
  { id: 1002, nombre: "Contingencia Uniclub F" },
  { id: 1003, nombre: "Contingencia Allclub G" },
  { id: 1004, nombre: "Contingencia Allclub F" },
  { id: 1005, nombre: "Streaming G" },
  { id: 1006, nombre: "Streaming F" },
  { id: 1026, nombre: "Pareja* F" },
  { id: 1418, nombre: "Weekend" },
  { id: 2229, nombre: "Multi Club" },
  { id: 2632, nombre: "Allclub2 Promo" },
  { id: 3005, nombre: "Allclub Promo" },
  { id: 3200, nombre: "Uniclub 4 G" },
  { id: 3371, nombre: "Fundador 2" },
  { id: 3520, nombre: "Part Time Allclub" },
  { id: 3635, nombre: "Allclub 6" },
  { id: 3637, nombre: "Uniclub 6" },
  { id: 3734, nombre: "Allclub 7" },
  { id: 3757, nombre: "Allclub 6 3 Anios" },
  { id: 4421, nombre: "Pareja Uniclub 15" },
  { id: 4620, nombre: "Pareja Uniclub 16" },
  { id: 4719, nombre: "Weekend +" },
  { id: 4761, nombre: "Contingencia 2020 I" },
  { id: 4766, nombre: "Contingencia Pareja 2020" },
  { id: 4769, nombre: "Streaming" },
  { id: 4770, nombre: "Hibrido" },
  { id: 4776, nombre: "Contingencia Part Time 2020" },
  { id: 4783, nombre: "Contingencia Allclub" },
  { id: 4784, nombre: "Contingencia Uniclub" },
  { id: 4785, nombre: "Contingencia Pareja Uniclub" },
  { id: 4786, nombre: "Contingencia Pareja Allclub" },
  { id: 4869, nombre: "Contingencia Part Time 2021" },
  { id: 4872, nombre: "Uniclub 17" },
  { id: 4873, nombre: "Part Time 17" },
  { id: 4874, nombre: "Half Week 17" },
  { id: 4878, nombre: "Infantil 17" },
  { id: 4879, nombre: "Contingencia Weekend" },
  { id: 4892, nombre: "Uniclub flex" },
  { id: 4986, nombre: "Allclub Anual" },
  { id: 4998, nombre: "Uniclub Flex 2" },
  { id: 5023, nombre: "Allclub 2 anios" },
  { id: 5024, nombre: "Uniclub 2 anios" },
  { id: 5025, nombre: "Híbrido Anual" },
  { id: 5026, nombre: "Agregado Infantil" },
  { id: 5036, nombre: "Uniclub R2" },
  { id: 5037, nombre: "Contingencia Part Time Allclub" },
  { id: 5038, nombre: "Pareja Uniclub Invitado" },
  { id: 5039, nombre: "Allclub 2 anios 2" },
  { id: 5040, nombre: "Uniclub 2 anios 2" },
  { id: 5041, nombre: "Infantil esp" },
  { id: 5042, nombre: "Titular + hijo" },
  { id: 5043, nombre: "Papas + hijos" },
  { id: 5044, nombre: "Allclub 1 anio" },
  { id: 5045, nombre: "Uniclub 1 anio" },
  { id: 5047, nombre: "Uniclub grupal 2" },
  { id: 5048, nombre: "juvenil 2" },
  { id: 5049, nombre: "Allclub corp" },
  { id: 10005050, nombre: "AGREGADO ALL CLUB" },
  { id: 10005053, nombre: "AGREGADO UNICLUB" },
  { id: 10005056, nombre: "Fundador" },
  { id: 10005057, nombre: "Titular + hijos" },
  { id: 10005058, nombre: "Papas + hijos" },
  { id: 10005059, nombre: "Allclub 18" },
  { id: 10005060, nombre: "Uniclub 18" },
  { id: 10005061, nombre: "Infantil 18" },
  { id: 10005062, nombre: "Part Time 18" },
  { id: 10005063, nombre: "Part Time Alllcub 18" },
  { id: 10005064, nombre: "Half Week 18" },
  { id: 10005066, nombre: "Allclub 2 Anios 22" },
  { id: 10005067, nombre: "Uniclub 2 Anios 22" },
  { id: 10005068, nombre: "Uniclub 1 anio 22" },
  { id: 10005069, nombre: "Allclub 1 anio 22 " },
  { id: 10005071, nombre: "Juvenil 18" },
  { id: 10005072, nombre: "Pareja Allclub 18" },
  { id: 10005073, nombre: "Pareja Uniclub 18" },
  { id: 10005074, nombre: "Pareja Allclub 1.5" },
  { id: 10005075, nombre: "Pareja Uniclub 1.5" },
  { id: 10005076, nombre: "Uniclub Re" },
  { id: 10005077, nombre: "Juvenil Re" },
  { id: 10005078, nombre: "Uniclub 7m" },
  { id: 10005079, nombre: "Juvenil vespertino" },
  { id: 10005080, nombre: "Total Flex 2" },
  { id: 10005081, nombre: "Total Flex 3" },
  { id: 10005082, nombre: "Weekend 18" },
  { id: 10005083, nombre: "Total Flex All 2" },
  { id: 10005084, nombre: "Total Flex All 3" },
  { id: 10005086, nombre: "Uniclub load tras 1" },
  { id: 10005087, nombre: "Uniclub load tras 2" },
  { id: 10005088, nombre: "Uniclub load tras 3" },
  { id: 10005089, nombre: "Uniclub 18b" },
  { id: 10005090, nombre: "PINK" },
  { id: 10005091, nombre: "Half Week*" },
  { id: 10005092, nombre: "Infantil*" },
  { id: 10005093, nombre: "Pareja*" },
  { id: 10005094, nombre: "Part Time*" },
  { id: 10005095, nombre: "Uniclub*" },
  { id: 10005096, nombre: "Weekend*" },
  { id: 10005097, nombre: "Pink Plan 2" },
  { id: 10005098, nombre: "Plan Salud Empresarial" },
  { id: 10005099, nombre: "Uniclub 18 SWCLC" },
  { id: 10006002, nombre: "Juvenil Allclub" },
  { id: 10006005, nombre: "Part Time 18 CLC" }
];

export const empresas = [
  {
    id: 15062956,
    nombre: "2021 ESP ALSEA BARRANCA - Allclub 7",
    asignable: true
  },
  { id: 3513, nombre: "2021 ESP BACK TO SCHOOL UNICLUB", asignable: true },
  { id: 15066690, nombre: "2021 ESP BANAMEX - Allclub 7", asignable: true },
  {
    id: 14660747,
    nombre: "2021 ESP EXERSITE ZONA ESMERALDA - Uniclub 6",
    asignable: true
  },
  { id: 3847, nombre: "2021 ESP GRUPOS", asignable: true },
  { id: 3062, nombre: "2021 ESP LOCATARIOS PASEO INTERLOMAS", asignable: true },
  { id: 2254, nombre: "2021 ESP LOCATARIOS PLAZA MAYOR LEON", asignable: true },
  { id: 4036, nombre: "2021 ESP PROMOCION DINN", asignable: true },
  { id: 4080, nombre: "2021 GRUPOS CONTINGENCIA OCT", asignable: true },
  { id: 2136, nombre: "AAPAUNAM", asignable: true },
  {
    id: 2756,
    nombre: "ABBOTT LABORATORIES DE MEXICO S.A DE C.V",
    asignable: true
  },
  { id: 1767, nombre: "ABC HOSPITAL", asignable: true },
  { id: 1842, nombre: "ABILIA INTELIGENCIA INMOBILIARIA", asignable: true },
  {
    id: 3577,
    nombre: "ACCENDO BANCO S.A  INSTITUCION DE BANCA MULTIPLE",
    asignable: true
  },
  { id: 1789, nombre: "ACCENTURE", asignable: true },
  {
    id: 3463,
    nombre: "ACCIONA SERVICOS ADMINISTRATIVOS S.A DE C.V",
    asignable: true
  },
  { id: 2362, nombre: "ACE GROUP. SERVICIOS ACEINA SA DE CV", asignable: true },
  { id: 1786, nombre: "ACTINVER", asignable: true },
  { id: 114304, nombre: "ACTINVER - Uniclub", asignable: true },
  {
    id: 2044,
    nombre:
      "Administración y Dirección Corporativa COQU, SA DE CV ( QUALITAS )",
    asignable: true
  },
  {
    id: 126728,
    nombre:
      "Administración y Dirección Corporativa COQU, SA DE CV ( QUALITAS ) - Allclub",
    asignable: true
  },
  {
    id: 4648056,
    nombre:
      "Administración y Dirección Corporativa COQU, SA DE CV ( QUALITAS ) - Allclub2",
    asignable: true
  },
  { id: 19512376, nombre: "ADMINISTRADORA CABO NORTE A.C.", asignable: true },
  { id: 2638, nombre: "ADMINISTRADORA FIBRA DANHOS SC", asignable: true },
  {
    id: 2617,
    nombre: "AEROLINEAS EJECUTIVAS / ALE SERVICE CENTER",
    asignable: true
  },
  {
    id: 2259,
    nombre: "AEROPUERTO INTERNACIONAL CIUDAD DE MEXICO SA DE CV",
    asignable: true
  },
  { id: 2119, nombre: "AFORE SURA SA DE CV", asignable: true },
  { id: 2595, nombre: "AFTER SCHOOL", asignable: true },
  { id: 2088, nombre: "AIG SEGUROS MEXICO", asignable: true },
  { id: 3276, nombre: "AIRBUS HELICOPTERS MEXICO", asignable: true },
  { id: 3999, nombre: "Aire Libre 2021", asignable: true },
  { id: 3680, nombre: "ALCON LABORATORIOS SA DE CV", asignable: true },
  { id: 2445, nombre: "ALEN DEL NORTE", asignable: true },
  { id: 2942, nombre: "ALIANT UNIVERSIDADES", asignable: true },
  { id: 3041, nombre: "ALIANZA 8 S.A DE C.V", asignable: true },
  { id: 1896, nombre: "ALIMENTOS CAPULLO", asignable: true },
  { id: 4000, nombre: "All Access", asignable: true },
  { id: 3690, nombre: "All you need is battery", asignable: true },
  { id: 1874, nombre: "ALLIANZ", asignable: true },
  { id: 3176, nombre: "AMAZON", asignable: true },
  { id: 2536, nombre: "AMERICAN AIRLINES INC.", asignable: true },
  { id: 1852, nombre: "AMERICAN EXPRESS", asignable: true },
  { id: 2208, nombre: "AMERICAN STANDARD B&K S DE RL DE CV", asignable: true },
  {
    id: 2327,
    nombre: "AMEX DE MEXICO SA DE CV (STEELCASE INC) VENTA DE MUEBLES",
    asignable: true
  },
  { id: 3194, nombre: "ANZEN SOLUCIONES S.A DE C.V", asignable: true },
  {
    id: 2364,
    nombre: "AON MEXICO BUSINESS SUPPORT S.A DE C.V",
    asignable: true
  },
  { id: 1862, nombre: "APASCO-HOLCIM APASCO", asignable: true },
  {
    id: 2125,
    nombre: "APOYO ECONOMICO ,SERVICIOS CORPORATIVOS AEF",
    asignable: true
  },
  { id: 2570, nombre: "ARMANDO ALDUENA TIRADO", asignable: true },
  { id: 2100, nombre: "ARRENDADOR SWVER", asignable: true },
  { id: 3511, nombre: "ARSAMIK S.A DE CV", asignable: true },
  { id: 3532, nombre: "ARTISTAS INFLUENCERS", asignable: true },
  { id: 1969, nombre: "AS DEPORTE", asignable: true },
  { id: 1819, nombre: "ASEGURADORA ZURICH MEXICO", asignable: true },
  {
    id: 2785,
    nombre: "ASOCIACION DE TRIATLON Y ACTIVACION FISICA  A. C",
    asignable: true
  },
  {
    id: 1914,
    nombre: "Asociación Medica Hospital Star Medica Centro",
    asignable: true
  },
  { id: 2165, nombre: "ASOCIADOS HOME DEPOT SA DE CV", asignable: true },
  { id: 2263, nombre: "ASTRA ZENECA", asignable: true },
  { id: 2559, nombre: "AT&T", asignable: true },
  { id: 2606, nombre: "ATENTO SERVICIOS S.A DE C.V", asignable: true },
  { id: 3376, nombre: "ATEXCA", asignable: true },
  { id: 3216, nombre: "ATLAS WARE", asignable: true },
  { id: 3590, nombre: "ATLASWARE", asignable: true },
  { id: 2118, nombre: "ATLATEC AMBIENTAL", asignable: true },
  { id: 3539, nombre: "ATOMIC", asignable: true },
  {
    id: 19512390,
    nombre: "AUDITORIA SUPERIOR DE LA CIUDAD DE MEXICO",
    asignable: true
  },
  {
    id: 2104,
    nombre: "AUDITORIA SUPERIOR DE LA FEDERACION (ASF)",
    asignable: true
  },
  { id: 3974, nombre: "AUPA PUBLICIDAD SA DE CV", asignable: true },
  { id: 2111, nombre: "AVAYA SA DE CV", asignable: true },
  { id: 1788, nombre: "AVON", asignable: true },
  { id: 2791, nombre: "AXA", asignable: true },
  { id: 1984, nombre: "AXA SEGUROS, SA DE CV", asignable: true },
  { id: 2175, nombre: "Axtel S.A.B de C.V.", asignable: true },
  {
    id: 6767424,
    nombre: "AYUNTAMIENTO DE METEPEC - Allclub2",
    asignable: true
  },
  { id: 3849, nombre: "AYUNTAMIENTO ZAPOPAN", asignable: true },
  { id: 3534, nombre: "AYUNTAMIENTO ZAPOPAN", asignable: true },
  { id: 2750, nombre: "B2B CORTESIAS CDMX", asignable: true },
  {
    id: 4508349,
    nombre: "B2B- GNP (Grupo Nacional Provincial, S.A.B.) - Corporativo",
    asignable: true
  },
  {
    id: 4445347,
    nombre: "B2B-AYUNTAMIENTO DE TIJUANA - Corporativo",
    asignable: true
  },
  { id: 2397, nombre: "B2B-CADENA COMERCIAL OXXO SA DE CV", asignable: true },
  {
    id: 2406,
    nombre: "B2B-CONCIENCIA PLENA TIJUANA (Cedehzac S.C)",
    asignable: true
  },
  {
    id: 2415,
    nombre: "B2B-FARMACIAS ROMA (farmacias Modernas de Tijuana S.A de C.V",
    asignable: true
  },
  {
    id: 2414,
    nombre: "B2B-GOBIERNO DEL ESTADO DE BAJA CALIFORNIA",
    asignable: true
  },
  {
    id: 4473142,
    nombre: "B2B-GOBIERNO DEL ESTADO DE BAJA CALIFORNIA - Corporativo",
    asignable: true
  },
  {
    id: 4504643,
    nombre: "B2B-IMSS INSTITUTO DEL SEGURO SOCIAL MEXICANO - Corporativo",
    asignable: true
  },
  {
    id: 4443494,
    nombre: "B2B-ISESALUD BAJA CALIFORNIA - Corporativo",
    asignable: true
  },
  { id: 2426, nombre: "B2B-MIGUEL ANGEL EGUIA GARFIAS", asignable: true },
  { id: 2430, nombre: "B2B-NISSAN TIJUANA", asignable: true },
  {
    id: 4456465,
    nombre: "B2B-PGR (Fiscalia General de la Republica) - Corporativo",
    asignable: true
  },
  {
    id: 9021155,
    nombre: "B2B-PGR (Fiscalia General de la Republica) - Uniclub CT",
    asignable: true
  },
  {
    id: 2422,
    nombre: "B2B-PODER JUDICIAL DEL ESTADO BC (PJBC)",
    asignable: true
  },
  {
    id: 2820,
    nombre: "B2B_ADMINISTRADORA DE SERVICIOS BAJAMEX S.C  (Grupo Musa)",
    asignable: true
  },
  {
    id: 2794,
    nombre: "B2B_AT&T COMUNICACIONES DIGITALES S DE RL DE CV",
    asignable: true
  },
  {
    id: 5286609,
    nombre: "B2B_AVANDEL  S DE RL DE CV - Corporativo",
    asignable: true
  },
  {
    id: 4877096,
    nombre: "B2B_BANAMEX REGION 1 - Corporativo",
    asignable: true
  },
  { id: 9872632, nombre: "B2B_BANAMEX REGION 1 - Uniclub CT", asignable: true },
  { id: 2974, nombre: "B2B_BELLEZA MEXICO", asignable: true },
  { id: 4858566, nombre: "B2B_BIZ TALENT - Corporativo", asignable: true },
  {
    id: 4860419,
    nombre: "B2B_BOSE CORPORATION - Corporativo",
    asignable: true
  },
  {
    id: 4856713,
    nombre: "B2B_CETYS UNIVERSIDAD - Corporativo",
    asignable: true
  },
  { id: 2627, nombre: "B2B_CONCESIONARIOS", asignable: true },
  {
    id: 4630647,
    nombre: "B2B_CONSULADO E.U.A EN TIJUANA - Corporativo",
    asignable: true
  },
  {
    id: 4491672,
    nombre: "B2B_DELOITTE (Galaz, Yamazaki; Ruiz Urquiza S.C) - Corporativo",
    asignable: true
  },
  {
    id: 2459,
    nombre: "B2B_FAMILY & FRIENDS WORLD GYM TIJUANA",
    asignable: true
  },
  {
    id: 4556527,
    nombre: "B2B_FAMILY & FRIENDS WORLD GYM TIJUANA - Corporativo",
    asignable: true
  },
  { id: 2418, nombre: "B2B_GRUPO IMPULZA", asignable: true },
  { id: 5175429, nombre: "B2B_GRUPO SALINAS - Corporativo", asignable: true },
  { id: 10476543, nombre: "B2B_GRUPO SALINAS - Uniclub CT", asignable: true },
  { id: 2733, nombre: "B2B_GTEL ALESTRA", asignable: true },
  { id: 2795, nombre: "B2B_ICR SA DE CV (CARL´S JUNIOR)", asignable: true },
  {
    id: 2527,
    nombre: "B2B_MADSOL (Manejo y Administracion de Soluciones, S.A de C.V)",
    asignable: true
  },
  {
    id: 4100689,
    nombre: "B2B_ODASOFT S DE RL DE CV - Corporativo",
    asignable: true
  },
  {
    id: 141632,
    nombre: "B2B_ODASOFT S DE RL DE CV - Uniclub",
    asignable: true
  },
  {
    id: 4549115,
    nombre: "B2B_PRO FOODS CALIFORNIA DMT, S.A DE C.V - Corporativo",
    asignable: true
  },
  {
    id: 5622002,
    nombre: "B2B_SISTEMAS MEDICOS ALARIS - Corporativo",
    asignable: true
  },
  { id: 2480, nombre: "B2B_TELEVISA TIJUANA", asignable: true },
  {
    id: 4940699,
    nombre: "B2B_TOTALPLAY/ENLACE - Comunidad Uniclub",
    asignable: true
  },
  {
    id: 4815947,
    nombre: "B2B_TOTALPLAY/ENLACE - Corporativo",
    asignable: true
  },
  {
    id: 5273638,
    nombre: "B2B_TOYOTA MOTOR MANUFACTURING - Corporativo",
    asignable: true
  },
  { id: 2968, nombre: "B2B_TV AZTECA/JUMPMEX", asignable: true },
  { id: 2887, nombre: "B2B_VIASTARA S. DE RL. DE CV", asignable: true },
  { id: 1953, nombre: "BAKER AND MCKENZIE ABOGADOS SC", asignable: true },
  { id: 1794, nombre: "BANAMEX", asignable: true },
  { id: 2755, nombre: "BANCO ABC CAPITAL", asignable: true },
  { id: 2154, nombre: "BANCO DE MEXICO (BANXICO)", asignable: true },
  {
    id: 2179,
    nombre: "Banco de Tokyo-Mitsubishi UFJ(México) SA",
    asignable: true
  },
  { id: 2253, nombre: "BANCO DEL BAJIO SA", asignable: true },
  { id: 1785, nombre: "BANCOMER BBVA", asignable: true },
  { id: 2047, nombre: "BANCOMEXT", asignable: true },
  { id: 3582, nombre: "BANCOPPEL", asignable: true },
  {
    id: 2234,
    nombre: "BANK OF AMERICA AND MERILL LYNCH MEXICO",
    asignable: true
  },
  { id: 3470, nombre: "BANK OF CHINA", asignable: true },
  { id: 1863, nombre: "BANOBRAS", asignable: true },
  { id: 1834, nombre: "BANORTE-IXE", asignable: true },
  { id: 2322, nombre: "BANREGIO GRUPO FINANCIERO,SOFOM.", asignable: true },
  { id: 3104, nombre: "BANSEFI", asignable: true },
  { id: 3375, nombre: "BARRA NACIONAL DE ABOGADOS SC", asignable: true },
  { id: 108810, nombre: "BASE CORPORATIVA - Allclub", asignable: true },
  { id: 7267455, nombre: "BASE CORPORATIVA - Allclub 12", asignable: true },
  {
    id: 8097570,
    nombre: "BASE CORPORATIVA - Allclub 2 Dic 2021",
    asignable: true
  },
  { id: 5187780, nombre: "BASE CORPORATIVA - Allclub 2.0", asignable: true },
  { id: 5614245, nombre: "BASE CORPORATIVA - Allclub 4", asignable: true },
  { id: 6191640, nombre: "BASE CORPORATIVA - Allclub 4 2x1", asignable: true },
  { id: 6370650, nombre: "BASE CORPORATIVA - Allclub 4B", asignable: true },
  { id: 6379425, nombre: "BASE CORPORATIVA - Allclub 6", asignable: true },
  {
    id: 6593535,
    nombre: "BASE CORPORATIVA - Allclub 6 3Anios",
    asignable: true
  },
  { id: 6553170, nombre: "BASE CORPORATIVA - Allclub 7", asignable: true },
  {
    id: 7537725,
    nombre: "BASE CORPORATIVA - Allclub dic 2021",
    asignable: true
  },
  { id: 3990870, nombre: "BASE CORPORATIVA - Allclub2", asignable: true },
  { id: 2537730, nombre: "BASE CORPORATIVA - Biclub", asignable: true },
  { id: 6261840, nombre: "BASE CORPORATIVA - Biclub 4", asignable: true },
  {
    id: 3531060,
    nombre: "BASE CORPORATIVA - Biclub Corporativa",
    asignable: true
  },
  {
    id: 8355555,
    nombre: "BASE CORPORATIVA - Contingencia 2020",
    asignable: true
  },
  {
    id: 8394165,
    nombre: "BASE CORPORATIVA - Contingencia Allclub",
    asignable: true
  },
  {
    id: 8364330,
    nombre: "BASE CORPORATIVA - Contingencia Pareja 2020",
    asignable: true
  },
  {
    id: 8399430,
    nombre: "BASE CORPORATIVA - Contingencia Pareja Allclub",
    asignable: true
  },
  {
    id: 8397675,
    nombre: "BASE CORPORATIVA - Contingencia Pareja Uniclub",
    asignable: true
  },
  {
    id: 8381880,
    nombre: "BASE CORPORATIVA - Contingencia Part Time 2020",
    asignable: true
  },
  {
    id: 8545095,
    nombre: "BASE CORPORATIVA - Contingencia Part Time 2021",
    asignable: true
  },
  {
    id: 8395920,
    nombre: "BASE CORPORATIVA - Contingencia Uniclub",
    asignable: true
  },
  {
    id: 8546850,
    nombre: "BASE CORPORATIVA - Contingencia Uniclub 2x1",
    asignable: true
  },
  { id: 3252015, nombre: "BASE CORPORATIVA - Corporativo", asignable: true },
  { id: 3911895, nombre: "BASE CORPORATIVA - Multi Club", asignable: true },
  { id: 121095, nombre: "BASE CORPORATIVA - Multiclub", asignable: true },
  {
    id: 5656365,
    nombre: "BASE CORPORATIVA - Multiclub Foraneo",
    asignable: true
  },
  {
    id: 6268860,
    nombre: "BASE CORPORATIVA - Multiclub Foraneo 2",
    asignable: true
  },
  {
    id: 6358365,
    nombre: "BASE CORPORATIVA - Multiclub Foraneo 3",
    asignable: true
  },
  {
    id: 6391710,
    nombre: "BASE CORPORATIVA - Multiclub Foraneo 4",
    asignable: true
  },
  { id: 5659875, nombre: "BASE CORPORATIVA - Multiclub GDL", asignable: true },
  {
    id: 6361875,
    nombre: "BASE CORPORATIVA - Multiclub GDL 2",
    asignable: true
  },
  {
    id: 6395220,
    nombre: "BASE CORPORATIVA - Multiclub GDL 3",
    asignable: true
  },
  {
    id: 5619510,
    nombre: "BASE CORPORATIVA - Pareja Allclub 4",
    asignable: true
  },
  {
    id: 6270615,
    nombre: "BASE CORPORATIVA - Pareja Allclub 4A",
    asignable: true
  },
  {
    id: 6351345,
    nombre: "BASE CORPORATIVA - Pareja Allclub 5",
    asignable: true
  },
  {
    id: 6381180,
    nombre: "BASE CORPORATIVA - Pareja Allclub 6",
    asignable: true
  },
  {
    id: 8069490,
    nombre: "BASE CORPORATIVA - Pareja Allclub 7",
    asignable: true
  },
  {
    id: 5658120,
    nombre: "BASE CORPORATIVA - Pareja Multiclub Foraneo",
    asignable: true
  },
  {
    id: 6360120,
    nombre: "BASE CORPORATIVA - Pareja Multiclub Foraneo 2",
    asignable: true
  },
  {
    id: 6393465,
    nombre: "BASE CORPORATIVA - Pareja Multiclub Foraneo 4",
    asignable: true
  },
  {
    id: 5661630,
    nombre: "BASE CORPORATIVA - Pareja Multiclub GDL",
    asignable: true
  },
  {
    id: 6363630,
    nombre: "BASE CORPORATIVA - Pareja Multiclub GDL 2",
    asignable: true
  },
  {
    id: 6396975,
    nombre: "BASE CORPORATIVA - Pareja Multiclub GDL 3",
    asignable: true
  },
  { id: 5533515, nombre: "BASE CORPORATIVA - Pareja Uniclub", asignable: true },
  {
    id: 5617755,
    nombre: "BASE CORPORATIVA - Pareja Uniclub 4",
    asignable: true
  },
  {
    id: 6274125,
    nombre: "BASE CORPORATIVA - Pareja Uniclub 4A",
    asignable: true
  },
  {
    id: 6353100,
    nombre: "BASE CORPORATIVA - Pareja Uniclub 5",
    asignable: true
  },
  {
    id: 6384690,
    nombre: "BASE CORPORATIVA - Pareja Uniclub 6",
    asignable: true
  },
  {
    id: 6447870,
    nombre: "BASE CORPORATIVA - Pareja Uniclub 6A",
    asignable: true
  },
  {
    id: 7374510,
    nombre: "BASE CORPORATIVA - PAREJA UNICLUB 7",
    asignable: true
  },
  {
    id: 8071245,
    nombre: "BASE CORPORATIVA - Pareja Uniclub 8",
    asignable: true
  },
  { id: 6965595, nombre: "BASE CORPORATIVA - Part time 10", asignable: true },
  { id: 5568615, nombre: "BASE CORPORATIVA - Part Time 4", asignable: true },
  { id: 6175845, nombre: "BASE CORPORATIVA - Part Time 5", asignable: true },
  { id: 6349590, nombre: "BASE CORPORATIVA - Part Time 6", asignable: true },
  { id: 6386445, nombre: "BASE CORPORATIVA - Part Time 7", asignable: true },
  {
    id: 6177600,
    nombre: "BASE CORPORATIVA - Part Time Allclub",
    asignable: true
  },
  {
    id: 6389955,
    nombre: "BASE CORPORATIVA - Part Time Allclub 3",
    asignable: true
  },
  { id: 3989115, nombre: "BASE CORPORATIVA - Part Time2", asignable: true },
  { id: 5112315, nombre: "BASE CORPORATIVA - Part Time3", asignable: true },
  { id: 961740, nombre: "BASE CORPORATIVA - Plata", asignable: true },
  { id: 4557735, nombre: "BASE CORPORATIVA - Triclub", asignable: true },
  { id: 4682340, nombre: "BASE CORPORATIVA - Triclub2", asignable: true },
  { id: 112320, nombre: "BASE CORPORATIVA - Uniclub", asignable: true },
  { id: 6976125, nombre: "BASE CORPORATIVA - Uniclub 11", asignable: true },
  { id: 7534215, nombre: "BASE CORPORATIVA - Uniclub 14", asignable: true },
  { id: 2776410, nombre: "BASE CORPORATIVA - Uniclub 1775", asignable: true },
  {
    id: 8099325,
    nombre: "BASE CORPORATIVA - Uniclub 2 Dic 2021",
    asignable: true
  },
  { id: 5208840, nombre: "BASE CORPORATIVA - Uniclub 2.0", asignable: true },
  { id: 5616000, nombre: "BASE CORPORATIVA - Uniclub 4", asignable: true },
  { id: 6212700, nombre: "BASE CORPORATIVA - Uniclub 4 2x1", asignable: true },
  { id: 6251310, nombre: "BASE CORPORATIVA - Uniclub 4B", asignable: true },
  { id: 6356610, nombre: "BASE CORPORATIVA - Uniclub 5", asignable: true },
  { id: 6382935, nombre: "BASE CORPORATIVA - Uniclub 6", asignable: true },
  {
    id: 6604065,
    nombre: "BASE CORPORATIVA - Uniclub 6 3Anios",
    asignable: true
  },
  { id: 6440850, nombre: "BASE CORPORATIVA - Uniclub 6A", asignable: true },
  { id: 6644430, nombre: "BASE CORPORATIVA - Uniclub 6B", asignable: true },
  {
    id: 7539480,
    nombre: "BASE CORPORATIVA - Uniclub dic 2021",
    asignable: true
  },
  {
    id: 1393470,
    nombre: "BASE CORPORATIVA - Uniclub Especial",
    asignable: true
  },
  {
    id: 3674970,
    nombre: "BASE CORPORATIVA - Uniclub Foraneo",
    asignable: true
  },
  {
    id: 4945590,
    nombre: "BASE CORPORATIVA - Uniclub Foraneo 2017",
    asignable: true
  },
  { id: 5179005, nombre: "BASE CORPORATIVA - Uniclub Promo", asignable: true },
  {
    id: 5272020,
    nombre: "BASE CORPORATIVA - Uniclub Promo 2",
    asignable: true
  },
  { id: 7930845, nombre: "BASE CORPORATIVA - Uniclub R", asignable: true },
  { id: 5182515, nombre: "BASE CORPORATIVA - Uniclub RA", asignable: true },
  { id: 3985605, nombre: "BASE CORPORATIVA - Uniclub2", asignable: true },
  { id: 5026320, nombre: "BASE CORPORATIVA - Uniclub3", asignable: true },
  { id: 2002, nombre: "BASF INTERSERVICIOS SA DE CV", asignable: true },
  { id: 2107, nombre: "BASHAM ,RINGE Y CORREA", asignable: true },
  { id: 1883, nombre: "BAYER DE MEXICO", asignable: true },
  { id: 2650, nombre: "BAYER DE MEXICO S.A DE C.V", asignable: true },
  { id: 3491, nombre: "BBDO", asignable: true },
  { id: 3725, nombre: "BBDO garden", asignable: true },
  { id: 1948, nombre: "BCD TRAVEL SA DE CV", asignable: true },
  { id: 124672, nombre: "BCD TRAVEL SA DE CV - Uniclub", asignable: true },
  { id: 2707, nombre: "BDF CORPORATIVO SA DE CV", asignable: true },
  { id: 2312, nombre: "Becados Feel Healthy", asignable: true },
  { id: 2814, nombre: "BECADOS SW 2019", asignable: true },
  { id: 2716, nombre: "BECERRIL, COCA & BECERRIL S.C", asignable: true },
  { id: 1999, nombre: "BECTON DICKINSON DE MEXICO SA DE CV", asignable: true },
  { id: 3089, nombre: "BEGROUP", asignable: true },
  { id: 2256, nombre: "BEIERSDORF BDF CORPORATIVO SA DE CV", asignable: true },
  { id: 3501, nombre: "BELCORP", asignable: true },
  {
    id: 2508,
    nombre: "BELLO, GALLARDO, BONEQUI y GARCIA, S.C.",
    asignable: true
  },
  { id: 19512392, nombre: "BEMIS DE MEXICO SA DE CV ", asignable: true },
  { id: 2739, nombre: "BENEFICIOS CORPORATIVOS GO INTEGRO", asignable: true },
  { id: 19512400, nombre: "BENEFITHUB", asignable: true },
  { id: 3466, nombre: "BEPENSA", asignable: true },
  { id: 3101, nombre: "BEST BUY ENTERPRISES S DE RL DE CV", asignable: true },
  { id: 3479, nombre: "BH5 2019", asignable: true },
  { id: 3257, nombre: "BH5 OBRA", asignable: true },
  { id: 3319, nombre: "BH5 OBRA 2", asignable: true },
  { id: 3280, nombre: "BH5 OBRA INFIELES", asignable: true },
  { id: 3377, nombre: "BH5 STAFF 2019", asignable: true },
  { id: 3454, nombre: "BH5 TRES HERMANAS", asignable: true },
  { id: 3329, nombre: "BH5nov18", asignable: true },
  { id: 3629, nombre: "BICI EXPO 18", asignable: true },
  { id: 3425, nombre: "BILD FILMS", asignable: true },
  { id: 2039, nombre: "BIOPAPPEL(BioServicios Corporativos)", asignable: true },
  { id: 2616, nombre: "BMW DE MEXICO S.A DE C.V", asignable: true },
  {
    id: 1982,
    nombre: "BOLSA MEXICANA DE VALORES SAB DE CV (BMV)",
    asignable: true
  },
  {
    id: 126848,
    nombre: "BOLSA MEXICANA DE VALORES SAB DE CV (BMV) - Uniclub",
    asignable: true
  },
  { id: 3117, nombre: "BOLTMEDIA 1", asignable: true },
  { id: 3188, nombre: "BOLTMEDIA 2", asignable: true },
  {
    id: 2076,
    nombre: "BRANDARRAYS MEXICO -TAVISTOCK GROUP  (TYR)",
    asignable: true
  },
  { id: 2166, nombre: "BRITISH AMERICAN TOBACCO", asignable: true },
  { id: 19512386, nombre: "BRP QUERÉTARO", asignable: true },
  { id: 3972, nombre: "BUBBLE SWIM", asignable: true },
  {
    id: 2558,
    nombre: "BUPA MEXICO COMPANIA DE SEGUROS S.A DE CV",
    asignable: true
  },
  {
    id: 2475,
    nombre: "BURO DE CREDITO SERVICIOS Y ASESORIA SCOBC, S.A DE C.V",
    asignable: true
  },
  { id: 1830, nombre: "BUSINESS TRAVEL CONSULTING", asignable: true },
  { id: 3277, nombre: "CAFE PUNTA DEL CIELO", asignable: true },
  { id: 2488, nombre: "Cafeteria Cumbres Eric Ceron", asignable: true },
  { id: 3723, nombre: "CAFETERIA MAYRA XIMENA RABAGO", asignable: true },
  {
    id: 3150,
    nombre: "CAFETERÍA SW CRATER ACAI HEALTHY FOOD S.A DE C.V",
    asignable: true
  },
  {
    id: 2970,
    nombre: "CAFETERIAS CONCESIONARIO -TE QUIERO QUINOA",
    asignable: true
  },
  { id: 3509, nombre: "CAMARA DE COMENCIO ITALIANA", asignable: true },
  { id: 3740, nombre: "CAMARA DE SENADORES", asignable: true },
  {
    id: 2151,
    nombre: "CAMARA NACIONAL DE LA INDUSTRIA CANACINTRA",
    asignable: true
  },
  { id: 3736, nombre: "CAME TE CREEMOS", asignable: true },
  { id: 19512412, nombre: "CAMPARI MEXICO", asignable: true },
  { id: 2189, nombre: "CANON MEXICANA", asignable: true },
  { id: 1815, nombre: "CAPGEMINI", asignable: true },
  { id: 3185, nombre: "CAPITANES", asignable: true },
  { id: 3371, nombre: "CAPITANES 2019", asignable: true },
  { id: 3588, nombre: "CAPITANES 2019 OFFSEASON", asignable: true },
  { id: 3693, nombre: "Capitanes 2019-2020", asignable: true },
  { id: 3158, nombre: "CAPTURA FILMS (VW)", asignable: true },
  { id: 3633, nombre: "CARRERA AQUI NADIE SE RINDE", asignable: true },
  {
    id: 1997,
    nombre: "CARSO EFICENTRUM SA DE CV (GRUPO CARSO)",
    asignable: true
  },
  { id: 2816, nombre: "CARTELERAS.COM", asignable: true },
  { id: 3820, nombre: "CARTER MEDIA", asignable: true },
  { id: 2749, nombre: "CASSIDIAN MEXICO SA DE CV", asignable: true },
  { id: 3015, nombre: "CATATONIA", asignable: true },
  { id: 2997, nombre: "CATERPILLAR MEXICO S.A DE C.V", asignable: true },
  {
    id: 2921,
    nombre: "CB ADMINISTRACION DE PERSONAL S.A DE C.V",
    asignable: true
  },
  { id: 2658, nombre: "CBRE S.A DE C.V", asignable: true },
  { id: 2156, nombre: "CEMEX", asignable: true },
  {
    id: 2747,
    nombre: "CENACE CENTRO NACIONAL DE CONTROL DE ENERGIA",
    asignable: true
  },
  { id: 2008, nombre: "CENTRO DE DISEÑO ,CINE Y TELEVISION", asignable: true },
  {
    id: 3271,
    nombre: "CENTRO UNIIVERSITARIO INCARNATE WORLD",
    asignable: true
  },
  { id: 19512365, nombre: "CER CREATIVE", asignable: true },
  {
    id: 2758,
    nombre: "CESSA CENTRO DE ESTUDIOS SUPERIORES DE SAN ANGEL",
    asignable: true
  },
  { id: 1828, nombre: "CHEVEZ RUIZ ZAMARRIPA, S.C.", asignable: true },
  { id: 3080, nombre: "CHILANGUITA", asignable: true },
  { id: 3461, nombre: "CHILANGUITA ESPECIAL", asignable: true },
  { id: 3945, nombre: "CHRONO SPORT", asignable: true },
  { id: 2388, nombre: "CHRYSLER-FIAT (FCA)", asignable: true },
  { id: 2098, nombre: "CHUBB SA DE CV", asignable: true },
  { id: 1814, nombre: "CIBANCO", asignable: true },
  { id: 3533, nombre: "CINEBOX", asignable: true },
  { id: 3079, nombre: "CINEMEX", asignable: true },
  { id: 2842, nombre: "CINEMEX ( SERVIUNO S.A DE C.V)", asignable: true },
  { id: 3135, nombre: "CINEMEX 1", asignable: true },
  { id: 2833, nombre: "CIRCULO INFORMADOR", asignable: true },
  { id: 1851, nombre: "CIRCUS MARKETING", asignable: true },
  { id: 1887, nombre: "CISCO SA DE CV", asignable: true },
  { id: 3744, nombre: "CLEVER QUERETARO", asignable: true },
  { id: 2644, nombre: "CLUB CASA BLANCA", asignable: true },
  { id: 3115, nombre: "CLUB DE CORREDORES", asignable: true },
  {
    id: 2799,
    nombre: "CLUB DE PILOTOS AVIADORES PROFESIONALES DE MEXICO S.C",
    asignable: true
  },
  { id: 1729, nombre: "CLUB SAN ANGEL", asignable: true },
  {
    id: 2037,
    nombre: "CNBV(Comision Nacional Bancaria y de Valores)",
    asignable: true
  },
  { id: 1840, nombre: "COCA COLA FEMSA", asignable: true },
  { id: 114080, nombre: "COCA COLA FEMSA - Allclub", asignable: true },
  { id: 117760, nombre: "COCA COLA FEMSA - Uniclub", asignable: true },
  { id: 3114, nombre: "COCA COLA FEMSA 2018", asignable: true },
  { id: 2697, nombre: "COCA COLA FEMSA FORANEOS", asignable: true },
  { id: 1860, nombre: "COCA-COLA-COMPANY", asignable: true },
  { id: 3066, nombre: "CODIGO QRO", asignable: true },
  {
    id: 2786,
    nombre: "COFECE COMISION FEDERAL DE COMPETENCIA ECONÓMICA",
    asignable: true
  },
  { id: 2218, nombre: "COFEPRIS", asignable: true },
  { id: 2663, nombre: "COLABORADORES WORLDGYM CDMX", asignable: true },
  {
    id: 1892,
    nombre: "COLDWELL BANKER AFFILIATES DE MEXICO SA DE CV",
    asignable: true
  },
  {
    id: 1841,
    nombre: "COLEGIO DE CONTADORES PUBLICOS DE MEXICO",
    asignable: true
  },
  { id: 3395, nombre: "COLEGIO DE NUTRIOLOGOS DE LEON", asignable: true },
  { id: 2025, nombre: "COLEGIO HEBREO SINAI", asignable: true },
  { id: 2129, nombre: "COLEGIO IMAGEN PUBLICA", asignable: true },
  { id: 1868, nombre: "COLGATE PALMOLIVE", asignable: true },
  { id: 3489, nombre: "COLONOS CLUB TARANGO", asignable: true },
  { id: 6674457, nombre: "COLONOS CLUB TARANGO - Academia", asignable: true },
  {
    id: 13090728,
    nombre: "COLONOS CLUB TARANGO - Part Time CT",
    asignable: true
  },
  { id: 223296, nombre: "COLONOS CLUB TARANGO - Uniclub", asignable: true },
  {
    id: 13087239,
    nombre: "COLONOS CLUB TARANGO - Uniclub CT",
    asignable: true
  },
  { id: 7923519, nombre: "COLONOS CLUB TARANGO - Uniclub2", asignable: true },
  { id: 4947402, nombre: "COLONOS CLUB TARANGO - Weekend", asignable: true },
  { id: 3727, nombre: "COMBO COMUNICACIONES REVISTAS", asignable: true },
  { id: 2537, nombre: "COMERCIAL ACROS WHIRLPOOL S.A DE C.V", asignable: true },
  { id: 1895, nombre: "COMEX", asignable: true },
  { id: 1956, nombre: "COMISION FEDERAL ELECTRICIDAD CFE", asignable: true },
  {
    id: 2201,
    nombre: "COMISION NACIONAL DE CULTURA FISICA Y DEPORTE CONADE",
    asignable: true
  },
  {
    id: 2443,
    nombre: "COMISION NACIONAL DE DERECHOS HUMANOS (CNDH) SAN JERONIMO",
    asignable: true
  },
  {
    id: 168567,
    nombre:
      "COMISION NACIONAL DE DERECHOS HUMANOS (CNDH) SAN JERONIMO - Multiclub",
    asignable: true
  },
  {
    id: 2838,
    nombre: "COMISION NACIONAL DE SEGUROS Y FIANZAS (EXCEL)",
    asignable: true
  },
  {
    id: 2835,
    nombre: "COMISION NACIONAL DE SEGUROS Y FIANZAS NIÑOS",
    asignable: true
  },
  { id: 2808, nombre: "COMISION NACIONAL DEL AGUA CONAGUA", asignable: true },
  {
    id: 2323,
    nombre:
      "COMISION NACIONAL PARA EL DESARROLLO DE LOS PUEBLOS INDIGENAS (CDI)",
    asignable: true
  },
  { id: 2863, nombre: "COMISION REGULADORA DE ENERGIA", asignable: true },
  { id: 2864, nombre: "COMISION REGULADORA DE ENERGIA", asignable: true },
  {
    id: 6512736,
    nombre: "COMISION REGULADORA DE ENERGIA - Allclub2",
    asignable: true
  },
  { id: 3664, nombre: "COMPANY MAGAZINE", asignable: true },
  { id: 3674, nombre: "COMPANY MAGAZINE REGRESO A CLASES 19", asignable: true },
  { id: 1952, nombre: "COMPARTAMOS BANCO SA DE CV- GENTERA", asignable: true },
  { id: 3126, nombre: "COMPLEJOS RESIDENCIALES QRO", asignable: true },
  { id: 3583, nombre: "COMPUCOM", asignable: true },
  { id: 1831, nombre: "COMTELSAT", asignable: true },
  { id: 2216, nombre: "COMUNIDAD COLONOS PASEO INTERLOMAS", asignable: true },
  {
    id: 137392,
    nombre: "COMUNIDAD COLONOS PASEO INTERLOMAS - Allclub",
    asignable: true
  },
  { id: 2191, nombre: "COMUNIDAD POLANCO", asignable: true },
  { id: 2300, nombre: "COMUNIDAD POLANCO", asignable: true },
  { id: 142600, nombre: "COMUNIDAD POLANCO - Allclub", asignable: true },
  { id: 135842, nombre: "COMUNIDAD POLANCO - Allclub", asignable: true },
  {
    id: 3047500,
    nombre: "COMUNIDAD POLANCO - Cuota Polanco MULTICLUB",
    asignable: true
  },
  { id: 4883739, nombre: "COMUNIDAD POLANCO - Multi Club", asignable: true },
  { id: 151179, nombre: "COMUNIDAD POLANCO - Multiclub", asignable: true },
  { id: 158700, nombre: "COMUNIDAD POLANCO - Multiclub", asignable: true },
  { id: 140224, nombre: "COMUNIDAD POLANCO - Uniclub", asignable: true },
  { id: 3638600, nombre: "COMUNIDAD POLANCO - Uniclub 1775", asignable: true },
  { id: 3466162, nombre: "COMUNIDAD POLANCO - Uniclub 1775", asignable: true },
  { id: 2193, nombre: "COMUNIDAD ZONA NORTE", asignable: true },
  { id: 135966, nombre: "COMUNIDAD ZONA NORTE - Allclub", asignable: true },
  { id: 3157, nombre: "CONACYT INSTITUTO MORA", asignable: true },
  {
    id: 2221,
    nombre: "CONAGRA FOODS/CFM SERVICIOS ADMINISTRATIVOS",
    asignable: true
  },
  { id: 3433, nombre: "CONCESIONARIOS CLUB TARANGO", asignable: true },
  { id: 19512370, nombre: "CONSCAB CONSTRUCTORA", asignable: true },
  { id: 2677, nombre: "CONSEJERO DE ACTINVER", asignable: true },
  { id: 1839, nombre: "CONSEJO DE LA JUDICATURA", asignable: true },
  { id: 1829, nombre: "CONSEJO DE PROMOCION TURISTICA", asignable: true },
  { id: 2792, nombre: "CONSORCIO ARA", asignable: true },
  { id: 19512374, nombre: "CONSTRUCTORA Y EDIFICADORA GIA", asignable: true },
  {
    id: 2091,
    nombre: "CONSULADO USA (ESTADO UNIDOS DE AMERICA)",
    asignable: true
  },
  { id: 3093, nombre: "CONSULTORA 414 S.A DE C.V", asignable: true },
  { id: 3662, nombre: "CONVENIO EXICOM", asignable: true },
  { id: 19512361, nombre: "CONVENIO INACTIVO", asignable: true },
  { id: 2503, nombre: "Convenio Suplementos GNC", asignable: true },
  { id: 19512363, nombre: "COPPEL", asignable: true },
  {
    id: 1985,
    nombre: "CORPORACION INTERPUBLIC MEXICANA S.A DE C.V. McCANN WORLDGROUP",
    asignable: true
  },
  { id: 3043, nombre: "CORPORACION LIPU SAPI DE C.V", asignable: true },
  {
    id: 2875,
    nombre: "CORPORATIVO ESPECIAL ABRIL 2 MVTALENT AGENCIA",
    asignable: true
  },
  {
    id: 6537750,
    nombre: "CORPORATIVO ESPECIAL ABRIL 2 MVTALENT AGENCIA - Allclub2",
    asignable: true
  },
  {
    id: 2877,
    nombre: "CORPORATIVO ESPECIAL ABRIL 4 COMPUCOM",
    asignable: true
  },
  {
    id: 6542298,
    nombre: "CORPORATIVO ESPECIAL ABRIL 4 COMPUCOM - Allclub2",
    asignable: true
  },
  { id: 2873, nombre: "CORPORATIVO ESPECIAL CONAGRA ABRIL 1", asignable: true },
  {
    id: 6533202,
    nombre: "CORPORATIVO ESPECIAL CONAGRA ABRIL 1 - Allclub2",
    asignable: true
  },
  {
    id: 2926,
    nombre: "CORPORATIVO ESPECIAL GRUPO DIAGNOSTICO ARIES OLAB",
    asignable: true
  },
  {
    id: 181412,
    nombre: "CORPORATIVO ESPECIAL GRUPO DIAGNOSTICO ARIES OLAB - Allclub",
    asignable: true
  },
  {
    id: 6653724,
    nombre: "CORPORATIVO ESPECIAL GRUPO DIAGNOSTICO ARIES OLAB - Allclub2",
    asignable: true
  },
  { id: 2897, nombre: "CORPORATIVO ESPECIAL NOVARTIS", asignable: true },
  {
    id: 9267503,
    nombre: "CORPORATIVO ESPECIAL NOVARTIS - Allclub 4",
    asignable: true
  },
  {
    id: 6587778,
    nombre: "CORPORATIVO ESPECIAL NOVARTIS - Allclub2",
    asignable: true
  },
  {
    id: 6579087,
    nombre: "CORPORATIVO ESPECIAL NOVARTIS - Uniclub2",
    asignable: true
  },
  { id: 2898, nombre: "CORPORATIVO ESPECIAL NOVARTIS II", asignable: true },
  {
    id: 9270702,
    nombre: "CORPORATIVO ESPECIAL NOVARTIS II - Allclub 4",
    asignable: true
  },
  {
    id: 6590052,
    nombre: "CORPORATIVO ESPECIAL NOVARTIS II - Allclub2",
    asignable: true
  },
  { id: 3211, nombre: "CORPORATIVO ESPECIAL NOVARTIS III", asignable: true },
  {
    id: 10271989,
    nombre: "CORPORATIVO ESPECIAL NOVARTIS III - Allclub 4",
    asignable: true
  },
  {
    id: 11671985,
    nombre: "CORPORATIVO ESPECIAL NOVARTIS III - Allclub 6",
    asignable: true
  },
  {
    id: 10275200,
    nombre: "CORPORATIVO ESPECIAL NOVARTIS III - Uniclub 4",
    asignable: true
  },
  { id: 3681, nombre: "CORPORATIVO HUMANO", asignable: true },
  { id: 3458, nombre: "CORTESIAS GERENCIA CLUB TARANGO", asignable: true },
  { id: 2605, nombre: "CORTESIAS GERENCIA WGTIJUANA", asignable: true },
  { id: 2365, nombre: "COSTCO DE MEXICO SA DE CV", asignable: true },
  { id: 2374, nombre: "COSTCO UNICLUB BLOQUEADO PARA VENTA", asignable: true },
  { id: 2339, nombre: "COTEMAR CD. DEL CARMEN", asignable: true },
  { id: 1884, nombre: "CREDIT SUISSE", asignable: true },
  { id: 2608, nombre: "CREEL ABOGADOS", asignable: true },
  {
    id: 4469436,
    nombre: "CT-IOS OFFICES NORTE SA DE CV - Corporativo",
    asignable: true
  },
  {
    id: 3965420,
    nombre: "CT-UNIVERSIDAD XOCHICALCO - Corporativo",
    asignable: true
  },
  {
    id: 1502280,
    nombre: "CT-UNIVERSIDAD XOCHICALCO - Part Time Jr-Sr",
    asignable: true
  },
  {
    id: 4476848,
    nombre: "CT-VOLARIS S.A DE C..V - Corporativo",
    asignable: true
  },
  {
    id: 2383,
    nombre: "CT_ SAT SERVICIO DE ADMINISTRACION TRIBUTARIA",
    asignable: true
  },
  {
    id: 4558679,
    nombre: "CT_ SAT SERVICIO DE ADMINISTRACION TRIBUTARIA - Academia",
    asignable: true
  },
  {
    id: 4415699,
    nombre: "CT_ SAT SERVICIO DE ADMINISTRACION TRIBUTARIA - Corporativo",
    asignable: true
  },
  { id: 2734, nombre: "CT_BANCO SANTANDER", asignable: true },
  { id: 5066102, nombre: "CT_BANCO SANTANDER - Corporativo", asignable: true },
  { id: 10255234, nombre: "CT_BANCO SANTANDER - Uniclub CT", asignable: true },
  { id: 4738121, nombre: "CT_BANORTE - Corporativo", asignable: true },
  { id: 9591307, nombre: "CT_BANORTE - Uniclub CT", asignable: true },
  { id: 2436, nombre: "CT_CEMEX", asignable: true },
  { id: 9137436, nombre: "CT_CEMEX - Uniclub CT", asignable: true },
  {
    id: 4534291,
    nombre: "CT_CFE (COMISION FEDERAL DE ELECTRICIDAD) - Corporativo",
    asignable: true
  },
  {
    id: 9178697,
    nombre: "CT_CFE (COMISION FEDERAL DE ELECTRICIDAD) - Uniclub CT",
    asignable: true
  },
  { id: 4021, nombre: "CT_ESPECIAL GYM PLUS", asignable: true },
  {
    id: 15082771,
    nombre: "CT_ESPECIAL GYM PLUS - Uniclub CT",
    asignable: true
  },
  { id: 2490, nombre: "CT_FLEXTRONICS (Availmed S.A de C.V)", asignable: true },
  {
    id: 4313784,
    nombre:
      "CT_GRUPO CALIENTE TIJUANA (HIPODROMO DE AGUA CALIENTE SA DE CV) - Corporativo",
    asignable: true
  },
  {
    id: 148992,
    nombre:
      "CT_GRUPO CALIENTE TIJUANA (HIPODROMO DE AGUA CALIENTE SA DE CV) - Uniclub",
    asignable: true
  },
  {
    id: 4947510,
    nombre:
      "CT_GRUPO EFE  (Especialistas en Finanzas  y Economia) - Corporativo",
    asignable: true
  },
  {
    id: 10015170,
    nombre:
      "CT_GRUPO EFE  (Especialistas en Finanzas  y Economia) - Uniclub CT",
    asignable: true
  },
  {
    id: 4656589,
    nombre: "CT_GRUPO FINANCIERO MONEX - Corporativo",
    asignable: true
  },
  {
    id: 9426263,
    nombre: "CT_GRUPO FINANCIERO MONEX - Uniclub CT",
    asignable: true
  },
  {
    id: 4769622,
    nombre: "CT_GUARDERIA INFANTIL CUIDADOS CON AMOR - Corporativo",
    asignable: true
  },
  {
    id: 4439788,
    nombre:
      "CT_HOSPITAL ANGELES (Prestadora de servicios Honense) - Corporativo",
    asignable: true
  },
  {
    id: 8987396,
    nombre:
      "CT_HOSPITAL ANGELES (Prestadora de servicios Honense) - Uniclub CT",
    asignable: true
  },
  { id: 3649, nombre: "CT_HOTEL LUCERNA", asignable: true },
  { id: 6761597, nombre: "CT_HOTEL LUCERNA - Corporativo", asignable: true },
  {
    id: 14951486,
    nombre: "CT_INDIVIDUAL SOLO GYM + CLASES - Uniclub CT",
    asignable: true
  },
  { id: 7623305, nombre: "CT_INDIVIDUAL SOLO GYM - Academia", asignable: true },
  {
    id: 14947735,
    nombre: "CT_INDIVIDUAL SOLO GYM - Uniclub CT",
    asignable: true
  },
  {
    id: 3987,
    nombre: "CT_INDIVIDUAL SOLO GYM CLASES+ALBERCA",
    asignable: true
  },
  {
    id: 14955237,
    nombre: "CT_INDIVIDUAL SOLO GYM CLASES+ALBERCA - Uniclub CT",
    asignable: true
  },
  {
    id: 3988,
    nombre: "CT_INDIVIDUAL SOLO GYM CLASES+ALBERCA+TENIS",
    asignable: true
  },
  {
    id: 14958988,
    nombre: "CT_INDIVIDUAL SOLO GYM CLASES+ALBERCA+TENIS - Uniclub CT",
    asignable: true
  },
  { id: 4598852, nombre: "CT_ISSSTE - Academia", asignable: true },
  { id: 4454612, nombre: "CT_ISSSTE - Corporativo", asignable: true },
  { id: 9017404, nombre: "CT_ISSSTE - Uniclub CT", asignable: true },
  {
    id: 4712179,
    nombre: "CT_KPMG CARDENAS DOSAL, S.C - Corporativo",
    asignable: true
  },
  {
    id: 9538793,
    nombre: "CT_KPMG CARDENAS DOSAL, S.C - Uniclub CT",
    asignable: true
  },
  { id: 4817800, nombre: "CT_MEDTRONIC - Corporativo", asignable: true },
  {
    id: 2407,
    nombre: "CT_MERCEDES-BENZ (AutoProductos de la Costa S.A de C.V",
    asignable: true
  },
  {
    id: 4449053,
    nombre: "CT_TELCEL (Radiomovil Dipsa S.A de C.V) - Corporativo",
    asignable: true
  },
  {
    id: 9006151,
    nombre: "CT_TELCEL (Radiomovil Dipsa S.A de C.V) - Uniclub CT",
    asignable: true
  },
  {
    id: 2630,
    nombre: "CT_UNIVERSIDAD AUTONOMA DE BAJA CALIFORNIA",
    asignable: true
  },
  {
    id: 4873390,
    nombre: "CT_UNIVERSIDAD AUTONOMA DE BAJA CALIFORNIA - Corporativo",
    asignable: true
  },
  { id: 3586, nombre: "CUSTOM ROCK", asignable: true },
  {
    id: 1901,
    nombre:
      "DAIMLER SERVICIOS CORPORATIVOS MEXICO S DE RL DE CV (MERCEDEZ BENZ)",
    asignable: true
  },
  { id: 3404, nombre: "DAN HENRY", asignable: true },
  { id: 3851, nombre: "DANFOS", asignable: true },
  { id: 1784, nombre: "DANONE", asignable: true },
  { id: 3508, nombre: "DE LA RIVA DESPACHO", asignable: true },
  { id: 3418, nombre: "DE LA VEGA Y MARTINEZ ROJAS S.C.", asignable: true },
  { id: 2869, nombre: "DEACERO SAPI S.A DE C.V", asignable: true },
  { id: 19512367, nombre: "DEAL SA DE CV", asignable: true },
  {
    id: 1867,
    nombre:
      "DELL MEXICO SA DE CV, Dell Computer Services de México, S.A. de C.V",
    asignable: true
  },
  { id: 1799, nombre: "DELOITTE", asignable: true },
  { id: 3048, nombre: "DENTEGRA", asignable: true },
  { id: 3879, nombre: "DESIGN HUNTER 2021", asignable: true },
  { id: 2033, nombre: "DEUTSCHE BANK MEXICO SA DE CV", asignable: true },
  {
    id: 1921,
    nombre: "DHL EXPRESS MEXICO, S.A. DE C.V./Global",
    asignable: true
  },
  { id: 3172, nombre: "DIAGEO MEXICO S.A DE C.V", asignable: true },
  { id: 19512396, nombre: "DICIPA SA DE CV ", asignable: true },
  { id: 1981, nombre: "DIEBOLD DE MEXICO", asignable: true },
  {
    id: 3094,
    nombre: "DISCOVERY NETWORKS MEXICO S DE RL DE CV",
    asignable: true
  },
  { id: 1888, nombre: "DISH SA DE CV", asignable: true },
  { id: 3608, nombre: "DISPOSITIVOS DE CONTROL SA DE CV", asignable: true },
  { id: 19512413, nombre: "DORAMA, INSTITUCION DE GARANTIAS", asignable: true },
  { id: 2754, nombre: "DOW QUIMICA MEXICANA S.A DE C.V", asignable: true },
  { id: 3183, nombre: "DUPONT", asignable: true },
  { id: 2058, nombre: "DXC Technology antes HP", asignable: true },
  { id: 3601, nombre: "ECOLAB S. DE RL DE CV", asignable: true },
  { id: 19512380, nombre: "EGRESADOS IBERO", asignable: true },
  { id: 19512382, nombre: "EGRESADOS LOTTUS", asignable: true },
  { id: 19512381, nombre: "EGRESADOS TEC DE MONTERREY", asignable: true },
  { id: 19512379, nombre: "EGRESADOS UNAM", asignable: true },
  { id: 19512368, nombre: "EGRESADOS UVM", asignable: true },
  { id: 2649, nombre: "EHD GIMNASIO 1", asignable: true },
  { id: 6015879, nombre: "EHD GIMNASIO 1 - Uniclub2", asignable: true },
  { id: 19512410, nombre: "EL PUERTO DE LIVERPOOL", asignable: true },
  { id: 3770, nombre: "ELECTRONICA STEREN SA DE CV", asignable: true },
  { id: 3047, nombre: "ELEMENT", asignable: true },
  { id: 2667, nombre: "ELIUD CASTILLO ORTEGA", asignable: true },
  {
    id: 2520,
    nombre: "EMBAJADA DEL REINO UNIDO DE LA GRAN BRETAÑA E IRLANDA DEL NORTE",
    asignable: true
  },
  { id: 4016, nombre: "EMBAJADA USA", asignable: true },
  { id: 1957, nombre: "EMC MEXICO SERVICIOS SA DE CV", asignable: true },
  {
    id: 2425,
    nombre: "EMERSON ELECTRIC DE MEXICO(SUBSIDIARIAS Y FILIALES)",
    asignable: true
  },
  {
    id: 3406,
    nombre: "EMPLEADOS AMIS FITNESS CENTER ARBOLEDAS",
    asignable: true
  },
  {
    id: 3407,
    nombre: "EMPLEADOS AMIS FITNESS CENTER LUNA PARC",
    asignable: true
  },
  { id: 2928, nombre: "EMPLEADOS CAFETERIA ERIC ALONSO", asignable: true },
  { id: 3429, nombre: "EMPLEADOS CLUB TARANGO", asignable: true },
  { id: 2432, nombre: "EMPLEADOS EXERCISEMENT", asignable: true },
  {
    id: 1988,
    nombre: "EMPLEADOS PLA USOS MIXTOS SERVICIOS S DE RL DE CV (VYVE)",
    asignable: true
  },
  { id: 2124, nombre: "EMPLEADOS SW", asignable: true },
  { id: 131688, nombre: "EMPLEADOS SW - Allclub", asignable: true },
  { id: 4829976, nombre: "EMPLEADOS SW - Allclub2", asignable: true },
  { id: 4823604, nombre: "EMPLEADOS SW - Uniclub2", asignable: true },
  { id: 3077, nombre: "EMPLEADOS WORLDGYM TIJUANA", asignable: true },
  { id: 2207, nombre: "Energy Reforma", asignable: true },
  { id: 2099, nombre: "ENSO MEDIA SA DE CV", asignable: true },
  {
    id: 1893,
    nombre: "ENTORNO CORPORATIVO Y EMPRESARIAL SC/PRESTADORA EV OUTSORCING",
    asignable: true
  },
  { id: 19512418, nombre: "ENTROPÍA AI", asignable: true },
  { id: 19512378, nombre: "EPAM SYSTEM MEXICO S DE RL DE CV", asignable: true },
  { id: 3596, nombre: "EPIC ENDURO", asignable: true },
  { id: 3536, nombre: "EPICOR SOFWARE MEXICO", asignable: true },
  { id: 2052, nombre: "EQUIPO TRIATLON", asignable: true },
  { id: 3716, nombre: "ERICSSON TELECOM", asignable: true },
  { id: 3993, nombre: "ESP 2021 AMIGOS Y FAM SW", asignable: true },
  {
    id: 14909862,
    nombre: "ESP 2021 AMIGOS Y FAM SW - Allclub 7",
    asignable: true
  },
  {
    id: 5722354,
    nombre: "ESP ABRIL TRI-LAG-PRA - Uniclub 2x1 Zona centro",
    asignable: true
  },
  { id: 3294, nombre: "ESP ADIDAS", asignable: true },
  { id: 11973690, nombre: "ESP ADIDAS - Allclub 6", asignable: true },
  { id: 11598174, nombre: "ESP ADIDAS - Infantil 5", asignable: true },
  { id: 11601468, nombre: "ESP ADIDAS - Infantil 5 Uniclub", asignable: true },
  {
    id: 8272180,
    nombre: "ESP ADMNISTRACION PLAZA MAYOR LEON - Uniclub 6A",
    asignable: true
  },
  { id: 2951, nombre: "ESP ALSEA BARRANCA", asignable: true },
  { id: 9443200, nombre: "ESP ALSEA BARRANCA - Uniclub 4", asignable: true },
  { id: 6701721, nombre: "ESP ALSEA BARRANCA - Uniclub2", asignable: true },
  { id: 3218, nombre: "ESP ALSEA BARRANCA I", asignable: true },
  { id: 11697430, nombre: "ESP ALSEA BARRANCA I - Allclub 6", asignable: true },
  { id: 10297600, nombre: "ESP ALSEA BARRANCA I - Uniclub 4", asignable: true },
  { id: 11655596, nombre: "ESP ALSEA BARRANCA I - Uniclub 5", asignable: true },
  { id: 2908, nombre: "ESP ALSEA BARRANCA MAYO2017", asignable: true },
  {
    id: 6604068,
    nombre: "ESP ALSEA BARRANCA MAYO2017 - Uniclub2",
    asignable: true
  },
  { id: 2967, nombre: "ESP AMEX CONDESA", asignable: true },
  { id: 9491433, nombre: "ESP AMEX CONDESA - Allclub 4", asignable: true },
  { id: 6746958, nombre: "ESP AMEX CONDESA - Allclub2", asignable: true },
  { id: 9494400, nombre: "ESP AMEX CONDESA - Uniclub 4", asignable: true },
  { id: 6738057, nombre: "ESP AMEX CONDESA - Uniclub2", asignable: true },
  { id: 10332770, nombre: "ESP AMEX CONDESA I - Allclub 4", asignable: true },
  { id: 10336000, nombre: "ESP AMEX CONDESA I - Uniclub 4", asignable: true },
  { id: 3017, nombre: "ESP BACK TO SCHOOL", asignable: true },
  { id: 8918252, nombre: "ESP BACK TO SCHOOL - Allclub 2.0", asignable: true },
  { id: 9651383, nombre: "ESP BACK TO SCHOOL - Allclub 4", asignable: true },
  { id: 6860658, nombre: "ESP BACK TO SCHOOL - Allclub2", asignable: true },
  {
    id: 9660434,
    nombre: "ESP BACK TO SCHOOL - Pareja Allclub 4",
    asignable: true
  },
  { id: 9654400, nombre: "ESP BACK TO SCHOOL - Uniclub 4", asignable: true },
  { id: 3138, nombre: "ESP BACK TO SCHOOL 2018", asignable: true },
  {
    id: 10038462,
    nombre: "ESP BACK TO SCHOOL 2018 - Allclub 4",
    asignable: true
  },
  {
    id: 10041600,
    nombre: "ESP BACK TO SCHOOL 2018 - Uniclub 4",
    asignable: true
  },
  { id: 3212, nombre: "ESP BACK TO SCHOOL 2018 I", asignable: true },
  {
    id: 10275188,
    nombre: "ESP BACK TO SCHOOL 2018 I - Allclub 4",
    asignable: true
  },
  {
    id: 10278400,
    nombre: "ESP BACK TO SCHOOL 2018 I - Uniclub 4",
    asignable: true
  },
  { id: 3290, nombre: "ESP BACK TO SCHOOL 2018 II", asignable: true },
  {
    id: 10524710,
    nombre: "ESP BACK TO SCHOOL 2018 II - Allclub 4",
    asignable: true
  },
  {
    id: 11959150,
    nombre: "ESP BACK TO SCHOOL 2018 II - Allclub 6",
    asignable: true
  },
  {
    id: 11916380,
    nombre: "ESP BACK TO SCHOOL 2018 II - Uniclub 5",
    asignable: true
  },
  {
    id: 11965730,
    nombre: "ESP BACK TO SCHOOL 2018 II - Uniclub 6",
    asignable: true
  },
  {
    id: 12074300,
    nombre: "ESP BACK TO SCHOOL 2018 II - Uniclub 6A",
    asignable: true
  },
  { id: 2980, nombre: "ESP BACK TO SCHOOL AGOSTO 2017", asignable: true },
  {
    id: 8808880,
    nombre: "ESP BACK TO SCHOOL AGOSTO 2017 - Allclub 2.0",
    asignable: true
  },
  {
    id: 6776520,
    nombre: "ESP BACK TO SCHOOL AGOSTO 2017 - Allclub2",
    asignable: true
  },
  {
    id: 6767580,
    nombre: "ESP BACK TO SCHOOL AGOSTO 2017 - Uniclub2",
    asignable: true
  },
  {
    id: 3477,
    nombre: "ESP BACK TO SCHOOL FORANEOS 3 AÑOS 2019",
    asignable: true
  },
  {
    id: 13083951,
    nombre: "ESP BACK TO SCHOOL FORANEOS 3 AÑOS 2019 - Uniclub 6 3Anios",
    asignable: true
  },
  { id: 3010, nombre: "ESP BACK TO SCHOOL MULTICLUB 2017", asignable: true },
  {
    id: 9400230,
    nombre: "ESP BACK TO SCHOOL MULTICLUB 2017 - Multiclub 2",
    asignable: true
  },
  {
    id: 6835710,
    nombre: "ESP BACK TO SCHOOL MULTICLUB 2017 - Uniclub2",
    asignable: true
  },
  {
    id: 10971099,
    nombre: "ESP BACK TO SCHOOL UNICLUB  2020 - Multiclub 2",
    asignable: true
  },
  {
    id: 11241600,
    nombre: "ESP BACK TO SCHOOL UNICLUB  2020 - Uniclub 4",
    asignable: true
  },
  {
    id: 12776781,
    nombre: "ESP BACK TO SCHOOL UNICLUB  2020 - Uniclub 6",
    asignable: true
  },
  {
    id: 12892710,
    nombre: "ESP BACK TO SCHOOL UNICLUB  2020 - Uniclub 6A",
    asignable: true
  },
  {
    id: 15875247,
    nombre: "ESP BACK TO SCHOOL UNICLUB  2020 - Uniclub R",
    asignable: true
  },
  { id: 3937, nombre: "ESP BACK TO SCHOOL UNICLUB  2020 1", asignable: true },
  {
    id: 14318869,
    nombre: "ESP BACK TO SCHOOL UNICLUB  2020 1 - Uniclub 6",
    asignable: true
  },
  { id: 3938, nombre: "ESP BACK TO SCHOOL UNICLUB 2020 2", asignable: true },
  {
    id: 14322506,
    nombre: "ESP BACK TO SCHOOL UNICLUB 2020 2 - Uniclub 6",
    asignable: true
  },
  {
    id: 3955,
    nombre: "ESP BACK TO SCHOOL UNICLUB QUERETARO 2020",
    asignable: true
  },
  {
    id: 14384335,
    nombre: "ESP BACK TO SCHOOL UNICLUB QUERETARO 2020 - Uniclub 6",
    asignable: true
  },
  { id: 3024, nombre: "ESP BANAMEX BARRANCA", asignable: true },
  { id: 8808912, nombre: "ESP BANAMEX BARRANCA - Part Time3", asignable: true },
  { id: 9676800, nombre: "ESP BANAMEX BARRANCA - Uniclub 4", asignable: true },
  { id: 6867504, nombre: "ESP BANAMEX BARRANCA - Uniclub2", asignable: true },
  { id: 3217, nombre: "ESP BANAMEX BARRANCA I", asignable: true },
  {
    id: 11693795,
    nombre: "ESP BANAMEX BARRANCA I - Allclub 6",
    asignable: true
  },
  {
    id: 10294400,
    nombre: "ESP BANAMEX BARRANCA I - Uniclub 4",
    asignable: true
  },
  {
    id: 11651974,
    nombre: "ESP BANAMEX BARRANCA I - Uniclub 5",
    asignable: true
  },
  {
    id: 11700229,
    nombre: "ESP BANAMEX BARRANCA I - Uniclub 6",
    asignable: true
  },
  {
    id: 135160,
    nombre:
      "ESP BANCO DEL AHORRO NACIONAL Y SERVICIOS FINANCIEROS BANSEFI - Allclub",
    asignable: true
  },
  {
    id: 4957320,
    nombre:
      "ESP BANCO DEL AHORRO NACIONAL Y SERVICIOS FINANCIEROS BANSEFI - Allclub2",
    asignable: true
  },
  { id: 3103, nombre: "ESP BANCOMEXT CRATER", asignable: true },
  { id: 9926497, nombre: "ESP BANCOMEXT CRATER - Allclub 4", asignable: true },
  { id: 3390, nombre: "ESP BEAM SUNTORY BARRANCA", asignable: true },
  {
    id: 12322650,
    nombre: "ESP BEAM SUNTORY BARRANCA - Allclub 6",
    asignable: true
  },
  {
    id: 2923,
    nombre: "ESP BEBIDAS INTERNACIONALES BEPENSA SA DE CV",
    asignable: true
  },
  {
    id: 9350677,
    nombre: "ESP BEBIDAS INTERNACIONALES BEPENSA SA DE CV - Allclub 4",
    asignable: true
  },
  {
    id: 6646902,
    nombre: "ESP BEBIDAS INTERNACIONALES BEPENSA SA DE CV - Allclub2",
    asignable: true
  },
  {
    id: 9353600,
    nombre: "ESP BEBIDAS INTERNACIONALES BEPENSA SA DE CV - Uniclub 4",
    asignable: true
  },
  {
    id: 6638133,
    nombre: "ESP BEBIDAS INTERNACIONALES BEPENSA SA DE CV - Uniclub2",
    asignable: true
  },
  { id: 3064, nombre: "ESP C+ REFORMA", asignable: true },
  { id: 6967536, nombre: "ESP C+ REFORMA - Allclub2", asignable: true },
  { id: 2635, nombre: "ESP CAMPUS CORPORATIVO COYOACAN", asignable: true },
  {
    id: 5984085,
    nombre: "ESP CAMPUS CORPORATIVO COYOACAN - Uniclub2",
    asignable: true
  },
  { id: 2464, nombre: "ESP CIBERGESTION SAN JERONIMO", asignable: true },
  {
    id: 170016,
    nombre: "ESP CIBERGESTION SAN JERONIMO - Multiclub",
    asignable: true
  },
  { id: 3029, nombre: "ESP CINEMEX CORPORATIVO", asignable: true },
  {
    id: 9689771,
    nombre: "ESP CINEMEX CORPORATIVO - Allclub 4",
    asignable: true
  },
  {
    id: 11010415,
    nombre: "ESP CINEMEX CORPORATIVO - Allclub 6",
    asignable: true
  },
  {
    id: 6887946,
    nombre: "ESP CINEMEX CORPORATIVO - Allclub2",
    asignable: true
  },
  { id: 12165765, nombre: "ESP CINEPOLIS PATRIO - Uniclub 6", asignable: true },
  { id: 2947, nombre: "ESP CLUB TEN", asignable: true },
  { id: 6701478, nombre: "ESP CLUB TEN - Allclub2", asignable: true },
  {
    id: 8587558,
    nombre: "ESP CLUB TEN - Part Time corporativa2",
    asignable: true
  },
  { id: 2964, nombre: "ESP CLUB TEN JULIO 2017 INDIVIDUAL", asignable: true },
  {
    id: 6740136,
    nombre: "ESP CLUB TEN JULIO 2017 INDIVIDUAL - Allclub2",
    asignable: true
  },
  { id: 2159, nombre: "ESP COMUNIDAD COLEGIO INTERNACIONAL", asignable: true },
  {
    id: 133858,
    nombre: "ESP COMUNIDAD COLEGIO INTERNACIONAL - Allclub",
    asignable: true
  },
  {
    id: 7847965,
    nombre: "ESP COMUNIDAD COLEGIO INTERNACIONAL - Allclub 6",
    asignable: true
  },
  {
    id: 7601839,
    nombre: "ESP COMUNIDAD COLEGIO INTERNACIONAL - Infantil 5",
    asignable: true
  },
  {
    id: 7603998,
    nombre: "ESP COMUNIDAD COLEGIO INTERNACIONAL - Infantil 5 Uniclub",
    asignable: true
  },
  {
    id: 138176,
    nombre: "ESP COMUNIDAD COLEGIO INTERNACIONAL - Uniclub",
    asignable: true
  },
  {
    id: 7852283,
    nombre: "ESP COMUNIDAD COLEGIO INTERNACIONAL - Uniclub 6",
    asignable: true
  },
  { id: 3307, nombre: "ESP CONECTA PALMAS", asignable: true },
  { id: 10579093, nombre: "ESP CONECTA PALMAS - Allclub 4", asignable: true },
  { id: 12020945, nombre: "ESP CONECTA PALMAS - Allclub 6", asignable: true },
  { id: 7520118, nombre: "ESP CONECTA PALMAS - Allclub2", asignable: true },
  { id: 3774, nombre: "ESP CORPORATIVO METEPEC 2020", asignable: true },
  {
    id: 233988,
    nombre: "ESP CORPORATIVO METEPEC 2020 - Allclub",
    asignable: true
  },
  {
    id: 8582076,
    nombre: "ESP CORPORATIVO METEPEC 2020 - Allclub2",
    asignable: true
  },
  { id: 3054, nombre: "ESP CREDIT SUISSE", asignable: true },
  { id: 6944796, nombre: "ESP CREDIT SUISSE - Allclub2", asignable: true },
  {
    id: 12522191,
    nombre: "ESP DE LA RIVA BARRANCA - Uniclub 6",
    asignable: true
  },
  { id: 12188155, nombre: "ESP DELMAR MANACAR - Allclub 6", asignable: true },
  { id: 3553, nombre: "ESP DESPACHO PABLO OLVERA ESP", asignable: true },
  {
    id: 2994,
    nombre: "ESP DEUTSCHE QUÍMICA, S.A. DE C.V. XOLA",
    asignable: true
  },
  {
    id: 9577806,
    nombre: "ESP DEUTSCHE QUÍMICA, S.A. DE C.V. XOLA - Allclub 4",
    asignable: true
  },
  {
    id: 6808356,
    nombre: "ESP DEUTSCHE QUÍMICA, S.A. DE C.V. XOLA - Allclub2",
    asignable: true
  },
  {
    id: 6796380,
    nombre: "ESP DEUTSCHE QUÍMICA, S.A. DE C.V. XOLA - Infantil",
    asignable: true
  },
  {
    id: 3299,
    nombre: "ESP DEUTSCHE QUÍMICA, S.A. DE C.V. XOLA 1",
    asignable: true
  },
  {
    id: 10553501,
    nombre: "ESP DEUTSCHE QUÍMICA, S.A. DE C.V. XOLA 1 - Allclub 4",
    asignable: true
  },
  {
    id: 11991865,
    nombre: "ESP DEUTSCHE QUÍMICA, S.A. DE C.V. XOLA 1 - Allclub 6",
    asignable: true
  },
  {
    id: 11948978,
    nombre: "ESP DEUTSCHE QUÍMICA, S.A. DE C.V. XOLA 1 - Uniclub 5",
    asignable: true
  },
  { id: 3401, nombre: "ESP DROMO GOES", asignable: true },
  { id: 12362635, nombre: "ESP DROMO GOES - Allclub 6", asignable: true },
  { id: 2648, nombre: "ESP EHD 20 GIMNASIO", asignable: true },
  { id: 6013608, nombre: "ESP EHD 20 GIMNASIO - Uniclub2", asignable: true },
  { id: 3145, nombre: "ESP ELEKTRA", asignable: true },
  { id: 10060855, nombre: "ESP ELEKTRA - Allclub 4", asignable: true },
  { id: 11432075, nombre: "ESP ELEKTRA - Allclub 6", asignable: true },
  { id: 10064000, nombre: "ESP ELEKTRA - Uniclub 4", asignable: true },
  { id: 14969606, nombre: "ESP EMBAJADA EU 2021 - Allclub 7", asignable: true },
  { id: 2910, nombre: "ESP EMPLEADOS SW VENTA ESPECIAL", asignable: true },
  {
    id: 9309090,
    nombre: "ESP EMPLEADOS SW VENTA ESPECIAL - Allclub 4",
    asignable: true
  },
  {
    id: 10577850,
    nombre: "ESP EMPLEADOS SW VENTA ESPECIAL - Allclub 6",
    asignable: true
  },
  {
    id: 6617340,
    nombre: "ESP EMPLEADOS SW VENTA ESPECIAL - Allclub2",
    asignable: true
  },
  {
    id: 6605700,
    nombre: "ESP EMPLEADOS SW VENTA ESPECIAL - Infantil",
    asignable: true
  },
  {
    id: 10246110,
    nombre: "ESP EMPLEADOS SW VENTA ESPECIAL - Infantil 5",
    asignable: true
  },
  {
    id: 10598220,
    nombre: "ESP EMPLEADOS SW VENTA ESPECIAL - Multiclub Foraneo 4",
    asignable: true
  },
  {
    id: 10583670,
    nombre: "ESP EMPLEADOS SW VENTA ESPECIAL - Uniclub 6",
    asignable: true
  },
  { id: 3414, nombre: "ESP EMPRESALIA QUERETARO", asignable: true },
  { id: 2672, nombre: "ESP ENERGY ALL CLUB", asignable: true },
  { id: 6076128, nombre: "ESP ENERGY ALL CLUB - Allclub2", asignable: true },
  { id: 6068112, nombre: "ESP ENERGY ALL CLUB - Uniclub2", asignable: true },
  { id: 2673, nombre: "ESP ENERGY UNICLUB", asignable: true },
  {
    id: 6781401,
    nombre: "ESP ENERGY UNICLUB - Pareja Uniclub2",
    asignable: true
  },
  { id: 6075729, nombre: "ESP ENERGY UNICLUB - Part Time2", asignable: true },
  {
    id: 5287194,
    nombre: "ESP ENERGY UNICLUB - Uniclub 2x1 Zona centro",
    asignable: true
  },
  { id: 6070383, nombre: "ESP ENERGY UNICLUB - Uniclub2", asignable: true },
  { id: 3293, nombre: "ESP EQUIPO TRIATLON INTERLOMAS", asignable: true },
  {
    id: 204166,
    nombre: "ESP EQUIPO TRIATLON INTERLOMAS - Allclub",
    asignable: true
  },
  {
    id: 11970055,
    nombre: "ESP EQUIPO TRIATLON INTERLOMAS - Allclub 6",
    asignable: true
  },
  {
    id: 7488282,
    nombre: "ESP EQUIPO TRIATLON INTERLOMAS - Allclub2",
    asignable: true
  },
  {
    id: 11594653,
    nombre: "ESP EQUIPO TRIATLON INTERLOMAS - Infantil 5",
    asignable: true
  },
  {
    id: 11966762,
    nombre: "ESP EQUIPO TRIATLON INTERLOMAS - Triatlon Jr",
    asignable: true
  },
  { id: 2925, nombre: "ESP EVEN LABS", asignable: true },
  { id: 9357075, nombre: "ESP EVEN LABS - Allclub 4", asignable: true },
  { id: 6651450, nombre: "ESP EVEN LABS - Allclub2", asignable: true },
  { id: 9360000, nombre: "ESP EVEN LABS - Uniclub 4", asignable: true },
  { id: 6642675, nombre: "ESP EVEN LABS - Uniclub2", asignable: true },
  { id: 3313, nombre: "ESP EVEN LABS I", asignable: true },
  { id: 10598287, nombre: "ESP EVEN LABS I - Allclub 4", asignable: true },
  { id: 12042755, nombre: "ESP EVEN LABS I - Allclub 6", asignable: true },
  { id: 11999686, nombre: "ESP EVEN LABS I - Uniclub 5", asignable: true },
  { id: 12049381, nombre: "ESP EVEN LABS I - Uniclub 6", asignable: true },
  { id: 2982, nombre: "ESP EX SOCIOS CERO2 QRO", asignable: true },
  {
    id: 6772122,
    nombre: "ESP EX SOCIOS CERO2 QRO - Uniclub2",
    asignable: true
  },
  { id: 2938, nombre: "ESP EX SOCIOS SC 2017", asignable: true },
  {
    id: 8684728,
    nombre: "ESP EX SOCIOS SC 2017 - Allclub 2.0",
    asignable: true
  },
  { id: 6681012, nombre: "ESP EX SOCIOS SC 2017 - Allclub2", asignable: true },
  { id: 6672198, nombre: "ESP EX SOCIOS SC 2017 - Uniclub2", asignable: true },
  { id: 3111, nombre: "ESP EX SOCIOS SC Y OTROS 2018", asignable: true },
  {
    id: 9952089,
    nombre: "ESP EX SOCIOS SC Y OTROS 2018 - Allclub 4",
    asignable: true
  },
  {
    id: 9961422,
    nombre: "ESP EX SOCIOS SC Y OTROS 2018 - Pareja Allclub 4",
    asignable: true
  },
  {
    id: 9958311,
    nombre: "ESP EX SOCIOS SC Y OTROS 2018 - Pareja Uniclub 4",
    asignable: true
  },
  {
    id: 9955200,
    nombre: "ESP EX SOCIOS SC Y OTROS 2018 - Uniclub 4",
    asignable: true
  },
  { id: 3213, nombre: "ESP EX SOCIOS SC Y OTROS 2018 I", asignable: true },
  {
    id: 10278387,
    nombre: "ESP EX SOCIOS SC Y OTROS 2018 I - Allclub 4",
    asignable: true
  },
  {
    id: 10288026,
    nombre: "ESP EX SOCIOS SC Y OTROS 2018 I - Pareja Allclub 4",
    asignable: true
  },
  {
    id: 10284813,
    nombre: "ESP EX SOCIOS SC Y OTROS 2018 I - Pareja Uniclub 4",
    asignable: true
  },
  {
    id: 10281600,
    nombre: "ESP EX SOCIOS SC Y OTROS 2018 I - Uniclub 4",
    asignable: true
  },
  { id: 3289, nombre: "ESP EX SOCIOS SC Y OTROS 2018 II", asignable: true },
  {
    id: 10521511,
    nombre: "ESP EX SOCIOS SC Y OTROS 2018 II - Allclub 4",
    asignable: true
  },
  {
    id: 11955515,
    nombre: "ESP EX SOCIOS SC Y OTROS 2018 II - Allclub 6",
    asignable: true
  },
  {
    id: 11902891,
    nombre: "ESP EX SOCIOS SC Y OTROS 2018 II - Pareja Allclub 5",
    asignable: true
  },
  {
    id: 11912758,
    nombre: "ESP EX SOCIOS SC Y OTROS 2018 II - Uniclub 5",
    asignable: true
  },
  {
    id: 11962093,
    nombre: "ESP EX SOCIOS SC Y OTROS 2018 II - Uniclub 6",
    asignable: true
  },
  {
    id: 12070630,
    nombre: "ESP EX SOCIOS SC Y OTROS 2018 II - Uniclub 6A",
    asignable: true
  },
  { id: 2902, nombre: "ESP EX SOCIOS SC Y OTROS NVO", asignable: true },
  {
    id: 8578312,
    nombre: "ESP EX SOCIOS SC Y OTROS NVO - Allclub 2.0",
    asignable: true
  },
  {
    id: 9283498,
    nombre: "ESP EX SOCIOS SC Y OTROS NVO - Allclub 4",
    asignable: true
  },
  {
    id: 6599148,
    nombre: "ESP EX SOCIOS SC Y OTROS NVO - Allclub2",
    asignable: true
  },
  {
    id: 9292204,
    nombre: "ESP EX SOCIOS SC Y OTROS NVO - Pareja Allclub 4",
    asignable: true
  },
  {
    id: 9286400,
    nombre: "ESP EX SOCIOS SC Y OTROS NVO - Uniclub 4",
    asignable: true
  },
  {
    id: 2934,
    nombre: "ESP EX USUARIOS CLUBES DEPORTIVOS METEPEC",
    asignable: true
  },
  {
    id: 6663114,
    nombre: "ESP EX USUARIOS CLUBES DEPORTIVOS METEPEC - Uniclub2",
    asignable: true
  },
  {
    id: 2935,
    nombre: "ESP EX USUARIOS CLUBES DEPORTIVOS REVOLUCION",
    asignable: true
  },
  {
    id: 9392000,
    nombre: "ESP EX USUARIOS CLUBES DEPORTIVOS REVOLUCION - Uniclub 4",
    asignable: true
  },
  {
    id: 6665385,
    nombre: "ESP EX USUARIOS CLUBES DEPORTIVOS REVOLUCION - Uniclub2",
    asignable: true
  },
  { id: 3016, nombre: "ESP EX USUARIOS GYM 2017", asignable: true },
  {
    id: 8915296,
    nombre: "ESP EX USUARIOS GYM 2017 - Allclub 2.0",
    asignable: true
  },
  {
    id: 6858384,
    nombre: "ESP EX USUARIOS GYM 2017 - Allclub2",
    asignable: true
  },
  {
    id: 6849336,
    nombre: "ESP EX USUARIOS GYM 2017 - Uniclub2",
    asignable: true
  },
  { id: 3625, nombre: "ESP EX USUARIOS GYM SANTA FE", asignable: true },
  { id: 3343, nombre: "ESP EXELTIS BARRANCA", asignable: true },
  { id: 12158491, nombre: "ESP EXELTIS BARRANCA - Uniclub 6", asignable: true },
  { id: 3980, nombre: "ESP EXERSITE ZONA ESMERALDA 2021", asignable: true },
  {
    id: 14861320,
    nombre: "ESP EXERSITE ZONA ESMERALDA 2021 - Allclub 7",
    asignable: true
  },
  {
    id: 14475260,
    nombre: "ESP EXERSITE ZONA ESMERALDA 2021 - Uniclub 6",
    asignable: true
  },
  { id: 2889, nombre: "ESP EXSC ABRIL SEMANA SANTA", asignable: true },
  {
    id: 6569586,
    nombre: "ESP EXSC ABRIL SEMANA SANTA - Allclub2",
    asignable: true
  },
  { id: 3827, nombre: "ESP EXSOCIOS 2020", asignable: true },
  { id: 14290018, nombre: "ESP EXSOCIOS 2020 - Allclub 7", asignable: true },
  {
    id: 17596546,
    nombre: "ESP EXSOCIOS 2020 - Pareja Allclub 7",
    asignable: true
  },
  { id: 12246400, nombre: "ESP EXSOCIOS 2020 - Uniclub 4", asignable: true },
  { id: 13861394, nombre: "ESP EXSOCIOS 2020 - Uniclub 5", asignable: true },
  { id: 13918799, nombre: "ESP EXSOCIOS 2020 - Uniclub 6", asignable: true },
  { id: 14045090, nombre: "ESP EXSOCIOS 2020 - Uniclub 6A", asignable: true },
  {
    id: 8013738,
    nombre: "ESP EXSOCIOS 2020 - Uniclub Foraneo",
    asignable: true
  },
  {
    id: 11293477,
    nombre: "ESP EXSOCIOS 2020 - Uniclub Promo",
    asignable: true
  },
  { id: 17294213, nombre: "ESP EXSOCIOS 2020 - Uniclub R", asignable: true },
  { id: 3924, nombre: "ESP EXSOCIOS 2020 1", asignable: true },
  { id: 3925, nombre: "ESP EXSOCIOS 2020 2", asignable: true },
  { id: 3926, nombre: "ESP EXSOCIOS 2020 3", asignable: true },
  { id: 3927, nombre: "ESP EXSOCIOS 2020 4", asignable: true },
  { id: 2830, nombre: "ESP EXSOCIOS SC", asignable: true },
  { id: 6435420, nombre: "ESP EXSOCIOS SC - Allclub2", asignable: true },
  { id: 3775, nombre: "ESP FABIAN BIFARETTI", asignable: true },
  { id: 13722125, nombre: "ESP FABIAN BIFARETTI - Allclub 6", asignable: true },
  { id: 13729675, nombre: "ESP FABIAN BIFARETTI - Uniclub 6", asignable: true },
  { id: 3609, nombre: "ESP FAMILIA GARCIA INTERLOMAS", asignable: true },
  {
    id: 13118715,
    nombre: "ESP FAMILIA GARCIA INTERLOMAS - Allclub 6",
    asignable: true
  },
  {
    id: 13409515,
    nombre: "ESP FAMILIARES DIRECTOS BBVA - Allclub 6",
    asignable: true
  },
  { id: 3021, nombre: "ESP FEEL GOOD TELEFONICA", asignable: true },
  {
    id: 9664179,
    nombre: "ESP FEEL GOOD TELEFONICA - Allclub 4",
    asignable: true
  },
  {
    id: 6869754,
    nombre: "ESP FEEL GOOD TELEFONICA - Allclub2",
    asignable: true
  },
  {
    id: 6860691,
    nombre: "ESP FEEL GOOD TELEFONICA - Uniclub2",
    asignable: true
  },
  {
    id: 3056,
    nombre: "ESP FLORERIA ANA PAULINA PEREZ CONDESA",
    asignable: true
  },
  {
    id: 6949344,
    nombre: "ESP FLORERIA ANA PAULINA PEREZ CONDESA - Allclub2",
    asignable: true
  },
  { id: 2880, nombre: "ESP FOVISSSTE BARRANCA", asignable: true },
  {
    id: 9216000,
    nombre: "ESP FOVISSSTE BARRANCA - Uniclub 4",
    asignable: true
  },
  { id: 6540480, nombre: "ESP FOVISSSTE BARRANCA - Uniclub2", asignable: true },
  { id: 3219, nombre: "ESP FOVISSSTE BARRANCA I", asignable: true },
  {
    id: 11701065,
    nombre: "ESP FOVISSSTE BARRANCA I - Allclub 6",
    asignable: true
  },
  {
    id: 10300800,
    nombre: "ESP FOVISSSTE BARRANCA I - Uniclub 4",
    asignable: true
  },
  {
    id: 11659218,
    nombre: "ESP FOVISSSTE BARRANCA I - Uniclub 5",
    asignable: true
  },
  { id: 3050, nombre: "ESP GALICIA ABOGADOS ANTARA PRADO", asignable: true },
  {
    id: 11086750,
    nombre: "ESP GALICIA ABOGADOS ANTARA PRADO - Allclub 6",
    asignable: true
  },
  {
    id: 6935700,
    nombre: "ESP GALICIA ABOGADOS ANTARA PRADO - Allclub2",
    asignable: true
  },
  { id: 3159, nombre: "ESP GIMNASIO SPA SATELITE", asignable: true },
  { id: 2955, nombre: "ESP GOOGLE PALMAS", asignable: true },
  { id: 10741425, nombre: "ESP GOOGLE PALMAS - Allclub 6", asignable: true },
  { id: 6719670, nombre: "ESP GOOGLE PALMAS - Allclub2", asignable: true },
  { id: 2998, nombre: "ESP GRUOPE ROCHER YVES", asignable: true },
  { id: 6817452, nombre: "ESP GRUOPE ROCHER YVES - Allclub2", asignable: true },
  { id: 3131, nombre: "ESP GRUPO BAL 2018", asignable: true },
  { id: 10016069, nombre: "ESP GRUPO BAL 2018 - Allclub 4", asignable: true },
  {
    id: 10025462,
    nombre: "ESP GRUPO BAL 2018 - Pareja Allclub 4",
    asignable: true
  },
  {
    id: 10022331,
    nombre: "ESP GRUPO BAL 2018 - Pareja Uniclub 4",
    asignable: true
  },
  { id: 10019200, nombre: "ESP GRUPO BAL 2018 - Uniclub 4", asignable: true },
  { id: 3438, nombre: "ESP GRUPO BATIA AMORES", asignable: true },
  {
    id: 12497130,
    nombre: "ESP GRUPO BATIA AMORES - Allclub 6",
    asignable: true
  },
  { id: 14364698, nombre: "ESP GRUPOS 2020 - Allclub 7", asignable: true },
  { id: 16407455, nombre: "ESP GRUPOS 2020 - Triclub MTY", asignable: true },
  { id: 10263796, nombre: "ESP GRUPOS 2020 - Triclub2", asignable: true },
  { id: 12310400, nombre: "ESP GRUPOS 2020 - Uniclub 4", asignable: true },
  { id: 13991539, nombre: "ESP GRUPOS 2020 - Uniclub 6", asignable: true },
  { id: 14118490, nombre: "ESP GRUPOS 2020 - Uniclub 6A", asignable: true },
  { id: 8055618, nombre: "ESP GRUPOS 2020 - Uniclub Foraneo", asignable: true },
  { id: 3873, nombre: "ESP GRUPOS 2020 1", asignable: true },
  { id: 14461782, nombre: "ESP GRUPOS 2020 1 - Allclub 7", asignable: true },
  { id: 16518345, nombre: "ESP GRUPOS 2020 1 - Triclub MTY", asignable: true },
  { id: 10333164, nombre: "ESP GRUPOS 2020 1 - Triclub2", asignable: true },
  { id: 12393600, nombre: "ESP GRUPOS 2020 1 - Uniclub 4", asignable: true },
  { id: 14086101, nombre: "ESP GRUPOS 2020 1 - Uniclub 6", asignable: true },
  { id: 14213910, nombre: "ESP GRUPOS 2020 1 - Uniclub 6A", asignable: true },
  {
    id: 8110062,
    nombre: "ESP GRUPOS 2020 1 - Uniclub Foraneo",
    asignable: true
  },
  { id: 3874, nombre: "ESP GRUPOS 2020 2", asignable: true },
  { id: 14465516, nombre: "ESP GRUPOS 2020 2 - Allclub 7", asignable: true },
  { id: 16522610, nombre: "ESP GRUPOS 2020 2 - Triclub MTY", asignable: true },
  { id: 10335832, nombre: "ESP GRUPOS 2020 2 - Triclub2", asignable: true },
  { id: 12396800, nombre: "ESP GRUPOS 2020 2 - Uniclub 4", asignable: true },
  { id: 14089738, nombre: "ESP GRUPOS 2020 2 - Uniclub 6", asignable: true },
  { id: 14217580, nombre: "ESP GRUPOS 2020 2 - Uniclub 6A", asignable: true },
  {
    id: 8112156,
    nombre: "ESP GRUPOS 2020 2 - Uniclub Foraneo",
    asignable: true
  },
  { id: 3875, nombre: "ESP GRUPOS 2020 3", asignable: true },
  { id: 14469250, nombre: "ESP GRUPOS 2020 3 - Allclub 7", asignable: true },
  { id: 3876, nombre: "ESP GRUPOS 2020 4", asignable: true },
  { id: 14472984, nombre: "ESP GRUPOS 2020 4 - Allclub 7", asignable: true },
  { id: 3922, nombre: "ESP GRUPOS 2020 5", asignable: true },
  { id: 14644748, nombre: "ESP GRUPOS 2020 5 - Allclub 7", asignable: true },
  { id: 3923, nombre: "ESP GRUPOS 2020 6", asignable: true },
  { id: 14648482, nombre: "ESP GRUPOS 2020 6 - Allclub 7", asignable: true },
  { id: 3928, nombre: "ESP GRUPOS 2020 7", asignable: true },
  { id: 14667152, nombre: "ESP GRUPOS 2020 7 - Allclub 7", asignable: true },
  { id: 3160, nombre: "ESP HAMPTON INN MERIDA", asignable: true },
  {
    id: 10112000,
    nombre: "ESP HAMPTON INN MERIDA - Uniclub 4",
    asignable: true
  },
  { id: 3457, nombre: "ESP HAUS PEDREGAL SAN JERONIMO", asignable: true },
  {
    id: 2856,
    nombre: "ESP ICE GALLERY INTERTEL SERVICES S de RL de C.V. XOLA 1",
    asignable: true
  },
  {
    id: 9139200,
    nombre:
      "ESP ICE GALLERY INTERTEL SERVICES S de RL de C.V. XOLA 1 - Uniclub 4",
    asignable: true
  },
  {
    id: 10344432,
    nombre:
      "ESP ICE GALLERY INTERTEL SERVICES S de RL de C.V. XOLA 1 - Uniclub 5",
    asignable: true
  },
  {
    id: 10387272,
    nombre:
      "ESP ICE GALLERY INTERTEL SERVICES S de RL de C.V. XOLA 1 - Uniclub 6",
    asignable: true
  },
  {
    id: 6485976,
    nombre:
      "ESP ICE GALLERY INTERTEL SERVICES S de RL de C.V. XOLA 1 - Uniclub2",
    asignable: true
  },
  { id: 3387, nombre: "ESP ICE XOLA 2018", asignable: true },
  { id: 12311745, nombre: "ESP ICE XOLA 2018 - Allclub 6", asignable: true },
  { id: 12318519, nombre: "ESP ICE XOLA 2018 - Uniclub 6", asignable: true },
  { id: 3779, nombre: "ESP INBURSA DIRECTORES", asignable: true },
  { id: 2905, nombre: "ESP INEE BARRANCA", asignable: true },
  { id: 9296000, nombre: "ESP INEE BARRANCA - Uniclub 4", asignable: true },
  { id: 6597255, nombre: "ESP INEE BARRANCA - Uniclub2", asignable: true },
  { id: 2949, nombre: "ESP INFONAVIT BARRANCA", asignable: true },
  {
    id: 9436800,
    nombre: "ESP INFONAVIT BARRANCA - Uniclub 4",
    asignable: true
  },
  { id: 6697179, nombre: "ESP INFONAVIT BARRANCA - Uniclub2", asignable: true },
  { id: 3222, nombre: "ESP INFONAVIT BARRANCA I", asignable: true },
  {
    id: 11711970,
    nombre: "ESP INFONAVIT BARRANCA I - Allclub 6",
    asignable: true
  },
  {
    id: 10310400,
    nombre: "ESP INFONAVIT BARRANCA I - Uniclub 4",
    asignable: true
  },
  {
    id: 11670084,
    nombre: "ESP INFONAVIT BARRANCA I - Uniclub 5",
    asignable: true
  },
  { id: 3161, nombre: "ESP INTOR MERIDA", asignable: true },
  { id: 10115200, nombre: "ESP INTOR MERIDA - Uniclub 4", asignable: true },
  { id: 3312, nombre: "ESP IZA BUSINESS CENTER", asignable: true },
  {
    id: 10595088,
    nombre: "ESP IZA BUSINESS CENTER - Allclub 4",
    asignable: true
  },
  {
    id: 12039120,
    nombre: "ESP IZA BUSINESS CENTER - Allclub 6",
    asignable: true
  },
  {
    id: 11996064,
    nombre: "ESP IZA BUSINESS CENTER - Uniclub 5",
    asignable: true
  },
  {
    id: 12045744,
    nombre: "ESP IZA BUSINESS CENTER - Uniclub 6",
    asignable: true
  },
  {
    id: 2991,
    nombre: "ESP IZA BUSINESS CENTER BARRANCA - PATRIO",
    asignable: true
  },
  {
    id: 9568209,
    nombre: "ESP IZA BUSINESS CENTER BARRANCA - PATRIO - Allclub 4",
    asignable: true
  },
  {
    id: 6801534,
    nombre: "ESP IZA BUSINESS CENTER BARRANCA - PATRIO - Allclub2",
    asignable: true
  },
  {
    id: 9571200,
    nombre: "ESP IZA BUSINESS CENTER BARRANCA - PATRIO - Uniclub 4",
    asignable: true
  },
  {
    id: 6792561,
    nombre: "ESP IZA BUSINESS CENTER BARRANCA - PATRIO - Uniclub2",
    asignable: true
  },
  {
    id: 3223,
    nombre: "ESP IZA BUSINESS CENTER BARRANCA - PATRIO I",
    asignable: true
  },
  {
    id: 10310377,
    nombre: "ESP IZA BUSINESS CENTER BARRANCA - PATRIO I - Allclub 4",
    asignable: true
  },
  {
    id: 10313600,
    nombre: "ESP IZA BUSINESS CENTER BARRANCA - PATRIO I - Uniclub 4",
    asignable: true
  },
  { id: 3063, nombre: "ESP KELLOGS QUERETARO", asignable: true },
  { id: 3095, nombre: "ESP KYO UNIVERSIDAD", asignable: true },
  { id: 9900905, nombre: "ESP KYO UNIVERSIDAD - Allclub 4", asignable: true },
  { id: 9820435, nombre: "ESP KYO UNIVERSIDAD - Part Time 4", asignable: true },
  { id: 9904000, nombre: "ESP KYO UNIVERSIDAD - Uniclub 4", asignable: true },
  {
    id: 2780,
    nombre: "ESP LEON CLUBES SPORT CITY / NARANJOS",
    asignable: true
  },
  {
    id: 8962720,
    nombre: "ESP LEON CLUBES SPORT CITY / NARANJOS - Pareja Multiclub Foraneo",
    asignable: true
  },
  { id: 2911, nombre: "ESP LG", asignable: true },
  { id: 6528654, nombre: "ESP LG SATELITE - Allclub2", asignable: true },
  { id: 7734474, nombre: "ESP LG SATELITE - Allclub2 2x1", asignable: true },
  {
    id: 9465600,
    nombre: "ESP LOCATARIOS JURIQUILLA - Uniclub 4",
    asignable: true
  },
  {
    id: 6717618,
    nombre: "ESP LOCATARIOS JURIQUILLA - Uniclub2",
    asignable: true
  },
  {
    id: 11136494,
    nombre: "ESP LOCATARIOS PASEO INTERLOMAS - Uniclub 6",
    asignable: true
  },
  {
    id: 14584370,
    nombre: "ESP LOCATARIOS TORREON - Uniclub 6",
    asignable: true
  },
  { id: 3023, nombre: "ESP MANJARREZ LAGO ALBERTO", asignable: true },
  {
    id: 6874302,
    nombre: "ESP MANJARREZ LAGO ALBERTO - Allclub2",
    asignable: true
  },
  { id: 3672, nombre: "ESP MARYEL CONSULTORIA DE NEGOCIOS", asignable: true },
  {
    id: 3439,
    nombre: "ESP MAYOLY SPINDLER DE MEXICO MANACAR",
    asignable: true
  },
  { id: 3309, nombre: "ESP MCM PALMAS 1", asignable: true },
  { id: 10585491, nombre: "ESP MCM PALMAS 1 - Allclub 4", asignable: true },
  { id: 12028215, nombre: "ESP MCM PALMAS 1 - Allclub 6", asignable: true },
  { id: 7524666, nombre: "ESP MCM PALMAS 1 - Allclub2", asignable: true },
  {
    id: 3585,
    nombre:
      "ESP MERCADO LIBRE PSGAC Prestadora de Servicios Gerenciales,Administrativos",
    asignable: true
  },
  {
    id: 13031475,
    nombre:
      "ESP MERCADO LIBRE PSGAC Prestadora de Servicios Gerenciales,Administrativos - Allclub 6",
    asignable: true
  },
  {
    id: 13468845,
    nombre:
      "ESP MERCADO LIBRE PSGAC Prestadora de Servicios Gerenciales,Administrativos - Allclub 6 3Anios",
    asignable: true
  },
  {
    id: 15397575,
    nombre:
      "ESP MERCADO LIBRE PSGAC Prestadora de Servicios Gerenciales,Administrativos - Allclub dic 2021",
    asignable: true
  },
  { id: 3518, nombre: "ESP METLIFE FEBRERO 2019", asignable: true },
  {
    id: 12787930,
    nombre: "ESP METLIFE FEBRERO 2019 - Allclub 6",
    asignable: true
  },
  { id: 3475, nombre: "ESP MONKYS CORREDORES", asignable: true },
  {
    id: 12631625,
    nombre: "ESP MONKYS CORREDORES - Allclub 6",
    asignable: true
  },
  { id: 2914, nombre: "ESP MRP MAYO GARDEN", asignable: true },
  { id: 6626436, nombre: "ESP MRP MAYO GARDEN - Allclub2", asignable: true },
  { id: 6617694, nombre: "ESP MRP MAYO GARDEN - Uniclub2", asignable: true },
  {
    id: 2948,
    nombre: "ESP MULTISISTEMAS DE SEGURIDAD INDUSTRIALANZURES",
    asignable: true
  },
  {
    id: 9430652,
    nombre: "ESP MULTISISTEMAS DE SEGURIDAD INDUSTRIALANZURES - Allclub 4",
    asignable: true
  },
  {
    id: 10715980,
    nombre: "ESP MULTISISTEMAS DE SEGURIDAD INDUSTRIALANZURES - Allclub 6",
    asignable: true
  },
  {
    id: 6703752,
    nombre: "ESP MULTISISTEMAS DE SEGURIDAD INDUSTRIALANZURES - Allclub2",
    asignable: true
  },
  {
    id: 9433600,
    nombre: "ESP MULTISISTEMAS DE SEGURIDAD INDUSTRIALANZURES - Uniclub 4",
    asignable: true
  },
  {
    id: 6694908,
    nombre: "ESP MULTISISTEMAS DE SEGURIDAD INDUSTRIALANZURES - Uniclub2",
    asignable: true
  },
  { id: 3846, nombre: "ESP NATURA 2020 XOLA", asignable: true },
  { id: 14360964, nombre: "ESP NATURA 2020 XOLA - Allclub 7", asignable: true },
  { id: 2939, nombre: "ESP NATURA XOLA 1", asignable: true },
  { id: 9401861, nombre: "ESP NATURA XOLA 1 - Allclub 4", asignable: true },
  { id: 10683265, nombre: "ESP NATURA XOLA 1 - Allclub 6", asignable: true },
  { id: 6683286, nombre: "ESP NATURA XOLA 1 - Allclub2", asignable: true },
  { id: 3042, nombre: "ESP NUBAJ IT & CONSULTING BARRANCA", asignable: true },
  {
    id: 9731358,
    nombre: "ESP NUBAJ IT & CONSULTING BARRANCA - Allclub 4",
    asignable: true
  },
  {
    id: 6917508,
    nombre: "ESP NUBAJ IT & CONSULTING BARRANCA - Allclub2",
    asignable: true
  },
  { id: 3224, nombre: "ESP NUBAJ IT & CONSULTING BARRANCA I", asignable: true },
  {
    id: 10313576,
    nombre: "ESP NUBAJ IT & CONSULTING BARRANCA I - Allclub 4",
    asignable: true
  },
  {
    id: 11719240,
    nombre: "ESP NUBAJ IT & CONSULTING BARRANCA I - Allclub 6",
    asignable: true
  },
  { id: 2937, nombre: "ESP OPERA DESARROLLADORA PALMAS", asignable: true },
  {
    id: 9395463,
    nombre: "ESP OPERA DESARROLLADORA PALMAS - Allclub 4",
    asignable: true
  },
  {
    id: 10675995,
    nombre: "ESP OPERA DESARROLLADORA PALMAS - Allclub 6",
    asignable: true
  },
  {
    id: 6678738,
    nombre: "ESP OPERA DESARROLLADORA PALMAS - Allclub2",
    asignable: true
  },
  {
    id: 9398400,
    nombre: "ESP OPERA DESARROLLADORA PALMAS - Uniclub 4",
    asignable: true
  },
  { id: 3081, nombre: "ESP PGJ AMORES", asignable: true },
  { id: 9859200, nombre: "ESP PGJ AMORES - Uniclub 4", asignable: true },
  { id: 11159382, nombre: "ESP PGJ AMORES - Uniclub 5", asignable: true },
  { id: 2878, nombre: "ESP PLAYER GROUP", asignable: true },
  { id: 9206722, nombre: "ESP PLAYER GROUP - Allclub 4", asignable: true },
  { id: 6544572, nombre: "ESP PLAYER GROUP - Allclub2", asignable: true },
  { id: 3038, nombre: "ESP PRADO ESPECIAL OCT 2017", asignable: true },
  {
    id: 6899298,
    nombre: "ESP PRADO ESPECIAL OCT 2017 - Uniclub2",
    asignable: true
  },
  { id: 3191, nombre: "ESP PROCESAR BARRANCA", asignable: true },
  {
    id: 10208009,
    nombre: "ESP PROCESAR BARRANCA - Allclub 4",
    asignable: true
  },
  {
    id: 11599285,
    nombre: "ESP PROCESAR BARRANCA - Allclub 6",
    asignable: true
  },
  {
    id: 10211200,
    nombre: "ESP PROCESAR BARRANCA - Uniclub 4",
    asignable: true
  },
  {
    id: 14977074,
    nombre: "ESP PROCTER & GAMBLE 2021 - Allclub 7",
    asignable: true
  },
  { id: 2977, nombre: "ESP PROMTEL XOLA", asignable: true },
  { id: 9523423, nombre: "ESP PROMTEL XOLA - Allclub 4", asignable: true },
  { id: 6769698, nombre: "ESP PROMTEL XOLA - Allclub2", asignable: true },
  { id: 3107, nombre: "ESP PROVEEDORES SW", asignable: true },
  { id: 3316, nombre: "ESP PULSO INMOBILIARIO PALMAS II", asignable: true },
  {
    id: 10607884,
    nombre: "ESP PULSO INMOBILIARIO PALMAS II - Allclub 4",
    asignable: true
  },
  {
    id: 12053660,
    nombre: "ESP PULSO INMOBILIARIO PALMAS II - Allclub 6",
    asignable: true
  },
  { id: 3933, nombre: "ESP REACTIVACION GRUPOS 2020", asignable: true },
  {
    id: 14685822,
    nombre: "ESP REACTIVACION GRUPOS 2020 - Allclub 7",
    asignable: true
  },
  {
    id: 16774245,
    nombre: "ESP REACTIVACION GRUPOS 2020 - Triclub MTY",
    asignable: true
  },
  {
    id: 10493244,
    nombre: "ESP REACTIVACION GRUPOS 2020 - Triclub2",
    asignable: true
  },
  {
    id: 12585600,
    nombre: "ESP REACTIVACION GRUPOS 2020 - Uniclub 4",
    asignable: true
  },
  {
    id: 14304321,
    nombre: "ESP REACTIVACION GRUPOS 2020 - Uniclub 6",
    asignable: true
  },
  {
    id: 14434110,
    nombre: "ESP REACTIVACION GRUPOS 2020 - Uniclub 6A",
    asignable: true
  },
  {
    id: 8235702,
    nombre: "ESP REACTIVACION GRUPOS 2020 - Uniclub Foraneo",
    asignable: true
  },
  { id: 3232, nombre: "ESP RESCATE MERIDA", asignable: true },
  { id: 10339168, nombre: "ESP RESCATE MERIDA - Allclub 4", asignable: true },
  { id: 10342400, nombre: "ESP RESCATE MERIDA - Uniclub 4", asignable: true },
  { id: 3051, nombre: "ESP RESTAURANTE ROTTERSDAM XOLA", asignable: true },
  {
    id: 11090385,
    nombre: "ESP RESTAURANTE ROTTERSDAM XOLA - Allclub 6",
    asignable: true
  },
  {
    id: 6937974,
    nombre: "ESP RESTAURANTE ROTTERSDAM XOLA - Allclub2",
    asignable: true
  },
  { id: 2929, nombre: "ESP SANOFI JUNIO 2017", asignable: true },
  { id: 6660546, nombre: "ESP SANOFI JUNIO 2017 - Allclub2", asignable: true },
  { id: 6651759, nombre: "ESP SANOFI JUNIO 2017 - Uniclub2", asignable: true },
  { id: 2783, nombre: "ESP SC EXSOCIOS", asignable: true },
  { id: 6328542, nombre: "ESP SC EXSOCIOS - Allclub2", asignable: true },
  { id: 3244, nombre: "ESP SHAPE UP TRIA BOSQ INTER", asignable: true },
  {
    id: 10377556,
    nombre: "ESP SHAPE UP TRIA BOSQ INTER - Allclub 4",
    asignable: true
  },
  {
    id: 11444832,
    nombre: "ESP SHAPE UP TRIA BOSQ INTER - Allclub 4 2x1",
    asignable: true
  },
  {
    id: 11791940,
    nombre: "ESP SHAPE UP TRIA BOSQ INTER - Allclub 6",
    asignable: true
  },
  {
    id: 10380800,
    nombre: "ESP SHAPE UP TRIA BOSQ INTER - Uniclub 4",
    asignable: true
  },
  { id: 3227, nombre: "ESP SUPER FITNESS VIP MONTERREY", asignable: true },
  {
    id: 10326400,
    nombre: "ESP SUPER FITNESS VIP MONTERREY - Uniclub 4",
    asignable: true
  },
  { id: 19512360, nombre: "ESP SW AMORES 2021 - Uniclub 17", asignable: true },
  {
    id: 2979,
    nombre: "ESP TARJETAHABIENTES SANTANDER AGOSTO-OCTUBRE",
    asignable: true
  },
  {
    id: 6774246,
    nombre: "ESP TARJETAHABIENTES SANTANDER AGOSTO-OCTUBRE - Allclub2",
    asignable: true
  },
  { id: 3030, nombre: "ESP TOYOTA CORPORATIVO", asignable: true },
  {
    id: 9692970,
    nombre: "ESP TOYOTA CORPORATIVO - Allclub 4",
    asignable: true
  },
  {
    id: 11014050,
    nombre: "ESP TOYOTA CORPORATIVO - Allclub 6",
    asignable: true
  },
  { id: 6890220, nombre: "ESP TOYOTA CORPORATIVO - Allclub2", asignable: true },
  {
    id: 3009,
    nombre: "ESP TRIBUNAL FEDERAL DE JUSTICIA ADMINISTRATIVA CONVENIO ESPECIAL",
    asignable: true
  },
  {
    id: 9625791,
    nombre:
      "ESP TRIBUNAL FEDERAL DE JUSTICIA ADMINISTRATIVA CONVENIO ESPECIAL - Allclub 4",
    asignable: true
  },
  {
    id: 6842466,
    nombre:
      "ESP TRIBUNAL FEDERAL DE JUSTICIA ADMINISTRATIVA CONVENIO ESPECIAL - Allclub2",
    asignable: true
  },
  {
    id: 6785616,
    nombre: "ESP ULTRALASHES CONDESA - Allclub2",
    asignable: true
  },
  { id: 2924, nombre: "ESP UNIVAR XOLA", asignable: true },
  { id: 9353876, nombre: "ESP UNIVAR XOLA - Allclub 4", asignable: true },
  { id: 10628740, nombre: "ESP UNIVAR XOLA - Allclub 6", asignable: true },
  { id: 6649176, nombre: "ESP UNIVAR XOLA - Allclub2", asignable: true },
  { id: 3102, nombre: "ESP UPSTER ZENTRICA CORPORATIVO", asignable: true },
  {
    id: 9926400,
    nombre: "ESP UPSTER ZENTRICA CORPORATIVO - Uniclub 4",
    asignable: true
  },
  { id: 2957, nombre: "ESP VENTA ESPECIAL JULIO R1", asignable: true },
  {
    id: 6724218,
    nombre: "ESP VENTA ESPECIAL JULIO R1 - Allclub2",
    asignable: true
  },
  { id: 3834, nombre: "ESP VISA PLATINUM Y/O VISA INFINITE", asignable: true },
  {
    id: 14316156,
    nombre: "ESP VISA PLATINUM Y/O VISA INFINITE - Allclub 7",
    asignable: true
  },
  { id: 3247, nombre: "ESP WARNER BROS.", asignable: true },
  { id: 10387153, nombre: "ESP WARNER BROS. - Allclub 4", asignable: true },
  { id: 10390400, nombre: "ESP WARNER BROS. - Uniclub 4", asignable: true },
  { id: 3302, nombre: "ESP WEWORK 2019", asignable: true },
  { id: 10563098, nombre: "ESP WEWORK 2019 - Allclub 4", asignable: true },
  { id: 12002770, nombre: "ESP WEWORK 2019 - Allclub 6", asignable: true },
  { id: 11959844, nombre: "ESP WEWORK 2019 - Uniclub 5", asignable: true },
  { id: 12009374, nombre: "ESP WEWORK 2019 - Uniclub 6", asignable: true },
  { id: 3835, nombre: "ESP WEWORK 2020", asignable: true },
  { id: 3049, nombre: "ESP WEWORK REFORMA", asignable: true },
  { id: 9753751, nombre: "ESP WEWORK REFORMA - Allclub 4", asignable: true },
  { id: 6933426, nombre: "ESP WEWORK REFORMA - Allclub2", asignable: true },
  { id: 2711, nombre: "ESP WOMEN´S STUDIO BOSQUES", asignable: true },
  {
    id: 6156681,
    nombre: "ESP WOMEN´S STUDIO BOSQUES - Uniclub2",
    asignable: true
  },
  { id: 3657, nombre: "ESTADIOS MTY", asignable: true },
  { id: 2449, nombre: "ESTAFETA MEXICANA S.A DE C.V", asignable: true },
  { id: 1864, nombre: "ESTEE LAUDER", asignable: true },
  { id: 3186, nombre: "ESTRELLAS DEL FUTURO", asignable: true },
  { id: 2847, nombre: "ETC&A", asignable: true },
  { id: 3411, nombre: "EVENPLAN SC", asignable: true },
  { id: 2064, nombre: "EVERIS SA DE CV", asignable: true },
  { id: 3905, nombre: "EXA QUERETARO 2020", asignable: true },
  { id: 3403, nombre: "EXPO BICI", asignable: true },
  { id: 3651, nombre: "EXPO FITNESS WEEKEND", asignable: true },
  { id: 19512408, nombre: "FABULA CONTENIDOS MX", asignable: true },
  { id: 3537, nombre: "FAMILIA GASQUE", asignable: true },
  { id: 2931, nombre: "FAMILIA INVERSIONISTA CABO NORTE", asignable: true },
  { id: 2768, nombre: "FAMILIA MANUEL SOL", asignable: true },
  { id: 2545, nombre: "FAMILIA TORRADO", asignable: true },
  { id: 1728, nombre: "FAMILIA TRONCOSO", asignable: true },
  {
    id: 2596,
    nombre: "FARMACIAS DEL AHORRO: COMERCIALIZADORA FARMACEUTICA DE CHIAPAS",
    asignable: true
  },
  {
    id: 19512403,
    nombre: "FEDERACION NACIONAL DE SINDICATOS DE LA BANCA DE DESARROLLO",
    asignable: true
  },
  { id: 3014, nombre: "FEEL GOOD TELEFONICA 2017", asignable: true },
  { id: 3020, nombre: "FEEL GOOD TELEFONICA 2017 II", asignable: true },
  { id: 2402, nombre: "FELIPE HERNANDEZ NAVARRO B2B", asignable: true },
  { id: 3737, nombre: "FERRARI GOLF LOS ENCINOS", asignable: true },
  { id: 3335, nombre: "FIANZAS DORAMA", asignable: true },
  {
    id: 2385,
    nombre:
      "Filiales - Administración y Dirección Corporativa COQU, SA DE CV ( QUALITAS )",
    asignable: true
  },
  {
    id: 147870,
    nombre:
      "Filiales - Administración y Dirección Corporativa COQU, SA DE CV ( QUALITAS ) - Allclub",
    asignable: true
  },
  {
    id: 2229,
    nombre: "FINCOMÚN SERVICIOS FINANCIERON COMUNITARIOS, SA DE CV",
    asignable: true
  },
  { id: 2989, nombre: "FMDiabetes", asignable: true },
  { id: 3233, nombre: "FMDiabetes2", asignable: true },
  {
    id: 3704,
    nombre: "FMFA FEDERACIONMEXICANA DE FUTBOL ASOCIACION AC",
    asignable: true
  },
  { id: 3783, nombre: "FOGON DO BRASIL GDL", asignable: true },
  { id: 1787, nombre: "FONATUR", asignable: true },
  { id: 3787, nombre: "FOOTGOLF ACAPULCO 2019", asignable: true },
  { id: 3842, nombre: "Forbes", asignable: true },
  { id: 2992, nombre: "FORBES", asignable: true },
  { id: 2023, nombre: "FORD MOTOR COMPANY, S.A. DE C.V", asignable: true },
  { id: 3007, nombre: "FOREVER 21 SERVICIOS S DE RL DE CV", asignable: true },
  { id: 2944, nombre: "FORMACION EJECUTIVA", asignable: true },
  { id: 3525, nombre: "FOVISSSTE", asignable: true },
  { id: 1832, nombre: "FOX SPORTS", asignable: true },
  { id: 2710, nombre: "FREE AND GREEN S.A DE C.V", asignable: true },
  { id: 1820, nombre: "FRISA SA DE CV ( PACISA )", asignable: true },
  { id: 19512405, nombre: "FUJIFILM DE MEXICO", asignable: true },
  {
    id: 2027,
    nombre: "FULLER BEAUTY COSMETICS S DE RL DE CV",
    asignable: true
  },
  { id: 2344, nombre: "FUNDADOR CD DEL CARMEN", asignable: true },
  { id: 3859, nombre: "G MAS (SALTILLO)", asignable: true },
  { id: 3697, nombre: "G21", asignable: true },
  { id: 3278, nombre: "GANADORES / PATY CASCAR", asignable: true },
  { id: 3206, nombre: "GANADORES BOLTMEDIA 2", asignable: true },
  { id: 3328, nombre: "GANADORES BOLTMEDIA 3", asignable: true },
  {
    id: 3061,
    nombre: "GANADORES CARRERA SECRETARIA DE SALUD",
    asignable: true
  },
  {
    id: 3388,
    nombre: "GANADORES CARRERA SECRETARIA DE SALUD 2019",
    asignable: true
  },
  { id: 3123, nombre: "GANADORES CHILANGUITA", asignable: true },
  { id: 3444, nombre: "GANADORES HUEVOCARTOON", asignable: true },
  { id: 3164, nombre: "GANADORES LUISA FERSS 1 (BOLTMEDIA)", asignable: true },
  { id: 2001, nombre: "GENERAL DE SEGUROS SAB", asignable: true },
  { id: 1861, nombre: "GENERAL ELECTRIC- GE", asignable: true },
  { id: 3069, nombre: "GENERAL MILLS DE MEXICO S. DE RL", asignable: true },
  { id: 2012, nombre: "GENERAL MOTORS DE MEXICO", asignable: true },
  {
    id: 2271,
    nombre:
      "GENTE COMPAÑIA DE SOLUCIONES PROFESIONALES DE MEXICO SA DE CV ( HDI )",
    asignable: true
  },
  {
    id: 15852845,
    nombre: "GIN GROUP LINDAVISTA - Allclub dic 2021",
    asignable: true
  },
  {
    id: 2317,
    nombre: "GLOBAL ASSURANCE BROKERS, AGENTE DE SEGUROS Y FIANZAS S.A DE C.V",
    asignable: true
  },
  { id: 3753, nombre: "GNC", asignable: true },
  { id: 3694, nombre: "GOLF HERITAGE", asignable: true },
  { id: 2110, nombre: "GONZALEZ CALVILLO SC", asignable: true },
  { id: 19512388, nombre: "GPP GYMBOREE", asignable: true },
  { id: 3128, nombre: "GRISI S.A DE C.V 2018", asignable: true },
  { id: 1811, nombre: "GRISI SA DE CV", asignable: true },
  { id: 2378, nombre: "GRISI SA DE CV", asignable: true },
  { id: 112282, nombre: "GRISI SA DE CV - Allclub", asignable: true },
  { id: 3000, nombre: "GRUOPE ROCHER", asignable: true },
  { id: 2109, nombre: "GRUPO ADO SA DE CV", asignable: true },
  { id: 1790, nombre: "GRUPO AEROMEXICO", asignable: true },
  { id: 2810, nombre: "GRUPO AHG", asignable: true },
  {
    id: 2309,
    nombre: "GRUPO ALFA (ALESTRA,ALFA CORP,ALPEK,NEMAK,SIGMA ALIMENTOS)",
    asignable: true
  },
  { id: 2657, nombre: "GRUPO ALSEA", asignable: true },
  { id: 6034047, nombre: "GRUPO ALSEA - Uniclub2", asignable: true },
  { id: 19512395, nombre: "GRUPO ARIES ALQUILER", asignable: true },
  {
    id: 2019,
    nombre: "GRUPO ASSA MEXICO SOLUCIONES INFORMATICAS",
    asignable: true
  },
  { id: 2115, nombre: "GRUPO AUTOFIN -CIRCULO EXCLUSIVO", asignable: true },
  {
    id: 2062,
    nombre: "GRUPO AXO SERVICIOS DE CAPITAL HUMANO",
    asignable: true
  },
  { id: 2316, nombre: "GRUPO BAL", asignable: true },
  { id: 2379, nombre: "GRUPO BIMBO", asignable: true },
  { id: 3464, nombre: "Grupo Camaleón", asignable: true },
  { id: 3707, nombre: "GRUPO CANTAREL", asignable: true },
  { id: 1917, nombre: "GRUPO CIE", asignable: true },
  { id: 19512385, nombre: "GRUPO CINBERSOL ", asignable: true },
  { id: 2805, nombre: "GRUPO CLEBER", asignable: true },
  { id: 2127, nombre: "GRUPO COMERCIAL MEXICANA, SA DE CV", asignable: true },
  { id: 3399, nombre: "GRUPO CONTINENTAL AUTOMOTRIZ", asignable: true },
  { id: 1923, nombre: "GRUPO COPRI", asignable: true },
  {
    id: 2164,
    nombre: "GRUPO CP (CENTRO INFER DE SERVICIOS SA DE CV)",
    asignable: true
  },
  { id: 3722, nombre: "GRUPO CVA", asignable: true },
  {
    id: 2086,
    nombre: "GRUPO DIAGNOSTICO MEDICO PROA(LAB. CHOPO)",
    asignable: true
  },
  { id: 2357, nombre: "GRUPO EDITORIAL NOTMUSA SA DE CV", asignable: true },
  { id: 2103, nombre: "GRUPO FARMACEUTICO SOMAR", asignable: true },
  {
    id: 2182,
    nombre: "GRUPO FARMACOS ESPECIALIZADOS SA DE CV ( GRUFESA )",
    asignable: true
  },
  { id: 2801, nombre: "GRUPO FERRERO", asignable: true },
  { id: 3678, nombre: "GRUPO FOGRA SA DE CV", asignable: true },
  { id: 2825, nombre: "GRUPO GEPP, S.A.P.I. DE C.V.", asignable: true },
  {
    id: 6424050,
    nombre: "GRUPO GEPP, S.A.P.I. DE C.V. - Allclub2",
    asignable: true
  },
  { id: 3116, nombre: "GRUPO GEPP, S.A.P.I. DE C.V. 2018", asignable: true },
  { id: 3666, nombre: "GRUPO GIA SWCUE", asignable: true },
  {
    id: 2038,
    nombre:
      "GRUPO GIGANTE S.A.B(Grupo Inmobiliario,Hoteles Presidentes,Office Depot,Toks,VWOla Polanco,Nami,Losmor)",
    asignable: true
  },
  { id: 3560, nombre: "GRUPO GRAGO", asignable: true },
  { id: 1995, nombre: "GRUPO ICA SA DE CV, OMA//ICA FLUOR", asignable: true },
  { id: 19512420, nombre: "GRUPO KABAT", asignable: true },
  { id: 1770, nombre: "GRUPO KUO-HERDEZ", asignable: true },
  { id: 2584, nombre: "GRUPO LAUMAN", asignable: true },
  { id: 1938, nombre: "GRUPO MEDIOS SA DE CV", asignable: true },
  { id: 3389, nombre: "GRUPO MEXICANO DE MEDIOS", asignable: true },
  {
    id: 2358,
    nombre: "GRUPO MEXICO SERVICIOS,MINERA MEXICO E INFRAESTRUCTURA",
    asignable: true
  },
  { id: 2132, nombre: "GRUPO MODELO S. DE R.L. DE CV", asignable: true },
  {
    id: 19512419,
    nombre: "GRUPO OPEN ( Northware S.A. de C.V.)",
    asignable: true
  },
  { id: 2266, nombre: "GRUPO OXXO", asignable: true },
  { id: 2319, nombre: "GRUPO PEÑAFIEL, S.A DE C.V", asignable: true },
  { id: 3627, nombre: "GRUPO POL", asignable: true },
  { id: 2032, nombre: "GRUPO POSADAS", asignable: true },
  { id: 125984, nombre: "GRUPO POSADAS - Allclub", asignable: true },
  { id: 19512364, nombre: "GRUPO PREMIER", asignable: true },
  { id: 19512384, nombre: "GRUPO PREMIER CULIACAN", asignable: true },
  {
    id: 2248,
    nombre:
      "GRUPO REAL TURISMO / GRUPO EMPRESARIAL ANGELES SA DE CV (CAMINO REAL )",
    asignable: true
  },
  {
    id: 139376,
    nombre:
      "GRUPO REAL TURISMO / GRUPO EMPRESARIAL ANGELES SA DE CV (CAMINO REAL ) - Allclub",
    asignable: true
  },
  {
    id: 155112,
    nombre:
      "GRUPO REAL TURISMO / GRUPO EMPRESARIAL ANGELES SA DE CV (CAMINO REAL ) - Multiclub",
    asignable: true
  },
  {
    id: 143872,
    nombre:
      "GRUPO REAL TURISMO / GRUPO EMPRESARIAL ANGELES SA DE CV (CAMINO REAL ) - Uniclub",
    asignable: true
  },
  { id: 1965, nombre: "GRUPO SALINAS", asignable: true },
  { id: 2090, nombre: "GRUPO SCANDA", asignable: true },
  { id: 3035, nombre: "GRUPO SORDO MADALENO", asignable: true },
  {
    id: 1943,
    nombre: "GRUPO TRADECO INFRAESTRUCTURA SA DE CV",
    asignable: true
  },
  { id: 1949, nombre: "GRUPO WPP", asignable: true },
  { id: 3815, nombre: "GYMPASS PREMIO", asignable: true },
  {
    id: 19512366,
    nombre: "HALTA ZAGEN ADMINISTRACION SA DE CV ",
    asignable: true
  },
  { id: 3323, nombre: "HARMAN DE MEXICO S DE RL DE CV", asignable: true },
  { id: 1758, nombre: "HARMON HALL", asignable: true },
  { id: 2160, nombre: "havas worldwide mexico sa de cv", asignable: true },
  { id: 2255, nombre: "HAYS SERVICIOS, SA DE CV", asignable: true },
  { id: 1855, nombre: "HENKEL SA DE CV", asignable: true },
  { id: 2087, nombre: "HERBALIFE--SERVICIOS INTEGRALES HIM", asignable: true },
  { id: 2693, nombre: "HERRADURA COSESA B-FS DE RL DE CV", asignable: true },
  { id: 3124, nombre: "HEYILIN VENEGAS", asignable: true },
  {
    id: 2133,
    nombre: "HIGH PERFORMANCE IT SOLUTION & SERVICE-GLOBAL HITSS",
    asignable: true
  },
  { id: 3209, nombre: "HIGH SCHOOL THOMAS JEFFERSON S.C", asignable: true },
  { id: 2138, nombre: "HIR CASA INMOBILIARIA", asignable: true },
  { id: 3527, nombre: "HOCKEY INTERLOMAS", asignable: true },
  {
    id: 2006,
    nombre: "HOGAN LOVELLS BSTL (BARRERA, SIQUEIROS, TORRES LANDA)",
    asignable: true
  },
  { id: 19512371, nombre: "HOGARES DEL MAYAB", asignable: true },
  { id: 2779, nombre: "HONDA", asignable: true },
  { id: 2745, nombre: "HONEYWELL", asignable: true },
  { id: 4001, nombre: "HOP TRIP", asignable: true },
  {
    id: 3623,
    nombre: "HOSPITAL ESPANOL SOCIEDAD DE BENEFICENCIA ESPANOLA",
    asignable: true
  },
  {
    id: 19512398,
    nombre: "HOSPITAL FARO CM DEL MAYAB (CABO)",
    asignable: true
  },
  { id: 2304, nombre: "HOSPITAL SAN ANGEL INN", asignable: true },
  {
    id: 146624,
    nombre: "HOSPITAL SAN JOSE HERMOSILLO - Uniclub",
    asignable: true
  },
  {
    id: 5202861,
    nombre: "HOSPITAL SAN JOSE HERMOSILLO - Uniclub2",
    asignable: true
  },
  { id: 2089, nombre: "HOTELES CITY", asignable: true },
  { id: 19512391, nombre: "HOUM", asignable: true },
  { id: 2765, nombre: "HR INTELLIGENCE", asignable: true },
  { id: 19512411, nombre: "HR RATINGS DE MEXICO", asignable: true },
  { id: 1774, nombre: "HSBC MEXICO", asignable: true },
  {
    id: 2714,
    nombre: "HUAWEI TECHNOLOGIES ENGINEERING DE MEXICO",
    asignable: true
  },
  { id: 3797, nombre: "HUMAN", asignable: true },
  { id: 3881, nombre: "HUNTER 2 GDL", asignable: true },
  { id: 1823, nombre: "HUNTER DOUGLAS SA DE CV", asignable: true },
  { id: 3510, nombre: "HUNTER GDL 2020", asignable: true },
  { id: 1764, nombre: "IBM", asignable: true },
  { id: 2084, nombre: "IDS COMERCIAL SA DE CV", asignable: true },
  { id: 3667, nombre: "IE 24 Morelos SWCUE", asignable: true },
  { id: 3520, nombre: "IE CANCHAS OBRERO MUNDIAL", asignable: true },
  { id: 2748, nombre: "IGSA S.A DE C.V", asignable: true },
  {
    id: 19512383,
    nombre: "IMEF (Instituto Mexicano de Ejecutivos de Ejecutivos de Finanzas)",
    asignable: true
  },
  { id: 3202, nombre: "IMPACTO 21", asignable: true },
  {
    id: 3685,
    nombre: "IMPORTADORA Y DISTRIBUIDORA KCG, SA DE CV",
    asignable: true
  },
  { id: 1910, nombre: "INBURSA SEGUROS INBURSA", asignable: true },
  {
    id: 2199,
    nombre: "INDESOL,INSTITUTO NACIONAL DE DESARROLLO SOCIAL",
    asignable: true
  },
  { id: 1904, nombre: "INDRA SISTEMAS MEXICO, SA DE CV", asignable: true },
  { id: 2684, nombre: "INE INSTITUTO NACIONAL ELECTORAL", asignable: true },
  { id: 3954, nombre: "INFLUENCER SALTILLO", asignable: true },
  { id: 3970, nombre: "INFLUENCERS 2020-2021", asignable: true },
  { id: 3823, nombre: "INFLUENCERS 2021", asignable: true },
  { id: 2855, nombre: "INFONAVIT", asignable: true },
  { id: 1964, nombre: "INFONAVIT", asignable: true },
  { id: 6483705, nombre: "INFONAVIT - Uniclub2", asignable: true },
  { id: 2050, nombre: "INFOR SA DE CV", asignable: true },
  { id: 2116, nombre: "INFOSYS TECHNOLOGIES", asignable: true },
  {
    id: 1959,
    nombre: "INGRAM MICRO COMPAÑIA DE SERVICIOS SA DE CV",
    asignable: true
  },
  {
    id: 1954,
    nombre:
      "INSTITUTO DE SEGURIDAD Y SERVICIOS SOCIALES PARA LOS TRABAJADORES DEL ESTADO ISSSTE",
    asignable: true
  },
  {
    id: 125056,
    nombre:
      "INSTITUTO DE SEGURIDAD Y SERVICIOS SOCIALES PARA LOS TRABAJADORES DEL ESTADO ISSSTE - Uniclub",
    asignable: true
  },
  { id: 2696, nombre: "INSTITUTO ELECTORAL DEL DF", asignable: true },
  {
    id: 2286,
    nombre: "INSTITUTO FEDERAL DE TELECOMUNICACIONES (IFT)",
    asignable: true
  },
  {
    id: 2106,
    nombre: "INSTITUTO MEXICANO DE LA PROPIEDAD INDUSTRIAL (IMPI)",
    asignable: true
  },
  {
    id: 1962,
    nombre: "INSTITUTO MEXICANO SEGURO SOCIAL IMSS",
    asignable: true
  },
  { id: 3340, nombre: "INSTITUTO NACIONAL DE LAS MUJERES", asignable: true },
  {
    id: 1891,
    nombre: "Instituto Tecnologico Autonomo de Mexico ITAM",
    asignable: true
  },
  { id: 3108, nombre: "INT TU AMBIENTE", asignable: true },
  { id: 2258, nombre: "INTELISIS SOFTWER SA DE CV", asignable: true },
  { id: 19512407, nombre: "INTELLIGENT CUSTOMER ACQUISITION", asignable: true },
  { id: 2168, nombre: "INTERCAM", asignable: true },
  { id: 2790, nombre: "INTERPROTECCION", asignable: true },
  { id: 2113, nombre: "INVEX GRUPO FINANCIERO", asignable: true },
  { id: 3442, nombre: "INVITADO ONLINE PASE POR UN DIA", asignable: true },
  { id: 2850, nombre: "IOS OFFICES", asignable: true },
  { id: 2240, nombre: "IPADE", asignable: true },
  { id: 143360, nombre: "IPADE - Uniclub", asignable: true },
  {
    id: 1940,
    nombre: "IPG MEDIABRANDS COMMUNICATION SA DE CV",
    asignable: true
  },
  { id: 3542, nombre: "IPRINT MERIDA", asignable: true },
  { id: 3333, nombre: "IRMA MARCELA GONZALEZ TREVIÑO", asignable: true },
  { id: 4044, nombre: "IZA BUSINESS CENTER", asignable: true },
  { id: 2092, nombre: "IZZI (CABLEVISION)", asignable: true },
  { id: 3721, nombre: "J RAY MCDERMOTT MEXICO SA DE CV", asignable: true },
  { id: 2679, nombre: "J. GARCIA LOPEZ", asignable: true },
  { id: 3208, nombre: "J. P MORGAN SERVICIOS", asignable: true },
  { id: 1980, nombre: "JEFATURA DE GOBIERNO DEL CDMX", asignable: true },
  { id: 3818, nombre: "JEROJU SA DE CV", asignable: true },
  { id: 2054, nombre: "JOHNSON & JOHNSON MD & DIAGNOSTICS", asignable: true },
  {
    id: 3171,
    nombre: "JOHSON CONTROLS BE SERVICIOS MEXICO S. DE R.L DE C.V",
    asignable: true
  },
  { id: 1810, nombre: "JONES LANG LA SALLE", asignable: true },
  { id: 3382, nombre: "JOSE CARLOS CERVANTES", asignable: true },
  { id: 19512362, nombre: "JULIA BORBOLLA ", asignable: true },
  { id: 2547, nombre: "KAPPA", asignable: true },
  { id: 3619, nombre: "KASST AGENCY ANGELES AZULES", asignable: true },
  { id: 2026, nombre: "KELLY SERVICES MEXICO SA DE CV", asignable: true },
  { id: 3465, nombre: "KEYENCE MEXICO", asignable: true },
  { id: 2013, nombre: "KIMBERLY -CLARK DE MEXICO SA DE CV", asignable: true },
  { id: 1890, nombre: "KIO NETWORKS", asignable: true },
  { id: 1800, nombre: "KPMG CARDENAS DOSAL", asignable: true },
  { id: 3751, nombre: "KRONOS DE MEXICO", asignable: true },
  { id: 3450, nombre: "KUA MEX FOODS ADMINISTRADORA", asignable: true },
  { id: 1849, nombre: "KUEHNE NAGEL", asignable: true },
  { id: 3683, nombre: "LA BUENA VIBRA EVENTO YOGA", asignable: true },
  { id: 2197, nombre: "LA COSTEÑA S.A DE C.V", asignable: true },
  { id: 3393, nombre: "LA CRONICA", asignable: true },
  { id: 19512421, nombre: "LA HAUS", asignable: true },
  { id: 3288, nombre: "LA ISLA CABO", asignable: true },
  { id: 11958456, nombre: "LA ISLA CABO - Uniclub 6", asignable: true },
  { id: 2121, nombre: "LABORATORIOS LILLY SA DE CV", asignable: true },
  { id: 2751, nombre: "LABORATORIOS PROGALENICA - ANDROMACO", asignable: true },
  {
    id: 1922,
    nombre: "LABORATORIOS SERVIER MEXICO, SA DE CV",
    asignable: true
  },
  { id: 3039, nombre: "LAGASH MEXICO", asignable: true },
  { id: 1869, nombre: "LALA", asignable: true },
  { id: 2260, nombre: "LENOVO MEXICO S DE RL DE CV", asignable: true },
  { id: 3971, nombre: "LEONAS QUERETARO", asignable: true },
  { id: 3561, nombre: "LEONES DE YUCATAN", asignable: true },
  { id: 2453, nombre: "LINIO RESOURCES I SRL DE C.V", asignable: true },
  { id: 1765, nombre: "LIVERPOOL", asignable: true },
  { id: 1927, nombre: "LOREAL", asignable: true },
  { id: 2899, nombre: "LOREAL Dinámica La Roche Posay", asignable: true },
  { id: 1793, nombre: "MABE", asignable: true },
  { id: 2819, nombre: "MAERSK MEXICO S.A DE C.V", asignable: true },
  { id: 3805, nombre: "MAGAZINE PEDREGAL", asignable: true },
  { id: 2326, nombre: "MANCERA SC ( EY) ERNST & YOUNG", asignable: true },
  { id: 1976, nombre: "MAPFRE TEPEYAC SA", asignable: true },
  { id: 2963, nombre: "MARI CARMEN PAVON- SPA", asignable: true },
  { id: 4046, nombre: "MARIO ESPARZA RUTAS TODO TERRENO MKT", asignable: true },
  { id: 2011, nombre: "MARMOLES ARCA GRUPO NEXXUS", asignable: true },
  { id: 3338, nombre: "MARS MEXICO", asignable: true },
  {
    id: 2150,
    nombre: "MARSH AND MCLENNAN COMPANIES SA DE CV",
    asignable: true
  },
  { id: 19512422, nombre: "MARY KAY COSMETICS", asignable: true },
  { id: 1779, nombre: "MATTEL SERVICIOS SA DE CV", asignable: true },
  { id: 2746, nombre: "MAXCOM TELECOMUNICACIONES SAB DE CV", asignable: true },
  { id: 3739, nombre: "MAXIMA RADIO", asignable: true },
  { id: 3146, nombre: "MAXWELL", asignable: true },
  { id: 3713, nombre: "MAXWELL", asignable: true },
  {
    id: 2572,
    nombre: "MAZDA SERVICIOS DE MEXICO S. DE R.L DE C.V",
    asignable: true
  },
  {
    id: 5848728,
    nombre: "MAZDA SERVICIOS DE MEXICO S. DE R.L DE C.V - Allclub2",
    asignable: true
  },
  { id: 2485, nombre: "MEDIX MEDIFAST", asignable: true },
  { id: 3400, nombre: "MEGACABLE QRO", asignable: true },
  { id: 19512406, nombre: "MEGALABS ( ITALMEX )", asignable: true },
  { id: 3282, nombre: "MEIMEX", asignable: true },
  { id: 3541, nombre: "MERCA DIGITAL", asignable: true },
  {
    id: 3482,
    nombre:
      "MERCADO LIBRE PSGAC Prestadora de Servicios Gerenciales,Administrativos",
    asignable: true
  },
  { id: 2021, nombre: "MERCK SA DE CV Y BACHER ZOOPI", asignable: true },
  {
    id: 1966,
    nombre: "MERCK SHARP & DOHME S DE RL DE CV (MSD)",
    asignable: true
  },
  { id: 3083, nombre: "META SPORTS", asignable: true },
  { id: 2619, nombre: "METEPEC CORPORATIVO", asignable: true },
  { id: 1780, nombre: "METLIFE", asignable: true },
  { id: 3099, nombre: "MEXICO BUSINESS PUBLISHING", asignable: true },
  { id: 3771, nombre: "MEXPHARM MEDICAL HEALTH SOLUTIONS", asignable: true },
  { id: 19512394, nombre: "MG MOTOR MEXICO", asignable: true },
  { id: 1802, nombre: "MICROSOFT MEXICO S DE RL DE CV", asignable: true },
  { id: 3544, nombre: "MID WOMAN MERIDA", asignable: true },
  { id: 1796, nombre: "MIJARES Y ASOCIADOS", asignable: true },
  { id: 4012, nombre: "MKT- AUPA PUBLICIDAD SA DE CV 2", asignable: true },
  { id: 3070, nombre: "MKT- CARTELERAS", asignable: true },
  { id: 3995, nombre: "MKT- CENTRAL FM 1", asignable: true },
  { id: 4018, nombre: "MKT- LACAVI 1", asignable: true },
  { id: 4038, nombre: "MKT- LifeStyle", asignable: true },
  { id: 3765, nombre: "MKT- MEDICABLE", asignable: true },
  { id: 3967, nombre: "MKT- MILENIO 2020", asignable: true },
  { id: 3994, nombre: "MKT- MORITA", asignable: true },
  { id: 4030, nombre: "MKT- OUTFIT MAGAZINE 2021", asignable: true },
  { id: 4007, nombre: "MKT- PATROCIONIO INFLUENCER", asignable: true },
  { id: 3996, nombre: "MKT- PEIGARZA 2021", asignable: true },
  { id: 3854, nombre: "MKT- RECORRIDOS VIRTUALES GOOGLE", asignable: true },
  { id: 3471, nombre: "MKT- RENTABLE 2021", asignable: true },
  { id: 4020, nombre: "MKT- REViSTA VIVE 2", asignable: true },
  { id: 4003, nombre: "MKT- SIETE VEINTICUATRO", asignable: true },
  { id: 4014, nombre: "MKT- UNIVISION", asignable: true },
  { id: 4017, nombre: "MKT-G21 1", asignable: true },
  { id: 3210, nombre: "MODA HOLDING", asignable: true },
  { id: 1768, nombre: "MODA HOLDING", asignable: true },
  { id: 3187, nombre: "MOLLER", asignable: true },
  { id: 3268, nombre: "MOM2MOM", asignable: true },
  { id: 1989, nombre: "MONDELEZ", asignable: true },
  { id: 2187, nombre: "MONEX GRUPO FINANCIERO,BANCO MONEX", asignable: true },
  { id: 1803, nombre: "MRP", asignable: true },
  { id: 3587, nombre: "MULTIMEDIOS", asignable: true },
  {
    id: 3530,
    nombre: "MULTISISTEMAS DE SEGURIDAD INDUSTRIAL",
    asignable: true
  },
  { id: 3977, nombre: "Mundo Ejecutivo 2021", asignable: true },
  { id: 3082, nombre: "MUNDO EJECUTIVO MKT", asignable: true },
  { id: 3701, nombre: "MUNICIPIO ZAPOPAN LICENCIAS", asignable: true },
  { id: 3267, nombre: "MXCity", asignable: true },
  { id: 1778, nombre: "NACIONAL FINANCIERA", asignable: true },
  { id: 1996, nombre: "NACIONAL MONTE DE PIEDAD I.A.P.", asignable: true },
  { id: 3699, nombre: "NAVISTAR MEXICO S DE RL DE CV", asignable: true },
  { id: 2147, nombre: "NEMAK", asignable: true },
  { id: 2015, nombre: "NESTLE GRUPO", asignable: true },
  { id: 19512409, nombre: "NETA SYSTEMS", asignable: true },
  { id: 1944, nombre: "NEW BALANCE MEXICO SA DE CV", asignable: true },
  { id: 1808, nombre: "NEXTEL", asignable: true },
  { id: 3730, nombre: "NEXTMARKETING", asignable: true },
  { id: 19512387, nombre: "NEXU", asignable: true },
  { id: 1727, nombre: "NEXXUS", asignable: true },
  { id: 1760, nombre: "NEXXUS", asignable: true },
  { id: 109120, nombre: "NEXXUS - Allclub", asignable: true },
  { id: 4002240, nombre: "NEXXUS - Allclub2", asignable: true },
  { id: 2134, nombre: "NIELSEN MEXICO /NIELSEN IBOPE", asignable: true },
  { id: 3545, nombre: "NIVEL 72", asignable: true },
  { id: 15652550, nombre: "NORMA GROUP MEXICO - Triclub MTY", asignable: true },
  { id: 11744000, nombre: "NORMA GROUP MEXICO - Uniclub 4", asignable: true },
  {
    id: 1919,
    nombre: "NORTH AMERICAN SOFTWARE S.A.P.I. DE CV (NASOFT)",
    asignable: true
  },
  { id: 3486, nombre: "NOTARIA 122", asignable: true },
  { id: 3531, nombre: "NOTARIA CUERNAVACA", asignable: true },
  { id: 19512399, nombre: "NOVARTIS", asignable: true },
  { id: 3175, nombre: "NOVONORDISK", asignable: true },
  { id: 3250, nombre: "NUTRICIÓN LA RIOJA", asignable: true },
  { id: 1972, nombre: "OAPAS", asignable: true },
  { id: 19512372, nombre: "OCESA", asignable: true },
  { id: 1771, nombre: "OFFICE MAX", asignable: true },
  {
    id: 2040,
    nombre: "OFS SERVICIOS SA DE CV ( schulemberger)",
    asignable: true
  },
  {
    id: 130560,
    nombre: "OFS SERVICIOS SA DE CV ( schulemberger) - Uniclub",
    asignable: true
  },
  { id: 1924, nombre: "OGILVY & MATHER SA", asignable: true },
  { id: 1759, nombre: "OLAB", asignable: true },
  { id: 3676, nombre: "OMEDIA", asignable: true },
  { id: 3932, nombre: "OMEDIA TORREON", asignable: true },
  { id: 1886, nombre: "ORACLE DE MEXICO SA DE CV", asignable: true },
  { id: 3179, nombre: "OUTFIT MAGAZINE", asignable: true },
  { id: 3428, nombre: "OUTFIT MAGAZINE 2", asignable: true },
  { id: 3741, nombre: "OUTFIT MAGAZINE 20-21", asignable: true },
  { id: 3570, nombre: "OUTLET BICI", asignable: true },
  {
    id: 2153,
    nombre: "PAGE GROUP(MICHAEL PAGE /PAGE PERSONNEL)",
    asignable: true
  },
  { id: 3322, nombre: "PANTALLA INTERLOMAS - EMPRESA DIAFI", asignable: true },
  { id: 3500, nombre: "PANTALLA LED LEDPUBLICITY SWAGU", asignable: true },
  { id: 2128, nombre: "Part Time Felix Cuevas 1560", asignable: true },
  {
    id: 134064,
    nombre: "Part Time Felix Cuevas 1560 - Part Time",
    asignable: true
  },
  {
    id: 2451456,
    nombre: "Part Time Felix Cuevas 1560 - Part Time Especial",
    asignable: true
  },
  { id: 1826, nombre: "PARTIDO ACCION NACIONAL PAN", asignable: true },
  { id: 2703, nombre: "PASEO INTERLOMAS CONVENIOS A", asignable: true },
  { id: 2701, nombre: "PASEO INTERLOMAS LITE", asignable: true },
  { id: 167462, nombre: "PASEO INTERLOMAS LITE - Allclub", asignable: true },
  {
    id: 5734223,
    nombre: "PASEO INTERLOMAS LITE - Allclub 2k",
    asignable: true
  },
  {
    id: 5663997,
    nombre: "PASEO INTERLOMAS LITE - Allclub 2x1 Nueva 2.0",
    asignable: true
  },
  { id: 3905646, nombre: "PASEO INTERLOMAS LITE - Biclub", asignable: true },
  { id: 186369, nombre: "PASEO INTERLOMAS LITE - Multiclub", asignable: true },
  { id: 170163, nombre: "PASEO INTERLOMAS LITE - Part Time", asignable: true },
  {
    id: 6820025,
    nombre: "PASEO INTERLOMAS LITE - Part Time Zona Poniente",
    asignable: true
  },
  {
    id: 5580266,
    nombre: "PASEO INTERLOMAS LITE - Tematica 2.0",
    asignable: true
  },
  { id: 172864, nombre: "PASEO INTERLOMAS LITE - Uniclub", asignable: true },
  {
    id: 2144594,
    nombre: "PASEO INTERLOMAS LITE - Uniclub Especial",
    asignable: true
  },
  {
    id: 6147476,
    nombre: "PASEO INTERLOMAS LITE - Uniclub Part time",
    asignable: true
  },
  { id: 2700, nombre: "PASEO INTERLOMAS NEWSTARTERS", asignable: true },
  {
    id: 167400,
    nombre: "PASEO INTERLOMAS NEWSTARTERS - Allclub",
    asignable: true
  },
  {
    id: 5732100,
    nombre: "PASEO INTERLOMAS NEWSTARTERS - Allclub 2k",
    asignable: true
  },
  {
    id: 5437800,
    nombre: "PASEO INTERLOMAS NEWSTARTERS - Allclub 2x1",
    asignable: true
  },
  {
    id: 5661900,
    nombre: "PASEO INTERLOMAS NEWSTARTERS - Allclub 2x1 Nueva 2.0",
    asignable: true
  },
  {
    id: 5246100,
    nombre: "PASEO INTERLOMAS NEWSTARTERS - Allclub 2x1.",
    asignable: true
  },
  {
    id: 5202900,
    nombre: "PASEO INTERLOMAS NEWSTARTERS - Allclub Universitario",
    asignable: true
  },
  {
    id: 6139800,
    nombre: "PASEO INTERLOMAS NEWSTARTERS - Allclub2",
    asignable: true
  },
  {
    id: 3904200,
    nombre: "PASEO INTERLOMAS NEWSTARTERS - Biclub",
    asignable: true
  },
  {
    id: 5364900,
    nombre: "PASEO INTERLOMAS NEWSTARTERS - Ghost",
    asignable: true
  },
  {
    id: 5335200,
    nombre: "PASEO INTERLOMAS NEWSTARTERS - Juvenil",
    asignable: true
  },
  {
    id: 6126300,
    nombre: "PASEO INTERLOMAS NEWSTARTERS - Pareja",
    asignable: true
  },
  {
    id: 170100,
    nombre: "PASEO INTERLOMAS NEWSTARTERS - Part Time",
    asignable: true
  },
  {
    id: 1895400,
    nombre: "PASEO INTERLOMAS NEWSTARTERS - Part Time Jr-Sr",
    asignable: true
  },
  {
    id: 6137100,
    nombre: "PASEO INTERLOMAS NEWSTARTERS - Part Time2",
    asignable: true
  },
  {
    id: 5337900,
    nombre: "PASEO INTERLOMAS NEWSTARTERS - Tematica",
    asignable: true
  },
  {
    id: 5578200,
    nombre: "PASEO INTERLOMAS NEWSTARTERS - Tematica 2.0",
    asignable: true
  },
  {
    id: 5964300,
    nombre: "PASEO INTERLOMAS NEWSTARTERS - Tematica 3.0",
    asignable: true
  },
  {
    id: 172800,
    nombre: "PASEO INTERLOMAS NEWSTARTERS - Uniclub",
    asignable: true
  },
  {
    id: 5340600,
    nombre: "PASEO INTERLOMAS NEWSTARTERS - Uniclub 2x1 Zona centro",
    asignable: true
  },
  {
    id: 2143800,
    nombre: "PASEO INTERLOMAS NEWSTARTERS - Uniclub Especial",
    asignable: true
  },
  {
    id: 6145200,
    nombre: "PASEO INTERLOMAS NEWSTARTERS - Uniclub Part time",
    asignable: true
  },
  {
    id: 5200200,
    nombre: "PASEO INTERLOMAS NEWSTARTERS - Uniclub Universitario",
    asignable: true
  },
  {
    id: 6131700,
    nombre: "PASEO INTERLOMAS NEWSTARTERS - Uniclub2",
    asignable: true
  },
  { id: 2702, nombre: "PASEO INTERLOMAS PRIORITY", asignable: true },
  {
    id: 167524,
    nombre: "PASEO INTERLOMAS PRIORITY - Allclub",
    asignable: true
  },
  {
    id: 3907092,
    nombre: "PASEO INTERLOMAS PRIORITY - Biclub",
    asignable: true
  },
  {
    id: 186438,
    nombre: "PASEO INTERLOMAS PRIORITY - Multiclub",
    asignable: true
  },
  {
    id: 170226,
    nombre: "PASEO INTERLOMAS PRIORITY - Part Time",
    asignable: true
  },
  {
    id: 172928,
    nombre: "PASEO INTERLOMAS PRIORITY - Uniclub",
    asignable: true
  },
  {
    id: 4796050,
    nombre: "PASEO INTERLOMAS PRIORITY - Uniclub Agregado",
    asignable: true
  },
  {
    id: 2145388,
    nombre: "PASEO INTERLOMAS PRIORITY - Uniclub Especial",
    asignable: true
  },
  {
    id: 6149752,
    nombre: "PASEO INTERLOMAS PRIORITY - Uniclub Part time",
    asignable: true
  },
  {
    id: 5231072,
    nombre: "PASEO INTERLOMAS PRIORITY - Uniclub Promo CC",
    asignable: true
  },
  { id: 2699, nombre: "PASEO INTERLOMAS STARTER", asignable: true },
  { id: 167338, nombre: "PASEO INTERLOMAS STARTER - Allclub", asignable: true },
  {
    id: 5729977,
    nombre: "PASEO INTERLOMAS STARTER - Allclub 2k",
    asignable: true
  },
  {
    id: 5244157,
    nombre: "PASEO INTERLOMAS STARTER - Allclub 2x1.",
    asignable: true
  },
  {
    id: 5200973,
    nombre: "PASEO INTERLOMAS STARTER - Allclub Universitario",
    asignable: true
  },
  {
    id: 6137526,
    nombre: "PASEO INTERLOMAS STARTER - Allclub2",
    asignable: true
  },
  { id: 3902754, nombre: "PASEO INTERLOMAS STARTER - Biclub", asignable: true },
  { id: 5362913, nombre: "PASEO INTERLOMAS STARTER - Ghost", asignable: true },
  {
    id: 186231,
    nombre: "PASEO INTERLOMAS STARTER - Multiclub",
    asignable: true
  },
  {
    id: 170037,
    nombre: "PASEO INTERLOMAS STARTER - Part Time",
    asignable: true
  },
  {
    id: 5808248,
    nombre: "PASEO INTERLOMAS STARTER - Reactivacion Uniclub",
    asignable: true
  },
  { id: 172736, nombre: "PASEO INTERLOMAS STARTER - Uniclub", asignable: true },
  {
    id: 4790725,
    nombre: "PASEO INTERLOMAS STARTER - Uniclub Agregado",
    asignable: true
  },
  {
    id: 2143006,
    nombre: "PASEO INTERLOMAS STARTER - Uniclub Especial",
    asignable: true
  },
  {
    id: 6142924,
    nombre: "PASEO INTERLOMAS STARTER - Uniclub Part time",
    asignable: true
  },
  { id: 3612, nombre: "PEAS", asignable: true },
  { id: 3136, nombre: "PEIGARZA", asignable: true },
  { id: 3817, nombre: "PEIGARZA 2020", asignable: true },
  { id: 1775, nombre: "PEMEX", asignable: true },
  { id: 110050, nombre: "PEMEX - Allclub", asignable: true },
  { id: 4036350, nombre: "PEMEX - Allclub2", asignable: true },
  { id: 113600, nombre: "PEMEX - Uniclub", asignable: true },
  { id: 1913, nombre: "PEMEX 805 uniclub lago alberto", asignable: true },
  {
    id: 122432,
    nombre: "PEMEX 805 uniclub lago alberto - Uniclub",
    asignable: true
  },
  {
    id: 6121600,
    nombre: "PEMEX 805 uniclub lago alberto - Uniclub 4",
    asignable: true
  },
  {
    id: 4344423,
    nombre: "PEMEX 805 uniclub lago alberto - Uniclub2",
    asignable: true
  },
  { id: 1781, nombre: "PEPSICO", asignable: true },
  {
    id: 2029,
    nombre: "PERIODICO EXCELSIOR GRUPO IMAGEN SA DE CV",
    asignable: true
  },
  { id: 1773, nombre: "PERNOD RICARD", asignable: true },
  { id: 2476, nombre: "PFIZER S.A DE C.V", asignable: true },
  { id: 2257, nombre: "PHILIPS MEXICANA SA DE CV", asignable: true },
  {
    id: 1967,
    nombre: "PHILIPS MORRIS CIGATAM PRODUCTOS Y SERVICIOS, S. de R.L. de C.V",
    asignable: true
  },
  { id: 19512375, nombre: "PICTIK MEXICO", asignable: true },
  {
    id: 1916,
    nombre:
      "POLICIA FEDERAL, PROTECCIÓ“N FEDERAL, PREVENCION Y READAPTACIÓN SOCIAL",
    asignable: true
  },
  { id: 3656, nombre: "PORTER NOVELLI / KELLOGS", asignable: true },
  { id: 3441, nombre: "PORTER NOVELLI INFLUENCERS", asignable: true },
  { id: 3165, nombre: "PORTER NOVELLI RETO SW/INFLUENCERS", asignable: true },
  { id: 3373, nombre: "POSTERMEDIA", asignable: true },
  { id: 19512402, nombre: "POWER LOGISTIC CARGO GROUP", asignable: true },
  {
    id: 5002495,
    nombre: "PREFERANCIAL GERENCIA WG - Academia",
    asignable: true
  },
  {
    id: 4845595,
    nombre: "PREFERANCIAL GERENCIA WG - Corporativo",
    asignable: true
  },
  {
    id: 164745,
    nombre: "PREFERANCIAL GERENCIA WG - Part Time",
    asignable: true
  },
  {
    id: 6419825,
    nombre: "PREFERANCIAL GERENCIA WG - Part Time Especial WG",
    asignable: true
  },
  { id: 167360, nombre: "PREFERANCIAL GERENCIA WG - Uniclub", asignable: true },
  {
    id: 9808865,
    nombre: "PREFERANCIAL GERENCIA WG - Uniclub CT",
    asignable: true
  },
  { id: 2143, nombre: "PRESIDENCIA DE LA REPUBLICA", asignable: true },
  { id: 2798, nombre: "PRESS 360", asignable: true },
  {
    id: 2246,
    nombre: "PRESTACIONES UNIVERSALES SA DE CV ( SI VALE )",
    asignable: true
  },
  {
    id: 3028,
    nombre:
      "PRESTADORA DE SERVICIOS PROFESIONALES Y OPERATIVOS S.A DE C.V (AMSA)",
    asignable: true
  },
  { id: 1955, nombre: "PREVEM SEGUROS SA CV", asignable: true },
  { id: 2141, nombre: "PRI COMITE EJECUTIVO NACIONAL", asignable: true },
  { id: 3880, nombre: "PRIDE", asignable: true },
  { id: 3045, nombre: "PRINCIPLE MEXICO S.A DE C.V", asignable: true },
  { id: 1993, nombre: "PROCTER & GAMBLE S DE RL DE CV", asignable: true },
  {
    id: 1912,
    nombre: "PROCURADURIA GENERAL DE JUSTICIA DEL DF",
    asignable: true
  },
  {
    id: 2209,
    nombre: "PROCURADURIA GENERAL DE LA REPÚBLICA PGR",
    asignable: true
  },
  {
    id: 3624,
    nombre: "PRODUCTORA IMPULMAX EVENTO GRAN PREMIO HISTORICO",
    asignable: true
  },
  { id: 2500, nombre: "PRODUCTOS RICH,S.A DE C.V.", asignable: true },
  {
    id: 2034,
    nombre: "PROFUTURO SERVICIOS INTEGRALES GP  SA CV",
    asignable: true
  },
  { id: 1772, nombre: "PROMEXICO", asignable: true },
  { id: 2664, nombre: "PROMOCION GROUPON 30 DIAS", asignable: true },
  { id: 2971, nombre: "PROMOCION GROUPON UPSTER", asignable: true },
  {
    id: 5279980,
    nombre: "PROMOCION UNIVERSITARIOS - Allclub Universitario",
    asignable: true
  },
  {
    id: 7315800,
    nombre: "PROMOCION UNIVERSITARIOS - Uniclub2 Universitario",
    asignable: true
  },
  { id: 3141, nombre: "PROMOTORA 815", asignable: true },
  { id: 3036, nombre: "PROYECTO CAPITANES MKT", asignable: true },
  { id: 2391, nombre: "Proyecto ECS", asignable: true },
  {
    id: 17650386,
    nombre: "prueba Ivonne Normal - Plan Salud Empresarial",
    asignable: true
  },
  { id: 3334, nombre: "PRUEBA MKT CRM NUEVO", asignable: true },
  {
    id: 3962,
    nombre: "PUBLICACION 1000 EMPRESAS MUNDO EJECUTIVO",
    asignable: true
  },
  {
    id: 2612,
    nombre: "PUBLICIDAD FERRER Y ASOCIADOS, S.A DE C.V",
    asignable: true
  },
  { id: 3949, nombre: "PUBLICIDAD ZEFERINO SWBER", asignable: true },
  { id: 1848, nombre: "PUBLICIS", asignable: true },
  { id: 3507, nombre: "PUBLILAND PUEBLA", asignable: true },
  { id: 3952, nombre: "PUEBLA FC", asignable: true },
  { id: 19512404, nombre: "PULPPO PROPIEDADES", asignable: true },
  { id: 19512377, nombre: "PULSO INMOBILIARIO", asignable: true },
  { id: 2496, nombre: "PULSO INMOBILIARIO", asignable: true },
  { id: 1777, nombre: "PWC", asignable: true },
  { id: 2004, nombre: "QUALCOMM INTERNATIONAL INC", asignable: true },
  { id: 2828, nombre: "QUIKSILVER", asignable: true },
  { id: 2360, nombre: "RAAM SINERGIA", asignable: true },
  { id: 3416, nombre: "RADAR QUERETARO", asignable: true },
  { id: 3330, nombre: "RADIORAMA", asignable: true },
  { id: 3944, nombre: "RCG Saltillo", asignable: true },
  { id: 3242, nombre: "RE/MAX", asignable: true },
  { id: 3098, nombre: "RECREACION EMPRESARIAL", asignable: true },
  { id: 3189, nombre: "RECREACION EMPRESARIAL 2018", asignable: true },
  { id: 3852, nombre: "RECREACION EMPRESARIAL EN MEXICO", asignable: true },
  { id: 3190, nombre: "RECREACION EMPRESARIAL UNICLUBES", asignable: true },
  { id: 1825, nombre: "RED BULL", asignable: true },
  { id: 1766, nombre: "REDIT", asignable: true },
  { id: 19512417, nombre: "REINA MADRE", asignable: true },
  { id: 1807, nombre: "RENAULT & NISSAN", asignable: true },
  { id: 2941, nombre: "RESPONSABLE.NET", asignable: true },
  { id: 3976, nombre: "RETO ACTINVER", asignable: true },
  { id: 3650, nombre: "RETO LIV OUTLET BICI", asignable: true },
  { id: 3398, nombre: "REVISTA BEING", asignable: true },
  { id: 3487, nombre: "REVISTA CHILANGO", asignable: true },
  { id: 3286, nombre: "REVISTA ESR", asignable: true },
  { id: 3617, nombre: "REVISTA FEDERACION MEXICANA DE GOLF", asignable: true },
  { id: 3806, nombre: "REVISTA FREE", asignable: true },
  { id: 3182, nombre: "REVISTA MAXIM", asignable: true },
  { id: 3522, nombre: "REVISTA ONE PUTT", asignable: true },
  { id: 2732, nombre: "REVLON", asignable: true },
  { id: 3484, nombre: "REYES Y ASOCIADOS  FISCALISTAS SC", asignable: true },
  { id: 3599, nombre: "ROBERT BOSCH", asignable: true },
  { id: 2435, nombre: "ROCHE SERVICIOS DE MEXICO S.A DE C.V", asignable: true },
  { id: 3776, nombre: "ROCKET JOURNEY", asignable: true },
  { id: 3668, nombre: "RODADA FAMILIAR IMAGEN", asignable: true },
  { id: 1898, nombre: "ROMO PAILLES", asignable: true },
  { id: 1806, nombre: "ROTOPLAS", asignable: true },
  { id: 2061, nombre: "ROYAL HOLIDAY  BETAMEX", asignable: true },
  { id: 1856, nombre: "RSA ASEGURADORA", asignable: true },
  { id: 3372, nombre: "RUDE MEDIA PREVENTAS", asignable: true },
  { id: 3626, nombre: "RUTA MORELOS SWCUE", asignable: true },
  { id: 19512414, nombre: "SAAVI ENERGIA", asignable: true },
  { id: 3728, nombre: "SABORES DE MEXICO", asignable: true },
  {
    id: 2036,
    nombre: "SAE (Servicio de Administracion y Enajenacion de Bienes)",
    asignable: true
  },
  {
    id: 1951,
    nombre: "SAMSUNG ELECTRONICS CORPORATIVO SA DE CV",
    asignable: true
  },
  { id: 2548, nombre: "SANOFI AVENTIS DE MEXICO S.A DE C.V", asignable: true },
  { id: 2219, nombre: "SANTAMARINA & STETA, SC.", asignable: true },
  { id: 2075, nombre: "SANTANDER", asignable: true },
  { id: 3540, nombre: "SANTANDER CORTESIAS", asignable: true },
  { id: 1875, nombre: "SAP", asignable: true },
  { id: 1845, nombre: "SAT", asignable: true },
  { id: 3287, nombre: "SAVE THE CHILDREN", asignable: true },
  { id: 3780, nombre: "SCANSOURCE DE MEXICO S DE RL DE CV", asignable: true },
  { id: 2010, nombre: "SCHNEIDER ELECTRIC MEXICO", asignable: true },
  { id: 1809, nombre: "SCOTIABANK INVERLAT SA", asignable: true },
  {
    id: 2375,
    nombre: "SECRETARIA DE COMUNICACIONES Y TRANSPORTES",
    asignable: true
  },
  { id: 2235, nombre: "SECRETARIA DE ECONOMIA", asignable: true },
  {
    id: 2120,
    nombre: "SECRETARIA DE EDUCACION PUBLICA ( SEP )",
    asignable: true
  },
  {
    id: 135680,
    nombre: "SECRETARIA DE EDUCACION PUBLICA ( SEP ) - Uniclub",
    asignable: true
  },
  { id: 2294, nombre: "SECRETARIA DE ENERGIA", asignable: true },
  { id: 2105, nombre: "SECRETARIA DE FINANZAS DEL DF", asignable: true },
  { id: 2275, nombre: "SECRETARIA DE GOBERNACION SEGOB", asignable: true },
  { id: 2376, nombre: "SECRETARIA DE LA FUNCION PUBLICA SFP", asignable: true },
  { id: 2347, nombre: "SECRETARIA DE MARINA", asignable: true },
  { id: 2236, nombre: "SECRETARIA DE RELACIONES EXTERIORES", asignable: true },
  { id: 2287, nombre: "SECRETARIA DE SALUD", asignable: true },
  { id: 3071, nombre: "SECRETARIA DE SALUD", asignable: true },
  { id: 3445, nombre: "SECRETARIA DE SALUD 2019", asignable: true },
  {
    id: 1983,
    nombre: "SECRETARIA DEL TRABAJO Y PREVISION SOCIAL",
    asignable: true
  },
  {
    id: 2082,
    nombre: "SECRETARIA HACIENDA CREDITO PUBLICO ( SHCP )",
    asignable: true
  },
  { id: 2372, nombre: "SEGUROS MONTERREY NEW YORK LIFE", asignable: true },
  {
    id: 2190,
    nombre: "SEMARNAT(SECRETARIA DE MEDIO AMBIENTE Y RECURSOS NATURALES)",
    asignable: true
  },
  { id: 3304, nombre: "SENADI", asignable: true },
  { id: 3462, nombre: "SEP", asignable: true },
  { id: 19512393, nombre: "SEPHORA", asignable: true },
  { id: 1929, nombre: "SERVICIOS 3M", asignable: true },
  { id: 2894, nombre: "SERVICIOS BOVIS MX S.A DE C.V LAGO", asignable: true },
  { id: 2659, nombre: "SERVICIOS BROXEL S.A.P.I DE C.V", asignable: true },
  { id: 1961, nombre: "SERVICIOS MSB SA CV MEDICA SUR", asignable: true },
  { id: 1971, nombre: "SERVICIOS NARET SA DE CV", asignable: true },
  {
    id: 1899,
    nombre: "SERVICIOS PROFESIONALES RDS, SA DE CV PUMA",
    asignable: true
  },
  {
    id: 2607,
    nombre: "Servicios Sunbeam Coleman de México S.A. de C.V.",
    asignable: true
  },
  { id: 1804, nombre: "SHELL", asignable: true },
  { id: 3762, nombre: "SHOOTING KINETICS", asignable: true },
  { id: 3734, nombre: "SHOOTING LOAD", asignable: true },
  { id: 3598, nombre: "SIEMENS HEALTHCARE SERVICIOS", asignable: true },
  {
    id: 19512373,
    nombre:
      "SINDICATO DE TRABAJADORES DEL TRIBUNAL SUPERIOR DE JUSTICIA DE LA CDMX",
    asignable: true
  },
  { id: 1761, nombre: "SINDICATO PROGRESISTA", asignable: true },
  {
    id: 19512415,
    nombre: "SISTEMA DE TRANSFERENCIAS Y PAGOS STP",
    asignable: true
  },
  { id: 1776, nombre: "SKANDIA,( OLD MUTUAL )", asignable: true },
  { id: 3336, nombre: "SKYEMPIRE", asignable: true },
  { id: 2370, nombre: "Snake Santa Fe", asignable: true },
  { id: 163530, nombre: "Snake Santa Fe - Multiclub", asignable: true },
  { id: 2592, nombre: "SOCIOS FUNDADORES", asignable: true },
  { id: 2226, nombre: "SODEXO MOTIVATION SOLUTIONS SA DE CV", asignable: true },
  {
    id: 2628,
    nombre: "SOFTTEK SERVICIOS CORPORATIVOS S.A DE C.V",
    asignable: true
  },
  { id: 3580, nombre: "SOL A SOL", asignable: true },
  { id: 19512397, nombre: "SOLERA TECHNOLOGY", asignable: true },
  { id: 2812, nombre: "SONORA GRILL", asignable: true },
  { id: 1903, nombre: "SONY COMERCIO DE MEXICO SA DE CV", asignable: true },
  {
    id: 2535,
    nombre: "SORIANA CORPORACION CENTRALIZADORA S.A DE C.V",
    asignable: true
  },
  { id: 3166, nombre: "SPRING CAMP 2018 2 SEMANAS", asignable: true },
  { id: 3837, nombre: "SSA MEXICO SAN ANGEL", asignable: true },
  { id: 3807, nombre: "SSIRED CAPITAL SA DE CV", asignable: true },
  {
    id: 19512416,
    nombre: "STAR REINSURANCE BROKERS INTERMEDIARIO DE ASEGURADO",
    asignable: true
  },
  { id: 3825, nombre: "STEELERS FOOTBALL CAMP", asignable: true },
  {
    id: 2167,
    nombre: "STRYKER SERVICIOS ADMINISTRATIVOS S DE RL CV",
    asignable: true
  },
  { id: 3828, nombre: "SUPER HEROE KALIMAN", asignable: true },
  { id: 3558, nombre: "SUSHI ITTO", asignable: true },
  { id: 3700, nombre: "SW ESP COLEGIO INTERNACIONAL 2019", asignable: true },
  {
    id: 13449500,
    nombre: "SW ESP COLEGIO INTERNACIONAL 2019 - Allclub 6",
    asignable: true
  },
  {
    id: 11122200,
    nombre: "SW ESP COLEGIO INTERNACIONAL 2019 - Infantil Promo",
    asignable: true
  },
  {
    id: 9564500,
    nombre: "SW ESP COLEGIO INTERNACIONAL 2019 - Ninos Promo",
    asignable: true
  },
  {
    id: 13456900,
    nombre: "SW ESP COLEGIO INTERNACIONAL 2019 - Uniclub 6",
    asignable: true
  },
  { id: 3702, nombre: "SW ESP EXUSUARIOS GYM 2019", asignable: true },
  {
    id: 13456770,
    nombre: "SW ESP EXUSUARIOS GYM 2019 - Allclub 6",
    asignable: true
  },
  {
    id: 13464174,
    nombre: "SW ESP EXUSUARIOS GYM 2019 - Uniclub 6",
    asignable: true
  },
  { id: 3717, nombre: "SW REESTRUCTURA A 2019", asignable: true },
  { id: 3718, nombre: "SW REESTRUCTURA SW 2019", asignable: true },
  { id: 1757, nombre: "TACO HOLDING", asignable: true },
  { id: 2048, nombre: "TAKEDA SA DE CV", asignable: true },
  { id: 3708, nombre: "TALENTO SHOOTING 2018", asignable: true },
  { id: 3889, nombre: "TALENTOS IMPACT NITRO", asignable: true },
  { id: 2434, nombre: "TAMSA TUBOS DE ACERO DE MEXICO S.A", asignable: true },
  {
    id: 2501,
    nombre: "TATA CONSULTANCY SERVICES DE MEXICO, S.A DE C.V",
    asignable: true
  },
  {
    id: 19512369,
    nombre: "TDCONSENTIDO INBURSA VRIM MEDICALL HOME",
    asignable: true
  },
  { id: 3549, nombre: "TDJ MEDIOS MERIDA", asignable: true },
  { id: 2325, nombre: "TECHINT SERVICIOS", asignable: true },
  { id: 148800, nombre: "TECHINT SERVICIOS - Uniclub", asignable: true },
  { id: 3143, nombre: "TECNOHUMAN DAMSA S.A DE C.V", asignable: true },
  { id: 2059, nombre: "TECNOLOGICO DE MONTERREY", asignable: true },
  { id: 131776, nombre: "TECNOLOGICO DE MONTERREY - Uniclub", asignable: true },
  { id: 2177, nombre: "TELECOMUNICACIONES DE MEXICO", asignable: true },
  { id: 1859, nombre: "TELEFONICA MOVISTAR", asignable: true },
  { id: 2232, nombre: "TELEPERFORMANCE", asignable: true },
  { id: 1805, nombre: "TELEVISA", asignable: true },
  { id: 3207, nombre: "TELEVISA INTERCAMBIO", asignable: true },
  { id: 3634, nombre: "TELEVISA SALTILLO", asignable: true },
  {
    id: 2017,
    nombre: "TELEVISION PRODUCTS RETAIL, GENOMMA LAB",
    asignable: true
  },
  { id: 3121, nombre: "TELVISTA", asignable: true },
  { id: 2049, nombre: "TETRAPAK SA DE CV", asignable: true },
  { id: 2152, nombre: "THOMSON REUTERS DE MEXICO", asignable: true },
  { id: 3397, nombre: "Tiburones Rojos de Veracruz", asignable: true },
  { id: 3279, nombre: "TIP KIDS", asignable: true },
  { id: 2055, nombre: "TORK, SA DE CV", asignable: true },
  { id: 3665, nombre: "TORNEO AGOGO GRUPO GRAGO", asignable: true },
  { id: 3546, nombre: "TORNEO COPA DEL REY", asignable: true },
  { id: 4015, nombre: "Torneo golf Ferrari 2021 MKT", asignable: true },
  { id: 3148, nombre: "TORRE MANACAR", asignable: true },
  { id: 2851, nombre: "TOYOTA DISTRIBUIDORES", asignable: true },
  { id: 3759, nombre: "TRACK MARKETING QUERETARO", asignable: true },
  { id: 19512401, nombre: "TRAMITADORA DE CARGA AEREA SC", asignable: true },
  {
    id: 2474,
    nombre: "TRANSCANADA TCEM SERVICES COMPANY, S DE R.L DE C.V",
    asignable: true
  },
  { id: 3548, nombre: "TRES60 PUEBLA", asignable: true },
  { id: 1843, nombre: "TRESALIA CAPITAL", asignable: true },
  { id: 2403, nombre: "TREVOX MEXICO SC", asignable: true },
  { id: 3969, nombre: "TRIATLON INDOOR", asignable: true },
  {
    id: 1998,
    nombre: "TRIBUNAL ELECTORAL DEL PODER JUDICIAL DE LA FEDERACION TRIFE",
    asignable: true
  },
  { id: 3602, nombre: "TRIPLE I", asignable: true },
  {
    id: 2000,
    nombre: "TUPPERWARE BRANDS MEXICO S DE RL DE CV",
    asignable: true
  },
  { id: 2268, nombre: "TV AZTECA", asignable: true },
  { id: 2986, nombre: "TV Azteca (Pantallas en GDL y QRO)", asignable: true },
  { id: 3434, nombre: "TV AZTECA - SUSANA POSADAS", asignable: true },
  { id: 3652, nombre: "TV AZTECA PROMO ESPACIO", asignable: true },
  { id: 3515, nombre: "TV AZTECA SONORA", asignable: true },
  { id: 3794, nombre: "TV RADIO MEXIQUENSE", asignable: true },
  { id: 3430, nombre: "U STORAGE SERVICIOS", asignable: true },
  {
    id: 2669,
    nombre: "UBER CONDUCTORES UBER MEXICO TECHNOLOGY & SOFTWARE, S.A DE C.V",
    asignable: true
  },
  {
    id: 2668,
    nombre: "UBER EMPLEADOS, UBER MEXICO TECHNOLOGY & SOFTWARE,S.A DE C.V",
    asignable: true
  },
  { id: 2525, nombre: "UBS SERVICIOS S.A DE C.V", asignable: true },
  { id: 2350, nombre: "UDLAP CD DEL CARMEN", asignable: true },
  { id: 2340, nombre: "UNACAR CD. DEL CARMEN", asignable: true },
  { id: 2250, nombre: "UNAM PART TIME", asignable: true },
  { id: 141750, nombre: "UNAM PART TIME - Part Time", asignable: true },
  { id: 1579500, nombre: "UNAM PART TIME - Part Time Jr-Sr", asignable: true },
  { id: 2081, nombre: "UNIFAR SA DE CV GRUPO SAN PABLO", asignable: true },
  {
    id: 2759,
    nombre: "UNIFIN FINANCIERA SAB DE C.V. SOFOM ENR",
    asignable: true
  },
  { id: 1795, nombre: "UNILEVER", asignable: true },
  { id: 3574, nombre: "UNIVAR", asignable: true },
  { id: 2020, nombre: "UNIVERSIDAD ANAHUAC", asignable: true },
  { id: 129280, nombre: "UNIVERSIDAD ANAHUAC - Uniclub", asignable: true },
  {
    id: 3162,
    nombre: "UNIVERSIDAD AUTONOMA DE GUADALAJARA AC",
    asignable: true
  },
  { id: 2777, nombre: "UNIVERSIDAD DE GUADALAJARA", asignable: true },
  { id: 2420, nombre: "UNIVERSIDAD DE MONTERREY", asignable: true },
  {
    id: 2274,
    nombre: "UNIVERSIDAD DEL VALLE DE MEXICO S.C. UVM",
    asignable: true
  },
  { id: 2960, nombre: "UNIVERSIDAD GESTALT", asignable: true },
  { id: 1987, nombre: "UNIVERSIDAD IBEROAMERICANA", asignable: true },
  {
    id: 127168,
    nombre: "UNIVERSIDAD IBEROAMERICANA - Uniclub",
    asignable: true
  },
  { id: 3833, nombre: "UNIVERSIDAD INTERCONTINENTAL", asignable: true },
  { id: 3773, nombre: "UNIVERSIDAD ISEC DEL VALLE", asignable: true },
  { id: 2126, nombre: "UNIVERSIDAD LA SALLE", asignable: true },
  { id: 4738854, nombre: "UNIVERSIDAD LA SALLE - Multi Club", asignable: true },
  { id: 146694, nombre: "UNIVERSIDAD LA SALLE - Multiclub", asignable: true },
  { id: 2743, nombre: "UNIVERSIDAD LATINOAMERICANA ULA", asignable: true },
  { id: 2708, nombre: "UNIVERSIDAD MARISTA DE MERIDA AC", asignable: true },
  { id: 2802, nombre: "UNIVERSIDAD MOTOLINIA", asignable: true },
  { id: 2131, nombre: "UNIVERSIDAD PANAMERICANA (UP)", asignable: true },
  {
    id: 2231,
    nombre: "UNIVERSIDAD POPULAR AUTONOMA DEL ESTADO DE PUEBLA A.C.  UPAEP",
    asignable: true
  },
  {
    id: 142784,
    nombre:
      "UNIVERSIDAD POPULAR AUTONOMA DEL ESTADO DE PUEBLA A.C.  UPAEP - Uniclub",
    asignable: true
  },
  { id: 2094, nombre: "UNIVERSIDAD REGIOMONTANA", asignable: true },
  {
    id: 1986,
    nombre: "UNIVERSIDAD TECNOLOGICA DE MÉXICO UNITEC",
    asignable: true
  },
  { id: 2139, nombre: "UNIVERSIDAD UNAM", asignable: true },
  { id: 19512389, nombre: "UNIVERSIDADES LOTTUS", asignable: true },
  { id: 2390, nombre: "Universitaria Gold", asignable: true },
  { id: 2683, nombre: "UPSTER TELEFONICA MOVISTAR", asignable: true },
  { id: 3643, nombre: "VALLAS MOVILES 2020", asignable: true },
  { id: 3506, nombre: "VALLAS PUEBLA", asignable: true },
  { id: 2148, nombre: "VECTOR CASA DE BOLSA,SA DE CV", asignable: true },
  { id: 2579, nombre: "VELVET ELEMENTA", asignable: true },
  { id: 3496, nombre: "VENTA TARJETAS DIC 2018", asignable: true },
  { id: 3068, nombre: "VERTICE", asignable: true },
  { id: 1889, nombre: "VESPA", asignable: true },
  { id: 1791, nombre: "VITALMEX DISTRIBUCION", asignable: true },
  { id: 3935, nombre: "VIVE 2020", asignable: true },
  { id: 3614, nombre: "VOGA GROUP VERACRUZ", asignable: true },
  { id: 2214, nombre: "VOLARIS", asignable: true },
  { id: 2181, nombre: "VOLKSWAGEN DE MEXICO", asignable: true },
  { id: 139584, nombre: "VOLKSWAGEN DE MEXICO - Uniclub", asignable: true },
  { id: 2014, nombre: "VOLVO SERVICES MEXICO SA DE CV", asignable: true },
  { id: 3956, nombre: "VOZ INSTITUCIONAL LUISA MARIA", asignable: true },
  { id: 1792, nombre: "WALMART MEXICO", asignable: true },
  { id: 114688, nombre: "WALMART MEXICO - Uniclub", asignable: true },
  { id: 2135, nombre: "WELLNESS CLUB SA DE CV", asignable: true },
  { id: 136640, nombre: "WELLNESS CLUB SA DE CV - Uniclub", asignable: true },
  { id: 3485, nombre: "WESTMEDIA GDL 2020", asignable: true },
  { id: 2278, nombre: "WG_CENTRO TECNOLOGICO SIGLO XXI", asignable: true },
  {
    id: 4221134,
    nombre: "WG_CENTRO TECNOLOGICO SIGLO XXI - Corporativo",
    asignable: true
  },
  {
    id: 145792,
    nombre: "WG_CENTRO TECNOLOGICO SIGLO XXI - Uniclub",
    asignable: true
  },
  { id: 3204, nombre: "WG_RADIO LATINA", asignable: true },
  { id: 3075, nombre: "WINDSOR", asignable: true },
  { id: 3476, nombre: "WORLD PADEL TOUR", asignable: true },
  { id: 3761, nombre: "WORLD PADEL TOUR 2", asignable: true },
  { id: 2940, nombre: "XDESING", asignable: true },
  { id: 3746, nombre: "YANBAL", asignable: true },
  { id: 1801, nombre: "YOUNG AND RUBICAM GROUP", asignable: true },
  {
    id: 3074,
    nombre: "Z 4076 DENTALIA INTERCAMBIO COMERCIAL",
    asignable: false
  },
  { id: 1878, nombre: "Z 4076 DISH INTERCAMBIO COMERCIAL", asignable: false },
  {
    id: 2715,
    nombre: "Z 4076 ESSITY (TORK) INTERCAMBIO COMERCIAL",
    asignable: false
  },
  {
    id: 2560,
    nombre: "Z 4076 EXPANSION INTERCAMBIO COMERCIAL",
    asignable: false
  },
  {
    id: 2815,
    nombre: "Z 4076 GATORADE - GEPP INTERCAMBIO COMERCIAL",
    asignable: false
  },
  { id: 3997, nombre: "Z 4076 YOPRO CAMPAÑA DANONE", asignable: false },
  { id: 3027, nombre: "Z 4092 ARENA INTERCAMBIO COMERCIAL", asignable: false },
  { id: 3011, nombre: "Z 4096 IZZI INTERCAMBIO COMERCIAL", asignable: false },
  { id: 3998, nombre: "Z MK GUSTAVO ADOLFO INFANTE", asignable: false },
  { id: 4002, nombre: "Z- 001  IE GERARDO ESCUTIA", asignable: false },
  { id: 3754, nombre: "Z- GRV- AGENCIA COREANA NAVE 01", asignable: false },
  { id: 3245, nombre: "Z- MK- HALL OF FAME", asignable: false },
  { id: 3964, nombre: "Z-4092 ACCENTURE NOV-DIC covid 2020", asignable: false },
  { id: 2789, nombre: "Z-4092 ACCENTURE SC", asignable: false },
  { id: 2510, nombre: "Z-4092-Activaciones Medifast", asignable: false },
  { id: 3251, nombre: "Z-4999 ABA CARGO PLAN SALUD", asignable: false },
  { id: 3918, nombre: "Z-4999 ABBOTT PLAN 360", asignable: false },
  { id: 2890, nombre: "Z-4999 ACTINVER ALLCLUB", asignable: false },
  { id: 3325, nombre: "Z-4999 ACTINVER DIRECTIVOS", asignable: false },
  { id: 2891, nombre: "Z-4999 ACTINVER UNICLUB CDMX", asignable: false },
  { id: 2895, nombre: "Z-4999 ACTINVER UNICLUB FORANEO", asignable: false },
  {
    id: 3868,
    nombre: "Z-4999 ADICIONALES TOYOTA PLAN SALUD",
    asignable: false
  },
  { id: 3799, nombre: "Z-4999 AMADEUS PLAN SALUD", asignable: false },
  { id: 3749, nombre: "Z-4999 ANVA (ANZALDO) GPO", asignable: false },
  {
    id: 17357544,
    nombre: "Z-4999 APPLE MIX PLAN 360 - Plan Salud Empresarial",
    asignable: false
  },
  { id: 3554, nombre: "Z-4999 APRHIFAC PLAN SALUD", asignable: false },
  { id: 3757, nombre: "Z-4999 ARAUCO PLAN SALUD", asignable: false },
  { id: 3913, nombre: "Z-4999 ATT LOCKTON 2020", asignable: false },
  { id: 3134, nombre: "Z-4999 ATXK ATEXCA PLAN SALUD", asignable: false },
  { id: 3892, nombre: "Z-4999 BASHAM PLAN 360", asignable: false },
  { id: 3687, nombre: "Z-4999 BBVA PLAN SALUD", asignable: false },
  {
    id: 17668134,
    nombre: "Z-4999 BDF MIX FAMILIARES - Plan Salud Empresarial",
    asignable: false
  },
  { id: 3616, nombre: "Z-4999 BIOMERIEUX PLAN SALUD", asignable: false },
  { id: 3705, nombre: "Z-4999 BOSTON SCIENTIFIC PLAN SALUD", asignable: false },
  { id: 3523, nombre: "Z-4999 BTG PACTUAL PLAN SALUD", asignable: false },
  {
    id: 3033,
    nombre: "Z-4999 BUFETTE SANCHEZ NAVARRO PLAN SALUD",
    asignable: false
  },
  { id: 4045, nombre: "Z-4999 BUPA PLAN SALUD EMPRESARIAL", asignable: false },
  { id: 3800, nombre: "Z-4999 CASA CUERVO PLAN SALUD", asignable: false },
  { id: 3858, nombre: "Z-4999 CHG-MERIDIAN PLAN SALUD", asignable: false },
  { id: 3910, nombre: "Z-4999 CINEMEX MIX PLAN 360", asignable: false },
  {
    id: 17348670,
    nombre: "Z-4999 CINEMEX MIX PLAN 360 - Plan Salud Empresarial",
    asignable: false
  },
  { id: 3798, nombre: "Z-4999 CLUB DE PILOTOS PLAN SALUD", asignable: false },
  { id: 3559, nombre: "Z-4999 COMEX PPG PLAN SALUD", asignable: false },
  { id: 3934, nombre: "Z-4999 DECATHLON PLAN 360", asignable: false },
  {
    id: 3863,
    nombre: "Z-4999 DICONSA MIX SEGALMEX LICONSA PLAN SALUD",
    asignable: false
  },
  { id: 3738, nombre: "Z-4999 EL SOTANO", asignable: false },
  { id: 3641, nombre: "Z-4999 ELEMENT ELFICO PLAN SALUD", asignable: false },
  { id: 2809, nombre: "Z-4999 ENEL GREEN POWER PLAN SALUD", asignable: false },
  { id: 3894, nombre: "Z-4999 ENGIE PLAN 360", asignable: false },
  { id: 3234, nombre: "Z-4999 EVANZA PLAN SALUD P GRAL", asignable: false },
  { id: 3447, nombre: "Z-4999 FOX 1 PLAN SALUD", asignable: false },
  { id: 3459, nombre: "Z-4999 FOX II PLAN SALUD", asignable: false },
  { id: 3490, nombre: "Z-4999 FOX III PLAN SALUD", asignable: false },
  { id: 3424, nombre: "Z-4999 FOX PLAN SALUD", asignable: false },
  { id: 3555, nombre: "Z-4999 FOX V PLAN SALUD", asignable: false },
  { id: 3557, nombre: "Z-4999 GRUPO AXO PLAN SALUD", asignable: false },
  {
    id: 3808,
    nombre: "Z-4999 GRUPO CONCENTRADOR SW FAMILIARES PLAN SALUD",
    asignable: false
  },
  { id: 2919, nombre: "Z-4999 HIR CASA PLAN SALUD", asignable: false },
  {
    id: 17379729,
    nombre: "Z-4999 ICE MIX PLAN 360 - Plan Salud Empresarial",
    asignable: false
  },
  {
    id: 3498,
    nombre: "Z-4999 INTERESSE PLAN SALUD suspendido temporalmente",
    asignable: false
  },
  { id: 3747, nombre: "Z-4999 INVEX BANCA PLAN DE SALUD", asignable: false },
  {
    id: 3394,
    nombre: "Z-4999 IP2 ENTERTAINMENT THE AMERICAS MANAGEMENT PLAN SALUD",
    asignable: false
  },
  { id: 3819, nombre: "Z-4999 JUGOS DEL VALLE PLAN SALUD", asignable: false },
  { id: 3763, nombre: "Z-4999 KIWILIMON PLAN SALUD", asignable: false },
  { id: 3862, nombre: "Z-4999 LICONSA PLAN SALUD", asignable: false },
  { id: 3801, nombre: "Z-4999 LLORENTE & CUENCA PLAN SALUD", asignable: false },
  { id: 3696, nombre: "Z-4999 LOCKTON COMPUCOM PLAN SALUD", asignable: false },
  { id: 3584, nombre: "Z-4999 LOCKTON PLAN SALUD", asignable: false },
  {
    id: 3647,
    nombre: "Z-4999 LOCKTON SEGUROS MONTERREY PLAN SALUD",
    asignable: false
  },
  {
    id: 3870,
    nombre: "Z-4999 LOREAL MIX FAMILIARES PLAN SALUD",
    asignable: false
  },
  {
    id: 17171190,
    nombre: "Z-4999 LOREAL MIX FAMILIARES PLAN SALUD - Plan Salud Empresarial",
    asignable: false
  },
  { id: 3684, nombre: "Z-4999 LOREAL PLAN SALUD", asignable: false },
  { id: 3792, nombre: "Z-4999 LUNDBECK MEXICO PLAN SALUD", asignable: false },
  { id: 3900, nombre: "Z-4999 MABE MIX EMPLEADOS PLAN 360", asignable: false },
  {
    id: 14562600,
    nombre: "Z-4999 MABE MIX EMPLEADOS PLAN 360 - Allclub 7",
    asignable: false
  },
  {
    id: 17304300,
    nombre: "Z-4999 MABE MIX EMPLEADOS PLAN 360 - Plan Salud Empresarial",
    asignable: false
  },
  { id: 3853, nombre: "Z-4999 Mc CAIN PLAN SALUD", asignable: false },
  { id: 3865, nombre: "Z-4999 MELTSAN PLAN SALUD", asignable: false },
  {
    id: 17295426,
    nombre:
      "Z-4999 MICHAEL PAGE MIX FAMILIARES DE EMPLEADOS PLAN 360 - Plan Salud Empresarial",
    asignable: false
  },
  { id: 3883, nombre: "Z-4999 MICHAEL PAGE PLAN 360", asignable: false },
  { id: 3958, nombre: "Z-4999 MK NADADORES MASTERS BECADOS", asignable: false },
  { id: 3423, nombre: "Z-4999 MSD INTERVET PLAN SALUD", asignable: false },
  { id: 3421, nombre: "Z-4999 MSD SALUD HUMANA PLAN SALUD", asignable: false },
  { id: 3957, nombre: "Z-4999 NADADORES MASTER", asignable: false },
  {
    id: 17557209,
    nombre: "Z-4999 NADADORES MASTER - Plan Salud Empresarial",
    asignable: false
  },
  {
    id: 17251056,
    nombre:
      "Z-4999 P&G MIX FAMILIARES DIRECTOS PLAN 360° - Plan Salud Empresarial",
    asignable: false
  },
  {
    id: 17246619,
    nombre: "Z-4999 P&G MIX PLAN 360° - Plan Salud Empresarial",
    asignable: false
  },
  { id: 3916, nombre: "Z-4999 PAYCLIP PLAN 360", asignable: false },
  { id: 3953, nombre: "Z-4999 PROFUTURO GNP PLAN SALUD", asignable: false },
  { id: 3551, nombre: "Z-4999 PROMOCION H&M", asignable: false },
  { id: 3813, nombre: "Z-4999 QUARKSOFT PLAN SALUD", asignable: false },
  { id: 3840, nombre: "Z-4999 RED HUMAN PLAN SALUD", asignable: false },
  { id: 3710, nombre: "Z-4999 RICHEMONT MEXICO PLAN SALUD", asignable: false },
  { id: 3052, nombre: "Z-4999 RL&A PLAN SALUD", asignable: false },
  {
    id: 3826,
    nombre: "Z-4999 SANDOVAL CASTANEDA Y ASOCIADOS S.A",
    asignable: false
  },
  { id: 3890, nombre: "Z-4999 SANOFI PLAN 360", asignable: false },
  { id: 3836, nombre: "Z-4999 SEPSISA PLAN SALUD", asignable: false },
  { id: 3920, nombre: "Z-4999 TEAM PLAN 360", asignable: false },
  {
    id: 3026,
    nombre: "Z-4999 TFSM TOYOTA SERVICIOS PLAN SALUD",
    asignable: false
  },
  { id: 3022, nombre: "Z-4999 TMSM TOYOTA PLAN SALUD", asignable: false },
  {
    id: 3960,
    nombre: "Z-4999 TRION3 EQUIPO TRIATLON PLAN 360",
    asignable: false
  },
  { id: 3679, nombre: "Z-4999 WASPERT PLAN SALUD", asignable: false },
  { id: 3909, nombre: "Z-4999 WERNER PLAN 360", asignable: false },
  {
    id: 2981,
    nombre: "Z-4999 XSCHEL DE MEXICO S.A DE C.V PLAN SALUD",
    asignable: false
  },
  { id: 3538, nombre: "Z-MK -ACTITUD VERACRUZ", asignable: false },
  { id: 3975, nombre: "Z-MK Club Atlante FC", asignable: false },
  { id: 3948, nombre: "Z-MK- ADELANTADOS SW", asignable: false },
  { id: 3451, nombre: "Z-MK- ALDEA NAVIDEÑA", asignable: false },
  { id: 3318, nombre: "Z-MK- FAMILIA KURI", asignable: false },
  { id: 3866, nombre: "Z-MK- HALL OF FAME", asignable: false },
  { id: 3963, nombre: "Z-MK-AIRE LIBRE 2020", asignable: false },
  { id: 4032, nombre: "Z-MKT- ANUNCIART", asignable: false },
  { id: 4033, nombre: "Z-MKT- BBVA", asignable: false },
  { id: 4027, nombre: "Z-MKT- EXCELSIOR", asignable: false },
  {
    id: 4041,
    nombre: "Z-MKT- FORMACIÓN EJECUTIVA EMPRESARIAL",
    asignable: false
  },
  { id: 4022, nombre: "Z-MKT- KIOSCOS", asignable: false },
  { id: 4023, nombre: "Z-MKT- MEXICO TRAVEL CHANNEL 1", asignable: false },
  { id: 4024, nombre: "Z-MKT- MEXICO TRAVEL CHANNEL 2", asignable: false },
  { id: 4040, nombre: "Z-MKT- MULTIMEDIOS", asignable: false },
  { id: 4025, nombre: "Z-MKT- RESTAURANTES", asignable: false },
  { id: 4042, nombre: "Z-MKT- REVISTA LÍDER", asignable: false },
  { id: 4026, nombre: "Z-MKT-SIETE VEINTICUATRO 2", asignable: false }
];
