import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import {
  Container,
  NavbarBrand,
  Nav,
  Navbar,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavItem,
  NavLink,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col
} from "reactstrap";
import take from "lodash.take";
import words from "lodash.words";
import toNumber from "lodash.tonumber";
import isNaN from "lodash.isnan";
import upperCase from "lodash.uppercase";
import { withRouter } from "react-router-dom";
import { UserInfo } from "../../../types-business/Profile";
import s from "./styles.scss";
import logoUrl from "../../../media/img/logo.png";
import { getUserMenu } from "../../../utils/accessTree";

interface Props {
  userContext: UserInfo;
  history: any;
  client: any;
  logoutUser: () => any;
  location: any;
  match: any;
  accessTreeAvailable: boolean;
  logOutSuccess: boolean;
  restartApp: () => any;
  headerLabel: string;
}

interface State {
  isOpen: object;
  hover: object;
  logoutSuccess: boolean;
}

class Header extends React.Component<Props, State> {
  state = {
    isOpen: {},
    hover: {},
    menuAnterior: "",
    menusAnteriores: {},
    logoutSuccess: false
  };

  toggle = (id: any, menu: any) => {
    const filterOnlyInteger = (x: any) => {
      let aux = Object.entries(x);
      let newObject = {};
      for (let i = 0; i < aux.length; i++) {
        if (!isNaN(parseInt(aux[i][0]))) {
          newObject[aux[i][0]] = aux[i][1];
        }
      }
      return newObject;
    };

    const filterSimpleString = (x: any) => {
      let aux = Object.entries(x);
      let newObject = {};
      for (let i = 0; i < aux.length; i++) {
        newObject[aux[i][0]] = aux[i][1];
      }
      return newObject;
    };

    let isOpen = { ...this.state.isOpen };
    isOpen[-1] = false;
    menu.forEach(({ id }) => (isOpen[id] = false));

    if (this.state.menusAnteriores[0] !== undefined) {
      if (this.state.menusAnteriores[0].text === id) {
        isOpen = filterSimpleString(isOpen);
      } else {
        isOpen = filterOnlyInteger(isOpen);
      }
    } else {
      isOpen = filterOnlyInteger(isOpen);
    }

    this.setState(state => ({
      ...state,
      isOpen: { ...isOpen, [id]: !state.isOpen[id] },
      menuAnterior: id,
      menusAnteriores: menu
    }));
  };

  oldToggle = (id, menu) => {
    const isOpen = { ...this.state.isOpen };
    isOpen[-1] = false;
    menu.forEach(({ id }) => {
      isOpen[id] = false;
    });
    this.setState(state => ({
      ...state,
      isOpen: { ...isOpen, [id]: !state.isOpen[id] }
    }));
  };

  unToggle = () => this.setState({ isOpen: {} });

  setOnHover = display => {
    this.setState(prevState => ({
      ...prevState,
      hover: {
        ...prevState.hover,
        [display]: !prevState.hover[display]
      }
    }));
  };

  getBradcrumbs = () => {
    const stages = (!!this.props.headerLabel
      ? this.props.headerLabel
      : this.props.location.pathname
    ).split("/");
    const breadCrumbs = stages
      .map((stage, index) => ({
        href: `${take(stages, index + 1).join("/")}`,
        stage
      }))
      .filter(({ stage }) => stage.length && isNaN(toNumber(stage)))
      .map(({ stage, href }) => ({ stage: words(stage), href }));

    return (
      <Breadcrumb className={s.breadCrumb}>
        {breadCrumbs.map(({ stage, href }, index) =>
          index !== breadCrumbs.length - 1 ? (
            <BreadcrumbItem key={index}>
              <a>{upperCase(stage.join(" "))}</a>
            </BreadcrumbItem>
          ) : (
            <BreadcrumbItem active key={index}>
              <span>{upperCase(stage.join(" "))}</span>
            </BreadcrumbItem>
          )
        )}
      </Breadcrumb>
    );
  };

  goTo = (e: any, path: any) => {
    e.preventDefault();
    if (path) {
      this.props.history.push(path);
    }
  };

  getSubTree = (node: any, father: any, i: any) => {
    const { text, menu, url } = node;
    if (!menu.length) {
      return (
        <DropdownItem
          className={s.dropdownTitle}
          onClick={e => this.goTo(e, url)}
          key={text}
          onMouseEnter={() => this.oldToggle(node, father)}
        >
          {text}
        </DropdownItem>
      );
    } else {
      return (
        <Dropdown key={text} isOpen={this.state.isOpen[text]} toggle={() => {}}>
          <DropdownToggle
            className={`${s.subMenuTitle} ${s.dropdownTitle}`}
            nav
            caret
            onClick={(e: any) => this.goTo(e, url)}
            onMouseEnter={() => this.toggle(text, menu)}
          >
            <span>{text}</span>
          </DropdownToggle>
          <DropdownMenu className={s.subMenu}>
            {menu.map((m: any) => this.getSubTree(m, menu, i++))}
          </DropdownMenu>
        </Dropdown>
      );
    }
  };

  componentDidMount() {
    this.generateuserBgImage();
  }

  logout = () => {
    this.props
      .logoutUser()
      .then(() => {
        this.setState({
          logoutSuccess: true
        });
        this.props.restartApp();
      })
      .catch(e => console.error(e));
  };

  goToMenu = (e, { url }) => {
    e.preventDefault();
    if (url) {
      this.props.history.push(url);
    }
  };

  isDevEnviroment = () => {
    //console.info(window.location.pathname);
    //console.info(window.location.href);
    //console.info(!window.location.href.includes("crmqa"));
    return !window.location.href.includes("crmqa");
  };

  generateuserBgImage = () => {
    const isDevEnv = this.isDevEnviroment();

    if (!!this.props && !!this.props.userContext) {
      const { username } = this.props.userContext;

      var canvas = document.createElement("canvas");
      canvas.id = "CursorLayer";
      canvas.width = 90;
      canvas.height = 180;
      canvas.style.position = "absolute";

      var ctx = canvas.getContext("2d");
      if (isDevEnv) {
        ctx.fillStyle = "#cceeff";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
      }
      ctx.globalAlpha = 0.1;
      ctx.fillStyle = "black";
      ctx.font = "18px Arial";

      ctx.save();
      ctx.rotate((-Math.PI * 2) / (0.8 * 6));
      ctx.textAlign = "center";
      ctx.fillText(username, -100, 70);
      ctx.restore();

      var img = new Image();
      img.src = canvas.toDataURL("image/png");
      document.body.style.backgroundImage = "url('" + img.src + "')";
    }
  };

  render() {
    const isDevEnv = this.isDevEnviroment();

    return (
      <div>
        {/*</div><div className={!isDevEnv ? s.root : s.rootdev}>*/}
        <div className={s.root}>
          {!!isDevEnv && (
            <h1
              style={{ color: "#FF0000", fontWeight: "bold" }}
              className="mb-3 text-center"
            >
              AMBIENTE DE DESARROLLO
            </h1>
          )}
          <div className="ml-2 mr-2">
            <Navbar>
              <NavbarBrand
                onClick={() => this.props.history.push("/")}
                className={s.logoContainer}
              >
                <img src={logoUrl} className="img-fluid" />
              </NavbarBrand>
              {this.props.userContext &&
              this.props.userContext.name &&
              this.props.accessTreeAvailable ? (
                <Nav className="ml-auto">
                  {getUserMenu()
                    .map((menu, index) => {
                      return (
                        <NavItem key={index}>
                          {!menu.menu.length ? (
                            <NavLink
                              className={s.navLink}
                              onMouseEnter={() =>
                                this.toggle(menu.id, getUserMenu())
                              }
                              onMouseLeave={this.unToggle}
                              onClick={(e: any) => this.goToMenu(e, menu)}
                            >
                              {menu.text}
                            </NavLink>
                          ) : (
                            <Dropdown
                              key={index}
                              isOpen={this.state.isOpen[menu.id]}
                              toggle={""}
                            >
                              <DropdownToggle
                                className={s.headerTitle}
                                nav
                                caret
                                onMouseEnter={() =>
                                  this.toggle(menu.id, getUserMenu())
                                }
                              >
                                <span>{menu.text}</span>
                              </DropdownToggle>
                              <DropdownMenu onMouseLeave={this.unToggle}>
                                {menu.menu.map(m =>
                                  this.getSubTree(m, menu.menu, 0)
                                )}
                              </DropdownMenu>
                            </Dropdown>
                          )}
                        </NavItem>
                      );
                    })
                    .concat(
                      <Dropdown key={-1} isOpen={this.state.isOpen[-1]}>
                        <DropdownToggle
                          nav
                          caret
                          className={s.headerTitle}
                          onMouseEnter={() => this.toggle(-1, getUserMenu())}
                        >
                          <span>
                            {this.props.userContext
                              ? `${this.props.userContext.name || ""} ${this
                                  .props.userContext.firstSurname || ""} ${this
                                  .props.userContext.secondSurname || ""}`
                              : null}
                          </span>
                        </DropdownToggle>
                        <DropdownMenu
                          onMouseLeave={() => this.toggle(-1, getUserMenu())}
                        >
                          <DropdownItem
                            className={s.dropdownTitle}
                            onClick={() => this.logout()}
                          >
                            Cerrar Sesión
                          </DropdownItem>
                          <DropdownItem
                            className={s.dropdownTitle}
                            onClick={e =>
                              this.goToMenu(e, { url: "/password" })
                            }
                          >
                            Cambiar contraseña
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    )}
                </Nav>
              ) : null}
            </Navbar>
          </div>
          <div className="text-right pr-5 pb-3">
            <span style={{ fontSize: "0.8em" }}>
              {(
                (this.props.userContext &&
                  this.props.userContext.positions &&
                  this.props.userContext.positions.length > 0 &&
                  this.props.userContext.positions) ||
                (this.props.userContext && this.props.userContext.userId
                  ? [{ name: "SIN PUESTO" }]
                  : [])
              )
                .map(p => p.name || "")
                .join(", ")}
              {this.props.userContext
                ? `${
                    !!this.props.userContext.clubSessionName
                      ? " - CLUB " +
                        this.props.userContext.clubSessionName.toUpperCase() +
                        ""
                      : ""
                  }`
                : null}
            </span>
          </div>
        </div>
        <div>
          <Container>
            <Row>
              <Col className="p-0 m-0 pt-2">{this.getBradcrumbs()}</Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(s)(Header));
