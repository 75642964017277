import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";
import { QueryArguments } from "../../types-business/QueryArguments";
import FileDownload from "js-file-download";
import moment from "moment";
import { errorNotification } from "../../utils/notifications";

const baseUrl = `/api`;
const productsBaseUrl = `/products`;

const PAGE_SIZE = 10000;

export const getClassificationByLevel = (level: number): IAction => {
  return {
    type: actionTypes.GET_PRODUCTS_CLASSIFICATION_BY_LEVEL,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/products/classification`,
        {
          withCredentials: true,
          params: { nivel: level }
        }
      )
    }
  };
};

export const getClassificationByLevelRequest = (level: number) => {
  return axios.get(`${constants.BASE_URL}${baseUrl}/products/classification`, {
    withCredentials: true,
    params: { nivel: level }
  });
};

export const createClassificationNode = (data: object) => {
  return axios.post(
    `${constants.BASE_URL}${baseUrl}/products/classification/create`,
    data,
    {
      withCredentials: true
    }
  );
};

export const updateClassificationNode = (
  classificationId: number,
  data: object
) => {
  return axios.put(
    `${constants.BASE_URL}${baseUrl}/products/classification/update/${classificationId}`,
    data,
    {
      withCredentials: true
    }
  );
};

export const getProductsAllTypes = (params: object): IAction => {
  return {
    type: actionTypes.GET_PRODUCTS_ALL_TYPES,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/products/general`, {
        withCredentials: true,
        params
      })
    }
  };
};

export const activate = (products: object[]): IAction => {
  return {
    type: actionTypes.ACTIVATE_PRODUCTS,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${baseUrl}/products-activation/activate`,
        products,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const sendMembersTypesRules = (
  membresiaId: string,
  data: object[]
): IAction => {
  return {
    type: actionTypes.SEND_MEMBERS_RULES,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${baseUrl}/memberships/${membresiaId}/members/types-rules`,
        data,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getMembersTypesRules = (membresiaId: string): IAction => {
  return {
    type: actionTypes.GET_MEMBERS_RULES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/memberships/${membresiaId}/members/types-rules`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getPayrollConcepts = (productoId: string): IAction => {
  return {
    type: actionTypes.GET_PAYROLL_CONCEPT,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/products/${productoId}/conceptos-nomina`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getPayrollConceptsSelect = (
  clubId: string,
  productoConcepto: string
): IAction => {
  return {
    type: actionTypes.GET_PAYROLL_CONCEPT_SELECT,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/products/concepto-nomina/${clubId}/${productoConcepto}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const sendPayrollConceptsSave = (data: object): IAction => {
  return {
    type: actionTypes.POST_PAYROLL_CONCEPT_SAVE,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${baseUrl}/maintenances/productos/conceptos-nomina`,
        data,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const deletePayrollConceptsRequest = (data: any) => {
  const { productoId, clubId } = data;
  return axios.delete(
    `${constants.BASE_URL}${baseUrl}/maintenances/productos/conceptos-nomina?productoId=${productoId}&clubId=${clubId}`,
    {
      withCredentials: true
    }
  );
};

export const getProductsCategories = (): IAction => {
  return {
    type: actionTypes.GET_PRODUCTS_CATEGORIES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/maintenances/categories`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getBIIndicators = (): IAction => {
  return {
    type: actionTypes.GET_BI_INDICATORS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/maintenances/indicators`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const sendPricesFile = (pricesFile: Blob, fileName: string): IAction => {
  const data = new FormData();
  data.append("file", pricesFile);
  data.append("filename", fileName);

  return {
    type: actionTypes.SEND_PRICES_FILE,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${baseUrl}/maintenances/massive-prices-file`,
        data,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const setPricesFile = (payload: Blob): IAction => {
  return {
    type: actionTypes.SET_PRICES_FILE,
    payload
  };
};

export const getMemberships = (): IAction => {
  return {
    type: actionTypes.GET_MEMBERSHIPS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/memberships`, {
        withCredentials: true,
        params: {
          size: PAGE_SIZE,
          page: 0,
          ...constants.DEFAULT_SORTING_PARAMS
        }
      })
    }
  };
};

export const getMaintenancesMembership = (membershipId): IAction => {
  return {
    type: actionTypes.GET_MAINTENANCES_MEMBERSHIP,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/memberships/${membershipId}/maintenances`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const searchByAttributes = (attributes): IAction => {
  return {
    type: actionTypes.SEARCH_MAINTENANCES_BY_ATTRIBUTES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/maintenances/search`,
        {
          withCredentials: true,
          params: {
            ...attributes
          }
        }
      )
    }
  };
};

export const getMassivePricesFile = (params): IAction => {
  return {
    type: actionTypes.GET_MASSIVE_PRICES_FILE,
    payload: {
      promise: axios
        .get(
          `${constants.BASE_URL}${baseUrl}/maintenances/massive-prices-file`,
          {
            withCredentials: true,
            params,
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          FileDownload(data, "precios-mantenimiento.csv");
        })
    }
  };
};

export const getMassivePricesDownloadOnParts = async (
  maintenancesIds,
  clubsIds,
  callback
) => {
  try {
    const precioSegmentos = [];
    const clubes = clubsIds.map(({ id }) => id).join(",");

    for (let i = 0; i < maintenancesIds.length; i += 10) {
      let segmento = maintenancesIds.slice(i, i + 10);
      console.info(segmento);
      console.info(new Date());

      const response = await axios.get(
        `${constants.BASE_URL}${baseUrl}/maintenances/massive-prices-file`,
        {
          withCredentials: true,
          params: {
            maintenancesIds: segmento.map(({ id }) => id).join(","),
            clubsIds: clubes,
            addHeader: i == 0
          },
          responseType: "text"
        }
      );

      precioSegmentos.push(response.data);
    }

    FileDownload(
      precioSegmentos.join("") || "No se encontró información",
      "precios-mantenimiento.csv"
    );
    callback && callback();
  } catch (errr) {
    errorNotification("No se pudo descargar el archivo de mantenimientos");
    callback && callback();
  }
};

export const getMassivePricesDownload = (params, callback) => {
  axios
    .get(`${constants.BASE_URL}${baseUrl}/maintenances/massive-prices-file`, {
      withCredentials: true,
      params,
      responseType: "blob"
    })
    .then(({ data }) => {
      FileDownload(
        data || "No se encontró información",
        "precios-mantenimiento.csv"
      );
      callback && callback();
    })
    .catch(err => {
      errorNotification("No se pudo descargar el archivo de mantenimientos");
      callback && callback();
    });
};

const getBasePath = (tipoProducto: string) => {
  if (tipoProducto === "membresias") {
    return "memberships";
  } else if (tipoProducto === "mantenimientos") {
    return "maintenances";
  } else {
    return "";
  }
};

export const getMembershipMembers = (membresiaId): IAction => {
  return {
    type: actionTypes.GET_MEMBERSHIP_MEMBERS_TYPES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/memberships/${membresiaId}/members/types`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getMembershipById = (membresiaId): IAction => {
  return {
    type: actionTypes.GET_MEMBERSHIP_BY_ID,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/memberships/${membresiaId}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const addClubsToProducts = (
  productId: string,
  tipoProducto: string,
  clubs: object[]
): IAction => {
  return {
    type: actionTypes.ADD_CLUBS_PRODUCT,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${baseUrl}/${getBasePath(
          tipoProducto
        )}/${productId}/clubs`,
        clubs,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const updateClubsToProducts = (
  productId: string,
  tipoProducto: string,
  clubs: object[]
): IAction => {
  return {
    type: actionTypes.ADD_CLUBS_PRODUCT,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${baseUrl}/${getBasePath(
          tipoProducto
        )}/${productId}/clubs`,
        clubs,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getMaintenancesPrices = (params: object): IAction => {
  return {
    type: actionTypes.GET_PRICES_MAINTENANCES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/maintenances/prices/`,
        {
          withCredentials: true,
          params
        }
      )
    }
  };
};

export const getPricesToMaintenance = (params: object): IAction => {
  return {
    type: actionTypes.GET_PRICES_MAINTENANCE,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/maintenances/prices`,
        {
          withCredentials: true,
          params
        }
      )
    }
  };
};

export const getPricesToMaintenanceMassive = (params: object): IAction => {
  return {
    type: actionTypes.GET_PRICES_MAINTENANCE,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/maintenances/prices-massive`,
        {
          withCredentials: true,
          params
        }
      )
    }
  };
};

export const addPricesToMaintenance = (
  productId: string,
  tipoProducto: string,
  fechaVigencia: string,
  prices: object[]
): IAction => {
  const body = {
    prices,
    fechaVigencia
  };
  return {
    type: actionTypes.ADD_PRICES_MAINTENANCE,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${baseUrl}/${getBasePath(
          tipoProducto
        )}/${productId}/prices/`,
        body,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getPricesToMemberships = (params): IAction => {
  return {
    type: actionTypes.GET_PRICES_PRODUCT,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/memberships/prices`, {
        withCredentials: true,
        params
      })
    }
  };
};

export const getMassivePricesMemberships = (params): IAction => {
  return {
    type: actionTypes.GET_MASSIVE_PRICES_MEMBERSHIP,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/memberships/prices/massive`,
        {
          withCredentials: true,
          params
        }
      )
    }
  };
};

export const addPricesToMemberships = (
  productId: string,
  tipoProducto: string,
  prices: object
): IAction => {
  return {
    type: actionTypes.ADD_PRICES_PRODUCT,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${baseUrl}/${getBasePath(
          tipoProducto
        )}/${productId}/prices`,
        prices,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const saveConfigurations = (
  productId: string,
  tipoProducto: string,
  conf: object
): IAction => {
  return {
    type: actionTypes.SAVE_PRODUCT_CONFIG,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${baseUrl}/${getBasePath(
          tipoProducto
        )}/${productId}/general-config`,
        conf,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const updateConfigurations = (
  productId: string,
  tipoProducto: string,
  conf: object
): IAction => {
  return {
    type: actionTypes.UPDATE_PRODUCT_CONFIG,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${baseUrl}/${getBasePath(
          tipoProducto
        )}/${productId}/general-config`,
        conf,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const authorizeMembershipPrices = (prices: object[]): IAction => {
  return {
    type: actionTypes.AUTHORIZE_MEMBERSHIP_PRICES,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${baseUrl}/memberships/prices/authorize`,
        prices,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const sendMembersTypes = (
  membresiaId: string,
  membersTypes: object[]
): IAction => {
  return {
    type: actionTypes.MEMBERS_TYPE_MEMBERSHIP,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${baseUrl}/memberships/${membresiaId}/members/types`,
        membersTypes,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const authorizeMaintenancesPrices = (prices: object[]): IAction => {
  return {
    type: actionTypes.AUTHORIZE_MAINTENANCES_PRICES,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${baseUrl}/maintenances/prices/authorize`,
        prices,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const authorizeMaintenancesPricesMasive = (
  prices: object[]
): IAction => {
  return {
    type: actionTypes.AUTHORIZE_MAINTENANCES_PRICES,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${baseUrl}/maintenances/prices/authorize/masive`,
        prices,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const saveBasicMaintenances = (
  tipoProducto: string,
  product: object
): IAction => {
  return {
    type: actionTypes.SAVE_BASIC_PRODUCT,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${baseUrl}/${getBasePath(
          tipoProducto
        )}/basicData`,
        product,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const updateBasicMaintenances = (
  tipoProducto: string,
  productId: string,
  product: object
): IAction => {
  return {
    type: actionTypes.SAVE_BASIC_PRODUCT,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${baseUrl}/${getBasePath(
          tipoProducto
        )}/basicData/${productId}`,
        product,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getBasicData = (tipoProducto: string, id: string): IAction => {
  return {
    type: actionTypes.GET_BASIC_PRODUCT,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/${getBasePath(
          tipoProducto
        )}/basicData/${id}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getClubsProduct = (tipoProducto: string, id: string): IAction => {
  return {
    type: actionTypes.GET_CLUBS_PRODUCT,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/${getBasePath(
          tipoProducto
        )}/${id}/clubs`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getProductConfig = (tipoProducto: string, id: string): IAction => {
  return {
    type: actionTypes.GET_PRODUCT_CONFIG,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/${getBasePath(
          tipoProducto
        )}/${id}/general-config`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getProducts = (
  tipoProducto: string,
  params?: QueryArguments
): IAction => {
  if (tipoProducto === "mantenimientos" && !params["estatus"]) {
    params["estatus"] = "TODOS";
  }
  return {
    type: actionTypes.GET_PRODUCTS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/${getBasePath(tipoProducto)}/${
          tipoProducto === "mantenimientos" ? "basicData" : "all-memberships"
        }`,
        {
          withCredentials: true,
          params: { ...params, ...constants.DEFAULT_SORTING_PARAMS }
        }
      )
    }
  };
};

export const getMembershipsMaintenance = (id: string): IAction => {
  return {
    type: actionTypes.GET_MEMBERSHIP_MAINTENANCE,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/maintenances/${id}/memberships`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getAccountingAccounts = (): IAction => {
  return {
    type: actionTypes.GET_PRODUCT_ACCOUNTING_ACCOUNTS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/catalogs/accounting-accounts`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const maintenancesSchedule = (data: object): IAction => {
  return {
    type: actionTypes.POST_MAINTENANCES_SCHEDULE,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${baseUrl}/maintenances/maintenances-schedule`,
        data,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getMaintenancesSchedule = (id: string): IAction => {
  return {
    type: actionTypes.GET_MAINTENANCES_SCHEDULE,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/maintenances/maintenances-schedule/${id}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const createMaintenanceCharges = (data: object): IAction => {
  return {
    type: actionTypes.CREATE_MAINTENANCE_CHARGES,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${baseUrl}/products/create-maintenance-charges`,
        data,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const createMaintenanceChargesRequest = (data: object) => {
  return axios.post(
    `${constants.BASE_URL}${baseUrl}/products/create-maintenance-charges`,
    data,
    {
      withCredentials: true
    }
  );
};

export const createMaintenanceChargesForAccessExtensionRequest = (
  data: object
) => {
  return axios.post(
    `${constants.BASE_URL}${baseUrl}/products/create-maintenance-charges-for-access-extension`,
    data,
    {
      withCredentials: true
    }
  );
};

export const createAnnuityZumaTiempoAccessExtension = (data: object) => {
  const url = `${constants.BASE_URL}${baseUrl}/products/create-annuity-zuma-tiempo-access-extension`;
  const params = { withCredentials: true };
  return axios.post(url, data, params);
};

export const createMaintenanceDiscount = (data: object): IAction => {
  return {
    type: actionTypes.CREATE_MAINTENANCE_DISCOUNT,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${baseUrl}/products/create-maintenance-discount`,
        data,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const createMaintenanceDiscountRequest = (data: object) => {
  return axios.post(
    `${constants.BASE_URL}${baseUrl}/products/create-maintenance-discount`,
    data,
    {
      withCredentials: true
    }
  );
};

export const rightsChange = (
  idMembresiaSocio: string,
  idPersona: string
): IAction => {
  const data = { idMembresiaSocio, idPersona };
  return {
    type: actionTypes.RIGHTS_CHANGE,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${baseUrl}/memberships/rights-change`,
        data,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const addQuiz = (data: object): IAction => {
  return {
    type: actionTypes.POST_QUIZ_ADD,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${baseUrl}/maintenances/quizzes/add`,
        data,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getQuizzes = (params): IAction => {
  return {
    type: actionTypes.GET_QUIZZES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/maintenances/quizzes`,
        {
          withCredentials: true,
          params
        }
      )
    }
  };
};

export const getQuizzesQuestionsById = (idQuiz: number): IAction => {
  return {
    type: actionTypes.GET_QUIZZES_QUESTIONS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/maintenances/quizzes/${idQuiz}/questions`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getSubCategoriesByCategory = (idCategory: string): IAction => {
  return {
    type: actionTypes.GET_SUBCATEGORIES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/maintenances/subcategories/${idCategory}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getSubCategoriesByCategoryRequest = (idCategory: string) => {
  return axios.get(
    `${constants.BASE_URL}${baseUrl}/maintenances/subcategories/${idCategory}`,
    {
      withCredentials: true
    }
  );
};

export const getMemberByConfiguration = (): IAction => {
  return {
    type: actionTypes.GET_MEMBER_BY_CONFIGURATION,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/maintenances/subcategories/members`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getHistAutCharges = (
  clubId: string,
  membresiaIdPublico: string
): IAction => {
  return {
    type: actionTypes.GET_HISTORY_AUT_CHARGES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/maintenances/cargos-automaticos/historial/${clubId}/${membresiaIdPublico}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getHistAutChargesRequest = (
  clubId: string,
  membresiaIdPublico: string
) => {
  return axios.get(
    `${constants.BASE_URL}${baseUrl}/maintenances/cargos-automaticos/historial/${clubId}/${membresiaIdPublico}`,
    {
      withCredentials: true
    }
  );
};

export const autChargesExecuteProcess = (clubId: string) => {
  return axios.post(
    `${constants.BASE_URL}${baseUrl}/maintenances/cargos-automaticos/execute?clubId=${clubId}`,
    {},
    { withCredentials: true }
  );
};

export const getProductsWithComissionsConfigToAuthorizeByBehaviorType = (
  tipoComportamiento: string
): IAction => {
  return {
    type: actionTypes.GET_PRODUCTS_WITH_COMISSION_CONFIG_TO_AUTH,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}${productsBaseUrl}/tyc/with-commisions-to-authorize`,
        {
          withCredentials: true,
          params: { tipoComportamiento }
        }
      )
    }
  };
};

export const clearProductsWithComissionsConfigToAuthorizeByBehaviorType = () => {
  return {
    type: actionTypes.CLEAR_PRODUCTS_WITH_COMISSION_CONFIG_TO_AUTH
  };
};

export const getProductPositionsGenerateComissions = (
  productId: number,
  comissiontype: string
) => {
  return axios.get(
    `${constants.BASE_URL}${baseUrl}${productsBaseUrl}/tyc/position-that-generate-comissions`,
    {
      withCredentials: true,
      params: { productoId: productId, tipoComision: comissiontype }
    }
  );
};

export const authorizeComissionsConfigByProductsIds = (
  productIds: number[]
) => {
  return axios.put(
    `${constants.BASE_URL}${baseUrl}${productsBaseUrl}/tyc/authorize-commissions-by-product-ids`,
    productIds,
    {
      withCredentials: true
    }
  );
};

export const getQuizzCategoriesRequest = () => {
  return axios.get(
    `${constants.BASE_URL}${baseUrl}/maintenances/quizzes/categories`,
    {
      withCredentials: true
    }
  );
};

export const createQuizzCategoryRequest = (data: object) => {
  return axios.post(
    `${constants.BASE_URL}${baseUrl}/maintenances/quizzes/categories`,
    data,
    {
      withCredentials: true
    }
  );
};

export const updateQuizzCategoryRequest = (data: object) => {
  return axios.put(
    `${constants.BASE_URL}${baseUrl}/maintenances/quizzes/categories`,
    data,
    {
      withCredentials: true
    }
  );
};

export const getQuizzDevicesResumeRequest = () => {
  return axios.get(
    `${constants.BASE_URL}${baseUrl}/maintenances/quizzes/devices/resume`,
    {
      withCredentials: true
    }
  );
};

export const getQuizzDevicesResumeRequestDownload = (filters, _callback) => {
  const url = `${constants.BASE_URL}${baseUrl}/maintenances/quizzes/devices/resume/download`;
  const config = { withCredentials: true, responseType: "blob" };

  return axios.get(url, config).then(({ data }) => {
    if (!!_callback) {
      _callback();
    }

    FileDownload(
      data,
      "ReporteEstatusDispositivosEncuestas" + moment().toISOString() + ".csv"
    );
  });
};

export const getQuizzAppliedResumeRequest = filters => {
  return axios.get(
    `${constants.BASE_URL}${baseUrl}/maintenances/quizzes/applied/resume`,
    {
      withCredentials: true,
      params: filters
    }
  );
};

export const getQuizzAppliedResumeRequestDownload = (
  filters: Object,
  _callback
) => {
  const url = `${constants.BASE_URL}${baseUrl}/maintenances/quizzes/applied/resume/download`;
  const config = {
    withCredentials: true,
    params: filters,
    responseType: "blob"
  };

  return axios.get(url, config).then(({ data }) => {
    if (!!_callback) {
      _callback();
    }

    FileDownload(
      data,
      "ReporteEncuestasAplicadas" + moment().toISOString() + ".csv"
    );
  });
};

export const getAllQuizzesRequest = () => {
  return axios.get(`${constants.BASE_URL}${baseUrl}/maintenances/quizzes`, {
    withCredentials: true
  });
};
