import {
  GetConvenioEmpresasActivas,
  GetConvenioEmpresasConvenio
} from "../../../Home/reducers/convenios";

export interface IEmpresaConvenioSelectorStore
  extends GetConvenioEmpresasConvenio,
    GetConvenioEmpresasActivas {}

export const iEmpresaConvenioSelectorStore: (
  state
) => IEmpresaConvenioSelectorStore = state => {
  const { convenios } = state;
  return {
    empresasConvenio: convenios.empresasConvenio,
    empresasConvenioLoading: convenios.empresasConvenioLoading,
    empresasActivas: convenios.empresasActivas,
    empresasActivasLoading: convenios.empresasActivasLoading
  };
};
