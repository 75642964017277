import React from "react";
import s from "./styles.scss";
import { Preview } from "../../../types/IAction";
import {
  Expediente,
  RequestArchivo,
  Archivo,
  StatusAutorizacion
} from "../expedientsV2controls/types";
import { Styled, ReduxConnect } from "../../../toolkit/decorators/Artifact";
import ArchivoControl from "../expedientsV2controls/archivo/archivo";
import * as actions from "../../actions/expedientes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { formConfig } from "../comisionesDeportivaPendientes/ComisionesDeportivaPendiente.form";
import { catFormConfig } from "./cat.form";
import {
  successNotification,
  errorNotification
} from "../../../utils/notifications";

const mapDispatchToProps = dispatch => ({
  getExpedienteFromMembresia: (idExpediente): Promise<Preview<any>> => {
    return dispatch(actions.getExpedienteFromMembresia(idExpediente));
  },
  postRefresh: (requestArchivo: RequestArchivo): Promise<Preview<any>> => {
    return dispatch(actions.postRefresh(requestArchivo));
  },
  postEnvioCorreo: (idExpediente, idItem): Promise<Preview<any>> => {
    return dispatch(actions.postEnvioCorreo(idExpediente, idItem));
  },
  putCincelFirmas: (idExpediente, idItem): Promise<Preview<any>> => {
    return dispatch(actions.putCincelFirmas(idExpediente, idItem));
  }
});

const mapStateToProps = state => {
  const { expedientes } = state;

  return {
    expedienteMembresia: expedientes.expedienteMembresia,
    expedienteMembresiaLoading: expedientes.expedienteMembresiaLoading,
    archivoRefreshLoading: expedientes.archivoRefreshLoading
  };
};

interface Props {
  // Datos del expediente y sus archivos
  expedienteMembresia?: Expediente;
  expedienteMembresiaLoading?: boolean;
  archivoRefreshLoading?: boolean;

  // Actions del expediente
  getExpedienteFromMembresia?: (idExpediente) => Promise<Preview<any>>;
  postRefresh?: (requestArchivo: RequestArchivo) => Promise<Preview<any>>;
  postEnvioCorreo?: (idExpediente, idItem) => Promise<Preview<any>>;
  putCincelFirmas?: (idExpediente, idItem) => Promise<Preview<any>>;

  idMembresiaSocio: any;

  // Indica si la membresía fue traspasada
  descripcionTraspaso: any;
  membershipBlocked: boolean;
}

interface State {
  waiting: boolean;
  archivos: Archivo[][];
}

/**
 * Tabla del módulo de expedientes
 */
@ReduxConnect(mapStateToProps, mapDispatchToProps)
@Styled(s)
export default class ExpedienteTabla extends React.Component<Props, State> {
  state = {
    waiting: false,
    archivos: []
  };

  componentDidMount(): void {
    if (this.props.idMembresiaSocio) {
      this.props.getExpedienteFromMembresia(this.props.idMembresiaSocio);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.idMembresiaSocio != prevProps.idMembresiaSocio) {
      this.props.getExpedienteFromMembresia(this.props.idMembresiaSocio);
    }

    // Se activa la animación de carga, cuando se está actualizando el expediente
    if (
      this.props.expedienteMembresiaLoading !=
        prevProps.expedienteMembresiaLoading ||
      this.props.archivoRefreshLoading != prevProps.archivoRefreshLoading
    ) {
      this.setState({
        waiting:
          this.props.expedienteMembresiaLoading ||
          this.props.archivoRefreshLoading
      });
    }

    // Si se actualiza el expediente correctamente, se ordenan los archivos
    if (
      this.props.expedienteMembresia != prevProps.expedienteMembresia &&
      this.props.expedienteMembresia.vigentes
    ) {
      this.setState({
        archivos: this.ordenarArchivos()
      });
    }
  }

  sendEmail = async (archivo: Archivo) => {
    const preview = await this.props.postEnvioCorreo(
      archivo.idExpediente,
      archivo.idItem
    );
    return preview;
  };

  regenerar = async (archivo: Archivo, values?: any) => {
    const requestArchivo: RequestArchivo = {
      codigo: archivo.codigo,
      idMembresiaSocio: this.props.idMembresiaSocio,
      idPersonaExpediente: null,
      idMembresiaSocioPersona: archivo.idMembresiaSocioPersona,
      idPersonaDocumento: archivo.idPersona,
      idMedioPago: archivo.idMedioPago,
      idCotizacionItem: archivo.idCotizacionItem,
      idPersonaEvaluacion: archivo.idPersonaEvaluacion,
      values
    };

    const preview = await this.props.postRefresh(requestArchivo);

    if (preview.fulfilled) {
      await this.props.getExpedienteFromMembresia(this.props.idMembresiaSocio);
    }

    return preview;
  };

  update = async () => {
    return await this.props.getExpedienteFromMembresia(
      this.props.idMembresiaSocio
    );
  };

  cincelFirma = async (archivo: Archivo) => {
    if (archivo.statusAutorizacion == StatusAutorizacion.NO_GENERADO) {
      errorNotification(
        "Debe generar el documento antes de verificar las firmas"
      );

      return {
        fulfilled: false
      };
    }

    const preview = await this.props.putCincelFirmas(
      archivo.idExpediente,
      archivo.idItem
    );

    if (preview.fulfilled) {
      successNotification("El documento firmado se obtuvo correctamente");
      await this.props.getExpedienteFromMembresia(this.props.idMembresiaSocio);
    }

    return preview;
  };

  ordenarArchivos() {
    const hash: any = {};
    const ordenados = [];

    // Se separan los expedientes por usuario
    for (const archivo of this.props.expedienteMembresia.vigentes) {
      if (hash[archivo.idOwner]) {
        hash[archivo.idOwner].push(archivo);
      } else {
        hash[archivo.idOwner] = [archivo];
      }
    }

    // Se concentran los expedientes por usuario en una lista
    for (const archivosKey in hash) {
      ordenados.push(hash[archivosKey]);
    }

    return ordenados;
  }

  isMembershipBlocked = () => {
    return this.props.membershipBlocked || false;
  };

  render() {
    // Si no se han cargado o actualizado los documentos, se muestra la animación
    const className =
      this.state.waiting || !this.props.expedienteMembresia.vigentes
        ? `${s.expedient} ${s.waiting}`
        : s.expedient;

    return (
      <div className={className}>
        <div className={s.spinner}>
          <FontAwesomeIcon icon={faSpinner} pulse />
        </div>

        {this.state.archivos.map(this.renderTable)}
      </div>
    );
  }

  renderTable = (archivos: Archivo[]) => {
    const archivosPorMostrar: Archivo[] = this.isMembershipBlocked()
      ? (archivos || []).filter(archivo => archivo.completo)
      : archivos;

    if (!archivosPorMostrar.length) {
      return <></>;
    }

    const nombre = `${archivos[0].nombreCompleto} (${archivos[0].idOwner})`;
    return (
      <>
        <div className={s.ownerName}>{nombre}</div>
        {archivosPorMostrar.map(archivo => {
          const codigo = archivo.codigo;
          let formConfig = null;

          // Casos especiales
          if (codigo == "ALTA_CAT" || codigo == "CINCEL_ALTA_CAT") {
            formConfig = catFormConfig;
          }

          return (
            <ArchivoControl
              key={"archivo-item-" + archivo.idItem}
              archivo={archivo}
              userId={0}
              sendEmail={this.sendEmail}
              regenerar={this.regenerar}
              formConfigRegenerar={formConfig}
              update={this.update}
              botonCorreo={!this.isMembershipBlocked()}
              botonFirmaOEscanner={!this.isMembershipBlocked()}
              botonRegenrar={!this.isMembershipBlocked()}
              cincelFirma={this.cincelFirma}
            />
          );
        })}
      </>
    );
  };
}
