import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";
const BASE_URL = "/api/surveys";

export const getSurveys = (params: object): IAction => {
  return {
    type: actionTypes.GET_SURVEYS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${BASE_URL}`, {
        withCredentials: true,
        params
      })
    }
  };
};

export const getSurveysRequest = (params?: object) => {
  return axios.get(`${constants.BASE_URL}${BASE_URL}`, {
    withCredentials: true,
    params
  });
};

export const saveAnswersRequest = (body: any): Promise<any> => {
  return axios.post(`${constants.BASE_URL}${BASE_URL}`, body, {
    withCredentials: true
  });
};

export const saveAnswers = (body: object): IAction => {
  return {
    type: actionTypes.SAVE_SURVEYS,
    payload: {
      promise: axios.post(`${constants.BASE_URL}${BASE_URL}`, body, {
        withCredentials: true
      })
    }
  };
};
