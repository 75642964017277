import React from "react";
import BaseInput from "../../../../toolkit/baseInput";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Container, Row, Col, Button, Form } from "reactstrap";
import ReactTable from "react-table";
import s from "./styles.scss";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import { withRouter } from "react-router-dom";
import { faTags } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalFrame from "../../../../toolkit/modalFrame";
import { BFF_URL, IMAGES_ENDPOINT } from "../../../../utils/constants";

interface Props {
  getKits: (data: object, clubId: string) => any;
  getResponsibles: (menorId: string) => any;
  assignTag: (data: object) => any;
  deallocationTag: (data: object) => any;
  listFitkits: object[];
  listResponsibleFitkits: object[];
  responseAssignTag: object[];
  responseDellocationTag: object[];
  clubs: {
    clubId: string;
    nombre: string;
  }[];
  userContext: any;
}

interface State {
  selected: any;
  responsableSelected: any;
  isOpen: boolean;
  tagAsignacion: boolean;
  fields: object;
  errorMessageModal: string;
  errorPrincipal: string;
  responsiblesList: object[];
  asignado: boolean;
  desasignado: boolean;
}

class SearchKidz extends React.Component<Props, State> {
  state = {
    selected: null,
    responsableSelected: null,
    isOpen: false,
    errorMessageModal: "",
    errorPrincipal: "",
    tagAsignacion: false,
    fields: {
      nombrePersona: "",
      membresiaId: "",
      clubId: "",
      tag: ""
    },
    asignado: false,
    desasignado: false,
    responsiblesList: null
  };

  columnsResponsibles = [
    {
      Header: () => <p className="font-weight-bold">Id</p>,
      id: 0,
      accessor: "id",
      Cell: row => {
        return <p className={s.initial}>{row.original.id}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Nombre del menor</p>,
      id: 0,
      accessor: "nombrePersonaMenor",
      Cell: row => {
        return <p className={s.initial}>{row.original.nombrePersonaMenor}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Nombre del responsable</p>,
      id: 0,
      accessor: "nombreResponsable",
      Cell: row => {
        return <p className={s.initial}>{row.original.nombreResponsable}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Parentesco</p>,
      id: 0,
      accessor: "parentesco",
      Cell: row => {
        return <p className={s.initial}>{row.original.parentesco}</p>;
      }
    }
  ];

  columns = [
    {
      Header: () => <p className="font-weight-bold">Foto</p>,
      id: 0,
      accessor: "clubNombre",
      Cell: row => {
        return (
          <p className={s.initial}>
            {row.original.personaFotoStorageId ? (
              <img
                src={`${BFF_URL}${IMAGES_ENDPOINT}/${row.original.personaFotoStorageId}`}
                width="80"
                height="80"
              />
            ) : null}
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Club</p>,
      id: 0,
      accessor: "clubNombre",
      Cell: row => {
        return <p className={s.initial}>{row.original.clubNombre}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Nombre del menor</p>,
      id: 0,
      accessor: "personaNombre",
      Cell: row => {
        const idPersona = row.original.personaId;
        return (
          <p className={s.initial}>
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                window.open(`/personas/${idPersona}/ver`, "_blank");
              }}
            >
              {row.original.personaNombre}
            </a>
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Id menor</p>,
      id: 0,
      accessor: "personaId",
      Cell: row => {
        return <p className={s.initial}>{row.original.personaId}</p>;
      }
    },
    {
      Header: () => {
        return <p className="font-weight-bold">Opciones</p>;
      },
      id: -300,
      width: 80,
      accessor: "actions",
      Cell: row => {
        return (
          <Container className="p-0">
            <Row className="m-0">
              <Col className="px-1">
                <a
                  id={`kid${row.original.personaId}`}
                  onClick={() => {
                    this.getRisponsiblesList(
                      row.original.personaId,
                      row.original.tagAsignacion
                    );
                  }}
                  style={{
                    color: row.original.tagAsignacion ? "red" : "green"
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      cursor: "pointer"
                    }}
                    icon={faTags}
                  />
                </a>
              </Col>
            </Row>
          </Container>
        );
      }
    }
  ];

  componentWillReceiveProps(nextProps) {
    if (nextProps.listResponsibleFitkits !== null) {
      this.setState({
        responsiblesList: nextProps.listResponsibleFitkits
      });
    }
    if (nextProps.responseAssignTag !== null) {
      if (this.props.responseAssignTag === nextProps.responseAssignTag) {
        this.setState({
          asignado: false
        });
      } else {
        this.setState({
          asignado: true
        });
      }
    }
    if (nextProps.responseDellocationTag !== null) {
      if (
        this.props.responseDellocationTag !== nextProps.responseDellocationTag
      ) {
        this.setState({
          desasignado: true
        });
      } else {
        this.setState({
          desasignado: false
        });
      }
    }
  }

  getRisponsiblesList = (menorId, tagAsignacion) => {
    this.setState({ tagAsignacion });
    this.props.getResponsibles(menorId);
    this.toggleModalResponsibles();
  };

  toggleModalResponsibles = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      errorMessageModal: ""
    });
    const { nombrePersona, membresiaId, clubId } = this.state.fields;
    if (this.state.isOpen) {
      this.props.getKits(
        {
          nombrePersona,
          membresiaId
        },
        clubId
      );
    }
  };

  onChangeFields = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };

        return { ...state, error: {}, fields };
      });
    };
  };

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...this.state.fields,
          [fieldName]: value
        };
        return { ...state, fields };
      });
    };
  };

  search = e => {
    e.preventDefault();
    const { nombrePersona, membresiaId, clubId } = this.state.fields;
    if (nombrePersona !== "" || membresiaId !== "") {
      this.props.getKits(
        {
          nombrePersona,
          membresiaId
        },
        clubId
      );
      this.setState({
        errorPrincipal: ""
      });
    } else {
      this.setState({
        errorPrincipal: "Debe ingresar al menos nombre y/o membresía"
      });
    }
  };

  asignarResponsable = e => {
    e.preventDefault();
    if (this.state.responsableSelected !== null) {
      this.props.assignTag({
        clubId: localStorage.getItem("club"),
        personaMenorId: this.state.responsableSelected.idPersonaMenor,
        responsableId: this.state.responsableSelected.idResponsable,
        tagCodigo: this.state.fields.tag
      });
      this.setState({
        errorMessageModal: ""
      });
    } else {
      this.setState({
        errorMessageModal: "Debe seleccionar un responsable"
      });
    }
  };

  retirar = e => {
    e.preventDefault();
    if (this.state.responsableSelected !== null) {
      this.props.deallocationTag({
        clubId: localStorage.getItem("club"),
        personaMenorId: this.state.responsableSelected.idPersonaMenor,
        responsableId: this.state.responsableSelected.idResponsable
      });
    } else {
      this.setState({
        errorMessageModal: "Debe seleccionar un responsable"
      });
    }
  };

  componentDidMount = () => {
    this.setState({
      fields: {
        ...this.state.fields,
        clubId:
          (this.props.userContext && this.props.userContext.clubSessionId) ||
          null
      }
    });
  };

  render() {
    return (
      <Container className="p-0">
        <ModalFrame
          title={`Asociación TAG`}
          isOpen={this.state.isOpen}
          toggle={this.toggleModalResponsibles}
          size="lg"
        >
          <Container>
            <Row className={`${s.customDistancia}`}>
              <Col xs={12} md={12} lg={12}>
                Seleccione un responsable para el menor:
              </Col>
              <Col>
                <ReactTable
                  getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                      return {
                        onClick: e => {
                          this.setState({
                            selected: rowInfo.index,
                            responsableSelected: rowInfo.original
                          });
                        },
                        style: {
                          background:
                            rowInfo.original.estatus === "INACTIVO"
                              ? "#fc050a"
                              : rowInfo.index === this.state.selected
                              ? "#fc050a"
                              : "",
                          color:
                            rowInfo.original.estatus === "INACTIVO"
                              ? "white"
                              : rowInfo.index === this.state.selected
                              ? "white"
                              : ""
                        }
                      };
                    } else {
                      return {};
                    }
                  }}
                  data={this.state.responsiblesList || []}
                  columns={this.columnsResponsibles}
                  className="mt-2 -striped -highlight"
                  sortable={false}
                  resizable={false}
                  showPagination={false}
                  pageSize={(this.state.responsiblesList || []).length || 3}
                />
              </Col>
            </Row>

            {this.state.errorMessageModal !== "" ? (
              <Row className={`${s.customDistancia}`}>
                <Col xs={12} md={12} lg={12} className={s.error}>
                  {this.state.errorMessageModal}
                </Col>
              </Row>
            ) : (
              ""
            )}

            {!this.state.tagAsignacion ? (
              <Row className={`${s.customDistancia}`}>
                <Col xs={12} md={3} lg={3}>
                  <BaseInput
                    label={"TAG"}
                    name="tag"
                    type="text"
                    id="tag"
                    value={this.state.fields.tag}
                    onChange={this.onChangeFields("tag")}
                  />
                </Col>
                {!this.state.asignado ? (
                  <Col xs={12} md={2} lg={2} className="mt-4">
                    <Button
                      block
                      color="secondary"
                      onClick={this.asignarResponsable}
                    >
                      Asociar TAG
                    </Button>
                  </Col>
                ) : (
                  <Col xs={12} md={6} lg={6} className="mt-4">
                    Se ha Asociado un tag al menor
                  </Col>
                )}
              </Row>
            ) : !this.state.desasignado ? (
              <Row className={`${s.customDistancia}`}>
                <Col xs={12} md={12} lg={12}>
                  <Button block color="secondary" onClick={this.retirar}>
                    Retirar
                  </Button>
                </Col>
              </Row>
            ) : (
              <Row className={`${s.customDistancia}`}>
                <Col xs={12} md={12} lg={12}>
                  Se ha retirado el menor
                </Col>
              </Row>
            )}

            <div className={`text-right mt-3 ${s.customDistancia}`}>
              <Button color="secondary" onClick={this.toggleModalResponsibles}>
                Cerrar
              </Button>
            </div>
          </Container>
        </ModalFrame>

        <Form
          onSubmit={e => {
            e.preventDefault();
            this.search(e);
          }}
        >
          <Row className="mt-3">
            <Col xs={12} md={3} lg={3}>
              <BaseInput
                label="Club"
                name="club"
                id="club"
                type="select"
                placeholder="Seleccionar club"
                value={this.state.fields.clubId}
                onChange={this.onChangeFilter("clubId")}
                size={"sm"}
                options={[{ value: "", label: "Seleccionar club" }].concat(
                  this.props.clubs
                    ? this.props.clubs.map(option => ({
                        value: option.clubId,
                        label: option.nombre
                      }))
                    : []
                )}
                disabled={
                  this.props.userContext && this.props.userContext.clubSessionId
                }
              />
            </Col>
            <Col xs={12} md={4} lg={4}>
              <BaseInput
                label={"Nombre"}
                name="nombrePersona"
                type="text"
                id="nombrePersona"
                value={this.state.fields.nombrePersona}
                onChange={this.onChangeFields("nombrePersona")}
              />
            </Col>

            <Col xs={12} md={3} lg={3}>
              <BaseInput
                label={"Id membresía"}
                name=""
                type="text"
                id="membresiaId"
                value={this.state.fields.membresiaId}
                onChange={this.onChangeFields("membresiaId")}
              />
            </Col>
            <Col xs={12} md={2} lg={2} className="mt-4">
              <Button
                size="sm"
                className={`float-right btn-block`}
                type="submit"
                disabled={this.state.fields.clubId === ""}
              >
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
        {this.state.errorPrincipal !== "" ? (
          <Row className={`${s.customDistancia}`}>
            <Col xs={12} md={12} lg={12} className={s.error}>
              {this.state.errorPrincipal}
            </Col>
          </Row>
        ) : (
          ""
        )}
        <ReactTable
          data={this.props.listFitkits ? this.props.listFitkits : []}
          columns={this.columns}
          className="mt-5 -striped -highlight"
          sortable={false}
          resizable={false}
          showPagination={false}
          pageSize={this.props.listFitkits ? this.props.listFitkits.length : 5}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(SearchKidz));
