import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Row, Col } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import { getLocationByPostalCode } from "../../helpers/LocationByPostalCode";
import { INTERFACE_DOMICILIO_FISCAL } from "./interfaces";

interface Props {
  onChangeAddress: (
    address: INTERFACE_DOMICILIO_FISCAL,
    validated: boolean
  ) => any;
  disabled: boolean;
  billingData: {
    billingCatalogs: any[];
    dataResponse: object;
    gettingCatalogs: boolean;
    sendingData: boolean;
    successSend: boolean;
  };
  address: {
    calle: string;
    noExterior: string;
    noInterior: string;
    colonia: string;
    codigoPostal: string;
    localidad: string;
    municipio: string;
    estado: string;
    pais: string;
    codigoPaisERP: string;
  };
}

interface State {}

class AddressData extends React.Component<Props, State> {
  state = {
    coloniasOptions: [{ value: "", label: "Seleccionar la Colonia" }],
    municipiosOptions: [{ value: "", label: "Seleccionar el Municipio" }],
    estadosOptions: [{ value: "", label: "Seleccionar el Estado" }]
  };

  addressDataUpdate = async address => {
    const coloniasNombre = {};
    const municipiosNombre = {};
    const estadosNombre = {};

    const colonias = [
      {
        value: "",
        label:
          address.length > 0
            ? "Seleccionar la Colonia"
            : "Verifica el Código Postal"
      }
    ];
    const municipios = [
      {
        value: "",
        label:
          address.length > 0
            ? "Seleccionar el Municipio"
            : "Verifica el Código Postal"
      }
    ];
    const estados = [
      {
        value: "",
        label:
          address.length > 0
            ? "Seleccionar el Estado"
            : "Verifica el Código Postal"
      }
    ];

    address.forEach(direccion => {
      const { nombre, municipio, estado } = direccion;
      if (!coloniasNombre[nombre]) {
        colonias.push({
          value: nombre,
          label: nombre
        });
        coloniasNombre[nombre] = true;
      }
      if (!municipiosNombre[municipio]) {
        municipios.push({
          value: municipio,
          label: municipio
        });
        municipiosNombre[municipio] = true;
      }
      if (!estadosNombre[estado]) {
        estados.push({
          value: estado,
          label: estado
        });
        estadosNombre[estado] = true;
      }
    });
    let cOptions = this.state.coloniasOptions;
    let mOptions = this.state.municipiosOptions;
    let eOptions = this.state.estadosOptions;
    cOptions = colonias;
    mOptions = municipios;
    eOptions = estados;

    this.setState(state => {
      return {
        ...state,
        coloniasOptions: cOptions,
        municipiosOptions: mOptions,
        estadosOptions: eOptions,
        tempAddress: address
      };
    });
  };

  handleChangeCodigoPostal = async () => {
    const value = this.props.address.codigoPostal;
    if (value.length == 5) {
      getLocationByPostalCode(value).then(res => this.addressDataUpdate(res));
    }
  };

  validateFields = () => {
    const { address } = this.props;
    for (const key in address) {
      if (key == "noInterior") continue;
      if (address.hasOwnProperty(key)) {
        const element = address[key];
        if (element == "") return false;
      }
    }
    return true;
  };

  onChangeField = async (e, fieldName) => {
    const { address } = this.props;
    address[fieldName] = e.target.value;

    if (fieldName === "codigoPostal") {
      this.handleChangeCodigoPostal();
    }
    if (this.validateFields()) {
      this.props.onChangeAddress(address, true);
    } else {
      this.props.onChangeAddress(address, false);
    }
  };

  render() {
    let CatCodigoPostal = [];

    for (let catalog of this.props.billingData.billingCatalogs) {
      if (catalog["clave"] == "CatCodigoPostal")
        CatCodigoPostal = catalog["data"];
    }
    return (
      <Row>
        <Col xs={12} md={6} lg={4}>
          <BaseInput
            label={"Calle"}
            name={`calle`}
            type="text"
            id={`calle`}
            disabled={this.props.disabled}
            placeholder={"Calle"}
            value={this.props.address.calle}
            onChange={e => this.onChangeField(e, "calle")}
          />
        </Col>
        <Col xs={2}>
          <BaseInput
            label={"Número exterior"}
            name={`noExterior`}
            type="text"
            disabled={this.props.disabled}
            id={`noExterior`}
            placeholder={"Número"}
            onChange={e => this.onChangeField(e, "noExterior")}
          />
        </Col>

        <Col xs={2}>
          <BaseInput
            label={"Número interior"}
            name={`noInterior`}
            type="text"
            disabled={this.props.disabled}
            id={`noInterior`}
            placeholder={"Número"}
            onChange={e => this.onChangeField(e, "noInterior")}
          />
        </Col>
        <Col xs={12} md={6} lg={4}>
          <BaseInput
            label={"Código Postal"}
            name={`codigoPostal`}
            type="select"
            id={`codigoPostal`}
            placeholder={"Código Postal"}
            disabled={this.props.disabled}
            options={[
              { value: -1, label: "Seleccionar el codigo postal" }
            ].concat(
              CatCodigoPostal.map(({ clave }) => {
                return {
                  value: clave,
                  label: clave
                };
              })
            )}
            value={this.props.address.codigoPostal}
            onChange={e => this.onChangeField(e, "codigoPostal")}
          />
        </Col>
        <Col xs={12} md={6} lg={4}>
          <BaseInput
            label={"Colonia"}
            name={`colonia`}
            disabled={this.props.disabled}
            type="select"
            options={this.state.coloniasOptions}
            id={`colonia`}
            placeholder={"Colonia"}
            disabled={this.state.coloniasOptions.length > 1 ? false : true}
            value={this.props.address.colonia}
            onChange={e => this.onChangeField(e, "colonia")}
          />
        </Col>
        <Col xs={12} md={6} lg={4}>
          <BaseInput
            label={"Municipio"}
            name={`municipio`}
            type="select"
            options={this.state.municipiosOptions}
            id={`municipio`}
            disabled={this.props.disabled}
            placeholder={"Municipio"}
            onChange={e => this.onChangeField(e, "municipio")}
            value={this.props.address.municipio}
            disabled={this.state.municipiosOptions.length > 1 ? false : true}
          />
        </Col>
        <Col xs={12} md={6} lg={4}>
          <BaseInput
            label={"Estado"}
            name={`estado`}
            type="select"
            options={this.state.estadosOptions}
            id={`estado`}
            disabled={this.props.disabled}
            placeholder={"Estado"}
            onChange={e => this.onChangeField(e, "estado")}
            value={this.props.address.estado}
            disabled={this.state.estadosOptions.length > 1 ? false : true}
          />
        </Col>
      </Row>
    );
  }
}

export default AddressData;
