import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import BlackpassPage from "../../components/blackpass";

interface Props {}
interface State {}

class Blackpass extends React.Component<Props, State> {
  render() {
    return (
      <main>
        <Helmet title="Integrantes por Membresía" />
        <Layout>
          <BlackpassPage />
        </Layout>
      </main>
    );
  }
}

export default Blackpass;
