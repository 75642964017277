import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Container } from "reactstrap";
import Tree, { TreeNode } from "rc-tree";
import differenceBy from "lodash.differenceby";
import unionBy from "lodash.unionby";
import uniq from "lodash.uniq";
import pull from "lodash.pull";
import every from "lodash.every";
import find from "lodash.find";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";
import { setChildStep } from "../../helpers/stepper-state-comparator";

const FONT_SIZE_TREE = "0.75rem";
const INACTIVE_COLOR = "gray";
const ACTIVE_COLOR = "black";

interface PERMISSION_TREE {
  permissionId: string;
  area: string;
  description: string;
  childPermissions: PERMISSION_TREE[];
  padre: PERMISSION_TREE;
  active: boolean;
}

interface Props {
  permissionsTree: PERMISSION_TREE;
  permissions: number[];
  onClickSave: (e: object) => any;
  updatePermits: (personaId: string, permissions: number[]) => any;
  match: any;
}

interface State {
  checkedKeys: { checked: string[]; halfChecked: string[] };
}

class UserPermits extends React.Component<Props, State> {
  state = {
    checkedKeys: { checked: [], halfChecked: [] }
  };

  componentDidMount() {
    this.props.onClickSave(e => {
      e.preventDefault();
      this.props.updatePermits(
        this.props.match.params.personaId,
        this.state.checkedKeys.checked
      );
    });

    const checkedKeys = {
      checked: this.props.permissions.map(e => String(e)),
      halfChecked: []
    };

    setChildStep(checkedKeys);

    if (this.props.permissions) {
      this.setState(prevState => ({
        ...prevState,
        checkedKeys
      }));
    }
  }

  componentDidUpdate() {}

  getPathToRoot = original => {
    const path = [];
    let padre = original.padre;

    while (padre && padre.padre) {
      path.push(padre.permissionId);
      padre = padre.padre;
    }

    return path;
  };

  onCheck = (newCheckedKeys, info) => {
    if (this.checkedAll) {
      this.checkedAll = false;
      return;
    }

    const {
      node: {
        props: { original }
      }
    } = info;
    const checkedNodes = info.checkedNodes.map(({ key }) => key);

    let pathToRoot = [];
    const deselect = [];
    const deselectChild = node => {
      deselect.push(node.permissionId);
      if (node.childPermissions) {
        node.childPermissions.forEach(child => {
          deselectChild(child);
        });
      }
    };

    const deselectParent = node => {
      let parent = node.padre;

      if (parent) {
        const children = parent.childPermissions;

        if (
          every(
            children,
            child => !find(checkedNodes, key => key === child.permissionId)
          )
        ) {
          const { permissionId: parentId } = parent;
          pull(checkedNodes, parentId);
          deselect.push(parentId);
          deselectParent(parent);
        }
      }
    };

    if (!info.checked) {
      deselectChild(original);
      deselectParent(original);
    } else {
      pathToRoot = this.getPathToRoot(original);
    }

    const checked = uniq(
      differenceBy(unionBy(newCheckedKeys.checked, pathToRoot), deselect)
    );

    const checkedKeys = { ...newCheckedKeys, checked };

    setChildStep(checkedKeys);

    this.setState({ checkedKeys });
  };

  checkedAll = false;

  checkAllChildren = parentNode => {
    this.checkedAll = true;

    const select = [];

    const selectChildren = node => {
      select.push(node.permissionId);
      if (node.childPermissions) {
        node.childPermissions.forEach(child => {
          selectChildren(child);
        });
      }
    };

    selectChildren(parentNode);
    const pathToRoot = this.getPathToRoot(parentNode);

    const checked = uniq(
      unionBy(this.state.checkedKeys.checked, pathToRoot, select)
    );

    const checkedKeys = { ...this.state.checkedKeys, checked };
    setChildStep(checkedKeys);
    this.setState({ checkedKeys });
  };

  getTreeLabel = node => (
    <span
      className="rc-tree-title"
      style={{
        fontSize: FONT_SIZE_TREE,
        color: !node.active ? INACTIVE_COLOR : ACTIVE_COLOR
      }}
    >
      {node.description}
      <a
        onClick={() => this.checkAllChildren(node)}
        className={`${s.checkAllChildrenAnchor}`}
        style={{ marginLeft: "1rem" }}
      >
        {node.childPermissions.length ? (
          <FontAwesomeIcon style={{ color: "green" }} icon={faCheckDouble} />
        ) : null}
      </a>
    </span>
  );

  getTreeNodes = (node: PERMISSION_TREE) => (
    <TreeNode
      original={node}
      title={this.getTreeLabel(node)}
      key={node.permissionId}
      leaf={!node.childPermissions}
      disableCheckbox={node.permissionId === "FAKE_ROOT_PERMISSION"}
      selectable={false}
    >
      {node.childPermissions
        ? node.childPermissions.map(this.getTreeNodes)
        : null}
    </TreeNode>
  );

  getTree = () => {
    return (
      <Tree
        className="myCls"
        showLine
        checkable
        defaultExpandAll
        showIcon={false}
        onCheck={this.onCheck}
        checkStrictly
        checkedKeys={this.state.checkedKeys}
        selectable={false}
      >
        {this.getTreeNodes(this.props.permissionsTree)}
      </Tree>
    );
  };

  render() {
    return (
      <Container className="w-100 p-0 mt-3">
        <div>
          <p className={`${s.primaryTitle}`}>Permisos</p>
          <div className="form-row">{this.getTree()}</div>
        </div>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(UserPermits));
