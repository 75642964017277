import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import { getErrorMessage } from "../../utils/helpers";
import { ERROR, errorNotification } from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";
import EmpresaConvenio from "../../types/EmpresaConvenio";
import EmpresaConvenioInvitado from "../../types/EmpresaConvenioInvitado";
import { QueryResponse } from "../../types/QueryResponse";

export interface GetEmpresasConvenio {
  empresasConvenio: QueryResponse<EmpresaConvenio>;
  gettingEmpresasConvenio: boolean;
  gettingEmpresasConvenioError: string;
}
export interface GetEmpresaConvenio {
  empresaConvenio: EmpresaConvenio;
  gettingEmpresaConvenio: boolean;
  gettingEmpresaConvenioError: string;
}
export interface CreateEmpresaConvenio {
  empresaConvenioCreated: EmpresaConvenio;
  creatingEmpresaConvenio: boolean;
  creatingEmpresaConvenioError: string;
}
export interface UpdateEmpresaConvenio {
  empresaConvenioUpdated: EmpresaConvenio;
  updatingEmpresaConvenio: boolean;
  updatingEmpresaConvenioError: string;
}
export interface GetEmpresaConvenioInvitado {
  empresaConvenioInvitado: EmpresaConvenioInvitado;
  gettingEmpresaConvenioInvitado: boolean;
  gettingEmpresaConvenioInvitadoError: string;
}
export interface UpdateEmpresaConvenioInvitado {
  empresaConvenioInvitadoUpdated: EmpresaConvenioInvitado;
  updatingEmpresaConvenioInvitado: boolean;
  updatingEmpresaConvenioInvitadoError: string;
}
export interface DeleteEmpresaConvenio {
  deletingEmpresaConvenio: boolean;
  deletingEmpresaConvenioError: string;
}

interface State
  extends GetEmpresasConvenio,
    GetEmpresaConvenio,
    CreateEmpresaConvenio,
    UpdateEmpresaConvenio,
    UpdateEmpresaConvenioInvitado,
    DeleteEmpresaConvenio,
    GetEmpresaConvenioInvitado {}

const initialState: State = {
  empresasConvenio: null,
  gettingEmpresasConvenio: false,
  gettingEmpresasConvenioError: null,

  empresaConvenio: null,
  gettingEmpresaConvenio: false,
  gettingEmpresaConvenioError: null,

  empresaConvenioCreated: null,
  creatingEmpresaConvenio: false,
  creatingEmpresaConvenioError: null,

  empresaConvenioUpdated: null,
  updatingEmpresaConvenio: false,
  updatingEmpresaConvenioError: null,

  empresaConvenioInvitado: null,
  gettingEmpresaConvenioInvitado: false,
  gettingEmpresaConvenioInvitadoError: null,

  empresaConvenioInvitadoUpdated: null,
  updatingEmpresaConvenioInvitado: false,
  updatingEmpresaConvenioInvitadoError: null,

  deletingEmpresaConvenio: false,
  deletingEmpresaConvenioError: null
};

export default function empresasConvenioReducer(
  state: State = initialState,
  action: IAction
): State {
  const snapshot = snapshotAction(state, action);

  // Llamada a resolver de snapshot
  state != snapshot &&
    !action.type.endsWith("_PENDING") &&
    action.meta &&
    action.meta.resolver &&
    action.meta.resolver({
      fulfilled: action.type.endsWith("_FULFILLED"),
      ...snapshot
    });

  return state != snapshot
    ? {
        ...state,
        ...snapshot
      }
    : state;
}

function snapshotAction(
  state: State = initialState,
  action: IAction
): Partial<State> {
  switch (action.type) {
    //GET ALL EMPRESAS_CONVENIO
    case `${actionTypes.GET_EMPRESAS_CONVENIO}_PENDING`: {
      return {
        gettingEmpresasConvenio: true,
        gettingEmpresasConvenioError: null
      };
    }
    case `${actionTypes.GET_EMPRESAS_CONVENIO}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        empresasConvenio: null,
        gettingEmpresasConvenio: false,
        gettingEmpresasConvenioError: getErrorMessage(action.payload)
      };
    }
    case `${actionTypes.GET_EMPRESAS_CONVENIO}_FULFILLED`: {
      return {
        empresasConvenio: action.payload.data,
        gettingEmpresasConvenio: false,
        gettingEmpresasConvenioError: null
      };
    }
    //GET EMPRESA_CONVENIO
    case `${actionTypes.GET_EMPRESA_CONVENIO}_PENDING`: {
      return {
        gettingEmpresaConvenio: true,
        gettingEmpresaConvenioError: null
      };
    }
    case `${actionTypes.GET_EMPRESA_CONVENIO}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        empresaConvenio: null,
        gettingEmpresaConvenio: false,
        gettingEmpresaConvenioError: getErrorMessage(action.payload)
      };
    }
    case `${actionTypes.GET_EMPRESA_CONVENIO}_FULFILLED`: {
      return {
        empresaConvenio: action.payload.data,
        gettingEmpresaConvenio: false,
        gettingEmpresaConvenioError: null
      };
    }
    //CREATE EMPRESA_CONVENIO
    case `${actionTypes.CREATE_EMPRESA_CONVENIO}_PENDING`: {
      return {
        creatingEmpresaConvenio: true,
        creatingEmpresaConvenioError: null
      };
    }
    case `${actionTypes.CREATE_EMPRESA_CONVENIO}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        empresaConvenioCreated: null,
        creatingEmpresaConvenio: false,
        creatingEmpresaConvenioError: getErrorMessage(action.payload)
      };
    }
    case `${actionTypes.CREATE_EMPRESA_CONVENIO}_FULFILLED`: {
      return {
        empresaConvenioCreated: action.payload.data,
        creatingEmpresaConvenio: false,
        creatingEmpresaConvenioError: null
      };
    }
    //UPDATE EMPRESA_CONVENIO
    case `${actionTypes.UPDATE_EMPRESA_CONVENIO}_PENDING`: {
      return {
        updatingEmpresaConvenio: true,
        updatingEmpresaConvenioError: null
      };
    }
    case `${actionTypes.UPDATE_EMPRESA_CONVENIO}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        empresaConvenioUpdated: null,
        updatingEmpresaConvenio: false,
        updatingEmpresaConvenioError: getErrorMessage(action.payload)
      };
    }
    case `${actionTypes.UPDATE_EMPRESA_CONVENIO}_FULFILLED`: {
      return {
        empresaConvenioUpdated: action.payload.data,
        updatingEmpresaConvenio: false,
        updatingEmpresaConvenioError: null
      };
    }
    //GET EMPRESA_CONVENIO_INVITADO
    case `${actionTypes.GET_EMPRESA_CONVENIO_INVITADO}_PENDING`: {
      return {
        gettingEmpresaConvenioInvitado: true,
        gettingEmpresaConvenioInvitadoError: null
      };
    }
    case `${actionTypes.GET_EMPRESA_CONVENIO_INVITADO}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        empresaConvenioInvitado: null,
        gettingEmpresaConvenioInvitado: false,
        gettingEmpresaConvenioInvitadoError: getErrorMessage(action.payload)
      };
    }
    case `${actionTypes.GET_EMPRESA_CONVENIO_INVITADO}_FULFILLED`: {
      return {
        empresaConvenioInvitado: action.payload.data,
        gettingEmpresaConvenioInvitado: false,
        gettingEmpresaConvenioInvitadoError: null
      };
    }
    //UPDATE EMPRESA_CONVENIO_INVITADO
    case `${actionTypes.UPDATE_EMPRESA_CONVENIO_INVITADO}_PENDING`: {
      return {
        updatingEmpresaConvenioInvitado: true,
        updatingEmpresaConvenioInvitadoError: null
      };
    }
    case `${actionTypes.UPDATE_EMPRESA_CONVENIO_INVITADO}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        empresaConvenioInvitadoUpdated: null,
        updatingEmpresaConvenioInvitado: false,
        updatingEmpresaConvenioInvitadoError: getErrorMessage(action.payload)
      };
    }
    case `${actionTypes.UPDATE_EMPRESA_CONVENIO_INVITADO}_FULFILLED`: {
      return {
        empresaConvenioInvitadoUpdated: action.payload.data,
        updatingEmpresaConvenioInvitado: false,
        updatingEmpresaConvenioInvitadoError: null
      };
    }
    //DELETE EMPRESA_CONVENIO
    case `${actionTypes.DELETE_EMPRESA_CONVENIO}_PENDING`: {
      return {
        deletingEmpresaConvenio: true,
        deletingEmpresaConvenioError: null
      };
    }
    case `${actionTypes.DELETE_EMPRESA_CONVENIO}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        deletingEmpresaConvenio: false,
        deletingEmpresaConvenioError: getErrorMessage(action.payload)
      };
    }
    case `${actionTypes.DELETE_EMPRESA_CONVENIO}_FULFILLED`: {
      return {
        deletingEmpresaConvenio: false,
        deletingEmpresaConvenioError: null
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
