import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";
import { Banco } from "../../types/Banks";
import { QueryArguments } from "../../types-business/QueryArguments";

const BANKS_BASE_URL = "/api/banks";

export const getBanks = (params?: QueryArguments): IAction => {
  return {
    type: actionTypes.GET_BANKS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${BANKS_BASE_URL}`, {
        withCredentials: true,
        params: { ...params, ...constants.DEFAULT_SORTING_PARAMS }
      })
    }
  };
};

export const getMonthsWithoutInterests = (params?: QueryArguments): IAction => {
  return {
    type: actionTypes.GET_MONTHS_WITHOUT_INTEREST,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${BANKS_BASE_URL}/months-without-interests`,
        {
          withCredentials: true,
          params
        }
      )
    }
  };
};

export const getMonthsWithoutInterestsByBankId = bankId => {
  return axios.get(
    `${constants.BASE_URL}${BANKS_BASE_URL}/months-without-interests/bank/${bankId}`,
    {
      withCredentials: true,
      params: {}
    }
  );
};

export const createBank = (fields: object): IAction => {
  return {
    type: actionTypes.CREATE_BANK,
    payload: {
      promise: axios.post(`${constants.BASE_URL}${BANKS_BASE_URL}`, fields, {
        withCredentials: true
      })
    }
  };
};

export const createCreditNote = (fields: object): IAction => {
  return {
    type: actionTypes.CREATE_CREDIT_NOTE,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${BANKS_BASE_URL}/credit-note`,
        fields,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const editBank = (bancoId: string, banco: Banco): IAction => {
  return {
    type: actionTypes.CREATE_BANK,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${BANKS_BASE_URL}/${bancoId}`,
        banco,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const createMonthsWithoutInterest = (fields: object): IAction => {
  return {
    type: actionTypes.CREATE_MONTHS_WITHOUT_INTEREST,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${BANKS_BASE_URL}/months-without-interests`,
        fields,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const editMonthsWithoutInterest = (
  banco: object,
  id: string
): IAction => {
  return {
    type: actionTypes.CREATE_MONTHS_WITHOUT_INTEREST,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${BANKS_BASE_URL}/months-without-interests/${id}`,
        banco,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const disableBankCreated = (): IAction => {
  return {
    type: actionTypes.DISABLE_BANK_CREATED
  };
};
