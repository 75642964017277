export default {
  PROSPECT_CREATION: "crear prospecto",
  PROSPECT_DETAILS_EDITION: "EDITAR_DETALLES_PROSPECTO",
  PROSPECTS_REASSIGNATION: "reasignar prospecto",
  MEMBERSHIP_CREATION: "CREAR_MEMBRESIA",
  MEMBERSHIP_EDITION: "EDITAR_MEMBRESIA",
  MAINTENANCE_CREATION: "CREAR_MANTENIMIENTO",
  MAINTENANCE_EDITION: "EDITAR_MANTENIMIENTO",
  AUTHORIZE_MAINTENANCES_PRICE: "AUTORIZAR_PRECIOS_DE_MAINTENIMIENTO",
  AUTHORIZE_MEMBERSHIPS_PRICE: "Autorizar precios de membresias",
  AUTORIZAR_COMISIONES: "Autorizar comisiones",
  // Agregar backend
  AUTHORIZE_SPORTS_PRODUCTS_PRICE: "AUTHORIZE_SPORTS_PRODUCTS_PRICE",
  CARRITO_FITKIDZ: "Carrito solo FITKIDZ",
  CONSULT_SCHEDULE: "Consultar agenda",
  PERMISSION_99: "Ventas corporativas",
  //permisos autorizar
  AUTORIZAR_NOTA_CREDITO_DATOS_FISCALES:
    "Autorizar nota credito datos fiscales",
  AUTORIZAR_NOTA_CREDITO_COBRANZA_ERRONEA:
    "Autorizar nota credito cobranza erronea",
  FACTURAR_CARGOS_CONCESIONARIOS: "Facturar cargos concesionarios",
  BORRAR_CARGOS_CONCESIONARIOS: "Borrar cargos concesionarios",
  RECHAZAR_SOLICITUD_NOTA_CREDITO_DATOS_FISCALES:
    "Rechazar solicitud nota credito datos fiscales",
  RECHAZAR_SOLICITUD_NOTA_CREDITO_COBRANZA_ERRONEA:
    "Rechazar solicitud nota credito cobranza erronea",
  //permisos devoluciones
  CANCELAR_SOLICITUD_DEVOLUCIONES: "Cancelar solicitud devoluciones",
  APROBAR_SOLICITUD_DEVOLUCIONES: "Aprobar solicitudes devoluciones",
  GENERAR_CONTRACARGOS_DEVOLUCIONES: "Generar contracargos devoluciones"
};
