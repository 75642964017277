import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import InvitadoDetalle from "../../types/InvitadoDetalle";
import { readMessageBack } from "../../utils/readMessageBack";
import { PaymentMovement } from "../components/paymentMovements";

export interface GetInvitados {
  invitados: Page<InvitadoDetalle>;
  gettingInvitados: boolean;
  gettingInvitadosError: string;
}
export interface GetInvitado {
  invitado: InvitadoDetalle;
  gettingInvitado: boolean;
  gettingInvitadoError: string;
}
export interface CreateInvitado {
  idInvitado: number;
  creatingInvitado: boolean;
  creatingInvitadoError: string;
}
export interface UpdateInvitado {
  updatingInvitado: boolean;
  updatingInvitadoError: string;
}
export interface DeleteInvitado {
  deletingInvitado: boolean;
  deletingInvitadoError: string;
}
export interface ActivarInvitado {
  activandoInvitado: boolean;
  activandoInvitadoError: string;
}
export interface DesactivarInvitado {
  desactivandoInvitado: boolean;
  desactivandoInvitadoError: string;
}
export interface GetMovimientosInvitado {
  movimientosInvitado: PaymentMovement[];
  gettingMovimientosInvitado: boolean;
  gettingMovimientosInvitadoError: string;
}
export interface GetBitacoraInvitado {
  bitacoraInvitado: any[];
  gettingBitacoraInvitado: boolean;
  gettingBitacoraInvitadoError: string;
}
export interface PostHuellaInvitado {
  postingHuellaInvitado: boolean;
}

interface State
  extends GetInvitados,
    GetInvitado,
    CreateInvitado,
    UpdateInvitado,
    DeleteInvitado,
    ActivarInvitado,
    DesactivarInvitado,
    GetMovimientosInvitado,
    GetBitacoraInvitado,
    PostHuellaInvitado {}

const initialState: State = {
  invitados: null,
  gettingInvitados: false,
  gettingInvitadosError: null,

  invitado: null,
  gettingInvitado: false,
  gettingInvitadoError: null,

  idInvitado: null,
  creatingInvitado: false,
  creatingInvitadoError: null,

  updatingInvitado: false,
  updatingInvitadoError: null,

  deletingInvitado: false,
  deletingInvitadoError: null,

  activandoInvitado: false,
  activandoInvitadoError: null,

  desactivandoInvitado: false,
  desactivandoInvitadoError: null,

  movimientosInvitado: null,
  gettingMovimientosInvitado: false,
  gettingMovimientosInvitadoError: null,

  bitacoraInvitado: null,
  gettingBitacoraInvitado: false,
  gettingBitacoraInvitadoError: null,

  postingHuellaInvitado: false
};

export default function invitadoeReducer(
  state: State = initialState,
  action: IAction
): State {
  const snapshot = snapshotAction(state, action);

  // Llamada a resolver de snapshot
  state != snapshot &&
    !action.type.endsWith("_PENDING") &&
    action.meta &&
    action.meta.resolver &&
    action.meta.resolver({
      fulfilled: action.type.endsWith("_FULFILLED"),
      ...snapshot
    });

  return state != snapshot
    ? {
        ...state,
        ...snapshot
      }
    : state;
}

function snapshotAction(
  state: State = initialState,
  action: IAction
): Partial<State> {
  switch (action.type) {
    // CLEAR_INVITADOS
    case actionTypes.CLEAR_INVITADOS: {
      return {
        invitados: null
      };
    }
    // CLEAR_INVITADO
    case actionTypes.CLEAR_INVITADO: {
      return {
        invitado: null,
        idInvitado: null
      };
    }

    // GET INVITADOS
    case `${actionTypes.GET_INVITADOS}_PENDING`: {
      return {
        gettingInvitados: true,
        gettingInvitadosError: null
      };
    }
    case `${actionTypes.GET_INVITADOS}_REJECTED`: {
      return {
        invitados: null,
        gettingInvitados: false,
        gettingInvitadosError: readMessageBack(action)
      };
    }
    case `${actionTypes.GET_INVITADOS}_FULFILLED`: {
      return {
        invitados: action.payload.data,
        gettingInvitados: false,
        gettingInvitadosError: null
      };
    }

    // GET INVITADO
    case `${actionTypes.GET_INVITADO}_PENDING`: {
      return {
        gettingInvitado: true,
        gettingInvitadoError: null
      };
    }
    case `${actionTypes.GET_INVITADO}_REJECTED`: {
      return {
        invitado: null,
        gettingInvitado: false,
        gettingInvitadoError: readMessageBack(action)
      };
    }
    case `${actionTypes.GET_INVITADO}_FULFILLED`: {
      return {
        invitado: action.payload.data,
        gettingInvitado: false,
        gettingInvitadoError: null
      };
    }

    // POST INVITADO
    case `${actionTypes.POST_INVITADO}_PENDING`: {
      return {
        creatingInvitado: true,
        creatingInvitadoError: null
      };
    }
    case `${actionTypes.POST_INVITADO}_REJECTED`: {
      return {
        idInvitado: null,
        creatingInvitado: false,
        creatingInvitadoError: readMessageBack(action)
      };
    }
    case `${actionTypes.POST_INVITADO}_FULFILLED`: {
      return {
        idInvitado: action.payload.data,
        creatingInvitado: false,
        creatingInvitadoError: null
      };
    }

    // PUT INVITADO
    case `${actionTypes.PUT_INVITADO}_PENDING`: {
      return {
        updatingInvitado: true,
        updatingInvitadoError: null
      };
    }
    case `${actionTypes.PUT_INVITADO}_REJECTED`: {
      return {
        updatingInvitado: false,
        updatingInvitadoError: readMessageBack(action)
      };
    }
    case `${actionTypes.PUT_INVITADO}_FULFILLED`: {
      return {
        updatingInvitado: false,
        updatingInvitadoError: null
      };
    }

    // DELETE INVITADO
    case `${actionTypes.DELETE_INVITADO}_PENDING`: {
      return {
        deletingInvitado: true,
        deletingInvitadoError: null
      };
    }
    case `${actionTypes.DELETE_INVITADO}_REJECTED`: {
      return {
        deletingInvitado: false,
        deletingInvitadoError: readMessageBack(action)
      };
    }
    case `${actionTypes.DELETE_INVITADO}_FULFILLED`: {
      return {
        deletingInvitado: false,
        deletingInvitadoError: null
      };
    }

    // PUT INVITADO ACTIVAR
    case `${actionTypes.PUT_INVITADO_ACTIVAR}_PENDING`: {
      return {
        activandoInvitado: true,
        activandoInvitadoError: null
      };
    }
    case `${actionTypes.PUT_INVITADO_ACTIVAR}_REJECTED`: {
      return {
        activandoInvitado: false,
        activandoInvitadoError: readMessageBack(action)
      };
    }
    case `${actionTypes.PUT_INVITADO_ACTIVAR}_FULFILLED`: {
      return {
        activandoInvitado: false,
        activandoInvitadoError: null
      };
    }

    // PUT INVITADO DESACTIVAR
    case `${actionTypes.PUT_INVITADO_DESACTIVAR}_PENDING`: {
      return {
        desactivandoInvitado: true,
        desactivandoInvitadoError: null
      };
    }
    case `${actionTypes.PUT_INVITADO_DESACTIVAR}_REJECTED`: {
      return {
        desactivandoInvitado: false,
        desactivandoInvitadoError: readMessageBack(action)
      };
    }
    case `${actionTypes.PUT_INVITADO_DESACTIVAR}_FULFILLED`: {
      return {
        desactivandoInvitado: false,
        desactivandoInvitadoError: null
      };
    }

    // CLEAR MOVIMIENTOS INVITADO
    case actionTypes.CLEAR_MOVIMIENTOS_INVITADO: {
      return {
        movimientosInvitado: null
      };
    }

    // GET MOVIMIENTOS INVITADO
    case `${actionTypes.GET_MOVIMIENTOS_INVITADO}_PENDING`: {
      return {
        gettingMovimientosInvitado: true,
        gettingMovimientosInvitadoError: null
      };
    }
    case `${actionTypes.GET_MOVIMIENTOS_INVITADO}_REJECTED`: {
      return {
        movimientosInvitado: null,
        gettingMovimientosInvitado: false,
        gettingMovimientosInvitadoError: readMessageBack(action)
      };
    }
    case `${actionTypes.GET_MOVIMIENTOS_INVITADO}_FULFILLED`: {
      return {
        movimientosInvitado: action.payload.data,
        gettingMovimientosInvitado: false,
        gettingMovimientosInvitadoError: null
      };
    }

    // CLEAR BITACORA INVITADO
    case actionTypes.CLEAR_BITACORA_INVITADO: {
      return {
        bitacoraInvitado: null
      };
    }

    // GET BITACORA INVITADO
    case `${actionTypes.GET_BITACORA_INVITADO}_PENDING`: {
      return {
        gettingBitacoraInvitado: true,
        gettingBitacoraInvitadoError: null
      };
    }
    case `${actionTypes.GET_BITACORA_INVITADO}_REJECTED`: {
      return {
        bitacoraInvitado: null,
        gettingBitacoraInvitado: false,
        gettingBitacoraInvitadoError: readMessageBack(action)
      };
    }
    case `${actionTypes.GET_BITACORA_INVITADO}_FULFILLED`: {
      return {
        bitacoraInvitado: action.payload.data,
        gettingBitacoraInvitado: false,
        gettingBitacoraInvitadoError: null
      };
    }

    // POST HUELLA INVITADO
    case `${actionTypes.POST_HUELLA_INVITADO}_PENDING`: {
      return {
        postingHuellaInvitado: true
      };
    }
    case `${actionTypes.POST_HUELLA_INVITADO}_REJECTED`: {
      return {
        postingHuellaInvitado: false
      };
    }
    case `${actionTypes.POST_HUELLA_INVITADO}_FULFILLED`: {
      return {
        postingHuellaInvitado: false
      };
    }

    default: {
      return state;
    }
  }
}
