import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import ReactTable from "react-table";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Input, Button, Label } from "reactstrap";
import ReportFilter from "./ReportFilter";
import { FILTERS_CATALOGS, FILTERS } from "./interfaces";
import s from "./styles.scss";
import { downloadComissionsProductReport } from "../../actions/reports";
import { faExchangeAlt, faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalFrame from "../../../toolkit/modalFrame";
import axios from "axios";
import constants from "../../../utils/constants";
import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import { readMessageBack } from "../../../utils/readMessageBack";
import { userHasPermission } from "../../../utils/accessTree";
import permissionCodes from "../../../utils/permissionCodes";
import { Typeahead } from "react-bootstrap-typeahead";
import BaseInput from "../../../toolkit/baseInput";
import { postRepararCoord, postRepararVenta } from "../../actions/events";

// TODO: REDUX
const REPORTS_BASE_URL = "/api/reports";

// Última actualización:
// Iteración 2: Cambio del colaborador que tiene el derecho a una comisión

const changeSellStyles = {
  fontSize: "1.15rem",
  color: "blue",
  display: "block",
  textAlign: "center",
  cursor: "pointer"
};

interface Props {
  fetchSearchData: (filters: FILTERS) => void;
  filterCatalogs: FILTERS_CATALOGS;
  searching: boolean;
  reportComissions: object[];
  loadingReport: boolean;
  clubs: object;
  periods: object;
  sellers: any[];
  authorizeComissions: (comissionsList: object) => void;
  userContext: any;
}

interface State {
  checked: Number[];
  selectAll: Boolean;
  filters: any;
  showProccessConfirm: boolean;
  waitProccess: boolean;

  // Reasignación de comisiones
  showColaborador: boolean;
  changeComision: any;
  idVendedor: any;

  // Regeneración de comisiones
  showRegenerar: boolean;
  tipoComision: string;
  folioFactura: string;
  regeneracion: any[];
  waitRegenerar: boolean;
}

class ComissionsProductPage extends React.Component<Props, State> {
  state = {
    checked: [],
    selectAll: false,
    filters: {} as any,
    showProccessConfirm: false,
    waitProccess: false,
    showColaborador: false,
    changeComision: null,
    idVendedor: null,
    showRegenerar: false,
    tipoComision: "0",
    folioFactura: "",
    regeneracion: null,
    waitRegenerar: false
  };

  /**
   * Cambio de valor en input de vendedor
   * @param selected Vendedores seleccionados (Uno o ninguno permitidos)
   */
  onChangeSeller = async selected => {
    await this.setState({
      idVendedor: selected.length > 0 ? selected[0]["id"] : null
    });
  };

  handleSelectAll(e: Boolean) {
    if (e) {
      this.setState({
        checked: this.props.reportComissions.map(e => e.comisionId),
        selectAll: e
      });
    } else {
      this.setState({ checked: [], selectAll: e });
    }
  }
  handleCheckComission(id) {
    if (this.state.checked.includes(id)) {
      this.setState({ checked: this.state.checked.filter(e => e !== id) });
    } else {
      this.setState({ checked: [...this.state.checked, id] });
    }
  }
  handleSubmit = (e, status) => {
    e.preventDefault();
    const comissionsList = {
      comisionesId: this.state.checked,
      estatus: status
    };
    this.props.authorizeComissions(comissionsList);
    this.handleSelectAll(false);
  };

  fetchSearchData = (filters: FILTERS) => {
    this.setState({ filters });
    this.props.fetchSearchData({
      ...filters,
      tipoProducto: filters.tipoProducto == "-1" ? null : filters.tipoProducto
    });
  };

  fetchProccess = () => {
    this.setState({
      showProccessConfirm: true
    });
  };

  toggleModal = () => {
    this.setState({
      showProccessConfirm: !this.state.showProccessConfirm
    });
  };

  reasignarComsion = comision => {
    this.setState({
      showColaborador: true,
      changeComision: comision
    });
  };

  toggleModalColaborador = () => {
    this.setState({
      showColaborador: !this.state.showColaborador
    });
  };

  regenerarComision = () => {
    if (!this.state.folioFactura || !this.state.folioFactura.trim()) {
      errorNotification("Debe ingresar un folio de favtura");
      return;
    }

    if (!this.state.idVendedor) {
      errorNotification(
        "Debe seleccionar al colaborador que recibirá la comisión"
      );
      return;
    }

    this.setState({
      regeneracion: null,
      waitRegenerar: true
    });
    const tipo = this.state.tipoComision == "1" ? "coordinación" : "venta";
    infoNotification(`Regenerando comisiones de ${tipo} faltantes`);

    if (this.state.tipoComision == "1") {
      postRepararCoord(this.state.idVendedor, this.state.folioFactura)
        .then(response => {
          successNotification("Revise el resultado de su solicitud por favor");
          this.setState({
            regeneracion: response.data,
            waitRegenerar: false
          });
        })
        .catch(err => {
          errorNotification(
            "Ocurrió un error inesperado, intente de nuevo más tarde"
          );
          this.setState({
            regeneracion: null,
            waitRegenerar: false
          });
        });
    } else {
      postRepararVenta(this.state.idVendedor, this.state.folioFactura)
        .then(response => {
          successNotification("Revise el resultado de su solicitud por favor");
          this.setState({
            regeneracion: response.data,
            waitRegenerar: false
          });
        })
        .catch(err => {
          errorNotification(
            "Ocurrió un error inesperado, intente de nuevo más tarde"
          );
          this.setState({
            regeneracion: null,
            waitRegenerar: false
          });
        });
    }
  };

  cancelarRegeneracion = () => {
    this.toggleModalRegenerar();
  };

  toggleModalRegenerar = () => {
    this.setState({
      showRegenerar: !this.state.showRegenerar,
      tipoComision: "0",
      folioFactura: "",
      regeneracion: null,
      idVendedor: null
    });
  };

  changeColaborador = async () => {
    try {
      this.setState({
        showColaborador: false
      });

      infoNotification("Actualizando al colaborador");
      let response = await axios.post(
        `${constants.BASE_URL}${REPORTS_BASE_URL}/comissionsProducts/reasignar`,
        {
          idVendedor: this.state.idVendedor,
          idComision: this.state.changeComision.comisionId
        },
        {
          withCredentials: true
        }
      );

      this.props.fetchSearchData(this.state.filters);

      this.setState({
        changeComision: null
      });

      infoNotification(
        response.data.message || "Comisión reasignada correctamente"
      );
    } catch (error) {
      errorNotification(
        readMessageBack(error, "No fue posible reasignar la comisión")
      );
    }
  };

  confirmProccess = async () => {
    try {
      this.setState({
        showProccessConfirm: false
      });

      infoNotification("Procesando las comisiones");
      let response = await axios.post(
        `${constants.BASE_URL}${REPORTS_BASE_URL}/comissionsProducts/proccess`,
        null,
        {
          withCredentials: true
        }
      );

      infoNotification(
        response.data.message || "Comisiones procesadas correctamente"
      );
    } catch (error) {
      errorNotification(
        readMessageBack(error, "No fue posible procesar las comisiones")
      );
    }
  };

  render() {
    const columnsCommisionsTableReport = [
      {
        Header: _ => {
          return (
            <div className={`${s.checkboxSelectAll} w-100 text-center`}>
              <Input
                type="checkbox"
                checked={this.state.selectAll}
                onChange={e => this.handleSelectAll(Boolean(e.target.checked))}
              />
            </div>
          );
        },
        id: "seleccionado",
        minWidth: 50,
        accessor: comission => {
          return (
            <Input
              type="checkbox"
              style={{ left: "24px" }}
              checked={this.state.checked.includes(comission.comisionId)}
              onChange={e => this.handleCheckComission(comission.comisionId)}
            />
          );
        }
      },
      {
        Header: "#",
        accessor: "numero",
        width: 50
      },
      {
        Header: "Vendedor",
        accessor: "nombreVendedor",
        minWidth: 160
      },
      {
        Header: "Tipo Comisión",
        accessor: "tipo"
      },
      {
        Header: "Descripcion producto",
        accessor: "productoDescripcion"
      },
      {
        Header: "Importe venta",
        accessor: "importeVenta",
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: "right"
            }
          };
        }
      },
      {
        Header: "Comisión ($)",
        accessor: "totalComision",
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: "right"
            }
          };
        }
      },
      {
        Header: "Comisión (%)",
        accessor: "totalPorcentaje",
        getProps: (state, rowInfo, column) => {
          return {
            style: {
              textAlign: "right"
            }
          };
        }
      },
      {
        Header: "Estatus",
        accessor: "estatus"
      },
      {
        Header: "Fecha de registro",
        accessor: "fechaRegistro"
      },
      {
        Header: "Fecha de aplicación",
        accessor: "fechaAplicacion"
      },
      {
        Header: "Fecha de autorización",
        accessor: "fechaAutorizacion"
      },
      {
        Header: "Reasignar",
        Cell: row => {
          if (!row.original.fechaAplicacion) {
            return (
              <div>
                <a
                  style={changeSellStyles}
                  onClick={() => this.reasignarComsion(row.original)}
                >
                  <FontAwesomeIcon icon={faExchangeAlt} />
                  &nbsp;
                </a>
              </div>
            );
          } else {
            return null;
          }
        }
      }
    ];

    if (!userHasPermission(permissionCodes.COMMISSIONS_AUTHORIZATION)) {
      columnsCommisionsTableReport.splice(0, 1);
    }

    if (!userHasPermission(permissionCodes.COMMISSIONS_PRODUCTS_REASIGN)) {
      columnsCommisionsTableReport.pop();
    }

    return (
      <Container className="mt-3 mb-5 p-0">
        <Row className="mb-1">
          <Col sm="12">
            Ingresa los campos requeridos para comenzar ( * ) o incia el
            procesamiento de pago
          </Col>
        </Row>

        <ReportFilter
          // @ts-ignore
          clubs={this.props.clubs}
          periods={this.props.periods}
          fetchSearchData={this.fetchSearchData}
          sellers={this.props.sellers}
          showProccess={false}
          fetchProccess={this.fetchProccess}
          userContext={this.props.userContext}
        />
        {!!(this.props.reportComissions || []).length && (
          <Row className={"mt-5"}>
            <Col sm="6">
              Resultados de la búsqueda:{" "}
              <b
                style={{ fontWeight: "bolder", display: "inline-block" }}
                className="ml-3"
              >
                {(this.props.reportComissions || []).length}
              </b>
            </Col>
            <Col sm="6" className="text-right">
              <span>Exportar a CSV: </span>
              <Button
                id="btnReportExportCsv"
                color="link"
                onClick={() => {
                  const { filters } = this.state;
                  downloadComissionsProductReport(filters);
                }}
              >
                <FontAwesomeIcon icon={faFileExport} color="black" />
              </Button>
            </Col>
          </Row>
        )}

        <div className="alert alert-danger">
          <div>
            Si no encuentra alguna comisión de venta o coordinación, es probable
            que se deba a la configuración del producto. En tal caso, oprima el
            siguiente botón:
          </div>
          <div>
            <Button
              className={`mt-4 ${s.buttonMarginTop} ${s.primaryButton}`}
              onClick={this.toggleModalRegenerar}
              style={{ height: "2rem", marginLeft: "8px" }}
            >
              Regenerar comisión
            </Button>
          </div>
        </div>
        <ReactTable
          pages={1}
          data={this.props.reportComissions || []}
          columns={columnsCommisionsTableReport}
          showPagination={false}
          sortable={false}
          multiSort={false}
          resizable={true}
          pageSize={(this.props.reportComissions || []).length + 3}
        />
        {this.props.reportComissions &&
          userHasPermission(permissionCodes.COMMISSIONS_AUTHORIZATION) && (
            <Row>
              <Col xs={12} className="text-right pr-0 pt-2">
                <Button
                  className={`${s.buttonMarginTop} ${s.primaryButton} mr-3`}
                  onClick={e => this.handleSubmit(e, "AUTORIZADA")}
                  size="sm"
                  hidden={this.state.waitProccess}
                >
                  {"Autorizar"}
                </Button>
                <Button
                  className={`${s.buttonMarginTop} ${s.primaryButton}`}
                  onClick={e => this.handleSubmit(e, "RECHAZADA")}
                  size="sm"
                  hidden={this.state.waitProccess}
                >
                  {"Rechazar"}
                </Button>
              </Col>
            </Row>
          )}

        <ModalFrame
          title={"Confirme la operación"}
          isOpen={this.state.showProccessConfirm}
          toggle={this.toggleModal}
          size="lg"
        >
          <Row>
            <Col lg={12}>
              Cuando autoriza una comisión, esta se procesa en un sistema
              externo que debería estar disponible para continuar con el flujo
              de pago. <br />
              <br />
              Diariamente se realiza una comprobación sobre las comisiones
              autorizadas que no aplicaron este proceso; sin embargo, usted
              puede efectuar esta comprobación manualmente con esta opción.
            </Col>
            <Col lg={12}>
              <div style={{ textAlign: "right" }}>
                <Button
                  className={`mt-4 ${s.buttonMarginTop} ${s.primaryButton}`}
                  onClick={this.confirmProccess}
                  style={{ height: "2rem", marginLeft: "8px" }}
                >
                  Aceptar
                </Button>
                <Button
                  className={`mt-4 ${s.buttonMarginTop} ${s.primaryButton}`}
                  onClick={this.toggleModal}
                  style={{ height: "2rem", marginLeft: "8px" }}
                >
                  Cancelar
                </Button>
              </div>
            </Col>
          </Row>
        </ModalFrame>

        <ModalFrame
          title={"Seleccione al colaborador"}
          isOpen={this.state.showColaborador}
          toggle={this.toggleModalColaborador}
          size="lg"
        >
          <Row>
            <Col lg={12}>
              Seleccione al colaborador que recibirá la comisión
            </Col>
            <Col lg={12}>
              <hr />
            </Col>
            <Col lg={12}>
              <Label className={s.label}>Nombre de vendedor</Label>
              <Typeahead
                id={"vendedor"}
                promptText={"Vendedores"}
                bsSize={"sm"}
                onChange={selected => this.onChangeSeller(selected)}
                emptyLabel={"Sin coincidencias"}
                disabled={this.props.sellers ? false : true}
                maxResults={20}
                options={
                  this.props.sellers
                    ? this.props.sellers.map(item => ({
                        id: item.userId,
                        label: `${item.name || ""} ${item.firstSurname ||
                          ""} ${item.secondSurname || ""}`.trim(),
                        seller: item
                      }))
                    : []
                }
              />
            </Col>
            <Col lg={12}>
              <div style={{ textAlign: "right", marginTop: "15px" }}>
                <Button
                  className={`mt-4 ${s.buttonMarginTop} ${s.primaryButton}`}
                  onClick={this.changeColaborador}
                  style={{ height: "2rem", marginLeft: "8px" }}
                >
                  Aceptar
                </Button>
                <Button
                  className={`mt-4 ${s.buttonMarginTop} ${s.primaryButton}`}
                  onClick={this.toggleModalColaborador}
                  style={{ height: "2rem", marginLeft: "8px" }}
                >
                  Cancelar
                </Button>
              </div>
            </Col>
          </Row>
        </ModalFrame>

        <ModalFrame
          title={"Regenerar comisión"}
          isOpen={this.state.showRegenerar}
          toggle={this.toggleModalRegenerar}
          size="lg"
        >
          <Row>
            <Col md={12}>
              Ingrese el folio de la factura, elija el tipo de comisión y
              seleccione al colaborador que la recibirá.
            </Col>
            <Col md={12}>
              <hr />
            </Col>
            <Col md={3}>
              <BaseInput
                label={"Tipo de comisión"}
                name="tipoComision"
                type="select"
                id="tipoComision"
                size={"sm"}
                value={this.state.tipoComision}
                options={[{ value: -1, label: "Selecciona una opción" }].concat(
                  [
                    { label: "Venta", value: 0 },
                    { label: "Coordinacion", value: 1 }
                  ]
                )}
                onChange={e => this.setState({ tipoComision: e.target.value })}
              />
            </Col>
            <Col md={3}>
              <BaseInput
                label={"Folio de factura"}
                name="folioFactura"
                id="folioFactura"
                size={"sm"}
                value={this.state.folioFactura}
                onChange={e => this.setState({ folioFactura: e.target.value })}
              />
            </Col>
            <Col md={6}>
              <Label className={s.label}>Nombre de vendedor</Label>
              <Typeahead
                id={"vendedor"}
                promptText={"Vendedores"}
                bsSize={"sm"}
                onChange={selected => this.onChangeSeller(selected)}
                emptyLabel={"Sin coincidencias"}
                disabled={this.props.sellers ? false : true}
                maxResults={20}
                options={
                  this.props.sellers
                    ? this.props.sellers.map(item => ({
                        id: item.userId,
                        label: `${item.name || ""} ${item.firstSurname ||
                          ""} ${item.secondSurname || ""}`.trim(),
                        seller: item
                      }))
                    : []
                }
              />
            </Col>
            {this.state.regeneracion ? (
              <Col md={12}>
                <hr />
                <ReactTable
                  data={this.state.regeneracion}
                  columns={[
                    {
                      Header: "Producto",
                      id: "producto",
                      accessor: "producto",
                      width: "256"
                    },
                    {
                      Header: "Estatus",
                      id: "estatus",
                      accessor: "estatus",
                      width: "96"
                    },
                    {
                      Header: "Fecha reg.",
                      id: "fecha",
                      accessor: "fecha",
                      width: "96"
                    },
                    {
                      Header: " ",
                      id: "message",
                      accessor: "message"
                    }
                  ]}
                  showPagination={false}
                  sortable={false}
                  multiSort={false}
                  resizable={true}
                  pageSize={this.state.regeneracion.length}
                />
              </Col>
            ) : null}
            <Col md={12}>
              <div style={{ textAlign: "right", marginTop: "15px" }}>
                <Button
                  className={`mt-4 ${s.buttonMarginTop} ${s.primaryButton}`}
                  onClick={this.regenerarComision}
                  style={{ height: "2rem", marginLeft: "8px" }}
                  disabled={this.state.waitRegenerar}
                >
                  {this.state.waitRegenerar ? "Regenerando..." : "Aceptar"}
                </Button>
                <Button
                  className={`mt-4 ${s.buttonMarginTop} ${s.primaryButton}`}
                  onClick={this.cancelarRegeneracion}
                  style={{ height: "2rem", marginLeft: "8px" }}
                  disabled={this.state.waitRegenerar}
                >
                  {this.state.regeneracion ? "Cerrar" : "Cancelar"}
                </Button>
              </div>
            </Col>
          </Row>
        </ModalFrame>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ComissionsProductPage));
