import React from "react";
import { connect } from "react-redux";
import Layout from "../../components/layout";
import { Helmet } from "react-helmet";
import SellsLtePage from "../../components/sellsLtePage";

import { sendSells, clearDatasendSells } from "../../actions/reports";

interface Props {
  sendSells: (tiposPago: any[], cantidad: number, nroItems: number) => any;
  clearDatasendSells: () => any;
  sellsLte: object;
}

interface State {}

class SellsLte extends React.Component<Props, State> {
  state = {};

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    return (
      <main>
        <Layout>
          <Helmet title="Generación de ventas por en lote" />

          <SellsLtePage
            sellsLteReport={this.props.sellsLte}
            sendSells={this.props.sendSells}
            clearDatasendSells={this.props.clearDatasendSells}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  sendSells: (tiposPago: any[], cantidad: number, nroItems: number) => {
    return dispatch(sendSells(tiposPago, cantidad, nroItems));
  },
  clearDatasendSells: () => {
    return dispatch(clearDatasendSells());
  }
});

const mapStateToProps = state => {
  const { sellsLte } = state;
  return {
    sellsLte
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SellsLte);
