import React from "react";
import BaseInput from "../../../toolkit/baseInput";
import { Row, Col, FormGroup, Label, Input, Card, CardBody } from "reactstrap";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./stylesComponents.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faSave,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";

const OFICINA = "OFICINA";
const PARTICULAR = "PARTICULAR";

interface CorreoElectronico {
  correo: string;
  tipo: string;
  principal: boolean;
  bloqueoPublicidad: boolean;
  correoElectronicoId: string;
}

interface Props {
  viewMode: boolean;
  data: CorreoElectronico[];
  onAddEmail: () => void;
  onDeleteEmail: (index: string) => void;
  saveEmail: (index: number) => void;
  onChangeEmail: (name: string, value: string, index: string) => void;
  onChangePrincipalEmail: (index: string) => void;
  onChangeBloqueoPublicidad: (checked: boolean, index: string) => void;
  errors: any;
}

class CorreosElectronicos extends React.Component<Props> {
  renderCorreos = () => {
    let data = this.props.data.map((correoElectronico, index) => {
      const disableSave = !correoElectronico.correo || !correoElectronico.tipo;
      const disableDelete =
        !correoElectronico.correoElectronicoId || correoElectronico.principal;
      return (
        <Card key={index + "-correoElectronico"} className={s.card}>
          <CardBody className={`p-1 ${s.cardBody}`}>
            <Row>
              <Col>
                <BaseInput
                  label={"Correo Electronico * "}
                  name={`correo_${index}`}
                  type="text"
                  id={`correo_${index}`}
                  placeholder={"Correo Electronico"}
                  value={correoElectronico.correo}
                  onChange={e => this.handleChangeEmail("correo", index, e)}
                  disabled={true}
                />
              </Col>
              <Col>
                <BaseInput
                  label={"Tipo * "}
                  name={`tipo_${index}`}
                  type="select"
                  options={this.typeEmailOptions}
                  id={`tipo_${index}`}
                  placeholder={"Tipo"}
                  value={correoElectronico.tipo}
                  onChange={e => this.handleChangeEmail("tipo", index, e)}
                  disabled={!!this.props.viewMode}
                />
              </Col>
              <Col className={s.centerRow} xs={2}>
                <FormGroup className={s.label} check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={correoElectronico.principal}
                      onChange={_ => this.handleChangePrincipal(index)}
                      disabled={true}
                    />
                    Principal
                  </Label>
                </FormGroup>
              </Col>
              <Col className={s.centerRow} xs={2}>
                <FormGroup className={s.label} check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={correoElectronico.bloqueoPublicidad}
                      onChange={e =>
                        this.handleChangeBloqueoPublicidad(e, index)
                      }
                      disabled={!!this.props.viewMode}
                    />
                    Bloqueo Publicidad
                  </Label>
                </FormGroup>
              </Col>
              {!this.props.viewMode && (
                <Col xs={1}>
                  <div className={`float-right mx-1 mt-4 ${s.deleteRow}`}>
                    <a
                      onClick={e => {
                        e.preventDefault();
                        if (!disableSave) this.props.saveEmail(index);
                      }}
                      style={{
                        color: disableSave ? "gray" : "black"
                      }}
                    >
                      <FontAwesomeIcon icon={faSave} />
                    </a>
                  </div>
                  <div className={`float-right mx-1 mt-4 ${s.deleteRow}`}>
                    <a
                      style={{
                        color: disableDelete ? "gray" : "black"
                      }}
                      onClick={e =>
                        !disableDelete && this.deleteEmail(e, index)
                      }
                    >
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </a>
                  </div>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      );
    });
    return data;
  };

  handleChangeEmail = (name, index, e) => {
    const value = e.target.value;
    this.props.onChangeEmail(name, value, index);
  };

  handleChangePrincipal = index => {
    this.props.onChangePrincipalEmail(index);
  };

  handleChangeBloqueoPublicidad = (e, index) => {
    const checked = e.target.checked;
    this.props.onChangeBloqueoPublicidad(checked, index);
  };

  handleAddEmail = e => {
    e.preventDefault();
    this.props.onAddEmail();
  };

  deleteEmail = (e, index) => {
    e.preventDefault();
    this.props.onDeleteEmail(index);
  };

  typeEmailOptions = [
    { value: PARTICULAR, label: "PARTICULAR" },
    { value: OFICINA, label: "OFICINA" }
  ];

  render() {
    return (
      <div>
        <Row className="primaryTitle m-0 mt-3">
          <Col>
            <p className="m-0">Correos Electrónicos Registrados</p>
            {this.props.errors &&
              this.props.errors.map((message, index) => {
                return (
                  <span key={index} className={s.errorSpan}>
                    {message}
                    <br />
                  </span>
                );
              })}
          </Col>
          {/*!this.props.viewMode && (
            <Col className="p-0" xs={2}>
              <span
                style={{ cursor: "pointer" }}
                onClick={e => this.handleAddEmail(e)}
              >
                Agregar Correo
              </span>
            </Col>
          )*/}
        </Row>
        {this.renderCorreos()}
      </div>
    );
  }
}

export default withRouter(withStyles(s)(CorreosElectronicos));
