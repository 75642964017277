import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import s from "./styles.scss";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  message: string;
  loading: string;
  small: boolean;
}

class BasicSpinner extends React.Component<Props, void> {
  render() {
    const { message, loading } = this.props;
    return (
      <div>
        {!!loading && (
          <div
            className={
              "text-center " + (!!this.props.small ? "m-2 p-1" : "m-5 p-5")
            }
          >
            <FontAwesomeIcon
              icon={faSpinner}
              style={{ color: "#000", fontSize: "3em" }}
              pulse={true}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span style={{ fontSize: "2em" }}>{message || "Cargando ..."}</span>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(s)(BasicSpinner);
