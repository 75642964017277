import React from "react";
import { Container } from "reactstrap";
import { errorNotification } from "../../../utils/notifications";
import { Router, Styled } from "../../../toolkit/decorators/Artifact";
import FormBuilder from "../../../toolkit/baseForms2/formBuilder/FormBuilder";
import { Buttons, formConfig, Inputs } from "./MembresiasExternas.form";
import { MemoTable } from "./MembresiasExternas.table";
import s from "./styles.scss";
import { MembresiasExternasStore } from "../../containers/membresiasExternas/MembresiasExternas.store";
import { MembresiasExternasDispatcher } from "../../containers/membresiasExternas/MembresiasExternas.dispatcher";

interface Props extends MembresiasExternasStore, MembresiasExternasDispatcher {}

interface State {}

@Router()
@Styled(s)
export default class MembresiasExternas extends React.Component<Props, State> {
  submit = async (fields: { [key in Inputs]: string }) => {
    if (!fields.membresia && !fields.persona && !fields.personaId) {
      errorNotification(
        "Ingrese al menos un criterio de búsqueda además del club"
      );
      return;
    }

    const preview = await this.props.getMembresiasCrm1Acceso(fields);

    if (!preview.fulfilled) {
      errorNotification(preview.membresiasCrm1AccesoError);
    }
  };

  render() {
    return (
      <Container className="mb-5">
        <FormBuilder<Inputs, Buttons>
          config={formConfig}
          submit={this.submit}
          processing={this.props.membresiasCrm1AccesoLoading}
        ></FormBuilder>

        <hr className="row" />

        <MemoTable
          data={this.props.membresiasCrm1Acceso || []}
          loading={this.props.membresiasCrm1AccesoLoading}
        />
      </Container>
    );
  }
}
