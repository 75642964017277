import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import { getErrorMessage } from "../../utils/helpers";
import {
  infoNotification,
  CREATE_BANK_PENDING,
  errorNotification,
  CREATE_BANK_ERROR,
  successNotification,
  CREATE_BANK,
  CREATE_MONTHS_WITHOUT_INTEREST_PENDING,
  CREATE_MONTHS_WITHOUT_INTEREST_ERROR,
  CREATE_MONTHS_WITHOUT_INTEREST,
  CREATE_CREDIT_NOTE_PENDING,
  CREATE_CREDIT_NOTE_ERROR,
  CREATE_CREDIT_NOTE,
  ERROR
} from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";
interface State {
  banksMonthsWithoutInterest: object[];
  gettingBanks: boolean;
  createBankError: object;
  creatingBank: boolean;
  bankCreated: boolean;
  all: object;
  reloadBanks: boolean;
  createCreditNoteError: boolean;
  createCreditNote: boolean;
  creditNoteCreated: boolean;
}
const initialState: State = {
  banksMonthsWithoutInterest: null,
  gettingBanks: false,
  createBankError: null,
  creatingBank: false,
  bankCreated: null,
  all: null,
  reloadBanks: false,
  createCreditNoteError: null,
  createCreditNote: false,
  creditNoteCreated: null
};

export default function catalogsReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.GET_BANKS}_PENDING`: {
      return {
        ...state,
        all: null,
        gettingBanks: true,
        reloadBanks: false
      };
    }
    case `${actionTypes.GET_BANKS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        all: null,
        gettingBanks: false
      };
    }
    case `${actionTypes.GET_BANKS}_FULFILLED`: {
      return {
        ...state,
        all: action.payload.data,
        gettingBanks: false
      };
    }
    case `${actionTypes.CREATE_BANK}_PENDING`: {
      infoNotification(CREATE_BANK_PENDING);
      return {
        ...state,
        createBankError: null,
        creatingBank: true
      };
    }
    case `${actionTypes.CREATE_BANK}_REJECTED`: {
      errorNotification(readMessageBack(action, CREATE_BANK_ERROR));
      return {
        ...state,
        createBankError: getErrorMessage(action.payload),
        creatingBank: false
      };
    }
    case `${actionTypes.CREATE_BANK}_FULFILLED`: {
      successNotification(CREATE_BANK);
      return {
        ...state,
        createBankError: null,
        creatingBank: false,
        bankCreated: true,
        reloadBanks: true
      };
    }
    case `${actionTypes.DISABLE_BANK_CREATED}`: {
      return {
        ...state,
        bankCreated: false
      };
    }
    case `${actionTypes.GET_MONTHS_WITHOUT_INTEREST}_PENDING`: {
      return {
        ...state,
        banksMonthsWithoutInterest: [],
        gettingBanks: true,
        reloadBanks: false
      };
    }
    case `${actionTypes.GET_MONTHS_WITHOUT_INTEREST}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        banksMonthsWithoutInterest: [],
        gettingBanks: false
      };
    }
    case `${actionTypes.GET_MONTHS_WITHOUT_INTEREST}_FULFILLED`: {
      return {
        ...state,
        banksMonthsWithoutInterest: action.payload.data,
        gettingBanks: false
      };
    }
    case `${actionTypes.CREATE_MONTHS_WITHOUT_INTEREST}_PENDING`: {
      infoNotification(CREATE_MONTHS_WITHOUT_INTEREST_PENDING);
      return {
        ...state,
        createBankError: null,
        creatingBank: true
      };
    }
    case `${actionTypes.CREATE_MONTHS_WITHOUT_INTEREST}_REJECTED`: {
      errorNotification(
        readMessageBack(action, CREATE_MONTHS_WITHOUT_INTEREST_ERROR)
      );
      return {
        ...state,
        createBankError: getErrorMessage(action.payload),
        creatingBank: false
      };
    }
    case `${actionTypes.CREATE_MONTHS_WITHOUT_INTEREST}_FULFILLED`: {
      successNotification(CREATE_MONTHS_WITHOUT_INTEREST);
      return {
        ...state,
        createBankError: null,
        creatingBank: false,
        bankCreated: true,
        reloadBanks: true
      };
    }
    case `${actionTypes.CREATE_CREDIT_NOTE}_PENDING`: {
      infoNotification(CREATE_CREDIT_NOTE_PENDING);
      return {
        ...state,
        createCreditNoteError: null,
        createCreditNote: true
      };
    }
    case `${actionTypes.CREATE_CREDIT_NOTE}_REJECTED`: {
      errorNotification(readMessageBack(action, CREATE_CREDIT_NOTE_ERROR));
      return {
        ...state,
        createCreditNoteError: getErrorMessage(action.payload),
        createCreditNote: false
      };
    }
    case `${actionTypes.CREATE_CREDIT_NOTE}_FULFILLED`: {
      successNotification(CREATE_CREDIT_NOTE);
      return {
        ...state,
        createCreditNoteError: null,
        createCreditNote: false,
        creditNoteCreated: true
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
