import React from "react";
import ReactTable from "react-table";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";

class NotificationsMessages extends React.Component {
  render() {
    let columns = [
      {
        Header: () => <p className="font-weight-bold">Tipo</p>,
        id: 0,
        width: 150,
        accessor: "tipo",
        Cell: row => {
          return row.original.tipo === "success" ? (
            <p className={s.success}>Realizado</p>
          ) : row.original.tipo === "warning" ? (
            <p className={s.warning}>Nota</p>
          ) : row.original.tipo === "error" ? (
            <p className={s.error}>Error</p>
          ) : (
            <p>{row.original.tipo}</p>
          );
        }
      },
      {
        Header: () => <p className="font-weight-bold">Mensaje</p>,
        id: 1,
        width: 500,
        accessor: "mensaje",
        Cell: row => {
          return <p className={s.mensaje}>{row.original.mensaje}</p>;
        }
      }
    ];

    const { messages } = this.props;

    return (
      <div>
        <ReactTable
          data={messages || []}
          className={s.table}
          columns={columns}
          sortable={false}
          resizable={true}
          pageSize={(messages || []).length + 1}
          showPagination={false}
          noDataText="Sin errores."
        />
      </div>
    );
  }
}

export default withRouter(withStyles(s)(NotificationsMessages));
