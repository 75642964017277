import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import * as prospectsActions from "../../actions/prospects";
import * as catalogActions from "../../actions/catalogs";
import * as convenioActions from "../../actions/convenios";
import * as promotionActions from "../../actions/promotions";
import Quotations from "../../components/quotations";
import * as productActions from "../../actions/products";
import { QueryArguments } from "../../../types-business/QueryArguments";

interface Props {
  getQuotations: (personaId: string, args: object, integrantes: object) => any;
  getClubs: () => void;
  getMembershipsTypes: (clubId: string) => void;
  getMaintenances: (membresiaId: string) => void;
  getPromotions: () => void;
  getEmpresasConvenio: () => void;
  loading: boolean;
  quotations: object[];
  clubs: object[];
  membershipsTypes: object[];
  maintenances: object[];
  promotions: object[];
  getMembershipById: (id: string) => any;
  membershipById: object;
  empresasConvenio: any[];
  getMemberhips: (id: string) => any;
  successfullPurchase: object;
  validateReferralCode: (codeObject: object) => any;
  checkMode: boolean;
  userContext: object;
}

class QuotationsScreen extends React.Component<Props> {
  render() {
    return (
      <main>
        <Helmet title="Prospects Quotations" />
        <Layout>
          <Quotations
            {...this.props}
            quotationUse="VENTA"
            userContext={this.props.userContext}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getQuotations: (personaId: string, args: object, integrantes: object) => {
    return dispatch(
      prospectsActions.getQuotations(personaId, args, integrantes)
    );
  },
  getPromotions: (attr: QueryArguments) => {
    dispatch(promotionActions.getPromotionsFiltered(attr));
  },
  getClubs: () => {
    dispatch(catalogActions.getClubs());
  },
  getMembershipsTypes: (clubId: string) => {
    dispatch(catalogActions.getMembershipsTypesByClub(clubId));
  },
  getMaintenances: (membresiaId: string) => {
    dispatch(catalogActions.getMaintenancesByMembershipId(membresiaId));
  },
  getMembershipById: (id: string) => {
    dispatch(productActions.getMembershipById(id));
  },
  getEmpresasConvenio: () => {
    dispatch(convenioActions.getEmpresasConvenio());
  },
  getMemberhips: maintenanceId => {
    dispatch(productActions.getMembershipsMaintenance(maintenanceId));
  },
  membershipPurchase: (membershipClient: object) => {
    dispatch(prospectsActions.membershipPurchase(membershipClient));
  },
  validateReferralCode: (codeObject: object) => {
    return dispatch(prospectsActions.validateReferralCode(codeObject));
  }
});

const mapStateToProps = state => {
  const { prospects, catalogs, products, convenios, promotions, user } = state;

  return {
    quotations: prospects.quotations,
    loading: prospects.loadingQuotations,
    successfullPurchase: prospects.successfullPurchase,
    clubs: catalogs.clubs,
    membershipsTypes: catalogs.membershipsTypes,
    maintenances: catalogs.maintenances,
    promotions: promotions.promotionsFiltered,
    membershipById: products.membershipById,
    empresasConvenio: convenios.empresasConvenio,
    membershipsMaintenance: products.membershipsMaintenance,
    checkMode: true,
    userContext: user.userContext
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuotationsScreen);
