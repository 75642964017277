import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Row, Col, Button } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import s from "./styles.scss";
import ReactTable from "react-table";

interface Props {
  clubs: { clubId: number; nombre: string }[];
  onChangeImpuestos: (genData: any[], TaxesDataValidated: boolean) => any;
  tipoImpuestos: { idCampo; label }[];
  tipoFactor: { idCampo; label }[];
  billingData: {
    billingCatalogs: any[];
    dataResponse: object;
    gettingCatalogs: boolean;
    sendingData: boolean;
    successSend: boolean;
  };
  impuestos: object[];
  disabled: boolean;
}

interface State {
  fields: {
    tipo: string;
    cfdiBase: string;
    cfdiImpuesto: string;
    cfdiTipoFactor: string;
    cfdiTasaOCuota: string;
    cfdiImporte: string;
  };
  validData: boolean;
}

class TaxesData extends React.Component<Props, State> {
  state = {
    fields: {
      tipo: "",
      cfdiBase: "",
      cfdiImpuesto: "",
      cfdiTipoFactor: "",
      cfdiTasaOCuota: "",
      cfdiImporte: ""
    },
    validData: false
  };

  onChangeField = async (e, fieldName) => {
    let state = { ...this.state };
    state.fields[fieldName] = e.target.value;
    await this.setState(state);

    if (this.validateFields()) {
      this.setState({ ...this.state, validData: true });
    } else {
      this.setState({ ...this.state, validData: false });
    }
  };

  validateFields = () => {
    const { fields } = this.state;
    for (const key in fields) {
      if (fields.hasOwnProperty(key)) {
        const element = fields[key];
        if (element == "") return false;
      }
    }
    return true;
  };
  getClearFields = () => ({
    tipo: "",
    cfdiBase: "",
    cfdiImpuesto: "",
    cfdiTipoFactor: "",
    cfdiTasaOCuota: "",
    cfdiImporte: ""
  });

  addItem = () => {
    const state = { ...this.state };

    const impuestos = [...this.props.impuestos];
    impuestos.push(state.fields);
    this.props.onChangeImpuestos(impuestos, true);

    state.fields = this.getClearFields();
    state.validData = false;
    this.setState(state);
  };

  render() {
    const itemsTablecolumns = [
      {
        Header: "tipo",
        accessor: "tipo"
      },
      {
        Header: "cfdiBase",
        accessor: "cfdiBase"
      },
      {
        Header: "cfdiImpuesto",
        accessor: "cfdiImpuesto"
      },
      {
        Header: "cfdiTipoFactor",
        accessor: "cfdiTipoFactor"
      },
      {
        Header: "cfdiTasaOCuota",
        accessor: "cfdiTasaOCuota"
      },
      {
        Header: "cfdiImporte",
        accessor: "cfdiImporte"
      }
    ];
    let dataTable = this.props.impuestos;
    let CatImpuesto = [];
    for (let catalog of this.props.billingData.billingCatalogs) {
      if (catalog["clave"] == "CatImpuesto") CatImpuesto = catalog["data"];
    }
    return (
      <span>
        <h5 className={s.grayTitle}>Agregar impuestos al item</h5>
        <Row>
          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*tipo"}
              name={`tipo`}
              type="select"
              id={`tipo`}
              placeholder={"tipo"}
              options={[
                { value: -1, label: "Seleccionar el Tipo impuesto" }
              ].concat(
                this.props.tipoImpuestos.map(({ label }) => {
                  return {
                    value: label,
                    label: label
                  };
                })
              )}
              value={this.state.fields.tipo}
              onChange={e => this.onChangeField(e, "tipo")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*cfdiBase"}
              name={`cfdiBase`}
              type="text"
              id={`cfdiBase`}
              placeholder={"cfdiBase"}
              value={this.state.fields.cfdiBase}
              onChange={e => this.onChangeField(e, "cfdiBase")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*CFDI Impuesto"}
              name={`cfdiImpuesto`}
              type="select"
              id={`cfdiImpuesto`}
              placeholder={"cfdiImpuesto"}
              value={this.state.fields.cfdiImpuesto}
              options={[
                { value: -1, label: "Seleccionar la clave del impuesto" }
              ].concat(
                CatImpuesto.map(({ clave, descripcion }) => {
                  return {
                    value: clave,
                    label: descripcion
                  };
                })
              )}
              onChange={e => this.onChangeField(e, "cfdiImpuesto")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*cfdi Tipo Factor"}
              name={`cfdiTipoFactor`}
              type="select"
              id={`cfdiTipoFactor`}
              placeholder={"cfdiTipoFactor"}
              options={[
                { value: -1, label: "Seleccionar el Tipo factor" }
              ].concat(
                this.props.tipoFactor.map(({ label }) => {
                  return {
                    value: label,
                    label: label
                  };
                })
              )}
              value={this.state.fields.cfdiTipoFactor}
              onChange={e => this.onChangeField(e, "cfdiTipoFactor")}
            />
          </Col>

          <Col sm="5">
            <BaseInput
              disabled={this.props.disabled}
              label={"*cfdiTasaOCuota"}
              name={`cfdiTasaOCuota`}
              type="text"
              id={`cfdiTasaOCuota`}
              placeholder={"cfdiTasaOCuota"}
              value={this.state.fields.cfdiTasaOCuota}
              onChange={e => this.onChangeField(e, "cfdiTasaOCuota")}
            />
          </Col>

          <Col sm="5">
            <BaseInput
              disabled={this.props.disabled}
              label={"*cfdiImporte"}
              name={`cfdiImporte`}
              type="text"
              id={`cfdiImporte`}
              placeholder={"cfdiImporte"}
              value={this.state.fields.cfdiImporte}
              onChange={e => this.onChangeField(e, "cfdiImporte")}
            />
          </Col>
          <Col sm="2">
            <Button
              disabled={!this.state.validData}
              block
              onClick={() => this.addItem()}
              className="mt-4"
              style={{ height: "2rem" }}
            >
              +IMPUESTOS
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <ReactTable
              pages={1}
              data={dataTable}
              columns={itemsTablecolumns}
              showPagination={false}
              sortable={true}
              multiSort={true}
              resizable={true}
              pageSize={this.props.impuestos.length + 3}
            />
          </Col>
        </Row>
      </span>
    );
  }
}

export default withStyles(s)(TaxesData);
