import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import s from "./styles.scss";
import { UncontrolledTooltip } from "reactstrap";
import { userHasPermission } from "../../../utils/accessTree";
import {
  faUser,
  faFileContract,
  faCheckDouble
} from "@fortawesome/free-solid-svg-icons";
import permissionCodes from "../../../utils/permissionCodes";

const { AUTORIZACION_EXPEDIENTES } = permissionCodes;

export const columns = (
  goToMembresia: (row: any) => void,
  openExpediente: (row: any) => void,
  autorizar: (row: any) => void
) => {
  const basicColumns = [
    {
      Header: "#",
      id: "evNumero",
      accessor: simpleAccessor(s.rtNumero, "numero"),
      width: "64",
      className: s.tdclr,
      headerClassName: s.rtNumeroHeader
    },
    {
      Header: "Memb.",
      id: "evMembresia",
      accessor: simpleAccessor(s.rtMembresia, "idPublico"),
      width: "72",
      className: s.tdclr,
      headerClassName: s.rtMembresiaHeader
    },
    {
      Header: "Fecha",
      id: "fecha",
      accessor: dateAccessor(s.rtMembresia, "fechaDesautorizacion"),
      width: "128",
      className: s.tdclr,
      headerClassName: s.rtMembresiaHeader
    },
    {
      Header: "Titular",
      id: "evTitular",
      accessor: simpleAccessor(s.rtTitular, "titular"),
      width: "240",
      className: s.tdclr,
      headerClassName: s.rtTitularHeader
    },
    {
      Header: "Válidos",
      id: "evValidos",
      accessor: simpleAccessor(s.rtValidos, "validos"),
      width: "96",
      className: s.tdclr,
      headerClassName: s.rtValidosHeader
    },
    {
      Header: "Sin validar",
      id: "evSinValidar",
      accessor: simpleAccessor(s.rtSinValidar, "sinValidar"),
      width: "96",
      className: s.tdclr,
      headerClassName: s.rtSinValidarHeader
    },
    {
      Header: "Faltantes",
      id: "evFaltantes",
      accessor: simpleAccessor(s.rtFaltantes, "faltantes"),
      width: "96",
      className: s.tdclr,
      headerClassName: s.rtFaltantesHeader
    },
    {
      Header: "Erróneos",
      id: "evErroneos",
      accessor: simpleAccessor(s.rtErroneos, "erroneos"),
      width: "96",
      className: s.tdclr,
      headerClassName: s.rtErroneosHeader
    },
    {
      Header: "Ver memb.",
      id: "evVerMemb",
      width: "96",
      className: s.tdclr,
      headerClassName: s.rtVerMembHeader,
      accessor: row => {
        const completoClass = row.completo ? ` ${s.completo}` : "";
        const autorizadoClass = row.autorizado ? ` ${s.autorizado}` : "";
        const estatusClass = autorizadoClass || completoClass;

        return (
          <>
            <div
              className={`${s.divclr} ${s.rtVerMemb}${estatusClass}`}
              onClick={() => {
                goToMembresia(row);
              }}
              id={"ver-memb-" + row.idMembresiaSocio}
            >
              <FontAwesomeIcon icon={faUser} />
            </div>
            <UncontrolledTooltip
              placement="bottom"
              target={"ver-memb-" + row.idMembresiaSocio}
            >
              Ver membresía
            </UncontrolledTooltip>
          </>
        );
      }
    },
    {
      Header: "Ver exp.",
      id: "evVerExp",
      width: "96",
      className: s.tdclr,
      headerClassName: s.rtVerExpHeader,
      accessor: row => {
        const completoClass = row.completo ? ` ${s.completo}` : "";
        const autorizadoClass = row.autorizado ? ` ${s.autorizado}` : "";
        const estatusClass = autorizadoClass || completoClass;

        return (
          <>
            <div
              className={`${s.divclr} ${s.rtVerExp}${estatusClass}`}
              onClick={() => {
                openExpediente(row);
              }}
              id={"ver-exp-" + row.idMembresiaSocio}
            >
              <FontAwesomeIcon icon={faFileContract} />
            </div>
            <UncontrolledTooltip
              placement="bottom"
              target={"ver-exp-" + row.idMembresiaSocio}
            >
              Ver expediente
            </UncontrolledTooltip>
          </>
        );
      }
    }
  ];

  if (userHasPermission(AUTORIZACION_EXPEDIENTES)) {
    basicColumns.push({
      Header: "Autorizar",
      id: "evAutorizar",
      width: "96",
      className: s.tdclr,
      headerClassName: s.rtAutorizarHeader,
      accessor: row => {
        const completoClass = row.completo ? ` ${s.completo}` : "";
        const autorizadoClass = row.autorizado ? ` ${s.autorizado}` : "";
        const estatusClass = autorizadoClass || completoClass;

        return (
          <>
            <div
              className={`${s.divclr} ${s.rtAutorizar}${estatusClass}`}
              onClick={() => {
                autorizar(row);
              }}
              id={"autorizar-" + row.idMembresiaSocio}
            >
              <FontAwesomeIcon icon={faCheckDouble} />
            </div>
            <UncontrolledTooltip
              placement="bottom"
              target={"autorizar-" + row.idMembresiaSocio}
            >
              Autorizar
            </UncontrolledTooltip>
          </>
        );
      }
    });
  }

  return basicColumns;
};

function simpleAccessor(className: string, accessor: string) {
  return row => {
    const completoClass = row.completo ? ` ${s.completo}` : "";
    const autorizadoClass = row.autorizado ? ` ${s.autorizado}` : "";
    const estatusClass = autorizadoClass || completoClass;

    return (
      <div className={`${s.divclr} ${className}${estatusClass}`}>
        {row[accessor]}
      </div>
    );
  };
}

function dateAccessor(className: string, accessor: string) {
  return row => {
    const str = row[accessor] || "-";
    const completoClass = row.completo ? ` ${s.completo}` : "";
    const autorizadoClass = row.autorizado ? ` ${s.autorizado}` : "";
    const estatusClass = autorizadoClass || completoClass;

    return (
      <div className={`${s.divclr} ${className}${estatusClass}`}>
        {formatDate(str)}
      </div>
    );
  };
}

function formatDate(isoDate: string): string {
  try {
    const [date, time] = isoDate.split("T");
    const [year, month, day] = date.split("-");

    // Formato de la fecha:
    const fmtDate = `${day}/${month}/${year} `;
    const indexSeconds = time.lastIndexOf(":");
    const fmtTime = indexSeconds >= 4 ? time.substring(0, indexSeconds) : time;

    return fmtDate + fmtTime;
  } catch (error) {
    return isoDate;
  }
}
