import React from "react";
import { Container, TabPane } from "reactstrap";
import BasicSpinner from "../../../../toolkit/basicSpinner";
import MaintenancesDetailTable from "./maintenancesDetailTable";

interface Props {
  maintenancesDetail: any[];
  idMembresiaSocio: string;
  searching: boolean;
  invalidateStateValue: (fieldName: string, value: any) => void;
  getMembershipMaintenancesDetail: () => void;
}

class TabMaintenancesDetailReport extends React.Component<Props> {
  componentDidMount() {}

  render() {
    const { maintenancesDetail } = this.props;
    return (
      <TabPane className="my-2" tabId="21">
        <Container className="p-0">
          {!!this.props.searching && (
            <BasicSpinner
              message={"Consultando cargos de mantenimiento"}
              loading={this.props.searching}
            />
          )}

          {!this.props.searching && (
            <MaintenancesDetailTable
              maintenancesDetailList={maintenancesDetail || []}
              partnerMembershipId={this.props.idMembresiaSocio}
              invalidateStateValue={this.props.invalidateStateValue}
              getMembershipMaintenancesDetail={
                this.props.getMembershipMaintenancesDetail
              }
            />
          )}
        </Container>
      </TabPane>
    );
  }
}

export default TabMaintenancesDetailReport;
