import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Container, Row, Col, Input, Label, Button } from "reactstrap";
import s from "./styles.scss";
import { YearComponent } from "./calendar/YearComponent";
import constants from "../../../utils/constants";
import axios from "axios";
import moment from "moment";
import {
  errorNotification,
  successNotification
} from "../../../utils/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

// TODO: Aplicar patrón Redux (Actualización 1)
const CALENDAR_BASE_URL = "/api/inbox-calendar";

interface Props extends RouteComponentProps {}

interface State {
  // Carga de tipos de calendario remota
  calendarTypesLoading: boolean;
  calendarTypes: {
    calendarioId: number;
    nombre: string;
    tipo: string;
  }[];

  // Carga de calendario seleccionado
  calendarId: number;
  calendarLoading: boolean;
  calendarSaving: boolean;
  calendarView: boolean;

  // Propiedades de la interfaz del calendario
  submitter: any;
  selected: {
    fecha: string;
    tipoAfiliacion: "ORIGINAL" | "ALTERNA";
  }[];
  year: number;
  soportaAlterna: boolean;
}

/**
 * Calendario de cargos para configurar los días de transacciones en tarjetas
 */
class InboxCalendarSelectorPage extends React.Component<Props, State> {
  state = {
    calendarTypesLoading: true,
    calendarTypes: [],
    calendarId: 0,
    calendarLoading: false,
    calendarSaving: false,
    calendarView: false,
    submitter: {} as any,
    selected: [],
    year: moment().year(),
    soportaAlterna: false
  };

  /**
   * Consulta de los tipos de calendarios
   */
  async componentDidMount() {
    let response;
    try {
      response = await axios.get(
        `${constants.BASE_URL}${CALENDAR_BASE_URL}/getCalendarTypes`,
        { withCredentials: true }
      );
    } catch (error) {
      errorNotification(
        "No fue posible consultar los tipos de cargos; recargue la página por favor."
      );
    }

    this.setState({
      calendarTypesLoading: false,
      calendarTypes: response ? response.data : []
    });
  }

  /**
   * Listener del botón "Guardar"
   */
  onClickSave = async () => {
    // Se muestra la animación de carga
    this.setState({
      calendarSaving: true
    });

    // Endpoint de almacenamiento
    const fechas = this.state.submitter.calendar();
    try {
      await axios.post(
        `${constants.BASE_URL}${CALENDAR_BASE_URL}/saveBlockedDates`,
        {
          calendarioId: this.state.calendarId,
          year: this.state.year,
          fechas
        },
        {
          withCredentials: true
        }
      );
      successNotification("Fechas de cargo almacenadas correctamente");
    } catch (error) {
      errorNotification(
        "No se pudieron guardar las fechas del calendario; intente de nuevo por favor"
      );
    }

    // Muestra del calendario de nuevo
    this.setState({
      calendarSaving: false,
      selected: fechas
    });
  };

  /**
   * Listener de selector de tipos de cargo
   */
  onChangePaymentType = async e => {
    // Se deja de visualizar el calendario y si se eligió "Seleccione..." no se hace nada más
    const selection = Number(e.target.value);
    const calendar = this.state.calendarTypes.find(
      c => c.calendarioId == selection
    );
    this.setState({
      calendarId: e.target.value,
      calendarLoading: selection > 0,
      calendarView: false,
      soportaAlterna: !!calendar && calendar.numeroBuzones >= 2
    });

    if (selection > 0) {
      await this.findFechasCargo(selection, this.state.year);
    }
  };

  /**
   * Listener de cambio de año
   */
  onChangeYear = async (year: number) => {
    // Se deja de visualizar el calendario y se cargan los nuevos datos
    this.setState({
      year,
      calendarLoading: true,
      calendarView: false
    });
    await this.findFechasCargo(this.state.calendarId, year);
  };

  /**
   * Consulta remota de fechas seleccionadas en un tipo de calendario de cargos
   */
  async findFechasCargo(idCargo: number, year: number) {
    // Se consulta el estado actual del calendario
    let response;
    try {
      response = await axios.get(
        `${constants.BASE_URL}${CALENDAR_BASE_URL}/all_dates/${idCargo}/${year}`,
        { withCredentials: true }
      );
    } catch (error) {
      errorNotification(
        "No se pudieron consultar las fechas, intente seleccionando otro tipo de cargo"
      );
    }

    this.setState({
      selected: response.data.fechas || [],
      calendarLoading: false,
      calendarView: response ? true : false
    });
  }

  /**
   * Renderizado del calendario, cuando aplica
   */
  renderCalendar() {
    if (this.state.calendarLoading || this.state.calendarSaving) {
      return (
        <div style={{ textAlign: "center" }}>
          Espere un momento por favor <br />
          <span style={{ fontSize: "1.4em" }}>
            <FontAwesomeIcon icon={faSpinner} pulse />
          </span>
        </div>
      );
    } else if (this.state.calendarView) {
      return (
        <div style={{ textAlign: "center" }}>
          <YearComponent
            id={this.state.calendarId + "-" + this.state.year}
            year={this.state.year}
            submitter={this.state.submitter}
            selected={this.state.selected}
            onChangeYear={this.onChangeYear}
            soportaAlterna={this.state.soportaAlterna}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    return (
      <Container>
        <div style={{ margin: "16px 0" }}>
          Seleccione un tipo de cargo y elija las fechas de aplicación
        </div>

        {/* Selector de tipo de cargo (TDD, TDC o AMEX) */}
        <div className="calendar-labels-container">
          <Row>
            <Col xs={5}>
              <Label>Tipo de cargo</Label>
              <Input
                name="calendarId"
                type="select"
                id="calendarId"
                value={this.state.calendarId}
                onChange={e => {
                  this.onChangePaymentType(e);
                }}
                disabled={
                  this.state.calendarTypesLoading || this.state.calendarSaving
                }
              >
                <option key={0} value={0}>
                  {this.state.calendarTypesLoading
                    ? "Espere un momento..."
                    : "Seleccione una opción"}
                </option>
                {this.state.calendarTypes.map(opt => (
                  <option key={opt.calendarioId} value={opt.calendarioId}>
                    {opt.nombre}
                  </option>
                ))}
              </Input>
            </Col>

            <div className="col-7" style={{ paddingTop: "20px" }}>
              <div
                className="sw-calendar date selected afiliacion-original"
                style={{ marginLeft: "16px", pointerEvents: "none" }}
                hidden={!this.state.soportaAlterna}
              >
                &nbsp;
              </div>
              <div
                style={{ display: "inline-block", fontSize: "0.8rem" }}
                hidden={!this.state.soportaAlterna}
              >
                Común
              </div>
              <div
                className="sw-calendar date selected afiliacion-alterna"
                style={{ marginLeft: "16px", pointerEvents: "none" }}
                hidden={!this.state.soportaAlterna}
              >
                &nbsp;
              </div>
              <div
                style={{ display: "inline-block", fontSize: "0.8rem" }}
                hidden={!this.state.soportaAlterna}
              >
                Alterna
              </div>
            </div>

            <div
              className="alert alert-warning font-weight-bold"
              style={{ margin: "16px 0" }}
              hidden={!this.state.soportaAlterna}
            >
              Existen dos afiliaciones de MIT para algunos de los métodos de
              pago, por favor seleccione la común (color naranja), la alterna
              que sirve para reintentos de cobro (color azul) o ambas (ambos
              colores).
            </div>

            <Col xs={12}>
              <hr />
            </Col>
          </Row>
        </div>

        {/* Renderizado del calendario */}
        {this.renderCalendar()}

        {/* Botón "Guardar" */}
        <Row
          hidden={
            this.state.calendarLoading ||
            this.state.calendarSaving ||
            !this.state.calendarView
          }
          className="my-4"
        >
          <hr />
          <Col sm={{ size: "3", offset: 8 }}>
            <Button block onClick={this.onClickSave}>
              Guardar
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter<Props, any>(withStyles(s)(InboxCalendarSelectorPage));
