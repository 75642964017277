import React from "react";
import { Container } from "reactstrap";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import ExpedienteValidatorComponent from "../../components/expedientV2validation";

interface Props {}
interface State {}

export default class ExpedienteValidatorPage extends React.Component<
  Props,
  State
> {
  render() {
    return (
      <main>
        <Helmet title="Expediente Documentos" />
        <Layout>
          <Container>
            <ExpedienteValidatorComponent />
          </Container>
        </Layout>
      </main>
    );
  }
}

// ExpedienteValidatorComponent
