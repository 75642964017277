import React from "react";
import { Col, Container, Row, TabPane } from "reactstrap";
import ReferredCodesDetailTable from "./referredCodesDetailTable";
import ReferredDetailTable from "./referredDetailTable";

interface Props {
  referredCodesDetail: any[];
  referredDetail: any[];
  idMembresiaSocio: string;
  invalidateStateValue: (fieldName: string, value: any) => void;
  createReferredCodes: () => void;
}

class TabReferred extends React.Component<Props> {
  componentDidMount() {}

  render() {
    const {
      referredCodesDetail,
      referredDetail,
      idMembresiaSocio,
      createReferredCodes
    } = this.props;
    return (
      <TabPane className="my-2" tabId="22">
        <Container className="p-0 pt-5">
          <Row>
            <Col xs={12} md={3} lg={3}>
              <ReferredCodesDetailTable
                referredCodesDetailList={referredCodesDetail || []}
                partnerMembershipId={idMembresiaSocio}
                createReferredCodes={createReferredCodes}
              />
            </Col>
            <Col xs={12} md={9} lg={9}>
              <ReferredDetailTable
                referredDetailList={referredDetail || []}
                partnerMembershipId={idMembresiaSocio}
              />
            </Col>
          </Row>
        </Container>
      </TabPane>
    );
  }
}

export default TabReferred;
