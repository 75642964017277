import React from "react";
import { connect } from "react-redux";
import { getAllClubsBasicData } from "../../actions/clubs";
import ClubSelector from "../../components/clubSelector";
import Constants from "../../../utils/constants";
import pullAllBy from "lodash.pullallby";
import { setChildStep } from "../../helpers/stepper-state-comparator";
import forEach from "lodash.foreach";

const { CLUB_SELECTION } = Constants;

interface Props {
  getClubs: () => any;
  clubs: { clubId: string }[];
  onClickSave: (e: object) => any;
  setClubs: (clubs: string[]) => any;
  successfulSave: boolean;
  validClubs: { clubId: string }[];
  alreadyUsed: { clubId: string }[];
}

interface State {
  selectedClubs: object;
  concepto: string;
  empresa: number;
  visibleClubs: object;
  loaded: boolean;
}

const checkCondition = (filter, constant, key, obj) => {
  return filter[key] == constant || obj[key] == filter[key];
};

class ClubSelectorContainer extends React.Component<Props, State> {
  state = {
    selectedClubs: {},
    concepto: CLUB_SELECTION.ALL_CONCEPTOS,
    empresa: CLUB_SELECTION.ALL_EMPRESAS_ID,
    visibleClubs: null,
    loaded: false
  };

  componentDidMount() {
    this.props.getClubs();
    this.props.onClickSave(e => {
      if (e) e.preventDefault();
      const clubs = [];
      this.props.clubs.forEach(club => {
        const { clubId } = club;
        if (this.state.selectedClubs[clubId]) clubs.push(club);
      });

      this.props.setClubs(clubs);
    });
  }

  componentDidUpdate() {
    if (
      this.props.clubs &&
      this.props.clubs.length &&
      !this.state.visibleClubs
    ) {
      const visibleClubs = {};

      this.props.clubs.forEach(club => {
        visibleClubs[club.clubId] = true;
      });

      this.setState(state => ({
        ...state,
        visibleClubs
      }));
    }

    if (
      this.props.validClubs &&
      this.props.validClubs.length &&
      !this.state.loaded
    ) {
      const selectedClubs = {
        ...this.state.selectedClubs
      };

      this.props.validClubs.forEach(club => {
        selectedClubs[club.clubId] = true;
      });
      setChildStep(selectedClubs);
      this.setState(state => ({ ...state, selectedClubs, loaded: true }));
    }
  }

  cleanState = state => {
    const newChildState = {};
    forEach(state, (value, key) => {
      if (value) {
        newChildState[key] = value;
      }
    });

    return newChildState;
  };

  checkClub = (e, id) => {
    const checked = e.target.checked;
    const selectedClubs = {
      ...this.state.selectedClubs,
      [id]: checked
    };

    setChildStep(this.cleanState(selectedClubs));

    this.setState(state => ({
      ...state,
      selectedClubs
    }));
  };

  onChangeFilter = (key, event) => {
    const value = event.target.value;
    const { concepto, empresa, visibleClubs } = this.state;
    const filter = { concepto, empresa };
    filter[key] = value;
    this.props.clubs.forEach(club => {
      visibleClubs[club.clubId] =
        checkCondition(
          filter,
          CLUB_SELECTION.ALL_CONCEPTOS,
          "concepto",
          club
        ) &&
        checkCondition(filter, CLUB_SELECTION.ALL_EMPRESAS_ID, "empresa", club);
    });
    this.setState(state => ({
      ...state,
      [key]: value,
      visibleClubs
    }));
  };

  selectAll = (e, value) => {
    e.preventDefault();
    const selectedClubs = { ...this.state.selectedClubs };
    this.props.clubs.forEach(({ clubId }) => {
      if (this.state.visibleClubs[clubId]) {
        selectedClubs[clubId] = value;
      }
    });

    setChildStep(this.cleanState(selectedClubs));

    this.setState(state => ({
      ...state,
      selectedClubs
    }));
  };

  render() {
    return (
      <ClubSelector
        clubs={pullAllBy(this.props.clubs, this.props.alreadyUsed, "clubId")}
        checkClub={this.checkClub}
        selectAll={this.selectAll}
        selectedClubs={this.state.selectedClubs}
        visibleClubs={this.state.visibleClubs}
        concepto={this.state.concepto}
        empresa={this.state.empresa}
        onChangeFilter={this.onChangeFilter}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getClubs: () => {
    dispatch(getAllClubsBasicData());
  }
});

const mapStateToProps = state => {
  const { clubs } = state;

  return {
    clubs: clubs.allClubsBasicData
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClubSelectorContainer);
