import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import LotesCargosAutomaticosPage from "../../components/lotesCargosAutomaticos";

interface Props {
  userContext: any;
}

class LotesCargosAutomaticos extends React.Component<Props, void> {
  render() {
    return (
      <main>
        <Helmet title="Integrantes por Membresía" />
        <Layout>
          <LotesCargosAutomaticosPage userContext={this.props.userContext} />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = () => {};

const mapStateToProps = state => {
  return {
    userContext: state.user.userContext
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LotesCargosAutomaticos);
