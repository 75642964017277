import React from "react";
import { connect } from "react-redux";
import SaveNextCarousel from "../../components/saveNextCarousel";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import { DOCUMENTS_STEPS } from "../../../steps";

interface Props {
  savedDocument: { id: string };
  history: any;
  match: any;
  location: any;
  index: number;
  component: any;
}

interface State {}

class DocumentsCarousel extends React.Component<Props, State> {
  state = {};

  componentDidMount() {}

  componentDidUpdate() {}

  onClickNext = e => {
    e.preventDefault();
    const params = {
      ...this.props.match.params,
      id: this.props.match.params.id || this.props.savedDocument.id
    };
    this.props.history.push(
      DOCUMENTS_STEPS[this.props.index].getNextStep(params)
    );
  };

  getDocumentId = () =>
    this.props.match.params.id ||
    (this.props.savedDocument && this.props.savedDocument.id);

  render() {
    const id = this.getDocumentId();
    return (
      <main>
        <Helmet title="Nuevo Documento" />
        <Layout>
          <SaveNextCarousel
            steps={DOCUMENTS_STEPS.map(({ title, getHref }) => ({
              title,
              href: id ? getHref(id) : null
            }))}
            onClickNext={this.onClickNext}
            index={this.props.index}
            disableNext={
              !this.props.match.params.id && !this.props.savedDocument
            }
          >
            {DOCUMENTS_STEPS.map(({ component: Component, ...rest }, index) => (
              <Component {...rest} key={index} />
            ))}
          </SaveNextCarousel>
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = () => ({});
const mapStateToProps = state => {
  const { documents } = state;
  return {
    savedDocument: documents.basicDocuments
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentsCarousel);
