import React from "react";
import { Helmet } from "react-helmet";
import ComisionesTycPendientesPage from "../../components/comisionesTycPendientes";
import Layout from "../../components/layout";

interface Props {}

interface State {}

class ComisionesTycPendientes extends React.Component<Props, State> {
  state = {};

  render() {
    return (
      <main>
        <Helmet title="Reporte de comisiones pendientes" />
        <Layout>
          <ComisionesTycPendientesPage />
        </Layout>
      </main>
    );
  }
}

export default ComisionesTycPendientes;
