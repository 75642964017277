import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";
import { QueryArguments } from "../../types-business/QueryArguments";

const COMISSIONS_SCHEMAS_URL = "/api/benefits-schemas";
const GET_COMISSIONS_SCHEMAS_BASE_URL = "/schemas";

export const getIndicators = (): IAction => {
  return {
    type: actionTypes.GET_INDICATORS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${COMISSIONS_SCHEMAS_URL}/indicators`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getSchemaById = (id: string): IAction => {
  return {
    type: actionTypes.GET_SCHEMA_BY_ID,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${COMISSIONS_SCHEMAS_URL}/schemas/${id}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const cleanSchemaById = (): IAction => {
  return {
    type: actionTypes.CLEAN_SCHEMA_BY_ID
  };
};

export const cleanPeticionData = (setData: object): IAction => {
  return {
    type: actionTypes.CLEAN_PETICION_DATA,
    payload: setData
  };
};

export const saveComissionsSchemas = (schema: object): any => {
  return {
    type: actionTypes.CREATE_COMISSIONS_SCHEMA,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${COMISSIONS_SCHEMAS_URL}/schemas`,
        schema,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getSchemas = (params?: QueryArguments): IAction => {
  return {
    type: actionTypes.GET_BENEFITS_SCHEMAS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${COMISSIONS_SCHEMAS_URL}${GET_COMISSIONS_SCHEMAS_BASE_URL}`,
        {
          withCredentials: true,
          params
        }
      )
    }
  };
};

export const savePostsBySchema = (schema: object): any => {
  return {
    type: actionTypes.SAVE_POSTS_BYSCHEMA,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${COMISSIONS_SCHEMAS_URL}/positions`,
        schema,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getPostsBySchema = (idEsquema: string): IAction => {
  return {
    type: actionTypes.GET_POSTS_BYSCHEMA,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${COMISSIONS_SCHEMAS_URL}/positions/${idEsquema}`,
        {
          withCredentials: true
        }
      )
    }
  };
};
