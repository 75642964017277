import React from "react";
import { connect } from "react-redux";
import SearchBill from "./searchBill";
import { getBill } from "../../../actions/billing";

interface Props {
  getBill: (uuid: string) => any;
  bill: object;
  cancelBill: () => any;
  remakeBill: () => any;
  setUUID: (uuid: string) => any;
}

class SearchBillContainer extends React.Component<Props> {
  render() {
    return (
      <SearchBill
        getBill={this.props.getBill}
        bill={this.props.bill}
        cancelBill={this.props.cancelBill}
        remakeBill={this.props.remakeBill}
        setUUID={this.props.setUUID}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getBill: (uuid: string) => {
    return dispatch(getBill(uuid));
  }
});

const mapStateToProps = ({ billing }) => {
  const { bill } = billing;
  return {
    bill
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBillContainer);
