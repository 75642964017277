import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import { Container, Col, Button, Row, Form } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import ReactTable from "react-table";
import s from "./styles.scss";
import IAction from "../../../types/IAction";
import {
  faClipboardList,
  faTimesCircle,
  faPlusCircle,
  faPencilAlt,
  faCheck,
  faShoppingCart
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AsignDealer from "./asignDealer";
import ModalFrame from "../../../toolkit/modalFrame";
import isNil from "lodash.isnil";
import BaseButtonWithIconAndTooltip from "../../../toolkit/baseButtonWithIconAndTooltip";
import { userHasPermission } from "../../../utils/accessTree";
import permissionCodes from "../../../utils/permissionCodes";
import { validateChargeDealer } from "../../actions/dealers";
import { handleRequestError } from "../../../utils/helpers";
import { successNotification } from "../../../utils/notifications";

interface Props {
  clubs: object[];
  dealers: object[];
  getDealers: (clubId: string, name: string) => IAction;
  deleteDealer: (personaId: string, clubId: string) => IAction;
  deleteDealerBoolean: boolean;
  getDealersCharges: (concesionarioId: string, clubId: string) => IAction;
  dealersCharges: object[];
  deleteChargeDealer: (cargoId: string) => IAction;
  deleteChargeDealerBoolean: boolean;
  getSelectedDealerCharge: (cargoId: string) => IAction;
  selectedDealerCharge: object;
  accountingAccounts: object[];
  addDealerConfigCharge: (body: object) => IAction;
  updateDealerConfigCharge: (body: object) => IAction;
  dataUpdate: any;
  dataAddDCC: any;
  history: any;
}

interface State {
  filters: object;
  clubs: object[];
  pagina: boolean;
  selectedClub: object;
  toOpen: boolean;
  createAsign: boolean;
  isOpen: boolean;
  deleteConcesionario: object;
  reloadTable: boolean;
  reloadSecondTable: boolean;
  clickToAction: boolean;
}

class GenerationDealerChargesComponent extends React.Component<Props, State> {
  state = {
    filters: {
      idConcepto: "",
      idClub: "",
      razonSocial: "",
      fechaBusqueda: null
    },
    selectedClub: {
      razonSocial: "",
      club: "",
      id: "",
      clubId: ""
    },
    clubs: [],
    pagina: true,
    toOpen: false,
    isOpen: false,
    createAsign: false,
    deleteConcesionario: {},
    reloadTable: false,
    reloadSecondTable: false,
    clickToAction: false
  };

  componentDidUpdate() {
    if (this.state.reloadTable && this.props.deleteDealerBoolean) {
      const { idClub, razonSocial } = this.state.filters;
      this.props.getDealers(idClub, razonSocial);
      this.setState({ reloadTable: false });
    }

    if (
      (this.state.reloadSecondTable && this.props.deleteChargeDealerBoolean) ||
      (this.state.reloadSecondTable && !isNil(this.props.dataAddDCC)) ||
      (this.state.reloadSecondTable && !isNil(this.props.dataUpdate)) ||
      (!this.state.clickToAction && !isNil(this.props.dataUpdate)) ||
      (!this.state.clickToAction && !isNil(this.props.dataAddDCC))
    ) {
      const { id, clubId } = this.state.selectedClub;
      if (id !== "" && clubId !== "") {
        this.props.getDealersCharges(id, clubId);
      }
      this.setState({
        reloadSecondTable: false,
        toOpen: false,
        clickToAction: true
      });
    }
  }

  columns = [
    {
      Header: () => <p className="font-weight-bold">Club</p>,
      id: 0,
      width: 200,
      accessor: "club",
      className: "text-uppercase"
    },
    {
      Header: () => <p className="font-weight-bold">Razon Social</p>,
      id: 1,
      width: 300,
      accessor: "nombre",
      className: "text-uppercase"
    },
    {
      Header: () => <p className="font-weight-bold">RFC</p>,
      id: 2,
      width: 300,
      accessor: "rfc",
      className: "text-uppercase"
    },
    {
      Header: () => <p className="font-weight-bold">Opciones</p>,
      id: 3,
      width: 220,
      accessor: "opciones",
      Cell: row => {
        return (
          <Container className="p-0">
            <Row className="m-0">
              <Col className="px-1">
                <BaseButtonWithIconAndTooltip
                  id={`editar_club_config-${row.original.clubId}-${row.original.id}`}
                  icon={faClipboardList}
                  tooltipTitle="Editar configuración para club"
                  toolTipPlacement="left"
                  onClick={() => this.onClickEditar(row.original)}
                />
              </Col>
              <Col className="px-1">
                <BaseButtonWithIconAndTooltip
                  id={`eliminar_club_config-${row.original.clubId}-${row.original.id}`}
                  icon={faTimesCircle}
                  tooltipTitle=" Eliminar configuración para club"
                  toolTipPlacement="left"
                  onClick={() => {
                    this.setState({
                      isOpen: true,
                      deleteConcesionario: row.original
                    });
                  }}
                />
              </Col>
              <Col className="px-1">
                <BaseButtonWithIconAndTooltip
                  id={`carrito_concesionario-${row.original.clubId}-${row.original.id}`}
                  icon={faShoppingCart}
                  tooltipTitle=" Ir a carrito"
                  toolTipPlacement="left"
                  onClick={() => {
                    window.open(
                      `/productos/carrito/${row.original.id}`,
                      "_blank"
                    );
                  }}
                />
              </Col>
            </Row>
          </Container>
        );
      }
    }
  ];

  secondColumns = [
    {
      Header: () => <p className="font-weight-bold">Concepto</p>,
      id: 0,
      width: 400,
      accessor: "descripcion",
      className: "text-uppercase"
    },
    {
      Header: () => <p className="font-weight-bold">Tipo de Cargo</p>,
      id: 1,
      width: 150,
      accessor: "tipoCargo",
      className: "text-uppercase text-center"
    },
    {
      Header: () => <p className="font-weight-bold">Día de apliación</p>,
      id: 2,
      width: 150,
      accessor: "diaAplicacion",
      className: "text-center"
    },
    {
      Header: () => <p className="font-weight-bold">Validado</p>,
      id: 2,
      width: 150,
      accessor: "diaAplicacion",
      className: "text-center",
      Cell: row => {
        return <span>{!!row.original.validado ? "SI" : "NO"}</span>;
      }
    },
    {
      Header: () => (
        <div className="w-100 text-center">
          {userHasPermission(
            permissionCodes.CONCESSIONAIRE_CREATE_CHARGE_CONFIG
          ) && (
            <Button
              color="link"
              onClick={() => this.onClickCreate()}
              className={`${s.innerButton} ${s.primaryLink}`}
            >
              <FontAwesomeIcon icon={faPlusCircle} />
            </Button>
          )}
        </div>
      ),
      id: 3,
      width: 230,
      accessor: "opciones",
      className: "text-center",
      Cell: row => {
        return (
          <Container className="p-0">
            <Row className="m-0">
              {userHasPermission(
                permissionCodes.CONCESSIONAIRE_EDIT_CHARGE_CONFIG
              ) && (
                <Col className="px-1">
                  <BaseButtonWithIconAndTooltip
                    id={`modal${row.original.idCargo}`}
                    icon={faPencilAlt}
                    tooltipTitle="Editar configuración de cargo"
                    toolTipPlacement="left"
                    onClick={() => this.onClickModal(row.original.idCargo)}
                  />
                </Col>
              )}
              {userHasPermission(
                permissionCodes.CONCESSIONAIRE_DELETE_CHARGE_CONFIG
              ) && (
                <Col>
                  <BaseButtonWithIconAndTooltip
                    id={`eliminarCobro${row.original.idCargo}`}
                    icon={faTimesCircle}
                    tooltipTitle="Eliminar configuración de cargo"
                    toolTipPlacement="left"
                    onClick={() =>
                      this.onClickEliminarCobro(row.original.idCargo)
                    }
                    confirmModal={{
                      title: "Eliminar configuración de cargo",
                      message: `¿Desea eliminar la configuración del cargo seleccionado?`
                    }}
                  />
                </Col>
              )}
              {userHasPermission(
                permissionCodes.CONCESSIONAIRE_VALIDATE_CHARGE_CONFIG
              ) &&
                !row.original.validado && (
                  <Col className="px-1">
                    <BaseButtonWithIconAndTooltip
                      id={`validateCharge${row.original.idCargo}`}
                      icon={faCheck}
                      tooltipTitle="Validar configuración de cargo"
                      toolTipPlacement="left"
                      onClick={() =>
                        this.onClickValidarCobro(row.original.idCargo)
                      }
                    />
                  </Col>
                )}
            </Row>
          </Container>
        );
      }
    }
  ];

  onClickEditar = id => {
    let updateData = { ...this.state.selectedClub };
    updateData.club = id.club;
    updateData.razonSocial = id.nombre;
    updateData.id = id.id;
    updateData.clubId = id.clubId;
    this.props.getDealersCharges(id.id, id.clubId);
    this.setState({
      pagina: false,
      selectedClub: updateData
    });
  };

  onClickEliminarConcesionario = () => {
    const { deleteConcesionario } = this.state;
    this.props.deleteDealer(deleteConcesionario.id, deleteConcesionario.clubId);
    this.setState({
      isOpen: false,
      deleteConcesionario: {},
      reloadTable: true
    });
  };

  onClickCreate = () => {
    this.setState({ toOpen: true, createAsign: true, clickToAction: true });
  };

  onClickModal = id => {
    this.props.getSelectedDealerCharge(id);
    this.setState({ toOpen: true, createAsign: false, clickToAction: true });
  };

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onClickEliminarCobro = id => {
    this.props.deleteChargeDealer(id);
    this.setState({ reloadSecondTable: true });
  };

  onClickValidarCobro = id => {
    validateChargeDealer(id)
      .then(() => {
        successNotification("Cargo validado correctamente");
        const { id, clubId } = this.state.selectedClub;
        if (id !== "" && clubId !== "") {
          this.props.getDealersCharges(id, clubId);
        }
      })
      .catch(handleRequestError);
    this.setState({ reloadSecondTable: true });
  };

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  getCrm2Clubs = () => {
    return this.props.clubs.filter(club => !!club.migradoCrm2);
  };

  search = e => {
    e.preventDefault();
    const { idClub, razonSocial } = this.state.filters;
    this.props.getDealers(idClub, razonSocial);
    let selectedClub: {
      razonSocial: "";
      club: "";
      id: "";
      clubId: "";
    };
    this.setState({ pagina: true, selectedClub });
  };

  componentWillUnmount() {
    let filters: {
      idConcepto: "";
      idClub: "";
      razonSocial: "";
      fechaBusqueda: null;
    };
    let selectedClub: {
      razonSocial: "";
      club: "";
      id: "";
      clubId: "";
    };
    this.setState({
      filters,
      selectedClub,
      clubs: [],
      pagina: true,
      deleteConcesionario: {}
    });
  }

  render() {
    const { razonSocial, idClub } = this.state.filters;
    const clubId = !isNil(this.state.selectedClub)
      ? this.state.selectedClub.clubId
      : "";
    const { dealers, dealersCharges, selectedDealerCharge } = this.props;
    return (
      <Container className="p-0">
        <AsignDealer
          isNowOpen={this.state.toOpen}
          toggle={() => {
            const toOpen = !this.state.toOpen;
            if (!toOpen) {
            }
            this.setState({ toOpen });
          }}
          createAsign={this.state.createAsign}
          data={selectedDealerCharge}
          accountingAccounts={this.props.accountingAccounts}
          addDealerConfigCharge={this.props.addDealerConfigCharge}
          updateDealerConfigCharge={this.props.updateDealerConfigCharge}
          dataUpdate={this.props.dataUpdate}
          dataAddDCC={this.props.dataAddDCC}
          idClub={clubId}
          concesionarioId={
            this.state.selectedClub ? this.state.selectedClub.id : null
          }
          setSecondTable={() => {
            this.setState({ reloadSecondTable: true });
          }}
        />
        <div className="row mt-3 mb-3">
          <div className="col">
            <p className={`${s.primaryTitle} w-100 m-0`}>
              Configuración cargos concesionario
            </p>
          </div>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault();
            this.search(e);
          }}
        >
          <Row>
            <Col xs={12} md={4} lg={3}>
              <BaseInput
                label="Club"
                name="club"
                id="club"
                type="select"
                placeholder="Seleccionar club"
                value={idClub}
                onChange={this.onChangeFilter("idClub")}
                size={"sm"}
                options={[{ value: "", label: "Seleccionar club" }].concat(
                  this.getCrm2Clubs().map(option => ({
                    value: option.clubId,
                    label: option.nombre
                  }))
                )}
              />
            </Col>
            <Col xs={12} md={4} lg={3}>
              <BaseInput
                label={"Razón Social"}
                name="razonSocial"
                type="text"
                id="razonSocial"
                placeholder="Razón Social"
                value={razonSocial}
                onChange={this.onChangeFilter("razonSocial")}
              />
            </Col>
            <Col xs={12} md={4} lg={3} className="mt-4">
              <Button
                size="sm"
                className={`float-right btn-block`}
                onClick={this.search}
                disabled={idClub.length == 0 && razonSocial.length == 0}
              >
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
        {this.state.pagina ? (
          <ReactTable
            data={dealers ? dealers : []}
            columns={this.columns}
            showPagination={false}
            pageSize={dealers.length || 10}
          />
        ) : null}
        {!this.state.pagina ? (
          <div>
            <div className="row mt-3 mb-3">
              <div className="col">
                <p className={`${s.grayTitle} w-100 m-0`}>
                  {`Razón Social: ${this.state.selectedClub.razonSocial}`}
                </p>
              </div>
              <div className="col">
                <p className={`${s.grayTitle} w-100 m-0`}>
                  {`Club: ${this.state.selectedClub.club}`}
                </p>
              </div>
            </div>
            <ReactTable
              data={dealersCharges ? dealersCharges : []}
              columns={this.secondColumns}
              sortable={false}
              resizable={false}
              showPagination={true}
              pageSize={20}
            />
          </div>
        ) : null}
        <ModalFrame
          title={"Eliminación concesionario"}
          isOpen={this.state.isOpen}
          toggle={this.toggle}
        >
          <div className="container-fluid">
            <p>
              Al eliminar este concesionario del club se borraran todas las
              configuraciones de cargos fijos y variables
            </p>
            <div className="row">
              <div className="col">
                <Button
                  className={`${s.buttonMarginTop} ${s.primaryButton}`}
                  onClick={this.toggle}
                  size="lg"
                  block
                >
                  Cancelar
                </Button>
              </div>
              <div className="col">
                <Button
                  className={`${s.buttonMarginTop} ${s.primaryButton}`}
                  onClick={e => {
                    e.preventDefault();
                    this.onClickEliminarConcesionario();
                  }}
                  size="lg"
                  block
                >
                  Confirmar
                </Button>
              </div>
            </div>
          </div>
        </ModalFrame>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(GenerationDealerChargesComponent));
