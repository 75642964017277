import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import * as catalogActions from "../../actions/catalogs";
import { showDocument } from "../../actions/documents";
import * as reportes from "../../actions/reports";
import Layout from "../../components/layout";
import Bill from "../../components/bill";
import IAction from "../../../types/IAction";
import { getBill } from "../../actions/billing";
import { createCreditNote } from "../../actions/banks";
import { Club } from "../../../types-business/Clubs";
import { getClubsRequest } from "../../actions/catalogs";
import { handleRequestError } from "../../../utils/helpers";

interface Props {
  getAccountingAccounts: () => any;
  toggleBill: (storageId: number) => any;
  searchReportAccountingAccount: (fields: object) => IAction;
  searchReportWayToPay: (fields: object) => IAction;
  downloadReportAccountingAccount: (body: object, callback?: any) => IAction;
  downloadReportWayToPay: (body: object, callback?: any) => IAction;
  accountingAccounts: object[];
  reportAccountingAccount: object[];
  reportWayToPay: object[];
  getBill: (uuid: string) => any;
  billing: object[];
  createCreditNote: (fields: object) => IAction;
  banks: object;
  userContext: { clubSessionId: any };
}

interface State {
  clubs: Club[];
}

class Bills extends React.Component<Props, State> {
  state = {
    tipoReporte: [
      {
        tipoReporteId: "FormaPago",
        tipoReporte: "Forma de pago"
      },
      {
        tipoReporteId: "CuentaContable",
        tipoReporte: "Cuenta contable"
      }
    ],
    clubs: []
  };

  componentDidMount() {
    this.props.getAccountingAccounts();
    this.getClubs();
  }

  getClubs = () => {
    getClubsRequest()
      .then(({ data }) => {
        this.setState({ clubs: (data || []).filter(x => !!x.migradoCrm2) });
      })
      .catch(handleRequestError);
  };

  render() {
    return (
      <main>
        <Helmet title="Bills" />
        <Layout>
          <Bill
            clubs={this.state.clubs || []}
            accountingAccounts={this.props.accountingAccounts}
            tipoReporte={this.state.tipoReporte}
            toggleBill={this.props.toggleBill}
            getAccountingAccount={this.props.searchReportAccountingAccount}
            getWayToPay={this.props.searchReportWayToPay}
            reportAccountingAccount={this.props.reportAccountingAccount}
            reportWayToPay={this.props.reportWayToPay}
            downloadReportAccountingAccount={
              this.props.downloadReportAccountingAccount
            }
            downloadReportWayToPay={this.props.downloadReportWayToPay}
            getBill={this.props.getBill}
            billing={this.props.billing}
            createCreditNote={this.props.createCreditNote}
            banks={this.props.banks}
            userContext={this.props.userContext}
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({ catalogs, reports, billing, banks, user }) => {
  return {
    accountingAccounts: catalogs.accountingAccounts,
    reportAccountingAccount: reports.reportAccountingAccount,
    reportWayToPay: reports.reportWayToPay,
    billing: billing.bill,
    banks,
    userContext: user.userContext
  };
};

const mapDispatchToProps = dispatch => ({
  getAccountingAccounts: () => {
    dispatch(catalogActions.getAccountingAccounts());
  },
  toggleBill: showDocument,
  searchReportAccountingAccount: data => {
    dispatch(reportes.searchReportAccountingAccount(data));
  },
  searchReportWayToPay: data => {
    dispatch(reportes.searchReportWayToPay(data));
  },
  downloadReportAccountingAccount: (body, callback?: any) => {
    dispatch(reportes.downloadReportAccountingAccount(body, callback));
  },
  downloadReportWayToPay: (body, callback?: any) => {
    dispatch(reportes.downloadReportWayToPay(body, callback));
  },
  getBill: (uuid: string) => {
    return dispatch(getBill(uuid));
  },
  createCreditNote: data => {
    dispatch(createCreditNote(data));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Bills);
