import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";

import {
  infoNotification,
  errorNotification,
  successNotification,
  UPDATE_REFERRED_CODES_CLUBS_PENDING,
  UPDATE_REFERRED_CODES_CLUBS_REJECTED,
  UPDATE_REFERRED_CODES_CLUBS_FULFILLED,
  UPDATE_REFERRED_CODES_MAINTENANCES_PENDING,
  UPDATE_REFERRED_CODES_MAINTENANCES_REJECTED,
  UPDATE_REFERRED_CODES_MAINTENANCES_FULFILLED
} from "../../utils/notifications";

interface State {
  maintenances: object[];
  clubs: object[];
}

const initialState: State = {
  maintenances: null,
  clubs: null
};

export default function schedulePatterns(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.UPDATE_REFERRED_CODES_CLUBS}_PENDING`: {
      infoNotification(UPDATE_REFERRED_CODES_CLUBS_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.UPDATE_REFERRED_CODES_CLUBS}_REJECTED`: {
      errorNotification(UPDATE_REFERRED_CODES_CLUBS_REJECTED);
      return {
        ...state
      };
    }
    case `${actionTypes.UPDATE_REFERRED_CODES_CLUBS}_FULFILLED`: {
      successNotification(UPDATE_REFERRED_CODES_CLUBS_FULFILLED);
      return {
        ...state
      };
    }
    case `${actionTypes.GET_REFERRED_CODES_CLUBS}_PENDING`: {
      return {
        ...state,
        clubs: null
      };
    }
    case `${actionTypes.GET_REFERRED_CODES_CLUBS}_REJECTED`: {
      return {
        ...state,
        clubs: null
      };
    }
    case `${actionTypes.GET_REFERRED_CODES_CLUBS}_FULFILLED`: {
      return {
        ...state,
        clubs: action.payload.data
      };
    }
    case `${actionTypes.UPDATE_REFERRED_CODES_MAINTENANCES}_PENDING`: {
      infoNotification(UPDATE_REFERRED_CODES_MAINTENANCES_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.UPDATE_REFERRED_CODES_MAINTENANCES}_REJECTED`: {
      errorNotification(UPDATE_REFERRED_CODES_MAINTENANCES_REJECTED);
      return {
        ...state
      };
    }
    case `${actionTypes.UPDATE_REFERRED_CODES_MAINTENANCES}_FULFILLED`: {
      successNotification(UPDATE_REFERRED_CODES_MAINTENANCES_FULFILLED);
      return {
        ...state
      };
    }
    case `${actionTypes.GET_REFERRED_CODES_MAINTENANCES}_PENDING`: {
      return {
        ...state,
        maintenances: null
      };
    }
    case `${actionTypes.GET_REFERRED_CODES_MAINTENANCES}_REJECTED`: {
      return {
        ...state,
        maintenances: null
      };
    }
    case `${actionTypes.GET_REFERRED_CODES_MAINTENANCES}_FULFILLED`: {
      return {
        ...state,
        maintenances: action.payload.data
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
