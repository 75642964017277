import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Container, Row, Col, Button, Label, Input } from "reactstrap";
import ReactTable from "react-table";
import s from "./styles.scss";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import { withRouter } from "react-router-dom";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalFrame from "../../../../toolkit/modalFrame";
import IAction from "../../../../types/IAction";

interface Props {
  getAccrual: () => IAction;
  updateAccrual: (data: object, idEsquema: string) => IAction;
  listAccrual: object[];
}

interface State {}

class AccrualList extends React.Component<Props, State> {
  state = {
    fields: {
      idEsquema: "",
      descripcion: "",
      nombre: "",
      tipoDevengo: ""
    },
    esActivo: false,
    isOpen: false
  };

  columns = [
    {
      Header: () => <p className="font-weight-bold">Id</p>,
      width: 30,
      id: 0,
      accessor: "clubNombre",
      Cell: row => {
        return <p className={s.initial}> {row.original.id} </p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Nombre</p>,
      id: 0,
      accessor: "clubNombre",
      Cell: row => {
        return <p className={s.initial}> {row.original.nombre} </p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Descripción</p>,
      id: 0,
      accessor: "clubNombre",
      Cell: row => {
        return <p className={s.initial}> {row.original.descripcion} </p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Tipo devengo</p>,
      id: 0,
      accessor: "clubNombre",
      Cell: row => {
        return <p className={s.initial}> {row.original.tipoDevengo} </p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Activo</p>,
      id: 0,
      width: 80,
      accessor: "clubNombre",
      Cell: row => {
        return (
          <p className={s.initial}> {row.original.esActivo ? "Sí" : "No"} </p>
        );
      }
    },
    {
      Header: () => {
        return <p className="font-weight-bold">Opciones</p>;
      },
      id: -300,
      width: 80,
      accessor: "actions",
      Cell: row => {
        return (
          <Container className="p-0">
            <Row className="m-0">
              <Col className="px-1">
                <a
                  id={`accrual${row.original.id}`}
                  onClick={() => {
                    this.updateSchema(row.original);
                  }}
                >
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faPencilAlt}
                  />
                </a>
              </Col>
            </Row>
          </Container>
        );
      }
    }
  ];

  componentDidMount() {
    this.props.getAccrual();
  }

  updateSchema = data => {
    const { esActivo, descripcion, nombre, id, tipoDevengo } = data;
    this.setState({ esActivo });
    this.setState(state => {
      const fields = {
        ...this.state.fields,
        descripcion,
        nombre,
        idEsquema: id,
        tipoDevengo
      };
      return { ...state, fields };
    });
    this.toggleModal();
  };

  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
    this.state.isOpen ? this.props.getAccrual() : null;
  };

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...this.state.fields,
          [fieldName]: value
        };
        return { ...state, fields };
      });
    };
  };

  onChangeCheckActivo = e => {
    const esActivo = e.target.checked;
    this.setState({ esActivo });
  };

  updateSchemaConfirm = () => {
    const { nombre, tipoDevengo, descripcion, idEsquema } = this.state.fields;
    this.props.updateAccrual(
      {
        nombre,
        tipoDevengo,
        descripcion,
        esActivo: this.state.esActivo
      },
      idEsquema
    );
  };

  render() {
    return (
      <Container className="p-0">
        <ModalFrame
          title={`Esquema ${this.state.fields.nombre}`}
          isOpen={this.state.isOpen}
          toggle={this.toggleModal}
          size="lg"
        >
          <Row className={`${s.customDistanciaCheck}`}>
            <Label check>
              <Input
                type="checkbox"
                checked={this.state.esActivo}
                onChange={e => this.onChangeCheckActivo(e)}
              />
              Activo
            </Label>
          </Row>
          <Row className={`${s.customDistancia}`}>
            Descripción
            <textarea
              name="descripcion"
              rows={6}
              id="descripcion"
              value={this.state.fields.descripcion}
              className="form-control"
              onChange={this.onChangeFilter("descripcion")}
            />
          </Row>
          <Row className={`${s.customDistancia}`}>
            <Col xs={6} md={6} lg={6}>
              <Button block color="secondary" onClick={this.toggleModal}>
                Cerrar
              </Button>
            </Col>
            <Col xs={6} md={6} lg={6}>
              <Button
                block
                color="secondary"
                onClick={this.updateSchemaConfirm}
              >
                Editar
              </Button>
            </Col>
          </Row>
        </ModalFrame>
        <div className="row mt-3 mb-3">
          <div className="col">
            <p className={`${s.primaryTitle} w-100 m-0`}>
              Esquemas devengamiento
            </p>
          </div>
        </div>
        <Row className={s.customTable}>
          <Col>
            <ReactTable
              data={this.props.listAccrual ? this.props.listAccrual : []}
              columns={this.columns}
              className="mt-5 -striped -highlight"
              sortable={false}
              resizable={false}
              showPagination={true}
              pageSize={20}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(AccrualList));
