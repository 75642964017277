import {
  GetClasesSinComision,
  PostClasesComisionGenerar
} from "../../reducers/comisionesDeportivaCrm1";

export interface ClaseSinComisionStore
  extends GetClasesSinComision,
    PostClasesComisionGenerar {}

export const claseSinComisionStore: (
  state
) => ClaseSinComisionStore = state => {
  const { comisionesDeportivaCrm1 } = state;

  return {
    clasesSinComision: comisionesDeportivaCrm1.clasesSinComision,
    clasesSinComisionLoading: comisionesDeportivaCrm1.clasesSinComisionLoading,
    clasesSinComisionError: comisionesDeportivaCrm1.clasesSinComisionError,
    clasesComisionGeneradas: comisionesDeportivaCrm1.clasesComisionGeneradas,
    clasesComisionGenerarLoading:
      comisionesDeportivaCrm1.clasesComisionGenerarLoading,
    clasesComisionGenerarError:
      comisionesDeportivaCrm1.clasesComisionGenerarError
  };
};
