import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";

const ANNUITIES_BASE_URL = "/api/annuities";

export const getAnnuitiesRequest = filters => {
  return axios.get(`${constants.BASE_URL}${ANNUITIES_BASE_URL}`, {
    withCredentials: true,
    params: { ...filters }
  });
};

export const getAnnuitiesByMaintenanceAndClubRequest = (
  maintenanceId,
  clubId
) => {
  return axios.get(
    `${constants.BASE_URL}${ANNUITIES_BASE_URL}/by-maintenance-and-club`,
    {
      withCredentials: true,
      params: { mantenimientoId: maintenanceId, clubId }
    }
  );
};

export const getAnnuitiesToAuthorizeRequest = () => {
  return axios.get(`${constants.BASE_URL}${ANNUITIES_BASE_URL}/to-authorize`, {
    withCredentials: true
  });
};

export const getMaintenancesAvailableForAnnuities = membresiaSocioId => {
  return axios.get(
    `${constants.BASE_URL}${ANNUITIES_BASE_URL}/maintenances-available`,
    {
      withCredentials: true,
      params: { membresiaSocioId }
    }
  );
};

export const getAnnuityRequest = (id: string) => {
  return axios.get(`${constants.BASE_URL}${ANNUITIES_BASE_URL}/${id}`, {
    withCredentials: true
  });
};

export const saveAnnuityRequest = (data: object) => {
  return axios.put(`${constants.BASE_URL}${ANNUITIES_BASE_URL}`, data, {
    withCredentials: true
  });
};

export const authorizeAnnuitiesByIds = (ids: any[]) => {
  return axios.put(
    `${constants.BASE_URL}${ANNUITIES_BASE_URL}/authorize-by-ids`,
    ids,
    {
      withCredentials: true
    }
  );
};

export const rejectAnnuitiesByIds = (ids: any[]) => {
  return axios.put(
    `${constants.BASE_URL}${ANNUITIES_BASE_URL}/reject-by-ids`,
    ids,
    {
      withCredentials: true
    }
  );
};

export const cancelAnnuitiesByIds = (ids: any[]) => {
  return axios.put(
    `${constants.BASE_URL}${ANNUITIES_BASE_URL}/cancel-by-ids`,
    ids,
    {
      withCredentials: true
    }
  );
};

export const toggleAddPhaseModal = (): IAction => ({
  type: actionTypes.ADD_PHASE_TOGGLE
});

export const getAnnuityQuote = (params): IAction => {
  return {
    type: actionTypes.GET_ANNUITY_QUOTE,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${ANNUITIES_BASE_URL}/quote`, {
        withCredentials: true,
        params
      })
    }
  };
};

export const getAnnuityQuoteRequest = params => {
  return axios.get(`${constants.BASE_URL}${ANNUITIES_BASE_URL}/quote`, {
    withCredentials: true,
    params
  });
};

export const saveAnnuityQuote = (data: object): IAction => {
  return {
    type: actionTypes.SAVE_ANNUITY_QUOTE,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${ANNUITIES_BASE_URL}/quote`,
        data,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const saveAnnuityQuoteRequest = (data: object) => {
  return axios.post(`${constants.BASE_URL}${ANNUITIES_BASE_URL}/quote`, data, {
    withCredentials: true
  });
};

export const getAnnuityQuoteById = (id): IAction => {
  return {
    type: actionTypes.GET_ANNUITY_QUOTE_BY_ID,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${ANNUITIES_BASE_URL}/quote/${id}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const payAnnuityQuote = (id): IAction => {
  return {
    type: actionTypes.PAY_ANNUITY_QUOTE_BY_ID,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${ANNUITIES_BASE_URL}/quote/${id}`,
        {},
        {
          withCredentials: true
        }
      )
    }
  };
};
