import React from "react";
import IInputBuilder from "../iInputBuilder/IInputBuilder";
import IDate from "../iDate/IDate";
import IDateBuilderProps from "./IDateBuilder.props";
import IDateBuilderState from "./IDateBuilder.state";

export default class IDateBuilder<INP extends string> extends IInputBuilder<
  INP,
  IDateBuilderProps<INP>,
  IDateBuilderState
> {
  render() {
    return <IDate {...(this.props as any)} />;
  }
}
