import IAction from "../../types/IAction";
import axios from "axios";

/**
 * Información de consumo por Axios
 */
export interface SwAxiosConfig {
  // Propiedades de configuración de solicitudes en Axios
  actionType: string;
  urlComplement?: string;
  method: "get" | "post" | "put" | "delete";
  body?: any;
  params?: any;
  data?: FormData;
  headers?: any;
  noCredentials?: boolean;

  // Propiedades para testing
  testing?: boolean;
  fixture?: any;
  reject?: any;
}

/**
 * Construye una función generadora de IAction's para Redux
 * @param urlBase Url base de la que partirán las rutas de consumo
 * @returns Una función que acepta un parámetro de tipo SwAxiosConfig, con la información de consumo
 */
export function restActionFactory(
  urlBase: string
): (config: SwAxiosConfig) => IAction {
  return (config: SwAxiosConfig): IAction => {
    if (config.testing) {
      return testAction(config);
    }

    const url = `${urlBase}${config.urlComplement || ""}`;
    const axiosConfig = {
      withCredentials: !config.noCredentials,
      params: config.params || undefined,
      headers: config.headers || undefined
    };
    const promise =
      config.method === "get" || config.method === "delete"
        ? axios[config.method](url, axiosConfig)
        : axios[config.method](url, config.body || config.data, axiosConfig);

    return {
      type: config.actionType,
      payload: {
        promise
      }
    };
  };
}

function testAction(config: {
  actionType: string;
  fixture?: any;
  reject?: any;
}): IAction {
  console.error("FIXTURE");
  console.error(config);

  return {
    type: config.actionType,
    payload: {
      promise: new Promise((resolve, reject) => {
        setTimeout(() => {
          config.reject
            ? reject({
                response: {
                  data:
                    typeof config.reject === "function"
                      ? config.reject()
                      : config.reject
                }
              })
            : resolve({
                data:
                  typeof config.fixture === "function"
                    ? config.fixture()
                    : config.fixture
              });
        }, 1000);
      })
    }
  };
}
