import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import moment from "moment";
import React from "react";
import ReactTable from "react-table";
import { Container } from "reactstrap";
import BaseButtonWithIconAndTooltip from "../../../../../toolkit/baseButtonWithIconAndTooltip";
import { userHasPermission } from "../../../../../utils/accessTree";
import { handleRequestError } from "../../../../../utils/helpers";
import permissionCodes from "../../../../../utils/permissionCodes";
import { deleteDiscountCode } from "../../../../actions/partners";
import { getClassificationByLevelRequest } from "../../../../actions/products";
import ConfirmModal from "../../../common/confirmModal/confirmModal";
import MexicanCurrency from "../../../common/currency";
import ModalAddProductsDiscounts from "../../modalAddProductsDiscounts";
import s from "../styles.scss";

interface Props {
  fitKidzDiscountsCodesList: any[];
  idMembresiaSocio: string;
  refreshPartner: () => void;
  invalidateStateValue: (fieldName: string, value: any) => void;
  getDiscountCodes: () => any;
}

class FitKidzDiscountCodesTable extends React.Component<Props> {
  state = {
    confirmDeleteDiscountCodeModalIsOpen: false,
    idDiscountCodeToDelete: null,
    modalProductsDiscountsIsOpen: false,
    classifications: []
  };

  componentDidMount() {
    getClassificationByLevelRequest(3)
      .then(({ data }) => {
        this.setState({ classifications: [...data] });
      })
      .catch(handleRequestError);
  }

  columns = [
    {
      Header: "Producto aplicable",
      accessor: "codigoProducto"
    },
    {
      Header: "Club aplicable",
      accessor: "codigoClubNombre"
    },
    {
      Header: "Estatus",
      accessor: "codigoEstatus"
    },
    {
      Header: "Inicio vigencia",
      accessor: "codigoInicioVigencia",
      className: "text-center",
      Cell: ({ value }) => moment(value).format("DD-MM-YYYY")
    },
    {
      Header: "Fin vigencia",
      accessor: "codigoFinVigencia",
      className: "text-center",
      Cell: ({ value }) => moment(value).format("DD-MM-YYYY")
    },
    {
      Header: "Código",
      accessor: "codigoDescuento",
      className: "text-center"
    },
    {
      Header: "Tipo",
      accessor: "codigoTipoDescuento",
      className: "text-center"
    },
    {
      Header: "Descuento",
      accessor: "descuento",
      className: "text-center",
      Cell: row => {
        return row.original.codigoTipoDescuento == "PORCENTAJE" ? (
          <span> {row.original.codigoPorcentajeDescuento} % </span>
        ) : row.original.codigoTipoDescuento == "MONTO" ? (
          <MexicanCurrency quantity={row.original.codigoMontoDescuento} />
        ) : null;
      }
    },
    {
      Header: () => {
        return (
          <div className="text-center">
            <BaseButtonWithIconAndTooltip
              id={`addFitKidzDiscountCode_`}
              icon={faPlus}
              tooltipTitle={"Agregar código de descuento"}
              onClick={this.handleAddDiscountCode}
            />
          </div>
        );
      },
      className: "text-center",
      Cell: row => {
        return userHasPermission(
          permissionCodes.PARTNER_MEMBERSHIP_TAB_DISCOUNT_CODES_DELETE_DISCOUNT_CODE
        ) && row.original.codigoEstatus == "CREADO" ? (
          <div>
            {" "}
            <BaseButtonWithIconAndTooltip
              id={`deleteDiscountCode_${row.original.id}`}
              icon={faTimes}
              tooltipTitle={"Eliminar código de descuento"}
              onClick={() => {
                this.setState({
                  idDiscountCodeToDelete: row.original.id,
                  confirmDeleteDiscountCodeModalIsOpen: true
                });
              }}
            />
          </div>
        ) : null;
      }
    }
  ];

  handleAddDiscountCode = () => {
    this.setState({
      modalProductsDiscountsIsOpen: true
    });
  };

  clearModalProductsDiscounts = () => {
    this.setState({
      modalProductsDiscountsIsOpen: false
    });
  };

  handleDeleteDiscountCode = () => {
    const { idDiscountCodeToDelete: id } = this.state;
    const { idMembresiaSocio } = this.props;

    deleteDiscountCode(idMembresiaSocio, id)
      .then(() => {
        this.setState({
          idDiscountCodeToDelete: null,
          confirmDeleteDiscountCodeModalIsOpen: false
        });

        this.props.invalidateStateValue("fitKidzDiscountsCodesList", []);
        this.props.getDiscountCodes();
      })
      .catch(handleRequestError);
  };

  render() {
    return (
      <Container className="p-0">
        <ConfirmModal
          title="Eliminar código de descuento"
          message="Se eliminara el código de descuento, ¿Desea continuar?"
          isOpen={this.state.confirmDeleteDiscountCodeModalIsOpen}
          requireInput={false}
          ok={this.handleDeleteDiscountCode}
          cancel={() => {
            this.setState({
              idDiscountCodeToDelete: null,
              confirmDeleteDiscountCodeModalIsOpen: false
            });
          }}
        />

        <ModalAddProductsDiscounts
          isOpen={this.state.modalProductsDiscountsIsOpen}
          classifications={this.state.classifications || []}
          partnerMembershipId={this.props.idMembresiaSocio}
          fitkidz={true}
          onOk={() => {
            this.clearModalProductsDiscounts();
            this.props.invalidateStateValue("fitKidzDiscountsCodesList", []);
            this.props.getDiscountCodes();
          }}
          onCancel={() => {
            this.clearModalProductsDiscounts();
          }}
        />

        <p className={`${s.primaryTitle} w-100 m-0 mb-3`}>
          Códigos de descuento
        </p>

        <ReactTable
          data={this.props.fitKidzDiscountsCodesList || []}
          columns={this.columns}
          pageSize={5}
          showPagination={true}
          className="mt-3"
          style={{ fontSize: "0.75rem", width: "100%", maxWidth: "100%" }}
        />
      </Container>
    );
  }
}
export default withStyles(s)(FitKidzDiscountCodesTable);
