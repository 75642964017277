import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";

import {
  infoNotification,
  errorNotification,
  successNotification,
  STORE_PATTERN_PENDING,
  STORE_PATTERN_REJECTED,
  STORE_PATTERN_FULFILLED,
  UPDATE_PATTERN_GROUPS_PENDING,
  UPDATE_PATTERN_GROUPS_REJECTED,
  UPDATE_PATTERN_GROUPS_FULFILLED
} from "../../utils/notifications";

export interface PatronesHorario {
  patterns: any[];
  patternsLoading: boolean;
}

interface State extends PatronesHorario {
  patternById: object;
  patternGroupsMaintenance: object;
}

const initialState: State = {
  patterns: null,
  patternsLoading: false,
  patternById: null,
  patternGroupsMaintenance: null
};

export default function scheduledPatternReducer(
  state: State = initialState,
  action: IAction
): State {
  const snapshot = snapshotAction(state, action);

  // Llamada a resolver de snapshot
  state != snapshot &&
    !action.type.endsWith("_PENDING") &&
    action.meta &&
    action.meta.resolver &&
    action.meta.resolver({
      fulfilled: action.type.endsWith("_FULFILLED"),
      ...snapshot
    });

  return state != snapshot
    ? {
        ...state,
        ...snapshot
      }
    : state;
}

function snapshotAction(
  state: State = initialState,
  action: IAction
): Partial<State> {
  switch (action.type) {
    case `${actionTypes.STORE_PATTERN}_PENDING`: {
      infoNotification(STORE_PATTERN_PENDING);
      return {};
    }
    case `${actionTypes.STORE_PATTERN}_REJECTED`: {
      errorNotification(STORE_PATTERN_REJECTED);
      return {};
    }
    case `${actionTypes.STORE_PATTERN}_FULFILLED`: {
      successNotification(STORE_PATTERN_FULFILLED);
      return {};
    }
    case `${actionTypes.UPDATE_PATTERN_GROUPS}_PENDING`: {
      infoNotification(UPDATE_PATTERN_GROUPS_PENDING);
      return {};
    }
    case `${actionTypes.UPDATE_PATTERN_GROUPS}_REJECTED`: {
      errorNotification(UPDATE_PATTERN_GROUPS_REJECTED);
      return {};
    }
    case `${actionTypes.UPDATE_PATTERN_GROUPS}_FULFILLED`: {
      successNotification(UPDATE_PATTERN_GROUPS_FULFILLED);
      return {};
    }
    case `${actionTypes.GET_PATTERNS}_PENDING`: {
      return {
        patternsLoading: true,
        patterns: null
      };
    }
    case `${actionTypes.GET_PATTERNS}_REJECTED`: {
      return {
        patternsLoading: false,
        patterns: null
      };
    }
    case `${actionTypes.GET_PATTERNS}_FULFILLED`: {
      return {
        patternsLoading: false,
        patterns: action.payload.data
      };
    }
    case `${actionTypes.GET_PATTERN_BY_ID}_PENDING`: {
      return {
        patternById: null
      };
    }
    case `${actionTypes.GET_PATTERN_BY_ID}_REJECTED`: {
      return {
        patternById: null
      };
    }
    case `${actionTypes.GET_PATTERN_BY_ID}_FULFILLED`: {
      return {
        patternById: action.payload.data
      };
    }
    case `${actionTypes.GET_PATTERN_GROUPS_MAINTENANCE}_PENDING`: {
      return {
        patternGroupsMaintenance: null
      };
    }
    case `${actionTypes.GET_PATTERN_GROUPS_MAINTENANCE}_REJECTED`: {
      return {
        patternGroupsMaintenance: null
      };
    }
    case `${actionTypes.GET_PATTERN_GROUPS_MAINTENANCE}_FULFILLED`: {
      return {
        patternGroupsMaintenance: action.payload.data
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
