import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import { getErrorMessage } from "../../utils/helpers";
import {
  infoNotification,
  errorNotification,
  successNotification,
  ADD_SESSION_PENDING,
  ADD_SESSION_SUCCESSFUL,
  ADD_SESSION_ERROR,
  DELETE_SESSION_ERROR,
  DELETE_SESSION_PENDING,
  DELETE_SESSION_SUCCESSFUL,
  BASIC_DATA_PRODUCT_WAITING,
  BASIC_DATA_PRODUCT_ERROR,
  BASIC_DATA_PRODUCT,
  CLUBS_CREATED,
  CLUBS_ERROR,
  CLUBS_WAITING,
  SAVE_PROMOTION_SPORTS_PRODUCT_PENDING,
  SAVE_PROMOTION_SPORTS_PRODUCT_ERROR,
  SAVE_PROMOTION_SPORTS_PRODUCT,
  GET_QUOTATION_ERROR,
  SAVE_SPORTS_PRODUCTS_COMISSIONS_PENDING,
  SAVE_SPORTS_PRODUCTS_COMISSIONS,
  SAVE_SPORTS_PRODUCTS_COMISSIONS_ERROR,
  SAVE_SPORTS_PRODUCTS_PRICES_PENDING,
  SAVE_SPORTS_PRODUCTS_PRICES,
  SAVE_SPORTS_PRODUCTS_PRICES_ERROR,
  SPORTS_PRODUCTS_PAY_QUOTE_PENDING,
  SPORTS_PRODUCTS_PAY_QUOTE_ERROR,
  SPORTS_PRODUCTS_PAY_QUOTE,
  SAVE_QUOTE_SPORTS_PRODUCTS,
  SAVE_QUOTE_SPORTS_PRODUCTS_ERROR,
  SAVE_QUOTE_SPORTS_PRODUCTS_PENDING,
  GET_SPORTS_PROGRAMS_PENDING,
  GET_SPORTS_PROGRAMS_REJECTED,
  DELETE_PRODUCT,
  DELETE_PRODUCT_ERROR,
  ERROR
} from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";
interface State {
  basicDataSaved: boolean;
  basicProductSaved: object;
  authorizeCommissions: object;
  gettingBasicProduct: boolean;
  basicProduct: object;
  gettingProductsError: object;
  gettingProducts: boolean;
  products: object[];
  productGroups: object[];
  groupsSaved: boolean;
  pricesToProduct: object[];
  loadingPricesToProduct: boolean;
  pricesSaved: boolean;
  savingPrices: boolean;
  savingPricesError: boolean;
  generalConfigById: object;
  generalProductById: object;
  promotionsBySportProductId: object[];
  promotionBenefitsById: object[];
  promotionSavedSuccessfully: boolean;
  sportsProductsFiltered: object[];
  quotation: object;
  comissionsBySportsProductId: object;
  comissionsCoordinationBySportsProductId: object;
  comissionsOperationBySportsProductId: object;
  operationComissionsBySportsProductId: any;
  sportProductById: object;
  quoteById: object;
  savingQuotePay: boolean;
  quoteByPersonId: object;
  quoteByMembership: object;
  quoteByFiltersClubAgreement: object;
  gettingProductQuote: boolean;
  cashboxById: object;
  gettingCashbox: boolean;
  events: object[];
  eventDetails: any;
  usersInEvent: object;
  gettingUsersInEvent: boolean;
  newAttendant: boolean;
  deleteAttendant: boolean;
  deleteSession: boolean;
  addSession: boolean;
  editSession: boolean;
  newSessionProductId: number;
  newCart: object;
  cartCreated: boolean;
  productsList: object;
  isPending: boolean;
  amountPending: number;
  gettingSportsProgramsInstances: boolean;
  sportsProgramsInstancesError: object;
  sportsProgramsInstances: object;
  deletingProduct: boolean;
  deletingProductError: object;
}
const initialState: State = {
  basicDataSaved: false,
  authorizeCommissions: null,
  basicProductSaved: null,
  gettingBasicProduct: false,
  basicProduct: null,
  gettingProductsError: null,
  gettingProducts: false,
  products: null,
  productGroups: null,
  groupsSaved: false,
  pricesToProduct: null,
  loadingPricesToProduct: false,
  pricesSaved: false,
  savingPrices: false,
  savingPricesError: false,
  generalConfigById: null,
  generalProductById: null,
  promotionsBySportProductId: null,
  promotionSavedSuccessfully: false,
  sportsProductsFiltered: null,
  quotation: null,
  promotionBenefitsById: null,
  comissionsBySportsProductId: null,
  comissionsCoordinationBySportsProductId: null,
  comissionsOperationBySportsProductId: null,
  sportProductById: null,
  quoteById: null,
  savingQuotePay: false,
  quoteByPersonId: null,
  quoteByMembership: null,
  quoteByFiltersClubAgreement: null,
  gettingProductQuote: false,
  cashboxById: null,
  gettingCashbox: true,
  events: null,
  eventDetails: null,
  usersInEvent: null,
  gettingUsersInEvent: false,
  newAttendant: false,
  deleteAttendant: false,
  deleteSession: false,
  addSession: false,
  editSession: false,
  newSessionProductId: null,
  newCart: null,
  cartCreated: true,
  productsList: [],
  isPending: false,
  amountPending: -1,
  operationComissionsBySportsProductId: [],
  gettingSportsProgramsInstances: false,
  sportsProgramsInstancesError: null,
  sportsProgramsInstances: null,
  deletingProduct: false,
  deletingProductError: null
};

export default function sportsProductsReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.DELETE_PRODUCT}_PENDING`: {
      return {
        ...state,
        deletingProduct: true
      };
    }
    case `${actionTypes.DELETE_PRODUCT}_REJECTED`: {
      errorNotification(readMessageBack(action, DELETE_PRODUCT_ERROR));
      return {
        ...state,
        deletingProductError: action.payload || {},
        deletingProduct: false
      };
    }
    case `${actionTypes.DELETE_PRODUCT}_FULFILLED`: {
      successNotification(DELETE_PRODUCT);
      return {
        ...state,
        deletingProductError: null,
        deletingProduct: false
      };
    }
    case `${actionTypes.GET_CASHBOX_BY_ID}_PENDING`: {
      return {
        ...state,
        cashboxById: null,
        gettingCashbox: true
      };
    }
    case `${actionTypes.GET_CASHBOX_BY_ID}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        cashboxById: null,
        gettingCashbox: false
      };
    }
    case `${actionTypes.GET_CASHBOX_BY_ID}_FULFILLED`: {
      return {
        ...state,
        cashboxById: action.payload.data,
        gettingCashbox: false,
        cartCreated: true
      };
    }
    case `${actionTypes.GET_QUOTE_BY_PERSON_ID}_PENDING`: {
      return {
        ...state,
        quoteByPersonId: null,
        gettingProductQuote: true
      };
    }
    case `${actionTypes.GET_QUOTE_BY_PERSON_ID}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        quoteByPersonId: null,
        gettingProductQuote: false
      };
    }
    case `${actionTypes.GET_QUOTE_BY_PERSON_ID}_FULFILLED`: {
      return {
        ...state,
        quoteByPersonId: action.payload.data,
        gettingProductQuote: false
      };
    }
    case `${actionTypes.GET_QUOTE_BY_MEMBERSHIP}_PENDING`: {
      return {
        ...state,
        quoteByMembership: null,
        gettingProductQuote: true
      };
    }
    case `${actionTypes.GET_QUOTE_BY_MEMBERSHIP}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        quoteByMembership: null,
        gettingProductQuote: false
      };
    }
    case `${actionTypes.GET_QUOTE_BY_MEMBERSHIP}_FULFILLED`: {
      return {
        ...state,
        quoteByMembership: action.payload.data,
        gettingProductQuote: false
      };
    }
    case `${actionTypes.GET_QUOTE_FILTERED}_PENDING`: {
      return {
        ...state,
        quoteByFiltersClubAgreement: null,
        gettingProductQuote: true
      };
    }
    case `${actionTypes.GET_QUOTE_FILTERED}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        quoteByFiltersClubAgreement: null,
        gettingProductQuote: false
      };
    }
    case `${actionTypes.GET_QUOTE_FILTERED}_FULFILLED`: {
      return {
        ...state,
        quoteByFiltersClubAgreement: action.payload.data,
        gettingProductQuote: false
      };
    }
    case `${actionTypes.UPDATE_PROMOTION_SPORTS_PRODUCTS}_PENDING`: {
      infoNotification(SAVE_PROMOTION_SPORTS_PRODUCT_PENDING);
      return {
        ...state,
        promotionSavedSuccessfully: false
      };
    }
    case `${actionTypes.UPDATE_PROMOTION_SPORTS_PRODUCTS}_REJECTED`: {
      errorNotification(
        readMessageBack(action, SAVE_PROMOTION_SPORTS_PRODUCT_ERROR)
      );
      return {
        ...state,
        promotionSavedSuccessfully: false
      };
    }
    case `${actionTypes.UPDATE_PROMOTION_SPORTS_PRODUCTS}_FULFILLED`: {
      successNotification(SAVE_PROMOTION_SPORTS_PRODUCT);
      return {
        ...state,
        promotionSavedSuccessfully: true
      };
    }
    case `${actionTypes.SAVE_PROMOTION_SPORTS_PRODUCTS}_PENDING`: {
      infoNotification(SAVE_PROMOTION_SPORTS_PRODUCT_PENDING);
      return {
        ...state,
        promotionSavedSuccessfully: false
      };
    }
    case `${actionTypes.SAVE_PROMOTION_SPORTS_PRODUCTS}_REJECTED`: {
      errorNotification(
        readMessageBack(action, SAVE_PROMOTION_SPORTS_PRODUCT_ERROR)
      );
      return {
        ...state,
        promotionSavedSuccessfully: false
      };
    }
    case `${actionTypes.SAVE_PROMOTION_SPORTS_PRODUCTS}_FULFILLED`: {
      successNotification(SAVE_PROMOTION_SPORTS_PRODUCT);
      return {
        ...state,
        promotionSavedSuccessfully: true
      };
    }
    case `${actionTypes.GET_PROMOTIONS_SPORTS_PRODUCTS}_PENDING`: {
      return {
        ...state,
        promotionsBySportProductId: null
      };
    }
    case `${actionTypes.GET_PROMOTIONS_SPORTS_PRODUCTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        promotionsBySportProductId: null
      };
    }
    case `${actionTypes.GET_PROMOTIONS_SPORTS_PRODUCTS}_FULFILLED`: {
      return {
        ...state,
        promotionsBySportProductId: action.payload.data
      };
    }
    case `${actionTypes.GET_PROMOTION_BENEFITS_SPORTS_PRODUCTS}_PENDING`: {
      return {
        ...state,
        promotionBenefitsById: null
      };
    }
    case `${actionTypes.GET_PROMOTION_BENEFITS_SPORTS_PRODUCTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        promotionBenefitsById: null
      };
    }
    case `${actionTypes.GET_PROMOTION_BENEFITS_SPORTS_PRODUCTS}_FULFILLED`: {
      return {
        ...state,
        promotionBenefitsById: action.payload.data
      };
    }
    case `${actionTypes.GET_SPORTS_PRODUCTS}_PENDING`: {
      return {
        ...state,
        gettingProductsError: null,
        gettingProducts: true
      };
    }
    case `${actionTypes.GET_SPORTS_PRODUCTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        gettingProductsError: getErrorMessage(action.payload),
        products: null,
        gettingProducts: false
      };
    }
    case `${actionTypes.GET_SPORTS_PRODUCTS}_FULFILLED`: {
      return {
        ...state,
        gettingProductsError: null,
        products: action.payload.data,
        gettingProducts: false
      };
    }
    case `${actionTypes.SAVE_BASIC_SPORTS_PRODUCTS}_PENDING`: {
      infoNotification(BASIC_DATA_PRODUCT_WAITING);
      return {
        ...state,
        basicDataSaved: false,
        basicProductSaved: null
      };
    }
    case `${actionTypes.SAVE_BASIC_SPORTS_PRODUCTS}_REJECTED`: {
      errorNotification(readMessageBack(action, BASIC_DATA_PRODUCT_ERROR));
      return {
        ...state,
        basicProductSaved: null,
        basicDataSaved: false
      };
    }
    case `${actionTypes.SAVE_CONFIG_SPORTS_PRODUCTS}_FULFILLED`: {
      successNotification(BASIC_DATA_PRODUCT);
      return {
        ...state
      };
    }
    case `${actionTypes.SAVE_CONFIG_SPORTS_PRODUCTS}_PENDING`: {
      infoNotification(BASIC_DATA_PRODUCT_WAITING);
      return {
        ...state
      };
    }
    case `${actionTypes.SAVE_CONFIG_SPORTS_PRODUCTS}_REJECTED`: {
      errorNotification(readMessageBack(action, BASIC_DATA_PRODUCT_ERROR));
      return {
        ...state
      };
    }
    case `${actionTypes.SAVE_BASIC_SPORTS_PRODUCTS}_FULFILLED`: {
      successNotification(BASIC_DATA_PRODUCT);
      return {
        ...state,
        basicDataSaved: true,
        basicProductSaved: action.payload.data
      };
    }
    case `${actionTypes.CLEAR_BASICDATASAVED_SPORTS_PRODUCTS}`: {
      return {
        ...state,
        basicDataSaved: false
      };
    }
    case `${actionTypes.GET_CONFIG_SPORTS_PRODUCTS}_PENDING`: {
      return {
        ...state,
        generalConfigById: null,
        generalProductById: null
      };
    }
    case `${actionTypes.GET_CONFIG_SPORTS_PRODUCTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        generalConfigById: null,
        generalProductById: null
      };
    }
    case `${actionTypes.GET_CONFIG_SPORTS_PRODUCTS}_FULFILLED`: {
      return {
        ...state,
        generalConfigById: action.payload.data.configuracion || {},
        generalProductById: action.payload.data.producto
      };
    }
    case `${actionTypes.SAVE_COMISSIONS_SPORTS_PRODUCTS}_PENDING`: {
      infoNotification(SAVE_SPORTS_PRODUCTS_COMISSIONS_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.SAVE_COMISSIONS_SPORTS_PRODUCTS}_REJECTED`: {
      errorNotification(
        readMessageBack(action, SAVE_SPORTS_PRODUCTS_COMISSIONS_ERROR)
      );
      return {
        ...state
      };
    }
    case `${actionTypes.SAVE_COMISSIONS_SPORTS_PRODUCTS}_FULFILLED`: {
      successNotification(SAVE_SPORTS_PRODUCTS_COMISSIONS);
      return {
        ...state
      };
    }
    case `${actionTypes.AUTORIZE_COMISSIONS}_PENDING`: {
      infoNotification(SAVE_SPORTS_PRODUCTS_COMISSIONS_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.AUTORIZE_COMISSIONS}_REJECTED`: {
      errorNotification(
        readMessageBack(action, SAVE_SPORTS_PRODUCTS_COMISSIONS_ERROR)
      );
      return {
        ...state
      };
    }
    case `${actionTypes.AUTORIZE_COMISSIONS}_FULFILLED`: {
      successNotification(SAVE_SPORTS_PRODUCTS_COMISSIONS);
      return {
        ...state
      };
    }
    case `${actionTypes.GET_COMISSIONS_SPORTS_PRODUCTS}_PENDING`: {
      return {
        ...state,
        comissionsBySportsProductId: null
      };
    }
    case `${actionTypes.GET_COMISSIONS_SPORTS_PRODUCTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        comissionsBySportsProductId: null
      };
    }
    case `${actionTypes.GET_COMISSIONS_SPORTS_PRODUCTS}_FULFILLED`: {
      return {
        ...state,
        comissionsBySportsProductId: action.payload.data
      };
    }
    case `${actionTypes.GET_COMISSIONS_SPORTS_PRODUCTS_COORDINATION}_PENDING`: {
      return {
        ...state,
        comissionsCoordinationBySportsProductId: null
      };
    }
    case `${actionTypes.GET_COMISSIONS_SPORTS_PRODUCTS_COORDINATION}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        comissionsCoordinationBySportsProductId: null
      };
    }
    case `${actionTypes.GET_COMISSIONS_SPORTS_PRODUCTS_COORDINATION}_FULFILLED`: {
      return {
        ...state,
        comissionsCoordinationBySportsProductId: action.payload.data
      };
    }
    case `${actionTypes.GET_COMISSIONS_SPORTS_PRODUCTS_OPERATION}_PENDING`: {
      return {
        ...state,
        comissionsOperationBySportsProductId: null
      };
    }
    case `${actionTypes.GET_COMISSIONS_SPORTS_PRODUCTS_OPERATION}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        comissionsOperationBySportsProductId: null
      };
    }
    case `${actionTypes.GET_COMISSIONS_SPORTS_PRODUCTS_OPERATION}_FULFILLED`: {
      return {
        ...state,
        comissionsOperationBySportsProductId: action.payload.data
      };
    }
    case `${actionTypes.GET_OPERATION_COMISSIONS_SPORTS_PRODUCTS}_FULFILLED`: {
      return {
        ...state,
        operationComissionsBySportsProductId: action.payload.data
      };
    }
    case `${actionTypes.SAVE_OPERATION_COMISSIONS_SPORTS_PRODUCTS}_PENDING`: {
      infoNotification(SAVE_SPORTS_PRODUCTS_COMISSIONS_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.SAVE_OPERATION_COMISSIONS_SPORTS_PRODUCTS}_REJECTED`: {
      errorNotification(
        readMessageBack(action, SAVE_SPORTS_PRODUCTS_COMISSIONS_ERROR)
      );
      return {
        ...state
      };
    }
    case `${actionTypes.SAVE_OPERATION_COMISSIONS_SPORTS_PRODUCTS}_FULFILLED`: {
      successNotification(SAVE_SPORTS_PRODUCTS_COMISSIONS);
      return {
        ...state
      };
    }
    case `${actionTypes.GET_BASIC_SPORTS_PRODUCTS}_PENDING`: {
      return {
        ...state,
        gettingBasicProduct: true,
        basicProduct: null
      };
    }

    case `${actionTypes.GET_BASIC_SPORTS_PRODUCTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        gettingBasicProduct: false,
        basicProduct: null
      };
    }

    case `${actionTypes.GET_BASIC_SPORTS_PRODUCTS}_FULFILLED`: {
      return {
        ...state,
        gettingBasicProduct: false,
        basicProduct: action.payload.data
      };
    }
    case `${actionTypes.UPDATE_GROUPS_SPORTS_PRODUCTS}_PENDING`: {
      infoNotification(CLUBS_WAITING);
      return {
        ...state,
        groupsSaved: false
      };
    }
    case `${actionTypes.UPDATE_GROUPS_SPORTS_PRODUCTS}_REJECTED`: {
      errorNotification(readMessageBack(action, CLUBS_ERROR));
      return {
        ...state,
        groupsSaved: false
      };
    }
    case `${actionTypes.UPDATE_GROUPS_SPORTS_PRODUCTS}_FULFILLED`: {
      successNotification(CLUBS_CREATED);
      return {
        ...state,
        groupsSaved: true
      };
    }
    case `${actionTypes.GET_GROUPS_SPORTS_PRODUCTS}_PENDING`: {
      return {
        ...state,
        productGroups: null
      };
    }
    case `${actionTypes.GET_GROUPS_SPORTS_PRODUCTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        productGroups: null
      };
    }
    case `${actionTypes.GET_GROUPS_SPORTS_PRODUCTS}_FULFILLED`: {
      return {
        ...state,
        productGroups: action.payload.data
      };
    }
    case `${actionTypes.GET_PRICES_SPORTS_PRODUCTS}_PENDING`: {
      return {
        ...state,
        pricesToProduct: null,
        loadingPricesToProduct: true
      };
    }
    case `${actionTypes.GET_PRICES_SPORTS_PRODUCTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        pricesToProduct: null,
        loadingPricesToProduct: false
      };
    }
    case `${actionTypes.GET_PRICES_SPORTS_PRODUCTS}_FULFILLED`: {
      return {
        ...state,
        pricesToProduct: action.payload.data,
        loadingPricesToProduct: false
      };
    }
    case `${actionTypes.GET_PRICES_SPORTS_PRODUCTS_BY_DATE}_PENDING`: {
      return {
        ...state,
        pricesToProduct: null,
        loadingPricesToProduct: true
      };
    }
    case `${actionTypes.GET_PRICES_SPORTS_PRODUCTS_BY_DATE}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        pricesToProduct: null,
        loadingPricesToProduct: false
      };
    }
    case `${actionTypes.GET_PRICES_SPORTS_PRODUCTS_BY_DATE}_FULFILLED`: {
      if ((action.payload.data || []).length <= 0) {
        errorNotification("El producto no tiene precios autorizados vigentes.");
      }
      return {
        ...state,
        pricesToProduct: action.payload.data,
        loadingPricesToProduct: false
      };
    }
    case `${actionTypes.ADD_PRICES_SPORTS_PRODUCTS}_PENDING`: {
      infoNotification(SAVE_SPORTS_PRODUCTS_PRICES_PENDING);
      return {
        ...state,
        pricesSaved: false,
        savingPrices: true,
        savingPricesError: false
      };
    }
    case `${actionTypes.ADD_PRICES_SPORTS_PRODUCTS}_REJECTED`: {
      errorNotification(
        readMessageBack(action, SAVE_SPORTS_PRODUCTS_PRICES_ERROR)
      );
      return {
        ...state,
        pricesSaved: false,
        savingPrices: false,
        savingPricesError: true
      };
    }
    case `${actionTypes.ADD_PRICES_SPORTS_PRODUCTS}_FULFILLED`: {
      successNotification(SAVE_SPORTS_PRODUCTS_PRICES);
      return {
        ...state,
        pricesSaved: true,
        savingPrices: false,
        savingPricesError: false
      };
    }
    case `${actionTypes.AUTHORIZE_SPORTS_PRODUCTS_PRICES}_PENDING`: {
      infoNotification(SAVE_SPORTS_PRODUCTS_PRICES_PENDING);
      return {
        ...state,
        pricesSaved: false,
        savingPrices: true,
        savingPricesError: false
      };
    }
    case `${actionTypes.AUTHORIZE_SPORTS_PRODUCTS_PRICES}_REJECTED`: {
      errorNotification(
        readMessageBack(action, SAVE_SPORTS_PRODUCTS_PRICES_ERROR)
      );
      return {
        ...state,
        pricesSaved: false,
        savingPrices: false,
        savingPricesError: true
      };
    }
    case `${actionTypes.AUTHORIZE_SPORTS_PRODUCTS_PRICES}_FULFILLED`: {
      successNotification(SAVE_SPORTS_PRODUCTS_PRICES);
      return {
        ...state,
        pricesSaved: true,
        savingPrices: false,
        savingPricesError: false
      };
    }
    case `${actionTypes.GET_SPORTS_PRODUCTS_BY_FILTERS}_PENDING`: {
      return {
        ...state,
        sportsProductsFiltered: null
      };
    }
    case `${actionTypes.GET_SPORTS_PRODUCTS_BY_FILTERS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        sportsProductsFiltered: null
      };
    }
    case `${actionTypes.GET_SPORTS_PRODUCTS_BY_FILTERS}_FULFILLED`: {
      return {
        ...state,
        sportsProductsFiltered: action.payload.data
      };
    }
    case `${actionTypes.GET_SPORT_PRODUCT_QUOTE}_PENDING`: {
      return {
        ...state,
        quotation: null
      };
    }
    case `${actionTypes.GET_SPORT_PRODUCT_QUOTE}_REJECTED`: {
      errorNotification(readMessageBack(action, GET_QUOTATION_ERROR));
      return {
        ...state,
        quotation: null
      };
    }
    case `${actionTypes.GET_SPORT_PRODUCT_QUOTE}_FULFILLED`: {
      return {
        ...state,
        quotation: action.payload.data
      };
    }
    case `${actionTypes.CLEAN_SPORTS_PRODUCTS}`: {
      return {
        ...state,
        sportsProductsFiltered: null
      };
    }
    case `${actionTypes.CLEAN_PROMOTIONS}`: {
      return {
        ...state,
        promotionsBySportProductId: null
      };
    }
    case `${actionTypes.CLEAN_QUOTATION}`: {
      return {
        ...state,
        quotation: null
      };
    }
    case `${actionTypes.CLEAR_EVENTS}`: {
      return {
        ...state,
        events: null
      };
    }

    case `${actionTypes.GET_SPORT_PRODUCT_BY_ID}_PENDING`: {
      return {
        ...state,
        sportProductById: null
      };
    }
    case `${actionTypes.GET_SPORT_PRODUCT_BY_ID}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        sportProductById: null
      };
    }
    case `${actionTypes.GET_SPORT_PRODUCT_BY_ID}_FULFILLED`: {
      return {
        ...state,
        sportProductById: action.payload.data
      };
    }
    case `${actionTypes.GET_SPORT_PRODUCT_QUOTE_BY_ID}_PENDING`: {
      return {
        ...state,
        quoteById: null
      };
    }
    case `${actionTypes.GET_SPORT_PRODUCT_QUOTE_BY_ID}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        quoteById: null
      };
    }
    case `${actionTypes.GET_SPORT_PRODUCT_QUOTE_BY_ID}_FULFILLED`: {
      return {
        ...state,
        quoteById: action.payload.data
      };
    }
    case `${actionTypes.SPORTS_PRODUCTS_PAY_QUOTE}_PENDING`: {
      infoNotification(SPORTS_PRODUCTS_PAY_QUOTE_PENDING);
      return {
        ...state,
        savingQuotePay: true,
        isPending: false,
        amountPending: 0
      };
    }
    case `${actionTypes.SPORTS_PRODUCTS_PAY_QUOTE}_REJECTED`: {
      errorNotification(
        readMessageBack(action, SPORTS_PRODUCTS_PAY_QUOTE_ERROR)
      );
      return {
        ...state,
        savingQuotePay: false
      };
    }
    case `${actionTypes.SPORTS_PRODUCTS_PAY_QUOTE}_FULFILLED`: {
      successNotification(SPORTS_PRODUCTS_PAY_QUOTE);
      return {
        ...state,
        savingQuotePay: false,
        isPending: false,
        amountPending: 0
      };
    }
    case `${actionTypes.SAVE_QUOTE_SPORTS_PRODUCTS}_PENDING`: {
      infoNotification(SAVE_QUOTE_SPORTS_PRODUCTS_PENDING);
      return {
        ...state,
        savingQuotePay: true
      };
    }
    case `${actionTypes.SAVE_QUOTE_SPORTS_PRODUCTS}_REJECTED`: {
      errorNotification(
        readMessageBack(action, SAVE_QUOTE_SPORTS_PRODUCTS_ERROR)
      );
      return {
        ...state,
        savingQuotePay: false
      };
    }
    case `${actionTypes.SAVE_QUOTE_SPORTS_PRODUCTS}_FULFILLED`: {
      successNotification(SAVE_QUOTE_SPORTS_PRODUCTS);
      return {
        ...state,
        savingQuotePay: false,
        cartCreated: true
      };
    }
    case actionTypes.LOAD_QUOTE_SPORTS_PRODUCTS: {
      infoNotification(SAVE_QUOTE_SPORTS_PRODUCTS_PENDING);
      return {
        ...state,
        newCart: action.payload.fields,
        productsList: action.payload.productsList,
        cartCreated: false
      };
    }
    case actionTypes.PENDING_PAYMENT: {
      return {
        ...state,
        isPending: true,
        amountPending: action.payload
      };
    }
    case `${actionTypes.GET_EVENTS}_PENDING`: {
      return {
        ...state,
        gettingProductsError: null,
        gettingProducts: true,
        events: null
      };
    }
    case `${actionTypes.GET_EVENTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        gettingProductsError: getErrorMessage(action.payload),
        gettingProducts: false
      };
    }
    case `${actionTypes.GET_EVENTS}_FULFILLED`: {
      return {
        ...state,
        gettingProductsError: null,
        events: action.payload.data,
        gettingProducts: false
      };
    }
    case `${actionTypes.GET_EVENT_DETAILS}_PENDING`: {
      return {
        ...state,
        gettingProductsError: null,
        gettingProducts: true
      };
    }
    case `${actionTypes.GET_EVENT_DETAILS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        gettingProductsError: getErrorMessage(action.payload),
        eventDetails: null,
        gettingProducts: false
      };
    }
    case `${actionTypes.GET_EVENT_DETAILS}_FULFILLED`: {
      return {
        ...state,
        gettingProductsError: null,
        eventDetails: action.payload.data,
        gettingProducts: false
      };
    }
    case `${actionTypes.GET_SPORTS_PROGRAMS_INSTANCES}_PENDING`: {
      infoNotification(GET_SPORTS_PROGRAMS_PENDING);
      return {
        ...state,
        gettingSportsProgramsInstances: true,
        sportsProgramsInstancesError: null,
        sportsProgramsInstances: null
      };
    }
    case `${actionTypes.GET_SPORTS_PROGRAMS_INSTANCES}_REJECTED`: {
      errorNotification(readMessageBack(action, GET_SPORTS_PROGRAMS_REJECTED));
      return {
        ...state,
        gettingSportsProgramsInstances: false,
        sportsProgramsInstancesError: getErrorMessage(action.payload),
        sportsProgramsInstances: null
      };
    }
    case `${actionTypes.GET_SPORTS_PROGRAMS_INSTANCES}_FULFILLED`: {
      return {
        ...state,
        gettingSportsProgramsInstances: false,
        sportsProgramsInstancesError: null,
        sportsProgramsInstances: action.payload.data
      };
    }
    case `${actionTypes.GET_USERS_IN_EVENT}_PENDING`: {
      return {
        ...state,
        usersInEvent: null,
        gettingUsersInEvent: true
      };
    }
    case `${actionTypes.GET_USERS_IN_EVENT}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        usersInEvent: null,
        gettingUsersInEvent: false
      };
    }
    case `${actionTypes.GET_USERS_IN_EVENT}_FULFILLED`: {
      return {
        ...state,
        usersInEvent: action.payload.data,
        gettingUsersInEvent: false
      };
    }
    case `${actionTypes.ADD_ATTENDANT_TO_EVENT}_PENDING`: {
      return {
        ...state,
        gettingUsersInEvent: true
      };
    }
    case `${actionTypes.ADD_ATTENDANT_TO_EVENT}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        newAttendant: false,
        gettingUsersInEvent: false
      };
    }
    case `${actionTypes.ADD_ATTENDANT_TO_EVENT}_FULFILLED`: {
      return {
        ...state,
        eventDetails: action.payload.data,
        newAttendant: true,
        gettingUsersInEvent: false
      };
    }

    case `${actionTypes.DELETE_ATTENDANT_TO_EVENT}_PENDING`: {
      return {
        ...state,
        gettingUsersInEvent: true,
        deleteAttendant: true
      };
    }
    case `${actionTypes.DELETE_ATTENDANT_TO_EVENT}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        deleteAttendant: false,
        gettingUsersInEvent: false
      };
    }
    case `${actionTypes.DELETE_ATTENDANT_TO_EVENT}_FULFILLED`: {
      return {
        ...state,
        eventDetails: action.payload.data,
        deleteAttendant: false,
        gettingUsersInEvent: false
      };
    }
    case `${actionTypes.DELETE_SESSION}_PENDING`: {
      infoNotification(DELETE_SESSION_PENDING);
      return {
        ...state,
        deleteSession: true
      };
    }
    case `${actionTypes.DELETE_SESSION}_REJECTED`: {
      errorNotification(readMessageBack(action, DELETE_SESSION_ERROR));
      return {
        ...state,
        deleteSession: false
      };
    }
    case `${actionTypes.DELETE_SESSION}_FULFILLED`: {
      let sesionesRes = state.eventDetails.sesionesRestantes;
      if (sesionesRes + 1 > state.eventDetails.numeroSesiones) {
        sesionesRes = state.eventDetails.numeroSesiones;
      } else {
        sesionesRes = sesionesRes + 1;
      }
      successNotification(DELETE_SESSION_SUCCESSFUL);
      let newClasses = state.eventDetails.clases.filter(
        x => x.id != action.payload.data.id
      );
      const newDetails = {
        ...state.eventDetails,
        sesionesRestantes: sesionesRes,
        clases: newClasses
      };
      return {
        ...state,
        eventDetails: newDetails,
        deleteSession: false
      };
    }
    case `${actionTypes.ADD_SESSION}_PENDING`: {
      infoNotification(ADD_SESSION_PENDING);
      return {
        ...state,
        addSession: true
      };
    }
    case `${actionTypes.ADD_SESSION}_REJECTED`: {
      errorNotification(readMessageBack(action, ADD_SESSION_ERROR));
      return {
        ...state,
        addSession: false
      };
    }
    case `${actionTypes.ADD_SESSION}_FULFILLED`: {
      let sesionesRes = state.eventDetails.sesionesRestantes;
      if (sesionesRes - 1 < 0) {
        sesionesRes = 0;
      } else {
        sesionesRes = sesionesRes - 1;
      }
      successNotification(ADD_SESSION_SUCCESSFUL);
      let newDetails = {
        ...state.eventDetails,
        sesionesRestantes: sesionesRes,
        clases: [...state.eventDetails.clases, action.payload.data]
      };
      return {
        ...state,
        eventDetails: newDetails,
        addSession: false
      };
    }
    case `${actionTypes.EDIT_SESSION}_PENDING`: {
      return {
        ...state,
        editSession: true
      };
    }
    case `${actionTypes.EDIT_SESSION}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        editSession: false
      };
    }
    case `${actionTypes.EDIT_SESSION}_FULFILLED`: {
      if (!state.eventDetails) {
        if (!state.events) {
          return {
            ...state,
            editSession: false,
            events: null
          };
        }

        let temp = state.events;
        const i = temp.findIndex(
          x => x.informacionClase.id == action.payload.data.id
        );
        temp[i].informacionClase = action.payload.data;
        return {
          ...state,
          editSession: false,
          events: temp
        };
      } else {
        let temp = state.eventDetails;
        const i = temp.clases.findIndex(x => x.id == action.payload.data.id);
        temp.clases[i] = action.payload.data;
        return {
          ...state,
          editSession: false,
          eventDetails: temp
        };
      }
    }
    case actionTypes.NEW_SESSION_ID: {
      return {
        ...state,
        newSessionProductId: action.payload
      };
    }
    case actionTypes.PAY_PENDING: {
      return {
        ...state,
        isPending: false,
        amountPending: 0
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
