import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Container, Col, Button, Row, Label } from "reactstrap";
import DatePicker from "../common/DatePicker";
import PagedTable from "../common/PagedTable";
import BaseInput from "../../../toolkit/baseInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { QueryResponse } from "../../../types/QueryResponse";
import {
  getPartnerMembershipsWithAgreementsReportRequest,
  executePartnerMembershipsWithAgreementsReportDownload,
  getAgreementsRequest
} from "../../actions/agreements";
import { getErrorMessage } from "../../../utils/helpers";
import { getAllClubsBasicDataRequest } from "../../actions/clubs";
import {
  infoNotification,
  errorNotification
} from "../../../utils/notifications";
import BaseCsvIconDownloader from "../../../toolkit/baseCsvIconDownloader";

interface Props {
  history: any;
  match: any;
}

interface State {
  partnerMemberships: QueryResponse<object>;
  clubs: any[];
  agreements: any[];
  filters: any;
  searching: boolean;
}

class AgreementsReport extends React.Component<Props, State> {
  state = {
    partnerMemberships: null,
    clubs: [],
    agreements: [],
    searching: false,
    filters: {
      clubId: "",
      convenioId: "",
      fechaDesde: "",
      fechaHasta: "",
      page: "0",
      size: "20"
    }
  };

  onFetchData = ({ pageSize: size, page }) => {
    const flds = { ...this.state.filters, size: size || 20, page: page || 0 };
    this.setState({ filters: flds });
    this.getData(page || 0, size || 20);
  };

  getData = (page, size) => {
    this.setState({ searching: true });
    infoNotification("Consultado reporte de membresías con promoción...");
    getPartnerMembershipsWithAgreementsReportRequest({
      ...this.state.filters,
      size,
      page
    })
      .then(({ data }) => {
        this.setState({ searching: false, partnerMemberships: data });
      })
      .catch(err => {
        errorNotification(
          getErrorMessage(
            err,
            "Ocurrió un error al consultar el reporte de membresías con convenio."
          ).message
        );
        this.setState({ searching: false });
      });
  };

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const filters = {
          ...state.filters,
          [fieldName]: date
        };
        return { ...state, error: {}, filters };
      });
    };
  };

  onChangeFilterFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  componentDidMount() {
    getAllClubsBasicDataRequest().then(({ data: clubs }) => {
      this.setState({ clubs });
    });
    getAgreementsRequest().then(({ data: { content: agreements } }) => {
      this.setState({ agreements });
    });
  }

  render() {
    const columns = [
      { name: "Club", id: "clubNombre" },
      { name: "Membresía", id: "membresiaSocioPublicoId" },
      { name: "Tipo membresía", id: "membresiaNombre" },
      { name: "Tipo mantenimiento", id: "mantenimientoNombre" },
      { name: "Convenio", id: "convenioNombre" },
      { name: "Responsable", id: "convenioResponsable" },
      { name: "Fecha", id: "membresiaSocioFecha" },
      { name: "Estatus", id: "membresiaSocioEstatus" },
      { name: "Titular", id: "membresiaSocioTitularNombre" },
      { name: "Último Mtto", id: "membresiaSocioFechaUltimoMantenimiento" },
      {
        headerActions: []
      }
    ];

    const actions = [
      {
        name: "view",
        label: "Ver membresía",
        f: row => {
          const idClub = row.clubId;
          const idPublico = row.membresiaSocioPublicoId;
          window.open(
            `/membresias-socios?idClub=${idClub}&idPublico=${idPublico}`,
            "_blank"
          );
        }
      }
    ];

    return (
      <Container className="p-0 mt-3">
        <Row>
          <Col>
            <BaseInput
              label="Club"
              name="clubSelector"
              id="clubSelector"
              type="select"
              placeholder="Seleccionar club"
              value={this.state.filters.clubId}
              onChange={this.onChangeFilterFactory("clubId")}
              size={"sm"}
              options={[{ value: "", label: "Seleccione un club" }].concat(
                (this.state.clubs || []).map(option => ({
                  value: option.clubId,
                  label: option.nombre
                }))
              )}
            />
          </Col>
          <Col>
            <BaseInput
              label="Convenio"
              name="agreementSelector"
              id="agreementSelector"
              type="select"
              placeholder="Seleccionar convenio"
              value={this.state.filters.convenioId}
              onChange={this.onChangeFilterFactory("convenioId")}
              size={"sm"}
              options={[{ value: "", label: "Seleccione un convenio" }].concat(
                (this.state.agreements || []).map(option => ({
                  value: option.convenioId,
                  label: option.nombre
                }))
              )}
            />
          </Col>
          <Col>
            <DatePicker
              label="Desde"
              name="fromDate"
              id="fromDate"
              selected={this.state.filters["fechaDesde"]}
              onChange={this.onChangeDate("fechaDesde")}
            />
          </Col>
          <Col>
            <DatePicker
              label="hasta"
              name="toDate"
              id="toDate"
              selected={this.state.filters["fechaHasta"]}
              onChange={this.onChangeDate("fechaHasta")}
            />
          </Col>

          <Col>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <br />
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={() => {
                this.getData(0, 20);
              }}
              disabled={false}
            >
              Buscar
            </Button>
          </Col>
        </Row>

        <BaseCsvIconDownloader
          rowCount={(this.state.partnerMemberships || {}).totalElements || 0}
          executeReportDownload={
            executePartnerMembershipsWithAgreementsReportDownload
          }
          getFilters={() => {
            return { ...this.state.filters };
          }}
        />

        <PagedTable
          data={this.state.partnerMemberships || []}
          columns={columns}
          actions={actions}
          fetchData={this.onFetchData}
          searching={this.state.searching}
        />
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(AgreementsReport));
