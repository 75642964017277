import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import AnnuitiesConfig from "../../components/annuitiesConfig";
import * as annuitiesActions from "../../actions/annuities";
import * as productActions from "../../actions/products";
import * as catalogActions from "../../actions/catalogs";
import * as bankActions from "../../actions/banks";
import { getClassificationByLevel } from "../../actions/products";

interface Props {
  searchMaintenancesByAttributes: (filters: object) => void;
  getAnnuity: (filters: object) => void;
  saveAnnuity: (data: object[]) => void;
  toggleAddPhaseModal: () => void;
  maintenances: object[];
  showAddPhaseModal: boolean;
  annuity: object;
  gettingAnnuity: boolean;
  reloadAnnuity: boolean;
  getMemberByConfiguration: () => void;
  memberConfiguration: object[];
  getClubs: () => void;
  getBanks: (params?) => void;
  clubs: object[];
  banks: object[];
  getClassificationByLevel: (level: number) => any;
}

class AnnuitiesConfigPage extends React.Component<Props> {
  state = {
    classifications: []
  };
  componentDidMount() {
    this.props.getClubs();
    this.props.searchMaintenancesByAttributes({});
    this.props.getBanks({ size: 1000 });

    this.props.getClassificationByLevel(3).then(({ value: { data } }) => {
      this.setState({
        classifications: [...data]
      });
    });
  }

  render() {
    return (
      <main>
        <Helmet title="Annuities" />
        <Layout>
          <AnnuitiesConfig
            maintenances={this.props.maintenances}
            searchMaintenancesByAttributes={
              this.props.searchMaintenancesByAttributes
            }
            getAnnuity={this.props.getAnnuity}
            saveAnnuity={this.props.saveAnnuity}
            showAddPhaseModal={this.props.showAddPhaseModal}
            toggleAddPhaseModal={this.props.toggleAddPhaseModal}
            annuity={this.props.annuity}
            gettingAnnuity={this.props.gettingAnnuity}
            reloadAnnuity={this.props.reloadAnnuity}
            getMemberByConfiguration={this.props.getMemberByConfiguration}
            memberConfiguration={this.props.memberConfiguration}
            clubs={this.props.clubs}
            banks={this.props.banks}
            classifications={this.state.classifications}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  searchMaintenancesByAttributes: (attributes: object) => {
    dispatch(productActions.searchByAttributes(attributes));
  },
  getAnnuity: (filters: object) => {
    dispatch(() => {
      return "";
    });
  },
  saveAnnuity: (data: object[]) => {
    dispatch(() => {
      return "";
    });
  },
  toggleAddPhaseModal: () => {
    dispatch(annuitiesActions.toggleAddPhaseModal());
  },
  getMemberByConfiguration: () => {
    dispatch(productActions.getMemberByConfiguration());
  },
  getClubs: () => {
    dispatch(catalogActions.getClubs());
  },
  getBanks: (params?) => {
    dispatch(bankActions.getBanks(params));
  },
  getClassificationByLevel: (level: number) => {
    return dispatch(getClassificationByLevel(level));
  }
});

const mapStateToProps = state => {
  const { annuities, products, catalogs, banks } = state;
  return {
    showAddPhaseModal: annuities.showAddPhaseModal,
    annuity: annuities.annuity,
    gettingAnnuity: annuities.gettingAnnuity,
    reloadAnnuity: annuities.reloadAnnuity,
    maintenances: products.maintenancesByAttributes,
    memberConfiguration: products.memberConfiguration,
    clubs: catalogs.clubs,
    banks: banks.all
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnuitiesConfigPage);
