import React from "react";
import { Label, Input } from "reactstrap";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./styles.scss";

interface Props {
  label?: string;
  placeholder?: string;
  placeholder2?: string;
  value: string | number | Date;
  value2: string | number | Date;
  onChange: (string: string) => void;
  onChange2: (string: string) => void;
  size: string;
  errors: Array<string>;
  disabled: boolean;
  className?: string;
  uppercase: boolean;
  maxlength: string;

  onCheckedChange: (value: boolean) => void;
  disabledWhenUnchecked: boolean;
  checked: boolean;
}

class BaseInputWithCheck extends React.Component<Props> {
  state = {};
  render() {
    const {
      label,
      value,
      value2,
      placeholder,
      placeholder2,
      children,
      errors,
      disabled,
      size,
      maxlength
    } = this.props;

    const idOne = "textbox_" + Math.floor(Math.random() * 100000 + 1);
    const idTwo = "textbox_" + Math.floor(Math.random() * 100000 + 1);

    return (
      <div>
        <Input
          style={{
            marginLeft: "0px",
            marginTop: "5px",
            display: "inline-block"
          }}
          type="checkbox"
          checked={this.props.checked}
          onChange={e => {
            const checked = e.target.checked;
            if (!!this.props.onCheckedChange) {
              this.props.onCheckedChange(checked);
            }
          }}
        />
        {label && (
          <Label
            className={s.label}
            for={idOne}
            style={{ marginLeft: "20px", display: "inline-block" }}
          >
            {label}:
          </Label>
        )}
        <br />
        <Input
          type="text"
          id={idOne}
          name={idOne}
          size={size || "md"}
          maxLength={maxlength || ""}
          placeholder={placeholder}
          className={`${s.input} px-2 py-1 ${
            !!this.props.uppercase ? "text-uppercase" : ""
          }`}
          value={value || ""}
          disabled={
            disabled ||
            ((this.props.disabledWhenUnchecked || false) &&
              !this.props.checked) ||
            false
          }
          onChange={this.props.onChange}
          onKeyPress={event => {
            if (
              !(event.charCode >= 48 && event.charCode <= 57) ||
              (event.charCode == 8 ||
                event.charCode == 9 ||
                event.charCode == 45 ||
                event.charCode == 46)
            ) {
              event.preventDefault();
            }
          }}
        />
        <br />
        <Input
          type="text"
          name={idTwo}
          id={idTwo}
          size={size || "md"}
          maxLength={maxlength || ""}
          placeholder={placeholder2}
          className={`${s.input} px-2 py-1 ${
            !!this.props.uppercase ? "text-uppercase" : ""
          }`}
          value={value2 || ""}
          disabled={
            disabled ||
            ((this.props.disabledWhenUnchecked || false) &&
              !this.props.checked) ||
            false
          }
          onChange={this.props.onChange2}
          onKeyPress={event => {
            if (
              !(
                event.charCode == 46 ||
                (event.charCode >= 48 && event.charCode <= 57)
              ) ||
              (event.charCode == 8 ||
                event.charCode == 9 ||
                event.charCode == 45)
            ) {
              event.preventDefault();
            }
          }}
        />
        {children}
        {errors &&
          errors.map((message, index) => {
            return (
              <span key={index} className={s.errorSpan}>
                {message}
                <br />
              </span>
            );
          })}
      </div>
    );
  }
}

export default withStyles(s)(BaseInputWithCheck);
