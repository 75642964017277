import React from "react";
import s from "./styles.scss";
import { Styled } from "../../../../toolkit/decorators/Artifact";

@Styled(s)
export default class ColoresControl extends React.Component<{}, {}> {
  render() {
    return (
      <div style={{ textAlign: "right", maxHeight: "80px" }}>
        <div
          style={{
            display: "inline-block",
            background: "#eee",
            border: "1px #999 solid",
            padding: "8px"
          }}
        >
          <table>
            <tbody>
              <tr>
                <td
                  colSpan={5}
                  style={{ textAlign: "center", fontSize: "16px" }}
                >
                  SIGNIFICADO DE COLORES
                </td>
              </tr>
              <tr>
                <td
                  colSpan={3}
                  style={{ textAlign: "center", borderRight: "3px solid #aaa" }}
                >
                  Requeridos para comisión
                </td>
                <td colSpan={2} style={{ textAlign: "center" }}>
                  Solicitados
                </td>
              </tr>
              <tr>
                <td>
                  <div
                    className={`${s.archivo} ${s.noAutorizado} ${s.requerido}`}
                  >
                    <div className={`${s.nombre}`}>No Aut.</div>
                  </div>
                  <div className={`${s.hint}`}>No autorizado</div>
                </td>
                <td>
                  <div
                    className={`${s.archivo} ${s.firmaInvalida} ${s.requerido}`}
                  >
                    <div className={`${s.nombre}`}>Firma</div>
                  </div>
                  <div className={`${s.hint}`}>Firma inválida</div>
                </td>
                <td>
                  <div
                    className={`${s.archivo} ${s.documentoIncorrecto} ${s.requerido}`}
                  >
                    <div className={`${s.nombre}`}>Doc.</div>
                  </div>
                  <div className={`${s.hint}`}>Doc. incorrecto</div>
                </td>
                <td>
                  <div className={`${s.archivo} ${s.firmaInvalida}`}>
                    <div className={`${s.content}`}>Firma</div>
                  </div>
                  <div className={`${s.hint}`}>Firma inválida</div>
                </td>
                <td>
                  <div className={`${s.archivo} ${s.documentoIncorrecto}`}>
                    <div className={`${s.content}`}>Doc.</div>
                  </div>
                  <div className={`${s.hint}`}>Doc. incorrecto</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
