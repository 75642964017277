import React from "react";
import { Container, Row, Col } from "reactstrap";
import { FILTER_TYPES, QUOTATION_TYPE } from "../../../utils/constants";
import MaintenanceSelector from "../../../Selectors/containers/MaintenanceSelector";
import MembershipSelector from "../../../Selectors/containers/MembershipSelector";
import ClubSelector from "../../../Selectors/containers/ClubSelector";
import PromotionSelector from "../../../Selectors/containers/PromotionSelector";
import AgreementSelector from "../../../Selectors/containers/AgreementSelector";
import { FormaPagoSelector } from "../../../Selectors/components/FormaPagoSelector";
import { getEmptyFilters } from "../../utils";
import isNil from "lodash.isnil";
import { isObject } from "lodash";

const onChange = (filters, name, value, quotationUse) => {
  const emptyFilters = getEmptyFilters();
  switch (name) {
    case FILTER_TYPES.CLUB:
      filters = { ...emptyFilters, club: value, tipoCotizacion: quotationUse };
      break;
    case FILTER_TYPES.MEMBERSHIP:
      filters = {
        ...emptyFilters,
        club: filters.club,
        descuento: filters.descuento,
        membresia: value,
        tipoCotizacion: quotationUse
      };
      break;
    case FILTER_TYPES.MAINTENANCE:
      filters = {
        ...emptyFilters,
        club: filters.club,
        membresia: filters.membresia,
        descuento: filters.descuento,
        mantenimiento: value,
        tipoCotizacion: quotationUse,
        clubAlterno: null,
        clubAlternoId: null
      };
      break;
    case FILTER_TYPES.AGREEMENT:
      filters = {
        ...filters,
        convenio: !!value ? value.split("-")[0] : null,
        empresa: !!value ? value.split("-")[1] : null,
        promocion: "",
        tipoCotizacion: quotationUse
      };
      break;
    case FILTER_TYPES.PROMOTION:
      filters = {
        ...filters,
        promocion: !isNil(value) ? value : "",
        tipoCotizacion: quotationUse
      };
      break;
    case FILTER_TYPES.DISCOUNT:
      filters = {
        ...filters,
        descuento: value,
        tipoCotizacion: quotationUse
      };
      break;
    case FILTER_TYPES.CLUB_ALTERNO:
      filters = {
        ...filters,
        clubAlterno: value,
        clubAlternoId: value,
        tipoCotizacion: quotationUse
      };
      break;
    default:
      break;
  }
  return { ...filters };
};

export const QuotationFilters = ({
  filters,
  onChangeFilters,
  disableAgreements,
  disablePromotions,
  disableDiscounts,
  quotationUse,
  partner,
  selectedPromotion,
  selectedMaintenance
}) => (
  <Container className="m-0 p-0">
    {quotationUse == QUOTATION_TYPE.REACTIVACION &&
      (!!partner &&
        !!partner.tieneReactivacionesConPromocionYPeriodoNoCumplido) && (
        <div className="alert alert-warning mt-2 mb-3">
          {`No es posible seleccionar una promoción ya que la membresía socio tiene una reactivación con promoción que no ha cumplido los ${partner.numeroMesesPeriodoReactivacionConPromocion} meses establecidos`}
        </div>
      )}
    <Row className="w-100 p-0 m-0">
      <Col>
        <ClubSelector
          migrado={true}
          value={filters.club}
          onChange={value =>
            onChangeFilters(
              onChange(filters, FILTER_TYPES.CLUB, value, quotationUse)
            )
          }
        />
      </Col>
      <Col>
        <MembershipSelector
          value={filters.membresia}
          disabled={!filters.club}
          onChange={value =>
            onChangeFilters(
              onChange(filters, FILTER_TYPES.MEMBERSHIP, value, quotationUse)
            )
          }
          clubId={filters.club}
        />
      </Col>
    </Row>
    <Row className="w-100 p-0 m-0">
      <Col>
        <MaintenanceSelector
          value={filters.mantenimiento}
          disabled={!filters.membresia}
          membershipId={filters.membresia}
          clubId={filters.club}
          quotationUse={quotationUse}
          onChange={value =>
            onChangeFilters(
              onChange(filters, FILTER_TYPES.MAINTENANCE, value, quotationUse)
            )
          }
        />
      </Col>
      <Col>
        <AgreementSelector
          value={filters.empresa}
          disabled={disableAgreements || !filters.mantenimiento}
          onChange={value =>
            onChangeFilters(
              onChange(filters, FILTER_TYPES.AGREEMENT, value, quotationUse)
            )
          }
          params={{
            clubId: filters.club,
            mantenimientoId: filters.mantenimiento,
            membresiaId: filters.membresia
          }}
        />
      </Col>
    </Row>
    <Row className="w-100 p-0 m-0">
      {(quotationUse == QUOTATION_TYPE.VENTA ||
        (quotationUse == QUOTATION_TYPE.REACTIVACION &&
          (!!partner &&
            !partner.tieneReactivacionesConPromocionYPeriodoNoCumplido))) && (
        <Col xs="6">
          <PromotionSelector
            value={filters.promocion}
            onChange={value => {
              onChangeFilters(
                onChange(filters, FILTER_TYPES.PROMOTION, value, quotationUse)
              );
            }}
            disabled={disablePromotions || !filters.mantenimiento}
            params={filters}
            quotationUse={quotationUse}
          />
        </Col>
      )}
      <Col xs="6">
        <FormaPagoSelector
          value={filters.descuento}
          disabled={disableDiscounts || !filters.membresia}
          onChange={value =>
            onChangeFilters(
              onChange(filters, FILTER_TYPES.DISCOUNT, value, quotationUse)
            )
          }
        />
      </Col>
      {((!!selectedMaintenance && !!selectedMaintenance.biclub) ||
        (!!selectedPromotion && !!selectedPromotion.biclub)) && (
        <Col xs="6">
          <ClubSelector
            label="Club alterno"
            value={filters.clubAlterno}
            onChange={value =>
              onChangeFilters(
                onChange(
                  filters,
                  FILTER_TYPES.CLUB_ALTERNO,
                  value,
                  quotationUse
                )
              )
            }
          />
        </Col>
      )}
    </Row>
  </Container>
);
