import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import ReactTable from "react-table";
import { Button, Col, Container, Row } from "reactstrap";
import ModalFrame from "../../../../toolkit/modalFrame";
import s from "./tableInfoModal.scss";

interface Props {
  isOpen: boolean;
  title: String;
  message: String;
  size: String;
  columns: any[];
  data: any[];
  close: () => void;
}

class TableInfoModal extends React.Component<Props> {
  state = {
    value: null
  };

  render() {
    return (
      <ModalFrame
        title={this.props.title}
        isOpen={this.props.isOpen}
        toggle={() => this.props.close()}
        size={this.props.size || "sm"}
      >
        <Container style={{ width: "90%" }} className="center-block p-0 mb-3">
          <p>{this.props.message}</p>

          <div className="mt-3">
            <ReactTable
              className=" -striped -highlight"
              data={this.props.data || []}
              columns={this.props.columns}
              sortable={false}
              resizable={false}
              showPagination={false}
              pageSize={(this.props.data || []).length + 1}
            />
          </div>

          <Row className="m-0">
            <Col xs="12" md="6"></Col>
            <Col xs="12" md="6">
              <Button
                className={`${s.secundaryButton} mt-3`}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.close();
                }}
                block
              >
                Cerrar
              </Button>
            </Col>
          </Row>
        </Container>
      </ModalFrame>
    );
  }
}

export default withStyles(s)(TableInfoModal);
