import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import PagedTable from "../common/PagedTable";
import { QueryResponse } from "../../../types/QueryResponse";
import { Container, Button, Form } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import { QueryArguments } from "../../../types-business/QueryArguments";

// TODO: REDUX
import axios from "axios";
import constants from "../../../utils/constants";
import {
  errorNotification,
  successNotification
} from "../../../utils/notifications";
import ConfirmModal from "../common/confirmModal/confirmModal";
import { userHasPermission } from "../../../utils/accessTree";
import permissionCodes from "../../../utils/permissionCodes";
import {
  authorizePromotion,
  unauthorizePromotion
} from "../../actions/promotions";
import { handleRequestError } from "../../../utils/helpers";

interface Props {
  promotions: QueryResponse<Promotion>;
  searching: boolean;
  onClickNewPromotion: (promotion) => any;
  reloadPromotions: boolean;
  fetchData: (args: QueryArguments) => void;
  history: any;
}

interface Promotion {
  id: string;
  nombre: string;
}

class Promotions extends React.Component<Props> {
  state = {
    nombrePromocion: null,
    tipoUsoPromocion: null,
    modalEliminar: false,
    promocionEliminar: null,
    soloVigentes: true,
    pageSize: 20,
    page: 0
  };

  fetchData = () => {
    const {
      nombrePromocion: nombre,
      tipoUsoPromocion: tipoUso,
      page,
      pageSize,
      soloVigentes
    } = this.state;

    var obj = {
      pageSize,
      page,
      nombre: nombre || null,
      tipoUso: tipoUso || null,
      soloVigentes
    } as any;

    this.props.fetchData(obj);
  };

  handleEditPromotion = promotion => {
    this.props.onClickNewPromotion(promotion);
  };

  handleDeletePromotion = async promotion => {
    this.setState({
      modalEliminar: true,
      promocionEliminar: promotion
    });
  };

  handleAuthorizePromotion = async promotion => {
    const promise = !!promotion.autorizada
      ? unauthorizePromotion(promotion.id)
      : authorizePromotion(promotion.id);
    promise
      .then(() => {
        this.fetchData();
      })
      .catch(handleRequestError);
  };

  clearDeleteModal = () => {
    this.setState({
      promocionEliminar: null,
      modalEliminar: false
    });
  };

  confirmDeletePromotion = async () => {
    const id = this.state.promocionEliminar.id;

    try {
      await axios.delete(`${constants.BASE_URL}/api/promotions/${id}`, {
        withCredentials: true
      });

      successNotification("Promoción eliminada correctamente");
      this.clearDeleteModal();
      this.fetchData();
    } catch (e) {
      errorNotification("No fue posible eliminar la promoción");
    }
  };

  render() {
    const columns = [
      { name: "Nombre", id: "nombre" },
      {
        name: "Inicio de vigencia",
        id: "inicioVigencia",
        className: "text-center"
      },
      { name: "Fin de vigencia", id: "finVigencia", className: "text-center" },
      { name: "Tipo", id: "tipoUsoPromocion", className: "text-center" },
      {
        name: "ESTATUS AUTORIZACION",
        id: "autorizada",
        className: "text-center"
      },
      {
        headerActions: [{ name: "add", f: this.props.onClickNewPromotion }]
      }
    ];

    const actions = [
      { name: "edit", f: this.handleEditPromotion },
      { name: "delete", f: this.handleDeletePromotion }
    ];

    if (userHasPermission(permissionCodes.PROMOTIONS_AUTHORIZE_PERMISSION)) {
      actions.push({ name: "authorize", f: this.handleAuthorizePromotion });
    }

    return (
      <Container className="p-0 mt-3">
        <Form
          onSubmit={async e => {
            e.preventDefault();
            await this.setState({ pageSize: 20, page: 0 });
            this.fetchData();
          }}
        >
          <div className="row mb-2">
            <div className="col-3">
              <BaseInput
                type="text"
                id="nameFilter"
                name="nameFilter"
                label="Nombre"
                placeholder="Nombre"
                value={this.state.nombrePromocion}
                onChange={e => {
                  this.setState({ nombrePromocion: e.target.value });
                }}
              />
            </div>
            <div className="col-3">
              <BaseInput
                type="select"
                id="tipoFilter"
                name="tipoFilter"
                label={"Tipo promoción"}
                value={this.state.tipoUsoPromocion}
                options={[
                  { value: "", label: "Seleccione un Tipo" },
                  ...["VENTA", "REACTIVACION"].map(option => ({
                    value: option,
                    label: option
                  }))
                ]}
                onChange={e => {
                  this.setState({
                    tipoUsoPromocion: e.target.value
                  });
                }}
              />
            </div>
            <div className="col-3">
              <BaseInput
                type="select"
                id="soloActivosFilter"
                name="soloActivosFilter"
                label={"Solo activos"}
                value={this.state.soloVigentes}
                options={[
                  { value: true, label: "SI" },
                  { value: false, label: "NO" }
                ]}
                onChange={e => {
                  this.setState({
                    soloVigentes: e.target.value
                  });
                }}
              />
            </div>

            <div className="col-3 pt-3">
              <Button
                block
                className={`${s.searchButton} ${s.primaryButton}`}
                type="submit"
              >
                Buscar
              </Button>
            </div>
          </div>
        </Form>
        <PagedTable
          data={this.props.promotions}
          columns={columns}
          actions={actions}
          fetchData={async obj => {
            await this.setState({ ...obj });
            this.fetchData();
          }}
          searching={this.props.searching}
        />

        <ConfirmModal
          title="Eliminar promoción"
          message="Confirme la eliminación de la promoción"
          isOpen={this.state.modalEliminar}
          ok={() => {
            this.confirmDeletePromotion();
          }}
          cancel={() => {
            this.setState({
              promocionEliminar: null,
              modalEliminar: false
            });
          }}
        />
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(Promotions));
