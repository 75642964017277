import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import { Table } from "reactstrap";
import s from "./styles.scss";

interface Props {
  messages: any[];
}

class SimpleTableNotificationsMessages extends React.Component<Props> {
  getRows = () =>
    (this.props.messages || []).map((mensaje, index) => (
      <tr key={`messageRow-${index}`}>
        <td>
          {mensaje.tipo === "success" ? (
            <p className={`${s.success} text-center`}>Realizado</p>
          ) : mensaje.tipo === "warning" ? (
            <p className={`${s.warning} text-center`}>Nota</p>
          ) : mensaje.tipo === "error" ? (
            <p className={`${s.error} text-center`}>Error</p>
          ) : (
            <p>{mensaje.tipo}</p>
          )}
        </td>
        <td>
          <p>{mensaje.mensaje}</p>
        </td>
      </tr>
    ));

  render() {
    return (
      <div>
        <Table bordered hover style={{ width: "100%" }}>
          <thead>
            <tr>
              <th width="100px">Tipo</th>
              <th>Mensaje</th>
            </tr>
          </thead>
          <tbody>{this.getRows()}</tbody>
        </Table>
      </div>
    );
  }
}

export default withRouter(withStyles(s)(SimpleTableNotificationsMessages));
