import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Clubs from "../../components/clubs";
import { Club } from "../../../types-business/Clubs";
import * as clubActions from "../../actions/clubs";
import * as modalsActions from "../../actions/modals";
import * as accountActions from "../../actions/profile";
import * as catalogActions from "../../actions/catalogs";
import { QueryResponse } from "../../../types/QueryResponse";
import { QueryArguments } from "../../../types-business/QueryArguments";
import CONSTANTS from "../../../utils/constants";

interface Props {
  clubs: QueryResponse<Club>;
  getClubs: (args?: QueryArguments) => any;
  getUsers: () => any;
  getGeneralManagers: () => any;
  searching: boolean;
  toggleCreateClubModal: (club) => any;
  getCatalogClubs: () => void;
  reloadClubs: boolean;
}

const CLUB_SEARCH_STATUS = "TODOS";

class ClubsPage extends React.Component<Props> {
  state = {
    filters: {
      size: CONSTANTS.DEFAULT_PAGE_SIZE,
      page: 0,
      estatus: CLUB_SEARCH_STATUS
    }
  };

  componentDidMount() {
    this.props.getUsers();
    this.props.getCatalogClubs();
  }

  componentDidUpdate() {
    if (this.props.reloadClubs) {
      this.props.getClubs(this.state.filters);
    }
  }

  onFetchData = ({ pageSize: size, page }) => {
    const { estatus } = this.state.filters;
    this.props.getClubs({ size, page, estatus });
    this.setState(state => {
      return {
        ...state,
        filters: {
          size,
          page,
          estatus
        }
      };
    });
  };

  render() {
    return (
      <main>
        <Helmet title="Clubs" />
        <Layout>
          <Clubs
            clubs={this.props.clubs}
            fetchData={this.onFetchData}
            searching={this.props.searching}
            onClickNewClub={this.props.toggleCreateClubModal}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getClubs: (args?: QueryArguments) => {
    dispatch(clubActions.getClubs(args));
  },
  getUsers: () => {
    dispatch(accountActions.getUsers());
  },
  getGeneralManagers: () => {
    dispatch(accountActions.getGeneralManagers());
  },
  toggleCreateClubModal: club => {
    dispatch(modalsActions.toggleCreateClubModal(club));
  },
  getCatalogClubs: () => {
    dispatch(catalogActions.getClubs());
  }
});

const mapStateToProps = state => {
  const { clubs } = state;
  return {
    clubs: clubs.clubs || { data: [], totalPages: 0 },
    searching: clubs.gettingClubs,
    reloadClubs: clubs.reloadClubs
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClubsPage);
