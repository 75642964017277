import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import Reform from "@franleplant/reform";
import { Container } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import s from "./styles.scss";
import { FormErrors } from "@franleplant/reform/types";
import { setChildStep } from "../../helpers/stepper-state-comparator";
import SelectFilter from "../common/SelectFilter";

const MIN_LENGHT = 3;

interface Props {
  onClickSave: (e: object) => any;
  history: any;
  client: any;
  saveBasicUser: (user: object) => any;
  updateBasicUser: (personaId: string, user: object) => any;
  match: any;
  user: User;
  getBasicUser: (id: string) => any;
  domains: Domain[];
  gettingUserById: boolean;

  // Catálogo de puestos de trabajo
  positions;
}

interface Fields {
  username: string;
  password: string;
  passwordConfirm: string;
  name: string;
  firstSurname: string;
  secondSurname: string;
  employeeNumber: string;
  domain: string;
  ldapDn: string;
  email: string;
  position: any;
}

interface Domain {
  domainCode: string;
  domainName: string;
}

interface User {
  username: string;
  password: string;
  name: string;
  firstSurname: string;
  secondSurname: string;
  employeeNumber: string;
  domain: string;
  ldapDn: string;
  email: string;
}

interface State {
  errors: FormErrors;
  fields: Fields;
  infoLoaded: boolean;
  positions: {
    label: string;
    value: string;
  }[];
  position: {
    label: string;
    value: string;
  };
}

class BasicDataUserForm extends React.Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);

  state = {
    fields: {
      username: "",
      password: "",
      passwordConfirm: "",
      name: "",
      firstSurname: "",
      secondSurname: "",
      email: "",
      employeeNumber: "",
      domain: "sw_domain",
      ldapDn: "",
      position: null
    },
    infoLoaded: false,
    errors: {},
    positions: [],
    position: null
  };

  userToFields = ({
    username,
    name,
    firstSurname,
    secondSurname,
    employeeNumber,
    domain,
    ldapDn,
    email,
    userPositions
  }) => {
    const position =
      userPositions && userPositions.length ? userPositions[0] : null;

    if (this.state.position) {
      this.setState({
        position: this.state.position.find(p => p.value == position)
      });
    }

    return {
      ...this.state.fields,
      username: username ? username : "",
      name: name ? name : "",
      firstSurname: firstSurname ? firstSurname : "",
      secondSurname: secondSurname ? secondSurname : "",
      employeeNumber: employeeNumber ? employeeNumber : "",
      domain: domain ? domain : "",
      ldapDn: ldapDn ? ldapDn : "",
      email,
      position
    };
  };

  initialLoad = () => {
    const { user } = this.props;

    if (user && !this.state.infoLoaded && this.props.match.params.personaId) {
      const fields = this.userToFields(user);
      setChildStep(fields);
      this.setState(state => ({
        ...state,
        fields,
        infoLoaded: true
      }));
    }
  };

  componentDidMount() {
    this.initialLoad();
    this.props.onClickSave(e => {
      e.preventDefault();

      if (this.re.validateFormFromState()) {
        const { personaId } = this.props.match.params;
        const user = {
          ...this.state.fields,
          userPositions: [this.state.fields.position],
          employeeNumber: parseInt(this.state.fields.employeeNumber),
          status: "ACTIVE"
        };

        if (!personaId) {
          this.props.saveBasicUser({ ...user }).then(({ value: { data } }) => {
            this.props.history.push(`/usuarios/datos-basicos/${data.userId}`);
          });
        } else {
          this.props.updateBasicUser(personaId, user);
        }
      }
    });
  }

  processed = false;

  componentDidUpdate(prevProps) {
    this.initialLoad();

    if (prevProps.positions != this.props.positions && this.props.positions) {
      const positions = this.props.positions.map(p => ({
        label: p.name,
        value: p.positionId
      }));

      this.setState({
        positions,
        position:
          this.state.fields && this.state.fields.position
            ? positions.find(p => p.value == this.state.fields.position)
            : null
      });
    }
  }

  validationRules = {
    name: { required: true },
    firstSurname: { required: true },
    secondSurname: { required: true },
    username: { required: true },
    password: {
      passwordValidation: value => this.validatePassword(value),
      minLength: MIN_LENGHT,
      maxLength: 16,
      createValidation: value => this.validateCreate(value)
    },
    passwordConfirm: {
      passwordShouldMatch: value => value !== this.state.fields.password
    },
    employeeNumber: { required: true },
    domain: { required: true },
    email: { required: true },
    position: { required: true }
  };

  validationMessages = {
    required: _ => `Obligatorio`,
    minLength: ruleValue => `Debe tener al menos ${ruleValue} caracteres`,
    maxLength: ruleValue => `Deber tener maximo ${ruleValue} caracteres`,
    default: _ => `Inválido`,
    passwordShouldMatch: _ => `Las contraseñas no coinciden`,
    passwordValidation: _ =>
      `Debe contener al menos una mayuscula, una minuscula y un número`,
    numericalSequenceValidation: _ =>
      `No debes tener sequencia de más de 4 números consecutivos`,
    createValidation: _ =>
      `Para crear un usuario la constraseña es obligatorio`,
    email: _ => "Correo electronico invalido"
  };

  validateCreate = value =>
    this.props.user || value.length > 0 ? false : true;

  validatePassword = value => {
    if (value.length >= MIN_LENGHT) {
      const exp = /^(?=(?:.*[A-Z]){1})(?=(?:.*[a-z]){1})(?=(?:.*[0-9]){1})\S/;
      return exp.exec(value) == null ? true : false;
    } else {
      return false;
    }
  };

  onChangeFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };
        setChildStep(fields);
        return { ...state, fields, errors: {} };
      });
      this.re.validateField(fieldName, value);
    };
  };

  onChangePassword = fieldName => {
    return event => {
      const value = event.target.value.replace(/\s/gm, "");
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };
        setChildStep(fields);
        return { ...state, fields, errors: {} };
      });
      this.re.validateField(fieldName, value);
    };
  };

  render() {
    return (
      <Container className="p-0" fluid>
        <div className="row mb-3">
          <div className="col">
            <p className={`${s.primaryTitle} w-100 m-0`}>Datos Básicos</p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <BaseInput
              label={"Nombre"}
              name="name"
              type="text"
              id="name"
              placeholder="Nombre"
              value={this.state.fields.name}
              onChange={this.onChangeFactory("name")}
              errors={this.re.mapFieldErrors("name")}
            />
          </div>
          <div className="col">
            <BaseInput
              label={"Apellido Paterno"}
              name="firstSurname"
              type="text"
              id="firstSurname"
              placeholder="Apellido Paterno"
              value={this.state.fields.firstSurname}
              onChange={this.onChangeFactory("firstSurname")}
              errors={this.re.mapFieldErrors("firstSurname")}
            />
          </div>
          <div className="col">
            <BaseInput
              label={"Apellido Materno"}
              name="secondSurname"
              type="text"
              id="secondSurname"
              placeholder="Apellido Materno"
              value={this.state.fields.secondSurname}
              onChange={this.onChangeFactory("secondSurname")}
              errors={this.re.mapFieldErrors("secondSurname")}
            />
          </div>
          <div className="col">
            <BaseInput
              label="Correo electrónico"
              name="userCreationEmail"
              type="email"
              id="userCreationEmail"
              placeholder="Correo electrónico"
              value={this.state.fields.email}
              onChange={this.onChangeFactory("email")}
              errors={this.re.mapFieldErrors("email")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <BaseInput
              label={"Nombre de usuario"}
              name="username"
              type="text"
              id="username"
              placeholder="Nombre de usuario"
              value={this.state.fields.username}
              onChange={this.onChangeFactory("username")}
              errors={this.re.mapFieldErrors("username")}
            />
          </div>
          <div className="col">
            <BaseInput
              label={"Contraseña"}
              name="password"
              type="password"
              id="password"
              placeholder="Contraseña"
              value={this.state.fields.password}
              onChange={this.onChangePassword("password")}
              errors={this.re.mapFieldErrors("password")}
            />
          </div>
          <div className="col">
            <BaseInput
              label={"Confirmar contraseña"}
              name="passwordConfirm"
              type="password"
              id="passwordConfirm"
              placeholder="Confirmar contraseña"
              value={this.state.fields.passwordConfirm}
              onChange={this.onChangePassword("passwordConfirm")}
              errors={this.re.mapFieldErrors("passwordConfirm")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <BaseInput
              label={"Número de Empleado"}
              name="employeeNumber"
              type="text"
              id="employeeNumber"
              placeholder="Número de Empleado"
              value={this.state.fields.employeeNumber}
              onChange={this.onChangeFactory("employeeNumber")}
              errors={this.re.mapFieldErrors("employeeNumber")}
            />
          </div>
          <div className="col">
            <SelectFilter
              value={this.state.position}
              onChange={position => {
                this.setState({
                  position,
                  fields: { ...this.state.fields, position: position.value }
                });
                this.re.validateField("position", position.value);
              }}
              options={this.state.positions}
              disabled={!this.props.positions}
              label={"Puesto de trabajo"}
              name="position"
              id="position"
              placeholder="Puesto de trabajo"
              errors={this.re.mapFieldErrors("position")}
              isMulti={false}
            />
          </div>
          <div className="col">
            <BaseInput
              label={"Compañia"}
              name={"domain"}
              type="select"
              id={"domain"}
              placeholder="Compañia"
              disabled
              value={this.state.fields.domain}
              onChange={this.onChangeFactory("domain")}
              options={[{ value: "", label: "Seleccione la Compañia" }].concat(
                this.props.domains.map(option => ({
                  value: option.domainCode,
                  label: option.domainName
                }))
              )}
            />
          </div>
          {!this.props.user && (
            <div className="col">
              <BaseInput
                label={"Ldap fdn"}
                name="ldapDn"
                type="text"
                id="Ldap fdn"
                placeholder="ldapDn"
                value={this.state.fields.ldapDn}
                onChange={this.onChangeFactory("ldapDn")}
                errors={this.re.mapFieldErrors("ldapDn")}
              />
            </div>
          )}
        </div>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(BasicDataUserForm));
