import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import {
  Button,
  Input,
  Label,
  FormGroup,
  Row,
  Col,
  Container,
  UncontrolledTooltip
} from "reactstrap";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalFrame from "../../../../toolkit/modalFrame";
import { setChildStep } from "../../../helpers/stepper-state-comparator";
import forEach from "lodash.foreach";
import IAction from "../../../../types/IAction";

interface Props {
  isNowOpenClubs: boolean;
  idUsuario: string;
  toggleClubs: () => any;
  getClubDealer: (personaId: string) => IAction;
  updateClubDealer: (personaId: string, body: object) => IAction;
  getDealers: (name: string, page: string, size: string) => IAction;
  clubsDealer: any;
  clubs: any;
  updateClubsDealer: any;
}

interface State {
  load: boolean;
  loadClubsDealer: boolean;
  loadSuccess: boolean;
  selectedClubes: object;
  originalClubs: object;
  clickUpdate: boolean;
}

class dealerClubs extends React.Component<Props, State> {
  state = {
    load: false,
    loadClubsDealer: false,
    loadSuccess: false,
    selectedClubes: {},
    originalClubs: {},
    clickUpdate: false
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.updateClubsDealer !== this.props.updateClubsDealer) {
      this.setState({ loadSuccess: false, loadClubsDealer: false });
    }

    if (this.state.clickUpdate) {
      this.props.getDealers(null, "0", "20");
      this.setState({
        clickUpdate: false,
        loadSuccess: true,
        loadClubsDealer: false
      });
      this.props.toggleClubs();
    }

    if (this.props.isNowOpenClubs && !this.state.load) {
      this.props.getClubDealer(this.props.idUsuario);
      this.setState({
        selectedClubes: {},
        originalClubs: {},
        load: true,
        loadClubsDealer: false
      });
    }

    if (this.props.clubsDealer !== null && !this.state.loadClubsDealer) {
      const selectedClubes = {
        ...this.state.selectedClubes
      };

      this.props.clubsDealer.map(clubPadre => {
        this.props.clubs.map(club => {
          if (club.clubId === clubPadre.clubId) {
            selectedClubes[club.clubId] = true;
          }
        });
      });

      const originalClubs = selectedClubes;
      setChildStep(selectedClubes);
      setChildStep(originalClubs);

      this.setState({
        selectedClubes,
        originalClubs,
        loadClubsDealer: true
      });
    }
  }

  clearData = () => {
    this.setState({
      selectedClubes: {},
      originalClubs: {},
      loadClubsDealer: false,
      load: false
    });
    this.props.toggleClubs();
  };

  cleanState = state => {
    const newChildState = {};
    forEach(state, (value, key) => {
      if (value) {
        newChildState[key] = value;
      }
    });

    return newChildState;
  };

  selectAll = (e, value) => {
    e.preventDefault();
    const selectedClubes = { ...this.state.selectedClubes };

    this.props.clubs.forEach(({ clubId }) => {
      selectedClubes[clubId] = value;
    });

    setChildStep(this.cleanState(selectedClubes));

    this.setState(state => ({
      ...state,
      selectedClubes
    }));
  };

  checkClub = (e, clubId) => {
    const checked = e.target.checked;
    const selectedClubes = {
      ...this.state.selectedClubes,
      [clubId]: checked
    };

    setChildStep(this.cleanState(selectedClubes));

    this.setState(state => ({
      ...state,
      selectedClubes
    }));
  };

  updateClubs = e => {
    e.preventDefault();
    const clubs = [];
    this.props.clubs.forEach(club => {
      const { clubId } = club;
      if (this.state.selectedClubes[clubId]) {
        clubs.push(clubId);
      }
    });
    this.setState({ clickUpdate: true });
    this.props.updateClubDealer(this.props.idUsuario, { clubs });
  };

  render() {
    return (
      <Container className="p-0">
        <ModalFrame
          title={`Asociar clubes a concesionario`}
          isOpen={this.props.isNowOpenClubs}
          toggle={this.clearData}
          size="lg"
        >
          <Row>
            <Col>
              <Container className={s.puestos}>
                <Row>
                  <Col>
                    <p className={`primaryTitle w-100`}>Clubes</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      id={"selectAll"}
                      onClick={e => this.selectAll(e, true)}
                      color="link"
                      className="text-success"
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </Button>
                    <Button
                      id={"unSelectAll"}
                      onClick={e => this.selectAll(e, false)}
                      color="link"
                      className="text-danger"
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                    <UncontrolledTooltip placement="left" target={`selectAll`}>
                      Selecionar todos
                    </UncontrolledTooltip>
                    <UncontrolledTooltip
                      placement="right"
                      target={`unSelectAll`}
                    >
                      Deseleccionar todos
                    </UncontrolledTooltip>
                  </Col>
                </Row>
                <Row className={s.checkBoxesContainer}>
                  {this.props.clubs &&
                    this.props.clubs.map(club => (
                      <Col
                        className={s.labelContainer}
                        key={club.clubId}
                        xs="3"
                      >
                        <FormGroup className={s.label} check>
                          <Label check>
                            <Input
                              type="checkbox"
                              disabled={!!this.state.originalClubs[club.clubId]}
                              checked={
                                this.state.originalClubs[club.clubId]
                                  ? true
                                  : !!this.state.selectedClubes[club.clubId]
                              }
                              onChange={e => this.checkClub(e, club.clubId)}
                            />
                            {club.nombre}
                          </Label>
                        </FormGroup>
                      </Col>
                    ))}
                </Row>
              </Container>
            </Col>
          </Row>
          <Row></Row>
          <Row className="mt-4">
            <Col xs={6} md={6} lg={6}>
              <Button block color="secondary" onClick={this.clearData}>
                CANCELAR
              </Button>
            </Col>
            <Col xs={6} md={6} lg={6}>
              <Button block color="secondary" onClick={this.updateClubs}>
                ACTUALIZAR
              </Button>
            </Col>
          </Row>
        </ModalFrame>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(dealerClubs));
