import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import ClubSelector from "../../containers/clubSelector";

interface Props {
  match: any;
  history: any;
  successfulSave: boolean;
  validClubs: object[];
  onClickSave: (e: object) => any;
  setClubs: () => any;
}

interface State {}

class AddClubsToProducts extends React.Component<Props, State> {
  state = {};

  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    return (
      <ClubSelector
        setClubs={this.props.setClubs}
        successfulSave={this.props.successfulSave}
        validClubs={this.props.validClubs}
        onClickSave={this.props.onClickSave}
      />
    );
  }
}

export default withRouter(withStyles(s)(AddClubsToProducts));
