import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Products from "../../components/products";
import { Product } from "../../../types-business/Products";
import { getProducts } from "../../actions/products";
import { getUsers } from "../../actions/profile";
import { QueryResponse } from "../../../types/QueryResponse";
import { QueryArguments } from "../../../types-business/QueryArguments";
import CONSTANTS from "../../../utils/constants";

interface Props {
  products: QueryResponse<Product>;
  getProducts: (tipoProducto: string, args?: QueryArguments) => any;
  getUsers: () => any;
  match: any;
  searching: boolean;
  addProduct: (product?) => any;
  history: any;
}

interface State {
  products: object;
}

class ProductsPage extends React.Component<Props, State> {
  state = { products: { content: [], totalPages: 0 } };

  onFetchData = ({
    pageSize: size,
    page,
    nombre,
    estatus,
    clasificacionId
  }) => {
    this.props.getProducts(this.props.match.params.tipoProducto, {
      size,
      page,
      nombre,
      estatus,
      clasificacionId
    });
  };

  goTo = destiny => {
    this.props.history.push(destiny);
  };

  componentDidUpdate(prevProps) {
    const { tipoProducto } = this.props.match.params;
    const { tipoProducto: prevTipoProducto } = prevProps.match.params;
    if (tipoProducto && prevTipoProducto !== tipoProducto) {
      this.onFetchData({
        pageSize: CONSTANTS.DEFAULT_PAGE_SIZE,
        page: 0,
        nombre: null,
        estatus: "TODOS",
        clasificacionId: null
      });
    }

    const { products } = this.props;
    const { products: prevProducts } = prevProps;
    if (products && prevProducts !== products) {
      this.setState({ products });
    }
  }

  render() {
    return (
      <main>
        <Helmet title="Products" />
        <Layout>
          <Products
            products={this.state.products}
            fetchData={this.onFetchData}
            searching={this.props.searching}
            onClickNewProduct={(product?) =>
              this.goTo(
                "/" +
                  this.props.match.params.tipoProducto +
                  "/datos-basicos/" +
                  (product
                    ? product.datosBasicosMembresiaId
                      ? product.datosBasicosMembresiaId
                      : product.datosBasicosMantenimientoId
                    : "")
              )
            }
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = {
  getProducts,
  getUsers
};

const mapStateToProps = state => {
  const { products } = state;
  return {
    products: products.products,
    searching: products.gettingProducts
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsPage);
