import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import PagedTable from "../common/PagedTable";
import { QueryResponse } from "../../../types/QueryResponse";
import { Container, Button } from "reactstrap";
import { Product } from "../../../types-business/Products";
import BaseInput from "../../../toolkit/baseInput";
import { MANTENIMIENTO_TYPE } from "../../../utils/constants";
import { getClassificationByLevelRequest } from "../../actions/products";
import { handleRequestError } from "../../../utils/helpers";

interface Props {
  products: QueryResponse<Product>;
  searching: boolean;
  onClickNewProduct: (product) => any;
  reloadProducts: boolean;
  fetchData: (obj: any) => void;
  history: any;
  match: any;
}

class Products extends React.Component<Props> {
  state = {
    categoriaMantenimiento: null,
    nombreMantenimiento: null,
    estatusMantenimiento: null,
    pageSize: null,
    tipoMantenimiento: null,
    categorias: []
  };

  componentDidMount() {
    getClassificationByLevelRequest(3)
      .then(({ data }) => {
        this.setState({ categorias: [...data] });
      })
      .catch(handleRequestError);
  }

  handleEditProduct = product => {
    this.props.onClickNewProduct(product);
  };

  handleDeleteProduct = product => {
    let productoId = product.datosBasicosMembresiaId
      ? product.datosBasicosMembresiaId
      : product.datosBasicosMantenimientoId;
    alert(`Eliminar producto con el id ${productoId}`);
  };

  handleAddTypeMember = ({ datosBasicosMembresiaId }) => {
    this.props.history.push(
      `/membresias/${datosBasicosMembresiaId}/integrantes`
    );
  };

  handleAddTypeMemberRules = ({ datosBasicosMembresiaId }) => {
    this.props.history.push(
      `/membresias/${datosBasicosMembresiaId}/reglas-integrantes`
    );
  };

  fetchData = ({ pageSize, page }) => {
    const {
      categoriaMantenimiento: clasificacionId,
      nombreMantenimiento: nombre
    } = this.state;
    this.props.fetchData({ pageSize, page, nombre, clasificacionId });

    this.setState({ pageSize });
  };

  getCategoriaOptions = () => {
    const { tipoMantenimiento } = this.state;

    return [
      { value: null, label: "Seleccione una categoría" },
      ...(this.state.categorias || [])
        .filter(x => x.padre == tipoMantenimiento)
        .map(option => ({
          value: option.id,
          label: option.descripcion.toUpperCase()
        }))
    ];
  };

  render() {
    const columns = [
      { name: "Id", id: "datosBasicosMantenimientoId" },
      { name: "Categoria", id: "categoria" },
      //{ name: "Subcategoria", id: "subCategoria" },
      { name: "Nombre", id: "nombre" },
      { name: "Estatus", id: "estatus" },
      // { name: "Unidades SAT", id: "unidadesSat" },
      // { name: "Producto/Servicio SAT", id: "productoServicioSat" },
      { name: "Inicio de vigencia", id: "fechaInicioVigencia" },
      { name: "Fin de vigencia", id: "fechaFinVigencia" },
      { name: "Fecha de creación", id: "fechaCreacion" },
      {
        headerActions: [{ name: "add", f: this.props.onClickNewProduct }]
      }
    ];

    const actions = [{ name: "edit", f: this.handleEditProduct }];

    const { tipoProducto } = this.props.match.params;
    if (tipoProducto === "membresias") {
      actions.push({ name: "addTypeMember", f: this.handleAddTypeMember });
      actions.push({
        name: "addTypeMemberRules",
        f: this.handleAddTypeMemberRules
      });
    }

    return (
      <Container className="p-0 mt-3">
        {tipoProducto === "mantenimientos" && (
          <div className="row mb-2">
            <div className="col-2">
              <BaseInput
                id="tipoFilter"
                name="tipoFilter"
                label={"Tipo mantenimiento"}
                type="select"
                value={this.state.tipoMantenimiento}
                options={[
                  { value: null, label: "Seleccione un Tipo" },
                  ...[...MANTENIMIENTO_TYPE].map(option => ({
                    value: option.value,
                    label: option.label.toUpperCase()
                  }))
                ]}
                onChange={e => {
                  this.setState({
                    tipoMantenimiento: e.target.value,
                    categoriaMantenimiento: null
                  });
                }}
              />
            </div>
            <div className="col-3">
              <BaseInput
                id="categoriaFilter"
                name="categoriaFilter"
                label={"Tipo mantenimiento"}
                type="select"
                value={this.state.categoriaMantenimiento}
                options={this.getCategoriaOptions()}
                onChange={e => {
                  this.setState({ categoriaMantenimiento: e.target.value });
                }}
              />
            </div>
            <div className="col-3">
              <BaseInput
                type="text"
                id="nameFilter"
                name="nameFilter"
                label="Nombre"
                placeholder="Nombre"
                value={this.state.nombreMantenimiento}
                onChange={e => {
                  this.setState({ nombreMantenimiento: e.target.value });
                }}
              />
            </div>
            <div className="col-2">
              <BaseInput
                id="estatusFilter"
                name="estatusFilter"
                label={"Estatus"}
                type="select"
                value={this.state.estatusMantenimiento}
                options={[
                  {
                    label: "Todos",
                    value: "TODOS"
                  },
                  {
                    label: "Activo",
                    value: "ACTIVO"
                  },
                  {
                    label: "Inactivo",
                    value: "INACTIVO"
                  }
                ]}
                onChange={e => {
                  this.setState({ estatusMantenimiento: e.target.value });
                }}
              />
            </div>

            <div className="col-2 pt-3">
              <Button
                block
                className={`${s.searchButton} ${s.primaryButton}`}
                onClick={() => {
                  const {
                    categoriaMantenimiento: clasificacionId,
                    nombreMantenimiento: nombre,
                    estatusMantenimiento: estatus,
                    pageSize
                  } = this.state;

                  var obj = {
                    pageSize,
                    page: 0,
                    nombre,
                    estatus,
                    clasificacionId
                  };

                  this.props.fetchData(obj);
                }}
              >
                Buscar
              </Button>
            </div>
          </div>
        )}
        <PagedTable
          data={this.props.products}
          columns={columns}
          actions={actions}
          fetchData={this.fetchData}
          searching={this.props.searching}
        />
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(Products));
