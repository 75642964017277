import React from "react";
import { Container, Row, Col } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import isNil from "lodash.isnil";
import isNumber from "lodash.isnumber";
import isString from "lodash.isstring";
import isEmpty from "lodash.isempty";
import { DEFAULT_ID } from "../../../utils/constants";

export interface Node {
  codigo: string;
  descripcion: string;
  estatus: boolean;
  id: number;
  nivel: number;
  padre: number;
}

interface Props {
  levelOneClassification: Node[];
  levelTwoClassification: Node[];
  levelThreeClassification: Node[];
  levelFourClassification: Node[];
  setResult: (
    classification: number,
    parents: number[],
    classificationLevel?: number
  ) => any;
  levelLabels?: string[];
  result: any;
  branch: number[];
  branchingMode: boolean;
  disabled: boolean;
  fitKidz: boolean;
  groupUser: string[];
}

interface State {
  level1: number;
  level2: number;
  level3: number;
  level4: number;
  disableFK: boolean;
}

const transformNodeList = (list: Node[], text: string) => {
  return [
    {
      value: DEFAULT_ID,
      label: text
    }
  ].concat(
    (list || []).map(option => ({
      value: option.id,
      label: option.descripcion
    }))
  );
};

class ProductClassification extends React.Component<Props, State> {
  state = {
    level1: 0,
    level2: 0,
    level3: 0,
    level4: 0,
    disableFK: false
  };

  resultLoaded = false;
  resultBranchLoaded = false;

  getClassificationById(classificationId) {
    const classificationFiltered = [
      ...(this.props.levelOneClassification || []),
      ...(this.props.levelTwoClassification || []),
      ...(this.props.levelThreeClassification || []),
      ...(this.props.levelFourClassification || [])
    ].filter(({ id }) => id == classificationId);
    return !!classificationFiltered && !!classificationFiltered.length
      ? classificationFiltered[0]
      : null;
  }

  getClassificationLevels(classificationId, levels) {
    if (!classificationId) {
      return levels;
    }
    const classification = this.getClassificationById(classificationId);
    if (!classification) {
      return levels;
    }
    levels["level" + classification.nivel] = classificationId;
    return this.getClassificationLevels(classification.padre, levels);
  }

  initializingValues() {
    const { result } = this.props;
    if (!this.resultBranchLoaded && this.props.branch) {
      const level4 = this.props.result;
      const level1 = this.props.branch[this.props.branch.length - 1];
      const level2 = this.props.branch[this.props.branch.length - 2];
      const level3 = this.props.branch[this.props.branch.length - 3];

      this.setState({
        ...this.state,
        level1,
        level2,
        level3,
        level4
      });

      this.resultBranchLoaded = true;
    }

    if (
      !this.resultLoaded &&
      ((isNumber(result) && result !== 0) ||
        (isString(result) && !isEmpty(result)))
    ) {
      const levels = this.getClassificationLevels(result, {});
      this.setState({
        ...this.state,
        ...levels
      });
    }
  }

  componentDidMount() {
    this.initializingValues();
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    const shouldUpdate: boolean =
      this.props.result != nextProps.result ||
      this.props.levelLabels != nextProps.levelLabels ||
      this.props.levelOneClassification != nextProps.levelOneClassification ||
      this.props.levelTwoClassification != nextProps.levelTwoClassification ||
      this.props.levelThreeClassification !=
        nextProps.levelThreeClassification ||
      this.props.levelFourClassification != nextProps.levelFourClassification ||
      this.state.level1 != nextState.level1 ||
      this.state.level2 != nextState.level2 ||
      this.state.level3 != nextState.level3 ||
      this.state.level4 != nextState.level4;

    if (shouldUpdate) {
      this.resultLoaded = false;
      this.resultBranchLoaded = false;
    }
    return shouldUpdate;
  }

  componentDidUpdate() {
    this.initializingValues();
  }

  setLevel = (levelN: number) => {
    const level = "level" + levelN;
    return async e => {
      const levelData = e.target.value;
      const newLevels = { ...this.state, [level]: levelData };

      //Se limpian la selección de los niveles subsecuentes)
      if (levelN < 4) {
        for (let i = levelN + 1; i <= 4; i++) {
          newLevels["level" + i] = DEFAULT_ID;
        }
      }

      if (!!this.props.branchingMode) {
        const { level4, level3, level2, level1 } = this.state;
        if (level === "level4") {
          this.props.setResult(levelData, [level3, level2, level1]);
        } else {
          this.props.setResult(level4, [level3, level2, level1]);
        }
      } else {
        const result = levelData;
        const parents = [];

        for (let i = levelN - 1; i >= 1; i--) {
          parents.push(this.state["level" + i]);
        }

        this.props.setResult(result, parents, levelN);
      }

      await this.setState({ ...newLevels });
    };
  };

  render() {
    return (
      <Container key={this.props.result || -1} className="p-0 m-0">
        <Row className="w-100 p-0 m-0">
          <Col className="pl-0 pr-0">
            <BaseInput
              label={
                (this.props.levelLabels && this.props.levelLabels[0]) ||
                "Tipo de producto"
              }
              name="tipoProducto"
              type="select"
              id="tipoProducto"
              placeholder="Tipo"
              value={this.state.level1}
              options={transformNodeList(
                this.props.levelOneClassification,
                "SELECCIONE TIPO DE PRODUCTO"
              )}
              onChange={this.setLevel(1)}
              disabled={!!this.props.disabled || this.state.disableFK}
            />
          </Col>
          <Col className="pr-0">
            <BaseInput
              label={
                (this.props.levelLabels && this.props.levelLabels[1]) || "Area"
              }
              name="areaProducto"
              type="select"
              id="areaProducto"
              placeholder="Tipo"
              value={this.state.level2}
              options={
                !isNil(this.state.level1) &&
                this.state.level1 !== DEFAULT_ID &&
                this.props.levelTwoClassification &&
                this.props.levelTwoClassification.length > 0
                  ? transformNodeList(
                      this.props.levelTwoClassification.filter(
                        ({ padre }) => padre == this.state.level1
                      ),
                      "SELECCIONE AREA"
                    )
                  : [
                      {
                        value: DEFAULT_ID,
                        label: "SELECCIONE AREA"
                      }
                    ]
              }
              onChange={this.setLevel(2)}
              disabled={
                !!this.props.disabled ||
                !this.state.level1 ||
                this.state.disableFK
              }
            />
          </Col>
          <Col className="pr-0">
            <BaseInput
              label={
                (this.props.levelLabels && this.props.levelLabels[2]) ||
                "Categoria"
              }
              name="categoriaProducto"
              type="select"
              id="CategoriaProducto"
              placeholder="Categoria"
              value={this.state.level3}
              options={
                !isNil(this.state.level2) &&
                this.state.level2 !== DEFAULT_ID &&
                this.props.levelThreeClassification &&
                this.props.levelThreeClassification.length > 0
                  ? transformNodeList(
                      this.props.levelThreeClassification.filter(
                        ({ padre }) => padre == this.state.level2
                      ),
                      "SELECCIONE CATEGORIA"
                    )
                  : [
                      {
                        value: DEFAULT_ID,
                        label: "SELECCIONE CATEGORIA"
                      }
                    ]
              }
              onChange={this.setLevel(3)}
              disabled={!!this.props.disabled || !this.state.level2}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ProductClassification;
