import React from "react";
import ReactTable from "react-table";
import { Col, Container, Row } from "reactstrap";

const paidStatus = ["PAGADO", "EXCEPCION_PAGO"];

interface Props {
  maintenancesDetailList: object[];
  partnerMembershipId: String;
  invalidateStateValue: (fieldName: string, value: any) => void;
  getMembershipMaintenancesDetail: () => void;
}

class MaintenancesDetailTable extends React.Component<Props> {
  state = {};

  columns = [
    {
      Header: " Fecha inicio Vigencia",
      className: "text-center",
      accessor: "movimientoInicioVigencia"
    },
    {
      Header: " Fecha fin Vigencia",
      className: "text-center",
      accessor: "movimientoFinVigencia"
    },
    {
      Header: "Id Movimiento",
      className: "text-center",
      accessor: "movimientoId"
    },
    {
      Header: "Forma de pago",
      className: "text-center",
      accessor: "movimientoFormaPago"
    },
    {
      Header: "Mantenimiento",
      accessor: "movimientoMantenimiento"
    },
    {
      Header: "Descripción",
      className: "text-wrap",
      accessor: "movimientoDescripcion",
      width: 280
    },
    {
      Header: "Pagado",
      className: "text-center",
      accessor: "estatus",
      Cell: row => {
        return (
          <span>
            {" "}
            {paidStatus.includes(row.original.estatus) ? "SI" : "NO"}{" "}
          </span>
        );
      }
    }
  ];

  render() {
    return (
      <Container className="p-0">
        <div className="m-3">
          <div
            style={{
              display: "inline",
              padding: "0px 20px",
              marginRight: "8px",
              background: "#ddd",
              border: "solid 1px #000"
            }}
          ></div>
          <span>Sin pagar</span>
        </div>
        <div>
          {this.props.maintenancesDetailList.map(socio => (
            <>
              <div className="mt-5">
                ({socio.personaId}) {socio.personaNombre}
              </div>
              <ReactTable
                key={"maintenance-table-" + socio.personaId}
                data={socio.mantenimientos || []}
                columns={this.columns}
                pageSize={(socio.mantenimientos || []).length + 2}
                className="-striped -highlight"
                sortable={false}
                showPagination={false}
                getTrProps={(_, item) => {
                  return !!item && !paidStatus.includes(item.original.estatus)
                    ? { style: { background: "#ddd" } }
                    : {};
                }}
              />
            </>
          ))}
        </div>
      </Container>
    );
  }
}

export default MaintenancesDetailTable;
