import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import s from "./styles.scss";
import { UncontrolledTooltip } from "reactstrap";
import {
  faUser,
  faFileContract,
  faCheckDouble
} from "@fortawesome/free-solid-svg-icons";

export const columns = [
  {
    Header: "Club",
    id: "club",
    accessor: simpleAccessor("club"),
    width: "192",
    className: s.tdclr,
    headerClassName: s.header
  },
  {
    Header: "Memb.",
    id: "membresia",
    accessor: intAccessor("membresia"),
    width: "80",
    className: s.tdclr,
    headerClassName: `${s.header} ${s.rightAlign}`
  },
  {
    Header: "Nombre",
    id: "nombre",
    accessor: simpleAccessor("nombre"),
    width: "256",
    className: s.tdclr,
    headerClassName: s.header
  },
  {
    Header: "Estado",
    id: "estado",
    accessor: enumAccessor("estado"),
    width: "96",
    className: s.tdclr,
    headerClassName: s.header
  },
  {
    Header: "Correo",
    id: "correo",
    accessor: simpleAccessor("correo"),
    width: "192",
    className: s.tdclr,
    headerClassName: s.header
  },
  {
    Header: "Telefono",
    id: "telefono",
    accessor: simpleAccessor("telefono"),
    width: "106",
    className: s.tdclr,
    headerClassName: s.header
  },
  {
    Header: "Adquisicion",
    id: "fechaAdquisicion",
    accessor: dateAccessor("fechaAdquisicion"),
    width: "96",
    className: s.tdclr,
    headerClassName: s.header
  },
  {
    Header: "Reactivacion",
    id: "fechaReactivacion",
    accessor: dateAccessor("fechaReactivacion"),
    width: "96",
    className: s.tdclr,
    headerClassName: s.header
  },
  {
    Header: "Más reciente",
    id: "adeudoFecha",
    accessor: dateAccessor("adeudoFecha"),
    width: "96",
    className: s.tdclr,
    headerClassName: s.header
  },
  {
    Header: "30 días",
    id: "adeudo30",
    accessor: moneyAccessor("adeudo30"),
    width: "106",
    className: s.tdclr,
    headerClassName: `${s.header} ${s.rightAlign}`
  },
  {
    Header: "60 días",
    id: "adeudo60",
    accessor: moneyAccessor("adeudo60"),
    width: "106",
    className: s.tdclr,
    headerClassName: `${s.header} ${s.rightAlign}`
  },
  {
    Header: "90 días",
    id: "adeudo90",
    accessor: moneyAccessor("adeudo90"),
    width: "106",
    className: s.tdclr,
    headerClassName: `${s.header} ${s.rightAlign}`
  },
  {
    Header: ">90 días",
    id: "adeudo90",
    accessor: moneyAccessor("adeudo90"),
    width: "106",
    className: s.tdclr,
    headerClassName: `${s.header} ${s.rightAlign}`
  },
  {
    Header: "Total",
    id: "adeudoTotal",
    accessor: moneyAccessor("adeudoTotal"),
    width: "106",
    className: s.tdclr,
    headerClassName: `${s.header} ${s.rightAlign}`
  },
  {
    Header: "Ult. Pago",
    id: "fechaPago",
    accessor: dateAccessor("fechaPago"),
    width: "96",
    className: s.tdclr,
    headerClassName: s.header
  }
];

function simpleAccessor(accessor: string) {
  return row => {
    return <div className={s.rowContent}>{row[accessor]}</div>;
  };
}

function intAccessor(accessor: string) {
  return row => {
    return (
      <div className={`${s.rowContent} ${s.rightAlign}`}>{row[accessor]}</div>
    );
  };
}

function enumAccessor(accessor: string) {
  return row => {
    const str = row[accessor] || "";
    const sentence = str.toLowerCase().split("_");

    for (var i = 0; i < sentence.length; i++) {
      if (sentence[i]) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1) + " ";
      }
    }

    return <div className={s.rowContent}>{sentence}</div>;
  };
}

const moneyFormatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN"
});

function moneyAccessor(accessor: string) {
  return row => {
    const cantidad = Number(row[accessor]);

    return (
      <div className={`${s.rowContent} ${s.rightAlign}`}>
        {!Number.isNaN(cantidad) && row[accessor] != null
          ? moneyFormatter.format(cantidad)
          : "-"}
      </div>
    );
  };
}

function dateAccessor(accessor: string) {
  return row => {
    return (
      <div className={s.rowContent}>{formatDate(row[accessor] || "-")}</div>
    );
  };
}

function formatDate(date: string): string {
  try {
    if (!date || !date.trim()) {
      return date;
    }

    const [year, month, day] = date.split("-");

    if (!year || !month || !day) {
      return date;
    }

    // Formato de la fecha:
    const fmtDate = `${day}/${month}/${year} `;

    return fmtDate;
  } catch (error) {
    return date;
  }
}
