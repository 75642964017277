import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import BaseInput from "../../../toolkit/baseInput";
import s from "./styles.scss";
import { errorNotification } from "../../../utils/notifications";

interface Props {
  companie: any;
  createCompanie: (companie: object) => any;
  updateCompanie: (id: string, companie: object) => any;
  onClickSave: (e: object) => any;
  history: any;
}

interface State {
  field: object;
}

class BasicDataCompaniesForm extends React.Component<Props, State> {
  state = {
    field: {
      id: null,
      nombre: "",
      activo: true,
      riEmpresa: "0155328",
      numeroCentroPagos: null
    }
  };

  loaded = false;
  componentDidUpdate() {
    const { companie } = this.props;
    if (companie && !this.loaded) {
      this.loaded = true;
      this.setState({
        field: {
          id: companie.id,
          nombre: companie.nombre,
          activo: companie.activo
        }
      });
    }
  }

  componentDidMount() {
    this.props.onClickSave(e => this.onSave());
  }

  onSave() {
    if (this.onValidate()) {
      let promise;
      if (this.state.field.id) {
        promise = this.props.updateCompanie(
          this.state.field.id,
          this.state.field
        );
      } else {
        promise = this.props.createCompanie(this.state.field);
      }
      promise.then(({ value: { data } }) => {
        if (data) {
          this.props.history.push(`/empresas`);
        }
      });
    }
  }

  onValidate() {
    let isValid = true;
    if (!this.state.field.nombre || this.state.field.nombre.trim() == "") {
      isValid = false;
      errorNotification("El nombre es requerido.");
    } else if (!this.state.field.activo) {
      isValid = false;
      errorNotification("El estatus es requerido.");
    }
    return isValid;
  }

  onChangeFactory(name, value) {
    this.setState({
      field: { ...this.state.field, [`${name}`]: value ? value : null }
    });
  }

  render() {
    const estatusOptions = [
      { label: "ACTIVO", value: true },
      { label: "INACTIVO", value: false }
    ];
    return (
      <form>
        <div className="form-row">
          <div className="col-12">
            <BaseInput
              label={"Nombre"}
              name="nombre"
              type="text"
              id="nombre"
              placeholder="Nombre"
              value={this.state.field.nombre}
              onChange={e =>
                this.onChangeFactory("nombre", e.target.value.toUpperCase())
              }
              uppercase={true}
              maxlength={"250"}
            />
          </div>
          <div className="col">
            <BaseInput
              label={"Estatus"}
              name="activo"
              type="select"
              id="activo"
              placeholder="Estatus"
              value={this.state.field.activo}
              options={estatusOptions.map(option => ({
                value: option.value,
                label: option.label
              }))}
              onChange={e => this.onChangeFactory("activo", e.target.value)}
            />
          </div>
        </div>
      </form>
    );
  }
}

export default withRouter(withStyles(s)(BasicDataCompaniesForm));
