import React from "react";
import { connect } from "react-redux";
import {
  updateGroupsSportsProduct,
  getGroupsSportsProduct
} from "../../actions/sportsProducts";
import AddGroupsToSportsProducts from "../../components/addGroupsToSportsProducts";
import { withRouter } from "react-router-dom";

interface Props {
  updateGroupsSportsProduct: (id: string, groups: object[]) => any;
  getGroupsSportsProduct: (id: string) => any;
  successfulSave: boolean;
  getClubsSportsProduct: (id: string) => any;
  validClubs: object[];
  onClickSave: (e: object) => any;
  match: any;
  productGroups: object[];
}

class AddGroupsToSportsProductsPage extends React.Component<Props, void> {
  render() {
    return (
      <AddGroupsToSportsProducts
        successfulSave={this.props.successfulSave}
        validClubs={this.props.validClubs}
        onClickSave={this.props.onClickSave}
        updateGroupsSportsProduct={this.props.updateGroupsSportsProduct}
        getGroupsSportsProduct={this.props.getGroupsSportsProduct}
        productGroups={this.props.productGroups}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateGroupsSportsProduct: (id: string, groups: object[]) => {
    return dispatch(updateGroupsSportsProduct(id, groups));
  },
  getGroupsSportsProduct: (id: string) => {
    dispatch(getGroupsSportsProduct(id));
  }
});

const mapStateToProps = state => {
  const { sportsProducts } = state;

  return {
    productGroups: sportsProducts.productGroups
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddGroupsToSportsProductsPage)
);
