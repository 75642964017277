import React from "react";
import {
  Button,
  Col,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row
} from "reactstrap";
import IAction from "../../../types/IAction";
import { MOTIVO_BAJA_SOCIO } from "../../../utils/constants";
import s from "./styles.scss";

interface PARTNER {
  name: String;
  id: String;
}

interface Props {
  isOpen: boolean;
  toggleModal: () => IAction;
  selectedPartner: PARTNER;
  deletePartner: (
    id: string,
    fecha: string,
    motive: string,
    idMembresiaSocio: string
  ) => IAction;
  idMembresiaSocio: string;
}

interface State {
  motivo: String;
  fechaBaja: String;
}

class DeletePartner extends React.Component<Props, State> {
  state = {
    fechaBaja: "",
    motivo: ""
  };
  handleSubmit = () => {
    this.props.deletePartner(
      this.props.selectedPartner.id,
      this.state.fechaBaja,
      this.state.motivo,
      this.props.idMembresiaSocio
    );
  };
  handleInput = e => this.setState({ fechaBaja: e.target.value });
  onChangeRadio = async e => this.setState({ motivo: e.target.id });
  handleClose = () => {
    this.setState({
      fechaBaja: "",
      motivo: ""
    });
    this.props.toggleModal();
  };
  render() {
    const { isOpen, selectedPartner } = this.props;
    return (
      selectedPartner && (
        <Modal isOpen={isOpen} toggle={this.handleClose}>
          <ModalHeader toggle={this.handleClose}>Eliminar Socio</ModalHeader>
          <ModalBody>
            <Row>
              <Col xs="12" sm="12" md="12">
                <div>
                  <Label for="socio">Socio</Label>
                  <p>
                    <b>{selectedPartner.name}</b>
                  </p>
                </div>
                <FormGroup>
                  <Label for="fechaBaja">Fecha</Label>
                  <Input
                    type="date"
                    name="fechaBaja"
                    id="fechaBaja"
                    placeholder="Fecha"
                    value={this.state.fechaBaja}
                    onChange={this.handleInput}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs="12" sm="12" md="12">
                <p className={`${s.primaryTitle} w-100 m-0`}>Motivo de baja</p>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col xs="12" sm="12" md="12">
                <select
                  onChange={e =>
                    this.setState({
                      motivo: e.target.value
                    })
                  }
                  value={this.state.motivo}
                  style={{
                    width: "100%"
                  }}
                >
                  {MOTIVO_BAJA_SOCIO.map((group, index) => {
                    return (
                      <optgroup key={index} label={group.label}>
                        {group.options &&
                          group.options.map((option, index) => {
                            return (
                              <option key={index} value={option.value}>
                                {option.label}
                              </option>
                            );
                          })}
                      </optgroup>
                    );
                  })}
                </select>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={!this.state.motivo || !this.state.fechaBaja}
              color="primary"
              onClick={this.handleSubmit}
            >
              Guardar
            </Button>{" "}
            <Button color="secondary" onClick={this.handleClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </Modal>
      )
    );
  }
}

export default DeletePartner;
