import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Container, Col, Button, Row } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import DatePicker from "../common/DatePicker";
import ReactTable from "react-table";
import MexicanCurrency from "../common/currency";
import s from "./styles.scss";
import IAction from "../../../types/IAction";
import { CONCEPTOS } from "../../../utils/constants";

interface Props {
  clubs: object[];
  depositoReferenciado: object[];
  getDepositoReferenciado: (clubId: string, fechaDePago: string) => IAction;
  pdfReferencedDeposit: (clubId: string, fechaDePago: string) => IAction;
}

interface State {
  filters: object;
  clubs: object[];
}

const DATE_FIELDNAME = "fechaBusqueda";

class ReferencedDepositReports extends React.Component<Props, State> {
  state = {
    filters: {
      idConcepto: "",
      idClub: "",
      fechaBusqueda: null
    },
    clubs: []
  };

  columns = [
    {
      Header: () => <p className="font-weight-bold">Forma de pago</p>,
      id: 0,
      width: 160,
      accessor: "formaDePago",
      Cell: row => {
        return <p>{row.original.formaDePago}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Importe</p>,
      id: 1,
      width: 160,
      accessor: "importe",
      Cell: row => {
        return (
          <p>
            <MexicanCurrency quantity={row.original.importe} />
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Número de cheque</p>,
      id: 2,
      width: 160,
      accessor: "numeroCheque",
      Cell: row => {
        return <p>{row.original.numeroCheque}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Banco</p>,
      id: 3,
      width: 160,
      accessor: "banco",
      Cell: row => {
        return <p>{row.original.banco}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Referencia</p>,
      id: 4,
      width: 160,
      accessor: "referencia",
      Cell: row => {
        return <p>{row.original.referencia}</p>;
      }
    }
  ];

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  onChangeConcepto = e => {
    let valFilters = this.state.filters;
    valFilters.idConcepto = e.target.value;
    let filterClubs = this.props.clubs.filter(
      value => value.concepto == e.target.value
    );
    this.setState({ filters: valFilters, clubs: filterClubs });
  };

  genReport = e => {
    e.preventDefault();
    const { idClub, fechaBusqueda } = this.state.filters;
    this.props.pdfReferencedDeposit(idClub, fechaBusqueda);
  };

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const filters = {
          ...state.filters,
          [fieldName]: date
        };
        return { ...state, error: {}, filters };
      });
    };
  };

  search = e => {
    e.preventDefault();
    const { idClub, fechaBusqueda } = this.state.filters;
    this.props.getDepositoReferenciado(idClub, fechaBusqueda);
  };

  render() {
    const { idClub, idConcepto, fechaBusqueda } = this.state.filters;
    const { clubs } = this.state;
    const { depositoReferenciado } = this.props;
    return (
      <Container className="p-0">
        <div className="row mt-3 mb-3">
          <div className="col">
            <p className={`${s.primaryTitle} w-100 m-0`}>
              Búsqueda por depósito referenciado
            </p>
          </div>
        </div>
        <Row>
          <Col xs={12} md={4} lg={3}>
            <BaseInput
              label="Concepto"
              name="concepto"
              id="concepto"
              type="select"
              placeholder="Seleccionar concepto"
              value={idConcepto}
              onChange={e => this.onChangeConcepto(e)}
              size={"sm"}
              options={[{ value: "", label: "Seleccionar concepto" }].concat(
                CONCEPTOS
                  ? CONCEPTOS.map(option => ({
                      value: option.value,
                      label: option.label
                    }))
                  : []
              )}
            />
          </Col>
          <Col xs={12} md={4} lg={3}>
            <BaseInput
              label="Club"
              name="club"
              id="club"
              type="select"
              placeholder="Seleccionar club"
              value={idClub}
              disabled={idConcepto.length == 0}
              onChange={this.onChangeFilter("idClub")}
              size={"sm"}
              options={[{ value: "", label: "Seleccionar club" }].concat(
                clubs.length > 0
                  ? clubs.map(option => ({
                      value: option.clubId,
                      label: option.nombre
                    }))
                  : []
              )}
            />
          </Col>
          <Col xs={12} md={4} lg={3}>
            <DatePicker
              label="Fecha"
              name={DATE_FIELDNAME}
              id={DATE_FIELDNAME}
              selected={this.state.filters[DATE_FIELDNAME]}
              onChange={this.onChangeDate(DATE_FIELDNAME)}
            />
          </Col>
          <Col xs={12} md={4} lg={3} className="mt-4">
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.search}
              disabled={idClub.length == 0 || fechaBusqueda == null}
            >
              Buscar
            </Button>
          </Col>
        </Row>
        <ReactTable
          data={depositoReferenciado ? depositoReferenciado : []}
          columns={this.columns}
          // defaultPageSize={
          //   depositoReferenciado.length != 0 ? depositoReferenciado.length : 3
          // }
          showPagination={false}
        />
        <Row>
          <Col></Col>
          <Col xs={12} md={4} lg={3} className="mt-4">
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.genReport}
              disabled={depositoReferenciado.length == 0}
            >
              Generar Reporte General
            </Button>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ReferencedDepositReports));
