import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import { Row, Col, Button } from "reactstrap";
import IAction from "../../../../../types/IAction";
import s from "./../../tabStyles.scss";
import PersonSelector from "../../../../../People/containers/PersonSelector";
import { Partner } from "./../../interfaces";
import ConfirmModal from "../../../../components/common/confirmModal/confirmModal";

interface Props {
  partner: Partner;
  rightsChange: (idMembresiaSocio: string, idPersona: string) => IAction;
  refreshPartner: () => void;
}

interface State {
  selectedPerson: any;
  confirmModalIsOpen: boolean;
}

class CesionDerechos extends React.Component<Props, State> {
  state = {
    selectedPerson: null,
    confirmModalIsOpen: false
  };

  toggleModalRights = () => {
    this.setState({ confirmModalIsOpen: !this.state.confirmModalIsOpen });
  };

  setSelectedPerson = (person: any) => {
    this.setState({ selectedPerson: person });
  };

  handleRightsChange = () => {
    const { partner } = this.props;
    const { selectedPerson } = this.state;
    this.props
      .rightsChange(partner.idMembresiaSocio, selectedPerson.personaId)
      .then(() => {
        this.props.refreshPartner();
      });
    this.toggleModalRights();
  };

  render() {
    return (
      <React.Fragment>
        <ConfirmModal
          title="Cesión de derechos"
          message={
            this.state.selectedPerson &&
            `Se cederán los derechos a la persona  ${this.state.selectedPerson.nombre} ${this.state.selectedPerson.primerApellido}`
          }
          isOpen={this.state.confirmModalIsOpen}
          requireInput={false}
          ok={this.handleRightsChange}
          cancel={() => {
            this.setState({
              confirmModalIsOpen: false
            });
          }}
        />

        <div>
          <div className="row mt-3 mb-3">
            <div className="col">
              <p className={`${s.primaryTitle} w-100 m-0`}>
                Cesión de derechos
              </p>
            </div>
          </div>
          <Row className="row mt-3 mb-3">
            <Col sm={8}>
              <PersonSelector
                showEditButton={true}
                setPerson={this.setSelectedPerson}
                cKey={"6KXXDT0OPW"}
              />
            </Col>
            <Col className="pl-0 mt-4" sm={4}>
              <Button
                onClick={this.toggleModalRights}
                disabled={!this.state.selectedPerson}
              >
                Cesión de derechos
              </Button>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(s)(CesionDerechos));
