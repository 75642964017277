import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import * as catalogActions from "../../actions/catalogs";
import * as reportsActions from "../../actions/reports";
import IAction from "../../../types/IAction";
import RefoundsReport from "../../components/refoundsReport";

interface Props {
  getClubs: () => void;
  clubs: object[];
  getRefunds: (
    clubId: string,
    fechaInicio: string,
    fechaFin: string,
    page: string,
    size: string
  ) => IAction;
  reportRefunds: any;
  getRefundsCsv: (body: object) => IAction;
  authorizeRefund: (solicitudId: string) => IAction;
  refuseRefund: (solicitudId: string) => IAction;
  chargebackRefund: (solicitudId: string) => IAction;
  userContext: any;
}

interface State {}

class Refounds extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    this.props.getClubs();
  }

  componentWillUnmount() {}

  render() {
    return (
      <main>
        <Helmet title="Santander Reference" />
        <Layout>
          <RefoundsReport
            clubs={this.props.clubs}
            getRefunds={this.props.getRefunds}
            reportRefunds={this.props.reportRefunds}
            getRefundsCsv={this.props.getRefundsCsv}
            authorizeRefund={this.props.authorizeRefund}
            refuseRefund={this.props.refuseRefund}
            chargebackRefund={this.props.chargebackRefund}
            userContext={this.props.userContext}
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({ catalogs, reports, user }) => {
  return {
    clubs: catalogs.clubs,
    reportRefunds: reports.reportRefunds,
    userContext: user.userContext
  };
};

const mapDispatchToProps = dispatch => ({
  getClubs: () => {
    dispatch(catalogActions.getClubs());
  },
  getRefunds: (
    clubId: string,
    fechaInicio: string,
    fechaFin: string,
    page: string,
    size: string
  ) => {
    dispatch(
      reportsActions.getRefunds(clubId, fechaInicio, fechaFin, page, size)
    );
  },
  getRefundsCsv: (body: object) => {
    dispatch(reportsActions.getRefundsCsv(body));
  },
  authorizeRefund: (solicitudId: string) => {
    dispatch(reportsActions.authorizeRefund(solicitudId));
  },
  refuseRefund: (solicitudId: string) => {
    dispatch(reportsActions.refuseRefund(solicitudId));
  },
  chargebackRefund: (solicitudId: string) => {
    dispatch(reportsActions.chargebackRefund(solicitudId));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Refounds);
