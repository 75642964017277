import { InvitadoTipo } from "../../../types/Invitado";

export const requiredIfFamiliar = {
  validator: (value, formValue) =>
    InvitadoTipo[formValue.tipo] === InvitadoTipo.FAMILIAR && !value,
  message: () => "Campo obligatorio"
};

export const requiredIfAutorizacionDirecta = {
  validator: (value, formValue) =>
    InvitadoTipo[formValue.tipo] === InvitadoTipo.AUTORIZACION_DIRECTA &&
    !value,
  message: () => "Campo obligatorio"
};

export const requiredClubsIfNotIntercambioComercial = {
  validator: (value, formValue) =>
    InvitadoTipo[formValue.tipo] !== InvitadoTipo.INTERCAMBIO_COMERCIAL &&
    (!value || value.length === 0),
  message: () => "Debe seleccionar al menos un club"
};

export const requiredIfIntercambioComercial = {
  validator: (value, formValue) =>
    InvitadoTipo[formValue.tipo] === InvitadoTipo.INTERCAMBIO_COMERCIAL &&
    !value,
  message: () => "Campo obligatorio"
};

export const hiddenIfNotFamiliar = formValue =>
  InvitadoTipo[formValue.tipo] !== InvitadoTipo.FAMILIAR;

export const hiddenIfNotAutorizacionDirecta = formValue =>
  InvitadoTipo[formValue.tipo] !== InvitadoTipo.AUTORIZACION_DIRECTA;

export const hiddenIfIntercambioComercial = formValue => {
  const tipo = formValue.tipo && InvitadoTipo[formValue.tipo];
  return !tipo || tipo === InvitadoTipo.INTERCAMBIO_COMERCIAL;
};

export const hiddenIfNotIntercambioComercial = formValue =>
  InvitadoTipo[formValue.tipo] !== InvitadoTipo.INTERCAMBIO_COMERCIAL;
