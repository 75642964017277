import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import AnnuitiesAuth from "../../components/annuitiesAuthorize";
import {} from "../../actions/products";

interface Props {}

interface State {}

class AnnuitiesAuthPage extends React.Component<Props, State> {
  state = {};

  render() {
    return (
      <main>
        <Helmet title="Autorización de anualidades" />
        <Layout>
          <AnnuitiesAuth />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnuitiesAuthPage);
