import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import { readMessageBack } from "../../utils/readMessageBack";
import { errorNotification, ERROR } from "../../utils/notifications";
interface State {
  loadingSpecialGuests: boolean;
  specialGuests: any[];
  specialGuestTypes: any[];
}
const initialState: State = {
  loadingSpecialGuests: false,
  specialGuests: null,
  specialGuestTypes: null
};

export default function partnerReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.GET_SPECIAL_GUESTS}_PENDING`: {
      return {
        ...state,
        loadingSpecialGuests: true,
        specialGuests: null
      };
    }
    case `${actionTypes.GET_SPECIAL_GUESTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        loadingSpecialGuests: false,
        specialGuests: null
      };
    }
    case `${actionTypes.GET_SPECIAL_GUESTS}_FULFILLED`: {
      return {
        ...state,
        loadingSpecialGuests: false,
        specialGuests: action.payload.data
      };
    }
    case `${actionTypes.CLEAR_SPECIAL_GUESTS}`: {
      return {
        ...state,
        loadingSpecialGuests: false,
        specialGuests: null
      };
    }
    case `${actionTypes.GET_SPECIAL_GUEST_TYPES}_PENDING`: {
      return {
        ...state,
        specialGuestTypes: null
      };
    }
    case `${actionTypes.GET_SPECIAL_GUEST_TYPES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        specialGuestTypes: null
      };
    }
    case `${actionTypes.GET_SPECIAL_GUEST_TYPES}_FULFILLED`: {
      return {
        ...state,
        specialGuestTypes: action.payload.data
      };
    }

    default: {
      return state;
    }
  }
}

export { initialState };
