import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import PagedTable from "../common/PagedTable";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { QueryResponse } from "../../../types/QueryResponse";
import BenefitsCalculatorModal from "./benefitsCalculatorModal";
import PostSelectorModal from "./postSelectorModal";
import isNil from "lodash.isnil";

interface Props {
  schemas: QueryResponse<SchemaBenefits>;
  onClickNewSchema: (schema) => any;
  fetchData: (args) => void;
  searching: boolean;
  content: object;
  getPostsBySchema: (idSchema?: string) => any;
  posts: object;
  postData: object[];
  savePostsBySchema: (args) => any;
  cleanPeticionData: (data: object) => any;
}

interface State {
  schema: object;
  isOpen: boolean;
  selPostOpen: boolean;
  checked: object;
  allChecked: boolean;
}

interface SchemaBenefits {
  content: object[];
  first: true;
  last: true;
  number: 0;
  numberOfElements: 0;
  size: 1;
  sort: null;
  totalElements: 0;
  totalPages: 0;
}

class ListBenefitsSchema extends React.Component<Props, State> {
  state = {
    schema: null,
    isOpen: false,
    selPostOpen: false,
    checked: {},
    allChecked: false
  };

  componentDidUpdate = () => {
    if (!isNil(this.props.posts) && !isNil(this.props.posts.puestos)) {
      if (
        this.props.posts.puestos.length > 0 &&
        Object.keys(this.state.checked).length == 0
      ) {
        let check = {};
        this.props.posts.puestos.forEach(element => {
          check[element] = true;
        });
        this.setState({ checked: check });
      }
    }
  };

  handleEditSchema = schema => {
    this.props.onClickNewSchema(schema);
  };
  handleBenefitsEmulator = schema => {
    this.setState({ isOpen: true, schema: schema });
  };

  toggle = schema => {
    this.setState({
      isOpen: !this.state.isOpen,
      schema: schema
    });
  };

  handlePost = schema => {
    this.props.getPostsBySchema(schema.esquemaId);
    this.setState({ selPostOpen: true, allChecked: false });
  };

  setCheck = (index, val) => {
    if (this.state.checked.hasOwnProperty(val)) {
      let check = { ...this.state.checked };
      check[val] = !this.state.checked[val];
      this.setState({ checked: check });
    } else {
      let check = { ...this.state.checked };
      check[val] = true;
      this.setState({ checked: check });
    }
  };

  setAllChecket = valBool => {
    if (valBool) {
      let check = {};
      this.props.postData.forEach(element => {
        check[element.id] = true;
      });
      this.setState({ checked: check, allChecked: true });
    } else {
      this.props.cleanPeticionData({
        idEsquemaComision: this.props.posts.idEsquemaComision,
        puestos: []
      });
      this.setState({ checked: {}, allChecked: false });
    }
  };

  fetchData = ({ pageSize: size, page }) => {
    this.props.fetchData({ size, page });
    this.setState(state => {
      return {
        ...state,
        search: { size, page }
      };
    });
  };

  savePosts = () => {
    if (Object.keys(this.state.checked).length > 0) {
      let arraySend = [];
      Object.entries(this.state.checked).map(([key, value]) => {
        if (value == true) arraySend.push(key);
      });
      let sendToSave = {
        idEsquemaComision: this.props.posts.idEsquemaComision,
        puestos: arraySend
      };
      const selPostOpen = !this.state.selPostOpen;
      if (!selPostOpen) {
      }
      this.props.cleanPeticionData({
        idEsquemaComision: this.props.posts.idEsquemaComision,
        puestos: []
      });
      this.setState({ selPostOpen, checked: {} });
      this.props.savePostsBySchema(sendToSave);
    }
  };

  render() {
    const columns = [
      { name: "#", id: "esquemaId" },
      { name: "Esquema", id: "nombre" },
      { name: "Tipo", id: "tipoEsquema" },
      {
        headerActions: [{ name: "add", f: this.props.onClickNewSchema }]
      }
    ];

    const actions = [
      { name: "edit", f: this.handleEditSchema },
      { name: "post", f: this.handlePost }
    ];
    return (
      <div className="container mt-3 p-0">
        <BenefitsCalculatorModal
          isOpen={this.state.isOpen}
          schema={this.state.schema}
          toggle={() => {
            const isOpen = !this.state.isOpen;
            if (!isOpen) {
            }
            this.setState({ isOpen });
          }}
        />
        <PostSelectorModal
          selPostOpen={this.state.selPostOpen}
          postData={this.props.postData}
          setCheck={this.setCheck}
          toggle={() => {
            const selPostOpen = !this.state.selPostOpen;
            if (!selPostOpen) {
            }
            this.props.cleanPeticionData({
              idEsquemaComision: this.props.posts.idEsquemaComision,
              puestos: []
            });
            this.setState({ selPostOpen, checked: {} });
          }}
          checked={this.state.checked}
          allChecked={this.state.allChecked}
          setAllChecket={this.setAllChecket}
          savePosts={this.savePosts}
        />
        {this.props.schemas && (
          <PagedTable
            data={this.props.schemas}
            columns={columns}
            actions={actions}
            fetchData={this.fetchData}
            searching={this.props.searching}
          />
        )}
      </div>
    );
  }
}

export default withRouter(withStyles(s)(ListBenefitsSchema));
