import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import s from "./styles.scss";

export interface TableProps {
  data: any[];
  loading: boolean;
}

export const MemoTable = React.memo(({ data, loading }: TableProps) => {
  const [checkAll, setCheckAll] = useState(false);
  const [columns, setColumns] = useState(columnsFormatter());

  // Actualización de estilos y checkboxes
  useEffect(() => {
    setColumns(columnsFormatter());
  }, []);

  // Limpieza de checkbox para seleccionar todo, en nuevas búsquedas
  useEffect(() => {
    checkAll && setCheckAll(false);
  }, [data]);

  return (
    <>
      <div className="row mt-4">
        <div style={{ width: "100%" }}>
          <ReactTable
            data={data || []}
            loading={loading}
            pageSize={data && data.length > 5 ? data.length : 5}
            columns={columns}
            sortable={false}
            resizable={true}
            showPagination={false}
            className={`w-100 -highlight -striped ${s.hidden}`}
          />
        </div>
      </div>
    </>
  );
});

const columnsFormatter = () => [
  {
    Header: "Fecha",
    id: "eventoFecha",
    width: "120",
    className: s.tdClear,
    accessor: dateAccessor("eventoFecha")
  },
  {
    Header: "Usuario",
    id: "eventoUsername",
    width: "80",
    className: s.tdClear,
    accessor: simpleAccessor("eventoUsername")
  },
  {
    Header: "Nombre",
    id: "eventoUserFullName",
    width: "80",
    className: s.tdClear,
    accessor: simpleAccessor("eventoUserFullName")
  },
  {
    Header: "Descripción",
    id: "actividad",
    width: "80",
    className: s.tdClear,
    accessor: noWrapAccessor("actividad")
  }
];

function fieldClassName(row: any) {
  return s.field;
}

function simpleAccessor(key: string) {
  return (row: any) => {
    return <div className={fieldClassName(row)}>{row[key]}&nbsp;</div>;
  };
}

function noWrapAccessor(key: string) {
  return (row: any) => {
    return (
      <div className={fieldClassName(row) + " text-nowrap"}>
        {row[key]}&nbsp;
      </div>
    );
  };
}

function dateAccessor(key: string) {
  return row => {
    return (
      <div className={fieldClassName(row)}>{formatDate(row[key] || "-")}</div>
    );
  };
}

function formatDate(isoDate: string): string {
  if (!isoDate || isoDate == "-") {
    return "-";
  }

  try {
    const [date, time] = isoDate.split("T");
    const [year, month, day] = date.split("-");

    // Formato de la fecha:
    const fmtDate = `${day}/${month}/${year} `;
    const indexSeconds = time.lastIndexOf(":");
    const fmtTime = indexSeconds >= 4 ? time.substring(0, indexSeconds) : time;

    return fmtDate + fmtTime;
  } catch (error) {
    return isoDate;
  }
}
