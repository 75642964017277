import React from "react";
import { connect } from "react-redux";
import {
  getUserActivities,
  markActivityRead,
  saveEvidence
} from "../../actions/activities";
import ActivitiesTable from "../../components/ActivitiesTable";
import { DEFAULT_CATEGORY_ID } from "../../../utils/constants";
import moment from "moment";

interface Props {
  clubId: string;
  month: number;
  year: number;
  getUserActivities: (query: object) => any;
  markActivityRead: (activityId: number) => any;
  saveCompromises: (userId: string) => any;
  saveEvidence: (activityId: number, evidence: string) => any;
  userId: number;
  userActivitySaved: boolean;
  activitySeenSuccess: boolean;
}

class SalesTotalsContainer extends React.Component<Props> {
  state = {
    activities: [],
    loading: false,
    errorGettingData: false
  };

  getUserActivities = async () => {
    try {
      this.setState({ loading: true });
      const {
        value: { data: activities }
      } = await this.props.getUserActivities({
        clubId: this.props.clubId,
        usuarioId: this.props.userId,
        categoriaId: DEFAULT_CATEGORY_ID,
        startDate: moment()
          .set("month", this.props.month - 1)
          .set("year", this.props.year)
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD"),
        endDate: moment()
          .set("month", this.props.month - 1)
          .set("year", this.props.year)
          .endOf("month")
          .add("days", 3)
          .endOf("day")
          .format("YYYY-MM-DD")
      });
      this.setState({ activities, errorGettingData: false });
    } catch (e) {
      console.error(e);
      this.setState({ errorGettingData: true });
    } finally {
      this.setState({ loading: false });
    }
  };

  componentDidMount() {
    this.getUserActivities();
  }

  componentDidUpdate({
    userActivitySaved: prevYserActivitySaved,
    activitySeenSuccess: prevActivitySeenSuccess,
    month: prevMonth,
    year: prevYear
  }) {
    if (
      (!prevYserActivitySaved && this.props.userActivitySaved) ||
      (!prevActivitySeenSuccess && this.props.activitySeenSuccess)
    ) {
      return this.getUserActivities();
    }

    const { month, year } = this.props;
    if (month != prevMonth || year != prevYear) {
      return this.getUserActivities();
    }

    return null;
  }

  render() {
    return (
      <ActivitiesTable
        activities={this.state.activities}
        errorGettingData={this.state.errorGettingData}
        loading={this.state.loading}
        markActivityRead={this.props.markActivityRead}
        saveEvidence={this.props.saveEvidence}
      />
    );
  }
}

const mapDispatchToProps = {
  getUserActivities,
  markActivityRead,
  saveEvidence
};

const mapStateToProps = ({
  activities: { userActivitySaved, activitySeenSuccess },
  user: {
    userContext: { userId }
  }
}) => {
  return {
    userId,
    userActivitySaved,
    activitySeenSuccess
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesTotalsContainer);
