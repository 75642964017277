import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import PagedTable from "../common/PagedTable";
import { QueryResponse } from "../../../types/QueryResponse";
import { QueryArguments } from "../../../types-business/QueryArguments";
import { Container, Row, Col, Button, Form } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";

interface Props {
  companies: QueryResponse<Companie>;
  searching: boolean;
  onClickNewCompanie: (companie) => any;
  fetchData: () => void;
  history: any;
  filters: Filters;
  onChangeFilter: any;
  getCompanies: (args?: QueryArguments) => any;
  handleDeleteProspect: (companie: Companie) => any;
}

interface Filters {
  clasificacionId: string;
  nombre: string;
  estatus: string;
  page: number;
  size: number;
}

interface Companie {
  id: string;
  nombre: string;
  activo: string;
  estatus: string;
  fechaCreacion: string;
  fechaActualizacion: string;
  riEmpresa: string;
  numeroCentroPagos: string;
}

class Companies extends React.Component<Props> {
  handleEditCompanie = companie => {
    this.props.onClickNewCompanie(companie);
  };

  search = () => {
    this.props.getCompanies(this.props.filters);
  };

  onChangeFactory = (event, fieldName) => {
    const value = event.target.value;
    this.props.onChangeFilter(value, fieldName);
  };

  render() {
    const columns = [
      { name: "Nombre", id: "nombre" },
      { name: "Estatus", id: "estatus" },
      { name: "Creación", id: "fechaCreacion" },
      { name: "Actualización", id: "fechaActualizacion" },
      {
        headerActions: [{ name: "add", f: this.props.onClickNewCompanie }]
      }
    ];

    const actions = [
      { name: "edit", f: this.handleEditCompanie },
      {
        name: "status",
        f: e => this.props.handleDeleteProspect(e)
      }
    ];

    return (
      <Container className="p-0 mt-3">
        <Form
          onSubmit={e => {
            e.preventDefault();
            this.search();
          }}
        >
          <Row>
            <Col style={{ paddingBottom: "5px" }} xs={12} md={16} lg={7}>
              <BaseInput
                label={"Nombre del empresa"}
                name="companieName"
                id="companiename"
                placeholder="Nombre del Empresa"
                dataQAId={"companie_name"}
                onChange={companieName =>
                  this.onChangeFactory(companieName, "nombre")
                }
              />
            </Col>
            <Col style={{ paddingBottom: "5px" }} xs={12} md={12} lg={3}>
              <BaseInput
                label={"Estatus"}
                name="estatus"
                type="select"
                id="estatus"
                options={[
                  { value: "", label: "Todos" },
                  { value: true, label: "Activo" },
                  { value: false, label: "Inactivo" }
                ]}
                placeholder="Estatus"
                //value={this.props.fields.estatus}
                onChange={estatus => this.onChangeFactory(estatus, "estatus")}
              />
            </Col>
            <Col xs={12} md={12} lg={2} className="mt-4">
              <Button
                size="sm"
                className={`float-right btn-block`}
                onClick={this.search}
                type="submit"
              >
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
        <PagedTable
          data={this.props.companies}
          columns={columns}
          actions={actions}
          fetchData={this.props.fetchData}
          searching={this.props.searching}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(Companies));
