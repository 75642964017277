import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import QuizzDevicesResume from "../../components/quizzDevicesResume";

interface Props {}

interface State {}

class QuizzDevicesResumePage extends React.Component<Props, State> {
  state = {
    quizzDeviceResume: []
  };

  render() {
    return (
      <main>
        <Helmet title="Quizzes" />
        <Layout>
          <QuizzDevicesResume />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuizzDevicesResumePage);
