import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Container, Col, Button, Row } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import DatePicker from "../common/DatePicker";
import ReactTable from "react-table";
import MexicanCurrency from "../common/currency";
import s from "./styles.scss";
import IAction from "../../../types/IAction";
import {
  faFileExport,
  faThumbsUp,
  faThumbsDown,
  faExchangeAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { allowedTo } from "../../../utils/accessTree";
import accessCodes from "../../../utils/accessCodes";
const {
  CANCELAR_SOLICITUD_DEVOLUCIONES,
  APROBAR_SOLICITUD_DEVOLUCIONES,
  GENERAR_CONTRACARGOS_DEVOLUCIONES
} = accessCodes;

interface Props {
  clubs: object[];
  getRefunds: (
    clubId: string,
    fechaInicio: string,
    fechaFin: string,
    page: string,
    size: string
  ) => IAction;
  reportRefunds: any;
  getRefundsCsv: (body: object) => IAction;
  authorizeRefund: (solicitudId: string) => IAction;
  refuseRefund: (solicitudId: string) => IAction;
  chargebackRefund: (solicitudId: string) => IAction;
  userContext: any;
}

interface State {
  filters: object;
}

const START_DATE_FIELDNAME = "fechaInicio";
const END_DATE_FIELDNAME = "fechaFin";

class RefoundsReport extends React.Component<Props, State> {
  state = {
    filters: {
      idClub: null,
      fechaInicio: null,
      fechaFin: null
    }
  };

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const filters = {
          ...state.filters,
          [fieldName]: date
        };
        return { ...state, error: {}, filters };
      });
    };
  };

  onFetchData = ({ pageSize: size, page }) => {
    const { idClub, fechaInicio, fechaFin } = this.state.filters;
    const clubId = idClub;
    this.props.getRefunds(
      clubId,
      fechaInicio,
      fechaFin,
      page ? page : "0",
      size ? size : "20"
    );
  };

  onClickDownloadCsvFile = () => {
    const { idClub, fechaInicio, fechaFin } = this.state.filters;
    const clubId = idClub;
    this.props.getRefundsCsv({ clubId, fechaInicio, fechaFin });
  };

  columns = [
    {
      Header: () => <p className="font-weight-bold">Devolución id</p>,
      id: 0,
      accessor: "devolucionId",
      Cell: row => {
        return <p>{row.original.devolucionId}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Club</p>,
      id: 1,
      accessor: "clubNombre",
      Cell: row => {
        return <p>{row.original.clubNombre}</p>;
      }
    },
    {
      Header: () => (
        <p className="font-weight-bold">Membresia Socio Id Publico</p>
      ),
      id: 2,
      accessor: "membresiaSocioIdPublico",
      Cell: row => {
        return <p>{row.original.membresiaSocioIdPublico}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Folio factura</p>,
      id: 3,
      accessor: "folioFactura",
      Cell: row => {
        return <p>{row.original.folioFactura}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Referencia</p>,
      id: 4,
      width: 150,
      accessor: "referencia",
      Cell: row => {
        return <p>{row.original.referencia}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Tipo</p>,
      id: 5,
      width: 100,
      accessor: "tipo",
      Cell: row => {
        return <p>{row.original.tipo}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Estatus</p>,
      id: 6,
      width: 150,
      accessor: "estatus",
      Cell: row => {
        return <p>{row.original.estatus}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Fecha solicitud</p>,
      id: 7,
      accessor: "fechaSolicitud",
      Cell: row => {
        return <p>{row.original.fechaSolicitud}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Fecha Contracargo</p>,
      id: 8,
      accessor: "fechaContraCargo",
      Cell: row => {
        return <p>{row.original.fechaContraCargo}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Importe</p>,
      id: 9,
      accessor: "importe",
      Cell: row => {
        return (
          <p>
            <MexicanCurrency quantity={row.original.importe} />
          </p>
        );
      }
    },
    {
      Header: () => {
        return <p className="font-weight-bold">Opciones</p>;
      },
      id: -300,
      width: 150,
      accessor: "actions",
      Cell: row => {
        return (
          <Container className="p-0">
            <Row>
              {allowedTo(APROBAR_SOLICITUD_DEVOLUCIONES) &&
              row.original.estatus == "CREADA" ? (
                <Col>
                  <a
                    id={`validate${row.original.folio}`}
                    onClick={() =>
                      this.props.authorizeRefund(row.original.devolucionId)
                    }
                  >
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={faThumbsUp}
                    />
                  </a>
                </Col>
              ) : (
                ""
              )}
              {allowedTo(CANCELAR_SOLICITUD_DEVOLUCIONES) &&
              row.original.estatus == "CREADA" ? (
                <Col>
                  <a
                    id={`refuse${row.original.folio}`}
                    onClick={() =>
                      this.props.refuseRefund(row.original.devolucionId)
                    }
                  >
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={faThumbsDown}
                    />
                  </a>
                </Col>
              ) : (
                ""
              )}
              {allowedTo(GENERAR_CONTRACARGOS_DEVOLUCIONES) &&
              row.original.tipo &&
              row.original.estatus == "ENVIADA" &&
              row.original.tipo == "FACTURA" ? (
                <Col>
                  <a
                    id={`refuse${row.original.folio}`}
                    onClick={() =>
                      this.props.chargebackRefund(row.original.devolucionId)
                    }
                  >
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={faExchangeAlt}
                    />
                  </a>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </Container>
        );
      }
    }
  ];

  componentDidMount = () => {
    this.setState({
      filters: {
        ...this.state.filters,
        idClub:
          (this.props.userContext && this.props.userContext.clubSessionId) ||
          null
      }
    });
  };

  render() {
    const { clubs, reportRefunds } = this.props;
    const { idClub } = this.state.filters;
    return (
      <Container className="p-0">
        <div className="row mt-3 mb-3">
          <div className="col">
            <p className={`${s.primaryTitle} w-100 m-0`}>
              Búsqueda de referencias
            </p>
          </div>
        </div>
        <Row>
          <Col xs={12} md={4} lg={4}>
            <BaseInput
              label="Club"
              name="club"
              id="club"
              type="select"
              placeholder="Seleccionar club"
              value={idClub}
              onChange={this.onChangeFilter("idClub")}
              size={"sm"}
              disabled={
                this.props.userContext && this.props.userContext.clubSessionId
              }
              options={[{ value: "", label: "Seleccionar club" }].concat(
                clubs
                  ? clubs.map(option => ({
                      value: option.clubId,
                      label: option.nombre
                    }))
                  : []
              )}
            />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <DatePicker
              label="Fecha inicio"
              name={START_DATE_FIELDNAME}
              id={START_DATE_FIELDNAME}
              selected={this.state.filters[START_DATE_FIELDNAME]}
              onChange={this.onChangeDate(START_DATE_FIELDNAME)}
            />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <DatePicker
              label="Fecha fin"
              name={END_DATE_FIELDNAME}
              id={END_DATE_FIELDNAME}
              selected={this.state.filters[END_DATE_FIELDNAME]}
              onChange={this.onChangeDate(END_DATE_FIELDNAME)}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4} lg={4} className="mt-4">
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.onFetchData}
            >
              Buscar
            </Button>
          </Col>
        </Row>
        {reportRefunds &&
        reportRefunds.content &&
        reportRefunds.content.length > 0 ? (
          <Row className={s.customRowCSV}>
            <Col className={s.customTextoCSV}>Exportar archivo CSV</Col>
            <Col className={s.customLogoCSV}>
              <Button
                id="btnLockerSummaryExportCsv"
                color="link"
                onClick={this.onClickDownloadCsvFile}
              >
                <FontAwesomeIcon icon={faFileExport} color="black" />
              </Button>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <Row className={s.customRow}>
          <Col>
            <ReactTable
              manual
              data={reportRefunds ? reportRefunds.content : []}
              pages={reportRefunds ? reportRefunds.totalPages : 0}
              columns={this.columns}
              className="w-100"
              onFetchData={this.onFetchData}
              defaultPageSize={20}
              sortable={false}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(RefoundsReport));
