import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button, Form } from "reactstrap";
import s from "./styles.scss";
import { Person, DayPass } from "./interfaces";
import PersonSelector from "../../../People/containers/PersonSelector";
import moment from "moment";
import DocumentsTable from "../../containers/documentsTable";
import { ID_MEMBRESIA_SOCIO_INVITED } from "../../../utils/constants";
import BaseInput from "../../../toolkit/baseInput";
import axios from "axios";
import constants from "../../../utils/constants";
import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import ReactTable from "react-table";
import { PassDocumentsModal } from "./documents/passDocumentsModal";
import { Archivo, RequestArchivo } from "../expedientsV2controls/types";
import { Preview } from "../../../types/IAction";
import ArchivoControl from "../expedientsV2controls/archivo/archivo";

// TODO: Implementar patrón Redux (Revisión 2)
interface Props {
  discounts: {
    dayPassList: object[];
  };
  getPassDayList: (personaId: number) => any;
  activateDayPass: (dayPass: any) => any;
  clearCodes: () => any;
  clearSelectedPerson: () => any;
  clubs: any[];
  postRefresh: (requestArchivo: RequestArchivo) => Preview;
  getArchivoVigente: (idExpediente, idItem) => Preview;
}

interface State {
  person: Person;
  passesInfo: any[];
  passInfo: any;
  passUsed: any;
  fechaInicioVigencia: string;
  fechaFinVigencia: string;
  isModalOpen: boolean;
  filters: {
    idClub: string;
    idPublico: string;
  };
  pase: any;
  queryString: string;
  isDocumentOpen: boolean;
  docIdentificacion: Archivo;
  docResponsiva: Archivo;
  docIdentificacionStorage: string;
  docResponsivaStorage: string;
}

class OneDayPassActivatorPage extends React.Component<Props, State> {
  state = {
    person: null,
    passesInfo: [],
    passInfo: null,
    passUsed: null,
    fechaInicioVigencia: moment().format("YYYY-MM-DD"),
    fechaFinVigencia: moment().format("YYYY-MM-DD"),
    isModalOpen: false,
    filters: {
      idClub: "",
      idPublico: ""
    },
    pase: null,
    queryString: "",
    isDocumentOpen: false,
    docIdentificacion: null as Archivo,
    docResponsiva: null as Archivo,
    docIdentificacionStorage: null,
    docResponsivaStorage: null
  };

  onChangeFilters = data => {
    this.setState({ ...this.state, ...data });
  };

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  search = async (idPersona?) => {
    const queryString = idPersona
      ? "?idPersona=" + idPersona
      : "?idClub=" +
        this.state.filters.idClub +
        "&idPublico=" +
        this.state.filters.idPublico;
    this.setState({
      queryString
    });
    this.searchByQueryString(queryString);
  };

  searchByQueryString = async queryString => {
    try {
      const response = await axios.get(
        `${constants.BASE_URL}/api/products/day-passes${queryString}`,
        {
          withCredentials: true
        }
      );

      this.setState({
        passesInfo: response.data
      });

      successNotification("Pases cargados correctamente");
    } catch (error) {
      const message =
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
        "Error, por favor intente de nuevo más tarde";
      errorNotification(message);
    }
  };

  setPerson = (person: Person) => {
    if (person) {
      this.setState({ person });
      this.search(person.personaId);
    }
  };

  activatePass = async () => {
    try {
      infoNotification("Activando pase, espere un momento por favor");
      const passInfo = this.state.passInfo;
      const pass = passInfo.pases[0];
      const dayPass = {
        id: pass.id,
        idMembresiaSocio: passInfo.idMembresiaSocio,
        idClub: passInfo.idClub,
        idPersona: passInfo.idPersona,
        idProducto: passInfo.idProducto,
        tipo: pass.tipo,
        cantidad: pass.cantidad,
        storageIdentificacion: this.state.docIdentificacionStorage,
        storageLiberacion: this.state.docResponsivaStorage
      };

      await axios.post(
        `${constants.BASE_URL}/api/discountCode/activateDayPass`,
        dayPass,
        {
          withCredentials: true
        }
      );

      this.searchByQueryString(this.state.queryString);
      successNotification(
        "Pases activado correctamente, puede permitir el acceso"
      );

      this.setState({
        passInfo: null
      });
    } catch (error) {
      const message =
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
        "Error, por favor intente de nuevo.";
      errorNotification(message);
    }
  };

  toggleDocument = () => {
    this.setState({
      isDocumentOpen: !this.state.isDocumentOpen
    });
  };

  render() {
    return (
      <Container className="mt-3 mb-5 p-0">
        <Row className="my-4">
          {/* Búsqueda por socio */}
          <Col md="4">
            <div style={{ marginBottom: "15px" }}>
              <p className={`${s.primaryTitle} w-100 m-0`}>Buscar por socio</p>
            </div>
            <div style={{ paddingRight: "15px" }}>
              <PersonSelector setPerson={this.setPerson} cKey={"MDOS0GJXZT"} />
            </div>
          </Col>

          {/* Búsqueda por número de membresía */}
          <Col md="8">
            <div style={{ marginBottom: "15px" }}>
              <p className={`${s.primaryTitle} w-100 m-0`}>
                Buscar por club y número membresía
              </p>
            </div>

            <Form
              onSubmit={e => {
                e.preventDefault();
                this.search();
              }}
            >
              <Row>
                <Col>
                  <BaseInput
                    label="Club"
                    name="club"
                    id="club"
                    type="select"
                    placeholder="Seleccionar Club"
                    value={this.state.filters.idClub}
                    onChange={this.onChangeFilter("idClub")}
                    size={"sm"}
                    options={[
                      { value: "", label: "Seleccione un club" }
                    ].concat(
                      (this.props.clubs || []).map(c => ({
                        value: c.clubId,
                        label: c.nombre
                      }))
                    )}
                  />
                </Col>
                <Col>
                  <BaseInput
                    label={"# Membresia"}
                    name={"idMembresiaSocio"}
                    id={"idMembresiaSocio"}
                    placeholder="Id Membresia Socio"
                    value={this.state.filters.idPublico}
                    size="sm"
                    onChange={this.onChangeFilter("idPublico")}
                    onKeyPress={target => {
                      if (target.charCode == 13) {
                        this.search();
                      }
                    }}
                  />
                </Col>
                <Col>
                  <Button
                    size="sm"
                    className={`${s.searchButton} ${s.primaryButton} float-right btn-block`}
                    type="submit"
                    disabled={
                      !this.state.filters.idClub ||
                      !this.state.filters.idPublico ||
                      this.state.passInfo
                    }
                    style={{ marginTop: "25px" }}
                  >
                    Buscar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>

        {/* Tabla con los datos del socio */}
        <p className={s.grayTitle}>Datos del socio</p>
        <ReactTable
          data={this.state.passesInfo}
          pageSize={
            this.state.passesInfo.length > 3
              ? this.state.passesInfo.length + 1
              : 3
          }
          showPagination={false}
          className="w-100 -striped -highlight"
          columns={[
            {
              Header: "Membresía",
              className: "text-center",
              accessor: "idPublico"
            },
            {
              Header: "Nombre",
              headerClassName: "text-left",
              className: "text-left",
              accessor: "nombreCompleto"
            },
            {
              Header: "Pases disponibles",
              className: "text-center",
              accessor: "cantidad"
            },
            {
              Header: "Utilizar pase",
              headerClassName: "text-left",
              className: "text-left",
              id: "fakeId",
              accessor: row => {
                // Si el pase de esta línea fue seleccionado, se puede cancelar
                if (this.state.passInfo === row) {
                  return (
                    <Button
                      size="sm"
                      onClick={() => {
                        this.setState({
                          passInfo: null
                        });
                      }}
                      className={s.rowDangerButton}
                    >
                      Cancelar
                    </Button>
                  );
                }

                // Si tiene pases disponibles, se permite su uso
                if (row.pases && row.pases.length > 0) {
                  return (
                    <Button
                      size="sm"
                      disabled={this.state.passInfo}
                      onClick={async () => {
                        const identificacionPreview = await this.props.postRefresh(
                          {
                            codigo: "IDENTIFICACION_INVITADO",
                            idMembresiaSocio: row.idMembresiaSocio || null,
                            idPersonaExpediente: row.idPersona,
                            idPersonaDocumento: row.idPersona
                          }
                        );

                        const liberacionPreview = await this.props.postRefresh({
                          codigo: "LIBERACION_INVITADO",
                          idMembresiaSocio: row.idMembresiaSocio || null,
                          idPersonaExpediente: row.idPersona,
                          idPersonaDocumento: row.idPersona
                        });

                        if (
                          identificacionPreview.fulfilled &&
                          liberacionPreview.fulfilled
                        ) {
                          this.setState({
                            passInfo: row,
                            docIdentificacion:
                              identificacionPreview.archivoRefresh,
                            docResponsiva: liberacionPreview.archivoRefresh
                          });
                        }
                      }}
                      className={s.rowButton}
                    >
                      Utilizar pase
                    </Button>
                  );
                } else {
                  return "Sin pases disponibles";
                }
              }
            },
            {
              Header: "Pases utilizados",
              headerClassName: "text-left",
              className: "text-left",
              id: "fake2Id",
              accessor: row => {
                return (
                  <Button
                    size="sm"
                    disabled={this.state.passInfo}
                    onClick={async () => {
                      try {
                        infoNotification("Buscando pases utilizados");
                        const response = await axios.get(
                          `${constants.BASE_URL}/api/products/day-passes-used?idPersona=${row.idPersona}`,
                          {
                            withCredentials: true
                          }
                        );

                        this.setState({
                          passUsed: response.data,
                          isDocumentOpen: true
                        });
                      } catch (error) {
                        const message =
                          (error &&
                            error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                          "Error al consultar los pases";
                        errorNotification(message);
                      }
                    }}
                    className={s.rowButton}
                  >
                    Ver pases
                  </Button>
                );
              }
            }
          ]}
        />
        <br />

        <p className={s.grayTitle}>Documentos Invitado</p>
        <Row className="my-4">
          <Col>
            {this.state.passInfo && false && (
              <DocumentsTable
                idMembresiaSocio={ID_MEMBRESIA_SOCIO_INVITED}
                idPersona={this.state.passInfo.idPersona}
                partnerDocuments={[].map(document => ({
                  ...document,
                  acciones: document.acciones.reduce((accum, accion) => {
                    accum[accion.nombre] = {
                      ...accion,
                      disponible: accion.disponible,
                      estatus: "FALSE"
                    };
                    return accum;
                  }, {})
                }))}
              />
            )}
          </Col>
        </Row>
        <Row className="my-4">
          <Col>
            {this.state.passInfo &&
              this.state.docIdentificacion &&
              this.state.docResponsiva && (
                <>
                  <ArchivoControl
                    archivo={this.state.docIdentificacion}
                    userId={0}
                    botonCorreo={false}
                    botonRegenrar={false}
                    update={async () => {
                      const preview = await this.props.getArchivoVigente(
                        this.state.docIdentificacion.idExpediente,
                        this.state.docIdentificacion.idDocumento
                      );

                      if (preview.fulfilled) {
                        this.setState({
                          docIdentificacion: preview.archivoVigente,
                          docIdentificacionStorage:
                            preview.archivoVigente.storage
                        });
                      } else {
                        errorNotification(
                          "No está disponible la identificación escaneada, no podrá activar el pase"
                        );
                      }

                      return preview;
                    }}
                  />
                  <ArchivoControl
                    archivo={this.state.docResponsiva}
                    userId={0}
                    botonCorreo={false}
                    botonRegenrar={false}
                    update={async () => {
                      const preview = await this.props.getArchivoVigente(
                        this.state.docResponsiva.idExpediente,
                        this.state.docResponsiva.idDocumento
                      );

                      if (preview.fulfilled) {
                        this.setState({
                          docResponsiva: preview.archivoVigente,
                          docResponsivaStorage: preview.archivoVigente.storage
                        });
                      } else {
                        errorNotification(
                          "No está disponible la responsiva escaneada, no podrá activar el pase"
                        );
                      }

                      return preview;
                    }}
                  />
                </>
              )}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col sm={{ size: 3, offset: 9 }}>
            <Button
              block
              size="sm"
              onClick={() => this.activatePass()}
              disabled={
                !this.state.passInfo ||
                !this.state.docIdentificacionStorage ||
                !this.state.docResponsivaStorage
              }
              className={s.primaryButton}
            >
              Activar pase
            </Button>
          </Col>
        </Row>
        <PassDocumentsModal
          isDocumentOpen={this.state.isDocumentOpen}
          toggleDocument={this.toggleDocument}
          dayPassInfo={this.state.passUsed}
        />
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(OneDayPassActivatorPage));
