import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import { LockerDto } from "../../types/Lockers";
import { getErrorMessage } from "../../utils/helpers";
import {
  infoNotification,
  errorNotification,
  successNotification,
  SAVE_LOCKER_ASSIGNMENT,
  SAVE_LOCKER_ASSIGNMENT_ERROR,
  SAVE_LOCKER_ASSIGNMENT_PENDING,
  SAVE_LOCKER_PENDING,
  SAVE_LOCKER_ERROR,
  SAVE_LOCKER,
  GET_LOCKERS_SUMMARY_BY_CLUB_ERROR,
  ERROR
} from "../../utils/notifications";

export interface GetLockersProducts {
  lockersProducts: any;
  gettingLockersProductsError: object;
  gettingLockersProducts: boolean;
}

export interface GetLockerProduct {
  lockerProduct: any;
  gettingLockerProductError: string;
  gettingLockerProduct: boolean;
}

export interface PutLockerExtension {
  puttingLockerExtension: boolean;
  puttingLockerExtensionError: string;
}

interface State
  extends GetLockersProducts,
    PutLockerExtension,
    GetLockerProduct {
  lockersAssigned: object[];
  gettingLockersAssignedError: object;
  gettingLockersAssigned: boolean;
  clubWalls: object;
  gettingClubWallsError: object;
  gettingClubWalls: boolean;
  clubWallLockers: LockerDto[];
  gettingClubWallLockersError: object;
  gettingClubWallLockers: boolean;
  gettingLockersSummary: boolean;
  lockersSummary: object[];
}

const initialState: State = {
  lockersAssigned: [],
  gettingLockersAssignedError: null,
  gettingLockersAssigned: false,
  clubWalls: {},
  gettingClubWallsError: null,
  gettingClubWalls: false,
  clubWallLockers: [],
  gettingClubWallLockersError: null,
  gettingClubWallLockers: false,
  lockersProducts: [],
  gettingLockersProductsError: null,
  gettingLockersProducts: false,
  gettingLockersSummary: false,
  lockersSummary: [],
  puttingLockerExtension: false,
  puttingLockerExtensionError: null,
  lockerProduct: null,
  gettingLockerProductError: null,
  gettingLockerProduct: false
};

import { readMessageBack } from "../../utils/readMessageBack";

export default function lockersReducer(
  state: State = initialState,
  action: IAction
): State {
  const snapshot = snapshotAction(state, action);

  // Llamada a resolver de snapshot
  state != snapshot &&
    !action.type.endsWith("_PENDING") &&
    action.meta &&
    action.meta.resolver &&
    action.meta.resolver({
      fulfilled: action.type.endsWith("_FULFILLED"),
      ...snapshot
    });

  return state != snapshot
    ? {
        ...state,
        ...snapshot
      }
    : state;
}

function snapshotAction(
  state: State = initialState,
  action: IAction
): Partial<State> {
  switch (action.type) {
    case `${actionTypes.GET_ASSIGNED_LOCKERS}_PENDING`: {
      return {
        gettingLockersAssignedError: null,
        gettingLockersAssigned: true
      };
    }
    case `${actionTypes.GET_ASSIGNED_LOCKERS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingLockersAssignedError: getErrorMessage(action.payload),
        gettingLockersAssigned: false
      };
    }
    case `${actionTypes.GET_ASSIGNED_LOCKERS}_FULFILLED`: {
      return {
        gettingLockersAssignedError: null,
        lockersAssigned: action.payload.data,
        gettingLockersAssigned: false
      };
    }
    case `${actionTypes.GET_LOCKERS_WALLS_BY_CLUB_ID}_PENDING`: {
      return {
        gettingClubWallLockersError: null,
        gettingClubWalls: true
      };
    }
    case `${actionTypes.GET_LOCKERS_WALLS_BY_CLUB_ID}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingClubWallLockersError: getErrorMessage(action.payload),
        gettingClubWalls: false
      };
    }
    case `${actionTypes.GET_LOCKERS_WALLS_BY_CLUB_ID}_FULFILLED`: {
      return {
        clubWalls: action.payload.data,
        gettingClubWallLockersError: null,
        gettingClubWalls: false
      };
    }
    case `${actionTypes.GET_LOCKERS_BY_WALL_ID}_PENDING`: {
      return {
        gettingClubWallLockersError: null,
        gettingClubWallLockers: true
      };
    }
    case `${actionTypes.GET_LOCKERS_BY_WALL_ID}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingClubWallLockersError: getErrorMessage(action.payload),
        gettingClubWallLockers: false
      };
    }
    case `${actionTypes.GET_LOCKERS_BY_WALL_ID}_FULFILLED`: {
      return {
        clubWallLockers: action.payload.data,
        gettingClubWallLockersError: null,
        gettingClubWallLockers: false
      };
    }
    case `${actionTypes.GET_LOCKERS_PRODUCTS}_PENDING`: {
      return {
        gettingLockersProductsError: null,
        gettingLockersProducts: true
      };
    }
    case `${actionTypes.GET_LOCKERS_PRODUCTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingLockersProductsError: getErrorMessage(action.payload),
        gettingLockersProducts: false
      };
    }
    case `${actionTypes.GET_LOCKERS_PRODUCTS}_FULFILLED`: {
      return {
        lockersProducts: action.payload.data,
        gettingLockersProductsError: null,
        gettingLockersProducts: false
      };
    }
    case `${actionTypes.GET_LOCKER_PRODUCT}_PENDING`: {
      return {
        lockerProduct: null,
        gettingLockerProductError: null,
        gettingLockerProduct: true
      };
    }
    case `${actionTypes.GET_LOCKER_PRODUCT}_REJECTED`: {
      return {
        lockerProduct: null,
        gettingLockerProductError: readMessageBack(action, ERROR),
        gettingLockerProduct: false
      };
    }
    case `${actionTypes.GET_LOCKER_PRODUCT}_FULFILLED`: {
      return {
        lockerProduct: action.payload.data,
        gettingLockerProductError: null,
        gettingLockerProduct: false
      };
    }
    case `${actionTypes.SAVE_LOCKER_ASSIGNMENT}_PENDING`: {
      infoNotification(SAVE_LOCKER_ASSIGNMENT_PENDING);
      return {};
    }
    case `${actionTypes.SAVE_LOCKER_ASSIGNMENT}_REJECTED`: {
      errorNotification(readMessageBack(action, SAVE_LOCKER_ASSIGNMENT_ERROR));
      return {};
    }
    case `${actionTypes.SAVE_LOCKER_ASSIGNMENT}_FULFILLED`: {
      successNotification(SAVE_LOCKER_ASSIGNMENT);
      return {};
    }
    case `${actionTypes.PUT_LOCKER_EXTENSION}_PENDING`:
      return {
        puttingLockerExtension: true,
        puttingLockerExtensionError: null
      };
    case `${actionTypes.PUT_LOCKER_EXTENSION}_REJECTED`:
      return {
        puttingLockerExtension: false,
        puttingLockerExtensionError: readMessageBack(action, ERROR)
      };
    case `${actionTypes.PUT_LOCKER_EXTENSION}_FULFILLED`:
      return {
        puttingLockerExtension: false,
        puttingLockerExtensionError: null
      };
    case `${actionTypes.SAVE_LOCKERS}_PENDING`: {
      infoNotification(SAVE_LOCKER_PENDING);
      return {};
    }
    case `${actionTypes.SAVE_LOCKERS}_REJECTED`: {
      errorNotification(readMessageBack(action, SAVE_LOCKER_ERROR));
      return {};
    }
    case `${actionTypes.SAVE_LOCKERS}_FULFILLED`: {
      successNotification(SAVE_LOCKER);
      return {};
    }
    case `${actionTypes.GET_LOCKERS_SUMMARY_BY_CLUB}_PENDING`: {
      return {
        gettingLockersSummary: true
      };
    }
    case `${actionTypes.GET_LOCKERS_SUMMARY_BY_CLUB}_REJECTED`: {
      errorNotification(
        readMessageBack(action, GET_LOCKERS_SUMMARY_BY_CLUB_ERROR)
      );
      return {
        gettingLockersSummary: false,
        lockersSummary: []
      };
    }
    case `${actionTypes.GET_LOCKERS_SUMMARY_BY_CLUB}_FULFILLED`: {
      return {
        gettingLockersSummary: false,
        lockersSummary: action.payload.data
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
