import React, { Component } from "react";
import { ReduxConnect } from "../../../../../toolkit/decorators/Artifact";
import InvitadoDetalle from "../../../../../types/InvitadoDetalle";
import { MemoTable } from "./TabBitacora.table";
import { PaymentMovement } from "../../../paymentMovements";
import { Container } from "reactstrap";
import { tabBitacoraStore, TabBitacoraStore } from "./TabBitacora.store";
import {
  tabBitacoraDispatcher,
  TabBitacoraDispatcher
} from "./TabBitacora.dispatcher";

interface Props extends TabBitacoraStore, TabBitacoraDispatcher {
  detalle: InvitadoDetalle;
}

interface State {
  showCreditNotes: boolean;
  row: PaymentMovement;
}

@ReduxConnect(tabBitacoraStore, tabBitacoraDispatcher)
export default class TabBitacora extends Component<Props, State> {
  state: Readonly<State> = {
    showCreditNotes: false,
    row: null as PaymentMovement
  };

  componentDidMount() {
    this.props.getBitacoraInvitado(this.props.detalle.idPersona);
  }

  render() {
    return (
      <Container>
        <MemoTable
          data={this.props.bitacoraInvitado}
          loading={this.props.gettingBitacoraInvitado}
        />
      </Container>
    );
  }
}
