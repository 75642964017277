import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import s from "./styles.scss";

interface Props {
  title: string;
  isOpen: boolean;
  toggle: () => void;
  size?: string;
}

class ModalFrame extends React.Component<Props, void> {
  render() {
    const { size, title, toggle, children, ...config } = this.props;

    return (
      <Modal
        size={size || "md"}
        backdrop="static"
        keyboard={false}
        {...config}
        toggle={toggle}
        contentClassName={s.modalBorder}
        className={size == "lg" ? s.large : ""}
      >
        {title && (
          <ModalHeader className={s.modalHeader} toggle={toggle}>
            {title}
          </ModalHeader>
        )}
        <ModalBody className={s.modalPadding}>{children}</ModalBody>
      </Modal>
    );
  }
}

export default withStyles(s)(ModalFrame);
