import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Row, Col } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import s from "./styles.scss";

interface Props {
  onChangeField: (e: object, field: string) => any;

  metodoPagoData: { idCampo; label }[];
  formaPagoData: { idCampo; label }[];
  condicionPagoData: { idCampo; label }[];
  expedicionData: { idCampo; label }[];
  claveProductoData: { idCampo; label }[];
  claveUnidadData: { idCampo; label }[];
  claveImpuestoData: { idCampo; label }[];
  disabled: boolean;
  metodoPago: null;
  formaPago: null;
  condicionPago: null;
  expedicion: null;
  claveProducto: null;
  claveUnidad: null;
  claveImpuesto: null;
  billingData: {
    billingCatalogs: any[];
    dataResponse: object;
    gettingCatalogs: boolean;
    sendingData: boolean;
    successSend: boolean;
  };
}

interface State {}

class FiscalData extends React.Component<Props, State> {
  render() {
    let CatUnidad = [];

    for (let catalog of this.props.billingData.billingCatalogs) {
      if (catalog["clave"] == "CatImpuesto") CatUnidad = catalog["data"];
    }

    return (
      <Row>
        <Col sm="4">
          <BaseInput
            disabled={this.props.disabled}
            label="* Clave Unidad"
            name="claveUnidad"
            type="select"
            id="claveUnidad"
            size={"sm"}
            value={this.props.claveUnidad}
            options={[
              { value: -1, label: "Seleccionar la Clave Unidad" }
            ].concat(
              CatUnidad.map(({ clave, descripcion }) => {
                return {
                  value: clave,
                  label: descripcion
                };
              })
            )}
            onChange={e => this.props.onChangeField(e, "claveUnidad")}
          />
        </Col>
      </Row>
    );
  }
}

export default withStyles(s)(FiscalData);
