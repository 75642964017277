import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import { ReduxConnect } from "../../../toolkit/decorators/Artifact";
import {
  InvitadosDetalleDispatcher,
  invitadosDetalleDispatcher
} from "./InvitadosDetalle.dispatcher";
import {
  InvitadosDetalleStore,
  invitadosDetalleStore
} from "./InvitadosDetalle.store";
import InvitadosDetalleComponent from "../../components/invitadosDetalle";

interface Props extends InvitadosDetalleStore, InvitadosDetalleDispatcher {}
interface State {}

@ReduxConnect(invitadosDetalleStore, invitadosDetalleDispatcher)
export default class InvitadosDetalleContainer extends React.Component<
  Props,
  State
> {
  render() {
    return (
      <main>
        <Layout>
          <Helmet title="InvitadosDetalle especiales" />
          <InvitadosDetalleComponent {...this.props} />
        </Layout>
      </main>
    );
  }
}
