import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import { withRouter } from "react-router-dom";
import MaintenanceConfigurationForm from "../maintenanceConfigurationForm";
import s from "./styles.scss";

interface Props {
  productId: string;
  match: any;
  history: any;
  successfulSave: boolean;
  config: object;
  memberships: object[];
  accountingAccounts: object[];
  addClubsToProducts: (
    productId: string,
    tipoProducto: string,
    clubs: object[]
  ) => any;
  saveConfigurations: (
    productId: string,
    tipoProducto: string,
    confg: object
  ) => any;
  updateConfigurations: (
    productId: string,
    tipoProducto: string,
    confg: object
  ) => any;
  getProductConfig: (tipoProducto: string, id: string) => any;
  getMemberships: () => any;
  getAccountingAccounts: () => any;
  onClickSave: (e: object) => any;
}

interface State {}

class MaintenanceConfiguration extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    const productId = this.props.match.params.productId;

    if (productId) {
      this.props.getProductConfig(
        this.props.match.params.tipoProducto,
        productId
      );
    }

    this.props.getMemberships();
    this.props.getAccountingAccounts();
  }

  componentDidUpdate() {}

  setClubs = clubs =>
    this.props.addClubsToProducts(
      this.props.match.params.productId,
      this.props.match.params.tipoProducto,
      clubs
    );

  render() {
    return (
      <MaintenanceConfigurationForm
        setClubs={this.setClubs}
        successfulSave={this.props.successfulSave}
        saveConfigurations={this.props.saveConfigurations}
        updateConfigurations={this.props.updateConfigurations}
        config={this.props.config}
        memberships={this.props.memberships}
        accountingAccounts={this.props.accountingAccounts}
        onClickSave={this.props.onClickSave}
      />
    );
  }
}

export default withRouter(withStyles(s)(MaintenanceConfiguration));
