import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import Reform from "@franleplant/reform";
import s from "./styles.scss";
import { FormErrors } from "@franleplant/reform/types";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import BasicDataProductFormHooks, {
  cleanState
} from "./basicDataProductFields";
import moment from "moment";

interface Category {
  name: string;
  categoriaId: number;
  subcategorias: {
    name: string;
    categoriaId: number;
  }[];
}

interface Props {
  onClickSave: (e: object) => any;
  onSuccessfulSave: () => any;
  history: any;
  client: any;
  productsCategories: Category[];
  listSubcategories: object[];
  saveBasicProduct: (tipoProducto: string, product: object) => any;
  updateBasicProduct: (
    tipoProducto: string,
    productId: string,
    product: object
  ) => any;
  successfulSave: boolean;
  match: any;
  indicators: { tagId: string; nombre: string }[];
  product: Product;
  getBasicProduct: (tipoProducto: string, id: string) => any;
  satProducts: { id: string; nombre: string }[];
  satUnits: { id: string; nombre: string }[];
  loadingProduct: boolean;
  getSubCategoriesByCategory: (idCategory: string) => any;
  conveniosList: any[];
}

interface Fields {
  nombre: string;
  estatus: string;
  unidadSAT: string;
  productoSAT: string;
  fechaInicioVigencia: string;
  fechaFinVigencia: string;
  indicadoresBI: object[];
  publicarPrecio: boolean;
  integrantes: string;
  isFechaFinVigenciaPresente: boolean;
  disableEndDate: boolean;
  editorState: EditorState;
  tipo: string;
  categoriaId: string;
  tipoReactivacion: string;
  categoriaReactivacionId: string;
  aplicaReferidos: boolean;
}

interface Product {
  datosBasicosMembresiaId?: number;
  datosBasicosMantenimientoId?: number;
  categoriaId;
  categoriaReactivacionId: string;
  nombre: string;
  estatus: string;
  isFechaFinVigenciaPresente: boolean;
  fechaInicioVigencia: string;
  fechaFinVigencia: string;
  publicarPrecio: boolean;
  unidadSAT: string;
  productoSAT: string;
  descripcion: string;
  indicadoresBI: any;
  tagsId: any;
  integrantes: number;
  convenios: any[];
}

interface State {
  fields: Fields;
  errors: FormErrors;
  loaded: boolean;
}

const productToFields = (
  {
    nombre,
    estatus,
    fechaInicioVigencia,
    fechaFinVigencia,
    publicarPrecio,
    unidadSAT,
    productoSAT,
    tagsId,
    integrantes,
    isFechaFinVigenciaPresente,
    tipo,
    categoriaId,
    subcategoriaId,
    tipoReactivacion,
    categoriaReactivacionId,
    convenios,
    aplicaReferidos
  },
  indicadores
) => {
  return {
    nombre,
    tipo: tipo || subcategoriaId,
    categoriaId,
    tipoReactivacion,
    categoriaReactivacionId,
    estatus,
    fechaInicioVigencia,
    fechaFinVigencia,
    publicarPrecio,
    unidadSAT,
    productoSAT,
    isFechaFinVigenciaPresente,
    integrantes,
    indicadoresBI: indicadores
      ? indicadores
          .filter(indicador => tagsId.indexOf(indicador.tagId) > -1)
          .map(i => {
            return { label: i.nombre, value: i.tagId };
          })
      : [],
    convenios,
    aplicaReferidos
  };
};

const getInitialState = () => ({
  nombre: "",
  estatus: "INACTIVO",
  unidadSAT: "",
  productoSAT: "",
  fechaInicioVigencia: "",
  fechaFinVigencia: "",
  indicadoresBI: [],
  publicarPrecio: false,
  integrantes: "",
  isFechaFinVigenciaPresente: false,
  disableEndDate: true,
  editorState: EditorState.createEmpty(),
  tipo: "",
  categoriaId: "",
  tipoReactivacion: "",
  categoriaReactivacionId: "",
  conveniosList: [],
  aplicaReferidos: false
});

class BasicDataProductForm extends React.Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);

  state = {
    fields: getInitialState(),
    errors: {},
    loaded: false
  };

  shouldComponentUpdate(nextProps) {
    if (
      this.props.successfulSave !== nextProps.successfulSave &&
      nextProps.successfulSave
    ) {
      this.props.onSuccessfulSave();
      return false;
    }

    return true;
  }

  componentDidMount() {
    this.loadProduct(null);
    this.props.onClickSave(e => {
      e.preventDefault();
      if (this.re.validateFormFromState()) {
        const product = cleanState(this.state.fields);

        const { tipoProducto, productId } = this.props.match.params;

        if (tipoProducto == "mantenimientos") delete product.integrantes;

        let promiseSave;
        if (!productId) {
          promiseSave = this.props.saveBasicProduct(tipoProducto, product);
        } else {
          promiseSave = this.props.updateBasicProduct(
            tipoProducto,
            productId,
            product
          );
        }

        if (promiseSave) {
          return promiseSave.then(({ value: { data } }) => {
            const id =
              data.datosBasicosMantenimientoId || data.datosBasicosMembresiaId;
            const { tipoProducto } = this.props.match.params;
            this.props.history.push(`/${tipoProducto}/datos-basicos/${id}`);
          });
        }
      }
    });
  }

  setProductState = product => {
    this.setState(state => ({
      ...state,
      fields: {
        ...productToFields(product, this.props.indicators),
        disableEndDate: !product.isFechaFinVigenciaPresente,
        editorState: EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(product.descripcion).contentBlocks
          )
        )
      }
    }));
  };

  loadProduct(prevProduct) {
    const { tipoProducto, productId } = this.props.match.params;
    const { product, loadingProduct } = this.props;

    const productToLoad = product;
    if (productId && !prevProduct && productToLoad && this.props.indicators) {
      this.setState({ loaded: true });
      this.setProductState(productToLoad);
    }

    const datosBasicosProductoId =
      product &&
      (tipoProducto === "mantenimientos"
        ? product.datosBasicosMantenimientoId
        : product.datosBasicosMembresiaId);

    if (
      !loadingProduct &&
      productId &&
      (!datosBasicosProductoId ||
        datosBasicosProductoId !== parseInt(productId))
    ) {
      this.props.getBasicProduct(tipoProducto, productId);
    }

    if (!loadingProduct && !productId && !this.state.loaded) {
      this.setState({ loaded: true });
    }
  }

  componentDidUpdate(prevProps) {
    this.loadProduct(prevProps.product);
  }

  dateValidator = dateInput => {
    const { productId: id } = this.props.match.params;
    if (id && this.props.product.fechaInicioVigencia === dateInput) {
      return false;
    }
    const date = moment(dateInput).startOf("day");
    const now = moment().startOf("day");
    return date.isBefore(now);
  };

  bothDatesValidator = (dateInput, otherInput) => {
    const date = new Date(dateInput);
    const other = new Date(otherInput);
    return dateInput && otherInput && date >= other;
  };

  membershipsScreen = () =>
    this.props.match.params.tipoProducto === "membresias";

  mantenimientosScreen = () =>
    this.props.match.params.tipoProducto === "mantenimientos";

  validationRules = {
    fechaInicioVigencia: {
      required: true,
      dateMustBeFuture: value => this.dateValidator(value),
      bothDates: value =>
        this.bothDatesValidator(value, this.state.fields.fechaFinVigencia)
    },
    fechaFinVigencia: {
      dateMustBeFuture: value => this.dateValidator(value),
      bothDates: value =>
        this.bothDatesValidator(this.state.fields.fechaInicioVigencia, value),
      required: value => !this.state.fields.disableEndDate && !value
    },
    nombre: { required: true },
    categoriaId: { required: true },
    categoriaReactivacionId: {
      required: value => this.membershipsScreen() && !value
    },
    indicadoresBI: { required: value => !value.length },
    integrantes: { required: value => this.membershipsScreen() && !value },
    tipo: { required: true }
  };

  validationMessages = {
    required: _ => `Obligatorio`,
    dateMustBeFuture: _ => "La fecha debe ser futura",
    bothDates: _ => "La fecha inicial debe ser menor a la final",
    minLength: ruleValue => `Debe tener al menos ${ruleValue} caracteres`,
    maxLength: ruleValue => `Deber tener maximo ${ruleValue} caracteres`,
    default: _ => `Inválido`
  };

  render() {
    return this.props.indicators && this.state.loaded ? (
      <BasicDataProductFormHooks
        satUnits={this.props.satUnits}
        satProducts={this.props.satProducts}
        initialState={this.state.fields}
        re={this.re}
        indicators={this.props.indicators}
        productsCategories={this.props.productsCategories}
        listSubcategories={this.props.listSubcategories}
        membershipsScreen={this.membershipsScreen()}
        setstate={state => this.setState({ fields: { ...state } })}
        getSubCategoriesByCategory={this.props.getSubCategoriesByCategory}
        mantenimientosScreen={this.mantenimientosScreen()}
        conveniosList={this.props.conveniosList}
      />
    ) : null;
  }
}

export default withRouter(withStyles(s)(BasicDataProductForm));
