import React from "react";
import { Container, TabPane, Button } from "reactstrap";
import { userHasPermission } from "../../../../utils/accessTree";
import permissionCodes from "../../../../utils/permissionCodes";
import DiscountCodesTable from "./discountCodesTable";
import ModalAddGuestPassesDiscounts from "../modalAddGuestPassesDiscounts";
import ModalAddProductsDiscounts from "../modalAddProductsDiscounts";
import { getClassificationByLevelRequest } from "../../../actions/products";
import { handleRequestError } from "../../../../utils/helpers";

interface Props {
  discountCodes: any[];
  idMembresiaSocio: string;
  invalidateStateValue: (fieldName: string, value: any) => void;
  getMembershipDiscountCodes: () => void;
}

interface State {
  modalGuestsPassesIsOpen: boolean;
  modalProductsDiscountsIsOpen: boolean;
  classifications: any[];
}

class TabDiscountCodes extends React.Component<Props, State> {
  state = {
    modalGuestsPassesIsOpen: false,
    modalProductsDiscountsIsOpen: false,
    classifications: []
  };

  componentDidMount() {
    getClassificationByLevelRequest(3)
      .then(({ data }) => {
        this.setState({ classifications: [...data] });
      })
      .catch(handleRequestError);
  }

  toggleModalFreeGuests = () => {
    this.setState({
      modalGuestsPassesIsOpen: true
    });
  };

  clearModalFreeGuests = () => {
    this.setState({
      modalGuestsPassesIsOpen: false
    });
  };

  toggleModalProductsDiscounts = () => {
    this.setState({
      modalProductsDiscountsIsOpen: true
    });
  };

  clearModalProductsDiscounts = () => {
    this.setState({
      modalProductsDiscountsIsOpen: false
    });
  };

  render() {
    const { discountCodes } = this.props;
    return (
      <TabPane className="my-2" tabId="15">
        <Container className="p-0">
          <ModalAddGuestPassesDiscounts
            isOpen={this.state.modalGuestsPassesIsOpen}
            partnerMembershipId={this.props.idMembresiaSocio}
            onOk={() => {
              this.clearModalFreeGuests();
              this.props.invalidateStateValue("discountCodes", []);
              this.props.getMembershipDiscountCodes();
            }}
            onCancel={() => {
              this.clearModalFreeGuests();
            }}
          />

          <ModalAddProductsDiscounts
            isOpen={this.state.modalProductsDiscountsIsOpen}
            classifications={this.state.classifications || []}
            partnerMembershipId={this.props.idMembresiaSocio}
            fitkidz={false}
            onOk={() => {
              this.clearModalProductsDiscounts();
              this.props.invalidateStateValue("discountCodes", []);
              this.props.getMembershipDiscountCodes();
            }}
            onCancel={() => {
              this.clearModalProductsDiscounts();
            }}
          />

          <div className="m-4 text-right">
            {userHasPermission(
              permissionCodes.PARTNER_MEMBERSHIP_TAB_DISCOUNT_CODES_GENERATE_GUESTS_PASSES
            ) && (
              <React.Fragment>
                <Button
                  className="ml-4"
                  size="sm"
                  onClick={this.toggleModalFreeGuests}
                >
                  Agregar descuentos de pases de invitado
                </Button>
              </React.Fragment>
            )}

            {userHasPermission(
              permissionCodes.PARTNER_MEMBERSHIP_TAB_DISCOUNT_CODES_GENERATE_PRODUCT_DISCOUNT
            ) && (
              <React.Fragment>
                <Button
                  className="ml-4"
                  size="sm"
                  onClick={this.toggleModalProductsDiscounts}
                >
                  Agregar descuentos para productos
                </Button>
              </React.Fragment>
            )}
          </div>
          <DiscountCodesTable
            discountCodesList={discountCodes || []}
            partnerMembershipId={this.props.idMembresiaSocio}
            invalidateStateValue={this.props.invalidateStateValue}
            getMembershipDiscountCodes={this.props.getMembershipDiscountCodes}
          />
        </Container>
      </TabPane>
    );
  }
}

export default TabDiscountCodes;
