import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import BillSearch from "./billSearch";
import IAction from "../../../types/IAction";

interface Props {
  getAccountingAccount: (fields: object) => IAction;
  getWayToPay: (fields: object) => IAction;
  toggleBill: (storageId: number) => any;
  downloadReportAccountingAccount: (body: object) => IAction;
  downloadReportWayToPay: (body: object) => IAction;
  clubs: object[];
  tipoReporte: object[];
  accountingAccounts: object[];
  reportAccountingAccount: object[];
  reportWayToPay: object[];
  getBill: (uuid: string) => any;
  billing: object[];
  createCreditNote: (fields: object) => IAction;
  banks: object;
  userContext: { clubSessionId: any };
}

interface State {}

class Bill extends React.Component<Props, State> {
  render() {
    return (
      <Container className="p-0 mt-3">
        <BillSearch
          clubs={this.props.clubs}
          accountingAccounts={this.props.accountingAccounts}
          tipoReporte={this.props.tipoReporte}
          toggleBill={this.props.toggleBill}
          getAccountingAccount={this.props.getAccountingAccount}
          getWayToPay={this.props.getWayToPay}
          reportAccountingAccount={this.props.reportAccountingAccount}
          reportWayToPay={this.props.reportWayToPay}
          downloadReportAccountingAccount={
            this.props.downloadReportAccountingAccount
          }
          downloadReportWayToPay={this.props.downloadReportWayToPay}
          getBill={this.props.getBill}
          billing={this.props.billing}
          createCreditNote={this.props.createCreditNote}
          banks={this.props.banks}
          userContext={this.props.userContext}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles()(Bill));
