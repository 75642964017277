import React from "react";
import ReactTable from "react-table";
import { Container } from "reactstrap";

interface Props {
  referredDetailList: object[];
  partnerMembershipId: String;
}

class ReferredDetailTable extends React.Component<Props> {
  columns = [
    {
      Header: "Club",
      className: "text-center",
      accessor: "referidaClubNombre"
    },
    {
      Header: "# Membresía",
      accessor: "referidaNumeroMembresia"
    },
    {
      Header: " Titular",
      accessor: "referidaNombreTitular"
    },
    {
      Header: "Código aplicado",
      className: "text-center",
      accessor: "referidaCodigo"
    },
    {
      Header: "Fecha inicio",
      className: "text-center",
      accessor: "fechaInicio"
    },
    {
      Header: "Fecha fin",
      className: "text-center",
      accessor: "fechaFin"
    },
    {
      Header: "Estatus",
      className: "text-center",
      accessor: "estatus"
    },
    {
      Header: "Tipo descuento",
      className: "text-center",
      accessor: "tipoDescuentoMembresiaReferida"
    }
  ];

  render() {
    return (
      <Container className="p-0">
        <p className="primaryTitle w-100">Membresías Socio Referidas</p>
        <ReactTable
          data={this.props.referredDetailList || []}
          columns={this.columns}
          pageSize={20}
          showPagination={true}
          className="mt-3"
        />
      </Container>
    );
  }
}

export default ReferredDetailTable;
