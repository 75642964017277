import React from "react";
import { connect } from "react-redux";
import TotalSales from "../../components/MonthlySalesTracking/TotalSales";
import { getSalesTracking } from "../../../actions/sales";
interface Props {
  clubId: string;
  month: string;
  year: string;
  getSalesTracking: (params) => any;
  saveCompromises: () => any;
  disabled: boolean;
}

class SalesTotalsContainer extends React.Component<Props> {
  getSalesTracking = async params => {
    const {
      value: { data: goals }
    } = await this.props.getSalesTracking(params);
    return goals;
  };

  render() {
    return (
      <TotalSales
        disabled={this.props.disabled}
        clubId={this.props.clubId}
        month={this.props.month}
        year={this.props.year}
        getSalesTracking={this.getSalesTracking}
        saveCompromises={this.props.saveCompromises}
      />
    );
  }
}

const mapDispatchToProps = {
  getSalesTracking
};

const mapStateToProps = () => {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesTotalsContainer);
