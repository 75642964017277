import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import ModalFrame from "../../../toolkit/modalFrame";
import s from "./photoModal.scss";
import { Container } from "reactstrap";
import WebcamCapture from "../../components/common/WebCamCapture";
import { updatePhotoProm } from "../../actions/persons";
import {
  successNotification,
  infoNotification
} from "../../../utils/notifications";
import { handleRequestError } from "../../../utils/helpers";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  history: any;
  uploadAction: (action: string, image: Blob) => void;
  partner: any;
  action: string;
}

/* interface State {
  fields: {
    image: Blob;
    previewImage: string;
  };
  errors: object;
} */

class PhotoModal extends React.Component<Props> {
  /* re = Reform.reactMixins.objectMixin(this);

  state = {
    fields: {
      image: new Blob(),
      previewImage: ""
    },
    errors: {}
  };

  validateImage = () => this.state.fields.image == new Blob();

  validationRules = {
    image: { required: true, validateImage: this.validateImage }
  };

  validationMessages = {
    required: _ => `Obligatorio`,
    validateImage: _ => `Obligatorio`,
    default: _ => `Invalido`
  };

  onSubmit = e => {
    e.preventDefault();
    if (this.re.validateFormFromState()) {
      this.props.uploadAction(this.props.action, this.state.fields.image);
    }
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState(state => ({
        ...state,
        fields: {
          image: new Blob(),
          previewImage: ""
        }
      }));
    }
  }

  onChangeFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };

        return { ...state, error: {}, fields };
      });
      this.re.validateField(fieldName, value);
    };
  };

  handleFileChosen = async ev => {
    ev.preventDefault();
    const image = ev.target.files[0];
    const previewImage = await this.getBase64(image);
    this.setState(state => {
      const fields = {
        ...this.state.fields,
        image,
        previewImage
      };
      return {
        ...state,
        fields
      };
    });
  };

  getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }; */

  render() {
    return (
      <ModalFrame
        title={`Actualizar ${this.props.action} de ${this.props.partner.nombre}`}
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <Container>
          {/* <input
              className={`${s.inputFile}`}
              type="file"
              id={`file`}
              accept="image/bmp, image/cis-cod, image/gif, image/ief, image/jpeg, image/pipeg,image/svg+xml, image/tiff, image/x-cmu-raster, image/x-cmx, image/x-icon,image/x-portable-anymap, image/x-portable-bitmap, image/x-portable-graymap,image/x-portable-pixmap, image/x-rgb, image/x-xbitmap, image/x-xpixmap, image/x-xwindowdump"
              onChange={e => this.handleFileChosen(e)}
            /> */}
          {/*  {this.state.fields.previewImage && (
              <img
                className={`${s.previewImage}`}
                src={this.state.fields.previewImage}
              />
            )} */}
          <WebcamCapture
            personId={this.props.partner.idPersona}
            saveImage={img => {
              infoNotification("Guardando foto...");
              updatePhotoProm(this.props.partner.idPersona, img)
                .then((data: any) => {
                  successNotification("Foto guardada correctamente.");
                  //const fData = JSON.parse(data.data);
                  this.props.toggle();
                })
                .catch(handleRequestError);
            }}
          />
          {/*  <Button
              className={`${s.buttonMarginTop} ${s.primaryButton}`}
              onClick={e => this.onSubmit(e)}
              size="lg"
              block
            >
              Guardar
            </Button> */}
        </Container>
      </ModalFrame>
    );
  }
}

export default withRouter(withStyles(s)(PhotoModal));
