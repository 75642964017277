import React from "react";
import { ReduxConnect } from "../../../toolkit/decorators/Artifact";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import {
  MembresiasExternasStore,
  membresiasExternasStore
} from "./MembresiasExternas.store";
import {
  MembresiasExternasDispatcher,
  membresiasExternasDispatcher
} from "./MembresiasExternas.dispatcher";
import MembresiasExternas from "../../components/membresiasExternas";

interface Props extends MembresiasExternasStore, MembresiasExternasDispatcher {}

interface State {}

@ReduxConnect(membresiasExternasStore, membresiasExternasDispatcher)
export default class MembresiasExternasPage extends React.Component<
  Props,
  State
> {
  render() {
    return (
      <main>
        <Helmet title="Agreements" />
        <Layout>
          <MembresiasExternas {...this.props} />
        </Layout>
      </main>
    );
  }
}
