import React from "react";
import ModalFrame from "../../../toolkit/modalFrame";

export default ({ title, children, isOpen, toggle }) => {
  return (
    <ModalFrame title={title} isOpen={isOpen} toggle={toggle}>
      {children}
    </ModalFrame>
  );
};
