import axios from "axios";
import constants from "../../utils/constants";

const baseUrl = `/api`;

export const deletePaymentLineRequest = (paymentLineId: number) => {
  return axios.delete(
    `${constants.BASE_URL}${baseUrl}/maintenances/payment-lines/${paymentLineId}`,
    {
      withCredentials: true
    }
  );
};

export const testPaymentLineRequest = (line: string) => {
  return axios.post(
    `${constants.BASE_URL}${baseUrl}/maintenances/payment-lines/test/${line}`,
    null,
    {
      withCredentials: true
    }
  );
};

export const getPaymentLine = (linea: string) => {
  return axios.get(`${constants.BASE_URL}/api/mit/payment-lines/public`, {
    withCredentials: true,
    params: { linea }
  });
};

export const postPaymentLineAccept = (linea: string) => {
  return axios.post(
    `${constants.BASE_URL}/api/mit/payment-lines/public/accept`,
    null,
    {
      withCredentials: true,
      params: { linea }
    }
  );
};

export const getHasLineasPagoPendientes = (idPersona: any) => {
  return axios.get(
    `${constants.BASE_URL}${baseUrl}/mit/payment-lines/clients/${idPersona}/hasPending`,
    {
      withCredentials: true
    }
  );
};
