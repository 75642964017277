import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { Input, Button } from "reactstrap";
import s from "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import ClaseImpartidaSinComision from "../../../types/ClaseImpartidaSinComision";

export interface TableProps {
  data: ClaseImpartidaSinComision[];
  loading: boolean;
  onExport: () => void;
  onCheck: (id: number, check: boolean) => void;
  onCheckAll: (check: boolean) => void;
  processed: number[];
  selected: number[];
}

export const MemoTable = React.memo(
  ({
    data,
    loading,
    onExport,
    onCheck,
    onCheckAll,
    processed,
    selected
  }: TableProps) => {
    const [checkAll, setCheckAll] = useState(false);
    const [columns, setColumns] = useState(
      columnsFormatter(onCheck, processed, selected)
    );

    // Actualización de estilos y checkboxes
    useEffect(() => {
      setColumns(columnsFormatter(onCheck, processed, selected));
    }, [processed, selected]);

    // Limpieza de checkbox para seleccionar todo, en nuevas búsquedas
    useEffect(() => {
      checkAll && setCheckAll(false);
    }, [data]);

    return (
      <>
        <div className="row">
          {/* Botón para exportar */}
          {!!data && !!data.length && (
            <>
              <div className="col-6 p-0" style={{ lineHeight: "40px" }}>
                <Input
                  type="checkbox"
                  style={{
                    marginLeft: "8px",
                    marginRight: "16px",
                    position: "unset"
                  }}
                  checked={checkAll}
                  onChange={e => {
                    const checked = e.target.checked;
                    setCheckAll(checked);
                    onCheckAll(checked);
                  }}
                  disabled={!!processed}
                />
                <span>
                  {!checkAll ? "Seleccionar" : "Descartar"} todos ({data.length}
                  )
                </span>
              </div>
              <div
                className="col-6 text-right p-0"
                style={{ lineHeight: "40px" }}
              >
                <span>Exportar a CSV: </span>
                <Button
                  id="btnReportExportCsv"
                  color="link"
                  type="button"
                  onClick={() => onExport()}
                >
                  <FontAwesomeIcon icon={faFileExport} color="black" />
                </Button>
              </div>
            </>
          )}
        </div>
        <div className="row p-0 mb-5">
          <ReactTable
            data={data || []}
            loading={loading}
            pageSize={data && data.length > 5 ? data.length : 5}
            columns={columns}
            sortable={false}
            resizable={true}
            showPagination={false}
            className={`w-100 -highlight -striped ${s.hidden}`}
          />
        </div>
      </>
    );
  }
);

const columnsFormatter = (onCheck, processed, selected) => [
  {
    Header: "",
    id: "checkbox",
    maxWidth: "32",
    className: s.tdClear,
    accessor: checkboxAccessor(onCheck, processed, selected)
  },
  {
    Header: "Id",
    id: "id",
    maxWidth: "64",
    className: s.tdClear,
    accessor: simpleAccessor("id", processed, selected)
  },
  {
    Header: "Instructor",
    id: "instructor",
    maxWidth: "296",
    className: s.tdClear,
    accessor: simpleAccessor("instructor", processed, selected)
  },
  {
    Header: "Producto",
    id: "producto",
    maxWidth: "296",
    className: s.tdClear,
    accessor: simpleAccessor("producto", processed, selected)
  },
  {
    Header: "Factura",
    id: "factura",
    maxWidth: "128",
    className: s.tdClear,
    accessor: simpleAccessor("factura", processed, selected)
  },
  {
    Header: "Impartición",
    id: "fecha",
    maxWidth: "96",
    className: s.tdClear,
    accessor: dateAccessor("fecha", processed, selected),
    headerClassName: "text-left"
  },
  {
    Header: "Cliente",
    id: "cliente",
    className: s.tdClear,
    accessor: simpleAccessor("cliente", processed, selected),
    headerClassName: "text-left"
  }
];

function fieldClassName(
  row: ClaseImpartidaSinComision,
  processed: number[],
  selected: number[]
) {
  if (!processed || !selected.includes(row.id)) {
    return `${s.field}`;
  }

  return processed.includes(row.id)
    ? `${s.field} ${s.procesada}`
    : `${s.field} ${s.noProcesada}`;
}

function simpleAccessor(
  key: keyof ClaseImpartidaSinComision,
  processed: number[],
  selected: number[]
) {
  return (row: ClaseImpartidaSinComision) => {
    return (
      <div className={fieldClassName(row, processed, selected)}>
        {row[key]}&nbsp;
      </div>
    );
  };
}

function checkboxAccessor(
  onCheck: (id: number, check) => void,
  processed: number[],
  selected: number[]
) {
  return row => (
    <div className={fieldClassName(row, processed, selected)}>
      &nbsp;
      <Input
        type="checkbox"
        style={{ left: "24px" }}
        checked={selected.includes(row.id)}
        onChange={e => onCheck(row.id, e.target.checked)}
        disabled={!!processed}
      />
    </div>
  );
}

function dateAccessor(
  key: keyof ClaseImpartidaSinComision,
  processed: number[],
  selected: number[]
) {
  return row => {
    return (
      <div className={fieldClassName(row, processed, selected)}>
        {formatDate(row[key] || "-")}
      </div>
    );
  };
}

function formatDate(isoDate: string): string {
  try {
    const [year, month, day] = isoDate.split("-");
    return `${day}/${month}/${year} `;
  } catch (error) {
    return isoDate;
  }
}
