import React from "react";
import { Container } from "reactstrap";
import { ReferidosLicenciasReporteDispatcher } from "../../containers/referidosLicenciasReporte/ReferidosLicenciasReporte.dispatcher";
import { ReferidosLicenciasReporteStore } from "../../containers/referidosLicenciasReporte/ReferidosLicenciasReporte.store";
import {
  Router,
  Styled,
  RouterProps
} from "../../../toolkit/decorators/Artifact";
import s from "./styles.scss";
import FormBuilder from "../../../toolkit/baseForms2/formBuilder/FormBuilder";
import { formConfig, Inputs } from "./ReferidosLicenciasReporte.form";
import { errorNotification } from "../../../utils/notifications";
import {
  getConLicenciaReporte,
  getReferidosReporte
} from "../../actions/partners";

interface Props
  extends ReferidosLicenciasReporteDispatcher,
    ReferidosLicenciasReporteStore,
    RouterProps {}

interface State {
  downloading: boolean;
}

@Router()
@Styled(s)
export default class ReferidosLicenciasReporteComponent extends React.Component<
  Props,
  State
> {
  state = {
    downloading: false
  };

  submit = (fields: { [key in Inputs]: any }) => {
    if (!fields.tipoReporte) {
      errorNotification("Seleccione el tipo de reporte por descargar.");
      return;
    }

    this.setState({ downloading: true });

    fields.tipoReporte == "R"
      ? getReferidosReporte(() => this.setState({ downloading: false }))
      : getConLicenciaReporte(() => this.setState({ downloading: false }));
  };

  render() {
    return (
      <>
        <Container className="p-0 mt-3">
          <FormBuilder
            config={formConfig}
            processing={this.state.downloading}
            submit={this.submit}
          />
        </Container>
      </>
    );
  }
}
