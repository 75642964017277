import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import { Club } from "../../../types-business/Clubs";
import ReactTable from "react-table";
import s from "./tabTableStyles.scss";
import { Row, Container, Col, TabPane, Button, Input } from "reactstrap";

const DEFAULT_PAGE_SIZE = 10;

interface Props {
  promotionsFilters: any;
  onChangePromotionFilter: any;
  allowedClubs: Club[];
  maintenances: Maintenance[];
  memberships: Membership[];
  searchPromotions: any;
  promotions: any;
  columnsPromotions: object;
  promotionsLoading: boolean;
  handlePromotionsRowClick: any;
  debouncedOnFetchDataPromotions: any;
}

interface Membership {
  nombre: string;
  datosBasicosMembresiaId: string;
}

interface Maintenance {
  nombre: string;
  datosBasicosMantenimientoId: string;
}

class TabContentPromotions extends React.Component<Props> {
  render() {
    return (
      <TabPane tabId="1" className="h-100">
        <Container className="px-0 h-100">
          <Row>
            <Col xs={12} md={12} lg={6}>
              <Input
                id="clubId"
                name="clubId"
                type="select"
                className={s.allowedClubs}
                value={this.props.promotionsFilters.clubId}
                disabled={this.props.allowedClubs.length === 1}
                size="xs"
                onChange={e => this.props.onChangePromotionFilter(e, "clubId")}
              >
                <option key="" value="">
                  Seleccionar Club
                </option>
                {this.props.allowedClubs.map(club => (
                  <option key={club.clubId} value={club.clubId}>
                    {club.nombre}
                  </option>
                ))}
              </Input>
            </Col>

            <Col xs={12} md={12} lg={6}>
              <Input
                id="membresiaId"
                name="membresiaId"
                type="select"
                value={this.props.promotionsFilters.membresiaId}
                className={s.allowedClubs}
                onChange={e =>
                  this.props.onChangePromotionFilter(e, "membresiaId")
                }
              >
                <option key="" value="">
                  Seleccionar Membresia
                </option>
                {this.props.memberships &&
                  this.props.memberships.map(membership => (
                    <option
                      key={membership.datosBasicosMembresiaId}
                      value={membership.datosBasicosMembresiaId}
                    >
                      {membership.nombre}
                    </option>
                  ))}
              </Input>
            </Col>

            <Col xs={12} md={12} lg={6}>
              <Input
                id="mantenimientoId"
                name="mantenimientoId"
                type="select"
                value={this.props.promotionsFilters.mantenimientoId}
                className={s.allowedClubs}
                onChange={e =>
                  this.props.onChangePromotionFilter(e, "mantenimientoId")
                }
              >
                <option key="" value="">
                  Seleccionar Mantenimiento
                </option>
                {this.props.maintenances &&
                  this.props.maintenances.map(maintenance => (
                    <option
                      key={maintenance.datosBasicosMantenimientoId}
                      value={maintenance.datosBasicosMantenimientoId}
                    >
                      {maintenance.nombre}
                    </option>
                  ))}
              </Input>
            </Col>

            <Col xs={12} md={12} lg={6}>
              <Button
                size="sm"
                className={`${s.searchButton} ${s.primaryButton} float-right btn-block`}
                onClick={this.props.searchPromotions}
              >
                Buscar
              </Button>
            </Col>
          </Row>

          <div style={{ paddingTop: "5px", height: "550px" }}>
            <ReactTable
              manual
              data={(this.props.promotions || {}).content || []}
              columns={this.props.columnsPromotions}
              className="w-100 h-100 -striped -highlight"
              loading={this.props.promotionsLoading}
              sortable={false}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              onFetchData={this.props.debouncedOnFetchDataPromotions}
              pages={(this.props.promotions || {}).totalPages || 1}
              getTrProps={(_, promotion) => {
                return {
                  onClick: () =>
                    this.props.handlePromotionsRowClick(promotion.original)
                };
              }}
            />
          </div>
        </Container>
      </TabPane>
    );
  }
}
export default withRouter(withStyles(s)(TabContentPromotions));
