import { toast } from "react-toastify";

export const GET_ACCRUAL_MESSAGE_PENDING = "Cargando esquemas de devengamiento";

export const GET_ACCRUAL_MESSAGE_ERROR =
  "Error al obtener esquemas de devengamiento";

export const GET_ACCRUAL_MESSAGE_FULLIED = "Esquemas de devengamiento cargados";

export const GET_TRANSACTION_BY_REFERENCE_MESSAGE_PENDING =
  "Cargando transacción por referencia";

export const GET_TRANSACTION_BY_REFERENCE_MESSAGE_ERROR =
  "Error al cargar transacción por referencia";

export const GET_TRANSACTION_BY_REFERENCE_MESSAGE_FULLIED =
  "Se obtuvo la transacción por referencia";

export const GET_RETURN_INVOICE_MESSAGE_PENDING =
  "Cargando facturas para devolución";

export const GET_RETURN_INVOICE_MESSAGE_ERROR =
  "Error al cargar facturas para devolución";

export const GET_RETURN_INVOICE_MESSAGE_FULLIED =
  "Se obtuvieron las facturas para devolución";

export const UPDATE_ACCRUAL_MESSAGE_PENDING =
  "Actualizando esquema de devengamiento";

export const UPDATE_ACCRUAL_MESSAGE_ERROR =
  "Error al actualizar esquema devengamiento";

export const UPDATE_ACCRUAL_MESSAGE_FULLIED =
  "Esquema de devengamiento actualizado";

export const UPDATE_PERSON_EMAIL_FULFILLED = "Datos guardados correctamente.";

export const UPDATE_PERSON_EMAIL_PENDING = "Guardando correo electronico.";

export const UPDATE_PERSON_EMAIL_REJECTED =
  "Ocurrió un error guardando el correo electronico, intentar mas tarde.";

export const UPDATE_PERSON_ADDRESS_FULFILLED = "Datos guardados correctamente.";

export const UPDATE_PERSON_ADDRESS_PENDING = "Guardando direccion.";

export const UPDATE_PERSON_ADDRESS_REJECTED =
  "Ocurrió un error guardandola direccion, intentar mas tarde.";

export const DELETE_PERSON_EMAIL_FULFILLED = "Datos guardados correctamente.";

export const DELETE_PERSON_EMAIL_PENDING = "Eliminando correo electronico.";

export const DELETE_PERSON_EMAIL_REJECTED =
  "Ocurrió un error eliminando el correo electronico, intentar mas tarde.";

export const DELETE_PERSON_ADDRESS_FULFILLED = "Datos guardados correctamente.";

export const DELETE_PERSON_ADDRESS_PENDING = "Eliminando direccion.";

export const DELETE_PERSON_ADDRESS_REJECTED =
  "Ocurrió un error eliminando la direccion, intentar mas tarde.";

export const DELETE_PERSON_CONTACT_FULFILLED = "Datos guardados correctamente.";

export const DELETE_PERSON_CONTACT_PENDING = "Eliminando contacto.";

export const DELETE_PERSON_CONTACT_REJECTED =
  "Ocurrió un error eliminando el contacto, intentar mas tarde.";

export const DELETE_PERSON_PHONE_FULFILLED = "Datos guardados correctamente.";

export const DELETE_PERSON_PHONE_PENDING = "Eliminando telefono.";

export const DELETE_PERSON_PHONE_REJECTED =
  "Ocurrió un error eliminando el telefono, intentar mas tarde.";

export const UPDATE_PERSON_CONTACT_FULFILLED = "Datos guardados correctamente.";

export const UPDATE_PERSON_CONTACT_PENDING = "Guardando contacto.";

export const UPDATE_PERSON_CONTACT_REJECTED =
  "Ocurrió un error guardando el contacto, intentar mas tarde.";

export const UPDATE_PERSON_PHONE_FULFILLED = "Datos guardados correctamente.";

export const UPDATE_PERSON_PHONE_PENDING = "Guardando telefono.";

export const UPDATE_PERSON_PHONE_REJECTED =
  "Ocurrió un error guardando el telefono, intentar mas tarde.";

export const RESPONSABLE_SAVED_SUCCESSFULLY =
  "Responsable guardado correctamente.";

export const LOADING_RESPONSABLE = "Guardando Responsable.";

export const DEFAULT_MESSAGE =
  "Error inesperado, comuniquese con el administrador.";

export const LOADING_PICTURE = "Cargando foto";

export const PICTURE_UPLOADED_SUCCESSFULLY = "Foto cargada correctamente";

export const REFERRAL_CODE_VALIDATION_PENDING = "Validando Código.";

export const VALID_REFERRAL_CODE = "Código válido.";

export const UPDATE_REFERRED_CODES_CLUBS_PENDING = "Actualizando clubes.";

export const UPDATE_REFERRED_CODES_CLUBS_REJECTED =
  "Ocurrió un error al actualizar los clubes, intentar mas tarde.";

export const UPDATE_REFERRED_CODES_CLUBS_FULFILLED =
  "Datos guardados correctamente.";

export const UPDATE_REFERRED_CODES_MAINTENANCES_PENDING =
  "Actualizando mantenimientos.";

export const UPDATE_REFERRED_CODES_MAINTENANCES_REJECTED =
  "Ocurrió un error al actualizar los mantenimientos, intentar mas tarde.";

export const UPDATE_REFERRED_CODES_MAINTENANCES_FULFILLED =
  "Datos guardados correctamente.";

export const SAVE_EVIDENCE_PENDING = "Guardando evidencia.";

export const SAVE_EVIDENCE_REJECTED =
  "Ocurrió un error al guardar la evidencia, intentar mas tarde.";

export const SAVE_EVIDENCE_FULFILLED = "Evidencia guardada correctamente.";

export const MARK_USER_ACTIVITY_CHECKED_PENDING =
  "Marcando actividad de usuario.";

export const MARK_USER_ACTIVITY_CHECKED_REJECTED =
  "Ocurrió un error al intentar marcar la actividad, intentar mas tarde.";

export const MARK_USER_ACTIVITY_CHECKED_FULFILLED =
  "Actividad guardada correctamente.";

export const NEW_ACTIVITY_USER_PENDING = "Creando actividad de usuario.";

export const NEW_ACTIVITY_USER_REJECTED =
  "Ocurrió un error al intentar crear actividad, intentar mas tarde.";

export const NEW_ACTIVITY_USER_FULFILLED = "Actividad guardada correctamente.";

export const UPDATE_COMROMISES_BY_SELLER_PENDING =
  "Guardando configuracion de compromisos por vendedor.";

export const UPDATE_COMROMISES_BY_SELLER_REJECTED =
  "Ocurrió un error al intentar guardar la configuracion, intentar mas tarde.";

export const UPDATE_COMROMISES_BY_SELLER_FULFILLED =
  "Configuracion guardada correctamente.";

export const UPDATE_PATTERN_GROUPS_PENDING =
  "Guardando configuracion de patrones";

export const UPDATE_PATTERN_GROUPS_REJECTED =
  "Ocurrió un error al intentar guardar la configuracion, intentar mas tarde";

export const UPDATE_PATTERN_GROUPS_FULFILLED =
  "Configuracion guardada correctamente";

export const STORE_PATTERN_PENDING = "Guardando patron";

export const STORE_PATTERN_REJECTED =
  "Ocurrió un error al intentar guardar el patron, intentar mas tarde";

export const STORE_PATTERN_FULFILLED = "Patron guardado correctamente";

export const SAVE_FOLLOW_UP_SURVEY_PENDING = "Guardando respuestas";

export const SAVE_FOLLOW_UP_SURVEY_REJECTED =
  "Ocurrió un error al intentar guardar las respuestas, intentar mas tarde";

export const SAVE_FOLLOW_UP_SURVEY_FULFILLED =
  "Respuestas guardados correctamente";

export const SAVE_DEMAND_DETAILS_PENDING =
  "Guardando detalles de queja/sugerencia";

export const SAVE_DEMAND_DETAILS_REJECTED =
  "Ocurrió un error al intentar guardar detalles intentar mas tarde";

export const SAVE_DEMAND_DETAILS_FULFILLED = "Detalles guardados correctamente";

export const SAVE_DEMAND_OBSERVATION_PENDING = "Guardando observacion";

export const SAVE_DEMAND_OBSERVATION_REJECTED =
  "Ocurrió un error al intentar guardar observacion intentar mas tarde";

export const SAVE_DEMAND_OBSERVATION_FULFILLED =
  "Observacion guardada correctamente";

export const UPDATE_DEMAND_PENDING = "Actualizando queja/sugerencia";

export const UPDATE_DEMAND_REJECTED =
  "Ocurrió un error al intentar actualizar queja/sugerencia intentar mas tarde";

export const UPDATE_DEMAND_FULFILLED =
  "Queja/sugerencia actualizada correctamente";

export const SAVE_DEMAND_PENDING = "Guardando queja/sugerencia";

export const SAVE_DEMAND_REJECTED =
  "Ocurrió un error al intentar guardar queja/sugerencia intentar mas tarde";

export const SAVE_DEMAND_FULFILLED = "Queja/sugerencia guardada correctamente";

export const CANCEL_BILL_REJECTED = "Ocurrio un error cancelando la factura";

export const CANCEL_BILL_FULFILLED = "Se cancelo la factura de manera correcta";

export const CANCEL_BILL_PENDING = "Cancelando la factura";

export const REMAKE_BILL_REJECTED = "Ocurrio un error refacturando la factura";

export const REMAKE_BILL_FULFILLED = "Se refacturo de manera correcta";

export const REMAKE_BILL_PENDING = "Realizando la refactura";

export const GET_BILL_INFO = "Factura no existente.";

export const GET_SINGLE_CODE_PENDING = "Obteniendo código";
export const GET_SINGLE_CODE_FULFILLED = "Código obtenido";
export const GET_SINGLE_CODE_REJECTED =
  "Ocurrió un error al intentar obtener el código, intentar mas tarde";

export const ACTIVATE_DAY_PASS_PENDING = "Activando pase";

export const ACTIVATE_DAY_PASS_REJECTED =
  "Ocurrió un error al intentar activar el pase, intentar mas tarde";

export const ACTIVATE_DAY_PASS_FULFILLED = "Pase activado correctamente";

export const GET_DISCOUT_CODES_LIST_PENDING = "Obteniendo códigos";

export const GET_DISCOUT_CODES_LIST_REJECTED =
  "Ocurrió un error al intentar consultar los códigos, intentar mas tarde";

export const CREATE_DISCOUT_CODES_PENDING = "Creando códigos";

export const CREATE_DISCOUT_CODES_REJECTED =
  "Ocurrió un error al intentar crear los códigos, intentar mas tarde";

export const CREATE_DISCOUT_CODES_FULFILLED = "Codigos creados correctamente";

export const SEND_BILLING_PENDING = "Enviando";

export const SEND_BILLING_SUCCESS = "Se ha creado la factura correctamente";

export const SEND_BILLING_ERROR =
  "Ocurrió un error al intentar facturar, verifica todos los campos esten llenados correctamente";

export const SEND_ACCRUAL_SUCCESS =
  "Se almacenaron las lineas de devengo correctamente";

export const SEND_ACCRUAL_ERROR =
  "Ocurrió un error al guardar las lineas de devengo";

export const SAVE_INBOX_CALENDAR_BLOCKED_PENDING = "Guardando";

export const SAVE_INBOX_CALENDAR_BLOCKED_SUCCESS =
  "Guardado de nuevas fechas completo";

export const SAVE_INBOX_CALENDAR_BLOCKED_ERROR =
  "Ocurrión un error al guardar las nuevas fechas, intenta más tarde";

export const REASIGN_MEMBERSHIP_PENDING = "Reasignando venta";

export const REASIGN_MEMBERSHIP_ERROR =
  "No se pudo reasignar la membresía, verifica sea de un periodo válido";

export const REASIGN_MEMBERSHIP_SUCCESS = "Reasignación correcta";

export const REPORTS_COMISSIONS_ERROR =
  "Ocurrió un error al obtener programas asociados, por favor comuníquese con el administrador";

export const MESSAGE_REPORT_WAY_TO_PAY_SUCCESS = "Proceso completado";

export const MESSAGE_REPORT_WAY_TO_PAY_PENDING = "Buscando...";

export const MESSAGE_GET_CASH_CUT_PENDING = "Buscando...";

export const MESSAGE_GET_CASH_CUT_ERROR =
  "Ocurrió un error, por favor comuníquese con el administrador.";

export const MESSAGE_GET_CASH_CUT_SUCCESS = "Búsqueda finalizada";

export const MESSAGE_PDF_REFERENCED_DEPOSIT_PENDING =
  "Generando PDF depósito referenciado";

export const MESSAGE_PDF_REFERENCED_DEPOSIT_ERROR =
  "Error al generar PDF depósito referenciado";

export const MESSAGE_PDF_REFERENCED_DEPOSIT_SUCCESS =
  "PDF depósito referenciado generado";

export const MESSAGE_GET_SHIFT_DELIVERY_PENDING = "Buscando...";

export const MESSAGE_GET_SHIFT_DELIVERY_ERROR =
  "Ocurrió un error, por favor comuníquese con el administrador.";

export const MESSAGE_GET_SHIFT_DELIVERY_SUCCESS = "Búsqueda finalizada";

export const MESSAGE_GET_CUSTOMIZABLE_RECEIPT_PENDING = "Buscando...";

export const MESSAGE_GET_CUSTOMIZABLE_RECEIPT_ERROR =
  "Ocurrió un error, por favor comuníquese con el administrador.";

export const HISTORICAL_REBILLING_SUCCESS = "Búsqueda finalizada";

export const HISTORICAL_REBILLING_PENDING = "Buscando...";

export const HISTORICAL_REBILLING_ERROR =
  "Ocurrió un error, por favor comuníquese con el administrador.";

export const MESSAGE_GET_CUSTOMIZABLE_RECEIPT_SUCCESS = "Búsqueda finalizada";

export const MESSAGE_DEALERS_PENDING = "Buscando...";

export const MESSAGE_DEALERS_ERROR =
  "Ocurrió un error, por favor comuníquese con el administrador.";

export const MESSAGE_DEALERS_SUCCESS = "Búsqueda finalizada";

export const MESSAGE_REFUSE_CUSTOMIZABLE_RECEIPT_PENDING = "Procesando...";

export const MESSAGE_REFUSE_CUSTOMIZABLE_RECEIPT_ERROR =
  "Ocurrió un error, por favor comuníquese con el administrador.";

export const MESSAGE_REFUSE_CUSTOMIZABLE_RECEIPT_SUCCESS = "proceso finalizado";

export const MESSAGE_VALIDATE_CUSTOMIZABLE_RECEIPT_PENDING = "Procesando...";

export const MESSAGE_VALIDATE_CUSTOMIZABLE_RECEIPT_ERROR =
  "Ocurrió un error, por favor comuníquese con el administrador.";

export const MESSAGE_REFERENCE_SANTANDER_REPORT_SUCCESS = "Búsqueda finalizada";

export const MESSAGE_REFERENCE_SANTANDER_REPORT_PENDING = "Procesando...";

export const MESSAGE_REFERENCE_SANTANDER_REPORT_ERROR =
  "Ocurrió un error, por favor comuníquese con el administrador.";

export const MESSAGE_GET_REFUNDS_REPORT_SUCCESS = "Búsqueda finalizada";

export const MESSAGE_GET_REFUNDS_REPORT_PENDING = "Buscando devoluciones";

export const MESSAGE_GET_REFUNDS_REPORT_ERROR =
  "Ocurrió un error al buscar devoluciones";

export const MESSAGE_REFERENCE_CONSECUTIVE_CLUB_SUCCESS =
  "Se obtuvo el consecutivo del club";

export const MESSAGE_REFERENCE_CONSECUTIVE_CLUB_PENDING =
  "Obteniendo el consecutivo del club";

export const MESSAGE_REFERENCE_CONSECUTIVE_CLUB_ERROR =
  "Error al obtener el consecutivo del club";

export const MESSAGE_CREATE_REFERENCES_BY_CLUB_SUCCESS =
  "Se crearon las referencias para el club";

export const MESSAGE_CREATE_REFERENCES_BY_CLUB_PENDING =
  "Creando referencias para el club";

export const MESSAGE_CREATE_REFERENCES_BY_CLUB_ERROR =
  "Error al crear referencias para el club";

export const MESSAGE_AUTHORIZE_REFOUND_SUCCESS =
  "Se ha autorizado la devolución";

export const MESSAGE_AUTHORIZE_REFOUND_PENDING = "Autorizando devolución";

export const MESSAGE_AUTHORIZE_REFOUND_ERROR = "Error al autorizar devolución";

export const MESSAGE_REFUSE_REFOUND_SUCCESS = "Se ha rechazado la devolución";

export const MESSAGE_REFUSE_REFOUND_PENDING = "Rechazando devolución";

export const MESSAGE_REFUSE_REFOUND_ERROR = "Error al rechazar devolución";

export const MESSAGE_CHARGEBACK_REFOUND_SUCCESS =
  "Se ha realizado el contracargo";

export const MESSAGE_CHARGEBACK_REFOUND_PENDING = "Realizando contracargo";

export const MESSAGE_CHARGEBACK_REFOUND_ERROR = "Error al realizar contracargo";

export const MESSAGE_VALIDATE_CUSTOMIZABLE_RECEIPT_SUCCESS =
  "proceso finalizado";

export const MESSAGE_GET_CREDIT_NOTES_PENDING = "Buscando...";

export const MESSAGE_GET_CREDIT_NOTES_ERROR =
  "Ocurrió un error, por favor comuníquese con el administrador.";

export const MESSAGE_GET_CREDIT_NOTES_SUCCESS = "Búsqueda finalizada";

export const MESSAGE_GET_SALES_PAYABLE_PENDING = "Buscando...";

export const MESSAGE_GET_SALES_PAYABLE_ERROR =
  "Ocurrió un error, por favor comuníquese con el administrador.";

export const MESSAGE_GET_SALES_PAYABLE_SUCCESS = "Búsqueda finalizada";

export const MESSAGE_PROCESS_CREDIT_NOTE_PENDING = "Procesando...";

export const MESSAGE_PROCESS_CREDIT_NOTE_ERROR =
  "Ocurrió un error, por favor comuníquese con el administrador.";

export const MESSAGE_PROCESS_CREDIT_NOTE_SUCCESS = "Proceso finalizado";

export const REJECT_CREDIT_NOTE_DEMAND_PENDING =
  "Procesando rechazo de solicitud";

export const REJECT_CREDIT_NOTE_DEMAND_ERROR = "Error al rechazar la solicitud";

export const REJECT_CREDIT_NOTE_DEMAND_SUCCESS = "Solicitud procesada";

export const REPORTS_COMISSIONS_PENDING =
  "Obteniendo programas asociados, por favor espere";

export const SCHEMAS_BY_PERIOD_PENDING =
  "Guardando los clubes por esquema de beneficios, por favor espere";

export const SCHEMAS_BY_PERIOD_ERROR =
  "Ocurrió un error, por favor comuníquese con el administrador.";

export const SCHEMAS_BY_PERIOD =
  "Clubes por esquema de beneficios guardados correctamente";

export const CREATE_COMISSIONS_SCHEMA_PENDING =
  "Guardando el esquema de beneficios, por favor espere";

export const CREATE_COMISSIONS_SCHEMA_ERROR =
  "Ocurrió un error guardando el esquema de beneficios, por favor comuníquese con el administrador.";

export const CREATE_COMISSIONS_SCHEMA =
  "Esquema de beneficios guardado correctamente";

export const SAVE_POST_BY_SCHEMAID_PENDING =
  "Guardando los puestos de acuerdo al esquema";

export const SAVE_POST_BY_SCHEMAID_ERROR =
  "Ocurrió un error guardando los puestos de acuerdo al esquema, por favor comuníquese con el administrador.";

export const SAVE_POST_BY_SCHEMAID =
  "Puestos de acuerdo al esquema guardados correctamente";

export const AUTHORIZE_EXPEDIENT_PENDING =
  "Autorizando el expediente, por favor espere";

export const AUTHORIZE_EXPEDIENT_ERROR =
  "Ocurrió un error autorizando el expediente, por favor comuníquese con el administrador.";

export const AUTHORIZE_EXPEDIENT = "Expediente autorizado correctamente";

export const AUTHORIZE_DOCUMENT_PENDING =
  "Autorizando el documento, por favor espere";

export const AUTHORIZE_DOCUMENT_ERROR =
  "Ocurrió un error autorizando el documento, por favor comuníquese con el administrador.";

export const AUTHORIZE_DOCUMENT = "Documento autorizado correctamente";

export const SAVE_GOALS_PENDING = "Guardando metas, por favor espere.";

export const SAVE_GOALS_ERROR =
  "Ocurrió un error guardando metas, por favor comuníquese con el administrador.";

export const SAVE_GOALS = "Metas guardada correctamente.";

export const SAVE_QUOTE_SPORTS_PRODUCTS_PENDING =
  "Guardando cotizacion, por favor espere.";

export const GET_SPORTS_PROGRAMS_PENDING =
  "Obteniendo programas deportivos, por favor espere.";

export const GET_SPORTS_PROGRAMS_REJECTED =
  "No hay resultados por medio de los filtros obtenidos";

export const SAVE_QUOTE_SPORTS_PRODUCTS_ERROR =
  "Ocurrió un error guardando cotizacion, por favor comuníquese con el administrador.";

export const SAVE_QUOTE_SPORTS_PRODUCTS = "Cotizacion guardada correctamente.";

export const REASSIGN_PROSPECT_PENDING =
  "Reasignando prospecto, por favor espere";

export const REASSIGN_PROSPECT_ERROR =
  "Ocurrió un error reasignando el prospecto, por favor comuníquese con el administrador.";

export const REASSIGN_PROSPECT = "Prospecto reasignado correctamente";

export const SAVE_SPORTS_PRODUCTS_COMISSIONS_PENDING =
  "Guardando información, por favor espere";
export const SAVE_SPORTS_PRODUCTS_COMISSIONS_ERROR =
  "Ocurrió un error guardando la infromación, por favor comuníquese con el administrador.";
export const SAVE_SPORTS_PRODUCTS_COMISSIONS =
  "Información guardada correctamente";
export const SAVE_SPORTS_PRODUCTS_PRICES_PENDING =
  "Guardando información de precios, por favor espere";
export const SAVE_SPORTS_PRODUCTS_PRICES_ERROR =
  "Ocurrió un error guardando los precios, por favor comuníquese con el administrador.";
export const SAVE_SPORTS_PRODUCTS_PRICES =
  "Información de precios guardada correctamente";
export const SAVE_USER_PERMITS_PENDING =
  "Guardando los permisos, por favor espere";
export const SAVE_USER_PERMITS_ERROR =
  "Ocurrió un error guardando los permisos, por favor comuníquese con el administrador.";
export const SAVE_USER_PERMITS = "Permisos guardados correctamente";
export const GET_KITS_MESSAGE_PENDING = "Obteniendo listado de fitkitz";
export const GET_KITS_MESSAGE_ERROR = "Error al obtener listado de fitkitz";
export const GET_KITS_MESSAGE_FULLIED = "Listado de fitkitz";
export const GET_KITS_RESPONSIBLES_MESSAGE_PENDING =
  "obteniendo listado de responsables de fitkitz";
export const GET_KITS_RESPONSIBLES_MESSAGE_ERROR =
  "Error al obtener listado de responsables";
export const GET_KITS_RESPONSIBLES_MESSAGE_FULLIED = "Listado de responsables";
export const ASSIGN_TAG_MESSAGE_PENDING = "Asignando responsable";
export const ASSIGN_TAG_MESSAGE_ERROR = "Error al asignar responsable";
export const ASSIGN_TAG_MESSAGE_FULLIED =
  "Responsable asignado satisfactoriamente";
export const DELLOCATION_TAG_MESSAGE_PENDING = "Desasignando responsable";
export const DELLOCATION_TAG_MESSAGE_ERROR = "Error al desasignar responsable";
export const DELLOCATION_TAG_MESSAGE_FULLIED = "Retiro satisfactorio";
export const SAVE_PROMOTION_SPORTS_PRODUCT =
  "Promoción guardada correctamente.";

export const SAVE_PROMOTION_SPORTS_PRODUCT_ERROR =
  "Ocurrió un error guardando la promoción, por favor comuníquese con el administrador.";

export const SAVE_PROMOTION_SPORTS_PRODUCT_PENDING =
  "Guardando la promoción, por favor espere.";

export const SPORTS_PRODUCTS_PAY_QUOTE_PENDING =
  "Guardando el pago, por favor espere.";
export const SPORTS_PRODUCTS_PAY_QUOTE_ERROR =
  "Ocurrió un error guardando el pago, por favor comuníquese con el administrador.";
export const SPORTS_PRODUCTS_PAY_QUOTE = "Pago guardado correctamente";

export const SAVE_ROLES_USER = "Roles guardados correctamente.";

export const SAVE_ROLES_USER_ERROR =
  "Ocurrió un error guardando los roles, por favor comuníquese con el administrador.";

export const SAVE_ROLES_USER_PENDING = "Guardando los roles, por favor espere.";

export const SAVE_USER = "Usuario guardado correctamente.";

export const SAVE_USER_ERROR =
  "Ocurrió un error guardando el usuario, por favor comuníquese con el administrador.";

export const SAVE_USER_PENDING = "Guardando el usuario, por favor espere.";

export const SEND_MEMBERS_RULES =
  "Configuracion de reglas almacenada correctamente.";

export const SEND_MEMBERS_RULES_ERROR =
  "Ocurrió un error almacenando la informacion de reglas, por favor comuníquese con el administrador.";

export const SEND_MEMBERS_RULES_PENDING =
  "Guardando configuracion de reglas, por favor espere.";

export const MEMBERS_TYPE_MEMBERSHIP =
  "Configuracion de convenios almacenada correctamente.";

export const MEMBERS_TYPE_MEMBERSHIP_ERROR =
  "Ocurrió un error almacenando la informacion, por favor comuníquese con el administrador.";

export const MEMBERS_TYPE_MEMBERSHIP_PENDING =
  "Guardando configuracion de convenios, por favor espere.";

export const SAVE_CHANGES_BENEFITS_AGREEMENT =
  "Cambios guardadados correctamente.";

export const SAVE_CHANGES_BENEFITS_AGREEMENT_ERROR =
  "Ocurrió un error almacenando la informacion, por favor comuníquese con el administrador.";

export const SAVE_CHANGES_BENEFITS_AGREEMENT_PENDING =
  "Guardando configuracion de beneficios, por favor espere.";

export const ADD_AGREEMENTS_PROMOTION =
  "Configuracion de convenios almacenada correctamente.";

export const ADD_AGREEMENTS_PROMOTION_ERROR =
  "Ocurrió un error almacenando la informacion, por favor comuníquese con el administrador.";

export const ADD_AGREEMENTS_PROMOTION_PENDING =
  "Guardando configuracion de convenios, por favor espere.";

export const RECREATE_FILE = "Documento regenerado correctamente.";

export const RECREATE_FILE_ERROR =
  "Ocurrió un error regenerando el documento, por favor comuníquese con el administrador.";

export const RECREATE_FILE_PENDING =
  "Regenerando el documento, por favor espere.";

export const SEND_EMAIL_MOVEMENTS = "Factura enviada correctamente.";

export const SEND_EMAIL_MOVEMENTS_ERROR =
  "Ocurrió un error enviando la factura, por favor comuníquese con el administrador.";

export const SEND_EMAIL_MOVEMENTS_PENDING = "Enviado factura.";

export const SEND_EMAIL_FILE = "Documento enviado correctamente.";

export const SEND_EMAIL_FILE_ERROR =
  "Ocurrió un error enviando un documento, por favor comuníquese con el administrador.";

export const SEND_EMAIL_FILE_MISSING =
  "El documento no puede ser enviado porque no ha sido generado, debe generar el documento primero.";

export const SEND_EMAIL_FILE_PENDING = "Enviado documentos.";

export const SEND_SCANNED_FILE = "Documento guardado correctamente.";

export const SEND_SCANNED_FILE_ERROR =
  "Ocurrió un error guardando los documentos, por favor comuníquese con el administrador.";

export const SEND_SCANNED_FILE_PENDING = "Guardando documentos.";

export const DOCUMENT_LOAD_ERROR =
  "Ocurrió un error cargando el documento solicitado, por favor comuníquese con el administrador.";

export const SAVE_SURVEYS = "Respuesta guardada correctamente.";

export const SAVE_SURVEYS_ERROR =
  "Ocurrió un error guardando las respuestas, por favor comuníquese con el administrador.";

export const SAVE_SURVEYS_PENDING = "Guardando respuestas.";

export const GET_PERSON = "Persona encontrada.";

export const GET_PERSON_ERROR = "Persona no encontrada.";

export const GET_PERSON_PENDING = "Pidiendo los datos de la persona.";

export const CREATE_PARTNER = "Socio creado correctamente.";

export const CREATE_MESSAGE = "Mensaje creado exitosamente.";

export const UPDATE_MESSAGE = "Titular actualizado exitosamente.";

export const UPDATE_CONVENIO_SUCCESS = "Convenio actualizado exitosamente.";
export const UPDATE_CONVENIO_INFO =
  "Actualizando convenio de membresía-socio...";

export const CREATE_PARTNER_ERROR =
  "Ocurrió un error, por favor comuníquese con el administrador.";

export const CREATE_PARTNER_PENDING = "Enviando información.";

export const CREATE_CLUB = "Club creado correctamente.";

export const CREATE_CLUB_ERROR =
  "Ocurrió un error, por favor comuníquese con el administrador.";

export const CREATE_CLUB_PENDING = "Enviando información.";

export const SAVE_PRICES_MAINTENANCE = "Precios actualizados correctamente.";

export const SAVE_PRICES_MAINTENANCE_ERROR =
  "Ocurrió un error actualizando los precios, por favor comuníquese con el administrador.";

export const SAVE_PRICES_MAINTENANCE_PENDING = "Actualizando los precios";

export const CREATE_PROSPECT = "Prospecto creado correctamente";

export const CREATE_PROSPECT_ERROR =
  "Ocurrió un error creando el prospecto, por favor comuníquese con el administrador.";

export const ERROR_SALE =
  "Ocurrió un error inesperado, por favor comuníquese con el administrador.";

export const CREATE_PROSPECT_WAITING = "Creando Prospecto";

export const DELETE_PROSPECT = "Prospecto eliminado correctamente";

export const DELETE_PROSPECT_ERROR =
  "Ocurrio un error eliminando el prospecto. Por favor comuníquese con el administrador.";

export const DELETE_PROSPECT_WAITING = "Eliminando el prospecto.";

export const DELETE_PRODUCT = "Producto eliminado correctamente";

export const DELETE_PRODUCT_ERROR =
  "Ocurrio un error eliminando el producto. Por favor comuníquese con el administrador.";

export const PATCH_PROSPECT = "Prospecto editado correctamente";

export const PATCH_PROSPECT_ERROR =
  "Ocurrio un error editando el prospecto. Por favor comuníquese con el administrador.";

export const PATCH_PROSPECT_WAITING = "Editando el prospecto.";

export const ACTIVITY_CREATION = "Actividad creada correctamente";

export const ACTIVITY_CREATION_ERROR =
  "Ocurrio un error creando la actividad. Por favor comuníquese con el administrador.";

export const ACTIVITY_CREATION_WAITING = "Creando la actividad.";

export const ROLE_CREATION = "Rol creado correctamente";

export const ROLE_CREATION_ERROR =
  "Ocurrio un error creando el rol. Por favor comuníquese con el administrador.";

export const ROLE_CREATION_WAITING = "Creando el rol";

export const LOAD_FILE = "Archivo cargado correctamente";

export const LOAD_FILE_ERROR =
  "Ocurrio un error cargando el archivo. Por favor comuníquese con el administrador.";

export const LOAD_FILE_WAITING = "Cargando Archivo";

export const DOWNLOAD_FILE = "Archivo descargado correctamente";

export const DOWNLOAD_FILE_ERROR =
  "Ocurrio un error descargando el archivo. Por favor comuníquese con el administrador.";

export const DOWNLOAD_FILE_WAITING = "Descargando Archivo";

export const ACCESS_MAINTENANCE = "Accesos guardada correctamente.";

export const ACCESS_MAINTENANCE_ERROR =
  "Hubo un error guardando los accesos. Por favor comuníquese con el administrador.";

export const ACCESS_MAINTENANCE_WAITING =
  "Enviando los accesos para ser guardados. Por favor espere.";

export const GENERAL_CONFIG_MAINTENANCE =
  "Configuracion guardada correctamente.";

export const GENERAL_CONFIG_MAINTENANCE_ERROR =
  "Hubo un error guardando la configuracion. Por favor comuníquese con el administrador.";

export const GENERAL_CONFIG_MAINTENANCE_WAITING =
  "Enviando la configuracion para ser guardada. Por favor espere.";

export const BASIC_DATA_PRODUCT = "Datos enviados correctamente.";

export const BASIC_DATA_PRODUCT_ERROR =
  "Hubo un error enviando los datos. Por favor comuníquese con el administrador.";

export const BASIC_DATA_PRODUCT_WAITING =
  "Enviando los datos para ser guardados. Por favor espere.";

export const PRICES_AUTHORIZATION = "Precios autorizados correctamente.";

export const PRICES_AUTHORIZATION_ERROR =
  "Hubo un error autorizando los precios. Por favor comuníquese con el administrador.";

export const PRICES_AUTHORIZATION_WAITING =
  "Autorizando los precios. Por favor espere.";

export const PRICES_CAPTURED = "Precios capturados correctamente.";

export const PRICES_CAPTURED_ERROR =
  "Hubo un error capturando los precios. Por favor comuníquese con el administrador.";

export const PRICES_CAPTURED_WAITING =
  "Capturando los precios. Por favor espere.";

export const CLUBS_CREATED = "Clubes modificados correctamente.";

export const CLUBS_ERROR =
  "Hubo un error modificando los clubes. Por favor comuníquese con el administrador.";

export const CLUBS_WAITING = "Modificando clubes. Por favor espere.";

// Convenios
export const PENDING = "Enviando información.";
export const ERROR =
  "Ocurrió un error, por favor comuníquese con el administrador.";
export const SAVE_BASIC_AGREEMENT = "Datos de convenio guardados exitosamente.";
export const ADD_CLUBS_AGREEMENT = "Clubes guardados exitosamente";
export const ADD_MAINTENANCES_AGREEMENT =
  "Mantenimientos guardados exitosamente";
// Promociones
export const SAVE_BASIC_PROMOTION =
  "Datos de promociòn guardados exitosamente.";
export const ADD_CLUBS_PROMOTION = "Clubes guardados exitosamente";
export const ADD_MAINTENANCES_PROMOTION =
  "Mantenimientos guardados exitosamente";
// Socios
export const ERROR_GET_PARTNER =
  "No existen datos del socio. Por favor verifique los datos de búsqueda.";
export const DOCUMENT_SIGN_ERROR =
  "Error al firmar el documento. Por favor comuníquese con el administrador.";
export const DOCUMENT_SIGN_SUCESS = "Documento firmado correctamente.";
export const PARTNER_UPDATE_PHOTO_PENDING = "Guardando foto. Por favor espere";
export const PARTNER_UPDATE_PHOTO_SUCCESS = "Foto actualizada correctamente";
export const PARTNER_UPDATE_PHOTO_ERROR =
  "Error al actualizar la foto. Por favor comuníquese con el administrador.";
export const PARTNER_UPDATE_PAW_PRINT_PENDING =
  "Guardando huella. Por favor espere";
export const PARTNER_UPDATE_PAW_PRINT_SUCCESS =
  "Huella actualizada correctamente";
export const PARTNER_UPDATE_PAW_PRINT_ERROR =
  "Error al actualizar la huella. Por favor comuníquese con el administrador.";
export const PARTNER_ACCESS_REGISTER_PENDING =
  "Registrando acceso. Por favor espere";
export const PARTNER_ACCESS_REGISTER_SUCCESS =
  "Acceso registrado correctamente";
export const PARTNER_ACCESS_REGISTER_ERROR =
  "Error al registrar acceso. Por favor comuníquese con el administrador.";
export const ERROR_GET_PARTNER_BANK_CARDS =
  "Error al obtener los medios de pago. Por favor comuníquese con el administrador.";
export const PARTNER_SAVE_BANK_CARD_PENDING =
  "Guardando medio de pago. Por favor espere";
export const PARTNER_SAVE_BANK_CARD_ERROR =
  "Error al guardar medio de pago. Por favor comuníquese con el administrador.";
export const PARTNER_SAVE_BANK_CARD = "Medio de pago guardado correctamente";
export const CREATE_PERSON = "Persona registrada correctamente.";
export const CREATE_PERSON_PENDING = "Registrando persona";
export const CREATE_PERSON_ERROR =
  "Error al crear persona. Por favor comuníquese con el administrador";

export const GET_QUOTATION_ERROR = "La busqueda no tiene precio valido";
export const CREATE_BANK = "Banco creado correctamente.";
export const CREATE_BANK_ERROR =
  "Ocurrió un error, por favor comuníquese con el administrador.";
export const CREATE_BANK_PENDING = "Enviando información.";
export const CREATE_MONTHS_WITHOUT_INTEREST =
  "Configuracion guardada correctamente.";
export const CREATE_MONTHS_WITHOUT_INTEREST_ERROR =
  "Ocurrió un error, por favor comuníquese con el administrador.";
export const CREATE_MONTHS_WITHOUT_INTEREST_PENDING = "Enviando información.";
export const ACTIVATE_PRODUCTS_PENDING = "Enviando información";
export const ACTIVATE_PRODUCTS_ERROR =
  "Ocurrió un error  al activar los productos, por favor comuníquese con el administrador.";
export const ACTIVATE_PRODUCTS = "Los productos de activaron correctamente";
export const GET_PAYROLL_CONCEPT_PENDING = "Obteniendo conceptos de nómina";
export const GET_PAYROLL_CONCEPT_ERROR =
  "Ocurrió un error al obtener los conceptos de nómina";
export const GET_PAYROLL_CONCEPT = "Se obtuvieron los conceptos de nómina";
export const GET_PAYROLL_CONCEPT_SELECT_PENDING =
  "Obteniendo conceptos por club";
export const GET_PAYROLL_CONCEPT_SELECT_ERROR =
  "Ocurrió un error al obtener los conceptos por club";
export const GET_PAYROLL_CONCEPT_SELECT =
  "Se obtuvieron los conceptos por club";
export const POST_PAYROLL_CONCEPT_SAVE_PENDING = "Guardando concepto del club";
export const POST_PAYROLL_CONCEPT_SAVE_ERROR =
  "Error al guardar el concepto del club";
export const POST_PAYROLL_CONCEPT_SAVE = "Se guardo el concepto del club";

// Documents
export const SAVE_BASIC_DOCUMENT = "Datos de documento guardados exitosamente.";
export const ADD_MEMBERSHIPS_DOCUMENT = "Membresias agregadas exitosamente";

//Annuities
export const ANNUITY_BLOCKED =
  "Esta configuración de anualidad ya ha sido vendida, por lo tanto no se puede editar.";
export const SAVE_ANNUITY = "La anualidad fue guardada correctamente.";
export const SAVE_ANNUITY_QUOTE = "La cotización fue guardada correctamente.";
export const PAY_ANNUITY_QUOTE_BY_ID =
  "Los pagos fueron guardados correctamente.";

export const SAVE_LOCKER = "Configuracion de lockers guardada correctamente.";

export const SAVE_LOCKER_PENDING = "Registrando configuracion del lockers";

export const SAVE_LOCKER_ERROR =
  "Error al registrar la configuracion de lockers. Por favor comuníquese con el administrador";

export const SAVE_LOCKER_ASSIGNMENT = "Locker asignado correctamente.";

export const SAVE_LOCKER_ASSIGNMENT_PENDING =
  "Registrando la asignación del locker";

export const SAVE_LOCKER_ASSIGNMENT_ERROR =
  "Error al registrar la asignación del locker. Por favor comuníquese con el administrador";

export const GET_LOCKERS_SUMMARY_BY_CLUB_ERROR =
  "Error al consultar el resumen de lockers";

export const AUTHORIZE_COMISSION_INVALID_STATUS =
  "Las comisiones que se intentaron autorizar no estan pendientes.";
export const AUTHORIZE_COMISSION_ERROR =
  "Error inesperado al intentar actualizar comisiones";
export const AUTHORIZE_COMISSION_FULFILLED =
  "Las comisiones fueron autorizadas exitosamente.";
export const AUTHORIZE_COMISSION_PENDING =
  "Autorizando comisiones seleccionadas.";

export const ERROR_DELETE_PARTNER = "Error al eliminar socio de Membresia";

export const DELETE_PARTNER_SUCCESSFUL = "Eliminado socio de Membresia";

export const DELETE_PARTNER_PENDING = "Eliminando socio de Membresia";

export const MEMBERSHIP_PURCHASE_REJECTED =
  "Este usuario ya tiene una membresia asociada";

export const CHANGE_MEMBERSHIP_PENDING = "Cambiando de membresia";

export const CHANGE_MEMBERSHIP_FULFILLED = "Cambio de membresia exitoso";

export const CHANGE_MEMBERSHIP_REJECTED =
  "Error inesperado al intentar cambiar de membresia";

export const GET_MOVEMENTS_CLUB_MEMBERSHIP_REJECTED =
  "Error inesperado al consultar los movimientos por membresia socio";

export const ADD_SESSION_PENDING = "Creando nueva clase";

export const ERROR_TOTAL_DATA = "Favor de ingresar toda la información";

export const ADD_SESSION_SUCCESSFUL = "Clase creada exitosamente";

export const ADD_SESSION_ERROR = "Error inesperado al intentar crear clase";

export const DELETE_SESSION_PENDING = "Eliminando clase";

export const DELETE_SESSION_ERROR =
  "Error inesperado al intentar eliminar clase";

export const DELETE_SESSION_SUCCESSFUL = "Clase eliminada exitosamente";

export const SWITCH_MEMBERSHIP_PENDING = "Consultando cambiando de membresia";

export const SWITCH_MEMBERSHIP_FULFILLED = "Consulta exitosa";

export const SWITCH_MEMBERSHIP_REJECTED =
  "Error inesperado al intentar cambiar de membresia";

export const ERROR_MESSAGES_LIST =
  "Error inesperado en la obtención de mensajes";

export const ERROR_ADD_MESSAGE = "Error inesperado al agregar mensaje";

export const SPORTS_PROGRAMS_GET_VALID_CUSTOMERS_PENDING_MESSAGE =
  "Consultando los clientes válidos para la creación de instancia de programa deportivo";
export const SPORTS_PROGRAMS_GET_VALID_CUSTOMERS_NOT_FOUND_MESSAGE =
  "No se encontraron clientes que adquirieran el programa deportivo y club seleccionados";
export const SPORTS_PROGRAMS_GET_VALID_CUSTOMERS_REJECTED_MESSAGE =
  "Error inesperado al consultar los clientes válidos para la creación de instancia de programa deportivo";

export const RIGHTS_CHANGE_REJECTED_MESSAGE =
  "No se pudo realizar la cesión de derechos";
export const RIGHTS_CHANGE_SUCCESS_MESSAGE =
  "Se realizó la cesión de derechos con éxito";
export const CREATE_MAINTENANCE_DISCOUNT_SUCCESS_MESSAGE =
  "Se creó el descuento con éxito";
export const CREATE_MAINTENANCE_DISCOUNT_REJECTED_MESSAGE =
  "No se pudo crear el descuento";

export const FULFILLED_QUIZ_ADD = "Se agregó correctamente la encuesta";

export const PENDING_QUIZ_ADD = "Creando encuesta";

export const REJECTED_ADD_MESSAGE = "Error al crear encuesta";

export const FULFILLED_QUIZ_SEARCH = "Proceso completado...";

export const PENDING_QUIZ_SEARCH = "Buscando";

export const REJECTED_QUIZ_SEARCH = "Error al obtener resultados";

export const ERROR_SUM_VALUES_TAXDISTRIBUTION =
  "La sumatoria en distribuciones contables no corresponde al importe del item";

export const ERROR_ITEM_VALUE_EXCESS =
  "El monto es superior al del item seleccionado";

export const ERROR_MONTH_YEAR = "Falta seleccionar mes o a\u00F1o";

export const NOT_CATEGORY_SELECTED = "No se ha seleccionado categoria";

export const UPDATE_PERSON_ADDRES_INVALID_RFC =
  "El RFC proporcionado no es válido";

export const NOT_PERSON_FINDED =
  "No se ha encontrado información con los filtros aplicados";

export const ERROR_PERSON_FINDED =
  "Ocurrió un error al obtener información, por favor comuníquese con el administrador";

export const CREATE_CREDIT_NOTE_PENDING =
  "Solictando cancelación o nota de crédito";

export const CREATE_CREDIT_NOTE_ERROR =
  "Error en la solicitud de la cancelación o nota de crédito";

export const CREATE_CREDIT_NOTE = "Solicitud generada exitosamente";

export const GET_DATA_TERMINAL_PENDING =
  "Obteniendo datos de logueo de la terminal";

export const GET_DATA_TERMINAL_ERROR =
  "No se pudo obtener los datos de logueo para la terminal";

export const GET_DATA_TERMINAL =
  "Se obtuvieron los datos de la terminal de manera correcta";

export const GET_MEMBER_BY_CONFIGURATION_PENDING =
  "Obteniendo tipo de membresías";

export const GET_MEMBER_BY_CONFIGURATION_ERROR =
  "Error al obtener el tipo de membresías";

export const GET_MEMBER_BY_CONFIGURATION_FULFILLED =
  "Se obtuvieron el tipo de membresías";

export const CREATE_MAINTENANCE_CHARGES_PENDING =
  "Generando cargos adelantados";

export const CREATE_MAINTENANCE_CHARGES_ERROR =
  "No se lograron generar cargos adelantados";

export const CREATE_MAINTENANCE_CHARGES =
  "Se generaron los cargos adelantados exitosamente";

export const SAVE_PAID_COMPLEMENT_PENDING = "Generando recibo de pago.";

export const SAVE_PAID_COMPLEMENT_ERROR = "Error al guardar el recibo de pago";

export const SAVE_PAID_COMPLEMENT_FULLIED =
  "Recibo de pago guardado correctamente";

export const SAVE_RETURN_INVOICE_PENDING = "Guardando solicitud de devolución";

export const SAVE_RETURN_INVOICE_ERROR =
  "Error al guardar la solicitud de devolución";

export const SAVE_RETURN_INVOICE_FULLIED =
  "Se guardo la solicitud de devolución";

export const GET_REFERENCE_SANTANDER_CARGO_PENDING =
  "Obteniendo referencia Santander";

export const GET_REFERENCE_SANTANDER_CARGO_ERROR =
  "No se pudo obtener la referencia Santander";

export const GET_REFERENCE_SANTANDER_CARGO =
  "Se obtuvo la referencia Santander";

export const GET_REFERENCE_NOT_USED_SANTANDER_PENDING =
  "Obteniendo referencias no usadas";

export const GET_REFERENCE_NOT_USED_SANTANDER_ERROR =
  "No se pudieron obtener las referencias";

export const GET_REFERENCE_NOT_USED_SANTANDER =
  "Se obtuvieron las referencias no usadas";

export const GET_DEPOSITO_REFERENCIADO_FULFILLED =
  "Se obtuvieron los depósitos refernciados";

export const GET_DEPOSITO_REFERENCIADO_PENDING =
  "Obteniendo depósitos referenciados";

export const GET_DEPOSITO_REFERENCIADO_REJECTED =
  "Error al obtener depósitos referenciados";

export const GET_DEALERS_FULFILLED = "Se obtuvieron los concesionarios";

export const GET_DEALERS_PENDING = "Obteniendo concesionarios por filtro";

export const GET_DEALERS_REJECTED =
  "Error al obtener concesionarios por filtro";

export const GET_DEALERS_CHARGES_FULFILLED =
  "Se obtuvieron los cargos de concesionarios";

export const GET_DEALERS_CHARGES_PENDING =
  "Obteniendo cargos de concesionarios";

export const GET_DEALERS_CHARGES_REJECTED =
  "Error al obtener cargos de concesionarios";

export const GET_SELECTED_DEALER_CHARGE_FULFILLED =
  "Se obtuvo cargo del concesionario";

export const GET_SELECTED_DEALER_CHARGE_PENDING =
  "Obteniendo cargo de concesionario";

export const GET_SELECTED_DEALER_CHARGE_REJECTED =
  "Error al obtener cargo del concesionario";

export const VARIABLES_CHARGE_DEALERS_FULFILLED =
  "Se obtuvieron los cargos variables";

export const GENERATE_VARIABLE_PENDING = "Procesando...";

export const GENERATE_VARIABLE_REJECTED = "Error en el proceso...";

export const GENERATE_VARIABLE_FULFILLED = "Se ha completado el proceso";

export const VARIABLES_CHARGE_DEALERS_PENDING =
  "Obteniendo cargos variables...";

export const VARIABLES_CHARGE_DEALERS_REJECTED =
  "Error al obtener cargos variables";

export const DELETE_DEALER_FULFILLED = "Se eliminó el concesionario";

export const DELETE_DEALER_PENDING = "Eliminando el concesionario";

export const DELETE_DEALER_REJECTED = "Error al eliminar el concesionario";

export const DELETE_CHARGE_DEALER_FULFILLED =
  "Se eliminó el cargo del concesionario";

export const DELETE_CHARGE_DEALER_PENDING =
  "Eliminando el cargo del concesionario";

export const DELETE_CHARGE_DEALER_REJECTED =
  "Error al eliminar el cargo del concesionario";

export const GET_DEALERS_MOVEMENTS_FULFILLED = "Se obtuvieron movimientos";

export const GET_DEALERS_MOVEMENTS_PENDING = "Obteniendo movimientos";

export const GET_DEALERS_MOVEMENTS_REJECTED = "Error al obtener movimientos";

export const GET_DEALERS_STATUS_REPORT_FULFILLED =
  "Se obtuvieron estados de cuenta de concesionario";

export const GET_DEALERS_STATUS_REPORT_PENDING =
  "Obteniendo estados de cuenta de concesionario";

export const GET_DEALERS_STATUS_REPORT_REJECTED =
  "Error al obtener estados de cuenta de concesionario";

export const ACCEPT_CHARGE_MOVEMENTS_FULFILLED = "Se acepto el movimiento";

export const ACCEPT_CHARGE_MOVEMENTS_PENDING = "Aceptando movimiento";

export const ACCEPT_CHARGE_MOVEMENTS_REJECTED =
  "Error al aceptar el movimiento";

export const GET_CLUB_DEALER_FULFILLED =
  "Se obtuvieron los clubes del concesionario";

export const GET_CLUB_DEALER_PENDING = "Obteniendo clubes del concesionario";

export const GET_CLUB_DEALER_REJECTED =
  "Error al obtener clubes del concesionario";

export const UPDATE_CLUB_DEALER_FULFILLED = "Se generaron los cargos variables";

export const UPDATE_CLUB_DEALER_PENDING = "Generando cargos variables";

export const UPDATE_CLUB_DEALER_REJECTED = "Error al generar cargos variables";

export const ADD_DEALERS_FULFILLED = "Se agregó concesionario correctamente";

export const ADD_DEALERS_PENDING = "Agregando concesionario...";

export const ADD_DEALERS_REJECTED = "Error al agregar concecionario";

export const successNotification = message => toast.success(message);

export const infoNotification = message => toast.info(message);

export const errorNotification = message => toast.error(message);

export const warnNotification = message => toast.warn(message);

export const dismissNotifications = () => toast.dismiss();
