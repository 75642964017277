import FormBuilderConfig from "../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IButtonBuilder from "../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import IUserSelectorBuilder from "../../../toolkit/baseForms2Commons/iUserSelectorBuilder/IUserSelectorBuilder";

export type Inputs = "productoId" | "responsableId" | "generar" | "archivo";
export type Buttons = "aceptar" | "cancelar";

export const formConfig: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "productoId",
      label: "* Producto",
      bts: {
        sCol: 3
      },
      props: {
        maxlength: 10
      }
    },
    {
      name: "responsableId",
      label: "* Responsable",
      bts: {
        sCol: 5
      },
      component: IUserSelectorBuilder
    }
  ],
  buttons: [
    {
      name: "aceptar",
      label: "Reasignar",
      isSubmit: true,
      bts: {
        sCol: 3,
        sOff: 6
      },
      component: IButtonBuilder
    },
    {
      name: "cancelar",
      label: "Regresar",
      isSubmit: false,
      bts: {
        sCol: 3
      },
      component: IButtonBuilder
    }
  ],
  validations: {
    productoId: {
      required: true
    },
    responsableId: {
      required: true
    }
  },
  rows: [
    ["productoId", "responsableId", "generar", "archivo", "aceptar", "cancelar"]
  ]
};
