import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import * as catalogActions from "../../actions/catalogs";
import * as reportsActions from "../../actions/reports";
import IAction from "../../../types/IAction";
import ReferenceDosntApplyReport from "../../components/referenceDosntApplyReport";

interface Props {
  getClubs: () => void;
  clubs: object[];
  getSantanderRefDosntApply: (
    clubId: string,
    formaDePago: string,
    fechaInicio: string,
    fechaFin: string,
    page: string,
    size: string
  ) => IAction;
  reportReferenceDA: any;
  getSantanderRefDosntApplyCsv: (body: object) => IAction;
}

interface State {}

class SantanderRefDosntApply extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    this.props.getClubs();
  }

  componentWillUnmount() {}

  render() {
    return (
      <main>
        <Helmet title="Santander Reference" />
        <Layout>
          <ReferenceDosntApplyReport
            clubs={this.props.clubs}
            getSantanderRefDosntApply={this.props.getSantanderRefDosntApply}
            reportReferenceDA={this.props.reportReferenceDA}
            getSantanderRefDosntApplyCsv={
              this.props.getSantanderRefDosntApplyCsv
            }
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({ catalogs, reports }) => {
  return {
    clubs: catalogs.clubs,
    reportReferenceDA: reports.reportReferenceDA
  };
};

const mapDispatchToProps = dispatch => ({
  getClubs: () => {
    dispatch(catalogActions.getClubs());
  },
  getSantanderRefDosntApply: (
    clubId: string,
    formaDePago: string,
    fechaInicio: string,
    fechaFin: string,
    page: string,
    size: string
  ) => {
    dispatch(
      reportsActions.getSantanderRefDosntApply(
        clubId,
        formaDePago,
        fechaInicio,
        fechaFin,
        page,
        size
      )
    );
  },
  getSantanderRefDosntApplyCsv: (body: object) => {
    dispatch(reportsActions.getSantanderRefDosntApplyCsv(body));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SantanderRefDosntApply);
