exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3_tGY{background:#f3d8d8}._22-aI{background:#bce0bc}._3fYfl{background:#fff}._2L-Ca{padding:0!important}._3vVhO{padding:7px 3px}._1ofo5 div{overflow-x:hidden!important}._1ofo5 div div div div{overflow:hidden!important}", ""]);

// exports
exports.locals = {
	"pendiente": "_3_tGY",
	"procesada": "_22-aI",
	"normal": "_3fYfl",
	"tdClear": "_2L-Ca",
	"field": "_3vVhO",
	"hidden": "_1ofo5"
};