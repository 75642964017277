import { Preview, PreviewDispatcher } from "../../../types/IAction";
import { GetAllClubsBasicData } from "../../../Home/reducers/clubs";
import { getAllClubsBasicData } from "../../../Home/actions/clubs";

export interface IClubSelectorDispatcher {
  getAllClubsBasicData: () => Promise<Preview<GetAllClubsBasicData>>;
}

export const iClubSelectorDispatcher: PreviewDispatcher<
  IClubSelectorDispatcher
> = dispatch => ({
  getAllClubsBasicData: () => {
    return dispatch(getAllClubsBasicData());
  }
});
