import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import moment from "moment";
import {
  Container,
  Col,
  Button,
  Row,
  UncontrolledTooltip,
  Form
} from "reactstrap";
import {
  faCircle,
  faFilePdf,
  faTimes,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BaseInput from "../../../toolkit/baseInput";
import DatePicker from "../common/DatePicker";
import s from "./styles.scss";
import { getReportPaymentLinesRequest } from "../../actions/reports";
import {
  deletePaymentLineRequest,
  testPaymentLineRequest
} from "../../actions/paymentLines";
import {
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import { handleRequestError } from "../../../utils/helpers";
import uppercase from "lodash.uppercase";
import BaseNumericInput from "../../../toolkit/baseNumericInput";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { errorNotification } from "../../../utils/notifications";

interface Props {
  toggleBill: (storageId: number) => any;
  clubs: {
    clubId: number;
    nombre: string;
  }[];
}

interface State {
  filters: object;
  data: any[];
  onSearch: boolean;
  isOpen: boolean;
}

const START_DATE_FIELDNAME = "fechaInicio";
const END_DATE_FIELDNAME = "fechaFin";

class PaymentLinesReport extends React.Component<Props, State> {
  state = {
    data: [],
    filters: {
      idClub: "",
      fechaInicio: null,
      fechaFin: null,
      membresiaIdPublico: null,
      idPersona: null
    },
    isOpen: false,
    onSearch: false
  };

  columns = [
    {
      Header: () => <p className="font-weight-bold">Club</p>,
      id: 0,
      width: 120,
      className: "text-center",
      accessor: "itemClubNombre"
    },
    {
      Header: () => <p className="font-weight-bold"># Membresía</p>,
      id: 0,
      width: 100,
      className: "text-center",
      accessor: "itemMembresiaIdPublico"
    },
    {
      Header: () => <p className="font-weight-bold">Cliente</p>,
      id: 0,
      width: 150,
      accessor: "itemClienteNombre",
      Cell: row => {
        return (
          <div className="text-wrap">
            {uppercase(row.original.itemClienteNombre)}
          </div>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Estatus</p>,
      id: 0,
      width: 150,
      className: "text-center",
      accessor: "itemEstatus"
    },
    {
      Header: () => <p className="font-weight-bold">Referencia</p>,
      id: 0,
      width: 150,
      className: "text-center",
      accessor: "itemReferencia"
    },
    {
      Header: () => <p className="font-weight-bold">Línea de pago</p>,
      id: 0,
      width: 300,
      accessor: "itemUrl",
      Cell: row => {
        return <div className="text-wrap">{row.original.itemUrl}</div>;
      }
    },

    {
      Header: () => (
        <p className="font-weight-bold">
          Fecha
          <br />
          generación
        </p>
      ),
      id: 0,
      width: 100,
      className: "text-center",
      accessor: "itemFechaGeneracion"
    },
    {
      Header: () => (
        <p className="font-weight-bold">
          Fecha
          <br />
          envío
        </p>
      ),
      id: 0,
      width: 100,
      className: "text-center",
      accessor: "itemFechaEnvioCliente"
    },
    {
      Header: () => (
        <p className="font-weight-bold">
          Fecha
          <br />
          pago
        </p>
      ),
      id: 0,
      width: 100,
      className: "text-center",
      accessor: "itemFechaRespuestaMit"
    },
    {
      Header: () => (
        <p className="font-weight-bold">
          Fecha
          <br />
          Facturación
        </p>
      ),
      id: 0,
      width: 100,
      className: "text-center",
      accessor: "itemFechaFacturacion"
    },
    {
      Header: () => <p className="font-weight-bold">Factura folio</p>,
      id: 0,
      width: 100,
      className: "text-center",
      accessor: "itemFacturaFolio"
    },
    {
      Header: () => <p className="font-weight-bold">Factura UUID</p>,
      id: 0,
      width: 150,
      className: "text-center",
      accessor: "itemFacturaUuid",
      Cell: row => {
        return (
          <div className="text-wrap">
            {uppercase(row.original.itemFacturaUuid)}
          </div>
        );
      }
    },

    {
      Header: () => {
        return <p className="font-weight-bold">Opciones</p>;
      },
      id: -300,
      width: 80,
      accessor: "actions",
      Cell: row => {
        return (
          <Container className="p-0">
            <Row className="m-0">
              {["FACTURADA", "COBRADA_MIT"].indexOf(row.original.itemEstatus) >=
                0 && (
                <Col className="px-1">
                  <a
                    id={`factura${row.original.itemStorageId}`}
                    onClick={() =>
                      this.props.toggleBill(row.original.itemStorageId)
                    }
                  >
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={faFilePdf}
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={`factura${row.original.itemStorageId}`}
                    >
                      Ver pdf
                    </UncontrolledTooltip>
                  </a>
                </Col>
              )}
              {["CREADA", "ENVIADA_CLIENTE"].indexOf(
                row.original.itemEstatus
              ) >= 0 && (
                <Col className="px-1">
                  <a
                    id={`testPaymentLine${row.original.itemId}`}
                    style={{ marginRight: "8px" }}
                    onClick={() => {
                      infoNotification("Comprobando línea de pago...");
                      testPaymentLineRequest(row.original.itemId)
                        .then(() => {
                          this.search();
                        })
                        .catch(handleRequestError);
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={faEye}
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={`testPaymentLine${row.original.itemId}`}
                    >
                      Comprobar pago
                    </UncontrolledTooltip>
                  </a>
                  <a
                    id={`delPaymentLine${row.original.itemId}`}
                    onClick={() => {
                      infoNotification("Cancelando línea de pago...");
                      deletePaymentLineRequest(row.original.itemId)
                        .then(() => {
                          this.search();
                        })
                        .catch(handleRequestError);
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ cursor: "pointer" }}
                      icon={faTimes}
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={`delPaymentLine${row.original.itemId}`}
                    >
                      Cancelar
                    </UncontrolledTooltip>
                  </a>
                </Col>
              )}
            </Row>
          </Container>
        );
      }
    }
  ];

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const filters = {
          ...state.filters,
          [fieldName]: date
        };
        return { ...state, error: {}, filters };
      });
    };
  };

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  search = () => {
    this.setState({
      onSearch: true,
      data: []
    });

    const {
      idClub,
      fechaInicio,
      fechaFin,
      membresiaIdPublico,
      idPersona
    } = this.state.filters;

    infoNotification("Consultando datos...");

    getReportPaymentLinesRequest(
      idClub || null,
      fechaInicio,
      fechaFin,
      membresiaIdPublico,
      idPersona
    )
      .then(({ data }) => {
        this.setState({ data, onSearch: false });
        successNotification("Datos consultados correctamente...");
      })
      .catch(handleRequestError);
  };

  render() {
    const { data } = this.state;
    const { idClub } = this.state.filters;
    const { clubs } = this.props;

    return (
      <Container className="p-0">
        <Form
          onSubmit={e => {
            e.preventDefault();
            this.search();
          }}
        >
          <Row>
            <Col>
              <BaseInput
                label="Club"
                name="club"
                id="club"
                type="select"
                value={idClub}
                onChange={this.onChangeFilter("idClub")}
                size={"sm"}
                options={[{ value: "", label: "Seleccionar club" }].concat(
                  clubs
                    ? clubs.map(option => ({
                        value: option.clubId + "",
                        label: option.nombre
                      }))
                    : []
                )}
              />
            </Col>
            <Col>
              <BaseNumericInput
                label={"# Membresía"}
                name="noMembresia"
                id="noMembresia"
                type="text"
                value={this.state.filters.membresiaIdPublico}
                onChange={this.onChangeFilter("membresiaIdPublico")}
              />
            </Col>
            <Col>
              <BaseNumericInput
                label={"# Persona"}
                name="noPersona"
                id="noPersona"
                type="text"
                value={this.state.filters.idPersona}
                onChange={this.onChangeFilter("idPersona")}
              />
            </Col>
            <Col>
              <DatePicker
                label="Fecha Inicial"
                name={START_DATE_FIELDNAME}
                id={START_DATE_FIELDNAME}
                selected={this.state.filters[START_DATE_FIELDNAME]}
                onChange={this.onChangeDate(START_DATE_FIELDNAME)}
              />
            </Col>
            <Col>
              <DatePicker
                label="Fecha Final"
                name={END_DATE_FIELDNAME}
                id={END_DATE_FIELDNAME}
                selected={this.state.filters[END_DATE_FIELDNAME]}
                onChange={this.onChangeDate(END_DATE_FIELDNAME)}
              />
            </Col>
            <Col>
              <br />
              <Button
                size="sm"
                className={`float-right btn-block`}
                type="submit"
              >
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
        <ReactTable
          data={data || []}
          columns={this.columns}
          className="mt-5 -striped -highlight"
          sortable={false}
          resizable={false}
          pageSize={20}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(PaymentLinesReport));
