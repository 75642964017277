import React from "react";
import { withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import PriceDataMemberships from "../../containers/priceDataMemberships";
import {
  MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES,
  PRICES_STATUS
} from "../../../types/MassiveAuthorizationPricesFilter";

const TabMemberships = () => (
  <Container className="p-0 mt-3 mb-3">
    <PriceDataMemberships
      filterTypes={[
        MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES.MEMBERSHIP,
        MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES.PRICE_TYPE
      ]}
      defaultStatus={PRICES_STATUS.PRICES_NO_AUTORIZADOS}
      massive
      disableAmounts
    />
  </Container>
);

export default withRouter(TabMemberships);
