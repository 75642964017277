import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import IAction from "../../../types/IAction";
import * as reportes from "../../actions/reports";
import CatPreliminarDebtRestructuring from "../../components/catPreliminarDebtRestructuring";
import Layout from "../../components/layout";

interface Props {
  downloadDebtRestructuringCatPreliminar: (body: object) => IAction;
  userContext: { clubSessionId: any };
}

class CatPreliminarDebtRestructuringPage extends React.Component<Props> {
  render() {
    return (
      <main>
        <Helmet title="Reporte restructuración de deuda para preliminar de CAT" />
        <Layout>
          <CatPreliminarDebtRestructuring
            downloadDebtRestructuringCatPreliminar={
              this.props.downloadDebtRestructuringCatPreliminar
            }
            userContext={this.props.userContext}
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    userContext: user.userContext
  };
};

const mapDispatchToProps = dispatch => ({
  downloadDebtRestructuringCatPreliminar: (clubId, diasACobrar) => {
    dispatch(
      reportes.downloadDebtRestructuringCatPreliminar(clubId, diasACobrar)
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CatPreliminarDebtRestructuringPage);
