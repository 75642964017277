import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import AnnuitiesQuote from "../../components/annuitiesQuote";
import * as catalogActions from "../../actions/catalogs";
import * as annuityActions from "../../actions/annuities";

interface Props {
  getAnnuityQuote: (filters: object) => void;
  saveAnnuityQuote: (data: object) => void;
  getBanksMonthsWithoutInterest: () => void;
  getClubs: () => void;
  getAnnuityTypes: () => void;
  getWaysPay: () => void;
  getUsers: () => any;
  banksMonthsWithoutInterest: object[];
  clubs: object[];
  annuityTypes: object[];
  waysPay: object[];
  quote: object;
  loading: boolean;
  users: object[];
  savedQuote: number;
  history: any;
}

class AnnuitiesQuotePage extends React.Component<Props, void> {
  componentDidMount() {
    this.props.getClubs();
    this.props.getAnnuityTypes();
    this.props.getWaysPay();
    this.props.getUsers();
    this.props.getBanksMonthsWithoutInterest();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.savedQuote && this.props.savedQuote) {
      this.props.history.push(
        `/anualidades/cotizacion/${this.props.savedQuote}`
      );
    }
  }

  render() {
    return (
      <main>
        <Helmet title="Annuities Quote" />
        <Layout>
          <AnnuitiesQuote
            getAnnuityQuote={this.props.getAnnuityQuote}
            saveAnnuityQuote={this.props.saveAnnuityQuote}
            quote={this.props.quote}
            loading={this.props.loading}
            clubs={this.props.clubs}
            annuityTypes={this.props.annuityTypes}
            waysPay={this.props.waysPay}
            users={this.props.users}
            banksMonthsWithoutInterest={this.props.banksMonthsWithoutInterest}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getAnnuityQuote: (filters: object) => {
    dispatch(annuityActions.getAnnuityQuote(filters));
  },
  saveAnnuityQuote: (data: object) => {
    dispatch(annuityActions.saveAnnuityQuote(data));
  },
  getClubs: () => {
    dispatch(catalogActions.getClubs());
  },
  getAnnuityTypes: () => {
    dispatch(catalogActions.getAnnuityTypes());
  },
  getWaysPay: () => {
    dispatch(catalogActions.getWaysPay());
  },
  getUsers: () => {
    dispatch(catalogActions.getSellers());
  },
  getBanksMonthsWithoutInterest: () => {
    dispatch(catalogActions.getBanksMonthsWithoutInterest());
  }
});

const mapStateToProps = state => {
  const { catalogs, annuities } = state;
  return {
    clubs: catalogs.clubs,
    annuityTypes: catalogs.annuityTypes,
    waysPay: catalogs.waysPay,
    users: catalogs.sellers,
    quote: annuities.quoteByFilters,
    banksMonthsWithoutInterest: catalogs.banksMonthsWithoutInterest,
    loading: annuities.quoteByFiltersLoading,
    savedQuote: annuities.savedQuote
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnuitiesQuotePage);
