import React from "react";
import { connect } from "react-redux";
import * as productActions from "../../actions/products";
import PriceDataMaintenance from "../../components/priceDataMaintenance";
import * as catalogActions from "../../actions/catalogs";

interface Props {
  addPricesToMaintenance: (
    clubId: string,
    tipoProducto: string,
    fechaVigencia: string,
    prices: object
  ) => any;
  successfulSave: boolean;
  getPricesToMaintenance: (params: object) => any;
  loading: boolean;
  prices: object[];
  savingPrices: boolean;
  savingPricesError: boolean;
  getConcepts: () => any;
  getStatus: () => any;
  conceptos: object[];
  estatus: object[];
  onClickSave: (e: object) => any;
  authorizePrices: (prices: object[]) => any;
  clubs: object[];
  getClubsProduct: (tipoProducto: string, id: string) => any;
  getAccountingAccounts: () => any;
  getProductAccounts: () => any;
  onLoading: () => any;
  onSuccessfulSave: () => any;
  getClubsAll: () => any;
  clubsAll: object[];
}

class PriceDataMaintenancePage extends React.Component<Props, void> {
  componentDidMount() {
    this.props.getConcepts();
    this.props.getStatus();
    this.props.getAccountingAccounts();
    this.props.getProductAccounts();
    this.props.getClubsAll();
  }

  render() {
    return (
      <div className="w-100 m-0 p-0">
        {this.props.estatus && this.props.conceptos && (
          <PriceDataMaintenance
            addPricesToMaintenance={this.props.addPricesToMaintenance}
            getPricesToMaintenance={this.props.getPricesToMaintenance}
            prices={this.props.prices}
            successfulSave={this.props.successfulSave}
            loading={this.props.loading}
            savingPrices={this.props.savingPrices}
            savingPricesError={this.props.savingPricesError}
            getConcepts={this.props.getConcepts}
            getStatus={this.props.getStatus}
            conceptos={this.props.conceptos}
            estatus={this.props.estatus}
            onClickSave={this.props.onClickSave}
            authorizePrices={this.props.authorizePrices}
            clubs={this.props.clubsAll}
            getClubsProduct={this.props.getClubsProduct}
            onLoading={this.props.onLoading}
            onSuccessfulSave={this.props.onSuccessfulSave}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addPricesToMaintenance: (
    productId: string,
    tipoProducto: string,
    fechaVigencia: string,
    price: object[]
  ) => {
    return dispatch(
      productActions.addPricesToMaintenance(
        productId,
        tipoProducto,
        fechaVigencia,
        price
      )
    );
  },
  getConcepts: () => {
    dispatch(catalogActions.getConcepts());
  },
  getStatus: () => {
    dispatch(catalogActions.getStatus());
  },
  getAccountingAccounts: () => {
    dispatch(catalogActions.getAccountingAccounts());
  },
  getProductAccounts: () => {
    dispatch(catalogActions.getProductAccounts());
  },
  getPricesToMaintenance: (params: object) => {
    return dispatch(productActions.getPricesToMaintenance(params));
  },
  authorizePrices: (prices: object[]) => {
    return dispatch(productActions.authorizeMaintenancesPrices(prices));
  },
  getClubsProduct: (tipoProducto: string, id: string) => {
    dispatch(productActions.getClubsProduct(tipoProducto, id));
  },
  getClubsAll: () => {
    dispatch(catalogActions.getClubs());
  }
});

const mapStateToProps = state => {
  const { products, catalogs } = state;
  return {
    successfulSave: products.pricesToMaintenanceSaved,
    conceptos: catalogs.conceptos,
    estatus: catalogs.estatus,
    prices: products.pricesToMaintenance,
    loading: products.loadingPricesToMaintenance,
    savingPrices: products.savingPricesMaintenance,
    savingPricesError: products.savingPricesMaintenanceError,
    clubs: products.productClubs,
    clubsAll: catalogs.clubs
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceDataMaintenancePage);
