import actionTypes from "./actionTypes";
import axios from "axios";
import constants from "../../utils/constants";

const TRACKING_BASE_URL = "/api/tracking";

export const getSalesTracking = (params: object) => {
  return {
    type: actionTypes.GET_SALES_TRACKING,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${TRACKING_BASE_URL}`, {
        withCredentials: true,
        params: { ...params }
      })
    }
  };
};

export const getSalesTrackingBySeller = (params: object) => {
  return {
    type: actionTypes.GET_SALES_TRACKING_BY_SELLER,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${TRACKING_BASE_URL}/sellers`, {
        withCredentials: true,
        params: { ...params }
      })
    }
  };
};

export const sendCompromisesBySeller = compromises => {
  return {
    type: actionTypes.UPDATE_COMPROMISES_BY_SELLER,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${TRACKING_BASE_URL}/compromises/sellers`,
        compromises,
        {
          withCredentials: true,
          params: {
            clubId: compromises.clubId
          }
        }
      )
    }
  };
};
