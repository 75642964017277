import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import { withRouter } from "react-router-dom";
import AdminQuizzAndDevicesPerYear from "../../components/adminQuizzAndDevicesPerYear";

interface Props {}

class AdminQuizzAndDevicesPerYearPage extends React.Component<Props> {
  render() {
    return (
      <main>
        <Helmet title="Sports Products" />
        <Layout>
          <AdminQuizzAndDevicesPerYear />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminQuizzAndDevicesPerYearPage);
