import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import * as catalogActions from "../../actions/catalogs";
import * as reportsActions from "../../actions/reports";
import ShiftDeliveryReport from "../../components/shiftDeliveryReport";
import IAction from "../../../types/IAction";

interface Props {
  getClubs: () => void;
  getShiftDelivery: (clubId: string, fechaDePago: string) => IAction;
  pdfShiftDelivery: (
    data: object,
    clubId: string,
    fechaDePago: string
  ) => IAction;
  clubs: object[];
  reportShiftDelivery: any;
}

interface State {}

class ShiftDelivery extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    this.props.getClubs();
  }

  render() {
    return (
      <main>
        <Helmet title="Shift delivery" />
        <Layout>
          <ShiftDeliveryReport
            getShiftDelivery={this.props.getShiftDelivery}
            pdfShiftDelivery={this.props.pdfShiftDelivery}
            clubs={this.props.clubs}
            reportShiftDelivery={this.props.reportShiftDelivery}
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({ catalogs, reports }) => {
  return {
    clubs: catalogs.clubs,
    reportShiftDelivery: reports.reportShiftDelivery
  };
};

const mapDispatchToProps = dispatch => ({
  getClubs: () => {
    dispatch(catalogActions.getClubs());
  },
  getShiftDelivery: (clubId: string, fechaDePago: string) => {
    return dispatch(reportsActions.getShiftDelivery(clubId, fechaDePago));
  },
  pdfShiftDelivery: (data: object, clubId: string, fechaDePago: string) => {
    return dispatch(reportsActions.pdfShiftDelivery(data, clubId, fechaDePago));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShiftDelivery);
