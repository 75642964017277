import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import * as clubsActions from "../../actions/clubs";
import * as dealersActions from "../../actions/dealers";
import * as reportsActions from "../../actions/reports";
import IAction from "../../../types/IAction";
import ReferenceCalculationPage from "../../components/referenceCalculationPage";

interface Props {
  getAllClubsBasicData: () => void;
  clubs: object[];
  clearDealers: () => IAction;
  getReferenceConsecutiveClub: (clubId: string) => IAction;
  createReferencesByClub: (
    clubId: string,
    monto: string,
    formaDePago: string,
    fechaCreacion: string,
    numeroReferencias: string
  ) => IAction;
  referenceConsecutive: string;
  referencesByClub: object[];
  clearReasignMembership: () => IAction;
}

interface State {}

class ReferenceCalculation extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    this.props.getAllClubsBasicData();
  }

  componentWillUnmount() {
    this.props.clearDealers();
    this.props.clearReasignMembership();
  }

  render() {
    return (
      <main>
        <Helmet title="Shift delivery" />
        <Layout>
          <ReferenceCalculationPage
            clubs={this.props.clubs}
            getReferenceConsecutiveClub={this.props.getReferenceConsecutiveClub}
            referenceConsecutive={this.props.referenceConsecutive}
            createReferencesByClub={this.props.createReferencesByClub}
            referencesByClub={this.props.referencesByClub}
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({ clubs, reports }) => {
  return {
    clubs: clubs.allClubsBasicData,
    referenceConsecutive: reports.referenceConsecutive,
    referencesByClub: reports.referencesByClub
  };
};

const mapDispatchToProps = dispatch => ({
  getAllClubsBasicData: () => {
    dispatch(clubsActions.getAllClubsBasicData());
  },
  clearDealers: () => {
    return dispatch(dealersActions.clearDealers());
  },
  getReferenceConsecutiveClub: (clubId: string) => {
    return dispatch(reportsActions.getReferenceConsecutiveClub(clubId));
  },
  createReferencesByClub: (
    clubId: string,
    monto: string,
    formaDePago: string,
    fechaCreacion: string,
    numeroReferencias: string
  ) => {
    return dispatch(
      reportsActions.createReferencesByClub(
        clubId,
        monto,
        formaDePago,
        fechaCreacion,
        numeroReferencias
      )
    );
  },
  clearReasignMembership: () => {
    return dispatch(reportsActions.clearReasignMembership());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferenceCalculation);
