import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import { getErrorMessage } from "../../utils/helpers";
import { readMessageBack } from "../../utils/readMessageBack";
interface State {
  gettingRolesError: object;
  gettingRoles: boolean;
  roles: object[];
}
const initialState: State = {
  gettingRolesError: null,
  gettingRoles: false,
  roles: null
};
import { errorNotification, ERROR } from "../../utils/notifications";

export default function rolesReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.GET_ROLES}_PENDING`: {
      return {
        ...state,
        gettingRolesError: null,
        gettingRoles: true
      };
    }
    case `${actionTypes.GET_ROLES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        gettingRolesError: getErrorMessage(action.payload),
        roles: null,
        gettingRoles: false
      };
    }
    case `${actionTypes.GET_ROLES}_FULFILLED`: {
      return {
        ...state,
        gettingRolesError: null,
        roles: action.payload.data,
        gettingRoles: false
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
