import axios from "axios";
import actionTypes from "../actionTypes";
import constants from "../../utils/constants";

const SCHEDULES_BASE_URL = "/api/schedule-patterns";
const MAINTENANCES_BASE_URL = "/api/maintenances";

export const storePattern = (fields: object): any => {
  return {
    type: actionTypes.STORE_PATTERN,
    payload: {
      promise: axios.put(`${constants.BASE_URL}${SCHEDULES_BASE_URL}`, fields, {
        withCredentials: true
      })
    }
  };
};

export const updateGroupsSchedulePatterns = (
  id: number,
  fields: object
): any => {
  return {
    type: actionTypes.UPDATE_PATTERN_GROUPS,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${MAINTENANCES_BASE_URL}/${id}/groups-schedule-patterns`,
        fields,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getGroupsSchedulePatterns = (id: number): any => {
  return {
    type: actionTypes.GET_PATTERN_GROUPS_MAINTENANCE,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${MAINTENANCES_BASE_URL}/${id}/groups-schedule-patterns`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getPatterns = (): any => {
  return {
    type: actionTypes.GET_PATTERNS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${SCHEDULES_BASE_URL}`, {
        withCredentials: true
      })
    }
  };
};

export const getPatternById = (patternId: number): any => {
  return {
    type: actionTypes.GET_PATTERN_BY_ID,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${SCHEDULES_BASE_URL}/${patternId}`,
        {
          withCredentials: true
        }
      )
    }
  };
};
