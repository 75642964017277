import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import { Button, Col, Container, Row } from "reactstrap";
import { infoNotification } from "../../../utils/notifications";
import { PropsTable } from "./interfaces";
import s from "./styles.scss";

class TableReportPartnerMembershipsWithoutCharge extends React.Component<
  PropsTable
> {
  //COLUMN DEFINITION
  columns = [
    {
      Header: () => <p className="font-weight-bold">Mantenimiento</p>,
      id: 0,
      width: 300,
      className: "text-left",
      accessor: "nombre"
    }
  ];

  //CONTROL BEHAVIOR
  downloadReportPartnerMembershipWithoutMonthCharge = () => {
    infoNotification("Descargando reporte...");
    this.props.downloadReportPartnerMembershipWithoutMonthCharge();
  };

  //RENDER
  render() {
    return (
      <Container className="p-0">
        {!!(this.props.data || []).length && (
          <Row className={"mt-5"}>
            <Col sm="6">
              Resultados de la búsqueda:{" "}
              <b
                style={{ fontWeight: "bolder", display: "inline-block" }}
                className="ml-3"
              >
                {(this.props.data || []).length}
              </b>
            </Col>
            <Col sm="6" className="text-right">
              <span>Exportar a CSV: </span>
              {!this.props.onSearch && (
                <Button
                  id="btnReportExportCsv"
                  color="link"
                  disabled={this.props.onSearch}
                  onClick={() => {
                    this.downloadReportPartnerMembershipWithoutMonthCharge();
                  }}
                >
                  <FontAwesomeIcon icon={faFileExport} color="black" />
                </Button>
              )}
            </Col>
          </Row>
        )}

        <ReactTable
          data={this.props.data}
          columns={this.columns}
          className="mt-2 -striped -highlight"
          sortable={false}
          resizable={false}
          pageSize={(this.props.data || []).length + 2}
          loading={this.props.onSearch}
        />
      </Container>
    );
  }
}

export default withRouter(
  withStyles(s)(TableReportPartnerMembershipsWithoutCharge)
);
