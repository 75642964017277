import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  Table
} from "reactstrap";
import s from "./styles.scss";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import RichEditor from "../../richEditor";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import moment from "moment";
import { errorNotification } from "../../../../utils/notifications";

const ESTATUS_CERRADA = "CERRADA";

interface Props {
  saveObservation: (observacion: object) => any;
  estatus: string;
  demand: any;
  comentarios: { comentario: string; fechaCreacion: string }[];
}

interface State {
  observacion: string;
  cerrarQueja: boolean;
  estatus: string;
  comentariosSeguimiento: { comentario: string; fechaCreacion: string }[];
  editorState: EditorState;
}

class ObservationComponent extends React.Component<Props, State> {
  state = {
    observacion: "",
    cerrarQueja: false,
    estatus: "",
    comentariosSeguimiento: [],
    editorState: EditorState.createEmpty()
  };

  componentDidUpdate({
    comentarios: prevPropsComentarios,
    estatus: prevEstatus
  }) {
    if (
      prevPropsComentarios !== this.props.comentarios &&
      this.props.comentarios
    ) {
      this.setState({ comentariosSeguimiento: this.props.comentarios });
    }

    if (prevEstatus !== this.props.estatus) {
      this.setState({ estatus: this.props.estatus });
    }
  }

  componentDidMount() {
    if (this.props.comentarios) {
      this.setState({
        comentariosSeguimiento: this.props.comentarios,
        estatus: this.props.estatus
      });
    }
  }

  setCerrarQueja = e => {
    const cerrarQueja = e.target.checked;
    this.setState({ ...this.state, cerrarQueja });
  };

  saveObservation = () => {
    const { observacion, cerrarQueja } = this.state;
    if (
      !observacion ||
      observacion.trim() == "" ||
      observacion.trim() == "<p></p>"
    ) {
      errorNotification("La observación es requerida");
      return false;
    }
    this.props
      .saveObservation({
        observacion,
        cerrarQueja
      })
      .then(({ value: { data } }) => {
        const { estatus } = data;
        this.setState({
          estatus,
          observacion: "",
          editorState: EditorState.createEmpty()
        });
      });
  };

  render() {
    return (
      <Container>
        {this.state.estatus != ESTATUS_CERRADA && (
          <Row>
            <Col>
              <div>
                <RichEditor
                  onChangeEditor={async editorState => {
                    await this.setState({ editorState });
                    this.setState({
                      observacion: draftToHtml(
                        convertToRaw(editorState.getCurrentContent())
                      )
                    });
                  }}
                  editorState={this.state.editorState}
                />
              </div>
            </Col>
          </Row>
        )}
        {this.state.estatus != ESTATUS_CERRADA && (
          <div className="form-row">
            <Col xs={1} />
            <FormGroup className="col-2" check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={this.state.cerrarQueja}
                  onChange={e => this.setCerrarQueja(e)}
                  disabled={this.state.estatus == ESTATUS_CERRADA}
                />
                Cerrar observación
              </Label>
            </FormGroup>
            <Col />
            <Col xs={3}>
              <Button
                onClick={this.saveObservation}
                className={`${s.buttonMarginTop} ${s.primaryButton}`}
                disabled={this.state.estatus == ESTATUS_CERRADA}
                block
              >
                Agregar Observación
              </Button>
            </Col>
          </div>
        )}
        <Row className="mt-3">
          <Col>
            <div className="table-responsive">
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Creado Por</th>
                    <th>Observación</th>
                    <th>Fecha Creación</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.demand &&
                    this.props.demand.comentariosSeguimiento &&
                    this.props.demand.comentariosSeguimiento.map(cs => (
                      <tr>
                        <td>{cs.createdName}</td>
                        <td
                          style={{
                            whiteSpace: "pre-line",
                            overflowWrap: "break-word",
                            width: "75%"
                          }}
                          dangerouslySetInnerHTML={{ __html: cs.comentario }}
                        ></td>
                        <td>
                          {moment(cs.fechaCreacion)
                            .utc()
                            .format("YYYY-MM-DD")}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withStyles(s)(ObservationComponent);
