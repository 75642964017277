import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Reform from "@franleplant/reform";
import moment from "moment";
import { Container, Row, Col, Button } from "reactstrap";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";

import DatePicker from "../common/DatePicker";
import CONSTANTS from "../../../utils/constants";
import BaseInput from "../../../toolkit/baseInput";
import IAction from "../../../types/IAction";
import s from "./styles.scss";
import ModalFrame from "../../../toolkit/modalFrame";
import { postExtraComision } from "../../actions/events";
import { errorNotification } from "../../../utils/notifications";

interface Fields {
  fechaInicio: string;
  fechaFin: string;
}

export interface EventType {
  informacionCliente: {
    clienteId: number;
    nombre: string;
    fechaInicioEvento: string;
    fechaRegistro: string;
    ventaId: number;
    factura: string;
    productoInstanciaId: number;
    tieneEventos: boolean;
  };
  informacionClase: {
    id: number;
    fechaClase: string;
    horaClase: string;
    instructorId: number;
    productoInstanciaId: number;
    nombreEvento: string;
    estatus: string;
    esActivo: boolean;
  };
}

interface Props extends RouteComponentProps {
  clubs: any;
  searching: boolean;
  history: any;
  match: any;
  getEventsByFilter: (body: object) => any;
  events: EventType[];
  toggleEditSession: (edit?: boolean) => IAction;
  sellers: any;
  products: any;
  fetchData: (args?: any) => void;
  filters: any;
  onChangeFilter: (value: any, name: any) => void;
  getEvents: (clubId: number, args?: any) => any;
  getProducts: (args?: any) => any;
  sportsProducts: any[];
  userContext: any;
}

interface State {
  selectedClub: string;
  fields: Fields;
  changeModal: boolean;
  estatus: string;
  comisionIsOpen: boolean;
  razonIgnorarPuesto;
  session: any;
  generando: boolean;
  buscando: boolean;
}

// Estados posibles de cada clase
const ESTATUS = [
  {
    value: "ASIGNADO",
    name: "Asignado"
  },
  {
    value: "CANCELADO",
    name: "Cancelado"
  },
  {
    value: "FALTO_INSTRUCTOR",
    name: "Faltó instructor"
  },
  {
    value: "FALTO_USUARIO",
    name: "Faltó usuario"
  },
  {
    value: "IMPARTIDO",
    name: "Impartido"
  }
];

const RAZON_IGNORAR_PUESTO = [
  {
    value: "Puesto no permitido/configurado para impartir",
    label: "Puesto no permitido/configurado para impartir"
  },
  {
    value: "Instructor no disponible",
    label: "Instructor no disponible"
  },
  {
    value: "Instructor dado de baja",
    label: "Instructor dado de baja"
  },
  {
    value: "Solicitud de cambio del cliente",
    label: "Solicitud de cambio del cliente"
  },
  {
    value: "Cambio de horario",
    label: "Cambio de horario"
  }
];

const START_DATE_FIELDNAME = "fechaInicio";
const END_DATE_FIELDNAME = "fechaFin";

const getCleanFields: () => Fields = () => ({
  fechaInicio: moment().format("YYYY-MM-DD"),
  fechaFin: moment().format("YYYY-MM-DD")
});

class ScheduledClasses extends React.Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);

  state = {
    selectedClub: "",
    fields: getCleanFields(),
    errors: {},
    changeModal: false,
    estatus: "",
    comisionIsOpen: false,
    razonIgnorarPuesto: "Puesto no permitido/configurado para impartir",
    session: null,
    generando: false,
    buscando: false
  };

  validationRules = {};

  validationMessages = {
    required: _ => `Obligatorio`,
    default: _ => `Invalido`
  };

  search = () => {
    if (
      !this.state.selectedClub ||
      !this.state.fields.fechaInicio ||
      !this.state.fields.fechaFin
    ) {
      errorNotification(
        "Verifique la información soliciatada en el formulario"
      );
      return;
    }

    this.props.getEventsByFilter(
      this.state.estatus
        ? {
            clubId: this.state.selectedClub,
            startDate: this.state.fields.fechaInicio,
            endDate: this.state.fields.fechaFin,
            estatus: this.state.estatus
          }
        : {
            clubId: this.state.selectedClub,
            startDate: this.state.fields.fechaInicio,
            endDate: this.state.fields.fechaFin
          }
    );
  };

  handleChangeModal = () => {
    this.setState({ changeModal: true });
  };

  componentDidMount(): void {
    this.setState({
      selectedClub:
        (this.props.userContext && this.props.userContext.clubSessionId) || ""
    });
  }

  componentDidUpdate = () => {
    if (this.state.changeModal) {
      this.setState({
        changeModal: false
      });
      this.search();
    }
  };

  handleClubSelection = e => {
    this.setState({ selectedClub: e.target.value });
  };

  handleEstatusSelection = e => {
    this.setState({ estatus: e.target.value });
  };

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: date
        };
        return { ...state, error: {}, fields };
      });
      this.re.validateField(fieldName, date);
    };
  };

  comisionToggleModal = () => {
    this.setState({
      comisionIsOpen: !this.state.comisionIsOpen
    });
  };

  render() {
    const columns = [
      { Header: "Usuario", accessor: "informacionCliente.nombre" },
      { Header: "Tipo de clase", accessor: "informacionClase.nombreEvento" },
      {
        Header: "Fecha programada",
        accessor: "informacionClase.fechaClase"
      },
      {
        Header: "Instructor",
        id: "instructor",
        accessor: session => {
          if (this.props.sellers) {
            let texto = this.props.sellers.find(
              element =>
                element.userId ==
                parseInt(session.informacionClase.instructorId)
            ) || {
              name: `¡Usuario ${session.informacionClase.instructorId} inactivo!`
            };

            return texto.name || "¡Usuario inactivo!";
          } else {
            "Cargando...";
          }
        }
      },
      { Header: "Factura", accessor: "informacionCliente.factura" },
      { Header: "Estatus", accessor: "informacionClase.estatus" },
      { Header: "Hora", accessor: "informacionClase.horaClase" },
      {
        Header: "",
        id: "comision",
        accessor: session => {
          if (!session.comisionEsperada) {
            return "No aplica";
          }

          if (session.comisionGenerada) {
            return session.estatusComision
              ? session.estatusComision[0] +
                  session.estatusComision.slice(1).toLowerCase()
              : "Pendiente";
          }

          return (
            <a
              id={`${session.productoId}`}
              style={{
                color: "#3366ff",
                textDecoration: "underline",
                cursor: "pointer"
              }}
              onClick={() => {
                this.setState({
                  comisionIsOpen: true,
                  session
                });
              }}
            >
              NO GENERADA
            </a>
          );
        }
      },
      {
        Header: "",
        id: "buttons",
        accessor: session => {
          return (
            <a
              id={`${session.productoId}`}
              onClick={() => {
                let sessionSend = session;
                sessionSend.handleChangeModal = this.handleChangeModal;
                this.props.toggleEditSession(sessionSend);
              }}
            >
              <FontAwesomeIcon icon={faPen} />
            </a>
          );
        }
      }
    ];
    return (
      <>
        <ModalFrame
          title={"Generar comisión"}
          isOpen={this.state.comisionIsOpen}
          toggle={this.comisionToggleModal}
          size="lg"
        >
          <Row className={`${s.customDistanciaCheck}`}>
            <Col xs={12} md={12} lg={12}>
              <div className="alert alert-warning mt-3 mb-4 m-0">
                Esta comisión no se generó, esto suele suceder porque no existe
                una configuración aplicable al puesto de trabajo o producto.
                <br />
                <br />
                Si asignó esta clase de forma extraordinaria, elija una razón
                (se ignorará si el puesto de trabajo es válido).
              </div>
            </Col>

            <Col xs={12} md={12} lg={12}>
              <BaseInput
                label="Razón de generación"
                name="razonIgnorarPuesto"
                type="select"
                id="razonIgnorarPuesto"
                placeholder="Estatus"
                value={this.state.razonIgnorarPuesto}
                options={RAZON_IGNORAR_PUESTO}
                onChange={e =>
                  this.setState({ razonIgnorarPuesto: e.target.value })
                }
              />
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xs={6} md={6} lg={6}>
              &nbsp;
            </Col>
            <Col xs={3} md={3} lg={3}>
              <Button
                hidden={this.state.generando}
                block
                color="secondary"
                onClick={this.comisionToggleModal}
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={3} md={3} lg={3}>
              <Button
                block
                disabled={this.state.generando}
                onClick={() => {
                  const idEvento = this.state.session.informacionClase.id;
                  const razonIgnorarPuesto = this.state.razonIgnorarPuesto;
                  this.setState({
                    generando: true
                  });

                  postExtraComision(idEvento, razonIgnorarPuesto)
                    .then(() => {
                      this.setState({
                        generando: false,
                        comisionIsOpen: false
                      });
                      this.search();
                    })
                    .catch(err => {
                      errorNotification(
                        err &&
                          err.response &&
                          err.response.data &&
                          err.response.data.message
                          ? err.response.data.message
                          : "No se pudo generar la comisión, verifique el producto"
                      );

                      this.setState({
                        generando: false
                      });
                    });
                }}
              >
                {this.state.generando ? "Espere..." : "Generar"}
              </Button>
            </Col>
          </Row>
        </ModalFrame>
        <Container className="p-0 mt-3">
          <Row>
            <Col xs={12} md={3} lg={3}>
              <BaseInput
                label="*Club"
                name="club"
                type="select"
                id="club"
                placeholder="Tipo"
                value={this.state.selectedClub}
                disabled={
                  this.props.userContext && this.props.userContext.clubSessionId
                }
                options={
                  this.props.clubs &&
                  [{ value: 0, label: "Seleccione el club" }].concat(
                    this.props.clubs.map(e => {
                      return { value: e.clubId, label: e.nombre };
                    })
                  )
                }
                onChange={this.handleClubSelection}
              />
            </Col>
            <Col xs={12} md={2} lg={2}>
              <DatePicker
                label="*Fecha inicial"
                name={START_DATE_FIELDNAME}
                id={START_DATE_FIELDNAME}
                selected={this.state.fields[START_DATE_FIELDNAME]}
                onChange={this.onChangeDate(START_DATE_FIELDNAME)}
              />
            </Col>
            <Col xs={12} md={2} lg={2}>
              <DatePicker
                label="*Fecha final"
                name={END_DATE_FIELDNAME}
                id={END_DATE_FIELDNAME}
                selected={this.state.fields[END_DATE_FIELDNAME]}
                onChange={this.onChangeDate(END_DATE_FIELDNAME)}
              />
            </Col>
            <Col xs={12} md={2} lg={2}>
              <BaseInput
                label="Estatus"
                name="estatus"
                type="select"
                id="estatus"
                placeholder="Estatus"
                value={this.state.estatus}
                options={[{ value: "", label: "Seleccione el estado" }].concat(
                  ESTATUS.map(e => {
                    return { value: e.value, label: e.name };
                  })
                )}
                onChange={this.handleEstatusSelection}
              />
            </Col>
            <Col className="mt-4" xs={12} md={3} lg={3}>
              <Button
                size="sm"
                className={`float-right btn-block`}
                onClick={this.search}
                disabled={!this.state.selectedClub || this.props.searching}
              >
                {this.props.searching ? "Espere..." : "Buscar"}
              </Button>
            </Col>
          </Row>
          <Row>
            {this.props.events && (
              <Col>
                <ReactTable
                  manual
                  data={this.props.events}
                  columns={columns}
                  className="w-100"
                  defaultPageSize={CONSTANTS.DEFAULT_PAGE_SIZE}
                  loading={this.props.searching}
                  sortable={false}
                  pageSize={(this.props.events || []).length + 3}
                  showPagination={false}
                  style={{ height: CONSTANTS.TABLES_HEIGHT }}
                />
              </Col>
            )}
          </Row>
        </Container>
      </>
    );
  }
}
export default withRouter<Props, any>(withStyles(s)(ScheduledClasses));
