import React from "react";
import ReactTable from "react-table";
import { ID_CONTADO } from "../../../utils/constants";
import MexicanCurrency from "../common/currency";

export interface Payment {
  formaDePago: string;
  mesesSinIntereses: number;
  monto: number;
  numeroAutorizacion: string;
  pagoId: number;
  referencia: string;
}

interface Props {
  payments: Payment[];
}

const columns = [
  {
    Header: "FORMA DE PAGO",
    accessor: "formaDePago"
  },
  {
    Header: "MESES SIN INTERESES",
    accessor: "mesesSinIntereses",
    Cell: ({ value }) => (value === ID_CONTADO ? "CONTADO" : value)
  },
  {
    Header: "MONTO",
    accessor: "monto",
    Cell: ({ value }) => <MexicanCurrency quantity={value}></MexicanCurrency>
  },
  {
    Header: "NUMERO DE AUTORIZACION",
    accessor: "numeroAutorizacion"
  },
  {
    Header: "REFERENCIA",
    accessor: "referencia"
  },
  {
    Header: "ESTATUS",
    accessor: "estatus"
  },
  {
    Header: "FACTURA",
    accessor: "folioFactura"
  }
];

const PaymentsTable = ({ payments }: Props) => (
  <ReactTable
    data={payments}
    columns={columns}
    sortable={false}
    showPagination={false}
    pageSize={payments.length || 5}
    className="-highlight w-100"
  />
);

export default PaymentsTable;
