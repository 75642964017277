import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter, RouteComponentProps } from "react-router-dom";
import Layout from "../../components/layout";
import { Helmet } from "react-helmet";
import s from "./styles.scss";
import SignatureTest from "./signature/signature";
import { Container, Row, Col } from "reactstrap";

interface Props extends RouteComponentProps {}

interface State {}

class TestPage extends React.Component<Props, State> {
  render() {
    return (
      <Layout>
        <Container>
          <Helmet title="Inicio" />
          <Row className={"my-3"}>
            <SignatureTest />
          </Row>
        </Container>
      </Layout>
    );
  }
}

export default withRouter(withStyles(s)(TestPage));
