import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Container, Col, Button, Row } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import MexicanCurrency from "../common/currency";
import DatePicker from "../common/DatePicker";
import s from "./styles.scss";
import IAction from "../../../types/IAction";

interface Props {
  clubs: object[];
  getShiftDelivery: (clubId: string, fechaDePago: string) => IAction;
  pdfShiftDelivery: (
    data: object,
    clubId: string,
    fechaDePago: string
  ) => IAction;
  reportShiftDelivery: any;
}

interface State {
  filters: object;
  load: boolean;
  efectivo: any;
  cheque: any;
  tdc: any;
  tdd: any;
  amex: any;
  transferencia: any;
  definir99: any;
  linea: any;
  app: any;
  fields: any;
}

const START_DATE_FIELDNAME = "fechaInicio";

class ShiftDeliveryReport extends React.Component<Props, State> {
  state = {
    filters: {
      idClub: "",
      fechaInicio: null
    },
    fields: {
      observaciones: ""
    },
    efectivo: {
      montoTotal: 0,
      montoReal: 0,
      diferencia: 0,
      observaciones: ""
    },
    cheque: {
      montoTotal: 0,
      montoReal: 0,
      diferencia: 0,
      observaciones: ""
    },
    tdc: {
      montoTotal: 0,
      montoReal: 0,
      diferencia: 0,
      observaciones: ""
    },
    tdd: {
      montoTotal: 0,
      montoReal: 0,
      diferencia: 0,
      observaciones: ""
    },
    amex: {
      montoTotal: 0,
      montoReal: 0,
      diferencia: 0,
      observaciones: ""
    },
    transferencia: {
      montoTotal: 0,
      montoReal: 0,
      diferencia: 0,
      observaciones: ""
    },
    definir99: {
      montoTotal: 0,
      montoReal: 0,
      diferencia: 0,
      observaciones: ""
    },
    linea: {
      montoTotal: 0,
      montoReal: 0,
      diferencia: 0,
      observaciones: ""
    },
    app: {
      montoTotal: 0,
      montoReal: 0,
      diferencia: 0,
      observaciones: ""
    },
    load: false
  };

  componentDidUpdate(prevProps, prevStates) {
    if (
      prevStates.efectivo.diferencia !== this.state.efectivo.diferencia ||
      prevStates.efectivo.montoTotal !== this.state.efectivo.montoTotal ||
      prevStates.efectivo.montoReal !== this.state.efectivo.montoReal
    ) {
      const { montoTotal, montoReal, observaciones } = this.state.efectivo;
      const diferencia = montoTotal - montoReal;
      this.setState(state => ({
        ...state,
        efectivo: { montoTotal, montoReal, diferencia, observaciones }
      }));
    }

    if (
      prevStates.cheque.diferencia !== this.state.cheque.diferencia ||
      prevStates.cheque.montoTotal !== this.state.cheque.montoTotal ||
      prevStates.cheque.montoReal !== this.state.cheque.montoReal
    ) {
      const { montoTotal, montoReal, observaciones } = this.state.cheque;
      const diferencia = montoTotal - montoReal;
      this.setState(state => ({
        ...state,
        cheque: { montoTotal, montoReal, diferencia, observaciones }
      }));
    }

    if (
      prevStates.tdc.diferencia !== this.state.tdc.diferencia ||
      prevStates.tdc.montoTotal !== this.state.tdc.montoTotal ||
      prevStates.tdc.montoReal !== this.state.tdc.montoReal
    ) {
      const { montoTotal, montoReal, observaciones } = this.state.tdc;
      const diferencia = montoTotal - montoReal;
      this.setState(state => ({
        ...state,
        tdc: { montoTotal, montoReal, diferencia, observaciones }
      }));
    }

    if (
      prevStates.tdd.diferencia !== this.state.tdd.diferencia ||
      prevStates.tdd.montoTotal !== this.state.tdd.montoTotal ||
      prevStates.tdd.montoReal !== this.state.tdd.montoReal
    ) {
      const { montoTotal, montoReal, observaciones } = this.state.tdd;
      const diferencia = montoTotal - montoReal;
      this.setState(state => ({
        ...state,
        tdd: { montoTotal, montoReal, diferencia, observaciones }
      }));
    }

    if (
      prevStates.amex.diferencia !== this.state.amex.diferencia ||
      prevStates.amex.montoTotal !== this.state.amex.montoTotal ||
      prevStates.amex.montoReal !== this.state.amex.montoReal
    ) {
      const { montoTotal, montoReal, observaciones } = this.state.amex;
      const diferencia = montoTotal - montoReal;
      this.setState(state => ({
        ...state,
        amex: { montoTotal, montoReal, diferencia, observaciones }
      }));
    }

    if (
      prevStates.transferencia.diferencia !==
        this.state.transferencia.diferencia ||
      prevStates.transferencia.montoTotal !==
        this.state.transferencia.montoTotal ||
      prevStates.transferencia.montoReal !== this.state.transferencia.montoReal
    ) {
      const { montoTotal, montoReal, observaciones } = this.state.transferencia;
      const diferencia = montoTotal - montoReal;
      this.setState(state => ({
        ...state,
        transferencia: { montoTotal, montoReal, diferencia, observaciones }
      }));
    }

    if (
      prevStates.definir99.diferencia !== this.state.definir99.diferencia ||
      prevStates.definir99.montoTotal !== this.state.definir99.montoTotal ||
      prevStates.definir99.montoReal !== this.state.definir99.montoReal
    ) {
      const { montoTotal, montoReal, observaciones } = this.state.definir99;
      const diferencia = montoTotal - montoReal;
      this.setState(state => ({
        ...state,
        definir99: { montoTotal, montoReal, diferencia, observaciones }
      }));
    }

    if (
      prevStates.linea.diferencia !== this.state.linea.diferencia ||
      prevStates.linea.montoTotal !== this.state.linea.montoTotal ||
      prevStates.linea.montoReal !== this.state.linea.montoReal
    ) {
      const { montoTotal, montoReal, observaciones } = this.state.linea;
      const diferencia = montoTotal - montoReal;
      this.setState(state => ({
        ...state,
        linea: { montoTotal, montoReal, diferencia, observaciones }
      }));
    }

    if (
      prevStates.app.diferencia !== this.state.app.diferencia ||
      prevStates.app.montoTotal !== this.state.app.montoTotal ||
      prevStates.app.montoReal !== this.state.app.montoReal
    ) {
      const { montoTotal, montoReal, observaciones } = this.state.app;
      const diferencia = montoTotal - montoReal;
      this.setState(state => ({
        ...state,
        app: { montoTotal, montoReal, diferencia, observaciones }
      }));
    }

    if (this.props.reportShiftDelivery && !this.state.load) {
      let efectivoMontoTotal = 0;
      let efectivoMontoReal = 0;
      let efectivoDiferencia = 0;
      let efectivoObservaciones = "";
      let chequeMontoTotal = 0;
      let chequeMontoReal = 0;
      let chequeDiferencia = 0;
      let chequeObservaciones = "";
      let tdcMontoTotal = 0;
      let tdcMontoReal = 0;
      let tdcDiferencia = 0;
      let tdcObservaciones = "";
      let tddMontoTotal = 0;
      let tddMontoReal = 0;
      let tddDiferencia = 0;
      let tddObservaciones = "";
      let amexMontoTotal = 0;
      let amexMontoReal = 0;
      let amexDiferencia = 0;
      let amexObservaciones = "";
      let transferenciaMontoTotal = 0;
      let transferenciaMontoReal = 0;
      let transferenciaDiferencia = 0;
      let transferenciaObservaciones = "";
      let definir99MontoTotal = 0;
      let definir99MontoReal = 0;
      let definir99Diferencia = 0;
      let definir99Observaciones = "";
      let lineaMontoTotal = 0;
      let lineaMontoReal = 0;
      let lineaDiferencia = 0;
      let lineaObservaciones = "";
      let appMontoTotal = 0;
      let appMontoReal = 0;
      let appDiferencia = 0;
      let appObservaciones = "";

      this.props.reportShiftDelivery.detalles.map(data => {
        switch (data.formaDePago) {
          case "EFECTIVO":
            efectivoMontoTotal = data.montoTotal;
            efectivoMontoReal = data.montoReal;
            efectivoDiferencia = efectivoMontoTotal - efectivoMontoReal;
            efectivoObservaciones = data.observaciones;
            break;
          case "CHEQUE":
            chequeMontoTotal = data.montoTotal;
            chequeMontoReal = data.montoReal;
            chequeDiferencia = efectivoMontoTotal - efectivoMontoReal;
            chequeObservaciones = data.observaciones;
            break;
          case "TDC":
            tdcMontoTotal = data.montoTotal;
            tdcMontoReal = data.montoReal;
            tdcDiferencia = efectivoMontoTotal - efectivoMontoReal;
            tdcObservaciones = data.observaciones;
            break;
          case "TDD":
            tddMontoTotal = data.montoTotal;
            tddMontoReal = data.montoReal;
            tddDiferencia = efectivoMontoTotal - efectivoMontoReal;
            tddObservaciones = data.observaciones;
            break;
          case "AMEX":
            amexMontoTotal = data.montoTotal;
            amexMontoReal = data.montoReal;
            amexDiferencia = efectivoMontoTotal - efectivoMontoReal;
            amexObservaciones = data.observaciones;
            break;
          case "TRANSFERENCIA":
            transferenciaMontoTotal = data.montoTotal;
            transferenciaMontoReal = data.montoReal;
            transferenciaDiferencia = efectivoMontoTotal - efectivoMontoReal;
            transferenciaObservaciones = data.observaciones;
            break;
          case "POR_DEFINIR_99":
            definir99MontoTotal = data.montoTotal;
            definir99MontoReal = data.montoReal;
            definir99Diferencia = efectivoMontoTotal - efectivoMontoReal;
            definir99Observaciones = data.observaciones;
            break;
          case "PAGO_EN_LINEA":
            lineaMontoTotal = data.montoTotal;
            lineaMontoReal = data.montoReal;
            lineaDiferencia = efectivoMontoTotal - efectivoMontoReal;
            lineaObservaciones = data.observaciones;
            break;
          case "APP":
            appMontoTotal = data.montoTotal;
            appMontoReal = data.montoReal;
            appDiferencia = efectivoMontoTotal - efectivoMontoReal;
            appObservaciones = data.observaciones;
            break;
          default:
            break;
        }
      });

      this.setState(state => ({
        ...state,
        efectivo: {
          montoTotal: efectivoMontoTotal,
          montoReal: efectivoMontoReal,
          diferencia: efectivoDiferencia,
          observaciones: efectivoObservaciones
        },
        cheque: {
          montoTotal: chequeMontoTotal,
          montoReal: chequeMontoReal,
          diferencia: chequeDiferencia,
          observaciones: chequeObservaciones
        },
        tdc: {
          montoTotal: tdcMontoTotal,
          montoReal: tdcMontoReal,
          diferencia: tdcDiferencia,
          observaciones: tdcObservaciones
        },
        tdd: {
          montoTotal: tddMontoTotal,
          montoReal: tddMontoReal,
          diferencia: tddDiferencia,
          observaciones: tddObservaciones
        },
        amex: {
          montoTotal: amexMontoTotal,
          montoReal: amexMontoReal,
          diferencia: amexDiferencia,
          observaciones: amexObservaciones
        },
        transferencia: {
          montoTotal: transferenciaMontoTotal,
          montoReal: transferenciaMontoReal,
          diferencia: transferenciaDiferencia,
          observaciones: transferenciaObservaciones
        },
        definir99: {
          montoTotal: definir99MontoTotal,
          montoReal: definir99MontoReal,
          diferencia: definir99Diferencia,
          observaciones: definir99Observaciones
        },
        linea: {
          montoTotal: lineaMontoTotal,
          montoReal: lineaMontoReal,
          diferencia: lineaDiferencia,
          observaciones: lineaObservaciones
        },
        app: {
          montoTotal: appMontoTotal,
          montoReal: appMontoReal,
          diferencia: appDiferencia,
          observaciones: appObservaciones
        },
        load: true
      }));
    }
  }

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const filters = {
          ...state.filters,
          [fieldName]: date
        };
        return { ...state, error: {}, filters };
      });
    };
  };

  onChangeFieldsEfectivo = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const efectivo = {
          ...state.efectivo,
          [fieldName]: value
        };
        return { ...state, error: {}, efectivo };
      });
    };
  };

  onChangeFieldsCheque = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const cheque = {
          ...state.cheque,
          [fieldName]: value
        };
        return { ...state, error: {}, cheque };
      });
    };
  };

  onChangeFieldsTdc = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const tdc = {
          ...state.tdc,
          [fieldName]: value
        };
        return { ...state, error: {}, tdc };
      });
    };
  };

  onChangeFieldsTdd = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const tdd = {
          ...state.tdd,
          [fieldName]: value
        };
        return { ...state, error: {}, tdd };
      });
    };
  };

  onChangeFieldsAmex = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const amex = {
          ...state.amex,
          [fieldName]: value
        };
        return { ...state, error: {}, amex };
      });
    };
  };

  onChangeFieldsTransferencia = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const transferencia = {
          ...state.transferencia,
          [fieldName]: value
        };
        return { ...state, error: {}, transferencia };
      });
    };
  };

  onChangeFieldsDefinir99 = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const definir99 = {
          ...state.definir99,
          [fieldName]: value
        };
        return { ...state, error: {}, definir99 };
      });
    };
  };

  onChangeFieldsLinea = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const linea = {
          ...state.linea,
          [fieldName]: value
        };
        return { ...state, error: {}, linea };
      });
    };
  };

  onChangeFieldsApp = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const app = {
          ...state.app,
          [fieldName]: value
        };
        return { ...state, error: {}, app };
      });
    };
  };

  onChangeFields = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };
        return { ...state, error: {}, fields };
      });
    };
  };

  search = e => {
    e.preventDefault();
    const { idClub, fechaInicio } = this.state.filters;
    this.props.getShiftDelivery(idClub, fechaInicio);
    this.setState({ load: false });
  };

  generarPDF = e => {
    e.preventDefault();
    const { idClub, fechaInicio } = this.state.filters;
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const day = new Date().getDate();
    const hours = new Date().getHours();
    const minutes = new Date().getMinutes();
    const seconds = new Date().getSeconds();

    const {
      efectivo,
      cheque,
      tdc,
      tdd,
      amex,
      transferencia,
      definir99,
      linea,
      app
    } = this.state;

    this.props.pdfShiftDelivery(
      {
        clubId: 1,
        clubNombre: "Valle",
        detalles: [
          {
            formaDePago: "EFECTIVO",
            montoTotal: efectivo.montoTotal,
            montoReal: efectivo.montoReal,
            diferencia: efectivo.diferencia,
            observaciones: efectivo.observaciones
          },
          {
            formaDePago: "CHEQUE",
            montoTotal: cheque.montoTotal,
            montoReal: cheque.montoReal,
            diferencia: cheque.diferencia,
            observaciones: cheque.observaciones
          },
          {
            formaDePago: "TDC",
            montoTotal: tdc.montoTotal,
            montoReal: tdc.montoReal,
            diferencia: tdc.diferencia,
            observaciones: tdc.observaciones
          },
          {
            formaDePago: "TDD",
            montoTotal: tdd.montoTotal,
            montoReal: tdd.montoReal,
            diferencia: tdd.diferencia,
            observaciones: tdd.observaciones
          },
          {
            formaDePago: "AMEX",
            montoTotal: amex.montoTotal,
            montoReal: amex.montoReal,
            diferencia: amex.diferencia,
            observaciones: amex.observaciones
          },
          {
            formaDePago: "TRANSFERENCIA",
            montoTotal: transferencia.montoTotal,
            montoReal: transferencia.montoReal,
            diferencia: transferencia.diferencia,
            observaciones: transferencia.observaciones
          },
          {
            formaDePago: "POR_DEFINIR_99",
            montoTotal: definir99.montoTotal,
            montoReal: definir99.montoReal,
            diferencia: definir99.diferencia,
            observaciones: definir99.observaciones
          },
          {
            formaDePago: "PAGO_EN_LINEA",
            montoTotal: linea.montoTotal,
            montoReal: linea.montoReal,
            diferencia: linea.diferencia,
            observaciones: linea.observaciones
          },
          {
            formaDePago: "APP",
            montoTotal: app.montoTotal,
            montoReal: app.montoReal,
            diferencia: app.diferencia,
            observaciones: app.observaciones
          }
        ],
        observaciones: this.state.fields.observaciones,
        fechaGeneracion: `${year}-${month >= 10 ? month : "0" + month}-${
          day >= 10 ? day : "0" + day
        }T${hours >= 10 ? hours : "0" + hours}:${
          minutes >= 10 ? minutes : "0" + minutes
        }:${seconds >= 10 ? seconds : "0" + seconds}`
      },
      idClub,
      fechaInicio
    );
  };

  render() {
    const { idClub } = this.state.filters;
    const { clubs } = this.props;

    return (
      <Container className="p-0">
        <div className="row mt-3 mb-3">
          <div className="col">
            <p className={`${s.primaryTitle} w-100 m-0`}>
              Búsqueda de entrega de turno
            </p>
          </div>
        </div>
        <Row>
          <Col xs={12} md={4} lg={4}>
            <BaseInput
              label="Club"
              name="club"
              id="club"
              type="select"
              placeholder="Seleccionar club"
              value={idClub}
              onChange={this.onChangeFilter("idClub")}
              size={"sm"}
              options={[{ value: "", label: "Seleccionar club" }].concat(
                clubs
                  ? clubs.map(option => ({
                      value: option.clubId,
                      label: option.nombre
                    }))
                  : []
              )}
            />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <DatePicker
              label="Fecha"
              name={START_DATE_FIELDNAME}
              id={START_DATE_FIELDNAME}
              selected={this.state.filters[START_DATE_FIELDNAME]}
              onChange={this.onChangeDate(START_DATE_FIELDNAME)}
            />
          </Col>
          <Col xs={12} md={4} lg={4} className="mt-4">
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.search}
            >
              Buscar
            </Button>
          </Col>
        </Row>
        {this.props.reportShiftDelivery !== null ? (
          <React.Fragment>
            <div className="row mt-3 mb-3">
              <div className="col">
                <p className={`${s.primaryTitle} w-100 m-0`}>Reporte</p>
              </div>
            </div>
            <Row>
              <Col xs={12} md={2} lg={2} className={s.cabezal}>
                Club
              </Col>
              <Col xs={12} md={2} lg={2} className={s.cabezal}>
                Forma de pago
              </Col>
              <Col xs={12} md={2} lg={2} className={s.cabezal}>
                Total
              </Col>
              <Col xs={12} md={2} lg={2} className={s.cabezal}>
                Real
              </Col>
              <Col xs={12} md={2} lg={2} className={s.cabezal}>
                Diferencia
              </Col>
              <Col xs={12} md={2} lg={2} className={s.cabezal}>
                Observaciones
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                {this.props.reportShiftDelivery.clubNombre}
              </Col>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                Efectivo
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="efectivoMontoTotal"
                  type="number"
                  id="efectivoMontoTotal"
                  disabled={true}
                  value={this.state.efectivo.montoTotal}
                  onChange={this.onChangeFieldsEfectivo("montoTotal")}
                />
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="efectivoMontoReal"
                  type="number"
                  id="efectivoMontoReal"
                  disabled={false}
                  value={this.state.efectivo.montoReal}
                  onChange={this.onChangeFieldsEfectivo("montoReal")}
                />
              </Col>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                <MexicanCurrency quantity={this.state.efectivo.diferencia} />
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="efectivoObservaciones"
                  type="text"
                  id="efectivoObservaciones"
                  disabled={false}
                  value={this.state.efectivo.observaciones}
                  onChange={this.onChangeFieldsEfectivo("observaciones")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                {this.props.reportShiftDelivery.clubNombre}
              </Col>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                Cheque
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="chequeMontoTotal"
                  type="number"
                  id="chequeMontoTotal"
                  disabled={true}
                  value={this.state.cheque.montoTotal}
                  onChange={this.onChangeFieldsCheque("montoTotal")}
                />
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="chequeMontoReal"
                  type="number"
                  id="chequeMontoReal"
                  disabled={false}
                  value={this.state.cheque.montoReal}
                  onChange={this.onChangeFieldsCheque("montoReal")}
                />
              </Col>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                <MexicanCurrency quantity={this.state.cheque.diferencia} />
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="chequeObservaciones"
                  type="text"
                  id="chequeObservaciones"
                  disabled={false}
                  value={this.state.cheque.observaciones}
                  onChange={this.onChangeFieldsCheque("observaciones")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                {this.props.reportShiftDelivery.clubNombre}
              </Col>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                Tarjeta de crédito
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="tdcMontoTotal"
                  type="number"
                  id="tdcMontoTotal"
                  disabled={true}
                  value={this.state.tdc.montoTotal}
                  onChange={this.onChangeFieldsTdc("montoTotal")}
                />
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="tdcMontoReal"
                  type="number"
                  id="tdcMontoReal"
                  disabled={false}
                  value={this.state.tdc.montoReal}
                  onChange={this.onChangeFieldsTdc("montoReal")}
                />
              </Col>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                <MexicanCurrency quantity={this.state.tdc.diferencia} />
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="tdcObservaciones"
                  type="text"
                  id="tdcObservaciones"
                  disabled={false}
                  value={this.state.tdc.observaciones}
                  onChange={this.onChangeFieldsTdc("observaciones")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                {this.props.reportShiftDelivery.clubNombre}
              </Col>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                Tarjeta de débito
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="tddMontoTotal"
                  type="number"
                  id="tddMontoTotal"
                  disabled={true}
                  value={this.state.tdd.montoTotal}
                  onChange={this.onChangeFieldsTdd("montoTotal")}
                />
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="tddMontoReal"
                  type="number"
                  id="tddMontoReal"
                  disabled={false}
                  value={this.state.tdd.montoReal}
                  onChange={this.onChangeFieldsTdd("montoReal")}
                />
              </Col>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                <MexicanCurrency quantity={this.state.tdd.diferencia} />
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="tddObservaciones"
                  type="text"
                  id="tddObservaciones"
                  disabled={false}
                  value={this.state.tdd.observaciones}
                  onChange={this.onChangeFieldsTdd("observaciones")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                {this.props.reportShiftDelivery.clubNombre}
              </Col>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                American Express
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="amexMontoTotal"
                  type="number"
                  id="amexMontoTotal"
                  disabled={true}
                  value={this.state.amex.montoTotal}
                  onChange={this.onChangeFieldsAmex("montoTotal")}
                />
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="amexMontoReal"
                  type="number"
                  id="amexMontoReal"
                  disabled={false}
                  value={this.state.amex.montoReal}
                  onChange={this.onChangeFieldsAmex("montoReal")}
                />
              </Col>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                <MexicanCurrency quantity={this.state.amex.diferencia} />
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="amexObservaciones"
                  type="text"
                  id="amexObservaciones"
                  disabled={false}
                  value={this.state.amex.observaciones}
                  onChange={this.onChangeFieldsAmex("observaciones")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                {this.props.reportShiftDelivery.clubNombre}
              </Col>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                Transferencia
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="transferenciaMontoTotal"
                  type="number"
                  id="transferenciaMontoTotal"
                  disabled={true}
                  value={this.state.transferencia.montoTotal}
                  onChange={this.onChangeFieldsTransferencia("montoTotal")}
                />
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="transferenciaMontoReal"
                  type="number"
                  id="transferenciaMontoReal"
                  disabled={false}
                  value={this.state.transferencia.montoReal}
                  onChange={this.onChangeFieldsTransferencia("montoReal")}
                />
              </Col>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                <MexicanCurrency
                  quantity={this.state.transferencia.diferencia}
                />
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="transferenciaObservaciones"
                  type="text"
                  id="transferenciaObservaciones"
                  disabled={false}
                  value={this.state.transferencia.observaciones}
                  onChange={this.onChangeFieldsTransferencia("observaciones")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                {this.props.reportShiftDelivery.clubNombre}
              </Col>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                Por definir 99
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="definir99MontoTotal"
                  type="number"
                  id="definir99MontoTotal"
                  disabled={true}
                  value={this.state.definir99.montoTotal}
                  onChange={this.onChangeFieldsDefinir99("montoTotal")}
                />
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="definir99MontoReal"
                  type="number"
                  id="definir99MontoReal"
                  disabled={false}
                  value={this.state.definir99.montoReal}
                  onChange={this.onChangeFieldsDefinir99("montoReal")}
                />
              </Col>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                <MexicanCurrency quantity={this.state.definir99.diferencia} />
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="definir99Observaciones"
                  type="text"
                  id="definir99Observaciones"
                  disabled={false}
                  value={this.state.definir99.observaciones}
                  onChange={this.onChangeFieldsDefinir99("observaciones")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                {this.props.reportShiftDelivery.clubNombre}
              </Col>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                Pago en línea
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="lineaMontoTotal"
                  type="number"
                  id="lineaMontoTotal"
                  disabled={true}
                  value={this.state.linea.montoTotal}
                  onChange={this.onChangeFieldsLinea("montoTotal")}
                />
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="lineaMontoReal"
                  type="number"
                  id="lineaMontoReal"
                  disabled={false}
                  value={this.state.linea.montoReal}
                  onChange={this.onChangeFieldsLinea("montoReal")}
                />
              </Col>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                <MexicanCurrency quantity={this.state.linea.diferencia} />
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="lineaObservaciones"
                  type="text"
                  id="lineaObservaciones"
                  disabled={false}
                  value={this.state.linea.observaciones}
                  onChange={this.onChangeFieldsLinea("observaciones")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                {this.props.reportShiftDelivery.clubNombre}
              </Col>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                APP
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="appMontoTotal"
                  type="number"
                  id="appMontoTotal"
                  disabled={true}
                  value={this.state.app.montoTotal}
                  onChange={this.onChangeFieldsApp("montoTotal")}
                />
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="appMontoReal"
                  type="number"
                  id="appMontoReal"
                  disabled={false}
                  value={this.state.app.montoReal}
                  onChange={this.onChangeFieldsApp("montoReal")}
                />
              </Col>
              <Col xs={12} md={2} lg={2} className={s.parametro}>
                <MexicanCurrency quantity={this.state.app.diferencia} />
              </Col>
              <Col xs={12} md={2} lg={2}>
                <BaseInput
                  name="appObservaciones"
                  type="text"
                  id="appObservaciones"
                  disabled={false}
                  value={this.state.app.observaciones}
                  onChange={this.onChangeFieldsApp("observaciones")}
                />
              </Col>
            </Row>
            <Row className={s.rowDiferent}>
              <Col xs={12} md={4} lg={4} className={s.parametro}>
                Observaciones
              </Col>
              <Col xs={12} md={8} lg={8}>
                <BaseInput
                  name="observaciones"
                  type="text"
                  id="observaciones"
                  disabled={false}
                  value={this.state.fields.observaciones}
                  onChange={this.onChangeFields("observaciones")}
                />
              </Col>
            </Row>
            <Row className={s.rowDiferent}>
              <Col className="mt-4">
                <Button
                  size="sm"
                  className={`float-right btn-block`}
                  onClick={this.generarPDF}
                >
                  Generar PDF
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          ""
        )}
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ShiftDeliveryReport));
