import React from "react";
import ReactTable from "react-table";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import DatePicker from "../common/DatePicker";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Col, Button, Row } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import SelectFilter from "../common/SelectFilter";
import s from "./styles.scss";
import IAction from "../../../types/IAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";

interface Props {
  programs: Node[];
  sportProgramsProducts: object[];
  clubs: Club[];
  getPrograms: (fields: object) => IAction;
  sellers: object[];
  classificationByLevel: object[];
  getSportsProductsFiltered: (args: object) => any;
  selectProgram: (data: object) => void;
  selectedProgram: object;
  sportsProgramsInstances: object[];
  downloadSportsProgramCommissions: (args: object) => IAction;
}
interface State {
  fields: object;
  instructor: object;
}

interface Club {
  clubId: number;
  nombre: string;
}

interface Node {
  id: number;
  name: string;
}

const transformNodeList = (list: any, text: string, idName?: string) => {
  return [
    {
      value: 0,
      label: text
    }
  ].concat(
    list.map(option => ({
      value: idName ? option[idName] : option.id,
      label: option.nombre
    }))
  );
};

const START_DATE_FIELDNAME = "fechaInicio";
const END_DATE_FIELDNAME = "fechaFin";

class SportsProgramSearchReport extends React.Component<Props, State> {
  state = {
    fields: {
      programaId: null,
      clubId: null,
      instructorId: null,
      fechaInicio: null,
      fechaFin: null
    },
    instructor: null
  };

  handleSelection = e => {
    e.preventDefault();
    const newFields = { ...this.state.fields, [e.target.name]: e.target.value };
    this.setState({ fields: newFields });
  };

  onClickDownloadCsvFile = () => {
    this.props.downloadSportsProgramCommissions(this.state.fields);
  };

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: date
        };
        return { ...state, error: {}, fields };
      });
    };
  };

  handleSelect = e =>
    this.setState({
      fields: {
        ...this.state.fields,
        instructorId: e.value
      },
      instructor: e
    });

  search = () => {
    this.props.getPrograms({
      ...this.state.fields,
      estatus: "CERRADO"
    });
  };

  getSportsProgramsInstancesSource = () => {
    return this.props.sportsProgramsInstances || [];
  };

  columns = () => [
    { Header: "Programa", accessor: "nombre" },
    { Header: "Club", accessor: "club" },
    { Header: "Instructor", accessor: "instructor" },
    { Header: "Fecha Inicio", accessor: "fechaInicio" },
    { Header: "Fecha Final", accessor: "fechaFin" }
  ];

  render() {
    let columns = [
      {
        Header: () => <p className="font-weight-bold">Programa</p>,
        id: 0,
        width: 300,
        accessor: "programa",
        Cell: row => {
          return <p>{row.original.nombrePrograma}</p>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Club</p>,
        id: 1,
        width: 150,
        accessor: "club",
        Cell: row => {
          return <p>{row.original.clubNombre}</p>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Instructor</p>,
        id: 2,
        width: 250,
        accessor: "instructor",
        Cell: row => {
          return <p>{row.original.instructorNombre}</p>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Fecha inicio</p>,
        id: 3,
        width: 100,
        accessor: "fechaInicio",
        Cell: row => {
          return <p>{row.original.fechaInicio}</p>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Fecha fin</p>,
        id: 4,
        width: 100,
        accessor: "fechaFin",
        Cell: row => {
          return <p>{row.original.fechaFin}</p>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Comision acumulada</p>,
        id: 5,
        width: 150,
        accessor: "comision",
        Cell: row => {
          return <p>{row.original.comisionAcumulada}</p>;
        }
      },
      {
        Header: () => {
          return <p className="text-center"></p>;
        },
        id: -300,
        width: 80,
        accessor: "actions",
        Cell: row => {
          return (
            <div>
              <Button
                size="sm"
                onClick={() => {
                  this.props.selectProgram(row.original);
                }}
              >
                Mostrar
              </Button>
            </div>
          );
        }
      }
    ];

    return (
      <div>
        <Row>
          <Col xs={12} md={4} lg={4}>
            <BaseInput
              label={"Programa deportivo"}
              name="programaId"
              type="select"
              id="programaId"
              placeholder="TIPO DE PRODUCTO"
              value={this.state.fields.programaId}
              options={
                this.props.sportProgramsProducts &&
                transformNodeList(
                  this.props.sportProgramsProducts.map(e => ({
                    ...e
                  })),
                  "Seleccione un programa deportivo"
                )
              }
              onChange={this.handleSelection}
            />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <BaseInput
              label="Club"
              name="clubId"
              type="select"
              id="clubId"
              placeholder="Club"
              value={this.state.fields.clubId}
              options={
                this.props.clubs &&
                transformNodeList(
                  this.props.clubs,
                  "Seleccione un club",
                  "clubId"
                )
              }
              onChange={this.handleSelection}
            />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <SelectFilter
              label="Selecciona instructor"
              name="instructorId"
              type="select"
              id="instructorId"
              placeholder="Seleccione un instructor"
              value={this.state.instructor}
              isMulti={false}
              options={
                this.props.sellers
                  ? this.props.sellers.map(item => ({
                      label: `${name} ${item.firstSurname} ${item.secondSurname}`,
                      value: item.userId
                    }))
                  : null
              }
              onChange={this.handleSelect}
              loading={false}
              isAsync={false}
            />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <DatePicker
              label="Fecha Inicial"
              name={START_DATE_FIELDNAME}
              id={START_DATE_FIELDNAME}
              selected={this.state.fields[START_DATE_FIELDNAME]}
              onChange={this.onChangeDate(START_DATE_FIELDNAME)}
            />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <DatePicker
              label="Fecha Final"
              name={END_DATE_FIELDNAME}
              id={END_DATE_FIELDNAME}
              selected={this.state.fields[END_DATE_FIELDNAME]}
              onChange={this.onChangeDate(END_DATE_FIELDNAME)}
            />
          </Col>
          <Col xs={12} md={4} lg={4} className="mt-4">
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.search}
              disabled={
                !this.state.fields.programaId ||
                this.state.fields.programaId <= 0
              }
            >
              Buscar
            </Button>
          </Col>
        </Row>
        {this.state.fields.programaId !== null &&
        this.props.sportsProgramsInstances ? (
          <Row className={"mt-3"}>
            <Col sm="6"></Col>
            <Col sm="6" className="text-right">
              <span>Exportar a CSV: </span>
              <Button
                id="btnReportExportCsv"
                color="link"
                onClick={this.onClickDownloadCsvFile}
              >
                <FontAwesomeIcon icon={faFileExport} color="black" />
              </Button>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <ReactTable
          className="mt-3 -striped -highlight"
          data={this.getSportsProgramsInstancesSource()}
          columns={columns}
          sortable={false}
          resizable={false}
          pageSize={
            !!this.props.sportsProgramsInstances &&
            !!this.props.sportsProgramsInstances.length
              ? this.props.sportsProgramsInstances.length + 1
              : 5
          }
          showPagination={false}
        />
      </div>
    );
  }
}

export default withRouter(withStyles(s)(SportsProgramSearchReport));
