import React from "react";
import moment from "moment";
import { Moment } from "moment";
import { DateItem, Week } from "./types";
import { WeekComponent } from "./WeekComponent";
import { MonthHeaderComponent } from "./MonthHeaderComponent";
import { MonthWeeksComponent } from "./MonthWeeksComponent";

interface Props {
  id: String;
  month: number;
  dates: DateItem[];
  soportaAlterna: boolean;
}

interface State {}

/**
 * Muestra un mes con su encabezado y semanas
 */
export class MonthComponent extends React.Component<Props, State> {
  render() {
    return (
      <div className="sw-calendar month container">
        <MonthHeaderComponent month={this.props.month} />
        <MonthWeeksComponent
          id={this.props.id}
          dates={this.props.dates}
          soportaAlterna={this.props.soportaAlterna}
        />
      </div>
    );
  }
}
