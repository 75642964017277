import { RouteComponentProps, withRouter } from "react-router-dom";
import React from "react";
import Reform from "@franleplant/reform";
import { FormErrors } from "@franleplant/reform/types";
import { Row, Col, Button, Container } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./styles.scss";

// TODO: REDUX
import axios from "axios";
import constants from "../../../utils/constants";
import { successNotification } from "../../../utils/notifications";
import {
  errorNotification,
  infoNotification
} from "../../../utils/notifications";
import { delay } from "lodash";

// Interfaz para validación de formularios con Reform
interface Fields {
  datoUsuario: string;
}

// Propiedades y estado del componente
interface Props extends RouteComponentProps {
  logoutUser: () => any;
  restartApp: () => any;
  user: any;
}

interface State {
  // Propiedades requeridas por Reform, para validación de formularios
  fields: Fields;
  errors: FormErrors;

  // Propiedades requeridas para manejar la forma en que se muestran los errores
  datoUsuarioDirty: boolean;
  loading: boolean;
}

/**
 * Componente que permite la actualización de la contraseña
 */
class RecoveryRequest extends React.Component<Props, State> {
  /* Estado inicial del formulario */
  state = {
    fields: {
      datoUsuario: ""
    },
    errors: {},
    username: false,
    datoUsuarioDirty: false,
    loading: false
  };

  /* Propiedades para la validación de formularios */
  re = Reform.reactMixins.objectMixin(this);

  validationRules = {
    datoUsuario: {
      datoUsuarioRequired: value => !(this.props.user || value)
    }
  };

  validationMessages = {
    datoUsuarioRequired: () => "Ingrese su usuario o correo",
    default: () => "Valor requerido o no válido"
  };

  /* Métodos de interacción con el formulario */

  // Actualización de los campos de texto
  onChangeTextInput = (targetValue, fieldName) => {
    const value = targetValue || "";

    // Se valida todo el formulario porque los campos tienen dependencias
    this.setState(
      {
        fields: {
          ...this.state.fields,
          [fieldName]: value
        },
        [fieldName + "Dirty"]: true
      } as any,
      this.re.validateFormFromState
    );
  };

  // Valida el estado de un campo y muestra solo un error
  onFieldValidation = fieldName => {
    if (!this.state[fieldName + "Dirty"]) {
      return [];
    }

    const errors = this.re.mapFieldErrors(fieldName) || [];
    return errors.length > 1 ? [errors[0]] : errors;
  };

  logout = () => {
    this.props
      .logoutUser()
      .then(() => {
        this.props.restartApp();
      })
      .catch(e => console.error(e));
  };

  // Actualización de la contraseña en el servidor
  submit = async evt => {
    evt.preventDefault();

    this.setState({
      datoUsuarioDirty: true
    });

    if (!this.re.validateFormFromState()) {
      errorNotification(
        "No ha ingresado la información requerida, revise los datos por favor."
      );
      return;
    }

    this.setState({
      loading: true
    });

    try {
      infoNotification("Recuperando contraseña...");
      const recoveryRequest = {
        datoUsuario: this.state.fields.datoUsuario
      };

      const response = await axios.post(
        `${constants.BASE_URL}/api/auth/recovery-request`,
        recoveryRequest,
        {
          withCredentials: true
        }
      );
      successNotification(response.data.message);

      this.setState({
        fields: {
          datoUsuario: ""
        }
      });

      delay(this.logout, 2500);
    } catch (error) {
      const hasData = error && error.response && error.response.data;
      const data = hasData ? error.response.data : {};

      const message =
        data.message || "Error desconocido, intente de nuevo más tarde";
      errorNotification(message);
    }

    this.setState({
      loading: false
    });
  };

  render() {
    return (
      <Container>
        <div className="alert alert-info mt-3" role="alert">
          Ingrese su nombre de usuario o correo
        </div>
        <Row>
          <Col md={4}>&nbsp;</Col>
          <Col md={4}>
            <BaseInput
              label={"Usuario o correo"}
              name="datoUsuario"
              type="text"
              id="datoUsuario"
              placeholder="Nombre de usuario o correo"
              value={this.state.fields.datoUsuario}
              onChange={evt =>
                this.onChangeTextInput(evt.target.value, "datoUsuario")
              }
              errors={this.onFieldValidation("datoUsuario")}
            />
          </Col>
        </Row>
        <Row>
          <Col md={4}>&nbsp;</Col>
          <Col md={4}>
            <Button
              className={s.buttonMarginTop + " primaryButton"}
              type="button"
              onClick={this.submit}
              size="sm"
              block
              disabled={this.state.loading}
            >
              {!this.state.loading ? "Recuperar contraseña" : "Espere..."}
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(RecoveryRequest));
