import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Promotions from "../../components/promotions";
import * as promotionsActions from "../../actions/promotions";
import { QueryResponse } from "../../../types/QueryResponse";

interface Props {
  promotions: QueryResponse<Promotion>;
  getPromotions: (args?: object) => any;
  searching: boolean;
  addPromotion: (promotion?) => any;
  history: any;
  match: any;
}

interface Promotion {
  id: string;
  nombre: string;
}

class PromotionsPage extends React.Component<Props> {
  onFetchData = ({ pageSize: size, page, nombre, tipoUso, soloVigentes }) => {
    this.props.getPromotions({
      size,
      page,
      nombre,
      tipoUso,
      soloVigentes
    });
  };

  goTo = destiny => {
    this.props.history.push(destiny);
  };

  render() {
    return (
      <main>
        <Helmet title="Promotions" />
        <Layout>
          <Promotions
            promotions={this.props.promotions}
            fetchData={this.onFetchData}
            searching={this.props.searching}
            onClickNewPromotion={(promotion?) =>
              this.goTo(
                `/promociones/datos-basicos/${promotion ? promotion.id : ""}`
              )
            }
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getPromotions: (args?: object) => {
    dispatch(promotionsActions.getPromotions(args));
  }
});

const mapStateToProps = state => {
  const { promotions } = state;
  return {
    promotions: promotions.promotions || { data: [], totalPages: 0 },
    searching: promotions.gettingPromotions
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionsPage);
