import React from "react";
import { connect } from "react-redux";
import UserPermits from "../../components/userPermits";
import * as userActions from "../../actions/users";
import * as sessionActions from "../../actions/session";
import { withRouter } from "react-router-dom";
import { Container } from "reactstrap";

interface Props {
  getPermissionsTree: () => any;
  permissionsTree: object;
  updatePermits: (personaId: string, permissions: number[]) => any;
  getPermitsById: (personaId: string) => any;
  userPermitsSaved: object;
  validPermitsId: boolean;
  permissions: number[];
  onClickSave: (f: any) => any;
  onSuccessfulSave: () => any;
  successfulSave: boolean;
  match: any;
}

class UserPermitsPage extends React.Component<Props> {
  componentDidMount() {
    this.props.getPermissionsTree();
    const { personaId } = this.props.match.params;
    if (personaId) this.props.getPermitsById(personaId);
  }

  shouldComponentUpdate(nextProps) {
    if (
      this.props.userPermitsSaved !== nextProps.userPermitsSaved &&
      nextProps.userPermitsSaved
    ) {
      this.props.onSuccessfulSave();
      return false;
    }

    return true;
  }

  render() {
    const { permissionsTree, permissions } = this.props;
    const { personaId } = this.props.match.params;
    return (
      <Container className="w-100">
        {permissionsTree &&
        (!personaId || (personaId && permissions !== null)) ? (
          <UserPermits
            permissionsTree={this.props.permissionsTree}
            updatePermits={this.props.updatePermits}
            onClickSave={this.props.onClickSave}
            permissions={this.props.permissions}
          />
        ) : null}
      </Container>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getPermissionsTree: () => {
    dispatch(sessionActions.getPermissionsTree());
  },
  updatePermits: (personaId: string, permissions: number[]) => {
    dispatch(userActions.updatePermits(personaId, permissions));
  },
  getPermitsById: (personaId: string) => {
    dispatch(userActions.getPermitsById(personaId));
  }
});

const mapStateToProps = state => {
  const { user, auth } = state;
  return {
    permissionsTree: user.permissionsTree,
    userPermitsSaved: user.userPermitsSaved,
    permissions: user.permitsUser,
    validPermitsId: auth.validPermitsId
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserPermitsPage)
);
