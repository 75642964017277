import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";
import { QueryArguments } from "../../types-business/QueryArguments";

const baseUrl = `/api`;

export const getUsers = (params?: QueryArguments): IAction => {
  return {
    type: actionTypes.GET_USERS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/users`, {
        withCredentials: true,
        params: { ...params }
      })
    }
  };
};

export const saveBasicUser = (user: object): IAction => {
  return {
    type: actionTypes.SAVE_BASIC_USER,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${baseUrl}/users/basicData`,
        user,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const updateBasicUser = (personaId: string, user: object): IAction => {
  return {
    type: actionTypes.UPDATE_BASIC_USER,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${baseUrl}/users/basicData/${personaId}`,
        user,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getBasicData = (id: string): IAction => {
  return {
    type: actionTypes.GET_BASIC_USER,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/users/basicData/${id}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const clearBasicUser = (): IAction => {
  return {
    type: actionTypes.CLEAR_BASIC_USER,
    payload: null
  };
};

export const getRolesData = (id: string): IAction => {
  return {
    type: actionTypes.GET_ROLES_USER,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/users/roles/${id}`, {
        withCredentials: true
      })
    }
  };
};

export const saveRolesUser = (user: object, id): IAction => {
  return {
    type: actionTypes.SAVE_ROLES_USER,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${baseUrl}/users/roles/${id}`,
        user,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const clearRolesData = (): IAction => {
  return {
    type: actionTypes.CLEAR_ROLES_USER,
    payload: null
  };
};

export const getPermitsById = (roleId: string) => {
  return {
    type: actionTypes.GET_USER_PERMITS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}/api/users/permits/${roleId}`, {
        withCredentials: true
      })
    }
  };
};

export const updatePermits = (
  personaId: string,
  permissions: number[]
): IAction => {
  return {
    type: actionTypes.SAVE_USER_PERMITS,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}/api/users/permits/${personaId}`,
        {
          permissions
        },
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getUserById = (id: string): IAction => {
  return {
    type: actionTypes.GET_USER_BY_ID,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/users/basicData/${id}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getKits = (data: any, clubId: string): IAction => {
  return {
    type: actionTypes.GET_KITS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/users/fitkits/${clubId}`,
        {
          withCredentials: true,
          params: { ...data }
        }
      )
    }
  };
};

export const getResponsibles = (menorId: string): IAction => {
  return {
    type: actionTypes.GET_RESPONSIBLES_KITS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/users/responsible/${menorId}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const assignTag = (data: object): IAction => {
  return {
    type: actionTypes.ASSIGN_TAG,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${baseUrl}/users/asignacion-tag-fitkidz`,
        data,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const deallocationTag = (data: object): IAction => {
  return {
    type: actionTypes.DELLOCATION_TAG,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${baseUrl}/users/desasignacion-tag-fitkidz`,
        data,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getMitTerminalCredentialsByUserIdRequest = (userId: string) => {
  return axios.get(
    `${constants.BASE_URL}/api/auth/mit-terminal-credentials/${userId}`,
    {
      withCredentials: true
    }
  );
};

export const saveMitTerminalCredentialsByUserIdRequest = (
  userId: string,
  mitUser: string,
  mitPassword: string
) => {
  return axios.put(
    `${constants.BASE_URL}/api/auth/mit-terminal-credentials/${userId}`,
    {
      mitUser,
      mitPassword
    },
    {
      withCredentials: true
    }
  );
};
