import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import Layout from "../../components/layout";

import { getClubs } from "../../actions/catalogs";
import { getProducts } from "../../actions/sportsProducts";
import { getEvents, clearEvents } from "../../actions/events";
import { getSportsProductsTypes } from "../../actions/catalogs";
import SoldProducts from "../../components/soldProducts";

import { QueryResponse } from "../../../types/QueryResponse";
import { QueryArguments } from "../../../types-business/QueryArguments";
import CONSTANTS from "../../../utils/constants";

interface Product {
  id: string;
  clasificacionId: string;
  nombre: string;
  estatus: string;
  publicarPrecio: boolean;
  fechaInicioVigencia: string;
  fechaFinVigencia: string;
  descripcion: string;
}

interface Props {
  clubs: object[];
  products: QueryResponse<Product>;
  getClubs: () => any;
  getEvents: (clubId: number, args?: QueryArguments) => any;
  getProducts: (args?: QueryArguments) => any;
  getSportsProductsTypes: () => any;
  match: any;
  searching: boolean;
  addProduct: (product?) => any;
  history: any;
  sportsProductsTypes: object[];
  events: object[];
  clearEvents: () => any;
  userContext: any;
}

interface State {
  products: object;
  filters: { clasificacionId: string };
}

class SportsProductsProducts extends React.Component<Props, State> {
  state = {
    products: {
      content: [],
      totalPages: 0
    },
    filters: {
      clasificacionId: null,
      size: CONSTANTS.DEFAULT_PAGE_SIZE,
      page: 0
    }
  };

  onChangeFilter = (value, name) => {
    const filters = {
      ...this.state.filters,
      [`${name}`]: value ? value : null
    };
    this.setState(state => ({
      ...state,
      filters
    }));
  };

  onFetchData = ({ pageSize: size, page }) => {
    const filters = {
      ...this.state.filters,
      size,
      page
    };
    this.props.getProducts(filters);
    this.setState(state => ({ ...state, ...filters }));
  };

  goTo = destiny => {
    this.props.history.push(destiny);
  };

  componentDidUpdate(prevProps) {
    const { products } = this.props;
    const { products: prevProducts } = prevProps;
    if (products && prevProducts !== products) {
      this.setState({ products, events: { content: this.props.events || [] } });
    }
  }

  componentDidMount() {
    this.props.getClubs();
  }

  componentWillUnmount() {
    this.props.clearEvents();
  }

  render() {
    return (
      <main>
        <Helmet title="Sports Sold Products" />
        <Layout>
          <SoldProducts
            clubs={this.props.clubs}
            events={this.props.events}
            products={this.state.products}
            fetchData={this.onFetchData}
            searching={this.props.searching}
            filters={this.state.filters}
            onChangeFilter={this.onChangeFilter}
            getEvents={this.props.getEvents}
            getProducts={this.props.getProducts}
            sportsProducts={this.props.sportsProductsTypes}
            clearEvents={this.props.clearEvents}
            onClickProduct={producto =>
              this.goTo(
                "/deportiva/ventas/" +
                  (producto
                    ? producto.productoId
                      ? producto.productoId
                      : ""
                    : "") +
                  "/club/" +
                  producto.clubId
              )
            }
            userContext={this.props.userContext}
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = state => {
  const { sportsProducts, catalogs } = state;
  return {
    clubs: catalogs.clubs,
    products: sportsProducts.products,
    searching: sportsProducts.gettingProducts,
    sportsProductsTypes: catalogs.sportsProductsTypes,
    events: sportsProducts.events,
    userContext: state.user.userContext
  };
};

const mapDispatchToProps = {
  getClubs,
  getEvents,
  getProducts,
  getSportsProductsTypes,
  clearEvents
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SportsProductsProducts);
