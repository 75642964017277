import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Container, Row, Col, Button } from "reactstrap";
import { Club } from "../../../types-business/Clubs";
import BaseInput from "../../../toolkit/baseInput";
import Reform from "@franleplant/reform";
import { FormErrors } from "@franleplant/reform/types";
import {
  Criticidad,
  TipoPeticion,
  OrigenPeticion,
  Demand
} from "./types/Demand";
import isNil from "lodash.isnil";
import { ESTATUS_APERTURA } from "../demandList/demandListTypes";
import { getEnumOptions } from "../../../utils/helpers";
import DemandClassificationSelector from "../demandClassificationSelector";

interface Props {
  clubs: Club[];
  getClassificationByLevel: (level: number) => any;
  saveDemand: (demand: object) => any;
  updateDemand: (id: number, demand: object) => any;
  successfullyCreated: boolean;
  match: any;
  demandById: Demand;
  history: any;
  location: any;
}

interface State {
  demandObject: object;
  fields: {
    email: string;
    clubEmisorId: number;
    clubReceptorId: number;
    clubBaseId: number;
    membresiaPublicoId: string;

    tipoPeticion: TipoPeticion;
    classificationId: number;

    origen: OrigenPeticion;
    comentario: string;
    criticidad: Criticidad;
  };
  errors: FormErrors;
}

export const cleanLevelData = data => (data == 0 || isNil(data) ? null : data);

class NewDemand extends React.Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);

  state = {
    demandObject: null,
    fields: {
      email: "",
      clubEmisorId: null,
      clubReceptorId: null,
      clubBaseId: null,
      classificationId: null,
      tipoPeticion: TipoPeticion.QUEJA,
      origen: OrigenPeticion.KIOSCO,
      comentario: "",
      criticidad: Criticidad.MEDIA,
      membresiaPublicoId: ""
    },
    errors: {}
  };

  componentDidMount() {
    const { quejaId } = this.props.match.params;
    const { demandById } = this.props;

    if (quejaId && demandById) {
      this.setFields({
        ...demandById,
        clubEmisorId: demandById.clubEmisorId,
        clubReceptorId: demandById.clubReceptorId,
        nivelClasificacionId:
          demandById.nivel4ClasificacionId ||
          demandById.nivel3ClasificacionId ||
          demandById.nivel2ClasificacionId ||
          demandById.nivel1ClasificacionId,
        classificationId:
          demandById.nivel4ClasificacionId ||
          demandById.nivel3ClasificacionId ||
          demandById.nivel2ClasificacionId ||
          demandById.nivel1ClasificacionId
      });
      this.setState({ demandObject: { ...demandById } });
    } else {
      this.setState({
        demandObject: null,
        fields: {
          email: "",
          clubEmisorId: null,
          clubReceptorId: null,
          clubBaseId: null,
          classificationId: null,
          tipoPeticion: TipoPeticion.QUEJA,
          origen: OrigenPeticion.KIOSCO,
          comentario: "",
          criticidad: Criticidad.MEDIA,
          membresiaPublicoId: ""
        },
        errors: {}
      });
    }
  }

  validationRules = {
    email: { email: true, required: true, maxLength: 255 },
    clubEmisorId: { required: true },
    clubReceptorId: { required: true },
    comentario: { required: true }
  };

  setField = (field, value) => {
    this.setState({
      ...this.state,
      fields: { ...this.state.fields, [field]: value }
    });
  };

  setFields = values => {
    this.setState({
      ...this.state,
      fields: { ...this.state.fields, ...values }
    });
  };

  validationMessages = {
    required: _ => `Obligatorio`,
    default: _ => `Inválido`
  };

  onClickSubmit = () => {
    if (this.re.validateFormFromState()) {
      const fields = { ...this.state.fields };
      const body = {
        ...fields,
        clubEmisorId: fields.clubEmisorId,
        clubReceptorId: fields.clubReceptorId,
        clubBaseId: fields.clubBaseId,
        nivelClasificacionId: fields.classificationId
      };

      const { quejaId } = this.props.match.params;
      if (!quejaId) {
        this.props.saveDemand(body);
      } else {
        this.props.updateDemand(quejaId, body);
      }
    }
  };

  validState = () => {
    const fields = { ...this.state.fields };
    return (
      fields.clubEmisorId &&
      fields.clubReceptorId &&
      fields.comentario &&
      fields.email
    );
  };

  render() {
    return (
      <Container className="mt-3 p-0">
        <Row>
          <Col>
            <DemandClassificationSelector
              level1={(this.state.demandObject || {}).nivel1ClasificacionId}
              level2={(this.state.demandObject || {}).nivel2ClasificacionId}
              level3={(this.state.demandObject || {}).nivel3ClasificacionId}
              level4={(this.state.demandObject || {}).nivel4ClasificacionId}
              type={
                (this.state.demandObject || {}).tipoPeticion ||
                this.state.fields.tipoPeticion
              }
              tag={(this.state.demandObject || {}).id || -1}
              onChangeType={(type: string) => {
                this.setState({
                  fields: {
                    ...this.state.fields,
                    tipoPeticion: type as TipoPeticion,
                    classificationId: null
                  }
                });
              }}
              onChangeClassification={classification => {
                if (!!classification) {
                  this.setState({
                    fields: {
                      ...this.state.fields,
                      criticidad: classification.criticidad,
                      classificationId: classification.id
                    }
                  });
                } else {
                  this.setState({
                    fields: {
                      ...this.state.fields,
                      criticidad: Criticidad.MEDIA,
                      classificationId: null
                    }
                  });
                }
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <BaseInput
              type="select"
              label="Club Emisor"
              name="clubEmisorSelect"
              id="clubEmisorSelect"
              options={[{ value: 0, label: "Seleccione el Club" }].concat(
                this.props.clubs
                  ? this.props.clubs.map(
                      ({ clubId: value, nombre: label }) => ({
                        value,
                        label
                      })
                    )
                  : []
              )}
              value={this.state.fields.clubEmisorId}
              onChange={e => {
                const clubId = e.target.value;
                this.setField("clubEmisorId", clubId);
              }}
              errors={[]}
              disabled={!!this.props.demandById}
            />
          </Col>
          <Col>
            <BaseInput
              type="select"
              label="Club Receptor"
              name="clubReceptorSelect"
              id="clubReceptorSelect"
              options={[{ value: 0, label: "Seleccione el Club" }].concat(
                this.props.clubs
                  ? this.props.clubs.map(
                      ({ clubId: value, nombre: label }) => ({
                        value,
                        label
                      })
                    )
                  : []
              )}
              value={this.state.fields.clubReceptorId}
              onChange={e => {
                const clubId = e.target.value;
                this.setField("clubReceptorId", clubId);
              }}
              errors={[]}
              disabled={!!this.props.demandById}
            />
          </Col>
          <Col>
            <BaseInput
              label="Email"
              name="emailPeticion"
              type="text"
              id="emailPeticion"
              placeholder="Email"
              value={this.state.fields.email}
              onChange={e => {
                const email = e.target.value;
                this.setField("email", email);
              }}
              errors={this.re.mapFieldErrors("email")}
              disabled={!!this.props.demandById}
            />
          </Col>
          <Col>
            <BaseInput
              type="select"
              label="Club Base"
              name="clubBaseSelect"
              id="clubBaseSelect"
              options={[{ value: 0, label: "Seleccione el Club" }].concat(
                this.props.clubs
                  ? this.props.clubs.map(
                      ({ clubId: value, nombre: label }) => ({
                        value,
                        label
                      })
                    )
                  : []
              )}
              value={this.state.fields.clubBaseId}
              onChange={e => {
                const clubId = e.target.value;
                this.setField("clubBaseId", clubId);
              }}
              errors={[]}
              disabled={!!this.props.demandById}
            />
          </Col>
          <Col>
            <BaseInput
              label="Id público membresía"
              name="idPublicoMembresia"
              type="text"
              id="idPublicoMembresia"
              placeholder="Id público membresía"
              value={this.state.fields.membresiaPublicoId}
              onChange={e => {
                const membresiaPublicoId = e.target.value;
                this.setField("membresiaPublicoId", membresiaPublicoId);
              }}
              errors={[]}
              disabled={!!this.props.demandById}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <BaseInput
              label="Origen"
              name="origenPeticion"
              type="select"
              options={getEnumOptions(OrigenPeticion)}
              id="origenPeticion"
              placeholder="Tipo Peticion"
              value={this.state.fields.origen}
              onChange={e => {
                const origen = e.target.value;
                this.setField("origen", origen);
              }}
              errors={[]}
              disabled={!!this.props.demandById}
            />
          </Col>
          <Col>
            <BaseInput
              type="select"
              options={getEnumOptions(Criticidad)}
              label="Criticidad"
              name="criticidadPeticion"
              id="criticidadPeticion"
              value={this.state.fields.criticidad}
              onChange={e => {
                const criticidad = e.target.value;
                this.setField("criticidad", criticidad);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col className="form-group">
            <label>Comentarios:</label>
            <textarea
              name="comentarioPeticion"
              rows={6}
              id="comentarioPeticion"
              value={this.state.fields.comentario}
              className="form-control"
              onChange={e => {
                const comentario = e.target.value;
                this.setField("comentario", comentario);
              }}
              disabled={!!this.props.demandById}
              maxLength={32000}
            />
            <div
              style={{ textAlign: "right", fontSize: "14px", color: "#777" }}
            >
              {(this.state.fields.comentario || "").length} de 32000 permitidos.
            </div>
          </Col>
        </Row>
        <Row>
          <Col></Col>
          {this.props.location &&
            this.props.location.state &&
            this.props.location.state.query && (
              <Col xs={2}>
                <Button
                  className={`${s.buttonMarginTop} ${s.primaryButton} float-right`}
                  onClick={() =>
                    this.props.history.push({
                      pathname: `/quejas`,
                      state: { query: this.props.location.state.query }
                    })
                  }
                  block
                  type="button"
                >
                  Regresar
                </Button>
              </Col>
            )}
          <Col xs={2}>
            <Button
              className={`${s.buttonMarginTop} ${s.primaryButton}`}
              onClick={this.onClickSubmit}
              disabled={!this.validState()}
            >
              Guardar Cambios
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(NewDemand));
