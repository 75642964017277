import React from "react";
import IInputBuilder from "../iInputBuilder/IInputBuilder";
import IText from "../iText/IText";
import ITextBuilderProps from "./ITextBuilder.props";
import ITextBuilderState from "./ITextBuilder.state";

export default class ITextBuilder<INP extends string> extends IInputBuilder<
  INP,
  ITextBuilderProps<INP>,
  ITextBuilderState
> {
  render() {
    return <IText {...(this.props as any)} />;
  }
}
