import React from "react";
import { connect } from "react-redux";
import { getSalesTrackingBySeller } from "../../../actions/sales";
import TotalSalesBySeller from "../../components/MonthlySalesTracking/TotalSalesBySeller";
interface Props {
  clubId: string;
  month: string;
  year: string;
  getSalesTrackingBySeller: (params) => any;
  saveCompromisesBySeller: () => any;
  disabled: boolean;
  setHasSellers: (hs: boolean) => any;
}

class SalesTotalsBySellerContainer extends React.Component<Props> {
  getSalesTrackingBySeller = async params => {
    const {
      value: { data: goals }
    } = await this.props.getSalesTrackingBySeller(params);
    return goals;
  };

  render() {
    return (
      <TotalSalesBySeller
        disabled={this.props.disabled}
        clubId={this.props.clubId}
        month={this.props.month}
        year={this.props.year}
        getSalesTrackingBySeller={this.getSalesTrackingBySeller}
        saveCompromisesBySeller={this.props.saveCompromisesBySeller}
        setHasSellers={this.props.setHasSellers}
      />
    );
  }
}

const mapDispatchToProps = {
  getSalesTrackingBySeller
};

const mapStateToProps = () => {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesTotalsBySellerContainer);
