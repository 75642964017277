import React from "react";
import ModalFrame from "../../../toolkit/modalFrame";
import s from "./styles.scss";
import moment from "moment";

import { Container, Row, Col, Label } from "reactstrap";

interface Props {
  gettingSingleCode: boolean;
  dataSingleCode: object;
  isOpenModal: boolean;
  toggleModal: () => any;
}

interface State {}

class GetSingleCodeModal extends React.Component<Props, State> {
  state = {};

  render() {
    return (
      <ModalFrame
        title={"Código de descuento"}
        isOpen={this.props.isOpenModal}
        toggle={() => this.props.toggleModal()}
        size="lg"
      >
        <Container>
          {this.props.gettingSingleCode && (
            <Row>
              <Col sm={"12"}>
                {" "}
                <h5>Obteniendo código</h5>
              </Col>
            </Row>
          )}
          {!this.props.gettingSingleCode && this.props.dataSingleCode && (
            <Row>
              <Col sm={"6"}>
                <Label>Código</Label>
                <br />
                <h5>{this.props.dataSingleCode["codigo"]}</h5>
              </Col>
              <Col sm={"6"}>
                {" "}
                <Label>Vigencia</Label>
                <br />{" "}
                {moment(this.props.dataSingleCode["fechaFinVigencia"]).format(
                  "YYYY-MM-DD"
                )}
              </Col>
            </Row>
          )}
        </Container>
      </ModalFrame>
    );
  }
}

export default GetSingleCodeModal;
