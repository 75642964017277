import React from "react";
import { connect } from "react-redux";
import * as catalogActions from "../../../Home/actions/catalogs";
import { MembershipSelector } from "../../components/MembershipSelector";
import find from "lodash.find";
import { Membership } from "../../../types-business/Products";

interface Props {
  getMemberships: () => any;
  getMembershipsByClub: (clubId: string) => any;
  loading: boolean;
  memberships: Membership[];
  disabled: boolean;
  onChange: (value: Membership) => any;
  value: Membership;
  clubId: string;
}

class MaintenanceSelectorContainer extends React.Component<Props> {
  componentDidMount() {
    this.loadMembership(this.props.clubId);
  }

  componentDidUpdate({ clubId: prevclubId }) {
    const { clubId } = this.props;
    if (prevclubId != clubId) {
      this.loadMembership(clubId);
    }
  }

  loadMembership = clubId => {
    if (!this.props.disabled) {
      if (clubId) {
        this.props.getMembershipsByClub(clubId);
      } else {
        this.props.getMemberships();
      }
    }
  };

  render() {
    return (
      <MembershipSelector
        value={this.props.value}
        memberships={this.props.memberships}
        onChange={value => {
          const membershipObject = find(
            this.props.memberships,
            ({ datosBasicosMembresiaId }) => value == datosBasicosMembresiaId
          );
          this.props.onChange(membershipObject);
        }}
        disabled={this.props.disabled || this.props.loading}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getMemberships: () => {
    dispatch(catalogActions.getMembershipsTypes());
  },
  getMembershipsByClub: (clubId: string) => {
    dispatch(catalogActions.getMembershipsTypesByClub(clubId));
  }
});

const mapStateToProps = state => {
  const {
    catalogs: { membershipsTypes, gettingMembershipsTypes }
  } = state;

  return {
    memberships: membershipsTypes,
    loading: gettingMembershipsTypes
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaintenanceSelectorContainer);
