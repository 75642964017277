import React from "react";
import s from "./cellRender.scss";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
const ULTIMOS_REGISTRADOS = "6";
const AUTORIZADOS = "2";
const NO_AUTORIZADOS = "4";
if (process.env.BROWSER) {
  var ReactDataGrid = require("react-data-grid");
  var Cell = ReactDataGrid.Cell;
}

interface Props {
  rowData: any;
  column: any;
}

class CellRenderer extends React.Component<Props> {
  render() {
    return (
      <Cell
        className={
          this.props.rowData.estatusId == ULTIMOS_REGISTRADOS &&
          this.props.column.key == "precio"
            ? s.cellEdit
            : this.props.rowData.estatusId == AUTORIZADOS &&
              this.props.column.key == "precio"
            ? s.cellAutorized
            : this.props.rowData.estatusId == NO_AUTORIZADOS &&
              this.props.column.key == "precio"
            ? s.cellUnautorized
            : s.cell
        }
        {...this.props}
      />
    );
  }
}

export default withRouter(withStyles(s)(CellRenderer));
