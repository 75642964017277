import React from "react";
import { connect } from "react-redux";
import * as promotionActions from "../../actions/promotions";
import * as productActions from "../../actions/products";
import * as accountActions from "../../actions/profile";
import BasicDataPromotionForm from "../../components/basicDataPromotionForm";

interface Props {
  saveBasicPromotion: (promotion: object) => any;
  updateBasicPromotion: (productId: string, product: object) => any;
  successfulSave: boolean;
  basicPromotionSaved: object;
  getBasicPromotion: (id: string) => any;
  promotion: object;
  onClickSave: (f: any) => any;
  onSuccessfulSave: () => any;
  getMemberships: () => any;
  memberships: object[];
  users: object[];
  getUsers: () => any;
}

class BasicDataPromotionContainer extends React.Component<Props, void> {
  componentDidMount() {
    this.props.getMemberships();
    this.props.getUsers();
  }

  render() {
    return (
      <div className="h-100 w-100 p-0 w-0">
        {this.props.memberships && (
          <BasicDataPromotionForm
            saveBasicPromotion={this.props.saveBasicPromotion}
            promotion={
              window.location.pathname != "/promociones/datos-basicos/"
                ? this.props.promotion
                : null
            }
            updateBasicPromotion={this.props.updateBasicPromotion}
            onClickSave={this.props.onClickSave}
            getBasicPromotion={this.props.getBasicPromotion}
            memberships={this.props.memberships}
            users={this.props.users}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  saveBasicPromotion: (promotion: object) => {
    return dispatch(promotionActions.saveBasicPromotion(promotion));
  },
  getBasicPromotion: (id: string) => {
    dispatch(promotionActions.getBasicData(id));
  },
  updateBasicPromotion: (productId: string, product: object) => {
    dispatch(promotionActions.updateBasicPromotion(productId, product));
  },
  getMemberships: () => {
    dispatch(productActions.getMemberships());
  },
  getUsers: () => {
    dispatch(accountActions.getUsers());
  }
});

const mapStateToProps = state => {
  const { promotions, products, user } = state;
  return {
    basicPromotionSaved: promotions.basicPromotionSaved,
    promotion: promotions.basicPromotion,
    memberships:
      products.memberships && products.memberships.content
        ? products.memberships.content
        : null,
    users: user.usersPage
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasicDataPromotionContainer);
