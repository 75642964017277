import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Companies from "../../components/companies";
import * as companieActions from "../../actions/companies";
import { QueryResponse } from "../../../types/QueryResponse";
import { QueryArguments } from "../../../types-business/QueryArguments";
import CONSTANTS from "../../../utils/constants";

interface Companie {
  id: string;
  nombre: string;
  activo: string;
  estatus: string;
  fechaCreacion: string;
  fechaActualizacion: string;
  riEmpresa: string;
  numeroCentroPagos: string;
}

interface Props {
  companies: QueryResponse<Companie>;
  getCompanies: (args?: QueryArguments) => any;
  deleteCompanie: (companie: Companie) => any;
  searching: boolean;
  updatingCompanie;
  history: any;
}

interface State {
  companies: object;
  filters: {};
}

class CompaniesPage extends React.Component<Props, State> {
  state = {
    companies: {
      content: [],
      totalPages: 0
    },
    filters: {
      size: CONSTANTS.DEFAULT_PAGE_SIZE,
      page: 0
    }
  };

  onChangeFilter = (value, name) => {
    const filters = {
      ...this.state.filters,
      [`${name}`]: value ? value : null
    };
    this.setState(state => ({ ...state, filters }));
  };

  onFetchData = ({ pageSize: size, page }) => {
    const filters = { ...this.state.filters, size, page };
    this.props.getCompanies(filters);
    this.setState(state => ({ ...state, ...filters }));
  };

  goTo = (destiny: string, select: any) => {
    if (select) {
      this.props.history.push(destiny + select.id);
    } else {
      this.props.history.push(destiny);
    }
  };

  handleDeleteProspect = companie => {
    companie.activo = !companie.activo;
    this.props.deleteCompanie(companie).then(() => {
      this.setState({
        ...this.state,
        companies: { content: [], totalPages: 0 }
      });
      this.props.getCompanies();
    });
  };

  componentDidUpdate(prevProps) {
    const { companies } = this.props;
    const { companies: prevCompanies } = prevProps;
    if (companies && prevCompanies !== companies) {
      companies.content.forEach(
        c => (c.estatus = c.activo ? "Activo" : "Inactivo")
      );
      this.setState({ companies });
    }
  }

  render() {
    return (
      <main>
        <Helmet title="Companies" />
        <Layout>
          <Companies
            companies={this.state.companies}
            fetchData={this.onFetchData}
            searching={this.props.searching}
            filters={this.state.filters}
            onChangeFilter={this.onChangeFilter}
            getCompanies={this.props.getCompanies}
            handleDeleteProspect={this.handleDeleteProspect}
            onClickNewCompanie={(companie?) =>
              this.goTo("/empresa/datos-basicos/", companie)
            }
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getCompanies: (args?: QueryArguments) => {
    return dispatch(companieActions.getCompanies(args));
  },
  deleteCompanie: (companie: Companie) => {
    return dispatch(companieActions.updateCompanie(companie.id, companie));
  }
});

const mapStateToProps = state => {
  const { companies } = state;
  return {
    companies: companies.companies
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompaniesPage);
