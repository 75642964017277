import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Container, Row, Col, Input, Button } from "reactstrap";
import SelectFilter from "../common/SelectFilter";
import ReactTable from "react-table";
import { TIPO_PERSONA } from "../../../types/Memberships";

interface Props {
  match: any;
  memberships: {
    datosBasicosMembresiaId: number;
    nombre: string;
    integrantes: number;
  }[];
  membershipTypesMembers: {
    tipoPersona: TIPO_PERSONA;
    nombre: string;
    maximoIntegrantes: number;
  }[];
  history: any;
  sendMembersTypes: (membresiaId: string, members: object[]) => any;
  getMembershipMembers: (membresiaId: string) => any;
}

interface State {
  membershipSelected: {
    value: number;
    label: string;
    integrantes: number;
  };
  membershipSelectedDropDown: object;
  options: object[];
  members: {
    nombre: string;
    maximoIntegrantes: number;
    tipoPersona: TIPO_PERSONA;
  }[];
}

const emptyRow = tipoPersona => ({
  nombre: "",
  maximoIntegrantes: 1,
  tipoPersona
});

const getEmptyState = () => ({
  membershipSelected: null,
  membershipSelectedDropDown: null,
  options: null,
  members: [emptyRow(TIPO_PERSONA.CLIENTE_TITULAR)]
});

const getOptions = memberships =>
  memberships.map(({ datosBasicosMembresiaId, nombre }) => ({
    label: nombre,
    value: datosBasicosMembresiaId
  }));

class MembershipMembers extends React.Component<Props, State> {
  state = getEmptyState();

  setMemberhip = membresiaId => {
    const index = this.props.memberships.findIndex(
      ({ datosBasicosMembresiaId }) => datosBasicosMembresiaId == membresiaId
    );
    this.props.getMembershipMembers(membresiaId);
    const options = getOptions(this.props.memberships);
    this.setState({
      ...this.state,
      options,
      membershipSelectedDropDown: options[index],
      membershipSelected: {
        value: membresiaId,
        label: this.props.memberships[index].nombre,
        integrantes: this.props.memberships[index].integrantes
      }
    });
  };

  componentDidMount() {
    const { membresiaId } = this.props.match.params;
    this.setMemberhip(membresiaId);
  }

  componentDidUpdate(prevProps) {
    const { membresiaId } = this.props.match.params;
    const { membresiaId: prevMembresiaId } = prevProps.match.params;
    if (membresiaId !== prevMembresiaId) {
      this.setMemberhip(membresiaId);
    }

    const { membershipTypesMembers } = this.props;
    const { membershipTypesMembers: prevMembershipTypesMembers } = prevProps;

    if (!prevMembershipTypesMembers && membershipTypesMembers) {
      const members = membershipTypesMembers;
      if (members.length !== 0) {
        this.setState({ ...this.state, members });
      }
    }
  }

  changeMembership = memberhsip => {
    this.props.history.push(`/membresias/${memberhsip.value}/integrantes`);
    this.setState({ membershipSelectedDropDown: memberhsip });
  };

  getMaxMembers = () =>
    (this.state.membershipSelected &&
      this.state.membershipSelected.integrantes) ||
    0;

  setValue = (index, id, value) => {
    const members = [...this.state.members];
    members[index][id] = value;
    this.setState({ ...this.state, members });
  };

  renderInputText = ({ index, column: { id } }) => {
    return (
      <Input
        type="text"
        className={`${s.renderEditableInput} my-1`}
        disabled={true}
        value={this.state.members[index][id]}
        onChange={e => {
          const value = e.target.value;
          this.setValue(index, id, value);
        }}
      />
    );
  };

  invalidMax = n => Number(n) < 0 || Number(n) > this.getMaxMembers();

  renderInputNumber = ({ index, column: { id } }) => {
    /*  return (
       <Input
         type="number"
         className={`${s.renderEditableInput} my-1 ${this.invalidMax(this.state.members[index][id]) ? "is-invalid" : ""
           }`}
         value={this.state.members[index][id]}
         max={this.getMaximo()}
         min={1}
         disabled={
           this.state.members[index].tipoPersona === TIPO_PERSONA.CLIENTE_TITULAR
         }
         onChange={e => {
           const value = e.target.value;
           this.setValue(index, id, value);
         }}
       />
     ); */
    return (
      <div className="text-center pt-2">
        <Input
          className="cursor-pointer"
          type="checkbox"
          checked={!!this.state.members[index][id]}
          onChange={e => {
            const checked = e.target.checked;
            this.setValue(index, id, checked ? 1 : 0);
          }}
        />
      </div>
    );
  };

  pushMember = () => {
    const members = [...this.state.members];
    members.push(emptyRow(TIPO_PERSONA.CLIENTE_INTEGRANTE));
    this.setState({ ...this.state, members });
  };

  getMaximo = () =>
    (this.state.membershipSelected &&
      this.state.membershipSelected.integrantes) ||
    0;

  getTotal = () =>
    this.state.members.reduce(
      (accum, { maximoIntegrantes }) => accum + Number(maximoIntegrantes),
      0
    );

  maximumExceed = () => false; //this.getTotal() > this.getMaximo();

  maximumReached = () => this.getTotal() >= this.getMaximo();

  disableButton = () => {
    return (
      this.state.members.some(
        ({ maximoIntegrantes, nombre }) =>
          !nombre || this.invalidMax(maximoIntegrantes)
      ) || this.maximumExceed()
    );
  };

  sendMembersTypes = e => {
    e.preventDefault();
    const { membresiaId } = this.props.match.params;
    this.props
      .sendMembersTypes(membresiaId, this.state.members)
      .then(({ value: { data } }) => {
        this.setState({ members: data });
      });
  };

  render() {
    const buttonsStyle = {
      marginTop: "15px"
    };

    return (
      <Container className="p-0 mt-2">
        <Row className="p-0 m-0 mb-3">
          <Col xs="6" className="p-0 m-0">
            <SelectFilter
              label={"Membresía"}
              name="membershipSelector"
              type="select"
              id="membershipSelector"
              placeholder="Membresía"
              value={this.state.membershipSelectedDropDown}
              options={this.state.options}
              onChange={this.changeMembership}
            />
          </Col>
          <Col>
            <p
              className="mb-0 text-center font-weight-bold"
              style={{ marginTop: "36px" }}
            >
              MÁXIMO DE LA MEMBRESÍA: {this.getMaximo()}
            </p>
          </Col>
        </Row>
        <Row className="p-0 m-0">
          <ReactTable
            data={this.state.members}
            columns={[
              {
                Header: "MEMBRESIA",
                Cell: () => (
                  <p className="text-center m-0 h-100 p-1">
                    {(this.state.membershipSelected &&
                      this.state.membershipSelected.label) ||
                      ""}
                  </p>
                ),
                className: "p-0"
              },
              {
                Header: "NOMBRE",
                accessor: "nombre",
                Cell: this.renderInputText,
                className: "p-0"
              },
              {
                Header: "INTEGRANTES",
                accessor: "maximoIntegrantes",
                Cell: this.renderInputNumber,
                className: "p-0"
              }
            ]}
            pageSize={this.state.members.length}
            sortable={false}
            showPagination={false}
            className="-highlight w-100"
            getTbodyProps={() => ({ style: { backgroundColor: "#EAECEE" } })}
          />
        </Row>
        {this.maximumExceed() && (
          <Row className="p-0 m-0">
            <Col />
            <Col className="p-0 m-0" xs={3}>
              <span className={`${s.errorSpan} float-right`}>
                Máximo número de integrantes de la membresía excedido.
                <br />
              </span>
            </Col>
          </Row>
        )}
        <Row className="p-0 m-0">
          <Col />
          <Col xs="2" className="float-right pr-0">
            <Button
              className={`${s.buttonMarginTop} ${s.primaryButton} mt-4`}
              onClick={this.sendMembersTypes}
              size="sm"
              block
              disabled={this.disableButton()}
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(MembershipMembers));
