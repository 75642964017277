import React from "react";
import Layout from "../../components/layout";
import InboxCalendarSelectorPage from "../../components/inboxCalendarSelectorPage";

interface Props {}
interface State {}

/**
 * Contenedor principal del calendario de cargos; para determinar los días de
 * transacciones entre tipos de tarjetas (TDD, TDC, AMEX)
 */
export class InboxCalendarSelector extends React.Component<Props, State> {
  render() {
    return (
      <main>
        <Layout>
          <InboxCalendarSelectorPage />
        </Layout>
      </main>
    );
  }
}
