import React, { useState, useEffect } from "react";
import { Container, Label, Input } from "reactstrap";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faStore } from "@fortawesome/free-solid-svg-icons";
import MexicanCurrency from "../../common/currency";
import s from "./styles.scss";
import { PRODUCT_STATE } from "..";
import reduce from "lodash.reduce";

interface Props {
  products: PRODUCT_STATE[];
  loading: boolean;
  setChecked?: (id: string, checked: boolean) => any;
  checked?: object;
  allChecked: boolean;
  freezeObject: object;
  setFreezeObject: (data: object) => any;
}

const showCurrency = quantity => (
  <p className="p-0 text-center w-100 m-0">
    <MexicanCurrency quantity={quantity} />
  </p>
);

const ProductsListTempSelector = React.memo(
  ({ products, loading, setChecked, freezeObject, setFreezeObject }: Props) => {
    const [localChecked, setLocalChecked] = useState(freezeObject);

    useEffect(() => {
      if (Object.keys(localChecked).length > 0) {
        setFreezeObject(localChecked);
      }
    }, [localChecked]);

    useEffect(() => {
      if (Object.keys(localChecked).length > 0) {
        let valLocalChecked = localChecked;
        products.forEach(element => {
          if (!localChecked.hasOwnProperty(element.sportsProductId)) {
            Object.defineProperty(valLocalChecked, element.sportsProductId, {
              value: false
            });
          }
        });
        setLocalChecked(valLocalChecked);
      } else {
        setLocalChecked(
          reduce(
            products,
            (accum, { sportsProductId, porPagar }) => ({
              ...accum,
              [sportsProductId]: porPagar
            }),
            {}
          )
        );
      }
    }, [products]);
    const columns: any = [
      {
        Header: "Cliente",
        accessor: "nombreCliente"
      },
      {
        Header: "Nombre",
        accessor: "sportsProductName"
      },
      {
        Header: "Club",
        accessor: "club",
        width: 90
      },
      {
        Header: "Meses sin intereses",
        accessor: "mesesSinIntereses",
        width: 100,
        Cell: ({ value }) => (
          <p className="p-0 text-center w-100 m-0">
            {value === 0 ? "Contado" : value}
          </p>
        )
      },
      {
        Header: "Cantidad",
        accessor: "quantity",
        width: 70,
        Cell: ({ value }) => (
          <p className="p-0 text-center w-100 m-0">{value}</p>
        )
      },
      {
        Header: "Subtotal",
        accessor: "subtotal",
        width: 100,
        Cell: ({ value }) => showCurrency(value)
      },
      {
        Header: "Descuento",
        accessor: "montoDescuento",
        width: 100,
        Cell: ({ value }) => showCurrency(value)
      },
      {
        Header: "IVA",
        accessor: "montoIva",
        width: 100,
        Cell: ({ value }) => showCurrency(value)
      },
      {
        Header: "Total",
        accessor: "total",
        width: 100,
        Cell: ({ value }) => showCurrency(value)
      }
    ];
    if (setChecked) {
      columns.push({
        id: "sportsProductIdx",
        className: "p-0",
        width: 25,
        Header: () => <FontAwesomeIcon icon={faStore} />,
        accessor: ({ itemId }) => {
          return (
            <Input
              className={s.checkboxInput}
              type="checkbox"
              onChange={e => {
                const productoChecked = e.target.checked;

                setLocalChecked({
                  ...localChecked,
                  [itemId]: productoChecked
                });

                setChecked(itemId, productoChecked);
              }}
              style={{ marginLeft: "0.4rem" }}
            />
          );
        }
      });
    }

    return (
      <Container className="row p-0 m-0 mt-2">
        <ReactTable
          manual
          data={products || []}
          columns={columns}
          className="w-100 -striped -highlight"
          loading={loading}
          sortable={false}
          showPagination={false}
          defaultPageSize={5}
        />
      </Container>
    );
  }
);

export default ProductsListTempSelector;
