import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import ClubSelector from "../../../Selectors/containers/ClubSelector";
import ModalFrame from "../../../toolkit/modalFrame";
import NotificationsMessages from "./notificationsMessages";
import ProcedureChargesMessages from "./procedureChargesMessages";
import { Club } from "../../../types-business/Clubs";
import {
  transferClubMembershipRequest,
  transferClubMembershipValidationRequest
} from "../../actions/partners";
import { errorNotification } from "../../../utils/notifications";
import { handleRequestError } from "../../../utils/helpers";
import permissionCodes from "../../../utils/permissionCodes";
import { userHasPermission } from "../../../utils/accessTree";

interface Partner {
  idMembresiaSocio: string;
  idPersona: string;
  idPropietario: string;
}

interface Props {
  partner: Partner;
  clubs: Club[];
  redirect: (url) => any;
}

interface State {
  isOpen: boolean;
  transferClubMessages: any[];
  transferClubCharges: any[];
  selectedClubId: any;
  transferClubConditions: any;
  cambiando: boolean;
}

class TransferClubMembership extends React.Component<Props, State> {
  state = {
    isOpen: false,
    transferClubMessages: [],
    transferClubCharges: [],
    selectedClubId: null,
    transferClubConditions: null,
    cambiando: false
  };

  toggleTransferModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  validateTransferClubMembership = () => {
    const { selectedClubId: clubId } = this.state;

    if (!clubId) {
      errorNotification("Seleccione el club destino");
      return;
    }

    const conditions = { clubId };
    this.setState({ transferClubConditions: conditions });

    const { partner } = this.props;

    transferClubMembershipValidationRequest(
      conditions,
      partner.idMembresiaSocio
    )
      .then(({ data }) => {
        const { resultado, mensajes, cargosAGenerar } = data;
        this.setState({
          isOpen: true,
          transferClubMessages: mensajes,
          transferClubCharges: cargosAGenerar
        });
      })
      .catch(handleRequestError);
  };

  executeTransferClubMembership = (withoutPayment: boolean) => {
    const { partner } = this.props;
    const { transferClubConditions } = this.state;

    this.setState({ cambiando: true });

    transferClubMembershipRequest(
      { traspasoSinPago: !!withoutPayment, ...transferClubConditions },
      partner.idMembresiaSocio,
      "1",
      "false"
    )
      .then(({ data }) => {
        this.setState({ cambiando: false });
        const { resultado } = data;
        const membresiaSocio = data.data || {};

        if (!!resultado) {
          this.clearState();

          const { idMembresiaSocio, idPublico, idClubBase } = membresiaSocio;

          //Si hubo un cambio de club, se va a la pantalla de venta exitosa de membresía socio, si no, se va al carrito para el pago
          if (partner.idClubBase != idClubBase) {
            this.props.redirect(
              `/membresias-socios/${idMembresiaSocio}?idPublico=${idPublico}&idClub=${idClubBase}`
            );
          } else {
            this.props.redirect(
              `/productos/carrito/${this.props.partner.idPersona}`
            );
          }
        } else {
          errorNotification("Ocurrió un error al ejecutar el traspaso de club");
        }
      })
      .catch(e => {
        this.setState({ cambiando: false });
        handleRequestError(e);
      });
  };

  cancelTransferClubMembership = () => {
    this.clearState();
  };

  clearState = () => {
    this.setState({
      isOpen: false,
      transferClubMessages: [],
      transferClubCharges: [],
      transferClubConditions: null
    });
  };

  render() {
    return (
      <div>
        <ModalFrame
          title={"Confirmar traspaso de club"}
          isOpen={this.state.isOpen}
          toggle={this.toggleTransferModal}
          size="lg"
        >
          <Container>
            <p>Mensajes:</p>
            <NotificationsMessages
              messages={this.state.transferClubMessages || []}
            />
            <br />
            <br />
            <p>Cargos a generar:</p>
            <ProcedureChargesMessages
              cargos={this.state.transferClubCharges || []}
            />
            <br />
            {!(this.state.transferClubMessages || []).length && (
              <React.Fragment>
                <br />
                <p className={`text-center w-100`}>
                  ¿Desea realizar el cambio de membresía?
                </p>
              </React.Fragment>
            )}
            <Row>
              <Col className="text-right">
                <Button
                  color="secondary"
                  onClick={this.cancelTransferClubMembership}
                  style={{ marginRight: "15px" }}
                >
                  Cancelar
                </Button>

                {!(this.state.transferClubMessages || []).length && (
                  <Button
                    onClick={() => {
                      this.executeTransferClubMembership(false);
                    }}
                    style={{ marginRight: "15px" }}
                    disabled={this.state.cambiando}
                  >
                    Traspasar
                  </Button>
                )}

                {!(this.state.transferClubMessages || []).length &&
                  userHasPermission(
                    permissionCodes.PARTNER_MEMBERSHIP_TAB_TRAM_TRANSFER_CLUB
                  ) && (
                    <Button
                      onClick={() => {
                        this.executeTransferClubMembership(true);
                      }}
                      style={{ marginRight: "15px" }}
                      disabled={this.state.cambiando}
                    >
                      Traspasar sin pago
                    </Button>
                  )}
              </Col>
            </Row>
          </Container>
        </ModalFrame>

        <Row className="p-0 m-0">
          <Col xs={4}>
            <ClubSelector
              label="Club destino"
              value={this.state.selectedClubId}
              onChange={value => {
                this.setState({ selectedClubId: value });
              }}
            />
          </Col>
          <Col xs={2}>
            <Button
              onClick={this.validateTransferClubMembership}
              className="mt-3"
            >
              Traspasar
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default TransferClubMembership;
