import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import { QueryResponse } from "../../types/QueryResponse";
import { Agreement } from "../../types-business/Agreements";
import { AGREEMENT_PRICE, PRICE_TYPES } from "../../types-business/Agreements";
import moment from "moment";
import {
  infoNotification,
  errorNotification,
  successNotification,
  PENDING,
  ERROR,
  SAVE_BASIC_AGREEMENT,
  ADD_CLUBS_AGREEMENT,
  ADD_MAINTENANCES_AGREEMENT,
  SAVE_CHANGES_BENEFITS_AGREEMENT_PENDING,
  SAVE_CHANGES_BENEFITS_AGREEMENT_ERROR,
  SAVE_CHANGES_BENEFITS_AGREEMENT
} from "../../utils/notifications";
import { setChildStep } from "../helpers/stepper-state-comparator";
import { readMessageBack } from "../../utils/readMessageBack";

// TODO: Esta funcionalidad se heredó de los convenios; faltan exclusiones

const MONTHS_IN_YEAR = 12;

interface State {
  gettingAgreementsError: object;
  agreements: QueryResponse<Agreement>;
  searchingAgreements: boolean;
  createAgreementError: object;
  creatingAgreement: boolean;
  agreementCreated: boolean;
  agreementsByAttributes: object[];
  loadingPrices: boolean;
  basicAgreementSaved: boolean;
  basicAgreement: object;
  gettingBasicAgreement: boolean;
  clubsSaved: boolean;
  agreementClubs: object[];
  maintenancesSaved: boolean;
  agreementMaintenances: object[];
  agreementMemberships: object[];
  agreementPrices: AGREEMENT_PRICE;
  agreementModified: boolean;
  changesConfirmed: boolean;
  agreementById: object;
  gettingAgreementById: boolean;
  gettingAgreementsByAttributes: boolean;
  filterAgreements: QueryResponse<Agreement>;
}

const initialState: State = {
  gettingAgreementsError: null,
  agreements: null,
  searchingAgreements: false,
  createAgreementError: null,
  creatingAgreement: false,
  agreementCreated: false,
  loadingPrices: false,
  agreementsByAttributes: null,
  basicAgreementSaved: false,
  basicAgreement: null,
  gettingBasicAgreement: false,
  clubsSaved: false,
  agreementClubs: null,
  maintenancesSaved: false,
  agreementMaintenances: null,
  agreementMemberships: null,
  agreementPrices: null,
  agreementModified: false,
  changesConfirmed: false,
  agreementById: null,
  gettingAgreementById: false,
  gettingAgreementsByAttributes: false,
  filterAgreements: null
};

function getErrorMessage(payload) {
  if (payload.response && payload.response.data) {
    return {
      message: payload.response.data.message || "Ocurrió un error inesperado",
      error_description:
        payload.response.data.error_description ||
        payload.response.data.message ||
        "Ocurrió un error inesperado"
    };
  }
  return {
    error_description: "Ocurrió un error inesperado"
  };
}

export default function agreementsReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.GROUP_GET_AGREEMENTS}_PENDING`: {
      return {
        ...state,
        gettingAgreementsError: null,
        searchingAgreements: true,
        agreements: null
      };
    }
    case `${actionTypes.GROUP_GET_AGREEMENTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        gettingAgreementsError: getErrorMessage(action.payload),
        searchingAgreements: false,
        agreements: null
      };
    }
    case `${actionTypes.GROUP_GET_AGREEMENTS}_FULFILLED`: {
      return {
        ...state,
        gettingAgreementsError: null,
        agreements: action.payload.data,
        searchingAgreements: false
      };
    }
    case `${actionTypes.GROUP_SEARCH_AGREEMENTS_BY_ATTRIBUTES}_PENDING`: {
      return {
        ...state,
        agreementsByAttributes: null,
        gettingAgreementsByAttributes: true
      };
    }
    case `${actionTypes.GROUP_SEARCH_AGREEMENTS_BY_ATTRIBUTES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        agreementsByAttributes: null,
        gettingAgreementsByAttributes: false
      };
    }
    case `${actionTypes.GROUP_SEARCH_AGREEMENTS_BY_ATTRIBUTES}_FULFILLED`: {
      return {
        ...state,
        agreementsByAttributes: action.payload.data,
        gettingAgreementsByAttributes: false
      };
    }
    case `${actionTypes.GROUP_CREATE_AGREEMENT}_PENDING`: {
      return {
        ...state,
        createAgreementError: null,
        creatingAgreement: true
      };
    }
    case `${actionTypes.GROUP_CREATE_AGREEMENT}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        createAgreementError: getErrorMessage(action.payload),
        creatingAgreement: false
      };
    }
    case `${actionTypes.GROUP_CREATE_AGREEMENT}_FULFILLED`: {
      return {
        ...state,
        createAgreementError: null,
        creatingAgreement: false,
        agreementCreated: true
      };
    }
    case `${actionTypes.GROUP_DISABLE_AGREEMENT_CREATED}`: {
      return {
        ...state,
        agreementCreated: false
      };
    }
    case `${actionTypes.GROUP_DISABLE_BASIC_AGREEMENT_SAVED}`: {
      return {
        ...state,
        basicAgreementSaved: false
      };
    }
    case `${actionTypes.GROUP_GET_PRICES_AGREEMENT}_PENDING`: {
      return {
        ...state,
        loadingPrices: true
      };
    }
    case `${actionTypes.GROUP_GET_PRICES_AGREEMENT}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        loadingPrices: false
      };
    }
    case `${actionTypes.GROUP_GET_PRICES_AGREEMENT}_FULFILLED`: {
      return {
        ...state,
        loadingPrices: false,
        agreementPrices: action.payload.data
      };
    }
    case `${actionTypes.GROUP_UPDATE_BASIC_AGREEMENT}_PENDING`: {
      infoNotification(PENDING);
      return {
        ...state,
        basicAgreement: null,
        basicAgreementSaved: false
      };
    }
    case `${actionTypes.GROUP_UPDATE_BASIC_AGREEMENT}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        basicAgreement: null,
        basicAgreementSaved: false
      };
    }
    case `${actionTypes.GROUP_UPDATE_BASIC_AGREEMENT}_FULFILLED`: {
      successNotification(SAVE_BASIC_AGREEMENT);
      return {
        ...state,
        basicAgreementSaved: true,
        basicAgreement: action.payload.data,
        agreementById: action.payload.data
      };
    }
    case `${actionTypes.GROUP_SAVE_BASIC_AGREEMENT}_PENDING`: {
      infoNotification(PENDING);
      return {
        ...state,
        basicAgreement: null,
        basicAgreementSaved: false
      };
    }
    case `${actionTypes.GROUP_SAVE_BASIC_AGREEMENT}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        basicAgreement: null,
        basicAgreementSaved: false
      };
    }
    case `${actionTypes.GROUP_SAVE_BASIC_AGREEMENT}_FULFILLED`: {
      successNotification(SAVE_BASIC_AGREEMENT);
      return {
        ...state,
        basicAgreementSaved: true,
        basicAgreement: action.payload.data
      };
    }
    case `${actionTypes.GROUP_ADD_CLUBS_AGREEMENT}_PENDING`: {
      infoNotification(PENDING);
      return {
        ...state,
        clubsSaved: false
      };
    }
    case `${actionTypes.GROUP_ADD_CLUBS_AGREEMENT}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        clubsSaved: false
      };
    }
    case `${actionTypes.GROUP_ADD_CLUBS_AGREEMENT}_FULFILLED`: {
      successNotification(ADD_CLUBS_AGREEMENT);
      return {
        ...state,
        clubsSaved: true
      };
    }
    case `${actionTypes.GROUP_GET_CLUBS_AGREEMENT}_PENDING`: {
      return {
        ...state,
        agreementClubs: null
      };
    }
    case `${actionTypes.GROUP_GET_CLUBS_AGREEMENT}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        agreementClubs: null
      };
    }
    case `${actionTypes.GROUP_GET_CLUBS_AGREEMENT}_FULFILLED`: {
      return {
        ...state,
        agreementClubs: action.payload.data
      };
    }
    case `${actionTypes.GROUP_ADD_MAINTENANCES_AGREEMENT}_PENDING`: {
      infoNotification(PENDING);
      return {
        ...state,
        maintenancesSaved: false
      };
    }
    case `${actionTypes.GROUP_ADD_MAINTENANCES_AGREEMENT}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        maintenancesSaved: false
      };
    }
    case `${actionTypes.GROUP_ADD_MAINTENANCES_AGREEMENT}_FULFILLED`: {
      successNotification(ADD_MAINTENANCES_AGREEMENT);
      return {
        ...state,
        maintenancesSaved: true
      };
    }
    case `${actionTypes.GROUP_GET_MAINTENANCES_AGREEMENT}_PENDING`: {
      return {
        ...state,
        agreementMaintenances: null
      };
    }
    case `${actionTypes.GROUP_GET_MAINTENANCES_AGREEMENT}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        agreementMaintenances: null
      };
    }
    case `${actionTypes.GROUP_GET_MAINTENANCES_AGREEMENT}_FULFILLED`: {
      return {
        ...state,
        agreementMaintenances: action.payload.data
      };
    }
    case `${actionTypes.GROUP_GET_MEMBERSHIPS_AGREEMENT}_PENDING`: {
      return {
        ...state,
        agreementMemberships: null
      };
    }
    case `${actionTypes.GROUP_GET_MEMBERSHIPS_AGREEMENT}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        agreementMemberships: null
      };
    }
    case `${actionTypes.GROUP_GET_MEMBERSHIPS_AGREEMENT}_FULFILLED`: {
      return {
        ...state,
        agreementMemberships: action.payload.data
      };
    }
    case `${actionTypes.GROUP_CHECK_PROPORTIONAL_AGREEMENT}`: {
      const agreementPrices = { ...state.agreementPrices };
      agreementPrices.aplicaProporcional = action.payload;
      setChildStep(agreementPrices);
      return {
        ...state,
        agreementPrices,
        agreementModified: true
      };
    }
    case `${actionTypes.GROUP_CHECK_ABSOLUTE_AGREEMENT}`: {
      const agreementPrices = { ...state.agreementPrices };
      const mesRelativo = action.payload;
      agreementPrices.mesRelativo = mesRelativo;
      if (mesRelativo) {
        agreementPrices.meses.forEach((mesInfo, index) => {
          mesInfo.mes = index + 1;
        });
      }
      setChildStep(agreementPrices);
      return {
        ...state,
        agreementPrices,
        agreementModified: true
      };
    }
    case `${actionTypes.GROUP_AGREEMENT_MEMBERSHIP_VALUE}`: {
      const agreementPrices = { ...state.agreementPrices };
      agreementPrices.valorBeneficioMembresia = action.payload;
      setChildStep(agreementPrices);
      return {
        ...state,
        agreementPrices,
        agreementModified: true
      };
    }
    case `${actionTypes.GROUP_AGREEMENT_MEMBERSHIP_VALUE_TYPE}`: {
      const agreementPrices = { ...state.agreementPrices };
      agreementPrices.tipoBeneficioMembresia = action.payload;
      setChildStep(agreementPrices);
      return {
        ...state,
        agreementPrices,
        agreementModified: true
      };
    }
    case `${actionTypes.GROUP_AGREEMENT_MEMBERSHIP_VALUE_TYPE_MONTH}`: {
      const agreementPrices = { ...state.agreementPrices };
      const { monthIndex, priceType } = action.payload;
      agreementPrices.meses[monthIndex].tipoBeneficioMes = priceType;
      agreementPrices.meses[monthIndex].beneficios.forEach(beneficio => {
        beneficio.valor = 0;
      });
      setChildStep(agreementPrices);
      return {
        ...state,
        agreementPrices,
        agreementModified: true
      };
    }
    case `${actionTypes.GROUP_AGREEMENT_PRICE_CELL}`: {
      const agreementPrices = { ...state.agreementPrices };
      const { monthIndex, memberIndex, value } = action.payload;
      agreementPrices.meses[monthIndex].beneficios[memberIndex].valor = value;
      setChildStep(agreementPrices);
      return {
        ...state,
        agreementPrices,
        agreementModified: true
      };
    }
    case `${actionTypes.GROUP_CHECK_LAST_MONTH_AGREEMENT}`: {
      const agreementPrices = { ...state.agreementPrices };
      const { monthIndex, checked } = action.payload;
      if (checked) {
        agreementPrices.meses.forEach(benefit => {
          benefit.esUltimoMesRecurrente = false;
        });
      }
      agreementPrices.meses[monthIndex].esUltimoMesRecurrente = checked;
      setChildStep(agreementPrices);
      return {
        ...state,
        agreementPrices,
        agreementModified: true
      };
    }
    case `${actionTypes.GROUP_DELETE_MONTH_PRICES_AGREEMENT}`: {
      const agreementPrices = { ...state.agreementPrices };
      agreementPrices.meses.pop();
      setChildStep(agreementPrices);
      return {
        ...state,
        agreementPrices,
        agreementModified: true
      };
    }
    case `${actionTypes.GROUP_ADD_MONTH_PRICES_AGREEMENT}`: {
      const agreementPrices = { ...state.agreementPrices };
      const benefits = [];
      for (
        let memberId = 1;
        memberId <= agreementPrices.numeroDeIntegrantes;
        memberId++
      ) {
        benefits.push({
          integrante: memberId,
          valor: 0
        });
      }
      agreementPrices.meses.push({
        tipoBeneficioMes: PRICE_TYPES.DESCUENTOABS,
        esUltimoMesRecurrente: false,
        beneficios: benefits,
        posicion:
          agreementPrices.meses.length > 0
            ? agreementPrices.meses[agreementPrices.meses.length - 1].posicion +
              1
            : 0,
        mes:
          agreementPrices.meses.length > 0
            ? (agreementPrices.meses[agreementPrices.meses.length - 1].mes +
                1) %
              MONTHS_IN_YEAR
            : moment().month() + 1
      });
      setChildStep(agreementPrices);
      return {
        ...state,
        agreementPrices,
        agreementModified: true
      };
    }
    case actionTypes.GROUP_MEMBERSHIP_CHANGED_AGREEMENT: {
      setChildStep(null);
      return {
        ...state,
        changesConfirmed: false,
        agreementModified: false,
        agreementPrices: null
      };
    }
    case actionTypes.GROUP_CONFIRM_BENEFITS_CHANGES: {
      return {
        ...state,
        changesConfirmed: true
      };
    }
    case actionTypes.GROUP_CLUB_CHANGED_AGREEMENT: {
      return {
        ...state,
        changesConfirmed: false,
        agreementModified: false
      };
    }
    case `${actionTypes.GROUP_SAVE_CHANGES_BENEFITS_AGREEMENT}_PENDING`: {
      infoNotification(SAVE_CHANGES_BENEFITS_AGREEMENT_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.GROUP_SAVE_CHANGES_BENEFITS_AGREEMENT}_REJECTED`: {
      errorNotification(
        readMessageBack(action, SAVE_CHANGES_BENEFITS_AGREEMENT_ERROR)
      );
      return {
        ...state
      };
    }
    case `${actionTypes.GROUP_SAVE_CHANGES_BENEFITS_AGREEMENT}_FULFILLED`: {
      successNotification(SAVE_CHANGES_BENEFITS_AGREEMENT);
      return {
        ...state,
        changesConfirmed: false,
        agreementModified: false
      };
    }
    case `${actionTypes.GROUP_GET_AGREEMENT_BY_ID}_PENDING`: {
      return {
        ...state,
        agreementById: null,
        gettingAgreementById: true
      };
    }
    case `${actionTypes.GROUP_GET_AGREEMENT_BY_ID}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        agreementById: null,
        gettingAgreementById: false
      };
    }
    case `${actionTypes.GROUP_GET_AGREEMENT_BY_ID}_FULFILLED`: {
      return {
        ...state,
        agreementById: action.payload.data,
        gettingAgreementById: false
      };
    }
    case `${actionTypes.GROUP_FILTER_AGREEMENTS}_PENDING`: {
      return {
        ...state,
        filterAgreements: null
      };
    }
    case `${actionTypes.GROUP_FILTER_AGREEMENTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        filterAgreements: null
      };
    }
    case `${actionTypes.GROUP_FILTER_AGREEMENTS}_FULFILLED`: {
      return {
        ...state,
        filterAgreements: action.payload.data
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
