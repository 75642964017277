import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import SportsProducts from "../../components/sportsProducts";
import * as sportProductActions from "../../actions/sportsProducts";
import * as catalogActions from "../../actions/catalogs";
import { QueryResponse } from "../../../types/QueryResponse";
import { QueryArguments } from "../../../types-business/QueryArguments";
import CONSTANTS from "../../../utils/constants";

interface Product {
  id: string;
  clasificacionId: string;
  nombre: string;
  estatus: string;
  publicarPrecio: boolean;
  fechaInicioVigencia: string;
  fechaFinVigencia: string;
  descripcion: string;
}

interface Props {
  products: QueryResponse<Product>;
  getProducts: (args?: QueryArguments) => any;
  getSportsProductsTypes: () => any;
  match: any;
  searching: boolean;
  addProduct: (product?) => any;
  history: any;
  sportsProductsTypes: object[];
}

interface State {
  products: object;
  filters: any;
}

class SportsProductsPage extends React.Component<Props, State> {
  state = {
    products: {
      content: [],
      totalPages: 0
    },
    filters: {
      consultarConfiguracionComision: true,
      size: CONSTANTS.DEFAULT_PAGE_SIZE,
      page: 0
    }
  };

  onChangeFilter = (value, name) => {
    const filters = {
      ...this.state.filters,
      [`${name}`]: value ? value : null
    };
    this.setState({
      filters
    });
  };

  onFetchData = ({ pageSize: size, page }) => {
    const filters = {
      ...this.state.filters,
      size,
      page
    };
    this.props.getProducts(filters);
    this.setState(state => ({ ...state, ...filters }));
  };

  goTo = destiny => {
    this.props.history.push(destiny);
  };

  componentDidUpdate(prevProps) {
    const { products } = this.props;
    const { products: prevProducts } = prevProps;
    if (products && prevProducts !== products) {
      this.setState({ products });
    }
  }

  render() {
    return (
      <main>
        <Helmet title="Sports Products" />
        <Layout>
          <SportsProducts
            products={this.state.products}
            fetchData={this.onFetchData}
            searching={this.props.searching}
            filters={this.state.filters}
            onChangeFilter={this.onChangeFilter}
            getProducts={this.props.getProducts}
            sportsProducts={this.props.sportsProductsTypes}
            onClickNewProduct={(product?) =>
              this.goTo(
                "/productos/datos-basicos/" +
                  (product ? (product.id ? product.id : product.id) : "")
              )
            }
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getProducts: (args?: QueryArguments) => {
    dispatch(sportProductActions.getProducts(args));
  },
  getSportsProductsTypes: () => {
    dispatch(catalogActions.getSportsProductsTypes());
  }
});

const mapStateToProps = state => {
  const { sportsProducts, catalogs } = state;
  return {
    products: sportsProducts.products,
    searching: sportsProducts.gettingProducts,
    sportsProductsTypes: catalogs.sportsProductsTypes
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SportsProductsPage);
