import React, { useState } from "react";
import ReactTable from "react-table";
import NotaCreditoBloqueo from "../../../types/NotaCreditoBloqueo";
import { UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export interface TableProps {
  data: NotaCreditoBloqueo[];
  loading: boolean;
  deleteAction: (facturaUUID: string) => void;
}

export const MemoTable = React.memo(
  ({ data, loading, deleteAction }: TableProps) => {
    const [columns, _] = useState(columnsFormatter(deleteAction));

    return (
      <>
        <div className="row">
          {/* Cantidad de facturas bloqueadas */}
          {!!data && !!data.length && (
            <>
              <div className="col p-0" style={{ lineHeight: "40px" }}>
                <span>Facturas bloqueadas: ({data.length})</span>
              </div>
            </>
          )}
        </div>

        <div className="row p-0 mb-5">
          <ReactTable
            data={data || []}
            loading={loading}
            pageSize={data && data.length > 5 ? data.length : 5}
            columns={columns}
            sortable={false}
            resizable={true}
            showPagination={false}
            className={`w-100 -highlight -striped`}
          />
        </div>
      </>
    );
  }
);

const columnsFormatter = (deleteAction: (facturaUUID: string) => void) => [
  {
    Header: "Folio",
    id: "facturaFolio",
    maxWidth: "128",
    accessor: simpleAccessor("facturaFolio"),
    headerClassName: "text-left"
  },
  {
    Header: "UUID",
    id: "facturaUUID",
    maxWidth: "512",
    accessor: simpleAccessor("facturaUUID"),
    headerClassName: "text-left"
  },
  {
    Header: " ",
    id: "delete",
    maxWidth: "64",
    accessor: deleteButtonAccessor(deleteAction),
    headerClassName: "text-left"
  }
];

function simpleAccessor(key: keyof NotaCreditoBloqueo) {
  return (row: NotaCreditoBloqueo) => {
    return <div>{row[key]}&nbsp;</div>;
  };
}

function deleteButtonAccessor(deleteAction: (facturaUUID: string) => void) {
  return (row: NotaCreditoBloqueo) => {
    const uuid = row["facturaUUID"];
    return (
      <>
        <UncontrolledTooltip placement="left" target={"delete-ncb-" + uuid}>
          Descartar bloqueo
        </UncontrolledTooltip>
        <div
          id={"delete-ncb-" + uuid}
          style={{ cursor: "pointer" }}
          onClick={() => deleteAction(uuid)}
        >
          <FontAwesomeIcon icon={faTimes} color={"#900"} />
        </div>
      </>
    );
  };
}
