import FormBuilderConfig from "../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import ISelectBuilder from "../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";
import IButtonBuilder from "../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import ITextBuilder from "../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";
import { getOptionsFromEnum } from "../../../toolkit/utils/enum-utils";
import { InvitadoEstatus, InvitadoTipo } from "../../../types/Invitado";
import IEmpresaConvenioSelectorBuilder from "../../../toolkit/baseForms2Commons/iEmpresaConvenioSelectorBuilder/IEmpresaConvenioBuilder";

export type Inputs = "idPersona" | "nombre" | "tipo" | "estatus" | "idEmpresa";
export type Buttons = "buscar";

export const formConfig: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "idPersona",
      label: "Id de Persona",
      bts: {
        mCol: 2
      },
      component: ITextBuilder
    },
    {
      name: "nombre",
      label: "Nombre del invitado",
      bts: {
        mCol: 3
      },
      component: ITextBuilder
    },
    {
      name: "tipo",
      label: "Tipo",
      bts: {
        mCol: 2
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Todos",
        data: getOptionsFromEnum(InvitadoTipo)
      }
    },
    {
      name: "estatus",
      label: "Estatus",
      bts: {
        mCol: 2
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Todos",
        data: getOptionsFromEnum(InvitadoEstatus)
      }
    },
    {
      name: "idEmpresa",
      label: "Empresa",
      bts: {
        mCol: 3
      },
      component: IEmpresaConvenioSelectorBuilder,
      props: {
        todas: true
      }
    }
  ],
  buttons: [
    {
      name: "buscar",
      label: "Buscar",
      isSubmit: true,
      bts: {
        mOff: 9,
        mCol: 3
      },
      component: IButtonBuilder
    }
  ],
  rows: [["idPersona", "nombre", "tipo", "estatus", "idEmpresa"], ["buscar"]]
};
