import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";
import { Prospect, INTEREST_STATUS } from "../../types-business/User";
import {
  ProspectArguments,
  ProspectReportArguments
} from "../../types/Prospects";
import FileDownload from "js-file-download";
import moment from "moment";

export const createActivity = (personaId: string, activity: object) => {
  return {
    type: actionTypes.CREATE_ACTIVITY,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}/api/prospects/${personaId}/activity`,
        {
          personaId,
          ...activity
        },
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getProspectRequest = args => {
  let baseUrl = "/api/prospects";
  const params = {
    page: 0,
    size: 1000000,
    ...args,
    ...constants.DEFAULT_SORTING_NONE
  };

  return axios.get(`${constants.BASE_URL}${baseUrl}`, {
    withCredentials: true,
    params
  });
};

export const getRepeatedProspects = (args: ProspectArguments): IAction => {
  return {
    type: actionTypes.GET_PROSPECTS_REPEATED,
    payload: {
      promise: getProspectRequest(args)
    }
  };
};

export const getProspects = (args: ProspectArguments): IAction => {
  return {
    type: actionTypes.GET_PROSPECTS,
    payload: {
      promise: getProspectRequest(args)
    }
  };
};

const buildProspect = prospect => {
  const body = {
    ...prospect,

    telefono: {
      numero: prospect.numeroTelefono,
      extension: prospect.extension
    },
    status: INTEREST_STATUS.STATUS_INTERESADO
  };

  if (prospect.codigoPostal || prospect.colonia) {
    body.direccion = {
      codigoPostal: prospect.codigoPostal || "",
      colonia: prospect.colonia || ""
    };
  }

  delete body.codigoPostal;
  delete body.colonia;
  delete body.numeroTelefono;
  delete body.extension;

  return body;
};

export const createProspect = (prospect: Prospect): IAction => {
  return {
    type: actionTypes.CREATE_PROSPECT,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}/api/prospects`,
        buildProspect(prospect),
        {
          withCredentials: true
        }
      )
    }
  };
};

export const validateReferralCode = (codeObject: object): IAction => {
  return {
    type: actionTypes.VALIDATE_REFERAL_CODE,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}/api/referred-codes/validate`,
        codeObject,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const editProspect = (
  personaId: string,
  prospect: Prospect
): IAction => {
  return {
    type: actionTypes.CREATE_PROSPECT,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}/api/prospects/${personaId}`,
        buildProspect(prospect),
        {
          withCredentials: true
        }
      )
    }
  };
};

export const patchProspect = (personaId, body: any): IAction => {
  return {
    type: actionTypes.PATCH_PROSPECT,
    payload: {
      promise: axios.patch(
        `${constants.BASE_URL}/api/prospects/${personaId}/status`,
        body,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const deleteProspect = (personaId: string): IAction => {
  return {
    type: actionTypes.DELETE_PROSPECT,
    payload: {
      promise: axios.delete(
        `${constants.BASE_URL}/api/prospects/${personaId}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const clearCreatedProspectVariable = (): IAction => {
  return {
    type: actionTypes.CLEAR_CREATE_PROSPECT_VARIABLE
  };
};

export const updateProspectExtraDataById = (
  prospect: Prospect,
  personaId: string
): IAction => {
  return {
    type: actionTypes.CREATE_PROSPECT,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}/api/prospects/extra_data/${personaId}`,
        prospect,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const cleanProspectById = (): IAction => {
  return {
    type: actionTypes.CLEAN_PROSPECT_BY_ID,
    payload: null
  };
};

export const saveReassignment = (
  type: string,
  id: string,
  prospects: object[]
): IAction => {
  return {
    type: actionTypes.SAVE_REASSIGNMENT,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}/api/prospects/reassign/${type}/${id}`,
        prospects,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const saveReassignmentRequest = (
  type: string,
  id: string,
  prospects: object[]
) => {
  return axios.post(
    `${constants.BASE_URL}/api/prospects/reassign/${type}/${id}`,
    prospects,
    {
      withCredentials: true
    }
  );
};

export const getQuotations = (
  personaId: string,
  params: object,
  integrantes: object
): IAction => {
  return {
    type: actionTypes.GET_QUOTATIONS,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}/api/prospects/quotations/${personaId}`,
        { ...params, integrantes },
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getActualQuote = (
  personaId: string,
  params: object,
  integrantes: object
): IAction => {
  return {
    type: actionTypes.GET_ACTUAL_QUOTE,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}/api/prospects/quotations/${personaId}`,
        { ...params, integrantes },
        {
          withCredentials: true
        }
      )
    }
  };
};

export const membershipPurchase = (body: object): IAction => {
  return {
    type: actionTypes.MEMBERSHIP_PURCHASE,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}/api/prospects/membership-purchase`,
        body,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const cleanSuccessfullPurchase = (): IAction => {
  return {
    type: actionTypes.CLEAN_SUCCESSFULL_PURCHASE,
    payload: null
  };
};

export const getProspectReportRequest = args => {
  let baseUrl = "/api/prospects";
  const params = {
    page: 0,
    size: 20,
    ...args
  };
  return axios.get(`${constants.BASE_URL}${baseUrl}/report`, {
    withCredentials: true,
    params
  });
};

export const executeProspectReportDownload = (args, _callback) => {
  let baseUrl = "/api/prospects";
  const params = {
    page: 0,
    size: 20,
    ...args
  };
  return axios
    .get(`${constants.BASE_URL}${baseUrl}/report/descargar`, {
      withCredentials: true,
      params,
      responseType: "blob"
    })
    .then(({ data }) => {
      if (!!_callback) {
        _callback();
      }
      FileDownload(
        data,
        "ReporteProspectos_" + moment().toISOString() + ".csv"
      );
    });
};
