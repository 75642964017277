import React from "react";
import { Button, Col, Container, Row } from "reactstrap";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import BaseInput from "../../../../toolkit/baseInput";
import ModalFrame from "../../../../toolkit/modalFrame";
import { handleRequestError } from "../../../../utils/helpers";
import {
  infoNotification,
  successNotification
} from "../../../../utils/notifications";
import {
  getClubsRequest,
  getSchedulePatternsCatalog
} from "../../../actions/catalogs";
import {
  createExtraClubAccessConfiguration,
  deleteExtraClubAccessConfiguration
} from "../../../actions/partners";
import s from "./extraClubAccessConfigurationModal.scss";

interface Props {
  config: {
    id: number;
    membresiaSocioId: number;
    personaId: number;
    patronHorarioId: number;
    clubesIds: number[];
  };
  partnerName: string;
  isOpen: boolean;
  cancel: () => any;
  success: () => any;
}

interface State {
  selectedPatronId: number;
  selectedClubId: any[];
  patterns: any[];
  clubs: any[];
}

class ExtraClubAccessConfigurationModal extends React.Component<Props, State> {
  state = {
    selectedPatronId: null,
    selectedClubId: null,
    patterns: [],
    clubs: []
  };

  componentDidMount() {
    getSchedulePatternsCatalog()
      .then(({ data }) => {
        this.setState({ patterns: data });
      })
      .catch(handleRequestError);
    getClubsRequest()
      .then(({ data }) => {
        this.setState({ clubs: data });
      })
      .catch(handleRequestError);
  }

  handleSubmit = () => {
    const { selectedClubId, selectedPatronId } = this.state;

    infoNotification("Guardando datos de configuración...");

    createExtraClubAccessConfiguration(
      this.props.config.membresiaSocioId,
      this.props.config.personaId,
      {
        ...this.props.config,
        patronHorarioId: selectedPatronId,
        clubesIds: [...selectedClubId]
      }
    )
      .then(() => {
        successNotification("Datos guardados correctamente");
        this.setState({ selectedClubId: null, selectedPatronId: null });
        this.props.success();
      })
      .catch(handleRequestError);
  };

  handleDelete = () => {
    infoNotification("Eliminando datos de configuración...");

    deleteExtraClubAccessConfiguration(
      this.props.config.membresiaSocioId,
      this.props.config.personaId,
      { ...this.props.config }
    )
      .then(() => {
        successNotification("Datos eliminados correctamente");
        this.setState({ selectedClubId: null, selectedPatronId: null });
        this.props.success();
      })
      .catch(handleRequestError);
  };

  handleCancel = () => {
    this.setState({ selectedClubId: null, selectedPatronId: null });
    this.props.cancel();
  };

  render() {
    const patternId = (this.props.config || {}).patronHorarioId || 0;
    let clubId = null;
    if (!!this.props.config && (this.props.config.clubesIds || []).length > 0) {
      clubId = [...this.props.config.clubesIds];
    }

    return (
      <ModalFrame
        title={`Configuración de acceso a club adicional para integrante ${this.props.partnerName}`}
        isOpen={this.props.isOpen}
        toggle={() => {}}
        size={"md"}
      >
        <Container style={{ width: "90%" }} className="center-block p-0 mb-3">
          <div>
            <BaseInput
              type="select"
              id="iPatronHorario"
              name="iPatronHorario"
              label={"Patron horario"}
              value={this.state.selectedPatronId || patternId}
              onChange={e => {
                const value = e.target.value;
                this.setState({ selectedPatronId: value });
              }}
              options={[{ value: 0, label: "Seleccione" }].concat(
                (this.state.patterns || []).map(x => {
                  return { value: x.patronId, label: x.nombre };
                })
              )}
            />
          </div>

          <div className="mb-4">
            <BaseInput
              type="select"
              id="iClub"
              name="iClub"
              label={"Club"}
              multipleSelection={true}
              value={this.state.selectedClubId || clubId}
              onChange={e => {
                var options = e.target.options;
                var value = [];
                for (var i = 0, l = options.length; i < l; i++) {
                  if (options[i].selected) {
                    value.push(options[i].value);
                  }
                }
                this.setState({ selectedClubId: value });
              }}
              options={(this.state.clubs || []).map(x => {
                return { value: x.clubId, label: x.nombre };
              })}
            />
          </div>

          <div className="m-3">
            <span>Para seleccionar múltiples clubes:</span>
            <br />
            <span>CTRL + CLICK </span>
            <br />
            <span>SHIFT + CLICK </span>
          </div>

          <Row className="m-0">
            <Col xs="12" md="4">
              <Button
                className={`mt-3 dangerButton`}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.handleDelete();
                }}
                block
                disabled={!this.props.config.id}
              >
                Eliminar
              </Button>
            </Col>
            <Col xs="12" md="4">
              <Button
                className={`mt-3 primaryButton`}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.handleSubmit();
                }}
                block
                disabled={
                  !this.state.selectedClubId || !this.state.selectedPatronId
                }
              >
                Guardar
              </Button>
            </Col>
            <Col xs="12" md="4">
              <Button
                className={` mt-3`}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.handleCancel();
                }}
                block
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </Container>
      </ModalFrame>
    );
  }
}

export default withStyles(s)(ExtraClubAccessConfigurationModal);
