import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import BaseInput from "../../../toolkit/baseInput";
import { Alert, Button } from "reactstrap";
import ModalFrame from "../../../toolkit/modalFrame";
import s from "./styles.scss";
import Reform from "@franleplant/reform";
import { FormErrors } from "@franleplant/reform/types";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  creatingBank: boolean;
  history: any;
  editBank: (bancoId: string, bank: Banco) => any;
  creatingBankError: object;
  createBank: (fields: object) => any;
  bankCreated: boolean;
  bankToEdit: bankToEdit;
  disableBankCreated: () => {};
}

interface bankToEdit {
  bancoId: string;
  nombre: string;
}

interface Banco {
  nombre: string;
}

interface State {
  fields: Banco;
  editing: boolean;
  errors: FormErrors;
}

const getCleanFields = () => {
  return {
    nombre: ""
  };
};

class CreateBankModal extends React.Component<Props, State> {
  state = {
    fields: getCleanFields(),
    editing: false,
    errors: {}
  };

  re = Reform.reactMixins.objectMixin(this);

  validationRules = {
    nombre: { required: true }
  };

  validationMessages = {
    required: _ => `Obligatorio`,
    default: _ => `Inválido`
  };

  componentDidUpdate() {
    if (!this.props.isOpen && this.state.editing) {
      this.setState(prevState => {
        return {
          ...prevState,
          fields: getCleanFields(),
          editing: false
        };
      });
    }
    if (this.props.isOpen && !this.state.editing) {
      let fields;
      if (this.props.bankToEdit) {
        const bankToEdit = this.props.bankToEdit;
        fields = {
          ...this.state.fields,
          ...bankToEdit
        };
      } else {
        fields = getCleanFields();
      }
      this.setState(prevState => {
        return {
          ...prevState,
          fields,
          editing: true,
          errors: {}
        };
      });
    }
  }

  onChangeFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };

        return { ...state, error: {}, fields };
      });
      this.re.validateField(fieldName, value);
    };
  };

  onSubmit = e => {
    e.preventDefault();
    if (this.re.validateFormFromState()) {
      if (this.props.bankToEdit)
        this.props.editBank(
          this.props.bankToEdit && this.props.bankToEdit.bancoId,
          this.state.fields
        );
      else this.props.createBank(this.state.fields);
    }
  };

  render() {
    return (
      <ModalFrame
        title={`${this.props.bankToEdit ? "Editar" : "Crear"} Banco`}
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        size="lg"
      >
        <div className={s.modalContainer}>
          <div className="h-100">
            <form className={s.basicDataClub}>
              <div className="form-row">
                <div className="col">
                  <BaseInput
                    label={"Nombre"}
                    name="nombre"
                    type="text"
                    id="nombre"
                    placeholder="Nombre"
                    value={this.state.fields.nombre}
                    onChange={this.onChangeFactory("nombre")}
                    errors={this.re.mapFieldErrors("nombre")}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className={s.formGroup}>
            <div className="row">
              <div className="col" />
              <div className="col-2">
                <Button
                  className={`${s.buttonMarginTop} ${s.primaryButton}`}
                  onClick={e => this.onSubmit(e)}
                  size="sm"
                  block
                  disabled={this.props.creatingBank}
                >
                  {"Guardar"}
                </Button>
              </div>
            </div>
          </div>
          {this.props.creatingBankError && (
            <span>
              <hr />
              <Alert color="danger">
                Ocurrio un error creando el banco. Intente nuevamente.
              </Alert>
            </span>
          )}
        </div>
      </ModalFrame>
    );
  }
}

export default withRouter(withStyles(s)(CreateBankModal));
