import React from "react";
import s from "./styles.scss";
import { Styled } from "../../../toolkit/decorators/Artifact";
import ClubSelectList from "../../containers/clubSelectList";
import { Row, Col, Button } from "reactstrap";
import SelectFilter from "../common/SelectFilter";
import BaseInput from "../../../toolkit/baseInput";
import { FormErrors } from "@franleplant/reform/types";
import { errorNotification } from "../../../utils/notifications";
import moment from "moment";

// Interfaz para validación de formularios con Reform
interface Fields {
  club: any;
  idPublico: string;
  tratamiento: any;
  rango: any;
  estatus: any[];
}

interface FiltersMap {
  idClub?: number;
  idPublico?: number;
  tratamiento: string;
  rango: number;
  inactiva: boolean;
  activa: boolean;
  pasiva: boolean;
  pendiente: boolean;
}

// Propiedades y estado del componente
interface Props {
  submit: (evt: FiltersMap) => void;
  userContext: any;
  loading: boolean;
}

interface State {
  // Propiedades requeridas por Reform, para validación de formularios
  fields: Fields;
  errors: FormErrors;
}

@Styled(s)
export default class AdeudosFilters extends React.Component<Props, State> {
  emptyAntiguedad = { value: "", label: "Seleccione" };
  state = {
    fields: {
      club: null,
      idPublico: null,
      tratamiento: null,
      rango: null,
      estatus: null
    } as Fields,
    errors: {}
  };

  submit = evt => {
    evt.preventDefault();
    let idPublico: number = null;
    let idClub: number = Number(this.state.fields.club.value) || null;
    /*if (!this.state.fields.club || !this.state.fields.club.value) {
      errorNotification("Debe seleccionar el club de búsqueda");
      return;
    }*/

    if (
      this.state.fields.idPublico &&
      !((idPublico = Number(this.state.fields.idPublico.trim())) > 0)
    ) {
      errorNotification("El número de membresía es inválido");
      return;
    }

    const estatus = (this.state.fields.estatus || []).map(
      option => option.value
    );
    const filters: FiltersMap = {
      tratamiento: "NIY",
      rango: Number((this.state.fields.rango || { value: 0 }).value) || 0,
      inactiva: estatus.indexOf("INACTIVA") > -1,
      activa: estatus.indexOf("ACTIVA") > -1,
      pasiva: estatus.indexOf("PASIVA") > -1,
      pendiente: estatus.indexOf("PENDIENTE") > -1
    };

    if (idClub) {
      filters.idClub = idClub;
    }

    if (idPublico) {
      filters.idPublico = idPublico;
    }

    // Limpieza de datos
    this.props.submit(filters);
  };

  onChangeDate = fieldName => {
    return event => {
      const date = event ? moment(event).format("YYYY-MM-DD") : null;

      this.setState({
        fields: {
          ...this.state.fields,
          [fieldName]: date
        }
      });
    };
  };

  render() {
    return (
      <>
        {/* Filtros */}
        <form onSubmit={this.submit} autoComplete="off">
          <Row className="mt-4">
            {/* Selector de Club */}
            <Col md={3}>
              <ClubSelectList
                clubSelected={this.state.fields.club}
                onSelectClub={club =>
                  this.setState({ fields: { ...this.state.fields, club } })
                }
                label="Club"
                userClub={this.props.userContext.clubSessionId}
              />
            </Col>

            {/* Membresía */}
            <Col md={2}>
              <BaseInput
                label={"Membresía"}
                name={"idMembresiaSocio"}
                id={"idMembresiaSocio"}
                placeholder="Membresía"
                value={this.state.fields.idPublico}
                size="sm"
                onChange={e => {
                  const idPublico = e.target.value;
                  this.setState({
                    fields: { ...this.state.fields, idPublico }
                  });
                }}
              />
            </Col>

            {/* Estatus */}
            <Col md={3}>
              <SelectFilter
                label="Estado de la membresía"
                name="estatus"
                type="select"
                id="estatus"
                placeholder="Estado de la membresía"
                value={this.state.fields.estatus}
                isMulti={true}
                options={[
                  {
                    label: "Inactiva",
                    value: "INACTIVA"
                  },
                  {
                    label: "Activa",
                    value: "ACTIVA"
                  },
                  {
                    label: "Pasiva",
                    value: "PASIVA"
                  },
                  {
                    label: "Pendiente",
                    value: "PENDIENTE"
                  }
                ]}
                onChange={estatus => {
                  this.setState({
                    fields: { ...this.state.fields, estatus }
                  });
                }}
                loading={false}
                isAsync={false}
              />
            </Col>

            {/* Fecha de adeudo */}
            <Col md={2}>
              <SelectFilter
                label="Fecha de adeudo"
                name="rango"
                type="select"
                id="rango"
                placeholder="Fecha de adeudo"
                value={this.state.fields.rango}
                isMulti={false}
                options={[
                  {
                    label: "Hasta 30 días",
                    value: "30"
                  },
                  {
                    label: "Hasta 60 días",
                    value: "60"
                  },
                  {
                    label: "Hasta 90 días",
                    value: "90"
                  },
                  {
                    label: "Cualquier fecha",
                    value: "0"
                  }
                ]}
                onChange={rango => {
                  this.setState({
                    fields: { ...this.state.fields, rango }
                  });
                }}
                loading={false}
                isAsync={false}
              />
            </Col>

            <Col md={2} className="p-0">
              <Button
                className={`${s.primaryButton} mt-4`}
                type="submit"
                size="sm"
                block
                disabled={this.props.loading}
              >
                {this.props.loading ? "Buscando..." : "Buscar"}
              </Button>
            </Col>
          </Row>
        </form>
      </>
    );
  }
}
