import React from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as sessionActions from "../Home/actions/session";
import { WHO_AM_I_STATE } from "../types-business/Profile";
import { validAccess } from "../utils/accessTree";
import { RouteNotAllowedError } from "../Home/components/errorMessages";

class PrivateRoute extends Route<any> {
  state = {
    loading: true,
    success: false,
    accessTreeAvailable: false
  };

  componentDidMount() {
    this.props.whoami();
  }

  componentDidUpdate() {
    if (this.state.loading) {
      if (this.props.iKnowWhoIAm === WHO_AM_I_STATE.SUCCESS) {
        this.props.getFunctionalityTree();
        this.setState(() => ({
          loading: false,
          success: true
        }));
      } else if (
        this.props.iKnowWhoIAm === WHO_AM_I_STATE.FAILED ||
        this.props.iKnowWhoIAm === WHO_AM_I_STATE.EMPTY
      ) {
        this.setState(() => ({
          loading: false,
          success: false
        }));
      }
    }
  }
  render() {
    const {
      component: Component,
      adminRoute,
      client,
      display,
      ...rest
    } = this.props;

    if (this.state.loading || !this.props.accessTreeAvailable) {
      return <div />;
    } else if (!validAccess(display)) {
      return <RouteNotAllowedError />;
    } else if (
      this.state.success &&
      this.props.accessTreeAvailable &&
      validAccess(display)
    ) {
      return (
        <Route
          {...rest}
          render={props => <Component {...this.props.extraParams} {...props} />}
        />
      );
    } else {
      return (
        <Route
          {...rest}
          render={props => (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          )}
        />
      );
    }
  }
}

const mapStateToProps = state => {
  const { user } = state;
  return {
    iKnowWhoIAm: user.iKnowWhoIAm,
    accessTreeAvailable: user.treeAvailable
  };
};

const mapDispatchToProps = dispatch => ({
  whoami: () => {
    dispatch(sessionActions.whoami());
  },
  cleanState: () => {
    dispatch(sessionActions.cleanState());
  },
  getFunctionalityTree: () => {
    dispatch(sessionActions.getFunctionalityTree());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PrivateRoute));
