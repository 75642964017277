import React from "react";
import { connect } from "react-redux";
import * as companiesActions from "../../actions/companies";
import BasicDataCompaniesForm from "../../components/basicDataCompaniesForm";

interface Props {
  getCompanie: (id: string) => any;
  createCompanie: (companie: object) => any;
  updateCompanie: (id: string, companie: object) => any;
  onClickSave: (f: any) => any;
  companie: object;
  id: string;
}

class BasicDataCompaniesContainer extends React.Component<Props, void> {
  componentDidMount() {
    const { id } = this.props;
    if (id) {
      this.props.getCompanie(id);
    }
  }

  render() {
    return (
      <div className="p-0 m-0 w-100 h-100">
        <BasicDataCompaniesForm
          companie={this.props.companie}
          createCompanie={this.props.createCompanie}
          updateCompanie={this.props.updateCompanie}
          onClickSave={this.props.onClickSave}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createCompanie: (companie: object) => {
    return dispatch(companiesActions.createCompanie(companie));
  },
  getCompanie: (id: string) => {
    dispatch(companiesActions.getCompanie(id));
  },
  updateCompanie: (id: string, companie: object) => {
    return dispatch(companiesActions.updateCompanie(id, companie));
  }
});

const mapStateToProps = state => {
  const { companies } = state;
  return {
    companie: companies.companie
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasicDataCompaniesContainer);
