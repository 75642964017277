import React, { Component } from "react";
import s from "./styles.scss";
import { Container, Row, Col, UncontrolledTooltip, Button } from "reactstrap";
import ReactTable from "react-table";
import columns from "./columns";
import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Router, Styled } from "../../../toolkit/decorators/Artifact";
import {
  postSatCancelacionesConfirmar,
  postUploadSatCancelaciones
} from "../../actions/notaCredito";
import ConfirmModal from "../common/confirmModal/confirmModal";

interface Props {}

interface State {
  facturas: any[];
  wait: boolean;
  applying: boolean;
  confirm: boolean;
}

@Router()
@Styled(s)
export default class SatCancelacionesPage extends Component<Props, State> {
  state = {
    facturas: [],
    wait: false,
    applying: false,
    confirm: false
  };

  handleFileChange = async evt => {
    evt.preventDefault();

    if (!evt || !evt.target || !evt.target.files || !evt.target.files.length) {
      errorNotification("Debe seleccionar un archivo");
      return;
    }

    infoNotification("Cargando archivo...");

    this.setState({
      wait: true
    });

    postUploadSatCancelaciones(evt.target.files[0])
      .then(response => {
        this.setState({
          facturas: response.data,
          wait: false
        });
      })
      .catch(err => {
        errorNotification(
          err && err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "No se pudo cargar el archivo"
        );

        this.setState({
          facturas: [],
          wait: false
        });
      });
  };

  submit = evt => {
    evt.preventDefault();
    this.setState({
      confirm: true
    });
  };

  onConfirm = () => {
    this.setState({
      confirm: false,
      applying: true
    });

    infoNotification("Registrando facturas...");

    postSatCancelacionesConfirmar(this.state.facturas)
      .then(response => {
        successNotification(response.data.message);

        this.setState({
          applying: false,
          facturas: []
        });
      })
      .catch(err => {
        errorNotification(
          err && err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "No se pudo procesar el archivo"
        );

        this.setState({
          applying: false
        });
      });
  };

  render() {
    return (
      <Container className="mt-3 p-0">
        <ConfirmModal
          title="Confirmar actualización de fechas"
          message="Se actualizarán las fechas acorde a las reportadas por el SAT.  ¿Desea continuar?"
          isOpen={this.state.confirm}
          size="md"
          ok={this.onConfirm}
          cancel={() => {
            this.setState({
              confirm: false
            });
          }}
        />

        {/* Filtros */}
        <Row className="mt-4">
          {/* Selector de archivo */}
          <Col md="4">
            <Button
              type="button"
              className={`${s.primaryButton} mt-4 ${s.fileButton}`}
              size="sm"
              block
              onClick={(evt: any) => {
                if (this.state.wait) {
                  evt.preventDefault();
                  evt.stopPropagation();
                  return false;
                }

                return true;
              }}
            >
              {this.state.wait ? (
                <FontAwesomeIcon icon={faSpinner} pulse />
              ) : (
                "Seleccionar archivo"
              )}
              <input
                className={s.inputFile}
                type="file"
                id={`scannedFile`}
                accept=".xlsx"
                title=""
                onChange={this.handleFileChange}
              />
            </Button>
          </Col>
          <Col md="4" className="mt-4">
            <a
              href="https://docs.google.com/spreadsheets/d/1jAoKeW3zzXOz-yjgiHOlnIUxdajHXHsF/edit?usp=drive_link&ouid=107699402348587484019&rtpof=true&sd=true"
              target="blank"
            >
              Ver formato requerido
            </a>
          </Col>
        </Row>

        <ReactTable
          className="mt-5 -striped -highlight"
          data={this.state.facturas || []}
          columns={columns}
          sortable={false}
          resizable={true}
          pageSize={
            this.state.facturas && this.state.facturas.length > 5
              ? this.state.facturas.length
              : 5
          }
          showPagination={false}
          loading={this.state.wait}
        />

        <Row>
          <Col md="8">&nbsp;</Col>

          {/* Botón de carga */}
          <div hidden={this.state.facturas.length == 0} className="col-4">
            <Button
              className={`${s.primaryButton} mt-4`}
              type="button"
              size="sm"
              block
              onClick={this.submit}
              disabled={this.state.applying}
            >
              {this.state.applying ? "Registrando facturas..." : "Confirmar"}
            </Button>
          </div>
        </Row>
      </Container>
    );
  }
}
