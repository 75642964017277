import React from "react";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import LayoutStyles from "../../../toolkit/baseStyles";
import LineaPagoPublico from "../../components/lineaPagoPublico";

interface Props {}

export default class LineaPagoPublicoPage extends React.Component<Props> {
  render() {
    return (
      <LayoutStyles>
        <ToastContainer style={{ fontSize: "0.8rem" }} />
        <main>
          <Helmet title="Línea de pago" />
          <LineaPagoPublico />
        </main>
      </LayoutStyles>
    );
  }
}
