import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import * as personsActions from "../../actions/persons";
import * as catalogActions from "../../actions/catalogs";
import DealershipEnvironment from "../../components/dealershipEnvironment";
import IAction from "../../../types/IAction";

interface Props {
  getClubs: () => void;
  getDealers: (name: string, page: string, size: string) => IAction;
  getClubDealer: (personaId: string) => IAction;
  addDealers: (body: object) => IAction;
  updateClubDealer: (personaId: string, body: object) => IAction;
  persons: any;
  clubsDealer: any;
  clubs: any;
  objAddDealers: any;
  updateClubsDealer: any;
}

interface State {}

class Dealers extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    this.props.getClubs();
  }

  render() {
    return (
      <main>
        <Helmet title="Shift delivery" />
        <Layout>
          <DealershipEnvironment
            getDealers={this.props.getDealers}
            getClubDealer={this.props.getClubDealer}
            addDealers={this.props.addDealers}
            updateClubDealer={this.props.updateClubDealer}
            persons={this.props.persons}
            clubsDealer={this.props.clubsDealer}
            clubs={this.props.clubs}
            objAddDealers={this.props.objAddDealers}
            updateClubsDealer={this.props.updateClubsDealer}
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({ catalogs, persons }) => {
  return {
    clubs: catalogs.clubs,
    persons: persons.listDealers,
    clubsDealer: persons.listClubsDealer,
    objAddDealers: persons.objAddDealers,
    updateClubsDealer: persons.updateClubsDealer
  };
};

const mapDispatchToProps = dispatch => ({
  getClubs: () => {
    dispatch(catalogActions.getClubs());
  },
  getDealers: (name: string, page: string, size: string) => {
    return dispatch(personsActions.getDealers(name, page, size));
  },
  getClubDealer: (personaId: string) => {
    return dispatch(personsActions.getClubDealer(personaId));
  },
  addDealers: (body: object) => {
    return dispatch(personsActions.addDealers(body));
  },
  updateClubDealer: (personaId: string, body: object) => {
    return dispatch(personsActions.updateClubDealer(personaId, body));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dealers);
