import React from "react";
import ReactTable from "react-table";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";
import s from "./styles.scss";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import { withRouter } from "react-router-dom";

interface Props {
  listQuizzes: object;
  toActionsQuiz: any;
}

interface State {}

class QuizzesSection extends React.Component<Props, State> {
  state = {};

  columns = [
    {
      Header: () => <p className="font-weight-bold">Nombre</p>,
      id: 0,
      accessor: "nombre",
      Cell: row => {
        return <p>{row.original.nombre}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Identificador</p>,
      id: 0,
      accessor: "identificador",
      Cell: row => {
        return <p>{row.original.identificador}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Categoría</p>,
      id: 0,
      accessor: "categoria",
      Cell: row => {
        return <p>{row.original.categoria}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Estatus</p>,
      id: 0,
      accessor: "estatus",
      Cell: row => {
        return <p className={s.parrafo}>{row.original.estatus}</p>;
      }
    },
    {
      Header: () => (
        <div className="w-100 text-center">
          <Button
            color="link"
            className={`${s.innerButton} ${s.primaryLink}`}
            onClick={() =>
              this.props.toActionsQuiz(
                {
                  nombre: "",
                  categoria: "",
                  estatus: "",
                  identificador: "",
                  preguntas: [
                    {
                      orden: "",
                      pregunta: "",
                      valor: "",
                      tipo: "Opinión",
                      categoria: "Demográficos",
                      estatus: "Activo"
                    }
                  ]
                },
                "Agregar"
              )
            }
          >
            <FontAwesomeIcon icon={faPlusCircle} />
          </Button>
        </div>
      ),
      id: -300,
      width: 80,
      accessor: "actions",
      Cell: row => {
        return (
          <div className="w-100 text-center">
            <a
              onClick={() => {
                this.props.toActionsQuiz(row.original, "Editar");
              }}
              className={`${s.editAnchor}`}
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </a>
          </div>
        );
      }
    }
  ];

  render() {
    return (
      <ReactTable
        manual
        data={this.props.listQuizzes}
        columns={this.columns}
        className={`${s.tablaDatos} mt-5 -striped -highlight w-100`}
        pageSize={10}
      />
    );
  }
}

export default withRouter(withStyles(s)(QuizzesSection));
