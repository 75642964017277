import React from "react";
import ITypeSelect from "../../baseForms2/iTypeSelect/ITypeSelect";
import { ReduxConnect } from "../../decorators/Artifact";
import { iEmpresaConvenioSelectorDispatcher } from "./IEmpresaConvenioSelector.dispatcher";
import IEmpresaConvenioSelectorProps from "./IEmpresaConvenioSelector.props";
import IEmpresaConvenioSelectorState, {
  EmpresaOption
} from "./IEmpresaConvenioSelector.state";
import { iEmpresaConvenioSelectorStore } from "./IEmpresaConvenioSelector.store";

@ReduxConnect(iEmpresaConvenioSelectorStore, iEmpresaConvenioSelectorDispatcher)
export default class IEmpresaConvenioSelectorConnected extends React.Component<
  IEmpresaConvenioSelectorProps,
  IEmpresaConvenioSelectorState
> {
  state = {
    empresaOptions: null as EmpresaOption[],
    options: null,
    value: null
  };

  async componentDidMount(): Promise<void> {
    this.props.todas
      ? await this.findEmpresas()
      : await this.findEmpresasParaManenimiento();
  }

  async componentDidUpdate(prevProps): Promise<void> {
    const changeTodas = prevProps.todas !== this.props.todas;
    const changeIdClub = prevProps.idClub !== this.props.idClub;
    const changeIdMantenimiento =
      prevProps.idMantenimiento !== this.props.idMantenimiento;

    if (changeTodas && this.props.todas) {
      this.setState({ value: null });
      await this.findEmpresas();
    } else if (changeIdClub || changeIdMantenimiento || changeTodas) {
      this.setState({ value: null });
      this.findEmpresasParaManenimiento();
    }
  }

  findEmpresas = async (): Promise<EmpresaOption[]> => {
    const preview = await this.props.getEmpresasConvenio();

    if (preview.fulfilled) {
      const options: EmpresaOption[] = preview.empresasConvenio.map(item => ({
        value: this.props.ignorarConvenio
          ? item.empresaId
          : parseEmpresaConvenioSelectorValue(item.empresaId, item.convenioId),
        label: item.empresa
      }));

      this.setState({ empresaOptions: options });
      return options;
    }

    return [];
  };

  findEmpresasParaManenimiento = async (): Promise<EmpresaOption[]> => {
    if (!this.props.idClub || !this.props.idMantenimiento) {
      return [];
    }

    const preview = await this.props.getEmpresasActivas(
      this.props.idClub,
      this.props.idMantenimiento
    );

    if (preview.fulfilled) {
      const options: EmpresaOption[] = preview.empresasActivas.map(item => ({
        value: this.props.ignorarConvenio
          ? item.empresaId
          : parseEmpresaConvenioSelectorValue(item.empresaId, item.convenioId),
        label: item.empresa
      }));

      this.setState({ empresaOptions: options });
    }

    return [];
  };

  render() {
    return (
      <ITypeSelect
        {...(this.props as any)}
        data={this.state.empresaOptions}
        isLoading={
          this.props.empresasConvenioLoading ||
          this.props.empresasActivasLoading
        }
      />
    );
  }
}

export function parseEmpresaConvenio(value: string): { idEmpresa; idConvenio } {
  if (!value || !value.includes("-")) {
    return { idEmpresa: null, idConvenio: null };
  }

  const [idEmpresa, idConvenio] = value.split("-");

  return {
    idEmpresa: idEmpresa != "0" ? idEmpresa : null,
    idConvenio: idConvenio != "0" ? idConvenio : null
  };
}

export function parseEmpresaConvenioSelectorValue(
  idEmpresa,
  idConvenio
): string {
  return `${idEmpresa || 0}-${idConvenio || 0}`;
}
