import React from "react";
import { connect } from "react-redux";
import Layout from "../../components/layout";
import { Helmet } from "react-helmet";
import SurveyFollowUp from "../../components/surveyFollowUp";
import { saveSurvey, getTokenStatus } from "../../actions/demans";

interface Props {
  saveSurvey: (survey: object) => any;
  surveySuccessfullyCreated: boolean;
  getTokenStatus: (token: string) => any;
}

interface State {
  club: object;
}

class SurveyFollowUpContainer extends React.Component<Props, State> {
  render() {
    return (
      <main>
        <Layout>
          <Helmet title="Peticiones y Quejas" />
          <SurveyFollowUp
            surveySuccessfullyCreated={this.props.surveySuccessfullyCreated}
            saveSurvey={this.props.saveSurvey}
            getTokenStatus={this.props.getTokenStatus}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  saveSurvey: (demand: object) => {
    return dispatch(saveSurvey(demand));
  },
  getTokenStatus: (token: string) => {
    return dispatch(getTokenStatus(token));
  }
});

const mapStateToProps = state => {
  const {
    demands: { surveySuccessfullyCreated }
  } = state;
  return {
    surveySuccessfullyCreated
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyFollowUpContainer);
