import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import * as reportes from "../../actions/reports";
import Layout from "../../components/layout";
import IAction from "../../../types/IAction";
import UnbilledSalesReport from "../../components/unbilledSalesReport";
import { Club } from "../../../types-business/Clubs";
import { getClubsRequest } from "../../actions/catalogs";
import { handleRequestError } from "../../../utils/helpers";

interface Props {
  downloadReportSalesNotBilled: (
    clubId: string,
    fechaInicio: string,
    fechaFin: string
  ) => IAction;
  userContext: { clubSessionId: any };
}

interface State {
  clubs: Club[];
}

class UnbilledSales extends React.Component<Props, State> {
  state = {
    tipoReporte: [
      {
        tipoReporteId: "FormaPago",
        tipoReporte: "Forma de pago"
      },
      {
        tipoReporteId: "CuentaContable",
        tipoReporte: "Cuenta contable"
      }
    ],
    clubs: null
  };

  componentDidMount() {
    this.getClubsMigradoActivo();
  }

  getClubsMigradoActivo = () => {
    getClubsRequest()
      .then(({ data }) => {
        this.setState({ clubs: (data || []).filter(x => !!x.migradoCrm2) });
      })
      .catch(handleRequestError);
  };

  render() {
    return (
      <main>
        <Helmet title="Bills" />
        <Layout>
          <UnbilledSalesReport
            clubs={this.state.clubs || []}
            downloadReportSalesNotBilled={
              this.props.downloadReportSalesNotBilled
            }
            userContext={this.props.userContext}
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    userContext: user.userContext
  };
};

const mapDispatchToProps = dispatch => ({
  downloadReportSalesNotBilled: (clubId, fechaInicio, fechaFin) => {
    dispatch(
      reportes.downloadReportSalesNotBilled(clubId, fechaInicio, fechaFin)
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnbilledSales);
