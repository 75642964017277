import axios from "axios";
import actionTypes from "../actionTypes";
import constants from "../../utils/constants";

const REFERRED_CODES_URL = "/api/referred-codes";

export const updateClubsReferredCodes = (clubs: object[]): any => {
  return {
    type: actionTypes.UPDATE_REFERRED_CODES_CLUBS,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${REFERRED_CODES_URL}/clubs`,
        clubs,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const updateMaintenancesReferredCodes = (
  maintenances: object[]
): any => {
  return {
    type: actionTypes.UPDATE_REFERRED_CODES_MAINTENANCES,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${REFERRED_CODES_URL}/maintenances`,
        maintenances,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getClubsReferredCodes = (): any => {
  return {
    type: actionTypes.GET_REFERRED_CODES_CLUBS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${REFERRED_CODES_URL}/clubs`, {
        withCredentials: true
      })
    }
  };
};

export const getMaintenancesReferredCodes = (): any => {
  return {
    type: actionTypes.GET_REFERRED_CODES_MAINTENANCES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${REFERRED_CODES_URL}/maintenances`,
        {
          withCredentials: true
        }
      )
    }
  };
};
