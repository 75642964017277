import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";
import { QueryArguments } from "../../types-business/QueryArguments";
import FileDownload from "js-file-download";
import { errorNotification } from "../../utils/notifications";

const baseUrl = `/api`;

export const getRoles = (params?: QueryArguments): IAction => {
  return {
    type: actionTypes.GET_ROLES,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/roles`, {
        withCredentials: true,
        params: { ...params, ...constants.DEFAULT_SORTING_PARAMS }
      })
    }
  };
};

export const getReporteRolesPermisos = (callback: any) => {
  return axios
    .get(`${constants.BASE_URL}${baseUrl}/roles/reportes/permisos`, {
      withCredentials: true,
      responseType: "blob"
    })
    .then(({ data }) => {
      FileDownload(data, `ReporteRolesPermisos.csv`);
      callback && callback();
    })
    .catch(error => {
      errorNotification("No se pudo descargar el reporte");
      callback && callback(error);
    });
};

export const getReporteRolesPermisosIndividuales = (callback: any) => {
  return axios
    .get(
      `${constants.BASE_URL}${baseUrl}/roles/reportes/permisos-individuales`,
      {
        withCredentials: true,
        responseType: "blob"
      }
    )
    .then(({ data }) => {
      FileDownload(data, `ReportePermisosIndividuales.csv`);
      callback && callback();
    })
    .catch(error => {
      errorNotification("No se pudo descargar el reporte");
      callback && callback(error);
    });
};
