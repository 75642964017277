import React from "react";
import s from "./styles.scss";
import { Styled } from "../../../toolkit/decorators/Artifact";
import { Row, Col, Button, UncontrolledTooltip, Container } from "reactstrap";
import { errorNotification } from "../../../utils/notifications";
import { downloadReactivaciones } from "../../actions/reports";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "../common/DatePicker";
import moment from "moment";

interface Props {}

interface State {
  fechaInicio: any;
  fechaFin: any;
  wait: boolean;
}

@Styled(s)
export default class AdeudosComponent extends React.Component<Props, State> {
  state = {
    fechaInicio: null,
    fechaFin: null,
    wait: false
  };

  submit = async evt => {
    evt.preventDefault();

    if (!this.state.fechaInicio) {
      errorNotification("Por favor, seleccione la fecha de inicio de búsqueda");
      return;
    } else if (!this.state.fechaFin) {
      errorNotification("Por favor, seleccione la fecha final de búsqueda");
      return;
    }

    this.setState({
      wait: true
    });

    downloadReactivaciones(this.state.fechaInicio, this.state.fechaFin, () => {
      this.setState({
        wait: false
      });
    });
  };

  render() {
    return (
      <Container className="mt-3 p-0">
        {/* Filtros */}
        <form onSubmit={this.submit} autoComplete="off">
          <Row className="mt-3">
            {/* Fecha de inicio */}
            <div className="col-2 mb-2">
              <div
                style={{
                  display: "inline-block",
                  width: "calc(100% - 24px)"
                }}
              >
                <DatePicker
                  id="fechaInicio"
                  name="fechaInicio"
                  label="* Fecha inicio"
                  selected={this.state.fechaInicio}
                  onChange={e =>
                    this.setState({
                      fechaInicio: moment(e).format("YYYY-MM-DD")
                    })
                  }
                />
              </div>
              <UncontrolledTooltip placement="top" target="fechaInicioBorrar">
                Borrar fecha
              </UncontrolledTooltip>
              <div
                id="fechaInicioBorrar"
                onClick={e => {
                  e.preventDefault();
                  this.setState({ fechaInicio: null });
                }}
                style={{
                  cursor: "pointer",
                  textAlign: "right",
                  display: "inline-block",
                  width: "24px"
                }}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </div>
            </div>

            {/* Fecha de fin */}
            <div className="col-2 mb-2">
              <div
                style={{
                  display: "inline-block",
                  width: "calc(100% - 24px)"
                }}
              >
                <DatePicker
                  id="fechaFin"
                  name="fechaFin"
                  label="* Fecha fin"
                  selected={this.state.fechaFin}
                  onChange={e =>
                    this.setState({ fechaFin: moment(e).format("YYYY-MM-DD") })
                  }
                />
              </div>
              <UncontrolledTooltip placement="top" target="fechaFinBorrar">
                Borrar fecha
              </UncontrolledTooltip>
              <div
                id="fechaFinBorrar"
                onClick={e => {
                  e.preventDefault();
                  this.setState({ fechaFin: null });
                }}
                style={{
                  cursor: "pointer",
                  textAlign: "right",
                  display: "inline-block",
                  width: "24px"
                }}
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </div>
            </div>

            {/* Botón de búsqueda */}
            <Col md={2} className="p-0">
              <Button
                className={`${s.primaryButton} mt-4`}
                type="submit"
                size="sm"
                disabled={this.state.wait}
                block
              >
                {this.state.wait ? "Descargando..." : "Descargar"}
              </Button>
            </Col>
          </Row>
        </form>
      </Container>
    );
  }
}
