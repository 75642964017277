import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import ModalFrame from "../../../../toolkit/modalFrame";
import { Button, Container, Row, Col } from "reactstrap";
import s from "./confirmModals.scss";
import BaseNumericInput from "../../../../toolkit/baseNumericInput";
import BaseInput from "../../../../toolkit/baseInput";
import TextWithLineBreaks from "../TextWithLineBreaks";

interface Props {
  isOpen: boolean;
  title: String;
  message: String;
  requireInput: boolean;
  inputType: string;
  inputTitle: string;
  waiting: boolean;
  options: any[];
  size: String;
  currentValue: any;
  ok: (value: any) => void;
  cancel: () => void;
}

class ConfirmModal extends React.Component<Props> {
  state = {
    value: null
  };

  render() {
    const val = this.state.value || this.props.currentValue;

    return (
      <ModalFrame
        title={this.props.title}
        isOpen={this.props.isOpen}
        toggle={() => this.props.cancel()}
        size={this.props.size || "sm"}
      >
        <Container style={{ width: "90%" }} className="center-block p-0 mb-3">
          <TextWithLineBreaks text={this.props.message} />

          {this.props.requireInput && this.props.inputType === "numeric" && (
            <div>
              <BaseNumericInput
                type="text"
                id="iiValue"
                name="iiValue"
                label={this.props.inputTitle || ""}
                placeholder={""}
                value={this.state.value || this.props.currentValue || null}
                onChange={e => {
                  const value = e.target.value;
                  this.setState({ value });
                }}
              />
            </div>
          )}

          {this.props.requireInput && this.props.inputType === "alphanumeric" && (
            <div>
              <BaseInput
                type="text"
                id="iiValue"
                name="iiValue"
                label={this.props.inputTitle || ""}
                placeholder={""}
                value={this.state.value || this.props.currentValue || null}
                onChange={e => {
                  const value = e.target.value;
                  this.setState({ value });
                }}
              />
            </div>
          )}

          {this.props.requireInput && this.props.inputType === "select" && (
            <div>
              <BaseInput
                type="select"
                id="iiValue"
                name="iiValue"
                label={this.props.inputTitle || ""}
                value={this.state.value || this.props.currentValue || null}
                onChange={e => {
                  const value = e.target.value;
                  this.setState({ value });
                }}
                options={this.props.options || []}
              />
            </div>
          )}

          <Row className="m-0">
            <Col xs="12" md="6">
              <Button
                className={`${s.primaryButton} mt-3`}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  const { value } = this.state;
                  this.props.ok(value);
                  this.setState({ value: null });
                }}
                block
                disabled={
                  !!this.props.waiting || (!!this.props.requireInput && !val)
                }
              >
                Aceptar
              </Button>
            </Col>
            <Col xs="12" md="6">
              <Button
                className={`${s.secundaryButton} mt-3`}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({ value: null });
                  this.props.cancel();
                }}
                block
                disabled={!!this.props.waiting}
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </Container>
      </ModalFrame>
    );
  }
}

export default withStyles(s)(ConfirmModal);
