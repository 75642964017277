import IAction from "../../types/IAction";
import { UserContext } from "../../types-business/Profile";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";
import * as localStorageHelper from "./localStorage";
import { executeCatalogWithLocalStorage } from "./catalogs";
import { STORED_TYPE } from "./localStorage";

export const getFunctionalityTree = () => {
  return {
    type: actionTypes.FUNCTIONALITY_TREE,
    payload: {
      promise: axios.get(`${constants.BASE_URL}/api/auth/functionality-tree`, {
        withCredentials: true
      })
    }
  };
};

export const getFunctionalityTreeRequest = () => {
  return executeCatalogWithLocalStorage({
    localStorageKey: "functionalityTree",
    localStorageType: STORED_TYPE.OBJECT,
    url: `${constants.BASE_URL}/api/auth/functionality-tree`,
    config: { withCredentials: true }
  });
};

export const getPermissionsTree = () => {
  return {
    type: actionTypes.PERMISSION_TREE,
    payload: {
      promise: axios.get(`${constants.BASE_URL}/api/auth/permissions-tree`, {
        withCredentials: true
      })
    }
  };
};

export const getRoleById = (roleId: string) => {
  return {
    type: actionTypes.GET_ROLE_BY_ID,
    payload: {
      promise: axios.get(`${constants.BASE_URL}/api/auth/roles/${roleId}`, {
        withCredentials: true
      })
    }
  };
};

export const setUserContext = (userContext: UserContext): IAction => ({
  type: actionTypes.SET_USER_CONTEXT,
  payload: userContext
});

export const whoami = (): IAction => {
  return {
    type: actionTypes.WHO_AM_I,
    payload: {
      promise: axios.get(`${constants.BASE_URL}/api/auth/me`, {
        withCredentials: true
      })
    }
  };
};

export const loginUser = (userName: string, password: string): IAction => {
  return {
    type: actionTypes.GET_USER_SSP,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}/api/auth/get-security-spaces`,
        {
          userName,
          password
        }
      )
    }
  };
};

export const loginUserSSP = (
  userName: string,
  password: string,
  ssp: any
): IAction => {
  localStorageHelper.clear();
  localStorage.setItem("club", ssp.externalId);
  return {
    type: actionTypes.LOGIN_USER_SSP,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}/api/auth/login`,
        {
          userName,
          password,
          ssp
        },
        {
          withCredentials: true
        }
      )
    }
  };
};

export const logoutUser = (): IAction => {
  localStorageHelper.clear();
  return {
    type: actionTypes.LOGOUT_USER,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}/api/auth/logout`,
        {},
        {
          withCredentials: true
        }
      )
    }
  };
};

export const saveRole = (group: object, permissions: object[]): IAction => {
  return {
    type: actionTypes.SAVE_ROLE,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}/api/auth/roles`,
        {
          group,
          permissions
        },
        {
          withCredentials: true
        }
      )
    }
  };
};

export const updateRole = (
  roleId: string,
  group: object,
  permissions: object[]
): IAction => {
  return {
    type: actionTypes.SAVE_ROLE,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}/api/auth/roles/${roleId}`,
        {
          group,
          permissions
        },
        {
          withCredentials: true
        }
      )
    }
  };
};

export const cleanState = (): IAction => {
  return {
    type: actionTypes.CLEAN_GLOBAL_STATE
  };
};

export const restartApp = (): IAction => {
  return {
    type: actionTypes.RESTART_APP
  };
};
