import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";

const GOALS_BASE_URL = "/api/goals";

export const saveGoals = (goals: object[]) => {
  return {
    type: actionTypes.SAVE_GOALS,
    payload: {
      promise: axios.put(`${constants.BASE_URL}${GOALS_BASE_URL}`, goals, {
        withCredentials: true
      })
    }
  };
};

export const getGoals = (params: object) => {
  return {
    type: actionTypes.GET_GOALS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${GOALS_BASE_URL}`, {
        withCredentials: true,
        params: { ...params }
      })
    }
  };
};
