import React from "react";

export interface FILTERS {
  clubId: number;
  periodoId: number;
  tipoComision: string;
  estatusComision: string;
  vendedorId?: number;
}

export interface FILTERS_CATALOGS {
  clubs: any;
  periodos: any;
  tipoComision?: any;
  estatusComision?: any;
}

export const TIPOS_COMISION = [
  {
    value: "VENTA_DE_MEMBRESIA",
    label: "Membresía"
  },
  {
    value: "REACTIVACION",
    label: "Reactivación"
  },
  {
    value: "MANTENIMIENTO_ANUAL_MSI",
    label: "Anualidad"
  }
];

export const ESTATUS_COMISION = [
  {
    value: "PREAUTORIZADA",
    label: "Esperando fecha de cierre"
  },
  {
    value: "AUTORIZADA",
    label: "En proceso de pago"
  }
];

export const TIPOS_COMISION_SERIAL = {
  VENTA_DE_MEMBRESIA: 0,
  REACTIVACION: 3,
  MANTENIMIENTO_ANUAL_MSI: 10
};

const DANGER_COLOR = "#A00";
const WARNING_COLOR = "#CC0";
const SUCCESS_COLOR = "#080";
export const FLUJO_COMISION = {
  NO_APLICA: fieldWithColor("Pagada o por pagar", SUCCESS_COLOR),
  FACTURADO: fieldWithColor("Facturado", DANGER_COLOR),
  FALTA_DOCUMENTACION: fieldWithColor("Exp. incompleto", DANGER_COLOR),
  FALTA_TOKENIZACION: fieldWithColor("Sin tokenización", DANGER_COLOR),
  FALTA_DOCUMENTO_CAT: fieldWithColor("Sin documento CAT", DANGER_COLOR),
  CAT_ACTIVO: fieldWithColor("50% - Esperando Mtto", WARNING_COLOR),
  SIN_CAT: fieldWithColor("50% - Esperando Mtto", WARNING_COLOR),
  MANTENIMIENTO: fieldWithColor("Pagada o por pagar", SUCCESS_COLOR),
  ANUALIDAD: fieldWithColor("Pagada o por pagar", SUCCESS_COLOR),
  CLUB_SIN_APERTURA: fieldWithColor("Pagada o por pagar", SUCCESS_COLOR)
};

function fieldWithColor(content: string, color: string) {
  return <span style={{ color }}>{content}</span>;
}
