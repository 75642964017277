import React from "react";
import { connect } from "react-redux";
import Layout from "../../components/layout";
import { Helmet } from "react-helmet";
import ComissionsProductPage from "../../components/comissionsProduct";
import {
  getComissionsProduct,
  getPastPeriods,
  authorizeComissions
} from "../../actions/reports";
import { getClubs, getSellers } from "../../actions/catalogs";
import { FILTERS } from "../../components/comissionsProduct/interfaces";
import moment from "moment";

const PAST_PERIODS_TO_GET = 12;

interface Props {
  clubs: object;
  periods: object;
  reportComissions: object[];
  sellers: object[];
  userContext: any;
  getClubs: () => any;
  getPastPeriods: (limite: string, cantidad: number) => any;
  getSellers: () => any;
  getComissionsReport: (filters: FILTERS) => void;
  authorizeComissions: (comissionsList: object) => void;
}

interface State {}

class ComissionsProduct extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    this.props.getClubs();
    this.props.getPastPeriods(
      moment()
        .add(20, "days")
        .format("YYYY-MM-DD"),
      PAST_PERIODS_TO_GET
    );
    this.props.getSellers();
  }

  componentDidUpdate() {}

  render() {
    return (
      <main>
        <Helmet title="Creacion de Esquema de Beneficios" />
        <Layout>
          {this.props.clubs && this.props.periods && (
            <ComissionsProductPage
              // @ts-ignore
              fetchSearchData={this.props.getComissionsReport}
              clubs={this.props.clubs}
              periods={this.props.periods}
              reportComissions={this.props.reportComissions}
              sellers={this.props.sellers}
              authorizeComissions={this.props.authorizeComissions}
              userContext={this.props.userContext}
            />
          )}
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getClubs: () => {
    return dispatch(getClubs());
  },
  getPastPeriods: (limite, cantidad) => {
    return dispatch(getPastPeriods(limite, cantidad));
  },
  getComissionsReport: (filters: FILTERS) => {
    return dispatch(getComissionsProduct(filters));
  },
  getSellers: () => {
    return dispatch(getSellers());
  },
  authorizeComissions: (comissionsList: object) => {
    return dispatch(authorizeComissions(comissionsList));
  }
});

const mapStateToProps = state => {
  const {
    catalogs: { clubs, sellers },
    periods: { periods },
    reports: { reportComissions, loadingReport },
    user: { userContext }
  } = state;
  return {
    clubs,
    periods,
    sellers,
    reportComissions,
    loadingReport,
    userContext
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComissionsProduct);
