import { faArrowRight, faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import { Button, Col, Container, Row } from "reactstrap";
import BaseButtonWithIconAndTooltip from "../../../toolkit/baseButtonWithIconAndTooltip";
import { handleRequestError } from "../../../utils/helpers";
import {
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import { getReportAccrualProcessLinesSent } from "../../actions/reports";
import MexicanCurrency from "../common/currency";
import {
  PropsAccrualProcesses,
  StateAccrualProcesses,
  getContextualStatusClass
} from "./interfaces";
import s from "./styles.scss";

class TableReportAccrualProcesses extends React.Component<
  PropsAccrualProcesses,
  StateAccrualProcesses
> {
  state = {
    onSearch: false,
    processId: null,
    dataLines: []
  };

  //COLUMN DEFINITION
  columnsAccrualProcesses = [
    {
      Header: () => <p className="font-weight-bold">Club</p>,
      id: 0,
      className: "text-center",
      accessor: "clubNombre"
    },
    {
      Header: () => <p className="font-weight-bold">Fecha</p>,
      id: 1,
      className: "text-center",
      accessor: "fecha"
    },
    {
      Header: () => <p className="font-weight-bold"></p>,
      id: 2,
      class: "text-center",
      Cell: row => {
        return (
          <BaseButtonWithIconAndTooltip
            id={`process-${row.original.id}`}
            icon={faArrowRight}
            tooltipTitle="Seleccionar"
            onClick={() => {
              //Se selecciona el proceso
              this.props.selectProcess(row.original.id);
              //Se consultan los datos de las lineas enviadas
              this.setState({ processId: row.original.id }, () => {
                this.search();
              });
            }}
          />
        );
      }
    }
  ];

  columnsAccrualProcessLines = [
    {
      Header: () => <p className="font-weight-bold">Club</p>,
      className: "text-center",
      accessor: "clubNombre"
    },
    {
      Header: () => <p className="font-weight-bold">Movimiento</p>,
      className: "text-center",
      accessor: "movimientoId"
    },
    {
      Header: () => <p className="font-weight-bold">Folio</p>,
      className: "text-center",
      accessor: "ventaFolio"
    },
    {
      Header: () => <p className="font-weight-bold">UUID</p>,
      className: "text-center",
      accessor: "ventaUuid"
    },
    {
      Header: () => <p className="font-weight-bold">monto</p>,
      className: "text-center",
      accessor: "monto",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.monto} />;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Fecha</p>,
      className: "text-center",
      accessor: "fechaLinea"
    }
  ];

  //CONTROL BEHAVIOR

  search = () => {
    infoNotification("Consultando datos...");

    const { processId } = this.state;

    this.setState({ onSearch: true, dataLines: [] });

    getReportAccrualProcessLinesSent(processId)
      .then(({ data }) => {
        this.setState({ dataLines: data, onSearch: false });
        successNotification("Datos consultados correctamente...");
      })
      .catch(handleRequestError);
  };

  downloadReportAccrualProcessLinesSent = () => {
    const { processId } = this.state;
    this.props.downloadReportAccrualProcessLinesSent(processId);
  };

  //RENDER
  render() {
    return (
      <Container className="p-0 mt-5">
        {!!(this.state.dataLines || []).length && (
          <Row className={"mt-5"}>
            <Col sm="6">
              Resultados de la búsqueda:{" "}
              <b
                style={{ fontWeight: "bolder", display: "inline-block" }}
                className="ml-3"
              >
                {(this.state.dataLines || []).length}
              </b>
            </Col>
            <Col sm="6" className="text-right">
              <span>Exportar a CSV: </span>
              <Button
                id="btnReportExportCsv"
                color="link"
                onClick={() => {
                  this.downloadReportAccrualProcessLinesSent();
                }}
              >
                <FontAwesomeIcon icon={faFileExport} color="black" />
              </Button>
            </Col>
          </Row>
        )}

        <Row>
          <Col xs={12} md={3} lg={3}>
            <p className="primaryTitle w-100">Procesos</p>
            <ReactTable
              data={this.props.data}
              columns={this.columnsAccrualProcesses}
              className="mt-2"
              sortable={false}
              resizable={false}
              pageSize={20}
              loading={this.props.onSearch}
              getTrProps={(_, process) => {
                return !!process
                  ? { className: getContextualStatusClass(process.original) }
                  : {};
              }}
            />
          </Col>
          <Col xs={12} md={9} lg={9}>
            <p className="primaryTitle w-100">Líneas enviadas</p>
            <ReactTable
              data={this.state.dataLines}
              columns={this.columnsAccrualProcessLines}
              className="mt-2"
              sortable={false}
              resizable={false}
              pageSize={20}
              loading={this.state.onSearch}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(TableReportAccrualProcesses));
