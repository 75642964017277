import React, { Component } from "react";
import {
  Router,
  RouterProps,
  Styled
} from "../../../toolkit/decorators/Artifact";
import { InvitadosListDispatcher } from "../../containers/invitadosList/InvitadosList.dispatcher";
import { InvitadosListStore } from "../../containers/invitadosList/InvitadosList.store";
import s from "./styles.scss";
import { Container, Form, Button } from "reactstrap";
import FormBuilder from "../../../toolkit/baseForms2/formBuilder/FormBuilder";
import { Inputs, Buttons, formConfig } from "./InvitadosList.form";
import { MemoTable } from "./InvitadosList.table";
import InvitadoDetalle from "../../../types/InvitadoDetalle";
import { deleteEmptyProps } from "../../../toolkit/utils/filter-utils";
import { parseEmpresaConvenio } from "../../../toolkit/baseForms2Commons/iEmpresaConvenioSelector/IEmpresaConvenioSelectorConnected";
import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import { getReporteInvitados } from "../../actions/invitados";
import ConfirmModal from "../common/confirmModal/confirmModal";
import ModalFrame from "../../../toolkit/modalFrame";
import { updatePhotoProm } from "../../actions/persons";
import WebcamCapture from "../common/WebCamCapture";
import BaseInput from "../../../toolkit/baseInput";

interface Props
  extends InvitadosListStore,
    InvitadosListDispatcher,
    RouterProps<any> {}

interface State {
  downloading: boolean;
  filters: Inputs & {
    page: number;
    size: number;
  };

  // Eliminación del invitado
  confirmPopupOpen: boolean;
  confirmPopupId: any;
  confirmPopupTitle: string;
  confirmPopupText: string;

  // Modal de captura de foto
  fotoPopupOpen: boolean;
  fotoPopupId: any;

  // Modal de captura de huella
  huellaPopupOpen: boolean;
  huellaPopupId: any;
  huellaPopupCopied: boolean;
  huellaPopupReady: boolean;
  huella: string;
}

@Router()
@Styled(s)
export default class InvitadosListComponent extends Component<Props, State> {
  state = {
    downloading: false,
    filters: {
      page: 0,
      size: 20
    } as any,
    confirmPopupOpen: false,
    confirmPopupId: null,
    confirmPopupTitle: null,
    confirmPopupText: null,
    fotoPopupOpen: false,
    fotoPopupId: null,
    huellaPopupOpen: false,
    huellaPopupId: null,
    huellaPopupCopied: false,
    huellaPopupReady: false,
    huella: ""
  };

  componentDidMount(): void {
    this.fetchData({} as any);
  }

  submit = (values: any) => {
    // Obtención de la empresa de convenio
    const valuesWithEmpresa = {
      ...values,
      idEmpresa: parseEmpresaConvenio(values.idEmpresa).idEmpresa
    };

    // Búsqueda de datos en página 1
    this.fetchData({ page: 0 } as any, null, valuesWithEmpresa);
  };

  // Este método se invoca por el formulario de paginación, el botón de búsqueda
  // y al inicializar este componente
  fetchData = async (
    { pageSize: tablePageSize, page: tablePage },
    _?: any,
    formFilters?
  ) => {
    const filters = formFilters || this.state.filters;
    const page = tablePage >= 0 ? tablePage : this.state.filters.page;
    const size = tablePageSize >= 0 ? tablePageSize : this.state.filters.size;
    const pageableFilters = deleteEmptyProps({ ...filters, page, size });

    this.setState({ filters: pageableFilters });

    const preview = await this.props.getInvitados(pageableFilters);
    if (!preview.fulfilled) {
      errorNotification(
        preview.gettingInvitadosError || "Error al obtener los invitados"
      );
    }
  };

  onExport = () => {
    this.setState({ downloading: true });
    getReporteInvitados(() => this.setState({ downloading: false }));
  };

  onAddClick = () => {
    this.props.history.push("/invitado-especial");
  };

  onEditClick = idInvitado => {
    this.props.history.push(`/invitado-especial/${idInvitado}`);
  };

  onDeleteClick = idInvitado => {
    this.setState({
      confirmPopupOpen: true,
      confirmPopupId: idInvitado,
      confirmPopupTitle: "Confirme la eliminación",
      confirmPopupText: "¿Está seguro de eliminar el invitado?"
    });
  };

  delete = async () => {
    const idInvitado = this.state.confirmPopupId;
    const preview = await this.props.deleteInvitado(idInvitado);

    if (!preview.fulfilled) {
      errorNotification(
        preview.deletingInvitadoError || "Error al eliminar al invitado"
      );
      return;
    }

    successNotification("Invitado eliminado correctamente");
    this.setState({
      confirmPopupOpen: false,
      confirmPopupId: null
    });
    this.fetchData({} as any);
  };

  onActivacion = async idInvitado => {
    const preview = await this.props.putInvitadoActivar(idInvitado);

    if (!preview.fulfilled) {
      errorNotification(
        preview.activandoInvitadoError || "Error al activar al invitado"
      );
      return;
    }

    this.fetchData({} as any);
  };

  onDesactivacion = async idInvitado => {
    const preview = await this.props.putInvitadoDesactivar(idInvitado);

    if (!preview.fulfilled) {
      errorNotification(
        preview.desactivandoInvitadoError || "Error al desactivar al invitado"
      );
      return;
    }

    this.fetchData({} as any);
  };

  onHuellaClick = idPersona => {
    this.setState({
      huellaPopupOpen: true,
      huellaPopupId: idPersona
    });
  };

  onFotoClick = idPersona => {
    this.setState({
      fotoPopupOpen: true,
      fotoPopupId: idPersona
    });
  };

  onViewPersonaClick = idPersona => {
    window.open(`/invitado/${idPersona}/detalle`, "_blank");
  };

  render() {
    return (
      <Container className="mb-5">
        {/* Eliminación del invitado */}
        <ConfirmModal
          title={this.state.confirmPopupTitle}
          message={this.state.confirmPopupText}
          isOpen={this.state.confirmPopupOpen}
          size="md"
          waiting={this.props.deletingInvitado}
          ok={this.delete}
          cancel={() => {
            this.setState({
              confirmPopupOpen: false
            });
          }}
        />

        {/* Modal de captura de fotografía */}
        <ModalFrame
          title={`${this.state.fotoPopupId} Captura de fotografía`}
          isOpen={this.state.fotoPopupOpen}
          size="lg"
          toggle={() => this.setState({ fotoPopupOpen: false })}
        >
          <Container>
            <WebcamCapture
              personId={this.state.fotoPopupId}
              saveImage={img => {
                infoNotification("Guardando foto...");
                updatePhotoProm(this.state.fotoPopupId, img)
                  .then(() => {
                    successNotification("Foto guardada correctamente.");
                    this.setState({ fotoPopupOpen: false });
                    this.props.getInvitados(this.state.filters);
                  })
                  .catch(() => errorNotification("Error al guardar la foto"));
              }}
            />
          </Container>
        </ModalFrame>

        {/* Modal de captura de huella */}
        <ModalFrame
          title={`${this.state.huellaPopupId} - Actualización de la huella`}
          isOpen={this.state.huellaPopupOpen}
          toggle={() => this.setState({ huellaPopupOpen: false })}
        >
          <Container>
            <div className="row">
              <div className="alert alert-info" style={{ fontSize: "0.8rem" }}>
                Abra la siguiente ruta en Internet Explorer y copie la huella
                generada por favor:
                <br />
                <br />
                <p
                  style={{
                    color: "#007acc",
                    background: "#eee",
                    padding: "5px 2px"
                  }}
                >
                  {`https://crm.sportsworld.com.mx/publico/lecturaHuella/${this.state.huellaPopupId}`}
                </p>
                <p style={{ textAlign: "right" }}>
                  <a
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    hidden={this.state.huellaPopupCopied}
                    onClick={() => {
                      this.setState({ huellaPopupCopied: true });
                      navigator.clipboard.writeText(
                        `https://crm.sportsworld.com.mx/publico/lecturaHuella/${this.state.huellaPopupId}`
                      );
                    }}
                  >
                    Copiar
                  </a>
                  <a
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    hidden={!this.state.huellaPopupCopied}
                    onClick={() => this.setState({ huellaPopupReady: true })}
                  >
                    Listo
                  </a>
                </p>
              </div>
            </div>
            <div className="mb-3" hidden={!this.state.huellaPopupReady}>
              <Form>
                <BaseInput
                  label={"Huella"}
                  name="pawPrint"
                  type="text"
                  id="pawPrint"
                  placeholder="Ingresa la cadena de caracteres de la huella"
                  value={this.state.huella}
                  onChange={evt => this.setState({ huella: evt.target.value })}
                />
                <Button
                  className={`${s.buttonMarginTop} ${s.primaryButton}`}
                  onClick={async evt => {
                    evt.preventDefault();

                    if (!this.state.huella) {
                      errorNotification("Debe ingresar la huella");
                      return;
                    }

                    infoNotification("Guardando huella...");

                    const preview = await this.props.postHuellaInvitado(
                      this.state.huellaPopupId,
                      this.state.huella
                    );

                    if (preview.fulfilled) {
                      successNotification("Huella guardada correctamente.");
                      this.setState({ huellaPopupOpen: false });
                    } else {
                      errorNotification("Error al guardar la huella");
                    }
                  }}
                  size="lg"
                  block
                >
                  Guardar
                </Button>
              </Form>
            </div>
          </Container>
        </ModalFrame>

        <FormBuilder<Inputs, Buttons>
          config={formConfig}
          submit={this.submit}
          processing={
            this.props.gettingInvitados ||
            this.state.downloading ||
            this.props.activandoInvitado ||
            this.props.desactivandoInvitado ||
            this.props.deletingInvitado
          }
        />

        <hr className="row" />

        <MemoTable
          page={this.props.invitados || ({} as Page<InvitadoDetalle>)}
          loading={
            this.props.gettingInvitados ||
            this.state.downloading ||
            this.props.activandoInvitado ||
            this.props.desactivandoInvitado ||
            this.props.deletingInvitado
          }
          onFetchData={this.fetchData}
          onExport={this.onExport}
          onAddClick={this.onAddClick}
          onEditClick={this.onEditClick}
          onDeleteClick={this.onDeleteClick}
          onActivacion={this.onActivacion}
          onDesactivacion={this.onDesactivacion}
          onHuellaClick={this.onHuellaClick}
          onFotoClick={this.onFotoClick}
          onViewPersonaClick={this.onViewPersonaClick}
        />
      </Container>
    );
  }
}
