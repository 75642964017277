import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button, Label, Form } from "reactstrap";
import BaseInput from "../../../../toolkit/baseInput";
import s from "../styles.scss";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import { FILTERS, TIPOS_COMISION, ESTATUS_COMISION } from "../interfaces";

interface Props {
  fetchSearchData: (filters: FILTERS) => void;
  clubs: { clubId: number; nombre: string }[];
  periods: { id: number; nombre: string }[];
  sellers: any[];
  showProccess?: boolean;
  fetchProccess?: (filters: FILTERS) => void;
  userContext: { clubSessionId?: any };
  loadingReport: boolean;
}

interface State extends FILTERS {
  searchEnabled: boolean;
  proccessEnabled: boolean;
}

class ReportFilter extends React.Component<Props, State> {
  state = {
    clubId: this.props.userContext.clubSessionId || -1,
    periodoId: null,
    vendedorId: null,
    estatusComision: null,
    tipoComision: null,
    searchEnabled: false,
    proccessEnabled: false
  };

  filter: FILTERS = {
    clubId: null,
    periodoId: null,
    tipoComision: null,
    estatusComision: null,
    vendedorId: null
  };

  /**
   * Cambio de valor en combos
   * @param e Evento Javascript
   * @param fieldName Nombre del campo seleccionado
   */
  onChangeGenericDrop = async (e, fieldName) => {
    await this.setState(({
      [fieldName]: e.target.value
    } as unknown) as State);
    this.updateFilters();
  };

  /**
   * Cambio de valor en input de vendedor
   * @param selected Vendedores seleccionados (Uno o ninguno permitidos)
   */
  onChangeSeller = async selected => {
    await this.setState({
      vendedorId: selected.length > 0 ? selected[0]["id"] : null
    });
    this.updateFilters();
  };

  /**
   * Cambios en los filtros seleccionados
   */
  updateFilters = () => {
    // Filtros obligatorios para habilitar botones de búsqueda y proceso
    this.setState({
      searchEnabled:
        this.state.clubId > -1 &&
        this.state.periodoId > -1 &&
        this.state.tipoComision &&
        this.state.estatusComision,
      proccessEnabled:
        this.state.clubId > -1 &&
        this.state.periodoId > -1 &&
        this.state.tipoComision
    });

    // Valores admitidos en filtros
    this.filter = {
      clubId: this.state.clubId > -1 ? this.state.clubId : null,
      periodoId: this.state.periodoId > -1 ? this.state.periodoId : null,
      tipoComision: this.state.tipoComision || null,
      estatusComision: this.state.estatusComision || null,
      vendedorId: this.state.vendedorId
    };
  };

  render() {
    return (
      <Container className="mt-3 mb-5 p-0">
        <Form
          onSubmit={e => {
            e.preventDefault();
            this.props.fetchSearchData(this.filter);
          }}
        >
          <Row className="my-4">
            <Col sm="3">
              <BaseInput
                label={"* Club"}
                name="Club"
                type="select"
                id="club"
                size={"sm"}
                value={this.state.clubId}
                disabled={
                  this.props.userContext && this.props.userContext.clubSessionId
                }
                options={[{ value: -1, label: "Seleccionar el club" }].concat(
                  this.props.clubs.map(({ clubId, nombre }) => {
                    return {
                      value: clubId,
                      label: nombre
                    };
                  })
                )}
                onChange={e => this.onChangeGenericDrop(e, "clubId")}
              />
            </Col>
            <Col sm="3">
              <BaseInput
                label={"* Periodo"}
                name="perios"
                type="select"
                id="periodo"
                size={"sm"}
                value={this.state.periodoId}
                options={[
                  { value: -1, label: "Seleccionar el periodo" }
                ].concat(
                  this.props.periods.map(({ id, nombre }) => {
                    return {
                      value: id,
                      label: nombre
                    };
                  })
                )}
                onChange={e => this.onChangeGenericDrop(e, "periodoId")}
              />
            </Col>

            <Col sm="3">
              <BaseInput
                label={"* Tipo de comisión"}
                name="tipoComision"
                type="select"
                id="tipoComision"
                size={"sm"}
                value={this.state.tipoComision}
                options={[{ value: "", label: "Selecciona una opción" }].concat(
                  TIPOS_COMISION.map(({ value, label }) => ({ value, label }))
                )}
                onChange={e => this.onChangeGenericDrop(e, "tipoComision")}
              />
            </Col>

            <Col sm="3">
              <BaseInput
                label={"* Estatus de la comisión"}
                name="estatus"
                type="select"
                id="estatus"
                size={"sm"}
                value={this.state.estatusComision}
                options={[{ value: "", label: "Selecciona una opción" }].concat(
                  ESTATUS_COMISION.map(({ value, label }) => ({ value, label }))
                )}
                onChange={e => this.onChangeGenericDrop(e, "estatusComision")}
              />
            </Col>

            <Col sm="6">
              <Label className={s.label}>Nombre de vendedor</Label>
              <Typeahead
                id={"vendedor"}
                promptText={"Vendedores"}
                bsSize={"sm"}
                onChange={selected => this.onChangeSeller(selected)}
                emptyLabel={"Sin coincidencias"}
                disabled={this.props.sellers ? false : true}
                maxResults={20}
                options={
                  this.props.sellers
                    ? this.props.sellers.map(item => ({
                        id: item.userId,
                        label: `${item.name || ""} ${item.firstSurname ||
                          ""} ${item.secondSurname || ""}`.trim(),
                        seller: item
                      }))
                    : []
                }
              />
            </Col>

            <Col sm="3">
              <label>&nbsp;</label>
              <Button
                size="md"
                block
                disabled={!this.state.searchEnabled}
                type="submit"
              >
                Buscar
              </Button>
            </Col>

            <Col hidden={!this.props.showProccess} sm="3">
              <label>&nbsp;</label>
              <Button
                size="md"
                block
                disabled={!this.state.proccessEnabled}
                onClick={() => this.props.fetchProccess(this.filter)}
              >
                Procesar pagos
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ReportFilter));
