import React from "react";
import MexicanCurrency from "../common/currency/index";

export const columns = [
  {
    Header: () => <p className="font-weight-bold text-right">NO.</p>,
    id: "no",
    width: 30,
    Cell: row => {
      return <p className="text-right"> {row.index + 1} </p>;
    }
  },
  {
    Header: () => <p className="font-weight-bold">ID</p>,
    id: "id",
    width: 120,
    Cell: row => {
      return <p className="text-center"> {row.original.idComision} </p>;
    }
  },
  {
    Header: () => <p className="font-weight-bold text-left">DESCRIPCIÓN</p>,
    id: "desc",
    Cell: row => {
      return <p> {row.original.descripcion} </p>;
    }
  },
  {
    Header: () => <p className="font-weight-bold text-right">IMPORTE</p>,
    id: "importe",
    Cell: row => {
      return (
        <p className="font-weight-bold text-right">
          {" "}
          <MexicanCurrency quantity={row.original.importe} />{" "}
        </p>
      );
    }
  },
  {
    Header: () => <p className="font-weight-bold text-right">PORCENTAJE</p>,
    id: "porcentaje",
    Cell: row => {
      return <p className="text-right"> {row.original.porcentaje} % </p>;
    }
  },
  {
    Header: () => <p className="font-weight-bold">REGLA</p>,
    id: "regla",
    Cell: row => {
      return <p className="text-center"> {row.original.regla} </p>;
    }
  },
  {
    Header: () => <p className="font-weight-bold text-right">COMISIÓN</p>,
    id: "id",
    Cell: row => {
      return (
        <p className="font-weight-bold text-right">
          {" "}
          <MexicanCurrency quantity={row.original.montoComision} />{" "}
        </p>
      );
    }
  }
];
