import React from "react";
import s from "./styles.scss";
import { Preview } from "../../../../types/IAction";
import { infoNotification } from "../../../../utils/notifications";
import BaseInput from "../../../../toolkit/baseInput";
import { Button } from "reactstrap";
import { Styled } from "../../../../toolkit/decorators/Artifact";

interface Props {
  // Identificador del convenio
  id: number;

  // Mantenimientos asociados al convenio
  mantenimientosAsignables: any[];
  mantenimientosAsignablesLoading: boolean;
  mantenimientosAsociados: number[];
  mantenimientosAsociadosLoading: boolean;
  getMantenimientosAplicables: () => Promise<Preview>;
  getMantenimientosAsociados: (idConvenio: number) => Promise<Preview>;
  putMantenimientosAsociados: (
    idConvenio: number,
    mantenimientos: number[]
  ) => Promise<Preview>;

  // Anuncio de formulario para confirmar el cambio de paso en el carrusel
  handleDirty: (dirty: boolean, dirtyMsg: string) => void;
}
interface State {
  // Buscador de mantenimientos
  mantenimientoFinder: string;
  mantenimientos: any[];
  selectedMantenimientos: number[];
}

@Styled(s)
export default class ConvenioMantenimientos extends React.Component<
  Props,
  State
> {
  state = {
    mantenimientoFinder: "",
    mantenimientos: [],
    selectedMantenimientos: []
  };

  /// Ciclo de vida del componente
  componentDidMount() {
    this.props.getMantenimientosAsociados(this.props.id);
    this.props.getMantenimientosAplicables();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      prevProps.mantenimientosAsociados != this.props.mantenimientosAsociados
    ) {
      this.setState({
        selectedMantenimientos: this.props.mantenimientosAsociados
      });
    }

    if (
      prevProps.mantenimientosAsignables != this.props.mantenimientosAsignables
    ) {
      this.setState({
        mantenimientos: this.props.mantenimientosAsignables,
        mantenimientoFinder: ""
      });
    }
  }
  ////

  /// Gestión del formulario
  // Filtro de mantenimiento
  onChangeMantenimientoFilter = evt => {
    const value = evt.target.value || "";
    this.setState({
      mantenimientoFinder: value,
      mantenimientos: value
        ? this.props.mantenimientosAsignables.filter(
            m =>
              m.nombre &&
              m.nombre.toUpperCase().indexOf(value.toUpperCase()) > -1
          )
        : this.props.mantenimientosAsignables
    });
  };

  // Seleccionar todos
  onCheckAll = evt => {
    const value = evt.target.checked ? true : false;
    const asignables = [...this.state.mantenimientos.map(m => m.id)];

    const selected = value
      ? [...this.state.selectedMantenimientos, ...asignables]
      : this.state.selectedMantenimientos.filter(
          id => asignables.indexOf(id) === -1
        );

    this.setState({
      selectedMantenimientos: [...new Set(selected)]
    });
  };

  // Selección de una mantenimiento
  onCheckMantenimiento = (evt, idMantenimiento) => {
    const value = evt.target.checked ? true : false;
    const index = this.state.selectedMantenimientos.indexOf(
      Number(idMantenimiento)
    );
    const selected = [...this.state.selectedMantenimientos];

    if (value && index === -1) {
      selected.push(idMantenimiento);
    } else if (!value && index !== -1) {
      selected.splice(index, 1);
    }

    this.setState({
      selectedMantenimientos: selected
    });
  };

  // Actualización de mantenimientos
  submit = async evt => {
    const preview = await this.props.putMantenimientosAsociados(
      this.props.id,
      this.state.selectedMantenimientos
    );
    evt.preventDefault();

    if (preview.fulfilled) {
      infoNotification(`Mantenimientos actualizados exitosamente`);
    }
  };
  ////

  /// Métodos de renderizado
  render() {
    if (this.props.mantenimientosAsignablesLoading) {
      /// JSX del componente cuando está en carga
      return (
        <div className="h-100">
          <p className="primaryTitle w-100">
            Mantenimientos asociados al convenio
          </p>
          <p style={{ color: "#444", marginTop: "16px", fontSize: "14px" }}>
            Espere un momento por favor...
          </p>
        </div>
      );
      ////
    }

    return (
      <div className="h-100">
        <form>
          <p className="primaryTitle w-100">
            Mantenimientos asociados al convenio
          </p>
          {this.renderAlert()}

          {/* /// JSX de búsqueda por mantenimiento */}
          <div className="form-row">
            <div className="col">
              <BaseInput
                label={"Mantenimiento"}
                name="mantenimiento"
                type="text"
                id="mantenimiento"
                placeholder="Búsqueda por mantenimiento"
                value={this.state.mantenimientoFinder}
                onChange={this.onChangeMantenimientoFilter}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="col-3 mt-4">
              <div style={{ display: "inline-block", marginRight: "8px" }}>
                <input
                  id="all-mantenimientos"
                  onChange={this.onCheckAll}
                  type="checkbox"
                  style={{
                    position: "unset",
                    margin: "0px",
                    marginLeft: "0px"
                  }}
                />
              </div>
              <label
                htmlFor="all-mantenimientos"
                style={{ display: "inline-block" }}
              >
                Seleccionar todos
              </label>
            </div>
          </div>

          <div
            style={{
              overflowY: "scroll",
              overflowX: "hidden",
              border: "1px #999 solid",
              height: "calc(100vh - 700px)",
              marginTop: "16px",
              paddingBottom: "16px",
              minHeight: "500px"
            }}
          >
            <div className="container">
              <div className="row">{this.renderMantenimientos()}</div>
            </div>
          </div>

          <div className="row">
            <div className="col" />
            <div className="col-2">
              <Button
                className={`mt-3 ${s.primaryButton}`}
                onClick={this.submit}
                size="sm"
                block
              >
                Guardar
              </Button>
            </div>
          </div>
          {/* //// */}
        </form>
      </div>
    );
  }

  renderMantenimientos() {
    /// JSX de checkboxes de Mantenimientos
    return this.state.mantenimientos.map(e => (
      <div key={"mantenimiento-ls-" + e.id} className="col-3 mt-2">
        <div
          id={"mantenimiento-check-" + e.id}
          style={{
            display: "inline-block",
            marginRight: "8px",
            paddingTop: "1px",
            verticalAlign: "top"
          }}
        >
          <input
            onChange={evt => this.onCheckMantenimiento(evt, e.id)}
            type="checkbox"
            checked={this.state.selectedMantenimientos.indexOf(e.id) >= 0}
            style={{ position: "unset", margin: "0px", marginLeft: "0px" }}
          />
        </div>
        <label
          htmlFor={"mantenimiento-check-" + e.id}
          style={{
            display: "inline-block",
            width: "calc(100% - 24px)",
            fontSize: "12px"
          }}
        >
          {e.nombre}
        </label>
      </div>
    ));
    ////
  }

  renderAlert() {
    /// JSX de alerta de errores o warnings
    const mantenimientos: number[] = this.props.mantenimientosAsociados || [];

    if (
      !this.props.mantenimientosAsociadosLoading &&
      this.props.id &&
      mantenimientos.length == 0
    ) {
      return (
        <div className="alert alert-warning mt-3">
          El convenio no tienen asociado ningún mantenimiento.
        </div>
      );
    }

    return <></>;
    ////
  }
  ////
}
