import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Col, Button, Row } from "reactstrap";
import ReactTable from "react-table";
import BaseInput from "../../../toolkit/baseInput";
import DatePicker from "../common/DatePicker";
import SelectFilter from "../common/SelectFilter";
import s from "./styles.scss";
import IAction from "../../../types/IAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPlus,
  faDoorClosed,
  faFileExport
} from "@fortawesome/free-solid-svg-icons";
import constants from "../../../utils/constants";
import clonedeep from "lodash.clonedeep";

interface Props {
  clubs: Club[];
  sellers: object[];
  sportProgramsProducts: object[];
  sportsProgramsInstances: object[];
  gettingSportsProgramsInstances: boolean;
  searchSportsProgramsInstances: (fields: object) => IAction;
  changeSportProgramInstanceSelection: (data: object, mode: string) => void;
  confirmCloseSportProgramInstance: (instanceData: any) => void;
  downloadSportsProgramCommissions: (id: string) => IAction;
  userClub: any;
}
interface State {
  fields: object;
  instructor: object;
}

interface Club {
  clubId: number;
  nombre: string;
}

const transformNodeList = (list: any, text: string, idName?: string) => {
  return [
    {
      value: 0,
      label: text
    }
  ].concat(
    list.map(option => ({
      value: idName ? option[idName] : option.id,
      label: option.nombre
    }))
  );
};

const START_DATE_FIELDNAME = "fechaInicio";
const END_DATE_FIELDNAME = "fechaFin";

class SportProgram extends React.Component<Props, State> {
  state = {
    fields: {
      programaId: null,
      clubId: null,
      instructorId: null,
      fechaInicio: null,
      fechaFin: null
    },
    instructor: null
  };

  handleSelection = e => {
    e.preventDefault();
    const newFields = { ...this.state.fields, [e.target.name]: e.target.value };
    if (newFields[e.target.name] <= 0) {
      delete newFields[e.target.name];
    }
    this.setState({ fields: newFields });
  };

  onClickDownloadCsvFile = () => {
    this.props.downloadSportsProgramCommissions(this.state.fields.programaId);
  };

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: date
        };
        return { ...state, error: {}, fields };
      });
    };
  };

  handleSelect = e =>
    this.setState({
      fields: {
        ...this.state.fields,
        instructorId: e.value
      },
      instructor: e
    });

  search = () => {
    this.props.searchSportsProgramsInstances({
      ...clonedeep({ ...this.state.fields }),
      estatus: "ABIERTO"
    });
  };

  getSportsProgramsInstancesSource = () => {
    return this.props.sportsProgramsInstances || [];
  };

  componentDidMount = () => {
    this.setState({
      fields: {
        ...this.state.fields,
        clubId: this.props.userClub || null
      }
    });
  };

  render() {
    let columns = [
      {
        Header: () => <p className="font-weight-bold">Programa</p>,
        id: 0,
        width: 300,
        accessor: "programa",
        Cell: row => {
          return <p>{row.original.nombrePrograma}</p>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Club</p>,
        id: 1,
        width: 150,
        accessor: "club",
        Cell: row => {
          return <p>{row.original.clubNombre}</p>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Instructor</p>,
        id: 2,
        width: 250,
        accessor: "instructor",
        Cell: row => {
          return <p>{row.original.instructorNombre}</p>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Fecha inicio</p>,
        id: 3,
        width: 100,
        accessor: "fechaInicio",
        Cell: row => {
          return <p>{row.original.fechaInicio}</p>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Fecha fin</p>,
        id: 4,
        width: 100,
        accessor: "fechaFin",
        Cell: row => {
          return <p>{row.original.fechaFin}</p>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Comision acumulada</p>,
        id: 5,
        width: 150,
        accessor: "comision",
        Cell: row => {
          return <p>{row.original.comisionAcumulada}</p>;
        }
      },
      {
        Header: () => {
          return (
            <p className="text-center">
              <Button
                title="Crear"
                className={`${s.actionHeaderIcon}`}
                onClick={() => {
                  this.props.changeSportProgramInstanceSelection(
                    { productoId: -1, programaDeportivoId: -1 },
                    constants.SPORTS_PROGRAM_REGISTRATION_MODE.CREATE
                  );
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </p>
          );
        },
        id: -300,
        width: 80,
        accessor: "actions",
        Cell: row => {
          return (
            <div>
              <Button
                title="Editar"
                className={`${s.actionHeaderIcon} mr-2`}
                onClick={() => {
                  this.props.changeSportProgramInstanceSelection(
                    row.original,
                    constants.SPORTS_PROGRAM_REGISTRATION_MODE.EDIT
                  );
                }}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              <Button
                title="Cerrar"
                className={`${s.actionHeaderIcon} mr-2`}
                onClick={() => {
                  this.props.confirmCloseSportProgramInstance(row.original);
                }}
              >
                <FontAwesomeIcon icon={faDoorClosed} />
              </Button>
            </div>
          );
        }
      }
    ];

    return (
      <div>
        <Row>
          <Col xs={12} md={4} lg={4}>
            <BaseInput
              label="Club"
              name="clubId"
              type="select"
              id="clubId"
              placeholder="Club"
              value={this.state.fields.clubId}
              disabled={this.props.userClub}
              options={
                this.props.clubs &&
                transformNodeList(
                  this.props.clubs,
                  "Seleccione un club",
                  "clubId"
                )
              }
              onChange={this.handleSelection}
            />
          </Col>

          <Col xs={12} md={4} lg={4}>
            <BaseInput
              label={"Programa deportivo"}
              name="programaId"
              type="select"
              id="programaId"
              placeholder="TIPO DE PRODUCTO"
              value={this.state.fields.programaId}
              options={
                this.props.sportProgramsProducts &&
                transformNodeList(
                  this.props.sportProgramsProducts.map(e => ({
                    ...e
                  })),
                  "Seleccione un programa deportivo"
                )
              }
              onChange={this.handleSelection}
            />
          </Col>

          <Col xs={12} md={4} lg={4}>
            <SelectFilter
              label="Selecciona instructor"
              name="instructorId"
              type="select"
              id="instructorId"
              placeholder="Seleccione un instructor"
              value={this.state.instructor}
              isMulti={false}
              options={
                this.props.sellers
                  ? this.props.sellers.map((item: any) => ({
                      label: `${item.name || ""} ${item.firstSurname ||
                        ""} ${item.secondSurname || ""}`.trim(),
                      value: item.userId
                    }))
                  : null
              }
              onChange={this.handleSelect}
              loading={false}
              isAsync={false}
            />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <DatePicker
              label="Fecha Inicial"
              name={START_DATE_FIELDNAME}
              id={START_DATE_FIELDNAME}
              selected={this.state.fields[START_DATE_FIELDNAME]}
              onChange={this.onChangeDate(START_DATE_FIELDNAME)}
            />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <DatePicker
              label="Fecha Final"
              name={END_DATE_FIELDNAME}
              id={END_DATE_FIELDNAME}
              selected={this.state.fields[END_DATE_FIELDNAME]}
              onChange={this.onChangeDate(END_DATE_FIELDNAME)}
            />
          </Col>
          <Col xs={12} md={4} lg={4} className="mt-4">
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.search}
            >
              Buscar
            </Button>
          </Col>
        </Row>

        <ReactTable
          className="mt-5 -striped -highlight"
          data={this.getSportsProgramsInstancesSource()}
          columns={columns}
          sortable={false}
          resizable={false}
          pageSize={
            !!this.props.sportsProgramsInstances &&
            !!this.props.sportsProgramsInstances.length
              ? this.props.sportsProgramsInstances.length + 1
              : 5
          }
          showPagination={false}
          loading={this.props.gettingSportsProgramsInstances}
        />
      </div>
    );
  }
}

export default withRouter(withStyles(s)(SportProgram));
