import { MEMBERSHIP_TYPE } from "../utils/constants";

export interface SalesTrackingBySellerInterface {
  clubId: string;
  tipoEsquema: string;
  nombreClub: string;
  vendedores: {
    vendedorId: string;
    nombreVendedor: string;
    periodos: {
      compromisoId: string;
      nombrePeriodo: string;
      periodoId: string;
      numeroVentas: {
        presupuesto: string;
        compromiso: string;
        alcanzado: string;
      };
      totalVentas: {
        presupuesto: string;
        compromiso: string;
        alcanzado: string;
      };
      numeroProspectos: {
        presupuesto: string;
        compromiso: string;
        alcanzado: string;
      };
    }[];
  }[];
}

export interface Product {
  datosBasicosMembresiaId: string;
  datosBasicosMantenimientoId: string;
  categoria: string;
  subCategoria: string;
  indicadoresBI: Array<any>;
  nombre: string;
  estatus: string;
  unidadesSat: string;
  productoServicioSat: string;
  fechaInicioVigencia: string;
  fechaFinVigencia: string;
}

export interface GoalInterface {
  anio: string;
  mes: string;
  clubId: string;
  tipoEsquema: MEMBERSHIP_TYPE;
  club: {
    nombre: string;
  };
  nroMembresias: string;
  nroProspectos: string;
  totalVentas: string;
  nroEjecutivosQ1: string;
  nroEjecutivosQ2: string;
}

export interface Maintenance {
  datosBasicosMantenimientoId: string;
  nombre: string;
}
export interface Membership {
  datosBasicosMembresiaId: string;
  nombre: string;
}

export interface Promotion {
  nombre: string;
  id: string;
  isConvenio: boolean;
  finVigencia: string;
}

export enum TIPO_PRODUCTO {
  PROGRAMAS_DEPORTIVOS = "PROGRAMAS_DEPORTIVOS",
  CLASES_GRUPALES = "CLASES_GRUPALES",
  CLASES_PERSONALIZADAS = "CLASES_PERSONALIZADAS",
  EVENTOS = "EVENTOS",
  FIT_KIDS = "FIT_KIDS"
}
