export interface FILTERS {
  clubId: number;
  periodoId: number;
  esquemaId: number;
  vendedorId?: number;
  estatusComision?: number;
}
export interface FILTERS_CATALOGS {
  clubs: object;
  periodos: object;
  esquemas: object;
  tipoComision?: object;
  estatusComision?: object;
}

export const TIPOS_COMISION = [
  {
    value: "VENTA_MEMBRESIA",
    label: "Venta membresía"
  },
  {
    value: "VENTA_MEMBRESIA_COMPLEMENTO",
    label: "Venta membresía complemento"
  },
  {
    value: "REACTIVACION_MEMBRESIA",
    label: "Reactivación membresía"
  }
];

export const ESTATUS_COMISION = [
  {
    value: "CREADA",
    label: "Creada"
  },
  {
    value: "PENDIENTE",
    label: "Pendiente"
  },
  {
    value: "AUTORIZADA",
    label: "Autorizada"
  },
  {
    value: "RECHAZADA",
    label: "Rechazada"
  },
  {
    value: "PAGADA",
    label: "Pagada"
  }
];
