import { Preview } from "../../../types/IAction";
import * as actions from "../../actions/notaCredito";
import {
  GetNotaCreditoBloqueos,
  PostNotaCreditoBloqueo,
  DeleteNotaCreditoBloqueo
} from "../../reducers/notaCredito";

export interface NotaCreditoBloqueoDispatcher {
  getNotaCreditoBloqueadas: () => Promise<Preview<GetNotaCreditoBloqueos>>;
  postNotaCreditoBloqueada: (
    facturaFolio: string
  ) => Promise<Preview<PostNotaCreditoBloqueo>>;
  deleteNotaCreditoBloqueada: (
    facturaUUID: string
  ) => Promise<Preview<DeleteNotaCreditoBloqueo>>;
}

export const notaCreditoBloqueoDispatcher: (
  dispatch: (...args) => Promise<Preview<any>>
) => NotaCreditoBloqueoDispatcher = dispatch => ({
  getNotaCreditoBloqueadas: () => {
    return dispatch(actions.getNotaCreditoBloqueadas());
  },
  postNotaCreditoBloqueada: (facturaFolio: string) => {
    return dispatch(actions.postNotaCreditoBloqueada(facturaFolio));
  },
  deleteNotaCreditoBloqueada: (facturaUUID: string) => {
    return dispatch(actions.deleteNotaCreditoBloqueada(facturaUUID));
  }
});
