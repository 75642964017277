import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Container, Button, Row, Col } from "reactstrap";
import Stepper from "react-stepper-horizontal";
import {
  compareStates,
  resetStates,
  cleanUp
} from "../../helpers/stepper-state-comparator";
import ModalFrame from "../../../toolkit/modalFrame";

interface Props {
  productsCategories: object[];
  saveBasicProduct: () => any;
  onClickNext: (e) => any;
  match: any;
  index: number;
  disableNext: boolean;
  steps: { title; href; backHref; backLabel }[];
  hiddenSaveButton: boolean;
  parcialHidden: object[];
  history: any;
  activeStep: number;

  // Para mostrar un botón de regresar
  backHref?: string;
  backLabel?: string;
}

interface State {
  lockNext: boolean;
  lockSave: boolean;
  isOpen: boolean;
}

class SaveNextCarousel extends React.Component<Props, State> {
  state = {
    lockNext: true,
    lockSave: false,
    isOpen: false
  };

  onClickSave = null;
  href = null;

  componentDidMount() {}

  componentDidUpdate() {}

  resetSuccessfulSave = () => {
    this.setState({ lockNext: true, lockSave: false });
  };

  onSuccessfulSave = () => {
    this.setState({ lockNext: false, lockSave: false });
  };

  onLoading = () => {
    this.setState({ lockNext: true, lockSave: true });
  };

  compareStatesAndSave = event => {
    resetStates();
    this.onClickSave(event);
  };

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const child = React.Children.toArray(this.props.children)[
      this.props.index
    ] as React.ReactElement<{
      onClickSave: (f: any) => any;
      onSuccessfulSave: () => any;
      resetSuccessfulSave: () => any;
      onLoading: () => any;
    }>;

    const paddingSize = 6;

    return (
      <div className="h-100 w-100">
        <Container className="px-0 py-1 my-1">
          <Row
            className={`p-0 m-0 ${s.stepperPointer}`}
            style={{ paddingBottom: paddingSize * 2 }}
          >
            <Stepper
              steps={this.props.steps.map(({ href, title }) => {
                return {
                  title: <span>{title}</span>,
                  onClick: () => {
                    if (compareStates()) {
                      cleanUp();
                      this.props.history.push(href);
                    } else {
                      if (href) {
                        this.href = href;
                        this.toggle();
                      }
                    }
                  }
                };
              })}
              activeColor="#fc0524"
              activeStep={this.props.activeStep}
              completeColor="#000000"
              size={24}
              circleFontSize={14}
              titleFontSize={12}
              circleTop={paddingSize}
            />
          </Row>
          <Row className="p-0 m-0 mt-3" style={{ minHeight: "250px" }}>
            {React.isValidElement(child)
              ? React.cloneElement(child, {
                  onClickSave: f => (this.onClickSave = f),
                  onSuccessfulSave: this.onSuccessfulSave,
                  resetSuccessfulSave: this.resetSuccessfulSave,
                  onLoading: this.onLoading
                })
              : null}
          </Row>
          <Row className="p-0 m-0">
            <Col />
            <Col xs={2} className="pr-0">
              {/* <Button
              className={`${s.buttonMarginTop} ${s.primaryButton} float-right`}
              onClick={e => this.props.onClickNext(e)}
              disabled={this.props.disableNext}
              block
              size="sm"
            >
              {"Siguiente"}
            </Button> */}
            </Col>

            {/* Regresar a un listado principal */}
            {this.props.backHref && (
              <Col xs={2} className="float-right pr-0 mt-3">
                <Button
                  className={`${s.buttonMarginTop} ${s.primaryButton} float-right`}
                  onClick={() => this.props.history.push(this.props.backHref)}
                  size="sm"
                  block
                  type="button"
                >
                  {this.props.backLabel || "Regresar"}
                </Button>
              </Col>
            )}

            {!this.props.hiddenSaveButton &&
              (this.props.parcialHidden &&
              this.props.parcialHidden[this.props.index][
                "parcialHidden"
              ] ? null : this.props.match.path !==
                "/productos/:id/comisiones" ? (
                <Col xs={2} className="float-right pr-0 mt-3">
                  <Button
                    className={`${s.buttonMarginTop} ${s.primaryButton} float-right`}
                    onClick={this.compareStatesAndSave}
                    disabled={this.state.lockSave}
                    size="sm"
                    block
                  >
                    {"Guardar"}
                  </Button>
                </Col>
              ) : (
                ""
              ))}
          </Row>
        </Container>
        <ModalFrame
          title={"Acciones"}
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          size="sm"
        >
          <div className="container-fluid">
            <p>¿Desea continuar sin guardar los cambios?</p>
            <div className="row">
              <div className="col">
                <Button
                  className={`${s.buttonMarginTop} ${s.primaryButton}`}
                  onClick={this.toggle}
                  size="lg"
                  block
                >
                  Cancelar
                </Button>
              </div>
              <div className="col">
                <Button
                  className={`${s.buttonMarginTop} ${s.primaryButton}`}
                  onClick={e => {
                    e.preventDefault();
                    cleanUp();
                    this.props.history.push(this.href);
                  }}
                  size="lg"
                  block
                >
                  Confirmar
                </Button>
              </div>
            </div>
          </div>
        </ModalFrame>
      </div>
    );
  }
}

export default withRouter(withStyles(s)(SaveNextCarousel));
