import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import { getErrorMessage } from "../../utils/helpers";
import { ERROR, errorNotification } from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";

interface State {
  companies: object[];
  gettingCompanies: boolean;
  gettingCompaniesError: object;

  companie: object;
  gettingCompanie: boolean;
  gettingCompanieError: object;

  companieCreated: boolean;
  creatingCompanie: boolean;
  creatingCompanieError: object;

  companieUpdated: boolean;
  updatingCompanie: boolean;
  updatingCompanieError: object;

  companieDeleted: boolean;
  deletingCompanie: boolean;
  deletingCompanieError: object;
}

const initialState: State = {
  companies: null,
  gettingCompanies: false,
  gettingCompaniesError: null,

  companie: null,
  gettingCompanie: false,
  gettingCompanieError: null,

  companieCreated: false,
  creatingCompanie: false,
  creatingCompanieError: null,

  companieUpdated: false,
  updatingCompanie: false,
  updatingCompanieError: null,

  companieDeleted: false,
  deletingCompanie: false,
  deletingCompanieError: null
};

export default function companiesReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    //GET ALL COMPANIES
    case `${actionTypes.GET_COMPANIES}_PENDING`: {
      return {
        ...state,
        gettingCompanies: true,
        gettingCompaniesError: null
      };
    }
    case `${actionTypes.GET_COMPANIES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        companies: null,
        gettingCompanies: false,
        gettingCompaniesError: getErrorMessage(action.payload)
      };
    }
    case `${actionTypes.GET_COMPANIES}_FULFILLED`: {
      return {
        ...state,
        companies: action.payload.data,
        gettingCompanies: false,
        gettingCompaniesError: null
      };
    }
    //GET COMPANIE
    case `${actionTypes.GET_COMPANIE}_PENDING`: {
      return {
        ...state,
        gettingCompanie: true,
        gettingCompanieError: null
      };
    }
    case `${actionTypes.GET_COMPANIE}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        companie: null,
        gettingCompanie: false,
        gettingCompanieError: getErrorMessage(action.payload)
      };
    }
    case `${actionTypes.GET_COMPANIE}_FULFILLED`: {
      return {
        ...state,
        companie: action.payload.data,
        gettingCompanie: false,
        gettingCompanieError: null
      };
    }
    //CREATE COMPANIE
    case `${actionTypes.CREATE_COMPANIE}_PENDING`: {
      return {
        ...state,
        creatingCompanie: true,
        creatingCompanieError: null
      };
    }
    case `${actionTypes.CREATE_COMPANIE}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        companieCreated: null,
        creatingCompanie: false,
        creatingCompanieError: getErrorMessage(action.payload)
      };
    }
    case `${actionTypes.CREATE_COMPANIE}_FULFILLED`: {
      return {
        ...state,
        companieCreated: action.payload.data,
        creatingCompanie: false,
        creatingCompanieError: null
      };
    }
    //UPDATE COMPANIE
    case `${actionTypes.UPDATE_COMPANIE}_PENDING`: {
      return {
        ...state,
        updatingCompanie: true,
        updatingCompanieError: null
      };
    }
    case `${actionTypes.UPDATE_COMPANIE}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        companieUpdated: null,
        updatingCompanie: false,
        updatingCompanieError: getErrorMessage(action.payload)
      };
    }
    case `${actionTypes.UPDATE_COMPANIE}_FULFILLED`: {
      return {
        ...state,
        companieUpdated: action.payload.data,
        updatingCompanie: false,
        updatingCompanieError: null
      };
    }
    //DELETE COMPANIE
    case `${actionTypes.DELETE_COMPANIE}_PENDING`: {
      return {
        ...state,
        deletingCompanie: true,
        deletingCompanieError: null
      };
    }
    case `${actionTypes.DELETE_COMPANIE}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        companieDeleted: null,
        deletingCompanie: false,
        deletingCompanieError: getErrorMessage(action.payload)
      };
    }
    case `${actionTypes.DELETE_COMPANIE}_FULFILLED`: {
      return {
        ...state,
        companieDeleted: action.payload.data,
        deletingCompanie: false,
        deletingCompanieError: null
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
