import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import ProspectDetail from "../../components/prospectDetail";
import {
  updateProspectExtraDataById,
  cleanProspectById
} from "../../actions/prospects";
import {
  updatePhoto,
  saveResponsable,
  getResponsables,
  toggleResponsable,
  deletePhone,
  updatePhone,
  deleteContact,
  updateContact,
  deleteAddress,
  updateAddress,
  deleteEmail,
  updateEmail,
  getPersonById
} from "../../actions/persons";
import permissionCodes from "../../../utils/permissionCodes";
import { userHasPermission } from "../../../utils/accessTree";
import { getEmpresasConvenio } from "../../actions/convenios";
import { getEmpresasConvenioRequest } from "../../actions/catalogs";
import { handleRequestError } from "../../../utils/helpers";

interface Props {
  person: object;
  getPersonById: (personaId: string) => void;
  cleanProspectById: () => void;
  match: any;
  errorGetUser: boolean;
  loadingGetUser: boolean;
  updateProspectExtraDataById: (person: any, id: string) => any;
  personPicture: object;
  updatePhoto: (idPersona: string, photo: Blob) => any;
  saveResponsable: (responsable: object) => any;
  responsables: object[];
  getResponsables: (personaId: string) => any;
  toggleResponsable: (idMenor: string, idResponsable) => any;
  deletePhone: (phoneId: string) => any;
  updatePhone: (personId: string, body: object) => any;
  deleteContact: (contactId: string) => any;
  updateContact: (personId: string, body: object) => any;
  deleteAddress: (addressId: string) => any;
  updateAddress: (addressId: string, body: object) => any;
  deleteEmail: (addressId: string) => any;
  updateEmail: (addressId: string, body: object) => any;
  history: any;
  getEmpresasConvenio: () => void;
  empresasConvenio: any[];
}

class prospectDetail extends React.Component<Props> {
  state = {
    viewBasicDataMode: false,
    viewDetailMode: false,
    empresasConvenio: null
  };

  handleSubmit = (prospect, id) => {
    this.props.updateProspectExtraDataById(prospect, id);
  };

  componentDidMount() {
    const { personaId } = this.props.match.params;

    let viewBasicDataMode = false;
    let viewDetailMode = false;

    if (this.props.match.path.includes("prospecto")) {
      viewBasicDataMode = !userHasPermission(
        permissionCodes.PROSPECT_BASIC_DATA_EDITION_PERMISSION
      );
      viewDetailMode = !userHasPermission(
        permissionCodes.PROSPECT_DETAIL_EDITION_PERMISSION
      );

      if (viewBasicDataMode && viewDetailMode) {
        this.props.history.push(`/prospecto/${personaId}/ver`);
        return;
      }
    } else if (this.props.match.path.includes("socio")) {
      viewBasicDataMode = !userHasPermission(
        permissionCodes.PARTNER_BASIC_DATA_EDITION_PERMISSION
      );
      viewDetailMode = !userHasPermission(
        permissionCodes.PARTNER_DETAIL_EDITION_PERMISSION
      );

      if (viewBasicDataMode && viewDetailMode) {
        this.props.history.push(`/socio/${personaId}/ver`);
        return;
      }
    } else if (this.props.match.path.includes("invitado")) {
      viewBasicDataMode = !userHasPermission(
        permissionCodes.SPECIAL_GUESTS_BASIC_DATA_EDITION_PERMISSION
      );
      viewDetailMode = !userHasPermission(
        permissionCodes.SPECIAL_GUESTS_DETAIL_EDITION_PERMISSION
      );

      if (viewBasicDataMode && viewDetailMode) {
        this.props.history.push(`/invitado/${personaId}/ver`);
        return;
      }
    } else if (this.props.match.path.includes("externo")) {
      viewBasicDataMode = !userHasPermission(
        permissionCodes.EXTERN_BASIC_DATA_EDITION_PERMISSION
      );
      viewDetailMode = !userHasPermission(
        permissionCodes.EXTERN_DETAIL_EDITION_PERMISSION
      );

      if (viewBasicDataMode && viewDetailMode) {
        this.props.history.push(`/externo/${personaId}/ver`);
        return;
      }
    } else if (this.props.match.path.includes("empleado")) {
      viewBasicDataMode = !userHasPermission(
        permissionCodes.EMPLOYEE_BASIC_DATA_EDITION_PERMISSION
      );
      viewDetailMode = !userHasPermission(
        permissionCodes.EMPLOYEE_DETAIL_EDITION_PERMISSION
      );

      if (viewBasicDataMode && viewDetailMode) {
        this.props.history.push(`/empleado/${personaId}/ver`);
        return;
      }
    } else {
    }

    this.setState({ viewBasicDataMode, viewDetailMode });

    if (personaId) {
      this.props.getPersonById(personaId);
    }

    this.getAggreementsCompanies();
  }

  componentDidUnmount() {
    this.props.cleanProspectById();
  }

  getAggreementsCompanies = () => {
    if ((this.state.empresasConvenio || []).length <= 0) {
      getEmpresasConvenioRequest()
        .then(({ data }) => {
          this.setState({ empresasConvenio: [...data] });
        })
        .catch(handleRequestError);
    }
  };

  render() {
    return (
      <main>
        <Helmet title="Detalle de un Prospecto" />
        <Layout>
          {this.props.person &&
            (this.state.empresasConvenio || []).length > 0 && (
              <ProspectDetail
                viewBasicDataMode={this.state.viewBasicDataMode}
                viewDetailMode={this.state.viewDetailMode}
                person={this.props.person}
                error={this.props.errorGetUser}
                loading={this.props.loadingGetUser}
                onEditExtraDataProspect={this.handleSubmit}
                personPicture={this.props.personPicture}
                updatePhoto={this.props.updatePhoto}
                saveResponsable={this.props.saveResponsable}
                toggleResponsable={this.props.toggleResponsable}
                responsables={this.props.responsables}
                getResponsables={this.props.getResponsables}
                deletePhone={this.props.deletePhone}
                updatePhone={this.props.updatePhone}
                deleteContact={this.props.deleteContact}
                updateContact={this.props.updateContact}
                deleteAddress={this.props.deleteAddress}
                updateAddress={this.props.updateAddress}
                deleteEmail={this.props.deleteEmail}
                updateEmail={this.props.updateEmail}
                empresasConvenio={this.state.empresasConvenio}
              />
            )}
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = {
  updateProspectExtraDataById,
  getPersonById,
  cleanProspectById,
  updatePhoto,
  saveResponsable,
  getResponsables,
  toggleResponsable,
  deletePhone,
  updatePhone,
  deleteContact,
  updateContact,
  deleteAddress,
  updateAddress,
  deleteEmail,
  updateEmail,
  getEmpresasConvenio
};

const mapStateToProps = state => {
  const {
    user,
    persons: { personPicture, responsables, personById: person }
  } = state;
  return {
    errorGetUser: user.prospectDetailError,
    loadingGetUser: user.prospectDetailLoading,
    personPicture,
    responsables,
    person,
    empresasConvenio: state.convenios.empresasConvenio
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(prospectDetail);
