import React from "react";

import ReactTable from "react-table";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Button, Row, Col, Container, UncontrolledTooltip } from "reactstrap";
import ModalFrame from "../../../../../toolkit/modalFrame";
import IAction from "../../../../../types/IAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

interface Props {
  isOpenHCN: boolean;
  toggleHCN: () => any;
  listHistoricalRebilling: any;
  historicalRebilling: (ventaId: string) => IAction;
  ventaId: string;
  toggleBill: (storageId: number) => any;
}

interface State {
  load: boolean;
  loadList: boolean;
  listHistorical: any;
}

class HistoryCreditNotes extends React.Component<Props, State> {
  state = {
    load: false,
    loadList: false,
    listHistorical: []
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.ventaId != this.props.ventaId) {
      this.props.historicalRebilling(this.props.ventaId);
      this.setState({ load: true });
    }

    if (
      this.props.listHistoricalRebilling !== null &&
      this.props.listHistoricalRebilling.length > 0 &&
      !this.state.loadList
    ) {
      this.setState({
        loadList: true,
        listHistorical: this.props.listHistoricalRebilling
      });
    }
  }

  clearData = () => {
    this.props.toggleHCN();
  };

  columns = [
    {
      Header: "Factura UUID",
      accessor: "facturaUUID"
    },
    {
      Header: "Nota Credito UUID",
      accessor: "notaCreditoUUID"
    },
    {
      Header: "Folio Factura",
      accessor: "folioFactura"
    },
    {
      Header: "Folio Nota Credito",
      accessor: "folioNotaCredito"
    },
    {
      Header: "Fecha Autorizacion Nota Credito",
      accessor: "fechaAutorizacionNotaCredito"
    },
    {
      Header: "Folio Factura",
      accessor: "folioFactura"
    },
    {
      Header: () => {
        return <p className="font-weight-bold">Opciones</p>;
      },
      id: -300,
      width: 80,
      accessor: "actions",
      Cell: row => {
        return (
          <Container className="p-0">
            <Row className="m-0">
              <Col className="px-1">
                <a
                  id={`factura${row.original.facturaStorageId}`}
                  onClick={() =>
                    this.props.toggleBill(row.original.facturaStorageId)
                  }
                >
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faFilePdf}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={`factura${row.original.facturaStorageId}`}
                  >
                    Abrir pdf factura
                  </UncontrolledTooltip>
                </a>
              </Col>
              <Col className="px-1">
                <a
                  id={`notaCredito${row.original.notaCreditoStorageId}`}
                  onClick={() =>
                    this.props.toggleBill(row.original.notaCreditoStorageId)
                  }
                >
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faFilePdf}
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={`notaCredito${row.original.notaCreditoStorageId}`}
                  >
                    Abrir pdf notya crédito
                  </UncontrolledTooltip>
                </a>
              </Col>
            </Row>
          </Container>
        );
      }
    }
  ];

  render() {
    return (
      <Container className="p-0">
        <ModalFrame
          title={`Historial notas de crédito`}
          isOpen={this.props.isOpenHCN}
          toggle={this.clearData}
          size="lg"
        >
          <ReactTable
            pageSize={10}
            data={this.props.listHistoricalRebilling || []}
            columns={this.columns}
            showPagination={true}
            className="mt-3"
            getTrProps={() => ({
              className: "mt-1"
            })}
          />
          <Row className="mt-4">
            <Col xs={6} md={12} lg={12}>
              <Button block color="secondary" onClick={this.clearData}>
                SALIR
              </Button>
            </Col>
          </Row>
        </ModalFrame>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(HistoryCreditNotes));
