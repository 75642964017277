import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";
import { PROMOTION_PRICE } from "../../types-business/Promotions";
import FileDownload from "js-file-download";
import moment from "moment";

const baseUrl = `/api`;

export const getPromotions = (params?: object): IAction => {
  return {
    type: actionTypes.GET_PROMOTIONS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/promotions`, {
        withCredentials: true,
        params: { ...params, sort: "updated_date,desc" }
      })
    }
  };
};

export const getPromotionsFiltered = (params?: object): IAction => {
  return {
    type: actionTypes.GET_PROMOTIONS_FILTERED,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/promotions/filtered`,
        {
          withCredentials: true,
          params
        }
      )
    }
  };
};

export const getPromotionsFilteredRequest = (params?: object) => {
  return axios.get(`${constants.BASE_URL}${baseUrl}/promotions/filtered`, {
    withCredentials: true,
    params
  });
};

export const saveChangesBenefits = (promotionPrices: PROMOTION_PRICE) => {
  return {
    type: actionTypes.SAVE_CHANGES_BENEFITS,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${baseUrl}/promotions/benefits`,
        promotionPrices,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const membershipChanged = () => {
  return {
    type: actionTypes.MEMBERSHIP_CHANGED
  };
};

export const clubChanged = () => {
  return {
    type: actionTypes.CLUB_CHANGED
  };
};

export const cancelBenefitsChanges = () => {
  return {
    type: actionTypes.CANCEL_BENEFITS_CHANGES
  };
};

export const confirmBenefitsChanges = () => {
  return {
    type: actionTypes.CONFIRM_BENEFITS_CHANGES
  };
};

export const deleteMonthPrices = () => {
  return {
    type: actionTypes.DELETE_MONTH_PRICES
  };
};

export const addMonthPrices = () => {
  return {
    type: actionTypes.ADD_MONTH_PRICES
  };
};

export const checkLastMonth = (monthIndex, ev) => {
  const checked = ev.target.checked;
  return {
    type: actionTypes.CHECK_LAST_MONTH,
    payload: {
      monthIndex,
      checked
    }
  };
};

export const updateCellPrice = (monthIndex, memberIndex, ev) => {
  ev.preventDefault();
  const value = ev.target.value;
  return {
    type: actionTypes.PROMOTION_PRICE_CELL,
    payload: {
      monthIndex,
      memberIndex,
      value
    }
  };
};

export const onChangePriceType = (membershipPriceType: string) => {
  return {
    type: actionTypes.PROMOTION_MEMBERSHIP_VALUE_TYPE,
    payload: membershipPriceType
  };
};

export const onChangePriceTypeMonth = (
  monthIndex: number,
  priceType: string
) => {
  return {
    type: actionTypes.PROMOTION_MEMBERSHIP_VALUE_TYPE_MONTH,
    payload: {
      priceType,
      monthIndex
    }
  };
};

export const onChangeMembershipValue = ev => {
  ev.preventDefault();
  const value = ev.target.value;
  return {
    type: actionTypes.PROMOTION_MEMBERSHIP_VALUE,
    payload: value
  };
};

export const checkProportional = ev => {
  const checked = ev.target.checked;
  return {
    type: actionTypes.CHECK_PROPORTIONAL,
    payload: checked
  };
};

export const updateMirrorFreePartners = (_checked: boolean) => {
  return {
    type: actionTypes.PROMOTION_BENEFIT_FREE_USER_MIRROR,
    payload: _checked
  };
};
export const updateFreePartnersByBirthDay = (_checked: boolean) => {
  return {
    type: actionTypes.PROMOTION_BENEFIT_FREE_USER_BY_BIRTHDAY,
    payload: _checked
  };
};
export const updateFreePartnersNumber = (_number: number) => {
  return {
    type: actionTypes.PROMOTION_BENEFIT_FREE_USER_NUMBER,
    payload: _number
  };
};
export const updateFreePartnersInitialDate = (initialDate: any) => {
  return {
    type: actionTypes.PROMOTION_BENEFIT_FREE_USER_INITIAL_DATE,
    payload: initialDate
  };
};
export const updateFreePartnersFinalDate = (finalDate: any) => {
  return {
    type: actionTypes.PROMOTION_BENEFIT_FREE_USER_FINAL_DATE,
    payload: finalDate
  };
};
export const updateFreePartnersMonths = (_number: number) => {
  return {
    type: actionTypes.PROMOTION_BENEFIT_FREE_USER_MONTHS,
    payload: _number
  };
};
export const updateFreeGuestsPassesNumber = (_number: number) => {
  return {
    type: actionTypes.PROMOTION_BENEFIT_FREE_GUESTS_PASSES_NUMBER,
    payload: _number
  };
};
export const updateFreeChildrenNumber = (_number: number) => {
  return {
    type: actionTypes.PROMOTION_BENEFIT_FREE_CHILDREN_NUMBER,
    payload: _number
  };
};

export const checkRelative = ev => {
  const checked = ev.target.checked;
  return {
    type: actionTypes.CHECK_ABSOLUTE,
    payload: checked
  };
};

export const addClubsToPromotion = (
  promotionId: string,
  clubs: object[]
): IAction => {
  return {
    type: actionTypes.ADD_CLUBS_PROMOTION,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${baseUrl}/promotions/${promotionId}/clubs`,
        clubs,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getPromotionById = (promotionId: string): IAction => {
  return {
    type: actionTypes.PROMOTION_BY_ID,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/promotions/${promotionId}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const addMaintenancesToPromotion = (
  promotionId: string,
  maintenances: object[]
): IAction => {
  return {
    type: actionTypes.ADD_MAINTENANCES_PROMOTION,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${baseUrl}/promotions/${promotionId}/maintenances`,
        maintenances,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const addAgreementsToPromotion = (
  promotionId: string,
  agreements: object
): IAction => {
  return {
    type: actionTypes.ADD_AGREEMENTS_PROMOTION,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${baseUrl}/promotions/${promotionId}/agreements`,
        agreements,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getClubsPromotion = (id: string): IAction => {
  return {
    type: actionTypes.GET_CLUBS_PROMOTION,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/promotions/${id}/clubs`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getPricesPromotion = (
  promotionId: string,
  membershipId: string,
  clubId: string
): IAction => {
  return {
    type: actionTypes.GET_PRICES_PROMOTION,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/promotions/${promotionId}/memberships/${membershipId}/clubs/${clubId}/benefits`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getMaintenancesPromotion = (id: string): IAction => {
  return {
    type: actionTypes.GET_MAINTENANCES_PROMOTION,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/promotions/${id}/maintenances`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getAgreementsPromotion = (id: string): IAction => {
  return {
    type: actionTypes.GET_AGREEMENTS_PROMOTION,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/promotions/${id}/agreements`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getPromotionMemberships = (id: string): IAction => {
  return {
    type: actionTypes.GET_MEMBERSHIPS_PROMOTION,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/promotions/${id}/memberships`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const saveBasicPromotion = (promotion: object): IAction => {
  return {
    type: actionTypes.SAVE_BASIC_PROMOTION,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${baseUrl}/promotions/basicData`,
        promotion,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const updateBasicPromotion = (
  promotionId: string,
  promotion: object
): IAction => {
  return {
    type: actionTypes.UPDATE_BASIC_PROMOTION,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${baseUrl}/promotions/basicData/${promotionId}`,
        promotion,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getBasicData = (id: string): IAction => {
  return {
    type: actionTypes.GET_BASIC_PROMOTION,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/promotions/basicData/${id}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getPromotionDetailById = (id: string): IAction => {
  return {
    type: actionTypes.GET_PROMOTION_DETAIL,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/promotions/${id}/detail`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getPartnerMembershipsWithPromotionsReportRequest = args => {
  let baseUrl = "/api/promotions";
  const params = {
    page: 0,
    size: 20,
    ...args
  };
  return axios.get(`${constants.BASE_URL}${baseUrl}/report`, {
    withCredentials: true,
    params
  });
};

export const executePartnerMembershipsWithPromotionsReportDownload = (
  args,
  _callback
) => {
  let baseUrl = "/api/promotions";
  const params = {
    page: 0,
    size: 20,
    ...args
  };

  return axios
    .get(`${constants.BASE_URL}${baseUrl}/report/download`, {
      withCredentials: true,
      params,
      responseType: "blob"
    })
    .then(({ data }) => {
      if (!!_callback) {
        _callback();
      }
      FileDownload(
        data,
        "ReporteMembresiasConPromocion_" +
          moment().format("YYYY-MM-DD HH:mm") +
          ".csv"
      );
    });
};

export const authorizePromotion = (promotionId: string) => {
  return axios.put(
    `${constants.BASE_URL}${baseUrl}/promotions/${promotionId}/authorize`,
    null,
    {
      withCredentials: true
    }
  );
};

export const unauthorizePromotion = (promotionId: string) => {
  return axios.put(
    `${constants.BASE_URL}${baseUrl}/promotions/${promotionId}/unauthorize`,
    null,
    {
      withCredentials: true
    }
  );
};
