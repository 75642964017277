import React from "react";
import { connect } from "react-redux";
import * as promotionACtions from "../../actions/promotions";
import AddClubsToProducts from "../../components/addClubsToProducts";
import { withRouter } from "react-router-dom";

interface Props {
  addClubsToPromotion: (promotionId: string, clubs: object[]) => any;
  successfulSave: boolean;
  getClubsPromotion: (id: string) => any;
  validClubs: object[];
  onClickSave: (e: object) => any;
  match: any;
}

class AddClubsPromotionPage extends React.Component<Props, void> {
  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) this.props.getClubsPromotion(id);
  }

  setClubs = clubs => {
    const { id } = this.props.match.params;
    this.props.addClubsToPromotion(id, clubs);
  };

  render() {
    return (
      <AddClubsToProducts
        successfulSave={this.props.successfulSave}
        validClubs={this.props.validClubs}
        onClickSave={this.props.onClickSave}
        setClubs={this.setClubs}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addClubsToPromotion: (promotionId: string, clubs: object[]) => {
    dispatch(promotionACtions.addClubsToPromotion(promotionId, clubs));
  },
  getClubsPromotion: (id: string) => {
    dispatch(promotionACtions.getClubsPromotion(id));
  }
});

const mapStateToProps = state => {
  const { promotions } = state;

  return {
    successfulSave: promotions.clubsSaved,
    validClubs: promotions.promotionClubs
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddClubsPromotionPage)
);
