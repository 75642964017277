import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button, Form } from "reactstrap";
import DatePicker from "../common/DatePicker";
import s from "./styles.scss";
import BaseInput from "../../../toolkit/baseInput";
import moment from "moment";
import ReactTable from "react-table";
import MexicanCurrency from "../common/currency";
import PagedTable from "../common/PagedTable";

interface Props {
  discounts: {
    gettingList: boolean;
    discountList: object;
    dataLteList: object[];
  };
  clubs: {
    clubId: number;
    nombre: string;
  }[];
  getCodesList: (lote, args?) => any;
  getDiscountCodesListFile: (lote: string) => any;
  getCodesListLte: (data: object) => any;
  clearCodes: () => any;
}

interface State {
  fields: {
    fecha: string;
    fechaFin: String;
    clubId: string;
  };
  search: object;
  lote: string;
}

interface Props {}

class DiscountCodeListPage extends React.Component<Props, State> {
  state = {
    fields: {
      fecha: moment().format("YYYY-MM-DD"),
      fechaFin: moment().format("YYYY-MM-DD"),
      clubId: null
    },
    search: {},
    lote: null
  };

  getFile = e => {
    e.preventDefault();
    this.props.getDiscountCodesListFile(this.state.lote);
  };

  onChangeFecha = fieldName => {
    return async event => {
      const date = moment(event, "YYYY-MM-DD").format("YYYY-MM-DD");
      let state = { ...this.state };
      if (fieldName == "fecha") state.fields.fecha = date;
      if (fieldName == "fechaFin") state.fields.fechaFin = date;
      await this.setState(state);
    };
  };

  onChangeField = async (e, fieldName) => {
    let state = { ...this.state };
    state.fields[fieldName] = e.target.value;
    await this.setState(state);
  };

  getCodesListLte = () => {
    let { clubId, fecha, fechaFin } = this.state.fields;
    this.props.getCodesListLte({
      clubId,
      fechaCreacionInicio: fecha,
      fechaCreacionFin: fechaFin
    });
  };

  preProcessTableLteData = () => {
    let tableData = [];
    tableData = this.props.discounts.dataLteList
      ? this.props.discounts.dataLteList
      : null;
    return tableData;
  };

  getCodesList = async (lote: string) => {
    await this.setState({ ...this.state, lote });
    this.props.getCodesList(this.state.lote);
  };

  fetchData = ({ pageSize: size, page }) => {
    if (!this.state.lote) return null;
    this.props.getCodesList(this.state.lote, { size, page });
    this.setState(state => {
      return {
        ...state,
        search: { size, page }
      };
    });
  };

  render() {
    const listDiscountsTablecolumnslist = [
      {
        name: "Club",
        id: "club"
      },
      {
        name: "Codigo",
        id: "codigo"
      },
      {
        name: "Id Grupo",
        id: "codigoGrupoId"
      },
      {
        name: "Creación",
        id: "createdDate",
        Cell: row => (
          <p className="m-0 p-0">{moment(row.value).format("YYYY-MM-DD")}</p>
        )
      },
      {
        name: "Estatus",
        id: "estatusCodigoDescuento"
      },
      {
        name: "Inicio de vigencia",
        id: "fechaInicioVigencia",
        Cell: row => (
          <p className="m-0 p-0">{moment(row.value).format("YYYY-MM-DD")}</p>
        )
      },
      {
        name: "Fin de vigencia",
        id: "fechaFinVigencia",
        Cell: row => (
          <p className="m-0 p-0">{moment(row.value).format("YYYY-MM-DD")}</p>
        )
      },
      {
        name: "Monto de descuento",
        id: "montoDescuento",
        Cell: row => (
          <p className="text-right m-0 p-0">
            <MexicanCurrency quantity={row.value} />
          </p>
        )
      },
      {
        name: "% Descuento",
        id: "porcentajeDescuento",
        Cell: row => <p className="text-right m-0 p-0">{row.value} %</p>
      },
      {
        name: "Tipo Código Descuento",
        id: "tipoCodigoDescuento"
      },
      {
        name: "Tipo Descuento",
        id: "tipoDescuento"
      },
      {
        name: "Producto",
        id: "productoNombre"
      }
    ];

    const tableLteData: any[] = this.preProcessTableLteData();
    const listDiscountsTablecolumnsLTE = [
      {
        Header: "Lote",
        accessor: "loteId"
      },
      {
        Header: "Descripción",
        accessor: "descripcionLote"
      },
      {
        Header: "Creación",
        accessor: "fechaLote"
      },
      {
        accessor: "loteId",
        Cell: row => {
          return (
            <Button
              onClick={() => {
                this.getCodesList(row.value);
              }}
              block
              size="sm"
              color="link"
            >
              Ver detalle
            </Button>
          );
        }
      }
    ];

    return (
      <Container className="w-100 p-0 mt-3">
        <Row className="mb-1">
          <Col sm="12">Ingresa los campos requeridos para comenzar ( * )</Col>
        </Row>

        <Form
          onSubmit={e => {
            e.preventDefault();
            this.getCodesListLte();
          }}
        >
          <Row className="my-2">
            <Col sm="3">
              <DatePicker
                label="Fecha de creación (inicio)"
                name={"fecha"}
                id={"fecha"}
                selected={moment(this.state.fields.fecha, "YYYY-MM-DD")}
                onChange={this.onChangeFecha("fecha")}
              />
            </Col>
            <Col sm="3">
              <DatePicker
                label="Fecha de creación (fin)"
                name={"fechaFin"}
                id={"fechaFin"}
                selected={moment(this.state.fields.fechaFin, "YYYY-MM-DD")}
                onChange={this.onChangeFecha("fechaFin")}
              />
            </Col>

            <Col sm="3">
              <BaseInput
                label="Club"
                name="Club"
                type="select"
                id="club"
                size={"sm"}
                value={this.state.fields.clubId}
                options={[{ value: -1, label: "Seleccionar el club" }].concat(
                  this.props.clubs.map(({ clubId, nombre }) => {
                    return {
                      value: clubId,
                      label: nombre
                    };
                  })
                )}
                onChange={e => this.onChangeField(e, "clubId")}
              />
            </Col>

            <Col sm={"3"}>
              <br />
              <Button
                disabled={
                  !this.state.fields.fecha || !this.state.fields.fechaFin
                }
                type="submit"
                block
              >
                Buscar Lotes
              </Button>
            </Col>
          </Row>
        </Form>

        <hr />
        {tableLteData && (
          <span>
            <h5 className="my-4">Resultados de la búsqueda</h5>
            <Row>
              <Col xs="12">
                <ReactTable
                  pages={1}
                  data={tableLteData}
                  // data={dataTable}
                  columns={listDiscountsTablecolumnsLTE}
                  showPagination={false}
                  sortable={true}
                  multiSort={true}
                  resizable={true}
                  pageSize={this.props.discounts.dataLteList.length + 3}
                />
              </Col>
            </Row>
          </span>
        )}

        {this.state.lote && (
          <span>
            <Row className="mt-4 mb-2">
              <h5 className="my-4">Listado de códigos</h5>
              <Col sm={{ size: 3, offset: 9 }}>
                <Button
                  className={`${s.buttonMarginTop} ${s.primaryButton}`}
                  onClick={this.getFile}
                  size="sm"
                  disabled={this.props.discounts.discountList ? false : true}
                  block
                >
                  Descargar Archivo
                </Button>
              </Col>
            </Row>

            <Row>
              <Col xs="12">
                <PagedTable
                  data={
                    this.props.discounts.discountList
                      ? this.props.discounts.discountList
                      : []
                  }
                  columns={listDiscountsTablecolumnslist}
                  // actions={actions}
                  fetchData={this.fetchData}
                  searching={this.props.discounts.gettingList}
                />
              </Col>
            </Row>
          </span>
        )}
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(DiscountCodeListPage));
