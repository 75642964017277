import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import ReactTable from "react-table";
import { Button, Col, Container, Row } from "reactstrap";
import BaseCsvIconDownloader from "../../../toolkit/baseCsvIconDownloader";
import BaseInput from "../../../toolkit/baseInput";
import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import { getPeriodsAnualRangeRequest } from "../../actions/periods";
import {
  downloadComissionsSummaryReport,
  getComissionsSummaryReportRequest
} from "../../actions/reports";
import MexicanCurrency from "../common/currency";
import PercentageDisplay from "../common/percentage";
import s from "./styles.scss";

interface Props {}

interface State {
  sellerId: null;
  periodId: null;
  status: null;
  initialDate: string;
  finalDate: string;
  disabledSearch: boolean;
  comissionsSummaryReport: any[];
  sellers: any[];
  periods: any[];
}

class ComissionsSummaryReport extends React.Component<Props, State> {
  state = {
    sellerId: null,
    periodId: null,
    status: null,
    initialDate: null,
    finalDate: null,
    disabledSearch: false,
    comissionsSummaryReport: [],
    sellers: [],
    periods: []
  };

  componentDidMount() {
    // TODO: REVISIÓN 1, determinar el vendedor por la sesión
    /*getSellersRequest().then(({ data }) => {
      this.setState({ sellers: data });
    });*/
    getPeriodsAnualRangeRequest().then(({ data }) => {
      this.setState({
        periodId: data.periodoEnCursoId,
        periods: data.periodos
      });
    });
  }

  onChangeSellerPeriodDrop = (e, fieldName) => {
    const state = this.state;
    state[fieldName] = e.target.value;
    this.setState({ ...state });
  };

  onChangeSeller = selected => {
    if (selected.length > 0) {
      let state = this.state;
      state.sellerId = selected[0]["id"];
      this.setState(state);
    } else {
      let state = this.state;
      state.sellerId = null;
      this.setState(state);
    }
  };

  search = () => {
    //if (!!this.state.sellerId) {
    infoNotification("Consultando las comisiones...");
    getComissionsSummaryReportRequest(
      this.state.sellerId,
      this.state.periodId,
      this.state.status
    )
      .then(({ data: comissionsSummaryReport }) => {
        successNotification("Consulta efectuada exitosamente");
        if (!!(comissionsSummaryReport || []).length) {
          const sum = comissionsSummaryReport.reduce(
            (acum, { comisionMonto }) => acum + comisionMonto,
            0.0
          );

          comissionsSummaryReport.push({});
          comissionsSummaryReport.push({
            comisionPorcentaje: "Total: ",
            comisionMonto: sum
          });
        }

        this.setState({ comissionsSummaryReport });
      })
      .catch(() => {
        errorNotification("Error en la consulta de comisiones");
      });
    //}
  };

  render() {
    const columns = [
      {
        Header: "Id",
        accessor: "comisionId",
        width: 50,
        className: "text-center"
      },
      {
        Header: "Tipo",
        accessor: "comisionTipo"
      },
      {
        Header: "Descripción",
        accessor: "comisionDescripcion",
        width: 350
      },
      {
        Header: "Estatus",
        accessor: "comisionEstatus"
      },
      {
        Header: "Importe venta",
        accessor: "comisionImporteVenta",
        Cell: row => {
          return !!row.original.comisionImporteVenta ? (
            <MexicanCurrency quantity={row.original.comisionImporteVenta} />
          ) : (
            <span></span>
          );
        }
      },
      {
        Header: "Porcentaje",
        accessor: "comisionPorcentaje",
        Cell: row => {
          return !!row.original.comisionPorcentaje &&
            !isNaN(row.original.comisionPorcentaje) ? (
            <PercentageDisplay quantity={row.original.comisionPorcentaje} />
          ) : (
            <span>{row.original.comisionPorcentaje || ""}</span>
          );
        }
      },
      {
        Header: "Monto",
        accessor: "comisionMonto",
        Cell: row => {
          return !!row.original.comisionMonto ? (
            <MexicanCurrency quantity={row.original.comisionMonto} />
          ) : (
            <span></span>
          );
        }
      },
      {
        Header: "Fecha",
        accessor: "comisionFecha",
        className: "text-center"
      }
    ];

    return (
      <Container className="mt-3 mb-5 p-0">
        <Row className="my-4">
          {/*  
          <Col>
            <Label className={s.label}>Nombre de vendedor</Label>
            <Typeahead
              id={"ddlSellerId"}
              promptText={"Empleado"}
              bsSize={"sm"}
              onChange={selected => this.onChangeSeller(selected)}
              emptyLabel={"Sin coincidencias"}
              disabled={!!(this.state.sellers || []).length ? false : true}
              maxResults={20}
              options={(this.state.sellers || []).map(item => {
                return {
                  id: item["userId"],
                  label: `${item["name"]} ${item["firstSurname"]} ${item["secondSurname"]}`
                };
              })}
            />
          </Col>
          */}
          <Col>
            <BaseInput
              type="select"
              label={"* Fecha comisión"}
              name="ddlPeriodId"
              id="ddlPeriodId"
              size={"sm"}
              value={this.state.periodId}
              options={[{ value: 0, label: "Seleccionar un periodo" }].concat(
                (this.state.periods || []).map(({ id, nombre }) => {
                  return {
                    value: id,
                    label: nombre
                  };
                })
              )}
              onChange={e => this.onChangeSellerPeriodDrop(e, "periodId")}
            />
          </Col>
          <Col>
            <BaseInput
              type="select"
              label={"Estatus"}
              name="ddlStatus"
              id="ddlStatus"
              size={"sm"}
              value={this.state.status}
              options={[{ value: "", label: "Seleccionar un estatus" }].concat([
                {
                  value: "PENDIENTE",
                  label: "PENDIENTE"
                },
                {
                  value: "AUTORIZADA",
                  label: "AUTORIZADA"
                },
                {
                  value: "RECHAZADA",
                  label: "RECHAZADA"
                }
              ])}
              onChange={e => this.onChangeSellerPeriodDrop(e, "status")}
            />
          </Col>
          <Col className="mt-4">
            <Button
              size="sm"
              disabled={!this.state.periodId}
              block
              onClick={this.search}
            >
              Buscar
            </Button>
          </Col>
        </Row>

        <BaseCsvIconDownloader
          rowCount={(this.state.comissionsSummaryReport || []).length}
          executeReportDownload={downloadComissionsSummaryReport}
          getFilters={() => {
            return {
              vendedorId: this.state.sellerId,
              periodoId: this.state.periodId,
              estatus: this.state.status
            };
          }}
        />

        <ReactTable
          data={this.state.comissionsSummaryReport}
          columns={columns}
          showPagination={false}
          sortable={false}
          multiSort={false}
          resizable={true}
          pageSize={(this.state.comissionsSummaryReport || []).length + 3}
        />
      </Container>
    );
  }
}

export default withStyles(s)(ComissionsSummaryReport);
