import React from "react";
import { connect } from "react-redux";
import * as catalogActions from "../../../Home/actions/catalogs";
import { MaintenanceSelector } from "../../components/MaintenanceSelector";
import { Maintenance } from "../../../types-business/Products";
import { handleRequestError } from "../../../utils/helpers";

interface Props {
  getMaintenancesByMembership: (membresiaId: string) => void;
  getMaintenances: () => any;
  membershipId: string;
  clubId: string;
  loading: boolean;
  maintenances: Maintenance[];
  disabled: boolean;
  onChange: (value: string) => any;
  value: string;
  quotationUse: string;
}

const getMaintenance = (maintenaces: any, maintenanceId: any) => {
  return maintenaces.find(
    ({ datosBasicosMantenimientoId }) =>
      datosBasicosMantenimientoId == maintenanceId
  );
};

class MaintenanceSelectorContainer extends React.Component<Props> {
  state = {
    maintenances: []
  };

  componentDidMount() {
    this.loadMaintenances(this.props.membershipId, this.props.clubId);
  }

  componentDidUpdate({ membershipId: prevMembershipId }) {
    const { membershipId, clubId } = this.props;
    if (prevMembershipId != membershipId) {
      this.loadMaintenances(membershipId, clubId);
    }
  }

  loadMaintenances = (membershipId, clubId) => {
    if (!this.props.disabled) {
      if (membershipId) {
        catalogActions
          .getMaintenancesByMembershipForQuotation(
            membershipId,
            clubId,
            this.props.quotationUse
          )
          .then(({ data }) => {
            this.setState({ maintenances: data });
          })
          .catch(handleRequestError);
      } else {
        this.setState({ maintenances: null });
        this.props.getMaintenances();
      }
    }
  };

  getMaintenaces = () => {
    if (!!this.state.maintenances && !!this.state.maintenances.length) {
      return [...this.state.maintenances];
    } else if (!!this.props.maintenances && !!this.props.maintenances.length) {
      return [...this.state.maintenances];
    } else {
      return [];
    }
  };

  render() {
    return (
      <MaintenanceSelector
        value={this.props.value}
        maintenances={this.getMaintenaces()}
        onChange={value => {
          const maintenanceObject = getMaintenance(
            this.getMaintenaces(),
            value
          );
          this.props.onChange(maintenanceObject);
        }}
        disabled={this.props.loading || this.props.disabled}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getMaintenancesByMembership: (membresiaId: string) => {
    dispatch(catalogActions.getMaintenancesByMembershipId(membresiaId));
  },
  getMaintenances: () => {
    dispatch(catalogActions.getMaintenances());
  }
});

const mapStateToProps = state => {
  const {
    catalogs: { maintenances, gettingMaintenances }
  } = state;

  return {
    maintenances,
    loading: gettingMaintenances
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaintenanceSelectorContainer);
