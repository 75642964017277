import React from "react";
import { connect } from "react-redux";
import * as documentActions from "../../actions/documents";
import BasicDataDocumentForm from "../../components/basicDataDocumentForm";

interface Props {
  saveBasicDocument: (document: object) => any;
  updateBasicDocument: (convenioId: string, document: object) => any;
  successfulSave: boolean;
  basicDocumentSaved: object;
  getBasicDocument: (id: string) => any;
  document: object;
  onClickSave: (f: any) => any;
  onSuccessfulSave: () => any;
}

class BasicDataDocumentContainer extends React.Component<Props, void> {
  shouldComponentUpdate(nextProps) {
    if (
      this.props.basicDocumentSaved !== nextProps.basicDocumentSaved &&
      nextProps.basicDocumentSaved
    ) {
      this.props.onSuccessfulSave();
      return false;
    }

    return true;
  }

  render() {
    return (
      <div className="h-100 w-100 p-0 w-0">
        <BasicDataDocumentForm
          saveBasicDocument={this.props.saveBasicDocument}
          document={this.props.document}
          updateBasicDocument={this.props.updateBasicDocument}
          onClickSave={this.props.onClickSave}
          getBasicDocument={this.props.getBasicDocument}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  saveBasicDocument: (document: object) => {
    dispatch(documentActions.saveBasicDocument(document));
  },
  getBasicDocument: (id: string) => {
    dispatch(documentActions.getBasicData(id));
  },
  updateBasicDocument: (convenioId: string, document: object) => {
    dispatch(documentActions.updateBasicDocument(convenioId, document));
  }
});

const mapStateToProps = state => {
  const { documents } = state;
  return {
    basicDocumentSaved: documents.basicDocumentSaved,
    document: documents.basicDocument
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasicDataDocumentContainer);
