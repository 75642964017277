import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Row, Col, Label } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import s from "./styles.scss";
import DatePicker from "../common/DatePicker";
import moment from "moment";
import TimePicker from "react-times";

interface Props {
  clubs: { clubId: number; nombre: string }[];
  onChangeGenData: (genData: any, validated: boolean) => any;
  billingData: {
    billingCatalogs: any[];
    dataResponse: object;
    gettingCatalogs: boolean;
    sendingData: boolean;
    successSend: boolean;
  };
  plataformaOrigen: { label }[];
  terminosPagoErp: { label }[];
  tipoTransaccionErp: { label }[];
  unidadNegocio: { label }[];
  disabled: boolean;
  fields: {
    empresaId: string;
    clubId: string;
    emisorRI: string;
    regimenFiscalEmisor: string;
    fechaExpedicion: any;
    lugarExpedicion: string;
    folio: string;
    metodoPago: string;
    formaPago: string;
    moneda: string;
    tipoCambio: string;
    subTotal: string;
    total: string;
    condicionPago: string;
    observaciones: string;
    procesoId: string;
    plataformaOrigen: string;
    terminosPagoErp: string;
    tipoTransaccionErp: string;
    unidadNegocio: string;
    contactoTelefonoCodigoPais: string;
    contactoTelefonoExtension: string;
  };
}

interface State {}

const getEmpresas = clubs => {
  const empresaCache = {};
  const empresas = [{ value: "", label: "Seleccionar la Empresa" }];
  clubs.forEach((club, index) => {
    const empresa = club.empresa;
    if (!empresaCache[empresa]) {
      empresaCache[empresa] = true;
      empresas.push({ value: club.clubId, label: club.empresaInfo.nombre });
    }
  });
  return empresas;
};

class GenData extends React.Component<Props, State> {
  state = {
    hora: moment().format("HH:mm"),
    fecha: moment().format("YYYY-MM-DD")
  };

  onChangeField = async (e, fieldName) => {
    const fields = { ...this.props.fields };
    fields[fieldName] = e.target.value;

    if (this.validateFields()) {
      this.props.onChangeGenData(fields, true);
    } else {
      this.props.onChangeGenData(fields, false);
    }
  };

  validateFields = () => {
    const { fields } = this.props;
    for (const key in fields) {
      if (fields.hasOwnProperty(key)) {
        const element = fields[key];

        if (element == "") return false;
      }
    }
    return true;
  };

  onChangeDate = async dateString => {
    const fields = { ...this.props.fields };
    fields.fechaExpedicion = dateString;
    this.props.onChangeGenData(fields, false);
  };

  onChangeFecha = () => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      let state = { ...this.state };
      state.fecha = date;
      this.setState(state);
      this.convertToUTC();
    };
  };

  convertToUTC = async () => {
    const fields = { ...this.props.fields };

    fields.fechaExpedicion = moment(
      `${this.state.fecha},${this.state.hora}`,
      "YYYY-MM-DD,h:mm"
    ).toISOString();

    this.props.onChangeGenData(fields, false);
  };

  onTimeChange = ({ hour, minute }) => {
    let hora = moment(`${hour}:${minute}`, "HH:mm").format("HH:mm");
    const state = { ...this.state };
    state.hora = hora;
    this.setState(state);
    this.convertToUTC();
  };

  render() {
    let CatRegimenFiscalEmisor = [];
    let CatCondicionPago = [];
    let CatFormaPago = [];
    let CatMetodoPago = [];
    let CatMoneda = [];

    for (let catalog of this.props.billingData.billingCatalogs) {
      if (catalog["clave"] == "CatRegimenFiscalEmisor")
        CatRegimenFiscalEmisor = catalog["data"];
      else if (catalog["clave"] == "CatCondicionPago")
        CatCondicionPago = catalog["data"];
      else if (catalog["clave"] == "CatFormaPago")
        CatFormaPago = catalog["data"];
      else if (catalog["clave"] == "CatMetodoPago")
        CatMetodoPago = catalog["data"];
      else if (catalog["clave"] == "CatMoneda") CatMoneda = catalog["data"];
      else if (catalog["clave"] == "CatMoneda") CatMoneda = catalog["data"];
    }

    return (
      <span>
        <Row>
          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label="* Club"
              name="Club"
              type="select"
              id="club"
              size={"sm"}
              value={this.props.fields.clubId}
              options={[{ value: -1, label: "Seleccionar el club" }].concat(
                this.props.clubs.map(({ clubId, nombre }) => {
                  return {
                    value: clubId,
                    label: nombre
                  };
                })
              )}
              onChange={e => this.onChangeField(e, "clubId")}
            />
          </Col>
          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*Empresa"}
              name={`Empresa`}
              type="select"
              options={getEmpresas(this.props.clubs)}
              id={`empresaId`}
              placeholder={"Empresa"}
              onChange={e => this.onChangeField(e, "empresaId")}
              value={this.props.fields.empresaId}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*emisorRI"}
              name={`emisorRI`}
              type="text"
              id={`emisorRI`}
              placeholder={"emisorRI"}
              value={this.props.fields.emisorRI}
              onChange={e => this.onChangeField(e, "emisorRI")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*Regimen Fiscal Emisor"}
              name={`regimenFiscalEmisor`}
              type="select"
              id={`regimenFiscalEmisor`}
              placeholder={"regimenFiscalEmisor"}
              options={[
                { value: -1, label: "Seleccionar el Regimen Fiscal Emisor" }
              ].concat(
                CatRegimenFiscalEmisor.map(({ clave, descripcion }) => {
                  return {
                    value: clave,
                    label: descripcion
                  };
                })
              )}
              value={this.props.fields.regimenFiscalEmisor}
              onChange={e => this.onChangeField(e, "regimenFiscalEmisor")}
            />
          </Col>

          <Col sm="3">
            <DatePicker
              label="Fecha emisión"
              name={"fechaExpedicion"}
              id={"fechaExpedicion"}
              disabled={this.props.disabled}
              selected={this.props.fields["fechaExpedicion"]}
              onChange={this.onChangeFecha()}
            />
          </Col>

          <Col sm="3">
            <Label>Hora</Label>
            <TimePicker
              disabled={this.props.disabled}
              onTimeChange={this.onTimeChange.bind(this)}
              theme="classic"
              withoutIcon
              timeMode="24"
              time={moment(new Date(this.props.fields.fechaExpedicion)).format(
                "HH:mm"
              )}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*lugar Expedicion"}
              name={`lugarExpedicion`}
              type="text"
              id={`lugarExpedicion`}
              placeholder={"lugar Expedicion"}
              value={this.props.fields.lugarExpedicion}
              onChange={e => this.onChangeField(e, "lugarExpedicion")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*Folio"}
              name={`folio`}
              type="text"
              id={`folio`}
              placeholder={"Folio"}
              value={this.props.fields.folio}
              onChange={e => this.onChangeField(e, "folio")}
            />
          </Col>
        </Row>

        <h5 className={s.grayTitle}>Descripción de Pago</h5>

        <Row>
          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*Método de pago "}
              name={`metodoPago`}
              type="select"
              id={`metodoPago`}
              placeholder={"metodoPago"}
              options={[{ value: -1, label: "Seleccionar método pago" }].concat(
                CatMetodoPago.map(({ clave, descripcion }) => {
                  return {
                    value: clave,
                    label: descripcion
                  };
                })
              )}
              value={this.props.fields.metodoPago}
              onChange={e => this.onChangeField(e, "metodoPago")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*Forma de pago"}
              name={`formaPago`}
              type="select"
              id={`formaPago`}
              placeholder={"formaPago"}
              options={[
                { value: -1, label: "Seleccionar forma de pago" }
              ].concat(
                CatFormaPago.map(({ clave, descripcion }) => {
                  return {
                    value: clave,
                    label: descripcion
                  };
                })
              )}
              value={this.props.fields.formaPago}
              onChange={e => this.onChangeField(e, "formaPago")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*Moneda"}
              name={`moneda`}
              type="select"
              id={`moneda`}
              placeholder={"moneda"}
              options={[{ value: -1, label: "Seleccionar moneda" }].concat(
                CatMoneda.map(({ clave, descripcion }) => {
                  return {
                    value: clave,
                    label: descripcion
                  };
                })
              )}
              value={this.props.fields.moneda}
              onChange={e => this.onChangeField(e, "moneda")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*Tipo de Cambio"}
              name={`tipoCambio`}
              type="text"
              id={`tipoCambio`}
              placeholder={"tipoCambio"}
              value={this.props.fields.tipoCambio}
              onChange={e => this.onChangeField(e, "tipoCambio")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*subTotal"}
              name={`subTotal`}
              type="text"
              id={`subTotal`}
              placeholder={"subTotal"}
              value={this.props.fields.subTotal}
              onChange={e => this.onChangeField(e, "subTotal")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*total"}
              name={`total`}
              type="text"
              id={`total`}
              placeholder={"total"}
              value={this.props.fields.total}
              onChange={e => this.onChangeField(e, "total")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*Condicion de pago"}
              name={`condicionPago`}
              type="select"
              id={`condicionPago`}
              placeholder={"condicionPago"}
              options={[
                { value: -1, label: "Seleccionar condición pago" }
              ].concat(
                CatCondicionPago.map(({ clave, descripcion }) => {
                  return {
                    value: clave,
                    label: descripcion
                  };
                })
              )}
              value={this.props.fields.condicionPago}
              onChange={e => this.onChangeField(e, "condicionPago")}
            />
          </Col>

          <Col sm="12">
            <BaseInput
              disabled={this.props.disabled}
              label={"*observaciones"}
              name={`observaciones`}
              type="text"
              id={`observaciones`}
              placeholder={"observaciones"}
              value={this.props.fields.observaciones}
              onChange={e => this.onChangeField(e, "observaciones")}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*Id proceso"}
              name={`procesoId`}
              type="text"
              id={`procesoId`}
              placeholder={"procesoId"}
              value={this.props.fields.procesoId}
              onChange={e => this.onChangeField(e, "procesoId")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*Plataforma Origen"}
              name={`plataformaOrigen`}
              type="select"
              id={`plataformaOrigen`}
              placeholder={"plataformaOrigen"}
              options={[
                { value: -1, label: "Seleccionar el plataforma origen" }
              ].concat(
                this.props.plataformaOrigen.map(({ label }) => {
                  return {
                    value: label,
                    label: label
                  };
                })
              )}
              value={this.props.fields.plataformaOrigen}
              onChange={e => this.onChangeField(e, "plataformaOrigen")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*Terminos de Pago de ERP"}
              name={`terminosPagoErp`}
              type="select"
              id={`terminosPagoErp`}
              placeholder={"terminosPagoErp"}
              options={[
                { value: -1, label: "Seleccionar termino de pago" }
              ].concat(
                this.props.terminosPagoErp.map(({ label }) => {
                  return {
                    value: label,
                    label: label
                  };
                })
              )}
              value={this.props.fields.terminosPagoErp}
              onChange={e => this.onChangeField(e, "terminosPagoErp")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*Tipo de Transaccion ERP"}
              name={`tipoTransaccionErp`}
              type="select"
              id={`tipoTransaccionErp`}
              placeholder={"tipoTransaccionErp"}
              options={[
                { value: -1, label: "Seleccionar el Tipo item" }
              ].concat(
                this.props.tipoTransaccionErp.map(({ label }) => {
                  return {
                    value: label,
                    label: label
                  };
                })
              )}
              value={this.props.fields.tipoTransaccionErp}
              onChange={e => this.onChangeField(e, "tipoTransaccionErp")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*Unidad de Negocio"}
              name={`unidadNegocio`}
              type="select"
              id={`unidadNegocio`}
              placeholder={"unidadNegocio"}
              options={[
                { value: -1, label: "Seleccionar Unidad de Negocio" }
              ].concat(
                this.props.unidadNegocio.map(({ label }) => {
                  return {
                    value: label,
                    label: label
                  };
                })
              )}
              value={this.props.fields.unidadNegocio}
              onChange={e => this.onChangeField(e, "unidadNegocio")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"* Telefono Codigo Pais"}
              name={`contactoTelefonoCodigoPais`}
              type="number"
              id={`contactoTelefonoCodigoPais`}
              placeholder={"contactoTelefonoCodigoPais"}
              value={this.props.fields.contactoTelefonoCodigoPais}
              onChange={e =>
                this.onChangeField(e, "contactoTelefonoCodigoPais")
              }
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*Extensión "}
              name={`contactoTelefonoExtension`}
              type="number"
              id={`contactoTelefonoExtension`}
              placeholder={"contactoTelefonoExtension"}
              value={this.props.fields.contactoTelefonoExtension}
              onChange={e => this.onChangeField(e, "contactoTelefonoExtension")}
            />
          </Col>
        </Row>
      </span>
    );
  }
}

export default withStyles(s)(GenData);
