import React from "react";
import ModalFrame from "../modalFrame";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";

// import s from "./styles.scss";

interface Props {
  url: string;
  title: string;
}

interface State {
  isOpen: boolean;
}

class ModalPicture extends React.Component<Props, State> {
  state = {
    isOpen: false
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    const { url, title } = this.props;

    return (
      <span>
        <ModalFrame
          title={title}
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          size="lg"
        >
          <img
            src={url}
            alt={title}
            className="img-fluid mx-auto d-block"
          ></img>
        </ModalFrame>
        <span
          onClick={this.toggle}
          style={{
            cursor: "pointer",
            color: "black"
          }}
        >
          <FontAwesomeIcon icon={faImage} />
        </span>
      </span>
    );
  }
}

export default ModalPicture;
