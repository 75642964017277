import axios from "axios";
import constants from "../../utils/constants";
import FileDownload from "js-file-download";
import { errorNotification } from "../../utils/notifications";

const LOTES_BASE_URL = "/api/mit";
const FILE_URL_BASE = `${constants.BASE_URL}/${constants.DOCUMENTS_BASE_URL}`;

export const getLotesCargosAutomaticos = (params: object) => {
  return axios.get(
    `${constants.BASE_URL}${LOTES_BASE_URL}/lotes-cargos-automaticos`,
    {
      withCredentials: true,
      params
    }
  );
};

export const downloadLotesCargosAutomaticos = (params: any, callback: any) => {
  return axios
    .get(
      `${constants.BASE_URL}${LOTES_BASE_URL}/lotes-cargos-automaticos/reporte`,
      {
        withCredentials: true,
        params,
        responseType: "blob"
      }
    )
    .then(({ data }) => {
      FileDownload(data, "EnvioACobro.csv");
      callback && callback();
    })
    .catch(error => {
      errorNotification("No se pudo descargar el archivo");
      callback && callback(error);
    });
};

export const downloadGcpFile = (storage, fileName, callback?) => {
  return axios
    .get(`${FILE_URL_BASE}/${storage}`, {
      withCredentials: true,
      responseType: "blob"
    })
    .then(({ data }) => {
      FileDownload(data, fileName);
      callback && callback();
    })
    .catch(error => {
      errorNotification("No se pudo descargar el archivo");
      callback && callback(error);
    });
};
