import React from "react";

import ReactTable from "react-table";
import { Container, Row, Col } from "reactstrap";

import { userHasPermission } from "../../../../../utils/accessTree";
import permissionCodes from "../../../../../utils/permissionCodes";
import BaseButtonWithIconAndTooltip from "../../../../../toolkit/baseButtonWithIconAndTooltip";
import { faCheck, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  applyPartnerMembershipVacationFee,
  cancelPartnerMembershipVacationFee,
  signPartnerMembershipVacationFee
} from "../../../../actions/partners";
import { handleRequestError } from "../../../../../utils/helpers";
import ConfirmModal from "../../../../components/common/confirmModal/confirmModal";
import ArchivoControl from "../../../expedientsV2controls/archivo/archivo";
import * as actionsExp from "../../../../actions/expedientes";
import {
  Archivo,
  Expediente,
  RequestArchivo
} from "../../../expedientsV2controls/types";
import { Preview } from "../../../../../types/IAction";
import { ReduxConnect } from "../../../../../toolkit/decorators/Artifact";

const estatusAllowedTocancel = ["CREADA", "DOCUMENTO_FIRMADO", "CADUCADA"];
const estatusAllowedToSign = ["CREADA", "DOCUMENTO_FIRMADO"];
const estatusAllowedToApply = ["DOCUMENTO_FIRMADO"];

const mapDispatchToProps = dispatch => ({
  /// Dispatchers de Expedientes
  getExpedienteFromMembresia: (idExpediente): Promise<Preview> => {
    return dispatch(actionsExp.getExpedienteFromMembresia(idExpediente));
  },
  postRefresh: (requestArchivo: RequestArchivo): Promise<Preview> => {
    return dispatch(actionsExp.postRefresh(requestArchivo));
  },
  postEnvioCorreo: (idExpediente, idItem): Promise<Preview> => {
    return dispatch(actionsExp.postEnvioCorreo(idExpediente, idItem));
  }
  ////
});

const mapStateToProps = state => {
  const { expedientes } = state;

  return {
    /// State de Expedientes
    expedienteMembresia: expedientes.expedienteMembresia,
    expedienteMembresiaLoading: expedientes.expedienteMembresiaLoading,
    archivoRefreshLoading: expedientes.archivoRefreshLoading
    ////
  };
};

interface Props {
  vacationFeesList: object[];
  partnerMembershipId: String;
  invalidateStateValue: (fieldName: string, value: any) => void;
  getMembershipVacationFees: () => void;
  addVacationFee: () => void;

  // Datos del expediente y sus archivos
  expedienteMembresia?: Expediente;
  expedienteMembresiaLoading?: boolean;
  archivoRefreshLoading?: boolean;

  // Actions del expediente
  getExpedienteFromMembresia?: (idExpediente) => Promise<Preview>;
  postRefresh?: (requestArchivo: RequestArchivo) => Promise<Preview>;
  postEnvioCorreo?: (idExpediente, idItem) => Promise<Preview>;
}

@ReduxConnect(mapStateToProps, mapDispatchToProps)
class VacationFeesTable extends React.Component<Props> {
  state = {
    confirmCancelVacationFeeModalIsOpen: false,
    vacationFeeIdToCancel: null,
    confirmApplyVacationFeeModalIsOpen: false,
    vacationFeeIdToApply: null
  };

  columns = [
    {
      Header: "Fecha solicitud",
      className: "text-center",
      accessor: "fechaSolicitud"
    },
    {
      Header: "Fecha aplicable Inicio",
      className: "text-center",
      accessor: "fechaAplicableInicio"
    },
    {
      Header: "Fecha aplicable Fin",
      className: "text-center",
      accessor: "fechaAplicableFin"
    },
    {
      Header: "Estatus",
      className: "text-center",
      accessor: "estatus"
    },
    {
      Header: "Quincena de acceso",
      className: "text-center",
      accessor: "quincena"
    },
    {
      Header: "Documento",
      className: "text-center",
      Cell: row => {
        const { estatus } = row.original;
        return (
          <div>
            <ArchivoControl
              key={"archivo-item-" + row.original.id}
              archivo={{
                ...row.original.documentoArchivo,
                idEntity: row.original.id
              }}
              userId={0}
              sendEmail={this.sendEmail}
              update={this.update}
              sm={true}
              botonCorreo={estatus == "APLICADA"}
              botonFirmaOEscanner={estatusAllowedToSign.includes(estatus)}
              botonRegenrar={false}
            />
          </div>
        );
      }
    },
    {
      Header: () => (
        <div className="w-100 text-center">
          {userHasPermission(
            permissionCodes.PARTNER_MEMBERSHIP_TAB_VACATION_FEE_CREATE_REQUEST
          ) ? (
            <BaseButtonWithIconAndTooltip
              id={`addVacationFee_abc`}
              icon={faPlus}
              tooltipTitle={"Crear solicitud de cuota vacacional"}
              onClick={() => {
                if (!!this.props.addVacationFee) {
                  this.props.addVacationFee();
                }
              }}
            />
          ) : null}
        </div>
      ),
      className: "text-center",
      Cell: row => {
        return (
          <div>
            {userHasPermission(
              permissionCodes.PARTNER_MEMBERSHIP_TAB_VACATION_FEE_APPLY_REQUEST
            ) && estatusAllowedTocancel.includes(row.original.estatus) ? (
              <BaseButtonWithIconAndTooltip
                id={`cancelVacationFee_${row.original.id}`}
                icon={faTimes}
                tooltipTitle={"Cancelar solicitud de cuota vacacional"}
                onClick={() => {
                  this.setState({
                    vacationFeeIdToCancel: row.original.id,
                    confirmCancelVacationFeeModalIsOpen: true
                  });
                }}
              />
            ) : null}{" "}
            {userHasPermission(
              permissionCodes.PARTNER_MEMBERSHIP_TAB_VACATION_FEE_APPLY_REQUEST
            ) && estatusAllowedToApply.includes(row.original.estatus) ? (
              <BaseButtonWithIconAndTooltip
                id={`applyVacationFee_${row.original.id}`}
                icon={faCheck}
                tooltipTitle={"Aplicar solicitud de cuota vacacional"}
                onClick={() => {
                  this.setState({
                    vacationFeeIdToApply: row.original.id,
                    confirmApplyVacationFeeModalIsOpen: true
                  });
                }}
              />
            ) : null}
          </div>
        );
      }
    }
  ];

  handlevacationFeeCancel = () => {
    const { vacationFeeIdToCancel } = this.state;
    const { partnerMembershipId } = this.props;

    cancelPartnerMembershipVacationFee(
      partnerMembershipId,
      vacationFeeIdToCancel
    )
      .then(() => {
        this.setState({
          vacationFeeIdToCancel: null,
          confirmCancelVacationFeeModalIsOpen: false
        });
        this.props.invalidateStateValue("vacationFees", []);
        this.props.getMembershipVacationFees();
      })
      .catch(handleRequestError);
  };

  handleVacationFeeApply = () => {
    const { vacationFeeIdToApply } = this.state;
    const { partnerMembershipId } = this.props;

    applyPartnerMembershipVacationFee(partnerMembershipId, vacationFeeIdToApply)
      .then(() => {
        this.setState({
          vacationFeeIdToApply: null,
          confirmApplyVacationFeeModalIsOpen: false
        });
        this.props.invalidateStateValue("vacationFees", []);
        this.props.getMembershipVacationFees();
      })
      .catch(handleRequestError);
  };

  sendEmail = async (archivo: Archivo) => {
    const preview = await this.props.postEnvioCorreo(
      archivo.idExpediente,
      archivo.idItem
    );
    return preview;
  };

  update = async (archivo: Archivo) => {
    const { idEntity } = archivo;

    await this.props.invalidateStateValue("vacationFees", []);
    await signPartnerMembershipVacationFee(
      this.props.partnerMembershipId,
      idEntity
    );
    await this.props.getMembershipVacationFees();
  };

  render() {
    return (
      <Container className="p-0">
        <ConfirmModal
          title="Cancelar solicitud de cuota vacacional"
          message="Se cancelará la solicitud de cuota vacacional, ¿Desea continuar?"
          isOpen={this.state.confirmCancelVacationFeeModalIsOpen}
          requireInput={false}
          ok={this.handlevacationFeeCancel}
          cancel={() => {
            this.setState({
              vacationFeeIdToCancel: null,
              confirmCancelVacationFeeModalIsOpen: false
            });
          }}
        />
        <ConfirmModal
          title="Aplicar solicitud de cuota vacacional"
          message="Se aplicará la solicitud de cuota vacacional (generación de cargo de mantenimiento correspondiente), ¿Desea continuar?"
          isOpen={this.state.confirmApplyVacationFeeModalIsOpen}
          requireInput={false}
          ok={this.handleVacationFeeApply}
          cancel={() => {
            this.setState({
              vacationFeeIdToApply: null,
              confirmApplyVacationFeeModalIsOpen: false
            });
          }}
        />
        <Row>
          <Col>
            <ReactTable
              data={this.props.vacationFeesList || []}
              columns={this.columns}
              pageSize={20}
              showPagination={true}
              className="mt-3"
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default VacationFeesTable;
