import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import { Button, Col, Container, Row } from "reactstrap";
import MexicanCurrency from "../common/currency";
import { PropsToAccrualBySaleDate } from "./interfaces";
import s from "./styles.scss";

class TableReportToAccrualBySaleDate extends React.Component<
  PropsToAccrualBySaleDate
> {
  //COLUMN DEFINITION
  columns = [
    {
      Header: () => <p className="font-weight-bold">Club clave</p>,
      width: 150,
      className: "text-center",
      accessor: "rdClubClave"
    },
    {
      Header: () => <p className="font-weight-bold">Club</p>,
      width: 150,
      className: "text-center",
      accessor: "rdClubNombre"
    },
    {
      Header: () => <p className="font-weight-bold">ITEM ID</p>,
      className: "text-center",
      accessor: "rdItemId"
    },
    {
      Header: () => <p className="font-weight-bold">ESTATUS</p>,
      className: "text-center",
      accessor: "rdItemEstatus"
    },
    {
      Header: () => <p className="font-weight-bold">TIPO</p>,
      className: "text-center",
      accessor: "rdItemTipo"
    },
    {
      Header: () => <p className="font-weight-bold">FECHA CREACION</p>,
      className: "text-center",
      accessor: "rdItemFechaCreacion"
    },
    {
      Header: () => <p className="font-weight-bold">FECHA APLICA</p>,
      className: "text-center",
      accessor: "rdItemFechaAplica"
    },
    {
      Header: () => <p className="font-weight-bold">PRECIO</p>,
      id: 0,
      class: "text-center",
      accessor: "rdItemPrecio",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.rdItemPrecio} />;
      }
    },
    {
      Header: () => <p className="font-weight-bold">PRECIO</p>,
      id: 0,
      class: "text-center",
      accessor: "rdItemPrecioSinIva",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.rdItemPrecioSinIva} />;
      }
    },
    {
      Header: () => <p className="font-weight-bold">NO. MESES</p>,
      className: "text-center",
      accessor: "rdItemNoMeses"
    },
    {
      Header: () => <p className="font-weight-bold">NO. MESES COMPENSADOS</p>,
      className: "text-center",
      accessor: "rdItemNoCompensados"
    },
    {
      Header: () => <p className="font-weight-bold">MONTO COMPENSADO</p>,
      id: 0,
      class: "text-center",
      accessor: "rdItemMontoCompensado",
      Cell: row => {
        return (
          <MexicanCurrency quantity={row.original.rdItemMontoCompensado} />
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">MONTO SIN COMPENSAR</p>,
      id: 0,
      class: "text-center",
      accessor: "rdItemMontoSinCompensar",
      Cell: row => {
        return (
          <MexicanCurrency quantity={row.original.rdItemMontoSinCompensar} />
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">FACTURA FOLIO</p>,
      className: "text-center",
      accessor: "rdItemFacturaFolio"
    },
    {
      Header: () => <p className="font-weight-bold">FACTURA UUID</p>,
      className: "text-center",
      accessor: "rdItemFacturaUuid"
    },
    {
      Header: () => <p className="font-weight-bold">FACTURA FECHA</p>,
      className: "text-center",
      accessor: "rdItemFacturaFecha"
    },
    {
      Header: () => <p className="font-weight-bold">CUENTA INGRESO</p>,
      className: "text-center",
      accessor: "rdItemCuentaIngreso"
    },
    {
      Header: () => <p className="font-weight-bold">CUENTA DEVENGO</p>,
      className: "text-center",
      accessor: "rdItemCuentaDevengo"
    },
    {
      Header: () => <p className="font-weight-bold">DESCRIPCION</p>,
      className: "text-center",
      accessor: "rdItemDescripcion"
    }
  ];

  //CONTROL BEHAVIOR
  downloadReport = () => {
    this.props.downloadReport();
  };

  //RENDER
  render() {
    return (
      <Container className="p-0">
        {!!(this.props.data || []).length && (
          <Row className={"mt-5"}>
            <Col sm="6">
              Resultados de la búsqueda:{" "}
              <b
                style={{ fontWeight: "bolder", display: "inline-block" }}
                className="ml-3"
              >
                {(this.props.data || []).length}
              </b>
            </Col>
            <Col sm="6" className="text-right">
              <span>Exportar a CSV: </span>
              <Button
                id="btnReportExportCsv"
                color="link"
                onClick={() => {
                  this.downloadReport();
                }}
              >
                <FontAwesomeIcon icon={faFileExport} color="black" />
              </Button>
            </Col>
          </Row>
        )}

        <ReactTable
          data={this.props.data}
          columns={this.columns}
          className="mt-2 -striped -highlight"
          sortable={false}
          resizable={false}
          pageSize={20}
          loading={this.props.onSearch}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(TableReportToAccrualBySaleDate));
