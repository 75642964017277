import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typeahead } from "react-bootstrap-typeahead";
import { Label } from "reactstrap";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export const AgreementSelector = ({
  value,
  disabled,
  onChange,
  agreements,
  size = "sm"
}) => {
  // INTERRUPT: DSU -
  const ref = React.createRef();

  return (
    <div>
      <Label>Convenio:</Label>

      <Typeahead
        ref={ref}
        label={"Convenio"}
        name="Convenio"
        type="select"
        id="Convenio"
        promptText={"Convenio"}
        bsSize={size || "sm"}
        emptyLabel={"Sin coincidencias"}
        disabled={disabled}
        options={
          agreements
            ? agreements.map(agreement => ({
                id: `${agreement.convenioId}-${agreement.empresaId}`,
                label: agreement.empresa
              }))
            : []
        }
        onChange={sel => (sel.length > 0 ? onChange(sel[0]["id"]) : null)}
        selected={
          value
            ? agreements
                .filter(agreement => agreement.empresaId == value)
                .map(agreement => ({
                  id: `${agreement.convenioId}-${agreement.empresaId}`,
                  label: agreement.empresa
                }))
            : null
        }
      />
      <a
        id={"aggrementSelectorClearButton"}
        style={{
          cursor: "pointer",
          float: "right",
          position: "relative",
          marginTop: "-23px",
          marginRight: "10px"
        }}
        onClick={e => {
          e.preventDefault();
          ref.current.clear();
          onChange(null);
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </a>
    </div>
  );
};
