import { Club } from "../../../types-business/Clubs";
import {
  Demand,
  ESTATUS_ATENCION,
  TipoPeticion,
  OrigenPeticion,
  DemandEvidence
} from "../demands/types/Demand";
import {
  faMeh,
  faDizzy,
  faSmile,
  faSadTear
} from "@fortawesome/free-solid-svg-icons";

export enum ESTATUS_APERTURA {
  ABIERTA = "ABIERTA",
  CERRADA = "CERRADA"
}

export enum ESTATUS_ENCUESTA {
  ENCUESTA_NO_ENVIADA = "ENCUESTA_NO_ENVIADA",
  ENCUESTA_ENVIADA = "ENCUESTA_ENVIADA",
  CONTESTO_CONTACTADO = "CONTESTO_CONTACTADO",
  CONTESTO_NO_CONTACTADO = "CONTESTO_NO_CONTACTADO",
  ENCUESTA_NO_CONTESTADA = "ENCUESTA_NO_CONTESTADA"
}

export const opcionesEstatus = [
  {
    value: null,
    label: "TODOS"
  },
  {
    value: ESTATUS_ATENCION.CERRADO_EXITOSAMENTE,
    label: ESTATUS_ATENCION.CERRADO_EXITOSAMENTE
  },
  {
    value: ESTATUS_ATENCION.CERRADO_VENCIDO,
    label: ESTATUS_ATENCION.CERRADO_VENCIDO
  },
  {
    value: ESTATUS_ATENCION.VENCIDO,
    label: ESTATUS_ATENCION.VENCIDO
  },
  {
    value: ESTATUS_ATENCION.VIGENTE,
    label: ESTATUS_ATENCION.VIGENTE
  }
];

export const opcionesEstatusApertura = [
  {
    value: null,
    label: "TODOS"
  },
  {
    value: "ABIERTA",
    label: "ABIERTA"
  },
  {
    value: "CERRADA",
    label: "CERRADA"
  }
];

export interface Props {
  clubs: Club[];
  demands: Demand[];
  classificationByLevelOne: Node[];
  classificationByLevelTwo: Node[];
  classificationByLevelThree: Node[];
  classificationByLevelFour: Node[];
  loadingDemands: boolean;
  history: any;
  location: any;
  users: {
    userId: number;
    name: string;
    firstSurname: string;
  }[];
  loadingUsers: boolean;
  getClassificationByLevel: (level: number) => any;
  getDemands: (demand: object) => any;
  getDemandsFile: () => any;
  saveObservation: (id: number, observacion: object) => any;
  saveDetails: (
    id: string,
    details: { responsableId: number; fechaCompromiso: string }
  ) => any;
}

export interface State {
  query: {
    nivelClasificacionId: number;
    clubResponsable: object;
    criticidad: string;
    estatus: ESTATUS_APERTURA;
    clubEmisor: object;
    clubBase: object;
    folio: string;
    membresiaPublicoId: string;
    desde: object;
    hasta: object;
    tipo: TipoPeticion;
    origen: OrigenPeticion;
  };
  modalOpenComment: boolean;
  modalOpenDate: boolean;
  modalId: number;
  modalComentarios: object[];
  modalComentariosEstatus: string;

  modalOpenEvidences: boolean;
  modalDemandEvidences: DemandEvidence[];
  modalDemandId: number;

  page: number;
  exporting: boolean;
}

export const defaultColumn = {
  className: "m-0 text-center",
  minWidth: 80,
  style: { whiteSpace: "pre-line", overflowWrap: "break-word" }
};

export const getColorEstatusRespuesta = (value: ESTATUS_ATENCION) => {
  let color = "white";

  switch (value) {
    case ESTATUS_ATENCION.CERRADO_EXITOSAMENTE:
      color = "green";
      break;
    case ESTATUS_ATENCION.CERRADO_VENCIDO:
      color = "yellow";
      break;
    case ESTATUS_ATENCION.VIGENTE:
      color = "blue";
      break;
    case ESTATUS_ATENCION.VENCIDO:
      color = "red";
      break;
    case ESTATUS_ATENCION.SIN_CLASIFICAR:
      color = "gray";
      break;
    default:
      break;
  }

  return color;
};

export const getIconEstatusRespuesta = (value: ESTATUS_ENCUESTA) => {
  let icon = null;

  switch (value) {
    case ESTATUS_ENCUESTA.CONTESTO_CONTACTADO:
      icon = faSmile;
      break;
    case ESTATUS_ENCUESTA.CONTESTO_NO_CONTACTADO:
      icon = faSadTear;
      break;
    case ESTATUS_ENCUESTA.ENCUESTA_ENVIADA:
      icon = faMeh;
      break;
    case ESTATUS_ENCUESTA.ENCUESTA_NO_CONTESTADA:
      icon = faDizzy;
      break;
    default:
      break;
  }

  return icon;
};

export const getFaceColorEstatusRespuesta = (value: ESTATUS_ENCUESTA) => {
  let clsname = "";

  switch (value) {
    case ESTATUS_ENCUESTA.CONTESTO_CONTACTADO:
      clsname = "s.faceGreen";
      break;
    case ESTATUS_ENCUESTA.CONTESTO_NO_CONTACTADO:
      clsname = "s.faceRed";
      break;
    case ESTATUS_ENCUESTA.ENCUESTA_ENVIADA:
      clsname = "s.faceYellow";
      break;
    case ESTATUS_ENCUESTA.ENCUESTA_NO_CONTESTADA:
      clsname = "s.faceOrange";
      break;
    default:
      break;
  }

  return clsname;
};
