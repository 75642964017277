import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../../components/layout";
import ProductsWithComissionConfigToAuth from "../../../components/tyc/productsWithComissionConfigToAuth";
import * as productsActions from "../../../actions/products";
import IAction from "../../../../types/IAction";

interface Props {
  getProductsWithComissionsConfigToAuthorizeByBehaviorType: (
    behaviorType: string
  ) => IAction;
  clearProductsWithComissionsConfigToAuthorizeByBehaviorType: () => IAction;
  productsList: any[];
  searching: boolean;
}

interface State {}

class ProductsWithComissionConfigToAuthPage extends React.Component<
  Props,
  State
> {
  state = {};

  componentDidMount() {
    this.props.getProductsWithComissionsConfigToAuthorizeByBehaviorType(null);
  }

  render() {
    return (
      <main>
        <Helmet title="TYC" />
        <Layout>
          <ProductsWithComissionConfigToAuth
            getProductsWithComissionsConfigToAuthorizeByBehaviorType={
              this.props
                .getProductsWithComissionsConfigToAuthorizeByBehaviorType
            }
            clearProductsWithComissionsConfigToAuthorizeByBehaviorType={
              this.props
                .clearProductsWithComissionsConfigToAuthorizeByBehaviorType
            }
            productsList={this.props.productsList}
            searching={this.props.searching}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getProductsWithComissionsConfigToAuthorizeByBehaviorType: behaviorType => {
    dispatch(
      productsActions.getProductsWithComissionsConfigToAuthorizeByBehaviorType(
        behaviorType
      )
    );
  },
  clearProductsWithComissionsConfigToAuthorizeByBehaviorType: () => {
    dispatch(
      productsActions.clearProductsWithComissionsConfigToAuthorizeByBehaviorType()
    );
  }
});

const mapStateToProps = state => {
  const { products } = state;
  return {
    productsList: products.listProductsWithComissionConfigToAuth,
    searching: products.searchingProductsWithComissionConfigToAuth
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsWithComissionConfigToAuthPage);
