import React from "react";
import { Button, Col, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPencilAlt } from "@fortawesome/free-solid-svg-icons";

export default function columnsFactory(
  addConvenio: () => void,
  editConvenio: (item) => void,
  actionHeaderIcon: string
) {
  return [
    {
      Header: "Id",
      id: 0,
      accessor: "id"
    },
    {
      Header: "Nombre",
      id: 1,
      accessor: "nombre"
    },
    {
      Header: "Descripcion",
      id: 2,
      accessor: "descripcion"
    },
    {
      Header: "",
      id: 3,
      accessor: row => {
        if (row.hasError) {
          return (
            <>
              <div
                id={`error-c-${row.id}`}
                style={{ color: "#700", textDecoration: "underline" }}
              >
                ERRÓNEO
              </div>
              <UncontrolledTooltip
                placement="bottom"
                target={`error-c-${row.id}`}
              >
                {row.error}
              </UncontrolledTooltip>
            </>
          );
        } else {
          return row.estatus;
        }
      }
    },
    {
      Header: "No. de empresas",
      id: 4,
      accessor: "empresas"
    },
    {
      Header: () => (
        <div className="w-100 text-center">
          <Button
            color="link"
            onClick={() => addConvenio()}
            className={actionHeaderIcon}
          >
            <FontAwesomeIcon
              style={{ fontSize: "15px !important" }}
              icon={faPlus}
            />
          </Button>
        </div>
      ),
      width: 48,
      id: "buttons",
      accessor: item => (
        <Col key={`editAction${item.userId}`} xs={2}>
          <a id={`editAction${item.userId}`} onClick={() => editConvenio(item)}>
            <FontAwesomeIcon
              style={{ fontSize: "15px !important", cursor: "pointer" }}
              icon={faPencilAlt}
            />
          </a>
        </Col>
      )
    }
  ];
}
