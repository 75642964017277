import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import ModalFrame from "../../../toolkit/modalFrame";
import s from "./styles.scss";
import { Button } from "reactstrap";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  history: any;
  client: any;
  cancelChanges: () => any;
  confirmChanges: () => any;
}

class ConfirmBenefitsModal extends React.Component<Props> {
  state = {};

  render() {
    return (
      <ModalFrame
        title="Confirmación de cambios"
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        size="md"
      >
        <div className="container-fluid">
          <p>¿Desea cambiar de membresía sin guardar los cambios?</p>
          <div className="row">
            <div className="col">
              <Button
                className={`${s.buttonMarginTop} ${s.primaryButton}`}
                onClick={this.props.cancelChanges}
                size="lg"
                block
              >
                Cancelar
              </Button>
            </div>
            <div className="col">
              <Button
                className={`${s.buttonMarginTop} ${s.primaryButton}`}
                onClick={this.props.confirmChanges}
                size="lg"
                block
              >
                Confirmar
              </Button>
            </div>
          </div>
        </div>
      </ModalFrame>
    );
  }
}

export default withRouter(withStyles(s)(ConfirmBenefitsModal));
