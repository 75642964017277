import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import {
  AUTHORIZE_DOCUMENT_PENDING,
  AUTHORIZE_DOCUMENT,
  AUTHORIZE_DOCUMENT_ERROR,
  AUTHORIZE_EXPEDIENT_PENDING,
  AUTHORIZE_EXPEDIENT_ERROR,
  AUTHORIZE_EXPEDIENT,
  CHANGE_MEMBERSHIP_PENDING,
  CHANGE_MEMBERSHIP_FULFILLED,
  CHANGE_MEMBERSHIP_REJECTED,
  CREATE_PARTNER,
  CREATE_MESSAGE,
  UPDATE_MESSAGE,
  CREATE_PARTNER_PENDING,
  DELETE_PARTNER_SUCCESSFUL,
  DELETE_PARTNER_PENDING,
  DOCUMENT_LOAD_ERROR,
  DOCUMENT_SIGN_ERROR,
  DOCUMENT_SIGN_SUCESS,
  ERROR_GET_PARTNER_BANK_CARDS,
  ERROR_DELETE_PARTNER,
  ERROR_GET_PARTNER,
  errorNotification,
  infoNotification,
  PARTNER_UPDATE_PHOTO_PENDING,
  PARTNER_UPDATE_PHOTO_SUCCESS,
  PARTNER_UPDATE_PHOTO_ERROR,
  PARTNER_UPDATE_PAW_PRINT_PENDING,
  PARTNER_UPDATE_PAW_PRINT_SUCCESS,
  PARTNER_UPDATE_PAW_PRINT_ERROR,
  PARTNER_ACCESS_REGISTER_PENDING,
  PARTNER_ACCESS_REGISTER_SUCCESS,
  PARTNER_ACCESS_REGISTER_ERROR,
  PARTNER_SAVE_BANK_CARD_PENDING,
  PARTNER_SAVE_BANK_CARD_ERROR,
  PARTNER_SAVE_BANK_CARD,
  RECREATE_FILE,
  RECREATE_FILE_ERROR,
  RECREATE_FILE_PENDING,
  SEND_EMAIL_FILE,
  SEND_EMAIL_FILE_ERROR,
  SEND_EMAIL_FILE_MISSING,
  SEND_EMAIL_FILE_PENDING,
  SEND_EMAIL_MOVEMENTS_PENDING,
  SEND_EMAIL_MOVEMENTS_ERROR,
  SEND_EMAIL_MOVEMENTS,
  SEND_SCANNED_FILE,
  SEND_SCANNED_FILE_ERROR,
  SEND_SCANNED_FILE_PENDING,
  GET_MOVEMENTS_CLUB_MEMBERSHIP_REJECTED,
  SWITCH_MEMBERSHIP_PENDING,
  SWITCH_MEMBERSHIP_FULFILLED,
  SWITCH_MEMBERSHIP_REJECTED,
  ERROR_MESSAGES_LIST,
  ERROR_ADD_MESSAGE,
  UPDATE_CONVENIO_SUCCESS,
  successNotification,
  ERROR
} from "../../utils/notifications";
import { getErrorMessagePartnerCreation } from "../../utils/helpers";
import { readMessageBack } from "../../utils/readMessageBack";
interface State {
  partner: object;
  partnerCreated: boolean;
  partnerLinking: boolean;
  partnerLinkError: any;
  addMessageError: any;
  messageCreated: boolean;
  partnerTypes: object[];
  idDocument: object;
  partnerDocuments: object[];
  partnersMembership: object[];
  loadingPartnersMembership: boolean;
  bankCards: object[];
  bankCardsLoading: boolean;
  reloadBankCards: boolean;

  // Expedientes de documentos
  documentsExpedients: object[];
  loadingExpedients: boolean;

  // Paginación de expedientes
  loadingExpedientsPagination: boolean;
  documentsExpedientsPagination: {
    limit: number;
    count: number;
    pages: number;
  };

  fileScanned: boolean;
  showDeletePartner: boolean;
  selectedPartner: object;
  idMembresiaSocio: string;
  showChangeMembership: boolean;
  paymentMovementsByMembership: object[];
  messagesByMembresia: object[];
  loadingMessagesByMembresia: boolean;
  successfullSwitchMembership: object[];
  successfullReactivateMembership: object[];
  loadingIngressByPartnerReport: boolean;
  ingressByPartnerReport: boolean;

  // Envío de facturas asociadas a movimientos
  sendingMovementsMail: boolean;

  // Reporte de medios de pago
  loadingMediosPagoEliminados: boolean;
  downloadingMediosPagoEliminados: boolean;
  mediosPagoEliminados: any[];
}
const initialState: State = {
  partner: null,
  partnerTypes: null,
  partnerCreated: false,
  partnerLinking: false,
  partnerLinkError: null,
  addMessageError: null,
  messageCreated: false,
  idDocument: null,
  partnerDocuments: null,
  partnersMembership: null,
  bankCards: null,
  bankCardsLoading: false,
  reloadBankCards: false,
  documentsExpedients: null,
  loadingExpedients: false,
  loadingExpedientsPagination: false,
  documentsExpedientsPagination: {
    limit: 20,
    count: 0,
    pages: 1
  },
  fileScanned: false,
  showDeletePartner: false,
  selectedPartner: null,
  idMembresiaSocio: "",
  showChangeMembership: false,
  paymentMovementsByMembership: [],
  messagesByMembresia: null,
  loadingMessagesByMembresia: false,
  successfullSwitchMembership: null,
  loadingPartnersMembership: false,
  successfullReactivateMembership: null,
  loadingIngressByPartnerReport: false,
  ingressByPartnerReport: null,
  sendingMovementsMail: false,
  loadingMediosPagoEliminados: false,
  downloadingMediosPagoEliminados: false,
  mediosPagoEliminados: null
};

export default function partnerReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.AUTHORIZE_EXPEDIENT}_PENDING`: {
      infoNotification(AUTHORIZE_EXPEDIENT_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.AUTHORIZE_EXPEDIENT}_REJECTED`: {
      errorNotification(readMessageBack(action, AUTHORIZE_EXPEDIENT_ERROR));
      return {
        ...state
      };
    }
    case `${actionTypes.AUTHORIZE_EXPEDIENT}_FULFILLED`: {
      successNotification(AUTHORIZE_EXPEDIENT);
      return {
        ...state
      };
    }
    case `${actionTypes.AUTHORIZE_DOCUMENT}_PENDING`: {
      infoNotification(AUTHORIZE_DOCUMENT_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.AUTHORIZE_DOCUMENT}_REJECTED`: {
      errorNotification(readMessageBack(action, AUTHORIZE_DOCUMENT_ERROR));
      return {
        ...state
      };
    }
    case `${actionTypes.AUTHORIZE_DOCUMENT}_FULFILLED`: {
      successNotification(AUTHORIZE_DOCUMENT);
      return {
        ...state
      };
    }
    case `${actionTypes.LINK_PARTNER}_PENDING`: {
      infoNotification(CREATE_PARTNER_PENDING);
      return {
        ...state,
        partnerLinkError: null,
        partnerCreated: false,
        partnerLinking: true
      };
    }
    case `${actionTypes.LINK_PARTNER}_REJECTED`: {
      const errores = getErrorMessagePartnerCreation(action.payload);
      return {
        ...state,
        partnerCreated: false,
        partnerLinkError: errores,
        partnerLinking: false
      };
    }
    case `${actionTypes.LINK_PARTNER}_FULFILLED`: {
      successNotification(CREATE_PARTNER);
      return {
        ...state,
        partnerLinkError: null,
        partnerCreated: true,
        partnerLinking: false
      };
    }
    case `${actionTypes.SEND_EMAIL_MOVEMENTS}_PENDING`: {
      infoNotification(SEND_EMAIL_MOVEMENTS_PENDING);
      return {
        ...state,
        sendingMovementsMail: true
      };
    }
    case `${actionTypes.SEND_EMAIL_MOVEMENTS}_REJECTED`: {
      errorNotification(readMessageBack(action, SEND_EMAIL_MOVEMENTS_ERROR));
      return {
        ...state,
        sendingMovementsMail: false
      };
    }
    case `${actionTypes.SEND_EMAIL_MOVEMENTS}_FULFILLED`: {
      successNotification(SEND_EMAIL_MOVEMENTS);
      return {
        ...state,
        sendingMovementsMail: false
      };
    }
    case `${actionTypes.SEND_EMAIL_FILE}_PENDING`: {
      infoNotification(SEND_EMAIL_FILE_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.SEND_EMAIL_FILE}_REJECTED`: {
      const { status } = action.payload.response;
      if (status === 404) {
        errorNotification(readMessageBack(action, SEND_EMAIL_FILE_MISSING));
      } else {
        errorNotification(readMessageBack(action, SEND_EMAIL_FILE_ERROR));
      }
      return {
        ...state
      };
    }
    case `${actionTypes.SEND_EMAIL_FILE}_FULFILLED`: {
      successNotification(SEND_EMAIL_FILE);
      return {
        ...state
      };
    }
    case `${actionTypes.SEND_SCANNED_FILE}_PENDING`: {
      infoNotification(SEND_SCANNED_FILE_PENDING);
      return {
        ...state,
        fileScanned: false
      };
    }
    case `${actionTypes.SEND_SCANNED_FILE}_REJECTED`: {
      errorNotification(readMessageBack(action, SEND_SCANNED_FILE_ERROR));
      return {
        ...state,
        fileScanned: false
      };
    }
    case `${actionTypes.SEND_SCANNED_FILE}_FULFILLED`: {
      successNotification(SEND_SCANNED_FILE);
      return {
        ...state,
        fileScanned: true
      };
    }
    case `${actionTypes.RECREATE_FILE}_PENDING`: {
      infoNotification(RECREATE_FILE_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.RECREATE_FILE}_REJECTED`: {
      errorNotification(readMessageBack(action, RECREATE_FILE_ERROR));
      return {
        ...state
      };
    }
    case `${actionTypes.RECREATE_FILE}_FULFILLED`: {
      successNotification(RECREATE_FILE);
      return {
        ...state
      };
    }
    case `${actionTypes.CREATE_PARTNER}_PENDING`: {
      infoNotification(CREATE_PARTNER_PENDING);
      return {
        ...state,
        partnerCreated: false,
        partnerLinking: true
      };
    }
    case `${actionTypes.CREATE_PARTNER}_REJECTED`: {
      const errores = getErrorMessagePartnerCreation(action.payload);
      return {
        ...state,
        partnerCreated: false,
        partnerLinkError: errores,
        partnerLinking: false
      };
    }
    case `${actionTypes.CREATE_PARTNER}_FULFILLED`: {
      successNotification(CREATE_PARTNER);
      return {
        ...state,
        partnerCreated: true,
        partnerLinking: false
      };
    }
    case `${actionTypes.LINK_PARTNER_TOGGLE}`: {
      return {
        ...state,
        partnerLinkError: null
      };
    }
    case `${actionTypes.GET_PARTNER}_PENDING`: {
      return {
        ...state,
        partner: null,
        partnersMembership: null
      };
    }
    case `${actionTypes.GET_PARTNER}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR_GET_PARTNER));
      return {
        ...state,
        partner: null
      };
    }
    case `${actionTypes.GET_PARTNER}_FULFILLED`: {
      return {
        ...state,
        partner: action.payload.data
      };
    }
    case `${actionTypes.GET_PARTNER_TYPES}_PENDING`: {
      return {
        ...state,
        partnerTypes: null
      };
    }
    case `${actionTypes.GET_PARTNER_TYPES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        partnerTypes: null
      };
    }
    case `${actionTypes.GET_PARTNER_TYPES}_FULFILLED`: {
      return {
        ...state,
        partnerTypes: action.payload.data
      };
    }

    // Consulta de expedientes de documentos
    case `${actionTypes.GET_DOCUMENTS_EXPEDIENTS}_PENDING`: {
      return {
        ...state,
        loadingExpedients: true
      };
    }
    case `${actionTypes.GET_DOCUMENTS_EXPEDIENTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        loadingExpedients: false
      };
    }
    case `${actionTypes.GET_DOCUMENTS_EXPEDIENTS}_FULFILLED`: {
      return {
        ...state,
        documentsExpedients: action.payload.data,
        loadingExpedients: false
      };
    }

    // Paginación de expedientes de documentos
    case `${actionTypes.GET_DOCUMENTS_EXPEDIENTS_PAGINATION}_PENDING`: {
      return {
        ...state,
        loadingExpedientsPagination: true
      };
    }
    case `${actionTypes.GET_DOCUMENTS_EXPEDIENTS_PAGINATION}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        loadingExpedientsPagination: false,
        documentsExpedientsPagination: {
          limit: 20,
          count: 0,
          pages: 1
        }
      };
    }
    case `${actionTypes.GET_DOCUMENTS_EXPEDIENTS_PAGINATION}_FULFILLED`: {
      return {
        ...state,
        loadingExpedientsPagination: false,
        documentsExpedientsPagination: action.payload.data
      };
    }

    case `${actionTypes.GET_DOCUMENT}_PENDING`: {
      return {
        ...state,
        idDocument: null
      };
    }
    case `${actionTypes.GET_DOCUMENT}_REJECTED`: {
      errorNotification(readMessageBack(action, DOCUMENT_LOAD_ERROR));
      return {
        ...state,
        idDocument: null
      };
    }
    case `${actionTypes.GET_DOCUMENT}_FULFILLED`: {
      return {
        ...state,
        idDocument: action.payload.data.idDocumento
      };
    }
    case `${actionTypes.PRINT_DOCUMENT}_PENDING`: {
      return {
        ...state,
        idDocument: null
      };
    }
    case `${actionTypes.PRINT_DOCUMENT}_REJECTED`: {
      errorNotification(readMessageBack(action, DOCUMENT_LOAD_ERROR));
      return {
        ...state,
        idDocument: null
      };
    }
    case `${actionTypes.PRINT_DOCUMENT}_FULFILLED`: {
      return {
        ...state,
        idDocument: action.payload.data.idDocumento
      };
    }
    case `${actionTypes.GET_DOCUMENT_TO_SIGN}_PENDING`: {
      return {
        ...state,
        idDocument: null
      };
    }
    case `${actionTypes.GET_DOCUMENT_TO_SIGN}_REJECTED`: {
      errorNotification(readMessageBack(action, DOCUMENT_LOAD_ERROR));
      return {
        ...state,
        idDocument: null
      };
    }
    case `${actionTypes.GET_DOCUMENT_TO_SIGN}_FULFILLED`: {
      return {
        ...state,
        idDocument: null
      };
    }
    case `${actionTypes.SIGN_DOCUMENT}_PENDING`: {
      return {
        ...state,
        idDocument: null
      };
    }
    case `${actionTypes.SIGN_DOCUMENT}_REJECTED`: {
      errorNotification(readMessageBack(action, DOCUMENT_SIGN_ERROR));
      return {
        ...state,
        idDocument: null
      };
    }
    case `${actionTypes.SIGN_DOCUMENT}_FULFILLED`: {
      successNotification(DOCUMENT_SIGN_SUCESS);
      return {
        ...state,
        idDocument: null
      };
    }
    case `${actionTypes.CLEAN_DOCUMENT}`: {
      return {
        ...state,
        idDocument: null
      };
    }
    case `${actionTypes.GET_PARTNERS_MEMBERSHIP}_PENDING`: {
      return {
        ...state,
        partnersMembership: null,
        loadingPartnersMembership: true
      };
    }
    case `${actionTypes.GET_PARTNERS_MEMBERSHIP}_FULFILLED`: {
      return {
        ...state,
        partnersMembership: action.payload.data,
        loadingPartnersMembership: false
      };
    }
    case `${actionTypes.GET_PARTNERS_MEMBERSHIP}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        partnersMembership: null,
        loadingPartnersMembership: false
      };
    }
    case `${actionTypes.GET_PARTNER_DOCUMENTS}_PENDING`: {
      return {
        ...state,
        partnerDocuments: null
      };
    }
    case `${actionTypes.GET_PARTNER_DOCUMENTS}_FULFILLED`: {
      return {
        ...state,
        partnerDocuments: action.payload.data
      };
    }
    case `${actionTypes.GET_PARTNER_DOCUMENTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        partnerDocuments: null
      };
    }
    case `${actionTypes.PARTNER_UPDATE_PHOTO}_PENDING`: {
      infoNotification(PARTNER_UPDATE_PHOTO_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.PARTNER_UPDATE_PHOTO}_REJECTED`: {
      errorNotification(readMessageBack(action, PARTNER_UPDATE_PHOTO_ERROR));
      return {
        ...state
      };
    }
    case `${actionTypes.PARTNER_UPDATE_PHOTO}_FULFILLED`: {
      successNotification(PARTNER_UPDATE_PHOTO_SUCCESS);
      return {
        ...state,
        partnersMembership: action.payload.data
      };
    }
    case `${actionTypes.PARTNER_UPDATE_PAW_PRINT}_PENDING`: {
      infoNotification(PARTNER_UPDATE_PAW_PRINT_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.PARTNER_UPDATE_PAW_PRINT}_REJECTED`: {
      errorNotification(
        readMessageBack(action, PARTNER_UPDATE_PAW_PRINT_ERROR)
      );
      return {
        ...state
      };
    }
    case `${actionTypes.PARTNER_UPDATE_PAW_PRINT}_FULFILLED`: {
      successNotification(PARTNER_UPDATE_PAW_PRINT_SUCCESS);
      return {
        ...state,
        partnersMembership: action.payload.data
      };
    }
    case `${actionTypes.PARTNER_ACCESS_REGISTER}_PENDING`: {
      infoNotification(PARTNER_ACCESS_REGISTER_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.PARTNER_ACCESS_REGISTER}_REJECTED`: {
      errorNotification(readMessageBack(action, PARTNER_ACCESS_REGISTER_ERROR));
      return {
        ...state
      };
    }
    case `${actionTypes.PARTNER_ACCESS_REGISTER}_FULFILLED`: {
      successNotification(PARTNER_ACCESS_REGISTER_SUCCESS);
      return {
        ...state
      };
    }
    case `${actionTypes.GET_PARTNER_BANK_CARDS}_PENDING`: {
      return {
        ...state,
        bankCards: null,
        bankCardsLoading: true,
        reloadBankCards: false
      };
    }
    case `${actionTypes.GET_PARTNER_BANK_CARDS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR_GET_PARTNER_BANK_CARDS));
      return {
        ...state,
        bankCards: null,
        bankCardsLoading: false
      };
    }
    case `${actionTypes.GET_PARTNER_BANK_CARDS}_FULFILLED`: {
      return {
        ...state,
        bankCards: action.payload.data,
        bankCardsLoading: false
      };
    }
    case `${actionTypes.PARTNER_SAVE_BANK_CARD}_PENDING`: {
      infoNotification(PARTNER_SAVE_BANK_CARD_PENDING);
      return {
        ...state,
        reloadBankCards: false
      };
    }
    case `${actionTypes.PARTNER_SAVE_BANK_CARD}_REJECTED`: {
      errorNotification(readMessageBack(action, PARTNER_SAVE_BANK_CARD_ERROR));
      return {
        ...state
      };
    }
    case `${actionTypes.PARTNER_SAVE_BANK_CARD}_FULFILLED`: {
      successNotification(PARTNER_SAVE_BANK_CARD);
      return {
        ...state,
        reloadBankCards: true
      };
    }
    case actionTypes.DELETE_PARTNER_MODAL: {
      return {
        ...state,
        showDeletePartner: !state.showDeletePartner,
        selectedPartner: action.payload
          ? {
              name: action.payload.nombrePersona,
              id: action.payload.idPersona
            }
          : null,
        idMembresiaSocio: action.payload.idMembresiaSocio
      };
    }
    case `${actionTypes.DELETE_PARTNER}_FULFILLED`: {
      successNotification(DELETE_PARTNER_SUCCESSFUL);
      const newPartners = state.partnersMembership.filter(
        e => e.idPersona !== state.selectedPartner.id
      );
      return {
        ...state,
        partnersMembership: newPartners,
        showDeletePartner: false
      };
    }
    case `${actionTypes.DELETE_PARTNER}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR_DELETE_PARTNER));
      return {
        ...state
      };
    }
    case `${actionTypes.DELETE_PARTNER}_PENDING`: {
      infoNotification(DELETE_PARTNER_PENDING);
      return {
        ...state
      };
    }
    case actionTypes.CHANGE_MEMBERSHIP_MODAL: {
      return {
        ...state,
        showChangeMembership: !state.showChangeMembership
      };
    }
    case `${actionTypes.CHANGE_MEMBERSHIP}_PENDING`: {
      infoNotification(CHANGE_MEMBERSHIP_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.CHANGE_MEMBERSHIP}_FULFILLED`: {
      successNotification(CHANGE_MEMBERSHIP_FULFILLED);
      return {
        ...state
      };
    }
    case `${actionTypes.CHANGE_MEMBERSHIP}_REJECTED`: {
      errorNotification(readMessageBack(action, CHANGE_MEMBERSHIP_REJECTED));
      return {
        ...state
      };
    }
    case actionTypes.CHANGE_MEMBERSHIP_MODAL: {
      return {
        ...state,
        showChangeMembership: !state.showChangeMembership
      };
    }
    case `${actionTypes.CHANGE_MEMBERSHIP}_PENDING`: {
      infoNotification(CHANGE_MEMBERSHIP_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.CHANGE_MEMBERSHIP}_FULFILLED`: {
      successNotification(CHANGE_MEMBERSHIP_FULFILLED);
      return {
        ...state
      };
    }
    case `${actionTypes.CHANGE_MEMBERSHIP}_REJECTED`: {
      errorNotification(readMessageBack(action, CHANGE_MEMBERSHIP_REJECTED));
      return {
        ...state
      };
    }
    case `${actionTypes.GET_MOVEMENTS_CLUB_MEMBERSHIP}_PENDING`: {
      return {
        ...state,
        paymentMovementsByMembership: null
      };
    }
    case `${actionTypes.GET_MOVEMENTS_CLUB_MEMBERSHIP}_FULFILLED`: {
      return {
        ...state,
        paymentMovementsByMembership: action.payload.data
      };
    }
    case `${actionTypes.GET_MOVEMENTS_CLUB_MEMBERSHIP}_REJECTED`: {
      errorNotification(
        readMessageBack(action, GET_MOVEMENTS_CLUB_MEMBERSHIP_REJECTED)
      );
      return {
        ...state,
        paymentMovementsByMembership: null
      };
    }
    case `${actionTypes.REACTIVATE_MEMBERSHIP}_PENDING`: {
      infoNotification(SWITCH_MEMBERSHIP_PENDING);
      return {
        ...state,
        successfullReactivateMembership: null
      };
    }
    case `${actionTypes.REACTIVATE_MEMBERSHIP}_FULFILLED`: {
      successNotification(SWITCH_MEMBERSHIP_FULFILLED);
      return {
        ...state,
        successfullReactivateMembership: action.payload.data
      };
    }
    case `${actionTypes.REACTIVATE_MEMBERSHIP}_REJECTED`: {
      errorNotification(readMessageBack(action, SWITCH_MEMBERSHIP_REJECTED));
      return {
        ...state,
        successfullReactivateMembership: null
      };
    }
    case `${actionTypes.SWITCH_MEMBERSHIP}_PENDING`: {
      infoNotification(SWITCH_MEMBERSHIP_PENDING);
      return {
        ...state,
        successfullSwitchMembership: null
      };
    }
    case `${actionTypes.SWITCH_MEMBERSHIP}_FULFILLED`: {
      successNotification(SWITCH_MEMBERSHIP_FULFILLED);
      return {
        ...state,
        successfullSwitchMembership: action.payload.data
      };
    }
    case `${actionTypes.SWITCH_MEMBERSHIP}_REJECTED`: {
      errorNotification(readMessageBack(action, SWITCH_MEMBERSHIP_REJECTED));
    }
    case `${actionTypes.GET_MESSAGES_BY_MEMBRESIA}_PENDING`: {
      return {
        ...state,
        messagesByMembresia: null,
        loadingMessagesByMembresia: true
      };
    }

    case `${actionTypes.GET_MESSAGES_BY_MEMBRESIA}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR_MESSAGES_LIST));
      return {
        ...state,
        messagesByMembresia: null,
        loadingMessagesByMembresia: false
      };
    }

    case `${actionTypes.GET_MESSAGES_BY_MEMBRESIA}_FULFILLED`: {
      return {
        ...state,
        messagesByMembresia: action.payload.data,
        loadingMessagesByMembresia: false
      };
    }

    case `${actionTypes.ADD_MESSAGE}_PENDING`: {
      infoNotification(CREATE_PARTNER_PENDING);
      return {
        ...state,
        addMessageError: null,
        messageCreated: false
      };
    }

    case `${actionTypes.ADD_MESSAGE}_FULFILLED`: {
      successNotification(CREATE_MESSAGE);
      return {
        ...state,
        addMessageError: null,
        messageCreated: true
      };
    }

    case `${actionTypes.ADD_MESSAGE}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR_ADD_MESSAGE));
      return {
        ...state,
        messageCreated: false,
        addMessageError: getErrorMessagePartnerCreation(action.payload)
      };
    }

    case `${actionTypes.UPDATE_MESSAGE}_FULFILLED`: {
      successNotification(CREATE_MESSAGE);
      return {
        ...state,
        addMessageError: null,
        messageCreated: true
      };
    }

    case `${actionTypes.UPDATE_MESSAGE}_PENDING`: {
      infoNotification(CREATE_PARTNER_PENDING);
      return {
        ...state,
        addMessageError: null,
        messageCreated: false
      };
    }

    case `${actionTypes.UPDATE_MESSAGE}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR_ADD_MESSAGE));
      return {
        ...state,
        messageCreated: false,
        addMessageError: getErrorMessagePartnerCreation(action.payload)
      };
    }

    case `${actionTypes.UPDATE_TITULAR}_FULFILLED`: {
      successNotification(UPDATE_MESSAGE);
      return {
        ...state,
        addMessageError: null,
        messageCreated: true
      };
    }

    case `${actionTypes.UPDATE_TITULAR}_PENDING`: {
      infoNotification(CREATE_PARTNER_PENDING);
      return {
        ...state,
        addMessageError: null,
        messageCreated: false
      };
    }

    case `${actionTypes.UPDATE_TITULAR}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR_ADD_MESSAGE));
      return {
        ...state,
        messageCreated: false,
        addMessageError: getErrorMessagePartnerCreation(action.payload)
      };
    }

    case `${actionTypes.UPDATE_CONVENIO}_FULFILLED`: {
      successNotification(UPDATE_CONVENIO_SUCCESS);
      return {
        ...state,
        addMessageError: null,
        messageCreated: true
      };
    }

    case `${actionTypes.UPDATE_CONVENIO}_PENDING`: {
      infoNotification(CREATE_PARTNER_PENDING);
      return {
        ...state,
        addMessageError: null,
        messageCreated: false
      };
    }

    case `${actionTypes.UPDATE_CONVENIO}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR_ADD_MESSAGE));
      return {
        ...state,
        messageCreated: false,
        addMessageError: getErrorMessagePartnerCreation(action.payload)
      };
    }

    case `${actionTypes.GET_INGRESS_BY_PARTNER_REPORT}_PENDING`: {
      return {
        ...state,
        loadingIngressByPartnerReport: true,
        ingressByPartnerReport: null
      };
    }
    case `${actionTypes.GET_INGRESS_BY_PARTNER_REPORT}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        loadingIngressByPartnerReport: false,
        ingressByPartnerReport: null
      };
    }
    case `${actionTypes.GET_INGRESS_BY_PARTNER_REPORT}_FULFILLED`: {
      return {
        ...state,
        loadingIngressByPartnerReport: false,
        ingressByPartnerReport: action.payload.data
      };
    }
    case `${actionTypes.CLEAR_INGRESS_BY_PARTNER_REPORT}`: {
      return {
        ...state,
        loadingIngressByPartnerReport: false,
        ingressByPartnerReport: null
      };
    }

    // Medios de pago eliminados
    case `${actionTypes.GET_MEDIOS_PAGO_ELIMINADOS}_PENDING`: {
      return {
        ...state,
        loadingMediosPagoEliminados: true,
        mediosPagoEliminados: null
      };
    }
    case `${actionTypes.GET_MEDIOS_PAGO_ELIMINADOS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        loadingMediosPagoEliminados: false,
        mediosPagoEliminados: null
      };
    }
    case `${actionTypes.GET_MEDIOS_PAGO_ELIMINADOS}_FULFILLED`: {
      return {
        ...state,
        loadingMediosPagoEliminados: false,
        mediosPagoEliminados: action.payload.data || []
      };
    }

    // Medios de pago eliminados (reporte)
    case `${actionTypes.GET_MEDIOS_PAGO_ELIMINADOS_REPORTE}_PENDING`: {
      return {
        ...state,
        downloadingMediosPagoEliminados: true
      };
    }
    case `${actionTypes.GET_MEDIOS_PAGO_ELIMINADOS_REPORTE}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        downloadingMediosPagoEliminados: false
      };
    }
    case `${actionTypes.GET_MEDIOS_PAGO_ELIMINADOS_REPORTE}_FULFILLED`: {
      return {
        ...state,
        downloadingMediosPagoEliminados: false
      };
    }

    default: {
      return state;
    }
  }
}

export { initialState };
