import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Home from "../../components/home";
import Layout from "../../components/layout";
import { UserContext, WHO_AM_I_STATE } from "../../../types-business/Profile";
import * as sessionActions from "../../actions/session";

interface Props {
  userContext: UserContext;
  loginSSP: (password: string, userName: string, ssp?: object) => any;
  login: (password: string, userName: string) => any;
  whoami: () => any;
  logoutUser: () => any;
  ssp: Array<object>;
  loginUser: boolean;
  loginUserError: any;
  successfullLogin: boolean;
  iKnowWhoIAm: WHO_AM_I_STATE;
  securitySpacesError: object;
  gettingSecuritySpaces: boolean;
  successfullSecuritySpaces: boolean;
}

class HomePage extends React.Component<Props, void> {
  render() {
    return (
      <div>
        <Helmet title="Home" />
        <Layout>
          <Home {...this.props} />
        </Layout>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  loginSSP: (userName = null, password = null, ssp = null) => {
    dispatch(sessionActions.loginUserSSP(userName, password, ssp));
  },
  login: (userName = null, password = null) => {
    dispatch(sessionActions.loginUser(userName, password));
  },
  whoami: () => {
    dispatch(sessionActions.whoami());
  },
  logoutUser: () => {
    dispatch(sessionActions.logoutUser());
  }
});

const mapStateToProps = state => {
  const { user } = state;

  return {
    loginUser: user.loginUser,
    loginUserError: user.loginUserError,
    userContext: user.userContext,
    ssp: user.userContext ? user.userContext.ssp : null,
    successfullLogin: user.successfullLogin,
    iKnowWhoIAm: user.iKnowWhoIAm,
    securitySpacesError: user.securitySpacesError,
    gettingSecuritySpaces: user.gettingSecuritySpaces,
    successfullSecuritySpaces: user.successfullSecuritySpaces
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage);
