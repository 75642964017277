import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Container, Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import ReactTable from "react-table";
import { LockerSummary } from "../../../types/Lockers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faFileExport } from "@fortawesome/free-solid-svg-icons";

interface Props {
  lockersSummary: LockerSummary[];
  searching: boolean;
  match: any;
  history: any;
  onClickDownloadCsvFile: () => any;
}

interface State {
  selectedClubConcept: string;
}

const getSummaryColumnObject = (
  title: string,
  columnId: number,
  fieldName: string,
  accessor: string
) => {
  return {
    Header: () => (
      <div
        className="font-weight-bold"
        style={{ paddingLeft: "30px", transform: "rotate(90deg)" }}
      >
        {title}
      </div>
    ),
    id: columnId,
    headerStyle: { height: "90px" },
    width: 60,
    accessor,
    Cell: row => {
      const value = (row.original.resumen || {})[`${fieldName}`] || 0;
      if (value > 0) {
        return <p className="text-center font-weight-bold">{value}</p>;
      } else {
        return <p className="text-center">{value}</p>;
      }
    }
  };
};

class LockersSummary extends React.Component<Props, State> {
  state = {
    selectedClubConcept: ""
  };

  getClubConceptsOptions() {
    return [
      { value: "", label: "Todos" },
      { value: "SW", label: "sw" },
      { value: "GYM_PLUS", label: "gym_plus" },
      { value: "LOAD", label: "load" },
      { value: "ZUMA", label: "zuma" },
      { value: "BTB", label: "btb" },
      { value: "GYM", label: "gym" }
    ];
  }

  handleClubConceptChanged = e => {
    const selectedClubConcept = e.target.value;
    this.setState(state => ({
      ...state,
      selectedClubConcept
    }));
  };

  getLockersSummaryFiltered = () => {
    const { selectedClubConcept } = this.state;
    return selectedClubConcept === ""
      ? [...this.props.lockersSummary]
      : [
          ...this.props.lockersSummary.filter(
            x => x.club.concepto === selectedClubConcept
          )
        ];
  };

  render() {
    let columns = [
      {
        Header: () => (
          <p className="font-weight-bold" style={{ marginTop: "38px" }}>
            Club
          </p>
        ),
        id: 0,
        width: 200,
        accessor: "club",
        Cell: row => {
          const clubName = (row.original.club || {}).nombre || "";
          return <p>{clubName}</p>;
        }
      },
      {
        Header: () => (
          <p className="text-center mt-3 font-weight-bold">Caballeros</p>
        ),
        width: 180,
        columns: [
          getSummaryColumnObject("Chico", 1, "hombreChico", "hombreChico"),
          getSummaryColumnObject(
            "Mediano",
            1,
            "hombreMediano",
            "hombreMediano"
          ),
          getSummaryColumnObject("Grande", 1, "hombreGrande", "hombreGrande")
        ]
      },
      {
        Header: () => (
          <p className="text-center mt-3 font-weight-bold">Damas</p>
        ),
        width: 180,
        columns: [
          getSummaryColumnObject("Chico", 1, "mujerChico", "mujerChico"),
          getSummaryColumnObject("Mediano", 1, "mujerMediano", "mujerMediano"),
          getSummaryColumnObject("Grande", 1, "mujerGrande", "mujerGrande")
        ]
      },
      {
        Header: () => (
          <p className="text-center mt-3 font-weight-bold">Niños</p>
        ),
        width: 180,
        columns: [
          getSummaryColumnObject("Chico", 1, "ninoChico", "ninoChico"),
          getSummaryColumnObject("Mediano", 1, "ninoMediano", "ninoMediano"),
          getSummaryColumnObject("Grande", 1, "ninoGrande", "ninoGrande")
        ]
      },
      {
        Header: () => (
          <p className="text-center mt-3 font-weight-bold">Niñas</p>
        ),
        width: 180,
        columns: [
          getSummaryColumnObject("Chico", 1, "ninaChico", "ninaChico"),
          getSummaryColumnObject("Mediano", 1, "ninaMediano", "ninaMediano"),
          getSummaryColumnObject("Grande", 1, "ninaGrande", "ninaGrande")
        ]
      },
      {
        Header: () => (
          <p className="font-weight-bold" style={{ marginTop: "38px" }}>
            Total
          </p>
        ),
        id: -200,
        width: 100,
        accessor: "total",
        Cell: row => {
          const value = row.original.total || 0;
          if (value > 0) {
            return <p className="text-center font-weight-bold">{value}</p>;
          } else {
            return <p className="text-center">{value}</p>;
          }
        }
      },
      {
        Header: () => (
          <p className="font-weight-bold" style={{ marginTop: "38px" }}></p>
        ),
        id: -300,
        width: 50,
        accessor: "config",
        Cell: row => {
          return (
            <Button
              className={`${s.actionHeaderIcon}`}
              onClick={() => {
                this.props.history.push("/lockers/configuracion");
              }}
            >
              <FontAwesomeIcon icon={faCog} />
            </Button>
          );
        }
      }
    ];

    return (
      <Container className="my-3 p-0">
        <Row className="mt-4">
          <Col md={11} xs={9}>
            <BaseInput
              label="Concepto"
              name="clubConcept"
              id="clubConcept"
              type="select"
              placeholder="Club"
              options={this.getClubConceptsOptions()}
              onChange={this.handleClubConceptChanged}
              disabled={this.props.searching}
            />
          </Col>
          <Col md={1} xs={3}>
            <div className="w-100 text-center">
              <Button
                id="btnLockerSummaryExportCsv"
                color="link"
                onClick={this.props.onClickDownloadCsvFile}
                className={`${s.innerButton} ${s.primaryLink} mt-4`}
              >
                <FontAwesomeIcon icon={faFileExport} />
              </Button>
              <UncontrolledTooltip
                placement="top"
                target="btnLockerSummaryExportCsv"
              >
                Exportar
              </UncontrolledTooltip>
            </div>
          </Col>
        </Row>

        <ReactTable
          className="mt-2 -striped -highlight"
          style={{
            height: "430px"
          }}
          data={this.getLockersSummaryFiltered()}
          columns={columns}
          sortable={false}
          resizable={false}
          pageSize={
            !!this.props.lockersSummary.length
              ? this.props.lockersSummary.length
              : 20
          }
          showPagination={false}
          loading={this.props.searching}
        />
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(LockersSummary));
