export default {
  PROSPECTS: 1,
  MEMBERSHIPS: 4,
  MAINTENANCES: 3,
  PRICES: 2,
  MASSIVE_LOAD: 7,
  CLUBS: 5,
  AGREEMENTS: 6,
  PASS: -1
};
