import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Container, Row, Col, Button, Form } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import PagedTable from "../common/PagedTable";
import { LockerDto } from "../../../types/Lockers";
import LockerAssignmentForm from "../lockersAssignmentForm";
import { QueryResponse } from "../../../types/QueryResponse";
import CONSTANTS from "../../../utils/constants";

interface Filters {
  page: number;
  size: number;
}

interface Props {
  clubsCatalog: any;
  selectedClubId: number;
  selectedLockerRoom: string;
  selectedWallId: number;
  lockerNumber: string;
  lockerRoomWallsFiltered: object[];
  searching: boolean;
  wallLockers: QueryResponse<LockerDto>;
  filters: Filters;
  products: object;
  searchingClubWalls: boolean;
  sellers: object[];
  onClubChanged: (newClubId: number) => any;
  onLockerRoomChanged: (newLockerRoom: string) => any;
  onWallChanged: (newWallId: number) => any;
  onLockerNumberChanged: (newLockerNumber: string) => any;
  searchLockers: () => any;
  fetchData: () => void;
  saveLockerAssignment: (
    lockerId: number,
    personId: number,
    productId: number,
    responsibleId: number,
    msi: number,
    codigo: string,
    storage: string | number
  ) => any;
  history: any;
  match: any;
  lockerDocuments: object[];
  fileScanned: boolean;
  userContext: any;
}

interface State {
  wallLockers: QueryResponse<LockerDto>;
  selectedLocker: LockerDto;
  clear: any;
}

const emptySelectedLocker: LockerDto = {
  id: -1,
  size: "",
  posicion: "",
  estatus: "",
  numero: "",
  esAsignable: false,
  selected: false,
  club: "",
  vestidor: "",
  pared: ""
};

const getLockerContextualStatusClass = locker => {
  return !!locker.selected ? "table-success" : "";
};

class LockerAssignment extends React.Component<Props, State> {
  state = {
    wallLockers: { ...this.props.wallLockers },
    selectedLocker: emptySelectedLocker,
    clear: false
  };

  handleClubChanged = e => {
    const clubId = e.target.value;
    this.props.onClubChanged(clubId);
    this.clearFormComponent();
  };

  handleLockerRoomChanged = e => {
    const lockerRoom = e.target.value;
    this.props.onLockerRoomChanged(lockerRoom);
    this.clearFormComponent();
  };

  handleWallChanged = e => {
    const wallId = e.target.value;
    this.props.onWallChanged(wallId);
    this.clearFormComponent();
  };

  handleLockerNumberChanged = e => {
    const lockerNumber = e.target.value;
    this.props.onLockerNumberChanged(lockerNumber);
    this.clearFormComponent();
  };

  handleSearchClicked = e => {
    e.preventDefault();
    if (!!this.props.selectedClubId && this.props.selectedClubId) {
      this.props.searchLockers();
      this.clearFormComponent();
    }
  };

  handleSelectLocker = locker => {
    const { clear } = this.state;
    const tmp = { ...this.state.wallLockers };
    tmp.content.forEach(x => {
      x.selected = x.id === locker.id;
    });
    this.setState(state => ({
      ...state,
      selectedLocker: locker,
      clear: !clear,
      wallLockers: tmp
    }));
  };

  handleAssignLocker = (
    lockerId: number,
    personId: number,
    productId: number,
    responsibleId: number,
    msi: number,
    codigo: string,
    storage: string
  ) => {
    this.props
      .saveLockerAssignment(
        lockerId,
        personId,
        productId,
        responsibleId,
        msi,
        codigo,
        storage
      )
      .then(() => {
        this.props.searchLockers();
        this.clearFormComponent();
      });
  };

  clearFormComponent = () => {
    const { clear } = this.state;
    this.setState(state => ({
      ...state,
      selectedLocker: emptySelectedLocker,
      clear: !clear
    }));
  };

  getOptionsClubes = clubs => {
    return [{ value: "", label: "Seleccionar" }].concat(
      (clubs || [])
        .filter(club => !!club.nombre)
        .map(club => {
          return { label: club.nombre, value: club.clubId };
        })
    );
  };

  getOptionsWalls = walls => {
    return [{ value: -1, label: "Seleccionar" }].concat(
      (walls || [])
        .filter(wall => !!wall.nombre)
        .map(wall => {
          return { label: wall.nombre, value: wall.id };
        })
    );
  };

  getOptionsLockerRooms = () => {
    return [
      { value: "", label: "Seleccionar" },
      { value: "MUJER", label: "Mujeres" },
      { value: "HOMBRE", label: "Hombres" },
      { value: "NINA", label: "Niñas" },
      { value: "NINO", label: "Niños" }
    ];
  };

  componentWillReceiveProps(props) {
    const { wallLockers } = props;
    this.setState(state => ({
      ...state,
      wallLockers: { ...wallLockers }
    }));
  }

  getProductsByLockerSize = () => {
    return [
      ...(this.props.products[(this.state.selectedLocker || {}).size] || [])
    ];
  };

  render() {
    const columns = [
      { name: "Club", id: "club" },
      { name: "Vestidor", id: "vestidor" },
      { name: "Pared", id: "pared" },
      { name: "Tamaño", id: "size" },
      { name: "Posición", id: "posicion" },
      { name: "Número", id: "numero" },
      { name: "Estatus", id: "estatus" },
      {
        headerActions: []
      }
    ];
    const actions = [
      {
        name: "assign",
        f: this.handleSelectLocker,
        visible: (item: LockerDto) => {
          return (
            !!item.esAsignable && item.estatus === CONSTANTS.LOCKER_STATUS.FREE
          );
        }
      }
    ];

    return (
      <Container className="p-0 mt-3">
        <Form
          onSubmit={e => {
            e.preventDefault();
            this.handleSearchClicked(e);
          }}
        >
          <Row style={{ marginBottom: "20px" }}>
            <Col style={{ paddingBottom: "5px" }} xs={12} md={3} lg={2}>
              <BaseInput
                label={"Club"}
                name="lockerAssignmentClub"
                type="select"
                id="lockerAssignmentClub"
                placeholder="Club"
                value={this.props.selectedClubId}
                options={this.getOptionsClubes(this.props.clubsCatalog)}
                onChange={this.handleClubChanged}
                disabled={
                  this.props.searching ||
                  (this.props.userContext &&
                    this.props.userContext.clubSessionId)
                }
              />
            </Col>
            <Col style={{ paddingBottom: "5px" }} xs={12} md={3} lg={2}>
              <BaseInput
                label={"Vestidor"}
                name="lockerAssignmentRoom"
                type="select"
                id="lockerAssignmentRoom"
                placeholder="Vestidor"
                value={this.props.selectedLockerRoom}
                options={this.getOptionsLockerRooms()}
                onChange={this.handleLockerRoomChanged}
                disabled={this.props.searching || this.props.searchingClubWalls}
              />
            </Col>
            <Col style={{ paddingBottom: "5px" }} xs={12} md={3} lg={3}>
              <BaseInput
                label={"Pared"}
                name="lockerAssignmentWall"
                type="select"
                id="lockerAssignmentWall"
                placeholder="Pared"
                value={this.props.selectedWallId}
                options={this.getOptionsWalls(
                  this.props.lockerRoomWallsFiltered
                )}
                onChange={this.handleWallChanged}
                disabled={this.props.searching || this.props.searchingClubWalls}
              />
            </Col>
            <Col style={{ paddingBottom: "5px" }} xs={12} md={3} lg={2}>
              <BaseInput
                label={"No. Locker"}
                name="lockerAssignmentNumber"
                id="lockerAssignmentNumber"
                type="text"
                placeholder="No. Locker"
                onBlur={this.handleLockerNumberChanged}
                disabled={this.props.searching}
              />
            </Col>
            <Col xs={12} md={3} lg={2} className="mt-4">
              <Button
                size="sm"
                className={`${s.searchButton} ${s.primaryButton} float-right btn-block`}
                type="submit"
                disabled={
                  this.props.searching ||
                  this.props.searchingClubWalls ||
                  (!this.props.selectedClubId || this.props.selectedClubId < 0)
                }
              >
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col style={{ paddingBottom: "5px" }} xs={12} md={6} lg={8}>
            <PagedTable
              data={this.state.wallLockers}
              columns={columns}
              actions={actions}
              fetchData={this.props.fetchData}
              searching={this.props.searching}
              getTrProps={(_, locker) => {
                return locker
                  ? {
                      className: getLockerContextualStatusClass(locker.original)
                    }
                  : {};
              }}
            />
          </Col>
          <Col style={{ paddingBottom: "5px" }} xs={12} md={6} lg={4}>
            <LockerAssignmentForm
              selectedLocker={this.state.selectedLocker}
              selectedClubId={this.props.selectedClubId}
              onAssignProduct={this.handleAssignLocker}
              productsFilteredBySize={this.getProductsByLockerSize()}
              sellers={this.props.sellers}
              lockerDocuments={this.props.lockerDocuments}
              fileScanned={this.props.fileScanned}
              clear={this.state.clear}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(LockerAssignment));
