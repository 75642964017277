import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Row, TabPane } from "reactstrap";
import { setChildStep } from "../../../helpers/stepper-state-comparator";
import moment from "moment";
import s from "./tabMessages.scss";
import ListMessages from "../listMessages";
import AddMessage from "../addMessage";
import UpdateMessage from "../updateMessage";
import {
  updateMessageRequest,
  addMessageRequest
} from "../../../actions/partners";
import { handleRequestError } from "../../../../utils/helpers";

interface OPTION_MEMBERSHIP {
  idPersona: string;
  nombre: string;
  primerApellido: string;
  segundoApellido: string;
}

const formatTime = (dateTime?) =>
  moment(dateTime)
    .utc()
    .format("YYYY-MM-DD");

interface Props {
  idMembresiaSocio: string;
  messagesByMembresia: object[];
  partnersMembership: OPTION_MEMBERSHIP[];
  getMessages: () => any[];
}

class TabMessages extends React.Component<Props> {
  state = {
    filters: {
      id: "",
      personaId: "",
      idMembresiaSocio: "",
      titulo: "",
      descripcion: "",
      fechaAlerta: formatTime()
    },
    openDatePicker: false,
    alerta: false,
    activo: false,
    formAddNotification: false,
    formUpdateNotification: false
  };

  toUpdateMessage = objUpdate => {
    this.setState({
      formUpdateNotification: true,
      filters: objUpdate,
      alerta: objUpdate.alerta,
      activo: objUpdate.activo
    });
  };

  toAddNotification = () => {
    this.setState({
      formAddNotification: true,
      formUpdateNotification: false,
      filters: [],
      alerta: false
    });
  };

  returnListNotificaciones = () => {
    this.setState({
      formAddNotification: false,
      formUpdateNotification: false,
      filters: [],
      alerta: false
    });
    this.props.getMessages();
  };

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  onChangeCheck = e => {
    this.setState({
      alerta: e.target.checked,
      openDatePicker: e.target.checked
    });
  };

  onChangeCheckActivo = e => {
    this.setState({
      activo: e.target.checked
    });
  };

  onChangeFecha = () => {
    return dateTime => {
      const date = formatTime(dateTime);
      const filters = { ...this.state.filters, fechaAlerta: date };
      setChildStep(filters);
      this.setState({ ...this.state, filters });
    };
  };

  addNotification = () => {
    const { filters } = this.state;
    addMessageRequest({
      personaId: filters.idMembresiaSocio,
      titulo: filters.titulo,
      descripcion: filters.descripcion,
      alerta: this.state.alerta,
      fechaAlerta: this.state.alerta ? filters.fechaAlerta : null
    })
      .then(() => {
        this.returnListNotificaciones();
      })
      .catch(handleRequestError);
  };

  updateNotification = () => {
    const { filters } = this.state;
    updateMessageRequest(
      {
        personaId: filters.personaId,
        titulo: filters.titulo,
        descripcion: filters.descripcion,
        alerta: this.state.alerta,
        activo: this.state.activo
      },
      filters.id
    )
      .then(() => {
        this.returnListNotificaciones();
      })
      .catch(handleRequestError);
  };

  render() {
    const {
      formAddNotification,
      formUpdateNotification,
      filters,
      alerta,
      activo,
      openDatePicker
    } = this.state;

    const { partnersMembership, messagesByMembresia } = this.props;

    return (
      <TabPane className="my-2" tabId="8">
        <Row className="p-0 m-0">
          {formUpdateNotification ? (
            <UpdateMessage
              titulo={filters.titulo}
              alerta={alerta}
              activo={activo}
              idMembresiaSocio={filters.idMembresiaSocio}
              descripcion={filters.descripcion}
              returnListNotificaciones={this.returnListNotificaciones}
              onChangeFilter={this.onChangeFilter}
              updateNotification={this.updateNotification}
              onChangeCheckActivo={this.onChangeCheckActivo}
              onChangeCheck={this.onChangeCheck}
            />
          ) : !formAddNotification ? (
            <ListMessages
              messagesByMembresia={messagesByMembresia}
              toAddNotification={this.toAddNotification}
              toUpdateMessage={this.toUpdateMessage}
            />
          ) : (
            <AddMessage
              partnersMembership={partnersMembership}
              idMembresiaSocio={filters.idMembresiaSocio}
              titulo={filters.titulo}
              alerta={alerta}
              openDatePicker={openDatePicker}
              fechaAlerta={filters.fechaAlerta}
              descripcion={filters.descripcion}
              onChangeCheck={this.onChangeCheck}
              onChangeFilter={this.onChangeFilter}
              onChangeFecha={this.onChangeFecha}
              returnListNotificaciones={this.returnListNotificaciones}
              addNotification={this.addNotification}
            />
          )}
        </Row>
      </TabPane>
    );
  }
}

export default withStyles(s)(TabMessages);
