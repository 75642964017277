import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import constants from "../../utils/constants";
import * as fixture from "../actionsFixture/convenios";
import { restActionFactory } from "../../toolkit/axiosActions/axiosActionsFactory";
import axios from "axios";

const CONVENIOS_BASE_URL = "/api/cconvenios";

// Datos de convenios para listado en tabla
export const getConveniosList = (): IAction => {
  return conveniosRestAction({
    actionType: actionTypes.GET_CONVENIOS_LIST,
    method: "get",

    testing: false,
    fixture: fixture.convenios,
    reject: false
  });
};

// Limpieza para convenio para carrusel
export const clearConvenioCarousel = (): IAction => {
  return {
    type: actionTypes.CLEAR_CONVENIO_CAROUSEL,
    payload: true
  };
};

// Datos básicos de un convenio, con las empresas asociadas
export const getConvenioDatosBasicos = (idConvenio: number): IAction => {
  return conveniosRestAction({
    actionType: actionTypes.GET_CONVENIO_DATOS_BASICOS,
    urlComplement: `/${idConvenio}/datos-basicos`,
    method: "get",

    testing: false,
    fixture: fixture.convenio,
    reject: false
  });
};

// Creación de convenio con datos básicos
export const postConvenioDatosBasicos = (datosBasicos: any): IAction => {
  return conveniosRestAction({
    actionType: actionTypes.POST_CONVENIO_DATOS_BASICOS,
    urlComplement: `/datos-basicos`,
    body: datosBasicos,
    method: "post",

    testing: false,
    fixture: fixture.convenio,
    reject: false
  });
};

// Actualización de datos básicos del convenio
export const putConvenioDatosBasicos = (
  idConvenio: number,
  datosBasicos: any
): IAction => {
  return conveniosRestAction({
    actionType: actionTypes.PUT_CONVENIO_DATOS_BASICOS,
    urlComplement: `/${idConvenio}/datos-basicos`,
    body: datosBasicos,
    method: "put",

    testing: false,
    fixture: fixture.convenio,
    reject: false
  });
};

// Listado de empresas asignables a un convenio, es decir, protegiendo a las que tienen otro asociado
export const getEmpresasAsignables = (idConvenio: number): IAction => {
  return conveniosRestAction({
    actionType: actionTypes.GET_CONVENIO_EMPRESAS_ASIGNABLES,
    urlComplement: `/${idConvenio}/empresas-aplicables`,
    method: "get",

    testing: false,
    fixture: fixture.empresas,
    reject: false
  });
};

// Listado de empresas aplicables a un club y mantenimiento
export const getEmpresasActivas = (
  idClub: number,
  idMantenimiento: number
): IAction => {
  return conveniosRestAction({
    actionType: actionTypes.GET_CONVENIO_EMPRESAS_ACTIVAS,
    urlComplement: `/empresas-activas/${idClub}/${idMantenimiento}`,
    method: "get",

    testing: false,
    fixture: fixture.empresas,
    reject: false
  });
};

// Listado de empresas con convenio de uso
export const getEmpresasConvenio = (): IAction => {
  return conveniosRestAction({
    actionType: actionTypes.GET_CONVENIO_EMPRESAS_CONVENIO,
    urlComplement: `/empresas-convenio`,
    method: "get",

    testing: false,
    fixture: fixture.empresas,
    reject: false
  });
};

// Identificadores de los clubes asociados a un convenio
export const getClubesAsignables = (): IAction => {
  return conveniosRestAction({
    actionType: actionTypes.GET_CONVENIO_CLUBES_ASIGNABLES,
    urlComplement: `/clubes-asignables`,
    method: "get",

    testing: false,
    fixture: fixture.clubs,
    reject: false
  });
};

// Identificadores de los clubes asociados a un convenio
export const getClubesAsociados = (idConvenio: number): IAction => {
  return conveniosRestAction({
    actionType: actionTypes.GET_CONVENIO_CLUBES_ASOCIADOS,
    urlComplement: `/${idConvenio}/clubes-asociados`,
    method: "get",

    testing: false,
    fixture: fixture.clubsAsc,
    reject: false
  });
};

// Identificadores de los clubes asociados a un convenio
export const putClubesAsociados = (
  idConvenio: number,
  clubes: number[]
): IAction => {
  return conveniosRestAction({
    actionType: actionTypes.PUT_CONVENIO_CLUBES_ASOCIADOS,
    urlComplement: `/${idConvenio}/clubes-asociados`,
    body: clubes,
    method: "put",

    testing: false,
    fixture: fixture.clubsAsc,
    reject: false
  });
};

// Identificadores de los mantenimientos asociados a un convenio
export const getMantenimientosAplicables = (): IAction => {
  return conveniosRestAction({
    actionType: actionTypes.GET_CONVENIO_MANTENIMIENTOS_ASIGNABLES,
    urlComplement: `/mantenimientos-aplicables`,
    method: "get",

    testing: false,
    fixture: fixture.mantenimientos,
    reject: false
  });
};

// Identificadores de los mantenimientos asociados a un convenio
export const getMantenimientosAsociados = (idConvenio: number): IAction => {
  return conveniosRestAction({
    actionType: actionTypes.GET_CONVENIO_MANTENIMIENTOS_ASOCIADOS,
    urlComplement: `/${idConvenio}/mantenimientos-asociados`,
    method: "get",

    testing: false,
    fixture: fixture.mttoAsc,
    reject: false
  });
};

// Nombres de los mantenimientos asociados a un convenio
export const getMantenimientosAsociadosNombres = (
  idConvenio: number
): Promise<any> => {
  return axios.get(
    `${constants.BASE_URL}${CONVENIOS_BASE_URL}/${idConvenio}/mantenimientos-asociados-nombres`,
    {
      withCredentials: true
    }
  );
};

// Identificadores de los mantenimientos asociados a un convenio
export const putMantenimientosAsociados = (
  idConvenio: number,
  mantenimientos: number[]
): IAction => {
  return conveniosRestAction({
    actionType: actionTypes.PUT_CONVENIO_MANTENIMIENTOS_ASOCIADOS,
    urlComplement: `/${idConvenio}/mantenimientos-asociados`,
    body: mantenimientos,
    method: "put",

    testing: false,
    fixture: fixture.mttoAsc,
    reject: false
  });
};

// Construye una instancia de IAction para Redux, para manipular convenios
const conveniosRestAction = restActionFactory(
  `${constants.BASE_URL}${CONVENIOS_BASE_URL}`
);
