import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import { Container, Col, Button, Row } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import ReactTable from "react-table";
import s from "./styles.scss";
import IAction from "../../../types/IAction";
import {
  faFileExport,
  faCubes,
  faLeaf
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  OPTIONS_ESTATUS_EMPLEADOS,
  OPTIONS_EMPRESA,
  OPTIONS_ESTATUS_EVALUACION
} from "../../../utils/constants";
import ModalResumenEmployed from "./modalResumenEmployed";

interface Props {
  clubs: object[];
  getEmployees: (
    clubId: string,
    email: string,
    empresaId: string,
    estatus: string,
    estatusEvaluacion: string,
    nombre: string,
    numero: string,
    puesto: string,
    sinEmail: boolean,
    pageNumber: string,
    pageSize: string
  ) => IAction;
  reportEmployees: any;
  getEmployeesCsv: (body: object) => IAction;
  getpersonByID: (personId: string) => any;
  clearSelectedPerson: () => any;
  personByID: object;
  userContext: any;
}

interface State {
  filters: object;
  modalOpen: boolean;
}

class EmployeesReport extends React.Component<Props, State> {
  state = {
    filters: {
      clubId: null,
      email: null,
      empresa: null,
      estatus: null,
      estatusEvaluacion: null,
      nombre: null,
      numero: null,
      puesto: null,
      sinEmail: false
    },
    modalOpen: false
  };

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  onFetchData = ({ pageSize: size, page }) => {
    const {
      clubId,
      email,
      empresa,
      estatus,
      estatusEvaluacion,
      nombre,
      numero,
      puesto,
      sinEmail
    } = this.state.filters;

    this.props.getEmployees(
      clubId,
      email,
      empresa,
      estatus,
      estatusEvaluacion,
      nombre,
      numero,
      puesto,
      sinEmail,
      page ? page : "0",
      size ? size : "20"
    );
  };

  onClickDownloadCsvFile = () => {
    const {
      clubId,
      email,
      empresa,
      estatus,
      estatusEvaluacion,
      nombre,
      numero,
      puesto,
      sinEmail
    } = this.state.filters;

    this.props.getEmployeesCsv({
      clubId,
      email,
      empresa,
      estatus,
      estatusEvaluacion,
      nombre,
      numero,
      puesto,
      sinEmail
    });
  };

  columns = [
    {
      Header: () => <p className="font-weight-bold">Empelado Id</p>,
      id: 0,
      accessor: "empeladoId",
      Cell: row => {
        return <p>{row.original.empeladoId}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Club</p>,
      id: 0,
      accessor: "clubNombre",
      Cell: row => {
        return <p>{row.original.clubNombre}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Nombre empleado</p>,
      id: 0,
      accessor: "nombreEmpleado",
      Cell: row => {
        return <p>{row.original.nombreEmpleado}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Puesto</p>,
      id: 0,
      accessor: "puesto",
      Cell: row => {
        return <p>{row.original.puesto}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Empresa</p>,
      id: 0,
      accessor: "empresaNombre",
      Cell: row => {
        return <p>{row.original.empresaNombre}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Estado</p>,
      id: 0,
      accessor: "estatus",
      Cell: row => {
        return <p>{row.original.estatus}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Email</p>,
      id: 0,
      accessor: "email",
      Cell: row => {
        return <p>{row.original.email}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Estatus evaluación</p>,
      id: 0,
      accessor: "estatusEvaluacion",
      Cell: row => {
        return <p>{row.original.estatusEvaluacion}</p>;
      }
    },
    {
      Header: () => {
        return <p className="font-weight-bold">Opciones</p>;
      },
      id: -300,
      width: 80,
      accessor: "actions",
      Cell: row => {
        return (
          <Container className="p-0">
            <Row className="m-0">
              <Col className="px-1">
                <a
                  id={`complemento${row.original.empeladoId}`}
                  onClick={e => {
                    e.preventDefault();
                    this.props.getpersonByID(row.original.empeladoId);
                    this.setState({ modalOpen: true });
                  }}
                >
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faCubes}
                  />
                </a>
              </Col>
            </Row>
          </Container>
        );
      }
    }
  ];

  componentDidMount = () => {
    this.setState({
      filters: {
        ...this.state.filters,
        clubId:
          (this.props.userContext && this.props.userContext.clubSessionId) ||
          null
      }
    });
  };

  render() {
    const { clubs, reportEmployees } = this.props;
    const { clubId } = this.state.filters;
    return (
      <Container className="p-0">
        <ModalResumenEmployed
          personById={this.props.personByID}
          isOpen={this.state.modalOpen}
          toggle={() => {
            this.props.clearSelectedPerson();
            this.setState({
              modalOpen: !this.state.modalOpen
            });
          }}
        />
        <div className="row mt-3 mb-3">
          <div className="col">
            <p className={`${s.primaryTitle} w-100 m-0`}>
              Búsqueda de empleados
            </p>
          </div>
        </div>
        <Row>
          <Col xs={12} md={4} lg={4}>
            <BaseInput
              label="Club"
              name="club"
              id="club"
              type="select"
              placeholder="Seleccionar club"
              value={clubId}
              onChange={this.onChangeFilter("clubId")}
              size={"sm"}
              disabled={
                this.props.userContext && this.props.userContext.clubSessionId
              }
              options={[{ value: "null", label: "Seleccionar club" }].concat(
                clubs
                  ? clubs.map(option => ({
                      value: option.clubId,
                      label: option.nombre
                    }))
                  : []
              )}
            />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <BaseInput
              label={"Puesto"}
              name="puesto"
              type="text"
              id="puesto"
              value={this.state.filters.puesto}
              onChange={this.onChangeFilter("puesto")}
            />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <BaseInput
              label={"Nombre de empleado"}
              name=""
              type="text"
              id="nombre"
              value={this.state.filters.nombre}
              onChange={this.onChangeFilter("nombre")}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4} lg={4}>
            <BaseInput
              label={"Número de empleado"}
              name=""
              type="text"
              id="numero"
              value={this.state.filters.numero}
              onChange={this.onChangeFilter("numero")}
            />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <BaseInput
              label="Estatus"
              name="estatus"
              id="estatus"
              type="select"
              placeholder="Seleccionar estatus"
              value={this.state.filters.estatus}
              onChange={this.onChangeFilter("estatus")}
              size={"sm"}
              options={[{ value: "null", label: "Seleccionar estatus" }].concat(
                OPTIONS_ESTATUS_EMPLEADOS.map(option => ({
                  value: option,
                  label: option
                }))
              )}
            />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <BaseInput
              label={"Email"}
              name=""
              type="text"
              id="email"
              value={this.state.filters.email}
              onChange={this.onChangeFilter("email")}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4} lg={4}>
            <BaseInput
              label="Empresa"
              name="empresa"
              id="empresa"
              type="select"
              placeholder="Seleccionar empresa"
              value={this.state.filters.empresa}
              onChange={this.onChangeFilter("empresa")}
              size={"sm"}
              options={[{ value: "null", label: "Seleccionar empresa" }].concat(
                OPTIONS_EMPRESA.map(option => ({
                  value: option,
                  label: option
                }))
              )}
            />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <BaseInput
              label="Estatus evaluación"
              name="estatusEvaluacion"
              id="estatusEvaluacion"
              type="select"
              placeholder="Seleccionar estatus evaluación"
              value={this.state.filters.estatusEvaluacion}
              onChange={this.onChangeFilter("estatusEvaluacion")}
              size={"sm"}
              options={[
                { value: "null", label: "Seleccionar estatus evaluación" }
              ].concat(
                OPTIONS_ESTATUS_EVALUACION.map(option => ({
                  value: option,
                  label: option
                }))
              )}
            />
          </Col>
          <Col xs={12} md={4} lg={4} className="mt-4">
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.onFetchData}
            >
              Buscar
            </Button>
          </Col>
        </Row>
        {reportEmployees &&
        reportEmployees.content &&
        reportEmployees.content.length > 0 ? (
          <Row className={s.customRowCSV}>
            <Col className={s.customTextoCSV}>Exportar archivo CSV</Col>
            <Col className={s.customLogoCSV}>
              <Button
                id="btnLockerSummaryExportCsv"
                color="link"
                onClick={this.onClickDownloadCsvFile}
              >
                <FontAwesomeIcon icon={faFileExport} color="black" />
              </Button>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <Row className={s.customRow}>
          <Col>
            <ReactTable
              manual
              data={reportEmployees ? reportEmployees.content : []}
              pages={reportEmployees ? reportEmployees.totalPages : 0}
              columns={this.columns}
              className="w-100"
              onFetchData={this.onFetchData}
              defaultPageSize={20}
              sortable={false}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(EmployeesReport));
