import React from "react";
import ReactTable from "react-table";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalFrame from "../../../../toolkit/modalFrame";
import BaseInput from "../../../../toolkit/baseInput";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Container, Col, Button, Row } from "reactstrap";
import s from "./styles.scss";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import { withRouter } from "react-router-dom";

interface Props {
  toDo: string;
  errorValIntPreguntas: string;
  errorValIntTotalPreguntas: string;
  errorOrdenInt: string;
  isOpen: object;
  fieldsActions: any;
  quizzesList: any;
  toggleModal: any;
  addEmptyQuiz: any;
  renderInputText: any;
  renderInputSelect: any;
  renderActions: any;
  onChangeFactoryActions: any;
  saveQuiz: any;
  opcionesCategoria: object;
  opcionesEstatus: object;
  listQuestionsQuizzes: object;
}

interface State {}

class ActionsQuizzesList extends React.Component<Props, State> {
  state = {};

  ENUM_TIPO = ["Opinión", "Percepción"];

  ENUM_ESTATUS = ["Activo", "Inactivo", "Eliminado"];

  ENUM_CATEGORIA = [
    "Demográficos",
    "Autonomía",
    "Cohesión y trabajo en equipo",
    "Limpieza",
    "Comunicación",
    "Dirección y liderazgo",
    "Pertenencia",
    "Planeación, organización y calidad",
    "Reconocimiento y compenzación",
    "Atención",
    "Servicio",
    "Equipamiento",
    "Instalación",
    "Evaluación"
  ];

  render() {
    return (
      <ModalFrame
        title={`${this.props.toDo} encuesta`}
        isOpen={this.props.isOpen}
        toggle={this.props.toggleModal}
        size="lg"
      >
        <Container>
          {this.props.errorValIntPreguntas !== null ? (
            <Row>
              <Col className={`${s.customErrorMessage}`}>
                {this.props.errorValIntPreguntas}
              </Col>
            </Row>
          ) : (
            ""
          )}
          {this.props.errorValIntTotalPreguntas !== null ? (
            <Row>
              <Col className={`${s.customErrorMessage}`}>
                {this.props.errorValIntTotalPreguntas}
              </Col>
            </Row>
          ) : (
            ""
          )}
          {this.props.errorOrdenInt !== null ? (
            <Row>
              <Col className={`${s.customErrorMessage}`}>
                {this.props.errorOrdenInt}
              </Col>
            </Row>
          ) : (
            ""
          )}
          <Row>
            <Col>
              <BaseInput
                label={"Nombre"}
                name="nombre"
                type="text"
                id="nombre"
                value={this.props.fieldsActions.nombre}
                onChange={this.props.onChangeFactoryActions("nombre")}
              />
            </Col>
            <Col>
              <BaseInput
                label={"Identificador"}
                name="identificador"
                type="text"
                id="identificador"
                value={this.props.fieldsActions.identificador}
                onChange={this.props.onChangeFactoryActions("identificador")}
              />
            </Col>
            <Col>
              <BaseInput
                label={"Categoría"}
                name="categoria"
                type="select"
                options={this.props.opcionesCategoria}
                id="categoria"
                value={this.props.fieldsActions.categoria}
                onChange={this.props.onChangeFactoryActions(
                  "encuestaCategoriaId"
                )}
              />
            </Col>
            <Col>
              <BaseInput
                label={"Estatus"}
                name="estatus"
                type="select"
                options={this.props.opcionesEstatus}
                id="estatus"
                value={this.props.fieldsActions.estatus}
                onChange={this.props.onChangeFactoryActions("estatus")}
              />
            </Col>
          </Row>
          <Row className={`${s.customDistancia}`}>
            <Col>
              <ReactTable
                data={this.props.quizzesList}
                columns={[
                  {
                    Header: () => (
                      <div className="w-100 text-center">
                        <Button
                          color="link"
                          className={`${s.innerButton} ${s.primaryLink}`}
                          onClick={this.props.addEmptyQuiz}
                        >
                          <FontAwesomeIcon icon={faPlusCircle} />
                        </Button>
                      </div>
                    ),
                    columns: [
                      {
                        Header: "Orden",
                        accessor: "orden",
                        Cell: this.props.renderInputText,
                        className: "p-0",
                        width: 80
                      },
                      {
                        Header: "Pregunta",
                        accessor: "pregunta",
                        Cell: this.props.renderInputText,
                        className: "p-0"
                      },
                      {
                        Header: "Valor",
                        accessor: "valor",
                        Cell: this.props.renderInputText,
                        className: "p-0",
                        width: 80
                      },
                      {
                        Header: "Tipo",
                        accessor: "tipo",
                        Cell: this.props.renderInputSelect(this.ENUM_TIPO),
                        className: "p-0",
                        width: 120
                      },
                      {
                        Header: "Categoría",
                        accessor: "categoria",
                        Cell: this.props.renderInputSelect(this.ENUM_CATEGORIA),
                        className: "p-0",
                        width: 180
                      },
                      {
                        Header: "Estatus",
                        accessor: "estatus",
                        Cell: this.props.renderInputSelect(this.ENUM_ESTATUS),
                        className: "p-0",
                        width: 120
                      },
                      {
                        Header: "Acciones",
                        id: "rolId",
                        accessor: quiz => (
                          <Row> {this.props.renderActions(quiz)} </Row>
                        ),
                        maxWidth: 70
                      }
                    ]
                  }
                ]}
                pageSize={this.props.quizzesList.length}
                sortable={false}
                showPagination={false}
                className="-highlight -striped w-100"
                getTbodyProps={() => ({
                  style: { backgroundColor: "#EAECEE" }
                })}
              />
            </Col>
          </Row>
          <Row className={`${s.customDistancia}`}>
            <Col xs={12} md={6} lg={6}>
              <Button block color="secondary" onClick={this.props.toggleModal}>
                Cancelar
              </Button>
            </Col>
            <Col xs={12} md={6} lg={6}>
              <Button
                block
                color="secondary"
                onClick={this.props.saveQuiz}
                disabled={
                  this.props.fieldsActions.categoria === "" ||
                  this.props.fieldsActions.estatus === "" ||
                  this.props.fieldsActions.identificador === "" ||
                  this.props.fieldsActions.nombre === ""
                }
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Container>
      </ModalFrame>
    );
  }
}

export default withRouter(withStyles(s)(ActionsQuizzesList));
