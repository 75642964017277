import React from "react";
import { Container } from "reactstrap";
import {
  errorNotification,
  successNotification
} from "../../../utils/notifications";
import { Router, Styled } from "../../../toolkit/decorators/Artifact";
import FormBuilder from "../../../toolkit/baseForms2/formBuilder/FormBuilder";
import IButtonBuilder from "../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import { Buttons, formConfig, Inputs } from "./ClasesSinComision.form";
import { MemoTable } from "./ClasesSinComision.table";
import s from "./styles.scss";
import { ClaseSinComisionStore } from "../../containers/claseSinComision/ClaseSinComision.store";
import { ClaseSinComisionDispatcher } from "../../containers/claseSinComision/ClaseSinComision.dispatcher";
import claseSinComisionCsv from "../../helpers/claseSinComisionCsv";
import { warnNotification } from "../../../utils/notifications";

interface Props extends ClaseSinComisionStore, ClaseSinComisionDispatcher {}

interface State {
  selected: number[];
}

@Router()
@Styled(s)
export default class ClaseSinComision extends React.Component<Props, State> {
  state = {
    selected: []
  };

  onCheck = (id: number, checked: boolean) => {
    const selected = checked
      ? this.state.selected.includes(id)
        ? [...this.state.selected]
        : [...this.state.selected, id]
      : this.state.selected.filter(sel => sel != id);

    this.setState({ selected });
  };

  onCheckAll = (checked: boolean) => {
    const clases = this.props.clasesSinComision || [];
    this.setState({
      selected: checked ? clases.map(c => c.id) : []
    });
  };

  submit = async (fields: { [key in Inputs]: string }) => {
    const preview = await this.props.getClasesSinComision({
      club: fields.club || null,
      instructor: fields.instructor || null,
      folioFactura: fields.folioFactura || null
    });

    if (preview.fulfilled) {
      this.setState({ selected: [] });
    } else {
      errorNotification(preview.clasesSinComisionError);
    }
  };

  generar = async () => {
    // Se garantiza que no se repitan las clases
    const solicitudes = this.state.selected.filter(
      (value, index, array) => array.indexOf(value) === index
    );

    if (!solicitudes.length) {
      errorNotification("Seleccione las clases que debe generar");
      return;
    }

    // Se reparan las comisiones de las clases seleccionadas
    const preview = await this.props.postClasesComisionGenerar(
      this.state.selected
    );

    if (!preview.fulfilled) {
      errorNotification(preview.clasesComisionGenerarError);
    } else if (!preview.clasesComisionGeneradas.length) {
      errorNotification("El puesto de trabajo de los instructores es inválido");
    } else if (preview.clasesComisionGeneradas.length < solicitudes.length) {
      warnNotification(
        "No se pudieron generar todas las comisiones, verifique"
      );
      this.download(preview.clasesComisionGeneradas);
    } else {
      successNotification("Comisiones generadas, verifique el resultado");
      this.download(preview.clasesComisionGeneradas);
    }
  };

  download = (resultado?: number[]) => {
    const paraReporte = resultado
      ? this.props.clasesSinComision
          .filter(
            c => this.state.selected.includes(c.id) && resultado.includes(c.id)
          )
          .map(c => ({ ...c }))
      : this.props.clasesSinComision.map(c => ({ ...c }));

    paraReporte.length &&
      claseSinComisionCsv(
        paraReporte,
        resultado ? "ComisionesGeneradas" : "ClasesSinComision"
      );
  };

  render() {
    return (
      <Container className="mb-5">
        <FormBuilder<Inputs, Buttons>
          config={formConfig}
          submit={this.submit}
          processing={this.props.clasesSinComisionLoading}
        >
          <IButtonBuilder<Inputs, Buttons>
            name="generar"
            onClick={this.generar}
            processing={this.props.clasesComisionGenerarLoading}
            disabled={
              !!this.props.clasesComisionGeneradas ||
              !this.props.clasesSinComision
            }
          />
        </FormBuilder>

        <hr className="row" />

        <MemoTable
          data={this.props.clasesSinComision || []}
          loading={
            this.props.clasesSinComisionLoading ||
            this.props.clasesComisionGenerarLoading
          }
          onExport={this.download}
          onCheck={this.onCheck}
          onCheckAll={this.onCheckAll}
          processed={this.props.clasesComisionGeneradas}
          selected={this.state.selected}
        />
      </Container>
    );
  }
}
