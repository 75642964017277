import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";

export const getUsers = (): IAction => {
  return {
    type: actionTypes.GET_USERS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}/api/accounts`, {
        withCredentials: true
      })
    }
  };
};

export const getUsersRequest = () => {
  return axios.get(`${constants.BASE_URL}/api/accounts`, {
    withCredentials: true
  });
};

export const getGeneralManagers = (): IAction => {
  return {
    type: actionTypes.GET_GENERAL_MANAGERS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}/api/accounts/generalManagers`, {
        withCredentials: true
      })
    }
  };
};
