import IAction from "../../types/IAction";
import axios from "axios";
import constants from "../../utils/constants";
import actionTypes from "../actionTypes";
const DISCOUNT_BASE_URL = "/api/discountCode";
import FileDownload from "js-file-download";
import { QueryArguments } from "../../types-business/QueryArguments";

export const getCodesListLte = (params: any): IAction => {
  const url = `${constants.BASE_URL}${DISCOUNT_BASE_URL}/getCodesListLte`;
  return {
    type: actionTypes.GET_DISCOUNT_CODES_LTE,
    payload: {
      promise: axios.get(url, {
        withCredentials: true,
        params
      })
    }
  };
};

export const createCodes = (data: object): IAction => {
  return {
    type: actionTypes.CREATE_DISCOUT_CODES,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${DISCOUNT_BASE_URL}/create`,
        data,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getCodesList = (lote: string, args?: QueryArguments): IAction => {
  return {
    type: actionTypes.GET_DISCOUT_CODES_LIST,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${DISCOUNT_BASE_URL}/getCodesList/`,
        {
          withCredentials: true,
          params: { lote, ...args, sort: "desc" }
        }
      )
    }
  };
};

export const clearCodes = (): IAction => {
  return {
    type: actionTypes.CLEAR_DISCOUT_CODES,
    payload: null
  };
};

export const getDiscountCodesListFile = (lote): IAction => {
  return {
    type: actionTypes.GET_DISCOUNT_CODES_LIST_FILE,
    payload: {
      promise: axios
        .get(
          `${constants.BASE_URL}${DISCOUNT_BASE_URL}/getCodesListFile/${lote}`,
          {
            withCredentials: true,
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          FileDownload(data, "discount-codes-list.csv");
        })
    }
  };
};

export const getPassDayList = (idPersona: number): IAction => {
  return {
    type: actionTypes.GET_PASS_DAY_LIST,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${DISCOUNT_BASE_URL}/getPassDayList/${idPersona}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const activateDayPass = (data: object): IAction => {
  return {
    type: actionTypes.ACTIVATE_DAY_PASS,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${DISCOUNT_BASE_URL}/activateDayPass`,
        data,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getCodesListLtebyMembrsh = (idMembresia: string): IAction => {
  return {
    type: actionTypes.GET_DISCOUNT_CODES_LTE_MEMBRSH,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${DISCOUNT_BASE_URL}/getCodesListLteMembrsh/${idMembresia}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getSingleCode = (data: object): IAction => {
  return {
    type: actionTypes.GET_SINGLE_CODE,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${DISCOUNT_BASE_URL}/getSingleCode`,
        data,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const clearSingleCode = (): IAction => {
  return {
    type: actionTypes.CLEAR_SINGLE_CODE,
    payload: null
  };
};

export const getDirectIdMembresiaSocio = (idMembresiaSocio): Promise<any> => {
  return axios.get(
    `${constants.BASE_URL}${DISCOUNT_BASE_URL}/directo/${idMembresiaSocio}`,
    {
      withCredentials: true
    }
  );
};

export const postDirectUpload = (file): Promise<any> => {
  const data = new FormData();
  data.append("file", file);

  return axios.post(
    `${constants.BASE_URL}${DISCOUNT_BASE_URL}/directo/upload`,
    data,
    {
      withCredentials: true
    }
  );
};

export const postDirectConfirmar = (descuentos): Promise<any> => {
  return axios.post(
    `${constants.BASE_URL}${DISCOUNT_BASE_URL}/directo/confirmar`,
    descuentos,
    {
      withCredentials: true
    }
  );
};
