import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import SchedulePatterns from "../../components/SchedulePatterns";
import Layout from "../../../Home/components/layout";
import {
  storePattern,
  getPatterns,
  getPatternById
} from "../../actions/schedules";

interface Props {
  storePattern: (fields: object) => any;
  getPatterns: () => any;
  patterns: object[];
  getPatternById: (patternId: number) => any;
}

class SchedulePatternsContainer extends React.Component<Props> {
  componentDidMount() {
    this.props.getPatterns();
  }

  render() {
    return (
      <main>
        <Helmet title="Patrones de Horario" />
        <Layout>
          {this.props.patterns && (
            <SchedulePatterns
              storePattern={this.props.storePattern}
              getPatterns={this.props.getPatterns}
              patterns={this.props.patterns}
              getPatternById={this.props.getPatternById}
            />
          )}
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = {
  storePattern,
  getPatterns,
  getPatternById
};

const mapStateToProps = state => {
  const {
    schedulePatterns: { patterns, patternById }
  } = state;

  return {
    patterns,
    patternById
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SchedulePatternsContainer)
);
