import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Crm1PartnerMembershiptReactivate from "../../components/crm1PartnerMembershiptReactivate";
import Layout from "../../components/layout";

interface Props {
  userContext: { clubSessionId: any; clubs: any[] };
}

class Crm1PartnerMembershipReactivatePage extends React.Component<Props, void> {
  componentDidMount() {}

  render() {
    return (
      <main>
        <Helmet title="Prospects" />
        <Layout>
          <Crm1PartnerMembershiptReactivate />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => {
  const {} = state;
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Crm1PartnerMembershipReactivatePage);
