import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Container, Row, Col, Button } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import MexicanCurrency from "../common/currency";

interface Props {
  getSportsProductsByFilters: (filters: object) => any;
  getPromotions: (productoId: string) => any;
  getQuotation: (filters: object) => any;
  cleanSportsProducts: () => any;
  cleanPromotions: () => any;
  cleanQuotation: () => any;
  toggleAddPersonModal: () => any;
  clubs: Club[];
  personTypes: Person[];
  sportProductTypes: SportProductTypes[];
  sportsProducts: SportsProduct[];
  promotions: Promotion[];
  quotation: Quotation;
  selectedPerson: any;
  tiposPrecio: tipoPrecio[];
}

interface Club {
  clubId: string;
  nombre: string;
}

interface Person {
  id: string;
  nombre: string;
}

interface SportProductTypes {
  id: string;
  nombre: string;
}

interface Promotion {
  idDatosBasicosPromocion: string;
  nombre: string;
}

interface SportsProduct {
  id: string;
  nombre: string;
}

interface Quotation {
  precioEmpleado: string;
  precioSocio: string;
  precioPublico: string;
  fechaInicioVigencia: string;
  fechaFinVigencia: string;
}

interface tipoPrecio {
  esquemaPagoId: string;
  nombre: string;
}

class SportsProducts extends React.Component<Props> {
  state = {
    filters: {
      tipoPersona: "",
      clubId: "",
      tipoProducto: "",
      tipoPagoId: "",
      productoId: "",
      promocionId: ""
    }
  };

  changeFilter = async (e, fieldName) => {
    const { value } = e.target;
    this.setState(state => {
      const filters = {
        ...this.state.filters,
        [fieldName]: value
      };
      return { ...state, filters };
    });
  };

  changeClub = async e => {
    const { value } = e.target;
    await this.props.cleanQuotation();
    await this.setState(state => {
      const filters = {
        ...this.state.filters,
        clubId: value,
        productoId: "",
        promocionId: ""
      };
      return { ...state, filters };
    });
    if (
      this.state.filters.clubId === "" &&
      this.state.filters.tipoProducto === ""
    ) {
      this.props.cleanSportsProducts();
    } else {
      this.props.getSportsProductsByFilters(this.state.filters);
    }
    this.props.cleanPromotions();
  };

  changeSportProductTypes = async e => {
    const { value } = e.target;
    await this.props.cleanQuotation();
    await this.setState(state => {
      const filters = {
        ...this.state.filters,
        tipoProducto: value,
        productoId: "",
        promocionId: ""
      };
      return { ...state, filters };
    });
    if (
      this.state.filters.clubId === "" &&
      this.state.filters.tipoProducto === ""
    ) {
      this.props.cleanSportsProducts();
    } else {
      this.props.getSportsProductsByFilters(this.state.filters);
    }
    this.props.cleanPromotions();
  };

  changePaymentType = async e => {
    const { value } = e.target;
    await this.props.cleanQuotation();
    await this.setState(state => {
      const filters = {
        ...this.state.filters,
        tipoPagoId: value
      };
      return { ...state, filters };
    });
    if (
      this.state.filters.tipoPersona !== "" &&
      this.state.filters.productoId !== "" &&
      this.state.filters.tipoPagoId !== ""
    )
      this.props.getQuotation(this.state.filters);
  };

  changeSportsProduct = async e => {
    const { value } = e.target;
    await this.props.cleanQuotation();
    await this.setState(state => {
      const filters = {
        ...this.state.filters,
        productoId: value,
        promocionId: ""
      };
      return { ...state, filters };
    });
    if (this.state.filters.productoId === "") {
      this.props.cleanPromotions();
    } else {
      this.props.getPromotions(this.state.filters.productoId);
    }
    if (
      this.state.filters.tipoPersona !== "" &&
      this.state.filters.productoId !== "" &&
      this.state.filters.tipoPagoId !== ""
    )
      this.props.getQuotation(this.state.filters);
  };

  changePromotion = async e => {
    const { value } = e.target;
    await this.props.cleanQuotation();
    await this.setState(state => {
      const filters = {
        ...this.state.filters,
        promocionId: value
      };
      return { ...state, filters };
    });
    if (
      this.state.filters.productoId !== "" &&
      this.state.filters.tipoPagoId !== ""
    ) {
      this.props.getQuotation(this.state.filters);
    } else {
      this.props.cleanPromotions();
    }
  };

  render() {
    return (
      <Container className="w-100 p-0 my-3">
        <Row>
          <Col xs={6} className="w-100 p-0 m-0">
            <Row className="w-100 p-0 m-0">
              <Col>
                <BaseInput
                  label={"Tipo de Persona"}
                  name={"tipoPersona"}
                  type="select"
                  id={"tipoPersona"}
                  placeholder="Tipo de Persona"
                  value={this.state.filters.tipoPersona}
                  size="sm"
                  disabled={!this.props.personTypes}
                  onChange={e => this.changeFilter(e, "tipoPersona")}
                  options={[
                    { value: "", label: "Seleccionar Tipo de Persona" }
                  ].concat(
                    this.props.personTypes
                      ? this.props.personTypes.map(option => ({
                          value: option.id,
                          label: option.nombre
                        }))
                      : []
                  )}
                />
              </Col>
              <Col>
                <BaseInput
                  label={"Club"}
                  name={"Club"}
                  type="select"
                  id={"club"}
                  placeholder="Club"
                  value={this.state.filters.clubId}
                  size="sm"
                  disabled={!this.props.clubs}
                  onChange={e => this.changeClub(e)}
                  options={[{ value: "", label: "Seleccionar Club" }].concat(
                    this.props.clubs
                      ? this.props.clubs.map(option => ({
                          value: option.clubId,
                          label: option.nombre
                        }))
                      : []
                  )}
                />
              </Col>
            </Row>
            <Row className="w-100 p-0 m-0">
              <Col>
                <BaseInput
                  label={"Tipo Producto"}
                  name={"tipoProducto"}
                  type="select"
                  id={"tipoProducto"}
                  placeholder="Tipo Producto"
                  value={this.state.filters.tipoProducto}
                  size="sm"
                  disabled={!this.props.sportProductTypes}
                  onChange={e => this.changeSportProductTypes(e)}
                  options={[
                    {
                      value: "",
                      label: "Seleccionar Tipo de Producto"
                    }
                  ].concat(
                    this.props.sportProductTypes
                      ? this.props.sportProductTypes.map(option => ({
                          value: option.id,
                          label: option.nombre
                        }))
                      : []
                  )}
                />
              </Col>
              <Col>
                <BaseInput
                  label={"Tipo de Pago"}
                  name={"tipoPagoId"}
                  type="select"
                  id={"tipoPagoId"}
                  placeholder="Tipo de Pago"
                  value={this.state.filters.tipoPagoId}
                  size="sm"
                  disabled={!this.props.tiposPrecio}
                  onChange={e => this.changePaymentType(e)}
                  options={[
                    { value: "", label: "Seleccionar Tipo de Pago" }
                  ].concat(
                    this.props.tiposPrecio
                      ? this.props.tiposPrecio.map(option => ({
                          value: option.esquemaPagoId,
                          label: option.nombre
                        }))
                      : []
                  )}
                />
              </Col>
            </Row>
            <Row className="w-100 p-0 m-0">
              <Col>
                <BaseInput
                  label={"Producto"}
                  name={"productoId"}
                  type="select"
                  id={"productoId"}
                  placeholder="Producto"
                  value={this.state.filters.productoId}
                  size="sm"
                  disabled={
                    !this.props.sportsProducts ||
                    this.state.filters.tipoPersona === ""
                  }
                  onChange={e => this.changeSportsProduct(e)}
                  options={[
                    { value: "", label: "Seleccionar Producto" }
                  ].concat(
                    this.props.sportsProducts
                      ? this.props.sportsProducts.map(option => ({
                          value: option.id,
                          label: option.nombre
                        }))
                      : []
                  )}
                />
              </Col>
              <Col>
                <BaseInput
                  label={"Promoción"}
                  name={"promocionId"}
                  type="select"
                  id={"promocionId"}
                  placeholder="Promoción"
                  value={this.state.filters.promocionId}
                  size="sm"
                  disabled={
                    !this.props.promotions && !this.state.filters.productoId
                  }
                  onChange={e => this.changePromotion(e)}
                  options={[
                    { value: "", label: "Seleccionar Promoción" }
                  ].concat(
                    this.props.promotions
                      ? this.props.promotions.map(option => ({
                          value: option.idDatosBasicosPromocion,
                          label: option.nombre
                        }))
                      : []
                  )}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={6} className="m-0 d-flex align-items-center">
            <Row
              className="mt-1 m-0 alert alert-secondary row p-0 w-100"
              role="alert"
            >
              <table className="table m-0">
                <tbody>
                  <tr>
                    <th scope="row">PRECIO</th>
                    <td>
                      <div className="float-right">
                        {this.props.quotation && (
                          <MexicanCurrency
                            quantity={
                              this.state.filters.tipoPersona === "SOCIO"
                                ? this.props.quotation.precioSocio
                                : this.state.filters.tipoPersona === "EMPLEADO"
                                ? this.props.quotation.precioEmpleado
                                : this.state.filters.tipoPersona === "INVITADO"
                                ? this.props.quotation.precioPublico
                                : 0
                            }
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                  {this.props.selectedPerson && (
                    <tr>
                      <th scope="row">NOMBRE</th>
                      <td>
                        <div className="float-right">
                          {`${this.props.selectedPerson.nombre} ${this.props.selectedPerson.primerApellido} ${this.props.selectedPerson.segundoApellido}`}
                        </div>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th scope="row">
                      {false && (
                        <Button
                          className={`${s.buttonMarginTop} ${s.primaryButton} float-right`}
                          onClick={() => this.props.toggleAddPersonModal()}
                          size="sm"
                          block
                        >
                          {"Persona"}
                        </Button>
                      )}
                    </th>
                    <td>
                      {this.props.selectedPerson &&
                        this.props.quotation &&
                        (this.props.quotation.precioPublico ||
                          this.props.quotation.precioEmpleado ||
                          this.props.quotation.precioSocio) &&
                        false && (
                          <Button
                            className={`${s.buttonMarginTop} ${s.primaryButton} float-right`}
                            onClick={() => true}
                            size="sm"
                            block
                          >
                            {"Vender"}
                          </Button>
                        )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Row>
          </Col>
        </Row>
        <hr />
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(SportsProducts));
