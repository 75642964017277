import FormBuilderConfig from "../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IButtonBuilder from "../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import ITextBuilder from "../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";
import IClubSelectorBuilder from "../../../toolkit/baseForms2Commons/iClubSelectorBuilder/IClubSelectorBuilder";

export type Inputs = "clubId" | "personaId" | "persona" | "membresia";
export type Buttons = "buscar";
export const formConfig: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "clubId",
      label: "* Club",
      bts: {
        sCol: 3
      },
      component: IClubSelectorBuilder,
      props: {
        includeAll: true
      }
    },
    {
      name: "membresia",
      label: "No. de Membresía",
      bts: {
        sCol: 2
      },
      component: ITextBuilder
    },
    {
      name: "personaId",
      label: "Id de Persona",
      bts: {
        sCol: 2
      },
      component: ITextBuilder
    },
    {
      name: "persona",
      label: "Nombre de Persona",
      bts: {
        sCol: 5
      },
      component: ITextBuilder
    }
  ],
  validations: {
    clubId: {
      required: true
    }
  },
  buttons: [
    {
      name: "buscar",
      label: "Buscar",
      isSubmit: true,
      component: IButtonBuilder,
      bts: {
        sCol: 3,
        sOff: 9
      }
    }
  ],
  rows: [["clubId", "membresia", "personaId", "persona", "buscar"]]
};
