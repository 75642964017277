import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import * as clubsActions from "../../actions/clubs";
import * as dealersActions from "../../actions/dealers";
import * as sportsAction from "../../actions/sportsProducts";
import IAction from "../../../types/IAction";
import MovementStatusPage from "../../components/movementStatusPage";

interface Props {
  getAllClubsBasicData: () => void;
  clubs: object[];
  depositoReferenciado: object[];
  clearDealers: () => IAction;
  getMovements: (
    fechaInicio: string,
    fechaFin: string,
    body: object
  ) => IAction;
  downloadMovementStatus: (
    fechaInicio: string,
    fechaFin: string,
    body: object
  ) => IAction;
  dealersMovement: object;
  acceptCharge: (cargoConcecionarioId: string) => IAction;
  dealers: object;
  deleteProducts: (cargoConcecionarioId: string) => IAction;
  deletingProduct: boolean;
  deletingProductError: any;
  acceptChargeValidate: boolean;
  errorAcceptCharge: boolean;
}

interface State {}

class MovementStatus extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    this.props.getAllClubsBasicData();
  }

  componentWillUnmount() {
    this.props.clearDealers();
  }

  render() {
    return (
      <main>
        <Helmet title="Shift delivery" />
        <Layout>
          <MovementStatusPage
            clubs={this.props.clubs}
            depositoReferenciado={this.props.depositoReferenciado}
            getMovements={this.props.getMovements}
            dealersMovement={this.props.dealersMovement}
            downloadMovementStatus={this.props.downloadMovementStatus}
            acceptCharge={this.props.acceptCharge}
            dealers={this.props.dealers}
            deleteProducts={this.props.deleteProducts}
            deletingProduct={this.props.deletingProduct}
            deletingProductError={this.props.deletingProductError}
            acceptChargeValidate={this.props.acceptChargeValidate}
            errorAcceptCharge={this.props.errorAcceptCharge}
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({ clubs, reports, dealers, sportsProducts }) => {
  return {
    clubs: clubs.allClubsBasicData,
    depositoReferenciado: reports.depositoReferenciado,
    dealersMovement: dealers.dealersMovement,
    dealers,
    deletingProduct: sportsProducts.deletingProduct,
    deletingProductError: sportsProducts.deletingProductError,
    acceptChargeValidate: dealers.acceptCharge,
    errorAcceptCharge: dealers.errorAcceptCharge
  };
};

const mapDispatchToProps = dispatch => ({
  getAllClubsBasicData: () => {
    dispatch(clubsActions.getAllClubsBasicData());
  },
  clearDealers: () => {
    return dispatch(dealersActions.clearDealers());
  },
  acceptCharge: (cargoConcecionarioId: string) => {
    return dispatch(dealersActions.acceptCharge(cargoConcecionarioId));
  },
  getMovements: (fechaInicio: string, fechaFin: string, body: object) => {
    return dispatch(dealersActions.getMovements(fechaInicio, fechaFin, body));
  },
  deleteProducts: (cargoConcecionarioId: string) => {
    return dispatch(sportsAction.deleteProducts(cargoConcecionarioId));
  },
  downloadMovementStatus: (
    fechaInicio: string,
    fechaFin: string,
    body: object
  ) => {
    return dispatch(
      dealersActions.downloadMovementStatus(fechaInicio, fechaFin, body)
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MovementStatus);
