import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import SportsProductsNewQuote from "../../components/sportsProductsNewQuote";
import * as catalogActions from "../../actions/catalogs";
import { getSellers } from "../../actions/catalogs";
import { addDataLogueo } from "../../actions/terminal";
import {
  getPricesToSportsProductsByDate,
  getQuoteByPersonId,
  getCashboxPersonId
} from "../../actions/sportsProducts";
import constants, { STATUS_PRICE_ACTIVE } from "../../../utils/constants";
import {
  loadSPQuote,
  saveSPQuote,
  deleteProducts
} from "../../actions/sportsProducts";
import {
  clearSelectedPerson,
  getPersonById,
  setSelectedPerson
} from "../../actions/persons";
import { allowedTo } from "../../../utils/accessTree";
import accessCodes from "../../../utils/accessCodes";
const { CARRITO_FITKIDZ } = accessCodes;
import moment from "moment";

interface Props {
  getSportsProductsTypes: () => any;
  sportsProductsTypes: object[];
  searchSportsProducts: (args: object) => any;
  sportsProductsLoading: boolean;
  sportsProducts: { id: string; nombre: string }[];
  users: object[];
  getUsers: () => any;
  getPricesToSportsProducts: (id: string, tipoPrecio: string) => any;
  prices: object[];
  saveSPQuote: (data: object) => any;
  loadSPQuote: (data: object, list: object[]) => any;
  getQuoteByPersonId: (personId: number, params: object) => any;
  clearSelectedPerson: () => any;
  getCashboxPersonId: (personId: number) => any;
  getpersonByID: (personId: string) => any;
  deleteProducts: (productId: string) => any;
  personByID: object;
  setSelectedPerson: (person: object) => any;
  groupUser: string[];
  deletingProduct: boolean;
  deletingProductError: object;
  terminal: object;
  addDataLogueo: () => any;
  getPaymentTypes: () => any;
  tiposPrecio: tipoPrecio[];
}

interface tipoPrecio {
  esquemaPagoId: string;
  nombre: string;
}

class SportsProductsNewQuotePage extends React.Component<Props> {
  componentDidMount() {
    this.props.getUsers();
    this.props.getPaymentTypes();
    this.props.addDataLogueo();
  }

  componentWillUnmount() {
    this.props.clearSelectedPerson();
  }

  render() {
    return (
      <main>
        <Helmet title="Sports Products New Quote" />
        <Layout>
          <SportsProductsNewQuote
            getpersonByID={this.props.getpersonByID}
            personByID={this.props.personByID}
            searchSportsProducts={this.props.searchSportsProducts}
            sportsProductsLoading={this.props.sportsProductsLoading}
            sportsProducts={this.props.sportsProducts}
            users={this.props.users}
            sportsProductsTypes={this.props.sportsProductsTypes}
            getPricesToSportsProducts={this.props.getPricesToSportsProducts}
            prices={this.props.prices}
            saveSPQuote={this.props.saveSPQuote}
            loadSPQuote={this.props.loadSPQuote}
            getQuoteByPersonId={this.props.getQuoteByPersonId}
            getCashboxPersonId={this.props.getCashboxPersonId}
            setSelectedPerson={this.props.setSelectedPerson}
            fitKidz={allowedTo(CARRITO_FITKIDZ)}
            groupUser={this.props.groupUser}
            deleteProducts={this.props.deleteProducts}
            deletingProduct={this.props.deletingProduct}
            deletingProductError={this.props.deletingProductError}
            tiposPrecio={this.props.tiposPrecio}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  searchSportsProducts: (filters: object) => {
    dispatch(catalogActions.getSportsProductsFiltered(filters));
  },
  getSportsProductsTypes: () => {
    dispatch(catalogActions.getSportsProductsTypes());
  },
  getUsers: () => {
    dispatch(getSellers());
  },
  getpersonByID: (id: string) => {
    return dispatch(getPersonById(id));
  },
  getPricesToSportsProducts: (id: string, tipoPrecio: string) => {
    return dispatch(
      getPricesToSportsProductsByDate({
        id: id,
        tipoPagoId: tipoPrecio,
        estatus: STATUS_PRICE_ACTIVE,
        fechaVigencia: moment().format("YYYY-MM-DD")
      })
    );
  },
  saveSPQuote: (data: object) => {
    return dispatch(saveSPQuote(data));
  },
  loadSPQuote: (data: object, list: object[]) => {
    return dispatch(loadSPQuote(data, list));
  },
  getQuoteByPersonId: (personId: number, params: object) => {
    return dispatch(getQuoteByPersonId(personId, params));
  },
  clearSelectedPerson: () => {
    return dispatch(clearSelectedPerson());
  },
  getCashboxPersonId: (personId: number) => {
    return dispatch(getCashboxPersonId(personId));
  },
  setSelectedPerson: (person: object) => {
    dispatch(setSelectedPerson(person));
  },
  deleteProducts: (productId: string) => {
    dispatch(deleteProducts(productId));
  },
  addDataLogueo: () => {
    dispatch(addDataLogueo());
  },
  getPaymentTypes: () => {
    dispatch(catalogActions.getPaymentTypes());
  }
});

const mapStateToProps = state => {
  const { catalogs, sportsProducts, persons, user, terminal } = state;
  return {
    personByID: persons.personById,
    sportsProducts: catalogs.sportsProducts,
    sportsProductsLoading: catalogs.gettingSportsProducts,
    sportsProductsTypes: catalogs.sportsProductsTypes,
    tiposPrecio: catalogs.tiposPrecio,
    users: catalogs.sellers,
    prices: sportsProducts.pricesToProduct,
    groupUser: user && user.userContext && user.userContext.groups,
    deletingProduct: sportsProducts && sportsProducts.deletingProduct,
    deletingProductError: sportsProducts && sportsProducts.deletingProductError,
    terminal
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SportsProductsNewQuotePage);
