export interface DATA {
  tiposPago: {
    origenPago: string;
    formaPago: string;
  }[];
  origenPago: number;
  formaPago: number;
  cantidad: number;
  nroItems: number;
}
export const TABLE_REPORT = [
  {
    Header: "#",
    accessor: "numero",
    width: 50
  },
  {
    Header: "origenPago",
    accessor: "origenPago"
  },
  {
    Header: "formaPago",
    accessor: "formaPago"
  }
];

export const ORIGEN_PAGO = [
  {
    value: -1,
    label: "Seleccionar forma de pago"
  },
  {
    value: "EFECTIVO",
    label: "EFECTIVO"
  },
  {
    value: "CHEQUE",
    label: "CHEQUE"
  },
  {
    value: "CONEKTA_VENTA_LINEA",
    label: "CONEKTA_VENTA_LINEA"
  },
  {
    value: "CARGO_DIRECTO",
    label: "CARGO_DIRECTO"
  },
  {
    value: "DOMICILIADO",
    label: "DOMICILIADO"
  },
  {
    value: "CARGOS_AUTOMATICOS_VSMC",
    label: "CARGOS_AUTOMATICOS_VSMC"
  },
  {
    value: "CARGOS_AUTOMATICOS_AMEX",
    label: "CARGOS_AUTOMATICOS_AMEX"
  },
  {
    value: "CONEKTA_APP",
    label: "CONEKTA_APP"
  },
  {
    value: "CARGO_DIRECTO_AMEX",
    label: "CARGO_DIRECTO_AMEX"
  }
];

export const FORMA_PAGO = [
  {
    value: -1,
    label: "Seleccionar forma de pago"
  },
  {
    value: "EFECTIVO",
    label: "EFECTIVO"
  },
  {
    value: "CHEQUE",
    label: "CHEQUE"
  },
  {
    value: "TDC",
    label: "TDC"
  },
  {
    value: "TDD",
    label: "TDD"
  },
  {
    value: "CONEKTA",
    label: "CONEKTA"
  }
];
