import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  FormFeedback,
  Input
} from "reactstrap";
import axios from "axios";
import isNil from "lodash.isnil";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import constants from "../../../utils/constants";
const DISCOUNT_BASE_URL = "/api/discountCode";

interface Props {
  onValidateDiscountCode: (codeInfo: CODE_VALIDITY) => any;
  disabled?: boolean;
  tipoCodigo: string;
  productoId: number;
  reset: boolean;
  clubId: String;
}

export interface CODE_VALIDITY {
  id: number;
  tipoCodigo: string;
  estatus: string;
  tipoDescuento: string;
  descuento: number;
  codigoDescuento: string;
  vigente: boolean;
  clasificacionProductoId: number;
}

interface State {
  codigoDescuento: string;
  isValid: boolean;
}

class CouponValidator extends React.Component<Props, State> {
  state = {
    codigoDescuento: "",
    isValid: null
  };

  onChangeCoupon = (value): void => this.setState({ codigoDescuento: value });

  processResponse = (infoCodigo: CODE_VALIDITY): void => {
    const { vigente } = infoCodigo;
    this.setState({
      ...this.state,
      isValid: vigente
    });
    this.props.onValidateDiscountCode(infoCodigo);
  };

  validateDiscountCode = async (): Promise<void> => {
    const state = { ...this.state };
    const { codigoDescuento } = state;
    const { productoId, clubId } = this.props;

    axios
      .get(`${constants.BASE_URL}${DISCOUNT_BASE_URL}/validate`, {
        withCredentials: true,
        params: { codigoDescuento, productoId: productoId || -1, clubId }
      })
      .then(({ data }) => {
        this.processResponse(data);
      })
      .catch(() => {
        this.setState({ isValid: false });
      });
  };

  componentWillReceiveProps(nextProps: Props) {
    if (this.props.reset != nextProps.reset) {
      this.reset();
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    let shouldUpdate = false;

    if (
      this.props.reset != nextProps.reset ||
      this.props.productoId != nextProps.productoId ||
      this.state.codigoDescuento != nextState.codigoDescuento ||
      this.state.isValid != nextState.isValid
    ) {
      shouldUpdate = true;
    }

    return shouldUpdate;
  }

  reset = async () => {
    await this.setState({
      codigoDescuento: "",
      isValid: null
    });
  };

  render() {
    return (
      <Container className="w-100 p-0 my-0">
        <Row className="m-0">
          <Col className="pl-0">
            <FormGroup className="mb-1">
              <Label for="idCupon">Cupón de descuento</Label>
              <Input
                id="idCupon"
                placeholder="Ingresa el código de descuento"
                onChange={e => this.onChangeCoupon(e.target.value)}
                disabled={!!this.props.disabled || !this.props.productoId}
                value={this.state.codigoDescuento}
                valid={this.state.isValid}
                invalid={!isNil(this.state.isValid) && !this.state.isValid}
                className={s.input}
              />
              <FormFeedback valid>Código válido</FormFeedback>
              <FormFeedback invalid>Código inválido</FormFeedback>
            </FormGroup>
          </Col>
          <Col className="p-0" xs="2">
            <Button
              className={`${s.primaryButton} mt-4`}
              onClick={() => {
                this.validateDiscountCode();
              }}
              disabled={
                isNil(this.state.codigoDescuento) || !this.props.productoId
              }
              block
              size="sm"
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(CouponValidator));
