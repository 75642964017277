import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import {
  infoNotification,
  GET_PERSON_PENDING,
  errorNotification,
  successNotification,
  GET_PERSON_ERROR,
  CREATE_PERSON_PENDING,
  CREATE_PERSON_ERROR,
  CREATE_PERSON,
  PICTURE_UPLOADED_SUCCESSFULLY,
  DEFAULT_MESSAGE,
  LOADING_PICTURE,
  LOADING_RESPONSABLE,
  RESPONSABLE_SAVED_SUCCESSFULLY,
  ERROR_PERSON_FINDED,
  MESSAGE_DEALERS_PENDING,
  MESSAGE_DEALERS_ERROR,
  MESSAGE_DEALERS_SUCCESS,
  ADD_DEALERS_PENDING,
  ADD_DEALERS_REJECTED,
  ADD_DEALERS_FULFILLED,
  GET_CLUB_DEALER_FULFILLED,
  GET_CLUB_DEALER_REJECTED,
  GET_CLUB_DEALER_PENDING,
  UPDATE_CLUB_DEALER_FULFILLED,
  UPDATE_CLUB_DEALER_REJECTED,
  UPDATE_CLUB_DEALER_PENDING,
  VARIABLES_CHARGE_DEALERS_FULFILLED,
  VARIABLES_CHARGE_DEALERS_PENDING,
  VARIABLES_CHARGE_DEALERS_REJECTED,
  GENERATE_VARIABLE_FULFILLED,
  GENERATE_VARIABLE_PENDING,
  GENERATE_VARIABLE_REJECTED,
  ERROR
} from "../../utils/notifications";
interface State {
  persons: object[];
  personById: object;
  personCreated: object;
  selectedPerson: object;
  paymentMovements: object[];
  personPicture: object;
  responsables: object[];
  loadingListDealers: boolean;
  listDealers: object[];
  loadingListClubsDealer: boolean;
  listClubsDealer: object[];
  loadingUpdateClubsDealer: boolean;
  updateClubsDealer: object[];
  loadingAddDealers: boolean;
  objAddDealers: object[];
  variablesCharge: object[];
  loadingVariablesCharge: boolean;
  dataUpdate: object[];
  dataAddDCC: object[];
}
const initialState: State = {
  persons: null,
  personById: null,
  personCreated: null,
  selectedPerson: null,
  paymentMovements: null,
  personPicture: null,
  responsables: null,
  loadingListDealers: false,
  listDealers: null,
  loadingListClubsDealer: false,
  listClubsDealer: null,
  loadingUpdateClubsDealer: false,
  updateClubsDealer: null,
  loadingAddDealers: false,
  objAddDealers: null,
  variablesCharge: null,
  loadingVariablesCharge: false,
  dataUpdate: null,
  dataAddDCC: null
};
import { readMessageBack } from "../../utils/readMessageBack";

export default function personsReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.UPDATE_RESPONSABLE}_PENDING`: {
      infoNotification(LOADING_RESPONSABLE);
      return {
        ...state
      };
    }
    case `${actionTypes.UPDATE_RESPONSABLE}_REJECTED`: {
      errorNotification(readMessageBack(action, DEFAULT_MESSAGE));
      return {
        ...state
      };
    }
    case `${actionTypes.UPDATE_RESPONSABLE}_FULFILLED`: {
      successNotification(RESPONSABLE_SAVED_SUCCESSFULLY);
      return {
        ...state
      };
    }
    case `${actionTypes.SAVE_RESPONSABLE}_PENDING`: {
      infoNotification(LOADING_RESPONSABLE);
      return {
        ...state
      };
    }
    case `${actionTypes.SAVE_RESPONSABLE}_REJECTED`: {
      errorNotification(readMessageBack(action, DEFAULT_MESSAGE));
      return {
        ...state
      };
    }
    case `${actionTypes.SAVE_RESPONSABLE}_FULFILLED`: {
      successNotification(RESPONSABLE_SAVED_SUCCESSFULLY);
      return {
        ...state
      };
    }
    case `${actionTypes.GET_RESPONSABLES}_PENDING`: {
      return {
        ...state,
        responsables: null
      };
    }
    case `${actionTypes.GET_RESPONSABLES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        responsables: null
      };
    }
    case `${actionTypes.GET_RESPONSABLES}_FULFILLED`: {
      return {
        ...state,
        responsables: action.payload.data
      };
    }
    case `${actionTypes.PERSON_UPDATE_PHOTO}_PENDING`: {
      infoNotification(LOADING_PICTURE);
      return {
        ...state,
        personPicture: null
      };
    }
    case `${actionTypes.PERSON_UPDATE_PHOTO}_REJECTED`: {
      errorNotification(readMessageBack(action, DEFAULT_MESSAGE));
      return {
        ...state,
        personPicture: null
      };
    }
    case `${actionTypes.PERSON_UPDATE_PHOTO}_FULFILLED`: {
      successNotification(PICTURE_UPLOADED_SUCCESSFULLY);
      return {
        ...state,
        personPicture: action.payload.data
      };
    }
    case `${actionTypes.GET_PAYMENT_MOVEMENTS}_PENDING`: {
      return {
        ...state,
        paymentMovements: null
      };
    }
    case `${actionTypes.GET_PAYMENT_MOVEMENTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        paymentMovements: null
      };
    }
    case `${actionTypes.GET_PAYMENT_MOVEMENTS}_FULFILLED`: {
      return {
        ...state,
        paymentMovements: action.payload.data
      };
    }
    case `${actionTypes.GET_PERSONS}_PENDING`: {
      return {
        ...state,
        persons: null
      };
    }
    case `${actionTypes.GET_PERSONS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR_PERSON_FINDED));
      return {
        ...state,
        persons: null
      };
    }
    case `${actionTypes.GET_PERSONS}_FULFILLED`: {
      return {
        ...state,
        persons: action.payload.data
      };
    }
    case `${actionTypes.GET_PERSON_BY_ID}_PENDING`: {
      infoNotification(GET_PERSON_PENDING);
      return {
        ...state,
        personById: null
      };
    }
    case `${actionTypes.GET_PERSON_BY_ID}_REJECTED`: {
      errorNotification(readMessageBack(action, GET_PERSON_ERROR));
      return {
        ...state,
        personById: null
      };
    }
    case `${actionTypes.GET_PERSON_BY_ID}_FULFILLED`: {
      return {
        ...state,
        personById: action.payload.data
      };
    }
    case `${actionTypes.CREATE_PERSON}_PENDING`: {
      infoNotification(CREATE_PERSON_PENDING);
      return {
        ...state,
        personCreated: null
      };
    }
    case `${actionTypes.CREATE_PERSON}_REJECTED`: {
      errorNotification(readMessageBack(action, CREATE_PERSON_ERROR));
      return {
        ...state,
        personCreated: null
      };
    }
    case `${actionTypes.CREATE_PERSON}_FULFILLED`: {
      let personCreated = null;
      const { alreadyExistingPersonas, personas } = action.payload.data;
      if (alreadyExistingPersonas) {
        errorNotification(
          "Error creando la persona. Informacion ya existente."
        );
      } else {
        successNotification(CREATE_PERSON);
        const body = JSON.parse(action.payload.config.data);
        personCreated = {
          ...body,
          ...personas[0]
        };
      }

      return {
        ...state,
        personCreated,
        selectedPerson: null
      };
    }
    case `${actionTypes.SET_SELECTED_PERSON}`: {
      return {
        ...state,
        selectedPerson: action.payload,
        personCreated: null
      };
    }
    case `${actionTypes.CLEAR_SELECTED_PERSON}`: {
      return {
        ...state,
        selectedPerson: null,
        personCreated: null,
        personById: null
      };
    }

    case `${actionTypes.GET_DEALERS}_PENDING`: {
      infoNotification(MESSAGE_DEALERS_PENDING);
      return {
        ...state,
        loadingListDealers: false,
        listDealers: null
      };
    }
    case `${actionTypes.GET_DEALERS}_REJECTED`: {
      errorNotification(readMessageBack(action, MESSAGE_DEALERS_ERROR));
      return {
        ...state,
        loadingListDealers: true,
        listDealers: null
      };
    }
    case `${actionTypes.GET_DEALERS}_FULFILLED`: {
      successNotification(MESSAGE_DEALERS_SUCCESS);
      return {
        ...state,
        loadingListDealers: false,
        listDealers: action.payload ? action.payload.data : []
      };
    }

    case `${actionTypes.GET_CLUB_DEALER}_PENDING`: {
      infoNotification(GET_CLUB_DEALER_PENDING);
      return {
        ...state,
        loadingListClubsDealer: false,
        listClubsDealer: null
      };
    }
    case `${actionTypes.GET_CLUB_DEALER}_REJECTED`: {
      errorNotification(readMessageBack(action, GET_CLUB_DEALER_REJECTED));
      return {
        ...state,
        loadingListClubsDealer: true,
        listClubsDealer: null
      };
    }
    case `${actionTypes.GET_CLUB_DEALER}_FULFILLED`: {
      successNotification(GET_CLUB_DEALER_FULFILLED);
      return {
        ...state,
        loadingListClubsDealer: false,
        listClubsDealer: action.payload ? action.payload.data : []
      };
    }

    case `${actionTypes.UPDATE_CLUB_DEALER}_PENDING`: {
      infoNotification(UPDATE_CLUB_DEALER_PENDING);
      return {
        ...state,
        loadingUpdateClubsDealer: false,
        updateClubsDealer: null
      };
    }
    case `${actionTypes.UPDATE_CLUB_DEALER}_REJECTED`: {
      errorNotification(readMessageBack(action, UPDATE_CLUB_DEALER_REJECTED));
      return {
        ...state,
        loadingUpdateClubsDealer: false,
        updateClubsDealer: null
      };
    }
    case `${actionTypes.UPDATE_CLUB_DEALER}_FULFILLED`: {
      successNotification(UPDATE_CLUB_DEALER_FULFILLED);
      return {
        ...state,
        loadingUpdateClubsDealer: true,
        updateClubsDealer: action.payload ? action.payload.data : []
      };
    }

    case `${actionTypes.ADD_DEALERS}_PENDING`: {
      infoNotification(ADD_DEALERS_PENDING);
      return {
        ...state,
        loadingAddDealers: false,
        objAddDealers: null
      };
    }
    case `${actionTypes.ADD_DEALERS}_REJECTED`: {
      errorNotification(readMessageBack(action, ADD_DEALERS_REJECTED));
      return {
        ...state,
        loadingAddDealers: false,
        objAddDealers: null
      };
    }
    case `${actionTypes.ADD_DEALERS}_FULFILLED`: {
      successNotification(ADD_DEALERS_FULFILLED);
      return {
        ...state,
        loadingAddDealers: true,
        objAddDealers: action.payload
      };
    }

    case `${actionTypes.GET_VARIABLES_CHARGE_DEALERS}_PENDING`: {
      infoNotification(VARIABLES_CHARGE_DEALERS_PENDING);
      return {
        ...state,
        variablesCharge: null
      };
    }
    case `${actionTypes.GET_VARIABLES_CHARGE_DEALERS}_REJECTED`: {
      errorNotification(
        readMessageBack(action, VARIABLES_CHARGE_DEALERS_REJECTED)
      );
      return {
        ...state,
        variablesCharge: null
      };
    }
    case `${actionTypes.GET_VARIABLES_CHARGE_DEALERS}_FULFILLED`: {
      successNotification(VARIABLES_CHARGE_DEALERS_FULFILLED);
      return {
        ...state,
        variablesCharge: action.payload.data
      };
    }

    case `${actionTypes.GENERATE_VARIABLE_CHARGE}_PENDING`: {
      infoNotification(GENERATE_VARIABLE_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.GENERATE_VARIABLE_CHARGE}_REJECTED`: {
      errorNotification(readMessageBack(action, GENERATE_VARIABLE_REJECTED));
      return {
        ...state
      };
    }
    case `${actionTypes.GENERATE_VARIABLE_CHARGE}_FULFILLED`: {
      successNotification(GENERATE_VARIABLE_FULFILLED);
      return {
        ...state
      };
    }

    case `${actionTypes.ADD_DEALER_CONFIG_CHARGE}_PENDING`: {
      infoNotification(GENERATE_VARIABLE_PENDING);
      return {
        ...state,
        dataAddDCC: null
      };
    }
    case `${actionTypes.ADD_DEALER_CONFIG_CHARGE}_REJECTED`: {
      errorNotification(readMessageBack(action, GENERATE_VARIABLE_REJECTED));
      return {
        ...state,
        dataAddDCC: null
      };
    }
    case `${actionTypes.ADD_DEALER_CONFIG_CHARGE}_FULFILLED`: {
      successNotification(GENERATE_VARIABLE_FULFILLED);
      return {
        ...state,
        dataAddDCC: action.payload
      };
    }

    case `${actionTypes.UPDATE_DEALER_CONFIG_CHARGE}_PENDING`: {
      infoNotification(GENERATE_VARIABLE_PENDING);
      return {
        ...state,
        dataUpdate: null
      };
    }
    case `${actionTypes.UPDATE_DEALER_CONFIG_CHARGE}_REJECTED`: {
      errorNotification(readMessageBack(action, GENERATE_VARIABLE_REJECTED));
      return {
        ...state,
        dataUpdate: null
      };
    }
    case `${actionTypes.UPDATE_DEALER_CONFIG_CHARGE}_FULFILLED`: {
      successNotification(GENERATE_VARIABLE_FULFILLED);
      return {
        ...state,
        dataUpdate: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export { initialState };
