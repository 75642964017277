import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Col, Button, Row, Label, Form } from "reactstrap";
import DatePicker from "../common/DatePicker";
import PagedTable from "../common/PagedTable";
import BaseInput from "../../../toolkit/baseInput";
import { Typeahead } from "react-bootstrap-typeahead";
import ModalFrame from "../../../toolkit/modalFrame";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import moment from "moment";
import { QueryResponse } from "../../../types/QueryResponse";
import {
  ProspectReportArguments,
  PROSPECT_REPORT_STATUS
} from "../../../types/Prospects";
import {
  getProspectReportRequest,
  saveReassignmentRequest,
  executeProspectReportDownload
} from "../../actions/prospects";
import { getErrorMessage } from "../../../utils/helpers";
import { getAllClubsBasicDataRequest } from "../../actions/clubs";
import { getUsersRequest } from "../../actions/profile";
import { deletePersonRequest } from "../../actions/persons";
import {
  infoNotification,
  errorNotification,
  successNotification
} from "../../../utils/notifications";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import permissionCodes from "../../../utils/permissionCodes";
import { userHasPermission } from "../../../utils/accessTree";
import { catalogoMonth, getYearCatalog } from "./interfaces";
import BasicSpinner from "../../../toolkit/basicSpinner";
import BaseCsvIconDownloader from "../../../toolkit/baseCsvIconDownloader";

interface Props {
  history: any;
  match: any;
  userContext: any;
}

interface State {
  prospects: QueryResponse<object>;
  clubs: any[];
  users: any[];
  filters: ProspectReportArguments;
  searching: boolean;
  modalChangeOpen: boolean;
  editedProspect: string;
  newAssignedToId: string;
  refresh: boolean;
  descargando: boolean;
}

class ProspectsReport extends React.Component<Props, State> {
  state = {
    prospects: null,
    clubs: [],
    users: [],
    searching: false,
    filters: {
      clubId: "",
      vendedorId: "",
      estatus: "",
      fechaDesde: "",
      fechaHasta: "",
      mes: moment().format("M"),
      anio: moment().format("YYYY"),
      page: "0",
      size: "100"
    },
    modalChangeOpen: false,
    editedProspect: "",
    newAssignedToId: "",
    refresh: false,
    descargando: false
  };

  toggleModal = () => {
    this.setState({
      modalChangeOpen: !this.state.modalChangeOpen,
      newAssignedToId: ""
    });
  };

  closeChangeAssignedToModal = () => {
    this.setState({
      modalChangeOpen: false,
      newAssignedToId: "",
      editedProspect: ""
    });
  };

  executeChangeAssignedTo = () => {
    const { editedProspect, newAssignedToId } = this.state;
    const tmp = [];
    tmp.push(editedProspect);

    infoNotification("Actualizando la asignación.");
    saveReassignmentRequest("seller", newAssignedToId, tmp)
      .then(() => {
        successNotification("La asignación se actualizó correctamente");
        this.closeChangeAssignedToModal();
      })
      .catch(err => {
        errorNotification(
          getErrorMessage(err, "Ocurrió un error al actualizar la asignación.")
            .message
        );
      });
  };

  handleViewDetail = prospect => {
    window.open(`/prospecto/${prospect.personaId}/detalle`, "_blank");
  };

  handleChangeAssignedTo = prospect => {
    const { personaId: editedProspect } = prospect;
    this.setState({ editedProspect });
    this.toggleModal();
  };

  handleDeleteProspect = prospect => {
    const { personaId } = prospect;
    if (!!personaId) {
      deletePersonRequest(personaId).then(() => {
        successNotification("Prospecto cancelado correctamente...");
        const { prospects, refresh } = this.state;
        const idx = (prospects.content || []).findIndex(
          x => x.personaId == personaId
        );
        if (idx >= 0) {
          prospects.content[idx].estatus = PROSPECT_REPORT_STATUS.CANCELADO;
        }
        this.setState({ prospects: { content: [] }, refresh: !refresh });
        this.setState({ prospects: { ...prospects }, refresh: !refresh });
      });
    }
  };

  onFetchData = ({ pageSize: size, page }) => {
    infoNotification("Consultado reporte de prospectos...");
    const flds = { ...this.state.filters, size: size || 100, page: page || 0 };
    this.setState({ filters: flds });
    this.getData(page || 0, size || 100);
  };

  getData = (page, size) => {
    this.setState({ searching: true });

    const { mes, anio } = this.state.filters;

    if (!mes || !anio) {
      errorNotification("Debe seleccionar el mes y año de consulta");
      return;
    }

    const myMomentObject = moment(`${anio}-${mes}-01`, "YYYY-MM-DD");

    const requestFilters = {
      ...this.state.filters,
      fechaDesde: myMomentObject.startOf("month").format("YYYY-MM-DD"),
      fechaHasta: myMomentObject.endOf("month").format("YYYY-MM-DD"),
      size,
      page
    };

    getProspectReportRequest(requestFilters)
      .then(({ data }) => {
        this.setState({ searching: false, prospects: data });
      })
      .catch(err => {
        errorNotification(
          getErrorMessage(
            err,
            "Ocurrió un error al consultar el reporte de prospectos."
          ).message
        );
        this.setState({ searching: false });
      });
  };

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const filters = {
          ...state.filters,
          [fieldName]: date
        };
        return { ...state, error: {}, filters };
      });
    };
  };

  onChangeFilterFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  onChangeSeller = selected => {
    let vendedorId = selected.length > 0 ? selected[0]["id"] : "";
    const flds = { ...this.state.filters, vendedorId };
    this.setState({ filters: flds });
  };

  onChangeNewSeller = selected => {
    let newAssignedToId = selected.length > 0 ? selected[0]["id"] : "";
    this.setState({ newAssignedToId });
  };

  componentDidMount() {
    getAllClubsBasicDataRequest().then(({ data: clubs }) => {
      this.setState({
        clubs,
        filters: {
          ...this.state.filters,
          clubId:
            (this.props.userContext && this.props.userContext.clubSessionId) ||
            ""
        }
      });
    });
    getUsersRequest().then(({ data }) => {
      this.setState({ users: data });
    });

    /* const initialFilters = {
      mes: moment().format('M'),
      anio: moment().format('YYYY')
    };
    this.setState({ filters: { ...this.state.filters, ...initialFilters } })

    console.log(initialFilters);
 */
  }

  getTableData = () => {
    return this.state.prospects || {};
  };

  render() {
    const columns = [
      { name: "Nombre", id: "personaNombre" },
      { name: "Correo", id: "correo" },
      { name: "Telefono", id: "telefono" },
      { name: "Fecha registro", id: "fecharegistro" },
      { name: "Vendedor", id: "vendedorNombre" },
      { name: "Estatus", id: "estatus" },
      { name: "Club", id: "clubNombre" },
      { name: "Origen", id: "medioProspeccion" },
      {
        headerActions: []
      }
    ];

    const actions: any = [];

    if (
      userHasPermission(
        permissionCodes.PROSPECT_BASIC_DATA_EDITION_PERMISSION
      ) ||
      userHasPermission(permissionCodes.PROSPECT_DETAIL_EDITION_PERMISSION)
    ) {
      actions.push({
        name: "edit",
        f: this.handleViewDetail,
        visible: item => item.tipo === "PROSPECTO" && item.estatus == "ACTIVO"
      });
    }
    if (userHasPermission(permissionCodes.PROSPECT_DELETE_PERMISSION)) {
      actions.push({
        name: "delete",
        f: this.handleDeleteProspect,
        visible: item => item.tipo === "PROSPECTO" && item.estatus == "ACTIVO",
        label: "Cancelar"
      });
    }
    if (userHasPermission(permissionCodes.PROSPECT_REASIGN_PERMISSION)) {
      actions.push({
        name: "assign",
        f: this.handleChangeAssignedTo,
        visible: item => item.tipo === "PROSPECTO" && item.estatus == "ACTIVO",
        label: "Reasignar"
      });
    }

    return (
      <Container className="p-0 mt-3">
        <ModalFrame
          title={"Confirmar cambio de asignación"}
          isOpen={this.state.modalChangeOpen}
          toggle={this.toggleModal}
        >
          <Container>
            <p>
              Seleccione al nuevo empleado para realizar el cambio de
              asignación:
            </p>
            <br />
            <Row>
              <Col>
                <Typeahead
                  id={"newSellerSelector"}
                  promptText={"Vendedor"}
                  bsSize={"sm"}
                  onChange={selected => this.onChangeNewSeller(selected)}
                  emptyLabel={"Sin coincidencias"}
                  disabled={!(this.state.users || []).length}
                  maxResults={20}
                  options={(this.state.users || []).map(item => {
                    return {
                      id: item.userId,
                      label: `${item.name} ${item.firstSurname} ${item.secondSurname}`
                    };
                  })}
                />
              </Col>
            </Row>
            <br />
            <br />
            <br />
            <Row>
              <Col className="text-right">
                <Button
                  color="secondary"
                  onClick={this.closeChangeAssignedToModal}
                  style={{ marginRight: "15px" }}
                >
                  Cancelar
                </Button>
                {this.state.editedProspect && this.state.newAssignedToId && (
                  <Button
                    onClick={this.executeChangeAssignedTo}
                    color="primary"
                  >
                    Cambiar
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        </ModalFrame>

        <Row>
          <Col>
            <BaseInput
              label="Club"
              name="clubSelector"
              id="clubSelector"
              type="select"
              placeholder="Seleccionar club"
              value={this.state.filters.clubId}
              onChange={this.onChangeFilterFactory("clubId")}
              disabled={
                this.props.userContext && this.props.userContext.clubSessionId
              }
              size={"sm"}
              options={[{ value: "", label: "Seleccione un club" }].concat(
                (this.state.clubs || []).map(option => ({
                  value: option.clubId,
                  label: option.nombre
                }))
              )}
            />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <Label className={s.label}>Vendedor</Label>
            <Typeahead
              id={"sellerSelector"}
              promptText={"Vendedor"}
              bsSize={"sm"}
              onChange={selected => this.onChangeSeller(selected)}
              emptyLabel={"Sin coincidencias"}
              disabled={!(this.state.users || []).length}
              maxResults={20}
              options={(this.state.users || []).map(item => {
                return {
                  id: item.userId,
                  label: `${item.name} ${item.firstSurname} ${item.secondSurname}`
                };
              })}
            />
          </Col>
          <Col>
            <BaseInput
              label="Estatus"
              name="estatusSelector"
              id="estatusSelector"
              type="select"
              placeholder="Seleccionar estatus"
              value={this.state.filters.estatus}
              onChange={this.onChangeFilterFactory("estatus")}
              size={"sm"}
              options={[
                { value: "", label: "Seleccione un estatus" },
                {
                  value: PROSPECT_REPORT_STATUS.ACTIVO,
                  label: PROSPECT_REPORT_STATUS.ACTIVO
                },
                {
                  value: PROSPECT_REPORT_STATUS.VENDIDO,
                  label: PROSPECT_REPORT_STATUS.VENDIDO
                },
                {
                  value: PROSPECT_REPORT_STATUS.CANCELADO,
                  label: PROSPECT_REPORT_STATUS.CANCELADO
                }
              ]}
            />
          </Col>
        </Row>
        <Form
          onSubmit={e => {
            e.preventDefault();
            this.getData(0, 100);
          }}
        >
          <Row>
            <Col xs={12} md={4} lg={4}>
              <BaseInput
                label="Mes"
                name="reportMonth"
                id="reportMonth"
                type="select"
                options={catalogoMonth}
                placeholder="Mes"
                value={this.state.filters.mes}
                onChange={this.onChangeFilterFactory("mes")}
                errors={[]}
              />
            </Col>
            <Col xs={12} md={4} lg={4}>
              <BaseInput
                label="Año"
                name="reportYear"
                id="reportYear"
                type="select"
                options={getYearCatalog()}
                placeholder="Año"
                value={this.state.filters.anio}
                onChange={this.onChangeFilterFactory("anio")}
                errors={[]}
              />
            </Col>

            <Col>
              &nbsp; &nbsp; &nbsp; &nbsp;
              <br />
              <Button
                size="sm"
                className={`float-right btn-block`}
                type="submit"
                disabled={!this.state.filters.mes || !this.state.filters.anio}
              >
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>

        <BaseCsvIconDownloader
          rowCount={(this.state.prospects || {}).totalElements || 0}
          executeReportDownload={executeProspectReportDownload}
          getFilters={() => {
            const { mes, anio } = this.state.filters;

            const myMomentObject = moment(`${anio}-${mes}-01`, "YYYY-MM-DD");

            return {
              ...this.state.filters,
              fechaDesde: myMomentObject.startOf("month").format("YYYY-MM-DD"),
              fechaHasta: myMomentObject.endOf("month").format("YYYY-MM-DD")
            };
          }}
        />

        <PagedTable
          data={this.getTableData()}
          columns={columns}
          actions={actions}
          fetchData={this.onFetchData}
          searching={this.state.searching}
        />
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(ProspectsReport));
