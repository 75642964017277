import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import BaseInput from "../../../toolkit/baseInput";
import PeriodSchemaComissionSelector from "./periodSchemaComissionSelector";
import ClubCatalogSelect from "../sportsProductsNewQuote/ClubCatalogSelect";
import { Button } from "reactstrap";
import { errorNotification } from "../../../utils/notifications";

interface PERIOD {
  id: number;
  nombre: string;
  fechaInicio: string;
  fechaFin: string;
}

interface Props {
  periods: PERIOD[];
  schemas: {
    esquemaId: number;
  }[];
  getSchemas: () => any;
  match: any;
  history: any;
  clubs: { clubId: number; nombre: string }[];
  saveSchemasByPeriod: (schemasByClub: object) => any;
  getPeriodById: (id: string) => any;
  periodById: {
    periodoId: number;
    esquemasAplicables: {
      esquemaId: number;
      clubesAplicables: number[];
    }[];
  };
}

interface State {
  periodId: string;
  comissionSchemaType: string;
  selectedSchemas: object;
  clubsSelectedBySchema: object;
  clickedSchema: string;
  loaded: boolean;
}

const ComissionSchemaTypes = [
  { value: "VENTA", label: "Venta" },
  { value: "REACTIVACION", label: "Reactivación" }
];

class PeriodsConfiguration extends React.Component<Props, State> {
  state = {
    periodId: "-1",
    comissionSchemaType: "",
    selectedSchemas: [],
    clubsSelectedBySchema: {},
    clickedSchema: null,
    loaded: false
  };
  selectedSchemas = [];

  componentDidMount() {
    const { periodId } = this.props.match.params;
    if (periodId) {
      this.setState({ periodId });
      this.props.getPeriodById(periodId);
    }
  }

  loadPeriod = () => {
    if (this.props.clubs && this.props.schemas && this.props.periodById) {
      const schemasHash = {};
      this.props.schemas.forEach(schema => {
        schemasHash[schema.esquemaId] = schema;
      });

      const clubHash = {};
      this.props.clubs.forEach(club => {
        clubHash[club.clubId] = club;
      });

      const selectedSchemas = this.props.periodById.esquemasAplicables.map(
        ({ esquemaId }) => {
          return {
            esquemaId,
            nombre: schemasHash[esquemaId].nombre
          };
        }
      );

      const clubsSelectedBySchema = this.props.periodById.esquemasAplicables.reduce(
        (accum, esquemaAplicable) => {
          accum[
            esquemaAplicable.esquemaId
          ] = esquemaAplicable.clubesAplicables.map(clubId => ({
            value: clubId,
            label: clubHash[clubId].nombre
          }));
          return accum;
        },
        {}
      );

      this.setState({
        ...this.state,
        loaded: true,
        selectedSchemas,
        clubsSelectedBySchema
      });
    }
  };

  componentDidUpdate(prevProps) {
    const { periodId } = this.props.match.params;
    const { periodId: prevPeriodId } = prevProps.match.params;
    const { periodById: prevPeriodById } = prevProps;

    if (prevPeriodId !== periodId) {
      this.props.getPeriodById(periodId);
    }

    if (
      !this.state.loaded ||
      (this.state.loaded &&
        this.props.periodById &&
        this.props.periodById !== prevPeriodById)
    ) {
      this.loadPeriod();
    }
  }

  onSelectClub = clubSelected => {
    this.setState({
      clubsSelectedBySchema: {
        ...this.state.clubsSelectedBySchema,
        [this.state.clickedSchema]: clubSelected
      }
    });
  };

  onSubmit = e => {
    e.preventDefault();
    try {
      if (this.selectedSchemas.length > 0) {
        this.props.saveSchemasByPeriod({
          periodoId: this.state.periodId,
          esquemasAplicables: this.selectedSchemas.map(({ id }) => ({
            esquemaId: id,
            clubesAplicables: this.state.clubsSelectedBySchema[id].map(
              ({ value: clubId }) => clubId
            )
          }))
        });
      } else
        errorNotification(
          "No se ha seleccionado ningún esquema , Verifica e intenta de nuevo"
        );
    } catch (error) {
      errorNotification(
        "Existen esquemas seleccionados sin clubes configurados, Verifica e intenta de nuevo"
      );
    }
  };

  resetClicked = async (source: object[]) => {
    let state = { ...this.state };
    source.forEach(element => {
      if (element["id"] == this.state.clickedSchema) {
        state.clickedSchema = null;
      }
      delete state.clubsSelectedBySchema[element["id"]];
    });
    await this.setState(state);
  };

  onClickSelected = (clickedSchema, state) => {
    let clubsSelectedBySchema = { ...this.state.clubsSelectedBySchema };

    for (let element in clubsSelectedBySchema) {
      if (clubsSelectedBySchema[element].length < 1)
        delete clubsSelectedBySchema[element];
    }

    if (state) {
      let schemaClubs = this.state.clubsSelectedBySchema[clickedSchema];
      if (!schemaClubs) {
        schemaClubs = [];
      }
      this.setState({
        clickedSchema,
        clubsSelectedBySchema: {
          ...clubsSelectedBySchema,
          [clickedSchema]: schemaClubs
        }
      });
    } else {
      this.setState({ clickedSchema: null });
    }
  };

  getSchemasFiltered = () => {
    const { comissionSchemaType } = this.state;
    const schemas = [...(this.props.schemas || [])];

    if (!!comissionSchemaType) {
      return schemas.filter((x: any) => x.tipoEsquema == comissionSchemaType);
    }

    return schemas;
  };

  render() {
    return (
      <div className="container p-0 mt-3">
        <div className="row">
          <div className="col-xs-12 col-sm-8">
            <BaseInput
              label="Periodo"
              name="periodSelector"
              type="select"
              id="periodSelector"
              placeholder="Periodo"
              value={this.state.periodId}
              size="sm"
              onChange={e => {
                const periodId = e.target.value;
                if (periodId !== "-1") {
                  this.props.history.push(
                    `/configuracion-periodos/${periodId}`
                  );
                }
                this.setState({
                  periodId,
                  comissionSchemaType: "",
                  selectedSchemas: [],
                  clickedSchema: null
                });
              }}
              options={[{ value: -1, label: "Seleccione un Periodo" }].concat(
                this.props.periods.map(option => ({
                  value: option.id,
                  label: option.nombre
                }))
              )}
            />
          </div>
          <div className="col-xs-12 col-sm-4">
            <BaseInput
              label="Tipo esquema comisión"
              name="comissionSchemaType"
              type="select"
              id="comissionSchemaType"
              value={this.state.comissionSchemaType}
              options={[{ value: "", label: "Seleccione un tipo" }].concat(
                ComissionSchemaTypes
              )}
              onChange={e => {
                const comissionSchemaType = e.target.value;
                this.setState({ comissionSchemaType });
              }}
              disabled={(this.state.periodId || -1) == -1}
            />
          </div>
        </div>
        {this.state.periodId !== "-1" && (
          <div>
            <div className="row m-0">
              <div className="col-8 p-0">
                <PeriodSchemaComissionSelector
                  items={this.getSchemasFiltered()}
                  selected={this.state.selectedSchemas}
                  updateSelection={({ selected }) => {
                    const selectedSchemas = selected;
                    this.selectedSchemas = selectedSchemas;
                  }}
                  onClickSelected={this.onClickSelected}
                  searchByText={this.props.getSchemas}
                  keyId="esquemaId"
                  keyName="nombre"
                  resetClicked={this.resetClicked}
                  disableSearch
                  itemFullWidth={true}
                />
              </div>
              <div className="col">
                {this.state.clickedSchema && (
                  <ClubCatalogSelect
                    club={
                      this.state.clubsSelectedBySchema[
                        this.state.clickedSchema
                      ] || []
                    }
                    onSelectClub={this.onSelectClub}
                    clubs={this.props.clubs}
                    isMulti
                    disabled={!this.state.clickedSchema}
                  />
                )}
              </div>
            </div>
            <div className="row mt-3">
              <div className="col" />
              <div className="col-2">
                <Button
                  className={`${s.primaryButton}`}
                  onClick={e => this.onSubmit(e)}
                  size="sm"
                  block
                >
                  {"Guardar"}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(withStyles(s)(PeriodsConfiguration));
