import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import SatCancelacionesPage from "../../components/satCancelaciones";

interface Props {}
interface State {}

export default class SatCancelacionesContainer extends Component<Props, State> {
  render() {
    return (
      <main>
        <Helmet title="Cancelaciones SAT" />
        <Layout>
          <SatCancelacionesPage />
        </Layout>
      </main>
    );
  }
}
