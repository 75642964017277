import React from "react";
import { connect } from "react-redux";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import SaveNextCarousel from "../../components/saveNextCarousel";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import { USERS_STEPS } from "../../../steps";
import { getUserById } from "../../actions/users";
import s from "./styles.scss";

interface Props {
  savedUser: { userId: string };
  getUserById: (userId: string) => any;
  gettingUserById: boolean;
  user: object;
  history: any;
  match: any;
  location: any;
  index: number;
  component: any;
}

interface State {}

const ComponentWithMembershipName = (
  Component,
  index,
  rest,
  user,
  personaId
) => props => (
  <div className="w-100 p-0 m-0">
    {user && personaId && (
      <p className={s.primaryTitle}>
        {user.name.toUpperCase() +
          " " +
          user.firstSurname.toUpperCase() +
          " " +
          user.secondSurname.toUpperCase() +
          (" - " +
            (user.roles && user.roles.length
              ? user.roles[0].description
              : "SIN ROL SELECCIONADO"))}
      </p>
    )}
    <Component {...rest} {...props} key={index} />
  </div>
);

class UsersCarousel extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    const { personaId } = this.props.match.params;
    if (personaId) this.props.getUserById(personaId);
  }

  componentDidUpdate() {}

  onClickNext = e => {
    e.preventDefault();
    const params = {
      ...this.props.match.params,
      personaId:
        this.props.match.params.personaId || this.props.savedUser.userId
    };
    this.props.history.push(USERS_STEPS[this.props.index].getNextStep(params));
  };

  getUserId = () =>
    this.props.match.params.personaId ||
    (this.props.savedUser && this.props.savedUser.userId);

  render() {
    let personaId;
    if (this.props.match.params.personaId) {
      personaId = this.getUserId();
    }

    return (
      <main>
        <Helmet title="Nuevo Usuario" />
        <Layout>
          {!personaId ||
          (personaId && (this.props.user || this.props.savedUser)) ? (
            <SaveNextCarousel
              steps={USERS_STEPS.map(
                ({ title, getHref, backHref, backLabel }) => ({
                  title,
                  href: personaId ? getHref(personaId) : null,
                  backHref,
                  backLabel
                })
              )}
              onClickNext={this.onClickNext}
              index={this.props.index}
              disableNext={
                !this.props.match.params.personaId && !this.props.savedUser
              }
              activeStep={personaId ? USERS_STEPS.length : 0}
              backHref={USERS_STEPS[0].backHref}
              backLabel={USERS_STEPS[0].backLabel}
            >
              {USERS_STEPS.map(({ component: Component, ...rest }, index) => {
                const ComponentWithName = ComponentWithMembershipName(
                  Component,
                  index,
                  rest,
                  this.props.user,
                  personaId
                );
                return <ComponentWithName key={index} />;
              })}
            </SaveNextCarousel>
          ) : null}
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getUserById: (personaId: string) => {
    dispatch(getUserById(personaId));
  }
});

const mapStateToProps = state => {
  const { user } = state;
  return {
    savedUser: user.basicUserSaved,
    //savingUser: user.savingUser,
    user: user.userById
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(s)(UsersCarousel));
