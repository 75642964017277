import React from "react";
import { RouterProps, Styled } from "../../../../toolkit/decorators/Artifact";
import FormBuilder from "../../../../toolkit/baseForms2/formBuilder/FormBuilder";
import { EmpresaConvenioCarouselDispatcher } from "../../../containers/empresaConvenioCarousel/EmpresaConvenioCarousel.dispatcher";
import { EmpresaConvenioCarouselStore } from "../../../containers/empresaConvenioCarousel/EmpresaConvenioCarousel.store";
import {
  successNotification,
  errorNotification
} from "../../../../utils/notifications";
import { Container, Row } from "reactstrap";
import {
  formConfig,
  EmpresaConvenioInvitadoInputs
} from "./EmpresaConvenioInvitado.form";
import ISelectBuilder from "../../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";
import IButton from "../../../../toolkit/baseForms2/iButton/IButton";
import s from "./styles.scss";
import { getSchedulePatternsCatalog } from "../../../actions/catalogs";
import { handleRequestError } from "../../../../utils/helpers";

interface Props
  extends EmpresaConvenioCarouselDispatcher,
    EmpresaConvenioCarouselStore,
    RouterProps<{
      id: string;
    }> {
  id: number;
  handleDirty: (dirty: boolean, dirtyMsg: string) => void;
  handleParams: (id: number) => void;
}
interface State {
  selected: number[];
  esActivo: boolean;
  init: any;
  schedulePatterns: any[];
}

@Styled(s)
export default class EmpresaConvenioInvitadoStep extends React.Component<
  Props,
  State
> {
  state = {
    selected: [],
    esActivo: false,
    init: null,
    schedulePatterns: []
  };

  triggers = {} as {
    formSubmit: () => {
      hasError: boolean;
      values: { [key in EmpresaConvenioInvitadoInputs]: string };
    };
  };

  async componentDidMount() {
    const preview = await this.props.getEmpresaConvenioInvitado(this.props.id);

    if (preview.fulfilled) {
      this.setState({
        selected: preview.empresaConvenioInvitado.clubes,
        esActivo: preview.empresaConvenioInvitado.invitadosPermitidos,
        init: {
          activar: preview.empresaConvenioInvitado.invitadosPermitidos
            ? "1"
            : "0",
          patron: preview.empresaConvenioInvitado.tipoAcceso
        }
      });
    }

    this.props.getClubesAsignables();

    getSchedulePatternsCatalog()
      .then(({ data }) => {
        this.setState({ schedulePatterns: data });
      })
      .catch(handleRequestError);
  }

  // Seleccionar todos los clubes
  onCheckAll = evt => {
    const value = evt.target.checked ? true : false;
    const selected = value
      ? [
          ...this.props.clubesAsignables
            .map(c => ({ id: c.id, nombre: c.nombre }))
            .map(c => c.id)
        ]
      : [];

    this.setState({ selected });
  };

  // Selección de un club
  onCheckClub = (evt, idClub) => {
    const value = evt.target.checked ? true : false;
    const selected = [...this.state.selected];
    const index = selected.indexOf(Number(idClub));

    if (value && index === -1) {
      selected.push(idClub);
    } else if (!value && index !== -1) {
      selected.splice(index, 1);
    }

    this.setState({ selected });
  };

  submit = async evt => {
    evt.preventDefault();
    const formInfo = this.triggers.formSubmit();

    if (formInfo.hasError) {
      errorNotification("Verifique la información solicitada");
      return;
    }

    const preview = await this.props.putEmpresaConvenioInvitado({
      id: this.props.id,
      clubes: this.state.selected,
      invitadosPermitidos: formInfo.values.activar == "1",
      tipoAcceso: formInfo.values.patron
    });

    if (preview.fulfilled) {
      successNotification("Configuración de invitados guardada correctamente");
    } else {
      errorNotification("No se pudo guardar la configuración de invitados");
    }
  };

  regresar = () => {
    this.props.history.push("/empresas-convenios");
  };

  render() {
    const activeClass = this.state.esActivo ? "" : s.disableForm;
    return (
      <Container>
        <p className="primaryTitle w-100">Datos para invitados especiales</p>
        <FormBuilder<EmpresaConvenioInvitadoInputs, string>
          config={formConfig}
          triggerSubmit={trigger => (this.triggers.formSubmit = trigger)}
          loading={this.props.gettingEmpresaConvenioInvitado}
          init={this.state.init}
        >
          <ISelectBuilder
            name="activar"
            data={[
              { value: "0", label: "No activa" },
              { value: "1", label: "Activa" }
            ]}
            onChange={value => this.setState({ esActivo: value == "1" })}
          />
          <ISelectBuilder
            name="patron"
            data={this.state.schedulePatterns}
            mapOption={item => ({ value: item.patronId, label: item.nombre })}
            disabled={!this.state.esActivo}
          />
        </FormBuilder>

        <p className="primaryTitle w-100">Clubes de acceso permitidos</p>

        <form onSubmit={this.submit}>
          <div className={`form-row ${activeClass}`}>
            <div className="col-3 mt-2">
              <div style={{ display: "inline-block", marginRight: "8px" }}>
                <input
                  id="all-clubes"
                  onChange={this.onCheckAll}
                  type="checkbox"
                  style={{
                    position: "unset",
                    margin: "0px",
                    marginLeft: "0px"
                  }}
                />
              </div>
              <label htmlFor="all-clubes" style={{ display: "inline-block" }}>
                Seleccionar todas
              </label>
            </div>
          </div>

          <hr className={`${activeClass}`} />

          <Row className={`${activeClass}`}>{this.renderClub()}</Row>

          <Row className="mb-5">
            <IButton
              name="submit"
              label="Guardar"
              isSubmit={true}
              sCol={3}
              sOff={6}
              disabled={this.props.clubesAsignablesLoading}
              buttonClass={s.primaryButton}
            />
            <IButton
              name="regresar"
              label="Regresar"
              isSubmit={false}
              sCol={3}
              onClick={this.regresar}
              buttonClass={s.secondaryButton}
            />
          </Row>
        </form>
      </Container>
    );
  }

  renderClub = () => {
    return this.props.clubesAsignables
      ? this.props.clubesAsignables
          .map(c => ({ id: c.id, nombre: c.nombre }))
          .map(e => (
            <div key={"club-ls-" + e.id} className="col-3 mt-2">
              <div
                id={"club-check-" + e.id}
                style={{
                  display: "inline-block",
                  marginRight: "8px",
                  paddingTop: "1px",
                  verticalAlign: "top"
                }}
              >
                <input
                  onChange={evt => this.onCheckClub(evt, e.id)}
                  type="checkbox"
                  checked={this.state.selected.indexOf(e.id) >= 0}
                  style={{
                    position: "unset",
                    margin: "0px",
                    marginLeft: "0px"
                  }}
                />
              </div>
              <label
                htmlFor={"club-check-" + e.id}
                style={{
                  display: "inline-block",
                  width: "calc(100% - 24px)",
                  fontSize: "12px"
                }}
              >
                {e.nombre}
              </label>
            </div>
          ))
      : "Cargando clubes...";
  };
}
