import React from "react";
import CellRenderer from "./CellRenderer";
if (process.env.BROWSER) {
  var ReactDataGrid = require("react-data-grid");
  var Row = ReactDataGrid.Row;
}

interface Props {
  idx: number;
  row: any;
}
class RowRenderer extends React.Component<Props> {
  render() {
    return <Row cellRenderer={CellRenderer} ref="row" {...this.props} />;
  }
}

export default RowRenderer;
