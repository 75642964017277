import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import {
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Button,
  Input
} from "reactstrap";
import ReactTable from "react-table";
import columns from "./columns";
import ClubSelectList from "../../containers/clubSelectList";
import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "../common/DatePicker";
import moment from "moment";
import {
  getLotesCargosAutomaticos,
  downloadLotesCargosAutomaticos
} from "../../actions/lotesCargosAutomaticos";
import BaseInput from "../../../toolkit/baseInput";
import { downloadReportCatPaymentLines } from "../../actions/reports";

interface Props {
  userContext: any;
}

interface State {
  reportType: String;

  club: any;
  fechaInicio: any;
  fechaFin: any;
  hasError: boolean;
  data: any[];
  downloading: boolean;

  reporteLpFecha: any;
}

class LotesMediosPagoPage extends React.Component<Props, State> {
  state = {
    club: null,
    fechaInicio: null,
    fechaFin: null,
    hasError: false,
    data: [],
    reportType: "ARCHIVOS",
    reporteLpFecha: null,
    downloading: false
  };

  generateFilters = () => {
    if (!this.state.fechaInicio) {
      errorNotification("Por favor, seleccione la fecha de inicio de búsqueda");
      return null;
    } else if (!this.state.fechaFin) {
      errorNotification("Por favor, seleccione la fecha final de búsqueda");
      return null;
    }

    return {
      idClub: (this.state.club && this.state.club.value) || null,
      fechaInicio: this.state.fechaInicio,
      fechaFin: this.state.fechaFin,
      hasError: this.state.hasError || null
    };
  };

  submit = evt => {
    evt.preventDefault();
    const filters = this.generateFilters();
    filters && infoNotification("Buscando medios de pago eliminados");

    filters &&
      getLotesCargosAutomaticos(filters)
        .then(response => {
          successNotification("Búsqueda finalizada");
          this.setState({ data: response.data });
        })
        .catch(err => errorNotification("No se pudo completar la búsqueda"));
  };

  descargar = evt => {
    evt.preventDefault();
    const filters = this.generateFilters();
    filters && infoNotification("Descargando archivo...");
    filters && this.setState({ downloading: true });

    filters &&
      downloadLotesCargosAutomaticos(filters, () =>
        this.setState({ downloading: false })
      );
  };

  render() {
    return (
      <Container className="mt-3 p-0">
        <Row>
          <Col xs={12} md={4} lg={2}>
            <div
              style={{ display: "inline-block", width: "calc(100% - 24px)" }}
            >
              <BaseInput
                type="select"
                label="Tipo de reporte"
                id="tiporeporteCat"
                name="tiporeporteCat"
                placeholder="Seleccione un tipo de reporte"
                value={this.state.reportType}
                onChange={e => {
                  this.setState({ reportType: e.target.value });
                }}
                options={[
                  { value: "ARCHIVOS", label: "ARCHIVOS" },
                  { value: "LINEAS_PAGO", label: "LINEAS DE PAGO" }
                ]}
              />
            </div>
          </Col>
          <Col xs={12} md={8} lg={10}>
            {/* Filtros */}
            {this.state.reportType == "ARCHIVOS" && (
              <form onSubmit={this.submit} autoComplete="off">
                <Row>
                  {/* Selector de club */}
                  <Col md={3}>
                    <ClubSelectList
                      clubSelected={this.state.club}
                      onSelectClub={club => this.setState({ club })}
                      label="Club"
                      userClub={this.props.userContext.clubSessionId}
                      soloMigrados={true}
                    />
                  </Col>

                  {/* Fecha de inicio */}
                  <div className="col-2 mb-2">
                    <div
                      style={{
                        display: "inline-block",
                        width: "calc(100% - 24px)"
                      }}
                    >
                      <DatePicker
                        id="fechaInicio"
                        name="fechaInicio"
                        label="* Fecha inicio"
                        selected={this.state.fechaInicio}
                        onChange={e =>
                          this.setState({
                            fechaInicio: moment(e).format("YYYY-MM-DD")
                          })
                        }
                      />
                    </div>
                    <UncontrolledTooltip
                      placement="top"
                      target="fechaInicioBorrar"
                    >
                      Borrar fecha
                    </UncontrolledTooltip>
                    <div
                      id="fechaInicioBorrar"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ fechaInicio: null });
                      }}
                      style={{
                        cursor: "pointer",
                        textAlign: "right",
                        display: "inline-block",
                        width: "24px"
                      }}
                    >
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </div>
                  </div>

                  {/* Fecha de fin */}
                  <div className="col-2 mb-2">
                    <div
                      style={{
                        display: "inline-block",
                        width: "calc(100% - 24px)"
                      }}
                    >
                      <DatePicker
                        id="fechaFin"
                        name="fechaFin"
                        label="* Fecha fin"
                        selected={this.state.fechaFin}
                        onChange={e =>
                          this.setState({
                            fechaFin: moment(e).format("YYYY-MM-DD")
                          })
                        }
                      />
                    </div>
                    <UncontrolledTooltip
                      placement="top"
                      target="fechaFinBorrar"
                    >
                      Borrar fecha
                    </UncontrolledTooltip>
                    <div
                      id="fechaFinBorrar"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ fechaFin: null });
                      }}
                      style={{
                        cursor: "pointer",
                        textAlign: "right",
                        display: "inline-block",
                        width: "24px"
                      }}
                    >
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </div>
                  </div>

                  {/* Checkbox para indicar que solo se muestren procesos con error */}
                  <div className="col-2">
                    <div className="mt-1 mb-1" style={{ fontSize: "0.8em" }}>
                      Procesos con errores
                    </div>
                    <div>
                      <Input
                        type="checkbox"
                        className="m-0 p-0"
                        checked={this.state.hasError}
                        onChange={e =>
                          this.setState({ hasError: e.target.checked })
                        }
                      />
                    </div>
                  </div>

                  {/* Botón de búsqueda */}
                  <Col md={2} className="p-0">
                    <Button
                      className={`${s.primaryButton} mt-4`}
                      type="submit"
                      size="sm"
                      block
                    >
                      Buscar
                    </Button>
                  </Col>
                </Row>
              </form>
            )}

            {this.state.reportType == "LINEAS_PAGO" && (
              <div>
                <Row>
                  {/* Fecha*/}
                  <div className="col-2 mb-2">
                    <div
                      style={{
                        display: "inline-block",
                        width: "calc(100% - 24px)"
                      }}
                    >
                      <DatePicker
                        id="fechaLp"
                        name="fechaLp"
                        label="* Fecha"
                        selected={this.state.reporteLpFecha}
                        onChange={e =>
                          this.setState({
                            reporteLpFecha: moment(e).format("YYYY-MM-DD")
                          })
                        }
                      />
                    </div>
                    <UncontrolledTooltip placement="top" target="fechaLpBorrar">
                      Borrar fecha
                    </UncontrolledTooltip>
                    <div
                      id="fechaLpBorrar"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ reporteLpFecha: null });
                      }}
                      style={{
                        cursor: "pointer",
                        textAlign: "right",
                        display: "inline-block",
                        width: "24px"
                      }}
                    >
                      <FontAwesomeIcon icon={faTimesCircle} />
                    </div>
                  </div>

                  {/* Botón de búsqueda */}
                  <Col md={2} className="p-0">
                    <Button
                      className={`${s.primaryButton} mt-4`}
                      size="sm"
                      block
                      disabled={!this.state.reporteLpFecha}
                      onClick={e => {
                        e.preventDefault();
                        downloadReportCatPaymentLines(
                          this.state.reporteLpFecha
                        );
                      }}
                    >
                      Descargar
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </Row>

        {this.state.reportType == "ARCHIVOS" && (
          <>
            <div className="row mt-5">
              {/* Botón para exportar */}
              {!!this.state.data && !!this.state.data.length && (
                <>
                  <div className="col-6 p-0" style={{ lineHeight: "40px" }}>
                    <span>Envios encontrados: {this.state.data.length}</span>
                  </div>
                  <div
                    className="col-6 text-right p-0"
                    style={{ lineHeight: "40px" }}
                  >
                    <span>Exportar a CSV: </span>
                    <Button
                      id="btnReportExportCsv"
                      color="link"
                      type="button"
                      onClick={evt => this.descargar(evt)}
                      disabled={this.state.downloading}
                    >
                      <FontAwesomeIcon icon={faFileExport} color="black" />
                    </Button>
                  </div>
                </>
              )}
            </div>
            <ReactTable
              className=" -striped -highlight"
              data={this.state.data || []}
              columns={columns}
              sortable={false}
              resizable={false}
              pageSize={
                this.state.data && this.state.data.length > 5
                  ? this.state.data.length
                  : 5
              }
              showPagination={false}
            />
          </>
        )}
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(LotesMediosPagoPage));
