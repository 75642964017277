import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Row, Container } from "reactstrap";
import ReactTable from "react-table";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { QueryResponse } from "../../../types/QueryResponse";
import isNil from "lodash.isnil";

interface Props {
  banks: QueryResponse<object>;
  searching: boolean;
  onFetchData: () => void;
  onClickNewBank: (bank) => any;
}

class Banks extends React.Component<Props> {
  handleEditBank = bank => {
    this.props.onClickNewBank(bank);
  };

  getStatusClass = estatusBanco => (estatusBanco ? "" : "table-danger");

  renderActionsBank = bank => {
    return (
      <div className="w-100 text-center">
        <Button
          color="link"
          onClick={() => this.props.onClickNewBank(bank)}
          className={`${s.actionHeaderIcon} ${s.editAnchor}`}
        >
          <FontAwesomeIcon icon={faPencilAlt} />
        </Button>
      </div>
    );
  };

  cellValue = (estatusBanco, estatusValor, valor) =>
    !estatusBanco
      ? ""
      : isNil(estatusValor) && isNil(valor)
      ? "No configurado"
      : estatusValor
      ? valor
      : `No aplica`;

  render() {
    const columns = [
      {
        Header: "Banco",
        accessor: "nombre"
      },
      {
        Header: "3 Meses Sin Intereses",
        id: "tresMeses",
        accessor: d =>
          this.cellValue(d.estatusBanco, d.estatusTresMeses, d.tresMeses)
      },
      {
        Header: "6 Meses  Sin Intereses",
        id: "seisMeses",
        accessor: d =>
          this.cellValue(d.estatusBanco, d.estatusSeisMeses, d.seisMeses)
      },
      {
        Header: "9 Meses Sin Intereses",
        id: "nueveMeses",
        accessor: d =>
          this.cellValue(d.estatusBanco, d.estatusNueveMeses, d.nueveMeses)
      },
      {
        Header: "12 Meses Sin Intereses",
        id: "doceMeses",
        accessor: d =>
          this.cellValue(d.estatusBanco, d.estatusDoceMeses, d.doceMeses)
      },
      {
        Header: "Acciones",
        id: "bancoId",
        accessor: bank => <Row> {this.renderActionsBank(bank)} </Row>
      }
    ];

    return (
      <Container className="mt-3 p-0">
        {this.props.banks && this.props.banks.content && (
          <ReactTable
            manual
            data={
              this.props.banks && this.props.banks.content
                ? this.props.banks.content
                : []
            }
            columns={columns}
            className="w-100"
            fetchData={this.props.onFetchData}
            loading={this.props.searching}
          />
        )}
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(Banks));
