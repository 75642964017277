import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import { Club } from "../../../../types-business/Clubs";
import ReactTable from "react-table";
import s from "./tabTableStyles.scss";
import { Row, Container, Col, TabPane, Button, Input } from "reactstrap";
import { Maintenance, Membership } from "../interfaces";

const DEFAULT_PAGE_SIZE = 10;

interface Props {
  agreementsFilters: any;
  onChangeAgreementFilter: any;
  allowedClubs: Club[];
  maintenances: Maintenance[];
  memberships: Membership[];
  searchAgreements: any;
  agreements: any;
  columnsAgreements: object;
  agreementsLoading: boolean;
  handleAgreementsRowClick: any;
  debouncedOnFetchDataAgreements: any;
}

class TabContentAgreements extends React.Component<Props> {
  render() {
    return (
      <TabPane tabId="2" className="h-100">
        <Container className="px-0 h-100">
          <Row>
            <Col xs={12} md={12} lg={6}>
              <Input
                id="clubId"
                name="clubId"
                type="select"
                value={this.props.agreementsFilters.clubId}
                disabled={this.props.allowedClubs.length === 1}
                className={s.allowedClubs}
                size="xs"
                onChange={e => this.props.onChangeAgreementFilter(e, "clubId")}
              >
                <option key="" value="">
                  Seleccionar Club
                </option>
                {this.props.allowedClubs.map(club => (
                  <option key={club.clubId} value={club.clubId}>
                    {club.nombre}
                  </option>
                ))}
              </Input>
            </Col>
            <Col xs={12} md={12} lg={6}>
              <Input
                id="mantenimientoId"
                name="mantenimientoId"
                type="select"
                value={this.props.agreementsFilters.mantenimientoId}
                className={s.allowedClubs}
                onChange={e =>
                  this.props.onChangeAgreementFilter(e, "mantenimientoId")
                }
              >
                <option key="" value="">
                  Seleccionar Mantenimiento
                </option>
                {this.props.maintenances &&
                  this.props.maintenances.map(maintenance => (
                    <option
                      key={maintenance.datosBasicosMantenimientoId}
                      value={maintenance.datosBasicosMantenimientoId}
                    >
                      {maintenance.nombre}
                    </option>
                  ))}
              </Input>
            </Col>
            <Col xs={12} md={12} lg={6}></Col>
            <Col xs={12} md={12} lg={6}>
              <Button
                size="sm"
                className={`${s.searchButton} ${s.primaryButton} float-right btn-block`}
                onClick={this.props.searchAgreements}
                disabled={
                  !this.props.agreementsFilters.clubId ||
                  !this.props.agreementsFilters.mantenimientoId
                }
              >
                Buscar
              </Button>
            </Col>
          </Row>
          <div style={{ paddingTop: "5px", height: "550px" }}>
            <ReactTable
              manual
              data={(this.props.agreements || {}).content || []}
              columns={this.props.columnsAgreements}
              className="w-100 h-100 -striped -highlight"
              loading={this.props.agreementsLoading}
              sortable={false}
              defaultPageSize={DEFAULT_PAGE_SIZE}
              onFetchData={this.props.debouncedOnFetchDataAgreements}
              pages={(this.props.agreements || {}).totalPages || 1}
              getTrProps={(_, agreement) => {
                return {
                  onClick: () =>
                    this.props.handleAgreementsRowClick(agreement.original)
                };
              }}
            />
          </div>
        </Container>
      </TabPane>
    );
  }
}
export default withRouter(withStyles(s)(TabContentAgreements));
