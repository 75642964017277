import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Row, Col, Button, Input, Container } from "reactstrap";
import {
  faTimes,
  faCheck,
  faArrowRight,
  faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import mapValues from "lodash.mapvalues";
import isFunction from "lodash.isfunction";
import isNil from "lodash.isnil";

const move = (source, destination, droppableSource, droppableDestination) => {
  const { index: sourceIndex, droppableId: sourceId } = droppableSource;
  const {
    index: destinationIndex,
    droppableId: destinationId
  } = droppableDestination;

  const [removed] = source.splice(sourceIndex, 1);
  destination.splice(destinationIndex, 0, removed);

  const result = {};
  result[sourceId] = source;
  result[destinationId] = destination;
  return result;
};

const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid,
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? "rgba(123, 157, 72,0.5)" : "rgba(155, 155,155, 0.1)",
  fontSize: "0.8rem",
  ...draggableStyle
});

const getListStyle = (isDraggingOver: boolean, _height: string) => ({
  background: isDraggingOver ? "rgba(77, 171, 247,0.2)" : "#FFF",
  border: "1px solid red",
  borderRadius: "3px",
  height: _height || "600px",
  overflowY: "scroll"
});

interface ITEM {
  id: string;
  content: string;
}

interface Props {
  data: ITEM[];
  selected: ITEM[];
  height: string;
  itemFullWidth: boolean;
  updateSelection: (data: object) => any;
  onClickSelected?: (itemId: string, state: boolean) => any;
  resetClicked: (source: object[]) => any;
}

interface State {
  checkedLeft: object;
  checkedRight: object;
  clicked: object;
}

class DragAndDropSelect extends Component<Props, State> {
  state = {
    checkedLeft: null,
    checkedRight: null,
    clicked: {}
  };

  id2List = {
    source: "data",
    destination: "selected"
  };

  getList = id => this.props[this.id2List[id]];

  updateSelection = () => {
    const { data: items } = this.props;
    const checkedLeft = items.reduce((accum, { id }) => {
      accum[id] = false;
      return accum;
    }, {});

    this.setState(prevState => ({
      ...prevState,
      checkedLeft,
      checkedRight: { ...checkedLeft }
    }));
  };

  componentDidMount() {
    this.updateSelection();
  }

  componentDidUpdate(prevProps) {
    const { data: prevData } = prevProps;
    if (!this.props.data && this.state.checkedLeft) {
      this.setState({ checkedLeft: null, checkedRight: null });
    }

    if (
      (this.props.data && !this.state.checkedLeft) ||
      (prevData &&
        this.props.data &&
        prevData.length !== this.props.data.length)
    ) {
      this.updateSelection();
    }

    if (this.props.selected !== prevProps.selected) {
      this.updateSelection();
    }
  }

  setSelection = (selection: { items: ITEM[]; selected: ITEM[] }) => {
    this.props.updateSelection({
      ...selection,
      selected: selection.selected.map(itemSelected => ({
        ...itemSelected,
        clicked: this.state.clicked[itemSelected.id]
      }))
    });
  };

  onDragEnd = result => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const { index: sourceIndex, droppableId: sourceDroppableId } = source;
    const { droppableId: destinationDroppableId } = destination;

    if (sourceDroppableId !== destinationDroppableId) {
      const result: any = move(
        this.getList(sourceDroppableId),
        this.getList(destinationDroppableId),
        source,
        destination
      );
      if (
        sourceDroppableId === "source" &&
        destinationDroppableId == "destination"
      ) {
        this.setSelection({
          items: result.source,
          selected: result.destination
        });
      } else if (
        sourceDroppableId === "destination" &&
        destinationDroppableId == "source"
      ) {
        let state = { ...this.state };
        state.clicked = {};
        this.setState(state);
        if (isFunction(this.props.resetClicked))
          this.props.resetClicked(result.source);
        this.setSelection({
          selected: result.destination,
          items: result.source
        });
      }
    }
    if (this.props.data[sourceIndex])
      this.checkItem(this.props.data[sourceIndex].id, sourceDroppableId, false);
  };

  checkItem = (id, droppableId, checked) => {
    if (droppableId === "source") {
      this.setState(prevState => ({
        ...prevState,
        checkedLeft: {
          ...prevState.checkedLeft,
          [id]: checked
        }
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        checkedRight: {
          ...prevState.checkedRight,
          [id]: checked
        }
      }));
    }
  };

  onClickItem = (id, droppableId) => {
    if (droppableId === "destination") {
      const clicked = {
        ...mapValues(this.state.clicked, _ => false),
        [id]: !this.state.clicked[id]
      };

      this.setState({
        clicked
      });

      if (this.props.onClickSelected) {
        this.props.onClickSelected(id, clicked[id]);
      }
    }
  };

  handlecheckItem = (e, id, droppableId) => {
    const checked = e.target.checked;
    this.checkItem(id, droppableId, checked);
  };

  checkAll = value => {
    const items = this.props.data;
    const checkedLeft = items.reduce((accum, { id }) => {
      accum[id] = value;
      return accum;
    }, {});

    this.setState(() => ({ checkedLeft }));
  };

  transferAllChecked = async (e, startDroppableId) => {
    e.preventDefault();
    let checkedObject;
    let items;

    if (startDroppableId === "source") {
      checkedObject = this.state.checkedLeft;
      items = this.props.data;
    } else {
      checkedObject = this.state.checkedRight;
      items = this.props.selected;
    }

    const checkedItems = items.filter(({ id }) => {
      return checkedObject[id];
    });

    const uncheckedItems = items.filter(({ id }) => {
      return !checkedObject[id];
    });

    items.forEach(({ id }) => {
      checkedObject[id] = false;
    });

    if (startDroppableId === "source") {
      this.setSelection({
        items: uncheckedItems,
        selected: this.props.selected.concat(checkedItems)
      });
      this.setState(prevState => {
        return {
          ...prevState,
          checkedLeft: checkedObject
        };
      });
    } else {
      let state = { ...this.state };
      state.clicked = {};
      await this.setState(state);

      if (isFunction(this.props.resetClicked)) {
        this.props.resetClicked(checkedItems);
      }

      this.setSelection({
        items: this.props.data.concat(checkedItems),
        selected: uncheckedItems
      });
      this.setState(prevState => {
        return {
          ...prevState,
          checkedRight: checkedObject
        };
      });
    }
  };

  render() {
    const getDroppable = (items, droppableId, checked) => (
      <Droppable droppableId={droppableId}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver, this.props.height)}
          >
            <Row className="m-0 p-0 py-1">
              {items.map(({ id, content, draggable }, index) => (
                <Col
                  xs={!!this.props.itemFullWidth ? 12 : 6}
                  className="p-0 px-1 py-0"
                  key={id}
                >
                  {(!isNil(draggable) ? !!draggable : true) && (
                    <Draggable key={id} draggableId={id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            ),
                            borderColor: this.state.clicked[id]
                              ? "red"
                              : "gray",
                            borderStyle: "solid",
                            borderWidth: "1px"
                          }}
                        >
                          <div>
                            <Input
                              style={{
                                position: "relative",
                                margin: "0px 2px 0px 0px"
                              }}
                              type="checkbox"
                              checked={checked[id]}
                              onChange={e =>
                                this.handlecheckItem(e, id, droppableId)
                              }
                            />
                            <span
                              onClick={() => {
                                this.onClickItem(id, droppableId);
                              }}
                              style={{
                                display: "inline-block",
                                width: "80%",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"
                              }}
                            >
                              {content}
                            </span>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  )}
                  {(!isNil(draggable) ? !draggable : false) && (
                    <div
                      style={{
                        userSelect: "none",
                        padding: grid,
                        margin: `0 0 ${grid}px 0`,
                        background: "rgba(2, 132, 201, 0.2)",
                        fontSize: "0.8rem",
                        borderColor: "gray",
                        borderStyle: "solid",
                        borderWidth: "1px"
                      }}
                    >
                      {content}
                    </div>
                  )}
                </Col>
              ))}
            </Row>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );

    return (
      <Container fluid>
        <Row className="p-0">
          {this.props.data &&
            this.state.checkedLeft &&
            this.state.checkedRight && (
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Col xs={6} className="p-0">
                  {getDroppable(
                    this.props.data,
                    "source",
                    this.state.checkedLeft
                  )}
                </Col>
                <Col
                  xs={1}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "top"
                  }}
                  className="p-0"
                >
                  <Button
                    onClick={_ => this.checkAll(true)}
                    color="link"
                    className="text-success float-left"
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </Button>
                  <Button
                    onClick={_ => this.checkAll(false)}
                    color="link"
                    className="text-danger float-left"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                  <div style={{ height: "30%" }} />
                  <Button
                    onClick={e => this.transferAllChecked(e, "source")}
                    color="link"
                    className="text-success float-left"
                  >
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Button>
                  <Button
                    onClick={e => this.transferAllChecked(e, "destination")}
                    color="link"
                    className="text-danger float-left"
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </Button>
                </Col>
                <Col xs={5} className="p-0">
                  {getDroppable(
                    this.props.selected,
                    "destination",
                    this.state.checkedRight
                  )}
                </Col>
              </DragDropContext>
            )}
        </Row>
      </Container>
    );
  }
}

export default DragAndDropSelect;
