import {
  faCalendar,
  faCircle,
  faConciergeBell,
  faFile,
  faFileExport,
  faPencilAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import moment from "moment";
import React from "react";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  UncontrolledTooltip
} from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import ModalFrame from "../../../toolkit/modalFrame";
import { getEnumOptions } from "../../../utils/helpers";
import {
  getDemandEvidencesRequest,
  getDemandsFileDownload,
  getDemandsFileAxios
} from "../../actions/demans";
import DatePicker from "../common/DatePicker";
import DemandClassificationSelector from "../demandClassificationSelector";
import {
  Criticidad,
  Demand,
  ESTATUS_ATENCION,
  ESTATUS_CLASIFICACION,
  OrigenPeticion
} from "../demands/types/Demand";
import { Node } from "../productsClassification";
import ClubCatalogSelect from "../sportsProductsNewQuote/ClubCatalogSelect";
import EditDetails from "./EditDetails";
import EvidencesComponent from "./EvidencesComponent";
import ObservationComponent from "./ObservationComponent";
import s from "./styles.scss";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
  Props,
  State,
  ESTATUS_APERTURA,
  ESTATUS_ENCUESTA,
  opcionesEstatus,
  opcionesEstatusApertura,
  defaultColumn,
  getColorEstatusRespuesta,
  getIconEstatusRespuesta,
  getFaceColorEstatusRespuesta
} from "./demandListTypes";
import BaseCsvIconDownloader from "../../../toolkit/baseCsvIconDownloader";

const formatTime = dateTime =>
  dateTime
    ? moment(dateTime)
        .utc()
        .format("YYYY-MM-DD")
    : null;

class NewDemand extends React.Component<Props, State> {
  state = {
    query: {
      nivelClasificacionId: null,
      clubResponsable: { value: null },
      clubBase: { value: null },
      clubEmisor: { value: null },
      estatus: null,
      criticidad: null,
      folio: "",
      membresiaPublicoId: "",
      desde: null,
      hasta: null,
      tipo: null,
      origen: null
    },
    modalOpenComment: false,
    modalOpenDate: false,
    modalId: null,
    modalComentarios: null,
    modalComentariosEstatus: "",

    modalOpenEvidences: false,
    modalDemandEvidences: [],
    modalDemandId: 0,

    page: 0,

    exporting: false
  };

  async componentDidMount() {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.query
    ) {
      await this.setState({
        ...this.state,
        query: this.props.location.state.query
      });
    }
    //await this.onClickSearch();
  }

  nombreClub = clubId => {
    return (
      this.props.clubs &&
      (
        this.props.clubs.find(({ clubId: id }) => id == clubId) || {
          nombre: "-"
        }
      ).nombre
    );
  };

  getLevelName = (levelList: Node[], levelId) => {
    return levelList && levelId
      ? levelList.find(({ id }) => levelId == id).descripcion
      : "";
  };

  setQuery = (attrs: object) => {
    this.setState({
      query: {
        ...this.state.query,
        ...attrs
      }
    });
  };

  setClubResponsable = (clubResponsable: object) => {
    this.setQuery({ clubResponsable });
  };

  setClubEmisor = (clubEmisor: object) => {
    this.setQuery({ clubEmisor });
  };

  setClubBase = (clubBase: object) => {
    this.setQuery({ clubBase });
  };

  columns = [
    {
      ...defaultColumn,
      Header: "Folio",
      accessor: "id",
      width: 60
    },
    {
      ...defaultColumn,
      Header: "Tipo P.",
      accessor: "tipoPeticion"
    },
    {
      Header: "Comentario",
      accessor: "comentario",
      width: 185,
      Cell: ({ value, column: { id }, index }) => {
        const tooltipId = `${id}-${index}`;
        return (
          <p
            className="h-100"
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden"
            }}
            id={tooltipId}
          >
            {value}
            <UncontrolledTooltip target={tooltipId} placement="bottom">
              {value}
            </UncontrolledTooltip>
          </p>
        );
      }
    },
    {
      ...defaultColumn,
      Header: "Emisor",
      accessor: "clubEmisorId",
      Cell: ({ value }) => (
        <p
          className="m-0 text-center"
          style={{ whiteSpace: "pre-line" }}
        >{`${this.nombreClub(value)}`}</p>
      )
    },
    {
      ...defaultColumn,
      Header: "Responsable",
      accessor: "clubReceptorId",
      Cell: ({ value }) => (
        <p
          className="m-0 text-center"
          style={{ whiteSpace: "pre-line" }}
        >{`${this.nombreClub(value)}`}</p>
      )
    },
    {
      ...defaultColumn,
      Header: "Fecha",
      accessor: "fechaApertura",
      width: 100,
      Cell: ({ value }) => moment(value).format("DD-MM-YYYY HH:mm")
    },
    {
      ...defaultColumn,
      Header: "Estatus",
      accessor: "estatus"
    },
    {
      ...defaultColumn,
      Header: "Cri.",
      accessor: "criticidad"
    },
    {
      ...defaultColumn,
      Header: "Fecha límite",
      accessor: "fechaLimiteRespuesta",
      width: 100,
      Cell: ({ value }) => moment(value).format("DD-MM-YYYY HH:mm")
    },
    {
      ...defaultColumn,
      Header: "Correo Electrónico",
      accessor: "email",
      width: 130
    },
    {
      ...defaultColumn,
      Header: "Respuesta",
      accessor: "estatusAtencion",
      width: 40,
      Cell: cellInfo => {
        const {
          value,
          original: { estatusClasificacion }
        } = cellInfo;

        let estatus = value;

        if (estatusClasificacion === ESTATUS_CLASIFICACION.SIN_CLASIFICAR) {
          estatus = ESTATUS_ATENCION.SIN_CLASIFICAR;
        }

        return (
          <FontAwesomeIcon
            style={{
              cursor: "pointer",
              color: getColorEstatusRespuesta(estatus)
            }}
            className="w-100 h-100"
            icon={faCircle}
          />
        );
      }
    },
    {
      ...defaultColumn,
      Header: "Contactado",
      accessor: "estatusRespuesta",
      width: 40,
      Cell: cellInfo => {
        const {
          value,
          original: { fechaEnvioEncuesta }
        } = cellInfo;
        const momentFechaLimiteRespuesta = moment(fechaEnvioEncuesta)
          .add(1, "month")
          .date(5)
          .startOf("day");
        const momentNow = moment();

        let estatus = value;

        if (
          value === ESTATUS_ENCUESTA.ENCUESTA_ENVIADA &&
          fechaEnvioEncuesta &&
          momentNow.isAfter(momentFechaLimiteRespuesta)
        ) {
          estatus = ESTATUS_ENCUESTA.ENCUESTA_NO_CONTESTADA;
        }

        return (
          <FontAwesomeIcon
            style={{ cursor: "pointer" }}
            icon={getIconEstatusRespuesta(estatus)}
            className={`w-100 h-100 ${getFaceColorEstatusRespuesta(estatus)}`}
          />
        );
      }
    },
    {
      Header: "Opciones",
      id: "acciones",
      width: 80,
      accessor: (demand: Demand) => {
        return (
          <Container className="p-0">
            <Row className="m-0">
              <Col className="px-1">
                <a
                  id={`edicion${demand.id}`}
                  onClick={() =>
                    this.props.history.push({
                      pathname: `/nueva-queja/${demand.id}`,
                      state: { query: this.state.query }
                    })
                  }
                >
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faPencilAlt}
                  />
                </a>
                <UncontrolledTooltip
                  placement="left"
                  target={`edicion${demand.id}`}
                >
                  Editar Queja
                </UncontrolledTooltip>
              </Col>
              <Col className="px-1">
                <a
                  id={`cerrar${demand.id}`}
                  onClick={() =>
                    this.toggleModalComment(
                      demand.id,
                      demand.comentariosSeguimiento,
                      demand.estatus
                    )
                  }
                >
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faConciergeBell}
                  />
                </a>
                <UncontrolledTooltip
                  placement="left"
                  target={`cerrar${demand.id}`}
                >
                  Seguimiento Queja
                </UncontrolledTooltip>
              </Col>
              <Col className="px-1">
                <a
                  id={`fechaYresponsable${demand.id}`}
                  onClick={() => this.toggleModalDate(demand.id, demand)}
                >
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faCalendar}
                  />
                </a>
                <UncontrolledTooltip
                  placement="left"
                  target={`fechaYresponsable${demand.id}`}
                >
                  Fecha Compromiso y Responsable
                </UncontrolledTooltip>
              </Col>
              <Col className="px-1">
                <a
                  id={`evidences${demand.id}`}
                  onClick={() => this.openModalEvidenes(demand.id)}
                >
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    icon={faFile}
                  />
                </a>
                <UncontrolledTooltip
                  placement="left"
                  target={`evidences${demand.id}`}
                >
                  Evidencias
                </UncontrolledTooltip>
              </Col>
            </Row>
          </Container>
        );
      }
    }
  ];

  setCriticidad = e => {
    const criticidad = e.target.value;
    this.setQuery({ criticidad });
  };

  setEstatusAtencion = e => {
    const estatusAtencion = e.target.value;
    this.setQuery({ estatusAtencion });
  };

  setEstatusApertura = e => {
    const estatus = e.target.value;
    this.setQuery({ estatus });
  };

  fetchData = async ({ pageSize: size, page }) => {
    await this.setState({ page: page || 0 });
    this.onClickSearch();
  };

  getFiltersObject = () => {
    const {
      criticidad,
      clubResponsable: { value: clubResponsableId },
      clubEmisor: { value: clubEmisorId },
      clubBase: { value: clubBaseId },
      nivelClasificacionId,
      estatus,
      folio,
      membresiaPublicoId: membresiaSocioId,
      desde,
      hasta,
      tipo,
      origen
    } = this.state.query;

    return {
      criticidad,
      // estatusAtencion,
      estatus,
      folio,
      membresiaSocioId,
      clubResponsableId: clubResponsableId || "",
      clubEmisorId: clubEmisorId || "",
      clubBaseId: clubBaseId || "",
      desde: formatTime(desde),
      hasta: formatTime(hasta),
      tipo,
      origen,
      nivelClasificacionId,
      size: 20,
      page: this.state.page || 0
    };
  };

  onClickSearch = () => {
    const filters = this.getFiltersObject();
    this.props.getDemands(filters);
  };

  toggleModalComment = (
    id?: number,
    comentarios?: object[],
    estatus?: string
  ) => {
    this.setState({
      modalOpenComment: !this.state.modalOpenComment,
      modalId: id,
      modalComentarios: comentarios,
      modalComentariosEstatus: estatus
    });
  };

  toggleModalEvidences = () => {
    this.setState({ modalOpenEvidences: !this.state.modalOpenEvidences });
  };

  openModalEvidenes = (demandId: number) => {
    if (!!demandId) {
      this.getDemandEvidences(demandId, () => {
        this.setState({ modalDemandId: demandId, modalOpenEvidences: true });
      });
    }
  };

  getDemandEvidences = (demandId: number, f?: Function) => {
    getDemandEvidencesRequest(demandId).then(({ data }) => {
      this.setState({ modalDemandEvidences: data });
      if (!!f) {
        f();
      }
    });
  };

  toggleModalDate = (id?: number, demand?: Demand) => {
    this.setState({
      modalOpenDate: !this.state.modalOpenDate,
      modalId: id
    });
  };

  saveObservation = (observacion: object) => {
    let save = this.props.saveObservation(this.state.modalId, observacion);
    save.then(() => this.onClickSearch());
    return save;
  };

  saveDetails = (details: object) => {
    let save = this.props.saveDetails(this.state.modalId, details);
    save.then(() => this.onClickSearch());
    return save;
  };

  setMembresiaPublicoId = e => {
    const membresiaPublicoId = e.target.value;
    this.setQuery({ membresiaPublicoId });
  };

  setFolio = e => {
    const folio = e.target.value;
    this.setQuery({ folio });
  };

  setQueryDate = keyDate => {
    return value => {
      this.setQuery({ [keyDate]: value });
    };
  };

  setTipoPeticion = e => {
    const tipo = e.target.value;
    this.setQuery({ tipo });
  };

  setOrigen = e => {
    const origen = e.target.value;
    this.setQuery({ origen });
  };

  render() {
    return (
      <Container className="mt-3 p-0">
        <ModalFrame
          title={"Fecha Compromiso y Responsable"}
          isOpen={this.state.modalOpenDate}
          toggle={this.toggleModalDate}
          size="lg"
        >
          <EditDetails
            users={this.props.users}
            loadingUsers={this.props.loadingUsers}
            demand={
              ((this.props.demands || {}).content || []).find(
                d => d.id == this.state.modalId
              ) || {}
            }
            saveDetails={this.saveDetails}
          />
        </ModalFrame>
        <ModalFrame
          title={"Seguimiento Queja"}
          isOpen={this.state.modalOpenComment}
          toggle={this.toggleModalComment}
          size="lg"
        >
          <ObservationComponent
            comentarios={this.state.modalComentarios}
            estatus={this.state.modalComentariosEstatus}
            demand={
              ((this.props.demands || {}).content || []).find(
                d => d.id == this.state.modalId
              ) || {}
            }
            saveObservation={this.saveObservation}
          />
        </ModalFrame>
        <ModalFrame
          title={"Evidencias"}
          isOpen={this.state.modalOpenEvidences}
          toggle={this.toggleModalEvidences}
          size="lg"
        >
          <EvidencesComponent
            saved={() => {
              this.getDemandEvidences(this.state.modalDemandId);
            }}
            demandId={this.state.modalDemandId}
            evidences={this.state.modalDemandEvidences}
          />
        </ModalFrame>

        <Row>
          <Col>
            <DemandClassificationSelector
              searchMode={true}
              onChangeType={(type: string) => {
                this.setQuery({ tipo: type != "TODOS" ? type : null });
              }}
              onChangeClassification={classification => {
                const { query: tmp } = this.state;
                tmp.nivelClasificacionId = !!classification
                  ? classification.id
                  : null;
                this.setState({ query: { ...tmp } });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="3" lg="2">
            <ClubCatalogSelect
              club={this.state.query.clubEmisor}
              label="Club Emisor"
              clubs={this.props.clubs}
              onSelectClub={this.setClubEmisor}
            />
          </Col>
          <Col xs="12" md="3" lg="2">
            <ClubCatalogSelect
              club={this.state.query.clubResponsable}
              label="Club Responsable"
              clubs={this.props.clubs}
              onSelectClub={this.setClubResponsable}
            />
          </Col>
          <Col xs="12" md="3" lg="2">
            <BaseInput
              label="Criticidad"
              name="criticidadPeticion"
              type="select"
              options={[{ value: null, label: "TODOS" }]
                .concat(getEnumOptions(Criticidad))
                .map(({ value, label }) => {
                  return {
                    value: value || "",
                    label
                  };
                })}
              id="criticidadPeticion"
              placeholder="Criticidad"
              value={this.state.query.criticidad}
              onChange={this.setCriticidad}
              errors={[]}
            />
          </Col>
          <Col xs="12" md="3" lg="2">
            <BaseInput
              label="Estatus"
              name="estatusApertura"
              type="select"
              options={opcionesEstatus}
              id="estatusApertura"
              placeholder="Estatus"
              value={this.state.query.estatus}
              onChange={this.setEstatusApertura}
              errors={[]}
            />
          </Col>

          <Col xs="12" md="3" lg="2">
            <BaseInput
              label="Origen"
              name="origenPeticion"
              type="select"
              options={[{ value: null, label: "TODOS" }]
                .concat(getEnumOptions(OrigenPeticion))
                .map(({ value, label }) => {
                  return {
                    value: value || "",
                    label
                  };
                })}
              id="origenPeticion"
              placeholder="Tipo Peticion"
              value={this.state.query.origen}
              onChange={this.setOrigen}
              errors={[]}
            />
          </Col>
        </Row>

        <Form
          onSubmit={async e => {
            e.preventDefault();
            await this.setState({ page: 0 });
            this.onClickSearch();
          }}
        >
          <Row>
            <Col xs="12" md="3" lg="2">
              <ClubCatalogSelect
                club={this.state.query.clubBase}
                label="Club Base"
                clubs={this.props.clubs}
                onSelectClub={this.setClubBase}
              />
            </Col>
            <Col xs="12" md="3" lg="2">
              <BaseInput
                id="idPublicoMembresia"
                name="idPublicoMembresia"
                label="# Membresía"
                type="text"
                placeholder="# Membresía"
                value={this.state.query.membresiaPublicoId}
                onChange={this.setMembresiaPublicoId}
                errors={[]}
              />
            </Col>
            <Col xs="12" md="3" lg="2">
              <BaseInput
                label="Folio"
                name="folio"
                type="text"
                id="folio"
                placeholder="Folio"
                value={this.state.query.folio}
                onChange={this.setFolio}
                errors={[]}
              />
            </Col>
            <Col xs="12" md="3" lg="2">
              <DatePicker
                label="Desde"
                name="desde"
                id="desde"
                selected={this.state.query.desde}
                onChange={this.setQueryDate("desde")}
                errors={[]}
              />
            </Col>
            <Col xs="12" md="3" lg="2">
              <DatePicker
                label="Hasta"
                name="hasta"
                id="hasta"
                selected={this.state.query.hasta}
                onChange={this.setQueryDate("hasta")}
                errors={[]}
              />
            </Col>
            <Col xs={1}>
              <Button
                className={`${s.buttonMarginTop} ${s.primaryButton} mt-4`}
                type="submit"
                size="sm"
              >
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>

        <Row className="mt-4 mb-4">
          <Col xs={12} md={6}>
            <div className="text-center">ESTATUS RESPUESTA</div>
            <br />
            <Row>
              <Col className="text-center">
                <span className={`${s.captionElementContainer}`}>
                  <FontAwesomeIcon
                    className={`${s.captionElement}`}
                    style={{
                      color: getColorEstatusRespuesta(ESTATUS_ATENCION.VENCIDO)
                    }}
                    // className="w-100 h-100"
                    icon={faCircle}
                  />
                  <br />
                  {ESTATUS_ATENCION.VENCIDO}
                </span>
              </Col>
              <Col className="text-center">
                <span className={`${s.captionElementContainer}`}>
                  <FontAwesomeIcon
                    className={`${s.captionElement}`}
                    style={{
                      color: getColorEstatusRespuesta(ESTATUS_ATENCION.VIGENTE)
                    }}
                    icon={faCircle}
                  />
                  <br />
                  {ESTATUS_ATENCION.VIGENTE}
                </span>
              </Col>
              <Col className="text-center">
                <span className={`${s.captionElementContainer}`}>
                  <FontAwesomeIcon
                    className={`${s.captionElement}`}
                    style={{
                      color: getColorEstatusRespuesta(
                        ESTATUS_ATENCION.CERRADO_VENCIDO
                      )
                    }}
                    icon={faCircle}
                  />
                  <br />
                  {ESTATUS_ATENCION.CERRADO_VENCIDO}
                </span>
              </Col>
              <Col className="text-center">
                <span className={`${s.captionElementContainer}`}>
                  <FontAwesomeIcon
                    className={`${s.captionElement}`}
                    style={{
                      color: getColorEstatusRespuesta(
                        ESTATUS_ATENCION.CERRADO_EXITOSAMENTE
                      )
                    }}
                    icon={faCircle}
                  />
                  <br />
                  {ESTATUS_ATENCION.CERRADO_EXITOSAMENTE}
                </span>
              </Col>
              <Col className="text-center">
                <span className={`${s.captionElementContainer}`}>
                  <FontAwesomeIcon
                    className={`${s.captionElement}`}
                    style={{
                      color: getColorEstatusRespuesta(
                        ESTATUS_ATENCION.SIN_CLASIFICAR
                      )
                    }}
                    icon={faCircle}
                  />
                  <br />
                  {ESTATUS_ATENCION.SIN_CLASIFICAR}
                </span>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={1}></Col>
          <Col xs={12} md={5}>
            <div className="text-center">ESTATUS ENCUESTA CONTACTO</div>
            <br />
            <Row>
              <Col className="text-center">
                <span className={`${s.captionElementContainer}`}>
                  <FontAwesomeIcon
                    className={`${s.captionElement} ${s.faceGreen}`}
                    icon={getIconEstatusRespuesta(
                      ESTATUS_ENCUESTA.CONTESTO_CONTACTADO
                    )}
                  />
                  <br />
                  CONTESTO CONTACTADO
                </span>
              </Col>
              <Col className="text-center">
                <span className={`${s.captionElementContainer}`}>
                  <FontAwesomeIcon
                    className={`${s.captionElement}  ${s.faceRed}`}
                    icon={getIconEstatusRespuesta(
                      ESTATUS_ENCUESTA.CONTESTO_NO_CONTACTADO
                    )}
                  />
                  <br />
                  CONTESTO NO CONTACTADO
                </span>
              </Col>
              <Col className="text-center">
                <span className={`${s.captionElementContainer}`}>
                  <FontAwesomeIcon
                    className={`${s.captionElement} ${s.faceYellow}`}
                    icon={getIconEstatusRespuesta(
                      ESTATUS_ENCUESTA.ENCUESTA_ENVIADA
                    )}
                  />
                  <br />
                  ENCUESTA ENVIADA
                </span>
              </Col>
              <Col className="text-center">
                <span className={`${s.captionElementContainer}`}>
                  <FontAwesomeIcon
                    className={`${s.captionElement} ${s.faceOrange}`}
                    icon={getIconEstatusRespuesta(
                      ESTATUS_ENCUESTA.ENCUESTA_NO_CONTESTADA
                    )}
                  />
                  <br />
                  ENCUESTA NO CONTESTADA
                </span>
              </Col>
            </Row>
          </Col>
        </Row>

        <BaseCsvIconDownloader
          rowCount={(this.props.demands || {}).totalElements}
          executeReportDownload={getDemandsFileAxios}
          getFilters={() => {
            return this.getFiltersObject();
          }}
        />

        <ReactTable
          columns={this.columns}
          manual
          data={(this.props.demands || {}).content || []}
          pageSize={20}
          page={this.state.page}
          pages={(this.props.demands || {}).totalPages || 0}
          showPagination={true}
          showPageSizeOptions={false}
          loading={this.props.loadingDemands}
          className="mt-3"
          getTrProps={() => ({ style: { height: "60px" }, className: "mt-1" })}
          onFetchData={this.fetchData}
        />
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(NewDemand));
