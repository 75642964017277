import React, { useEffect } from "react";

const MitTpvFrame = ({ messageChannel }) => {
  const iFrameRef = React.useRef(null);

  useEffect(() => {
    iFrameRef.current.addEventListener("load", () => {
      // Transfer port2 to the iframe
      if (iFrameRef.current.contentWindow) {
        iFrameRef.current.contentWindow.postMessage("message", "*", [
          messageChannel.port2
        ]);
      }
    });
  }, []);

  return (
    <div>
      <iframe
        id="iframeMitPayment"
        src="https://m.mit.com.mx/pinpadWeb/ppw/index.html"
        width="0"
        height="0"
        style={{ display: "none" }}
        allow="serial;geolocation;"
        ref={iFrameRef}
      ></iframe>
    </div>
  );
};

export default MitTpvFrame;
