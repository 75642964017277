import React from "react";
import { Container, Row, Col, Input } from "reactstrap";
import { QuotationFilters } from "../QuotationFilters";
import { QuotationTotals } from "../QuotationTotals";
import QuotationTable from "../../components/QuotationTable";
import { getEmptyFilters } from "../../utils";
import { isObject } from "util";
import { Filters, QuotationInterface } from "../../types";
import { FORMA_DE_PAGO } from "../../../utils/constants";
import { Partner } from "../../../Home/components/partners/interfaces";
import MexicanCurrency from "../../../Home/components/common/currency";
import {
  errorNotification,
  infoNotification,
  REFERRAL_CODE_VALIDATION_PENDING
} from "../../../utils/notifications";
import { validatePartnerMembershipReferredCode } from "../../../Home/actions/partners";
import { handleRequestError } from "../../../utils/helpers";
import debounce from "lodash.debounce";
import constants from "../../../utils/constants";

enum REFERRAL_CODE_STATE {
  VALID,
  INVALID,
  DEFAULT
}

interface Props {
  disableAgreements: boolean;
  disablePromotions: boolean;
  disableDiscounts: boolean;
  disablePrices: boolean;
  disableReferredCode: boolean;
  quotation: QuotationInterface;
  enableMemberEdition: boolean;
  enableColumnEdition: boolean;
  quotationUse: string;
  checkMode: boolean;
  partner: Partner;
  onChangeFilters: (filters: Filters) => any;
  updateSelectedMonths: (months) => any;
  setStateReferral: (newStateReferral) => any;
  setCodigoReferido: (newCodigoReferido) => any;
}

export class Quotation extends React.Component<Props> {
  state = {
    filters: getEmptyFilters(),
    promotion: null,
    membership: null,
    maintenance: null,
    codigoReferido: null,
    codigoReferidoValidacion: null,
    stateReferral: REFERRAL_CODE_STATE.DEFAULT
  };

  onChangeFilters = values => {
    const { promocion, membresia, mantenimiento, ...rest } = values;
    let promotionObject = this.state.promotion;
    let membershipObject = this.state.membership;
    let maintenanceObject = this.state.maintenance;

    // let promotionId = this.state.filters.promocion;
    let promotionId = promocion;
    if (isObject(promocion)) {
      promotionObject = promocion;
      promotionId = promocion.id;
    } else if (promocion == "") {
      promotionObject = null;
    }

    let membershipId = this.state.filters.membresia;
    if (isObject(membresia)) {
      membershipObject = membresia;
      membershipId = membresia.datosBasicosMembresiaId;
    }

    // let promotionId = this.state.filters.promocion;
    let maintenanceId = mantenimiento;
    if (isObject(mantenimiento)) {
      maintenanceObject = mantenimiento;
      maintenanceId = mantenimiento.datosBasicosMantenimientoId;
    } else if (mantenimiento == "") {
      maintenanceObject = null;
    }

    const filters = {
      ...rest,
      promocion: promotionId,
      membresia: membershipId,
      mantenimiento: maintenanceId
    };

    this.setState({
      promotion: promotionObject,
      membership: membershipObject,
      maintenance: maintenanceObject,
      filters: filters
    });

    if (this.props.onChangeFilters) {
      this.props.onChangeFilters({
        ...filters,
        biclub: !!maintenanceObject && maintenanceObject.biclub,
        biclubImporteIncremento:
          !!maintenanceObject && maintenanceObject.biclubImporteIncremento
      });
    }
  };

  onChangeStateReferral = stateReferral => {
    this.setState({ stateReferral });
    if (this.props.setStateReferral) {
      this.props.setStateReferral(stateReferral);
    }
  };

  onChangeCodigoReferido = codigoReferido => {
    if (this.props.setCodigoReferido) {
      this.props.setCodigoReferido(codigoReferido);
    }
  };

  validateReferredCode = async (codigo: string) => {
    infoNotification(REFERRAL_CODE_VALIDATION_PENDING);
    if (!!codigo) {
      validatePartnerMembershipReferredCode(codigo)
        .then(({ data }) => {
          const isValid = !!(data || {}).valido;
          this.setState({
            codigoReferidoValidacion: isValid ? data : null
          });

          this.onChangeCodigoReferido(isValid ? data : null);
          this.onChangeStateReferral(
            isValid ? REFERRAL_CODE_STATE.VALID : REFERRAL_CODE_STATE.INVALID
          );

          if (!isValid) {
            errorNotification(
              (data.errores || ["El código de referido no es válido"]).join(
                "\n"
              )
            );
          }
        })
        .catch(err => {
          this.onChangeStateReferral(REFERRAL_CODE_STATE.INVALID);
          handleRequestError(err);
        });
    } else {
      this.onChangeStateReferral(REFERRAL_CODE_STATE.DEFAULT);
    }
  };

  validateReferredCodeDebounced = debounce(
    this.validateReferredCode,
    constants.DEBOUNCE_INPUT_TIME
  );

  render() {
    const { quotation, enableMemberEdition, enableColumnEdition } = this.props;
    const { filters, membership, promotion, maintenance } = this.state;
    const { descuento: formaPago } = filters;

    return (
      <Container className="w-100 p-0 my-3">
        {!this.props.disableReferredCode && (
          <Row>
            <Col md="4">
              <Container className="m-0 p-0 mb-4">
                <div className="input-group input-group-sm">
                  <div className="input-group-prepend">
                    <span className="input-group-text">Código de Referido</span>
                  </div>
                  <Input
                    id="codigoReferido"
                    label="Código de Referido"
                    name="codigoReferido"
                    type="text"
                    size="sm"
                    value={this.state.codigoReferido}
                    placeholder="Código de Referido"
                    className={`mr-2 ${
                      this.state.stateReferral == REFERRAL_CODE_STATE.DEFAULT
                        ? ""
                        : this.state.stateReferral == REFERRAL_CODE_STATE.VALID
                        ? "is-valid"
                        : "is-invalid"
                    }`}
                    onChange={e => {
                      const codigoReferido = e.target.value;
                      this.setState({ codigoReferido });
                      this.validateReferredCodeDebounced(codigoReferido);
                    }}
                  />
                </div>
              </Container>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={this.props.disablePrices ? 12 : 6} className="w-100 p-0 m-0">
            <QuotationFilters
              disableAgreements={this.props.disableAgreements}
              disablePromotions={this.props.disablePromotions}
              disableDiscounts={this.props.disableDiscounts}
              filters={filters}
              selectedPromotion={this.state.promotion}
              selectedMaintenance={this.state.maintenance}
              onChangeFilters={this.onChangeFilters}
              quotationUse={this.props.quotationUse}
              partner={this.props.partner}
            />
          </Col>
          {!this.props.disablePrices && (
            <Col xs={6} className="m-0 d-flex align-items-center">
              <QuotationTotals
                quotation={quotation}
                membership={membership}
                promocion={promotion}
              />
            </Col>
          )}
        </Row>
        <hr />
        {(formaPago == FORMA_DE_PAGO.TDC || formaPago == FORMA_DE_PAGO.TDD) && (
          <Row>
            <Col sm="12">
              <div className="alert alert-warning mt-4 mb-4">
                El precio reflejado es solo informativo. Dicho precio se verá
                reflejado una vez que se registre una forma de pago válida. (TDD
                / TDC)
              </div>
            </Col>
          </Row>
        )}

        {!!maintenance && !!maintenance.biclub && (
          <Row>
            <Col sm="12">
              <div className="alert alert-warning mt-4 mb-4">
                Para el mantenimiento BICLUB prevalecerá como cuota de
                mantenimiento mensual aquella del club cuyo precio de lista sea
                más alto más&nbsp;
                <MexicanCurrency
                  quantity={maintenance.biclubImporteIncremento || 0}
                />{" "}
                .
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12} className="w-100 m-0">
            <QuotationTable
              enableMemberEdition={enableMemberEdition}
              enableColumnEdition={enableColumnEdition}
              quotation={quotation}
              checkMode={this.props.checkMode}
              updateSelectedMonths={months => {
                if (!!this.props.updateSelectedMonths) {
                  this.props.updateSelectedMonths([...months]);
                }
              }}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
