import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import ReactTable from "react-table";
import { Input, Button, Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import parseInt from "lodash.parseint";
import isFinite from "lodash.isfinite";
import take from "lodash.take";
import takeWhile from "lodash.takewhile";
import zip from "lodash.zip";
import BaseInput from "../../../toolkit/baseInput";

const INITIAL_NUMBER_INDICATORS = 5;

interface Props {
  indicators: {
    nombre: string;
    id: number;
  }[];
  saveComissionsSchemas: (schema: COMISSION_SCHEMA) => any;
  getSchemaById: (id: string) => any;
  schemaById: COMISSION_SCHEMA;
  match: any;
}

interface REQUEST_INDICATOR {
  idColumna: number;
  indicadorId: number;
  maximo: number;
  maximoActivo: boolean;
  minimo: number;
  minimoActivo: boolean;
  orden: number;
  rollup: boolean;
}

interface SEGMENT {
  beneficioMontoAbsoluto: number;
  beneficioMontoPorVenta: number;
  beneficioPorcentajeVentas: number;
  numeroSegmento: number;
  indicadores: REQUEST_INDICATOR[];
}

interface COMISSION_SCHEMA {
  nombre: string;
  tipoEsquema: string;
  esquemaId: number;
  activo: boolean;
  esquema: SEGMENT[];
}

interface COLUMN {
  columns?: COLUMN[];
  accessor?: any;
  width?: any;
  className?: any;
  headerClassName?: any;
  Header?: any;
}

interface STATE_INDICATOR {
  id: number;
  indicadorId: number;
  minimo: string;
  maximo: string;
  minimoActivo: boolean;
  maximoActivo: boolean;
  rollup: boolean;
}

interface STATE_SEGMENT {
  numeroSegmento: number;
  beneficioMontoAbsoluto: number;
  beneficioPorcentajeVentas: number;
  beneficioMontoPorVenta: number;
  indicadores: STATE_INDICATOR[];
}

interface State {
  segments: STATE_SEGMENT[];
  activo: boolean;
  name: string;
  comissionSchemaType: string;
  columns: COLUMN[];
  indicators: { indicatorSelected: number }[];
  loadSchemaById: boolean;
  schemaId: number;
}

const ComissionSchemaTypes = [
  { value: "VENTA", label: "Venta" },
  { value: "REACTIVACION", label: "Reactivación" }
];

const DEFAULT_INDICATOR_ID = -1;

const getEmptyIndicator = (
  indicadorId = DEFAULT_INDICATOR_ID
): STATE_INDICATOR => ({
  id: null,
  indicadorId: indicadorId,
  minimo: "",
  maximo: "",
  minimoActivo: false,
  maximoActivo: false,
  rollup: false
});

class BenefitsSchema extends React.Component<Props, State> {
  state = {
    loadSchemaById: false,
    schemaId: null,
    indicators: [],
    activo: true,
    name: "",
    comissionSchemaType: "",
    segments: [
      {
        numeroSegmento: 1,
        beneficioMontoAbsoluto: 0,
        beneficioPorcentajeVentas: 0,
        beneficioMontoPorVenta: 0,
        indicadores: []
      }
    ],
    columns: [
      {
        Header: () => (
          <div className="container px-2">
            <div className="row m-0">
              <div className="p-0 col">
                <Button
                  color="link"
                  onClick={() => {
                    this.addNewSegment();
                  }}
                  className={`${s.actionHeaderIcon} p-0`}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </div>
              <div className="p-0 col">
                <Button
                  color="link"
                  onClick={() => {
                    this.deleteLastSegment();
                  }}
                  className={`${s.deleteHeaderIcon} p-0`}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              </div>
            </div>
          </div>
        ),
        accessor: "numeroSegmento",
        width: 70,
        className: "text-center",
        headerClassName: "p-0"
      },
      {
        Header: <p className="m-0 pt-1">Beneficios</p>,
        columns: [
          {
            Header: "Monto Abs.",
            accessor: "beneficioMontoAbsoluto",
            Cell: data =>
              this.renderInputNumber(data, "beneficioMontoAbsoluto"),
            width: 100
          },
          {
            Header: "% de Ventas",
            accessor: "beneficioPorcentajeVentas",
            Cell: data =>
              this.renderInputNumber(data, "beneficioPorcentajeVentas"),
            width: 100
          },
          {
            Header: "Monto Venta",
            accessor: "beneficioMontoPorVenta",
            Cell: data =>
              this.renderInputNumber(data, "beneficioMontoPorVenta"),
            width: 100
          }
        ]
      },
      {
        Header: () => (
          <div className="container-fluid">
            <div className="row">
              <div className="col-1 text-left">
                <Button
                  color="link"
                  onClick={() => {
                    this.addNewIndicator(1);
                  }}
                  className={`${s.actionHeaderIcon} p-0`}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </div>
              <div className="col text-center pt-1">
                <span>Indicadores</span>
              </div>
            </div>
          </div>
        ),
        accessor: "numeroSegmento",
        className: "p-0",
        columns: []
      }
    ]
  };

  componentDidMount() {
    this.addNewIndicator(INITIAL_NUMBER_INDICATORS);

    const { id: schemaId } = this.props.match.params;
    if (schemaId) {
      this.props.getSchemaById(schemaId);
    }
  }

  componentDidUpdate({ schemaById: prevStateSchemaById }) {
    const lastHeaderColumns = this.state.columns[this.state.columns.length - 1][
      "columns"
    ];

    if (!prevStateSchemaById && this.props.schemaById) {
      const firstSegment: { indicadores: { indicadorId: number }[] } = this
        .props.schemaById.esquema[0];
      const missingIndicators =
        firstSegment.indicadores.length - lastHeaderColumns.length;
      if (missingIndicators > 0) {
        this.addNewIndicator(missingIndicators);
      }
      this.setState({
        loadSchemaById: true
      });
    }

    if (this.state.loadSchemaById) {
      const firstSegment: { indicadores: { indicadorId: number }[] } = this
        .props.schemaById.esquema[0];

      this.setState({
        loadSchemaById: false,
        schemaId: this.props.schemaById.esquemaId,
        name: this.props.schemaById.nombre,
        comissionSchemaType: this.props.schemaById.tipoEsquema,
        segments: zip(this.state.segments, this.props.schemaById.esquema).map(
          ([emptySegment, segmentSaved]) => {
            return segmentSaved ? segmentSaved : emptySegment;
          }
        ),
        indicators: zip(this.state.indicators, firstSegment.indicadores).map(
          ([emptyIndicator, indicatorSaved]) =>
            indicatorSaved
              ? {
                  indicatorSelected: indicatorSaved.indicadorId
                }
              : emptyIndicator
        )
      });
    }
  }

  onChangeIndicator = (index, value) => {
    const indicators = [...this.state.indicators];
    const segments = [...this.state.segments];
    const intValue = parseInt(value);

    segments.forEach(segments => {
      segments.indicadores[index] = {
        ...segments.indicadores[index],
        indicadorId: intValue
      };
    });

    indicators[index] = {
      indicatorSelected: intValue
    };

    this.setState({ indicators, segments });
  };

  addNewIndicator = (q: number) => {
    const lastHeader: any = this.state.columns[this.state.columns.length - 1];
    const columns = [...this.state.columns];
    const indicators = [...this.state.indicators];
    const segments = [...this.state.segments];

    for (let i = 0; i < q; i++) {
      const newIndicator = { indicatorSelected: DEFAULT_INDICATOR_ID };
      indicators.push(newIndicator);
      const index = indicators.length - 1;

      segments.forEach(segment => {
        segment.indicadores.push(getEmptyIndicator());
      });

      lastHeader.columns.push({
        Header: () => (
          <div>
            <select
              onChange={event => {
                this.onChangeIndicator(index, event.target.value);
              }}
              disabled={
                !take(this.state.indicators, index).every(
                  ({ indicatorSelected }) =>
                    indicatorSelected !== DEFAULT_INDICATOR_ID
                )
              }
              value={
                this.state.indicators[index]
                  ? this.state.indicators[index].indicatorSelected
                  : DEFAULT_INDICATOR_ID
              }
              style={{
                width: "90%"
              }}
            >
              <option value={DEFAULT_INDICATOR_ID}>Sel. Indicador</option>
              {this.props.indicators.map(indicator => (
                <option value={indicator.id} key={indicator.id}>
                  {indicator.nombre}
                </option>
              ))}
            </select>
          </div>
        ),
        accessor: "numeroSegmento",
        width: 154,
        Cell: ({ index: rowIndex }) => (
          <div className="container p-0">
            <div className="row m-0">
              <div className="col p-0">
                {this.renderInputCellIndicator(
                  rowIndex,
                  index,
                  "minimo",
                  "minimoActivo"
                )}
              </div>
              <div className="col p-0">
                {this.renderInputCellIndicator(
                  rowIndex,
                  index,
                  "maximo",
                  "maximoActivo"
                )}
              </div>
            </div>
            <div className="row m-0">
              {this.renderInputCellRollup(rowIndex, index)}
            </div>
          </div>
        )
      });
    }

    this.setState({ columns, indicators, segments });
  };

  renderInputCellRollup = (rowIndex, index) => {
    const validSegment =
      this.state.segments[rowIndex] &&
      this.state.segments[rowIndex].indicadores[index];

    return (
      <Label check className="ml-4 mt-1">
        <input
          type="checkbox"
          style={{ position: "static" }}
          disabled={
            this.state.indicators[index].indicatorSelected ===
            DEFAULT_INDICATOR_ID
          }
          checked={
            validSegment
              ? this.state.segments[rowIndex].indicadores[index].rollup
              : false
          }
          onChange={e => {
            const rollup = e.target.checked;
            const segments = [...this.state.segments];
            segments[rowIndex].indicadores[index].rollup = rollup;
            this.setState({
              segments
            });
          }}
        />{" "}
        Rollup?
      </Label>
    );
  };

  renderInputCellIndicator = (rowIndex, index, key, validKey) => {
    const validSegment =
      this.state.segments[rowIndex] &&
      this.state.segments[rowIndex].indicadores[index];

    return (
      <Input
        type="number"
        className={`${s.renderEditableInput} p-1`}
        disabled={
          this.state.indicators[index].indicatorSelected ===
          DEFAULT_INDICATOR_ID
        }
        value={
          validSegment
            ? this.state.segments[rowIndex].indicadores[index][key]
            : ""
        }
        onChange={e => {
          const value = e.target.value;
          const segments = [...this.state.segments];
          segments[rowIndex].indicadores[index][key] = value;
          segments[rowIndex].indicadores[index][validKey] = isFinite(
            parseInt(value)
          );
          this.setState({
            segments
          });
        }}
      />
    );
  };

  addNewSegment = () => {
    const segments = [...this.state.segments];
    segments.push({
      numeroSegmento: segments.length + 1,
      beneficioMontoAbsoluto: 0,
      beneficioPorcentajeVentas: 0,
      beneficioMontoPorVenta: 0,
      indicadores: this.state.indicators.map(({ indicatorSelected }) =>
        getEmptyIndicator(indicatorSelected)
      )
    });
    this.setState({ ...this.state, segments });
  };

  deleteLastSegment = () => {
    const segments = [...this.state.segments];
    if (this.state.segments.length > 1) {
      segments.pop();
      this.setState({ ...this.state, segments });
    }
  };

  renderInputNumber = ({ value, index }, id) => {
    return (
      <Input
        type="number"
        style={{
          height: "50%"
        }}
        className={`${s.renderEditableInput}`}
        value={value}
        min={0}
        onChange={e => {
          const value = e.target.value;
          const segments = [...this.state.segments];
          segments[index][id] = value;
          this.setState({ ...this.state, segments });
        }}
      />
    );
  };

  validSchema = () => {
    let valid =
      this.state.indicators[0] &&
      this.state.indicators[0].indicatorSelected !== DEFAULT_INDICATOR_ID;

    valid = valid && !!this.state.name && !!this.state.comissionSchemaType;

    if (valid) {
      this.state.segments.forEach(segment => {
        valid =
          valid &&
          (segment.beneficioMontoAbsoluto !== 0 ||
            segment.beneficioPorcentajeVentas !== 0 ||
            segment.beneficioMontoPorVenta !== 0);
      });
    }

    return valid;
  };

  onSubmit = e => {
    e.preventDefault();
    const payload = {
      esquemaId: this.state.schemaId,
      nombre: this.state.name,
      tipoEsquema: this.state.comissionSchemaType,
      activo: this.state.activo,
      esquema: this.state.segments.map(segment => {
        const segmentFiltered = {
          ...segment,
          indicadores: takeWhile(
            segment.indicadores,
            ({ indicadorId }) => indicadorId !== DEFAULT_INDICATOR_ID
          ).map((indicador, orden) => ({ ...indicador, orden: orden + 1 }))
        };
        return segmentFiltered;
      })
    };

    this.props.saveComissionsSchemas(payload);
  };

  render() {
    return (
      <div className="container p-0 mt-3">
        <div className="row">
          <div className="col-8">
            <BaseInput
              label="Nombre"
              name="benefitsSchemaName"
              type="text"
              id="benefitsSchemaName"
              placeholder="Nombre del esquema de beneficios"
              value={this.state.name}
              onChange={e => {
                e.preventDefault();
                const name = e.target.value;
                this.setState({ name });
              }}
            />
          </div>
          <div className="col-2">
            <BaseInput
              label="Tipo"
              name="comissionSchemaType"
              type="select"
              id="comissionSchemaType"
              value={this.state.comissionSchemaType}
              options={[{ value: "", label: "Seleccione un tipo" }].concat(
                ComissionSchemaTypes
              )}
              onChange={e => {
                const comissionSchemaType = e.target.value;
                this.setState({ comissionSchemaType });
              }}
            />
          </div>
          <div className="col pt-4 pl-5">
            <Label>
              <Input
                name="activo"
                type="checkbox"
                id="comissionsSchemaActive"
                checked={this.state.activo}
                onChange={e => {
                  const activo = e.target.checked;
                  this.setState({ activo });
                }}
              />
              Activo
            </Label>
          </div>
        </div>
        <div className="row m-0">
          <ReactTable
            data={this.state.segments}
            columns={this.state.columns}
            sortable={false}
            showPagination={false}
            resizable={false}
            className="-highlight w-100"
            defaultPageSize={15}
            pageSize={(this.state.segments || []).length + 2}
            getTbodyProps={() => ({ style: { backgroundColor: "#EAECEE" } })}
            getTrProps={() => ({ style: { height: "60px" } })}
          />
        </div>
        <div className="row">
          <div className="col" />
          <div className="col-2">
            <Button
              className={`mt-3 ${s.primaryButton}`}
              onClick={e => this.onSubmit(e)}
              disabled={!this.validSchema()}
              size="sm"
              block
            >
              Guardar
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(s)(BenefitsSchema));
