export enum PRICE_TYPES {
  PORCENTAJE = "PORCENTAJE",
  DESCUENTOABS = "DESCUENTOABS",
  PRECIOFIJO = "PRECIOFIJO",
  DIAS_FIJO = "DIAS_FIJO",
  NA = "NA"
}

export interface BENEFITS {
  integrante: number;
  valor: number;
}

export interface PROMOTION_MONTH_PRICE {
  tipoBeneficioMes: PRICE_TYPES;
  mes: number;
  posicion: number;
  esUltimoMesRecurrente: boolean;
  beneficios: BENEFITS[];
}

export interface PROMOTION_PRICE {
  promocion: string;
  membresia: string;
  numeroDeIntegrantes: number;
  tipoBeneficioMembresia: PRICE_TYPES;
  valorBeneficioMembresia: number;
  aplicaProporcional: boolean;
  mesRelativo: boolean;
  meses: PROMOTION_MONTH_PRICE[];
  invitadosGratisEspejo: boolean;
  invitadosGratisPorCumpleanos: boolean;
  invitadosGratis: number;
  invitadosGratisFechaInicioPeriodo: string;
  invitadosGratisFechaFinPeriodo: string;
  invitadosGratisMeses: number;
  pasesInvitadoGratis: number;
  ninosGratis: number;
}

export enum TYPE_OPTIONS {
  ONLY_AGREEMENTS = "SOLO_APLICA_A_A_CONVENIOS",
  SOME_AGREEMENTS = "APLICA_A_TODOS_CONVENIOS",
  NO_AGREEMENTS = "NO_APLICA_A_CONVENIOS"
}

export enum TYPE_PROMOTION_USE {
  VENTA = "VENTA",
  REACTIVACION = "REACTIVACION"
}
