import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { setChildStep } from "../../helpers/stepper-state-comparator";
import {
  Container,
  Col,
  Button,
  Row,
  Input,
  UncontrolledTooltip
} from "reactstrap";
import s from "./styles.scss";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import { withRouter } from "react-router-dom";
import QuizzesList from "./quizzesList";
import ActionsQuizSelected from "./actionsQuizSelected";
import FormSearchQuizzes from "./formSearchQuizzes";
import IAction from "../../../types/IAction";
import find from "lodash.find";

interface Props {
  addQuiz: (data: object) => IAction;
  getQuizzes: (data: object) => IAction;
  getQuizzesQuestionsById: (id: number) => IAction;
  listQuizzes: object;
  listQuestionsQuizzes: any;
  quizzCategories: { id: number; nombre: string; activa: boolean }[];
}

interface State {
  isOpen: boolean;
  toDo: string;
  fields: object;
  fieldsActions: object;
  quizzesList: Array<{
    orden: number;
    pregunta: string;
    valor: number;
    tipo: string;
    categoria: string;
    estatus: string;
  }>;
  errorValIntPreguntas: string;
  errorValIntTotalPreguntas: string;
  errorOrdenInt: string;
}

class QuizzesSection extends React.Component<Props, State> {
  state = {
    quizzesList: [],
    isOpen: false,
    toDo: null,
    fields: {
      nombre: "",
      identificador: "",
      categoria: "",
      estatus: ""
    },
    fieldsActions: {
      nombre: "",
      identificador: "",
      encuestaCategoriaId: "",
      estatus: ""
    },
    errorValIntPreguntas: null,
    errorValIntTotalPreguntas: null,
    errorOrdenInt: null
  };

  toggleModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  toActionsQuiz = (dataQuiz, toDo) => {
    if (toDo === "Editar") {
      this.props.getQuizzesQuestionsById(dataQuiz.id);
    } else {
      this.setState({
        quizzesList: dataQuiz.preguntas
      });
    }

    this.setState({ toDo });

    this.setState(state => {
      const fieldsActions = {
        ...state.fieldsActions,
        nombre: dataQuiz.nombre,
        identificador: dataQuiz.identificador,
        encuestaCategoriaId: dataQuiz.encuestaCategoriaId,
        estatus: dataQuiz.estatus
      };

      return { ...state, error: {}, fieldsActions };
    });

    this.toggleModal();
  };

  setValue = (index, id, value) => {
    const quizzesList = [...this.state.quizzesList];
    quizzesList[index][id] = value;
    setChildStep(quizzesList);
    this.setState({ ...this.state, quizzesList });
  };

  addEmptyQuiz = e => {
    e.preventDefault();
    const quizzesList = [...this.state.quizzesList];
    quizzesList.push({
      orden: "",
      pregunta: "",
      valor: "",
      tipo: "Opinión",
      categoria: "Demográficos",
      estatus: "Activo"
    });

    setChildStep(quizzesList);
    this.setState({ quizzesList });
  };

  removeQuiz = quiz => {
    const quizzesList = this.state.quizzesList.filter(q => quiz !== q);
    setChildStep(quizzesList);
    this.setState(state => {
      return {
        ...state,
        quizzesList
      };
    });
  };

  renderInputText = ({ index, column: { id } }) => {
    return (
      <Container className="my-1">
        <Row className="mt-1">
          <Col className="px-1">
            <Input
              type="text"
              className={`${s.renderEditableInput}`}
              value={this.state.quizzesList[index][id]}
              onChange={e => {
                const value = e.target.value;
                this.setValue(index, id, value);
              }}
            />
          </Col>
        </Row>
      </Container>
    );
  };

  renderActions = quiz => {
    return (
      <div className="w-100 text-center">
        <Button
          color="link"
          onClick={() => this.removeQuiz(quiz)}
          className={`${s.actionHeaderIcon} ${s.deleteAnchor}`}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </div>
    );
  };

  renderInputSelect = (options, nameKey = null, idKey = null) => ({
    index,
    column: { id }
  }) => {
    return (
      <Container className="my-1">
        <UncontrolledTooltip placement="left" target={`selector${index}${id}`}>
          {nameKey
            ? find(
                options,
                option => this.state.quizzesList[index][id] == option[idKey]
              )[nameKey]
            : this.state.quizzesList[index][id]}
        </UncontrolledTooltip>
        <Row className="mt-1">
          <Col className="px-1">
            <Input
              name="memberTypeSelector"
              type="select"
              id={`selector${index}${id}`}
              className={`${s.renderEditableInput}`}
              value={this.state.quizzesList[index][id]}
              onChange={e => {
                const value = e.target.value;
                this.setValue(index, id, value);
              }}
              style={{ height: "2rem" }}
            >
              {nameKey
                ? options.map(opt => (
                    <option key={opt[idKey]} value={opt[idKey]}>
                      {opt[nameKey]}
                    </option>
                  ))
                : options.map(opt => (
                    <option key={opt} value={opt}>
                      {opt}
                    </option>
                  ))}
            </Input>
          </Col>
        </Row>
      </Container>
    );
  };

  onChangeFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };

        return { ...state, error: {}, fields };
      });
    };
  };

  onChangeFactoryActions = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fieldsActions = {
          ...state.fieldsActions,
          [fieldName]: value
        };

        return { ...state, error: {}, fieldsActions };
      });
    };
  };

  searchQuizzes = e => {
    e.preventDefault();
    const { fields } = this.state;
    this.props.getQuizzes({
      nombre: fields.nombre,
      identificador: fields.identificador,
      categoria: fields.categoria,
      estatus: fields.estatus
    });
  };

  saveQuiz = e => {
    e.preventDefault();
    this.setState({
      errorValIntPreguntas: null,
      errorValIntTotalPreguntas: null,
      errorOrdenInt: null
    });

    let quizzesList = this.state.quizzesList;

    let suma = 0;
    let isIntegerValor = true;
    let isIntegerOrden = true;

    quizzesList.map(quiz => {
      switch (quiz.tipo) {
        case "Opinión":
          quiz.tipo = "OPINION";
          break;
        case "Percepción":
          quiz.tipo = "PERCEPCION";
          break;
        default:
          break;
      }

      switch (quiz.categoria) {
        case "Demográficos":
          quiz.categoria = "DEMOGRAFICOS";
          break;
        case "Autonomía":
          quiz.categoria = "AUTONOMIA";
          break;
        case "Cohesión y trabajo en equipo":
          quiz.categoria = "COHESION_TRABAJO_EQUIPO";
          break;
        case "Limpieza":
          quiz.categoria = "LIMPIEZA";
          break;
        case "Comunicación":
          quiz.categoria = "COMUNICACION";
          break;
        case "Dirección y liderazgo":
          quiz.categoria = "DIRECCION_LIDERAZGO";
          break;
        case "Pertenencia":
          quiz.categoria = "PERTENENCIA";
          break;
        case "Planeación, organización y calidad":
          quiz.categoria = "PLANEACION_ORGANIZACION_CALIDAD";
          break;
        case "Reconocimiento y compenzación":
          quiz.categoria = "RECONOCIMIENTO_COMPENSACION";
          break;
        case "Atención":
          quiz.categoria = "ATENCION";
          break;
        case "Servicio":
          quiz.categoria = "SERVICIO";
          break;
        case "Equipamiento":
          quiz.categoria = "EQUIPAMIENTO";
          break;
        case "Instalación":
          quiz.categoria = "INSTALACION";
          break;
        case "Evaluación":
          quiz.categoria = "EVALUACION";
          break;
        default:
          break;
      }

      quiz.estatus = quiz.estatus.toUpperCase();

      isIntegerValor = Number.isInteger(parseInt(quiz.valor));
      isIntegerOrden = Number.isInteger(parseInt(quiz.orden));

      if (!isIntegerValor) {
        this.setState({
          errorValIntPreguntas: `El valor ${quiz.valor} no es un número entero`
        });
        return;
      }

      if (!isIntegerOrden) {
        this.setState({
          errorOrdenInt: `El orden ${quiz.orden} no es un número entero`
        });
        return;
      }
      suma += parseInt(quiz.valor);
    });

    const { toDo, fieldsActions } = this.state;

    if (suma === 100) {
      switch (toDo) {
        case "Editar":
          this.props.addQuiz({
            id: this.props.listQuestionsQuizzes.id,
            nombre: fieldsActions.nombre,
            identificador: fieldsActions.identificador,
            encuestaCategoriaId: fieldsActions.encuestaCategoriaId,
            estatus: fieldsActions.estatus,
            encuestaPreguntas: quizzesList
          });
          this.toggleModal();
          break;
        case "Agregar":
          this.props.addQuiz({
            nombre: fieldsActions.nombre,
            identificador: fieldsActions.identificador,
            encuestaCategoriaId: fieldsActions.encuestaCategoriaId,
            estatus: fieldsActions.estatus,
            encuestaPreguntas: quizzesList
          });
          this.toggleModal();
          break;
        default:
          break;
      }
    } else {
      this.setState({
        errorValIntTotalPreguntas: `La suma de los valores en las preguntas debe ser igual a 100`
      });
    }
  };

  opcionesCategoria = [
    { value: "", label: "Seleccionar" },
    { value: "SATISFACCION", label: "Satisfacción" }
  ];

  getOpcionesCategoria = () => {
    const options = (this.props.quizzCategories || []).map(x => {
      return { value: x.id, label: x.nombre };
    });
    return [{ value: "", label: "Seleccionar" }, ...options];
  };

  opcionesEstatus = [
    { value: "", label: "Seleccionar" },
    { value: "ACTIVO", label: "Activo" },
    { value: "INACTIVO", label: "Inactivo" },
    { value: "ELIMINADO", label: "Eliminado" }
  ];

  componentDidMount() {
    this.props.getQuizzes({
      nombre: "",
      identificador: "",
      categoria: "",
      estatus: ""
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.listQuestionsQuizzes) {
      if (nextProps.listQuestionsQuizzes !== null) {
        let encuestaPreguntas =
          nextProps.listQuestionsQuizzes.encuestaPreguntas;

        encuestaPreguntas.map(encuesta => {
          switch (encuesta.tipo) {
            case "OPINION":
              encuesta.tipo = "Opinión";
              break;
            case "PERCEPCION":
              encuesta.tipo = "Percepción";
              break;
            default:
              break;
          }

          switch (encuesta.categoria) {
            case "DEMOGRAFICOS":
              encuesta.categoria = "Demográficos";
              break;
            case "AUTONOMIA":
              encuesta.categoria = "Autonomía";
              break;
            case "COHESION_TRABAJO_EQUIPO":
              encuesta.categoria = "Cohesión y trabajo en equipo";
              break;
            case "LIMPIEZA":
              encuesta.categoria = "Limpieza";
              break;
            case "COMUNICACION":
              encuesta.categoria = "Comunicación";
              break;
            case "DIRECCION_LIDERAZGO":
              encuesta.categoria = "Dirección y liderazgo";
              break;
            case "PERTENENCIA":
              encuesta.categoria = "Pertenencia";
              break;
            case "PLANEACION_ORGANIZACION_CALIDAD":
              encuesta.categoria = "Planeación, organización y calidad";
              break;
            case "RECONOCIMIENTO_COMPENSACION":
              encuesta.categoria = "Reconocimiento y compenzación";
              break;
            case "ATENCION":
              encuesta.categoria = "Atención";
              break;
            case "SERVICIO":
              encuesta.categoria = "Servicio";
              break;
            case "EQUIPAMIENTO":
              encuesta.categoria = "Equipamiento";
              break;
            case "INSTALACION":
              encuesta.categoria = "Instalación";
              break;
            case "EVALUACION":
              encuesta.categoria = "Evaluación";
              break;
            default:
              break;
          }

          switch (encuesta.tipo) {
            case "Activo":
              encuesta.tipo = "Activo";
              break;
            case "INACTIVO":
              encuesta.tipo = "inactivo";
              break;
            case "ELIMINADO":
              encuesta.tipo = "eliminado";
              break;
            default:
              break;
          }
        });

        this.setState({
          quizzesList: encuestaPreguntas
        });
      }
    }
  }

  render() {
    return (
      <Container className="p-0 pt-3">
        <ActionsQuizSelected
          toDo={this.state.toDo}
          isOpen={this.state.isOpen}
          quizzesList={this.state.quizzesList}
          errorValIntPreguntas={this.state.errorValIntPreguntas}
          errorValIntTotalPreguntas={this.state.errorValIntTotalPreguntas}
          errorOrdenInt={this.state.errorOrdenInt}
          fieldsActions={this.state.fieldsActions}
          opcionesCategoria={this.getOpcionesCategoria()}
          opcionesEstatus={this.opcionesEstatus}
          toggleModal={this.toggleModal}
          addEmptyQuiz={this.addEmptyQuiz}
          renderInputText={this.renderInputText}
          renderInputSelect={this.renderInputSelect}
          renderActions={this.renderActions}
          onChangeFactoryActions={this.onChangeFactoryActions}
          saveQuiz={this.saveQuiz}
          listQuestionsQuizzes={this.props.listQuestionsQuizzes}
        />
        <FormSearchQuizzes
          fields={this.state.fields}
          opcionesCategoria={this.getOpcionesCategoria()}
          opcionesEstatus={this.opcionesEstatus}
          onChangeFactory={this.onChangeFactory}
          searchQuizzes={this.searchQuizzes}
        />
        <Row>
          <Col>
            <QuizzesList
              toActionsQuiz={this.toActionsQuiz}
              listQuizzes={this.props.listQuizzes ? this.props.listQuizzes : []}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(QuizzesSection));
