import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Container } from "reactstrap";
import s from "./styles.scss";
import Layout from "../layout";

const NOT_ALLOWED_TEXT =
  "No posee acceso a esta vista. Por favor, contacte al administrador.";
const NOT_FOUND_TEXT =
  "La vista a la que intenta acceder no existe. Por favor, contacte al administrador.";
const ERROR_MOBILE_TEXT =
  "El Sistema está restringido para su operación en celulares, tabletas o cualquier otro dispositivo móvil.";

const getErrorLayout = ({ message }) => (
  <Layout>
    <Container className="p-0 mt-3">
      <div
        className="alert alert-danger"
        style={{ padding: "6px 8px" }}
        role="alert"
      >
        <h4 className="alert-heading">ERROR</h4>
        <p className="m-0">{message}</p>
      </div>
    </Container>
  </Layout>
);

const getExportableLayout = ({ component: Component, ...rest }) =>
  withStyles(s)(() => <Component {...rest} />);

export const RouteNotAllowedError = getExportableLayout({
  component: getErrorLayout,
  message: NOT_ALLOWED_TEXT
});

export const RouteNotFoundError = getExportableLayout({
  component: getErrorLayout,
  message: NOT_FOUND_TEXT
});

export const RouteMobileError = getExportableLayout({
  component: getErrorLayout,
  message: ERROR_MOBILE_TEXT
});
