import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import { Container } from "reactstrap";
import IAction from "../../../types/IAction";
import s from "./styles.scss";
import SportsProgramsComissionsTable from "../../components/sportsProgramsComissionsTable";
import SportsProgramSearchReport from "../sportsProgramSearchReport";

interface Props {
  getSportsProgramCommissions: (id: string) => IAction;
  sportProgramsProducts: object[];
  sportsProgramCommisions: object;
  clubs: object[];
  sellers: object[];
  getPrograms: (fields: object) => IAction;
  sportsProducts: { id: string; nombre: string }[];
  classificationByLevel: object[];
  getSportsProductsFiltered: (args: object) => any;
  sportsProgramsInstances: object[];
  downloadSportsProgramCommissions: (args: object) => IAction;
}
interface State {
  selectedProgram: object;
  selectedClients: object[];
}

class SportProgramComissions extends React.Component<Props, State> {
  state = {
    selectedProgram: null,
    selectedClients: null
  };

  selectProgram = (data: object) => {
    this.setState({ selectedProgram: data });
    this.props.getSportsProgramCommissions(data.programaDeportivoId);
  };

  render() {
    let programaDeportivoId = null;
    if (this.state.selectedProgram !== null) {
      programaDeportivoId = this.state.selectedProgram.programaDeportivoId;
    }

    return (
      <Container className="p-0 mt-3">
        <SportsProgramSearchReport
          programs={this.props.sportsProducts}
          clubs={this.props.clubs}
          sellers={this.props.sellers}
          getPrograms={this.props.getPrograms}
          classificationByLevel={this.props.classificationByLevel}
          getSportsProductsFiltered={this.props.getSportsProductsFiltered}
          selectProgram={this.selectProgram}
          selectedProgram={this.state.selectedProgram}
          sportsProgramsInstances={this.props.sportsProgramsInstances}
          sportProgramsProducts={this.props.sportProgramsProducts}
          downloadSportsProgramCommissions={
            this.props.downloadSportsProgramCommissions
          }
        />
        {programaDeportivoId !== null ? (
          <SportsProgramsComissionsTable
            sportsProgramCommisions={this.props.sportsProgramCommisions}
            programaDeportivoId={programaDeportivoId}
          />
        ) : null}
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(SportProgramComissions));
