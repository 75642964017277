import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Container, Input, Row, Col, Button } from "reactstrap";
import ReactTable from "react-table";
import BaseInput from "../../../toolkit/baseInput";
import MexicanCurrency from "../common/currency";
import SelectFilter from "../common/SelectFilter";
import moment from "moment";
import isNil from "lodash.isnil";
interface Props {
  getAnnuityQuote: (filters: object) => void;
  saveAnnuityQuote: (data: object) => void;
  loading: boolean;
  quote: any;
  clubs: Club[];
  annuityTypes: Catalog[];
  waysPay: Catalog[];
  users: { userId: number; name: string; firstSurname: string }[];
  banksMonthsWithoutInterest: Banks[];
}

interface Club {
  clubId: string;
  nombre: string;
}

interface Banks {
  mesesSinInteresesId: string;
  bancoId: string;
  nombre: string;
  tresMeses: string;
  estatusTresMeses: boolean;
  seisMeses: string;
  estatusSeisMeses: boolean;
  nueveMeses: string;
  estatusNueveMeses: boolean;
  doceMeses: string;
  estatusDoceMeses: boolean;
}

interface Catalog {
  id: string;
  nombre: string;
}

class AnnuitiesQuote extends React.Component<Props> {
  state = {
    filters: {
      membresiaSocioIdPublico: "",
      membresiaSocioClub: "",
      tipoAnualidad: "",
      anio: "",
      formaPago: "",
      mesesSinIntereses: "",
      bancoId: ""
    },
    responsible: { label: "", value: "" },
    selected: [],
    total: 0,
    optionsMonthsWithoutInterest: []
  };

  onChangeRequiredFilters = async (e, fieldName) => {
    const value = e.target.value;
    await this.setState(state => {
      const filters = {
        ...this.state.filters,
        [fieldName]: value,
        formaPago: "",
        bancoId: "",
        mesesSinIntereses: ""
      };
      return { ...state, filters, selected: [], total: 0 };
    });
    const {
      membresiaSocioIdPublico,
      membresiaSocioClub,
      tipoAnualidad,
      anio
    } = this.state.filters;
    if (
      membresiaSocioIdPublico !== "" &&
      membresiaSocioClub !== "" &&
      tipoAnualidad !== "" &&
      anio !== ""
    )
      this.props.getAnnuityQuote(this.state.filters);
  };

  onChangeWayPay = e => {
    const value = e.target.value;
    this.setState(state => {
      const filters = {
        ...this.state.filters,
        formaPago: value,
        bancoId: "",
        mesesSinIntereses: ""
      };
      return { ...state, filters, total: this.getTotal() };
    });
  };

  onChangeFilters = (e, fieldName) => {
    const value = e.target.value;
    this.setState(state => {
      const filters = {
        ...this.state.filters,
        [fieldName]: value
      };
      return { ...state, filters, total: this.getTotal() };
    });
  };

  renderPrice = cellInfo => {
    const { index, column } = cellInfo;
    const row = this.props.quote.integrantes[index];
    const { faseAAplicar } = this.props.quote;
    const { precio } = row.meses.find(month => month.mesNombre === column.id);
    let showPrice =
      precio + precio * (faseAAplicar.porcentajeIncremento * 0.01);
    if (this.state.filters.formaPago === "TDC") {
      showPrice =
        this.state.filters.mesesSinIntereses === ""
          ? showPrice - showPrice * (faseAAplicar.porcentajeContado * 0.01)
          : showPrice -
            showPrice *
              (faseAAplicar[`${this.state.filters.mesesSinIntereses}`] * 0.01);
    } else if (
      this.state.filters.formaPago === "TDD" ||
      this.state.filters.formaPago === "EFECTIVO"
    ) {
      showPrice =
        showPrice - showPrice * (faseAAplicar.porcentajeContado * 0.01);
    }
    return (
      <p className="m-0 text-center">
        <MexicanCurrency quantity={showPrice} />
      </p>
    );
  };

  handleCheckMember = async (e, id) => {
    const value = e.target.checked;
    const selected = this.state.selected;
    if (value) {
      selected.push(id);
    } else {
      const index = selected.indexOf(id);
      selected.splice(index, 1);
    }
    await this.setState({ selected, total: this.getTotal() });

    if (this.state.selected.length > 0) {
      const bank = this.props.banksMonthsWithoutInterest.find(
        bank => bank.bancoId == this.state.filters.bancoId
      );
      const optionsMonthsWithoutInterest = bank
        ? this.getMonthWithoutInterestOptions(bank)
        : [];
      this.setState({ optionsMonthsWithoutInterest });
    }
  };

  getTotal = () => {
    let total = 0;
    if (!isNil(this.props.quote)) {
      const { integrantes } = this.props.quote;
      const price = integrantes
        .filter(
          i =>
            this.state.selected.indexOf(`${i.numero}-${i.tipoIntegrante}`) != -1
        )
        .map(integrante =>
          integrante.meses.reduce((a, backMonth) => a + backMonth.precio, 0)
        )
        .reduce((a, b) => a + b, 0);
      total =
        price +
        price * (this.props.quote.faseAAplicar.porcentajeIncremento * 0.01);
      if (this.state.filters.formaPago === "TDC") {
        total =
          this.state.filters.mesesSinIntereses === ""
            ? total -
              total * (this.props.quote.faseAAplicar.porcentajeContado * 0.01)
            : total -
              total *
                (this.props.quote.faseAAplicar[
                  `${this.state.filters.mesesSinIntereses}`
                ] *
                  0.01);
      } else if (
        this.state.filters.formaPago === "TDD" ||
        this.state.filters.formaPago === "EFECTIVO"
      ) {
        total =
          total -
          total * (this.props.quote.faseAAplicar.porcentajeContado * 0.01);
      }
    }
    return total;
  };

  onChangeResponsible = responsible => {
    this.setState({ responsible });
  };

  onChangeBank = e => {
    e.preventDefault();
    const bancoId = e.target.value;
    const bank = this.props.banksMonthsWithoutInterest.find(
      banco => banco.bancoId == bancoId
    );
    const optionsMonthsWithoutInterest = bank
      ? this.getMonthWithoutInterestOptions(bank)
      : [];

    const filters = {
      ...this.state.filters,
      bancoId,
      mesesSinIntereses: ""
    };
    this.setState({
      filters,
      optionsMonthsWithoutInterest
    });
  };

  getMonthWithoutInterestOptions = bank => {
    let options = [];
    if (bank.tresMeses < this.state.total && bank.estatusTresMeses)
      options.push({ id: "porcentaje3msi", nombre: "Tres Meses" });
    if (bank.seisMeses < this.state.total && bank.estatusSeisMeses)
      options.push({ id: "porcentaje6msi", nombre: "Seis Meses" });
    if (bank.nueveMeses < this.state.total && bank.estatusNueveMeses)
      options.push({ id: "porcentaje9msi", nombre: "Nueve Meses" });
    if (bank.doceMeses < this.state.total && bank.estatusDoceMeses)
      options.push({ id: "porcentaje12msi", nombre: "Doce Meses" });
    return options;
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      membresiaSocioClub: membresiaSocioClubId,
      membresiaSocioIdPublico,
      mesesSinIntereses,
      bancoId
    } = this.state.filters;
    const data = {
      ...this.state.filters,
      membresiaSocioClubId: parseInt(membresiaSocioClubId),
      membresiaSocioIdPublico: parseInt(membresiaSocioIdPublico),
      mesesSinIntereses:
        mesesSinIntereses == "porcentaje3msi"
          ? 3
          : mesesSinIntereses == "porcentaje6msi"
          ? 6
          : mesesSinIntereses == "porcentaje9msi"
          ? 9
          : mesesSinIntereses == "porcentaje12msi"
          ? 12
          : 0,
      responsableId: parseInt(this.state.responsible.value),
      bancoId: bancoId != "" ? parseInt(bancoId) : null,
      integrantes: this.props.quote.integrantes
        .filter(
          i =>
            this.state.selected.indexOf(`${i.numero}-${i.tipoIntegrante}`) != -1
        )
        .map(i => ({ numero: i.numero, tipoIntegrante: i.tipoIntegrante }))
    };
    this.props.saveAnnuityQuote(data);
  };

  render() {
    const columns: any = [
      {
        Header: "",
        id: "seleccionado",
        width: 25,
        accessor: member => {
          return (
            <div className={`${s.checkboxContainer} ${s.myStyle}`}>
              <Row>
                <Col>
                  <Input
                    name="selected"
                    type="checkbox"
                    id="selected"
                    checked={
                      this.state.selected.indexOf(
                        `${member.numero}-${member.tipoIntegrante}`
                      ) > -1
                    }
                    onChange={e =>
                      this.handleCheckMember(
                        e,
                        `${member.numero}-${member.tipoIntegrante}`
                      )
                    }
                  />
                </Col>
              </Row>
            </div>
          );
        }
      },
      {
        Header: "Nombre",
        accessor: "persona",
        Cell: ({ value }) => (
          <p className="m-0 text-center">{`${value.nombre} ${value.primerApellido}`}</p>
        ),
        width: 150
      }
    ];

    const indexForEach = e => {
      columns.push({
        Header: `${e.mesNombre}`,
        accessor: `${e.mesNombre}`,
        Cell: d => this.renderPrice(d),
        width: 80
      });
    };

    if (this.props.quote && this.props.quote.meses) {
      this.props.quote.meses.forEach(indexForEach);
    }

    return (
      <Container className="w-100 p-0 my-3">
        <Row>
          <Col xs={12} md={6} className="w-100 p-0 m-0">
            <Row className="w-100 p-0 m-0">
              <Col>
                <BaseInput
                  label={"# Membresia Socio"}
                  name={"membresiaSocioIdPublico"}
                  type="text"
                  id={"membresiaSocioIdPublico"}
                  placeholder="# Membresia Socio"
                  value={this.state.filters.membresiaSocioIdPublico}
                  onChange={e =>
                    this.onChangeRequiredFilters(e, "membresiaSocioIdPublico")
                  }
                  size="sm"
                />
              </Col>
              <Col>
                <BaseInput
                  label="Club"
                  name="membresiaSocioClub"
                  id="membresiaSocioClub"
                  type="select"
                  placeholder="Seleccionar Club"
                  value={this.state.filters.membresiaSocioClub}
                  onChange={e =>
                    this.onChangeRequiredFilters(e, "membresiaSocioClub")
                  }
                  size={"sm"}
                  options={[{ value: "", label: "Seleccionar Club" }].concat(
                    this.props.clubs
                      ? this.props.clubs.map(option => ({
                          value: option.clubId,
                          label: option.nombre
                        }))
                      : []
                  )}
                />
              </Col>
            </Row>
            <Row className="w-100 p-0 m-0">
              <Col>
                <BaseInput
                  label="Tipo de Anualidad"
                  name="tipoAnualidad"
                  id="tipoAnualidad"
                  type="select"
                  placeholder="Seleccionar Tipo de Anualidad"
                  value={this.state.filters.tipoAnualidad}
                  onChange={e =>
                    this.onChangeRequiredFilters(e, "tipoAnualidad")
                  }
                  size={"sm"}
                  options={[
                    { value: "", label: "Seleccionar Tipo de Anualidad" }
                  ].concat(
                    this.props.annuityTypes
                      ? this.props.annuityTypes.map(option => ({
                          value: option.id,
                          label: option.nombre
                        }))
                      : []
                  )}
                />
              </Col>
              <Col>
                <BaseInput
                  label={"Año"}
                  name="anio"
                  type="select"
                  id="anio"
                  placeholder="anio"
                  value={this.state.filters.anio}
                  options={[{ value: "", label: "Seleccionar el año" }].concat([
                    {
                      value: String(moment().year()),
                      label: String(moment().year())
                    },
                    {
                      value: String(moment().year() + 1),
                      label: String(moment().year() + 1)
                    }
                  ])}
                  onChange={e => this.onChangeRequiredFilters(e, "anio")}
                />
              </Col>
            </Row>
            <Row className="w-100 p-0 m-0">
              <Col>
                <SelectFilter
                  label="Responsable de Venta"
                  name="responsible"
                  type="select"
                  id="responsible"
                  placeholder="Responsable de Venta"
                  value={this.state.responsible}
                  isMulti={false}
                  options={
                    this.props.users
                      ? this.props.users.map(
                          ({ userId, name, firstSurname }) => ({
                            label: `${name} ${firstSurname}`,
                            value: userId
                          })
                        )
                      : []
                  }
                  onChange={this.onChangeResponsible}
                  loading={false}
                  isAsync={false}
                />
              </Col>
            </Row>
            <Row className="w-100 p-0 m-0">
              <Col>
                <BaseInput
                  label="Forma de Pago"
                  name="formaPago"
                  id="formaPago"
                  type="select"
                  placeholder="Seleccionar Forma de Pago"
                  value={this.state.filters.formaPago}
                  onChange={e => this.onChangeWayPay(e)}
                  size={"sm"}
                  options={[
                    { value: "", label: "Seleccionar Forma de Pago" }
                  ].concat(
                    this.props.waysPay
                      ? this.props.waysPay.map(option => ({
                          value: option.id,
                          label: option.nombre
                        }))
                      : []
                  )}
                />
              </Col>
              <Col>
                {(this.state.filters.formaPago === "TDC" ||
                  this.state.filters.formaPago === "TDD") && (
                  <BaseInput
                    label="Banco"
                    name="bancoId"
                    type="select"
                    id="bancoId"
                    placeholder="Banco"
                    value={this.state.filters.bancoId}
                    size="sm"
                    onChange={this.onChangeBank}
                    options={[
                      { value: "", label: "Seleccione el Banco" }
                    ].concat(
                      this.props.banksMonthsWithoutInterest
                        ? this.props.banksMonthsWithoutInterest.map(option => ({
                            value: option.bancoId,
                            label: option.nombre
                          }))
                        : []
                    )}
                  />
                )}
              </Col>
              <Col>
                {this.state.optionsMonthsWithoutInterest.length > 0 &&
                  this.state.filters.formaPago === "TDC" && (
                    <BaseInput
                      label="Meses sin Intereses"
                      name="mesesSinIntereses"
                      type="select"
                      id="mesesSinIntereses"
                      placeholder="Meses sin Intereses"
                      value={this.state.filters.mesesSinIntereses}
                      size="sm"
                      onChange={e =>
                        this.onChangeFilters(e, "mesesSinIntereses")
                      }
                      options={[
                        {
                          value: "",
                          label: "Seleccione los Meses Sin Intereses"
                        }
                      ].concat(
                        this.state.optionsMonthsWithoutInterest
                          ? this.state.optionsMonthsWithoutInterest.map(
                              option => ({
                                value: option.id,
                                label: option.nombre
                              })
                            )
                          : []
                      )}
                    />
                  )}
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6} className="m-0 d-flex align-items-center">
            <Row
              className="mt-1 m-0 alert alert-secondary row p-0 w-100"
              role="alert"
            >
              <table className="table m-0">
                <tbody>
                  <tr>
                    <th scope="row">TOTAL</th>
                    <td>
                      <div className="float-right">
                        {this.props.quote && (
                          <MexicanCurrency quantity={this.state.total} />
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {this.props.quote && false && (
                        <Button
                          className={`${s.buttonMarginTop} ${s.primaryButton} float-right`}
                          onClick={() => true}
                          size="sm"
                          block
                        >
                          {"Vender"}
                        </Button>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Row>
          </Col>
        </Row>
        {this.props.quote && this.props.quote.integrantes && (
          <Row className="w-100 p-0 m-0">
            <ReactTable
              data={this.props.quote.integrantes || []}
              columns={columns}
              className="w-100 -striped -highlight"
              sortable={false}
              showPagination={false}
              defaultPageSize={
                this.props.quote.integrantes.length === 0
                  ? 3
                  : this.props.quote.integrantes.length
              }
              loading={this.props.loading}
            />
          </Row>
        )}
        <Col xs={2} className="float-right pr-0 pt-2">
          <Button
            className={`${s.buttonMarginTop} ${s.primaryButton} float-right`}
            onClick={e => this.handleSubmit(e)}
            size="sm"
            disabled={
              this.state.selected.length === 0 ||
              this.state.responsible.value === ""
            }
            block
          >
            {"Guardar"}
          </Button>
        </Col>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(AnnuitiesQuote));
