import axios from "axios";
import constants from "../../utils/constants";

const SPORTS_PROGRAMS_BASE_URL = "/api/sports-programs";

export const getValidCustomersForSportProgramInstance = (
  productId: number,
  clubId: number
) => {
  return axios.get(
    `${constants.BASE_URL}${SPORTS_PROGRAMS_BASE_URL}/valid-customers`,
    {
      withCredentials: true,
      params: { productId, clubId }
    }
  );
};

export const createSportProgramInstance = (
  sportProgramInstanceData: object
) => {
  return axios.post(
    `${constants.BASE_URL}${SPORTS_PROGRAMS_BASE_URL}/create`,
    sportProgramInstanceData,
    {
      withCredentials: true
    }
  );
};

export const updateSportProgramInstance = (
  sportProgramInstanceId: number,
  productInstanceIds: any
) => {
  return axios.put(
    `${constants.BASE_URL}${SPORTS_PROGRAMS_BASE_URL}/update/${sportProgramInstanceId}`,
    productInstanceIds,
    {
      withCredentials: true
    }
  );
};

export const closeSportProgramInstance = (sportProgramInstanceId: number) => {
  return axios.put(
    `${constants.BASE_URL}${SPORTS_PROGRAMS_BASE_URL}/close/${sportProgramInstanceId}`,
    {},
    {
      withCredentials: true
    }
  );
};

export const deleteSportProgramInstance = (sportProgramInstanceId: number) => {
  return axios.delete(
    `${constants.BASE_URL}${SPORTS_PROGRAMS_BASE_URL}/${sportProgramInstanceId}`,
    { withCredentials: true }
  );
};

export const getSportProgramInstanceDetail = (
  sportProgramInstanceId: number
) => {
  return axios.get(
    `${constants.BASE_URL}${SPORTS_PROGRAMS_BASE_URL}/detail/${sportProgramInstanceId}`,
    {
      withCredentials: true
    }
  );
};
