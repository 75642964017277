import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import { Button, Col, Container, Row } from "reactstrap";
import BaseInput from "../../../../../toolkit/baseInput";
import { handleRequestError } from "../../../../../utils/helpers";
import {
  errorNotification,
  infoNotification
} from "../../../../../utils/notifications";
import { applyPartnerMembershipFitKidzMaintenanceChange } from "../../../../actions/partners";
import ConfirmModal from "../../../common/confirmModal/confirmModal";
import s from "../styles.scss";

interface Props {
  idMembresiaSocio: string;
  refreshPartner: () => void;
}

interface State {
  confirmModalIsOpen: boolean;
  maintenanceId: number;
  processing: boolean;
}

const getMaintenancesCatalog = () => {
  return [
    { value: null, label: "Seleccionar mantenimiento" },
    { value: 10005071, label: "Juvenil 18" },
    { value: 10006013, label: "JUV ALL" }
  ];
};

class FitKidzMaintenanceChange extends React.Component<Props, State> {
  state = {
    confirmModalIsOpen: false,
    maintenanceId: null,
    processing: false
  };

  onChangeMaintenance = e => {
    const val = e.target.value;
    this.setState({ maintenanceId: val });
  };

  handleChangeMaintenance = () => {
    this.setState({ processing: false, confirmModalIsOpen: true });
  };

  cancelAddFile = () => {
    this.setState({ processing: false, confirmModalIsOpen: false });
  };

  executeChangeMaintenance = async () => {
    const { idMembresiaSocio } = this.props;
    const { maintenanceId } = this.state;

    if (!maintenanceId) {
      errorNotification("Debe seleccionar un mantenimiento");
      return;
    }

    infoNotification("Aplicando cambio de mantenimiento...");

    await this.setState({ processing: true });

    applyPartnerMembershipFitKidzMaintenanceChange(
      idMembresiaSocio,
      maintenanceId
    )
      .then(() => {
        this.handleMaintenanceChanged();
      })
      .catch(handleRequestError);
  };

  handleMaintenanceChanged = async () => {
    await this.setState({ processing: false, confirmModalIsOpen: false });

    infoNotification("Cambio de mantenimiento aplicado");

    if (!!this.props.refreshPartner) {
      this.props.refreshPartner();
    }
  };

  render() {
    return (
      <Container className="mt-4 mb-4 p-0">
        <ConfirmModal
          title="Upgrade de mantenimiento"
          message="Se realizará el cambio al mantenimiento seleccionado. ¿Desea continuar?"
          isOpen={this.state.confirmModalIsOpen}
          size="md"
          ok={() => {
            infoNotification("Actualizando mantenimiento... ");
            this.setState({
              confirmModalIsOpen: false
            });

            this.executeChangeMaintenance();
          }}
          cancel={() => {
            this.setState({
              confirmModalIsOpen: false
            });
          }}
        />

        <p className={`${s.primaryTitle} w-100 m-0 mb-3`}>
          Upgrade de mantenimiento
        </p>

        <Row>
          <Col xs={12} md={8} lg={8}>
            <BaseInput
              label="Mantenimiento"
              name="maintenanceSelector"
              id="maintenanceSelector"
              type="select"
              options={getMaintenancesCatalog()}
              placeholder="Mantenimiento"
              value={null}
              onChange={this.onChangeMaintenance}
            />
          </Col>
          <Col xs={12} md={3} lg={3} className="mt-4">
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.handleChangeMaintenance}
              disabled={!this.state.maintenanceId}
            >
              Actualizar
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withStyles(s)(FitKidzMaintenanceChange);
