import {
  faEdit,
  faPlusCircle,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import cloneDeep from "lodash.clonedeep";
import React from "react";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import { Button, Col, Container, Row } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import constants from "../../../utils/constants";
import { handleRequestError } from "../../../utils/helpers";
import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import { postBillingAdm } from "../../actions/billing";
import {
  getAccountingAccountsRequest,
  getClubsRequest,
  getProductsSatRequest,
  getUnitsSatRequest
} from "../../actions/catalogs";
import { getPersonByIdRequest } from "../../actions/persons";
import {
  getRefenceSantanderCargoRequest,
  getRefenceSantanderComplementoRequest
} from "../../actions/terminal";
import ConfirmModal from "../common/confirmModal/confirmModal";
import MexicanCurrency from "../common/currency";
import TextWithLineBreaks from "../common/TextWithLineBreaks";
import Direcciones from "../prospectDetail/Direcciones";
import AdmAddProductModal from "./admAddProductModal";
import { AdmCharge } from "./interfaces";
import s from "./styles.scss";

export const TARJETA_CREDITO = "TDC";
export const TARJETA_DEBITO = "TDD";
export const TARJETA_AMEX = "AMEX";
export const CONEKTA = "CONEKTA";
export const ID_EFECTIVO = "EFECTIVO";
export const ID_TRANSFERENCIA = "TRANSFERENCIA";
export const ID_CHEQUE = "CHEQUE";
export const POR_DEFINIR_99 = "POR_DEFINIR_99";

const OPTIONS_FORMA_PAGO = [
  ID_EFECTIVO,
  TARJETA_CREDITO,
  TARJETA_DEBITO,
  TARJETA_AMEX,
  ID_TRANSFERENCIA,
  POR_DEFINIR_99
];

interface Props {
  history: any;
  match: any;
}

interface State {
  fields: {
    empresaEmisoraId: number;
    cargos: AdmCharge[];
    cargosTotal: number;
    formaPago: String;
    referencia: String;
  };
  isAddChargeModalOpen: boolean;
  edited: any;

  clubsCatalog: any[];
  accountingAccounts: any[];
  satProducts: any[];
  satUnits: any[];
  selectedPersonId: number;
  selectedPersonName: String;
  person: any;
  selectedPersonsDireccion: any;

  confirmIsOpen: boolean;
  saving: boolean;
  datosFacturacion: any;
}

class FacturacionAdm extends React.Component<Props, State> {
  state = {
    //--------------------------------
    fields: {
      empresaEmisoraId: null,
      cargos: [],
      cargosTotal: 0,
      formaPago: null,
      referencia: null
    },
    //--------------------------------
    isAddChargeModalOpen: false,
    edited: null,

    clubsCatalog: [],
    accountingAccounts: [],
    satProducts: [],
    satUnits: [],
    selectedPersonId: null,
    selectedPersonName: null,
    person: null,
    selectedPersonsDireccion: null,

    confirmIsOpen: false,
    saving: false,
    datosFacturacion: null
  };

  componentDidMount() {
    this.getClubsCatalog();
    this.getAccountingAccounts();
    this.getProductsSat();
    this.getUnitsSat();

    const { personaId } = this.props.match.params;
    if (!!personaId) {
      this.getSelectedPersonAddresses(personaId);
    } else {
      this.setState({
        selectedPersonId: null,
        selectedPersonName: "Venta al Publico",
        selectedPersonsDireccion: null,
        person: null
      });
    }
  }

  getClubsCatalog = () => {
    getClubsRequest()
      .then(({ data }) => {
        this.setState({ clubsCatalog: data });
      })
      .catch(handleRequestError);
  };

  getAccountingAccounts = () => {
    if ((this.state.accountingAccounts || []).length <= 0) {
      getAccountingAccountsRequest()
        .then(({ data }) => {
          this.setState({ accountingAccounts: [...data] });
        })
        .catch(handleRequestError);
    }
  };

  getProductsSat = () => {
    if ((this.state.satProducts || []).length <= 0) {
      getProductsSatRequest()
        .then(({ data }) => {
          this.setState({ satProducts: [...data] });
        })
        .catch(handleRequestError);
    }
  };

  getUnitsSat = () => {
    if ((this.state.satUnits || []).length <= 0) {
      getUnitsSatRequest()
        .then(({ data }) => {
          this.setState({ satUnits: [...data] });
        })
        .catch(handleRequestError);
    }
  };

  toggleAddChargeModal = () => {
    this.setState({
      edited: null,
      isAddChargeModalOpen: !this.state.isAddChargeModalOpen
    });
  };

  onChangeField = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...this.state.fields,
          [fieldName]: value
        };
        return { ...state, fields };
      });

      if (fieldName == "formaPago") {
        if (value == "POR_DEFINIR_99") {
          getRefenceSantanderComplementoRequest("1", "0", value).then(
            ({ data }) => {
              const fieldss = { ...this.state.fields, referencia: data };
              this.setState({ fields: fieldss });
            }
          );
        } else {
          getRefenceSantanderCargoRequest("1", "0", value).then(({ data }) => {
            const fieldss = { ...this.state.fields, referencia: data };
            this.setState({ fields: fieldss });
          });
        }
      }
    };
  };

  handleAddCharge = (charge: AdmCharge) => {
    const { fields } = this.state;
    fields.cargos = [...fields.cargos.filter(x => x.key != charge.key), charge];
    fields.cargosTotal = fields.cargos
      .map(x => parseFloat(x.importe))
      .reduce((a, b) => a + b, 0);
    this.setState({
      edited: null,
      fields,
      isAddChargeModalOpen: false
    });
  };

  handleCancelAddCharge = () => {
    this.setState({
      edited: null,
      isAddChargeModalOpen: false
    });
  };

  handleEditCharge = async charge => {
    await this.setState({
      edited: charge
    });
    await this.setState({
      isAddChargeModalOpen: true
    });
  };

  handleDeleteCharge = chargeKey => {
    const { fields } = this.state;
    fields.cargos = [...fields.cargos.filter(x => x.key != chargeKey)];
    this.setState({
      fields,
      isAddChargeModalOpen: false,
      edited: null
    });
  };

  handleSubmit = () => {
    const obj = cloneDeep(this.state.fields);
    obj["personaId"] = this.state.selectedPersonId || -1000;

    infoNotification("Facturando...");

    postBillingAdm(obj)
      .then(({ data }) => {
        if (!data.error) {
          const { facturaSerie, facturaFolio, archivoPdfStorageId } = data;

          window.open(
            `${constants.BASE_URL}/${constants.DOCUMENTS_BASE_URL}/${archivoPdfStorageId}`,
            "_blank"
          );

          this.setState({ datosFact: false });

          successNotification(
            `Facturado con los siguientes datos: Folio referencia:${facturaSerie}${facturaFolio}`
          );
        } else {
          errorNotification("Error al facturar: " + data.mensajeError);
          this.setState({ saving: false });
        }

        this.setState({ confirmIsOpen: false });
      })
      .catch(handleRequestError);
  };

  setPerson = async selectedPerson => {
    if (!!selectedPerson) {
      this.setState({
        selectedPersonId: selectedPerson.personaId,
        person: selectedPerson
      });
      this.getSelectedPersonAddresses(selectedPerson.personaId);
    } else {
      this.setState({
        selectedPersonId: null,
        person: null
      });
    }
  };

  getSelectedPersonAddresses = personId => {
    getPersonByIdRequest(personId).then(({ data }) => {
      const { direcciones } = data;
      const direccionFiscal = direcciones.find(x => !!x.domicilioFiscal);
      this.setState({
        selectedPersonId: personId,
        selectedPersonName: data.nombreCompleto,
        selectedPersonsDireccion: direccionFiscal || null
      });
    });
  };

  columns = [
    {
      Header: "Descripcion",
      accessor: "descripcion",
      className: "text-wrap"
    },
    {
      Header: "Cuentas contables",
      Cell: row => {
        return <TextWithLineBreaks text={row.original.cuentasContablesDesc} />;
      }
    },
    {
      Header: "Importe",
      className: "text-right",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.importe} />;
      },
      Footer: (
        <span>
          SUBTOTAL:{" "}
          <MexicanCurrency quantity={this.state.fields.cargosTotal || 0} />
        </span>
      )
    },
    {
      Header: () => (
        <Container className="w-100 text-center">
          <Button
            color="link"
            onClick={() => this.toggleAddChargeModal()}
            className={`${s.innerButton} ${s.primaryLink}`}
          >
            <FontAwesomeIcon icon={faPlusCircle} />
          </Button>
        </Container>
      ),
      id: "id",
      accessor: charge => {
        return (
          <Container className="w-100 text-center">
            <Button
              color="link"
              onClick={() => this.handleEditCharge(charge)}
              className={`${s.innerButton} ${s.primaryLink}`}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
            &nbsp;
            <Button
              color="link"
              onClick={() => this.handleDeleteCharge(charge.key)}
              className={`${s.innerButton} ${s.primaryLink}`}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </Container>
        );
      }
    }
  ];

  render() {
    return (
      <Container className="w-100 px-0 pt-2">
        <ConfirmModal
          title="Facturación ADM"
          message={`Se realizará una facturación ADM con los datos ingresados.\n\n¿Desea continuar?`}
          isOpen={this.state.confirmIsOpen}
          requireInput={false}
          ok={this.handleSubmit}
          cancel={() => {
            this.setState({
              confirmIsOpen: false,
              saving: false
            });
          }}
        />

        <AdmAddProductModal
          isOpen={this.state.isAddChargeModalOpen}
          addCharge={this.handleAddCharge}
          cancel={this.handleCancelAddCharge}
          edited={this.state.edited}
          clubsCatalog={this.state.clubsCatalog || []}
          accountingAccounts={this.state.accountingAccounts || []}
          satProducts={this.state.satProducts || []}
          satUnits={this.state.satUnits || []}
        />

        {/* ------------------------------------------------------- */}

        <Row className="primaryTitle m-0 mb-2 mt-4">
          <Col xs="10">
            <p className="m-0">Cliente</p>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <BaseInput
              label={"Cliente:"}
              id="admConfigCliente"
              name="admConfigCliente"
              type="text"
              value={this.state.selectedPersonName || ""}
              disabled={true}
            />
          </Col>
        </Row>
        {!!this.state.selectedPersonId &&
          !!this.state.selectedPersonsDireccion && (
            <Row>
              <Col>
                <Direcciones
                  viewMode={true}
                  isCorporativo={true}
                  ocultarTitulo={true}
                  data={[this.state.selectedPersonsDireccion]}
                />
              </Col>
            </Row>
          )}

        <Row className="primaryTitle m-0 mb-2 mt-4">
          <Col xs="10">
            <p className="m-0">Empresa emisora</p>
          </Col>
        </Row>

        <Row>
          <Col xs={6} className="pr-0 pt-2">
            <BaseInput
              label={"Empresas:"}
              id="admConfigEmpresa"
              name="admConfigEmpresa"
              type="select"
              value={this.state.fields.empresaEmisoraId}
              options={[
                { value: null, label: "Seleccionar" },
                {
                  value: 1,
                  label: "OPERADORA Y ADMINISTRADORA SW, S.A DE C.V."
                }
              ]}
              onChange={this.onChangeField("empresaEmisoraId")}
            />
          </Col>
        </Row>

        {/* ------------------------------------------------------- */}
        <Row className="primaryTitle m-0 mb-2 mt-4">
          <Col xs="10">
            <p className="m-0">Cargos</p>
          </Col>
        </Row>
        <Container className="w-100 px-0 pt-2">
          <ReactTable
            data={this.state.fields.cargos}
            columns={this.columns}
            className="-striped -highlight"
            sortable={false}
            showPagination={false}
            pageSize={(this.state.fields.cargos || []).length + 1}
          />
        </Container>

        <Row className="primaryTitle m-0 mb-2 mt-4">
          <Col xs="10">
            <p className="m-0">Forma de pago</p>
          </Col>
        </Row>

        <Row>
          <Col xs={6} md={4} className="pr-0 pt-2">
            <BaseInput
              label={"Forma de pago:"}
              id="admConfigFormaPago"
              name="admConfigFormaPago"
              type="select"
              value={this.state.fields.formaPago}
              options={[{ value: "", label: "Seleccionar" }].concat(
                OPTIONS_FORMA_PAGO.map(option => ({
                  value: option,
                  label:
                    option == "POR_DEFINIR_99" ? "99 - POR DEFINIR" : option
                }))
              )}
              onChange={this.onChangeField("formaPago")}
            />
          </Col>
          <Col xs={6} md={4} className="pr-0 pt-2">
            <BaseInput
              label={"Referencia:"}
              id="admConfigReferencia"
              name="admConfigReferencia"
              type="text"
              value={this.state.fields.referencia}
              onChange={this.onChangeField("referencia")}
            />
          </Col>
        </Row>

        <div className="text-right pr-0 pt-2 mt-4 mb-4">
          <Button
            className={`${s.buttonMarginTop} ${s.primaryButton} mr-5`}
            onClick={() => {
              this.setState({
                confirmIsOpen: true
              });
            }}
            size="sm"
            disabled={this.state.saving}
          >
            Facturar
          </Button>

          <Button
            className={`${s.buttonMarginTop}  mr-5`}
            onClick={() => {
              this.props.history.push("/clientes-corporativos");
            }}
            size="sm"
          >
            Regresar
          </Button>
        </div>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(FacturacionAdm));
