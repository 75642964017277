import Reform from "@franleplant/reform";
import { FormErrors } from "@franleplant/reform/types";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import { withRouter } from "react-router-dom";
import { DropdownItem, FormGroup, Input, Label, Table } from "reactstrap";
import BaseNumericInput from "../../../toolkit/baseNumericInput";
import { handleRequestError } from "../../../utils/helpers";
import { getSchedulePatternsCatalog } from "../../actions/catalogs";
import { setChildStep } from "../../helpers/stepper-state-comparator";
import s from "./styles.scss";

interface Props {
  history: any;
  client: any;
  successfulSave: boolean;
  match: any;
  config: CONFIG_RESPONSE;
  memberships: {
    content: { nombre: string; datosBasicosMembresiaId: string }[];
  };
  accountingAccounts: object[];
  onClickSave: (e: object) => any;
  onSuccessfulSave: () => any;
  saveBasicProduct: (tipoProducto: string, product: object) => any;
  saveConfigurations: (
    productId: string,
    tipoProducto: string,
    confg: object
  ) => any;
  updateConfigurations: (
    productId: string,
    tipoProducto: string,
    confg: object
  ) => any;
}

enum TIPO_ACCESO {
  SINGLE_CLUB = "SINGLE_CLUB",
  ALL_CLUB = "ALL_CLUB",
  ELITE = "ELITE"
}

interface CONFIG_RESPONSE {
  ventaPiso: boolean;
  ventaCorporativa: boolean;
  operacionPiso: boolean;
  operacionCorporativa: boolean;
  publicarPrecio: boolean;
  disponiblePortalUsuarios: boolean;
  tipoInfantil: boolean;
  permiteAnualidad: boolean;
  habilitar2x1: boolean;
  generarCargoPreventa: boolean;
  permitirInvitadosGratis: boolean;
  tipoAcceso: TIPO_ACCESO;
  fulltime: boolean;
  configuracionGeneralId: number;
  titularPatronHorarioId: number;
  integrantePatronHorarioId: number;
  extraPatronHorarioId: number;
  bebePatronHorarioId: number;
  invitadoPatronHorarioId: number;
  biclub: boolean;
  biclubImporteIncremento: number;
  accesosClubXDia: number;
  accesosClubXMes: number;
  membresias: {
    membresiaId: number;
    bebesExtras: number;
    personasExtras: number;
    cuentaContableId: number;
    cuentaContableNombre: string;
  }[];
}

interface Fields {
  ventaPiso: boolean;
  ventaCorporativa: boolean;
  operacionPiso: boolean;
  operacionCorporativa: boolean;
  publicarPrecio: boolean;
  disponiblePortalUsuarios: boolean;
  tipoInfantil: boolean;
  permiteAnualidad: boolean;
  habilitar2x1: boolean;
  generarCargoPreventa: boolean;
  permitirInvitadosGratis: boolean;
  tipoAcceso: TIPO_ACCESO;
  fulltime: boolean;
  membershipsSelected: object;
  babiesAndExtras: object;
  dropDownOpen: object;
  selectedAA: object;
  titularPatronHorarioId: number;
  integrantePatronHorarioId: number;
  extraPatronHorarioId: number;
  bebePatronHorarioId: number;
  invitadoPatronHorarioId: number;
  biclub: boolean;
  biclubImporteIncremento: number;
  accesosClubXDia: number;
  accesosClubXMes: number;
}

interface State {
  errors: FormErrors;
  fields: Fields;
  inputProcessed: boolean;
  membershipProcessed: boolean;
  schedulePatterns: any[];
}

export const transformTimeObject = ({ hour, minute, meridiem }) => {
  let formatHour = hour;
  if (meridiem === "PM" && hour !== "12") {
    formatHour = Number(hour) + 12;
  }
  return `${formatHour}:${minute}:00`;
};

export const transformTimeToObject = (time: string) => {
  const [hour, minute] = time.split(":");
  let hourNumber = parseInt(hour);
  let meridiem = "AM";

  if (hourNumber > 12) {
    hourNumber -= 12;
    meridiem = "PM";
  } else if (hourNumber === 12) {
    meridiem = "PM";
  }

  return {
    hour: hourNumber < 10 ? `0${hourNumber}` : `${hourNumber}`,
    minute,
    meridiem
  };
};

class MaintenanceConfigurationForm extends React.Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);
  state = {
    fields: {
      fulltime: false,
      ventaPiso: false,
      ventaCorporativa: false,
      tipoAcceso: TIPO_ACCESO.SINGLE_CLUB,
      operacionPiso: false,
      operacionCorporativa: false,
      publicarPrecio: false,
      disponiblePortalUsuarios: false,
      tipoInfantil: false,
      permiteAnualidad: false,
      habilitar2x1: false,
      generarCargoPreventa: false,
      permitirInvitadosGratis: false,
      membershipsSelected: null,
      babiesAndExtras: null,
      dropDownOpen: null,
      selectedAA: null,
      titularPatronHorarioId: -1,
      integrantePatronHorarioId: -1,
      extraPatronHorarioId: -1,
      bebePatronHorarioId: -1,
      invitadoPatronHorarioId: -1,
      biclub: false,
      biclubImporteIncremento: null,
      accesosClubXDia: null,
      accesosClubXMes: null
    },
    inputProcessed: false,
    membershipProcessed: false,
    errors: {},
    schedulePatterns: null
  };

  validationRules = {
    membershipsSelected: {
      membershipsSelected: value => this.validateMembershipsSelected(value)
    }
  };

  validationMessages = {
    required: _ => `Obligatorio`,
    membershipsSelected: _ => `Debes configurar al menos una membresía`,
    default: _ => `Inválido`
  };

  validateMembershipsSelected = value => {
    let membresias = [];
    const { content } = this.props.memberships;
    content.forEach(({ datosBasicosMembresiaId }) => {
      if (value[datosBasicosMembresiaId]) {
        membresias.push({
          bebesExtras: this.state.fields.babiesAndExtras[
            datosBasicosMembresiaId
          ].babies,
          personasExtras: this.state.fields.babiesAndExtras[
            datosBasicosMembresiaId
          ].extras,
          cuentaContableId: this.state.fields.selectedAA[
            datosBasicosMembresiaId
          ].id,
          membresiaId: datosBasicosMembresiaId
        });
      }
    });
    return membresias.length == 0 ? true : false;
  };

  componentDidMount() {
    this.props.onClickSave(e => {
      e.preventDefault();
      if (this.re.validateFormFromState()) {
        const { productId, tipoProducto } = this.props.match.params;
        let f: any;

        if (this.props.config || this.props.successfulSave) {
          f = this.props.updateConfigurations;
        } else {
          f = this.props.saveConfigurations;
        }
        const copy = Object.assign({}, this.state.fields);
        delete copy.selectedAA;
        delete copy.membershipsSelected;
        delete copy.dropDownOpen;
        delete copy.babiesAndExtras;

        if ((copy.titularPatronHorarioId || -1) == -1) {
          delete copy.titularPatronHorarioId;
        }

        if ((copy.integrantePatronHorarioId || -1) == -1) {
          delete copy.integrantePatronHorarioId;
        }

        if ((copy.extraPatronHorarioId || -1) == -1) {
          delete copy.extraPatronHorarioId;
        }

        if ((copy.bebePatronHorarioId || -1) == -1) {
          delete copy.bebePatronHorarioId;
        }

        if ((copy.invitadoPatronHorarioId || -1) == -1) {
          delete copy.invitadoPatronHorarioId;
        }

        let membresias = [];

        const { content } = this.props.memberships;

        content.forEach(({ datosBasicosMembresiaId }) => {
          if (this.state.fields.membershipsSelected[datosBasicosMembresiaId]) {
            membresias.push({
              bebesExtras: this.state.fields.babiesAndExtras[
                datosBasicosMembresiaId
              ].babies,
              personasExtras: this.state.fields.babiesAndExtras[
                datosBasicosMembresiaId
              ].extras,
              cuentaContableId: this.state.fields.selectedAA[
                datosBasicosMembresiaId
              ].id,
              membresiaId: datosBasicosMembresiaId
            });
          }
        });

        f(productId, tipoProducto, {
          ...copy,
          membresias
        });
      }
    });

    getSchedulePatternsCatalog()
      .then(({ data }) => {
        this.setState({ schedulePatterns: data });
      })
      .catch(handleRequestError);
  }

  componentDidUpdate() {
    if (
      this.props.config &&
      this.state.fields.babiesAndExtras &&
      this.state.fields.membershipsSelected &&
      this.state.fields.selectedAA &&
      !this.state.inputProcessed
    ) {
      const { config } = this.props;
      const babiesAndExtras = { ...this.state.fields.babiesAndExtras };
      const membershipsSelected = { ...this.state.fields.membershipsSelected };
      const selectedAA = { ...this.state.fields.selectedAA };

      config.membresias.forEach(
        ({
          membresiaId,
          bebesExtras,
          personasExtras,
          cuentaContableId,
          cuentaContableNombre
        }) => {
          babiesAndExtras[membresiaId] = {
            babies: bebesExtras,
            extras: personasExtras
          };

          membershipsSelected[membresiaId] = true;
          selectedAA[membresiaId] = {
            id: cuentaContableId,
            name: cuentaContableNombre
          };
        }
      );

      delete config.membresias;
      const fields = {
        ...this.state.fields,
        ventaPiso: config.ventaPiso,
        ventaCorporativa: config.ventaCorporativa,
        operacionPiso: config.operacionPiso,
        operacionCorporativa: config.operacionCorporativa,
        publicarPrecio: config.publicarPrecio,
        disponiblePortalUsuarios: config.disponiblePortalUsuarios,
        tipoInfantil: config.tipoInfantil,
        permiteAnualidad: config.permiteAnualidad,
        habilitar2x1: config.habilitar2x1,
        generarCargoPreventa: config.generarCargoPreventa,
        permitirInvitadosGratis: config.permitirInvitadosGratis,
        tipoAcceso: config.tipoAcceso,
        fulltime: false,
        babiesAndExtras,
        membershipsSelected,
        selectedAA,
        titularPatronHorarioId: config.titularPatronHorarioId || -1,
        integrantePatronHorarioId: config.integrantePatronHorarioId || -1,
        extraPatronHorarioId: config.extraPatronHorarioId || -1,
        bebePatronHorarioId: config.bebePatronHorarioId || -1,
        invitadoPatronHorarioId: config.invitadoPatronHorarioId || -1,
        biclub: config.biclub || false,
        biclubImporteIncremento: config.biclubImporteIncremento || 0,
        accesosClubXDia: config.accesosClubXDia || 0,
        accesosClubXMes: config.accesosClubXMes || 0
      };

      this.setState({
        ...this.state,
        fields,
        inputProcessed: true
      });
    }

    if (
      this.props.memberships &&
      this.props.memberships.content &&
      !this.state.membershipProcessed
    ) {
      const { content } = this.props.memberships;
      const state = { ...this.state };

      if (!this.state.fields.babiesAndExtras) {
        const babiesAndExtras = {};
        content.forEach(({ datosBasicosMembresiaId }) => {
          babiesAndExtras[datosBasicosMembresiaId] = {
            babies: "",
            extras: ""
          };
        });
        state.fields = { ...state.fields, babiesAndExtras };
      }

      if (!this.state.fields.membershipsSelected) {
        const membershipsSelected = {};
        content.forEach(({ datosBasicosMembresiaId }) => {
          membershipsSelected[datosBasicosMembresiaId] = false;
        });
        state.fields = {
          ...state.fields,
          membershipsSelected
        };
      }

      if (!this.state.fields.dropDownOpen) {
        const dropDownOpen = {};
        content.forEach(({ datosBasicosMembresiaId }) => {
          dropDownOpen[datosBasicosMembresiaId] = false;
        });
        state.fields = {
          ...state.fields,
          dropDownOpen
        };
      }

      if (!this.state.fields.selectedAA) {
        const selectedAA = {};
        content.forEach(({ datosBasicosMembresiaId }) => {
          selectedAA[datosBasicosMembresiaId] = {
            name: null,
            id: null
          };
        });
        state.fields = {
          ...state.fields,
          selectedAA
        };
      }

      this.setState({
        ...state,
        membershipProcessed: true
      });
    }

    if (this.state.inputProcessed && this.state.membershipProcessed) {
      setChildStep(this.state.fields);
    }
  }

  onChangeFactory = (fieldName: any) => {
    return (event: any) => {
      const value = event.target.checked;
      const fields = {
        ...this.state.fields,
        [fieldName]: value
      };
      setChildStep(fields);
      this.setState(state => ({ ...state, fields }));
    };
  };

  select = (attr: any, event: any) => {
    const value = event.target.value;
    const fields = {
      ...this.state.fields,
      [attr]: value
    };

    setChildStep(fields);
    this.setState(state => ({ ...state, fields }));
  };

  toggle = id => {
    const opened = this.state.fields.dropDownOpen[id];
    const fields = {
      ...this.state.fields,
      dropDownOpen: {
        ...this.state.fields.dropDownOpen,
        [id]: !opened
      }
    };
    setChildStep(fields);
    this.setState(state => ({
      ...state,
      fields
    }));
  };

  onSelectMembership = (event, id) => {
    const { checked } = event.target;
    const fields = {
      ...this.state.fields,
      membershipsSelected: {
        ...this.state.fields.membershipsSelected,
        [id]: checked
      },
      babiesAndExtras: {
        ...this.state.fields.babiesAndExtras,
        [id]: {
          babies: 0,
          extras: 0
        }
      },
      dropDownOpen: {
        ...this.state.fields.dropDownOpen,
        [id]: false
      },
      selectedAA: {
        ...this.state.fields.selectedAA,
        [id]: {
          name: null,
          id: null
        }
      }
    };

    setChildStep(fields);

    this.setState(state => ({
      ...state,
      fields
    }));
  };

  selectBabiesAndExtras = (event, id, type) => {
    const { value } = event.target;
    const fields = {
      ...this.state.fields,
      babiesAndExtras: {
        ...this.state.fields.babiesAndExtras,
        [id]: {
          ...this.state.fields.babiesAndExtras[id],
          [type]: value
        }
      }
    };

    setChildStep(fields);

    this.setState(prevState => ({
      ...prevState,
      fields
    }));
  };

  selectAccountingAccount = (id, accountingAccount) => {
    const fields = {
      ...this.state.fields,
      selectedAA: {
        ...this.state.fields.selectedAA,
        [id]: {
          name: accountingAccount.nombre,
          id: accountingAccount.cuentaContableId
        }
      },
      dropDownOpen: {
        ...this.state.fields.dropDownOpen,
        [id]: false
      }
    };

    const state = { ...this.state, fields };

    setChildStep(fields);
    this.setState(_ => state);
  };

  checkBiclub = e => {
    const { fields } = this.state;
    fields.biclub = e.target.checked;

    if (!fields.biclub) {
      fields.biclubImporteIncremento = 0;
    }

    this.setState({ fields: { ...fields } });
  };

  handleBiclubAmoutnChange = e => {
    const { fields } = this.state;
    fields.biclubImporteIncremento = e.target.value;
    this.setState({ fields: { ...fields } });
  };

  handleClubAccessBydayChange = e => {
    const { fields } = this.state;
    fields.accesosClubXDia = e.target.value;
    fields.accesosClubXMes = 0;
    this.setState({ fields: { ...fields } });
  };

  handleClubAccessByMonthChange = e => {
    const { fields } = this.state;
    fields.accesosClubXMes = e.target.value;
    fields.accesosClubXDia = 0;
    this.setState({ fields: { ...fields } });
  };

  render() {
    const COLUMN_LENGTH = 6;
    const ROW_LENGTH = 12;

    const getHeader = () => {
      const header = [];

      for (let i = 0; i < ROW_LENGTH / COLUMN_LENGTH; i++) {
        header.push(
          <div key={i} className={`col-${COLUMN_LENGTH} mb-2`}>
            <div className="row">
              <div className="col-5">
                <b>
                  <label className="my-0">
                    <b>MEMBRESÍAS</b>
                  </label>
                </b>
              </div>
              <div className="col-1" />
              <div className="col-2">
                <b>
                  <label className="my-0">
                    <b>BEBÉS</b>
                  </label>
                </b>
              </div>
              <div className="col-2">
                <b>
                  <label className="my-0">
                    <b>EXTRAS</b>
                  </label>
                </b>
              </div>
            </div>
          </div>
        );
      }

      return header;
    };

    const dropdownOptions = (id, options) => {
      return options.map(option => (
        <DropdownItem
          onClick={() => {
            this.selectAccountingAccount(id, option);
          }}
          key={option.cuentaContableId}
          value={option.cuentaContableId}
          className={s.options}
        >
          {option.nombre}
        </DropdownItem>
      ));
    };
    return (
      <form className={s.basicDataProduct}>
        <div className="form-group">
          <p className={`${s.primaryTitle}`}>Membresias Habilitadas</p>
          <div className="row my-3">
            {getHeader()}
            {this.props.memberships &&
              this.props.memberships.content &&
              this.props.memberships.content.map(
                membership =>
                  this.state.fields.babiesAndExtras &&
                  membership.nombre && (
                    <div
                      key={membership.datosBasicosMembresiaId}
                      className={`col-${COLUMN_LENGTH} my-1`}
                    >
                      <div className="row">
                        <div className="col-5">
                          <label className="my-0">{membership.nombre}</label>
                        </div>
                        <div className="col-1">
                          <input
                            className={s.checkboxInput}
                            type="checkbox"
                            name={membership.datosBasicosMembresiaId}
                            checked={
                              this.state.fields.membershipsSelected[
                                membership.datosBasicosMembresiaId
                              ]
                            }
                            onChange={e =>
                              this.onSelectMembership(
                                e,
                                membership.datosBasicosMembresiaId
                              )
                            }
                          />
                        </div>
                        <div className={`${s.numberContainer} col-2`}>
                          <Input
                            name="babies"
                            type="number"
                            className={s.inputNumber}
                            disabled={
                              !this.state.fields.membershipsSelected[
                                membership.datosBasicosMembresiaId
                              ]
                            }
                            value={
                              this.state.fields.babiesAndExtras[
                                membership.datosBasicosMembresiaId
                              ].babies
                            }
                            onChange={e =>
                              this.selectBabiesAndExtras(
                                e,
                                membership.datosBasicosMembresiaId,
                                "babies"
                              )
                            }
                          />
                        </div>
                        <div className={`${s.numberContainer} col-2`}>
                          <Input
                            name="extras"
                            type="number"
                            className={s.inputNumber}
                            disabled={
                              !this.state.fields.membershipsSelected[
                                membership.datosBasicosMembresiaId
                              ]
                            }
                            value={
                              this.state.fields.babiesAndExtras[
                                membership.datosBasicosMembresiaId
                              ].extras
                            }
                            onChange={e =>
                              this.selectBabiesAndExtras(
                                e,
                                membership.datosBasicosMembresiaId,
                                "extras"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )
              )}
          </div>
        </div>
        {this.re.mapFieldErrors("membershipsSelected") &&
          this.re
            .mapFieldErrors("membershipsSelected")
            .map((message, index) => {
              return (
                <span key={index} className={s.errorSpan}>
                  {message}
                  <br />
                </span>
              );
            })}

        <div className="form-group">
          <p className={`${s.primaryTitle}`}>Disponibilidad Mantenimiento</p>
          <div className="form-row my-4">
            <div className="form-check form-check-inline col-2">
              <input
                onChange={this.onChangeFactory("ventaPiso")}
                className="form-check-input"
                checked={this.state.fields.ventaPiso}
                type="checkbox"
              />
              <label className="form-check-label">Venta Piso</label>
            </div>
            <div className="form-check form-check-inline col-2">
              <input
                onChange={this.onChangeFactory("operacionPiso")}
                className="form-check-input"
                checked={this.state.fields.operacionPiso}
                type="checkbox"
              />
              <label className="form-check-label">Operacion Piso</label>
            </div>
            <div className="form-check form-check-inline col-2">
              <input
                onChange={this.onChangeFactory("publicarPrecio")}
                checked={this.state.fields.publicarPrecio}
                className="form-check-input"
                type="checkbox"
              />
              <label className="form-check-label">Publicar Precio</label>
            </div>
            <div className="form-check form-check-inline col-2">
              <input
                onChange={this.onChangeFactory("ventaCorporativa")}
                checked={this.state.fields.ventaCorporativa}
                className="form-check-input"
                type="checkbox"
              />
              <label className="form-check-label">Venta Corporativa</label>
            </div>
            <div className="form-check form-check-inline col-2">
              <input
                onChange={this.onChangeFactory("operacionCorporativa")}
                checked={this.state.fields.operacionCorporativa}
                className="form-check-input"
                type="checkbox"
              />
              <label className="form-check-label">Operacion Corporativa</label>
            </div>
          </div>
        </div>

        <div className="form-group">
          <p className={`${s.primaryTitle}`}>Opciones Extras</p>
          <div className="form-row my-4">
            <div className="form-check form-check-inline col-2">
              <input
                onChange={this.onChangeFactory("disponiblePortalUsuarios")}
                checked={this.state.fields.disponiblePortalUsuarios}
                className="form-check-input"
                type="checkbox"
              />
              <label className="form-check-label">
                Disponible Portal Usuarios
              </label>
            </div>
            <div className="form-check form-check-inline col-2">
              <input
                onChange={this.onChangeFactory("tipoInfantil")}
                checked={this.state.fields.tipoInfantil}
                className="form-check-input"
                type="checkbox"
              />
              <label className="form-check-label">Tipo Infantil</label>
            </div>
            <div className="form-check form-check-inline col-2">
              <input
                onChange={this.onChangeFactory("permiteAnualidad")}
                checked={this.state.fields.permiteAnualidad}
                className="form-check-input"
                type="checkbox"
              />
              <label className="form-check-label">Permite Anualidad</label>
            </div>
            <div className="form-check form-check-inline col-2">
              <input
                onChange={this.onChangeFactory("habilitar2x1")}
                checked={this.state.fields.habilitar2x1}
                className="form-check-input"
                type="checkbox"
              />
              <label className="form-check-label">Habilitar 2x1</label>
            </div>
            <div className="form-check form-check-inline col-2">
              <input
                onChange={this.onChangeFactory("generarCargoPreventa")}
                checked={this.state.fields.generarCargoPreventa}
                className="form-check-input"
                type="checkbox"
              />
              <label className="form-check-label">
                Genera cargos en preventa
              </label>
            </div>
          </div>
          <div className="form-row my-4">
            <div className="form-check form-check-inline col-2">
              <input
                onChange={this.onChangeFactory("permitirInvitadosGratis")}
                checked={this.state.fields.permitirInvitadosGratis}
                className="form-check-input"
                type="checkbox"
              />
              <label className="form-check-label">
                Permite invitados gratis
              </label>
            </div>
          </div>
        </div>

        <div className="my-4">
          <p className={`${s.primaryTitle}`}>Biclub</p>
          <div className="form-row">
            <div className="col-1">
              <FormGroup check className="form-check-inline float-right mr-5">
                <Label check>
                  <Input
                    type="checkbox"
                    checked={this.state.fields.biclub}
                    onChange={e => this.checkBiclub(e)}
                  />{" "}
                  Biclub
                </Label>
              </FormGroup>
            </div>
            <div className="col-2">
              <FormGroup check className="form-check-inline float-right mr-5">
                <BaseNumericInput
                  type="text"
                  label={"Importe incremento (pesos)"}
                  name="biclubImporteIncremento"
                  id="biclubImporteIncremento"
                  value={this.state.fields.biclubImporteIncremento}
                  disabled={!this.state.fields.biclub}
                  onChange={this.handleBiclubAmoutnChange}
                />
              </FormGroup>
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="row">
            <div className="col-8">
              <p className={`${s.primaryTitle}`}>
                Accesos a club base por tipo de integrante
              </p>
              <Table size="sm" hover className="table-borderless">
                <thead className="mb-3">
                  <tr>
                    <th>Tipo integrante</th>
                    <th>Patrón de horario</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ verticalAlign: "middle" }}>TITULAR</td>
                    <td>
                      <Input
                        type="select"
                        id="titularPatronHorarioId"
                        value={this.state.fields.titularPatronHorarioId}
                        onChange={e => this.select("titularPatronHorarioId", e)}
                        style={{ width: 300 }}
                      >
                        <option value={-1}>
                          SELECCIONE UN PATRON DE HORARIO
                        </option>
                        {this.state.schedulePatterns &&
                          this.state.schedulePatterns.map(
                            (option: any, index) => {
                              return (
                                <option key={index} value={option.patronId}>
                                  {option.nombre}
                                </option>
                              );
                            }
                          )}
                      </Input>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: "middle" }}>INTEGRANTE</td>
                    <td>
                      <Input
                        type="select"
                        id="integrantePatronHorarioId"
                        value={this.state.fields.integrantePatronHorarioId}
                        onChange={e =>
                          this.select("integrantePatronHorarioId", e)
                        }
                        style={{ width: 300 }}
                      >
                        <option value={-1}>
                          SELECCIONE UN PATRON DE HORARIO
                        </option>
                        {this.state.schedulePatterns &&
                          this.state.schedulePatterns.map(
                            (option: any, index) => {
                              return (
                                <option key={index} value={option.patronId}>
                                  {option.nombre}
                                </option>
                              );
                            }
                          )}
                      </Input>
                    </td>
                  </tr>{" "}
                  <tr>
                    <td style={{ verticalAlign: "middle" }}>EXTRA</td>
                    <td>
                      <Input
                        type="select"
                        id="extraPatronHorarioId"
                        value={this.state.fields.extraPatronHorarioId}
                        onChange={e => this.select("extraPatronHorarioId", e)}
                        style={{ width: 300 }}
                      >
                        <option value={-1}>
                          SELECCIONE UN PATRON DE HORARIO
                        </option>
                        {this.state.schedulePatterns &&
                          this.state.schedulePatterns.map(
                            (option: any, index) => {
                              return (
                                <option key={index} value={option.patronId}>
                                  {option.nombre}
                                </option>
                              );
                            }
                          )}
                      </Input>
                    </td>
                  </tr>{" "}
                  <tr>
                    <td style={{ verticalAlign: "middle" }}>BEBÉ</td>
                    <td>
                      <Input
                        type="select"
                        id="bebePatronHorarioId"
                        value={this.state.fields.bebePatronHorarioId}
                        onChange={e => this.select("bebePatronHorarioId", e)}
                        style={{ width: 300 }}
                      >
                        <option value={-1}>
                          SELECCIONE UN PATRON DE HORARIO
                        </option>
                        {this.state.schedulePatterns &&
                          this.state.schedulePatterns.map(
                            (option: any, index) => {
                              return (
                                <option key={index} value={option.patronId}>
                                  {option.nombre}
                                </option>
                              );
                            }
                          )}
                      </Input>
                    </td>
                  </tr>{" "}
                  <tr>
                    <td style={{ verticalAlign: "middle" }}>INVITADO</td>
                    <td>
                      <Input
                        type="select"
                        id="invitadoPatronHorarioId"
                        value={this.state.fields.invitadoPatronHorarioId}
                        onChange={e =>
                          this.select("invitadoPatronHorarioId", e)
                        }
                        style={{ width: 300 }}
                      >
                        <option value={-1}>
                          SELECCIONE UN PATRON DE HORARIO
                        </option>
                        {this.state.schedulePatterns &&
                          this.state.schedulePatterns.map(
                            (option: any, index) => {
                              return (
                                <option key={index} value={option.patronId}>
                                  {option.nombre}
                                </option>
                              );
                            }
                          )}
                      </Input>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="col-4">
              <p className={`${s.primaryTitle}`}># Accesos a club</p>
              <div className="alert alert-warning">
                Si no hay un límite de accesos por día/mes el valor de los
                campos debe ser igual a 0
              </div>
              <div className="form-row">
                <div className="col">
                  <FormGroup check className="form-check-inline">
                    <BaseNumericInput
                      type="text"
                      label={"# Accesos por día"}
                      name="clubAccesoXDia"
                      id="clubAccesoXDia"
                      value={this.state.fields.accesosClubXDia}
                      onChange={this.handleClubAccessBydayChange}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="form-row">
                <div className="col">
                  <FormGroup check className="form-check-inline">
                    <BaseNumericInput
                      type="text"
                      label={"# Accesos por mes"}
                      name="clubAccesoXMes"
                      id="clubAccesoXMes"
                      value={this.state.fields.accesosClubXMes}
                      onChange={this.handleClubAccessByMonthChange}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default withRouter(withStyles(s)(MaintenanceConfigurationForm));
