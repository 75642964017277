import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import AnnuitiesQuoteById from "../../components/annuitiesQuoteById";
import * as catalogActions from "../../actions/catalogs";
import * as annuityActions from "../../actions/annuities";

interface Props {
  getAnnuityQuoteById: (id: string) => void;
  payAnnuityQuote: (id: string) => void;
  getClubs: () => void;
  getAnnuityTypes: () => void;
  getWaysPay: () => void;
  clubs: object[];
  annuityTypes: object[];
  waysPay: object[];
  quote: object;
  loading: boolean;
  match: any;
}

class AnnuitiesQuotePage extends React.Component<Props, void> {
  componentDidMount() {
    this.props.getAnnuityQuoteById(this.props.match.params.id);
    this.props.getClubs();
    this.props.getAnnuityTypes();
    this.props.getWaysPay();
  }

  render() {
    return (
      <main>
        <Helmet title="Annuities Quote" />
        <Layout>
          {this.props.quote && this.props.clubs && (
            <AnnuitiesQuoteById
              payAnnuityQuote={this.props.payAnnuityQuote}
              quote={this.props.quote}
              loading={this.props.loading}
              clubs={this.props.clubs}
              annuityTypes={this.props.annuityTypes}
              waysPay={this.props.waysPay}
            />
          )}
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getAnnuityQuoteById: (id: string) => {
    dispatch(annuityActions.getAnnuityQuoteById(id));
  },
  payAnnuityQuote: (id: string) => {
    dispatch(annuityActions.payAnnuityQuote(id));
  },
  getClubs: () => {
    dispatch(catalogActions.getClubs());
  },
  getAnnuityTypes: () => {
    dispatch(catalogActions.getAnnuityTypes());
  },
  getWaysPay: () => {
    dispatch(catalogActions.getWaysPay());
  }
});

const mapStateToProps = state => {
  const { catalogs, annuities } = state;
  return {
    clubs: catalogs.clubs,
    annuityTypes: catalogs.annuityTypes,
    waysPay: catalogs.waysPay,
    quote: annuities.quoteById,
    loading: annuities.savingPayQuote
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnnuitiesQuotePage);
