import React from "react";
import { withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./styles.scss";
import PriceDataMaintenanceForm from "../priceDataMaintenanceForm";

interface Props {
  history: any;
  match: any;
  addPricesToMaintenance: (
    productId: string,
    tipoProducto: string,
    fechaVigencia: string,
    prices: object
  ) => any;
  getPricesToMaintenance: (params: object) => any;
  prices: object;
  successfulSave: boolean;
  savingPrices: boolean;
  savingPricesError: boolean;
  conceptos: object[];
  estatus: object[];
  onClickSave: (e: object) => any;
  authorizePrices: (prices: object[]) => any;
  clubs: object[];
  getClubsProduct: (tipoProducto: string, id: string) => any;
  loading: boolean;
  onLoading: () => any;
  onSuccessfulSave: () => any;
}

const priceDataMaintenance = (props: Props) => (
  <div>
    <PriceDataMaintenanceForm
      addPricesToMaintenance={props.addPricesToMaintenance}
      getPricesToMaintenance={props.getPricesToMaintenance}
      tipoProducto={props.match.params.tipoProducto}
      productId={props.match.params.productId}
      successfulSave={props.successfulSave}
      conceptos={props.conceptos}
      estatus={props.estatus}
      prices={props.prices}
      savingPrices={props.savingPrices}
      savingPricesError={props.savingPricesError}
      onClickSave={props.onClickSave}
      authorizePrices={props.authorizePrices}
      clubs={props.clubs}
      loading={props.loading}
      getClubsProduct={props.getClubsProduct}
      onLoading={props.onLoading}
      onSuccessfulSave={props.onSuccessfulSave}
    />
  </div>
);

export default withRouter(withStyles(s)(priceDataMaintenance));
