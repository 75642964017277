import React from "react";
import { Container, Input, Row, Col } from "reactstrap";
import moment from "moment";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import StartValidityModal from "../setStartValidity";
import SelectCuentaContable from "../../containers/selectCuentaContable";
import SelectCuentaProducto from "../../containers/selectCuentaProducto";
import {
  PRICES_STATUS,
  MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES
} from "../../../types/MassiveAuthorizationPricesFilter";
import Filters from "./Filters";
import Table from "./Table";
import { errorNotification } from "../../../utils/notifications";
import { setChildStep } from "../../helpers/stepper-state-comparator";

interface Props {
  successfulSave: boolean;
  addPricesToMemberships: (
    productId: string,
    tipoProducto: string,
    prices: object
  ) => any;
  getPricesToMemberships: (params: object) => any;
  onClickSave: (e: object) => any;
  tiposPrecio: TipoPrecio[];
  prices: {
    precio: number;
    maximoDescuento: number;
    cuentaContableId: number;
    cuentaProductoId: number;
  }[];
  tipoProducto: string;
  productId: string;
  estatus: object[];
  loading: boolean;
  savingPrices: boolean;
  savingPricesError: boolean;
  onSuccessfulSave: () => any;
  authorizePrices: (prices: object[]) => any;
  filterTypes: MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES[];
  defaultStatus: PRICES_STATUS;
  memberships: object[];
  disableAmounts: boolean;
}

interface TipoPrecio {
  esquemaPagoId: string;
  nombre: string;
}

class PriceDataMembershipsForm extends React.Component<Props> {
  state = {
    isModalOpen: false,
    data: [],
    checked: {}
  };

  filters = {};

  handleCheckPrice = (id, e) => {
    const value = Boolean(e.target.checked);
    const checked = this.state.checked;
    checked[id] = value;
    this.setState(_ => {
      return { checked };
    });
  };

  handleSelectAll = boolSend => {
    let obj = this.state.checked;
    for (const prop in obj) {
      obj[prop] = boolSend;
    }
    this.setState({ checked: obj });
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.prices && this.props.prices) {
      let checkedInsert = this.state.checked;
      this.props.prices.map(data => {
        checkedInsert[`${data.clubId}-${data.precioId}`] = false;
      });
      const data = this.props.prices.map(price => {
        return {
          ...price,
          maximoDescuento: price.maximoDescuento || "",
          cuentaContableId: price.cuentaContableId || "",
          cuentaProductoId: price.cuentaProductoId || "",
          precio: price.precio || ""
        };
      });
      setChildStep(data);
      if (Object.keys(this.state.checked).length == 0) {
        this.setState({
          data,
          checked: checkedInsert
        });
      } else {
        this.setState({
          data
        });
      }
    }
  }

  changeCuentaContable = (e, index) => {
    const tmpData = [...this.state.data];
    tmpData[index][`cuentaContableId`] = e.target.value;
    setChildStep(tmpData);
    this.setState({ tmpData });
  };

  changeCuentaProducto = (e, index) => {
    const tmpData = [...this.state.data];
    tmpData[index][`cuentaProductoId`] = e.target.value;
    setChildStep(tmpData);
    this.setState({ tmpData });
  };

  changeMaximoDescuento = (e, index) => {
    const tmpData = [...this.state.data];
    tmpData[index]["maximoDescuento"] = e.target.value.replace(/[^0-9]/gi, "");
    if (tmpData[index].maximoDescuento != "") {
      tmpData[index][`estatusId`] = PRICES_STATUS.PRICES_ULTIMOS_REGISTRADOS;
    }
    setChildStep(tmpData);
    this.setState({ tmpData });
  };

  renderMaximoDescuento = ({ index, column: { id: columnId } }) => {
    const { data } = this.state;
    return (
      <div
        className={
          data[index].precio &&
          data[index].cuentaContableId &&
          data[index].cuentaProductoId &&
          data[index].estatusId == PRICES_STATUS.PRICES_ULTIMOS_REGISTRADOS
            ? `${s.cellEdit} ${s.renderEditable}`
            : data[index].estatusId == PRICES_STATUS.PRICES_AUTORIZADOS
            ? `${s.cellAutorized} ${s.renderEditable}`
            : data[index].estatusId == PRICES_STATUS.PRICES_NO_AUTORIZADOS
            ? `${s.cellUnautorized} ${s.renderEditable}`
            : `${s.cell} ${s.renderEditable}`
        }
      >
        <Input
          className={s.inputMaximoDescuento}
          name="maximoDescuento"
          type="numbero"
          id="maximoDescuento"
          size="sm"
          value={data[index][columnId]}
          onChange={e => {
            if (this.props.disableAmounts) {
              return;
            }
            this.changeMaximoDescuento(e, index);
          }}
        />
      </div>
    );
  };

  renderEditable = ({ index }) => {
    const priceInfo = this.state.data[index];
    const {
      precioId,
      clubId,
      precio,
      cuentaContableId,
      cuentaProductoId,
      estatusId
    } = priceInfo;

    return (
      <div
        className={
          precio &&
          cuentaContableId &&
          cuentaProductoId &&
          estatusId == PRICES_STATUS.PRICES_ULTIMOS_REGISTRADOS
            ? `${s.cellEdit} ${s.renderEditable}`
            : estatusId == PRICES_STATUS.PRICES_AUTORIZADOS
            ? `${s.cellAutorized} ${s.renderEditable}`
            : estatusId == PRICES_STATUS.PRICES_NO_AUTORIZADOS
            ? `${s.cellUnautorized} ${s.renderEditable}`
            : `${s.cell} ${s.renderEditable}`
        }
      >
        {(estatusId == PRICES_STATUS.PRICES_NO_AUTORIZADOS ||
          estatusId == PRICES_STATUS.PRICES_ULTIMOS_REGISTRADOS) && (
          <Input
            type="checkbox"
            className={`${s.renderEditableCheckBox}`}
            checked={this.state.checked[`${clubId}-${precioId}`]}
            onChange={e => this.handleCheckPrice(`${clubId}-${precioId}`, e)}
          />
        )}
        <Input
          type="text"
          className={s.renderEditableInput}
          value={this.state.data[index].precio}
          onChange={(e: any) => {
            e.preventDefault();
            if (this.props.disableAmounts) {
              return;
            }

            const tmpData = [...this.state.data];
            tmpData[index].precio = e.target.value.replace(/[^0-9]/gi, "");
            if (tmpData[index].precio != "") {
              tmpData[index][`estatusId`] =
                PRICES_STATUS.PRICES_ULTIMOS_REGISTRADOS;
            }

            setChildStep(tmpData);
            this.setState({ tmpData });
          }}
        />
      </div>
    );
  };

  renderCuentaContableSelector = ({ index, column: { id: columnId } }) => {
    const { data } = this.state;
    return (
      <SelectCuentaContable
        size="sm"
        value={data[index][columnId]}
        onChange={e => this.changeCuentaContable(e, index)}
      />
    );
  };

  renderCuentaProductoSelector = ({ index, column: { id: columnId } }) => {
    const { data } = this.state;
    return (
      <SelectCuentaProducto
        size="sm"
        value={data[index][columnId]}
        onChange={e => this.changeCuentaProducto(e, index)}
      />
    );
  };

  componentDidMount() {
    this.props.onClickSave &&
      this.props.onClickSave(e => {
        e.preventDefault();
        this.modalToggle();
      });
  }

  search = filters => {
    if (!filters.membresia && this.props.productId) {
      filters.membresia = this.props.productId;
    }

    this.props.getPricesToMemberships(filters);
  };

  modalToggle = () => {
    this.setState(state => {
      const isModalOpen = !this.state.isModalOpen;
      return { ...state, isModalOpen };
    });
  };

  shouldComponentUpdate(nextProps) {
    return !(
      this.props.successfulSave !== nextProps.successfulSave &&
      nextProps.successfulSave
    );
  }

  refreshPrices = actionPromise => {
    return actionPromise.then(() => {
      this.setState(() => ({ isModalOpen: false }));
      return this.search(this.filters);
    });
  };

  authorize = () => {
    if (this.validateValues(PRICES_STATUS.PRICES_NO_AUTORIZADOS)) {
      const checked = this.state.checked;
      const { data } = this.state;
      const pricesToAuthorize = data
        .filter(
          ({ clubId, precioId, estatusId }) =>
            checked[`${clubId}-${precioId}`] &&
            estatusId == PRICES_STATUS.PRICES_NO_AUTORIZADOS
        )
        .map(
          ({
            clubId, // membresia,
            membresiaId,
            tipoPrecioId,
            cuentaContableId,
            cuentaProductoId
          }) => ({
            clubId,
            membresiaId,
            // membresiaId: membresia.datosBasicosMembresiaId,
            tipoPrecioId,
            cuentaContableId,
            cuentaProductoId
          })
        );

      this.refreshPrices(this.props.authorizePrices(pricesToAuthorize));
    }
  };

  save = inicioVigencia => {
    if (this.validateValues(PRICES_STATUS.PRICES_ULTIMOS_REGISTRADOS)) {
      const checked = this.state.checked;
      const data = this.state.data;

      const updateData = data
        .filter(
          ({ clubId, precioId, estatusId }) =>
            checked[`${clubId}-${precioId}`] &&
            estatusId == PRICES_STATUS.PRICES_ULTIMOS_REGISTRADOS
        )
        .map(priceInfo => {
          return {
            ...priceInfo,
            inicioVigencia: moment.utc(inicioVigencia),
            estatusId: PRICES_STATUS.PRICES_NO_AUTORIZADOS
          };
        });

      this.refreshPrices(
        this.props.addPricesToMemberships(
          this.props.productId,
          this.props.tipoProducto,
          updateData
        )
      );
    }
  };

  validateValues = (estatusPrice: string) => {
    const { checked, data } = this.state;
    const updateData = data.filter(
      ({ clubId, precioId, estatusId }) =>
        checked[`${clubId}-${precioId}`] && estatusId == estatusPrice
    );
    if (updateData.length > 0) {
      for (const itemData of updateData) {
        const precioSW = itemData.precio !== "" ? true : false;
        if (!precioSW) {
          errorNotification("Falta por ingresar uno o más precios");
          return false;
        }
      }
      return true;
    } else {
      errorNotification("Sin membresías seleccionadas");
      return false;
    }
  };

  onChangeFilters = filters => {
    this.filters = filters;
  };

  render() {
    return (
      <Container fluid className="m-0 p-0">
        <StartValidityModal
          isOpen={this.state.isModalOpen}
          toggle={this.modalToggle}
          save={this.save}
          savingPrices={this.props.savingPrices}
          savingPricesError={this.props.savingPricesError}
        />
        <Filters
          tiposPrecio={this.props.tiposPrecio}
          estatus={this.props.estatus}
          search={this.search}
          onChange={this.onChangeFilters}
          filterTypes={this.props.filterTypes}
          defaultStatus={this.props.defaultStatus}
          searchOnLoad
          memberships={this.props.memberships}
        />
        <hr />

        <Table
          renderEditable={this.renderEditable}
          renderMaximoDescuento={this.renderMaximoDescuento}
          renderCuentaContableSelector={this.renderCuentaContableSelector}
          renderCuentaProductoSelector={this.renderCuentaProductoSelector}
          authorize={this.authorize}
          data={this.state.data}
          loading={this.props.loading}
          filters={this.filters}
          handleSelectAll={this.handleSelectAll}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(PriceDataMembershipsForm));
