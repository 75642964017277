import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import * as catalogActions from "../../actions/catalogs";
import * as productActions from "../../actions/products";
import * as sportsProductsActions from "../../actions/sportsProducts";
import ProductsAuthorization from "../../components/productsAuthorization";

interface Props {
  getConcepts: () => any;
  getStatus: () => any;
  getPaymentTypes: () => any;
  getAccountingAccounts: () => any;
  getProductAccounts: () => any;
  getClubs: () => any;
  getMaintenances: () => any;
  getPricesToMemberships: (params: object) => any;
  conceptos: Catalog[];
  tiposPrecio: tipoPrecio[];
  // cuentasContables: object[];
  // cuentasProductos: object[];
  clubs: object[];
  pricesMemberships: Prices;
  loading: boolean;
  authorizePricesMemberhips: (prices: object[]) => any;
  authorizePricesMaintenances: (prices: object[]) => any;
  maintenances: object[];
  getPricesToMaintenance: (params: object) => any;
  getPricesToSportsProducts: (params: object) => any;
  authorizePricesMassive: (prices: object[]) => any;
  getSportsProducts: () => any;
  sportsProducts: object[];
  pricesToProduct: object[];
  loadingPricesToProduct: boolean;
  pricesMaintenances: object[];
  loadingMaintenances: boolean;
}
interface PriceData {
  clubId: string;
  concepto: string;
  nombre: string;
  inicioVigencia: string;
  finVigencia: string;
  precio: string;
  cuentaContable: string;
  estatus: string;
}

interface Prices {
  content: PriceData[];
  totalPages: string;
}

interface tipoPrecio {
  esquemaPagoId: string;
  nombre: string;
}

interface Catalog {
  id: string;
  nombre: string;
}

class ProductsAuthorizationPage extends React.Component<Props> {
  componentDidMount() {
    this.props.getConcepts();
    this.props.getStatus();
    this.props.getPaymentTypes();
    this.props.getAccountingAccounts();
    this.props.getProductAccounts();
    this.props.getClubs();
    this.props.getMaintenances();
    this.props.getSportsProducts();
  }

  render() {
    return (
      <main>
        <Helmet title="Products Authorization" />
        <Layout>
          <ProductsAuthorization
            getPricesToMemberships={this.props.getPricesToMemberships}
            pricesMemberships={this.props.pricesMemberships}
            loading={this.props.loading}
            tiposPrecio={this.props.tiposPrecio}
            conceptos={this.props.conceptos}
            authorizePricesMemberhips={this.props.authorizePricesMemberhips}
            clubs={this.props.clubs}
            getPricesToMaintenance={this.props.getPricesToMaintenance}
            authorizePricesMaintenances={this.props.authorizePricesMaintenances}
            maintenances={this.props.maintenances}
            sportsProducts={this.props.sportsProducts}
            getPricesToSportsProducts={this.props.getPricesToSportsProducts}
            authorizePricesMassive={this.props.authorizePricesMassive}
            pricesToProduct={this.props.pricesToProduct}
            loadingPricesToProduct={this.props.loadingPricesToProduct}
            pricesMaintenances={this.props.pricesMaintenances}
            loadingMaintenances={this.props.loadingMaintenances}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getConcepts: () => {
    dispatch(catalogActions.getConcepts());
  },
  getStatus: () => {
    dispatch(catalogActions.getStatus());
  },
  getPaymentTypes: () => {
    dispatch(catalogActions.getPaymentTypes());
  },
  getAccountingAccounts: () => {
    dispatch(catalogActions.getAccountingAccounts());
  },
  getProductAccounts: () => {
    dispatch(catalogActions.getProductAccounts());
  },
  getClubs: () => {
    dispatch(catalogActions.getClubs());
  },
  getMaintenances: () => {
    dispatch(catalogActions.getMaintenances());
  },
  getSportsProducts: () => {
    dispatch(catalogActions.getSportsProducts());
  },
  getPricesToSportsProducts: (params: object) => {
    return dispatch(
      sportsProductsActions.getPricesToSportsProductsMassive(params)
    );
  },
  authorizePricesMassive: (prices: object[]) => {
    return dispatch(sportsProductsActions.authorizePricesMassive(prices));
  },
  getPricesToMemberships: (params: object) => {
    return dispatch(productActions.getPricesToMemberships(params));
  },
  getPricesToMaintenance: (params: object) => {
    return dispatch(productActions.getPricesToMaintenanceMassive(params));
  },
  authorizePricesMaintenances: (prices: object[]) => {
    return dispatch(productActions.authorizeMaintenancesPricesMasive(prices));
  }
});

const mapStateToProps = state => {
  const { catalogs, products, sportsProducts } = state;
  return {
    conceptos: catalogs.conceptos,
    tiposPrecio: catalogs.tiposPrecio,
    maintenances: catalogs.maintenances,
    clubs: catalogs.clubs,
    pricesMemberships: products.pricesToProduct,
    pricesMaintenances: products.pricesToMaintenance,
    loadingMaintenances: products.loadingPricesToMaintenance,
    loading: products.loadingPricesToProduct,
    sportsProducts: catalogs.sportsProducts,
    pricesToProduct: sportsProducts.pricesToProduct,
    loadingPricesToProduct: sportsProducts.loadingPricesToProduct
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsAuthorizationPage);
