import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import {
  infoNotification,
  errorNotification,
  successNotification,
  NEW_ACTIVITY_USER_PENDING,
  NEW_ACTIVITY_USER_REJECTED,
  NEW_ACTIVITY_USER_FULFILLED,
  MARK_USER_ACTIVITY_CHECKED_PENDING,
  MARK_USER_ACTIVITY_CHECKED_REJECTED,
  MARK_USER_ACTIVITY_CHECKED_FULFILLED,
  SAVE_EVIDENCE_PENDING,
  SAVE_EVIDENCE_REJECTED,
  SAVE_EVIDENCE_FULFILLED
} from "../../utils/notifications";

interface State {}

const initialState: State = {
  userActivitySaved: false
};

export default function activitiesReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.NEW_USER_ACTIVITY}_PENDING`: {
      infoNotification(NEW_ACTIVITY_USER_PENDING);
      return {
        ...state,
        userActivitySaved: false
      };
    }
    case `${actionTypes.NEW_USER_ACTIVITY}_REJECTED`: {
      errorNotification(NEW_ACTIVITY_USER_REJECTED);
      return {
        ...state,
        userActivitySaved: false
      };
    }
    case `${actionTypes.NEW_USER_ACTIVITY}_FULFILLED`: {
      successNotification(NEW_ACTIVITY_USER_FULFILLED);
      return {
        ...state,
        userActivitySaved: true
      };
    }
    case `${actionTypes.MARK_AS_SEEN}_PENDING`: {
      infoNotification(MARK_USER_ACTIVITY_CHECKED_PENDING);
      return {
        ...state,
        activitySeenSuccess: false
      };
    }
    case `${actionTypes.MARK_AS_SEEN}_REJECTED`: {
      errorNotification(MARK_USER_ACTIVITY_CHECKED_REJECTED);
      return {
        ...state,
        activitySeenSuccess: false
      };
    }
    case `${actionTypes.MARK_AS_SEEN}_FULFILLED`: {
      successNotification(MARK_USER_ACTIVITY_CHECKED_FULFILLED);
      return {
        ...state,
        activitySeenSuccess: true
      };
    }
    case `${actionTypes.SAVE_EVIDENCE}_PENDING`: {
      infoNotification(SAVE_EVIDENCE_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.SAVE_EVIDENCE}_REJECTED`: {
      errorNotification(SAVE_EVIDENCE_REJECTED);
      return {
        ...state
      };
    }
    case `${actionTypes.SAVE_EVIDENCE}_FULFILLED`: {
      successNotification(SAVE_EVIDENCE_FULFILLED);
      return {
        ...state
      };
    }
    default: {
      return state;
    }
  }
}
