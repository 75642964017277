import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";
import FileDownload from "js-file-download";
import moment from "moment";
import { errorNotification } from "../../utils/notifications";

const PARTNERS_BASE_URL = "/api/partners";

export const searchMovementsByClubAndMembership = (params: object): IAction => {
  return {
    type: actionTypes.GET_MOVEMENTS_CLUB_MEMBERSHIP,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/movements`,
        {
          withCredentials: true,
          params
        }
      )
    }
  };
};

export const searchMovementsByClubAndMembershipRequest = (params: object) => {
  return axios.get(`${constants.BASE_URL}${PARTNERS_BASE_URL}/movements`, {
    withCredentials: true,
    params
  });
};

export const authorizeMembership = (idMembresiaSocio: number): IAction => {
  return {
    type: actionTypes.AUTHORIZE_EXPEDIENT,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/${idMembresiaSocio}/expedient/authorize`,
        {},
        {
          withCredentials: true
        }
      )
    }
  };
};

export const sendMovementEmail = (
  idMovimiento: number,
  email: string
): IAction => {
  return {
    type: actionTypes.SEND_EMAIL_MOVEMENTS,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/movements/email`,
        { idMovimiento, email },
        {
          withCredentials: true
        }
      )
    }
  };
};

export const sendEmailFile = (
  idTipoDocumento: string,
  idMembresiaSocio: string,
  idPersona: string
): IAction => {
  return {
    type: actionTypes.SEND_EMAIL_FILE,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/documents/email`,
        { idTipoDocumento, idMembresiaSocio, idPersona },
        {
          withCredentials: true
        }
      )
    }
  };
};

export const recreateFile = (
  idTipoDocumento: string,
  idMembresiaSocio: string,
  idPersona: string
): IAction => {
  return {
    type: actionTypes.RECREATE_FILE,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/documents/recreate`,
        { idTipoDocumento, idMembresiaSocio, idPersona },
        {
          withCredentials: true
        }
      )
    }
  };
};

export const sendScannedFile = (
  idTipoDocumento: string,
  idMembresiaSocio: string,
  idPersona: string,
  file: Blob,
  fileName: string
): IAction => {
  const data = new FormData();
  data.append("file", file);
  data.append("filename", fileName);
  data.append("idTipoDocumento", idTipoDocumento);
  data.append("idMembresiaSocio", idMembresiaSocio);
  data.append("idPersona", idPersona);
  data.append("idDocumento", idTipoDocumento);

  return {
    type: actionTypes.SEND_SCANNED_FILE,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/documents/upload`,
        data,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getDocumentsExpedients = (params: object): IAction => {
  return {
    type: actionTypes.GET_DOCUMENTS_EXPEDIENTS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/documents/expedient`,
        {
          withCredentials: true,
          params
        }
      )
    }
  };
};

export const getDocumentsExpedientsPagination = (params: object): IAction => {
  return {
    type: actionTypes.GET_DOCUMENTS_EXPEDIENTS_PAGINATION,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/documents/expedient-pagination`,
        {
          withCredentials: true,
          params
        }
      )
    }
  };
};

export const getPartner = (params: object): IAction => {
  return {
    type: actionTypes.GET_PARTNER,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/partner/find`,
        {
          withCredentials: true,
          params
        }
      )
    }
  };
};

export const getPartnerRequest = (params: object) => {
  return axios.get(`${constants.BASE_URL}${PARTNERS_BASE_URL}/partner/find`, {
    withCredentials: true,
    params
  });
};

export const getPartnerTypes = (): IAction => {
  return {
    type: actionTypes.GET_PARTNER_TYPES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/partner-types`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const linkPartner = (
  idMembresiaSocio: string,
  partnerType: string,
  id: string,
  isFreePartner: boolean,
  isFreeChildPartner: boolean,
  isChangeFreePartner: boolean,
  changeFreePartnerData: any
): IAction => {
  return {
    type: actionTypes.LINK_PARTNER,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/${idMembresiaSocio}/link`,
        {
          idTipoIntegrante: partnerType,
          idPersona: id,
          esInvitadoGratis: isFreePartner || false,
          esNinoGratis: isFreeChildPartner || false,
          esCambioInvitadoGratis: isChangeFreePartner || false,
          datosCambioInvitadoGratis: changeFreePartnerData || null
        },
        {
          withCredentials: true
        }
      )
    }
  };
};

export const createPartner = (
  idMembresiaSocio: string,
  partnerType: string,
  partner: object,
  isFreePartner: boolean,
  isFreeChildPartner: boolean,
  isChangeFreePartner: boolean,
  changeFreePartnerData: any
): IAction => {
  return {
    type: actionTypes.CREATE_PARTNER,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/${idMembresiaSocio}/link/new`,
        {
          ...partner,
          idTipoIntegrante: partnerType,
          esInvitadoGratis: isFreePartner || false,
          esNinoGratis: isFreeChildPartner || false,
          esCambioInvitadoGratis: isChangeFreePartner || false,
          datosCambioInvitadoGratis: changeFreePartnerData || null
        },
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getDocument = (
  idTipoDocumento: string,
  idMembresiaSocio: string,
  idPersona: string,
  instanceData?: object
): IAction => {
  return {
    type: actionTypes.GET_DOCUMENT,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/documents`,
        { idTipoDocumento, idMembresiaSocio, idPersona, instanceData },
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getDocumentSignature = (
  idTipoDocumento: string,
  idMembresiaSocio: string,
  idPersona: string
): IAction => {
  return {
    type: actionTypes.GET_DOCUMENT_SIGNATURE,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/documents/instance/signature`,
        {
          withCredentials: true,
          params: { idTipoDocumento, idMembresiaSocio, idPersona }
        }
      )
    }
  };
};

export const getPartnerMembershipDocumentInstancesRequest = (
  membresiaSocioId: string
) => {
  return axios.get(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/documents/instances`,
    {
      withCredentials: true,
      params: { membresiaSocioId }
    }
  );
};

export const authorizeDocument = (
  idTipoDocumento: string,
  idMembresiaSocio: string,
  idPersona: string
): IAction => {
  return {
    type: actionTypes.AUTHORIZE_DOCUMENT,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/documents/authorize`,
        {
          documentoId: idTipoDocumento,
          membresiaSocioId: idMembresiaSocio,
          personaId: idPersona
        },
        {
          withCredentials: true
        }
      )
    }
  };
};

export const printDocument = (
  idTipoDocumento: string,
  idMembresiaSocio: string,
  idPersona: string
): IAction => {
  return {
    type: actionTypes.PRINT_DOCUMENT,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/documents/print`,
        { idTipoDocumento, idMembresiaSocio, idPersona },
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getDocumentForSign = (
  idTipoDocumento: string,
  idMembresiaSocio: string,
  idPersona: string
): IAction => {
  return {
    type: actionTypes.GET_DOCUMENT_TO_SIGN,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/documents/to-sign`,
        { idTipoDocumento, idMembresiaSocio, idPersona },
        {
          withCredentials: true
        }
      )
    }
  };
};

export const saveDocumentSignature = (
  idTipoDocumento: string,
  idMembresiaSocio: string,
  idPersona: string,
  signature: string
): IAction => {
  return {
    type: actionTypes.SIGN_DOCUMENT,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/documents/signature`,
        { idTipoDocumento, idMembresiaSocio, idPersona, signature },
        {
          withCredentials: true
        }
      )
    }
  };
};

export const cleanDocument = (): IAction => {
  return {
    type: actionTypes.CLEAN_DOCUMENT,
    payload: null
  };
};

export const getParnerDocuments = (
  idMembresiaSocio: string,
  idPersona
): IAction => {
  const params = {
    idMembresiaSocio,
    idPersona
  };
  return {
    type: actionTypes.GET_PARTNER_DOCUMENTS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/documents/partner`,
        {
          withCredentials: true,
          params
        }
      )
    }
  };
};

export const getPartnersMembership = (
  idMembresiaSocio: string,
  idMantenimiento: string,
  idMembresia: string
): IAction => {
  const params = {
    idMembresiaSocio,
    idMantenimiento,
    idMembresia
  };
  return {
    type: actionTypes.GET_PARTNERS_MEMBERSHIP,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/partners-membership/find`,
        {
          withCredentials: true,
          params
        }
      )
    }
  };
};

export const getPartnersMembershipRequest = (
  idMembresiaSocio: string,
  idMantenimiento: string,
  idMembresia: string
) => {
  const params = {
    idMembresiaSocio,
    idMantenimiento,
    idMembresia
  };
  return axios.get(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/partners-membership/find`,
    {
      withCredentials: true,
      params
    }
  );
};

export const updatePhoto = (
  idMantenimiento: string,
  idMembresia: string,
  idMembresiaSocio: string,
  idPersona: string,
  photo: Blob
): IAction => {
  const data = new FormData();
  data.append("fotoContenido", photo);
  return {
    type: actionTypes.PARTNER_UPDATE_PHOTO,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/partner/photo`,
        data,
        {
          withCredentials: true,
          params: { idPersona, idMantenimiento, idMembresia, idMembresiaSocio }
        }
      )
    }
  };
};

export const updatePawPrint = (
  idMantenimiento: string,
  idMembresia: string,
  idMembresiaSocio: string,
  idPersona: string,
  pawPrint: string
): IAction => {
  return {
    type: actionTypes.PARTNER_UPDATE_PAW_PRINT,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/partner/paw-print`,
        { idMembresia, idMantenimiento, idMembresiaSocio, idPersona, pawPrint },
        {
          withCredentials: true
        }
      )
    }
  };
};

export const accessRegister = (idPersona: string): IAction => {
  return {
    type: actionTypes.PARTNER_ACCESS_REGISTER,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/partner/access-register`,
        { idPersona },
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getBankCards = (id: string): IAction => {
  return {
    type: actionTypes.GET_PARTNER_BANK_CARDS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/bank_card/${id}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getPaymentMethodsRequest = (partnerMembershipId: string) => {
  return axios.get(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/bank_card/${partnerMembershipId}`,
    {
      withCredentials: true
    }
  );
};

export const saveBankCard = (fields: object): IAction => {
  return {
    type: actionTypes.PARTNER_SAVE_BANK_CARD,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/bank_card`,
        fields,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const uploadBankCard = (id: string, fields: object): IAction => {
  return {
    type: actionTypes.PARTNER_UPDATE_BANK_CARD,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/bank_card/${id}`,
        fields,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const deleteBankCard = (
  id: string,
  reason: string,
  questionResponse: string
): IAction => {
  return {
    type: actionTypes.PARTNER_DELETE_BANK_CARD,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/bank_card/${id}/${reason}`,
        {
          medioPagoId: id,
          razon: reason,
          respuestaPreguntaPermanencia: questionResponse
        },
        {
          withCredentials: true
        }
      )
    }
  };
};

export const deleteBankCardRequest = (payload: any) => {
  return axios.post(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/bank_card/${payload.medioPagoId}/delete`,
    payload,
    {
      withCredentials: true
    }
  );
};

export const copyBankCardRequest = (
  membresiaSocioId: string,
  medioPagoId: string
) => {
  return axios.post(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/bank_card/${membresiaSocioId}/copy/${medioPagoId}`,
    null,
    {
      withCredentials: true
    }
  );
};

export const toggleDeletePartner = (
  partner?: object,
  idMembresiaSocio?: string
): IAction => ({
  type: actionTypes.DELETE_PARTNER_MODAL,
  payload: { ...partner, idMembresiaSocio: idMembresiaSocio }
});

export const toggleChangeMembership = (partner?: object): IAction => ({
  type: actionTypes.CHANGE_MEMBERSHIP_MODAL,
  payload: partner
});

export const deletePartner = (
  id: string,
  fecha: string,
  motive: string,
  idMembresiaSocio: string
): IAction => {
  const data = {
    personaId: id,
    activoHasta: fecha,
    motivo: motive
  };
  return {
    type: actionTypes.DELETE_PARTNER,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/${idMembresiaSocio}/delete-member`,
        data,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const deletePartnerRequest = (
  id: string,
  fecha: string,
  motive: string,
  idMembresiaSocio: string
) => {
  const data = {
    personaId: id,
    activoHasta: fecha,
    motivo: motive
  };
  return axios.put(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/${idMembresiaSocio}/delete-member`,
    data,
    {
      withCredentials: true
    }
  );
};

export const getActualQuote = (idPublico: string, idClub: string): IAction => {
  return {
    type: actionTypes.GET_ACTUAL_QUOTE,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/quotation/${idPublico}/${idClub}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getActualQuoteRequest = (idPublico: string, idClub: string) => {
  return axios.get(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/quotation/${idPublico}/${idClub}`,
    {
      withCredentials: true
    }
  );
};

export const getFutureChargesQuote = (
  idPublico: string,
  idClub: string
): IAction => {
  return {
    type: actionTypes.GET_FUTURE_QUOTE,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/quotation-future-payments/${idPublico}/${idClub}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getFutureChargesQuoteRequest = (
  idPublico: string,
  idClub: string
) => {
  return axios.get(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/quotation-future-payments/${idPublico}/${idClub}`,
    {
      withCredentials: true
    }
  );
};

export const switchMembership = (
  data: object,
  idMembresiaSocio: string,
  idClub: string,
  validate: string
): IAction => {
  return {
    type: actionTypes.SWITCH_MEMBERSHIP,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/change-membership/${idMembresiaSocio}/${idClub}/${validate}`,
        {
          ...data,
          idMembresiaSocio
        },
        {
          withCredentials: true
        }
      )
    }
  };
};

export const switchMembershipRequest = (
  data: object,
  idMembresiaSocio: string,
  idClub: string,
  validate: string
) => {
  return axios.put(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/change-membership/${idMembresiaSocio}/${idClub}/${validate}`,
    {
      ...data,
      idMembresiaSocio
    },
    {
      withCredentials: true
    }
  );
};

export const switchMembershipValidationRequest = (
  data: object,
  idMembresiaSocio: string
) => {
  return axios.post(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/change-membership-validate/${idMembresiaSocio}`,
    {
      ...data,
      idMembresiaSocio
    },
    {
      withCredentials: true
    }
  );
};

export const reactivateMembership = (
  data: object,
  idMembresiaSocio: string,
  idClub: string,
  validate: string
): IAction => {
  return {
    type: actionTypes.REACTIVATE_MEMBERSHIP,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/switch-membership/${idMembresiaSocio}/${idClub}/${validate}`,
        {
          ...data,
          idMembresiaSocio
        },
        {
          withCredentials: true
        }
      )
    }
  };
};

export const reactivateMembershipRequest = (
  data: object,
  idMembresiaSocio: string,
  idClub: string,
  validate: string
) => {
  return axios.put(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/reactivate-membership/${idMembresiaSocio}/${idClub}/${validate}`,
    { ...data, idMembresiaSocio },
    { withCredentials: true }
  );
};

export const reactivateMembershipValidationRequest = (
  data: object,
  idMembresiaSocio: string
) => {
  return axios.put(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/reactivate-membership-validate/${idMembresiaSocio}`,
    { ...data, idMembresiaSocio },
    { withCredentials: true }
  );
};

export const cancelMembershipRequest = (data: object) => {
  return axios.put(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/cancel-membership/${data.membresiaSocioId}`,
    data,
    { withCredentials: true }
  );
};

export const cancelMembershipValidationRequest = (idMembresiaSocio: string) => {
  return axios.put(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/cancel-membership-validate/${idMembresiaSocio}`,
    { idMembresiaSocio },
    { withCredentials: true }
  );
};

export const transferClubMembershipRequest = (
  data: object,
  idMembresiaSocio: string,
  idClub: string,
  validate: string
) => {
  return axios.put(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/transfer-club-membership/${idMembresiaSocio}/${idClub}/${validate}`,
    { ...data, idMembresiaSocio },
    { withCredentials: true }
  );
};

export const transferClubMembershipValidationRequest = (
  data: object,
  idMembresiaSocio: string
) => {
  return axios.put(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/transfer-club-membership-validate/${idMembresiaSocio}`,
    { ...data, idMembresiaSocio },
    { withCredentials: true }
  );
};

export const getMessages = (membresiaSocioId: string): IAction => {
  return {
    type: actionTypes.GET_MESSAGES_BY_MEMBRESIA,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/messages/${membresiaSocioId}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const addMessage = (data: object): IAction => {
  return {
    type: actionTypes.ADD_MESSAGE,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/add-message`,
        data,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const updateMessage = (
  data: object,
  notificacionId: string
): IAction => {
  return {
    type: actionTypes.UPDATE_MESSAGE,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/update-message/${notificacionId}`,
        data,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getMessagesRequest = (membresiaSocioId: string) => {
  return axios.get(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/messages/${membresiaSocioId}`,
    {
      withCredentials: true
    }
  );
};

export const addMessageRequest = (data: object) => {
  return axios.post(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/add-message`,
    data,
    {
      withCredentials: true
    }
  );
};

export const updateMessageRequest = (data: object, notificacionId: string) => {
  return axios.put(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/update-message/${notificacionId}`,
    data,
    {
      withCredentials: true
    }
  );
};

export const updateTitular = (
  data: object,
  membresiaSocioId: string
): IAction => {
  return {
    type: actionTypes.UPDATE_TITULAR,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/update-titular/${membresiaSocioId}`,
        data,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const updateTitularRequest = (
  data: object,
  membresiaSocioId: string
) => {
  return axios.put(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/update-titular/${membresiaSocioId}`,
    data,
    {
      withCredentials: true
    }
  );
};

export const updatePartnerMembershipStatus = (
  membresiaSocioId: string,
  status: string
) => {
  return axios.put(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/update/${membresiaSocioId}/status/${status}`,
    null,
    {
      withCredentials: true
    }
  );
};

export const updateConvenio = (
  data: object,
  membresiaSocioId: string
): IAction => {
  return {
    type: actionTypes.UPDATE_CONVENIO,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/update-convenio/${membresiaSocioId}`,
        data,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const updateConvenioRequest = (
  convenioId: string,
  membresiaSocioId: string,
  empresaId: string
) => {
  return axios.put(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/update-convenio/${membresiaSocioId}`,
    { convenioId, empresaId },
    {
      withCredentials: true
    }
  );
};

export const putDesasociarConvenio = (membresiaSocioId: string) => {
  return axios.put(
    `${constants.BASE_URL}/api/memberships/${membresiaSocioId}/desasociar-convenio`,
    {},
    {
      withCredentials: true
    }
  );
};

export const associatePromotion = (
  partnerMembershipId: string,
  promotionId: string
) => {
  const url = `${constants.BASE_URL}/api/memberships/${partnerMembershipId}/associate-promotion/${promotionId}`;
  const config = { withCredentials: true };
  return axios.put(url, {}, config);
};

export const associateAlternateClub = (
  partnerMembershipId: string,
  alternateClubId: string
) => {
  const url = `${constants.BASE_URL}/api/memberships/${partnerMembershipId}/associate-alternate-club/${alternateClubId}`;
  const config = { withCredentials: true };
  return axios.put(url, {}, config);
};

export const getReportPersonIngressByPartner = (params): IAction => {
  return {
    type: actionTypes.GET_INGRESS_BY_PARTNER_REPORT,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/ingress-by-partner`,
        {
          withCredentials: true,
          params
        }
      )
    }
  };
};

export const getReportPersonIngressByPartnerRequest = params => {
  return axios.get(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/ingress-by-partner`,
    {
      withCredentials: true,
      params
    }
  );
};

export const clearReportPersonIngressByPartner = () => {
  return {
    type: actionTypes.CLEAR_INGRESS_BY_PARTNER_REPORT,
    payload: {}
  };
};

export const getPartnerMembershipByPartnerId = (partnerId: number) => {
  return axios.get(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/find-by-partner/${partnerId}`,
    {
      withCredentials: true
    }
  );
};

export const getSpecialGuests = (params): IAction => {
  return {
    type: actionTypes.GET_SPECIAL_GUESTS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/special-guests`,
        {
          withCredentials: true,
          params
        }
      )
    }
  };
};

export const downloadSpecialGuestsFile = (params: any): IAction => {
  return {
    type: actionTypes.GET_SPECIAL_GUESTS_FILE,
    payload: {
      promise: axios
        .get(
          `${constants.BASE_URL}${PARTNERS_BASE_URL}/special-guests/download`,
          {
            withCredentials: true,
            params,
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          FileDownload(
            data,
            `InvitadosEspeciales_${moment().format("YYYY-MM-DD")}.csv`
          );
        })
    }
  };
};

export const clearSpecialGuests = () => {
  return {
    type: actionTypes.CLEAR_SPECIAL_GUESTS,
    payload: {}
  };
};

export const saveSpecialGuest = (data: object) => {
  return axios.put(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/special-guests`,
    data,
    {
      withCredentials: true
    }
  );
};

export const getSpecialGuestById = specialGuestId => {
  return axios.get(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/special-guests/${specialGuestId}`,
    {
      withCredentials: true
    }
  );
};

export const getDiscountCodes = partnerMembershipId => {
  return axios.get(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/discount-codes/${partnerMembershipId}`,
    {
      withCredentials: true
    }
  );
};

export const getDiscountCodesFitkidz = partnerMembershipId => {
  return axios.get(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/discount-codes-fitkidz/${partnerMembershipId}`,
    {
      withCredentials: true
    }
  );
};

export const generateMonthlyCharges = () => {
  return axios.post(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/maintenance-charges/generate/monthly`,
    null,
    {
      withCredentials: true
    }
  );
};

export const generateCurrentMonthCharge = (
  partnerMembershipId: string,
  enabledForCat: boolean
) => {
  return axios.post(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/maintenance-charges/generate/by-partner-membership/${partnerMembershipId}?habilitadoCargoAutomatico=${enabledForCat}`,
    null,
    {
      withCredentials: true
    }
  );
};

export const generateMonthChargeWithDate = (
  partnerMembershipId: string,
  fecha: string,
  enabledForCat: boolean
) => {
  return axios.post(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/maintenance-charges/generate/by-partner-membership/${partnerMembershipId}?habilitadoCargoAutomatico=${enabledForCat}&fecha=${fecha}`,
    null,
    {
      withCredentials: true
    }
  );
};

export const generateIgualaMaintenanceCharge = (
  partnerMembershipId: string,
  monto: number
) => {
  return axios.post(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/maintenance-charges/generate/iguala/by-partner-membership/${partnerMembershipId}?monto=${monto}`,
    null,
    {
      withCredentials: true
    }
  );
};

export const generateFreeGuestsAccess = (idMembresiaSocio, data) => {
  return axios.post(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/${idMembresiaSocio}/free-guests`,
    data,
    {
      withCredentials: true
    }
  );
};

export const deleteFreeGuestsAccess = (idMembresiaSocio, id) => {
  return axios.delete(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/${idMembresiaSocio}/free-guests/${id}`,
    {
      withCredentials: true
    }
  );
};

export const generateGuestsPassesDiscounts = (idMembresiaSocio, data) => {
  return axios.post(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/${idMembresiaSocio}/guests-passes`,
    data,
    {
      withCredentials: true
    }
  );
};

export const generateProductDiscounts = (idMembresiaSocio, data) => {
  return axios.post(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/${idMembresiaSocio}/products-discounts`,
    data,
    {
      withCredentials: true
    }
  );
};

export const getCrm1Benefits = partnerMembershipId => {
  return axios.get(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/${partnerMembershipId}/crm1/benefits`,
    {
      withCredentials: true
    }
  );
};

export const deleteDiscountCode = (idMembresiaSocio, id) => {
  return axios.delete(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/${idMembresiaSocio}/discount-code/${id}`,
    {
      withCredentials: true
    }
  );
};

export const getComprobarConvenio = membresiaSocioId => {
  return axios.get(
    `${constants.BASE_URL}/api/memberships/${membresiaSocioId}/comprobar-convenio`,
    {
      withCredentials: true
    }
  );
};

export const getMediosPagoEliminados = (params): IAction => {
  return {
    type: actionTypes.GET_MEDIOS_PAGO_ELIMINADOS,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${PARTNERS_BASE_URL}/medios-pago-eliminados`,
        {
          withCredentials: true,
          params
        }
      )
    }
  };
};

export const getMediosPagoEliminadosReporte = (params: any): IAction => {
  return {
    type: actionTypes.GET_MEDIOS_PAGO_ELIMINADOS_REPORTE,
    payload: {
      promise: axios
        .get(
          `${constants.BASE_URL}${PARTNERS_BASE_URL}/medios-pago-eliminados/download`,
          {
            withCredentials: true,
            params,
            responseType: "blob"
          }
        )
        .then(({ data }) => {
          FileDownload(data, `ReporteMediosPagoEliminados.csv`);
        })
    }
  };
};

export const forceBlockClubAccessForPartner = (
  partnerMembershipId,
  personId
) => {
  return axios.put(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/${partnerMembershipId}/force-block-club-access/${personId}`,
    null,
    {
      withCredentials: true
    }
  );
};

export const partnerFingerprintCapturedValidation = (
  partnerMembershipId,
  personId
) => {
  return axios.put(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/${partnerMembershipId}/fingerprint-captured-validation/${personId}`,
    null,
    {
      withCredentials: true
    }
  );
};

export const getPartnerMembershipVacationFees = partnerMembershipId => {
  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/${partnerMembershipId}/vacation-fees`;
  const config = { withCredentials: true };
  return axios.get(url, config);
};

export const createPartnerMembershipVacationFee = (
  partnerMembershipId: string,
  quincena: string
) => {
  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/${partnerMembershipId}/vacation-fee`;
  const data = { membresiaSocioId: partnerMembershipId, quincena };
  const config = { withCredentials: true };
  return axios.post(url, data, config);
};

export const cancelPartnerMembershipVacationFee = (
  partnerMembershipId,
  vacationFeeId
) => {
  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/${partnerMembershipId}/vacation-fee/${vacationFeeId}`;
  const config = { withCredentials: true };
  return axios.delete(url, config);
};

export const applyPartnerMembershipVacationFee = (
  partnerMembershipId,
  vacationFeeId
) => {
  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/${partnerMembershipId}/vacation-fee/${vacationFeeId}/apply`;
  const config = { withCredentials: true };
  return axios.put(url, {}, config);
};

export const signPartnerMembershipVacationFee = (
  partnerMembershipId,
  vacationFeeId
) => {
  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/${partnerMembershipId}/vacation-fee/${vacationFeeId}/sign`;
  const config = { withCredentials: true };
  return axios.put(url, {}, config);
};

export const getAccesos = (idClub, idPublico) => {
  return axios.get(
    `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/asistencias/${idClub}/${idPublico}`,
    {
      withCredentials: true
    }
  );
};

export const getAccesosReporte = (idClub, idPublico, callback: any) => {
  return axios
    .get(
      `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/asistencias/${idClub}/${idPublico}/descargar`,
      {
        withCredentials: true,
        responseType: "blob"
      }
    )
    .then(({ data }) => {
      FileDownload(data, `ReporteAsistencias.csv`);
      callback && callback();
    })
    .catch(error => {
      errorNotification("No se pudo descargar el reporte");
      callback && callback(error);
    });
};

export const getPartnerMembershipMaintenacesDetailReport = partnerMembershipId => {
  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/${partnerMembershipId}/maintenaces-detail/report`;
  const config = { withCredentials: true };
  return axios.get(url, config);
};

export const createPartnerMembershipReferredCodes = (
  partnerMembershipId: string
) => {
  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/${partnerMembershipId}/referred/codes`;
  const config = { withCredentials: true };
  return axios.post(url, {}, config);
};

export const validatePartnerMembershipReferredCode = (code: string) => {
  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/-1/referred/codes/${code}/validate`;
  const config = { withCredentials: true };
  return axios.post(url, {}, config);
};

export const getPartnerMembershipAvailableReferredCodes = partnerMembershipId => {
  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/${partnerMembershipId}/referred/codes/available`;
  const config = { withCredentials: true };
  return axios.get(url, config);
};

export const getPartnerMembershipReferred = partnerMembershipId => {
  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/${partnerMembershipId}/referred`;
  const config = { withCredentials: true };
  return axios.get(url, config);
};

// Licencias de extranjeros
export const getExtranjeros = idMembresiaSocio => {
  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/extranjeros/${idMembresiaSocio}`;
  const config = { withCredentials: true };
  return axios.get(url, config);
};
export const getExtranjerosLicencias = idMembresiaSocio => {
  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/extranjeros/${idMembresiaSocio}/licencias`;
  const config = { withCredentials: true };
  return axios.get(url, config);
};
export const getExtranjerosLicenciaActual = idMembresiaSocio => {
  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/extranjeros/${idMembresiaSocio}/licencia-actual`;
  const config = { withCredentials: true };
  return axios.get(url, config);
};
export const deleteExtranjerosLicenciaActual = idMembresiaSocio => {
  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/extranjeros/${idMembresiaSocio}/licencia-actual`;
  const config = { withCredentials: true };
  return axios.delete(url, config);
};
export const putExtranjeroBandera = (idPersona, esExtranjero) => {
  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/extranjeros/${idPersona}/es-estranjero/${esExtranjero}`;
  const config = { withCredentials: true };
  return axios.put(url, null, config);
};
export const getExtranjerosLicenciaAplicable = (idMembresiaSocio, params) => {
  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/extranjeros/${idMembresiaSocio}/licencia-aplicable`;
  const config = { withCredentials: true, params };
  return axios.get(url, config);
};
export const postExtranjerosLicenciaAplicable = (idMembresiaSocio, params) => {
  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/extranjeros/${idMembresiaSocio}/licencia-aplicable`;
  const config = { withCredentials: true, params };
  return axios.post(url, null, config);
};

export const createExtraClubAccessConfiguration = (
  partnerMembershipId,
  personId,
  data
) => {
  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/${partnerMembershipId}/extra-club-access/${personId}`;
  const config = { withCredentials: true };
  return axios.put(url, data, config);
};

export const deleteExtraClubAccessConfiguration = (
  partnerMembershipId,
  personId,
  data
) => {
  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/${partnerMembershipId}/extra-club-access/${personId}`;
  const config = { withCredentials: true, data };
  return axios.delete(url, config);
};

export const getPartnersClubAccessInfo = partnerMembershipId => {
  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/${partnerMembershipId}/partners-club-access-info`;
  const config = { withCredentials: true };
  return axios.get(url, config);
};

export const getReferidosReporte = (callback: any) => {
  return axios
    .get(
      `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/referidos/descargar`,
      {
        withCredentials: true,
        responseType: "blob"
      }
    )
    .then(({ data }) => {
      FileDownload(data, `ReporteReferidos.csv`);
      callback && callback();
    })
    .catch(error => {
      errorNotification("No se pudo descargar el reporte");
      callback && callback(error);
    });
};

export const getConLicenciaReporte = (callback: any) => {
  return axios
    .get(
      `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/con-licencia/descargar`,
      {
        withCredentials: true,
        responseType: "blob"
      }
    )
    .then(({ data }) => {
      FileDownload(data, `ReporteConLicenciaExtranjero.csv`);
      callback && callback();
    })
    .catch(error => {
      errorNotification("No se pudo descargar el reporte");
      callback && callback(error);
    });
};

export const getPartnerMembershipFitKidzEvidence = partnerMembershipId => {
  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/${partnerMembershipId}/fitkidz/evidence`;
  const config = { withCredentials: true };
  return axios.get(url, config);
};

export const createPartnerMembershipFitKidzEvidence = (
  partnerMembershipId,
  file
) => {
  const data = new FormData();
  data.append("file", file);

  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/${partnerMembershipId}/fitkidz/evidence`;
  const config = { withCredentials: true };
  return axios.post(url, data, config);
};

export const applyPartnerMembershipFitKidzMaintenanceChange = (
  partnerMembershipId,
  newMaintenanceId
) => {
  const data = {
    membresiaSocioId: partnerMembershipId,
    mantenimientoId: newMaintenanceId
  };

  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/${partnerMembershipId}/fitkidz/maintenance-change`;
  const config = { withCredentials: true };
  return axios.post(url, data, config);
};

export const searchCrm1PartnerMembershipsForReactivation = (params: object) => {
  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/reactivate/from-crm1/search`;
  const config = { withCredentials: true, params };
  return axios.get(url, config);
};

export const saveCrm1PartnerMembershipsForReactivation = (data: object) => {
  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/reactivate/from-crm1/apply`;
  const config = { withCredentials: true };
  return axios.post(url, data, config);
};

export const createPartnerMembershipBitacoraMessage = (
  partnerMembershipId,
  message
) => {
  const data = {
    mensaje: message
  };

  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/${partnerMembershipId}/create-bitacora-message`;
  const config = { withCredentials: true };
  return axios.post(url, data, config);
};

export const getPartnerMembershipPreviousMaintenances = partnerMembershipId => {
  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/${partnerMembershipId}/previous-maintenances/list`;
  const config = { withCredentials: true };
  return axios.get(url, config);
};

export const applyDirectMaintenanceChange = (
  partnerMembershipId,
  newMaintenanceId
) => {
  const url = `${constants.BASE_URL}${PARTNERS_BASE_URL}/membership-partner/${partnerMembershipId}/direct-maintenance-change/${newMaintenanceId}/apply`;
  const config = { withCredentials: true };
  return axios.put(url, null, config);
};
