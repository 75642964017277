import {
  GetNotaCreditoBloqueos,
  PostNotaCreditoBloqueo,
  DeleteNotaCreditoBloqueo
} from "../../reducers/notaCredito";

export interface NotaCreditoBloqueoStore
  extends GetNotaCreditoBloqueos,
    PostNotaCreditoBloqueo,
    DeleteNotaCreditoBloqueo {}

export const notaCreditoBloqueoStore: (
  state
) => NotaCreditoBloqueoStore = state => {
  const { notasCredito } = state;
  return {
    notaCreditoBloqueos: notasCredito.notaCreditoBloqueos,
    gettingNotaCreditoBloqueos: notasCredito.gettingNotaCreditoBloqueos,
    gettingNotaCreditoBloqueosError:
      notasCredito.gettingNotaCreditoBloqueosError,
    creatingNotaCreditoBloqueo: notasCredito.creatingNotaCreditoBloqueo,
    creatingNotaCreditoBloqueoError:
      notasCredito.creatingNotaCreditoBloqueoError,
    deletingNotaCreditoBloqueo: notasCredito.deletingNotaCreditoBloqueo,
    deletingNotaCreditoBloqueoError:
      notasCredito.deletingNotaCreditoBloqueoError
  };
};
