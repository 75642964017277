export default class FullcalendarObjectMapper {
  getSettings(properties) {
    let newSettings = {};
    for (const key in properties) {
      if (properties.hasOwnProperty(key)) {
        newSettings[key] = properties[key];
      }
    }
    console.info(newSettings);
    return newSettings;
  }
}
