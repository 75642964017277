import React from "react";
import { connect } from "react-redux";
import { PromotionSelector } from "../../components/PromotionSelector";
import { getPromotionsFiltered } from "../../../Home/actions/promotions";
import isEqual from "lodash.isequal";
import { getPromotions } from "../../../Home/actions/catalogs";
import { isObject } from "lodash";

interface Props {
  getPromotionsFiltered: (params: object) => any;
  getPromotions: () => any;
  loading: boolean;
  promotions: object[];
  disabled: boolean;
  onChange: (value: string) => any;
  value: { id: number };
  params: {
    club: string;
    membresia: string;
    mantenimiento: string;
    convenio?: string;
  };
  quotationUse: string;
}

const getPromotion = (promotions: any, promocion: any) => {
  return promotions.find(({ id }) => id == promocion);
};

class MaintenanceSelectorContainer extends React.Component<Props> {
  componentDidMount() {
    this.loadPromorions(this.props.params);
  }

  componentDidUpdate({ params: prevParams }) {
    const { params } = this.props;
    if (!isEqual(params, prevParams)) {
      this.loadPromorions(params);
    }
  }

  loadPromorions = params => {
    if (!this.props.disabled && !this.props.loading) {
      if (params.club && params.membresia && params.mantenimiento) {
        this.props.getPromotionsFiltered(params);
      } else {
        this.props.getPromotions();
      }
    }
  };

  render() {
    return (
      <PromotionSelector
        value={this.props.value}
        promotions={
          !this.props.quotationUse
            ? this.props.promotions
            : (this.props.promotions || []).filter(
                x =>
                  x.tipoUsoPromocion == this.props.quotationUse &&
                  !!x.autorizada
              )
        }
        onChange={value => {
          const promotionObject = getPromotion(this.props.promotions, value);
          this.props.onChange(promotionObject);
        }}
        disabled={this.props.disabled || this.props.loading}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getPromotionsFiltered: (params: object) => {
    dispatch(getPromotionsFiltered(params));
  },
  getPromotions: () => {
    dispatch(getPromotions());
  }
});

const mapStateToProps = state => {
  const {
    catalogs: { promotions, gettingPromotions },
    promotions: { gettingPromotionsFiltered, promotionsFiltered }
  } = state;

  return {
    promotions: promotions || promotionsFiltered,
    loading: gettingPromotions || gettingPromotionsFiltered
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaintenanceSelectorContainer);
