import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import ReactTable from "react-table";
import { Container, Row, Col } from "reactstrap";

import s from "./styles.scss";
import { TABLE_REPORT } from "./interfaces";

interface Props {
  tiposPago: {
    origenPago: string;
    formaPago: string;
  }[];
}

interface State {}

class Table extends React.Component<Props, State> {
  state = {};

  render() {
    let data = this.props.tiposPago.map((item, numero) => {
      const origenPago = item.origenPago;
      const formaPago = item.formaPago;
      return { origenPago, formaPago, numero };
    });

    return (
      <Container className="mt-3 mb-5 p-0">
        <span>
          <hr />
          <Row className={"my-3"}>
            <Col sm="12">Contenidos</Col>
          </Row>
          <ReactTable
            pages={1}
            data={data}
            columns={TABLE_REPORT}
            showPagination={false}
            sortable={true}
            multiSort={true}
            resizable={true}
            pageSize={this.props.tiposPago.length + 3}
          />
        </span>
      </Container>
    );
  }
}

export default withStyles(s)(Table);
