export enum Criticidad {
  ALTA = "ALTA",
  MEDIA = "MEDIA",
  BAJA = "BAJA"
}

export enum OrigenPeticion {
  KIOSCO = "KIOSCO",
  PORTAL_WEB = "PORTAL_WEB",
  EMAIL = "EMAIL",
  REDES_SOCIALES = "REDES_SOCIALES",
  CALL_CENTER = "CALL_CENTER"
}

export enum TipoPeticion {
  QUEJA = "QUEJA",
  SUGERENCIA = "SUGERENCIA",
  INFORMACION = "INFORMACION",
  FELICITACION = "FELICITACION"
}

export enum ESTATUS_ATENCION {
  CERRADO_EXITOSAMENTE = "CERRADO_EXITOSAMENTE",
  CERRADO_VENCIDO = "CERRADO_VENCIDO",
  VIGENTE = "VIGENTE",
  VENCIDO = "VENCIDO",
  SIN_CLASIFICAR = "SIN_CLASIFICAR"
}

export enum ESTATUS_CLASIFICACION {
  SIN_CLASIFICAR = "SIN_CLASIFICAR",
  CLASIFICADA = "CLASIFICADA"
}

export interface Demand {
  id?: number;
  clubEmisorId: number;
  clubReceptorId: number;
  comentario: string;
  criticidad: Criticidad;
  email: string;
  membresiaPublicoId?: number;
  nivelClasificacionId?: number;
  nivel1ClasificacionId?: number;
  nivel2ClasificacionId?: number;
  nivel3ClasificacionId?: number;
  nivel4ClasificacionId?: number;
  origen: OrigenPeticion;
  tipoPeticion: TipoPeticion;
  comentariosSeguimiento: object[];
  fechaCreacionCompromiso: string;
  fechaCompromiso: string;
  responsableNombre: string;
}

export interface DemandEvidence {
  id?: number;
  quejaSugerenciaId: number;
  evidenciaUrl: string;
}
