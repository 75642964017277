import React from "react";
import IButton from "../iButton/IButton";
import IButtonBuilderProps from "./IButtonBuilder.props";
import IButtonBuilderState from "./IButtonBuilder.state";

export default class IButtonBuilder<
  INP extends string,
  BTN extends string
> extends React.Component<IButtonBuilderProps<INP, BTN>, IButtonBuilderState> {
  render() {
    return <IButton {...(this.props as any)} />;
  }
}
