import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import SportsProductsQuote from "../../components/sportsProductsQuote";
import * as sportProductActions from "../../actions/sportsProducts";
import * as catalogActions from "../../actions/catalogs";
import * as modalActions from "../../actions/modals";

interface Props {
  getPaymentTypes: () => any;
  getSportsProductsByFilters: (filters: object) => any;
  getPromotions: (productoId: string) => any;
  getQuotation: (filters: object) => any;
  cleanSportsProducts: () => any;
  cleanPromotions: () => any;
  cleanQuotation: () => any;
  getClubs: () => any;
  getPersonTypes: () => any;
  getSportProductTypes: () => any;
  toggleAddPersonModal: () => any;
  clubs: object[];
  personTypes: object[];
  sportProductTypes: object[];
  sportsProducts: object[];
  tiposPrecio: object[];
  promotions: object[];
  quotation: object;
  selectedPerson: object;
}

class SportsProductsQuotePage extends React.Component<Props> {
  componentDidMount() {
    this.props.getClubs();
    this.props.getPersonTypes();
    this.props.getSportProductTypes();
    this.props.getPaymentTypes();
  }

  render() {
    return (
      <main>
        <Helmet title="Sports Products" />
        <Layout>
          <SportsProductsQuote
            getSportsProductsByFilters={this.props.getSportsProductsByFilters}
            getPromotions={this.props.getPromotions}
            getQuotation={this.props.getQuotation}
            toggleAddPersonModal={this.props.toggleAddPersonModal}
            cleanSportsProducts={this.props.cleanSportsProducts}
            cleanPromotions={this.props.cleanPromotions}
            cleanQuotation={this.props.cleanQuotation}
            clubs={this.props.clubs}
            personTypes={this.props.personTypes}
            sportProductTypes={this.props.sportProductTypes}
            sportsProducts={this.props.sportsProducts}
            promotions={this.props.promotions}
            quotation={this.props.quotation}
            selectedPerson={this.props.selectedPerson}
            tiposPrecio={this.props.tiposPrecio}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSportsProductsByFilters: (filters: object) => {
    dispatch(sportProductActions.getSportsProductsByFilters(filters));
  },
  getPromotions: (productoId: string) => {
    dispatch(sportProductActions.getPromotions(productoId));
  },
  getQuotation: (filters: object) => {
    dispatch(sportProductActions.getQuotation(filters));
  },
  cleanSportsProducts: () => {
    dispatch(sportProductActions.cleanSportsProducts());
  },
  cleanPromotions: () => {
    dispatch(sportProductActions.cleanPromotions());
  },
  cleanQuotation: () => {
    dispatch(sportProductActions.cleanQuotation());
  },
  getPaymentTypes: () => {
    dispatch(catalogActions.getPaymentTypes());
  },
  getClubs: () => {
    dispatch(catalogActions.getClubs());
  },
  getPersonTypes: () => {
    dispatch(catalogActions.getPersonTypes());
  },
  getSportProductTypes: () => {
    dispatch(catalogActions.getSportsProductsTypes());
  },
  toggleAddPersonModal: () => {
    dispatch(modalActions.toggleAddPersonModal());
  }
});

const mapStateToProps = state => {
  const { catalogs, sportsProducts, persons } = state;
  return {
    clubs: catalogs.clubs,
    personTypes: catalogs.personTypes,
    sportProductTypes: catalogs.sportsProductsTypes,
    tiposPrecio: catalogs.tiposPrecio,
    sportsProducts: sportsProducts.sportsProductsFiltered,
    promotions: sportsProducts.promotionsBySportProductId,
    quotation: sportsProducts.quotation,
    selectedPerson: persons.selectedPerson
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SportsProductsQuotePage);
