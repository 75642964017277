import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import Layout from "../../components/layout";
import MiscReports from "../../components/miscReports";

interface Props {
  userContext: { clubSessionId: any };
}

class MiscReportsPage extends React.Component<Props> {
  render() {
    return (
      <main>
        <Helmet title="Directorio de traspasos" />
        <Layout>
          <MiscReports userContext={this.props.userContext} />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    userContext: user.userContext
  };
};

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MiscReportsPage);
