import Invitado, {
  InvitadoIntercambioComercialTipo,
  InvitadoTipo
} from "./Invitado";

export default interface InvitadoDetalle {
  idInvitado: number;
  idPersona: number;
  nombreCompleto: string;
  correo: string;
  tipo: InvitadoTipo;
  estatus: string;
  idUserRegistro: number;
  userRegistro: string;
  idUserAutorizacion: number;
  userAutorizacion: string;
  idUserInactivacion: number;
  userInactivacion: string;
  omitirDocumentacion: boolean;
  edad: number;
  idPersonaTutor: number;
  tutorNombreCompleto: string;

  // Información adicional
  tieneAcceso: boolean;
  razonesSinAcceso: string[];
  clubes: number[];

  // Datos de Invitado Familiar
  idUserColaborador: number;
  userColaborador: string;

  // Datos de tipo Invitado por Intercambio Comercial
  idEmpresa: number;
  empresa: string;
  tipoIntercambio: InvitadoIntercambioComercialTipo;
  idPatronHorario: number;
  patronHorario: string;

  // Datos de Invitado por Autorización Directa
  fechaInicioVigencia: string;
  fechaFinVigencia: string;
  observaciones: string;
}

export function mapInvitado(invitado: InvitadoDetalle): Invitado {
  return {
    idPersona: invitado.idPersona,
    tipo: invitado.tipo,
    omitirDocumentacion: invitado.omitirDocumentacion,
    idPersonaTutor: invitado.idPersonaTutor,
    idUserColaborador: invitado.idUserColaborador,
    idEmpresa: invitado.idEmpresa,
    tipoIntercambio: invitado.tipoIntercambio,
    idPatronHorario: invitado.idPatronHorario,
    fechaInicioVigencia: invitado.fechaInicioVigencia,
    fechaFinVigencia: invitado.fechaFinVigencia,
    observaciones: invitado.observaciones,
    clubes: invitado.clubes
  };
}
