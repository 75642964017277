import { GetMembresiasCrm1Acceso } from "../../reducers/membresiasCrm1";

export interface MembresiasExternasStore extends GetMembresiasCrm1Acceso {}

export const membresiasExternasStore: (
  state
) => MembresiasExternasStore = state => {
  const { membresiasCrm1 } = state;
  return {
    membresiasCrm1Acceso: membresiasCrm1.membresiasCrm1Acceso,
    membresiasCrm1AccesoLoading: membresiasCrm1.membresiasCrm1AccesoLoading,
    membresiasCrm1AccesoError: membresiasCrm1.membresiasCrm1AccesoError
  };
};
