import React from "react";

import { Container, Row, Col, Button } from "reactstrap";
import { DemandEvidence } from "../../demands/types/Demand";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import withStyles from "isomorphic-style-loader/lib/withStyles";

import BaseInput from "../../../../toolkit/baseInput";

import s from "../styles.scss";

import { createDemandEvidenceRequest } from "../../../actions/demans";

import { handleRequestError } from "../../../../utils/helpers";

interface Props {
  saved: () => any;
  demandId: number;
  evidences: DemandEvidence[];
}

interface State {
  url: string;
}

class EvidencesComponent extends React.Component<Props, State> {
  state = {
    url: ""
  };

  saveDemandEvidence = () => {
    const { url } = this.state;

    if (!this.props.demandId || !url) {
      return;
    }

    const data: DemandEvidence = {
      quejaSugerenciaId: this.props.demandId,
      evidenciaUrl: url
    };

    createDemandEvidenceRequest(this.props.demandId, data)
      .then(() => {
        this.setState({ url: "" });
        this.props.saved();
      })
      .catch(handleRequestError);
  };

  render() {
    return (
      <Container>
        <div className="form-row mb-5">
          <Col xs={9}>
            <BaseInput
              type="text"
              label="Url"
              name="evidenceUrl"
              id="evidenceUrl"
              placeholder="https://www.sportsworld.com.mx/"
              value={this.state.url}
              onChange={e => {
                const value = e.target.value;
                this.setState({ url: value });
              }}
            />
          </Col>
          <Col xs={3}>
            <br />
            <Button
              onClick={this.saveDemandEvidence}
              className={`${s.buttonMarginTop} ${s.primaryButton}`}
              block
            >
              Agregar Evidencia
            </Button>
          </Col>
        </div>

        <Row className="mt-3">
          <Col>
            <ReactTable
              data={this.props.evidences || []}
              pageSize={(this.props.evidences || []).length + 1}
              columns={[
                {
                  Header: () => <p className="font-weight-bold">Url</p>,
                  id: 0,
                  accessor: "evidenciaUrl",
                  Cell: row => {
                    return <p>{row.original.evidenciaUrl}</p>;
                  }
                },
                {
                  Header: () => <p className="font-weight-bold">Opciones</p>,
                  id: 1,
                  width: 80,
                  accessor: "opciones",
                  Cell: row => {
                    return (
                      <Container className="p-0 text-center">
                        <a
                          id={`showUrl${row.original.id}`}
                          onClick={() =>
                            window.open(row.original.evidenciaUrl, "_blank")
                          }
                        >
                          <FontAwesomeIcon
                            style={{ cursor: "pointer" }}
                            icon={faFile}
                          />
                        </a>
                      </Container>
                    );
                  }
                }
              ]}
              sortable={false}
              resizable={false}
              showPagination={false}
              className="-highlight -striped"
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withStyles(s)(EvidencesComponent);
