import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Container, Row, Col, Button } from "reactstrap";
import s from "./styles.scss";
import { INTERFACE_STATE, INTERFACE_DOMICILIO_FISCAL } from "./interfaces";
import {
  errorNotification,
  ERROR_SUM_VALUES_TAXDISTRIBUTION
} from "../../../utils/notifications";
import GenData from "./genData";
import ClientData from "./clientData";
import FiscalData from "./fiscalData";
import AddressData from "./addressData";
import ItemsData from "./itemsData";
import TaxData from "./taxDistributionData";
import AccrualData from "./accrualDistribution";
import PaymentsData from "./paymentsData";
import SearchBill from "./SearchBill";
import ModalFrame from "../../../toolkit/modalFrame";
import isNil from "lodash.isnil";
import { TIPO_MONTO } from "../../../utils/constants";

interface Props {
  clubs: { clubId: number; nombre: string }[];
  empresa: string;
  rfc: string;
  sendToBilling: (data: any) => any;
  billingData: { sendingData: boolean };
  bill: INTERFACE_STATE;
  cancelBill: (bill: object, uuid: string) => any;
  remakeBill: (bill: object, uuid: string) => any;
  success: boolean;
  accountingAccounts: object;
  sendToAccrual: (data: any) => any;
}

enum MODAL_MODES {
  CANCELAR = "CANCELAR",
  REFACTURAR = "REFACTURAR"
}

interface State {
  billingFields: INTERFACE_STATE;
  isOpen: boolean;
  modalTitle: MODAL_MODES;
  uuid: string;
  mode: MODAL_MODES;
}

class BillingPage extends React.Component<Props, State> {
  state = {
    isOpen: false,
    mode: null,
    modalTitle: MODAL_MODES.CANCELAR,
    uuid: "",
    billingFields: {
      cliente: {
        apellidoMaterno: null,
        apellidoPaterno: null,
        claveResidenciaFiscal: null,
        claveUsoCFDI: null,
        regimenFiscal: null,
        clienteId: null,
        contactoApellidoMaterno: null,
        contactoApellidoPaterno: null,
        contactoEmail: null,
        contactoNombre: null,
        contactoTelefono: null,
        contactoTelefonoCodigoPais: null,
        contactoTelefonoExtension: null,
        domicilioFiscal: {
          calle: null,
          noExterior: null,
          noInterior: null,
          colonia: null,
          codigoPostal: null,
          localidad: "localidad",
          municipio: null,
          estado: null,
          pais: "México",
          codigoPaisERP: "MX"
        },
        email: null,
        nombre: null,
        rfc: null,
        segundoNombre: null,
        telefono: null,
        tipoPersona: null
      },
      clubId: null,
      condicionPago: null,
      emisorRI: null,
      empresaId: null,
      fechaExpedicion: null,
      folio: null,
      formaPago: null,
      items: [],
      lugarExpedicion: null,
      metodoPago: null,
      moneda: null,
      observaciones: null,
      pagos: [],
      plataformaOrigen: null,
      procesoId: null,
      regimenFiscalEmisor: null,
      subTotal: null,
      terminosPagoErp: null,
      tipoCambio: null,
      tipoTransaccionErp: null,
      total: null,
      unidadNegocio: null,
      contactoTelefonoCodigoPais: null,
      contactoTelefonoExtension: null,
      lineasDistribucion: [],
      accrualDistribution: []
    },
    AddressDataValidated: false,
    GenDataValidated: false,
    ClientDataValidated: false,
    ItemsDataValidated: false,
    TaxesDataValidated: false,
    PaymentDataValidated: false,
    LineasDistribucionValidated: false,
    AccrualDistributionValidated: false,
    devengoDistribution: [],
    DevengoDistributionValidated: false
  };

  onChangeAddress = async (
    domicilioFiscal: INTERFACE_DOMICILIO_FISCAL,
    AddressDataValidated: boolean
  ) => {
    let state = { ...this.state };
    state.billingFields.cliente.domicilioFiscal = domicilioFiscal;
    state.AddressDataValidated = AddressDataValidated;
    await this.setState(state);
  };

  onChangeGenData = (genData: any, GenDataValidated: boolean) => {
    let state = this.state;
    state.billingFields = { ...genData };
    state.GenDataValidated = GenDataValidated;
    this.setState(state);
  };

  onChangeClientData = (genData: any, ClientDataValidated: boolean) => {
    let state = this.state;
    state.billingFields.cliente = { ...genData };
    state.ClientDataValidated = ClientDataValidated;
    this.setState(state);
  };

  onChangeItems = (items: any, ItemsDataValidated: boolean) => {
    let state = this.state;
    state.billingFields.items = items;
    state.ItemsDataValidated = ItemsDataValidated;
    this.setState(state);
  };

  onChangeTaxDistribution = (
    lineasDistribucion: any,
    LineasDistribucionValidated: boolean
  ) => {
    let state = this.state;
    state.billingFields.lineasDistribucion = lineasDistribucion;
    state.LineasDistribucionValidated = LineasDistribucionValidated;
    this.setState(state);
  };

  onChangeAccrualDistribution = (
    accrualDistribution: any,
    AccrualDistributionValidated: boolean
  ) => {
    let state = this.state;
    state.billingFields.accrualDistribution = accrualDistribution;
    state.AccrualDistributionValidated = AccrualDistributionValidated;
    this.setState(state);
  };

  onChangeDevengoDistribution = (
    devengoDistribution: any,
    DevengoDistributionValidated: boolean
  ) => {
    let state = this.state;
    state.devengoDistribution = devengoDistribution;
    state.DevengoDistributionValidated = DevengoDistributionValidated;
    this.setState(state);
  };

  onChangePayments = (pagos: any, PaymentDataValidated: boolean) => {
    let state = this.state;
    state.billingFields.pagos = pagos;
    state.PaymentDataValidated = PaymentDataValidated;
    this.setState(state);
  };

  onChangeField = (e, fieldName) => {
    let state = { ...this.state };
    state[fieldName] = e.target.value;
    this.setState(state);
  };

  sendDistributionLines = () => {
    const devengoDistribution = this.state.devengoDistribution;
    let valEnvio = [];
    let valPer = null;
    devengoDistribution.forEach(element => {
      valEnvio.push({
        clubId: element.clubId,
        monto: element.monto,
        cuentaContable: element.cuentaContableId,
        tipoMonto: TIPO_MONTO[1],
        fechaContable: element.fechaContable,
        renglon: element.renglon,
        periodo: element.periodo,
        facturaUUID: this.state.uuid
      });
      valEnvio.push({
        clubId: element.clubId,
        monto: element.monto,
        cuentaContable: element.cuentaContableIdDev,
        tipoMonto: TIPO_MONTO[0],
        fechaContable: element.fechaContable,
        renglon: element.renglon,
        periodo: element.periodo,
        facturaUUID: this.state.uuid
      });
      valPer = element.periodo;
    });
    let ultimateSend = {
      periodo: valPer,
      body: valEnvio
    };
    this.props.sendToAccrual(ultimateSend);
  };

  sendToBilling = () => {
    const taxDistribution = this.state.billingFields.lineasDistribucion;
    const items = this.state.billingFields.items;
    let valReturn = true;
    if (
      !isNil(taxDistribution) &&
      !isNil(items) &&
      taxDistribution.length > 0 &&
      items.length > 0
    ) {
      let valPosition = 0;
      items.forEach(element => {
        let valTotalSum = 0;
        taxDistribution.forEach(valTax => {
          if (valTax.item == valPosition) {
            valTotalSum = valTotalSum + parseFloat(valTax.monto);
          }
        });
        if (parseFloat(element.importe) != valTotalSum) {
          valReturn = false;
        }
        valPosition++;
      });
    }
    if (valReturn) {
      if (!this.state.mode) {
        let billingState = { ...this.state.billingFields };
        billingState.lineasDistribucion = this.state.billingFields.lineasDistribucion.map(
          element => {
            return {
              clubId: element.clubId,
              monto: element.monto,
              cuentaContable: element.cuentaContable,
              renglon: element.renglon
            };
          }
        );
        this.props.sendToBilling(billingState);
      } else {
        if (this.state.mode === MODAL_MODES.REFACTURAR) {
          this.remakeBill();
        } else {
          this.cancelBill();
        }
      }
    } else {
      errorNotification(ERROR_SUM_VALUES_TAXDISTRIBUTION);
    }
  };

  componentDidUpdate({ bill: prevBill, success: prevSuccess }) {
    if (prevBill !== this.props.bill) {
      this.setState({
        ...this.state,
        billingFields: {
          ...this.props.bill,
          lineasDistribucion: [],
          accrualDistribution: []
        }
      });
    }

    if (!prevSuccess && this.props.success) {
      this.toggle();
    }
  }

  cancelBill = () => {
    this.setState({
      modalTitle: MODAL_MODES.CANCELAR,
      isOpen: true
    });
  };

  remakeBill = () => {
    this.setState({
      modalTitle: MODAL_MODES.REFACTURAR,
      isOpen: true
    });
  };

  executeAction = () => {
    if (this.state.modalTitle === MODAL_MODES.CANCELAR) {
      this.props.cancelBill(this.state.billingFields, this.state.uuid);
    } else {
      this.props.remakeBill(this.state.billingFields, this.state.uuid);
    }
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    return (
      <Container className="mt-1 mb-1 p-0">
        <ModalFrame
          title={`${this.state.modalTitle} Factura`}
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          size="lg"
        >
          <div className="container-fluid">
            <p>
              ¿Seguro que desea {this.state.modalTitle} la factura con{" "}
              <b>{this.state.uuid}</b>?
            </p>
            <div className="row">
              <div className="col">
                <Button
                  className={`${s.buttonMarginTop} ${s.primaryButton}`}
                  onClick={this.executeAction}
                  block
                >
                  Sí
                </Button>
              </div>
              <div className="col">
                <Button
                  className={`${s.buttonMarginTop} ${s.primaryButton}`}
                  onClick={this.toggle}
                  block
                >
                  NO
                </Button>
              </div>
            </div>
          </div>
        </ModalFrame>
        <p className={s.grayTitle}>Buscar factura</p>
        <SearchBill
          cancelBill={() =>
            this.setState({
              mode: MODAL_MODES.CANCELAR,
              modalTitle: MODAL_MODES.CANCELAR
            })
          }
          remakeBill={() =>
            this.setState({
              mode: MODAL_MODES.REFACTURAR,
              modalTitle: MODAL_MODES.REFACTURAR
            })
          }
          setUUID={uuid => this.setState({ uuid, mode: null })}
        ></SearchBill>
        <hr />

        <p className={s.grayTitle}>Datos cliente</p>
        <ClientData
          {...this.props}
          onChangeClientData={this.onChangeClientData}
          fields={this.state.billingFields.cliente}
          disabled={
            this.props.bill && this.state.mode !== MODAL_MODES.REFACTURAR
          }
        />

        <p className={s.grayTitle}>Domicilio fiscal</p>
        <AddressData
          {...this.props}
          address={this.state.billingFields.cliente.domicilioFiscal}
          onChangeAddress={this.onChangeAddress}
          disabled={
            this.props.bill && this.state.mode !== MODAL_MODES.REFACTURAR
          }
        />

        <p className={`mt-1 ${s.grayTitle}`}>Datos generales</p>
        <GenData
          {...this.props}
          onChangeGenData={this.onChangeGenData}
          fields={this.state.billingFields}
          disabled={this.props.bill}
        />
        <p className={`mt-1 ${s.grayTitle}`}>Datos fiscales</p>
        <FiscalData
          {...this.props}
          onChangeField={this.onChangeField}
          disabled={this.props.bill}
        />

        <p className={`mt-1 ${s.grayTitle}`}>Items</p>
        <ItemsData
          {...this.props}
          items={this.state.billingFields.items}
          onChangeItems={this.onChangeItems}
          disabled={this.props.bill}
        />

        <p className={`mt-1 ${s.grayTitle}`}>Distribuciones contables</p>
        <TaxData
          {...this.props}
          items={this.state.billingFields.items}
          taxDistribution={this.state.billingFields.lineasDistribucion}
          onChangeTaxDistribution={this.onChangeTaxDistribution}
          disabled={this.props.bill}
        />

        {/* {!isNil(this.state.billingFields.taxDistribution) &&
        this.state.billingFields.taxDistribution.length > 0 ? (
          <span> */}
        <p className={`mt-1 ${s.grayTitle}`}>Reconocimiento de ingresos</p>
        <AccrualData
          {...this.props}
          taxDistribution={this.state.billingFields.lineasDistribucion}
          onChangeDevengoDistribution={this.onChangeDevengoDistribution}
          devengoDistribution={this.state.devengoDistribution}
          sendDistributionLines={this.sendDistributionLines}
          disabled={!isNil(this.props.bill) ? false : true}
        />
        {/* </span>
        ) : null} */}

        <p className={`mt-2 ${s.grayTitle}`}>Pagos</p>
        <PaymentsData
          {...this.props}
          pagos={this.state.billingFields.pagos || []}
          onChangePayments={this.onChangePayments}
          disabled={this.props.bill}
        />

        <Row className="mt-3">
          <Col sm={{ size: 4, offset: 8 }}>
            <Button
              disabled={this.props.billingData.sendingData}
              block
              className={`${s.buttonMarginTop} ${s.primaryButton}`}
              onClick={() => this.sendToBilling()}
            >
              {!this.state.mode
                ? "Enviar a factura"
                : this.state.mode === MODAL_MODES.CANCELAR
                ? "Cancelar factura"
                : "Refacturar"}
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withStyles(s)(BillingPage);
