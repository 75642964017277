import React from "react";
import { connect } from "react-redux";
import * as catalogActions from "../../actions/catalogs";
import * as productActions from "../../actions/products";
import PriceDataMemberships from "../../components/priceDataMemberships";
import {
  MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES,
  PRICES_STATUS
} from "../../../types/MassiveAuthorizationPricesFilter";

interface Props {
  addPricesToMemberships: (
    productId: string,
    tipoProducto: string,
    prices: object
  ) => any;
  successfulSave: boolean;
  getConcepts: () => any;
  getStatus: () => any;
  getPaymentTypes: () => any;
  getAccountingAccounts: () => any;
  getProductAccounts: () => any;
  getPricesToMemberships: (params: object) => any;
  conceptos: Catalog[];
  estatus: Catalog[];
  tiposPrecio: tipoPrecio[];
  prices: Prices;
  loading: boolean;
  savingPrices: boolean;
  savingPricesError: boolean;
  authorizePrices: (prices: object[]) => any;
  onClickSave: (e: object) => any;
  onSuccessfulSave: () => any;
  filterTypes: MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES[];
  defaultStatus: PRICES_STATUS;
  getMemberships: () => any;
  memberships: object[];
  massive: boolean;
  getMassivePricesMemberships: (paramas: object) => any;
  disableAmounts: boolean;
}

interface PriceData {
  clubId: string;
  concepto: string;
  nombre: string;
  inicioVigencia: string;
  finVigencia: string;
  precio: string;
  cuentaContable: string;
  estatus: string;
}

interface Prices {
  content: PriceData[];
  totalPages: string;
}

interface tipoPrecio {
  esquemaPagoId: string;
  nombre: string;
}

interface Catalog {
  id: string;
  nombre: string;
}

class PriceDataMembershipsPage extends React.Component<Props, void> {
  componentDidMount() {
    this.props.getConcepts();
    this.props.getStatus();
    this.props.getPaymentTypes();
    this.props.getAccountingAccounts();
    this.props.getProductAccounts();
    this.props.getMemberships();
  }

  render() {
    return (
      <PriceDataMemberships
        addPricesToMemberships={this.props.addPricesToMemberships}
        getPricesToMemberships={
          this.props.massive
            ? this.props.getMassivePricesMemberships
            : this.props.getPricesToMemberships
        }
        disableAmounts={this.props.disableAmounts}
        successfulSave={this.props.successfulSave}
        conceptos={this.props.conceptos}
        estatus={this.props.estatus}
        prices={this.props.prices}
        loading={this.props.loading}
        savingPrices={this.props.savingPrices}
        savingPricesError={this.props.savingPricesError}
        tiposPrecio={this.props.tiposPrecio}
        onClickSave={this.props.onClickSave}
        authorizePrices={this.props.authorizePrices}
        onSuccessfulSave={this.props.onSuccessfulSave}
        filterTypes={
          this.props.filterTypes || [
            MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES.DATE,
            MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES.ESTATUS,
            MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES.PRICE_TYPE
          ]
        }
        defaultStatus={
          this.props.defaultStatus || PRICES_STATUS.PRICES_ULTIMOS_REGISTRADOS
        }
        memberships={this.props.memberships}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addPricesToMemberships: (
    productId: string,
    tipoProducto: string,
    prices: object
  ) => {
    return dispatch(
      productActions.addPricesToMemberships(productId, tipoProducto, prices)
    );
  },
  getConcepts: () => {
    dispatch(catalogActions.getConcepts());
  },
  getStatus: () => {
    dispatch(catalogActions.getStatus());
  },
  getPaymentTypes: () => {
    dispatch(catalogActions.getPaymentTypes());
  },
  getMemberships: () => {
    dispatch(catalogActions.getMemberships());
  },
  getAccountingAccounts: () => {
    dispatch(catalogActions.getAccountingAccounts());
  },
  getProductAccounts: () => {
    dispatch(catalogActions.getProductAccounts());
  },
  getPricesToMemberships: (params: object) => {
    return dispatch(productActions.getPricesToMemberships(params));
  },
  getMassivePricesMemberships: (params: object) => {
    return dispatch(productActions.getMassivePricesMemberships(params));
  },
  authorizePrices: (prices: object[]) => {
    return dispatch(productActions.authorizeMembershipPrices(prices));
  }
});

const mapStateToProps = state => {
  const { catalogs, products } = state;
  return {
    conceptos: catalogs.conceptos,
    estatus: catalogs.estatus,
    tiposPrecio: catalogs.tiposPrecio,
    successfulSave: products.pricesSaved,
    prices: products.pricesToProduct || products.massivePricesMemberships,
    loading: products.loadingPricesToProduct,
    savingPrices: products.savingPrices,
    savingPricesError: products.savingPricesError,
    memberships: catalogs.memberships
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceDataMembershipsPage);
