import ITextBuilder from "../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";
import FormBuilderConfig from "../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IButtonBuilder from "../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";

type Inputs = "mitUser" | "mitPassword";
type Buttons = "aceptar" | "cancelar";

export const credencialesMitTpvFormConfig: FormBuilderConfig<
  Inputs,
  Buttons
> = {
  inputs: [
    {
      name: "mitUser",
      label: "Usuario",
      bts: {
        xCol: 4
      },
      component: ITextBuilder
    },
    {
      name: "mitPassword",
      label: "Password",
      bts: {
        xCol: 4
      },
      component: ITextBuilder
    }
  ],
  buttons: [
    {
      name: "aceptar",
      label: "Aceptar",
      isSubmit: true,
      bts: {
        mCol: 3,
        mOff: 6
      },
      component: IButtonBuilder
    },
    {
      name: "cancelar",
      label: "Cancelar",
      isSubmit: false,
      bts: {
        mCol: 3
      },
      component: IButtonBuilder
    }
  ],
  validations: {
    mitUser: {
      requiredWithNoSpace: {
        validator: (value: string) => !value || !value.replace(/\s+/g, ""),
        message: () => "Usuario obligatorio"
      },
      notSpace: {
        validator: (value: string) => /\s+/g.test(value),
        message: () => "No se admiten espacios en blanco"
      }
    },
    mitPassword: {
      requiredWithNoSpace: {
        validator: (value: string) => !value || !value.replace(/\s+/g, ""),
        message: () => "Password obligatorio"
      },
      notSpace: {
        validator: (value: string) => /\s+/g.test(value),
        message: () => "No se admiten espacios en blanco"
      }
    }
  },
  rows: [["mitUser", "mitPassword", "aceptar", "cancelar"]]
};
