import React from "react";
import { connect } from "react-redux";
import ModalFrame from "../../../toolkit/modalFrame";
import s from "./styles.scss";
import * as modalActions from "../../actions/modals";
import moment from "moment";

import { Container, Row, Col, Table } from "reactstrap";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  prospect: any;
  getProspectActionsLog: (any) => void;
  prospectActions: { actividadId: string }[];
}

interface State {
  keys: string[];
}

class ActionProspectModal extends React.Component<Props, State> {
  state = {
    keys: ["nota", "status", "tipo", "fechaRegistro", "horaRegistro"]
  };

  static getDerivedStateFromProps(props) {
    if (
      props.isOpen &&
      props.getProspectActionsLog != undefined &&
      props.prospect != null &&
      props.prospectActions == null
    ) {
      props.getProspectActionsLog(props.prospect.personaId);
    }
  }

  render() {
    let name =
      this.props.prospect && this.props.prospect.nombre
        ? this.props.prospect.nombre.toUpperCase()
        : "";
    let apellido =
      this.props.prospect && this.props.prospect.primerApellido
        ? this.props.prospect.primerApellido.toUpperCase()
        : "";
    const actionLogRow = () =>
      this.props.prospectActions.map(action => (
        <tr key={action.actividadId}>
          {this.state.keys.map(key => {
            let data: string;
            data = action[key];
            if (action[key]) {
              if (
                key == "fechaInicio" ||
                key == "fechaFin" ||
                key == "fechaIngreso"
              ) {
                return (
                  <td key={`${key}${action.actividadId}`}>
                    {moment(data).format("YYYY/MM/DD")}
                    <br />
                    {moment(data).format("h:mm:ss a")}
                  </td>
                );
              } else {
                return <td key={`${key}${action.actividadId}`}>{data}</td>;
              }
            } else {
              return <td />;
            }
          })}
        </tr>
      ));
    return (
      <ModalFrame
        title={`BITÁCORA DE PROSPECTO:  ${name} ${apellido}`}
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        size="lg"
      >
        <Container>
          <Row className={s.buttonMarginTop}>
            <Col>
              {this.props.prospectActions != null ? (
                <div className="table-responsive">
                  <Table bordered hover>
                    <thead>
                      <tr>
                        <th>Descripción</th>
                        <th>Estatus</th>
                        <th>Actividad</th>
                        <th>Fecha Registro</th>
                        <th>Hora Registro</th>
                      </tr>
                    </thead>
                    {<tbody>{actionLogRow()}</tbody>}
                  </Table>
                </div>
              ) : null}
            </Col>
          </Row>
        </Container>
      </ModalFrame>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getProspectActionsLog: id => {
    dispatch(modalActions.getProspectActionsLog(id));
  }
});

const mapStateToProps = state => {
  const { modals } = state;
  return {
    prospectActions:
      modals && modals.ProspectActionsLog ? modals.ProspectActionsLog : null
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionProspectModal);
