import React from "react";
import { withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import moment from "moment";
import { Container, Button, Row, Col } from "reactstrap";
import DatePicker from "../common/DatePicker";
import PagedTable from "../common/PagedTable";
import BaseInput from "../../../toolkit/baseInput";
import s from "./styles.scss";
import { QueryResponse } from "../../../types/QueryResponse";
import { downloadIngressByClubReport } from "../../actions/reports";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  getIngressByClubReport: (params: any) => any;
  onFetchData: (params: any) => void;
  clubs: any[];
  loadingIngressByClubReport: boolean;
  ingressByClubReport: object[];
  userContext: any;
}

interface State {
  clubId: number;
  fechaInicio: string;
  fechaFin: string;
}

const getIngressRowStatusClass = (ingress: any) => {
  if (ingress.tipoIngresoClub != "NORMAL") {
    return "table-danger";
  }
  return "";
};

const formatTime = (dateTime?) =>
  moment(dateTime)
    .utc()
    .format("YYYY-MM-DD");

class IngressByClubReport extends React.Component<Props, State> {
  state = {
    clubId: -1,
    fechaInicio: "",
    fechaFin: ""
  };

  getOptionsClubes = clubs => {
    return [{ value: "", label: "Seleccionar" }].concat(
      (clubs || [])
        .filter(club => !!club.nombre)
        .map(club => {
          return { label: club.nombre, value: club.clubId };
        })
    );
  };

  fetchData = ({ pageSize: size, page }) => {
    if (!!this.props.onFetchData) {
      const { clubId, fechaInicio, fechaFin } = this.state;

      if (clubId <= 0 || !fechaInicio || !fechaFin) {
        return;
      }

      this.props.onFetchData({
        size,
        page,
        idClub: clubId,
        fechaInicio,
        fechaFin
      });
    }
  };

  handleClubChanged = e => {
    const clubId = e.target.value;
    this.setState({ clubId });
  };

  handleInitialDateChanged = () => {
    return dateTime => {
      const date = formatTime(dateTime);
      this.setState({
        fechaInicio: date
      });
    };
  };
  handleFinalDateChanged = () => {
    return dateTime => {
      const date = formatTime(dateTime);
      this.setState({
        fechaFin: date
      });
    };
  };

  handleSearch = () => {
    if (!!this.props.onFetchData) {
      const { clubId, fechaInicio, fechaFin } = this.state;

      if (clubId <= 0 || !fechaInicio || !fechaFin) {
        return;
      }

      this.props.onFetchData({
        size: 20,
        page: 0,
        idClub: clubId,
        fechaInicio,
        fechaFin
      });
    }
  };

  // @ts-ignore
  componentDidMount = () => {
    this.setState({
      clubId:
        (this.props.userContext && this.props.userContext.clubSessionId) || null
    });
  };

  render() {
    const columns = [
      {
        name: "Persona",
        id: "nombrePersona"
      },
      {
        name: "Club",
        id: "nombreClub"
      },
      {
        name: "Fecha de ingreso",
        id: "fechaIngreso"
      },
      {
        name: "Hora entrada",
        id: "horaEntrada"
      },
      {
        name: "Hora salida",
        id: "horaSalida"
      },
      {
        name: "Estatus",
        id: "estatus"
      }
    ];

    return (
      <Container className="p-0 mt-3">
        <div className="row">
          <div className="col">
            <BaseInput
              label="Club"
              name="ingressClub"
              type="select"
              id="ingressClub"
              placeholder="Club"
              value={this.state.clubId}
              options={this.getOptionsClubes(this.props.clubs)}
              disabled={
                this.props.userContext && this.props.userContext.clubSessionId
              }
              onChange={this.handleClubChanged}
            />
          </div>
          <div className="col">
            <DatePicker
              label="Fecha Inicio"
              name="ingressInitialDate"
              id="ingressInitialDate"
              selected={this.state.fechaInicio}
              onChange={this.handleInitialDateChanged()}
            />
          </div>
          <div className="col">
            <DatePicker
              label="Fecha Fin"
              name="ingressFinalDate"
              id="ingressFinalDate"
              selected={this.state.fechaFin}
              onChange={this.handleFinalDateChanged()}
            />
          </div>
          <div className="col pt-3">
            <Button
              className={`${s.searchButton} ${s.primaryButton}`}
              onClick={this.handleSearch}
              disabled={
                this.state.clubId <= 0 ||
                !this.state.fechaInicio ||
                !this.state.fechaFin
              }
            >
              Buscar
            </Button>
          </div>
        </div>
        <Row className={"my-3"}>
          <Col sm="6"></Col>
          <Col sm="6" className="text-right">
            <span>Exportar a CSV: </span>
            <Button
              id="btnReportExportCsv"
              color="link"
              onClick={() => {
                const { clubId, fechaInicio, fechaFin } = this.state;

                if (clubId <= 0 || !fechaInicio || !fechaFin) {
                  return;
                }

                downloadIngressByClubReport(clubId, fechaInicio, fechaFin);
              }}
            >
              <FontAwesomeIcon icon={faFileExport} color="black" />
            </Button>
          </Col>
        </Row>
        <div className="mt-3">
          <PagedTable
            data={this.props.ingressByClubReport || []}
            columns={columns}
            fetchData={this.fetchData}
            searching={this.props.loadingIngressByClubReport}
            getTrProps={(_, ingress) => {
              return ingress
                ? { className: getIngressRowStatusClass(ingress.original) }
                : {};
            }}
          />
        </div>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(IngressByClubReport));
