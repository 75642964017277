import React from "react";
import { connect } from "react-redux";
import * as agreementActions from "../../../actions/agreementGroups";
import AddClubsToProducts from "../../../components/addClubsToProducts";
import { withRouter } from "react-router-dom";

// TODO: Esta funcionalidad se heredó de los convenios; faltan exclusiones

interface Props {
  addClubsToAgreement: (convenioId: string, clubs: object[]) => any;
  successfulSave: boolean;
  getClubsAgreement: (id: string) => any;
  validClubs: object[];
  onClickSave: (e: object) => any;
  match: any;
}

class AddClubsAgreementPage extends React.Component<Props, void> {
  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) this.props.getClubsAgreement(id);
  }

  setClubs = clubs => {
    const { id } = this.props.match.params;
    this.props.addClubsToAgreement(id, clubs.map(c => c.clubId));
  };

  render() {
    return (
      <AddClubsToProducts
        successfulSave={this.props.successfulSave}
        validClubs={this.props.validClubs}
        onClickSave={this.props.onClickSave}
        setClubs={this.setClubs}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addClubsToAgreement: (convenioId: string, clubs: object[]) => {
    dispatch(agreementActions.addClubsToAgreement(convenioId, clubs));
  },
  getClubsAgreement: (id: string) => {
    dispatch(agreementActions.getClubsAgreement(id));
  }
});

const mapStateToProps = state => {
  const agreements = state.agreementGroups;

  return {
    successfulSave: agreements.clubsSaved,
    validClubs: agreements.agreementClubs
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddClubsAgreementPage)
);
