import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import { ReduxConnect } from "../../../toolkit/decorators/Artifact";
import {
  InvitadosListDispatcher,
  invitadosListDispatcher
} from "./InvitadosList.dispatcher";
import { InvitadosListStore, invitadosListStore } from "./InvitadosList.store";
import InvitadosListComponent from "../../components/invitadosList";

interface Props extends InvitadosListStore, InvitadosListDispatcher {}

interface State {}

@ReduxConnect(invitadosListStore, invitadosListDispatcher)
export default class InvitadosListContainer extends React.Component<
  Props,
  State
> {
  render() {
    return (
      <main>
        <Layout>
          <Helmet title="Empresas de convenio" />
          <InvitadosListComponent {...this.props} />
        </Layout>
      </main>
    );
  }
}
