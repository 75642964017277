import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import * as reportes from "../../actions/reports";
import Layout from "../../components/layout";
import CatPreliminarReport from "../../components/catPreliminarReport";
import IAction from "../../../types/IAction";

interface Props {
  downloadReportCatPreliminar: (body: object) => IAction;
  userContext: { clubSessionId: any };
}

class CatPreliminarReportPage extends React.Component<Props> {
  render() {
    return (
      <main>
        <Helmet title="Reporte preliminar de CAT" />
        <Layout>
          <CatPreliminarReport
            downloadReportCatPreliminar={this.props.downloadReportCatPreliminar}
            userContext={this.props.userContext}
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    userContext: user.userContext
  };
};

const mapDispatchToProps = dispatch => ({
  downloadReportCatPreliminar: clubId => {
    dispatch(reportes.downloadReportCatPreliminar(clubId));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CatPreliminarReportPage);
