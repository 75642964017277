import React from "react";
import IInputBuilder from "../iInputBuilder/IInputBuilder";
import ISelect from "../iSelect/ISelect";
import ISelectBuilderProps from "./ISelectBuilder.props";
import ISelectBuilderState from "./ISelectBuilder.state";

export default class ISelectBuilder<INP extends string> extends IInputBuilder<
  INP,
  ISelectBuilderProps<INP>,
  ISelectBuilderState
> {
  render() {
    return <ISelect {...(this.props as any)} />;
  }
}
