import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import {
  successNotification,
  SAVE_SURVEYS,
  infoNotification,
  SAVE_SURVEYS_PENDING,
  errorNotification,
  SAVE_SURVEYS_ERROR,
  ERROR
} from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";
interface State {
  surveys: object[];
}
const initialState: State = {
  surveys: null
};

export default function surveysReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.GET_SURVEYS}_FULFILLED`: {
      return {
        ...state,
        surveys: action.payload.data
      };
    }
    case `${actionTypes.GET_SURVEYS}_PENDING`: {
      return {
        ...state,
        surveys: null
      };
    }
    case `${actionTypes.GET_SURVEYS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        surveys: null
      };
    }
    case `${actionTypes.SAVE_SURVEYS}_FULFILLED`: {
      successNotification(SAVE_SURVEYS);
      return {
        ...state
      };
    }
    case `${actionTypes.SAVE_SURVEYS}_PENDING`: {
      infoNotification(SAVE_SURVEYS_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.SAVE_SURVEYS}_REJECTED`: {
      errorNotification(readMessageBack(action, SAVE_SURVEYS_ERROR));
      return {
        ...state
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
