import FileDownload from "js-file-download";
import { parse } from "json2csv";
import ClaseImpartidaSinComision from "../../types/ClaseImpartidaSinComision";

const fields = [
  { label: "Clase", value: "id" },
  { label: "Producto", value: "producto" },
  { label: "Instructor", value: "instructor" },
  { label: "Factura", value: "factura" },
  { label: "Impartición", value: "fecha" },
  { label: "Cliente", value: "cliente" }
];

const opts = {
  fields,
  quotes: '"'
};

export default function claseSinComisionCsv(
  data: ClaseImpartidaSinComision[],
  nombre: string
) {
  FileDownload(parse(data, opts), `${nombre}.csv`);
}
