import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import {
  faDollarSign,
  faEquals,
  faPercentage,
  faArrowDown
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import s from "./styles.scss";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  ButtonGroup,
  Button,
  Form,
  UncontrolledTooltip
} from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import { PRICE_TYPES } from "../../../types-business/Promotions";

import { withRouter } from "react-router-dom";

const getSymbolPriceType = (priceType: PRICE_TYPES) => {
  switch (priceType) {
    case PRICE_TYPES.DESCUENTOABS:
      return "$";
    case PRICE_TYPES.NA:
      return "NA";
    case PRICE_TYPES.PRECIOFIJO:
      return "$";
    case PRICE_TYPES.PORCENTAJE:
      return "%";
    default:
      return "";
  }
};

const DEFAULT_MEMBERSHIP = {
  value: "",
  label: "Seleccione una Membresía"
};

const DEFAULT_CLUB = {
  value: "general",
  label: "General"
};

const getOptionsMemberships = memberships => {
  return [DEFAULT_MEMBERSHIP].concat(
    memberships.map(({ membresiaId: value, nombre: label }) => ({
      label,
      value
    }))
  );
};

const getOptionsClubs = clubs => {
  return [DEFAULT_CLUB].concat(
    clubs.map(({ clubId: value, nombre: label }) => ({
      label,
      value
    }))
  );
};

const getMembershipValuePlaceHolder = benefits => {
  const { tipoBeneficioMembresia: membershipPriceType } = benefits;
  return membershipPriceType === PRICE_TYPES.PRECIOFIJO
    ? "Precio"
    : "Porcentaje de Descuento";
};

const Header = ({
  benefits,
  onChangePriceType,
  checkProportional,
  checkRelative,
  membershipId,
  memberships,
  onChangeClub,
  onChangeMembership,
  onChangeMembershipValue,
  clubs,
  clubId
}) => {
  const fontsStyles = { width: "0.625em", height: "0.9em" };

  return (
    <Row className="w-100 p-0 m-0">
      <Col className="p-0" xs={3}>
        {memberships && (
          <BaseInput
            label="Membresía"
            name="promotionMembership"
            type="select"
            id="promotionMembership"
            options={getOptionsMemberships(memberships)}
            placeholder="Membresía"
            value={membershipId}
            onChange={onChangeMembership}
          />
        )}
      </Col>
      <Col xs={3}>
        {clubs && (
          <BaseInput
            label="Club"
            name="promotionClub"
            type="select"
            id="promotionClub"
            options={getOptionsClubs(clubs)}
            placeholder="Club"
            value={clubId}
            onChange={onChangeClub}
          />
        )}
      </Col>
      {membershipId && benefits && (
        <React.Fragment>
          <Col xs={6} className="p-0 text-right">
            <Label className="w-100" for="membershipTypeSelect">
              Beneficio de la Membresía
            </Label>
            <Row className="p-0 m-0">
              <Col className="p-0 m-0 text-right" xs={6}>
                <ButtonGroup id="membershipTypeSelect">
                  <Button
                    id="btnFixedPriceBenefit"
                    className={`${s.optionButton} mt-0`}
                    onClick={() => onChangePriceType(PRICE_TYPES.PRECIOFIJO)}
                    active={
                      benefits.tipoBeneficioMembresia === PRICE_TYPES.PRECIOFIJO
                    }
                  >
                    <FontAwesomeIcon style={fontsStyles} icon={faDollarSign} />{" "}
                    <FontAwesomeIcon style={fontsStyles} icon={faEquals} />
                    <UncontrolledTooltip
                      placement="top"
                      target="btnFixedPriceBenefit"
                    >
                      PRECIO BASE FIJO
                    </UncontrolledTooltip>
                  </Button>

                  <Button
                    id="btnFixedDiscountBenefit"
                    className={`${s.optionButton} mt-0`}
                    onClick={() => onChangePriceType(PRICE_TYPES.DESCUENTOABS)}
                    active={
                      benefits.tipoBeneficioMembresia ===
                      PRICE_TYPES.DESCUENTOABS
                    }
                  >
                    <FontAwesomeIcon style={fontsStyles} icon={faDollarSign} />{" "}
                    <FontAwesomeIcon style={fontsStyles} icon={faArrowDown} />
                    <UncontrolledTooltip
                      placement="top"
                      target="btnFixedDiscountBenefit"
                    >
                      DESCUENTO ABSOLUTO
                    </UncontrolledTooltip>
                  </Button>

                  <Button
                    id="btnFixedPercentageBenefit"
                    className={`${s.optionButton} mt-0`}
                    onClick={() => onChangePriceType(PRICE_TYPES.PORCENTAJE)}
                    active={
                      benefits.tipoBeneficioMembresia === PRICE_TYPES.PORCENTAJE
                    }
                  >
                    <FontAwesomeIcon style={fontsStyles} icon={faPercentage} />{" "}
                    <FontAwesomeIcon style={fontsStyles} icon={faArrowDown} />
                    <UncontrolledTooltip
                      placement="top"
                      target="btnFixedPercentageBenefit"
                    >
                      PORCENTAJE
                    </UncontrolledTooltip>
                  </Button>
                </ButtonGroup>
              </Col>
              <Col xs={6} className="p-0 m-0">
                <div className="input-group">
                  <Input
                    name="membershipValue"
                    type="number"
                    id="membershipValue"
                    placeholder={getMembershipValuePlaceHolder(benefits)}
                    value={benefits.valorBeneficioMembresia}
                    onChange={onChangeMembershipValue}
                    className={s.inputValue}
                    style={
                      benefits.tipoBeneficioMembresia ===
                        PRICE_TYPES.PORCENTAJE ||
                      benefits.tipoBeneficioMembresia ===
                        PRICE_TYPES.DESCUENTOABS
                        ? { color: "red" }
                        : {}
                    }
                  />
                  <div className={`${s.inputValue} input-group-prepend`}>
                    <span className="input-group-text">
                      {getSymbolPriceType(benefits.tipoBeneficioMembresia)}
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col className="p-0" xs={6}></Col>
          <Col className="p-0" xs={6}>
            <Form className={`${s.checkboxContainer}`}>
              <FormGroup check className="form-check-inline float-right">
                <Label check>
                  <Input
                    type="checkbox"
                    checked={benefits.aplicaProporcional}
                    onChange={e => checkProportional(e)}
                  />{" "}
                  Aplica Proporcional
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-inline float-right">
                <Label check>
                  <Input
                    type="checkbox"
                    checked={benefits.mesRelativo}
                    onChange={e => checkRelative(e)}
                  />{" "}
                  Mes Relativo
                </Label>
              </FormGroup>
            </Form>
          </Col>
        </React.Fragment>
      )}
    </Row>
  );
};

export default withRouter(withStyles(s)(Header));
