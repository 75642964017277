import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  FormText,
  Alert
} from "reactstrap";
import ReactTable from "react-table";
import MexicanCurrency from "../common/currency";

interface Props {
  payAnnuityQuote: (id: string) => void;
  loading: boolean;
  quote: any;
  clubs: Club[];
  annuityTypes: Catalog[];
  waysPay: Catalog[];
  match: any;
}

interface Club {
  clubId: string;
  nombre: string;
}

interface Catalog {
  id: string;
  nombre: string;
}

class AnnuitiesQuoteById extends React.Component<Props> {
  state = {
    mesesSinIntereses: "",
    club: "",
    tipoAnualidad: "",
    formaPago: ""
  };

  componentDidMount() {
    const { nombre: club } = this.props.clubs.find(
      club => club.clubId == this.props.quote.membresiaSocio.idClubBase
    );
    const { nombre: tipoAnualidad } = this.props.annuityTypes.find(
      tipoAnualidad => tipoAnualidad.id === this.props.quote.anualidad.tipo
    );
    const { nombre: formaPago } = this.props.waysPay.find(
      formaPago => formaPago.id === this.props.quote.formaPago
    );
    const mesesSinIntereses =
      this.props.quote &&
      this.props.quote.mesesSinIntereses &&
      this.props.quote.mesesSinIntereses === 3
        ? "porcentaje3msi"
        : this.props.quote &&
          this.props.quote.mesesSinIntereses &&
          this.props.quote.mesesSinIntereses === 6
        ? "porcentaje6msi"
        : this.props.quote &&
          this.props.quote.mesesSinIntereses &&
          this.props.quote.mesesSinIntereses === 9
        ? "porcentaje9msi"
        : this.props.quote &&
          this.props.quote.mesesSinIntereses &&
          this.props.quote.mesesSinIntereses === 12
        ? "porcentaje12msi"
        : "";
    this.setState({ club, tipoAnualidad, formaPago, mesesSinIntereses });
  }

  renderPrice = cellInfo => {
    const { index, column } = cellInfo;
    const row = this.props.quote.integrantes[index];
    const { faseAplicada } = this.props.quote;
    const { precio } = row.meses.find(month => month.mesNombre === column.id);
    let showPrice =
      precio + precio * (faseAplicada.porcentajeIncremento * 0.01);
    if (this.props.quote.formaPago === "TDC") {
      showPrice =
        this.state.mesesSinIntereses === ""
          ? showPrice - showPrice * (faseAplicada.porcentajeContado * 0.01)
          : showPrice -
            showPrice *
              (faseAplicada[`${this.state.mesesSinIntereses}`] * 0.01);
    } else if (
      this.props.quote.formaPago === "TDD" ||
      this.props.quote.formaPago === "EFECTIVO"
    ) {
      showPrice =
        showPrice - showPrice * (faseAplicada.porcentajeContado * 0.01);
    }
    return (
      <p className="m-0 text-center">
        <MexicanCurrency quantity={showPrice} />
      </p>
    );
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.payAnnuityQuote(this.props.match.params.id);
  };

  render() {
    const columns: any = [
      {
        Header: "Integrante",
        accessor: "tipoIntegrante",
        Cell: ({ value }) => <p className="m-0 text-center">{value}</p>,
        width: 150
      }
    ];
    const indexForEach = e => {
      columns.push({
        Header: `${e.mesNombre}`,
        accessor: `${e.mesNombre}`,
        Cell: d => this.renderPrice(d),
        width: 80
      });
    };

    if (this.props.quote && this.props.quote.meses) {
      this.props.quote.meses.forEach(indexForEach);
    }

    return (
      <Container className="w-100 p-0 my-3">
        <Row>
          <Col xs={12} md={6} className="w-100 p-0 m-0">
            <Row className="w-100 p-0 m-0">
              <Col>
                <FormGroup>
                  <Label># Membresia Socio:</Label>
                  <FormText color="muted">
                    {this.props.quote.membresiaSocio.idPublico}
                  </FormText>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Club:</Label>
                  <FormText color="muted">{this.state.club}</FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row className="w-100 p-0 m-0">
              <Col>
                <FormGroup>
                  <Label>Tipo de Anualidad:</Label>
                  <FormText color="muted">{this.state.tipoAnualidad}</FormText>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Año:</Label>
                  <FormText color="muted">
                    {this.props.quote.anualidad.anio}
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
            {this.props.quote.responsable && this.props.quote.responsable.name && (
              <Row className="w-100 p-0 m-0">
                <Col>
                  <FormGroup>
                    <Label>Responsable de Venta:</Label>
                    <FormText color="muted">
                      {this.props.quote.responsable.name}
                    </FormText>
                  </FormGroup>
                </Col>
              </Row>
            )}
            <Row className="w-100 p-0 m-0">
              <Col>
                <FormGroup>
                  <Label>Forma de Pago:</Label>
                  <FormText color="muted">{this.state.formaPago}</FormText>
                </FormGroup>
              </Col>
              <Col>
                {this.props.quote.banco && this.props.quote.banco.nombre && (
                  <FormGroup>
                    <Label>Banco:</Label>
                    <FormText color="muted">
                      {this.props.quote.banco.nombre}
                    </FormText>
                  </FormGroup>
                )}
              </Col>
              <Col>
                {this.props.quote.mesesSinIntereses ? (
                  <FormGroup>
                    <Label>Meses Sin Intereses:</Label>
                    <FormText color="muted">
                      {this.props.quote.mesesSinIntereses}
                    </FormText>
                  </FormGroup>
                ) : null}
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6} className="m-0 d-flex align-items-center">
            <Row
              className="mt-1 m-0 alert alert-secondary row p-0 w-100"
              role="alert"
            >
              <table className="table m-0">
                <tbody>
                  <tr>
                    <th scope="row">SUBTOTAL</th>
                    <td>
                      <div className="float-right">
                        {this.props.quote && (
                          <MexicanCurrency
                            quantity={this.props.quote.subtotal}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">DESCUENTO</th>
                    <td>
                      <div className="float-right">
                        {this.props.quote && (
                          <MexicanCurrency
                            quantity={this.props.quote.descuentos}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">TOTAL</th>
                    <td>
                      <div className="float-right">
                        {this.props.quote && (
                          <MexicanCurrency quantity={this.props.quote.total} />
                        )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Row>
          </Col>
        </Row>
        {this.props.quote && this.props.quote.integrantes && (
          <Row className="w-100 p-0 m-0">
            <ReactTable
              data={this.props.quote.integrantes || []}
              columns={columns}
              className="w-100 -striped -highlight"
              sortable={false}
              showPagination={false}
              defaultPageSize={
                this.props.quote.integrantes.length === 0
                  ? 3
                  : this.props.quote.integrantes.length
              }
            />
          </Row>
        )}
        {this.props.quote && this.props.quote.estatus === "PAGADA" && (
          <Container className="w-100 pr-0 pl-0 pt-2">
            <span>
              <Alert color="danger mb-0">
                Esta cotización ya ha sido pagada.
              </Alert>
            </span>
          </Container>
        )}
        <Col xs={2} className="float-right pr-0 pt-2">
          <Button
            className={`${s.buttonMarginTop} ${s.primaryButton} float-right`}
            onClick={e => this.handleSubmit(e)}
            size="sm"
            block
            disabled={
              this.props.loading || this.props.quote.estatus === "PAGADA"
            }
          >
            {"Pagar"}
          </Button>
        </Col>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(AnnuitiesQuoteById));
