import React from "react";

import SalesTrackingLayout from "./SalesTrackingLayout";

interface Props {
  sendCompromisesBySeller: (compromises) => any;
  sendCompromises: (compromises) => any;
  userContext: any;
}

class SalesTrackingIndex extends React.Component<Props> {
  render() {
    return (
      <SalesTrackingLayout
        sendCompromisesBySeller={this.props.sendCompromisesBySeller}
        sendCompromises={this.props.sendCompromises}
        userContext={this.props.userContext}
      />
    );
  }
}

export default SalesTrackingIndex;
