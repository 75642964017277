import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";

interface Props {}
interface State {}

export default class SworldConfigPage extends React.Component<Props, State> {
  render() {
    return (
      <main>
        <Helmet title="Expediente Documentos" />
        <Layout>
          <div className="container">
            <div>
              <br />
              <br />
              Bienvenido a la pantalla de configuración de SWidget; por favor,
              haga click en el enlace y permita la apertura por defecto:
              <br />
              <br />
              <a href="sworld://config">Abrir SWidget</a>
            </div>
          </div>
        </Layout>
      </main>
    );
  }
}
