import React from "react";
import s from "./styles.scss";
import { Preview } from "../../../types/IAction";
import {
  Expediente,
  RequestArchivo,
  Archivo
} from "../expedientsV2controls/types";
import { Styled, ReduxConnect } from "../../../toolkit/decorators/Artifact";
import ArchivoControl from "../expedientsV2controls/archivo/archivo";
import * as actions from "../../actions/expedientes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";
import { errorNotification } from "../../../utils/notifications";

const mapDispatchToProps = dispatch => ({
  getExpedienteFromInvitado: (idPersona): Promise<Preview<any>> => {
    return dispatch(actions.getExpedienteFromInvitado(idPersona));
  },
  postRefresh: (requestArchivo: RequestArchivo): Promise<Preview<any>> => {
    return dispatch(actions.postRefresh(requestArchivo));
  },
  postEnvioCorreo: (idExpediente, idItem): Promise<Preview<any>> => {
    return dispatch(actions.postEnvioCorreo(idExpediente, idItem));
  }
});

const mapStateToProps = state => {
  const { expedientes } = state;

  return {
    expedienteInvitado: expedientes.expedienteInvitado,
    expedienteInvitadoLoading: expedientes.expedienteInvitadoLoading,
    archivoRefreshLoading: expedientes.archivoRefreshLoading
  };
};

interface Props {
  // Datos del expediente y sus archivos
  expedienteInvitado?: Expediente;
  expedienteInvitadoLoading?: boolean;
  archivoRefreshLoading?: boolean;

  // Actions del expediente
  getExpedienteFromInvitado?: (idPersona) => Promise<Preview<any>>;
  postRefresh?: (requestArchivo: RequestArchivo) => Promise<Preview<any>>;
  postEnvioCorreo?: (idExpediente, idItem) => Promise<Preview<any>>;

  idPersona: any;
}

interface State {
  waiting: boolean;
  archivos: Archivo[][];
}

/**
 * Tabla del módulo de expedientes
 */
@ReduxConnect(mapStateToProps, mapDispatchToProps)
@Styled(s)
export default class ExpedienteInvitadoTabla extends React.Component<
  Props,
  State
> {
  state = {
    waiting: false,
    archivos: []
  };

  componentDidMount(): void {
    if (this.props.idPersona) {
      this.props.getExpedienteFromInvitado(this.props.idPersona);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.idPersona != prevProps.idPersona) {
      this.props.getExpedienteFromInvitado(this.props.idPersona);
    }

    // Se activa la animación de carga, cuando se está actualizando el expediente
    if (
      this.props.expedienteInvitadoLoading !=
        prevProps.expedienteInvitadoLoading ||
      this.props.archivoRefreshLoading != prevProps.archivoRefreshLoading
    ) {
      this.setState({
        waiting:
          this.props.expedienteInvitadoLoading ||
          this.props.archivoRefreshLoading
      });
    }

    // Si se actualiza el expediente correctamente, se ordenan los archivos
    if (
      this.props.expedienteInvitado != prevProps.expedienteInvitado &&
      this.props.expedienteInvitado.vigentes
    ) {
      this.setState({
        archivos: this.ordenarArchivos()
      });
    }
  }

  sendEmail = async (archivo: Archivo) => {
    const preview = await this.props.postEnvioCorreo(
      archivo.idExpediente,
      archivo.idItem
    );
    return preview;
  };

  regenerar = async (archivo: Archivo) => {
    const requestArchivo: RequestArchivo = {
      codigo: archivo.codigo,
      idMembresiaSocio: null,
      idPersonaExpediente: this.props.idPersona,
      idMembresiaSocioPersona: archivo.idMembresiaSocioPersona,
      idPersonaDocumento: archivo.idPersona,
      idMedioPago: archivo.idMedioPago,
      idCotizacionItem: archivo.idCotizacionItem,
      idPersonaEvaluacion: archivo.idPersonaEvaluacion
    };
    const preview = await this.props.postRefresh(requestArchivo);

    if (preview.fulfilled) {
      await this.props.getExpedienteFromInvitado(this.props.idPersona);
    }

    return preview;
  };

  update = async () => {
    return await this.props.getExpedienteFromInvitado(this.props.idPersona);
  };

  ordenarArchivos() {
    const hash: any = {};
    const ordenados = [];

    // Se separan los expedientes por usuario
    for (const archivo of this.props.expedienteInvitado.vigentes) {
      if (hash[archivo.idOwner]) {
        hash[archivo.idOwner].push(archivo);
      } else {
        hash[archivo.idOwner] = [archivo];
      }
    }

    // Se concentran los expedientes por usuario en una lista
    for (const archivosKey in hash) {
      ordenados.push(hash[archivosKey]);
    }

    return ordenados;
  }

  render() {
    // Si no se han cargado o actualizado los documentos, se muestra la animación
    const className =
      this.state.waiting || !this.props.expedienteInvitado.vigentes
        ? `${s.expedient} ${s.waiting}`
        : s.expedient;

    return (
      <div className={className}>
        <div className={s.spinner}>
          <FontAwesomeIcon icon={faSpinner} pulse />
        </div>

        {this.state.archivos.map(this.renderTable)}
      </div>
    );
  }

  renderTable = (archivos: Archivo[]) => {
    if (!archivos || !archivos.length) {
      return <></>;
    }

    // const nombre = `${archivos[0].nombreCompleto} (${archivos[0].idOwner})`;
    return (
      <>
        <div className={s.space}></div>
        <div style={{ width: "400px" }}>
          <Button
            className="primaryButton"
            onClick={async e => {
              this.setState({ waiting: true });

              try {
                await actions.putVerificarCondicinalesInvitado(
                  this.props.idPersona
                );
                this.props.getExpedienteFromInvitado(this.props.idPersona);
                this.setState({ waiting: false });
              } catch (err) {
                this.setState({ waiting: false });
                errorNotification("No se pudieron verificar los documentos");
              }
            }}
            size="sm"
            block
            disabled={this.state.waiting}
          >
            Verificar condicionales
          </Button>
        </div>
        <div className={s.space}></div>
        {archivos.map(archivo => (
          <ArchivoControl
            key={"archivo-item-" + archivo.idItem}
            archivo={archivo}
            userId={0}
            sendEmail={this.sendEmail}
            regenerar={this.regenerar}
            update={this.update}
          />
        ))}
      </>
    );
  };
}
