import React from "react";
import { DateItem, Week } from "./types";
import { WeekComponent } from "./WeekComponent";

interface Props {
  id: String;
  dates: DateItem[];
  soportaAlterna: boolean;
}

interface State {}

/**
 * Semanas pertenecientes a cada mes
 */
export class MonthWeeksComponent extends React.Component<Props, State> {
  weeks: Week[] = [];

  render() {
    const id = this.props.id;
    this.calcWeeks();
    return this.weeks.map(week => (
      <WeekComponent
        id={id}
        week={week}
        soportaAlterna={this.props.soportaAlterna}
      />
    ));
  }

  /**
   * Cálculo de semanas pertenecientes al rango de fechas en props.dates
   */
  calcWeeks() {
    if (this.weeks.length > 0) {
      return;
    }

    let week: Week = {};
    for (const date of this.props.dates) {
      // Normalización de los días de la semana (lunes día uno)
      const dayAtSunday = date.moment.get("day");
      const dayAtMonday = dayAtSunday === 0 ? 7 : dayAtSunday;

      // Se añaden los días correspondientes al objeto "week"
      switch (dayAtMonday) {
        case 1:
          week.monday = date;
          break;
        case 2:
          week.tuesday = date;
          break;
        case 3:
          week.wednesday = date;
          break;
        case 4:
          week.thursday = date;
          break;
        case 5:
          week.friday = date;
          break;
        case 6:
          week.saturday = date;
          break;
        case 7:
          // Cada domingo se agrega una nueva semana
          week.sunday = date;
          this.weeks.push(week);
          week = {};
          break;

        default:
          break;
      }
    }

    // Si sobraron días, se añade la semana final
    if (week.monday) {
      this.weeks.push(week);
    }
  }
}
