import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";

import ModalFrame from "../../../toolkit/modalFrame";
import s from "./styles.scss";

import { Row, Col, Button, Container, Alert } from "reactstrap";

interface Prospect {
  personaId: string;
  nombre: string;
  primerApellido: string;
  segundoApellido: string;
}

interface Props {
  isOpen: boolean;
  toggle: () => void;
  history: any;
  client: any;
  deletingProspect: boolean;
  deletingProspectError: any;
  prospect: Prospect;
  deleteProspect: (string) => void;
}

interface State {}

class DeleteProspectModal extends React.Component<Props, State> {
  state = {};

  componentWillReceiveProps(nextProps) {
    if (nextProps.userContext && nextProps.isOpen) {
      this.props.toggle();
    }
  }

  componentDidUpdate() {}

  onSubmit = e => {
    e.preventDefault();
    this.props.deleteProspect(this.props.prospect.personaId);
  };

  render() {
    return (
      <ModalFrame
        title={"Eliminar Prospecto"}
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <Container>
          <Row>
            <p className={`${s.breakWord} text-center w-100`}>
              ¿Está seguro que desea eliminar a
              <span className="text-capitalize font-weight-bold">
                {this.props.prospect
                  ? ` ${this.props.prospect.nombre} ${
                      this.props.prospect.primerApellido
                    }${
                      this.props.prospect.segundoApellido
                        ? ` ${this.props.prospect.segundoApellido}`
                        : ""
                    }?`
                  : null}
              </span>
            </p>
          </Row>
          <Row>
            <Col>
              <Button
                disabled={this.props.deletingProspect}
                onClick={() => this.props.toggle()}
                className={s.pullRight}
                color="secondary"
              >
                Cancelar
              </Button>
            </Col>
            <Col>
              <Button
                disabled={this.props.deletingProspect}
                onClick={e => this.onSubmit(e)}
                color="danger"
              >
                Eliminar
              </Button>
            </Col>
          </Row>
          {this.props.deletingProspectError && (
            <span>
              <hr />
              <Alert color="danger">Ocurrió un error inesperado.</Alert>
            </span>
          )}
        </Container>
      </ModalFrame>
    );
  }
}

export default withRouter(withStyles(s)(DeleteProspectModal));
