import React from "react";
import { connect } from "react-redux";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import SaveNextCarousel from "../../../components/saveNextCarousel";
import { Helmet } from "react-helmet";
import Layout from "../../../components/layout";
import { AGREEMENT_GROUPS_STEPS } from "../../../../steps";
import { getAgreementById } from "../../../actions/agreementGroups";
import s from "./styles.scss";

// TODO: Esta funcionalidad se heredó de los convenios; faltan exclusiones

interface Props {
  savedAgreement: { convenioId: string };
  getAgreementById: (id: string) => any;
  agreement: object;
  history: any;
  match: any;
  location: any;
  index: number;
  component: any;
}

interface State {}

const ComponentWithMembershipName = (
  Component,
  index,
  rest,
  agreement,
  convenioId
) => props => {
  return (
    <div className="w-100 p-0 m-0">
      {agreement && convenioId && (
        <p className={s.primaryTitle}>{agreement.nombre.toUpperCase()}</p>
      )}
      <Component {...rest} {...props} key={index} />
    </div>
  );
};

class AgreementsCarousel extends React.Component<Props, State> {
  state = {
    hiddenSaveButton: false
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) this.props.getAgreementById(id);
    this.setState(state => {
      const hiddenSaveButton =
        this.props.index + 1 > AGREEMENT_GROUPS_STEPS.length;
      return {
        ...state,
        hiddenSaveButton
      };
    });
  }

  onClickNext = e => {
    e.preventDefault();
    const params = {
      ...this.props.match.params,
      id: this.props.match.params.id || this.props.savedAgreement.convenioId
    };
    this.props.history.push(
      AGREEMENT_GROUPS_STEPS[this.props.index].getNextStep(params)
    );
  };

  getProductId = () =>
    this.props.match.params.id ||
    (this.props.savedAgreement && this.props.savedAgreement.convenioId);

  render() {
    const convenioId = this.getProductId();
    return (
      <main>
        <Helmet title="Nuevo Convenio" />
        <Layout>
          {!convenioId ||
          (convenioId &&
            (this.props.agreement || this.props.savedAgreement)) ? (
            <SaveNextCarousel
              steps={AGREEMENT_GROUPS_STEPS.map(({ title, getHref }) => ({
                title,
                href: convenioId ? getHref(convenioId) : null
              }))}
              onClickNext={this.onClickNext}
              index={this.props.index}
              disableNext={
                !this.props.match.params.id && !this.props.savedAgreement
              }
              hiddenSaveButton={this.state.hiddenSaveButton}
              activeStep={convenioId ? AGREEMENT_GROUPS_STEPS.length : 0}
            >
              {AGREEMENT_GROUPS_STEPS.map(
                ({ component: Component, ...rest }, index) => {
                  const ComponentWithName = ComponentWithMembershipName(
                    Component,
                    index,
                    rest,
                    this.props.agreement,
                    convenioId
                  );
                  return <ComponentWithName key={index} />;
                }
              )}
            </SaveNextCarousel>
          ) : null}
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getAgreementById: (id: string) => {
    dispatch(getAgreementById(id));
  }
});

const mapStateToProps = state => {
  const agreements = state.agreementGroups;
  return {
    savedAgreement: agreements.basicAgreement,
    agreement: agreements.agreementById
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(s)(AgreementsCarousel));
