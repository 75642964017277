import React from "react";
import { Button, Col, Container, Row } from "reactstrap";
import TextArea from "../../../../../toolkit/baseInput/TextArea";
import ModalFrame from "../../../../../toolkit/modalFrame";
import { userHasPermission } from "../../../../../utils/accessTree";
import { handleRequestError } from "../../../../../utils/helpers";
import { errorNotification } from "../../../../../utils/notifications";
import permissionCodes from "../../../../../utils/permissionCodes";
import {
  cancelMembershipRequest,
  cancelMembershipValidationRequest
} from "../../../../actions/partners";
import NotificationsMessages from "../..//notificationsMessages";

interface Partner {
  idMembresiaSocio: string;
  idPersona: string;
  idPropietario: string;
}

interface Props {
  partner: Partner;
  reload: () => any;
}

interface State {
  isOpen: boolean;
  cancelMessages: any[];
  cancelCharges: any[];
  reasonToCancel: string;
}

class CancelMembership extends React.Component<Props, State> {
  state = {
    isOpen: false,
    cancelMessages: [],
    cancelCharges: [],
    reasonToCancel: null
  };

  toggelCancelModal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  validateCancelMembership = () => {
    const { partner } = this.props;

    cancelMembershipValidationRequest(partner.idMembresiaSocio)
      .then(({ data }) => {
        const { mensajes, cargosAGenerar } = data;
        this.setState({
          isOpen: true,
          cancelMessages: mensajes,
          cancelCharges: cargosAGenerar || [],
          reasonToCancel: null
        });
      })
      .catch(handleRequestError);
  };

  executeCancelMembership = () => {
    const { idMembresiaSocio: membresiaSocioId } = this.props.partner;
    const { reasonToCancel: motivo } = this.state;

    cancelMembershipRequest({ membresiaSocioId, motivo })
      .then(({ data }) => {
        const { resultado } = data;

        if (!!resultado) {
          this.clearState();
          this.props.reload();
        } else {
          errorNotification(
            "Ocurrió un error al ejecutar la cancelación de membresía socio"
          );
        }
      })
      .catch(handleRequestError);
  };

  cancelAction = () => {
    this.clearState();
  };

  clearState = () => {
    this.setState({
      isOpen: false,
      cancelMessages: [],
      cancelCharges: [],
      reasonToCancel: null
    });
  };

  render() {
    return (
      <div>
        <ModalFrame
          title={"Confirmar cancelación de membresía socio"}
          isOpen={this.state.isOpen}
          toggle={this.toggelCancelModal}
          size="lg"
        >
          <Container>
            <p>Mensajes:</p>
            <NotificationsMessages messages={this.state.cancelMessages || []} />
            <br />
            {!(this.state.cancelMessages || []).length && (
              <div>
                <br />
                <p className={`w-100`}>
                  ¿Desea realizar la cancelacíon de la membresía socio? (Una vez
                  realizados los cambios no podrán ser revertidos)
                </p>
                <br />
                <br />
                <TextArea
                  value={this.state.reasonToCancel}
                  onChange={value => {
                    this.setState({ reasonToCancel: value });
                  }}
                  label="Observaciones / Motivo de cancelación"
                  id="textAreaReasonToCancel"
                />
                <br />
                <br />
              </div>
            )}
            <Row>
              <Col className="text-right">
                <Button
                  color="secondary"
                  onClick={this.cancelAction}
                  style={{ marginRight: "15px" }}
                >
                  Cancelar
                </Button>

                {!(this.state.cancelMessages || []).length &&
                  !!this.state.reasonToCancel &&
                  userHasPermission(
                    permissionCodes.PARTNER_MEMBERSHIP_TAB_TRAM_CANCEL_MEMBERSHIP
                  ) && (
                    <Button
                      onClick={() => {
                        this.executeCancelMembership();
                      }}
                      style={{ marginRight: "15px" }}
                    >
                      Realizar la cancelación de membresía socio
                    </Button>
                  )}
              </Col>
            </Row>
          </Container>
        </ModalFrame>

        <Row className="p-0 m-0">
          <Col xs={2}>
            <Button onClick={this.validateCancelMembership} className="mt-3">
              Cancelar membresía
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default CancelMembership;
