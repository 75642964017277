import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { showDocument } from "../../actions/documents";
import Layout from "../../components/layout";
import * as catalogActions from "../../actions/catalogs";
import * as reportsActions from "../../actions/reports";
import IAction from "../../../types/IAction";
import CreditNotesReport from "../../components/creditNotesReport";

interface Props {
  clubs: object[];
  reportCreditNotes: any;
  rejectCreditNote: boolean;
  userContext: any;
  getClubs: () => void;
  getCreditNotes: (
    clubId: string,
    fechaInicio: string,
    fechaFin: string,
    fechaInicioAutorizacion: string,
    fechaFinAutorizacion: string,
    estatus: string
  ) => IAction;
  toggleBill: (storageId: number) => any;
  processCreditNote: (solicitudId: string) => IAction;
  rejectCreditNoteDemand: (notaCreditoSolicitudId: string) => IAction;
  clearReasignMembership: () => IAction;
  loadingCreditNotes: boolean;
}

interface State {}

class CreditNotes extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    this.props.getClubs();
  }

  componentWillUnmount() {
    this.props.clearReasignMembership();
  }

  render() {
    return (
      <main>
        <Helmet title="Shift delivery" />
        <Layout>
          <CreditNotesReport
            clubs={this.props.clubs}
            reportCreditNotes={this.props.reportCreditNotes}
            getCreditNotes={this.props.getCreditNotes}
            loadingCreditNotes={this.props.loadingCreditNotes}
            toggleBill={this.props.toggleBill}
            processCreditNote={this.props.processCreditNote}
            rejectCreditNoteDemand={this.props.rejectCreditNoteDemand}
            rejectCreditNote={this.props.rejectCreditNote}
            userContext={this.props.userContext}
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({ catalogs, reports, user }) => {
  return {
    clubs: catalogs.clubs,
    reportCreditNotes: reports.reportCreditNotes,
    loadingCreditNotes: reports.loadingCreditNotes,
    rejectCreditNote: reports.rejectCreditNote,
    userContext: user.userContext
  };
};

const mapDispatchToProps = dispatch => ({
  toggleBill: showDocument,
  getClubs: () => {
    dispatch(catalogActions.getClubs());
  },
  getCreditNotes: (
    clubId: string,
    fechaInicio: string,
    fechaFin: string,
    fechaInicioAutorizacion: string,
    fechaFinAutorizacion: string,
    estatus: string,
    tipo: string
  ) => {
    return dispatch(
      reportsActions.getCreditNotes(
        clubId,
        fechaInicio,
        fechaFin,
        fechaInicioAutorizacion,
        fechaFinAutorizacion,
        estatus,
        tipo
      )
    );
  },
  processCreditNote: (solicitudId: string) => {
    return dispatch(reportsActions.processCreditNote(solicitudId));
  },
  rejectCreditNoteDemand: (notaCreditoSolicitudId: string) => {
    return dispatch(
      reportsActions.rejectCreditNoteDemand(notaCreditoSolicitudId)
    );
  },
  clearReasignMembership: () => {
    dispatch(reportsActions.clearReasignMembership());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditNotes);
