import { Profile } from "./Profile";

export interface User {
  userId?: number;
  username: string;
  domain: string;
  groups: string[];
  status: string;
  profile: Profile;
}

export interface UserAccount {
  account: User;
  profile: Profile;
}

export enum MedioProspeccion {
  CONTACT_CENTER = "CONTACT_CENTER",
  //TV = "TV",
  CINE = "CINE",
  ESPECTACULARES = "ESPECTACULARES",
  FACEBOOK_GOOGLE = "FACEBOOK_GOOGLE",
  FLYER_Y_ACTIVACIONES = "FLYER_Y_ACTIVACIONES",
  IMAGEN_CENTRO_COMERCIAL = "IMAGEN_CENTRO_COMERCIAL",
  REVISTA_Y_IMPRESOS = "REVISTA_Y_IMPRESOS",
  STANDS = "STANDS",
  CARRERAS_Y_EVENTOS = "CARRERAS_Y_EVENTOS",
  ACTIVIDADES_SW = "ACTIVIDADES_SW",
  PAGINA_WEB = "PAGINA_WEB",
  AMIGO_REFERIDO = "AMIGO_REFERIDO",
  //WALKING = "WALKING",
  WALKING_ESPONTANEO = "WALKING_ESPONTANEO",
  WALKING_GUIADO = "WALKING_GUIADO",
  WHATSAPP = "WHATSAPP"
}

export interface Prospect {
  correoElectronico: string;
  nombre: string;
  primerApellido: string;
  segundoApellido: string;
  fechaNacimiento: string;
  medioProspeccion: MedioProspeccion;
  codigoPostal: string;
  colonia: string;
  sexo: string;
  numeroTelefono: string;
  extension: string;
  folio: string;
}

export enum INTEREST_STATUS {
  STATUS_INTERESADO = "INTERESADO",
  STATUS_NO_INTERESADO = "NO_INTERESADO",
  STATUS_POSIBLEMENTE = "POSIBLEMENTE"
}

export const DISINTEREST_REASON = [
  {
    value: "PRECIO",
    label: "Precio"
  },
  {
    value: "OFERTA_DEPORTIVA",
    label: "Oferta deportiva"
  },
  {
    value: "MANTENIMIENTO_INSTALACIONES",
    label: "Mantenimiento instalaciones"
  },
  {
    value: "LIMPIEZA",
    label: "Limpieza"
  },
  {
    value: "MIEDO_CONTAGIO",
    label: "Miedo al contagio"
  },
  {
    value: "PROTOCOLO_SANIDAD",
    label: "Protocolo de sanidad"
  },
  {
    value: "INCERTIDUMBRE_SALARIAL",
    label: "Incertidumbre de sueldo"
  },
  {
    value: "ESTACIONAMIENTO",
    label: "Estacionamiento"
  },
  {
    value: "DISTANCIA",
    label: "Distancia del club"
  }
];
