import React from "react";
import { connect } from "react-redux";
import {
  getMediosPagoEliminados,
  getMediosPagoEliminadosReporte
} from "../../actions/partners";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import MediosPagoEliminadosPage from "../../components/mediosPagoEliminados";

interface Props {
  loadingMediosPagoEliminados: boolean;
  downloadingMediosPagoEliminados: boolean;
  mediosPagoEliminados: any[];
  getMediosPagoEliminados: (params: any) => void;
  getMediosPagoEliminadosReporte: (params: any) => void;
  userContext: any;
}

class MediosPagoEliminados extends React.Component<Props, void> {
  render() {
    return (
      <main>
        <Helmet title="Integrantes por Membresía" />
        <Layout>
          <MediosPagoEliminadosPage
            loadingMediosPagoEliminados={this.props.loadingMediosPagoEliminados}
            downloadingMediosPagoEliminados={
              this.props.downloadingMediosPagoEliminados
            }
            mediosPagoEliminados={this.props.mediosPagoEliminados}
            getMediosPagoEliminados={this.props.getMediosPagoEliminados}
            getMediosPagoEliminadosReporte={
              this.props.getMediosPagoEliminadosReporte
            }
            userContext={this.props.userContext}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getMediosPagoEliminados: (params: any): any => {
    dispatch(getMediosPagoEliminados(params));
  },
  getMediosPagoEliminadosReporte: (params: any): any => {
    dispatch(getMediosPagoEliminadosReporte(params));
  }
});

const mapStateToProps = state => {
  const { partners } = state;

  return {
    loadingMediosPagoEliminados: partners.loadingMediosPagoEliminados,
    downloadingMediosPagoEliminados: partners.downloadingMediosPagoEliminados,
    mediosPagoEliminados: partners.mediosPagoEliminados,
    userContext: state.user.userContext
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MediosPagoEliminados);
