import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import VentasPortalReportePage from "../../components/ventasPortalReporte";

interface Props {}
interface State {}

class VentasPortalReporte extends React.Component<Props, State> {
  render() {
    return (
      <main>
        <Helmet title="Ventas por portal" />
        <Layout>
          <VentasPortalReportePage />
        </Layout>
      </main>
    );
  }
}

export default VentasPortalReporte;
