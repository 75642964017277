import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";

// Eliminar
import * as fixture from "../actionsFixture/membresiasCrm1";
import { restActionFactory } from "../../toolkit/axiosActions/axiosActionsFactory";

// Limpieza para convenio para carrusel
export const clearMembresiasCrm1 = (): IAction => {
  return {
    type: actionTypes.CLEAR_MEMBRESIAS_CRM1,
    payload: true
  };
};

// Membresías de socios desde CRM1
export const getMembresiasCrm1Acceso = (params: any): IAction => {
  let formData = new FormData();
  formData.append("clubId", params.clubId || 0);
  formData.append("membresia", params.membresia || 0);
  formData.append("personaId", params.personaId || 0);

  if (params.persona) {
    formData.append("persona", params.persona);
  }

  return conveniosRestAction({
    actionType: actionTypes.GET_MEMBRESIAS_CRM1_ACCESO,
    method: "post",
    data: formData,
    noCredentials: true,
    headers: {
      "secret-key": "584AB9068ABE7D5B9AFCDA12F9F7"
    },

    testing: false,
    fixture: () => fixture.membresiasCrm1Fixture,
    reject: false
  });
};

// Construye una instancia de IAction para Redux, para manipular membresías de CRM1
const conveniosRestAction = restActionFactory(
  `https://cloud2.sportsworld.com.mx/apiMonitor/api/v1/buscar/perfil/detalle`
);
