import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import { getErrorMessage } from "../../utils/helpers";
import { ERROR, errorNotification } from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";
import NotaCreditoBloqueo from "../../types/NotaCreditoBloqueo";

export interface GetNotaCreditoBloqueos {
  notaCreditoBloqueos: NotaCreditoBloqueo[];
  gettingNotaCreditoBloqueos: boolean;
  gettingNotaCreditoBloqueosError: string;
}

export interface PostNotaCreditoBloqueo {
  creatingNotaCreditoBloqueo: boolean;
  creatingNotaCreditoBloqueoError: string;
}

export interface DeleteNotaCreditoBloqueo {
  deletingNotaCreditoBloqueo: boolean;
  deletingNotaCreditoBloqueoError: string;
}

interface State
  extends GetNotaCreditoBloqueos,
    PostNotaCreditoBloqueo,
    DeleteNotaCreditoBloqueo {}

const initialState: State = {
  notaCreditoBloqueos: [],
  gettingNotaCreditoBloqueos: false,
  gettingNotaCreditoBloqueosError: null,

  creatingNotaCreditoBloqueo: false,
  creatingNotaCreditoBloqueoError: null,

  deletingNotaCreditoBloqueo: false,
  deletingNotaCreditoBloqueoError: null
};

export default function notasCreditoReducer(
  state: State = initialState,
  action: IAction
): State {
  const snapshot = snapshotAction(state, action);

  // Llamada a resolver de snapshot
  state != snapshot &&
    !action.type.endsWith("_PENDING") &&
    action.meta &&
    action.meta.resolver &&
    action.meta.resolver({
      fulfilled: action.type.endsWith("_FULFILLED"),
      ...snapshot
    });

  return state != snapshot
    ? {
        ...state,
        ...snapshot
      }
    : state;
}

function snapshotAction(
  state: State = initialState,
  action: IAction
): Partial<State> {
  switch (action.type) {
    // GET_NOTA_CREDITO_BLOQUEADAS
    case `${actionTypes.GET_NOTA_CREDITO_BLOQUEADAS}_PENDING`: {
      return {
        notaCreditoBloqueos: [],
        gettingNotaCreditoBloqueos: true,
        gettingNotaCreditoBloqueosError: null
      };
    }
    case `${actionTypes.GET_NOTA_CREDITO_BLOQUEADAS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        gettingNotaCreditoBloqueos: false,
        gettingNotaCreditoBloqueosError: getErrorMessage(action.payload)
      };
    }
    case `${actionTypes.GET_NOTA_CREDITO_BLOQUEADAS}_FULFILLED`: {
      return {
        notaCreditoBloqueos: action.payload.data,
        gettingNotaCreditoBloqueos: false,
        gettingNotaCreditoBloqueosError: null
      };
    }

    // POST_NOTA_CREDITO_BLOQUEADA
    case `${actionTypes.POST_NOTA_CREDITO_BLOQUEADA}_PENDING`: {
      return {
        creatingNotaCreditoBloqueo: true,
        creatingNotaCreditoBloqueoError: null
      };
    }
    case `${actionTypes.POST_NOTA_CREDITO_BLOQUEADA}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        creatingNotaCreditoBloqueo: false,
        creatingNotaCreditoBloqueoError: getErrorMessage(action.payload)
      };
    }
    case `${actionTypes.POST_NOTA_CREDITO_BLOQUEADA}_FULFILLED`: {
      return {
        creatingNotaCreditoBloqueo: false,
        creatingNotaCreditoBloqueoError: null
      };
    }

    // DELETE_NOTA_CREDITO_BLOQUEADA
    case `${actionTypes.DELETE_NOTA_CREDITO_BLOQUEADA}_PENDING`: {
      return {
        deletingNotaCreditoBloqueo: true,
        deletingNotaCreditoBloqueoError: null
      };
    }
    case `${actionTypes.DELETE_NOTA_CREDITO_BLOQUEADA}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        deletingNotaCreditoBloqueo: false,
        deletingNotaCreditoBloqueoError: getErrorMessage(action.payload)
      };
    }
    case `${actionTypes.DELETE_NOTA_CREDITO_BLOQUEADA}_FULFILLED`: {
      return {
        deletingNotaCreditoBloqueo: false,
        deletingNotaCreditoBloqueoError: null
      };
    }

    default: {
      return state;
    }
  }
}

export { initialState };
