import React from "react";
import { Container, TabPane } from "reactstrap";
import { userHasPermission } from "../../../../utils/accessTree";
import permissionCodes from "../../../../utils/permissionCodes";
import VacationFeesTable from "./vacationFeesTable";
import { handleRequestError } from "../../../../utils/helpers";
import ConfirmModal from "../../common/confirmModal/confirmModal";
import { createPartnerMembershipVacationFee } from "../../../actions/partners";

const VACATIONAL_FEES_QUINCENAS = ["PRIMERA", "SEGUNDA"];
const VACATIONAL_FEES_DEFAULT_QUINCENA = "PRIMERA";

interface Props {
  vacationFees: any[];
  idMembresiaSocio: string;
  invalidateStateValue: (fieldName: string, value: any) => void;
  getMembershipVacationFees: () => void;
}

interface State {
  confirmAddVacationalFeeIsOpen: boolean;
}

class TabVacationFees extends React.Component<Props, State> {
  state = {
    confirmAddVacationalFeeIsOpen: false
  };

  componentDidMount() {}

  addVacationFee = () => {
    this.setState({
      confirmAddVacationalFeeIsOpen: true
    });
  };

  clearVacationFeeConfirmModal = () => {
    this.setState({
      confirmAddVacationalFeeIsOpen: false
    });
  };

  handleSubmitVacationFeeRequest = selected => {
    const membresiaSocioId = this.props.idMembresiaSocio;
    const quincena = selected || VACATIONAL_FEES_DEFAULT_QUINCENA;

    createPartnerMembershipVacationFee(membresiaSocioId, quincena)
      .then(() => {
        this.clearVacationFeeConfirmModal();

        if (!!this.props.invalidateStateValue) {
          this.props.invalidateStateValue("vacationFees", []);
        }

        if (!!this.props.getMembershipVacationFees) {
          this.props.getMembershipVacationFees();
        }
      })
      .catch(handleRequestError);
  };

  render() {
    const { vacationFees } = this.props;
    return (
      <TabPane className="my-2" tabId="20">
        <Container className="p-0">
          <ConfirmModal
            title="Crear solicitud de cuota vacacional"
            message="Se creará una solicitud de cuota vacacional para el siguiente mes y de acuerdo a la quincena seleccionada. ¿Desea continuar?"
            isOpen={this.state.confirmAddVacationalFeeIsOpen}
            requireInput={true}
            inputType="select"
            inputTitle="Quincena de acceso"
            options={VACATIONAL_FEES_QUINCENAS.map(x => {
              return { label: x, value: x };
            })}
            ok={this.handleSubmitVacationFeeRequest}
            cancel={this.clearVacationFeeConfirmModal}
          />

          <VacationFeesTable
            vacationFeesList={vacationFees || []}
            partnerMembershipId={this.props.idMembresiaSocio}
            invalidateStateValue={this.props.invalidateStateValue}
            getMembershipVacationFees={this.props.getMembershipVacationFees}
            addVacationFee={this.addVacationFee}
          />
        </Container>
      </TabPane>
    );
  }
}

export default TabVacationFees;
