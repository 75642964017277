import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";
import { QueryArguments } from "../../types-business/QueryArguments";

const baseUrl = `/api/companies`;

export const getCompanies = (params?: QueryArguments): IAction => {
  return {
    type: actionTypes.GET_COMPANIES,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}`, {
        withCredentials: true,
        params: { ...params, ...constants.DEFAULT_SORTING_PARAMS }
      })
    }
  };
};

export const getCompanie = (id: string): IAction => {
  return {
    type: actionTypes.GET_COMPANIE,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/${id}`, {
        withCredentials: true
      })
    }
  };
};

export const createCompanie = (fields: object): IAction => {
  return {
    type: actionTypes.CREATE_COMPANIE,
    payload: {
      promise: axios.post(`${constants.BASE_URL}${baseUrl}`, fields, {
        withCredentials: true
      })
    }
  };
};

export const updateCompanie = (id: string, fields: object): IAction => {
  return {
    type: actionTypes.UPDATE_COMPANIE,
    payload: {
      promise: axios.put(`${constants.BASE_URL}${baseUrl}/${id}`, fields, {
        withCredentials: true
      })
    }
  };
};

export const deleteCompanie = (id: string): IAction => {
  return {
    type: actionTypes.DELETE_COMPANIE,
    payload: {
      promise: axios.delete(`${constants.BASE_URL}${baseUrl}/${id}`, {
        withCredentials: true
      })
    }
  };
};
