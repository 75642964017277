import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import { ERROR } from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";
import MembresiaCrm1 from "../../types/MembresiaCrm1";

// Membresías de CRM1
export interface GetMembresiasCrm1Acceso {
  membresiasCrm1Acceso: MembresiaCrm1[];
  membresiasCrm1AccesoLoading: boolean;
  membresiasCrm1AccesoError: string;
}

interface State extends GetMembresiasCrm1Acceso {}

const initialState: State = {
  membresiasCrm1Acceso: null,
  membresiasCrm1AccesoLoading: false,
  membresiasCrm1AccesoError: null
};

export default function membresiasCrm1Reducer(
  state: State = initialState,
  action: IAction
): State {
  const snapshot = snapshotAction(state, action);

  // Llamada a resolver de snapshot
  state != snapshot &&
    !action.type.endsWith("_PENDING") &&
    action.meta &&
    action.meta.resolver &&
    action.meta.resolver({
      fulfilled: action.type.endsWith("_FULFILLED"),
      ...snapshot
    });

  return state != snapshot
    ? {
        ...state,
        ...snapshot
      }
    : state;
}

function snapshotAction(
  state: State = initialState,
  action: IAction
): Partial<State> {
  switch (action.type) {
    // CLEAR_MEMBRESIAS_CRM1
    case actionTypes.CLEAR_MEMBRESIAS_CRM1:
      return {
        membresiasCrm1Acceso: null
      };

    // GET_MEMBRESIAS_CRM1_ACCESO
    case `${actionTypes.GET_MEMBRESIAS_CRM1_ACCESO}_PENDING`:
      return {
        membresiasCrm1Acceso: null,
        membresiasCrm1AccesoLoading: true,
        membresiasCrm1AccesoError: null
      };
    case `${actionTypes.GET_MEMBRESIAS_CRM1_ACCESO}_REJECTED`:
      return {
        membresiasCrm1Acceso: null,
        membresiasCrm1AccesoLoading: false,
        membresiasCrm1AccesoError: readMessageBack(action, ERROR)
      };
    case `${actionTypes.GET_MEMBRESIAS_CRM1_ACCESO}_FULFILLED`:
      return {
        membresiasCrm1Acceso: action.payload.data || [],
        membresiasCrm1AccesoLoading: false,
        membresiasCrm1AccesoError: null
      };
    default:
      return state;
  }
}
