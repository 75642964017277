import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import * as reportes from "../../actions/reports";
import Layout from "../../components/layout";
import ReportCatProcessesDirectionSummary from "../../components/catProcessesDirectionSummaryReport";
import IAction from "../../../types/IAction";

interface Props {
  downloadReportCatProcessesDirectionSummary: () => IAction;
}

interface State {}

class ReportCatProcessesDirectionSummaryPage extends React.Component<
  Props,
  State
> {
  state = {};

  render() {
    return (
      <main>
        <Helmet title="ReportCatProcessesDirectionSummary" />
        <Layout>
          <ReportCatProcessesDirectionSummary
            downloadReportCatProcessesDirectionSummary={
              this.props.downloadReportCatProcessesDirectionSummary
            }
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = dispatch => ({
  downloadReportCatProcessesDirectionSummary: () => {
    dispatch(reportes.downloadReportCatProcessesDirectionSummary());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportCatProcessesDirectionSummaryPage);
