import React from "react";
import { TabPane, Button } from "reactstrap";
import { Partner } from "./interfaces";
import ColoresControl from "../expedientsV2controls/archivo/colores";
import ExpedienteTabla from "../expedientsV2table/index";
import { getDirectIdMembresiaSocio } from "../../actions/dicounts";
import ReactTable from "react-table";

interface Props {
  partner: Partner;
  activeTab: string;
}

interface State {
  descuentos: any[];
  wait: boolean;
}

export default class TabDescuentosDirectos extends React.Component<
  Props,
  State
> {
  state = {
    descuentos: [],
    wait: false
  };

  componentDidUpdate(prevProps) {
    if (prevProps.activeTab != this.props.activeTab) {
      if (this.props.activeTab == "19") {
        this.setState({
          wait: true
        });

        getDirectIdMembresiaSocio(this.props.partner.idMembresiaSocio)
          .then(response => {
            this.setState({
              descuentos: response.data,
              wait: false
            });
          })
          .catch(err => {
            this.setState({
              descuentos: [],
              wait: false
            });
          });
      } else {
        this.setState({
          descuentos: [],
          wait: false
        });
      }
    }
  }

  render() {
    if (this.props.activeTab != "19") {
      return <></>;
    }

    return (
      <TabPane className="my-2" tabId="19">
        <div>
          <ReactTable
            className="mt-5 -striped -highlight"
            data={this.state.descuentos || []}
            columns={columns}
            sortable={false}
            resizable={true}
            pageSize={
              this.state.descuentos && this.state.descuentos.length > 5
                ? this.state.descuentos.length
                : 5
            }
            showPagination={false}
            loading={this.state.wait}
          />
        </div>
      </TabPane>
    );
  }
}

const columns = [
  {
    Header: "Club",
    id: "club",
    accessor: "club",
    width: "128"
  },
  {
    Header: "Membresía",
    id: "idPublico",
    accessor: "idPublico",
    width: "88"
  },
  {
    Header: "Fecha beneficio",
    id: "fechaBeneficio",
    accessor: "fechaBeneficio",
    width: "120"
  },
  {
    Header: "Tipo beneficio",
    id: "tipo",
    accessor: "tipo",
    width: "104"
  },
  {
    Header: "Valor",
    id: "valor",
    accessor: "valor",
    width: "64"
  },
  {
    Header: "Estatus",
    id: "estatus",
    accessor: "estatus",
    width: "96"
  },
  {
    Header: "Fecha aplicación",
    id: "fechaAplicacion",
    accessor: "fechaAplicacion",
    width: "120"
  },
  {
    Header: "Usuario",
    id: "usuario",
    accessor: "usuario",
    width: "120"
  },
  {
    Header: "Observaciones",
    id: "observaciones",
    accessor: "observaciones",
    width: "512"
  }
];
