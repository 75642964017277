import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button, Form } from "reactstrap";
import s from "./styles.scss";
import BaseInput from "../../../toolkit/baseInput";
import moment from "moment";
import ReactTable from "react-table";
import MexicanCurrency from "../common/currency";
import PagedTable from "../common/PagedTable";
import GetSingleCodeModal from "./getSingleCodeModal";

interface Props {
  discounts: {
    gettingLteListMembrsh: boolean;
    gettingListMembrsh: boolean;
    gettingSingleCode: boolean;
    discountList: object;
    dataLteListMembrsh: object[];
    dataSingleCode: object;
  };
  getCodesList: (loteId, args?) => any;
  getCodesListLtebyMembrsh: (membresiaId: string) => any;
  clearCodes: () => any;
  getSingleCode: (data: object) => any;
  clearSingleCode: () => any;
}

interface State {
  membresiaId: string;
  search: object;
  loteId: string;
  isOpenModal: boolean;
}

class DiscCodeListByMembershipPage extends React.Component<Props, State> {
  state = {
    membresiaId: null,
    search: {},
    loteId: null,
    isOpenModal: false
  };

  onChangeField = async (e, fieldName) => {
    let state = { ...this.state };
    state[fieldName] = e.target.value;
    await this.setState(state);
  };

  getCodesListLte = () => {
    const { membresiaId } = this.state;
    this.props.getCodesListLtebyMembrsh(membresiaId);
  };

  preProcessTableLteData = () => {
    let tableData = [];
    tableData = this.props.discounts.dataLteListMembrsh
      ? this.props.discounts.dataLteListMembrsh
      : null;
    return tableData;
  };

  fetchData = ({ pageSize: size, page }) => {
    if (!this.state.membresiaId) return null;
    this.props.getCodesList(this.state.membresiaId, { size, page });
    this.setState(state => {
      return {
        ...state,
        search: { size, page }
      };
    });
  };

  getCodesList = async (loteId: string) => {
    await this.setState({ ...this.state, loteId });
    this.props.getCodesList(this.state.loteId);
  };

  getSingleCode = async (loteId: string) => {
    await this.setState({
      ...this.state,
      loteId,
      isOpenModal: !this.state.isOpenModal
    });
    const { membresiaId } = this.state;
    this.props.getSingleCode({ membresiaId, loteId });
  };

  toggleModal = async () => {
    await this.setState({
      ...this.state,
      isOpenModal: !this.state.isOpenModal
    });
    if (!this.props.discounts.discountList)
      await this.setState({ ...this.state, loteId: null });
    if (!this.state.isOpenModal) this.getCodesListLte();
  };

  render() {
    const listDiscountsTablecolumnslist = [
      {
        name: "Codigo",
        id: "codigo"
      },
      {
        name: "Creación",
        id: "createdDate",
        Cell: row => (
          <p className="m-0 p-0">{moment(row.value).format("YYYY-MM-DD")}</p>
        )
      },
      {
        name: "Estatus",
        id: "estatusCodigoDescuento"
      },
      {
        name: "Inicio de vigencia",
        id: "fechaInicioVigencia",
        Cell: row => (
          <p className="m-0 p-0">{moment(row.value).format("YYYY-MM-DD")}</p>
        )
      },
      {
        name: "Fin de vigencia",
        id: "fechaFinVigencia",
        Cell: row => (
          <p className="m-0 p-0">{moment(row.value).format("YYYY-MM-DD")}</p>
        )
      },
      {
        name: "Monto de descuento",
        id: "montoDescuento",
        Cell: row => (
          <p className="text-right m-0 p-0">
            <MexicanCurrency quantity={row.value} />
          </p>
        )
      },
      {
        name: "% Descuento",
        id: "porcentajeDescuento",
        Cell: row => <p className="text-right m-0 p-0">{row.value} %</p>
      },
      {
        name: "Tipo Código Descuento",
        id: "tipoCodigoDescuento"
      },
      {
        name: "Tipo Descuento",
        id: "tipoDescuento"
      }
    ];

    const tableLteData: any[] = this.preProcessTableLteData();
    const listDiscountsTablecolumnsLTE = [
      {
        Header: "Lote",
        accessor: "loteId",
        width: "50px"
      },
      {
        Header: "Creación",
        accessor: "fechaLote",
        Cell: row => (
          <p className="m-0 p-0">{moment(row.value).format("YYYY-MM-DD")}</p>
        )
      },
      {
        Header: "Vigencia",
        accessor: "fechaFinVigencia",
        Cell: row => (
          <p className="m-0 p-0">{moment(row.value).format("YYYY-MM-DD")}</p>
        )
      },
      {
        Header: "Disponibles",
        accessor: "cantidad"
      },
      {
        accessor: "loteId",
        Cell: row => {
          return (
            <Button
              onClick={() => {
                this.getSingleCode(row.value);
              }}
              block
              size="sm"
              color="link"
            >
              Obtener código
            </Button>
          );
        }
      },
      {
        accessor: "loteId",
        Cell: row => {
          return (
            <Button
              onClick={() => {
                this.getCodesList(row.value);
              }}
              block
              size="sm"
              color="link"
            >
              Ver Detalle
            </Button>
          );
        }
      }
    ];

    return (
      <Container className="w-100 p-0 mt-3">
        <Row className="mb-1">
          <Col sm="12">Ingresa los campos requeridos para comenzar ( * )</Col>
        </Row>

        <GetSingleCodeModal
          dataSingleCode={this.props.discounts.dataSingleCode}
          gettingSingleCode={this.props.discounts.gettingSingleCode}
          isOpenModal={this.state.isOpenModal}
          toggleModal={this.toggleModal}
        ></GetSingleCodeModal>
        <Form
          onSubmit={e => {
            e.preventDefault();
            this.getCodesListLte();
          }}
        >
          <Row className="my-2">
            <Col sm="4">
              <BaseInput
                label="Número de membresía"
                name="membresiaId"
                type="text"
                id="membresiaId"
                size={"sm"}
                value={this.state.membresiaId}
                onChange={e => this.onChangeField(e, "membresiaId")}
              />
            </Col>

            <Col sm={"4"}>
              <br />
              <Button
                disabled={this.state.membresiaId ? false : true}
                type="submit"
                block
              >
                Buscar membresia
              </Button>
            </Col>
          </Row>
        </Form>

        <hr />
        {tableLteData && (
          <span>
            <h5 className="my-4">Resultados de la búsqueda</h5>
            <Row>
              <Col xs="12">
                <ReactTable
                  pages={1}
                  data={tableLteData}
                  // data={dataTable}
                  columns={listDiscountsTablecolumnsLTE}
                  showPagination={false}
                  sortable={true}
                  multiSort={true}
                  resizable={true}
                  pageSize={this.props.discounts.dataLteListMembrsh.length + 3}
                />
              </Col>
            </Row>
          </span>
        )}

        {this.state.loteId && (
          <span>
            <h5 className="my-4">Detalle de códigos </h5>
            <Row>
              <Col xs="12">
                <PagedTable
                  data={
                    this.props.discounts.discountList
                      ? this.props.discounts.discountList
                      : []
                  }
                  columns={listDiscountsTablecolumnslist}
                  // actions={actions}
                  fetchData={this.fetchData}
                  searching={this.props.discounts.gettingListMembrsh}
                />
              </Col>
            </Row>
          </span>
        )}
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(DiscCodeListByMembershipPage));
