import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Row, Col, Button } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import s from "./styles.scss";
import ReactTable from "react-table";
import MexicanCurrency from "../common/currency";
import TaxesData from "./taxesData";
// import locale from 'antd/es/date-picker/locale/es_ES';

interface Props {
  clubs: { clubId: number; nombre: string }[];
  onChangeItems: (genData: any[], ItemsDataValidated: boolean) => any;
  tipoItemData: { idCampo; label }[];
  billingData: {
    billingCatalogs: any[];
    dataResponse: object;
    gettingCatalogs: boolean;
    sendingData: boolean;
    successSend: boolean;
  };
  disabled: boolean;
  items: object[];
}

interface State {
  fields: {
    tipo: string;
    areaSw: string;
    categoriaSw: string;
    subcategoriaSw: string;
    claveProductoServicioSAT: string;
    claveUnidadSAT: string;
    nombreProducto: string;
    cuentaContableId: string;
    cuentaProductoId: string;
    concepto: string;
    cantidad: string;
    precioUnitario: string;
    descuento: string;
    importe: string;
    impuestos: object[];
  };
  validData: boolean;
}

class ItemsData extends React.Component<Props, State> {
  state = {
    fields: {
      tipo: "",
      areaSw: "",
      categoriaSw: "",
      subcategoriaSw: "",
      claveProductoServicioSAT: "",
      claveUnidadSAT: "",
      nombreProducto: "",
      cuentaContableId: "",
      cuentaProductoId: "",
      concepto: "",
      cantidad: "",
      precioUnitario: "",
      descuento: "",
      importe: "",
      impuestos: []
    },
    validData: false
  };

  onChangeField = async (e, fieldName) => {
    let state = { ...this.state };
    state.fields[fieldName] = e.target.value;
    await this.setState(state);

    if (this.validateFields()) {
      this.setState({ ...this.state, validData: true });
    } else {
      this.setState({ ...this.state, validData: false });
    }
  };

  validateFields = () => {
    const { fields } = this.state;
    for (const key in fields) {
      if (fields.hasOwnProperty(key)) {
        const element = fields[key];
        if (element == "") return false;
      }
    }
    return true;
  };

  getClearFields = () => ({
    tipo: "",
    areaSw: "",
    categoriaSw: "",
    subcategoriaSw: "",
    claveProductoServicioSAT: "",
    claveUnidadSAT: "",
    nombreProducto: "",
    cuentaContableId: "",
    cuentaProductoId: "",
    concepto: "",
    cantidad: "",
    precioUnitario: "",
    descuento: "",
    importe: "",
    impuestos: []
  });

  addItem = async () => {
    const state = { ...this.state };
    const items = [...this.props.items];
    items.push(state.fields);
    this.props.onChangeItems(items, true);

    state.fields = this.getClearFields();
    state.validData = false;
    this.setState(state);
  };

  onChangeImpuestos = (impuestos: any) => {
    let state = this.state;
    state.fields.impuestos = impuestos;
    this.setState(state);
  };

  render() {
    let CatProductoServicio = [];

    for (let catalog of this.props.billingData.billingCatalogs) {
      if (catalog["clave"] == "CatProductoServicio")
        CatProductoServicio = catalog["data"];
    }

    const itemsTablecolumns = [
      {
        Header: "tipo",
        accessor: "tipo"
      },
      {
        Header: "areaSw",
        accessor: "areaSw"
      },
      {
        Header: "categoriaSw",
        accessor: "categoriaSw"
      },
      {
        Header: "subcategoriaSw",
        accessor: "subcategoriaSw"
      },
      {
        Header: "claveProductoServicioSAT",
        accessor: "claveProductoServicioSAT"
      },
      {
        Header: "claveUnidadSAT",
        accessor: "claveUnidadSAT"
      },
      {
        Header: "nombreProducto",
        accessor: "nombreProducto"
      },
      {
        Header: "cuentaContableId",
        accessor: "cuentaContableId"
      },
      {
        Header: "cuentaProductoId",
        accessor: "cuentaProductoId"
      },
      {
        Header: "concepto",
        accessor: "concepto"
      },
      {
        Header: "cantidad",
        accessor: "cantidad",
        Cell: row => <p className="text-right m-0 p-0">{row.value}</p>
      },
      {
        Header: "precioUnitario",
        accessor: "precioUnitario",
        Cell: row => (
          <p className="text-right m-0 p-0">
            <MexicanCurrency quantity={row.value} />
          </p>
        )
      },
      {
        Header: "descuento",
        accessor: "descuento",
        Cell: row => <p className="text-right m-0 p-0">{row.value} %</p>
      },
      {
        Header: "importe",
        accessor: "importe",
        Cell: row => (
          <p className="text-right m-0 p-0">
            <MexicanCurrency quantity={row.value} />
          </p>
        )
      }
    ];

    let dataTable = this.props.items;
    return (
      <span>
        <TaxesData
          {...this.props}
          onChangeImpuestos={this.onChangeImpuestos}
          impuestos={this.state.fields.impuestos}
          disabled={this.props.disabled}
        ></TaxesData>
        <h5 className={`mt-1 ${s.grayTitle}`}>Agregar items</h5>
        <Row>
          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*tipo"}
              name={`tipo`}
              type="select"
              id={`tipo`}
              placeholder={"tipo"}
              options={[
                { value: -1, label: "Seleccionar el Tipo item" }
              ].concat(
                this.props.tipoItemData.map(({ label }) => {
                  return {
                    value: label,
                    label: label
                  };
                })
              )}
              value={this.state.fields.tipo}
              onChange={e => this.onChangeField(e, "tipo")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*areaSw"}
              name={`areaSw`}
              type="text"
              id={`areaSw`}
              placeholder={"Folio"}
              value={this.state.fields.areaSw}
              onChange={e => this.onChangeField(e, "areaSw")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*categoriaSw"}
              name={`categoriaSw`}
              type="text"
              id={`categoriaSw`}
              placeholder={"categoriaSw"}
              value={this.state.fields.categoriaSw}
              onChange={e => this.onChangeField(e, "categoriaSw")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*subcategoriaSw"}
              name={`subcategoriaSw`}
              type="text"
              id={`subcategoriaSw`}
              placeholder={"subcategoriaSw"}
              value={this.state.fields.subcategoriaSw}
              onChange={e => this.onChangeField(e, "subcategoriaSw")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*claveProductoServicioSAT"}
              name={`claveProductoServicioSAT`}
              type="select"
              id={`claveProductoServicioSAT`}
              placeholder={"claveProductoServicioSAT"}
              options={[
                { value: -1, label: "Seleccionar la clave Producto Servicio" }
              ].concat(
                CatProductoServicio.map(({ clave, descripcion }) => {
                  return {
                    value: clave,
                    label: descripcion
                  };
                })
              )}
              value={this.state.fields.claveProductoServicioSAT}
              onChange={e => this.onChangeField(e, "claveProductoServicioSAT")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*claveUnidadSAT"}
              name={`claveUnidadSAT`}
              type="text"
              id={`claveUnidadSAT`}
              placeholder={"claveUnidadSAT"}
              value={this.state.fields.claveUnidadSAT}
              onChange={e => this.onChangeField(e, "claveUnidadSAT")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*nombreProducto"}
              name={`nombreProducto`}
              type="text"
              id={`nombreProducto`}
              placeholder={"nombreProducto"}
              value={this.state.fields.nombreProducto}
              onChange={e => this.onChangeField(e, "nombreProducto")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*cuentaContableId"}
              name={`cuentaContableId`}
              type="text"
              id={`cuentaContableId`}
              placeholder={"cuentaContableId"}
              value={this.state.fields.cuentaContableId}
              onChange={e => this.onChangeField(e, "cuentaContableId")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*cuentaProductoId"}
              name={`cuentaProductoId`}
              type="text"
              id={`cuentaProductoId`}
              placeholder={"cuentaProductoId"}
              value={this.state.fields.cuentaProductoId}
              onChange={e => this.onChangeField(e, "cuentaProductoId")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*concepto"}
              name={`concepto`}
              type="text"
              id={`concepto`}
              placeholder={"concepto"}
              value={this.state.fields.concepto}
              onChange={e => this.onChangeField(e, "concepto")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*cantidad"}
              name={`cantidad`}
              type="number"
              id={`cantidad`}
              placeholder={"cantidad"}
              value={this.state.fields.cantidad}
              onChange={e => this.onChangeField(e, "cantidad")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              disabled={this.props.disabled}
              label={"*precioUnitario"}
              name={`precioUnitario`}
              type="number"
              id={`precioUnitario`}
              placeholder={"precioUnitario"}
              value={this.state.fields.precioUnitario}
              onChange={e => this.onChangeField(e, "precioUnitario")}
            />
          </Col>

          <Col sm="5">
            <BaseInput
              disabled={this.props.disabled}
              label={"*descuento"}
              name={`descuento`}
              type="number"
              id={`descuento`}
              placeholder={"descuento"}
              value={this.state.fields.descuento}
              onChange={e => this.onChangeField(e, "descuento")}
            />
          </Col>

          <Col sm="5">
            <BaseInput
              disabled={this.props.disabled}
              label={"*importe"}
              name={`importe`}
              type="number"
              id={`importe`}
              placeholder={"importe"}
              value={this.state.fields.importe}
              onChange={e => this.onChangeField(e, "importe")}
            />
          </Col>
          <Col sm="2">
            <Button
              disabled={!this.state.validData}
              block
              onClick={() => this.addItem()}
              className="mt-4 mb-1"
              style={{ height: "2rem" }}
            >
              +ITEM
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <ReactTable
              pages={1}
              data={dataTable}
              columns={itemsTablecolumns}
              showPagination={false}
              sortable={true}
              multiSort={true}
              resizable={true}
              pageSize={this.props.items.length + 3}
            />
          </Col>
        </Row>
      </span>
    );
  }
}

export default withStyles(s)(ItemsData);
