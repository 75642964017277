import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import constants from "../../utils/constants";
import axios from "axios";
const baseUrl = `/api`;

export const addLogueoRedux = (objData: object) => {
  return {
    type: actionTypes.ADD_LOGUEO_REDUX,
    payload: objData
  };
};

export const clearLogueoRedux = () => {
  return {
    type: actionTypes.CLEAR_LOGUEO_REDUX,
    payload: {}
  };
};

export const clearReference = () => {
  return {
    type: actionTypes.CLEAR_REFERENCE_REDUX
  };
};

export const addDataLogueo = (): IAction => {
  return {
    type: actionTypes.GET_DATA_LOGUEO,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/maintenances/terminal`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getRefenceSantanderCargo = (
  clubId: string,
  idMovimiento: string,
  formaDePago: string
): IAction => {
  return {
    type: actionTypes.GET_REFERENCE_SANTANDER_CARGO,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/maintenances/referencia/santander/cargo-normal/${clubId}/${idMovimiento}/${formaDePago}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getRefenceSantanderComplemento = (
  clubId: string,
  monto: string,
  formaDePago: string
): IAction => {
  return {
    type: actionTypes.GET_REFERENCE_SANTANDER_COMPLEMENTO,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/maintenances/referencia/santander/complemento-pago/${clubId}/${monto}/${formaDePago}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getRefenceSantanderCargoRequest = (
  clubId: string,
  idMovimiento: string,
  formaDePago: string
) => {
  return axios.get(
    `${constants.BASE_URL}${baseUrl}/maintenances/referencia/santander/cargo-normal/${clubId}/${idMovimiento}/${formaDePago}`,
    {
      withCredentials: true
    }
  );
};

export const getRefenceSantanderComplementoRequest = (
  clubId: string,
  monto: string,
  formaDePago: string
) => {
  return axios.get(
    `${constants.BASE_URL}${baseUrl}/maintenances/referencia/santander/complemento-pago/${clubId}/${monto}/${formaDePago}`,
    {
      withCredentials: true
    }
  );
};

export const getReferencesNotUsed = (clubId: string): IAction => {
  return {
    type: actionTypes.GET_REFERENCE_NOT_USED_SANTANDER,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/maintenances/referencia/santander/referencias/${clubId}`,
        {
          withCredentials: true
        }
      )
    }
  };
};
