import React from "react";
import { connect } from "react-redux";
import * as catalogActions from "../../actions/catalogs";
import * as userActions from "../../actions/users";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import SearchKidz from "../../components/fitkidz/searchKidz";

interface Props {
  getClubs: () => void;
  getKits: (data: object, clubId: string) => any;
  getResponsibles: (menorId: string) => any;
  assignTag: (data: object) => any;
  deallocationTag: (data: object) => any;
  clubs: object[];
  listFitkits: object[];
  listResponsibleFitkits: object[];
  responseAssignTag: object[];
  responseDellocationTag: object[];
  userContext: any;
}

interface State {}

class Fitkidz extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    this.props.getClubs();
  }

  render() {
    return (
      <main>
        <Helmet title="Fitkids" />
        <Layout>
          <SearchKidz
            getKits={this.props.getKits}
            getResponsibles={this.props.getResponsibles}
            assignTag={this.props.assignTag}
            deallocationTag={this.props.deallocationTag}
            listResponsibleFitkits={this.props.listResponsibleFitkits}
            listFitkits={this.props.listFitkits}
            responseAssignTag={this.props.responseAssignTag}
            responseDellocationTag={this.props.responseDellocationTag}
            clubs={this.props.clubs}
            userContext={this.props.userContext}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getClubs: () => {
    dispatch(catalogActions.getClubs());
  },
  getKits: (data: object, clubId: string) => {
    return dispatch(userActions.getKits(data, clubId));
  },
  getResponsibles: (menorId: string) => {
    dispatch(userActions.getResponsibles(menorId));
  },
  assignTag: (data: object) => {
    return dispatch(userActions.assignTag(data));
  },
  deallocationTag: (data: object) => {
    return dispatch(userActions.deallocationTag(data));
  }
});

const mapStateToProps = ({ catalogs, user }) => {
  return {
    clubs: catalogs.clubs,
    listFitkits: user.listFitkits,
    listResponsibleFitkits: user.listResponsibleFitkits,
    responseAssignTag: user.responseAssignTag,
    responseDellocationTag: user.responseDellocationTag,
    userContext: user.userContext
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Fitkidz);
