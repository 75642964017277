import React from "react";
import { connect } from "react-redux";
import Layout from "../../components/layout";
import { Helmet } from "react-helmet";
import DiscountCodeCreatorPage from "../../components/DiscountCodeCreatorPage";
import { getClubs } from "../../actions/catalogs";
import { createCodes, clearCodes } from "../../actions/dicounts";

interface Props {
  clubs: object;
  getClubs: () => any;
  createCodes: (data: object) => any;
  clearCodes: () => any;
  discounts: object;
}

interface State {
  tipoCodigo: string; //"PRODUCTO", // "PRODUCTO"|| "MEMBRESIA",
  idProducto: string; //"idProducto",
  tipoDescuento: string; // "MONTO", // "MONTO"|| "PORCENTAJE",
  inicioVigencia: string; // "xxxx",
  finVigencia: string; // "xxxx",
  clubes: [
    {
      idCLub: number; // 0,
      cantidad: number; //0
    }
  ];
}

class DiscountCodeCreator extends React.Component<Props, State> {
  state = {
    tipoCodigo: "",
    idProducto: "",
    tipoDescuento: "",
    inicioVigencia: "",
    finVigencia: "",
    clubes: null
  };
  componentDidMount() {
    this.props.getClubs();
  }

  render() {
    return (
      <main>
        <Helmet title="Creacion codigos de descuento para clubes" />
        <Layout>
          {this.props.clubs && ( //this.props.sportsProductsTypes && (
            <DiscountCodeCreatorPage
              discounts={this.props.discounts}
              createCodes={this.props.createCodes}
              clearCodes={this.props.clearCodes}
              clubs={this.props.clubs}
            />
          )}
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  clearCodes: () => {
    return dispatch(clearCodes());
  },
  createCodes: data => {
    return dispatch(createCodes(data));
  },
  getClubs: () => {
    return dispatch(getClubs());
  }
});

const mapStateToProps = state => {
  const {
    catalogs: { clubs },
    discounts
  } = state;
  return {
    clubs,
    discounts
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DiscountCodeCreator);
