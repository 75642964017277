import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Row, Col, Input, Label, FormGroup, FormFeedback } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import s from "./styles.scss";
import { ValidateEmail } from "../../../utils/helpers";
import { getBillingCatalogByKeyRequest } from "../../actions/billing";

const USO_CFDI_CATALOG_KEY = "CatUsoCfdi";
const REGIMEN_RECEPTOR_CATALOG_KEY = "CatRegimenFiscalReceptor";

interface Props {
  clubs: { clubId: number; nombre: string }[];
  onChangeClientData: (genData: any, validated: boolean) => any;
  tipoPersona: { idCampo; label }[];
  claveUsoCFDIData: { idCampo; label }[];
  disabled: boolean;
  billingData: {
    billingCatalogs: any[];
    dataResponse: object;
    gettingCatalogs: boolean;
    sendingData: boolean;
    successSend: boolean;
  };
  fields: {
    clienteId: string;
    tipoPersona: string;
    nombre: string;
    segundoNombre: string;
    apellidoPaterno: string;
    apellidoMaterno: string;
    email: string;
    telefono: string;
    claveResidenciaFiscal: string;
    contactoNombre: string;
    contactoApellidoPaterno: string;
    contactoApellidoMaterno: string;
    contactoEmail: string;
    contactoTelefono: string;
    claveUsoCFDI: null;
    regimenFiscal: null;
    rfc: string;
  };
}

interface State {
  mailInvalid: boolean;
  catalogUsiCfdi: any[];
  catalogRegimen: any[];
}

class ClientData extends React.Component<Props, State> {
  state = {
    mailInvalid: null,
    catalogUsiCfdi: [],
    catalogRegimen: []
  };

  componentDidMount() {
    getBillingCatalogByKeyRequest(USO_CFDI_CATALOG_KEY).then(
      ({ data: catalogUsiCfdi }) => {
        this.setState({ catalogUsiCfdi });
      }
    );
    getBillingCatalogByKeyRequest(REGIMEN_RECEPTOR_CATALOG_KEY).then(
      ({ data: catalogRegimen }) => {
        this.setState({ catalogRegimen });
      }
    );
  }

  onChangeField = (e, fieldName) => {
    const state = { ...this.state };
    this.setState(state);

    const fields = { ...this.props.fields };
    fields[fieldName] = e.target.value;

    if (this.validateFields()) {
      this.props.onChangeClientData(fields, true);
    } else {
      this.props.onChangeClientData(fields, false);
    }
  };

  onBlurMail = e => {
    if (!ValidateEmail(e.target.value))
      this.setState({ ...this.state, mailInvalid: true });
    else this.setState({ ...this.state, mailInvalid: false });
  };

  validateFields = () => {
    const { fields } = this.props;
    for (const key in fields) {
      if (fields.hasOwnProperty(key)) {
        const element = fields[key];
        // if (key == "MORAL")
        //   if (element.length < 5)
        //     return false;
        if (key == "rfc") if (!element || element.length < 5) return false;
        if (element == "") return false;
      }
    }
    return true;
  };

  render() {
    let CatResidenciaFiscal = [];
    let CatUsoCfdi = [];
    for (let catalog of this.props.billingData.billingCatalogs) {
      if (catalog["clave"] == "CatResidenciaFiscal")
        CatResidenciaFiscal = catalog["data"];
      else if (catalog["clave"] == "CatUsoCfdi") CatUsoCfdi = catalog["data"];
    }

    return (
      <span>
        <Row>
          <Col sm="3">
            <BaseInput
              label={"*RFC"}
              name={`RFC`}
              type="text"
              id={`RFC`}
              placeholder={"RFC"}
              value={this.props.fields.rfc}
              onChange={e => this.onChangeField(e, "rfc")}
              disabled={this.props.disabled}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              label={"*Id de cliente"}
              name={`clienteId`}
              type="text"
              id={`clienteId`}
              placeholder={"Id de cliente"}
              value={this.props.fields.clienteId}
              onChange={e => this.onChangeField(e, "clienteId")}
              disabled={this.props.disabled}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              label="* Tipo Persona"
              name="tipoPersona"
              type="select"
              id="tipoPersona"
              size={"sm"}
              disabled={this.props.disabled}
              value={this.props.fields.tipoPersona}
              options={[
                { value: -1, label: "Seleccionar el Tipo Persona" }
              ].concat(
                this.props.tipoPersona.map(({ label }) => {
                  return {
                    value: label,
                    label: label
                  };
                })
              )}
              onChange={e => this.onChangeField(e, "tipoPersona")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              label={"*Clave Residencia Fiscal"}
              name={`claveResidenciaFiscal`}
              type="select"
              id={`claveResidenciaFiscal`}
              disabled={this.props.disabled}
              placeholder={"Residencia Fiscal"}
              options={[
                { value: -1, label: "Seleccionar el residencia fiscal" }
              ].concat(
                CatResidenciaFiscal.map(({ clave, descripcion }) => {
                  return {
                    value: clave,
                    label: descripcion
                  };
                })
              )}
              value={this.props.fields.claveResidenciaFiscal}
              onChange={e => this.onChangeField(e, "claveResidenciaFiscal")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              type="select"
              label="Régimen fiscal del receptor"
              name="regimenFiscalReceptor"
              id="regimenFiscalReceptor"
              size={"sm"}
              value={this.props.fields.regimenFiscal}
              options={[
                {
                  value: "",
                  label: "Seleccione"
                }
              ].concat(
                (this.state.catalogRegimen || [])
                  .filter(r =>
                    this.props.fields.tipoPersona == "MORAL"
                      ? r.personaMoral
                      : r.personaFisica
                  )
                  .sort((a, b) => a.descripcion.localeCompare(b.descripcion))
                  .map(({ clave, descripcion }) => {
                    return {
                      value: clave,
                      label: `${clave} - ${descripcion}`
                    };
                  })
              )}
              onChange={e => this.onChangeField(e, "regimenFiscal")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              type="select"
              label="Clave uso CFDI"
              name="claveUsoCFDI"
              id="claveUsoCFDI"
              size={"sm"}
              value={this.props.fields.claveUsoCFDI}
              options={(this.state.catalogUsiCfdi || [])
                .sort((a, b) => a.descripcion.localeCompare(b.descripcion))
                .map(({ clave, descripcion }) => {
                  return {
                    value: clave,
                    label: `${clave} - ${descripcion}`
                  };
                })}
              onChange={e => this.onChangeField(e, "claveUsoCFDI")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              label={"*Nombre"}
              name={`nombre`}
              type="text"
              id={`nombre`}
              disabled={this.props.disabled}
              placeholder={"Nombre"}
              value={this.props.fields.nombre}
              onChange={e => this.onChangeField(e, "nombre")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              label={"*Segundo Nombre"}
              name={`segundoNombre`}
              type="text"
              id={`segundoNombre`}
              disabled={this.props.disabled}
              placeholder={"Segundo Nombre"}
              value={this.props.fields.segundoNombre}
              onChange={e => this.onChangeField(e, "segundoNombre")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              label={"*Apellido Paterno"}
              name={`apellidoPaterno`}
              type="text"
              id={`apellidoPaterno`}
              disabled={this.props.disabled}
              placeholder={"Apellido Paterno"}
              value={this.props.fields.apellidoPaterno}
              onChange={e => this.onChangeField(e, "apellidoPaterno")}
            />
          </Col>

          <Col sm="3">
            <BaseInput
              label={"*Apellido Materno"}
              name={`apellidoMaterno`}
              type="text"
              disabled={this.props.disabled}
              id={`Apellido Materno`}
              placeholder={"Apellido Materno"}
              value={this.props.fields.apellidoMaterno}
              onChange={e => this.onChangeField(e, "apellidoMaterno")}
            />
          </Col>

          <Col sm="3">
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                name={`mail`}
                type="email"
                id={`email`}
                placeholder={"Email"}
                disabled={this.props.disabled}
                value={this.props.fields.email}
                onChange={e => this.onChangeField(e, "email")}
                onBlur={e => this.onBlurMail(e)}
                invalid={this.state.mailInvalid}
                className={s.input}
              />
              <FormFeedback invalid>Correo no válido</FormFeedback>
            </FormGroup>
          </Col>

          <Col sm="3">
            <BaseInput
              label={"* Telefono "}
              name={`telefono`}
              type="number"
              disabled={this.props.disabled}
              id={`telefono`}
              placeholder={"telefono"}
              value={this.props.fields.telefono}
              onChange={e => this.onChangeField(e, "telefono")}
            />
          </Col>
        </Row>

        <p className={s.grayTitle}>Datos de contacto (solo Personas Morales)</p>
        <Row>
          <Col sm="6">
            <BaseInput
              label={"*Nombre Contacto"}
              name={`contactoNombre`}
              type="text"
              disabled={this.props.disabled}
              id={`contactoNombre`}
              placeholder={"contactoNombre"}
              value={this.props.fields.contactoNombre}
              onChange={e => this.onChangeField(e, "contactoNombre")}
            />
          </Col>

          <Col sm="6">
            <BaseInput
              label={"* Apellido Materno Contacto"}
              name={`contactoApellidoMaterno`}
              type="text"
              disabled={this.props.disabled}
              id={`contactoApellidoMaterno`}
              placeholder={"contactoApellidoMaterno"}
              value={this.props.fields.contactoApellidoMaterno}
              onChange={e => this.onChangeField(e, "contactoApellidoMaterno")}
            />
          </Col>

          <Col sm="6">
            <BaseInput
              label={"* Apellido Paterno Contacto"}
              name={`contactoApellidoPaterno`}
              type="text"
              id={`contactoApellidoPaterno`}
              disabled={this.props.disabled}
              placeholder={"contactoApellidoPaterno"}
              value={this.props.fields.contactoApellidoPaterno}
              onChange={e => this.onChangeField(e, "contactoApellidoPaterno")}
            />
          </Col>

          <Col sm="3">
            <FormGroup>
              <Label for="Email contacto">Email Contacto</Label>
              <Input
                name={`contactoEmail`}
                type="mail"
                disabled={this.props.disabled}
                id={`contactoEmail`}
                placeholder={"Email Contacto"}
                value={this.props.fields.contactoEmail}
                onChange={e => this.onChangeField(e, "contactoEmail")}
                onBlur={e => this.onBlurMail(e)}
                invalid={this.state.mailInvalid}
                className={s.input}
              />
              <FormFeedback invalid>Correo no válido</FormFeedback>
            </FormGroup>
          </Col>

          <Col sm="3">
            <BaseInput
              label={"*Telefono contacto"}
              name={`contactoTelefono`}
              type="number"
              disabled={this.props.disabled}
              id={`contactoTelefono`}
              placeholder={"contactoTelefono"}
              value={this.props.fields.contactoTelefono}
              onChange={e => this.onChangeField(e, "contactoTelefono")}
            />
          </Col>
        </Row>
      </span>
    );
  }
}

export default withStyles(s)(ClientData);
