import { TipoBoton } from "../types";
import React from "react";
import s from "./styles.scss";
import { UncontrolledTooltip } from "reactstrap";
import { Styled } from "../../../../toolkit/decorators/Artifact";

interface Props {
  tipoBoton: TipoBoton;
  link?: string;
  action?: () => any;
  wait?: boolean;
  completo: boolean;
}

// Identificadores dependiendo de los componentes creados
let idBoton = 1;

@Styled(s)
export default class DocBotonLg extends React.Component<Props, {}> {
  idLink: string;
  idDiv: string;

  constructor(props) {
    super(props);

    idBoton += 1;
    this.idLink = `exp-btn-link-${idBoton}`;
    this.idDiv = `exp-btn-div-${idBoton}`;
  }

  render() {
    const wait = this.props.wait ? ` ${s.wait}` : "";
    const disabled =
      !this.props.action && !this.props.link ? ` ${s.disabled}` : "";

    return (
      <div id={this.idDiv} className={`${s.boton}${wait}${disabled}`}>
        {this.renderLink()}
        {this.renderTooltip()}
      </div>
    );
  }

  renderLink() {
    const className = `${s.link} ${this.renderImageClass()}`;

    if (this.props.wait) {
      return <a id={this.idLink} className={`${className} ${s.wait}`}></a>;
    } else if (this.props.link) {
      return (
        <a
          id={this.idLink}
          href={this.props.link}
          className={className}
          onClick={() => {
            setTimeout(() => {
              this.props.action && this.props.action();
            }, 2000);

            return true;
          }}
          target={this.props.link.startsWith("sworld") ? null : "_blank"}
        ></a>
      );
    } else if (this.props.action) {
      return (
        <a
          id={this.idLink}
          onClick={this.props.action}
          className={className}
        ></a>
      );
    } else {
      return <a id={this.idLink} className={`${s.link} ${s.disabled}`}></a>;
    }
  }

  renderTooltip() {
    return this.renderTooltipString() ? (
      <UncontrolledTooltip target={this.idLink} placement="bottom">
        {this.renderTooltipString()}
      </UncontrolledTooltip>
    ) : null;
  }

  renderImageClass() {
    const completed = this.props.completo ? ` ${s.completo}` : "";

    switch (this.props.tipoBoton) {
      case TipoBoton.CORREO:
        return `${s.correo}${completed}`;
      case TipoBoton.FIRMAR:
        return `${s.firmar}${completed}`;
      case TipoBoton.ESCANEAR:
        return `${s.escanear}${completed}`;
      case TipoBoton.VER:
        return `${s.ver}${completed}`;
      case TipoBoton.REGENERAR:
        return `${s.regenerar}${completed}`;
      case TipoBoton.AUDITORIA:
        return `${s.auditoria}${completed}`;
      case TipoBoton.CINCEL:
        return `${s.cincel}${completed}`;
    }
  }

  renderTooltipString() {
    if (this.props.wait || (!this.props.action && !this.props.link)) {
      return "";
    }

    const suffixCompleto = this.props.completo ? " otra vez" : "";
    const suffixRegenerar = this.props.completo ? " aunque esté completo" : "";
    const suffixIncompleto = !this.props.completo ? " sin firmar" : "";

    switch (this.props.tipoBoton) {
      case TipoBoton.CORREO:
        return `Enviar${suffixIncompleto}`;
      case TipoBoton.FIRMAR:
        return `Firmar${suffixCompleto}`;
      case TipoBoton.ESCANEAR:
        return `Escanear${suffixCompleto}`;
      case TipoBoton.VER:
        return `Ver`;
      case TipoBoton.REGENERAR:
        return `Regenerar${suffixRegenerar}`;
      case TipoBoton.AUDITORIA:
        return `Doc. de Auditoría`;
      case TipoBoton.CINCEL:
        return `Buscar firmas en Cincel`;
    }
  }
}
