import React from "react";
import { Label, FormGroup } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import IInput from "../iInput/IInput";
import ITypeSelectProps from "./ITypeSelect.props";
import ITypeSelectState from "./ITypeSelect.state";
import { Styled } from "../../decorators/Artifact";
import s from "./styles.scss";

@Styled(s)
export default class ITypeSelect extends IInput<
  ITypeSelectProps,
  ITypeSelectState
> {
  state = {
    value: "",
    options: [],
    selected: null
  };

  componentDidMount(): void {
    if (this.props.init) {
      this.setState(
        {
          value: this.props.init
        },
        () => super.triggerInput()
      );
    } else {
      super.triggerInput();
    }

    this.mapOptions();
  }

  componentDidUpdate(prevProps): void {
    if (this.props.init != prevProps.init) {
      const selected = this.state.options.find(o => o.value == this.props.init);
      this.setState({
        value: this.props.init,
        selected: selected ? [selected] : null
      });
    }

    if (this.props.data != prevProps.data) {
      this.mapOptions();
    }
  }

  onChange = selected => {
    const value = selected && selected.length ? selected[0].value : "";
    this.props.setter && this.props.setter(this.props.name, value);
    this.props.onChange && this.props.onChange(value);
    this.setState({ value, selected });
  };

  mapOptions() {
    const data = (this.props.data && this.props.data) || [];
    const options = this.props.mapOption
      ? data.map(this.props.mapOption)
      : data;
    const selected = options.find(o => o.value == this.state.value);
    this.setState({ options, selected: selected ? [selected] : null });
  }

  render() {
    if (this.props.hidden) {
      return null;
    }

    const id = `${this.props.formKey || ""}-${this.props.name}`;
    return (
      <div className={this.btsClassName()}>
        <FormGroup className={s.formGroup}>
          <Label hidden={!this.props.label} className={s.label} for={id}>
            {this.props.label}:
          </Label>
          <div className={`${s.inputGroup}`}>
            <Typeahead
              id={id}
              promptText={this.props.promptText || this.props.label || ""}
              emptyLabel={this.props.emptyLabel || "Sin coincidencias"}
              selected={this.state.selected}
              bsSize={"sm"}
              onChange={this.onChange}
              disabled={this.props.disabled || this.props.isLoading}
              maxResults={this.props.maxResults || 20}
              options={this.state.options}
              className={`${s.errClass} ${s.swinput}`}
            />
            {this.props.isLoading && (
              <div className={`${s.prepend}`}>
                <FontAwesomeIcon
                  icon={faSpinner}
                  pulse
                  style={{ fontSize: "18px", color: "#777" }}
                />
              </div>
            )}
          </div>
          {!!this.props.message && (
            <span className={`${s.errorSpan}`}>{this.props.message}</span>
          )}
        </FormGroup>
      </div>
    );
  }
}
