import React from "react";
import { connect } from "react-redux";
import * as productActions from "../../actions/products";
import { getSatProducts, getSatUnits } from "../../actions/catalogs";
import BasicDataProductForm from "../../components/basicDataProductForm";
import IAction from "../../../types/IAction";
import isNil from "lodash.isnil";
import { getConveniosList } from "../../actions/convenios";

interface Props {
  getProductsCategories: () => any;
  getBIIndicators: () => any;
  getSatProducts: () => any;
  getSatUnits: () => any;
  productsCategories: object[];
  indicators: object[];
  saveBasicProduct: (tipoProducto: string, product: object) => any;
  updateBasicProduct: (
    tipoProducto: string,
    productId: string,
    product: object
  ) => any;
  successfulSave: boolean;
  getConcepts: () => any;
  getStatus: () => any;
  getAccountingAccounts: () => any;
  conceptos: Catalog[];
  estatus: Catalog[];
  cuentasContables: Catalog[];
  getBasicProduct: (tipoProducto: string, id: string) => any;
  product: object;
  onClickSave: (f: any) => any;
  onSuccessfulSave: () => any;
  resetSuccessfulSave: () => any;
  satProducts: object[];
  satUnits: object[];
  loadingProduct: boolean;
  getSubCategoriesByCategory: (idCategory: string) => IAction;
  listSubcategories: object[];
  getConveniosList: () => IAction;
  conveniosList: any[];
}

interface Catalog {
  id: string;
  nombre: string;
}

interface State {
  stopFather: boolean;
}

class BasicDataProductContainer extends React.Component<Props, State> {
  state = {
    stopFather: false
  };

  componentDidMount() {
    if (!this.props.productsCategories) this.props.getProductsCategories();
    if (!this.props.indicators) this.props.getBIIndicators();
    if (!this.props.satUnits) this.props.getSatProducts();
    if (!this.props.satProducts) this.props.getSatUnits();
    if (this.props.conveniosList.length === 0) this.props.getConveniosList();
  }

  componentDidUpdate() {
    if (
      !this.state.stopFather &&
      this.props &&
      this.props.product &&
      this.props.product.categoriaId
    ) {
      this.props.getSubCategoriesByCategory(this.props.product.categoriaId);
      this.setState({ stopFather: true });
    }
  }

  render() {
    return (
      <div className="p-0 m-0 w-100 h-100">
        {this.props.productsCategories &&
          this.props.indicators &&
          this.props.satUnits &&
          this.props.satProducts && (
            // this.props.listSubcategories &&
            <BasicDataProductForm
              productsCategories={this.props.productsCategories}
              saveBasicProduct={this.props.saveBasicProduct}
              successfulSave={this.props.successfulSave}
              indicators={this.props.indicators}
              product={this.props.product}
              updateBasicProduct={this.props.updateBasicProduct}
              onClickSave={this.props.onClickSave}
              onSuccessfulSave={this.props.onSuccessfulSave}
              resetSuccessfulSave={this.props.resetSuccessfulSave}
              getBasicProduct={this.props.getBasicProduct}
              satProducts={this.props.satProducts}
              satUnits={this.props.satUnits}
              loadingProduct={this.props.loadingProduct}
              listSubcategories={
                isNil(this.props.listSubcategories)
                  ? []
                  : this.props.listSubcategories
              }
              getSubCategoriesByCategory={this.props.getSubCategoriesByCategory}
              conveniosList={this.props.conveniosList}
            />
          )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getProductsCategories: () => {
    dispatch(productActions.getProductsCategories());
  },
  getBIIndicators: () => {
    dispatch(productActions.getBIIndicators());
  },
  saveBasicProduct: (tipoProducto: string, product: object) => {
    return dispatch(
      productActions.saveBasicMaintenances(tipoProducto, product)
    );
  },
  getBasicProduct: (tipoProducto: string, id: string) => {
    return dispatch(productActions.getBasicData(tipoProducto, id));
  },
  updateBasicProduct: (
    tipoProducto: string,
    productId: string,
    product: object
  ) => {
    return dispatch(
      productActions.updateBasicMaintenances(tipoProducto, productId, product)
    );
  },
  getSatProducts: () => {
    dispatch(getSatProducts());
  },
  getSatUnits: () => {
    dispatch(getSatUnits());
  },
  getSubCategoriesByCategory: (idCategory: string) => {
    return dispatch(productActions.getSubCategoriesByCategory(idCategory));
  },
  getConveniosList: () => {
    return dispatch(getConveniosList());
  }
});

const mapStateToProps = state => {
  const { products, catalogs, convenios } = state;
  return {
    productsCategories: products.categories,
    successfulSave: products.basicDataSaved,
    indicators:
      products.indicators && products.indicators.content
        ? products.indicators.content
        : null,
    product: products.basicProduct,
    satProducts: catalogs.satProducts,
    satUnits: catalogs.satUnits,
    loadingProduct: products.gettingBasicProduct,
    listSubcategories: products.listSubcategories,
    conveniosList: convenios.conveniosList
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasicDataProductContainer);
