import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Container, Col, Button } from "reactstrap";
import MexicanCurrency from "../../common/currency";

interface Props {
  item: any;
  buy: (item: object) => any;
}

class AnnuityQuoteItem extends React.Component<Props> {
  state = {};

  render() {
    const item = { ...(this.props.item || {}) };
    return (
      <Col>
        <Container>
          <p className="text-center" style={{ fontWeight: "bold" }}>
            {item.titulo}
          </p>
          <p className={`${s.primaryTitleNoBg} w-100 text-right`}>
            {item.mesesPorPagar}
          </p>
          <p className={`${s.primaryTitleNoBg} w-100 text-right`}>
            {item.mesesAcceso}
          </p>
          <p className={`${s.primaryTitleNoBg} w-100 text-right`}>
            {item.mesInicialPago}
          </p>
          <p className={`${s.primaryTitleNoBg} w-100 text-right`}>
            {item.mesFinalPago}
          </p>

          <p className="w-100 text-right">
            <MexicanCurrency quantity={item.cuotaMensualCat} />
          </p>
          <p className="w-100 text-right">
            <MexicanCurrency quantity={item.cuotaAnualidad} />
          </p>
          <p className="w-100 text-right">
            <MexicanCurrency quantity={item.total} />
          </p>
          <p className="w-100 text-right mt-3 mb-3">
            <Button
              className={`${s.primaryButton}`}
              onClick={() => {
                this.props.buy(item);
              }}
              size="sm"
            >
              {"Comprar"}
            </Button>
          </p>
        </Container>
      </Col>
    );
  }
}
export default withRouter(withStyles(s)(AnnuityQuoteItem));
