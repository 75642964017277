import React from "react";
import { connect } from "react-redux";
import * as userActions from "../../actions/users";
import * as catalogActions from "../../actions/catalogs";
import BasicDataUserForm from "../../components/basicDataUserForm";
import { withRouter } from "react-router-dom";
import { getPositions } from "../../actions/catalogs";

interface Props {
  match: any;
  saveBasicUser: (user: object) => any;
  updateBasicUser: (personaId: string, user: object) => any;
  successfulSave: boolean;
  getBasicUser: (id: string) => any;
  user: object;
  onClickSave: (f: any) => any;
  onSuccessfulSave: () => any;
  domains: object[];
  getDomains: () => any;
  gettingUserById: boolean;
  clearBasicUser: () => any;

  // Catálogo de puestos de trabajo
  getPositions: () => any;
  positions: any;
}

class BasicDataUserContainer extends React.Component<Props, void> {
  componentDidMount() {
    this.props.getDomains();
    this.props.getPositions();
    if (!this.props.match.params.personaId) {
      this.props.clearBasicUser();
    }
  }

  render() {
    return (
      <BasicDataUserForm
        saveBasicUser={this.props.saveBasicUser}
        user={this.props.user}
        updateBasicUser={this.props.updateBasicUser}
        onClickSave={this.props.onClickSave}
        getBasicUser={this.props.getBasicUser}
        domains={this.props.domains}
        gettingUserById={this.props.gettingUserById}
        positions={this.props.positions}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  saveBasicUser: (user: object) => {
    return dispatch(userActions.saveBasicUser(user));
  },
  getBasicUser: (id: string) => {
    dispatch(userActions.getUserById(id));
  },
  updateBasicUser: (personaId: string, user: object) => {
    dispatch(userActions.updateBasicUser(personaId, user));
  },
  getDomains: () => {
    dispatch(catalogActions.getDomains());
  },
  clearBasicUser: () => {
    dispatch(userActions.clearBasicUser());
  },
  getPositions: () => {
    dispatch(getPositions());
  }
});

const mapStateToProps = state => {
  const { user, catalogs } = state;
  return {
    user: user.userById,
    gettingUserById: user.gettingBasicUser,
    domains: catalogs.domains ? catalogs.domains : [],
    positions: catalogs.catalogPostions
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(BasicDataUserContainer)
);
