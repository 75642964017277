import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import { ReduxConnect } from "../../../toolkit/decorators/Artifact";
import {
  InvitadosDispatcher,
  invitadosDispatcher
} from "./Invitados.dispatcher";
import { InvitadosStore, invitadosStore } from "./Invitados.store";
import InvitadosComponent from "../../components/invitados";

interface Props extends InvitadosStore, InvitadosDispatcher {}

interface State {}

@ReduxConnect(invitadosStore, invitadosDispatcher)
export default class InvitadosContainer extends React.Component<Props, State> {
  render() {
    return (
      <main>
        <Layout>
          <Helmet title="Invitados especiales" />
          <InvitadosComponent {...this.props} />
        </Layout>
      </main>
    );
  }
}
