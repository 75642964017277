import { RouteComponentProps, withRouter } from "react-router-dom";
import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import RecoveryRequest from "../../components/recoveryRequest";
import * as sessionActions from "../../actions/session";

// TODO: REDUX

interface Props extends RouteComponentProps {
  logoutUser: () => any;
  restartApp: () => any;
  user: any;
}
interface State {}

class RecoveryRequestPage extends React.Component<Props, State> {
  render() {
    return (
      <main>
        <Helmet title="Cambio de contraseña" />
        <Layout>
          <RecoveryRequest
            logoutUser={this.props.logoutUser}
            restartApp={this.props.restartApp}
            user={this.props.user}
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.userContext
});

const mapDispatchToProps = dispatch => ({
  logoutUser: () => {
    return dispatch(sessionActions.logoutUser());
  },
  restartApp: () => {
    dispatch(sessionActions.restartApp());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles()(RecoveryRequestPage)));
