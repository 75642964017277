exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".IDHwl{background:#f3d8d8}._1Vmt9{padding:0!important}._2EWPV{padding:7px 3px}._2yOoe div{overflow-x:hidden!important}._2yOoe div div div div{overflow:hidden!important}", ""]);

// exports
exports.locals = {
	"inactivo": "IDHwl",
	"tdClear": "_1Vmt9",
	"field": "_2EWPV",
	"hidden": "_2yOoe"
};