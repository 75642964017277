import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import { handleRequestError } from "../../../utils/helpers";
import {
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import {
  getReportAccrualProcesses,
  getReportMonthlyAccrualRequest,
  getReportItemsToAccrualBySaleDate
} from "../../actions/reports";
import {
  ACCRUAL_PROCESSES_REPORT_TYPE,
  catalogoMonth,
  getReportProductTypesCatalog,
  getReportTypesCatalog,
  getValidaDateFromState,
  getYearCatalog,
  Props,
  State,
  TO_ACCRUAL_REPORT_TYPE
} from "./interfaces";
import s from "./styles.scss";
import TableReportAccrualProcesses from "./tableReportAccrualProcesses";
import TableReportMonthlyAccrual from "./tableReportMonthlyAccrual";
import TableReportToAccrualBySaleDate from "./tableReportToAccrualBySaleDate";

class ReportMonthlyAccrual extends React.Component<Props, State> {
  state = {
    filters: {
      clubVentaId: null,
      clubDispersionId: null,
      mes: null,
      anio: null,
      tipo: null,
      tipoProducto: null
    },
    onSearch: false,
    data: []
  };

  //CONTROL BEHAVIOR

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  onChangeReportTypeFilter = () => {
    return event => {
      const value = event.target.value;

      const isProcessesReport = value == ACCRUAL_PROCESSES_REPORT_TYPE;
      const isToAccrualReport = value == TO_ACCRUAL_REPORT_TYPE;

      const { filters } = this.state;
      filters.tipo = value;

      if (isProcessesReport) {
        filters.clubDispersionId = null;
        filters.tipoProducto = null;
      }

      if (isToAccrualReport) {
        filters.clubVentaId = null;
        filters.clubDispersionId = null;
        filters.tipoProducto = null;
      }

      this.setState({ filters, data: [] });
    };
  };

  search = () => {
    const {
      clubVentaId,
      clubDispersionId,
      mes,
      anio,
      tipo,
      tipoProducto
    } = this.state.filters;

    if (
      !mes ||
      !anio ||
      !tipo ||
      (tipo != ACCRUAL_PROCESSES_REPORT_TYPE &&
        tipo != TO_ACCRUAL_REPORT_TYPE &&
        !tipoProducto)
    ) {
      return;
    }

    this.setState({ onSearch: true, data: [] });

    const { fechaInicio, fechaFin } = getValidaDateFromState(mes, anio);

    infoNotification("Consultando datos...");

    if (tipo == TO_ACCRUAL_REPORT_TYPE) {
      getReportItemsToAccrualBySaleDate(fechaFin)
        .then(({ data }) => {
          this.setState({ data, onSearch: false });
          successNotification("Datos consultados correctamente...");
        })
        .catch(handleRequestError);
    } else if (tipo == ACCRUAL_PROCESSES_REPORT_TYPE) {
      getReportAccrualProcesses(clubVentaId, fechaInicio)
        .then(({ data }) => {
          this.setState({ data, onSearch: false });
          successNotification("Datos consultados correctamente...");
        })
        .catch(handleRequestError);
    } else {
      getReportMonthlyAccrualRequest(
        clubVentaId,
        clubDispersionId,
        fechaInicio,
        fechaFin,
        tipo,
        tipoProducto
      )
        .then(({ data }) => {
          this.setState({ data, onSearch: false });
          successNotification("Datos consultados correctamente...");
        })
        .catch(handleRequestError);
    }
  };

  isAccrualProcessesReport = () => {
    const { tipo } = this.state.filters;
    return !!tipo && tipo == ACCRUAL_PROCESSES_REPORT_TYPE;
  };

  isToAccrualBySaleDateReport = () => {
    const { tipo } = this.state.filters;
    return !!tipo && tipo == TO_ACCRUAL_REPORT_TYPE;
  };

  downloadReportMonthlyAccrual = () => {
    const {
      clubVentaId,
      clubDispersionId,
      mes,
      anio,
      tipo,
      tipoProducto
    } = this.state.filters;
    if (!mes || !anio || !tipo || !tipoProducto) {
      return;
    }
    const { fechaInicio, fechaFin } = getValidaDateFromState(mes, anio);
    this.props.downloadReportMonthlyAccrual(
      clubVentaId,
      clubDispersionId,
      fechaInicio,
      fechaFin,
      tipo,
      tipoProducto
    );
  };

  downloadReportAccrualProcessLinesSent = processId => {
    this.props.downloadReportAccrualProcessLinesSent(processId);
  };

  downloadReportItemsToAccrualBySaleDate = () => {
    const { mes, anio, tipo } = this.state.filters;
    if (!mes || !anio || !tipo) {
      return;
    }
    const { fechaFin } = getValidaDateFromState(mes, anio);
    this.props.downloadReportItemsToAccrualBySaleDate(fechaFin);
  };

  selectProcess = processId => {
    const { data } = this.state;
    (data || []).forEach(process => {
      process.selected = process.id == processId;
    });
    this.setState({ data });
  };

  //RENDER
  render() {
    const {
      clubVentaId,
      clubDispersionId,
      mes,
      anio,
      tipo,
      tipoProducto
    } = this.state.filters;
    const { clubs } = this.props;

    return (
      <Container className="p-0">
        <Row>
          <Col>
            <Row>
              <Col xs={12} md={4} lg={4}>
                <BaseInput
                  label="Tipo"
                  name="reportType"
                  id="reportType"
                  type="select"
                  options={getReportTypesCatalog()}
                  value={tipo}
                  onChange={this.onChangeReportTypeFilter()}
                  errors={[]}
                />
              </Col>

              {!this.isToAccrualBySaleDateReport() && (
                <Col xs={12} md={4} lg={4}>
                  <BaseInput
                    label="Club venta"
                    name="reportClubVenta"
                    id="reportClubVenta"
                    type="select"
                    placeholder="Seleccionar club"
                    value={clubVentaId}
                    onChange={this.onChangeFilter("clubVentaId")}
                    options={[{ value: "", label: "Seleccionar club" }].concat(
                      clubs
                        ? clubs.map(option => ({
                            value: option.clubId,
                            label: option.nombre
                          }))
                        : []
                    )}
                  />
                </Col>
              )}

              {!this.isAccrualProcessesReport() &&
                !this.isToAccrualBySaleDateReport() && (
                  <Col xs={12} md={4} lg={4}>
                    <BaseInput
                      label="Club dispersión"
                      name="reportClubDispersion"
                      id="reportClubDispersion"
                      type="select"
                      placeholder="Seleccionar club"
                      value={clubDispersionId}
                      onChange={this.onChangeFilter("clubDispersionId")}
                      options={[
                        { value: "", label: "Seleccionar club" }
                      ].concat(
                        clubs
                          ? clubs.map(option => ({
                              value: option.clubId,
                              label: option.nombre
                            }))
                          : []
                      )}
                    />
                  </Col>
                )}

              {!this.isAccrualProcessesReport() &&
                !this.isToAccrualBySaleDateReport() && (
                  <Col xs={12} md={4} lg={4}>
                    <BaseInput
                      label="Tipo producto"
                      name="reportProductType"
                      id="reportProductType"
                      type="select"
                      options={getReportProductTypesCatalog()}
                      value={tipoProducto}
                      onChange={this.onChangeFilter("tipoProducto")}
                      errors={[]}
                    />
                  </Col>
                )}

              <Col xs={12} md={4} lg={4}>
                <BaseInput
                  label="Mes"
                  name="reportMonth"
                  id="reportMonth"
                  type="select"
                  options={catalogoMonth}
                  placeholder="Mes"
                  value={mes}
                  onChange={this.onChangeFilter("mes")}
                  errors={[]}
                />
              </Col>
              <Col xs={12} md={4} lg={4}>
                <BaseInput
                  label="Año"
                  name="reportYear"
                  id="reportYear"
                  type="select"
                  options={getYearCatalog()}
                  placeholder="Año"
                  value={anio}
                  onChange={this.onChangeFilter("anio")}
                  errors={[]}
                />
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={2} lg={2} className="mt-4">
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.search}
              disabled={
                !mes ||
                !anio ||
                !tipo ||
                (tipo != ACCRUAL_PROCESSES_REPORT_TYPE &&
                  tipo != TO_ACCRUAL_REPORT_TYPE &&
                  !tipoProducto)
              }
            >
              Buscar
            </Button>
          </Col>
        </Row>

        {!this.isAccrualProcessesReport() &&
          !this.isToAccrualBySaleDateReport() && (
            <TableReportMonthlyAccrual
              downloadReportMonthlyAccrual={this.downloadReportMonthlyAccrual}
              onSearch={this.state.onSearch}
              data={this.state.data}
            />
          )}

        {!!this.isToAccrualBySaleDateReport() && (
          <TableReportToAccrualBySaleDate
            downloadReport={this.downloadReportItemsToAccrualBySaleDate}
            onSearch={this.state.onSearch}
            data={this.state.data}
          />
        )}

        {!!this.isAccrualProcessesReport() && (
          <TableReportAccrualProcesses
            downloadReportAccrualProcessLinesSent={
              this.downloadReportAccrualProcessLinesSent
            }
            selectProcess={this.selectProcess}
            onSearch={this.state.onSearch}
            data={this.state.data}
          />
        )}
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ReportMonthlyAccrual));
