import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import BaseInput from "../../../toolkit/baseInput";
import { FormGroup, Label, Input, Alert, Button } from "reactstrap";
import ModalFrame from "../../../toolkit/modalFrame";
import s from "./styles.scss";
import Reform from "@franleplant/reform";
import { FormErrors } from "@franleplant/reform/types";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  history: any;
  editMonthsWithoutInterest: (bank: object, id: string) => any;
  createMonthsWithoutInterest: (fields: object) => any;
  creatingBank: boolean;
  creatingBankError: object;
  bankToEdit: bankToEdit;
}

interface bankToEdit {
  bancoId: string;
  mesesSinInteresesId: string;
  nombre: string;
  tresMeses: number;
  estatusTresMeses: boolean;
  seisMeses: number;
  estatusSeisMeses: boolean;
  nueveMeses: number;
  estatusNueveMeses: boolean;
  doceMeses: number;
  estatusDoceMeses: boolean;
  estatusBanco: boolean;
}

interface Banco {
  bancoId: string;
  mesesSinInteresesId: string;
  nombre: string;
  tresMeses: number;
  estatusTresMeses: boolean;
  seisMeses: number;
  estatusSeisMeses: boolean;
  nueveMeses: number;
  estatusNueveMeses: boolean;
  doceMeses: number;
  estatusDoceMeses: boolean;
  estatusBanco: boolean;
}

interface State {
  fields: Banco;
  editing: boolean;
  errors: FormErrors;
}

const getCleanFields = () => {
  return {
    bancoId: "",
    mesesSinInteresesId: "",
    nombre: "",
    tresMeses: 0,
    estatusTresMeses: false,
    seisMeses: 0,
    estatusSeisMeses: false,
    nueveMeses: 0,
    estatusNueveMeses: false,
    doceMeses: 0,
    estatusDoceMeses: false,
    estatusBanco: false
  };
};

class EditBankMonthsWithoutInterestModal extends React.Component<Props, State> {
  state = {
    fields: getCleanFields(),
    editing: false,
    errors: {}
  };

  re = Reform.reactMixins.objectMixin(this);

  validationRules = {
    nombre: { required: true }
  };

  validationMessages = {
    required: _ => `Obligatorio`,
    default: _ => `Inválido`
  };

  componentDidUpdate() {
    if (!this.props.isOpen && this.state.editing) {
      this.setState(prevState => {
        return {
          ...prevState,
          fields: getCleanFields(),
          editing: false
        };
      });
    }
    if (this.props.isOpen && !this.state.editing) {
      let fields;
      if (this.props.bankToEdit) {
        const bankToEdit = this.props.bankToEdit;
        fields = {
          ...this.state.fields,
          ...bankToEdit
        };
      } else {
        fields = getCleanFields();
      }
      this.setState(prevState => {
        return {
          ...prevState,
          fields,
          editing: true,
          errors: {}
        };
      });
    }
  }

  onChangeFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };
        return { ...state, error: {}, fields };
      });
      this.re.validateField(fieldName, value);
    };
  };

  onChangeCheckBox = (e, name) => {
    const { checked } = e.target;
    this.setState(state => {
      const fields = {
        ...state.fields,
        [name]: checked
      };
      return { ...state, error: {}, fields };
    });
  };

  onSubmit = e => {
    e.preventDefault();
    if (this.re.validateFormFromState()) {
      if (
        this.props.bankToEdit.mesesSinInteresesId &&
        this.props.bankToEdit.mesesSinInteresesId != "-1"
      ) {
        this.props.editMonthsWithoutInterest(
          this.state.fields,
          this.props.bankToEdit.mesesSinInteresesId
        );
      } else {
        this.props.createMonthsWithoutInterest(this.state.fields);
      }
    }
  };

  render() {
    return (
      <ModalFrame
        title={`Configurar Meses Sin Intereses ${
          this.props.bankToEdit && this.props.bankToEdit.nombre
            ? ` para ${this.props.bankToEdit.nombre}`
            : ""
        }`}
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        size="md"
      >
        <div className={s.modalContainer}>
          <div className="h-100">
            <form>
              <div className="form-row">
                <div className="col">
                  <BaseInput
                    label={"Tres Meses"}
                    name="tresMeses"
                    type="number"
                    id="tresMeses"
                    placeholder="Tres meses"
                    value={this.state.fields.tresMeses}
                    onChange={this.onChangeFactory("tresMeses")}
                    errors={this.re.mapFieldErrors("tresMeses")}
                  />
                </div>
                <div className="col">
                  <FormGroup className={s.checkboxContainer} check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={this.state.fields.estatusTresMeses}
                        onChange={e =>
                          this.onChangeCheckBox(e, "estatusTresMeses")
                        }
                      />
                      Activo
                    </Label>
                  </FormGroup>
                </div>
              </div>
              <div className="form-row">
                <div className="col">
                  <BaseInput
                    label={"Seis Meses"}
                    name="seisMeses"
                    type="number"
                    id="seisMeses"
                    placeholder="Seis meses"
                    value={this.state.fields.seisMeses}
                    onChange={this.onChangeFactory("seisMeses")}
                    errors={this.re.mapFieldErrors("seisMeses")}
                  />
                </div>
                <div className="col">
                  <FormGroup className={s.checkboxContainer} check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={this.state.fields.estatusSeisMeses}
                        onChange={e =>
                          this.onChangeCheckBox(e, "estatusSeisMeses")
                        }
                      />
                      Activo
                    </Label>
                  </FormGroup>
                </div>
              </div>
              <div className="form-row">
                <div className="col">
                  <BaseInput
                    label={"Nueve Meses"}
                    name="nueveMeses"
                    type="number"
                    id="nueveMeses"
                    placeholder="Nueve meses"
                    value={this.state.fields.nueveMeses}
                    onChange={this.onChangeFactory("nueveMeses")}
                    errors={this.re.mapFieldErrors("nueveMeses")}
                  />
                </div>
                <div className="col">
                  <FormGroup className={s.checkboxContainer} check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={this.state.fields.estatusNueveMeses}
                        onChange={e =>
                          this.onChangeCheckBox(e, "estatusNueveMeses")
                        }
                      />
                      Activo
                    </Label>
                  </FormGroup>
                </div>
              </div>
              <div className="form-row">
                <div className="col">
                  <BaseInput
                    label={"Doce Meses"}
                    name="doceMeses"
                    type="number"
                    id="doceMeses"
                    placeholder="Doce meses"
                    value={this.state.fields.doceMeses}
                    onChange={this.onChangeFactory("doceMeses")}
                    errors={this.re.mapFieldErrors("doceMeses")}
                  />
                </div>
                <div className="col">
                  <FormGroup className={s.checkboxContainer} check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={this.state.fields.estatusDoceMeses}
                        onChange={e =>
                          this.onChangeCheckBox(e, "estatusDoceMeses")
                        }
                      />
                      Activo
                    </Label>
                  </FormGroup>
                </div>
              </div>
            </form>
          </div>
          <div className={s.formGroup}>
            <div className="row">
              <div className="col" />
              <div className="col-3">
                <Button
                  className={`${s.buttonMarginTop} ${s.primaryButton}`}
                  onClick={e => this.onSubmit(e)}
                  size="sm"
                  block
                  disabled={this.props.creatingBank}
                >
                  {"Guardar"}
                </Button>
              </div>
            </div>
          </div>
          {this.props.creatingBankError && (
            <span>
              <hr />
              <Alert color="danger">
                Ocurrio un error. Intente nuevamente.
              </Alert>
            </span>
          )}
        </div>
      </ModalFrame>
    );
  }
}

export default withRouter(withStyles(s)(EditBankMonthsWithoutInterestModal));
