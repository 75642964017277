import { faEye, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import ReactTable from "react-table";
import {
  Button,
  Col,
  Container,
  Input,
  Row,
  UncontrolledTooltip
} from "reactstrap";
import BaseButtonWithIconAndTooltip from "../../../../../toolkit/baseButtonWithIconAndTooltip";
import ModalFrame from "../../../../../toolkit/modalFrame";
import constants from "../../../../../utils/constants";
import { handleRequestError } from "../../../../../utils/helpers";
import {
  errorNotification,
  infoNotification
} from "../../../../../utils/notifications";
import { createPartnerMembershipFitKidzEvidence } from "../../../../actions/partners";
import s from "../styles.scss";

interface Evidence {
  id: number;
  membresiaSocioId: number;
  fileStorageId: number;
  fechaCreacion: string;
}

interface Props {
  evidenceList: any[];
  idMembresiaSocio: string;
  refreshPartner: () => void;
  invalidateStateValue: (fieldName: string, value: any) => void;
  getEvidences: () => any;
}

interface State {
  uploadFileModalIsOpen: boolean;
  selectedFile: File;
  uploadingFile: boolean;
}

class FitKidzEvidenceTable extends React.Component<Props, State> {
  state = {
    uploadFileModalIsOpen: false,
    selectedFile: null,
    uploadingFile: false
  };

  columns = [
    {
      Header: "Fecha creación",
      className: "text-center",
      width: "120",
      accessor: "fechaCreacion"
    },
    {
      Header: () => {
        return (
          <div className="text-center">
            <BaseButtonWithIconAndTooltip
              id={`addFitKidzEvidence_abc`}
              icon={faPlus}
              tooltipTitle={"Agregar evidencia"}
              onClick={this.handleAddFile}
            />
          </div>
        );
      },
      id: "acciones",
      className: "text-center",
      width: "150",
      accessor: ({ id, fileStorageId }: Evidence) => {
        return (
          <div>
            <a
              id={`evidence-fitkidz-${id}`}
              onClick={e => {
                e.stopPropagation();
                this.viewFile(fileStorageId);
              }}
            >
              <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faEye} />
              <UncontrolledTooltip
                placement="top"
                target={`evidence-fitkidz-${id}`}
              >
                Ver archivo
              </UncontrolledTooltip>
            </a>
          </div>
        );
      }
    }
  ];

  handleFileChosen = e => {
    console.info(e.target.files[0]);
    this.setState({
      selectedFile: !!(e.target.files || []).length ? e.target.files[0] : null
    });
  };

  handleAddFile = () => {
    this.setState({ uploadFileModalIsOpen: true });
  };

  viewFile = fileStorageId => {
    window.open(
      `${constants.BASE_URL}/${constants.DOCUMENTS_BASE_URL}/${fileStorageId}`,
      "_blank"
    );
  };

  cancelAddFile = () => {
    this.setState({
      uploadFileModalIsOpen: false,
      selectedFile: null
    });
  };

  executeAddFile = async () => {
    const { idMembresiaSocio } = this.props;
    const { selectedFile } = this.state;

    if (!selectedFile) {
      errorNotification("Debe seleccionar un archivo");
      return;
    }

    infoNotification("Cargando archivo...");

    await this.setState({ uploadingFile: true });

    createPartnerMembershipFitKidzEvidence(idMembresiaSocio, selectedFile)
      .then(() => {
        this.handleFileAdded();
      })
      .catch(handleRequestError);
  };

  handleFileAdded = () => {
    this.setState({
      selectedFile: null,
      uploadingFile: false,
      uploadFileModalIsOpen: false
    });

    if (!!this.props.invalidateStateValue) {
      this.props.invalidateStateValue("fitKidzEvidencesList", []);
    }

    if (!!this.props.getEvidences) {
      this.props.getEvidences();
    }
  };

  render() {
    const movListLength = (this.props.evidenceList || []).length;
    const showPagination = movListLength > 5;

    return (
      <Container className="mt-4 mb-4 p-0">
        <ModalFrame
          title="Subir archivo"
          isOpen={this.state.uploadFileModalIsOpen}
          toggle={() => {
            this.setState({ uploadFileModalIsOpen: false });
          }}
          size="md"
        >
          <Container style={{ width: "90%" }} className="center-block p-0 mb-3">
            <p>Seleccionar archivo</p>

            <div>
              <Input
                type="file"
                id="pricesFile"
                accept="*"
                onChange={e => this.handleFileChosen(e)}
              />
            </div>

            <Row>
              <Col>
                <Button
                  className={`${s.secundaryButton} mt-3`}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({ uploadFileModalIsOpen: false });
                  }}
                  block
                  disabled={!!this.state.uploadingFile}
                >
                  Cancelar
                </Button>
              </Col>
              <Col>
                <Button
                  className={`${s.primaryButton} mt-3`}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.executeAddFile();
                  }}
                  block
                  disabled={
                    !!this.state.uploadingFile || !this.state.selectedFile
                  }
                >
                  Aceptar
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalFrame>

        <p className={`${s.primaryTitle} w-100 m-0 mb-3`}>Evidencias</p>

        <ReactTable
          data={this.props.evidenceList || []}
          columns={this.columns}
          pageSize={5}
          showPagination={showPagination}
          className="mt-3"
          style={{ fontSize: "0.75rem", width: "100%", maxWidth: "100%" }}
        />
      </Container>
    );
  }
}

export default withStyles(s)(FitKidzEvidenceTable);
