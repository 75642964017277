import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import moment from "moment";
import MexicanCurrency from "../../common/currency";
import {
  Container,
  Col,
  Button,
  Row,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup
} from "reactstrap";
import ReactTable from "react-table";
import s from "./styles.scss";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import BaseInput from "../../../../toolkit/baseInput";
import { withRouter } from "react-router-dom";
import IAction from "../../../../types/IAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faTable } from "@fortawesome/free-solid-svg-icons";
import {
  infoNotification,
  errorNotification,
  successNotification
} from "../../../../utils/notifications";
import {
  getProductPositionsGenerateComissions,
  authorizeComissionsConfigByProductsIds
} from "../../../actions/products";
import { getSportsProductOperationComissionsProm } from "../../../actions/sportsProducts";

interface PositionModel {
  idPuesto: number;
  nombre: string;
}
interface ConceptoNominaModel {
  id: number;
  descripcion: string;
  conceptoId: string;
}
interface ProductWithComissionConfigToAuthModel {
  productoId: number;
  productoNombre: string;
  fechaInicioVigencia: string;
  fechaFinVigencia: string;
  numeroEventos: number;
  maximoParticipantes: number;
  comisionVenta: string;
  comisionCoordinacion: string;
  comisionOperacion: string;
  tipoComportamiento: String;
  conceptosNomina: ConceptoNominaModel[];
  comisionCoordinacionPagoParcial: boolean;
  comisionOperacionPagoParcial: boolean;
}

interface IntervalModel {
  estatus: string;
  montoPorParticipante: number;
  montoPorcentajeParticipante: number;
  numeroParticipantesHasta: number;
  productoId: number;
}

const productBehaviorTypes = [
  { value: null, label: "Seleccione un elemento" },
  { value: "PROGRAMA_DEPORTIVO", label: "PROGRAMA DEPORTIVO" },
  { value: "CLASES_PERSONALIZADAS", label: "CLASES PERSONALIZADAS" },
  { value: "TIENDA", label: "TIENDA" }
];

const SALES_COMISSION_TYPE = "VENTA";
const OP_COMISSION_TYPE = "OPERACION";
const COORDINACION_COMISSION_TYPE = "COORDINACION";
const BEHAVIOR_TYPE_SPORT_PROGRAM = "PROGRAMA_DEPORTIVO";
const MODAL_POSITION_MODE = "POSITIONS";
const MODAL_INTERVAL_MODE = "INTERVALS";

interface Props {
  getProductsWithComissionsConfigToAuthorizeByBehaviorType: (
    behaviorType: string
  ) => IAction;
  clearProductsWithComissionsConfigToAuthorizeByBehaviorType: () => IAction;
  productsList: ProductWithComissionConfigToAuthModel[];
  searching: boolean;
}

interface State {
  selectedBehaviorType: string;
  checkedAll: boolean;
  checked: number[];
  showModal: boolean;
  modalMode: string;
  modalProductName: string;
  comissionType: string;
  positions: PositionModel[];
  intervals: IntervalModel[];
}

class ProductsWithComissionConfigToAuth extends React.Component<Props, State> {
  state = {
    selectedBehaviorType: null,
    checkedAll: false,
    checked: [],
    showModal: false,
    modalMode: null,
    modalProductName: "",
    comissionType: "",
    positions: [],
    intervals: []
  };

  onChangeBehaviorType = event => {
    const value = event.target.value;
    this.setState({ selectedBehaviorType: value });
  };

  checkAll = event => {
    const checked = event.target.checked
      ? (this.props.productsList || []).map(x => x.productoId)
      : [];
    this.setState({ checked, checkedAll: event.target.checked });
  };

  onCheckedChanged = event => {
    const target = event.target;
    const value = target.value;

    const checked =
      target.checked || false
        ? [...this.state.checked, parseInt(value)]
        : this.state.checked.filter(x => x != value);
    this.setState({ checked });
  };

  isChecked = (productId): boolean => {
    return this.state.checked.includes(productId);
  };

  search = () => {
    this.setState({ checked: [] });
    this.props.getProductsWithComissionsConfigToAuthorizeByBehaviorType(
      this.state.selectedBehaviorType || null
    );
  };

  getDataSource = () => {
    return this.props.productsList || [];
  };

  showPositionsinfo = (
    productId: number,
    productName: string,
    comissionType: string
  ) => {
    this.setState({
      modalMode: MODAL_POSITION_MODE,
      comissionType: comissionType,
      modalProductName: productName
    });
    infoNotification("Consultando puestos que generan comisión...");
    getProductPositionsGenerateComissions(productId, comissionType)
      .then(data => {
        this.setState({ positions: data.data });
        this.toggleModal();
      })
      .catch(err => {
        errorNotification(
          "Ocurrió un error al consultar los datos de los puestos que generan comisión."
        );
      });
  };

  showIntervalsinfo = (
    productId: number,
    productName: string,
    comissionType: string
  ) => {
    this.setState({
      modalMode: MODAL_INTERVAL_MODE,
      comissionType: comissionType,
      modalProductName: productName
    });
    infoNotification("Consultando la configuración de intervalos...");
    getSportsProductOperationComissionsProm(productId.toString())
      .then(data => {
        const { comisiones } = data.data;
        this.setState({ intervals: comisiones });
        this.toggleModal();
      })
      .catch(err => {
        errorNotification("Ocurrió un error al consultar los intervalos.");
      });
  };

  authorize = () => {
    if (!this.state.checked || !this.state.checked.length) {
      return;
    }

    infoNotification("Autorizando configuración de comisiones...");
    authorizeComissionsConfigByProductsIds([...this.state.checked])
      .then(() => {
        this.setState({
          checkedAll: false,
          checked: [],
          modalMode: null,
          modalProductName: "",
          comissionType: "",
          positions: [],
          intervals: []
        });
        successNotification("Configuraciones autorizadas correctamente.");
        this.search();
      })
      .catch(err => {
        errorNotification(
          "Ocurrió un error al autorizar las configuraciones seleccionadas."
        );
      });
  };

  //modal

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  handleClosePositionsModal = () => {
    this.toggleModal();
  };

  getSportProgramCell = (row: ProductWithComissionConfigToAuthModel) => {
    if (row.tipoComportamiento == BEHAVIOR_TYPE_SPORT_PROGRAM) {
      return (
        <p className={s.initial}>
          <br />
          <Button
            color="link"
            onClick={() => {
              this.showIntervalsinfo(
                row.productoId,
                row.productoNombre,
                OP_COMISSION_TYPE
              );
            }}
            className={`${s.innerButton} ${s.primaryLink}`}
          >
            <FontAwesomeIcon icon={faTable} />
          </Button>
          <Button
            color="link"
            onClick={() => {
              this.showPositionsinfo(
                row.productoId,
                row.productoNombre,
                OP_COMISSION_TYPE
              );
            }}
            className={`${s.innerButton} ${s.primaryLink}`}
          >
            <FontAwesomeIcon icon={faUser} />
          </Button>
          <br />
          <br />
          Pago parcial:{" "}
          <strong style={{ fontWeight: "bold" }}>
            {!!row.comisionOperacionPagoParcial ? "SI" : "NO"}
          </strong>
        </p>
      );
    } else {
      return (
        row.comisionOperacion && (
          <p className={s.initial}>
            {row.comisionOperacion}
            <br />
            <Button
              color="link"
              onClick={() => {
                this.showPositionsinfo(
                  row.productoId,
                  row.productoNombre,
                  OP_COMISSION_TYPE
                );
              }}
              className={`${s.innerButton} ${s.primaryLink}`}
            >
              <FontAwesomeIcon icon={faUser} />
            </Button>
            <br />
            <br />
            Pago parcial:{" "}
            <strong style={{ fontWeight: "bold" }}>
              {!!row.comisionOperacionPagoParcial ? "SI" : "NO"}
            </strong>
          </p>
        )
      );
    }
  };

  getAmountIntervalCell = (row: IntervalModel) => {
    const { montoPorParticipante, montoPorcentajeParticipante } = row;

    if (!!montoPorParticipante) {
      return <p className={s.initial}>$ {montoPorParticipante}</p>;
    } else if (!!montoPorcentajeParticipante) {
      return <p className={s.initial}>{montoPorcentajeParticipante}%</p>;
    } else {
      return <span></span>;
    }
  };

  columns = [
    {
      Header: () => (
        <div className={s.initial}>
          <Input
            id="checkAllProducts"
            type="checkbox"
            checked={this.state.checkedAll}
            onChange={this.checkAll}
          />
        </div>
      ),
      id: 0,
      width: 50,
      headerStyle: {
        height: "4rem"
      },
      accessor: "autorizar",
      Cell: row => {
        return (
          <div className={s.initial}>
            <Input
              type="checkbox"
              value={row.original.productoId}
              checked={this.isChecked(row.original.productoId)}
              onChange={this.onCheckedChanged}
            />
          </div>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Producto</p>,
      id: 0,
      width: 200,
      headerStyle: {
        height: "4rem"
      },
      accessor: "productName",
      Cell: row => {
        return <p>{row.original.productoNombre}</p>;
      }
    },
    {
      Header: () => (
        <p className="font-weight-bold">
          F. Inicio <br />
          Vigencia
        </p>
      ),
      id: 0,
      width: 100,
      headerStyle: {
        height: "4rem"
      },
      accessor: "productInitialDate",
      Cell: row => {
        return <p className={s.initial}>{row.original.fechaInicioVigencia}</p>;
      }
    },
    {
      Header: () => (
        <p className="font-weight-bold">
          F. Fin <br />
          Vigencia
        </p>
      ),
      id: 0,
      width: 100,
      headerStyle: {
        height: "4rem"
      },
      accessor: "productEndDate",
      Cell: row => {
        return <p className={s.initial}>{row.original.fechaFinVigencia}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Eventos</p>,
      id: 0,
      width: 80,
      headerStyle: {
        height: "4rem"
      },
      accessor: "noEvents",
      Cell: row => {
        return <p className={s.initial}>{row.original.numeroEventos}</p>;
      }
    },
    {
      Header: () => (
        <p className="font-weight-bold">
          Máx. <br />
          Participantes
        </p>
      ),
      id: 0,
      width: 80,
      headerStyle: {
        height: "4rem"
      },
      accessor: "maxPersons",
      Cell: row => {
        return <p className={s.initial}>{row.original.maximoParticipantes}</p>;
      }
    },
    {
      Header: () => (
        <p className="font-weight-bold">
          Config. <br />
          Venta
        </p>
      ),
      id: 0,
      width: 120,
      headerStyle: {
        height: "4rem"
      },
      accessor: "salesConfig",
      Cell: row => {
        return (
          row.original.comisionVenta && (
            <p className={s.initial}>
              {row.original.comisionVenta}
              <br />
              <Button
                color="link"
                onClick={() => {
                  this.showPositionsinfo(
                    row.original.productoId,
                    row.original.productoNombre,
                    SALES_COMISSION_TYPE
                  );
                }}
                className={`${s.innerButton} ${s.primaryLink}`}
              >
                <FontAwesomeIcon icon={faUser} />
              </Button>
            </p>
          )
        );
      }
    },
    {
      Header: () => (
        <p className="font-weight-bold">
          Config. <br />
          Coordinación
        </p>
      ),
      id: 0,
      width: 120,
      headerStyle: {
        height: "4rem"
      },
      accessor: "coordConfig",
      Cell: row => {
        return (
          row.original.comisionCoordinacion && (
            <p className={s.initial}>
              {row.original.comisionCoordinacion}
              <br />
              <Button
                color="link"
                onClick={() => {
                  this.showPositionsinfo(
                    row.original.productoId,
                    row.original.productoNombre,
                    COORDINACION_COMISSION_TYPE
                  );
                }}
                className={`${s.innerButton} ${s.primaryLink}`}
              >
                <FontAwesomeIcon icon={faUser} />
              </Button>
              <br />
              <br />
              Pago parcial:{" "}
              <strong style={{ fontWeight: "bold" }}>
                {!!row.original.comisionCoordinacionPagoParcial ? "SI" : "NO"}
              </strong>
            </p>
          )
        );
      }
    },
    {
      Header: () => (
        <p className="font-weight-bold">
          Config. <br />
          Operación
        </p>
      ),
      id: 0,
      width: 120,
      headerStyle: {
        height: "4rem"
      },
      accessor: "opConfig",
      Cell: row => {
        return this.getSportProgramCell(row.original);
      }
    }
  ];

  subTableColumns = [
    {
      Header: () => <p className="font-weight-bold">Club</p>,
      id: 0,
      width: 200,
      accessor: "clubName",
      Cell: row => {
        return <p className={s.initial}>{row.original.clubNombre}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Clave</p>,
      id: 0,
      width: 130,
      accessor: "conceptNomKey",
      Cell: row => {
        return <p className={s.initial}>{row.original.conceptoId}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Descripción</p>,
      id: 0,
      width: 300,
      accessor: "conceptNomDesc",
      Cell: row => {
        return <p>{row.original.descripcion}</p>;
      }
    }
  ];

  intervalsColumns = [
    {
      Header: () => (
        <p className="font-weight-bold">
          Hasta <br />
          (# de participantes)
        </p>
      ),
      id: 0,
      width: 180,
      accessor: "noUsers",
      Cell: row => {
        return (
          <p className={s.initial}>{row.original.numeroParticipantesHasta}</p>
        );
      }
    },
    {
      Header: () => (
        <p className="font-weight-bold">
          Monto/Porcentaje <br />
          por participante
        </p>
      ),
      id: 0,
      width: 180,
      accessor: "amountPerUser",
      Cell: row => {
        return this.getAmountIntervalCell(row.original);
      }
    }
  ];

  render() {
    return (
      <Container className="p-0 pt-3">
        <Row>
          <Col xs={12} md={4} lg={4}>
            <BaseInput
              label="Tipo de Producto"
              name="productBehaviorType"
              id="productBehaviorType"
              type="select"
              value={this.state.selectedBehaviorType}
              onChange={this.onChangeBehaviorType}
              size={"sm"}
              options={[...productBehaviorTypes]}
            />
          </Col>
          <Col xs={12} md={4} lg={2} className="mt-4">
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.search}
            >
              Buscar
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className="row mt-3 mb-3">
              <div className="col">
                <p className={`${s.primaryTitle} w-100 m-0`}>
                  Productos con configuración de comisiones por autorizar
                </p>
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <ReactTable
              data={this.getDataSource()}
              columns={this.columns}
              className={`${s.customTable} mt-5 -striped -highlight`}
              sortable={false}
              resizable={true}
              pageSize={(this.props.productsList || []).length + 2}
              showPagination={false}
              loading={this.props.searching}
              SubComponent={row => {
                return (
                  <div className={s.subtabla}>
                    <ReactTable
                      data={row.original.conceptosNomina || []}
                      columns={this.subTableColumns}
                      defaultPageSize={
                        (row.original.conceptosNomina || []).length + 1
                      }
                      showPagination={false}
                    />
                  </div>
                );
              }}
            />
          </Col>
        </Row>
        <div className="text-right mt-2 mb-5">
          <Button
            className={`${s.buttonMarginTop} ${s.primaryButton}`}
            onClick={this.authorize}
            size="sm"
            disabled={!(this.state.checked || []).length}
          >
            Autorizar
          </Button>
        </div>

        <Modal
          isOpen={this.state.showModal}
          toggle={this.toggleModal}
          fade={true}
          size="lg"
          backdrop="static"
          keyboard={false}
        >
          <ModalHeader toggle={this.toggleModal}>
            <span className="font-weight-bold">
              Producto: {this.state.modalProductName}
            </span>
          </ModalHeader>
          <ModalBody>
            {this.state.modalMode == MODAL_POSITION_MODE && (
              <div>
                <p className={`${s.primaryTitle} w-100 m-0`}>
                  Comisiones de {this.state.comissionType} - Puestos que generan
                  comisión
                </p>
                <div className="mt-3">
                  <Row>
                    {this.state.positions.map((position: PositionModel) => (
                      <Col
                        className={s.labelContainer}
                        key={position.idPuesto}
                        xs="6"
                        md="4"
                      >
                        <FormGroup className={s.label} check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={true}
                              disabled={true}
                            />{" "}
                            {position.nombre}
                          </Label>
                        </FormGroup>
                      </Col>
                    ))}
                  </Row>
                </div>
              </div>
            )}
            {this.state.modalMode == MODAL_INTERVAL_MODE && (
              <div>
                <p className={`${s.primaryTitle} w-100 m-0`}>
                  Comisiones de {this.state.comissionType} - Intervalos
                </p>
                <div className="mt-3">
                  <ReactTable
                    data={this.state.intervals || []}
                    columns={this.intervalsColumns}
                    className={`${s.customTable} mt-5 -striped -highlight`}
                    sortable={false}
                    resizable={false}
                    pageSize={(this.state.intervals || []).length + 2}
                    showPagination={false}
                    loading={false}
                  />
                </div>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              className={`${s.secondaryButton} d-inline-block mr-2`}
              onClick={this.handleClosePositionsModal}
            >
              Cerrar
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ProductsWithComissionConfigToAuth));
