import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import IngressByClubReport from "../../components/ingressByClubReport";
import { getClubs } from "../../actions/catalogs";
import { getIngressByClubReport } from "../../actions/reports";

interface Props {
  clubs: any;
  history: any;
  getClubs: () => any;
  getIngressByClubReport: (params: any) => any;
  loadingIngressByClubReport: boolean;
  ingressByClubReport: object[];
  userContext: any;
}

class IngressByClubReportPage extends React.Component<Props> {
  componentDidMount() {
    this.props.getClubs();
  }

  onFetchData = (params: any) => {
    this.props.getIngressByClubReport(params);
  };

  render() {
    return (
      <main>
        <Helmet title="Reporte de ingresos por club" />
        <Layout>
          <IngressByClubReport
            clubs={this.props.clubs}
            getIngressByClubReport={this.props.getIngressByClubReport}
            loadingIngressByClubReport={this.props.loadingIngressByClubReport}
            ingressByClubReport={this.props.ingressByClubReport}
            onFetchData={this.onFetchData}
            userContext={this.props.userContext}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getClubs: () => {
    dispatch(getClubs());
  },
  getIngressByClubReport: (params: any) => {
    dispatch(getIngressByClubReport(params));
  }
});

const mapStateToProps = state => {
  const { catalogs, reports } = state;

  return {
    clubs: catalogs.clubs,
    loadingIngressByClubReport: reports.loadingIngressByClubReport,
    ingressByClubReport: reports.ingressByClubReport,
    userContext: state.user.userContext
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IngressByClubReportPage);
