import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import {
  EmpresaConvenioCarouselStore,
  empresaConvenioCarouselStore
} from "./EmpresaConvenioCarousel.store";
import {
  EmpresaConvenioCarouselDispatcher,
  empresaConvenioCarouselDispatcher
} from "./EmpresaConvenioCarousel.dispatcher";
import {
  ReduxConnect,
  Router,
  Routing,
  RouterProps
} from "../../../toolkit/decorators/Artifact";
import Carousel2 from "../../../toolkit/carousel2/Carousel2";
import EmpresaConvenioBasicDataStep from "../../components/empresaConvenioCarousel/basicData/index";
import EmpresaConvenioInvitadoStep from "../../components/empresaConvenioCarousel/invitado";

interface Props
  extends EmpresaConvenioCarouselStore,
    EmpresaConvenioCarouselDispatcher,
    RouterProps<{
      id: string;
    }> {}

interface State {
  id: number;
  activeSteps: boolean;
  dirty: boolean;
  dirtyMsg: string;
}

@Router()
@ReduxConnect(empresaConvenioCarouselStore, empresaConvenioCarouselDispatcher)
@Routing(
  "/empresas-convenios/configuracion",
  "/empresas-convenios/:id/configuracion"
)
export default class EmpresaConvenioCarouselContainer extends React.Component<
  Props,
  State
> {
  state = {
    id: 0,
    activeSteps: false,
    dirty: false,
    dirtyMsg: ""
  };

  componentDidMount(): void {
    this.setState({
      id: Number(this.props.match.params.id) || 0
    });
  }

  // Indica, por parte del paso en el carrusel, si debe existir confirmación
  handleParams = (id: number) => {
    this.setState({ id });
  };

  // Indica, por parte del paso en el carrusel, si debe existir confirmación
  handleDirty = (dirty: boolean, dirtyMsg: string) => {
    this.setState({ dirty, dirtyMsg });
  };

  onChangeStep = (step: number) => {};

  render() {
    return (
      <main>
        <Layout>
          <Helmet title="Empresas de convenio" />
          <Carousel2 active={!!this.props.empresaConvenio}>
            <EmpresaConvenioBasicDataStep
              {...this.props}
              id={this.state.id}
              handleDirty={this.handleDirty}
              handleParams={this.handleParams}
              data-carousel-name="Datos básicos"
            />
            <EmpresaConvenioInvitadoStep
              {...this.props}
              id={this.state.id}
              handleDirty={this.handleDirty}
              handleParams={this.handleParams}
              data-carousel-name="Configuración invitados"
            />
          </Carousel2>
        </Layout>
      </main>
    );
  }
}
