import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import Layout from "../../components/layout";

import { getUsersInEvent } from "../../actions/events";
import { getProducts } from "../../actions/sportsProducts";
import { getSportsProductsTypes } from "../../actions/catalogs";
import ProductSells from "../../components/productSells";

import { QueryResponse } from "../../../types/QueryResponse";
import { QueryArguments } from "../../../types-business/QueryArguments";
import CONSTANTS from "../../../utils/constants";

interface Product {
  id: string;
  clasificacionId: string;
  nombre: string;
  estatus: string;
  publicarPrecio: boolean;
  fechaInicioVigencia: string;
  fechaFinVigencia: string;
  descripcion: string;
}

interface Props {
  clubs: object[];
  products: QueryResponse<Product>;
  getClubs: () => any;
  getUsersInEvent: (eventId: string, clubId: string) => any;
  getProducts: (args?: QueryArguments) => any;
  getSportsProductsTypes: () => any;
  match: any;
  searching: boolean;
  addProduct: (product?) => any;
  history: any;
  sportsProductsTypes: object[];
  usersInEvent: object;
  gettingUsersInEvent: boolean;
}

interface State {
  products: object;
  filters: { clasificacionId: string };
  events: object[];
}

class SportSells extends React.Component<Props, State> {
  state = {
    products: {
      content: [],
      totalPages: 0
    },
    filters: {
      clasificacionId: null,
      size: CONSTANTS.DEFAULT_PAGE_SIZE,
      page: 0
    },
    events: {
      content: [],
      totalPages: 0
    }
  };

  onChangeFilter = (value, name) => {
    const filters = {
      ...this.state.filters,
      [`${name}`]: value ? value : null
    };
    this.setState(state => ({
      ...state,
      filters
    }));
  };

  onFetchData = ({ pageSize: size, page }) => {
    const filters = {
      ...this.state.filters,
      size,
      page
    };
    this.props.getProducts(filters);
    this.setState(state => ({ ...state, ...filters }));
  };

  goTo = destiny => {
    this.props.history.push(destiny);
  };

  componentDidUpdate(prevProps) {
    const { products } = this.props;
    const { products: prevProducts } = prevProps;
    if (products && prevProducts !== products) {
      this.setState({ products, events: { content: this.props.events || [] } });
    }
  }
  render() {
    return (
      <main>
        <Helmet title="Sports Sold Products" />
        <Layout>
          <ProductSells
            usersInEvent={this.props.usersInEvent}
            gettingUsersInEvent={this.props.gettingUsersInEvent}
            products={this.state.products}
            events={this.state.events}
            fetchData={this.onFetchData}
            searching={this.props.searching}
            filters={this.state.filters}
            onChangeFilter={this.onChangeFilter}
            getProducts={this.props.getProducts}
            getUsersData={this.props.getUsersInEvent}
            sportsProducts={this.props.sportsProductsTypes}
            onClickNewProduct={(productId, clientId) =>
              this.goTo(`/deportiva/clase/${productId}/${clientId}`)
            }
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = state => {
  const {
    sportsProducts: { usersInEvent, gettingUsersInEvent },
    catalogs
  } = state;
  return {
    usersInEvent,
    gettingUsersInEvent,
    sportsProductsTypes: catalogs.sportsProductsTypes
  };
};

const mapDispatchToProps = {
  getProducts,
  getSportsProductsTypes,
  getUsersInEvent
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SportSells);
