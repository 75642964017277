import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Container, Row, Col, UncontrolledTooltip, Button } from "reactstrap";
import ReactTable from "react-table";
import columns from "./columns";
import ClubSelectList from "../../containers/clubSelectList";
import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExport,
  faSpinner,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import DatePicker from "../common/DatePicker";
import moment from "moment";
import { postDirectConfirmar, postDirectUpload } from "../../actions/dicounts";

interface Props {}

interface State {
  descuentos: any[];
  wait: boolean;
  applying: boolean;
}

class DescuentoDirectoPage extends React.Component<Props, State> {
  state = {
    descuentos: [],
    wait: false,
    applying: false
  };

  handleFileChange = async evt => {
    evt.preventDefault();

    if (!evt || !evt.target || !evt.target.files || !evt.target.files.length) {
      errorNotification("Debe seleccionar un archivo");
      return;
    }

    infoNotification("Cargando archivo...");

    this.setState({
      wait: true
    });

    postDirectUpload(evt.target.files[0])
      .then(response => {
        this.setState({
          descuentos: response.data,
          wait: false
        });
      })
      .catch(err => {
        errorNotification(
          err && err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "No se pudo cargar el archivo"
        );

        this.setState({
          descuentos: [],
          wait: false
        });
      });
  };

  submit = evt => {
    evt.preventDefault();

    this.setState({
      applying: true
    });

    infoNotification("Registrando descuentos...");

    postDirectConfirmar(this.state.descuentos)
      .then(response => {
        successNotification(response.data.message);

        this.setState({
          applying: false,
          descuentos: []
        });
      })
      .catch(err => {
        errorNotification(
          err && err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : "No se pudo procesar el archivo"
        );

        this.setState({
          applying: false
        });
      });
  };

  render() {
    return (
      <Container className="mt-3 p-0">
        {/* Filtros */}
        <Row className="mt-4">
          {/* Selector de archivo */}
          <Col md="4">
            <Button
              type="button"
              className={`${s.primaryButton} mt-4 ${s.fileButton}`}
              size="sm"
              block
              onClick={e => {
                if (this.state.wait) {
                  e.preventDefault();
                  e.stopPropagation();

                  return false;
                }
              }}
            >
              {this.state.wait ? (
                <FontAwesomeIcon icon={faSpinner} pulse />
              ) : (
                "Seleccionar archivo"
              )}
              <input
                className={s.inputFile}
                type="file"
                id={`scannedFile`}
                accept=".csv"
                title=""
                onChange={this.handleFileChange}
              />
            </Button>
          </Col>
        </Row>

        <ReactTable
          className="mt-5 -striped -highlight"
          data={this.state.descuentos || []}
          columns={columns}
          sortable={false}
          resizable={true}
          pageSize={
            this.state.descuentos && this.state.descuentos.length > 5
              ? this.state.descuentos.length
              : 5
          }
          showPagination={false}
          loading={this.state.wait}
        />

        <Row>
          <Col md="8">&nbsp;</Col>

          {/* Botón de carga */}
          <div hidden={this.state.descuentos.length == 0} className="col-4">
            <Button
              className={`${s.primaryButton} mt-4`}
              type="button"
              size="sm"
              block
              onClick={this.submit}
              disabled={this.state.applying}
            >
              {this.state.applying ? "Registrando descuentos..." : "Confirmar"}
            </Button>
          </div>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(DescuentoDirectoPage));
