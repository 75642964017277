import React from "react";
import { connect } from "react-redux";
import {
  getStatus,
  getPaymentTypes,
  getAccountingAccounts,
  getProductAccounts
} from "../../actions/catalogs";
import * as sportsProductsActions from "../../actions/sportsProducts";
import PriceDataSportsProducts from "../../components/priceDataSportsProducts";

interface Props {
  addPricesToSportsProducts: (id: string, prices: object) => any;
  successfulSave: boolean;
  getStatus: () => any;
  getPaymentTypes: () => any;
  getPricesToSportsProducts: (params: object) => any;
  estatus: Catalog[];
  tiposPrecio: tipoPrecio[];
  prices: Prices;
  loading: boolean;
  savingPrices: boolean;
  savingPricesError: boolean;
  authorizePrices: (prices: object[], id: string) => any;
  onClickSave: (e: object) => any;
  onSuccessfulSave: () => any;
  getSportsProductConfig: (id: string) => any;
  generalConfigById: object;
  generalProductById: object;
  accountingAccounts: object[];
  productAccounts: object[];
  getAccountingAccounts: () => any;
  getProductAccounts: () => any;
}

interface PriceData {
  clubId: string;
  concepto: string;
  nombre: string;
  inicioVigencia: string;
  finVigencia: string;
  precio: string;
  cuentaContable: string;
  estatus: string;
}

interface Prices {
  content: PriceData[];
  totalPages: string;
}

interface tipoPrecio {
  esquemaPagoId: string;
  nombre: string;
}

interface Catalog {
  id: string;
  nombre: string;
}

class PriceDataSportsProductsPage extends React.Component<Props, void> {
  componentDidMount() {
    this.props.getStatus();
    this.props.getPaymentTypes();
    this.props.getAccountingAccounts();
    this.props.getProductAccounts();
  }

  render() {
    return (
      <PriceDataSportsProducts
        addPricesToSportsProducts={this.props.addPricesToSportsProducts}
        getPricesToSportsProducts={this.props.getPricesToSportsProducts}
        estatus={this.props.estatus}
        prices={this.props.prices}
        loading={this.props.loading}
        savingPrices={this.props.savingPrices}
        savingPricesError={this.props.savingPricesError}
        tiposPrecio={this.props.tiposPrecio}
        onClickSave={this.props.onClickSave}
        authorizePrices={this.props.authorizePrices}
        onSuccessfulSave={this.props.onSuccessfulSave}
        getSportsProductConfig={this.props.getSportsProductConfig}
        generalConfigById={this.props.generalConfigById}
        generalProductById={this.props.generalProductById}
        accountingAccounts={this.props.accountingAccounts}
        productAccounts={this.props.productAccounts}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addPricesToSportsProducts: (id: string, prices: object) => {
    return dispatch(
      sportsProductsActions.addPricesToSportsProducts(id, prices)
    );
  },
  getStatus: () => {
    dispatch(getStatus());
  },
  getPaymentTypes: () => {
    dispatch(getPaymentTypes());
  },
  getAccountingAccounts: () => {
    dispatch(getAccountingAccounts());
  },
  getProductAccounts: () => {
    dispatch(getProductAccounts());
  },
  getPricesToSportsProducts: (params: object) => {
    return dispatch(sportsProductsActions.getPricesToSportsProducts(params));
  },
  authorizePrices: (prices: object[], id: string) => {
    return dispatch(sportsProductsActions.authorizePrices(prices, id));
  },
  getSportsProductConfig: (id: string) => {
    dispatch(sportsProductsActions.getSportsProductConfig(id));
  }
});

const mapStateToProps = state => {
  const { catalogs, sportsProducts } = state;
  return {
    estatus: catalogs.estatus,
    tiposPrecio: catalogs.tiposPrecio,
    successfulSave: sportsProducts.pricesSaved,
    prices: sportsProducts.pricesToProduct,
    loading: sportsProducts.loadingPricesToProduct,
    savingPrices: sportsProducts.savingPrices,
    savingPricesError: sportsProducts.savingPricesError,
    generalConfigById: sportsProducts.generalConfigById,
    generalProductById: sportsProducts.generalProductById,
    accountingAccounts: catalogs.accountingAccounts,
    productAccounts: catalogs.productAccounts
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PriceDataSportsProductsPage);
