import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { showDocument } from "../../actions/documents";
import Layout from "../../components/layout";
import * as clubsActions from "../../actions/clubs";
import * as reportsActions from "../../actions/reports";
import IAction from "../../../types/IAction";
import ReferencedDepositReport from "../../components/referencedDepositReport";

interface Props {
  getAllClubsBasicData: () => void;
  clubs: object[];
  depositoReferenciado: object[];
  getDepositoReferenciado: (clubId: string, fechaDePago: string) => IAction;
  pdfReferencedDeposit: (clubId: string, fechaDePago: string) => IAction;
  clearReasignMembership: () => IAction;
}

interface State {}

class ReferencedDeposit extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    this.props.getAllClubsBasicData();
  }

  componentWillUnmount() {
    this.props.clearReasignMembership();
  }

  render() {
    return (
      <main>
        <Helmet title="Shift delivery" />
        <Layout>
          <ReferencedDepositReport
            clubs={this.props.clubs}
            depositoReferenciado={this.props.depositoReferenciado}
            getDepositoReferenciado={this.props.getDepositoReferenciado}
            pdfReferencedDeposit={this.props.pdfReferencedDeposit}
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({ clubs, reports }) => {
  return {
    clubs: clubs.allClubsBasicData,
    depositoReferenciado: reports.depositoReferenciado
  };
};

const mapDispatchToProps = dispatch => ({
  toggleBill: showDocument,
  getAllClubsBasicData: () => {
    dispatch(clubsActions.getAllClubsBasicData());
  },
  getDepositoReferenciado: (clubId: string, fechaDePago: string) => {
    return dispatch(
      reportsActions.getDepositoReferenciado(clubId, fechaDePago)
    );
  },
  clearReasignMembership: () => {
    return dispatch(reportsActions.clearReasignMembership());
  },
  pdfReferencedDeposit: (clubId: string, fechaDePago: string) => {
    return dispatch(reportsActions.pdfReferencedDeposit(clubId, fechaDePago));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferencedDeposit);
