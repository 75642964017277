import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import PagedTable from "../common/PagedTable";
import { QueryResponse } from "../../../types/QueryResponse";
import { Container } from "reactstrap";
import { Document } from "../../../types-business/Documents";

interface Props {
  documents: QueryResponse<Document>;
  searching: boolean;
  onClickNewDocument: (document) => any;
  reloadDocuments: boolean;
  getDocuments: (args) => void;
}

interface State {
  keys: string[];
  search: object;
}

class ConfigDocuments extends React.Component<Props, State> {
  onChangeFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(prevState => ({
        ...prevState,
        [fieldName]: value
      }));
    };
  };

  handleEditDocument = d => {
    this.props.onClickNewDocument(d);
  };

  handleDeleteDocument = () => {};

  fetchData = ({ pageSize: size, page }) => {
    this.props.getDocuments({ size, page });
    this.setState(state => {
      return {
        ...state,
        search: { size, page }
      };
    });
  };

  componentDidUpdate() {
    if (this.props.reloadDocuments) {
      this.props.getDocuments(this.state.search);
    }
  }

  render() {
    const columns = [
      { name: "Id", id: "id" },
      { name: "Nombre", id: "nombre", minWidth: 300 },
      { name: "Tipo", id: "tipoDocumento" },
      {
        headerActions: [{ name: "add", f: this.props.onClickNewDocument }]
      }
    ];

    const actions = [
      { name: "edit", f: this.handleEditDocument },
      { name: "delete", f: this.handleDeleteDocument }
    ];

    return (
      <Container className="p-0 mt-3">
        <PagedTable
          data={this.props.documents}
          columns={columns}
          actions={actions}
          fetchData={this.fetchData}
          searching={this.props.searching}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ConfigDocuments));
