import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import Reform from "@franleplant/reform";
import { Form, FormGroup, Label, Input, Container, Row, Col } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import s from "./styles.scss";
import { FormErrors } from "@franleplant/reform/types";

interface Props {
  onClickSave: (e: object) => any;
  history: any;
  client: any;
  saveBasicDocument: (doc: object) => any;
  updateBasicDocument: (id: string, doc: object) => any;
  match: any;
  document: Document;
  getBasicDocument: (id: string) => any;
}

interface Fields {
  tipoDocumento: string;
  idEmpresa: string;
  nombre: string;
  contenido: string;
  email: boolean;
  imprimir: boolean;
  editar: boolean;
  firmar: boolean;
  escanear: boolean;
  regenerar: boolean;
  eliminar: boolean;
  esCritico: boolean;
}

interface Document {
  id: string;
  tipoDocumento: string;
  idEmpresa: string;
  nombre: string;
  contenido: string;
  email: boolean;
  imprimir: boolean;
  editar: boolean;
  firmar: boolean;
  escanear: boolean;
  regenerar: boolean;
  eliminar: boolean;
  esCritico: boolean;
}

interface State {
  errors: FormErrors;
  fields: Fields;
}

const documentToFields = ({
  tipoDocumento,
  idEmpresa,
  nombre,
  contenido,
  email,
  imprimir,
  editar,
  firmar,
  escanear,
  regenerar,
  eliminar,
  esCritico
}) => {
  return {
    tipoDocumento,
    idEmpresa,
    nombre,
    contenido,
    email,
    imprimir,
    editar,
    firmar,
    escanear,
    regenerar,
    eliminar,
    esCritico
  };
};

class BasicDataDocumentForm extends React.Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);

  state = {
    fields: {
      tipoDocumento: "",
      idEmpresa: "1",
      nombre: "",
      contenido: "",
      email: false,
      imprimir: false,
      editar: false,
      firmar: false,
      escanear: false,
      regenerar: false,
      eliminar: false,
      esCritico: false
    },
    errors: {}
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      this.props.getBasicDocument(id);
    }

    this.props.onClickSave(e => {
      e.preventDefault();

      if (this.re.validateFormFromState()) {
        const { id } = this.props.match.params;
        if (!id) {
          this.props.saveBasicDocument(this.state.fields);
        } else {
          this.props.updateBasicDocument(id, this.state.fields);
        }
      }
    });
  }

  processed = false;

  componentDidUpdate(prevProps) {
    const { document: propsDocument } = this.props;
    const { document: prevPropsDocument } = prevProps;

    if (propsDocument && prevPropsDocument !== propsDocument) {
      this.setState(state => ({
        ...state,
        fields: documentToFields(propsDocument)
      }));
    }
  }

  validationRules = {
    nombre: { required: true },
    contenido: { required: true },
    tipoDocumento: { required: true }
  };

  validationMessages = {
    required: _ => `Obligatorio`,
    default: _ => `Inválido`
  };

  onChangeFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };

        return { ...state, fields, errors: {} };
      });
      this.re.validateField(fieldName, value);
    };
  };

  onChangeFactoryCheckbox = (e, fieldName) => {
    const value = e.target.checked;
    this.setState(state => {
      const fields = {
        ...state.fields,
        [fieldName]: value
      };
      return { ...state, fields, errors: {} };
    });
    this.re.validateField(fieldName, value);
  };

  render() {
    return (
      <Container className="p-0" fluid>
        <Row className="mb-3">
          <Col>
            <p className={`${s.primaryTitle} w-100 m-0`}>Datos Básicos</p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <BaseInput
              label={"Nombre"}
              name="nombre"
              type="text"
              id="nombre"
              placeholder="Nombre"
              value={this.state.fields.nombre}
              onChange={this.onChangeFactory("nombre")}
              errors={this.re.mapFieldErrors("nombre")}
            />
          </Col>
          <Col xs={12} md={4}>
            <BaseInput
              label={"Contenido"}
              name="contenido"
              type="text"
              id="contenido"
              placeholder="Contenido"
              value={this.state.fields.contenido}
              onChange={this.onChangeFactory("contenido")}
              errors={this.re.mapFieldErrors("contenido")}
            />
          </Col>
          <Col xs={12} md={4}>
            <BaseInput
              label={"Tipo de Documento"}
              name={"tipoDocumento"}
              type="select"
              id={"tipoDocumento"}
              placeholder="Tipo de Documento"
              value={this.state.fields.tipoDocumento}
              onChange={this.onChangeFactory("tipoDocumento")}
              errors={this.re.mapFieldErrors("tipoDocumento")}
              options={[
                { value: "", label: "Seleccione el tipo de Documento" },
                { value: "MEMBRESIA", label: "Membresía" },
                { value: "SOCIO", label: "Socio" }
              ]}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <p className={`${s.primaryTitle} w-100 m-0`}>Acciones</p>
          </Col>
        </Row>
        <Row>
          <Col xs={6} md={3}>
            <Form className={`${s.checkboxContainer}`}>
              <FormGroup check className="form-check-inline">
                <Label check>
                  <Input
                    type="checkbox"
                    checked={this.state.fields.email}
                    onChange={e => this.onChangeFactoryCheckbox(e, "email")}
                  />{" "}
                  Email
                </Label>
              </FormGroup>
            </Form>
          </Col>
          <Col xs={6} md={3}>
            <Form className={`${s.checkboxContainer}`}>
              <FormGroup check className="form-check-inline">
                <Label check>
                  <Input
                    type="checkbox"
                    checked={this.state.fields.imprimir}
                    onChange={e => this.onChangeFactoryCheckbox(e, "imprimir")}
                  />{" "}
                  Imprimir
                </Label>
              </FormGroup>
            </Form>
          </Col>
          <Col xs={6} md={3}>
            <Form className={`${s.checkboxContainer}`}>
              <FormGroup check className="form-check-inline">
                <Label check>
                  <Input
                    type="checkbox"
                    checked={this.state.fields.firmar}
                    onChange={e => this.onChangeFactoryCheckbox(e, "firmar")}
                  />{" "}
                  Firmar
                </Label>
              </FormGroup>
            </Form>
          </Col>
          <Col xs={6} md={3}>
            <Form className={`${s.checkboxContainer}`}>
              <FormGroup check className="form-check-inline">
                <Label check>
                  <Input
                    type="checkbox"
                    checked={this.state.fields.escanear}
                    onChange={e => this.onChangeFactoryCheckbox(e, "escanear")}
                  />{" "}
                  Escanear
                </Label>
              </FormGroup>
            </Form>
          </Col>
          <Col xs={6} md={3}>
            <Form className={`${s.checkboxContainer}`}>
              <FormGroup check className="form-check-inline">
                <Label check>
                  <Input
                    type="checkbox"
                    checked={this.state.fields.regenerar}
                    onChange={e => this.onChangeFactoryCheckbox(e, "regenerar")}
                  />{" "}
                  Regenerar
                </Label>
              </FormGroup>
            </Form>
          </Col>
          <Col xs={6} md={3}>
            <Form className={`${s.checkboxContainer}`}>
              <FormGroup check className="form-check-inline">
                <Label check>
                  <Input
                    type="checkbox"
                    checked={this.state.fields.editar}
                    onChange={e => this.onChangeFactoryCheckbox(e, "editar")}
                  />{" "}
                  Editar
                </Label>
              </FormGroup>
            </Form>
          </Col>
          <Col xs={6} md={3}>
            <Form className={`${s.checkboxContainer}`}>
              <FormGroup check className="form-check-inline">
                <Label check>
                  <Input
                    type="checkbox"
                    checked={this.state.fields.eliminar}
                    onChange={e => this.onChangeFactoryCheckbox(e, "eliminar")}
                  />{" "}
                  Eliminar
                </Label>
              </FormGroup>
            </Form>
          </Col>
          <Col xs={6} md={3}>
            <Form className={`${s.checkboxContainer}`}>
              <FormGroup check className="form-check-inline">
                <Label check>
                  <Input
                    type="checkbox"
                    checked={this.state.fields.esCritico}
                    onChange={e => this.onChangeFactoryCheckbox(e, "esCritico")}
                  />{" "}
                  Es critico
                </Label>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(BasicDataDocumentForm));
