import React, { Component } from "react";
import { UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmModal from "../confirmModal/confirmModal";
import { handleRequestError } from "../../../../utils/helpers";

interface Props {
  onClick: () => any;
  success: () => any;
  buttonId: string;
  tooltipTitle: string;
  tooltipPlacement: string;
  icon: any;
  confirmModal: { title: string; message: string };
  color: String;
}

interface State {
  confirmAuthorizeModalIsOpen: boolean;
}

class SideActionButton extends Component<Props, State> {
  state = {
    confirmAuthorizeModalIsOpen: false
  };

  render() {
    return (
      <React.Fragment>
        {!!this.props.confirmModal && (
          <ConfirmModal
            title={this.props.confirmModal.title || "Confirmar"}
            message={
              this.props.confirmModal.message ||
              "¿Desea continuar con la operación?"
            }
            isOpen={this.state.confirmAuthorizeModalIsOpen}
            ok={() => {
              this.props
                .onClick()
                .then(() => {
                  if (!!this.props.success) {
                    this.props.success();
                  }
                  this.setState({
                    confirmAuthorizeModalIsOpen: false
                  });
                })
                .catch(handleRequestError);
            }}
            cancel={() => {
              this.setState({
                confirmAuthorizeModalIsOpen: false
              });
            }}
          />
        )}
        <a
          id={this.props.buttonId}
          className="ml-3"
          style={{ cursor: "pointer" }}
          onClick={e => {
            e.preventDefault();
            if (!!this.props.confirmModal) {
              this.setState({
                confirmAuthorizeModalIsOpen: true
              });
            } else {
              this.props
                .onClick()
                .then(() => {
                  if (!!this.props.success) {
                    this.props.success();
                  }
                })
                .catch(handleRequestError);
            }
          }}
        >
          <FontAwesomeIcon
            icon={this.props.icon}
            color={this.props.color || ""}
          />
          <UncontrolledTooltip
            placement={this.props.tooltipPlacement || "top"}
            target={this.props.buttonId}
          >
            {this.props.tooltipTitle}
          </UncontrolledTooltip>
        </a>
      </React.Fragment>
    );
  }
}

export default SideActionButton;
