import React from "react";
import { connect } from "react-redux";
import * as actions from "../../actions/empresaConvenio";
import { getUsers } from "../../actions/profile";
import BasicDataEmpresaConvenioForm from "../../components/basicDataEmpresaConvenioForm";

interface Props {
  getCompanie: (id: string) => any;
  createCompanie: (companie: object) => any;
  updateCompanie: (id: string, companie: object) => any;
  onClickSave: (f: any) => any;
  companie: object;
  id: string;
  getUsers: any;
  users: any[];
}

class BasicDataCompaniesContainer extends React.Component<Props, void> {
  componentDidMount() {
    const { id } = this.props;
    if (id) {
      this.props.getCompanie(id);
    }
  }

  render() {
    return (
      <div className="p-0 m-0 w-100 h-100">
        <BasicDataEmpresaConvenioForm
          companie={this.props.companie}
          createCompanie={this.props.createCompanie}
          updateCompanie={this.props.updateCompanie}
          onClickSave={this.props.onClickSave}
          getUsers={this.props.getUsers}
          users={this.props.users}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createCompanie: (companie: object) => {
    return dispatch(actions.createCompanie(companie));
  },
  getCompanie: (id: string) => {
    dispatch(actions.getCompanie(id));
  },
  updateCompanie: (id: string, companie: object) => {
    return dispatch(actions.updateCompanie(id, companie));
  },
  getUsers: () => {
    dispatch(getUsers());
  }
});

const mapStateToProps = state => {
  const { empresasConvenio, user } = state;
  return {
    companie: empresasConvenio.empresaConvenio,
    users: user.usersPage
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasicDataCompaniesContainer);
