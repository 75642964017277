import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import MembershipMembersRules from "../../components/membershipMembersRules";
import {
  sendMembersTypes,
  getMemberships,
  getMembershipMembers,
  getMaintenancesMembership,
  sendMembersTypesRules,
  getMembersTypesRules
} from "../../actions/products";

interface Props {
  memberships: object;
  getMemberships: () => any;
  sendMembersTypes: (membresiaId: string, membersTypes: object[]) => any;
  getMembershipMembers: (membresiaId: string) => any;
  getMaintenancesMembership: (membresiaId: string) => any;
  maintenancesMembership: object[];
  membershipTypesMembers: object[];
  sendMembersTypesRules: (membresiaId: string, data: object[]) => any;
  getMembersTypesRules: (membresiaId: string) => any;
  membersRules: {
    edadMaxima: number;
    edadMinima: number;
    idMantenimiento: number;
    idMembresia: number;
    idTipoIntegrante: number;
  }[][];
}

class PartnersPage extends React.Component<Props, void> {
  componentDidMount() {
    this.props.getMemberships();
  }

  render() {
    return (
      <main>
        <Helmet title="Reglas de integrantes por Membresía y Mantenimiento" />
        <Layout>
          {this.props.memberships && (
            <MembershipMembersRules
              memberships={this.props.memberships}
              sendMembersTypes={this.props.sendMembersTypes}
              getMembershipMembers={this.props.getMembershipMembers}
              getMaintenancesMembership={this.props.getMaintenancesMembership}
              maintenancesMembership={this.props.maintenancesMembership}
              membershipTypesMembers={(
                this.props.membershipTypesMembers || []
              ).filter(x => !!x.maximoIntegrantes)}
              sendMembersTypesRules={this.props.sendMembersTypesRules}
              getMembersTypesRules={this.props.getMembersTypesRules}
              membersRules={this.props.membersRules}
            />
          )}
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getMemberships: () => {
    dispatch(getMemberships());
  },
  sendMembersTypes: (membresiaId, membersTypes) => {
    dispatch(sendMembersTypes(membresiaId, membersTypes));
  },
  getMembershipMembers: membresiaId => {
    dispatch(getMembershipMembers(membresiaId));
  },
  getMaintenancesMembership: membresiaId => {
    dispatch(getMaintenancesMembership(membresiaId));
  },
  sendMembersTypesRules: (membresiaId, data) => {
    return dispatch(sendMembersTypesRules(membresiaId, data));
  },
  getMembersTypesRules: membresiaId => {
    dispatch(getMembersTypesRules(membresiaId));
  }
});

const mapStateToProps = state => {
  const { products } = state;
  const {
    membershipTypesMembers,
    memberships,
    maintenancesMembership,
    membersRules
  } = products;
  return {
    memberships: memberships && memberships.content,
    maintenancesMembership: maintenancesMembership,
    membershipTypesMembers: membershipTypesMembers,
    membersRules: membersRules
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnersPage);
