import React from "react";
import BaseInput from "../../../toolkit/baseInput";

export const UserSelector = ({
  value,
  disabled,
  onChange,
  users,
  size = "sm"
}) => (
  <BaseInput
    label="Responsable"
    name="responsable"
    type="select"
    id="responsable"
    placeholder="Responsable"
    value={value}
    disabled={disabled}
    size={size}
    onChange={e => {
      const value = e.target.value;
      return onChange(value);
    }}
    options={[
      {
        value: "",
        label: "Sin Responsable"
      }
    ].concat(
      users
        ? users.map(option => ({
            value: option.userId,
            label: option.username
          }))
        : []
    )}
  />
);
