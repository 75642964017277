import { faCheck } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import ReactTable from "react-table";
import { Button, Col, Container, Row } from "reactstrap";
import BaseButtonWithIconAndTooltip from "../../../../../toolkit/baseButtonWithIconAndTooltip";
import BaseInput from "../../../../../toolkit/baseInput";
import ModalFrame from "../../../../../toolkit/modalFrame";
import { Club } from "../../../../../types-business/Clubs";
import {
  handleRequestError,
  parseArrayToDropdownOptions
} from "../../../../../utils/helpers";
import {
  infoNotification,
  successNotification
} from "../../../../../utils/notifications";
import {
  copyBankCardRequest,
  getPartnerRequest,
  getPaymentMethodsRequest
} from "../../../../actions/partners";
import { Partner } from "../../interfaces";

interface Props {
  partner: Partner;
  idMembresiaSocio: string;
  isOpen: boolean;
  clubs: Club[];
  cancel: () => any;
  success: () => any;
}

interface State {
  clubId: number;
  publicoId: number;
  bankCards: any[];
}

class ImportBankCardModal extends React.Component<Props, State> {
  state = {
    clubId: null,
    publicoId: null,
    bankCards: []
  };

  columns = [
    { Header: "Nombre títular", accessor: "nombreTitular" },
    {
      Header: "Tipo",
      className: "text-center",
      accessor: "tipoMedioPago"
    },
    {
      Header: "Tipo tarjeta",
      className: "text-center",
      accessor: "tipoTarjeta"
    },
    {
      Header: "Banco",
      className: "text-center",
      accessor: "banco.nombre"
    },
    {
      Header: "Número",
      className: "text-center",
      accessor: "numero"
    },
    {
      Header: "Fecha vencimiento",
      className: "text-center",
      accessor: "fechaVencimiento"
    },
    {
      Header: "Opciones",
      id: "id",
      accessor: bank => (
        <Container className="w-100 text-center">
          <BaseButtonWithIconAndTooltip
            id={`payment-method-${bank.id}`}
            icon={faCheck}
            tooltipTitle="Copiar medio de pago"
            onClick={() => {
              this.copyBankcard(bank.id);
            }}
          />
        </Container>
      )
    }
  ];

  componentDidMount() {}

  copyBankcard = medioPagoId => {
    const { idMembresiaSocio } = this.props;

    infoNotification("Copiando medio de pago...");

    copyBankCardRequest(idMembresiaSocio, medioPagoId)
      .then(() => {
        successNotification("Medio de pago copiado correctamente");
        this.setState({ clubId: null, publicoId: null });
        this.props.success();
      })
      .catch(handleRequestError);
  };

  handleCancel = () => {
    this.setState({ clubId: null, publicoId: null });
    this.props.cancel();
  };

  search = () => {
    const { clubId, publicoId } = this.state;

    if (!clubId || !publicoId) {
      return;
    }

    const params = { idClub: clubId, idPublico: publicoId, basico: true };
    getPartnerRequest(params)
      .then(({ data }) => {
        const { idMembresiaSocio } = data;

        getPaymentMethodsRequest(idMembresiaSocio)
          .then(({ data: pmdata }) => {
            this.setState({
              bankCards: (pmdata || []).filter(
                x => x.estatus == "ACTIVO" && !!x.principal
              )
            });
          })
          .catch(handleRequestError);
      })
      .catch(handleRequestError);
  };

  render() {
    return (
      <ModalFrame
        title={`Copiar medio de pago desde membresía socio existente`}
        isOpen={this.props.isOpen}
        toggle={() => {}}
        size={"lg"}
      >
        <Container style={{ width: "90%" }} className="center-block p-0 mb-3">
          <div className="alert alert-warning">
            Al copiar el medio de pago específicado se darán de baja los medios
            de pago existentes.
          </div>

          <div className="mt-2">
            <Row>
              <Col>
                <BaseInput
                  label="Club"
                  name="club"
                  id="club"
                  type="select"
                  value={this.state.clubId}
                  onChange={e => {
                    const value = e.target.value;
                    this.setState({ clubId: value });
                  }}
                  options={parseArrayToDropdownOptions(
                    (this.props.clubs || []).filter(c => !!c.migradoCrm2) || [],
                    "clubId",
                    "nombre"
                  )}
                />
              </Col>
              <Col>
                <BaseInput
                  label={"# Membresia"}
                  name={"idMembresiaSocio"}
                  id={"idMembresiaSocio"}
                  placeholder="Id Membresia Socio"
                  value={this.state.publicoId}
                  size="sm"
                  onChange={e => {
                    const value = e.target.value;
                    this.setState({ publicoId: value });
                  }}
                />
              </Col>
              <Col>
                <br />
                <Button
                  size="sm"
                  className={`float-right btn-block`}
                  disabled={!this.state.clubId || !this.state.publicoId}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.search();
                  }}
                >
                  Buscar
                </Button>
              </Col>
            </Row>
          </div>

          <Row className="p-0 m-2">
            <ReactTable
              manual
              data={this.state.bankCards || []}
              columns={this.columns}
              className="w-100 -striped -highlight"
              style={{
                maxHeight: "400px"
              }}
              sortable={false}
              showPagination={false}
              defaultPageSize={3}
            />
          </Row>

          <Row className="m-2">
            <Col xs="12" md="7"></Col>
            <Col xs="12" md="5">
              <Button
                className={` mt-3`}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.handleCancel();
                }}
                block
              >
                Cerrar
              </Button>
            </Col>
          </Row>
        </Container>
      </ModalFrame>
    );
  }
}

export default ImportBankCardModal;
