import React from "react";
import { connect } from "react-redux";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import SaveNextCarousel from "../../components/saveNextCarousel";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import { COMPANIES_STEPS } from "../../../steps";
import * as companiesActions from "../../actions/companies";
import s from "./styles.scss";

interface Props {
  getCompanie: (id) => any;
  companie: object;
  history: any;
  match: any;
  location: any;
  index: number;
  component: any;
}

interface State {}

class ComponentWithMembershipName extends React.Component<any> {
  render() {
    const { Component, index, rest, companie } = this.props;
    return (
      <div className="w-100 p-0 m-0">
        {companie && (
          <p className={s.primaryTitle}>{companie.nombre.toUpperCase()}</p>
        )}
        <Component {...rest} {...this.props} key={index} />
      </div>
    );
  }
}

class CompaniesCarousel extends React.Component<Props, State> {
  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) this.props.getCompanie(id);
  }

  onClickNext = e => {
    e.preventDefault();
    this.props.history.push(COMPANIES_STEPS[this.props.index].getNextStep());
  };

  getCompanieId = () => this.props.match.params.id;

  render() {
    const id = this.getCompanieId();
    return (
      <main>
        <Helmet title="Nuevo Empresa" />
        <Layout>
          <SaveNextCarousel
            steps={COMPANIES_STEPS.map(({ title, getHref }) => ({
              title,
              href: id ? getHref(id) : null
            }))}
            onClickNext={this.onClickNext}
            index={this.props.index}
            disableNext={!this.props.match.params.id}
            activeStep={id ? COMPANIES_STEPS.length : 0}
            hiddenSaveButton={this.props.index == 5 ? true : false}
          >
            {COMPANIES_STEPS.map(({ component: Component, ...rest }, index) => {
              return (
                <ComponentWithMembershipName
                  Component={Component}
                  index={index}
                  rest={rest}
                  //companie={this.props.companie}
                  id={id}
                  key={index}
                />
              );
            })}
          </SaveNextCarousel>
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getCompanie: (id: string) => {
    dispatch(companiesActions.getCompanie(id));
  }
});

const mapStateToProps = state => {
  const { companies } = state;
  return {
    companie: companies.companie
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(s)(CompaniesCarousel));
