import {
  GetLockerProduct,
  GetLockersProducts,
  PutLockerExtension
} from "../../reducers/lockers";
import {
  getLockerProduct,
  getLockersProducts,
  putLockerExtension
} from "../../actions/lockers";
import { Preview } from "../../../types/IAction";
import { RequestArchivo } from "../../components/expedientsV2controls/types";
import { getArchivoVigente, postRefresh } from "../../actions/expedientes";

export interface LockerExtensionDispatcher {
  getLockersProducts: () => Promise<Preview<GetLockersProducts>>;
  getLockerProduct: (
    productoLockerId: string
  ) => Promise<Preview<GetLockerProduct>>;
  putLockerExtension: (body: any) => Promise<Preview<PutLockerExtension>>;

  getArchivoVigente: (idExpediente, idDocumento) => Promise<Preview<any>>;
  postRefresh: (requestArchivo: RequestArchivo) => Promise<Preview<any>>;
}

export const lockerExtensionDispatcher: (
  dispatch: (...args) => Promise<Preview<any>>
) => LockerExtensionDispatcher = dispatch => ({
  getLockersProducts: () => dispatch(getLockersProducts()),
  getLockerProduct: (productoLockerId: string) => {
    return dispatch(getLockerProduct(productoLockerId));
  },
  putLockerExtension: body => dispatch(putLockerExtension(body)),
  getArchivoVigente: (idExpediente, idDocumento): Promise<Preview<any>> => {
    return dispatch(getArchivoVigente(idExpediente, idDocumento));
  },
  postRefresh: (requestArchivo: RequestArchivo): Promise<Preview<any>> => {
    return dispatch(postRefresh(requestArchivo));
  }
});
