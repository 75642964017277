import React from "react";

import DatePicker from "../../common/DatePicker";
import BaseInput from "../../../../toolkit/baseInput";
import { Row, Col, Button } from "reactstrap";
import {
  MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES,
  PRICES_STATUS
} from "../../../../types/MassiveAuthorizationPricesFilter";
import s from "./styles.scss";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import moment from "moment";
import isEmpty from "lodash.isempty";

interface Props {
  fechaVigencia: string;
  estatusPrecio: PRICES_STATUS;
  tipoPrecio: string;
  estatus: { id: string; nombre: string }[];
  tiposPrecio: { esquemaPagoId: string; nombre: string }[];
  search: (filters: object) => any;
  onChange: (filters: object) => any;
  filterTypes: MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES[];
  defaultStatus: PRICES_STATUS;
  searchOnLoad: boolean;
  memberships: { nombre: string; datosBasicosMembresiaId: string }[];
}

class FiltersPriceDataMemberships extends React.PureComponent<Props> {
  state = {
    fechaVigencia: this.props.filterTypes.includes(
      MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES.DATE
    )
      ? moment().format("YYYY-MM-DD")
      : null,
    estatusPrecio: this.props.defaultStatus,
    tipoPrecio: "1",
    membresia: ""
  };

  componentDidMount() {
    this.props.onChange(this.state);
    this.props.searchOnLoad && this.search();
  }

  onChange = (type: MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES, event) => {
    const state = { ...this.state };
    switch (type) {
      case MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES.DATE:
        state.fechaVigencia = moment(event).format("YYYY-MM-DD");
        break;
      case MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES.ESTATUS:
        state.estatusPrecio = event.target.value;
        break;
      case MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES.PRICE_TYPE:
        state.tipoPrecio = event.target.value;
        break;
      case MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES.MEMBERSHIP:
        state.membresia = event.target.value;
        break;
      default:
        break;
    }
    this.props.onChange(state);
    this.setState(state);
  };

  onChangeMembership = event =>
    this.onChange(
      MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES.MEMBERSHIP,
      event
    );
  onChangeDate = date =>
    this.onChange(MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES.DATE, date);
  onChangeEstatus = event =>
    this.onChange(MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES.ESTATUS, event);
  onChangePriceType = event =>
    this.onChange(
      MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES.PRICE_TYPE,
      event
    );

  search = () => this.props.search(this.state);

  getDateInput = () => (
    <DatePicker
      label="Fecha de Vigencia"
      name="fechaVigenciaMembresiaPrecioFiltro"
      id="fechaVigenciaMembresiaPrecioFiltro"
      selected={this.state.fechaVigencia}
      onChange={this.onChangeDate}
      size="sm"
    />
  );

  getStatusInput = () => (
    <BaseInput
      label="Estatus"
      name="estatusMembresiaPrecioFiltro"
      type="select"
      id="estatusMembresiaPrecioFiltro"
      placeholder="Estatus"
      value={this.state.estatusPrecio}
      size="sm"
      onChange={this.onChangeEstatus}
      options={[{ value: "", label: "Seleccione el Estatus" }].concat(
        this.props.estatus.map(option => ({
          value: option.id,
          label: option.nombre
        }))
      )}
    />
  );

  getPriceTypeInput = () => (
    <BaseInput
      label="Tipo de Precio"
      name="tipoPrecioMembresiaPrecioFiltro"
      type="select"
      id="tipoPrecioMembresiaPrecioFiltro"
      placeholder="Tipo de Precio"
      value={this.state.tipoPrecio}
      size="sm"
      onChange={this.onChangePriceType}
      options={[{ value: "", label: "Seleccione el Tipo de Precio" }].concat(
        this.props.tiposPrecio.map(option => ({
          value: option.esquemaPagoId,
          label: option.nombre
        }))
      )}
    />
  );

  getMembershipInput = () => (
    <BaseInput
      label="Membresia"
      name="membresiaSelector"
      type="select"
      id="membresiaSelector"
      placeholder="Membresia"
      value={this.state.membresia}
      size="sm"
      onChange={this.onChangeMembership}
      options={[{ value: "", label: "Seleccione la Membresia" }].concat(
        this.props.memberships === null
          ? []
          : this.props.memberships.map(option => ({
              value: option.datosBasicosMembresiaId,
              label: option.nombre
            }))
      )}
    />
  );

  render() {
    return (
      <Row>
        {this.props.filterTypes.map(filter => {
          let f;
          switch (filter) {
            case MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES.DATE:
              f = this.getDateInput;
              break;
            case MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES.ESTATUS:
              f = this.getStatusInput;
              break;
            case MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES.PRICE_TYPE:
              f = this.getPriceTypeInput;
              break;
            case MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES.MEMBERSHIP:
              f = this.getMembershipInput;
              break;
            default:
              f = null;
              break;
          }

          return <Col>{f && f()}</Col>;
        })}
        <Col>
          <Button
            size="sm"
            className={`${s.searchButton} ${s.primaryButton} float-right btn-block`}
            onClick={this.search}
            disabled={this.props.filterTypes.some(filter => {
              switch (filter) {
                case MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES.DATE:
                  return isEmpty(this.state.fechaVigencia);
                case MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES.ESTATUS:
                  return isEmpty(this.state.estatusPrecio);
                case MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES.PRICE_TYPE:
                  return isEmpty(this.state.tipoPrecio);
                case MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES.MEMBERSHIP:
                  return false;
                default:
                  return true;
              }
            })}
          >
            Buscar
          </Button>
        </Col>
      </Row>
    );
  }
}

export default withStyles(s)(FiltersPriceDataMemberships);
