import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import LockerExtensionPage from "../../components/lockerExtension";
import {
  ReduxConnect,
  Router,
  RouterProps
} from "../../../toolkit/decorators/Artifact";
import {
  lockerExtensionDispatcher,
  LockerExtensionDispatcher
} from "./LockerExtension.dispatcher";
import {
  lockerExtensionStore,
  LockerExtensionStore
} from "./LockerExtension.store";

interface Props
  extends LockerExtensionDispatcher,
    LockerExtensionStore,
    RouterProps<any> {}

interface State {}

@Router()
@ReduxConnect(lockerExtensionStore, lockerExtensionDispatcher)
export default class LockerExtensionContainer extends React.Component<
  Props,
  State
> {
  render() {
    return (
      <main>
        <Helmet title="Companies" />
        <Layout>
          <LockerExtensionPage
            {...this.props}
            idProductoLocker={this.props.match.params.idProductoLocker}
          />
        </Layout>
      </main>
    );
  }
}
