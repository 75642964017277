import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import {
  infoNotification,
  successNotification,
  errorNotification,
  CREATE_PROSPECT_WAITING,
  CREATE_PROSPECT,
  PATCH_PROSPECT_WAITING,
  PATCH_PROSPECT_ERROR,
  PATCH_PROSPECT,
  DELETE_PROSPECT_WAITING,
  DELETE_PROSPECT_ERROR,
  DELETE_PROSPECT,
  REASSIGN_PROSPECT_PENDING,
  REASSIGN_PROSPECT_ERROR,
  REASSIGN_PROSPECT,
  MEMBERSHIP_PURCHASE_REJECTED,
  ERROR,
  ERROR_SALE
} from "../../utils/notifications";
import {
  getErrorMessageProspectCreation,
  getErrorMessageSale
} from "../../utils/helpers";
import { readMessageBack } from "../../utils/readMessageBack";
interface State {
  savingReassignment: boolean;
  saveReassignmentError: boolean;
  loadingQuotations: boolean;
  quotations: object[];
  actualQuote: object[];
  futureChargesQuote: object[];
  successfullPurchase: object;
  creatingProspectError: object;
  creatingProspect: boolean;
  creatingProspectValues: any;
  newProspectCreated: boolean;
  patchProspect: boolean;
  patchProspectError: object;
  deletingProspect: boolean;
  deletingProspectError: object;
}
const initialState: State = {
  savingReassignment: false,
  saveReassignmentError: false,
  loadingQuotations: false,
  quotations: null,
  successfullPurchase: null,
  creatingProspectError: null,
  creatingProspect: false,
  newProspectCreated: false,
  patchProspect: false,
  patchProspectError: null,
  deletingProspect: false,
  deletingProspectError: null,
  actualQuote: null,
  futureChargesQuote: null
};

export default function sellersReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.GET_PROSPECTS}_FULFILLED`: {
      return {
        ...state,
        newProspectCreated: false
      };
    }
    case `${actionTypes.DELETE_PROSPECT}_PENDING`: {
      infoNotification(DELETE_PROSPECT_WAITING);
      return {
        ...state,
        deletingProspect: true
      };
    }
    case `${actionTypes.DELETE_PROSPECT}_REJECTED`: {
      errorNotification(readMessageBack(action, DELETE_PROSPECT_ERROR));
      return {
        ...state,
        deletingProspectError: action.payload || {},
        deletingProspect: false
      };
    }
    case `${actionTypes.DELETE_PROSPECT}_FULFILLED`: {
      successNotification(DELETE_PROSPECT);
      return {
        ...state,
        deletingProspectError: null,
        deletingProspect: false,
        newProspectCreated: true
      };
    }
    case `${actionTypes.PATCH_PROSPECT}_PENDING`: {
      infoNotification(PATCH_PROSPECT_WAITING);
      return {
        ...state,
        patchProspect: true
      };
    }
    case `${actionTypes.PATCH_PROSPECT}_REJECTED`: {
      errorNotification(readMessageBack(action, PATCH_PROSPECT_ERROR));
      return {
        ...state,
        patchProspectError: action.payload,
        patchProspect: false
      };
    }
    case `${actionTypes.PATCH_PROSPECT}_FULFILLED`: {
      successNotification(PATCH_PROSPECT);
      return {
        ...state,
        patchProspectError: null,
        patchProspect: false,
        newProspectCreated: true
      };
    }
    case `${actionTypes.CREATE_PROSPECT}_PENDING`: {
      infoNotification(CREATE_PROSPECT_WAITING);
      return {
        ...state,
        creatingProspect: true,
        creatingProspectValues: null,
        creatingProspectError: null
      };
    }
    case `${actionTypes.CREATE_PROSPECT}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        creatingProspect: false,
        creatingProspectValues: null,
        creatingProspectError: action.payload
      };
    }
    case `${actionTypes.CREATE_PROSPECT}_FULFILLED`: {
      successNotification(CREATE_PROSPECT);
      return {
        ...state,
        creatingProspect: false,
        creatingProspectValues: action.payload.data,
        creatingProspectError: null,
        newProspectCreated: true
      };
    }
    case `${actionTypes.SAVE_REASSIGNMENT}_PENDING`: {
      infoNotification(REASSIGN_PROSPECT_PENDING);
      return {
        ...state,
        savingReassignment: true,
        saveReassignmentError: false
      };
    }
    case `${actionTypes.SAVE_REASSIGNMENT}_REJECTED`: {
      errorNotification(readMessageBack(action, REASSIGN_PROSPECT_ERROR));
      return {
        ...state,
        savingReassignment: false,
        saveReassignmentError: true
      };
    }
    case `${actionTypes.SAVE_REASSIGNMENT}_FULFILLED`: {
      successNotification(REASSIGN_PROSPECT);
      return {
        ...state,
        savingReassignment: false,
        saveReassignmentError: false
      };
    }
    case `${actionTypes.GET_QUOTATIONS}_PENDING`: {
      return {
        ...state,
        successfullPurchase: null,
        loadingQuotations: true,
        quotations: null
      };
    }
    case `${actionTypes.GET_QUOTATIONS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        loadingQuotations: false,
        quotations: null
      };
    }
    case `${actionTypes.GET_QUOTATIONS}_FULFILLED`: {
      return {
        ...state,
        loadingQuotations: false,
        quotations: action.payload.data
      };
    }
    case `${actionTypes.GET_ACTUAL_QUOTE}_PENDING`: {
      return {
        ...state,
        successfullPurchase: null,
        loadingQuotations: true,
        actualQuote: null
      };
    }
    case `${actionTypes.GET_ACTUAL_QUOTE}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        loadingQuotations: false,
        actualQuote: null
      };
    }
    case `${actionTypes.GET_ACTUAL_QUOTE}_FULFILLED`: {
      return {
        ...state,
        loadingQuotations: false,
        actualQuote: action.payload.data
      };
    }
    case `${actionTypes.GET_FUTURE_QUOTE}_PENDING`: {
      return {
        ...state,
        futureChargesQuote: null
      };
    }
    case `${actionTypes.GET_FUTURE_QUOTE}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        futureChargesQuote: null
      };
    }
    case `${actionTypes.GET_FUTURE_QUOTE}_FULFILLED`: {
      return {
        ...state,
        futureChargesQuote: action.payload.data
      };
    }
    case `${actionTypes.MEMBERSHIP_PURCHASE}_PENDING`: {
      return {
        ...state,
        successfullPurchase: null
      };
    }
    case `${actionTypes.MEMBERSHIP_PURCHASE}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR_SALE));
      return {
        ...state,
        successfullPurchase: null
      };
    }
    case `${actionTypes.MEMBERSHIP_PURCHASE}_FULFILLED`: {
      return {
        ...state,
        successfullPurchase: action.payload.data
      };
    }
    case `${actionTypes.CLEAN_SUCCESSFULL_PURCHASE}`: {
      return {
        ...state,
        successfullPurchase: null
      };
    }
    case `${actionTypes.VALIDATE_REFERAL_CODE}_PENDING`: {
      return {
        ...state
      };
    }
    case `${actionTypes.VALIDATE_REFERAL_CODE}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state
      };
    }
    case `${actionTypes.VALIDATE_REFERAL_CODE}_FULFILLED`: {
      return {
        ...state
      };
    }
    case `${actionTypes.CLEAR_CREATE_PROSPECT_VARIABLE}`: {
      return {
        ...state,
        newProspectCreated: false
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
