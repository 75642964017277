import React from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./styles.scss";
import { FormErrors } from "@franleplant/reform/types";
import IAction from "../../../types/IAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faTrash } from "@fortawesome/free-solid-svg-icons";
import ReactTable from "react-table";
import BaseInput from "../../../toolkit/baseInput";
import isNil from "lodash.isnil";
import { handleRequestError } from "../../../utils/helpers";
import { deletePayrollConceptsRequest } from "../../actions/products";

interface Props {
  payrollConcepts: object[];
  payrollConceptsSelect: object[];
  getPayrollConceptsSelect: (
    clubId: string,
    productoConcepto: string
  ) => IAction;
  reloadData: () => IAction;
  sendPayrollConceptsSave: (data: object) => IAction;
  id: string;
  savePayrollConcept: boolean;
  sportProduct: any;
}

interface State {
  errors: FormErrors;
  clubId: string;
  conceptoNominaId: string;
  reload: boolean;
  tipoComportamiento: string;
}

class priceDataSportsProducts extends React.Component<Props, State> {
  state = {
    errors: {},
    clubId: "",
    conceptoNominaId: "",
    reload: false,
    tipoComportamiento: null
  };

  componentDidMount() {}

  componentDidUpdate() {
    if (this.props.savePayrollConcept && this.state.reload) {
      this.props.reloadData();
      this.setState({ reload: false, clubId: "", conceptoNominaId: "" });
    }
    if (
      this.props.sportProduct &&
      this.props.sportProduct.hasOwnProperty("producto") &&
      isNil(this.state.tipoComportamiento)
    ) {
      let tipoComportamiento = "";
      if (
        this.props.sportProduct.producto.tipoComportamiento ==
        "PROGRAMA_DEPORTIVO"
      ) {
        tipoComportamiento = "PROGRAMA_DEPORTIVO";
      } else if (
        this.props.sportProduct.producto.tipoComportamiento ==
        "CLASES_PERSONALIZADAS"
      ) {
        tipoComportamiento = "CLASE_PERSONALIZADA";
      }
      this.setState({ tipoComportamiento });
    }
  }

  columns = [
    {
      Header: "Selector",
      id: 0,
      className: "text-center",
      width: 70,
      accessor: "id",
      Cell: row => {
        return (
          <a
            id={`faTag${row.original.clubId}`}
            onClick={e => {
              e.preventDefault();
              this.props.getPayrollConceptsSelect(
                row.original.clubId,
                this.state.tipoComportamiento
              );
              this.setState({
                clubId: row.original.clubId,
                conceptoNominaId: row.original.hasOwnProperty(
                  "conceptoNominaId"
                )
                  ? row.original.conceptoNominaId
                  : ""
              });
            }}
            style={{ cursor: "pointer" }}
          >
            <FontAwesomeIcon icon={faCog} />
          </a>
        );
      }
    },
    {
      Header: "Desasociar",
      id: 0,
      className: "text-center",
      width: 70,
      accessor: "id",
      Cell: row => {
        return !!row.original.conceptoNominaId ? (
          <a
            id={`deleteTag${row.original.clubId}`}
            onClick={e => {
              e.preventDefault();
              deletePayrollConceptsRequest({
                productoId: this.props.id,
                clubId: row.original.clubId
              })
                .then(() => {
                  this.props.reloadData();
                })
                .catch(handleRequestError);
            }}
            style={{ cursor: "pointer" }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </a>
        ) : (
          <span></span>
        );
      }
    },
    {
      Header: "Club",
      accessor: "clubNombre",
      className: "text-center",
      width: 200
    },
    {
      Header: "Concepto",
      accessor: "conceptoNominaId",
      className: "text-center",
      width: 280,
      Cell: row => {
        return row.original.hasOwnProperty("conceptoNominaId") ? (
          <p className={s.initial}>
            {" "}
            {row.original.conceptoNominaNombre +
              " - " +
              row.original.conceptoNominaDescripcion}{" "}
          </p>
        ) : null;
      }
    }
  ];

  saveData = () => {
    let data = {
      productoId: this.props.id,
      clubId: this.state.clubId,
      conceptoNominaId: this.state.conceptoNominaId
    };
    this.props.sendPayrollConceptsSave(data);
    this.setState({ reload: true });
  };

  onChangeRequiredFilters = async (e, fieldName) => {
    const value = e.target.value;
    if (fieldName == "clubId") {
      this.props.getPayrollConceptsSelect(value, this.state.tipoComportamiento);
    }
    await this.setState(state => {
      return { ...state, [fieldName]: value };
    });
  };

  render() {
    return (
      <div className="p-0 m-0 w-100 h-100">
        <Container
          className="w-100 p-0 m-0"
          style={{ height: "650px" }}
          xs={12}
        >
          <Row>
            <Col xs={6}>
              <ReactTable
                data={this.props.payrollConcepts}
                columns={this.columns}
                sortable={false}
                resizable={false}
                showPagination={true}
                defaultPageSize={10}
                className="mt-3 -striped -highlight"
              />
            </Col>
            <Col xs={6}>
              <Row>
                <Col />
                <Col xs={8}>
                  <BaseInput
                    label="Club"
                    name="clubId"
                    id="clubId"
                    type="select"
                    placeholder="Seleccionar Club"
                    value={this.state.clubId}
                    onChange={e => this.onChangeRequiredFilters(e, "clubId")}
                    size={"sm"}
                    options={[{ value: "", label: "Seleccionar Club" }].concat(
                      this.props.payrollConcepts &&
                        this.props.payrollConcepts.length > 0
                        ? this.props.payrollConcepts.map(option => ({
                            value: option.clubId,
                            label: option.clubNombre.toUpperCase()
                          }))
                        : []
                    )}
                  />
                </Col>
                <Col />
              </Row>
              <Row>
                <Col />
                <Col xs={8}>
                  <BaseInput
                    label="Concepto"
                    name="conceptoNominaId"
                    id="conceptoNominaId"
                    type="select"
                    placeholder="Seleccionar concepto"
                    value={this.state.conceptoNominaId}
                    onChange={e =>
                      this.onChangeRequiredFilters(e, "conceptoNominaId")
                    }
                    size={"sm"}
                    options={[
                      { value: "", label: "Seleccionar Concepto" }
                    ].concat(
                      this.props.payrollConceptsSelect
                        ? this.props.payrollConceptsSelect.map(option => ({
                            value: option.id,
                            label: option.conceptoId + "-" + option.descripcion
                          }))
                        : []
                    )}
                  />
                </Col>
                <Col />
              </Row>
              <Row>
                <Col />
                <Col xs={8}>
                  <Button
                    className={`${s.primaryButton} btn-block`}
                    size="sm"
                    onClick={e => {
                      e.preventDefault();
                      this.saveData();
                    }}
                    disabled={
                      this.state.clubId.length == 0 ||
                      this.state.conceptoNominaId.length == 0
                        ? true
                        : false
                    }
                  >
                    Guardar
                  </Button>
                </Col>
                <Col />
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(withStyles(s)(priceDataSportsProducts));
