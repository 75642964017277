import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import LockerAssigned from "../../components/lockersAssigned";
import {
  getAssignedByClubId,
  getClubFile,
  printExpirationNotification
} from "../../actions/lockers";
import { showDocument } from "../../actions/documents";
import { getClubs } from "../../actions/catalogs";
import { QueryResponse } from "../../../types/QueryResponse";
import { QueryArguments } from "../../../types-business/QueryArguments";
import CONSTANTS from "../../../utils/constants";

interface Locker {
  id: number;
  clubId: string;
  vestidor: string;
  pared: string;
  fechaAdquisicion: string;
  fechaVencimiento: string;
  estatus: string;
  personaId: string;
  personaNombre: string;
  locker: string;
  pdfStorageId: number;
}

interface Props {
  clubs: any;
  lockersAssigned: QueryResponse<Locker>;
  getAssignedByClubId: (args?: QueryArguments) => any;
  getClubFile: (clubId: number) => any;
  printExpirationNotification: (assignmentId: number) => any;
  getClubs: () => any;
  showDocument: (pdfStorageid: number) => any;
  match: any;
  searching: boolean;
  history: any;
  userContext: any;
}

interface State {
  lockersAssigned: object;
  filters: { clubId: Number };
}

class LockerAssignedPage extends React.Component<Props, State> {
  state = {
    lockersAssigned: {
      content: [],
      totalPages: 0
    },
    filters: {
      clubId: -1,
      size: CONSTANTS.DEFAULT_PAGE_SIZE,
      page: 0
    }
  };

  onChangeFilter = (value, name) => {
    const filters = {
      ...this.state.filters,
      [`${name}`]: value ? value : null
    };
    this.setState(state => ({
      ...state,
      filters
    }));
  };

  onFetchData = ({ pageSize: size, page }) => {
    const filters = { ...this.state.filters, size, page };
    this.setState({ filters });

    if (!!filters.clubId && filters.clubId > 0) {
      this.props.getAssignedByClubId(filters);
    }
  };

  onClickPrintExpirationNotification = (locker: Locker) => {
    this.props.printExpirationNotification(locker.id);
  };

  componentDidMount() {
    this.props.getClubs();

    this.setState({
      filters: {
        ...this.state.filters,
        clubId:
          (this.props.userContext && this.props.userContext.clubSessionId) || -1
      }
    });
  }

  render() {
    return (
      <main>
        <Helmet title="Lockers asignados" />
        <Layout>
          <LockerAssigned
            clubsCatalog={this.props.clubs}
            lockers={this.props.lockersAssigned}
            searching={this.props.searching}
            filters={this.state.filters}
            onChangeFilter={this.onChangeFilter}
            onClickPrintExpirationNotification={
              this.onClickPrintExpirationNotification
            }
            getClubFile={this.props.getClubFile}
            fetchData={this.onFetchData}
            getAssignedByClubId={this.props.getAssignedByClubId}
            showDocument={this.props.showDocument}
            userContext={this.props.userContext}
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = state => {
  const { lockers, catalogs } = state;
  return {
    lockersAssigned: lockers.lockersAssigned,
    searching: lockers.gettingLockersAssigned,
    clubs: catalogs.clubs,
    userContext: state.user.userContext
  };
};

const mapDispatchToProps = {
  getAssignedByClubId,
  getClubFile,
  getClubs,
  printExpirationNotification,
  showDocument
};
/*const mapDispatchToProps = dispatch => ({
  getAssignedByClubId: (args?: QueryArguments) => {
    dispatch(lockersActions.getAssignedByClubId(args));
  },
  getClubs: () => {
    dispatch(catalogActions.getClubs());
  }
});*/

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LockerAssignedPage);
