import React from "react";
import { Container, Row } from "reactstrap";
import MexicanCurrency from "../../../Home/components/common/currency";
import moment from "moment";

const getEndDatePromotion = (promotion?: any) => {
  return promotion && promotion.finVigencia
    ? moment(promotion.finVigencia).format("DD/MM/YYYY")
    : null;
};

export const QuotationTotals = ({ quotation, membership, promocion }) => (
  <Container className="p-0">
    <Row className="mt-1 m-0 alert alert-secondary row p-0 w-100" role="alert">
      <table className="table m-0">
        <tbody>
          <tr>
            <th scope="row">PRECIO</th>
            <td>
              {" "}
              {quotation ? (
                <MexicanCurrency
                  quantity={
                    quotation.precioMembresia ? quotation.precioMembresia : 0
                  }
                />
              ) : null}
            </td>
          </tr>
          <tr>
            <th scope="row">PORCENTAJE MÁXIMO DE DESCUENTO</th>
            <td>
              {quotation && quotation.maximoDescuento
                ? `${quotation.maximoDescuento}%`
                : null}
            </td>
          </tr>
          <tr>
            <th scope="row">PRECIO MINIMO DE VENTA</th>
            <td>
              {quotation ? (
                <MexicanCurrency
                  quantity={
                    quotation.precioMembresia && quotation.maximoDescuento
                      ? quotation.precioMembresia *
                        (1 - quotation.maximoDescuento / 100)
                      : quotation.precioMembresia
                  }
                />
              ) : null}
            </td>
          </tr>
          <tr>
            <th scope="row">INTEGRANTES</th>
            <td>
              {membership && membership.integrantes
                ? membership.integrantes
                : null}
            </td>
          </tr>
          <tr>
            <th scope="row">FECHA FIN DE LA PROMOCION</th>
            <td>{getEndDatePromotion(promocion)}</td>
          </tr>
        </tbody>
      </table>
    </Row>
  </Container>
);
