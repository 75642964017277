import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import ReactTable from "react-table";
import { Container, Row, Col, Button, Form } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import { parseArrayToDropdownOptions } from "../../../../src/utils/helpers";
import constants from "../../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { infoNotification } from "../../../utils/notifications";

interface Props {
  personalDocumentsHistory: any;
  searching: boolean;
  history: any;
  filters: Filters;
  onChangeFilter: any;
  getPersonalDocumentsHistory: (idClub: any, idPublico: any) => any;
  getPartner: (params: object) => any;
  clubs: object[];
}

interface Filters {
  idClub: string;
  idPublico: string;
}

interface Partner {
  nombrePersona: string;
  nombrePropietario: string;
  nombreMembresia: string;
  nombreConvenio: string;
  certificado: boolean;
  estatusMembresia: string;
  fechaAdquisicion: string;
  fechaInicioMantenimiento: string;
  idClubAlterno: string;
  idClubBase: string;
  idMembresia: string;
  idMantenimiento: string;
  idMembresiaSocio: string;
  idPersona: string;
  idPropietario: string;
  intransferible: boolean;
  limiteInicioMantenimiento: string;
  nueva: boolean;
  precioBaseMembresia: string;
  precioVentaMembresia: string;
  ventaEnLinea: boolean;
  membershipDocuments: object[];
  nombreMantenimiento: string;
  returnInvoiceList: object[];
  expedienteAutorizado: boolean;
  idPublico: number;
}

interface State {
  partner: Partner;
}

const DOCUMENTS_BASE_URL = "api/partners/membership-partner/documents";

class PersonalDocumentsHistory extends React.Component<Props, State> {
  state = {
    partner: null
  };

  search = () => {
    infoNotification("Buscando expediente, espere un momento por favor");
    this.props.getPersonalDocumentsHistory(
      this.props.filters.idClub,
      this.props.filters.idPublico
    );
  };

  onChangeFactory = (event, fieldName) => {
    const value = event.target.value;
    this.props.onChangeFilter(value, fieldName);
  };

  render() {
    return (
      <Container className="p-0 mt-3">
        <Form
          onSubmit={e => {
            e.preventDefault();
            this.search();
          }}
        >
          <Row>
            <Col>
              <BaseInput
                label="Club"
                name="club"
                id="club"
                type="select"
                //value={this.state.filters.idClub}
                onChange={idClub => this.onChangeFactory(idClub, "idClub")}
                options={parseArrayToDropdownOptions(
                  this.props.clubs || [],
                  "clubId",
                  "nombre"
                )}
              />
            </Col>
            <Col>
              <BaseInput
                label={"# Membresia"}
                name={"idMembresiaSocio"}
                id={"idMembresiaSocio"}
                placeholder="Id Membresia Socio"
                //value={this.state.filters.idPublico}
                size="sm"
                onChange={idPublico =>
                  this.onChangeFactory(idPublico, "idPublico")
                }
              />
            </Col>
            <Col>
              <br />
              <Button
                size="sm"
                className={`float-right btn-block`}
                type="submit"
                disabled={
                  !this.props.filters.idClub || !this.props.filters.idPublico
                }
              >
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
        <ReactTable
          data={(this.props.personalDocumentsHistory &&
          this.props.personalDocumentsHistory.items
            ? this.props.personalDocumentsHistory.items
            : []
          ).filter(d => d.completo)}
          columns={columns}
          sortable={false}
          resizable={true}
          pageSize={20}
          showPagination={true}
        />
      </Container>
    );
  }
}

const columns = [
  { Header: "Documento", id: "nombre", accessor: "nombre" },
  { Header: "Dueño", id: "nombreCompleto", accessor: "nombreCompleto" },
  { Header: "Fecha de firma", id: "fechaFirma", accessor: "fechaFirma" },
  {
    Header: "Ver",
    id: "ver",
    accessor: row => {
      const storage = row.storage;

      return storage ? (
        <div
          style={{ cursor: "pointer", textAlign: "center" }}
          onClick={() =>
            window.open(
              `${constants.BASE_URL}/${DOCUMENTS_BASE_URL}/${row.storage}`,
              "_blank"
            )
          }
        >
          <FontAwesomeIcon icon={faFileDownload} color="black" />
        </div>
      ) : (
        <div>No existe</div>
      );
    }
  }
];

const actions = [
  {
    name: "view",
    f: data =>
      window.open(
        `${constants.BASE_URL}/${DOCUMENTS_BASE_URL}/${data.storage}`,
        "_blank"
      )
  }
];

export default withRouter(withStyles(s)(PersonalDocumentsHistory));
