import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import Reform from "@franleplant/reform";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import BaseInput from "../../../toolkit/baseInput";
import { Button, Alert } from "reactstrap";
import {
  Container,
  Form,
  Row,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import { WHO_AM_I_STATE } from "../../../types-business/Profile";
import BasePasswordInput from "../../../toolkit/basePasswordInput";
import isNil from "lodash.isnil";

interface Props {
  loginSSP: (password: string, userName: string, ssp?: object) => any;
  login: (password: string, userName: string) => any;
  whoami: () => any;
  ssp: Array<{ userName: string; id: number }>;
  loginUser: boolean;
  loginUserError: any;
  history: any;
  successfullLogin: boolean;
  iKnowWhoIAm: WHO_AM_I_STATE;
  logoutUser: () => any;
  securitySpacesError: any;
  gettingSecuritySpaces: boolean;
  successfullSecuritySpaces: boolean;
}

interface State {
  fields: {
    userName: string;
    password: string;
  };
  dropdownOpen: boolean;
  selectedSSP: object;
  loginEnded: boolean;
}

class Home extends React.Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);

  state = {
    fields: {
      userName: "",
      password: ""
    },
    dropdownOpen: false,
    selectedSSP: null,
    errors: {},
    loginEnded: false
  };

  validationRules = {
    userName: { required: true, maxLength: 255 },
    password: { required: true, minLength: 4 }
  };

  validationMessages = {
    required: _ => `Obligatorio`,
    minLength: ruleValue =>
      `Debe ser de al menos ${ruleValue} caracteres de largo`,
    maxLength: ruleValue =>
      `Debe ser de menos de ${ruleValue} caracteres de largo`,
    default: _ => `Inválido`
  };

  onChangeFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };

        return { ...state, error: {}, fields };
      });
      this.re.validateField(fieldName, value);
    };
  };

  onSubmit = e => {
    e.preventDefault();

    if (this.re.validateFormFromState()) {
      if (!this.state.selectedSSP) {
        this.props.login(
          this.state.fields.userName,
          this.state.fields.password
        );
      } else {
        this.props.loginSSP(
          this.state.fields.userName,
          this.state.fields.password,
          this.state.selectedSSP
        );
      }
    }
  };

  toggle = () => {
    this.setState(state => ({ dropdownOpen: !state.dropdownOpen }));
  };

  selectSecureSpace = option => {
    this.setState(() => ({ selectedSSP: option }));
  };

  componentDidMount() {
    this.props.whoami();
  }

  componentDidUpdate() {
    if (
      !this.state.selectedSSP &&
      this.props.ssp &&
      this.props.ssp.length <= 1 &&
      !this.props.loginUserError
    ) {
      let selectedSSP = { id: -1, userName: "" };
      if (this.props.ssp.length === 1) {
        selectedSSP = this.props.ssp[0];
      }
      this.props.loginSSP(
        this.state.fields.userName,
        this.state.fields.password,
        selectedSSP
      );
    }

    if (
      this.props.successfullLogin ||
      this.props.iKnowWhoIAm === WHO_AM_I_STATE.SUCCESS
    ) {
      this.props.history.push("/inicio");
    }
  }

  disableForm = () => !!this.props.ssp;

  render() {
    const dropdownOptions = options => {
      return options.map(option => (
        <DropdownItem
          onClick={() => this.selectSecureSpace(option)}
          key={option.id}
        >
          {option.name}
        </DropdownItem>
      ));
    };

    return (
      <Container>
        <Row className={["justify-content-md-center", s.marginTop].join(" ")}>
          <Form className={["col-xs-12 col-md-4", s.form].join(" ")}>
            <p className={s.title}>Iniciar Sesión</p>
            <BaseInput
              label={"Nombre de Usuario"}
              name="userName"
              id="username"
              placeholder="Nombre de Usuario"
              value={this.state.fields.userName}
              dataQAId={"user_login_username"}
              onChange={this.onChangeFactory("userName")}
              errors={this.re.mapFieldErrors("userName")}
              disabled={this.disableForm()}
            />
            <BasePasswordInput
              label={"Contraseña"}
              name="password"
              id="password"
              placeholder="Contraseña"
              value={this.state.fields.password}
              dataQAId={"user_login_password"}
              onChange={this.onChangeFactory("password")}
              errors={this.re.mapFieldErrors("password")}
              disabled={this.disableForm()}
            />
            {this.props.ssp && this.props.ssp.length > 1 && (
              <Dropdown
                className={s.formGroup}
                isOpen={this.state.dropdownOpen}
                toggle={this.toggle}
              >
                <DropdownToggle className="w-100" caret>
                  {this.state.selectedSSP
                    ? this.state.selectedSSP.name
                    : "Seleccione un Club"}
                </DropdownToggle>
                <DropdownMenu className="w-100">
                  {dropdownOptions(this.props.ssp)}
                </DropdownMenu>
              </Dropdown>
            )}
            <div className={s.formGroup}>
              <Button
                className={s.primaryButton}
                onClick={this.onSubmit}
                size="lg"
                disabled={
                  this.props.gettingSecuritySpaces || this.props.loginUser
                }
                block
                type="submit"
              >
                {this.props.loginUser ? "Validando" : "Iniciar Sesión"}
              </Button>
            </div>
            {(this.props.loginUserError || this.props.securitySpacesError) && (
              <span>
                <hr />
                <Alert color="danger">
                  {(this.props.loginUserError &&
                    this.props.loginUserError.error_description) ||
                    (this.props.securitySpacesError &&
                      this.props.securitySpacesError.error_description)}
                </Alert>
              </span>
            )}
            {/* <div className={s.formGroup}>
              <a href="/membresias-externas" target="_blank">
                Consulta de membresías externas e invitados especiales de CRM1
              </a>
            </div> */}
            <div style={{ textAlign: "center" }} className={s.formGroup}>
              <a
                style={{ fontWeight: "bold", textDecoration: "underline" }}
                href="/recovery-request"
              >
                Olvidé mi contraseña
              </a>
            </div>
          </Form>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(Home));
