import FormBuilderConfig from "../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import ITextBuilder from "../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";
import IButtonBuilder from "../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import ISelectBuilder from "../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";

type Inputs = "esTitular" | "nombre" | "correo";
type Buttons = "aceptar" | "cancelar";

export const catFormConfig: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "esTitular",
      label: "* El socio es el titular de la tarjeta",
      bts: {
        xCol: 12
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Seleccione",
        data: [{ value: "1", label: "Sí" }, { value: "0", label: "No" }]
      }
    },
    {
      name: "nombre",
      label: "Nombre del titular de la tarjeta",
      bts: {
        xCol: 12
      },
      component: ITextBuilder,
      props: {
        disabled: values => values.esTitular == "1"
      }
    },
    {
      name: "correo",
      label: "Correo del titular de la tarjeta",
      bts: {
        xCol: 12
      },
      component: ITextBuilder,
      props: {
        disabled: values => values.esTitular == "1"
      }
    }
  ],
  buttons: [
    {
      name: "aceptar",
      label: "Aceptar",
      isSubmit: true,
      bts: {
        mCol: 3,
        mOff: 6
      },
      component: IButtonBuilder
    },
    {
      name: "cancelar",
      label: "Cancelar",
      isSubmit: false,
      bts: {
        mCol: 3
      },
      component: IButtonBuilder
    }
  ],
  validations: {
    nombre: {
      requiredIfTitular: {
        validator: (value, formValue) => !value && formValue.esTitular !== "1",
        message: () => "Obligatorio para firma de documento"
      }
    },
    correo: {
      email: true,
      requiredIfTitular: {
        validator: (value, formValue) => !value && formValue.esTitular !== "1",
        message: () => "Obligatorio para firma de documento"
      }
    }
  },
  rows: [["esTitular", "nombre", "correo", "aceptar", "cancelar"]]
};
