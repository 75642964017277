import React from "react";
import { connect } from "react-redux";
import Layout from "../../components/layout";
import { Helmet } from "react-helmet";
import ComissionsSummaryReport from "../../components/comissionsSummaryReport";

interface Props {}

interface State {
  comissionsSummaryReport: any[];
}

class ComissionsSummaryReportPage extends React.Component<Props, State> {
  state = {
    comissionsSummaryReport: []
  };

  componentDidUpdate() {}

  render() {
    return (
      <main>
        <Layout>
          <Helmet title="Reporte de comisiones por vendedor" />
          <ComissionsSummaryReport />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({});

const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComissionsSummaryReportPage);
