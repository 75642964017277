import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Container, Input, Button, Row } from "reactstrap";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SelectClubsModal from "./selectClubsModal";
import { isNumber } from "util";
import concat from "lodash.concat";
import { setChildStep } from "../../helpers/stepper-state-comparator";

interface Props {
  match: any;
  history: any;
  successfulSave: boolean;
  validClubs: object[];
  onClickSave: (e: object) => any;
  setClubs: () => any;
  updateGroupsSportsProduct: (id: string, groups: object[]) => any;
  getGroupsSportsProduct: (id: string) => any;
  productGroups: Array<{
    clubes: Array<{ nombre: string; clubId: number }>;
    nombre: string;
    id: number;
  }>;
}

interface State {
  groups: Array<{
    id: number;
    nombre: string;
    clubes: Array<{ clubId: number; nombre: string }>;
  }>;
  isOpenModal: boolean;
  index: number;
}

class AddGroupsToSportsProducts extends React.Component<Props, State> {
  state = {
    groups: [],
    isOpenModal: false,
    index: -1
  };

  componentDidMount() {
    this.addEmptyGroup();
    const { id: sportsProductId } = this.props.match.params;
    this.props.onClickSave(e => {
      e.preventDefault();
      this.props
        .updateGroupsSportsProduct(
          sportsProductId,
          this.state.groups.map(group => ({
            ...group,
            clubes: group.clubes.map(({ clubId }) => clubId)
          }))
        )
        .then(({ value: { data } }) => {
          this.setState({ groups: data, index: -1 });
        });
    });

    this.props.getGroupsSportsProduct(sportsProductId);
  }

  componentDidUpdate(prevProps) {
    const { productGroups: groups } = this.props;
    const { productGroups: prevProductGroups } = prevProps;

    if (!prevProductGroups && groups && groups.length) {
      setChildStep(groups);
      this.setState({ groups });
    }
  }

  setValue = (index, id, value) => {
    const groups = [...this.state.groups];
    groups[index][id] = value;
    setChildStep(groups);
    this.setState({ ...this.state, groups });
  };

  renderInputText = ({ index, column: { id } }) => {
    return (
      <Input
        type="text"
        className={`${s.renderEditableInput}`}
        value={this.state.groups[index][id]}
        onChange={e => {
          const value = e.target.value;
          this.setValue(index, id, value);
        }}
      />
    );
  };

  renderClubes = ({ index, column: { id } }) => {
    return (
      <p className="text-justify m-2">
        {this.state.groups[index][id].map(c => c.nombre).join(", ")}
      </p>
    );
  };

  addEmptyGroup = () => {
    const groups = [...this.state.groups];
    groups.push({
      nombre: `GRUPO ${String.fromCharCode(65 + groups.length)}`,
      clubes: []
    });

    setChildStep(groups);
    this.setState({ groups });
  };

  toggleModal = index => {
    this.setState({
      isOpenModal: !this.state.isOpenModal,
      index: isNumber(index) ? index : this.state.index
    });
  };

  addClubsToSportsClub = clubes => {
    const groups = [...this.state.groups];
    groups[this.state.index].clubes = [...clubes];
    setChildStep(groups);
    this.setState({ groups });
    this.toggleModal(this.state.index);
  };

  render() {
    return (
      <Container className="p-0 mt-2">
        {this.state.index >= 0 && (
          <SelectClubsModal
            clubsInUse={this.state.groups.reduce(
              (accum, group, index) =>
                concat(accum, index !== this.state.index ? group.clubes : []),
              []
            )}
            isOpen={this.state.isOpenModal}
            toggle={this.toggleModal}
            addClubsToSportsClub={this.addClubsToSportsClub}
            validClubs={this.state.groups[this.state.index].clubes}
          />
        )}
        <Row className="p-0 m-0 mb-3">
          <ReactTable
            data={this.state.groups}
            columns={[
              {
                Header: "NOMBRE",
                accessor: "nombre",
                Cell: this.renderInputText,
                className: "p-0",
                width: 200
              },
              {
                Header: "CLUBES",
                accessor: "clubes",
                Cell: this.renderClubes,
                className: "p-0",
                style: { whiteSpace: "normal" }
              },
              {
                Header: () => (
                  <div className="w-100 text-center">
                    <Button
                      color="link"
                      onClick={() => this.addEmptyGroup()}
                      className={`${s.innerButton} ${s.primaryLink}`}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </div>
                ),
                Cell: ({ index }) => (
                  <Button
                    className={`${s.primaryButton} btn-block`}
                    size="sm"
                    onClick={() => this.toggleModal(index)}
                  >
                    CLUBES
                  </Button>
                ),
                width: 100
              }
            ]}
            pageSize={this.state.groups.length}
            sortable={false}
            showPagination={false}
            className="-highlight -striped w-100"
            getTbodyProps={() => ({ style: { backgroundColor: "#EAECEE" } })}
          />
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(AddGroupsToSportsProducts));
