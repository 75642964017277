import { GetAllClubsBasicData } from "../../../Home/reducers/clubs";

export interface IClubSelectorStore extends GetAllClubsBasicData {
  userContext: any;
}

export const iClubSelectorStore: (state) => IClubSelectorStore = state => {
  const { clubs, user } = state;
  return {
    reloadClubs: clubs.reloadClubs,
    allClubsBasicData: clubs.allClubsBasicData,
    gettingAllClubsBasicData: clubs.gettingAllClubsBasicData,
    gettingAllClubsBasicDataError: clubs.gettingAllClubsBasicDataError,
    userContext: user.userContext
  };
};
