import React from "react";
import { connect } from "react-redux";
import RolesUser from "../../components/rolesUser";
import * as catalogActions from "../../actions/catalogs";
import { getAllClubsBasicData } from "../../actions/clubs";
import * as userActions from "../../actions/users";
import { withRouter } from "react-router-dom";

interface Props {
  getClubs: () => any;
  getRoles: () => any;
  getRolesUser: (id: string) => any;
  clubs: object[];
  roles: object[];
  user: object[];
  rolesUserSaved: object;
  saveRolesUser: (user: object, id: string) => any;
  onClickSave: (f: any) => any;
  onSuccessfulSave: () => any;
  successfulSave: boolean;
  match: any;
  gettingRolesUser: boolean;
  clearRolesData: () => any;
}

class RolesUserPage extends React.Component<Props> {
  componentDidMount() {
    this.props.getClubs();
    this.props.getRoles();
    if (!this.props.match.params.personaId) {
      this.props.clearRolesData();
    }
  }

  shouldComponentUpdate(nextProps) {
    if (
      this.props.rolesUserSaved !== nextProps.rolesUserSaved &&
      nextProps.rolesUserSaved
    ) {
      this.props.onSuccessfulSave();
      return false;
    }

    return true;
  }

  render() {
    return (
      <RolesUser
        saveRolesUser={this.props.saveRolesUser}
        user={this.props.user}
        onClickSave={this.props.onClickSave}
        clubs={this.props.clubs}
        roles={this.props.roles}
        getRolesUser={this.props.getRolesUser}
        gettingRolesUser={this.props.gettingRolesUser}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getClubs: () => {
    dispatch(getAllClubsBasicData());
  },
  getRoles: () => {
    dispatch(catalogActions.getRoles());
  },
  saveRolesUser: (user: object, id: string) => {
    dispatch(userActions.saveRolesUser(user, id));
  },
  getRolesUser: (id: string) => {
    dispatch(userActions.getRolesData(id));
  },
  clearRolesData: () => {
    dispatch(userActions.clearRolesData());
  }
});

const mapStateToProps = state => {
  const { catalogs, user, clubs } = state;
  return {
    // REQ: Arturo Salgado - Se pueden crear usuarios de CRM1 para autorización
    // clubs: clubs.allClubsBasicData.filter(c => c.migradoCrm2) || [],
    clubs: clubs.allClubsBasicData || [],
    roles: catalogs.roles ? catalogs.roles : [],
    rolesUserSaved: user.rolesUserSaved,
    user: user.rolesUser,
    gettingRolesUser: user.gettingRolesUser
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RolesUserPage)
);
