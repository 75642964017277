import React from "react";

interface Props {}

interface State {}

class SignatureTest extends React.Component<Props, State> {
  render() {
    return <div>HELLO</div>;
  }
}

export default SignatureTest;
