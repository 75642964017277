import { createStore as createReduxStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import rootStore from "../reducers";
import promiseMiddleware from "redux-promise-middleware";

const createStore = initialState => {
  const middlewares = [thunk, promiseMiddleware()];

  if (__DEV__) {
    middlewares.push(createLogger());
  }

  const composeEnhancers = composeWithDevTools({
    // options like actionSanitizer, stateSanitizer
  });

  return createReduxStore(
    rootStore,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
};

export default createStore;
