import React from "react";
import { connect } from "react-redux";
import * as productActions from "../../actions/products";
import MaintenanceConfiguration from "../../components/maintenanceConfiguration";

interface Props {
  successfulSave: boolean;
  config: object;
  memberships: object[];
  accountingAccounts: object[];
  addClubsToProducts: (
    productId: string,
    tipoProducto: string,
    clubs: object[]
  ) => any;
  saveConfigurations: (
    productId: string,
    tipoProducto: string,
    confg: object
  ) => any;
  updateConfigurations: (
    productId: string,
    tipoProducto: string,
    confg: object
  ) => any;
  getProductConfig: (tipoProducto: string, id: string) => any;
  getMemberships: () => any;
  getAccountingAccounts: () => any;
  onClickSave: (e: object) => any;
}

class configureMaintenance extends React.Component<Props, void> {
  componentDidMount() {}

  render() {
    return (
      <MaintenanceConfiguration
        addClubsToProducts={this.props.addClubsToProducts}
        successfulSave={this.props.successfulSave}
        saveConfigurations={this.props.saveConfigurations}
        updateConfigurations={this.props.updateConfigurations}
        getProductConfig={this.props.getProductConfig}
        config={this.props.config}
        getMemberships={this.props.getMemberships}
        memberships={this.props.memberships}
        getAccountingAccounts={this.props.getAccountingAccounts}
        accountingAccounts={this.props.accountingAccounts}
        onClickSave={this.props.onClickSave}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addClubsToProducts: (
    productId: string,
    tipoProducto: string,
    clubs: object[]
  ) => {
    dispatch(productActions.addClubsToProducts(productId, tipoProducto, clubs));
  },
  saveConfigurations: (
    productId: string,
    tipoProducto: string,
    confg: object
  ) => {
    dispatch(productActions.saveConfigurations(productId, tipoProducto, confg));
  },
  updateConfigurations: (
    productId: string,
    tipoProducto: string,
    confg: object
  ) => {
    dispatch(
      productActions.updateConfigurations(productId, tipoProducto, confg)
    );
  },
  getProductConfig: (tipoProducto: string, id: string) => {
    dispatch(productActions.getProductConfig(tipoProducto, id));
  },
  getMemberships: () => {
    dispatch(productActions.getMemberships());
  },
  getAccountingAccounts: () => {
    dispatch(productActions.getAccountingAccounts());
  }
});

const mapStateToProps = state => {
  const { products } = state;
  return {
    successfulSave: products.savedBasicConfig,
    config: products.basicConfig,
    memberships: products.memberships,
    accountingAccounts: products.accountingAccounts
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(configureMaintenance);
