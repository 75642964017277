import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./styles.scss";
import { Editor } from "react-draft-wysiwyg";

interface Props {
  onChangeEditor: () => any;
  editorState: object;
}

interface State {}

class RichEditor extends React.Component<Props, State> {
  render() {
    return (
      <div className={s.editor}>
        <Editor
          editorState={this.props.editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName={s.wrapperEditor}
          editorClassName={s.inputEditor}
          onEditorStateChange={this.props.onChangeEditor}
        />
      </div>
    );
  }
}

export default withStyles(s)(RichEditor);
