import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import PersonClubIngress from "../../components/personClubIngress";
import * as catalogActions from "../../actions/catalogs";

interface Props {
  clubs: any;
  history: any;
  getClubs: () => any;
}

class PersonClubIngressPage extends React.Component<Props> {
  componentDidMount() {
    this.props.getClubs();
  }

  render() {
    return (
      <main>
        <Helmet title="Lockers asignados" />
        <Layout>
          <PersonClubIngress clubs={this.props.clubs} />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getClubs: () => {
    dispatch(catalogActions.getClubs());
  }
});

const mapStateToProps = state => {
  const { catalogs } = state;

  return {
    clubs: catalogs.clubs
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonClubIngressPage);
