import React from "react";
import { connect } from "react-redux";
import * as productActions from "../../actions/products";
import AddClubsToProducts from "../../components/addClubsToProducts";
import { withRouter } from "react-router-dom";
import isNil from "lodash.isnil";
import { setChildStep } from "../../helpers/stepper-state-comparator";

interface Props {
  addClubsToProducts: (
    productId: string,
    tipoProducto: string,
    clubs: object[]
  ) => any;
  updateClubsToProducts: (
    productId: string,
    tipoProducto: string,
    clubs: object[]
  ) => any;
  successfulSave: boolean;
  getClubsProduct: (tipoProducto: string, id: string) => any;
  validClubs: object[];
  validClubsLoaded: boolean;
  onClickSave: (e: object) => any;
  match: any;
}

class AddClubsToProductPage extends React.Component<Props, void> {
  componentDidMount() {
    const { productId, tipoProducto } = this.props.match.params;
    if (productId) this.props.getClubsProduct(tipoProducto, productId);
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.validClubsLoaded &&
      this.props.validClubsLoaded &&
      !isNil(this.props.validClubs) &&
      this.props.validClubs.length === 0
    ) {
      setChildStep({});
    }
  }

  setClubs = clubs =>
    !this.props.validClubs.length
      ? this.props.addClubsToProducts(
          this.props.match.params.productId,
          this.props.match.params.tipoProducto,
          clubs
        )
      : this.props.updateClubsToProducts(
          this.props.match.params.productId,
          this.props.match.params.tipoProducto,
          clubs
        );

  render() {
    return this.props.validClubsLoaded ? (
      <AddClubsToProducts
        successfulSave={this.props.successfulSave}
        validClubs={this.props.validClubs}
        onClickSave={this.props.onClickSave}
        setClubs={this.setClubs}
      />
    ) : null;
  }
}

const mapDispatchToProps = dispatch => ({
  addClubsToProducts: (
    productId: string,
    tipoProducto: string,
    clubs: object[]
  ) => {
    dispatch(productActions.addClubsToProducts(productId, tipoProducto, clubs));
  },
  updateClubsToProducts: (
    productId: string,
    tipoProducto: string,
    clubs: object[]
  ) => {
    dispatch(
      productActions.updateClubsToProducts(productId, tipoProducto, clubs)
    );
  },
  getClubsProduct: (tipoProducto: string, id: string) => {
    dispatch(productActions.getClubsProduct(tipoProducto, id));
  }
});

const mapStateToProps = state => {
  const { products } = state;

  return {
    successfulSave: products.clubsSaved,
    validClubs: products.productClubs,
    validClubsLoaded: products.productClubsLoaded
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddClubsToProductPage)
);
