import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Button, Row, Col } from "reactstrap";
import ModalFrame from "../../../../toolkit/modalFrame";
import BaseNumericInput from "../../../../toolkit/baseNumericInput";
import { handleRequestError } from "../../../../utils/helpers";
import { generateGuestsPassesDiscounts } from "../../../actions/partners";

interface Props {
  isOpen: boolean;
  partnerMembershipId: String;
  onOk: () => void;
  onCancel: () => void;
}

interface State {
  fields: {
    noPasses: number;
  };
}

class ModalAddGuestPassesDiscounts extends React.Component<Props, State> {
  state = {
    fields: {
      noPasses: 0
    }
  };

  clearState = () => {
    const { fields } = this.state;
    fields.noPasses = 0;
    this.setState({ fields: { ...fields } });
  };

  onOkButton = () => {
    //CALLS endpoint
    const { partnerMembershipId: membresiaSocioId } = this.props;
    const { fields } = this.state;
    const { noPasses: noPases } = fields;

    const data = [
      {
        membresiaSocioId,
        noPases
      }
    ];

    generateGuestsPassesDiscounts(this.props.partnerMembershipId, data)
      .then(() => {
        this.clearState();
        if (!!this.props.onOk) {
          this.props.onOk();
        }
      })
      .catch(handleRequestError);
  };

  onCancelButton = () => {
    this.clearState();

    if (!!this.props.onCancel) {
      this.props.onCancel();
    }
  };

  onChangeNumberFactory = fieldName => {
    return event => {
      const value = event.target.value.replace(/[^0-9]/gi, "");
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };
        return { ...state, error: {}, fields };
      });
    };
  };

  render() {
    return (
      <ModalFrame
        title={`Creación de descuentos para pases de invitado`}
        isOpen={this.props.isOpen}
        toggle={() => {}}
        size="md"
      >
        <Row>
          <Col>
            <BaseNumericInput
              label={"# descuentos para pases de invitado"}
              name="noGuestsPasses"
              id="noGuestsPasses"
              value={this.state.fields.noPasses}
              onChange={this.onChangeNumberFactory("noPasses")}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xs={6} md={6} lg={6}>
            <Button
              className={`${s.primaryButton}`}
              block
              color="secondary"
              onClick={() => {
                this.onOkButton();
              }}
              disabled={!this.state.fields.noPasses}
            >
              CREAR DESCUENTOS
            </Button>
          </Col>
          <Col xs={6} md={6} lg={6}>
            <Button
              block
              color="secondary"
              onClick={() => {
                this.onCancelButton();
              }}
              disabled={false}
            >
              CANCELAR
            </Button>
          </Col>
        </Row>
      </ModalFrame>
    );
  }
}

export default withRouter(withStyles(s)(ModalAddGuestPassesDiscounts));
