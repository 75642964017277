import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../../Home/components/layout";
import ReferredCodesConfiguration from "../../components/ReferredCodesConfiguration";
import { getMaintenances } from "../../../Home/actions/catalogs";
import {
  updateMaintenancesReferredCodes,
  updateClubsReferredCodes,
  getClubsReferredCodes,
  getMaintenancesReferredCodes
} from "../../actions/configuration";

interface Props {
  maintenances: object[];
  getMaintenances: () => any;
  updateClubsReferredCodes: (clubs: object[]) => any;
  updateMaintenancesReferredCodes: (maintenances: object[]) => any;
  clubs: object[];
  maintenancesCodes: object[];
  getClubsReferredCodes: () => any;
  getMaintenancesReferredCodes: () => any;
}

class RolesPage extends React.Component<Props> {
  componentDidMount() {
    this.props.getMaintenances();
    this.props.getMaintenancesReferredCodes();
    this.props.getClubsReferredCodes();
  }

  saveData = (clubs, maintenances) => {
    this.props.updateClubsReferredCodes(clubs);
    this.props.updateMaintenancesReferredCodes(maintenances);
  };

  render() {
    return (
      <main>
        <Helmet title="Configuracion de codigos de referidos" />
        <Layout>
          <ReferredCodesConfiguration
            saveData={this.saveData}
            maintenances={this.props.maintenances}
            maintenancesCodes={this.props.maintenancesCodes}
            clubs={this.props.clubs}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = {
  getMaintenances,
  updateClubsReferredCodes,
  updateMaintenancesReferredCodes,
  getClubsReferredCodes,
  getMaintenancesReferredCodes
};

const mapStateToProps = state => {
  const {
    catalogs: { maintenances },
    configuration: { clubs, maintenances: maintenancesCodes }
  } = state;
  return {
    maintenances,
    clubs,
    maintenancesCodes
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RolesPage);
