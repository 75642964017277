import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import * as H from "history";
import { StaticContext, match } from "react-router";
import React from "react";
import PrivateRoute from "../../utils/privateRoute";
import DISPLAYS_CODES from "../../utils/displayCodes";
import IAction, { Preview } from "../../types/IAction";

/**
 * Clasificación de un componente React, para permitirle exportar la clase de modo más consiso.
 */

const ROUTES: {
  ctor: any;
  route: string;
}[] = [];

export function getDecoratedRoutes(component: any) {
  const ctor = component.WrappedComponent || component;

  const a = ROUTES.filter(r => r.ctor == ctor || r.ctor == component).map(
    (r, i) => (
      <PrivateRoute
        path={r.route}
        component={component}
        key={`decorated-${component.name}-${i}`}
        display={DISPLAYS_CODES.PASS}
      />
    )
  );
  return a;
}

/**
 * Versión personalizada de RouteComponentProps; para uso de sus propiedades.
 * Véase https://github.com/Microsoft/TypeScript/issues/4881
 */
export interface RouterProps<
  Params extends { [K in keyof Params]?: string } = {},
  C extends StaticContext = StaticContext,
  S = H.LocationState
> {
  history?: H.History;
  location?: H.Location<S>;
  match?: match<Params>;
  staticContext?: C;
}

/**
 * Conexión de propiedades y dispatchers de Redux en props de Componente de React
 */
export function ReduxConnect(
  mapStateToProps: (state: any) => any,
  mapDispatchToProps: (dispatch: any) => any
) {
  const originalDispatchers = mapDispatchToProps(swDispatch);
  const mapSWDispatchToProps = dispatch => {
    const dispatchers: any = {};

    for (let prop in originalDispatchers) {
      const originalFx = originalDispatchers[prop];

      dispatchers[prop] = (...a) => {
        const swd = originalFx(...a);
        dispatch(swd.action);
        return swd.promise;
      };
    }
    return dispatchers;
  };

  return connect(
    mapStateToProps,
    mapSWDispatchToProps
  );
}

function swDispatch(action: IAction) {
  const promise = new Promise<Preview<any>>(resolve => {
    if (action.meta != null) {
      action.meta.resolver = resolve;
    } else {
      action.meta = { resolver: resolve };
    }
  });

  return {
    action,
    promise
  };
}

/**
 * Componente con Router (withRouter); es decir, que puede navegar a través de las URL's del sistema
 */
export function Router() {
  return withRouter as any;
}

/**
 * Registro de rutas para el Componente
 */
export function Routing(...routes: string[]) {
  return (ctor: Function) => {
    routes.forEach(route => ROUTES.push({ route, ctor }));
  };
}

/**
 * Componente con hoja de estilos SCSS (withStyles)
 */
export function Styled(style: any) {
  function styled(ctor: Function) {
    const componentDidMount = ctor.prototype.componentDidMount;
    const componentWillUnmount = ctor.prototype.componentWillUnmount;
    const binded = withStyles(style)(ctor);

    (ctor as any).contextTypes = binded.contextTypes;

    ctor.prototype.componentDidMount = function() {
      if (componentDidMount) {
        componentDidMount.call(this);
      }

      if (binded.prototype.componentWillMount) {
        binded.prototype.componentWillMount.call(this);
      }
    };

    ctor.prototype.componentWillUnmount = function() {
      if (componentWillUnmount) {
        componentWillUnmount.call(this);
      }

      if (binded.prototype.componentWillUnmount) {
        binded.prototype.componentWillUnmount.call(this);
      }
    };
  }

  return styled;
}
