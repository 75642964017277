import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./styles.scss";
import ModalFrame from "../../../../toolkit/modalFrame";
import { handleRequestError } from "../../../../utils/helpers";
import { generateProductDiscounts } from "../../../actions/partners";
import ProductsBenefits from "../../annuitiesConfig/productsBenefits";

interface Props {
  isOpen: boolean;
  classifications: any[];
  partnerMembershipId: String;
  fitkidz: boolean;
  onOk: () => void;
  onCancel: () => void;
}

class ModalAddProductsDiscounts extends React.Component<Props> {
  onOkButton = productBenefit => {
    const data = [productBenefit];

    generateProductDiscounts(this.props.partnerMembershipId, data)
      .then(() => {
        if (!!this.props.onOk) {
          this.props.onOk();
        }
      })
      .catch(handleRequestError);
  };

  onCancelButton = () => {
    if (!!this.props.onCancel) {
      this.props.onCancel();
    }
  };

  render() {
    return (
      <ModalFrame
        title={`Creación de descuentos para productos`}
        isOpen={this.props.isOpen}
        toggle={() => {}}
        size="lg"
      >
        <ProductsBenefits
          classifications={this.props.classifications || []}
          showCancelButton={true}
          fitkidz={this.props.fitkidz || false}
          addBenefit={this.onOkButton}
          cancelBenefit={this.onCancelButton}
        />
      </ModalFrame>
    );
  }
}

export default withStyles(s)(ModalAddProductsDiscounts);
