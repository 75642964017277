/// imports
import React from "react";
import { Helmet } from "react-helmet";
import { ReduxConnect } from "../../../toolkit/decorators/Artifact";
import Layout from "../../components/layout";
import ConveniosComponent from "../../components/convenios/index";
import { getConveniosList } from "../../actions/convenios";
import { Preview } from "../../../types/IAction";
////

const mapDispatchToProps = dispatch => ({
  /// Dispatchers de convenios
  getConveniosList: (): Promise<Preview> => {
    return dispatch(getConveniosList());
  }
  ////
});

const mapStateToProps = state => {
  const convenios = state.convenios;

  return {
    /// State de Convenios
    conveniosList: convenios.conveniosList,
    conveniosListLoading: convenios.conveniosListLoading
    ////
  };
};

interface Props {
  // Listado de Convenios (Redux)
  conveniosList: any[];
  conveniosListLoading: boolean;
  getConveniosList: () => Promise<Preview>;
}

interface State {}

@ReduxConnect(mapStateToProps, mapDispatchToProps)
export default class ConveniosContainer extends React.Component<Props, State> {
  state = {
    useError: false
  };

  componentDidMount() {
    this.props.getConveniosList();
  }

  render() {
    return (
      <main>
        <Layout>
          <Helmet title="Convenios" />
          <ConveniosComponent
            conveniosList={this.props.conveniosList}
            conveniosListLoading={this.props.conveniosListLoading}
            getConveniosList={this.props.getConveniosList}
          />
        </Layout>
      </main>
    );
  }
}
