import React from "react";
import { connect } from "react-redux";
import * as sportsProductsActions from "../../actions/sportsProducts";
import {
  getProductsClassificationsRequest,
  getSatProducts,
  getSatUnits,
  getSportsProductsTypes
} from "../../actions/catalogs";
import BasicDataSportsProductsForm from "../../components/basicDataSportsProductsForm";

interface Props {
  saveBasicProduct: (product: object) => any;
  updateBasicProduct: (productId: string, product: object) => any;
  successfulSave: boolean;
  basicProductSaved: object;
  getBasicProduct: (id: string) => any;
  product: object;
  onClickSave: (f: any) => any;
  onSuccessfulSave: () => any;
  resetSuccessfulSave: () => any;
  getSportsProductsTypes: () => any;
  sportsProductsTypes: object[];
  getSatProducts: () => any;
  getSatUnits: () => any;
  satProducts: object[];
  satUnits: object[];
  sendPhotoFile: (photo: Blob, productId: number) => any;
  setProductPhoto: (photo: Blob) => any;
  productPhoto: Blob;
  basicDataSaved: boolean;
  classificationByLevel: object[];
}

interface State {
  classificationByLevel: object[];
}

class BasicDataSportsProductsContainer extends React.Component<Props, State> {
  state = {
    classificationByLevel: []
  };

  componentDidMount() {
    this.props.getSatProducts();
    this.props.getSatUnits();
    this.getProductsClassification();
  }

  getProductsClassification = () => {
    getProductsClassificationsRequest().then(({ data }) => {
      this.setState({
        classificationByLevel: [...data]
      });
    });
  };

  render() {
    return (
      <BasicDataSportsProductsForm
        saveBasicProduct={this.props.saveBasicProduct}
        successfulSave={this.props.successfulSave}
        product={this.props.product}
        updateBasicProduct={this.props.updateBasicProduct}
        onClickSave={this.props.onClickSave}
        onSuccessfulSave={this.props.onSuccessfulSave}
        resetSuccessfulSave={this.props.resetSuccessfulSave}
        getBasicProduct={this.props.getBasicProduct}
        sportsProducts={this.props.sportsProductsTypes}
        satProducts={this.props.satProducts}
        satUnits={this.props.satUnits}
        setProductPhoto={this.props.setProductPhoto}
        sendPhotoFile={this.props.sendPhotoFile}
        productPhoto={this.props.productPhoto}
        basicDataSaved={this.props.basicDataSaved}
        classificationByLevel={this.state.classificationByLevel}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  saveBasicProduct: (product: object) => {
    return dispatch(sportsProductsActions.saveBasicData(product));
  },
  sendPhotoFile: (photo: Blob, productId: number) => {
    return dispatch(sportsProductsActions.sendPhotoFile(photo, productId));
  },
  setProductPhoto: (photo: Blob) => {
    return dispatch(sportsProductsActions.setProductPhoto(photo));
  },
  getBasicProduct: (id: string) => {
    dispatch(sportsProductsActions.getBasicData(id));
  },
  updateBasicProduct: (productId: string, product: object) => {
    return dispatch(sportsProductsActions.updateBasicData(productId, product));
  },
  getSportsProductsTypes: () => {
    dispatch(getSportsProductsTypes());
  },
  getSatProducts: () => {
    dispatch(getSatProducts());
  },
  getSatUnits: () => {
    dispatch(getSatUnits());
  }
});

const mapStateToProps = state => {
  const { sportsProducts, catalogs, products } = state;
  return {
    successfulSave: sportsProducts.basicDataSaved,
    basicProductSaved: sportsProducts.basicProductSaved,
    product: sportsProducts.basicProduct,
    sportsProductsTypes: catalogs.sportsProductsTypes,
    satProducts: catalogs.satProducts,
    satUnits: catalogs.satUnits,
    productPhoto: products.productPhoto,
    basicDataSaved: sportsProducts.basicDataSaved,
    classificationByLevel: products.classificationByLevel
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasicDataSportsProductsContainer);
