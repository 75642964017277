import React from "react";
import { withRouter } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./styles.scss";
import { FormErrors } from "@franleplant/reform/types";
import SalesComissions from "./salesComissions";
import CoordinationCommisions from "./coordination";
import TransactionCommisions from "./transaction";
import IAction from "../../../types/IAction";
import SalesComissionsSportsProgramConfig from "../salesComissionsSportsProgramConfig";

interface Props {
  sportProduct: any;
  getClassificationByLevel: (level: number) => any;
  classificationByLevel: object[];
  saveSportsProductComissions: (id: string, fields: object) => IAction;
  onClickSave: (e: object) => any;
  match: any;
  getSportsProductComissions: (id: string) => any;
  comissionsBySportsProductId: object;
  getSportsProductOperationComissions: (id: string) => any;
  getSPComissionsCoordination: (id: string) => any;
  getSPComissionsOperation: (id: string) => any;
  comissionsById: any;
  comissionsByIdCoordination: any;
  comissionsByIdOperation: any;
  operationComissionsById: any;
  saveSportsProductOperationComissions: (body: any) => IAction;
  autorizeComissions: (id: string, tipo: string) => IAction;
  catalogPostions: any;
}

interface State {
  errors: FormErrors;
  selectedPuestos: object;
  load: boolean;
  activeTab: string;
}

class priceDataSportsProducts extends React.Component<Props, State> {
  state = {
    activeTab: "1",
    load: false,
    selectedPuestos: {},
    errors: {}
  };

  componentDidMount() {
    this.props.getSportsProductOperationComissions(this.props.match.params.id);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    let tipoComportamiento = null;

    if (this.props.sportProduct) {
      tipoComportamiento = this.props.sportProduct.producto.tipoComportamiento;
    }

    return (
      <div className="p-0 m-0 w-100 h-100">
        <Container
          className="w-100 p-0 m-0"
          style={{ height: "650px" }}
          xs={12}
        >
          <Nav className={s.tabs} tabs>
            <NavItem>
              <NavLink
                className={`${this.state.activeTab === "1" ? "active" : ""} ${
                  s.tab
                }`}
                onClick={() => {
                  this.toggleTab("1");
                }}
              >
                Ventas
              </NavLink>
            </NavItem>
            {tipoComportamiento === "PROGRAMA_DEPORTIVO" ||
            tipoComportamiento === "CLASES_PERSONALIZADAS" ? (
              <NavItem>
                <NavLink
                  className={`${this.state.activeTab === "2" ? "active" : ""} ${
                    s.tab
                  }`}
                  onClick={() => {
                    this.toggleTab("2");
                  }}
                >
                  Coordinación
                </NavLink>
              </NavItem>
            ) : (
              ""
            )}
            {tipoComportamiento === "PROGRAMA_DEPORTIVO" ||
            tipoComportamiento === "CLASES_PERSONALIZADAS" ? (
              <NavItem>
                <NavLink
                  className={`${this.state.activeTab === "3" ? "active" : ""} ${
                    s.tab
                  }`}
                  onClick={() => {
                    this.toggleTab("3");
                  }}
                >
                  Operación
                </NavLink>
              </NavItem>
            ) : (
              ""
            )}
          </Nav>
          <TabContent className="h-100" activeTab={this.state.activeTab}>
            <TabPane className="my-2" tabId="1">
              <Container className="p-0 m-0">
                <Row className="p-0 m-0">
                  <Col className="p-0 m-0">
                    <SalesComissions
                      catalogPostions={this.props.catalogPostions}
                      getSportsProductComissions={
                        this.props.getSportsProductComissions
                      }
                      comissionsById={this.props.comissionsById}
                      saveSportsProductComissions={
                        this.props.saveSportsProductComissions
                      }
                      autorizeComissions={this.props.autorizeComissions}
                    />
                  </Col>
                </Row>
              </Container>
            </TabPane>
            <TabPane className="my-2" tabId="2">
              <Container className="p-0 m-0">
                <Row className="p-0 m-0">
                  <Col className="p-0 m-0">
                    <CoordinationCommisions
                      catalogPostions={this.props.catalogPostions}
                      getSPComissionsCoordination={
                        this.props.getSPComissionsCoordination
                      }
                      comissionsByIdCoordination={
                        this.props.comissionsByIdCoordination
                      }
                      saveSportsProductComissions={
                        this.props.saveSportsProductComissions
                      }
                      autorizeComissions={this.props.autorizeComissions}
                    />
                  </Col>
                </Row>
              </Container>
            </TabPane>
            <TabPane className="my-2" tabId="3">
              <Container className="p-0 m-0">
                <Row className="p-0 m-0">
                  <Col className="p-0 m-0">
                    {tipoComportamiento === "CLASES_PERSONALIZADAS" ? (
                      <TransactionCommisions
                        catalogPostions={this.props.catalogPostions}
                        getSPComissionsOperation={
                          this.props.getSPComissionsOperation
                        }
                        comissionsByIdOperation={
                          this.props.comissionsByIdOperation
                        }
                        saveSportsProductComissions={
                          this.props.saveSportsProductComissions
                        }
                        autorizeComissions={this.props.autorizeComissions}
                      />
                    ) : (
                      <SalesComissionsSportsProgramConfig
                        catalogPostions={this.props.catalogPostions}
                        sportProduct={this.props.sportProduct}
                        productId={this.props.match.params.id}
                        comissions={this.props.operationComissionsById}
                        saveComissions={
                          this.props.saveSportsProductOperationComissions
                        }
                        autorizeComissions={this.props.autorizeComissions}
                      />
                    )}
                  </Col>
                </Row>
              </Container>
            </TabPane>
          </TabContent>
        </Container>
      </div>
    );
  }
}

export default withRouter(withStyles(s)(priceDataSportsProducts));
