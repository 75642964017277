import React from "react";
import { connect } from "react-redux";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./styles.scss";
import LayoutStyles from "../../../toolkit/baseStyles";
import Header from "../header";
//import Footer from "../footer";
import { ToastContainer } from "react-toastify";
import Modals from "../../containers/modals";
import { UserContext } from "../../../types-business/Profile";
import * as sessionActions from "../../actions/session";

interface Props {
  userContext: UserContext;
  logoutUser: () => any;
  logout: boolean;
  accessTreeAvailable: boolean;
  logOutSuccess: boolean;
  restartApp: () => any;
  headerLabel: string;
}

class Layout extends React.Component<Props, void> {
  render() {
    return (
      <LayoutStyles>
        <ToastContainer autoClose={8000} style={{ fontSize: "1.5rem" }} />
        <Header
          userContext={this.props.userContext}
          logoutUser={this.props.logoutUser}
          logout={this.props.logout}
          logOutSuccess={this.props.logOutSuccess}
          accessTreeAvailable={this.props.accessTreeAvailable}
          restartApp={this.props.restartApp}
          headerLabel={this.props.headerLabel}
        />
        <div>{this.props.children}</div>
        {this.props.userContext && this.props.accessTreeAvailable ? (
          <Modals />
        ) : null}
        {/* <Footer /> */}
      </LayoutStyles>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;
  return {
    userContext: user.userContext,
    logout: user.logout,
    accessTreeAvailable: user.treeAvailable,
    logOutSuccess: user.logOutSuccess
  };
};

const mapDispatchToProps = dispatch => ({
  logoutUser: () => {
    return dispatch(sessionActions.logoutUser());
  },
  restartApp: () => {
    dispatch(sessionActions.restartApp());
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(s)(Layout));
