import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import BaseNumericInput from "../../../toolkit/baseNumericInput";
import s from "./styles.scss";
import { Container, Form, Col, Row } from "reactstrap";

interface Props {
  benefits: any;
  updateFreeGuestsPassesNumber: (_number: number) => any;
}

class PromotionBenefitsFreeGuestsPasses extends React.Component<Props> {
  onChangeNumber = () => {
    return e => {
      const value = e.target.value;
      this.props.updateFreeGuestsPassesNumber(value);
    };
  };

  render() {
    return (
      this.props.benefits && (
        <Container className="mt-5">
          <Form>
            <Row className="mt-3">
              <Col>
                <p style={{ color: "red", fontWeight: "bold" }}>
                  Pases de Invitado gratis
                </p>
              </Col>
            </Row>

            <Row>
              <Col xs={4}>
                <BaseNumericInput
                  label={"# Pases de Invitado gratis"}
                  name="noPasesInvitadoGratis"
                  id="noPasesInvitadoGratis"
                  type="text"
                  value={this.props.benefits.pasesInvitadoGratis}
                  placeholder={"# Pases de Invitado gratis"}
                  onChange={this.onChangeNumber()}
                />
              </Col>
            </Row>
          </Form>
        </Container>
      )
    );
  }
}

export default withStyles(s)(PromotionBenefitsFreeGuestsPasses);
