import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { showDocument } from "../../actions/documents";
import Layout from "../../components/layout";
import * as dealersAction from "../../actions/dealers";
import * as clubsActions from "../../actions/clubs";
import * as reportsActions from "../../actions/reports";
import IAction from "../../../types/IAction";
import DealerStatementsPage from "../../components/dealerStatementsPage";

interface Props {
  clubs: object[];
  depositoReferenciado: object[];
  dealers: object[];
  dealersStatusReport: object;
  getAllClubsBasicData: () => void;
  clearReasignMembership: () => IAction;
  getDealers: (clubId: string, name: string) => IAction;
  getDealersStatusReport: (
    tipoReporte: string,
    clubId: string,
    concesionarioId: string,
    fechaHasta: string
  ) => IAction;
  downloadPDFDealersStatusReport: (
    tipoReporte: string,
    clubId: string,
    concesionarioId: string,
    fechaHasta: string
  ) => IAction;
  downloadCSVDealersStatusReport: (
    tipoReporte: string,
    clubId: string,
    concesionarioId: string,
    fechaHasta: string
  ) => IAction;
}

interface State {}

class DealerStatements extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    this.props.getAllClubsBasicData();
  }

  componentWillUnmount() {
    this.props.clearReasignMembership();
  }

  render() {
    return (
      <main>
        <Helmet title="Shift delivery" />
        <Layout>
          <DealerStatementsPage
            clubs={this.props.clubs}
            depositoReferenciado={this.props.depositoReferenciado}
            getDealers={this.props.getDealers}
            dealers={this.props.dealers}
            dealersStatusReport={this.props.dealersStatusReport}
            v
            getDealersStatusReport={this.props.getDealersStatusReport}
            downloadPDFDealersStatusReport={
              this.props.downloadPDFDealersStatusReport
            }
            downloadCSVDealersStatusReport={
              this.props.downloadCSVDealersStatusReport
            }
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({ clubs, reports, dealers }) => {
  return {
    clubs: clubs.allClubsBasicData,
    depositoReferenciado: reports.depositoReferenciado,
    dealers: dealers.dealers,
    dealersStatusReport: dealers.dealersStatusReport
  };
};

const mapDispatchToProps = dispatch => ({
  getAllClubsBasicData: () => {
    dispatch(clubsActions.getAllClubsBasicData());
  },
  clearReasignMembership: () => {
    return dispatch(reportsActions.clearReasignMembership());
  },
  getDealers: (clubId: string, name: string) => {
    return dispatch(dealersAction.getDealers(clubId, name));
  },
  getDealersStatusReport: (
    tipoReporte: string,
    clubId: string,
    concesionarioId: string,
    fechaHasta: string
  ) => {
    return dispatch(
      dealersAction.getDealersStatusReport(
        tipoReporte,
        clubId,
        concesionarioId,
        fechaHasta
      )
    );
  },
  downloadPDFDealersStatusReport: (
    tipoReporte: string,
    clubId: string,
    concesionarioId: string,
    fechaHasta: string
  ) => {
    return dispatch(
      dealersAction.downloadPDFDealersStatusReport(
        tipoReporte,
        clubId,
        concesionarioId,
        fechaHasta
      )
    );
  },
  downloadCSVDealersStatusReport: (
    tipoReporte: string,
    clubId: string,
    concesionarioId: string,
    fechaHasta: string
  ) => {
    return dispatch(
      dealersAction.downloadCSVDealersStatusReport(
        tipoReporte,
        clubId,
        concesionarioId,
        fechaHasta
      )
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DealerStatements);
