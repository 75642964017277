import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import { Container, Col, Button, Row, Input } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import s from "./styles.scss";
import IAction from "../../../types/IAction";
import { setChildStep } from "../../helpers/stepper-state-comparator";
import MexicanCurrency from "../common/currency";
import moment from "moment";

interface Props {
  clubs: object[];
  getVariablesChargeDealers: (
    clubId: string,
    year: string,
    month: string
  ) => IAction;
  variablesCharge: any;
  generateVariableCharge: (
    configuracionCargoId: string,
    monto: string
  ) => IAction;
}

interface State {
  filters: object;
  listVariablesCharge: Array<{
    ventas: number;
    montoMinimo: number;
    porcentaje: number;
    montoCargoFijo: number;
    restaMontoFijo: boolean;
  }>;
  load: boolean;
  onClickSearch: boolean;
}

export const catalogoYear = [
  { value: null, label: "Seleccionar año" },
  { value: "2020", label: "2020" },
  { value: "2019", label: "2019" },
  { value: "2018", label: "2018" },
  { value: "2017", label: "2017" },
  { value: "2016", label: "2016" },
  { value: "2015", label: "2015" },
  { value: "2014", label: "2014" }
];

const getYearCatalog = () => {
  const currentYear = moment().year() + 5;
  const catalog = [{ value: null, label: "Seleccionar año" }];
  const nYears = 15;

  for (let i = currentYear; i > currentYear - nYears; i--) {
    catalog.push({
      value: i,
      label: i.toString()
    });
  }

  return catalog;
};

export const catalogoMonth = [
  { value: null, label: "Seleccionar mes" },
  { value: "1", label: "ENERO" },
  { value: "2", label: "FEBRERO" },
  { value: "3", label: "MARZO" },
  { value: "4", label: "ABRIL" },
  { value: "5", label: "MAYO" },
  { value: "6", label: "JUNIO" },
  { value: "7", label: "JULIO" },
  { value: "8", label: "AGOSTO" },
  { value: "9", label: "SEPTIEMBRE" },
  { value: "10", label: "OCTUBRE" },
  { value: "11", label: "NOVIEMBRE" },
  { value: "12", label: "DICIEMBRE" }
];

class ListVariablesChargeDealer extends React.Component<Props, State> {
  state = {
    listVariablesCharge: null,
    filters: {
      idClub: "",
      year: "",
      month: "",
      catalogoMonth: ""
    },
    load: false,
    onClickSearch: false
  };

  componentDidUpdate() {
    if (
      this.props.variablesCharge !== null &&
      !this.state.load &&
      this.state.onClickSearch
    ) {
      this.setState({
        listVariablesCharge: this.props.variablesCharge,
        load: true,
        onClickSearch: false
      });
    }
  }

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  search = e => {
    e.preventDefault();
    this.setState({ onClickSearch: true });
    const { idClub, year, month } = this.state.filters;
    this.props.getVariablesChargeDealers(idClub, year, month);
  };

  setValue = (index, nodo, value) => {
    const listVariablesCharge = [...this.state.listVariablesCharge];
    listVariablesCharge[index][nodo] = value;
    setChildStep(listVariablesCharge);
    this.setState({ ...this.state, listVariablesCharge });
  };

  renderInputText = (nodo, id) => {
    let resValor = null;
    let resIndex = null;

    this.state.listVariablesCharge.map((item, index) => {
      if (item.id === id) {
        resValor = item[nodo];
        resIndex = index;
      }
    });

    return (
      <Input
        type={nodo !== "restaMontoFijo" ? "number" : "checkbox"}
        disabled={nodo === "ventas" ? false : true}
        value={resValor}
        onChange={e => {
          const value = e.target.value;
          this.setValue(resIndex, nodo, value);
        }}
      />
    );
  };

  calcular(e, data) {
    e.preventDefault();
    let diferencia = 0;
    if (parseFloat(data.montoMinimo) > 0) {
      !data.restaMontoFijo
        ? (diferencia =
            (parseFloat(data.ventas) - parseFloat(data.montoMinimo)) *
            (parseFloat(data.porcentaje) / 100))
        : (diferencia =
            (parseFloat(data.ventas) - parseFloat(data.montoMinimo)) *
              (parseFloat(data.porcentaje) / 100) -
            parseFloat(data.montoCargoFijo));
    } else if (parseFloat(data.montoMinimo) === 0) {
      !data.restaMontoFijo
        ? (diferencia =
            parseFloat(data.ventas) * (parseFloat(data.porcentaje) / 100))
        : (diferencia =
            parseFloat(data.ventas) * (parseFloat(data.porcentaje) / 100) -
            parseFloat(data.montoCargoFijo));
    }

    let resIndex = null;

    this.state.listVariablesCharge.map((item, index) => {
      if (item.id === data.id) {
        resIndex = index;
      }
    });

    const resDiferencia = diferencia < 0 ? 0 : diferencia;
    this.setValue(resIndex, "cargoGenerar", resDiferencia);
  }

  saveData = (e, data) => {
    e.preventDefault();
    if (data.cargoGenerar > 0) {
      this.props.generateVariableCharge(data.id, data.cargoGenerar);
    }
  };

  render() {
    const { clubs } = this.props;
    const { listVariablesCharge } = this.state;
    const { idClub, year, month } = this.state.filters;

    return (
      <Container className="p-0">
        <div className="row mt-3 mb-3">
          <div className="col">
            <p className={`${s.primaryTitle} w-100 m-0`}>
              Búsqueda por cargos variables
            </p>
          </div>
        </div>
        <Row>
          <Col xs={12} md={4} lg={3}>
            <BaseInput
              label="Club"
              name="club"
              id="club"
              type="select"
              placeholder="Seleccionar club"
              value={idClub}
              onChange={this.onChangeFilter("idClub")}
              size={"sm"}
              options={[{ value: "", label: "Seleccionar club" }].concat(
                clubs
                  ? clubs.map(option => ({
                      value: option.clubId,
                      label: option.nombre
                    }))
                  : []
              )}
            />
          </Col>
          <Col xs={12} md={4} lg={3}>
            <BaseInput
              label="Mes"
              name="month"
              type="select"
              options={catalogoMonth}
              id="month"
              placeholder="Mes"
              value={month}
              onChange={this.onChangeFilter("month")}
              errors={[]}
            />
          </Col>
          <Col xs={12} md={4} lg={3}>
            <BaseInput
              label="Año"
              name="year"
              type="select"
              options={getYearCatalog()}
              id="year"
              placeholder="Año"
              value={year}
              onChange={this.onChangeFilter("year")}
              errors={[]}
            />
          </Col>
          <Col xs={12} md={4} lg={3} className="mt-4">
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.search}
              disabled={!idClub || !year || !month}
            >
              Buscar
            </Button>
          </Col>
        </Row>
        {listVariablesCharge !== null
          ? listVariablesCharge.map(data => (
              <Container className={s.formNumerals}>
                <Row className={s.spaceRow}>
                  <Col xs={12} md={6} lg={6} className="mt-2">
                    Concepto:
                    <strong className={s.strong}>{data.concepto}</strong>
                  </Col>
                  <Col xs={12} md={6} lg={6} className="mt-2">
                    Cargo a generar:
                    <strong className={s.strong}>
                      <MexicanCurrency quantity={data.cargoGenerar} />
                    </strong>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6} className="mt-2">
                    Concesionario:{" "}
                    <strong className={s.strong}>{data.concesionario}</strong>
                  </Col>
                  <Col xs={12} md={6} lg={6} className="mt-2">
                    Día de Aplicacion:{" "}
                    <strong className={s.strong}>{data.diaAplicacion}</strong>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6} className="mt-4">
                    Cargo
                  </Col>
                  <Col xs={12} md={6} lg={6} className="mt-2"></Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6} className="mt-2">
                    Venta:
                  </Col>
                  <Col xs={12} md={6} lg={6} className="mt-2">
                    {this.renderInputText("ventas", data.id)}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6} className="mt-2">
                    Monto mínimo:
                  </Col>
                  <Col xs={12} md={6} lg={6} className="mt-2">
                    {this.renderInputText("montoMinimo", data.id)}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6} className="mt-2">
                    Porcentaje:
                  </Col>
                  <Col xs={12} md={6} lg={6} className="mt-2">
                    {this.renderInputText("porcentaje", data.id)}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6} className="mt-2">
                    Monto cargo fijo:
                  </Col>
                  <Col xs={12} md={6} lg={6} className="mt-2">
                    {this.renderInputText("montoCargoFijo", data.id)}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6} className="mt-2">
                    Resta monto fijo
                  </Col>
                  <Col xs={12} md={6} lg={6} className="mt-2">
                    {this.renderInputText("restaMontoFijo", data.id)}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6} lg={6} className="mt-4">
                    <Button
                      size="sm"
                      className={`float-right btn-block`}
                      onClick={e => {
                        this.calcular(e, data);
                      }}
                    >
                      Calcular
                    </Button>
                  </Col>
                  <Col xs={12} md={6} lg={6} className="mt-4">
                    <Button
                      size="sm"
                      className={`float-right btn-block`}
                      onClick={e => {
                        this.saveData(e, data);
                      }}
                    >
                      Generar cargo
                    </Button>
                  </Col>
                </Row>
              </Container>
            ))
          : ""}
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ListVariablesChargeDealer));
