import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Button, Input, Label, Row, Col, Container } from "reactstrap";
import ModalFrame from "../../../../toolkit/modalFrame";

interface Props {
  selPostOpen: boolean;
  toggle: () => any;
  postData: object[];
  setCheck: () => any;
  checked: object;
  allChecked: boolean;
  setAllChecket: () => any;
  savePosts: () => any;
}

class PostSelectorModal extends React.Component<Props, void> {
  componentDidMount() {}

  componentDidUpdate() {}

  render() {
    return (
      <Container className="p-0">
        <ModalFrame
          title={`Puestos`}
          isOpen={this.props.selPostOpen}
          toggle={this.props.toggle}
          size="lg"
        >
          <Row className={`${s.customDistanciaCheck}`}>
            {this.props.postData.length > 0
              ? this.props.postData.map((element, index) => {
                  return (
                    <Col xs={4} md={4} lg={4}>
                      <Label>
                        <Input
                          type="checkbox"
                          checked={
                            this.props.checked.hasOwnProperty(element.id)
                              ? this.props.checked[element.id]
                              : false
                          }
                          onChange={e => this.props.setCheck(index, element.id)}
                        />
                        {element.value}
                      </Label>
                    </Col>
                  );
                })
              : null}
          </Row>
          <Row className={`${s.customDistanciaCheck}`}>
            <Col xs={4} md={4} lg={4}>
              <Label>
                <Input
                  type="checkbox"
                  checked={this.props.allChecked}
                  onChange={e =>
                    this.props.setAllChecket(!this.props.allChecked)
                  }
                  disabled={this.props.postData.length == 0}
                />
                Todos
              </Label>
            </Col>
          </Row>
          <Row className={`${s.customDistancia}`}>
            <Col xs={6} md={6} lg={6}>
              <Button
                block
                color="secondary"
                onClick={this.props.savePosts}
                disabled={Object.keys(this.props.checked).length == 0}
              >
                GUARDAR
              </Button>
            </Col>
            <Col xs={6} md={6} lg={6}>
              <Button block color="secondary" onClick={this.props.toggle}>
                CANCELAR
              </Button>
            </Col>
          </Row>
        </ModalFrame>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(PostSelectorModal));
