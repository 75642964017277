import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Row, Col, Button } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import s from "./styles.scss";
import ReactTable from "react-table";
import MexicanCurrency from "../common/currency";

interface Props {
  clubs: { clubId: number; nombre: string }[];
  onChangePayments: (genData: any[], PaymentDataValidated: boolean) => any;
  origenPayment: { idCampo; label }[];
  wayPayment: { idCampo; label }[];
  pagos: object[];
  disabled: boolean;
}

interface State {
  fields: {
    origen: string;
    formaPago: string;
    monto: string;
    referenciaCrm: string;
  };
  validData: boolean;
}

class PaymentsData extends React.Component<Props, State> {
  state = {
    fields: {
      origen: "",
      formaPago: "",
      monto: "",
      referenciaCrm: ""
    },
    validData: false
  };

  onChangeField = async (e, fieldName) => {
    let state = { ...this.state };
    state.fields[fieldName] = e.target.value;
    await this.setState(state);

    if (this.validateFields()) {
      this.setState({ ...this.state, validData: true });
    } else {
      this.setState({ ...this.state, validData: false });
    }
  };

  validateFields = () => {
    const { fields } = this.state;
    for (const key in fields) {
      if (fields.hasOwnProperty(key)) {
        const element = fields[key];
        if (element == "") return false;
      }
    }
    return true;
  };
  getClearFields = () => ({
    origen: "",
    formaPago: "",
    monto: "",
    referenciaCrm: ""
  });

  addItem = async () => {
    const state = { ...this.state };
    const pagos = [...this.props.pagos];
    pagos.push(state.fields);
    this.props.onChangePayments(pagos, true);

    state.fields = this.getClearFields();
    state.validData = false;
    this.setState(state);
  };

  render() {
    const itemsTablecolumns = [
      {
        Header: "Origen",
        accessor: "origen"
      },
      {
        Header: "Forma de Pago",
        accessor: "formaPago"
      },
      {
        Header: "Monto",
        accessor: "monto",
        Cell: row => (
          <p className="text-right m-0 p-0">
            <MexicanCurrency quantity={row.value} />
          </p>
        )
      },
      {
        Header: "referenciaCrm",
        accessor: "referenciaCrm"
      }
    ];
    let dataTable = this.props.pagos;
    return (
      <span>
        <Row>
          <Col>
            <BaseInput
              disabled={this.props.disabled}
              label={"*Origen"}
              name={`origen`}
              type="select"
              id={`origen`}
              placeholder={"origen"}
              options={[{ value: -1, label: "Seleccionar el Origen" }].concat(
                this.props.origenPayment.map(({ label }) => {
                  return {
                    value: label,
                    label: label
                  };
                })
              )}
              value={this.state.fields.origen}
              onChange={e => this.onChangeField(e, "origen")}
            />
          </Col>

          <Col>
            <BaseInput
              disabled={this.props.disabled}
              label={"*Forma de Pago"}
              name={`formaPago`}
              type="select"
              id={`formaPago`}
              placeholder={"formaPago"}
              options={[
                { value: -1, label: "Seleccionar forma de pago" }
              ].concat(
                this.props.wayPayment.map(({ label }) => {
                  return {
                    value: label,
                    label: label
                  };
                })
              )}
              value={this.state.fields.formaPago}
              onChange={e => this.onChangeField(e, "formaPago")}
            />
          </Col>

          <Col>
            <BaseInput
              disabled={this.props.disabled}
              label={"*monto"}
              name={`monto`}
              type="text"
              id={`monto`}
              placeholder={"monto"}
              value={this.state.fields.monto}
              onChange={e => this.onChangeField(e, "monto")}
            />
          </Col>

          <Col>
            <BaseInput
              disabled={this.props.disabled}
              label={"*referenciaCrm"}
              name={`referenciaCrm`}
              type="text"
              id={`referenciaCrm`}
              placeholder={"referenciaCrm"}
              value={this.state.fields.referenciaCrm}
              onChange={e => this.onChangeField(e, "referenciaCrm")}
            />
          </Col>
          <Col sm={2}>
            <Button
              disabled={!this.state.validData}
              block
              onClick={() => this.addItem()}
              className="mt-4 mb-1"
              style={{ height: "2rem" }}
            >
              +PAGO
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <ReactTable
              pages={1}
              data={dataTable}
              columns={itemsTablecolumns}
              showPagination={false}
              sortable={true}
              multiSort={true}
              resizable={true}
              pageSize={this.props.pagos.length + 3}
            />
          </Col>
        </Row>
      </span>
    );
  }
}

export default withStyles(s)(PaymentsData);
