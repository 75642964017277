import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import moment from "moment";
import React from "react";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import { Button, Col, Container, Row } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import BaseNumericInput from "../../../toolkit/baseNumericInput";
import { handleRequestError } from "../../../utils/helpers";
import {
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import { getClubsRequest } from "../../actions/catalogs";
import { getReportPartnerMembershipTransfers } from "../../actions/reports";
import DatePicker from "../common/DatePicker";
import { Props, State } from "./interfaces";
import s from "./styles.scss";

class PartnerMembershipTransfersReport extends React.Component<Props, State> {
  state = {
    filters: {
      clubOrigenId: null,
      numeroPublicoOrigen: null,
      clubDestinoId: null,
      numeroPublicoDestino: null,
      fechaInicio: null,
      fechaFin: null
    },
    onSearch: false,
    clubs: [],
    data: []
  };

  componentDidMount() {
    this.getClubsCatalog();

    const { filters } = this.state;
    filters.clubOrigenId = this.props.userContext.clubSessionId;
    this.setState({ filters: { ...filters } });
  }

  getClubsCatalog = () => {
    getClubsRequest()
      .then(({ data }) => {
        this.setState({
          clubs: (data || []).map(option => ({
            value: option.clubId,
            label: option.nombre
          }))
        });
      })
      .catch(handleRequestError);
  };

  columns = [
    {
      Header: () => <p className="font-weight-bold">Club origen</p>,
      Cell: row => {
        return <p className={s.initial}>{row.original.clubNombreOrigen}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold"># Membresía Origen</p>,
      className: "text-center",
      Cell: row => {
        return <p className={s.initial}>{row.original.numeroPublicoOrigen}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Club Destino</p>,
      Cell: row => {
        return <p className={s.initial}>{row.original.clubNombreDestino}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold"># Membresía Destino</p>,
      className: "text-center",
      Cell: row => {
        return <p className={s.initial}>{row.original.numeroPublicoDestino}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Titular Destino</p>,
      Cell: row => {
        return (
          <p className={s.initial}>
            {row.original.personaTitularNombreDestino}
          </p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Fecha traspaso</p>,
      className: "text-center",
      Cell: row => {
        return <p className={s.initial}>{row.original.fechaTraspaso}</p>;
      }
    }
  ];

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const filters = {
          ...state.filters,
          [fieldName]: date
        };
        return { ...state, error: {}, filters };
      });
    };
  };

  onClickDownloadCsvFile = () => {
    this.props.downloadReportPartnerMembershipTransfers({
      ...this.state.filters
    });
  };

  search = () => {
    infoNotification("Consultando los datos ");

    this.setState({
      onSearch: true
    });

    getReportPartnerMembershipTransfers({ ...this.state.filters })
      .then(({ data }) => {
        this.setState({ data, onSearch: false });
        successNotification("Datos consultados correctamente");
      })
      .catch(handleRequestError);
  };

  render() {
    const { clubs, data } = this.state;

    return (
      <Container className="p-0">
        <Row>
          <Col xs={3}>
            <BaseInput
              id="clubOrigen"
              name="clubOrigen"
              label="Club Origen"
              type="select"
              value={this.state.filters.clubOrigenId}
              onChange={this.onChangeFilter("clubOrigenId")}
              size={"sm"}
              options={[{ value: "", label: "Seleccionar" }].concat(
                clubs || []
              )}
            />
          </Col>
          <Col xs={3}>
            <BaseNumericInput
              id="numeroPublicOrigen"
              name="numeroPublicOrigen"
              label={"# Membresia Origen"}
              value={this.state.filters.numeroPublicoOrigen}
              placeholder={"# Membresía"}
              onChange={this.onChangeFilter("numeroPublicoOrigen")}
            />
          </Col>

          <Col xs={3}>
            <BaseInput
              id="clubDestino"
              name="clubDestino"
              label="Club Destino"
              type="select"
              value={this.state.filters.clubDestinoId}
              onChange={this.onChangeFilter("clubDestinoId")}
              size={"sm"}
              options={[{ value: "", label: "Seleccionar" }].concat(
                clubs || []
              )}
            />
          </Col>
          <Col xs={3}>
            <BaseNumericInput
              id="numeroPublicdestino"
              name="numeroPublicdestino"
              label={"# Membresia Destino"}
              value={this.state.filters.numeroPublicoDestino}
              placeholder={"# Membresía"}
              onChange={this.onChangeFilter("numeroPublicoDestino")}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4} lg={4}>
            <DatePicker
              label="Fecha inicio"
              name="fechaInicio"
              id="fechaInicio"
              selected={this.state.filters.fechaInicio}
              onChange={this.onChangeDate("fechaInicio")}
            />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <DatePicker
              label="Fecha fin"
              name="fechaFin"
              id="fechaFin"
              selected={this.state.filters.fechaFin}
              onChange={this.onChangeDate("fechaFin")}
            />
          </Col>
          <Col xs={2} className="mt-4">
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.search}
            >
              Buscar
            </Button>
          </Col>
        </Row>

        {!!(data || []).length && (
          <Row className={"mt-5"}>
            <Col sm="6">
              Resultados de la búsqueda:{" "}
              <b
                style={{ fontWeight: "bolder", display: "inline-block" }}
                className="ml-3"
              >
                {(data || []).length}
              </b>
            </Col>
            <Col sm="6" className="text-right">
              <span>Exportar a CSV: </span>
              <Button
                id="btnReportExportCsv"
                color="link"
                onClick={this.onClickDownloadCsvFile}
              >
                <FontAwesomeIcon icon={faFileExport} color="black" />
              </Button>
            </Col>
          </Row>
        )}
        <ReactTable
          data={data || []}
          columns={this.columns}
          className="mt-2 -striped -highlight"
          sortable={false}
          resizable={false}
          pageSize={20}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(PartnerMembershipTransfersReport));
