import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import Reform from "@franleplant/reform";
import ModalFrame from "../../../../toolkit/modalFrame";
import BaseInput from "../../../../toolkit/baseInput";
import BaseNumericInput from "../../../../toolkit/baseNumericInput";
import DoubleNumericInput from "../../../../toolkit/doubleNumericInput";
import BaseInputWithCheck from "../../../../toolkit/baseInputWithCheck";
import DatePicker from "../../common/DatePicker";
import s from "./styles.scss";
import {
  Button,
  Container,
  Form,
  Col,
  Row,
  Input,
  Label,
  FormGroup
} from "reactstrap";
import moment from "moment";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTable from "react-table";
import cloneDeep from "lodash.clonedeep";
import { getSportsProductsFilteredRequest } from "../../../actions/catalogs";
import { handleRequestError } from "../../../../utils/helpers";
import ProductsBenefits from "../productsBenefits";

const formatTime = (dateTime?) =>
  moment(dateTime)
    .utc()
    .format("YYYY-MM-DD");

interface Props {
  isOpen: boolean;
  history: any;
  phases: object[];
  edited: any;
  banks: any[];
  classifications: any[];
  toggle: () => void;
  addPhase: (data: object) => void;
  searchSportsProducts: (args: object) => any;
}

interface State {
  fields: {
    id: string;
    fechaInicio: string;
    fechaFin: string;
    anioPreciosAplicar: string;
    mesesGratis: string;
    habilitadaKiosco: boolean;
    porcentajeContado: string;
    precioFijoContado: string;
    aplicaPorcentaje3msi: boolean;
    porcentaje3msi: string;
    precioFijo3msi: string;
    aplicaPorcentaje6msi: boolean;
    porcentaje6msi: string;
    precioFijo6msi: string;
    aplicaPorcentaje9msi: boolean;
    porcentaje9msi: string;
    precioFijo9msi: string;
    aplicaPorcentaje12msi: boolean;
    porcentaje12msi: string;
    precioFijo12msi: string;
    invitadosGratisEspejo: boolean;
    invitadosGratis: string;
    invitadosGratisFechaInicioPeriodo: string;
    invitadosGratisFechaFinPeriodo: string;
    invitadosGratisMeses: string;
    bancosDescuentos: any[];
    productosDescuentos: any[];
    key: any;
  };
  errors: object;
  //----------------------------------------
  tmpBankSelected: object;
  tmpBankDiscount: string;
  tmpClassificationId: number;
  tmpClassificationDiscountPerc: string;
  tmpClassificationDiscountAmount: string;
  tmpClassificationNoDiscountCodes: string;
  tmpProductSelected: object;
  tmpClassificationInitdate: string;
  tmpClassificationFinaldate: string;

  //----------------------------------------
  tmpClassificationProducts: any[];
}

class AddPhaseModal extends React.Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);

  state = {
    fields: {
      id: null,
      fechaInicio: formatTime(),
      fechaFin: formatTime(),
      anioPreciosAplicar: null,
      mesesGratis: null,
      habilitadaKiosco: false,
      porcentajeContado: null,
      precioFijoContado: null,
      aplicaPorcentaje3msi: false,
      porcentaje3msi: null,
      precioFijo3msi: null,
      aplicaPorcentaje6msi: false,
      porcentaje6msi: null,
      precioFijo6msi: null,
      aplicaPorcentaje9msi: false,
      porcentaje9msi: null,
      precioFijo9msi: null,
      aplicaPorcentaje12msi: false,
      porcentaje12msi: null,
      precioFijo12msi: null,
      invitadosGratisEspejo: false,
      invitadosGratis: null,
      invitadosGratisFechaInicioPeriodo: null,
      invitadosGratisFechaFinPeriodo: null,
      invitadosGratisMeses: null,
      bancosDescuentos: [],
      productosDescuentos: [],
      key: null
    },
    errors: {},

    tmpBankSelected: null,
    tmpBankDiscount: null,
    tmpClassificationId: null,
    tmpClassificationDiscountPerc: null,
    tmpClassificationDiscountAmount: null,
    tmpClassificationNoDiscountCodes: null,
    tmpProductSelected: null,
    tmpClassificationInitdate: null,
    tmpClassificationFinaldate: null,

    tmpClassificationProducts: []
  };

  validationRules = {
    nombre: { required: true },
    fechaInicio: {
      required: true,
      dateMustBeFuture: value => this.dateValidator(value),
      bothDates: value =>
        this.bothDatesValidator(value, this.state.fields.fechaFin),
      phasesDates: value => this.phasesDatesValidator(value)
    },
    fechaFin: {
      required: true,
      dateMustBeFuture: value => this.dateValidator(value),
      bothDates: value =>
        this.bothDatesValidator(this.state.fields.fechaInicio, value),
      phasesDates: value => this.phasesDatesValidator(value)
    },
    anioPreciosAplicar: { required: true }
  };

  validationMessages = {
    required: _ => `Obligatorio`,
    dateMustBeFuture: _ => `La fecha debe ser futura`,
    bothDates: _ => `La fecha inicial debe ser menor a la final`,
    maxDisccountValidation: _ => `El valor máximo es 100`,
    phasesDates: _ => `Esta fecha entra en otra fase`,
    default: _ => `Invalido`
  };

  bankColumns = [
    {
      Header: "Banco",
      accessor: "bancoNombre"
    },
    {
      Header: "% Descuento",
      accessor: "porcentajeDescuento",
      className: "text-center"
    },
    {
      Header: "Acciones",
      id: "id",
      accessor: banco => {
        return (
          <Container className="w-100 text-center">
            <Button
              color="link"
              onClick={() => {
                const { fields } = this.state;
                fields.bancosDescuentos = [
                  ...fields.bancosDescuentos.filter(
                    x => x.bancoId != banco.bancoId
                  )
                ];
                this.setState({ fields: { ...fields } });
              }}
              className={`${s.innerButton} ${s.primaryLink}`}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </Container>
        );
      }
    }
  ];

  discountsColumns = [
    {
      Header: "Tipo producto",
      accessor: "categoriaDescripcion"
    },
    {
      Header: "Producto",
      accessor: "productoNombre"
    },
    {
      Header: "% Descuento",
      accessor: "porcentajeDescuento",
      className: "text-center"
    },
    {
      Header: "$ Descuento",
      accessor: "montoDescuento",
      className: "text-center"
    },
    {
      Header: "No. Códigos Descuento",
      accessor: "numeroCodigosDescuento",
      className: "text-center"
    },
    {
      Header: "Acciones",
      id: "id",
      accessor: clasif => {
        return (
          <Container className="w-100 text-center">
            <Button
              color="link"
              onClick={() => {
                const { fields } = this.state;
                fields.productosDescuentos = [
                  ...fields.productosDescuentos.filter(
                    x => x.categoriaId != clasif.categoriaId
                  )
                ];
                this.setState({ fields: { ...fields } });
              }}
              className={`${s.innerButton} ${s.primaryLink}`}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </Container>
        );
      }
    }
  ];

  dateValidator = dateInput => {
    const date = moment(dateInput).startOf("day");
    const now = moment().startOf("day");
    return date.isBefore(now);
  };

  bothDatesValidator = (dateInput, otherInput) => {
    const date = new Date(dateInput);
    const other = new Date(otherInput);
    return dateInput && otherInput && date >= other;
  };

  phasesDatesValidator = value => {
    const now = moment(value);
    const invalidDateArray: any = this.props.phases.filter(
      (phase: any, index) => {
        if (index > 0) {
          const fechaInicio = moment(phase.fechaInicio);
          const fechaFin = moment(phase.fechaFin);
          return now.isSameOrAfter(fechaInicio) && now.isSameOrBefore(fechaFin);
        }
        return false;
      }
    );
    return invalidDateArray.length > 0 ? true : false;
  };

  onSubmit = e => {
    e.preventDefault();
    const obj = cloneDeep(this.state.fields);
    if (!obj.key) {
      obj.key = Math.floor(Math.random() * 100000 + 1);
    }
    this.props.addPhase(obj);
    this.clearFieldsValues();
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      if (!!(this.props.phases || []).length) {
        const lastPhase: any = this.props.phases[this.props.phases.length - 1];
        const { fields } = this.state;
        fields.fechaInicio =
          lastPhase && lastPhase.fechaFin
            ? formatTime(moment(lastPhase.fechaFin).add(1, "days"))
            : formatTime();

        fields.fechaFin =
          lastPhase && lastPhase.fechaFin
            ? formatTime(moment(lastPhase.fechaFin).add(2, "days"))
            : formatTime();

        this.setState({ fields: { ...fields } });
      }
      if (!!this.props.edited) {
        const edited = cloneDeep(this.props.edited);
        this.setState({
          fields: {
            id: edited.id || null,
            fechaInicio: edited.fechaInicio || formatTime(),
            fechaFin: edited.fechaFin || formatTime(),
            anioPreciosAplicar: edited.anioPreciosAplicar || null,
            mesesGratis: edited.mesesGratis || null,
            habilitadaKiosco: edited.habilitadaKiosco || false,
            porcentajeContado: edited.porcentajeContado || null,
            precioFijoContado: edited.precioFijoContado || null,
            aplicaPorcentaje3msi: edited.aplicaPorcentaje3msi || false,
            porcentaje3msi: edited.porcentaje3msi || null,
            precioFijo3msi: edited.precioFijo3msi || null,
            aplicaPorcentaje6msi: edited.aplicaPorcentaje6msi || false,
            porcentaje6msi: edited.porcentaje6msi || null,
            precioFijo6msi: edited.precioFijo6msi || null,
            aplicaPorcentaje9msi: edited.aplicaPorcentaje9msi || false,
            porcentaje9msi: edited.porcentaje9msi || null,
            precioFijo9msi: edited.precioFijo9msi || null,
            aplicaPorcentaje12msi: edited.aplicaPorcentaje12msi || false,
            porcentaje12msi: edited.porcentaje12msi || null,
            precioFijo12msi: edited.precioFijo12msi || null,
            invitadosGratisEspejo: edited.invitadosGratisEspejo || false,
            invitadosGratis: edited.invitadosGratis || null,
            invitadosGratisFechaInicioPeriodo:
              edited.invitadosGratisFechaInicioPeriodo || null,
            invitadosGratisFechaFinPeriodo:
              edited.invitadosGratisFechaFinPeriodo || null,
            invitadosGratisMeses: edited.invitadosGratisMeses || null,
            bancosDescuentos: edited.bancosDescuentos || [],
            productosDescuentos: edited.productosDescuentos || [],
            key: edited.key || null
          }
        });
      }
    }
  }

  onChangeFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };
        return { ...state, error: {}, fields };
      });
      this.re.validateField(fieldName, value);
    };
  };

  onChangeNumberFactory = fieldName => {
    return event => {
      const value = event.target.value.replace(/[^0-9]/gi, "");
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };
        return { ...state, error: {}, fields };
      });
      this.re.validateField(fieldName, value);
    };
  };
  onChangeNumberUpdateAndNull = (fieldName, nullFieldName) => {
    return event => {
      const value = event.target.value;
      const { fields } = this.state;
      fields[fieldName] = value;
      fields[nullFieldName] = null;
      this.setState({ fields: { ...fields } });
    };
  };

  onChangeDateFactory = fieldName => {
    return dateTime => {
      const value = formatTime(dateTime);
      this.setState({
        ...this.state,
        fields: { ...this.state.fields, [fieldName]: value }
      });
      this.re.validateField(fieldName, value);
    };
  };

  onChangeDateInvitados = fieldName => {
    return dateTime => {
      const value = formatTime(dateTime);
      this.setState({
        ...this.state,
        fields: {
          ...this.state.fields,
          [fieldName]: value,
          invitadosGratisMeses: null
        }
      });
      this.re.validateField(fieldName, value);
    };
  };

  onChangeInvitadosMeses = fieldName => {
    return event => {
      const value = event.target.value.replace(/[^0-9]/gi, "");
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value,
          invitadosGratisFechaInicioPeriodo: null,
          invitadosGratisFechaFinPeriodo: null
        };
        return { ...state, error: {}, fields };
      });
      this.re.validateField(fieldName, value);
    };
  };

  onClassificationLevelChange = (
    classificationId: number,
    parents: number[],
    level: number
  ) => {
    this.setState({
      tmpClassificationId: classificationId
    });

    if (level == 3) {
      const filters = {
        clasificacionId: classificationId,
        nombre: "",
        estatus: "ACTIVO",
        carrito: true
      };

      getSportsProductsFilteredRequest(filters)
        .then(({ data }) => {
          this.setState({ tmpClassificationProducts: [...data] });
        })
        .catch(handleRequestError);
    } else {
      this.setState({ tmpClassificationProducts: [] });
    }
  };

  clearFieldsValues = () => {
    this.setState({
      fields: {
        id: null,
        fechaInicio: formatTime(),
        fechaFin: formatTime(),
        anioPreciosAplicar: null,
        mesesGratis: null,
        habilitadaKiosco: false,
        porcentajeContado: null,
        precioFijoContado: null,
        aplicaPorcentaje3msi: false,
        porcentaje3msi: null,
        precioFijo3msi: null,
        aplicaPorcentaje6msi: false,
        porcentaje6msi: null,
        precioFijo6msi: null,
        aplicaPorcentaje9msi: false,
        porcentaje9msi: null,
        precioFijo9msi: null,
        aplicaPorcentaje12msi: false,
        porcentaje12msi: null,
        precioFijo12msi: null,
        invitadosGratisEspejo: false,
        invitadosGratis: null,
        invitadosGratisFechaInicioPeriodo: null,
        invitadosGratisFechaFinPeriodo: null,
        invitadosGratisMeses: null,
        bancosDescuentos: [],
        productosDescuentos: [],
        key: null
      },
      tmpBankSelected: null,
      tmpBankDiscount: null,
      tmpClassificationId: null,
      tmpClassificationDiscountPerc: null,
      tmpClassificationDiscountAmount: null,
      tmpClassificationNoDiscountCodes: null,
      tmpProductSelected: null,
      tmpClassificationInitdate: null,
      tmpClassificationFinaldate: null,

      tmpClassificationProducts: []
    });
  };

  render() {
    return (
      <ModalFrame
        title={`Agregar fase`}
        size={"lg"}
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <Container>
          <Form>
            <Row>
              <Col xs={3}>
                <DatePicker
                  label="Venta desde:"
                  name="fechaVentaDesde"
                  id="fechaVentaDesde"
                  selected={this.state.fields.fechaInicio}
                  onChange={this.onChangeDateFactory("fechaInicio")}
                  errors={this.re.mapFieldErrors("fechaInicio")}
                />
              </Col>
              <Col xs={3}>
                <DatePicker
                  label="Venta hasta:"
                  name="fechaVentaHasta"
                  id="fechaVentaHasta"
                  selected={this.state.fields.fechaFin}
                  onChange={this.onChangeDateFactory("fechaFin")}
                  errors={this.re.mapFieldErrors("fechaFin")}
                />
              </Col>
              <Col xs={3}>
                <BaseInput
                  label={"Respetar precios del Año"}
                  name="anioPreciosAplicar"
                  type="select"
                  id="anioPreciosAplicar"
                  value={this.state.fields.anioPreciosAplicar}
                  options={[
                    { value: "", label: "Seleccionar el año" },
                    { value: moment().year(), label: moment().year() },
                    { value: moment().year() + 1, label: moment().year() + 1 }
                  ]}
                  onChange={this.onChangeFactory("anioPreciosAplicar")}
                  errors={this.re.mapFieldErrors("anioPreciosAplicar")}
                />
              </Col>
              <Col xs={3}>
                <BaseNumericInput
                  label={"Meses gratis"}
                  name="noMesesGratis"
                  id="noMesesGratis"
                  type="text"
                  value={this.state.fields.mesesGratis}
                  placeholder={"Meses gratis"}
                  onChange={this.onChangeNumberFactory("mesesGratis")}
                  errors={this.re.mapFieldErrors("mesesGratis")}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={4} className="text-left">
                <FormGroup check className="form-check-inline">
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={this.state.fields.habilitadaKiosco}
                      onChange={e => {
                        const { fields } = this.state;
                        fields.habilitadaKiosco = e.target.checked;
                        this.setState({ fields: { ...fields } });
                      }}
                    />{" "}
                    Habilitar para Kiosco
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <p style={{ color: "red", fontWeight: "bold" }}>MSI</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <DoubleNumericInput
                  label={"Contado"}
                  type="text"
                  placeholder={"% Descuento contado"}
                  placeholder2={"Precio Fijo contado"}
                  value={this.state.fields.porcentajeContado}
                  value2={this.state.fields.precioFijoContado}
                  onChange={this.onChangeNumberUpdateAndNull(
                    "porcentajeContado",
                    "precioFijoContado"
                  )}
                  onChange2={this.onChangeNumberUpdateAndNull(
                    "precioFijoContado",
                    "porcentajeContado"
                  )}
                  errors={this.re.mapFieldErrors("porcentajeContado")}
                  doubleInput={true}
                />
              </Col>
              <Col>
                <BaseInputWithCheck
                  label={"Aplica 3 MSI"}
                  checked={this.state.fields.aplicaPorcentaje3msi}
                  placeholder={"% Descuento 3 MSI"}
                  placeholder2={"Precio Fijo 3 MSI"}
                  value={this.state.fields.porcentaje3msi}
                  value2={this.state.fields.precioFijo3msi}
                  onChange={this.onChangeNumberUpdateAndNull(
                    "porcentaje3msi",
                    "precioFijo3msi"
                  )}
                  onChange2={this.onChangeNumberUpdateAndNull(
                    "precioFijo3msi",
                    "porcentaje3msi"
                  )}
                  errors={this.re.mapFieldErrors("porcentaje3msi")}
                  onCheckedChange={(value: boolean) => {
                    const { fields } = this.state;
                    fields.aplicaPorcentaje3msi = value;
                    fields.porcentaje3msi = null;
                    fields.precioFijo3msi = null;
                    this.setState({ fields: { ...fields } });
                  }}
                  disabledWhenUnchecked={true}
                />
              </Col>
              <Col>
                <BaseInputWithCheck
                  label={"Aplica 6 MSI"}
                  checked={this.state.fields.aplicaPorcentaje6msi}
                  placeholder={"% Descuento 6 MSI"}
                  placeholder2={"Precio Fijo 6 MSI"}
                  value={this.state.fields.porcentaje6msi}
                  value2={this.state.fields.precioFijo6msi}
                  onChange={this.onChangeNumberUpdateAndNull(
                    "porcentaje6msi",
                    "precioFijo6msi"
                  )}
                  onChange2={this.onChangeNumberUpdateAndNull(
                    "precioFijo6msi",
                    "porcentaje6msi"
                  )}
                  errors={this.re.mapFieldErrors("porcentaje6msi")}
                  onCheckedChange={(value: boolean) => {
                    const { fields } = this.state;
                    fields.aplicaPorcentaje6msi = value;
                    fields.porcentaje6msi = null;
                    fields.precioFijo6msi = null;
                    this.setState({ fields: { ...fields } });
                  }}
                  disabledWhenUnchecked={true}
                />
              </Col>
              <Col>
                <BaseInputWithCheck
                  label={"Aplica 9 MSI"}
                  checked={this.state.fields.aplicaPorcentaje9msi}
                  placeholder={"% Descuento 9 MSI"}
                  placeholder2={"Precio Fijo 9 MSI"}
                  value={this.state.fields.porcentaje9msi}
                  value2={this.state.fields.precioFijo9msi}
                  onChange={this.onChangeNumberUpdateAndNull(
                    "porcentaje9msi",
                    "precioFijo9msi"
                  )}
                  onChange2={this.onChangeNumberUpdateAndNull(
                    "precioFijo9msi",
                    "porcentaje9msi"
                  )}
                  errors={this.re.mapFieldErrors("porcentaje9msi")}
                  onCheckedChange={(value: boolean) => {
                    const { fields } = this.state;
                    fields.aplicaPorcentaje9msi = value;
                    fields.porcentaje9msi = null;
                    fields.precioFijo9msi = null;
                    this.setState({ fields: { ...fields } });
                  }}
                  disabledWhenUnchecked={true}
                />
              </Col>
              <Col>
                <BaseInputWithCheck
                  label={"Aplica 12 MSI"}
                  checked={this.state.fields.aplicaPorcentaje12msi}
                  placeholder={"% Descuento 12 MSI"}
                  placeholder2={"Precio Fijo 12 MSI"}
                  value={this.state.fields.porcentaje12msi}
                  value2={this.state.fields.precioFijo12msi}
                  onChange={this.onChangeNumberUpdateAndNull(
                    "porcentaje12msi",
                    "precioFijo12msi"
                  )}
                  onChange2={this.onChangeNumberUpdateAndNull(
                    "precioFijo12msi",
                    "porcentaje12msi"
                  )}
                  errors={this.re.mapFieldErrors("porcentaje12msi")}
                  onCheckedChange={(value: boolean) => {
                    const { fields } = this.state;
                    fields.aplicaPorcentaje12msi = value;
                    fields.porcentaje12msi = null;
                    fields.precioFijo12msi = null;
                    this.setState({ fields: { ...fields } });
                  }}
                  disabledWhenUnchecked={true}
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <p style={{ color: "red", fontWeight: "bold" }}>
                  Invitados gratis
                </p>
              </Col>
            </Row>

            <Row>
              <Col xs={3}>
                <FormGroup check className="form-check-inline pl-1">
                  <Label check className="mt-4">
                    <Input
                      type="checkbox"
                      checked={this.state.fields.invitadosGratisEspejo}
                      onChange={e => {
                        const { fields } = this.state;
                        fields.invitadosGratisEspejo = e.target.checked;
                        fields.invitadosGratisFechaInicioPeriodo = null;
                        fields.invitadosGratisFechaFinPeriodo = null;
                        fields.invitadosGratisMeses = null;
                        this.setState({ fields });
                      }}
                    />{" "}
                    Invitados espejo (12 meses)
                  </Label>
                </FormGroup>
              </Col>
              <Col xs={3}>
                <BaseNumericInput
                  label={"# Invitados gratis"}
                  name="noInvitadosGratis"
                  id="noInvitadosGratis"
                  type="text"
                  value={this.state.fields.invitadosGratis}
                  placeholder={"# Invitados gratis"}
                  onChange={this.onChangeNumberFactory("invitadosGratis")}
                  errors={this.re.mapFieldErrors("invitadosGratis")}
                />
              </Col>
              <Col xs={3}>
                <DatePicker
                  label="Fecha inicio acceso:"
                  name="invitadosGratisFechaInicio"
                  id="invitadosGratisFechaInicio"
                  selected={this.state.fields.invitadosGratisFechaInicioPeriodo}
                  onChange={this.onChangeDateInvitados(
                    "invitadosGratisFechaInicioPeriodo"
                  )}
                  errors={this.re.mapFieldErrors(
                    "invitadosGratisFechaInicioPeriodo"
                  )}
                />
              </Col>
              <Col xs={3}>
                <DatePicker
                  label="Fecha fin acceso:"
                  name="invitadosGratisFechaFin"
                  id="invitadosGratisFechaFin"
                  selected={this.state.fields.invitadosGratisFechaFinPeriodo}
                  onChange={this.onChangeDateInvitados(
                    "invitadosGratisFechaFinPeriodo"
                  )}
                  errors={this.re.mapFieldErrors(
                    "invitadosGratisFechaFinPeriodo"
                  )}
                />
              </Col>
              <Col xs={3}>
                <BaseNumericInput
                  label={"# Meses acceso"}
                  name="noMesesAcceso"
                  id="noMesesAcceso"
                  type="text"
                  value={this.state.fields.invitadosGratisMeses || ""}
                  placeholder={"# Meses acceso"}
                  onChange={this.onChangeInvitadosMeses("invitadosGratisMeses")}
                  errors={this.re.mapFieldErrors("invitadosGratisMeses")}
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <p style={{ color: "red", fontWeight: "bold" }}>
                  Descuentos Bancos
                </p>
              </Col>
            </Row>

            <Row>
              <Col>
                <BaseInput
                  type="select"
                  label={"Banco"}
                  name="bankDiscountSelector"
                  id="bankDiscountSelector"
                  value={this.state.tmpBankSelected}
                  options={[{ value: null, label: "Seleccione" }].concat(
                    (this.props.banks || []).map(x => {
                      return { value: x.bancoId, label: x.nombre };
                    })
                  )}
                  onChange={e => {
                    this.setState({ tmpBankSelected: e.target.value });
                  }}
                />
              </Col>
              <Col>
                <BaseNumericInput
                  type="text"
                  label={"% Descuento"}
                  name="bankDiscountPercentage"
                  id="bankDiscountPercentage"
                  value={this.state.tmpBankDiscount}
                  onChange={e => {
                    this.setState({ tmpBankDiscount: e.target.value });
                  }}
                />
              </Col>
              <Col>
                <Button
                  className={`mt-4  ${s.primaryButton}`}
                  onClick={e => {
                    e.preventDefault();
                    const { tmpBankSelected, tmpBankDiscount } = this.state;
                    if (!tmpBankSelected || !tmpBankDiscount) {
                      return;
                    }

                    const banco = this.props.banks.find(
                      x => x.bancoId == tmpBankSelected
                    );

                    const { fields } = this.state;
                    fields.bancosDescuentos = [
                      ...fields.bancosDescuentos,
                      {
                        bancoId: banco.bancoId,
                        bancoNombre: banco.nombre,
                        porcentajeDescuento: tmpBankDiscount
                      }
                    ];

                    this.setState({
                      fields: { ...fields },
                      tmpBankSelected: null,
                      tmpBankDiscount: ""
                    });
                  }}
                  block
                  disabled={
                    !this.state.tmpBankSelected || !this.state.tmpBankDiscount
                  }
                >
                  Agregar
                </Button>
              </Col>
            </Row>
            <div className="mt-2">
              <ReactTable
                data={this.state.fields.bancosDescuentos}
                columns={this.bankColumns}
                className="-striped -highlight"
                sortable={false}
                showPagination={false}
                pageSize={(this.state.fields.bancosDescuentos || []).length + 1}
              />
            </div>

            <Row className="mt-3">
              <Col>
                <p style={{ color: "red", fontWeight: "bold" }}>
                  Descuentos productos
                </p>
              </Col>
            </Row>

            <ProductsBenefits
              classifications={this.props.classifications}
              addBenefit={productBenefit => {
                const { fields } = this.state;
                fields.productosDescuentos = [
                  ...fields.productosDescuentos,
                  productBenefit
                ];
                this.setState({
                  fields: { ...fields }
                });
              }}
            />
            <div className="mt-2">
              <ReactTable
                data={this.state.fields.productosDescuentos}
                columns={this.discountsColumns}
                className="-striped -highlight"
                sortable={false}
                showPagination={false}
                pageSize={
                  (this.state.fields.productosDescuentos || []).length + 1
                }
              />
            </div>

            <div className="mt-4">
              <Button
                className={`${s.buttonMarginTop} ${s.primaryButton}`}
                onClick={e => this.onSubmit(e)}
                size="lg"
                block
              >
                Guardar
              </Button>
            </div>
          </Form>
        </Container>
      </ModalFrame>
    );
  }
}

export default withRouter(withStyles(s)(AddPhaseModal));
