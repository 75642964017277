import React from "react";
import SelectFilter from "../common/SelectFilter";
import { Club } from "../../../types-business/Clubs";

interface Props {
  onSelectClub: (club: object) => any;
  club: {
    value: number;
    label: string;
  };
  clubs: Club[];
  isMulti?: boolean;
  disabled?: boolean;
  label?: string;
  userClub: number;
  soloMigrados?: boolean;
}

interface State {
  clubs: {
    value: number;
    label: string;
  }[];
  userClub: {
    value: number;
    label: string;
  };
}
class ClubCatalogSelect extends React.Component<Props, State> {
  state = {
    clubs: [],
    userClub: null
  };

  loadClubs = () => {
    const clubs = (!this.props.isMulti
      ? [{ value: 0, label: "Seleccione el Club" }]
      : []
    ).concat(
      this.props.clubs
        ? (!!this.props.soloMigrados
            ? this.props.clubs.filter(x => !!x.migradoCrm2)
            : this.props.clubs
          ).map(({ clubId: value, nombre: label }) => ({
            value,
            label
          }))
        : []
    );

    const userClub = this.props.userClub
      ? clubs.find(c => c.value == this.props.userClub)
      : null;

    this.setState({ clubs, userClub });

    if (userClub != null) {
      setTimeout(() => this.props.onSelectClub(userClub), 400);
    }

    return clubs;
  };

  componentDidMount() {
    this.loadClubs();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.clubs !== this.props.clubs ||
      prevProps.userClub !== this.props.userClub
    ) {
      const clubs = this.loadClubs();

      if (!this.props.isMulti) {
        let clubSelected = clubs[0];

        if (this.props.club && this.props.club.value) {
          const searchClub = clubs.find(
            ({ value }) => value === this.props.club.value
          );
          if (searchClub) {
            clubSelected = searchClub;
          }
        }

        this.props.onSelectClub(clubSelected);
      }
    }
  }

  render() {
    return (
      <SelectFilter
        value={this.props.club}
        onChange={this.props.onSelectClub}
        options={this.state.clubs}
        disabled={
          this.props.disabled || !this.state.clubs.length || this.props.userClub
        }
        label={this.props.label || "Club"}
        name="clubQuoteSportsProduct"
        id="clubQuoteSportsProduct"
        placeholder="Club"
        errors={[]}
        isMulti={this.props.isMulti}
      />
    );
  }
}
export default ClubCatalogSelect;
