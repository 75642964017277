import React from "react";
import { connect } from "react-redux";
import Layout from "../../components/layout";
import { Helmet } from "react-helmet";
import BillingPage from "../../components/billing";
import { getAllClubsBasicData } from "../../actions/clubs";
import { getAccountingAccounts } from "../../actions/catalogs";
import {
  sendToBilling,
  getBillingCatalogs,
  cancelBill,
  remakeBill,
  sendToAccrual
} from "../../actions/billing";
import isNil from "lodash.isnil";

const tipoPersona = [
  { idCampo: 0, label: "FISICA" },
  { idCampo: 1, label: "MORAL" }
];
const monedaData = [{ idCampo: 0, label: "demo" }];

const tipoItemData = [
  { idCampo: 0, label: "MANTENIMIENTO" },
  { idCampo: 1, label: "MEMBRESIA" },
  { idCampo: 2, label: "PRODUCTO" }
];
const tipoImpuestos = [
  { idCampo: 0, label: "TRASLADO" },
  { idCampo: 1, label: "RETENCION" }
];
const tipoFactor = [
  { idCampo: 0, label: "TASA" },
  { idCampo: 1, label: "CUOTA" },
  { idCampo: 2, label: "EXENTO " }
];
const origenPayment = [
  { idCampo: 0, label: "EFECTIVO" },
  { idCampo: 1, label: "CHEQUE" },
  { idCampo: 2, label: "CONEKTA_VENTA_LINEA" },
  { idCampo: 3, label: "CARGO_DIRECTO" },
  { idCampo: 4, label: "CONEKTA_APP" },
  { idCampo: 5, label: "CARGO_DIRECTO_AMEX" }
];
const wayPayment = [
  { idCampo: 0, label: "EFECTIVO" },
  { idCampo: 1, label: "CHEQUE" },
  { idCampo: 3, label: "TDC" },
  { idCampo: 4, label: "TDD" },
  { idCampo: 5, label: "CONEKTA" }
];

const plataformaOrigen = [
  { idCampo: 0, label: "CRM_1" },
  { idCampo: 1, label: "CRM_2" }
];
const terminosPagoErp = [
  { idCampo: 0, label: "DIAS_15" },
  { idCampo: 1, label: "DIAS_30" },
  { idCampo: 1, label: "DIAS_45" },
  { idCampo: 1, label: "DIAS_60" },
  { idCampo: 1, label: "DIAS_90" },
  { idCampo: 1, label: "INMEDIATO" }
];

const tipoTransaccionErp = [{ idCampo: 0, label: "SWTEC_FACTURA" }];

const unidadNegocio = [
  { idCampo: 0, label: "SWHLD" },
  { idCampo: 1, label: "SWOAS" },
  { idCampo: 1, label: "SWGCS" }
];

interface Props {
  clubs: object;
  billing: object;
  getClubs: () => any;
  sendToBilling: (data: any) => void;
  getBillingCatalogs: () => void;
  bill: object;
  cancelBill: (bill: object, uuid: string) => any;
  remakeBill: (bill: object, uuid: string) => any;
  success: boolean;
  getAccountingAccounts: () => any;
  accountingAccounts: object;
  sendToAccrual: (data: any) => void;
}

interface State {}

class Billing extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    this.props.getClubs();
    this.props.getBillingCatalogs();
    this.props.getAccountingAccounts();
  }

  componentDidUpdate() {}

  render() {
    return (
      <main>
        <Layout>
          <Helmet title="Facturación" />
          {this.props.clubs && this.props.billing["billingCatalogs"] && (
            <BillingPage
              billingData={this.props.billing}
              clubs={this.props.clubs}
              monedaData={monedaData}
              tipoPersona={tipoPersona}
              sendToBilling={this.props.sendToBilling}
              sendToAccrual={this.props.sendToAccrual}
              tipoItemData={tipoItemData}
              tipoImpuestos={tipoImpuestos}
              tipoFactor={tipoFactor}
              origenPayment={origenPayment}
              wayPayment={wayPayment}
              plataformaOrigen={plataformaOrigen}
              terminosPagoErp={terminosPagoErp}
              tipoTransaccionErp={tipoTransaccionErp}
              unidadNegocio={unidadNegocio}
              bill={this.props.bill}
              cancelBill={this.props.cancelBill}
              remakeBill={this.props.remakeBill}
              success={this.props.success}
              accountingAccounts={
                isNil(this.props.accountingAccounts)
                  ? []
                  : this.props.accountingAccounts
              }
            />
          )}
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getClubs: () => {
    return dispatch(getAllClubsBasicData());
  },
  sendToBilling: (data: any) => {
    return dispatch(sendToBilling(data));
  },
  sendToAccrual: (data: any) => {
    return dispatch(sendToAccrual(data));
  },
  getBillingCatalogs: () => {
    return dispatch(getBillingCatalogs());
  },
  cancelBill: (bill: object, uuid: string) => {
    return dispatch(cancelBill(bill, uuid));
  },
  remakeBill: (bill: object, uuid: string) => {
    return dispatch(remakeBill(bill, uuid));
  },
  getAccountingAccounts: () => {
    return dispatch(getAccountingAccounts());
  }
});

const mapStateToProps = state => {
  const {
    clubs: { allClubsBasicData },
    billing: { bill, remakeSuccess, cancelSuccess, ...rest },
    catalogs: { accountingAccounts }
  } = state;
  return {
    clubs: allClubsBasicData,
    billing: rest,
    bill,
    accountingAccounts: accountingAccounts,
    success: cancelSuccess || remakeSuccess
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Billing);
