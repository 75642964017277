import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import LockersSummary from "../../components/lockersSummary";
import { LockerSummary } from "../../../types/Lockers";
import * as lockersActions from "../../actions/lockers";

interface Props {
  lockersSummary: LockerSummary[];
  searching: boolean;
  getLockersSummaryByClub: () => any;
  match: any;
  history: any;
}

class LockersSummaryPage extends React.Component<Props> {
  componentDidMount() {
    this.props.getLockersSummaryByClub();
  }

  onClickDownloadCsvFile = e => {
    e.preventDefault();
    lockersActions.downloadLockersSummaryByClub();
  };

  render() {
    return (
      <main>
        <Helmet title="Resumen de lockers por club" />
        <Layout>
          <LockersSummary
            lockersSummary={this.props.lockersSummary}
            searching={this.props.searching}
            onClickDownloadCsvFile={this.onClickDownloadCsvFile}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getLockersSummaryByClub: () => {
    dispatch(lockersActions.getLockersSummaryByClub());
  }
});

const mapStateToProps = state => {
  const { lockers } = state;
  return {
    lockersSummary: lockers.lockersSummary,
    searching: lockers.gettingLockersSummary
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LockersSummaryPage);
