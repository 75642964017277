import React from "react";
import { connect } from "react-redux";
import { newUserActivity } from "../../actions/activities";
import NewUserActivity from "../../components/NewUserActivity";

interface Props {
  clubId: string;
  month: string;
  year: string;
  newUserActivity: (activity: object) => any;
  userId: number;
}

class SalesTotalsContainer extends React.Component<Props> {
  saveNewUserActivity = async ({ descripcion, userId }) => {
    const activty = {
      nota: descripcion,
      assignedTo: userId,
      idClub: this.props.clubId,
      idUsuario: this.props.userId,
      tipo: "NOTA",
      etapa: "PROSPECTO"
    };
    await this.props.newUserActivity(activty);
  };

  render() {
    return <NewUserActivity saveNewUserActivity={this.saveNewUserActivity} />;
  }
}

const mapDispatchToProps = {
  newUserActivity
};

const mapStateToProps = ({
  user: {
    userContext: { userId }
  }
}) => {
  return {
    userId
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesTotalsContainer);
