import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import MembershipMembers from "../../components/membershipMembers";
import {
  sendMembersTypes,
  getMemberships,
  getMembershipMembers
} from "../../actions/products";

interface Props {
  memberships: object;
  getMemberships: () => any;
  sendMembersTypes: (membresiaId: string, membersTypes: object[]) => any;
  getMembershipMembers: (membresiaId: string) => any;
  membershipTypesMembers: {
    tipoPersona: TIPO_PERSONA;
    nombre: string;
    maximoIntegrantes: number;
  }[];
}

enum TIPO_PERSONA {
  CLIENTE_TITULAR = 0,
  CLIENTE_INTEGRANTE = 1
}

class PartnersPage extends React.Component<Props, void> {
  componentDidMount() {
    this.props.getMemberships();
  }

  render() {
    return (
      <main>
        <Helmet title="Integrantes por Membresía" />
        <Layout>
          {this.props.memberships && (
            <MembershipMembers
              memberships={this.props.memberships}
              sendMembersTypes={this.props.sendMembersTypes}
              getMembershipMembers={this.props.getMembershipMembers}
              membershipTypesMembers={this.props.membershipTypesMembers}
            />
          )}
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getMemberships: () => {
    dispatch(getMemberships());
  },
  sendMembersTypes: (membresiaId, membersTypes) => {
    return dispatch(sendMembersTypes(membresiaId, membersTypes));
  },
  getMembershipMembers: membresiaId => {
    dispatch(getMembershipMembers(membresiaId));
  }
});

const mapStateToProps = state => {
  const { products } = state;
  const { memberships, membershipTypesMembers } = products;
  return {
    memberships: memberships && memberships.content,
    membershipTypesMembers: membershipTypesMembers
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnersPage);
