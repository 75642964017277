import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import SelectFilter from "../common/SelectFilter";
import { Container, Col, Row } from "reactstrap";
import ProductClassification from "../../containers/productClassification";
import ModalPicture from "../../../toolkit/modalPicture";
import { BFF_URL, IMAGES_ENDPOINT } from "../../../utils/constants";

interface Props {
  sportsProductsLoading: boolean;
  sportsProducts: { id: string; nombre: string; verEnCarrito: boolean }[];
  sportProduct: any;
  handleInputChange: (value: string) => void;
  onChangeFilter: (
    classificationId: number,
    parents: number[],
    level: number
  ) => void;
  onChangeSportProduct: (value: object) => void;
  onDropdownOpened: () => void;
  fitKidz: boolean;
  groupUser: string[];
}

const SearchSportProduct = ({
  sportsProductsLoading,
  sportsProducts,
  sportProduct,
  handleInputChange,
  onChangeFilter,
  onChangeSportProduct,
  onDropdownOpened,
  fitKidz,
  groupUser
}: Props) => (
  <Container className="w-100 p-0 mt-3">
    <Row>
      <Col xs={12} md={12} lg={8} className="pr-0">
        <ProductClassification
          setResult={onChangeFilter}
          fitKidz={fitKidz}
          groupUser={groupUser}
        ></ProductClassification>
      </Col>
      <Col xs={12} md={12} lg={4} className="pl-1">
        <Row>
          <Col xs={11}>
            <SelectFilter
              label={"Producto"}
              name="sportProduct"
              type="select"
              id="sportProduct"
              placeholder="Producto"
              value={sportProduct.selectedOnCombo}
              isMulti={false}
              options={
                sportsProducts
                  ? sportsProducts
                      .filter(({ verEnCarrito }) => !!verEnCarrito)
                      .map(({ id, nombre }) => ({
                        label: nombre,
                        value: id
                      }))
                  : []
              }
              onChange={onChangeSportProduct}
              loading={sportsProductsLoading}
              isAsync
              handleInputChange={handleInputChange}
              onDropdownOpened={onDropdownOpened}
            />
          </Col>
          <Col xs={1} className="pl-0" style={{ marginTop: "32px" }}>
            {sportProduct &&
              sportProduct.data &&
              sportProduct.data.foto &&
              sportProduct.data.foto.id && (
                <ModalPicture
                  title="FOTO DEL PRODUCTO"
                  url={`${BFF_URL}${IMAGES_ENDPOINT}/${sportProduct.data.foto.id}`}
                ></ModalPicture>
              )}
          </Col>
        </Row>
      </Col>
    </Row>
  </Container>
);
export default withRouter(withStyles(s)(SearchSportProduct));
