import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Container, TabPane, Row, Col, Button } from "reactstrap";
import s from "./tabStyles.scss";
import { Partner } from "./interfaces";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { getAccesos, getAccesosReporte } from "../../actions/partners";
import { errorNotification } from "../../../utils/notifications";
import TabInfoModal from "../../components/common/tableInfoModal/tableInfoModal";
import BaseButtonWithIconAndTooltip from "../../../toolkit/baseButtonWithIconAndTooltip";

interface Props {
  partner: Partner;
  activeTab: string;
  partnersClubAccessInfo: any[];
}

interface State {
  accesos: any[];
  wait: boolean;
  downloading: boolean;
  isModalOpen: boolean;
  modalData: any[];
}

class TabPartnersIngress extends React.Component<Props, State> {
  state = {
    accesos: null,
    wait: false,
    downloading: false,
    isModalOpen: false,
    modalData: []
  };

  columns = [
    {
      Header: "Club",
      id: "club",
      accessor: "club"
    },
    {
      Header: "Fecha",
      id: "fechaFormat",
      accessor: "fechaFormat"
    },
    {
      Header: "Tipo",
      id: "tipo",
      accessor: "tipo"
    },
    {
      Header: "Mensaje",
      id: "message",
      accessor: "message"
    }
  ];

  columnsClubAccess = [
    {
      Header: "Integrante",
      id: "clubAccessIntegrante",
      accessor: "personaNombre",
      className: "text-wrap",
      width: "300"
    },
    {
      Header: "Patrón de horario",
      id: "clubAccessPatron",
      accessor: "patronHorarioNombre",
      className: "text-center",
      width: "150"
    },
    {
      Header: "",
      className: "text-center",
      width: "60",
      Cell: row => {
        const { horarioDetalle } = row.original;
        return (
          <div>
            <BaseButtonWithIconAndTooltip
              id={`showSchedulePatternDetail_${row.original.personaId}${row.original.patronHorarioId}`}
              icon={faArrowRight}
              color={"#077"}
              toolTipPlacement={"right"}
              tooltipTitle={"Ver detalle de patrón de horario"}
              onClick={() => {
                this.handleSchedulePatternDetailView(horarioDetalle);
              }}
            />
          </div>
        );
      }
    },
    {
      Header: "Tipo",
      id: "clubAccessTipo",
      accessor: "tipo",
      width: "250"
    },
    {
      Header: "Clubes",
      id: "clubAccessClubes",
      accessor: "clubes",
      className: "text-wrap",
      width: "800"
    }
  ];

  columnsPatternDetail = [
    {
      Header: "DIA",
      id: "horarioDiaSemana",
      accessor: "diaSemana"
    },
    {
      Header: "Entrada",
      id: "horarioEntrada",
      accessor: "horarioEntrada"
    },
    {
      Header: "Salida",
      id: "horarioSalida",
      accessor: "horarioSalida"
    }
  ];

  componentDidUpdate(prevProps) {
    if (prevProps.activeTab != this.props.activeTab) {
      if (this.props.activeTab == "11") {
        this.setState({
          wait: true
        });

        getAccesos(this.props.partner.idClubBase, this.props.partner.idPublico)
          .then(response => {
            const accesos = response.data;

            accesos.forEach(socio => {
              socio.asistencias.forEach(asistencia => {
                asistencia.fechaFormat = formatDate(
                  asistencia.fecha,
                  asistencia.hora
                );
              });
            });

            this.setState({
              accesos: accesos,
              wait: false
            });
          })
          .catch(err => {
            errorNotification("No se pudieron consultar los accesos");
            this.setState({
              accesos: null,
              wait: false
            });
          });
      } else {
        this.setState({
          accesos: null,
          wait: false
        });
      }
    }
  }

  download = () => {
    this.setState({ downloading: true });
    getAccesosReporte(
      this.props.partner.idClubBase,
      this.props.partner.idPublico,
      () => this.setState({ downloading: false })
    );
  };

  handleSchedulePatternDetailView = data => {
    this.setState({ modalData: data, isModalOpen: true });
  };

  render() {
    if (this.props.activeTab != "11") {
      return <></>;
    }

    if (this.state.wait) {
      return (
        <Row>
          <Col>
            <p className="mt-3" style={{ fontSize: "1em" }}>
              Espere un momento por favor &nbsp;
              <FontAwesomeIcon
                icon={faSpinner}
                style={{ color: "#444" }}
                pulse={true}
              />
            </p>
          </Col>
        </Row>
      );
    }

    return (
      <TabPane className="my-2" tabId="11">
        <Container className="p-0 mt-3">
          <TabInfoModal
            title="Detalle de horario de acceso"
            message={""}
            isOpen={this.state.isModalOpen}
            size="md"
            columns={this.columnsPatternDetail}
            data={this.state.modalData}
            close={() => {
              this.setState({ isModalOpen: false, modalData: [] });
            }}
          />

          <div>
            <p className={`${s.primaryTitle} w-100 m-0`}>
              Configuración de acceso a clubes por integrante
            </p>
          </div>

          <div className="mt-3">
            <ReactTable
              className=" -striped -highlight"
              data={this.props.partnersClubAccessInfo || []}
              columns={this.columnsClubAccess}
              sortable={false}
              resizable={false}
              showPagination={false}
              pageSize={(this.props.partnersClubAccessInfo || []).length + 1}
            />
          </div>

          <div>
            <p className={`${s.primaryTitle} w-100 m-0 mt-4`}>
              Información de accesos a clubes
            </p>
          </div>
          <div className="mt-3">
            <Button
              className={`${s.searchButton} ${s.primaryButton}`}
              onClick={this.download}
              disabled={this.state.downloading}
            >
              Descargar
            </Button>
          </div>
          <div className="mt-3">
            {(this.state.accesos || []).map(socio => (
              <>
                <div className="mt-3">
                  ({socio.idPersona}) {socio.persona}
                </div>
                <ReactTable
                  key={socio.idPersona}
                  className=" -striped -highlight"
                  data={socio.asistencias || []}
                  columns={this.columns}
                  sortable={false}
                  resizable={false}
                  pageSize={
                    socio.asistencias && socio.asistencias.length > 5
                      ? socio.asistencias.length
                      : 5
                  }
                  showPagination={false}
                  loading={this.state.wait}
                />
              </>
            ))}
          </div>
        </Container>
      </TabPane>
    );
  }
}

function formatDate(date: string, time: string): string {
  try {
    const [year, month, day] = date.split("-");

    // Formato de la fecha:
    const fmtDate = `${day}/${month}/${year} `;
    const indexSeconds = time.lastIndexOf(":");
    const fmtTime = indexSeconds >= 4 ? time.substring(0, indexSeconds) : time;

    return fmtDate + fmtTime;
  } catch (error) {
    return (date || "") + " " + (time || "");
  }
}

export default withStyles(s)(TabPartnersIngress);
