import FormBuilderConfig from "../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import ITextBuilder from "../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";
import IButtonBuilder from "../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";

export type Inputs = "nombre" | "estatus";
export type Buttons = "buscar";
export const formConfig: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "nombre",
      label: "Nombre de la empresa",
      bts: {
        sCol: 6
      },
      component: ITextBuilder
    },
    {
      name: "estatus",
      label: "Estatus",
      bts: {
        sCol: 3
      }
    }
  ],
  buttons: [
    {
      name: "buscar",
      label: "Buscar",
      isSubmit: true,
      bts: {
        sCol: 3
      },
      component: IButtonBuilder
    }
  ],
  rows: [["nombre", "estatus", "buscar"]]
};
