import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Binnacles from "../../components/binnacles";
import * as binnacleActions from "../../actions/binnacles";

interface Props {
  getBinnacleByPartner: (params?: object) => any;
}

class BinnaclesPage extends React.Component<Props, void> {
  render() {
    return (
      <main>
        <Helmet title="Binnacles" />
        <Layout>
          <Binnacles getBinnacleByPartner={this.props.getBinnacleByPartner} />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getBinnacleByPartner: (args?: any) => {
    return dispatch(binnacleActions.getBinnacleByPartner(args));
  }
});

const mapStateToProps = state => {
  const { binnacles } = state;
  return {
    binnacles: binnacles.binnaclePartner || { data: [], totalPages: 0 }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BinnaclesPage);
