import React from "react";
import { connect } from "react-redux";
import { getProductAccounts } from "../../actions/catalogs";
import SelectCuenta from "../../components/selectCuenta";

interface CuentaProducto {
  cuentaProductoId: string;
  nombre: string;
  numeroCuenta: string;
}

interface Props {
  productAccounts: CuentaProducto[];
  getProductAccounts: () => any;
  value: string;
  onChange: (e) => any;
  disabled: boolean;
  gettingProductAccounts: boolean;
  size: string;
}

class RolesUserPage extends React.Component<Props> {
  componentDidMount() {
    if (!this.props.productAccounts && !this.props.gettingProductAccounts)
      this.props.getProductAccounts();
  }

  render() {
    const { productAccounts } = this.props;
    return (
      <div>
        {productAccounts && (
          <SelectCuenta
            value={this.props.value}
            onChange={this.props.onChange}
            disabled={this.props.disabled}
            size={this.props.size}
            id={"cuentaProducto"}
            options={this.props.productAccounts.map(account => ({
              ...account,
              id: account.cuentaProductoId
            }))}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getProductAccounts: () => {
    dispatch(getProductAccounts());
  }
});

const mapStateToProps = state => {
  const { catalogs } = state;
  const { productAccounts, gettingProductAccounts } = catalogs;
  return {
    productAccounts,
    gettingProductAccounts
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RolesUserPage);
