export function deleteEmptyProps(filters: any): any {
  const obj = filters || {};

  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== "" && obj[key] !== null && obj[key] !== undefined) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}
