import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import { getErrorMessage } from "../../utils/helpers";
import {
  infoNotification,
  errorNotification,
  successNotification,
  CLUBS_CREATED,
  CLUBS_ERROR,
  CLUBS_WAITING,
  PRICES_CAPTURED_WAITING,
  PRICES_CAPTURED_ERROR,
  PRICES_CAPTURED,
  PRICES_AUTHORIZATION_WAITING,
  PRICES_AUTHORIZATION_ERROR,
  PRICES_AUTHORIZATION,
  BASIC_DATA_PRODUCT_WAITING,
  BASIC_DATA_PRODUCT_ERROR,
  BASIC_DATA_PRODUCT,
  GENERAL_CONFIG_MAINTENANCE_WAITING,
  GENERAL_CONFIG_MAINTENANCE_ERROR,
  GENERAL_CONFIG_MAINTENANCE,
  ACCESS_MAINTENANCE,
  ACCESS_MAINTENANCE_WAITING,
  ACCESS_MAINTENANCE_ERROR,
  DOWNLOAD_FILE_WAITING,
  DOWNLOAD_FILE_ERROR,
  DOWNLOAD_FILE,
  LOAD_FILE_WAITING,
  LOAD_FILE_ERROR,
  LOAD_FILE,
  SAVE_PRICES_MAINTENANCE_PENDING,
  SAVE_PRICES_MAINTENANCE_ERROR,
  SAVE_PRICES_MAINTENANCE,
  MEMBERS_TYPE_MEMBERSHIP,
  MEMBERS_TYPE_MEMBERSHIP_ERROR,
  MEMBERS_TYPE_MEMBERSHIP_PENDING,
  SEND_MEMBERS_RULES_PENDING,
  SEND_MEMBERS_RULES_ERROR,
  SEND_MEMBERS_RULES,
  ACTIVATE_PRODUCTS_PENDING,
  ACTIVATE_PRODUCTS_ERROR,
  ACTIVATE_PRODUCTS,
  RIGHTS_CHANGE_REJECTED_MESSAGE,
  RIGHTS_CHANGE_SUCCESS_MESSAGE,
  CREATE_MAINTENANCE_DISCOUNT_SUCCESS_MESSAGE,
  CREATE_MAINTENANCE_DISCOUNT_REJECTED_MESSAGE,
  FULFILLED_QUIZ_ADD,
  PENDING_QUIZ_ADD,
  REJECTED_ADD_MESSAGE,
  FULFILLED_QUIZ_SEARCH,
  PENDING_QUIZ_SEARCH,
  REJECTED_QUIZ_SEARCH,
  CREATE_MAINTENANCE_CHARGES_PENDING,
  CREATE_MAINTENANCE_CHARGES_ERROR,
  CREATE_MAINTENANCE_CHARGES,
  ERROR,
  GET_MEMBER_BY_CONFIGURATION_PENDING,
  GET_MEMBER_BY_CONFIGURATION_ERROR,
  GET_MEMBER_BY_CONFIGURATION_FULFILLED,
  GET_PAYROLL_CONCEPT_PENDING,
  GET_PAYROLL_CONCEPT_ERROR,
  GET_PAYROLL_CONCEPT,
  GET_PAYROLL_CONCEPT_SELECT_PENDING,
  GET_PAYROLL_CONCEPT_SELECT_ERROR,
  GET_PAYROLL_CONCEPT_SELECT,
  POST_PAYROLL_CONCEPT_SAVE_PENDING,
  POST_PAYROLL_CONCEPT_SAVE_ERROR,
  POST_PAYROLL_CONCEPT_SAVE
} from "../../utils/notifications";
interface State {
  categories: object[];
  indicators: object[];
  getProductsCategoriesError: object;
  gettingCategories: boolean;
  basicDataSaved: boolean;
  clubsSaved: boolean;
  pricesSaved: boolean;
  basicProductSaved: object;
  accessProductSaved: object;
  access: object[];
  accessSaved: boolean;
  pricesToProduct: object[];
  loadingPricesToProduct: boolean;
  savingPrices: boolean;
  savingPricesError: boolean;
  gettingBasicProduct: boolean;
  basicProduct: object;
  gettingBasicConfig: boolean;
  basicConfig: object;
  savedBasicConfig: boolean;
  updatedBasicConfig: boolean;
  gettingMemberships: boolean;
  memberships: object[];
  gettingProductsError: object;
  gettingProducts: boolean;
  products: object[];
  productClubs: object[];
  accountingAccounts: object[];
  gettingAccountingAccounts: boolean;
  pricesToMaintenanceSaved: boolean;
  pricesToMaintenance: object[];
  loadingPricesToMaintenance: boolean;
  savingPricesMaintenance: boolean;
  savingPricesMaintenanceError: boolean;
  authorizingPrices: boolean;
  authorizingPricesError: object;
  maintenancesPrices: object[];
  maintenancesShedule: object[];
  loadingMaintenancesPrices: boolean;
  loadingMaintenancesShedule: boolean;
  maintenancesByAttributes: object[];
  fileLoaded: boolean;
  fileDownloaded: boolean;
  pricesFileErrors: string[];
  pricesFile: Blob;
  membershipById: object;
  membershipsMaintenance: object[];
  membersTypeUpdated: boolean;
  maintenancesMembership: object[];
  membershipTypesMembers: object[];
  membersRules: object;
  gettingProductsAllTypesError: object;
  gettingProductsAllTypes: boolean;
  productsAllTypes: object[];
  reloadProductsAllTypes: boolean;
  massivePricesMemberships: object;
  loadingMassivePricesMemberships: boolean;
  productClubsLoaded: boolean;
  classificationByLevel: object[];
  productPhoto: Blob;
  addQuizMessageError: any;
  getQuizesMessageError: any;
  messageQuizCreated: boolean;
  loadingGetQuizzes: boolean;
  listQuizzes: object;
  loadingGetQuestionsQuizzes: boolean;
  listQuestionsQuizzes: object;
  listSubcategories: object;
  createMaintenanceCharges: boolean;
  listHistoryAutCharges: object;
  memberConfiguration: object[];
  payrollConcepts: object[];
  payrollConceptsSelect: object[];
  savePayrollConcept: boolean;
  listProductsWithComissionConfigToAuth: object[];
  searchingProductsWithComissionConfigToAuth: boolean;
}
const initialState: State = {
  categories: null,
  getProductsCategoriesError: null,
  gettingCategories: false,
  basicDataSaved: false,
  basicProductSaved: null,
  accessProductSaved: null,
  clubsSaved: false,
  pricesSaved: false,
  indicators: null,
  access: null,
  accessSaved: false,
  pricesToProduct: null,
  loadingPricesToProduct: false,
  savingPrices: false,
  savingPricesError: false,
  gettingBasicProduct: false,
  basicProduct: null,
  gettingBasicConfig: false,
  basicConfig: null,
  savedBasicConfig: false,
  updatedBasicConfig: false,
  gettingMemberships: false,
  memberships: [],
  gettingProductsError: null,
  gettingProducts: false,
  products: null,
  productClubs: null,
  accountingAccounts: null,
  gettingAccountingAccounts: false,
  pricesToMaintenanceSaved: false,
  pricesToMaintenance: null,
  loadingPricesToMaintenance: false,
  savingPricesMaintenance: false,
  savingPricesMaintenanceError: false,
  authorizingPrices: false,
  authorizingPricesError: null,
  maintenancesPrices: null,
  maintenancesShedule: null,
  loadingMaintenancesPrices: false,
  loadingMaintenancesShedule: false,
  maintenancesByAttributes: null,
  fileLoaded: false,
  fileDownloaded: false,
  pricesFileErrors: null,
  pricesFile: null,
  membershipById: null,
  membershipsMaintenance: null,
  membersTypeUpdated: false,
  maintenancesMembership: null,
  membershipTypesMembers: null,
  membersRules: null,
  gettingProductsAllTypesError: null,
  gettingProductsAllTypes: false,
  productsAllTypes: null,
  reloadProductsAllTypes: false,
  massivePricesMemberships: null,
  loadingMassivePricesMemberships: false,
  productClubsLoaded: false,
  classificationByLevel: null,
  productPhoto: null,
  addQuizMessageError: null,
  getQuizesMessageError: null,
  messageQuizCreated: false,
  loadingGetQuizzes: false,
  listQuizzes: null,
  loadingGetQuestionsQuizzes: false,
  listQuestionsQuizzes: null,
  listSubcategories: null,
  createMaintenanceCharges: false,
  listHistoryAutCharges: null,
  memberConfiguration: [],
  payrollConcepts: [],
  payrollConceptsSelect: [],
  savePayrollConcept: false,
  listProductsWithComissionConfigToAuth: null,
  searchingProductsWithComissionConfigToAuth: false
};
import { readMessageBack } from "../../utils/readMessageBack";
import { LINEA_PAGO } from "../../utils/constants";

export default function productsReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.SET_PRODUCT_PHOTO}`: {
      return {
        ...state,
        productPhoto: action.payload
      };
    }
    case `${actionTypes.GET_PRODUCTS_CLASSIFICATION_BY_LEVEL}_PENDING`: {
      return {
        ...state,
        classificationByLevel: null
      };
    }
    case `${actionTypes.GET_PRODUCTS_CLASSIFICATION_BY_LEVEL}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        classificationByLevel: null
      };
    }
    case `${actionTypes.GET_PRODUCTS_CLASSIFICATION_BY_LEVEL}_FULFILLED`: {
      return {
        ...state,
        classificationByLevel: action.payload.data
      };
    }
    case `${actionTypes.GET_PRODUCTS_ALL_TYPES}_PENDING`: {
      return {
        ...state,
        gettingProductsAllTypesError: null,
        gettingProductsAllTypes: true,
        productsAllTypes: null
      };
    }
    case `${actionTypes.GET_PRODUCTS_ALL_TYPES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        gettingProductsAllTypesError: getErrorMessage(action.payload),
        productsAllTypes: null,
        gettingProductsAllTypes: false
      };
    }
    case `${actionTypes.GET_PRODUCTS_ALL_TYPES}_FULFILLED`: {
      return {
        ...state,
        gettingProductsAllTypesError: null,
        productsAllTypes: action.payload.data,
        gettingProductsAllTypes: false,
        reloadProductsAllTypes: false
      };
    }
    case `${actionTypes.ACTIVATE_PRODUCTS}_PENDING`: {
      infoNotification(ACTIVATE_PRODUCTS_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.ACTIVATE_PRODUCTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ACTIVATE_PRODUCTS_ERROR));
      return {
        ...state
      };
    }
    case `${actionTypes.ACTIVATE_PRODUCTS}_FULFILLED`: {
      successNotification(ACTIVATE_PRODUCTS);
      return {
        ...state,
        reloadProductsAllTypes: true
      };
    }
    case `${actionTypes.GET_PAYROLL_CONCEPT}_PENDING`: {
      infoNotification(GET_PAYROLL_CONCEPT_PENDING);
      return {
        ...state,
        payrollConcepts: []
      };
    }
    case `${actionTypes.GET_PAYROLL_CONCEPT}_REJECTED`: {
      errorNotification(readMessageBack(action, GET_PAYROLL_CONCEPT_ERROR));
      return {
        ...state,
        payrollConcepts: []
      };
    }
    case `${actionTypes.GET_PAYROLL_CONCEPT}_FULFILLED`: {
      successNotification(GET_PAYROLL_CONCEPT);
      return {
        ...state,
        payrollConcepts: action.payload.data
      };
    }
    case `${actionTypes.GET_PAYROLL_CONCEPT_SELECT}_PENDING`: {
      infoNotification(GET_PAYROLL_CONCEPT_SELECT_PENDING);
      return {
        ...state,
        payrollConceptsSelect: []
      };
    }
    case `${actionTypes.GET_PAYROLL_CONCEPT_SELECT}_REJECTED`: {
      errorNotification(
        readMessageBack(action, GET_PAYROLL_CONCEPT_SELECT_ERROR)
      );
      return {
        ...state,
        payrollConceptsSelect: []
      };
    }
    case `${actionTypes.GET_PAYROLL_CONCEPT_SELECT}_FULFILLED`: {
      successNotification(GET_PAYROLL_CONCEPT_SELECT);
      return {
        ...state,
        payrollConceptsSelect: action.payload.data
      };
    }
    case `${actionTypes.POST_PAYROLL_CONCEPT_SAVE}_PENDING`: {
      infoNotification(POST_PAYROLL_CONCEPT_SAVE_PENDING);
      return {
        ...state,
        savePayrollConcept: false
      };
    }
    case `${actionTypes.POST_PAYROLL_CONCEPT_SAVE}_REJECTED`: {
      errorNotification(
        readMessageBack(action, POST_PAYROLL_CONCEPT_SAVE_ERROR)
      );
      return {
        ...state,
        savePayrollConcept: false
      };
    }
    case `${actionTypes.POST_PAYROLL_CONCEPT_SAVE}_FULFILLED`: {
      successNotification(POST_PAYROLL_CONCEPT_SAVE);
      return {
        ...state,
        savePayrollConcept: true
      };
    }
    case `${actionTypes.GET_MEMBERSHIP_BY_ID}_PENDING`: {
      return {
        ...state,
        membershipById: null
      };
    }
    case `${actionTypes.GET_MEMBERSHIP_BY_ID}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        membershipById: null
      };
    }
    case `${actionTypes.GET_MEMBERSHIP_BY_ID}_FULFILLED`: {
      return {
        ...state,
        membershipById: action.payload.data
      };
    }
    case `${actionTypes.GET_PRODUCTS}_PENDING`: {
      return {
        ...state,
        gettingProductsError: null,
        gettingProducts: true
      };
    }
    case `${actionTypes.GET_PRODUCTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        gettingProductsError: getErrorMessage(action.payload),
        products: null,
        gettingProducts: false
      };
    }
    case `${actionTypes.GET_PRODUCTS}_FULFILLED`: {
      return {
        ...state,
        gettingProductsError: null,
        products: action.payload.data,
        gettingProducts: false
      };
    }
    case `${actionTypes.SET_PRICES_FILE}`: {
      return {
        ...state,
        pricesFile: action.payload,
        pricesFileErrors: null
      };
    }
    case `${actionTypes.SEND_PRICES_FILE}_PENDING`: {
      infoNotification(LOAD_FILE_WAITING);
      return {
        ...state,
        fileLoaded: false,
        pricesFileErrors: null,
        pricesFile: null
      };
    }
    case `${actionTypes.SEND_PRICES_FILE}_REJECTED`: {
      errorNotification(readMessageBack(action, LOAD_FILE_ERROR));
      const pricesFileErrors = action.payload.response.data.body.filter(
        line => line.length > 0
      );

      return {
        ...state,
        pricesFileErrors,
        fileLoaded: false,
        pricesFile: null
      };
    }
    case `${actionTypes.SEND_PRICES_FILE}_FULFILLED`: {
      successNotification(readMessageBack(action, LOAD_FILE));
      return {
        ...state,
        fileLoaded: true,
        pricesFileErrors: null,
        pricesFile: null
      };
    }
    case `${actionTypes.GET_MASSIVE_PRICES_FILE}_PENDING`: {
      infoNotification(DOWNLOAD_FILE_WAITING);
      return {
        ...state,
        fileLoaded: false
      };
    }
    case `${actionTypes.GET_MASSIVE_PRICES_FILE}_REJECTED`: {
      errorNotification(readMessageBack(action, DOWNLOAD_FILE_ERROR));
      return {
        ...state,
        fileLoaded: false
      };
    }
    case `${actionTypes.GET_MASSIVE_PRICES_FILE}_FULFILLED`: {
      successNotification(DOWNLOAD_FILE);
      return {
        ...state,
        fileLoaded: true
      };
    }
    case `${actionTypes.AUTHORIZE_MEMBERSHIP_PRICES}_PENDING`: {
      infoNotification(PRICES_AUTHORIZATION_WAITING);
      return {
        ...state,
        authorizingPrices: true,
        authorizingPricesError: null
      };
    }
    case `${actionTypes.AUTHORIZE_MEMBERSHIP_PRICES}_REJECTED`: {
      errorNotification(readMessageBack(action, PRICES_AUTHORIZATION_ERROR));
      return {
        ...state,
        authorizingPrices: false,
        authorizingPricesError: getErrorMessage(action.payload)
      };
    }
    case `${actionTypes.AUTHORIZE_MEMBERSHIP_PRICES}_FULFILLED`: {
      successNotification(PRICES_AUTHORIZATION);
      return {
        ...state,
        authorizingPrices: false,
        authorizingPricesError: null
      };
    }
    case `${actionTypes.AUTHORIZE_MAINTENANCES_PRICES}_PENDING`: {
      infoNotification(PRICES_AUTHORIZATION_WAITING);
      return {
        ...state,
        authorizingPrices: true,
        authorizingPricesError: null
      };
    }
    case `${actionTypes.AUTHORIZE_MAINTENANCES_PRICES}_REJECTED`: {
      errorNotification(readMessageBack(action, PRICES_AUTHORIZATION_ERROR));
      return {
        ...state,
        authorizingPrices: false,
        authorizingPricesError: getErrorMessage(action.payload)
      };
    }
    case `${actionTypes.AUTHORIZE_MAINTENANCES_PRICES}_FULFILLED`: {
      successNotification(PRICES_AUTHORIZATION);
      return {
        ...state,
        authorizingPrices: false,
        authorizingPricesError: null
      };
    }
    case `${actionTypes.ADD_CLUBS_PRODUCT}_PENDING`: {
      infoNotification(CLUBS_WAITING);
      return {
        ...state,
        clubsSaved: false
      };
    }
    case `${actionTypes.ADD_CLUBS_PRODUCT}_REJECTED`: {
      errorNotification(readMessageBack(action, CLUBS_ERROR));
      return {
        ...state,
        clubsSaved: false
      };
    }
    case `${actionTypes.ADD_CLUBS_PRODUCT}_FULFILLED`: {
      successNotification(CLUBS_CREATED);
      return {
        ...state,
        clubsSaved: true
      };
    }
    case `${actionTypes.ADD_PRICES_PRODUCT}_PENDING`: {
      infoNotification(PRICES_CAPTURED_WAITING);
      return {
        ...state,
        pricesSaved: false,
        savingPrices: true,
        savingPricesError: false
      };
    }
    case `${actionTypes.ADD_PRICES_PRODUCT}_REJECTED`: {
      errorNotification(readMessageBack(action, PRICES_CAPTURED_ERROR));
      return {
        ...state,
        pricesSaved: false,
        savingPrices: false,
        savingPricesError: true
      };
    }
    case `${actionTypes.ADD_PRICES_PRODUCT}_FULFILLED`: {
      successNotification(PRICES_CAPTURED);
      return {
        ...state,
        pricesSaved: true,
        savingPrices: false,
        savingPricesError: false
      };
    }
    case `${actionTypes.GET_MASSIVE_PRICES_MEMBERSHIP}_PENDING`: {
      return {
        ...state,
        massivePricesMemberships: null,
        loadingMassivePricesMemberships: true,
        pricesToProduct: null,
        loadingPricesToProduct: false
      };
    }
    case `${actionTypes.GET_MASSIVE_PRICES_MEMBERSHIP}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        massivePricesMemberships: null,
        loadingMassivePricesMemberships: false,
        pricesToProduct: null,
        loadingPricesToProduct: false
      };
    }
    case `${actionTypes.GET_MASSIVE_PRICES_MEMBERSHIP}_FULFILLED`: {
      return {
        ...state,
        massivePricesMemberships: action.payload.data,
        loadingMassivePricesMemberships: false,
        pricesToProduct: null,
        loadingPricesToProduct: false
      };
    }
    case `${actionTypes.GET_PRICES_PRODUCT}_PENDING`: {
      return {
        ...state,
        pricesToProduct: null,
        loadingPricesToProduct: true,
        massivePricesMemberships: null,
        loadingMassivePricesMemberships: false
      };
    }
    case `${actionTypes.GET_PRICES_PRODUCT}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        pricesToProduct: null,
        loadingPricesToProduct: false,
        massivePricesMemberships: null,
        loadingMassivePricesMemberships: false
      };
    }
    case `${actionTypes.GET_PRICES_PRODUCT}_FULFILLED`: {
      return {
        ...state,
        pricesToProduct: action.payload.data,
        loadingPricesToProduct: false,
        massivePricesMemberships: null,
        loadingMassivePricesMemberships: false
      };
    }
    case `${actionTypes.GET_BI_INDICATORS}_PENDING`: {
      return {
        ...state,
        indicators: null
      };
    }
    case `${actionTypes.GET_BI_INDICATORS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        indicators: null
      };
    }
    case `${actionTypes.GET_BI_INDICATORS}_FULFILLED`: {
      return {
        ...state,
        indicators: action.payload.data
      };
    }
    case `${actionTypes.GET_CLUBS_PRODUCT}_PENDING`: {
      return {
        ...state,
        productClubs: null,
        productClubsLoaded: false
      };
    }
    case `${actionTypes.GET_CLUBS_PRODUCT}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        productClubs: null,
        productClubsLoaded: false
      };
    }
    case `${actionTypes.GET_CLUBS_PRODUCT}_FULFILLED`: {
      return {
        ...state,
        productClubs: action.payload.data,
        productClubsLoaded: true
      };
    }
    case `${actionTypes.SEND_MEMBERS_RULES}_PENDING`: {
      infoNotification(SEND_MEMBERS_RULES_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.SEND_MEMBERS_RULES}_REJECTED`: {
      errorNotification(readMessageBack(action, SEND_MEMBERS_RULES_ERROR));
      return {
        ...state
      };
    }
    case `${actionTypes.SEND_MEMBERS_RULES}_FULFILLED`: {
      successNotification(SEND_MEMBERS_RULES);
      return {
        ...state
      };
    }
    case `${actionTypes.GET_MEMBERS_RULES}_PENDING`: {
      return {
        ...state,
        membersRules: null
      };
    }
    case `${actionTypes.GET_MEMBERS_RULES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        membersRules: null
      };
    }
    case `${actionTypes.GET_MEMBERS_RULES}_FULFILLED`: {
      return {
        ...state,
        membersRules: action.payload.data
      };
    }
    case `${actionTypes.GET_MEMBERSHIP_MAINTENANCE}_PENDING`: {
      return {
        ...state,
        membershipsMaintenance: null
      };
    }
    case `${actionTypes.GET_MEMBERSHIP_MAINTENANCE}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        membershipsMaintenance: null
      };
    }
    case `${actionTypes.GET_MEMBERSHIP_MAINTENANCE}_FULFILLED`: {
      return {
        ...state,
        membershipsMaintenance: action.payload.data
      };
    }
    case `${actionTypes.GET_PRODUCTS_CATEGORIES}_PENDING`: {
      return {
        ...state,
        categories: null
      };
    }
    case `${actionTypes.GET_PRODUCTS_CATEGORIES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        categories: null
      };
    }
    case `${actionTypes.GET_PRODUCTS_CATEGORIES}_FULFILLED`: {
      return {
        ...state,
        categories: action.payload.data
      };
    }
    case `${actionTypes.SAVE_BASIC_PRODUCT}_PENDING`: {
      infoNotification(BASIC_DATA_PRODUCT_WAITING);
      return {
        ...state,
        basicDataSaved: false,
        basicProductSaved: null
      };
    }
    case `${actionTypes.SAVE_BASIC_PRODUCT}_REJECTED`: {
      errorNotification(readMessageBack(action, BASIC_DATA_PRODUCT_ERROR));
      return {
        ...state,
        basicProductSaved: null,
        basicDataSaved: false
      };
    }
    case `${actionTypes.SAVE_BASIC_PRODUCT}_FULFILLED`: {
      successNotification(BASIC_DATA_PRODUCT);
      return {
        ...state,
        basicDataSaved: true,
        basicProductSaved: action.payload.data
      };
    }
    case `${actionTypes.GET_ACCESS_PRODUCT}_PENDING`: {
      return {
        ...state,
        accessSaved: false,
        access: null
      };
    }
    case `${actionTypes.GET_ACCESS_PRODUCT}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        access: null
      };
    }
    case `${actionTypes.GET_ACCESS_PRODUCT}_FULFILLED`: {
      return {
        ...state,
        access: action.payload.data
      };
    }
    case `${actionTypes.ADD_ACCESS_PRODUCT}_PENDING`: {
      infoNotification(ACCESS_MAINTENANCE_WAITING);
      return {
        ...state,
        accessSaved: false
      };
    }
    case `${actionTypes.ADD_ACCESS_PRODUCT}_REJECTED`: {
      errorNotification(readMessageBack(action, ACCESS_MAINTENANCE_ERROR));
      return {
        ...state,
        accessSaved: false
      };
    }
    case `${actionTypes.ADD_ACCESS_PRODUCT}_FULFILLED`: {
      successNotification(ACCESS_MAINTENANCE);
      return {
        ...state,
        accessSaved: true,
        accessProductSaved: action.payload.data,
        access: null
      };
    }
    case `${actionTypes.GET_BASIC_PRODUCT}_PENDING`: {
      return {
        ...state,
        gettingBasicProduct: true,
        basicProduct: null
      };
    }
    case `${actionTypes.GET_BASIC_PRODUCT}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        gettingBasicProduct: false,
        basicProduct: null
      };
    }
    case `${actionTypes.GET_BASIC_PRODUCT}_FULFILLED`: {
      return {
        ...state,
        gettingBasicProduct: false,
        basicProduct: action.payload.data
      };
    }
    case `${actionTypes.GET_PRODUCT_CONFIG}_PENDING`: {
      return {
        ...state,
        gettingBasicConfig: true,
        basicConfig: null
      };
    }
    case `${actionTypes.GET_PRODUCT_CONFIG}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        gettingBasicConfig: false,
        basicConfig: null
      };
    }
    case `${actionTypes.GET_PRODUCT_CONFIG}_FULFILLED`: {
      return {
        ...state,
        gettingBasicConfig: true,
        basicConfig: action.payload.data
      };
    }
    case `${actionTypes.ADD_PRICES_MAINTENANCE}_PENDING`: {
      infoNotification(SAVE_PRICES_MAINTENANCE_PENDING);
      return {
        ...state,
        pricesToMaintenanceSaved: false,
        savingPricesMaintenance: true,
        savingPricesMaintenanceError: false
      };
    }
    case `${actionTypes.ADD_PRICES_MAINTENANCE}_REJECTED`: {
      errorNotification(readMessageBack(action, SAVE_PRICES_MAINTENANCE_ERROR));
      return {
        ...state,
        pricesToMaintenanceSaved: false,
        savingPricesMaintenance: false,
        savingPricesMaintenanceError: true
      };
    }
    case `${actionTypes.ADD_PRICES_MAINTENANCE}_FULFILLED`: {
      successNotification(SAVE_PRICES_MAINTENANCE);
      return {
        ...state,
        pricesToMaintenanceSaved: true,
        savingPricesMaintenance: false,
        savingPricesMaintenanceError: false
      };
    }
    case `${actionTypes.GET_PRICES_MAINTENANCE}_PENDING`: {
      return {
        ...state,
        pricesToMaintenance: null,
        loadingPricesToMaintenance: true
      };
    }
    case `${actionTypes.GET_PRICES_MAINTENANCE}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        pricesToMaintenance: null,
        loadingPricesToMaintenance: false
      };
    }
    case `${actionTypes.GET_PRICES_MAINTENANCE}_FULFILLED`: {
      return {
        ...state,
        pricesToMaintenance: action.payload.data,
        loadingPricesToMaintenance: false
      };
    }
    case `${actionTypes.GET_MAINTENANCES_MEMBERSHIP}_PENDING`: {
      return {
        ...state,
        maintenancesMembership: null
      };
    }
    case `${actionTypes.GET_MAINTENANCES_MEMBERSHIP}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        maintenancesMembership: null
      };
    }
    case `${actionTypes.GET_MAINTENANCES_MEMBERSHIP}_FULFILLED`: {
      return {
        ...state,
        maintenancesMembership: action.payload.data
      };
    }

    case `${actionTypes.GET_HISTORY_AUT_CHARGES}_PENDING`: {
      return {
        ...state,
        listHistoryAutCharges: null
      };
    }
    case `${actionTypes.GET_HISTORY_AUT_CHARGES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        listHistoryAutCharges: null
      };
    }
    case `${actionTypes.GET_HISTORY_AUT_CHARGES}_FULFILLED`: {
      return {
        ...state,
        listHistoryAutCharges: action.payload.data
      };
    }

    case `${actionTypes.GET_MEMBERSHIPS}_PENDING`: {
      return {
        ...state,
        gettingMemberships: true,
        memberships: null
      };
    }
    case `${actionTypes.GET_MEMBERSHIPS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        gettingMemberships: false,
        memberships: null
      };
    }
    case `${actionTypes.GET_MEMBERSHIPS}_FULFILLED`: {
      return {
        ...state,
        gettingMemberships: true,
        memberships: action.payload.data
      };
    }
    case `${actionTypes.GET_MEMBERSHIP_MEMBERS_TYPES}_PENDING`: {
      return {
        ...state,
        membershipTypesMembers: null
      };
    }
    case `${actionTypes.GET_MEMBERSHIP_MEMBERS_TYPES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        membershipTypesMembers: null
      };
    }
    case `${actionTypes.GET_MEMBERSHIP_MEMBERS_TYPES}_FULFILLED`: {
      return {
        ...state,
        membershipTypesMembers: action.payload.data
      };
    }
    case `${actionTypes.SEARCH_MAINTENANCES_BY_ATTRIBUTES}_PENDING`: {
      return {
        ...state,
        maintenancesByAttributes: null
      };
    }
    case `${actionTypes.SEARCH_MAINTENANCES_BY_ATTRIBUTES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        maintenancesByAttributes: null
      };
    }
    case `${actionTypes.SEARCH_MAINTENANCES_BY_ATTRIBUTES}_FULFILLED`: {
      return {
        ...state,
        maintenancesByAttributes: action.payload.data
      };
    }
    case `${actionTypes.GET_PRODUCT_ACCOUNTING_ACCOUNTS}_PENDING`: {
      return {
        ...state,
        gettingAccountingAccounts: true,
        accountingAccounts: null
      };
    }
    case `${actionTypes.GET_PRODUCT_ACCOUNTING_ACCOUNTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        gettingAccountingAccounts: false,
        accountingAccounts: null
      };
    }
    case `${actionTypes.GET_PRODUCT_ACCOUNTING_ACCOUNTS}_FULFILLED`: {
      return {
        ...state,
        gettingAccountingAccounts: true,
        accountingAccounts: action.payload.data
      };
    }
    case `${actionTypes.SAVE_PRODUCT_CONFIG}_PENDING`: {
      infoNotification(GENERAL_CONFIG_MAINTENANCE_WAITING);
      return {
        ...state,
        savedBasicConfig: false
      };
    }
    case `${actionTypes.SAVE_PRODUCT_CONFIG}_REJECTED`: {
      errorNotification(
        readMessageBack(action, GENERAL_CONFIG_MAINTENANCE_ERROR)
      );
      return {
        ...state,
        savedBasicConfig: false
      };
    }
    case `${actionTypes.SAVE_PRODUCT_CONFIG}_FULFILLED`: {
      successNotification(GENERAL_CONFIG_MAINTENANCE);
      return {
        ...state,
        savedBasicConfig: true
      };
    }
    case `${actionTypes.UPDATE_PRODUCT_CONFIG}_PENDING`: {
      infoNotification(GENERAL_CONFIG_MAINTENANCE_WAITING);
      return {
        ...state,
        updatedBasicConfig: false
      };
    }
    case `${actionTypes.UPDATE_PRODUCT_CONFIG}_REJECTED`: {
      errorNotification(
        readMessageBack(action, GENERAL_CONFIG_MAINTENANCE_ERROR)
      );
      return {
        ...state,
        updatedBasicConfig: false
      };
    }
    case `${actionTypes.UPDATE_PRODUCT_CONFIG}_FULFILLED`: {
      successNotification(GENERAL_CONFIG_MAINTENANCE);
      return {
        ...state,
        updatedBasicConfig: true
      };
    }
    case `${actionTypes.MEMBERS_TYPE_MEMBERSHIP}_PENDING`: {
      infoNotification(MEMBERS_TYPE_MEMBERSHIP_PENDING);
      return {
        ...state,
        membersTypeUpdated: false
      };
    }
    case `${actionTypes.MEMBERS_TYPE_MEMBERSHIP}_REJECTED`: {
      errorNotification(readMessageBack(action, MEMBERS_TYPE_MEMBERSHIP_ERROR));
      return {
        ...state,
        membersTypeUpdated: false
      };
    }
    case `${actionTypes.MEMBERS_TYPE_MEMBERSHIP}_FULFILLED`: {
      successNotification(MEMBERS_TYPE_MEMBERSHIP);
      return {
        ...state,
        membersTypeUpdated: true
      };
    }
    case `${actionTypes.GET_PRICES_MAINTENANCES}_PENDING`: {
      return {
        ...state,
        loadingMaintenancesPrices: true
      };
    }
    case `${actionTypes.GET_PRICES_MAINTENANCES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        maintenancesPrices: null,
        loadingMaintenancesPrices: false
      };
    }
    case `${actionTypes.GET_PRICES_MAINTENANCES}_FULFILLED`: {
      return {
        ...state,
        maintenancesPrices: action.payload.data,
        loadingMaintenancesPrices: false
      };
    }
    case `${actionTypes.GET_MAINTENANCES_SCHEDULE}_FULFILLED`: {
      return {
        ...state,
        maintenancesShedule: action.payload.data,
        loadingMaintenancesShedule: false
      };
    }
    case `${actionTypes.RIGHTS_CHANGE}_FULFILLED`: {
      successNotification(RIGHTS_CHANGE_SUCCESS_MESSAGE);
      return {
        ...state
      };
    }
    case `${actionTypes.CREATE_MAINTENANCE_DISCOUNT}_FULFILLED`: {
      successNotification(CREATE_MAINTENANCE_DISCOUNT_SUCCESS_MESSAGE);
      return {
        ...state
      };
    }

    case `${actionTypes.CREATE_MAINTENANCE_DISCOUNT}_REJECTED`: {
      errorNotification(
        readMessageBack(action, CREATE_MAINTENANCE_DISCOUNT_REJECTED_MESSAGE)
      );
      return {
        ...state
      };
    }

    case `${actionTypes.RIGHTS_CHANGE}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state
      };
    }

    case `${actionTypes.POST_QUIZ_ADD}_FULFILLED`: {
      successNotification(FULFILLED_QUIZ_ADD);
      return {
        ...state,
        addQuizMessageError: null,
        messageQuizCreated: true
      };
    }

    case `${actionTypes.POST_QUIZ_ADD}_PENDING`: {
      infoNotification(PENDING_QUIZ_ADD);
      return {
        ...state,
        addQuizMessageError: null,
        messageQuizCreated: false
      };
    }

    case `${actionTypes.POST_QUIZ_ADD}_REJECTED`: {
      errorNotification(readMessageBack(action, REJECTED_ADD_MESSAGE));
      return {
        ...state,
        messageQuizCreated: false
      };
    }

    case `${actionTypes.CREATE_MAINTENANCE_CHARGES}_FULFILLED`: {
      successNotification(CREATE_MAINTENANCE_CHARGES);
      return {
        ...state,
        createMaintenanceCharges: true
      };
    }

    case `${actionTypes.CREATE_MAINTENANCE_CHARGES}_PENDING`: {
      infoNotification(CREATE_MAINTENANCE_CHARGES_PENDING);
      return {
        ...state,
        createMaintenanceCharges: false
      };
    }

    case `${actionTypes.CREATE_MAINTENANCE_CHARGES}_REJECTED`: {
      errorNotification(
        readMessageBack(action, CREATE_MAINTENANCE_CHARGES_ERROR)
      );
      return {
        ...state,
        createMaintenanceCharges: false
      };
    }

    case `${actionTypes.GET_QUIZZES}_PENDING`: {
      infoNotification(PENDING_QUIZ_SEARCH);
      return {
        ...state,
        loadingGetQuizzes: true,
        listQuizzes: null
      };
    }
    case `${actionTypes.GET_QUIZZES}_REJECTED`: {
      errorNotification(readMessageBack(action, REJECTED_QUIZ_SEARCH));
      return {
        ...state,
        loadingGetQuizzes: false,
        listQuizzes: null
      };
    }
    case `${actionTypes.GET_QUIZZES}_FULFILLED`: {
      successNotification(FULFILLED_QUIZ_SEARCH);
      return {
        ...state,
        loadingGetQuizzes: false,
        listQuizzes: action.payload.data
      };
    }

    case `${actionTypes.GET_QUIZZES_QUESTIONS}_PENDING`: {
      infoNotification(PENDING_QUIZ_SEARCH);
      return {
        ...state,
        loadingGetQuestionsQuizzes: true,
        listQuestionsQuizzes: null
      };
    }
    case `${actionTypes.GET_QUIZZES_QUESTIONS}_REJECTED`: {
      errorNotification(readMessageBack(action, REJECTED_QUIZ_SEARCH));
      return {
        ...state,
        loadingGetQuestionsQuizzes: false,
        listQuestionsQuizzes: null
      };
    }
    case `${actionTypes.GET_QUIZZES_QUESTIONS}_FULFILLED`: {
      successNotification(FULFILLED_QUIZ_SEARCH);
      return {
        ...state,
        loadingGetQuestionsQuizzes: false,
        listQuestionsQuizzes: action.payload.data
      };
    }

    case `${actionTypes.GET_SUBCATEGORIES}_PENDING`: {
      return {
        ...state,
        listSubcategories: null
      };
    }
    case `${actionTypes.GET_SUBCATEGORIES}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        listSubcategories: null
      };
    }
    case `${actionTypes.GET_SUBCATEGORIES}_FULFILLED`: {
      return {
        ...state,
        listSubcategories: action.payload.data
      };
    }
    case `${actionTypes.GET_MEMBER_BY_CONFIGURATION}_PENDING`: {
      infoNotification(GET_MEMBER_BY_CONFIGURATION_PENDING);
      return {
        ...state,
        memberConfiguration: []
      };
    }
    case `${actionTypes.GET_MEMBER_BY_CONFIGURATION}_REJECTED`: {
      errorNotification(
        readMessageBack(action, GET_MEMBER_BY_CONFIGURATION_ERROR)
      );
      return {
        ...state,
        memberConfiguration: []
      };
    }
    case `${actionTypes.GET_MEMBER_BY_CONFIGURATION}_FULFILLED`: {
      successNotification(GET_MEMBER_BY_CONFIGURATION_FULFILLED);
      return {
        ...state,
        memberConfiguration: action.payload.data
      };
    }
    case `${actionTypes.GET_PRODUCTS_WITH_COMISSION_CONFIG_TO_AUTH}_PENDING`: {
      infoNotification("Consultando información...");
      return {
        ...state,
        listProductsWithComissionConfigToAuth: null,
        searchingProductsWithComissionConfigToAuth: true
      };
    }
    case `${actionTypes.GET_PRODUCTS_WITH_COMISSION_CONFIG_TO_AUTH}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        listProductsWithComissionConfigToAuth: null,
        searchingProductsWithComissionConfigToAuth: false
      };
    }
    case `${actionTypes.GET_PRODUCTS_WITH_COMISSION_CONFIG_TO_AUTH}_FULFILLED`: {
      successNotification("Datos consultados correctamente");
      return {
        ...state,
        listProductsWithComissionConfigToAuth: action.payload.data,
        searchingProductsWithComissionConfigToAuth: false
      };
    }
    case `${actionTypes.CLEAR_PRODUCTS_WITH_COMISSION_CONFIG_TO_AUTH}`: {
      return {
        ...state,
        listProductsWithComissionConfigToAuth: null,
        searchingProductsWithComissionConfigToAuth: false
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
