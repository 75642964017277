import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import constants from "../../utils/constants";
import { restActionFactory } from "../../toolkit/axiosActions/axiosActionsFactory";
import axios from "axios";
import FileDownload from "js-file-download";
import { errorNotification } from "../../utils/notifications";

const INVITADOS_BASE_URL = "/api/invitados";

// Limpieza de invitados
export const clearInvitados = (): IAction => {
  return {
    type: actionTypes.CLEAR_INVITADOS,
    payload: true
  };
};

// Limpieza de invitado
export const clearInvitado = (): IAction => {
  return {
    type: actionTypes.CLEAR_INVITADO,
    payload: true
  };
};

export const getInvitados = (filters: any): IAction => {
  return invitadosRestAction({
    actionType: actionTypes.GET_INVITADOS,
    method: "get",
    params: filters
  });
};

export const getInvitado = (idInvitado): IAction => {
  return invitadosRestAction({
    actionType: actionTypes.GET_INVITADO,
    urlComplement: `/${idInvitado}`,
    method: "get"
  });
};

export const postInvitado = (invitado): IAction => {
  return invitadosRestAction({
    actionType: actionTypes.POST_INVITADO,
    body: invitado,
    method: "post"
  });
};

export const putInvitado = (idInvitado, invitado): IAction => {
  return invitadosRestAction({
    actionType: actionTypes.PUT_INVITADO,
    urlComplement: `/${idInvitado}`,
    body: invitado,
    method: "put"
  });
};

export const deleteInvitado = (idInvitado): IAction => {
  return invitadosRestAction({
    actionType: actionTypes.DELETE_INVITADO,
    urlComplement: `/${idInvitado}`,
    method: "delete"
  });
};

export const putInvitadoActivar = (idInvitado): IAction => {
  return invitadosRestAction({
    actionType: actionTypes.PUT_INVITADO_ACTIVAR,
    urlComplement: `/${idInvitado}/activar`,
    method: "put"
  });
};

export const putInvitadoDesactivar = (idInvitado): IAction => {
  return invitadosRestAction({
    actionType: actionTypes.PUT_INVITADO_DESACTIVAR,
    urlComplement: `/${idInvitado}/desactivar`,
    method: "put"
  });
};

// Limpieza de movimientos de invitado
export const clearMovimientosInvitado = (): IAction => {
  return {
    type: actionTypes.CLEAR_MOVIMIENTOS_INVITADO,
    payload: true
  };
};

export const getMovimientosInvitado = (idPersona): IAction => {
  return invitadosRestAction({
    actionType: actionTypes.GET_MOVIMIENTOS_INVITADO,
    urlComplement: `/${idPersona}/movimientos`,
    method: "get"
  });
};

// Limpieza de bitácora de invitado
export const clearBitacoraInvitado = (): IAction => {
  return {
    type: actionTypes.CLEAR_BITACORA_INVITADO,
    payload: true
  };
};

export const getBitacoraInvitado = (idPersona): IAction => {
  return invitadosRestAction({
    actionType: actionTypes.GET_BITACORA_INVITADO,
    urlComplement: `/${idPersona}/bitacora`,
    method: "get"
  });
};

export const postHuellaInvitado = (idPersona, huella): IAction => {
  return invitadosRestAction({
    actionType: actionTypes.POST_HUELLA_INVITADO,
    urlComplement: `/${idPersona}/huella/${huella}`,
    method: "post"
  });
};

export const getReporteInvitados = (callback: any) => {
  return axios
    .get(`${constants.BASE_URL}${INVITADOS_BASE_URL}/reporte/descargar`, {
      withCredentials: true,
      responseType: "blob"
    })
    .then(({ data }) => {
      FileDownload(data, `ReporteInvitados.csv`);
      callback && callback();
    })
    .catch(error => {
      errorNotification("No se pudo descargar el reporte");
      callback && callback(error);
    });
};

// Construye una instancia de RestAction para Redux, para manipular invitados
const invitadosRestAction = restActionFactory(
  `${constants.BASE_URL}${INVITADOS_BASE_URL}`
);
