import React from "react";
import BaseInput from "../../../toolkit/baseInput";

export const ClubSelector = ({
  value,
  disabled,
  onChange,
  clubs,
  size = "sm",
  label = "Club"
}) => (
  <BaseInput
    label={label}
    name="clubSelector"
    type="select"
    id="clubSelector"
    placeholder="Club"
    value={value}
    size={size}
    disabled={disabled}
    onChange={e => {
      const value = e.target.value;
      return onChange(value);
    }}
    options={[{ value: "", label: "Sin Club" }].concat(
      clubs
        ? clubs.map(option => ({
            value: option.clubId,
            label: option.nombre
          }))
        : []
    )}
  />
);
