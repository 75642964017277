import React from "react";
import { connect } from "react-redux";
import * as promotionActions from "../../actions/promotions";
import * as modalActions from "../../actions/modals";
import { withRouter } from "react-router-dom";
import { PRICE_TYPES } from "../../../types-business/Promotions";
import BenefitsConfiguration from "../../components/benefitsConfiguration";

interface Props {
  successfulSave: boolean;
  onClickSave: (e: object) => any;
  match: any;
  history: any;
  getPromotionMemberships: (id: string) => any;
  getClubsPromotion: (id: string) => any;
  getPricesPromotion: (
    promotionId: string,
    membershipId: string,
    clubId: string
  ) => any;
  promotionMemberships: object[];
  promotionClubs: object[];
  promotionPrices: object;
  checkRelative: (ev) => any;
  checkProportional: (ev) => any;
  onChangeMembershipValue: (ev) => any;
  onChangePriceType: (membershipPriceType: string) => any;
  checkLastMonth: (monthIndex, ev) => any;
  updateCellPrice: (monthIndex, memberIndex, ev) => any;
  onChangePriceTypeMonth: (monthIndex: number, type: PRICE_TYPES) => any;
  deleteMonthPrices: () => any;
  addMonthPrices: () => any;
  promotionModified: boolean;
  showConfirmBenefitsChangeModal: () => any;
  changesConfirmed: boolean;
  membershipChanged: () => any;
  clubChanged: () => any;
  saveChangesBenefits: (promotionPrices: object) => any;

  //INVITADOS GRATIS
  updateMirrorFreePartners: (_checked: boolean) => any;
  updateFreePartnersByBirthDay: (_checked: boolean) => any;
  updateFreePartnersNumber: (_number: number) => any;
  updateFreePartnersInitialDate: (initialDate: any) => any;
  updateFreePartnersFinalDate: (finalDate: any) => any;
  updateFreePartnersMonths: (_number: number) => any;

  //NIÑOS GRATIS
  updateFreeChildrenNumber: (_number: number) => any;

  //PASES DE INVITADO GRATIS
  updateFreeGuestsPassesNumber: (_number: number) => any;
}

class AddPricesPromotionPage extends React.Component<Props> {
  render() {
    return (
      <div className="container p-0 m-0">
        <BenefitsConfiguration
          onClickSave={this.props.onClickSave}
          getMemberships={this.props.getPromotionMemberships}
          getClubs={this.props.getClubsPromotion}
          getPrices={this.props.getPricesPromotion}
          memberships={this.props.promotionMemberships}
          clubs={this.props.promotionClubs}
          prices={this.props.promotionPrices}
          onChangePriceType={this.props.onChangePriceType}
          onChangeMembershipValue={this.props.onChangeMembershipValue}
          checkRelative={this.props.checkRelative}
          checkProportional={this.props.checkProportional}
          updateCellPrice={this.props.updateCellPrice}
          onChangePriceTypeMonth={this.props.onChangePriceTypeMonth}
          addMonthPrices={this.props.addMonthPrices}
          deleteMonthPrices={this.props.deleteMonthPrices}
          checkLastMonth={this.props.checkLastMonth}
          membershipChanged={this.props.membershipChanged}
          clubChanged={this.props.clubChanged}
          changesConfirmed={this.props.changesConfirmed}
          entityModified={this.props.promotionModified}
          showConfirmBenefitsChangeModal={
            this.props.showConfirmBenefitsChangeModal
          }
          saveChangesBenefits={this.props.saveChangesBenefits}
          updateMirrorFreePartners={this.props.updateMirrorFreePartners}
          updateFreePartnersByBirthDay={this.props.updateFreePartnersByBirthDay}
          updateFreePartnersNumber={this.props.updateFreePartnersNumber}
          updateFreePartnersInitialDate={
            this.props.updateFreePartnersInitialDate
          }
          updateFreePartnersFinalDate={this.props.updateFreePartnersFinalDate}
          updateFreePartnersMonths={this.props.updateFreePartnersMonths}
          updateFreeGuestsPassesNumber={this.props.updateFreeGuestsPassesNumber}
          updateFreeChildrenNumber={this.props.updateFreeChildrenNumber}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  showConfirmBenefitsChangeModal: () => {
    dispatch(modalActions.toggleConfirmBenefitsModal());
  },
  getPromotionMemberships: (id: string) => {
    dispatch(promotionActions.getPromotionMemberships(id));
  },
  getClubsPromotion: (id: string) => {
    dispatch(promotionActions.getClubsPromotion(id));
  },
  getPricesPromotion: (
    promotionId: string,
    membershipId: string,
    clubId: string
  ) => {
    dispatch(
      promotionActions.getPricesPromotion(promotionId, membershipId, clubId)
    );
  },
  checkProportional: ev => {
    dispatch(promotionActions.checkProportional(ev));
  },
  checkRelative: ev => {
    dispatch(promotionActions.checkRelative(ev));
  },
  onChangeMembershipValue: ev => {
    dispatch(promotionActions.onChangeMembershipValue(ev));
  },
  onChangePriceType: (membershipPriceType: string) => {
    dispatch(promotionActions.onChangePriceType(membershipPriceType));
  },
  updateCellPrice: (monthIndex, memberIndex, ev) => {
    dispatch(promotionActions.updateCellPrice(monthIndex, memberIndex, ev));
  },
  checkLastMonth: (monthIndex, ev) => {
    dispatch(promotionActions.checkLastMonth(monthIndex, ev));
  },
  onChangePriceTypeMonth: (monthIndex, type: PRICE_TYPES) => {
    dispatch(promotionActions.onChangePriceTypeMonth(monthIndex, type));
  },
  deleteMonthPrices: () => {
    dispatch(promotionActions.deleteMonthPrices());
  },
  addMonthPrices: () => {
    dispatch(promotionActions.addMonthPrices());
  },
  membershipChanged: () => {
    dispatch(promotionActions.membershipChanged());
  },
  clubChanged: () => {
    dispatch(promotionActions.clubChanged());
  },
  saveChangesBenefits: promotionPrices => {
    dispatch(promotionActions.saveChangesBenefits(promotionPrices));
  },
  updateMirrorFreePartners: _checked => {
    dispatch(promotionActions.updateMirrorFreePartners(_checked));
  },
  updateFreePartnersByBirthDay: _checked => {
    dispatch(promotionActions.updateFreePartnersByBirthDay(_checked));
  },
  updateFreePartnersNumber: _number => {
    dispatch(promotionActions.updateFreePartnersNumber(_number));
  },
  updateFreePartnersInitialDate: initialDate => {
    dispatch(promotionActions.updateFreePartnersInitialDate(initialDate));
  },
  updateFreePartnersFinalDate: finalDate => {
    dispatch(promotionActions.updateFreePartnersFinalDate(finalDate));
  },
  updateFreePartnersMonths: _number => {
    dispatch(promotionActions.updateFreePartnersMonths(_number));
  },
  updateFreeGuestsPassesNumber: _number => {
    dispatch(promotionActions.updateFreeGuestsPassesNumber(_number));
  },
  updateFreeChildrenNumber: _number => {
    dispatch(promotionActions.updateFreeChildrenNumber(_number));
  }
});

const mapStateToProps = state => {
  const { promotions } = state;

  return {
    promotionMemberships: promotions.promotionMemberships,
    promotionClubs: promotions.promotionClubs,
    promotionPrices: promotions.promotionPrices,
    promotionModified: promotions.promotionModified,
    changesConfirmed: promotions.changesConfirmed
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddPricesPromotionPage)
);
