import withStyles from "isomorphic-style-loader/lib/withStyles";
import moment from "moment";
import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import BaseNumericInput from "../../../../toolkit/baseNumericInput";
import ModalFrame from "../../../../toolkit/modalFrame";
import { handleRequestError } from "../../../../utils/helpers";
import { createAnnuityZumaTiempoAccessExtension } from "../../../actions/products";
import s from "./styles.scss";

interface Props {
  isOpen: boolean;
  partnerMembershipId: number;
  movementId: number;
  onOk: () => void;
  onCancel: () => void;
}

interface State {
  fields: {
    months: number;
  };
}

class ModalAddAnnuityZumaTiempoBenefit extends React.Component<Props, State> {
  state = {
    fields: {
      months: 1
    }
  };

  clearState = () => {
    const { fields } = this.state;
    fields.months = 1;
    this.setState({ fields: { ...fields } });
  };

  onOkButton = () => {
    //CALLS endpoint
    const {
      partnerMembershipId: membresiaSocioId,
      movementId: cpiId
    } = this.props;

    const { fields } = this.state;
    const { months: meses } = fields;

    const data = { membresiaSocioId, cpiId, meses };

    createAnnuityZumaTiempoAccessExtension(data)
      .then(() => {
        this.clearState();
        if (!!this.props.onOk) {
          this.props.onOk();
        }
      })
      .catch(handleRequestError);
  };

  onCancelButton = () => {
    this.clearState();

    if (!!this.props.onCancel) {
      this.props.onCancel();
    }
  };

  onChangeNumberFactory = fieldName => {
    return event => {
      const value = event.target.value.replace(/[^0-9]/gi, "");
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };
        return { ...state, error: {}, fields };
      });
    };
  };

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: date
        };
        return { ...state, error: {}, fields };
      });
    };
  };

  render() {
    return (
      <ModalFrame
        title={`Anualidad - Extensión de acceso por beneficio ZUMA TIEMPO`}
        isOpen={this.props.isOpen}
        toggle={() => {
          this.onCancelButton();
        }}
        size="sm"
      >
        <p>
          Se extenderá la anualidad de acuerdo al numero de meses ingresado (1 a
          3 meses)
        </p>
        <Row>
          <Col>
            <BaseNumericInput
              label={"# meses"}
              name="noMeses"
              id="noMeses"
              value={this.state.fields.months}
              placeholder={"# meses"}
              onChange={this.onChangeNumberFactory("months")}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xs={6} md={6} lg={6}>
            <Button
              className={`${s.primaryButton}`}
              block
              color="secondary"
              onClick={() => {
                this.onOkButton();
              }}
              disabled={
                !this.state.fields.months ||
                this.state.fields.months < 1 ||
                this.state.fields.months > 3
              }
            >
              ACEPTAR
            </Button>
          </Col>
          <Col xs={6} md={6} lg={6}>
            <Button
              block
              color="secondary"
              onClick={() => {
                this.onCancelButton();
              }}
              disabled={false}
            >
              CANCELAR
            </Button>
          </Col>
        </Row>
      </ModalFrame>
    );
  }
}

export default withRouter(withStyles(s)(ModalAddAnnuityZumaTiempoBenefit));
