import React from "react";
import * as documentsActions from "../../actions/documents";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Documents from "../../components/configDocuments";
import { QueryArguments } from "../../../types-business/QueryArguments";

interface Props {
  getDocuments: (args?: QueryArguments) => any;
  documents: object[];
  gettingDocuments: boolean;
  documentCreated: boolean;
  history: any;
}

class ConfigDocumentsPage extends React.Component<Props, void> {
  goTo = destiny => {
    this.props.history.push(destiny);
  };

  render() {
    return (
      <main>
        <Helmet title="Documents" />
        <Layout>
          <Documents
            documents={this.props.documents}
            searching={this.props.gettingDocuments}
            reloadDocuments={this.props.documentCreated}
            getDocuments={this.props.getDocuments}
            onClickNewDocument={(document?) =>
              this.goTo(
                `/documentos/datos-basicos/${document ? document.id : ""}`
              )
            }
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getDocuments: (args?: QueryArguments) => {
    dispatch(documentsActions.getDocuments(args));
  }
});

const mapStateToProps = state => {
  const { documents } = state;

  return {
    documents: documents.documents || { data: [], totalPages: 0 },
    documentCreated: documents.documentCreated,
    gettingDocuments: documents.searchingDocuments
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfigDocumentsPage);
