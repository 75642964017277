import { faBomb } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import { Button, Col, Container, Row } from "reactstrap";
import BaseCsvIconDownloader from "../../../toolkit/baseCsvIconDownloader";
import BaseInput from "../../../toolkit/baseInput";
import { handleRequestError } from "../../../utils/helpers";
import {
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import { getClubsRequest } from "../../actions/catalogs";
import {
  getQuizzDevicesResumeRequest,
  getQuizzDevicesResumeRequestDownload
} from "../../actions/products";
import s from "./styles.scss";

interface Props {}

interface State {
  filters: Object;
  quizzDeviceResume: {
    clubId: string;
    clubNombre: string;
    dispositivoCodigo: string;
    dispositivoNombre: string;
    dispositivoTipo: string;
    dispositivoVersion: string;
    dispositivoUbicacion: string;
    dispositivoActivo: boolean;
    dispositivoFechaUltimaRespuesta: string;
  }[];
  clubsCatalog: { cubId: number; nombre: string }[];
}

class QuizzDevicesResume extends React.Component<Props, State> {
  state = {
    filters: { clubId: "", estatus: "FALSE" },
    quizzDeviceResume: [],
    clubsCatalog: []
  };

  getDeviceResume = () => {
    infoNotification("Consultando datos de dispositivos...");
    getQuizzDevicesResumeRequest()
      .then(({ data }) => {
        const { clubId, estatus } = this.state.filters;
        if (!!clubId) {
          data = data.filter(x => x.clubId == clubId);
        }
        if (estatus != "TODOS") {
          if (estatus == "TRUE") {
            data = data.filter(x => !!x.dispositivoActivo);
          } else {
            data = data.filter(x => !x.dispositivoActivo);
          }
        }
        this.setState({ quizzDeviceResume: data });
        successNotification("Datos consultados correctamente...");
      })
      .catch(handleRequestError);
  };

  getClubsCatalog = () => {
    getClubsRequest()
      .then(({ data }) => {
        this.setState({ clubsCatalog: data });
      })
      .catch(handleRequestError);
  };
  componentDidMount() {
    this.getClubsCatalog();
    this.getDeviceResume();
  }

  search = () => {
    this.getDeviceResume();
  };

  render() {
    let columns = [
      {
        Header: () => <p className="font-weight-bold">Club</p>,
        id: 0,
        accessor: "clubNombre",
        Cell: row => {
          return <div className="pl-2">{row.original.clubNombre}</div>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Código</p>,
        id: 0,
        accessor: "dispositivoCodigo",
        Cell: row => {
          return <div className="pl-2">{row.original.dispositivoCodigo}</div>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Nombre dispositivo</p>,
        id: 0,
        accessor: "dispositivoNombre",
        Cell: row => {
          return <div className="pl-2">{row.original.dispositivoNombre}</div>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Dispositivo</p>,
        id: 0,
        accessor: "dispositivoTipo",
        Cell: row => {
          return <div className="pl-2">{row.original.dispositivoTipo}</div>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Salón</p>,
        id: 0,
        accessor: "dispositivoUbicacion",
        Cell: row => {
          return (
            <div className="pl-2">{row.original.dispositivoUbicacion}</div>
          );
        }
      },
      {
        Header: () => <p className="font-weight-bold">Estatus</p>,
        id: 1,
        accessor: "dispositivoActivo",
        Cell: row => {
          return (
            <div className="text-center">
              {!!row.original.dispositivoActivo ? "SI" : "NO"}
            </div>
          );
        }
      },
      {
        Header: () => <p className="font-weight-bold">Versión</p>,
        id: 0,
        accessor: "dispositivoVersion",
        Cell: row => {
          return <div className="pl-2">{row.original.dispositivoVersion}</div>;
        }
      },
      {
        Header: () => (
          <p className="font-weight-bold">Fecha Última Respuesta</p>
        ),
        id: 0,
        accessor: "dispositivoFechaUltimaRespuesta",
        Cell: row => {
          return (
            <div className="pl-2">
              {row.original.dispositivoFechaUltimaRespuesta}
            </div>
          );
        }
      },
      {
        Header: () => {
          return <div className="text-center">Acciones</div>;
        },
        id: -300,
        width: 80,
        accessor: "actions",
        Cell: row => {
          return (
            <div className="text-center">
              <Button
                title="Eliminar"
                className={`${s.actionHeaderIcon} mr-2`}
                onClick={() => {}}
              >
                <FontAwesomeIcon icon={faBomb} />
              </Button>
            </div>
          );
        }
      }
    ];

    return (
      <Container className="p-0 mt-3">
        <Row>
          <Col>
            <BaseInput
              type="select"
              label="Club"
              name={"clubFilter"}
              id={"clubFilter"}
              value={this.state.filters.clubId}
              options={[{ value: "", label: "TODOS" }].concat(
                (this.state.clubsCatalog || []).map(({ clubId, nombre }) => {
                  return {
                    value: clubId,
                    label: nombre
                  };
                })
              )}
              onChange={e => {
                const value = e.target.value;
                const { filters } = this.state;
                filters.clubId = value;
                this.setState({ filters });
              }}
            />
          </Col>
          <Col>
            <BaseInput
              type="select"
              label="Aplicación"
              name={"applicationFilter"}
              id={"applicationFilter"}
              value={""}
              options={[{ value: "", label: "TODOS" }]}
              onChange={e => {
                /* const value = e.target.value;
                const { filters } = this.state;
                filters.clubId = value;
                this.setState({ filters: { ...filters } }); */
              }}
            />
          </Col>
          <Col>
            <BaseInput
              type="select"
              label="Estatus"
              name={"statusFilter"}
              id={"statusFilter"}
              value={this.state.filters.estatus}
              options={[
                { value: "", label: "TODOS" },
                { value: "TRUE", label: "ACTIVOS" },
                { value: "FALSE", label: "INACTIVOS" }
              ]}
              onChange={e => {
                const value = e.target.value;
                const { filters } = this.state;
                filters.estatus = value;
                this.setState({ filters });
              }}
            />
          </Col>
          <Col xs={2}>
            <Button
              className={`${s.buttonMarginTop} ${s.primaryButton} mt-4`}
              onClick={e => this.search()}
              size="sm"
              block
            >
              Buscar
            </Button>
          </Col>
        </Row>
        <br />
        <BaseCsvIconDownloader
          rowCount={(this.state.quizzDeviceResume || []).length}
          executeReportDownload={getQuizzDevicesResumeRequestDownload}
          getFilters={() => {
            return {};
          }}
        />

        <ReactTable
          className="mt-5 -striped -highlight"
          data={this.state.quizzDeviceResume || []}
          columns={columns}
          sortable={false}
          resizable={false}
          pageSize={(this.state.quizzDeviceResume || []).length + 2}
          showPagination={false}
          loading={false}
        />
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(QuizzDevicesResume));
