import React from "react";
import { ScheduleRow } from "./ScheduleRow";

export enum DayOfWeek {
  Monday = "LUNES",
  Tuesday = "MARTES",
  Wednesday = "MIERCOLES",
  Thursday = "JUEVES",
  Friday = "VIERNES",
  Saturday = "SABADO",
  Sunday = "DOMINGO"
}

export const ListDays = [
  DayOfWeek.Monday,
  DayOfWeek.Tuesday,
  DayOfWeek.Wednesday,
  DayOfWeek.Thursday,
  DayOfWeek.Friday,
  DayOfWeek.Saturday,
  DayOfWeek.Sunday
];

export const ScheduleTable = ({
  fields,
  activeField,
  startField,
  endField,
  onChangeStartTime,
  onChangeChecked,
  onChangeEndTime
}) => (
  <div className="form-group">
    {ListDays.map((day: DayOfWeek) => (
      <ScheduleRow
        day={day}
        checked={fields[day][activeField]}
        onChangeChecked={onChangeChecked(day)}
        startTime={fields[day][startField]}
        setStartTime={onChangeStartTime(day)}
        endTime={fields[day][endField]}
        setEndtime={onChangeEndTime(day)}
      />
    ))}
  </div>
);
