import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Club } from "../../../types-business/Clubs";
import { QueryArguments } from "../../../types-business/QueryArguments";
import { QueryResponse } from "../../../types/QueryResponse";
import * as catalogActions from "../../actions/catalogs";
import * as lockersActions from "../../actions/lockers";
import Layout from "../../components/layout";
import LockersConfiguration from "../../components/lockersConfiguration";
import { LOCKER_CONFIG } from "../../components/lockersConfiguration/interfaces";

interface Locker {
  id: string;
  clubId: string;
  vestidor: string;
  pared: string;
  fechaAdquisicion: string;
  fechaVencimiento: string;
  estatus: string;
  personaId: string;
  personaNombre: string;
  locker: string;
  pdfStorageId: number;
}

interface Props {
  clubs: Club[];
  lockersAssigned: QueryResponse<Locker>;
  getAssignedByClubId: (args?: QueryArguments) => any;
  getClubs: () => any;
  saveLockersConfig: (lockerConfig: LOCKER_CONFIG) => any;
  getListFile: () => any;
  getLockers: (clubId: number) => any;
  userContext: any;
}

class LockerAssignedPage extends React.Component<Props> {
  componentDidMount() {
    this.props.getClubs();
  }

  componentDidUpdate() {}

  render() {
    return (
      <main>
        <Helmet title="Configuracion de Lockers" />
        <Layout>
          <LockersConfiguration
            saveLockersConfig={this.props.saveLockersConfig}
            clubs={this.props.clubs}
            getLockers={this.props.getLockers}
            getListFile={this.props.getListFile}
            userContext={this.props.userContext}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getListFile: () => {
    dispatch(lockersActions.getListFile());
  },
  getAssignedByClubId: (args?: QueryArguments) => {
    dispatch(lockersActions.getAssignedByClubId(args));
  },
  getClubs: () => {
    dispatch(catalogActions.getClubs());
  },
  saveLockersConfig: lockersConfig => {
    return dispatch(lockersActions.saveLockersConfig(lockersConfig));
  },
  getLockers: clubId => {
    return dispatch(lockersActions.getLockers(clubId));
  }
});

const mapStateToProps = state => {
  const {
    lockers: { lockersAssigned, gettingLockersAssigned },
    catalogs: { clubs }
  } = state;
  return {
    lockersAssigned: lockersAssigned,
    searching: gettingLockersAssigned,
    clubs: clubs,
    userContext: state.user.userContext
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LockerAssignedPage);
