import React from "react";
import { Label } from "reactstrap";
import capitalize from "lodash.capitalize";
import BaseInputTimePicker from "../../../../toolkit/baseTimePickerInput";

export const ScheduleRow = ({
  day,
  checked,
  onChangeChecked,
  startTime,
  setStartTime,
  endTime,
  setEndtime
}) => (
  <div className="form-row my-4">
    <div className="form-check form-check-inline col-2">
      <input
        onChange={onChangeChecked}
        checked={checked}
        className="form-check-input"
        type="checkbox"
      />
      <label className="form-check-label">{capitalize(day)}</label>
    </div>
    <div className="col-1">
      <Label className="my-0 align-self-center" for="selectAccessType">
        Entrada:
      </Label>
    </div>
    <div className="col-3">
      <BaseInputTimePicker
        value={startTime}
        setTime={time => setStartTime(time)}
      />
    </div>
    <div className="col-1">
      <Label className="my-0 align-self-center" for="selectScheduleTime">
        Salida:
      </Label>
    </div>
    <div className="col-3">
      <BaseInputTimePicker value={endTime} setTime={time => setEndtime(time)} />
    </div>
  </div>
);
