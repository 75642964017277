import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
// import Reform from "@franleplant/reform";
import { FormGroup, Label, Input, Col } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import s from "./styles.scss";
import { FormErrors } from "@franleplant/reform/types";
import RichEditor from "../richEditor";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { setChildStep } from "../../helpers/stepper-state-comparator";
import { TIPO_PRODUCTO } from "../../../types-business/Products";
import moment from "moment";
import DatePicker from "../common/DatePicker";
import ProductClassification from "../../containers/productClassification";
import isNil from "lodash.isnil";
import { BFF_URL, IMAGES_ENDPOINT } from "../../../utils/constants";
import ModalPicture from "../../../toolkit/modalPicture";
import {
  BASIC_DATA_PRODUCT,
  NOT_CATEGORY_SELECTED,
  successNotification,
  errorNotification
} from "../../../utils/notifications";

interface Props {
  onClickSave: (e: object) => any;
  onSuccessfulSave: () => any;
  history: any;
  client: any;
  saveBasicProduct: (product: object) => any;
  updateBasicProduct: (id: string, product: object) => any;
  successfulSave: boolean;
  match: any;
  product: Fields;
  getBasicProduct: (id: string) => any;
  sportsProducts: [{ id: string; nombre: string }];
  satProducts: { id: string; nombre: string }[];
  satUnits: { id: string; nombre: string }[];
  sendPhotoFile: (photo: Blob, productId: number) => any;
  setProductPhoto: (photo: Blob) => any;
  productPhoto: Blob;
  basicDataSaved: boolean;
  clearBasicDataSaved: () => any;
  classificationByLevel: object[];
}

interface Product {
  nombre: string;
  estatus: string;
  publicarPrecio: boolean;
  clasificacionId: string;
  descripcion: string;
  idUnidadSAT: string;
  idProductoSAT: string;
  foto: {
    url: string;
    id: number;
  };
  pases: number;
}

interface ConfigProduct {
  numeroEventos: number;
  fechaInicioVenta: string;
  fechaInicio: string;
  fechaFin: string;
  vigenciaDias: number;
  capacidad: number;
  aceptaInvitados: boolean;
  aceptaEmpleados: boolean;
}

interface Fields {
  producto: Product;
  configuracion: ConfigProduct;
}

interface State {
  errors: FormErrors;
  fields: Fields;
  editorState: EditorState;
  basicDataSaved: boolean;
}

const productToFields = fields => ({ ...fields });

const formatTime = (dateTime?) =>
  moment(dateTime)
    .utc()
    .format("YYYY-MM-DD");

class BasicDataSportsProductsForm extends React.Component<Props, State> {
  // re = Reform.reactMixins.objectMixin(this);

  state = {
    fields: {
      producto: {
        nombre: "",
        estatus: "INACTIVO",
        publicarPrecio: false,
        tipoProducto: null,
        idUnidadSAT: "",
        idProductoSAT: "",
        descripcion: "",
        clasificacionId: null,
        pases: 0
      },
      configuracion: {
        numeroEventos: 0,
        fechaInicioVenta: formatTime(),
        fechaInicio: formatTime(),
        fechaFin: formatTime(),
        vigenciaDias: 0,
        capacidad: 0,
        aceptaInvitados: false,
        aceptaEmpleados: false
      }
    },
    editorState: EditorState.createEmpty(),
    errors: {},
    basicDataSaved: this.props.basicDataSaved
  };

  shouldComponentUpdate(nextProps) {
    if (
      this.props.successfulSave !== nextProps.successfulSave &&
      nextProps.successfulSave
    ) {
      this.props.onSuccessfulSave();
      return false;
    }
    return true;
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    if (id) {
      this.props.getBasicProduct(id);
    }

    this.props.onClickSave(e => {
      e.preventDefault();
      // if (this.re.validateFormFromState()) {
      let valFields = { ...this.state.fields };
      valFields.producto.descripcion = draftToHtml(
        convertToRaw(this.state.editorState.getCurrentContent())
      );
      const product = {
        ...valFields,
        productos: {
          ...this.state.fields.producto,
          descripcion: draftToHtml(
            convertToRaw(this.state.editorState.getCurrentContent())
          )
        }
      };
      const { id } = this.props.match.params;
      let promise;
      let valLevel = this.props.classificationByLevel.find(
        element => element.id == product.productos.clasificacionId
      );
      if (!isNil(valLevel) && !isNil(valLevel.nivel) && valLevel.nivel == 3) {
        if (!id) {
          promise = this.props.saveBasicProduct(product);
        } else {
          promise = this.props.updateBasicProduct(id, product);
        }
        promise.then(({ value: { data } }) => {
          const productoId = data.producto.id;
          if (!isNil(this.props.productPhoto)) {
            this.props
              .sendPhotoFile(this.props.productPhoto, productoId)
              .then(() => {
                this.props.history.push(
                  `/productos/datos-basicos/${productoId}`
                );
              });
          } else {
            this.props.history.push(`/productos/datos-basicos/${productoId}`);
          }
        });
      } else {
        errorNotification(NOT_CATEGORY_SELECTED);
      }
      // }
    });
  }

  loaded = false;

  componentDidUpdate() {
    const id = this.props.match.params.id;
    const letreroVisible = this.state.basicDataSaved;
    if (letreroVisible) {
      successNotification(BASIC_DATA_PRODUCT);
      this.setState({ basicDataSaved: false });
    }
    if (id && (this.props.product && !this.loaded)) {
      this.loaded = true;
      const fields: Fields = productToFields(this.props.product);
      setChildStep(fields);
      this.setState(state => ({
        ...state,
        fields,
        editorState: EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(this.props.product.producto.descripcion).contentBlocks
          )
        )
      }));
    }
  }

  // validationRules = {
  //   producto: { nombre: { required: true }, tipoProducto: { required: true } }
  // };

  validationRules = {};

  validationMessages = {
    required: _ => `Obligatorio`,
    default: _ => `Inválido`
  };

  onChangeFactoryProducto = fieldName => {
    return event => {
      const value = event.target.value;
      this.onChangeProducto(fieldName, value);
      // this.re.validateField(fieldName, value);
    };
  };

  onChangeProducto = (fieldName, value) => {
    this.setState(state => {
      const fields = {
        ...state.fields,
        producto: {
          ...state.fields.producto,
          [fieldName]: value
        }
      };
      setChildStep(fields);
      return { ...state, fields, errors: {} };
    });
  };

  onChangeConfiguracion = (fieldName, value) => {
    this.setState(state => {
      const fields = {
        ...state.fields,
        configuracion: {
          ...state.fields.configuracion,
          [fieldName]: value
        }
      };
      setChildStep(fields);
      return { ...state, fields, errors: {} };
    });
  };

  onChangeFactoryConfiguracion = fieldName => {
    return event => {
      const value = event.target.value;
      this.onChangeConfiguracion(fieldName, value);
      // this.re.validateField(fieldName, value);
    };
  };

  checkPublishPrice = e => {
    const publicarPrecio = e.target.checked;
    this.onChangeProducto("publicarPrecio", publicarPrecio);
  };

  onChangeFecha = fieldName => {
    return dateTime => {
      const date = formatTime(dateTime);
      this.onChangeConfiguracion(fieldName, date);
      // this.re.validateField(fieldName, date);
    };
  };

  onChangeEditor = editorState => this.setState({ editorState });

  checkAceptaEmpleados = e => {
    const aceptaEmpleados = e.target.checked;
    this.onChangeConfiguracion("aceptaEmpleados", aceptaEmpleados);
  };

  checkAceptaInvitados = e => {
    const aceptaInvitados = e.target.checked;
    this.onChangeConfiguracion("aceptaInvitados", aceptaInvitados);
  };

  handleFileChosen = ev => {
    ev.preventDefault();
    this.props.setProductPhoto(ev.target.files[0]);
  };

  render() {
    const tipoProducto: TIPO_PRODUCTO = this.state.fields.producto.tipoProducto;
    return (
      <form className={s.basicDataProduct}>
        <div className="form-row">
          <ProductClassification
            setResult={clasificacionId =>
              this.onChangeProducto("clasificacionId", clasificacionId)
            }
            result={this.state.fields.producto.clasificacionId}
          ></ProductClassification>
        </div>
        <div className="form-row">
          <div className="col-12">
            <BaseInput
              label={"Nombre"}
              name="nombre"
              type="text"
              id="nombre"
              placeholder="Nombre"
              value={this.state.fields.producto.nombre}
              onChange={this.onChangeFactoryProducto("nombre")}
              uppercase={true}
              maxlength={"250"}
            />
          </div>
          <div className="col">
            <BaseInput
              label={"Estatus"}
              name="estatus"
              type="select"
              id="estatus"
              placeholder="Estatus"
              disabled
              value={this.state.fields.producto.estatus}
              options={["ACTIVO", "INACTIVO"].map(option => ({
                value: option,
                label: option
              }))}
              onChange={this.onChangeFactoryProducto("estatus")}
            />
          </div>
          <div className="col">
            {this.props.satUnits && (
              <BaseInput
                label={"Unidades SAT"}
                name="idUnidadSAT"
                type="select"
                id="idUnidadSAT"
                placeholder="Unidades SAT"
                value={this.state.fields.producto.idUnidadSAT}
                options={[
                  { value: "", label: "Seleccionar Unidad SAT" }
                ].concat(
                  this.props.satUnits.map(option => ({
                    value: option.id,
                    label: `${option.claveUnidad} - ${option.nombre}`
                  }))
                )}
                onChange={this.onChangeFactoryProducto("idUnidadSAT")}
              />
            )}
          </div>
          <div className="col">
            {this.props.satProducts && (
              <BaseInput
                label={"Producto/Servicio SAT"}
                name="idProductoSAT"
                type="select"
                id="idProductoSAT"
                placeholder="Producto/Servicio SAT"
                value={this.state.fields.producto.idProductoSAT}
                options={[
                  { value: "", label: "Seleccionar Producto SAT" }
                ].concat(
                  this.props.satProducts.map(option => ({
                    value: option.id,
                    label: `${option.claveProducto} - ${option.nombre}`
                  }))
                )}
                onChange={this.onChangeFactoryProducto("idProductoSAT")}
              />
            )}
          </div>
        </div>
        <div className="form-row">
          {/* Si el producto es un pase de invitado se muestra la cantidad de pases */}
          <div
            hidden={this.state.fields.producto.clasificacionId != 54}
            className="col"
          >
            <BaseInput
              label={"Cantidad de pases"}
              name="pases"
              type="number"
              id="pases"
              placeholder="Pases"
              value={this.state.fields.producto.pases}
              onChange={this.onChangeFactoryProducto("pases")}
            />
          </div>
          <div
            hidden={this.state.fields.producto.clasificacionId == 54}
            className="col"
          >
            <BaseInput
              label={"Numero de eventos"}
              name="numeroEventos"
              type="number"
              id="numeroEventos"
              placeholder="Numero de eventos"
              value={
                this.state.fields.configuracion &&
                this.state.fields.configuracion.numeroEventos
                  ? this.state.fields.configuracion.numeroEventos
                  : null
              }
              onChange={this.onChangeFactoryConfiguracion("numeroEventos")}
            />
          </div>

          <div className="col">
            <DatePicker
              label="Fecha Inicio"
              name="fechaInicio"
              id="fechaInicio"
              selected={
                this.state.fields.configuracion &&
                this.state.fields.configuracion.fechaInicio
                  ? this.state.fields.configuracion.fechaInicio
                  : null
              }
              disabled={
                tipoProducto === TIPO_PRODUCTO.CLASES_PERSONALIZADAS ||
                tipoProducto === TIPO_PRODUCTO.FIT_KIDS
              }
              onChange={this.onChangeFecha("fechaInicio")}
            />
          </div>
          <div className="col">
            <DatePicker
              label="Fecha Fin"
              name="fechaFin"
              id="fechaFin"
              selected={
                this.state.fields.configuracion &&
                this.state.fields.configuracion.fechaFin
                  ? this.state.fields.configuracion.fechaFin
                  : null
              }
              disabled={
                tipoProducto === TIPO_PRODUCTO.CLASES_PERSONALIZADAS ||
                tipoProducto === TIPO_PRODUCTO.FIT_KIDS
              }
              onChange={this.onChangeFecha("fechaFin")}
            />
          </div>
          <div className="col">
            <DatePicker
              label="Fecha Inicio Venta"
              name="fechaInicioVenta"
              id="fechaInicioVenta"
              disabled={tipoProducto === TIPO_PRODUCTO.CLASES_GRUPALES}
              selected={
                this.state.fields.configuracion &&
                this.state.fields.configuracion.fechaInicioVenta
                  ? this.state.fields.configuracion.fechaInicioVenta
                  : undefined
              }
              onChange={this.onChangeFecha("fechaInicioVenta")}
            />
          </div>
          <div className="col">
            <BaseInput
              label={"Vigencia Dias"}
              name="vigenciaDias"
              type="number"
              id="vigenciaDias"
              placeholder="Vigencia Dias"
              value={
                this.state.fields.configuracion &&
                this.state.fields.configuracion.vigenciaDias
                  ? this.state.fields.configuracion.vigenciaDias
                  : undefined
              }
              disabled={
                tipoProducto === TIPO_PRODUCTO.PROGRAMAS_DEPORTIVOS ||
                tipoProducto === TIPO_PRODUCTO.EVENTOS ||
                tipoProducto === TIPO_PRODUCTO.CLASES_GRUPALES
              }
              onChange={this.onChangeFactoryConfiguracion("vigenciaDias")}
            />
          </div>
          <div className="col">
            <BaseInput
              label={"Capacidad"}
              name="capacidad"
              type="number"
              id="capacidad"
              placeholder="Capacidad"
              value={
                this.state.fields.configuracion &&
                this.state.fields.configuracion.capacidad
                  ? this.state.fields.configuracion.capacidad
                  : null
              }
              onChange={this.onChangeFactoryConfiguracion("capacidad")}
            />
          </div>
        </div>
        <div className="form-row">
          <Col className="p-0 m-0">
            <Label>Foto:</Label>
            <Label>
              <Input
                type="file"
                id="pricesFile"
                accept="image/*"
                onChange={e => this.handleFileChosen(e)}
              />
            </Label>
          </Col>
          <Col className="p-0 m-0 mt-4">
            {this.props.product &&
            this.props.product.producto.foto &&
            this.props.product.producto.foto.id ? (
              <ModalPicture
                title="FOTO DEL PRODUCTO"
                url={`${BFF_URL}${IMAGES_ENDPOINT}/${this.props.product.producto.foto.id}`}
              ></ModalPicture>
            ) : null}
          </Col>
          <FormGroup className="col-2" check>
            <Label className="float-right" check>
              <Input
                type="checkbox"
                checked={this.state.fields.producto.publicarPrecio}
                onChange={e => this.checkPublishPrice(e)}
              />
              Publicar Precio
            </Label>
          </FormGroup>
          <FormGroup className="col-2" check>
            <Label className="float-right" check>
              <Input
                type="checkbox"
                checked={
                  this.state.fields.configuracion &&
                  this.state.fields.configuracion.aceptaInvitados
                    ? this.state.fields.configuracion.aceptaInvitados
                    : undefined
                }
                onChange={e => this.checkAceptaInvitados(e)}
              />
              Acepta Invitados
            </Label>
          </FormGroup>
          <FormGroup className="col-2" check>
            <Label className="float-right" check>
              <Input
                type="checkbox"
                checked={
                  this.state.fields.configuracion &&
                  this.state.fields.configuracion.aceptaEmpleados
                    ? this.state.fields.configuracion.aceptaEmpleados
                    : undefined
                }
                onChange={e => this.checkAceptaEmpleados(e)}
              />
              Acepta Empleados
            </Label>
          </FormGroup>
        </div>
        <div className="form-row mt-3">
          <Label className={`${s.label} col`}>Descripción:</Label>
          <div style={{ height: "350px" }}>
            <RichEditor
              onChangeEditor={this.onChangeEditor}
              editorState={this.state.editorState}
            />
          </div>
        </div>
      </form>
    );
  }
}

export default withRouter(withStyles(s)(BasicDataSportsProductsForm));
