import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";

const PERIODS_BASE_URL = `/api/periods`;

export const getPeriods = (): IAction => {
  return {
    type: actionTypes.GET_PERIODS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${PERIODS_BASE_URL}`, {
        withCredentials: true
      })
    }
  };
};

export const getPeriodsRequest = () => {
  return axios.get(`${constants.BASE_URL}${PERIODS_BASE_URL}`, {
    withCredentials: true
  });
};

export const getPeriodsAnualRangeRequest = () => {
  return axios.get(`${constants.BASE_URL}${PERIODS_BASE_URL}/range?meses=12`, {
    withCredentials: true
  });
};

export const getPeriodById = (id: string): IAction => {
  return {
    type: actionTypes.GET_PERIOD_BY_ID,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${PERIODS_BASE_URL}/${id}/schemas`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const saveSchemasByPeriod = (schemas: object): any => {
  return {
    type: actionTypes.SCHEMAS_BY_PERIOD,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${PERIODS_BASE_URL}/schemas`,
        schemas,
        {
          withCredentials: true
        }
      )
    }
  };
};
