import React from "react";
import { Container } from "reactstrap";
import { Router } from "../../../toolkit/decorators/Artifact";
import FormBuilder from "../../../toolkit/baseForms2/formBuilder/FormBuilder";
import { Inputs, Buttons, formConfig } from "./NotaCreditoBloqueo.form";
import { NotaCreditoBloqueoStore } from "../../containers/notaCreditoBloqueo/NotaCreditoBloqueo.store";
import { NotaCreditoBloqueoDispatcher } from "../../containers/notaCreditoBloqueo/NotaCreditoBloqueo.dispatcher";
import {
  errorNotification,
  successNotification
} from "../../../utils/notifications";
import { MemoTable } from "./NotaCreditoBloqueo.table";

interface Props extends NotaCreditoBloqueoStore, NotaCreditoBloqueoDispatcher {}

interface State {}

@Router()
export default class NotaCreditoBloqueoComponent extends React.Component<
  Props,
  State
> {
  componentDidMount(): void {
    this.props.getNotaCreditoBloqueadas();
  }

  submit = async (fields: { [key in Inputs]: string }) => {
    const folio = fields.folioFactura;

    if (!folio) {
      errorNotification("Ingrese el folio de la factura por bloquear");
      return;
    }

    const preview = await this.props.postNotaCreditoBloqueada(folio);
    if (preview.fulfilled) {
      successNotification("La factura se ha bloqueado correctamente");
      this.props.getNotaCreditoBloqueadas();
    }
  };

  delete = async (facturaUUID: string) => {
    const preview = await this.props.deleteNotaCreditoBloqueada(facturaUUID);
    if (preview.fulfilled) {
      successNotification("El bloqueo se descartó correctamente");
      this.props.getNotaCreditoBloqueadas();
    }
  };

  render() {
    return (
      <Container className="mb-5">
        <FormBuilder<Inputs, Buttons>
          config={formConfig}
          submit={this.submit}
          processing={
            this.props.creatingNotaCreditoBloqueo ||
            this.props.deletingNotaCreditoBloqueo
          }
        />

        <hr className="row" />

        <MemoTable
          data={this.props.notaCreditoBloqueos}
          loading={this.props.gettingNotaCreditoBloqueos}
          deleteAction={facturaUUID => this.delete(facturaUUID)}
        />
      </Container>
    );
  }
}
