import FormBuilderConfig from "../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IButtonBuilder from "../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import ITextBuilder from "../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";

export type Inputs = "folioFactura";
export type Buttons = "aceptar";
export const formConfig: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "folioFactura",
      label: "* Folio de factura",
      bts: {
        sCol: 3
      },
      component: ITextBuilder
    }
  ],
  validations: {
    folioFactura: {
      required: true
    }
  },
  buttons: [
    {
      name: "aceptar",
      label: "Bloquear",
      isSubmit: true,
      component: IButtonBuilder,
      bts: {
        sCol: 3
      }
    }
  ],
  rows: [["folioFactura", "aceptar"]]
};
