import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import AdminProductsClassifications from "../../components/adminProductsClassifications";
import { getClassificationByLevel } from "../../actions/products";
import {
  getAccountingAccounts,
  getCatalogAccrual
} from "../../actions/catalogs";

interface Props {
  getClassificationByLevel: (level: number) => any;
  getAccountingAccounts: () => any;
  getCatalogAccrual: () => any;
  catalogAccrual: any;
  accountingAccounts: any;
}

class AdminProductsClassificationsPage extends React.Component<Props> {
  render() {
    return (
      <main>
        <Helmet title="Sports Products" />
        <Layout>
          <AdminProductsClassifications
            getClassificationByLevel={this.props.getClassificationByLevel}
            getAccountingAccounts={this.props.getAccountingAccounts}
            getCatalogAccrual={this.props.getCatalogAccrual}
            catalogAccrual={this.props.catalogAccrual}
            accountingAccounts={this.props.accountingAccounts}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getClassificationByLevel: (level: number) => {
    return dispatch(getClassificationByLevel(level));
  },
  getAccountingAccounts: () => {
    return dispatch(getAccountingAccounts());
  },
  getCatalogAccrual: () => {
    return dispatch(getCatalogAccrual());
  }
});

const mapStateToProps = state => {
  const { catalogs } = state;
  return {
    catalogAccrual: catalogs.catalogAccrual,
    accountingAccounts: catalogs.accountingAccounts
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminProductsClassificationsPage);
