import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import * as catalogActions from "../../actions/catalogs";
import * as dealersActions from "../../actions/persons";
import IAction from "../../../types/IAction";
import ListVariablesChargeDealer from "../../components/listVariablesChargeDealer";

interface Props {
  getClubs: () => void;
  clubs: object[];
  getVariablesChargeDealers: (
    clubId: string,
    year: string,
    month: string
  ) => IAction;
  generateVariableCharge: (
    configuracionCargoId: string,
    monto: string
  ) => IAction;
  variablesCharge: any;
}

interface State {}

class VariablesChargeDealer extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    this.props.getClubs();
  }

  render() {
    return (
      <main>
        <Helmet title="Variables Charge Dealer" />
        <Layout>
          <ListVariablesChargeDealer
            clubs={this.props.clubs}
            getVariablesChargeDealers={this.props.getVariablesChargeDealers}
            variablesCharge={this.props.variablesCharge}
            generateVariableCharge={this.props.generateVariableCharge}
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({ catalogs, persons }) => {
  return {
    clubs: catalogs.clubs,
    variablesCharge: persons.variablesCharge
  };
};

const mapDispatchToProps = dispatch => ({
  getClubs: () => {
    dispatch(catalogActions.getClubs());
  },
  getVariablesChargeDealers: (clubId: string, year: string, month: string) => {
    return dispatch(
      dealersActions.getVariablesChargeDealers(clubId, year, month)
    );
  },
  generateVariableCharge: (configuracionCargoId: string, monto: string) => {
    return dispatch(
      dealersActions.generateVariableCharge(configuracionCargoId, monto)
    );
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VariablesChargeDealer);
