import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import {
  infoNotification,
  errorNotification,
  successNotification,
  PENDING,
  ERROR,
  SAVE_ANNUITY,
  SAVE_ANNUITY_QUOTE,
  PAY_ANNUITY_QUOTE_BY_ID
} from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";
interface State {
  annuity: object;
  gettingAnnuity: boolean;
  reloadAnnuity: boolean;
  showAddPhaseModal: boolean;
  quoteByFilters: object;
  quoteByFiltersLoading: boolean;
  savedQuote: number;
  quoteById: object;
  savingPayQuote: boolean;
}
const initialState: State = {
  annuity: null,
  gettingAnnuity: false,
  reloadAnnuity: false,
  showAddPhaseModal: false,
  quoteByFilters: null,
  quoteByFiltersLoading: false,
  savedQuote: null,
  quoteById: null,
  savingPayQuote: false
};

export default function catalogsReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.GET_ANNUITY}_PENDING`: {
      return {
        ...state,
        annuity: null,
        gettingAnnuity: true,
        reloadAnnuity: false
      };
    }
    case `${actionTypes.GET_ANNUITY}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        annuity: null,
        gettingAnnuity: false
      };
    }
    case `${actionTypes.GET_ANNUITY}_FULFILLED`: {
      return {
        ...state,
        annuity: action.payload.data,
        gettingAnnuity: false
      };
    }
    case `${actionTypes.SAVE_ANNUITY}_PENDING`: {
      infoNotification(PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.SAVE_ANNUITY}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state
      };
    }
    case `${actionTypes.SAVE_ANNUITY}_FULFILLED`: {
      successNotification(SAVE_ANNUITY);
      return {
        ...state,
        annuity: action.payload.data,
        reloadAnnuity: true
      };
    }
    case actionTypes.ADD_PHASE_TOGGLE: {
      return {
        ...state,
        showAddPhaseModal: !state.showAddPhaseModal
      };
    }
    case `${actionTypes.GET_ANNUITY_QUOTE}_PENDING`: {
      return {
        ...state,
        quoteByFilters: null,
        quoteByFiltersLoading: true
      };
    }
    case `${actionTypes.GET_ANNUITY_QUOTE}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        quoteByFilters: null,
        quoteByFiltersLoading: false
      };
    }
    case `${actionTypes.GET_ANNUITY_QUOTE}_FULFILLED`: {
      return {
        ...state,
        quoteByFilters: action.payload.data,
        quoteByFiltersLoading: false
      };
    }
    case `${actionTypes.SAVE_ANNUITY_QUOTE}_PENDING`: {
      infoNotification(PENDING);
      return {
        ...state,
        savedQuote: null
      };
    }
    case `${actionTypes.SAVE_ANNUITY_QUOTE}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        savedQuote: null
      };
    }
    case `${actionTypes.SAVE_ANNUITY_QUOTE}_FULFILLED`: {
      successNotification(SAVE_ANNUITY_QUOTE);
      return {
        ...state,
        savedQuote: action.payload.data
      };
    }
    case `${actionTypes.GET_ANNUITY_QUOTE_BY_ID}_PENDING`: {
      return {
        ...state,
        quoteById: null,
        savedQuote: null
      };
    }
    case `${actionTypes.GET_ANNUITY_QUOTE_BY_ID}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        quoteById: null
      };
    }
    case `${actionTypes.GET_ANNUITY_QUOTE_BY_ID}_FULFILLED`: {
      return {
        ...state,
        quoteById: action.payload.data
      };
    }
    case `${actionTypes.PAY_ANNUITY_QUOTE_BY_ID}_PENDING`: {
      infoNotification(PENDING);
      return {
        ...state,
        savingPayQuote: true
      };
    }
    case `${actionTypes.PAY_ANNUITY_QUOTE_BY_ID}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        savingPayQuote: false
      };
    }
    case `${actionTypes.PAY_ANNUITY_QUOTE_BY_ID}_FULFILLED`: {
      successNotification(PAY_ANNUITY_QUOTE_BY_ID);
      return {
        ...state,
        savingPayQuote: false
      };
    }
    case actionTypes.ADD_PHASE_TOGGLE: {
      return {
        ...state,
        showAddPhaseModal: !state.showAddPhaseModal
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
