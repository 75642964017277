import React from "react";
import { connect } from "react-redux";
import ClubCatalogSelect from "./../../components/sportsProductsNewQuote/ClubCatalogSelect";
import { getClubs } from "../../actions/catalogs";

interface Props {
  clubSelected: { value: any; label: any };
  clubs: { clubId: number; nombre: string }[];
  onSelectClub: (club: object) => any;
  getClubs: () => any;
  label?: string;
  soloMigrados?: boolean;

  // El user
  userClub: number;
}

class ClubSelectList extends React.Component<Props, void> {
  componentDidMount() {
    this.props.getClubs();
  }

  render() {
    return (
      <ClubCatalogSelect
        club={this.props.clubSelected}
        onSelectClub={this.props.onSelectClub}
        clubs={this.props.clubs}
        label={this.props.label}
        userClub={this.props.userClub}
        soloMigrados={!!this.props.soloMigrados}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getClubs: () => {
    return dispatch(getClubs());
  }
});

const mapStateToProps = state => {
  const {
    catalogs: { clubs }
  } = state;

  return {
    clubs
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClubSelectList);
