import React from "react";
import { connect } from "react-redux";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import SaveNextCarousel from "../../components/saveNextCarousel";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import { MEMBERSHIPS_STEPS } from "../../../steps";
import * as productActions from "../../actions/products";
import s from "./styles.scss";

interface Props {
  saveBasicProduct: (tipoProducto: string, product: object) => any;
  basicDataSaved: boolean;
  successfulSave: boolean;
  history: any;
  match: any;
  indicators: object[];
  getBasicProduct: (tipoProducto: string, id: string) => any;
  updateBasicProduct: (
    tipoProducto: string,
    productId: string,
    product: object
  ) => any;
  product: object;
  location: any;
  index: number;
  getNextStep: (object) => any;
  component: any;
  getMembershipById: (membershipId: string) => any;
  membership: { nombre: string };
  loadingMembership: boolean;
}

interface State {}

class ComponentWithMembershipName extends React.Component<any> {
  render() {
    const { Component, membership, rest, index, ...externalProps } = this.props;
    return (
      <div className="w-100 p-0 m-0">
        {membership && (
          <p className={s.primaryTitle}>{membership.nombre.toUpperCase()}</p>
        )}
        <Component {...rest} {...externalProps} key={index} />
      </div>
    );
  }
}

class MaintenancesCarousel extends React.Component<Props, State> {
  state = {
    steps: MEMBERSHIPS_STEPS,
    membership: null
  };

  componentDidMount() {
    const { productId } = this.props.match.params;
    if (productId) {
      this.props.getMembershipById(productId).then(({ value: { data } }) => {
        this.setState({ membership: data });
      });
    }
  }

  onClickNext = e => {
    e.preventDefault();
    const params = {
      ...this.props.match.params,
      productId: this.props.match.params.productId
    };
    this.props.history.push(
      MEMBERSHIPS_STEPS[this.props.index].getNextStep(params)
    );
  };

  getProductId = () => this.props.match.params.productId;

  render() {
    const productId = this.getProductId();

    return (
      <main>
        <Helmet title="Nueva Membresia" />
        <Layout>
          <SaveNextCarousel
            steps={this.state.steps.map(({ title, getHref }) => ({
              title,
              href: productId ? getHref(productId) : null
            }))}
            onClickNext={this.onClickNext}
            index={this.props.index}
            disableNext={!this.props.match.params.productId}
            activeStep={productId ? this.state.steps.length : 0}
          >
            {this.state.steps.map(
              ({ component: Component, ...rest }, index) => {
                return (
                  <ComponentWithMembershipName
                    Component={Component}
                    index={index}
                    rest={rest}
                    membership={this.state.membership}
                    key={index}
                  />
                );
              }
            )}
          </SaveNextCarousel>
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getMembershipById: (id: string) => {
    return dispatch(productActions.getBasicData("membresias", id));
  }
});

const mapStateToProps = _ => {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(s)(MaintenancesCarousel));
