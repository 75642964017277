import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";

import { Button, Row, Col, Input, Container } from "reactstrap";
import s from "./styles.scss";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DragAndDropSelect from "../common/dragAndDropSelect";
import trim from "lodash.trim";
import pullAllBy from "lodash.pullallby";
import isNil from "lodash.isnil";

interface Props {
  items: any[];
  selected: any[];
  updateSelection: any;
  searchByText: any;
  keyId: string;
  keyName: string;
  disableSearch: boolean;
  itemFullWidth: boolean;
  onClickSelected?: (itemId: string, state: boolean) => any;
  resetClicked: (source: object[]) => any;
}

interface State {
  searchText: string;
  items: ITEM[];
  selected: ITEM[];
  dataLoaded: boolean;
  selectedLoaded: boolean;
}

interface ITEM {
  id: string;
  content: string;
}

const checkValid = (propsData, alreadyLoaded) =>
  !alreadyLoaded && propsData && propsData.length !== 0;

const sortItems = (data: ITEM[]) =>
  data.sort(({ content: contentA }, { content: contentB }) =>
    contentA.localeCompare(contentB)
  );

const pullSelected = (data, selected) =>
  sortItems(pullAllBy<ITEM>(data, selected, "id"));

const convertToItems = (data, idKey, nameKey) =>
  data.map(({ [idKey]: id, [nameKey]: content }) => ({
    id,
    content: trim(content)
  }));

class MaintenancesSelector extends React.PureComponent<Props, State> {
  state = {
    searchText: "",
    items: null,
    selected: [],
    dataLoaded: false,
    selectedLoaded: false
  };

  componentDidMount() {
    if (this.props.searchByText) {
      this.props.searchByText({
        text: "*"
      });
    }
  }

  componentDidUpdate({ items: prevItems, selected: prevSelected }) {
    if (checkValid(this.props.items, this.state.dataLoaded)) {
      const items = pullSelected(
        convertToItems(this.props.items, this.props.keyId, this.props.keyName),
        this.state.selected
      );
      this.setState({ items, dataLoaded: true });
    }

    if (
      (this.state.dataLoaded &&
        !this.state.selectedLoaded &&
        this.props.selected &&
        this.props.selected.length) ||
      (this.state.selectedLoaded &&
        (!prevSelected || prevSelected.length === 0) &&
        this.props.selected.length !== 0) ||
      (this.state.selectedLoaded && prevSelected !== this.props.selected)
    ) {
      const selected = convertToItems(
        this.props.selected,
        this.props.keyId,
        this.props.keyName
      );
      if (!isNil(selected[0].id)) {
        const newItems = pullSelected(this.state.items, selected);
        this.setState({ items: newItems, selected, selectedLoaded: true });
        this.props.updateSelection({ selected });
      }
    }

    if (prevItems && !this.props.items)
      this.setState({ ...this.state, dataLoaded: false });
  }

  searchMaintenancesByText = (e, text) => {
    e.preventDefault();
    this.props.searchByText({
      text,
      dataLoaded: false
    });
  };

  onChangeText = e => {
    const { value: searchText } = e.target;
    this.setState({ searchText });
  };

  updateSelection = ({ items, selected }) => {
    this.setState({
      items: sortItems(items),
      selected: sortItems(selected)
    });
    this.props.updateSelection({ selected });
  };

  render() {
    return (
      <Container className="p-0 m-0">
        {this.props.searchByText && !this.props.disableSearch && (
          <Row className="p-0 m-0">
            <Col className="p-0 m-0 mb-2">
              <Input
                label={"Búsqueda por Nombre"}
                name="nameSearch"
                type="text"
                id="nameSearch"
                placeholder="Escriba * para buscar todos"
                value={this.state.searchText}
                onChange={e => this.onChangeText(e)}
                errors={[]}
              />
            </Col>
            <Col xs={1} className="p-0 m-0 mb-2">
              <Button
                onClick={e =>
                  this.searchMaintenancesByText(e, this.state.searchText)
                }
                color="link"
                className="text-danger float-left"
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </Col>
            <Col />
          </Row>
        )}
        <Row className="p-0 m-0">
          {this.state.items && (
            <DragAndDropSelect
              data={this.state.items}
              selected={this.state.selected}
              updateSelection={this.updateSelection}
              onClickSelected={this.props.onClickSelected}
              resetClicked={this.props.resetClicked}
              itemFullWidth={this.props.itemFullWidth}
            />
          )}
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(MaintenancesSelector));
