import ComisionDeportiva from "../../types/ComisionDeportiva";
import IAction from "../../types/IAction";
import { ERROR } from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";
import actionTypes from "../actionTypes";
import ComisionProcesada from "../../types/ComisionProcesada";
import ClaseImpartidaSinComision from "../../types/ClaseImpartidaSinComision";

export interface GetComisionesDeportivaCrm1Pendientes {
  comsionesDeportivaCrm1Pendientes: ComisionDeportiva[];
  comsionesDeportivaCrm1PendientesLoading: boolean;
  comsionesDeportivaCrm1PendientesError: string;
}

export interface PutComisionesDeportivaCrm1Procesar {
  comsionesDeportivaCrm1Procesadas: ComisionProcesada[];
  comsionesDeportivaCrm1ProcesarLoading: boolean;
  comsionesDeportivaCrm1ProcesarError: string;
}

export interface GetClasesSinComision {
  clasesSinComision: ClaseImpartidaSinComision[];
  clasesSinComisionLoading: boolean;
  clasesSinComisionError: string;
}

export interface PostClasesComisionGenerar {
  clasesComisionGeneradas: number[];
  clasesComisionGenerarLoading: boolean;
  clasesComisionGenerarError: string;
}

interface State
  extends GetComisionesDeportivaCrm1Pendientes,
    PutComisionesDeportivaCrm1Procesar,
    GetClasesSinComision,
    PostClasesComisionGenerar {}

const initialState: State = {
  comsionesDeportivaCrm1Pendientes: null,
  comsionesDeportivaCrm1PendientesLoading: false,
  comsionesDeportivaCrm1PendientesError: null,

  comsionesDeportivaCrm1Procesadas: null,
  comsionesDeportivaCrm1ProcesarLoading: false,
  comsionesDeportivaCrm1ProcesarError: null,

  clasesSinComision: null,
  clasesSinComisionLoading: false,
  clasesSinComisionError: null,

  clasesComisionGeneradas: null,
  clasesComisionGenerarLoading: false,
  clasesComisionGenerarError: null
};

export default function comisionesDeportivaCrm1(
  state: State = initialState,
  action: IAction
): State {
  const snapshot = snapshotAction(state, action);

  // Llamada a resolver de snapshot
  state != snapshot &&
    !action.type.endsWith("_PENDING") &&
    action.meta &&
    action.meta.resolver &&
    action.meta.resolver({
      fulfilled: action.type.endsWith("_FULFILLED"),
      ...snapshot
    });

  return state != snapshot
    ? {
        ...state,
        ...snapshot
      }
    : state;
}

function snapshotAction(state = initialState, action: IAction): Partial<State> {
  switch (action.type) {
    /// GET_COMISIONES_DEPORTIVA_CRM1_PENDIENTES
    case `${actionTypes.GET_COMISIONES_DEPORTIVA_CRM1_PENDIENTES}_PENDING`: {
      return {
        comsionesDeportivaCrm1Pendientes: null,
        comsionesDeportivaCrm1PendientesLoading: true,
        comsionesDeportivaCrm1PendientesError: null,
        comsionesDeportivaCrm1Procesadas: null
      };
    }
    case `${actionTypes.GET_COMISIONES_DEPORTIVA_CRM1_PENDIENTES}_REJECTED`: {
      return {
        comsionesDeportivaCrm1Pendientes: null,
        comsionesDeportivaCrm1PendientesLoading: false,
        comsionesDeportivaCrm1PendientesError: readMessageBack(action, ERROR)
      };
    }
    case `${actionTypes.GET_COMISIONES_DEPORTIVA_CRM1_PENDIENTES}_FULFILLED`: {
      return {
        comsionesDeportivaCrm1Pendientes: action.payload.data || [],
        comsionesDeportivaCrm1PendientesLoading: false,
        comsionesDeportivaCrm1PendientesError: null
      };
    } ////

    /// PUT_COMISIONES_DEPORTIVA_CRM1_PROCESAR
    case `${actionTypes.PUT_COMISIONES_DEPORTIVA_CRM1_PROCESAR}_PENDING`: {
      return {
        comsionesDeportivaCrm1Procesadas: null,
        comsionesDeportivaCrm1ProcesarLoading: true,
        comsionesDeportivaCrm1ProcesarError: null
      };
    }
    case `${actionTypes.PUT_COMISIONES_DEPORTIVA_CRM1_PROCESAR}_REJECTED`: {
      return {
        comsionesDeportivaCrm1Procesadas: null,
        comsionesDeportivaCrm1ProcesarLoading: false,
        comsionesDeportivaCrm1ProcesarError: readMessageBack(action, ERROR)
      };
    }
    case `${actionTypes.PUT_COMISIONES_DEPORTIVA_CRM1_PROCESAR}_FULFILLED`: {
      return {
        comsionesDeportivaCrm1Procesadas: action.payload.data || [],
        comsionesDeportivaCrm1ProcesarLoading: false,
        comsionesDeportivaCrm1ProcesarError: null
      };
    } ////

    /// GET_CLASES_SIN_COMISION
    case `${actionTypes.GET_CLASES_SIN_COMISION}_PENDING`: {
      return {
        clasesComisionGeneradas: null,
        clasesSinComision: null,
        clasesSinComisionLoading: true,
        clasesSinComisionError: null
      };
    }
    case `${actionTypes.GET_CLASES_SIN_COMISION}_REJECTED`: {
      return {
        clasesSinComision: null,
        clasesSinComisionLoading: false,
        clasesSinComisionError: readMessageBack(action, ERROR)
      };
    }
    case `${actionTypes.GET_CLASES_SIN_COMISION}_FULFILLED`: {
      return {
        clasesSinComision: action.payload.data || [],
        clasesSinComisionLoading: false,
        clasesSinComisionError: null
      };
    } ////

    /// POST_CLASES_COMISION_GENERAR
    case `${actionTypes.POST_CLASES_COMISION_GENERAR}_PENDING`: {
      return {
        clasesComisionGeneradas: null,
        clasesComisionGenerarLoading: true,
        clasesComisionGenerarError: null
      };
    }
    case `${actionTypes.POST_CLASES_COMISION_GENERAR}_REJECTED`: {
      return {
        clasesComisionGeneradas: null,
        clasesComisionGenerarLoading: false,
        clasesComisionGenerarError: readMessageBack(action, ERROR)
      };
    }
    case `${actionTypes.POST_CLASES_COMISION_GENERAR}_FULFILLED`: {
      return {
        clasesComisionGeneradas: action.payload.data || [],
        clasesComisionGenerarLoading: false,
        clasesComisionGenerarError: null
      };
    } ////

    default: {
      return state;
    }
  }
}
