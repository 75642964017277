import React from "react";
import { connect } from "react-redux";
import Layout from "../../components/layout";
import { Helmet } from "react-helmet";
import DiscCodeListByMembershipPage from "../../components/discCodeListByMembershipPage";
import {
  clearSingleCode,
  getSingleCode,
  getCodesList,
  clearCodes,
  getCodesListLtebyMembrsh
} from "../../actions/dicounts";
import { QueryArguments } from "../../../types-business/QueryArguments";

interface Props {
  getCodesList: (lote: string, args?: QueryArguments) => any;
  getCodesListLtebyMembrsh: (data: object) => any;
  getSingleCode: (data: object) => any;
  clearCodes: () => any;
  clearSingleCode: () => any;
  discounts: object;
}

interface State {
  tipoCodigo: string; //"PRODUCTO", // "PRODUCTO"|| "MEMBRESIA",
  idProducto: string; //"idProducto",
  tipoDescuento: string; // "MONTO", // "MONTO"|| "PORCENTAJE",
  inicioVigencia: string; // "xxxx",
  finVigencia: string; // "xxxx",
}

class DiscCodeListByMembership extends React.Component<Props, State> {
  state = {
    tipoCodigo: "",
    idProducto: "",
    tipoDescuento: "",
    inicioVigencia: "",
    finVigencia: ""
  };

  render() {
    return (
      <main>
        <Helmet title="Creacion codigos de descuento para clubes" />
        <Layout>
          <DiscCodeListByMembershipPage
            discounts={this.props.discounts}
            getCodesList={this.props.getCodesList}
            getCodesListLtebyMembrsh={this.props.getCodesListLtebyMembrsh}
            clearCodes={this.props.clearCodes}
            getSingleCode={this.props.getSingleCode}
            clearSingleCode={this.props.clearSingleCode}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getCodesListLtebyMembrsh: (idMembresia: string) => {
    dispatch(getCodesListLtebyMembrsh(idMembresia));
  },
  clearCodes: () => {
    return dispatch(clearCodes());
  },
  getCodesList: (lote: string, args?: QueryArguments) => {
    return dispatch(getCodesList(lote, args));
  },
  getSingleCode: (data: object) => {
    dispatch(getSingleCode(data));
  },
  clearSingleCode: () => {
    dispatch(clearSingleCode());
  }
});

const mapStateToProps = state => {
  const { discounts } = state;
  return {
    discounts
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DiscCodeListByMembership);
