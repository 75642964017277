import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import Reform from "@franleplant/reform";

import ModalFrame from "../../../toolkit/modalFrame";
import s from "./styles.scss";
import {
  Container,
  Form,
  Alert,
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";
import SelectFilter from "../common/SelectFilter";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  save: (type: string, id: string) => any;
  saving: boolean;
  savingError: boolean;
  clubs: object[];
  sellers: object[];
}

interface State {
  fields: {
    reassign: string;
  };
  clubs: object[];
  activeTab: string;
  errors: object;
}

class ReassignProspectModal extends React.Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);

  state = {
    fields: {
      reassign: "seller",
      id: "",
      comboData: { value: "", label: "Seleccionar" }
    },
    clubs: [],
    sellers: [],
    errors: {},
    activeTab: "1"
  };

  validationRules = {
    id: {
      required: true
    }
  };

  validationMessages = {
    required: _ => `Obligatorio`,
    default: _ => `Invalido`
  };

  onChangeCombo = option => {
    const { value } = option;
    this.setState(state => ({
      fields: {
        ...state.fields,
        ["id"]: value,
        ["comboData"]: option
      }
    }));
  };

  getOptionsClubs = clubs => {
    const clubsNombres = {};
    const clubsOptions = [
      {
        value: "",
        label: "Seleccionar"
      }
    ];

    clubs.forEach(club => {
      const { clubId, nombre: nombreClub } = club;
      if (!clubsNombres[nombreClub]) {
        clubsOptions.push({
          value: clubId,
          label: clubId + " - " + nombreClub
        });
        clubsNombres[nombreClub] = true;
      }
    });

    return clubsOptions;
  };

  getOptionsSellers = sellers => {
    const sellersOptions = [
      {
        value: "",
        label: "Seleccionar"
      }
    ];
    sellers.forEach(seller => {
      const { userId, name, firstSurname, secondSurname } = seller;
      if (userId && name) {
        sellersOptions.push({
          value: userId,
          label: `${userId} - ${name || ""} ${firstSurname ||
            ""} ${secondSurname || ""}`
        });
      }
    });
    return sellersOptions;
  };

  updateState = () => {
    if (!this.state.clubs.length && this.props.clubs) {
      this.setState(() => ({
        clubs: this.getOptionsClubs(this.props.clubs)
      }));
    }
    if (!this.state.sellers.length && this.props.sellers) {
      this.setState(() => ({
        sellers: this.getOptionsSellers(this.props.sellers)
      }));
    }
  };

  componentDidMount() {
    this.updateState();
  }

  componentDidUpdate() {
    this.updateState();
  }

  onSubmit = async e => {
    e.preventDefault();
    if (this.re.validateFormFromState()) {
      const response = await this.props.save(
        this.state.fields.reassign,
        this.state.fields.id
      );
      if (response && response.value && response.value.status == 200) {
        this.setState(state => {
          const fields = {
            id: "",
            comboData: { value: "", label: "Seleccionar" }
          };
          return {
            ...state,
            fields
          };
        });
      }
    }
  };

  toggleTab = tab => {
    const reassign = tab === "1" ? "seller" : "club";
    if (this.state.activeTab !== tab) {
      this.setState(state => {
        const fields = {
          comboData: { value: "", label: "Seleccionar" },
          id: "",
          reassign
        };
        return {
          ...state,
          activeTab: tab,
          fields
        };
      });
    }
  };

  render() {
    return (
      <ModalFrame
        title={"Reasignar Prospectos"}
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        size={"md"}
      >
        <Container>
          <Form>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "1"
                  })}
                  onClick={() => {
                    this.toggleTab("1");
                  }}
                >
                  Vendedor
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === "2"
                  })}
                  onClick={() => {
                    this.toggleTab("2");
                  }}
                >
                  Club
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent className="mt-3" activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                {this.state.activeTab === "1" && (
                  <Row>
                    <Col>
                      <SelectFilter
                        value={this.state.fields.comboData}
                        onChange={this.onChangeCombo}
                        options={this.state.sellers}
                        label={"Vendedor"}
                        name="id"
                        id="id"
                        placeholder="Vendedor"
                        errors={this.re.mapFieldErrors("id")}
                      />
                    </Col>
                  </Row>
                )}
              </TabPane>
              <TabPane tabId="2">
                {this.state.activeTab === "2" && (
                  <Row>
                    <Col>
                      <SelectFilter
                        value={this.state.fields.comboData}
                        onChange={this.onChangeCombo}
                        options={this.state.clubs}
                        label={"Club"}
                        name="id"
                        id="id"
                        placeholder="Club"
                        errors={this.re.mapFieldErrors("id")}
                      />
                    </Col>
                  </Row>
                )}
              </TabPane>
            </TabContent>
            <Button
              className={`${s.buttonMarginTop} ${s.primaryButton}`}
              onClick={e => this.onSubmit(e)}
              size="lg"
              disabled={this.props.saving}
              block
            >
              {this.props.saving ? "Guardando" : "Guardar"}
            </Button>
            {this.props.savingError && (
              <span>
                <hr />
                <Alert color="danger">Ocurrió un error inesperado.</Alert>
              </span>
            )}
          </Form>
        </Container>
      </ModalFrame>
    );
  }
}

export default withRouter(withStyles(s)(ReassignProspectModal));
