export default interface Invitado {
  idPersona: number;
  tipo: InvitadoTipo;
  omitirDocumentacion: boolean;
  idUserColaborador: number;
  idEmpresa: number;
  tipoIntercambio: InvitadoIntercambioComercialTipo;
  idPatronHorario: number;
  fechaInicioVigencia: string;
  fechaFinVigencia: string;
  observaciones: string;
  idPersonaTutor: number;
  clubes: number[];
}

export enum InvitadoTipo {
  FAMILIAR = "Familiar",
  AUTORIZACION_DIRECTA = "Autorización directa",
  INTERCAMBIO_COMERCIAL = "Intercambio comercial"
}

export enum InvitadoIntercambioComercialTipo {
  AUDITORIA = "Auditoría",
  PLAN_SALUD = "Plan Salud",
  PROVEEDOR = "Proveedor",
  MARKETING = "Marketing",
  NUEVOS_NEGOCIOS = "Nuevos negocios",
  OTROS = "Otros"
}

export enum InvitadoEstatus {
  ACTIVO = "Activo",
  INACTIVO = "Inactivo",
  PENDIENTE_AUTORIZACION = "Pendiente de autorización"
}
