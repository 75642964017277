import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import axios from "axios";
import constants from "../../utils/constants";

const baseUrl = `/api/events`;

export const getEvents = (body: object): IAction => {
  // 1221
  return {
    type: actionTypes.GET_EVENTS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/get-event/`, {
        withCredentials: true,
        params: { ...body }
      })
    }
  };
};

export const getUsersInEvent = (eventId: string, clubId: string): IAction => {
  // 1223
  return {
    type: actionTypes.GET_USERS_IN_EVENT,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/get-users-in-event`, {
        withCredentials: true,
        params: { eventId, clubId }
      })
    }
  };
};

export const addAttendant = (
  productId: string,
  attendantId: string
): IAction => {
  // 1229
  return {
    type: actionTypes.ADD_ATTENDANT_TO_EVENT,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${baseUrl}/add-attendant-in-product/${productId}/${attendantId}`,
        {},
        {
          withCredentials: true
        }
      )
    }
  };
};

export const deleteAttendantOnEvent = (attendantId: string): IAction => {
  // 1230
  return {
    type: actionTypes.DELETE_ATTENDANT_TO_EVENT,
    payload: {
      promise: axios.delete(
        `${constants.BASE_URL}${baseUrl}/delete-attendant/${attendantId}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const postSession = (body: object): IAction => {
  // 1232
  return {
    type: actionTypes.ADD_SESSION,
    payload: {
      promise: axios.post(
        `${constants.BASE_URL}${baseUrl}/add-session-in-product`,
        body,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const editSession = (body: object): IAction => {
  // 1233
  return {
    type: actionTypes.EDIT_SESSION,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${baseUrl}/edit-session-in-product`,
        body,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const deleteSession = (body: object): IAction => {
  // 1233
  return {
    type: actionTypes.DELETE_SESSION,
    payload: {
      promise: axios.put(
        `${constants.BASE_URL}${baseUrl}/remove-session-in-product`,
        body,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getEventsByFilter = (body: object): IAction => {
  // 1234
  return {
    type: actionTypes.GET_EVENTS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/event-by-filter/`, {
        withCredentials: true,
        params: { ...body }
      })
    }
  };
};

export const getDetails = (body: object): IAction => {
  // 1231
  return {
    type: actionTypes.GET_EVENT_DETAILS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/product-details`, {
        withCredentials: true,
        params: { ...body }
      })
    }
  };
};

export const newSessionProductId = (id: number): IAction => {
  return {
    type: actionTypes.NEW_SESSION_ID,
    payload: id
  };
};

export const searchSportsProgramsInstances = (body: any): IAction => {
  const productoId = body.programaId;
  return {
    type: actionTypes.GET_SPORTS_PROGRAMS_INSTANCES,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/programs/${productoId}`,
        {
          withCredentials: true,
          params: { ...body }
        }
      )
    }
  };
};

export const clearEvents = (): IAction => {
  return {
    type: actionTypes.CLEAR_EVENTS,
    payload: null
  };
};

export const postRepararVenta = (idVendedor, folio) => {
  return axios.post(
    `${constants.BASE_URL}${baseUrl}/reparar-comision-venta/${idVendedor}/${folio}`,
    null,
    {
      withCredentials: true
    }
  );
};

export const postRepararCoord = (idVendedor, folio) => {
  return axios.post(
    `${constants.BASE_URL}${baseUrl}/reparar-comision-coord/${idVendedor}/${folio}`,
    null,
    {
      withCredentials: true
    }
  );
};

export const postExtraComision = (idEvento, razonIgnorarPuesto) => {
  return axios.post(
    `${constants.BASE_URL}/api/reports/reparar-operacion/${idEvento}`,
    null,
    {
      withCredentials: true,
      params: { razonIgnorarPuesto }
    }
  );
};
