export const hasKey = (key: string) => {
  return !!localStorage.getItem(key);
};

export const getString = (key: string) => {
  return localStorage.getItem(key);
};

export const getObject = (key: string, _default?: any) => {
  const tmp = localStorage.getItem(key);
  if (!tmp) {
    return _default || null;
  }

  return JSON.parse(tmp);
};

export const getArray = (key: string, _default?: any) => {
  const tmp = localStorage.getItem(key);

  if (!tmp) {
    return _default || null;
  }

  return JSON.parse(tmp);
};

export const putString = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

export const putObject = (key: string, value: object) => {
  if (!!value) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export const putArray = (key: string, value: any[]) => {
  if (!!value && !!value.length) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export const remove = (key: string) => {
  localStorage.removeItem(key);
};

export const clear = () => {
  localStorage.clear();
};

export enum STORED_TYPE {
  OBJECT = "OBJECT",
  ARRAY = "ARRAY"
}
