import React from "react";
import ReactTable from "react-table";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";

class ProcedureChargesMessages extends React.Component {
  render() {
    let columns = [
      {
        Header: () => <p className="font-weight-bold">Monto</p>,
        id: 0,
        width: 150,
        accessor: "monto",
        Cell: row => {
          return (
            <p className="text-center">
              {!!row.original.montoMinimo
                ? `$ ${row.original.montoMinimo}
              - `
                : ""}
              $ {row.original.monto || 0.0}
            </p>
          );
        }
      },
      {
        Header: () => <p className="font-weight-bold">Descripción</p>,
        id: 1,
        width: 500,
        accessor: "descripcion",
        Cell: row => {
          return <p className={s.mensaje}>{row.original.descripcion}</p>;
        }
      }
    ];

    const { cargos } = this.props;

    return (
      <div>
        <ReactTable
          data={cargos || []}
          className={s.table}
          columns={columns}
          sortable={false}
          resizable={true}
          pageSize={(cargos || []).length + 1}
          showPagination={false}
          noDataText="Sin cargos"
        />
      </div>
    );
  }
}

export default withRouter(withStyles(s)(ProcedureChargesMessages));
