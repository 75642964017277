exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "span._1FuzG{cursor:pointer}span._1FuzG,span._1O2jJ{font-size:16px;margin:0 8px}._3hnw6{background:#f3d8d8}._3SNi8{padding:0!important}._3US1N{padding:7px 3px}._3vJVO div{overflow-x:hidden!important}._3vJVO div div div div{overflow:hidden!important}", ""]);

// exports
exports.locals = {
	"btn": "_1FuzG",
	"info": "_1O2jJ",
	"sinAcceso": "_3hnw6",
	"tdClear": "_3SNi8",
	"field": "_3US1N",
	"hidden": "_3vJVO"
};