import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import { Button, Col, Container, Row } from "reactstrap";
import BaseInput from "../../../../toolkit/baseInput";
import ModalFrame from "../../../../toolkit/modalFrame";
import { handleRequestError } from "../../../../utils/helpers";
import {
  infoNotification,
  successNotification
} from "../../../../utils/notifications";
import { associateAlternateClub } from "../../../actions/partners";
import { Partner } from "../interfaces";
import s from "../styles.scss";

interface Props {
  partner: Partner;
  isOpen: boolean;
  clubs: any[];
  cancel: () => any;
  success: () => any;
}

interface State {
  selectedClubId: number;
}

class AlternateClubAssociationModal extends React.Component<Props, State> {
  state = {
    selectedClubId: null
  };

  componentDidMount() {}

  handleSubmit = () => {
    const { selectedClubId } = this.state;
    const { idMembresiaSocio } = this.props.partner;

    infoNotification("Asociando club alterno...");

    associateAlternateClub(idMembresiaSocio, selectedClubId)
      .then(() => {
        successNotification("Club alterno asociado correctamente");
        this.setState({
          selectedClubId: null
        });
        this.props.success();
      })
      .catch(handleRequestError);
  };

  handleCancel = () => {
    this.setState({ selectedClubId: null });
    this.props.cancel();
  };

  render() {
    return (
      <ModalFrame
        title={`Asociación de club alterno`}
        isOpen={this.props.isOpen}
        toggle={() => {}}
        size={"md"}
      >
        <Container style={{ width: "90%" }} className="center-block p-0 mb-3">
          <div>
            <BaseInput
              type="select"
              id={"alternateClubId"}
              name={"alternateClubId"}
              label={"Club alterno"}
              value={this.state.selectedClubId}
              size="sm"
              options={[{ value: "", label: "Seleccione el Club" }].concat(
                this.props.clubs.map(option => ({
                  value: option.clubId,
                  label: option.nombre
                }))
              )}
              onChange={e => {
                const value = e.target.value;
                this.setState({ selectedClubId: value });
              }}
            />
          </div>

          <Row className="m-0">
            <Col xs="12" md="5">
              <Button
                className={`mt-3 primaryButton`}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.handleSubmit();
                }}
                block
                disabled={!this.state.selectedClubId}
              >
                Guardar
              </Button>
            </Col>
            <Col xs="12" md="2"></Col>
            <Col xs="12" md="5">
              <Button
                className={` mt-3`}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.handleCancel();
                }}
                block
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </Container>
      </ModalFrame>
    );
  }
}

export default withStyles(s)(AlternateClubAssociationModal);
