import React from "react";
import { connect } from "react-redux";
import isEqual from "lodash.isequal";
import { AgreementSelector } from "../../components/AgreementSelector";
import {
  getEmpresasActivas,
  getEmpresasConvenio
} from "../../../Home/actions/convenios";

interface Props {
  searchByAttributes: (params: object) => any;
  loading: boolean;
  agreements: object[];
  disabled: boolean;
  onChange: (value: string) => any;
  value: string;
  getEmpresasConvenio: () => any;
  porEmpresas?: boolean;
  empresas: any[];
  empresasLoading: boolean;
  params: {
    clubId: string;
    mantenimientoId: string;
    membresiaId: string;
  };
}

class MaintenanceSelectorContainer extends React.Component<Props> {
  componentDidMount() {
    this.loadAgreements(this.props.params);
  }

  componentDidUpdate({ params: prevParams }) {
    const { params } = this.props;
    if (!isEqual(params, prevParams)) {
      this.loadAgreements(params);
    }
  }

  loadAgreements = params => {
    if (!this.props.disabled) {
      if (this.props.porEmpresas) {
        this.props.getEmpresasConvenio();
      } else {
        this.props.searchByAttributes(params);
      }
    }
  };

  render() {
    return (
      <AgreementSelector
        value={this.props.value}
        agreements={
          this.props.porEmpresas ? this.props.empresas : this.props.agreements
        }
        onChange={this.props.onChange}
        disabled={
          this.props.disabled ||
          this.props.loading ||
          this.props.empresasLoading
        }
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  searchByAttributes: (params: any) => {
    dispatch(getEmpresasActivas(params.clubId, params.mantenimientoId));
  },
  getEmpresasConvenio: () => {
    dispatch(getEmpresasConvenio());
  }
});

const mapStateToProps = state => {
  const {
    convenios: {
      empresasActivas,
      empresasActivasLoading,
      empresasConvenio,
      empresasConvenioLoading
    }
  } = state;

  return {
    agreements: empresasActivas,
    empresas: empresasConvenio,
    loading: empresasActivasLoading,
    empresasLoading: empresasConvenioLoading
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaintenanceSelectorContainer);
