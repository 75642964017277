import React from "react";
import { withRouter } from "react-router-dom";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./styles.scss";
import PriceDataMembershipsForm from "../priceDataMembershipsForm";
import {
  MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES,
  PRICES_STATUS
} from "../../../types/MassiveAuthorizationPricesFilter";

interface Props {
  history: any;
  match: any;
  addPricesToMemberships: (
    productId: string,
    tipoProducto: string,
    prices: object
  ) => any;
  getPricesToMemberships: (params: object) => any;
  successfulSave: boolean;
  conceptos: object[];
  estatus: object[];
  tiposPrecio: object[];
  prices: object;
  loading: boolean;
  savingPrices: boolean;
  savingPricesError: boolean;
  onClickSave: (e: object) => any;
  authorizePrices: (prices: object[]) => any;
  onSuccessfulSave: () => any;
  filterTypes: MEMBERSHIP_MASSIVE_AUTHORIZATION_FILTER_TYPES[];
  defaultStatus: PRICES_STATUS;
  memberships: object[];
  disableAmounts: boolean;
}

const priceDataMemberships = (props: Props) => (
  <PriceDataMembershipsForm
    addPricesToMemberships={props.addPricesToMemberships}
    getPricesToMemberships={props.getPricesToMemberships}
    tipoProducto={props.match.params.tipoProducto}
    productId={props.match.params.productId}
    successfulSave={props.successfulSave}
    conceptos={props.conceptos}
    estatus={props.estatus}
    prices={props.prices}
    tiposPrecio={props.tiposPrecio}
    loading={props.loading}
    savingPrices={props.savingPrices}
    savingPricesError={props.savingPricesError}
    onClickSave={props.onClickSave}
    authorizePrices={props.authorizePrices}
    onSuccessfulSave={props.onSuccessfulSave}
    filterTypes={props.filterTypes}
    defaultStatus={props.defaultStatus}
    memberships={props.memberships}
    disableAmounts={props.disableAmounts}
  />
);

export default withRouter(withStyles(s)(priceDataMemberships));
