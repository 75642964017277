import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button, Label } from "reactstrap";
import DatePicker from "../common/DatePicker";
import s from "./styles.scss";
import BaseInput from "../../../toolkit/baseInput";
import moment from "moment";
import SelectClubsModal from "./selectClubsModal";
import ProductClassification from "../../containers/productClassification";
import { getSportsProductsFilteredRequest } from "../../actions/catalogs";
import { handleRequestError } from "../../../utils/helpers";

export enum DiscountTypes {
  PERCENTAGE = "PORCENTAJE",
  AMOUNT = "MONTO"
}

interface Props {
  discounts: object;
  clubs: {
    clubId: number;
    nombre: string;
  }[];
  createCodes: (data: object) => any;
  clearCodes: () => any;
}

interface State {
  clasificacionProductoId: number;
  tmpClassificationProducts: any[];
  tmpProductSelected: object;

  tipoDescuento: string;
  descuento: string;
  inicioVigencia: string;
  finVigencia: string;
  totalCupones: number;

  clubesHash: object;
  isOpenModal: boolean;
  index: number;
  clubsSelected: {
    clubId: number;
    nombre: string;
  }[];
  descripcionLote: string;
}

interface Props {}

class DiscountCodeCreatorPage extends React.Component<Props, State> {
  state = {
    groups: [],
    clubesHash: null,
    totalCupones: 0,
    inicioVigencia: moment().toISOString(),
    finVigencia: moment().toISOString(),
    clasificacionProductoId: 0,
    tipoDescuento: DiscountTypes.PERCENTAGE,
    descuento: "",
    isOpenModal: false,
    index: -1,
    clubsSelected: null,
    descripcionLote: null,

    tmpClassificationProducts: [],
    tmpProductSelected: null
  };

  componentDidUpdate() {
    if (this.props.discounts && this.props.discounts.successSend) {
      let state = { ...this.state };
      state = {
        groups: [],
        clubesHash: null,
        totalCupones: 0,
        inicioVigencia: moment().toISOString(),
        finVigencia: moment().toISOString(),
        clasificacionProductoId: 0,
        tipoDescuento: DiscountTypes.PERCENTAGE,
        descuento: "",
        isOpenModal: false,
        index: -1,
        clubsSelected: null,
        descripcionLote: "",
        tmpProductSelected: null,
        tmpClassificationProducts: []
      };
      this.props.clearCodes();
      this.setState(state);
    }
  }

  onClubFieldChange = (e, idClub) => {
    let state = { ...this.state };
    state.clubesHash[idClub].cantidad = parseInt(e.target.value);
    let totalCupones = 0;
    for (const club in state.clubesHash) {
      totalCupones = totalCupones + state.clubesHash[club].cantidad;
    }
    state.totalCupones = totalCupones;
    this.setState(state);
  };

  cuponSort() {
    const noCuponesClub = Math.trunc(
      this.state.totalCupones / this.state.clubsSelected.length
    );
    const restante = this.state.totalCupones % this.state.clubsSelected.length;
    const clubesHash = {};
    for (let clubInfo of this.state.clubsSelected) {
      clubesHash[clubInfo.clubId] = clubInfo;
      clubesHash[clubInfo.clubId].cantidad = noCuponesClub;
    }
    for (const club in clubesHash) {
      clubesHash[club].cantidad = clubesHash[club].cantidad + restante;
      break;
    }
    this.setState({ ...this.state, clubesHash });
  }

  inputCreator = () => {
    if (!this.state.clubesHash) return null;

    let objectsArray = [];
    for (const club in this.state.clubesHash) {
      const clubTemp = this.state.clubesHash[club];
      objectsArray.push(
        <Col key={clubTemp.clubId} sm="2">
          <BaseInput
            label={clubTemp.nombre}
            name={clubTemp.clubId}
            type="number"
            id={clubTemp.clubId}
            size={"sm"}
            value={clubTemp.cantidad}
            onChange={e => this.onClubFieldChange(e, clubTemp.clubId)}
          />{" "}
        </Col>
      );
    }
    return objectsArray;
  };

  setCuponesClub() {
    let clubes = [];
    let state = { ...this.state };
    for (const club in state.clubesHash) {
      let { clubId, cantidad } = state.clubesHash[club];
      clubes.push({ clubId, cantidad });
    }
    const {
      inicioVigencia,
      finVigencia,
      clasificacionProductoId,
      tmpProductSelected,
      tipoDescuento,
      descuento,
      descripcionLote
    } = this.state;
    const obj = {
      inicioVigencia,
      finVigencia,
      clasificacionProductoId,
      productoId: tmpProductSelected,
      tipoDescuento,
      descuento,
      clubes,
      descripcionLote
    };
    this.props.createCodes(obj);
  }

  onChangeFecha = fieldName => {
    return async event => {
      const date = moment(event, "YYYY-MM-DD").toISOString();
      let state = { ...this.state };
      if (fieldName == "inicioVigencia") state.inicioVigencia = date;
      if (fieldName == "finVigencia") state.finVigencia = date;
      await this.setState(state);
    };
  };

  onChangeFilters = data => {
    this.setState({ ...this.state, ...data });
  };

  toggleModal = () => {
    this.setState({ ...this.state, isOpenModal: !this.state.isOpenModal });
  };

  addClubsToSportsClub = async clubsSelected => {
    await this.setState({ ...this.state, clubsSelected });
    this.toggleModal();
  };

  onClassificationLevelChange = (
    classificationId: number,
    parents: number[],
    level: number
  ) => {
    this.setState({
      clasificacionProductoId: classificationId
    });

    if (level == 3) {
      getSportsProductsFilteredRequest({
        clasificacionId: classificationId,
        nombre: "",
        estatus: "ACTIVO",
        carrito: true
      })
        .then(({ data }) => {
          this.setState({ tmpClassificationProducts: [...data] });
        })
        .catch(handleRequestError);
    } else {
      this.setState({ tmpClassificationProducts: [] });
    }
  };

  render() {
    return (
      <Container className="w-100 p-0 mt-3">
        <Row className="mb-3">
          <Col sm="12">Ingresa los campos requeridos para comenzar ( * )</Col>
        </Row>

        <SelectClubsModal
          clubsInUse={[]}
          isOpen={this.state.isOpenModal}
          toggle={this.toggleModal}
          addClubsToSportsClub={this.addClubsToSportsClub}
          validClubs={this.props.clubs}
        />

        <Row>
          <Col xs="9">
            <ProductClassification
              setResult={this.onClassificationLevelChange}
            />
          </Col>
          <Col>
            <BaseInput
              type="select"
              label={"Producto"}
              name="productSelector"
              id="productSelector"
              value={this.state.tmpProductSelected}
              options={[{ value: null, label: "Seleccione" }].concat(
                (this.state.tmpClassificationProducts || []).map(a => {
                  return { value: a.id, label: a.nombre };
                })
              )}
              onChange={e => {
                this.setState({ tmpProductSelected: e.target.value });
              }}
            />
          </Col>
        </Row>

        <Row className="mt-2">
          <Col sm={3}>
            <BaseInput
              label={"* Tipo de descuento"}
              name="tipoDescuento"
              type="select"
              id="tipo"
              placeholder="Tipo de descuento"
              value={this.state.tipoDescuento || DiscountTypes.PERCENTAGE}
              options={[
                {
                  label: DiscountTypes.PERCENTAGE,
                  value: DiscountTypes.PERCENTAGE
                },
                {
                  label: DiscountTypes.AMOUNT,
                  value: DiscountTypes.AMOUNT
                }
              ]}
              onChange={e => {
                const tipoDescuento = e.target.value;
                this.setState({ tipoDescuento });
              }}
            />
          </Col>
          <Col sm={3}>
            <BaseInput
              label={"* Descuento"}
              name={`descuento`}
              type="number"
              id={`descuento`}
              placeholder={"Descuento"}
              value={this.state.descuento}
              onChange={e => {
                const descuento = e.target.value;
                this.setState({ descuento });
              }}
            />
          </Col>
          <Col sm={3}>
            <DatePicker
              label="Inicio de vigencia"
              name={"inicioVigencia"}
              id={"inicioVigencia"}
              selected={moment(this.state.inicioVigencia, "YYYY-MM-DD")}
              onChange={this.onChangeFecha("inicioVigencia")}
            />
          </Col>
          <Col sm={3}>
            <DatePicker
              label="Fin de vigencia"
              name={"finVigencia"}
              id={"finVigencia"}
              selected={moment(this.state.finVigencia, "YYYY-MM-DD")}
              onChange={this.onChangeFecha("finVigencia")}
            />
          </Col>
          <Col sm={3}>
            <BaseInput
              label={"* Número de cupones a distribuir"}
              name={`totalCupones`}
              type="text"
              id={`totalCupones`}
              placeholder={"Número de cupones a crear"}
              value={this.state.totalCupones}
              onChange={e => {
                let state = { ...this.state };
                state.totalCupones =
                  e.target.value != ""
                    ? parseInt(e.target.value)
                    : e.target.value;
                this.setState(state);
              }}
            />
          </Col>
          <Col sm={9}>
            <BaseInput
              label={"* Descripción de lote"}
              name={`descripcionLote`}
              type="text"
              id={`descripcionLote`}
              placeholder={"Descripción"}
              value={this.state.descripcionLote}
              onChange={e => {
                let state = { ...this.state };
                state.descripcionLote = e.target.value;
                this.setState(state);
              }}
            />
          </Col>
        </Row>

        <div className="mt-2">
          <Label>Selección de clubes *:</Label>
          <div className="row">
            <div className="col">
              <div className={`text-justify ${s.divWithBorder} p-3 h-100`}>
                {!!this.state.clubsSelected &&
                  this.state.clubsSelected.map(x => x.nombre).join()}
              </div>
            </div>
            <div className="col-sm-4 col-md-3">
              <Button
                className="btn-sm w-100"
                onClick={() => this.toggleModal()}
              >
                Clubes
              </Button>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <div className="row">
            <div className="col"></div>
            <div className="col-sm-4 col-md-3">
              <Button
                className="btn-sm w-100"
                disabled={
                  !(
                    this.state.clubsSelected &&
                    !!this.state.clasificacionProductoId &&
                    !!this.state.tmpProductSelected &&
                    !!this.state.tipoDescuento &&
                    !!this.state.descuento &&
                    !!this.state.totalCupones &&
                    !!this.state.descripcionLote
                  )
                }
                onClick={() => this.cuponSort()}
              >
                Distribuir cupones
              </Button>
            </div>
          </div>
        </div>

        <Row className="mt-4">{this.inputCreator()}</Row>

        {this.state.clubesHash && (
          <span>
            <hr />
            <Row className="mt-4">
              <Col sm={{ size: 4, offset: 8 }}>
                <br />
                <Button
                  disabled={this.state.totalCupones > 0 ? false : true}
                  onClick={() => this.setCuponesClub()}
                  block
                >
                  Asignar cupones
                </Button>
              </Col>
            </Row>
          </span>
        )}
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(DiscountCodeCreatorPage));
