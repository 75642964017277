import FormBuilderConfig from "../../../toolkit/baseForms2/formBuilder/FormBuilder.config";
import IDateBuilder from "../../../toolkit/baseForms2/iDateBuilder/IDateBuilder";
import ISelectBuilder from "../../../toolkit/baseForms2/iSelectBuilder/ISelectBuilder";
import IButtonBuilder from "../../../toolkit/baseForms2/iButtonBuilder/IButtonBuilder";
import ITextBuilder from "../../../toolkit/baseForms2/iTextBuilder/ITextBuilder";
import IClubSelectorBuilder from "../../../toolkit/baseForms2Commons/iClubSelectorBuilder/IClubSelectorBuilder";
import IUserSelectorBuilder from "../../../toolkit/baseForms2Commons/iUserSelectorBuilder/IUserSelectorBuilder";

export type Inputs = "club" | "fecha" | "estatus" | "empleado" | "folioFactura";
export type Buttons = "buscar" | "procesar";
export const formConfig: FormBuilderConfig<Inputs, Buttons> = {
  inputs: [
    {
      name: "club",
      label: "Club",
      bts: {
        sCol: 4,
        mCol: 3
      },
      component: IClubSelectorBuilder
    },
    {
      name: "fecha",
      label: "* Fecha límite",
      bts: {
        sCol: 4,
        mCol: 3
      },
      component: IDateBuilder
    },
    {
      name: "estatus",
      label: "Estatus",
      bts: {
        sCol: 4,
        mCol: 3
      },
      component: ISelectBuilder,
      props: {
        emptyLabel: "Todos",
        data: [
          { value: "PENDIENTE", label: "Pendiente" },
          { value: "AUTORIZADA", label: "Autorizada" }
        ]
      }
    },
    {
      name: "folioFactura",
      label: "Factura",
      bts: {
        sCol: 4,
        mCol: 3
      },
      component: ITextBuilder
    },
    {
      name: "empleado",
      label: "Empleado",
      bts: {
        sCol: 8,
        mCol: 6
      },
      component: IUserSelectorBuilder
    }
  ],
  buttons: [
    {
      name: "buscar",
      label: "Buscar",
      isSubmit: true,
      component: IButtonBuilder,
      bts: {
        sCol: 4,
        sOff: 4,
        mCol: 3,
        mOff: 0
      }
    },
    {
      name: "procesar",
      label: "Procesar",
      bts: {
        sCol: 4,
        mCol: 3
      }
    }
  ],
  validations: {
    fecha: {
      required: true
    }
  },
  rows: [
    [
      "fecha",
      "club",
      "estatus",
      "folioFactura",
      "empleado",
      "buscar",
      "procesar"
    ]
  ]
};
