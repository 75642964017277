import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { FLUJO_COMISION } from "./interfaces";

export const MEMBERSHIP_TABLE_STRUCT = [
  {
    Header: "#",
    accessor: "ventaId",
    width: 50,
    className: "text-center"
  },
  {
    Header: "Club",
    accessor: "clubNombre",
    width: 70
  },
  {
    Header: "Membresía",
    accessor: "idPublico",
    className: "text-center",
    width: 80
  },
  {
    Header: "Vendedor",
    accessor: "vendedorNombre",
    minWidth: 120
  },
  {
    Header: "Tipo de comisión",
    accessor: "tipoComision"
  },
  {
    Header: "Descripción",
    accessor: "descripcion",
    minWidth: 120
  },
  {
    Header: "CAT",
    accessor: "estatusCAT",
    width: 70,
    className: "text-center"
  },
  {
    Header: "Importe de venta",
    accessor: "precioVenta",
    className: "text-right"
  },
  {
    Header: "Estatus",
    className: "text-center",
    minWidth: 120,
    Cell: row =>
      FLUJO_COMISION[row.original.estatusCRM1] || row.original.estatusCRM1
  },
  {
    Header: "Comisión",
    accessor: "montoComision",
    className: "text-right"
  },
  {
    Header: "% de comisión",
    accessor: "porcentajeComision",
    className: "text-right"
  }
];

/**
 * Genera un descriptor de columna para gestionar reasignaciones
 * @param callback Función invocada al oprimir el botón de reasignación
 * @returns Descriptor de columna admisible en una tabla
 */
export function generateReasignationCol(callback: (row: any) => void): any {
  return {
    Header: "Reasignar",
    Cell: row => {
      if (row.original.estatusComision == "AUTORIZADA") {
        return (
          <>
            <div
              id={"row-" + row.original.ventaId}
              style={{ textAlign: "center" }}
            >
              <FontAwesomeIcon icon={faExchangeAlt} color="#777" />
            </div>
            <UncontrolledTooltip
              placement="top"
              target={"row-" + row.original.ventaId}
            >
              Reasignación no permitida
            </UncontrolledTooltip>
          </>
        );
      } else {
        return (
          <div
            onClick={() => callback(row.original)}
            style={{ textAlign: "center", cursor: "pointer" }}
          >
            <FontAwesomeIcon icon={faExchangeAlt} color="#3366ff" />
          </div>
        );
      }
    }
  };
}
