import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import IAction from "../../../types/IAction";
import Layout from "../../components/layout";
import SportProgramComissions from "../../components/sportProgramComissions";
import { getClubs, getSellers } from "../../actions/catalogs";
import { searchSportsProgramsInstances } from "../../actions/events";
import { getSportsProductsFiltered } from "../../actions/catalogs";
import { getClassificationByLevel } from "../../actions/products";
import { getSportsProgramCommissions } from "../../actions/reports";
import { downloadSportsProgramCommissions } from "../../actions/reports";

interface Props {
  getClubs: () => IAction;
  getSellers: () => IAction;
  clubs: object[];
  sellers: object[];
  searchSportsProgramsInstances: (fields: object) => IAction;
  getSportsProductsFiltered: (args: object) => any;
  sportsProducts: { id: string; nombre: string }[];
  getClassificationByLevel: (level: number) => IAction;
  classificationByLevel: object[];
  sportsProgramsInstances: object[];
  getSportsProgramCommissions: (id: string) => IAction;
  downloadSportsProgramCommissions: (args: object) => IAction;
  sportsProgramCommisions: object;
}
interface State {}

class SportsProgramsComissions extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    this.props.getClubs();
    this.props.getSellers();
    this.props.getSportsProductsFiltered({
      clasificacionId: "73",
      estatus: "ACTIVO"
    });
  }

  render() {
    return (
      <main>
        <Helmet title="Sports programs comissions" />
        <Layout>
          <SportProgramComissions
            clubs={this.props.clubs}
            sellers={this.props.sellers}
            getPrograms={this.props.searchSportsProgramsInstances}
            sportProgramsProducts={this.props.sportsProducts}
            classificationByLevel={this.props.sportsProducts}
            getSportsProductsFiltered={this.props.getSportsProductsFiltered}
            sportsProgramsInstances={this.props.sportsProgramsInstances}
            getSportsProgramCommissions={this.props.getSportsProgramCommissions}
            downloadSportsProgramCommissions={
              this.props.downloadSportsProgramCommissions
            }
            sportsProgramCommisions={this.props.sportsProgramCommisions}
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({ catalogs, products, sportsProducts, reports }) => ({
  clubs: catalogs.clubs,
  sellers: catalogs.sellers,
  sportsProducts: catalogs.sportsProducts,
  classificationByLevel: products.classificationByLevel,
  sportsProgramsInstances: sportsProducts.sportsProgramsInstances,
  sportsProgramCommisions: reports.sportsProgramCommisions
});

const mapDispatchToProps = {
  getClassificationByLevel,
  getSellers,
  getClubs,
  searchSportsProgramsInstances,
  getSportsProductsFiltered,
  getSportsProgramCommissions,
  downloadSportsProgramCommissions
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SportsProgramsComissions);
