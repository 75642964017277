import React from "react";
import { Input } from "reactstrap";

export const InputCellTable = ({ value, onChange, disabled }) => (
  <Input
    type="number"
    disabled={disabled}
    className="renderEditableInputTracking"
    value={value}
    onChange={e => {
      const input = e.target.value;
      onChange(input);
    }}
  />
);
