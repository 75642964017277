import React from "react";
import IClubSelectorBuilderProps from "./IClubSelectorBuilder.props";
import IClubSelectorBuilderState from "./IClubSelectorBuilder.state";
import IInputBuilder from "../../baseForms2/iInputBuilder/IInputBuilder";
import IClubSelectorConnected from "../iClubSelector/IClubSelectorConnected";

export default class IClubSelectorBuilder<
  INP extends string
> extends IInputBuilder<
  INP,
  IClubSelectorBuilderProps<INP>,
  IClubSelectorBuilderState
> {
  render() {
    return <IClubSelectorConnected {...(this.props as any)} />;
  }
}
