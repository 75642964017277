import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import Reform from "@franleplant/reform";
import ModalFrame from "../../../toolkit/modalFrame";
import BaseInput from "../../../toolkit/baseInput";
import s from "./pawPrintModal.scss";
import { Button, Container, Form } from "reactstrap";

interface Props {
  isOpen: boolean;
  toggle: () => void;
  history: any;
  uploadAction: (action: string, pawPrint: string) => void;
  partner: any;
  partnerClubId: any;
  action: string;
}

interface State {
  fields: {
    pawPrint: string;
  };
  errors: object;
  ready: boolean;
  copied: boolean;
}

class PawPrintModal extends React.Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);

  state = {
    fields: {
      pawPrint: ""
    },
    errors: {},
    ready: false,
    copied: false
  };

  validationRules = {
    pawPrint: { required: true }
  };

  validationMessages = {
    required: _ => `Obligatorio`,
    default: _ => `Invalido`
  };

  onSubmit = e => {
    e.preventDefault();
    if (this.re.validateFormFromState()) {
      this.props.uploadAction(this.props.action, this.state.fields.pawPrint);
    }
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState(state => ({
        ...state,
        fields: {
          pawPrint: ""
        },
        ready: false,
        copied: false
      }));
    }
  }

  onChangeFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };
        return { ...state, error: {}, fields };
      });
      this.re.validateField(fieldName, value);
    };
  };

  render() {
    return (
      <ModalFrame
        title={`Actualizar ${this.props.action} de ${this.props.partner.nombre}`}
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
      >
        <Container>
          <div className="row" hidden={this.state.ready}>
            <div className="alert alert-info" style={{ fontSize: "0.8rem" }}>
              Abra la siguiente ruta en Internet Explorer y copie la huella
              generada por favor:
              <br />
              <br />
              <p
                style={{
                  color: "#007acc",
                  background: "#eee",
                  padding: "5px 2px"
                }}
              >
                {`https://crm.sportsworld.com.mx/publico/lecturaHuella/${this.props.partner.idPersona}/${this.props.partnerClubId}`}
              </p>
              <p style={{ textAlign: "right" }}>
                <a
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  hidden={this.state.copied}
                  onClick={() => {
                    this.setState({ copied: true });
                    navigator.clipboard.writeText(
                      `https://crm.sportsworld.com.mx/publico/lecturaHuella/${this.props.partner.idPersona}/${this.props.partnerClubId}`
                    );
                  }}
                >
                  Copiar
                </a>
                <a
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  hidden={!this.state.copied}
                  onClick={() => this.setState({ ready: true })}
                >
                  Listo
                </a>
              </p>
            </div>
          </div>
          <div className="mb-3" hidden={!this.state.ready}>
            <Form>
              <BaseInput
                label={"Huella"}
                name="pawPrint"
                type="text"
                id="pawPrint"
                placeholder="Ingresa la cadena de caracteres de la huella"
                value={this.state.fields.pawPrint}
                onChange={this.onChangeFactory("pawPrint")}
                errors={this.re.mapFieldErrors("pawPrint")}
              />
              <Button
                className={`${s.buttonMarginTop} ${s.primaryButton}`}
                onClick={e => this.onSubmit(e)}
                size="lg"
                block
              >
                Guardar
              </Button>
            </Form>
          </div>
        </Container>
      </ModalFrame>
    );
  }
}

export default withRouter(withStyles(s)(PawPrintModal));
