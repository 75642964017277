import React from "react";
import s from "./styles.scss";
import { Preview } from "../../../types/IAction";
import {
  Expediente,
  Archivo,
  StatusAutorizacion,
  ArchivoFirma,
  FlujoDocumento
} from "../expedientsV2controls/types";
import { Styled, ReduxConnect } from "../../../toolkit/decorators/Artifact";
import * as actions from "../../actions/expedientes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faSpinner } from "@fortawesome/free-solid-svg-icons";
import ExpedienteVisorOpciones from "./options";
import constants from "../../../utils/constants";
import {
  infoNotification,
  successNotification
} from "../../../utils/notifications";

const URL_BASE = `${constants.BASE_URL}/${constants.DOCUMENTS_BASE_URL}/`;

const mapDispatchToProps = dispatch => ({
  /// Dispatchers de Expedientes
  getExpedienteFromMembresia: (idExpediente): Promise<Preview<any>> => {
    return dispatch(actions.getExpedienteFromMembresia(idExpediente));
  },
  putStatus: (idExpediente, idItem, status): Promise<Preview<any>> => {
    return dispatch(actions.putStatus(idExpediente, idItem, status));
  },
  getFirmas: (idExpediente, idItem): Promise<Preview<any>> => {
    return dispatch(actions.getFirmas(idExpediente, idItem));
  }
  ////
});

const mapStateToProps = state => {
  const { expedientes } = state;

  return {
    /// State de Expedientes
    expedienteMembresia: expedientes.expedienteMembresia,
    expedienteMembresiaLoading: expedientes.expedienteMembresiaLoading,
    actualizandoStatus: expedientes.actualizandoStatus,
    archivoFirmas: expedientes.archivoFirmas,
    archivoFirmasError: expedientes.archivoFirmasError,
    archivoFirmasLoading: expedientes.archivoFirmasLoading
    ////
  };
};

interface Props {
  // Datos del expediente y sus archivos
  expedienteMembresia?: Expediente;
  expedienteMembresiaLoading?: boolean;
  getExpedienteFromMembresia?: (idExpediente) => Promise<Preview<any>>;

  // Actualización del estatus
  actualizandoStatus?: boolean;
  putStatus?: (idExpediente, idItem, status) => Promise<Preview<any>>;

  // Consulta de firmas
  archivoFirmasLoading?: boolean;
  getFirmas?: (idExpediente, idItem) => Promise<Preview<any>>;

  idMembresiaSocio: any;
  onClose: () => void;
}

interface State {
  waiting: boolean;
  opcion: OpcionType;
  archivo: Archivo;
  firmas: ArchivoFirma[];
}

export type OpcionType = "POR_VALIDAR" | "COMISION" | "TODOS";

/**
 * Visor del módulo de expedientes
 */
@ReduxConnect(mapStateToProps, mapDispatchToProps)
@Styled(s)
export default class ExpedienteVisor extends React.Component<Props, State> {
  archivos: Archivo[][] = [];
  state = {
    waiting: false,
    opcion: "POR_VALIDAR" as OpcionType,
    archivo: null as Archivo,
    firmas: [] as ArchivoFirma[]
  };

  componentDidMount(): void {
    this.props.getExpedienteFromMembresia(this.props.idMembresiaSocio);
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    // Se activa la animación de carga, cuando se está actualizando el expediente
    if (
      this.props.expedienteMembresiaLoading !=
      prevProps.expedienteMembresiaLoading
    ) {
      this.setState({
        waiting: this.props.expedienteMembresiaLoading
      });
    }
  }

  onSelectOpcion = (opcion: OpcionType) => {
    this.setState({ opcion });
  };

  onSelectArchivo = async (archivo: Archivo) => {
    this.setState({ archivo });

    const { idExpediente, idItem } = archivo;
    const preview = await this.props.getFirmas(idExpediente, idItem);
    const firmas = preview.fulfilled ? preview.archivoFirmas : [];

    this.setState({ firmas });
  };

  onClickStatus = async (status: StatusAutorizacion) => {
    const { idExpediente, idItem } = this.state.archivo;
    const preview = await this.props.putStatus(idExpediente, idItem, status);

    infoNotification("Actualizando el documento");

    if (preview.fulfilled) {
      successNotification(preview.message);
      this.props.getExpedienteFromMembresia(this.props.idMembresiaSocio);
    }
  };

  ordenarArchivos() {
    if (!this.props.expedienteMembresia.vigentes) {
      this.archivos = [];
      return;
    }

    const hash: any = {};
    const ordenados = [];

    // Se separan los expedientes por usuario
    for (const archivo of this.props.expedienteMembresia.vigentes) {
      switch (this.state.opcion) {
        case "POR_VALIDAR":
          if (archivo.statusAutorizacion == StatusAutorizacion.AUTORIZADO) {
            continue;
          }
          break;

        case "COMISION":
          if (!archivo.requeridoComision) {
            continue;
          }
          break;
      }

      if (hash[archivo.idOwner]) {
        hash[archivo.idOwner].push(archivo);
      } else {
        hash[archivo.idOwner] = [archivo];
      }
    }

    // Se concentran los expedientes por usuario en una lista
    for (const archivosKey in hash) {
      ordenados.push(hash[archivosKey]);
    }

    this.archivos = ordenados;
  }

  render() {
    if (this.state.waiting) {
      return this.renderEmpty();
    }

    this.ordenarArchivos();

    return (
      <div className={`${s.shadow}`}>
        <div className={`${s.visorPanel}`}>
          {/* Título del frame y opción de cierre */}
          <div className={`${s.topTools}`}>
            <div className={`${s.title}`}>Validación del expediente</div>
            <div className={`${s.closer}`} onClick={this.props.onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>

          {/* Contenido del frame */}
          <div className={`${s.content}`}>
            {/* Parte izquierda, donde se listan documentos y firmas */}
            <div className={`${s.documents}`}>
              {/* Listado de documentos superior */}
              <div className={`${s.list}`}>
                {/* Título y botones de selección de documentos */}
                <ExpedienteVisorOpciones
                  opcion={this.state.opcion}
                  onChange={this.onSelectOpcion}
                />

                {/* Contenedor de documentos */}
                <div className={`${s.listContent}`}>
                  {this.archivos.map(this.renderBloquesDocumentos)}
                </div>
              </div>

              {/* Detalles del archivo seleccionado y sus firmas inferior */}
              {this.renderArchivoSeleccionado()}
            </div>

            {/* Parte derecha, donde se muestra el documento en formato PDF */}
            <div className={`${s.frame}`}>{this.renderFrame()}</div>
          </div>
        </div>
      </div>
    );
  }

  renderEmpty = () => {
    return (
      <div className={`${s.shadow}`}>
        <div className={`${s.visorPanel}`}>
          {/* Título del frame y opción de cierre */}
          <div className={`${s.topTools}`}>
            <div className={`${s.title}`}>Validación del expediente</div>
            <div className={`${s.closer}`} onClick={this.props.onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>

          {/* Contenido del frame vacío */}
          <div className={`${s.content}`}>
            <div
              style={{ textAlign: "center", margin: "16px", fontSize: "21px" }}
            >
              <div className={s.spinner}>
                <FontAwesomeIcon icon={faSpinner} pulse />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Documentos vigentes, ordenados por integrantes
  renderBloquesDocumentos = (todos: Archivo[]) => {
    const archivos = todos
      ? todos.filter(archivo => archivo.flujo != FlujoDocumento.CINCEL)
      : null;
    if (!archivos || !archivos.length) {
      return <></>;
    }

    const nombre = `${archivos[0].nombreCompleto} (${archivos[0].idOwner})`;
    return (
      <div className={`${s.documentBlock}`}>
        <div className={s.nombre}>{nombre}</div>
        {archivos.map(archivo => (
          <div
            key={"archivo-itemx-" + archivo.idItem}
            className={`${s.document} ${this.renderArchivoClass(archivo)}`}
            onClick={() => this.onSelectArchivo(archivo)}
          >
            {archivo.nombre}
          </div>
        ))}
      </div>
    );
  };

  // Cálculo de la clase aplicable al nombre del archivo
  renderArchivoClass = (archivo: Archivo) => {
    const selected = archivo == this.state.archivo ? ` ${s.selected}` : "";

    switch (archivo.statusAutorizacion) {
      case StatusAutorizacion.NO_AUTORIZADO:
        return ` ${s.noAutorizado}${selected}`;
      case StatusAutorizacion.AUTORIZADO:
        return ` ${s.autorizado}${selected}`;
      case StatusAutorizacion.FIRMA_INVALIDA:
        return ` ${s.firmaInvalida}${selected}`;
      case StatusAutorizacion.DOCUMENTO_INCORRECTO:
        return ` ${s.documentoIncorrecto}${selected}`;
      default:
        return "";
    }
  };

  // Panel inferior con los detalles del archivo
  renderArchivoSeleccionado = () => {
    const archivo: Archivo = this.state.archivo;

    if (archivo == null) {
      return <></>;
    }

    if (this.props.archivoFirmasLoading) {
      return (
        <div style={{ textAlign: "center", margin: "16px", fontSize: "21px" }}>
          <div className={s.spinner}>
            <FontAwesomeIcon icon={faSpinner} pulse />
          </div>
        </div>
      );
    }

    return (
      <div className={`${s.signs}`}>
        <div className={`${s.signsTitle}`}>Documento seleccionado</div>
        <div className={`${s.nombre}`}>{archivo.nombre}</div>
        <div className={`${s.signsContent}`}>{this.renderFirmas()}</div>
        {this.renderAcciones()}
      </div>
    );
  };

  // Devuelve las firmas, en caso de existir
  renderFirmas = () => {
    if (this.state.firmas.length == 0) {
      return (
        <div className={`${s.signAlert}`}>
          No existen firmas en el documento
        </div>
      );
    }

    return this.state.firmas.map(firma => (
      <div
        key={firma.idExpediente + "-" + firma.idItem + "-" + firma.bookmark}
        className={`${s.sign}`}
      >
        <div className={`${s.signOwner}`}>{firma.nombre}</div>
        <div className={`${s.signImageFrame}`}>
          <img src={firma.base64Uri} className={`${s.signImage}`} />
        </div>
      </div>
    ));
  };

  // Devuelve los botones de acciones de validación, para documentos completos
  renderAcciones = () => {
    if (this.state.archivo == null) {
      return <div className={`${s.acciones}`}></div>;
    }

    if (!this.state.archivo.storage) {
      return (
        <div className={`${s.acciones}`}>
          <span>El archivo no existe, no se pueden realizar acciones.</span>
        </div>
      );
    }

    if (!this.state.archivo.completo) {
      return (
        <div className={`${s.acciones}`}>
          <span>
            El archivo no está firmado, no se pueden realizar acciones.
          </span>
        </div>
      );
    }

    const requiereFirma =
      this.state.archivo.flujo == FlujoDocumento.FIRMA_DIGITAL ||
      this.state.archivo.flujo == FlujoDocumento.FIRMA_MATERIAL;

    const requiereEscaner =
      this.state.archivo.flujo == FlujoDocumento.ESCANEAR ||
      this.state.archivo.flujo == FlujoDocumento.FIRMA_MATERIAL;

    return (
      <div className={`${s.acciones}`}>
        <div>
          <button
            onClick={() => this.onClickStatus(StatusAutorizacion.AUTORIZADO)}
            type="button"
            className="btn btn-success btn-sm"
          >
            Autorizar
          </button>
        </div>
        <div hidden={!requiereFirma}>
          <button
            onClick={() =>
              this.onClickStatus(StatusAutorizacion.FIRMA_INVALIDA)
            }
            type="button"
            className="btn btn-danger btn-sm"
          >
            Firma inválida
          </button>
        </div>
        <div hidden={!requiereEscaner}>
          <button
            onClick={() =>
              this.onClickStatus(StatusAutorizacion.DOCUMENTO_INCORRECTO)
            }
            type="button"
            className="btn btn-info btn-sm"
          >
            Documento incorrecto
          </button>
        </div>
      </div>
    );
  };

  // Devuelve el documento consultado en formato PDF, desde MSFile
  renderFrame = () => {
    if (!this.state.archivo) {
      return <div>Seleccione un documento para visualizarlo</div>;
    }

    if (!this.state.archivo.storage) {
      return (
        <div className={`${s.error}`}>
          El documento seleccionado no se ha generado o escaneado
        </div>
      );
    }

    return (
      <iframe
        src={`${URL_BASE}${this.state.archivo.storage}`}
        style={{ background: "#fff" }}
      />
    );
  };
}
