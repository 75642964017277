import React from "react";
import ReactTable from "react-table";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";

interface Props {
  messagesByMembresia: [];
  toAddNotification: any;
  toUpdateMessage: any;
}

class ListMessages extends React.Component<Props> {
  render() {
    const {
      messagesByMembresia,
      toAddNotification,
      toUpdateMessage
    } = this.props;

    const columns = [
      {
        Header: () => (
          <div className="w-100 text-center">
            <Button
              color="link"
              className={`${s.innerButton} ${s.primaryLink}`}
              onClick={() => toAddNotification()}
            >
              <FontAwesomeIcon icon={faPlusCircle} />
            </Button>
          </div>
        ),
        columns: [
          {
            Header: () => <p className="font-weight-bold">Id</p>,
            id: 0,
            width: 40,
            accessor: "id",
            Cell: row => {
              return <p>{row.original.id}</p>;
            }
          },
          {
            Header: () => <p className="font-weight-bold">Id Persona</p>,
            id: 0,
            width: 80,
            accessor: "personaId",
            Cell: row => {
              return <p>{row.original.personaId}</p>;
            }
          },
          {
            Header: () => <p className="font-weight-bold">Cliente</p>,
            id: 0,
            width: 120,
            accessor: "nombreCliente",
            Cell: row => {
              return <p className={s.parrafo}>{row.original.nombreCliente}</p>;
            }
          },
          {
            Header: () => <p className="font-weight-bold">Título</p>,
            id: 0,
            width: 120,
            accessor: "titulo",
            Cell: row => {
              return <p className={s.parrafo}>{row.original.titulo}</p>;
            }
          },
          {
            Header: () => <p className="font-weight-bold">Descripción</p>,
            id: 0,
            width: 280,
            accessor: "descripcion",
            Cell: row => {
              return <p className={s.parrafo}>{row.original.descripcion}</p>;
            }
          },
          {
            Header: () => <p className="font-weight-bold">Fecha alerta</p>,
            id: 0,
            width: 110,
            accessor: "fechaAlerta",
            Cell: row => {
              return <p>{row.original.fechaAlerta}</p>;
            }
          },
          {
            Header: () => <p className="font-weight-bold">Alerta</p>,
            id: 0,
            width: 110,
            accessor: "alertaString",
            Cell: row => {
              return <p>{row.original.alertaString}</p>;
            }
          },
          {
            Header: () => <p className="font-weight-bold">Fecha creación</p>,
            id: 0,
            width: 110,
            accessor: "fechaCreacionFormat",
            Cell: row => {
              return <p>{row.original.fechaCreacionFormat}</p>;
            }
          },
          {
            Header: () => {
              return <p className="text-center"></p>;
            },
            id: -300,
            width: 80,
            accessor: "actions",
            Cell: row => {
              return (
                <div>
                  <Button
                    size="sm"
                    onClick={() => {
                      toUpdateMessage(row.original);
                    }}
                  >
                    Editar
                  </Button>
                </div>
              );
            }
          }
        ]
      }
    ];

    return (
      <ReactTable
        manual
        data={messagesByMembresia ? messagesByMembresia : []}
        columns={columns}
        className={`${s.tablaDatos} mt-5 -striped -highlight w-100`}
        style={{ maxLength: "400px" }}
        sortable={false}
        showPagination={false}
        defaultPageSize={5}
      />
    );
  }
}

export default withRouter(withStyles(s)(ListMessages));
