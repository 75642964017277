import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import ModalFrame from "../../../../toolkit/modalFrame";
import { Button, Container, Row, Col } from "reactstrap";
import s from "./confirmModalsLink.scss";
import BaseNumericInput from "../../../../toolkit/baseNumericInput";
import BaseInput from "../../../../toolkit/baseInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {
  errorNotification,
  successNotification
} from "../../../../utils/notifications";

interface Props {
  isOpen: boolean;
  title: String;
  message: String;
  requireInput: boolean;
  inputType: string;
  waiting: boolean;
  options: any[];
  ok: (value: any) => void;
  cancel: () => void;
  success: () => void;
  linkConfirm?: string;
  urlUpload?: string;
}

interface State {
  value: any;
  wait: boolean;
}

/**
 * Copia fiel de ConfirmModal, pero con Links en la confirmación
 */
class ConfirmModal extends React.Component<Props, State> {
  state = {
    value: null,
    wait: false
  };

  handleFileChange = async evt => {
    evt.preventDefault();
    if (!evt || !evt.target || !evt.target.files || !evt.target.files.length) {
      errorNotification("Debe seleccionar un archivo");
      return;
    }

    const file = evt.target.files[0];
    const data = new FormData();
    data.append("file", file);

    this.setState({
      wait: true
    });

    try {
      await axios.post(this.props.urlUpload, data, {
        withCredentials: true
      });
      successNotification("Archivo cargado correctamente");
      this.props.success();
    } catch (error) {
      errorNotification("No se pudo cargar el archivo");
      this.props.cancel();
    }

    this.setState({
      wait: false
    });
  };

  render() {
    return (
      <ModalFrame
        title={this.props.title}
        isOpen={this.props.isOpen}
        toggle={() => this.props.cancel()}
        size="md"
      >
        <Container style={{ width: "90%" }} className="center-block p-0 mb-3">
          <p>{this.props.message}</p>

          {this.props.requireInput && this.props.inputType === "numeric" && (
            <div>
              <BaseNumericInput
                type="text"
                id="iiValue"
                name="iiValue"
                label={""}
                placeholder={""}
                value={this.state.value}
                onChange={e => {
                  const value = e.target.value;
                  this.setState({ value });
                }}
              />
            </div>
          )}

          {this.props.requireInput && this.props.inputType === "alphanumeric" && (
            <div>
              <BaseInput
                type="text"
                id="iiValue"
                name="iiValue"
                label={""}
                placeholder={""}
                value={this.state.value}
                onChange={e => {
                  const value = e.target.value;
                  this.setState({ value });
                }}
              />
            </div>
          )}
          {this.props.requireInput && this.props.inputType === "select" && (
            <div>
              <BaseInput
                type="select"
                id="iiValue"
                name="iiValue"
                value={this.state.value}
                onChange={e => {
                  const value = e.target.value;
                  this.setState({ value });
                }}
                options={this.props.options || []}
              />
            </div>
          )}

          <Row className="mt-4">
            <Col xs="12" md="6">
              <div>
                <a
                  className={`${s.btnLink} ${s.primaryBtnLink}`}
                  href={this.props.linkConfirm}
                  onClick={e => {
                    if (this.state.wait) {
                      return false;
                    }

                    this.setState({
                      wait: true
                    });

                    setTimeout(() => {
                      const { value } = this.state;
                      this.props.ok(value);
                      this.setState({
                        wait: false
                      });
                    }, 2000);
                    return true;
                  }}
                >
                  {this.state.wait ? (
                    <FontAwesomeIcon icon={faSpinner} pulse />
                  ) : this.props.urlUpload ? (
                    "Escanear"
                  ) : (
                    "Aceptar"
                  )}
                </a>
              </div>
            </Col>
            {this.props.urlUpload && (
              <Col xs="12" md="6">
                <a
                  className={`${s.btnLink} ${s.secundaryBtnLink}`}
                  onClick={e => {
                    if (this.state.wait) {
                      e.preventDefault();
                      e.stopPropagation();

                      return false;
                    }
                  }}
                >
                  {this.state.wait ? (
                    <FontAwesomeIcon icon={faSpinner} pulse />
                  ) : (
                    "Seleccionar archivo"
                  )}
                  <input
                    className={s.inputFile}
                    type="file"
                    id={`scannedFile`}
                    accept=".pdf"
                    title=""
                    onChange={this.handleFileChange}
                  />
                </a>
              </Col>
            )}
            <Col xs="12" md={this.props.urlUpload ? "12" : "6"}>
              <a
                className={`${s.btnLink} ${s.cancelBtnLink}`}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();

                  if (this.state.wait) {
                    return false;
                  }

                  this.setState({ value: null });
                  this.props.cancel();
                }}
              >
                {this.state.wait ? (
                  <FontAwesomeIcon icon={faSpinner} pulse />
                ) : (
                  "Cancelar"
                )}
              </a>
            </Col>
          </Row>
        </Container>
      </ModalFrame>
    );
  }
}

export default withStyles(s)(ConfirmModal);
