import React from "react";
import { Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import ReactTable from "react-table";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import { QueryResponse } from "../../../types/QueryResponse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faPencilAlt,
  faTimes,
  faUser,
  faSortNumericDown,
  faCalculator,
  faPrint,
  faArrowRight,
  faIdCard,
  faEye,
  faFileCsv,
  faCheckCircle,
  faDotCircle,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import s from "./PagedTable.scss";
import CONSTANTS from "../../../utils/constants";

interface Props {
  data: QueryResponse<any>;
  columns: any;
  actions: any;
  fetchData: () => void;
  getTrProps: () => any;
  searching: boolean;
  height: string;
  refresh: boolean;
  defaultPageSize?: number;
}

interface State {
  columns: any;
}
class PagedTable extends React.Component<Props, State> {
  state = {
    columns: []
  };

  renderHeaderActions = headerActions => {
    const actions = headerActions.map((action, index) => {
      switch (action.name) {
        case "update":
          return (
            <div key={`update${index}`} className="w-100 text-center">
              <Button
                color="link"
                onClick={() => action.f()}
                className={`${s.actionHeaderIcon}`}
              >
                <FontAwesomeIcon className={`${s.fawico}`} icon={faPlus} />
              </Button>
            </div>
          );
        case "add":
          return (
            <div key={`add${index}`} className="w-100 text-center">
              <Button
                color="link"
                onClick={() => action.f()}
                className={`${s.actionHeaderIcon}`}
              >
                <FontAwesomeIcon className={`${s.fawico}`} icon={faPlus} />
              </Button>
            </div>
          );
        case "membership":
          return " ";
        default:
          return null;
      }
    });
    return actions;
  };

  renderActionsItem = item => {
    const actions = this.props.actions.map((action, index) => {
      const idRandom = Math.floor(Math.random() * 10000 + 1);
      if (action.visible && typeof action.visible === "function") {
        if (!action.visible(item)) {
          return null;
        }
      }
      switch (action.name) {
        case "edit":
          return (
            <Col key={`editAction${index}`} className={s.marginLeftNil} xs={2}>
              <a
                id={`editAction${idRandom}`}
                onClick={() => action.f(item)}
                className={`${s.editAnchor}`}
              >
                <FontAwesomeIcon
                  className={`${s.fawico}`}
                  icon={action.icon || faPencilAlt}
                />
              </a>
              <UncontrolledTooltip
                placement="left"
                target={`editAction${idRandom}`}
              >
                {action.label || "Editar"}
              </UncontrolledTooltip>
            </Col>
          );
        case "delete":
          return (
            <Col
              key={`deleteAction${index}`}
              className={s.marginLeftNil}
              xs={2}
            >
              <a
                id={`deleteAction${idRandom}`}
                onClick={() => action.f(item)}
                className={`${s.deleteAnchor}`}
              >
                <FontAwesomeIcon className={`${s.fawico}`} icon={faTimes} />
              </a>
              <UncontrolledTooltip
                placement="left"
                target={`deleteAction${idRandom}`}
              >
                {action.label || "Eliminar"}
              </UncontrolledTooltip>
            </Col>
          );
        case "addTypeMember":
          return (
            <Col
              key={`addTypeMember${index}`}
              className={s.marginLeftNil}
              xs={2}
            >
              <a
                id={`addTypeMember${idRandom}`}
                onClick={() =>
                  item.estatus === "ACTIVO" ? action.f(item) : ""
                }
                className={`${s.greenAnchor}`}
              >
                <FontAwesomeIcon className={`${s.fawico}`} icon={faUser} />
              </a>
              <UncontrolledTooltip
                placement="left"
                target={`addTypeMember${idRandom}`}
              >
                {item.estatus === "ACTIVO"
                  ? "Miembros"
                  : "Sin acceso membresía inactiva"}
              </UncontrolledTooltip>
            </Col>
          );
        case "addTypeMemberRules":
          return (
            <Col
              key={`addTypeMemberRules${index}`}
              className={s.marginLeftNil}
              xs={2}
            >
              <a
                id={`addTypeMemberRules${idRandom}`}
                onClick={() =>
                  item.estatus === "ACTIVO" ? action.f(item) : ""
                }
                className={`${s.blackAnchor}`}
              >
                <FontAwesomeIcon
                  className={`${s.fawico}`}
                  icon={faSortNumericDown}
                />
              </a>
              <UncontrolledTooltip
                placement="left"
                target={`addTypeMemberRules${idRandom}`}
              >
                {item.estatus === "ACTIVO"
                  ? "Agregar reglas"
                  : "Sin acceso membresía inactiva"}
              </UncontrolledTooltip>
            </Col>
          );
        case "calculate":
          return (
            <Col
              key={`faCalculator${index}`}
              className={s.marginLeftNil}
              xs={2}
            >
              <a
                id={`faCalculator${idRandom}`}
                onClick={() => action.f(item)}
                className={`${s.blueAnchor}`}
              >
                <FontAwesomeIcon
                  className={`${s.fawico}`}
                  icon={faCalculator}
                />
              </a>
              <UncontrolledTooltip
                placement="left"
                target={`faCalculator${idRandom}`}
              >
                {action.label || "Calcular"}
              </UncontrolledTooltip>
            </Col>
          );
        case "print":
          return (
            <Col key={`editAction${index}`} className={s.marginLeftNil} xs={2}>
              <a
                id={`editAction${idRandom}`}
                onClick={() => action.f(item)}
                className={`${s.editAnchor}`}
              >
                <FontAwesomeIcon className={`${s.fawico}`} icon={faPrint} />
              </a>
              <UncontrolledTooltip
                placement="left"
                target={`editAction${idRandom}`}
              >
                {action.label || "Imprimir"}
              </UncontrolledTooltip>
            </Col>
          );
        case "assign":
          return (
            <Col key={`editAction${index}`} className={s.marginLeftNil} xs={2}>
              <a
                id={`editAction${idRandom}`}
                onClick={() => action.f(item)}
                className={`${s.editAnchor}`}
              >
                <FontAwesomeIcon
                  className={`${s.fawico}`}
                  icon={faArrowRight}
                />
              </a>
              <UncontrolledTooltip
                placement="left"
                target={`editAction${idRandom}`}
              >
                {action.label || "Asignar"}
              </UncontrolledTooltip>
            </Col>
          );
        case "post":
          return (
            <Col key={`postAction${index}`} className={s.marginLeftNil} xs={2}>
              <a
                id={`postAction${idRandom}`}
                onClick={() => action.f(item)}
                className={`${s.greenAnchor}`}
              >
                <FontAwesomeIcon className={`${s.fawico}`} icon={faIdCard} />
              </a>
              <UncontrolledTooltip
                placement="left"
                target={`postAction${idRandom}`}
              >
                Puestos
              </UncontrolledTooltip>
            </Col>
          );
        case "view":
          return (
            <Col key={`postAction${index}`} className={s.marginLeftNil} xs={2}>
              <a
                id={`postAction${idRandom}`}
                onClick={() => action.f(item)}
                className={`${s.greenAnchor}`}
              >
                <FontAwesomeIcon className={`${s.fawico}`} icon={faEye} />
              </a>
              <UncontrolledTooltip
                placement="left"
                target={`postAction${idRandom}`}
              >
                {action.label || "Ver"}
              </UncontrolledTooltip>
            </Col>
          );
        case "report":
          return (
            <Col key={`postAction${index}`} className={s.marginLeftNil} xs={2}>
              <a
                id={`postAction${idRandom}`}
                onClick={() => action.f(item)}
                className={`${s.greenAnchor}`}
              >
                <FontAwesomeIcon className={`${s.fawico}`} icon={faFileCsv} />
              </a>
              <UncontrolledTooltip
                placement="left"
                target={`postAction${idRandom}`}
              >
                {action.label || "Reporte"}
              </UncontrolledTooltip>
            </Col>
          );
        case "status":
          return (
            <Col
              key={`deleteAction${index}`}
              className={s.marginLeftNil}
              xs={2}
            >
              <a
                id={`deleteAction${idRandom}`}
                onClick={() => action.f(item)}
                className={
                  item.estatus.toUpperCase() === "ACTIVO"
                    ? `${s.deleteAnchor}`
                    : `${s.greenAnchor}`
                }
              >
                <FontAwesomeIcon
                  className={`${s.fawico}`}
                  icon={
                    item.estatus.toUpperCase() === "ACTIVO"
                      ? faDotCircle
                      : faCheckCircle
                  }
                />
              </a>
              <UncontrolledTooltip
                placement="left"
                target={`deleteAction${idRandom}`}
              >
                {item.estatus.toUpperCase() === "ACTIVO"
                  ? "Desactivar"
                  : "Activar"}
              </UncontrolledTooltip>
            </Col>
          );
        case "authorize":
          return (
            <Col
              key={`authorizeAction${index}`}
              className={s.marginLeftNil}
              xs={2}
            >
              <a
                id={`authorizeAction${item.id}`}
                onClick={() => action.f(item)}
                className={
                  !!item.autorizada ? `${s.deleteAnchor}` : `${s.greenAnchor}`
                }
              >
                <FontAwesomeIcon
                  className={`${s.fawico}`}
                  icon={!!item.autorizada ? faTimesCircle : faCheckCircle}
                />
              </a>
              <UncontrolledTooltip
                placement="left"
                target={`authorizeAction${item.id}`}
              >
                {!!item.autorizada ? "Desautorizar" : "Autorizar"}
              </UncontrolledTooltip>
            </Col>
          );
        case "membership":
          /*return (
            <div key={`membership${index}`} className="w-100 text-center">
              <Button
                color="link"
                onClick={() => action.f()}
                className={`${s.actionHeaderIcon}`}
              >
                <FontAwesomeIcon className={`${s.fawico}`} icon={faUser} />
              </Button>
            </div>
            
          );*/
          return (
            <Col key={`membership${index}`} className={s.marginLeftNil} xs={2}>
              <a
                id={`membership${idRandom}`}
                onClick={() => action.f(item)}
                hidden={!item.idMembresiaSocio}
                className={`${s.anchor}`}
              >
                <FontAwesomeIcon className={`${s.fawico}`} icon={faUser} />
              </a>
              <UncontrolledTooltip
                placement="left"
                target={`membership${idRandom}`}
              >
                {action.label}
              </UncontrolledTooltip>
            </Col>
          );
        default:
          return null;
      }
    });
    return actions;
  };

  //TODO: Refactorizar
  componentDidMount() {
    const columns = [];
    this.props.columns.forEach(column => {
      if (column.id && column.name && column.minWidth) {
        columns.push({
          Header: column.name,
          accessor: column.id,
          minWidth: column.minWidth,
          className: column.className || ""
        });
      } else if (column.id == "activo") {
        columns.push({
          Header: column.name,
          id: column.id,
          accessor: d => (d.activo ? "Si" : "No")
        });
      } else if (column.id == "autorizada") {
        columns.push({
          Header: column.name,
          id: column.id,
          className: column.className || "",
          accessor: d => (!!d.autorizada ? "AUTORIZADA" : "PENDIENTE")
        });
      } else if (column.id && column.name) {
        columns.push({
          Header: column.name,
          accessor: column.id,
          className: column.className || ""
        });
      } else if (column.headerActions) {
        columns.push({
          Header: () => this.renderHeaderActions(column.headerActions),
          id: "buttons",
          accessor: item => <Row> {this.renderActionsItem(item)} </Row>
        });
      }
    });
    this.setState({
      columns
    });
  }

  render() {
    return (
      <Row>
        <Col>
          <ReactTable
            manual
            data={this.props.data.content}
            pages={this.props.data.totalPages}
            columns={this.state.columns}
            className="w-100"
            onFetchData={this.props.fetchData}
            defaultPageSize={
              this.props.defaultPageSize
                ? this.props.defaultPageSize
                : CONSTANTS.DEFAULT_PAGE_SIZE
            }
            loading={this.props.searching}
            sortable={false}
            style={{
              height: this.props.height
                ? this.props.height
                : CONSTANTS.TABLES_HEIGHT
            }}
            getTrProps={this.props.getTrProps}
          />
        </Col>
      </Row>
    );
  }
}

export default withRouter(withStyles(s)(PagedTable));
