import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import PersonalDocumentsHistory from "../../components/personalDocumentsHistory";
import * as actions from "../../actions/personalDocumentsHistory";
import * as catalogActions from "../../actions/catalogs";
import * as partnerActions from "../../actions/partners";
import { QueryResponse } from "../../../types/QueryResponse";
import { QueryArguments } from "../../../types-business/QueryArguments";
import CONSTANTS from "../../../utils/constants";
import { Club } from "../../../types-business/Clubs";
import { getClubsRequest } from "../../actions/catalogs";
import { handleRequestError } from "../../../utils/helpers";

interface PersonalDocumentHistory {
  documento: number;
  membresiaSocio: number;
  persona: number;
  empresa: number;
  fechaCompletado: string;
  documentStorageId: number;
  status: number;
  firma: string;
  contentType: string;
  flujoCompleto: Boolean;
}

interface Props {
  personalDocumentsHistory: QueryResponse<PersonalDocumentHistory>;
  getPersonalDocumentsHistory: (idClub: any, idPublico: any) => any;
  getPartner: (params: object) => any;
  searching: boolean;
  history: any;
}

interface State {
  personalDocumentsHistory: object;
  filters: {};
  clubs: Club[];
}

class PersonalDocumentsHistoryPage extends React.Component<Props, State> {
  state = {
    personalDocumentsHistory: {
      content: [],
      totalPages: 0
    },
    filters: {
      idClub: "",
      idPublico: "",
      size: CONSTANTS.DEFAULT_PAGE_SIZE,
      page: 0
    },
    clubs: null
  };

  componentDidMount() {
    this.getClubsMigradoActivo();
  }

  getClubsMigradoActivo = () => {
    getClubsRequest()
      .then(({ data }) => {
        this.setState({ clubs: (data || []).filter(x => !!x.migradoCrm2) });
      })
      .catch(handleRequestError);
  };

  onChangeFilter = (value, name) => {
    const filters = {
      ...this.state.filters,
      [`${name}`]: value ? value : null
    };
    this.setState(state => ({ ...state, filters }));
  };

  goTo = (destiny: string, select: any) => {
    if (select) {
      this.props.history.push(destiny + select.id);
    } else {
      this.props.history.push(destiny);
    }
  };

  componentDidUpdate(prevProps) {
    const { personalDocumentsHistory } = this.props;
    const {
      personalDocumentsHistory: prevPersonalDocumentsHistory
    } = prevProps;
    if (
      personalDocumentsHistory &&
      prevPersonalDocumentsHistory !== personalDocumentsHistory
    ) {
      this.setState({ personalDocumentsHistory });
    }
  }

  render() {
    return (
      <main>
        <Helmet title="PersonalDocumentsHistory" />
        <Layout>
          <PersonalDocumentsHistory
            personalDocumentsHistory={this.state.personalDocumentsHistory}
            searching={this.props.searching}
            filters={this.state.filters}
            onChangeFilter={this.onChangeFilter}
            getPersonalDocumentsHistory={this.props.getPersonalDocumentsHistory}
            getPartner={this.props.getPartner}
            clubs={this.state.clubs || []}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getPersonalDocumentsHistory: (idClub: any, idPublico: any) => {
    dispatch(actions.getPersonalDocumentsHistory(idClub, idPublico));
  },
  getPartner: (params: object) => {
    return dispatch(partnerActions.getPartner(params));
  }
});

const mapStateToProps = state => {
  const { personalDocumentsHistory } = state;
  return {
    personalDocumentsHistory: personalDocumentsHistory.personalDocumentsHistory
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalDocumentsHistoryPage);
