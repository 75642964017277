import React from "react";
import { Container, TabPane, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { Member } from "../interfaces";

// TODO: REDUX
// TODO: Security Spec - Consultar desde el servidor
import axios from "axios";
import { errorNotification } from "../../../../utils/notifications";
import ReactTable from "react-table";

const URL = "https://app.sportsworld.com.mx/apiSocios/api/v1/misComprasCrm1/";
const COLUMNS = [
  {
    Header: "Club",
    headerClassName: "text-left",
    accessor: "club",
    width: "150"
  },
  {
    Header: "Movimiento",
    headerClassName: "text-left",
    accessor: "idMovimiento",
    width: "110"
  },
  {
    Header: "Estatus",
    headerClassName: "text-left",
    accessor: "estatus",
    width: "110"
  },
  {
    Header: "Fecha",
    headerClassName: "text-left",
    accessor: "fecha",
    width: "110"
  },
  {
    Header: "Descripción",
    headerClassName: "text-left",
    accessor: "descripcion",
    width: "470"
  },
  {
    Header: "Importe",
    headerClassName: "text-left",
    accessor: "importe",
    width: "110"
  },
  {
    Header: "Factura",
    headerClassName: "text-left",
    accessor: "factura",
    width: "110"
  }
];

interface Props {
  persons: Member[];
  activeTab: string;
}

interface State {
  personsWithMovs: {
    id: number;
    name: string;
    movements: {
      idMovimiento: number;
      fecha: string;
      descripcion: string;
      importe: string;
      club: string;
      factura: string;
      estatus: string;
    }[];
  }[];
  loading: boolean;
}

/**
 * Tab en pantalla de membresía donde se muestra el histórico de movimientos en CRM1
 */
class TabHistoryCrm1 extends React.Component<Props, State> {
  state = {
    personsWithMovs: [],
    loading: false
  };

  async componentDidUpdate(prevProps: Readonly<Props>) {
    let loading = this.state.loading;

    if (this.props.persons !== prevProps.persons) {
      this.setState({
        loading: false
      });
      loading = false;
    }

    if (this.props.persons && this.props.activeTab == "16" && !loading) {
      this.setState({
        loading: true
      });

      let personsWithMovs = [];

      for (let person of this.props.persons) {
        try {
          const response = await axios.get(
            `${URL}${person.idPersonaCrm1 || person.idPersona}`,
            {
              headers: {
                "secret-key": "5F09560D2A9A7D1AD9388EFD3018566F795A3C7A"
              }
            }
          );
          personsWithMovs.push({
            id: person.idPersona,
            name: person.nombrePersona,
            movements: response.data.data
          });
        } catch (error) {
          errorNotification(
            "No se pudieron cargar los movimientos de " + person.nombrePersona
          );
        }
      }

      this.setState({
        personsWithMovs
      });
    }
  }

  renderMovements() {
    return this.state.personsWithMovs.map(p => (
      <>
        <Row>
          <Col className="mt-3">
            <p>{p.name}</p>
          </Col>
        </Row>
        <ReactTable
          data={p.movements}
          columns={COLUMNS}
          sortable={false}
          pageSize={p.movements.length > 5 ? p.movements.length : 5}
          showPagination={false}
        />
      </>
    ));
  }

  render() {
    const waitReturn = (
      <Row>
        <Col>
          <p className="mt-3" style={{ fontSize: "1em" }}>
            Espere un momento por favor &nbsp;
            <FontAwesomeIcon
              icon={faSpinner}
              style={{ color: "#444" }}
              pulse={true}
            />
          </p>
        </Col>
      </Row>
    );

    return (
      <TabPane className="my-2" tabId="16">
        <Container className="p-0">
          {this.state.personsWithMovs.length > 0
            ? this.renderMovements()
            : waitReturn}
        </Container>
      </TabPane>
    );
  }
}

export default TabHistoryCrm1;
