import IAction from "../../../types/IAction";

export interface Props {
  downloadReportPartnerMembershipDirectory: (params: any) => IAction;
  userContext: { clubSessionId: any };
}

export interface State {
  filters: {
    clubId: number;
    personaId: number;
    estatusMembresia: string;
    tipoMembresia: string;
    formaPago: string;
    tieneConvenio: number;
    personaNombre: string;
  };
  onSearch: boolean;
  clubs: {
    clubId: number;
    nombre: string;
  }[];
  data: any[];
}

export const partnerMembershipStatus = [
  { value: "ACTIVA", label: "ACTIVA" },
  { value: "INACTIVA", label: "INACTIVA" },
  { value: "PASIVA", label: "PASIVA" }
];

export const partnerMembershipTypes = [
  { value: "INDIVIDUAL", label: "INDIVIDUAL" },
  { value: "PAREJA", label: "PAREJA" },
  { value: "FAMILIAR", label: "FAMILIAR" },
  { value: "GRUPAL", label: "GRUPAL" }
];

export const partnerMembershipPaymentTypes = [
  { value: "CAT", label: "CAT" },
  { value: "MENSUAL", label: "MENSUAL" }
];

export const partnerMembershipAggrementTypes = [
  { value: 1, label: "SI" },
  { value: 0, label: "NO" }
];
