import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import {
  infoNotification,
  successNotification,
  errorNotification
} from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";
interface State {
  report: object[];
  loadingReport: boolean;
  successfullSells: boolean;
}
const initialState: State = {
  report: null,
  loadingReport: false,
  successfullSells: false
};

export default function sellsLte(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.SEND_LTE_SELLS}_PENDING`: {
      infoNotification("Enviando lote de ventas");
      return {
        ...state,
        loadingReport: false,
        report: null,
        successfullSells: false
      };
    }
    case `${actionTypes.SEND_LTE_SELLS}_REJECTED`: {
      errorNotification(
        readMessageBack(action, "No se pudo enviar el lote de ventas")
      );
      return {
        ...state,
        loadingReport: true,
        report: null,
        successfullSells: false
      };
    }
    case `${actionTypes.SEND_LTE_SELLS}_FULFILLED`: {
      successNotification("Ventas generadas");
      return {
        ...state,
        loadingReport: false,
        report: action.payload.data,
        successfullSells: true
      };
    }

    case `${actionTypes.SEND_LTE_SELLS_CLEAR}`: {
      return {
        ...state,
        loadingReport: false,
        report: null,
        successfullSells: false
      };
    }

    default: {
      return state;
    }
  }
}

export { initialState };
