/// imports
import React from "react";
import { Helmet } from "react-helmet";
import {
  ReduxConnect,
  Router,
  Routing,
  RouterProps
} from "../../../toolkit/decorators/Artifact";
import Carousel2 from "../../../toolkit/carousel2/Carousel2";
import Layout from "../../components/layout";
import ConvenioBasicData from "../../components/convenioCarousel/basicData";
import ConvenioClubes from "../../components/convenioCarousel/clubes";
import ConvenioMantenimientos from "../../components/convenioCarousel/mantenimientos";
import * as actions from "../../actions/convenios";
import { Preview } from "../../../types/IAction";
import { Club } from "../../../types-business/Clubs";
import { getClubsRequest } from "../../actions/catalogs";
import { handleRequestError } from "../../../utils/helpers";
////

const mapDispatchToProps = dispatch => ({
  /// Dispatchers de Convenios
  clearConvenioCarousel: (): Promise<Preview<any>> => {
    return dispatch(actions.clearConvenioCarousel());
  },
  getConvenioDatosBasicos: (idConvenio: number): Promise<Preview<any>> => {
    return dispatch(actions.getConvenioDatosBasicos(idConvenio));
  },
  postConvenioDatosBasicos: (datosBasicos: any): Promise<Preview<any>> => {
    return dispatch(actions.postConvenioDatosBasicos(datosBasicos));
  },
  putConvenioDatosBasicos: (
    idConvenio: number,
    datosBasicos: any
  ): Promise<Preview<any>> => {
    return dispatch(actions.putConvenioDatosBasicos(idConvenio, datosBasicos));
  },
  getEmpresasAsignables: (idConvenio: number): Promise<Preview<any>> => {
    return dispatch(actions.getEmpresasAsignables(idConvenio));
  },
  getEmpresasActivas: (
    idMembresia: number,
    idMantenimiento: number
  ): Promise<Preview<any>> => {
    return dispatch(actions.getEmpresasActivas(idMembresia, idMantenimiento));
  },
  getClubesAsociados: (idConvenio: number): Promise<Preview<any>> => {
    return dispatch(actions.getClubesAsociados(idConvenio));
  },
  putClubesAsociados: (
    idConvenio: number,
    clubes: number[]
  ): Promise<Preview<any>> => {
    return dispatch(actions.putClubesAsociados(idConvenio, clubes));
  },
  getMantenimientosAplicables: (): Promise<Preview<any>> => {
    return dispatch(actions.getMantenimientosAplicables());
  },
  getMantenimientosAsociados: (idConvenio: number): Promise<Preview<any>> => {
    return dispatch(actions.getMantenimientosAsociados(idConvenio));
  },
  putMantenimientosAsociados: (
    idConvenio: number,
    mantenimientos: number[]
  ): Promise<Preview<any>> => {
    return dispatch(
      actions.putMantenimientosAsociados(idConvenio, mantenimientos)
    );
  }
  ////
});

const mapStateToProps = state => {
  const { convenios } = state;

  return {
    /// State de Convenios
    convenioDatosBasicos: convenios.convenioDatosBasicos,
    convenioDatosBasicosLoading: convenios.convenioDatosBasicosLoading,

    empresasAsignables: convenios.empresasAsignables,
    empresasAsignablesLoading: convenios.empresasAsignablesLoading,

    clubesAsociados: convenios.clubesAsociados,
    clubesAsociadosLoading: convenios.clubesAsociadosLoading,

    mantenimientosAsignables: convenios.mantenimientosAsignables,
    mantenimientosAsignablesLoading: convenios.mantenimientosAsignablesLoading,

    mantenimientosAsociados: convenios.mantenimientosAsociados,
    mantenimientosAsociadosLoading: convenios.mantenimientosAsociadosLoading,

    membresiasAsignables: convenios.membresiasAsignables,
    membresiasAsignablesLoading: convenios.membresiasAsignablesLoading,

    membresiasAsociadas: convenios.membresiasAsociadas,
    membresiasAsociadasLoading: convenios.membresiasAsociadasLoading,

    descuentos: convenios.descuentos,
    descuentosLoading: convenios.descuentosLoading
    ////
  };
};

interface Props extends RouterProps<any> {
  /// Dispatchers y State de convenios (Redux)

  // Limpieza de carrusel de convenios
  clearConvenioCarousel: () => Promise<Preview<any>>;

  // Datos básicos
  convenioDatosBasicos: any;
  convenioDatosBasicosLoading: boolean;
  getConvenioDatosBasicos: (idConvenio: number) => Promise<Preview<any>>;
  postConvenioDatosBasicos: (datosBasicos: any) => Promise<Preview<any>>;
  putConvenioDatosBasicos: (
    idConvenio: number,
    datosBasicos: any
  ) => Promise<Preview<any>>;

  // Empresas asociadas al convenio
  empresasAsignables: any[];
  empresasAsignablesLoading: boolean;
  getEmpresasAsignables: (idConvenio: number) => Promise<Preview<any>>;

  // Clubes asociados al convenio
  clubesAsociados: number[];
  clubesAsociadosLoading: boolean;
  getClubesAsociados: (idConvenio: number) => Promise<Preview<any>>;
  putClubesAsociados: (
    idConvenio: number,
    clubes: number[]
  ) => Promise<Preview<any>>;

  // Membresías asignables al convenio
  membresiasAsignables: any[];
  membresiasAsignablesLoading: boolean;
  membresiasAsociadas: any[];
  membresiasAsociadasLoading: boolean;
  getMembresiasAsignables: (idConvenio: number) => Promise<Preview<any>>;
  getMembresiasAsociadas: (idConvenio: number) => Promise<Preview<any>>;
  putMembresiasAsociadas: (
    idConvenio: number,
    membresias: number[]
  ) => Promise<Preview<any>>;

  // Mantenimientos asociados al convenio
  mantenimientosAsignables: any[];
  mantenimientosAsignablesLoading: boolean;
  mantenimientosAsociados: number[];
  mantenimientosAsociadosLoading: boolean;
  getMantenimientosAplicables: () => Promise<Preview<any>>;
  getMantenimientosAsociados: (idConvenio: number) => Promise<Preview<any>>;
  putMantenimientosAsociados: (
    idConvenio: number,
    mantenimientos: number[]
  ) => Promise<Preview<any>>;

  // Descuentos aplicados al convenio
  descuentos: any;
  descuentosLoading: boolean;
  getDescuentos: (
    idConvenio: number,
    idMembresia: number
  ) => Promise<Preview<any>>;
  putDescuentos: (
    idConvenio: number,
    idMembresia: number,
    descuentos: any
  ) => Promise<Preview<any>>;
  ////
}

interface State {
  id: number;
  activeSteps: boolean;
  dirty: boolean;
  dirtyMsg: string;

  clubesAsignables: Club[];
  loadingClubesAsignables: boolean;
}

@Router()
@ReduxConnect(mapStateToProps, mapDispatchToProps)
@Routing("/cconvenios/configuracion", "/cconvenios/:id/configuracion")
export default class ConvenioCarousel extends React.Component<Props, State> {
  state = {
    id: 0,
    activeSteps: false,
    dirty: false,
    dirtyMsg: "",
    clubesAsignables: null,
    loadingClubesAsignables: false
  };

  componentDidMount(): void {
    this.setState({
      id: Number(this.props.match.params.id) || 0
    });
  }

  getClubesAsignables = () => {
    this.setState({ loadingClubesAsignables: true });
    getClubsRequest()
      .then(({ data }) => {
        this.setState({
          clubesAsignables: (data || []).filter(x => !!x.migradoCrm2),
          loadingClubesAsignables: false
        });
      })
      .catch(handleRequestError);
  };

  onChangeStep = (step: number) => {};

  // Indica, por parte del paso en el carrusel, si debe existir confirmación
  handleDirty = (dirty: boolean, dirtyMsg: string) => {
    this.setState({ dirty, dirtyMsg });
  };

  render() {
    return (
      <main>
        <Layout>
          <Helmet title="Convenios" />
          <Carousel2 active={this.props.convenioDatosBasicos.id > 0}>
            <ConvenioBasicData
              id={this.state.id}
              clearConvenioCarousel={this.props.clearConvenioCarousel}
              convenioDatosBasicos={this.props.convenioDatosBasicos}
              convenioDatosBasicosLoading={
                this.props.convenioDatosBasicosLoading
              }
              getConvenioDatosBasicos={this.props.getConvenioDatosBasicos}
              postConvenioDatosBasicos={this.props.postConvenioDatosBasicos}
              putConvenioDatosBasicos={this.props.putConvenioDatosBasicos}
              empresasAsignables={this.props.empresasAsignables}
              empresasAsignablesLoading={this.props.empresasAsignablesLoading}
              getEmpresasAsignables={this.props.getEmpresasAsignables}
              handleDirty={this.handleDirty}
              data-carousel-name="Datos básicos"
            />
            <ConvenioClubes
              id={this.state.id}
              clubesAsignables={this.state.clubesAsignables || []}
              clubesAsignablesLoading={this.state.loadingClubesAsignables}
              clubesAsociados={this.props.clubesAsociados}
              clubesAsociadosLoading={this.props.clubesAsociadosLoading}
              getClubesAsignables={this.getClubesAsignables}
              getClubesAsociados={this.props.getClubesAsociados}
              putClubesAsociados={this.props.putClubesAsociados}
              handleDirty={this.handleDirty}
              data-carousel-name="Clubes"
            />
            <ConvenioMantenimientos
              id={this.state.id}
              mantenimientosAsignables={this.props.mantenimientosAsignables}
              mantenimientosAsignablesLoading={
                this.props.mantenimientosAsignablesLoading
              }
              mantenimientosAsociados={this.props.mantenimientosAsociados}
              mantenimientosAsociadosLoading={
                this.props.mantenimientosAsociadosLoading
              }
              getMantenimientosAplicables={
                this.props.getMantenimientosAplicables
              }
              getMantenimientosAsociados={this.props.getMantenimientosAsociados}
              putMantenimientosAsociados={this.props.putMantenimientosAsociados}
              handleDirty={this.handleDirty}
              data-carousel-name="Mantenimientos"
            />
          </Carousel2>
        </Layout>
      </main>
    );
  }
}
