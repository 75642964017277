import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import DescuentoDirectoPage from "../../components/descuentoDirecto";

interface Props {}
interface State {}

class DescuentoDirecto extends React.Component<Props, State> {
  render() {
    return (
      <main>
        <Helmet title="Integrantes por Membresía" />
        <Layout>
          <DescuentoDirectoPage />
        </Layout>
      </main>
    );
  }
}

export default DescuentoDirecto;
