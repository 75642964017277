import React from "react";

if (process.env.BROWSER) {
  var ReactDataGrid = require("react-data-grid");
  var Cell = ReactDataGrid.Cell;
}

interface Props {
  rowData: any;
  column: any;
  value: any;
}

class CellRenderer extends React.Component<Props> {
  render() {
    return <Cell {...this.props} />;
  }
}

export default CellRenderer;
