export interface FILTERS {
  clubId: number;
  periodoId: number;
  empleadoId?: number;
  estatus?: number;
  fechaRegistroDesde: string;
  fechaRegistroHasta: string;
  tipo: number;
  fechaAplicacionDesde: string;
  fechaAplicacionHasta: string;
  tipoProducto: string;
}
export interface FILTERS_CATALOGS {
  clubs: object;
  periodos: object;
  estatusComision?: object;
}

export const ESTATUS_COMISION = [
  {
    value: "PENDIENTE",
    label: "Pendiente"
  },
  {
    value: "AUTORIZADA",
    label: "Autorizada"
  },
  {
    value: "RECHAZADA",
    label: "Rechazada"
  },
  {
    value: "PAGADA",
    label: "Pagada"
  }
];

export const TIPO_PRODUCTO_COMISIONES = [
  {
    value: "PRODUCTO_DEPORTIVA",
    label: "Producto de deportiva"
  },
  {
    value: "PROGRAMA_DEPORTIVO",
    label: "Programa deportivo"
  }
];

export const columnsCommisionsTableReport = [
  {
    Header: "#",
    accessor: "numero",
    width: 50
  },
  {
    Header: "Vendedor",
    accessor: "nombreVendedor",
    minWidth: 160
  },
  {
    Header: "Tipo Comisión",
    accessor: "tipo"
  },
  {
    Header: "Descripcion producto",
    accessor: "productoDescripcion"
  },
  {
    Header: "Importe venta",
    accessor: "importeVenta"
  },
  {
    Header: "Comisión ($)",
    accessor: "totalComisionMontoProducto"
  },
  {
    Header: "Comisión parcial ($)",
    accessor: "totalComisionMonto"
  },
  {
    Header: "Comisión (%)",
    accessor: "porcentajeComision"
  },
  {
    Header: "Comisión parcial (%)",
    accessor: "totalComisionPorcentaje"
  },
  {
    Header: "Estatus",
    accessor: "estatus"
  },
  {
    Header: "Fecha de registro",
    accessor: "fechaRegistro"
  },
  {
    Header: "Fecha de aplicación",
    accessor: "fechaAplicacion"
  }
];
