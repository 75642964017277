import moment from "moment";
import React from "react";
import { MonthComponent } from "./MonthComponent";
import { DateItem } from "./types";

interface Props {
  id: String;
  year: number;
  submitter: {
    [key: string]: () => {
      fecha: string;
      tipoAfiliacion: "ORIGINAL" | "ALTERNA";
    }[];
  };
  selected: {
    fecha: string;
    tipoAfiliacion: "ORIGINAL" | "ALTERNA";
  }[];
  onChangeYear: (year: number) => void;
  soportaAlterna: boolean;
}

interface State {}

/**
 * Muestra un año completo
 */
export class YearComponent extends React.Component<Props, State> {
  dates: DateItem[] = [];

  constructor(props: Props | Readonly<Props>) {
    super(props);

    const firstDate = moment(new Date(this.props.year, 0, 1));
    const now = moment();
    this.props.submitter.calendar = () => {
      return this.dates
        .filter(d => d.selected != null)
        .map(d => ({
          fecha: d.moment.format("YYYY-MM-DD"),
          tipoAfiliacion: d.selected
        }));
    };

    // Se recorren 366 días por los años bisiestos
    for (let i = 0; i <= 366; i++) {
      const moment = firstDate.clone().add({ days: i });

      // Se comprueba que, al agregar un día, pertenezca al año establecido
      if (moment.get("year") === this.props.year) {
        const selected = this.props.selected.find(s => moment.isSame(s.fecha));
        this.dates.push({
          moment,
          disabled: !moment.isAfter(now),
          selected: selected ? selected.tipoAfiliacion : null
        });
      }
    }
  }

  /**
   * Listener de cambio al año anterior
   */
  prevYear = () => {
    if (this.props.onChangeYear) {
      this.props.onChangeYear(this.props.year - 1);
    }
  };

  /**
   * Listener de cambio al año siguiente
   */
  nextYear = () => {
    if (this.props.onChangeYear) {
      this.props.onChangeYear(this.props.year + 1);
    }
  };

  render() {
    return (
      <div className="sw-calendar year container">
        <div className="sw-calendar year header">
          <span
            className="sw-calendar year change prev"
            onClick={this.prevYear}
          >
            «
          </span>
          {this.props.year}
          <span
            className="sw-calendar year change next"
            onClick={this.nextYear}
          >
            »
          </span>
        </div>
        <MonthComponent
          id={this.props.id}
          month={0}
          dates={this.dates.filter(d => d.moment.get("month") === 0)}
          soportaAlterna={this.props.soportaAlterna}
        />
        <MonthComponent
          id={this.props.id}
          month={1}
          dates={this.dates.filter(d => d.moment.get("month") === 1)}
          soportaAlterna={this.props.soportaAlterna}
        />
        <MonthComponent
          id={this.props.id}
          month={2}
          dates={this.dates.filter(d => d.moment.get("month") === 2)}
          soportaAlterna={this.props.soportaAlterna}
        />
        <MonthComponent
          id={this.props.id}
          month={3}
          dates={this.dates.filter(d => d.moment.get("month") === 3)}
          soportaAlterna={this.props.soportaAlterna}
        />
        <MonthComponent
          id={this.props.id}
          month={4}
          dates={this.dates.filter(d => d.moment.get("month") === 4)}
          soportaAlterna={this.props.soportaAlterna}
        />
        <MonthComponent
          id={this.props.id}
          month={5}
          dates={this.dates.filter(d => d.moment.get("month") === 5)}
          soportaAlterna={this.props.soportaAlterna}
        />
        <MonthComponent
          id={this.props.id}
          month={6}
          dates={this.dates.filter(d => d.moment.get("month") === 6)}
          soportaAlterna={this.props.soportaAlterna}
        />
        <MonthComponent
          id={this.props.id}
          month={7}
          dates={this.dates.filter(d => d.moment.get("month") === 7)}
          soportaAlterna={this.props.soportaAlterna}
        />
        <MonthComponent
          id={this.props.id}
          month={8}
          dates={this.dates.filter(d => d.moment.get("month") === 8)}
          soportaAlterna={this.props.soportaAlterna}
        />
        <MonthComponent
          id={this.props.id}
          month={9}
          dates={this.dates.filter(d => d.moment.get("month") === 9)}
          soportaAlterna={this.props.soportaAlterna}
        />
        <MonthComponent
          id={this.props.id}
          month={10}
          dates={this.dates.filter(d => d.moment.get("month") === 10)}
          soportaAlterna={this.props.soportaAlterna}
        />
        <MonthComponent
          id={this.props.id}
          month={11}
          dates={this.dates.filter(d => d.moment.get("month") === 11)}
          soportaAlterna={this.props.soportaAlterna}
        />
      </div>
    );
  }
}
