import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Container, Col, Button, Row } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import DatePicker from "../common/DatePicker";
import ReactTable from "react-table";
import MexicanCurrency from "../common/currency";
import s from "./styles.scss";
import IAction from "../../../types/IAction";
import { CONCEPTOS, REPORT_TYPE } from "../../../utils/constants";
import isNil from "lodash.isnil";

interface Props {
  clubs: object[];
  depositoReferenciado: object[];
  dealers: object[];
  dealersStatusReport: object;
  getDealers: (clubId: string, name: string) => IAction;
  getDealersStatusReport: (
    tipoReporte: string,
    clubId: string,
    concesionarioId: string,
    fechaHasta: string
  ) => IAction;
  downloadPDFDealersStatusReport: (
    tipoReporte: string,
    clubId: string,
    concesionarioId: string,
    fechaHasta: string
  ) => IAction;
  downloadCSVDealersStatusReport: (
    tipoReporte: string,
    clubId: string,
    concesionarioId: string,
    fechaHasta: string
  ) => IAction;
}

interface State {
  filters: object;
  clubs: object[];
  dataForTable: object[];
  setData: boolean;
}

class DealerStatementsPage extends React.Component<Props, State> {
  state = {
    filters: {
      idConcepto: "",
      clubId: "",
      concesionarioId: "",
      tipoReporte: "",
      fechaHasta: null
    },
    clubs: [],
    dataForTable: [],
    setData: false
  };

  componentDidUpdate() {
    const { filters, setData } = this.state;
    let createData = [];
    if (filters.tipoReporte.length != 0 && setData) {
      if (
        filters.tipoReporte == "ANTIGUEDAD_FACTURAS" &&
        this.props.dealersStatusReport.hasOwnProperty(
          "reporteAntiguedadFacturas"
        )
      ) {
        this.props.dealersStatusReport.reporteAntiguedadFacturas.clubConcesionarios.forEach(
          element => {
            createData.push({ concesionario: element.clubNombre });
            element.cargos.forEach(cargo => createData.push(cargo));
          }
        );
        let data = Object.assign(
          { fechaCargo: "SUBTOTALES" },
          this.props.dealersStatusReport.reporteAntiguedadFacturas.subtotales
        );
        createData.push(data);
        data = Object.assign({
          de91a120Dias: "GRAN TOTAL",
          mas120Dias: this.props.dealersStatusReport.reporteAntiguedadFacturas
            .granTotal
        });
        createData.push(data);
        this.setState({ dataForTable: createData, setData: false });
      } else if (
        this.props.dealersStatusReport.hasOwnProperty("reportePorConcesionario")
      ) {
        this.props.dealersStatusReport.reportePorConcesionario.cargos.forEach(
          element => createData.push(element)
        );
        let data = Object.assign({
          descripcion: "TOTAL",
          importeCargo: this.props.dealersStatusReport.reportePorConcesionario
            .granTotal
        });
        createData.push(data);
        this.setState({ dataForTable: createData, setData: false });
      }
    }
  }

  columns = [
    {
      Header: () => <p className="font-weight-bold">Club</p>,
      id: 0,
      width: 80,
      accessor: "clubNombre",
      Cell: row => {
        return <p>{row.original.clubNombre}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Concesionario</p>,
      id: 1,
      width: 250,
      accessor: "concesionario",
      Cell: row => {
        return <p>{row.original.concesionario}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Factura</p>,
      id: 2,
      width: 80,
      accessor: "facturaFolioReferencia",
      Cell: row => {
        return <p>{row.original.facturaFolioReferencia}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Referencia</p>,
      id: 3,
      width: 160,
      accessor: "referencia",
      Cell: row => {
        return <p>{row.original.referencia}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Fecha</p>,
      id: 4,
      width: 100,
      accessor: "fechaCargo",
      Cell: row => {
        return <p>{row.original.fechaCargo}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Descripción</p>,
      id: 5,
      width: 320,
      accessor: "descripcion",
      Cell: row => {
        return <p>{row.original.descripcion}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Importe</p>,
      id: 6,
      width: 80,
      accessor: "importeCargo",
      Cell: row => {
        return (
          <p className="text-right m-0 p-0">
            <MexicanCurrency quantity={row.original.importeCargo} />
          </p>
        );
      }
    }
  ];

  secondColumns = [
    {
      Header: () => <p className="font-weight-bold">Concesionario</p>,
      id: 0,
      width: 250,
      accessor: "concesionario",
      Cell: row => {
        return <p>{row.original.concesionario}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Factura</p>,
      id: 1,
      width: 80,
      accessor: "facturaFolioReferencia",
      Cell: row => {
        return <p>{row.original.facturaFolioReferencia}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Referencia</p>,
      id: 2,
      width: 160,
      accessor: "referencia",
      Cell: row => {
        return <p>{row.original.referencia}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Fecha</p>,
      id: 3,
      width: 100,
      accessor: "fechaCargo",
      Cell: row => {
        return <p>{row.original.fechaCargo}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Menos 30 días</p>,
      id: 4,
      width: 120,
      accessor: "menos30Dias",
      Cell: row => {
        return row.original.hasOwnProperty("menos30Dias") ? (
          <p className="text-right m-0 p-0">
            <MexicanCurrency quantity={row.original.menos30Dias} />
          </p>
        ) : null;
      }
    },
    {
      Header: () => <p className="font-weight-bold">31 a 60 días</p>,
      id: 5,
      width: 120,
      accessor: "de31a60Dias",
      Cell: row => {
        return row.original.hasOwnProperty("de31a60Dias") ? (
          <p className="text-right m-0 p-0">
            <MexicanCurrency quantity={row.original.de31a60Dias} />
          </p>
        ) : null;
      }
    },
    {
      Header: () => <p className="font-weight-bold">61 a 90 días</p>,
      id: 6,
      width: 120,
      accessor: "de61a90Dias",
      Cell: row => {
        return row.original.hasOwnProperty("de61a90Dias") ? (
          <p className="text-right m-0 p-0">
            <MexicanCurrency quantity={row.original.de61a90Dias} />
          </p>
        ) : null;
      }
    },
    {
      Header: () => <p className="font-weight-bold">91 a 120 días</p>,
      id: 7,
      width: 120,
      accessor: "de91a120Dias",
      Cell: row => {
        return row.original.hasOwnProperty("de91a120Dias") ? (
          row.original.de91a120Dias == "GRAN TOTAL" ? (
            <p className="text-right m-0 p-0">
              <p>{row.original.de91a120Dias}</p>
            </p>
          ) : (
            <p className="text-right m-0 p-0">
              <MexicanCurrency quantity={row.original.de91a120Dias} />
            </p>
          )
        ) : null;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Más de 120 días</p>,
      id: 8,
      width: 120,
      accessor: "mas120Dias",
      Cell: row => {
        return row.original.hasOwnProperty("mas120Dias") ? (
          <p className="text-right m-0 p-0">
            <MexicanCurrency quantity={row.original.mas120Dias} />
          </p>
        ) : null;
      }
    }
  ];

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      if (fieldName == "tipoReporte") {
        this.setState({ dataForTable: [] });
      }
      if (fieldName == "clubId" && value.toString().length != 0) {
        this.props.getDealers(value, "");
      }
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  onChangeConcepto = e => {
    let valFilters = this.state.filters;
    valFilters.idConcepto = e.target.value;
    let filterClubs = this.props.clubs.filter(
      value => value.concepto == e.target.value
    );
    this.setState({ filters: valFilters, clubs: filterClubs });
  };

  genReportCSV = e => {
    e.preventDefault();
    const { filters } = this.state;
    this.props.downloadCSVDealersStatusReport(
      filters.tipoReporte,
      filters.clubId,
      filters.concesionarioId,
      filters.fechaHasta
    );
  };

  genReportPDF = e => {
    e.preventDefault();
    const { filters } = this.state;
    this.props.downloadPDFDealersStatusReport(
      filters.tipoReporte,
      filters.clubId,
      filters.concesionarioId,
      filters.fechaHasta
    );
  };

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const filters = {
          ...state.filters,
          [fieldName]: date
        };
        return { ...state, error: {}, filters };
      });
    };
  };

  search = e => {
    e.preventDefault();
    const { filters } = this.state;
    this.props.getDealersStatusReport(
      filters.tipoReporte,
      filters.clubId,
      filters.concesionarioId,
      filters.fechaHasta
    );
    this.setState({ setData: true, dataForTable: [] });
  };

  validateButton = () => {
    const { filters } = this.state;
    let activate = true;
    if (filters.tipoReporte.toString().length != 0) {
      if (
        filters.tipoReporte == "ANTIGUEDAD_FACTURAS" &&
        isNil(filters.fechaHasta)
      ) {
        activate = true;
      } else {
        activate = false;
      }
    }
    return activate;
  };

  render() {
    const {
      clubId,
      idConcepto,
      fechaHasta,
      concesionarioId,
      tipoReporte
    } = this.state.filters;
    const { clubs, dataForTable } = this.state;
    const { dealers } = this.props;
    return (
      <Container className="p-0">
        <div className="row mt-3 mb-3">
          <div className="col">
            <p className={`${s.primaryTitle} w-100 m-0`}>
              Buscar estado de cuenta de concesionarios
            </p>
          </div>
        </div>
        <Row>
          <Col xs={12} md={4} lg={4}>
            <BaseInput
              label="Concepto"
              name="concepto"
              id="concepto"
              type="select"
              placeholder="Seleccionar concepto"
              value={idConcepto}
              onChange={e => this.onChangeConcepto(e)}
              size={"sm"}
              options={[{ value: "", label: "Seleccionar concepto" }].concat(
                CONCEPTOS
                  ? CONCEPTOS.map(option => ({
                      value: option.value,
                      label: option.label
                    }))
                  : []
              )}
            />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <BaseInput
              label="Club"
              name="club"
              id="club"
              type="select"
              placeholder="Seleccionar club"
              value={clubId}
              disabled={idConcepto.length == 0}
              onChange={this.onChangeFilter("clubId")}
              size={"sm"}
              options={[{ value: "", label: "Seleccionar club" }].concat(
                clubs.length > 0
                  ? clubs.map(option => ({
                      value: option.clubId,
                      label: option.nombre
                    }))
                  : []
              )}
            />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <BaseInput
              label="Concesionario"
              name="concesionarioId"
              id="concesionarioId"
              type="select"
              placeholder="Seleccionar concesionario"
              value={concesionarioId}
              onChange={this.onChangeFilter("concesionarioId")}
              size={"sm"}
              options={[
                { value: "", label: "Seleccionar concesionario" }
              ].concat(
                dealers
                  ? dealers.map(option => ({
                      value: option.id,
                      label: option.nombre
                    }))
                  : []
              )}
            />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <BaseInput
              label="Tipo de reporte"
              name="tipoReporte"
              id="tipoReporte"
              type="select"
              placeholder="Tipo de reporte"
              value={tipoReporte}
              onChange={this.onChangeFilter("tipoReporte")}
              size={"sm"}
              options={[
                { value: "", label: "Seleccionar tipo de reporte" }
              ].concat(
                REPORT_TYPE
                  ? REPORT_TYPE.map(option => ({
                      value: option.value,
                      label: option.label
                    }))
                  : []
              )}
            />
          </Col>
          {tipoReporte == "ANTIGUEDAD_FACTURAS" ? (
            <Col xs={12} md={4} lg={4}>
              <DatePicker
                label="Fecha Hasta"
                name="fechaHasta"
                id="fechaHasta"
                selected={fechaHasta}
                onChange={this.onChangeDate("fechaHasta")}
              />
            </Col>
          ) : null}
          <Col xs={12} md={4} lg={4} className="mt-4">
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.search}
              disabled={this.validateButton()}
            >
              Buscar
            </Button>
          </Col>
        </Row>
        <Row>
          <Col></Col>
          {tipoReporte == "ANTIGUEDAD_FACTURAS" ? (
            <Col xs={12} md={4} lg={3} className="mt-4">
              <Button
                size="sm"
                className={`float-right btn-block`}
                onClick={this.genReportCSV}
                disabled={dataForTable.length == 0}
              >
                Generar CSV
              </Button>
            </Col>
          ) : null}
          <Col xs={12} md={4} lg={3} className="mt-4">
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.genReportPDF}
              disabled={dataForTable.length == 0}
            >
              Generar PDF
            </Button>
          </Col>
          <Col></Col>
        </Row>
        <ReactTable
          data={dataForTable ? dataForTable : []}
          columns={
            tipoReporte == "ANTIGUEDAD_FACTURAS"
              ? this.secondColumns
              : this.columns
          }
          pageSize={dataForTable.length || 10}
          resizable={false}
          sortable={false}
          showPagination={false}
          className="mt-4 -highlight"
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(DealerStatementsPage));
