import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import BaseNumericInput from "../../../toolkit/baseNumericInput";
import DatePicker from "../common/DatePicker";
import s from "./styles.scss";
import { Container, Form, Col, Row, FormGroup, Label, Input } from "reactstrap";
import moment from "moment";

const formatTime = (dateTime?) =>
  moment(dateTime)
    .utc()
    .format("YYYY-MM-DD");

interface Props {
  benefits: any;
  updateMirrorFreePartners: (_checked: boolean) => any;
  updateFreePartnersByBirthDay: (_checked: boolean) => any;
  updateFreePartnersNumber: (_number: number) => any;
  updateFreePartnersInitialDate: (initialDate: any) => any;
  updateFreePartnersFinalDate: (finalDate: any) => any;
  updateFreePartnersMonths: (_number: number) => any;
}

class PromotionBenefitsFreeUsers extends React.Component<Props> {
  onChangeInitialDate = () => {
    return dateTime => {
      const value = formatTime(dateTime);
      this.props.updateFreePartnersInitialDate(value);
    };
  };

  onChangeFinalDate = () => {
    return dateTime => {
      const value = formatTime(dateTime);
      this.props.updateFreePartnersFinalDate(value);
    };
  };

  onChangeNumber = () => {
    return e => {
      const value = e.target.value;
      this.props.updateFreePartnersNumber(value);
    };
  };

  onChangeMonths = () => {
    return e => {
      const value = e.target.value;
      this.props.updateFreePartnersMonths(value);
    };
  };

  onChangeCheckActivo = e => {
    this.props.updateFreePartnersByBirthDay(e.target.checked);
  };

  onChangeCheckMirror = e => {
    this.props.updateMirrorFreePartners(e.target.checked);
  };

  clearFieldsValues = () => {
    this.setState({
      invitadosGratis: null,
      invitadosGratisFechaInicioPeriodo: null,
      invitadosGratisFechaFinPeriodo: null,
      invitadosGratisMeses: null
    });
  };

  render() {
    return (
      this.props.benefits && (
        <Container className="mt-5">
          <Form>
            <Row className="mt-3">
              <Col>
                <p style={{ color: "red", fontWeight: "bold" }}>
                  Invitados gratis
                </p>
              </Col>
            </Row>

            <Row>
              <Col xs={2}>
                <FormGroup check className="form-check-inline pl-1">
                  <Label check className="mt-4">
                    <Input
                      type="checkbox"
                      checked={this.props.benefits.invitadosGratisEspejo}
                      onChange={e => this.onChangeCheckMirror(e)}
                    />{" "}
                    Invitados espejo (12 meses)
                  </Label>
                </FormGroup>
              </Col>
              <Col xs={2}>
                <FormGroup check className="form-check-inline pl-1">
                  <Label check className="mt-4">
                    <Input
                      type="checkbox"
                      checked={this.props.benefits.invitadosGratisPorCumpleanos}
                      onChange={e => this.onChangeCheckActivo(e)}
                    />{" "}
                    Por cumpleaños
                  </Label>
                </FormGroup>
              </Col>
              <Col xs={2}>
                <BaseNumericInput
                  label={"# Invitados gratis"}
                  name="noInvitadosGratis"
                  id="noInvitadosGratis"
                  type="text"
                  value={this.props.benefits.invitadosGratis}
                  placeholder={"# Invitados gratis"}
                  onChange={this.onChangeNumber()}
                />
              </Col>
              <Col xs={2}>
                <DatePicker
                  label="Fecha inicio acceso"
                  name="invitadosGratisFechaInicio"
                  id="invitadosGratisFechaInicio"
                  selected={
                    this.props.benefits.invitadosGratisFechaInicioPeriodo
                  }
                  disabled={
                    !!this.props.benefits.invitadosGratisPorCumpleanos ||
                    !!this.props.benefits.invitadosGratisEspejo
                  }
                  onChange={this.onChangeInitialDate()}
                />
              </Col>
              <Col xs={2}>
                <DatePicker
                  label="Fecha fin acceso"
                  name="invitadosGratisFechaFin"
                  id="invitadosGratisFechaFin"
                  selected={this.props.benefits.invitadosGratisFechaFinPeriodo}
                  disabled={
                    !!this.props.benefits.invitadosGratisPorCumpleanos ||
                    !!this.props.benefits.invitadosGratisEspejo
                  }
                  onChange={this.onChangeFinalDate()}
                />
              </Col>
              <Col xs={2}>
                <BaseNumericInput
                  label={"# Meses Acceso"}
                  name="noMesesAcceso"
                  id="noMesesAcceso"
                  type="text"
                  value={this.props.benefits.invitadosGratisMeses || ""}
                  placeholder={"# Meses Acceso"}
                  disabled={
                    !!this.props.benefits.invitadosGratisPorCumpleanos ||
                    !!this.props.benefits.invitadosGratisEspejo
                  }
                  onChange={this.onChangeMonths()}
                />
              </Col>
            </Row>
          </Form>
        </Container>
      )
    );
  }
}

export default withStyles(s)(PromotionBenefitsFreeUsers);
