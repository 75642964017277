import React from "react";
import s from "./styles.scss";
import { Styled } from "../../../toolkit/decorators/Artifact";
import AdeudosFilters from "./filters";
import { columns } from "./reactTable";
import ReactTable from "react-table";
import { Row, Col, Button } from "reactstrap";
import ExpedienteVisor from "../expedientsV2visor/index";
import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import {
  countAdeudos,
  downloadAdeudos,
  getAdeudos
} from "../../actions/reports";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";

interface Props {}

interface State {
  page: number;
  pages: number;
  count: number;
  filters: any;
  data: any[];
  loading: boolean;
}

@Styled(s)
export default class AdeudosComponent extends React.Component<Props, State> {
  state = {
    page: 0,
    pages: 1,
    count: 0,
    filters: null,
    data: [],
    loading: false
  };

  fetchData = options => {
    if (!this.state.filters) {
      return;
    }

    this.submit(this.state.filters, options.page);
  };

  submit = async (filters, pageArg?) => {
    const page = pageArg || 0;
    this.setState({ page, filters });

    if (!(pageArg >= 0)) {
      infoNotification("Consultando adeudos, espere un momento por favor...");
      try {
        const responseCount = await countAdeudos(filters);
        const count = responseCount.data.body || 0;
        this.setState({
          count,
          pages: Math.ceil(count / 20)
        });
      } catch (err) {
        errorNotification("No se pudo consultar la información");
        return;
      }
    }

    getAdeudos({ ...filters, page })
      .then(response => {
        this.setState({ data: response.data });
        successNotification("Adeudos consultados correctamente");
      })
      .catch(err =>
        errorNotification("No se pudo consultar la información...")
      );
  };

  download = () => {
    infoNotification(
      "Descargando reporte de adeudos, espere un momento por favor..."
    );
    downloadAdeudos(this.state.filters, () => {});
  };

  render() {
    return (
      <>
        {/* Filtros */}
        <AdeudosFilters submit={this.submit} userContext={{}} loading={false} />

        {/* Resultados de la página */}
        {this.state.count > 0 && (
          <Row className={"mt-5"}>
            <Col sm="6">
              Resultados de la búsqueda:{" "}
              <b
                style={{ fontWeight: "bolder", display: "inline-block" }}
                className="ml-3"
              >
                {this.state.count}
              </b>
            </Col>
            <Col sm="6" className="text-right">
              <span>Exportar a CSV: </span>
              <Button
                id="btnReportExportCsv"
                color="link"
                onClick={this.download}
              >
                <FontAwesomeIcon icon={faFileExport} color="black" />
              </Button>
            </Col>
          </Row>
        )}

        {/* Tabla */}
        <div className={s.fixReactTable}>
          <ReactTable
            manual
            data={this.state.data}
            loading={this.state.loading}
            pageSize={20}
            pages={this.state.pages}
            page={this.state.page}
            columns={columns}
            sortable={false}
            resizable={true}
            showPagination={true}
            showPageSizeOptions={false}
            getTrProps={() => ({ style: { height: "30px" } })}
            noDataText={"No hay adeudos por mostrar"}
            onFetchData={this.fetchData}
          />
        </div>
      </>
    );
  }
}
