import isEqual from "lodash.isequal";
import isObject from "lodash.isobject";
import toString from "lodash.tostring";
import mapValues from "lodash.mapvalues";
import cloneDeep from "lodash.clonedeep";

let initialState = null;

let finalState = null;

export const setChildStep = state => {
  const toStringValues = value => {
    if (!isObject(value)) {
      return toString(value);
    }

    return value;
  };

  const dubState = mapValues(cloneDeep(state), toStringValues);

  if (!initialState) {
    initialState = dubState;
    finalState = dubState;
  } else finalState = dubState;
};

export const compareStates = () => {
  const equal = isEqual(initialState, finalState);
  return equal;
};

export const resetStates = () => {
  initialState = finalState;
};

export const cleanUp = () => {
  initialState = null;
  finalState = null;
};
