import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import * as catalogActions from "../../actions/catalogs";
import * as reportes from "../../actions/reports";
import Layout from "../../components/layout";
import ReportMonthlyAccrual from "../../components/accrualMonthlyReport";
import IAction from "../../../types/IAction";

interface Props {
  getClubs: () => void;
  downloadReportMonthlyAccrual: (
    clubVentaId: number,
    clubDispersionId: number,
    fechaInicio: string,
    fechaFin: string,
    tipo: string,
    tipoProducto: string
  ) => IAction;
  downloadReportAccrualProcessLinesSent: (processId: number) => IAction;
  downloadReportItemsToAccrualBySaleDate: (fecha: string) => IAction;
  clubs: object[];
}

interface State {}

class ReportMonthlyAccrualPage extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    this.props.getClubs();
  }

  render() {
    return (
      <main>
        <Helmet title="ReportMonthlyAccrual" />
        <Layout>
          <ReportMonthlyAccrual
            clubs={this.props.clubs}
            downloadReportMonthlyAccrual={
              this.props.downloadReportMonthlyAccrual
            }
            downloadReportAccrualProcessLinesSent={
              this.props.downloadReportAccrualProcessLinesSent
            }
            downloadReportItemsToAccrualBySaleDate={
              this.props.downloadReportItemsToAccrualBySaleDate
            }
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({ catalogs }) => {
  return {
    clubs: catalogs.clubs
  };
};

const mapDispatchToProps = dispatch => ({
  getClubs: () => {
    dispatch(catalogActions.getClubs());
  },
  downloadReportMonthlyAccrual: (
    clubVentaId: number,
    clubDispersionId: number,
    fechaInicio: string,
    fechaFin: string,
    tipo: string,
    tipoProducto: string
  ) => {
    dispatch(
      reportes.downloadReportMonthlyAccrual(
        clubVentaId,
        clubDispersionId,
        fechaInicio,
        fechaFin,
        tipo,
        tipoProducto
      )
    );
  },
  downloadReportAccrualProcessLinesSent: (processId: number) => {
    dispatch(reportes.downloadReportAccrualProcessLinesSent(processId));
  },
  downloadReportItemsToAccrualBySaleDate: (fecha: string) => {
    dispatch(reportes.downloadReportItemsToAccrualBySaleDate(fecha));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportMonthlyAccrualPage);
