import React from "react";
import { connect } from "react-redux";
import SaveNextCarousel from "../../components/saveNextCarousel";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import { PROMOTIONS_STEPS } from "../../../steps";

interface Props {
  savedPromotion: { id: string };
  history: any;
  match: any;
  location: any;
  index: number;
  component: any;
}

interface State {}

class PromotionsCarousel extends React.Component<Props, State> {
  state = {
    hiddenSaveButton: false
  };

  componentDidMount() {
    this.setState(state => {
      const hiddenSaveButton = this.props.index + 1 > PROMOTIONS_STEPS.length;
      return {
        ...state,
        hiddenSaveButton
      };
    });
  }

  componentDidUpdate() {}

  onClickNext = e => {
    e.preventDefault();
    const params = {
      ...this.props.match.params,
      id: this.props.match.params.id || this.props.savedPromotion.id
    };
    this.props.history.push(
      PROMOTIONS_STEPS[this.props.index].getNextStep(params)
    );
  };

  getProductId = () =>
    this.props.match.params.id ||
    (this.props.savedPromotion && this.props.savedPromotion.id);

  render() {
    const promotionId = this.getProductId();

    return (
      <main>
        <Helmet title="Nueva Promocion" />
        <Layout>
          <SaveNextCarousel
            steps={PROMOTIONS_STEPS.map(({ title, getHref }) => ({
              title,
              href: promotionId ? getHref(promotionId) : null
            }))}
            onClickNext={this.onClickNext}
            index={this.props.index}
            disableNext={
              !this.props.match.params.id && !this.props.savedPromotion
            }
            hiddenSaveButton={this.state.hiddenSaveButton}
            activeStep={promotionId ? PROMOTIONS_STEPS.length : 0}
          >
            {PROMOTIONS_STEPS.map(
              ({ component: Component, ...rest }, index) => (
                <Component {...rest} key={index} />
              )
            )}
          </SaveNextCarousel>
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = () => ({});

const mapStateToProps = state => {
  const { promotions } = state;
  return {
    savedPromotion: promotions.basicPromotion
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionsCarousel);
