import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import { Row, Col, Button } from "reactstrap";
import s from "./../../tabStyles.scss";
import PersonSelector from "../../../../../People/containers/PersonSelector";
import { Partner } from "./../../interfaces";
import ConfirmModal from "../../../../components/common/confirmModal/confirmModal";
import { updateTitularRequest } from "../../../../actions/partners";
import {
  infoNotification,
  successNotification
} from "../../../../../utils/notifications";
import { handleRequestError } from "../../../../../utils/helpers";

interface Props {
  partner: Partner;
  refreshPartner: () => void;
}

interface State {
  selectedPerson: any;
  confirmModalIsOpen: boolean;
}

class CambioTitular extends React.Component<Props, State> {
  state = {
    selectedPerson: null,
    confirmModalIsOpen: false
  };

  toggleModal = () => {
    this.setState({ confirmModalIsOpen: !this.state.confirmModalIsOpen });
  };

  setSelectedPerson = (person: any) => {
    this.setState({ selectedPerson: person });
  };

  handleChange = () => {
    const { partner } = this.props;
    const { idMembresiaSocio } = partner;
    const { selectedPerson } = this.state;

    infoNotification("Actualizando titular de la membresía socio...");

    updateTitularRequest(
      {
        personaNuevoTitular: selectedPerson.personaId,
        esPersonaMismaMembresia: false
      },
      idMembresiaSocio
    )
      .then(() => {
        this.toggleModal();
        successNotification("Titular actualizado correctamente");
        if (this.props.refreshPartner) {
          this.props.refreshPartner();
        }
      })
      .catch(handleRequestError);
  };

  render() {
    return (
      <React.Fragment>
        <ConfirmModal
          title="Cambio de titula"
          message={
            !!this.props.partner &&
            !!this.state.selectedPerson &&
            `Se realizará el cambio de titular de ${this.props.partner.nombrePersona} a ${this.state.selectedPerson.nombre} ${this.state.selectedPerson.primerApellido}`
          }
          isOpen={this.state.confirmModalIsOpen}
          requireInput={false}
          ok={this.handleChange}
          cancel={() => {
            this.setState({
              confirmModalIsOpen: false
            });
          }}
        />

        <div>
          <div className="row mt-3 mb-3">
            <div className="col">
              <p className={`${s.primaryTitle} w-100 m-0`}>Cambio de titular</p>
            </div>
          </div>
          <Row className="row mt-3 mb-3">
            <Col sm={8}>
              <PersonSelector
                setPerson={this.setSelectedPerson}
                cKey={"LD8H2IUYX0"}
              />
            </Col>
            <Col className="pl-0 mt-4" sm={4}>
              <Button
                onClick={this.toggleModal}
                disabled={!this.state.selectedPerson}
              >
                Cambio de titular
              </Button>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(s)(CambioTitular));
