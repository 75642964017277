import React from "react";
import ITypeSelect from "../../../toolkit/baseForms2/iTypeSelect/ITypeSelect";
import { errorNotification } from "../../../utils/notifications";
import { getEmployeesBasicCatalog } from "../../../Home/actions/catalogs";

interface Props {
  useNumeroEmpleado?: boolean;
  initValue?: string;
  fieldName?: string;
  label?: string;
  includeSuspended?: boolean;
  onChange: (fieldName, value) => void;
}

interface State {
  loading: boolean;
  employees: any[];
}

export default class EmployeeSelector extends React.Component<Props, State> {
  state = {
    loading: false,
    employees: []
  };

  async componentDidMount(): Promise<void> {
    this.setState({ loading: true });

    try {
      const users = this.props.includeSuspended
        ? await getEmployeesBasicCatalog(false, true)
        : await getEmployeesBasicCatalog(false, false);

      this.setState({ employees: users || [] });
    } catch (error) {
      errorNotification("No se pudieron cargar los empleados");
    }

    this.setState({ loading: false });
  }

  render() {
    return (
      <ITypeSelect
        data={this.state.employees}
        isLoading={this.state.loading}
        mapOption={item => ({
          value: this.props.useNumeroEmpleado
            ? item.employeeNumber
            : item.userId,
          label: item.label
        })}
        promptText={""}
        emptyLabel={"Seleccione al empleado"}
        wait={false}
        message={""}
        setter={(fieldName, value) => this.props.onChange(fieldName, value)}
        name={this.props.fieldName || "employees"}
        label={this.props.label || "Empleado"}
        inputClass={""}
        init={this.props.initValue}
      />
    );
  }
}
