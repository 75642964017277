import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import Reform from "@franleplant/reform";
import { Form, FormGroup, Label, Input, Container } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import BaseInput from "../../../../toolkit/baseInput";
import s from "./styles.scss";
import { FormErrors } from "@franleplant/reform/types";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import RichEditor from "../../richEditor";
import DatePicker from "../../common/DatePicker";
import moment from "moment";
import fill from "lodash.fill";
import findIndex from "lodash.findindex";
import { setChildStep } from "../../../helpers/stepper-state-comparator";

// TODO: Esta funcionalidad se heredó de los convenios; faltan exclusiones

interface MEMBERSHIP {
  datosBasicosMembresiaId: number;
  nombre: string;
}

interface MEMBERSHIP_SAVED {
  membresiaId: number;
}

interface Props {
  onClickSave: (e: object) => any;
  history: any;
  client: any;
  saveBasicAgreement: (agreement: object) => any;
  updateBasicAgreement: (convenioId: string, agreement: object) => any;
  match: any;
  agreement: Agreement;
  getBasicAgreement: (convenioId: string) => any;
  memberships: MEMBERSHIP[];
  users: object[];
  gettingAgreement: boolean;
}

interface Fields {
  nombre: string;
  responsableId: string;
  condicionesLegales: string;
  inicioVigencia: string;
  finVigencia: string;
  numeroMaximo: string;
  empresa: string;
  activo: boolean;
  sinFechaFinDeVigencia: boolean;
}

interface Agreement {
  condicionesLegales: string;
  nombre: string;
  responsableId: string;
  sinFechaFinDeVigencia: boolean;
  inicioVigencia: string;
  finVigencia: string;
  descripcion: string;
  membresias: MEMBERSHIP_SAVED[];
  empresa: string;
  numeroMaximo: string;
  activo: boolean;
}

interface State {
  errors: FormErrors;
  fields: Fields;
  editorState: EditorState;
  membershipsSelected: object;
}

const agreementToFields = ({
  nombre,
  responsableId,
  condicionesLegales,
  inicioVigencia,
  finVigencia,
  empresa,
  numeroMaximo,
  activo,
  sinFechaFinDeVigencia,
  descripcion
}) => {
  return {
    nombre,
    responsableId,
    condicionesLegales,
    inicioVigencia: moment(inicioVigencia).format("YYYY-MM-DD"),
    finVigencia: moment(finVigencia).format("YYYY-MM-DD"),
    empresa,
    numeroMaximo,
    activo,
    sinFechaFinDeVigencia,
    descripcion,
    membresiasAplicables: []
  };
};

class BasicDataAgreementForm extends React.Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);

  state = {
    fields: {
      nombre: "",
      responsableId: "",
      inicioVigencia: "",
      finVigencia: "",
      condicionesLegales: "",
      numeroMaximo: "",
      empresa: "",
      activo: false,
      sinFechaFinDeVigencia: false,
      descripcion: "",
      membresiasAplicables: null
    },
    editorState: EditorState.createEmpty(),
    errors: {},
    membershipsSelected: null
  };

  setInitialState = () => {
    const { id } = this.props.match.params;
    if (id && (!this.props.gettingAgreement && !this.props.agreement)) {
      this.props.getBasicAgreement(id);
    }

    if (!this.state.membershipsSelected) {
      this.setState({
        membershipsSelected: fill(Array(this.props.memberships.length), false)
      });
    }

    const { agreement: propsAgreement } = this.props;

    if (propsAgreement) {
      const fields = agreementToFields(propsAgreement);
      setChildStep(fields);
      this.setState(state => ({
        ...state,
        fields,
        editorState: EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(propsAgreement.descripcion).contentBlocks
          )
        )
      }));

      if (
        propsAgreement.finVigencia &&
        this.state.fields.sinFechaFinDeVigencia
      ) {
        this.setState(state => {
          const fields = {
            ...state.fields,
            finVigencia: ""
          };
          setChildStep(fields);
          return {
            ...state,
            fields
          };
        });
      }
    }
  };

  componentDidMount() {
    this.setInitialState();
    this.props.onClickSave(e => {
      e.preventDefault();

      if (this.re.validateFormFromState()) {
        const {
          inicioVigencia,
          finVigencia,
          numeroMaximo,
          responsableId
        } = this.state.fields;
        const agreement = {
          ...this.state.fields,
          /*descripcion: draftToHtml(
            convertToRaw(this.state.editorState.getCurrentContent())
          ),*/
          responsableId: parseInt(responsableId, 10),
          numeroMaximo: parseInt(numeroMaximo, 10),
          inicioVigencia: moment(inicioVigencia),
          finVigencia: finVigencia
            ? moment(finVigencia)
            : moment(inicioVigencia).add(100, "years"),
          membresias: this.props.memberships
            .filter((_, index) => this.state.membershipsSelected[index])
            .map(({ datosBasicosMembresiaId: membresiaId }) => ({
              membresiaId
            }))
        };

        delete agreement.membresiasAplicables;
        const { id } = this.props.match.params;

        if (!id) {
          this.props
            .saveBasicAgreement(agreement)
            .then(({ value: { data } }) => {
              this.props.history.push(
                `/grupos-convenios/datos-basicos/${data.grupoId}`
              );
            });
        } else {
          this.props.updateBasicAgreement(id, agreement);
        }
      }
    });
  }

  processed = false;

  optionsResponsible = users => {
    const response = [];
    response.push({ value: "", label: "Seleccionar Responsable" });
    users.forEach(user => {
      if (user.name) {
        response.push({
          label: user.name,
          value: user.userId
        });
      }
    });

    return response;
  };

  componentDidUpdate() {
    const { agreement: propsAgreement } = this.props;

    const { memberships } = this.props;

    if (
      memberships.length &&
      propsAgreement &&
      propsAgreement.membresias &&
      propsAgreement.membresias.length &&
      !this.processed
    ) {
      const { membresias: appliedMemberships } = propsAgreement;
      const { id } = this.props.match.params;
      this.setState({
        membershipsSelected: id
          ? memberships.map(
              ({ datosBasicosMembresiaId }) =>
                findIndex(
                  appliedMemberships,
                  m => datosBasicosMembresiaId === m.membresiaId
                ) > -1
            )
          : []
      });
      this.processed = true;
    }
  }

  dateValidator = (dateInput, inputName) => {
    const { id } = this.props.match.params;
    if (id && this.props.agreement[`${inputName}`] === dateInput) {
      return false;
    }
    const date = moment(dateInput).startOf("day");
    const now = moment().startOf("day");
    return date.isBefore(now);
  };

  bothDatesValidator = (dateInput, otherInput) => {
    const date = new Date(dateInput);
    const other = new Date(otherInput);
    return dateInput && otherInput && date >= other;
  };

  validationRules = {
    /*inicioVigencia: {
      required: true,
      dateMustBeFuture: value => this.dateValidator(value, "inicioVigencia"),
      bothDates: value =>
        this.bothDatesValidator(value, this.state.fields.finVigencia)
    },
    finVigencia: {
      dateMustBeFuture: value => this.dateValidator(value, "finVigencia"),
      bothDates: value =>
        this.bothDatesValidator(this.state.fields.inicioVigencia, value),
      required: value => !this.state.fields.sinFechaFinDeVigencia && !value
    },*/
    nombre: { required: true },
    descripcion: { required: true },
    /*condicionesLegales: { required: true },
    numeroMaximo: { required: true },
    empresa: { required: true },
    responsableId: { required: true },*/
    membresiasAplicables: {
      required: _ =>
        this.props.memberships
          .filter((_, index) => this.state.membershipsSelected[index])
          .map(({ datosBasicosMembresiaId: membresiaId }) => ({
            membresiaId
          })).length === 0
    }
  };

  validationMessages = {
    required: _ => `Obligatorio`,
    dateMustBeFuture: _ => "La fecha debe ser futura",
    bothDates: _ => "La fecha inicial debe ser menor a la final",
    minLength: ruleValue => `Debe tener al menos ${ruleValue} caracteres`,
    maxLength: ruleValue => `Deber tener maximo ${ruleValue} caracteres`,
    default: _ => `Inválido`
  };

  onChangeFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };

        setChildStep(fields);

        return { ...state, fields, errors: {} };
      });
      this.re.validateField(fieldName, value);
    };
  };

  onChangeFecha = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: date
        };
        setChildStep(fields);
        return { ...state, error: {}, fields };
      });
      this.re.validateField(fieldName, date);
    };
  };

  checkDisableDate = e => {
    const sinFechaFinDeVigencia = e.target.checked;
    const fields = {
      ...this.state.fields,
      sinFechaFinDeVigencia,
      finVigencia: ""
    };
    setChildStep(fields);
    this.setState(state => ({
      ...state,
      fields
    }));
  };

  checkActive = e => {
    const activo = e.target.checked;
    const fields = { ...this.state.fields, activo };
    setChildStep(fields);
    this.setState(() => ({
      fields
    }));
  };

  onChangeEditor = editorState => {
    this.setState(state => {
      const fields = {
        ...state.fields,
        descripcion: JSON.stringify(
          draftToHtml(convertToRaw(editorState.getCurrentContent()))
        ).replace(/<[^>]*>?|\\n|"/g, "")
      };
      setChildStep(fields);
      return {
        ...state,
        fields
      };
    });

    this.re.validateField(
      "descripcion",
      JSON.stringify(
        draftToHtml(convertToRaw(editorState.getCurrentContent()))
      ).replace(/<[^>]*>?|\\n|"/g, "")
    );

    return this.setState({ editorState });
  };

  onSelectMembership = (event, id) => {
    const { checked } = event.target;
    const { membershipsSelected } = this.state;
    membershipsSelected[id] = checked;
    this.setState({ membershipsSelected });
  };

  onChangeSeller = selected => {
    let responsableId = null;
    if (selected.length > 0) {
      responsableId = selected[0]["id"];
    } else {
      responsableId = null;
    }
    this.setState(state => {
      const fields = {
        ...state.fields,
        responsableId
      };

      setChildStep(fields);

      return { ...state, fields, errors: {} };
    });
  };

  formatOptions = options =>
    options.map(option => ({
      value: option == "Seleccionar" ? "" : option,
      label: option
    }));

  findUser = () => {
    let dataReturn = [];
    if (
      this.props.users &&
      this.props.agreement &&
      this.props.agreement.responsableId
    ) {
      let objReturn = this.props.users.find(
        element => element.userId == this.props.agreement.responsableId
      );
      if (objReturn.hasOwnProperty("userId")) {
        dataReturn.push({
          id: objReturn.userId,
          label:
            objReturn.name +
            " " +
            objReturn.firstSurname +
            " " +
            objReturn.secondSurname
        });
      }
    }
    return dataReturn;
  };

  render() {
    return (
      <Container className="p-0" fluid>
        <div className="row">
          <div className="col-3">
            <BaseInput
              label={"Nombre"}
              name="nombrePromocion"
              type="text"
              id="nombrePromocion"
              placeholder="Nombre"
              value={this.state.fields.nombre}
              onChange={this.onChangeFactory("nombre")}
              errors={this.re.mapFieldErrors("nombre")}
            />
          </div>
          <div className="col-3">
            <BaseInput
              label={"Descripción"}
              name="descripcion"
              type="text"
              id="descripcion"
              placeholder="Descripción"
              value={this.state.fields.descripcion}
              onChange={this.onChangeFactory("descripcion")}
              errors={this.re.mapFieldErrors("descripcion")}
            />
          </div>
          <div className="col-3">
            <DatePicker
              name="finVigencia"
              id="finVigencia"
              selected={this.state.fields.finVigencia}
              onChange={finVigencia =>
                this.setState({ fields: { ...this.state.fields, finVigencia } })
              }
              placeholder="Fecha de vigencia"
              label="Vigente hasta:"
            />
          </div>
          <div className="col-3 p-t-20">
            <Form className={`${s.checkboxContainer}`}>
              <FormGroup check className="form-check-inline">
                <Label check>
                  <Input
                    type="checkbox"
                    checked={this.state.fields.activo}
                    onChange={e => this.checkActive(e)}
                  />{" "}
                  Activo
                </Label>
              </FormGroup>
            </Form>
          </div>
        </div>

        <div className="form-group">
          <div className="row mb-3 mt-3">
            <div className="col">
              <p className={`${s.primaryTitle} w-100 m-0`}>
                Membresias Aplicables
              </p>
            </div>
          </div>
          <div className="row m-0 p-0">
            {this.state.membershipsSelected &&
              this.props.memberships &&
              this.props.memberships.length &&
              this.props.memberships.map(
                ({ nombre, datosBasicosMembresiaId }, index) => (
                  <div key={datosBasicosMembresiaId} className="col-3 p-0 m-0">
                    <div className="row p-0 m-0">
                      <div className="col-2 m-0 p-0">
                        <input
                          className={s.checkboxInput}
                          type="checkbox"
                          name={nombre}
                          checked={this.state.membershipsSelected[index]}
                          onChange={e => this.onSelectMembership(e, index)}
                          style={{ marginLeft: "0.75rem" }}
                        />
                      </div>
                      <div className="col-10 m-0 p-0">
                        <label className="my-0">{nombre}</label>
                      </div>
                    </div>
                  </div>
                )
              )}
            {this.re.mapFieldErrors("membresiasAplicables") &&
              this.re
                .mapFieldErrors("membresiasAplicables")
                .map((message, index) => {
                  return (
                    <span key={index} className={s.errorSpan}>
                      {message}
                      <br />
                    </span>
                  );
                })}
          </div>
        </div>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(BasicDataAgreementForm));
