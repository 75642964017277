import React from "react";
import { connect } from "react-redux";
import * as promotionActions from "../../actions/promotions";
import * as productActions from "../../actions/products";
import { withRouter } from "react-router-dom";
import MaintenancesSelector from "../../components/maintenancesSelector";

interface Props {
  maintenances: { datosBasicosMantenimientoId: string; nombre: string }[];
  searchMaintenancesByAttributes: () => any;
  successfulSave: boolean;
  onClickSave: (e: object) => any;
  addMaintenancesToPromotion: (
    promotionId: string,
    maintenances: object[]
  ) => any;
  getMaintenancesPromotion: (id: string) => any;
  maintenancesPromotion: { mantenimientoId: string }[];
  match: any;
}

interface State {
  selected: object[];
}

class AddMaintenancesPromotionPage extends React.Component<Props, State> {
  state = {
    selected: null
  };

  selectedMaintenances = [];

  componentDidMount() {
    this.props.searchMaintenancesByAttributes();
    const { id } = this.props.match.params;

    this.props.onClickSave(e => {
      e.preventDefault();
      this.props.addMaintenancesToPromotion(
        id,
        this.selectedMaintenances.map(({ id: mantenimientoId }) => ({
          mantenimientoId
        }))
      );
    });

    this.props.getMaintenancesPromotion(id);
  }

  selectedLoaded = false;

  componentDidUpdate() {
    if (
      !this.selectedLoaded &&
      this.props.maintenances &&
      this.props.maintenancesPromotion
    ) {
      this.setState({
        selected: this.props.maintenancesPromotion.map(
          ({ mantenimientoId }) => {
            const { nombre } = this.props.maintenances.find(
              ({ datosBasicosMantenimientoId }) =>
                datosBasicosMantenimientoId === mantenimientoId
            );
            return {
              nombre,
              datosBasicosMantenimientoId: mantenimientoId
            };
          }
        )
      });
      this.selectedLoaded = true;
    }
  }

  render() {
    return (
      <MaintenancesSelector
        items={this.props.maintenances}
        selected={this.state.selected}
        updateSelection={({ selected }) => {
          this.selectedMaintenances = selected;
        }}
        searchByText={this.props.searchMaintenancesByAttributes}
        keyId="datosBasicosMantenimientoId"
        keyName="nombre"
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  searchMaintenancesByAttributes: (attributes: object) => {
    dispatch(productActions.searchByAttributes(attributes));
  },
  addMaintenancesToPromotion: (promotionId: string, maintenances: object[]) => {
    dispatch(
      promotionActions.addMaintenancesToPromotion(promotionId, maintenances)
    );
  },
  getMaintenancesPromotion: (id: string) => {
    dispatch(promotionActions.getMaintenancesPromotion(id));
  }
});

const mapStateToProps = state => {
  const { promotions, products } = state;

  return {
    successfulSave: promotions.maintenancesSaved,
    validClubs: promotions.promotionClubs,
    maintenances: products.maintenancesByAttributes,
    maintenancesPromotion: promotions.promotionMaintenances
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddMaintenancesPromotionPage)
);
