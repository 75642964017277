import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import { Button, Col, Container, Label, Row, Form } from "reactstrap";
import BaseInput from "../../../../toolkit/baseInput";
import s from "../styles.scss";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import {
  ESTATUS_COMISION,
  FILTERS,
  TIPO_PRODUCTO_COMISIONES
} from "../interfaces";

interface Props {
  fetchSearchData: (filters: FILTERS) => void;
  clubs: { clubId: number; nombre: string }[];
  periods: {
    id: number;
    nombre: string;
    fechaInicio: string;
    fechaFin: string;
  }[];
  sellers: any[];
  showProccess?: boolean;
  fetchProccess?: () => void;
  userContext: any;
}

interface State extends FILTERS {}

class ReportFilter extends React.Component<Props, State> {
  state = {
    clubId: null,
    periodoId: null,
    empleadoId: null,
    fechaRegistroDesde: null,
    fechaRegistroHasta: null,
    estatus: null,
    tipo: null,
    fechaAplicacionDesde: null,
    fechaAplicacionHasta: null,
    disabledSearch: true,
    tipoProducto: null
  };

  // @ts-ignore
  componentDidMount = () => {
    this.setState({
      clubId:
        (this.props.userContext && this.props.userContext.clubSessionId) || null
    });
  };

  toggleDisabledSearch = () => {
    let state = this.state;
    if (this.state.clubId && this.state.periodoId) state.disabledSearch = false;
    else state.disabledSearch = true;
    this.setState(state);
  };

  onChangeClubDrop = async e => {
    let state = this.state;
    state["clubId"] = e.target.value;
    await this.setState(state);
    if (state.periodoId) this.toggleDisabledSearch();
  };

  onChangePeriodDrop = async e => {
    let state = this.state;

    if (e.target.value > 0) {
      var periodo = this.props.periods.filter(
        value => value.id == e.target.value
      );
      state["fechaRegistroDesde"] = periodo[0].fechaInicio;
      state["fechaRegistroHasta"] = periodo[0].fechaFin;
    }

    state["periodoId"] = e.target.value;
    await this.setState(state);
    if (state.clubId) this.toggleDisabledSearch();
  };

  onChangeGenericDrop = async (e, fieldName) => {
    let state = this.state;
    state[fieldName] = e.target.value;
    await this.setState(state);
    this.toggleDisabledSearch();
  };

  onChangeSeller = selected => {
    if (selected.length > 0) {
      let state = this.state;
      state.empleadoId = selected[0]["id"];
      this.setState(state);
    } else {
      let state = this.state;
      state.empleadoId = null;
      this.setState(state);
    }
  };

  render() {
    return (
      <Container className="mt-3 mb-5 p-0">
        <Form
          onSubmit={e => {
            e.preventDefault();
            this.props.fetchSearchData(this.state);
          }}
        >
          <Row className="my-4">
            <Col sm="3">
              <BaseInput
                label={"* Club"}
                name="Club"
                type="select"
                id="club"
                size={"sm"}
                disabled={
                  this.props.userContext &&
                  this.props.userContext.clubSessionName
                }
                value={this.state.clubId}
                options={[{ value: -1, label: "Seleccionar el club" }].concat(
                  this.props.clubs.map(({ clubId, nombre }) => {
                    return {
                      value: clubId,
                      label: nombre
                    };
                  })
                )}
                onChange={e => this.onChangeClubDrop(e)}
              />
            </Col>
            <Col sm="3">
              <BaseInput
                label={"* Periodo"}
                name="perios"
                type="select"
                id="periodo"
                size={"sm"}
                value={this.state.periodoId}
                options={[
                  { value: -1, label: "Seleccionar el periodo" }
                ].concat(
                  this.props.periods.map(({ id, nombre }) => {
                    return {
                      value: id,
                      label: nombre
                    };
                  })
                )}
                onChange={e => this.onChangePeriodDrop(e)}
              />
            </Col>
            <Col sm="3">
              <BaseInput
                label={"Estatus de la comisión"}
                name="estatus"
                type="select"
                id="estatus"
                size={"sm"}
                value={this.state.estatus}
                options={[{ value: -1, label: "Selecciona una opción" }].concat(
                  // @ts-ignore
                  ESTATUS_COMISION.map(({ value, label }) => ({ value, label }))
                )}
                onChange={e => this.onChangeGenericDrop(e, "estatus")}
              />
            </Col>
            <Col sm="3">
              <BaseInput
                label={"Tipo de producto"}
                name="tipoProducto"
                type="select"
                id="tipoProducto"
                size={"sm"}
                value={this.state.tipoProducto}
                options={[{ value: -1, label: "Selecciona una opción" }].concat(
                  // @ts-ignore
                  TIPO_PRODUCTO_COMISIONES.map(({ value, label }) => ({
                    value,
                    label
                  }))
                )}
                onChange={e => this.onChangeGenericDrop(e, "tipoProducto")}
              />
            </Col>

            <Col sm="3">
              <Label className={s.label}>Nombre de vendedor</Label>
              <Typeahead
                id={"vendedor"}
                promptText={"Vendedores"}
                bsSize={"sm"}
                onChange={selected => this.onChangeSeller(selected)}
                emptyLabel={"Sin coincidencias"}
                disabled={this.props.sellers ? false : true}
                maxResults={20}
                options={
                  this.props.sellers
                    ? this.props.sellers.map(item => ({
                        id: item.userId,
                        label: `${item.name || ""} ${item.firstSurname ||
                          ""} ${item.secondSurname || ""}`.trim(),
                        seller: item
                      }))
                    : []
                }
              />
            </Col>

            <Col sm="3">
              <label>&nbsp;</label>
              <Button
                size="md"
                disabled={this.state.disabledSearch}
                block
                type="submit"
              >
                Buscar
              </Button>
            </Col>

            <Col hidden={!this.props.showProccess} sm="3">
              <label>&nbsp;</label>
              <Button
                size="md"
                block
                onClick={() => this.props.fetchProccess()}
              >
                Procesar pagos
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ReportFilter));
