import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Container, Row, Col, Button } from "reactstrap";
import BaseInput from "../../../../toolkit/baseInput";
import s from "./styles.scss";
import { INTERFACE_STATE } from "../interfaces";

interface Props {
  bill: INTERFACE_STATE;
  getBill: (uuid: string) => any;
  cancelBill: () => any;
  remakeBill: () => any;
  setUUID: (uuid: string) => any;
}

interface State {
  uuid: string;
}

class BillSearch extends React.Component<Props, State> {
  state = {
    uuid: ""
  };

  onChangeUUID = e => {
    const uuid = e.target.value;
    this.setState({ uuid });
    this.props.setUUID(uuid);
  };

  getBill = () => {
    this.props.getBill(this.state.uuid);
  };

  render() {
    return (
      <Container>
        <Row>
          <Col className="p-0">
            <BaseInput
              label="UUID de la factura"
              name="uuidFactura"
              type="string"
              id="uuidFactura"
              placeholder="UUID de la factura"
              onChange={this.onChangeUUID}
              value={this.state.uuid}
            />
          </Col>
          <Col xs={2}>
            <Button
              className={`${s.searchButton} ${s.buttonMarginTop} ${s.primaryButton} mt-4 p-1`}
              onClick={this.getBill}
              size="lg"
              disabled={!this.state.uuid}
              block
            >
              Buscar
            </Button>
          </Col>
          <Col xs={2} className="p-0">
            <Button
              className={`${s.searchButton} ${s.buttonMarginTop} ${s.primaryButton} mt-4 p-1`}
              onClick={this.props.remakeBill}
              size="lg"
              disabled={!this.props.bill}
              block
            >
              Refacturar
            </Button>
          </Col>
          <Col xs={2} className="pr-0">
            <Button
              className={`${s.searchButton} ${s.buttonMarginTop} ${s.primaryButton} mt-4 p-1`}
              onClick={this.props.cancelBill}
              size="lg"
              disabled={!this.props.bill}
              block
            >
              Cancelar
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withStyles(s)(BillSearch);
