export const readMessageBack = (objectBack: any, message?: string): string => {
  let messageSend =
    objectBack.payload &&
    objectBack.payload.response &&
    objectBack.payload.response.data &&
    objectBack.payload.response.data.message
      ? objectBack.payload.response.data.message
      : message;
  return messageSend;
};
