import { FORMA_DE_PAGO } from "../utils/constants";
import { Filters } from "./types";

export const getEmptyFilters = (): Filters => ({
  club: "",
  clubAlterno: "",
  clubAlternoId: "",
  mantenimiento: "",
  membresia: "",
  promocion: "",
  convenio: "",
  empresa: "",
  descuento: FORMA_DE_PAGO.MENSUAL
});
