import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import * as prospectsActions from "../../actions/prospects";
import PartnerSuccessfullPurchase from "../../components/partnerSuccessfullPurchase";
import { getPartner } from "../../actions/partners";

interface Props {
  cleanSuccessfullPurchase: () => void;
  getPartner: (params: object) => void;
}

class PartnerSuccessfullPurchasePage extends React.Component<Props, void> {
  componentDidMount() {
    this.props.cleanSuccessfullPurchase();
  }

  render() {
    return (
      <main>
        <Helmet title="Membresia Socio" />
        <Layout>
          <PartnerSuccessfullPurchase
            // @ts-ignore
            getPartner={this.props.getPartner}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  cleanSuccessfullPurchase: () => {
    return dispatch(prospectsActions.cleanSuccessfullPurchase());
  },
  getPartner: (params: object) => {
    return dispatch(getPartner(params));
  }
});

const mapStateToProps = _ => {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerSuccessfullPurchasePage);
