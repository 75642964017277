import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import { Container, Col, Button, Row } from "reactstrap";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BaseInput from "../../../toolkit/baseInput";
import MexicanCurrency from "../common/currency";
import s from "./styles.scss";
import { getClubsRequest } from "../../actions/catalogs";
import {
  downloadReportPartnerMembershipDirectory,
  getReportPartnerMembershipDirectory
} from "../../actions/reports";
import { handleRequestError } from "../../../utils/helpers";
import {
  Props,
  State,
  partnerMembershipStatus,
  partnerMembershipTypes,
  partnerMembershipPaymentTypes,
  partnerMembershipAggrementTypes
} from "./interfaces";
import { userHasPermission } from "../../../utils/accessTree";
import permissionCodes from "../../../utils/permissionCodes";
import {
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import BaseNumericInput from "../../../toolkit/baseNumericInput";
import BaseAlphabeticInput from "../../../toolkit/baseAlphabeticInput";
import BaseCsvIconDownloader from "../../../toolkit/baseCsvIconDownloader";

class PartnerMembershipDirectoryReport extends React.Component<Props, State> {
  state = {
    filters: {
      clubId: null,
      personaId: null,
      estatusMembresia: null,
      tipoMembresia: null,
      formaPago: null,
      tieneConvenio: null,
      personaNombre: null
    },
    onSearch: false,
    clubs: [],
    data: []
  };

  componentDidMount() {
    this.getClubsCatalog();

    const { filters } = this.state;
    filters.clubId = this.props.userContext.clubSessionId;
    this.setState({ filters: { ...filters } });
  }

  getClubsCatalog = () => {
    getClubsRequest()
      .then(({ data }) => {
        this.setState({
          clubs: (data || [])
            .filter(c => !!c.migradoCrm2)
            .map(option => ({
              value: option.clubId,
              label: option.nombre
            }))
        });
      })
      .catch(handleRequestError);
  };

  columns = [
    {
      Header: () => <p className="font-weight-bold">Club</p>,
      Cell: row => {
        return <p className={s.initial}>{row.original.clubClave}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold"># Membresía</p>,
      className: "text-center",
      Cell: row => {
        return <p className={s.initial}>{row.original.membresiaNumero}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Estatus membresía</p>,
      className: "text-center",
      Cell: row => {
        return <p className={s.initial}>{row.original.membresiaEstatus}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Id Persona</p>,
      className: "text-center",
      Cell: row => {
        return <p className={s.initial}>{row.original.personaId}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Nombre</p>,
      Cell: row => {
        return <p className={s.initial}>{row.original.personaNombre}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Tipo socio</p>,
      className: "text-center",
      Cell: row => {
        return <p className={s.initial}>{row.original.personaTipo}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Tipo Membresia</p>,
      Cell: row => {
        return <p className={s.initial}>{row.original.membresiaNombre}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Tipo Mantenimiento</p>,
      Cell: row => {
        return <p className={s.initial}>{row.original.mantenimientoNombre}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Forma de pago</p>,
      className: "text-center",
      Cell: row => {
        return (
          <p className={s.initial}>{row.original.mantenimientoFormaPago}</p>
        );
      }
    },
    {
      Header: () => <p className="font-weight-bold">Estatus Persona</p>,
      className: "text-center",
      Cell: row => {
        return <p className={s.initial}>{row.original.personaEstatus}</p>;
      }
    },

    {
      Header: () => <p className="font-weight-bold">Telefonos</p>,
      Cell: row => {
        return <p className={s.initial}>{row.original.personaTelefonos}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Correos</p>,
      Cell: row => {
        return <p className={s.initial}>{row.original.personaCorreos}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Convenio</p>,
      className: "text-center",
      Cell: row => {
        return <p className={s.initial}>{row.original.convenioNombre}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Mantenimiento inicio</p>,
      className: "text-center",
      Cell: row => {
        return <p className={s.initial}>{row.original.mantenimientoInicio}</p>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Mantenimiento fin</p>,
      className: "text-center",
      Cell: row => {
        return <p className={s.initial}>{row.original.mantenimientoFin}</p>;
      }
    }
  ];

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  onClickDownloadCsvFile = () => {
    this.props.downloadReportPartnerMembershipDirectory({
      ...this.state.filters
    });
  };

  search = () => {
    infoNotification("Consultando los datos ");

    this.setState({
      onSearch: true
    });

    getReportPartnerMembershipDirectory({ ...this.state.filters })
      .then(({ data }) => {
        this.setState({ data, onSearch: false });
        successNotification("Datos consultados correctamente");
      })
      .catch(handleRequestError);
  };

  render() {
    const { clubs, data } = this.state;
    const searchAllClubsPermission = userHasPermission(
      permissionCodes.REPORT_PARTNER_MEMBERSHIP_DIRECTORY_SEARCH_ALL_CLUBS
    );

    return (
      <Container className="p-0">
        <Row>
          <Col xs={3}>
            <BaseInput
              id="club"
              name="club"
              label="Club"
              type="select"
              value={this.state.filters.clubId}
              onChange={this.onChangeFilter("clubId")}
              size={"sm"}
              options={[{ value: "", label: "Seleccionar" }].concat(
                clubs || []
              )}
              disabled={!searchAllClubsPermission}
            />
          </Col>
          <Col xs={3}>
            <BaseNumericInput
              id="personId"
              name="personId"
              label={"Id Persona"}
              value={this.state.filters.personaId}
              placeholder={"Persona Id"}
              onChange={this.onChangeFilter("personaId")}
            />
          </Col>
          <Col xs={6}>
            <BaseAlphabeticInput
              id="personName"
              name="personName"
              label={"Nombre Persona"}
              value={this.state.filters.personaNombre}
              placeholder={"Persona nombre"}
              onChange={this.onChangeFilter("personaNombre")}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={2}>
            <BaseInput
              id="partnerMembershipStatus"
              name="partnerMembershipStatus"
              label="Estatus"
              type="select"
              value={this.state.filters.estatusMembresia}
              onChange={this.onChangeFilter("estatusMembresia")}
              size={"sm"}
              options={[{ value: "", label: "Seleccionar" }].concat(
                partnerMembershipStatus || []
              )}
            />
          </Col>
          {/* <Col xs={2}>
            <BaseInput
              id="partnerMembershipType"
              name="partnerMembershipType"
              label="Tipo membresía"
              type="select"
              value={this.state.filters.tipoMembresia}
              onChange={this.onChangeFilter("tipoMembresia")}
              size={"sm"}
              options={[{ value: "", label: "Seleccionar" }].concat(
                partnerMembershipTypes || []
              )}
            />
          </Col>
 */}
          <Col xs={2}>
            <BaseInput
              id="partnerMembershipPaymentForm"
              name="partnerMembershipPaymentForm"
              label="Forma de pago"
              type="select"
              value={this.state.filters.formaPago}
              onChange={this.onChangeFilter("formaPago")}
              size={"sm"}
              options={[{ value: "", label: "Seleccionar" }].concat(
                partnerMembershipPaymentTypes || []
              )}
            />
          </Col>
          <Col xs={2}>
            <BaseInput
              id="partnerMembershipAggrement"
              name="partnerMembershipAggrement"
              label="Tiene convenio"
              type="select"
              value={this.state.filters.tieneConvenio}
              onChange={this.onChangeFilter("tieneConvenio")}
              size={"sm"}
              options={[{ value: "", label: "Seleccionar" }].concat(
                partnerMembershipAggrementTypes || []
              )}
            />
          </Col>

          <Col xs={2} className="mt-4">
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.search}
              disabled={!this.state.filters.clubId}
            >
              Buscar
            </Button>
          </Col>
        </Row>

        <BaseCsvIconDownloader
          rowCount={(data || []).length}
          executeReportDownload={downloadReportPartnerMembershipDirectory}
          getFilters={() => {
            return { ...this.state.filters };
          }}
        />

        <ReactTable
          data={data || []}
          columns={this.columns}
          className="mt-2 -striped -highlight"
          sortable={false}
          resizable={false}
          pageSize={20}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(PartnerMembershipDirectoryReport));
