import React from "react";
import { DateItem, Week } from "./types";
import { WeekComponent } from "./WeekComponent";

interface Props {
  month: number;
}

interface State {}

export class MonthHeaderComponent extends React.Component<Props, State> {
  render() {
    return (
      <>
        <div className="sw-calendar month header">{this.getMonthName()}</div>
        <div className="sw-calendar month day-name">Lun</div>
        <div className="sw-calendar month day-name">Mar</div>
        <div className="sw-calendar month day-name">Mie</div>
        <div className="sw-calendar month day-name">Jue</div>
        <div className="sw-calendar month day-name">Vie</div>
        <div className="sw-calendar month day-name">Sab</div>
        <div className="sw-calendar month day-name">Dom</div>
      </>
    );
  }

  getMonthName() {
    switch (this.props.month) {
      case 0:
        return "Enero";
      case 1:
        return "Febrero";
      case 2:
        return "Marzo";
      case 3:
        return "Abril";
      case 4:
        return "Mayo";
      case 5:
        return "Junio";
      case 6:
        return "Julio";
      case 7:
        return "Agosto";
      case 8:
        return "Septiembre";
      case 9:
        return "Octubre";
      case 10:
        return "Noviembre";
      case 11:
        return "Diciembre";
      default:
        break;
    }
  }
}
