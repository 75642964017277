exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1X0kt{background:#f3d8d8}._1RUbb{background:#bce0bc}.NpbJx{padding:0!important}._1fxnV{padding:7px 3px}._1n5zl div{overflow-x:hidden!important}._1n5zl div div div div{overflow:hidden!important}", ""]);

// exports
exports.locals = {
	"noProcesada": "_1X0kt",
	"procesada": "_1RUbb",
	"tdClear": "NpbJx",
	"field": "_1fxnV",
	"hidden": "_1n5zl"
};