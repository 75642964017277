import actionTypes from "../actions/actionTypes";
import IAction from "../../types/IAction";

import {
  infoNotification,
  errorNotification,
  successNotification,
  UPDATE_COMROMISES_BY_SELLER_REJECTED,
  UPDATE_COMROMISES_BY_SELLER_PENDING,
  UPDATE_COMROMISES_BY_SELLER_FULFILLED
} from "../../utils/notifications";

interface State {}

const initialState: State = {};

export default function schedulePatterns(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.UPDATE_COMPROMISES_BY_SELLER}_PENDING`: {
      infoNotification(UPDATE_COMROMISES_BY_SELLER_PENDING);
      return {
        ...state
      };
    }
    case `${actionTypes.UPDATE_COMPROMISES_BY_SELLER}_REJECTED`: {
      errorNotification(UPDATE_COMROMISES_BY_SELLER_REJECTED);
      return {
        ...state
      };
    }
    case `${actionTypes.UPDATE_COMPROMISES_BY_SELLER}_FULFILLED`: {
      successNotification(UPDATE_COMROMISES_BY_SELLER_FULFILLED);
      return {
        ...state
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
