import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import constants from "../../utils/constants";
import { restActionFactory } from "../../toolkit/axiosActions/axiosActionsFactory";
import { RequestArchivo } from "../components/expedientsV2controls/types";
import axios from "axios";

const EXPEDIENTES_BASE_URL = "/api/expedientes";

export const getExpediente = (idExpediente): IAction => {
  return expedientesRestAction({
    actionType: actionTypes.GET_EXPEDIENTE_DATOS,
    urlComplement: `/${idExpediente}`,
    method: "get"
  });
};

export const getExpedienteFromMembresia = (idMemebresia): IAction => {
  return expedientesRestAction({
    actionType: actionTypes.GET_EXPEDIENTE_MEMBRESIA_DATOS,
    urlComplement: `/membresia/${idMemebresia}`,
    method: "get"
  });
};

export const postRefresh = (requestArchivo: RequestArchivo): IAction => {
  return expedientesRestAction({
    actionType: actionTypes.POST_EXPEDIENTES_ARCHIVO_REFRESH,
    urlComplement: `/archivos/refresh`,
    body: requestArchivo,
    method: "post"
  });
};

export const getArchivo = (idExpediente, idItem): IAction => {
  return expedientesRestAction({
    actionType: actionTypes.GET_EXPEDIENTES_ARCHIVO_DATOS,
    urlComplement: `/archivos/${idExpediente}/item/${idItem}`,
    method: "get"
  });
};

export const getArchivoVigente = (idExpediente, idDocumento): IAction => {
  return expedientesRestAction({
    actionType: actionTypes.GET_EXPEDIENTES_ARCHIVO_VIGENTE,
    urlComplement: `/archivos/${idExpediente}/documento/${idDocumento}`,
    method: "get"
  });
};

export const postEnvioCorreo = (idExpediente, idItem): IAction => {
  return expedientesRestAction({
    actionType: actionTypes.POST_EXPEDIENTES_ENVIO_CORREO,
    urlComplement: `/archivos/${idExpediente}/item/${idItem}/correo`,
    method: "post"
  });
};

export const putStatus = (idExpediente, idItem, status): IAction => {
  return expedientesRestAction({
    actionType: actionTypes.PUT_EXPEDIENTES_ARCHIVO_STATUS,
    urlComplement: `/archivos/${idExpediente}/item/${idItem}/status/${status}`,
    method: "put"
  });
};

export const getFirmas = (idExpediente, idItem): IAction => {
  return expedientesRestAction({
    actionType: actionTypes.GET_EXPEDIENTES_ARCHIVO_FIRMAS,
    urlComplement: `/archivos/${idExpediente}/item/${idItem}/firmas`,
    method: "get"
  });
};

export const getCondensados = (filters: any): IAction => {
  return expedientesRestAction({
    actionType: actionTypes.GET_EXPEDIENTES_CONDENSADOS,
    urlComplement: `/condensados`,
    method: "get",
    params: filters
  });
};

export const putAutorizar = (idExpediente): IAction => {
  return expedientesRestAction({
    actionType: actionTypes.PUT_EXPEDIENTES_AUTORIZAR,
    urlComplement: `/${idExpediente}/autorizar`,
    method: "put"
  });
};

export const getExpedienteFromInvitado = (idPersona): IAction => {
  return expedientesRestAction({
    actionType: actionTypes.GET_EXPEDIENTE_INVITADO_DATOS,
    urlComplement: `/invitados-especiales/personas/${idPersona}`,
    method: "get"
  });
};

export const putCincelFirmas = (idExpediente, idItem): IAction => {
  return expedientesRestAction({
    actionType: actionTypes.PUT_EXPEDIENTE_ARCHIVO_CINCEL_FIRMAS,
    urlComplement: `/cincel/${idExpediente}/item/${idItem}/firmas`,
    method: "put"
  });
};

export const postCincelPregeneracion = (idMembresiaSocio): IAction => {
  return expedientesRestAction({
    actionType: actionTypes.POST_CINCEL_PREGENERACION,
    urlComplement: `/cincel/pregenerar/${idMembresiaSocio}`,
    method: "post"
  });
};

export const putVerificarCondicinalesMembresia = (
  idMembresiaSocio
): Promise<any> => {
  return axios.put(
    `${constants.BASE_URL}${EXPEDIENTES_BASE_URL}/archivos/condicionales/${idMembresiaSocio}/membresia`,
    {},
    {
      withCredentials: true
    }
  );
};

export const putVerificarCondicinalesInvitado = (idPersona): Promise<any> => {
  return axios.put(
    `${constants.BASE_URL}${EXPEDIENTES_BASE_URL}/archivos/condicionales/${idPersona}/invitado`,
    {},
    {
      withCredentials: true
    }
  );
};

// Construye una instancia de RestAction para Redux, para manipular expedientes
const expedientesRestAction = restActionFactory(
  `${constants.BASE_URL}${EXPEDIENTES_BASE_URL}`
);
