import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import { QueryResponse } from "../../types/QueryResponse";
import { Document } from "../../types-business/Documents";
import {
  infoNotification,
  errorNotification,
  successNotification,
  PENDING,
  ERROR,
  SAVE_BASIC_DOCUMENT,
  ADD_MEMBERSHIPS_DOCUMENT
} from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";
interface State {
  documents: QueryResponse<Document>;
  searchingDocuments: boolean;
  gettingBasicDocument: boolean;
  basicDocument: object;
  basicDocumentSaved: boolean;
  memberships: object;
  membershipsSaved: boolean;
  guestDocuments: object[];
  lockerDocuments: object[];
  specialGuestDocuments: object[];
}
const initialState: State = {
  documents: null,
  searchingDocuments: false,
  gettingBasicDocument: false,
  basicDocument: null,
  basicDocumentSaved: false,
  memberships: null,
  membershipsSaved: false,
  guestDocuments: null,
  lockerDocuments: null,
  specialGuestDocuments: null
};

export default function agreementsReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.GET_DOCUMENTS_SPECIAL_GUEST}_PENDING`: {
      return {
        ...state,
        specialGuestDocuments: null
      };
    }
    case `${actionTypes.GET_DOCUMENTS_SPECIAL_GUEST}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        specialGuestDocuments: null
      };
    }
    case `${actionTypes.GET_DOCUMENTS_SPECIAL_GUEST}_FULFILLED`: {
      return {
        ...state,
        specialGuestDocuments: action.payload.data
      };
    }
    case `${actionTypes.GET_DOCUMENTS_GUEST}_PENDING`: {
      return {
        ...state,
        guestDocuments: null
      };
    }
    case `${actionTypes.GET_DOCUMENTS_GUEST}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        guestDocuments: null
      };
    }
    case `${actionTypes.GET_DOCUMENTS_GUEST}_FULFILLED`: {
      return {
        ...state,
        guestDocuments: action.payload.data
      };
    }
    case `${actionTypes.GET_DOCUMENTS_LOCKER}_PENDING`: {
      return {
        ...state,
        lockerDocuments: null
      };
    }
    case `${actionTypes.GET_DOCUMENTS_LOCKER}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        lockerDocuments: null
      };
    }
    case `${actionTypes.GET_DOCUMENTS_LOCKER}_FULFILLED`: {
      return {
        ...state,
        lockerDocuments: action.payload.data
      };
    }
    case `${actionTypes.GET_DOCUMENTS}_PENDING`: {
      return {
        ...state,
        searchingDocuments: true
      };
    }
    case `${actionTypes.GET_DOCUMENTS}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        searchingDocuments: false
      };
    }
    case `${actionTypes.GET_DOCUMENTS}_FULFILLED`: {
      return {
        ...state,
        documents: action.payload.data,
        searchingDocuments: false
      };
    }
    case `${actionTypes.GET_BASIC_DOCUMENT}_PENDING`: {
      return {
        ...state,
        gettingBasicDocument: true
      };
    }
    case `${actionTypes.GET_BASIC_DOCUMENT}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        gettingBasicDocument: false
      };
    }
    case `${actionTypes.GET_BASIC_DOCUMENT}_FULFILLED`: {
      return {
        ...state,
        gettingBasicDocument: false,
        basicDocument: action.payload.data
      };
    }
    case `${actionTypes.SAVE_BASIC_DOCUMENT}_PENDING`: {
      infoNotification(PENDING);
      return {
        ...state,
        basicDocumentSaved: false
      };
    }
    case `${actionTypes.SAVE_BASIC_DOCUMENT}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        basicDocumentSaved: false
      };
    }
    case `${actionTypes.SAVE_BASIC_DOCUMENT}_FULFILLED`: {
      successNotification(SAVE_BASIC_DOCUMENT);
      return {
        ...state,
        basicDocumentSaved: true,
        basicDocument: action.payload.data
      };
    }
    case `${actionTypes.UPDATE_BASIC_DOCUMENT}_PENDING`: {
      return {
        ...state,
        basicDocumentSaved: false
      };
    }
    case `${actionTypes.UPDATE_BASIC_DOCUMENT}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        basicDocumentSaved: false
      };
    }
    case `${actionTypes.UPDATE_BASIC_DOCUMENT}_FULFILLED`: {
      return {
        ...state,
        basicDocumentSaved: true,
        basicDocument: action.payload.data
      };
    }
    case `${actionTypes.GET_MEMBERSHIPS_DOCUMENT}_PENDING`: {
      return {
        ...state,
        memberships: null
      };
    }
    case `${actionTypes.GET_MEMBERSHIPS_DOCUMENT}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        memberships: null
      };
    }
    case `${actionTypes.GET_MEMBERSHIPS_DOCUMENT}_FULFILLED`: {
      return {
        ...state,
        memberships: action.payload.data
      };
    }
    case `${actionTypes.ADD_MEMBERSHIPS_DOCUMENT}_PENDING`: {
      infoNotification(PENDING);
      return {
        ...state,
        membershipsSaved: false
      };
    }
    case `${actionTypes.ADD_MEMBERSHIPS_DOCUMENT}_REJECTED`: {
      errorNotification(readMessageBack(action, ERROR));
      return {
        ...state,
        membershipsSaved: false
      };
    }
    case `${actionTypes.ADD_MEMBERSHIPS_DOCUMENT}_FULFILLED`: {
      successNotification(ADD_MEMBERSHIPS_DOCUMENT);
      return {
        ...state,
        membershipsSaved: true
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
