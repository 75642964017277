import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import SalesSearch from "../../components/salesReport";
import * as catalogActions from "../../actions/catalogs";
import * as reportes from "../../actions/reports";
import IAction from "../../../types/IAction";

interface Props {
  sellers: object[];
  memberships: object[];
  loadingTotalSales: boolean;
  reportTotalSales: any[];
  loadingAmountSales: boolean;
  reportAmountSales: any[];
  getMemberships: () => void;
  getSellers: () => any;
  getTotalSalesReport: (fields: object) => IAction;
  getAmountSalesReport: (fields: object) => IAction;
}

interface State {}

class Sales extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    this.props.getMemberships();
    // this.props.getSellers();
  }

  render() {
    return (
      <main>
        <Helmet title="Sales" />
        <Layout>
          <SalesSearch
            memberships={this.props.memberships}
            getTotalSalesReport={this.props.getTotalSalesReport}
            getAmountSalesReport={this.props.getAmountSalesReport}
            reportTotalSales={this.props.reportTotalSales}
            loadingTotalSales={this.props.loadingTotalSales}
            reportAmountSales={this.props.reportAmountSales}
            loadingAmountSales={this.props.loadingAmountSales}
          />
        </Layout>
      </main>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getSellers: () => {
    return dispatch(catalogActions.getSellers());
  },
  getMemberships: () => {
    dispatch(catalogActions.getMembershipsTypes());
  },
  getTotalSalesReport: data => {
    dispatch(reportes.getTotalSalesReport(data));
  },
  getAmountSalesReport: data => {
    dispatch(reportes.getAmountSalesReport(data));
  }
});

const mapStateToProps = state => {
  const { catalogs, reports } = state;
  return {
    memberships: catalogs.membershipsTypes,
    sellers: catalogs.sellers,
    reportTotalSales: reports.reportTotalSales,
    loadingTotalSales: reports.loadingTotalSales,
    reportAmountSales: reports.reportAmountSales,
    loadingAmountSales: reports.loadingAmountSales
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sales);
