import React from "react";
import { getProductsClassificationsRequest } from "../../actions/catalogs";
import ProductClassification, {
  Node
} from "../../components/productsClassification";

interface Props {
  getClassificationByLevel: (level: number) => any;
  setResult: (classification: number, parents: number[]) => any;
  result: number;
  levelLabels?: string[];
  branch: number[];
  branchingMode: boolean;
  fitKidz: boolean;
  groupUser: string[];
}

interface State {
  classificationByLevelOne: Node[];
  classificationByLevelTwo: Node[];
  classificationByLevelThree: Node[];
  classificationByLevelFour: Node[];
}

export const LEVEL_ONE = 1;
export const LEVEL_TWO = 2;
export const LEVEL_THREE = 3;
export const LEVEL_FOUR = 4;

class ProductClassificationComponent extends React.Component<Props, State> {
  state = {
    classificationByLevelOne: null,
    classificationByLevelTwo: null,
    classificationByLevelThree: null,
    classificationByLevelFour: null
  };

  componentDidMount() {
    getProductsClassificationsRequest().then(({ data }) => {
      this.setState({
        classificationByLevelOne: data.filter(x => x.nivel == 1),
        classificationByLevelTwo: data.filter(x => x.nivel == 2),
        classificationByLevelThree: data.filter(x => x.nivel == 3),
        classificationByLevelFour: data.filter(x => x.nivel == 4)
      });
    });
  }

  render() {
    return (
      <ProductClassification
        levelOneClassification={this.state.classificationByLevelOne}
        levelTwoClassification={this.state.classificationByLevelTwo}
        levelThreeClassification={this.state.classificationByLevelThree}
        levelFourClassification={this.state.classificationByLevelFour}
        setResult={this.props.setResult}
        result={this.props.result}
        branch={this.props.branch}
        branchingMode={this.props.branchingMode}
        levelLabels={this.props.levelLabels}
        disabled={false}
        fitKidz={this.props.fitKidz}
        groupUser={this.props.groupUser}
      />
    );
  }
}

export default ProductClassificationComponent;
