/* tslint-disable */

import React from "react";
import * as $ from "jquery";
import FullcalendarObjectMapper from "./fullcalendarObjectMapper";

if (process.env.BROWSER) require("fullcalendar");

export default class FullCalendar extends React.Component<any, null> {
  jq = $.noConflict();
  fullcalendarObjectMapper = new FullcalendarObjectMapper();
  root = null;
  instance = null;
  date = new Date();

  componentDidMount() {
    const objectMapperSettings = this.fullcalendarObjectMapper.getSettings(
      this.props
    );
    this.instance = this.jq(`#${this.root}`).fullCalendar(objectMapperSettings);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.selectedSeller !== this.props.selectedSeller ||
      nextProps.createdAction !== this.props.createdAction
    ) {
      this.jq(`#${this.root}`).fullCalendar("refetchEvents");
    }
  }

  render() {
    this.root = this.props.id || "ID" + this.date.getTime();
    return <div id={this.root} />;
  }
}
