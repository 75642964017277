import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { parse as parseQueryParams } from "query-string";
import { ESTATUS_ENCUESTA } from "../demandList/demandListTypes";

interface Props {
  saveSurvey: (survey: object) => any;
  getTokenStatus: (token: string) => any;
  surveySuccessfullyCreated: boolean;
  match: any;
  location: any;
}

interface State {
  fields: {
    p1FueContactado: string;
    p2EsSatisfecho: string;
    p3PorQue: string;
    quejaSugerenciaId: string;
    tokenEncuesta: string;
  };
  estatusRespuesta: ESTATUS_ENCUESTA;
}

class NewDemand extends React.Component<Props, State> {
  state = {
    fields: {
      p1FueContactado: "",
      p2EsSatisfecho: "",
      p3PorQue: "",
      tokenEncuesta: null,
      quejaSugerenciaId: null
    },
    estatusRespuesta: null
  };

  componentDidMount() {
    const { tokenEncuesta, quejaSugerenciaId } = parseQueryParams(
      this.props.location.search
    );
    if (tokenEncuesta && quejaSugerenciaId) {
      this.setState({
        ...this.state,
        fields: {
          ...this.state.fields,
          tokenEncuesta,
          quejaSugerenciaId
        }
      });

      this.props
        .getTokenStatus(tokenEncuesta)
        .then(({ value: { data: { estatusRespuesta } } }) => {
          this.setState({ estatusRespuesta });
        });
    }
  }

  setFieldsCheck = key => {
    return event => {
      const value = event.target.checked;
      this.setState({
        ...this.state,
        fields: {
          ...this.state.fields,
          [key]: value
        }
      });
    };
  };

  setFields = key => {
    return event => {
      const value = event.target.value;
      this.setState({
        ...this.state,
        fields: {
          ...this.state.fields,
          [key]: value
        }
      });
    };
  };

  validState = () => {
    const fields = { ...this.state.fields };
    return fields.p3PorQue;
  };

  onClickSubmit = () => {
    this.props.saveSurvey(this.state.fields);
  };

  render() {
    const { tokenEncuesta, quejaSugerenciaId } = this.state.fields;

    return tokenEncuesta &&
      quejaSugerenciaId &&
      this.state.estatusRespuesta === ESTATUS_ENCUESTA.ENCUESTA_ENVIADA ? (
      <Container className="mt-3 p-0">
        <Row>
          <FormGroup className="col" check>
            <Label check>
              <Input
                type="checkbox"
                checked={this.state.fields.p1FueContactado}
                className="ml-0"
                onChange={this.setFieldsCheck("p1FueContactado")}
              />
              <span style={{ paddingLeft: "15px" }}>
                ¿Se han puesto en contacto con usted para comentar sobre su
                observación, ya sea vía telefónica, correo electrónico o bien en
                persona?
              </span>
            </Label>
          </FormGroup>
        </Row>
        <Row>
          <FormGroup className="col" check>
            <Label check>
              <Input
                type="checkbox"
                checked={this.state.fields.p2EsSatisfecho}
                className="ml-0"
                onChange={this.setFieldsCheck("p2EsSatisfecho")}
              />
              <span style={{ paddingLeft: "15px" }}>
                ¿Ha quedado satisfecho con la atención y respuesta que le han
                dado a cada uno de los puntos que nos envió en su observación?
              </span>
            </Label>
          </FormGroup>
        </Row>
        <Row className="mt-3">
          <Col className="form-group">
            <label>¿Por qué?:</label>
            <textarea
              name="comentarioPeticion"
              rows={6}
              id="question3SurveyFollowUp"
              value={this.state.fields.p3PorQue}
              className="form-control"
              onChange={this.setFields("p3PorQue")}
            />
          </Col>
        </Row>
        <Row>
          <Col></Col>
          <Col xs={2}>
            <Button
              className={`${s.buttonMarginTop} ${s.primaryButton}`}
              onClick={this.onClickSubmit}
              disabled={
                !this.validState() || this.props.surveySuccessfullyCreated
              }
            >
              Guardar Respuestas
            </Button>
          </Col>
        </Row>
      </Container>
    ) : (
      <Container className="p-0 mt-3">
        <div
          className="alert alert-danger"
          style={{ padding: "6px 8px" }}
          role="alert"
        >
          <h4 className="alert-heading">ERROR</h4>
          <p className="m-0">
            Link vencido/inválido o encuesta contestada anteriormente.
          </p>
        </div>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(NewDemand));
