import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import DatePicker from "../../common/DatePicker";
import s from "./styles.scss";
import { Button, Input, Label, Row, Col, Container } from "reactstrap";
import ModalFrame from "../../../../toolkit/modalFrame";
import BaseInput from "../../../../toolkit/baseInput";
import moment from "moment";
import IAction from "../../../../types/IAction";

interface Props {
  isNowOpen: boolean;
  toggle: () => any;
  addDealers: (body: object) => IAction;
  objAddDealers: any;
  loadingAddDealers: boolean;
  getDealers: (name: string, page: string, size: string) => IAction;
  modalTitle: string;
}

interface State {
  personaFisica: boolean;
  personaMoral: boolean;
  fields: any;
  hombre: boolean;
  mujer: boolean;
  load: boolean;
}

const FECHA_NACIMIENTO = "fechaNacimiento";
const FECHA_REGISTRO = "fechaRegistro";

class AddUserDealer extends React.Component<Props, State> {
  state = {
    personaFisica: false,
    personaMoral: true,
    hombre: true,
    mujer: false,
    load: false,
    fields: {
      nombrePF: "",
      paterno: "",
      materno: "",
      fechaNacimiento: "",
      nombreMoral: "",
      razonSocial: "",
      fechaRegistro: "",
      cuentaProducto: null
    }
  };

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (prevProps.objAddDealers !== this.props.objAddDealers) {
      this.setState({ load: false });
    }

    if (this.props.objAddDealers !== null && !this.state.load) {
      this.props.getDealers(null, "0", "20");
      this.setState({ load: true });
      this.props.toggle();
    }
  }

  clearData = () => {
    this.setState({
      load: false,
      fields: {
        nombrePF: "",
        paterno: "",
        materno: "",
        fechaNacimiento: "",
        nombreMoral: "",
        razonSocial: "",
        fechaRegistro: "",
        cuentaProducto: null
      }
    });
    this.props.toggle();
  };

  onChangePersona = () => {
    return event => {
      this.setState({
        personaFisica: !this.state.personaFisica,
        personaMoral: !this.state.personaMoral
      });
    };
  };

  onChangeSexo = () => {
    return event => {
      this.setState({
        hombre: !this.state.hombre,
        mujer: !this.state.mujer
      });
    };
  };

  onChangeFields = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...this.state.fields,
          [fieldName]: !!value ? value.toUpperCase() : value
        };
        return { ...state, fields };
      });
    };
  };

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: date
        };
        return { ...state, error: {}, fields };
      });
    };
  };

  addDealer = () => {
    const {
      nombrePF,
      paterno,
      materno,
      fechaNacimiento,
      nombreMoral,
      razonSocial,
      fechaRegistro,
      cuentaProducto
    } = this.state.fields;

    let obj = null;

    this.state.personaFisica
      ? (obj = {
          tipoPersonaFiscal: "FISICA",
          nombre: nombrePF,
          apellidoPaterno: paterno,
          apellidoMaterno: materno,
          fecha: fechaNacimiento,
          sexo: this.state.hombre ? "MASCULINO" : "FEMENINO",
          cuentaProducto
        })
      : (obj = {
          tipoPersonaFiscal: "MORAL",
          nombre: nombreMoral,
          fecha: fechaRegistro,
          razonSocial,
          cuentaProducto
        });
    this.props.addDealers(obj);
  };

  disableAddButton = () => {
    const {
      nombrePF,
      paterno,
      materno,
      fechaNacimiento,
      nombreMoral,
      razonSocial,
      fechaRegistro,
      cuentaProducto
    } = this.state.fields;

    return this.state.personaFisica
      ? !nombrePF || !paterno || !materno || !fechaNacimiento || !cuentaProducto
      : !nombreMoral || !razonSocial || !fechaRegistro || !cuentaProducto;
  };

  render() {
    return (
      <Container className="p-0">
        <ModalFrame
          title={this.props.modalTitle || `Agregar concesionario`}
          isOpen={this.props.isNowOpen}
          toggle={this.clearData}
          size="lg"
        >
          <Row>
            <Col xs={4} md={4} lg={4}>
              <Label className="float-right" check>
                <Input
                  type="checkbox"
                  checked={this.state.personaMoral}
                  onChange={this.onChangePersona()}
                />
                Persona moral
              </Label>
            </Col>
            <Col xs={4} md={4} lg={4}>
              <Label className="float-right" check>
                <Input
                  type="checkbox"
                  checked={this.state.personaFisica}
                  onChange={this.onChangePersona()}
                />
                Persona física
              </Label>
            </Col>
          </Row>
          {!this.state.personaMoral ? (
            <React.Fragment>
              <Row>
                <Col xs={12} md={4} lg={4} className="mt-4">
                  <BaseInput
                    label={"Nombre(s) *"}
                    name="nombrePF"
                    type="text"
                    id="nombrePF"
                    value={this.state.fields.nombrePF}
                    onChange={this.onChangeFields("nombrePF")}
                    uppercase={true}
                  />
                </Col>
                <Col xs={12} md={4} lg={4} className="mt-4">
                  <BaseInput
                    label={"Apellido paterno *"}
                    name="paterno"
                    type="text"
                    id="paterno"
                    value={this.state.fields.paterno}
                    onChange={this.onChangeFields("paterno")}
                    uppercase={true}
                  />
                </Col>
                <Col xs={12} md={4} lg={4} className="mt-4">
                  <BaseInput
                    label={"Apellido materno *"}
                    name="materno"
                    type="text"
                    id="materno"
                    value={this.state.fields.materno}
                    onChange={this.onChangeFields("materno")}
                    uppercase={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={4} lg={4} className="mt-4">
                  <DatePicker
                    label="Fecha de nacimiento *"
                    name={FECHA_NACIMIENTO}
                    id={FECHA_NACIMIENTO}
                    selected={this.state.fields[FECHA_NACIMIENTO]}
                    onChange={this.onChangeDate(FECHA_NACIMIENTO)}
                  />
                </Col>

                <Col xs={12} md={2} lg={2} className="mt-4">
                  <Label className="text-center" check>
                    <Input
                      type="checkbox"
                      checked={this.state.hombre}
                      onChange={this.onChangeSexo()}
                    />
                    Hombre
                  </Label>
                </Col>
                <Col xs={12} md={2} lg={2} className="mt-4">
                  <Label className="text-center" check>
                    <Input
                      type="checkbox"
                      checked={this.state.mujer}
                      onChange={this.onChangeSexo()}
                    />
                    Mujer
                  </Label>
                </Col>
                <Col xs={12} md={4} lg={4} className="mt-4">
                  <BaseInput
                    label={"Cuenta producto *"}
                    type="text"
                    name="cuentaProductoPF"
                    id="cuentaProductoPF"
                    value={this.state.fields.cuentaProducto}
                    onChange={this.onChangeFields("cuentaProducto")}
                    maxlength={12}
                    uppercase={true}
                  />
                </Col>
              </Row>
            </React.Fragment>
          ) : (
            <Row>
              <Col xs={12} md={4} lg={4} className="mt-4">
                <BaseInput
                  label={"Nombre *"}
                  name="nombreMoral"
                  type="text"
                  id="nombreMoral"
                  value={this.state.fields.nombreMoral}
                  onChange={this.onChangeFields("nombreMoral")}
                  uppercase={true}
                />
              </Col>
              <Col xs={12} md={8} lg={8} className="mt-4">
                <BaseInput
                  label={"Razón Social *"}
                  name="razonSocial"
                  type="text"
                  id="razonSocial"
                  value={this.state.fields.razonSocial}
                  onChange={this.onChangeFields("razonSocial")}
                  uppercase={true}
                />
              </Col>
              <Col xs={12} md={4} lg={4} className="mt-4">
                <DatePicker
                  label="Fecha de registro *"
                  name={FECHA_REGISTRO}
                  id={FECHA_REGISTRO}
                  selected={this.state.fields[FECHA_REGISTRO]}
                  onChange={this.onChangeDate(FECHA_REGISTRO)}
                />
              </Col>
              <Col xs={12} md={4} lg={4} className="mt-4">
                <BaseInput
                  label={"Cuenta producto *"}
                  type="text"
                  name="cuentaProductoPM"
                  id="cuentaProductoPM"
                  value={this.state.fields.cuentaProducto}
                  onChange={this.onChangeFields("cuentaProducto")}
                  maxlength={12}
                  uppercase={true}
                />
              </Col>
            </Row>
          )}
          <Row className="mt-4">
            <Col xs={6} md={6} lg={6}>
              <Button block color="secondary" onClick={this.clearData}>
                CANCELAR
              </Button>
            </Col>
            <Col xs={6} md={6} lg={6}>
              <Button
                block
                color="secondary"
                onClick={this.addDealer}
                disabled={this.disableAddButton()}
              >
                AGREGAR
              </Button>
            </Col>
          </Row>
        </ModalFrame>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(AddUserDealer));
