import React from "react";
import { connect } from "react-redux";
import * as documentActions from "../../actions/documents";
import * as productActions from "../../actions/products";
import AddMembershipsToDocument from "../../components/addMembershipsToDocument";
import { withRouter } from "react-router-dom";

interface Props {
  addMembershipsToDocument: (id: string, memberships: object[]) => any;
  successfulSave: boolean;
  getMembershipsDocument: (id: string) => any;
  getMemberships: () => any;
  membershipsDocument: object[];
  memberships: object[];
  onClickSave: (e: object) => any;
  match: any;
}

class AddMembershipsDocumentPage extends React.Component<Props, void> {
  componentDidMount() {
    this.props.getMemberships();
    const { id } = this.props.match.params;
    if (id) this.props.getMembershipsDocument(id);
  }

  setMemberships = memberships => {
    const { id } = this.props.match.params;
    this.props.addMembershipsToDocument(id, memberships);
  };

  render() {
    return (
      <div className="h-100 w-100 p-0 w-0">
        {this.props.memberships && (
          <AddMembershipsToDocument
            successfulSave={this.props.successfulSave}
            membershipsDocument={this.props.membershipsDocument}
            memberships={this.props.memberships}
            onClickSave={this.props.onClickSave}
            setMemberships={this.setMemberships}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addMembershipsToDocument: (id: string, memberships: object[]) => {
    dispatch(documentActions.addMembershipsToDocument(id, memberships));
  },
  getMembershipsDocument: (id: string) => {
    dispatch(documentActions.getMembershipsDocument(id));
  },
  getMemberships: () => {
    dispatch(productActions.getMemberships());
  }
});

const mapStateToProps = state => {
  const { documents, products } = state;

  return {
    successfulSave: documents.membershipsSaved,
    membershipsDocument: documents.memberships,
    memberships:
      products.memberships && products.memberships.content
        ? products.memberships.content
        : null
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddMembershipsDocumentPage)
);
