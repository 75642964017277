import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import moment from "moment";
import { Container, Row, Col, Input, Button, Form } from "reactstrap";
import s from "./styles.scss";
import cloneDeep from "lodash.clonedeep";
import uppercase from "lodash.uppercase";
import BaseInput from "../../../toolkit/baseInput";
import CONSTANTS from "../../../utils/constants";

interface Props {
  getProducts: (args: object) => any;
  activate: (products: object[]) => any;
  products: object[];
  satProducts: { id: string; nombre: string }[];
  satUnits: { id: string; nombre: string }[];
  sellers: any;
  reload: boolean;
  loading: boolean;
}

const formatDate = date => moment(date).format("DD/MM/YYYY");
const getCleanChecked = () => ({});

const productTypes = [
  {
    id: "PRODUCTO",
    nombre: "Producto"
  },
  {
    id: "MEMBRESIA",
    nombre: "Membresia"
  },
  {
    id: "MANTENIMIENTO",
    nombre: "Mantenimiento"
  }
];
class ProductsActivation extends React.Component<Props> {
  renderEditable = (cellInfo, options) => {
    const { index, column } = cellInfo;
    const inputName = column.id;
    let opt = [
      {
        id: "",
        nombre: "Seleccionar"
      }
    ].concat(
      options
        ? options.map(({ id, nombre }) => ({
            id,
            nombre
          }))
        : []
    );
    return (
      <div className={`${s.cell} ${s.renderEditable}`}>
        <Input
          type="select"
          className={s.renderEditableInput}
          value={this.state.data[index][`${inputName}`]}
          onChange={(e: any) => {
            e.preventDefault();
            const tmpData = [...this.state.data];
            tmpData[index][`${inputName}`] = e.target.value.replace(
              /[^0-9]/gi,
              ""
            );
            this.setState({ tmpData });
          }}
        >
          {opt.map((option, index) => {
            return (
              <option key={index} value={option.id}>
                {option.nombre}
              </option>
            );
          })}
        </Input>
      </div>
    );
  };

  state = {
    data: [],
    selectAll: false,
    columns: [
      {
        Header: _ => {
          return (
            <div className={`${s.checkboxSelectAll} w-100 text-center`}>
              <Input
                type="checkbox"
                checked={this.state.selectAll}
                onChange={e => this.handleSelectAll(Boolean(e.target.checked))}
              />
            </div>
          );
        },
        id: "seleccionado",
        minWidth: 40,
        maxWidth: 40,
        accessor: product => {
          return (
            <div className={`${s.checkboxContainer} ${s.myStyle}`}>
              <Row>
                <Col>
                  <Input
                    type="checkbox"
                    className={`${s.renderEditableCheckBox}`}
                    checked={
                      this.state.checked[
                        `${product.idBase}-${product.tipoProducto}`
                      ]
                    }
                    onChange={e =>
                      this.handleCheck(
                        `${product.idBase}-${product.tipoProducto}`,
                        e
                      )
                    }
                  />
                </Col>
              </Row>
            </div>
          );
        }
      },
      {
        Header: "Tipo Producto",
        id: "tipoProducto",
        accessor: ({ tipoProducto }) =>
          tipoProducto === "PRODUCTO"
            ? "PRODUCTO"
            : tipoProducto === "MEMBRESIA"
            ? "MEMBRESIA"
            : tipoProducto === "MANTENIMIENTO"
            ? "MANTENIMIENTO"
            : "",
        maxWidth: 130
      },
      {
        Header: "Nombre",
        accessor: "nombre",
        Cell: row => {
          return (
            <div className="text-wrap">{uppercase(row.original.nombre)}</div>
          );
        }
      },
      {
        id: "usuarioSolicitante",
        Header: "Usuario Solicitante",
        maxWidth: 130,
        Cell: row => {
          return (
            <div className="text-wrap">
              {uppercase(row.original.usuarioSolicitanteNombre)}
            </div>
          );
        }
      },
      {
        id: "fechaSolicitud",
        Header: "Fecha de Solicitud",
        accessor: ({ fechaSolicitud }) => formatDate(fechaSolicitud),
        maxWidth: 130
      },
      {
        Header: "Producto SAT",
        accessor: "idProductoSAT",
        Cell: cellInfo =>
          this.renderEditable(
            cellInfo,
            this.props.satProducts.map(({ id, nombre: nom, claveProducto }) => {
              return { id, nombre: `${claveProducto} - ${nom}` };
            })
          )
      },
      {
        Header: "Unidad SAT",
        accessor: "idUnidadSAT",
        Cell: cellInfo =>
          this.renderEditable(
            cellInfo,
            this.props.satUnits.map(({ id, nombre: nom, claveUnidad }) => {
              return { id, nombre: `${claveUnidad} - ${nom}` };
            })
          )
      }
    ],
    checked: getCleanChecked(),
    errorData: false,
    errorEmptyData: false,
    filters: {
      estatus: "INACTIVO",
      tipoProducto: "",
      usuarioSolicitante: "",
      nombre: null
    }
  };

  componentDidMount() {
    this.props.getProducts(this.state.filters);
  }

  componentDidUpdate() {
    if (this.props.reload) {
      this.props.getProducts(this.state.filters);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.products && nextProps.products) {
      const state = cloneDeep(this.state);
      this.setState({
        ...state,
        data: nextProps.products,
        checked: getCleanChecked()
      });
    }
  }

  search = () => {
    this.props.getProducts(this.state.filters);
    this.handleSelectAll(false);
  };

  handleSelectAll = value => {
    const checked = this.state.checked;
    const data = cloneDeep(this.state.data);
    this.state.data.forEach(({ idBase, tipoProducto }) => {
      checked[`${idBase}-${tipoProducto}`] = value;
    });
    this.setState(state => ({
      ...state,
      data,
      checked,
      selectAll: value
    }));
  };

  handleCheck = (id, e) => {
    const value = Boolean(e.target.checked);
    const checked = this.state.checked;
    const data = cloneDeep(this.state.data);
    checked[id] = value;
    this.setState(state => ({
      ...state,
      data,
      checked,
      errorData: false,
      errorEmptyData: false
    }));
  };

  onChangeFilter = (event, name) => {
    const value = event.target.value;
    const filters = {
      ...this.state.filters,
      [`${name}`]: value ? value : null
    };
    this.setState(state => ({
      ...state,
      filters
    }));
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState(state => {
      return {
        ...state,
        errorData: false,
        errorEmptyData: false
      };
    });
    const checked = this.state.checked;
    const data: any = this.state.data;
    let dataToActive = data
      .filter(
        ({ idBase, tipoProducto }) => checked[`${idBase}-${tipoProducto}`]
      )
      .map(product => ({
        ...product,
        idProductoSAT: product.idProductoSAT,
        idUnidadSAT: product.idUnidadSAT
      }));
    let checkData = data.filter(
      ({ idBase, tipoProducto, idProductoSAT, idUnidadSAT }) =>
        checked[`${idBase}-${tipoProducto}`] &&
        (idProductoSAT !== "" && typeof idProductoSAT !== "undefined") &&
        (idUnidadSAT !== "" && typeof idUnidadSAT !== "undefined")
    );
    if (dataToActive.length === checkData.length && dataToActive.length > 0) {
      this.props.activate(dataToActive);
    } else {
      const errorName =
        dataToActive.length === 0 ? "errorEmptyData" : "errorData";
      this.setState(state => {
        return {
          ...state,
          [`${errorName}`]: true
        };
      });
    }
  };

  render() {
    return (
      <Container className="p-0 mt-3">
        <Form
          onSubmit={e => {
            e.preventDefault();
            this.search();
          }}
        >
          <Row>
            <Col style={{ paddingBottom: "5px" }} xs={12} md={3} lg={3}>
              <BaseInput
                label="Tipo de Producto"
                name="tipoProducto"
                id="tipoProducto"
                type="select"
                placeholder="Seleccionar Tipo de Producto"
                selected={this.state.filters.tipoProducto}
                onChange={e => this.onChangeFilter(e, "tipoProducto")}
                size={"sm"}
                options={[
                  { value: "", label: "Seleccionar Tipo de Producto" }
                ].concat(
                  productTypes.map(option => ({
                    value: option.id,
                    label: option.nombre
                  }))
                )}
              />
            </Col>
            {this.props.sellers && (
              <Col style={{ paddingBottom: "5px" }} xs={12} md={3} lg={4}>
                <BaseInput
                  label="Usuario Solicitante"
                  name="usuarioSolicitante"
                  id="usuarioSolicitante"
                  type="select"
                  placeholder="Seleccionar Usuario Solicitante"
                  selected={this.state.filters.usuarioSolicitante}
                  onChange={e => this.onChangeFilter(e, "usuarioSolicitante")}
                  size={"sm"}
                  options={[
                    { value: "", label: "Seleccionar Usuario Solicitante" }
                  ].concat(
                    this.props.sellers.map(option => ({
                      value: option.userId,
                      label: `${option.name} ${option.firstSurname ||
                        ""} ${option.secondSurname || ""}`
                    }))
                  )}
                />
              </Col>
            )}
            <Col style={{ paddingBottom: "5px" }} xs={12} md={3} lg={3}>
              <BaseInput
                label="Nombre"
                name="productName"
                id="productName"
                type="text"
                placeholder="Nombre"
                value={this.state.filters.nombre}
                onChange={e => this.onChangeFilter(e, "nombre")}
                size={"sm"}
              />
            </Col>
            <Col xs={12} md={3} lg={2}>
              <Button
                size="sm"
                className={`${s.searchButton} ${s.primaryButton} float-right btn-block`}
                onClick={this.search}
              >
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
        <ReactTable
          manual
          data={this.props.loading ? [] : this.state.data}
          columns={this.state.columns}
          className="w-100 -striped -highlight"
          loading={this.props.loading}
          sortable={false}
          showPagination={false}
          defaultPageSize={CONSTANTS.DEFAULT_PAGE_SIZE}
          resizable={true}
          style={{
            minHeight: CONSTANTS.TABLES_HEIGHT
          }}
        />
        {this.state.errorEmptyData && (
          <div className={`${s.errorSpan} pl-2 pt-2 mt-4`}>
            {"Necesitas seleccionar los productos a activar"}
          </div>
        )}
        {this.state.errorData && (
          <div className={`${s.errorSpan} pl-2 pt-2 mt-4`}>
            {
              "No puedes activar productos sin Producto SAT o Unidad SAT , verifica los datos"
            }
          </div>
        )}
        <div className="text-right pr-2 pt-2 mt-4 mb-4">
          <Button
            className={`${s.buttonMarginTop} ${s.primaryButton}`}
            onClick={e => this.handleSubmit(e)}
            size="sm"
          >
            {"Activar"}
          </Button>
        </div>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(ProductsActivation));
