import moment from "moment";

export const catalogoMonth = [
  { value: "", label: "Seleccionar mes" },
  { value: "1", label: "ENERO" },
  { value: "2", label: "FEBRERO" },
  { value: "3", label: "MARZO" },
  { value: "4", label: "ABRIL" },
  { value: "5", label: "MAYO" },
  { value: "6", label: "JUNIO" },
  { value: "7", label: "JULIO" },
  { value: "8", label: "AGOSTO" },
  { value: "9", label: "SEPTIEMBRE" },
  { value: "10", label: "OCTUBRE" },
  { value: "11", label: "NOVIEMBRE" },
  { value: "12", label: "DICIEMBRE" }
];

export const getYearCatalog = () => {
  const currentYear = moment().year();
  const catalog = [{ value: 0, label: "Seleccionar año" }];
  const nYears = 3;

  for (let i = currentYear; i > currentYear - nYears; i--) {
    catalog.push({
      value: i,
      label: i.toString()
    });
  }

  return catalog;
};
