import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import IAction from "../../../types/IAction";
import Layout from "../../components/layout";
import SportProgram from "../../components/sportProgram";
import { getClubs, getSellers } from "../../actions/catalogs";
import { searchSportsProgramsInstances } from "../../actions/events";
import { getProgramasDeportivos } from "../../actions/catalogs";

interface Props {
  clubs: object[];
  sellers: object[];
  sportsProducts: { id: string; nombre: string }[];
  sportsProgramsInstances: object[];
  gettingSportsProgramsInstances: boolean;
  getClubs: () => IAction;
  getSellers: () => IAction;
  getProgramasDeportivos: () => any;
  searchSportsProgramsInstances: (fields: object) => IAction;
  userContext: any;
}
interface State {}

class SportsProgramsRegistrationPage extends React.Component<Props, State> {
  componentDidMount() {
    this.props.getClubs();
    this.props.getSellers();
    this.props.getProgramasDeportivos();
  }

  render() {
    return (
      <main>
        <Helmet title="Registro de programas deportivos" />
        <Layout>
          <SportProgram
            clubs={this.props.clubs}
            sellers={this.props.sellers}
            sportProgramsProducts={this.props.sportsProducts}
            sportsProgramsInstances={this.props.sportsProgramsInstances}
            gettingSportsProgramsInstances={
              this.props.gettingSportsProgramsInstances
            }
            searchSportsProgramsInstances={
              this.props.searchSportsProgramsInstances
            }
            updated={() => {}}
            userContext={this.props.userContext}
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({ catalogs, sportsProducts, user }) => ({
  clubs: catalogs.clubs,
  sellers: catalogs.sellers,
  sportsProducts: catalogs.sportsProducts,
  sportsProgramsInstances: sportsProducts.sportsProgramsInstances,
  gettingSportsProgramsInstances: sportsProducts.gettingSportsProgramsInstances,
  userContext: user.userContext
});

const mapDispatchToProps = {
  getClubs,
  getSellers,
  getProgramasDeportivos,
  searchSportsProgramsInstances
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SportsProgramsRegistrationPage);
