import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import ProductClassification from "../../components/productsClassification";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  infoNotification,
  errorNotification,
  successNotification
} from "../../../utils/notifications";
import {
  createClassificationNode,
  updateClassificationNode
} from "../../actions/products";
import {
  DEFAULT_PRODUCTS_THREE_LEVEL,
  DEFAULT_ID
} from "../../../utils/constants";

interface ClassificationNode {
  id: number;
  codigo: string;
  descripcion: string;
  nivel: number;
  estatus: boolean;
  padre: number;
  tipoConfiguracion: string;
  padreNombre: string;
  aplicaDevengo: boolean;
  cuentaContableIngreso: object;
  cuentaContableDevengo: object;
  esquemaDevengamiento: object;
}

const OP_MODES = {
  NONE: "NONE",
  CREATE: "CREATE",
  EDIT: "EDIT"
};

interface Props {
  getClassificationByLevel: (level: number) => any;
  getAccountingAccounts: () => any;
  getCatalogAccrual: () => any;
  location: object;
  catalogAccrual: any;
  accountingAccounts: any;
}

interface State {
  lastSelectedClassificationId: number;
  lastSelectedClassificationParents: number[];
  selectedClassifications: any;
  searching: boolean;
  classifications: ClassificationNode[];
  classificationsFiltered: ClassificationNode[];

  //modal
  showModal: boolean;
  selectedClassificationNode: ClassificationNode;
  mode: string;
}

export interface Node {
  id: number;
  nombre: string;
}

export interface Catalog {
  cuentaContableId: number;
  nombre: string;
  numeroCuenta: string;
}

const transformNodeList = (list: Node[], text: string) => {
  return [
    {
      value: DEFAULT_ID,
      label: text
    }
  ].concat(
    (list || []).map(option => ({
      value: option.id,
      label: option.nombre.toUpperCase()
    }))
  );
};

const transformCatalogList = (list: Catalog[], text: string) => {
  return [
    {
      value: DEFAULT_ID,
      label: text
    }
  ].concat(
    (list || []).map(option => ({
      value: option.cuentaContableId,
      label: option.numeroCuenta + " - " + option.nombre.toUpperCase()
    }))
  );
};

class AdminProductsClassifications extends React.Component<Props, State> {
  state = {
    lastSelectedClassificationId: -1,
    lastSelectedClassificationParents: [],
    selectedClassifications: {
      level1: 0,
      level2: 0,
      level3: 0,
      level4: 0
    },
    searching: false,
    classifications: [],
    classificationsFiltered: [],
    showModal: false,
    selectedClassificationNode: {} as ClassificationNode,
    mode: OP_MODES.NONE
  };

  componentDidMount() {
    this.getAllClassifications();
    this.props.getAccountingAccounts();
    this.props.getCatalogAccrual();
  }

  async getAllClassifications() {
    this.props.getClassificationByLevel(-1).then(({ value: { data } }) => {
      const parentFullPath = this.getParentsFullName(
        this.state.lastSelectedClassificationParents
      );
      const filtered = [...data].filter(
        x => x.nivel == DEFAULT_PRODUCTS_THREE_LEVEL
      );
      filtered.forEach(f => {
        f.padreNombre = parentFullPath;
      });
      this.setState({
        classifications: [...data],
        classificationsFiltered: filtered
      });
    });
  }

  onChangeClassificationFilter = (classificationId, parents, level) => {
    const { selectedClassifications } = this.state;
    selectedClassifications["level" + level] = classificationId;
    if (level < 4) {
      for (let i = level + 1; i <= 4; i++) {
        selectedClassifications["level" + i] = 0;
      }
    }

    let classificationIdFilter = classificationId;
    if (classificationIdFilter == 0) {
      parents.splice(0, 1);
      if (level - 1 >= 1) {
        classificationIdFilter = selectedClassifications["level" + (level - 1)];
        parents.splice(0, 0, classificationIdFilter);
      } else {
        classificationIdFilter = -1;
      }
    } else {
      parents.splice(0, 0, classificationId);
    }

    if (
      selectedClassifications.level3 == 0 &&
      selectedClassifications.level2 > 0 &&
      classificationId == 0
    ) {
      classificationId = selectedClassifications.level2;
    } else if (selectedClassifications.level2 == 0 && classificationId == 0) {
      classificationId = selectedClassifications.level1;
    }

    if (selectedClassifications.level1 == 0) {
      this.setState({
        selectedClassifications: selectedClassifications,
        lastSelectedClassificationParents: parents
      });
      this.getAllClassifications();
    } else {
      const parentFullPath = this.getParentsFullName(parents);
      let filtered = [];
      if (
        selectedClassifications.level1 > 0 &&
        selectedClassifications.level2 == 0
      ) {
        let dato = [];
        this.state.classifications.forEach(element => {
          if (element.padre == classificationId) dato.push(element.id);
        });
        filtered = [...this.state.classifications].filter(x =>
          dato.includes(x.padre)
        );
        filtered.forEach(f => {
          f.padreNombre = parentFullPath;
        });
      } else {
        filtered = [...this.state.classifications].filter(
          x => x.padre == classificationId
        );
        filtered.forEach(f => {
          f.padreNombre = parentFullPath;
        });
      }
      this.setState({
        lastSelectedClassificationId: classificationIdFilter,
        lastSelectedClassificationParents: parents,
        selectedClassifications,
        classificationsFiltered: filtered
      });
    }
  };

  getParentsFullName = (parents: number[]) => {
    const fullName: string[] = [];
    parents.forEach(p => {
      const pObj: any = this.state.classifications.filter(x => x.id == p);
      if (!!pObj.length) {
        fullName.push(pObj[0].descripcion);
      }
    });
    return !!fullName.length ? fullName.reverse().join("  /  ") : "ROOT";
  };

  search = () => {};

  onClickNewClassification = () => {
    this.setState({
      selectedClassificationNode: {
        padre: -1,
        nivel: 1,
        estatus: true,
        cuentaContableDevengo: null,
        cuentaContableIngreso: null,
        esquemaDevengamiento: null
      } as ClassificationNode,
      mode: OP_MODES.CREATE
    });
    this.toggleModal();
  };

  onClickEditClassification = (classificationItem: ClassificationNode) => {
    if (!classificationItem.cuentaContableDevengo) {
      classificationItem.cuentaContableDevengo = null;
    }
    if (!classificationItem.cuentaContableIngreso) {
      classificationItem.cuentaContableIngreso = null;
    }
    if (!classificationItem.esquemaDevengamiento) {
      classificationItem.esquemaDevengamiento = null;
    }
    this.setState({
      selectedClassificationNode: { ...classificationItem },
      mode: OP_MODES.EDIT
    });
    this.toggleModal();
  };

  sortClassifications = array => {
    return array.sort((a: any, b: any) => b.nivel - a.nivel);
  };

  //modal

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  clearEditObjectState = () => {
    this.setState({
      selectedClassificationNode: {} as ClassificationNode,
      mode: OP_MODES.NONE
    });
  };

  handleSaveClassificationNode = e => {
    e.preventDefault();
    if (
      !this.state.selectedClassificationNode ||
      (!this.state.selectedClassificationNode.padre ||
        !this.state.selectedClassificationNode.codigo ||
        !this.state.selectedClassificationNode.descripcion ||
        !this.state.selectedClassificationNode.nivel) ||
      (this.state.selectedClassificationNode.aplicaDevengo &&
        !this.state.selectedClassificationNode.esquemaDevengamiento &&
        !this.state.selectedClassificationNode.cuentaContableDevengo)
    ) {
      errorNotification("Debe llenar los campos obligatorios.");
      return;
    }
    switch (this.state.mode) {
      case OP_MODES.CREATE:
        infoNotification("Creando nodo de clasificación de producto...");

        createClassificationNode({ ...this.state.selectedClassificationNode })
          .then(({ data }: any) => {
            successNotification(
              "El nodo de clasificación se creó correctamente"
            );

            this.handleCancelEditClassificationNode();
            this.getAllClassifications();
          })
          .catch((err: any) => {
            errorNotification(
              "Ocurrió un error al guardar los datos del nodo de clasificación"
            );
          });

        break;
      case OP_MODES.EDIT:
        infoNotification("Actualizando el nodo de clasificación...");

        updateClassificationNode(this.state.selectedClassificationNode.id, {
          ...this.state.selectedClassificationNode
        })
          .then(() => {
            successNotification(
              "El nodo de clasificación se actualizó correctamente"
            );
            this.handleCancelEditClassificationNode();
            this.getAllClassifications();
          })
          .catch(() => {
            errorNotification(
              "Ocurrió un error al actualizar los datos del nodo de clasificación"
            );
          });

        break;
      default:
        this.handleCancelEditClassificationNode();
        break;
    }
  };

  handleCancelEditClassificationNode = () => {
    this.toggleModal();
    this.clearEditObjectState();
  };

  onChangeFactory = (fieldName, f) => {
    return event => {
      const value = f(event);
      this.setState({
        ...this.state,
        selectedClassificationNode: {
          ...this.state.selectedClassificationNode,
          [fieldName]: value
        }
      });
    };
  };

  onChangeCountId = (fieldName, f) => {
    return event => {
      const value = f(event);
      this.setState({
        ...this.state,
        selectedClassificationNode: {
          ...this.state.selectedClassificationNode,
          [fieldName]: value == 0 ? null : { cuentaContableId: value }
        }
      });
    };
  };

  onChangeRule = (fieldName, f) => {
    return event => {
      const value = f(event);
      this.setState({
        ...this.state,
        selectedClassificationNode: {
          ...this.state.selectedClassificationNode,
          [fieldName]: value == 0 ? null : { id: value }
        }
      });
    };
  };

  onChangeCheckedFactory = fieldName => {
    this.setState({
      ...this.state,
      selectedClassificationNode: {
        ...this.state.selectedClassificationNode,
        [fieldName]: !this.state.selectedClassificationNode[fieldName]
      }
    });
  };

  onChangeModalClassification = (classificationId, parents, level) => {
    this.setState({
      ...this.state,
      selectedClassificationNode: {
        ...this.state.selectedClassificationNode,
        padre: classificationId,
        nivel: level + 1
      }
    });
  };

  render() {
    let columns = [
      {
        Header: () => <p className="font-weight-bold">Padre(s)</p>,
        id: 0,
        width: 280,
        accessor: "fullPath",
        Cell: row => {
          return <div className="text-wrap">{row.original.padreNombre}</div>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Código</p>,
        id: 0,
        width: 200,
        accessor: "code",
        Cell: row => {
          return <div className="pl-2">{row.original.codigo}</div>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Descripción</p>,
        id: 1,
        width: 400,
        accessor: "description",
        Cell: row => {
          return <div className="text-wrap">{row.original.descripcion}</div>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Nivel</p>,
        id: 2,
        width: 80,
        accessor: "level",
        Cell: row => {
          return <div className="text-center">{row.original.nivel}</div>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Activo</p>,
        id: 3,
        width: 80,
        accessor: "active",
        Cell: row => {
          return (
            <div className="text-center">
              {!!row.original.estatus ? "SI" : "NO"}
            </div>
          );
        }
      },
      {
        Header: () => <p className="font-weight-bold">Aplica devengo</p>,
        id: 4,
        width: 100,
        accessor: "devengo",
        Cell: row => {
          return (
            <div className="text-center">
              {!!row.original.aplicaDevengo ? "SI" : "NO"}
            </div>
          );
        }
      },
      {
        Header: () => <p className="font-weight-bold">Cuenta ingreso</p>,
        id: 5,
        width: 200,
        accessor: "cuentaContableIngreso",
        Cell: row => {
          return (
            <div className="text-center">
              {row.original.cuentaContableIngreso
                ? row.original.cuentaContableIngreso.numeroCuenta +
                  " - " +
                  row.original.cuentaContableIngreso.nombre.toUpperCase()
                : null}
            </div>
          );
        }
      },
      {
        Header: () => <p className="font-weight-bold">Cuenta devengo</p>,
        id: 6,
        width: 200,
        accessor: "devengo",
        Cell: row => {
          return (
            <div className="text-center">
              {row.original.aplicaDevengo && row.original.cuentaContableDevengo
                ? row.original.cuentaContableDevengo.numeroCuenta +
                  " - " +
                  row.original.cuentaContableDevengo.nombre.toUpperCase()
                : null}
            </div>
          );
        }
      },
      {
        Header: () => <p className="font-weight-bold">Esquema devengamiento</p>,
        id: 7,
        width: 200,
        accessor: "devengo",
        Cell: row => {
          return (
            <div className="text-center">
              {row.original.aplicaDevengo && row.original.esquemaDevengamiento
                ? row.original.esquemaDevengamiento.id +
                  " - " +
                  row.original.esquemaDevengamiento.nombre.toUpperCase()
                : null}
            </div>
          );
        }
      },
      {
        Header: () => {
          return (
            <div className="text-center">
              <Button
                title="Crear"
                className={`${s.actionHeaderIcon}`}
                onClick={this.onClickNewClassification}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          );
        },
        id: -300,
        width: 80,
        accessor: "actions",
        Cell: row => {
          return (
            <div className="text-center">
              <Button
                title="Editar"
                className={`${s.actionHeaderIcon} mr-2`}
                onClick={() => {
                  this.onClickEditClassification(row.original);
                }}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            </div>
          );
        }
      }
    ];
    return (
      <Container className="p-0 mt-3">
        <Row>
          <Col style={{ paddingBottom: "5px" }}>
            <ProductClassification
              setResult={this.onChangeClassificationFilter}
              levelOneClassification={(this.state.classifications || []).filter(
                x => x.nivel == 1
              )}
              levelTwoClassification={(this.state.classifications || []).filter(
                x => x.nivel == 2
              )}
              levelThreeClassification={(
                this.state.classifications || []
              ).filter(x => x.nivel == 3)}
              levelFourClassification={(
                this.state.classifications || []
              ).filter(x => x.nivel == 4)}
            ></ProductClassification>
          </Col>
        </Row>
        <ReactTable
          className="mt-5 -striped -highlight"
          data={this.state.classificationsFiltered}
          columns={columns}
          sortable={false}
          resizable={false}
          pageSize={
            !!this.state.classificationsFiltered &&
            !!this.state.classificationsFiltered.length
              ? this.state.classificationsFiltered.length + 1
              : 5
          }
          showPagination={false}
          loading={this.state.searching}
        />

        <Modal
          isOpen={this.state.showModal}
          toggle={this.toggleModal}
          fade={true}
          size="lg"
          backdrop="static"
          keyboard={false}
        >
          <ModalHeader toggle={this.toggleModal}>
            {this.state.mode === "CREATE" && (
              <span className="font-weight-bold">
                Creación de clasificación de producto
              </span>
            )}
            {this.state.mode === "EDIT" && (
              <span className="font-weight-bold">
                Edición de clasificación de producto
              </span>
            )}
          </ModalHeader>
          <ModalBody>
            <div className="mt-2">
              <Form>
                <Row className="mb-3">
                  <Col xs={12}>
                    <Label className="d-block font-weight-bold">
                      Clasificación padre *
                    </Label>
                    <ProductClassification
                      setResult={this.onChangeModalClassification}
                      result={this.state.selectedClassificationNode.padre}
                      levelOneClassification={(
                        this.state.classifications || []
                      ).filter(x => x.nivel == 1)}
                      levelTwoClassification={(
                        this.state.classifications || []
                      ).filter(x => x.nivel == 2)}
                      levelThreeClassification={(
                        this.state.classifications || []
                      ).filter(x => x.nivel == 3)}
                      levelFourClassification={(
                        this.state.classifications || []
                      ).filter(x => x.nivel == 4)}
                      disabled={this.state.mode == OP_MODES.EDIT}
                    ></ProductClassification>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={6} md={4} lg={3}>
                    <BaseInput
                      label="Código *"
                      name="classificationCode"
                      type="text"
                      id="classificationCode"
                      placeholder="Código"
                      value={this.state.selectedClassificationNode.codigo || ""}
                      onChange={this.onChangeFactory(
                        "codigo",
                        e => e.target.value
                      )}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={8} lg={9}>
                    <BaseInput
                      label="Descripción *"
                      name="classificationDesc"
                      type="text"
                      id="classificationDesc"
                      placeholder="Descripción"
                      value={
                        this.state.selectedClassificationNode.descripcion || ""
                      }
                      onChange={this.onChangeFactory(
                        "descripcion",
                        e => e.target.value
                      )}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={4} lg={3}>
                    <FormGroup check className="mt-2">
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={
                            this.state.selectedClassificationNode.estatus
                          }
                          onChange={e => this.onChangeCheckedFactory("estatus")}
                        />
                        Activo
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col xs={12} sm={6} md={8} lg={9}>
                    <BaseInput
                      label="Cuenta contable ingreso"
                      name="cuentaContableIngreso"
                      type="select"
                      id="cuentaContableIngreso"
                      placeholder="Cuenta contable ingreso"
                      value={
                        (this.state.selectedClassificationNode &&
                          this.state.selectedClassificationNode
                            .cuentaContableIngreso &&
                          this.state.selectedClassificationNode
                            .cuentaContableIngreso.cuentaContableId) ||
                        ""
                      }
                      options={transformCatalogList(
                        this.props.accountingAccounts,
                        "SELECCIONE CUENTA CONTABLE INGRESO"
                      )}
                      onChange={this.onChangeCountId(
                        "cuentaContableIngreso",
                        e => e.target.value
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={6} md={4} lg={3}>
                    <FormGroup check className="mt-2">
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={
                            this.state.selectedClassificationNode.aplicaDevengo
                          }
                          onChange={e =>
                            this.onChangeCheckedFactory("aplicaDevengo")
                          }
                        />
                        Devengo
                      </Label>
                    </FormGroup>
                  </Col>
                  {this.state.selectedClassificationNode.aplicaDevengo ? (
                    <Col xs={12} sm={6} md={8} lg={5}>
                      <BaseInput
                        label="Cuenta contable devengo *"
                        name="cuentaContableDevengo"
                        type="select"
                        id="cuentaContableDevengo"
                        placeholder="Cuenta contable devengo"
                        value={
                          (this.state.selectedClassificationNode &&
                            this.state.selectedClassificationNode
                              .cuentaContableDevengo &&
                            this.state.selectedClassificationNode
                              .cuentaContableDevengo.cuentaContableId) ||
                          ""
                        }
                        options={transformCatalogList(
                          this.props.accountingAccounts,
                          "SELECCIONE CUENTA CONTABLE DEVENGO"
                        )}
                        onChange={this.onChangeCountId(
                          "cuentaContableDevengo",
                          e => e.target.value
                        )}
                      />
                    </Col>
                  ) : (
                    undefined
                  )}
                  {this.state.selectedClassificationNode.aplicaDevengo ? (
                    <Col xs={12} sm={6} md={4} lg={4}>
                      <BaseInput
                        label="Regla *"
                        name="selectionRegla"
                        type="select"
                        id="selectionRegla"
                        placeholder="Regla"
                        value={
                          (this.state.selectedClassificationNode &&
                            this.state.selectedClassificationNode
                              .esquemaDevengamiento &&
                            this.state.selectedClassificationNode
                              .esquemaDevengamiento.id) ||
                          ""
                        }
                        options={transformNodeList(
                          this.props.catalogAccrual,
                          "ESQUEMA DEVENGAMIENTO"
                        )}
                        onChange={this.onChangeRule(
                          "esquemaDevengamiento",
                          e => e.target.value
                        )}
                      />
                    </Col>
                  ) : (
                    undefined
                  )}
                </Row>
              </Form>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className={`${s.primaryButton} d-inline-block mr-2`}
              onClick={this.handleSaveClassificationNode}
              disabled={
                !this.state.selectedClassificationNode ||
                (!this.state.selectedClassificationNode.padre ||
                  !this.state.selectedClassificationNode.codigo ||
                  !this.state.selectedClassificationNode.descripcion ||
                  !this.state.selectedClassificationNode.nivel)
              }
            >
              GUARDAR
            </Button>
            <Button
              className={`${s.secondaryButton} d-inline-block mr-2`}
              onClick={this.handleCancelEditClassificationNode}
            >
              CANCELAR
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(AdminProductsClassifications));
