import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import * as clubsActions from "../../actions/clubs";
import * as dealers from "../../actions/dealers";
import * as persons from "../../actions/persons";
import * as catalogActions from "../../actions/catalogs";
import IAction from "../../../types/IAction";
import GenerationDealerChargesComponent from "../../components/generationDealerCharges";

interface Props {
  getAllClubsBasicData: () => void;
  clearDealers: () => IAction;
  clubs: object[];
  dealers: object[];
  getDealers: (clubId: string, name: string) => IAction;
  deleteDealer: (personaId: string, clubId: string) => IAction;
  deleteDealerBoolean: boolean;
  getDealersCharges: (concesionarioId: string, clubId: string) => IAction;
  dealersCharges: object[];
  deleteChargeDealer: (cargoId: string) => IAction;
  deleteChargeDealerBoolean: boolean;
  getSelectedDealerCharge: (cargoId: string) => IAction;
  selectedDealerCharge: object;
  getAccountingAccounts: () => any;
  accountingAccounts: object[];
  addDealerConfigCharge: (body: object) => IAction;
  updateDealerConfigCharge: (body: object) => IAction;
  dataUpdate: any;
  dataAddDCC: any;
  history: any;
}

interface State {}

class GenerationDealerCharges extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    this.props.getAllClubsBasicData();
    this.props.getAccountingAccounts();
  }

  componentWillUnmount() {
    this.props.clearDealers();
  }

  render() {
    return (
      <main>
        <Helmet title="Shift delivery" />
        <Layout>
          <GenerationDealerChargesComponent
            clubs={this.props.clubs}
            dealers={this.props.dealers}
            getDealers={this.props.getDealers}
            deleteDealer={this.props.deleteDealer}
            deleteDealerBoolean={this.props.deleteDealerBoolean}
            getDealersCharges={this.props.getDealersCharges}
            dealersCharges={this.props.dealersCharges}
            deleteChargeDealer={this.props.deleteChargeDealer}
            deleteChargeDealerBoolean={this.props.deleteChargeDealerBoolean}
            getSelectedDealerCharge={this.props.getSelectedDealerCharge}
            selectedDealerCharge={this.props.selectedDealerCharge}
            accountingAccounts={this.props.accountingAccounts}
            addDealerConfigCharge={this.props.addDealerConfigCharge}
            updateDealerConfigCharge={this.props.updateDealerConfigCharge}
            dataUpdate={this.props.dataUpdate}
            dataAddDCC={this.props.dataAddDCC}
            history={this.props.history}
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({ clubs, dealers, catalogs, persons }) => {
  return {
    clubs: clubs.allClubsBasicData,
    dealers: dealers.dealers,
    deleteDealerBoolean: dealers.deleteDealer,
    deleteChargeDealerBoolean: dealers.deleteChargeDealer,
    dealersCharges: dealers.dealersCharges,
    selectedDealerCharge: dealers.selectedDealerCharge,
    accountingAccounts: catalogs.accountingAccounts,
    dataUpdate: persons.dataUpdate,
    dataAddDCC: persons.dataAddDCC
  };
};

const mapDispatchToProps = dispatch => ({
  getAccountingAccounts: () => {
    dispatch(catalogActions.getAccountingAccounts());
  },
  getAllClubsBasicData: () => {
    dispatch(clubsActions.getAllClubsBasicData());
  },
  getDealers: (clubId: string, name: string) => {
    return dispatch(dealers.getDealers(clubId, name));
  },
  deleteDealer: (personaId: string, clubId: string) => {
    return dispatch(dealers.deleteDealer(personaId, clubId));
  },
  deleteChargeDealer: (cargoId: string) => {
    return dispatch(dealers.deleteChargeDealer(cargoId));
  },
  getDealersCharges: (concesionarioId: string, clubId: string) => {
    return dispatch(dealers.getDealersCharges(concesionarioId, clubId));
  },
  getSelectedDealerCharge: (cargoId: string) => {
    return dispatch(dealers.getSelectedDealerCharge(cargoId));
  },
  clearDealers: () => {
    return dispatch(dealers.clearDealers());
  },
  addDealerConfigCharge: (body: object) => {
    return dispatch(persons.addDealerConfigCharge(body));
  },
  updateDealerConfigCharge: (body: object) => {
    return dispatch(persons.updateDealerConfigCharge(body));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenerationDealerCharges);
