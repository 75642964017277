import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import moment from "moment";
import { Container, Col, Button, Row } from "reactstrap";
import {
  faArrowAltCircleRight,
  faArrowCircleRight,
  faCheckCircle,
  faFileExport,
  faSync
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BaseInput from "../../../toolkit/baseInput";
import DatePicker from "../common/DatePicker";
import MexicanCurrency from "../common/currency";
import s from "./styles.scss";
import IAction from "../../../types/IAction";
import { getReportSalesNotBilledRequest } from "../../actions/reports";
import {
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import { handleRequestError } from "../../../utils/helpers";
import SideActionButton from "../common/sideActionButton";
import {
  saleWithErrorRefactType1,
  saleWithErrorRefactType2
} from "../../actions/billing";
import { userHasPermission } from "../../../utils/accessTree";
import permissionCodes from "../../../utils/permissionCodes";

interface Props {
  downloadReportSalesNotBilled: (
    clubId: string,
    fechaInicio: string,
    fechaFin: string
  ) => IAction;
  clubs: {
    clubId: number;
    nombre: string;
  }[];
  userContext: { clubSessionId: any };
}

interface State {
  filters: object;
  data: any[];
  onSearch: boolean;
  isOpen: boolean;
}

const START_DATE_FIELDNAME = "fechaInicio";
const END_DATE_FIELDNAME = "fechaFin";

class UnbilledSalesReport extends React.Component<Props, State> {
  state = {
    data: [],
    filters: {
      idClub: "",
      fechaInicio: null,
      fechaFin: null
    },
    isOpen: false,
    onSearch: false
  };

  columns = [
    {
      Header: () => <p className="font-weight-bold">Club venta</p>,
      id: 0,
      width: 160,
      className: "text-center",
      accessor: "ventaClubVentaNombre"
    },
    {
      Header: () => <p className="font-weight-bold">Club membresía</p>,
      id: 0,
      width: 160,
      className: "text-center",
      accessor: "ventaClubMembresiaNombre"
    },
    {
      Header: () => <p className="font-weight-bold"># membresía</p>,
      id: 0,
      width: 120,
      className: "text-center",
      accessor: "ventaMembresiaIdPublico"
    },
    {
      Header: () => <p className="font-weight-bold">Estatus</p>,
      id: 0,
      width: 200,
      className: "text-center",
      accessor: "ventaEstatus"
    },
    {
      Header: () => <p className="font-weight-bold">Fecha venta</p>,
      id: 0,
      width: 120,
      className: "text-center",
      accessor: "ventaFechaVenta"
    },
    {
      Header: () => <p className="font-weight-bold">Venta Importe</p>,
      id: 0,
      width: 150,
      className: "text-center",
      accessor: "ventaImporte",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.ventaImporte} />;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Refacturar</p>,
      id: 0,
      width: 100,
      className: "text-center",
      accessor: "ventaImporte",
      Cell: row => (
        <div style={{ fontSize: "1.2em" }}>
          {userHasPermission(permissionCodes.REPORT_SALES_WITH_ERRORS_REFACT) &&
            row.original.tipoRefacturacionId > 0 && (
              <SideActionButton
                buttonId={`refact${row.original.ventaId}`}
                tooltipTitle={"Refacturar"}
                icon={faSync}
                color={""}
                onClick={() => {
                  switch (row.original.tipoRefacturacionId) {
                    case 1:
                      this.refactSaleWithErrorType1(row.original.ventaId);
                      break;
                    case 1:
                      this.refactSaleWithErrorType2(row.original.ventaId);
                      break;
                  }
                }}
                success={null}
                confirmModal={{
                  title: "Refacturar venta",
                  message: `¿Desea refacturar la venta con identificador ${row.original.ventaId}?`
                }}
              />
            )}
        </div>
      )
    }
  ];

  componentDidMount() {
    const { filters } = this.state;
    filters.idClub = this.props.userContext.clubSessionId;
    this.setState({ filters: { ...filters } });
  }

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const filters = {
          ...state.filters,
          [fieldName]: date
        };
        return { ...state, error: {}, filters };
      });
    };
  };

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  onClickDownloadCsvFile = () => {};

  search = () => {
    this.setState({
      onSearch: true
    });

    const { idClub, fechaInicio, fechaFin } = this.state.filters;

    if (!fechaInicio || !fechaFin) {
      return;
    }

    infoNotification("Consultando datos...");
    getReportSalesNotBilledRequest(idClub, fechaInicio, fechaFin)
      .then(({ data }) => {
        this.setState({ data, onSearch: false });
        successNotification("Datos consultados correctamente...");
      })
      .catch(handleRequestError);
  };

  refactSaleWithErrorType1 = saleId => {
    infoNotification("Refacturando venta...");
    return saleWithErrorRefactType1(saleId)
      .then(() => {
        successNotification("Venta refacturada correctamente.");
        this.setState({ data: [] });
        this.search();
      })
      .catch(handleRequestError);
  };

  refactSaleWithErrorType2 = saleId => {
    infoNotification("Refacturando venta...");
    return saleWithErrorRefactType2(saleId)
      .then(() => {
        successNotification("Venta refacturada correctamente.");
        this.setState({ data: [] });
        this.search();
      })
      .catch(handleRequestError);
  };

  render() {
    const { data } = this.state;
    const { idClub } = this.state.filters;
    const { clubs } = this.props;

    return (
      <Container className="p-0">
        <Row>
          <Col>
            <BaseInput
              label="Club"
              name="club"
              id="club"
              type="select"
              placeholder="Seleccionar club"
              value={idClub}
              onChange={this.onChangeFilter("idClub")}
              size={"sm"}
              options={[{ value: "", label: "Seleccionar" }].concat(
                (clubs || [])
                  .filter(c => !!c.migradoCrm2)
                  .map(option => ({
                    value: option.clubId,
                    label: option.nombre
                  }))
              )}
            />
          </Col>
          <Col>
            <DatePicker
              label="Fecha Inicial"
              name={START_DATE_FIELDNAME}
              id={START_DATE_FIELDNAME}
              selected={this.state.filters[START_DATE_FIELDNAME]}
              onChange={this.onChangeDate(START_DATE_FIELDNAME)}
            />
          </Col>
          <Col>
            <DatePicker
              label="Fecha Final"
              name={END_DATE_FIELDNAME}
              id={END_DATE_FIELDNAME}
              selected={this.state.filters[END_DATE_FIELDNAME]}
              onChange={this.onChangeDate(END_DATE_FIELDNAME)}
            />
          </Col>
          <Col>
            <br />
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.search}
              disabled={
                !this.state.filters.fechaInicio ||
                !this.state.filters.fechaInicio
              }
            >
              Buscar
            </Button>
          </Col>
        </Row>
        {!!this.state.filters.fechaInicio && !!this.state.filters.fechaInicio && (
          <Row className={s.customRowCSV}>
            <Col className={s.customTextoCSV}>Exportar archivo CSV</Col>
            <Col className={s.customLogoCSV}>
              <Button
                id="btnLockerSummaryExportCsv"
                color="link"
                onClick={() => {
                  const { idClub, fechaInicio, fechaFin } = this.state.filters;

                  this.props.downloadReportSalesNotBilled(
                    idClub,
                    fechaInicio,
                    fechaFin
                  );
                }}
              >
                <FontAwesomeIcon icon={faFileExport} color="black" />
              </Button>
            </Col>
          </Row>
        )}
        <ReactTable
          data={data || []}
          columns={this.columns}
          className="mt-5 -striped -highlight"
          sortable={false}
          resizable={false}
          pageSize={20}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(UnbilledSalesReport));
