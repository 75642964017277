import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import * as catalogActions from "../../actions/catalogs";
import * as reportes from "../../actions/reports";
import Layout from "../../components/layout";
import ReportMaintenancesPricesGrid from "../../components/maintenacesPricesGrid";
import IAction from "../../../types/IAction";

interface Props {
  getClubs: () => void;
  downloadMaintenancesPriceGrid: (clubId: number, anio: number) => IAction;

  clubs: object[];
}

interface State {}

class ReportMaintenancesPricesGridPage extends React.Component<Props, State> {
  state = {};

  componentDidMount() {
    this.props.getClubs();
  }

  render() {
    return (
      <main>
        <Helmet title="ReportMaintenancesPricesGrid" />
        <Layout>
          <ReportMaintenancesPricesGrid
            clubs={this.props.clubs}
            downloadMaintenancesPriceGrid={
              this.props.downloadMaintenancesPriceGrid
            }
          />
        </Layout>
      </main>
    );
  }
}

const mapStateToProps = ({ catalogs }) => {
  return {
    clubs: catalogs.clubs
  };
};

const mapDispatchToProps = dispatch => ({
  getClubs: () => {
    dispatch(catalogActions.getClubs());
  },

  downloadMaintenancesPriceGrid: (clubId: number, anio: number) => {
    dispatch(reportes.downloadMaintenancesPriceGrid(clubId, anio));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportMaintenancesPricesGridPage);
