import React, { Component, useEffect, useState } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import RichEditor from "../richEditor";
import SelectFilter from "../common/SelectFilter";
import DatePicker from "../common/DatePicker";
import s from "./styles.scss";
import moment from "moment";
import { setChildStep } from "../../helpers/stepper-state-comparator";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import isNil from "lodash.isnil";
import {
  MEMBRESIA_TYPE,
  MEMBRESIA_REACTIVACION_TYPE,
  MANTENIMIENTO_TYPE
} from "../../../utils/constants";
import {
  getClassificationByLevelRequest,
  getProductsCategories,
  getSubCategoriesByCategoryRequest
} from "../../actions/products";
import { handleRequestError } from "../../../utils/helpers";

interface Category {
  name: string;
  categoriaId: number;
  subcategorias: {
    name: string;
    categoriaId: number;
  }[];
}

const getSubcategories = (categories: Category[], categoryId: string) => {
  const subCategories = [{ label: "Seleccione una Sub Categoria", value: -1 }];
  let valIndex = -1;
  if (parseInt(categoryId) >= 0) {
    categories.forEach((element, index) => {
      if (element.id == categoryId) valIndex = index;
    });
    if (valIndex >= 0 && categories[valIndex].subcategorias.length > 0) {
      subCategories.concat(
        categories[valIndex].subcategorias.map((categoryId, name) => ({
          label: name,
          value: categoryId
        }))
      );
    }
  }
  return subCategories;
};

export const cleanState = state => {
  const cleanState = {
    ...state,
    fechaFinVigencia: state.disableEndDate ? "" : state.fechaFinVigencia,
    integrantes: parseInt(state.integrantes, 10),
    descripcion: state.editorState
      ? draftToHtml(convertToRaw(state.editorState.getCurrentContent()))
      : null,
    isFechaFinVigenciaPresente: !state.disableEndDate
  };

  delete cleanState.editorState;

  return cleanState;
};

const basicDataProductFields = ({
  membershipsScreen,
  initialState,
  re,
  productsCategories,
  indicators,
  satUnits,
  satProducts,
  setstate,
  listSubcategories,
  getSubCategoriesByCategory,
  mantenimientosScreen,
  conveniosList
}) => {
  const [valArrayType, setValArrayType] = useState([]);
  const [valArrayReactType, setValArrayReactType] = useState([]);
  const [categoriasReactivacion, setCategoriasReactivacion] = useState([]);

  useEffect(() => {
    const subcategorias = listSubcategories.subcategorias || [];

    if (window.location.pathname.includes("membresia")) {
      setValArrayType(MEMBRESIA_TYPE);
      setValArrayReactType(MEMBRESIA_REACTIVACION_TYPE);
    }
    if (window.location.pathname.includes("mantenimiento")) {
      setValArrayType(MANTENIMIENTO_TYPE);
    }
    if (initialState && subcategorias.length == 0 && initialState.categoriaId) {
      getSubCategoriesByCategory(initialState.categoriaId);
    }

    if (window.location.pathname.includes("membresia")) {
      getClassificationByLevelRequest(3)
        .then(({ data }) => {
          setCategoriasReactivacion([...data]);
        })
        .catch(handleRequestError);
    }
  }, []);

  useEffect(() => {
    const padre = listSubcategories.padre;
    const subcategorias = listSubcategories.subcategorias || [];

    if (!isNil(padre) && subcategorias.length == 0) {
      getSubCategoriesByCategory(listSubcategories.padre);
    }
  }, [listSubcategories]);

  const conveniosOptions = conveniosList.map(({ id, nombre }) => ({
    label: nombre,
    value: id
  }));

  const state = {
    ...initialState,
    categoriasReactivacion: [],
    conveniosSel: conveniosOptions.filter(c =>
      (initialState.convenios || []).find(id => c.value == id)
    )
  };
  setChildStep(cleanState(state));

  const onChangeEditor = editorState => setstate({ ...state, editorState });

  const checkDisableDate = e => {
    const disableEndDate = e.target.checked;
    setstate({
      ...state,
      disableEndDate,
      fechaFinVigencia: disableEndDate ? "" : moment().format("YYYY-MM-DD")
    });
  };

  const checkPublishPrice = e => {
    const publicarPrecio = e.target.checked;
    setstate({ ...state, publicarPrecio });
  };

  const checkReferredProgramApply = e => {
    const aplicaReferidos = e.target.checked;
    setstate({ ...state, aplicaReferidos });
  };

  const onChangeIndicator = indicadoresBI => {
    setstate({ ...state, indicadoresBI });
  };

  const onChangeConvenios = conveniosSel => {
    setstate({
      ...state,
      convenios: conveniosSel.map(c => c.value),
      conveniosSel
    });
  };

  const onChangeType = value => {
    if (value.target.value > 0) {
      getSubCategoriesByCategory(value.target.value);
      const fields = {
        ...state,
        tipo: value.target.value
      };
      setstate({ ...fields });
    }
  };

  const onChangeFactory = (fieldName, f) => {
    return event => {
      const value = f(event);
      const fields = {
        ...state,
        disableEndDate:
          fieldName === "fechaFinVigencia" && !isNil(value)
            ? false
            : state.disableEndDate,
        [fieldName]: value
      };

      setstate({ ...fields });
      re.validateField(fieldName, value);
    };
  };

  const getCategoriasReactivacion = () => {
    const parents = MEMBRESIA_REACTIVACION_TYPE.map(x => x.value);
    return (categoriasReactivacion || []).filter(
      x => parents.indexOf(x.padre) >= 0
    );
  };

  return (
    <form className={s.basicDataProduct}>
      <div className="form-row">
        <div className="col">
          <BaseInput
            label={"Tipo"}
            name="tipo"
            type="select"
            id="tipo"
            placeholder="Tipo"
            value={state.tipo}
            options={[{ value: "", label: "Seleccione un Tipo" }].concat(
              valArrayType.map(option => ({
                value: option.value,
                label: option.label.toUpperCase()
              }))
            )}
            onChange={e => onChangeType(e)}
          />
        </div>
        <div className="col">
          <BaseInput
            label={"Categoría"}
            name="categoriaId"
            type="select"
            id="categoriaId"
            placeholder="Categoría"
            value={state.categoriaId}
            options={
              listSubcategories && listSubcategories.subcategorias
                ? [{ value: "", label: "Seleccione una Categoría" }].concat(
                    listSubcategories.subcategorias.map(option => ({
                      value: option.id.toString(),
                      label: option.descripcion
                    }))
                  )
                : [{ value: "", label: "Seleccione una Categoría" }]
            }
            onChange={onChangeFactory("categoriaId", e => e.target.value)}
            errors={re.mapFieldErrors("categoriaId")}
            disabled={state.tipo == "" ? true : false}
          />
        </div>

        {membershipsScreen && (
          <div className="col">
            <BaseInput
              type="select"
              id="categoriaReactId"
              name="categoriaReactId"
              label={"Categoría para reactivación"}
              value={state.categoriaReactivacionId}
              options={[
                { value: "", label: "Seleccione una Categoría" },
                ...getCategoriasReactivacion().map(x => {
                  return { value: x.id, label: x.descripcion };
                })
              ]}
              onChange={onChangeFactory(
                "categoriaReactivacionId",
                e => e.target.value
              )}
              errors={re.mapFieldErrors("categoriaReactivacionId")}
            />
          </div>
        )}

        {membershipsScreen && (
          <div className="col">
            <div className="col">
              <BaseInput
                label={"Integrantes"}
                name="integrantes"
                type="number"
                id="integrantes"
                placeholder="Integrantes"
                value={state.integrantes}
                onChange={onChangeFactory("integrantes", e => e.target.value)}
                errors={re.mapFieldErrors("integrantes")}
              />
            </div>
          </div>
        )}
        {mantenimientosScreen && (
          <div className="col">
            {indicators && (
              <SelectFilter
                label={"Convenios aplicables"}
                name="convenios"
                type="select"
                id="convenios"
                placeholder="Convenios"
                value={state.conveniosSel}
                isMulti
                options={conveniosList.map(({ id, nombre }) => ({
                  label: nombre,
                  value: id
                }))}
                onChange={onChangeConvenios}
              />
            )}
          </div>
        )}
        <div className="col-4">
          {indicators && (
            <SelectFilter
              label={"Indicadores BI"}
              name="indicadoresBI"
              type="select"
              id="indicadoresBI"
              placeholder="Indicadores BI"
              value={state.indicadoresBI}
              isMulti
              options={indicators.map(({ tagId, nombre }) => ({
                label: nombre,
                value: tagId
              }))}
              onChange={onChangeIndicator}
              errors={re.mapFieldErrors("indicadoresBI")}
            />
          )}
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <BaseInput
            label={"Nombre"}
            name="nombre"
            type="text"
            id="nombre"
            placeholder="Nombre"
            value={state.nombre}
            onChange={onChangeFactory("nombre", e => e.target.value)}
            errors={re.mapFieldErrors("nombre")}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <BaseInput
            label={"Estatus"}
            name="estatus"
            type="select"
            id="estatus"
            placeholder="Estatus"
            disabled
            value={state.estatus}
            options={["ACTIVO", "INACTIVO"].map(option => ({
              value: option,
              label: option
            }))}
            onChange={onChangeFactory("estatus", e => e.target.value)}
            errors={re.mapFieldErrors("estatus")}
          />
        </div>
        <div className="col">
          {satUnits && (
            <BaseInput
              label={"Unidades SAT"}
              name="unidadSAT"
              type="select"
              id="unidadSAT"
              placeholder="Unidades SAT"
              value={state.unidadSAT}
              options={[{ value: "", label: "Seleccionar Unidad SAT" }].concat(
                satUnits.map(option => ({
                  value: option.id,
                  label: option.nombre
                }))
              )}
              onChange={onChangeFactory("unidadSAT", e => e.target.value)}
              errors={re.mapFieldErrors("unidadSAT")}
            />
          )}
        </div>
        <div className="col">
          {satProducts && (
            <BaseInput
              label={"Producto/Servicio SAT"}
              name="productoSAT"
              type="select"
              id="productoSAT"
              placeholder="Producto/Servicio SAT"
              value={state.productoSAT}
              options={[
                { value: "", label: "Seleccionar Producto SAT" }
              ].concat(
                satProducts.map(option => ({
                  value: option.id,
                  label: option.nombre
                }))
              )}
              onChange={onChangeFactory("productoSAT", e => e.target.value)}
              errors={re.mapFieldErrors("productoSAT")}
            />
          )}
        </div>
        <div className="col">
          <DatePicker
            label="Inicio Vigencia"
            name="fechaInicioVigencia"
            id="fechaInicioVigencia"
            selected={state.fechaInicioVigencia}
            onChange={onChangeFactory("fechaInicioVigencia", event =>
              moment(event).format("YYYY-MM-DD")
            )}
            errors={re.mapFieldErrors("fechaInicioVigencia")}
          />
        </div>
        <div className="col">
          <DatePicker
            label="Fin Vigencia"
            name="fechaFinVigencia"
            id="fechaFinVigencia"
            selected={state.fechaFinVigencia}
            onChange={onChangeFactory("fechaFinVigencia", event =>
              moment(event).format("YYYY-MM-DD")
            )}
            errors={re.mapFieldErrors("fechaFinVigencia")}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="col">
          <div className="row">
            <Label className={`${s.label} col`}>Descripción:</Label>
            <FormGroup className="col-2" check>
              <Label className="float-right" check>
                <Input
                  type="checkbox"
                  checked={state.aplicaReferidos}
                  onChange={e => checkReferredProgramApply(e)}
                />
                Aplica a programa de referidos
              </Label>
            </FormGroup>
            <FormGroup className="col-2" check>
              <Label className="float-right" check>
                <Input
                  type="checkbox"
                  checked={state.publicarPrecio}
                  onChange={e => checkPublishPrice(e)}
                />
                Ver en parrilla de precios
              </Label>
            </FormGroup>
            <FormGroup className="col-2" check>
              <Label className="float-right" check>
                <Input
                  type="checkbox"
                  checked={state.disableEndDate}
                  onChange={e => checkDisableDate(e)}
                />
                Sin Fin de Vigencia
              </Label>
            </FormGroup>
          </div>
          <div style={{ height: "350px" }}>
            <RichEditor
              onChangeEditor={onChangeEditor}
              editorState={state.editorState}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default basicDataProductFields;
