import axios from "axios";
import FileDownload from "js-file-download";

import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import constants from "../../utils/constants";
import { QueryArguments } from "../../types-business/QueryArguments";

const baseUrl = `/api/lockers`;

export const getLockers = (clubId: number): IAction => {
  return {
    type: actionTypes.GET_LOCKERS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/club/${clubId}`, {
        withCredentials: true
      })
    }
  };
};

export const getAssignedByClubId = (params?: QueryArguments): IAction => {
  return {
    type: actionTypes.GET_ASSIGNED_LOCKERS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/assigned`, {
        withCredentials: true,
        params: { ...params, sort: "fechaVigencia,asc" }
      })
    }
  };
};

export const saveLockersConfig = (lockersConfig: object): IAction => {
  return {
    type: actionTypes.SAVE_LOCKERS,
    payload: {
      promise: axios.put(`${constants.BASE_URL}${baseUrl}`, lockersConfig, {
        withCredentials: true
      })
    }
  };
};

export const getLockersWallsByClubId = (clubId: number): IAction => {
  return {
    type: actionTypes.GET_LOCKERS_WALLS_BY_CLUB_ID,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/club/${clubId}/walls`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const getLockersWithFiltersPaginated = (
  clubId: number,
  lockerRoom: string,
  wallId: number,
  status: string,
  lockerNumber: string,
  isAssignable: boolean,
  params?: QueryArguments
): IAction => {
  const filters = {};
  if (!!clubId && clubId > 0) {
    filters["clubId"] = clubId;
  }
  if (!!lockerRoom && lockerRoom != "") {
    filters["vestidor"] = lockerRoom;
  }
  if (!!wallId && wallId > 0) {
    filters["paredId"] = wallId;
  }
  if (!!status && status != "") {
    filters["estatus"] = status;
  }
  if (!!lockerNumber && lockerNumber != "") {
    filters["lockerNumero"] = lockerNumber;
  }
  if (typeof isAssignable !== "undefined") {
    filters["esAsignable"] = isAssignable;
  }

  return {
    type: actionTypes.GET_LOCKERS_BY_WALL_ID,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/paginated`, {
        withCredentials: true,
        params: { ...params, ...filters }
      })
    }
  };
};

export const getLockersProducts = (): IAction => {
  return {
    type: actionTypes.GET_LOCKERS_PRODUCTS,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/products`, {
        withCredentials: true
      })
    }
  };
};

export const getLockerProduct = (productoId: any): IAction => {
  return {
    type: actionTypes.GET_LOCKER_PRODUCT,
    payload: {
      promise: axios.get(
        `${constants.BASE_URL}${baseUrl}/product/${productoId}`,
        {
          withCredentials: true
        }
      )
    }
  };
};

export const saveLockerAssignment = (
  lockerId: number,
  personId: number,
  productId: number,
  responsibleId: number,
  msi: number,
  codigo: string,
  storage: string | number
): IAction => {
  return {
    type: actionTypes.SAVE_LOCKER_ASSIGNMENT,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/assign`, {
        withCredentials: true,
        params: {
          lockerId,
          personaId: personId,
          productoId: productId,
          responsableId: responsibleId,
          mesesSinInteseres: msi,
          codigo,
          storage
        }
      })
    }
  };
};

export const putLockerExtension = (extension: any): IAction => {
  return {
    type: actionTypes.PUT_LOCKER_EXTENSION,
    payload: {
      promise: axios.put(`${constants.BASE_URL}${baseUrl}/reasign`, extension, {
        withCredentials: true
      })
    }
  };
};

export const getListFile = (): IAction => {
  return {
    type: actionTypes.GET_LOCKERS_FILE,
    payload: {
      promise: axios
        .get(`${constants.BASE_URL}${baseUrl}/file-list`, {
          withCredentials: true,
          responseType: "blob"
        })
        .then(({ data }) => {
          FileDownload(data, "lockers.csv");
        })
    }
  };
};

export const getClubFile = (clubId: number): IAction => {
  return {
    type: actionTypes.GET_LOCKERS_FILE,
    payload: {
      promise: axios
        .get(`${constants.BASE_URL}${baseUrl}/file-club/${clubId}`, {
          withCredentials: true,
          responseType: "blob"
        })
        .then(({ data }) => {
          FileDownload(data, "club-lockers.csv");
        })
    }
  };
};

export const printExpirationNotification = (assignmentId: number): void => {
  window.open(
    `${constants.BASE_URL}${baseUrl}/assignment/${assignmentId}/generate-notification`,
    "_blank"
  );
};

export const getLockersSummaryByClub = (): IAction => {
  return {
    type: actionTypes.GET_LOCKERS_SUMMARY_BY_CLUB,
    payload: {
      promise: axios.get(`${constants.BASE_URL}${baseUrl}/summary-by-club`, {
        withCredentials: true
      })
    }
  };
};

export const downloadLockersSummaryByClub = (): void => {
  window.open(
    `${constants.BASE_URL}${baseUrl}/summary-by-club/download`,
    "_blank"
  );
};
