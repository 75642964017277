import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import { Container, Row, Col, Button, Label } from "reactstrap";
import BaseInput from "../../../../toolkit/baseInput";
import s from "../styles.scss";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import { FILTERS, TIPOS_COMISION, ESTATUS_COMISION } from "../interfaces";

interface Props {
  fetchSearchData: (filters: FILTERS) => void;
  getSchemasByPeriod: (periodoId) => void;
  clubs: { clubId: number; nombre: string }[];
  schemas: {
    activo: boolean;
    esquema: object[];
    esquemaId: number;
    nombre: string;
  }[];
  periods: { id: number; nombre: string }[];
  sellers: object[];
  showProccess?: boolean;
  fetchProccess?: () => void;
}

interface State extends FILTERS {}

class ComissionsReactivationReportFilter extends React.Component<Props, State> {
  state = {
    clubId: null,
    periodoId: null,
    esquemaId: null,
    vendedorId: null,
    estatusComision: null,
    disabledSearch: true
  };

  // @ts-ignore
  componentDidMount = () => {};

  toggleDisabledSearch = () => {
    let state = this.state;
    if (this.state.clubId && this.state.periodoId && this.state.esquemaId)
      state.disabledSearch = false;
    else state.disabledSearch = true;
    this.setState(state);
  };

  onChangeClubPeriodDrop = async (e, fieldName) => {
    let state = this.state;
    state[fieldName] = e.target.value;
    await this.setState(state);
    if (state.periodoId) this.props.getSchemasByPeriod(this.state.periodoId);
    this.toggleDisabledSearch();
  };

  onChangeGenericDrop = async (e, fieldName) => {
    let state = this.state;
    state[fieldName] = e.target.value;
    await this.setState(state);
    this.toggleDisabledSearch();
  };
  onChangeSeller = selected => {
    if (selected.length > 0) {
      let state = this.state;
      state.vendedorId = selected[0]["id"];
      this.setState(state);
    } else {
      let state = this.state;
      state.vendedorId = null;
      this.setState(state);
    }
  };

  optionsCreator = () => {
    if (this.props.schemas)
      if (this.props.schemas.length > 0)
        return [{ value: -1, label: "Todos" }].concat(
          this.props.schemas.map(({ esquemaId, nombre }) => ({
            value: esquemaId,
            label: nombre
          }))
        );
      else return [{ value: -2, label: "Ningún esquema aplicable" }];

    return [];
  };

  render() {
    return (
      <Container className="mt-3 mb-5 p-0">
        <Row className="my-4">
          <Col sm="3">
            <BaseInput
              label={"* Club"}
              name="Club"
              type="select"
              id="club"
              size={"sm"}
              value={this.state.clubId}
              options={[{ value: -1, label: "Seleccionar el club" }].concat(
                this.props.clubs.map(({ clubId, nombre }) => {
                  return {
                    value: clubId,
                    label: nombre
                  };
                })
              )}
              onChange={e => this.onChangeClubPeriodDrop(e, "clubId")}
            />
          </Col>
          <Col sm="3">
            <BaseInput
              label={"* Periodo"}
              name="perios"
              type="select"
              id="periodo"
              size={"sm"}
              value={this.state.periodoId}
              options={[{ value: -1, label: "Seleccionar el periodo" }].concat(
                this.props.periods.map(({ id, nombre }) => {
                  return {
                    value: id,
                    label: nombre
                  };
                })
              )}
              onChange={e => this.onChangeClubPeriodDrop(e, "periodoId")}
            />
          </Col>
          <Col sm="3">
            <BaseInput
              label={"* Esquema"}
              name="esquema"
              type="select"
              id="esquema"
              size={"sm"}
              value={this.state.esquemaId}
              options={[{ value: -2, label: "Seleccionar el esquema" }].concat(
                this.optionsCreator()
              )}
              onChange={e => this.onChangeGenericDrop(e, "esquemaId")}
              disabled={this.props.schemas ? false : true}
            />
          </Col>
          <Col sm="3">
            <BaseInput
              label={"Estatus de la comisión"}
              name="estatus"
              type="select"
              id="estatus"
              size={"sm"}
              value={this.state.estatusComision}
              options={[{ value: -1, label: "Selecciona una opción" }].concat(
                // @ts-ignore
                ESTATUS_COMISION.map(({ value, label }) => ({ value, label }))
              )}
              onChange={e => this.onChangeGenericDrop(e, "estatusComision")}
            />
          </Col>
        </Row>

        <Row>
          <Col sm="3">
            <Label className={s.label}>Nombre de vendedor</Label>
            <Typeahead
              id={"vendedor"}
              promptText={"Vendedores"}
              bsSize={"sm"}
              onChange={selected => this.onChangeSeller(selected)}
              emptyLabel={"Sin coincidencias"}
              disabled={this.props.sellers ? false : true}
              maxResults={20}
              options={
                this.props.sellers
                  ? this.props.sellers.map(item => {
                      var rObj = {};
                      rObj["id"] = item["userId"];
                      rObj[
                        "label"
                      ] = `${item["name"]} ${item["firstSurname"]} ${item["secondSurname"]} `;
                      return rObj;
                    })
                  : []
              }
            />
          </Col>

          <Col sm="3">
            <label>&nbsp;</label>
            <Button
              size="md"
              block
              disabled={this.state.disabledSearch}
              onClick={() => this.props.fetchSearchData(this.state)}
            >
              Buscar
            </Button>
          </Col>

          <Col hidden={!this.props.showProccess} sm="3">
            <label>&nbsp;</label>
            <Button size="md" block onClick={() => this.props.fetchProccess()}>
              Procesar pagos
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ComissionsReactivationReportFilter));
