import React from "react";
import s from "./styles.scss";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { Container, Row, Col, Button } from "reactstrap";
import BaseInput from "../../../../toolkit/baseInput";
import Reform from "@franleplant/reform";
import { FormErrors } from "@franleplant/reform/types";
import { connect } from "react-redux";

interface Props {
  id: number;
  sendEmail: (id: number, email: string) => any;
  sendingMovementsMail: boolean;
}

interface State {
  fields: {
    email: string;
  };
  errors: FormErrors;
}

class EmailBill extends React.Component<Props, State> {
  re = Reform.reactMixins.objectMixin(this);

  state = {
    fields: {
      email: null
    },
    errors: {}
  };

  validationRules = {
    email: { email: true, required: true, maxLength: 255 }
  };

  validationMessages = {
    required: _ => `Obligatorio`,
    default: _ => `Inválido`
  };

  setEmail = e => {
    const email = e.target.value;
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        email
      }
    });
  };

  onClickSubmit = () => {
    if (this.re.validateFormFromState()) {
      const { email } = { ...this.state.fields };
      this.props.sendEmail(this.props.id, email);
    }
  };

  validState = () => {
    const fields = { ...this.state.fields };
    return fields.email;
  };

  render() {
    return (
      <Container>
        <Row>
          <Col>
            <BaseInput
              label="Email"
              name="emailMovimiento"
              type="text"
              id="emailMovimiento"
              placeholder="Email"
              value={this.state.fields.email}
              onChange={this.setEmail}
              errors={this.re.mapFieldErrors("email")}
              disabled={this.props.sendingMovementsMail}
            />
          </Col>
          <Col xs={3}>
            <Button
              className={`mt-4 ${s.buttonMarginTop} ${s.primaryButton}`}
              onClick={this.onClickSubmit}
              disabled={!this.validState() || this.props.sendingMovementsMail}
              block
              style={{ height: "2rem" }}
            >
              {this.props.sendingMovementsMail
                ? "Enviando..."
                : "Enviar Factura"}
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  const { partners } = state;

  return {
    sendingMovementsMail: partners.sendingMovementsMail
  };
};

export default withStyles(s)(connect(mapStateToProps)(EmailBill));
