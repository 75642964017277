import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import ModalFrame from "../../../../toolkit/modalFrame";
import { Button, Container, Row, Col, Label } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead-bs4.css";
import BaseInput from "../../../../toolkit/baseInput";

import s from "./styles.scss";

interface Props {
  // Atributos de uso y filtros
  isModalOpen: boolean;
  sellers: any[];

  // Atributos generales de la comisión
  cotizacionProductoItemId: number;
  idPublico: number;
  descripcion: string;
  club: string;

  // Funciones padre
  reasignSale: (filters: any) => void;
  close: () => void;
}

interface State {
  fullName: string;
  status: string;
  seller: any;
}

/**
 * Capa modal para reasignar un vendedor a cierta comisión
 */
class ReasignationModal extends React.Component<Props, State> {
  state = {
    fullName: null,
    status: null,
    seller: null
  };

  /**
   * Handler de selección del vendedor
   * @param selected Vendedor seleccionado
   */
  onChangeSeller = selected => {
    if (selected.length > 0) {
      const seller = selected[0]["seller"];
      this.setState({
        fullName: `${seller.name} ${seller.firstSurname} ${seller.secondSurname}`,
        status: seller.status,
        seller
      });
    } else {
      this.setState({
        fullName: null,
        status: null,
        seller: null
      });
    }
  };

  render() {
    return (
      <ModalFrame
        title={`Reasignación de venta`}
        isOpen={this.props.isModalOpen}
        toggle={() => {
          this.props.close();
          this.setState({
            fullName: null,
            status: null,
            seller: null
          });
        }}
        size="lg"
      >
        <Container style={{ width: "90%" }} className="center-block p-0 mb-3">
          <Row className="m-0">
            {/* Datos informativos de la venta */}
            <Col lg={12}>
              <p
                className={"mt-3 mb-2"}
                style={{ fontWeight: "bold", fontSize: "1.2em" }}
              >
                Datos de la venta
              </p>
            </Col>
            <Col sm={3}>
              <BaseInput
                label="# Membresía"
                name="reassignModalNoMembership"
                id="reassignModalNoMembership"
                type="text"
                value={this.props.idPublico}
                disabled={true}
              />
            </Col>
            <Col sm={6}>
              <BaseInput
                label="Nombre"
                name="reassignModalName"
                id="reassignModalName"
                type="text"
                value={this.props.descripcion}
                disabled={true}
              />
            </Col>
            <Col sm={3}>
              <BaseInput
                label="Club"
                name="reassignModalClub"
                id="reassignModalClub"
                type="text"
                value={this.props.club}
                disabled={true}
              />
            </Col>

            {/* Selector y datos de vendedor a quien se le reasigna la venta */}
            <Col lg={12}>
              <p
                className={"mt-3 mb-2"}
                style={{ fontWeight: "bold", fontSize: "1.2em" }}
              >
                Reasignar a:
              </p>
            </Col>
            <Col lg={12}>
              <Label className={s.label}>Nombre de vendedor</Label>
              <Typeahead
                id={"vendedor"}
                promptText={"Vendedores"}
                bsSize={"sm"}
                onChange={selected => this.onChangeSeller(selected)}
                emptyLabel={"Sin coincidencias"}
                disabled={this.props.sellers ? false : true}
                maxResults={20}
                options={
                  this.props.sellers
                    ? this.props.sellers.map(item => ({
                        id: item.userId,
                        label: `${item.name || ""} ${item.firstSurname ||
                          ""} ${item.secondSurname || ""}`.trim(),
                        seller: item
                      }))
                    : []
                }
              />
              <span
                style={{
                  fontSize: "0.7em",
                  marginTop: "4px",
                  color: this.state.seller ? "#000" : "#400"
                }}
              >
                {this.state.seller
                  ? "Vendedor seleccionado: " +
                    this.state.fullName +
                    " (" +
                    this.state.status +
                    ")"
                  : "No ha seleccionado un vendedor"}
              </span>
            </Col>

            {/* Botón de confirmación */}
            <Col lg={12}>
              <hr />
            </Col>
            <Col sm={{ size: 3, offset: 9 }}>
              <div style={{ textAlign: "right" }}>
                <Button
                  className={" mt-3"}
                  onClick={() =>
                    this.props.reasignSale({
                      ...this.state,
                      cotizacionProductoItemId: this.props
                        .cotizacionProductoItemId
                    })
                  }
                  size="lg"
                  block
                  disabled={!this.state.seller}
                >
                  Reasignar
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </ModalFrame>
    );
  }
}

export default withRouter(withStyles(s)(ReasignationModal));
