import React from "react";
import { connect } from "react-redux";
import PayrollConceptsProductsComponent from "../../components/payrollConceptsProducts";
import * as productsActions from "../../actions/products";
import IAction from "../../../types/IAction";

interface Props {
  sportProduct: any;
  getPayrollConcepts: (productoId: string) => IAction;
  getPayrollConceptsSelect: (
    clubId: string,
    productoConcepto: string
  ) => IAction;
  sendPayrollConceptsSave: (data: object) => IAction;
  payrollConcepts: object[];
  payrollConceptsSelect: object[];
  id: string;
  savePayrollConcept: boolean;
}

class PayrollConceptsProducts extends React.Component<Props, void> {
  componentDidMount() {
    this.reloadData();
  }

  reloadData = () => {
    this.props.getPayrollConcepts(this.props.id);
  };

  render() {
    return (
      <PayrollConceptsProductsComponent
        payrollConcepts={this.props.payrollConcepts}
        payrollConceptsSelect={this.props.payrollConceptsSelect}
        getPayrollConceptsSelect={this.props.getPayrollConceptsSelect}
        sendPayrollConceptsSave={this.props.sendPayrollConceptsSave}
        reloadData={this.reloadData}
        id={this.props.id}
        savePayrollConcept={this.props.savePayrollConcept}
        sportProduct={this.props.sportProduct}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getPayrollConcepts: (productoId: string) => {
    dispatch(productsActions.getPayrollConcepts(productoId));
  },
  getPayrollConceptsSelect: (clubId: string, productoConcepto: string) => {
    dispatch(
      productsActions.getPayrollConceptsSelect(clubId, productoConcepto)
    );
  },
  sendPayrollConceptsSave: (data: object) => {
    dispatch(productsActions.sendPayrollConceptsSave(data));
  }
});

const mapStateToProps = state => {
  const { products } = state;
  return {
    payrollConcepts: products.payrollConcepts,
    payrollConceptsSelect: products.payrollConceptsSelect,
    savePayrollConcept: products.savePayrollConcept
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PayrollConceptsProducts);
