import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import { Container } from "reactstrap";
import MexicanCurrency from "../common/currency";
import s from "./styles.scss";

interface Props {
  membershipData: any;
}

interface State {}

class PricesGridMembershipComponent extends React.Component<Props, State> {
  state = {};

  columns = [
    {
      Header: () => <p className="font-weight-bold">Mantenimiento</p>,
      id: 0,
      width: 250,
      accessor: "mantenimientoNombre"
    },
    {
      Header: () => <p className="font-weight-bold">Integrante</p>,
      id: 0,
      width: 150,
      className: "text-center",
      accessor: "integrante"
    },
    {
      Header: () => (
        <p className="font-weight-bold">
          Cargo Automático <br /> Crédito
        </p>
      ),
      id: 0,
      class: "text-center",
      accessor: "precioCat",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.precioCat} />;
      }
    },
    {
      Header: () => (
        <p className="font-weight-bold">
          Cargo Automático <br /> Débito
        </p>
      ),
      id: 0,
      class: "text-center",
      accessor: "precioDeb",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.precioDeb} />;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Mensual</p>,
      id: 0,
      class: "text-center",
      accessor: "precio",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.precio} />;
      }
    }
  ];

  getTabledata = () => {
    if (!this.props.membershipData) {
      return [];
    }

    const { mantenimientos } = this.props.membershipData;

    if (!(mantenimientos || []).length) {
      return [];
    }

    const data = [];

    mantenimientos.forEach(mnto => {
      (mnto.integrantes || []).forEach(int => {
        data.push({
          counter: mnto.counter,
          mantenimientoNombre: mnto.mantenimientoNombre,
          integrante: int.integrante,
          precio: int.precio,
          precioCat: int.precioCargoAutomatico,
          precioDeb: int.precioDebito
        });
      });
    });

    return data;
  };

  render() {
    return (
      <Container className="p-0 mb-4">
        <div>
          <p className={`${s.primaryTitle} w-100 m-0 text-center`}>
            {(this.props.membershipData || {}).membresiaNombre || ""}
          </p>
        </div>

        <ReactTable
          manual
          data={this.getTabledata()}
          columns={this.columns}
          className="mt-2 -striped -highlight"
          sortable={false}
          resizable={false}
          showPagination={false}
          defaultPageSize={3}
          getTrProps={(state, rowInfo) => {
            if (rowInfo) {
              return {
                style: {
                  background:
                    rowInfo.original.counter % 2 == 0 ? "#FFF" : "#EDEDED",
                  color: rowInfo.original.counter % 2 == 0 ? "" : ""
                }
              };
            } else {
              return {};
            }
          }}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(PricesGridMembershipComponent));
