import React from "react";
import { TabPane, Container, Row, Col, Button } from "reactstrap";
import ModalFrame from "../../../../toolkit/modalFrame";
import { LAST_MONTH } from "../../../../Quotations/components/QuotationTable";
import { getEmptyFilters } from "../../../../Quotations/utils";
import QuotationTableFutureQuote from "../../../../Quotations/components/QuotationsTableFutureQuote";
import { createMaintenanceChargesRequest } from "../../../actions/products";
import { handleRequestError } from "../../../../utils/helpers";
import { Partner } from "../interfaces";
import { FIRST_MONTH } from "../../../../Quotations/components/QuotationsTableFutureQuote/index";

interface Props {
  partner: Partner;
  futureChargesQuote: object;
  invalidateStateValue: (fieldName: string, value: any) => void;
  refreshPartner: () => void;
}

interface State {
  isOpen: boolean;
  data: object;
  lastMonth: number;
  saving: boolean;
}

let filters = getEmptyFilters();

const Quotations = props => {
  const handleSwitch = () => {
    props.toggle({
      clubId: filters.club,
      mantenimientoId: filters.mantenimiento,
      membresiaId: filters.membresia
    });
  };

  const onChangeMonth = month => {
    props.changeState(month);
  };

  return (
    <Container className="w-100 p-0 my-3">
      <Row>
        <Col lg={12} md={12} xs={12} className="w-100 p-0 m-0 test-class">
          <QuotationTableFutureQuote
            quotation={props.futureChargesQuote || {}}
            enableColumnEdition={true}
            onChangeMonth={onChangeMonth}
            keepIntegrantes={true}
          />
        </Col>
      </Row>

      <Row>
        <Col className="pl-0">
          <Button onClick={handleSwitch}>Generar cargos adelantados</Button>
        </Col>
      </Row>
    </Container>
  );
};

//******************************Other Component**************************************
class TabPayments extends React.Component<Props, State> {
  state = {
    isOpen: false,
    data: null,
    lastMonth: FIRST_MONTH,
    saving: false
  };

  confirm = () => {
    const { partner, futureChargesQuote } = this.props;
    const startDate =
      futureChargesQuote.preciosFechaMantenimiento[0].fechaInicio;
    const finalDate =
      futureChargesQuote.preciosFechaMantenimiento[this.state.lastMonth - 1]
        .fechaFin;

    this.setState({ saving: true }, () => {
      createMaintenanceChargesRequest({
        startDate,
        finalDate,
        idMembresiaSocio: partner.idMembresiaSocio
      })
        .then(() => {
          this.setState({ isOpen: false, saving: false });

          if (!!this.props.invalidateStateValue) {
            this.props.invalidateStateValue("movementsList", []);
          }

          this.props.refreshPartner();
        })
        .catch(handleRequestError);
    });
  };

  toggleModal = (data?: object) =>
    this.setState({ isOpen: !this.state.isOpen, data: data });

  changeState = month => {
    this.setState({ lastMonth: month });
  };

  render() {
    return (
      <TabPane className="my-2" tabId="9">
        <ModalFrame
          title={"Generar cargos adelantados"}
          isOpen={this.state.isOpen}
          toggle={this.toggleModal}
        >
          <Container>
            <Row>
              <p className={`text-center w-100`}>
                Se generará el cargo adelantado para los meses seleccionados.
                <br />
                ¿Desea continuar?
              </p>
            </Row>
            <Row>
              <Col xs={6} className="text-center">
                <Button
                  onClick={this.confirm}
                  color="primary"
                  disabled={!!this.state.saving}
                >
                  Aceptar
                </Button>
              </Col>

              <Col xs={6} className="text-center">
                <Button color="secondary" onClick={this.toggleModal}>
                  Cancelar
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalFrame>
        <Container className="p-0 m-0">
          <Row className="p-0 m-0">
            <Quotations
              futureChargesQuote={this.props.futureChargesQuote}
              toggle={this.toggleModal}
              changeState={this.changeState}
            />
          </Row>
        </Container>
      </TabPane>
    );
  }
}
export default TabPayments;
