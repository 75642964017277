import IAction from "../../types/IAction";
import actionTypes from "../actionTypes";
import constants from "../../utils/constants";
import { restActionFactory } from "../../toolkit/axiosActions/axiosActionsFactory";
import axios from "axios";

const NOTAS_CREDITO_BASE_URL = "/api/maintenances/notas-credito";

// Facturas bloqueadas para generar notas de crédito
export const getNotaCreditoBloqueadas = (): IAction => {
  return notaCreditoRestAction({
    urlComplement: `/bloqueo/listado`,
    actionType: actionTypes.GET_NOTA_CREDITO_BLOQUEADAS,
    method: "get"
  });
};

// Bloqueo de factura para generar nota de crédito o cancelación
export const postNotaCreditoBloqueada = (facturaFolio: string): IAction => {
  return notaCreditoRestAction({
    urlComplement: `/bloqueo/${facturaFolio}`,
    actionType: actionTypes.POST_NOTA_CREDITO_BLOQUEADA,
    method: "post"
  });
};

// Bloqueo de factura para generar nota de crédito o cancelación
export const deleteNotaCreditoBloqueada = (facturaUUID: string): IAction => {
  return notaCreditoRestAction({
    urlComplement: `/bloqueo/${facturaUUID}`,
    actionType: actionTypes.DELETE_NOTA_CREDITO_BLOQUEADA,
    method: "delete"
  });
};

// Carga de archivo de fechas de cancelaciones del SAT
export const postUploadSatCancelaciones = (file): Promise<any> => {
  const data = new FormData();
  data.append("file", file);

  return axios.post(
    `${constants.BASE_URL}${NOTAS_CREDITO_BASE_URL}/sat-cancelaciones/upload`,
    data,
    {
      withCredentials: true
    }
  );
};

// Procesamiento de fechas de cancelaciones del SAT
export const postSatCancelacionesConfirmar = (
  porCambiarFecha
): Promise<any> => {
  return axios.post(
    `${constants.BASE_URL}${NOTAS_CREDITO_BASE_URL}/sat-cancelaciones/confirmar`,
    porCambiarFecha,
    {
      withCredentials: true
    }
  );
};

// Función que genera IAction's para manipular Notas de Crédito
const notaCreditoRestAction = restActionFactory(
  `${constants.BASE_URL}${NOTAS_CREDITO_BASE_URL}`
);
