import React, { useState } from "react";
import UserSelector from "../../../Selectors/containers/UserSelector";
import { Container, Row, Col, Button } from "reactstrap";
import TextArea from "../../../toolkit/baseInput/TextArea";

export default ({ saveNewUserActivity }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [description, setDescription] = useState(null);

  return (
    <Container>
      <Row>
        <Col>
          <UserSelector
            onChange={setUserInfo}
            value={userInfo ? userInfo.userId : ""}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextArea
            value={description}
            onChange={setDescription}
            label="Descripcion"
            id="descripcionNewUserActivity"
          />
        </Col>
      </Row>
      <Row>
        <Col />
        <Col>
          <Button
            className="primaryButton"
            disabled={!(userInfo && userInfo.userId) || !description}
            onClick={e => {
              e.preventDefault();
              saveNewUserActivity({
                userId: userInfo.userId,
                descripcion: description
              });
            }}
            size="sm"
            block
          >
            Guardar Nueva Actividad
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
