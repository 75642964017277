import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Container, Row, Col, Button } from "reactstrap";
import { infoNotification } from "../../../utils/notifications";
import { downloadBlackpass } from "../../actions/reports";
import BaseInput from "../../../toolkit/baseInput";

interface Props {}

interface State {
  wait: boolean;
  anio: any;
  mes: any;
}

class BlackpassPage extends React.Component<Props, State> {
  state = {
    wait: false,
    anio: 2023,
    mes: 1
  };

  submit = evt => {
    evt.preventDefault();

    this.setState({
      wait: true
    });

    infoNotification("Descargando reporte...");
    downloadBlackpass(
      this.state.anio,
      this.state.mes,
      meses[this.state.mes],
      () => {
        this.setState({
          wait: false
        });
      }
    );
  };

  render() {
    return (
      <Container className="mt-3 p-0">
        {/* Filtros */}
        <Row className="mt-4">
          {/* Selector de archivo */}
          <Col md="3">
            <BaseInput
              label="Mes"
              name="mes"
              id="mes"
              type="select"
              options={mesesOpts}
              placeholder="Mes"
              value={this.state.mes}
              onChange={e => this.setState({ mes: e.target.value })}
              errors={[]}
            />
          </Col>
          <Col md="3">
            <BaseInput
              label="Año"
              name="anio"
              id="anio"
              type="select"
              options={[
                { label: 2023, value: 2023 },
                { label: 2024, value: 2024 },
                { label: 2025, value: 2025 }
              ]}
              placeholder="Año"
              value={this.state.anio}
              onChange={e => this.setState({ anio: e.target.value })}
              errors={[]}
            />
          </Col>
          <Col md="3">
            <Button
              type="button"
              className={`${s.primaryButton} mt-4`}
              size="sm"
              block
              disabled={this.state.wait}
              onClick={this.submit}
            >
              {this.state.wait ? "Descargando..." : "Descargar"}
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

const meses = {
  1: "Enero",
  2: "Febrero",
  3: "Marzo",
  4: "Abril",
  5: "Mayo",
  6: "Junio",
  7: "Julio",
  8: "Agosto",
  9: "Septiembre",
  10: "Octubre",
  11: "Noviembre",
  12: "Diciembre"
};

const mesesOpts = [];

for (const key in meses) {
  mesesOpts.push({
    label: meses[key],
    value: key
  });
}

export default withRouter(withStyles(s)(BlackpassPage));
