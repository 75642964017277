import React from "react";
import { connect } from "react-redux";

import IAction from "../../../types/IAction";
import CreateProspectModal from "../../components/createProspect";
import DeleteProspectModal from "../../components/deleteProspect";
import ActionProspectModal from "../../components/actionProspect";
import ActionLogProspectModal from "../../components/actionLogProspect";
import StatusProspectModal from "../../components/statusProspect";
import StatusActivityModal from "../../components/statusActivity";
import PromotionDetailModal from "../../components/promotionDetail";
import ConfirmBenefitsModal from "../../components/confirmBenefits";
import DeletePartnerModal from "../../components/deletePartner";
import AddPersonModal from "../addPerson";
import CreateClubModal from "../../components/createClub";
import CreateBankModal from "../../components/createBank";
import EditBankMonthsWithoutInterest from "../../components/editBankMonthsWithoutInterestModal";
import {
  editStatusActivity,
  toggleCreateProspectModal,
  toggleLinkPartnerModal,
  toggleAddPersonModal,
  toggleConfirmBenefitsModal,
  toggleChangeStatusActivity,
  togglePromotionDetailModal,
  toggleAgreementDetailModal,
  toggleCreateAgreementModal,
  toggleCreateClubModal,
  toggleDeleteProspectModal,
  toggleActionProspectModal as toggleActionsProspectModal,
  toggleActionLogProspectModal as toggleActionsLogProspectModal,
  toggleStatusProspectModal,
  toggleCreateBankModal,
  toggleEditMonthsWithoutInterest,
  toggleEditSession,
  setProspectToEdit
} from "../../actions/modals";
import {
  createProspect,
  createActivity,
  editProspect,
  deleteProspect,
  patchProspect,
  getRepeatedProspects
} from "../../actions/prospects";
import {
  editAgreement,
  getAgreementDetailById,
  createAgreement,
  disableAgreementCreated,
  getBasicAgreements
} from "../../actions/agreements";
import { editClub, createClub, disableClubCreated } from "../../actions/clubs";
import { getClubs, getCompanies } from "../../actions/catalogs";
import {
  getPromotionDetailById,
  cancelBenefitsChanges,
  confirmBenefitsChanges
} from "../../actions/promotions";
import {
  editBank,
  createBank,
  createMonthsWithoutInterest,
  editMonthsWithoutInterest,
  disableBankCreated
} from "../../actions/banks";
import {
  deletePartner,
  toggleDeletePartner,
  toggleChangeMembership
} from "../../actions/partners";
import { editSession, postSession } from "../../actions/events";
import { getAllClubsBasicData } from "../../actions/clubs";
import { Prospect } from "../../../types-business/User";
import { Club } from "../../../types/Clubs";
import { Banco } from "../../../types/Banks";
import { Agreement } from "../../../types-business/Agreements";
import LinkPartnerModal from "../linkPartnerModal";
import EditSessionModal from "../../components/editSessionModal";
import { getEmpresasConvenio } from "../../actions/convenios";

interface Props {
  showCreateProspectModal: boolean;
  showActionsProspectModal: boolean;
  showActionsLogProspectModal: boolean;
  showStatusProspectModal: boolean;
  showCreateAgreementModal: boolean;
  showCreateClubModal: boolean;
  showPromotionDetailModal: boolean;
  showAgreementDetailModal: boolean;
  showConfirmBenefitsModal: boolean;
  showCreateBankModal: boolean;
  showEditBankMonthsWithoutInterestModal: boolean;
  toggleCreateProspectModal: () => void;
  toggleConfirmBenefitsModal: () => void;
  toggleCreateAgreementModal: () => void;
  toggleCreateClubModal: () => void;
  toggleCreateBankModal: () => void;
  toggleActionsProspectModal: () => void;
  toggleActionsLogProspectModal: () => void;
  toggleStatusProspectModal: () => void;
  togglePromotionDetailModal: () => void;
  toggleAgreementDetailModal: () => void;
  toggleEditMonthsWithoutInterest: () => void;
  showDeleteProspectModal: boolean;
  toggleDeleteProspectModal: () => void;
  createProspect: (prospect: Prospect) => void;
  createActivity: (personaId: string, activity: object) => void;
  deleteProspect: (string) => void;
  editProspect: (personaId: string, prospect: Prospect) => void;
  editAgreement: (agreementId: string, agreement: Agreement) => void;
  editClub: (clubId: string, club: Club) => void;
  editStatusProspect: (prospect: Prospect, body: any) => void;
  editBank: (bancoId: string, banco: Banco) => void;
  creatingProspect: boolean;
  creatingProspectError: any;
  deletingProspect: boolean;
  deletingProspectError: any;
  prospectToEdit: object;
  agreementToEdit: object;
  clubToEdit: object;
  bankToEdit: object;
  prospectToDelete: object;
  statusProspect: object;
  actionProspect: object;
  actionLogProspect: object;
  userId: string;
  patchProspect: boolean;
  patchProspectError: object;
  getClubs: () => void;
  getCompanies: () => void;
  companiesTypes: () => object[];
  users: object[];
  generalManagers: object[];
  clubs: object[];
  createAgreement: (fields: object) => any;
  createClub: (fields: object) => any;
  createBank: (fields: object) => any;
  createMonthsWithoutInterest: (fields: object) => any;
  editMonthsWithoutInterest: (fields: object, string) => any;
  agreementCreated: boolean;
  clubCreated: boolean;
  disableAgreementCreated: () => any;
  disableClubCreated: () => any;
  disableBankCreated: () => any;
  creatingAgreement: boolean;
  creatingAgreementError: object;
  creatingClub: boolean;
  creatingClubError: object;
  getProspects: (name?: string, birthdate?: string, lastName?: string) => any;
  repeatedProspects: object;
  openChangeStatusActivity: boolean;
  toggleChangeStatusActivity: () => any;
  activityId: string;
  editStatusActivity: (activityId: string, status: string) => any;
  changingActivityStatus: boolean;
  changingActivityStatusError: object;
  promotion: object;
  getPromotionDetailById: (id: string) => void;
  getAgreementDetailById: (id: string) => void;
  promotionDetail: any;
  agreement: object;
  confirmBenefitsChanges: () => any;
  cancelBenefitsChanges: () => any;
  toggleLinkPartnerModal: () => any;
  showLinkPartnerModal: boolean;
  showLinkPartnerModalAsFreePartner: boolean;
  showLinkPartnerModalAsChangeFreePartner: boolean;
  showLinkPartnerModalAsFreeChildPartner: boolean;
  linkPartnerTitle: string;
  toggleAddPersonModal: () => any;
  showAddPersonModal: boolean;
  bankCreated: boolean;
  creatingBank: boolean;
  creatingBankError: object;
  creatingActivity: boolean;
  usersList: Array<User>;
  usersPage: Array<User>;
  sellers: object[];
  toggleEditSession: (id?: number, edit?: boolean) => IAction;
  showEditSessionModal: boolean;
  editSessionModal: any;
  newSessionId: number;
  postSession: (eventClass?) => IAction;
  editSession: (eventClass?) => IAction;
  showDeletePartner: boolean;
  toggleDeletePartner: () => IAction;
  selectedPartner: PARTNER;
  deletePartner: (
    id: string,
    fecha: string,
    motive: string,
    idMembresiaSocio: string
  ) => IAction;
  idMembresiaSocio: string;
  toggleChangeMembership: () => IAction;
  showChangeMembership: boolean;
  membershipsList: object[];
  membershipName: object;
  changeMembership: (id: number, newMemb: number) => IAction;
  getAllClubsBasicData: () => void;
  allClubsBasicData: object[];
  setProspectToEdit: (prospect: object) => any;
  empresasConvenio: any[];
  getEmpresasConvenio: () => any;
  changeFreePartnerData: any;
}

interface User {
  id: string;
  name: string;
  username: string;
}

interface PARTNER {
  name: String;
  id: String;
}
class Modals extends React.Component<Props, void> {
  render() {
    return (
      <div>
        <CreateProspectModal
          isOpen={this.props.showCreateProspectModal}
          toggle={this.props.toggleCreateProspectModal}
          createProspect={this.props.createProspect}
          editProspect={this.props.editProspect}
          creatingProspect={this.props.creatingProspect}
          creatingProspectError={this.props.creatingProspectError}
          userId={this.props.userId}
          prospectToEdit={this.props.prospectToEdit}
          getClubs={this.props.getClubs}
          clubs={this.props.clubs}
          getProspects={this.props.getProspects}
          repeatedProspects={this.props.repeatedProspects}
          setProspectToEdit={this.props.setProspectToEdit}
          getEmpresasConvenio={this.props.getEmpresasConvenio}
          empresasConvenio={this.props.empresasConvenio}
        />
        <DeleteProspectModal
          isOpen={this.props.showDeleteProspectModal}
          toggle={this.props.toggleDeleteProspectModal}
          prospect={this.props.prospectToDelete}
          deleteProspect={this.props.deleteProspect}
          deletingProspect={this.props.deletingProspect}
          deletingProspectError={this.props.deletingProspectError}
        />
        <ActionProspectModal
          isOpen={this.props.showActionsProspectModal}
          toggle={this.props.toggleActionsProspectModal}
          prospect={this.props.actionProspect}
          createActivity={this.props.createActivity}
          creatingActivity={this.props.creatingActivity}
          userId={this.props.userId}
        />
        <ActionLogProspectModal
          isOpen={this.props.showActionsLogProspectModal}
          toggle={this.props.toggleActionsLogProspectModal}
          prospect={this.props.actionLogProspect}
        />
        <StatusProspectModal
          isOpen={this.props.showStatusProspectModal}
          toggle={this.props.toggleStatusProspectModal}
          prospect={this.props.statusProspect}
          editStatusProspect={this.props.editStatusProspect}
          statusProspect={this.props.patchProspect}
          statusProspectError={this.props.patchProspectError}
        />
        <StatusActivityModal
          isOpen={this.props.openChangeStatusActivity}
          toggle={this.props.toggleChangeStatusActivity}
          activityId={this.props.activityId}
          editStatusActivity={this.props.editStatusActivity}
          changingActivityStatus={this.props.changingActivityStatus}
          changingActivityStatusError={this.props.changingActivityStatusError}
        />
        {this.props.showCreateClubModal && (
          <CreateClubModal
            isOpen={this.props.showCreateClubModal}
            toggle={this.props.toggleCreateClubModal}
            clubToEdit={this.props.clubToEdit}
            editClub={this.props.editClub}
            users={this.props.usersPage}
            generalManagers={this.props.generalManagers}
            createClub={this.props.createClub}
            clubCreated={this.props.clubCreated}
            disableClubCreated={this.props.disableClubCreated}
            creatingClub={this.props.creatingClub}
            creatingClubError={this.props.creatingClubError}
            catalogClubs={this.props.allClubsBasicData}
            getCompanies={this.props.getCompanies}
            companiesTypes={this.props.companiesTypes}
          />
        )}
        <PromotionDetailModal
          isOpen={this.props.showPromotionDetailModal}
          toggle={this.props.togglePromotionDetailModal}
          promotion={this.props.promotion}
          getPromotionDetailById={this.props.getPromotionDetailById}
          promotionDetail={this.props.promotionDetail}
        />
        <ConfirmBenefitsModal
          isOpen={this.props.showConfirmBenefitsModal}
          toggle={this.props.toggleConfirmBenefitsModal}
          confirmChanges={this.props.confirmBenefitsChanges}
          cancelChanges={this.props.cancelBenefitsChanges}
        />
        <LinkPartnerModal
          isOpen={this.props.showLinkPartnerModal}
          toggle={this.props.toggleLinkPartnerModal}
          isFreePartner={this.props.showLinkPartnerModalAsFreePartner}
          isChangeFreePartner={
            this.props.showLinkPartnerModalAsChangeFreePartner
          }
          changeFreePartnerData={this.props.changeFreePartnerData}
          isFreeChildPartner={this.props.showLinkPartnerModalAsFreeChildPartner}
          linkPartnerTitle={this.props.linkPartnerTitle}
        />
        <AddPersonModal
          isOpen={this.props.showAddPersonModal}
          toggle={this.props.toggleAddPersonModal}
        />
        <CreateBankModal
          isOpen={this.props.showCreateBankModal}
          toggle={this.props.toggleCreateBankModal}
          bankToEdit={this.props.bankToEdit}
          editBank={this.props.editBank}
          createBank={this.props.createBank}
          creatingBank={this.props.creatingBank}
          creatingBankError={this.props.creatingBankError}
          bankCreated={this.props.bankCreated}
          disableBankCreated={this.props.disableBankCreated}
        />
        <EditBankMonthsWithoutInterest
          toggle={this.props.toggleEditMonthsWithoutInterest}
          isOpen={this.props.showEditBankMonthsWithoutInterestModal}
          bankToEdit={this.props.bankToEdit}
          editMonthsWithoutInterest={this.props.editMonthsWithoutInterest}
          createMonthsWithoutInterest={this.props.createMonthsWithoutInterest}
          creatingBank={this.props.creatingBank}
          creatingBankError={this.props.creatingBankError}
        />
        <EditSessionModal
          isOpen={this.props.showEditSessionModal}
          toggleModal={this.props.toggleEditSession}
          editEvent={this.props.editSessionModal}
          sellers={this.props.sellers}
          postSession={this.props.postSession}
          editSession={this.props.editSession}
          newSessionId={this.props.newSessionId}
        />

        <DeletePartnerModal
          isOpen={this.props.showDeletePartner}
          toggleModal={this.props.toggleDeletePartner}
          selectedPartner={this.props.selectedPartner}
          deletePartner={this.props.deletePartner}
          idMembresiaSocio={this.props.idMembresiaSocio}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  modals,
  user,
  clubs,
  convenios,
  catalogs,
  prospects,
  banks,
  sportsProducts,
  partners,
  products
}) => {
  return {
    showCreateProspectModal: modals.showCreateProspectModal,
    showConfirmBenefitsModal: modals.showConfirmBenefitsModal,
    showCreateAgreementModal: modals.showCreateAgreementModal,
    openChangeStatusActivity: modals.openChangeStatusActivity,
    showCreateClubModal: modals.showCreateClubModal,
    showActionsProspectModal: modals.showActionsProspectModal,
    creatingActivity: modals.creatingActivity,
    showActionsLogProspectModal: modals.showActionsLogProspectModal,
    showStatusProspectModal: modals.showStatusProspectModal,
    showDeleteProspectModal: modals.showDeleteProspectModal,
    showLinkPartnerModal: modals.showLinkPartnerModal,
    showLinkPartnerModalAsFreePartner: modals.showLinkPartnerModalAsFreePartner,
    showLinkPartnerModalAsChangeFreePartner:
      modals.showLinkPartnerModalAsChangeFreePartner,
    showLinkPartnerModalAsFreeChildPartner:
      modals.showLinkPartnerModalAsFreeChildPartner,
    linkPartnerTitle: modals.linkPartnerTitle,
    showAddPersonModal: modals.showAddPersonModal,
    showCreateBankModal: modals.showCreateBankModal,
    creatingProspect: prospects.creatingProspect,
    creatingProspectError: prospects.creatingProspectError,
    deletingProspect: prospects.deletingProspect,
    deletingProspectError: prospects.deletingProspectError,
    prospectToEdit: modals.prospectToEdit,
    clubToEdit: modals.clubToEdit,
    bankToEdit: modals.bankToEdit,
    prospectToDelete: modals.prospectToDelete,
    statusProspect: modals.statusProspect,
    actionProspect: modals.actionProspect,
    actionLogProspect: modals.actionLogProspect,
    patchProspect: prospects.patchProspect,
    patchProspectError: prospects.patchProspectError,
    userId: user.userContext.userId,
    clubs: catalogs.clubs,
    companiesTypes: catalogs.companiesTypes,
    users: user.usersList,
    usersPage: user.usersPage,
    generalManagers: user.generalManagers,
    clubCreated: clubs.clubCreated,
    creatingClub: clubs.creatingClub,
    creatingClubError: clubs.createClubError,
    repeatedProspects: user.repeatedProspects,
    activityId: modals.activityId,
    changingActivityStatus: modals.changingActivityStatus,
    changingActivityStatusError: modals.changingActivityStatusError,
    showPromotionDetailModal: modals.showPromotionDetailModal,
    promotion: modals.promotion,
    promotionDetail: modals.promotionDetail,
    creatingBank: banks.creatingBank,
    creatingBankError: banks.createBankError,
    bankCreated: banks.bankCreated,
    showEditBankMonthsWithoutInterestModal:
      modals.showEditBankMonthsWithoutInterestModal,
    usersList: user.usersList,
    sellers: catalogs.sellers,
    showEditSessionModal: modals.showEditSessionModal,
    editSessionModal: modals.editSession,
    newSessionId: sportsProducts.newSessionProductId,
    showDeletePartner: partners.showDeletePartner,
    selectedPartner: partners.selectedPartner,
    idMembresiaSocio: partners.idMembresiaSocio,
    showChangeMembership: partners.showChangeMembership,
    membershipName: partners.partner,
    membershipsList: products.memberships,
    allClubsBasicData: clubs.allClubsBasicData,
    empresasConvenio: convenios.empresasConvenio,
    changeFreePartnerData: modals.changeFreePartnerData
  };
};

const mapDispatchToProps = {
  toggleCreateProspectModal,
  toggleLinkPartnerModal,
  toggleAddPersonModal,
  toggleConfirmBenefitsModal,
  toggleChangeStatusActivity,
  togglePromotionDetailModal,
  toggleAgreementDetailModal,
  toggleCreateAgreementModal,
  toggleCreateClubModal,
  toggleDeleteProspectModal,
  toggleActionsProspectModal,
  toggleActionsLogProspectModal,
  toggleStatusProspectModal,
  toggleCreateBankModal,
  toggleEditMonthsWithoutInterest,
  toggleEditSession,
  editStatusActivity,
  createProspect,
  createActivity,
  editProspect,
  deleteProspect,
  editStatusProspect: patchProspect,
  editAgreement,
  getAgreementDetailById,
  createAgreement,
  disableAgreementCreated,
  getClubs,
  getCompanies,
  editClub,
  createClub,
  disableClubCreated,
  getProspects: getRepeatedProspects,
  getPromotionDetailById,
  cancelBenefitsChanges,
  confirmBenefitsChanges,
  editBank,
  createBank,
  createMonthsWithoutInterest,
  editMonthsWithoutInterest,
  disableBankCreated,
  postSession,
  editSession,
  toggleDeletePartner,
  deletePartner,
  toggleChangeMembership,
  getAllClubsBasicData,
  setProspectToEdit,
  getBasicAgreements,
  getEmpresasConvenio
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Modals);
