import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Container, Row, Col, Button } from "reactstrap";
import { infoNotification } from "../../../utils/notifications";
import { downloadVentasPortal } from "../../actions/reports";

interface Props {}

interface State {
  wait: boolean;
}

class VentasPortalReportePage extends React.Component<Props, State> {
  state = {
    wait: false
  };

  submit = evt => {
    evt.preventDefault();

    this.setState({
      wait: true
    });

    infoNotification("Descargando reporte...");
    downloadVentasPortal(() => {
      this.setState({
        wait: false
      });
    });
  };

  render() {
    return (
      <Container className="mt-3 p-0">
        {/* Filtros */}
        <Row className="mt-4">
          {/* Selector de archivo */}
          <Col md="12">
            Incluye todas las membresías adquiridas por Portal Web
          </Col>
          <Col md="2">
            <Button
              type="button"
              className={`${s.primaryButton} mt-4`}
              size="sm"
              block
              disabled={this.state.wait}
              onClick={this.submit}
            >
              {this.state.wait ? "Descargando..." : "Descargar"}
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(VentasPortalReportePage));
