import React from "react";
import Webcam from "react-webcam";
import { Container, Row, Col, Button } from "reactstrap";
import s from "./WebCamCapture.scss";
import axios from "axios";

const WebcamCapture = ({ saveImage, personId }) => {
  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);
  const [captured, setCaptured] = React.useState(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    setCaptured(true);
  }, [webcamRef, setImgSrc]);

  const restartCam = React.useCallback(() => {
    setImgSrc(null);
    setCaptured(false);
  }, [webcamRef, setImgSrc]);

  const videoConstraints = {
    width: 480,
    height: 270
  };

  return (
    <div>
      <Row>
        {!captured && (
          <Col>
            <Webcam
              width="480"
              height="360"
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
            />
          </Col>
        )}
        {!!captured && <Col>{imgSrc && <img src={imgSrc} />}</Col>}
      </Row>
      <br />
      <Row>
        <Col>
          {!captured && (
            <Button
              className={`${s.buttonMarginTop} ${s.primaryButton} m-2`}
              onClick={capture}
              size="sm"
            >
              Tomar foto
            </Button>
          )}
          {!!captured && (
            <Button
              className={`${s.buttonMarginTop} ${s.primaryButton} m-2`}
              onClick={restartCam}
              size="sm"
            >
              Volver a tomar foto
            </Button>
          )}
          {!!captured && (
            <Button
              className={`${s.buttonMarginTop} ${s.primaryButton} m-2`}
              onClick={() => {
                if (!!saveImage) {
                  axios
                    .get(imgSrc, {
                      responseType: "blob"
                    })
                    .then(response => {
                      saveImage(
                        new File([response.data], `${personId}.jpeg`, {
                          type: "image/jpeg"
                        })
                      );
                      //restartCam();
                    });
                }
              }}
              size="sm"
            >
              Guardar
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default WebcamCapture;
