import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import { Button, Col, Container, Row } from "reactstrap";
import BaseInput from "../../../../toolkit/baseInput";
import ModalFrame from "../../../../toolkit/modalFrame";
import { handleRequestError } from "../../../../utils/helpers";
import {
  infoNotification,
  successNotification
} from "../../../../utils/notifications";
import { associatePromotion } from "../../../actions/partners";
import { Partner } from "../interfaces";
import s from "../styles.scss";

interface Props {
  partner: Partner;
  isOpen: boolean;
  promociones: any[];
  cancel: () => any;
  success: () => any;
}

interface State {
  selectedTipo: String;
  selectedPromocionId: number;
  promocionesFiltered: any[];
}

class PromotionAssociationModal extends React.Component<Props, State> {
  state = {
    selectedTipo: null,
    selectedPromocionId: null,
    promocionesFiltered: []
  };

  componentDidMount() {}

  handleSubmit = () => {
    const { selectedPromocionId } = this.state;
    const { idMembresiaSocio } = this.props.partner;

    infoNotification("Aplicando promoción...");

    associatePromotion(idMembresiaSocio, selectedPromocionId)
      .then(() => {
        successNotification("Promoción aplicada correctamente");
        this.setState({
          selectedTipo: null,
          selectedPromocionId: null,
          promocionesFiltered: []
        });
        this.props.success();
      })
      .catch(handleRequestError);
  };

  hanldeChangeTipo = (tipo: string) => {
    if (!tipo) {
      this.setState({ promocionesFiltered: [] });
      return;
    }

    const { promociones } = this.props;
    const filtered = (promociones || []).filter(
      x => x.tipoUsoPromocion == tipo
    );
    this.setState({ promocionesFiltered: filtered });
  };

  handleCancel = () => {
    this.setState({ selectedTipo: null, selectedPromocionId: null });
    this.props.cancel();
  };

  render() {
    return (
      <ModalFrame
        title={`Asociación de promoción`}
        isOpen={this.props.isOpen}
        toggle={() => {}}
        size={"md"}
      >
        <Container style={{ width: "90%" }} className="center-block p-0 mb-3">
          <div>
            <BaseInput
              type="select"
              id="iTipoPromocion"
              name="iTipoPromocion"
              label={"Tipo"}
              value={this.state.selectedTipo}
              onChange={e => {
                const value = e.target.value;
                this.setState({ selectedTipo: value });
                this.hanldeChangeTipo(value);
              }}
              options={[{ value: null, label: "Seleccione" }].concat([
                { value: "VENTA", label: "VENTA" },
                { value: "REACTIVACION", label: "REACTIVACION" }
              ])}
            />
          </div>
          <div>
            <BaseInput
              type="select"
              id="iPromocionId"
              name="iPromocionId"
              label={"Promoción"}
              value={this.state.selectedPromocionId}
              onChange={e => {
                const value = e.target.value;
                this.setState({ selectedPromocionId: value });
              }}
              options={[{ value: 0, label: "Seleccione" }].concat(
                (this.state.promocionesFiltered || []).map(x => {
                  return { value: x.id, label: x.nombre };
                })
              )}
            />
          </div>

          <div className="mt-3 mb-3" style={{ fontStyle: "bold" }}>
            <span>
              Nota: Se aplicarán los beneficios de invitados gratis y pases de
              invitado gratis correspondientes a la promoción
            </span>
          </div>

          <Row className="m-0">
            <Col xs="12" md="5">
              <Button
                className={`mt-3 primaryButton`}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.handleSubmit();
                }}
                block
                disabled={
                  !this.state.selectedTipo || !this.state.selectedPromocionId
                }
              >
                Guardar
              </Button>
            </Col>
            <Col xs="12" md="2"></Col>
            <Col xs="12" md="5">
              <Button
                className={` mt-3`}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.handleCancel();
                }}
                block
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </Container>
      </ModalFrame>
    );
  }
}

export default withStyles(s)(PromotionAssociationModal);
