import { faTag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import {
  Button,
  Col,
  Container,
  Form,
  Label,
  Row,
  UncontrolledTooltip
} from "reactstrap";
import BaseAlphabeticInput from "../../../toolkit/baseAlphabeticInput";
import BaseInput from "../../../toolkit/baseInput";
import BaseNumericInput from "../../../toolkit/baseNumericInput";
import { userHasPermission } from "../../../utils/accessTree";
import CONSTANTS from "../../../utils/constants";
import { handleRequestError } from "../../../utils/helpers";
import {
  errorNotification,
  infoNotification,
  successNotification
} from "../../../utils/notifications";
import permissionCodes from "../../../utils/permissionCodes";
import { getClubsRequest } from "../../actions/catalogs";
import {
  saveCrm1PartnerMembershipsForReactivation,
  searchCrm1PartnerMembershipsForReactivation
} from "../../actions/partners";
import { Props, State } from "./interfaces";
import s from "./styles.scss";
if (process.env.BROWSER) require("bootstrap");

class Crm1PartnerMembershiptReactivate extends React.Component<Props, State> {
  state = {
    clubsCatalog: [],
    searching: false,
    filters: {
      clubId: null,
      publicoId: null,
      personaId: null,
      personaNombre: null
    },
    data: []
  };

  componentDidMount() {
    this.getClubsCatalog();
  }

  getClubsCatalog = () => {
    getClubsRequest()
      .then(({ data }) => {
        this.setState({ clubsCatalog: data });
      })
      .catch(handleRequestError);
  };

  searchData = async () => {
    await this.setState({
      data: [],
      searching: true
    });

    searchCrm1PartnerMembershipsForReactivation({ ...this.state.filters }).then(
      ({ data }) => {
        data.forEach(element => {
          //Nombre del propietario
          const { propietario, socios } = element;
          if (!!propietario) {
            const propietarioNombre = `${propietario.nombre ||
              ""} ${propietario.paterno || ""} ${propietario.materno || ""}`;
            element["propietarioNombre"] = propietarioNombre;
          }

          //Nombre del titular
          if (!!socios && !!socios.length) {
            const titular = socios.find(
              s => (s.tipoCliente || "").toLowerCase() == "titular"
            );
            console.info(socios, titular);
            if (!!titular) {
              const titularNombre = `${titular.nombre ||
                ""} ${titular.paterno || ""} ${titular.materno || ""}`;
              element["titularNombre"] = titularNombre;
            }
          }

          const club = this.state.clubsCatalog.find(
            c => c.clubId == parseInt(element.idUn)
          );
          if (!!club) {
            element["idUnNombre"] = club.nombre;
          }
        });

        this.setState({ data, searching: false });
      }
    );
  };

  handleReactivatePartnerMembership = async item => {
    await this.setState({
      searching: true
    });

    infoNotification("Reactivando membresía de CRM1 en CRM2...");

    saveCrm1PartnerMembershipsForReactivation(item)
      .then(() => {
        successNotification("Membresía transferida correctamente.");
        const url = `/membresias-socios/${item.idUnicoMembresia}?idClub=${item.idUn}&idPublico=${item.idPublico}`;
        this.props.history.push(url);
      })
      .catch(() => {
        errorNotification("Error al transferir la membresía socio");
        this.setState({
          searching: false
        });
      });
  };

  render() {
    const columns: any = [
      {
        Header: "Club",
        id: "idUnNombre",
        accessor: "idUnNombre"
      },
      {
        Header: "# Membresía",
        id: "idPublico",
        accessor: "idPublico"
      },
      {
        Header: "Tipo membresía",
        id: "tipoMembresia",
        accessor: "tipoMembresia"
      },
      {
        Header: "Propietario",
        id: "propietarioNombre",
        accessor: "propietarioNombre"
      },
      {
        Header: "Titular",
        id: "titularNombre",
        accessor: "titularNombre"
      },
      {
        Header: "",
        id: "acciones",
        accessor: item => {
          return (
            <div className={`${s.buttonsContainer} ${s.myStyle}`}>
              <Row>
                {userHasPermission(
                  permissionCodes.PROSPECT_CHANGE_INTEREST_STATUS_PERMISSION
                ) && (
                  <Col className={s.marginLeftNil} xs={2}>
                    <a
                      id={`faTag${item.idUnicoMembresia}`}
                      onClick={() => {
                        this.handleReactivatePartnerMembership(item);
                      }}
                      className={`${s.statusAnchor}`}
                    >
                      <FontAwesomeIcon icon={faTag} />
                    </a>
                    <UncontrolledTooltip
                      placement="left"
                      target={`faTag${item.idUnicoMembresia}`}
                    >
                      Reactivar
                    </UncontrolledTooltip>
                  </Col>
                )}
              </Row>
            </div>
          );
        }
      }
    ];

    const allowedClubs = (this.state.clubsCatalog || []).filter(
      club => !!club.migradoCrm2
    );

    const { filters } = this.state;
    const allowedToSearch =
      !!filters.clubId ||
      !!filters.personaId ||
      !!filters.personaNombre ||
      !!filters.publicoId;

    return (
      <Container className="p-0 my-3">
        {/* Filtros de búsqueda de los prospectos */}
        <Form
          onSubmit={e => {
            e.preventDefault();
            this.searchData();
          }}
        >
          <Row>
            <Col sm="2">
              <BaseInput
                type="select"
                id={"filterClub"}
                name={"filterClub"}
                label={"Club"}
                placeholder="Club"
                value={this.state.filters.clubId}
                size="sm"
                onChange={e => {
                  const { filters } = this.state;
                  filters.clubId = e.target.value || null;
                  this.setState({ filters });
                }}
                options={[{ value: "", label: "Seleccionar un club" }].concat(
                  [...allowedClubs].map(option => ({
                    value: option.clubId,
                    label: option.nombre
                  }))
                )}
              />
            </Col>

            <Col xs={2}>
              <BaseNumericInput
                label={"# Membresía"}
                name="numeroMembresia"
                id="numeroMembresia"
                type="text"
                value={this.state.filters.publicoId}
                placeholder={"# Membresía"}
                onChange={e => {
                  const { filters } = this.state;
                  filters.publicoId = e.target.value || null;
                  this.setState({ filters });
                }}
              />
            </Col>

            <Col xs={2}>
              <BaseNumericInput
                label={"ID Persona"}
                name="personaId"
                id="personaId"
                type="text"
                value={this.state.filters.personaId}
                placeholder={"ID Persona"}
                onChange={e => {
                  const { filters } = this.state;
                  filters.personaId = e.target.value || null;
                  this.setState({ filters });
                }}
              />
            </Col>

            <Col xs={3}>
              <BaseAlphabeticInput
                id="personName"
                name="personName"
                label={"Nombre Persona"}
                value={this.state.filters.personaNombre}
                placeholder={"Nombre persona"}
                onChange={e => {
                  const { filters } = this.state;
                  filters.personaNombre = e.target.value || null;
                  this.setState({ filters });
                }}
              />
            </Col>

            <Col sm="2">
              <Label className={s.label}>&nbsp;</Label>
              <Button
                size="sm"
                className={`${s.searchButton} ${s.primaryButton} float-right btn-block`}
                disabled={!allowedToSearch || this.state.searching}
                type="submit"
              >
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>

        <div>&nbsp;</div>

        <ReactTable
          style={{ height: CONSTANTS.TABLES_HEIGHT }}
          data={this.state.data || []}
          columns={columns}
          className="w-100"
          pageSize={(this.state.data || []).length + 2}
          loading={this.state.searching}
          sortable={false}
          showPagination={false}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(Crm1PartnerMembershiptReactivate));
