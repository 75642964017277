import React from "react";
import s from "./styles.scss";
import { Styled } from "../../../../toolkit/decorators/Artifact";
import { Preview } from "../../../../types/IAction";
import { Button } from "reactstrap";
import { infoNotification } from "../../../../utils/notifications";

interface Props {
  // Identificador del convenio
  id: number;

  // Clubes asociados al convenio
  clubesAsignables: any[];
  clubesAsignablesLoading: boolean;
  clubesAsociados: number[];
  clubesAsociadosLoading: boolean;
  getClubesAsignables: () => Promise<Preview<any>>;
  getClubesAsociados: (idConvenio: number) => Promise<Preview<any>>;
  putClubesAsociados: (
    idConvenio: number,
    clubes: number[]
  ) => Promise<Preview<any>>;

  // Anuncio de formulario para confirmar el cambio de paso en el carrusel
  handleDirty: (dirty: boolean, dirtyMsg: string) => void;
}

interface State {
  selected: number[];
}

@Styled(s)
export default class ConvenioClubes extends React.Component<Props, State> {
  state = {
    selected: []
  };

  /// Ciclo de vida del componente
  componentDidMount() {
    this.props.getClubesAsociados(this.props.id);
    this.props.getClubesAsignables();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.clubesAsociados != this.props.clubesAsociados) {
      this.setState({
        selected: this.props.clubesAsociados
      });
    }
  }
  ////

  /// Gestión del formulario
  // Seleccionar todos
  onCheckAll = evt => {
    const value = evt.target.checked ? true : false;
    const selected = value
      ? [
          ...this.props.clubesAsignables
            .map(c => ({ id: c.clubId, nombre: c.nombre }))
            .map(c => c.id)
        ]
      : [];

    this.setState({ selected });
  };

  // Selección de un club
  onCheckClub = (evt, idClub) => {
    const value = evt.target.checked ? true : false;
    const selected = [...this.state.selected];
    const index = selected.indexOf(Number(idClub));

    if (value && index === -1) {
      selected.push(idClub);
    } else if (!value && index !== -1) {
      selected.splice(index, 1);
    }

    this.setState({ selected });
  };

  // Actualización de clubes
  submit = async evt => {
    const preview = await this.props.putClubesAsociados(
      this.props.id,
      this.state.selected
    );
    evt.preventDefault();

    if (preview.fulfilled) {
      infoNotification(`Clubes actualizados exitosamente`);
    }
  };
  ////

  render() {
    if (this.props.clubesAsignablesLoading && this.props.clubesAsignables) {
      /// JSX del componente cuando está en carga
      return (
        <div className="h-100">
          <p className="primaryTitle w-100">Clubes asociados al convenio</p>
          <p style={{ color: "#444", marginTop: "16px", fontSize: "14px" }}>
            Espere un momento por favor...
          </p>
        </div>
      );
      ////
    }

    return (
      <div className="h-100">
        <form>
          <p className="primaryTitle w-100">
            Clubes que admiten venta del convenio
          </p>
          {this.renderAlert()}

          <div className="form-row">
            <div className="col-3 mt-2">
              <div style={{ display: "inline-block", marginRight: "8px" }}>
                <input
                  id="all-clubes"
                  onChange={this.onCheckAll}
                  type="checkbox"
                  style={{
                    position: "unset",
                    margin: "0px",
                    marginLeft: "0px"
                  }}
                />
              </div>
              <label htmlFor="all-clubes" style={{ display: "inline-block" }}>
                Seleccionar todas
              </label>
            </div>
          </div>

          <hr />

          <div className="form-row">{this.renderClub()}</div>

          <div className="row">
            <div className="col" />
            <div className="col-2">
              <Button
                className={`mt-3 ${s.primaryButton}`}
                onClick={this.submit}
                size="sm"
                block
              >
                Guardar
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }

  renderClub = () => {
    /// JSX de checkboxes de Clubes
    return this.props.clubesAsignables ? (
      this.props.clubesAsignables
        .map(c => ({ id: c.clubId, nombre: c.nombre }))
        .map(e => (
          <div key={"club-ls-" + e.id} className="col-3 mt-2">
            <div
              id={"club-check-" + e.id}
              style={{
                display: "inline-block",
                marginRight: "8px",
                paddingTop: "1px",
                verticalAlign: "top"
              }}
            >
              <input
                onChange={evt => this.onCheckClub(evt, e.id)}
                type="checkbox"
                checked={this.state.selected.indexOf(e.id) >= 0}
                style={{ position: "unset", margin: "0px", marginLeft: "0px" }}
              />
            </div>
            <label
              htmlFor={"club-check-" + e.id}
              style={{
                display: "inline-block",
                width: "calc(100% - 24px)",
                fontSize: "12px"
              }}
            >
              {e.nombre}
            </label>
          </div>
        ))
    ) : (
      <></>
    );
    ////
  };

  renderAlert() {
    /// JSX de alerta de errores o warnings
    const clubesEnConvenio: number[] = this.props.clubesAsociados || [];
    if (
      !this.props.clubesAsociadosLoading &&
      this.props.id &&
      clubesEnConvenio.length == 0
    ) {
      return (
        <div className="alert alert-warning mt-3">
          El convenio no tienen asociada ningun club.
        </div>
      );
    }

    return <></>;
    ////
  }
}
