import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import React from "react";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table";
import { Button, Col, Container, Row } from "reactstrap";
import MexicanCurrency from "../common/currency";
import { PropsMonthlyAccrual } from "./interfaces";
import s from "./styles.scss";

class TableReportMonthlyAccrual extends React.Component<PropsMonthlyAccrual> {
  //COLUMN DEFINITION
  columns = [
    {
      Header: () => <p className="font-weight-bold">Club factura</p>,
      id: 0,
      width: 150,
      className: "text-center",
      accessor: "itemClubVentaNombre"
    },
    {
      Header: () => <p className="font-weight-bold">Club dispersión</p>,
      id: 0,
      width: 150,
      className: "text-center",
      accessor: "itemClubDispNombre"
    },
    {
      Header: () => <p className="font-weight-bold">Folio</p>,
      id: 0,
      width: 80,
      className: "text-center",
      accessor: "itemFactura"
    },
    {
      Header: () => <p className="font-weight-bold">UUID</p>,
      id: 0,
      width: 250,
      accessor: "itemFacturaUuid"
    },
    {
      Header: () => <p className="font-weight-bold">Club membresía</p>,
      id: 0,
      width: 150,
      className: "text-center",
      accessor: "itemClubMembresiaNombre"
    },
    {
      Header: () => <p className="font-weight-bold"># membresía</p>,
      id: 0,
      width: 100,
      className: "text-center",
      accessor: "itemMembresiaIdPublico"
    },
    {
      Header: () => <p className="font-weight-bold">Fecha facturación</p>,
      id: 0,
      width: 140,
      className: "text-center",
      accessor: "itemFechaFacturacion"
    },
    {
      Header: () => <p className="font-weight-bold">Fecha aplica</p>,
      id: 0,
      width: 100,
      className: "text-center",
      accessor: "itemFechaAplica"
    },
    {
      Header: () => <p className="font-weight-bold">Nombre producto</p>,
      id: 0,
      width: 300,
      accessor: "itemNombre",
      Cell: row => {
        return <div className={s.divBreak}>{row.original.itemNombre} </div>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Tipo</p>,
      id: 0,
      class: "text-center",
      width: 180,
      accessor: "itemTipoProducto"
    },
    {
      Header: () => <p className="font-weight-bold">Eventos</p>,
      id: 0,
      className: "text-center",
      accessor: "itemEventos"
    },
    {
      Header: () => <p className="font-weight-bold">Eventos devengados</p>,
      id: 0,
      className: "text-center",
      accessor: "itemEventosCompensados"
    },

    {
      Header: () => <p className="font-weight-bold"></p>,
      id: 0,
      width: 30,
      accessor: "divisor"
    },
    {
      Header: () => (
        <p className="font-weight-bold">
          Item <br /> Subtotal
        </p>
      ),
      id: 0,
      class: "text-center",
      accessor: "itemImporteSinIva",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.itemImporteSinIva} />;
      }
    },
    {
      Header: () => (
        <p className="font-weight-bold">
          Item <br /> Iva
        </p>
      ),
      id: 0,
      class: "text-center",
      accessor: "itemImporteIva",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.itemImporteIva} />;
      }
    },
    {
      Header: () => (
        <p className="font-weight-bold">
          Item <br /> total
        </p>
      ),
      id: 0,
      class: "text-center",
      accessor: "itemImporte",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.itemImporte} />;
      }
    },
    {
      Header: () => <p className="font-weight-bold"></p>,
      id: 0,
      width: 30,
      accessor: "divisor"
    },
    {
      Header: () => (
        <p className="font-weight-bold">
          Cuenta
          <br />
          contable
          <br />
          ingreso
        </p>
      ),
      id: 0,
      className: "text-center",
      accessor: "itemCuentaContableIngreso"
    },
    {
      Header: () => <p className="font-weight-bold">% devengado</p>,
      id: 0,
      accessor: "itemPorcentajeDevengado",
      className: "text-center",
      Cell: row => {
        return <span>{row.original.itemPorcentajeDevengado} %</span>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Monto devengado</p>,
      id: 0,
      width: 160,
      class: "text-center",
      className: "text-center",
      accessor: "itemMontoDevengado",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.itemMontoDevengado} />;
      }
    },
    {
      Header: () => <p className="font-weight-bold"></p>,
      id: 0,
      width: 30,
      accessor: "divisor"
    },
    {
      Header: () => (
        <p className="font-weight-bold">
          Cuenta
          <br />
          contable
          <br />
          devengo
        </p>
      ),
      id: 0,
      className: "text-center",
      accessor: "itemCuentaContableDevengo"
    },
    {
      Header: () => <p className="font-weight-bold">% por devengar</p>,
      id: 0,
      accessor: "itemPorcentajePorDevengar",
      className: "text-center",
      Cell: row => {
        return <span>{row.original.itemPorcentajePorDevengar} %</span>;
      }
    },
    {
      Header: () => <p className="font-weight-bold">Monto por devengar</p>,
      id: 0,
      width: 160,
      class: "text-center",
      className: "text-center",
      accessor: "itemMontoPorDevengar",
      Cell: row => {
        return <MexicanCurrency quantity={row.original.itemMontoPorDevengar} />;
      }
    },
    {
      Header: () => <p className="font-weight-bold"></p>,
      id: 0,
      width: 30,
      accessor: "divisor"
    },
    {
      Header: () => <p className="font-weight-bold">Compensado</p>,
      id: 0,
      accessor: "itemCompensado",
      className: "text-center"
    },
    {
      Header: () => (
        <p className="font-weight-bold">
          Folio Nota
          <br />
          Crédito
        </p>
      ),
      id: 0,
      width: 100,
      className: "text-center",
      accessor: "itemFolioNotaCredito"
    }
  ];

  //CONTROL BEHAVIOR
  downloadReportMonthlyAccrual = () => {
    this.props.downloadReportMonthlyAccrual();
  };

  //RENDER
  render() {
    return (
      <Container className="p-0">
        {!!(this.props.data || []).length && (
          <Row className={"mt-5"}>
            <Col sm="6">
              Resultados de la búsqueda:{" "}
              <b
                style={{ fontWeight: "bolder", display: "inline-block" }}
                className="ml-3"
              >
                {(this.props.data || []).length}
              </b>
            </Col>
            <Col sm="6" className="text-right">
              <span>Exportar a CSV: </span>
              <Button
                id="btnReportExportCsv"
                color="link"
                onClick={() => {
                  this.downloadReportMonthlyAccrual();
                }}
              >
                <FontAwesomeIcon icon={faFileExport} color="black" />
              </Button>
            </Col>
          </Row>
        )}

        <ReactTable
          data={this.props.data}
          columns={this.columns}
          className="mt-2 -striped -highlight"
          sortable={false}
          resizable={false}
          pageSize={20}
          loading={this.props.onSearch}
        />
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(TableReportMonthlyAccrual));
