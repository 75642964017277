import { ConvenioClubesAsignables } from "../../reducers/convenios";
import {
  CreateInvitado,
  GetInvitado,
  UpdateInvitado
} from "../../reducers/invitados";

export interface InvitadosDetalleStore
  extends GetInvitado,
    CreateInvitado,
    UpdateInvitado,
    ConvenioClubesAsignables {}

export const invitadosDetalleStore: (
  state
) => InvitadosDetalleStore = state => {
  const { invitados, convenios } = state;
  return {
    invitado: invitados.invitado,
    gettingInvitado: invitados.gettingInvitado,
    gettingInvitadoError: invitados.gettingInvitadoError,

    idInvitado: invitados.idInvitado,
    creatingInvitado: invitados.creatingInvitado,
    creatingInvitadoError: invitados.creatingInvitadoError,

    updatingInvitado: invitados.updatingInvitado,
    updatingInvitadoError: invitados.updatingInvitadoError,

    clubesAsignables: convenios.clubesAsignables,
    clubesAsignablesLoading: convenios.clubesAsignablesLoading
  };
};
