import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import ReactTable from "react-table";
import { withRouter } from "react-router-dom";
import { Button, Container, Row, Col, Input } from "reactstrap";
import ReportFilter from "./ReportFilter";
import { FILTERS_CATALOGS, FILTERS } from "./interfaces";
import s from "./styles.scss";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { downloadMembershipsSalesComissionsReport } from "../../actions/reports";
import ModalFrame from "../../../toolkit/modalFrame";
import axios from "axios";
import constants from "../../../utils/constants";
import {
  errorNotification,
  infoNotification
} from "../../../utils/notifications";
import { readMessageBack } from "../../../utils/readMessageBack";

// TODO: REDUX
const REPORTS_BASE_URL = "/api/reports";

interface Props {
  fetchSearchData: (filters: FILTERS) => void;
  getSchemasByPeriod: (idPeriodo) => void;
  filterCatalogs: FILTERS_CATALOGS;
  searching: boolean;
  reportComissions: object[];
  loadingReport: boolean;
  clubs: object;
  schemas: object;
  periods: object;
  sellers: object[];
  authorizeMemberships: (comissionsList: object) => void;
}

interface State {
  checked: Number[];
  selectAll: Boolean;
  currentFilters: FILTERS;
  showProccessConfirm: boolean;
  waitProccess: boolean;

  // TODO: Provisional
  showAnnuityConfirm: boolean;
}

class ComissionsReportPage extends React.Component<Props, State> {
  state = {
    checked: [],
    selectAll: false,
    currentFilters: null,
    showProccessConfirm: false,
    waitProccess: false,
    showAnnuityConfirm: false
  };

  handleSelectAll(e: Boolean) {
    if (e) {
      this.setState({
        checked: this.props.reportComissions.map(e => e.id),
        selectAll: e
      });
    } else {
      this.setState({ checked: [], selectAll: e });
    }
  }

  handleCheckComission(id) {
    if (this.state.checked.includes(id)) {
      this.setState({ checked: this.state.checked.filter(e => e !== id) });
    } else {
      this.setState({ checked: [...this.state.checked, id] });
    }
  }

  handleSubmit = (e, status) => {
    e.preventDefault();
    const comissionsList = {
      comisionesId: this.state.checked,
      estatus: status
    };
    this.props.authorizeMemberships(comissionsList);
    this.handleSelectAll(false);
  };

  fetchProccess = () => {
    this.setState({
      showProccessConfirm: true
    });
  };

  toggleModal = () => {
    this.setState({
      showProccessConfirm: !this.state.showProccessConfirm
    });
  };

  confirmProccess = async () => {
    try {
      this.setState({
        showProccessConfirm: false
      });

      infoNotification("Procesando las comisiones");
      let response = await axios.post(
        `${constants.BASE_URL}${REPORTS_BASE_URL}/comissionsMembership/proccess`,
        null,
        {
          withCredentials: true
        }
      );

      infoNotification(
        response.data.message || "Comisiones procesadas correctamente"
      );
    } catch (error) {
      errorNotification(
        readMessageBack(error, "No fue posible procesar las comisiones")
      );
    }
  };
  fetchAnnuity = () => {
    this.setState({
      showAnnuityConfirm: true
    });
  };

  toggleAnnuity = () => {
    this.setState({
      showAnnuityConfirm: !this.state.showAnnuityConfirm
    });
  };

  confirmAnnuity = async () => {
    try {
      this.setState({
        showAnnuityConfirm: false
      });

      infoNotification("Procesando las comisiones de anualidad");
      let response = await axios.post(
        `${constants.BASE_URL}${REPORTS_BASE_URL}/comissionsAnnuity/proccess`,
        null,
        {
          withCredentials: true
        }
      );

      infoNotification(
        response.data.message || "Comisiones procesadas correctamente"
      );
    } catch (error) {
      errorNotification(
        readMessageBack(error, "No fue posible procesar las comisiones")
      );
    }
  };

  render() {
    const columnsCommisionsTableReport = [
      {
        Header: "#",
        accessor: "numero",
        width: 50,
        className: "text-center"
      },
      {
        Header: "Club",
        accessor: "clubNombre"
      },
      {
        Header: "# Membresía socio",
        accessor: "membresiaSocioIdPublico",
        className: "text-center"
      },

      {
        Header: "Vendedor",
        accessor: "vendedorNombre",
        minWidth: 200
      },
      {
        Header: "Tipo Comisión",
        accessor: "tipoComision"
      },
      {
        Header: "Descripción",
        accessor: "descripcion",
        minWidth: 200
      },
      {
        Header: "CAT",
        accessor: "membresiaSocioCargosAutomaticos",
        width: 50,
        className: "text-center"
      },
      {
        Header: "Importe venta",
        accessor: "importeVentaMembresia",
        className: "text-center"
      },
      {
        Header: "Porcentaje",
        accessor: "porcentaje",
        width: 80,
        className: "text-center"
      },
      {
        Header: "Importe comisión",
        accessor: "monto",
        className: "text-center"
      },
      {
        Header: "Estatus",
        accessor: "estatus",
        className: "text-center"
      },
      {
        Header: "Fecha de Pago",
        accessor: "fechaPosiblePago",
        className: "text-center"
      },
      {
        Header: "Esquema",
        accessor: "esquemaNombre"
      }
    ];

    return (
      <Container className="mt-3 mb-5 p-0">
        <Row className="mb-1">
          <Col sm="12">Ingresa los campos requeridos para comenzar ( * )</Col>
        </Row>

        <ReportFilter
          // @ts-ignore
          clubs={this.props.clubs}
          periods={this.props.periods}
          schemas={this.props.schemas}
          fetchSearchData={(filters: FILTERS) => {
            this.setState({ currentFilters: filters });
            this.props.fetchSearchData(filters);
          }}
          getSchemasByPeriod={this.props.getSchemasByPeriod}
          sellers={this.props.sellers}
          showProccess={false}
          fetchProccess={this.fetchProccess}
          fetchAnnuity={this.fetchAnnuity}
        />

        <span>
          <Row className={"my-3"}>
            <Col sm="6">Resultados de Búsqueda</Col>
            <Col sm="6" className="text-right">
              <span>Exportar a CSV: </span>
              <Button
                id="btnReportExportCsv"
                color="link"
                disabled={!this.state.currentFilters}
                onClick={() => {
                  if (!this.state.currentFilters) {
                    return;
                  }

                  downloadMembershipsSalesComissionsReport(
                    this.state.currentFilters
                  );
                }}
              >
                <FontAwesomeIcon icon={faFileExport} color="black" />
              </Button>
            </Col>
          </Row>
          <ReactTable
            pages={1}
            data={this.props.reportComissions || []}
            columns={columnsCommisionsTableReport}
            showPagination={false}
            sortable={false}
            multiSort={false}
            resizable={true}
            pageSize={(this.props.reportComissions || []).length + 3}
          />
        </span>

        <ModalFrame
          title={"Confirme la operación"}
          isOpen={this.state.showProccessConfirm}
          toggle={this.toggleModal}
          size="lg"
        >
          <Row>
            <Col lg={12}>
              Cuando autoriza una comisión, esta se procesa en un sistema
              externo que debería estar disponible para continuar con el flujo
              de pago. <br />
              <br />
              Diariamente se realiza una comprobación sobre las comisiones
              autorizadas que no aplicaron este proceso; sin embargo, usted
              puede efectuar esta comprobación manualmente con esta opción.
            </Col>
            <Col lg={12}>
              <div style={{ textAlign: "right" }}>
                <Button
                  className={`mt-4 ${s.buttonMarginTop} ${s.primaryButton}`}
                  onClick={this.confirmProccess}
                  style={{ height: "2rem", marginLeft: "8px" }}
                >
                  Aceptar
                </Button>
                <Button
                  className={`mt-4 ${s.buttonMarginTop} ${s.primaryButton}`}
                  onClick={this.toggleModal}
                  style={{ height: "2rem", marginLeft: "8px" }}
                >
                  Cancelar
                </Button>
              </div>
            </Col>
          </Row>
        </ModalFrame>

        <ModalFrame
          title={"Confirme la operación"}
          isOpen={this.state.showAnnuityConfirm}
          toggle={this.toggleAnnuity}
          size="lg"
        >
          <Row>
            <Col lg={12}>
              Cuando autoriza una comisión, esta se procesa en un sistema
              externo que debería estar disponible para continuar con el flujo
              de pago. <br />
              <br />
              Diariamente se realiza una comprobación sobre las comisiones
              autorizadas que no aplicaron este proceso; sin embargo, usted
              puede efectuar esta comprobación manualmente con esta opción.
            </Col>
            <Col lg={12}>
              <div style={{ textAlign: "right" }}>
                <Button
                  className={`mt-4 ${s.buttonMarginTop} ${s.primaryButton}`}
                  onClick={this.confirmAnnuity}
                  style={{ height: "2rem", marginLeft: "8px" }}
                >
                  Aceptar
                </Button>
                <Button
                  className={`mt-4 ${s.buttonMarginTop} ${s.primaryButton}`}
                  onClick={this.toggleAnnuity}
                  style={{ height: "2rem", marginLeft: "8px" }}
                >
                  Cancelar
                </Button>
              </div>
            </Col>
          </Row>
        </ModalFrame>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(ComissionsReportPage));
