import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import BaseInput from "../../../toolkit/baseInput";
import { Button, FormGroup, Label, Input } from "reactstrap";
import s from "./styles.scss";
import Reform from "@franleplant/reform";
import { FormErrors } from "@franleplant/reform/types";
import { Container } from "reactstrap";
import Tree, { TreeNode } from "rc-tree";
import unionBy from "lodash.unionby";
import differenceBy from "lodash.differenceby";
import uniq from "lodash.uniq";
import pull from "lodash.pull";
import every from "lodash.every";
import find from "lodash.find";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";

const FONT_SIZE_TREE = "0.75rem";
const INACTIVE_COLOR = "gray";
const ACTIVE_COLOR = "black";

const ROOT_PERMISSION = "FAKE_ROOT_PERMISSION";

interface PERMISSION_TREE {
  permissionId: string;
  area: string;
  description: string;
  childPermissions: PERMISSION_TREE[];
  padre: PERMISSION_TREE;
  active: boolean;
}

interface Props {
  domains: { domainCode: string; domainName: string }[];
  permissionsTree: PERMISSION_TREE;
  saveRole: (role: object, permissions: string[]) => any;
  role: {
    group: {
      domain: string;
      code: string;
      description: string;
      multiclub: boolean;
    };
    permissions: number[];
  };
  match: any;
}

interface Role {
  code: string;
  description: string;
  domain: string;
  active: boolean;
  multiclub: boolean;
}

interface State {
  fields: Role;
  errors: FormErrors;
  checkedKeys: { checked: string[]; halfChecked: string[] };
}

const transformRole = props => {
  const { role } = props;

  return {
    code: role ? role.group.code : "",
    description: role ? role.group.description : "",
    domain: role
      ? role.group.domain
      : props.domains && props.domains.length
      ? props.domains[0].domainCode
      : null,
    active: role ? role.group.active : true,
    multiclub: role ? role.group.multiclub : false
  };
};

const emptyRol = () => ({
  code: "",
  description: "",
  domain: "1",
  active: false,
  multiclub: false
});

class CreateRole extends React.Component<Props, State> {
  state = {
    fields: emptyRol(),
    checkedKeys: { checked: [], halfChecked: [] },
    errors: {}
  };

  re = Reform.reactMixins.objectMixin(this);

  validationRules = {
    code: { required: true },
    description: { required: true },
    domain: { required: true }
  };

  validationMessages = {
    required: _ => `Obligatorio`,
    minLength: ruleValue => `Debe tener al menos ${ruleValue} caracteres`,
    maxLength: ruleValue => `Deber tener máximo ${ruleValue} caracteres`,
    default: _ => `Inválido`
  };

  componentDidMount() {
    const { roleId } = this.props.match.params;

    if (this.props.role && roleId) {
      const { permissions } = this.props.role;
      this.setState(prevState => ({
        ...prevState,
        fields: transformRole(this.props),
        checkedKeys: {
          checked: permissions.map(e => String(e)),
          halfChecked: []
        }
      }));
    }
  }

  componentDidUpdate() {}

  getPathToRoot = original => {
    const path = [];
    let padre = original.padre;

    while (padre && padre.padre) {
      path.push(padre.permissionId);
      padre = padre.padre;
    }

    return path;
  };

  onCheck = (checkedKeys, info) => {
    if (this.checkedAll) {
      this.checkedAll = false;
      return;
    }

    const {
      node: {
        props: { original }
      }
    } = info;
    const checkedNodes = info.checkedNodes.map(({ key }) => key);

    let pathToRoot = [];
    const deselect = [];
    const deselectChild = node => {
      deselect.push(node.permissionId);
      if (node.childPermissions) {
        node.childPermissions.forEach(child => {
          deselectChild(child);
        });
      }
    };

    const deselectParent = node => {
      let parent = node.padre;

      if (parent) {
        const children = parent.childPermissions;

        if (
          every(
            children,
            child => !find(checkedNodes, key => key === child.permissionId)
          )
        ) {
          const { permissionId: parentId } = parent;
          pull(checkedNodes, parentId);
          deselect.push(parentId);
          deselectParent(parent);
        }
      }
    };

    if (!info.checked) {
      deselectChild(original);
      deselectParent(original);
    } else {
      pathToRoot = this.getPathToRoot(original);
    }

    const checked = uniq(
      differenceBy(unionBy(checkedKeys.checked, pathToRoot), deselect)
    );

    this.setState({ checkedKeys: { ...checkedKeys, checked } });
  };

  checkedAll = false;

  checkAllChildren = parentNode => {
    this.checkedAll = true;

    const select = [];

    const selectChildren = node => {
      select.push(node.permissionId);
      if (node.childPermissions) {
        node.childPermissions.forEach(child => {
          selectChildren(child);
        });
      }
    };

    selectChildren(parentNode);
    const pathToRoot = this.getPathToRoot(parentNode);

    const checked = uniq(
      unionBy(this.state.checkedKeys.checked, pathToRoot, select)
    );

    this.setState({ checkedKeys: { ...this.state.checkedKeys, checked } });
  };

  getTreeLabel = node => (
    <span
      className="rc-tree-title"
      style={{
        fontSize: FONT_SIZE_TREE,
        color: !node.active ? INACTIVE_COLOR : ACTIVE_COLOR
      }}
    >
      {node.description}
      <a
        onClick={() => this.checkAllChildren(node)}
        className={`${s.checkAllChildrenAnchor}`}
        style={{ marginLeft: "1rem" }}
      >
        {node.childPermissions.length ? (
          <FontAwesomeIcon style={{ color: "green" }} icon={faCheckDouble} />
        ) : null}
      </a>
    </span>
  );

  onChangeFactory = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const fields = {
          ...state.fields,
          [fieldName]: value
        };

        return { ...state, error: {}, fields };
      });
      this.re.validateField(fieldName, value);
    };
  };

  onSubmit = e => {
    e.preventDefault();
    if (this.re.validateFormFromState()) {
      this.props.saveRole(this.state.fields, this.state.checkedKeys.checked);
    }
  };

  handleChangeActive = e => {
    const active = e.target.checked;
    this.setState(state => {
      return {
        ...state,
        fields: {
          ...state.fields,
          active
        }
      };
    });
  };

  handleChangeMulticlub = e => {
    const multiclub = e.target.checked;
    this.setState(state => {
      return {
        ...state,
        fields: {
          ...state.fields,
          multiclub
        }
      };
    });
  };

  getOptionsDomains = domains =>
    domains.map(({ domainCode: value, domainName: label }) => ({
      label,
      value
    }));

  getTreeNodes = (node: PERMISSION_TREE) => (
    <TreeNode
      original={node}
      title={this.getTreeLabel(node)}
      key={node.permissionId}
      leaf={!node.childPermissions}
      disableCheckbox={node.permissionId === ROOT_PERMISSION}
      selectable={false}
    >
      {node.childPermissions
        ? node.childPermissions.map(this.getTreeNodes)
        : null}
    </TreeNode>
  );

  getTree = () => {
    return (
      <Tree
        className="myCls"
        showLine
        checkable
        defaultExpandAll
        defaultExpandedKeys={[ROOT_PERMISSION]}
        showIcon={false}
        onCheck={this.onCheck}
        checkStrictly
        checkedKeys={this.state.checkedKeys}
        selectable={false}
      >
        {this.getTreeNodes(this.props.permissionsTree)}
      </Tree>
    );
  };

  render() {
    return (
      <Container className="p-0 mt-3">
        <div className="h-100 pb-3">
          <p className={`${s.primaryTitle}`}>Datos Básicos</p>
          <form className={s.basicDataClub}>
            <div className="form-row">
              <div className="col-2">
                <BaseInput
                  label={"Codename"}
                  name="code"
                  id="code"
                  type="text"
                  placeholder="Codename"
                  value={this.state.fields.code}
                  onChange={this.onChangeFactory("code")}
                  errors={this.re.mapFieldErrors("code")}
                />
              </div>
              <div className="col">
                <BaseInput
                  label={"Descripción"}
                  name="description"
                  type="text"
                  id="description"
                  placeholder="Descripción"
                  value={this.state.fields.description}
                  onChange={this.onChangeFactory("description")}
                  errors={this.re.mapFieldErrors("description")}
                />
              </div>
              {this.props.domains && (
                <div className="col-2">
                  <BaseInput
                    label={"Dominio"}
                    name="domain"
                    type="select"
                    id="domain"
                    options={this.getOptionsDomains(this.props.domains)}
                    placeholder="Dominio"
                    value={this.state.fields.domain}
                    onChange={this.onChangeFactory("domain")}
                    errors={this.re.mapFieldErrors("domain")}
                  />
                </div>
              )}
            </div>
            <div className="form-row">
              <div className="col">
                <div className={s.checkboxContainer}>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={this.state.fields.active}
                        onChange={e => this.handleChangeActive(e)}
                      />{" "}
                      Activo
                    </Label>
                  </FormGroup>
                </div>
              </div>

              <div className="col">
                <div className={s.checkboxContainer}>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={this.state.fields.multiclub}
                        onChange={e => this.handleChangeMulticlub(e)}
                      />{" "}
                      Activar multiclub
                    </Label>
                  </FormGroup>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div>
          <p className={`${s.primaryTitle}`}>Permisos</p>
          <div
            className="form-row"
            style={{
              height: "calc(100vh - 450px)",
              overflowY: "auto",
              overflowX: "hidden",
              border: "1px solid #ccc",
              minHeight: "300px"
            }}
          >
            {this.getTree()}
          </div>
          <div hidden className="col-2 p-0 m-0 mt-1 border border-danger">
            <p className="text-center m-1" style={{ fontSize: FONT_SIZE_TREE }}>
              <span>ACTIVO</span>
              <span className="ml-2" style={{ color: INACTIVE_COLOR }}>
                INACTIVO
              </span>
            </p>
          </div>
        </div>
        <div className={s.formGroup}>
          <div className="row">
            <div className="col" />
            <div className="col-2">
              <Button
                className={`${s.buttonMarginTop} ${s.primaryButton}`}
                onClick={e => this.props.history.push("/roles")}
                size="sm"
                block
                type="button"
              >
                {"Regresar"}
              </Button>
            </div>
            <div className="col-2">
              <Button
                className={`${s.buttonMarginTop} ${s.primaryButton}`}
                onClick={e => this.onSubmit(e)}
                size="sm"
                block
                disabled={false}
              >
                {"Guardar"}
              </Button>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(CreateRole));
