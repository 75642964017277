import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Container, Col, Button, Row } from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import MexicanCurrency from "../common/currency";
import DatePicker from "../common/DatePicker";
import s from "./styles.scss";
import IAction from "../../../types/IAction";

function formatToCurrency(n) {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(n || 0).replace("$", "");
}

interface Props {
  getCashCut: (clubId: string, fechaDePago: string) => IAction;
  pdfCashCut: (data: object, clubId: string, fechaDePago: string) => IAction;
  clubs: object[];
  reportCashCut: any;
}

interface State {
  filters: object;
  pagosFacturados: object;
  conciliaciones: object;
  cobranzas: object;
  load: boolean;
}

const START_DATE_FIELDNAME = "fechaInicio";

class CashCutReport extends React.Component<Props, State> {
  state = {
    filters: {
      idClub: null,
      fechaInicio: null
    },
    pagosFacturados: {
      EFECTIVO: 0,
      CHEQUE: 0,
      TDC: 0,
      TDD: 0,
      AMEX: 0,
      TRANSFERENCIA: 0,
      POR_DEFINIR_99: 0,
      PAGO_EN_LINEA: 0,
      APP: 0
    },
    conciliaciones: {
      NOTA_CREDITO: 0,
      NOTA_CREDITO_COBRANZA_ERRONEA: 0,
      NOTA_CREDITO_DATOS_FISCALES: 0,
      FACTURACION_EXCEPCIONAL: 0,
      FACTURACION_99: 0,
      RECIBOS_PROVISIONALES: 0,
      COBRANZA_FACTURA: 0,
      FACTURACION_COBRANZA_DIAS_ANTERIORES: 0,
      COBRANZA_PENDIENTE_FACTURAR: 0
    },
    cobranzas: {
      Efectivo: 0,
      Cheques: 0,
      PDTDD: 0,
      PDTDC: 0,
      PDAMEX: 0,
      CATDC: 0,
      CATDD: 0,
      CAAMEX: 0,
      Transferencia: 0,
      pagoLinea: 0,
      APP: 0
    },
    totalPagosFacturados: 0,
    subtotalConciliacionesMas: 0,
    subtotalConciliacionesMenos: 0,
    totalConciliaciones: 0,
    totalCobranzas: 0,
    diferencia: 0,
    load: false
  };

  componentDidUpdate(prevProps, prevStates) {
    //Si hay cambios en la conciliación, se recalculan los valores
    if (prevStates.conciliaciones !== this.state.conciliaciones) {
      const {
        //------------ MENOS ------------
        NOTA_CREDITO,
        NOTA_CREDITO_COBRANZA_ERRONEA,
        NOTA_CREDITO_DATOS_FISCALES,
        FACTURACION_EXCEPCIONAL,
        FACTURACION_99,
        FACTURACION_COBRANZA_DIAS_ANTERIORES,
        //------------ MAS ------------
        COBRANZA_FACTURA,
        RECIBOS_PROVISIONALES,
        COBRANZA_PENDIENTE_FACTURAR
      } = this.state.conciliaciones;

      const subtotalConciliacionesMenos =
        (NOTA_CREDITO || 0) +
        (NOTA_CREDITO_DATOS_FISCALES || 0) +
        (NOTA_CREDITO_COBRANZA_ERRONEA || 0) +
        (FACTURACION_EXCEPCIONAL || 0) +
        (FACTURACION_99 || 0) +
        (FACTURACION_COBRANZA_DIAS_ANTERIORES || 0);

      const subtotalConciliacionesMas =
        (COBRANZA_FACTURA || 0) +
        (RECIBOS_PROVISIONALES || 0) +
        (COBRANZA_PENDIENTE_FACTURAR || 0);

      const totalConciliaciones =
        this.state.totalPagosFacturados -
        subtotalConciliacionesMenos +
        subtotalConciliacionesMas;

      const diferencia = 0 - totalConciliaciones + this.state.totalCobranzas;

      this.setState(state => ({
        ...state,
        subtotalConciliacionesMenos,
        subtotalConciliacionesMas,
        totalConciliaciones,
        diferencia
      }));
    }

    //Si hubo cambios en la cobranza, se recalculan los valores
    if (prevStates.cobranzas !== this.state.cobranzas) {
      const {
        Efectivo,
        Cheques,
        PDTDD,
        PDTDC,
        PDAMEX,
        CATDC,
        CATDD,
        CAAMEX,
        Transferencia,
        pagoLinea,
        APP
      } = this.state.cobranzas;

      const totalCobranzas =
        (Efectivo || 0) +
        (Cheques || 0) +
        (PDTDD || 0) +
        (PDTDC || 0) +
        (PDAMEX || 0) +
        (CATDC || 0) +
        (CATDD || 0) +
        (CAAMEX || 0) +
        (Transferencia || 0) +
        (pagoLinea || 0) +
        (APP || 0);

      const diferencia = 0 - this.state.totalConciliaciones + totalCobranzas;

      this.setState(state => ({
        ...state,
        totalCobranzas,
        diferencia
      }));
    }

    if (this.props.reportCashCut && !this.state.load) {
      let EFECTIVO = 0;
      let CHEQUE = 0;
      let TDC = 0;
      let TDD = 0;
      let AMEX = 0;
      let TRANSFERENCIA = 0;
      let POR_DEFINIR_99: 0;
      let PAGO_EN_LINEA: 0;
      let _APP = 0;

      this.props.reportCashCut.pagosFacturados.map(pago => {
        switch (pago.concepto) {
          case "EFECTIVO":
            EFECTIVO = pago.monto;
            break;
          case "CHEQUE":
            CHEQUE = pago.monto;
            break;
          case "TDC":
            TDC = pago.monto;
            break;
          case "TDD":
            TDD = pago.monto;
            break;
          case "AMEX":
            AMEX = pago.monto;
            break;
          case "TRANSFERENCIA":
            TRANSFERENCIA = pago.monto;
            break;
          case "POR_DEFINIR_99":
            POR_DEFINIR_99 = pago.monto;
            break;
          case "PAGO_EN_LINEA":
            PAGO_EN_LINEA = pago.monto;
            break;
          case "APP":
            _APP = pago.monto;
            break;
          default:
            break;
        }
      });

      let NOTA_CREDITO = 0;
      let NOTA_CREDITO_COBRANZA_ERRONEA = 0;
      let NOTA_CREDITO_DATOS_FISCALES = 0;
      let FACTURACION_EXCEPCIONAL = 0;
      let FACTURACION_99 = 0;
      let COBRANZA_FACTURA = 0;
      let RECIBOS_PROVISIONALES = 0;
      let FACTURACION_COBRANZA_DIAS_ANTERIORES: 0;
      let COBRANZA_PENDIENTE_FACTURAR: 0;

      this.props.reportCashCut.conciliaciones.map(conciliacion => {
        switch (conciliacion.concepto) {
          case "Nota de Crédito por devolución y/o bonificación total o parcial":
            NOTA_CREDITO = conciliacion.monto;
            break;
          case "Nota de Crédito por cobranza errónea":
            NOTA_CREDITO_COBRANZA_ERRONEA = conciliacion.monto;
            break;
          case "Nota de Crédito por cambio datos fiscales":
            NOTA_CREDITO_DATOS_FISCALES = conciliacion.monto;
            break;
          case "Facturación excepcional":
            FACTURACION_EXCEPCIONAL = conciliacion.monto;
            break;
          case "Facturación forma de pago 99 'por definir'":
            FACTURACION_99 = conciliacion.monto;
            break;
          case "Cobranza de facturas con formas de pago 99 'por definir'":
            COBRANZA_FACTURA = conciliacion.monto;
            break;
          case "Recibos provisionales (cheque)":
            RECIBOS_PROVISIONALES = conciliacion.monto;
            break;
          case "Facturación cobranza dias anteriores":
            FACTURACION_COBRANZA_DIAS_ANTERIORES = conciliacion.monto;
            break;
          case "Cobranza pendiente de facturar":
            COBRANZA_PENDIENTE_FACTURAR = conciliacion.monto;
            break;
          default:
            break;
        }
      });

      let Efectivo = "80";
      let Cheques = 0;
      let PDTDD = 0;
      let PDTDC = 0;
      let PDAMEX = 0;
      let CATDC = 0;
      let CATDD = 0;
      let CAAMEX = 0;
      let Transferencia = 0;
      let pagoLinea = 0;
      let APP = 0;

      this.props.reportCashCut.cobranzas.map(cobranza => {
        switch (cobranza.concepto) {
          case "Efectivo":
            Efectivo = cobranza.monto;
            break;
          case "Cheques":
            Cheques = cobranza.monto;
            break;
          case "Pago directo en caja.- tarjetas de débito":
            PDTDD = cobranza.monto;
            break;
          case "Pago directo en caja.- tarjetas de crédito":
            PDTDC = cobranza.monto;
            break;
          case "Pago directo en caja.- AMEX":
            PDAMEX = cobranza.monto;
            break;
          case "Cargo automático a TDC":
            CATDC = cobranza.monto;
            break;
          case "Cargo automático a débito (domiciliados)":
            CATDD = cobranza.monto;
            break;
          case "Cargo automático AMEX":
            CAAMEX = cobranza.monto;
            break;
          case "Transferencia":
            Transferencia = cobranza.monto;
            break;
          case "Pago en línea":
            pagoLinea = cobranza.monto;
            break;
          case "APP":
            APP = cobranza.monto;
            break;
          default:
            break;
        }
      });

      const {
        totalPagosFacturados,
        totalConciliaciones,
        subtotalConciliacionesMenos,
        subtotalConciliacionesMas,
        totalCobranzas,
        diferencia
      } = this.props.reportCashCut;

      this.setState(state => ({
        ...state,
        pagosFacturados: {
          EFECTIVO,
          CHEQUE,
          TDC,
          TDD,
          AMEX,
          TRANSFERENCIA,
          POR_DEFINIR_99,
          PAGO_EN_LINEA,
          APP: _APP
        },
        conciliaciones: {
          NOTA_CREDITO,
          NOTA_CREDITO_COBRANZA_ERRONEA,
          NOTA_CREDITO_DATOS_FISCALES,
          FACTURACION_EXCEPCIONAL,
          FACTURACION_99,
          COBRANZA_FACTURA,
          RECIBOS_PROVISIONALES,
          FACTURACION_COBRANZA_DIAS_ANTERIORES,
          COBRANZA_PENDIENTE_FACTURAR
        },
        cobranzas: {
          Efectivo,
          Cheques,
          PDTDD,
          PDTDC,
          PDAMEX,
          CATDC,
          CATDD,
          CAAMEX,
          Transferencia,
          pagoLinea,
          APP
        },
        totalPagosFacturados,
        totalConciliaciones,
        subtotalConciliacionesMenos,
        subtotalConciliacionesMas,
        totalCobranzas,
        diferencia,
        load: true
      }));
    }
  }

  onChangeFilter = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...this.state.filters,
          [fieldName]: value
        };
        return { ...state, filters };
      });
    };
  };

  onChangeDate = fieldName => {
    return event => {
      const date = moment(event).format("YYYY-MM-DD");
      this.setState(state => {
        const filters = {
          ...state.filters,
          [fieldName]: date
        };
        return { ...state, error: {}, filters };
      });
    };
  };

  onChangeFieldsPagosFacturados = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const pagosFacturados = {
          ...state.pagosFacturados,
          [fieldName]: parseFloat(value || "0")
        };

        return { ...state, error: {}, pagosFacturados };
      });
    };
  };

  onChangeFieldsCobranzas = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const cobranzas = {
          ...state.cobranzas,
          [fieldName]: parseFloat(value || "0")
        };

        return { ...state, error: {}, cobranzas };
      });
    };
  };

  onChangeFieldsConciliacion = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const conciliaciones = {
          ...state.conciliaciones,
          [fieldName]: parseFloat(value || "0")
        };

        return { ...state, error: {}, conciliaciones };
      });
    };
  };

  onChangeFields = fieldName => {
    return event => {
      const value = event.target.value;
      this.setState(state => {
        const filters = {
          ...state.filters,
          [fieldName]: value
        };

        return { ...state, error: {}, filters };
      });
    };
  };

  search = e => {
    e.preventDefault();
    const { idClub, fechaInicio } = this.state.filters;
    this.props.getCashCut(idClub, fechaInicio);
    this.setState({ load: false });
  };

  generarPDF = e => {
    e.preventDefault();

    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const day = new Date().getDate();
    const hours = new Date().getHours();
    const minutes = new Date().getMinutes();
    const seconds = new Date().getSeconds();
    const { idClub, fechaInicio } = this.state.filters;

    const {
      EFECTIVO,
      CHEQUE,
      TDC,
      TDD,
      AMEX,
      TRANSFERENCIA,
      POR_DEFINIR_99,
      PAGO_EN_LINEA,
      APP: _APP
    } = this.state.pagosFacturados;

    const {
      NOTA_CREDITO,
      NOTA_CREDITO_COBRANZA_ERRONEA,
      NOTA_CREDITO_DATOS_FISCALES,
      FACTURACION_EXCEPCIONAL,
      FACTURACION_99,
      RECIBOS_PROVISIONALES,
      COBRANZA_FACTURA,
      FACTURACION_COBRANZA_DIAS_ANTERIORES,
      COBRANZA_PENDIENTE_FACTURAR
    } = this.state.conciliaciones;

    const {
      Efectivo,
      Cheques,
      PDTDD,
      PDTDC,
      PDAMEX,
      CATDC,
      CATDD,
      CAAMEX,
      Transferencia,
      pagoLinea,
      APP
    } = this.state.cobranzas;

    this.props.pdfCashCut(
      {
        clubId: this.props.reportCashCut.idClub,
        clubNombre: this.props.reportCashCut.clubNombre,
        pagosFacturados: [
          {
            concepto: "EFECTIVO",
            monto: EFECTIVO
          },
          {
            concepto: "CHEQUE",
            monto: CHEQUE
          },
          {
            concepto: "TDC",
            monto: TDC
          },
          {
            concepto: "TDD",
            monto: TDD
          },
          {
            concepto: "AMEX",
            monto: AMEX
          },
          {
            concepto: "TRANSFERENCIA",
            monto: TRANSFERENCIA
          },
          {
            concepto: "POR_DEFINIR_99",
            monto: POR_DEFINIR_99
          },
          {
            concepto: "PAGO_EN_LINEA",
            monto: PAGO_EN_LINEA
          },
          {
            concepto: "APP",
            monto: _APP
          }
        ],
        totalPagosFacturados: this.state.totalPagosFacturados,
        conciliaciones: [
          {
            concepto:
              "Nota de Crédito por devolución y/o bonificación total o parcial",
            monto: NOTA_CREDITO
          },
          {
            concepto: "Nota de Crédito por cobranza errónea",
            monto: NOTA_CREDITO_COBRANZA_ERRONEA
          },
          {
            concepto: "Nota de Crédito por cambio datos fiscales",
            monto: NOTA_CREDITO_DATOS_FISCALES
          },
          {
            concepto: "Facturación excepcional",
            monto: FACTURACION_EXCEPCIONAL
          },
          {
            concepto: "Facturación forma de pago 99 'por definir'",
            monto: FACTURACION_99
          },
          {
            concepto: "Recibos provisionales (cheque)",
            monto: RECIBOS_PROVISIONALES
          },
          {
            concepto:
              "Cobranza de facturas con formas de pago 99 'por definir'",
            monto: COBRANZA_FACTURA
          },
          {
            concepto: "Facturación cobranza dias anteriores",
            monto: FACTURACION_COBRANZA_DIAS_ANTERIORES
          },
          {
            concepto: "Cobranza pendiente de facturar",
            monto: COBRANZA_PENDIENTE_FACTURAR
          }
        ],
        totalConciliaciones: this.state.totalConciliaciones,
        cobranzas: [
          {
            concepto: "Efectivo",
            monto: Efectivo
          },
          {
            concepto: "Cheques",
            monto: Cheques
          },
          {
            concepto: "Pago directo en caja.- tarjetas de débito",
            monto: PDTDD
          },
          {
            concepto: "Pago directo en caja.- tarjetas de crédito",
            monto: PDTDC
          },
          {
            concepto: "Pago directo en caja.- AMEX",
            monto: PDAMEX
          },
          {
            concepto: "Cargo automático a TDC",
            monto: CATDC
          },
          {
            concepto: "Cargo automático a débito (domiciliados)",
            monto: CATDD
          },
          {
            concepto: "Cargo automático AMEX",
            monto: CAAMEX
          },
          {
            concepto: "Transferencia",
            monto: Transferencia
          },
          {
            concepto: "Pago en línea",
            monto: pagoLinea
          },
          {
            concepto: "APP",
            monto: APP
          }
        ],
        totalCobranzas: this.state.totalCobranzas,
        diferencia: this.state.diferencia,
        fechaGeneracion: `${year}-${month >= 10 ? month : 0 + month}-${
          day >= 10 ? day : 0 + day
        }T${hours >= 10 ? hours : 0 + hours}:${
          minutes >= 10 ? minutes : 0 + minutes
        }:${seconds >= 10 ? seconds : 0 + seconds}`
      },
      idClub,
      fechaInicio
    );
  };

  render() {
    const { idClub } = this.state.filters;
    const { clubs } = this.props;

    return (
      <Container className="p-0">
        <div className="row mt-3 mb-3">
          <div className="col">
            <p className={`${s.primaryTitle} w-100 m-0`}>Búsqueda</p>
          </div>
        </div>
        <Row>
          <Col xs={12} md={4} lg={4}>
            <BaseInput
              label="Club"
              name="club"
              id="club"
              type="select"
              placeholder="Seleccionar club"
              value={idClub}
              onChange={this.onChangeFilter("idClub")}
              size={"sm"}
              options={[{ value: "", label: "Seleccionar club" }].concat(
                clubs
                  ? clubs.map(option => ({
                      value: option.clubId,
                      label: option.nombre
                    }))
                  : []
              )}
            />
          </Col>
          <Col xs={12} md={4} lg={4}>
            <DatePicker
              label="Fecha"
              name={START_DATE_FIELDNAME}
              id={START_DATE_FIELDNAME}
              selected={this.state.filters[START_DATE_FIELDNAME]}
              onChange={this.onChangeDate(START_DATE_FIELDNAME)}
            />
          </Col>
          <Col xs={12} md={4} lg={4} className="mt-4">
            <Button
              size="sm"
              className={`float-right btn-block`}
              onClick={this.search}
              disabled={
                !this.state.filters.idClub || !this.state.filters.fechaInicio
              }
            >
              Buscar
            </Button>
          </Col>
        </Row>
        {this.props.reportCashCut !== null ? (
          <React.Fragment>
            {/*----------------------- FACTURACION -----------------------------------*/}

            <div className="row mt-3 mb-3">
              <div className="col">
                <p className={`${s.primaryTitle} w-100 m-0`}>Facturación</p>
              </div>
            </div>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                Efectivo
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  name="EFECTIVO"
                  type="text"
                  id="EFECTIVO"
                  disabled={true}
                  value={formatToCurrency(this.state.pagosFacturados.EFECTIVO)}
                  onChange={this.onChangeFieldsPagosFacturados("EFECTIVO")}
                  asCurrency={true}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                Cheque
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  name="CHEQUE"
                  type="text"
                  id="CHEQUE"
                  disabled={true}
                  value={formatToCurrency(this.state.pagosFacturados.CHEQUE)}
                  onChange={this.onChangeFieldsPagosFacturados("CHEQUE")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                Tarjeta de crédito
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  name="TDC"
                  type="text"
                  id="TDC"
                  disabled={true}
                  value={formatToCurrency(this.state.pagosFacturados.TDC)}
                  onChange={this.onChangeFieldsPagosFacturados("TDC")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                Tarjeta de débito
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  name="TDD"
                  type="text"
                  id="TDD"
                  disabled={true}
                  value={formatToCurrency(this.state.pagosFacturados.TDD)}
                  onChange={this.onChangeFieldsPagosFacturados("TDD")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                AMEX
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  name="AMEX"
                  type="text"
                  id="AMEX"
                  disabled={true}
                  value={formatToCurrency(this.state.pagosFacturados.AMEX)}
                  onChange={this.onChangeFieldsPagosFacturados("AMEX")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                Transferencia
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  name="TRANSFERENCIA"
                  type="text"
                  id="TRANSFERENCIA"
                  disabled={true}
                  value={formatToCurrency(
                    this.state.pagosFacturados.TRANSFERENCIA
                  )}
                  onChange={this.onChangeFieldsPagosFacturados("TRANSFERENCIA")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                Por definir (99)
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  id="POR_DEFINIR_99"
                  name="POR_DEFINIR_99"
                  type="text"
                  disabled={true}
                  value={formatToCurrency(
                    this.state.pagosFacturados.POR_DEFINIR_99
                  )}
                  onChange={this.onChangeFieldsPagosFacturados(
                    "POR_DEFINIR_99"
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                Pago en Línea
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  id="PAGO_EN_LINEA"
                  name="PAGO_EN_LINEA"
                  type="text"
                  disabled={true}
                  value={formatToCurrency(
                    this.state.pagosFacturados.PAGO_EN_LINEA
                  )}
                  onChange={this.onChangeFieldsPagosFacturados("PAGO_EN_LINEA")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                App
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  id="APP"
                  name="APP"
                  type="text"
                  disabled={true}
                  value={formatToCurrency(this.state.pagosFacturados.APP)}
                  onChange={this.onChangeFieldsPagosFacturados("APP")}
                />
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                md={7}
                lg={7}
                className={`${s.parametro} ${s.black}`}
              >
                Total
              </Col>
              <Col xs={12} md={5} lg={5} className={s.parametro}>
                <MexicanCurrency quantity={this.state.totalPagosFacturados} />
              </Col>
            </Row>

            {/*----------------------- CONCILIACION -----------------------------------*/}
            <div className="row mt-3 mb-3">
              <div className="col">
                <p className={`${s.primaryTitle} w-100 m-0`}>Conciliación</p>
              </div>
            </div>
            <Row>
              <Col xs={12} md={8} lg={8} className="mt-4">
                MENOS
              </Col>
              <Col xs={12} md={4} lg={4} className="mt-4"></Col>
            </Row>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                Nota de Crédito por devolución y/o bonificación total o parcial
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  name="NOTA_CREDITO"
                  type="text"
                  id="NOTA_CREDITO"
                  value={this.state.conciliaciones.NOTA_CREDITO}
                  onChange={this.onChangeFieldsConciliacion("NOTA_CREDITO")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                Nota de Crédito por cambio datos fiscales
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  name="NOTA_CREDITO_DATOS_FISCALES"
                  type="text"
                  id="NOTA_CREDITO_DATOS_FISCALES"
                  value={formatToCurrency(
                    this.state.conciliaciones.NOTA_CREDITO_DATOS_FISCALES
                  )}
                  onChange={this.onChangeFieldsConciliacion(
                    "NOTA_CREDITO_DATOS_FISCALES"
                  )}
                  disabled={true}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                Nota de Crédito por cobranza errónea
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  name="NOTA_CREDITO_COBRANZA_ERRONEA"
                  type="text"
                  id="NOTA_CREDITO_COBRANZA_ERRONEA"
                  value={formatToCurrency(
                    this.state.conciliaciones.NOTA_CREDITO_COBRANZA_ERRONEA
                  )}
                  onChange={this.onChangeFieldsConciliacion(
                    "NOTA_CREDITO_COBRANZA_ERRONEA"
                  )}
                  disabled={true}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                Facturación forma de pago 99 'por definir'
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  name="FACTURACION_99"
                  type="text"
                  id="FACTURACION_99"
                  value={formatToCurrency(
                    this.state.conciliaciones.FACTURACION_99
                  )}
                  onChange={this.onChangeFieldsConciliacion("FACTURACION_99")}
                  disabled={true}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                Facturación excepcional
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  name="FACTURACION_EXCEPCIONAL"
                  type="text"
                  id="FACTURACION_EXCEPCIONAL"
                  value={this.state.conciliaciones.FACTURACION_EXCEPCIONAL}
                  onChange={this.onChangeFieldsConciliacion(
                    "FACTURACION_EXCEPCIONAL"
                  )}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                Facturación cobranza días anteriores
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  name="FACTURACION_COBRANZA_DIAS_ANTERIORES"
                  type="text"
                  id="FACTURACION_COBRANZA_DIAS_ANTERIORES"
                  value={
                    this.state.conciliaciones
                      .FACTURACION_COBRANZA_DIAS_ANTERIORES
                  }
                  onChange={this.onChangeFieldsConciliacion(
                    "FACTURACION_COBRANZA_DIAS_ANTERIORES"
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                md={7}
                lg={7}
                className={`${s.parametro} ${s.black}`}
              >
                Subtotal
              </Col>
              <Col xs={12} md={5} lg={5} className={s.parametro}>
                <MexicanCurrency
                  quantity={this.state.subtotalConciliacionesMenos}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={8} lg={8} className="mt-4">
                MÁS
              </Col>
              <Col xs={12} md={4} lg={4} className="mt-4"></Col>
            </Row>

            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                Cobranza de facturas con formas de pago 99 'por definir'
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  name="COBRANZA_FACTURA"
                  type="text"
                  id="COBRANZA_FACTURA"
                  value={this.state.conciliaciones.COBRANZA_FACTURA}
                  onChange={this.onChangeFieldsConciliacion("COBRANZA_FACTURA")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                Recibos provisionales (cheque)
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  name="RECIBOS_PROVISIONALES"
                  type="text"
                  id="RECIBOS_PROVISIONALES"
                  value={formatToCurrency(
                    this.state.conciliaciones.RECIBOS_PROVISIONALES
                  )}
                  onChange={this.onChangeFieldsConciliacion(
                    "RECIBOS_PROVISIONALES"
                  )}
                  disabled={true}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                Cobranza pendiente de facturar
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  name="COBRANZA_PENDIENTE_FACTURAR"
                  type="text"
                  id="COBRANZA_PENDIENTE_FACTURAR"
                  value={this.state.conciliaciones.COBRANZA_PENDIENTE_FACTURAR}
                  onChange={this.onChangeFieldsConciliacion(
                    "COBRANZA_PENDIENTE_FACTURAR"
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                md={7}
                lg={7}
                className={`${s.parametro} ${s.black}`}
              >
                Subtotal
              </Col>
              <Col xs={12} md={5} lg={5} className={s.parametro}>
                <MexicanCurrency
                  quantity={this.state.subtotalConciliacionesMas}
                />
              </Col>
            </Row>

            {/*----------------------- COBRANZA -----------------------------------*/}
            <div className="row mt-3 mb-3">
              <div className="col">
                <p className={`${s.primaryTitle} w-100 m-0`}>Cobranza</p>
              </div>
            </div>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                Efectivo
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  name="Efectivo"
                  type="text"
                  id="Efectivo"
                  value={this.state.cobranzas.Efectivo}
                  onChange={this.onChangeFieldsCobranzas("Efectivo")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                Cheques
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  name="Cheques"
                  type="text"
                  id="Cheques"
                  value={formatToCurrency(this.state.cobranzas.Cheques)}
                  onChange={this.onChangeFieldsCobranzas("Cheques")}
                  disabled={true}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                Pago directo en caja.- tarjetas de débito
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  name="PDTDD"
                  type="text"
                  id="PDTDD"
                  value={this.state.cobranzas.PDTDD}
                  onChange={this.onChangeFieldsCobranzas("PDTDD")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                Pago directo en caja.- tarjetas de crédito
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  name="PDTDC"
                  type="text"
                  id="PDTDC"
                  value={this.state.cobranzas.PDTDC}
                  onChange={this.onChangeFieldsCobranzas("PDTDC")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                Pago directo en caja.- AMEX
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  name="PDAMEX"
                  type="text"
                  id="PDAMEX"
                  value={this.state.cobranzas.PDAMEX}
                  onChange={this.onChangeFieldsCobranzas("PDAMEX")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                Cargo automático a TDC
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  name="CATDC"
                  type="text"
                  id="CATDC"
                  value={this.state.cobranzas.CATDC}
                  onChange={this.onChangeFieldsCobranzas("CATDC")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                Cargo automático a débito (domiciliados)
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  name="CATDD"
                  type="text"
                  id="CATDD"
                  value={this.state.cobranzas.CATDD}
                  onChange={this.onChangeFieldsCobranzas("CATDD")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                Cargo automático AMEX
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  name="CAAMEX"
                  type="text"
                  id="CAAMEX"
                  value={this.state.cobranzas.CAAMEX}
                  onChange={this.onChangeFieldsCobranzas("CAAMEX")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                Transferencia
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  name="Transferencia"
                  type="text"
                  id="Transferencia"
                  value={this.state.cobranzas.Transferencia}
                  onChange={this.onChangeFieldsCobranzas("Transferencia")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                Pago en línea
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  name="pagoLinea"
                  type="text"
                  id="pagoLinea"
                  value={this.state.cobranzas.pagoLinea}
                  onChange={this.onChangeFieldsCobranzas("pagoLinea")}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={7} lg={7} className={s.parametro}>
                APP
              </Col>
              <Col xs={12} md={5} lg={5}>
                <BaseInput
                  name="APP"
                  type="text"
                  id="APP"
                  value={this.state.cobranzas.APP}
                  onChange={this.onChangeFieldsCobranzas("APP")}
                />
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                md={7}
                lg={7}
                className={`${s.black} ${s.parametro}`}
              >
                Subtotal
              </Col>
              <Col xs={12} md={5} lg={5} className={s.parametro}>
                <MexicanCurrency quantity={this.state.totalCobranzas} />
              </Col>
            </Row>
            <Row>
              <Col
                xs={12}
                md={7}
                lg={7}
                className={`${s.black} ${s.parametro}`}
              >
                Diferiencia
              </Col>
              <Col xs={12} md={5} lg={5} className={s.parametro}>
                <MexicanCurrency quantity={this.state.diferencia} />
              </Col>
            </Row>
            <Row>
              <Col className="mt-4 mb-4">
                <Button
                  size="sm"
                  className={`float-right btn-block`}
                  onClick={this.generarPDF}
                >
                  Generar PDF
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          ""
        )}
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(CashCutReport));
