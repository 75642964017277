import actionTypes from "../actionTypes";
import IAction from "../../types/IAction";
import {
  errorNotification,
  GET_DATA_TERMINAL_ERROR,
  GET_REFERENCE_SANTANDER_CARGO_ERROR,
  GET_REFERENCE_NOT_USED_SANTANDER_ERROR
} from "../../utils/notifications";
import { readMessageBack } from "../../utils/readMessageBack";
interface State {
  nameAPP: string;
  Ambiente: string;
  Country: string;
  IdBranch: string;
  IdCompany: string;
  pwd: string;
  User: string;
  UserTRX: string;
  EMV: string;
  ModeloTerminal: string;
  SerieTerminal: string;
  SoporteCTLS: string;
  Printer: string;
  VersionTerminal: string;
  TpOperation: string;
  Currency: string;
  Reverse: string;
  CurrencyCode: string;
  TimeOutPinPad: string;
  SoportaFirma: string;
  MarcaTerminal: string;
  getData: boolean;
  ReferenceSantander: string;
  referencesNotUsed: object[];
  UserTkn: string;
  pwdTkn: string;
  IdCompanyTkn: string;
  IdBranchTkn: string;
  isLogguedInTerminal: boolean;
}
const initialState: State = {
  nameAPP: "",
  Ambiente: "",
  Country: "",
  IdBranch: "",
  IdCompany: "",
  pwd: "",
  User: "",
  UserTRX: "",
  EMV: "",
  ModeloTerminal: "",
  SerieTerminal: "",
  SoporteCTLS: "",
  Printer: "",
  VersionTerminal: "",
  TpOperation: "",
  Currency: "",
  Reverse: "",
  CurrencyCode: "",
  TimeOutPinPad: "",
  SoportaFirma: "",
  MarcaTerminal: "",
  getData: false,
  ReferenceSantander: "",
  referencesNotUsed: [],
  UserTkn: "",
  pwdTkn: "",
  IdCompanyTkn: "",
  IdBranchTkn: "",
  isLogguedInTerminal: false
};

export default function promotionsReducer(
  state: State = initialState,
  action: IAction
): State {
  switch (action.type) {
    case `${actionTypes.ADD_LOGUEO_REDUX}`: {
      return {
        ...state,
        IdBranch: action.payload.bs_branch,
        IdCompany: action.payload.bs_company,
        EMV: action.payload.EMV,
        Printer: action.payload.impresora,
        ModeloTerminal: action.payload.modelo,
        SerieTerminal: action.payload.serie,
        SoporteCTLS: action.payload.soportaCTLS,
        VersionTerminal: action.payload.versionApp,
        SoportaFirma: action.payload.soportaFirma,
        MarcaTerminal: action.payload.marca,
        isLogguedInTerminal: action.payload.isLogguedInTerminal || false
      };
    }
    case `${actionTypes.CLEAR_LOGUEO_REDUX}`: {
      return {
        ...state,
        IdBranch: null,
        IdCompany: null,
        EMV: null,
        Printer: null,
        ModeloTerminal: null,
        SerieTerminal: null,
        SoporteCTLS: null,
        VersionTerminal: null,
        SoportaFirma: null,
        MarcaTerminal: null,
        isLogguedInTerminal: false
      };
    }
    case `${actionTypes.GET_DATA_LOGUEO}_PENDING`: {
      // infoNotification(GET_DATA_TERMINAL_PENDING);
      return {
        ...state,
        getData: true
      };
    }
    case `${actionTypes.GET_DATA_LOGUEO}_REJECTED`: {
      errorNotification(readMessageBack(action, GET_DATA_TERMINAL_ERROR));
      return {
        ...state,
        getData: false
      };
    }
    case `${actionTypes.GET_DATA_LOGUEO}_FULFILLED`: {
      // successNotification(GET_DATA_TERMINAL);
      const data = action.payload.data;
      return {
        ...state,
        nameAPP: data.nameAPP,
        Ambiente: data.ambiente,
        Country: data.country,
        pwd: data.pwd,
        User: data.user,
        UserTRX: data.userTRX,
        TpOperation: data.tpOperation,
        Currency: data.currency,
        Reverse: data.reverse,
        CurrencyCode: data.currencyCode,
        TimeOutPinPad: data.timeOutPinPad,
        getData: false,
        UserTkn: data.userTkn || "",
        pwdTkn: data.pwdTkn || "",
        IdCompanyTkn: data.companyTkn || "",
        IdBranchTkn: data.branchTkn || ""
      };
    }
    case `${actionTypes.GET_REFERENCE_SANTANDER_COMPLEMENTO}_PENDING`: {
      // infoNotification(GET_REFERENCE_SANTANDER_CARGO_PENDING);
      return {
        ...state,
        ReferenceSantander: ""
      };
    }
    case `${actionTypes.GET_REFERENCE_SANTANDER_COMPLEMENTO}_REJECTED`: {
      errorNotification(
        readMessageBack(action, GET_REFERENCE_SANTANDER_CARGO_ERROR)
      );
      return {
        ...state,
        ReferenceSantander: ""
      };
    }
    case `${actionTypes.GET_REFERENCE_SANTANDER_COMPLEMENTO}_FULFILLED`: {
      // successNotification(GET_REFERENCE_SANTANDER_CARGO);
      return {
        ...state,
        ReferenceSantander: action.payload.data
      };
    }
    case `${actionTypes.GET_REFERENCE_NOT_USED_SANTANDER}_PENDING`: {
      // infoNotification(GET_REFERENCE_NOT_USED_SANTANDER_PENDING);
      return {
        ...state,
        referencesNotUsed: []
      };
    }
    case `${actionTypes.GET_REFERENCE_NOT_USED_SANTANDER}_REJECTED`: {
      errorNotification(
        readMessageBack(action, GET_REFERENCE_NOT_USED_SANTANDER_ERROR)
      );
      return {
        ...state,
        referencesNotUsed: []
      };
    }
    case `${actionTypes.GET_REFERENCE_NOT_USED_SANTANDER}_FULFILLED`: {
      // successNotification(GET_REFERENCE_NOT_USED_SANTANDER);
      return {
        ...state,
        referencesNotUsed: action.payload.data
      };
    }
    case `${actionTypes.GET_REFERENCE_SANTANDER_CARGO}_PENDING`: {
      // infoNotification(GET_REFERENCE_SANTANDER_CARGO_PENDING);
      return {
        ...state,
        ReferenceSantander: ""
      };
    }
    case `${actionTypes.GET_REFERENCE_SANTANDER_CARGO}_REJECTED`: {
      errorNotification(
        readMessageBack(action, GET_REFERENCE_SANTANDER_CARGO_ERROR)
      );
      return {
        ...state,
        ReferenceSantander: ""
      };
    }
    case `${actionTypes.GET_REFERENCE_SANTANDER_CARGO}_FULFILLED`: {
      // successNotification(GET_REFERENCE_SANTANDER_CARGO);
      return {
        ...state,
        ReferenceSantander: action.payload.data
      };
    }
    case `${actionTypes.CLEAR_REFERENCE_REDUX}`: {
      return {
        ...state,
        ReferenceSantander: ""
      };
    }
    default: {
      return state;
    }
  }
}

export { initialState };
