import React from "react";

import ReactTable from "react-table";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import { Button, Row, Col, Container } from "reactstrap";
import ModalFrame from "../../../../toolkit/modalFrame";
import IAction from "../../../../types/IAction";

interface Props {
  isOpenHCN: boolean;
  toggleHCN: () => any;
  listHistoricalRebilling: any;
  historicalRebilling: (ventaId: string) => IAction;
  ventaId: string;
}

interface State {
  load: boolean;
  loadList: boolean;
  listHistorical: any;
}

class HistoryCreditNotes extends React.Component<Props, State> {
  state = {
    load: false,
    loadList: false,
    listHistorical: []
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isOpenHCN && !this.state.load) {
      this.props.historicalRebilling(this.props.ventaId);
      this.setState({ load: true });
    }

    if (
      this.props.listHistoricalRebilling !== null &&
      this.props.listHistoricalRebilling.length > 0 &&
      !this.state.loadList
    ) {
      this.setState({
        loadList: true,
        listHistorical: this.props.listHistoricalRebilling
      });
    }
  }

  clearData = () => {
    this.props.toggleHCN();
  };

  columns = [
    {
      Header: "Factura UUID",
      accessor: "facturaUUID"
    },
    {
      Header: "Nota Credito UUID",
      accessor: "notaCreditoUUID"
    },
    {
      Header: "Folio Factura",
      accessor: "folioFactura"
    },
    {
      Header: "Folio Nota Credito",
      accessor: "folioNotaCredito"
    },
    {
      Header: "Fecha Autorizacion Nota Credito",
      accessor: "fechaAutorizacionNotaCredito"
    },
    {
      Header: "Folio Factura",
      accessor: "folioFactura"
    }
  ];

  render() {
    return (
      <Container className="p-0">
        <ModalFrame
          title={`Historial notas de crédito`}
          isOpen={this.props.isOpenHCN}
          toggle={this.clearData}
          size="lg"
        >
          <ReactTable
            data={this.state.listHistorical}
            columns={this.columns}
            showPagination={false}
            className="mt-3"
            getTrProps={() => ({
              // style: { height: "60px" },
              className: "mt-1"
            })}
          />
          <Row className="mt-4">
            <Col xs={6} md={12} lg={12}>
              <Button block color="secondary" onClick={this.clearData}>
                SALIR
              </Button>
            </Col>
          </Row>
        </ModalFrame>
      </Container>
    );
  }
}

export default withRouter(withStyles(s)(HistoryCreditNotes));
