import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import BenefitsTable from "../benefitsTable";
import { Container } from "reactstrap";
import { MEMBERSHIP, Club, Props } from "./interfaces";
import BenefitsHeader from "../benefitsHeader";
import PromotionBenefitsFreeUsers from "../benefitsFreePartners";
import PromotionBenefitsFreeChildren from "../benefitsFreeChildren";
import PromotionBenefitsFreeGuestsPasses from "../benefitsFreeGuestsPasses";

const Benefits = ({
  prices,
  updateCellPrice,
  deleteMonthPrices,
  addMonthPrices,
  onChangeClub,
  clubId,
  clubs,
  onChangeMembership,
  membershipId,
  memberships,
  checkLastMonth,
  checkProportional,
  checkRelative,
  onChangeMembershipValue,
  onChangePriceTypeMonth,
  onChangePriceType,
  updateMirrorFreePartners,
  updateFreePartnersByBirthDay,
  updateFreePartnersNumber,
  updateFreePartnersInitialDate,
  updateFreePartnersFinalDate,
  updateFreePartnersMonths,
  updateFreeGuestsPassesNumber,
  updateFreeChildrenNumber
}: Props) => (
  <Container className="w-100 p-0 m-0">
    <BenefitsHeader
      onChangeClub={onChangeClub}
      onChangePriceType={onChangePriceType}
      clubId={clubId}
      clubs={clubs}
      onChangeMembership={onChangeMembership}
      membershipId={membershipId}
      memberships={memberships}
      checkRelative={checkRelative}
      checkProportional={checkProportional}
      benefits={prices}
      onChangeMembershipValue={onChangeMembershipValue}
    />
    <hr />
    <BenefitsTable
      benefits={prices}
      updateCellPrice={updateCellPrice}
      checkLastMonth={checkLastMonth}
      onChangePriceTypeMonth={onChangePriceTypeMonth}
      deleteMonthPrices={deleteMonthPrices}
      addMonthPrices={addMonthPrices}
      editable
    />

    <PromotionBenefitsFreeUsers
      benefits={prices}
      updateMirrorFreePartners={updateMirrorFreePartners}
      updateFreePartnersByBirthDay={updateFreePartnersByBirthDay}
      updateFreePartnersNumber={updateFreePartnersNumber}
      updateFreePartnersInitialDate={updateFreePartnersInitialDate}
      updateFreePartnersFinalDate={updateFreePartnersFinalDate}
      updateFreePartnersMonths={updateFreePartnersMonths}
    />

    <PromotionBenefitsFreeChildren
      benefits={prices}
      updateFreeChildrenNumber={updateFreeChildrenNumber}
    />

    <PromotionBenefitsFreeGuestsPasses
      benefits={prices}
      updateFreeGuestsPassesNumber={updateFreeGuestsPassesNumber}
    />
  </Container>
);

export default withRouter(withStyles(s)(Benefits));
