import React from "react";
import { withRouter } from "react-router-dom";
import Benefits from "../../components/benefits";
import { PRICE_TYPES } from "../../../types-business/Agreements";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import s from "./styles.scss";
import isNil from "lodash.isnil";

interface Props {
  onClickSave: (e: object) => any;
  match: any;
  history: any;
  getMemberships: (id: string) => any;
  getClubs: (id: string) => any;
  getPrices: (id: string, membershipId: string, clubId: string) => any;
  memberships: object[];
  clubs: object[];
  prices: object;
  checkRelative: (ev) => any;
  checkProportional: (ev) => any;
  onChangeMembershipValue: (ev) => any;
  onChangePriceType: (membershipPriceType: string) => any;
  checkLastMonth: (monthIndex, ev) => any;
  updateCellPrice: (monthIndex, memberIndex, ev) => any;
  onChangePriceTypeMonth: (monthIndex: number, type: PRICE_TYPES) => any;
  deleteMonthPrices: () => any;
  addMonthPrices: () => any;
  entityModified: boolean;
  showConfirmBenefitsChangeModal: () => any;
  changesConfirmed: boolean;
  membershipChanged: (membershipId: string) => any;
  clubChanged: () => any;
  saveChangesBenefits: (prices: object) => any;

  //INVITADOS GRATIS
  updateMirrorFreePartners: (_checked: boolean) => any;
  updateFreePartnersByBirthDay: (_checked: boolean) => any;
  updateFreePartnersNumber: (_number: number) => any;
  updateFreePartnersInitialDate: (initialDate: any) => any;
  updateFreePartnersFinalDate: (finalDate: any) => any;
  updateFreePartnersMonths: (_number: number) => any;

  //NIÑOS GRATIS
  updateFreeChildrenNumber: (_number: number) => any;

  //PASES INVITADO GRATIS
  updateFreeGuestsPassesNumber: (_number: number) => any;
}

const getPath = (tipo, id, membershipId, clubId) => {
  const pathClub = clubId ? `clubs/${clubId}/` : `clubs/`;
  return `/${tipo}/${id}/membresias${
    membershipId ? `/${membershipId}/` : "/"
  }${pathClub}beneficios/`;
};

const DEFAULT_DUMMY_ID = "-1";
let memberhipId = DEFAULT_DUMMY_ID;
let clubId = DEFAULT_DUMMY_ID;

class BenefitsConfiguration extends React.Component<Props> {
  componentDidMount() {
    const { id, membershipId, clubId } = this.props.match.params;
    if (id && !this.props.memberships) {
      this.props.getMemberships(id);
      this.props.getClubs(id);
    }
    if (id && !isNil(membershipId))
      this.props.getPrices(
        id,
        membershipId,
        !isNil(clubId) ? clubId : "general"
      );

    this.props.onClickSave(() => {
      this.props.saveChangesBenefits(this.props.prices);
    });
  }

  changeMembreship = membershipId => {
    const { tipo, id, clubId: pathClubId } = this.props.match.params;
    const clubId = membershipId.length ? pathClubId : "general";
    this.props.history.push(getPath(tipo, id, membershipId, clubId));
    if (membershipId.length)
      this.props.getPrices(
        id,
        membershipId,
        !isNil(clubId) ? clubId : "general"
      );
    this.props.membershipChanged(membershipId);
  };

  changeClub = clubId => {
    const { tipo, id, membershipId } = this.props.match.params;
    this.props.history.push(getPath(tipo, id, membershipId, clubId));
    if (!isNil(membershipId)) {
      this.props.getPrices(id, membershipId, clubId);
    }
    this.props.clubChanged();
  };

  onChangeMembership = ev => {
    ev.preventDefault();
    const { value: newMembershipId } = ev.target;
    memberhipId = newMembershipId;
    if (this.props.entityModified) {
      if (!this.props.changesConfirmed) {
        this.props.showConfirmBenefitsChangeModal();
      }
    } else {
      this.changeMembreship(memberhipId);
      memberhipId = DEFAULT_DUMMY_ID;
    }
  };

  onChangeClub = ev => {
    ev.preventDefault();
    const { value: newClubId } = ev.target;
    clubId = newClubId;
    if (this.props.entityModified) {
      if (!this.props.changesConfirmed) {
        this.props.showConfirmBenefitsChangeModal();
      }
    } else {
      this.changeClub(clubId);
      clubId = DEFAULT_DUMMY_ID;
    }
  };

  componentDidUpdate() {
    if (this.props.entityModified) {
      if (this.props.changesConfirmed) {
        if (memberhipId !== DEFAULT_DUMMY_ID)
          this.changeMembreship(memberhipId);
        if (clubId !== DEFAULT_DUMMY_ID) this.changeClub(clubId);
      }
    }
  }

  render() {
    return (
      <div className="container p-0 m-0">
        <Benefits
          onChangePriceType={this.props.onChangePriceType}
          onChangeMembershipValue={this.props.onChangeMembershipValue}
          checkRelative={this.props.checkRelative}
          checkProportional={this.props.checkProportional}
          prices={this.props.prices}
          memberships={this.props.memberships}
          clubs={this.props.clubs}
          onChangeMembership={this.onChangeMembership}
          onChangeClub={this.onChangeClub}
          membershipId={this.props.match.params.membershipId}
          clubId={this.props.match.params.clubId}
          updateCellPrice={this.props.updateCellPrice}
          onChangePriceTypeMonth={this.props.onChangePriceTypeMonth}
          addMonthPrices={this.props.addMonthPrices}
          deleteMonthPrices={this.props.deleteMonthPrices}
          checkLastMonth={this.props.checkLastMonth}
          updateMirrorFreePartners={this.props.updateMirrorFreePartners}
          updateFreePartnersByBirthDay={this.props.updateFreePartnersByBirthDay}
          updateFreePartnersNumber={this.props.updateFreePartnersNumber}
          updateFreePartnersInitialDate={
            this.props.updateFreePartnersInitialDate
          }
          updateFreePartnersFinalDate={this.props.updateFreePartnersFinalDate}
          updateFreePartnersMonths={this.props.updateFreePartnersMonths}
          updateFreeGuestsPassesNumber={this.props.updateFreeGuestsPassesNumber}
          updateFreeChildrenNumber={this.props.updateFreeChildrenNumber}
        />
      </div>
    );
  }
}

export default withRouter(withStyles(s)(BenefitsConfiguration));
