import React from "react";
import withStyles from "isomorphic-style-loader/lib/withStyles";
import { withRouter } from "react-router-dom";
import s from "./styles.scss";
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import BaseInput from "../../../toolkit/baseInput";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  infoNotification,
  errorNotification,
  successNotification
} from "../../../utils/notifications";
import { getErrorMessage } from "../../../utils/helpers";
import {
  getDemandsClassificationsFiiltered,
  createDemandClassificationNode,
  updateDemandClassificationNode
} from "../../actions/demans";
import { getClubsRequest, getUsersRequest } from "../../actions/catalogs";
import {
  DEFAULT_ID,
  DEMAND_CLASSIFICATION_TYPES
} from "../../../utils/constants";

interface ClassificationNode {
  id: number;
  tipo: string;
  descripcion: string;
  nivel: number;
  padre: number;
  estatus: boolean;
  criticidad: string;
  padreRuta: string;
  clubesConceptos: string[];
  responsablesClubes: ClassificationClubResponsibleNode[];
}

interface ClassificationClubResponsibleNode {
  id: number;
  clubId: number;
  responsableId: number;
}

const OP_CRITICALLITY = {
  ALTA: "ALTA",
  MEDIA: "MEDIA",
  BAJA: "BAJA"
};

const OP_MODES = {
  NONE: "NONE",
  CREATE: "CREATE",
  EDIT: "EDIT"
};

const OP_CONCEPTS = [
  { value: "TODOS", label: "TODOS" },
  { value: "SW", label: "SW" },
  { value: "GYM_PLUS", label: "GYM +" },
  { value: "LOAD", label: "LOAD" },
  { value: "ZUMA", label: "ZUMA" },
  { value: "BTB", label: "BTB" },
  { value: "GYM", label: "GYM" }
];

interface Props {}

interface State {
  filters: {
    type: string;
    level1: number;
    level2: number;
    level3: number;
  };
  level1Data: ClassificationNode[];
  level2Data: ClassificationNode[];
  level3Data: ClassificationNode[];
  tableData: ClassificationNode[];
  lastSelectedLevel: number;
  lastSelectedParentId: number;
  searching: boolean;
  //modal
  showModal: boolean;
  selectedClassificationNode: ClassificationNode;
  mode: string;

  usersCatalog: any[];
  clubsCatalog: any[];
}

const getEnumOptions = enumObject => {
  const options = [];
  for (const key in enumObject) {
    if (enumObject.hasOwnProperty(key)) {
      const element = enumObject[key];
      options.push({
        value: element,
        label: element
      });
    }
  }
  return options;
};

class AdminDemandsClassifications extends React.Component<Props, State> {
  state = {
    filters: {
      type: DEMAND_CLASSIFICATION_TYPES.QUEJA,
      level1: 0,
      level2: 0,
      level3: 0
    },
    level1Data: [],
    level2Data: [],
    level3Data: [],
    tableData: [],
    lastSelectedLevel: 0,
    lastSelectedParentId: -1,
    searching: false,
    showModal: false,
    selectedClassificationNode: {} as ClassificationNode,
    mode: OP_MODES.NONE,

    usersCatalog: [],
    clubsCatalog: []
  };

  componentDidMount() {
    this.getClubsCatalog();
    this.getUsersCatalog();
    this.getLevel1Data();
    this.search();
  }

  handleRequestError = err => {
    errorNotification(
      getErrorMessage(err, "Ocurrió un error al consultar los datos.").message
    );
    this.setState({ searching: false });
  };

  getClubsCatalog = () => {
    getClubsRequest()
      .then(({ data }) => {
        this.setState({ clubsCatalog: data });
      })
      .catch(this.handleRequestError);
  };

  getUsersCatalog = () => {
    getUsersRequest({ full: false })
      .then(({ data }) => {
        this.setState({ usersCatalog: data });
      })
      .catch(this.handleRequestError);
  };

  getLevel1Data = () => {
    const { type } = this.state.filters;
    getDemandsClassificationsFiiltered(type, 1, -1)
      .then(({ data }) => {
        this.setState({ level1Data: data });
        this.search();
      })
      .catch(this.handleRequestError);
  };
  getLevel2Data = () => {
    const { type, level1 } = this.state.filters;
    getDemandsClassificationsFiiltered(type, 2, level1)
      .then(({ data }) => {
        this.setState({ level2Data: data });
        this.search();
      })
      .catch(this.handleRequestError);
  };
  getLevel3Data = () => {
    const { type, level2 } = this.state.filters;
    getDemandsClassificationsFiiltered(type, 3, level2)
      .then(({ data }) => {
        this.setState({ level3Data: data });
        this.search();
      })
      .catch(this.handleRequestError);
  };
  getLevel4Data = () => {
    this.search();
  };

  search = () => {
    let level = -1;
    let parentId = -1;

    const { level1, level2, level3, type } = this.state.filters;

    if (!!level3) {
      level = 4;
      parentId = level3;
    } else if (!!level2) {
      level = 3;
      parentId = level2;
    } else if (!!level1) {
      level = 2;
      parentId = level1;
    } else {
      level = 1;
      parentId = -1;
    }

    getDemandsClassificationsFiiltered(type, level, parentId, true)
      .then(({ data }) => {
        const path = this.getParentPath();
        data.forEach((x: ClassificationNode) => (x.padreRuta = path));
        this.setState({ tableData: data });
      })
      .catch(this.handleRequestError);
  };

  getParentPath = () => {
    const name = [];

    const { level1, level2, level3, type } = this.state.filters;

    if (!!level1) {
      name.push(
        this.state.level1Data.find((x: ClassificationNode) => x.id == level1)
          .descripcion
      );
    }
    if (!!level2) {
      name.push(
        this.state.level2Data.find((x: ClassificationNode) => x.id == level2)
          .descripcion
      );
    }
    if (!!level3) {
      name.push(
        this.state.level3Data.find((x: ClassificationNode) => x.id == level3)
          .descripcion
      );
    }
    return name.join(" / ");
  };

  onClickNewClassification = () => {
    const {
      selectedClassificationNode,
      lastSelectedLevel,
      lastSelectedParentId
    } = this.state;
    selectedClassificationNode.padre = lastSelectedParentId;
    selectedClassificationNode.nivel = lastSelectedLevel + 1;
    selectedClassificationNode.tipo = this.state.filters.type;
    selectedClassificationNode.criticidad = OP_CRITICALLITY.ALTA;
    selectedClassificationNode.padreRuta = this.getParentPath();
    selectedClassificationNode.estatus = false;
    selectedClassificationNode.clubesConceptos = [];
    selectedClassificationNode.responsablesClubes = [];

    this.setState({
      mode: OP_MODES.CREATE,
      selectedClassificationNode
    });
    this.toggleModal();
  };

  onClickEditClassification = (classificationItem: ClassificationNode) => {
    this.setState({
      selectedClassificationNode: { ...classificationItem },
      mode: OP_MODES.EDIT
    });
    this.toggleModal();
  };

  //modal

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  clearEditObjectState = () => {
    this.setState({
      selectedClassificationNode: {} as ClassificationNode,
      mode: OP_MODES.NONE
    });
  };

  handleSaveClassificationNode = e => {
    e.preventDefault();

    if (
      !this.state.selectedClassificationNode ||
      (!this.state.selectedClassificationNode.padre ||
        !this.state.selectedClassificationNode.criticidad ||
        !this.state.selectedClassificationNode.descripcion ||
        !this.state.selectedClassificationNode.nivel)
    ) {
      errorNotification("Debe llenar los campos obligatorios.");
      return;
    }

    switch (this.state.mode) {
      case OP_MODES.CREATE:
        infoNotification("Creando nodo de clasificación...");

        createDemandClassificationNode({
          ...this.state.selectedClassificationNode
        })
          .then(() => {
            successNotification(
              "El nodo de clasificación se creó correctamente"
            );
            this.handleCancelEditClassificationNode();
            this.search();
          })
          .catch(this.handleRequestError);

        break;
      case OP_MODES.EDIT:
        infoNotification("Actualizando el nodo de clasificación...");

        updateDemandClassificationNode({
          ...this.state.selectedClassificationNode
        })
          .then(() => {
            successNotification(
              "El nodo de clasificación se actualizó correctamente"
            );
            this.handleCancelEditClassificationNode();
            this.search();
          })
          .catch(this.handleRequestError);

        break;
      default:
        this.handleCancelEditClassificationNode();
        break;
    }
  };

  handleCancelEditClassificationNode = () => {
    this.toggleModal();
    this.clearEditObjectState();
  };

  onChangeFactory = (fieldName, f) => {
    return event => {
      const value = f(event);
      this.setState({
        ...this.state,
        selectedClassificationNode: {
          ...this.state.selectedClassificationNode,
          [fieldName]: value
        }
      });
    };
  };

  onChangeCheckedFactory = fieldName => {
    this.setState({
      ...this.state,
      selectedClassificationNode: {
        ...this.state.selectedClassificationNode,
        [fieldName]: !this.state.selectedClassificationNode[fieldName]
      }
    });
  };

  onChangeCheckedConceptFactory = e => {
    const { selectedClassificationNode } = this.state;
    const checked = e.target.checked;
    const value = e.target.value;

    if (value == "TODOS") {
      if (checked) {
        selectedClassificationNode.clubesConceptos = OP_CONCEPTS.map(
          x => x.value
        );
      } else {
        selectedClassificationNode.clubesConceptos = [];
      }
    } else {
      if (checked) {
        selectedClassificationNode.clubesConceptos = [
          ...selectedClassificationNode.clubesConceptos,
          value
        ];
      } else {
        selectedClassificationNode.clubesConceptos = selectedClassificationNode.clubesConceptos.filter(
          x => x != value && x != "TODOS"
        );
      }
    }

    this.setState({
      selectedClassificationNode
    });
  };

  renderSelectClub = ({ index }) => {
    return (
      <Input
        type={"select"}
        name={"clubSelector" + index}
        id={"clubSelector" + index}
        value={
          this.state.selectedClassificationNode.responsablesClubes[index].clubId
        }
        disabled={false}
        onChange={e => {
          const value = e.target.value;
          const { selectedClassificationNode } = this.state;
          selectedClassificationNode.responsablesClubes[index].clubId = value;
          this.setState({ selectedClassificationNode });
        }}
        size={"md"}
      >
        {(this.state.clubsCatalog || []).map(({ clubId, nombre }) => {
          return (
            <option key={index} value={clubId}>
              {nombre}
            </option>
          );
        })}
      </Input>
    );
  };

  renderSelectResponsible = ({ index, column }) => {
    return (
      <Input
        type={"select"}
        name={"responsibleSelector" + index}
        id={"responsibleSelector" + index}
        value={
          this.state.selectedClassificationNode.responsablesClubes[index]
            .responsableId
        }
        disabled={false}
        onChange={e => {
          const value = e.target.value;
          const { selectedClassificationNode } = this.state;
          selectedClassificationNode.responsablesClubes[
            index
          ].responsableId = value;
          this.setState({ selectedClassificationNode });
        }}
        size={"md"}
      >
        {(this.state.usersCatalog || []).map(
          ({ userId, name, firstSurname, secondSurname }) => {
            return (
              <option key={index} value={userId}>
                {`${name || ""} ${firstSurname || ""} ${secondSurname || ""}`}
              </option>
            );
          }
        )}
      </Input>
    );
  };

  renderDeleteButton = ({ index, column }) => {
    return (
      <Button
        className={`${s.primaryButton} btn-block`}
        size="sm"
        onClick={() => {
          const { selectedClassificationNode } = this.state;
          selectedClassificationNode.responsablesClubes[index].id = -1000;
          selectedClassificationNode.responsablesClubes = selectedClassificationNode.responsablesClubes.filter(
            x => x.id != -1000
          );
          this.setState({ selectedClassificationNode });
        }}
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    );
  };

  render() {
    let columns = [
      {
        Header: () => <p className="font-weight-bold">Id</p>,
        id: 0,
        width: 80,
        accessor: "id",
        Cell: row => {
          return <div className="pl-2">{row.original.id}</div>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Tipo</p>,
        id: 1,
        width: 100,
        accessor: "tipo",
        Cell: row => {
          return <div className="text-wrap">{row.original.tipo}</div>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Padre</p>,
        id: 1,
        width: 200,
        accessor: "description",
        Cell: row => {
          return <div className="text-wrap">{row.original.padreRuta}</div>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Descripción</p>,
        id: 1,
        width: 400,
        accessor: "description",
        Cell: row => {
          return <div className="text-wrap">{row.original.descripcion}</div>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Criticidad</p>,
        id: 1,
        width: 120,
        accessor: "criticidad",
        Cell: row => {
          return <div className="text-wrap">{row.original.criticidad}</div>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Nivel</p>,
        id: 2,
        width: 80,
        accessor: "level",
        Cell: row => {
          return <div className="text-center">{row.original.nivel}</div>;
        }
      },
      {
        Header: () => <p className="font-weight-bold">Activo</p>,
        id: 3,
        width: 80,
        accessor: "active",
        Cell: row => {
          return (
            <div className="text-center">
              {!!row.original.estatus ? "SI" : "NO"}
            </div>
          );
        }
      },
      {
        Header: () => {
          return (
            <div className="text-center">
              <Button
                title="Crear"
                className={`${s.actionHeaderIcon}`}
                onClick={this.onClickNewClassification}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          );
        },
        id: -300,
        width: 80,
        accessor: "actions",
        Cell: row => {
          return (
            <div className="text-center">
              <Button
                title="Editar"
                className={`${s.actionHeaderIcon} mr-2`}
                onClick={() => {
                  this.onClickEditClassification(row.original);
                }}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            </div>
          );
        }
      }
    ];

    return (
      <Container className="p-0 mt-3">
        <Row>
          <Col>
            <BaseInput
              type="select"
              label="Tipo*"
              name="demandClassificationType"
              id="demandClassificationType"
              placeholder="Tipo de clasificación"
              value={this.state.filters.type}
              options={getEnumOptions(DEMAND_CLASSIFICATION_TYPES)}
              onChange={e => {
                const value = e.target.value;
                const { filters } = this.state;
                filters.type = value;
                filters.level1 = DEFAULT_ID;
                filters.level2 = DEFAULT_ID;
                filters.level3 = DEFAULT_ID;
                this.setState({
                  filters,
                  level1Data: [],
                  level2Data: [],
                  level3Data: [],
                  tableData: [],
                  lastSelectedLevel: 0,
                  lastSelectedParentId: -1
                });
                this.getLevel1Data();
              }}
            />
          </Col>
          <Col>
            <BaseInput
              type="select"
              label="Nivel 1*"
              name="demandClassificationLevel1"
              id="demandClassificationLevel1"
              placeholder="Nivel 1"
              value={this.state.filters.level1}
              options={[{ value: DEFAULT_ID, label: "Seleccione..." }].concat(
                (this.state.level1Data || []).map(
                  (option: ClassificationNode) => ({
                    value: option.id,
                    label: option.descripcion
                  })
                )
              )}
              onChange={e => {
                const value = e.target.value;
                const { filters } = this.state;
                filters.level1 = value;
                filters.level2 = DEFAULT_ID;
                filters.level3 = DEFAULT_ID;
                this.setState({
                  filters,
                  level2Data: [],
                  level3Data: [],
                  tableData: [],
                  lastSelectedLevel: 1,
                  lastSelectedParentId: value
                });
                this.getLevel2Data();
              }}
            />
          </Col>
          <Col>
            <BaseInput
              type="select"
              label="Nivel 2*"
              name="demandClassificationLevel2"
              id="demandClassificationLevel2"
              placeholder="Nivel 2"
              value={this.state.filters.level2}
              options={[{ value: DEFAULT_ID, label: "Seleccione..." }].concat(
                (this.state.level2Data || []).map(
                  (option: ClassificationNode) => ({
                    value: option.id,
                    label: option.descripcion
                  })
                )
              )}
              onChange={e => {
                const value = e.target.value;
                const { filters } = this.state;
                filters.level2 = value;
                filters.level3 = DEFAULT_ID;
                this.setState({
                  filters,
                  level3Data: [],
                  tableData: [],
                  lastSelectedLevel: 2,
                  lastSelectedParentId: value
                });
                this.getLevel3Data();
              }}
            />
          </Col>
          <Col>
            <BaseInput
              type="select"
              label="Nivel 3*"
              name="demandClassificationLevel3"
              id="demandClassificationLevel3"
              placeholder="Nivel 3"
              value={this.state.filters.level3}
              options={[{ value: DEFAULT_ID, label: "Seleccione..." }].concat(
                (this.state.level3Data || []).map(
                  (option: ClassificationNode) => ({
                    value: option.id,
                    label: option.descripcion
                  })
                )
              )}
              onChange={e => {
                const value = e.target.value;
                const { filters } = this.state;
                filters.level3 = value;
                this.setState({
                  filters,
                  tableData: [],
                  lastSelectedLevel: 3,
                  lastSelectedParentId: value
                });
                this.getLevel4Data();
              }}
            />
          </Col>
        </Row>
        <ReactTable
          className="mt-5 -striped -highlight"
          data={this.state.tableData || []}
          columns={columns}
          sortable={false}
          resizable={false}
          pageSize={(this.state.tableData || []).length + 2}
          showPagination={false}
          loading={this.state.searching}
        />

        <Modal
          isOpen={this.state.showModal}
          toggle={this.toggleModal}
          fade={true}
          size="lg"
          backdrop="static"
          keyboard={false}
        >
          <ModalHeader toggle={this.toggleModal}>
            {this.state.mode === OP_MODES.CREATE && (
              <span className="font-weight-bold">
                Creación de clasificación
              </span>
            )}
            {this.state.mode === OP_MODES.EDIT && (
              <span className="font-weight-bold">Edición de clasificación</span>
            )}
          </ModalHeader>
          <ModalBody>
            <div className="mt-2">
              <Form>
                <Row>
                  <Col>
                    <p>
                      <b style={{ fontWeight: "bold" }}>Padre: </b>{" "}
                      {this.state.selectedClassificationNode.padreRuta || "N/A"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <BaseInput
                      type="text"
                      label="Descripción *"
                      name="classificationDesc"
                      id="classificationDesc"
                      placeholder="Descripción"
                      value={
                        this.state.selectedClassificationNode.descripcion || ""
                      }
                      onChange={this.onChangeFactory(
                        "descripcion",
                        e => e.target.value
                      )}
                    />
                  </Col>
                  <Col xs={12} sm={2}>
                    <BaseInput
                      type="select"
                      label="Criticidad"
                      name="classificationCrit"
                      id="classificationCrit"
                      placeholder="Criticidad"
                      value={
                        this.state.selectedClassificationNode.criticidad ||
                        OP_CRITICALLITY.ALTA
                      }
                      options={getEnumOptions(OP_CRITICALLITY)}
                      onChange={this.onChangeFactory(
                        "criticidad",
                        e => e.target.value
                      )}
                    />
                  </Col>
                  <Col xs={12} sm={2}>
                    <BaseInput
                      type="text"
                      label="Tiempo atención:"
                      name="classificationCritTime"
                      id="classificationCritTime"
                      placeholder="Tiempo de atención"
                      value={
                        this.state.selectedClassificationNode.criticidad ==
                        OP_CRITICALLITY.ALTA
                          ? "24 horas"
                          : this.state.selectedClassificationNode.criticidad ==
                            OP_CRITICALLITY.MEDIA
                          ? "48 horas"
                          : "72 horas"
                      }
                      disabled={true}
                    />
                  </Col>
                  <Col xs={12} sm={2}>
                    <FormGroup check className="mt-2">
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={
                            this.state.selectedClassificationNode.estatus
                          }
                          onChange={e => this.onChangeCheckedFactory("estatus")}
                        />
                        Activo
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col xs={12}>
                    <p style={{ fontWeight: "bold" }}>Disponible en:</p>
                  </Col>
                  {OP_CONCEPTS.map((x, index) => (
                    <Col xs={2} key={x.value + index}>
                      <FormGroup check className="mt-2">
                        <Label check>
                          <Input
                            type="checkbox"
                            value={x.value}
                            checked={
                              (
                                this.state.selectedClassificationNode
                                  .clubesConceptos || []
                              ).find(ax => ax == x.value) != null
                            }
                            onChange={this.onChangeCheckedConceptFactory}
                          />
                          {x.label}
                        </Label>
                      </FormGroup>
                    </Col>
                  ))}
                </Row>

                <Row className="mt-5">
                  <Col xs={12}>
                    <p style={{ fontWeight: "bold" }}>
                      Responsable directo del club:
                    </p>
                  </Col>
                  <Col xs={12}>
                    <ReactTable
                      data={
                        this.state.selectedClassificationNode
                          .responsablesClubes || []
                      }
                      columns={[
                        {
                          Header: "Club",
                          accessor: "clubId",
                          Cell: this.renderSelectClub,
                          className: "p-0",
                          width: 200
                        },
                        {
                          Header: "Responsable",
                          accessor: "clubes",
                          Cell: this.renderSelectResponsible,
                          className: "p-0",
                          style: { whiteSpace: "normal" }
                        },
                        {
                          Header: () => (
                            <div className="w-100 text-center">
                              <Button
                                color="link"
                                onClick={() => {
                                  const {
                                    selectedClassificationNode
                                  } = this.state;
                                  selectedClassificationNode.responsablesClubes.push(
                                    {
                                      clubId: 1,
                                      responsableId: 1
                                    } as ClassificationClubResponsibleNode
                                  );
                                  this.setState({ selectedClassificationNode });
                                }}
                                className={`${s.innerButton} ${s.primaryLink}`}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                              </Button>
                            </div>
                          ),
                          Cell: this.renderDeleteButton,
                          width: 100
                        }
                      ]}
                      pageSize={
                        (
                          this.state.selectedClassificationNode
                            .responsablesClubes || []
                        ).length + 1
                      }
                      sortable={false}
                      showPagination={false}
                      className="-highlight -striped w-100"
                    />
                  </Col>
                </Row>
              </Form>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className={`${s.primaryButton} d-inline-block mr-2`}
              onClick={this.handleSaveClassificationNode}
              disabled={
                !this.state.selectedClassificationNode ||
                (!this.state.selectedClassificationNode.padre ||
                  !this.state.selectedClassificationNode.criticidad ||
                  !this.state.selectedClassificationNode.descripcion ||
                  !this.state.selectedClassificationNode.nivel)
              }
            >
              GUARDAR
            </Button>
            <Button
              className={`${s.secondaryButton} d-inline-block mr-2`}
              onClick={this.handleCancelEditClassificationNode}
            >
              CANCELAR
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}
export default withRouter(withStyles(s)(AdminDemandsClassifications));
